import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import $ from "jquery";
import { sanitizer } from "../../helpers";

class ContentHtml extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      html: this.props.html,
      type: this.props.type || "inner",
      src: this.props.src || "",
    };
  }

  loadContent() {
    let content = this.state.html;

    /*eslint-disable*/
    let headerScripts =
      '<link rel="stylesheet" href="https://dashboard.memberpages.app/custom-tools/css/shared-styles.css" />';
    let footerScripts = `<script type="text/javascript" src="https://dashboard.memberpages.app/custom-tools/js/shared-scripts.js?v1.000"></script>`;
    /*eslint-enable*/

    content = content.replace("</head>", headerScripts + "</head>");
    content = content.replace("</body>", footerScripts + "</body>");

    $("#custom_content_iframe")
      .contents()
      .find("body")
      .html(content)
      .ready(() => {
        let newInterval = setInterval(() => {
          let newHeight = $("#custom_content_iframe")
            .contents()
            //            .find("body")
            .height();

          if (newHeight !== $("#custom_content_iframe").height()) {
            $("#custom_content_iframe").animate(
              {
                height: newHeight,
              },
              {
                duration: 75,
                complete: function () {
                  setTimeout(() => {
                    clearInterval(newInterval);
                  }, 2000);
                },
              }
            );
          }
        }, 50);
      });
  }

  setContent() {
    let self = this;

    let x = setInterval(() => {
      self.loadContent();
    }, 200);

    setTimeout(() => {
      clearInterval(x);
    }, 200);
  }

  renderContent() {
    let type = this.state.type;
    if (type === "inner") {
      return (
        <div className="qlContent">
          <div
            dangerouslySetInnerHTML={{
              __html: sanitizer(this.state.html),
            }}
          />
        </div>
      );
    }

    if (type === "iframe") {
      return (
        <div>
          <iframe
            scrolling="no"
            style={{ width: "100%", border: 0 }}
            title="custom_content_iframe"
            id="custom_content_iframe"
            allowfullscreen="true" 
            webkitallowfullscreen="true" 
            mozallowfullscreen="true"
            allow="autoplay; encrypted-media; picture-in-picture" 
            src="about:blank"></iframe>
          {this.setContent()}
        </div>
      );
    }

    if (type === "iframeSrc") {
      return (
        <div>
          <iframe
            scrolling="no"
            style={{ width: "100%", border: 0 }}
            title="iframe_src"
            id="iframe_src"
            allowfullscreen="true" 
            webkitallowfullscreen="true" 
            mozallowfullscreen="true"
            allow="autoplay; encrypted-media; picture-in-picture" 
            src={this.state.src}
          />
        </div>
      );
    }
  }

  render() {
    return <>{this.renderContent()}</>;
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ContentHtml);
