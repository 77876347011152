import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { callGetSettings } from "../../../services/settingService";

class LeadCustomFields extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      customFields: [],
      customFieldValues: this.props.customFieldValues,
    };
  }

  componentDidMount() {
    this.getSettings();
  }

  async getSettings() {
    let d = await callGetSettings("leadsettings");
    let settings = d.data;
    let leadSettings = settings.lead_settings || {};
    let customFields = leadSettings.custom_fields || [];

    this.setState({ customFields }, () => {
      this.manageTextCustomFields();
    });
  }

  manageTextCustomFields() {
    let customFields = this.state.customFields || [];
    let customFieldValues = this.state.customFieldValues;

    customFields.forEach((element) => {
      let selectedOptions = customFieldValues.filter(
        (d) => d.id === element.id
      );
      if (selectedOptions.length > 0) {
        element["field_value"] = selectedOptions[0].field_value;
      } else {
        element["field_value"] = "";
      }
    });

    this.setState({
      customFields: customFields,
    });
  }

  handleTextChange(event, id) {
    const target = event.target;
    const value = target.value;

    let customFields = this.state.customFields || [];
    customFields.forEach((element) => {
      if (element.id === id) {
        element["field_value"] = value;
      }
    });
    this.setState({
      customFields: customFields,
    });
    this.props.onChangeFields(customFields);
  }

  renderCustomFields() {
    let customFields = this.state.customFields;
    let returnVals = [];

    for (let i = 0; i < customFields.length; i++) {
      const element = customFields[i];
      let dropdownValues = element.value.split("\n");
      let type = element.type || "text";
      returnVals.push(
        <>
          <div className="row">
            <div className="col-sm-12">
              {type === "text" && (
                <div
                  className={
                    element.field_value !== "" &&
                    element.field_value !== undefined
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>{element.label}</label>
                  <input
                    type="text"
                    value={element.field_value}
                    onChange={(event) =>
                      this.handleTextChange(event, element.id)
                    }
                  />
                </div>
              )}
              {type === "dropdown" && (
                <div
                  className={
                    element.field_value !== "" &&
                    element.field_value !== undefined
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>{element.label}</label>
                  <select
                    id={element.id}
                    name={element.id}
                    value={element.field_value}
                    onChange={(event) =>
                      this.handleTextChange(event, element.id)
                    }>
                    <option value="" disabled="disabled"></option>
                    {dropdownValues.map((i) => (
                      <option value={i}>{i}</option>
                    ))}
                  </select>
                </div>
              )}
            </div>
          </div>
          <div className="gap20" />
        </>
      );
    }
    return returnVals;
  }

  render() {
    return <div>{this.renderCustomFields()}</div>;
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LeadCustomFields);
