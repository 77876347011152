import {
  RESET_STATUS,
  CREATE_LINK_SUCCESS,
  CREATE_LINK_FAILURE,
  GET_LINK_SUCCESS
} from "../types";

const initialState = {
  message: "",
  success: false,
  links: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RESET_STATUS:
      return {
        ...state,
        message: "",
        success: false
      };
    case CREATE_LINK_SUCCESS:
      return {
        ...state,
        message: action.message,
        success: action.success
      };
    case CREATE_LINK_FAILURE:
      return {
        ...state,
        message: action.message,
        success: action.success
      };
    case GET_LINK_SUCCESS:
      return {
        ...state,
        links: action.data
      };
    default:
      return state;
  }
};
