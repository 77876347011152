import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import RadioTagV2 from "../../components/form/radioTagV2";

class RadioTagExample extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.radioTagRef = React.createRef();

    this.state = {};
  }

  render() {
    const groups = [
      {
        group: "SomeName",
        type: "single",
        minWidth: "200px",
        maxWidth: "100%",
        additionalInfoType: "",
        options: [
          {
            id: "option1",
            title: "Radio Option 1",
            subtitle: "Some additional information about this",
            additionalInfo: "<div>This is additional info for Option 1</div>",
          },
          {
            id: "option2",
            title: "Radio Option 2",
            subtitle: "Some additional information about this",
          },
          {
            id: "option3",
            title: "Radio Option 3",
            subtitle:
              "Some additional information about this Some additional information about this Some additional information about this Some additional information about this",
            additionalInfo:
              "<div>This is additional info for Option 3 Some additional information about this Some additional information about this Some additional information about this <img style='max-width:300px;' src='https://d38x6s7j8yvy7v.cloudfront.net/h3sf345hf32-1719009009333-vwat2ddufig.jpeg'></div>",
          },
        ],
      },
      {
        group: "AnotherGroup",
        type: "multi",
        minWidth: "200px",
        maxWidth: "300px",
        additionalInfoType: "direct",
        options: [
          {
            id: "option4",
            title: "Radio Option 4",
            subtitle: "Some additional information",
            image:
              "https://d38x6s7j8yvy7v.cloudfront.net/h3sf345hf32-1719009009304-wxh5xrph9v.png",
            additionalInfo: "<div>This is additional info for Option 4</div>",
          },
          {
            id: "option5",
            title: "Radio Option 5",
            subtitle: "Some additional information",
            image:
              "https://d38x6s7j8yvy7v.cloudfront.net/h3sf345hf32-1719009009333-vwat2ddufig.jpeg",
            additionalInfo: "<div>This is additional info for Option 5</div>",
          },
          {
            id: "option6",
            title: "Radio Option 6",
            subtitle: "Some additional information",
            image:
              "https://d38x6s7j8yvy7v.cloudfront.net/h3sf345hf32-1719009009325-09hz25r4mk1v.jpeg",
            additionalInfo: "<div>This is additional info for Option 6</div>",
          },
        ],
      },
    ];

    return (
      <div>
        <p>Radio Tag V2 Page</p>
        <RadioTagV2 ref={this.radioTagRef} groups={groups} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RadioTagExample);
