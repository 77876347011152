import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import TitleBox from "../../../components/general/titleBox";
import {
  getParameterByName,
  showNotification,
  toPromise,
} from "../../../helpers";
import {
  callCreateTaskHistoryTemplate,
  callGetTaskHistoryTemplateById,
  callUpdateTaskHistoryTemplate,
} from "../../../services";
import RadioTag from "../../../components/form/radioTag";
import { callGetSettings } from "../../../services/settingService";

class AddTaskHistoryTemplatePage extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    let id = getParameterByName("id") || 0;

    this.state = {
      id: id,
      name: "",
      fields: [],
      keyFields: Math.random(),
      labelList: [
        { name: "Task", value: "task" },
        { name: "Description", value: "description" },
        { name: "Due Date", value: "due_date" },
        { name: "Priority", value: "priority" },
        { name: "Status", value: "status" },
        { name: "Last Status Change Date", value: "status_date" },
        { name: "Assigned User", value: "assigned_user" },
        { name: "Invoice ID", value: "invoice_id" },
        { name: "Invoice Amount", value: "invoice_amount" },
      ],
    };
  }

  componentDidMount() {
    this.getData();
  }

  async getData() {
    await this.getSettings();
    if (this.state.id !== 0) {
      await this.callTemplate();
    }
  }

  async getSettings() {
    let d = await callGetSettings("tasksettings");
    let settings = d.data;
    let taskSettings = settings.task_settings || {};

    let labelList = this.state.labelList;
    let customFields = taskSettings.custom_fields || [];

    customFields.forEach((element) => {
      labelList.push({
        name: element.label,
        value: element.id,
      });
    });

    this.setState({
      labelList: labelList,
      keyFields: Math.random(),
    });
  }

  async callTemplate() {
    let response = await callGetTaskHistoryTemplateById(this.state.id);
    if (response._id) {
      this.setState({
        id: response._id,
        name: response.name,
        fields: response.fields,
        keyFields: Math.random(),
      });
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  resetProps() {
    this.setState({
      name: "",
      keyFields: Math.random(),
      fields: [],
    });
  }

  onChangeDate(value) {
    this.setState({
      releaseDate: value,
    });
  }

  onChangeContentValue = (html, json) => {
    this.setState({ description: html, descriptionObject: json });
  };

  onSelectFields(item, id) {
    let fieldList = [];

    Object.keys(item).forEach((obj) => {
      fieldList.push(item[obj].value);
    });

    this.setState({
      fields: fieldList,
    });
  }

  async onPressSave() {
    if (this.state.name.trim().length === 0) {
      showNotification("error", "Name is required", 4000);
      return;
    }

    if (this.state.fields.length === 0) {
      showNotification("error", "Fields are required", 4000);
      return;
    }

    /*eslint-disable*/
    let apiData = {
      name: this.state.name,
      fields: this.state.fields,
    };

    if (this.state.id === 0) {
      let [err, data] = await toPromise(callCreateTaskHistoryTemplate(apiData));

      if (err) {
        showNotification("error", err.message, 5000);
      } else {
        showNotification("success", "Created Successfully", 4000);
        this.resetProps();
      }
    } else {
      let [err, data] = await toPromise(
        callUpdateTaskHistoryTemplate(this.state.id, apiData)
      );
      if (err) {
        showNotification("error", err.message, 5000);
      } else {
        showNotification("success", "Updated Successfully", 4000);
      }
    }
  }

  render() {
    return (
      <div>
        <div className="container-fluid">
          <TitleBox
            title={
              this.state.id === 0
                ? "Add Task History Template"
                : "Edit Task History Template"
            }
            showBackBtn={this.state.id !== 0}
          />

          <div className="gap20" />

          <div className="card">
            <div className="container-fluid">
              <div className="gap20" />
              <div
                className={
                  this.state.name !== "" ? "mdInput mdFocussed" : "mdInput"
                }>
                <label>Name</label>
                <input
                  type=""
                  className="challangeDesc"
                  name="name"
                  value={this.state.name}
                  onChange={(event) => this.handleInputChange(event)}
                />
              </div>
              <div className="gap20" />

              <div className="tabsHolder radioTabs">
                <RadioTag
                  onCheckChange={this.onSelectFields.bind(this)}
                  labelList={this.state.labelList}
                  id={"fields"}
                  type={"multiple"}
                  key={this.state.keyFields}
                  selectedList={this.state.fields}
                  selectType={"value"}
                />
              </div>
              <div className="gap30" />
            </div>
          </div>

          <div className="text-right">
            <button
              className="btn btn-primary"
              onClick={() => this.onPressSave()}>
              Save
            </button>
          </div>
          <div className="gap20" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddTaskHistoryTemplatePage);
