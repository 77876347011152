const images = {
  appstore: require("../static/images/appstore.png"),
  playstore: require("../static/images/playstore.png"),
  logo: require("../static/images/logo-rect-blue.jpg"),
  logoHeader: require("../static/images/logo/logo-2.png"),
  defaultLogo: require("../static/images/logo/default-logo.png"),
  logoSalesHeader: require("../static/images/logo/logo-white-orange.png"),
  salesHeaderLayer: require("../static/images/footer-layer.png"),
  logoFooter: require("../static/images/logo/logo-front-header.png"),
  footerLayer: require("../static/images/footer-layer.png"),
  logoWhiteNoSig: require("../static/images/logo/logo-white-no-sig.png"),
  logoOnlyWhite: require("../static/images/logo-orange-icon-small.png"),
  headerCharacter: require("../static/images/header-character.png"),
  hmLogo1: require("../static/images/hm-logo-1.png"),
  hmLogo2: require("../static/images/hm-logo-2.png"),
  hmLogo3: require("../static/images/hm-logo-3.png"),
  hmLogo4: require("../static/images/hm-logo-4.png"),
  hmLogo5: require("../static/images/hm-logo-5.png"),
  hmLogo6: require("../static/images/hm-logo-6.png"),
  sectionImg1: require("../static/images/section-img-1.jpg"),
  sectionImg2: require("../static/images/section-img-2.jpg"),
  sectionImg3: require("../static/images/section-img-3.jpg"),
  sectionImg4: require("../static/images/section-img-4.jpg"),
  homeGraph: require("../static/images/home-graph.jpg"),
  envelope: require("../static/images/envelope.png"),
  logoSm: require("../static/images/logo/logo-cp.png"),
  logoFrontHeader: require("../static/images/logo/logo-front-header.png"),
  notificationBell: require("../static/images/notification-bell.png"),
  pieChart: require("../static/images/pie-chart.png"),
  smIcon1: require("../static/images/s-m-icon-1.png"),
  smIcon2: require("../static/images/s-m-icon-2.png"),
  smIcon3: require("../static/images/s-m-icon-3.png"),
  smIcon4: require("../static/images/s-m-icon-4.png"),
  smIcon5: require("../static/images/s-m-icon-5.png"),
  broken: require("../static/images/broken.png"),
  videoLoading: require("../static/images/loader.gif"),
  userImage: require("../static/images/default-user.png"),
  trainer1: require("../static/images/trainer1.png"),
  trainer2: require("../static/images/trainer2.png"),
  trainer3: require("../static/images/trainer3.png"),
  smallLogo: require("../static/images/small-logo.png"),
  // marciCircleLogo: require("../static/images/marcis-circle-logo.png"),
  headerImage: require("../static/images/header-image.png"),
  fastTrainers: require("../static/images/fast-trainers.png"),
  arrowDown: require("../static/images/arrow-down.png"),
  bodyType: require("../static/images/btype.png"),
  marciMckenzie: require("../static/images/marci-mckenzie.jpg"),
  atqSubmitted: require("../static/images/atq-submitted.png"),
  bonusCompleted: require("../static/images/bonuses-completed.png"),
  totalPoints: require("../static/images/total-points.png"),
  totalChallenges: require("../static/images/total-challenges.png"),
  marciStanding: require("../static/images/marci-standing.png"),
  marciCouch: require("../static/images/marci-couch.jpg"),
  marciMountain: require("../static/images/marci-mountain.jpg"),
  marciStretching: require("../static/images/marci-stretching.png"),
  marciBarker: require("../static/images/marcibarker.png"),
  // longTerm1: require("../static/images/longterm-1.png"),
  // longTerm2: require("../static/images/longterm-2.png"),
  // longTerm3: require("../static/images/longterm-3.png"),
  // longTerm4: require("../static/images/longterm-4.png"),
  // longTerm5: require("../static/images/longterm-5.png"),
  // aIcon: require("../static/images/a-icon.png"),
  // bIcon: require("../static/images/b-icon.png"),
  // cIcon: require("../static/images/c-icon.png"),
  // communicationIcon: require("../static/images/communication.png"),
  // noShakesIcon: require("../static/images/no-shakes.png"),
  // pathwayIcon: require("../static/images/pathway.png"),
  logoIconBlackFull: require("../static/images/logo-black-icon-full.png"),
  maleAvatar: require("../static/images/male.png"),
  femaleAvatar: require("../static/images/female.png"),
  accountabilityEmailIcon: require("../static/images/accountability-emails-icon.png"),
  // bonusChallengeIcon: require("../static/images/bonus-challenges-icon.png"),
  // liveGroupTrainingIcon: require("../static/images/live-group-trainings-icon.png"),
  motivationalEmailIcon: require("../static/images/motivational-emails-icon.png"),
  privateDashboardIcon: require("../static/images/private-dashboard-icon.png"),
  weeklyFocusIcon: require("../static/images/weekly-focus-icon.png"),
  logoLoadingOrange: require("../static/images/logo-loading-orange.gif"),
  homeBTC: require("../static/images/fsbtc.png"),
  homeHEC: require("../static/images/fshec.png"),
  homeHEA: require("../static/images/fshea.png"),
  homeMC: require("../static/images/fsmc.png"),
  homeHeaderTexture: require("../static/images/home-top-texture.png"),
  help: require("../static/images/help.png"),
  refresh: require("../static/images/refresh.png"),
  imageGallery: require("../static/images/image-gallery.png"),
  sideMenuTheme: require("../static/images/sidemenu-theme.png"),
  admin: {
    challenges: require("../static/images/menu/challenges.png"),
    mealPlans: require("../static/images/menu/meal-plans.png"),
    marciCircle: require("../static/images/menu/marci-circle.png"),
    emails: require("../static/images/menu/emails.png"),
    articles: require("../static/images/menu/articles.png"),
    billing: require("../static/images/menu/billing.png"),
    payments: require("../static/images/menu/payments.png"),
    reports: require("../static/images/menu/reports.png"),
    customPrograms: require("../static/images/menu/custom-programs.png"),
    companies: require("../static/images/menu/companies.png"),
    users: require("../static/images/menu/users.png"),
    tasks: require("../static/images/menu/tasks.png"),
    atqResponses: require("../static/images/menu/atq-responses.png"),
    home: require("../static/images/menu/home.png"),
    systemTag: require("../static/images/menu/system-tag.png"),
    systemUpdates: require("../static/images/menu/system-updates.png"),
    churn: require("../static/images/menu/churn.png"),
    events: require("../static/images/menu/events.png"),
    landingPage: require("../static/images/menu/landing-page.png"),
    popup: require("../static/images/menu/popup.png"),
    process: require("../static/images/menu/process.png"),
    settings: require("../static/images/menu/settings.png"),
    tiniSite: require("../static/images/menu/tini-site.png"),
    leads: require("../static/images/menu/leads.png"),
    subscriptionArea: require("../static/images/menu/subscription-area.png"),
    accountability: require("../static/images/menu/accountability.png"),
    course: require("../static/images/menu/course.png"),
    sms: require("../static/images/menu/sms.png"),
    notification: require("../static/images/menu/notification.png"),
    prepage: require("../static/images/menu/prepage.png"),
    helpdesk: require("../static/images/menu/help.png"),
    forms: require("../static/images/menu/forms.png"),
    quiz: require("../static/images/menu/quiz.png"),
    pdfTemplate: require("../static/images/menu/pdf.svg"),
    stripeHelp1: require("../static/images/stripe-help-1.jpg"),
    stripeHelp2: require("../static/images/stripe-help-2.jpg"),
    stripeHelp3: require("../static/images/stripe-help-3.jpg"),
    stripeHelp4: require("../static/images/stripe-help-4.jpg"),
    stripeHelp5: require("../static/images/stripe-help-5.jpg"),
  },
  member: {
    dashboard: require("../static/images/menu/member-dashboard.png"),
    messages: require("../static/images/menu/messages.png"),
    awards: require("../static/images/menu/awards.png"),
    charts: require("../static/images/menu/charts.png"),
    progressJournal: require("../static/images/menu/progress-journal.png"),
    menuMealPlans: require("../static/images/menu/member-meal-plans.png"),
    newsletter: require("../static/images/menu/newsletter.png"),
    mealPlans: require("../static/images/menu/meals.png"),
    trainings: require("../static/images/menu/trainings.png"),
    spotlight: require("../static/images/menu/spotlight.png"),
    savings: require("../static/images/menu/savings.png"),
    paymentHistory: require("../static/images/menu/payment-history.png"),
    resources: require("../static/images/menu/resources.png"),
    movements: require("../static/images/menu/movement-database.png"),
    newsletterPlaceholder: require("../static/images/newsplaceholder.png"),
    foamRolling: require("../static/images/menu/foam-rolling.png"),
  },
  company: {
    switchCompany: require("../static/images/menu/switch-company.png"),
    companyBilling: require("../static/images/menu/company-billing.png"),
  },
  challenges: {
    salesBackgroundBlue: require("../static/images/challenges/sales-background-blue.png"),
    salesBackgroundLightBlue: require("../static/images/challenges/sales-background-light-blue.png"),
    salesBackgroundDarkBlue: require("../static/images/challenges/sales-background-dark-blue.png"),
    salesBackgroundGreen: require("../static/images/challenges/sales-background-green.png"),
    salesBackgroundPink: require("../static/images/challenges/sales-background-pink.png"),
    salesBackgroundPurple: require("../static/images/challenges/sales-background-purple.png"),
    salesBackgroundBlack: require("../static/images/challenges/sales-background-black.png"),
    // logoWhite: require("../static/images/challenges/logo-white.png"),
    logoBlack: require("../static/images/challenges/logo-black.png"),
    // salesMarciBook: require("../static/images/challenges/marci-book.jpg"),
    // salesMarciHeadphones: require("../static/images/challenges/marci-headphones.jpg"),
    // salesMarciStanding: require("../static/images/challenges/marci-standing.jpg"),
    // salesBeforeAfter: require("../static/images/before-after.jpg"),
    // blueTickMark: require("../static/images/challenges/blue-tick-mark.png"),
    // challengerImageA: require("../static/images/challenges/challenger-image-a.png"),
    // challengerImageB: require("../static/images/challenges/challenger-image-b.png"),
    // challengerImageC: require("../static/images/challenges/challenger-image-c.png"),
    // review1: require("../static/images/challenges/Alyssa-young-words.jpg"),
    // review2: require("../static/images/challenges/amy-carter-words.jpg"),
    // review3: require("../static/images/challenges/britney-mcmurray-words.jpg"),
    // review4: require("../static/images/challenges/jenny-hansen-words.jpg"),
    // review5: require("../static/images/challenges/megan-call-words.jpg"),
    // review6: require("../static/images/challenges/meg-burt-words.jpg"),
    // review7: require("../static/images/challenges/rosanne-chadwick-words.jpg"),
    // review8: require("../static/images/challenges/Tara-beth-words.jpg"),
    // mcNecklacePhoto: require("../static/images/challenges/mcnecklacephoto.jpg"),
    // commentIcon: require("../static/images/challenges/comment-icon.png"),
    // fullReviewImageBarbie: require("../static/images/challenges/barbie-mills.jpg"),
    // week1FoodImage: require("../static/images/challenges/week1-image-for-sales-page.jpg"),
    // week2FoodImage: require("../static/images/challenges/week2-image-for-sales-page.jpg"),
    // week3FoodImage: require("../static/images/challenges/week3-image-for-sales-page.jpg"),
    // week4FoodImage: require("../static/images/challenges/week4-image-for-sales-page.jpg"),
    // marciFlyingHand: require("../static/images/challenges/marci-flying-hand.png"),
    // barbieMills: require("../static/images/challenges/barbie-mills.jpg"),
    // andreaChallenge: require("../static/images/challenges/Andrea-challenge.jpg"),
    // jessicaBluntBeforeAndAfter: require("../static/images/challenges/jessica-blunt-beforeand-after.jpg"),
    // marciIndicatesWithFingure: require("../static/images/challenges/marci-indicates-with-fingure.png")
  },
  textures: {
    bubbles: require("../static/images/textures/bubbles.png"),
    texture1: require("../static/images/textures/texture1.png"),
  },
};

export default images;
