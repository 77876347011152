import React from "react";

class ColorPicker extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    let color = this.props.color || "#000000";
    let rawColor = "#000000";
    let rangeHex = "FF";
    if (color) {
      rawColor = color.substr(0, 7);
      rangeHex = color.substr(7, 8) || "FF";
    }

    const normalize = (val, max, min) => {
      return (val - min) / (max - min);
    };

    const hexToAlpha = (alphaHexString) => {
      return Math.round(normalize(parseInt(alphaHexString, 16), 255, 0) * 100);
    };

    let range = hexToAlpha(rangeHex) / 100;

    this.state = {
      label: this.props.label || "Color",
      color: color,
      rawColor: rawColor,
      range: this.props.range || range,
      showOpacity: this.props.opacity,
      keyColor: Math.random(),
    };
  }

  onChangeColor = (event) => {
    let color = event.target.value;
    this.convertColor(color, this.state.range);
  };

  addAlpha(color, opacity) {
    const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
    return color + _opacity.toString(16).toUpperCase();
  }

  convertColor = (color, opacity) => {
    const hex = this.addAlpha(color, opacity);
    this.setState({ color: hex, rawColor: color });
    this.props.onChange(hex);
  };

  onRemoveColor = () => {
    this.setState({ color: "#000000", rawColor: "#000000", range: 1 });
    this.props.onChange("#000000");
  };

  onChangeRange = (event) => {
    let value = event.target.value;
    this.convertColor(this.state.rawColor, value);
    this.setState({ range: value, keyColor: Math.random() });
    if (this.props.onChangeRange) {
      this.props.onChangeRange(value);
    }
  };

  render() {
    return (
      <div className="card">
        <div className="container-fluid">
          <div className="gap20" />
          <div className="row">
            <div className="col-sm-10">
              <p className="bgSetter">
                <input
                  type="color"
                  style={{
                    opacity: this.state.range === 0 ? 1 : this.state.range,
                    background: "#fff",
                    borderRadius: "5px",
                    display: "inline-block",
                    cursor: "pointer",
                  }}
                  key={this.state.keyColor}
                  value={this.state.rawColor}
                  onChange={this.onChangeColor}
                />
                {this.state.label}
              </p>
            </div>
            <div className="col-sm-2">
              <div
                onClick={this.onRemoveColor}
                style={{ float: "right", cursor: "pointer" }}>
                <i className="fa fa-undo" />
              </div>
            </div>
          </div>
          <div className="row">
            {this.state.showOpacity !== false && (
              <div className="col-sm-12">
                <input
                  type="range"
                  min="0"
                  max="1"
                  step="0.05"
                  color="#2ae"
                  style={{ width: "100%" }}
                  value={this.state.range}
                  onChange={this.onChangeRange}
                />
                <label style={{ color: "#888", fontSize: 14 }}>
                  Opacity {(this.state.range * 100).toFixed(0)}%
                </label>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ColorPicker;
