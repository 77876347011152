import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import $ from "jquery";

import FilterList from "../../../components/form/filterList";
import TitleBox from "../../../components/general/titleBox";
import { filterByTags } from "../../../helpers/filters";
import { isFS, showNotification } from "../../../helpers";
import PreviewChallenge from "./previewChallenge";

import {
  callCreateChlg,
  callUpdateChlg,
  callGetChlgById,
  callGetChlgsByQuery,
} from "../../../services";
import ChallengeOperations from "./challengeOpts";
import { getItem, removeItem, setItem } from "../../../helpers/storage";
import { getUpdatedEditorContent } from "../../../components/editor/processEditor";
import ExportContent from "../../../helpers/exportContent";
import moment from "moment-timezone";

class ViewChallenge extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    let isAdmin = true;
    if (this.props.path.includes("admin/preview-challenges")) {
      isAdmin = false;
    }

    this.previewChlgRef = React.createRef();

    this.state = {
      isAdmin: isAdmin,
      chlgs: [],
      chlgBackup: [],
      searchQuery: "",
      categories: [],
      selectedFilter: "all",
      filterList: [],
      selectedChallenge: {},
      challengeName: "",
      shortName: "",
      keyChallengeDate: Math.random(),
      keyFilter: Math.random(),
      keyExport: Math.random(),
      logQuery: false,
    };
  }

  componentDidMount() {
    this.getChallenges();
  }

  async getChallenges() {
    let query = {
      query: {
        archive: { $ne: true },
        $select: ["-content", "-html"],
      },
    };
    let chlgs = await callGetChlgsByQuery(query);
    this.setState(
      {
        chlgs: chlgs.data,
        chlgBackup: chlgs.data,
        keyExport: Math.random(),
      },
      () => {
        let cq = getItem("cq");
        if (cq) {
          let query = JSON.parse(cq);
          let isDate = moment().isAfter(
            moment(query.date).add("5", "minutes"),
            "minutes"
          );
          if (!isDate) {
            let search = query.filterList.search;
            this.filterUpdated(
              search.values,
              "search",
              "search",
              search.searchFields
            );
            this.setState({
              keyFilter: Math.random(),
              searchQuery: query.searchQuery,
              filterList: query.filterList,
            });
          } else {
            removeItem("cq");
          }
        }
      }
    );
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    if (name === "challengeName") {
      if (value.length > 0) {
        let aValue = value.match(/\b\w/g).join("");
        this.setState({ shortName: aValue });
      }
    }

    this.setState({
      [name]: value,
    });
  }

  onClickEditChallenge = (challenge) => {
    this.props.history.push(`/admin/add-challenge?id=${challenge._id}`);
  };

  onClickPreviewChallenge = async (challenge) => {
    let challengeInfo = await callGetChlgById(challenge._id);
    this.previewChlgRef.current.onClickPreviewButton(challengeInfo);
  };

  onClickCloneChallenge = (challenge) => {
    this.setState({ selectedChallenge: challenge }, () => {
      $(
        ".modalAlertOverlay.challengeModalOverlay,.modalAlert.challengeModal"
      ).fadeIn(200);
    });
  };

  onClickArchiveChallenge = (challenge) => {
    this.setState({ selectedChallenge: challenge }, () => {
      $(".modalAlertOverlay.archiveOverlay,.modalAlert.archiveAlert").fadeIn(
        200
      );
    });
  };

  renderChallenges() {
    let returnVals = [];
    let chlgs = this.state.chlgs;
    chlgs.forEach((element) => {
      returnVals.push(
        <tr key={element._id}>
          <td>{element.name}</td>
          <td>{element.description}</td>
          <td>
            {element.duration_type === "nd"
              ? "No Duration"
              : element.challenge_days}
          </td>
          <td>{element.priority ? element.priority : ""}</td>
          <td className="min-width-150">
            {this.state.isAdmin && (
              <button
                onClick={this.onClickEditChallenge.bind(this, element)}
                className="btn btn-default btn-sm margin-right-10 border-0">
                <i className="fa fa-pencil" />
              </button>
            )}
            {this.state.isAdmin && (
              <button
                onClick={this.onClickCloneChallenge.bind(this, element)}
                className="btn btn-default btn-sm margin-right-10 border-0">
                <i className="fa fa-clone" />
              </button>
            )}
            <button
              onClick={this.onClickPreviewChallenge.bind(this, element)}
              className="btn btn-default btn-sm border-0 margin-right-10">
              <i className="fa fa-share-square-o" />
            </button>
            <button
              title="Archive"
              onClick={this.onClickArchiveChallenge.bind(this, element)}
              className="btn btn-danger btn-sm  border-0">
              <i className="fa fa-archive" />
            </button>
          </td>
        </tr>
      );
    });
    return returnVals;
  }

  filterUpdated(updatedFilter, name, type, searchFields) {
    let filterList = this.state.filterList;
    filterList[name] = {
      name: name,
      type: type,
      searchFields: searchFields,
      values: updatedFilter,
    };

    let searchType = filterList.search;
    let searchQuery = "";
    if (searchType) {
      let values = searchType.values[0];
      searchQuery = values.value;
    }

    this.setState(
      {
        filterList: filterList,
      },
      () => {
        let data = filterByTags(this.state.chlgBackup, filterList);
        this.setState({
          chlgs: data,
          logQuery: true,
        });
        if (this.state.logQuery) {
          setItem(
            "cq",
            JSON.stringify({
              date: new Date(),
              filterList: Object.assign({}, filterList),
              searchQuery: searchQuery,
            })
          );
        }
      }
    );
  }

  onClickCancelFromChallengeModal() {
    $(
      ".modalAlertOverlay.challengeModalOverlay,.modalAlert.challengeModal"
    ).fadeOut(200);
  }

  async onClickProcessFromChallengeModal() {
    if (this.state.challengeName.length === 0) {
      showNotification("error", "Please enter challenge name", 4000);
      return;
    }

    let existingChallenge = this.state.chlgs.filter((d) => {
      return (
        d.name.trim().toLowerCase() ===
        this.state.challengeName.trim().toLowerCase()
      );
    });

    if (existingChallenge.length > 0) {
      showNotification("error", "Challenge name already exists", 4000);
      return;
    }

    $(
      ".modalAlertOverlay.challengeModalOverlay,.modalAlert.challengeModal"
    ).fadeOut(200);

    let selectedChallenge = this.state.selectedChallenge;

    let apiData = {
      challenge: "",
      category: selectedChallenge.category,
      status: "active",
      name: this.state.challengeName,
      description: selectedChallenge.description,
      /*eslint-disable*/
      short_name: this.state.shortName,
      active_days: selectedChallenge.active_days,
      add_active_days: selectedChallenge.add_active_days || "0",
      challenge_days: selectedChallenge.challenge_days,
      preview_days: selectedChallenge.preview_days,
      start_day_of_week: selectedChallenge.start_day_of_week,
      feedback: selectedChallenge.feedback,
      video_list: selectedChallenge.video_list,
      challenge_item_list: selectedChallenge.challenge_item_list,
      duration_type: selectedChallenge.duration_type || "nd",
      /*eslint-enable*/
    };

    let chlgOp = new ChallengeOperations();
    let editorType = isFS()
      ? "mp"
      : getItem("de") === "classic"
      ? "unlayer"
      : "mp";
    let { rules, emailGenericTagId, challengeTemplate } =
      await chlgOp.prepareChallengeData(
        this.state.challengeName,
        selectedChallenge,
        editorType
      );

    apiData.rules = rules;
    if (challengeTemplate.html) {
      apiData.content = getUpdatedEditorContent(
        challengeTemplate.editor_content,
        challengeTemplate.html
      );
    }

    apiData.html = challengeTemplate.html;
    apiData["email_group_id"] = emailGenericTagId;
    // apiData["base_question_list"] = [questionId];

    callCreateChlg(apiData).then((d) => {
      if (d._id) {
        this.setState(
          { selectedChallenge: {}, challengeName: "", shortName: "" },
          () => {
            showNotification("success", "Challenge created successfully", 4000);
            this.getChallenges();
          }
        );
      } else {
        showNotification("error", "Something went wrong", 4000);
      }
    });
  }

  renderNewChallengeModal() {
    let name = "";
    if (this.state.selectedChallenge.name) {
      name = this.state.selectedChallenge.name;
    }
    return (
      <div>
        <div className="modalAlertOverlay challengeModalOverlay" />
        <div className="modalAlert challengeModal">
          <div className="clearfix">
            <div className="pull-left">
              <p>Clone Challenge - {name}</p>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div
                  className={
                    this.state.challengeName !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Challenge Name</label>
                  <input
                    type="text"
                    name="challengeName"
                    value={this.state.challengeName}
                    onChange={(event) => this.handleInputChange(event)}
                  />
                </div>
                <div className="gap10" />
              </div>
            </div>
            <label>Note: Challenge items and rules will not be copied.</label>
          </div>
          <div className="gap10" />

          <div className="alertFooterBtns">
            <button
              onClick={this.onClickCancelFromChallengeModal.bind(this)}
              className="btn btn-default dismissThisModal margin-right-10">
              Cancel
            </button>
            <button
              onClick={this.onClickProcessFromChallengeModal.bind(this)}
              className="btn btn-primary dismissThisModal">
              Create Challenge
            </button>
          </div>
        </div>
      </div>
    );
  }

  onClickNoArchiveAlert = () => {
    $(".modalAlertOverlay.archiveOverlay,.modalAlert.archiveAlert").fadeOut(
      200
    );
  };

  onClickYesArchiveAlert = () => {
    callUpdateChlg(this.state.selectedChallenge._id, { archive: true }).then(
      (d) => {
        $(".modalAlertOverlay.archiveOverlay,.modalAlert.archiveAlert").fadeOut(
          200
        );
        this.getChallenges();
      }
    );
  };

  renderArchiveConfirmationAlert() {
    let selectedChallenge = this.state.selectedChallenge;
    let name = "";
    if (selectedChallenge.name) {
      name = selectedChallenge.name;
    }
    return (
      <div>
        <div className="modalAlertOverlay archiveOverlay" />
        <div className="modalAlert archiveAlert">
          <p>{`Are you sure you want to archive "${name}" challenge?`}</p>
          <div className="alertFooterBtns">
            <button
              className="btn btn-default closeModal-No margin-right-10"
              onClick={this.onClickNoArchiveAlert}>
              No
            </button>
            <button
              className="btn btn-primary closeModal-Yes"
              onClick={this.onClickYesArchiveAlert}>
              Yes
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderFilterView() {
    return (
      <>
        <div className="list-group-item">
          <FilterList
            key={this.state.keyFilter}
            label={"Search Challenges"}
            name={"search"}
            type={"search"}
            searchQuery={this.state.searchQuery}
            searchFields={["name", "description"]}
            onFinish={this.filterUpdated.bind(this)}
          />
        </div>
      </>
    );
  }

  onClickViewArchivedChallenges() {
    this.props.history.push("/admin/view-archived-challenges");
  }

  render() {
    return (
      <div>
        {this.renderNewChallengeModal()}
        {this.renderArchiveConfirmationAlert()}
        <PreviewChallenge
          ref={this.previewChlgRef}
          history={this.props.history}
        />
        <div className="container-fluid">
          <TitleBox title="Challenge Overview" />
          <div className="gap20" />
          <div className="card">
            <div className="list-group list-group-flush">
              {this.renderFilterView()}
              <div className="list-group-item">
                <button
                  className="btn btn-default btn-sm"
                  onClick={this.onClickViewArchivedChallenges.bind(this)}>
                  View Archived Challenges
                </button>
              </div>
              <div className="list-group-item">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Challenge Name</th>
                      <th>Description</th>
                      <th>Days</th>
                      <th>Priority</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>{this.renderChallenges()}</tbody>
                </table>
              </div>
              <div className="list-group-item">
                <ExportContent
                  key={this.state.keyExport}
                  data={this.state.chlgs}
                  keys={[
                    "name",
                    "description",
                    "createdAt",
                    "priority",
                    "active_days",
                    "challenge_days",
                    "preview_days",
                    "type",
                    "status",
                    "forever_open",
                    "start_day_of_week",
                  ]}
                  screen={"challenges"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  chlgs: state.challengeReducer.chlgs,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ViewChallenge);
