import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DatePicker from "react-date-picker";
import moment from "moment";

import TitleBox from "../../../components/general/titleBox";
import Checkbox from "../../../components/form/checkBox";
import TypeableSelect from "../../../components/form/typeableSelect";
import {
  resetStatus,
  getMemberProductUsingQuery
} from "../../../modules/actions/index";
import config from "../../../helpers/config";
import { showNotification } from "../../../helpers";
import {
  callUpdateMemberProduct,
  callCreateCustomProgram,
  callUpdateCustomProgram,
  callGetUserById,
  callGetCustomProgramTemplates
} from "../../../services";
import HelpTextDiv from "../../../components/form/helpTextDiv";

class AddProgram extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    let customProgram = {
      _id: 0,
      name: "",
      description: "",
      days: 1,
      startDate: new Date()
    };

    let isEditMode = false;

    let custProg = null;

    if (this.props.history.location.state !== undefined) {
      let custProgram = this.props.history.location.state.customProgram;

      customProgram._id = custProgram._id;
      customProgram.name = custProgram.name;
      customProgram.description = custProgram.description;
      customProgram.days = custProgram.days;
      customProgram.startDate = new Date(custProgram.start_date);

      isEditMode = true;
      custProg = custProgram;
    }

    this.state = {
      isEditMode: isEditMode,
      customProgramId: customProgram._id,
      customProgram: custProg,
      name: customProgram.name,
      description: customProgram.description,
      days: customProgram.days,
      startDate: customProgram.startDate,
      memberProducts: [],
      selectedUser: { value: "", label: "" },
      keyUser: Math.random(),
      keyTemplate: Math.random(),
      selectedTemplate: { value: "", label: "" },
      templates: [],
      isClearDays: false,
      keyClearDays: Math.random()
    };
  }

  componentDidMount() {
    this.getMemberProducts();
    this.getTemplates();
  }

  getTemplates() {
    callGetCustomProgramTemplates().then(d => {
      let data = d.data;
      let templates = [];
      data.forEach(element => {
        templates.push({
          value: element,
          label: element.name
        });
      });
      this.setState({ templates: templates, keyTemplate: Math.random() });
    });
  }

  getMemberProducts() {
    let query = {
      query: {
        $or: [{ status: "pending", name: "Custom Program" }]
      }
    };
    this.props.getMemberProductUsingQuery(query);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.memberProducts.data) {
      let data = newProps.memberProducts.data;
      this.prepareData(data);
    }
  }

  async prepareData(data) {
    let memberProducts = [];

    for (let i = 0; i < data.length; i++) {
      const element = data[i];
      let memberInfo = await callGetUserById(element.member_id);
      memberProducts.push({
        label: memberInfo.name,
        value: element
      });
    }

    let selectedUser = { value: "", label: "" };
    if (this.state.customProgramId !== 0) {
      let mProd = data.filter(d => {
        return d._id === this.state.customProgramId;
      });
      if (mProd.length > 0) {
        let memberInfo = await callGetUserById(mProd[0].member_id);
        selectedUser.value = mProd[0];
        selectedUser.label = memberInfo.name;
      }
    }

    this.setState({
      selectedUser: selectedUser,
      memberProducts: memberProducts,
      keyUser: Math.random()
    });
  }

  resetProps() {
    this.setState({
      name: "",
      description: "",
      days: 1,
      startDate: new Date(),
      selectedUser: ""
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  onChangeStartDate(value) {
    this.setState({
      startDate: value
    });
  }

  onSelectUser = user => {
    this.setState({ selectedUser: user });
  };

  onSelectTemplate = template => {
    this.setState({ selectedTemplate: template });
  };

  afterAddCustomProgram(customProgramItem) {
    this.props.history.push("/admin/edit-custom-program", {
      customProgramItem: customProgramItem
    });
  }

  toggleCheckbox(check) {
    this.setState({ isClearDays: check });
  }

  getEventsFromTemplate() {
    let template = this.state.selectedTemplate.value;
    let events = template.events;

    let programEvents =
      this.state.customProgram !== null ? this.state.customProgram.events : [];

    if (events.length === 0) {
      return [];
    }

    events.sort((a, b) => {
      return a.day > b.day;
    });

    let lastEvent = events[events.length - 1];

    let startDate = moment(this.state.startDate)
      .subtract(1, "days")
      .startOf("day");

    let endDate = moment(this.state.startDate)
      .add(parseInt(lastEvent.day, 10), "days")
      .startOf("day");

    let day = 1;
    let filteredEvents = [];

    while (startDate.add(1, "days").diff(endDate) < 0) {
      let cloneDate = startDate.clone().startOf("day");
      let eventDate = cloneDate.format("YYYY-MM-DD");

      let eventDay = day;

      let event = events.filter(d => {
        return d.day === eventDay;
      });

      if (event.length === 1) {
        let eventInfo = {
          date: eventDate,
          sets: event[0].sets,
          notes: event[0].notes
        };

        if (this.state.isEditMode && !this.state.isClearDays) {
          let existingEvent = programEvents.filter(e => {
            return e.date === eventDate;
          });
          if (existingEvent.length === 0) {
            filteredEvents.push(eventInfo);
          }
        } else {
          filteredEvents.push(eventInfo);
        }
      }
      day++;
    }

    let returnEvents = filteredEvents;

    if (this.state.isEditMode && !this.state.isClearDays) {
      returnEvents = returnEvents.concat(programEvents);
    }

    return returnEvents;
  }

  onPressCreateCustomProgram() {
    if (this.state.name.trim().length === 0) {
      showNotification("error", "Name is required", 4000);
      return;
    }

    if (this.state.description.trim().length === 0) {
      showNotification("error", "Description is required", 4000);
      return;
    }

    if (this.state.days.toString().trim().length === 0) {
      showNotification("error", "Days is required", 4000);
      return;
    }

    if (!this.state.isEditMode) {
      if (this.state.selectedUser.label.trim().length === 0) {
        showNotification("error", "Please select the user", 4000);
        return;
      }
    }

    let events = [];
    let phases = [];

    if (this.state.selectedTemplate.label.trim().length > 0) {
      events = this.getEventsFromTemplate();
    }

    let memberProduct = {};
    if (!this.state.isEditMode) {
      memberProduct = this.state.selectedUser.value;
    } else {
      memberProduct = this.state.customProgram;
    }

    let apiData = {};

    if (!this.state.isEditMode) {
      apiData = {
        name: this.state.name,
        description: this.state.description,
        /*eslint-disable*/
        member_id: memberProduct.member_id,
        start_date: this.state.startDate,
        days: this.state.days,
        status: "pending",
        events: events,
        phases: phases
        /*eslint-enable*/
      };
      callCreateCustomProgram(apiData).then(newData => {
        if (newData._id) {
          this.resetProps();
          showNotification(
            "success",
            config.messages.createChlgQueSuccess,
            5000
          );
          this.callUpdateMemberProductAPI(memberProduct, newData._id);
          this.afterAddCustomProgram(newData);
        } else {
          showNotification("error", config.messages.createChlgQueError, 5000);
        }
        this.props.resetStatus();
      });
    } else {
      apiData = {
        name: this.state.name,
        description: this.state.description,
        /*eslint-disable*/
        start_date: this.state.startDate,
        days: this.state.days
        /*eslint-enable*/
      };

      if (events.length > 0) {
        apiData["events"] = events;
      }

      callUpdateCustomProgram(this.state.customProgram._id, apiData).then(
        updatedData => {
          if (updatedData._id) {
            showNotification("success", config.messages.updateMessage, 5000);
            this.afterAddCustomProgram(updatedData);
          } else {
            showNotification("error", config.messages.createChlgQueError, 5000);
          }
          this.props.resetStatus();
        }
      );
    }
  }

  callUpdateMemberProductAPI(memberProduct, customProgramId) {
    /*eslint-disable*/
    let updateMemberProductApiData = {
      status: "active",
      program_id: customProgramId
    };
    /*eslint-enable*/

    callUpdateMemberProduct(memberProduct._id, updateMemberProductApiData).then(
      md => {
        this.getMemberProducts();
      }
    );
  }

  render() {
    return (
      <div>
        <div className="container-fluid">
          <TitleBox
            title={
              this.state.customProgramId === 0
                ? "Create Custom Program"
                : "Edit Custom Program"
            }
            showBackBtn={this.state.customProgramId !== 0}
          />
          <div className="gap20" />

          <div className="card">
            <div className="container-fluid">
              <div className="gap20" />

              <HelpTextDiv
                id="idProgramName"
                label="Learn more about the Program Name"
                type="addProgram"
                isInline="false"
              />
              <div
                className={
                  this.state.name !== "" ? "mdInput mdFocussed" : "mdInput"
                }>
                <label>Name</label>
                <input
                  type=""
                  className="challangeName"
                  name="name"
                  value={this.state.name}
                  onChange={event => this.handleInputChange(event)}
                />
              </div>
              <div className="gap20" />

              <HelpTextDiv
                id="idProgramDescription"
                label="Learn more about the Description"
                type="addProgram"
                isInline="false"
              />
              <div
                className={
                  this.state.description !== ""
                    ? "mdInput mdFocussed"
                    : "mdInput"
                }>
                <label>Description</label>
                <input
                  type=""
                  className="challangeDesc"
                  name="description"
                  value={this.state.description}
                  onChange={event => this.handleInputChange(event)}
                />
              </div>
              <div className="gap20" />
              <div className="row">
                <div className="col-lg-6">
                  <HelpTextDiv
                    id="idProgramStartDate"
                    label="Learn more about the Start Date"
                    type="addProgram"
                    isInline="false"
                  />
                  <label className="inputFakeLabelFocussed">
                    Select Start Date
                  </label>
                  <div>
                    <DatePicker
                      value={this.state.startDate}
                      onChange={this.onChangeStartDate.bind(this)}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <HelpTextDiv
                    id="idProgramDays"
                    label="Learn more about the Number Of Days"
                    type="addProgram"
                    isInline="false"
                  />
                  <div
                    className={
                      this.state.days !== "" ? "mdInput mdFocussed" : "mdInput"
                    }>
                    <label>Number Of Days</label>
                    <input
                      type="number"
                      className="challangeDesc"
                      name="days"
                      value={this.state.days}
                      onChange={event => this.handleInputChange(event)}
                    />
                  </div>
                </div>
              </div>
              <div className="gap20" />
              <div className="row">
                <div className="col-lg-6">
                  <HelpTextDiv
                    id="idProgramUser"
                    label="Learn more about the User"
                    type="addProgram"
                    isInline="false"
                  />
                  {this.state.isEditMode === false && (
                    <TypeableSelect
                      key={this.state.keyUser}
                      name={"selectedUser"}
                      placeholder={"Select A User"}
                      selected={this.state.selectedUser}
                      onSelect={this.onSelectUser}
                      options={this.state.memberProducts}
                    />
                  )}
                  {this.state.isEditMode === true && (
                    <div className={"mdInput mdFocussed"}>
                      <label>Selected User</label>
                      <input
                        type="text"
                        className="challangeDesc"
                        name="name"
                        disabled
                        value={this.state.customProgram.member_info.name}
                      />
                    </div>
                  )}
                </div>
                <div className="col-lg-6">
                  <HelpTextDiv
                    id="idProgramTemplate"
                    label="Learn more about the Template"
                    type="addProgram"
                    isInline="false"
                  />
                  <TypeableSelect
                    key={this.state.keyTemplate}
                    name={"selectedTemplate"}
                    placeholder={"Select A Template"}
                    selected={this.state.selectedTemplate}
                    onSelect={this.onSelectTemplate}
                    options={this.state.templates}
                  />
                  <div className="gap20" />
                  {this.state.isEditMode && (
                    <Checkbox
                      key={this.state.keyClearDays}
                      label={"Clear existing days ?"}
                      isChecked={this.state.isClearDays}
                      handleCheckboxChange={this.toggleCheckbox.bind(this)}
                    />
                  )}
                  <div className="gap10" />
                  {this.state.isClearDays && (
                    <i style={{ color: "red", fontSize: 15 }}>
                      If you change template then all existing sets/exercises
                      will be replaced by new from selected template.
                    </i>
                  )}
                </div>
              </div>
              <div className="gap20" />
            </div>
          </div>
          <div
            className="text-right"
            onClick={() => this.onPressCreateCustomProgram()}>
            {/*eslint-disable-next-line*/}
            <a className="btn btn-primary">
              {this.state.customProgramId === 0
                ? "Add Custom Program"
                : "Update Custom Program"}
            </a>
          </div>
          <div className="gap20" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  message: state.customProgramReducer.message,
  success: state.customProgramReducer.success,
  memberProducts: state.customProgramReducer.memberProducts
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ resetStatus, getMemberProductUsingQuery }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddProgram);
