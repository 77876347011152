/* eslint jsx-a11y/anchor-is-valid: 0 */

import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import images from "../../helpers/images";

class NotFound extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      code: this.props.code || "404"
    };
  }

  onClickReturn() {
    if (this.props.history) {
      this.props.history.goBack();
    }
  }

  render() {
    return (
      <div className="nfHolder">
        <div className="nfInner">
          <h1>Error - {this.state.code}</h1>
          <img alt="broken" src={images.broken} className="headerCharacter" />
          <div className="gap10" />
          <p>Oops! Looks like you landed in a wrong place</p>
          <a className="btn btn-primary" onClick={() => this.onClickReturn()}>
            Return
          </a>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotFound);
