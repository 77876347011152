const config = {
  urls: {
    apiUrl: process.env.REACT_APP_API_PATH || "http://localhost:1234",
    siteUrl:
      process.env.REACT_APP_IS_DEV === "true"
        ? "http://localhost:3000"
        : "https://www.funandsustainable.com",
    cookieUrl:
      process.env.REACT_APP_IS_DEV === "true"
        ? "devfunandsustainable.com"
        : "funandsustainable.com",
  },
  messages: {
    loginSuccess: "Login Success",
    loginFailure:
      "Incorrect email or password, please try again or reset your password below",
    createChlgQueSuccess: "Created Successfully",
    updateMessage: "Updated Successfully",
    atqMessage: "Successfully Sent",
    progressJournalMessage: "Your progress has been successfully added/updated",
    createChlgQueError: "Couldn't save. Please double check all values.",
    createUserSuccess: "User added successfully",
    errorMessage: "Couldn't save. Please double check all values.",
  },
  editorTemplateIds: {
    defaultEmail: "5dda036e01b7027d8bcef485",
    challengeEmail: "5eab5e855296834d16ede523",
    defaultChallenge: "5eab52e75296834d16ede3e6",
    defaultMpChallenge: "620c81a2e069603fed9f0196",
    defaultMpChallengeItem: "5fb6d3bb5df28175b121e197",
    defaultMpUpsellItem: "633b2d5474541843966012a2",
    defaultMpKb: "6333702882bff5890698e7a7",
    defaultMpThankYou: "633b68f67454184396601e38",
    defaultLandingPage: "5e647fb972cbc45c4a27d515",
    salesLandingPage: "63584a025268ca61a7d22e02",
    optinLandingPage: "63ee7efac577ed93400e0cbc",
    thankYouLandingPage: "5f736e4e49e20c1859b5680d",
  },
  isDev: process.env.REACT_APP_IS_DEV,
  isAWSDev: process.env.REACT_APP_IS_AWS_DEV,
  draftInterval: 300000,
  showTutorial: true,
  tags: {
    resetPasswordTag: "system-reset-password",
    newMemberTag: "new member",
    fsMemberTag: "system-member",
    companyMemberTag: "company-member",
    preSurveyTag: "system-pre-survey",
    atqResponseTag: "system-atq-response",
    personalMessageTag: "system-personal-message",
    messageReceivedTag: "system-message-received",
    responseReceivedTag: "system-response-received",
    welcomeEmailTag: "welcome email",
    newUserTag: "system-new-user-details",
    blogOptin: "blog-optin",
    systemStaffRegistration: "system-staff-registration",
    universalGroup: "universal",
    comeFollowMe: "comefollowme",
    taskCreatedTag: "system-task-created",
    taskCommentTag: "system-task-comment",
    taskMentionTag: "system-task-mention",
    staffEventNotificatinTag: "system-staff-event-notification",
    systemEventRegistration: "system-event-registration",
    systemEventDayPrior: "system-event-1-day-prior",
    discussionBoardReply: "system-discussion-board-reply",
    discussionBoardTag: "system-discussion-board-tagged",
    cardAdded: "system-new-card-added",
    cardUpdated: "system-card-updated",
  },
  stripeApiKey: process.env.REACT_APP_STRIPE_KEY_PRIVATE,
  encryptionKey: "funandsustainablefitnesskey",
  timezones: [
    "America/New_York",
    "America/Chicago",
    "America/Denver",
    "America/Phoenix",
    "America/Los_Angeles",
    "America/Anchorage",
    "America/Adak",
    "Pacific/Honolulu",
    "Australia/Adelaide",
    "Australia/Brisbane",
    "Australia/Broken_Hill",
    "Australia/Currie",
    "Australia/Darwin",
    "Australia/Eucla",
    "Australia/Hobart",
    "Australia/Lindeman",
    "Australia/Lord_Howe",
    "Australia/Melbourne",
    "Australia/Perth",
    "Australia/Sydney",
    "Europe/Amsterdam",
    "Europe/Andorra",
    "Europe/Athens",
    "Europe/Belgrade",
    "Europe/Berlin",
    "Europe/Bratislava",
    "Europe/Brussels",
    "Europe/Bucharest",
    "Europe/Budapest",
    "Europe/Busingen",
    "Europe/Chisinau",
    "Europe/Copenhagen",
    "Europe/Dublin",
    "Europe/Gibraltar",
    "Europe/Guernsey",
    "Europe/Helsinki",
    "Europe/Isle_of_Man",
    "Europe/Istanbul",
    "Europe/Jersey",
    "Europe/Kaliningrad",
    "Europe/Kiev",
    "Europe/Lisbon",
    "Europe/Ljubljana",
    "Europe/London",
    "Europe/Luxembourg",
    "Europe/Madrid",
    "Europe/Malta",
    "Europe/Mariehamn",
    "Europe/Minsk",
    "Europe/Monaco",
    "Europe/Moscow",
    "Europe/Oslo",
    "Europe/Paris",
    "Europe/Podgorica",
    "Europe/Prague",
    "Europe/Riga",
    "Europe/Rome",
    "Europe/Samara",
    "Europe/San_Marino",
    "Europe/Sarajevo",
    "Europe/Simferopol",
    "Europe/Skopje",
    "Europe/Sofia",
    "Europe/Stockholm",
    "Europe/Tallinn",
    "Europe/Tirane",
    "Europe/Uzhgorod",
    "Europe/Vaduz",
    "Europe/Vatican",
    "Europe/Vienna",
    "Europe/Vilnius",
    "Europe/Volgograd",
    "Europe/Warsaw",
    "Europe/Zagreb",
    "Europe/Zaporozhye",
    "Europe/Zurich",
  ],
  countries: {
    AR: "Argentina",
    AU: "Australia",
    AT: "Austria",
    BE: "Belgium",
    CA: "Canada",
    CR: "Costa Rica",
    CY: "Cyprus",
    DK: "Denmark",
    DE: "Germany",
    HK: "Hong Kong",
    IN: "India",
    IL: "Israel",
    MU: "Mauritius",
    NZ: "New Zealand",
    PH: "Philippines",
    WS: "Samoa",
    ZA: "South Africa",
    SE: "Sweden",
    GB: "United Kingdom",
    US: "United States",
  },
  awsBuckets: {
    dev: {
      1: "https://mpa-testing.s3.amazonaws.com",
      2: "https://mpa-testing.s3.amazonaws.com",
      3: "https://mpa-testing.s3.amazonaws.com",
    },
    live: {
      1: "https://memberpages.s3.amazonaws.com",
      2: "https://progress-journal-live.s3.amazonaws.com",
      3: "https://fsf-resources.s3.amazonaws.com",
    },
  },
  appleProducts: [
    { id: "mpc5", amount: "$4.99" },
    { id: "mpc10", amount: "$9.99" },
    { id: "mpc15", amount: "$14.99" },
    { id: "mpc20", amount: "$19.99" },
    { id: "mpc25", amount: "$24.99" },
    { id: "mpc30", amount: "$29.99" },
    { id: "mpc35", amount: "$34.99" },
    { id: "mpc40", amount: "$39.99" },
    { id: "mpc45", amount: "$44.99" },
    { id: "mpc50", amount: "$49.99" },
    { id: "mpc75", amount: "$74.99" },
    { id: "mpc100", amount: "$99.99" },
    { id: "mpc150", amount: "$149.99" },
  ],
};
export default config;
