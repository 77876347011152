import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getExercises } from "../../../modules/actions/index";
import TitleBox from "../../../components/general/titleBox";

import FilterList from "../../../components/form/filterList";
import { filterByTags } from "../../../helpers/filters";

class ViewExercise extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      exercises: [],
      exercisesBackup: [],
      filterList: []
    };
  }

  componentDidMount() {
    this.props.getExercises();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.exercises.data) {
      this.setState({
        exercises: newProps.exercises.data,
        exercisesBackup: newProps.exercises.data
      });
    }
  }

  onClickExercise = exerciseItem => {
    this.props.history.push("/admin/add-exercise", {
      exerciseItem: exerciseItem
    });
  };

  renderExercises() {
    var returnVals = [];
    let items = this.state.exercises;
    items.forEach(element => {
      returnVals.push(
        <tr key={element._id}>
          <td>{element.name}</td>
          <td>{element.description}</td>
          <td>{element.notes}</td>
          <td>{element.video_list.length}</td>
          <td>
            {/*eslint-disable-next-line*/}
            <a
              onClick={this.onClickExercise.bind(this, element)}
              className="btn btn-default btn-sm border-0">
              <i className="fa fa-pencil" />
            </a>
          </td>
        </tr>
      );
    });
    return returnVals;
  }

  filterUpdated(updatedFilter, name, type, searchFields) {
    let filterList = this.state.filterList;
    filterList[name] = {
      name: name,
      type: type,
      searchFields: searchFields,
      values: updatedFilter
    };
    this.setState(
      {
        filterList: filterList
      },
      () => {
        let data = filterByTags(this.state.exercisesBackup, filterList);
        this.setState({
          exercises: data
        });
      }
    );
  }

  render() {
    return (
      <div className="container-fluid">
        <TitleBox title="Exercise Overview" />
        <div className="gap20" />
        <div className="card">
          <div className="list-group list-group-flush">
            <div className="list-group-item">
              <FilterList
                label={"Search Exercises"}
                name={"search"}
                type={"search"}
                searchFields={["name", "description", "notes"]}
                onFinish={this.filterUpdated.bind(this)}
              />
            </div>
            <div className="list-group-item">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Name </th>
                    <th>Description</th>
                    <th>Notes</th>
                    <th>Videos</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>{this.renderExercises()}</tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  exercises: state.customProgramReducer.exercises
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getExercises
    },
    dispatch
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewExercise);
