import React from "react";
import RadioTag from "../../../../components/form/radioTag";
import { callGetUsersByQuery } from "../../../../services";
import Checkbox from "../../../../components/form/checkBox";

class TaskSettingInformation extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    let taskFields = [
      {
        name: "Status",
        value: "status",
      },
      {
        name: "Priority",
        value: "priority",
      },
      {
        name: "Assigned",
        value: "assigned",
      },
      {
        name: "Created",
        value: "created",
      },
      {
        name: "Updated",
        value: "updated",
      },
      {
        name: "Due Date",
        value: "due_date",
      },
      {
        name: "Category",
        value: "category",
      },
      {
        name: "Bill",
        value: "incoming_invoice",
      },
      {
        name: "Invoice",
        value: "outgoing_invoice",
      },
    ];

    this.state = {
      defaultWatchers: this.props.defaultWatchers,
      keyWatcherRadio: Math.random(),
      hideTableFields: this.props.hideTableFields,
      keyHideTableFields: Math.random(),
      watcherList: [],
      pageTitle: this.props.pageTitle,
      taskName: this.props.taskName,
      addComment: this.props.addComment,
      linkForm: this.props.linkForm,
      linkInvoice: this.props.linkInvoice,
      showHistory: this.props.showHistory,
      keyLinkForm: Math.random(),
      keyLinkInvoice: Math.random(),
      keyHistory: Math.random(),
      taskFields,
    };
  }

  componentDidMount() {
    this.getData();
  }

  async getData() {
    await this.procecssUsers();
  }

  procecssUsers() {
    /*eslint-disable*/
    let query = {
      query: {
        member_type: "staff",
        status: { $ne: "archive" },
        $select: ["_id", "name"],
        populate: "no",
      },
    };
    /*eslint-enable*/

    callGetUsersByQuery(query).then((d) => {
      let fUsers = d.data;
      let watcherList = [];
      fUsers.forEach((element) => {
        watcherList.push({ name: element.name, value: element._id });
      });
      this.setState({
        watcherList: watcherList,
        keyWatcherRadio: Math.random(),
      });
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  onSelectWatcher(item, id) {
    let defaultWatchers = [];

    Object.keys(item).forEach((obj) => {
      defaultWatchers.push(item[obj].value);
    });

    this.setState({
      defaultWatchers: defaultWatchers,
    });
  }

  onSelectHideTableFields(item, id) {
    let hideTableFields = [];

    Object.keys(item).forEach((obj) => {
      hideTableFields.push(item[obj].value);
    });

    this.setState({
      hideTableFields: hideTableFields,
    });
  }

  toggleLinkForm = (check) => {
    this.setState({ linkForm: check });
  };

  toggleLinkInvoice = (check) => {
    this.setState({ linkInvoice: check });
  };

  toggleShowHistory = (check) => {
    this.setState({ showHistory: check });
  };

  onClickSaveInfo() {
    this.props.onClickSaveInfo({
      linkForm: this.state.linkForm,
      linkInvoice: this.state.linkInvoice,
      showHistory: this.state.showHistory,
      defaultWatchers: this.state.defaultWatchers,
      pageTitle: this.state.pageTitle,
      taskName: this.state.taskName,
      addComment: this.state.addComment,
      hideTableFields: this.state.hideTableFields,
    });
  }

  render() {
    return (
      <div className="card">
        <div className="container-fluid">
          <div className="gap10" />
          <p>Default Watchers</p>
          <div className="tabsHolder radioTabs">
            <RadioTag
              key={this.state.keyWatcherRadio}
              onCheckChange={this.onSelectWatcher.bind(this)}
              labelList={this.state.watcherList}
              type={"multiple"}
              id={"defaultWatchers"}
              selectedList={this.state.defaultWatchers}
              selectType={"value"}
            />
          </div>
          <div className="gap20" />
          <div className="row">
            <div className="col-sm-6">
              <div
                className={
                  this.state.pageTitle !== "" ? "mdInput mdFocussed" : "mdInput"
                }>
                <label>Page Title</label>
                <input
                  type="text"
                  name="pageTitle"
                  value={this.state.pageTitle}
                  onChange={(event) => this.handleInputChange(event)}
                />
              </div>
            </div>
          </div>
          <div className="gap20" />
          <div className="row">
            <div className="col-sm-6">
              <div
                className={
                  this.state.taskName !== "" ? "mdInput mdFocussed" : "mdInput"
                }>
                <label>Rename Task</label>
                <input
                  type="text"
                  name="taskName"
                  value={this.state.taskName}
                  onChange={(event) => this.handleInputChange(event)}
                />
              </div>
            </div>
          </div>
          <div className="gap20" />
          <div className="row">
            <div className="col-sm-6">
              <div
                className={
                  this.state.addComment !== ""
                    ? "mdInput mdFocussed"
                    : "mdInput"
                }>
                <label>Rename Add Comment</label>
                <input
                  type="text"
                  name="addComment"
                  value={this.state.addComment}
                  onChange={(event) => this.handleInputChange(event)}
                />
              </div>
            </div>
          </div>
          <div className="gap30" />
          <Checkbox
            label={"Allow Tasks to be linked to form"}
            isChecked={this.state.linkForm}
            handleCheckboxChange={this.toggleLinkForm}
            key={this.state.keyLinkForm}
          />
          <div className="gap20" />
          <Checkbox
            label={"Allow Tasks to be linked to invoice"}
            isChecked={this.state.linkInvoice}
            handleCheckboxChange={this.toggleLinkInvoice}
            key={this.state.keyLinkInvoice}
          />
          <div className="gap20" />
          <Checkbox
            label={"Show History"}
            isChecked={this.state.showHistory}
            handleCheckboxChange={this.toggleShowHistory}
            key={this.state.keyHistory}
          />
          <div className="gap30" />
          <p>Hide Fields</p>
          <div className="tabsHolder radioTabs">
            <RadioTag
              key={this.state.keyHideTableFields}
              onCheckChange={this.onSelectHideTableFields.bind(this)}
              labelList={this.state.taskFields}
              type={"multiple"}
              id={"hideTableFields"}
              selectedList={this.state.hideTableFields}
              selectType={"value"}
            />
          </div>
          <div className="gap20" />
          <div className="text-right">
            <button
              className="btn btn-primary"
              onClick={this.onClickSaveInfo.bind(this)}>
              Save
            </button>
            <div className="gap10" />
          </div>
        </div>
      </div>
    );
  }
}

export default TaskSettingInformation;
