import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment";

import FilterList from "../../../components/form/filterList";
import TitleBox from "../../../components/general/titleBox";
import { filterByTags } from "../../../helpers/filters";
import { callGetMemberPaymentsByQuery } from "../../../services/billingService";

class ViewMemberPayment extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      payments: [],
      paymentsBackup: [],
      filterList: [],
      dateFilters: [],
      keyFilter: Math.random()
    };
  }

  componentDidMount() {
    this.getPayments();
  }

  getPayments() {
    /*eslint-disable*/
    let query = {
      query: {
        status: "completed",
        $sort: { paid_date: -1 }
      }
    };
    /*eslint-enable*/
    callGetMemberPaymentsByQuery(query).then(d => {
      d.data.forEach(element => {
        element["user_name"] = element.member_info.name;
      });
      this.setState({
        payments: d.data,
        paymentsBackup: d.data
      });
      this.prepareDataForFilter(d.data);
    });
  }

  prepareDataForFilter(data) {
    let dateFilters = [
      {
        name: "All",
        value: "all",
        type: "selected",
        selected: true
      },
      {
        name: "Today",
        value: "-days-0",
        type: "selected",
        selected: false
      },
      {
        name: "Last 7 Days",
        value: "-days-7",
        type: "selected",
        selected: false
      },
      {
        name: "Last 30 Days",
        value: "-days-30",
        type: "selected",
        selected: false
      }
    ];

    this.setState({
      dateFilters: dateFilters,
      keyFilter: Math.random()
    });
  }

  filterUpdated(updatedFilter, name, type, searchFields) {
    let filterList = this.state.filterList;
    filterList[name] = {
      name: name,
      type: type,
      searchFields: searchFields,
      values: updatedFilter
    };
    this.setState(
      {
        filterList: filterList
      },
      () => {
        let data = filterByTags(this.state.paymentsBackup, filterList);
        this.setState({
          payments: data
        });
      }
    );
  }

  renderPayments() {
    let payments = this.state.payments;
    let returnVals = [];
    for (let i = 0; i < payments.length; i++) {
      const element = payments[i];
      returnVals.push(
        <tr key={i}>
          <td className="firstLetter">{element.member_info.name}</td>
          <td>${parseFloat(element.amount).toFixed(2)}</td>
          <td>{element.notes}</td>
          <td>{moment(element.paid_date).format("MMM, Do YYYY hh:mm A")}</td>
          <td>{moment(element.createdAt).format("MMM, Do YYYY hh:mm A")}</td>
        </tr>
      );
    }
    return returnVals;
  }

  renderFilterView() {
    return (
      <>
        <div className="list-group-item">
          <FilterList
            label={"Search Users"}
            name={"search"}
            type={"search"}
            searchFields={["user_name", "notes"]}
            onFinish={this.filterUpdated.bind(this)}
          />
        </div>
        <div className="list-group-item">
          <p>Filter by date</p>
          <FilterList
            key={this.state.keyFilter}
            filterList={this.state.dateFilters}
            name={"releaseDates"}
            type={"date"}
            searchFields={["paid_date"]}
            onFinish={this.filterUpdated.bind(this)}
          />
        </div>
      </>
    );
  }

  render() {
    return (
      <div>
        <div className="container-fluid">
          <TitleBox title="Member Payment Overview" />
          <div className="gap20" />
          <div className="card">
            <div className="list-group list-group-flush">
              {this.renderFilterView()}
              <div className="list-group-item">
                <div className="table-responsive">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Amount</th>
                        <th>Notes</th>
                        <th>Paid Date</th>
                        <th>Created</th>
                      </tr>
                    </thead>
                    <tbody>{this.renderPayments()}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ViewMemberPayment);
