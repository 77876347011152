import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { arrayMove } from "react-sortable-hoc";

import TitleBox from "../../../components/general/titleBox";
import RadioTag from "../../../components/form/radioTag";
import PageContent from "../../../components/editor/pageContent";

import { SortableVideoList } from "../../../components/form/sortableList";
import TagSelect from "../../../components/form/tagSelect";
import { showNotification, toPromise, slugify } from "../../../helpers";

import {
  callCreateMovement,
  callUpdateMovement,
  callCreateGenericTag,
  callGetGenericTags,
} from "../../../services";
import SelectAddImage from "../../../components/form/selectAddImage";
import HelpTextDiv from "../../../components/form/helpTextDiv";
import VideoPlayerNote from "../../../components/general/videoPlayerNote";

class AddMovement extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    let movementData = {
      movementId: 0,
      name: "",
      description: "",
      urlName: "",
      content: "",
      editorContent: {},
      videoList: [],
      imgUrl: "",
      selectedTags: [],
      tags: [],
      fTags: { new: [], existing: [] },
      keyTag: Math.random(),
    };

    if (this.props.history.location.state !== undefined) {
      let mData = this.props.history.location.state.movement;

      movementData.movementId = mData._id;
      movementData.name = mData.name;
      movementData.description = mData.description;
      movementData.urlName = mData.url_name;
      movementData.content = mData.content;
      movementData.editorContent = mData.editor_content;
      movementData.image = mData.image || "";
      movementData.imgUrl = mData.image || "";
      movementData.videoList = mData.video_list;
      movementData.selectedTags = mData.ftags || [];
    }

    this.state = {
      movementId: movementData.movementId,
      name: movementData.name,
      description: movementData.description,
      urlName: movementData.urlName,
      content: movementData.content,
      editorContent: movementData.editorContent,
      videoList: movementData.videoList,
      imgUrl: movementData.imgUrl,
      videoTitle: "",
      videoUrl: "",
      videoPlayer: "Youtube",
      selectedTags: movementData.selectedTags,
      tags: [],
      fTags: { new: [], existing: movementData.selectedTags },
      keyTag: movementData.keyTag,
      videoKey: Math.random(),
    };
  }

  componentDidMount() {
    callGetGenericTags("movement").then((d) => {
      this.setState({
        tags: d.data,
        keyTag: Math.random(),
      });
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  resetProps() {
    this.setState({
      movementId: 0,
      name: "",
      description: "",
      urlName: "",
      content: "",
      editorContent: {},
      videoList: [],
      imgUrl: "",
      videoTitle: "",
      videoUrl: "",
      videoPlayer: "Youtube",
    });
  }

  onChangeContent(html, content) {
    this.setState({
      editorContent: content,
      content: html,
    });
  }

  onSelectItem(item, id) {
    this.setState({
      [id]: item[Object.keys(item)[0]].value,
      videoKey: Math.random(),
    });
  }

  onSortEndVideos = ({ oldIndex, newIndex }) => {
    const { videoList } = this.state;

    this.setState({
      videoList: arrayMove(videoList, oldIndex, newIndex),
    });
  };

  onPressRemoveVideo(e) {
    let index = e.target.dataset["value"];
    let videoList = this.state.videoList;
    videoList.splice(index, 1);
    this.setState({
      videoList: videoList,
    });
  }

  onClickAddVideo() {
    let videos = this.state.videoList;

    if (this.state.videoTitle.trim().length === 0) {
      showNotification("error", "Video Title is required", 4000);
      return;
    }
    if (this.state.videoUrl.trim().length === 0) {
      showNotification("error", "Video ID is required", 4000);
      return;
    }
    if (this.state.videoPlayer.trim().length === 0) {
      showNotification("error", "Video Player is required", 4000);
      return;
    }

    videos.push({
      title: this.state.videoTitle,
      url: this.state.videoUrl,
      player: this.state.videoPlayer,
    });

    this.setState({
      videoList: videos,
      videoTitle: "",
      videoUrl: "",
      videoPlayer: "Youtube",
    });
  }

  onChangeTags = (tags) => {
    if (tags.length > 0) {
      let fTags = { new: [], existing: [] };
      tags.forEach((element) => {
        if (element.name) {
          fTags.existing.push(element);
        } else {
          fTags.new.push(element);
        }
      });
      this.setState({ fTags: fTags });
    }
  };

  async onPressAddMovement() {
    if (this.state.name.trim().length === 0) {
      showNotification("error", "Name is required", 4000);
      return;
    }

    if (this.state.description.trim().length === 0) {
      showNotification("error", "Description is required", 4000);
      return;
    }

    if (this.state.urlName.trim().length === 0) {
      showNotification("error", "Url Name is required", 4000);
      return;
    }

    if (this.state.content.trim().length === 0) {
      showNotification("error", "Content is required", 4000);
      return;
    }

    let tags = this.state.fTags;
    if (tags.new.length > 0) {
      let data = [];
      tags.new.forEach((element) => {
        data.push({ name: element, type: "movement" });
      });
      callCreateGenericTag(data).then((resp) => {
        let eTags = this.state.tags;
        this.setState({
          tags: eTags.concat(resp),
        });
        let newTagIds = resp.map((t) => t._id);
        let tagIds = tags.existing.map((t) => t._id);
        let finalTagIds = newTagIds.concat(tagIds);
        this.callMovementAPI(finalTagIds);
      });
    } else {
      let tagIds = tags.existing.map((t) => t._id);
      this.callMovementAPI(tagIds);
    }
  }

  async callMovementAPI(tagIds) {
    /*eslint-disable*/
    let apiData = {
      name: this.state.name,
      description: this.state.description,
      url_name: slugify(this.state.urlName),
      editor_content: this.state.editorContent,
      content: this.state.content,
      video_list: this.state.videoList,
      generic_tag_list: tagIds,
      image: this.state.imgUrl,
    };
    /*eslint-enable*/

    if (this.state.movementId === 0) {
      let [err, d] = await toPromise(callCreateMovement(apiData));
      this.handleResponse(err, d, 1);
    } else {
      let [err, d] = await toPromise(
        callUpdateMovement(this.state.movementId, apiData)
      );
      this.handleResponse(err, d, 2);
    }
  }

  async handleResponse(err, d, opt) {
    if (err) {
      if (err.code === 409) {
        showNotification("error", "Url name already exists", 4000);
      } else {
        showNotification("error", err.message, 4000);
      }
      return;
    }
    if (d._id) {
      if (opt === 1) {
        this.resetProps();
      }
    } else {
      showNotification("error", "Something went wrong", 4000);
    }
  }

  onChangeImage = (image) => {
    this.setState({ imgUrl: image });
  };

  render() {
    return (
      <div>
        <div className="container-fluid">
          <TitleBox
            title={
              this.state.movementId === 0 ? "Create Movement" : "Edit Movement"
            }
            showBackBtn={this.state.movementId !== 0}
          />
          <div className="gap20" />

          <div className="card">
            <div className="container-fluid">
              <div className="gap20" />

              <HelpTextDiv
                id="idMovementName"
                label="Learn more about the Name"
                type="addMovement"
                isInline="false"
              />

              <div
                className={
                  this.state.name !== "" ? "mdInput mdFocussed" : "mdInput"
                }>
                <label>Name</label>
                <input
                  className="challangeName"
                  name="name"
                  value={this.state.name}
                  onChange={(event) => this.handleInputChange(event)}
                />
              </div>
              <div className="gap20" />

              <HelpTextDiv
                id="idMovementDescription"
                label="Learn more about the Description"
                type="addMovement"
                isInline="false"
              />

              <div
                className={
                  this.state.description !== ""
                    ? "mdInput mdFocussed"
                    : "mdInput"
                }>
                <label>Description</label>
                <input
                  className="challangeDesc"
                  name="description"
                  value={this.state.description}
                  onChange={(event) => this.handleInputChange(event)}
                />
              </div>
              <div className="gap20" />

              <HelpTextDiv
                id="idMovementURL"
                label="Learn more about the URL"
                type="addMovement"
                isInline="false"
              />

              <div
                className={
                  this.state.urlName !== "" ? "mdInput mdFocussed" : "mdInput"
                }>
                <label>Url Name (unique)</label>
                <input
                  className="challengeName"
                  name="urlName"
                  value={this.state.urlName}
                  onChange={(event) => this.handleInputChange(event)}
                />
              </div>
              <div className="gap20" />
              <SelectAddImage
                label="Movement Image"
                onChangeImage={this.onChangeImage}
                image={this.state.imgUrl}
              />
              <div className="gap20" />
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <PageContent
            title={this.state.name}
            screen={"add-movement"}
            html={this.state.editorContent}
            content={this.state.content}
            onChangeData={this.onChangeContent.bind(this)}
            pageName={"addMovement"}
            onClickAlert={this.onPressAddMovement.bind(this)}
          />
        </div>

        <div className="container-fluid">
          <div className="card">
            <div className="container-fluid">
              <div className="gap20" />
              <h5>Add Videos</h5>
              <div className="gap20" />
              <div className="row">
                <div className="col-sm-4">
                  <HelpTextDiv
                    id="idMovementVideoTitle"
                    label="Learn more about the Video Title"
                    type="addMovement"
                    isInline="false"
                  />

                  <div
                    className={
                      this.state.videoTitle !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>Video Title</label>
                    <input
                      type="text"
                      className="videoUrl"
                      name="videoTitle"
                      value={this.state.videoTitle}
                      onChange={(event) => this.handleInputChange(event)}
                    />
                  </div>
                  <div className="gap20" />
                </div>
                <div className="col-sm-4">
                  <HelpTextDiv
                    id="idMovementVideoID"
                    label="Learn more about the Video ID"
                    type="addMovement"
                    isInline="false"
                  />

                  <div
                    className={
                      this.state.videoUrl !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>
                      {this.state.videoPlayer === "vooplayer"
                        ? "Video ID"
                        : "Video ID"}
                    </label>
                    <input
                      type="text"
                      className="videoUrl"
                      name="videoUrl"
                      value={this.state.videoUrl}
                      onChange={(event) => this.handleInputChange(event)}
                    />
                  </div>
                  <div className="gap20" />
                </div>
                <div className="col-sm-4">
                  <div className="havingHelpText">
                    <p>Video Player</p>
                  </div>
                  <HelpTextDiv
                    id="idMovementVideoPlayer"
                    label="Learn more about the Video Player"
                    type="addMovement"
                    isInline="true"
                  />
                  <div className="tabsHolder radioTabs">
                    <RadioTag
                      onCheckChange={this.onSelectItem.bind(this)}
                      labelList={[
                        { name: "YouTube", value: "Youtube" },
                        { name: "Vimeo", value: "Vimeo" },
                        { name: "Voo Player", value: "vooplayer" },
                      ]}
                      id={"videoPlayer"}
                      selectedList={[this.state.videoPlayer]}
                      selectType={"value"}
                    />
                  </div>
                  <div className="gap20" />
                  <div className="gap20" />
                </div>
              </div>
              <VideoPlayerNote
                videoPlayer={this.state.videoPlayer}
                key={this.state.videoKey}
              />
              <div
                className="text-right"
                onClick={() => this.onClickAddVideo()}>
                {/*eslint-disable-next-line*/}
                <a className="btn btn-primary addVideoButton">Add Video</a>
              </div>
              <div className="gap20" />
              <SortableVideoList
                items={this.state.videoList}
                onSortEnd={this.onSortEndVideos}
                onPressRemove={this.onPressRemoveVideo.bind(this)}
              />
              <div className="gap20" />
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="card">
            <div className="container-fluid">
              <div className="gap20" />
              <h5>Add Tag</h5>
              <div className="gap20" />

              <HelpTextDiv
                id="idMovementTags"
                label="Learn more about the Tags"
                type="addMovement"
                isInline="false"
              />

              <TagSelect
                id={"add-movement-add-tag"}
                key={this.state.keyTag}
                selectedValues={this.state.selectedTags}
                searchKey={"name"}
                selectKey={"name"}
                btnText={"Create New Tag"}
                lblText={"Type a Tag"}
                data={this.state.tags}
                onChange={this.onChangeTags}
              />
              <div className="gap20" />
            </div>
          </div>
          <div className="text-right" onClick={() => this.onPressAddMovement()}>
            <button className="btn btn-primary">
              {this.state.movementId === 0
                ? "Create Movement"
                : "Update Movement"}
            </button>
          </div>
          <div className="gap20" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddMovement);
