import React, { Component } from "react";
import { VelocityTransitionGroup } from "velocity-react";
import PubSub from "pubsub-js";

import ContentHtml from "../general/contentHtml";
import { setItem, getItem } from "../../helpers/storage";

var helpTextJson = require("../form/helpText.json");

class HelpTextDiv extends Component {
  constructor(props) {
    super(props);

    let id = this.props.id;
    let label = this.props.label;
    let isInline = this.props.isInline === "true" ? true : false;
    let showHelpDescription = false;
    let type = this.props.type;
    let topMargin = this.props.topMargin || 0;

    this.state = {
      id: id,
      type: type,
      label: label,
      isInline: isInline,
      showHelpDescription: showHelpDescription,
      helpTextOn: getItem("helpTextOn") || false,
      topMargin: topMargin
    };
  }

  componentDidMount() {
    this.subscribe = PubSub.subscribe("showHelpItems", (key, run) => {
      setItem("helpTextOn", true);
      this.setState({ helpTextOn: true });
    });

    this.subscribe = PubSub.subscribe("hideHelpItems", (key, run) => {
      setItem("helpTextOn", false);
      this.setState({ helpTextOn: false, showHelpDescription: false });
    });
  }

  componentWillUnmount() {
    PubSub.unsubscribe(this.subscribe);
  }

  onClickShowHelpDescription() {
    this.setState({
      showHelpDescription: true
    });
  }

  onClickHideHelpDescription() {
    this.setState({
      showHelpDescription: false
    });
  }

  setHelpTextOnTrue() {
    let helpTextOn = getItem("helpTextOn") || false;
    this.setState({
      helpTextOn: helpTextOn
    });
  }

  render() {
    let helpTextDesc = helpTextJson[0][this.state.type]
      ? helpTextJson[0][this.state.type][0][this.state.id] || ""
      : "";

    return (
      <div
        className={
          this.state.isInline && this.state.helpTextOn
            ? "havingHelpTextButton margin-left-10"
            : ""
        }
        style={{ marginTop: this.state.topMargin + "px" }}
      >
        {this.state.helpTextOn && helpTextDesc && (
          <button
            className="helpTextButtonStyle removeFocusDecoration"
            onClick={() =>
              this.state.showHelpDescription
                ? this.onClickHideHelpDescription()
                : this.onClickShowHelpDescription()
            }
          >
            {this.state.label}
          </button>
        )}
        <VelocityTransitionGroup
          enter={{ animation: "slideDown" }}
          leave={{ animation: "slideUp" }}
        >
          {this.state.showHelpDescription && (
            <div
              className={
                this.state.showHelpDescription
                  ? "helpTextDescriptionBox"
                  : "helpTextDescriptionBox hideHelpItemDescription"
              }
            >
              <ContentHtml html={helpTextDesc} type="inner" />
            </div>
          )}
        </VelocityTransitionGroup>
      </div>
    );
  }
}

export default HelpTextDiv;
