import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { saveAs } from "file-saver";
import { callGetSettings } from "../services/settingService";

class ExportContent extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      data: this.props.data,
      keys: this.props.keys,
      screenName: this.props.screen,
      showExport: false,
    };
  }

  componentDidMount() {
    callGetSettings("packagetype").then((d) => {
      let settings = d.data;
      let packageType = settings.package_type;
      if (packageType === "full") {
        this.setState({ showExport: true });
      }
    });
  }

  onClickExport() {
    let keys = this.state.keys;
    let rows = [];

    let headerMap = {
      "script_data.fbPixel": "facebookPixel",
      "script_data.googlePixel": "googlePixel",
      "script_data.purchasePixel": "purchasePixel",
      "script_data.pageTitle": "pageTitle",
      "script_data.socialMediaDesc": "socialMediaDescription",
      "script_data.socialMediaImage": "socialMediaImage",
      "script_data.leadPixel": "leadPixel",
    };

    this.state.data.forEach((element) => {
      let newElement = {};
      keys.forEach((key) => {
        let value = "";
        if (key.includes(".")) {
          var arrKey = key.split(".");
          if (element[arrKey[0]]) {
            value = element[arrKey[0]][arrKey[1]]
              ? element[arrKey[0]][arrKey[1]]
              : "";
          }
        } else {
          value = element[key] ? element[key] : "";
        }

        newElement[headerMap[key] || key] = value;
      });
      rows.push(newElement);
    });
    let json = { data: rows };
    var file = new File(
      [JSON.stringify(json)],
      `${this.state.screenName}.json`
    );
    saveAs(file);
  }

  render() {
    return (
      <>
        {this.state.showExport && (
          <div className="float-right">
            <button
              className="btn btn-sm btn-primary"
              onClick={this.onClickExport.bind(this)}>
              Export
            </button>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ExportContent);
