import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import DatePicker from "react-date-picker";
import { arrayMove } from "react-sortable-hoc";

import TitleBox from "../../../components/general/titleBox";
import TypeableSelect from "../../../components/form/typeableSelect";
import { SortableQuestionList } from "../../../components/form/sortableList";
import { getChlgQues } from "../../../modules/actions/index";
import { callUpdateCustomProgram } from "../../../services";
import { showNotification } from "../../../helpers";

class AddProgramPhase extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    let editMode = false;
    let customProgram = {};
    let phase = {
      id: 0,
      name: "",
      description: "",
      startDate: new Date(),
      endDate: new Date(),
      lockDate: new Date(),
      baseQuestionList: [],
    };

    if (this.props.history.location.state !== undefined) {
      customProgram = this.props.history.location.state.customProgram;
      let phaseInfo = this.props.history.location.state.phase;
      if (phaseInfo) {
        editMode = true;
        phase.id = phaseInfo.id;
        phase.name = phaseInfo.name;
        phase.description = phaseInfo.description;
        phase.startDate = new Date(phaseInfo.start_date);
        phase.endDate = new Date(phaseInfo.end_date);
        phase.lockDate = new Date(phaseInfo.unlock_date);

        let baseQuestionList = [];

        if (phaseInfo.base_question_list) {
          phaseInfo.base_question_list.forEach((element) => {
            let bInfo = customProgram.base_question_list_info.filter((d) => {
              return d._id === element;
            });
            if (bInfo.length > 0) {
              let qInfo = bInfo[0];
              baseQuestionList.push({
                name: qInfo.name,
                question: qInfo.question,
                description: qInfo.description,
                value: qInfo,
              });
            }
          });
        }

        phase.baseQuestionList = baseQuestionList;
      }
    }

    this.state = {
      editMode: editMode,
      customProgram: customProgram,
      phase: {
        id: phase.id,
        name: phase.name,
        description: phase.description,
        startDate: phase.startDate,
        endDate: phase.endDate,
        lockDate: phase.lockDate,
        baseQuestionList: phase.baseQuestionList,
      },
      questions: [],
      selectedQue: { value: "", label: "" },
      keyQuestion: Math.random(),
    };
  }

  componentDidMount() {
    this.props.getChlgQues();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.chlgQuestions.data) {
      let ques = newProps.chlgQuestions.data;
      let fQues = [];
      ques.forEach((element) => {
        fQues.push({ value: element, label: element.question });
      });

      this.setState({
        questions: fQues,
        keyQuestion: Math.random(),
      });
    }
  }

  handlePhaseInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    let phase = this.state.phase;
    phase[name] = value;

    this.setState({
      phase: phase,
    });
  }

  onChangeStartPhaseDate(value) {
    let phase = this.state.phase;
    phase["startDate"] = value;
    this.setState({
      phase: phase,
    });
  }

  onChangeEndPhaseDate(value) {
    let phase = this.state.phase;
    phase["endDate"] = value;
    this.setState({
      phase: phase,
    });
  }

  onChangeLockPhaseDate(value) {
    let phase = this.state.phase;
    phase["lockDate"] = value;
    this.setState({
      phase: phase,
    });
  }

  onSelectQuestion = (que) => {
    this.setState({ selectedQue: que });
  };

  onSortEndQues = ({ oldIndex, newIndex }) => {
    let phase = this.state.phase;
    phase.baseQuestionList = arrayMove(
      phase.baseQuestionList,
      oldIndex,
      newIndex
    );

    this.setState({
      phase: phase,
    });
  };

  onPressRemoveQue(e) {
    let index = e.target.dataset["value"];
    let phase = this.state.phase;
    phase.baseQuestionList.splice(index, 1);

    this.setState({
      phase: phase,
    });
  }

  onClickAddQuestion() {
    let phase = this.state.phase;
    if (!this.state.selectedQue.value.question) {
      showNotification("error", "Select the question first", 4000);
      return;
    }

    phase.baseQuestionList.push({
      name: this.state.selectedQue.value.name,
      question: this.state.selectedQue.value.question,
      description: this.state.selectedQue.value.description,
      value: this.state.selectedQue.value,
    });
    this.setState({
      phase: phase,
      selectedQue: { value: "", label: "" },
      keyQuestion: Math.random(),
    });
  }

  onClickAddPhase() {
    // call update custom program api
    let phase = this.state.phase;

    if (phase.name.trim().length === 0) {
      showNotification("error", "Phase name is required", 4000);
      return;
    }
    if (phase.description.trim().length === 0) {
      showNotification("error", "Phase description is required", 4000);
      return;
    }

    let queList = [];
    this.state.phase.baseQuestionList.forEach((element) => {
      queList.push(element.value._id);
    });

    if (this.state.editMode) {
      let phases = this.state.customProgram.phases;
      phases.forEach((element) => {
        if (element.id === this.state.phase.id) {
          element.id = this.state.phase.id;
          element.name = this.state.phase.name;
          element.description = this.state.phase.description;
          element["start_date"] = this.state.phase.startDate;
          element["end_date"] = this.state.phase.endDate;
          element["unlock_date"] = this.state.phase.lockDate;
          element["base_question_list"] = queList;
        }
      });
      callUpdateCustomProgram(this.state.customProgram._id, {
        phases: phases,
      }).then((d) => {
        this.props.history.goBack();
      });
    } else {
      /*eslint-disable*/
      let phaseData = {
        id: Math.random().toString(36).substring(7),
        name: this.state.phase.name,
        description: this.state.phase.description,
        start_date: this.state.phase.startDate,
        end_date: this.state.phase.endDate,
        unlock_date: this.state.phase.lockDate,
        base_question_list: queList,
      };
      /*eslint-enable*/

      callUpdateCustomProgram(this.state.customProgram._id, {
        $push: { phases: phaseData },
      }).then((d) => {
        this.props.history.goBack();
      });
    }
  }

  render() {
    return (
      <div>
        <div className="container-fluid">
          <TitleBox
            title={this.state.editMode ? "Edit Phase" : "Add Phase"}
            showBackBtn={true}
          />
          <div className="gap20" />

          <div className="card">
            <div className="container-fluid">
              <div
                className={
                  this.state.phase.name !== ""
                    ? "mdInput mdFocussed"
                    : "mdInput"
                }>
                <label>Name</label>
                <input
                  type="text"
                  name="name"
                  value={this.state.phase.name}
                  onChange={(event) => this.handlePhaseInputChange(event)}
                />
              </div>
              <div className="gap10" />
              <div
                className={
                  this.state.phase.description !== ""
                    ? "mdInput mdFocussed"
                    : "mdInput"
                }>
                <label>Description</label>
                <input
                  type="text"
                  name="description"
                  value={this.state.phase.description}
                  onChange={(event) => this.handlePhaseInputChange(event)}
                />
              </div>
              <div className="gap20" />
              <div className="row">
                <div className="col-md-4">
                  <label className="inputFakeLabelFocussed">Start Date</label>
                  <div>
                    <DatePicker
                      value={this.state.phase.startDate}
                      onChange={this.onChangeStartPhaseDate.bind(this)}
                      key={this.state.keyDate}
                    />
                  </div>
                  <div className="gap10" />
                </div>
                <div className="col-md-4">
                  <label className="inputFakeLabelFocussed">End Date</label>
                  <div>
                    <DatePicker
                      value={this.state.phase.endDate}
                      onChange={this.onChangeEndPhaseDate.bind(this)}
                    />
                  </div>
                  <div className="gap10" />
                </div>
                <div className="col-md-4">
                  <label className="inputFakeLabelFocussed">Unlock Date</label>
                  <div>
                    <DatePicker
                      value={this.state.phase.lockDate}
                      onChange={this.onChangeLockPhaseDate.bind(this)}
                    />
                  </div>
                  <div className="gap10" />
                </div>
              </div>
              <div className="gap20" />
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="card">
            <div className="container-fluid">
              <div className="gap20" />
              <h5 className="noMargin">Add Basic Accountability Questions</h5>
              <div className="gap20" />
              <TypeableSelect
                key={this.state.keyQuestion}
                name={"question"}
                placeholder={"Search Question"}
                selected={this.state.selectedQue}
                onSelect={this.onSelectQuestion}
                options={this.state.questions}
              />
              <div className="gap20" />
              <div
                className="text-right"
                onClick={() => this.onClickAddQuestion()}>
                {/*eslint-disable-next-line*/}
                <a className="btn btn-primary addQs">Add Question</a>
              </div>
              <div className="gap20" />
            </div>
          </div>
        </div>

        <div className="container-fluid">
          {this.state.phase.baseQuestionList.length > 0 && (
            <div className="card">
              <div className="container-fluid">
                <div className="gap20" />
                <h5 className="noMargin">Added Accountability Questions</h5>
                <div className="gap20" />
                <SortableQuestionList
                  items={this.state.phase.baseQuestionList}
                  onSortEnd={this.onSortEndQues}
                  showName={true}
                  onPressRemove={this.onPressRemoveQue.bind(this)}
                />
                <div className="gap20" />
              </div>
            </div>
          )}
          <div className="text-right" onClick={() => this.onClickAddPhase()}>
            {/*eslint-disable-next-line*/}
            <a className="btn btn-primary">
              {this.state.editMode ? "Update Phase" : "Add Phase"}
            </a>
          </div>
          <div className="gap20" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  chlgQuestions: state.challengeReducer.chlgQuestions,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getChlgQues,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AddProgramPhase);
