import { callApi } from "../helpers";
import * as api from "./api";

function callCreateHtmlSnippet(data) {
  return callApi(api.service("html-snippets").create(data));
}

function callUpdateHtmlSnippet(id, data) {
  return callApi(api.service("html-snippets").patch(id, data));
}

function callGetHtmlSnippets(query) {
  return callApi(api.service("html-snippets").find(query));
}

function callRemoveHtmlSnippet(id) {
  return callApi(api.service("html-snippets").remove(id));
}

function callGetRefreshAwsImages() {
  return callApi(api.service("general").get("refresh-aws-images"));
}

function callGetAdminApiCounts() {
  return callApi(api.service("general").get("get-admin-api-counts"));
}

function callCreateAwsImage(data, isLoading = true) {
  return callApi(api.service("aws-images").create(data), isLoading);
}

function callGetAwsImages(query, isLoading = true) {
  return callApi(api.service("aws-images").find(query), isLoading);
}

function callUpdateAwsImage(id, data) {
  return callApi(api.service("aws-images").patch(id, data));
}

function callCreatePopup(data) {
  return callApi(api.service("popups").create(data));
}

function callGetPopups() {
  return callApi(api.service("popups").find({}));
}

function callGetPopupsUsingQuery(query, isLoading = true) {
  return callApi(api.service("popups").find(query), isLoading);
}

function callGetPopupsById(id, isLoading = true) {
  return callApi(api.service("popups").get(id), isLoading);
}

function callUpdatePopup(id, data) {
  return callApi(api.service("popups").patch(id, data));
}

function callDeletePopupById(id) {
  return callApi(api.service("popups").remove(id));
}

function callCreateLandingPage(data) {
  return callApi(api.service("landing-page").create(data));
}

function callGetLandingPages() {
  return callApi(api.service("landing-page").find({}));
}

function callGetLandingPageById(id) {
  return callApi(api.service("landing-page").get(id));
}

function callGetLandingPagesUsingQuery(query, isLoading = true) {
  return callApi(api.service("landing-page").find(query), isLoading);
}

function callUpdateLandingPage(id, data) {
  return callApi(api.service("landing-page").patch(id, data));
}

function callDeleteLandingPageById(id) {
  return callApi(api.service("landing-page").remove(id));
}

function callCreateEventLog(data) {
  return callApi(api.service("event-log").create(data), false);
}

function callDeleteEventLogByQuery(query) {
  return callApi(api.service("event-log").remove(null, query), false);
}

function callCreateProcess(data) {
  return callApi(api.service("process").create(data));
}

function callGetProcesses() {
  return callApi(api.service("process").find({}));
}

function callGetProcessesUsingQuery(query, isLoading = true) {
  return callApi(api.service("process").find(query), isLoading);
}

function callUpdateProcess(id, data) {
  return callApi(api.service("process").patch(id, data));
}

function callDeleteProcessById(id) {
  return callApi(api.service("process").remove(id));
}

function callGetCancelServiceLog(id) {
  return callApi(api.service("cancel-service-log").get(id));
}

function callUpdateCancelServiceLog(id, data) {
  return callApi(api.service("cancel-service-log").patch(id, data));
}

function callCreateAwsFile(data, isLoading = true) {
  return callApi(api.service("aws-files").create(data), isLoading);
}

function callGetAwsFiles(query, isLoading = true) {
  return callApi(api.service("aws-files").find(query), isLoading);
}

function callDeleteAwsFile(id) {
  return callApi(api.service("aws-files").remove(id));
}

function callCreateHelpItem(data) {
  return callApi(api.service("help-item").create(data));
}

function callGetHelpItems() {
  return callApi(api.service("help-item").find({}));
}

function callGetHelpItemsUsingQuery(query, isLoading = true) {
  return callApi(api.service("help-item").find(query), isLoading);
}

function callUpdateHelpItem(id, data) {
  return callApi(api.service("help-item").patch(id, data));
}

function callDeleteHelpItemById(id) {
  return callApi(api.service("help-item").remove(id));
}

function callGetHelpItemsFromMemberPagesDb(query, isLoading = true) {
  return callApi(
    api.service("general").get("get-help-items", { query: query }),
    isLoading
  );
}

function callCreateOnboarding(data) {
  return callApi(api.service("onboarding").create(data));
}

function callGetOnboardings() {
  return callApi(api.service("onboarding").find({}));
}

function callGetOnboardingsUsingQuery(query, isLoading = true) {
  return callApi(api.service("onboarding").find(query), isLoading);
}

function callGetOnboardingById(id, isLoading = true) {
  return callApi(api.service("onboarding").get(id), isLoading);
}

function callUpdateOnboarding(id, data) {
  return callApi(api.service("onboarding").patch(id, data));
}

function callDeleteOnboarding(id) {
  return callApi(api.service("onboarding").remove(id));
}

function callCreateGeneralLog(data) {
  return callApi(api.service("general").create(data));
}

function callManageRules(data) {
  return callApi(api.service("general").create(data));
}

function callCreateDiscussionBoard(data) {
  return callApi(api.service("discussion-board").create(data));
}

function callUpdateDiscussionBoard(id, data) {
  return callApi(api.service("discussion-board").patch(id, data));
}

function callGetDiscussionBoards(query) {
  return callApi(api.service("discussion-board").find(query));
}

function callGetDiscussionBoardById(id) {
  return callApi(api.service("discussion-board").get(id));
}

function callRemoveDiscussionBoard(id) {
  return callApi(api.service("discussion-board").remove(id));
}

function callCreateContentEditorSection(data) {
  return callApi(api.service("content-editor-section").create(data));
}

function callGetContentEditorSectionById(id) {
  return callApi(api.service("content-editor-section").get(id));
}

function callUpdateContentEditorSection(id, data) {
  return callApi(api.service("content-editor-section").patch(id, data));
}

function callGetContentEditorSections(query = {}) {
  return callApi(api.service("content-editor-section").find(query));
}

function callDeleteContentEditorSectionById(id) {
  return callApi(api.service("content-editor-section").remove(id));
}

function callSearchImages(data) {
  return callApi(api.service("search-images").create(data));
}

function callUploadImageUrl(data) {
  return callApi(api.service("upload-image-url").create(data));
}

export {
  callGetDiscussionBoardById,
  callCreateDiscussionBoard,
  callUpdateDiscussionBoard,
  callGetDiscussionBoards,
  callRemoveDiscussionBoard,
  callCreateOnboarding,
  callGetOnboardings,
  callGetOnboardingsUsingQuery,
  callGetOnboardingById,
  callUpdateOnboarding,
  callDeleteOnboarding,
  callCreateHtmlSnippet,
  callUpdateHtmlSnippet,
  callGetHtmlSnippets,
  callRemoveHtmlSnippet,
  callCreateAwsImage,
  callGetRefreshAwsImages,
  callGetAwsImages,
  callUpdateAwsImage,
  callCreatePopup,
  callGetPopups,
  callUpdatePopup,
  callDeletePopupById,
  callGetPopupsUsingQuery,
  callCreateLandingPage,
  callGetLandingPages,
  callGetLandingPagesUsingQuery,
  callUpdateLandingPage,
  callDeleteLandingPageById,
  callCreateEventLog,
  callDeleteEventLogByQuery,
  callCreateProcess,
  callUpdateProcess,
  callGetProcesses,
  callGetProcessesUsingQuery,
  callDeleteProcessById,
  callUpdateCancelServiceLog,
  callGetCancelServiceLog,
  callGetPopupsById,
  callCreateAwsFile,
  callGetAwsFiles,
  callCreateHelpItem,
  callGetHelpItems,
  callGetHelpItemsUsingQuery,
  callUpdateHelpItem,
  callDeleteHelpItemById,
  callGetHelpItemsFromMemberPagesDb,
  callGetLandingPageById,
  callCreateGeneralLog,
  callManageRules,
  callDeleteAwsFile,
  callGetAdminApiCounts,
  callCreateContentEditorSection,
  callGetContentEditorSections,
  callDeleteContentEditorSectionById,
  callUpdateContentEditorSection,
  callGetContentEditorSectionById,
  callSearchImages,
  callUploadImageUrl,
};
