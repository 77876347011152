import React from "react";
import reactCSS from "reactcss";
import TrashSVG from "../../images/trash.svg";
import { getStorageItem } from "../../../../helpers/storage";

class MpColorPicker extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    let color = this.props.color || "#ffffff";
    let rawColor = "#ffffff";
    if (color) {
      rawColor = color.substr(0, 7);
    }
    let showOpacity = this.props.showOpacity;
    if (showOpacity === undefined) {
      showOpacity = true;
    }
    let et = getStorageItem("et");
    if (et === "email") {
      showOpacity = false;
    }
    this.state = {
      displayColorPicker: false,
      color: color,
      rawColor: rawColor,
      range: this.props.range || 1,
      showOpacity: showOpacity,
    };
  }

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
    this.props.onClose();
  };

  handleChange = (color) => {
    let nColor = `rgba(${color.r},${color.g},${color.b},${color.a})`;
    this.setState({ color: nColor });
    this.props.onChange(nColor);
  };

  onChangeColor = (event) => {
    let color = event.target.value;
    this.convertColor(color, this.state.range);
  };

  addAlpha(color, opacity) {
    const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
    return color + _opacity.toString(16).toUpperCase();
  }

  convertColor = (color, opacity) => {
    let newColor = color;
    if (this.state.showOpacity) {
      newColor = this.addAlpha(color, opacity);
    }
    this.setState({ color: newColor, rawColor: color });
    this.props.onChange(newColor);
  };

  onRemoveColor = () => {
    this.setState({ color: "#ffffff", range: 1 });
    this.props.onChange("transparent");
  };

  onChangeRange = (event) => {
    let value = event.target.value;
    this.convertColor(this.state.rawColor, value);
    this.setState({ range: value });
    if (this.props.onChangeRange) {
      this.props.onChangeRange(value);
    }
  };

  render() {
    const styles = reactCSS({
      default: {
        color: {
          width: "26px",
          height: "18px",
          borderRadius: "3px",
          background: `${this.state.color}`,
        },
        swatch: {
          padding: "0px",
          width: "30px",
          background: "#fff",
          borderRadius: "1px",
          display: "inline-block",
          cursor: "pointer",
        },
        popover: {
          position: "absolute",
          zIndex: "2",
          right: "0",
        },
        cover: {
          position: "fixed",
          top: "0px",
          right: "100px",
          bottom: "0px",
          left: "0px",
        },
        colorInput: {
          width: "30px",
          border: "none",
        },
      },
    });

    return (
      <div>
        <div className="alt-row">
          {this.state.showOpacity !== false && (
            <div className="alt-col-8">
              <input
                type="range"
                min="0"
                max="1"
                step="0.05"
                color="#2ae"
                value={this.state.range}
                onChange={this.onChangeRange}
              />
              <label className="opacitylabel">
                Opacity {(this.state.range * 100).toFixed(0)}%
              </label>
            </div>
          )}
          <div className="alt-col-2">
            <div style={styles.swatch}>
              <input
                type="color"
                value={this.state.rawColor}
                style={styles.colorInput}
                onChange={this.onChangeColor}
              />
            </div>
          </div>
          <div className="alt-col-2">
            <div className="cSpinBtn spinUp" onClick={this.onRemoveColor}>
              <img src={TrashSVG} alt="Trash" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MpColorPicker;
