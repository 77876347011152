import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import TitleBox from "../../../components/general/titleBox";
import { getParameterByName } from "../../../helpers";
import { callGetChlgById, callGetQuestionsUsingQuery } from "../../../services";

class ChallengeAtqRulesPage extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    let challengeId = getParameterByName("id");

    this.state = {
      challengeId: challengeId,
      challengeName: "",
      atqRules: [],
      keyName: Math.random(),
    };
  }

  componentDidMount() {
    if (this.state.challengeId) {
      this.getChallengeInfo(this.state.challengeId);
    }
  }

  async getChallengeInfo() {
    let challenge = await callGetChlgById(this.state.challengeId);
    if (challenge._id) {
      this.setState({ challengeName: challenge.name, keyName: Math.random() });
      if (challenge.atq_rules) {
        await this.prepareAtqRules(challenge.atq_rules);
      }
    }
  }

  async prepareAtqRules(rawRules) {
    let rules = rawRules || [];
    let eRules = [];

    for (let i = 0; i < rules.length; i++) {
      let element = rules[i];

      if (element.question_linked) {
        let response = await callGetQuestionsUsingQuery(
          {
            query: {
              _id: element.question_linked,
            },
          },
          false
        );
        if (response.data.length > 0) {
          element.questionInfo = response.data[0];
        }
      }
      eRules.push(element);
    }
    this.setState({ atqRules: eRules });
  }

  renderRules() {
    let returnVals = [];
    let rules = this.state.atqRules;
    /*eslint-disable*/
    let object = {
      at_signup: "At Signup",
      on_specific_day: "On Day",
    };
    /*eslint-enable*/
    rules.forEach((element) => {
      let questionInfo = element.questionInfo;
      returnVals.push(
        <div className="card">
          <div className="container-fluid">
            <div className="gap10" />
            <p className="mb5">
              <b>Rule :</b> {object[element.type]} {element.day}
            </p>
            <p className="mb5">
              <b>Tag Name :</b> {element.tag_name}
            </p>
            <p className="mb5">
              <b>Question Name :</b> {questionInfo.name}
            </p>
            <p className="mb5">
              <b>Group :</b> {questionInfo.group_info ? questionInfo.name : ""}
            </p>
            <p className="mb5">
              <b>Answer Type :</b> {questionInfo.answer_type}
            </p>
            <p className="mb5">
              <b>Answers :</b>
              <br />
              <div className="gap10" />
              {questionInfo.answer_list.map((i) => (
                <div className="card" style={{ marginBottom: 7 }}>
                  <div className="container-fluid">
                    <div className="gap10" />
                    <p className="mb5">
                      <b>Answer : </b> {i.answer}
                    </p>
                    <p className="mb5">
                      <b>Points : </b> {i.points}
                    </p>
                    <p className="mb5">
                      <b>On Form Submission : </b> {i.form_submission}
                    </p>
                    <div className="gap10" />
                  </div>
                </div>
              ))}
            </p>
          </div>
        </div>
      );
    });
    return returnVals;
  }

  render() {
    return (
      <div>
        <div className="container-fluid">
          <TitleBox
            key={this.state.keyName}
            title={`Challenge Accountability Rules - ${this.state.challengeName}`}
          />
          <div className="gap20" />
          {this.renderRules()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChallengeAtqRulesPage);
