import {
  RESET_STATUS,
  CREATE_EXERCISE_SUCCESS,
  CREATE_EXERCISE_FAILURE,
  UPDATE_EXERCISE_SUCCESS,
  UPDATE_EXERCISE_FAILURE,
  GET_EXERCISE,
  GET_MEMBER_PRODUCT,
  GET_CUSTOM_PROGRAM
} from "../types";

const initialState = {
  message: "",
  success: false,
  exercises: {},
  memberProducts: {},
  customPrograms: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RESET_STATUS:
      return {
        ...state,
        message: "",
        success: false
      };
    case CREATE_EXERCISE_SUCCESS:
      return {
        ...state,
        message: action.message,
        success: action.success
      };
    case CREATE_EXERCISE_FAILURE:
      return {
        ...state,
        message: action.message,
        success: action.success
      };
    case UPDATE_EXERCISE_SUCCESS:
      return {
        ...state,
        message: action.message,
        success: action.success
      };
    case UPDATE_EXERCISE_FAILURE:
      return {
        ...state,
        message: action.message,
        success: action.success
      };
    case GET_EXERCISE:
      return {
        ...state,
        exercises: action.data
      };
    case GET_MEMBER_PRODUCT:
      return {
        ...state,
        memberProducts: action.data
      };
    case GET_CUSTOM_PROGRAM:
      return {
        ...state,
        customPrograms: action.data
      };
    default:
      return state;
  }
};
