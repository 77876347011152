import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import SelectAddImage from "../../../components/form/selectAddImage";
import { SortableNavLinkList } from "../../../components/form/sortableList";
import { arrayMove } from "react-sortable-hoc/dist/commonjs/utils";
import { getParameterByName, showNotification } from "../../../helpers";
import Modal from "react-modal";
import ContentIcon from "../../../packages/mp-content-editor/components/general/icon";
import { icons } from "../../../packages/mp-content-editor/components/general/icons";
import {
  callGetSettings,
  callSaveSettings,
} from "../../../services/settingService";
import TitleBox from "../../../components/general/titleBox";
import RadioTag from "../../../components/form/radioTag";
import Checkbox from "../../../components/form/checkBox";
import {
  callGetEventsUsingQuery,
  callGetLandingPagesUsingQuery,
} from "../../../services";
import { DraftEditor } from "../../../packages/draft-editor";
import ColorPicker from "../../../components/form/colorPicker";

class MySitePage extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    let selectedTab = "details";

    let tab = getParameterByName("tab");
    if (tab) {
      selectedTab = tab;
    }

    let tabOptions = [
      { label: "Details", key: "details", selected: selectedTab === "details" },
      { label: "Menu List", key: "menu", selected: selectedTab === "menu" },
      {
        label: "Branding",
        key: "branding",
        selected: selectedTab === "branding",
      },
      { label: "Custom Tags", key: "tags", selected: selectedTab === "tags" },
      {
        label: "Social Media",
        key: "social-media",
        selected: selectedTab === "social-media",
      },
      {
        label: "Settings",
        key: "settings",
        selected: selectedTab === "settings",
      },
    ];

    this.state = {
      selectedTab,
      tabOptions: tabOptions,
      settingsData: this.props.settings,
      pageTitle: "",
      pageDescription: "",
      pageDescriptionObject: {},
      footerDescription: "",
      footerDescriptionObject: {},
      logoImage: "",
      keyLogoImage: Math.random(),
      backgroundImage: "",
      keyBackgroundImage: Math.random(),
      mobileBackgroundImage: "",
      keyMobileBackgroundImage: Math.random(),
      menuList: [],
      menuType: "submenu",
      subMenuType: "landingpage",
      menuUrl: "",
      subMenuLink: "",
      menuDescription: "",
      menuLabel: "",
      menuIcon: "",
      menuLandingPage: "",
      menuEvent: "",
      subMenus: [],
      subMenuLabel: "",
      subMenuDescription: "",
      subMenuUrl: "",
      sMenuType: "link",
      sSubMenuType: "landingpage",
      sMenuLandingPage: "",
      sMenuEvent: "",
      sSubMenuLink: "",
      subMenuIcon: "",
      settingId: "",
      iconModalOpen: false,
      selectedIcon: "",
      selectedSubIcon: "",
      modalType: "menu",
      secondaryColor: "",
      primaryColor: "",
      primaryHoverColor: "",
      selectedMenuIndex: -1,
      selectedMenuId: "",
      selectedSubMenuIndex: -1,
      selectedSubMenuId: "",
      domainList: [],
      accountKey: "",
      showSubMenu: false,
      headTags: "",
      socialMediaDescription: "",
      socialMediaImage: "",
      hideSliderArrows: false,
      keySliderArrows: Math.random(),
      landingPages: [],
      theme: "minimal",
      themes: ["sliding", "minimal"],
      events: [],
      boxCoverColor: "",
      box1Color: "",
      box2Color: "",
      box3Color: "",
      box4Color: "",
      box5Color: "",
      box6Color: "",
      logoBackColor: "",
      gridBackColor: "",
      keyEditor: Math.random(),
      keyMenuType: Math.random(),
      keySubMenuType: Math.random(),
    };
  }

  componentDidMount() {
    this.getSettings();
  }

  async getSettings() {
    let d = await callGetSettings("");

    /*eslint-disable*/
    let landingPages = await callGetLandingPagesUsingQuery({
      query: {
        $select: ["name", "_id", "url"],
        status: { $ne: "archive" },
        sub_id: "",
      },
    });
    /*eslint-enable*/
    this.setState({ landingPages: landingPages.data });

    let events = await callGetEventsUsingQuery({
      query: {
        $select: ["name", "_id", "event_id"],
        status: { $ne: "archive" },
      },
    });
    this.setState({ events: events.data });

    let data = d.data;
    if (data) {
      this.setState({
        settingId: data._id,
        domainList: data.business_details[0].domain_list || [],
        accountKey: data.business_details[0].unique_id || "",
      });
      let mySite = data.my_site;
      if (mySite) {
        this.setState({
          pageTitle: mySite.title,
          pageDescription: mySite.description || "",
          pageDescriptionObject: mySite.description_object,
          footerDescription: mySite.footer_description || "",
          footerDescriptionObject: mySite.footer_description_object,
          primaryColor: mySite.primary_color || "",
          primaryHoverColor: mySite.primary_hover_color || "",
          secondaryColor: mySite.secondary_color || "",
          boxCoverColor: mySite.box_cover_color || "#97000080",
          box1Color: mySite.box_1_color || "#00000000",
          box2Color: mySite.box_2_color || "#00000000",
          box3Color: mySite.box_3_color || "#00000000",
          box4Color: mySite.box_4_color || "#00000000",
          box5Color: mySite.box_5_color || "#00000000",
          box6Color: mySite.box_6_color || "#00000000",
          logoBackColor: mySite.logo_back_color || "#00000000",
          gridBackColor: mySite.grid_back_color || "#000000cc",
          logoImage: mySite.logo,
          backgroundImage: mySite.background,
          mobileBackgroundImage: mySite.mobile_background || "",
          menuList: mySite.menu_list || [],
          keyBackgroundImage: Math.random(),
          keyMobileBackgroundImage: Math.random(),
          keyLogoImage: Math.random(),
          headTags: mySite.head_tags || "",
          socialMediaDescription: mySite.social_media_description || "",
          socialMediaImage: mySite.social_media_image || "",
          hideSliderArrows: mySite.hide_slider_arrows,
          keySliderArrows: Math.random(),
          theme: mySite.theme || "minimal",
          keyEditor: Math.random(),
        });
      }
    }
  }

  handleInputChange(event) {
    const target = event.target;
    var value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  async onClickSave() {
    /*eslint-disable*/
    let data = {
      id: this.state.settingId,
      type: "my_site",
      my_site: {
        title: this.state.pageTitle,
        description: this.state.pageDescription,
        description_object: this.state.pageDescriptionObject,
        footer_description: this.state.footerDescription,
        footer_description_object: this.state.footerDescriptionObject,
        primary_color: this.state.primaryColor,
        primary_hover_color: this.state.primaryHoverColor,
        secondary_color: this.state.secondaryColor,
        box_cover_color: this.state.boxCoverColor,
        box_1_color: this.state.box1Color,
        box_2_color: this.state.box2Color,
        box_3_color: this.state.box3Color,
        box_4_color: this.state.box4Color,
        box_5_color: this.state.box5Color,
        box_6_color: this.state.box6Color,
        logo_back_color: this.state.logoBackColor,
        grid_back_color: this.state.gridBackColor,
        logo: this.state.logoImage,
        background: this.state.backgroundImage,
        mobile_background: this.state.mobileBackgroundImage,
        menu_list: this.state.menuList,
        head_tags: this.state.headTags,
        social_media_description: this.state.socialMediaDescription,
        social_media_image: this.state.socialMediaImage,
        hide_slider_arrows: this.state.hideSliderArrows,
        theme: this.state.theme,
      },
    };
    /*eslint-enable*/
    let updatedData = await callSaveSettings(data);
    if (updatedData.status) {
      showNotification("success", "Data updated", 3000);
    } else {
      showNotification("error", updatedData.message, 5000);
    }
  }

  onClickPreview() {
    let domainList = this.state.domainList;
    let urls = domainList.filter((d) => {
      return d.type === "default";
    });
    let url = `https://mysite.memberpages.app?key=${this.state.accountKey}`;
    if (urls.length > 0) {
      let subDomain = urls[0].domain.split(".");
      if (subDomain.length > 0) {
        url = `https://${subDomain[0]}.tini.app`;
      }
    }
    window.open(url, "_blank");
  }

  onChangeLogoImage = (image) => {
    this.setState({ logoImage: image });
  };

  onChangeSocialMediaImage = (image) => {
    this.setState({ socialMediaImage: image });
  };

  onChangeBackgroundImage = (image) => {
    this.setState({ backgroundImage: image });
  };

  onChangeMobileBackgroundImage = (image) => {
    this.setState({ mobileBackgroundImage: image });
  };

  onSortEndMenuList = ({ oldIndex, newIndex }) => {
    let menuList = this.state.menuList;
    menuList = arrayMove(menuList, oldIndex, newIndex);

    this.setState({
      menuList: menuList,
    });
  };

  onClickCancelUpdateMenu() {
    this.setState({
      selectedMenuIndex: -1,
      menuLabel: "",
      menuUrl: "",
      menuDescription: "",
      selectedIcon: "",
      selectedMenuId: "",
      subMenus: [],
      menuLandingPage: "",
      showSubMenu: false,
      menuType: "submenu",
      menuEvent: "",
      subMenuType: "landingpage",
      subMenuLink: "",
      keyMenuType: Math.random(),
    });
    this.onClickCancelUpdateSubMenu();
  }

  onPressEditMenu(type, e) {
    let index = e.target.dataset["value"];
    let menuList = this.state.menuList;

    let menu = menuList[index];

    this.setState({
      menuLabel: menu.label,
      menuUrl: menu.url || "",
      menuDescription: menu.description || "",
      selectedIcon: menu.icon,
      selectedMenuId: menu.id,
      selectedMenuIndex: index,
      subMenus: menu.subMenus,
      showSubMenu: type === "editSubmenu",
      menuType: menu.type || "submenu",
      menuLandingPage: menu.landingPage || "",
      menuEvent: menu.event || "",
      subMenuType: menu.subMenuType || "landingpage",
      subMenuLink: menu.landingPageUrl || "",
      keyMenuType: Math.random(),
    });
  }

  onPressRemoveMenu(e) {
    let index = e.target.dataset["value"];
    let menuList = this.state.menuList;
    menuList.splice(index, 1);
    this.setState({
      menuList: menuList,
    });
  }

  onClickAddMenu() {
    let menuList = this.state.menuList;

    if (this.state.menuLabel.trim().length === 0) {
      showNotification("error", "Label is required", 4000);
      return;
    }

    let id = "m" + Math.random().toString(36).substr(2);

    let field = {
      label: this.state.menuLabel,
      url: this.state.menuUrl,
      description: this.state.menuDescription,
      id: id,
      icon: this.state.selectedIcon,
      subMenus: this.state.subMenus,
      type: this.state.menuType,
      landingPage: this.state.menuLandingPage,
      subMenuType: this.state.subMenuType,
      landingPageUrl: this.state.subMenuLink,
      event: this.state.menuEvent,
    };

    if (this.state.selectedMenuIndex >= 0) {
      field.id = this.state.selectedMenuId;
      menuList[this.state.selectedMenuIndex] = field;
    } else {
      menuList.push(field);
    }

    this.setState({
      selectedMenuIndex: -1,
      selectedMenuId: "",
      menuList: menuList,
      menuLabel: "",
      menuUrl: "",
      menuDescription: "",
      selectedIcon: "",
      subMenus: [],
      showSubMenu: false,
      menuLandingPage: "",
      menuType: "submenu",
      subMenuType: "landingpage",
      subMenuLink: "",
      menuEvent: "",
      keyMenuType: Math.random(),
    });
  }

  onSortEndSubMenus = ({ oldIndex, newIndex }) => {
    let subMenus = this.state.subMenus;
    subMenus = arrayMove(subMenus, oldIndex, newIndex);

    this.setState({
      subMenus: subMenus,
    });
  };

  onPressRemoveSubMenu(e) {
    let index = e.target.dataset["value"];
    let subMenus = this.state.subMenus;
    subMenus.splice(index, 1);
    this.setState({
      subMenus: subMenus,
    });
  }

  onClickCancelUpdateSubMenu() {
    this.setState({
      subMenuLabel: "",
      subMenuDescription: "",
      subMenuUrl: "",
      selectedSubIcon: "",
      selectedSubMenuIndex: -1,
      selectedSubMenuId: "",
      sMenuType: "link",
      sMenuLandingPage: "",
      sMenuEvent: "",
      sSubMenuType: "landingpage",
      sSubMenuLink: "",
      keySubMenuType: Math.random(),
    });
  }

  onPressEditSubMenu(e) {
    let index = e.target.dataset["value"];
    let subMenus = this.state.subMenus;

    let subMenu = subMenus[index];

    this.setState({
      subMenuLabel: subMenu.label,
      subMenuDescription: subMenu.description || "",
      subMenuUrl: subMenu.url,
      selectedSubIcon: subMenu.icon,
      selectedSubMenuId: subMenu.id,
      selectedSubMenuIndex: index,
      sMenuType: subMenu.type || "link",
      sMenuLandingPage: subMenu.landingPage || "",
      sMenuEvent: subMenu.event || "",
      sSubMenuType: subMenu.subMenuType || "landingpage",
      sSubMenuLink: subMenu.landingPageUrl || "",
      keySubMenuType: Math.random(),
    });
  }

  onClickAddSubMenu() {
    let subMenus = this.state.subMenus;

    if (this.state.subMenuLabel.trim().length === 0) {
      showNotification("error", "Label is required", 4000);
      return;
    }

    if (
      this.state.sMenuType === "link" &&
      this.state.subMenuUrl.trim().length === 0
    ) {
      showNotification("error", "Url is required", 4000);
      return;
    }

    let id = "sm" + Math.random().toString(36).substr(2);

    let field = {
      label: this.state.subMenuLabel,
      description: this.state.subMenuDescription,
      url: this.state.subMenuUrl,
      icon: this.state.selectedSubIcon,
      id: id,
      type: this.state.sMenuType,
      landingPage: this.state.sMenuLandingPage,
      event: this.state.sMenuEvent,
      subMenuType: this.state.sSubMenuType,
      landingPageUrl: this.state.sSubMenuLink,
    };

    if (this.state.selectedSubMenuIndex >= 0) {
      field.id = this.state.selectedSubMenuId;
      subMenus[this.state.selectedSubMenuIndex] = field;
    } else {
      subMenus.push(field);
    }

    this.setState({
      subMenus: subMenus,
      subMenuLabel: "",
      subMenuDescription: "",
      subMenuUrl: "",
      selectedSubIcon: "",
      selectedSubMenuIndex: -1,
      selectedSubMenuId: "",
      sMenuType: "link",
      sMenuLandingPage: "",
      sSubMenuType: "landingpage",
      sSubMenuLink: "",
      sMenuEvent: "",
      keySubMenuType: Math.random(),
    });
  }

  onSelectSubMenuType(item, id) {
    this.setState({ [id]: item[Object.keys(item)[0]].value });
  }

  onClickTab(tab) {
    let tabOptions = this.state.tabOptions;
    tabOptions.forEach((element) => {
      element.selected = element.key === tab;
    });
    this.setState({
      tabOptions,
      selectedTab: tab,
    });
  }

  renderTabOptions() {
    let tabOptions = this.state.tabOptions;
    let returnVals = [];
    for (let i = 0; i < tabOptions.length; i++) {
      const element = tabOptions[i];
      const dataTab = `.for-tab${i + 1}`;
      /*eslint-disable*/
      returnVals.push(
        <a
          className={element.selected ? "active" : ""}
          onClick={() => this.onClickTab(element.key)}
          data-for={dataTab}>
          {element.label}
        </a>
      );
      /*eslint-enable*/
    }
    return returnVals;
  }

  onChangeDescriptionValue = (html, json) => {
    this.setState({ pageDescription: html, pageDescriptionObject: json });
  };

  onChangeFooterDescriptionValue = (html, json) => {
    this.setState({ footerDescription: html, footerDescriptionObject: json });
  };

  renderDetailsTab() {
    return (
      <>
        <div
          className={
            this.state.pageTitle !== "" ? "mdInput mdFocussed" : "mdInput"
          }>
          <label>Page Title</label>
          <input
            type="text"
            name="pageTitle"
            value={this.state.pageTitle}
            onChange={(event) => this.handleInputChange(event)}
          />
        </div>
        <div className="gap20" />
        <>
          <div className="inputFakeLabelFocussed">
            <p>Top Text</p>
          </div>

          <div className="add-task-comment">
            <DraftEditor
              key={this.state.keyEditor}
              cType="task"
              defaultText={this.state.pageDescription}
              lineHeight={"inherit"}
              textAlign={"left"}
              html={this.state.pageDescription}
              json={this.state.pageDescriptionObject}
              onChange={this.onChangeDescriptionValue.bind(this)}
            />
          </div>
          <div className="gap20" />
        </>
        <>
          <div className="inputFakeLabelFocussed">
            <p>Bottom Text</p>
          </div>

          <div className="add-task-comment">
            <DraftEditor
              key={this.state.keyEditor}
              cType="task"
              defaultText={this.state.footerDescription}
              lineHeight={"inherit"}
              textAlign={"left"}
              html={this.state.footerDescription}
              json={this.state.footerDescriptionObject}
              onChange={this.onChangeFooterDescriptionValue.bind(this)}
            />
          </div>
          <div className="gap20" />
        </>
      </>
    );
  }

  renderMenuListTab() {
    return (
      <>
        <div className="gap20" />
        <h5>Menu List</h5>
        <div className="gap10" />
        {!this.state.showSubMenu && (
          <>
            <div className="row">
              <div className="col-md-6">
                <div
                  className={
                    this.state.menuLabel !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Menu Label</label>
                  <input
                    type="text"
                    name="menuLabel"
                    maxLength={50}
                    value={this.state.menuLabel}
                    onChange={(event) => this.handleInputChange(event)}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div
                  className={
                    this.state.menuDescription !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Menu Description</label>
                  <input
                    type="text"
                    name="menuDescription"
                    value={this.state.menuDescription}
                    onChange={(event) => this.handleInputChange(event)}
                  />
                </div>
              </div>
            </div>
            <div className="gap10" />
            <div className="radioBHolder">
              <p>Type</p>
              <div className="tabsHolder radioTabs">
                <RadioTag
                  key={this.state.keyMenuType}
                  onCheckChange={this.onSelectSubMenuType.bind(this)}
                  labelList={[
                    { name: "Sub Menu", value: "submenu" },
                    { name: "Direct Link", value: "link" },
                    { name: "Tini Site Page", value: "landingpage" },
                  ]}
                  id={"menuType"}
                  selectedList={[this.state.menuType]}
                  selectType={"value"}
                />
              </div>
            </div>
            {this.state.menuType === "link" && (
              <div className="row">
                <div className="col-md-6">
                  <div
                    className={
                      this.state.menuUrl !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>Menu Url</label>
                    <input
                      type="text"
                      name="menuUrl"
                      value={this.state.menuUrl}
                      onChange={(event) => this.handleInputChange(event)}
                    />
                  </div>
                </div>
              </div>
            )}
            {this.state.menuType === "landingpage" && (
              <>
                <div className="gap20" />
                <div className="radioBHolder">
                  <p>Sub Type</p>
                  <div className="tabsHolder radioTabs">
                    <RadioTag
                      onCheckChange={this.onSelectSubMenuType.bind(this)}
                      labelList={[
                        { name: "Landing Page", value: "landingpage" },
                        { name: "Event", value: "event" },
                        { name: "URL", value: "url" },
                      ]}
                      id={"subMenuType"}
                      selectedList={[this.state.subMenuType]}
                      selectType={"value"}
                    />
                  </div>
                </div>
                {this.state.subMenuType === "landingpage" && (
                  <div className="row">
                    <div className="col-md-6">
                      <div
                        className={
                          this.state.menuLandingPage !== ""
                            ? "mdInput mdFocussed"
                            : "mdInput"
                        }>
                        <label>Select Landing Page</label>
                        <select
                          name="menuLandingPage"
                          value={this.state.menuLandingPage}
                          onChange={(event) => this.handleInputChange(event)}>
                          <option value=""></option>
                          {this.state.landingPages.map((i) => (
                            <option value={i.url} key={i.url}>
                              {i.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                )}
                {this.state.subMenuType === "event" && (
                  <div className="row">
                    <div className="col-md-6">
                      <div
                        className={
                          this.state.menuEvent !== ""
                            ? "mdInput mdFocussed"
                            : "mdInput"
                        }>
                        <label>Select Event</label>
                        <select
                          name="menuEvent"
                          value={this.state.menuEvent}
                          onChange={(event) => this.handleInputChange(event)}>
                          <option value=""></option>
                          {this.state.events.map((i) => (
                            <option value={i.event_id} key={i._id}>
                              {i.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                )}
                {this.state.subMenuType === "url" && (
                  <div className="row">
                    <div className="col-md-6">
                      <div
                        className={
                          this.state.subMenuLink !== ""
                            ? "mdInput mdFocussed"
                            : "mdInput"
                        }>
                        <label>Url</label>
                        <input
                          type="text"
                          name="subMenuLink"
                          value={this.state.subMenuLink}
                          onChange={(event) => this.handleInputChange(event)}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
            <div className="gap20" />
            <div className="row">
              <div className="col-md-6">
                <button
                  className="btn btn-default"
                  onClick={() => {
                    this.setState({
                      iconModalOpen: true,
                      modalType: "menu",
                    });
                  }}>
                  {this.state.selectedIcon ? (
                    <ContentIcon
                      name={this.state.selectedIcon}
                      width="50"
                      color="black"
                      key={this.state.selectedIcon}
                    />
                  ) : (
                    ""
                  )}
                  Select Icon
                </button>
              </div>
            </div>
          </>
        )}

        <div className="gap20" />
        {this.state.showSubMenu && (
          <div className="card">
            <div className="container-fluid">
              <div className="gap20" />
              <h5>Add Sub Menu</h5>
              <div className="gap10" />
              <div className="row">
                <div className="col-md-6">
                  <div
                    className={
                      this.state.subMenuLabel !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>SubMenu Label</label>
                    <input
                      type="text"
                      name="subMenuLabel"
                      maxLength={50}
                      value={this.state.subMenuLabel}
                      onChange={(event) => this.handleInputChange(event)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div
                    className={
                      this.state.subMenuDescription !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>SubMenu Description</label>
                    <input
                      type="text"
                      name="subMenuDescription"
                      value={this.state.subMenuDescription}
                      onChange={(event) => this.handleInputChange(event)}
                    />
                  </div>
                </div>
              </div>

              <div className="gap20" />
              <div className="radioBHolder">
                <p>Type</p>
                <div className="tabsHolder radioTabs">
                  <RadioTag
                    key={this.state.keySubMenuType}
                    onCheckChange={this.onSelectSubMenuType.bind(this)}
                    labelList={[
                      { name: "Direct Link", value: "link" },
                      { name: "Tini Site Page", value: "landingpage" },
                    ]}
                    id={"sMenuType"}
                    selectedList={[this.state.sMenuType]}
                    selectType={"value"}
                  />
                </div>
              </div>
              {this.state.sMenuType === "link" && (
                <div className="row">
                  <div className="col-md-6">
                    <div
                      className={
                        this.state.subMenuUrl !== ""
                          ? "mdInput mdFocussed"
                          : "mdInput"
                      }>
                      <label>SubMenu Url</label>
                      <input
                        type="text"
                        name="subMenuUrl"
                        value={this.state.subMenuUrl}
                        onChange={(event) => this.handleInputChange(event)}
                      />
                    </div>
                  </div>
                </div>
              )}
              {this.state.sMenuType === "landingpage" && (
                <>
                  <div className="gap20" />
                  <div className="radioBHolder">
                    <p>Sub Type</p>
                    <div className="tabsHolder radioTabs">
                      <RadioTag
                        onCheckChange={this.onSelectSubMenuType.bind(this)}
                        labelList={[
                          { name: "Landing Page", value: "landingpage" },
                          { name: "Event", value: "event" },
                          { name: "URL", value: "url" },
                        ]}
                        id={"sSubMenuType"}
                        selectedList={[this.state.sSubMenuType]}
                        selectType={"value"}
                      />
                    </div>
                  </div>
                  {this.state.sSubMenuType === "landingpage" && (
                    <div className="row">
                      <div className="col-md-6">
                        <div
                          className={
                            this.state.sMenuLandingPage !== ""
                              ? "mdInput mdFocussed"
                              : "mdInput"
                          }>
                          <label>Select Landing Page</label>
                          <select
                            name="sMenuLandingPage"
                            value={this.state.sMenuLandingPage}
                            onChange={(event) => this.handleInputChange(event)}>
                            <option value=""></option>
                            {this.state.landingPages.map((i) => (
                              <option value={i.url} key={i.url}>
                                {i.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.sSubMenuType === "event" && (
                    <div className="row">
                      <div className="col-md-6">
                        <div
                          className={
                            this.state.sMenuEvent !== ""
                              ? "mdInput mdFocussed"
                              : "mdInput"
                          }>
                          <label>Select Event</label>
                          <select
                            name="sMenuEvent"
                            value={this.state.sMenuEvent}
                            onChange={(event) => this.handleInputChange(event)}>
                            <option value=""></option>
                            {this.state.events.map((i) => (
                              <option value={i.event_id} key={i._id}>
                                {i.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.sSubMenuType === "url" && (
                    <div className="row">
                      <div className="col-md-6">
                        <div
                          className={
                            this.state.sSubMenuLink !== ""
                              ? "mdInput mdFocussed"
                              : "mdInput"
                          }>
                          <label>Url</label>
                          <input
                            type="text"
                            name="sSubMenuLink"
                            value={this.state.sSubMenuLink}
                            onChange={(event) => this.handleInputChange(event)}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}

              <div className="row">
                <div className="col-md-6">
                  <div className="gap20" />
                  <button
                    className="btn btn-default"
                    onClick={() => {
                      this.setState({
                        iconModalOpen: true,
                        modalType: "submenu",
                      });
                    }}>
                    {this.state.selectedSubIcon ? (
                      <ContentIcon
                        name={this.state.selectedSubIcon}
                        width="50"
                        color="black"
                        key={this.state.selectedSubIcon}
                      />
                    ) : (
                      ""
                    )}
                    Select Icon
                  </button>
                </div>
              </div>
              <div className="gap20" />
              <div className="text-right">
                {this.state.selectedSubMenuIndex > -1 && (
                  <button
                    className="btn btn-default margin-right-10"
                    onClick={this.onClickCancelUpdateSubMenu.bind(this)}>
                    Cancel
                  </button>
                )}
                <button
                  className="btn btn-primary"
                  onClick={this.onClickAddSubMenu.bind(this)}>
                  {this.state.selectedSubMenuIndex > -1
                    ? "Update Sub Menu"
                    : "Add Sub Menu"}
                </button>
                <div className="gap20" />
              </div>
              <SortableNavLinkList
                distance={10}
                items={this.state.subMenus}
                onSortEnd={this.onSortEndSubMenus}
                onPressEdit={this.onPressEditSubMenu.bind(this)}
                onPressRemove={this.onPressRemoveSubMenu.bind(this)}
              />
              <div className="gap20" />
            </div>
          </div>
        )}

        <div className="gap20" />
        <div className="text-right">
          {this.state.selectedMenuIndex > -1 && (
            <button
              className="btn btn-default margin-right-10"
              onClick={this.onClickCancelUpdateMenu.bind(this)}>
              Cancel
            </button>
          )}
          <button
            className="btn btn-primary"
            onClick={this.onClickAddMenu.bind(this)}>
            {this.state.selectedMenuIndex > -1 ? "Update Menu" : "Add Menu"}
          </button>
          <div className="gap20" />
        </div>
        <SortableNavLinkList
          distance={10}
          type={"menu"}
          items={this.state.menuList}
          onSortEnd={this.onSortEndMenuList}
          onPressEdit={this.onPressEditMenu.bind(this, "edit")}
          onPressEditSubMenu={this.onPressEditMenu.bind(this, "editSubmenu")}
          onPressRemove={this.onPressRemoveMenu.bind(this)}
        />
        <div className="gap20" />
      </>
    );
  }

  renderBrandingTab() {
    return (
      <>
        <div className="gap20" />
        <SelectAddImage
          type="site-app-logo"
          backColor={"white"}
          key={this.state.keyLogoImage}
          label="Site Logo"
          onChangeImage={this.onChangeLogoImage}
          image={this.state.logoImage}
        />
        <label>(Recommended max height of 150 px)</label>
        <div className="gap20" />
        <SelectAddImage
          type="site-background-logo"
          backColor={"white"}
          key={this.state.keyBackgroundImage}
          label="Background Image"
          onChangeImage={this.onChangeBackgroundImage}
          image={this.state.backgroundImage}
        />
        <label>(Recommended size of 1920 x 1080 px)</label>
        <div className="gap20" />
        <SelectAddImage
          type="site-mobile-background-logo"
          backColor={"white"}
          key={this.state.keyMobileBackgroundImage}
          label="Mobile Background Image"
          onChangeImage={this.onChangeMobileBackgroundImage}
          image={this.state.mobileBackgroundImage}
        />
        <label>(Recommended size of 400 x 700 px)</label>
        <div className="gap30" />

        <div className="row">
          <div className="col-sm-4">
            <ColorPicker
              label="Primary Color"
              color={this.state.primaryColor}
              opacity={true}
              onChange={(color) => {
                this.setState({ primaryColor: color });
              }}
            />
            <div className="gap10" />
            <ColorPicker
              label="Primary Hover Color"
              color={this.state.primaryHoverColor}
              opacity={true}
              onChange={(color) => {
                this.setState({ primaryHoverColor: color });
              }}
            />
            <div className="gap10" />
            <ColorPicker
              label="Secondary Color"
              color={this.state.secondaryColor}
              opacity={true}
              onChange={(color) => {
                this.setState({ secondaryColor: color });
              }}
            />
            <ColorPicker
              label="Logo Background Color"
              color={this.state.logoBackColor}
              opacity={true}
              onChange={(color) => {
                this.setState({ logoBackColor: color });
              }}
            />
            <div className="gap10" />
            <ColorPicker
              label="Grid Background Color"
              color={this.state.gridBackColor}
              opacity={true}
              onChange={(color) => {
                this.setState({ gridBackColor: color });
              }}
            />
          </div>
          <div className="col-sm-4">
            <ColorPicker
              label="Box Hover"
              color={this.state.boxCoverColor}
              opacity={true}
              onChange={(color) => {
                this.setState({ boxCoverColor: color });
              }}
            />
            <div className="gap10" />
            <ColorPicker
              label="Box 1 Color"
              color={this.state.box1Color}
              opacity={true}
              onChange={(color) => {
                this.setState({ box1Color: color });
              }}
            />
            <div className="gap10" />
            <ColorPicker
              label="Box 2 Color"
              color={this.state.box2Color}
              opacity={true}
              onChange={(color) => {
                this.setState({ box2Color: color });
              }}
            />
            <div className="gap10" />

            <ColorPicker
              label="Box 3 Color"
              color={this.state.box3Color}
              opacity={true}
              onChange={(color) => {
                this.setState({ box3Color: color });
              }}
            />
            <div className="gap10" />
            <ColorPicker
              label="Box 4 Color"
              color={this.state.box4Color}
              opacity={true}
              onChange={(color) => {
                this.setState({ box4Color: color });
              }}
            />
            <div className="gap10" />
          </div>
          <div className="col-sm-4">
            <ColorPicker
              label="Box 5 Color"
              color={this.state.box5Color}
              opacity={true}
              onChange={(color) => {
                this.setState({ box5Color: color });
              }}
            />
            <div className="gap10" />
            <ColorPicker
              label="Box 6 Color"
              color={this.state.box6Color}
              opacity={true}
              onChange={(color) => {
                this.setState({ box6Color: color });
              }}
            />
          </div>
        </div>
      </>
    );
  }

  renderCustomTagsTab() {
    return (
      <>
        <div
          className={
            this.state.headTags !== "" ? "mdInput mdFocussed" : "mdInput"
          }>
          <label>Head Tags</label>
          <textarea
            type="text"
            name="headTags"
            value={this.state.headTags}
            onChange={(event) => this.handleInputChange(event)}
          />
        </div>
        <div className="gap20" />
      </>
    );
  }

  renderSocialMediaTab() {
    return (
      <>
        <div
          className={
            this.state.socialMediaDescription !== ""
              ? "mdInput mdFocussed"
              : "mdInput"
          }>
          <label>Description</label>
          <input
            type="text"
            name="socialMediaDescription"
            value={this.state.socialMediaDescription}
            onChange={(event) => this.handleInputChange(event)}
          />
        </div>
        <div className="gap20" />
        <SelectAddImage
          type="social-image-app-logo"
          backColor={"white"}
          key={this.state.keyLogoImage}
          label="Image"
          onChangeImage={this.onChangeSocialMediaImage}
          image={this.state.socialMediaImage}
        />
        <div className="gap20" />
      </>
    );
  }

  toggleSliderArrows = (check) => {
    this.setState({ hideSliderArrows: check });
  };

  renderSettingsTab() {
    return (
      <>
        <div className="gap20" />
        <Checkbox
          label={"Show slider arrows"}
          isChecked={this.state.hideSliderArrows}
          handleCheckboxChange={this.toggleSliderArrows}
          key={this.state.keySliderArrows}
        />
        <div className="gap20" />
        <div
          className={
            this.state.theme !== "" ? "mdInput mdFocussed" : "mdInput"
          }>
          <label>Select Theme</label>
          <select
            name="theme"
            value={this.state.theme}
            onChange={(event) => this.handleInputChange(event)}>
            {this.state.themes.map((i) => (
              <option value={i} key={i}>
                {i}
              </option>
            ))}
          </select>
        </div>
        <div className="gap20" />
      </>
    );
  }

  render() {
    const customStyles = {
      overlay: { zIndex: 20000 },
      content: {
        top: "10%",
        left: "35%",
        right: "35%",
        bottom: "30%",
      },
    };
    return (
      <div className="container-fluid">
        <TitleBox title="Tini Site" />
        <Modal
          isOpen={this.state.iconModalOpen}
          contentLabel="Icon Elem Modal"
          style={customStyles}
          shouldCloseOnOverlayClick={true}
          onRequestClose={() => {
            this.setState({ iconModalOpen: false });
          }}>
          <div className="alt-row">
            {Object.keys(icons).map((i) => (
              <div
                className="alt-col-2"
                key={i}
                onClick={() => {
                  if (this.state.modalType === "menu") {
                    this.setState({ selectedIcon: i, iconModalOpen: false });
                  } else {
                    this.setState({ selectedSubIcon: i, iconModalOpen: false });
                  }
                }}
                style={{ padding: 10, cursor: "pointer" }}>
                <ContentIcon name={i} width="50" color="black" key={i} />
              </div>
            ))}
          </div>
        </Modal>
        <div className="gap20" />
        <div className="card">
          <div>
            <div className="profileTab">
              <div className="modalHeaderTabs">{this.renderTabOptions()}</div>
            </div>
            <div className="gap10" />
            <div className="container-fluid">
              {this.state.selectedTab === "details" && (
                <div
                  className={
                    this.state.selectedTab === "details"
                      ? "active for-tab1"
                      : "for-tab1"
                  }>
                  {this.renderDetailsTab()}
                </div>
              )}
              {this.state.selectedTab === "menu" && (
                <div
                  className={
                    this.state.selectedTab === "menu"
                      ? "active for-tab2"
                      : "for-tab2"
                  }>
                  {this.renderMenuListTab()}
                </div>
              )}
              {this.state.selectedTab === "branding" && (
                <div
                  className={
                    this.state.selectedTab === "branding"
                      ? "active for-tab3"
                      : "for-tab3"
                  }>
                  {this.renderBrandingTab()}
                </div>
              )}
              {this.state.selectedTab === "tags" && (
                <div
                  className={
                    this.state.selectedTab === "tags"
                      ? "active for-tab4"
                      : "for-tab4"
                  }>
                  {this.renderCustomTagsTab()}
                </div>
              )}
              {this.state.selectedTab === "social-media" && (
                <div
                  className={
                    this.state.selectedTab === "social-media"
                      ? "active for-tab5"
                      : "for-tab5"
                  }>
                  {this.renderSocialMediaTab()}
                </div>
              )}
              {this.state.selectedTab === "settings" && (
                <div
                  className={
                    this.state.selectedTab === "settings"
                      ? "active for-tab6"
                      : "for-tab6"
                  }>
                  {this.renderSettingsTab()}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="text-right">
          <button
            className="btn btn-default margin-right-10"
            onClick={this.onClickPreview.bind(this)}>
            Preview
          </button>
          <button
            className="btn btn-primary"
            onClick={this.onClickSave.bind(this)}>
            Save
          </button>
          <div className="gap20" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MySitePage);
