import React from "react";
import { VelocityTransitionGroup } from "velocity-react";
import $ from "jquery";
import {
  callCreateAwsImage,
  callSearchImages,
  callUploadImageUrl,
} from "../../services";
import { getParameterByName, showNotification } from "../../helpers";
import { getItem } from "../../helpers/storage";

class SearchImages extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      showFindImages: false,
      searchQuery: "",
      images: [],
      totalImages: 0,
      page: 1,
      selectedImage: {},
    };
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  onClickFindImages() {
    this.setState({ showFindImages: !this.state.showFindImages });
  }

  async onPressSearchImages(option = 1) {
    const response = await callSearchImages({
      query: this.state.searchQuery,
      page: this.state.page,
    });

    let imageList = this.state.images;
    if (option === 2) {
      imageList = imageList.concat(response.photos);
    } else {
      imageList = response.photos;
    }

    this.setState({
      images: imageList,
      totalImages: response.total_results,
    });
  }

  fetchMoreData() {
    this.setState({ page: this.state.page + 1 }, () => {
      this.onPressSearchImages(2);
    });
  }

  async onClickSizeButton(url, id) {
    let imageKey = `pexels-photo-${id}.jpeg`;
    let thumbKey = `pexels-photo-${id}-thumb.jpeg`;
    this.onClickCancelSizeModal();

    const response = await callUploadImageUrl({
      url: url,
      imageKey: imageKey,
      thumbKey: thumbKey,
    });
    if (response.status) {
      let key = imageKey;
      let fileName = key.substr(0, key.lastIndexOf("."));
      /*eslint-disable*/
      let apiData = {
        key: key,
        file: fileName,
        thumbnail_key: thumbKey,
        date_modified: new Date(),
        bucket: getItem("bucketName"),
        vendor: "aws",
        mime_type: response.contentType,
        size: response.contentLength,
        variant: "",
        generic_tag_list: [],
        variations: [],
      };
      /*eslint-enable*/
      await callCreateAwsImage(apiData);
      this.setState({ searchQuery: "", showFindImages: false, images: [] });
      this.props.refreshImages();
      showNotification("success", "Image uploaded");
    }
  }

  onClickCancelSizeModal() {
    this.setState({ selectedImage: {} }, () => {
      $(
        ".modalAlertOverlay.selectSizeModal,.modalAlert.selectSizeAlert"
      ).fadeOut(200);
    });
  }

  renderSelectSizeModal() {
    let selectedImage = this.state.selectedImage;
    if (!selectedImage.id) {
      return;
    }

    let src = selectedImage.src;
    let width = selectedImage.width;
    let height = selectedImage.height;

    const selectButton = (url) => (
      <button
        className="btn btn-sm btn-default margin-right-10"
        onClick={this.onClickSizeButton.bind(this, url, selectedImage.id)}>
        Select
      </button>
    );

    const sizeRow = (type) => (
      <tr key={type}>
        <td className="firstLetter">{type}</td>
        <td>
          {type === "original" ? height : getParameterByName("h", src[type])}
        </td>
        <td>
          {type === "original" ? width : getParameterByName("w", src[type])}
        </td>
        <td>{selectButton(src[type])}</td>
      </tr>
    );

    return (
      <div>
        <div className="modalAlertOverlay selectSizeModal" />
        <div className="modalAlert selectSizeAlert">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Select Size
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.onClickCancelSizeModal.bind(this)}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="gap20" />
          <div className="container-fluid">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Size</th>
                  <th>Height</th>
                  <th>Width</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {sizeRow("tiny")}
                {sizeRow("small")}
                {sizeRow("medium")}
                {sizeRow("large")}
                {sizeRow("portrait")}
                {sizeRow("landscape")}
                {sizeRow("original")}
              </tbody>
            </table>
          </div>
          <div className="gap10" />
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderSelectSizeModal()}
        <div className="card">
          <div className="list-group list-group-flush">
            <div
              className="list-group-item pointer-click"
              onClick={this.onClickFindImages.bind(this)}>
              <h5 className="noMargin pull-left firstLetter">Find Images</h5>

              <div className="pull-right">
                <i
                  className={
                    this.state.showFindImages
                      ? "fa fa-chevron-up"
                      : "fa fa-chevron-down"
                  }
                  aria-hidden="true"
                />
              </div>
            </div>
            <VelocityTransitionGroup
              enter={{ animation: "slideDown" }}
              leave={{ animation: "slideUp" }}>
              {this.state.showFindImages === true && (
                <>
                  <div className="list-group-item">
                    <div
                      className={
                        this.state.searchQuery !== ""
                          ? "mdInput mdFocussed"
                          : "mdInput"
                      }>
                      <label>Search Keyword</label>
                      <input
                        type="text"
                        name="searchQuery"
                        onChange={this.handleInputChange.bind(this)}
                        value={this.state.searchQuery}
                      />
                    </div>
                    <div className="gap20" />
                    <button
                      className="btn btn-primary"
                      onClick={() => this.onPressSearchImages()}>
                      Search
                    </button>
                  </div>
                  {this.state.images.length > 0 && (
                    <div className="list-group-item">
                      <label>Total Images : {this.state.totalImages}</label>
                      <div className="gap20" />
                      <div className="row">
                        {this.state.images.map((i) => (
                          <div className="col-sm-3" key={i.key}>
                            <img
                              onClick={() => {
                                this.setState({ selectedImage: i }, () => {
                                  $(
                                    ".modalAlertOverlay.selectSizeModal,.modalAlert.selectSizeAlert"
                                  ).fadeIn(200);
                                });
                              }}
                              src={i.src.small}
                              className="btn-block pointer-click editorGalleryImage"
                              alt="ImageUrl"
                              width={200}
                              height={200}
                              data-toggle="tooltip"
                              title={
                                "<img style='max-width:600px; max-height:400px; object-fit: contain;' src='" +
                                i.src.original +
                                "' />"
                              }
                            />
                            <div className="gap30" />
                          </div>
                        ))}
                      </div>
                      {this.state.images.length <= this.state.totalImages && (
                        <div className="text-center">
                          <button
                            className="btn btn-primary"
                            onClick={this.fetchMoreData.bind(this)}>
                            Load More
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                </>
              )}
            </VelocityTransitionGroup>
          </div>
        </div>
      </div>
    );
  }
}

export default SearchImages;
