import * as api from "./api";
import { callApi } from "../helpers";

function callCreateHelpDesk(data) {
  return callApi(api.service("help-desk").create(data));
}

function callUpdateHelpDesk(id, data) {
  return callApi(api.service("help-desk").patch(id, data));
}

function callDeleteHelpDesk(id) {
  return callApi(api.service("help-desk").remove(id));
}

function callGetHelpDesks() {
  return callApi(api.service("help-desk").find({}));
}

function callGetHelpDesksByQuery(query) {
  return callApi(api.service("help-desk").find(query));
}

function callCreateDepartment(data) {
  return callApi(api.service("department").create(data));
}

function callUpdateDepartment(id, data) {
  return callApi(api.service("department").patch(id, data));
}

function callDeleteDepartment(id) {
  return callApi(api.service("department").remove(id));
}

function callGetDepartments() {
  return callApi(api.service("department").find({}));
}

function callGetDepartmentsByQuery(query) {
  return callApi(api.service("department").find(query));
}

function callCreateTicket(data) {
  return callApi(api.service("ticket").create(data));
}

function callGetTicketById(id) {
  return callApi(api.service("ticket").get(id));
}

function callUpdateTicket(id, data) {
  return callApi(api.service("ticket").patch(id, data));
}

function callUpdateTicketByQuery(data, query) {
  return callApi(api.service("ticket").patch(null, data, query));
}

function callDeleteTicketByQuery(query) {
  return callApi(api.service("ticket").remove(null, query));
}

function callDeleteTicket(id) {
  return callApi(api.service("ticket").remove(id));
}

function callGetTickets() {
  return callApi(api.service("ticket").find({}));
}

function callGetTicketsByQuery(query) {
  return callApi(api.service("ticket").find(query));
}

function callGetPageTicketsByQuery(query) {
  return callApi(api.service("ticket/page").find(query));
}

function callCreateTicketResponse(data) {
  return callApi(api.service("ticket-response").create(data));
}

function callGetTicketResponseById(id) {
  return callApi(api.service("ticket-response").get(id));
}

function callUpdateTicketResponse(id, data) {
  return callApi(api.service("ticket-response").patch(id, data));
}

function callDeleteTicketResponse(id) {
  return callApi(api.service("ticket-response").remove(id));
}

function callGetTicketResponses() {
  return callApi(api.service("ticket-response").find({}));
}

function callGetTicketResponsesByQuery(query) {
  return callApi(api.service("ticket-response").find(query));
}

function callManageTicketNotification(data) {
  return callApi(api.service("general").create(data));
}

export {
  callGetPageTicketsByQuery,
  callCreateTicketResponse,
  callGetTicketResponseById,
  callUpdateTicketResponse,
  callDeleteTicketResponse,
  callGetTicketResponses,
  callGetTicketResponsesByQuery,
  callCreateTicket,
  callUpdateTicket,
  callDeleteTicket,
  callGetTickets,
  callGetTicketsByQuery,
  callCreateDepartment,
  callGetTicketById,
  callUpdateDepartment,
  callDeleteDepartment,
  callGetDepartments,
  callGetDepartmentsByQuery,
  callCreateHelpDesk,
  callUpdateHelpDesk,
  callDeleteHelpDesk,
  callGetHelpDesks,
  callGetHelpDesksByQuery,
  callUpdateTicketByQuery,
  callDeleteTicketByQuery,
  callManageTicketNotification,
};
