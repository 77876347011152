import * as api from "./api";
import { callApi } from "../helpers";

function callCreateLandingFunnel(data) {
  return callApi(api.service("landing-funnel").create(data));
}

function callUpdateLandingFunnel(id, data) {
  return callApi(api.service("landing-funnel").patch(id, data));
}

function callDeleteLandingFunnel(id) {
  return callApi(api.service("landing-funnel").remove(id));
}

function callGetLandingFunnels() {
  return callApi(api.service("landing-funnel").find({}));
}

function callGetLandingFunnelsByQuery(query) {
  return callApi(api.service("landing-funnel").find(query));
}

export {
  callCreateLandingFunnel,
  callUpdateLandingFunnel,
  callDeleteLandingFunnel,
  callGetLandingFunnels,
  callGetLandingFunnelsByQuery,
};
