import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment-timezone";
import $ from "jquery";

import TitleBox from "../../../components/general/titleBox";
import {
  callDeleteTaskHistoryTemplate,
  callGetTaskHistoryTemplates,
} from "../../../services";

class TaskHistoryTemplatesPage extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = { items: [], selectedItem: {} };
  }

  componentDidMount() {
    this.getItems();
  }

  async getItems() {
    let data = await callGetTaskHistoryTemplates();
    this.setState({ items: data.data });
  }

  onClickEditTemplate = (event) => {
    this.props.history.push(`/admin/add-task-history-template?id=${event._id}`);
  };

  onClickDeleteTemplate = (item) => {
    this.setState({ selectedItem: item }, () => {
      $(
        ".modalAlertOverlay.deleteTemplateOverlay,.modalAlert.deleteAlert"
      ).fadeIn(200);
    });
  };

  renderTemplates() {
    var returnVals = [];
    let items = this.state.items;

    for (let i = 0; i < items.length; i++) {
      const element = items[i];

      returnVals.push(
        <tr key={element._id}>
          <td>{element.name}</td>
          <td>{element.fields.length}</td>
          <td>{moment(element.createdAt).format("Do MMM YYYY hh:mm A")}</td>
          <td>
            <button
              title="Edit"
              onClick={this.onClickEditTemplate.bind(this, element)}
              className="btn btn-default btn-sm margin-right-10 border-0">
              <i className="fa fa-pencil" />
            </button>
            <button
              title="Delete"
              onClick={this.onClickDeleteTemplate.bind(this, element)}
              className="btn btn-danger btn-sm border-0">
              <i className="fa fa-trash" />
            </button>
          </td>
        </tr>
      );
    }
    return returnVals;
  }

  onClickCreateTemplate() {
    // open create template
    this.props.history.push("/admin/add-task-history-template");
  }

  onClickNoAlert = () => {
    $(
      ".modalAlertOverlay.deleteTemplateOverlay,.modalAlert.deleteAlert"
    ).fadeOut(200);
  };

  onClickYesAlert = async () => {
    await callDeleteTaskHistoryTemplate(this.state.selectedItem._id);

    $(
      ".modalAlertOverlay.deleteTemplateOverlay,.modalAlert.deleteAlert"
    ).fadeOut(200);
    this.getItems();
  };

  renderConfirmationAlert() {
    let selectedItem = this.state.selectedItem;
    let name = "";
    if (selectedItem.name) {
      name = selectedItem.name;
    }
    return (
      <div>
        <div className="modalAlertOverlay deleteTemplateOverlay" />
        <div className="modalAlert deleteAlert">
          <p>{`Are you sure you want to delete "${name}" template?`}</p>
          <div className="alertFooterBtns">
            <button
              className="btn btn-default closeModal-No margin-right-10"
              onClick={this.onClickNoAlert}>
              No
            </button>
            <button
              className="btn btn-primary closeModal-Yes"
              onClick={this.onClickYesAlert}>
              Yes
            </button>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="container-fluid">
        {this.renderConfirmationAlert()}
        <TitleBox title={"Task History Templates"} showBackBtn={true} />
        <div className="gap20" />
        <div className="card">
          <div className="list-group list-group-flush">
            <div className="list-group-item">
              <div className="pull-right">
                <button
                  className="btn btn-primary btn-sm"
                  onClick={this.onClickCreateTemplate.bind(this)}>
                  Create New Template
                </button>
              </div>
            </div>
            <div className="list-group-item">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Fields</th>
                    <th>Created Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>{this.renderTemplates()}</tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaskHistoryTemplatesPage);
