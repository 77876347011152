import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { arrayMove } from "react-sortable-hoc";

import TitleBox from "../../../components/general/titleBox";
import Checkbox from "../../../components/form/checkBox";
import RadioTag from "../../../components/form/radioTag";
import QuillEditor from "../../../components/form/reactQuill";
import HelpTextDiv from "../../../components/form/helpTextDiv";
import { DraftEditor } from "../../../packages/draft-editor/index";

import { showNotification, getParameterByName } from "../../../helpers";

import {
  SortableFieldOptionList,
  SortableFormFieldList,
  SortableStepList,
} from "../../../components/form/sortableList";
import {
  callCreateForm,
  callGetFormById,
  callUpdateForm,
} from "../../../services";
import SelectAddImage from "../../../components/form/selectAddImage";

class AddFormPage extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    let formId = Math.random().toString(36).substr(2);

    let id = getParameterByName("id");
    if (!id) {
      id = 0;
    }
    if (this.props.fromPopup) {
      id = 0;
    }

    this.state = {
      id: id,
      name: "",
      description: "",
      isCreatePdf: false,
      isPublicPdf: false,
      keyCreatePdf: Math.random(),
      keyPublicPdf: Math.random(),
      formId: formId,
      formFieldLabel: "",
      formFieldType: "text",
      formFieldRequired: false,
      formFieldValue: "",
      formFieldValueJson: {},
      formFieldId: "",
      formFieldLimit: "",
      selectedFormFieldIndex: -1,
      keyFieldRequired: Math.random(),
      keyFieldType: Math.random(),
      keyFieldValue: Math.random(),
      formFields: [],
      steps: [],
      stepId: "",
      stepTitle: "",
      selectedStepIndex: -1,
      editorType: "new",
      users: [],
      options: [],
      optionLabel: "",
      optionId: "",
      optionSelected: false,
      optionAllowText: false,
      keyOptionAllowText: Math.random(),
      keyOptionSelected: Math.random(),
      selectedOptionIndex: -1,
    };
  }

  async componentDidMount() {
    if (this.state.id !== 0) {
      let form = await callGetFormById(this.state.id);
      if (form._id) {
        this.setState({
          id: form._id,
          name: form.name,
          description: form.description,
          isCreatePdf: form.create_pdf || false,
          isPublicPdf: form.public_pdf || false,
          formId: form.form_id,
          steps: form.steps,
          keyCreatePdf: Math.random(),
          keyPublicPdf: Math.random(),
        });
      }
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  resetProps() {
    this.setState({
      id: 0,
      name: "",
      description: "",
      formId: "",
      formFieldLabel: "",
      formFieldType: "text",
      formFieldRequired: false,
      formFieldValue: "",
      formFieldValueJson: {},
      formFieldId: "",
      editorType: "new",
      selectedFormFieldIndex: -1,
      keyFieldRequired: Math.random(),
      keyFieldType: Math.random(),
      keyFieldValue: Math.random(),
      formFields: [],
      steps: [],
      stepId: "",
      stepTitle: "",
      selectedStepIndex: -1,
      isCreatePdf: false,
      isPublicPdf: false,
      keyCreatePdf: Math.random(),
      keyPublicPdf: Math.random(),
    });
  }

  toggleFieldRequired = (check) => {
    this.setState({ formFieldRequired: check });
  };

  toggleCreatePdf = (check) => {
    this.setState({ isCreatePdf: check });
  };

  togglePublicPdf = (check) => {
    this.setState({ isPublicPdf: check });
  };

  onChangeFormFieldValue(value, text) {
    this.setState({
      formFieldValue: value,
    });
  }

  onChangeParagaphValue = (html, json) => {
    this.setState({ formFieldValue: html, formFieldValueJson: json });
  };

  onChangeImage = (image) => {
    this.setState({ formFieldValue: image });
  };

  onSelectFormFieldType(item, id) {
    let value = item[Object.keys(item)[0]].value;
    this.setState({ [id]: value });
  }

  onSelectUser(item, id) {
    let users = [];

    Object.keys(item).forEach((obj) => {
      users.push(item[obj].value);
    });

    this.setState({
      users: users,
    });
  }

  onSortEndFields = ({ oldIndex, newIndex }) => {
    let formFields = this.state.formFields;
    formFields = arrayMove(formFields, oldIndex, newIndex);

    this.setState({
      formFields: formFields,
    });
  };

  onPressRemoveField(e) {
    let index = e.target.dataset["value"];
    let formFields = this.state.formFields;
    formFields.splice(index, 1);
    this.setState({
      formFields: formFields,
    });
  }

  onPressEditField(e) {
    let index = e.target.dataset["value"];
    let formFields = this.state.formFields;

    let field = formFields[index];

    this.setState({
      selectedFormFieldIndex: index,
      formFieldLabel: field.label,
      formFieldRequired: field.required,
      formFieldType: field.type,
      formFieldValueJson: field.json,
      editorType: field.editorType
        ? field.editorType
        : field.value
        ? "old"
        : "new",
      formFieldValue: field.value,
      formFieldId: field.id,
      formFieldLimit: field.limit,
      keyFieldRequired: Math.random(),
      keyFieldType: Math.random(),
      keyFieldValue: Math.random(),
      options: field.options || [],
    });
  }

  onClickCancelFormField() {
    this.setState({
      selectedFormFieldIndex: -1,
      formFieldLabel: "",
      formFieldRequired: "",
      formFieldType: "text",
      formFieldValue: "",
      editorType: "new",
      formFieldValueJson: {},
      formFieldId: "",
      keyFieldRequired: Math.random(),
      keyFieldType: Math.random(),
      keyFieldValue: Math.random(),
    });
  }

  onClickAddField() {
    let fields = this.state.formFields;

    if (this.state.formFieldLabel.trim().length === 0) {
      showNotification("error", "Label is required", 4000);
      return;
    }

    let id = Math.random().toString(36).substr(2);

    let field = {
      label: this.state.formFieldLabel,
      required: this.state.formFieldRequired,
      value: this.state.formFieldValue,
      json: this.state.formFieldValueJson,
      type: this.state.formFieldType,
      id: id,
      showEdit: true,
      editorType: this.state.editorType,
      options: this.state.options,
      limit: this.state.formFieldLimit,
    };

    if (this.state.selectedFormFieldIndex >= 0) {
      field.id = this.state.formFieldId;
      fields[this.state.selectedFormFieldIndex] = field;
    } else {
      fields.push(field);
    }

    this.setState({
      options: [],
      formFields: fields,
      selectedFormFieldIndex: -1,
      formFieldLabel: "",
      formFieldType: "text",
      formFieldValue: "",
      formFieldValueJson: {},
      formFieldId: "",
      editorType: "new",
      formFieldLimit: "",
      formFieldRequired: false,
      keyFieldRequired: Math.random(),
      keyFieldType: Math.random(),
      keyFieldValue: Math.random(),
    });
  }

  onSortEndSteps = ({ oldIndex, newIndex }) => {
    let steps = this.state.steps;
    steps = arrayMove(steps, oldIndex, newIndex);

    this.setState({
      steps: steps,
    });
  };

  onPressRemoveStep(e) {
    let index = e.target.dataset["value"];
    let steps = this.state.steps;
    steps.splice(index, 1);
    this.setState({
      steps: steps,
    });
  }

  onPressEditStep(e) {
    let index = e.target.dataset["value"];
    let steps = this.state.steps;

    let step = steps[index];

    this.setState({
      formFields: step.fields,
      selectedStepIndex: index,
      stepTitle: step.title,
      stepId: step.id,
    });
  }

  onClickCancelStep() {
    this.setState({
      selectedStepIndex: -1,
      stepTitle: "",
      formFields: [],
      stepId: "",
    });
  }

  onClickAddStep() {
    if (this.state.selectedFormFieldIndex >= 0) {
      showNotification("error", "Please save current field", 4000);
      return;
    }

    let steps = this.state.steps;

    if (this.state.stepTitle.trim().length === 0) {
      showNotification("error", "Title is required", 4000);
      return;
    }

    let id = Math.random().toString(36).substr(2);

    let step = {
      title: this.state.stepTitle,
      fields: this.state.formFields,
      id: id,
    };

    if (this.state.selectedStepIndex >= 0) {
      step.id = this.state.stepId;
      steps[this.state.selectedStepIndex] = step;
    } else {
      steps.push(step);
    }

    this.setState({
      steps: steps,
      selectedStepIndex: -1,
      stepTitle: "",
      stepId: "",
      formFields: [],
    });
  }

  async onPressSaveForm() {
    if (this.state.selectedFormFieldIndex >= 0) {
      showNotification("error", "Please save current field", 4000);
      return;
    }
    if (this.state.selectedStepIndex >= 0) {
      showNotification("error", "Please save current step", 4000);
      return;
    }

    if (this.state.name.trim().length === 0) {
      showNotification("error", "Form name is required", 4000);
      return;
    }

    if (this.state.description.trim().length === 0) {
      showNotification("error", "Form description is required", 4000);
      return;
    }

    if (this.state.steps.length === 0) {
      showNotification("error", "Atleast 1 step is required", 4000);
      return;
    }

    /*eslint-disable*/
    let apiData = {
      form_id: this.state.formId,
      name: this.state.name,
      description: this.state.description,
      create_pdf: this.state.isCreatePdf,
      public_pdf: this.state.isPublicPdf,
      steps: this.state.steps,
    };
    /*eslint-enable*/

    if (this.state.id !== 0) {
      let response = await callUpdateForm(this.state.id, apiData);
      if (response._id) {
        showNotification("success", "Form updated successfully", 3000);
      }
    } else {
      let response = await callCreateForm(apiData);
      if (response._id) {
        showNotification("success", "Form added successfully", 3000);
        this.resetProps();
        if (this.props.fromPopup) {
          this.props.onCreate();
        }
      }
    }
  }

  onClickPreviewForm() {
    let url = `https://${window.location.host}/member/form?id=${this.state.id}`;

    window.open(url, "_blank");
  }

  renderStepList() {
    return (
      <>
        <div className="text-right">
          <button
            className="btn btn-primary addQuestionButton"
            onClick={() => this.onClickAddStep()}>
            Add Step
          </button>
        </div>
        <div className="gap20" />

        <SortableStepList
          distance={10}
          items={this.state.steps}
          onSortEnd={this.onSortEndSteps}
          onPressEdit={this.onPressEditStep.bind(this)}
          onPressRemove={this.onPressRemoveStep.bind(this)}
        />
        <div className="gap20" />
      </>
    );
  }

  onSortEndOptions = ({ oldIndex, newIndex }) => {
    let options = this.state.options;
    options = arrayMove(options, oldIndex, newIndex);

    this.setState({
      options: options,
    });
  };

  onPressRemoveOption(e) {
    let index = e.target.dataset["value"];
    let options = this.state.options;
    options.splice(index, 1);
    this.setState({
      options: options,
    });
  }

  onPressEditOption(e) {
    let index = e.target.dataset["value"];
    let options = this.state.options;

    let option = options[index];

    this.setState({
      selectedOptionIndex: index,
      optionLabel: option.label,
      optionId: option.id,
      optionSelected: option.selected,
      keyOptionSelected: Math.random(),
      optionAllowText: option.allowText,
      keyOptionAllowText: Math.random(),
    });
  }

  toggleOptionSelected = (check) => {
    this.setState({ optionSelected: check });
  };

  toggleOptionAllowText = (check) => {
    this.setState({ optionAllowText: check });
  };

  isRenderSelectedOptionCheck() {
    let check = false;
    let arrTemp = this.state.options.filter((d) => {
      return d.selected === true;
    });
    check = arrTemp.length > 0 ? false : true;

    if (this.state.selectedOptionIndex >= 0) {
      let selectedOption = this.state.options[this.state.selectedOptionIndex];
      if (selectedOption && selectedOption.selected) {
        check = true;
      }
    }

    return check;
  }

  onClickAddOption() {
    let options = this.state.options;

    if (this.state.optionLabel.trim().length === 0) {
      showNotification("error", "Label is required", 4000);
      return;
    }

    let id = Math.random().toString(36).substr(2);

    let option = {
      label: this.state.optionLabel,
      id: id,
      showEdit: true,
      selected: this.state.optionSelected,
      allowText: this.state.optionAllowText,
    };

    if (this.state.selectedOptionIndex >= 0) {
      option.id = this.state.optionId;
      options[this.state.selectedOptionIndex] = option;
    } else {
      options.push(option);
    }

    this.setState({
      options: options,
      selectedOptionIndex: -1,
      optionLabel: "",
      optionId: "",
      optionSelected: false,
      keyOptionSelected: Math.random(),
      optionAllowText: false,
      keyOptionAllowText: Math.random(),
    });
  }

  renderAddFieldSection() {
    return (
      <>
        <div className="card">
          <div className="container-fluid">
            <div className="gap20" />
            <h5 className="noMargin">{this.state.stepTitle} Fields</h5>
            <div className="gap20" />

            <div className="row">
              <div className="col-md-6">
                <HelpTextDiv
                  id="idFormLabel"
                  label="Learn more about the label"
                  type="addForm"
                  isInline="false"
                />
                <div
                  className={
                    this.state.formFieldLabel !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Label</label>
                  <input
                    type="text"
                    className="offsetDays"
                    name="formFieldLabel"
                    value={this.state.formFieldLabel}
                    onChange={(event) => this.handleInputChange(event)}
                  />
                </div>
                <div className="gap20" />
              </div>
              {this.state.formFieldType !== "paragraph" && (
                <div className="col-md-6">
                  <div className="gap20" />
                  <div className="gap20" />

                  <div className="havingHelpText">
                    <Checkbox
                      label={"Required"}
                      isChecked={this.state.formFieldRequired}
                      handleCheckboxChange={this.toggleFieldRequired}
                      key={this.state.keyFieldRequired}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="havingHelpText">
                  <p>Type</p>
                </div>
                <HelpTextDiv
                  id="idFormType"
                  label="Learn more about the Type"
                  type="addForm"
                  isInline="true"
                />
                <div className="tabsHolder radioTabs">
                  <RadioTag
                    key={this.state.keyFieldType}
                    onCheckChange={this.onSelectFormFieldType.bind(this)}
                    labelList={[
                      { name: "Text", value: "text" },
                      { name: "Date", value: "date" },
                      { name: "Checkbox", value: "checkbox" },
                      { name: "Paragraph", value: "paragraph" },
                      { name: "Signature", value: "signature" },
                      { name: "Image", value: "image" },
                      { name: "Dropdown", value: "select" },
                      { name: "Radio", value: "radio" },
                      { name: "Time", value: "time" },
                      { name: "Large Text Area", value: "editor" },
                    ]}
                    id={"formFieldType"}
                    selectedList={[this.state.formFieldType]}
                    selectType={"value"}
                  />
                </div>
              </div>
            </div>
            <div className="gap10" />

            {this.state.formFieldType === "paragraph" && (
              <div className="row">
                <div className="col-md-6">
                  {this.state.editorType === "old" ? (
                    <div
                      className={
                        this.state.formFieldValue !== ""
                          ? "mdInput mdFocussed"
                          : "mdInput"
                      }>
                      <QuillEditor
                        key={this.state.keyFieldValue}
                        theme="bubble"
                        show={""}
                        label="Value"
                        type="mdInput"
                        value={this.state.formFieldValue}
                        onChangeValue={this.onChangeFormFieldValue.bind(this)}
                      />
                    </div>
                  ) : (
                    <div className="add-task-comment">
                      <DraftEditor
                        key={this.state.keyFieldValue}
                        cType="task"
                        defaultText=""
                        lineHeight={"inherit"}
                        textAlign={"left"}
                        html={this.state.formFieldValue}
                        json={this.state.formFieldValueJson}
                        onChange={this.onChangeParagaphValue.bind(this)}
                      />
                    </div>
                  )}
                </div>
                <div className="gap10" />
              </div>
            )}
            {this.state.formFieldType === "image" && (
              <SelectAddImage
                type="form-field-image"
                label="Image"
                onChangeImage={this.onChangeImage}
                image={this.state.formFieldValue}
              />
            )}

            {(this.state.formFieldType === "text" ||
              this.state.formFieldType === "editor") && (
              <div className="row">
                <div className="col-md-6">
                  <div
                    className={
                      this.state.formFieldLimit !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>Character Limit </label>
                    <input
                      type="number"
                      className="offsetDays"
                      name="formFieldLimit"
                      value={this.state.formFieldLimit}
                      onChange={(event) => this.handleInputChange(event)}
                    />
                  </div>
                  <div className="gap20" />
                </div>
              </div>
            )}

            {(this.state.formFieldType === "select" ||
              this.state.formFieldType === "radio") && (
              <div className="card">
                <div className="container-fluid">
                  <div className="gap20" />
                  <h5 className="noMargin">
                    {this.state.formFieldLabel} Options
                  </h5>
                  <div className="gap20" />

                  <div className="row">
                    <div className="col-md-6">
                      <div
                        className={
                          this.state.optionLabel !== ""
                            ? "mdInput mdFocussed"
                            : "mdInput"
                        }>
                        <label>Label</label>
                        <input
                          type="text"
                          className="offsetDays"
                          name="optionLabel"
                          value={this.state.optionLabel}
                          onChange={(event) => this.handleInputChange(event)}
                        />
                      </div>
                      <div className="gap20" />
                    </div>
                  </div>
                  <div className="gap10" />
                  {this.isRenderSelectedOptionCheck() && (
                    <Checkbox
                      label={"Selected by default"}
                      isChecked={this.state.optionSelected}
                      handleCheckboxChange={this.toggleOptionSelected.bind(
                        this
                      )}
                      key={this.state.keyOptionSelected}
                    />
                  )}
                  <div className="gap20" />
                  <Checkbox
                    label={
                      "If this option is selected allow the user to add text"
                    }
                    isChecked={this.state.optionAllowText}
                    handleCheckboxChange={this.toggleOptionAllowText.bind(this)}
                    key={this.state.keyOptionAllowText}
                  />

                  <div className="gap20" />
                  <div className="text-right">
                    {this.state.selectedOptionIndex >= 0 && (
                      <button
                        className="btn btn-default margin-right-10"
                        onClick={() => this.onClickCancelFormField()}>
                        Cancel
                      </button>
                    )}
                    <button
                      className="btn btn-primary addQuestionButton"
                      onClick={() => this.onClickAddOption()}>
                      {this.state.selectedOptionIndex >= 0
                        ? "Update Option"
                        : "Add Option"}
                    </button>
                  </div>
                  <div className="gap20" />
                  <SortableFieldOptionList
                    distance={10}
                    items={this.state.options}
                    onSortEnd={this.onSortEndOptions}
                    onPressEdit={this.onPressEditOption.bind(this)}
                    onPressRemove={this.onPressRemoveOption.bind(this)}
                  />
                  <div className="gap20" />
                </div>
              </div>
            )}

            <div className="text-right">
              {this.state.selectedFormFieldIndex >= 0 && (
                <button
                  className="btn btn-default margin-right-10"
                  onClick={() => this.onClickCancelFormField()}>
                  Cancel
                </button>
              )}
              <button
                className="btn btn-primary addQuestionButton"
                onClick={() => this.onClickAddField()}>
                {this.state.selectedFormFieldIndex >= 0
                  ? "Update Field"
                  : "Add Field"}
              </button>
            </div>
            <div className="gap20" />
            <SortableFormFieldList
              distance={10}
              items={this.state.formFields}
              onSortEnd={this.onSortEndFields}
              onPressEdit={this.onPressEditField.bind(this)}
              onPressRemove={this.onPressRemoveField.bind(this)}
            />
            <div className="gap20" />
          </div>
        </div>
        <div className="gap10" />
        <div className="text-right">
          <button
            className="btn btn-default margin-right-10"
            onClick={() => this.onClickCancelStep()}>
            Cancel
          </button>
          <button
            className="btn btn-primary addQuestionButton"
            onClick={() => this.onClickAddStep()}>
            Save Step
          </button>
        </div>
        <div className="gap20" />
      </>
    );
  }

  render() {
    let showTitle = true;
    if (this.props.fromPopup === true) {
      showTitle = false;
    }
    return (
      <div>
        <div className="container-fluid">
          {showTitle && (
            <TitleBox
              title={this.state.id === 0 ? "Add New Form" : "Edit Form"}
              showBackBtn={this.state.id !== 0}
            />
          )}
          <div className="gap20" />

          <div className="card">
            <div className="container-fluid">
              <div className="gap10" />
              <HelpTextDiv
                id="idFormName"
                label="Learn more about the Name"
                type="addForm"
                isInline="false"
              />
              <div
                className={
                  this.state.name !== "" ? "mdInput mdFocussed" : "mdInput"
                }>
                <label>Name</label>
                <input
                  type="text"
                  name="name"
                  onChange={this.handleInputChange.bind(this)}
                  value={this.state.name}
                />
              </div>
              <div className="gap10" />

              <HelpTextDiv
                id="idFormDescription"
                label="Learn more about the Description"
                type="addForm"
                isInline="false"
              />
              <div
                className={
                  this.state.description !== ""
                    ? "mdInput mdFocussed"
                    : "mdInput"
                }>
                <label>Description</label>
                <input
                  type="text"
                  name="description"
                  onChange={this.handleInputChange.bind(this)}
                  value={this.state.description}
                />
              </div>
              <div className="gap20" />

              <div className="havingHelpText">
                <Checkbox
                  label={"Create PDF from form"}
                  isChecked={this.state.isCreatePdf}
                  handleCheckboxChange={this.toggleCreatePdf}
                  key={this.state.keyCreatePdf}
                />
              </div>
              <HelpTextDiv
                id="idFormCreatePDF"
                label="Learn more about the Create PDF of Form"
                type="addForm"
                isInline="true"
              />
              <div className="gap20" />
              {this.state.isCreatePdf && (
                <>
                  <Checkbox
                    label={"Make PDF public (so customer can also access it)"}
                    isChecked={this.state.isPublicPdf}
                    handleCheckboxChange={this.togglePublicPdf}
                    key={this.state.keyPublicPdf}
                  />
                  <div className="gap20" />
                </>
              )}
              <div className="gap10" />
            </div>
          </div>

          <div className="card">
            <div className="container-fluid">
              <div className="gap20" />

              <div className="havingHelpText">
                <h5 className="noMargin">Steps</h5>
              </div>
              <HelpTextDiv
                id="idFormSteps"
                label="Learn more about the Form Steps"
                type="addForm"
                isInline="true"
              />
              <div className="gap20" />

              <div className="row">
                <div className="col-md-6">
                  <HelpTextDiv
                    id="idFormStepTitle"
                    label="Learn more about the Form Step Title"
                    type="addForm"
                    isInline="false"
                  />
                  <div
                    className={
                      this.state.stepTitle !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>Title</label>
                    <input
                      type="text"
                      className="offsetDays"
                      name="stepTitle"
                      value={this.state.stepTitle}
                      onChange={(event) => this.handleInputChange(event)}
                    />
                  </div>
                  <div className="gap20" />
                </div>
              </div>

              {this.state.selectedStepIndex === -1 && this.renderStepList()}
              {this.state.selectedStepIndex > -1 &&
                this.renderAddFieldSection()}
            </div>
          </div>

          <div className="text-right">
            {this.state.id !== 0 && (
              <button
                className="btn btn-default margin-right-10"
                onClick={() => this.onClickPreviewForm()}>
                Preview
              </button>
            )}
            <button
              className="btn btn-primary"
              onClick={() => this.onPressSaveForm()}>
              Save Form
            </button>
          </div>
        </div>

        <div className="gap20" />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddFormPage);
