import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import $ from "jquery";

import TitleBox from "../../../components/general/titleBox";

import {
  callGetContentEditorSections,
  callDeleteContentEditorSectionById,
} from "../../../services";

class ViewCustomSectionsPage extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      items: [],
      itemsBackup: [],
      filterList: [],
      selectedItem: {},
    };
  }

  componentDidMount() {
    this.getCustomSections();
  }

  getCustomSections() {
    callGetContentEditorSections().then((d) => {
      this.setState({ items: d.data, itemsBackup: d.data });
    });
  }

  onClickEditSection = (item) => {
    this.props.history.push(`/admin/add-custom-section?id=${item._id}`);
  };

  onClickDeleteSection = (item) => {
    this.setState({ selectedItem: item }, () => {
      $(".modalAlertOverlay,.modalAlert").fadeIn(200);
    });
  };

  renderCustomSections() {
    var returnVals = [];
    let items = this.state.items;
    items.forEach((element) => {
      returnVals.push(
        <tr key={element._id}>
          <td>{element.name}</td>
          <td>{element.non_customizable ? "Yes" : "No"}</td>
          <td className="min-width-100">
            {element.from !== "templates" && (
              <>
                <button
                  onClick={this.onClickDeleteSection.bind(this, element)}
                  className="btn btn-danger btn-sm margin-right-10 border-0">
                  <i className="fa fa-trash" />
                </button>
                <button
                  onClick={this.onClickEditSection.bind(this, element)}
                  className="btn btn-default btn-sm border-0">
                  <i className="fa fa-pencil" />
                </button>
              </>
            )}
          </td>
        </tr>
      );
    });
    return returnVals;
  }

  onClickNoAlert = () => {
    $(".modalAlertOverlay,.modalAlert").fadeOut(200);
  };

  onClickYesAlert = () => {
    callDeleteContentEditorSectionById(this.state.selectedItem._id).then(
      (d) => {
        $(".modalAlertOverlay,.modalAlert").fadeOut(200);
        this.getCustomSections();
      }
    );
  };

  renderConfirmationAlert() {
    let selectedItem = this.state.selectedItem;
    let name = "";
    if (selectedItem.name) {
      name = selectedItem.name;
    }

    return (
      <div>
        <div className="modalAlertOverlay" />
        <div className="modalAlert">
          <p>{`Are you sure you want to delete "${name}" custom section?`}</p>
          <div className="alertFooterBtns">
            <button
              className="btn btn-default closeModal-No margin-right-10"
              onClick={this.onClickNoAlert}>
              No
            </button>
            <button
              className="btn btn-primary closeModal-Yes"
              onClick={this.onClickYesAlert}>
              Yes
            </button>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="container-fluid">
        {this.renderConfirmationAlert()}
        <TitleBox title="Custom Sections" />
        <div className="gap20" />
        <div className="card">
          <div className="list-group list-group-flush">
            <div className="list-group-item">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Non Customizable</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>{this.renderCustomSections()}</tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewCustomSectionsPage);
