import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { VelocityTransitionGroup } from "velocity-react";
import TextAreaAutoResize from "../form/textAreaAutoResize";
import TagSelect from "../../components/form/tagSelect";

import { getGenericTags } from "../../modules/actions/index";
import {
  callCreateGenericTag,
  callCreatePredefinedResponse,
  callGetPredefinedResponses,
  callUpdatePredefinedResponse,
} from "../../services";
import { showNotification } from "../../helpers";

class PredefinedResponse extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      messageId: 0,
      name: "",
      message: this.props.message || "",
      showAddMessage: false,
      keyTag: Math.random(),
      tags: [],
      fTags: { new: [], existing: [] },
      selectedTags: [],
      messages: [],
      messageKey: Math.random(),
    };
  }

  componentDidMount() {
    this.props.getGenericTags("predefined-response");
    this.getMessages();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.genericTags.data) {
      this.setState({
        tags: newProps.genericTags.data,
        keyTag: Math.random(),
      });
    }
  }

  getMessages() {
    callGetPredefinedResponses().then((d) => {
      this.setState({ messages: d.data });
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  onClickShowMessage() {
    this.setState({ showAddMessage: !this.state.showAddMessage });
  }

  onChangeTags = (tags) => {
    if (tags.length > 0) {
      let fTags = { new: [], existing: [] };
      tags.forEach((element) => {
        if (element.name) {
          fTags.existing.push(element);
        } else {
          fTags.new.push(element);
        }
      });
      this.setState({ fTags: fTags });
    }
  };

  onClickAddMessage() {
    if (this.state.name.trim().length === 0) {
      showNotification("error", "Name is required", 4000);
      return;
    }

    if (this.state.message.trim().length === 0) {
      showNotification("error", "Message is required", 4000);
      return;
    }

    let tags = this.state.fTags;
    if (tags.new.length > 0) {
      let data = [];
      tags.new.forEach((element) => {
        data.push({ name: element, type: "predefined-response" });
      });
      callCreateGenericTag(data).then((resp) => {
        let eTags = this.state.tags;
        this.setState({
          tags: eTags.concat(resp),
        });
        let newTagIds = resp.map((t) => t._id);
        let tagIds = tags.existing.map((t) => t._id);
        let finalTagIds = newTagIds.concat(tagIds);

        this.callCreateResponseAPI(finalTagIds);
      });
    } else {
      let tagIds = tags.existing.map((t) => t._id);
      this.callCreateResponseAPI(tagIds);
    }
  }

  callCreateResponseAPI(tagIds) {
    /*eslint-disable*/
    let apiData = {
      name: this.state.name,
      message: this.state.message,
      generic_tag_list: tagIds,
    };
    /*eslint-enable*/

    if (this.state.messageId === 0) {
      callCreatePredefinedResponse(apiData).then((d) => {
        if (d._id) {
          this.setState(
            {
              messageId: 0,
              showAddMessage: false,
              name: "",
              message: "",
              messageKey: Math.random(),
              selectedTags: [],
              fTags: { new: [], existing: [] },
              keyTag: Math.random(),
            },
            () => {
              showNotification("success", "Message added successfully", 4000);
              this.getMessages();
            }
          );
        } else {
          showNotification("error", "Something went wrong", 4000);
        }
      });
    } else {
      callUpdatePredefinedResponse(this.state.messageId, apiData).then((d) => {
        if (d._id) {
          this.setState(
            {
              messageId: 0,
              showAddMessage: false,
              name: "",
              message: "",
              messageKey: Math.random(),
              selectedTags: [],
              fTags: { new: [], existing: [] },
              keyTag: Math.random(),
            },
            () => {
              showNotification("success", "Message updated successfully", 4000);
              this.getMessages();
            }
          );
        } else {
          showNotification("error", "Something went wrong", 4000);
        }
      });
    }
  }

  onClickInsertMessage(element) {
    this.props.onClickInsertMessage(element);
  }

  onClickResetMessage() {
    this.setState({
      showAddMessage: false,
      messageKey: Math.random(),
      keyTag: Math.random(),
      messageId: 0,
      name: "",
      message: "",
      selectedTags: [],
      fTags: { new: [], existing: [] },
    });
  }

  onClickEditMessage(element) {
    let tags = element.ftags;
    this.setState({
      showAddMessage: true,
      messageKey: Math.random(),
      messageId: element._id,
      name: element.name,
      message: element.message,
      selectedTags: tags || [],
      fTags: { new: [], existing: tags },
    });
  }

  renderMessages() {
    let messages = this.state.messages;
    let returnVals = [];

    for (let i = 0; i < messages.length; i++) {
      const element = messages[i];
      returnVals.push(
        <tr key={i}>
          <td>{element.name}</td>
          <td>{element.message}</td>
          {/*eslint-disable*/}
          <td>
            <a
              className="btn btn-default btn-sm btn-block"
              onClick={this.onClickEditMessage.bind(this, element)}>
              Edit
            </a>
          </td>
          <td>
            <a
              className="btn btn-default btn-sm btn-block"
              onClick={this.onClickInsertMessage.bind(this, element)}>
              Insert
            </a>
          </td>
          {/*eslint-enable*/}
        </tr>
      );
    }
    return returnVals;
  }

  render() {
    return (
      <div>
        <div className="list-group list-group-flush">
          <div className="list-group-item">
            {/*eslint-disable-next-line*/}
            <a
              className="btn btn-default"
              onClick={this.onClickShowMessage.bind(this)}>
              Add New Message
            </a>
            <div className="gap20" />

            <VelocityTransitionGroup
              enter={{ animation: "slideDown" }}
              leave={{ animation: "slideUp" }}>
              {this.state.showAddMessage && (
                <div className="container-fluid">
                  <div className="card">
                    <div className="gap20" />
                    <div className="container-fluid">
                      <div
                        className={
                          this.state.name !== ""
                            ? "mdInput mdFocussed"
                            : "mdInput"
                        }>
                        <label>Name</label>
                        <input
                          type=""
                          className="challangeDesc"
                          name="name"
                          value={this.state.name}
                          onChange={(event) => this.handleInputChange(event)}
                        />
                      </div>
                      <div className="gap10" />
                      <TextAreaAutoResize
                        key={this.state.messageKey}
                        onRef={(input) => {
                          this.iRef = input;
                        }}
                        label={"Message"}
                        name={"message"}
                        value={this.state.message}
                        onTextChange={this.handleInputChange.bind(this)}
                      />
                      <div className="gap10" />
                      <p
                        style={{
                          color: "gray",
                          fontSize: 13,
                        }}>
                        {
                          "{{first_name}} will be replaced with the customers first name"
                        }
                      </p>
                      <div className="gap20" />
                      <TagSelect
                        id={"predefined-responses-add-tag"}
                        key={this.state.keyTag}
                        selectedValues={this.state.selectedTags}
                        searchKey={"name"}
                        selectKey={"name"}
                        btnText={"Create New Tag"}
                        lblText={"Type a Tag"}
                        data={this.state.tags}
                        onChange={this.onChangeTags}
                      />
                      <div className="gap20" />
                      <div className="text-right">
                        {/*eslint-disable*/}
                        {this.state.messageId !== 0 && (
                          <a
                            className="btn btn-default margin-right-10"
                            onClick={this.onClickResetMessage.bind(this)}>
                            Cancel
                          </a>
                        )}
                        <a
                          className="btn btn-primary"
                          onClick={this.onClickAddMessage.bind(this)}>
                          {this.state.messageId === 0 ? "Save" : "Update"}
                        </a>
                        {/*eslint-enable*/}
                      </div>
                      <div className="gap20" />
                    </div>
                  </div>
                </div>
              )}
            </VelocityTransitionGroup>

            {this.state.messages.length > 0 && (
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Message</th>
                    <th>Details</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>{this.renderMessages()}</tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  genericTags: state.tagReducer.genericTags,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getGenericTags,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PredefinedResponse);
