import React from "react";
import { icons } from "./icons";

function ContentIcon(props) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: props.alignItems || "center",
        justifyContent: props.justifyContent || "center",
        fill: props.color,
      }}>
      <svg
        preserveAspectRatio="xMidYMid"
        viewBox="0 0 64 64"
        style={{ width: `${props.width}px` }}>
        {icons[props.name]}
      </svg>
    </div>
  );
}

export default ContentIcon;
