import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { VelocityTransitionGroup } from "velocity-react";
import * as $ from "jquery";
import { Scrollbars } from "react-custom-scrollbars";
import { Pie } from "react-chartjs-2";
import DatePicker from "react-date-picker";

import TitleBox from "../../../components/general/titleBox";
import ResponsesLogic from "./logic";
import { filterByTags } from "../../../helpers/filters";
import TableLoader from "../../../components/loader/table";
import moment from "moment-timezone";
import {
  UserProfileComponent,
  QuestionPoints,
  ChallengeNames,
  MessagesList,
  PointsModal,
  ViewFullConversionModal,
} from "./components";
import {
  callGetChartCountData,
  callUpdateMessage,
  callCreateMessage,
  callAddMemberTag,
  callUpdateUser,
  callUpdateAccountabilityResponseByQuery,
} from "../../../services";
import ReplyAutoResize from "../../../components/form/replyAutoResize";
import PredefinedResponse from "../../../components/general/predefinedResponse";

import RadioTag from "../../../components/form/radioTag";

import { getUniqueId, showNotification } from "../../../helpers";

class AccountabilityResponsesPage extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.respLogic = new ResponsesLogic();

    this.chartData = [0, 0];
    this.chartLabel = ["Did Not Track", "Tracked"];

    this.state = {
      users: [],
      usersBackup: [],
      userData: this.props.userData || {},
      reply: "",
      selectedUser: {},
      showViewFullConversion: false,
      filterList: [],
      atqResponseTagId: "",
      isCallAPI: true,
      trackingFromDate: new Date(),
      trackingToDate: new Date(),
      filterType: "tracked",
      filterDateType: "last-7-days",
      keyFilterType: Math.random(),
      keyTrackingDate: Math.random(),
      keyResponse: Math.random(),
      currentReply: "",
      keyAtqType: Math.random(),
      atqTypes: [],
      cmpUpdate: false,
      showLoader: true,
      pointsData: {},
      filterResponseType: "unresponded",
      keyFilterResponseType: Math.random(),
      selectedTag: {},
      attName: "",
      attValue: "",
      attId: "",
      attrRefresh: Math.random(),
    };
  }

  componentDidMount() {
    if (this.state.userData._id) {
      this.getUsers();
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.userData) {
      this.setState({ userData: newProps.userData }, () => {
        this.getUsers();
      });
    }
  }

  shouldComponentUpdate() {
    if (this.state.users.length > 0 || this.state.cmpUpdate) {
      return true;
    }
    return false;
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    if (name === "notes") {
      let selectedUser = this.state.selectedUser;
      selectedUser.notes = value;
      this.setState({ selectedUser });
    }

    this.setState({
      [name]: value,
    });
  }

  handleKeyPress(user, event) {
    if (event.keyCode === 13 && event.ctrlKey) {
      this.onClickSendReply(user);
    }
  }

  async getUsers() {
    console.log("Get Accountability Users Start :", new Date());
    let data = await this.respLogic.getUsers(this.state.userData._id);
    console.log("Get Accountability Users End :", new Date());
    console.log("Accountability Users : ", data.length);
    if (data.length === 0) {
      this.setState({ showLoader: false });
    } else {
      this.setState({ users: data, usersBackup: data }, () => {
        let date = new Date();
        date.setDate(date.getDate() - 7);
        this.callGetTagByName();
        this.prepareFilterData();

        this.getAccountabilityResponses(date);
      });
    }
  }

  async callGetTagByName() {
    let tagId = await this.respLogic.callGetTagByName();
    this.setState({ atqResponseTagId: tagId });
  }

  async prepareFilterData() {
    if (this.state.atqTypes.length > 0) {
      return;
    }
    let challengeList = await this.respLogic.getChallenges();
    this.setState({
      atqTypes: challengeList,
      keyAtqType: Math.random(),
    });
  }

  async getAccountabilityResponses(date) {
    console.log("Get Accountability Data Start: ", new Date());
    let { data, atqDate } =
      await this.respLogic.getAccountabilityResponsesByDate(
        date,
        this.state.filterDateType,
        this.state.trackingToDate
      );
    console.log("Get Accountability Data End: ", new Date());
    console.log("Accountability Responses : ", data.length);

    let { fUsers, inputRef, users } = await this.respLogic.prepareAtqData(
      this.state.userData,
      this.state.usersBackup,
      data,
      atqDate
    );

    this.inputRef = inputRef;
    console.log("Get Accountability Chart Data Start : ", new Date());
    this.getChartData(users);
    console.log("Get Accountability Chart Data End : ", new Date());

    this.setState(
      {
        users: fUsers,
        usersBackup: users,
        filterType: "tracked",
        filterResponseType: "unresponded",
        keyFilterType: Math.random(),
        keyFilterResponseType: Math.random(),
        showLoader: false,
      },
      () => {
        this.filterUsingTags(this.state.usersBackup, this.state.filterList);
      }
    );
  }

  filterUpdated(updatedFilter, name, type, searchFields) {
    let filterList = this.state.filterList;
    filterList[name] = {
      name: name,
      type: type,
      searchFields: searchFields,
      values: updatedFilter,
    };
    this.setState(
      {
        filterList: filterList,
      },
      () => {
        this.filterUsingTags(this.state.usersBackup, filterList);
      }
    );
  }

  filterUsingTags(users, filterList) {
    let data = filterByTags(users, filterList);
    this.getChartData(data);
    this.filterUsingStatus(data);
  }

  filterUsingStatus(users) {
    let fUsers = [];

    let responseStatus = this.state.filterResponseType;

    fUsers = users.filter((d) => {
      return d.responseStatus === responseStatus;
    });

    let value = this.state.filterType;
    if (value === "tracked") {
      fUsers = fUsers.filter((d) => {
        return d.atqData && d.atqData.length > 0;
      });
    } else {
      fUsers = fUsers.filter((d) => {
        return d.atqData && d.atqData.length === 0;
      });
    }

    this.setState({
      users: fUsers,
      cmpUpdate: true,
    });
  }

  updateByType(type, id) {
    let users = this.state.usersBackup;
    let value = type[Object.keys(type)[0]].value;
    this.setState({ [id]: value }, () => {
      this.filterUsingTags(users, this.state.filterList);
    });
  }

  updateByResponseType(type, id) {
    let users = this.state.usersBackup;
    let value = type[Object.keys(type)[0]].value;
    this.setState({ [id]: value }, () => {
      this.filterUsingTags(users, this.state.filterList);
    });
  }

  updateByDate(day) {
    let date = new Date();
    day = day[Object.keys(day)[0]].value;
    this.setState({ filterDateType: day }, () => {
      switch (day) {
        case "last-7-days":
          date.setDate(date.getDate() - 7);
          this.getAccountabilityResponses(date);
          break;
        case "yesterday":
          date.setDate(date.getDate() - 1);
          this.getAccountabilityResponses(date);
          break;
        case "today":
          date.setDate(date.getDate());
          this.getAccountabilityResponses(date);
          break;
        case "custom":
          this.setState({ keyTrackingDate: Math.random() });
          $(
            ".modalAlertOverlay.filterDateOverlay,.modalAlert.filterDateAlert"
          ).show();
          break;
        default:
          break;
      }
    });
  }

  getChartData(users) {
    let { chartData, chartLabel } = this.respLogic.getChartData(users);
    this.chartData = chartData;
    this.chartLabel = chartLabel;
  }

  async onClickGetPoints(user) {
    /*eslint-disable*/
    let query = {
      member_id: user._id,
    };
    /*eslint-enable*/
    let d = await callGetChartCountData(query);
    this.setState({ pointsData: d, selectedUser: user }, () => {
      $(".modalAlertOverlay.pointsOverlay,.modalAlert.pointsAlert").fadeIn(200);
    });
  }

  onClickInsertResponses(user) {
    this.setState({ selectedUser: user, keyResponse: Math.random() }, () => {
      $(
        ".modalAlertOverlay.responsesOverlay,.modalAlert.responsesAlert"
      ).fadeIn(200);
    });
  }

  onClickViewNotesFromProfile(user) {
    this.setState({ selectedUser: user }, () => {
      $(".modalAlertOverlay.notesOverlay,.modalAlert.notesAlert").show();
    });
  }

  onClickViewConversion(user) {
    this.setState({ selectedUser: user }, () => {
      $(
        ".modalAlertOverlay.conversionOverlay,.modalAlert.conversionAlert"
      ).show();
    });
  }

  onClickShowDetailsFromATQ(user) {
    let users = this.state.users;
    users.forEach((element) => {
      if (element._id === user._id) {
        element.isPendingResponse = !element.isPendingResponse;
      }
    });
    this.setState({ users });
  }

  onClickReplyFromATQ(user) {
    let users = this.state.users;
    users.forEach((element) => {
      if (element._id === user._id) {
        element.showReply = !element.showReply;
      }
    });

    setTimeout(() => {
      let ref = this.inputRef[user._id];
      if (ref) {
        ref.focus();
      }
    }, 100);

    this.setState({ users });
  }

  async onClickIgnore(user) {
    let users = this.state.users;
    let atqData = await this.updateAccountabilityResponses("ignored", user);

    users.forEach((element) => {
      if (element._id === user._id) {
        element.atqData = atqData;
        element.responseStatus = "ignored";
        element.isPendingResponse = !element.isPendingResponse;
      }
    });
    this.setState({
      users: users,
      isCallAPI: false,
      cmpUpdate: true,
    });
  }

  async updateAccountabilityResponses(status, user) {
    let atqData = user.atqData;

    var dAtqData = atqData.sort((a, b) => {
      return new Date(b.tracking_date) - new Date(a.tracking_date);
    });

    if (dAtqData.length === 0) {
      return atqData;
    }

    let recentTrackingDate = dAtqData[0].date;
    let fAtqData = dAtqData.filter((d) => {
      return (
        moment(d.tracking_date).format("DD-MM-YYYY") ===
        moment(recentTrackingDate).format("DD-MM-YYYY")
      );
    });

    let ids = [];
    fAtqData.forEach((element) => {
      ids.push(element._id);
      element["response_status"] = status;
    });

    /*eslint-disable*/
    let updatedData = {
      response_status: status,
    };
    /*eslint-enable*/

    await callUpdateAccountabilityResponseByQuery(updatedData, {
      /*eslint-disable*/
      query: { _id: { $in: ids }, member_id: user._id },
      /*eslint-enable*/
    });
    return atqData;
  }

  async updateUserForAtqMessage(user, updateData, message) {
    showNotification("success", "Sent message successfully", 4000);
    let users = this.state.users;
    let atqData = await this.updateAccountabilityResponses("responded", user);

    users.forEach((element) => {
      if (element._id === user._id) {
        element.showReply = !element.showReply;
        element.atqData = atqData;
        element.responseStatus = "responded";
        element.isPendingResponse = !element.isPendingResponse;
        element.memberAtqMessage = updateData;
        message["userInfo"] = this.state.users.filter((u) => {
          return u._id === message.member_id;
        })[0];
        element.atqMessages.push(message);
      }
    });
    this.setState({
      users: users,
      reply: "",
      isCallAPI: false,
      cmpUpdate: true,
    });
    this.callAtqResponseTagUpdateAPI(user);
  }

  callAtqResponseTagUpdateAPI(user) {
    if (this.state.atqResponseTagId) {
      let apiData = {
        action: "add-member-tag",
        data: {
          /*eslint-disable*/
          member_id: user._id,
          tag_id: this.state.atqResponseTagId,
          /*eslint-enable*/
        },
      };
      callAddMemberTag(apiData);
    }
  }

  async onClickSendReply(user) {
    let reply = this.inputRef[user._id].value.trim();
    if (reply.length === 0) {
      showNotification("error", "Please enter reply");
      return;
    }

    /*eslint-disable*/

    let memberAtqMessage = user.memberAtqMessage;

    let message = {
      member_id: this.state.userData._id,
      message: reply,
      status: "sent",
      time: new Date(),
      atq_date: user.atqDate,
    };

    if (memberAtqMessage) {
      // update the already atq message
      let updateData = await callUpdateMessage(memberAtqMessage._id, {
        $push: { message_list: message },
      });
      if (updateData._id) {
        this.updateUserForAtqMessage(user, updateData, message);
      }
    } else {
      // create the new one if no atq message
      let apiData = {
        member_from_id: user._id,
        member_to_id: "",
        assigned_staff_id: "",
        title: "Accountability Tracking Quiz",
        atq_feedback: true,
        message_list: [message],
      };
      let createData = await callCreateMessage(apiData);
      if (createData._id) {
        this.updateUserForAtqMessage(user, createData, message);
      }
    }
    /*eslint-enable*/
  }

  async onClickSaveFromViewNotesModal() {
    let users = this.state.users;
    users.forEach((element) => {
      if (element._id === this.state.selectedUser._id) {
        element.notes = this.state.selectedUser.notes;
      }
    });
    this.setState({ users });

    /*eslint-disable*/
    let d = await callUpdateUser(this.state.selectedUser._id, {
      notes: this.state.selectedUser.notes,
    });
    if (d._id) {
      showNotification("success", "Note changed", 4000);
    }
    $(".modalAlertOverlay.notesOverlay,.modalAlert.notesAlert").hide();
    /*eslint-enable*/
  }

  renderSendReplyComponent(user) {
    return (
      <div className="responseButtons">
        <div>
          <ReplyAutoResize
            label="Enter your reply"
            onRef={(input) => {
              let inputRef = this.inputRef;
              if (inputRef) {
                inputRef[user._id] = input;
                this.inputRef = inputRef;
              }
            }}
          />
        </div>
        <div className="gap20" />
        <div className="text-left">
          <button
            className="btn btn-primary hideResponseBody margin-right-10"
            onClick={this.onClickSendReply.bind(this, user)}>
            Send
          </button>
          <button
            className="btn btn-primary text-right"
            onClick={this.onClickInsertResponses.bind(this, user)}>
            Insert Predefined Message
          </button>
        </div>
      </div>
    );
  }

  onClickCloseFromPointsModal() {
    $(".modalAlertOverlay.pointsOverlay,.modalAlert.pointsAlert").fadeOut(200);
  }

  onClickCloseFromResponsesModal() {
    $(".modalAlertOverlay.responsesOverlay,.modalAlert.responsesAlert").fadeOut(
      200
    );
  }

  onClickCloseFromViewConversionModal() {
    $(
      ".modalAlertOverlay.conversionOverlay,.modalAlert.conversionAlert"
    ).hide();
  }

  onClickCloseFromViewNotesModal() {
    $(".modalAlertOverlay.notesOverlay,.modalAlert.notesAlert").hide();
  }

  onChangeTrackingFromDate(date) {
    this.setState({
      trackingFromDate: date,
    });
  }

  onChangeTrackingToDate(date) {
    this.setState({
      trackingToDate: date,
    });
  }

  onClickCloseFromTrackingDateModal() {
    $(
      ".modalAlertOverlay.filterDateOverlay,.modalAlert.filterDateAlert"
    ).hide();
  }

  onClickDoneFromTrackingDateModal() {
    $(
      ".modalAlertOverlay.filterDateOverlay,.modalAlert.filterDateAlert"
    ).hide();
    this.getAccountabilityResponses(this.state.trackingFromDate);
  }

  renderPointsModal() {
    let selectedUser = this.state.selectedUser;
    let pointsData = this.state.pointsData;
    if (pointsData.totalPoints === undefined) {
      return;
    }

    return (
      <PointsModal
        selectedUser={selectedUser}
        pointsData={pointsData}
        onClickCloseFromPointsModal={this.onClickCloseFromPointsModal.bind(
          this
        )}
      />
    );
  }

  onClickTagInfoModal(user, data) {
    let tag = data.info.question_info.included_tags[0];
    if (tag) {
      let tagList = user.tag_list || [];
      let fTags = tagList.filter((d) => d.id === tag);
      fTags.sort((a, b) => new Date(b.created) - new Date(a.created));
      if (fTags.length > 0) {
        this.setState({ selectedTag: fTags[0], selectedUser: user }, () => {
          $(
            ".modalAlertOverlay.tagUpdateOverlay,.modalAlert.tagUpdateAlert"
          ).show();
        });
      }
    }
  }

  onPressCreateCustomValue() {
    let tag = this.state.selectedTag;
    let attributes = tag.attributes || [];
    if (this.state.attName.trim().length === 0) {
      showNotification("error", "Attribute name is required");
      return;
    }
    if (this.state.attValue.trim().length === 0) {
      showNotification("error", "Attribute value is required");
      return;
    }
    if (this.state.attId) {
      attributes.forEach((element) => {
        if (element.id === this.state.attId) {
          element.name = this.state.attName;
          element.value = this.state.attValue;
        }
      });
    } else {
      attributes.push({
        name: this.state.attName,
        value: this.state.attValue,
        id: getUniqueId(),
      });
    }

    tag.attributes = attributes;
    this.setState({ selectedTag: tag, attName: "", attValue: "", attId: "" });
  }

  onClickDeleteCustomValue(i) {
    let tag = this.state.selectedTag;
    let attributes = tag.attributes || [];
    attributes = attributes.filter((d) => d.id !== i.id);
    tag.attributes = attributes;
    this.setState({ selectedTag: tag, attrRefresh: Math.random() });
  }

  onClickEditCustomValue(i) {
    let tag = this.state.selectedTag;
    let attributes = tag.attributes || [];
    let attInfo = attributes.filter((d) => d.id === i.id)[0];
    this.setState({
      attName: attInfo.name,
      attValue: attInfo.value,
      attId: attInfo.id,
    });
  }

  renderTagUpdateAlert() {
    let attributes = this.state.selectedTag.attributes || [];
    let modalHeight = window.innerHeight - 250;

    return (
      <div>
        <div className="modalAlertOverlay tagUpdateOverlay" />
        <div className="modalAlert tagUpdateAlert">
          <p>Update Tag Attributes - {this.state.selectedTag.title}</p>
          <div className="container-fluid">
            <Scrollbars
              autoHide
              autoHideTimeout={1000}
              autoHideDuration={200}
              style={{ height: modalHeight }}>
              <div className="gap20" />
              <div className="card">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-6">
                      <div
                        className={
                          this.state.attName !== ""
                            ? "mdInput mdFocussed"
                            : "mdInput"
                        }>
                        <label>Name</label>
                        <input
                          type="text"
                          name="attName"
                          onChange={this.handleInputChange.bind(this)}
                          value={this.state.attName}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div
                        className={
                          this.state.attValue !== ""
                            ? "mdInput mdFocussed"
                            : "mdInput"
                        }>
                        <label>Value</label>
                        <input
                          type="text"
                          name="attValue"
                          onChange={this.handleInputChange.bind(this)}
                          value={this.state.attValue}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="gap20" />
                  <button
                    className="btn btn-primary"
                    onClick={() => this.onPressCreateCustomValue()}>
                    {this.state.attId
                      ? "Update Custom Value"
                      : "Create Custom Value"}
                  </button>
                  <div className="gap10" />
                  {attributes.length > 0 && (
                    <div key={this.state.attrRefresh}>
                      <div className="gap20" />
                      <label>Added Custom Values</label>
                      <div className="gap20" />
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Value</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {attributes.map((i) => (
                            <tr key={i.name}>
                              <td>{i.name}</td>
                              <td>{i.value}</td>

                              <td className="min-width-100">
                                <button
                                  className="btn btn-default btn-sm border-0 margin-right-10"
                                  onClick={this.onClickEditCustomValue.bind(
                                    this,
                                    i
                                  )}>
                                  <i className="fa fa-pencil" />
                                </button>
                                <button
                                  className="btn btn-danger btn-sm border-0"
                                  onClick={this.onClickDeleteCustomValue.bind(
                                    this,
                                    i
                                  )}>
                                  <i className="fa fa-trash" />
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </Scrollbars>
          </div>

          <div className="alertFooterBtns">
            <button
              className="btn btn-default closeModal-No margin-right-10"
              onClick={this.onClickTagUpdateNoAlert}>
              Cancel
            </button>
            <button
              className="btn btn-primary closeModal-Yes"
              onClick={this.onClickTagUpdateYesAlert}>
              Update
            </button>
          </div>
        </div>
      </div>
    );
  }

  onClickTagUpdateNoAlert = () => {
    $(".modalAlertOverlay.tagUpdateOverlay,.modalAlert.tagUpdateAlert").hide();
  };

  onClickTagUpdateYesAlert = async () => {
    let tags = this.state.selectedUser.tag_list || [];
    let newTags = [];
    tags.forEach((element) => {
      if (element.member_tag_id === this.state.selectedTag.member_tag_id) {
        if (element.modification === undefined) {
          element = { ...element, modification: [] };
        }

        var object = {
          modifiedBy: this.state.selectedTag.member_tag_id,
          originalDate: element.created,
          created: new Date(),
        };

        element.attributes = this.state.selectedTag.attributes;
        element.modification.push(object);
      }
      newTags.push(element);
    });

    /*eslint-disable*/
    await callUpdateUser(this.state.selectedUser._id, { tag_list: newTags });
    showNotification("success", "Tag Updated Successfully", 4000);
    /*eslint-enable*/
    $(".modalAlertOverlay.tagUpdateOverlay,.modalAlert.tagUpdateAlert").hide();
  };

  renderAtqResponses() {
    var returnVals = [];
    let users = this.respLogic.getActiveUsers(this.state.users);
    for (let i = 0; i < users.length; i++) {
      const element = users[i];

      returnVals.push(
        <div className="container-fluid" key={i}>
          <div className="card">
            <div className="list-group list-group-flush">
              <VelocityTransitionGroup
                enter={{ animation: "slideDown" }}
                leave={{ animation: "slideUp" }}>
                {element.isPendingResponse === true && (
                  <div>
                    <div className="list-group-item">
                      <div className="clearfix">
                        <div className="row">
                          <div className="col-sm-8">
                            <h5 className="noMargin">Pending Response</h5>
                          </div>
                          <div className="col-sm-2">
                            <p className="noMargin">
                              {moment(element.atqDate).format("MMMM Do, YYYY")}
                            </p>
                          </div>
                          <div className="col-sm-2">
                            <button
                              className="btn btn-sm btn-default btn-block"
                              onClick={this.onClickShowDetailsFromATQ.bind(
                                this,
                                element
                              )}>
                              Hide Details
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="list-group-item responseBody">
                      <div className="container-fluid">
                        <div className="gap20" />
                        <div className="row">
                          <UserProfileComponent
                            user={element}
                            onClickGetPoints={this.onClickGetPoints.bind(this)}
                            onClickViewNotesFromProfile={this.onClickViewNotesFromProfile.bind(
                              this
                            )}
                          />
                          <div className="col-sm-9 col-md-10">
                            {element.atqData.length === 0 && (
                              <div className="text-center">
                                <h6 className="text-primary">Did Not Track</h6>
                              </div>
                            )}
                            <div className="text-center">
                              <h5>
                                <ChallengeNames user={element} />
                              </h5>
                            </div>
                            <div className="gap10" />
                            <div className="ble-responsive">
                              <table className="table table-bordered">
                                <QuestionPoints
                                  user={element}
                                  onClickTag={this.onClickTagInfoModal.bind(
                                    this,
                                    element
                                  )}
                                />
                              </table>
                            </div>
                            <div className="gap20" />
                            <MessagesList user={element} />
                            <div className="text-right">
                              {element.atqMessages.length > 0 && (
                                <button
                                  className="btn btn-default btn-sm margin-right-10"
                                  onClick={this.onClickViewConversion.bind(
                                    this,
                                    element
                                  )}>
                                  View Full Conversation
                                </button>
                              )}
                              <button
                                className="btn btn-default btn-sm"
                                onClick={this.onClickReplyFromATQ.bind(
                                  this,
                                  element
                                )}>
                                Reply
                              </button>
                            </div>

                            <VelocityTransitionGroup
                              enter={{ animation: "fadeIn" }}
                              leave={{ animation: "fadeOut" }}>
                              {element.showReply &&
                                this.renderSendReplyComponent(element)}
                            </VelocityTransitionGroup>
                            <div className="gap10" />
                            {element.responseStatus !== "ignored" &&
                              element.atqData.length > 0 && (
                                <div className="text-right">
                                  <button
                                    className="btn btn-default btn-sm"
                                    onClick={this.onClickIgnore.bind(
                                      this,
                                      element
                                    )}>
                                    Ignore
                                  </button>
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="gap20" />
                      </div>
                    </div>
                  </div>
                )}
              </VelocityTransitionGroup>

              {element.isPendingResponse === false && (
                <div className="list-group-item">
                  <div className="row">
                    <div className="col-sm-4">
                      <p className="noMargin firstLetter">{element.name}</p>
                    </div>
                    <div className="col-sm-6">
                      <p className="noMargin">
                        <b>
                          <ChallengeNames user={element} />
                        </b>
                      </p>
                    </div>
                    <div className="col-sm-2">
                      <button
                        className="btn btn-sm btn-default btn-block"
                        onClick={this.onClickShowDetailsFromATQ.bind(
                          this,
                          element
                        )}>
                        Show Details
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }
    return returnVals;
  }

  onClickInsertMessage(data) {
    this.onClickCloseFromResponsesModal();

    let reply = data.message.replace(
      /{{first_name}}/gi,
      this.state.selectedUser.name.split(" ")[0]
    );

    this.inputRef[this.state.selectedUser._id].value += reply;
  }

  renderResponsesModal() {
    let modalHeight = window.innerHeight - 200;
    return (
      <div>
        <div className="modalAlertOverlay responsesOverlay" />
        <div className="modalAlert responsesAlert modal-lg big">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Predefined Responses
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.onClickCloseFromResponsesModal.bind(this)}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div
              className="container-fluid modalInnerBody"
              style={{ maxHeight: modalHeight, overflowY: "hidden" }}>
              <Scrollbars
                autoHide
                autoHideTimeout={1000}
                autoHideDuration={200}
                style={{ height: modalHeight }}>
                <PredefinedResponse
                  key={this.state.keyResponse}
                  message={this.state.currentReply}
                  onClickInsertMessage={this.onClickInsertMessage.bind(this)}
                />
              </Scrollbars>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderViewNotesModal() {
    return (
      <div>
        <div className="modalAlertOverlay notesOverlay" />
        <div className="modalAlert notesAlert modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Customer Notes
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.onClickCloseFromViewNotesModal.bind(this)}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="card">
                <div className="container-fluid">
                  <div className="gap10" />
                  <div
                    className={
                      this.state.selectedUser.notes !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>Note</label>
                    <textarea
                      name="notes"
                      value={this.state.selectedUser.notes}
                      onChange={(event) => this.handleInputChange(event)}
                    />
                  </div>
                  <div className="gap10" />
                  <div className="text-right">
                    <button
                      className="btn btn-sm btn-default"
                      onClick={this.onClickSaveFromViewNotesModal.bind(this)}>
                      SAVE
                    </button>
                  </div>
                  <div className="gap10" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderCustomDateModal() {
    return (
      <div>
        <div className="modalAlertOverlay filterDateOverlay" />
        <div className="modalAlert filterDateAlert">
          <p> Filter By Date </p>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6">
                <label className="inputFakeLabelFocussed">
                  Select From Date
                </label>
                <div>
                  <DatePicker
                    key={this.state.keyTrackingDate}
                    maxDate={new Date()}
                    value={this.state.trackingFromDate}
                    onChange={this.onChangeTrackingFromDate.bind(this)}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <label className="inputFakeLabelFocussed">Select To Date</label>
                <div>
                  <DatePicker
                    key={this.state.keyTrackingDate}
                    maxDate={new Date()}
                    value={this.state.trackingToDate}
                    onChange={this.onChangeTrackingToDate.bind(this)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="alertFooterBtns">
            <button
              className="btn btn-default dismissThisModal margin-right-10"
              onClick={this.onClickCloseFromTrackingDateModal.bind(this)}>
              Close
            </button>
            <button
              className="btn btn-primary dismissThisModal"
              onClick={this.onClickDoneFromTrackingDateModal.bind(this)}>
              Done
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderFilterView() {
    return (
      <div className="card">
        <div className="list-group list-group-flush">
          {/* <div className="list-group-item">
            <p>Filter By Challenge</p>
            <FilterList
              key={this.state.keyAtqType}
              filterList={this.state.atqTypes}
              name={"searchType"}
              type={"single"}
              searchFields={[
                "member_challenges->name",
                "member_products->name",
              ]}
              onFinish={this.filterUpdated.bind(this)}
            />
          </div> */}
          <div className="list-group-item">
            <p>Filter By Type</p>
            <div className="tabsHolder radioTabs">
              <RadioTag
                key={this.state.keyFilterType}
                onCheckChange={this.updateByType.bind(this)}
                labelList={[
                  { name: "Tracked", value: "tracked" },
                  { name: "Did not Track", value: "nottracked" },
                ]}
                selectedList={[this.state.filterType]}
                id={"filterType"}
                selectType={"value"}
              />
            </div>
          </div>

          <div className="list-group-item">
            <p>Filter By Date</p>
            <div className="tabsHolder radioTabs">
              <RadioTag
                onCheckChange={this.updateByDate.bind(this)}
                selectedList={[this.state.filterDateType]}
                selectType={"value"}
                labelList={[
                  { name: "Last 7 Days", value: "last-7-days" },
                  { name: "Yesterday", value: "yesterday" },
                  { name: "Today", value: "today" },
                  { name: "Custom", value: "custom" },
                ]}
              />
            </div>
          </div>
          <div className="list-group-item">
            <p>Filter By Response</p>
            <div className="tabsHolder radioTabs">
              <RadioTag
                key={this.state.keyFilterResponseType}
                onCheckChange={this.updateByResponseType.bind(this)}
                selectedList={[this.state.filterResponseType]}
                selectType={"value"}
                id={"filterResponseType"}
                labelList={[
                  { name: "Unresponded", value: "unresponded" },
                  { name: "Responded", value: "responded" },
                  { name: "Ignored", value: "ignored" },
                ]}
              />
            </div>
          </div>
          <div className="list-group-item">
            <div className="gap10" />
            <div className="row">
              <div className="col-md-4">
                <Pie
                  legend={{ reverse: true }}
                  data={{
                    labels: this.chartLabel,
                    datasets: [
                      {
                        data: this.chartData,
                        backgroundColor: ["#FF6384", "#36A2EB"],
                        hoverBackgroundColor: ["#FF6384", "#36A2EB"],
                      },
                    ],
                  }}
                />
              </div>
            </div>
            <div className="gap10" />
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderPointsModal()}
        {this.renderResponsesModal()}
        {this.renderViewNotesModal()}
        {this.renderCustomDateModal()}
        {this.renderTagUpdateAlert()}
        <ViewFullConversionModal
          selectedUser={this.state.selectedUser}
          onClickCloseFromViewConversionModal={
            this.onClickCloseFromViewConversionModal
          }
        />
        <div className="container-fluid">
          <TitleBox title="Accountability Tracking Feedback" />
          <div className="gap20" />
          {this.renderFilterView()}
        </div>
        {this.state.filterType === "nottracked" && (
          <div className="container-fluid">
            <div className="card">
              <div className="container-fluid">
                <div className="gap10" />
                <p>
                  Displaying everyone who did not track who is currently active
                  in a challenge
                </p>
              </div>
            </div>
          </div>
        )}
        {!this.state.showLoader && this.renderAtqResponses()}
        {this.state.showLoader && (
          <div className="container-fluid">
            <div className="card">
              <div className="container-fluid">
                <TableLoader />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ userData: state.authReducer.userData });

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountabilityResponsesPage);
