import config from "../helpers/config";
import { showLoading, hideLoading, callApi } from "../helpers";
import * as api from "./api";
import moment from "moment-timezone";
import { getItem } from "../helpers/storage";
import axios from "axios";
import PubSub from "pubsub-js";

const DOMAIN_ID = window.location.hostname;

function callUploadImageAPI(url, data, type = "image") {
  let headers = {};

  let token = getItem("accessToken");
  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  headers["domainid"] = window.location.hostname;

  var request = new Request(url, {
    method: "POST",
    body: data,
    headers: headers,
  });
  showLoading();

  if (type === "file") {
    return axios
      .request({
        method: "post",
        url: url,
        data: data,
        headers: headers,
        onUploadProgress: (progress) => {
          const { total, loaded } = progress;
          const totalSizeInMB = (total / 1000000).toFixed(0);
          const loadedSizeInMB = (loaded / 1000000).toFixed(0);
          const percentage = ((loaded / total) * 100).toFixed(0);
          const fileProgress = `${loadedSizeInMB}/${totalSizeInMB} MB (${percentage}%)`;
          PubSub.publish("fileProgress", fileProgress);
        },
      })
      .then((data) => {
        hideLoading();
        return data.data;
      })
      .catch((err) => {
        hideLoading();
        return err;
      });
  } else {
    return fetch(request)
      .then((res) => {
        hideLoading();
        return res;
      })
      .catch((err) => {
        hideLoading();
        return err;
      });
  }
}

function uploadImage(file, fileName = "", type = "") {
  let data = new FormData();
  data.append("file", file);

  let url = `${config.urls.apiUrl}/uploads?domainid=${DOMAIN_ID}&type=single`;

  if (fileName) {
    url += "&filename=" + fileName;
  }
  if (type) {
    url += "&itype=" + type;
  }

  return callUploadImageAPI(url, data, type);
}

function uploadImages(files, type = "") {
  let data = new FormData();

  for (let i = 0; i < files.length; i++) {
    const element = files[i];
    data.append("file" + i, element);
  }

  let url = `${config.urls.apiUrl}/multi_uploads?domainid=${DOMAIN_ID}&type=multi`;

  return callUploadImageAPI(url, data, type);
}

function callAddCustomLog(data) {
  return api.service("custom-log").create(data);
}

function callAddLink(data) {
  return api.service("email-link").create(data);
}

function callRemoveLink(id) {
  return api.service("email-link").remove(id);
}

function callGetLinks(query = {}) {
  return api.service("email-link").find(query);
}

function callCreateSubscriptionHistory(data, auth = true) {
  return api.service("subscription-history", auth).create(data);
}

function callGetMessages(query) {
  return api.service("message").find(query);
}

function callGetDashboardChartData(query) {
  return api.service("general").get("dashboard-chart", {
    query: query,
  });
}

function callGetChartCountData(query) {
  return api.service("general").get("member-chart-count", {
    query: query,
  });
}

function callGetGoogleCalendarList(query) {
  return api.service("general").get("get-google-calendar-list", {
    query: query,
  });
}

function callRemoveLoggedinCustomer(query) {
  return api.service("general").get("remove-loggedin-customer-entry", {
    query: query,
  });
}

function callGetCompanyDashboardChartData(query) {
  return api.service("general").get("company-dashboard-chart", {
    query: query,
  });
}

function callSearchMaterialIcons(query) {
  return api.service("general").get("search-material-icons", {
    query: query,
  });
}

function callGetPopularMaterialIcons() {
  return api.service("general").get("popular-material-icons", {});
}

function callGetCustomNewsLetterData(query) {
  return api.service("general").get("newsletter", {
    query: query,
  });
}

function callGetStripeAccountBalance() {
  return callApi(api.service("general").get("account-balance"));
}

function callGetAtqQuestionsUsingTags(query, isLoading = true) {
  return callApi(
    api.service("general").get("get-atq-questions", { query: query }),
    isLoading
  );
}

function callGetResourcesUsingTags(query) {
  return callApi(api.service("general").get("get-resources", { query: query }));
}

function callGetSubscriptionAreaUsingTags(query, isLoading = true) {
  return callApi(
    api.service("general").get("get-subscription-area", { query: query }),
    isLoading
  );
}

function callGetTagInfo(query) {
  return callApi(api.service("general").get("get-tag-info", { query: query }));
}

function callGetUsersV2() {
  return callApi(api.service("general").get("get-users"));
}

function callRunCron(query, auth = true) {
  return callApi(
    api.service("general", auth).get("run-cron", {
      query: query,
    })
  );
}

function callGetDemioEvents() {
  return callApi(api.service("general").get("get-demio-events"));
}

function callGetAwsImageCount(query) {
  return callApi(
    api.service("general").get("get-aws-bucket-count", { query: query })
  );
}

function callGetStripeSubscription(query, isLoading = true) {
  return callApi(
    api.service("general").get("stripe-subscription", { query: query }),
    isLoading
  );
}

function callGetLeaderBoard(query) {
  return callApi(api.service("general").get("leaderboard", { query: query }));
}

function callAddMemberPayment(data) {
  return callApi(api.service("general").create(data));
}

function callDeleteAwsImage(data) {
  return callApi(api.service("general").create(data));
}

function callResizeAwsImage(data, isLoading = true) {
  return callApi(api.service("general").create(data), isLoading);
}

function callGetTagFilterUsers(data) {
  return callApi(api.service("general").create(data));
}

function callGetUserMessages(data) {
  return callApi(api.service("general").create(data));
}

function callImportAwsImage(data) {
  return callApi(api.service("general").create(data));
}

function callUpdateMultiAwsImages(data) {
  return callApi(api.service("general").create(data));
}

function callSendMail(data) {
  return callApi(api.service("general").create(data));
}

function callAffiliateTrack(data) {
  return callApi(api.service("general").create(data));
}

function callProcessOrderTagInfo(data) {
  return callApi(api.service("general").create(data));
}

function callExecuteTaskRules(data) {
  return callApi(api.service("general").create(data));
}

function callGetFilteredTasks(data) {
  return callApi(api.service("general").create(data));
}

function callPayTaskInvoices(data) {
  return callApi(api.service("general").create(data));
}

function callProcessUpcomingPayment(data) {
  return callApi(api.service("general").create(data));
}

function callGetStripePayments(query, isLoading = true) {
  return callApi(api.service("stripe-payment").find(query), isLoading);
}

function callUpdateStripePayment(id, data) {
  return callApi(api.service("stripe-payment").patch(id, data));
}

function callGetStripeSubscriptions(query, isLoading = true) {
  return callApi(api.service("stripe-subscription").find(query), isLoading);
}

function callUpdateStripeCharge(id, data) {
  return callApi(api.service("stripe-charge").patch(id, data));
}

function callGetStripeChargeByQuery(query) {
  return callApi(api.service("stripe-charge").find(query));
}

function callUpdateStripeSubscription(id, data) {
  return callApi(api.service("stripe-subscription").patch(id, data));
}

function callCreateEditorDraft(data) {
  return callApi(api.service("content-editor-draft").create(data));
}

function callGetEditorDraftById(id) {
  return callApi(api.service("content-editor-draft").get(id));
}

function callGetEditorDrafts(query) {
  return callApi(api.service("content-editor-draft").find(query));
}

function callGetPageEditorDrafts(query) {
  return callApi(api.service("content-editor-draft/page").find(query));
}

function callCreatePredefinedResponse(data) {
  return callApi(api.service("predefined-responses").create(data));
}

function callUpdatePredefinedResponse(id, data) {
  return callApi(api.service("predefined-responses").patch(id, data));
}

function callGetPredefinedResponses(query) {
  return callApi(api.service("predefined-responses").find(query));
}

function callCreatePreSurvey(data) {
  return callApi(api.service("pre-survey").create(data));
}

function callUpdatePreSurvey(id, data) {
  return callApi(api.service("pre-survey").patch(id, data));
}

function callGetPreSurveyUsingQuery(query) {
  return callApi(api.service("pre-survey").find(query));
}

function callGetPartnerLeads(query) {
  return callApi(api.service("partner-leads").find(query));
}

function callUpdatePartnerLeads(id, data) {
  return callApi(api.service("partner-leads").patch(id, data));
}

function callGetAffiliateTracks(query, isLoading = true) {
  return callApi(api.service("affiliate-track").find(query), isLoading);
}

function callGetDashboardEvents(query) {
  return callApi(
    api.service("general").get("get-dashboard-events", { query: query })
  );
}

function callGetAffiliateReport() {
  return callApi(
    api.service("general").get("get-affiliate-report", { query: {} })
  );
}

function callCreatePartnerSubscription(data) {
  let url = config.urls.apiUrl + "/partner-subscription";

  let headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  let token = getItem("accessToken");
  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  var request = new Request(url, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(data),
  });
  showLoading();

  return fetch(request)
    .then((res) => {
      hideLoading();
      return res.json();
    })
    .catch((err) => {
      hideLoading();
      return err;
    });
}

function callGetIPAddress() {
  let url = config.urls.apiUrl + "/ipaddress";

  var request = new Request(url, {
    method: "GET",
  });

  return fetch(request)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

function callGetReports(query, isLoading = false) {
  query["timezone"] = moment.tz.guess();
  return callApi(
    api.service("reports").get("get-reports", {
      query: query,
    }),
    isLoading
  );
}

function callProcessTextMessageRule(data) {
  return callApi(api.service("general").create(data));
}

function callCreateLandingPageVisit(id) {
  let url = `${config.urls.apiUrl}/landing-page-visit?id=${id}&domainid=${DOMAIN_ID}`;

  let headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  let token = getItem("accessToken");
  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  var request = new Request(url, {
    method: "GET",
    headers: headers,
  });
  showLoading();

  return fetch(request)
    .then((res) => {
      hideLoading();
      return res.json();
    })
    .catch((err) => {
      hideLoading();
      return err;
    });
}

function callGetAPIMemberChallenges(query) {
  return callApi(api.service("api/member/challenges").find(query));
}

function callGetSqpProducts() {
  let url = config.urls.apiUrl + "/sqp/products";

  let headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  let token = getItem("accessToken");
  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }
  headers["domainid"] = window.location.hostname;

  var request = new Request(url, {
    method: "GET",
    headers: headers,
  });

  return fetch(request)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

function callAddShortLink(data) {
  return api.service("general").create(data);
}

function callGetPayTaskHistory() {
  return callApi(api.service("general").get("get-pay-task-history"));
}

function callGeneralPost(data) {
  return callApi(api.service("general").create(data));
}

export {
  callGeneralPost,
  uploadImage,
  uploadImages,
  callAddCustomLog,
  callAddLink,
  callGetLinks,
  callCreateSubscriptionHistory,
  callGetDashboardChartData,
  callGetCustomNewsLetterData,
  callGetStripeAccountBalance,
  callAddMemberPayment,
  callGetIPAddress,
  callRunCron,
  callGetStripePayments,
  callGetChartCountData,
  callUpdateStripeCharge,
  callGetStripeSubscriptions,
  callCreateEditorDraft,
  callGetEditorDrafts,
  callCreatePredefinedResponse,
  callGetPredefinedResponses,
  callUpdatePredefinedResponse,
  callCreatePreSurvey,
  callGetPartnerLeads,
  callUpdatePartnerLeads,
  callCreatePartnerSubscription,
  callGetReports,
  callUpdateStripeSubscription,
  callSendMail,
  callRemoveLink,
  callGetCompanyDashboardChartData,
  callUpdatePreSurvey,
  callGetPreSurveyUsingQuery,
  callDeleteAwsImage,
  callResizeAwsImage,
  callAffiliateTrack,
  callGetAffiliateTracks,
  callGetEditorDraftById,
  callGetDemioEvents,
  callImportAwsImage,
  callGetAwsImageCount,
  callUpdateMultiAwsImages,
  callUpdateStripePayment,
  callGetStripeChargeByQuery,
  callGetUsersV2,
  callGetLeaderBoard,
  callProcessUpcomingPayment,
  callGetStripeSubscription,
  callGetTagFilterUsers,
  callGetAtqQuestionsUsingTags,
  callGetSubscriptionAreaUsingTags,
  callGetResourcesUsingTags,
  callGetPageEditorDrafts,
  callGetDashboardEvents,
  callProcessOrderTagInfo,
  callGetAffiliateReport,
  callGetTagInfo,
  callRemoveLoggedinCustomer,
  callGetMessages,
  callGetUserMessages,
  callProcessTextMessageRule,
  callCreateLandingPageVisit,
  callGetAPIMemberChallenges,
  callGetGoogleCalendarList,
  callGetSqpProducts,
  callAddShortLink,
  callExecuteTaskRules,
  callGetFilteredTasks,
  callPayTaskInvoices,
  callGetPayTaskHistory,
  callSearchMaterialIcons,
  callGetPopularMaterialIcons,
};
