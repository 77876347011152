import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { VelocityTransitionGroup } from "velocity-react";

import MessageBox from "../../../components/general/messageBox";
import TitleBox from "../../../components/general/titleBox";
import { showNotification } from "../../../helpers";
import {
  callUpdateMessage,
  callGetSystemTagUsingName,
  callAddMemberTag,
  uploadImages,
} from "../../../services";
import config from "../../../helpers/config";

class SendMessage extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    let message = this.props.history.location.state.message;
    let userInfo = this.props.history.location.state.userInfo;

    this.state = {
      userData: this.props.userData || {},
      userInfo: userInfo,
      users: [],
      reply: "",
      message: message || [],
      files: [],
      addAttachment: false,
    };
  }

  componentDidMount() {
    this.callGetTagByName();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.userData) {
      this.setState({ userData: newProps.userData });
      this.callGetTagByName();
    }
  }

  callGetTagByName() {
    let personalMessageTag = config.tags.personalMessageTag;

    callGetSystemTagUsingName(personalMessageTag).then((d) => {
      if (d.data.length > 0) {
        this.setState({ personalMessageTagId: d.data[0]._id });
      }
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  handleFileUpload(event) {
    this.setState({ files: event.target.files });
  }

  async onClickSendReply() {
    // send reply

    if (this.state.reply.trim().length === 0) {
      showNotification("error", "Please enter reply", 3000);
      return;
    }

    /*eslint-disable*/

    let fileUrls = [];

    if (this.state.files.length > 0) {
      let resp = await uploadImages(this.state.files);
      let data = await resp.json();
      if (data.file_names) {
        data.file_names.forEach((element) => {
          fileUrls.push({ file: element.key, name: element.originalname });
        });
      }
    }

    let messageInfo = this.state.message;

    let message = {
      member_id: this.state.userData._id,
      message: this.state.reply,
      status: "sent",
      time: new Date(),
      atq_date: new Date(),
      files: fileUrls,
    };

    callUpdateMessage(messageInfo._id, {
      $push: { message_list: message },
    }).then((updateData) => {
      if (updateData._id) {
        this.callTagUpdateAPI();
      }
    });

    /*eslint-enable*/
  }

  callTagUpdateAPI() {
    if (this.state.personalMessageTagId) {
      let apiData = {
        action: "add-member-tag",
        data: {
          /*eslint-disable*/
          member_id: this.state.userInfo._id,
          tag_id: this.state.personalMessageTagId,
          /*eslint-enable*/
        },
      };
      callAddMemberTag(apiData);
    }
    this.props.history.goBack();
  }

  onClickAddAttachment() {
    this.setState({ addAttachment: !this.state.addAttachment });
  }

  render() {
    return (
      <div className="container-fluid">
        <TitleBox
          title={`${this.state.userInfo.name}'s Messages`}
          showBackBtn={true}
        />
        <div className="gap20" />

        <div className="card">
          <div className="list-group-item responseButtons">
            <div className="mdInput">
              <label>Enter your reply</label>
              <textarea
                name="reply"
                value={this.state.reply}
                onChange={(event) => this.handleInputChange(event)}
              />
            </div>
            <div className="gap20" />
            <VelocityTransitionGroup
              enter={{ animation: "slideDown" }}
              leave={{ animation: "slideUp" }}>
              {this.state.addAttachment && (
                <input
                  ref={(ref) => (this.fileInput = ref)}
                  type="file"
                  onChange={this.handleFileUpload.bind(this)}
                  multiple
                />
              )}
            </VelocityTransitionGroup>
            <div className="gap20" />
            <div className="text-right">
              {/*eslint-disable*/}
              <a
                className="btn btn-primary hideResponseBody margin-right-10"
                onClick={this.onClickSendReply.bind(this)}>
                Send
              </a>
              <a className="btn btn-primary text-right">
                Insert Predefined Message
              </a>
              {/*eslint-enable*/}
            </div>
          </div>
        </div>

        <div className="card">
          <div className="list-group list-group-flush">
            <div className="list-group-item">
              <div className="gap20" />
              <MessageBox
                messages={this.state.message.message_list}
                from={"messageList"}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.authReducer.userData,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(SendMessage);
