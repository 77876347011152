import { getUniqueId, arrayMove } from ".";
import { getParameterByName } from "../../../helpers";
import { getItem } from "../../../helpers/storage";
import { rawIcons } from "../components/general/icons";
import { defaultPopupHtml } from "../data";
import { getPageAttributeValue } from "./value";
// import { preparePaymentForm } from "./stripe";

export const prepareStyle = (attributes, type = "") => {
  let style = "";
  let styleMap = {
    alignment: "alignment",
    paddingLeft: "padding-left",
    paddingRight: "padding-right",
    paddingTop: "padding-top",
    paddingBottom: "padding-bottom",
    backgroundColor: "background-color",
    backgroundImage: "background-image",
    backgroundRepeat: "background-repeat",
    backgroundAttachment: "background-attachment",
    backgroundPosition: "background-position",
    backgroundSize: "background-size",
    background: "background",
    marginTop: "margin-top",
    marginBottom: "margin-bottom",
    marginRight: "margin-right",
    marginLeft: "margin-left",
    fontSize: "font-size",
    lineHeight: "line-height",
    textAlign: "text-align",
    color: "color",
    border: "border",
    padding: "padding",
    margin: "margin",
    display: "display",
    fontFamily: "font-family",
    borderRadius: "border-radius",
    width: "width",
    maxWidth: "max-width",
    borderSpacing: "border-spacing",
    borderCollapse: "border-collapse",
    height: "height",
    float: "float",
    zIndex: "z-index",
    overflow: "overflow",
    position: "position",
    top: "top",
    left: "left",
    // content: "content",
    bottom: "bottom",
    right: "right",
    opacity: "opacity",
    minHeiBottomght: "min-height",
    minHeRightmght: "min-height",
    minHeLeftmght: "min-height",
    borderTop: "border-top",
    borderBottom: "border-bottom",
    borderLeft: "border-left",
    borderRight: "border-right",
    outline: "outline",
    letterSpacing: "letter-spacing",
    fontWeight: "font-weight",
  };
  for (const key in attributes) {
    if (attributes.hasOwnProperty(key)) {
      let element = attributes[key];
      if (key === "border") {
        if (element && element.includes("0px")) {
          element = "0";
        }
      }
      if (element && styleMap[key]) {
        style += `${styleMap[key]}:${element};`;
      }
    }
  }
  return style;
};

export const getTitleDefaultAttr = (json) => {
  let editor = json.contentEditor;
  let pageAttributes = editor.pageAttributes || {};
  let attrs = {};

  let fontSize = getPageAttributeValue("title", "fontSize", pageAttributes);
  attrs["fontSize"] = fontSize + "px";

  let lineHeight = getPageAttributeValue("title", "lineHeight", pageAttributes);
  attrs["lineHeight"] = lineHeight + "px";

  let font = getPageAttributeValue("title", "font", pageAttributes);
  attrs["fontFamily"] = font;

  let color = getPageAttributeValue("title", "color", pageAttributes);
  attrs["color"] = color;

  let letterSpacing = getPageAttributeValue(
    "title",
    "letterSpacing",
    pageAttributes
  );
  attrs["letterSpacing"] = letterSpacing;

  let fontWeight = getPageAttributeValue("title", "fontWeight", pageAttributes);
  attrs["fontWeight"] = fontWeight;

  return attrs;
};

export const getSubTitleDefaultAttr = (json) => {
  let editor = json.contentEditor;
  let pageAttributes = editor.pageAttributes || {};
  let attrs = {};

  let fontSize = getPageAttributeValue("subTitle", "fontSize", pageAttributes);
  attrs["fontSize"] = fontSize + "px";

  let lineHeight = getPageAttributeValue(
    "subTitle",
    "lineHeight",
    pageAttributes
  );
  attrs["lineHeight"] = lineHeight + "px";

  let font = getPageAttributeValue("subTitle", "font", pageAttributes);
  attrs["fontFamily"] = font;

  let color = getPageAttributeValue("subTitle", "color", pageAttributes);
  attrs["color"] = color;

  let letterSpacing = getPageAttributeValue(
    "subTitle",
    "letterSpacing",
    pageAttributes
  );
  attrs["letterSpacing"] = letterSpacing;

  let fontWeight = getPageAttributeValue(
    "subTitle",
    "fontWeight",
    pageAttributes
  );
  attrs["fontWeight"] = fontWeight;

  return attrs;
};

export const getTextDefaultAttr = (json) => {
  let editor = json.contentEditor;
  let pageAttributes = editor.pageAttributes || {};
  let attrs = {};

  let fontSize = getPageAttributeValue("text", "fontSize", pageAttributes);
  attrs["fontSize"] = fontSize + "px";

  let lineHeight = getPageAttributeValue("text", "lineHeight", pageAttributes);
  attrs["lineHeight"] = lineHeight + "px";

  let font = getPageAttributeValue("text", "font", pageAttributes);
  attrs["fontFamily"] = font;

  let color = getPageAttributeValue("text", "color", pageAttributes);
  attrs["color"] = color;

  let letterSpacing = getPageAttributeValue(
    "text",
    "letterSpacing",
    pageAttributes
  );
  attrs["letterSpacing"] = letterSpacing;

  let fontWeight = getPageAttributeValue("text", "fontWeight", pageAttributes);
  attrs["fontWeight"] = fontWeight;

  return attrs;
};

export const getButtonDefaultAttr = (json) => {
  let editor = json.contentEditor;
  let pageAttributes = editor.pageAttributes || {};
  let attrs = {};

  let fontSize = getPageAttributeValue("text", "fontSize", pageAttributes);
  attrs["fontSize"] = fontSize + "px";

  let lineHeight = getPageAttributeValue("text", "fontSize", pageAttributes);
  attrs["lineHeight"] = lineHeight + "px";

  let font = getPageAttributeValue("text", "font", pageAttributes);
  attrs["fontFamily"] = font;

  let color = getPageAttributeValue("text", "color", pageAttributes);
  attrs["color"] = color;

  let letterSpacing = getPageAttributeValue(
    "text",
    "letterSpacing",
    pageAttributes
  );
  attrs["letterSpacing"] = letterSpacing;

  let fontWeight = getPageAttributeValue("text", "fontWeight", pageAttributes);
  attrs["fontWeight"] = fontWeight;

  return attrs;
};

export const getIconAttributes = (iconAttributes, editorType) => {
  let defaultIconAttributes = {
    buttonIconEnable: true,
    buttonIconAlignment: "left",
    buttonIconName: "rightTriangle",
    buttonIconColor: "white",
    buttonIconSize: "20",
    buttonIconPaddingRight: "5",
    buttonIconPaddingLeft: "5",
  };
  if (
    iconAttributes === undefined ||
    iconAttributes.buttonIconEnable === undefined
  ) {
    if (iconAttributes) {
      if (iconAttributes.buttonIconName) {
        defaultIconAttributes.buttonIconName = iconAttributes.buttonIconName;
      }
      if (iconAttributes.buttonIconColor) {
        defaultIconAttributes.buttonIconColor = iconAttributes.buttonIconColor;
      }
      if (iconAttributes.buttonIconAlignment) {
        defaultIconAttributes.buttonIconAlignment =
          iconAttributes.buttonIconAlignment;
      }
      if (iconAttributes.buttonIconSize) {
        defaultIconAttributes.buttonIconSize = iconAttributes.buttonIconSize;
      }
      if (iconAttributes.buttonIconPaddingLeft) {
        defaultIconAttributes.buttonIconPaddingLeft =
          iconAttributes.buttonIconPaddingLeft;
      }
      if (iconAttributes.buttonIconPaddingRight) {
        defaultIconAttributes.buttonIconPaddingRight =
          iconAttributes.buttonIconPaddingRight;
      }
    }

    iconAttributes = defaultIconAttributes;
  }
  return iconAttributes;
};

export const getIconElemAttributes = (iconAttributes, editorType) => {
  let defaultIconAttributes = {
    name: "rightTriangle",
    color: "#000000",
    size: "20",
    gap: "5",
    position: "center",
  };

  if (iconAttributes) {
    if (iconAttributes.name) {
      defaultIconAttributes.name = iconAttributes.name;
    }
    if (iconAttributes.color) {
      defaultIconAttributes.color = iconAttributes.color;
    }

    if (iconAttributes.size) {
      defaultIconAttributes.size = iconAttributes.size;
    }

    if (iconAttributes.gap) {
      defaultIconAttributes.gap = iconAttributes.gap;
    }

    if (iconAttributes.position) {
      defaultIconAttributes.position = iconAttributes.position;
    }
  }

  iconAttributes = defaultIconAttributes;

  return iconAttributes;
};

const getIconHtml = (color, size, name) => {
  let icon = `<div style="display:flex; align-items:center;justify-content:center;fill:${color};"><svg preserveAspectRatio="xMidYMid" viewBox="0 0 64 64" style="width:${size}px"> ${rawIcons[name]} </svg></div>`;
  icon = icon.replace(/\n/g, " ");
  return icon;
};

const getAnimationStyle = (className, animation) => {
  if (animation === "slide-right") {
    return `.${className}[data-inviewport="${animation}"] {
      transition: 2s;
      transform: translateX(-100%);            
    }
    .${className}[data-inviewport="${animation}"].is-inViewport {
      transform: translateX(0);
    }`;
  } else if (animation === "slide-left") {
    return `.${className}[data-inviewport="${animation}"] {
      transition: 2s;
      transform: translateX(100%);         
    }
    .${className}[data-inviewport="${animation}"].is-inViewport {
      transform: translateX(0);
      overflow:auto;
    }`;
  } else if (animation === "scale-in") {
    return `.${className}[data-inviewport="${animation}"] {
      transition: 2s;
      transform: scale(0);            
    }
    .${className}[data-inviewport="${animation}"].is-inViewport {
      transform: scale(1);
    }`;
  } else if (animation === "fade-in") {
    return `.${className}[data-inviewport="${animation}"] {
      transition: 2s;
      opacity: 0;   
    }
    .${className}[data-inviewport="${animation}"].is-inViewport {
      opacity: 1;
    }`;
  } else if (animation === "Typewriter") {
    return `.${className}[data-inviewport="${animation}"] {
      overflow: hidden; /* Ensures the content is not revealed until the animation */
      border-right: .15em solid orange; /* The typwriter cursor */
      white-space: nowrap; /* Keeps the content on a single line */
      margin: 0 auto; /* Gives that scrolling effect as the typing happens */
      letter-spacing: .15em; /* Adjust as needed */
      animation: 
      typing 3.5s steps(40, end),
      blink-caret .75s step-end infinite;      
    }
    .${className}[data-inviewport="${animation}"].is-inViewport {
      @keyframes typing {
        from { width: 0 }
        to { width: 100% }
      }

      @keyframes blink-caret {
        from, to { border-color: transparent }
        50% { border-color: orange; }
      }
    }`;
  }
};

export const generateHtml = (
  htmlContent,
  json,
  editorType,
  editorPopups,
  popups,
  attributes
) => {
  let titleTag = "{{--REPLACE-TITLE--}}";
  let bodyTag = "{{--REPLACE-BODY--}}";
  let sectionTag = "{{--REPLACE-SECTION--}}";
  let rowTag = "{{--REPLACE-ROW--}}";
  let columnTag = "{{--REPLACE-COLUMN--}}";
  let itemTag = "{{--REPLACE-ITEM--}}";
  let styleTag = "{{--REPLACE-STYLE--}}";
  let modalTag = "{{--REPLACE-MODAL--}}";
  let webStyleTag = "{{--REPLACE-WEB-STYLE--}}";
  let fontStyleTag = "{{--REPLACE-FONT-STYLE--}}";
  let html = htmlContent;
  let siteId = getItem("sk");

  let content = json.contentEditor;
  html = html.replace(titleTag, content.pageTitle);
  let defaultTitleAttr = getTitleDefaultAttr(json);
  let defaultSubTitleAttr = getSubTitleDefaultAttr(json);
  let defaultTextAttr = getTextDefaultAttr(json);
  let defaultButtonAttr = getButtonDefaultAttr(json);

  let sections = content.itemList.filter((d) => d.type === "section");
  let sectionTags = "";
  sections.forEach((_, index) => {
    sectionTags += sectionTag + index;
  });

  let backgroundAttributes = {
    backgroundImage: content.bodyAttributes.backgroundImage,
    backgroundRepeat: content.bodyAttributes.backgroundRepeat,
    backgroundAttachment: content.bodyAttributes.backgroundAttachment,
    backgroundPosition: content.bodyAttributes.backgroundPosition,
    backgroundSize: content.bodyAttributes.backgroundSize,
    backgroundColor: content.bodyAttributes.backgroundColor,
    width: "100%",
    overflow: "visible",
  };

  if (editorType === "popup") {
    backgroundAttributes = {};
  }

  let bodyAttributes = JSON.parse(JSON.stringify(content.bodyAttributes));

  delete bodyAttributes.backgroundImage;
  delete bodyAttributes.backgroundRepeat;
  delete bodyAttributes.backgroundPosition;
  delete bodyAttributes.backgroundAttachment;
  delete bodyAttributes.backgroundSize;
  delete bodyAttributes.backgroundColor;
  delete bodyAttributes.opacity;
  delete bodyAttributes.alignment;
  delete bodyAttributes.height;

  if (
    !bodyAttributes.marginLeft &&
    !bodyAttributes.marginRight &&
    bodyAttributes.textAlign !== "left"
  ) {
    bodyAttributes.marginLeft = "auto";
    bodyAttributes.marginRight = "auto";
    bodyAttributes.textAlign = "center";
  }

  var pWidth = content.width || "1600";
  // pWidth = pWidth + "px";
  // bodyAttributes["maxWidth"] = pWidth;

  let bodyId = getUniqueId();

  let styles = `.body${bodyId}Class{${prepareStyle(bodyAttributes)}}`;
  styles += `.mpMainBody{${prepareStyle(backgroundAttributes)}}`;

  sections.forEach((secElem) => {
    let sectionAttributes = JSON.parse(
      JSON.stringify(secElem.attributes.attributes)
    );

    let maxWidth = sectionAttributes.maxWidth || pWidth + "px";

    if (secElem.usePageWidth) {
      maxWidth = pWidth + "px";
    }

    let backgroundFull = sectionAttributes.backgroundFull;
    if (backgroundFull === undefined) {
      backgroundFull = true;
    }

    let backgroundAttributes = {
      backgroundImage: sectionAttributes.backgroundImage,
      backgroundRepeat: sectionAttributes.backgroundRepeat,
      backgroundAttachment: sectionAttributes.backgroundAttachment,
      backgroundPosition: sectionAttributes.backgroundPosition,
      backgroundSize: sectionAttributes.backgroundSize,
      backgroundColor: sectionAttributes.backgroundColor,
      maxWidth: backgroundFull ? "100%" : maxWidth,
      margin: "0 auto",
      outline: `${sectionAttributes.outline}`,
      borderRadius: sectionAttributes.borderRadius,
    };

    delete sectionAttributes.backgroundImage;
    delete sectionAttributes.backgroundRepeat;
    delete sectionAttributes.backgroundPosition;
    delete sectionAttributes.backgroundAttachment;
    delete sectionAttributes.backgroundSize;
    delete sectionAttributes.backgroundColor;
    delete sectionAttributes.outline;
    delete sectionAttributes.border;
    delete sectionAttributes.borderRadius;

    sectionAttributes["maxWidth"] = maxWidth;

    styles += ` .Section${secElem.attributes.id}BackClass{${prepareStyle(
      backgroundAttributes
    )}}`;
    styles += ` .Section${secElem.attributes.id}Class{${prepareStyle(
      sectionAttributes
    )}}`;

    secElem.itemList.forEach((rowElem) => {
      styles += ` .Row${rowElem.attributes.id}Class{${prepareStyle(
        rowElem.attributes.attributes
      )}}`;

      rowElem.itemList.forEach((colElem) => {
        var attributes = JSON.parse(
          JSON.stringify(colElem.attributes.attributes)
        );

        let backgroundImageAttr = {
          backgroundImage: attributes.backgroundImage,
          backgroundRepeat: attributes.backgroundRepeat,
          backgroundAttachment: attributes.backgroundAttachment,
          backgroundPosition: attributes.backgroundPosition,
          backgroundSize: attributes.backgroundSize,
          backgroundColor: attributes.backgroundColor,
        };

        if (attributes.backgroundImage) {
          delete attributes.backgroundImage;
          delete attributes.backgroundRepeat;
          delete attributes.backgroundPosition;
          delete attributes.backgroundAttachment;
          delete attributes.backgroundSize;
          delete attributes.backgroundColor;
        }

        let divAttributes = {
          paddingTop: attributes.paddingTop,
          paddingRight: attributes.paddingRight,
          paddingLeft: attributes.paddingLeft,
          paddingBottom: attributes.paddingBottom,
          border: `${attributes.border} !important`,
          borderRadius: attributes.borderRadius,
          backgroundColor: attributes.backgroundColor,
          marginLeft: attributes.marginLeft,
          marginRight: attributes.marginRight,
        };
        let mpColAttributes = {
          ...attributes,
          paddingTop: 0,
          paddingRight: 0,
          paddingLeft: 0,
          paddingBottom: 0,
          marginLeft: 0,
          marginRight: 0,
          border: 0,
          borderRadius: 0,
          backgroundColor: "",
          width: `${parseFloat(colElem.widthPer)}%`,
        };

        let backgroundFull = attributes.backgroundFull;
        if (backgroundFull === false) {
          divAttributes = { ...divAttributes, ...backgroundImageAttr };
        } else {
          mpColAttributes = { ...mpColAttributes, ...backgroundImageAttr };
        }

        styles += ` .Column${colElem.attributes.id}MpColClass{${prepareStyle(
          mpColAttributes
        )}}`;
        styles += ` .Column${colElem.attributes.id}DivClass{${prepareStyle(
          divAttributes
        )}}`;
        colElem.itemList.forEach((itemElem) => {
          if (itemElem.type === "title" || itemElem.type === "subTitle") {
            let defaultAttr =
              itemElem.type === "subTitle"
                ? defaultSubTitleAttr
                : defaultTitleAttr;
            let itemAttr = {};
            if (
              !itemElem.useDefaultStyle ||
              itemElem.useDefaultStyle === false
            ) {
              itemAttr = itemElem.attributes.attributes;
            } else {
              itemAttr = {
                ...itemElem.attributes.attributes,
                ...defaultAttr,
              };
            }
            if (itemElem.attributes.linkColor) {
              let linkColorClass = {
                color: itemElem.attributes.linkColor,
              };
              styles += ` .Item${itemElem.attributes.id}${
                itemElem.type
              }Class a{${prepareStyle(linkColorClass)}}`;
            }
            if (itemElem.attributes.linkColorHover) {
              let linkColorHoverClass = {
                color: itemElem.attributes.linkColorHover,
              };
              styles += ` .Item${itemElem.attributes.id}${
                itemElem.type
              }Class a:hover{${prepareStyle(linkColorHoverClass)}}`;
            }
            styles += ` .Item${itemElem.attributes.id}${
              itemElem.type
            }Class{${prepareStyle(itemAttr)}}`;
          } else if (itemElem.type === "text") {
            let itemAttr = {};
            if (
              !itemElem.useDefaultStyle ||
              itemElem.useDefaultStyle === false
            ) {
              itemAttr = itemElem.attributes.attributes;
            } else {
              itemAttr = {
                ...itemElem.attributes.attributes,
                ...defaultTextAttr,
              };
            }
            if (itemElem.attributes.linkColor) {
              let linkColorClass = {
                color: itemElem.attributes.linkColor,
              };
              styles += ` .Item${itemElem.attributes.id}${
                itemElem.type
              }Class a{${prepareStyle(linkColorClass)}}`;
            }
            if (itemElem.attributes.linkColorHover) {
              let linkColorHoverClass = {
                color: itemElem.attributes.linkColorHover,
              };
              styles += ` .Item${itemElem.attributes.id}${
                itemElem.type
              }Class a:hover{${prepareStyle(linkColorHoverClass)}}`;
            }
            styles += ` .Item${itemElem.attributes.id}${
              itemElem.type
            }Class{${prepareStyle(itemAttr)}}`;
          } else if (itemElem.type === "checkbox") {
            let itemAttr = {};
            if (
              !itemElem.useDefaultStyle ||
              itemElem.useDefaultStyle === false
            ) {
              itemAttr = itemElem.attributes.attributes;
            } else {
              itemAttr = {
                ...itemElem.attributes.attributes,
                ...defaultTextAttr,
              };
            }

            let checkboxAttr = {
              display: "inline-block",
              margin: "0px",
              height: itemAttr.fontSize,
              width: itemAttr.fontSize,
            };

            let divAttr = {
              display: "inline-block",
            };

            if (itemElem.attributes.linkColor) {
              let linkColorClass = {
                color: itemElem.attributes.linkColor,
              };
              styles += ` .Item${itemElem.attributes.id}${
                itemElem.type
              }Class a{${prepareStyle(linkColorClass)}}`;
            }
            if (itemElem.attributes.linkColorHover) {
              let linkColorHoverClass = {
                color: itemElem.attributes.linkColorHover,
              };
              styles += ` .Item${itemElem.attributes.id}${
                itemElem.type
              }Class a:hover{${prepareStyle(linkColorHoverClass)}}`;
            }

            styles += ` .Item${itemElem.attributes.id}${
              itemElem.type
            }Class{${prepareStyle(itemAttr)}}`;

            styles += ` .Item${itemElem.attributes.id}${
              itemElem.type
            }CheckboxDivClass{${prepareStyle(divAttr)}}`;

            styles += ` .Item${itemElem.attributes.id}${
              itemElem.type
            }CheckboxClass{${prepareStyle(checkboxAttr)}}`;
          } else if (itemElem.type === "image") {
            let itemAttr = {};

            itemAttr = itemElem.attributes.attributes;

            let imageAttr = {
              ...itemAttr,
              border: "0",
              borderRadius: "0",
              fontSize: "0",
            };

            delete imageAttr.width;
            delete imageAttr.maxWidth;

            let maxWidth = itemAttr.maxWidth || "100%";

            let imageAttributes = {
              border: itemAttr.border,
              borderRadius: itemAttr.borderRadius,
              maxWidth: maxWidth,
            };

            styles += ` .Item${itemElem.attributes.id}${
              itemElem.type
            }DivImageClass{${prepareStyle(imageAttr)}}`;

            styles += ` .Item${itemElem.attributes.id}${
              itemElem.type
            }ImageClass{${prepareStyle(imageAttributes)}}`;
          } else if (itemElem.type === "button") {
            let divAttributes = {
              paddingTop: itemElem.attributes.attributes.paddingTop,
              paddingRight: itemElem.attributes.attributes.paddingRight,
              paddingLeft: itemElem.attributes.attributes.paddingLeft,
              paddingBottom: itemElem.attributes.attributes.paddingBottom,
            };
            let buttonAttributes = {
              ...itemElem.attributes.attributes,
              paddingRight: 0,
              paddingTop: 0,
              paddingLeft: 0,
              paddingBottom: 0,
            };

            let itemAttr = {};
            if (
              !itemElem.useDefaultStyle ||
              itemElem.useDefaultStyle === false
            ) {
              itemAttr = buttonAttributes;
            } else {
              itemAttr = {
                ...buttonAttributes,
                ...defaultButtonAttr,
              };
            }

            let aAttributes = {
              color: itemElem.attributes.attributes.color,
            };

            let divWebAttributes = {
              margin: itemAttr.margin,
              textAlign: itemAttr.textAlign,
            };

            styles += ` .Item${itemElem.attributes.id}${
              itemElem.type
            }DivWebClass{${prepareStyle(divWebAttributes)}}`;

            styles += ` .Item${itemElem.attributes.id}${
              itemElem.type
            }Class{${prepareStyle(itemAttr)}}`;

            styles += ` .Item${itemElem.attributes.id}${
              itemElem.type
            }Class:hover{${prepareStyle(itemElem.attributes.hoverAttributes)}}`;

            styles += ` .Item${itemElem.attributes.id}${
              itemElem.type
            }DivClass{${prepareStyle(divAttributes)}}`;

            styles += ` .Item${itemElem.attributes.id}${
              itemElem.type
            }AClass{${prepareStyle(aAttributes)}}`;
          } else if (itemElem.type === "line") {
            let lineAttributes = {};

            lineAttributes = itemElem.attributes.attributes;

            let height = lineAttributes.height || "1px";
            let color = lineAttributes.backgroundColor || "#000000";
            let style = lineAttributes.lineStyle || "solid";
            if (!height.includes("px")) {
              height = `${height}px`;
            }

            let hrAttributes = {
              borderBottom: "0px !important",
              borderRight: "0px !important",
              borderLeft: "0px !important",
              borderTop: `${height} ${style} ${color}`,
            };

            let itemAttr = {
              ...lineAttributes,
              height: 0,
              backgroundColor: "transparent",
            };

            styles += ` .Item${itemElem.attributes.id}${
              itemElem.type
            }Class{${prepareStyle(itemAttr)}}`;

            styles += ` .Item${itemElem.attributes.id}${
              itemElem.type
            }HRClass{${prepareStyle(hrAttributes)}}`;
          } else if (itemElem.type === "icon") {
            let iconAttributes = {};

            iconAttributes = itemElem.attributes.attributes;

            let itemAttr = {
              ...iconAttributes,
              border: iconAttributes.border + "!important",
              display: "inline-block",
            };

            let divAttributes = {
              margin: itemAttr.margin,
              textAlign: itemAttr.textAlign,
            };

            styles += ` .Item${itemElem.attributes.id}${
              itemElem.type
            }Class{${prepareStyle(itemAttr)}}`;
            styles += ` .Item${itemElem.attributes.id}${
              itemElem.type
            }DivClass{${prepareStyle(divAttributes)}}`;
          } else if (itemElem.type === "iconText") {
            let itemAttr = {};
            if (
              !itemElem.useDefaultStyle ||
              itemElem.useDefaultStyle === false
            ) {
              itemAttr = itemElem.attributes.attributes;
            } else {
              itemAttr = {
                ...itemElem.attributes.attributes,
                ...defaultTextAttr,
              };
            }

            styles += ` .Item${itemElem.attributes.id}${
              itemElem.type
            }Class{${prepareStyle(itemAttr)}}`;
          } else if (itemElem.type === "imageText") {
            let itemAttr = {};
            if (
              !itemElem.useDefaultStyle ||
              itemElem.useDefaultStyle === false
            ) {
              itemAttr = itemElem.attributes.attributes;
            } else {
              itemAttr = {
                ...itemElem.attributes.attributes,
                ...defaultTextAttr,
              };
            }

            delete itemAttr.maxWidth;
            delete itemAttr.position;
            delete itemAttr.gap;

            styles += ` .Item${itemElem.attributes.id}${
              itemElem.type
            }Class{${prepareStyle(itemAttr)}}`;
          } else if (itemElem.type === "input") {
            let inputAttributes = itemElem.attributes.inputAttributes || {};
            let attributes = itemElem.attributes.attributes;

            let color = inputAttributes.color || "#000000";
            let backgroundColor = inputAttributes.backgroundColor || "";
            let fontSize = inputAttributes.fontSize || "12";
            let width = inputAttributes.width || "100";
            width = width + "%";

            let itemAttr = {
              ...attributes,
              border: 0,
              borderRadius: 0,
            };

            let height = inputAttributes.inputHeight || "100";

            let multipleLines = inputAttributes.multipleLines;
            let inputAttr = {
              border: attributes.border,
              borderRadius: attributes.borderRadius,
              width: width,
              color: color,
              maxWidth: itemAttr.maxWidth,
              fontSize: fontSize + "px",
              backgroundColor: backgroundColor,
            };
            delete itemAttr.maxWidth;

            if (multipleLines) {
              inputAttr["height"] = `${height}px`;
            }

            styles += ` .Item${itemElem.attributes.id}${
              itemElem.type
            }Class{${prepareStyle(itemAttr)}}`;

            styles += ` .Item${itemElem.attributes.id}${
              itemElem.type
            }InputClass{${prepareStyle(inputAttr)}}`;
          } else if (itemElem.type === "fileInput") {
            let inputAttributes = itemElem.attributes.inputAttributes || {};
            let attributes = itemElem.attributes.attributes;

            let width = inputAttributes.width || "100";
            width = width + "%";

            let itemAttr = {
              ...attributes,
              border: 0,
              borderRadius: 0,
            };

            let inputAttr = {
              border: attributes.border,
              borderRadius: attributes.borderRadius,
              width: width,
            };

            styles += ` .Item${itemElem.attributes.id}${
              itemElem.type
            }Class{${prepareStyle(itemAttr)}}`;

            styles += ` .Item${itemElem.attributes.id}${
              itemElem.type
            }InputClass{${prepareStyle(inputAttr)}}`;
          } else if (itemElem.type === "code") {
            let codeAttr = {
              ...itemElem.attributes.attributes,
              content: "",
            };
            styles += ` .Item${itemElem.attributes.id}${
              itemElem.type
            }Class{${prepareStyle(codeAttr)}}`;
          } else if (itemElem.type === "countdown") {
            let countdownAttributes = itemElem.attributes
              .countdownAttributes || {
              textColor: "#ffffff",
              timeFontSize: "30",
              textFontSize: "16",
              outerBackgroundColor: "#333333",
              innerBackgroundColor: "#555555",
              fontFamily: "Ubuntu",
            };
            let attributes = itemElem.attributes.attributes;

            let counterAttr = {
              ...attributes,
            };
            let mainDivAttr = {
              fontFamily: countdownAttributes.fontFamily,
              color: countdownAttributes.textColor,
              display: "inline-block",
              fontWeight: 100,
              textAlign: "center",
              fontSize: `${countdownAttributes.timeFontSize}px`,
            };
            let subDivAttr = {
              padding: "10px",
              marginRight: "0.2em",
              marginBottom: "0.2em",
              borderRadius: "3px",
              background: countdownAttributes.outerBackgroundColor,
              display: "inline-block",
            };
            let subDivSpanAttr = {
              padding: "15px",
              borderRadius: "3px",
              background: countdownAttributes.innerBackgroundColor,
              display: "inline-block",
            };

            let smallTextAttr = {
              paddingTop: "5px",
              fontSize: `${countdownAttributes.textFontSize}px`,
            };

            styles += ` .Item${itemElem.attributes.id}${
              itemElem.type
            }Class{${prepareStyle(counterAttr)}}`;

            styles += ` .Item${itemElem.attributes.id}${
              itemElem.type
            }MainDivClass{${prepareStyle(mainDivAttr)}}`;

            styles += ` .Item${itemElem.attributes.id}${
              itemElem.type
            }SubDivClass{${prepareStyle(subDivAttr)}}`;

            styles += ` .Item${itemElem.attributes.id}${
              itemElem.type
            }SubDivSpanClass{${prepareStyle(subDivSpanAttr)}}`;

            styles += ` .Item${itemElem.attributes.id}${
              itemElem.type
            }SmallTextClass{${prepareStyle(smallTextAttr)}}`;
          } else {
            styles += ` .Item${itemElem.attributes.id}${
              itemElem.type
            }Class{${prepareStyle(itemElem.attributes.attributes)}}`;
          }
        });
      });
    });
  });

  html = html.replace(
    webStyleTag,
    `
      html, body {
        height: 100%;
        margin:0;
    }
  `
  );

  // prepare body view
  let bodyView = "";

  if (editorType === "email") {
    var width = content.width || "100%";

    bodyView = `
    <body marginwidth="0" marginheight="0" leftmargin="0" topmargin="0" yahoo="fix" style="${prepareStyle(
      content.bodyAttributes
    )}">
      <table width=${width} cellpadding="0" cellspacing="0" border="0" align="center">
        <tr>
          <td>
          \n${sectionTags}\n
          </td>
        </tr>
      </table>        
    </body>`;
  } else {
    bodyView = `
    <body class="mpMainBody" marginwidth="0" marginheight="0" leftmargin="0" topmargin="0" yahoo="fix">        
      <div class="body${bodyId}Class">\n${sectionTags}\n</div>      
    </body>`;
  }

  html = html.replace(bodyTag, bodyView);

  let animationStyle = "";

  // prepare section view
  sections.forEach((element, sectionIndex) => {
    // prepare row view
    let rows = element.itemList.filter((d) => d.type === "row");
    let rowTags = "";
    rows.forEach((_, rowIndex) => {
      rowTags += rowTag + sectionIndex + rowIndex;
    });

    var textAlign = element.attributes.attributes.textAlign || "center";

    let sectionView = "";
    let animationOpenDiv = "";
    let animationCloseDiv = "";

    if (editorType === "email") {
      var sectionAttributes = element.attributes.attributes;
      var sectionWidth = sectionAttributes.maxWidth || "100%";
      sectionView = `
        <table width=${sectionWidth} cellpadding="0" cellspacing="0" border="0" id="${
        element.attributes.id
      }" class="Section${
        element.attributes.id
      }Class  mobile" style="${prepareStyle(
        sectionAttributes
      )}" align="${textAlign}">
            <tr>
              <td width="100%" valign="top" align="${textAlign}">${rowTags}</td>
            </tr>
        </table>`;
    } else if (editorType === "web" || editorType === "popup") {
      // if (element.subId && element.nonCustomizable) {
      //   sectionView = `<div>{{custom_section(${element.subId})}}</div>`;
      // } else {

      let animation = element.attributes.attributes.animation || "";
      let viewPort = "";
      if (animation) {
        viewPort = `data-inviewport="${animation}"`;
        animationStyle += getAnimationStyle(
          `Section${element.attributes.id}BackClass`,
          animation
        );
        animationOpenDiv = "<div style='max-width:100vw;overflow: hidden;'>";
        animationCloseDiv = "</div>";
      }
      sectionView = `${animationOpenDiv}<div class="Section${element.attributes.id}BackClass" ${viewPort}>
        <div id="${element.attributes.id}" class="Section${element.attributes.id}Class mp-section" align="${textAlign}">            
          ${rowTags}            
        </div>
      </div>${animationCloseDiv}`;
      // }
    }

    html = html.replace(sectionTag + sectionIndex, sectionView);

    rows.forEach((rowElement, rowIndex) => {
      // prepare column view
      let columns = rowElement.itemList.filter((d) => d.type === "column");
      let colTags = "";
      columns.forEach((_, colIndex) => {
        colTags += columnTag + sectionIndex + rowIndex + colIndex;
      });

      let rowWidth = rowElement.width;
      let rowView = "";

      if (editorType === "email") {
        var rowAttributes = rowElement.attributes.attributes;
        var borderAttributes = {
          borderSpacing: rowAttributes.borderSpacing,
          borderCollapse: rowAttributes.borderCollapse,
        };
        rowView = `
        <table width="100%" id="${rowElement.attributes.id}"
          cellpadding="0" cellspacing="0" border="0"
          class="Row${rowElement.attributes.id}Class wrapper mobile"
          style="${prepareStyle(rowAttributes)}"
        >
          <tr>
            <td align="center">
              <table width="100%" cellpadding="0" cellspacing="0" border="0" class="container mobile" style="${prepareStyle(
                borderAttributes
              )}">
                <tr>${colTags}</tr>
              </table>
            </td>
          </tr>
        </table>`;
      } else if (editorType === "web" || editorType === "popup") {
        let animation = rowElement.attributes.attributes.animation || "";
        let viewPort = "";
        if (animation) {
          viewPort = `data-inviewport="${animation}"`;
          animationStyle += getAnimationStyle(
            `Row${rowElement.attributes.id}Class`,
            animation
          );
        }

        // add reverseFlexColumns if true
        let reverseColumns = rowElement.attributes.attributes.reverseColumns;
        let rCClass = "";
        if (reverseColumns) {
          rCClass = "reverseFlexColumns";
        }

        rowView = `<div id="${rowElement.attributes.id}"          
            class="Row${rowElement.attributes.id}Class wrapper mp-row ${rCClass}" ${viewPort}>
            ${colTags}
          </div>`;
      }

      html = html.replace(rowTag + sectionIndex + rowIndex, rowView);

      columns.forEach((colElement, colIndex) => {
        // prepare item view
        let items = colElement.itemList;
        let itemTags = "";
        items.forEach((_, itemIndex) => {
          itemTags += itemTag + sectionIndex + rowIndex + colIndex + itemIndex;
        });
        let width =
          (parseFloat(rowWidth) * parseFloat(colElement.widthPer)) / 100;

        let columnView = "";
        if (editorType === "email") {
          columnView = `
            <td id="${
              colElement.attributes.id
            }" width="${width}" class="Column${
            colElement.attributes.id
          }Class mobile" 
            style="${prepareStyle(
              colElement.attributes.attributes
            )}"><div class="mobileCol">
              ${itemTags}
              </div>
            </td>`;
        } else if (editorType === "web" || editorType === "popup") {
          let animation = colElement.attributes.attributes.animation || "";
          let viewPort = "";
          if (animation) {
            viewPort = `data-inviewport="${animation}"`;
            animationStyle += getAnimationStyle(
              `Column${colElement.attributes.id}MpColClass`,
              animation
            );
          }
          columnView = `<div id="${colElement.attributes.id}" 
          class="Column${colElement.attributes.id}MpColClass mp-col" ${viewPort}>
            <div style="width:100%;">
              <div class="Column${colElement.attributes.id}DivClass">
                ${itemTags}
              </div>
            </div>
          </div>`;
        }

        html = html.replace(
          columnTag + sectionIndex + rowIndex + colIndex,
          columnView
        );

        // prepare content
        items.forEach((itemElem, itemIndex) => {
          let attributes = itemElem.attributes.attributes;
          if (itemElem.type === "text" || itemElem.type === "code") {
            let itemAttr = {};
            if (
              !itemElem.useDefaultStyle ||
              itemElem.useDefaultStyle === false
            ) {
              itemAttr = itemElem.attributes.attributes;
            } else {
              itemAttr = {
                ...itemElem.attributes.attributes,
                ...defaultTextAttr,
              };
            }

            let textView = "";

            let textContent = attributes.content;

            textContent = textContent.replace(
              "text-align:left",
              `text-align:${itemElem.attributes.attributes.textAlign}`
            );

            if (editorType === "email") {
              textView = `
              <div id="${itemElem.attributes.id}" class="Item${
                itemElem.attributes.id
              }${itemElem.type}Class" style="${prepareStyle(
                itemAttr
              )}overflow-wrap: anywhere;">
                ${textContent}
              </div>  
            `;
            } else if (editorType === "web" || editorType === "popup") {
              textView = `
              <div id="${itemElem.attributes.id}" 
              class="Item${itemElem.attributes.id}${itemElem.type}Class" 
              style="overflow-wrap: anywhere;">
                ${textContent}
              </div>  
            `;
            }

            html = html.replace(
              itemTag + sectionIndex + rowIndex + colIndex + itemIndex,
              textView
            );
          } else if (
            itemElem.type === "title" ||
            itemElem.type === "subTitle"
          ) {
            let defaultAttr =
              itemElem.type === "subTitle"
                ? defaultSubTitleAttr
                : defaultTitleAttr;
            let itemAttr = {};
            if (
              !itemElem.useDefaultStyle ||
              itemElem.useDefaultStyle === false
            ) {
              itemAttr = itemElem.attributes.attributes;
            } else {
              itemAttr = {
                ...itemElem.attributes.attributes,
                ...defaultAttr,
              };
            }
            let titleView = "";
            if (editorType === "email") {
              let textContent = attributes.content.replace(
                "text-align:center",
                `text-align:${itemElem.attributes.attributes.textAlign}`
              );
              titleView = `
              <div id="${itemElem.attributes.id}" class="Item${
                itemElem.attributes.id
              }${itemElem.type}Class" style="${prepareStyle(
                itemAttr
              )}overflow-wrap: anywhere;">
                ${textContent}
              </div>  
            `;
            } else if (editorType === "web" || editorType === "popup") {
              let textContent = attributes.content;
              textContent = textContent.replace(
                "text-align:center",
                `text-align:${itemElem.attributes.attributes.textAlign}`
              );
              titleView = `
              <div id="${itemElem.attributes.id}" 
              class="Item${itemElem.attributes.id}${itemElem.type}Class" 
              style="overflow-wrap: anywhere;">
              ${textContent}
              </div>  
            `;
            }

            html = html.replace(
              itemTag + sectionIndex + rowIndex + colIndex + itemIndex,
              titleView
            );
          } else if (itemElem.type === "button") {
            /*eslint-disable*/
            let divAttributes = {
              paddingTop: itemElem.attributes.attributes.paddingTop,
              paddingRight: itemElem.attributes.attributes.paddingRight,
              paddingLeft: itemElem.attributes.attributes.paddingLeft,
              paddingBottom: itemElem.attributes.attributes.paddingBottom,
            };

            let buttonAttributes = {
              ...itemElem.attributes.attributes,
              paddingRight: 0,
              paddingTop: 0,
              paddingLeft: 0,
              paddingBottom: 0,
              display: "inline-block",
              textDecoration: "none",
            };

            let itemAttr = {};
            if (
              !itemElem.useDefaultStyle ||
              itemElem.useDefaultStyle === false
            ) {
              itemAttr = buttonAttributes;
            } else {
              itemAttr = {
                ...buttonAttributes,
                ...defaultButtonAttr,
              };
            }

            let iconAttributes = getIconAttributes(
              itemElem.attributes.iconAttributes,
              editorType
            );
            let leftIcon = "";
            let rightIcon = "";
            /*eslint-enable*/
            let icon = "";
            if (iconAttributes.buttonIconEnable) {
              icon = getIconHtml(
                iconAttributes.buttonIconColor,
                iconAttributes.buttonIconSize,
                iconAttributes.buttonIconName
              );
              if (iconAttributes.buttonIconAlignment === "left") {
                leftIcon = icon;
                rightIcon = "";
              }
              if (iconAttributes.buttonIconAlignment === "right") {
                leftIcon = "";
                rightIcon = icon;
              }
            }

            let buttonView = "";

            if (editorType === "email") {
              buttonView = `
              <table width="100%" border="0" cellspacing="0" cellpadding="0" style="border-collapse:collapse !important;">
              <tr style="border:0">
                <td align="${
                  itemElem.attributes.attributes.textAlign
                }" style=" border:0; padding-top: ${
                divAttributes.paddingTop
              }; padding-bottom: ${
                divAttributes.paddingBottom
              }; padding-left: ${divAttributes.paddingLeft}; padding-right: ${
                divAttributes.paddingRight
              };">
                  <table width="${
                    itemAttr.width
                  }" border="0" cellspacing="0" cellpadding="0" style="border-collapse:collapse !important;">
                    <tr style="border:0">
                    <td align="center" valign="middle" style="border:0; ms-text-size-adjust:none;-webkit-text-size-adjust:none; text-align:center; mso-line-height-rule:exactly;">
                    <a href="${
                      itemElem.src ? itemElem.src : "#"
                    }" target="_blank"  style="text-decoration:none; 
                    ${prepareStyle(itemAttr)}; ${prepareStyle(divAttributes)}">
            ${attributes.title}
                    </a>
                    </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
              `;
            } else if (editorType === "web" || editorType === "popup") {
              let buttonActionType =
                itemElem.attributes.buttonActionType || "url";
              let newWindow = itemElem.attributes.buttonNewWindow;
              let markCompleted = itemElem.attributes.buttonMarkCompleted;
              let buttonSecondLine = itemElem.attributes.buttonSecondLine;

              let buttonSecondLineFontSize =
                itemElem.attributes.buttonSecondLineFontSize;
              let buttonSecondLineLineHeight =
                itemElem.attributes.buttonSecondLineLineHeight;

              let buttonSaveSubmission =
                itemElem.attributes.buttonSaveSubmission;
              let buttonCreateCustomer =
                itemElem.attributes.buttonCreateCustomer;
              if (buttonCreateCustomer === undefined) {
                buttonCreateCustomer = true;
              }

              let buttonFormStaffUser = itemElem.attributes.buttonFormStaffUser;
              if (buttonFormStaffUser === undefined) {
                buttonFormStaffUser = "";
              }

              if (buttonSaveSubmission === undefined) {
                buttonSaveSubmission = false;
              }

              let buttonSaveLead = itemElem.attributes.buttonSaveLead;

              if (buttonSaveLead === undefined) {
                buttonSaveLead = false;
              }

              let buttonLeadStaffUser =
                itemElem.attributes.buttonLeadStaffUser || "";

              if (newWindow === undefined) {
                newWindow = false;
              }
              let action = "";
              if (buttonActionType === "url") {
                action = `href="${itemElem.src ? itemElem.src : "#"}"`;
                if (newWindow) {
                  action = `${action} target="_blank"`;
                } else {
                  action = `${action} onClick="return mpOpenParent('${itemElem.src}');"`;
                }
              } else if (buttonActionType === "form") {
                action = ` onClick="return mp_submit_form('${itemElem.attributes.buttonFormName}');" `;
              } else if (buttonActionType === "popup") {
                action = `href="#" id="button${itemElem.attributes.id}"`;
              } else if (
                buttonActionType === "next-page" ||
                buttonActionType === "previous-page"
              ) {
                action = ` onClick="window.parent.postMessage({c:${markCompleted},a:'${buttonActionType}'},'*');" `;
              } else if (buttonActionType === "next-page") {
                const src = "{{NextPage}}";
                action = `href="${src}"`;
                action = `${action} onClick="return mpOpenParent('${src}');"`;
              } else if (buttonActionType === "checkout") {
                const checkoutId = itemElem.attributes.buttonCheckoutId || "";
                const key = getItem("sk");
                const url = `https://checkout.memberpages.app?p=${checkoutId}&key=${key}`;

                action = `href="${url}"`;
                action = `${action} target="_blank"`;
              }

              // button tags - hidden input
              let inputHidden = "";
              if (
                itemElem.attributes.buttonTags &&
                buttonActionType === "form"
              ) {
                let tags = itemElem.attributes.buttonTags
                  .map((i) => i)
                  .join(",");
                inputHidden = `
                <input name="tags" value="${tags}" type="hidden" formName="${itemElem.attributes.buttonFormName}" />
                `;
              }

              let redirectHidden = "";
              let submissionHidden = "";
              let dontCreateHideen = "";
              let staffUser = "";
              let saveLeadHidden = "";
              let leadStaffUser = "";

              if (buttonActionType === "form") {
                redirectHidden = `<input name="redirect" value="${itemElem.attributes.buttonThankYouUrl}" type="hidden" formName="${itemElem.attributes.buttonFormName}" />`;
                submissionHidden = `<input name="saveFormSubmission" value="${buttonSaveSubmission}" type="hidden" formName="${itemElem.attributes.buttonFormName}" />`;
                dontCreateHideen = `<input name="createCustomer" value="${buttonCreateCustomer}" type="hidden" formName="${itemElem.attributes.buttonFormName}" />`;
                staffUser = `<input name="staffUserId" value="${buttonFormStaffUser}" type="hidden" formName="${itemElem.attributes.buttonFormName}" />`;
                saveLeadHidden = `<input name="saveLead" value="${buttonSaveLead}" type="hidden" formName="${itemElem.attributes.buttonFormName}" />`;
                leadStaffUser = `<input name="leadStaffUserId" value="${buttonLeadStaffUser}" type="hidden" formName="${itemElem.attributes.buttonFormName}" />`;
              }

              let buttonSecondLineView = "";
              if (buttonSecondLine) {
                buttonSecondLineView = `
                <tr>
                  <td colspan="3" style="font-size:${buttonSecondLineFontSize}px;line-height:${buttonSecondLineLineHeight}">
                    ${attributes.subTitle}
                  </td>
                </tr>
                `;
              }

              buttonView = `
              <div class="Item${itemElem.attributes.id}${itemElem.type}DivWebClass" id="${itemElem.attributes.id}">
                <a 
                  style="cursor:pointer"
                  ${action}
                 class="Item${itemElem.attributes.id}${itemElem.type}Class">
                 <div class="Item${itemElem.attributes.id}${itemElem.type}DivClass">
                  <table style="margin: 0 auto; padding: 0; border:collapse; border:0" cellspacing="0" cellpadding="0">
                    <tr>
                      <td style="padding-right: ${iconAttributes.buttonIconPaddingRight}px; padding-left: ${iconAttributes.buttonIconPaddingLeft}px;">
                        ${leftIcon}
                      </td>
                      <td>
                        ${attributes.title}
                      </td>
                      <td style="padding-right: ${iconAttributes.buttonIconPaddingRight}px; padding-left: ${iconAttributes.buttonIconPaddingLeft}px;">
                        ${rightIcon}
                      </td>
                    </tr>
                    ${buttonSecondLineView}
                  </table>
                 </div>
                 </a>
                </div>
              ${inputHidden}
              ${redirectHidden}
              ${submissionHidden}
              ${dontCreateHideen}
              ${staffUser}
              ${saveLeadHidden}
              ${leadStaffUser}
              <input name="siteKey" value="${siteId}" type="hidden" formName="${itemElem.attributes.buttonFormName}" />                            
              `;
            }
            html = html.replace(
              itemTag + sectionIndex + rowIndex + colIndex + itemIndex,
              buttonView
            );
          } else if (itemElem.type === "image") {
            let imageView = "";
            if (editorType === "email") {
              imageView = `
            <table class="Item${itemElem.attributes.id}${
                itemElem.type
              }Class" width="${
                itemElem.attributes.attributes.maxWidth
              }" align="${
                itemElem.attributes.attributes.textAlign
              }" style="${prepareStyle(itemElem.attributes.attributes)}">
              <tr>
                <td style="line-height:0; padding:0;">
                  <img src="${itemElem.src}" id="${
                itemElem.attributes.id
              }" alt="I" width="100%"/>
                </td>
              </tr>
            </table>`;
            } else if (editorType === "web" || editorType === "popup") {
              imageView = `
            <div class="Item${itemElem.attributes.id}${itemElem.type}DivImageClass">              
              <img src="${itemElem.src}" id="${itemElem.attributes.id}" 
              alt="I" class="Item${itemElem.attributes.id}${itemElem.type}ImageClass responsiveImage"/>                
            </div>`;
            }
            let linkUrl = itemElem.attributes.attributes.linkUrl;
            let action = "";
            let newWindow = itemElem.attributes.imageLinkNewWindow;
            if (newWindow === undefined) {
              newWindow = false;
            }
            if (newWindow) {
              action = `${action} target="_blank"`;
            }
            if (linkUrl) {
              imageView = `<a border=0 href="${linkUrl}" ${action}>${imageView}</a>`;
            }
            html = html.replace(
              itemTag + sectionIndex + rowIndex + colIndex + itemIndex,
              imageView
            );
          } else if (itemElem.type === "imageSlider") {
            let imageSliderView = "";
            let splidId = `splide${itemElem.attributes.id}`;

            let sliderImages = itemElem.attributes.sliderImages || [];
            let sliderFullImage = itemElem.attributes.sliderFullImage;

            let sliderImagesView = "";

            if (sliderFullImage) {
              sliderImages.forEach((element) => {
                sliderImagesView = `
                  ${sliderImagesView}
                  <li class="splide__slide">                  
                    <a href="${element.src}" data-lightbox="${element.id}" data-title="">
                      <img alt="${element.id}" src="${element.src}" responsiveImage /> 
                    </a>                 
                  </li>
                `;
              });
            } else {
              sliderImages.forEach((element) => {
                sliderImagesView = `
                  ${sliderImagesView}
                  <li class="splide__slide">                  
                    <img alt="${element.id}" src="${element.src}" />                  
                  </li>
                `;
              });
            }

            imageSliderView = `
            <div class="Item${itemElem.attributes.id}${itemElem.type}Class">              
              <section class="splide" id="${splidId}" aria-label="Presentation">
                  <div class="splide__track">
                      <ul class="splide__list">
                        ${sliderImagesView}
                      </ul>
                  </div>
              </section>
              <script type="text/javascript">
                  document.addEventListener('DOMContentLoaded', function() {
                      var splide${splidId} = new Splide('#${splidId}', {
                          pagination: false,
                          lazyLoad: 'nearby'
                      }).mount();
                  });
              </script>            
            </div>`;

            html = html.replace(
              itemTag + sectionIndex + rowIndex + colIndex + itemIndex,
              imageSliderView
            );
          } else if (itemElem.type === "video") {
            let videoView = "";

            let videoUrl = itemElem.src;
            var player = "member-pages";
            if (itemElem.src && itemElem.src.includes("vimeo")) {
              player = "vimeo";
              var parts = itemElem.src.split("/");
              let url = parts.pop() || parts.pop();
              videoUrl = `https://player.vimeo.com/video/${url}`;
            }
            if (itemElem.src && itemElem.src.includes("youtube")) {
              player = "youtube";
              let url = getParameterByName("v", itemElem.src);
              videoUrl = `https://www.youtube.com/embed/${url}`;
            }

            let videoType = itemElem.attributes.attributes.videoType || "16-9";

            let videoPlayerView = `
            <video id="${itemElem.attributes.id}"
              class="video-js vjs-theme-fantasy vjs-${videoType} vjs-big-play-centered vjs-show-big-play-button-on-pause"
              controls              
              preload="auto"
              controlslist="nodownload" 
              data-setup="{}">
                <source src="${videoUrl}" type="application/x-mpegURL" />
            </video>
            <script>
              var options = 
              {
                controlBar: {
                  fullscreenToggle: true
                },
                poster: '${itemElem.srcThumb}'                
              };
              var player${itemElem.attributes.id} = videojs('${itemElem.attributes.id}', options);

              player${itemElem.attributes.id}.hlsQualitySelector({
                displayCurrentQuality: true,
                resolution: '1080'
              });

              var playbackRate${itemElem.attributes.id} = "1.50";
              if(customerId != "")
              {
                player${itemElem.attributes.id}.ready(function(){
                  var ${itemElem.attributes.id}Last = 0;        
                  var loaded${itemElem.attributes.id} = true;

                  player${itemElem.attributes.id}.on(['waiting', 'pause'], function() {
                  });
                  
                  player${itemElem.attributes.id}.on(['ended'], function() {
                    console.log("It finished");
                  });

                  
                  player${itemElem.attributes.id}.on(['seeking'], function() {
                    ${itemElem.attributes.id}Last = 0;  
                  });
                  
                  player${itemElem.attributes.id}.on('playing', function() {
                    if(loaded${itemElem.attributes.id} === false)
                    {
                      player${itemElem.attributes.id}.currentTime(${itemElem.attributes.id}Last);
                      loaded${itemElem.attributes.id} = true;
                    }
                  });


                  $.get('https://api.memberpages.app/user-videos-details?key=${siteId}&user_id='+customerId+'&video_id=${itemElem.attributes.id}', function(data){
                    if (typeof data[0] !== 'undefined') {
                      ${itemElem.attributes.id}Last = data[0]['current_watch_time'];
                      loaded${itemElem.attributes.id} = false;
                      
                  }
                  });
                  
                    var ${itemElem.attributes.id}Interval = setInterval(function(){
                      let currentTime = player${itemElem.attributes.id}.currentTime();
                      let currentDuration = player${itemElem.attributes.id}.duration();
                      let totalTime = player${itemElem.attributes.id}.duration();
                      if(currentTime >= currentDuration)
                      {
                        updateVideo(0, totalTime, true);
                        clearInterval(${itemElem.attributes.id}Interval);
                        ${itemElem.attributes.id}Interval = null;
                      }
                      else if(currentTime >= (currentDuration - 15))
                      {
                        updateVideo(currentTime, totalTime, true);
                      }
                      else if(${itemElem.attributes.id}Last < currentTime)
                      {
                        ${itemElem.attributes.id}Last = ${itemElem.attributes.id}Last + 10;
                        updateVideo(currentTime, totalTime, false);
                      }
                    }, 3000)  
                  });

                  function updateVideo(watchTime, totalTime, completed){
                    $.post('https://api.memberpages.app/user-videos?key=${siteId}', {user_id: customerId, video_id: "${itemElem.attributes.id}", current_watch_time: watchTime, total_time: totalTime, challenge_item_id: challengeItemId, finished:completed}, function(data){
                      
                    });
                  }
              }

              var ButtonComp = videojs.getComponent('Button');
              var speedPlayer${itemElem.attributes.id} = videojs(document.querySelector('#${itemElem.attributes.id}'))
              var speedButton = new ButtonComp(speedPlayer${itemElem.attributes.id}, {
                  clickHandler: function(event) {
                    speedPlayer${itemElem.attributes.id}.playbackRate(playbackRate${itemElem.attributes.id});
                    switch(playbackRate${itemElem.attributes.id}) {
                    case "1.0":
                    document.querySelector('.modifySpeedButton .vjs-icon-placeholder').innerHTML = '1x'
                    playbackRate${itemElem.attributes.id} = "1.50";
                    break;
                  case "1.50":
                      document.querySelector('.modifySpeedButton .vjs-icon-placeholder').innerHTML = '1.5x'
                    playbackRate${itemElem.attributes.id} = "1.75";
                    break;
                  case "1.75":
                      document.querySelector('.modifySpeedButton .vjs-icon-placeholder').innerHTML = '1.75x'
                    playbackRate${itemElem.attributes.id} = "2.0";
                    break;
                  case "2.0":
                      document.querySelector('.modifySpeedButton .vjs-icon-placeholder').innerHTML = '2x'
                    playbackRate${itemElem.attributes.id} = ".75";
                    break;
                  case ".75":
                      document.querySelector('.modifySpeedButton .vjs-icon-placeholder').innerHTML = '.75x'
                    playbackRate${itemElem.attributes.id} = "1.0";
                    break;
                  }
                  }
                  });
                  speedButton.addClass('modifySpeedButton')
                  speedPlayer${itemElem.attributes.id}.controlBar.addChild(speedButton,{},16)
                  console.log(speedButton.contentEl());
                  document.querySelector('.modifySpeedButton .vjs-icon-placeholder').innerHTML = '1x'

            </script>
            `;

            if (player === "vimeo") {
              videoPlayerView = `
              <div style="padding:56.25% 0 0 0;position:relative;">
                <iframe 
                  title=${videoUrl}
                  src=${videoUrl} 
                  style="position:absolute;top:0;left:0;width:100%;height:100%;" 
                  frameborder="0"                  
                  allow="autoplay; fullscreen" 
                  allowfullscreen>
                </iframe>
              </div>
              `;
            }

            if (player === "youtube") {
              videoPlayerView = `
                <div class="maxVideo">
                  <div class="embed-responsive embed-responsive-16by9 video-responsive">
                    <iframe
                      class="videoIframe"
                      src=${videoUrl}
                      frameborder="0"
                      allow="autoplay; fullscreen"
                      allowfullscreen
                      title=${videoUrl}>
                    </iframe>
                  </div>
                </div>
              `;
            }

            videoView = `
            <div class="Item${itemElem.attributes.id}${itemElem.type}Class">              
                ${videoPlayerView}
            </div>`;

            html = html.replace(
              itemTag + sectionIndex + rowIndex + colIndex + itemIndex,
              videoView
            );
          } else if (itemElem.type === "audio") {
            let audioView = "";

            let audioUrl = itemElem.src;

            audioView = `
            <div class="Item${itemElem.attributes.id}${itemElem.type}Class">              
              <audio controls>
                <source src="${audioUrl}" type="audio/mpeg" />
                  Your browser does not support the audio element.
              </audio>
            </div>`;

            html = html.replace(
              itemTag + sectionIndex + rowIndex + colIndex + itemIndex,
              audioView
            );
          } else if (itemElem.type === "input") {
            let inputAttributes = itemElem.attributes.inputAttributes || {};
            let name = inputAttributes.name || "customer-name";
            let type = inputAttributes.type || "name";
            let placeholder = inputAttributes.placeholder || "Enter your name";
            let required =
              inputAttributes.required !== undefined
                ? inputAttributes.required
                : true;
            let errorMessage = inputAttributes.errorMessage || "";
            let value = inputAttributes.value || "";
            let limit =
              inputAttributes.limit !== undefined
                ? inputAttributes.limit
                : false;
            let maxLength = inputAttributes.max || "";
            let minLength = inputAttributes.min || "";

            let limitView = "";
            if (limit) {
              if (maxLength) {
                limitView = ` maxlength="${maxLength}"`;
              }
              if (minLength) {
                if (limitView) {
                  limitView = `${limitView} minlength="${minLength}"`;
                } else {
                  limitView = ` minlength="${minLength}"`;
                }
              }
            }

            let multipleLines = inputAttributes.multipleLines;

            let inputType = "input";
            if (multipleLines) {
              inputType = "textarea";
            }

            let inputView = `
            <div class="Item${itemElem.attributes.id}${itemElem.type}Class">
              <${inputType}
                id="${itemElem.attributes.id}"
                type="${type}"
                value="${value}"
                class="mpTextInputStandard Item${itemElem.attributes.id}${
              itemElem.type
            }InputClass"
                name="${name}"
                placeholder="${placeholder}"
                isReq=${required}                
                formName="${inputAttributes.formName}"
                errMsg="${errorMessage}"
                ${limitView}
                ${
                  inputAttributes.saveResponse
                    ? `data-response="${inputAttributes.saveResponse}"`
                    : ""
                }
                ${
                  inputAttributes.saveResponseType
                    ? `data-responseType="${inputAttributes.saveResponseType}"`
                    : ""
                }
                ${
                  inputAttributes.saveResponseTags
                    ? `data-responseTag="${inputAttributes.saveResponseTags}"`
                    : ""
                }                
                ${
                  inputAttributes.saveResponseTagAttribute
                    ? `data-responseTagAttribute="${inputAttributes.saveResponseTagAttribute}"`
                    : ""
                }                               
              ></${inputType}>
            </div>`;
            html = html.replace(
              itemTag + sectionIndex + rowIndex + colIndex + itemIndex,
              inputView
            );
          } else if (itemElem.type === "fileInput") {
            let inputAttributes = itemElem.attributes.inputAttributes || {};
            let name = inputAttributes.name || "file-attachment";
            let required =
              inputAttributes.required !== undefined
                ? inputAttributes.required
                : true;
            let errorMessage = inputAttributes.errorMessage || "";

            let inputType = "input";

            let inputView = `
            <div class="Item${itemElem.attributes.id}${itemElem.type}Class">
              <${inputType}
                id="${itemElem.attributes.id}"
                type="file"
                class="mpTextInputStandard Item${itemElem.attributes.id}${itemElem.type}InputClass"
                name="${name}"
                isReq=${required}                
                formName="${inputAttributes.formName}"
                errMsg="${errorMessage}"            
              ></${inputType}>
            </div>`;
            html = html.replace(
              itemTag + sectionIndex + rowIndex + colIndex + itemIndex,
              inputView
            );
          } else if (itemElem.type === "checkbox") {
            let inputAttributes = itemElem.attributes.inputAttributes || {};
            let name =
              inputAttributes.name || `checkbox-${itemElem.attributes.id}`;
            let required =
              inputAttributes.required !== undefined
                ? inputAttributes.required
                : true;
            let checkboxPosition = inputAttributes.checkboxPosition || "center";

            let checkboxView = `
            <div id="checkbox-${itemElem.attributes.id}"
              class="Item${itemElem.attributes.id}${itemElem.type}Class">
              <div class="mp-row">
                <div class="mp-col">
                  <label for="${itemElem.attributes.id}">
                    <input 
                      type="checkbox" 
                      id="${itemElem.attributes.id}" 
                      name="${name}" 
                      style="vertical-align:${checkboxPosition}"
                      class="Item${itemElem.attributes.id}${
              itemElem.type
            }CheckboxClass" 
                      formName="${inputAttributes.formName}"
                      isReq=${required} 
                      ${
                        inputAttributes.saveResponse
                          ? `data-response="${inputAttributes.saveResponse}"`
                          : ""
                      }
                      ${
                        inputAttributes.saveResponseType
                          ? `data-responseType="${inputAttributes.saveResponseType}"`
                          : ""
                      }
                      ${
                        inputAttributes.saveResponseTags
                          ? `data-responseTag="${inputAttributes.saveResponseTags}"`
                          : ""
                      }                
                      ${
                        inputAttributes.saveResponseTagAttribute
                          ? `data-responseTagAttribute="${inputAttributes.saveResponseTagAttribute}"`
                          : ""
                      }  
                    />                  
                    <div id="${itemElem.attributes.id}" class="Item${
              itemElem.attributes.id
            }${itemElem.type}CheckboxDivClass" >
                      ${attributes.content}
                    </div>
                  </label>
                </div>
              </div>
            </div>`;
            html = html.replace(
              itemTag + sectionIndex + rowIndex + colIndex + itemIndex,
              checkboxView
            );
          } else if (itemElem.type === "selectbox") {
            let inputAttributes = itemElem.attributes.inputAttributes || {};
            let name =
              inputAttributes.name || `option-${itemElem.attributes.id}`;
            let required = inputAttributes.required || true;
            let width = inputAttributes.width || "100";
            width = width + "%";

            let errorMessage = inputAttributes.errorMessage || "";
            let optionTags = itemElem.attributes.optionTags || {};
            let options = inputAttributes.options || "";
            let arrOptions = options.split("\n");

            let placeholder = inputAttributes.selectPlaceholder;
            if (placeholder === undefined) {
              placeholder = true;
            }
            let wOptions = [];
            if (placeholder) {
              wOptions.push(
                `<option value=''>${inputAttributes.placeholder}</option>`
              );
            }
            arrOptions.forEach((element) => {
              if (element) {
                let tags = optionTags[element] || [];
                let tagString = "";
                if (tags.length > 0) {
                  tagString = tags.map((i) => i).join(",");
                }
                wOptions.push(
                  `<option value="${element}" tag="${tagString}">${element}</option>`
                );
              }
            });

            let inputView = `
            <div class="Item${itemElem.attributes.id}${itemElem.type}Class">
              <select
                id="${itemElem.attributes.id}"
                name="${name}"
                isReq=${required}
                errMsg="${errorMessage}"
                style="width: ${width}"
                class="mpTextInputStandard"
                formName="${inputAttributes.formName}"
                ${
                  inputAttributes.saveResponse
                    ? `data-response="${inputAttributes.saveResponse}"`
                    : ""
                }
                ${
                  inputAttributes.saveResponseType
                    ? `data-responseType="${inputAttributes.saveResponseType}"`
                    : ""
                }
                ${
                  inputAttributes.saveResponseTags
                    ? `data-responseTag="${inputAttributes.saveResponseTags}"`
                    : ""
                }                
                ${
                  inputAttributes.saveResponseTagAttribute
                    ? `data-responseTagAttribute="${inputAttributes.saveResponseTagAttribute}"`
                    : ""
                }  
              >
              ${wOptions}
             </select>
            </div>`;
            html = html.replace(
              itemTag + sectionIndex + rowIndex + colIndex + itemIndex,
              inputView
            );
          } else if (itemElem.type === "line") {
            let lineView = `
            <div class="Item${itemElem.attributes.id}${itemElem.type}Class">
              <hr class="Item${itemElem.attributes.id}${itemElem.type}HRClass"/>
            </div>`;
            html = html.replace(
              itemTag + sectionIndex + rowIndex + colIndex + itemIndex,
              lineView
            );
          } else if (itemElem.type === "countdown") {
            let cdAttr = itemElem.attributes.countdownAttributes || {};

            let dateOption = cdAttr.dateType || "date";
            let year = cdAttr.year || new Date().getFullYear();
            let month = cdAttr.month || "1";
            let day = cdAttr.day || "01";
            let hour = cdAttr.hour || "00";
            let minute = cdAttr.minute || "01";

            let cdDeadline = new Date(
              Date.parse(new Date()) + parseInt(minute) * 60 * 1000
            );

            if (dateOption === "date") {
              cdDeadline = new Date(
                `${year}-${month}-${day} ${hour}:${minute}`
              );
            }

            let showDays = cdAttr.showDays;
            let showHours = cdAttr.showHours;
            let showMinutes = cdAttr.showMinutes;
            let showSeconds = cdAttr.showSeconds;

            if (showDays === undefined) {
              showDays = true;
            }
            if (showHours === undefined) {
              showHours = true;
            }
            if (showMinutes === undefined) {
              showMinutes = true;
            }
            if (showSeconds === undefined) {
              showSeconds = true;
            }

            let daysView = `<div class="Item${itemElem.attributes.id}${itemElem.type}SubDivClass">
              <span class="Item${itemElem.attributes.id}${itemElem.type}SubDivSpanClass countdownDays">00</span>
              <div class="Item${itemElem.attributes.id}${itemElem.type}SmallTextClass">Days</div>
            </div>`;

            let hoursView = `<div class="Item${itemElem.attributes.id}${itemElem.type}SubDivClass">
              <span class="Item${itemElem.attributes.id}${itemElem.type}SubDivSpanClass countdownHours">00</span>
              <div class="Item${itemElem.attributes.id}${itemElem.type}SmallTextClass">Hours</div>
            </div>`;

            let minutesView = ` <div class="Item${itemElem.attributes.id}${itemElem.type}SubDivClass">
              <span class="Item${itemElem.attributes.id}${itemElem.type}SubDivSpanClass countdownMinutes">00</span>
              <div class="Item${itemElem.attributes.id}${itemElem.type}SmallTextClass">Minutes</div>
            </div>`;

            let secondsView = `<div class="Item${itemElem.attributes.id}${itemElem.type}SubDivClass">
              <span class="Item${itemElem.attributes.id}${itemElem.type}SubDivSpanClass countdownSeconds">00</span>
              <div class="Item${itemElem.attributes.id}${itemElem.type}SmallTextClass">Seconds</div>
            </div>`;

            if (!showDays) {
              daysView = "";
            }
            if (!showHours) {
              hoursView = "";
            }
            if (!showMinutes) {
              minutesView = "";
            }
            if (!showSeconds) {
              secondsView = "";
            }

            let countdownView = `
            <div class="Item${itemElem.attributes.id}${itemElem.type}Class">
              <div id="clockdiv${itemElem.attributes.id}" class="Item${itemElem.attributes.id}${itemElem.type}MainDivClass">
               ${daysView}
               ${hoursView}
               ${minutesView}
               ${secondsView}                               
              </div>
            </div>
            <script>     
              let clockType${itemElem.attributes.id} = '${dateOption}';
              if(clockType${itemElem.attributes.id} === 'time'){
                let cdDeadline${itemElem.attributes.id} = new Date(
                  Date.parse(new Date()) + parseInt(${minute}) * 60 * 1000
                ); 

                if(ctGetCookie('clockdiv${itemElem.attributes.id}')){
                  initializeClock('clockdiv${itemElem.attributes.id}', ctGetCookie('clockdiv${itemElem.attributes.id}'));   
                }
                else {
                  initializeClock('clockdiv${itemElem.attributes.id}', cdDeadline${itemElem.attributes.id});
                }
                
              }else{
                initializeClock('clockdiv${itemElem.attributes.id}', '${cdDeadline}');
              }              
            </script>
            `;
            html = html.replace(
              itemTag + sectionIndex + rowIndex + colIndex + itemIndex,
              countdownView
            );
          } else if (itemElem.type === "icon") {
            let iconAttributes = getIconElemAttributes(
              itemElem.attributes.iconElemAttributes,
              editorType
            );

            let icon = getIconHtml(
              iconAttributes.color,
              iconAttributes.size,
              iconAttributes.name
            );

            let iconView = `
            
              <div>
                <div class="Item${itemElem.attributes.id}${itemElem.type}DivClass">
                  <div class="Item${itemElem.attributes.id}${itemElem.type}Class">
                    ${icon}
                  </div>                 
                </div>
              </div>              
            `;
            html = html.replace(
              itemTag + sectionIndex + rowIndex + colIndex + itemIndex,
              iconView
            );
          } else if (itemElem.type === "iconText") {
            let iconAttributes = getIconElemAttributes(
              itemElem.attributes.iconElemAttributes,
              editorType
            );

            let icon = getIconHtml(
              iconAttributes.color,
              iconAttributes.size,
              iconAttributes.name
            );

            let iconView = `
            <div class="Item${itemElem.attributes.id}${itemElem.type}Class">
                <table class="Item${itemElem.attributes.id}${itemElem.type}Class" style="display:flex;justify-content:${itemElem.attributes.attributes.textAlign}">
                  <tbody>
                    <tr>
                      <td style="padding-right:${iconAttributes.gap}px;vertical-align: ${iconAttributes.position}">${icon}</td>
                      <td>${attributes.content}</td>
                    </tr>
                  </tbody>
                </table>
            </div>`;
            html = html.replace(
              itemTag + sectionIndex + rowIndex + colIndex + itemIndex,
              iconView
            );
          } else if (itemElem.type === "imageText") {
            let attributes = itemElem.attributes.attributes || {};

            let maxWidth = attributes.maxWidth || "100px";
            let position = attributes.position || "middle";
            let gap = attributes.gap || "10";

            let imageTextView = `
            <div class="Item${itemElem.attributes.id}${itemElem.type}Class">
              <div class="mp-row">
                <div class="mp-col">
                  <table>
                    <tbody>
                      <tr>
                        <td style="max-width: ${maxWidth};padding-right:${gap}px;">
                          <img src="${itemElem.src}" style="width:100%;" id="${itemElem.attributes.id}" alt="I" />
                        </td>
                        <td style="vertical-align:${position}">${attributes.content}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>              
            </div>`;

            let linkUrl = attributes.linkUrl;
            let action = "";
            let newWindow = itemElem.attributes.imageLinkNewWindow;
            if (newWindow === undefined) {
              newWindow = false;
            }
            if (newWindow) {
              action = `${action} target="_blank"`;
            }
            if (linkUrl) {
              imageTextView = `<a border=0 href="${linkUrl}" ${action}>${imageTextView}</a>`;
            }

            html = html.replace(
              itemTag + sectionIndex + rowIndex + colIndex + itemIndex,
              imageTextView
            );
          } else if (
            itemElem.type === "productName" ||
            itemElem.type === "productDescription" ||
            itemElem.type === "productPrice" ||
            itemElem.type === "productImage" ||
            itemElem.type === "productCart"
          ) {
            let productId = itemElem.attributes.productId || "";
            let productView = "";

            if (productId) {
              if (itemElem.type === "productImage") {
                productView = `<img class="Item${itemElem.attributes.id}${itemElem.type}Class" src="{{product(${productId}->${itemElem.type})}}" id="${itemElem.attributes.id}" alt="I" responsiveImage style="width:100%"/>`;
              } else if (itemElem.type === "productCart") {
                productView = `                    
                  <table id="info-${productId}" border="0" cellspacing="0" cellpadding="0" class="Item${itemElem.attributes.id}${itemElem.type}Class">
                    <tbody>
                      <tr>
                        <td>
                          <div onclick="removeItemFromCart('${productId}')" class="mpCartItem mpCartChange" style="">-</div>
                        </td>
                        <td>
                          <div class="mpCartItem mpCartCount" style="padding-left: 10px;padding-right: 10px;">0</div>   
                        </td>                    
                        <td>
                          <div onclick="addItemToCart('${productId}')" class="mpCartItem mpCartChange">+</div>   
                        </td>
                      </tr>
                    </tbody>
                  </table>                
                  `;
              } else {
                productView = `
            <div class="Item${itemElem.attributes.id}${itemElem.type}Class">
              <p>{{product(${productId}->${itemElem.type})}}</p>
            </div>`;
              }
            }
            html = html.replace(
              itemTag + sectionIndex + rowIndex + colIndex + itemIndex,
              productView
            );
          } else if (itemElem.type === "audio") {
            let audioView = `
            <div>
              <audio id="myAudio" ontimeupdate="onTimeUpdate()">
                <source
                  id="source-audio"
                  src="https://www.soundhelix.com/examples/mp3/SoundHelix-Song-10.mp3"
                  type="audio/mp3"
                />
                Your browser does not support the audio element.
              </audio>

              <div class="player-ctn">
                <div class="player-pad">
                  <div class="ap-infos-ctn">
                    <div class="ap-timer">00:00</div>
                    <div class="ap-title">Audio Title</div>
                    <div class="ap-duration">00:00</div>
                  </div>
                  <div id="audioPlayerProgress">
                    <div id="audioPlayerBar"></div>
                  </div>
                  <div class="ap-btn-ctn">
                    <div class="ap-btn-action ap-first-btn" onclick="previous()">
                      <div id="btn-faws-back">                  
                        ${getIconHtml("#FFFFFF", "20", "previous")}
                      </div>
                    </div>
                    <div class="ap-btn-action" onclick="rewind()">
                      <div id="btn-faws-rewind">                  
                        ${getIconHtml("#FFFFFF", "20", "rewind")}
                      </div>
                    </div>
                    <div class="ap-btn-action" onclick="toggleAudio()">
                      <div id="btn-faws-play-pause">
                        <div id="icon-play">                    
                          ${getIconHtml("#FFFFFF", "20", "rightTriangle")}
                        </div>
                        <div id="icon-pause" style="display: none">                    
                          ${getIconHtml("#FFFFFF", "20", "pause")}
                        </div>
                      </div>
                    </div>
                    <div class="btn-play" onclick="forward()">
                      <div id="btn-faws-forward">                  
                        ${getIconHtml("#FFFFFF", "20", "fastForward")}
                      </div>
                    </div>
                    <div class="ap-btn-action" onclick="next()">
                      <div id="btn-faws-next">                  
                        ${getIconHtml("#FFFFFF", "20", "skip")}
                      </div>
                    </div>
                    <div class="btn-mute" id="toggleMute" onclick="toggleMute()">
                      <div id="btn-faws-volume">
                        <div id="icon-vol-up">                    
                          ${getIconHtml("#FFFFFF", "20", "fullAudio")}
                        </div>
                        <div id="icon-vol-mute" style="display: none">                    
                          ${getIconHtml("#FFFFFF", "20", "mute")}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="playlist-ctn"></div>
                </div>
              </div>
            </div>`;
            html = html.replace(
              itemTag + sectionIndex + rowIndex + colIndex + itemIndex,
              audioView
            );
          } else if (itemElem.type === "checkoutForm") {
            let id = itemElem.attributes.id;
            const checkoutId = itemElem.attributes.checkoutId || "checkoutId";
            const key = getItem("sk");
            const url = `https://order.memberpages.app?p=${checkoutId}&key=${key}&v=iframe`;
            let checkoutForm = `
              <iframe
                class="checkoutFrame"
                id="${id}"
                width="100%"      
                src="${url}"
                scrolling= "no"
                allowtransparency="true"
                style="border:none !important; margin:0px !important; padding:0px !important;"
              ></iframe>
            `;
            html = html.replace(
              itemTag + sectionIndex + rowIndex + colIndex + itemIndex,
              checkoutForm
            );
          }
        });
      });
    });
  });

  html += `<style>${animationStyle}</style>`;

  // prepare modal view
  // get all buttons with buttonActionType popup
  let popupButtons = [];
  let allPopupButtons = [];
  let itemList = json.contentEditor.itemList;
  let modalHtml = "";
  itemList.forEach((element) => {
    element.itemList.forEach((rowElem) => {
      rowElem.itemList.forEach((colElem) => {
        colElem.itemList.forEach((itemElem) => {
          if (
            itemElem.type === "button" &&
            itemElem.attributes.buttonActionType === "popup"
          ) {
            if (itemElem.attributes.buttonPopupId) {
              let fs = popupButtons.filter(
                (d) => d.popupId === itemElem.attributes.buttonPopupId
              );
              if (fs.length === 0) {
                popupButtons.push({
                  id: itemElem.attributes.id,
                  popupId: itemElem.attributes.buttonPopupId,
                });
                modalHtml += `modal${itemElem.attributes.buttonPopupId}`;
              }
              allPopupButtons.push({
                id: itemElem.attributes.id,
                popupId: itemElem.attributes.buttonPopupId,
              });
            }
          }
        });
      });
    });
  });

  let popupList = popups || [];

  popupButtons.forEach((button) => {
    let popups = popupList.filter((d) => d.id === button.popupId);

    if (popups.length > 0) {
      let popupInfo = popups[0];
      let html = generateHtml(defaultPopupHtml, popupInfo.json, "popup", []);

      let buttons = "";
      let fButtons = allPopupButtons.filter(
        (d) => d.popupId === button.popupId
      );

      fButtons.forEach((element) => {
        buttons = `
          ${buttons}
          var btn${element.id} = document.getElementById("button${element.id}");
              
          btn${element.id}.onclick = function() {
            modal${element.popupId}.style.display = "block";
          };
        `;
      });
      let mHtml = `
        <div id="modal${button.popupId}" class="modal${button.popupId}">      
          <div class="modalContent${button.popupId}">        
            ${html}        
          </div>
        </div>
        <script>      
          $(document).ready(function(){
            var modal${button.popupId} = document.getElementById("modal${button.popupId}");      

            ${buttons}
            
            $(window).click(function(e) {
              if (e.target == modal${button.popupId}) { modal${button.popupId}.style.display = "none"; }
            });
          });
        </script>
        `;

      let modalAttribuets = {
        display: "none",
        position: "fixed",
        zIndex: "10000",
        paddingTop: "100px",
        left: "0",
        top: "0",
        width: "100%",
        height: "100%",
        overflow: "auto",
        backgroundColor: "rgba(0,0,0,0.7)",
      };

      let modalBodyAttributes = popupInfo.json.contentEditor.bodyAttributes;

      let backgroundAttributes = {
        backgroundImage: modalBodyAttributes.backgroundImage,
        backgroundRepeat: modalBodyAttributes.backgroundRepeat,
        backgroundAttachment: modalBodyAttributes.backgroundAttachment,
        backgroundPosition: modalBodyAttributes.backgroundPosition,
        backgroundSize: modalBodyAttributes.backgroundSize,
        backgroundColor: modalBodyAttributes.backgroundColor,
      };

      let modalContentAttributes = {
        backgroundColor: "#fff",
        margin: "auto",
        border: "0",
        borderRadius: "10px",
        maxWidth: `${popupInfo.json.contentEditor.width}px`,
        ...backgroundAttributes,
      };

      styles += ` .modal${button.popupId}{${prepareStyle(modalAttribuets)}}`;
      styles += ` .modalContent${button.popupId}{${prepareStyle(
        modalContentAttributes
      )}}`;

      modalHtml = modalHtml.replace(`modal${button.popupId}`, mHtml);
    }
  });

  let hideOnMobile = json.contentEditor.hideOnMobile || {};
  let hideOnDesktop = json.contentEditor.hideOnDesktop || {};

  function handleHideOnMobile(id) {
    if (hideOnMobile[id]) {
      styles += `
        @media (max-width: 500px) {
          #${id} {
            display:none;
          }
        }
      `;
    }
  }

  function handleHideOnDesktop(id) {
    if (hideOnDesktop[id]) {
      styles += `
        @media (min-width: 500px) {
          #${id} {
            display:none;
          }
        }
      `;
    }
  }

  itemList.forEach((element) => {
    handleHideOnMobile(element.attributes.id);
    handleHideOnDesktop(element.attributes.id);
    element.itemList.forEach((rowElem) => {
      handleHideOnMobile(rowElem.attributes.id);
      handleHideOnDesktop(rowElem.attributes.id);
      rowElem.itemList.forEach((colElem) => {
        handleHideOnMobile(colElem.attributes.id);
        handleHideOnDesktop(colElem.attributes.id);
        colElem.itemList.forEach((itemElem) => {
          handleHideOnMobile(itemElem.attributes.id);
          handleHideOnDesktop(itemElem.attributes.id);
        });
      });
    });
  });

  html = html.replace(styleTag, styles);

  let customStyles = "";

  if (content.itemStyles) {
    Object.keys(content.itemStyles).forEach((d) => {
      customStyles += `#${d}{${content.itemStyles[d]}}`;
    });
  }

  if (customStyles || content.customStyles) {
    let styles = "";
    if (customStyles) {
      styles += customStyles;
    }
    if (content.customStyles) {
      styles = content.customStyles + styles;
    }
    html = html.replace("{{--REPLACE-CUSTOM-STYLE--}}", styles);
  } else {
    html = html.replace("{{--REPLACE-CUSTOM-STYLE--}}", "");
  }

  let fontStyleGoogle =
    "https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Lato:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Mirza:wght@400;700&family=Raleway:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Rubik:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap&family=Pacifico:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900";

  if (attributes) {
    let fonts = getEditorFonts(json);
    fonts.forEach((font) => {
      let fontPl = `&family=${font}:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900`;
      if (fontStyleGoogle.indexOf(font) === -1) {
        fontStyleGoogle = `${fontStyleGoogle}${fontPl}`;
      }
    });
  }

  let fontStyles = `<link href="${fontStyleGoogle}" rel="stylesheet" type="text/css">`;

  html = html.replace(fontStyleTag, fontStyles);

  html = html.replace(modalTag, modalHtml);

  return html;
};

export const prepareSection = (json) => {
  let sectionId = getUniqueId();
  let sectionJson = {
    type: "section",
    attributes: {
      id: sectionId,
      contentId: "",
      attributes: {
        alignment: "",
        backgroundColor: "",
        backgroundImage: "",
        marginTop: "0px",
        marginBottom: "20px",
        paddingTop: "0px",
        paddingBottom: "0px",
        paddingLeft: "15px",
        paddingRight: "15px",
      },
    },
    itemList: [],
  };
  let jsonBackup = Object.assign({}, json);
  let itemList = jsonBackup.contentEditor.itemList;
  itemList.push(sectionJson);
  jsonBackup.contentEditor.itemList = itemList;
  return { jsonBackup, sectionId };
};

export const prepareRow = (json, sectionId) => {
  let itemList = json.contentEditor.itemList;
  let width = json.contentEditor.width;
  let rowId = getUniqueId();

  itemList.forEach((element) => {
    if (element.type === "section" && element.attributes.id === sectionId) {
      element.itemList.push({
        type: "row",
        width: width + "px",
        selected: true,
        attributes: {
          id: rowId,
          contentId: "",
          attributes: {
            alignment: "",
            paddingTop: "0px",
            paddingLeft: "0px",
            paddingBottom: "0px",
            paddingRight: "0px",
            backgroundImage: "",
            marginTop: "0px",
            marginBottom: "0px",
            backgroundColor: "",
            color: "",
            borderCollapse: "separate",
            borderSpacing: "0px",
          },
        },
        itemList: [],
      });
    }
  });
  let jsonBackup = Object.assign({}, json);
  jsonBackup.contentEditor.itemList = itemList;
  return { jsonBackup, rowId };
};

export const deleteSection = (json, sectionId) => {
  let itemList = json.contentEditor.itemList;
  itemList = itemList.filter((d) => d.attributes.id !== sectionId);
  let jsonBackup = Object.assign({}, json);
  jsonBackup.contentEditor.itemList = itemList;
  return jsonBackup;
};

export const deleteRow = (json, sectionId, rowId) => {
  let itemList = json.contentEditor.itemList;
  itemList.forEach((element) => {
    if (element.attributes.id === sectionId) {
      element.itemList = element.itemList.filter(
        (d) => d.attributes.id !== rowId
      );
    }
  });
  let jsonBackup = Object.assign({}, json);
  jsonBackup.contentEditor.itemList = itemList;
  return jsonBackup;
};

export const deleteItem = (json, sectionId, rowId, colId, itemId) => {
  let itemList = json.contentEditor.itemList;
  itemList.forEach((element) => {
    if (element.attributes.id === sectionId) {
      element.itemList.forEach((rowElem) => {
        if (rowElem.attributes.id === rowId) {
          rowElem.itemList.forEach((colElem) => {
            if (colElem.attributes.id === colId) {
              colElem.itemList = colElem.itemList.filter(
                (d) => d.attributes.id !== itemId
              );
            }
          });
        }
      });
    }
  });
  let jsonBackup = Object.assign({}, json);
  jsonBackup.contentEditor.itemList = itemList;
  return jsonBackup;
};

export const selectSection = (json, sectionId) => {
  let itemList = json.contentEditor.itemList;
  itemList.forEach((element) => {
    element.selected = element.attributes.id === sectionId;
  });
  let jsonBackup = Object.assign({}, json);
  jsonBackup.contentEditor.itemList = itemList;
  return jsonBackup;
};

export const selectRow = (json, sectionId, rowId) => {
  let itemList = json.contentEditor.itemList;
  itemList.forEach((element) => {
    if (element.attributes.id === sectionId) {
      element.itemList.forEach((rowElem) => {
        rowElem.selected = rowElem.attributes.id === rowId;
      });
    } else {
      element.itemList.forEach((rowElem) => {
        rowElem.selected = false;
      });
    }
  });
  let jsonBackup = Object.assign({}, json);
  jsonBackup.contentEditor.itemList = itemList;
  return jsonBackup;
};

export const selectColumn = (json, sectionId, rowId, colId) => {
  let itemList = json.contentEditor.itemList;
  itemList.forEach((element) => {
    if (element.attributes.id === sectionId) {
      element.itemList.forEach((rowElem) => {
        if (rowElem.attributes.id === rowId) {
          rowElem.itemList.forEach((colElem) => {
            colElem.selected = colElem.attributes.id === colId;
          });
        } else {
          rowElem.itemList.forEach((colElem) => {
            colElem.selected = false;
          });
        }
      });
    } else {
      element.itemList.forEach((rowElem) => {
        rowElem.itemList.forEach((colElem) => {
          colElem.selected = false;
        });
      });
    }
  });
  let jsonBackup = Object.assign({}, json);
  jsonBackup.contentEditor.itemList = itemList;
  return jsonBackup;
};

export const selectItem = (json, sectionId, rowId, colId, item) => {
  let itemList = json.contentEditor.itemList;
  itemList.forEach((element) => {
    if (element.attributes.id === sectionId) {
      element.itemList.forEach((rowElem) => {
        if (rowElem.attributes.id === rowId) {
          rowElem.itemList.forEach((colElem) => {
            if (colElem.attributes.id === colId) {
              colElem.itemList.forEach((itemElem) => {
                itemElem.attributes.key = Math.random();
                itemElem.key = Math.random();
                itemElem.selected =
                  itemElem.attributes.id === item.attributes.id;
              });
            } else {
              colElem.itemList.forEach((itemElem) => {
                itemElem.selected = false;
              });
            }
          });
        } else {
          rowElem.itemList.forEach((colElem) => {
            colElem.itemList.forEach((itemElem) => {
              itemElem.selected = false;
            });
          });
        }
      });
    } else {
      element.itemList.forEach((rowElem) => {
        rowElem.itemList.forEach((colElem) => {
          colElem.itemList.forEach((itemElem) => {
            itemElem.selected = false;
          });
        });
      });
    }
  });
  let jsonBackup = Object.assign({}, json);
  jsonBackup.contentEditor.itemList = itemList;
  return jsonBackup;
};

export const changeUrlAttr = (
  json,
  sectionId,
  rowId,
  colId,
  item,
  urlAttr,
  type
) => {
  let itemList = json.contentEditor.itemList;
  itemList.forEach((element) => {
    if (element.attributes.id === sectionId) {
      element.itemList.forEach((rowElem) => {
        if (rowElem.attributes.id === rowId) {
          rowElem.itemList.forEach((colElem) => {
            if (colElem.attributes.id === colId) {
              colElem.itemList.forEach((itemElem) => {
                if (itemElem.attributes.id === item.attributes.id) {
                  if (
                    type === "imageUrl" ||
                    type === "videoUrl" ||
                    type === "audioUrl"
                  ) {
                    itemElem.src = urlAttr[itemElem.attributes.id];
                    if (type === "videoUrl") {
                      var rest = itemElem.src.substring(
                        0,
                        itemElem.src.lastIndexOf("/") + 1
                      );

                      let thumbUrl = `${rest}thumb.jpeg`;
                      itemElem.srcThumb = thumbUrl;
                      urlAttr[itemElem.attributes.id + "-thumb"] = thumbUrl;
                    }
                  }
                  if (type === "thumbUrl") {
                    itemElem.srcThumb =
                      urlAttr[itemElem.attributes.id + "-thumb"];
                  }
                  if (type === "buttonUrl") {
                    itemElem.src = urlAttr[itemElem.attributes.id];
                  }
                }
              });
            }
          });
        }
      });
    }
  });
  let jsonBackup = Object.assign({}, json);
  jsonBackup.contentEditor.urlAttributes = urlAttr;
  jsonBackup.contentEditor.itemList = itemList;
  return jsonBackup;
};

export const changeDefaultStyle = (
  json,
  sectionId,
  rowId,
  colId,
  item,
  defaultStyle
) => {
  let itemList = json.contentEditor.itemList;
  itemList.forEach((element) => {
    if (element.attributes.id === sectionId) {
      element.itemList.forEach((rowElem) => {
        if (rowElem.attributes.id === rowId) {
          rowElem.itemList.forEach((colElem) => {
            if (colElem.attributes.id === colId) {
              colElem.itemList.forEach((itemElem) => {
                if (itemElem.attributes.id === item.attributes.id) {
                  itemElem.useDefaultStyle =
                    defaultStyle[itemElem.attributes.id];
                }
              });
            }
          });
        }
      });
    }
  });
  let jsonBackup = Object.assign({}, json);
  jsonBackup.contentEditor.defaultStyle = defaultStyle;
  jsonBackup.contentEditor.itemList = itemList;
  return jsonBackup;
};

export const changeUsePageWidth = (json, sectionId, sectionWidth) => {
  let itemList = json.contentEditor.itemList;
  itemList.forEach((element) => {
    if (element.attributes.id === sectionId) {
      element.usePageWidth = sectionWidth[sectionId];
    }
  });
  let jsonBackup = Object.assign({}, json);
  jsonBackup.contentEditor.sectionWidth = sectionWidth;
  jsonBackup.contentEditor.itemList = itemList;
  return jsonBackup;
};

export const changeButtonFullWidth = (
  json,
  sectionId,
  rowId,
  colId,
  item,
  btnFullWidth
) => {
  let itemList = json.contentEditor.itemList;
  itemList.forEach((element) => {
    if (element.attributes.id === sectionId) {
      element.itemList.forEach((rowElem) => {
        if (rowElem.attributes.id === rowId) {
          rowElem.itemList.forEach((colElem) => {
            if (colElem.attributes.id === colId) {
              colElem.itemList.forEach((itemElem) => {
                if (itemElem.attributes.id === item.attributes.id) {
                  var isFullWidth = btnFullWidth[itemElem.attributes.id];
                  itemElem.attributes.attributes.width = isFullWidth
                    ? "100%"
                    : "auto";
                }
              });
            }
          });
        }
      });
    }
  });
  let jsonBackup = Object.assign({}, json);
  jsonBackup.contentEditor.buttonFullWidth = btnFullWidth;
  jsonBackup.contentEditor.itemList = itemList;
  return jsonBackup;
};

export const changeLockElem = (json, id, lockElem) => {
  let itemList = json.contentEditor.itemList;
  itemList.forEach((element) => {
    if (element.attributes.id === id) {
      element.lock = lockElem[id];
      element.itemList.forEach((rowElem) => {
        if (rowElem.attributes.id === id) {
          rowElem.lock = lockElem[id];
          rowElem.itemList.forEach((colElem) => {
            if (colElem.attributes.id === id) {
              colElem.lock = lockElem[id];
              colElem.itemList.forEach((itemElem) => {
                if (itemElem.attributes.id === id) {
                  itemElem.lock = lockElem[id];
                }
              });
            }
          });
        }
      });
    }
  });
  let jsonBackup = Object.assign({}, json);
  jsonBackup.contentEditor.lockElem = lockElem;
  jsonBackup.contentEditor.itemList = itemList;
  return jsonBackup;
};

export const changeHideOnMobileElem = (json, id, hideOnMobile) => {
  let itemList = json.contentEditor.itemList;
  let jsonBackup = Object.assign({}, json);
  jsonBackup.contentEditor.hideOnMobile = hideOnMobile;
  jsonBackup.contentEditor.itemList = itemList;
  return jsonBackup;
};

export const changeHideOnDesktopElem = (json, id, hideOnDesktop) => {
  let itemList = json.contentEditor.itemList;
  let jsonBackup = Object.assign({}, json);
  jsonBackup.contentEditor.hideOnDesktop = hideOnDesktop;
  jsonBackup.contentEditor.itemList = itemList;
  return jsonBackup;
};

export const selectAllColumn = (json) => {
  let itemList = json.contentEditor.itemList;
  itemList.forEach((element) => {
    element.itemList.forEach((rowElem) => {
      rowElem.itemList.forEach((colElem) => {
        colElem.selected = true;
      });
    });
  });
  let jsonBackup = Object.assign({}, json);
  jsonBackup.contentEditor.itemList = itemList;
  return jsonBackup;
};

export const selectGrid = (json, type, selectedSectionId, selectedRowId) => {
  let itemList = json.contentEditor.itemList;
  itemList.forEach((element) => {
    if (element.attributes.id === selectedSectionId) {
      element.itemList.forEach((rowElem) => {
        if (rowElem.attributes.id === selectedRowId) {
          const itemListLen = rowElem.itemList.length;

          if (itemListLen > type) {
            rowElem.itemList = rowElem.itemList.slice(0, type);
          }

          if (itemListLen < type) {
            type = type - itemListLen;

            for (let i = 0; i < type; i++) {
              rowElem.itemList.push({
                type: "column",
                width: (100 / type).toFixed(0),
                widthPer: (100 / type).toFixed(0),
                overflowWrap: "break-word",
                attributes: {
                  id: getUniqueId(),
                  contentId: "",
                  attributes: {
                    paddingLeft: "0px",
                    paddingRight: "0px",
                    paddingTop: "0px",
                    paddingBottom: "0px",
                    backgroundColor: "",
                    backgroundImage: "",
                    marginTop: "0px",
                    marginBottom: "0px",
                    textAlign: "",
                    fontSize: "",
                  },
                },
                itemList: [],
              });
            }
          }
          const newLen = rowElem.itemList.length;
          rowElem.itemList.forEach((element) => {
            element.width = (100 / newLen).toFixed(0);
            element.widthPer = (100 / newLen).toFixed(0);
          });
        }
      });
    }
  });
  let jsonBackup = Object.assign({}, json);
  jsonBackup.contentEditor.itemList = itemList;
  return jsonBackup;
};

export const getDefaultAttributes = (type, editorType = "email") => {
  let commonAttributes = {
    paddingLeft: "0px",
    paddingRight: "0px",
    paddingTop: "0px",
    paddingBottom: "0px",
    backgroundColor: "",
    backgroundImage: "",
    marginTop: "0px",
    marginBottom: "0px",
    content: "",
    title: "",
    color: "",
    borderRadius: "",
    border: "",
    fontSize: "14px",
    fontFamily: "",
    marginLeft: "",
    marginRight: "",
    textAlign: "",
    letterSpacing: "0px",
    fontWeight: "400",
  };

  let attributes = Object.assign({}, commonAttributes);

  let buttonAttributes = {
    ...commonAttributes,
    backgroundColor: "#36abee",
    backgroundImage: "",
    color: "#ffffff",
    title: "Button",
    border: "",
    display: editorType === "email" ? "" : "inline-block",
    borderRadius: "6px",
    fontSize: "26px",
    width: "auto",
    fontFamily: editorType === "email" ? "Arial" : "Ubuntu",
    textAlign: "center",
    marginTop: editorType === "email" ? "0px" : "10px",
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
  };

  let titleAttributes = {
    ...commonAttributes,
    fontFamily: editorType === "email" ? "Arial" : "Ubuntu",
    fontSize: "40px",
    color: "#000000",
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
  };

  let subTitleAttributes = {
    ...commonAttributes,
    fontFamily: editorType === "email" ? "Arial" : "Ubuntu",
    fontSize: "30px",
    color: "#000000",
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
  };

  let textAttributes = {
    ...commonAttributes,
    fontFamily: editorType === "email" ? "Arial" : "Ubuntu",
    fontSize: "22px",
    color: "#000000",
    textAlign: "left",
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
  };

  let iconAttributes = {
    ...commonAttributes,
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
  };

  let inputAttributes = {
    ...commonAttributes,
    marginBottom: "10px",
    marginTop: "0px",
    textAlign: "left",
  };

  let checkboxAttributes = {
    ...commonAttributes,
    ...textAttributes,
    marginTop: "10px",
  };

  let iconTextAttributes = {
    ...commonAttributes,
    fontSize: "14px",
    lineHeight: "14px",
    textAlign: "left",
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingTop: "10px",
    paddingBottom: "10px",
  };

  if (type === "button") {
    attributes = buttonAttributes;
  } else if (type === "title") {
    attributes = titleAttributes;
  } else if (type === "subTitle") {
    attributes = subTitleAttributes;
  } else if (type === "text") {
    attributes = textAttributes;
  } else if (type === "input" || type === "selectbox" || type === "fileInput") {
    attributes = inputAttributes;
  } else if (type === "checkbox") {
    attributes = checkboxAttributes;
  } else if (type === "iconText") {
    attributes = iconTextAttributes;
  } else if (type === "imageText") {
    attributes = iconTextAttributes;
  } else if (type === "section") {
    attributes.marginBottom = "20px";
    attributes.paddingTop = "0px";
    attributes.paddingBottom = "0px";
    attributes.paddingLeft = "15px";
    attributes.paddingRight = "15px";
  } else if (type === "image") {
    attributes.textAlign = "center";
    attributes.marginLeft = "auto";
    attributes.marginRight = "auto";
    attributes.width = "100%";
  } else if (type === "icon") {
    attributes = iconAttributes;
  } else if (type === "video") {
    attributes = iconAttributes;
    attributes.textAlign = "center";
    attributes.maxWidth = "700";
    attributes.isMaxWidth = true;
  } else if (type === "imageSlider") {
    attributes = iconAttributes;
  } else if (type === "checkoutForm") {
    attributes = iconAttributes;
  }

  return attributes;
};

export const addTextElement = (
  json,
  selectedColId,
  selectedRowId,
  selectedSectionId,
  type,
  editorType,
  selectedItem
) => {
  if (selectedColId && selectedRowId && selectedSectionId) {
    let itemList = json.contentEditor.itemList;
    let id = getUniqueId();
    let subId = getUniqueId();
    let attributes = getDefaultAttributes(type, editorType);
    let inputAttributes = {};
    if (type === "input") {
      inputAttributes["name"] = "customer-name";
      inputAttributes["width"] = "100";
      inputAttributes["placeholder"] = "Enter your name";
      inputAttributes["required"] = true;
      inputAttributes["type"] = "name";
      inputAttributes["formName"] = "form0";
      inputAttributes["color"] = "#000000";
      inputAttributes["fontSize"] = "12";
      inputAttributes["errorMessage"] = "Please enter your name";
    }
    if (type === "selectbox") {
      inputAttributes["name"] = `option-${id}`;
      inputAttributes["width"] = "100";
      inputAttributes["placeholder"] = "Select an Option";
      inputAttributes["required"] = true;
      inputAttributes["formName"] = "form0";
    }
    if (type === "checkbox") {
      inputAttributes["name"] = `checkbox-${id}`;
      inputAttributes["required"] = true;
      inputAttributes["formName"] = "form0";
      inputAttributes["errorMessage"] = "Field not compelted";
    }
    if (type === "fileInput") {
      inputAttributes["name"] = `file-${id}`;
      inputAttributes["formName"] = "form0";
      inputAttributes["disabled"] = false;
    }
    if (type === "checkoutForm") {
      inputAttributes["width"] = "100";
      inputAttributes["formName"] = "form0";
    }
    let item = {
      type: type,
      attributes: {
        id: id,
        subId: subId,
        contentId: "",
        attributes: attributes,
        inputAttributes: inputAttributes,
      },
    };

    itemList.forEach((element) => {
      if (element.attributes.id === selectedSectionId) {
        element.itemList.forEach((rowElem) => {
          if (rowElem.attributes.id === selectedRowId) {
            rowElem.itemList.forEach((colElem) => {
              if (colElem.attributes.id === selectedColId) {
                if (
                  selectedItem &&
                  selectedItem.attributes &&
                  selectedItem.attributes.id
                ) {
                  let itemIndex = colElem.itemList.findIndex(
                    (d) => d.attributes.id === selectedItem.attributes.id
                  );
                  colElem.itemList.splice(itemIndex + 1, 0, item);
                } else {
                  colElem.itemList.push(item);
                }
              }
            });
          }
        });
      }
    });
    let jsonBackup = Object.assign({}, json);
    jsonBackup.contentEditor.itemList = itemList;
    return { jsonBackup, id, subId, item };
  }
};

export const saveHtml = (json, textValues, draftJsonValues) => {
  let itemList = json.contentEditor.itemList;
  itemList.forEach((secElem) => {
    secElem.itemList.forEach((rowElem) => {
      rowElem.itemList.forEach((colElem) => {
        colElem.itemList.forEach((itemElem) => {
          let cloneItemElem = JSON.parse(JSON.stringify(itemElem));
          let textTypes = [
            "text",
            "title",
            "subTitle",
            "checkbox",
            "iconText",
            "imageText",
          ];
          if (textTypes.indexOf(cloneItemElem.type) > -1) {
            let value = textValues[cloneItemElem.attributes.id];
            if (value) {
              cloneItemElem.attributes.attributes.content = value;
            }
            let jsonValue = draftJsonValues[cloneItemElem.attributes.id];
            if (jsonValue) {
              cloneItemElem.attributes.attributes.json = jsonValue;
            }
          }
          if (cloneItemElem.type === "button") {
            let value = textValues[cloneItemElem.attributes.id];
            if (value) {
              cloneItemElem.attributes.attributes.title = value;
            }
            let jsonValue = draftJsonValues[cloneItemElem.attributes.id];
            if (jsonValue) {
              cloneItemElem.attributes.attributes.json = jsonValue;
            }

            let subValue = textValues[cloneItemElem.attributes.subId];
            if (subValue) {
              cloneItemElem.attributes.attributes.subTitle = subValue;
            }
            let subJsonValue = draftJsonValues[cloneItemElem.attributes.subId];
            if (subJsonValue) {
              cloneItemElem.attributes.attributes.subJson = subJsonValue;
            }
          }
          itemElem.attributes = cloneItemElem.attributes;
        });
      });
    });
  });
  json.contentEditor.itemList = itemList;
  return json;
};

export const changeRowWidth = (json, customWidth, sectionId, rowId) => {
  let itemList = json.contentEditor.itemList;
  itemList.forEach((element) => {
    if (element.attributes.id === sectionId) {
      element.itemList.forEach((rowElem) => {
        if (rowElem.attributes.id === rowId) {
          rowElem.itemList.forEach((colElem) => {
            colElem.widthPer = customWidth[colElem.attributes.id];
          });
        }
      });
    }
  });
  let jsonBackup = Object.assign({}, json);
  jsonBackup.contentEditor.itemList = itemList;
  jsonBackup.contentEditor.customWidth = customWidth;
  return jsonBackup;
};

export const moveRow = (json, rowId, sectionId, type) => {
  let itemList = json.contentEditor.itemList;
  let newSectionId = sectionId;
  itemList.forEach((element, secIndex) => {
    if (element.attributes.id === sectionId) {
      let rows = element.itemList || [];
      let rowIndex = rows.findIndex((x) => x.attributes.id === rowId);
      let toIndex = type === "up" ? rowIndex - 1 : rowIndex + 1;
      if (toIndex > -1 && toIndex < rows.length) {
        rows = arrayMove(rows, rowIndex, toIndex);
      }
      if (toIndex === -1) {
        let item = rows[rowIndex];
        let newSecIndex = secIndex - 1;
        if (newSecIndex !== -1) {
          itemList[newSecIndex].itemList.push(item);
          rows.splice(rowIndex, 1);
          newSectionId = itemList[newSecIndex].attributes.id;
        }
      }
      if (toIndex >= rows.length) {
        let item = rows[rowIndex];
        let newSecIndex = secIndex + 1;
        if (itemList[newSecIndex]) {
          itemList[newSecIndex].itemList.unshift(item);
          rows.splice(rowIndex, 1);
          newSectionId = itemList[newSecIndex].attributes.id;
        }
      }
      element.itemList = rows;
    }
  });
  let jsonBackup = Object.assign({}, json);
  jsonBackup.contentEditor.itemList = itemList;
  return { jsonBackup, newSectionId };
};

export const moveSection = (json, sectionId, type) => {
  let itemList = json.contentEditor.itemList;

  let sections = itemList || [];
  let rowIndex = sections.findIndex((x) => x.attributes.id === sectionId);
  let toIndex = type === "up" ? rowIndex - 1 : rowIndex + 1;
  if (toIndex > -1 && toIndex < sections.length) {
    sections = arrayMove(sections, rowIndex, toIndex);
  }
  let jsonBackup = Object.assign({}, json);
  jsonBackup.contentEditor.itemList = sections;
  return jsonBackup;
};

export const moveItem = (json, sectionId, rowId, colId, itemData, type) => {
  let itemList = json.contentEditor.itemList;
  let newSectionId = sectionId;
  let itemId = itemData.attributes.id;
  let newColId = colId;
  let newRowId = rowId;

  function getNewRowIndex(rowIndex, rowList, type) {
    let newRowIndex = type === "up" ? rowIndex - 1 : rowIndex + 1;
    if (newRowIndex === -1 && type === "up") {
      return newRowIndex;
    }
    if (newRowIndex > rowList.length && type === "down") {
      return newRowIndex;
    }
    if (rowList[newRowIndex] && rowList[newRowIndex].itemList.length === 0) {
      newRowIndex = getNewRowIndex(newRowIndex, rowList, type);
    }
    return newRowIndex;
  }

  itemList.forEach((element, secIndex) => {
    if (element.attributes.id === sectionId) {
      let rowList = element.itemList;
      element.itemList.forEach((rowElem, rowIndex) => {
        if (rowElem.attributes.id === rowId) {
          let columnList = rowElem.itemList;
          rowElem.itemList.forEach((colElem, colIndex) => {
            if (colElem.attributes.id === colId) {
              let items = colElem.itemList || [];
              let itemIndex = items.findIndex(
                (x) => x.attributes.id === itemId
              );
              let toIndex = type === "up" ? itemIndex - 1 : itemIndex + 1;
              if (toIndex > -1 && toIndex < items.length) {
                items = arrayMove(items, itemIndex, toIndex);
              }
              if (toIndex === -1 && type === "up") {
                let item = items[itemIndex];
                let newColIndex = colIndex - 1;
                if (newColIndex !== -1) {
                  columnList[newColIndex].itemList.push(item);
                  items.splice(itemIndex, 1);
                  newColId = columnList[newColIndex].attributes.id;
                } else {
                  let newRowIndex = getNewRowIndex(rowIndex, rowList, type);
                  if (newRowIndex !== -1) {
                    let newColIndex = rowList[newRowIndex].itemList.length - 1;
                    rowList[newRowIndex].itemList[newColIndex].itemList.push(
                      item
                    );
                    items.splice(itemIndex, 1);
                    newRowId = rowList[newRowIndex].attributes.id;
                    newColId =
                      rowList[newRowIndex].itemList[newColIndex].attributes.id;
                  } else {
                    let newSectionIndex = secIndex - 1;
                    if (newSectionIndex !== -1) {
                      let newRowIndex =
                        itemList[newSectionIndex].itemList.length - 1;
                      let newColIndex =
                        itemList[newSectionIndex].itemList[newRowIndex].itemList
                          .length - 1;
                      itemList[newSectionIndex].itemList[newRowIndex].itemList[
                        newColIndex
                      ].itemList.push(item);
                      items.splice(itemIndex, 1);
                      newSectionId = itemList[newSectionIndex].attributes.id;
                      newRowId =
                        itemList[newSectionIndex].itemList[newRowIndex]
                          .attributes.id;
                      newColId =
                        itemList[newSectionIndex].itemList[newRowIndex]
                          .itemList[newColIndex].attributes.id;
                    }
                  }
                }
              }

              if (toIndex >= items.length && type === "down") {
                let item = items[itemIndex];
                let newColIndex = colIndex + 1;
                if (newColIndex < columnList.length) {
                  columnList[newColIndex].itemList.unshift(item);
                  items.splice(itemIndex, 1);
                  newColId = columnList[newColIndex].attributes.id;
                } else {
                  let newRowIndex = getNewRowIndex(rowIndex, rowList, type);
                  if (newRowIndex < rowList.length) {
                    rowList[newRowIndex].itemList[0].itemList.unshift(item);
                    items.splice(itemIndex, 1);
                    newColId = rowList[newRowIndex].itemList[0].attributes.id;
                    newRowId = rowList[newRowIndex].attributes.id;
                  } else {
                    let newSectionIndex = secIndex + 1;
                    if (newSectionIndex < itemList.length) {
                      itemList[
                        newSectionIndex
                      ].itemList[0].itemList[0].itemList.unshift(item);
                      items.splice(itemIndex, 1);
                      newColId =
                        itemList[newSectionIndex].itemList[0].itemList[0]
                          .attributes.id;
                      newRowId =
                        itemList[newSectionIndex].itemList[0].attributes.id;
                      newSectionId = itemList[newSectionIndex].attributes.id;
                    }
                  }
                }
              }
              colElem.itemList = items;
            }
          });
          rowElem.itemList = columnList;
        }
      });
      element.itemList = rowList;
    }
  });
  let jsonBackup = Object.assign({}, json);
  jsonBackup.contentEditor.itemList = itemList;
  return { jsonBackup, newSectionId, newColId, newRowId };
};

const changeItemSpacing = (item, attributes, editorType) => {
  let cloneItemElem = JSON.parse(JSON.stringify(item));

  let id = cloneItemElem.attributes.id;
  let newAttributes = cloneItemElem.attributes.attributes;
  let hoverAttributes = cloneItemElem.attributes.hoverAttributes || {};
  let iconAttributes = cloneItemElem.attributes.iconAttributes || {};
  let iconElemAttributes = cloneItemElem.attributes.iconElemAttributes || {};
  let inputAttributes = cloneItemElem.attributes.inputAttributes || {};
  let countdownAttributes = cloneItemElem.attributes.countdownAttributes || {};
  let buttonFormName = cloneItemElem.attributes.formName || "form0";
  let buttonFormStaffUser = cloneItemElem.attributes.buttonFormStaffUser;
  let buttonActionType = cloneItemElem.attributes.buttonActionType || "url";
  let buttonPopupId = cloneItemElem.attributes.buttonPopupId || "";
  let buttonCheckoutId = cloneItemElem.attributes.buttonCheckoutId || "";
  let buttonTags = cloneItemElem.attributes.buttonTags || [];
  let optionTags = cloneItemElem.attributes.optionTags || {};
  let buttonThankYouUrl = cloneItemElem.attributes.buttonThankYouUrl || "";
  let buttonNewWindow = cloneItemElem.attributes.buttonNewWindow;
  let buttonMarkCompleted = cloneItemElem.attributes.buttonMarkCompleted;
  let imageLinkNewWindow = cloneItemElem.attributes.imageLinkNewWindow || false;
  let buttonSecondLine = cloneItemElem.attributes.buttonSecondLine;
  let buttonSecondLineFontSize =
    cloneItemElem.attributes.buttonSecondLineFontSize || "14";
  let buttonSecondLineLineHeight =
    cloneItemElem.attributes.buttonSecondLineLineHeight || "14";
  let buttonSaveSubmission = cloneItemElem.attributes.buttonSaveSubmission;
  let buttonCreateCustomer = cloneItemElem.attributes.buttonCreateCustomer;
  let linkColor = cloneItemElem.attributes.linkColor || "";
  let linkColorHover = cloneItemElem.attributes.linkColorHover || "";
  let menuList = cloneItemElem.attributes.menuList || [];
  let productId = cloneItemElem.attributes.productId || "";
  let sliderImages = cloneItemElem.attributes.sliderImages || "";
  let sliderFullImage = cloneItemElem.attributes.sliderFullImage;
  let buttonSaveLead = cloneItemElem.attributes.buttonSaveLead;
  let buttonLeadStaffUser = cloneItemElem.attributes.buttonLeadStaffUser;
  let checkoutId = cloneItemElem.attributes.checkoutId;

  let socialFacebookLink = cloneItemElem.attributes.socialFacebookLink || "";
  let socialLinkedinLink = cloneItemElem.attributes.socialLinkedinLink || "";
  let socialYoutubeLink = cloneItemElem.attributes.socialYoutubeLink || "";
  let socialInstagramLink = cloneItemElem.attributes.socialInstagramLink || "";
  let socialPintrestLink = cloneItemElem.attributes.socialPintrestLink || "";
  let socialTwitterLink = cloneItemElem.attributes.socialTwitterLink || "";

  let lock = cloneItemElem.attributes.lock;

  if (attributes[id]) {
    newAttributes["paddingTop"] = attributes[id]["paddingTop"]
      ? attributes[id]["paddingTop"] + "px"
      : getDefaultAttributes(cloneItemElem.type, editorType).paddingTop;
    newAttributes["paddingBottom"] = attributes[id]["paddingBottom"]
      ? attributes[id]["paddingBottom"] + "px"
      : getDefaultAttributes(cloneItemElem.type, editorType).paddingBottom;
    newAttributes["paddingLeft"] = attributes[id]["paddingLeft"]
      ? attributes[id]["paddingLeft"] + "px"
      : getDefaultAttributes(cloneItemElem.type, editorType).paddingLeft;
    newAttributes["paddingRight"] = attributes[id]["paddingRight"]
      ? attributes[id]["paddingRight"] + "px"
      : getDefaultAttributes(cloneItemElem.type, editorType).paddingRight;

    newAttributes["marginTop"] = attributes[id]["marginTop"]
      ? attributes[id]["marginTop"] + "px"
      : getDefaultAttributes(cloneItemElem.type, editorType).marginTop;
    newAttributes["marginBottom"] = attributes[id]["marginBottom"]
      ? attributes[id]["marginBottom"] + "px"
      : getDefaultAttributes(cloneItemElem.type, editorType).marginBottom;

    newAttributes["marginRight"] = attributes[id]["marginRight"]
      ? attributes[id]["marginRight"] + "px"
      : getDefaultAttributes(cloneItemElem.type, editorType).marginRight;
    newAttributes["marginLeft"] = attributes[id]["marginLeft"]
      ? attributes[id]["marginLeft"] + "px"
      : getDefaultAttributes(cloneItemElem.type, editorType).marginLeft;

    newAttributes["backgroundColor"] = attributes[id]["backgroundColor"]
      ? attributes[id]["backgroundColor"]
      : getDefaultAttributes(cloneItemElem.type, editorType).backgroundColor;

    newAttributes["backgroundColorRange"] = attributes[id][
      "backgroundColorRange"
    ]
      ? attributes[id]["backgroundColorRange"]
      : 1;

    newAttributes["color"] = attributes[id]["color"]
      ? attributes[id]["color"]
      : getDefaultAttributes(cloneItemElem.type, editorType).color;

    newAttributes["borderRadius"] = attributes[id]["borderRadius"]
      ? attributes[id]["borderRadius"] + "px"
      : getDefaultAttributes(cloneItemElem.type, editorType).borderRadius;

    if (attributes[id]["backgroundImage"]) {
      newAttributes[
        "backgroundImage"
      ] = `url(${attributes[id]["backgroundImage"]})`;
      newAttributes["backgroundRepeat"] = attributes[id]["backgroundRepeat"]
        ? "repeat"
        : "no-repeat";
      if (newAttributes["backgroundRepeat"] === "no-repeat") {
        newAttributes["backgroundAttachment"] =
          attributes[id]["backgroundAttachment"] || "scroll";
        newAttributes["backgroundPosition"] =
          attributes[id]["backgroundPosition"] || "top";
        newAttributes["backgroundSize"] = attributes[id]["backgroundSize"]
          ? "contain"
          : "cover";
      } else {
        newAttributes["backgroundAttachment"] = "unset";
        newAttributes["backgroundSize"] = "unset";
      }
    } else {
      newAttributes["backgroundImage"] = "";
    }

    let backgroundFull = attributes[id]["backgroundFull"];
    if (backgroundFull === undefined) {
      backgroundFull = true;
    }

    newAttributes["backgroundFull"] = backgroundFull;

    if (cloneItemElem.type === "image" || cloneItemElem.type === "video") {
      if (attributes[id]["maxWidth"]) {
        newAttributes["maxWidth"] = `${attributes[id]["maxWidth"]}px`;
        if (cloneItemElem.type === "image") {
          newAttributes["width"] = `${attributes[id]["maxWidth"]}px`;
        }
      }
      newAttributes["videoType"] = attributes[id]["videoType"] || "16-9";
    } else {
      if (attributes[id]["maxWidth"]) {
        if (editorType === "email") {
          newAttributes["maxWidth"] = `${attributes[id]["maxWidth"]}px`;
        } else if (editorType === "web" || editorType === "popup") {
          newAttributes["maxWidth"] = `${attributes[id]["maxWidth"]}px`;
        }
      }
    }
    if (cloneItemElem.type !== "section") {
      newAttributes["isMaxWidth"] = attributes[id]["isMaxWidth"];
      if (
        !newAttributes["isMaxWidth"] ||
        !newAttributes["maxWidth"] ||
        newAttributes["maxWidth"] === "0"
      ) {
        delete newAttributes["maxWidth"];
      }
    }

    newAttributes["linkUrl"] = attributes[id]["linkUrl"] || "";
    imageLinkNewWindow = attributes[id]["imageLinkNewWindow"] || false;
    sliderFullImage = attributes[id]["sliderFullImage"];

    var textAlign = attributes[id]["textAlign"];

    if (cloneItemElem.type === "video") {
      if (!textAlign) {
        textAlign = "center";
      }
    }

    if (textAlign) {
      if (textAlign === "center") {
        newAttributes["marginLeft"] = "auto";
        newAttributes["marginRight"] = "auto";
      }
      if (textAlign === "right") {
        newAttributes["marginLeft"] = "auto";
        delete newAttributes["marginRight"];
      }
      if (textAlign === "left") {
        delete newAttributes["marginRight"];
        delete newAttributes["marginLeft"];
      }
      newAttributes["textAlign"] = textAlign;
    }

    if (attributes[id]["borderSpacing"]) {
      newAttributes["borderSpacing"] = attributes[id]["borderSpacing"] + "px";
    }

    newAttributes["animation"] = attributes[id]["animation"] || "";

    let borderWidth = attributes[id]["borderWidth"] || "0";
    let borderColor = attributes[id]["borderColor"] || "#000000";

    if (cloneItemElem.type === "input" && !attributes[id]["borderWidth"]) {
      borderWidth = "1";
    }
    if (cloneItemElem.type === "input" && !attributes[id]["borderColor"]) {
      borderColor = "#e8e8e8";
    }

    newAttributes["lineStyle"] = attributes[id]["lineStyle"] || "solid";
    if (cloneItemElem.type === "section") {
      newAttributes[
        "outline"
      ] = `${borderWidth}px ${borderColor} ${newAttributes["lineStyle"]}`;
      if (borderWidth === 0 || borderWidth === "0") {
        newAttributes["outline"] = "0";
      }
    } else {
      newAttributes[
        "border"
      ] = `${borderWidth}px ${borderColor} ${newAttributes["lineStyle"]}`;
      if (borderWidth === 0 || borderWidth === "0") {
        newAttributes["border"] = "0";
      }
    }

    var defaultLineHeight = "0";

    if (
      !cloneItemElem.useDefaultStyle ||
      cloneItemElem.useDefaultStyle === false
    ) {
      if (attributes[id]["customFontSize"]) {
        if (
          cloneItemElem.type !== "section" &&
          cloneItemElem.type !== "column" &&
          cloneItemElem.type !== "row"
        ) {
          newAttributes["fontSize"] = attributes[id]["customFontSize"] + "px";
          defaultLineHeight = newAttributes["fontSize"];
        }
      }
      if (attributes[id]["customFontColor"]) {
        newAttributes["color"] = attributes[id]["customFontColor"];
      }
      if (attributes[id]["customFontFamily"]) {
        newAttributes["fontFamily"] = attributes[id]["customFontFamily"];
      }

      newAttributes["letterSpacing"] = attributes[id]["letterSpacing"]
        ? attributes[id]["letterSpacing"] + "px"
        : "0px";

      newAttributes["fontWeight"] = attributes[id]["fontWeight"]
        ? attributes[id]["fontWeight"]
        : "400";
    } else {
      if (
        cloneItemElem.type !== "section" &&
        cloneItemElem.type !== "column" &&
        cloneItemElem.type !== "row"
      ) {
        newAttributes["fontSize"] = getDefaultAttributes(
          cloneItemElem.type,
          editorType
        ).fontSize;
        defaultLineHeight = newAttributes["fontSize"];
      }

      newAttributes["fontFamily"] = getDefaultAttributes(
        cloneItemElem.type,
        editorType
      ).fontFamily;
      newAttributes["color"] = getDefaultAttributes(
        cloneItemElem.type,
        editorType
      ).color;
    }

    if (cloneItemElem.type === "title") {
      defaultLineHeight = "40";
    }
    if (cloneItemElem.type === "subTitle") {
      defaultLineHeight = "30";
    }
    if (
      cloneItemElem.type === "text" ||
      cloneItemElem.type === "checkbox" ||
      cloneItemElem.type === "iconText" ||
      cloneItemElem.type === "imageText"
    ) {
      defaultLineHeight = "22";
    }
    if (attributes[id]["lineHeight"]) {
      newAttributes["lineHeight"] =
        attributes[id]["lineHeight"] || defaultLineHeight;
      newAttributes["lineHeight"] = newAttributes["lineHeight"] + "px";
    }

    if (attributes[id]["colorHover"]) {
      hoverAttributes["color"] = attributes[id]["colorHover"];
    }
    if (attributes[id]["backgroundColorHover"]) {
      hoverAttributes["backgroundColor"] =
        attributes[id]["backgroundColorHover"];
    }
    if (attributes[id]["menus"]) {
      menuList = attributes[id]["menus"];
    }
    if (attributes[id]["textColor"]) {
      newAttributes["textColor"] = attributes[id]["textColor"];
    }

    if (cloneItemElem.type === "navBar") {
      newAttributes["fontSize"] = attributes[id]["fontSize"] || "14";
      newAttributes["logoHeight"] = attributes[id]["logoHeight"] || "35";
      newAttributes["fontFamily"] = attributes[id]["fontFamily"] || "Ubuntu";
    }

    newAttributes["reverseColumns"] = attributes[id]["reverseColumns"];

    if (
      cloneItemElem.type === "productName" ||
      cloneItemElem.type === "productDescription" ||
      cloneItemElem.type === "productPrice" ||
      cloneItemElem.type === "iconText"
    ) {
      newAttributes["fontSize"] = attributes[id]["fontSize"] || "14";
      newAttributes["fontSize"] += "px";
      newAttributes["lineHeight"] = attributes[id]["lineHeight"] || "14";
      newAttributes["lineHeight"] += "px";

      newAttributes["fontFamily"] = attributes[id]["fontFamily"] || "Ubuntu";
      newAttributes["color"] = attributes[id]["color"] || "black";
    }

    iconAttributes["buttonIconEnable"] = attributes[id]["buttonIconEnable"];
    iconAttributes["buttonIconColor"] =
      attributes[id]["buttonIconColor"] || "#ffffff";
    iconAttributes["buttonIconSize"] = attributes[id]["buttonIconSize"] || "20";
    iconAttributes["buttonIconName"] =
      attributes[id]["buttonIconName"] || "rightTriangle";
    iconAttributes["buttonIconAlignment"] =
      attributes[id]["buttonIconAlignment"] || "left";
    iconAttributes["buttonIconPaddingRight"] =
      attributes[id]["buttonIconPaddingRight"] || "5";
    iconAttributes["buttonIconPaddingLeft"] =
      attributes[id]["buttonIconPaddingLeft"] || "5";

    inputAttributes["name"] = attributes[id]["inputName"];
    inputAttributes["width"] = attributes[id]["inputWidth"] || "100";
    inputAttributes["multipleLines"] = attributes[id]["inputMultipleLines"];
    inputAttributes["inputHeight"] = attributes[id]["inputHeight"] || "100";

    let defaultPlaceholder = "Enter your name";
    if (cloneItemElem.type === "selectbox") {
      defaultPlaceholder = "Select an Option";
      if (attributes[id]["optionTags"]) {
        optionTags = attributes[id]["optionTags"];
      }
    }
    inputAttributes["placeholder"] =
      attributes[id]["inputPlaceholder"] || defaultPlaceholder;
    inputAttributes["value"] = attributes[id]["inputValue"] || "";
    inputAttributes["errorMessage"] = attributes[id]["inputErrorMessage"] || "";
    inputAttributes["required"] =
      attributes[id]["inputRequired"] !== undefined
        ? attributes[id]["inputRequired"]
        : true;
    inputAttributes["disabled"] =
      attributes[id]["inputDisabled"] !== undefined
        ? attributes[id]["inputDisabled"]
        : false;
    inputAttributes["limit"] =
      attributes[id]["inputLimit"] !== undefined
        ? attributes[id]["inputLimit"]
        : false;
    inputAttributes["max"] = attributes[id]["inputLimitMax"] || "";
    inputAttributes["min"] = attributes[id]["inputLimitMin"] || "";
    inputAttributes["type"] = attributes[id]["inputType"] || "name";
    inputAttributes["formName"] = attributes[id]["inputFormName"] || "form0";
    inputAttributes["options"] = attributes[id]["inputOptions"] || "";
    inputAttributes["checkboxPosition"] =
      attributes[id]["checkboxPosition"] || "center";
    inputAttributes["selectPlaceholder"] =
      attributes[id]["inputSelectPlaceholder"];
    inputAttributes["color"] = attributes[id]["inputColor"] || "#000000";
    inputAttributes["fontSize"] = attributes[id]["inputFontSize"] || "12";
    inputAttributes["backgroundColor"] =
      attributes[id]["inputBackgroundColor"] || "";
    inputAttributes["saveResponse"] = attributes[id]["inputSaveResponse"];
    inputAttributes["saveResponseType"] =
      attributes[id]["inputSaveResponseType"] || "value";
    inputAttributes["saveResponseTags"] =
      attributes[id]["inputSaveResponseTags"] || [];
    inputAttributes["saveResponseTagAttribute"] =
      attributes[id]["inputSaveResponseTagAttribute"] || "";

    if (inputAttributes["selectPlaceholder"] === undefined) {
      inputAttributes["selectPlaceholder"] = true;
    }

    if (cloneItemElem.type === "button") {
      if (attributes[id]["buttonFormName"]) {
        buttonFormName = attributes[id]["buttonFormName"];
      }
      if (attributes[id]["buttonActionType"]) {
        buttonActionType = attributes[id]["buttonActionType"];
      }
      if (attributes[id]["buttonPopupId"]) {
        buttonPopupId = attributes[id]["buttonPopupId"];
      }
      if (attributes[id]["buttonCheckoutId"]) {
        buttonCheckoutId = attributes[id]["buttonCheckoutId"];
      }
      if (attributes[id]["buttonTags"]) {
        buttonTags = attributes[id]["buttonTags"];
      }
      if (attributes[id]["buttonThankYouUrl"]) {
        buttonThankYouUrl = attributes[id]["buttonThankYouUrl"];
      }
      buttonNewWindow = attributes[id]["buttonNewWindow"];
      buttonMarkCompleted = attributes[id]["buttonMarkCompleted"];
      buttonFormStaffUser = attributes[id]["buttonFormStaffUser"];
      buttonSecondLine = attributes[id]["buttonSecondLine"];
      buttonSecondLineFontSize =
        attributes[id]["buttonSecondLineFontSize"] || "14";
      buttonSecondLineLineHeight =
        attributes[id]["buttonSecondLineLineHeight"] || "1";
      buttonSaveSubmission = attributes[id]["buttonSaveSubmission"];
      buttonCreateCustomer = attributes[id]["buttonCreateCustomer"];
      buttonSaveLead = attributes[id]["buttonSaveLead"];
      buttonLeadStaffUser = attributes[id]["buttonLeadStaffUser"];
    }

    if (cloneItemElem.type === "socialMediaLinks") {
      socialFacebookLink = attributes[id]["socialFacebookLink"] || "";
      socialInstagramLink = attributes[id]["socialInstagramLink"] || "";
      socialLinkedinLink = attributes[id]["socialLinkedinLink"] || "";
      socialPintrestLink = attributes[id]["socialPintrestLink"] || "";
      socialTwitterLink = attributes[id]["socialTwitterLink"] || "";
      socialYoutubeLink = attributes[id]["socialYoutubeLink"] || "";
    }

    if (attributes[id]["linkColor"]) {
      linkColor = attributes[id]["linkColor"];
    }

    if (attributes[id]["linkColorHover"]) {
      linkColorHover = attributes[id]["linkColorHover"];
    }

    if (cloneItemElem.type === "line") {
      newAttributes["width"] = attributes[id]["width"] + "%";
      newAttributes["height"] = attributes[id]["height"]
        ? attributes[id]["height"]
        : "1px";
    }

    iconElemAttributes["color"] = attributes[id]["iconElemColor"] || "#000000";
    iconElemAttributes["size"] = attributes[id]["iconElemSize"] || "20";
    iconElemAttributes["gap"] = attributes[id]["iconGap"] || "5";
    iconElemAttributes["position"] = attributes[id]["iconPosition"] || "center";
    iconElemAttributes["name"] =
      attributes[id]["iconElemName"] || "rightTriangle";

    if (cloneItemElem.type === "imageText") {
      newAttributes["position"] = attributes[id]["textPosition"] || "middle";
      newAttributes["gap"] = attributes[id]["imageGap"] || "10";
    }

    if (cloneItemElem.type === "countdown") {
      countdownAttributes["textColor"] =
        attributes[id]["cdTextColor"] || "#ffffff";
      countdownAttributes["timeFontSize"] =
        attributes[id]["cdTimeFontSize"] || "30";
      countdownAttributes["textFontSize"] =
        attributes[id]["cdFontSize"] || "16";
      countdownAttributes["outerBackgroundColor"] =
        attributes[id]["cdOuterBackgroundColor"] || "#333333";
      countdownAttributes["innerBackgroundColor"] =
        attributes[id]["cdInnerBackgroundColor"] || "#555555";
      countdownAttributes["fontFamily"] =
        attributes[id]["cdFontFamily"] || "Ubuntu";

      countdownAttributes["dateType"] =
        attributes[id]["cdDateTypeOption"] || "date";
      countdownAttributes["year"] =
        attributes[id]["cdDateYear"] || new Date().getFullYear();
      countdownAttributes["month"] = attributes[id]["cdDateMonth"] || "1";
      countdownAttributes["day"] = attributes[id]["cdDateDay"] || "01";
      countdownAttributes["hour"] = attributes[id]["cdDateHour"] || "00";
      countdownAttributes["minute"] = attributes[id]["cdDateMinute"] || "01";

      countdownAttributes["showDays"] = attributes[id]["cdShowDays"];
      countdownAttributes["showHours"] = attributes[id]["cdShowHours"];
      countdownAttributes["showMinutes"] = attributes[id]["cdShowMinutes"];
      countdownAttributes["showSeconds"] = attributes[id]["cdShowSeconds"];
    }

    if (attributes[id]["sliderImages"]) {
      sliderImages = attributes[id]["sliderImages"];
    }

    if (attributes[id]["productId"]) {
      productId = attributes[id]["productId"];
    }

    if (cloneItemElem.type === "checkoutForm") {
      checkoutId = attributes[id]["checkoutId"];
    }

    lock = attributes[id]["lock"];
    if (lock === undefined) {
      lock = false;
    }
  }

  cloneItemElem.attributes.iconAttributes = iconAttributes;
  cloneItemElem.attributes.iconElemAttributes = iconElemAttributes;
  cloneItemElem.attributes.hoverAttributes = hoverAttributes;
  cloneItemElem.attributes.inputAttributes = inputAttributes;
  cloneItemElem.attributes.countdownAttributes = countdownAttributes;
  cloneItemElem.attributes.attributes = newAttributes;
  cloneItemElem.attributes.buttonFormName = buttonFormName;
  cloneItemElem.attributes.buttonFormStaffUser = buttonFormStaffUser;
  cloneItemElem.attributes.buttonThankYouUrl = buttonThankYouUrl;
  cloneItemElem.attributes.buttonActionType = buttonActionType;
  cloneItemElem.attributes.buttonPopupId = buttonPopupId;
  cloneItemElem.attributes.buttonCheckoutId = buttonCheckoutId;
  cloneItemElem.attributes.buttonTags = buttonTags;
  cloneItemElem.attributes.optionTags = optionTags;
  cloneItemElem.attributes.sliderImages = sliderImages;
  cloneItemElem.attributes.sliderFullImage = sliderFullImage;
  cloneItemElem.attributes.buttonNewWindow = buttonNewWindow;
  cloneItemElem.attributes.buttonMarkCompleted = buttonMarkCompleted;
  cloneItemElem.attributes.imageLinkNewWindow = imageLinkNewWindow;
  cloneItemElem.attributes.buttonSecondLine = buttonSecondLine;
  cloneItemElem.attributes.buttonSecondLineFontSize = buttonSecondLineFontSize;
  cloneItemElem.attributes.buttonSecondLineLineHeight =
    buttonSecondLineLineHeight;
  cloneItemElem.attributes.buttonSaveSubmission = buttonSaveSubmission;
  cloneItemElem.attributes.buttonCreateCustomer = buttonCreateCustomer;
  cloneItemElem.attributes.buttonSaveLead = buttonSaveLead;
  cloneItemElem.attributes.buttonLeadStaffUser = buttonLeadStaffUser;
  cloneItemElem.attributes.linkColor = linkColor;
  cloneItemElem.attributes.linkColorHover = linkColorHover;
  cloneItemElem.attributes.menuList = menuList;
  cloneItemElem.attributes.lock = lock;
  cloneItemElem.attributes.key = Math.random();
  cloneItemElem.attributes.productId = productId;
  cloneItemElem.attributes.checkoutId = checkoutId;
  cloneItemElem.attributes.socialFacebookLink = socialFacebookLink;
  cloneItemElem.attributes.socialInstagramLink = socialInstagramLink;
  cloneItemElem.attributes.socialLinkedinLink = socialLinkedinLink;
  cloneItemElem.attributes.socialPintrestLink = socialPintrestLink;
  cloneItemElem.attributes.socialTwitterLink = socialTwitterLink;
  cloneItemElem.attributes.socialYoutubeLink = socialYoutubeLink;
  return cloneItemElem;
};

export const changeItemAttributes = (
  json,
  attributes,
  editorType,
  linkStyles = ""
) => {
  let itemList = json.contentEditor.itemList;
  itemList.forEach((element) => {
    element.attributes = changeItemSpacing(
      element,
      attributes,
      editorType
    ).attributes;
    element.itemList.forEach((rowElem) => {
      rowElem.attributes = changeItemSpacing(
        rowElem,
        attributes,
        editorType
      ).attributes;
      rowElem.itemList.forEach((colElem) => {
        colElem.attributes = changeItemSpacing(
          colElem,
          attributes,
          editorType
        ).attributes;
        colElem.itemList.forEach((itemElem) => {
          let newItem = changeItemSpacing(itemElem, attributes, editorType);
          itemElem.attributes = newItem.attributes;
          itemElem.attributes.hoverAttributes =
            newItem.attributes.hoverAttributes;
          itemElem.attributes.iconAttributes =
            newItem.attributes.iconAttributes;
          itemElem.attributes.iconElemAttributes =
            newItem.attributes.iconElemAttributes;
        });
      });
    });
  });
  let jsonBackup = Object.assign({}, json);
  jsonBackup.contentEditor.attributes = attributes;
  jsonBackup.contentEditor.itemList = itemList;
  jsonBackup.contentEditor.linkStyles = linkStyles;
  return jsonBackup;
};

export const resetContent = (json) => {
  let itemList = json.contentEditor.itemList;
  itemList.forEach((element) => {
    element.selected = false;
    element.itemList.forEach((rowElem) => {
      rowElem.selected = false;
      rowElem.itemList.forEach((colElem) => {
        colElem.selected = false;
        colElem.itemList.forEach((itemElem) => {
          itemElem.selected = false;
        });
      });
    });
  });
  let jsonBackup = Object.assign({}, json);
  jsonBackup.contentEditor.itemList = itemList;
  return jsonBackup;
};

export const changePageAttributes = (json, pageAttr) => {
  let contentEditor = json.contentEditor;
  let bodyAttributes = contentEditor.bodyAttributes;

  if (pageAttr.page) {
    contentEditor.width = pageAttr["page"]["width"] || "1600";

    if (pageAttr.page.backgroundImage) {
      bodyAttributes[
        "backgroundImage"
      ] = `url(${pageAttr.page.backgroundImage})`;

      bodyAttributes["backgroundRepeat"] = pageAttr.page["backgroundRepeat"]
        ? "repeat"
        : "no-repeat";
      if (bodyAttributes["backgroundRepeat"] === "no-repeat") {
        bodyAttributes["backgroundAttachment"] =
          pageAttr.page["backgroundAttachment"] || "scroll";

        bodyAttributes["backgroundPosition"] =
          pageAttr.page["backgroundPosition"] || "top";
        bodyAttributes["backgroundSize"] = pageAttr.page["backgroundSize"]
          ? "contain"
          : "cover";
      } else {
        bodyAttributes["backgroundAttachment"] = "unset";
        bodyAttributes["backgroundSize"] = "unset";
      }
      // bodyAttributes["opacity"] = pageAttr.page["backgroundOpacity"] || 1.0;
    } else {
      bodyAttributes["backgroundImage"] = "";
    }

    var textAlign = pageAttr["page"]["textAlign"];

    if (textAlign) {
      if (textAlign === "center") {
        bodyAttributes["marginLeft"] = "auto";
        bodyAttributes["marginRight"] = "auto";
      }
      if (textAlign === "right") {
        bodyAttributes["marginLeft"] = "auto";
        delete bodyAttributes["marginRight"];
      }
      if (textAlign === "left") {
        delete bodyAttributes["marginRight"];
        delete bodyAttributes["marginLeft"];
      }
      bodyAttributes["textAlign"] = textAlign;
    }

    bodyAttributes.backgroundColor = pageAttr.page.backgroundColor;
  }

  if (pageAttr.title) {
    contentEditor.defaultTitleAttributes = pageAttr.title;
  }

  if (pageAttr.subTitle) {
    contentEditor.defaultSubTitleAttributes = pageAttr.subTitle;
  }

  if (pageAttr.text) {
    contentEditor.defaultTextAttributes = pageAttr.text;
  }

  if (pageAttr.button) {
    contentEditor.defaultButtonAttributes = pageAttr.button;
  }

  let jsonBackup = Object.assign({}, json);
  contentEditor.pageAttributes = pageAttr;
  contentEditor.bodyAttributes = bodyAttributes;
  jsonBackup.contentEditor = contentEditor;
  return jsonBackup;
};

export const selectParent = (json, sectionId, rowId, colId, item, type) => {
  let itemList = json.contentEditor.itemList;
  let selectedType = "";
  itemList.forEach((secElem) => {
    if (secElem.attributes.id === sectionId) {
      if (type === "item") {
        secElem.itemList.forEach((rowElem) => {
          if (rowElem.attributes.id === rowId) {
            rowElem.itemList.forEach((colElem) => {
              if (colElem.attributes.id === colId) {
                colElem.selected = true;
                colElem.itemList.forEach((itemElem) => {
                  itemElem.selected = false;
                });
              } else {
                colElem.selected = false;
              }
            });
          } else {
            rowElem.itemList.forEach((colElem) => {
              colElem.selected = false;
              colElem.itemList.forEach((itemElem) => {
                itemElem.selected = false;
              });
            });
          }
        });
        selectedType = "column";
      }

      if (type === "column") {
        secElem.itemList.forEach((rowElem) => {
          rowElem.selected = rowElem.attributes.id === rowId;

          rowElem.itemList.forEach((colElem) => {
            colElem.selected = false;
            colElem.itemList.forEach((itemElem) => {
              itemElem.selected = false;
            });
          });
        });
        selectedType = "row";
      }

      if (type === "row") {
        secElem.selected = true;
        secElem.itemList.forEach((rowElem) => {
          rowElem.selected = false;
          rowElem.itemList.forEach((colElem) => {
            colElem.selected = false;
            colElem.itemList.forEach((itemElem) => {
              itemElem.selected = false;
            });
          });
        });
        selectedType = "section";
      }
    } else {
      secElem.selected = false;
    }
  });
  let jsonBackup = Object.assign({}, json);
  jsonBackup.contentEditor.itemList = itemList;
  return { jsonBackup, selectedType };
};

export const transferItem = (json, to, from, itemData) => {
  let itemList = json.contentEditor.itemList;
  itemList.forEach((element) => {
    if (element.attributes.id === from.sectionId) {
      element.itemList.forEach((rowElem) => {
        if (rowElem.attributes.id === from.rowId) {
          rowElem.itemList.forEach((colElem) => {
            if (colElem.attributes.id === from.colId) {
              colElem.itemList = colElem.itemList.filter(
                (d) => d.attributes.id !== itemData.attributes.id
              );
            }
          });
        }
      });
    }
    if (element.attributes.id === to.sectionId) {
      element.itemList.forEach((rowElem) => {
        if (rowElem.attributes.id === to.rowId) {
          rowElem.itemList.forEach((colElem) => {
            if (colElem.attributes.id === to.colId) {
              colElem.itemList.push(itemData);
            }
          });
        }
      });
    }
  });
  let jsonBackup = Object.assign({}, json);
  jsonBackup.contentEditor.itemList = itemList;
  return { jsonBackup };
};

export const copyItem = (
  json,
  sectionId,
  rowId,
  colId,
  item,
  type,
  attributes,
  defaultStyle,
  textValues,
  draftJsonValues,
  sectionWidth,
  codeHtml,
  urlAttr,
  customWidth,
  itemStyles
) => {
  let itemList = json.contentEditor.itemList;
  let newId = getUniqueId();
  let newSubId = getUniqueId();
  let newItem = {};
  let newAttributes = JSON.parse(JSON.stringify(attributes));
  let newDefaultStyle = JSON.parse(JSON.stringify(defaultStyle));
  let newTextValues = JSON.parse(JSON.stringify(textValues));
  let newDraftJsonValues = JSON.parse(JSON.stringify(draftJsonValues));
  let newSectionWidth = JSON.parse(JSON.stringify(sectionWidth));
  let newCodeHtml = JSON.parse(JSON.stringify(codeHtml));
  let newUrlAttr = JSON.parse(JSON.stringify(urlAttr));
  let newCustomWidth = JSON.parse(JSON.stringify(customWidth));
  let newItemStyles = JSON.parse(JSON.stringify(itemStyles));

  itemList.forEach((secElem) => {
    if (secElem.attributes.id === sectionId) {
      if (type === "item") {
        secElem.itemList.forEach((rowElem) => {
          if (rowElem.attributes.id === rowId) {
            rowElem.itemList.forEach((colElem) => {
              if (colElem.attributes.id === colId) {
                colElem.itemList.forEach((itemElem) => {
                  if (itemElem.attributes.id === item.attributes.id) {
                    newAttributes[newId] = attributes[item.attributes.id];
                    newDefaultStyle[newId] = defaultStyle[item.attributes.id];
                    newTextValues[newId] = textValues[item.attributes.id];
                    newDraftJsonValues[newId] =
                      draftJsonValues[item.attributes.id];
                    newCodeHtml[newId] = codeHtml[item.attributes.id];
                    newUrlAttr[newId] = urlAttr[item.attributes.id];
                    newItemStyles[newId] = itemStyles[item.attributes.id];

                    newItem = JSON.parse(JSON.stringify(itemElem));
                    newItem.attributes.id = newId;
                    if (item.attributes.subId) {
                      newItem.attributes.subId = newSubId;
                      newTextValues[newSubId] =
                        textValues[item.attributes.subId];
                      newDraftJsonValues[newSubId] =
                        draftJsonValues[item.attributes.subId];
                    }
                    let itemIndex = colElem.itemList.findIndex(
                      (d) => d.attributes.id === item.attributes.id
                    );
                    colElem.itemList.splice(itemIndex + 1, 0, newItem);
                  }
                });
              }
            });
          }
        });
      }

      if (type === "section") {
        let newSection = JSON.parse(JSON.stringify(secElem));
        newSection.attributes.id = newId;
        if (secElem.attributes) {
          newAttributes[newId] = attributes[secElem.attributes.id];
          newDefaultStyle[newId] = defaultStyle[secElem.attributes.id];
          newSectionWidth[newId] = sectionWidth[secElem.attributes.id];
          newItemStyles[newId] = itemStyles[secElem.attributes.id];
        }

        newSection.itemList.forEach((rowElem) => {
          let rowId = getUniqueId();
          newAttributes[rowId] = attributes[rowElem.attributes.id];
          newDefaultStyle[rowId] = defaultStyle[rowElem.attributes.id];
          rowElem.attributes.id = rowId;
          rowElem.itemList.forEach((colElem) => {
            let colId = getUniqueId();
            newAttributes[colId] = attributes[colElem.attributes.id];
            newDefaultStyle[colId] = defaultStyle[colElem.attributes.id];
            newCustomWidth[colId] = customWidth[colElem.attributes.id];
            colElem.attributes.id = colId;
            colElem.itemList.forEach((itemElem) => {
              let itemId = getUniqueId();
              let newSubId = getUniqueId();
              newAttributes[itemId] = attributes[itemElem.attributes.id];
              newDefaultStyle[itemId] = defaultStyle[itemElem.attributes.id];
              newTextValues[itemId] = textValues[itemElem.attributes.id];
              newDraftJsonValues[itemId] =
                draftJsonValues[itemElem.attributes.id];
              newItemStyles[itemId] = itemStyles[itemElem.attributes.id];

              if (itemElem.attributes.subId) {
                itemElem.attributes.subId = newSubId;
                newTextValues[newSubId] = textValues[itemElem.attributes.subId];
                newDraftJsonValues[newSubId] =
                  draftJsonValues[itemElem.attributes.subId];
              }
              itemElem.attributes.id = itemId;
            });
          });
        });
        itemList.push(newSection);
      }

      if (type === "row") {
        secElem.itemList.forEach((rowElem) => {
          if (rowElem.attributes.id === rowId) {
            let newRow = JSON.parse(JSON.stringify(rowElem));
            newRow.attributes.id = newId;
            newAttributes[newId] = attributes[rowElem.attributes.id];
            newDefaultStyle[newId] = defaultStyle[rowElem.attributes.id];
            newItemStyles[newId] = itemStyles[rowElem.attributes.id];

            newRow.itemList.forEach((colElem) => {
              let colId = getUniqueId();
              newAttributes[colId] = attributes[colElem.attributes.id];
              newDefaultStyle[colId] = defaultStyle[colElem.attributes.id];
              newCustomWidth[colId] = customWidth[colElem.attributes.id];
              colElem.attributes.id = colId;
              colElem.itemList.forEach((itemElem) => {
                let itemId = getUniqueId();
                let newSubId = getUniqueId();
                newAttributes[itemId] = attributes[itemElem.attributes.id];
                newDefaultStyle[itemId] = defaultStyle[itemElem.attributes.id];
                newTextValues[itemId] = textValues[itemElem.attributes.id];
                newDraftJsonValues[itemId] =
                  draftJsonValues[itemElem.attributes.id];
                newItemStyles[itemId] = itemStyles[itemElem.attributes.id];

                if (itemElem.attributes.subId) {
                  itemElem.attributes.subId = newSubId;
                  newTextValues[newSubId] =
                    textValues[itemElem.attributes.subId];
                  newDraftJsonValues[newSubId] =
                    draftJsonValues[itemElem.attributes.subId];
                }
                itemElem.attributes.id = itemId;
              });
            });

            secElem.itemList.push(newRow);
          }
        });
      }
    }
  });
  let jsonBackup = Object.assign({}, json);
  jsonBackup.contentEditor.itemList = itemList;
  return {
    jsonBackup,
    newId,
    newItem,
    newAttributes,
    newDefaultStyle,
    newTextValues,
    newDraftJsonValues,
    newSectionWidth,
    newCodeHtml,
    newUrlAttr,
    newCustomWidth,
    newItemStyles,
  };
};

export const changeCodeHtml = (
  json,
  sectionId,
  rowId,
  colId,
  itemId,
  codeHtml
) => {
  let itemList = json.contentEditor.itemList;
  itemList.forEach((element) => {
    if (element.attributes.id === sectionId) {
      element.itemList.forEach((rowElem) => {
        if (rowElem.attributes.id === rowId) {
          rowElem.itemList.forEach((colElem) => {
            if (colElem.attributes.id === colId) {
              colElem.itemList.forEach((itemElem) => {
                if (itemElem.attributes.id === itemId) {
                  let newItem = JSON.parse(JSON.stringify(itemElem));
                  newItem.attributes.attributes.content =
                    codeHtml[newItem.attributes.id];
                  itemElem.attributes = newItem.attributes;
                }
              });
            }
          });
        }
      });
    }
  });
  let jsonBackup = Object.assign({}, json);
  jsonBackup.contentEditor.codeHtml = codeHtml;
  jsonBackup.contentEditor.itemList = itemList;
  return jsonBackup;
};

export const changeCustomStyles = (json, styles) => {
  let editor = json.contentEditor;
  editor.customStyles = styles;

  let jsonBackup = Object.assign({}, json);
  jsonBackup.contentEditor = editor;
  return jsonBackup;
};

export const changeItemCustomStyles = (json, styles) => {
  let editor = json.contentEditor;
  editor.itemStyles = styles;

  let jsonBackup = Object.assign({}, json);
  jsonBackup.contentEditor = editor;
  return jsonBackup;
};

export const getFormNames = (json) => {
  let itemList = json.contentEditor.itemList;
  let formNames = [];
  itemList.forEach((element) => {
    element.itemList.forEach((rowElem) => {
      rowElem.itemList.forEach((colElem) => {
        colElem.itemList.forEach((itemElem) => {
          if (
            itemElem.type === "input" ||
            itemElem.type === "fileInput" ||
            itemElem.type === "checkoutForm" ||
            itemElem.type === "selectbox"
          ) {
            let inputAttributes = itemElem.attributes.inputAttributes || {
              formName: "form0",
            };
            if (inputAttributes) {
              let formName = inputAttributes.formName;
              if (formNames.indexOf(formName) === -1) {
                formNames.push(formName);
              }
            }
          }
        });
      });
    });
  });
  return formNames;
};

export const getColItems = (json, sectionId, rowId, colId) => {
  let itemList = json.contentEditor.itemList;
  let colItemList = [];
  itemList.forEach((element) => {
    if (element.attributes.id === sectionId) {
      element.itemList.forEach((rowElem) => {
        if (rowElem.attributes.id === rowId) {
          rowElem.itemList.forEach((colElem) => {
            if (colElem.attributes.id === colId) {
              colItemList = colElem.itemList;
            }
          });
        }
      });
    }
  });
  return colItemList;
};

export const getEditorFonts = (json) => {
  let attributes = json.contentEditor.attributes || {};
  let fonts = [];
  for (const key in attributes) {
    if (Object.hasOwnProperty.call(attributes, key)) {
      const element = attributes[key];
      for (const key2 in element) {
        if (Object.hasOwnProperty.call(element, key2)) {
          const element2 = element[key2];
          if (
            key2 === "customFontFamily" ||
            key2 === "font" ||
            key2 === "fontFamily"
          ) {
            fonts.push(element2);
          }
        }
      }
    }
  }
  return fonts;
};
