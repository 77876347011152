import React from "react";

class ErrorBoundary extends React.Component {
  state = { hasError: false };

  static getDerivedStateFromError(error) {
    if (error.message.includes("ChunkLoadError")) {
      return { hasError: true };
    }
    return null;
  }

  componentDidCatch(error, info) {
    if (error.message.includes("ChunkLoadError")) {
      this.setState({ hasError: true });
    }
  }

  handleReload = () => {
    // window.location.reload();
  };

  handleAlert = () => {
    const userConfirmed = window.confirm(
      "An update is available. Do you want to reload the page to get the latest version?"
    );
    if (userConfirmed) {
      this.handleReload();
    }
  };

  render() {
    if (this.state.hasError) {
      this.handleAlert();
      return <h1>Loading error, please reload the page.</h1>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
