import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import TitleBox from "../../../components/general/titleBox";
import RadioTag from "../../../components/form/radioTag";

import { showNotification, slugify } from "../../../helpers";
import { uploadImage } from "../../../services";
import {
  callGetSettings,
  callSaveSettings,
} from "../../../services/settingService";
import { SortableTaskReportFieldList } from "../../../components/form/sortableList";
import { arrayMove } from "react-sortable-hoc";

class TaskReports extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      pages: [],
      selectedPageIndex: -1,
      userData: this.props.userData || {},
      settingId: "",
      pageName: "",
      svgFile: "",
      svgFileName: "",
      field: "none",
      keyField: Math.random(),
    };
  }

  componentDidMount() {
    this.getSettings();
  }

  async getSettings() {
    let d = await callGetSettings("taskreportssettings");
    let settings = d.data;
    let taskReportsSettings = settings.task_reports_settings || {};

    this.setState({
      pages: taskReportsSettings.pages || [],
      settingId: settings._id,
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  componentWillReceiveProps(newProps) {
    if (newProps.userData) {
      this.setState({
        userData: newProps.userData,
      });
    }
  }

  async onPressSave() {
    if (this.state.pages.length === 0) {
      showNotification("error", "Atleast one page is required", 4000);
    }

    /*eslint-disable*/
    let data = {
      id: this.state.settingId,
      type: "task_reports_settings",
      task_reports_settings: {
        pages: this.state.pages,
      },
    };
    /*eslint-enable*/
    let updatedData = await callSaveSettings(data);
    if (updatedData.status) {
      showNotification("success", "Task pages updated!", 3000);
    } else {
      showNotification("error", updatedData.message, 5000);
    }
  }

  onSelectRadioType(item, id) {
    this.setState({ [id]: item[Object.keys(item)[0]].value });
  }

  async onClickAddPage() {
    /*eslint-disable*/

    if (!this.state.pageName) {
      showNotification("error", "Page Name is required", 4000);
      return;
    }

    let svgFileName = this.state.svgFileName;

    if (this.state.svgFile) {
      let fileName = slugify(this.state.pageName);
      let resp = await uploadImage(this.state.svgFile, fileName);
      let data = await resp.json();
      svgFileName = data.file_name;
    }

    let pages = this.state.pages;

    let page = {
      name: this.state.pageName,
      svg_file: svgFileName,
      field: this.state.field,
    };
    /*eslint-enable*/

    if (this.state.selectedPageIndex > -1) {
      pages[this.state.selectedPageIndex] = page;
    } else {
      pages.push(page);
    }

    this.setState({
      pages: pages,
      selectedPageIndex: -1,
      pageName: "",
      field: "none",
      svgFile: "",
      svgFileName: "",
      keyField: Math.random(),
    });
  }

  onClickCancelPage(index) {
    this.setState({
      pageName: "",
      selectedPageIndex: -1,
      field: "none",
      svgFile: "",
      keyField: Math.random(),
    });
  }

  onFileChange(e) {
    e.preventDefault();
    let file = e.target.files[0];
    this.setState({ svgFile: file });
  }

  onSortEndPages = ({ oldIndex, newIndex }) => {
    let pages = this.state.pages;
    pages = arrayMove(pages, oldIndex, newIndex);

    this.setState({
      pages: pages,
    });
  };

  onPressRemovePage(e) {
    let index = e.target.dataset["value"];
    let pages = this.state.pages;
    pages.splice(index, 1);
    this.setState({
      pages: pages,
    });
  }

  onPressEditPage(e) {
    let index = e.target.dataset["value"];
    let pages = this.state.pages;

    let page = pages[index];

    this.setState({
      pageName: page.name,
      field: page.field,
      svgFileName: page.svg_file,
      selectedPageIndex: index,
      keyField: Math.random(),
    });
  }

  render() {
    return (
      <div>
        <div className="container-fluid">
          <TitleBox
            title={"Task Reports"}
            showBackBtn={this.state.certificateId !== 0}
          />
          <div className="gap20" />

          <div className="card">
            <div className="container-fluid">
              <div className="gap20" />

              <div className="havingHelpText">
                <h5>Add Task Pages</h5>
              </div>
              <div className="gap20" />
              <div className="card">
                <div className="container-fluid">
                  <div className="gap20" />
                  <div className="row">
                    <div className="col-sm-4">
                      <div
                        className={
                          this.state.pageName !== ""
                            ? "mdInput mdFocussed"
                            : "mdInput"
                        }>
                        <label>Page Name</label>
                        <input
                          type="text"
                          name="pageName"
                          value={this.state.pageName}
                          onChange={(event) => this.handleInputChange(event)}
                        />
                      </div>
                      <div className="gap10" />
                    </div>
                  </div>
                  <div className="gap20" />
                  <p>Select SVG</p>
                  <input
                    type="file"
                    name="FileUpload"
                    onChange={this.onFileChange.bind(this)}
                  />
                  <div className="gap20" />
                  <div className="row">
                    <div className="col-sm-6">
                      <p>Select Field</p>
                      <div className="tabsHolder radioTabs">
                        <RadioTag
                          key={this.state.keyField}
                          onCheckChange={this.onSelectRadioType.bind(this)}
                          labelList={[
                            { name: "None", value: "none" },
                            { name: "Task Images", value: "images" },
                          ]}
                          id={"field"}
                          selectedList={[this.state.field]}
                          selectType={"value"}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="text-right">
                    {this.state.selectedPageIndex > -1 && (
                      <button
                        className="btn btn-default margin-right-10"
                        onClick={this.onClickCancelPage.bind(this)}>
                        Cancel
                      </button>
                    )}
                    <button
                      className="btn btn-primary"
                      onClick={this.onClickAddPage.bind(this)}>
                      {this.state.selectedPageIndex > -1
                        ? "Update Page"
                        : "Add Page"}
                    </button>
                    <div className="gap20" />
                  </div>
                </div>
              </div>
              <div className="gap20" />
              {this.state.pages.length > 0 && (
                <div className="card">
                  <div className="container-fluid">
                    <div className="gap20" />
                    <h5 className="noMargin">Added Task Pages</h5>
                    <div className="gap20" />
                    <SortableTaskReportFieldList
                      distance={10}
                      task={true}
                      items={this.state.pages}
                      onSortEnd={this.onSortEndPages}
                      onPressEdit={this.onPressEditPage.bind(this)}
                      onPressRemove={this.onPressRemovePage.bind(this)}
                    />
                    <div className="gap20" />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="text-right container-fluid">
          <button
            className="btn btn-primary"
            onClick={() => this.onPressSave()}>
            Update
          </button>
        </div>
        <div className="gap20" />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ userData: state.authReducer.userData });

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TaskReports);
