import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import DatePicker from "react-date-picker";
import { Scrollbars } from "react-custom-scrollbars";
import * as $ from "jquery";

import TitleBox from "../../../../components/general/titleBox";
import {
  callGetCheckoutItems,
  callGetReports,
  callGetStripePlansUsingQuery,
} from "../../../../services";
import moment from "moment-timezone";
import RadioTag from "../../../../components/form/radioTag";

class CustomerRevenue extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    let fromDate = moment().subtract("month", 3).toDate();
    this.state = {
      fromDate,
      toDate: moment().toDate(),
      keyDate: Math.random(),
      customers: 0,
      revenue: 0,
      average: 0,
      isResult: false,
      checkoutItems: [],
      keyCheckoutItem: Math.random(),
      selectedCheckoutItems: [],
      filterType: "all",
      keyType: Math.random(),
      usersInfo: [],
      stripePlans: [],
      keyStripePlan: Math.random(),
      selectedStripePlans: [],
    };
  }

  componentDidMount() {
    this.getCheckoutItems();
    this.getStripePlans();
  }

  async getCheckoutItems() {
    let d = await callGetCheckoutItems();
    let checkoutItems = [];
    d.data.forEach((element) => {
      if (element.checkout_id) {
        checkoutItems.push({
          name: element.checkout_id,
          value: element.checkout_id,
        });
      }
    });
    this.setState({ checkoutItems, keyCheckoutItem: Math.random() });
  }

  async getStripePlans() {
    let d = await callGetStripePlansUsingQuery();
    let stripePlans = [];
    d.data.forEach((element) => {
      if (element.stripe_plan_id) {
        stripePlans.push({
          name: element.stripe_plan_id,
          value: element.stripe_plan_id,
        });
      }
    });
    this.setState({ stripePlans, keyStripePlan: Math.random() });
  }

  onChangeFromDate(date) {
    this.setState({
      fromDate: date,
    });
  }

  onChangeToDate(date) {
    this.setState({
      toDate: date,
    });
  }

  async onClickCalculate() {
    let fromDate = moment(this.state.fromDate).startOf("day").toDate();
    let toDate = moment(this.state.toDate).startOf("day").toDate();

    let data = {};
    data["type"] = "stripe-charge";
    data["action"] = "customer-revenue";
    data["data"] = {
      fromDate: fromDate,
      toDate: toDate,
      checkoutIds: this.state.selectedCheckoutItems,
      stripePlanIds: this.state.selectedStripePlans,
      filterType: this.state.filterType,
    };
    let d = await callGetReports(data);
    this.setState({
      revenue: d.revenue,
      isResult: true,
      customers: d.customers,
      average: d.average,
      usersInfo: d.usersInfo,
    });
  }

  onSelectCheckoutItem(item, id) {
    let ids = [];
    if (Object.keys(item).length > 0 && item.constructor === Object) {
      Object.keys(item).forEach((key) => {
        ids.push(item[key].value);
      });
    }
    this.setState({ selectedCheckoutItems: ids });
  }

  onSelectStripePlan(item, id) {
    let ids = [];
    if (Object.keys(item).length > 0 && item.constructor === Object) {
      Object.keys(item).forEach((key) => {
        ids.push(item[key].value);
      });
    }
    this.setState({ selectedStripePlans: ids });
  }

  onSelectType(item, id) {
    let value = item[Object.keys(item)[0]].value;
    let selectedCheckoutItems = this.state.selectedCheckoutItems;
    if (value === "all") {
      selectedCheckoutItems = [];
    }
    this.setState({ [id]: value, selectedCheckoutItems });
  }

  onClickCloseFromUsersModal() {
    $(".modalAlertOverlay.usersOverlay,.modalAlert.usersAlert").fadeOut(200);
  }

  onClickMemberName(element) {
    $(".modalAlertOverlay.usersOverlay,.modalAlert.usersAlert").fadeOut(200);
    this.props.history.push(`/admin/edit-user?id=${element._id}`);
  }

  onClickUsers() {
    $(".modalAlertOverlay.usersOverlay,.modalAlert.usersAlert").fadeIn(200);
  }

  renderUsersModal() {
    let modalHeight = window.innerHeight - 200;
    let allUsers = this.state.usersInfo;

    if (allUsers.length === 0) {
      return null;
    }

    var self = this;

    function renderUserRow() {
      let returnVals = [];
      for (let i = 0; i < allUsers.length; i++) {
        const user = allUsers[i];

        returnVals.push(
          <tr key={user._id}>
            <td>{user.name}</td>
            <td>{user.email_address}</td>
            <td>
              <button
                className="btn btn-default btn-sm border-0"
                onClick={self.onClickMemberName.bind(self, user)}>
                <i className="fa fa-eye" />
              </button>
            </td>
          </tr>
        );
      }
      return returnVals;
    }

    return (
      <div>
        <div className="modalAlertOverlay usersOverlay" />
        <div className="modalAlert usersAlert modal-lg big">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Users
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.onClickCloseFromUsersModal.bind(this)}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div
              className="container-fluid modalInnerBody"
              style={{ maxHeight: modalHeight, overflowY: "hidden" }}>
              <Scrollbars
                autoHide
                autoHideTimeout={1000}
                autoHideDuration={200}
                style={{ height: modalHeight }}>
                <div className="gap20" />
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>{renderUserRow()}</tbody>
                </table>
              </Scrollbars>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="container-fluid">
        {this.renderUsersModal()}
        <TitleBox title="Customer Revenue" showBackBtn={true} />
        <div className="gap20" />
        <div className="card">
          <div className="container-fluid">
            <div className="gap20" />
            <div className="row">
              <div className="col-md-2">
                <label className="inputFakeLabelFocussed">
                  Select From Date
                </label>
                <div>
                  <DatePicker
                    key={this.state.keyDate}
                    value={this.state.fromDate}
                    maxDate={new Date()}
                    onChange={this.onChangeFromDate.bind(this)}
                  />
                </div>
              </div>
              <div className="col-md-2">
                <label className="inputFakeLabelFocussed">Select To Date</label>
                <div>
                  <DatePicker
                    key={this.state.keyDate}
                    value={this.state.toDate}
                    maxDate={new Date()}
                    onChange={this.onChangeToDate.bind(this)}
                  />
                </div>
              </div>
            </div>

            <div className="gap20" />
            <div className="radioBHolder">
              <div className="havingHelpText">
                <p>Filter Type</p>
              </div>
              <div className="tabsHolder radioTabs">
                <RadioTag
                  onCheckChange={this.onSelectType.bind(this)}
                  labelList={[
                    { name: "All", value: "all" },
                    { name: "Checkout Item", value: "checkoutItem" },
                    { name: "Stripe Plans", value: "stripePlan" },
                  ]}
                  key={this.state.keyType}
                  id={"filterType"}
                  selectType={"value"}
                  selectedList={[this.state.filterType]}
                />
              </div>
              {this.state.filterType === "checkoutItem" && (
                <div>
                  <div className="gap10" />
                  <p>Select Checkout Items</p>
                  <div className="tabsHolder radioTabs">
                    <RadioTag
                      onCheckChange={this.onSelectCheckoutItem.bind(this)}
                      labelList={this.state.checkoutItems}
                      id={"selectedCheckoutItem"}
                      type={"multiple"}
                      key={this.state.keyCheckoutItem}
                      selectedList={this.state.selectedCheckoutItems}
                      selectType={"value"}
                    />
                  </div>
                </div>
              )}
              {this.state.filterType === "stripePlan" && (
                <div>
                  <div className="gap10" />
                  <p>Select Stripe Plans</p>
                  <div className="tabsHolder radioTabs">
                    <RadioTag
                      onCheckChange={this.onSelectStripePlan.bind(this)}
                      labelList={this.state.stripePlans}
                      id={"selectedStripePlan"}
                      type={"multiple"}
                      key={this.state.keyStripePlan}
                      selectedList={this.state.selectedStripePlans}
                      selectType={"value"}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="gap20" />
            <button
              className="btn btn-primary"
              onClick={this.onClickCalculate.bind(this)}>
              Calculate Customer Revenue
            </button>
            <div className="gap20" />
          </div>
        </div>
        {this.state.isResult && (
          <div className="card">
            <div className="container-fluid">
              <div className="gap10" />
              <p>
                Total Paying Customers :{" "}
                <b
                  className="link pointer-click"
                  onClick={this.onClickUsers.bind(this)}>
                  {this.state.customers}
                </b>
              </p>
              <p>
                Total Revenue : <b>{`$${this.state.revenue}`}</b>
              </p>
              <p>
                Average Customer Value : <b>{`$${this.state.average}`}</b>
              </p>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CustomerRevenue);
