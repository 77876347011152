import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import images from "../../helpers/images";

class TierPage extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {};
  }

  render() {
    return (
      <div className="nfHolder">
        <div className="nfInner">
          <h1>Error - 501</h1>
          <img alt="broken" src={images.broken} className="headerCharacter" />
          <div className="gap10" />
          <p>Unfortunately your plan doesn't support this</p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TierPage);
