import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

class TagInfo extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    let includedTags = this.props.selectedIncludedTags || [];
    let excludedTags = this.props.selectedExcludedTags || [];

    let selectedValues = [];

    includedTags.forEach((element) => {
      element.tagType = "included";
      selectedValues.push(element);
    });

    excludedTags.forEach((element) => {
      element.tagType = "excluded";
      selectedValues.push(element);
    });

    this.state = {
      selectedValues: selectedValues,
    };
  }

  renderSelectedValues() {
    var returnVals = [];

    let selectedValues = this.state.selectedValues;

    for (let i = 0; i < selectedValues.length; i++) {
      let val = selectedValues[i];
      let name = val["name"];
      let backgroundColor = val.tagType === "included" ? "white" : "#ff3a05";
      let textColor = val.tagType === "included" ? "black" : "white";
      returnVals.push(
        <span
          key={i}
          style={{
            backgroundColor: backgroundColor,
            color: textColor,
          }}>
          {name}
        </span>
      );
    }
    return returnVals;
  }

  render() {
    return (
      <div className="card">
        <div className="container-fluid">
          <div className="gap20" />
          <p>Tags that trigger this email</p>

          <div style={{ marginTop: 20 }} className="tagsHolder">
            {this.renderSelectedValues()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TagInfo);
