import {
  CREATE_CHLG_QUE_FAILURE,
  CREATE_CHLG_QUE_SUCCESS,
  GET_CHLG_QUE_SUCCESS,
  CREATE_CHLG_ITEM_FAILURE,
  CREATE_CHLG_ITEM_SUCCESS,
  GET_CHLG_ITEM_SUCCESS,
  CREATE_CHLG_SUCCESS,
  CREATE_CHLG_FAILURE,
  GET_CHLG_SUCCESS,
  CREATE_SYSTEM_TAG_SUCCESS,
  CREATE_SYSTEM_TAG_FAILURE,
  GET_SYSTEM_TAG_SUCCESS,
  RESET_STATUS,
  UPDATE_CHLG_QUE_SUCCESS,
  UPDATE_CHLG_QUE_FAILURE,
  UPDATE_CHLG_ITEM_SUCCESS,
  UPDATE_CHLG_ITEM_FAILURE,
  UPDATE_CHLG_SUCCESS,
  UPDATE_CHLG_FAILURE,
  CREATE_ATQ_SUCCESS,
  CREATE_ATQ_FAILURE,
  CREATE_PROGRESS_JOURNAL_SUCCESS,
  CREATE_PROGRESS_JOURNAL_FAILURE,
  UPDATE_PROGRESS_JOURNAL_SUCCESS,
  UPDATE_PROGRESS_JOURNAL_FAILURE
} from "../types";

const initialState = {
  message: "",
  success: false,
  chlgQuestions: {},
  chlgItems: {},
  chlgs: {},
  systemTags: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RESET_STATUS:
      return {
        ...state,
        message: "",
        success: false
      };
    case CREATE_CHLG_QUE_SUCCESS:
      return {
        ...state,
        message: action.message,
        success: action.success
      };
    case CREATE_CHLG_QUE_FAILURE:
      return {
        ...state,
        message: action.message,
        success: action.success
      };
    case UPDATE_CHLG_QUE_SUCCESS:
      return {
        ...state,
        message: action.message,
        success: action.success
      };
    case UPDATE_CHLG_QUE_FAILURE:
      return {
        ...state,
        message: action.message,
        success: action.success
      };
    case GET_CHLG_QUE_SUCCESS:
      return {
        ...state,
        chlgQuestions: action.data
      };
    case CREATE_CHLG_ITEM_SUCCESS:
      return {
        ...state,
        message: action.message,
        success: action.success
      };
    case CREATE_CHLG_ITEM_FAILURE:
      return {
        ...state,
        message: action.message,
        success: action.success
      };
    case UPDATE_CHLG_ITEM_SUCCESS:
      return {
        ...state,
        message: action.message,
        success: action.success
      };
    case UPDATE_CHLG_ITEM_FAILURE:
      return {
        ...state,
        message: action.message,
        success: action.success
      };
    case GET_CHLG_ITEM_SUCCESS:
      return {
        ...state,
        chlgItems: action.data
      };
    case CREATE_CHLG_SUCCESS:
      return {
        ...state,
        message: action.message,
        success: action.success
      };
    case CREATE_CHLG_FAILURE:
      return {
        ...state,
        message: action.message,
        success: action.success
      };
    case UPDATE_CHLG_SUCCESS:
      return {
        ...state,
        message: action.message,
        success: action.success
      };
    case UPDATE_CHLG_FAILURE:
      return {
        ...state,
        message: action.message,
        success: action.success
      };
    case GET_CHLG_SUCCESS:
      return {
        ...state,
        chlgs: action.data
      };
    case CREATE_SYSTEM_TAG_SUCCESS:
      return {
        ...state,
        message: action.message,
        success: action.success
      };
    case CREATE_SYSTEM_TAG_FAILURE:
      return {
        ...state,
        message: action.message,
        success: action.success
      };
    case GET_SYSTEM_TAG_SUCCESS:
      return {
        ...state,
        systemTags: action.data
      };
    case CREATE_ATQ_SUCCESS:
      return {
        ...state,
        message: action.message,
        success: action.success
      };
    case CREATE_ATQ_FAILURE:
      return {
        ...state,
        message: action.message,
        success: action.success
      };
    case CREATE_PROGRESS_JOURNAL_SUCCESS:
      return {
        ...state,
        message: action.message,
        success: action.success
      };
    case CREATE_PROGRESS_JOURNAL_FAILURE:
      return {
        ...state,
        message: action.message,
        success: action.success
      };
    case UPDATE_PROGRESS_JOURNAL_SUCCESS:
      return {
        ...state,
        message: action.message,
        success: action.success
      };
    case UPDATE_PROGRESS_JOURNAL_FAILURE:
      return {
        ...state,
        message: action.message,
        success: action.success
      };
    default:
      return state;
  }
};
