import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import TitleBox from "../../../../components/general/titleBox";
import {
  callGetZoomUsers,
  callGetZoomMeetings,
  callGetZoomRegistrants,
} from "../../../../services";

class ZoomMeetingReport extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      zoomUsers: [],
      selectedZoomMeeting: "",
      zoomMeetings: [],
      selectedZoomUser: "",
      errorMessage: "",
      registrants: [],
    };
  }

  componentDidMount() {
    this.getZoomUsers();
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    if (name === "selectedZoomUser") {
      this.setState(
        {
          [name]: value,
        },
        () => {
          this.getZoomMeetings(value);
        }
      );
    } else if (name === "selectedZoomMeeting") {
      this.setState(
        {
          [name]: value,
        },
        () => {
          this.getZoomRegistrants(value);
        }
      );
    } else {
      this.setState({
        [name]: value,
      });
    }
  }

  async getZoomUsers() {
    let users = await callGetZoomUsers();
    if (users.status) {
      this.setState({ zoomUsers: users.data }, () => {
        if (this.state.selectedZoomUser) {
          this.getZoomMeetings(this.state.selectedZoomUser);
        }
      });
    }
  }

  async getZoomMeetings(userId) {
    if (userId) {
      let response = await callGetZoomMeetings(userId);
      if (response.status) {
        let selectedZoomMeeting = "";
        let meetings = response.data;
        if (meetings.length > 0) {
          selectedZoomMeeting = meetings[0].id;
          if (this.state.selectedZoomMeeting) {
            selectedZoomMeeting = this.state.selectedZoomMeeting;
          }
        }
        this.setState(
          {
            zoomMeetings: meetings,
            selectedZoomMeeting: selectedZoomMeeting,
          },
          () => {
            this.getZoomRegistrants(selectedZoomMeeting);
          }
        );
      }
    } else {
      this.setState({ zoomMeetings: [] });
    }
  }

  async getZoomRegistrants(meetingId) {
    if (meetingId) {
      let response = await callGetZoomRegistrants(meetingId);
      if (response.message) {
        this.setState({ errorMessage: response.message });
      } else {
        this.setState({
          errorMessage: "",
          registrants: response.data.registrants,
        });
      }
    }
  }

  renderRegistrants() {
    var returnVals = [];
    let registrants = this.state.registrants;
    for (let i = 0; i < registrants.length; i++) {
      const element = registrants[i];
      returnVals.push(
        <tr key={element.id}>
          <td>
            {element.first_name} {element.last_name}
          </td>
          <td>{element.email}</td>
          <td className="firstLetter">{element.status}</td>
        </tr>
      );
    }
    return returnVals;
  }

  render() {
    return (
      <div>
        <div className="container-fluid">
          <TitleBox title={"Zoom Meetings"} showBackBtn={true} />
          <div className="gap20" />
          <div className="card">
            <div className="container-fluid">
              <div className="gap20" />
              <div className="row">
                <div className="col-sm-3">
                  <div
                    className={
                      this.state.selectedZoomUser !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>Select User</label>
                    <select
                      className="selectRuleTypeInput"
                      name="selectedZoomUser"
                      value={this.state.selectedZoomUser}
                      onChange={(event) => this.handleInputChange(event)}>
                      <option value="" />
                      {this.state.zoomUsers.map((i) => (
                        <option value={i.id} key={i.id}>
                          {i.first_name} {i.last_name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="gap20" />
                </div>
                <div className="col-sm-9">
                  <div
                    className={
                      this.state.selectedZoomMeeting !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>Select Meeting</label>
                    <select
                      className="actionSelect"
                      name="selectedZoomMeeting"
                      value={this.state.selectedZoomMeeting}
                      onChange={(event) => this.handleInputChange(event)}>
                      <option value="" />
                      {this.state.zoomMeetings.map((i) => (
                        <option value={i.id} key={i.id}>
                          {i.topic}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="gap20" />
            </div>
          </div>
          {this.state.errorMessage.length > 0 && (
            <div className="card">
              <div className="container-fluid">
                <div className="gap10" />
                <p className="text-danger">{this.state.errorMessage}</p>
              </div>
            </div>
          )}
          {this.state.registrants.length > 0 && (
            <div className="card">
              <div className="container-fluid">
                <div className="gap20" />
                <h5 className="firstLetter">Registrants</h5>
                <div className="gap20" />
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>{this.renderRegistrants()}</tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ZoomMeetingReport);
