import React, { useEffect } from "react";
import ContentColumn from "./column";
import { useRecoilValue } from "recoil";
import { jsonState, editorTypeState } from "../recoil/atoms";
import TrashSVG from "../images/trash.svg";

function ContentRow(props) {
  let row = props.row;
  let gson = useRecoilValue(jsonState);
  let rowWidth = gson.contentEditor.width;
  const editorType = useRecoilValue(editorTypeState);

  const onClickRow = (event) => {
    event.stopPropagation();
    props.onClickRow(row.attributes.id);
  };

  const onClickColumn = (colId) => {
    props.onClickColumn(row.attributes.id, colId);
  };

  const onClickItem = (colId, item) => {
    props.onClickItem(colId, row.attributes.id, item);
  };

  const onClickAddHtml = (colId, item) => {
    props.onClickAddHtml(colId, row.attributes.id, item);
  };

  const onClickAddItem = (colId) => {
    props.onClickAddItem(colId, row.attributes.id);
  };

  const onClickAddImage = (colId, item, url) => {
    props.onClickAddImage(colId, row.attributes.id, item, url);
  };

  const onClickAddVideo = (colId, item, url) => {
    props.onClickAddVideo(colId, row.attributes.id, item, url);
  };

  const onClickSelectGrid = () => {
    props.onClickSelectGrid(row.attributes.id);
  };

  const onClickDelete = () => {
    props.onDeleteRow(row.attributes.id);
  };

  const renderColumn = () => {
    let cols = row.itemList;
    let returnVals = [];
    let sectionWidth = props.width;
    let usePageWidth = props.usePageWidth;
    if (usePageWidth === undefined) {
      usePageWidth = true;
    }
    cols.forEach((element) => {
      returnVals.push(
        <ContentColumn
          column={element}
          key={element.attributes.id}
          rowWidth={usePageWidth ? rowWidth : sectionWidth}
          onClickColumn={onClickColumn}
          onClickItem={onClickItem}
          onClickAddHtml={onClickAddHtml}
          onClickAddItem={onClickAddItem}
          onClickAddImage={onClickAddImage}
          onClickAddVideo={onClickAddVideo}
        />
      );
    });
    return returnVals;
  };

  useEffect(() => {
    //
  }, [props.width, props.usePageWidth]);

  if (editorType === "email") {
    var rowAttributes = row.attributes.attributes;
    var borderAttributes = {
      borderSpacing: rowAttributes.borderSpacing,
      borderCollapse: rowAttributes.borderCollapse,
    };

    return (
      <div className="section">
        <div
          className={`${
            row.itemList.length > 0
              ? "sectionRow sectionRowEmpty"
              : "sectionRow"
          } ${row.selected ? "srSelected" : ""}`}
          onClick={onClickRow.bind(this)}>
          <span className="elemLabel">
            Row{" "}
            <img
              src={TrashSVG}
              alt="delete"
              height={10}
              style={{ cursor: "pointer" }}
              onClick={onClickDelete.bind(this)}
            />
          </span>
          <div className="rowIn">
            <table
              width="100%"
              id={row.attributes.id}
              cellPadding="0"
              cellSpacing="0"
              border="0"
              className="wrapper mobile"
              style={rowAttributes}>
              <tbody>
                <tr>
                  <td align="center">
                    <table
                      width="100%"
                      cellPadding="0"
                      cellSpacing="0"
                      border="0"
                      className="container mobile"
                      style={borderAttributes}>
                      <tbody>
                        <tr>
                          {row.itemList.length === 0 && (
                            <td>
                              <div className="addNewItemHold">
                                {/*eslint-disable*/}
                                <a onClick={onClickSelectGrid.bind(this)}>
                                  SELECT GRID
                                </a>
                                {/*eslint-enable*/}
                              </div>
                            </td>
                          )}
                          {row.itemList.length > 0 && renderColumn()}
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  } else if (editorType === "web" || editorType === "popup") {
    return (
      <div className="section">
        <div
          className={`${
            row.itemList.length > 0
              ? "sectionRow sectionRowEmpty"
              : "sectionRow"
          } ${row.selected ? "srSelected" : ""}`}
          style={{ clear: "both" }}
          onClick={onClickRow.bind(this)}>
          <span className="elemLabel">
            Row{" "}
            <img
              src={TrashSVG}
              alt="delete"
              height={10}
              style={{ cursor: "pointer" }}
              onClick={onClickDelete.bind(this)}
            />
          </span>
          <div
            id={row.attributes.id}
            cellPadding="0"
            cellSpacing="0"
            border="0"
            className="wrapper mp-row"
            style={row.attributes.attributes}>
            {row.itemList.length === 0 && (
              <div className="addNewItemHold">
                {/*eslint-disable*/}
                <a onClick={onClickSelectGrid.bind(this)}>SELECT GRID</a>
                {/*eslint-enable*/}
              </div>
            )}
            {row.itemList.length > 0 && renderColumn()}
          </div>
        </div>
      </div>
    );
  }
}

export default ContentRow;
