import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import Switch from "react-switch";

import TitleBox from "../../../components/general/titleBox";
import {
  callUpdateSystemUpdate,
  callGetSystemUpdatesByQuery
} from "../../../services";

import FilterList from "../../../components/form/filterList";
import { filterByTags } from "../../../helpers/filters";
import { isFS, sanitizer } from "../../../helpers";

class ViewSystemUpdate extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    let types = [
      { name: "All", value: "all", selected: true },
      { name: "Article", value: "article", selected: false },
      { name: "Challenge", value: "challenge", selected: false },
      { name: "Other", value: "other", selected: false }
    ];

    if (isFS()) {
      types = [
        { name: "All", value: "all", selected: true },
        { name: "Article", value: "article", selected: false },
        { name: "News", value: "news", selected: false },
        { name: "Marci's Circle", value: "marci-circle", selected: false },
        { name: "Meal Plan", value: "meal-plan", selected: false },
        { name: "Challenge", value: "challenge", selected: false },
        { name: "Other", value: "other", selected: false }
      ];
    }

    this.state = {
      updates: [],
      updatesBackup: [],
      filterList: [],
      types: types
    };
  }

  componentDidMount() {
    this.callGetSystemUpdates();
  }

  callGetSystemUpdates() {
    /*eslint-disable*/
    let query = {
      query: {
        $sort: { release_date: -1 }
      }
    };
    /*eslint-enable*/
    callGetSystemUpdatesByQuery(query).then(d => {
      this.setState({
        updates: d.data,
        updatesBackup: d.data
      });
    });
  }

  onClickInactive(element, isActive) {
    /*eslint-disable*/
    let data = {
      is_active: isActive
    };
    /*eslint-enable*/
    callUpdateSystemUpdate(element._id, data).then(d => {
      this.callGetSystemUpdates();
    });
  }

  onClickEditSystemUpdate(systemUpdate) {
    this.props.history.push("/admin/add-system-update", {
      systemUpdate: systemUpdate
    });
  }

  filterUpdated(updatedFilter, name, type, searchFields) {
    let filterList = this.state.filterList;
    filterList[name] = {
      name: name,
      type: type,
      searchFields: searchFields,
      values: updatedFilter
    };
    this.setState(
      {
        filterList: filterList
      },
      () => {
        let data = filterByTags(this.state.updatesBackup, filterList);
        this.setState({
          updates: data
        });
      }
    );
  }

  renderUpdates() {
    var returnVals = [];
    let items = this.state.updates;
    items.forEach(element => {
      returnVals.push(
        <tr key={element._id}>
          <td>{element.title}</td>
          <td>{element.update_type}</td>
          <td className="qlContent">
            <div
              dangerouslySetInnerHTML={{
                __html: sanitizer(element.content)
              }}
            />
          </td>
          <td>{element.action_url ? "Y" : "N"}</td>
          <td>{moment(element.release_date).format("MMMM Do, YYYY")}</td>
          <td>
            {/*eslint-disable-next-line*/}
            <a
              onClick={this.onClickEditSystemUpdate.bind(this, element)}
              className="btn btn-default btn-sm border-0"
            >
              <i className="fa fa-pencil" />
            </a>
          </td>
          <td>
            <Switch
              onChange={this.onClickInactive.bind(this, element)}
              checked={
                element.is_active === undefined ? true : element.is_active
              }
              onColor={"#26ace2"}
              checkedIcon={false}
              uncheckedIcon={false}
            />
          </td>
        </tr>
      );
    });
    return returnVals;
  }

  renderFilterView() {
    return (
      <>
        <div className="list-group-item">
          <FilterList
            label={"Search Updates"}
            name={"search"}
            type={"search"}
            searchFields={["title", "content"]}
            onFinish={this.filterUpdated.bind(this)}
          />
        </div>
        <div className="list-group-item">
          <p>Select a type</p>
          <FilterList
            filterList={this.state.types}
            name={"searchType"}
            type={"single"}
            searchFields={["update_type"]}
            onFinish={this.filterUpdated.bind(this)}
          />
        </div>
      </>
    );
  }

  render() {
    return (
      <div className="container-fluid">
        <TitleBox title="System Update Overview" />
        <div className="gap20" />
        <div className="card">
          <div className="list-group list-group-flush">
            {this.renderFilterView()}
            <div className="list-group-item">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Type</th>
                    <th>Content</th>
                    <th>Action Url</th>
                    <th>Release Date</th>
                    <th>Action</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>{this.renderUpdates()}</tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  systemUpdates: state.systemUpdateReducer.systemUpdates
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ViewSystemUpdate);
