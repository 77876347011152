import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import $ from "jquery";

import FilterList from "../../../components/form/filterList";
import TitleBox from "../../../components/general/titleBox";
import TableLoader from "../../../components/loader/table";
import { filterByTags } from "../../../helpers/filters";

import {
  callGetArticleById,
  callDeleteArticleById,
  callCreateArticle,
  callGetArticle,
} from "../../../services";
import { showNotification, slugify } from "../../../helpers";
import { getUpdatedEditorContent } from "../../../components/editor/processEditor";
import { getItem } from "../../../helpers/storage";
import { SettingsHelper } from "../../../helpers/settings";

class ArticleOverview extends React.Component {
  constructor(props) {
    super(props);

    this.props = props;
    this.state = {
      articles: [],
      articlesBackup: [],
      filterList: [],
      categories: [],
      tags: [],
      dateFilters: [],
      selectedArticle: {},
      articleTitle: "",
      keyFilter: Math.random(),
      showLoader: true,
      domainList: [],
    };
  }

  componentDidMount() {
    this.getArticles();
  }

  async getArticles() {
    var response = await callGetArticle();
    let settingsHelper = new SettingsHelper();
    let domainList = settingsHelper.getDomainList();
    this.setState(
      {
        articles: response.data,
        articlesBackup: response.data,
        showLoader: false,
        domainList: domainList,
      },
      () => {
        this.prepareDataForFilter(response.data);
      }
    );
  }

  prepareDataForFilter(data) {
    let categories = [{ name: "All", value: "all", selected: true }];
    let tags = [{ name: "All", value: "all", selected: true }];

    data.forEach((element) => {
      if (element.category) {
        let category = element.category[0].name;

        let arrCategory = categories.filter((d) => {
          return d.name.toLowerCase() === category.toLowerCase();
        });
        if (arrCategory.length === 0) {
          categories.push({
            name: category.charAt(0).toUpperCase() + category.slice(1),
            value: category,
            selected: false,
          });
        }
      }

      let tag = element.ftags || [];
      tag.forEach((el) => {
        let arrTag = tags.filter((d) => {
          return d.name.toLowerCase() === el.name.toLowerCase();
        });
        if (arrTag.length === 0) {
          tags.push({
            name: el.name.charAt(0).toUpperCase() + el.name.slice(1),
            value: el.name.toLowerCase(),
            selected: false,
          });
        }
      });
    });

    let dateFilters = [
      {
        name: "All",
        value: "all",
        type: "selected",
        selected: true,
      },
      {
        name: "Today",
        value: "-days-0",
        type: "selected",
        selected: false,
      },
      {
        name: "Tomorrow",
        value: "-days+1",
        type: "selected",
        selected: false,
      },
      {
        name: "Next 7 Days",
        value: "-days+7",
        type: "selected",
        selected: false,
      },
      {
        name: "Last 7 Days",
        value: "-days-7",
        type: "selected",
        selected: false,
      },
      {
        name: "Next 30 Days",
        value: "-days+30",
        type: "selected",
        selected: false,
      },
      {
        name: "Last 30 Days",
        value: "-days-30",
        type: "selected",
        selected: false,
      },
    ];

    this.setState({
      categories: categories,
      tags: tags,
      dateFilters: dateFilters,
      keyFilter: Math.random(),
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  getFormattedTags(tags) {
    if (tags && tags.length > 0) {
      let ftags = tags.map((a) => a.name);
      return ftags.join(", ");
    } else {
      return "";
    }
  }

  getFormattedDate(date) {
    // formatted date
    return moment(date).format("MMM, Do YYYY");
  }

  onClickEditArticle = (article) => {
    callGetArticleById(article._id).then((d) => {
      this.props.history.push("/admin/add-article", {
        article: d,
      });
    });
  };

  onClickCloneArticle = (article) => {
    callGetArticleById(article._id).then((d) => {
      this.setState({ selectedArticle: d }, () => {
        $(
          ".modalAlertOverlay.articleModalOverlay,.modalAlert.articleAlert"
        ).fadeIn(200);
      });
    });
  };

  onClickDeleteArticle = (article) => {
    this.setState({ selectedArticle: article }, () => {
      $(
        ".modalAlertOverlay.articleDeleteOverlay,.modalAlert.articleDeleteAlert"
      ).fadeIn(200);
    });
  };

  onClickViewArticle = (article) => {
    if (article.category && article.category.length > 0) {
      let category = article.category[0].name;
      let articleSlug = article.article_slug;
      let key = getItem("sk");

      let domainList = this.state.domainList;
      let articleUrls = domainList.filter((d) => {
        return d.type === "article";
      });
      let articleUrl = `https://articles.memberpages.app/${category}/${articleSlug}?key=${key}`;
      if (articleUrls.length > 0) {
        articleUrl = `https://${articleUrls[0].domain}/${category}/${articleSlug}?key=${key}`;
      }

      window.open(articleUrl, "_blank");
    }
  };

  renderArticles() {
    var returnVals = [];
    let items = this.state.articles;
    for (let i = 0; i < items.length; i++) {
      const element = items[i];
      returnVals.push(
        <tr key={element._id}>
          <td>{element.title}</td>
          <td>
            {element.category
              ? element.category[0].name
                ? element.category[0].name
                : ""
              : ""}
          </td>
          <td>
            {element.sub_category
              ? element.sub_category[0].name
                ? element.sub_category[0].name
                : ""
              : ""}
          </td>
          <td>{this.getFormattedTags(element.ftags)}</td>
          <td>{this.getFormattedDate(element.release_date)}</td>
          <td className="min-width-200">
            <button
              onClick={this.onClickViewArticle.bind(this, element)}
              className="btn btn-default btn-sm margin-right-10 border-0">
              <i className="fa fa-eye" />
            </button>
            <button
              onClick={this.onClickEditArticle.bind(this, element)}
              className="btn btn-default btn-sm margin-right-10 border-0">
              <i className="fa fa-pencil" />
            </button>
            <button
              onClick={this.onClickCloneArticle.bind(this, element)}
              className="btn btn-default btn-sm margin-right-10 border-0">
              <i className="fa fa-clone" />
            </button>
            <button
              onClick={this.onClickDeleteArticle.bind(this, element)}
              className="btn btn-danger btn-sm border-0">
              <i className="fa fa-trash" />
            </button>
          </td>
        </tr>
      );
    }

    return returnVals;
  }

  filterUpdated(updatedFilter, name, type, searchFields) {
    let filterList = this.state.filterList;
    filterList[name] = {
      name: name,
      type: type,
      searchFields: searchFields,
      values: updatedFilter,
    };
    this.setState(
      {
        filterList: filterList,
      },
      () => {
        let data = filterByTags(this.state.articlesBackup, filterList);
        this.setState({
          articles: data,
        });
      }
    );
  }

  onClickNoAlert = () => {
    $(
      ".modalAlertOverlay.articleDeleteOverlay,.modalAlert.articleDeleteAlert"
    ).fadeOut(200);
  };

  onClickYesAlert = () => {
    callDeleteArticleById(this.state.selectedArticle._id).then((d) => {
      $(
        ".modalAlertOverlay.articleDeleteOverlay,.modalAlert.articleDeleteAlert"
      ).fadeOut(200);
      this.getArticles();
    });
  };

  renderConfirmationAlert() {
    let selectedArticle = this.state.selectedArticle;
    let title = "";
    if (selectedArticle.title) {
      title = selectedArticle.title;
    }
    return (
      <div>
        <div className="modalAlertOverlay articleDeleteOverlay" />
        <div className="modalAlert articleDeleteAlert">
          <p>{`Are you sure you want to delete "${title}" article?`}</p>
          <div className="alertFooterBtns">
            <button
              className="btn btn-default closeModal-No margin-right-10"
              onClick={this.onClickNoAlert}>
              No
            </button>
            <button
              className="btn btn-primary closeModal-Yes"
              onClick={this.onClickYesAlert}>
              Yes
            </button>
          </div>
        </div>
      </div>
    );
  }

  onClickCancelFromArticleModal() {
    $(
      ".modalAlertOverlay.articleModalOverlay,.modalAlert.articleAlert"
    ).fadeOut(200);
  }

  onClickProcessFromArticleModal() {
    let articleTitle = this.state.articleTitle.trim();

    if (articleTitle.length === 0) {
      showNotification("error", "Please enter article title", 4000);
      return;
    }

    let articleSlug = slugify(articleTitle);

    let existingArticle = this.state.articlesBackup.filter((d) => {
      return d.article_slug === articleSlug;
    });

    if (existingArticle.length > 0) {
      showNotification("error", "Article already exists", 4000);
      return;
    }

    $(
      ".modalAlertOverlay.articleModalOverlay,.modalAlert.articleAlert"
    ).fadeOut(200);

    let selectedArticle = this.state.selectedArticle;

    /*eslint-disable*/
    let apiData = {
      title: articleTitle,
      article_slug: articleSlug,
      article_category_id: selectedArticle.article_category_id,
      article_subcategory_id: selectedArticle.article_subcategory_id,
      editor_content: getUpdatedEditorContent(
        selectedArticle.editor_content,
        selectedArticle.main_content
      ),
      main_content: selectedArticle.main_content,
      short_version: selectedArticle.short_version,
      status: "inactive",
      release_date: selectedArticle.release_date,
      image: selectedArticle.image,
      thumbnail: selectedArticle.thumbnail,
      video_url: selectedArticle.video_url,
      tags: selectedArticle.tags,
      author: selectedArticle.author,
    };
    /*eslint-enable*/

    callCreateArticle(apiData).then((d) => {
      if (d._id) {
        this.setState({ selectedArticle: {}, articleTitle: "" }, () => {
          showNotification("success", "Article created successfully", 4000);
          this.getArticles();
        });
      } else {
        showNotification("error", "Something went wrong", 4000);
      }
    });
  }

  renderNewArticleModal() {
    let title = "";
    if (this.state.selectedArticle.title) {
      title = this.state.selectedArticle.title;
    }
    return (
      <div>
        <div className="modalAlertOverlay articleModalOverlay" />
        <div className="modalAlert articleAlert">
          <div className="clearfix">
            <div className="pull-left">
              <p>Clone Article - {title}</p>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div
                  className={
                    this.state.articleTitle !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Article Title</label>
                  <input
                    type="text"
                    name="articleTitle"
                    value={this.state.articleTitle}
                    onChange={(event) => this.handleInputChange(event)}
                  />
                </div>
                <div className="gap10" />
              </div>
            </div>
          </div>
          <div className="gap10" />
          <div className="container-fluid">
            <label style={{ fontSize: 14 }}>
              Cloned articles will automatically be set as "Inactive" and will
              not go out until set to "Active"
            </label>
          </div>

          <div className="gap10" />

          <div className="alertFooterBtns">
            <button
              onClick={this.onClickCancelFromArticleModal.bind(this)}
              className="btn btn-default dismissThisModal margin-right-10">
              Cancel
            </button>
            <button
              onClick={this.onClickProcessFromArticleModal.bind(this)}
              className="btn btn-primary dismissThisModal">
              Create Article
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderFilterView() {
    return (
      <>
        <div className="list-group-item">
          <FilterList
            label={"Search Articles"}
            name={"search"}
            type={"search"}
            searchFields={["title", "short_version"]}
            onFinish={this.filterUpdated.bind(this)}
          />
        </div>
        <div className="list-group-item">
          <p>Filter by date</p>
          <FilterList
            key={this.state.keyFilter}
            filterList={this.state.dateFilters}
            name={"releaseDates"}
            type={"date"}
            searchFields={["release_date"]}
            onFinish={this.filterUpdated.bind(this)}
          />
        </div>
        <div className="list-group-item">
          <p>Select a category</p>
          <FilterList
            key={this.state.keyFilter}
            filterList={this.state.categories}
            name={"categories"}
            type={"single"}
            searchFields={["category->name"]}
            onFinish={this.filterUpdated.bind(this)}
          />
        </div>
        <div className="list-group-item">
          <p>Select a tag</p>
          <FilterList
            key={this.state.keyFilter}
            filterList={this.state.tags}
            name={"tags"}
            type={"single"}
            searchFields={["ftags->name"]}
            onFinish={this.filterUpdated.bind(this)}
          />
        </div>
      </>
    );
  }

  render() {
    return (
      <div>
        {this.renderConfirmationAlert()}
        {this.renderNewArticleModal()}
        <div className="container-fluid">
          <TitleBox title="Article Overview" />
          <div className="gap20" />
          <div className="card">
            <div className="list-group list-group-flush">
              {this.renderFilterView()}
              {!this.state.showLoader && (
                <div className="list-group-item">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Article Name</th>
                        <th>Category</th>
                        <th>Sub Category</th>
                        <th>Tags</th>
                        <th>Release Date</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>{this.renderArticles()}</tbody>
                  </table>
                </div>
              )}
              {this.state.showLoader && (
                <div className="container-fluid">
                  <TableLoader />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  articles: state.articleReducer.articles,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ArticleOverview);
