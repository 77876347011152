import * as api from "./api";
import { callApi } from "../helpers";

function callCreateQuiz(data) {
  return callApi(api.service("quiz").create(data));
}

function callUpdateQuiz(id, data) {
  return callApi(api.service("quiz").patch(id, data));
}

function callGetQuizUsingQuery(query) {
  return callApi(api.service("quiz").find(query));
}

function callGetQuizById(id) {
  return callApi(api.service("quiz").get(id));
}

function callDeleteQuizById(id) {
  return callApi(api.service("quiz").remove(id));
}

function callCreateQuizResult(data) {
  return callApi(api.service("quiz-result").create(data));
}

function callUpdateQuizResult(id, data) {
  return callApi(api.service("quiz-result").patch(id, data));
}

function callGetQuizResultUsingQuery(query) {
  return callApi(api.service("quiz-result").find(query));
}

function callGetQuizResultById(id) {
  return callApi(api.service("quiz-result").get(id));
}

function callDeleteQuizResultById(id) {
  return callApi(api.service("quiz-result").remove(id));
}

export {
  callCreateQuiz,
  callUpdateQuiz,
  callGetQuizUsingQuery,
  callGetQuizById,
  callDeleteQuizById,
  callCreateQuizResult,
  callUpdateQuizResult,
  callGetQuizResultUsingQuery,
  callGetQuizResultById,
  callDeleteQuizResultById
};
