import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import DatePicker from "react-date-picker";
import * as $ from "jquery";
import moment from "moment";

import RadioTag from "../form/radioTag";

class DateFilter extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    let customOption = this.props.customOption;

    if (customOption === undefined) {
      customOption = true;
    }

    let dateFilters = [
      { name: "All", value: "all" },
      { name: "Today", value: "today" },
      { name: "Yesterday", value: "yesterday" },
      { name: "This Week", value: "week" },
      { name: "This Month", value: "month" },
      { name: "This Year", value: "year" },
    ];
    if (customOption) {
      dateFilters.push({ name: "Custom", value: "custom" });
    }
    let selected = this.props.selected || "all";
    this.state = {
      dateFilters: dateFilters,
      keyOption: Math.random(),
      selectedCustomDates: [],
      keyFilterDate: Math.random(),
      startFilterDate: new Date(),
      endFilterDate: new Date(),
      selectedList: props.selectedList || [selected],
      label: this.props.label || "Filter By Date",
    };
  }

  onClickDateFilterReport(selectedList) {
    let filter = selectedList[Object.keys(selectedList)[0]].value;
    if (filter === "custom") {
      $(
        ".modalAlertOverlay.filterDateOverlay,.modalAlert.filterDateAlert"
      ).fadeIn(200);
      this.setState({ keyFilterDate: Math.random() });
    } else {
      this.props.onChangeFilter({ filter: filter });
    }
  }

  onChangeFilterDate(name, date) {
    this.setState({
      [name]: date,
    });
  }

  onClickCloseFromFilterDateModal() {
    $(
      ".modalAlertOverlay.filterDateOverlay,.modalAlert.filterDateAlert"
    ).fadeOut(200);
  }

  onClickDoneFromFilterDateModal() {
    $(
      ".modalAlertOverlay.filterDateOverlay,.modalAlert.filterDateAlert"
    ).fadeOut(200);
    let customDates =
      moment(this.state.startFilterDate).startOf("day").format("YYYY-MM-DD") +
      "," +
      moment(this.state.endFilterDate)
        .add(1, "day")
        .startOf("day")
        .format("YYYY-MM-DD");

    this.props.onChangeFilter({
      filter: "custom",
      customDates: customDates,
    });
  }

  renderCustomDateModal() {
    return (
      <div>
        <div className="modalAlertOverlay filterDateOverlay" />
        <div className="modalAlert filterDateAlert">
          <p> Filter By Date </p>
          <div className="container-fluid">
            <div className="row">
              <div className="col-6">
                <label className="inputFakeLabelFocussed">
                  Select Start Date
                </label>
                <div>
                  <DatePicker
                    key={this.state.keyFilterDate}
                    value={this.state.startFilterDate}
                    onChange={this.onChangeFilterDate.bind(
                      this,
                      "startFilterDate"
                    )}
                  />
                </div>
              </div>
              <div className="col-6">
                <label className="inputFakeLabelFocussed">
                  Select End Date
                </label>
                <div>
                  <DatePicker
                    key={this.state.keyFilterDate}
                    value={this.state.endFilterDate}
                    onChange={this.onChangeFilterDate.bind(
                      this,
                      "endFilterDate"
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="alertFooterBtns">
            <button
              className="btn btn-default dismissThisModal margin-right-10"
              onClick={this.onClickCloseFromFilterDateModal.bind(this)}>
              Close
            </button>
            <button
              className="btn btn-primary dismissThisModal"
              onClick={this.onClickDoneFromFilterDateModal.bind(this)}>
              Done
            </button>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderCustomDateModal()}

        <div className="list-group-item">
          <p>{this.state.label}</p>
          <div className="tabsHolder radioTabs">
            <RadioTag
              onCheckChange={this.onClickDateFilterReport.bind(this)}
              labelList={this.state.dateFilters}
              selectType={"value"}
              selectedList={this.state.selectedList}
              key={this.state.keyOption}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DateFilter);
