import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import TitleBox from "../../../../components/general/titleBox";
import { callGetUsersByQuery } from "../../../../services";
import RadioTag from "../../../../components/form/radioTag";
import { exportCSV } from "../../../../helpers/exportCSV";
import { callGetSettings } from "../../../../services/settingService";

class UnsubscribedUserReport extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      users: [],
      backupUsers: [],
      type: "all",
      keyRadioSelect: Math.random(),
      emailTypes: [],
    };
  }

  componentDidMount() {
    this.getUsers();
  }

  async getUsers() {
    let query = {
      query: {
        $select: [
          "_id",
          "name",
          "email_subscription",
          "email_type_subscription",
          "email_address",
          "unsubscribed_email_types",
        ],
        populate: "no",
      },
    };
    var response = await callGetUsersByQuery(query);
    let settings = await callGetSettings("emailsettings");
    let emailTypes = settings.data.email_settings.email_types || [];
    emailTypes = emailTypes.filter((d) => d.status === "active" || !d.status);
    emailTypes = emailTypes.filter((d) => d.name !== "System");

    let users = response.data;

    this.setState({
      users: users,
      backupUsers: users,
      emailTypes,
      keyRadioSelect: Math.random(),
    });
  }

  onClickViewUser = (user) => {
    if (user && user._id) {
      let url = `/admin/edit-user?id=${user._id}`;
      window.open(url, "_blank");
    }
  };

  renderEmailTypes(unsubscribedEmailTypes) {
    let emailTypes = this.state.emailTypes;
    let returnVals = [];

    emailTypes.forEach((element) => {
      if (unsubscribedEmailTypes.indexOf(element.value) > -1) {
        returnVals.push(
          <>
            {element.name} : <label className="text-danger">False</label>
            <br />
          </>
        );
      } else {
        returnVals.push(
          <>
            {element.name} : <label className="text-primary">True</label>
            <br />
          </>
        );
      }
    });
    return returnVals;
  }

  renderUsers() {
    var returnVals = [];
    let users = this.state.users;
    for (let i = 0; i < users.length; i++) {
      const element = users[i];
      returnVals.push(
        <tr key={element._id}>
          <td className="firstLetter">
            {/*eslint-disable*/}
            <a
              className="link mouse-pointer"
              onClick={this.onClickViewUser.bind(this, element)}>
              {element.name}
            </a>
            {/*eslint-enable*/}
          </td>
          <td>{element.email_address}</td>
          <td>
            {this.renderEmailTypes(element.unsubscribed_email_types || [])}
          </td>
        </tr>
      );
    }
    return returnVals;
  }

  onSelectRadioSelect(item, id) {
    let value = item[Object.keys(item)[0]].value;
    this.setState({ [id]: value });
    if (value !== "all") {
      let users = this.state.backupUsers.filter(
        (d) => d.unsubscribed_email_types.indexOf(value) > -1
      );
      this.setState({ users: users });
    } else {
      this.setState({ users: this.state.backupUsers });
    }
  }

  onClickExport() {
    let users = this.state.users;
    let emailTypes = this.state.emailTypes;

    let rows = [];

    let headers = [
      { id: "name", display: "Name" },
      { id: "email", display: "Email Address" },
      { id: "subscription", display: "Subscription" },
    ];
    users.forEach((element) => {
      let subs = "";

      emailTypes.forEach((emailType) => {
        if (element.unsubscribed_email_types.indexOf(emailType.value) > -1) {
          subs = `${subs}, ${emailType.name}: No`;
        } else {
          subs = `${subs}, ${emailType.name}: Yes`;
        }
      });
      subs = subs.slice(1, subs.length);
      rows.push({
        name: element.name,
        email: element.email_address,
        subscription: subs,
      });
    });
    exportCSV(headers, rows, "unsubscribed-users");
  }

  render() {
    return (
      <div className="container-fluid">
        <TitleBox title="Unsubscribed Users" showBackBtn={true} />
        <div className="gap20" />
        <div className="card">
          <div className="container-fluid">
            <div className="gap10" />
            <div>
              <label>Type</label>
              <div className="tabsHolder radioTabs">
                <RadioTag
                  onCheckChange={this.onSelectRadioSelect.bind(this)}
                  labelList={[...this.state.emailTypes]}
                  key={this.state.keyRadioSelect}
                  id={"type"}
                  selectType={"value"}
                  selectedList={[this.state.type]}
                />
              </div>
              <div className="gap20" />
            </div>
          </div>
        </div>
        <div className="card">
          <div className="container-fluid">
            <div className="gap20" />
            <div className="clearfix">
              <div className="float-left"></div>
              <div className="float-right">
                <button
                  className="btn btn-primary btn-sm"
                  onClick={this.onClickExport.bind(this)}>
                  Export
                </button>
              </div>
            </div>
            <div className="gap20" />
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Subscription</th>
                </tr>
              </thead>
              <tbody>{this.renderUsers()}</tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnsubscribedUserReport);
