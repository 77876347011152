import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
// import $ from "jquery";
import { Scrollbars } from "react-custom-scrollbars";

import FilterList from "../../../components/form/filterList";
import TitleBox from "../../../components/general/titleBox";
import TableLoader from "../../../components/loader/table";
import { filterByTags } from "../../../helpers/filters";
import * as api from "../../../services/api";
import {
  callGetEmailUsingId,
  callCreateEmail,
  callGetEmails,
  callUpdateEmail,
  callGetReports,
  callUpdateEmailByQuery,
} from "../../../services";
import { showNotification, getParameterByName } from "../../../helpers";
import Checkbox from "../../../components/form/checkBox";
import ActionFilter from "../../../components/form/actionFilter";
import { getUpdatedEditorContent } from "../../../components/editor/processEditor";

declare var $: any;

class ViewEmail extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    let isRedirecting = false;

    let emailId = getParameterByName("id");

    if (emailId) {
      isRedirecting = true;
    }

    this.state = {
      userData: this.props.userData || {},
      isRedirecting: isRedirecting,
      emailId: emailId,
      emails: [],
      emailsBackup: [],
      searchQuery: "",
      filterList: [],
      emailName: "",
      selectedEmail: {},
      showLoader: true,
      emailCategories: [
        { name: "All", value: "all", selected: true },
        { name: "Automated", value: "automated", selected: false },
        { name: "One Time", value: "onetime", selected: false },
      ],
      emailTypes: [
        { name: "All", value: "all", selected: false },
        { name: "General", value: "general", selected: true },
        { name: "System", value: "system", selected: false },
      ],
      emailStatus: [
        { name: "Active", value: "active", selected: true },
        { name: "Inactive", value: "inactive", selected: false },
        { name: "Completed", value: "completed", selected: false },
        { name: "All", value: "all", selected: false },
      ],
      tags: [],
      eventLogs: [],
      keyFilter: Math.random(),
      emailHistories: [],
      emailActions: [],
      isSelectAll: false,
      keySelectAll: Math.random(),
      emailCheckbox: {},
      keyActionFilter: Math.random(),
      tagFilters: [],
      removeGroups: [],
    };
  }

  componentDidMount() {
    this.setupEventLog();
    this.callGetEmailStats();
  }

  callGetEmailStats() {
    let data = {};
    data["type"] = "email-stats";
    data["action"] = "all";
    callGetReports(data).then((d) => {
      this.setState({
        emailHistories: d.email_histories,
        emailActions: d.email_actions,
      });
    });
  }

  componentDidUpdate() {
    if ($(window).width() > 780) {
      setTimeout(() => {
        let tooltipFn = $("[data-toggle='tooltip']");
        if (tooltipFn) {
          tooltipFn.tooltip({
            html: true,
            animated: "fade",
          });
        }
      }, 2000);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.userData) {
      this.setState(
        {
          userData: nextProps.userData,
        },
        () => {
          this.setupEventLog();
        }
      );
    }
  }

  getEmails(option) {
    /*eslint-disable*/
    let query = {
      query: {
        $sort: { createdAt: -1 },
        status: { $ne: "archive" },
        created_from: { $nin: ["challenge-rule", "event", "checkout-item"] },
      },
    };
    /*eslint-enable*/
    callGetEmails(query).then((d) => {
      this.setState(
        {
          emails: d.data,
          emailsBackup: d.data,
          showLoader: false,
        },
        () => {
          this.prepareEmailCheckbox(false);
          if (this.state.isRedirecting) {
            this.onClickEditEmail({ _id: this.state.emailId });
          } else {
            this.prepareDataForFilter(d.data);
            if (option === 1) {
              this.filterUpdated(
                this.state.emailStatus,
                "searchType",
                "single",
                ["status"]
              );
              this.filterUpdated(
                this.state.emailTypes,
                "searchType",
                "single",
                ["email_filter_type"]
              );
            } else if (option === 2) {
              this.filterUpdated(
                this.state.emailStatus,
                "searchStatus",
                "single",
                ["status"]
              );
            } else {
              this.filterEmails();
            }
          }

          this.state.emailsBackup.forEach((email) => {
            email["email_filter_type"] =
              email.email_type === "0" ? "system" : "general";
          });
        }
      );
    });
  }

  prepareEmailCheckbox(status = false) {
    let emails = this.state.emails;
    let emailCheckbox = {};
    emails.forEach((element) => {
      emailCheckbox[element._id] = status;
    });
    this.setState({ emailCheckbox: emailCheckbox }, () => {
      this.prepareRemoveGroups();
    });
  }

  prepareRemoveGroups() {
    let emailCheckbox = this.state.emailCheckbox;
    let emails = this.state.emailsBackup;
    let emailIds = [];
    for (const key in emailCheckbox) {
      if (emailCheckbox.hasOwnProperty(key)) {
        const element = emailCheckbox[key];
        if (element) {
          emailIds.push(key);
        }
      }
    }
    let removeGroups = [];
    emailIds.forEach((element) => {
      let emailInfo = emails.find((i) => i._id === element);
      removeGroups = removeGroups.concat(emailInfo.ftags);
    });
    let groups = [];
    removeGroups.forEach((element) => {
      let existingGroup = groups.find((e) => e._id === element._id);
      if (!existingGroup) {
        groups.push(element);
      }
    });
    this.setState({ removeGroups: groups, keyActionFilter: Math.random() });
  }

  filterEmails() {
    let data = filterByTags(this.state.emailsBackup, this.state.filterList);
    this.setState({
      emails: data,
    });
  }

  prepareDataForFilter(data) {
    let tags = [];
    let tagFilters = [];

    data.forEach((element) => {
      let tag = element.ftags || [];
      tag.forEach((el) => {
        let arrTag = tags.filter((d) => {
          return d.name.toLowerCase() === el.name.toLowerCase();
        });
        if (arrTag.length === 0) {
          tags.push({
            name: el.name.charAt(0).toUpperCase() + el.name.slice(1),
            value: el.name.toLowerCase(),
            selected: false,
          });
        }

        let arrTag1 = tagFilters.filter((d) => {
          return d.id === el._id;
        });
        if (arrTag1.length === 0) {
          tagFilters.push({
            name: el.name,
            id: el._id,
          });
        }
      });
    });

    this.setState({
      tags: tags,
      tagFilters: tagFilters,
      keyFilter: Math.random(),
      keyActionFilter: Math.random(),
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  filterUpdated(updatedFilter, name, type, searchFields) {
    let filterList = this.state.filterList;
    filterList[name] = {
      name: name,
      type: type,
      searchFields: searchFields,
      values: updatedFilter,
    };
    this.setState(
      {
        filterList: filterList,
      },
      () => {
        let data = filterByTags(this.state.emailsBackup, filterList);
        this.setState({
          emails: data,
        });
      }
    );
  }

  setupEventLog() {
    this.getEventLogs();
    if ($(window).width() > 780) {
      setTimeout(() => {
        let tooltipFn = $("[data-toggle='tooltip']");
        if (tooltipFn) {
          tooltipFn.tooltip({
            html: true,
            animated: "fade",
          });
        }
      }, 2000);
    }
    // api.service("event-log").on("created", data => {
    //   this.getEventLogs();
    // });

    // api.service("event-log").on("removed", data => {
    //   this.getEventLogs();
    // });
  }

  getEventLogs() {
    api
      .service("event-log")
      .find({ query: { type: "email" } })
      .then((d) => {
        let logs = d.data;
        let fLogs = logs.filter((d) => {
          return d.member_id !== this.state.userData._id;
        });
        this.setState({ eventLogs: fLogs });
        this.getEmails(1);
      });
  }

  checkEventLogs(emailId) {
    let eventLogs = this.state.eventLogs;
    let filterEventLogs = eventLogs.filter((d) => {
      return d.item_id === emailId;
    });
    if (filterEventLogs.length > 0) {
      return [true, filterEventLogs[0]];
    }
    return [false, {}];
  }

  onClickEditEmail = (email) => {
    let showBack = this.state.isRedirecting ? "n" : "y";
    this.props.history.push(
      `/admin/edit-email?eid=${email._id}&back=${showBack}`
    );
  };

  onClickReportEmail = (email) => {
    callGetEmailUsingId(email._id).then((emailInfo) => {
      if (emailInfo._id) {
        let count = 0;
        let fEmail = this.state.emailHistories.filter((d) => {
          return d.email_id === emailInfo._id;
        });
        if (fEmail.length > 0) {
          count = fEmail[0].count;
        }
        this.props.history.push("/admin/reports/email-links", {
          email: emailInfo,
          sentEmails: count,
        });
      }
    });
  };

  onClickTagOverview = (email) => {
    this.setState({ selectedEmail: email }, () => {
      $(".modalAlertOverlay.infoOverlay,.modalAlert.infoAlert").fadeIn(200);
    });
  };

  onClickViewArchivedEmails() {
    this.props.history.push("/admin/view-archived-emails");
  }

  onClickCloneEmail = (email) => {
    callGetEmailUsingId(email._id).then((emailInfo) => {
      this.setState({ selectedEmail: emailInfo }, () => {
        $(".modalAlertOverlay.emailModalOverlay,.modalAlert.emailModal").fadeIn(
          200
        );
      });
    });
  };

  onClickCancelFromEmailModal() {
    $(".modalAlertOverlay.emailModalOverlay,.modalAlert.emailModal").fadeOut(
      200
    );
  }

  onClickProcessFromEmailModal() {
    if (this.state.emailName.length === 0) {
      showNotification("error", "Please enter email name", 4000);
      return;
    }

    let existingMail = this.state.emailsBackup.filter((d) => {
      return (
        d.name.trim().toLowerCase() ===
        this.state.emailName.trim().toLowerCase()
      );
    });

    if (existingMail.length > 0) {
      showNotification("error", "Email name already exists", 4000);
      return;
    }

    $(".modalAlertOverlay.emailModalOverlay,.modalAlert.emailModal").fadeOut(
      200
    );

    let selectedEmail = this.state.selectedEmail;

    /*eslint-disable*/
    let apiData = {
      name: this.state.emailName,
      description: selectedEmail.description,
      status: selectedEmail.type === "automated" ? "active" : "inactive",
      system: selectedEmail.system,
      email_type: selectedEmail.email_type,
      type: selectedEmail.type,
      send_date: selectedEmail.send_date,
      timezone: selectedEmail.timezone,
      delay: selectedEmail.delay,
      title: selectedEmail.title,
      content: getUpdatedEditorContent(
        selectedEmail.content,
        selectedEmail.html
      ),
      html: selectedEmail.html,
      tag_list: selectedEmail.tag_list,
      tag_list_excluded: selectedEmail.tag_list_excluded,
      generic_tag_list: selectedEmail.generic_tag_list,
    };
    /*eslint-enable*/

    callCreateEmail(apiData).then((d) => {
      if (d._id) {
        this.setState(
          {
            selectedEmail: {},
            emailName: "",
            keyFilter: Math.random(),
          },
          () => {
            showNotification("success", "Email created successfully", 2000);
            if (apiData.status === "inactive") {
              showNotification(
                "warning",
                "Note: This email cloned as INACTIVE.",
                5000
              );
            }
            this.onClickEditEmail(d);
          }
        );
      } else {
        showNotification("error", "Something went wrong", 4000);
      }
    });
  }

  renderNewEmailModal() {
    let name = "";
    if (this.state.selectedEmail.name) {
      name = this.state.selectedEmail.name;
    }
    return (
      <div>
        <div className="modalAlertOverlay emailModalOverlay" />
        <div className="modalAlert emailModal">
          <div className="clearfix">
            <div className="pull-left">
              <p>Clone Email - {name}</p>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div
                  className={
                    this.state.emailName !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Email Name</label>
                  <input
                    type="text"
                    name="emailName"
                    value={this.state.emailName}
                    onChange={(event) => this.handleInputChange(event)}
                  />
                </div>
                <div className="gap10" />
              </div>
            </div>
          </div>
          <div className="gap10" />

          <div className="alertFooterBtns">
            {/*eslint-disable*/}
            <a
              onClick={this.onClickCancelFromEmailModal.bind(this)}
              className="btn btn-default dismissThisModal margin-right-10">
              Cancel
            </a>
            <a
              onClick={this.onClickProcessFromEmailModal.bind(this)}
              className="btn btn-primary dismissThisModal">
              Create Email
            </a>
            {/*eslint-enable*/}
          </div>
        </div>
      </div>
    );
  }

  getEmailClass(status) {
    if (status === "inactive") {
      return "text-danger";
    } else if (status === "completed") {
      return "text-secondary";
    } else {
      return "";
    }
  }

  getFormattedTags(tags) {
    if (tags && tags.length > 0) {
      let ftags = tags.map((a) => a.name);
      return ftags.join(", ");
    } else {
      return "-";
    }
  }

  onClickArchiveEmail = (email) => {
    this.setState({ selectedEmail: email }, () => {
      $(
        ".modalAlertOverlay.confirmAlertOverlay,.modalAlert.confirmAlert"
      ).fadeIn(200);
    });
  };

  getTagsTitle(element) {
    let iTags = element.fincludedtags || [];
    let eTags = element.fexcludedtags || [];

    let iString = "";
    if (iTags.length > 0) {
      iString = `<label><b>Included :</b>  ${iTags
        .map((i) => i.name)
        .join(", ")}</label>`;
    }
    if (eTags.length > 0) {
      iString += `<br/><label><b>Excluded :</b>  ${eTags
        .map((i) => i.name)
        .join(", ")}</label>`;
    }

    return iString;
  }

  getEmailCount(emailInfo) {
    let count = 0;
    let fEmail = this.state.emailHistories.filter((d) => {
      return d.email_id === emailInfo._id;
    });
    if (fEmail.length > 0) {
      count = fEmail[0].count;
    }
    return count;
  }

  renderEmails() {
    var returnVals = [];
    let emails = this.state.emails || [];
    emails.forEach((element) => {
      let [isEdit, eventData] = this.checkEventLogs(element._id);
      isEdit = false;
      returnVals.push(
        <tr key={element._id} className={isEdit ? "edit-email" : ""}>
          <td>
            <Checkbox
              isChecked={this.state.emailCheckbox[element._id]}
              handleCheckboxChange={this.toggleSelectCheckbox.bind(
                this,
                element._id
              )}
              key={this.state.keySelectAll}
            />
          </td>
          <td className={this.getEmailClass(element.status)}>
            {element.name}
            <br />
            {element.status === "completed" && (
              <label className="text-completed">[Email Has Been Sent]</label>
            )}
            {isEdit && (
              <label className="text-secondary">
                [Email is being edited by {eventData.member_name}]
              </label>
            )}
          </td>
          <td>{element.title}</td>
          <td>{this.getFormattedTags(element.ftags)}</td>
          <td>
            <button
              onClick={this.onClickTagOverview.bind(this, element)}
              data-toggle="tooltip"
              title={this.getTagsTitle(element)}
              className="btn btn-default btn-sm margin-right-10 border-0">
              <i className="fa fa-info-circle" />
            </button>
          </td>
          <td>
            {element.type !== "automated"
              ? moment(element.send_date).format("Do, MMM YYYY")
              : "-"}
          </td>

          <td>{this.getEmailCount(element)}</td>
          <td className="min-width-100">
            <button
              title="Edit"
              onClick={this.onClickEditEmail.bind(this, element)}
              className="btn btn-default btn-sm margin-right-10 border-0">
              <i className="fa fa-pencil" />
            </button>
            {this.state.emailHistories.length > 0 && (
              <button
                title="Report"
                onClick={this.onClickReportEmail.bind(this, element)}
                className="btn btn-default btn-sm margin-right-10 border-0">
                <i className="fa fa-table" />
              </button>
            )}
            {element.email_filter_type !== "system" && (
              <button
                title="Clone"
                onClick={this.onClickCloneEmail.bind(this, element)}
                className="btn btn-default btn-sm border-0">
                <i className="fa fa-clone" />
              </button>
            )}
            <button
              title="Archive"
              onClick={this.onClickArchiveEmail.bind(this, element)}
              className="btn btn-danger btn-sm  border-0">
              <i className="fa fa-archive" />
            </button>
          </td>
        </tr>
      );
    });
    return returnVals;
  }

  onClickNoAlert = () => {
    $(
      ".modalAlertOverlay.confirmAlertOverlay,.modalAlert.confirmAlert"
    ).fadeOut(200);
  };

  onClickYesAlert = () => {
    callUpdateEmail(this.state.selectedEmail._id, { status: "archive" }).then(
      (d) => {
        $(
          ".modalAlertOverlay.confirmAlertOverlay,.modalAlert.confirmAlert"
        ).fadeOut(200);
        this.getEmails(1);
      }
    );
  };

  onClickCloseFromInfoModal() {
    $(".modalAlertOverlay.infoOverlay,.modalAlert.infoAlert").fadeOut(200);
  }

  renderInfoModal() {
    let modalHeight = window.innerHeight - 200;
    let iTags = this.state.selectedEmail.fincludedtags || [];
    let eTags = this.state.selectedEmail.fexcludedtags || [];

    return (
      <div>
        <div className="modalAlertOverlay infoOverlay" />
        <div className="modalAlert infoAlert modal-lg big">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title margin-right-10"
                id="exampleModalLabel">
                Tags
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.onClickCloseFromInfoModal.bind(this)}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div
              className="container-fluid modalInnerBody"
              style={{ maxHeight: modalHeight, overflowY: "hidden" }}>
              <Scrollbars
                autoHide
                autoHideTimeout={1000}
                autoHideDuration={200}
                style={{ height: modalHeight }}>
                <div className="gap20" />
                {iTags.length > 0 && (
                  <>
                    <h6>Included Tags</h6>
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Description</th>
                          <th>Name</th>
                        </tr>
                      </thead>
                      <tbody>
                        {iTags.map((i) => (
                          <tr key={i._id}>
                            <td>{i.description}</td>
                            <td>{i.name}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="gap10" />
                  </>
                )}

                {eTags.length > 0 && (
                  <>
                    <h6>Excluded Tags</h6>
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Name</th>
                        </tr>
                      </thead>
                      <tbody>
                        {eTags.map((i) => (
                          <tr key={i._id}>
                            <td>{i._id}</td>
                            <td>{i.name}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </>
                )}
              </Scrollbars>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderConfirmationAlert() {
    let selectedEmail = this.state.selectedEmail;
    let name = "";
    if (selectedEmail.name) {
      name = selectedEmail.name;
    }
    return (
      <div>
        <div className="modalAlertOverlay confirmAlertOverlay" />
        <div className="modalAlert confirmAlert">
          <p>{`Are you sure you want to archive "${name}" email?`}</p>
          <div className="alertFooterBtns">
            <button
              className="btn btn-default closeModal-No margin-right-10"
              onClick={this.onClickNoAlert}>
              No
            </button>
            <button
              className="btn btn-primary closeModal-Yes"
              onClick={this.onClickYesAlert}>
              Yes
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderFilterView() {
    return (
      <>
        <div className="list-group-item">
          <FilterList
            label={"Search Emails"}
            name={"search"}
            type={"search"}
            searchFields={["name", "title", "html"]}
            onFinish={this.filterUpdated.bind(this)}
          />
        </div>
        <div className="list-group-item">
          <p>Select a category</p>
          <FilterList
            filterList={this.state.emailCategories}
            name={"searchCategory"}
            type={"single"}
            searchFields={["type"]}
            onFinish={this.filterUpdated.bind(this)}
          />
        </div>
        <div className="list-group-item">
          <p>Select a type</p>
          <FilterList
            filterList={this.state.emailTypes}
            name={"searchType"}
            type={"single"}
            searchFields={["email_filter_type"]}
            onFinish={this.filterUpdated.bind(this)}
          />
        </div>
        <div className="list-group-item">
          <p>Select a status</p>
          <FilterList
            filterList={this.state.emailStatus}
            name={"searchStatus"}
            type={"single"}
            searchFields={["status"]}
            onFinish={this.filterUpdated.bind(this)}
            key={this.state.keyFilter}
          />
        </div>
        <div className="list-group-item">
          <p>Select a tag</p>
          <FilterList
            key={this.state.keyFilter}
            filterList={this.state.tags}
            name={"tags"}
            type={"multiple"}
            searchFields={["ftags->name"]}
            onFinish={this.filterUpdated.bind(this)}
          />
        </div>
      </>
    );
  }

  toggleSelectCheckbox = (id, check) => {
    let emailCheckbox = this.state.emailCheckbox;
    emailCheckbox[id] = check;
    let isSelectAll = Object.values(emailCheckbox).indexOf(false) === -1;
    this.setState(
      { emailCheckbox, isSelectAll, keySelectAll: Math.random() },
      () => {
        this.prepareRemoveGroups();
      }
    );
  };

  toggleSelectAll = (check) => {
    this.setState({
      isSelectAll: check,
      keySelectAll: Math.random(),
    });
    this.prepareEmailCheckbox(check);
  };

  onSubmitGroup = async (group, action) => {
    let data = {};
    if (action === "add") {
      /*eslint-disable*/
      data = {
        $addToSet: { generic_tag_list: group },
      };
    } else if (action === "remove") {
      /*eslint-disable*/
      data = {
        $pull: { generic_tag_list: group },
      };
    }
    let emailCheckbox = this.state.emailCheckbox;
    let ids = [];
    for (const key in emailCheckbox) {
      if (emailCheckbox.hasOwnProperty(key)) {
        const element = emailCheckbox[key];
        if (element) {
          ids.push(key);
        }
      }
    }
    await callUpdateEmailByQuery(data, {
      query: { _id: { $in: ids } },
    });
    showNotification(
      "success",
      `Group ${action === "add" ? "added" : "removed"} successfully`,
      4000
    );
    this.getEmails();
    /*eslint-enable*/
  };

  render() {
    let showActionFilter =
      Object.values(this.state.emailCheckbox).indexOf(true) > -1;
    return (
      <div>
        {this.renderNewEmailModal()}
        {this.renderConfirmationAlert()}
        {this.renderInfoModal()}
        <div className="container-fluid">
          <TitleBox title="Email Overview" />
          <div className="gap20" />
          <div className="card">
            <div className="list-group list-group-flush">
              {this.renderFilterView()}
              <div className="list-group-item">
                <button
                  className="btn btn-default btn-sm"
                  onClick={this.onClickViewArchivedEmails.bind(this)}>
                  View Archived Emails
                </button>
              </div>
              {showActionFilter && (
                <div className="list-group-item">
                  <ActionFilter
                    type={"viewEmail"}
                    groups={this.state.tagFilters}
                    removeGroups={this.state.removeGroups}
                    key={this.state.keyActionFilter}
                    onSubmit={this.onSubmitGroup}
                  />
                </div>
              )}
              {!this.state.showLoader && (
                <div className="list-group-item">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>
                          <Checkbox
                            isChecked={this.state.isSelectAll}
                            handleCheckboxChange={this.toggleSelectAll}
                            key={this.state.keySelectAll}
                          />
                        </th>
                        <th>Name</th>
                        <th>Subject</th>
                        <th>Group</th>
                        <th>Tags</th>
                        <th>Email Date</th>
                        <th>Emails Sent</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>{this.renderEmails()}</tbody>
                  </table>
                </div>
              )}
              {showActionFilter && (
                <div className="list-group-item">
                  <ActionFilter
                    groups={this.state.tagFilters}
                    key={this.state.keyActionFilter}
                    onSubmitGroup={this.onSubmitGroup}
                  />
                </div>
              )}
              {this.state.showLoader && (
                <div className="container-fluid">
                  <TableLoader />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.authReducer.userData,
  emails: state.emailReducer.emails,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ViewEmail);
