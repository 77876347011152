import * as api from "./api";
import { callApi } from "../helpers";

function callCreateBtpQuestionnaire(questionnaireData) {
  return callApi(api.service("btp-questionnaire").create(questionnaireData));
}

function callGetBtpQuestionnaire() {
  return callApi(api.service("btp-questionnaire").find({}));
}

export { callCreateBtpQuestionnaire, callGetBtpQuestionnaire };
