import * as api from "./api";
import { callApi } from "../helpers";

function callCreateChlgQue(data) {
  return callApi(api.service("challenge-question").create(data));
}

function callUpdateChlgQue(id, data) {
  return callApi(api.service("challenge-question").patch(id, data));
}

function callGetChlgQues() {
  return callApi(api.service("challenge-question").find({}));
}

function callGetChlgQueById(id) {
  return callApi(api.service("challenge-question").get(id));
}

function callGetQuestionsUsingQuery(query, isLoading = true) {
  return callApi(api.service("challenge-question").find(query), isLoading);
}

function callCreateChlgItem(data) {
  return callApi(api.service("challenge-item").create(data));
}

function callUpdateChlgItem(id, data) {
  return callApi(api.service("challenge-item").patch(id, data));
}

function callCloneChlgItem(id, data) {
  return callApi(api.service("challenge-item/clone").patch(id, data));
}

function callGetChlgItems() {
  return callApi(api.service("challenge-item").find({}));
}

function callGetChlgItemsByQuery(query) {
  return callApi(api.service("challenge-item").find(query));
}

function callGetChlgItemById(id) {
  return callApi(api.service("challenge-item").get(id));
}

function callCreateChlg(data) {
  return callApi(api.service("challenge").create(data));
}

function callUpdateChlg(id, data) {
  return callApi(api.service("challenge").patch(id, data));
}

function callGetChlgs() {
  return callApi(api.service("challenge").find({}));
}

function callGetChlgById(id) {
  return callApi(api.service("challenge").get(id));
}

function callGetChlgsByQuery(query, isLoading = true) {
  return callApi(api.service("challenge").find(query), isLoading);
}

function callCreateMemberChallenge(data) {
  return callApi(api.service("member-challenge").create(data));
}

function callUpdateMemberChallenge(id, data) {
  return callApi(api.service("member-challenge").patch(id, data));
}

function callMemberChallengeById(id) {
  return callApi(api.service("member-challenge").get(id));
}

function callMemberChallengeByQuery(query) {
  return callApi(api.service("member-challenge").find(query));
}

function callCreateAtq(data) {
  return callApi(api.service("member-quiz-result").create(data));
}

function callDeleteAtqByQuery(query) {
  return callApi(api.service("member-quiz-result").remove(null, query));
}

function callGetAtqByQuery(query) {
  return callApi(api.service("member-quiz-result").find(query));
}

function callCreateMessage(data) {
  return callApi(api.service("message").create(data));
}

function callUpdateMessage(id, data) {
  return callApi(api.service("message").patch(id, data));
}

function callGetMessageByQuery(query) {
  return callApi(api.service("message").find(query));
}

function callCreateProgressJournal(data) {
  return callApi(api.service("progress-journal").create(data));
}

function callUpdateProgressJournal(id, data) {
  return callApi(api.service("progress-journal").patch(id, data));
}

function callDeleteProgressJournalByQuery(id) {
  return callApi(api.service("progress-journal").remove(id));
}

function callGetProgressJournalByQuery(query) {
  return callApi(api.service("progress-journal").find(query));
}

function callCreateChallengeGroup(data) {
  return callApi(api.service("challenge-group").create(data));
}

function callUpdateChallengeGroup(id, data) {
  return callApi(api.service("challenge-group").patch(id, data));
}

function callDeleteChallengeGroup(id) {
  return callApi(api.service("challenge-group").remove(id));
}

function callGetChallengGroupByQuery(query) {
  return callApi(api.service("challenge-group").find(query));
}

function callGetMemberChallengGroupByQuery(query) {
  return callApi(api.service("member-challenge-group").find(query));
}

function callUpdateMemberChallengGroup(id, data) {
  return callApi(api.service("member-challenge-group").patch(id, data));
}

function callProcessChallengeRules(query) {
  return api.service("general").get("process-challenge-rule", {
    query: query,
  });
}

function callOrderProcess(data) {
  return callApi(api.service("general").create(data));
}

export {
  callCreateChlgQue,
  callUpdateChlgQue,
  callGetChlgQues,
  callCreateChlgItem,
  callUpdateChlgItem,
  callGetChlgItems,
  callCreateChlg,
  callUpdateChlg,
  callGetChlgs,
  callUpdateMemberChallenge,
  callCreateAtq,
  callDeleteAtqByQuery,
  callGetAtqByQuery,
  callCreateMessage,
  callUpdateMessage,
  callGetMessageByQuery,
  callCreateProgressJournal,
  callDeleteProgressJournalByQuery,
  callGetProgressJournalByQuery,
  callUpdateProgressJournal,
  callGetChlgItemById,
  callMemberChallengeByQuery,
  callGetChlgsByQuery,
  callCreateChallengeGroup,
  callUpdateChallengeGroup,
  callDeleteChallengeGroup,
  callGetChallengGroupByQuery,
  callGetMemberChallengGroupByQuery,
  callGetChlgById,
  callCreateMemberChallenge,
  callUpdateMemberChallengGroup,
  callProcessChallengeRules,
  callMemberChallengeById,
  callGetQuestionsUsingQuery,
  callOrderProcess,
  callGetChlgItemsByQuery,
  callGetChlgQueById,
  callCloneChlgItem,
};
