import * as api from "./api";
import { callApi } from "../helpers";

function callCreateMarciCircle(data) {
  return callApi(api.service("marci-circle").create(data));
}

function callUpdateMarciCircle(id, data) {
  return callApi(api.service("marci-circle").patch(id, data));
}

function callGetMarciCircles(query) {
  return callApi(api.service("marci-circle").find(query));
}

function callGetMarciCircleUsingId(id) {
  return callApi(api.service("marci-circle").get(id));
}

function callDeleteMarciCircleUsingId(id) {
  return callApi(api.service("marci-circle").remove(id));
}

function callCreateSpotlight(data) {
  return callApi(api.service("spotlight").create(data));
}

function callUpdateSpotlight(id, data) {
  return callApi(api.service("spotlight").patch(id, data));
}

function callGetSpotlights() {
  return callApi(api.service("spotlight").find({}));
}

function callGetSpotlightsUsingQuery(query) {
  return callApi(api.service("spotlight").find(query));
}

function callCreateMCQuestion(data) {
  return callApi(api.service("marci-circle-atq").create(data));
}

function callUpdateMCQuestion(id, data) {
  return callApi(api.service("marci-circle-atq").patch(id, data));
}

function callGetMCQuestion() {
  return callApi(api.service("marci-circle-atq").find({}));
}

function callCreateMovement(data) {
  return callApi(api.service("movement").create(data));
}

function callUpdateMovement(id, data) {
  return callApi(api.service("movement").patch(id, data));
}

function callGetMovements() {
  return callApi(api.service("movement").find({}));
}

function callGetMovementUsingQuery(query) {
  return callApi(api.service("movement").find(query));
}

function callDeleteMovementUsingId(id) {
  return callApi(api.service("movement").remove(id));
}

function callGetMovementUsingId(id) {
  return callApi(api.service("movement").get(id));
}

function callProcessCancelMarciCircle(query) {
  return api.service("general").get("process-cancel-marci-circle", {
    query: query,
  });
}

function callCreateChurnItem(data) {
  return callApi(api.service("churn-item").create(data));
}

function callUpdateChurnItem(id, data) {
  return callApi(api.service("churn-item").patch(id, data));
}

function callGetChurnItems() {
  return callApi(api.service("churn-item").find({}));
}

function callGetChurnItemsUsingQuery(query) {
  return callApi(api.service("churn-item").find(query));
}

function callDeleteChurnItemUsingId(id) {
  return callApi(api.service("churn-item").remove(id));
}

function callGetChurnItemUsingId(id) {
  return callApi(api.service("churn-item").get(id));
}

function callCreateEducation(data) {
  return callApi(api.service("education").create(data));
}

function callUpdateEducation(id, data) {
  return callApi(api.service("education").patch(id, data));
}

function callGetEducations() {
  return callApi(api.service("education").find({}));
}

function callGetEducationUsingQuery(query) {
  return callApi(api.service("education").find(query));
}

function callDeleteEducationUsingId(id) {
  return callApi(api.service("education").remove(id));
}

function callGetEducationUsingId(id) {
  return callApi(api.service("education").get(id));
}

export {
  callCreateMarciCircle,
  callGetMarciCircles,
  callUpdateMarciCircle,
  callGetMarciCircleUsingId,
  callCreateSpotlight,
  callUpdateSpotlight,
  callGetSpotlights,
  callGetSpotlightsUsingQuery,
  callCreateMCQuestion,
  callUpdateMCQuestion,
  callGetMCQuestion,
  callDeleteMarciCircleUsingId,
  callCreateMovement,
  callUpdateMovement,
  callGetMovements,
  callDeleteMovementUsingId,
  callGetMovementUsingId,
  callGetMovementUsingQuery,
  callProcessCancelMarciCircle,
  callCreateChurnItem,
  callGetChurnItemUsingId,
  callGetChurnItems,
  callGetChurnItemsUsingQuery,
  callUpdateChurnItem,
  callDeleteChurnItemUsingId,
  callCreateEducation,
  callUpdateEducation,
  callGetEducations,
  callGetEducationUsingQuery,
  callDeleteEducationUsingId,
  callGetEducationUsingId,
};
