import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Scrollbars } from "react-custom-scrollbars";
import * as $ from "jquery";
import moment from "moment-timezone";
import DateTimePicker from "react-datetime-picker";

import TableLoader from "../../../../components/loader/table";
// import FilterList from "../../../../components/form/filterList";
import TitleBox from "../../../../components/general/titleBox";
import RadioTag from "../../../../components/form/radioTag";

import { filterByTags } from "../../../../helpers/filters";
import {
  callGetUsersByQuery,
  callGetAffiliateReport,
} from "../../../../services";
import BarChartHorizontal from "../../../../components/chart/barChartHorizontal";
import { exportCSV } from "../../../../helpers/exportCSV";

class AffiliateReports extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      filterList: [],
      affiliateDetails: [],
      affiliateDetailsBackup: [],
      showLoader: true,
      users: [],
      selectedDateFilter: "last-30",
      keyFilter: Math.random(),
      startFilterDate: new Date(),
      endFilterDate: new Date(),
      keyFilterDate: Math.random(),
      dateFilters: [
        {
          name: "All",
          value: "all",
        },
        {
          name: "Today",
          value: "today",
        },
        {
          name: "Yesterday",
          value: "yesterday",
        },
        {
          name: "Last 7 Days",
          value: "last-7",
        },
        {
          name: "Last 30 Days",
          value: "last-30",
        },
        {
          name: "Custom",
          value: "custom",
        },
      ],
      keyChart: Math.random(),
      affiliateNames: [],
      affiliatePayments: [],
      selectedElement: {},
    };
  }

  componentDidMount() {
    this.callGetAffiliates();
  }

  async callGetAffiliates() {
    let response = await callGetAffiliateReport();

    this.setState(
      {
        affiliateDetails: response.affiliateData,
        affiliateDetailsBackup: response.affiliateData,
        showLoader: false,
      },
      () => {
        var affiDetails = this.filterByDate(response.affiliateData);
        this.setState({
          affiliateDetails: affiDetails,
        });
      }
    );
  }

  prepareChartData(affiliateDetails) {
    let affiliateNames = [];
    let affiliatePayments = [];

    affiliateDetails.forEach((element) => {
      affiliateNames.push(element.name);
      affiliatePayments.push(element.numOfOrders);
    });
    this.setState({
      affiliateNames: affiliateNames,
      affiliatePayments: affiliatePayments,
      keyChart: Math.random(),
    });
  }

  filterByDate(affiliateDetails) {
    let dateFilter = this.state.selectedDateFilter;
    let details = [];

    let startDate = moment().subtract(1, "days").format("DD-MM-YYYY");
    let endDate = moment().format("DD-MM-YYYY");
    let inDateOrders = [];

    switch (dateFilter) {
      case "all":
        details = this.state.affiliateDetailsBackup;
        break;
      case "today":
        affiliateDetails.forEach((d) => {
          inDateOrders = d.orders.filter((element) => {
            return (
              moment(element.createdAt).format("DD-MM-YYYY") ===
              moment().format("DD-MM-YYYY")
            );
          });
          if (inDateOrders.length > 0) {
            d.numOfOrders = inDateOrders.length;
            details.push(d);
          }
        });
        break;
      case "yesterday":
        startDate = moment().subtract(1, "days").toDate().getTime();
        endDate = moment().toDate().getTime();
        affiliateDetails.forEach((d) => {
          inDateOrders = d.orders.filter((element) => {
            return (
              moment(element.createdAt).toDate().getTime() >= startDate &&
              moment(element.createdAt).toDate().getTime() <= endDate
            );
          });
          if (inDateOrders.length > 0) {
            d.numOfOrders = inDateOrders.length;
            details.push(d);
          }
        });
        break;
      case "last-7":
        startDate = moment().subtract(7, "days").toDate().getTime();
        endDate = moment().toDate().getTime();

        affiliateDetails.forEach((d) => {
          inDateOrders = d.orders.filter((element) => {
            return (
              moment(element.createdAt).toDate().getTime() >= startDate &&
              moment(element.createdAt).toDate().getTime() <= endDate
            );
          });
          if (inDateOrders.length > 0) {
            d.numOfOrders = inDateOrders.length;
            details.push(d);
          }
        });
        break;
      case "last-30":
        startDate = moment().subtract(30, "days").toDate().getTime();
        endDate = moment().toDate().getTime();

        affiliateDetails.forEach((d) => {
          inDateOrders = d.orders.filter((element) => {
            return (
              moment(element.createdAt).toDate().getTime() >= startDate &&
              moment(element.createdAt).toDate().getTime() <= endDate
            );
          });
          if (inDateOrders.length > 0) {
            d.numOfOrders = inDateOrders.length;
            details.push(d);
          }
        });
        break;
      case "custom":
        startDate = moment(this.state.startFilterDate).toDate().getTime();
        endDate = moment(this.state.endFilterDate).toDate().getTime();

        affiliateDetails.forEach((d) => {
          inDateOrders = d.orders.filter((element) => {
            return (
              moment(element.createdAt).toDate().getTime() >= startDate &&
              moment(element.createdAt).toDate().getTime() <= endDate
            );
          });
          if (inDateOrders.length > 0) {
            d.numOfOrders = inDateOrders.length;
            details.push(d);
          }
        });

        break;

      default:
        break;
    }

    this.prepareChartData(details);
    return details;
  }

  filterUpdated(updatedFilter, name, type, searchFields) {
    let filterList = this.state.filterList;
    filterList[name] = {
      name: name,
      type: type,
      searchFields: searchFields,
      values: updatedFilter,
    };
    this.setState(
      {
        filterList: filterList,
      },
      () => {
        let data = filterByTags(this.state.affiliateDetailsBackup, filterList);
        this.setState({
          affiliateDetails: data,
        });
      }
    );
  }

  onClickFilterDate(item, id) {
    let value = item[Object.keys(item)[0]].value;
    this.setState(
      { selectedDateFilter: value, keyFilter: Math.random() },
      () => {
        if (value === "custom") {
          $(
            ".modalAlertOverlay.filterDateTimeOverlay,.modalAlert.filterDateTimeAlert"
          ).fadeIn(200);
          this.setState({ keyFilterDate: Math.random() });
        } else {
          var affiDetails = this.filterByDate(
            this.state.affiliateDetailsBackup
          );
          this.setState({
            affiliateDetails: affiDetails,
          });
        }
      }
    );
  }

  onClickPayNowAllAffiliates() {
    this.props.history.push("/admin/add-member-payment");
  }

  renderAffiliates() {
    let data = this.state.affiliateDetails;
    var returnVals = [];
    data.forEach((element) => {
      returnVals.push(
        <tr>
          <td>{element.name}</td>
          <td>{element.email}</td>
          <td>{element.parentName}</td>
          <td>{element.commission}</td>
          <td>
            {element.totalClicks}/{element.uniqueClicks}
          </td>
          <td>
            <label
              className="link pointer-click"
              onClick={this.onClickUsers.bind(this, element)}>
              {element.numOfOrders}
            </label>
          </td>
          <td>{element.amountOwed}</td>
          <td>{element.amountPaid}</td>
        </tr>
      );
    });

    return returnVals;
  }

  onClickCloseFromUsersModal() {
    $(".modalAlertOverlay.usersOverlay,.modalAlert.usersAlert").fadeOut(200);
  }

  onClickMemberName(element) {
    $(".modalAlertOverlay.usersOverlay,.modalAlert.usersAlert").fadeOut(200);
    this.props.history.push(`/admin/edit-user?id=${element._id}`);
  }

  async onClickUsers(element) {
    let userIds = element.userIds || [];
    let users = [];
    if (userIds.length > 0) {
      let userInfo = await callGetUsersByQuery({
        query: {
          _id: { $in: userIds },
          $select: ["_id", "name", "email_address"],
        },
      });
      if (userInfo.data.length > 0) {
        users = userInfo.data;
      }
    }
    this.setState({ users: users, selectedElement: element }, () => {
      $(".modalAlertOverlay.usersOverlay,.modalAlert.usersAlert").fadeIn(200);
    });
  }

  onClickExport() {
    let affiliateDetails = this.state.affiliateDetails;

    let rows = [];

    let headers = [
      { id: "name", display: "Name" },
      { id: "email", display: "Email Address" },
      { id: "parentName", display: "Parent Name" },
      { id: "commission", display: "Commission" },
      { id: "clicks", display: "Total/Unique Clicks" },
      { id: "numOfOrders", display: "Number of Orders" },
      { id: "amountOwed", display: "Amount Owed" },
      { id: "amountPaid", display: "Amount Paid" },
    ];
    affiliateDetails.forEach((element) => {
      rows.push({
        name: element.name,
        email: element.email,
        parentName: element.commission,
        commission: element.commission,
        clicks: `${element.totalClicks}/${element.uniqueClicks}`,
        numOfOrders: element.numOfOrders,
        amountOwed: element.amountOwed,
        amountPaid: element.amountPaid,
      });
    });
    exportCSV(headers, rows, "affiliates");
  }

  renderUsersModal() {
    let modalHeight = window.innerHeight - 200;
    let allUsers = this.state.users;

    if (allUsers.length === 0) {
      return null;
    }

    let selectedElement = this.state.selectedElement;

    var self = this;

    function renderUserRow() {
      let returnVals = [];
      for (let i = 0; i < allUsers.length; i++) {
        const user = allUsers[i];

        let numOfOrders = selectedElement.orders.filter(
          (d) => d.member_id === user._id
        ).length;

        returnVals.push(
          <tr key={user._id}>
            <td>{user.name}</td>
            <td>{user.email_address}</td>
            <td>{numOfOrders}</td>
            <td>
              <button
                className="btn btn-default btn-sm border-0"
                onClick={self.onClickMemberName.bind(self, user)}>
                <i className="fa fa-eye" />
              </button>
            </td>
          </tr>
        );
      }
      return returnVals;
    }

    return (
      <div>
        <div className="modalAlertOverlay usersOverlay" />
        <div className="modalAlert usersAlert modal-lg big">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Users
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.onClickCloseFromUsersModal.bind(this)}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div
              className="container-fluid modalInnerBody"
              style={{ maxHeight: modalHeight, overflowY: "hidden" }}>
              <Scrollbars
                autoHide
                autoHideTimeout={1000}
                autoHideDuration={200}
                style={{ height: modalHeight }}>
                <div className="gap20" />
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>No Of Orders</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>{renderUserRow()}</tbody>
                </table>
              </Scrollbars>
            </div>
          </div>
        </div>
      </div>
    );
  }

  onChangeFilterDate(name, date) {
    this.setState({
      [name]: date,
    });
  }

  onClickCloseFromFilterDateModal() {
    $(
      ".modalAlertOverlay.filterDateTimeOverlay,.modalAlert.filterDateTimeAlert"
    ).fadeOut(200);
  }

  onClickDoneFromFilterDateModal() {
    $(
      ".modalAlertOverlay.filterDateTimeOverlay,.modalAlert.filterDateTimeAlert"
    ).fadeOut(200);
    var affiDetails = this.filterByDate(this.state.affiliateDetailsBackup);
    this.setState({
      affiliateDetails: affiDetails,
    });
  }

  renderCustomDateModal() {
    return (
      <div>
        <div className="modalAlertOverlay filterDateTimeOverlay" />
        <div className="modalAlert filterDateTimeAlert">
          <p> Filter By Date </p>
          <div className="container-fluid">
            <div className="row">
              <div className="col-6">
                <label className="inputFakeLabelFocussed">
                  Select start datetime
                </label>
                <div>
                  <DateTimePicker
                    key={this.state.keyFilterDate}
                    value={this.state.startFilterDate}
                    onChange={this.onChangeFilterDate.bind(
                      this,
                      "startFilterDate"
                    )}
                  />
                </div>
              </div>
              <div className="col-6">
                <label className="inputFakeLabelFocussed">
                  Select end datetime
                </label>
                <div>
                  <DateTimePicker
                    key={this.state.keyFilterDate}
                    value={this.state.endFilterDate}
                    onChange={this.onChangeFilterDate.bind(
                      this,
                      "endFilterDate"
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="alertFooterBtns">
            {/*eslint-disable*/}
            <a
              className="btn btn-default dismissThisModal margin-right-10"
              onClick={this.onClickCloseFromFilterDateModal.bind(this)}>
              Close
            </a>
            <a
              className="btn btn-primary dismissThisModal"
              onClick={this.onClickDoneFromFilterDateModal.bind(this)}>
              Done
            </a>
            {/*eslint-enable*/}
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="container-fluid">
        {this.renderUsersModal()}
        {this.renderCustomDateModal()}
        <TitleBox title="Affiliate Sales Report" showBackBtn={true} />
        <div className="gap20" />
        <div className="card">
          <div className="container-fluid">
            <div className="gap20" />
            <BarChartHorizontal
              key={this.state.keyChart}
              data={this.state.affiliatePayments}
              labels={this.state.affiliateNames}
              label={"Number Of Orders"}
            />
          </div>
        </div>
        <div className="card">
          <div className="list-group list-group-flush">
            {/* <div className="list-group-item">
              <FilterList
                label={"Search Users"}
                name={"search"}
                type={"search"}
                searchFields={["name", "email"]}
                onFinish={this.filterUpdated.bind(this)}
              />
            </div> */}
            <div className="list-group-item">
              <p>Filter by Orders</p>
              <div className="tabsHolder radioTabs">
                <RadioTag
                  onCheckChange={this.onClickFilterDate.bind(this)}
                  labelList={this.state.dateFilters}
                  selectType={"value"}
                  selectedList={[this.state.selectedDateFilter]}
                  key={this.state.keyFilter}
                />
              </div>
            </div>
            {!this.state.showLoader && (
              <>
                <div className="list-group-item">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Parent Affiliate</th>
                        <th>Commission</th>
                        <th>Total/Unique Clicks</th>
                        <th>Number of Orders</th>
                        <th>Amount Owed</th>
                        <th>Amount Paid</th>
                      </tr>
                    </thead>
                    <tbody>{this.renderAffiliates()}</tbody>
                  </table>
                </div>
                <div className="gap20" />
              </>
            )}
            {this.state.showLoader && (
              <div className="container-fluid">
                <TableLoader />
              </div>
            )}
            <div className="container-fluid">
              <div className="text-right">
                <button
                  className="btn btn-primary btn-sm margin-right-10"
                  onClick={this.onClickExport.bind(this)}>
                  Export
                </button>
                <button
                  onClick={this.onClickPayNowAllAffiliates.bind(this)}
                  className="btn btn-sm btn-primary">
                  Pay Affiliates
                </button>
              </div>
              <div className="gap20" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AffiliateReports);
