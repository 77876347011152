import React from "react";

import RadioTag from "../../../components/form/radioTag";

import { SortableLeadFieldList } from "../../../components/form/sortableList";
import { arrayMove } from "react-sortable-hoc/dist/commonjs/utils";
import { showNotification } from "../../../helpers";
import {
  callGetSettings,
  callSaveSettings,
} from "../../../services/settingService";
import TitleBox from "../../../components/general/titleBox";
import Checkbox from "../../../components/form/checkBox";

class LeadSettings extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      settingId: "",
      customFields: [],
      fieldLabel: "",
      fieldValue: "",
      fieldType: "text",
      fieldFilter: "yes",
      fieldId: "",
      fieldShow: false,
      fieldFromLandingPage: false,
      keyLandingPage: Math.random(),
      fieldFromLandingPageName: "",
      keyFieldShow: Math.random(),
      selectedFieldIndex: -1,
      keyRadioSelect: Math.random(),
    };
  }

  componentDidMount() {
    this.getSettings();
  }

  async getSettings() {
    let d = await callGetSettings("leadsettings");
    let settings = d.data;
    let leadSettings = settings.lead_settings || {};
    let customFields = leadSettings.custom_fields || [];

    this.setState({ customFields: customFields, settingId: settings._id });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  async onClickSaveInfo() {
    /*eslint-disable*/
    let data = {
      id: this.state.settingId,
      type: "lead_settings",
      lead_settings: {
        custom_fields: this.state.customFields,
      },
    };
    /*eslint-enable*/
    let updatedData = await callSaveSettings(data);
    if (updatedData.status) {
      showNotification("success", updatedData.message, 3000);
    } else {
      showNotification("error", updatedData.message, 5000);
    }
  }

  onClickAddField() {
    let customFields = this.state.customFields;

    if (this.state.fieldLabel.trim().length === 0) {
      showNotification("error", "label is required", 4000);
      return;
    }

    let id = Math.random().toString(36).substr(2);

    let field = {
      label: this.state.fieldLabel,
      value: this.state.fieldValue,
      type: this.state.fieldType,
      filter: this.state.fieldFilter,
      show: this.state.fieldShow,
      pageField: this.state.fieldFromLandingPage,
      pageFieldName: this.state.fieldFromLandingPageName,
      id: id,
    };

    if (this.state.selectedFieldIndex >= 0) {
      field.id = this.state.fieldId;
      customFields[this.state.selectedFieldIndex] = field;
    } else {
      customFields.push(field);
    }

    this.setState({
      customFields: customFields,
      selectedFieldIndex: -1,
      fieldLabel: "",
      fieldType: "single",
      fieldValue: "",
      fieldFilter: "yes",
      fieldId: "",
      fieldShow: false,
      keyFieldShow: Math.random(),
      fieldFromLandingPage: false,
      fieldFromLandingPageName: "",
      keyLandingPage: Math.random(),
    });
  }

  onSortEndFields = ({ oldIndex, newIndex }) => {
    let customFields = this.state.customFields;
    customFields = arrayMove(customFields, oldIndex, newIndex);

    this.setState({
      customFields: customFields,
    });
  };

  onPressRemoveField(e) {
    let index = e.target.dataset["value"];
    let customFields = this.state.customFields;
    customFields.splice(index, 1);
    this.setState({
      customFields: customFields,
    });
  }

  onPressEditField(e) {
    let index = e.target.dataset["value"];
    let customFields = this.state.customFields;

    let customField = customFields[index];

    this.setState({
      selectedFieldIndex: index,
      fieldLabel: customField.label,
      fieldType: customField.type || "text",
      fieldValue: customField.value || "",
      fieldFilter: customField.filter || "yes",
      fieldId: customField.id,
      fieldShow: customField.show,
      keyFieldShow: Math.random(),
      keyRadioSelect: Math.random(),
      fieldFromLandingPage: customField.pageField,
      fieldFromLandingPageName: customField.pageFieldName || "",
      keyLandingPage: Math.random(),
    });
  }

  onClickCancelField() {
    this.setState({
      selectedFieldIndex: -1,
      fieldLabel: "",
      fieldType: "text",
      fieldValue: "",
      fieldId: "",
      fieldFilter: "yes",
      fieldShow: false,
      keyFieldShow: Math.random(),
      keyRadioSelect: Math.random(),
      fieldFromLandingPage: false,
      keyLandingPage: Math.random(),
      fieldFromLandingPageName: "",
    });
  }

  onSelectRadioSelect(item, id) {
    let value = item[Object.keys(item)[0]].value;
    this.setState({ [id]: value });
  }

  toggleShowField = (check) => {
    this.setState({
      fieldShow: check,
    });
  };

  toggleLandingPage = (check) => {
    this.setState({
      fieldFromLandingPage: check,
    });
  };

  renderFieldList() {
    return (
      <>
        <div className="text-right">
          <button
            className="btn btn-primary addQuestionButton"
            onClick={() => this.onClickAddField()}>
            {this.state.selectedFieldIndex === -1
              ? "Add Field"
              : "Update Field"}
          </button>
        </div>
        <div className="gap20" />

        <SortableLeadFieldList
          distance={10}
          items={this.state.customFields}
          onSortEnd={this.onSortEndFields}
          onPressEdit={this.onPressEditField.bind(this)}
          onPressRemove={this.onPressRemoveField.bind(this)}
        />
        <div className="gap20" />
      </>
    );
  }

  render() {
    return (
      <div>
        <div className="container-fluid">
          <TitleBox title="Lead Settings" />
          <div className="gap20" />
          <div className="card">
            <div className="container-fluid">
              <div className="gap20" />

              <div className="havingHelpText">
                <h5 className="noMargin">Custom Fields</h5>
              </div>

              <div className="gap20" />

              <div className="row">
                <div className="col-md-6">
                  <label>Type</label>
                  <div className="tabsHolder radioTabs">
                    <RadioTag
                      onCheckChange={this.onSelectRadioSelect.bind(this)}
                      labelList={[
                        { name: "Text", value: "text" },
                        { name: "Dropdown", value: "dropdown" },
                      ]}
                      key={this.state.keyRadioSelect}
                      id={"fieldType"}
                      selectType={"value"}
                      selectedList={[this.state.fieldType]}
                    />
                  </div>
                  <div className="gap20" />
                  <div
                    className={
                      this.state.fieldLabel !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>Label</label>
                    <input
                      type="text"
                      className="offsetDays"
                      name="fieldLabel"
                      value={this.state.fieldLabel}
                      onChange={(event) => this.handleInputChange(event)}
                    />
                  </div>
                  <div className="gap20" />
                  {this.state.fieldType === "dropdown" && (
                    <>
                      <div
                        className={
                          this.state.fieldValue !== ""
                            ? "mdInput mdFocussed"
                            : "mdInput"
                        }>
                        <label>Value</label>
                        <textarea
                          className="offsetDays"
                          name="fieldValue"
                          value={this.state.fieldValue}
                          onChange={(event) => this.handleInputChange(event)}
                        />
                      </div>
                      <div className="gap20" />
                      <div className="tabsHolder radioTabs">
                        <RadioTag
                          onCheckChange={this.onSelectRadioSelect.bind(this)}
                          labelList={[
                            { name: "Filterable", value: "yes" },
                            { name: "Not Filterable", value: "no" },
                          ]}
                          key={this.state.keyRadioSelect}
                          id={"fieldFilter"}
                          selectType={"value"}
                          selectedList={[this.state.fieldFilter]}
                        />
                      </div>
                      <div className="gap20" />
                    </>
                  )}
                  <div className="gap20" />
                  <Checkbox
                    label={"Show on Leads Page"}
                    isChecked={this.state.fieldShow}
                    handleCheckboxChange={this.toggleShowField}
                    key={this.state.keyFieldShow}
                  />
                  <div className="gap20" />
                  <Checkbox
                    label={"Capture this value from landing page"}
                    isChecked={this.state.fieldFromLandingPage}
                    handleCheckboxChange={this.toggleLandingPage}
                    key={this.state.keyLandingPage}
                  />
                  {this.state.fieldFromLandingPage && (
                    <>
                      <div className="gap20" />
                      <div
                        className={
                          this.state.fieldFromLandingPageName !== ""
                            ? "mdInput mdFocussed"
                            : "mdInput"
                        }>
                        <label>Landing Page Field Name</label>
                        <input
                          type="text"
                          className="offsetDays"
                          name="fieldFromLandingPageName"
                          value={this.state.fieldFromLandingPageName}
                          onChange={(event) => this.handleInputChange(event)}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>

              {this.renderFieldList()}

              {this.state.selectedFieldIndex === -1 && (
                <div className="text-right">
                  <button
                    className="btn btn-primary"
                    onClick={this.onClickSaveInfo.bind(this)}>
                    Save Custom Fields
                  </button>
                  <div className="gap10" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LeadSettings;
