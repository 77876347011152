import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { arrayMove } from "react-sortable-hoc";
import { VelocityTransitionGroup } from "velocity-react";
import $ from "jquery";

import Checkbox from "../../../components/form/checkBox";
import TitleBox from "../../../components/general/titleBox";
import RadioTag from "../../../components/form/radioTag";
import { resetStatus } from "../../../modules/actions/index";
import { SortableAnswerList } from "../../../components/form/sortableList";
import {
  getParameterByName,
  getUniqueId,
  showNotification,
} from "../../../helpers";
import {
  callCreateChlgQue,
  callUpdateChlgQue,
  callGetSystemTags,
  callGetGenericTags,
  callCreateGenericTag,
  callUpdateGenericTag,
  callGetChlgQueById,
} from "../../../services";
import HelpTextDiv from "../../../components/form/helpTextDiv";
import TagSelect from "../../../components/form/tagSelect";
import TagUsers from "../../../components/form/tagUsers";
import SelectScreen from "../../../components/form/selectScreen";
import { DraftEditor } from "../../../packages/draft-editor";

class AddChallengeQuestion extends React.Component {
  constructor(props) {
    super(props);

    this.props = props;

    let id = getParameterByName("id") || "";

    let challengeQuestion = {
      _id: 0,
      name: this.props.questionName || "",
      question: this.props.questionName || "",
      description: this.props.questionName || "",
      itemType: "regular",
      answerType: "single",
      showChart: false,
      answerList: [],
      submissionLimit: "",
      submissionLimitDays: [],
      submissionLimitTags: [],
      submissionType: "days",
      sLimitTags: [],
      inactiveLimitTags: true,
      selectedIncludedTags: [],
      selectedExcludedTags: [],
      enableAccountability: this.props.newQuestionType === "atq" ? true : false,
      textFormSubmission: "leave",
      textIgnoreSubmission: false,
      textAddTags: [],
      textInactiveTags: [],
      selectedTextAddTags: [],
      selectedTextInactiveTags: [],
      sTextAddTags: [],
      sTextInactiveTags: [],
      group: "",
      priority: 0,
    };
    let showLimitSubmission = false;

    if (this.props.history && this.props.history.location.state !== undefined) {
      let chlgQuestion = this.props.history.location.state.challengeQuestion;

      challengeQuestion._id = chlgQuestion._id;
      challengeQuestion.name = chlgQuestion.name;
      challengeQuestion.question = chlgQuestion.question;
      challengeQuestion.description = chlgQuestion.description;
      challengeQuestion.itemType = chlgQuestion.item_type;
      challengeQuestion.answerType = chlgQuestion.answer_type;
      challengeQuestion.showChart = chlgQuestion.show_chart;
      challengeQuestion.answerList = chlgQuestion.answer_list;
      challengeQuestion.priority = chlgQuestion.priority || 0;

      challengeQuestion.submissionType = chlgQuestion.submission_type || "days";

      if (challengeQuestion.submissionType === "days") {
        challengeQuestion.submissionLimit = chlgQuestion.submission_limit || "";
        if (challengeQuestion.submissionLimit) {
          showLimitSubmission = true;
        }
      } else if (challengeQuestion.submissionType === "week") {
        challengeQuestion.submissionLimitDays =
          chlgQuestion.submission_limit_days || [];
        if (challengeQuestion.submissionLimitDays.length > 0) {
          showLimitSubmission = true;
        }
      } else {
        challengeQuestion.sLimitTags = chlgQuestion.submission_limit_tags || [];
        if (challengeQuestion.sLimitTags.length > 0) {
          showLimitSubmission = true;
          challengeQuestion.inactiveLimitTags =
            chlgQuestion.inactive_limit_tags;
        }
      }

      challengeQuestion.selectedIncludedTags = chlgQuestion.fincludedtags || [];
      challengeQuestion.selectedExcludedTags = chlgQuestion.fexcludedtags || [];
      challengeQuestion.enableAccountability =
        chlgQuestion.enable_accountability || false;

      challengeQuestion.textFormSubmission =
        chlgQuestion.text_form_submission || "leave";
      challengeQuestion.textIgnoreSubmission =
        chlgQuestion.text_ignore_submission || false;

      challengeQuestion.sTextAddTags = chlgQuestion.text_add_tags || [];
      challengeQuestion.sTextInactiveTags =
        chlgQuestion.text_inactive_tags || [];

      challengeQuestion.group = chlgQuestion.group || "";
    }

    if (this.props.questionTag && this.props.questionTag._id) {
      challengeQuestion.selectedIncludedTags = [this.props.questionTag];
    }

    this.state = {
      questionId: id,
      queId: challengeQuestion._id,
      name: challengeQuestion.name,
      question: challengeQuestion.question,
      description: challengeQuestion.description,
      itemType: challengeQuestion.itemType,
      answerType: challengeQuestion.answerType,
      answerList: challengeQuestion.answerList,
      addTags: challengeQuestion.selectedIncludedTags,
      excludeTags: challengeQuestion.selectedExcludedTags,
      selectedExcludedTags: challengeQuestion.selectedExcludedTags,
      selectedIncludedTags: challengeQuestion.selectedIncludedTags,
      ans: "",
      ansDesc: "",
      ansPoint: 0,
      ansSelected: false,
      ansIgnoreSubmission: false,
      ansDontShow: false,
      keyAnsDontShow: Math.random(),
      selectedEditAnswerIndex: -1,
      showChart: challengeQuestion.showChart,
      keyItemType: Math.random(),
      keyAnswerType: Math.random(),
      keyCheckbox: Math.random(),
      keySubmissionType: Math.random(),
      keySubmissionDay: Math.random(),
      submissionLimit: challengeQuestion.submissionLimit,
      showLimitSubmission: showLimitSubmission,
      submissionType: challengeQuestion.submissionType,
      submissionLimitDays: challengeQuestion.submissionLimitDays,
      submissionLimitTags: [],
      sLimitTags: challengeQuestion.sLimitTags,
      keyTag: Math.random(),
      systemTags: [],
      selectedTags: [],
      inactiveLimitTags: challengeQuestion.inactiveLimitTags,
      keyInactiveLimitTags: Math.random(),
      keyTagUsers: Math.random(),
      enableAccountability: challengeQuestion.enableAccountability,
      selectedAnsAddTags: [],
      selectedAnsInactiveTags: [],
      isTagAttributes: false,
      isAnsSubItem: false,
      ansSubItemLabel: "",
      ansSubItemType: "text",
      ansSubItemValue: "",
      ansSubItemValueObject: {},
      keyAnsSubItemValue: Math.random(),
      keyAnsSubItemType: Math.random(),
      keyIsAnsSubItem: Math.random(),
      keyIsTagAttributes: Math.random(),
      selectedAttributeTags: [],
      keyAttributeTag: Math.random(),
      ansAddTags: [],
      ansInactiveTags: [],
      attributeTags: [],
      tagAttributes: [],
      keyAnsAddTag: Math.random(),
      keyAnsInactiveTag: Math.random(),
      keyFormSubmission: Math.random(),
      ansFormSubmission: "leave",
      showCreateAnswer: false,
      textIgnoreSubmission: challengeQuestion.textIgnoreSubmission,
      keyTextIgnoreSubmission: Math.random(),
      textFormSubmission: challengeQuestion.textFormSubmission,
      keyTextFormSubmission: Math.random(),
      selectedTextAddTags: [],
      selectedTextInactiveTags: [],
      textAddTags: challengeQuestion.textAddTags,
      textInactiveTags: challengeQuestion.textInactiveTags,
      keyTextAddTag: Math.random(),
      keyTextInactiveTag: Math.random(),
      sTextAddTags: challengeQuestion.sTextAddTags,
      sTextInactiveTags: challengeQuestion.sTextInactiveTags,
      group: challengeQuestion.group,
      groups: [],
      keyGroup: Math.random(),
      showCreateGroup: false,
      groupName: "",
      showAdvancedOptionsDetails: challengeQuestion._id !== 0,
      redirectUser: false,
      redirectScreen: "",
      redirectChallengeId: "",
      redirectChallengeItemId: "",
      redirectWeight: 0,
      attName: "",
      attValue: "",
      attrRefresh: Math.random(),
      priority: challengeQuestion.priority,
    };
  }

  componentDidMount() {
    this.getQuestionInfo();
    this.getSystemTags();
    this.getQuestionGroups();
  }

  async getQuestionInfo() {
    if (this.state.questionId) {
      let chlgQuestion = await callGetChlgQueById(this.state.questionId);

      let challengeQuestion = {
        _id: 0,
        name: this.props.questionName || "",
        question: this.props.questionName || "",
        description: this.props.questionName || "",
        itemType: "regular",
        answerType: "single",
        showChart: false,
        answerList: [],
        submissionLimit: "",
        submissionLimitDays: [],
        submissionLimitTags: [],
        submissionType: "days",
        sLimitTags: [],
        inactiveLimitTags: true,
        selectedIncludedTags: [],
        selectedExcludedTags: [],
        enableAccountability:
          this.props.newQuestionType === "atq" ? true : false,
        textFormSubmission: "leave",
        textIgnoreSubmission: false,
        textAddTags: [],
        textInactiveTags: [],
        selectedTextAddTags: [],
        selectedTextInactiveTags: [],
        sTextAddTags: [],
        sTextInactiveTags: [],
        group: "",
      };

      let showLimitSubmission = false;

      challengeQuestion._id = chlgQuestion._id;
      challengeQuestion.name = chlgQuestion.name;
      challengeQuestion.question = chlgQuestion.question;
      challengeQuestion.description = chlgQuestion.description;
      challengeQuestion.itemType = chlgQuestion.item_type;
      challengeQuestion.answerType = chlgQuestion.answer_type;
      challengeQuestion.showChart = chlgQuestion.show_chart;
      challengeQuestion.answerList = chlgQuestion.answer_list;

      challengeQuestion.submissionType = chlgQuestion.submission_type || "days";

      if (challengeQuestion.submissionType === "days") {
        challengeQuestion.submissionLimit = chlgQuestion.submission_limit || "";
        if (challengeQuestion.submissionLimit) {
          showLimitSubmission = true;
        }
      } else if (challengeQuestion.submissionType === "week") {
        challengeQuestion.submissionLimitDays =
          chlgQuestion.submission_limit_days || [];
        if (challengeQuestion.submissionLimitDays.length > 0) {
          showLimitSubmission = true;
        }
      } else {
        challengeQuestion.sLimitTags = chlgQuestion.submission_limit_tags || [];
        if (challengeQuestion.sLimitTags.length > 0) {
          showLimitSubmission = true;
          challengeQuestion.inactiveLimitTags =
            chlgQuestion.inactive_limit_tags;
        }
      }

      challengeQuestion.selectedIncludedTags = chlgQuestion.fincludedtags || [];
      challengeQuestion.selectedExcludedTags = chlgQuestion.fexcludedtags || [];
      challengeQuestion.enableAccountability =
        chlgQuestion.enable_accountability || false;

      challengeQuestion.textFormSubmission =
        chlgQuestion.text_form_submission || "leave";
      challengeQuestion.textIgnoreSubmission =
        chlgQuestion.text_ignore_submission || false;

      challengeQuestion.sTextAddTags = chlgQuestion.text_add_tags || [];
      challengeQuestion.sTextInactiveTags =
        chlgQuestion.text_inactive_tags || [];

      challengeQuestion.group = chlgQuestion.group || "";

      if (this.props.questionTag && this.props.questionTag._id) {
        challengeQuestion.selectedIncludedTags = [this.props.questionTag];
      }

      this.setState({
        queId: challengeQuestion._id,
        name: challengeQuestion.name,
        question: challengeQuestion.question,
        description: challengeQuestion.description,
        itemType: challengeQuestion.itemType,
        answerType: challengeQuestion.answerType,
        answerList: challengeQuestion.answerList,
        addTags: challengeQuestion.selectedIncludedTags,
        excludeTags: challengeQuestion.selectedExcludedTags,
        selectedExcludedTags: challengeQuestion.selectedExcludedTags,
        selectedIncludedTags: challengeQuestion.selectedIncludedTags,

        keyAnsDontShow: Math.random(),
        selectedEditAnswerIndex: -1,
        showChart: challengeQuestion.showChart,
        keyItemType: Math.random(),
        keyAnswerType: Math.random(),
        keyCheckbox: Math.random(),
        keySubmissionType: Math.random(),
        keySubmissionDay: Math.random(),
        submissionLimit: challengeQuestion.submissionLimit,
        showLimitSubmission: showLimitSubmission,
        submissionType: challengeQuestion.submissionType,
        submissionLimitDays: challengeQuestion.submissionLimitDays,
        sLimitTags: challengeQuestion.sLimitTags,
        keyTag: Math.random(),
        inactiveLimitTags: challengeQuestion.inactiveLimitTags,
        keyInactiveLimitTags: Math.random(),
        keyTagUsers: Math.random(),
        enableAccountability: challengeQuestion.enableAccountability,
        keyAnsSubItemType: Math.random(),
        keyIsAnsSubItem: Math.random(),
        keyIsTagAttributes: Math.random(),
        selectedAttributeTags: [],
        keyAttributeTag: Math.random(),
        keyAnsAddTag: Math.random(),
        keyAnsInactiveTag: Math.random(),
        keyFormSubmission: Math.random(),
        textIgnoreSubmission: challengeQuestion.textIgnoreSubmission,
        keyTextIgnoreSubmission: Math.random(),
        textFormSubmission: challengeQuestion.textFormSubmission,
        keyTextFormSubmission: Math.random(),
        textAddTags: challengeQuestion.textAddTags,
        textInactiveTags: challengeQuestion.textInactiveTags,
        keyTextAddTag: Math.random(),
        keyTextInactiveTag: Math.random(),
        sTextAddTags: challengeQuestion.sTextAddTags,
        sTextInactiveTags: challengeQuestion.sTextInactiveTags,
        group: challengeQuestion.group,
        keyGroup: Math.random(),
        attrRefresh: Math.random(),
      });
    }
  }

  async getQuestionGroups() {
    let d = await callGetGenericTags("challenge-question");
    this.setState({ groups: d.data, keyGroup: Math.random() });
    if (this.state.queId === 0) {
      this.selectGroup(d.data);
    }
  }

  async selectGroup(data) {
    let fData = data.filter((d) => d.name === "Accountability");
    if (fData.length > 0) {
      this.setState({ group: fData[0]._id, keyGroup: Math.random() });
    } else {
      let apiData = [{ name: "Accountability", type: "challenge-question" }];
      let response = await callCreateGenericTag(apiData);
      if (response.length > 0) {
        this.setState({
          group: response[0]._id,
          keyGroup: Math.random(),
          groups: [response[0]],
        });
      }
    }
  }

  resetProps() {
    this.setState({
      ans: "",
      ansDesc: "",
      ansPoint: 0,
      ansSelected: false,
      ansIgnoreSubmission: false,
      name: "",
      question: "",
      description: "",
      itemType: "focus",
      answerType: "single",
      answerList: [],
      keyItemType: Math.random(),
      keyAnswerType: Math.random(),
      keyCheckbox: Math.random(),
      keySubmissionType: Math.random(),
      keySubmissionDay: Math.random(),
      showLimitSubmission: false,
      submissionLimit: "",
      submissionType: "days",
      submissionLimitDays: [],
      keyTag: Math.random(),
      addTags: [],
      excludeTags: [],
      keyTagUsers: Math.random(),
      selectedIncludedTags: [],
      selectedExcludedTags: [],
      enableAccountability: false,
      ansSubItemLabel: "",
      ansSubItemType: "text",
      ansSubItemValue: "",
      ansSubItemValueObject: {},
      keyAnsSubItemType: Math.random(),
      keyIsAnsSubItem: Math.random(),
      keyAnsSubItemValue: Math.random(),
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  async getSystemTags() {
    let tags = await callGetSystemTags();
    let submissionLimitTags = [];
    let textAddTags = [];
    let textInactiveTags = [];

    if (this.state.queId !== 0) {
      let sTags = this.state.sLimitTags;

      sTags.forEach((element) => {
        let fTags = tags.data.filter((d) => {
          return d._id === element;
        });
        if (fTags.length > 0) {
          submissionLimitTags.push(fTags[0]);
        }
      });

      let addTags = this.state.sTextAddTags;
      let inactiveTags = this.state.sTextInactiveTags;

      textAddTags = tags.data.filter((d) => {
        return addTags.indexOf(d._id) > -1;
      });
      textInactiveTags = tags.data.filter((d) => {
        return inactiveTags.indexOf(d._id) > -1;
      });
    }
    this.setState({
      systemTags: tags.data,
      keyTag: Math.random(),
      keyAnsAddTag: Math.random(),
      keyAnsInactiveTag: Math.random(),
      submissionLimitTags: submissionLimitTags,
      textAddTags,
      textInactiveTags,
      selectedTextAddTags: textAddTags,
      selectedTextInactiveTags: textInactiveTags,
      keyTextAddTag: Math.random(),
      keyTextInactiveTag: Math.random(),
    });
  }

  onSelectItem(item, id) {
    this.setState({ [id]: item[Object.keys(item)[0]].value });
  }

  onSelectFormSubmission(item, id) {
    this.setState({
      [id]: item[Object.keys(item)[0]].value,
      ansAddTags: [],
      ansInactiveTags: [],
      selectedAnsAddTags: [],
      selectedAnsInactiveTags: [],
      keyAnsAddTag: Math.random(),
      keyAnsInactiveTag: Math.random(),
    });
  }

  onSelectSubmissionType(item, id) {
    this.setState({
      [id]: item[Object.keys(item)[0]].value,
      submissionLimit: this.state.submissionLimit,
      submissionLimitDays: this.state.submissionLimitDays,
    });
  }

  onSelectSubmissionDay(item, id) {
    let days = [];
    if (Object.keys(item).length > 0 && item.constructor === Object) {
      Object.keys(item).forEach((key) => {
        days.push(item[key].value);
      });
    }
    this.setState({ submissionLimitDays: days });
  }

  onPressAddAnswer() {
    let answers = this.state.answerList;

    if (this.state.ans.trim().length === 0) {
      showNotification("error", "Answer is required", 4000);
      return;
    }
    if (this.state.ansDesc.trim().length === 0) {
      showNotification("error", "Answer Description Name is required", 4000);
      return;
    }
    if (this.state.ansPoint.toString().trim().length === 0) {
      showNotification("error", "Answer Point is required", 4000);
      return;
    }

    let ignoreSubmission = this.state.ansIgnoreSubmission;

    let addTagIds = this.state.ansAddTags.map((t) => t._id);
    let inactiveTagIds = this.state.ansInactiveTags.map((t) => t._id);
    let attributeTags = this.state.attributeTags.map((t) => t._id);

    if (this.state.ansFormSubmission === "inactivate") {
      inactiveTagIds = this.state.addTags.map((i) => i._id);
    }

    if (ignoreSubmission) {
      addTagIds = [];
      inactiveTagIds = [];
    }

    /*eslint-disable*/
    let answer = {
      points: this.state.ansPoint.toString(),
      answer: this.state.ans,
      description: this.state.ansDesc,
      selected: this.state.ansSelected,
      ignore_submission: ignoreSubmission,
      dont_show: this.state.ansDontShow,
      add_tags: addTagIds,
      inactive_tags: inactiveTagIds,
      attribute_tags: attributeTags,
      tag_attributes: this.state.tagAttributes,
      is_tag_attributes: this.state.isTagAttributes,
      form_submission: this.state.ansFormSubmission,
      redirect_user: this.state.redirectUser,
      redirect_screen: this.state.redirectScreen,
      redirect_weight: parseInt(this.state.redirectWeight),
      redirect_challenge_id: this.state.redirectChallengeId,
      redirect_challenge_item_id: this.state.redirectChallengeItemId,
      is_sub_item: this.state.isAnsSubItem,
      sub_item_label: this.state.ansSubItemLabel,
      sub_item_type: this.state.ansSubItemType,
      sub_item_value: this.state.ansSubItemValue,
      sub_item_value_object: this.state.ansSubItemValueObject,
    };
    /*eslint-enable*/

    if (this.state.selectedEditAnswerIndex >= 0) {
      answers[this.state.selectedEditAnswerIndex] = answer;
    } else {
      answers.push(answer);
    }

    this.setState({
      answerList: answers,
      ans: "",
      ansDesc: "",
      ansPoint: 0,
      selectedEditAnswerIndex: -1,
      keyCheckbox: Math.random(),
      ansSelected: false,
      ansIgnoreSubmission: false,
      ansAddTags: [],
      ansInactiveTags: [],
      keyAnsAddTag: Math.random(),
      keyAnsInactiveTag: Math.random(),
      selectedAnsAddTags: [],
      selectedAnsInactiveTags: [],
      tagAttributes: [],
      ansFormSubmission: "leave",
      keyFormSubmission: Math.random(),
      showCreateAnswer: false,
      redirectUser: false,
      redirectScreen: "",
      redirectChallengeId: "",
      redirectChallengeItemId: "",
      redirectWeight: 0,
      isAnsSubItem: false,
      keyAnsSubItemType: Math.random(),
      keyIsAnsSubItem: Math.random(),
      ansSubItemLabel: "",
      ansSubItemType: "text",
      ansSubItemValue: "",
      ansSubItemValueObject: {},
      keyAnsSubItemValue: Math.random(),
    });
  }

  onPressCancelEdit() {
    this.setState({
      ans: "",
      ansDesc: "",
      ansPoint: 0,
      selectedEditAnswerIndex: -1,
      keyCheckbox: Math.random(),
      ansSelected: false,
      ansIgnoreSubmission: false,
      ansAddTags: [],
      ansInactiveTags: [],
      keyAnsAddTag: Math.random(),
      keyAnsInactiveTag: Math.random(),
      selectedAnsAddTags: [],
      selectedAnsInactiveTags: [],
      ansFormSubmission: "leave",
      keyFormSubmission: Math.random(),
      showCreateAnswer: false,
      redirectUser: false,
      redirectScreen: "",
      redirectChallengeId: "",
      redirectChallengeItemId: "",
      redirectWeight: 0,
    });
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    let answerList = this.state.answerList;

    answerList = arrayMove(answerList, oldIndex, newIndex);
    this.setState({
      answerList: answerList,
    });
  };

  onPressRemove(e) {
    let index = e.target.dataset["value"];
    let answerList = this.state.answerList;
    answerList.splice(index, 1);
    this.setState({
      answerList: answerList,
    });
  }

  onPressEdit(e) {
    let index = e.target.dataset["value"];
    let answerList = this.state.answerList;

    let answer = answerList[index];

    let addTags = answer.add_tags || [];
    let inactiveTags = answer.inactive_tags || [];
    let attributeTags = answer.attribute_tags || [];

    let fAddTags = this.state.systemTags.filter((d) => {
      return addTags.indexOf(d._id) > -1;
    });
    let fInactiveTags = this.state.systemTags.filter((d) => {
      return inactiveTags.indexOf(d._id) > -1;
    });
    let fAttributeTags = this.state.systemTags.filter((d) => {
      return attributeTags.indexOf(d._id) > -1;
    });

    this.setState({
      ans: answer.answer,
      ansDesc: answer.description,
      ansPoint: answer.points,
      ansSelected: answer.selected,
      ansIgnoreSubmission: answer.ignore_submission,
      ansDontShow: answer.dont_show,
      keyAnsDontShow: Math.random(),
      selectedEditAnswerIndex: index,
      keyCheckbox: Math.random(),
      keyAnsAddTag: Math.random(),
      keyAnsInactiveTag: Math.random(),
      ansAddTags: fAddTags,
      ansInactiveTags: fInactiveTags,
      attributeTags: fAttributeTags,
      tagAttributes: answer.tag_attributes || [],
      isTagAttributes: answer.is_tag_attributes,
      keyIsTagAttributes: Math.random(),
      selectedAnsAddTags: fAddTags,
      selectedAnsInactiveTags: fInactiveTags,
      selectedAttributeTags: fAttributeTags,
      keyAttributeTag: Math.random(),
      ansFormSubmission: answer.form_submission || "leave",
      keyFormSubmission: Math.random(),
      showCreateAnswer: true,
      redirectUser: answer.redirect_user,
      redirectScreen: answer.redirect_screen || "",
      redirectChallengeId: answer.redirect_challenge_id || "",
      redirectChallengeItemId: answer.redirect_challenge_item_id || "",
      redirectWeight: answer.redirect_weight || 0,
      isAnsSubItem: answer.is_sub_item || false,
      ansSubItemLabel: answer.sub_item_label || "",
      ansSubItemType: answer.sub_item_type || "text",
      ansSubItemValue: answer.sub_item_value || "",
      ansSubItemValueObject: answer.sub_item_value_object || {},
      keyIsAnsSubItem: Math.random(),
      keyAnsSubItemType: Math.random(),
      keyAnsSubItemValue: Math.random(),
    });
  }

  toggleCheckbox = (check) => {
    this.setState({
      showChart: check,
    });
  };

  toggleEnableCheckbox = (check) => {
    this.setState({
      enableAccountability: check,
    });
  };

  toggleLimitCheckbox = (check) => {
    this.setState({
      showLimitSubmission: check,
    });
  };

  toggleAnsCheckbox = (check) => {
    this.setState({
      ansSelected: check,
    });
  };

  toggleAnsDontShow = (check) => {
    this.setState({
      ansDontShow: check,
    });
  };

  toggleInactiveTagCheckbox = (check) => {
    this.setState({
      inactiveLimitTags: check,
    });
  };

  toggleIgnoreSubmissionCheckbox = (check) => {
    this.setState({
      ansIgnoreSubmission: check,
    });
  };

  toggleTextIgnoreSubmissionCheckbox = (check) => {
    this.setState({
      textIgnoreSubmission: check,
    });
  };

  toggleRedirectUserCheckbox = (check) => {
    this.setState({
      redirectUser: check,
    });
  };

  toggleTagAttributesCheckbox = (check) => {
    this.setState({
      isTagAttributes: check,
    });
  };

  toggleIsAnsSubItemCheckbox = (check) => {
    this.setState({
      isAnsSubItem: check,
    });
  };

  onChangeParagaphValue = (html, json) => {
    this.setState({ ansSubItemValue: html, ansSubItemValueObject: json });
  };

  async onPressAddQuestion() {
    if (this.state.name.trim().length === 0) {
      showNotification("error", "Question Name is required", 4000);
      return;
    }

    if (this.state.question.trim().length === 0) {
      showNotification("error", "Question is required", 4000);
      return;
    }

    if (
      this.state.group.trim().length === 0 ||
      this.state.group.trim() === "Create New Group"
    ) {
      showNotification("error", "Group is required", 4000);
      return;
    }

    if (this.state.itemType.trim().length === 0) {
      showNotification("error", "Item Type is required", 4000);
      return;
    }

    if (this.state.answerType.trim().length === 0) {
      showNotification("error", "Answer Type is required", 4000);
      return;
    }

    if (this.state.answerType !== "text" && this.state.answerList.length < 2) {
      showNotification("error", "At least 2 answers are required", 4000);
      return;
    }

    let submissionLimitTags = this.state.submissionLimitTags
      .filter((d) => {
        return d._id !== null;
      })
      .map((t) => t._id);

    let includedTagIds = this.state.addTags.map((t) => t._id);
    let excludedTagIds = this.state.excludeTags.map((t) => t._id);

    let limit = this.state.submissionLimit;
    let limitDays = this.state.submissionLimitDays;
    let limitType = this.state.submissionType;
    let limitTags = submissionLimitTags;

    if (!this.state.showLimitSubmission) {
      limit = "";
      limitDays = [];
      limitTags = [];
    }

    let ignoreSubmission = this.state.textIgnoreSubmission;

    let addTagIds = this.state.textAddTags.map((t) => t._id);
    let inactiveTagIds = this.state.textInactiveTags.map((t) => t._id);

    if (this.state.textFormSubmission === "inactivate") {
      inactiveTagIds = this.state.addTags.map((i) => i._id);
    }

    let apiData = {
      name: this.state.name,
      description: this.state.description,
      question: this.state.question,
      /*eslint-disable*/
      item_type: this.state.itemType,
      answer_type: this.state.answerType,
      show_chart: this.state.showChart,
      answer_list: this.state.answerList,
      submission_limit: limit,
      submission_limit_days: limitDays,
      submission_type: limitType,
      submission_limit_tags: limitTags,
      inactive_limit_tags: this.state.inactiveLimitTags,
      included_tags: includedTagIds,
      excluded_tags: excludedTagIds,
      enable_accountability: this.state.enableAccountability,
      text_ignore_submission: ignoreSubmission,
      text_add_tags: addTagIds,
      text_inactive_tags: inactiveTagIds,
      text_form_submission: this.state.textFormSubmission,
      group: this.state.group,
      priority: this.state.priority,
      /*eslint-enable*/
    };

    if (this.state.queId === 0) {
      let result = await callCreateChlgQue(apiData);
      if (result._id) {
        showNotification("success", "Created Successfully", 4000);
        this.resetProps();
        if (this.props.fromPopup) {
          this.props.onCreate(result._id);
        }
      }
    } else {
      let result = await callUpdateChlgQue(this.state.queId, apiData);
      if (result._id) {
        showNotification("success", "Updated Successfully", 4000);
      }
    }
  }

  renderAnswerPoints() {
    let returnVals = [];
    returnVals.push(
      <option value={0} key={0}>
        0
      </option>
    );
    for (let i = 1; i < 11; i++) {
      returnVals.push(
        <option value={i} key={i}>
          {i}
        </option>
      );
    }
    return returnVals;
  }

  isRenderSelectedAnsCheck() {
    let answerType = this.state.answerType;
    let check = false;
    if (answerType === "text") {
      check = false;
    }

    if (answerType === "multiple") {
      check = true;
    }

    if (answerType === "single") {
      let arrTemp = this.state.answerList.filter((d) => {
        return d.selected === true;
      });
      check = arrTemp.length > 0 ? false : true;
    }

    if (this.state.selectedEditAnswerIndex >= 0) {
      let selectedAnswer =
        this.state.answerList[this.state.selectedEditAnswerIndex];
      if (selectedAnswer && selectedAnswer.selected) {
        check = true;
      }
    }

    return check;
  }

  onPressCreateNewAnswer() {
    this.setState({ showCreateAnswer: !this.state.showCreateAnswer });
  }

  onChangeTags = (tags) => {
    this.setState({ submissionLimitTags: tags });
  };

  onChangeAddTags = (tags) => {
    this.setState({ addTags: tags }, () => {
      this.refreshTags();
    });
  };

  onChangeExcludeTags = (tags) => {
    this.setState({ excludeTags: tags }, () => {
      this.refreshTags();
    });
  };

  async refreshTags() {
    await this.getSystemTags();
    this.setState({
      keyTextAddTag: Math.random(),
      keyTextInactiveTag: Math.random(),
      keyTag: Math.random(),
      keyAnsAddTag: Math.random(),
      keyAnsInactiveTag: Math.random(),
      keyAttributeTag: Math.random(),
    });
  }

  onChangeAnsAddTags = (tags) => {
    this.setState({ ansAddTags: tags });
  };

  onChangeAnsInactiveTags = (tags) => {
    this.setState({ ansInactiveTags: tags });
  };

  onChangeAttributeTags = (tags) => {
    this.setState({ attributeTags: tags });
  };

  onChangeTextAddTags = (tags) => {
    this.setState({ textAddTags: tags });
  };

  onChangeTextInactiveTags = (tags) => {
    this.setState({ textInactiveTags: tags });
  };

  handleOnBlurQuestionName(event) {
    const value = event.target.value;
    if (
      value.toLowerCase() === this.state.question ||
      this.state.question.length === 0
    ) {
      this.setState({ question: value });
    }
  }

  handleOnBlurAnswer(event) {
    const value = event.target.value;
    if (
      value.toLowerCase() === this.state.ansDesc ||
      this.state.ansDesc.length === 0
    ) {
      this.setState({ ansDesc: value });
    }
  }

  onSelectGroup(item, id) {
    this.setState({
      [id]: item[Object.keys(item)[0]].value,
      showCreateGroup:
        item[Object.keys(item)[0]].value === "Create New Group" ? true : false,
    });
  }

  onClickCancelFromUpdateCategoryModal() {
    $(
      ".modalAlertOverlay.updateCategoryModalOverlay,.modalAlert.updateCategoryAlert"
    ).fadeOut(200);
  }

  async onClickUpdateFromUpdateCategoryModel() {
    if (this.state.groupName.trim().length === 0) {
      showNotification("error", "Cagtegory name is required", 4000);
      return;
    }
    let updatedCategory = await callUpdateGenericTag(this.state.group, {
      name: this.state.groupName,
    });
    if (updatedCategory._id) {
      this.getQuestionGroups();
      $(
        ".modalAlertOverlay.updateCategoryModalOverlay,.modalAlert.updateCategoryAlert"
      ).fadeOut(200);
    }
  }

  onClickEditGroup() {
    let selectedGroup = this.state.groups.find(
      (d) => d._id === this.state.group
    );
    this.setState({ groupName: selectedGroup.name }, () => {
      $(
        ".modalAlertOverlay.updateCategoryModalOverlay,.modalAlert.updateCategoryAlert"
      ).fadeIn(200);
    });
  }

  renderUpdateCategoryModal() {
    let group = this.state.group;

    if (!group) {
      return null;
    }

    return (
      <div>
        <div className="modalAlertOverlay updateCategoryModalOverlay" />
        <div className="modalAlert updateCategoryAlert">
          <div className="clearfix">
            <div className="pull-left">
              <p>Edit Group</p>
            </div>
          </div>

          <div className="container-fluid">
            <div>
              <div
                className={
                  this.state.groupName !== "" ? "mdInput mdFocussed" : "mdInput"
                }>
                <label>Group Name</label>
                <input
                  type="text"
                  name="groupName"
                  value={this.state.groupName}
                  onChange={(event) => this.handleInputChange(event)}
                />
              </div>
              <div className="gap20" />
            </div>
          </div>
          <div className="gap20" />
          <div className="alertFooterBtns">
            <button
              onClick={this.onClickCancelFromUpdateCategoryModal.bind(this)}
              className="btn btn-default dismissThisModal margin-right-10">
              Cancel
            </button>
            <button
              onClick={this.onClickUpdateFromUpdateCategoryModel.bind(this)}
              className="btn btn-primary dismissThisModal">
              Update
            </button>
          </div>
        </div>
      </div>
    );
  }

  async onClickSubmitGroup() {
    let groupName = this.state.groupName.trim();

    if (groupName.trim().length === 0) {
      showNotification("error", "Group Name is required", 4000);
      return;
    }
    let existingGroup = this.state.groups.filter((d) => {
      return d.name.toLowerCase() === groupName.toLowerCase();
    });
    if (existingGroup.length > 0) {
      showNotification("error", "Group already exists");
      return;
    }

    let data = [{ name: this.state.groupName, type: "challenge-question" }];

    let response = await callCreateGenericTag(data);
    if (response.length > 0) {
      this.setState({ groupName: "", showCreateGroup: false }, () => {
        this.getQuestionGroups();
        this.setState({ group: response[0].id });
      });
    }
  }

  renderGroupComponent() {
    let groups = this.state.groups;
    let labelList = [];
    groups.forEach((element) => {
      labelList.push({
        name: element.name,
        value: element._id,
      });
    });
    labelList.push({
      name: "Create New Group",
      value: "Create New Group",
    });

    return (
      <>
        <div className="card">
          <div className="container-fluid">
            {labelList.length > 0 && (
              <div className="row" key={this.state.keyGroup}>
                <div className="col-md-12">
                  <div className="gap10" />
                  <label>Select Group</label>
                  <div className="tabsHolder radioTabs">
                    <RadioTag
                      onCheckChange={this.onSelectGroup.bind(this)}
                      labelList={labelList}
                      id={"group"}
                      selectedList={[this.state.group]}
                      selectType={"value"}
                    />
                  </div>
                  <div className="gap20" />
                </div>
              </div>
            )}

            <VelocityTransitionGroup
              enter={{ animation: "slideDown" }}
              leave={{ animation: "slideUp" }}>
              {this.state.showCreateGroup && (
                <>
                  <div className="row">
                    <div className="col-sm-4">
                      <div
                        className={
                          this.state.groupName !== ""
                            ? "mdInput mdFocussed"
                            : "mdInput"
                        }>
                        <label>Group Name</label>
                        <input
                          type="text"
                          name="groupName"
                          value={this.state.groupName}
                          onChange={(event) => this.handleInputChange(event)}
                        />
                      </div>
                      <div className="gap20" />
                    </div>
                    <div className="col-sm-4">
                      <div className="gap30" />
                      <div className="gap10" />
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={this.onClickSubmitGroup.bind(this)}>
                        Create Group
                      </button>
                    </div>
                  </div>
                </>
              )}
              {this.state.group !== "" && !this.state.showCreateGroup && (
                <button
                  className="btn btn-sm btn-primary"
                  onClick={this.onClickEditGroup.bind(this)}>
                  Edit Selected Group
                </button>
              )}
              <div className="gap10" />
            </VelocityTransitionGroup>
          </div>
        </div>
      </>
    );
  }

  onClickDeleteCustomValue(i) {
    let attributes = this.state.tagAttributes || [];
    attributes = attributes.filter((d) => d.id !== i.id);
    this.setState({ tagAttributes: attributes, attrRefresh: Math.random() });
  }

  onPressCreateCustomValue() {
    let attributes = this.state.tagAttributes || [];
    if (this.state.attName.trim().length === 0) {
      showNotification("error", "Attribute name is required");
      return;
    }
    if (this.state.attValue.trim().length === 0) {
      showNotification("error", "Attribute value is required");
      return;
    }

    attributes.push({
      name: this.state.attName,
      value: this.state.attValue,
      id: getUniqueId(),
    });

    this.setState({ tagAttributes: attributes, attName: "", attValue: "" });
  }

  render() {
    let showTitle = true;
    if (this.props.fromPopup === true) {
      showTitle = false;
    }

    let showTag = this.props.newQuestionType !== "atq";

    return (
      <div>
        {this.renderUpdateCategoryModal()}
        <div className="container-fluid">
          {showTitle && (
            <TitleBox
              title={
                this.state.queId === 0 ? "Create Question" : "Edit Question"
              }
              showBackBtn={this.state.queId !== 0}
            />
          )}
          <div className="gap20" />

          <div className="card">
            <div className="container-fluid">
              <div className="gap10" />
              <div className="text-right">
                <button
                  className={"btn btn-info"}
                  onClick={() =>
                    this.setState({
                      showAdvancedOptionsDetails:
                        !this.state.showAdvancedOptionsDetails,
                    })
                  }>
                  {this.state.showAdvancedOptionsDetails
                    ? "Hide Advanced Options"
                    : "Show Advanced Options"}
                </button>
              </div>
              <HelpTextDiv
                id="idChallengeQuestionName"
                label="Learn more about the Question Name"
                type="addChallengeQue"
                isInline="false"
              />

              <>
                <div
                  className={
                    this.state.name !== "" ? "mdInput mdFocussed" : "mdInput"
                  }>
                  <label>Question Name</label>
                  <input
                    type="text"
                    name="name"
                    value={this.state.name}
                    onChange={(event) => this.handleInputChange(event)}
                    onBlur={(event) => this.handleOnBlurQuestionName(event)}
                  />
                </div>
                <div className="gap20" />

                <HelpTextDiv
                  id="idChallengeQuestion"
                  label="Learn more about the Question"
                  type="addChallengeQue"
                  isInline="false"
                />
                <div
                  className={
                    this.state.question !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Question</label>
                  <input
                    type="text"
                    name="question"
                    value={this.state.question}
                    onChange={(event) => this.handleInputChange(event)}
                  />
                </div>
              </>

              <div className="gap20" />
              {this.state.showAdvancedOptionsDetails && showTag && (
                <TagUsers
                  label={"Add / exclude the tags challenge questions"}
                  key={this.state.keyTagUsers}
                  type="tags"
                  contentId={this.state.queId}
                  selectedIncludedTags={this.state.selectedIncludedTags}
                  selectedExcludedTags={this.state.selectedExcludedTags}
                  history={this.props.history}
                  onChangeAddTags={this.onChangeAddTags}
                  onChangeExcludeTags={this.onChangeExcludeTags}
                />
              )}

              {this.renderGroupComponent()}

              <div className="row">
                <div className="col-md-6">
                  <div className="havingHelpText">
                    <p>Answer Type</p>
                  </div>
                  <HelpTextDiv
                    id="idChallengeAnswerType"
                    label="Learn more about the Answer Type"
                    type="addChallengeQue"
                    isInline="true"
                  />
                  <div className="tabsHolder radioTabs">
                    <RadioTag
                      onCheckChange={this.onSelectItem.bind(this)}
                      labelList={[
                        { name: "Single", value: "single" },
                        { name: "Text", value: "text" },
                      ]}
                      id={"answerType"}
                      selectType={"value"}
                      key={this.state.keyAnswerType}
                      selectedList={[this.state.answerType]}
                    />
                  </div>
                  <div className="gap10" />
                </div>
              </div>
              <div className="gap10" />
              <div className="row">
                <div className="col-md-4">
                  <div
                    className={
                      this.state.priority !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>Sort Priority (higher numbers appear first)</label>
                    <input
                      type="number"
                      name="priority"
                      value={this.state.priority}
                      onChange={(event) => this.handleInputChange(event)}
                    />
                  </div>
                </div>
              </div>
              <div className="gap20" />

              {this.state.answerType === "text" && (
                <div className="card">
                  <div className="container-fluid">
                    <div className="gap10" />
                    <Checkbox
                      label={
                        "Continue displaying this question if this answer is left empty"
                      }
                      isChecked={this.state.textIgnoreSubmission}
                      handleCheckboxChange={this.toggleTextIgnoreSubmissionCheckbox.bind(
                        this
                      )}
                      key={this.state.keyTextIgnoreSubmission}
                    />
                    <div className="gap10" />
                    <>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="havingHelpText">
                            <p>On Form Submission</p>
                          </div>
                          <div className="tabsHolder radioTabs">
                            <RadioTag
                              onCheckChange={this.onSelectFormSubmission.bind(
                                this
                              )}
                              labelList={[
                                {
                                  name: "Leave Tags Alone",
                                  value: "leave",
                                },
                                {
                                  name: "Inactivate Tags",
                                  value: "inactivate",
                                },
                                { name: "Custom", value: "custom" },
                              ]}
                              id={"textFormSubmission"}
                              selectType={"value"}
                              key={this.state.keyTextFormSubmission}
                              selectedList={[this.state.textFormSubmission]}
                            />
                          </div>
                          <div className="gap10" />
                        </div>
                      </div>
                      {this.state.textFormSubmission === "inactivate" && (
                        <p>
                          The question will only be displayed until this answer
                          is not empty. At that point we will inactivate their
                          tag and they will no longer have it.
                        </p>
                      )}
                      {this.state.textFormSubmission === "leave" && (
                        <p>
                          The questions will continue to be displayed every day.
                        </p>
                      )}
                      {this.state.textFormSubmission === "custom" && (
                        <p>
                          Allows you to add or inactivate tags as you wish once
                          they select this answer.
                        </p>
                      )}
                      {this.state.textFormSubmission === "custom" && (
                        <>
                          <div className="gap20" />
                          <p>
                            Add the following tags if this answer is not empty
                            (optional):
                          </p>
                          <TagSelect
                            id={"add-question-add-text-tags"}
                            key={this.state.keyTextAddTag}
                            selectedValues={this.state.selectedTextAddTags}
                            searchKey={"name"}
                            selectKey={"name"}
                            lblText={"Create New Tag"}
                            data={this.state.systemTags}
                            onChange={this.onChangeTextAddTags}
                            reload={() => {
                              this.getSystemTags();
                            }}
                          />
                          <p>
                            Inactivate the following tags if this answer is
                            selected (optional):
                          </p>
                          <TagSelect
                            id={"add-question-inactivate-text-tags"}
                            key={this.state.keyTextInactiveTag}
                            selectedValues={this.state.selectedTextInactiveTags}
                            searchKey={"name"}
                            selectKey={"name"}
                            lblText={"Inactive Tag"}
                            data={this.state.systemTags}
                            onChange={this.onChangeTextInactiveTags}
                            reload={() => {
                              this.getSystemTags();
                            }}
                          />
                        </>
                      )}
                    </>
                  </div>
                </div>
              )}
              {this.state.showAdvancedOptionsDetails && (
                <>
                  <div className="havingHelpText">
                    <Checkbox
                      label={"Limit Submissions"}
                      isChecked={this.state.showLimitSubmission}
                      handleCheckboxChange={this.toggleLimitCheckbox.bind(this)}
                      key={this.state.keyCheckbox}
                    />
                  </div>
                  {this.state.showLimitSubmission && (
                    <div>
                      <div className="gap10" />
                      <div className="tabsHolder radioTabs">
                        <RadioTag
                          onCheckChange={this.onSelectSubmissionType.bind(this)}
                          labelList={[
                            { name: "By Days", value: "days" },
                            { name: "By Day of Week", value: "week" },
                          ]}
                          id={"submissionType"}
                          selectType={"value"}
                          key={this.state.keySubmissionType}
                          selectedList={[this.state.submissionType]}
                        />
                      </div>
                      {this.state.submissionType === "days" && (
                        <div className="row">
                          <div className="col-md-6">
                            <div
                              className={
                                this.state.submissionLimit !== ""
                                  ? "mdInput mdFocussed"
                                  : "mdInput"
                              }>
                              <label>
                                Accountability can only be submitted a maximum
                                of 1 time per X Days
                              </label>
                              <input
                                type="number"
                                name="submissionLimit"
                                value={this.state.submissionLimit}
                                onChange={(event) =>
                                  this.handleInputChange(event)
                                }
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      {this.state.submissionType === "week" && (
                        <div>
                          <div className="gap10" />
                          <div className="tabsHolder radioTabs">
                            <RadioTag
                              onCheckChange={this.onSelectSubmissionDay.bind(
                                this
                              )}
                              labelList={[
                                { name: "Monday", value: "monday" },
                                { name: "Tuesday", value: "tuesday" },
                                { name: "Wednesday", value: "wednesday" },
                                { name: "Thursday", value: "thursday" },
                                { name: "Friday", value: "friday" },
                                { name: "Saturday", value: "saturday" },
                                { name: "Sunday", value: "sunday" },
                              ]}
                              id={"submissionLimitDay"}
                              type={"multiple"}
                              key={this.state.keySubmissionDay}
                              selectedList={this.state.submissionLimitDays}
                              selectType={"value"}
                            />
                          </div>
                        </div>
                      )}
                      {this.state.submissionType === "tag" && (
                        <>
                          <TagSelect
                            id={"add-challenge-que-add-tag"}
                            key={this.state.keyTag}
                            selectedValues={this.state.submissionLimitTags}
                            searchKey={"name"}
                            selectKey={"name"}
                            btnText={"Create New Tag"}
                            lblText={"Select Tag"}
                            data={this.state.systemTags}
                            onChange={this.onChangeTags}
                            reload={() => {
                              this.getSystemTags();
                            }}
                          />
                          <div className="gap10" />
                          <Checkbox
                            label={
                              "Make the tag inactive once it has been submitted"
                            }
                            isChecked={this.state.inactiveLimitTags}
                            handleCheckboxChange={this.toggleInactiveTagCheckbox.bind(
                              this
                            )}
                            key={this.state.keyInactiveLimitTags}
                          />
                        </>
                      )}
                    </div>
                  )}
                  <div className="gap10" />
                  <div className="havingHelpText">
                    <Checkbox
                      label={
                        "Allow a chart to be created from this question and displayed to the customer"
                      }
                      isChecked={this.state.showChart}
                      handleCheckboxChange={this.toggleCheckbox.bind(this)}
                      key={this.state.keyCheckbox}
                    />
                  </div>
                  <HelpTextDiv
                    id="idChallengeAllowChart"
                    label="Learn more about this"
                    type="addChallengeQue"
                    isInline="true"
                  />
                  <div className="gap10" />
                  <Checkbox
                    label={
                      "Only enable this accountability if they are active inside a challenge or subscription which contains it."
                    }
                    isChecked={this.state.enableAccountability}
                    handleCheckboxChange={this.toggleEnableCheckbox.bind(this)}
                    key={this.state.keyCheckbox}
                  />
                  <div className="gap20" />
                </>
              )}
            </div>
          </div>

          {this.state.answerType !== "text" && (
            <div>
              <h3 className="noMargin">Add an Answer</h3>
              <div className="gap20" />
              <div className="card">
                <div className="container-fluid">
                  <div className="gap20" />
                  <button
                    className="btn btn-default btn-sm"
                    onClick={() => this.onPressCreateNewAnswer()}>
                    Create a New Answer
                  </button>
                  <div className="gap20" />
                  <VelocityTransitionGroup
                    enter={{ animation: "slideDown" }}
                    leave={{ animation: "slideUp" }}>
                    {this.state.showCreateAnswer && (
                      <div className="card">
                        <div className="container-fluid">
                          <HelpTextDiv
                            id="idChallengeAnswer"
                            label="Learn more about the Answer"
                            type="addChallengeQue"
                            isInline="false"
                          />
                          <div
                            className={
                              this.state.ans !== ""
                                ? "mdInput mdFocussed"
                                : "mdInput"
                            }>
                            <label>Answer</label>
                            <input
                              type="text"
                              name="ans"
                              className="ansName"
                              value={this.state.ans}
                              onChange={(event) =>
                                this.handleInputChange(event)
                              }
                              onBlur={(event) => this.handleOnBlurAnswer(event)}
                            />
                          </div>
                          <div className="gap20" />
                          <HelpTextDiv
                            id="idChallengeChartDescription"
                            label="Learn more about the Chart Description"
                            type="addChallengeQue"
                            isInline="false"
                          />
                          {this.state.showAdvancedOptionsDetails && (
                            <div
                              className={
                                this.state.ansDesc !== ""
                                  ? "mdInput mdFocussed"
                                  : "mdInput"
                              }>
                              <label>Chart Description</label>
                              <input
                                type="text"
                                name="ansDesc"
                                className="ansDesc"
                                value={this.state.ansDesc}
                                onChange={(event) =>
                                  this.handleInputChange(event)
                                }
                              />
                            </div>
                          )}

                          <div className="gap20" />
                          <div className="row">
                            <div className="col-md-6">
                              <Checkbox
                                label={
                                  "Continue displaying this question if this answer is selected (No points will be given if this is checked)"
                                }
                                isChecked={this.state.ansIgnoreSubmission}
                                handleCheckboxChange={this.toggleIgnoreSubmissionCheckbox.bind(
                                  this
                                )}
                                key={this.state.keyCheckbox}
                              />
                            </div>
                            <div className="col-lg-6">
                              {this.isRenderSelectedAnsCheck() && (
                                <Checkbox
                                  label={"Selected by default"}
                                  isChecked={this.state.ansSelected}
                                  handleCheckboxChange={this.toggleAnsCheckbox.bind(
                                    this
                                  )}
                                  key={this.state.keyCheckbox}
                                />
                              )}
                            </div>
                          </div>
                          <div className="gap10" />
                          {this.state.ansIgnoreSubmission && (
                            <>
                              <Checkbox
                                label={
                                  "Don't show this result inside of the Accountability Responses List"
                                }
                                isChecked={this.state.ansDontShow}
                                handleCheckboxChange={this.toggleAnsDontShow.bind(
                                  this
                                )}
                                key={this.state.keyAnsDontShow}
                              />
                              <div className="gap10" />
                            </>
                          )}
                          {!this.state.ansIgnoreSubmission && (
                            <>
                              <div className="gap10" />
                              <div className="row">
                                <div className="col-md-2">
                                  <HelpTextDiv
                                    id="idChallengePoints"
                                    label="Learn more about the Points"
                                    type="addChallengeQue"
                                    isInline="false"
                                  />
                                  <div className="mdInput mdFocussed">
                                    <label>Points</label>
                                    <input
                                      type="number"
                                      name="ansPoint"
                                      min={0}
                                      className="ansPoint"
                                      value={this.state.ansPoint}
                                      onChange={(event) =>
                                        this.handleInputChange(event)
                                      }
                                    />
                                  </div>
                                  <div className="gap20" />
                                </div>
                              </div>
                              <Checkbox
                                label={"Add Sub Item if this is selected"}
                                isChecked={this.state.isAnsSubItem}
                                handleCheckboxChange={this.toggleIsAnsSubItemCheckbox.bind(
                                  this
                                )}
                                key={this.state.keyIsAnsSubItem}
                              />

                              {this.state.isAnsSubItem && (
                                <>
                                  <div className="gap20" />
                                  <div className="row">
                                    <div className="col-sm-4">
                                      <div className="havingHelpText">
                                        <p>Type</p>
                                      </div>
                                      <div className="tabsHolder radioTabs">
                                        <RadioTag
                                          onCheckChange={this.onSelectItem.bind(
                                            this
                                          )}
                                          labelList={[
                                            { name: "Text", value: "text" },
                                            {
                                              name: "Paragraph",
                                              value: "paragraph",
                                            },
                                          ]}
                                          id={"ansSubItemType"}
                                          selectType={"value"}
                                          key={this.state.keyAnsSubItemType}
                                          selectedList={[
                                            this.state.ansSubItemType,
                                          ]}
                                        />
                                      </div>
                                    </div>
                                    {this.state.ansSubItemType === "text" && (
                                      <div className="col-sm-8">
                                        <div
                                          className={
                                            this.state.ansSubItemLabel !== ""
                                              ? "mdInput mdFocussed"
                                              : "mdInput"
                                          }>
                                          <label>Sub Item Label</label>
                                          <input
                                            type="text"
                                            name="ansSubItemLabel"
                                            value={this.state.ansSubItemLabel}
                                            onChange={(event) =>
                                              this.handleInputChange(event)
                                            }
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                  {this.state.ansSubItemType ===
                                    "paragraph" && (
                                    <>
                                      <div className="gap20" />
                                      <div className="inputFakeLabelFocussed">
                                        <p>Sub Item Value</p>
                                      </div>
                                      <div className="add-task-comment">
                                        <DraftEditor
                                          key={this.state.keyAnsSubItemValue}
                                          cType="task"
                                          defaultText=""
                                          lineHeight={"inherit"}
                                          textAlign={"left"}
                                          html={this.state.ansSubItemValue}
                                          json={
                                            this.state.ansSubItemValueObject
                                          }
                                          onChange={this.onChangeParagaphValue.bind(
                                            this
                                          )}
                                        />
                                      </div>
                                    </>
                                  )}
                                </>
                              )}

                              <div className="gap20" />
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="havingHelpText">
                                    <p>On Form Submission</p>
                                  </div>
                                  <div className="tabsHolder radioTabs">
                                    <RadioTag
                                      onCheckChange={this.onSelectFormSubmission.bind(
                                        this
                                      )}
                                      labelList={[
                                        {
                                          name: "Leave Tags Alone",
                                          value: "leave",
                                        },
                                        {
                                          name: "Inactivate Tags",
                                          value: "inactivate",
                                        },
                                        { name: "Custom", value: "custom" },
                                      ]}
                                      id={"ansFormSubmission"}
                                      selectType={"value"}
                                      key={this.state.keyFormSubmission}
                                      selectedList={[
                                        this.state.ansFormSubmission,
                                      ]}
                                    />
                                  </div>
                                  <div className="gap10" />
                                </div>
                              </div>
                              {this.state.ansFormSubmission ===
                                "inactivate" && (
                                <p>
                                  The question will only be displayed until this
                                  answer is selected. At that point we will
                                  inactivate their tag and they will no longer
                                  have it.
                                </p>
                              )}
                              {this.state.ansFormSubmission === "leave" && (
                                <p>
                                  The questions will continue to be displayed
                                  every day.
                                </p>
                              )}
                              {this.state.ansFormSubmission === "custom" && (
                                <p>
                                  Allows you to add or inactivate tags as you
                                  wish once they select this answer.
                                </p>
                              )}
                              {this.state.ansFormSubmission === "custom" && (
                                <>
                                  <div className="gap20" />
                                  <p>
                                    Add the following tags if this answer is
                                    selected (optional):
                                  </p>
                                  <TagSelect
                                    id={"add-question-add-ans-tags"}
                                    key={this.state.keyAnsAddTag}
                                    selectedValues={
                                      this.state.selectedAnsAddTags
                                    }
                                    searchKey={"name"}
                                    selectKey={"name"}
                                    lblText={"Select Tag"}
                                    data={this.state.systemTags}
                                    onChange={this.onChangeAnsAddTags}
                                    isClickable={true}
                                    reload={() => {
                                      this.getSystemTags();
                                    }}
                                  />
                                  <p>
                                    Inactivate the following tags if this answer
                                    is selected (optional):
                                  </p>
                                  <TagSelect
                                    id={"add-question-inactivate-ans-tags"}
                                    key={this.state.keyAnsInactiveTag}
                                    selectedValues={
                                      this.state.selectedAnsInactiveTags
                                    }
                                    searchKey={"name"}
                                    selectKey={"name"}
                                    lblText={"Select Tag"}
                                    data={this.state.systemTags}
                                    onChange={this.onChangeAnsInactiveTags}
                                    isClickable={true}
                                    reload={() => {
                                      this.getSystemTags();
                                    }}
                                  />
                                  {this.state.selectedAttributeTags.length > 0}
                                  <>
                                    <div className="card">
                                      <div className="container-fluid">
                                        <div className="gap20" />
                                        <Checkbox
                                          label={"Add Tag Attributes"}
                                          isChecked={this.state.isTagAttributes}
                                          handleCheckboxChange={this.toggleTagAttributesCheckbox.bind(
                                            this
                                          )}
                                          key={this.state.keyIsTagAttributes}
                                        />
                                        <div className="gap20" />
                                        {this.state.isTagAttributes && (
                                          <>
                                            <p>
                                              Select tag to add attributes
                                              (optional):
                                            </p>
                                            <TagSelect
                                              id={
                                                "add-question-attribute-ans-tags"
                                              }
                                              key={this.state.keyAttributeTag}
                                              selectedValues={
                                                this.state.selectedAttributeTags
                                              }
                                              searchKey={"name"}
                                              selectKey={"name"}
                                              lblText={"Select Tag"}
                                              singleSelection
                                              data={this.state.systemTags}
                                              onChange={
                                                this.onChangeAttributeTags
                                              }
                                              isClickable={true}
                                              reload={() => {
                                                this.getSystemTags();
                                              }}
                                            />
                                            <div className="gap10" />
                                            <div className="row">
                                              <div className="col-sm-6">
                                                <div
                                                  className={
                                                    this.state.attName !== ""
                                                      ? "mdInput mdFocussed"
                                                      : "mdInput"
                                                  }>
                                                  <label>Name</label>
                                                  <input
                                                    type="text"
                                                    name="attName"
                                                    onChange={this.handleInputChange.bind(
                                                      this
                                                    )}
                                                    value={this.state.attName}
                                                  />
                                                </div>
                                              </div>
                                              <div className="col-sm-6">
                                                <div
                                                  className={
                                                    this.state.attValue !== ""
                                                      ? "mdInput mdFocussed"
                                                      : "mdInput"
                                                  }>
                                                  <label>Value</label>
                                                  <input
                                                    type="text"
                                                    name="attValue"
                                                    onChange={this.handleInputChange.bind(
                                                      this
                                                    )}
                                                    value={this.state.attValue}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            <div className="gap20" />
                                            <button
                                              className="btn btn-primary"
                                              onClick={() =>
                                                this.onPressCreateCustomValue()
                                              }>
                                              Create Custom Value
                                            </button>
                                            <div className="gap10" />
                                          </>
                                        )}
                                      </div>
                                    </div>
                                    <div key={this.state.attrRefresh}>
                                      {this.state.tagAttributes.length > 0 && (
                                        <>
                                          <label>Added Custom Values</label>
                                          <div className="gap20" />
                                          <table className="table table-bordered">
                                            <thead>
                                              <tr>
                                                <th>Name</th>
                                                <th>Value</th>
                                                <th>Action</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {this.state.tagAttributes.map(
                                                (i) => (
                                                  <tr key={i.name}>
                                                    <td>{i.name}</td>
                                                    <td>{i.value}</td>

                                                    <td className="min-width-100">
                                                      <button
                                                        className="btn btn-danger btn-sm border-0"
                                                        onClick={this.onClickDeleteCustomValue.bind(
                                                          this,
                                                          i
                                                        )}>
                                                        <i className="fa fa-trash" />
                                                      </button>
                                                    </td>
                                                  </tr>
                                                )
                                              )}
                                            </tbody>
                                          </table>
                                        </>
                                      )}

                                      <div className="gap20" />
                                    </div>
                                  </>
                                  <div className="gap10" />
                                </>
                              )}
                            </>
                          )}

                          <Checkbox
                            label={
                              "Redirect user to another page if they select this answer"
                            }
                            isChecked={this.state.redirectUser}
                            handleCheckboxChange={this.toggleRedirectUserCheckbox.bind(
                              this
                            )}
                            key={this.state.keyCheckbox}
                          />
                          {this.state.redirectUser && (
                            <>
                              <div className="gap10"></div>
                              <SelectScreen
                                label={"Redirect to"}
                                challengeId={this.state.redirectChallengeId}
                                challengeItemId={
                                  this.state.redirectChallengeItemId
                                }
                                screen={this.state.redirectScreen}
                                onChange={(value) => {
                                  this.setState({
                                    redirectChallengeId: value.challengeId,
                                    redirectChallengeItemId:
                                      value.challengeItemId,
                                    redirectScreen: value.screen,
                                  });
                                }}
                              />
                              <div className="gap10"></div>
                              <div className="mdInput mdFocussed">
                                <label>Redirect Weight</label>
                                <input
                                  type="number"
                                  name="redirectWeight"
                                  min={0}
                                  className="ansPoint"
                                  value={this.state.redirectWeight}
                                  onChange={(event) =>
                                    this.handleInputChange(event)
                                  }
                                />
                              </div>
                              <label>
                                If multiple answers redirect, the user will be
                                redirected to the page with the highest weight.
                              </label>
                              <div className="gap10"></div>
                            </>
                          )}

                          <div className="text-right">
                            {this.state.selectedEditAnswerIndex >= 0 && (
                              <button
                                className="btn btn-default margin-right-10"
                                onClick={this.onPressCancelEdit.bind(this)}>
                                Cancel Edit
                              </button>
                            )}
                            <button
                              className="btn btn-primary"
                              onClick={() => this.onPressAddAnswer()}>
                              {this.state.selectedEditAnswerIndex >= 0
                                ? "Update Answer"
                                : " Add Answer"}
                            </button>
                          </div>
                          <div className="gap20" />
                        </div>
                      </div>
                    )}
                  </VelocityTransitionGroup>
                </div>
              </div>

              {this.state.answerList.length > 0 && (
                <div>
                  <h3 className="noMargin">Current Answers</h3>
                  <div className="gap20" />
                  <div className="card">
                    <div className="container-fluid">
                      <div className="gap20" />
                      <SortableAnswerList
                        items={this.state.answerList}
                        onSortEnd={this.onSortEnd}
                        showEdit={true}
                        onPressEdit={this.onPressEdit.bind(this)}
                        onPressRemove={this.onPressRemove.bind(this)}
                      />
                      <div className="gap20" />
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}

          <div className="text-right">
            <button
              className="btn btn-primary"
              onClick={() => this.onPressAddQuestion()}>
              {this.state.queId === 0 ? "Add Question" : "Update Question"}
            </button>
            <div className="gap20" />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  message: state.challengeReducer.message,
  success: state.challengeReducer.success,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      resetStatus,
    },
    dispatch
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddChallengeQuestion);
