import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import $ from "jquery";
import TitleBox from "../../../components/general/titleBox";
import PageContent from "../../../components/editor/pageContent";
import ContentHtml from "../../../components/general/contentHtml";

import { getEditorType, showNotification, toPromise } from "../../../helpers";
import {
  callCreateEditorPopup,
  callUpdateEditorPopup,
  callGetEditorPopupsById,
} from "../../../services/popupService";

class AddEditorPopupPage extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    let popup = {
      popupId: 0,
      title: "",
      html: "",
      content: {},
      editorType: "mp",
      draftId: "",
      draftImage: "",
    };

    if (this.props.history.location.state !== undefined) {
      let pData = this.props.history.location.state.popup;
      popup.popupId = pData._id;
    }

    this.state = {
      popupId: popup.popupId,
      title: popup.title,
      html: popup.html,
      content: popup.content,
      editorType: popup.editorType,
      draftId: popup.draftId,
      draftImage: popup.draftImage,
      pageContent: Math.random(),
    };
  }

  componentDidMount() {
    $(".close").on("click", () => {
      $("#previewModal").css("display", "none");
    });
    if (this.state.popupId !== 0) {
      this.getPopupInfo();
    }
  }

  async getPopupInfo() {
    let popup = {
      popupId: 0,
      title: "",
      html: "",
      content: {},
      editorType: "mp",
      draftId: "",
      draftImage: "",
    };
    let pData = await callGetEditorPopupsById(this.state.popupId);
    popup.popupId = pData._id;
    popup.title = pData.title;
    popup.html = pData.html || "";
    popup.content = pData.content;
    popup.draftId = pData.draft_id || "";
    popup.draftImage = pData.draft_image || "";

    popup.editorType = getEditorType(pData.html, pData.content);

    this.setState({
      popupId: popup.popupId,
      title: popup.title,
      html: popup.html,
      content: popup.content,
      editorType: popup.editorType,
      draftId: popup.draftId,
      draftImage: popup.draftImage,
      pageContent: Math.random(),
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  resetProps() {
    this.setState({ title: "", content: "", html: "" });
  }

  onChangeContent(html, content, draftId, draftImage) {
    this.setState(
      {
        content: content,
        html: html,
        draftId: draftId,
        draftImage: draftImage,
        pageContent: Math.random(),
      },
      () => {
        this.onPressSave();
      }
    );
  }

  onPressPreview() {
    $("#previewModal").css("display", "block");
  }

  async onPressSave() {
    if (this.state.title.trim().length === 0) {
      showNotification("error", "Title is required", 4000);
      return;
    }

    /*eslint-disable*/
    let apiData = {
      title: this.state.title,
      content: this.state.content,
      html: this.state.html,
      draft_id: this.state.draftId,
      draft_image: this.state.draftImage,
    };

    if (this.state.popupId !== 0) {
      let [err, data] = await toPromise(
        callUpdateEditorPopup(this.state.popupId, apiData)
      );

      /*eslint-enable*/
      if (err) {
        showNotification("error", err.message, 5000);
      } else {
        showNotification("success", "Popup updated successfully", 4000);
      }
    } else {
      /*eslint-disable*/
      let [err, data] = await toPromise(callCreateEditorPopup(apiData));
      /*eslint-enable*/
      if (err) {
        showNotification("error", err.message, 5000);
      } else {
        showNotification("success", "Popup created successfully", 4000);
        this.resetProps();
      }
    }
  }

  renderPreviewModal() {
    return (
      <div id="previewModal" className="previewModal">
        <div className="previewModalContent">
          <span className="close">&times;</span>
          <ContentHtml html={this.state.html} type={"inner"} />
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderPreviewModal()}
        <div className="container-fluid">
          <TitleBox
            title={this.state.popupId === 0 ? "Add Popup" : "Edit Popup"}
            showBackBtn={this.state.popupId !== 0}
          />
          <div className="gap20" />
          <div className="card">
            <div className="container-fluid">
              <div className="gap20" />
              <div
                className={
                  this.state.title !== "" ? "mdInput mdFocussed" : "mdInput"
                }>
                <label>Title</label>
                <input
                  type="text"
                  name="title"
                  onChange={this.handleInputChange.bind(this)}
                  value={this.state.title}
                />
              </div>
              <div className="gap20" />
              <PageContent
                key={this.state.pageContent}
                title={this.state.title}
                screen={"add-editor-popup"}
                html={this.state.html}
                content={this.state.content}
                onChangeData={this.onChangeContent.bind(this)}
                pageName={"addEditorPopup"}
                // displayMode={"popup"}
                previewType={"mp-preview"}
                showContent={true}
                previewNote={false}
                draftId={this.state.draftId}
                draftImage={this.state.draftImage}
                editorType={this.state.editorType}
              />
              <div className="gap20" />
              <div className="text-right">
                <button
                  className="btn btn-primary"
                  onClick={() => this.onPressSave()}>
                  Save
                </button>
              </div>
              <div className="gap20" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddEditorPopupPage);
