import {
  CREATE_SYSTEM_TAG_SUCCESS,
  CREATE_SYSTEM_TAG_FAILURE,
  GET_SYSTEM_TAG_SUCCESS,
  GET_GENERIC_TAG_SUCCESS,
  RESET_STATUS,
  CREATE_GENERIC_TAG_SUCCESS,
  CREATE_GENERIC_TAG_FAILURE,
  UPDATE_SYSTEM_TAG_SUCCESS,
  UPDATE_SYSTEM_TAG_FAILURE
} from "../types";

import config from "../../helpers/config";

import {
  callCreateSystemTag,
  callGetSystemTags,
  callGetGenericTags,
  callCreateGenericTag,
  callUpdateSystemTag
} from "../../services/index";

/*
  This method is used to reset the status
  This will reset message and success states
*/
export const resetStatus = data => {
  return async dispatch => {
    dispatch({
      type: RESET_STATUS
    });
  };
};

export const createSystemTag = data => {
  return async dispatch => {
    return callCreateSystemTag(data)
      .then(data => {
        dispatch({
          type: CREATE_SYSTEM_TAG_SUCCESS,
          message: config.messages.createChlgQueSuccess,
          success: true
        });
      })
      .catch(err => {
        dispatch({
          type: CREATE_SYSTEM_TAG_FAILURE,
          message: config.messages.createChlgQueError,
          success: false
        });
      });
  };
};

export const updateSystemTag = (id, data) => {
  return async dispatch => {
    return callUpdateSystemTag(id, data)
      .then(data => {
        dispatch({
          type: UPDATE_SYSTEM_TAG_SUCCESS,
          message: config.messages.updateMessage,
          success: true
        });
      })
      .catch(err => {
        dispatch({
          type: UPDATE_SYSTEM_TAG_FAILURE,
          message: config.messages.createChlgQueError,
          success: false
        });
      });
  };
};

export const getSystemTags = () => {
  return async dispatch => {
    return callGetSystemTags().then(data => {
      dispatch({
        type: GET_SYSTEM_TAG_SUCCESS,
        data: data
      });
    });
  };
};

export const createGenericTag = data => {
  return async dispatch => {
    return callCreateGenericTag(data)
      .then(data => {
        dispatch({
          type: CREATE_GENERIC_TAG_SUCCESS,
          message: config.messages.createChlgQueSuccess,
          success: true
        });
      })
      .catch(err => {
        dispatch({
          type: CREATE_GENERIC_TAG_FAILURE,
          message: config.messages.createChlgQueError,
          success: false
        });
      });
  };
};

export const getGenericTags = type => {
  return async dispatch => {
    return callGetGenericTags(type).then(data => {
      dispatch({
        type: GET_GENERIC_TAG_SUCCESS,
        data: data
      });
    });
  };
};
