import { showLoading, hideLoading, callApi } from "../helpers";
import config from "../helpers/config";
import { getItem } from "../helpers/storage";

import * as api from "./api";

const DOMAIN_ID = window.location.hostname;
const DEV_FS = "JJ4o8njFEl3id839DNebby30NDkr8rnFhbfoktgr5";

function processRequest(request) {
  showLoading();

  return fetch(request)
    .then((res) => {
      hideLoading();
      return res.json();
    })
    .catch((err) => {
      hideLoading();
      return err;
    });
}

function getHeaders() {
  let token = getItem("accessToken");
  let headers = {};
  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }
  return headers;
}

function callGetSettings(type = "all", auth = "yes") {
  let url = `${config.urls.apiUrl}/member-pages/settings?domainid=${DOMAIN_ID}&devfs=${DEV_FS}&fl=${type}`;

  let token = getItem("accessToken");
  let headers = {};
  if (token && auth === "yes") {
    headers["Authorization"] = `Bearer ${token}`;
  }

  var request = new Request(url, {
    method: "GET",
    headers: headers,
  });

  return processRequest(request);
}

function callSaveSettings(data) {
  let url = `${config.urls.apiUrl}/member-pages/settings?domainid=${DOMAIN_ID}&devfs=${DEV_FS}`;

  let headers = getHeaders();
  var request = new Request(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...headers,
    },
    body: JSON.stringify(data),
  });
  return processRequest(request);
}

function callEmailTemplateData(name = false) {
  let url = `${config.urls.apiUrl}/member-pages/data?domainid=${DOMAIN_ID}&devfs=${DEV_FS}&action=contenteditortemplates`;
  if (name) {
    url = `${url}&name=y`;
  }
  let headers = getHeaders();

  var request = new Request(url, {
    method: "GET",
    headers: headers,
  });
  return processRequest(request);
}

function callEmailTemplateById(id) {
  let url = `${config.urls.apiUrl}/member-pages/data?domainid=${DOMAIN_ID}&devfs=${DEV_FS}&action=contenteditortemplatebyid&id=${id}`;

  let headers = getHeaders();

  var request = new Request(url, {
    method: "GET",
    headers: headers,
  });
  return processRequest(request);
}

function callEmailFromTemplatesById(id) {
  let url = `${config.urls.apiUrl}/member-pages/data?domainid=${DOMAIN_ID}&devfs=${DEV_FS}&action=emailbyid&id=${id}`;

  let headers = getHeaders();

  var request = new Request(url, {
    method: "GET",
    headers: headers,
  });
  return processRequest(request);
}

function callSMSFromTemplatesById(id) {
  let url = `${config.urls.apiUrl}/member-pages/data?domainid=${DOMAIN_ID}&devfs=${DEV_FS}&action=smsbyid&id=${id}`;

  let headers = getHeaders();

  var request = new Request(url, {
    method: "GET",
    headers,
  });
  return processRequest(request);
}

function callGetMemberPagesData(data) {
  let url = `${config.urls.apiUrl}/member-pages/data?domainid=${DOMAIN_ID}&devfs=${DEV_FS}`;

  let headers = getHeaders();

  var request = new Request(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...headers,
    },
    body: JSON.stringify(data),
  });
  return processRequest(request);
}

function callVerifyAws(data) {
  let url = `${config.urls.apiUrl}/member-pages/aws-verify?domainid=${DOMAIN_ID}&devfs=${DEV_FS}`;

  let headers = getHeaders();

  var request = new Request(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...headers,
    },
    body: JSON.stringify(data),
  });
  return processRequest(request);
}

function callSearchSettings(newDomainId) {
  let url = `${config.urls.apiUrl}/member-pages/settings/search?domainid=${DOMAIN_ID}&devfs=${DEV_FS}&newdomainid=${newDomainId}`;

  let headers = getHeaders();

  var request = new Request(url, {
    method: "GET",
    headers,
  });
  return processRequest(request);
}

function callSignupMember(data) {
  let url = `${config.urls.apiUrl}/member-pages/signup?domainid=${DOMAIN_ID}&devfs=${DEV_FS}`;

  let headers = getHeaders();

  var request = new Request(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...headers,
    },
    body: JSON.stringify(data),
  });
  return processRequest(request);
}

function callGetMemberPagesDB(memberId) {
  let url = `${config.urls.apiUrl}/member-pages/settings/mdb?domainid=${DOMAIN_ID}&devfs=${DEV_FS}&member_id=${memberId}`;

  let headers = getHeaders();

  var request = new Request(url, {
    method: "GET",
    headers,
  });

  return processRequest(request);
}

function callGetMPStripeCustomer(data) {
  let url = `${config.urls.apiUrl}/member-pages/billing/get/customer?domainid=${DOMAIN_ID}&devfs=${DEV_FS}`;

  let headers = getHeaders();

  var request = new Request(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...headers,
    },
    body: JSON.stringify(data),
  });

  return processRequest(request);
}

function callCreateMPSMSCustomerOrder(data) {
  let url = `${config.urls.apiUrl}/member-pages/billing/sms-order?domainid=${DOMAIN_ID}&devfs=${DEV_FS}`;

  let headers = getHeaders();

  var request = new Request(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...headers,
    },
    body: JSON.stringify(data),
  });

  return processRequest(request);
}

function callCreateMPStripeCustomer(data) {
  let url = `${config.urls.apiUrl}/member-pages/billing/customer?domainid=${DOMAIN_ID}&devfs=${DEV_FS}`;

  let headers = getHeaders();

  var request = new Request(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...headers,
    },
    body: JSON.stringify(data),
  });

  return processRequest(request);
}

function callCreateMPStripeCard(data) {
  let url = `${config.urls.apiUrl}/member-pages/billing/card?domainid=${DOMAIN_ID}&devfs=${DEV_FS}`;

  let headers = getHeaders();

  var request = new Request(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...headers,
    },
    body: JSON.stringify(data),
  });

  return processRequest(request);
}

function callGetInvoice(data) {
  let url = `${config.urls.apiUrl}/pdf/invoice?domainid=${DOMAIN_ID}&devfs=${DEV_FS}`;

  let headers = getHeaders();

  var request = new Request(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      domainid: window.location.hostname,
      ...headers,
    },
    body: JSON.stringify(data),
  });

  showLoading();

  return fetch(request)
    .then((res) => {
      hideLoading();
      return res.blob();
    })
    .catch((err) => {
      hideLoading();
      return err;
    });
}

function callCreateFormPDF(data) {
  let url = `${config.urls.apiUrl}/pdf/form?domainid=${DOMAIN_ID}&devfs=${DEV_FS}`;

  let headers = getHeaders();

  var request = new Request(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      domainid: window.location.hostname,
      ...headers,
    },
    body: JSON.stringify(data),
  });

  return processRequest(request);
}

function callGetSSOUsers(userId) {
  let url = `${config.urls.apiUrl}/member-pages/sso/users?domainid=${DOMAIN_ID}&devfs=${DEV_FS}&userId=${userId}`;

  let headers = getHeaders();

  var request = new Request(url, {
    method: "GET",
    headers,
  });
  return processRequest(request);
}

function callGetUserSettings(userId, field) {
  let url = `${config.urls.apiUrl}/member-pages/settings/user?domainid=${DOMAIN_ID}&devfs=${DEV_FS}&userId=${userId}&field=${field}`;

  let headers = getHeaders();

  var request = new Request(url, {
    method: "GET",
    headers,
  });
  return processRequest(request);
}

function callSaveUserSettings(userId, field, data) {
  let url = `${config.urls.apiUrl}/member-pages/settings/user?domainid=${DOMAIN_ID}&devfs=${DEV_FS}&userId=${userId}&field=${field}`;

  let headers = getHeaders();

  var request = new Request(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      domainid: window.location.hostname,
      ...headers,
    },
    body: JSON.stringify(data),
  });

  return processRequest(request);
}

function callDisableSite(userId, status) {
  let url = `${config.urls.apiUrl}/member-pages/disable-site?domainid=${DOMAIN_ID}&devfs=${DEV_FS}&userId=${userId}&status=${status}`;

  let headers = getHeaders();

  var request = new Request(url, {
    method: "GET",
    headers,
  });
  return processRequest(request);
}

function callGetSSOToken(data) {
  let url = `${config.urls.apiUrl}/member-pages/sso/token?domainid=${DOMAIN_ID}&devfs=${DEV_FS}`;

  let headers = getHeaders();

  var request = new Request(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      domainid: window.location.hostname,
      ...headers,
    },
    body: JSON.stringify(data),
  });

  return processRequest(request);
}

function callUpdateUserMemberPages(data) {
  let url = `${config.urls.apiUrl}/member-pages/sso/update-user?domainid=${DOMAIN_ID}&devfs=${DEV_FS}`;

  let headers = getHeaders();

  var request = new Request(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      domainid: window.location.hostname,
      ...headers,
    },
    body: JSON.stringify(data),
  });

  return processRequest(request);
}

function callSendLoginSMS(data) {
  let url = `${config.urls.apiUrl}/member-pages/sso/send-sms?domainid=${DOMAIN_ID}&devfs=${DEV_FS}`;

  let headers = getHeaders();

  var request = new Request(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      domainid: window.location.hostname,
      ...headers,
    },
    body: JSON.stringify(data),
  });

  return processRequest(request);
}

function callGetDatabases(type = "normal", emailCount = "no") {
  let url = `${config.urls.apiUrl}/member-pages/dbs?domainid=${DOMAIN_ID}&devfs=${DEV_FS}&type=${type}&emailcount=${emailCount}`;

  let headers = getHeaders();

  var request = new Request(url, {
    method: "GET",
    headers,
  });
  return processRequest(request);
}

function callUpdateDatabaseEntry(data) {
  let url = `${config.urls.apiUrl}/member-pages/update-db?domainid=${DOMAIN_ID}&devfs=${DEV_FS}`;

  let headers = getHeaders();

  var request = new Request(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      domainid: window.location.hostname,
      ...headers,
    },
    body: JSON.stringify(data),
  });

  return processRequest(request);
}

function callDeleteDatabaseEntry(data) {
  let url = `${config.urls.apiUrl}/member-pages/delete-db?domainid=${DOMAIN_ID}&devfs=${DEV_FS}`;

  let headers = getHeaders();

  var request = new Request(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      domainid: window.location.hostname,
      ...headers,
    },
    body: JSON.stringify(data),
  });

  return processRequest(request);
}

function callRestoreDatabaseEntry(data) {
  let url = `${config.urls.apiUrl}/member-pages/restore-db?domainid=${DOMAIN_ID}&devfs=${DEV_FS}`;

  let headers = getHeaders();

  var request = new Request(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      domainid: window.location.hostname,
      ...headers,
    },
    body: JSON.stringify(data),
  });

  return processRequest(request);
}

function callCloneSite(data) {
  return callApi(api.service("general").create(data));
}

function callConnectQuickbook() {
  let key = getItem("sk");
  let url = `${config.urls.apiUrl}/quickbook/login?domainid=${DOMAIN_ID}&devfs=${DEV_FS}&key=${key}`;

  let headers = getHeaders();

  var request = new Request(url, {
    method: "GET",
    headers,
  });
  return processRequest(request);
}

export {
  callGetSettings,
  callSaveSettings,
  callSearchSettings,
  callVerifyAws,
  callSignupMember,
  callGetMemberPagesDB,
  callGetMPStripeCustomer,
  callCreateMPStripeCustomer,
  callCreateMPStripeCard,
  callGetInvoice,
  callCreateFormPDF,
  callGetSSOUsers,
  callGetSSOToken,
  callDisableSite,
  callEmailTemplateData,
  callGetMemberPagesData,
  callGetDatabases,
  callEmailTemplateById,
  callUpdateDatabaseEntry,
  callDeleteDatabaseEntry,
  callRestoreDatabaseEntry,
  callGetUserSettings,
  callSaveUserSettings,
  callCreateMPSMSCustomerOrder,
  callSendLoginSMS,
  callUpdateUserMemberPages,
  callEmailFromTemplatesById,
  callCloneSite,
  callSMSFromTemplatesById,
  callConnectQuickbook,
};
