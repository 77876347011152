import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DateTimePicker from "react-datetime-picker";
import * as $ from "jquery";
import moment from "moment-timezone";

import HelpTextDiv from "../../../components/form/helpTextDiv";
import {
  showNotification,
  isTemplateSite,
  toPromise,
  showOnClickNotification,
  getParameterByName,
  isDashboardSite,
} from "../../../helpers";

import { getGenericTags, getSystemTags } from "../../../modules/actions/index";

import PageContent from "../../../components/editor/pageContent";
import Checkbox from "../../../components/form/checkBox";
import StatusSwitch from "../../../components/form/statusSwitch";
import RadioTag from "../../../components/form/radioTag";
import TitleBox from "../../../components/general/titleBox";
import TagUsers from "../../../components/form/tagUsers";
import * as api from "../../../services/api";
import {
  callCreateGenericTag,
  callGetUsersByQuery,
  callCreateMemberTagAction,
  callCreateEventLog,
  callDeleteEventLogByQuery,
  callGetEmailTemplates,
  callCreateEmail,
  callUpdateEmail,
  callGetEmailUsingId,
  callGetSystemTagById,
  callGetChlgById,
  callGetGenericTagUsingId,
  callCreateUser,
} from "../../../services/index";
import { SettingsHelper } from "../../../helpers/settings";
import GenericTagSelect from "../../../components/form/genericTagSelect";
import Onboarding from "../onboarding/onboarding";
import { callGetGenericTags } from "../../../services/emailService";
import TagInfo from "../../../components/form/tagInfo";
import { callGetSettings } from "../../../services/settingService";

// moment.tz.setDefault("America/Denver");

class CreateEmail extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    let emailId = getParameterByName("eid");
    let showBack = getParameterByName("back");
    let editor = getParameterByName("editor") ?? "unlayer";

    let host = window.location.host;
    if (host.includes("templates") || host.includes("princek")) {
      editor = "mp";
    }

    let emailTypeClass = {
      automated: "",
      onetime: "",
    };

    let emailTimeClass = {
      soon: "",
      minutes: "",
    };

    let emailTimeZoneClass = {
      standard: "active",
    };

    let tagUserType = "tag";
    let tagUserIds = [];
    let directEmail = false;
    let name = "";

    let userId = getParameterByName("user_id");
    let userName = getParameterByName("user_name");
    if (userId && userName) {
      tagUserIds = [userId];
      tagUserType = "users";
      directEmail = true;
      name = `Direct Email to ${userName}`;
    }

    let emailInfo = {
      directEmail: directEmail,
      _id: emailId ? emailId : 0,
      isActive: true,
      isSystemEmail: false,
      name: name,
      description: "",
      title: "",
      content: {},
      htmlContent: "",
      emailType: "",
      emailTypeClass: emailTypeClass,
      emailTime: "",
      emailTimeClass: emailTimeClass,
      emailTimeZone: "America/Denver",
      emailTimeZoneClass: emailTimeZoneClass,
      emailTimeDelay: "",
      emailTimeDate: "",
      emailOptionType: "1",
      selectedEmailTypeList: ["1"],
      selectedEmailTags: [],
      selectedIncludedTags: [],
      selectedExcludedTags: [],
      emailStatusLabel: "Currently Active",
      emailFrom: "",
      fromTemplate: false,
      sendTimeType: "send",
      sendMins: "0",
      sendTime: "08:00",
      tagUserType: tagUserType,
      tagUserIds: tagUserIds,
      oneTimeType: "date",
    };

    this.sentLog = false;

    this.state = {
      directEmail: directEmail,
      showBack: showBack === "y" ? true : false,
      emailId: emailInfo._id,
      isActive: emailInfo.isActive,
      isSystemEmail: emailInfo.isSystemEmail,
      name: emailInfo.name,
      description: emailInfo.description,
      title: emailInfo.title,
      content: emailInfo.content,
      htmlContent: emailInfo.htmlContent,
      tags: [],
      fTags: { new: [], existing: emailInfo.selectedEmailTags },
      addTags: emailInfo.selectedIncludedTags,
      excludeTags: emailInfo.selectedExcludedTags,
      systemTags: [],
      selectedEmailTags: emailInfo.selectedEmailTags,
      selectedExcludedTags: emailInfo.selectedExcludedTags,
      selectedIncludedTags: emailInfo.selectedIncludedTags,
      keyTagUsers: Math.random(),
      emailType: emailInfo.emailType,
      emailTypeClass: emailInfo.emailTypeClass,
      emailTime: emailInfo.emailTime,
      emailTimeClass: emailInfo.emailTimeClass,
      emailTimeZone: emailInfo.emailTimeZone,
      emailTimeZoneClass: emailInfo.emailTimeZoneClass,
      emailTimeDate: emailInfo.emailTimeDate,
      emailOptionType: emailInfo.emailOptionType,
      selectedEmailTypeList: emailInfo.selectedEmailTypeList,
      emailTimeDelay: emailInfo.emailTimeDelay,
      keyTag: Math.random(),
      keyRadio: Math.random(),
      emailStatusLabel: emailInfo.emailStatusLabel,
      sendMailAddress: "",
      userData: this.props.userData || {},
      eventLogs: [],
      editMailAction: "",
      emailFrom: emailInfo.emailFrom,
      fromTemplate: emailInfo.fromTemplate,
      emailFromList: [],
      isFromTemplate: false,
      keyFromTemplate: Math.random(),
      templates: [],
      keyEmailContent: Math.random(),
      keySwitch: Math.random(),
      showAll: true,
      editorType: editor,
      keySendLimit: Math.random(),
      sendLimit: false,
      countEmails: 10,
      countHours: 2,
      usersCount: 0,
      sendTimeType: emailInfo.sendTimeType,
      sendMins: emailInfo.sendMins,
      sendTime: emailInfo.sendTime,
      selectedHour: "01",
      selectedMins: "00",
      selectedTimeType: "AM",
      tagUserType: emailInfo.tagUserType,
      tagUserIds: emailInfo.tagUserIds,
      oneTimeType: emailInfo.oneTimeType,
      emailTypes: [],
      keyEmailTimeZone: Math.random(),
      emailTypeIds: [],
      emailStatus: "active",
      showCategory: true,
      timezoneList: [
        {
          name: "Mountain Standard Time",
          value: "America/Denver",
        },
        {
          name: "Pacific Standard Time",
          value: "America/Los_Angeles",
        },
        {
          name: "Central Standard Time",
          value: "America/Chicago",
        },
        {
          name: "Eastern Standard Time",
          value: "America/New_York",
        },
      ],
      addTagAction: "",
      createdFrom: "normal",
      challengeName: "",
      ruleDay: "",
      tagName: "",
    };
  }

  checkPath(location) {
    let path = location.pathname;
    let type = "onetime";
    let showCategory = true;
    if (path.includes("add-automated-email")) {
      type = "automated";
      showCategory = false;
    }
    if (path.includes("add-single-email")) {
      type = "onetime";
      showCategory = false;
    }
    this.onClickEmailTypeOption(type);
    this.setState({ showCategory });
  }

  componentDidMount() {
    this.getEmailTypes();

    let location = this.props.history.location;
    this.checkPath(location);
    this.props.history.listen((location, action) => {
      this.checkPath(location);
    });

    this.getEmailGenericTags();
    // this.setupEventLog();
    this.getTemplates();
    setTimeout(() => {
      let settingsHelper = new SettingsHelper();

      if (this.state.emailFrom.length === 0) {
        this.setState({ emailFrom: "default" });
      }

      let fromEmailList = [];
      let defaultDomain = "";
      let domainList = settingsHelper.getDomainList();
      fromEmailList.push({
        value: "default",
        label: "Default Email",
      });

      domainList.forEach((domain) => {
        if (domain.type === "default") {
          defaultDomain = domain.domain;
        }
      });

      let subDomain = defaultDomain.split(".")[0] || "";
      let defaultFromEmail = subDomain ? subDomain + "@memberpages.app" : "";
      if (defaultFromEmail) {
        fromEmailList.push({
          value: defaultFromEmail,
          label: defaultFromEmail,
        });
      }

      let fromEmailListObj = settingsHelper.getFromEmailList();
      if (fromEmailListObj) {
        settingsHelper.getFromEmailList().forEach((element) => {
          fromEmailList.push({ value: element.email, label: element.email });
        });
      }
      this.setState(
        {
          emailFromList: fromEmailList,
        },
        () => {
          if (!this.state.isActive && this.state.emailId !== 0) {
            showNotification(
              "warning",
              "This email is currently inactive.",
              false
            );
          }
          this.checkForChallenge();
          this.getEmailInfo();
        }
      );
    }, 100);
  }

  async getEmailTypes() {
    let d = await callGetSettings("emailsettings");
    let emailTypes = d.data.email_settings.email_types || [];
    emailTypes = emailTypes.filter((d) => d.status === "active" || !d.status);
    let emailTypeIds = emailTypes.map((i) => i.value);
    if (!isDashboardSite() && !isTemplateSite()) {
      emailTypes = emailTypes.filter((d) => d.value !== "0");
    }
    this.setState({
      emailTypes: emailTypes,
      emailTypeIds,
    });
  }

  async checkForChallenge() {
    let cId = this.props.challengeId || getParameterByName("cId");
    let tId = this.props.tagId || getParameterByName("tId");

    if (cId && tId && this.state.emailId === 0) {
      let systemTag = await callGetSystemTagById(tId);
      let challengeInfo = await callGetChlgById(cId);
      let tagInfo = await callGetGenericTagUsingId(
        challengeInfo.email_group_id
      );

      this.setState({
        name: `${challengeInfo.name} - ${systemTag.name}`,
        selectedEmailTags: [tagInfo],
        fTags: { new: [], existing: [tagInfo] },
        keyTag: Math.random(),
        selectedEmailTypeList: ["Standard"],
        selectedIncludedTags: [systemTag],
        addTags: [systemTag],
        keyTagUsers: Math.random(),
        keyRadio: Math.random(),
        emailOptionType: "1",
      });
      this.onClickEmailTypeOption("automated");
    } else if (cId && this.state.emailId === 0) {
      let challengeInfo = await callGetChlgById(cId);
      let tagInfo = await callGetGenericTagUsingId(
        challengeInfo.email_group_id
      );

      this.setState({
        name: `${challengeInfo.name}`,
        selectedEmailTags: [tagInfo],
        fTags: { new: [], existing: [tagInfo] },
        keyTag: Math.random(),
        selectedEmailTypeList: ["Standard"],
        keyTagUsers: Math.random(),
        keyRadio: Math.random(),
        emailOptionType: "1",
      });
      this.onClickEmailTypeOption("automated");
    }
  }

  async getEmailInfo() {
    if (this.state.emailId !== 0) {
      let emailData = await callGetEmailUsingId(this.state.emailId);

      let emailTypeClass = {
        automated: "",
        onetime: "",
      };

      let emailTimeClass = {
        soon: "",
        minutes: "",
      };

      let emailTimeZoneClass = {
        standard: "active",
      };

      let emailInfo = {
        _id: this.state.emailId,
        isActive: true,
        isSystemEmail: false,
        name: "",
        description: "",
        title: "",
        content: {},
        htmlContent: "",
        emailType: "",
        emailTypeClass: emailTypeClass,
        emailTime: "",
        emailTimeClass: emailTimeClass,
        emailTimeZone: "America/Denver",
        emailTimeZoneClass: emailTimeZoneClass,
        emailTimeDelay: "",
        emailTimeDate: "",
        emailOptionType: "1",
        selectedEmailTypeList: [],
        selectedEmailTags: [],
        selectedIncludedTags: [],
        selectedExcludedTags: [],
        emailStatusLabel: "Currently Active",
        emailFrom: "",
        fromTemplate: false,
        editorType: "unlayer",
        sendLimit: false,
        countEmails: 10,
        countHours: 2,
        sendTimeType: "send",
        sendMins: "0",
        sendTime: "08:00",
        selectedHour: "01",
        selectedMins: "00",
        selectedTimeType: "AM",
        tagUserType: "tag",
        tagUserIds: [],
        oneTimeType: "date",
        emailStatus: "active",
      };

      if (emailData) {
        emailInfo._id = emailData._id;
        emailInfo.isActive =
          emailData.status === "active" || emailData.status === "completed"
            ? true
            : false;
        emailInfo.emailStatus = emailData.status || "active";
        emailInfo.isSystemEmail = emailData.system === "true" ? true : false;
        emailInfo.name = emailData.name;
        emailInfo.description = emailData.description;
        emailInfo.title = emailData.title;
        emailInfo.content = emailData.content || {};
        if (emailData.html !== "" && !emailInfo.content.body) {
          emailInfo.editorType = "mp";
        }
        emailInfo.htmlContent = emailData.html || "";
        emailInfo.emailType = emailData.type;
        emailInfo.emailOptionType = emailData.email_type || "";

        if (emailInfo.emailOptionType) {
          emailInfo.selectedEmailTypeList = [emailInfo.emailOptionType];
        }

        if (!emailInfo.isActive) {
          emailInfo.emailStatusLabel = "Currently Inactive";
        }

        if (emailInfo.emailType === "automated") {
          let emailTypeClass = {
            automated: "active",
            onetime: "",
          };
          emailInfo.emailTypeClass = emailTypeClass;
          let emailTimeClass = {
            soon: "",
            minutes: "",
          };
          emailTimeClass.soon = "active";
          emailInfo.emailTime = "soon";
          emailInfo.emailTimeClass = emailTimeClass;
        } else {
          let emailTypeClass = {
            automated: "",
            onetime: "active",
          };
          emailInfo.emailTypeClass = emailTypeClass;
          let emailTimeZoneClass = {
            standard: "",
          };
          emailTimeZoneClass.standard = "active";

          emailInfo.emailTimeZoneClass = emailTimeZoneClass;
          emailInfo.emailTimeZone = emailData.timezone;

          let timezone = emailData.timezone || "America/Denver";
          if (timezone === "standard") {
            timezone = "America/Denver";
          }

          emailInfo.emailTimeZone = timezone;

          emailInfo.emailTimeDate = moment
            .parseZone(emailData.send_date)
            .tz(timezone, false)
            .toDate();
        }

        emailInfo.selectedEmailTags = emailData.ftags || [];
        emailInfo.selectedIncludedTags = emailData.fincludedtags || [];
        emailInfo.selectedExcludedTags = emailData.fexcludedtags || [];
        emailInfo.emailFrom = emailData.email_from || "";
        emailInfo.fromTemplate = emailData.from_template;

        /*eslint-disable*/
        let sendLimit = emailData.send_limit || {
          enable: false,
          count_hours: 2,
          count_emails: 10,
        };
        /*eslint-enable*/
        emailInfo.sendLimit = sendLimit.enable;
        emailInfo.countEmails = sendLimit.count_emails;
        emailInfo.countHours = sendLimit.count_hours;
        emailInfo.sendTimeType = emailData.send_time_type || "send";
        emailInfo.sendMins = emailData.send_mins || "0";
        emailInfo.oneTimeType = emailData.one_time_type || "date";
        emailInfo.sendTime = emailData.send_time || "08:00";
        emailInfo.selectedHour = emailData.selected_hour || "01";
        emailInfo.selectedMins = emailData.selected_mins || "00";
        emailInfo.selectedTimeType = emailData.selected_time_type || "AM";
        emailInfo.tagUserIds = emailData.tag_user_ids || [];
        emailInfo.tagUserType = emailData.tag_user_type || "tag";
      }

      let existingMails = this.state.emailFromList.filter(
        (d) => d.value === emailInfo.emailFrom
      );
      if (existingMails.length === 0) {
        emailInfo.emailFrom = this.state.emailFromList[0].value;
      }

      let ruleDay = "";
      let challengeName = "";
      let tagName = "";

      if (emailData.rule) {
        ruleDay = emailData.rule.day;
        if (emailData.rule.challengeId) {
          let response = await callGetChlgById(emailData.rule.challengeId);
          challengeName = response.name;
        }
      }
      if (emailData.fincludedtags && emailData.fincludedtags[0]) {
        tagName = emailData.fincludedtags[0].name;
      }

      this.setState({
        emailId: emailInfo._id,
        isActive: emailInfo.isActive,
        isSystemEmail: emailInfo.isSystemEmail,
        name: emailInfo.name,
        description: emailInfo.description,
        title: emailInfo.title,
        content: emailInfo.content,
        htmlContent: emailInfo.htmlContent,
        fTags: { new: [], existing: emailInfo.selectedEmailTags },
        addTags: emailInfo.selectedIncludedTags,
        excludeTags: emailInfo.selectedExcludedTags,
        selectedEmailTags: emailInfo.selectedEmailTags,
        selectedExcludedTags: emailInfo.selectedExcludedTags,
        selectedIncludedTags: emailInfo.selectedIncludedTags,
        keyTagUsers: Math.random(),
        emailType: emailInfo.emailType,
        emailTypeClass: emailInfo.emailTypeClass,
        emailTime: emailInfo.emailTime,
        emailTimeClass: emailInfo.emailTimeClass,
        emailTimeZone: emailInfo.emailTimeZone,
        emailTimeZoneClass: emailInfo.emailTimeZoneClass,
        emailTimeDate: emailInfo.emailTimeDate,
        emailOptionType: emailInfo.emailOptionType,
        selectedEmailTypeList: emailInfo.selectedEmailTypeList,
        emailTimeDelay: emailInfo.emailTimeDelay,
        keyTag: Math.random(),
        keyRadio: Math.random(),
        emailStatusLabel: emailInfo.emailStatusLabel,
        emailFrom: emailInfo.emailFrom,
        fromTemplate: emailInfo.fromTemplate,
        isFromTemplate: false,
        keyFromTemplate: Math.random(),
        keyEmailContent: Math.random(),
        keySwitch: Math.random(),
        editorType: emailInfo.editorType,
        sendLimit: emailInfo.sendLimit,
        countEmails: emailInfo.countEmails,
        countHours: emailInfo.countHours,
        sendTimeType: emailInfo.sendTimeType,
        sendMins: emailInfo.sendMins,
        sendTime: emailInfo.sendTime,
        selectedHour: emailInfo.selectedHour,
        selectedMins: emailInfo.selectedMins,
        selectedTimeType: emailInfo.selectedTimeType,
        tagUserIds: emailInfo.tagUserIds,
        tagUserType: emailInfo.tagUserType,
        keyEmailTimeZone: Math.random(),
        emailStatus: emailInfo.emailStatus,
        createdFrom: emailData.created_from,
        ruleDay: ruleDay,
        challengeName: challengeName,
        tagName: tagName,
      });
    }
  }

  componentWillUnmount() {
    this.removeEventLog();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.genericTags.data) {
      let tags = newProps.genericTags.data;
      tags.forEach((element) => {
        element.newName = `${element.name} (${element._id})`;
      });
      this.setState({
        tags: tags,
        keyTag: Math.random(),
      });
    }

    if (newProps.userData) {
      this.setState({
        userData: newProps.userData,
      });
    }
  }

  async getEmailGenericTags() {
    let apiData = {
      action: "get-generic-tags",
      data: {
        type: "email",
      },
    };

    let tags = await callGetGenericTags(apiData);
    tags.forEach((element) => {
      element.newName = `${element.name} (${element.emailCount} emails)`;
    });
    this.setState({
      tags: tags,
      keyTag: Math.random(),
    });
  }

  getTemplates() {
    callGetEmailTemplates({ query: { status: { $ne: "archive" } } }).then(
      (d) => {
        this.setState({ templates: d.data });
      }
    );
  }

  setupEventLog() {
    if (this.state.emailId === 0) {
      return;
    }
    this.sendEventLog();

    // api.service("event-log").on("created", data => {
    //   this.getEventLogs();
    // });

    // api.service("event-log").on("removed", data => {
    //   this.getEventLogs();
    // });
  }

  getEventLogs() {
    /*eslint-disable*/
    let query = { query: { type: "email", item_id: this.state.emailId } };
    /*eslint-enable*/
    api
      .service("event-log")
      .find(query)
      .then((d) => {
        let logs = d.data;
        let fLogs = logs.filter((d) => {
          return d.member_id !== this.state.userData._id;
        });
        this.setState({ eventLogs: fLogs });
      });
  }

  sendEventLog() {
    if (!this.state.userData._id || this.sentLog) {
      return;
    }

    /*eslint-disable*/
    let data = {
      member_id: this.state.userData._id,
      member_name: this.state.userData.name,
      date: new Date(),
      type: "email",
      item_id: this.state.emailId,
    };
    /*eslint-enable*/

    this.sentLog = true;
    this.removeEventLog().then((d) => {
      callCreateEventLog(data).then((d) => {
        this.getEventLogs();
      });
    });
  }

  async removeEventLog() {
    /*eslint-disable*/
    let query = {
      query: {
        member_id: this.state.userData._id,
        member_name: this.state.userData.name,
        type: "email",
      },
    };
    /*eslint-enable*/

    await callDeleteEventLogByQuery(query);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  handleTemplateChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;

    this.onChangeEmailTemplate(value);

    this.setState({
      selectedTemplate: value,
      keyEmailContent: Math.random(),
    });
  }

  onChangeEmailTemplate(id) {
    let templates = this.state.templates.filter((d) => {
      return d._id === id;
    });
    if (templates.length === 0) {
      this.resetProps();
      return;
    }
    let emailData = templates[0];
    let emailInfo = {};

    emailInfo.name = emailData.name;
    emailInfo.description = emailData.description;
    emailInfo.title = emailData.title;
    emailInfo.content = emailData.content;
    emailInfo.htmlContent = emailData.html;
    emailInfo.emailType = emailData.type;
    emailInfo.emailOptionType = emailData.email_type || "1";

    if (emailInfo.emailOptionType) {
      emailInfo.selectedEmailTypeList = [emailInfo.emailOptionType];
    }

    if (!emailInfo.isActive) {
      emailInfo.emailStatusLabel = "Currently Inactive";
    }

    if (emailInfo.emailType === "automated") {
      let emailTypeClass = {
        automated: "active",
        onetime: "",
      };
      emailInfo.emailTypeClass = emailTypeClass;
      let emailTimeClass = {
        soon: "",
        minutes: "",
      };

      emailTimeClass.soon = "active";
      emailInfo.emailTime = "soon";

      emailInfo.emailTimeClass = emailTimeClass;
    } else {
      let emailTypeClass = {
        automated: "",
        onetime: "active",
      };
      emailInfo.emailTypeClass = emailTypeClass;
      let emailTimeZoneClass = {
        standard: "",
        user: "",
      };
      if (emailData.timezone === "user") {
        emailTimeZoneClass.user = "active";
      } else {
        emailTimeZoneClass.standard = "active";
      }
      emailInfo.emailTimeZoneClass = emailTimeZoneClass;
      emailInfo.emailTimeZone = emailData.timezone;
      emailInfo.emailTimeDate = "";
    }

    emailInfo.selectedEmailTags = emailData.ftags || [];
    emailInfo.selectedIncludedTags = emailData.fincludedtags || [];
    emailInfo.selectedExcludedTags = emailData.fexcludedtags || [];
    emailInfo.emailFrom = emailData.email_from || "";
    emailInfo.fromTemplate = emailData.from_template;

    this.setState({
      name: emailInfo.name,
      description: emailInfo.description,
      title: emailInfo.title,
      content: emailInfo.content,
      htmlContent: emailInfo.htmlContent,
      fTags: { new: [], existing: emailInfo.selectedEmailTags },
      addTags: emailInfo.selectedIncludedTags,
      excludeTags: emailInfo.selectedExcludedTags,
      selectedEmailTags: emailInfo.selectedEmailTags,
      selectedExcludedTags: emailInfo.selectedExcludedTags,
      selectedIncludedTags: emailInfo.selectedIncludedTags,
      keyTagUsers: Math.random(),
      emailType: emailInfo.emailType,
      emailTypeClass: emailInfo.emailTypeClass,
      emailTime: emailInfo.emailTime,
      emailTimeClass: emailInfo.emailTimeClass,
      emailTimeZone: emailInfo.emailTimeZone,
      emailTimeZoneClass: emailInfo.emailTimeZoneClass,
      emailTimeDate: emailInfo.emailTimeDate,
      emailOptionType: emailInfo.emailOptionType,
      selectedEmailTypeList: emailInfo.selectedEmailTypeList,
      emailTimeDelay: emailInfo.emailTimeDelay,
      keyTag: Math.random(),
      keyRadio: Math.random(),
      emailStatusLabel: emailInfo.emailStatusLabel,
      emailFrom: emailInfo.emailFrom,
      fromTemplate: emailInfo.fromTemplate,
    });
  }

  resetProps() {
    this.setState({
      isActive: true,
      isSystemEmail: false,
      name: "",
      description: "",
      title: "",
      content: {},
      htmlContent: "",
      fTags: { new: [], existing: [] },
      addTags: [],
      excludeTags: [],
      systemTags: [],
      selectedExcludedTags: [],
      selectedIncludedTags: [],
      selectedEmailTags: [],
      keyTagUsers: Math.random(),
      keyTag: Math.random(),
      keyRadio: Math.random(),
      emailTimeDate: "",
      emailTimeHour: "",
      emailTimeMinute: "",
      emailTimeAP: "",
      emailTimeDelay: "",
      emailStatusLabel: "Currently Active",
      keyEmailContent: Math.random(),
      isFromTemplate: false,
      keyFromTemplate: Math.random(),
    });
  }

  onChangeContent(html, content) {
    this.setState(
      {
        content: content,
        htmlContent: html,
        keyEmailContent: Math.random(),
      },
      () => {
        if (this.state.emailId !== 0) {
          this.onPressSaveEmail();
        }
      }
    );
  }

  toggleCheckbox = (check) => {
    this.setState({
      isActive: check,
      emailStatusLabel: check ? "Currently Active" : "Currently Inactive",
    });
  };

  toggleFromTemplate = (check) => {
    this.setState({
      isFromTemplate: check,
    });
  };

  toggleSystemEmailCheckbox = (check) => {
    this.setState({
      isSystemEmail: check,
    });
  };

  toggleSendLimit = (check) => {
    this.setState({
      sendLimit: check,
    });
  };

  onChangeTags = (tags) => {
    if (tags.length > 0) {
      let fTags = { new: [], existing: [] };
      tags.forEach((element) => {
        if (element.name) {
          fTags.existing.push(element);
        } else {
          fTags.new.push(element);
        }
      });
      this.setState({ fTags: fTags });
    }
  };

  onChangeAddTags = (tags) => {
    this.setState({ addTags: tags });
  };

  onChangeExcludeTags = (tags) => {
    this.setState({ excludeTags: tags });
  };

  onChangeEmailTimeDate(value) {
    this.setState({
      emailTimeDate: value,
    });
  }

  renderHours() {
    var returnVals = [];
    for (let i = 1; i <= 12; i++) {
      let hour = i.toString().length !== 1 ? i : `0${i}`;
      returnVals.push(
        <option value={hour} key={hour}>
          {hour}
        </option>
      );
    }
    return returnVals;
  }

  renderMins() {
    var returnVals = [];
    for (let i = 0; i <= 59; i = i + 1) {
      let min = i.toString().length !== 1 ? i : `0${i}`;
      returnVals.push(
        <option value={min} key={min}>
          {min}
        </option>
      );
    }
    return returnVals;
  }

  onClickEmailTypeOption(option) {
    this.setState({
      emailTypeClass: this.changeRadioBtns(this.state.emailTypeClass, option),
      emailType: option,
    });
  }

  onClickEmailTimeOption(option) {
    this.setState({
      emailTimeClass: this.changeRadioBtns(this.state.emailTimeClass, option),
      emailTime: option,
    });
  }

  onClickEmailTimeZoneOption(option) {
    this.setState({
      emailTimeZoneClass: this.changeRadioBtns(
        this.state.emailTimeZoneClass,
        option
      ),
      emailTimeZone: option,
    });
  }

  changeRadioBtns(data, option) {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        if (key === option) {
          data[key] = "active";
        } else {
          data[key] = "";
        }
      }
    }
    return data;
  }

  onSelectEmailTypeRadio(item, id) {
    this.setState({ [id]: item[Object.keys(item)[0]].value });
  }

  onPressSaveEmail() {
    if (this.state.emailType === "onetime") {
      let emailTimeDate = this.state.emailTimeDate;
      if (this.state.oneTimeType === "send") {
        emailTimeDate = new Date();
      }
      let date = Date.parse(emailTimeDate);
      if (isNaN(date)) {
        showNotification("error", "Invalid email date", 4000);
        return;
      }
      $(
        ".modalAlertOverlay.confirmEmailDateAlertOverlay,.modalAlert.confirmEmailDateAlert"
      ).fadeIn(200);
    } else {
      this.checkForEmailFields();
    }
  }

  checkForEmailFields() {
    if (
      this.state.eventLogs.length > 0 &&
      this.state.editMailAction.length === 0
    ) {
      this.setState({ editMailAction: "save" }, () => {
        $(".modalAlertOverlay.confirmModal,.modalAlert.confirmAlert").fadeIn(
          200
        );
      });
      return;
    }

    if (
      this.state.addTagAction.length === 0 &&
      this.state.addTags.length === 0 &&
      !this.state.directEmail
    ) {
      this.setState({ addTagAction: "yes" }, () => {
        $(
          ".modalAlertOverlay.confirmTagModal,.modalAlert.confirmTagAlert"
        ).fadeIn(200);
      });
      return;
    }

    if (this.state.name.trim().length === 0) {
      showNotification("error", "Name is required", 4000);
      return;
    }

    if (this.state.title.trim().length === 0) {
      showNotification("error", "Title is required", 4000);
      return;
    }

    if (this.state.htmlContent.trim().length === 0) {
      showNotification("error", "Content is required", 4000);
      return;
    }

    if (this.state.emailType.trim().length === 0) {
      showNotification("error", "Email Interval is required", 4000);
      return;
    }

    if (this.state.emailOptionType.trim().length === 0) {
      showNotification("error", "Email Type is required", 4000);
      return;
    }

    let tags = this.state.fTags;
    if (tags.new && tags.new.length > 0) {
      let data = [];
      tags.new.forEach((element) => {
        data.push({ name: element, type: "email" });
      });
      callCreateGenericTag(data).then((resp) => {
        let eTags = this.state.tags;
        this.setState({
          tags: eTags.concat(resp),
        });
        let newTagIds = resp.map((t) => t._id);
        let tagIds = tags.existing.map((t) => t._id);
        let finalTagIds = newTagIds.concat(tagIds);

        this.callAddEmailAPI(finalTagIds);
      });
    } else {
      let tagIds = tags.existing.map((t) => t._id);
      this.callAddEmailAPI(tagIds);
    }
  }

  async callAddEmailAPI(genericTags) {
    let addTagIds = this.state.addTags.map((t) => t._id);
    let excludeTagIds = this.state.excludeTags.map((t) => t._id);

    let emailTimeDate = this.state.emailTimeDate;
    if (this.state.oneTimeType === "send") {
      emailTimeDate = new Date();
    }

    let timezone = this.state.emailTimeZone;

    emailTimeDate = moment(emailTimeDate).tz(timezone, false).toDate();

    let status = this.state.isActive ? "active" : "inactive";

    if (this.state.emailStatus === "completed" && status === "active") {
      status = "completed";
    }

    let apiData = {
      /*eslint-disable */
      name: this.state.name,
      description: this.state.description,
      status: status,
      system: this.state.isSystemEmail,
      email_type: this.state.emailOptionType,
      email_from: this.state.emailFrom ? this.state.emailFrom : "default",
      type: this.state.emailType,
      send_date: emailTimeDate,
      timezone: timezone,
      delay: this.state.emailTimeDelay,
      title: this.state.title,
      content: this.state.content,
      html: this.state.htmlContent,
      from_template: this.state.fromTemplate,
      /*
        Tag list will contain the ids of respective ids
      */
      tag_list: addTagIds,
      tag_list_excluded: excludeTagIds,
      generic_tag_list: genericTags,
      send_limit: {
        enable: this.state.sendLimit,
        count_emails: this.state.countEmails,
        count_hours: this.state.countHours,
      },
      send_time: this.state.sendTime,
      send_time_type: this.state.sendTimeType,
      send_mins: this.state.sendMins,
      selected_hour: this.state.selectedHour,
      selected_mins: this.state.selectedMins,
      selected_time_type: this.state.selectedTimeType,
      tag_user_type: this.state.tagUserType,
      tag_user_ids: this.state.tagUserIds,
      one_time_type: this.state.oneTimeType,
      /*eslint-enable*/
    };

    if (this.state.emailId !== 0) {
      /*eslint-disable*/
      let [err, data] = await toPromise(
        callUpdateEmail(this.state.emailId, apiData)
      );

      /*eslint-enable*/
      if (err) {
        showNotification("error", err.message, 5000);
      } else {
        showNotification("success", "Updated successfully", 4000);
      }
    } else {
      /*eslint-disable*/
      let [err, data] = await toPromise(callCreateEmail(apiData));
      /*eslint-enable*/
      if (err) {
        showNotification("error", err.message, 5000);
      } else {
        showNotification("success", "Created successfully", 4000);
        this.resetProps();
        if (this.props.fromPopup) {
          this.props.onCreate();
        } else {
          showOnClickNotification(
            "Click here to go to this email now",
            20000,
            () => {
              this.props.history.replace(
                `/admin/edit-email?eid=${data._id}&back=y`
              );
              window.location.reload();
            }
          );
        }
      }
    }
  }

  onClickSendTestEmail() {
    this.setState(
      { sendMailAddress: this.state.userData.email_address },
      () => {
        $(
          ".modalAlertOverlay.sendMailModalOverlay,.modalAlert.sendMailModal"
        ).fadeIn(200);
      }
    );
  }

  onClickCancelFromMailModal() {
    $(
      ".modalAlertOverlay.sendMailModalOverlay,.modalAlert.sendMailModal"
    ).fadeOut(200);
  }

  async addTagToUser(userId) {
    /*eslint-disable*/
    let actionTag = {
      member_id: userId,
      type: "send_email",
      action: "onetime",
      email_id: this.state.emailId,
      action_date: new Date(),
      status: "pending",
      test_email: true,
      created: new Date(),
    };
    /*eslint-enable*/
    let actionData = await callCreateMemberTagAction(actionTag);
    if (actionData._id) {
      this.setState({ sendMailAddress: "" }, () => {
        showNotification(
          "info",
          "Test email has been scheduled. Please allow a minute for delivery.",
          4000
        );
      });
    }
    this.onClickCancelFromMailModal();
  }

  async onClickSendFromMailModal() {
    if (this.state.sendMailAddress.trim().length === 0) {
      showNotification("error", "Please enter email address", 4000);
      return;
    }

    /*eslint-disable*/
    let apiData = {
      query: {
        email_address: this.state.sendMailAddress.trim().toLowerCase(),
        $select: ["_id", "name", "email_address"],
      },
    };
    /*eslint-enable*/

    let users = await callGetUsersByQuery(apiData);
    if (users.data.length > 0) {
      let userId = users.data[0]._id;
      await this.addTagToUser(userId);
    } else {
      let password = Math.random().toString(36).substr(2, 6);

      /*eslint-disable*/
      let userData = {
        email_address: this.state.sendMailAddress.trim().toLowerCase(),
        password: password,
        initial_password: password,
        units: "standard",
        member_type: "subscriber",
        master_roles: false,
        is_trainer: false,
        member_update: {
          clicked_updates: [],
          last_viewed_updates: new Date(),
        },
        tag_list: [],
        unsubscribed_email_types: [],
      };
      /*eslint-enable*/
      let userInfo = await callCreateUser(userData);
      await this.addTagToUser(userInfo._id);
    }
  }

  renderSendMailModal() {
    return (
      <div>
        <div className="modalAlertOverlay sendMailModalOverlay" />
        <div className="modalAlert sendMailModal">
          <div className="clearfix">
            <div className="pull-left">
              <p>Send Test Mail</p>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div
                  className={
                    this.state.sendMailAddress !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Email Address</label>
                  <input
                    type="text"
                    name="sendMailAddress"
                    value={this.state.sendMailAddress}
                    onChange={(event) => this.handleInputChange(event)}
                  />
                </div>
                <div className="gap10" />
              </div>
            </div>
          </div>
          <div className="gap10" />
          <div className="container-fluid">
            <label style={{ fontSize: 14 }}>
              The saved email will be sent as a test, not the preview. So make
              sure to click "Update" before sending a test email.
            </label>
          </div>
          <div className="gap10" />

          <div className="alertFooterBtns">
            {/*eslint-disable*/}
            <a
              onClick={this.onClickCancelFromMailModal.bind(this)}
              className="btn btn-default dismissThisModal margin-right-10">
              Cancel
            </a>
            <a
              onClick={this.onClickSendFromMailModal.bind(this)}
              className="btn btn-primary dismissThisModal">
              Send
            </a>
            {/*eslint-enable*/}
          </div>
        </div>
      </div>
    );
  }

  renderEventLogs() {
    let eventLogs = this.state.eventLogs;
    let returnVals = [];

    for (let i = 0; i < eventLogs.length; i++) {
      const element = eventLogs[i];
      returnVals.push(
        <label className="text-danger" key={element.item_id}>
          This page is currently being edited by {element.member_name} since{" "}
          {moment(element.date)
            .tz(moment.tz.guess())
            .format("Do MMM YYYY hh:mm A z")}
        </label>
      );
    }

    return returnVals;
  }

  onClickYesConfirmAlert = () => {
    $(".modalAlertOverlay.confirmModal,.modalAlert.confirmAlert").fadeOut(200);
    if (this.state.editMailAction === "save") {
      this.onPressSaveEmail();
    } else if (this.state.editMailAction === "updateBtn") {
      this.onClickUpdateBtn();
    }
  };

  onClickNoConfirmAlert = () => {
    this.setState({ editMailAction: "" }, () => {
      $(".modalAlertOverlay.confirmModal,.modalAlert.confirmAlert").fadeOut(
        200
      );
    });
  };

  renderConfirmationAlert() {
    return (
      <div>
        <div className="modalAlertOverlay confirmModal" />
        <div className="modalAlert confirmAlert">
          <p>
            This email is being edited by other user. Are you sure you want to
            make changes?
          </p>
          <div className="alertFooterBtns">
            {/*eslint-disable*/}
            <a
              className="btn btn-default closeModal-No margin-right-10"
              onClick={this.onClickNoConfirmAlert}>
              No
            </a>
            <a
              className="btn btn-primary closeModal-Yes"
              onClick={this.onClickYesConfirmAlert}>
              Yes
            </a>
            {/*eslint-enable*/}
          </div>
        </div>
      </div>
    );
  }

  onClickYesTagConfirmAlert = () => {
    $(".modalAlertOverlay.confirmTagModal,.modalAlert.confirmTagAlert").fadeOut(
      200
    );
    this.checkForEmailFields();
  };

  onClickNoTagConfirmAlert = () => {
    this.setState({ addTagAction: "" }, () => {
      $(
        ".modalAlertOverlay.confirmTagModal,.modalAlert.confirmTagAlert"
      ).fadeOut(200);
    });
  };

  renderTagConfirmationAlert() {
    return (
      <div>
        <div className="modalAlertOverlay confirmTagModal" />
        <div className="modalAlert confirmTagAlert">
          <p>
            You haven't selected any tags. Are you sure you want to save the
            email?
          </p>
          <div className="alertFooterBtns">
            <button
              className="btn btn-default closeModal-No margin-right-10"
              onClick={this.onClickNoTagConfirmAlert}>
              No
            </button>
            <button
              className="btn btn-primary closeModal-Yes"
              onClick={this.onClickYesTagConfirmAlert}>
              Yes
            </button>
          </div>
        </div>
      </div>
    );
  }

  onSelectFromMail = (mail) => {
    this.setState({
      emailFrom: mail.target.value,
    });
  };

  renderEmailFromList() {
    let returnVals = [];
    let emailFromList = this.state.emailFromList;
    for (let i = 0; i < emailFromList.length; i++) {
      returnVals.push(
        /*eslint-disable*/
        <option value={emailFromList[i].value} key={emailFromList[i].value}>
          {emailFromList[i].label}
        </option>
        /*eslint-enable*/
      );
    }
    return returnVals;
  }

  onSelectSendTime(item, id) {
    this.setState({ [id]: item[Object.keys(item)[0]].value });
  }

  renderTimes() {
    var returnVals = [];
    for (let i = 0; i <= 23; i++) {
      let hour = i.toString().length !== 1 ? i : `0${i}`;
      hour += ":00";
      returnVals.push(
        <option value={hour} key={hour}>
          {hour}
        </option>
      );
    }
    return returnVals;
  }

  renderTemplates() {
    let returnVals = [];
    let templates = this.state.templates;
    returnVals.push(
      <option value={1} key={0}>
        Please select template
      </option>
    );
    for (let i = 0; i < templates.length; i++) {
      returnVals.push(
        <option value={templates[i]._id} key={i + 1}>
          {templates[i].name}
        </option>
      );
    }
    return returnVals;
  }

  addEmailTag = (add) => {
    this.state.tags.push(add);
    let selectedEmailTags = this.state.selectedEmailTags;
    selectedEmailTags.push(add);
    this.setState({
      fTags: {
        new: [],
        existing: this.state.selectedEmailTags,
      },
      selectedEmailTags: selectedEmailTags,
    });
  };

  onClickEmailDateNoAlert = () => {
    $(
      ".modalAlertOverlay.confirmEmailDateAlertOverlay,.modalAlert.confirmEmailDateAlert"
    ).fadeOut(200);
  };

  onClickEmailDateYesAlert = () => {
    $(
      ".modalAlertOverlay.confirmEmailDateAlertOverlay,.modalAlert.confirmEmailDateAlert"
    ).fadeOut(200);
    this.checkForEmailFields();
  };

  renderEmailDateConfirmationAlert() {
    let emailTimeDate = this.state.emailTimeDate;

    if (this.state.oneTimeType === "send") {
      emailTimeDate = moment().format("MMM Do, YYYY hh:mm A");
    } else {
      emailTimeDate = moment(emailTimeDate).format("MMM Do, YYYY hh:mm A");
    }

    let timezones = this.state.timezoneList || [];

    let timezone = "";
    timezones = timezones.filter((d) => d.value === this.state.emailTimeZone);
    if (timezones.length > 0) {
      timezone = timezones[0].name;
    }
    return (
      <div>
        <div className="modalAlertOverlay confirmEmailDateAlertOverlay" />
        <div className="modalAlert confirmEmailDateAlert">
          <p>{`This email is scheduled to go out at ${emailTimeDate} (${timezone})  - Is this correct?`}</p>
          <div className="alertFooterBtns">
            <button
              className="btn btn-default closeModal-No margin-right-10"
              onClick={this.onClickEmailDateNoAlert}>
              No
            </button>
            <button
              className="btn btn-primary closeModal-Yes"
              onClick={this.onClickEmailDateYesAlert}>
              Yes
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderEmailView() {
    let status = this.state.emailStatus;

    let showTitle = true;
    if (this.props.fromPopup === true) {
      showTitle = false;
    }

    let emailHtml = this.state.htmlContent;
    if (emailHtml.includes("{{system_email_logo}}")) {
      let settingsHelper = new SettingsHelper();
      let emailLogoImage = settingsHelper.getSystemEmailLogoImage();
      var imageLink =
        "https://memberpages.s3.amazonaws.com/1573757706274-bt84dkp17ip-177x85-rvvl5tnwyyg.png";
      if (emailLogoImage) {
        let bucket = settingsHelper.getAwsBucketName() || "memberpages";
        imageLink = `https://${bucket}.s3.amazonaws.com/${emailLogoImage}`;
      }
      emailHtml = emailHtml.replace(/{{system_email_logo}}/gi, imageLink);
    }

    // if (status === "completed") {
    //   return (
    //     <div className="container-fluid">
    //       {showTitle && (
    //         <TitleBox
    //           title={this.state.emailId === 0 ? "Create Email" : "Update Email"}
    //           showBackBtn={
    //             this.state.emailId !== 0 && this.state.showBack === true
    //           }
    //         />
    //       )}

    //       <div className="gap20" />
    //       <div className="alert alert-danger" role="alert">
    //         This email has already been sent and can not be updated.
    //       </div>
    //       <div className="gap20" />
    //       <PageContent
    //         key={this.state.keyEmailContent}
    //         displayMode={"email"}
    //         title={this.state.name}
    //         screen={"add-email"}
    //         html={emailHtml}
    //         previewType={"iframe"}
    //         content={this.state.content}
    //         onChangeData={this.onChangeContent.bind(this)}
    //         pageName={"addEmail"}
    //         onClickAlert={this.onPressSaveEmail.bind(this)}
    //         editorType={this.state.editorType}
    //         hideButton={"yes"}
    //       />
    //     </div>
    //   );
    // }

    let emailTypes = this.state.emailTypes;

    let showAll = this.state.emailId === 0 ? false : this.state.showAll;

    let systemEmail = this.state.selectedEmailTypeList.indexOf("0") > -1;

    let challengeRuleInfo =
      this.state.createdFrom === "challenge-rule" &&
      this.state.ruleDay &&
      this.state.challengeName;

    return (
      <div>
        {this.renderSendMailModal()}
        {this.renderConfirmationAlert()}
        {this.renderTagConfirmationAlert()}
        {this.renderEmailDateConfirmationAlert()}
        <div className="container-fluid">
          {showTitle && (
            <TitleBox
              title={this.state.emailId === 0 ? "Create Email" : "Update Email"}
              showBackBtn={
                this.state.emailId !== 0 && this.state.showBack === true
              }
            />
          )}
          <div className="gap20" />
          {status === "completed" && (
            <div className="alert alert-danger" role="alert">
              This email has already been sent and can not be updated.
            </div>
          )}
          {this.state.eventLogs.length > 0 && (
            <div className="card">
              <div className="container-fluid">
                <div className="gap20" />
                {this.renderEventLogs()}
              </div>
            </div>
          )}
          {this.state.emailId === 0 && !this.props.fromPopup && false && (
            <div className="card">
              <div className="container-fluid">
                <div className="gap20" />
                <Checkbox
                  label={"Create From Template"}
                  isChecked={this.state.isFromTemplate}
                  handleCheckboxChange={this.toggleFromTemplate.bind(this)}
                  key={this.state.keyFromTemplate}
                />
                {this.state.isFromTemplate && (
                  <>
                    <div className="gap10" />

                    <div
                      className={
                        this.state.selectedTemplate !== ""
                          ? "mdInput mdFocussed"
                          : "mdInput"
                      }>
                      <label>Select Template</label>
                      <select
                        name="selectedTemplate"
                        value={this.state.selectedTemplate}
                        onChange={(event) => this.handleTemplateChange(event)}>
                        {this.renderTemplates()}
                      </select>
                    </div>
                  </>
                )}
                <div className="gap20" />
              </div>
            </div>
          )}
          <div className="card">
            <div className="container-fluid">
              <div className="gap20" />
              <div className="havingHelpText">
                <StatusSwitch
                  key={this.state.keySwitch}
                  isActive={this.state.isActive}
                  onChange={this.toggleCheckbox.bind(this)}
                />
              </div>
              {this.state.emailId !== 0 && (
                <button
                  className={"btn btn-dark float-right"}
                  onClick={() =>
                    this.setState({ showAll: !this.state.showAll })
                  }>
                  {this.state.showAll ? "Show All" : "Hide All"}
                </button>
              )}

              {!challengeRuleInfo ? (
                <>
                  {!this.state.directEmail && (
                    <>
                      <div className="gap10" />
                      {!this.state.fromTemplate ||
                      isTemplateSite() ||
                      this.state.emailId === 0 ? (
                        <TagUsers
                          label={"Add users with the following tags"}
                          key={this.state.keyTagUsers}
                          contentId={this.state.emailId}
                          selectedIncludedTags={this.state.selectedIncludedTags}
                          selectedExcludedTags={this.state.selectedExcludedTags}
                          history={this.props.history}
                          onChangeAddTags={this.onChangeAddTags}
                          onChangeExcludeTags={this.onChangeExcludeTags}
                          onCountUsers={(userCount) => {
                            this.setState({ usersCount: userCount });
                          }}
                          screen="add-email"
                          tagUserType={this.state.tagUserType}
                          selectedUsers={this.state.tagUserIds}
                          onChangeSelectedUsers={(userIds, userType) => {
                            this.setState({
                              tagUserIds: userIds,
                              tagUserType: userType,
                            });
                            this.onClickEmailTypeOption("onetime");
                          }}
                        />
                      ) : (
                        <TagInfo
                          selectedIncludedTags={this.state.selectedIncludedTags}
                          selectedExcludedTags={this.state.selectedExcludedTags}
                        />
                      )}
                      <div className="gap10" />
                    </>
                  )}
                </>
              ) : (
                <>
                  <div className="card">
                    <div className="container-fluid">
                      <div className="gap20" />
                      <p>
                        This email is auto generated on day of{" "}
                        {this.state.ruleDay} of the {this.state.challengeName}
                      </p>
                      <p>It is triggered by the {this.state.tagName}</p>
                    </div>
                  </div>
                </>
              )}

              <HelpTextDiv
                id="idChallengeEmailStatus"
                label="Learn more about the Email Status"
                type="addEmail"
                isInline="true"
              />
              {!showAll && (
                <>
                  <HelpTextDiv
                    id="idChallengeEmailName"
                    label="Learn more about the Email Name"
                    type="addEmail"
                    isInline="false"
                  />
                  {!this.state.directEmail && (
                    <div
                      className={
                        this.state.name !== ""
                          ? "mdInput mdFocussed"
                          : "mdInput"
                      }>
                      <label>Email Name</label>
                      <input
                        type="text"
                        name="name"
                        // onChange={this.handleInputChange.bind(this)}
                        // value={this.state.name}
                        defaultValue={this.state.name}
                        onBlur={this.handleInputChange.bind(this)}
                      />
                    </div>
                  )}

                  <div className="gap20" />
                  <div className="emailRadioWizard">
                    {this.state.showCategory && (
                      <div className="radioBHolder">
                        <div className="havingHelpText">
                          <p>Email Category</p>
                        </div>
                        <HelpTextDiv
                          id="idChallengeEmailCategory"
                          label="Learn more about the Email Category"
                          type="addEmail"
                          isInline="true"
                        />
                        <div className="tabsHolder">
                          {this.state.tagUserIds.length === 0 && (
                            <div
                              className={this.state.emailTypeClass.automated}
                              onClick={() =>
                                this.onClickEmailTypeOption("automated")
                              }>
                              <span className="icon-check" /> Automated Email
                            </div>
                          )}
                          <div
                            className={this.state.emailTypeClass.onetime}
                            onClick={() =>
                              this.onClickEmailTypeOption("onetime")
                            }>
                            <span className="icon-check" /> One Time Email
                          </div>
                        </div>
                      </div>
                    )}
                    {this.state.emailType === "onetime" && (
                      <div>
                        <div className="gap10" />
                        <div className="radioBHolder">
                          <p>Send Time</p>
                          <div className="tabsHolder radioTabs">
                            <RadioTag
                              onCheckChange={this.onSelectSendTime.bind(this)}
                              labelList={[
                                {
                                  name: "Immediately",
                                  value: "send",
                                },
                                { name: "Specified Date", value: "date" },
                              ]}
                              id={"oneTimeType"}
                              selectedList={[this.state.oneTimeType]}
                              selectType={"value"}
                              key={this.state.keySendTime}
                            />
                          </div>
                        </div>
                        {this.state.oneTimeType === "date" && (
                          <>
                            <div className="radioBHolder autEmailDateTime">
                              <div className="gap10" />
                              <div className="row">
                                <div className="col-sm-3">
                                  <HelpTextDiv
                                    id="idEmailSelectDate"
                                    label="Learn more about the Date"
                                    type="addEmail"
                                    isInline="false"
                                  />

                                  <label className="inputFakeLabelFocussed">
                                    Select Date
                                  </label>
                                  <div>
                                    <DateTimePicker
                                      renderNumbers
                                      use12Hours
                                      onChange={this.onChangeEmailTimeDate.bind(
                                        this
                                      )}
                                      value={this.state.emailTimeDate}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="gap20" />

                            <div className="radioBHolder">
                              <p>Select Timezone</p>
                              <div className="tabsHolder radioTabs">
                                <RadioTag
                                  onCheckChange={this.onSelectSendTime.bind(
                                    this
                                  )}
                                  labelList={this.state.timezoneList}
                                  id={"emailTimeZone"}
                                  selectedList={[this.state.emailTimeZone]}
                                  selectType={"value"}
                                  key={this.state.keyEmailTimeZone}
                                />
                              </div>
                            </div>
                          </>
                        )}

                        {this.state.usersCount >= 2000 && (
                          <>
                            <div className="gap20" />
                            <Checkbox
                              label={
                                "Limit Emails Sent in a specific amount of time."
                              }
                              isChecked={this.state.sendLimit}
                              handleCheckboxChange={this.toggleSendLimit.bind(
                                this
                              )}
                              key={this.state.keySendLimit}
                            />
                            {this.state.sendLimit && (
                              <>
                                <div className="gap20" />
                                <div className="row">
                                  <div className="col-sm-2">
                                    <div
                                      className={
                                        this.state.countEmails !== ""
                                          ? "mdInput mdFocussed"
                                          : "mdInput"
                                      }>
                                      <label>Send X emails</label>
                                      <input
                                        type="text"
                                        name="countEmails"
                                        onChange={this.handleInputChange.bind(
                                          this
                                        )}
                                        value={this.state.countEmails}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-sm-2">
                                    <div
                                      className={
                                        this.state.countHours !== ""
                                          ? "mdInput mdFocussed"
                                          : "mdInput"
                                      }>
                                      <label>Every Y hours</label>
                                      <input
                                        type="text"
                                        name="countHours"
                                        onChange={this.handleInputChange.bind(
                                          this
                                        )}
                                        value={this.state.countHours}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    )}
                    {this.state.emailType === "automated" && (
                      <>
                        <div className="gap10" />
                        <div className="radioBHolder">
                          <p>Send Time</p>
                          <div className="tabsHolder radioTabs">
                            <RadioTag
                              onCheckChange={this.onSelectSendTime.bind(this)}
                              labelList={[
                                {
                                  name: "Immediately",
                                  value: "send",
                                },
                                { name: "Specified Time", value: "time" },
                                {
                                  name: "After Specified Number of Minutes",
                                  value: "mins",
                                },
                              ]}
                              id={"sendTimeType"}
                              selectedList={[this.state.sendTimeType]}
                              selectType={"value"}
                              key={this.state.keySendTime}
                            />
                          </div>
                        </div>
                        {this.state.sendTimeType === "time" && (
                          <>
                            <div className="row">
                              <div className="col-sm-3">
                                <div
                                  className={
                                    this.state.selectedHour !== ""
                                      ? "mdInput mdFocussed"
                                      : "mdInput"
                                  }>
                                  <label>Select Hour</label>
                                  <select
                                    name="selectedHour"
                                    value={this.state.selectedHour}
                                    onChange={(event) =>
                                      this.handleInputChange(event)
                                    }>
                                    <option value="" />
                                    {this.renderHours()}
                                  </select>
                                </div>
                                <div className="gap20" />
                              </div>
                              <div className="col-sm-3">
                                <div
                                  className={
                                    this.state.selectedMins !== ""
                                      ? "mdInput mdFocussed"
                                      : "mdInput"
                                  }>
                                  <label>Select Minute</label>
                                  <select
                                    name="selectedMins"
                                    value={this.state.selectedMins}
                                    onChange={(event) =>
                                      this.handleInputChange(event)
                                    }>
                                    <option value="" />
                                    {this.renderMins()}
                                  </select>
                                </div>
                                <div className="gap20" />
                              </div>
                              <div className="col-sm-3">
                                <div
                                  className={
                                    this.state.selectedTimeType !== ""
                                      ? "mdInput mdFocussed"
                                      : "mdInput"
                                  }>
                                  <label>Select Type</label>
                                  <select
                                    name="selectedTimeType"
                                    value={this.state.selectedTimeType}
                                    onChange={(event) =>
                                      this.handleInputChange(event)
                                    }>
                                    <option value="" />
                                    <option value="AM">AM</option>
                                    <option value="PM">PM</option>
                                  </select>
                                </div>
                                <div className="gap20" />
                              </div>
                            </div>
                            <div className="gap10" />
                          </>
                        )}
                        {this.state.sendTimeType === "mins" && (
                          <div className="row">
                            <div className="col-sm-3">
                              <div
                                className={
                                  this.state.sendMins !== ""
                                    ? "mdInput mdFocussed"
                                    : "mdInput"
                                }>
                                <label>Minutes</label>
                                <input
                                  type="text"
                                  name="sendMins"
                                  onChange={this.handleInputChange.bind(this)}
                                  value={this.state.sendMins}
                                />
                              </div>
                              <div className="gap20" />
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  <div className="gap10" />
                  {!systemEmail && (
                    <>
                      <div className="radioBHolder">
                        <div className="havingHelpText">
                          <p>Email Type</p>
                        </div>
                        <HelpTextDiv
                          id="idChallengeEmailType"
                          label="Learn more about the Email Type"
                          type="addEmail"
                          isInline="true"
                        />
                        <div className="tabsHolder">
                          <RadioTag
                            onCheckChange={this.onSelectEmailTypeRadio.bind(
                              this
                            )}
                            labelList={emailTypes}
                            id={"emailOptionType"}
                            selectedList={this.state.selectedEmailTypeList}
                            selectType={"value"}
                            key={this.state.keyRadio}
                          />
                        </div>
                      </div>
                      <div className="gap20" />
                    </>
                  )}
                  <HelpTextDiv
                    id="idChallengeEmailGroup"
                    label="Learn more about the Email Group"
                    type="addEmail"
                    isInline="false"
                  />
                  <GenericTagSelect
                    id={"add-email-select-group"}
                    key={this.state.keyTag}
                    selectedValues={this.state.selectedEmailTags}
                    searchKey={"newName"}
                    selectKey={"name"}
                    lblText={"Select a group"}
                    data={this.state.tags}
                    onChange={this.onChangeTags}
                    addTag={this.addEmailTag}
                    isClickable={true}
                    btnText={"Add Group"}
                    popupHeaderText={"Create a new group"}
                    popupGenericType={"email"}
                    popupHeaderFFEnableName={true}
                    popupTitle={"Groups"}
                  />
                  <div className="gap20" />

                  {!isTemplateSite() && (
                    <div>
                      <HelpTextDiv
                        id="idChallengeEmailFrom"
                        label="Learn more about the Email From"
                        type="addEmail"
                        isInline="false"
                      />

                      <div className={"mdInput mdFocussed"}>
                        <label>From Email</label>
                        <select
                          name="emailFrom"
                          value={this.state.emailFrom}
                          onChange={this.onSelectFromMail.bind(this)}>
                          {this.renderEmailFromList()}
                        </select>
                      </div>
                      <div className="gap20" />
                    </div>
                  )}
                </>
              )}
              <HelpTextDiv
                id="idChallengeEmailTitle"
                label="Learn more about the Email Title"
                type="addEmail"
                isInline="false"
              />
              <div
                className={
                  this.state.title !== "" ? "mdInput mdFocussed" : "mdInput"
                }>
                <label>Email Title</label>
                <input
                  type="text"
                  name="title"
                  // onChange={this.handleInputChange.bind(this)}
                  // value={this.state.title}
                  defaultValue={this.state.title}
                  onBlur={this.handleInputChange.bind(this)}
                />
              </div>
              <div className="gap40" />
              <PageContent
                key={this.state.keyEmailContent}
                displayMode={"email"}
                title={this.state.name}
                screen={"add-email"}
                html={emailHtml}
                previewType={"iframe"}
                content={this.state.content}
                onChangeData={this.onChangeContent.bind(this)}
                pageName={"addEmail"}
                onClickAlert={this.onPressSaveEmail.bind(this)}
                editorType={this.state.editorType}
              />
              {this.state.htmlContent.length > 0 &&
                this.state.emailId !== 0 && (
                  <div className="text-right container-fluid">
                    <div className="gap20" />
                    <button
                      className="text-right btn btn-default"
                      onClick={this.onClickSendTestEmail.bind(this)}>
                      Send Test Email
                    </button>
                    <div className="gap20" />
                  </div>
                )}
              {this.state.emailId !== 0 && (
                <div>
                  <div>
                    <b>Email Preview Link :</b>
                    <p className="link-share">{`https://${window.location.hostname}/admin/view-emails?id=${this.state.emailId}`}</p>
                  </div>
                  <div className="gap20" />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="text-right container-fluid">
          {(status === "completed" || status === "inactive") && (
            <p className="text-danger">
              Note: This email is currently set as inactive/completed and will
              not be sent.
            </p>
          )}
          {status !== "completed" && (
            <button
              className="btn btn-primary"
              onClick={() => this.onPressSaveEmail()}>
              {this.state.emailId === 0 ? "Save" : "Update"}
            </button>
          )}
        </div>
        <div className="gap20" />
      </div>
    );
  }

  render() {
    let fromPopup = this.props.fromPopup;
    if (fromPopup) {
      return this.renderEmailView();
    }
    return (
      <Onboarding history={this.props.history}>
        {this.renderEmailView()}
      </Onboarding>
    );
  }
}

const mapStateToProps = (state) => ({
  message: state.emailReducer.message,
  success: state.emailReducer.success,
  genericTags: state.tagReducer.genericTags,
  systemTags: state.tagReducer.systemTags,
  userData: state.authReducer.userData,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getGenericTags,
      getSystemTags,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CreateEmail);
