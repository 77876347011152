import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment-timezone";
import $ from "jquery";
import { Scrollbars } from "react-custom-scrollbars";

import TitleBox from "../../../components/general/titleBox";
import FilterList from "../../../components/form/filterList";
import { filterByTags } from "../../../helpers/filters";
import { callGetBtpQuestionnaire } from "../../../services";

class ViewApplications extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      applications: [],
      applicationsBackup: [],
      filterList: [],
      selectedApplication: {},
      fieldNameMap: {
        name: "Name",
        email: "Email Address",
        heightFT: "Height(feet)",
        heightIN: "Height(inches)",
        weight: "Weight",
        age: "Age",
        hec: "HEC",
        bsc: "BSC",
        btc: "BTC",
        mckenzie: "Mckenzie",
        marci: "Marci",
        bodyFat: "Body Fat",
        dexaResults: "Dexa Results",
        bodyType: "Body Type",
        bodyComments: "Body Comments",
        rightBicep: "Bicep(right)",
        leftBicep: "Bicep(left)",
        hips: "Hips",
        waist: "Waist",
        rightThigh: "Thigh(right)",
        leftThigh: "Thigh(left)",
        chest: "Chest",
        goalMuscle: "Goal Muscle",
        goalCardio: "Goal Cardio",
        goalFlexibility: "Goal Flexibility",
        goalSize: "Goal Size",
        goalStrength: "Goal Strength",
        goalSpeed: "Goal Speed",
        goalStability: "Goal Stability",
        goalShortTerm: "Goal Short Term",
        goalLongTerm: "Goal Long Term",
        fitnessTesting: "Fitness Training",
        accountabilityType: "Accountability Type",
        goalWhy: "Goal Why",
        goalThreeThings: "Goal Three Things",
        caloriePercentage: "Calorie Percentage",
        goalMotivationHours: "Goal Motivation Hours",
        whatKeepsCommitted: "What Keeps Committed",
        rewards: "Rewards",
        daysExercise: "Days Exercise",
        hoursExercise: "Hours Exercise",
        checkExerciseWeights: "Exercise Weights",
        checkExerciseRun: "Exercise Run",
        checkExerciseBike: "Exercise Bike",
        checkExerciseYoga: "Exercise Yoga",
        checkExerciseZumba: "Exercise Zumba",
        checkExerciseCircuit: "Exercise Circuit",
        checkExerciseOther: "Exercise Other",
        exercisePrefer: "Exercise Prefer",
        exercisePrograms: "Exercise Programs",
        exerciseProgramsWeight: "Exercise Programs Weight",
        btpDesigned: "BTP Designed",
        checkExerciseEquipmentDumbbells: "Equiment Dumbbells",
        checkExerciseEquipmentDumbbellsLBS: "Equipment Dumbbells LBS",
        checkExerciseEquipmentKettleBells: "Equipment Kettle Bells",
        checkExerciseEquipmentKettleBellsLBS: "Equipment Kettle Bells LBS",
        checkExerciseEquipmentBarbell: "Equipment Barbell",
        checkExerciseEquipmentBarbellLBS: "EquipmentBarbellLBS",
        checkExerciseEquipmentCurlBar: "EquipmentCurlBar",
        checkExerciseEquipmentCurlBarLBS: "EquipmentCurlBarLBS",
        checkExerciseEquipmentPullUpBar: "EquipmentPullUpBar",
        checkExerciseEquipmentResistanceTubes: "EquipmentResistanceTubes",
        checkExerciseEquipmentLoopBands: "EquipmentLoopBands",
        checkExerciseEquipmentStabilityBall: "EquipmentStabilityBall",
        checkExerciseEquipmentFoamRoller: "EquipmentFoamRoller",
        checkExerciseEquipmentMassageStick: "EquipmentMassageStick",
        checkExerciseEquipmentOther: "EquipmentOther",
        bmr: "BMR",
        trackMacrosKnow: "Track Marcos Know",
        trackMacrosLike: "Track Macros Like",
        caloriePercentage1: "Calore Percentage 1",
        stressManage: "Stress Manage",
        sleepHours: "Sleep Hours",
        sleepQuality: "Sleep Quality",
        discomforts: "Dicomforts",
        injuries: "Injuries",
        healthConcerns: "Health Concerns",
        trainerSucceed: "Trainer Succeed",
        occupation: "Occupation",
        startDate: "Start Date",
        premierPaid: "Premier Paid",
        nutritionGuidance: "Nutrition Guidance"
      }
    };
  }

  componentDidMount() {
    callGetBtpQuestionnaire().then(d => {
      this.setState({ applications: d.data, applicationsBackup: d.data });
    });
  }

  onClickViewApplication(application) {
    this.setState({ selectedApplication: application }, () => {
      $(".modalAlertOverlay.detailOverlay,.modalAlert.detailAlert").fadeIn(200);
    });
  }

  renderApplications() {
    var returnVals = [];
    let items = this.state.applications;
    items.forEach(element => {
      returnVals.push(
        <tr key={element._id}>
          <td className="firstLetter">{element.name}</td>
          <td>{element.email}</td>
          <td>{moment(element.createdAt).format("Do, MMM YYYY")}</td>
          <td>
            {/*eslint-disable-next-line*/}
            <a
              onClick={this.onClickViewApplication.bind(this, element)}
              className="btn btn-default btn-sm border-0">
              <i className="fa fa-eye" />
            </a>
          </td>
        </tr>
      );
    });
    return returnVals;
  }

  filterUpdated(updatedFilter, name, type, searchFields) {
    let filterList = this.state.filterList;
    filterList[name] = {
      name: name,
      type: type,
      searchFields: searchFields,
      values: updatedFilter
    };
    this.setState(
      {
        filterList: filterList
      },
      () => {
        let data = filterByTags(this.state.applicationsBackup, filterList);
        this.setState({
          applications: data
        });
      }
    );
  }

  onClickCloseFromDetailModal() {
    $(".modalAlertOverlay.detailOverlay,.modalAlert.detailAlert").fadeOut(200);
  }

  renderDetailModal() {
    let modalHeight = window.innerHeight - 200;
    let selectedApplication = this.state.selectedApplication;
    let fieldNameMap = this.state.fieldNameMap;

    if (!selectedApplication._id) {
      return null;
    }

    function renderApplicationRow() {
      let returnVals = [];
      for (const key in fieldNameMap) {
        if (fieldNameMap.hasOwnProperty(key)) {
          const element = fieldNameMap[key];
          if (selectedApplication.hasOwnProperty(key)) {
            returnVals.push(
              <tr key={key}>
                <td>{element}</td>
                <td>{selectedApplication[key]}</td>
              </tr>
            );
          }
        }
      }

      return returnVals;
    }

    return (
      <div>
        <div className="modalAlertOverlay detailOverlay" />
        <div className="modalAlert detailAlert modal-lg big">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Application Detail
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.onClickCloseFromDetailModal.bind(this)}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div
              className="container-fluid modalInnerBody"
              style={{ maxHeight: modalHeight, overflowY: "hidden" }}>
              <Scrollbars
                autoHide
                autoHideTimeout={1000}
                autoHideDuration={200}
                style={{ height: modalHeight }}>
                <div className="gap20" />
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Key</th>
                      <th>Value</th>
                    </tr>
                  </thead>
                  <tbody>{renderApplicationRow()}</tbody>
                </table>
              </Scrollbars>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderDetailModal()}
        <div className="container-fluid">
          <TitleBox title="Submitted BTP Applications" />
          <div className="gap20" />
          <div className="card">
            <div className="list-group list-group-flush">
              <div className="list-group-item">
                <FilterList
                  label={"Search Applications"}
                  name={"search"}
                  type={"search"}
                  searchFields={["name", "email"]}
                  onFinish={this.filterUpdated.bind(this)}
                />
              </div>
              <div className="list-group-item">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Name </th>
                      <th>Email</th>
                      <th>Submitted Date</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>{this.renderApplications()}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewApplications);
