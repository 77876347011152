/* eslint jsx-a11y/anchor-is-valid: 0 */

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import { Scrollbars } from "react-custom-scrollbars";
import $ from "jquery";

import FullCalendar from "../../../components/general/fullCalendar";
import TitleBox from "../../../components/general/titleBox";
import RadioTag from "../../../components/form/radioTag";

import {
  callGetCustomProgram,
  callUpdateCustomProgram,
  callCreateCustomProgramTemplate
} from "../../../services";
import { showNotification } from "../../../helpers";

class EditProgram extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    let customProgram = this.props.history.location.state.customProgramItem;

    this.state = {
      events: [],
      daySets: [],
      dayNotes: "",
      customProgram: customProgram,
      keyCalendar: Math.random(),
      selectedPhase: "all",
      phaseSelected: {},
      keyDate: Math.random(),
      keyPhase: Math.random(),
      phaseDescription: "",
      phases: [],
      selectedCustomProgramDay: "",
      templateName: "",
      templateDesc: ""
    };
  }

  componentDidMount() {
    this.callGetCustomProgramAPI();
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  getFormattedEvents(cuEvents) {
    let events = [];
    cuEvents.forEach(element => {
      let date = element.date;
      element.sets.forEach(el => {
        events.push({
          date: date,
          event: el.name
        });
      });
    });
    return events;
  }

  callGetCustomProgramAPI() {
    callGetCustomProgram(this.state.customProgram._id).then(data => {
      let cuEvents = data["events"];

      let phases = [{ name: "All", value: "all" }];
      let i = 0;
      if (data.phases) {
        data.phases.forEach(element => {
          phases.push({
            name: `Phase ${++i}`,
            value: element.id
          });
        });
      }

      let events = this.getFormattedEvents(cuEvents);
      this.setState({
        phases: phases,
        events: events,
        customProgram: data,
        keyCalendar: Math.random(),
        keyPhase: Math.random()
      });
    });
  }

  onClickEditDetailsProgram() {
    this.props.history.push("/admin/add-custom-program", {
      customProgram: this.state.customProgram
    });
  }

  onDayClick = day => {
    let customProgram = this.state.customProgram;

    let dayDate = moment(day)
      .startOf("day")
      .toDate();

    let startDate = moment(customProgram.start_date)
      .startOf("day")
      .toDate();

    let endDate = moment(customProgram.start_date)
      .add(parseInt(customProgram.days, 10), "days")
      .startOf("day")
      .toDate();

    if (dayDate >= startDate && dayDate < endDate) {
      customProgram["customProgramDay"] = day;
      this.props.history.push("/admin/edit-custom-program-day", {
        customProgram: customProgram,
        from: "program"
      });
    } else {
      showNotification(
        "warning",
        "This date is outside of the customers program. If needed you can edit the program to include this day.",
        6000
      );
    }
  };

  onSelectPhaseRadio(item, id) {
    let value = item[Object.keys(item)[0]].value;

    let phases = this.state.customProgram.phases;

    let phase = phases.filter(d => {
      return d.id === value;
    });

    let desc = "";
    let phaseSelected = {};
    if (phase.length > 0) {
      desc = phase[0].description;
      phaseSelected = phase[0];
    }

    this.setState({
      selectedPhase: value,
      phaseDescription: desc,
      phaseSelected: phaseSelected
    });
  }

  onPasteEvents(copyDay, newDay) {
    let customProgram = this.state.customProgram;
    var events = customProgram["events"];

    let copyDayEvent = events.filter(d => {
      return d.date === copyDay;
    });

    let newDayEvent = events.filter(d => {
      return d.date === newDay;
    });
    if (newDayEvent.length === 1) {
      events.forEach(element => {
        if (element.date === newDay) {
          let sets = element.sets;
          element.sets = sets.concat(copyDayEvent[0].sets);
        }
      });
    } else {
      events.push({
        date: newDay,
        notes: copyDayEvent[0].notes,
        sets: copyDayEvent[0].sets
      });
    }

    customProgram["events"] = events;

    callUpdateCustomProgram(customProgram._id, {
      events: customProgram["events"]
    }).then(data => {
      if (data._id) {
        showNotification("success", "Custom Program Updated", 3000);
      }
    });
  }

  onClickCloseDailyOverviewModel() {
    $(
      ".modalAlertOverlay.viewDailyOverview,.modalAlert.viewDailyOverviewAlert"
    ).hide();
  }

  onClickDeleteDailyOverviewModel() {
    this.onClickCloseDailyOverviewModel();
    setTimeout(() => {
      $(
        ".modalAlertOverlay.viewConfirmOverlay,.modalAlert.viewConfirmAlert"
      ).show();
    }, 300);
  }

  onViewClick = day => {
    let customProgram = this.state.customProgram;

    let customProgramDay = moment(day).format("YYYY-MM-DD");
    let events = customProgram["events"];

    let dayEvents = events.filter(d => {
      return d.date === customProgramDay;
    });

    let sets = [];
    let notes = "";

    if (dayEvents.length === 1) {
      sets = dayEvents[0].sets;
      notes = dayEvents[0].notes;
    }

    this.setState(
      {
        daySets: sets,
        dayNotes: notes,
        selectedCustomProgramDay: customProgramDay
      },
      () => {
        $(
          ".modalAlertOverlay.viewDailyOverview,.modalAlert.viewDailyOverviewAlert"
        ).show();
      }
    );
  };

  renderExercisesInDailyOverview() {
    let sets = this.state.daySets;
    let returnVals = [];
    for (let i = 0; i < sets.length; i++) {
      const element = sets[i];
      returnVals.push(
        <div className="card" key={i}>
          <div className="list-group list-group-flush">
            <div className="list-group-item">
              <div className="clearfix">
                <div className="pull-left">
                  <p>
                    <b>{element.name}</b>
                  </p>
                </div>
              </div>
              <div className="gap10" />
              {element.exercises.map(i => (
                <div className="card">
                  <div className="list-group-item">
                    <div className="clearfix">
                      <div className="pull-left">
                        <p>
                          <b>{i.name}</b>
                        </p>
                      </div>
                      <div className="pull-right">
                        {/*eslint-disable-next-line*/}
                        <a className="btn btn-primary btn-sm">View Exercise</a>
                      </div>
                    </div>
                  </div>
                  <div className="list-group-item">
                    <p>
                      {i.reps ? i.reps + " / " : ""}{" "}
                      {i.weight ? i.weight + "" : ""}
                    </p>
                    {i.time !== "" && <p>Rest {i.time}</p>}
                    <p>{i.notes}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      );
    }
    return returnVals;
  }

  renderDailyOverview() {
    let modalHeight = window.innerHeight - 300;
    return (
      <div>
        <div className="modalAlertOverlay viewDailyOverview" />
        <div className="modalAlert viewDailyOverviewAlert">
          <p>Daily Overview</p>

          <div
            className="container-fluid modalInnerBody"
            style={{ maxHeight: modalHeight, overflowY: "hidden" }}>
            <Scrollbars
              autoHide
              autoHideTimeout={1000}
              autoHideDuration={200}
              style={{ height: modalHeight }}>
              {this.renderExercisesInDailyOverview()}
              <div className="card">
                <div className="list-group list-group-flush">
                  <div className="list-group-item">
                    <p>
                      <b>Overview</b>
                    </p>
                  </div>
                  <div className="list-group-item">
                    <p>{this.state.dayNotes}</p>
                  </div>
                </div>
              </div>
            </Scrollbars>
          </div>

          <div className="alertFooterBtns">
            {/*eslint-disable*/}
            <a
              className="btn btn-danger dismissThisModal margin-right-10"
              onClick={this.onClickDeleteDailyOverviewModel.bind(this)}>
              Delete
            </a>
            <a
              className="btn btn-primary dismissThisModal"
              onClick={this.onClickCloseDailyOverviewModel.bind(this)}>
              Close
            </a>
            {/*eslint-enable*/}
          </div>
        </div>
      </div>
    );
  }

  onClickAddPhase() {
    this.props.history.push("/admin/add-program-phase", {
      customProgram: this.state.customProgram
    });
  }

  onClickEditPhase() {
    this.props.history.push("/admin/add-program-phase", {
      customProgram: this.state.customProgram,
      phase: this.state.phaseSelected
    });
  }

  onClickSaveAsTemplate() {
    let customProgram = this.state.customProgram;
    let events = customProgram["events"];

    if (events.length === 0) {
      showNotification(
        "error",
        "Sets/exercises not available for this program",
        4000
      );
      return;
    }
    $(
      ".modalAlertOverlay.createTemplateOverlay,.modalAlert.createTemplateAlert"
    ).show();
  }

  onClickDeletePhase() {
    $(
      ".modalAlertOverlay.deletePhaseConfirmOverlay,.modalAlert.deletePhaseConfirmAlert"
    ).show();
  }

  onClickNoConfirmAlert = () => {
    $(
      ".modalAlertOverlay.viewConfirmOverlay,.modalAlert.viewConfirmAlert"
    ).hide();
  };

  onClickYesConfirmAlert = () => {
    $(
      ".modalAlertOverlay.viewConfirmOverlay,.modalAlert.viewConfirmAlert"
    ).hide();

    let customProgram = this.state.customProgram;
    var events = customProgram["events"];

    const filteredEvents = events.filter(
      item => item.date !== this.state.selectedCustomProgramDay
    );

    customProgram["events"] = filteredEvents;

    callUpdateCustomProgram(customProgram._id, {
      events: customProgram["events"]
    }).then(data => {
      if (data._id) {
        let events = this.getFormattedEvents(data["events"]);
        this.setState(
          {
            events: events,
            selectedCustomProgramDay: "",
            customProgram: data,
            keyCalendar: Math.random()
          },
          () => {
            showNotification("success", "Custom Program Updated", 3000);
          }
        );
      }
    });
  };

  renderConfirmAlert() {
    return (
      <div>
        <div className="modalAlertOverlay viewConfirmOverlay" />
        <div className="modalAlert viewConfirmAlert">
          <p>
            Are you sure want to delete/clear{" "}
            {moment(this.state.selectedCustomProgramDay).format("Do MMM YYYY")}{" "}
            ?
          </p>
          <div className="alertFooterBtns">
            {/*eslint-disable*/}
            <a
              className="btn btn-default closeModal-No margin-right-10"
              onClick={this.onClickNoConfirmAlert}>
              No
            </a>
            <a
              className="btn btn-primary closeModal-Yes"
              onClick={this.onClickYesConfirmAlert}>
              Yes
            </a>
            {/*eslint-enable*/}
          </div>
        </div>
      </div>
    );
  }

  onClickNoDeletePhaseConfirmAlert = () => {
    $(
      ".modalAlertOverlay.deletePhaseConfirmOverlay,.modalAlert.deletePhaseConfirmAlert"
    ).hide();
  };

  onClickYesDeletePhaseConfirmAlert = () => {
    $(
      ".modalAlertOverlay.deletePhaseConfirmOverlay,.modalAlert.deletePhaseConfirmAlert"
    ).hide();

    let customProgram = this.state.customProgram;
    var phases = customProgram["phases"];

    const filteredPhases = phases.filter(
      item => item.id !== this.state.phaseSelected.id
    );

    customProgram["phases"] = filteredPhases;

    callUpdateCustomProgram(customProgram._id, {
      phases: customProgram["phases"]
    }).then(data => {
      if (data._id) {
        this.setState(
          {
            phaseDescription: "",
            phaseSelected: {},
            selectedPhase: "all"
          },
          () => {
            this.callGetCustomProgramAPI();
            showNotification("success", "Custom Program Updated", 3000);
          }
        );
      }
    });
  };

  renderDeletePhaseConfirmAlert() {
    let name = "";
    if (this.state.phaseSelected) {
      name = this.state.phaseSelected.name;
    }
    return (
      <div>
        <div className="modalAlertOverlay deletePhaseConfirmOverlay" />
        <div className="modalAlert deletePhaseConfirmAlert">
          <p>Are you sure want to delete {name}?</p>
          <div className="alertFooterBtns">
            {/*eslint-disable*/}
            <a
              className="btn btn-default closeModal-No margin-right-10"
              onClick={this.onClickNoDeletePhaseConfirmAlert}>
              No
            </a>
            <a
              className="btn btn-primary closeModal-Yes"
              onClick={this.onClickYesDeletePhaseConfirmAlert}>
              Yes
            </a>
            {/*eslint-enable*/}
          </div>
        </div>
      </div>
    );
  }

  onClickCancelFromCreateTemplateModal = () => {
    $(
      ".modalAlertOverlay.createTemplateOverlay,.modalAlert.createTemplateAlert"
    ).hide();
  };

  onClickCreateFromCreateTemplateModel = () => {
    $(
      ".modalAlertOverlay.createTemplateOverlay,.modalAlert.createTemplateAlert"
    ).hide();

    if (this.state.templateName.trim().length === 0) {
      showNotification("error", "Name is required", 4000);
      return;
    }

    if (this.state.templateDesc.trim().length === 0) {
      showNotification("error", "Description is required", 4000);
      return;
    }

    let customProgram = this.state.customProgram;
    let events = customProgram["events"];

    let startDate = moment(customProgram.start_date)
      .subtract(1, "days")
      .startOf("day");

    let endDate = moment(customProgram.start_date)
      .add(parseInt(customProgram.days, 10), "days")
      .startOf("day");

    let day = 1;
    let filteredEvents = [];

    while (startDate.add(1, "days").diff(endDate) < 0) {
      let cloneDate = startDate.clone().startOf("day");
      let eventDate = cloneDate.format("YYYY-MM-DD");

      let event = events.filter(d => {
        return d.date === eventDate;
      });

      if (event.length === 1) {
        let eventInfo = {
          day: day,
          sets: event[0].sets,
          notes: event[0].notes
        };
        filteredEvents.push(eventInfo);
      }
      day++;
    }

    let apiData = {
      name: this.state.templateName,
      description: this.state.templateDesc,
      events: filteredEvents
    };

    callCreateCustomProgramTemplate(apiData).then(d => {
      if (d._id) {
        showNotification("success", "Template created successfully", 4000);
        this.setState({
          templateName: "",
          templateDesc: ""
        });
      } else {
        showNotification("error", "Something went wrong", 4000);
      }
    });
  };

  renderCreateTemplateModal() {
    return (
      <div>
        <div className="modalAlertOverlay createTemplateOverlay" />
        <div className="modalAlert createTemplateAlert">
          <div className="clearfix">
            <div className="pull-left">
              <p>Create New Template</p>
            </div>
          </div>

          <div className="container-fluid">
            <div
              className={
                this.state.templateName !== ""
                  ? "mdInput mdFocussed"
                  : "mdInput"
              }>
              <label>Name</label>
              <input
                type="text"
                name="templateName"
                value={this.state.templateName}
                onChange={event => this.handleInputChange(event)}
              />
            </div>
            <div className="gap20" />
            <div
              className={
                this.state.templateDesc !== ""
                  ? "mdInput mdFocussed"
                  : "mdInput"
              }>
              <label>Description</label>
              <input
                type="text"
                name="templateDesc"
                value={this.state.templateDesc}
                onChange={event => this.handleInputChange(event)}
              />
            </div>
          </div>
          <div className="gap20" />
          <div className="alertFooterBtns">
            {/*eslint-disable*/}
            <a
              onClick={this.onClickCancelFromCreateTemplateModal.bind(this)}
              className="btn btn-default dismissThisModal margin-right-10">
              Cancel
            </a>
            <a
              onClick={this.onClickCreateFromCreateTemplateModel.bind(this)}
              className="btn btn-primary dismissThisModal">
              Create
            </a>
            {/*eslint-enable*/}
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderCreateTemplateModal()}
        {this.renderDeletePhaseConfirmAlert()}
        {this.renderConfirmAlert()}
        {this.renderDailyOverview()}
        <div className="container-fluid">
          <TitleBox title="Edit Custom Program" showBackBtn={true} />
          <div className="gap20" />
          <div className="card">
            <div className="list-group list-group-flush">
              <div className="list-group-item">
                <h5 className="noMargin pull-left firstLetter">
                  Program Details
                </h5>

                <div className="pull-right">
                  <a
                    className="btn btn-default btn-sm"
                    onClick={this.onClickEditDetailsProgram.bind(this)}>
                    Edit Details
                  </a>
                </div>
              </div>

              <div className="container-fluid">
                <div className="gap20" />
                <div className="card">
                  <div className="container-fluid">
                    <div className="gap10" />
                    <h6 className="noMargin">
                      User :{" "}
                      <b className="firstLetter">
                        {this.state.customProgram.member_info.name}
                      </b>
                    </h6>
                    <div className="gap10" />
                    <h6 className="noMargin">
                      Name : <b>{this.state.customProgram.name}</b>
                    </h6>
                    <div className="gap10" />
                    <h6 className="noMargin">
                      Start Date :{" "}
                      <b>
                        {moment(this.state.customProgram.start_date).format(
                          "MMM, Do YYYY"
                        )}
                      </b>
                    </h6>
                    <div className="gap10" />
                    <h6 className="noMargin">
                      Program Days: <b>{this.state.customProgram.days}</b>
                    </h6>
                    <div className="gap10" />
                  </div>
                </div>
              </div>

              <div className="list-group-item">
                <h5 className="noMargin pull-left">Phases</h5>
                <div className="pull-right">
                  {/*eslint-disable-next-line*/}
                  <a
                    className="btn btn-default btn-sm"
                    onClick={this.onClickAddPhase.bind(this)}>
                    Add New Phase
                  </a>
                </div>
              </div>

              <div className="list-group-item">
                <div className="tabsHolder radioTabs">
                  <RadioTag
                    onCheckChange={this.onSelectPhaseRadio.bind(this)}
                    labelList={this.state.phases}
                    id={"selectedPhase"}
                    selectedList={[this.state.selectedPhase]}
                    selectType={"value"}
                    key={this.state.keyPhase}
                  />
                </div>
                <div className="gap10" />
                {this.state.phaseDescription.trim().length > 0 && (
                  <div>
                    <div className="gap10" />
                    <h6 className="">{this.state.phaseDescription}</h6>
                    <div className="gap10" />
                    {/*eslint-disable*/}
                    <a
                      className="btn btn-default btn-sm margin-right-10"
                      onClick={this.onClickEditPhase.bind(this)}>
                      Edit
                    </a>
                    <a
                      className="btn btn-danger btn-sm"
                      onClick={this.onClickDeletePhase.bind(this)}>
                      Delete
                    </a>
                    {/*eslint-enable*/}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="card">
            <div className="list-group list-group-flush">
              <div className="list-group-item">
                <h5 className="noMargin pull-left firstLetter">
                  Sets & Exercises
                </h5>

                <div className="pull-right">
                  {/*eslint-disable-next-line*/}
                  <a
                    className="btn btn-default btn-sm"
                    onClick={this.onClickSaveAsTemplate.bind(this)}>
                    Save As Template
                  </a>
                </div>
              </div>
              <div className="gap20" />
              <FullCalendar
                ref={ref => (this.calenderRef = ref)}
                changeYear={false}
                changeMonth={true}
                onDayClick={day => this.onDayClick(day)}
                onViewClick={day => this.onViewClick(day)}
                onPasteEvents={this.onPasteEvents.bind(this)}
                events={this.state.events}
                key={this.state.keyCalendar}
              />
              <div className="gap20" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditProgram);
