import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Checkbox from "../../../components/form/checkBox";
import TitleBox from "../../../components/general/titleBox";
import { showNotification, toPromise } from "../../../helpers";
import HelpTextDiv from "../../../components/form/helpTextDiv";
import AutoSuggest from "../../../components/form/autoSuggest";
import DatePicker from "react-date-picker";

import {
  callCreateStripePlan,
  callUpdateStripePlan,
  callGetSystemTags,
  callGetChlgsByQuery,
} from "../../../services";
import TagSelect from "../../../components/form/tagSelect";
import { callGetSettings } from "../../../services/settingService";
import RadioTag from "../../../components/form/radioTag";

class CreateSubscriptionPlan extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    let plan = {
      id: 0,
      description: "",
      intervalRecAmount: "1",
      trialDays: "",
      trialAmount: "",
      amount: "",
      selectedRecurrType: "month",
      recurrClass: {
        month: "",
        year: "",
        week: "",
        day: "",
      },
      isTrial: false,
      rules: [],
      isDayOfMonth: false,
      dayOfMonth: 1,
      isStartDate: false,
      startDate: "",
      stripeAccount: "default",
    };

    if (this.props.history.location.state !== undefined) {
      let planInfo = this.props.history.location.state.plan;

      plan.id = planInfo._id;
      plan.description = planInfo.description;
      plan.intervalRecAmount = planInfo.interval_recur_at;
      plan.trialDays = planInfo.trial_days;
      plan.trialAmount = planInfo.trial_amount || "";
      plan.isTrial = plan.trialDays !== "";
      plan.amount = (parseFloat(planInfo.amount) / 100).toString();
      plan.selectedRecurrType = planInfo.interval_period;
      plan.recurrClass[plan.selectedRecurrType] = "active";
      plan.rules = planInfo.rules || [];
      plan.isDayOfMonth = planInfo.is_day_of_month;
      plan.dayOfMonth = planInfo.day_of_month || 1;
      plan.isStartDate = planInfo.is_start_date;
      plan.startDate = planInfo.start_date || "";
      plan.stripeAccount = planInfo.stripe_account || "default";
    } else {
      plan.recurrClass.month = "active";
    }

    this.state = {
      id: plan.id,
      processor: "stripe",
      description: plan.description,
      intervalRecAmount: plan.intervalRecAmount,
      trialDays: plan.trialDays,
      trialAmount: plan.trialAmount,
      amount: plan.amount,
      recurrClass: plan.recurrClass,
      selectedRecurrType: plan.selectedRecurrType,
      isTrial: plan.isTrial,
      keyCheckbox: Math.random(),
      rules: plan.rules,
      ruleType: "",
      tagValue: "",
      tags: [],
      tagSelected: {},
      challengeValue: "",
      challengeSelected: {},
      dayOfMonth: plan.dayOfMonth,
      isDayOfMonth: plan.isDayOfMonth,
      keyDayOfMonth: Math.random(),
      keyStartDate: Math.random(),
      isStartDate: plan.isStartDate,
      startDate: plan.startDate,
      challenges: [],
      stripeAccounts: [],
      stripeAccount: plan.stripeAccount,
    };
  }

  componentDidMount() {
    this.getData();
    this.getSettings();
  }

  async getData() {
    let d = await callGetSystemTags();
    this.setState({ tags: d.data });

    let query = {
      query: {
        $select: ["_id", "name"],
      },
    };

    let c = await callGetChlgsByQuery(query);
    this.setState({ challenges: c.data });
  }

  async getSettings() {
    let d = await callGetSettings("stripeaccounts");
    if (d.status) {
      let accounts = d.data.stripe_accounts || [];
      let newAccounts = [{ id: "default", name: "Default" }];
      newAccounts = newAccounts.concat(accounts);
      this.setState({ stripeAccounts: newAccounts });
    }
  }

  resetProps() {
    this.setState({
      processor: "stripe",
      description: "",
      intervalRecAmount: "1",
      trialDays: "",
      trialAmount: "",
      amount: "",
      selectedRecurrType: "month",
      isTrial: false,
      keyCheckbox: Math.random(),
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  toggleTrialCheckbox = (check) => {
    this.setState({
      isTrial: check,
    });
  };

  toggleDayOfMonthCheckbox = (check) => {
    this.setState({
      isDayOfMonth: check,
    });
  };

  toggleStartDateCheckbox = (check) => {
    this.setState({
      isStartDate: check,
    });
  };

  onClickRecurrTypeOption(option) {
    let recurrClass = this.state.recurrClass;
    for (const key in recurrClass) {
      if (recurrClass.hasOwnProperty(key)) {
        if (key === option) {
          recurrClass[key] = "active";
        } else {
          recurrClass[key] = "";
        }
      }
    }
    this.setState({ recurrClass: recurrClass, selectedRecurrType: option });
  }

  async onPressCreateNewPlan() {
    if (this.state.description.trim().length === 0) {
      showNotification("error", "Description is required", 4000);
      return;
    }

    if (this.state.amount.trim().length === 0) {
      showNotification("error", "Amount is required", 4000);
      return;
    }

    if (this.state.intervalRecAmount.toString().trim().length === 0) {
      showNotification("error", "Interval Recurrance Period is required", 4000);
      return;
    }

    if (this.state.isTrial) {
      if (this.state.trialDays.trim().length === 0) {
        showNotification("error", "Trial days is required", 4000);
        return;
      }
    }

    /*eslint-disable*/

    let apiData = {
      processor: this.state.processor,
      description: this.state.description,
      amount: this.state.amount,
      interval_recur_at: this.state.intervalRecAmount,
      interval_period: this.state.selectedRecurrType,
      trial_days: this.state.trialDays,
      trial_amount: this.state.trialAmount,
      rules: this.state.rules,
      is_day_of_month: this.state.isDayOfMonth,
      day_of_month: this.state.dayOfMonth,
      start_date: this.state.startDate,
      is_start_date: this.state.isStartDate,
      stripe_account: this.state.stripeAccount,
    };

    let updateData = {
      rules: this.state.rules,
      is_day_of_month: this.state.isDayOfMonth,
      day_of_month: this.state.dayOfMonth,
      start_date: this.state.startDate,
      is_start_date: this.state.isStartDate,
    };

    if (this.state.id === 0) {
      let [err, data] = await toPromise(callCreateStripePlan(apiData));

      if (err) {
        showNotification("error", err.message, 5000);
      } else {
        showNotification("success", "Created Successfully", 4000);
        this.resetProps();
      }
    } else {
      let [err, data] = await toPromise(
        callUpdateStripePlan(this.state.id, updateData)
      );
      if (err) {
        showNotification("error", err.message, 5000);
      } else {
        showNotification("success", "Updated Successfully", 4000);
      }
    }
    /*eslint-enable*/
  }

  onChangeStartDate(date) {
    this.setState({ startDate: date });
  }

  onChangeTagValue(value) {
    this.setState({
      tagValue: value,
    });
  }

  onSelectTagValue(value) {
    this.setState({
      tagSelected: value,
    });
  }

  onChangeChallengeValue(value) {
    this.setState({
      challengeValue: value,
    });
  }

  onSelectChallengeValue(value) {
    this.setState({
      challengeSelected: value,
    });
  }

  onClickAddRule() {
    let rules = this.state.rules;
    if (this.state.ruleType.trim().length === 0) {
      showNotification("error", "Rule type is required", 4000);
      return;
    }

    if (
      this.state.ruleType === "add-tag" ||
      this.state.ruleType === "remove-tag"
    ) {
      let tagId = this.state.tags.filter((d) => {
        return d.name === this.state.tagValue;
      });

      rules.push({
        rule: this.state.ruleType,
        /*eslint-disable*/
        tag_id: tagId[0]._id,
        tag_name: this.state.tagValue,
        /*eslint-enable*/
      });
    } else if (this.state.ruleType === "cancel-challenge") {
      let challengeId = this.state.challenges.filter((d) => {
        return d.name === this.state.challengeValue;
      });

      rules.push({
        rule: this.state.ruleType,
        /*eslint-disable*/
        challenge_id: challengeId[0]._id,
        challenge_name: this.state.challengeValue,
        /*eslint-enable*/
      });
    }

    this.setState({
      rules: rules,
      ruleType: "",
      tagValue: "",
      challengeValue: "",
      challengeSelected: {},
      tagSelected: {},
    });
  }

  onClickDeleteRule(index) {
    let rules = this.state.rules;
    rules.splice(index - 1, 1);
    this.setState({ rules: rules });
  }

  renderRules() {
    let rules = this.state.rules;
    let returnVals = [];
    let i = 0;
    if (rules.length > 0) {
      rules.forEach((element) => {
        i++;
        if (element.rule === "add-tag" || element.rule === "remove-tag") {
          let ruleType = element.rule === "add-tag" ? "Add Tag" : "Remove Tag";
          returnVals.push(
            <div key={i}>
              {ruleType} : <b>{element.tag_name}</b>
              <button
                onClick={this.onClickDeleteRule.bind(this, i)}
                className="btn btn-danger btn-sm"
                style={{ float: "right" }}>
                Remove
              </button>
            </div>
          );
        } else if (element.rule === "cancel-challenge") {
          let ruleType =
            element.rule === "cancel-challenge" ? "Cancel Challenge" : "";
          returnVals.push(
            <div key={i}>
              {ruleType} : <b>{element.challenge_name}</b>
              <button
                onClick={this.onClickDeleteRule.bind(this, i)}
                className="btn btn-danger btn-sm"
                style={{ float: "right" }}>
                Remove
              </button>
            </div>
          );
        }
      });
    }
    return returnVals;
  }

  onChangeAddTags = (tags) => {
    let tagValue = "";
    if (tags.length > 0) {
      tagValue = tags[0].name;
    }
    this.setState({ tagValue: tagValue });
  };

  renderRulesSection() {
    return (
      <div>
        <div className="card">
          <div className="container-fluid">
            <div className="gap20" />
            <h5 className="noMargin">On Cancelation</h5>
            <div className="gap20" />
            <div className="row">
              <div className="col-sm-4">
                <div
                  className={
                    this.state.ruleType !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Select Rule</label>
                  <select
                    className="actionSelect"
                    name="ruleType"
                    value={this.state.ruleType}
                    onChange={(event) => this.handleInputChange(event)}>
                    <option value="" />
                    <option value="add-tag">Add Tag</option>
                    <option value="remove-tag">Remove Tag</option>
                    <option value="cancel-challenge">Cancel Challenge</option>
                  </select>
                </div>
                <div className="gap20" />
              </div>
              {(this.state.ruleType === "add-tag" ||
                this.state.ruleType === "remove-tag") && (
                <div className="col-sm-8">
                  {/* <div
                    className={
                      this.state.tagValue !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>Search Tag</label>
                    <AutoSuggest
                      searchKey="name"
                      data={this.state.tags}
                      value={this.state.tagValue}
                      onChange={this.onChangeTagValue.bind(this)}
                      onSelect={this.onSelectTagValue.bind(this)}
                    />
                  </div> */}
                  <TagSelect
                    id={"add-subscription-add-tag"}
                    key={this.state.keyNotiTag}
                    searchKey={"name"}
                    singleSelection={true}
                    hideInput={true}
                    selectKey={"name"}
                    btnText={"Create New Tag"}
                    lblText={"Select Tag"}
                    data={this.state.tags}
                    onChange={this.onChangeAddTags}
                    isClickable={true}
                    selectedValues={[this.state.tagValue]}
                    reload={() => {
                      this.getData();
                    }}
                  />
                </div>
              )}
              {this.state.ruleType === "cancel-challenge" && (
                <div className="col-sm-8">
                  <div
                    className={
                      this.state.challengeValue !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>Search Challenge</label>
                    <AutoSuggest
                      searchKey="name"
                      data={this.state.challenges}
                      value={this.state.challengeValue}
                      onChange={this.onChangeChallengeValue.bind(this)}
                      onSelect={this.onSelectChallengeValue.bind(this)}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="gap20" />
            <div className="text-right" onClick={() => this.onClickAddRule()}>
              <button className="btn btn-primary addRuleBtn">Add Rule</button>
            </div>
            <div className="gap20" />
          </div>
          {this.state.rules.length > 0 && (
            <div className="container-fluid">
              <div className="card">
                <div className="container-fluid">
                  <div className="gap20" />
                  <h5 className="noMargin">Added Rules</h5>
                  <div className="gap20" />
                  <div className="addedRule">{this.renderRules()}</div>
                  <div className="gap20" />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  renderDayOfMonths() {
    let returnVals = [];
    for (let i = 1; i < 32; i++) {
      returnVals.push(<option value={i}>{i}</option>);
    }
    return returnVals;
  }

  onSelectChangeRadioTag(item, id) {
    let value = item[Object.keys(item)[0]].value;

    this.setState({ [id]: value });
  }

  render() {
    return (
      <div>
        <div className="container-fluid">
          <TitleBox
            title={
              this.state.id !== 0
                ? "Update Subscription Plan"
                : "Create New Subscription Plan"
            }
            showBackBtn={this.state.id !== 0}
          />
          <div className="gap20" />
          <div className="card">
            <div className="list-group list-group-flush">
              <div className="list-group-item">
                <HelpTextDiv
                  id="idSubscriptionPlanDescription"
                  label="Learn more about the Description"
                  type="createSubscriptionPlan"
                  isInline="false"
                />
                <div
                  className={
                    this.state.description !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Plan Description</label>
                  <input
                    type="text"
                    name="description"
                    value={this.state.description}
                    onChange={(event) => this.handleInputChange(event)}
                  />
                </div>
                <div className="gap20" />
              </div>
              <div className="list-group-item">
                <div className="havingHelpText">
                  <p>Interval Recurrance Type</p>
                </div>
                <HelpTextDiv
                  id="idSubscriptionPlanIntervalRecType"
                  label="Learn more about the Interval Recurance Type"
                  type="createSubscriptionPlan"
                  isInline="true"
                />
                <div className="tabsHolder radioTabs">
                  <div
                    className={this.state.recurrClass.month}
                    onClick={() => this.onClickRecurrTypeOption("month")}>
                    <span className="icon-check" /> Month
                  </div>
                  <div
                    className={this.state.recurrClass.year}
                    onClick={() => this.onClickRecurrTypeOption("year")}>
                    <span className="icon-check" /> Year
                  </div>
                  <div
                    className={this.state.recurrClass.week}
                    onClick={() => this.onClickRecurrTypeOption("week")}>
                    <span className="icon-check" /> Week
                  </div>
                  <div
                    className={this.state.recurrClass.day}
                    onClick={() => this.onClickRecurrTypeOption("day")}>
                    <span className="icon-check" /> Day
                  </div>
                </div>

                {this.state.selectedRecurrType === "month" && (
                  <>
                    <div className="gap10" />
                    <Checkbox
                      label={"Bill on certain day of the month"}
                      isChecked={this.state.isDayOfMonth}
                      handleCheckboxChange={this.toggleDayOfMonthCheckbox.bind(
                        this
                      )}
                      key={this.state.keyDayOfMonth}
                    />
                    <div className="gap10" />
                    {this.state.isDayOfMonth && (
                      <div className="row">
                        <div className="col-sm-2">
                          <div
                            className={
                              this.state.dayOfMonth !== ""
                                ? "mdInput mdFocussed"
                                : "mdInput"
                            }>
                            <label>Select Day Of Month</label>
                            <select
                              className="actionSelect"
                              name="dayOfMonth"
                              value={this.state.dayOfMonth}
                              onChange={(event) =>
                                this.handleInputChange(event)
                              }>
                              <option value="" />
                              {this.renderDayOfMonths()}
                            </select>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
                <>
                  <div className="gap10" />
                  <Checkbox
                    label={"Start billing on a certain date"}
                    isChecked={this.state.isStartDate}
                    handleCheckboxChange={this.toggleStartDateCheckbox.bind(
                      this
                    )}
                    key={this.state.keyStartDate}
                  />
                  <div className="gap10" />
                  {this.state.isStartDate && (
                    <div className="row">
                      <div className="col-sm-2">
                        <DatePicker
                          value={this.state.startDate}
                          onChange={this.onChangeStartDate.bind(this)}
                        />
                      </div>
                    </div>
                  )}
                </>
              </div>
              <div className="list-group-item">
                <HelpTextDiv
                  id="idSubscriptionPlanIntervalRecPeriod"
                  label="Learn more about the Interval Recurrance Period"
                  type="createSubscriptionPlan"
                  isInline="false"
                />
                <div
                  className={
                    this.state.intervalRecAmount !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Interval Recurrance Period</label>
                  <input
                    type="number"
                    name="intervalRecAmount"
                    value={this.state.intervalRecAmount}
                    onChange={(event) => this.handleInputChange(event)}
                  />
                </div>
                <div className="gap20" />
                <p
                  style={{
                    color: "gray",
                    fontSize: 14,
                  }}>
                  Users will be billed at a combination of type and period. So
                  if the month is 6 and the type is month then users will be
                  billed every 6 months.
                </p>
              </div>
              <div className="list-group-item">
                <div className="havingHelpText">
                  <Checkbox
                    label={"Include Trial"}
                    isChecked={this.state.isTrial}
                    handleCheckboxChange={this.toggleTrialCheckbox.bind(this)}
                    key={this.state.keyCheckbox}
                  />
                </div>
                <HelpTextDiv
                  id="idSubscriptionPlanIncludeTrial"
                  label="Learn more about the Include Trial"
                  type="createSubscriptionPlan"
                  isInline="true"
                />
                <div className="gap10" />
                {this.state.isTrial && (
                  <div className="row includeTrialRow">
                    <div className="col-sm-6">
                      <HelpTextDiv
                        id="idSubscriptionPlanTrialDays"
                        label="Learn more about the Trial Days"
                        type="createSubscriptionPlan"
                        isInline="false"
                      />
                      <div
                        className={
                          this.state.trialDays !== ""
                            ? "mdInput mdFocussed"
                            : "mdInput"
                        }>
                        <label>Trial Days</label>
                        <input
                          type="text"
                          name="trialDays"
                          value={this.state.trialDays}
                          onChange={(event) => this.handleInputChange(event)}
                        />
                      </div>
                      <div className="gap20" />
                    </div>
                    <div className="col-sm-6">
                      <div
                        className={
                          this.state.trialAmount !== ""
                            ? "mdInput mdFocussed"
                            : "mdInput"
                        }>
                        <label>Trial Amount</label>
                        <input
                          type="number"
                          min={0}
                          name="trialAmount"
                          value={this.state.trialAmount}
                          onChange={(event) => this.handleInputChange(event)}
                        />
                      </div>
                      <div className="gap20" />
                    </div>
                  </div>
                )}
              </div>
              <div className="list-group-item">
                <HelpTextDiv
                  id="idSubscriptionPlanAmount"
                  label="Learn more about the Amount"
                  type="createSubscriptionPlan"
                  isInline="false"
                />
                <div
                  className={
                    this.state.amount !== "" ? "mdInput mdFocussed" : "mdInput"
                  }>
                  <label>Amount</label>
                  <input
                    type="text"
                    name="amount"
                    value={this.state.amount}
                    onChange={(event) => this.handleInputChange(event)}
                  />
                </div>
                <div className="gap20" />
                {this.state.stripeAccounts.length > 0 && (
                  <>
                    <div className="radioBHolder">
                      <div className="havingHelpText">
                        <p>Stripe Account</p>
                      </div>
                      <div className="tabsHolder radioTabs">
                        <RadioTag
                          onCheckChange={this.onSelectChangeRadioTag.bind(this)}
                          labelList={this.state.stripeAccounts.map((i) => {
                            return { name: i.name, value: i.id };
                          })}
                          id={"stripeAccount"}
                          selectedList={[this.state.stripeAccount]}
                          selectType={"value"}
                        />
                      </div>
                    </div>
                    <div className="gap20" />
                  </>
                )}
              </div>
            </div>
          </div>

          {this.renderRulesSection()}

          <div
            className="text-right"
            onClick={() => this.onPressCreateNewPlan()}>
            <button className="btn btn-primary">Submit</button>
          </div>
          <div className="gap20" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateSubscriptionPlan);
