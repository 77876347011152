import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import $ from "jquery";

import FilterList from "../../../components/form/filterList";
import TitleBox from "../../../components/general/titleBox";
import { filterByTags } from "../../../helpers/filters";

import { getFunnelPaths, getSystemTags } from "../../../modules/actions/index";
import { callDeleteFunnelPathById } from "../../../services";

class ViewFunnelPath extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      paths: [],
      pathsBackup: [],
      systemTags: [],
      filterList: [],
      selectedPath: {},
    };
  }

  componentDidMount() {
    this.props.getFunnelPaths();
    this.props.getSystemTags();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.funnelPaths.data) {
      this.setState({
        paths: newProps.funnelPaths.data,
        pathsBackup: newProps.funnelPaths.data,
      });
    }
    if (newProps.systemTags.data) {
      this.setState({
        systemTags: newProps.systemTags.data,
      });
    }
  }

  onClickEditFunnelPath = (funnelPath) => {
    this.props.history.push("/admin/add-funnel-path", {
      funnelPath: funnelPath,
      systemTags: this.state.systemTags,
      type: "edit",
    });
  };

  onClickCloneFunnelPath = (funnelPath) => {
    this.props.history.push("/admin/add-funnel-path", {
      funnelPath: funnelPath,
      systemTags: this.state.systemTags,
      type: "clone",
    });
  };

  onClickDeleteFunnelPath = (funnelPath) => {
    this.setState({ selectedPath: funnelPath }, () => {
      $(".modalAlertOverlay.deleteOverlay,.modalAlert.deleteAlert").fadeIn(200);
    });
  };

  onClickNoAlert = () => {
    $(".modalAlertOverlay.deleteOverlay,.modalAlert.deleteAlert").fadeOut(200);
  };

  onClickYesAlert = () => {
    callDeleteFunnelPathById(this.state.selectedPath._id).then((d) => {
      $(".modalAlertOverlay.deleteOverlay,.modalAlert.deleteAlert").fadeOut(
        200
      );
      this.props.getFunnelPaths();
    });
  };

  filterUpdated(updatedFilter, name, type, searchFields) {
    let filterList = this.state.filterList;
    filterList[name] = {
      name: name,
      type: type,
      searchFields: searchFields,
      values: updatedFilter,
    };
    this.setState(
      {
        filterList: filterList,
      },
      () => {
        let data = filterByTags(this.state.pathsBackup, filterList);
        this.setState({
          paths: data,
        });
      }
    );
  }

  renderPaths() {
    var returnVals = [];
    let items = this.state.paths;
    items.forEach((element) => {
      returnVals.push(
        <tr key={element._id}>
          <td>{element.name}</td>
          <td>{element.description}</td>
          <td>{element.checkout_item_id}</td>
          <td>{element.paths.length}</td>
          <td className="min-width-100">
            <button
              onClick={this.onClickEditFunnelPath.bind(this, element)}
              className="btn btn-default btn-sm margin-right-10 border-0">
              <i className="fa fa-pencil" />
            </button>
            <button
              onClick={this.onClickCloneFunnelPath.bind(this, element)}
              className="btn btn-default btn-sm margin-right-10 border-0">
              <i className="fa fa-clone" />
            </button>
            <button
              onClick={this.onClickDeleteFunnelPath.bind(this, element)}
              className="btn btn-danger btn-sm border-0">
              <i className="fa fa-trash" />
            </button>
          </td>
        </tr>
      );
    });
    return returnVals;
  }

  renderFilterView() {
    return (
      <div className="list-group-item">
        <FilterList
          label={"Search Funnel Paths"}
          name={"search"}
          type={"search"}
          searchFields={["name", "description", "checkout_item_id"]}
          onFinish={this.filterUpdated.bind(this)}
        />
      </div>
    );
  }

  renderConfirmationAlert() {
    let selectedPath = this.state.selectedPath;
    let name = "";
    if (selectedPath.name) {
      name = selectedPath.name;
    }
    return (
      <div>
        <div className="modalAlertOverlay deleteOverlay" />
        <div className="modalAlert deleteAlert">
          <p>{`Are you sure you want to delete "${name}" funnel path?`}</p>
          <div className="alertFooterBtns">
            <button
              className="btn btn-default closeModal-No margin-right-10"
              onClick={this.onClickNoAlert}>
              No
            </button>
            <button
              className="btn btn-primary closeModal-Yes"
              onClick={this.onClickYesAlert}>
              Yes
            </button>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderConfirmationAlert()}
        <div className="container-fluid">
          <TitleBox title={"Funnel Path Overview "} showBackBtn={false} />
          <div className="gap20" />
          <div className="card">
            <div className="list-group list-group-flush">
              {this.renderFilterView()}
              <div className="list-group-item">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Description</th>
                      <th>Checkout Item</th>
                      <th>No Of Upsell Items</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>{this.renderPaths()}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  funnelPaths: state.billingReducer.funnelPaths,
  systemTags: state.tagReducer.systemTags,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getFunnelPaths,
      getSystemTags,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ViewFunnelPath);
