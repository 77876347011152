import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment";
import TitleBox from "../../../../components/general/titleBox";
import { callGetBouncedEmails } from "../../../../services";

class BouncedEmails extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      emails: [],
    };
  }

  componentDidMount() {
    this.getBouncedEmails();
  }

  async getBouncedEmails() {
    let data = await callGetBouncedEmails();
    if (data.data.length > 0) {
      let emails = data.data;
      this.setState({ emails: emails });
    }
  }

  renderEmails() {
    var returnVals = [];
    let emails = this.state.emails;
    for (let i = 0; i < emails.length; i++) {
      const element = emails[i];
      returnVals.push(
        <tr key={element._id}>
          <td>{element.notification_type}</td>
          <td>{element.to_email}</td>
          <td>{element.subject}</td>
          <td>{moment(element.send_date).format("Do MMM YYYY")}</td>
        </tr>
      );
    }
    return returnVals;
  }

  renderContent() {
    return (
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Type</th>
            <th>To</th>
            <th>Subject</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>{this.renderEmails()}</tbody>
      </table>
    );
  }

  render() {
    return (
      <div className="container-fluid">
        <TitleBox title="Email Bounces & Complaints" showBackBtn={true} />
        <div className="gap20" />

        <div className="card">
          <div className="container-fluid">
            <div className="gap20" />
            {this.renderContent()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(BouncedEmails);
