import {
  CREATE_CHLG_QUE_FAILURE,
  CREATE_CHLG_QUE_SUCCESS,
  GET_CHLG_QUE_SUCCESS,
  CREATE_CHLG_ITEM_SUCCESS,
  CREATE_CHLG_ITEM_FAILURE,
  UPDATE_CHLG_ITEM_SUCCESS,
  UPDATE_CHLG_ITEM_FAILURE,
  GET_CHLG_ITEM_SUCCESS,
  CREATE_CHLG_SUCCESS,
  CREATE_CHLG_FAILURE,
  GET_CHLG_SUCCESS,
  RESET_STATUS,
  UPDATE_CHLG_QUE_SUCCESS,
  UPDATE_CHLG_QUE_FAILURE,
  UPDATE_CHLG_SUCCESS,
  UPDATE_CHLG_FAILURE,
  CREATE_ATQ_SUCCESS,
  CREATE_ATQ_FAILURE,
  CREATE_PROGRESS_JOURNAL_SUCCESS,
  CREATE_PROGRESS_JOURNAL_FAILURE,
  UPDATE_PROGRESS_JOURNAL_SUCCESS,
  UPDATE_PROGRESS_JOURNAL_FAILURE
} from "../types";

import config from "../../helpers/config";

import {
  callCreateChlgQue,
  callGetChlgQues,
  callCreateChlgItem,
  callUpdateChlgItem,
  callGetChlgItems,
  callGetChlgs,
  callCreateChlg,
  callUpdateChlgQue,
  callUpdateChlg,
  callCreateAtq,
  callCreateProgressJournal,
  callUpdateProgressJournal
} from "../../services/index";

/*
  This method is used to reset the status
  This will reset message and success states
*/
export const resetStatus = data => {
  return async dispatch => {
    dispatch({
      type: RESET_STATUS
    });
  };
};

export const createChlgQue = data => {
  return async dispatch => {
    return callCreateChlgQue(data)
      .then(data => {
        dispatch({
          type: CREATE_CHLG_QUE_SUCCESS,
          message: config.messages.createChlgQueSuccess,
          success: true
        });
      })
      .catch(err => {
        dispatch({
          type: CREATE_CHLG_QUE_FAILURE,
          message: config.messages.createChlgQueError,
          success: false
        });
      });
  };
};

export const updateChlgQue = (id, data) => {
  return async dispatch => {
    return callUpdateChlgQue(id, data)
      .then(data => {
        dispatch({
          type: UPDATE_CHLG_QUE_SUCCESS,
          message: config.messages.updateMessage,
          success: true
        });
      })
      .catch(err => {
        dispatch({
          type: UPDATE_CHLG_QUE_FAILURE,
          message: config.messages.createChlgQueError,
          success: false
        });
      });
  };
};

export const getChlgQues = data => {
  return async dispatch => {
    return callGetChlgQues(data).then(data => {
      dispatch({
        type: GET_CHLG_QUE_SUCCESS,
        data: data
      });
    });
  };
};

export const createChlgItem = data => {
  return async dispatch => {
    return callCreateChlgItem(data)
      .then(data => {
        dispatch({
          type: CREATE_CHLG_ITEM_SUCCESS,
          message: config.messages.createChlgQueSuccess,
          success: true
        });
      })
      .catch(err => {
        dispatch({
          type: CREATE_CHLG_ITEM_FAILURE,
          message: config.messages.createChlgQueError,
          success: false
        });
      });
  };
};

export const getChlgItems = data => {
  return async dispatch => {
    return callGetChlgItems(data).then(data => {
      dispatch({
        type: GET_CHLG_ITEM_SUCCESS,
        data: data
      });
    });
  };
};

export const createChlg = data => {
  return async dispatch => {
    return callCreateChlg(data)
      .then(data => {
        dispatch({
          type: CREATE_CHLG_SUCCESS,
          message: config.messages.createChlgQueSuccess,
          success: true
        });
      })
      .catch(err => {
        dispatch({
          type: CREATE_CHLG_FAILURE,
          message: config.messages.createChlgQueError,
          success: false
        });
      });
  };
};

export const updateChlg = (id, data) => {
  return async dispatch => {
    return callUpdateChlg(id, data)
      .then(data => {
        dispatch({
          type: UPDATE_CHLG_SUCCESS,
          message: config.messages.updateMessage,
          success: true
        });
      })
      .catch(err => {
        dispatch({
          type: UPDATE_CHLG_FAILURE,
          message: config.messages.createChlgQueError,
          success: false
        });
      });
  };
};

export const updateChlgItem = (id, data) => {
  return async dispatch => {
    return callUpdateChlgItem(id, data)
      .then(data => {
        dispatch({
          type: UPDATE_CHLG_ITEM_SUCCESS,
          message: config.messages.updateMessage,
          success: true
        });
      })
      .catch(err => {
        dispatch({
          type: UPDATE_CHLG_ITEM_FAILURE,
          message: config.messages.createChlgQueError,
          success: false
        });
      });
  };
};

export const getChlgs = data => {
  return async dispatch => {
    return callGetChlgs(data).then(data => {
      dispatch({
        type: GET_CHLG_SUCCESS,
        data: data
      });
    });
  };
};

export const createAtq = data => {
  return async dispatch => {
    return callCreateAtq(data)
      .then(data => {
        dispatch({
          type: CREATE_ATQ_SUCCESS,
          message: config.messages.atqMessage,
          success: true
        });
      })
      .catch(err => {
        dispatch({
          type: CREATE_ATQ_FAILURE,
          message: config.messages.createChlgQueError,
          success: false
        });
      });
  };
};

export const createProgressJournal = data => {
  return async dispatch => {
    return callCreateProgressJournal(data)
      .then(data => {
        dispatch({
          type: CREATE_PROGRESS_JOURNAL_SUCCESS,
          message: config.messages.progressJournalMessage,
          success: true
        });
      })
      .catch(err => {
        dispatch({
          type: CREATE_PROGRESS_JOURNAL_FAILURE,
          message: config.messages.createChlgQueError,
          success: false
        });
      });
  };
};

export const updateProgressJournal = (id, data) => {
  return async dispatch => {
    return callUpdateProgressJournal(id, data)
      .then(data => {
        dispatch({
          type: UPDATE_PROGRESS_JOURNAL_SUCCESS,
          message: config.messages.progressJournalMessage,
          success: true
        });
      })
      .catch(err => {
        dispatch({
          type: UPDATE_PROGRESS_JOURNAL_FAILURE,
          message: config.messages.createChlgQueError,
          success: false
        });
      });
  };
};
