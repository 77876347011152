import { slugify, getUniqueId } from "../../../helpers";
import {
  callGetSystemTags,
  callCreateSystemTag,
  callGetGenericTagsUsingQuery,
  callCreateGenericTag,
  callCreateEmail,
  callCreateCheckoutItem,
  callUpdateChlg,
  callCreateChlgQue,
  callCreateNotification,
  callCreateTextMessage,
  callGeneralPost,
} from "../../../services";
import config from "../../../helpers/config";
import { callEmailTemplateById } from "../../../services/settingService";
import moment from "moment-timezone";

class ChallengeOperations {
  _getSystemTagObject(tagName, from = "normal") {
    /*eslint-disable*/
    let apiData = {
      name: tagName,
      description: tagName,
      rules: [
        {
          limit_tag_per_days: "1",
        },
      ],
      additional_rules: [],
      events: [],
      notifications: [],
      product_list: [],
      generic_tag_list: [],
      zoom_meeting: {
        user_id: "",
        meeting_id: "",
      },
      type: "automated",
      created_from: from,
    };
    /*eslint-enable*/
    return apiData;
  }

  _getGenericTagObject(tagName) {
    /*eslint-disable*/
    let apiData = {
      name: tagName,
      description: tagName,
      tag_list_included: [],
      tag_list_excluded: [],
      type: "email",
    };
    /*eslint-enable*/
    return apiData;
  }

  async _createSystemTag(tagName, from = "normal") {
    let id = "";
    let existingTag = await callGetSystemTags({ query: { name: tagName } });
    if (existingTag.data.length > 0) {
      id = existingTag.data[0]._id;
    } else {
      let newTag = await callCreateSystemTag(
        this._getSystemTagObject(tagName, from)
      );
      id = newTag._id;
    }
    return id;
  }

  async createAutoSignupSystemTag(challengeName, challengeId) {
    let chlgName = slugify(challengeName);

    let tagName = `${chlgName}-auto-signup`;
    let id = "";
    let existingTag = await callGetSystemTags({ query: { name: tagName } });
    if (existingTag.data.length > 0) {
      id = existingTag.data[0]._id;
    } else {
      /*eslint-disable*/
      let newTag = await callCreateSystemTag({
        name: tagName,
        description: tagName,
        rules: [
          {
            limit_tag_per_days: "1",
          },
        ],
        additional_rules: [],
        events: [],
        notifications: [],
        product_list: [
          {
            challenge_id: challengeId,
            product_id: "",
            name: challengeName,
            start_date: moment().format("DD-MM-YYYY"),
            type: "challenge",
            challenge_start_type: "Default Day",
            if_tag_available: true,
          },
        ],
        generic_tag_list: [],
        zoom_meeting: {
          user_id: "",
          meeting_id: "",
        },
        type: "automated",
        created_from: "challenge",
      });
      /*eslint-enable*/
      id = newTag._id;
    }
    return id;
  }

  async _createSystemTagV2(tagName, from = "normal") {
    let data = {};
    let existingTag = await callGetSystemTags({ query: { name: tagName } });
    if (existingTag.data.length > 0) {
      data = existingTag.data[0];
    } else {
      let newTag = await callCreateSystemTag(
        this._getSystemTagObject(tagName, from)
      );
      data = newTag;
    }
    return data;
  }

  async _createGenericTag(tagName) {
    let id = "";
    let existingTag = await callGetGenericTagsUsingQuery({
      query: {
        name: tagName,
        type: "email",
      },
    });
    if (existingTag.data.length > 0) {
      id = existingTag.data[0]._id;
    } else {
      let newTag = await callCreateGenericTag(
        this._getGenericTagObject(tagName)
      );
      id = newTag._id;
    }
    return id;
  }

  async _getTemplate(editorType) {
    let template = {};
    let apiData = {
      action: "get-template-content",
      data: {
        type: "challengeContent",
      },
    };

    let response = await callGeneralPost(apiData);
    if (response._id) {
      return response;
    }

    let id = config.editorTemplateIds.defaultChallenge;
    if (editorType === "mp") {
      id = config.editorTemplateIds.defaultMpChallenge;
    }

    if (id) {
      let editorData = await callEmailTemplateById(id);
      if (editorData && editorData.data && editorData.data._id) {
        template = editorData.data;
      }
    }
    return template;
  }

  async _createEmail(challengeName, groupTagId, includedTagId, editorType) {
    let subject = `${challengeName} - Access Information`;
    /*eslint-disable*/
    let id = "5eab5e855296834d16ede523";

    let emailTemplate = {};
    if (id) {
      let editorData = await callEmailTemplateById(id);
      if (editorData.data._id) {
        emailTemplate = editorData.data;
      }
    }
    let apiData = {
      email_type: "1",
      email_from: "",
      tag_list: [includedTagId],
      tag_list_excluded: [],
      generic_tag_list: [groupTagId],
      name: subject,
      description: subject,
      status: "active",
      system: "false",
      type: "automated",
      send_date: "",
      timezone: "",
      delay: "",
      title: subject,
      content: emailTemplate.editor_content,
      html: emailTemplate.html,
    };
    /*eslint-enable*/
    let email = await callCreateEmail(apiData);
    return email._id;
  }

  async _createQuestion(challengeName, includedTagId) {
    let name = `${challengeName} - Have you completed the first task?`;
    let question = "Have you completed the first task?";
    /*eslint-disable*/
    let apiData = {
      answer_list: [
        {
          points: "10",
          answer: "Yes",
          description: "Yes",
          selected: false,
          ignore_submission: false,
          inactive_tags: [includedTagId],
          form_submission: "inactivate",
        },
        {
          points: "0",
          answer: "No",
          description: "No",
          selected: false,
          ignore_submission: true,
        },
      ],
      submission_limit_days: [],
      submission_limit_tags: [],
      included_tags: [includedTagId],
      excluded_tags: [],
      name: name,
      description: "",
      question: question,
      item_type: "regular",
      answer_type: "single",
      show_chart: false,
      submission_limit: "",
      submission_type: "days",
      inactive_limit_tags: true,
      enable_accountability: true,
    };
    /*eslint-enable*/
    let response = await callCreateChlgQue(apiData);
    return response._id;
  }

  async prepareChallengeData(challengeName, oldChallenge, editorType) {
    let chlgName = slugify(challengeName);
    let signupTag = `${chlgName}-signup`;
    let purchaseTag = `${chlgName}-purchase`;
    let cancelledTag = `${chlgName}-canceled`;
    let completedTag = `${chlgName}-completed`;
    let accountabilityTag = `${chlgName}-accountability`;
    let formTag = `${chlgName}-agreement-form`;

    let signupTagId = await this._createSystemTag(signupTag);
    let purchaseTagId = await this._createSystemTag(purchaseTag);
    let cancelledTagId = await this._createSystemTag(cancelledTag);
    let completedTagId = await this._createSystemTag(completedTag);
    let acccountabilityTagId = await this._createSystemTag(accountabilityTag);
    let formTagId = await this._createSystemTag(formTag);

    /*eslint-disable*/
    let rules = [
      {
        type: "at_signup",
        action: "add-tag",
        tag_id: signupTagId,
        tag_name: signupTag,
        lock: true,
      },
      {
        type: "at_signup",
        action: "add-tag",
        tag_id: formTagId,
        tag_name: formTag,
        lock: true,
      },
      {
        type: "on_cancel",
        action: "add-tag",
        tag_id: cancelledTagId,
        tag_name: cancelledTag,
        lock: true,
      },
      {
        type: "on_last_day",
        action: "add-tag",
        tag_id: completedTagId,
        tag_name: completedTag,
        lock: true,
      },
      {
        type: "on_specific_day",
        day: "1",
        action: "add-tag",
        tag_id: acccountabilityTagId,
        tag_name: accountabilityTag,
        lock: false,
      },
    ];
    /*eslint-enable*/

    if (oldChallenge.rules && oldChallenge.rules.length > 0) {
      let oldChlgName = slugify(oldChallenge.name);

      let oldChallengeRules = [
        `${oldChlgName}-signup`,
        `${oldChlgName}-purchase`,
        `${oldChlgName}-canceled`,
        `${oldChlgName}-completed`,
        `${oldChlgName}-accountability`,
      ];
      oldChallenge.rules.forEach((element) => {
        if (oldChallengeRules.indexOf(element.tag_name) === -1) {
          rules.push(element);
        }
      });
    }

    let emailGenericTagId = await this._createGenericTag(chlgName);
    // let questionId = await this._createQuestion(
    //   challengeName,
    //   acccountabilityTagId
    // );

    let emailId = await this._createEmail(
      challengeName,
      emailGenericTagId,
      signupTagId,
      editorType
    );

    let challengeTemplate = await this._getTemplate(editorType);

    return {
      purchaseTagId,
      rules,
      emailGenericTagId,
      formTagId,
      emailId,
      challengeTemplate,
    };
  }

  async createChallengeRuleEmail(
    name,
    ruleDay,
    emailTitle,
    emailContent,
    emailHtml,
    extraData = {}
  ) {
    if (!ruleDay) {
      ruleDay = getUniqueId();
    }
    let tagName =
      slugify(name) + "-day-" + ruleDay.toString() + "-" + getUniqueId();
    let tagNameId = await this._createSystemTag(tagName, "challenge-rule");

    let emailGenericTagId = await this._createGenericTag(slugify(name));

    /*eslint-disable*/

    let apiData = {
      email_type: "1",
      email_from: "",
      tag_list: [tagNameId],
      tag_list_excluded: [],
      generic_tag_list: [emailGenericTagId],
      name: emailTitle,
      description: emailTitle,
      status: "active",
      system: "false",
      type: "automated",
      send_date: "",
      timezone: "",
      delay: "",
      title: emailTitle,
      content: emailContent,
      html: emailHtml,
      created_from: "challenge-rule",
      ...extraData,
    };
    /*eslint-enable*/
    let email = await callCreateEmail(apiData);
    let emailId = email._id;

    return {
      emailId: emailId,
      tagValue: tagName,
      tagId: tagNameId,
    };
  }

  async createChallengeRuleNotification(
    name,
    ruleDay,
    notiTitle,
    notiBody,
    notiScreen,
    extraData
  ) {
    if (!ruleDay) {
      ruleDay = getUniqueId();
    }
    let tagName =
      slugify(name) + "-day-" + ruleDay.toString() + "-" + getUniqueId();
    let tagNameId = await this._createSystemTag(tagName, "challenge-rule");

    /*eslint-disable*/

    let apiData = {
      title: notiTitle,
      body: notiBody,
      status: "active",
      active: true,
      type: "automated",
      screen: notiScreen,
      send_date: new Date(),
      tag_list: [tagNameId],
      tag_list_excluded: [],
      generic_tag_list: [],
      ...extraData,
    };
    /*eslint-enable*/
    let notification = await callCreateNotification(apiData);
    let notiId = notification._id;

    return {
      notiId: notiId,
      tagValue: tagName,
      tagId: tagNameId,
    };
  }

  async createChallengeRuleSMS(name, ruleDay, smsName, smsMessage, extraData) {
    if (!ruleDay) {
      ruleDay = getUniqueId();
    }
    let tagName =
      slugify(name) + "-day-" + ruleDay.toString() + "-" + getUniqueId();
    let tagNameId = await this._createSystemTag(tagName, "challenge-rule");

    /*eslint-disable*/

    let apiData = {
      excluded_tags: [],
      generic_tag_list: [],
      image_url: "",
      included_tags: [tagNameId],
      message: smsMessage,
      name: smsName,
      one_time_type: "date",
      send_date: "",
      send_message: false,
      send_mins: "0",
      send_time: "09:00",
      sms_status: "active",
      status: "active",
      tag_user_ids: [],
      tag_user_type: "tag",
      type: "automated",
      ...extraData,
    };
    /*eslint-enable*/
    let sms = await callCreateTextMessage(apiData);
    let smsId = sms._id;

    return {
      smsId: smsId,
      tagValue: tagName,
      tagId: tagNameId,
    };
  }

  async createQuestionTag(name, ruleDay) {
    if (!ruleDay) {
      ruleDay = getUniqueId();
    }
    let tagName =
      slugify(name) + "-day-" + ruleDay.toString() + "-" + getUniqueId();
    let tag = await this._createSystemTagV2(tagName, "challenge-rule");
    return {
      tagValue: tagName,
      tag: tag,
      tagId: tag._id,
    };
  }

  async createCheckoutItem(challenge, amount, options) {
    let checkoutId = slugify(challenge.name) + "-" + getUniqueId();
    /*eslint-disable*/
    let apiData = {
      order_bump: {
        product_list: [],
      },
      order_bumps: [],
      product_list: [
        {
          challenge_id: challenge._id,
          product_id: "",
          name: challenge.name,
          start_date: moment().format("MM-DD-YYYY"),
          type: "challenge",
          challenge_start_type: "Default Day",
        },
      ],
      challenge_group_list: [],
      tag_list: [],
      active: true,
      payment_options: [],
      name: challenge.name,
      description: challenge.name,
      checkout_id: checkoutId,
      status: "pending",
      amount: amount,
      regular_cost: "",
      order_type: "single",
      order_plan: "",
      payments: "",
      payment_max_days: "",
      coupon_code: options.promoCodeAvailable ? "Y" : "N",
      payment_type: "fixed",
      thank_you_html: "",
      valid_till_date: "",
      redirect_item: "",
      promo_codes: options.promoCodes || [],
      promo_type: options.promoType,
    };
    /*eslint-enable*/
    let checkoutItem = await callCreateCheckoutItem(apiData);
    let checkoutIds = challenge.checkout_ids || [];
    checkoutIds.push(checkoutItem._id);
    /*eslint-disable*/
    await callUpdateChlg(challenge._id, {
      checkout_ids: checkoutIds,
    });
    /*eslint-enable*/
    return { checkoutItem, checkoutIds };
  }

  async createTaskRuleEmail(
    rule,
    emailTitle,
    emailContent,
    emailHtml,
    extraData = {}
  ) {
    let tagName = slugify(rule) + "-" + getUniqueId();
    let tagNameId = await this._createSystemTag(tagName, "task-rule");

    let emailGenericTagId = await this._createGenericTag(slugify(rule));

    /*eslint-disable*/

    let apiData = {
      email_type: "1",
      email_from: "",
      tag_list: [tagNameId],
      tag_list_excluded: [],
      generic_tag_list: [emailGenericTagId],
      name: emailTitle,
      description: emailTitle,
      status: "active",
      system: "false",
      type: "automated",
      send_date: "",
      timezone: "",
      delay: "",
      title: emailTitle,
      content: emailContent,
      html: emailHtml,
      created_from: "task-rule",
      ...extraData,
    };
    /*eslint-enable*/
    let email = await callCreateEmail(apiData);
    let emailId = email._id;

    return {
      emailId: emailId,
      tagValue: tagName,
      tagId: tagNameId,
    };
  }

  async createTaskRuleSMS(rule, smsName, smsMessage, extraData) {
    let tagName = slugify(rule) + "-" + getUniqueId();
    let tagNameId = await this._createSystemTag(tagName, "task-rule");

    /*eslint-disable*/

    let apiData = {
      excluded_tags: [],
      generic_tag_list: [],
      image_url: "",
      included_tags: [tagNameId],
      message: smsMessage,
      name: smsName,
      one_time_type: "date",
      send_date: "",
      send_message: false,
      send_mins: "0",
      send_time: "09:00",
      sms_status: "active",
      status: "active",
      tag_user_ids: [],
      tag_user_type: "tag",
      type: "automated",
      ...extraData,
    };
    /*eslint-enable*/
    let sms = await callCreateTextMessage(apiData);
    let smsId = sms._id;

    return {
      smsId: smsId,
      tagValue: tagName,
      tagId: tagNameId,
    };
  }

  async createEmailSequenceRuleEmail(
    name,
    rule,
    emailTitle,
    emailContent,
    emailHtml,
    extraData = {}
  ) {
    let tagName =
      slugify(name) + "-day-" + rule.toString() + "-" + getUniqueId();
    let tagNameId = await this._createSystemTag(tagName, "email-sequence-rule");

    let emailGenericTagId = await this._createGenericTag(tagName);

    /*eslint-disable*/

    let apiData = {
      email_type: "1",
      email_from: "",
      tag_list: [tagNameId],
      tag_list_excluded: [],
      generic_tag_list: [emailGenericTagId],
      name: emailTitle,
      description: emailTitle,
      status: "active",
      system: "false",
      type: "automated",
      send_date: "",
      timezone: "",
      delay: "",
      title: emailTitle,
      content: emailContent,
      html: emailHtml,
      created_from: "email-sequence-rule",
      ...extraData,
    };
    /*eslint-enable*/
    let email = await callCreateEmail(apiData);
    let emailId = email._id;

    return {
      emailId: emailId,
      tagValue: tagName,
      tagId: tagNameId,
    };
  }

  async createEmailSequenceSystemTag(tagName) {
    let newTag = await callCreateSystemTag(
      this._getSystemTagObject(tagName, "email-sequence")
    );
    return newTag._id;
  }
}

export default ChallengeOperations;
