import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import $ from "jquery";

import TitleBox from "../../../components/general/titleBox";
import FilterList from "../../../components/form/filterList";

import { filterByTags } from "../../../helpers/filters";
import { callDeleteProductById, callGetProducts } from "../../../services";

class ViewProductsPage extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      items: [],
      itemsBackup: [],
      filterList: [],
      selectedItem: {},
    };
  }

  componentDidMount() {
    this.getItems();
  }

  getItems() {
    callGetProducts().then((d) => {
      this.setState({ items: d.data, itemsBackup: d.data });
    });
  }

  onClickEditProduct = (helpDesk) => {
    this.props.history.push(`/admin/add-product?id=${helpDesk._id}`);
  };

  onClickDeleteProduct = (helpDesk) => {
    this.setState({ selectedItem: helpDesk }, () => {
      $(".modalAlertOverlay,.modalAlert").fadeIn(200);
    });
  };

  filterUpdated(updatedFilter, name, type, searchFields) {
    let filterList = this.state.filterList;
    filterList[name] = {
      name: name,
      type: type,
      searchFields: searchFields,
      values: updatedFilter,
    };
    this.setState(
      {
        filterList: filterList,
      },
      () => {
        let data = filterByTags(this.state.itemsBackup, filterList);
        this.setState({
          items: data,
        });
      }
    );
  }

  renderItems() {
    var returnVals = [];
    let items = this.state.items;
    items.forEach((element) => {
      returnVals.push(
        <tr key={element._id}>
          <td>{element.name}</td>
          <td>{element.description}</td>
          <td>{element.price}</td>
          <td className="min-width-100">
            <button
              onClick={this.onClickDeleteProduct.bind(this, element)}
              className="btn btn-danger btn-sm margin-right-10 border-0">
              <i className="fa fa-trash" />
            </button>
            <button
              onClick={this.onClickEditProduct.bind(this, element)}
              className="btn btn-default btn-sm border-0">
              <i className="fa fa-pencil" />
            </button>
          </td>
        </tr>
      );
    });
    return returnVals;
  }

  onClickNoAlert = () => {
    $(".modalAlertOverlay,.modalAlert").fadeOut(200);
  };

  onClickYesAlert = () => {
    callDeleteProductById(this.state.selectedItem._id).then((d) => {
      $(".modalAlertOverlay,.modalAlert").fadeOut(200);
      this.getItems();
    });
  };

  renderConfirmationAlert() {
    let selectedItem = this.state.selectedItem;
    let name = "";
    if (selectedItem.name) {
      name = selectedItem.name;
    }
    return (
      <div>
        <div className="modalAlertOverlay" />
        <div className="modalAlert">
          <p>{`Are you sure you want to delete "${name}" product?`}</p>
          <div className="alertFooterBtns">
            <button
              className="btn btn-default closeModal-No margin-right-10"
              onClick={this.onClickNoAlert}>
              No
            </button>
            <button
              className="btn btn-primary closeModal-Yes"
              onClick={this.onClickYesAlert}>
              Yes
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderFilterView() {
    return (
      <div className="list-group-item">
        <FilterList
          label={"Search Products..."}
          name={"search"}
          type={"search"}
          searchFields={["name", "description"]}
          onFinish={this.filterUpdated.bind(this)}
        />
      </div>
    );
  }

  render() {
    return (
      <div className="container-fluid">
        {this.renderConfirmationAlert()}
        <TitleBox title="Products Overview" />
        <div className="gap20" />
        <div className="card">
          <div className="list-group list-group-flush">
            {this.renderFilterView()}
            <div className="list-group-item">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Description</th>
                    <th>Price</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>{this.renderItems()}</tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ViewProductsPage);
