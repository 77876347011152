import React from "react";

import { showNotification } from "../../../../helpers";
import {
  callGetSettings,
  callSaveSettings,
} from "../../../../services/settingService";
import TitleBox from "../../../../components/general/titleBox";
import TaskSettingInformation from "./information";
import TaskSettingCustomFields from "./customFields";
import TaskSettingStatusList from "./statusList";
import TaskSettingRuleList from "./ruleList";

class TaskSettings extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      settingId: "",
      customFields: [],
      defaultWatchers: [],
      keyWatcherRadio: Math.random(),
      watcherList: [],
      pageTitle: "Tasks",
      taskName: "Task",
      addComment: "Add Comment",
      linkForm: false,
      linkInvoice: false,
      showHistory: false,
      taskStatuses: [],
      taskRules: [],
      keySettingInfo: Math.random(),
      keyCustomFields: Math.random(),
      keyTaskStatus: Math.random(),
      keyRuleList: Math.random(),
      hideTableFields: [],
    };
  }

  componentDidMount() {
    this.getData();
  }

  async getData() {
    await this.getSettings();
  }

  async getSettings() {
    let d = await callGetSettings("tasksettings");
    let settings = d.data;
    let taskSettings = settings.task_settings || {};
    let customFields = taskSettings.custom_fields || [];
    let defaultWatchers = taskSettings.default_watchers || [];
    let pageTitle = taskSettings.page_title || "Tasks";
    let taskName = taskSettings.task_name || "Task";
    let addComment = taskSettings.add_comment || "Add Comment";
    let linkForm = taskSettings.link_form;
    let linkInvoice = taskSettings.link_invoice;
    let showHistory = taskSettings.show_history;
    let statusList = taskSettings.task_statuses || [];
    let ruleList = taskSettings.task_rules || [];
    let hideTableFields = taskSettings.hide_table_fields || [];

    this.setState({
      customFields: customFields,
      settingId: settings._id,
      defaultWatchers: defaultWatchers,
      pageTitle: pageTitle,
      linkForm: linkForm,
      linkInvoice: linkInvoice,
      showHistory: showHistory,
      taskStatuses: statusList,
      taskName: taskName,
      addComment: addComment,
      taskRules: ruleList,
      hideTableFields: hideTableFields,
      keySettingInfo: Math.random(),
      keyCustomFields: Math.random(),
      keyTaskStatus: Math.random(),
      keyRuleList: Math.random(),
    });
  }

  async onClickSaveInfo() {
    /*eslint-disable*/
    let data = {
      id: this.state.settingId,
      type: "task_settings",
      task_settings: {
        hide_table_fields: this.state.hideTableFields,
        default_watchers: this.state.defaultWatchers,
        custom_fields: this.state.customFields,
        page_title: this.state.pageTitle,
        link_form: this.state.linkForm,
        link_invoice: this.state.linkInvoice,
        show_history: this.state.showHistory,
        task_statuses: this.state.taskStatuses,
        task_rules: this.state.taskRules,
        task_name: this.state.taskName,
        add_comment: this.state.addComment,
      },
    };
    /*eslint-enable*/
    let updatedData = await callSaveSettings(data);
    if (updatedData.status) {
      showNotification("success", updatedData.message, 3000);
    } else {
      showNotification("error", updatedData.message, 5000);
    }
  }

  render() {
    return (
      <div>
        <div className="container-fluid">
          <TitleBox title="Task Settings" />
          <div className="gap20" />
          <TaskSettingInformation
            key={this.state.keySettingInfo}
            defaultWatchers={this.state.defaultWatchers}
            hideTableFields={this.state.hideTableFields}
            linkForm={this.state.linkForm}
            linkInvoice={this.state.linkInvoice}
            showHistory={this.state.showHistory}
            pageTitle={this.state.pageTitle}
            taskName={this.state.taskName}
            addComment={this.state.addComment}
            onClickSaveInfo={(info) => {
              this.setState(
                {
                  linkForm: info.linkForm,
                  linkInvoice: info.linkInvoice,
                  showHistory: info.showHistory,
                  defaultWatchers: info.defaultWatchers,
                  pageTitle: info.pageTitle,
                  taskName: info.taskName,
                  addComment: info.addComment,
                  hideTableFields: info.hideTableFields,
                },
                () => {
                  this.onClickSaveInfo();
                }
              );
            }}></TaskSettingInformation>
          <TaskSettingCustomFields
            key={this.state.keyCustomFields}
            customFields={this.state.customFields}
            onClickSaveInfo={(info) => {
              this.setState(
                {
                  customFields: info.customFields,
                },
                () => {
                  this.onClickSaveInfo();
                }
              );
            }}></TaskSettingCustomFields>
          <TaskSettingStatusList
            key={this.state.keyTaskStatus}
            taskStatuses={this.state.taskStatuses}
            onClickSaveInfo={(info) => {
              this.setState(
                {
                  taskStatuses: info.taskStatuses,
                },
                () => {
                  this.onClickSaveInfo();
                }
              );
            }}></TaskSettingStatusList>
          <TaskSettingRuleList
            key={this.state.keyRuleList}
            taskRules={this.state.taskRules}
            onClickSaveInfo={(info) => {
              this.setState(
                {
                  taskRules: info.taskRules,
                },
                () => {
                  this.onClickSaveInfo();
                }
              );
            }}></TaskSettingRuleList>
        </div>
      </div>
    );
  }
}

export default TaskSettings;
