import * as api from "./api";
import { callApi } from "../helpers";

function callCreateAwsVideo(data) {
  return callApi(api.service("aws-videos").create(data));
}

function callUpdateAwsVideo(id, data) {
  return callApi(api.service("aws-videos").patch(id, data));
}

function callDeleteAwsVideo(id) {
  return callApi(api.service("aws-videos").remove(id));
}

function callGetAwsVideos() {
  return callApi(api.service("aws-videos").find({}));
}

function callGetAwsVideoById(id) {
  return callApi(api.service("aws-videos").get(id));
}

function callGetAwsVideosByQuery(query) {
  return callApi(api.service("aws-videos").find(query));
}

function callUploadAwsVideo(data) {
  return callApi(api.service("general").create(data));
}

function callDeleteUserVideosByQuery(query) {
  return callApi(api.service("user-videos").remove(null, query));
}

export {
  callCreateAwsVideo,
  callUpdateAwsVideo,
  callDeleteAwsVideo,
  callGetAwsVideos,
  callGetAwsVideosByQuery,
  callUploadAwsVideo,
  callGetAwsVideoById,
  callDeleteUserVideosByQuery,
};
