import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import DatePicker from "react-date-picker";
import * as $ from "jquery";
import moment from "moment";

import RadioTag from "../../../../components/form/radioTag";
import TitleBox from "../../../../components/general/titleBox";
import { callGetReports, callUpdateUser } from "../../../../services";
import { showNotification } from "../../../../helpers";

class MarciCircleReports extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      keyOption: Math.random(),
      keyFilterDate: Math.random(),
      startFilterDate: new Date(),
      endFilterDate: new Date(),
      reportType: "",
      activeUsers: [],
      cancelUsers: [],
      cancelTagUsers: [],
      dateFilters: [
        { name: "Today", value: "today" },
        { name: "Yesterday", value: "yesterday" },
        { name: "This Week", value: "week" },
        { name: "This Month", value: "month" },
        { name: "This Year", value: "year" },
        { name: "Custom", value: "custom" }
      ]
    };
  }

  componentWillMount() {
    this.callGetActiveUsers({ filter: "today" });
    this.callGetCancelUsers({ filter: "today" });
    this.callGetCancelTagUsers({});
  }

  callGetActiveUsers(data) {
    data["type"] = "mc-users";
    data["action"] = "all";
    data["cancel"] = "false";

    callGetReports(data).then(reportData => {
      if (reportData.length > 0) {
        this.setState({ activeUsers: reportData });
      } else {
        this.setState({ activeUsers: [] });
      }
    });
  }

  callGetCancelUsers(data) {
    data["type"] = "mc-users";
    data["action"] = "all";
    data["cancel"] = "true";

    callGetReports(data).then(reportData => {
      if (reportData.length > 0) {
        this.setState({ cancelUsers: reportData });
      } else {
        this.setState({ cancelUsers: [] });
      }
    });
  }

  callGetCancelTagUsers(data) {
    data["type"] = "mc-users";
    data["action"] = "cancel-tag";

    callGetReports(data).then(reportData => {
      if (reportData.length > 0) {
        this.setState({ cancelTagUsers: reportData });
      } else {
        this.setState({ cancelTagUsers: [] });
      }
    });
  }

  getReportsData(type, data) {
    switch (type) {
      case "active-users":
        this.callGetActiveUsers(data);
        break;
      case "cancel-users":
        this.callGetCancelUsers(data);
        break;
      default:
        break;
    }
  }

  onClickDateFilterReport(type, selectedList, name) {
    let filter = selectedList[Object.keys(selectedList)[0]].value;
    switch (filter) {
      case "today":
      case "yesterday":
      case "week":
      case "month":
      case "year":
        this.getReportsData(type, { filter: filter });
        break;
      case "custom":
        this.setState({ reportType: type }, () => {
          $(
            ".modalAlertOverlay.filterDateOverlay,.modalAlert.filterDateAlert"
          ).fadeIn(200);
          this.setState({ keyFilterDate: Math.random() });
        });
        break;

      default:
        break;
    }
  }

  renderDateFilter(type) {
    return (
      <div className="tabsHolder radioTabs">
        <RadioTag
          onCheckChange={this.onClickDateFilterReport.bind(this, type)}
          labelList={this.state.dateFilters}
          selectType={"value"}
          selectedList={["today"]}
          key={this.state.keyOption}
        />
      </div>
    );
  }

  onClickCloseFromFilterDateModal() {
    $(
      ".modalAlertOverlay.filterDateOverlay,.modalAlert.filterDateAlert"
    ).fadeOut(200);
  }

  onClickDoneFromFilterDateModal() {
    $(
      ".modalAlertOverlay.filterDateOverlay,.modalAlert.filterDateAlert"
    ).fadeOut(200);
    let customDates =
      moment(this.state.startFilterDate)
        .startOf("day")
        .format("YYYY-MM-DD") +
      "," +
      moment(this.state.endFilterDate)
        .startOf("day")
        .format("YYYY-MM-DD");

    let reportType = this.state.reportType;
    switch (reportType) {
      case "active-users":
        this.callGetActiveUsers({
          filter: "custom",
          customDates: customDates
        });
        break;
      case "cancel-users":
        this.callGetCancelUsers({
          filter: "custom",
          customDates: customDates
        });
        break;
      default:
        break;
    }
  }

  onChangeFilterDate(name, date) {
    this.setState({
      [name]: date
    });
  }

  renderCustomDateModal() {
    return (
      <div>
        <div className="modalAlertOverlay filterDateOverlay" />
        <div className="modalAlert filterDateAlert">
          <p> Filter By Date </p>
          <div className="container-fluid">
            <div className="row">
              <div className="col-6">
                <label className="inputFakeLabelFocussed">
                  Select Start Date
                </label>
                <div>
                  <DatePicker
                    key={this.state.keyTrackingDate}
                    value={this.state.startFilterDate}
                    onChange={this.onChangeFilterDate.bind(
                      this,
                      "startFilterDate"
                    )}
                  />
                </div>
              </div>
              <div className="col-6">
                <label className="inputFakeLabelFocussed">
                  Select End Date
                </label>
                <div>
                  <DatePicker
                    key={this.state.keyTrackingDate}
                    value={this.state.endFilterDate}
                    onChange={this.onChangeFilterDate.bind(
                      this,
                      "endFilterDate"
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="alertFooterBtns">
            {/*eslint-disable*/}
            <a
              className="btn btn-default dismissThisModal margin-right-10"
              onClick={this.onClickCloseFromFilterDateModal.bind(this)}>
              Close
            </a>
            <a
              className="btn btn-primary dismissThisModal"
              onClick={this.onClickDoneFromFilterDateModal.bind(this)}>
              Done
            </a>
            {/*eslint-enable*/}
          </div>
        </div>
      </div>
    );
  }

  onClickRemoveTag(element) {
    let tagList = element.member_info[0].tag_list;
    let memberId = element.member_id;
    let mcTagId = element.mc_tag_id;

    let fTagList = tagList.filter(d => {
      return d.id !== mcTagId;
    });

    /*eslint-disable*/
    let data = {
      tag_list: fTagList
    };
    /*eslint-enable*/

    callUpdateUser(memberId, data).then(d => {
      if (d._id) {
        showNotification("success", "Tag removed", 4000);
        this.callGetCancelTagUsers({});
      }
    });
  }

  renderActiveUsers() {
    let activeUsers = this.state.activeUsers;
    let returnVals = [];
    for (let i = 0; i < activeUsers.length; i++) {
      const element = activeUsers[i];
      if (element.member_info[0]) {
        returnVals.push(
          <div className="listWithRight" key={element._id}>
            <p className="noMargin firstLetter">
              {element.member_info[0].name}
            </p>
            <div className="rightElem moreWidth">
              <span className="taskStatus taskGreen moreWidth">
                {moment(element.createdAt).format("Do MMM YYYY hh:mm A")}
              </span>
            </div>
          </div>
        );
      }
    }
    return returnVals;
  }

  renderCancelUsers() {
    let cancelUsers = this.state.cancelUsers;
    let returnVals = [];
    for (let i = 0; i < cancelUsers.length; i++) {
      const element = cancelUsers[i];
      if (element.member_info[0]) {
        returnVals.push(
          <div className="listWithRight" key={element._id}>
            <p className="noMargin firstLetter">
              {element.member_info[0].name}
            </p>
            <div className="rightElem moreWidth">
              <span className="taskStatus taskGreen moreWidth">
                {moment(element.cancelled_date).format("Do MMM YYYY hh:mm A")}
              </span>
            </div>
          </div>
        );
      }
    }
    return returnVals;
  }

  renderCancelTagUsers() {
    let cancelTagUsers = this.state.cancelTagUsers;
    let returnVals = [];
    for (let i = 0; i < cancelTagUsers.length; i++) {
      const element = cancelTagUsers[i];
      if (element.member_info[0]) {
        returnVals.push(
          <div className="listWithRight" key={element._id}>
            <p className="noMargin firstLetter">
              {element.member_info[0].name}
            </p>
            <div className="rightElem moreWidth">
              <span
                className="taskStatus taskRed moreWidth pointer-click"
                onClick={this.onClickRemoveTag.bind(this, element)}>
                Remove Tag
              </span>
            </div>
          </div>
        );
      }
    }
    return returnVals;
  }

  renderActiveUsersReport() {
    return (
      <div className="card">
        <div className="list-group list-group-flush">
          <div className="list-group-item">
            <h5 className="noMargin pull-left firstLetter">Active Users</h5>
            <div className="pull-right taskGreen">
              <b>{this.state.activeUsers.length}</b>
            </div>
          </div>
          <div className="list-group-item">
            {this.renderDateFilter("active-users")}
          </div>
          <div className="list-group-item">
            <div className="gap10" />
            <div className="cardList">{this.renderActiveUsers()}</div>
          </div>
        </div>
      </div>
    );
  }

  renderCancelUsersReport() {
    return (
      <div className="card">
        <div className="list-group list-group-flush">
          <div className="list-group-item">
            <h5 className="noMargin pull-left firstLetter">Cancelled Users</h5>
            <div className="pull-right taskGreen">
              <b>{this.state.cancelUsers.length}</b>
            </div>
          </div>
          <div className="list-group-item">
            {this.renderDateFilter("cancel-users")}
          </div>
          <div className="list-group-item">
            <div className="gap10" />
            <div className="cardList">{this.renderCancelUsers()}</div>
          </div>
        </div>
      </div>
    );
  }

  renderCancelUserWithMcReport() {
    return (
      <div className="card">
        <div className="list-group list-group-flush">
          <div className="list-group-item">
            <h5 className="noMargin pull-left firstLetter">
              Cancelled Users With Marci Circle Tag
            </h5>
            <div className="pull-right taskRed">
              <b>{this.state.cancelTagUsers.length}</b>
            </div>
          </div>
          <div className="list-group-item">
            <div className="gap10" />
            <div className="cardList">{this.renderCancelTagUsers()}</div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderCustomDateModal()}
        <div className="container-fluid">
          <TitleBox title="Marci's Circle Users" showBackBtn={true} />
          <div className="gap20" />
          <div className="row">
            <div className="col-lg-6">{this.renderActiveUsersReport()}</div>
            <div className="col-lg-6">{this.renderCancelUsersReport()}</div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              {this.renderCancelUserWithMcReport()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MarciCircleReports);
