import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import $ from "jquery";
import moment from "moment-timezone";

import TitleBox from "../../../components/general/titleBox";
import FilterList from "../../../components/form/filterList";

import { filterByTags } from "../../../helpers/filters";
import {
  callDeleteLandingPageById,
  callUpdateLandingPage,
  callGetLandingPagesUsingQuery,
} from "../../../services";
import { SettingsHelper } from "../../../helpers/settings";
import { getItem, removeItem, setItem } from "../../../helpers/storage";

class ViewLandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      pages: [],
      pagesBackup: [],
      filterList: [],
      selectedPage: {},
      accountKey: "",
      domainList: [],
      scriptData: {},
      showScript: false,
      pageName: "",
      keyScript: Math.random(),
      keyExport: Math.random(),
      logQuery: false,
      searchQuery: "",
    };
  }

  componentDidMount() {
    this.getPages();
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  getPages() {
    let query = {
      query: {
        status: "archive",
        $select: ["-content_html", "-content_object"],
      },
    };
    callGetLandingPagesUsingQuery(query).then((d) => {
      let settingsHelper = new SettingsHelper();
      let accountKey = settingsHelper.getAccountKey();
      let domainList = settingsHelper.getDomainList();

      let pageList = d.data;
      pageList = pageList.filter((d) => !d.sub_id);

      this.setState(
        {
          pages: pageList,
          pagesBackup: pageList,
          keyExport: Math.random(),
          accountKey,
          domainList,
        },
        () => {
          let lq = getItem("lq");
          if (lq) {
            let query = JSON.parse(lq);
            let isDate = moment().isAfter(
              moment(query.date).add("5", "minutes"),
              "minutes"
            );
            if (!isDate) {
              let search = query.filterList.search;
              this.filterUpdated(
                search.values,
                "search",
                "search",
                search.searchFields
              );
              this.setState({
                keyFilter: Math.random(),
                searchQuery: query.searchQuery,
                filterList: query.filterList,
              });
            } else {
              removeItem("lq");
            }
          }
        }
      );
    });
  }

  onClickDeletePage = (page) => {
    this.setState({ selectedPage: page }, () => {
      $(".modalAlertOverlay.deleteOverlay,.modalAlert.deleteAlert").fadeIn(200);
    });
  };

  filterUpdated(updatedFilter, name, type, searchFields) {
    let filterList = this.state.filterList;
    filterList[name] = {
      name: name,
      type: type,
      searchFields: searchFields,
      values: updatedFilter,
    };

    let searchType = filterList.search;
    let searchQuery = "";
    if (searchType) {
      let values = searchType.values[0];
      searchQuery = values.value;
    }

    this.setState(
      {
        filterList: filterList,
      },
      () => {
        let data = filterByTags(this.state.pagesBackup, filterList);
        this.setState({
          pages: data,
          logQuery: true,
        });
        if (this.state.logQuery) {
          setItem(
            "lq",
            JSON.stringify({
              date: new Date(),
              filterList: Object.assign({}, filterList),
              searchQuery: searchQuery,
            })
          );
        }
      }
    );
  }

  onClickUnarchivePage = async (page) => {
    await callUpdateLandingPage(page._id, {
      status: "unarchived",
    });
    this.getPages();
  };

  onClickPreviewLandingPage = (page) => {
    if (page.url.length > 0) {
      let domainList = this.state.domainList;
      let landingUrls = domainList.filter((d) => {
        return d.type === "landing";
      });
      let landingUrl = `https://site.memberpages.app/${page.url}?key=${this.state.accountKey}`;
      if (landingUrls.length > 0) {
        landingUrl = `https://${landingUrls[0].domain}/${page.url}`;
      }
      window.open(landingUrl, "_blank");
    }
  };

  renderPages() {
    var returnVals = [];
    let items = this.state.pages;
    items.forEach((element) => {
      returnVals.push(
        <tr key={element._id}>
          <td>{element.name}</td>
          {/* <td>{element.description}</td> */}
          <td>
            <label
              className="link-share"
              onClick={this.onClickPreviewLandingPage.bind(this, element)}>
              {element.url ? "/" : ""}
              {element.url}
            </label>
          </td>
          <td>{moment(element.updatedAt).format("Do MMM YYYY hh:mm A")}</td>
          <td className="min-width-100">
            <button
              title="Delete"
              onClick={this.onClickDeletePage.bind(this, element)}
              className="btn btn-danger btn-sm margin-right-10 border-0">
              <i className="fa fa-trash" />
            </button>

            <button
              title="Unarchive"
              onClick={this.onClickUnarchivePage.bind(this, element)}
              className="btn btn-default btn-sm  border-0">
              <i className="fa fa-undo" />
            </button>
          </td>
        </tr>
      );
    });
    return returnVals;
  }

  onClickNoAlert = () => {
    $(".modalAlertOverlay.deleteOverlay,.modalAlert.deleteAlert").fadeOut(200);
  };

  onClickYesAlert = () => {
    callDeleteLandingPageById(this.state.selectedPage._id).then((d) => {
      $(".modalAlertOverlay.deleteOverlay,.modalAlert.deleteAlert").fadeOut(
        200
      );
      this.getPages();
    });
  };

  renderConfirmationAlert() {
    let selectedPage = this.state.selectedPage;
    let name = "";
    if (selectedPage.name) {
      name = selectedPage.name;
    }
    return (
      <div>
        <div className="modalAlertOverlay deleteOverlay" />
        <div className="modalAlert deleteAlert">
          <p>{`Are you sure you want to delete "${name}" landing page?`}</p>
          <div className="alertFooterBtns">
            <button
              className="btn btn-default closeModal-No margin-right-10"
              onClick={this.onClickNoAlert}>
              No
            </button>
            <button
              className="btn btn-primary closeModal-Yes"
              onClick={this.onClickYesAlert}>
              Yes
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderFilterView() {
    return (
      <>
        <div className="list-group-item">
          <FilterList
            key={this.state.keyFilter}
            label={"Search Pages..."}
            name={"search"}
            type={"search"}
            searchQuery={this.state.searchQuery}
            searchFields={["name", "description"]}
            onFinish={this.filterUpdated.bind(this)}
          />
        </div>
      </>
    );
  }

  render() {
    return (
      <div className="container-fluid">
        {this.renderConfirmationAlert()}
        <TitleBox title="Archived Landing Pages Overview" showBackBtn={true} />
        <div className="gap20" />
        <div className="card">
          <div className="list-group list-group-flush">
            {this.renderFilterView()}
            <div className="list-group-item">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Name</th>
                    {/* <th>Description</th> */}
                    <th>Url</th>
                    <th>Last Updated</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>{this.renderPages()}</tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ViewLandingPage);
