import * as api from "./api";
import { callApi } from "../helpers";

function callCreateSubscriptionArea(data) {
  return callApi(api.service("subscription-area").create(data));
}

function callUpdateSubscriptionArea(id, data) {
  return callApi(api.service("subscription-area").patch(id, data));
}

function callGetSubscriptionAreaUsingQuery(query) {
  return callApi(api.service("subscription-area").find(query));
}

function callGetSubscriptionAreaById(id) {
  return callApi(api.service("subscription-area").get(id));
}

function callDeleteSubscriptionAreaById(id) {
  return callApi(api.service("subscription-area").remove(id));
}

export {
  callCreateSubscriptionArea,
  callUpdateSubscriptionArea,
  callGetSubscriptionAreaUsingQuery,
  callGetSubscriptionAreaById,
  callDeleteSubscriptionAreaById
};
