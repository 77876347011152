import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import $ from "jquery";

import TitleBox from "../../../components/general/titleBox";
import FilterList from "../../../components/form/filterList";

import { filterByTags } from "../../../helpers/filters";
import {
  callGetLeads,
  callDeleteLead,
  callUpdateLead,
  callGetUsersByQuery,
} from "../../../services";
import { callGetSettings } from "../../../services/settingService";
import moment from "moment-timezone";
import { showNotification } from "../../../helpers";

class ViewLeadPage extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      items: [],
      itemsBackup: [],
      filterList: [],
      selectedItem: {},
      customFields: [],
      statues: [
        { name: "All", value: "all", selected: false },
        { name: "Pending", value: "pending", selected: false },
        { name: "On Hold", value: "hold", selected: false },
        { name: "Follow Up", value: "follow", selected: true },
        { name: "Spam", value: "spam", selected: false },
        { name: "Completed", value: "completed", selected: false },
      ],
    };
  }

  componentDidMount() {
    this.getItems();
    this.getSettings();
  }

  async getSettings() {
    let d = await callGetSettings("leadsettings");
    let settings = d.data;
    let leadSettings = settings.lead_settings || {};
    let customFields = leadSettings.custom_fields || [];

    this.setState({ customFields: customFields });
  }

  async getItems() {
    let d = await callGetLeads();
    let items = d.data;
    items.forEach((element) => {
      if (element.custom_fields.length > 0) {
        element.custom_fields.forEach((e) => {
          if (e.type === "dropdown") {
            element[e.id] = e.field_value;
          }
        });
      }
    });
    this.setState({ items: items, itemsBackup: items }, () => {
      this.filterUpdated(this.state.statues, "status", "single", ["status"]);
    });
  }

  onClickEditLead = (lead) => {
    this.props.history.push("/admin/add-lead", {
      lead: lead,
    });
  };

  onClickDeleteLead = (lead) => {
    this.setState({ selectedItem: lead }, () => {
      $(".modalAlertOverlay,.modalAlert").fadeIn(200);
    });
  };

  onClickCompleteLead = async (lead) => {
    await callUpdateLead(lead._id, { status: "completed" });
    this.getItems();
  };

  filterUpdated(updatedFilter, name, type, searchFields) {
    let filterList = this.state.filterList;
    filterList[name] = {
      name: name,
      type: type,
      searchFields: searchFields,
      values: updatedFilter,
    };
    this.setState(
      {
        filterList: filterList,
      },
      () => {
        let data = filterByTags(this.state.itemsBackup, filterList);
        this.setState({
          items: data,
        });
      }
    );
  }

  async onClickMemberName(email) {
    if (email) {
      /*eslint-disable*/
      let users = await callGetUsersByQuery({
        query: { email_address: email },
      });
      /*eslint-enable*/
      if (users.data.length > 0) {
        let url = `/admin/edit-user?id=${users.data[0]._id}`;
        window.open(url, "_blank");
      } else {
        showNotification("error", "User doesn't exists");
      }
    }
  }

  renderItems() {
    var returnVals = [];
    let items = this.state.items;
    let customFields = this.state.customFields;
    items.sort((a, b) => new Date(a.follow_date) - new Date(b.follow_date));

    items.forEach((element) => {
      let customValues = [];
      let customFieldsElement = element.custom_fields || [];
      customFields.forEach((elHeader) => {
        if (elHeader.show) {
          let fValue = customFieldsElement.find((d) => d.id === elHeader.id);
          if (fValue) {
            customValues.push(fValue.field_value);
          } else {
            customValues.push("-");
          }
        }
      });

      returnVals.push(
        <tr key={element._id}>
          <td className="min-width-100">
            <button
              onClick={this.onClickDeleteLead.bind(this, element)}
              className="btn btn-danger btn-sm margin-right-10 border-0">
              <i className="fa fa-trash" />
            </button>
            <button
              onClick={this.onClickEditLead.bind(this, element)}
              className="btn btn-default btn-sm border-0 margin-right-10">
              <i className="fa fa-pencil" />
            </button>
            {element.status !== "completed" && (
              <button
                onClick={this.onClickCompleteLead.bind(this, element)}
                className="btn btn-default btn-sm border-0">
                <i className="fa fa-check" />
              </button>
            )}
          </td>
          <td
            className="pointer-click link"
            onClick={this.onClickMemberName.bind(this, element.email)}>
            {element.name}
          </td>
          <td
            className="pointer-click link"
            onClick={this.onClickMemberName.bind(this, element.email)}>
            {element.email}
          </td>
          <td>{element.phone}</td>
          <td className="firstLetter">
            {element.status === "follow"
              ? "Follow Up"
              : element.status || "pending"}
          </td>
          <td>
            {element.follow_date
              ? moment(element.follow_date).format("Do MMM YYYY hh:mm A")
              : "-"}
          </td>
          <td>
            {element.createdAt
              ? moment(element.createdAt).format("Do MMM YYYY hh:mm A")
              : "-"}
          </td>
          {customValues.map((i) => (
            <td>{i}</td>
          ))}
        </tr>
      );
    });
    return returnVals;
  }

  onClickNoAlert = () => {
    $(".modalAlertOverlay,.modalAlert").fadeOut(200);
  };

  onClickYesAlert = () => {
    callDeleteLead(this.state.selectedItem._id).then((d) => {
      $(".modalAlertOverlay,.modalAlert").fadeOut(200);
      this.getItems();
    });
  };

  renderConfirmationAlert() {
    return (
      <div>
        <div className="modalAlertOverlay" />
        <div className="modalAlert">
          <p>{"Are you sure you want to delete this lead?"}</p>
          <div className="alertFooterBtns">
            <button
              className="btn btn-default closeModal-No margin-right-10"
              onClick={this.onClickNoAlert}>
              No
            </button>
            <button
              className="btn btn-primary closeModal-Yes"
              onClick={this.onClickYesAlert}>
              Yes
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderCustomFilters() {
    let customFields = this.state.customFields;
    let returnVals = [];
    customFields.forEach((element) => {
      if (element.filter === "yes" && element.type === "dropdown") {
        let dropdownValues = element.value.split("\n");
        let filterList = [{ name: "All", value: "all", selected: true }];

        dropdownValues.forEach((elm) => {
          filterList.push({ name: elm, value: elm, selected: false });
        });
        returnVals.push(
          <div className="list-group-item">
            <p>Select a {element.label}</p>
            <FilterList
              filterList={filterList}
              name={element.id}
              type={"single"}
              searchFields={[element.id]}
              onFinish={this.filterUpdated.bind(this)}
            />
          </div>
        );
      }
    });

    return returnVals;
  }

  renderFilterView() {
    return (
      <>
        <div className="list-group-item">
          <FilterList
            label={"Search Leads..."}
            name={"search"}
            type={"search"}
            searchFields={["name", "email", "phone"]}
            onFinish={this.filterUpdated.bind(this)}
          />
        </div>
        <div className="list-group-item">
          <p>Select a Status</p>
          <FilterList
            filterList={this.state.statues}
            name={"status"}
            type={"single"}
            searchFields={["status"]}
            onFinish={this.filterUpdated.bind(this)}
          />
        </div>
        {this.renderCustomFilters()}
      </>
    );
  }

  render() {
    return (
      <div className="container-fluid">
        {this.renderConfirmationAlert()}
        <TitleBox title="Leads Overview" />
        <div className="gap20" />
        <div className="card">
          <div className="list-group list-group-flush">
            {this.renderFilterView()}
            <div className="list-group-item">
              <div class="table-responsive">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Action</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>Status</th>
                      <th>Follow Up Date</th>
                      <th>Created Date</th>
                      {this.state.customFields.map(
                        (i) =>
                          i.show && <th className="firstLetter">{i.label}</th>
                      )}
                    </tr>
                  </thead>
                  <tbody>{this.renderItems()}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ViewLeadPage);
