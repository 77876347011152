import * as api from "./api";
import { callApi } from "../helpers";

function callCreateEmail(data) {
  return callApi(api.service("email").create(data));
}

function callUpdateEmail(id, data) {
  return callApi(api.service("email").patch(id, data));
}

function callDeleteEmail(id) {
  return callApi(api.service("email").remove(id));
}

function callUpdateEmailByQuery(data, query) {
  return callApi(api.service("email").patch(null, data, query));
}

function callGetEmails(query = {}, isLoading = true) {
  return callApi(api.service("email").find(query), isLoading);
}

function callGetEmailUsingId(id) {
  return callApi(api.service("email").get(id));
}

function callUploadImage(uri) {
  return callApi(api.service("new_uploads").create({ uri: uri }));
}

function callGetEmailHistoryByQuery(query) {
  return callApi(api.service("email-history").find(query));
}

function callDeleteEmailHistoryByQuery(query) {
  return callApi(api.service("email-history").remove(null, query));
}

function callGetEmailActionByQuery(query) {
  return callApi(api.service("email-action").find(query));
}

function callDeleteEmailActionByQuery(query) {
  return callApi(api.service("email-action").remove(null, query));
}

function callCreateMemberTagAction(data) {
  return callApi(api.service("member-tag-action").create(data));
}

function callResendEmail(data) {
  return callApi(api.service("general").create(data));
}

function callGetGenericTags(data) {
  return callApi(api.service("general").create(data));
}

function callCreateEmailTemplate(data) {
  return callApi(api.service("email-template").create(data));
}

function callUpdateEmailTemplate(id, data) {
  return callApi(api.service("email-template").patch(id, data));
}

function callGetEmailTemplates(query = {}) {
  return callApi(api.service("email-template").find(query));
}

function callGetEmailTemplateUsingId(id) {
  return callApi(api.service("email-template").get(id));
}

function callGetBouncedEmails(query = {}) {
  return callApi(api.service("bounced-email").find(query));
}

function callGetFormUsers(data) {
  return callApi(api.service("general").create(data));
}

function callUpdateBouncedEmail(id, data, isLoading = true) {
  return callApi(api.service("bounced-email").patch(id, data), isLoading);
}

function callUpdateBouncedEmailByQuery(data, query) {
  return callApi(api.service("bounced-email").patch(null, data, query));
}

function callCreateEmailSequence(data) {
  return callApi(api.service("email-sequence").create(data));
}

function callUpdateEmailSequence(id, data) {
  return callApi(api.service("email-sequence").patch(id, data));
}

function callGetEmailSequences(query = {}) {
  return callApi(api.service("email-sequence").find(query));
}

function callGetEmailSequenceUsingId(id) {
  return callApi(api.service("email-sequence").get(id));
}

function callDeleteEmailSequenceById(id) {
  return callApi(api.service("email-sequence").remove(id));
}

export {
  callCreateEmailSequence,
  callUpdateEmailSequence,
  callGetEmailSequences,
  callGetEmailSequenceUsingId,
  callDeleteEmailSequenceById,
  callDeleteEmail,
  callCreateEmail,
  callUpdateEmail,
  callGetEmails,
  callUploadImage,
  callGetEmailUsingId,
  callDeleteEmailHistoryByQuery,
  callDeleteEmailActionByQuery,
  callCreateMemberTagAction,
  callGetEmailHistoryByQuery,
  callGetEmailActionByQuery,
  callResendEmail,
  callCreateEmailTemplate,
  callUpdateEmailTemplate,
  callGetEmailTemplates,
  callGetEmailTemplateUsingId,
  callGetGenericTags,
  callUpdateEmailByQuery,
  callGetBouncedEmails,
  callGetFormUsers,
  callUpdateBouncedEmail,
  callUpdateBouncedEmailByQuery,
};
