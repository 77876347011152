import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import $ from "jquery";
import moment from "moment-timezone";

import TitleBox from "../../../components/general/titleBox";
import { callGetFilteredTasks } from "../../../services";

class ViewDeletedTasks extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      items: [],
      selectedItem: {},
    };
  }

  componentDidMount() {
    this.getDeletedTasks();
  }

  async getDeletedTasks() {
    let apiData = {
      action: "get-deleted-tasks",
      data: {},
    };
    let tasksResponse = await callGetFilteredTasks(apiData);
    this.setState({ items: tasksResponse });
  }

  onClickRestoreItem = (item) => {
    this.setState({ selectedItem: item }, () => {
      $(
        ".modalAlertOverlay.restoreItemModal,.modalAlert.restoreItemAlert"
      ).fadeIn(200);
    });
  };

  renderItems() {
    var returnVals = [];
    let items = this.state.items;
    items.forEach((element) => {
      returnVals.push(
        <tr key={element._id}>
          <td className="firstLetter">{element.task}</td>
          <td className="firstLetter">{element.status}</td>
          <td>{moment(element.createdAt).format("MMM, Do YYYY")}</td>
          <td className="firstLetter">
            {element.updatedAt
              ? moment(element.updatedAt).format("MMM, Do YYYY")
              : "-"}
          </td>
          <td>
            {element.due_date
              ? moment(element.due_date).format("MMM, Do YYYY")
              : "-"}
          </td>
          <td className="min-width-100">
            <button
              onClick={this.onClickRestoreItem.bind(this, element)}
              className="btn btn-default btn-sm border-0 margin-right-10">
              <i className="fa fa-refresh" />
            </button>
          </td>
        </tr>
      );
    });
    return returnVals;
  }

  onClickNoAlert = () => {
    $(
      ".modalAlertOverlay.restoreItemModal,.modalAlert.restoreItemAlert"
    ).fadeOut(200);
  };

  onClickYesAlert = async () => {
    $(
      ".modalAlertOverlay.restoreItemModal,.modalAlert.restoreItemAlert"
    ).fadeOut(200);
    let apiData = {
      action: "restore-deleted-task",
      data: { itemId: this.state.selectedItem.itemId },
    };
    await callGetFilteredTasks(apiData);
    this.getDeletedTasks();
  };

  renderConfirmationAlert() {
    let selectedItem = this.state.selectedItem;
    let name = "";
    if (selectedItem.task) {
      name = selectedItem.task;
    }
    return (
      <div>
        <div className="modalAlertOverlay restoreItemModal" />
        <div className="modalAlert restoreItemAlert">
          <p>{`Are you sure you want to restore "${name}" task?`}</p>
          <div className="alertFooterBtns">
            <button
              className="btn btn-default closeModal-No margin-right-10"
              onClick={this.onClickNoAlert}>
              No
            </button>
            <button
              className="btn btn-primary closeModal-Yes"
              onClick={this.onClickYesAlert}>
              Yes
            </button>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="container-fluid">
        {this.renderConfirmationAlert()}
        <TitleBox title="Deleted Tasks" />
        <div className="gap20" />
        <div className="card">
          <div className="list-group list-group-flush">
            <div className="list-group-item">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Task</th>
                    <th>Status</th>
                    <th>Created</th>
                    <th>Updated</th>
                    <th>Due Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>{this.renderItems()}</tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ViewDeletedTasks);
