import * as api from "./api";
import { callApi } from "../helpers";

function callCreateCertificate(data) {
  return callApi(api.service("certificate").create(data));
}

function callUpdateCertificate(id, data) {
  return callApi(api.service("certificate").patch(id, data));
}

function callGetCertificates(query = {}) {
  return callApi(api.service("certificate").find(query));
}

function callGetCertificateUsingId(id) {
  return callApi(api.service("certificate").get(id));
}

function callDeleteCertificateUsingId(id) {
  return callApi(api.service("certificate").remove(id));
}

export {
  callCreateCertificate,
  callUpdateCertificate,
  callGetCertificates,
  callGetCertificateUsingId,
  callDeleteCertificateUsingId
};
