import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import $ from "jquery";
import { CopyToClipboard } from "react-copy-to-clipboard";

import TitleBox from "../../../components/general/titleBox";
import FilterList from "../../../components/form/filterList";

import { filterByTags } from "../../../helpers/filters";
import {
  callGetCertificates,
  callDeleteCertificateUsingId
} from "../../../services";
import { showNotification } from "../../../helpers";

class ViewCertificates extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      certificates: [],
      certificatesBackup: [],
      filterList: [],
      selectedCertificate: {}
    };
  }

  componentDidMount() {
    this.getCertificates();
  }

  getCertificates() {
    callGetCertificates().then(d => {
      this.setState({ certificates: d.data, certificatesBackup: d.data });
    });
  }

  onClickEditCertificate = certificate => {
    this.props.history.push("/admin/add-certificate", {
      certificate: certificate
    });
  };

  onClickDeleteCertificate = certificate => {
    this.setState({ selectedCertificate: certificate }, () => {
      $(".modalAlertOverlay,.modalAlert").fadeIn(200);
    });
  };

  filterUpdated(updatedFilter, name, type, searchFields) {
    let filterList = this.state.filterList;
    filterList[name] = {
      name: name,
      type: type,
      searchFields: searchFields,
      values: updatedFilter
    };
    this.setState(
      {
        filterList: filterList
      },
      () => {
        let data = filterByTags(this.state.certificatesBackup, filterList);
        this.setState({
          certificates: data
        });
      }
    );
  }

  renderCertificates() {
    var returnVals = [];
    let items = this.state.certificates;
    items.forEach(element => {
      returnVals.push(
        <tr key={element._id}>
          <td>{element.name}</td>
          <td>{element.fields.length}</td>
          <td className="min-width-100">
            <CopyToClipboard
              text={`{{certificate(${element.certificate_id})}}`}
              onCopy={() =>
                showNotification(
                  "info",
                  "Placeholder Copied - Please add this placeholder into your email (or as and image url inside your email) and it will be replaced with the certificate image.",
                  10000
                )
              }
            >
              <button className="btn btn-sm btn-default margin-right-10 border-0">
                <i className="fa fa-clipboard" />
              </button>
            </CopyToClipboard>
            <button
              onClick={this.onClickEditCertificate.bind(this, element)}
              className="btn btn-default btn-sm margin-right-10 border-0"
            >
              <i className="fa fa-pencil" />
            </button>
            <button
              onClick={this.onClickDeleteCertificate.bind(this, element)}
              className="btn btn-danger btn-sm margin-right-10 border-0"
            >
              <i className="fa fa-trash" />
            </button>
          </td>
        </tr>
      );
    });
    return returnVals;
  }

  onClickNoAlert = () => {
    $(".modalAlertOverlay,.modalAlert").fadeOut(200);
  };

  onClickYesAlert = () => {
    callDeleteCertificateUsingId(this.state.selectedCertificate._id).then(d => {
      $(".modalAlertOverlay,.modalAlert").fadeOut(200);
      this.getCertificates();
    });
  };

  renderConfirmationAlert() {
    let selectedCertificate = this.state.selectedCertificate;
    let name = "";
    if (selectedCertificate.name) {
      name = selectedCertificate.name;
    }
    return (
      <div>
        <div className="modalAlertOverlay" />
        <div className="modalAlert">
          <p>{`Are you sure you want to delete "${name}" certificate?`}</p>
          <div className="alertFooterBtns">
            <button
              className="btn btn-default closeModal-No margin-right-10"
              onClick={this.onClickNoAlert}
            >
              No
            </button>
            <button
              className="btn btn-primary closeModal-Yes"
              onClick={this.onClickYesAlert}
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderFilterView() {
    return (
      <div className="list-group-item">
        <FilterList
          label={"Search Certificates..."}
          name={"search"}
          type={"search"}
          searchFields={["name"]}
          onFinish={this.filterUpdated.bind(this)}
        />
      </div>
    );
  }

  render() {
    return (
      <div className="container-fluid">
        {this.renderConfirmationAlert()}
        <TitleBox title="Certificates Overview" />
        <div className="gap20" />
        <div className="card">
          <div className="list-group list-group-flush">
            {this.renderFilterView()}
            <div className="list-group-item">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>No of Fields</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>{this.renderCertificates()}</tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ViewCertificates);
