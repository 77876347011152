import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import TitleBox from "../../../components/general/titleBox";
import ImageUpload from "../../../components/form/imageUpload";
import {
  callCreateCompany,
  uploadImage,
  callUpdateCompany,
  callGetUsersByQuery,
  callUpdateUser,
  callCreateUser,
  callGetSystemTagUsingName,
  callAddMemberTag,
  callCreateSystemTag,
} from "../../../services";
import { showNotification, imageUrl, slugify } from "../../../helpers";
import config from "../../../helpers/config";
import HelpTextDiv from "../../../components/form/helpTextDiv";

class AddCompany extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    let companyInfo = {
      id: 0,
      name: "",
      description: "",
      email: "",
      phone: "",
      companyImage: "",
      existingImage: "",
      companyImageUpload: "",
      showEditImageSaveButton: false,
      clearUploadOnSuccess: true,
    };

    if (this.props.history.location.state !== undefined) {
      let company = this.props.history.location.state.company;

      companyInfo.id = company._id;
      companyInfo.name = company.name;
      companyInfo.description = company.description;
      companyInfo.email = company.email;
      companyInfo.phone = company.phone;
      companyInfo.existingImage = company.image;
    }

    this.state = {
      companyId: companyInfo.id,
      name: companyInfo.name,
      description: companyInfo.description,
      email: companyInfo.email,
      phone: companyInfo.phone,
      companyImage: companyInfo.companyImage,
      existingImage: companyInfo.existingImage,
      companyImageUpload: companyInfo.companyImageUpload,
      showEditImageSaveButton: companyInfo.showEditImageSaveButton,
      clearUploadOnSuccess: companyInfo.clearUploadOnSuccess,
      companyMemberTagId: "",
    };
  }

  componentDidMount() {
    let companyMemberTag = config.tags.companyMemberTag;

    callGetSystemTagUsingName(companyMemberTag).then((d) => {
      if (d.data.length > 0) {
        this.setState({ companyMemberTagId: d.data[0]._id });
      }
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  resetProps() {
    this.setState({
      name: "",
      description: "",
      email: "",
      phone: "",
      companyImage: "",
      companyImageUpload: "",
      showEditImageSaveButton: false,
      clearUploadOnSuccess: true,
    });
  }

  onChangeCompanyImage(image, file) {
    this.setState({
      companyImage: image.src,
      companyImageUpload: file,
      showEditImageSaveButton: true,
      clearUploadOnSuccess: false,
    });
  }

  imageEdited(file, image) {
    this.setState({
      companyImage: image.src,
      companyImageUpload: file,
      showEditImageSaveButton: false,
      clearUploadOnSuccess: true,
    });
  }

  imageEditCanceled() {
    this.setState({
      showEditImageSaveButton: false,
      clearUploadOnSuccess: true,
    });
  }

  removePhoto() {
    this.setState({
      existingImage: "",
    });
  }

  goToAddCompanyLicense(company) {
    this.props.history.push("/admin/add-company-license", { company: company });
  }

  async callCreateUserAPI(company) {
    let emailAddress = company.email.toLowerCase().trim();
    let userInformation = {};

    /*eslint-disable*/
    let query = {
      query: {
        email_address: emailAddress,
      },
    };
    /*eslint-enable*/
    let userData = await callGetUsersByQuery(query);

    /*eslint-disable*/
    let companyInfo = {
      id: company._id,
      user_role: "admin",
      is_customer: false,
    };
    /*eslint-enable*/

    if (userData.data.length > 0) {
      // user exists so update company id
      let userInfo = userData.data[0];
      /*eslint-disable*/
      let companyList = userInfo.company_list || [];
      companyList.push(companyInfo);

      let updatedUser = await callUpdateUser(userInfo._id, {
        company_list: companyList,
      });
      if (updatedUser._id) {
        userInformation = updatedUser;
      }
      /*eslint-enable*/
    } else {
      // user does not exists so create new user with company id
      let password = Math.random().toString(36).substr(2, 6);

      let apiData = {
        /*eslint-disable*/
        name: company.name,
        email_address: company.email.toLowerCase().trim(),
        password: password,
        initial_password: password,
        units: "standard",
        member_type: "customer",
        master_roles: false,
        is_trainer: false,
        company_list: [companyInfo],
        member_update: {
          clicked_updates: [],
          last_viewed_updates: new Date(),
        },
        /*eslint-enable*/
      };
      let createdUser = await callCreateUser(apiData);
      if (createdUser._id) {
        userInformation = createdUser;
      }
    }

    if (userInformation._id) {
      if (this.state.companyMemberTagId) {
        let apiData = {
          action: "add-member-tag",
          data: {
            /*eslint-disable*/
            member_id: userInformation._id,
            tag_id: this.state.companyMemberTagId,
            /*eslint-enable*/
          },
        };
        await callAddMemberTag(apiData);
      }
    }

    /*eslint-disable*/
    let tagData = {
      name: company.company_tag,
      description: company.name,
      type: "system",
      rules: [
        {
          limit_tag_per_days: "1",
        },
      ],
      additional_rules: [],
    };
    /*eslint-enable*/

    await callCreateSystemTag(tagData);

    this.goToAddCompanyLicense(company);
  }

  callCreateCompanyAPI(image) {
    /*eslint-disable*/
    let apiData = {
      name: this.state.name,
      description: this.state.description,
      email: this.state.email,
      phone: this.state.phone,
      image: image,
    };
    /*eslint-enable*/

    if (this.state.companyId === 0) {
      apiData["company_tag"] = `company-${slugify(this.state.name)}`;

      callCreateCompany(apiData).then(
        (d) => {
          if (d._id) {
            this.resetProps();
            this.callCreateUserAPI(d);
            showNotification("success", "Company Created Successfully", 4000);
          } else {
            showNotification("error", "Something went wrong", 4000);
          }
        },
        (error) => {
          showNotification("error", error.message, 4000);
        }
      );
    } else {
      callUpdateCompany(this.state.companyId, apiData).then(
        (d) => {
          if (d._id) {
            showNotification("success", "Company Updated Successfully", 4000);
          } else {
            showNotification("error", "Something went wrong", 4000);
          }
        },
        (error) => {
          showNotification("error", error.message, 4000);
        }
      );
    }
  }

  onClickAddCompany() {
    // add company
    if (this.state.name.trim().length === 0) {
      showNotification("error", "Name is required", 4000);
      return;
    }

    if (this.state.description.trim().length === 0) {
      showNotification("error", "Description is required", 4000);
      return;
    }

    if (this.state.email.trim().length === 0) {
      showNotification("error", "Email is required", 4000);
      return;
    }

    if (this.state.phone.trim().length === 0) {
      showNotification("error", "Phone is required", 4000);
      return;
    }

    if (this.state.companyImageUpload) {
      uploadImage(this.state.companyImageUpload).then((resp) => {
        resp.json().then((data) => {
          this.callCreateCompanyAPI(data.file_name);
        });
      });
    } else {
      this.callCreateCompanyAPI(this.state.existingImage);
    }
  }

  render() {
    return (
      <div>
        <div className="container-fluid">
          <TitleBox
            title={this.state.companyId === 0 ? "Add Company" : "Edit Company"}
            showBackBtn={this.state.companyId !== 0}
          />
          <div className="gap20" />
          <div className="card">
            <div className="container-fluid">
              <div className="gap20" />
              <HelpTextDiv
                id="idCompanyName"
                label="Learn more about the Company Name"
                type="addCompany"
                isInline="false"
              />
              <div
                className={
                  this.state.name !== "" ? "mdInput mdFocussed" : "mdInput"
                }>
                <label>Name</label>
                <input
                  type="text"
                  name="name"
                  value={this.state.name}
                  onChange={(event) => this.handleInputChange(event)}
                />
              </div>
              <div className="gap20" />
              <HelpTextDiv
                id="idCompanyDescription"
                label="Learn more about the Company Description"
                type="addCompany"
                isInline="false"
              />
              <div
                className={
                  this.state.description !== ""
                    ? "mdInput mdFocussed"
                    : "mdInput"
                }>
                <label>Description</label>
                <input
                  type="text"
                  name="description"
                  value={this.state.description}
                  onChange={(event) => this.handleInputChange(event)}
                />
              </div>
              <div className="gap20" />
              <HelpTextDiv
                id="idCompanyEmail"
                label="Learn more about the Company Email"
                type="addCompany"
                isInline="false"
              />
              <div
                className={
                  this.state.email !== "" ? "mdInput mdFocussed" : "mdInput"
                }>
                <label>Email</label>
                <input
                  type="text"
                  name="email"
                  value={this.state.email}
                  onChange={(event) => this.handleInputChange(event)}
                />
              </div>
              <div className="gap20" />
              <HelpTextDiv
                id="idCompanyPhone"
                label="Learn more about the Company Phone"
                type="addCompany"
                isInline="false"
              />
              <div
                className={
                  this.state.phone !== "" ? "mdInput mdFocussed" : "mdInput"
                }>
                <label>Phone</label>
                <input
                  type="text"
                  name="phone"
                  value={this.state.phone}
                  onChange={(event) => this.handleInputChange(event)}
                />
              </div>
              <div className="gap20" />

              {this.state.existingImage === "" && (
                <div>
                  <HelpTextDiv
                    id="idCompanyImage"
                    label="Learn more about the Company Image"
                    type="addCompany"
                    isInline="false"
                  />
                  <ImageUpload
                    thumbnailAlt={"Upload Company Image"}
                    onChangeImage={this.onChangeCompanyImage.bind(this)}
                    type={"full"}
                    width={100}
                    height={100}
                    clearOnSuccess={this.state.clearUploadOnSuccess}
                    file={this.state.companyImage}
                    onSaveEdit={this.imageEdited.bind(this)}
                    onCancelEdit={this.imageEditCanceled.bind(this)}
                    showSaveButton={this.state.showEditImageSaveButton}
                    scaleOnEdit={true}
                  />
                  <div className="gap20" />
                  <div className="gap20" />
                  {this.state.companyImage !== "" ? (
                    <div>
                      <h5 className="">Company Image</h5>
                      <div className="row">
                        <div className="col-sm-2">
                          <div
                            className="addedProgressPhotos"
                            style={{
                              backgroundImage:
                                "url(" + this.state.companyImage + ")",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              )}
              {this.state.existingImage !== "" && (
                <div>
                  <HelpTextDiv
                    id="idCompanyImage"
                    label="Learn more about the Company Image"
                    type="addCompany"
                    isInline="false"
                  />
                  <div className="inputFakeLabel">Company Image</div>
                  <div className="row">
                    <div className="col-sm-2">
                      <div className="text-right">
                        {/*eslint-disable-next-line*/}
                        <a onClick={(e) => this.removePhoto()} className="">
                          X
                        </a>
                      </div>
                      <div
                        className="addMealPlanPhoto"
                        style={{
                          backgroundImage:
                            "url(" + imageUrl(this.state.existingImage) + ")",
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="gap20" />
            </div>
          </div>

          <div className="text-right" onClick={() => this.onClickAddCompany()}>
            <button className="btn btn-primary">
              {this.state.companyId === 0 ? "Add Company" : "Update Company"}
            </button>
          </div>
          <div className="gap20" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddCompany);
