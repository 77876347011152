import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment-timezone";

import TitleBox from "../../../components/general/titleBox";
import {
  callGetPayTaskHistory,
  callGetTaskHistoryTemplates,
  callPayTaskInvoices,
} from "../../../services";
import { imageUrl, showNotification } from "../../../helpers";
import { saveAs } from "file-saver";

class PayTaskInvoiceHistoryPage extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = { items: [], templates: [] };
  }

  componentDidMount() {
    this.getHistory();
    this.getTemplates();
  }

  async getTemplates() {
    let response = await callGetTaskHistoryTemplates();
    this.setState({ templates: response.data });
  }

  async getHistory() {
    let data = await callGetPayTaskHistory();
    this.setState({ items: data });
  }

  async onClickTemplate(template, history) {
    if (history.reports) {
      if (history.reports[template._id]) {
        showNotification("info", "Downloading history report...", 2000);
        saveAs(
          imageUrl(history.reports[template._id]),
          history.reports[template._id]
        );
        return;
      }
    }
    showNotification(
      "info",
      "Generating & Downloading history report...",
      2000
    );
    let apiSendData = {
      action: "prepare-task-history-template",
      data: {
        templateId: template._id,
        historyId: history._id,
      },
    };
    let response = await callPayTaskInvoices(apiSendData);
    if (response.fileKey) {
      saveAs(imageUrl(response.fileKey), response.fileKey);
    } else {
      showNotification("error", "Error downloading history", 4000);
    }
    this.getHistory();
  }

  renderInvoices() {
    var returnVals = [];
    let items = this.state.items;

    for (let i = 0; i < items.length; i++) {
      const element = items[i];

      returnVals.push(
        <tr key={element._id}>
          <td>{element.pay_by_info[0].name}</td>
          <td>{moment(element.createdAt).format("Do MMM YYYY hh:mm A")}</td>
          <td>
            {this.state.templates.map((i) => (
              <button
                className="btn btn-default btn-sm margin-right-10"
                onClick={this.onClickTemplate.bind(this, i, element)}>
                {i.name}
              </button>
            ))}
          </td>
        </tr>
      );
    }
    return returnVals;
  }

  onClickTemplates() {
    this.props.history.push("/admin/task-history-templates");
  }

  render() {
    return (
      <div className="container-fluid">
        <TitleBox title={"Pay Task History"} showBackBtn={true} />
        <div className="gap20" />
        <div className="card">
          <div className="list-group list-group-flush">
            <div className="list-group-item">
              <div className="pull-right">
                <button
                  className="btn btn-primary btn-sm"
                  onClick={this.onClickTemplates.bind(this)}>
                  Templates
                </button>
              </div>
            </div>
            <div className="list-group-item">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Pay By</th>
                    <th>Created Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>{this.renderInvoices()}</tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PayTaskInvoiceHistoryPage);
