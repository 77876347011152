import * as api from "./api";
import { callApi } from "../helpers";

function callCreateAccountabilityResponse(data) {
  return callApi(api.service("accountability-response").create(data));
}

function callDeleteAccountabilityResponseByQuery(query) {
  return callApi(api.service("accountability-response").remove(null, query));
}

function callGetAccountabilityResponseByQuery(query, isLoading = true) {
  return callApi(api.service("accountability-response").find(query), isLoading);
}
function callUpdateAccountabilityResponseByQuery(data, query) {
  return callApi(
    api.service("accountability-response").patch(null, data, query)
  );
}

export {
  callGetAccountabilityResponseByQuery,
  callCreateAccountabilityResponse,
  callDeleteAccountabilityResponseByQuery,
  callUpdateAccountabilityResponseByQuery,
};
