import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import TitleBox from "../../../components/general/titleBox";
import {
  callGetChlgsByQuery,
  callCreateChallengeGroup,
  callUpdateChallengeGroup
} from "../../../services";
import Checkbox from "../../../components/form/checkBox";
import RadioTag from "../../../components/form/radioTag";
import { showNotification } from "../../../helpers";
import HelpTextDiv from "../../../components/form/helpTextDiv";

class AddChallengeGroup extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    let challengeGroup = {
      _id: 0,
      name: "",
      uniqueId: "",
      selectedChallengeList: [],
      keyChallengeList: Math.random(),
      isActive: true,
      groupStatusLabel: "Currently Active"
    };

    let editMode = false;

    if (this.props.history.location.state !== undefined) {
      let group = this.props.history.location.state.challengeGroup;

      challengeGroup._id = group._id;
      challengeGroup.uniqueId = group.unique_id || "";
      challengeGroup.name = group.name;
      challengeGroup.isActive = group.active;
      challengeGroup.groupStatusLabel = group.active
        ? "Currently Active"
        : "Currently Inactive";

      challengeGroup.selectedChallengeList = group.challenge_list;
      editMode = true;
    }

    this.state = {
      editMode: editMode,
      challengeGroupId: challengeGroup._id,
      name: challengeGroup.name,
      uniqueId: challengeGroup.uniqueId,
      challengeList: [],
      selectedChallengeList: challengeGroup.selectedChallengeList,
      keyChallengeList: challengeGroup.keyChallengeList,
      isActive: challengeGroup.isActive,
      groupStatusLabel: challengeGroup.groupStatusLabel
    };
  }

  componentDidMount() {
    let query = {
      query: {
        $select: ["name"]
      }
    };
    callGetChlgsByQuery(query).then(d => {
      let challengeList = [];
      if (d.data.length > 0) {
        d.data.forEach(element => {
          challengeList.push({
            name: element.name,
            value: element._id
          });
        });
      }
      this.setState({
        challengeList: challengeList,
        keyChallengeList: Math.random()
      });
    });
  }

  componentWillMount() {
    if (!this.state.isActive && this.state.challengeGroupId !== 0) {
      showNotification(
        "warning",
        "This group credit is currently inactive.",
        false
      );
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  resetProps() {
    this.setState({
      name: "",
      uniqueId: "",
      selectedChallengeList: [],
      keyChallengeList: Math.random(),
      isActive: true,
      groupStatusLabel: "Currently Active"
    });
  }

  toggleActiveCheckbox = check => {
    this.setState({
      isActive: check,
      groupStatusLabel: check ? "Currently Active" : "Currently Inactive"
    });
  };

  onSelectChallenge(item, id) {
    let challenges = [];
    if (Object.keys(item).length > 0 && item.constructor === Object) {
      Object.keys(item).forEach(key => {
        challenges.push(item[key].value);
      });
    }
    this.setState({ selectedChallengeList: challenges });
  }

  onClickSave() {
    if (this.state.name.trim().length === 0) {
      showNotification("error", "Name is required", 4000);
      return;
    }

    if (this.state.uniqueId.trim().length === 0) {
      showNotification("error", "Unique Id is required", 4000);
      return;
    }

    if (this.state.selectedChallengeList.length < 2) {
      showNotification("error", "Select atleast two challenges", 4000);
      return;
    }

    /*eslint-disable*/
    let apiData = {
      name: this.state.name,
      unique_id: this.state.uniqueId,
      challenge_list: this.state.selectedChallengeList,
      active: this.state.isActive
    };
    /*eslint-enable*/

    if (this.state.editMode) {
      callUpdateChallengeGroup(this.state.challengeGroupId, apiData).then(
        d => {
          if (d._id) {
            showNotification(
              "success",
              "Group Credit updated successfully",
              4000
            );
          }
        },
        error => {
          showNotification("error", error.message, 4000);
        }
      );
    } else {
      callCreateChallengeGroup(apiData).then(
        d => {
          if (d._id) {
            showNotification(
              "success",
              "Group Credit added successfully",
              4000
            );
            this.resetProps();
          }
        },
        error => {
          showNotification("error", error.message, 4000);
        }
      );
    }
  }

  render() {
    return (
      <div>
        <div className="container-fluid">
          <TitleBox
            title={
              this.state.editMode ? "Edit Group Credit" : "Add Group Credit"
            }
            showBackBtn={this.state.editMode}
          />
          <div className="gap20" />
          <div className="card">
            <div className="list-group list-group-flush">
              <div className="list-group-item">
                <div className="container-fluid">
                  <div className="gap20" />

                  <div className="havingHelpText">
                    <Checkbox
                      label={this.state.groupStatusLabel}
                      isChecked={this.state.isActive}
                      handleCheckboxChange={this.toggleActiveCheckbox.bind(
                        this
                      )}
                      key={"Group Status"}
                    />
                  </div>
                  <HelpTextDiv
                    id="idChallengeGroupStatus"
                    label="Learn more about the Status"
                    type="addChallengeGroup"
                    isInline="true"
                  />
                  <div className="gap10" />

                  <HelpTextDiv
                    id="idChallengeGroupName"
                    label="Learn more about the Name"
                    type="addChallengeGroup"
                    isInline="false"
                  />
                  <div
                    className={
                      this.state.name !== "" ? "mdInput mdFocussed" : "mdInput"
                    }
                  >
                    <label>Name</label>
                    <input
                      type="text"
                      name="name"
                      value={this.state.name}
                      onChange={event => this.handleInputChange(event)}
                    />
                  </div>
                  <div className="gap10" />

                  <HelpTextDiv
                    id="idChallengeGroupUniqueId"
                    label="Learn more about the Unique ID"
                    type="addChallengeGroup"
                    isInline="false"
                  />
                  <div
                    className={
                      this.state.uniqueId !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }
                  >
                    <label>Unique Id</label>
                    <input
                      disabled={this.state.challengeGroupId !== 0}
                      type="text"
                      name="uniqueId"
                      value={this.state.uniqueId}
                      onChange={event => this.handleInputChange(event)}
                    />
                  </div>
                  <div className="gap20" />

                  <div className="radioBHolder">
                    <div className="havingHelpText">
                      <p>Select Challenges</p>
                    </div>
                    <HelpTextDiv
                      id="idChallengeGroupChallenges"
                      label="Learn more about the Challenges"
                      type="addChallengeGroup"
                      isInline="true"
                    />
                    <div className="tabsHolder radioTabs">
                      <RadioTag
                        onCheckChange={this.onSelectChallenge.bind(this)}
                        labelList={this.state.challengeList}
                        id={"challengeList"}
                        type={"multiple"}
                        key={this.state.keyChallengeList}
                        selectedList={this.state.selectedChallengeList}
                        selectType={"value"}
                      />
                    </div>
                    <div className="gap20" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="text-right" onClick={() => this.onClickSave()}>
            {/*eslint-disable-next-line*/}
            <a className="btn btn-primary">Save</a>
          </div>
          <div className="gap20" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AddChallengeGroup);
