import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import $ from "jquery";
import PubSub from "pubsub-js";
import { saveAs } from "file-saver";

import TitleBox from "../../../components/general/titleBox";
import {
  getParameterByName,
  showNotification,
  getUniqueId,
  getLoggedInUser,
  imageUrl,
  slugify,
  sanitizer,
  linkify,
} from "../../../helpers";
import {
  callGetTaskById,
  callGetUsersUsingQuery,
  callUpdateTask,
  callAddMemberTagL,
  callGetSystemTagUsingName,
  callGetGenericTags,
  callCreatePdfGeneration,
  callGetPdfGenerationByQuery,
  callExecuteTaskRules,
  callGetPdfTemplatesUsingQuery,
  callGetFormById,
  callGetFormsUsingQuery,
  callGetFilteredTasks,
  callGetUserById,
  callGetFormSubmissionById,
  callGetUsersByQuery,
} from "../../../services";
import { TaskBasicInfo } from "./components/basicInfo";
import AddTaskComment from "./components/addComment";
import TaskCommentList from "./components/commentList";
import RadioTag from "../../../components/form/radioTag";

import config from "../../../helpers/config";
import TaskDelete from "./components/deleteTask";
import TaskEdit from "./components/editTask";
import { DraftEditor } from "../../../packages/draft-editor/index";
import TaskImageList from "./components/imageList";
import moment from "moment-timezone";
import { VelocityTransitionGroup } from "velocity-react";
import { callGetSettings } from "../../../services/settingService";
import PrePageForm from "../forms/previewForm";
import { getItem } from "../../../helpers/storage";
import SearchableUserSelect from "../../../components/form/searchableUserSelect";
import DateTimePicker from "react-datetime-picker/dist/DateTimePicker";
import { SettingsHelper } from "../../../helpers/settings";
import io from "socket.io-client";
import Checkbox from "../../../components/form/checkBox";

class TaskDetailPage extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    let taskId = getParameterByName("tid");

    let from = "member";

    if (window.location.pathname.includes("/admin/task-detail")) {
      from = "admin";
    }

    this.state = {
      from,
      taskId,
      userData: this.props.userData || {},
      loading: true,
      taskInfo: {},
      staffUsers: [],
      keyCommentView: Math.random(),
      taskCommentTagId: "",
      taskMentionTagId: "",
      keyRadioSelect: Math.random(),
      taskStatus: "",
      comment: "",
      commentJson: {},
      keyDeleteTask: Math.random(),
      history: [],
      showHistory: false,
      showForm: false,
      showDbHistory: false,
      statusList: [],
      fieldCategories: [],
      customFields: [],
      selectedCustomField: {},
      keyCustomField: Math.random(),
      taskPages: [],
      pdfGenerations: [],
      showPdfs: false,
      hideTableFields: [],
      pdfTemplates: [],
      selectedPdfTemplate: "",
      selectedField: "",
      keyDraftEditor: Math.random(),
      task: "",
      description: "",
      descriptionJson: {},
      priority: "",
      keySelectUser: Math.random(),
      selectedStaffUser: {},
      dueDateOption: "no",
      dueDate: new Date(),
      keyDueDate: Math.random(),
      notiTime: 0,
      notiType: "minutes",
      keyNotiType: Math.random(),
      formInfo: null,
      forms: [],
      selectedForm: "",
      duplicateTasks: [],
      statusUpdatedBy: "",
      formSubmittedByUser: "",
      formSubmissionInfo: {},
      taskName: "",
      keyTaskName: Math.random(),
      taskSocketUsers: [],
      note: "",
      noteJson: {},
      noteInternal: true,
      keyNoteInternal: Math.random(),
    };
  }

  componentDidMount() {
    this.getData();
  }

  async getData() {
    await this.getStaffUsers();
    await this.getTaskInfo();
    let userId = getItem("userId");
    let userName = "";
    let users = await callGetUsersByQuery({
      query: { _id: userId, $select: ["name"], populate: "no" },
    });
    if (users.data.length > 0) {
      userName = users.data[0].name;
    }
    this.setSockets(userName);
    this.getTaskName();
    // await this.getForms();
    await this.callGetTagByName();
    await this.getSettings();
    await this.updateHistory("open");
    await this.getFieldCategories();
    // await this.getPdfGenerations();
    // await this.getPdfTemplates();
    await this.getTasksDuplicates();
    this.setState({ loading: false });
  }

  getTaskName() {
    let settingsHelper = new SettingsHelper();
    let taskName = settingsHelper.getTasksName() || "Task";
    this.setState({
      taskName: taskName,
      keyTaskName: Math.random(),
    });
  }

  async getForms() {
    const response = await callGetFormsUsingQuery({ $select: ["_id", "name"] });
    this.setState({ forms: response.data });
  }

  async getTasksDuplicates() {
    let apiData = {
      action: "get-duplicate-tasks",
      data: { taskId: this.state.taskId },
    };
    let tasksResponse = await callGetFilteredTasks(apiData);
    this.setState({ duplicateTasks: tasksResponse });
  }

  getFieldCategories() {
    callGetGenericTags("taskfields").then((d) => {
      this.setState({ fieldCategories: d.data });
    });
  }

  async getSettings() {
    let d = await callGetSettings("tasksettings");
    let settings = d.data;
    let taskSettings = settings.task_settings || {};
    let showHistory = taskSettings.show_history;

    let taskStatusList = taskSettings.task_statuses || [];
    let hideTableFields = taskSettings.hide_table_fields || [];

    let statusList = [];
    taskStatusList.forEach((element) => {
      if (element.select || this.state.from === "admin") {
        statusList.push({
          name: element.name[0].toUpperCase() + element.name.slice(1),
          value: element.name,
        });
      }
    });

    let customFields = taskSettings.custom_fields || [];
    let taskReportsSettings = settings.task_reports_settings || {};

    this.setState({
      hideTableFields,
      customFields,
      showDbHistory: showHistory,
      statusList,
      keyRadioSelect: Math.random(),
      taskPages: taskReportsSettings.pages || [],
    });
  }

  async getPdfGenerations() {
    /*eslint-disable*/
    let query = {
      query: {
        task_id: this.state.taskId,
      },
    };
    if (this.state.from !== "admin") {
      query.query["member_id"] = getItem("userId");
    }
    /*eslint-enable*/
    var response = await callGetPdfGenerationByQuery(query);
    let data = response.data;
    data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    this.setState({ pdfGenerations: response.data });
  }

  async getPdfTemplates() {
    let query = {
      query: {},
    };
    if (this.state.from === "admin") {
      query.query["staff"] = true;
    } else if (this.state.from === "member") {
      query.query["customer"] = true;
    }
    var response = await callGetPdfTemplatesUsingQuery(query);
    this.setState({ pdfTemplates: response.data });
  }

  componentWillReceiveProps(newProps) {
    if (newProps.userData) {
      this.setState({ userData: newProps.userData });
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  async callGetTagByName() {
    let taskCommentTag = config.tags.taskCommentTag;
    let tagCommentId = "";
    let d = await callGetSystemTagUsingName(taskCommentTag);
    if (d.data.length > 0) {
      tagCommentId = d.data[0]._id;
    }
    let taskMetionTag = config.tags.taskMentionTag;
    let tagMentionId = "";
    let dm = await callGetSystemTagUsingName(taskMetionTag);
    if (dm.data.length > 0) {
      tagMentionId = dm.data[0]._id;
    }
    this.setState({
      taskCommentTagId: tagCommentId,
      taskMentionTagId: tagMentionId,
    });
  }

  async getStaffUsers() {
    /*eslint-disable*/
    let query = {
      query: {
        $or: [{ member_type: "staff" }],
        status: { $ne: "archive" },
        $select: ["_id", "name", "profile_image_url"],
        populate: "no",
      },
    };
    /*eslint-enable*/

    let d = await callGetUsersUsingQuery(query, false);
    this.setState({ staffUsers: d.data });
  }

  getUserName(id) {
    let users = this.state.staffUsers;
    let fUsers = users.filter((d) => d._id === id);
    if (fUsers.length > 0) {
      return fUsers[0].name;
    }
    return "-";
  }

  async getTaskInfo() {
    let taskId = this.state.taskId;
    let d = await callGetTaskById(taskId, { query: { from: this.state.from } });
    let createdByUser = this.getUserName(d.created_by);
    d["created_by_name"] = createdByUser;

    let formInfo = null;
    let formSubmissionInfo = null;
    if (d.form && d.form.form_id) {
      formInfo = await callGetFormById(d.form.form_id);
      if (d.form.form_submission_id) {
        formSubmissionInfo = await callGetFormSubmissionById(
          d.form.form_submission_id
        );
      }
    }

    let history = d.history || [];

    let changeHistory = history.filter((e) => e.status === d.status);
    if (changeHistory) {
      changeHistory.sort((a, b) => new Date(b.date) - new Date(a.date));
    }

    let statusUpdatedBy = "";
    if (changeHistory.length > 0) {
      statusUpdatedBy = this.getUserName(changeHistory[0].userId);
    }
    d["statusUpdatedBy"] = statusUpdatedBy;

    this.setState({
      taskInfo: d,
      formInfo: formInfo,
      keyCommentView: Math.random(),
      taskStatus: d.status,
      keyRadioSelect: Math.random(),
      comment: "",
      history: history,
      statusUpdatedBy,
      formSubmissionInfo,
    });
    let userId = getItem("userId");

    if (d.assigned_to === userId) {
      await this.executeTaskRules("taskOpenedAssignedUser", { userId: userId });
    }
  }

  setSockets(userName) {
    const newSocket = io(config.urls.apiUrl);
    let key = getItem("sk");
    let roomName = `task-${this.state.taskId}-${key}`;

    newSocket.on("connect", () => {
      newSocket.emit("joinTask", roomName, userName);
    });

    newSocket.on("currentTaskUsers", (users) => {
      if (users) {
        let userList = Object.values(users).filter(
          (d) => d.userName !== userName && d.room === roomName
        );
        this.setState({ taskSocketUsers: userList });
      }
    });

    this.setState({ socket: newSocket });
  }

  componentWillUnmount() {
    if (this.state.socket) {
      this.state.socket.disconnect();
    }
  }

  async updateHistory(action) {
    let historyObject = {
      date: new Date(),
      action: action,
      userId: getLoggedInUser(),
    };

    await callUpdateTask(this.state.taskId, {
      $push: {
        history: historyObject,
      },
      noUpdate: true,
    });

    let history = this.state.history;
    history.push(historyObject);
    this.setState({ history });

    let apiData = {
      action: "remove-task-rules",
      data: { taskId: this.state.taskId, userId: getItem("userId") },
    };
    await callGetFilteredTasks(apiData);
  }

  onClickSubmitComment = async (comment, commentJson, fileUrl, users) => {
    let commentInfo = {
      userId: this.state.userData._id,
      comment: comment,
      json: commentJson,
      date: new Date(),
      fileUrl: fileUrl,
      users: users,
      id: getUniqueId(),
    };
    let taskInfo = this.state.taskInfo;
    let updatedTaskInfo = await callUpdateTask(taskInfo._id, {
      $push: { comments: commentInfo },
    });

    const userInfo = await callGetUserById(this.state.userData._id);
    if (userInfo._id) {
      let from = userInfo.member_type === "staff" ? "staff" : "user";

      await this.executeTaskRules("onTaskComment", {
        from: from,
      });
    }

    if (updatedTaskInfo._id) {
      await this.getTaskInfo();
      this.callAddTagAPI(
        taskInfo.watchers,
        taskInfo._id,
        commentInfo.id,
        users
      );
      showNotification("success", "Comment added successfully", 2000);
    }
  };

  async executeTaskRules(rule, ruleData) {
    let apiData = {
      action: "execute-task-rule",
      data: {
        taskId: this.state.taskId,
        rule: rule,
        ruleData: ruleData,
      },
    };
    await callExecuteTaskRules(apiData);
  }

  onClickFormSubmit = async () => {
    let taskInfo = this.state.taskInfo;
    let form = taskInfo.form || {};

    form["form_submission_id"] = getItem("formSubmissionId");
    form["status"] = "completed";

    await callUpdateTask(taskInfo._id, {
      form: form,
    });
    await this.executeTaskRules("formCompleted", {});
    showNotification("success", "Form submitted/modified!", 4000);
    window.location.reload();
  };

  onClickSubmitImage = async (images, notification = true) => {
    let taskInfo = this.state.taskInfo;
    await callUpdateTask(taskInfo._id, {
      images: images,
    });
    if (notification) {
      showNotification("sucesss", "Images updated");
    }
    await this.getTaskInfo();
  };

  onClickUpdateComment = async (id, comment, commentJson, users) => {
    let taskInfo = this.state.taskInfo;
    let comments = taskInfo.comments;
    comments.forEach((element) => {
      if (element.id === id) {
        element.comment = comment;
        element.json = commentJson;
        element.users = users;
      }
    });

    let updatedTaskInfo = await callUpdateTask(taskInfo._id, {
      comments: comments,
    });
    if (updatedTaskInfo._id) {
      let comments = taskInfo.comments || [];
      taskInfo.comments = comments;
      this.setState({ taskInfo: taskInfo, keyCommentView: Math.random() });
      showNotification("success", "Comment updated successfully", 2000);
    }
  };

  onDeleteComment = async (commentId) => {
    let taskInfo = this.state.taskInfo;
    let comments = taskInfo.comments.filter((d) => {
      return d.id !== commentId;
    });
    let updatedTaskInfo = await callUpdateTask(taskInfo._id, {
      comments: comments,
    });
    if (updatedTaskInfo._id) {
      taskInfo.comments = comments;
      this.setState({ taskInfo: taskInfo, keyCommentView: Math.random() });
    }
  };

  async callAddTagAPI(users, taskId, commentId, mentionUsers) {
    let commentUsers = users.filter((d) => {
      return mentionUsers.indexOf(d) !== -1;
    });

    if (this.state.taskCommentTagId) {
      for (let i = 0; i < commentUsers.length; i++) {
        const element = commentUsers[i];
        let apiData = {
          action: "add-member-tag",
          data: {
            /*eslint-disable*/
            member_id: element,
            tag_id: this.state.taskCommentTagId,
            task_id: taskId,
            comment_id: commentId,
            action: {
              type: "task",
              id: taskId,
              id_type: "task",
            },
            /*eslint-enable*/
          },
        };
        await callAddMemberTagL(apiData);
      }
    }

    if (this.state.taskMentionTagId) {
      for (let i = 0; i < mentionUsers.length; i++) {
        const element = mentionUsers[i];
        let apiData = {
          action: "add-member-tag",
          data: {
            /*eslint-disable*/
            member_id: element,
            tag_id: this.state.taskMentionTagId,
            task_id: taskId,
            comment_id: commentId,
            action: {
              type: "task",
              id: taskId,
              id_type: "task",
            },
            /*eslint-enable*/
          },
        };
        await callAddMemberTagL(apiData);
      }
    }
  }

  onClickUnlockTicket() {
    $(".modalAlertOverlay.lockModal,.modalAlert.lockAlert").fadeIn(200);
  }

  onClickStatus() {
    $(
      ".modalAlertOverlay.changeStatusModalOverlay,.modalAlert.changeStatusModal"
    ).fadeIn(200);
  }

  onClickAddInternalNotes() {
    $(
      ".modalAlertOverlay.addInternalNotesModalOverlay,.modalAlert.addInternalNotesModal"
    ).fadeIn(200);
  }

  onClickDeleteTask() {
    this.setState({ keyDeleteTask: Math.random() }, () => {
      $(".modalAlertOverlay.deleteModal,.modalAlert.deleteAlert").fadeIn(200);
    });
  }

  async onClickExportTask() {
    $(
      ".modalAlertOverlay.pdfTemplateModalOverlay,.modalAlert.pdfTemplateAlert"
    ).fadeIn(200);
  }

  async onClickLockTicket() {
    let userId = getItem("userId");
    /*eslint-disable*/

    let historyObject = {
      date: new Date(),
      action: "lock",
      userId: userId,
    };
    await callUpdateTask(this.state.taskInfo._id, {
      lock_by: userId,
      $push: { history: historyObject },
    });
    /*eslint-enable*/
    showNotification("success", `${this.state.taskName} locked to yourself`);
    this.getTaskInfo();
  }

  onClickEditTask() {
    if (this.state.from === "admin") {
      this.editTaskRef.onClickEditTask(this.state.taskInfo);
    }
  }

  onClickEditCustomField(customField) {
    if (customField.edit || this.state.from === "admin") {
      let taskInfo = this.state.taskInfo;
      let taskCustomFields = taskInfo.custom_fields || [];

      let taskCustomField = taskCustomFields.find(
        (d) => d.id === customField.id
      );

      let customFields = this.state.customFields;
      customFields.forEach((element) => {
        if (element.id === customField.id) {
          if (taskCustomField) {
            element.selectedOption = taskCustomField.selectedOption;
            element.selectedOptions = taskCustomField.selectedOptions;
          }
        }
      });
      this.setState({ selectedCustomField: customField, customFields }, () => {
        $(
          ".modalAlertOverlay.changeCustomFieldModalOverlay,.modalAlert.changeCustomFieldModal"
        ).fadeIn(200);
      });
    }
  }

  onSelectRadioSelect(item, id) {
    let value = item[Object.keys(item)[0]].value;
    this.setState({ [id]: value });
  }

  onClickCancelFromStatusModal() {
    $(
      ".modalAlertOverlay.changeStatusModalOverlay,.modalAlert.changeStatusModal"
    ).fadeOut(200);
  }

  async onClickSubmitFromStatusModel() {
    $(
      ".modalAlertOverlay.changeStatusModalOverlay,.modalAlert.changeStatusModal"
    ).fadeOut(200);

    let taskInfo = this.state.taskInfo;
    let data = {
      status: this.state.taskStatus,
    };

    if (this.state.comment.trim().length > 0) {
      let commentInfo = {
        userId: this.state.userData._id,
        comment: this.state.comment,
        json: this.state.commentJson,
        date: new Date(),
        fileUrl: "",
        id: getUniqueId(),
      };
      data["$push"] = { comments: commentInfo };
    }

    let d = await callUpdateTask(taskInfo._id, data);

    if (d._id) {
      showNotification("success", "Status changed successfully", 4000);
      await this.executeTaskRules("field", {
        fields: ["status"],
      });

      let historyObject = {
        date: new Date(),
        action: "status change",
        userId: getLoggedInUser(),
        status: this.state.taskStatus,
      };

      await callUpdateTask(this.state.taskId, {
        $push: {
          history: historyObject,
        },
      });

      let history = this.state.history;
      history.push(historyObject);
      this.setState({ history });

      this.getTaskInfo();
      PubSub.publish("refreshOpenTasks", "");
    }
  }

  onChangeCommentValue = (html, json) => {
    this.setState({ comment: html, commentJson: json });
  };

  onChangeNoteValue = (html, json) => {
    this.setState({ note: html, noteJson: json });
  };

  onDeleteTask = () => {
    PubSub.publish("refreshOpenTasks", "");
    this.props.history.goBack();
  };

  onClickShowHistory() {
    this.setState({ showHistory: !this.state.showHistory });
  }

  onClickShowForm() {
    this.setState({ showForm: !this.state.showForm });
  }

  onClickShowPdfs() {
    this.setState({ showPdfs: !this.state.showPdfs });
  }

  onClickDownloadPDF(i) {
    if (i.download_url) {
      saveAs(
        imageUrl(i.download_url),
        `${slugify(this.state.taskInfo.task)}.pdf`
      );
    }
  }

  handleEditorChange(html, json, id, label) {
    let customFields = this.state.customFields || [];
    customFields.forEach((element) => {
      if (element.id === id) {
        element["selectedOption"] = {
          label: label,
          id: id,
          value: html,
          valueJson: json,
        };
      }
    });
    this.setState({
      customFields: customFields,
    });
  }

  handleTextChange(event, id, label) {
    const target = event.target;
    const value = target.value;

    let customFields = this.state.customFields || [];
    customFields.forEach((element) => {
      if (element.id === id) {
        element["selectedOption"] = { label: label, id: id, value: value };
      }
    });
    this.setState({
      customFields: customFields,
    });
  }

  handleDropdownChange(event, id, label) {
    const target = event.target;
    const value = target.value;

    let customFields = this.state.customFields || [];
    customFields.forEach((element) => {
      if (element.id === id) {
        let option = element.options.find((d) => d.id === value);
        element["selectedOption"] = {
          label: option.label,
          id: id,
          value: value,
        };
      }
    });
    this.setState({
      customFields: customFields,
    });
  }

  manageCustomFields(values, ids) {
    let customFields = this.state.customFields || [];

    customFields.forEach((element) => {
      if (ids.indexOf(element.id) > -1) {
        let selectedOption = element.options.filter((d) => {
          return values.indexOf(d.id) > -1;
        });
        if (selectedOption.length > 0) {
          element["selectedOption"] = selectedOption[0];
        }
        let defaultSelectedOption = element.options.filter((d) => {
          return d.selected === true;
        });
        if (defaultSelectedOption.length > 0 && !element["selectedOption"]) {
          element["selectedOption"] = defaultSelectedOption[0];
        }
      }
      if (ids.length === 0) {
        element["selectedOption"] = {};
      }
    });

    this.setState({
      customFields: customFields,
      keyCustomField: Math.random(),
    });
  }

  onSelectCustomOption(item, id) {
    this.manageCustomFields([item[Object.keys(item)[0]].value], [id]);
  }

  onSelectCustomOptions(item, id) {
    let customFields = this.state.customFields || [];
    let values = [];

    if (Object.keys(item).length > 0 && item.constructor === Object) {
      Object.keys(item).forEach((key) => {
        values.push(item[key].value);
      });
    }

    customFields.forEach((element) => {
      if (element.id === id) {
        let selectedOptions = element.options.filter((d) => {
          return values.indexOf(d.id) > -1;
        });
        if (selectedOptions.length > 0) {
          element["selectedOptions"] = selectedOptions;
        } else {
          element["selectedOptions"] = [];
        }
      }
    });

    this.setState({
      customFields: customFields,
      keyCustomField: Math.random(),
    });
  }

  onClickCancelFromCustomFieldModal() {
    $(
      ".modalAlertOverlay.changeCustomFieldModalOverlay,.modalAlert.changeCustomFieldModal"
    ).fadeOut(200);
  }

  async onClickSubmitFromCustomFieldModel() {
    $(
      ".modalAlertOverlay.changeCustomFieldModalOverlay,.modalAlert.changeCustomFieldModal"
    ).fadeOut(200);

    let taskInfo = this.state.taskInfo;
    let taskCustomFields = taskInfo.custom_fields || [];
    let customFields = this.state.customFields;
    let selectedCustomField = this.state.selectedCustomField;

    let fields = [];

    let existingField = taskCustomFields.find(
      (d) => d.id === selectedCustomField.id
    );
    if (!existingField) {
      let newField = customFields.find((d) => d.id === selectedCustomField.id);
      if (newField) {
        taskCustomFields.push(newField);
      }
    }

    taskCustomFields.forEach((element) => {
      if (element.id === selectedCustomField.id) {
        let taskCustomField = customFields.find(
          (d) => d.id === selectedCustomField.id
        );
        if (taskCustomField) {
          if (
            !element.selectedOption ||
            element.selectedOption.value !==
              taskCustomField.selectedOption.value ||
            element.selectedOption.id !== taskCustomField.selectedOption.id
          ) {
            if (fields.indexOf(element.id) === -1) {
              fields.push(element.id);
            }
          }
          if (
            !element.selectedOptions ||
            element.selectedOptions.length !==
              taskCustomField.selectedOptions.length
          ) {
            if (fields.indexOf(element.id) === -1) {
              fields.push(element.id);
            }
          }
          element.selectedOption = taskCustomField.selectedOption;
          element.selectedOptions = taskCustomField.selectedOptions;
        }
      }
    });

    /*eslint-disable*/
    let data = {
      custom_fields: taskCustomFields,
    };
    /*eslint-enable*/

    let d = await callUpdateTask(taskInfo._id, data);

    if (d._id) {
      showNotification("success", "Saved successfully", 4000);

      await this.executeTaskRules("field", {
        fields: fields,
      });

      this.getTaskInfo();
      PubSub.publish("refreshOpenTasks", "");
    }
  }

  renderCustomFieldChangeModal() {
    let taskInfo = this.state.taskInfo;

    let customField = this.state.selectedCustomField;

    if (!taskInfo._id || !customField.id) {
      return;
    }

    let type = customField.type || "single";

    let optionList = [];
    let selectedOption = customField.selectedOption || {};
    let selectedOptions = customField.selectedOptions || [];

    customField.options.forEach((opElem) => {
      if (
        !selectedOption.id &&
        opElem.selected &&
        customField.type === "single"
      ) {
        selectedOption = opElem;
      }
      if (
        selectedOptions.length === 0 &&
        opElem.selected &&
        customField.type === "multiple"
      ) {
        selectedOptions.push(opElem);
      }
      if (opElem.customerSelect || this.state.from === "admin") {
        optionList.push({ name: opElem.label, value: opElem.id });
      }
    });
    selectedOptions = selectedOptions.map((i) => i.id);

    return (
      <div>
        <div className="modalAlertOverlay changeCustomFieldModalOverlay" />
        <div className="modalAlert modalTaskAlert changeCustomFieldModal big">
          <div className="clearfix">
            <div className="pull-left">
              <p style={{ fontSize: 18 }}>Update</p>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                {type === "single" && (
                  <>
                    <label className="firstLetter"> {customField.label} </label>
                    <div className="tabsHolder radioTabs">
                      <RadioTag
                        onCheckChange={this.onSelectCustomOption.bind(this)}
                        id={customField.id}
                        labelList={optionList}
                        selectType={"value"}
                        key={this.state.keyCustomField}
                        selectedList={[selectedOption && selectedOption.id]}
                      />
                    </div>
                  </>
                )}
                {type === "dropdown" && (
                  <div
                    className={
                      selectedOption.value !== "" &&
                      selectedOption.value !== undefined
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>{customField.label}</label>
                    <select
                      value={selectedOption.value}
                      onChange={(event) =>
                        this.handleDropdownChange(
                          event,
                          customField.id,
                          customField.label
                        )
                      }>
                      <option value="" />
                      {optionList.map((i) => (
                        <option value={i.value} key={i.value}>
                          {i.name}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
                {type === "text" && (
                  <div
                    className={
                      selectedOption.value !== "" &&
                      selectedOption.value !== undefined
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>{customField.label}</label>
                    <textarea
                      value={selectedOption.value}
                      onChange={(event) =>
                        this.handleTextChange(
                          event,
                          customField.id,
                          customField.label
                        )
                      }
                    />
                  </div>
                )}
                {type === "multiple" && (
                  <>
                    <label className="firstLetter"> {customField.label} </label>
                    <div className="tabsHolder radioTabs">
                      <RadioTag
                        onCheckChange={this.onSelectCustomOptions.bind(this)}
                        id={customField.id}
                        labelList={optionList}
                        selectType={"value"}
                        type={"multiple"}
                        key={this.state.keyCustomField}
                        selectedList={selectedOptions}
                      />
                    </div>
                  </>
                )}
                {type === "editor" && (
                  <>
                    <label className="firstLetter"> {customField.label} </label>
                    <div className="add-task-comment">
                      <DraftEditor
                        cType="task"
                        defaultText=""
                        textAlign={"left"}
                        lineHeight={"inherit"}
                        html={selectedOption.value}
                        json={selectedOption.valueJson}
                        onChange={(html, json) =>
                          this.handleEditorChange(
                            html,
                            json,
                            customField.id,
                            customField.label
                          )
                        }
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="gap10" />
          </div>
          <div className="gap20" />
          <div className="alertFooterBtns">
            <button
              onClick={this.onClickCancelFromCustomFieldModal.bind(this)}
              className="btn btn-default dismissThisModal margin-right-10">
              Cancel
            </button>
            <button
              onClick={this.onClickSubmitFromCustomFieldModel.bind(this)}
              className="btn btn-primary dismissThisModal">
              Save
            </button>
          </div>
        </div>
      </div>
    );
  }

  onClickEditTaskField(field) {
    let taskInfo = this.state.taskInfo;

    let memberInfo = taskInfo.member_info;
    let selectedStaffUser = { label: "", value: "" };
    if (memberInfo) {
      selectedStaffUser = {
        label: taskInfo.member_info.name,
        value: taskInfo.member_info,
      };
    }

    this.setState(
      {
        selectedField: field,
        keyDraftEditor: Math.random(),
        task: taskInfo.task,
        description: taskInfo.description,
        descriptionJson: taskInfo.description_json,
        priority: taskInfo.priority,
        keyRadioSelect: Math.random(),
        selectedStaffUser: selectedStaffUser,
        keySelectUser: Math.random(),
        dueDate: taskInfo.due_date ? new Date(taskInfo.due_date) : "",
        dueDateOption: !taskInfo.due_date
          ? taskInfo.due_date_option
            ? taskInfo.due_date_option
            : "no"
          : "yes",
        keyDueDate: Math.random(),
        notiTime: taskInfo.noti_time || 0,
        notiType: taskInfo.noti_type || "minutes",
      },
      () => {
        $(
          ".modalAlertOverlay.changeFieldModalOverlay,.modalAlert.changeFieldModal"
        ).fadeIn(200);
      }
    );
  }

  onSelectStaffUser = (user) => {
    if (user) {
      this.setState({
        selectedStaffUser: user,
      });
    }
  };

  onClickUnassignedUser = () => {
    this.setState({ selectedStaffUser: { label: "", value: {} } });
  };

  onChangeTaskDate(value) {
    this.setState({
      dueDate: value,
    });
  }

  onChangeDescription = (html, json) => {
    this.setState({ description: html, descriptionJson: json });
  };

  onClickCancelFromFieldModal() {
    $(
      ".modalAlertOverlay.changeFieldModalOverlay,.modalAlert.changeFieldModal"
    ).fadeOut(200);
  }

  async onClickSubmitFromFieldModel() {
    $(
      ".modalAlertOverlay.changeFieldModalOverlay,.modalAlert.changeFieldModal"
    ).fadeOut(200);

    let updateData = {};

    let selectedField = this.state.selectedField;
    if (selectedField === "task") {
      updateData["task"] = this.state.task;
    }
    if (selectedField === "priority") {
      updateData["priority"] = this.state.priority;
    }
    if (selectedField === "description") {
      updateData["description"] = this.state.description;
      updateData["description_json"] = this.state.descriptionJson;
    }
    if (selectedField === "assigned") {
      let assignedTo = "ffffffffffffffffffffffff";

      if (this.state.selectedStaffUser.label.length > 0) {
        assignedTo = this.state.selectedStaffUser.value._id;
      }

      updateData["assigned_to"] = assignedTo;
    }
    if (selectedField === "dueDate") {
      let dueDate = this.state.dueDate;
      if (this.state.dueDateOption === "no") {
        dueDate = "";
      }
      /*eslint-disable*/
      updateData = {
        due_date: dueDate,
        due_date_option: this.state.dueDateOption,
        noti_time: this.state.notiTime,
        noti_type: this.state.notiType,
      };
      /*eslint-enable*/
    }

    await callUpdateTask(this.state.taskInfo._id, updateData);
    showNotification("success", "Saved successfully", 4000);

    if (this.state.taskInfo.assigned_to !== updateData["assigned_to"]) {
      await this.executeTaskRules("assignedToUser", {
        userId: updateData["assigned_to"],
      });
    }

    if (updateData["assigned_to"] === "ffffffffffffffffffffffff") {
      await this.executeTaskRules("unassignedUser");
    }

    let ruleFields = [];
    let task = this.state.taskInfo;
    if (task.task !== this.state.task) {
      ruleFields.push("task");
    }
    if (task.description !== this.state.description) {
      ruleFields.push("description");
    }
    if (task.priority !== this.state.priority) {
      ruleFields.push("priority");
    }
    if (task.due_date !== this.state.dueDate) {
      ruleFields.push("due_date");
    }
    if (task.assigned_to !== updateData["assigned_to"]) {
      ruleFields.push("assigned_to");
    }

    if (ruleFields.length > 0) {
      await this.executeTaskRules("field", {
        taskId: this.state.taskInfo._id,
        fields: ruleFields,
      });
    }

    this.getTaskInfo();
  }

  renderFieldChangeModal() {
    let taskInfo = this.state.taskInfo;
    let selectedField = this.state.selectedField;
    if (!taskInfo._id && !selectedField) {
      return;
    }

    return (
      <div>
        <div className="modalAlertOverlay changeFieldModalOverlay" />
        <div className="modalAlert modalTaskAlert changeFieldModal big">
          <div className="clearfix">
            <div className="pull-left">
              <p style={{ fontSize: 18 }}>Update</p>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                {selectedField === "task" && (
                  <>
                    <div
                      className={
                        this.state.task !== ""
                          ? "mdInput mdFocussed"
                          : "mdInput"
                      }>
                      <label>Task</label>
                      <input
                        type="text"
                        name="task"
                        value={this.state.task}
                        onChange={(event) => this.handleInputChange(event)}
                      />
                    </div>
                  </>
                )}
                {selectedField === "description" && (
                  <>
                    <div className="havingHelpText">
                      <label>Description</label>
                    </div>
                    <div className="add-task-comment">
                      <DraftEditor
                        key={this.state.keyDraftEditor}
                        cType="task"
                        defaultText="Your Description"
                        textAlign={"left"}
                        lineHeight={"inherit"}
                        html={this.state.description}
                        json={this.state.descriptionJson}
                        onChange={this.onChangeDescription.bind(this)}
                      />
                    </div>
                  </>
                )}
                {selectedField === "priority" && (
                  <div>
                    <label>Priority</label>
                    <div className="tabsHolder radioTabs">
                      <RadioTag
                        onCheckChange={this.onSelectRadioSelect.bind(this)}
                        labelList={[
                          { name: "Low", value: "low" },
                          { name: "Medium", value: "medium" },
                          { name: "High", value: "high" },
                        ]}
                        key={this.state.keyRadioSelect}
                        id={"priority"}
                        selectType={"value"}
                        selectedList={[this.state.priority]}
                      />
                    </div>
                    <div className="gap20" />
                  </div>
                )}
                {selectedField === "assigned" && (
                  <>
                    <SearchableUserSelect
                      key={this.state.keySelectUser}
                      name={"assignedTo"}
                      placeholder="Select A User"
                      onSelect={this.onSelectStaffUser}
                      selected={
                        this.state.selectedStaffUser
                      }></SearchableUserSelect>
                    <div className="gap20" />
                    {this.state.selectedStaffUser.label && (
                      <button
                        onClick={this.onClickUnassignedUser.bind(this)}
                        className="btn btn-default btn-sm dismissThisModal">
                        Unassign User
                      </button>
                    )}
                  </>
                )}
                {selectedField === "dueDate" && (
                  <div>
                    <label>Select Due Date</label>
                    <div className="tabsHolder radioTabs">
                      <RadioTag
                        onCheckChange={this.onSelectRadioSelect.bind(this)}
                        labelList={[
                          { name: "No Due Date", value: "no" },
                          { name: "Assign Due Date", value: "yes" },
                        ]}
                        key={this.state.keyRadioSelect}
                        id={"dueDateOption"}
                        selectType={"value"}
                        selectedList={[this.state.dueDateOption]}
                      />
                    </div>
                    {this.state.dueDateOption === "yes" && (
                      <div>
                        <div className="gap10" />
                        <DateTimePicker
                          key={this.state.keyDueDate}
                          value={this.state.dueDate}
                          onChange={this.onChangeTaskDate.bind(this)}
                        />
                        <div className="gap20" />
                        <div className="row">
                          <div className="col-sm-6">
                            <div
                              className={
                                this.state.notiTime !== ""
                                  ? "mdInput mdFocussed"
                                  : "mdInput"
                              }>
                              <label>Send Reminder to watchers before</label>
                              <input
                                type="text"
                                name="notiTime"
                                value={this.state.notiTime}
                                onChange={(event) =>
                                  this.handleInputChange(event)
                                }
                              />
                            </div>
                            <div className="gap10" />
                          </div>
                          <div className="col-sm-6">
                            <div>
                              <div className="gap30" />
                              <div className="gap10" />
                              <div className="tabsHolder radioTabs">
                                <RadioTag
                                  onCheckChange={this.onSelectRadioSelect.bind(
                                    this
                                  )}
                                  labelList={[
                                    { name: "Minutes", value: "minutes" },
                                    { name: "Hours", value: "hours" },
                                    { name: "Days", value: "days" },
                                  ]}
                                  key={this.state.keyNotiType}
                                  id={"notiType"}
                                  selectType={"value"}
                                  selectedList={[this.state.notiType]}
                                />
                              </div>
                              <div className="gap20" />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="gap10" />
          </div>
          <div className="gap20" />
          <div className="alertFooterBtns">
            <button
              onClick={this.onClickCancelFromFieldModal.bind(this)}
              className="btn btn-default dismissThisModal margin-right-10">
              Cancel
            </button>
            <button
              onClick={this.onClickSubmitFromFieldModel.bind(this)}
              className="btn btn-primary dismissThisModal">
              Save
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderStatusChangeModal() {
    let taskInfo = this.state.taskInfo;

    if (!taskInfo._id) {
      return;
    }

    return (
      <div>
        <div className="modalAlertOverlay changeStatusModalOverlay" />
        <div className="modalAlert modalTaskAlert changeStatusModal big">
          <div className="clearfix">
            <div className="pull-left">
              <p style={{ fontSize: 18 }}>Change Status</p>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div>
                  <label>Move to: </label>
                  <div className="tabsHolder radioTabs">
                    <RadioTag
                      onCheckChange={this.onSelectRadioSelect.bind(this)}
                      labelList={this.state.statusList}
                      key={this.state.keyRadioSelect}
                      id={"taskStatus"}
                      selectType={"value"}
                      selectedList={[this.state.taskStatus]}
                    />
                  </div>
                  <div className="gap20" />
                  <div className="add-task-comment">
                    <DraftEditor
                      cType="task"
                      defaultText="Your Comment"
                      textAlign={"left"}
                      lineHeight={"inherit"}
                      html={this.state.comment}
                      json={this.state.commentJson}
                      onChange={this.onChangeCommentValue.bind(this)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="gap10" />
          </div>
          <div className="gap20" />
          <div className="alertFooterBtns">
            <button
              onClick={this.onClickCancelFromStatusModal.bind(this)}
              className="btn btn-default dismissThisModal margin-right-10">
              Cancel
            </button>
            <button
              onClick={this.onClickSubmitFromStatusModel.bind(this)}
              className="btn btn-primary dismissThisModal">
              Submit
            </button>
          </div>
        </div>
      </div>
    );
  }

  toggleInternalNote = (check) => {
    this.setState({ noteInternal: check });
  };

  onClickCancelFromAddInternalNoteModal() {
    $(
      ".modalAlertOverlay.addInternalNotesModalOverlay,.modalAlert.addInternalNotesModal"
    ).fadeOut(200);
  }

  async onClickSubmitFromAddInternalNoteModel() {
    $(
      ".modalAlertOverlay.addInternalNotesModalOverlay,.modalAlert.addInternalNotesModal"
    ).fadeOut(200);

    let taskInfo = this.state.taskInfo;
    let data = {};

    if (this.state.note.trim().length > 0) {
      let noteInfo = {
        userId: this.state.userData._id,
        note: this.state.note,
        noteJson: this.state.noteJson,
        date: new Date(),
        id: getUniqueId(),
        internal: this.state.noteInternal,
      };
      data["$push"] = { notes: noteInfo };
    }

    let d = await callUpdateTask(taskInfo._id, data);

    if (d._id) {
      showNotification("success", "Note added", 4000);
      this.getTaskInfo();
      PubSub.publish("refreshOpenTasks", "");
    }
  }

  renderAddInternalNotesModal() {
    let taskInfo = this.state.taskInfo;

    if (!taskInfo._id) {
      return;
    }

    return (
      <div>
        <div className="modalAlertOverlay addInternalNotesModalOverlay" />
        <div className="modalAlert modalTaskAlert addInternalNotesModal big">
          <div className="clearfix">
            <div className="pull-left">
              <p style={{ fontSize: 18 }}>Add Internal Note</p>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div>
                  <div className="add-task-comment">
                    <DraftEditor
                      cType="task"
                      defaultText="Your Note"
                      textAlign={"left"}
                      lineHeight={"inherit"}
                      html={this.state.note}
                      json={this.state.noteJson}
                      onChange={this.onChangeNoteValue.bind(this)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="gap10" />
            <Checkbox
              label={"Keep Note Private (Visible Only to Staff Members)"}
              isChecked={this.state.noteInternal}
              handleCheckboxChange={this.toggleInternalNote}
              key={this.state.keyNoteInternal}
            />
          </div>
          <div className="gap20" />
          <div className="alertFooterBtns">
            <button
              onClick={this.onClickCancelFromAddInternalNoteModal.bind(this)}
              className="btn btn-default dismissThisModal margin-right-10">
              Cancel
            </button>
            <button
              onClick={this.onClickSubmitFromAddInternalNoteModel.bind(this)}
              className="btn btn-primary dismissThisModal">
              Submit
            </button>
          </div>
        </div>
      </div>
    );
  }

  onClickCancelFromPdfTemplateModal() {
    $(
      ".modalAlertOverlay.pdfTemplateModalOverlay,.modalAlert.pdfTemplateAlert"
    ).fadeOut(200);
  }

  async onClickSubmitFromPdfTemplateModel() {
    if (!this.state.selectedPdfTemplate) {
      showNotification("error", "Select pdf template", 4000);
      return;
    }

    let pdfTemplates = this.state.pdfTemplates;
    let pdfTemplate = pdfTemplates.find(
      (d) => d._id === this.state.selectedPdfTemplate
    );
    $(
      ".modalAlertOverlay.pdfTemplateModalOverlay,.modalAlert.pdfTemplateAlert"
    ).fadeOut(200);
    /*eslint-disable*/
    let data = {
      status: "pending",
      type: "task",
      task_id: this.state.taskId,
      task_pages: pdfTemplate.pages,
      pdf_template_id: pdfTemplate._id,
      member_id: getItem("userId"),
    };
    /*eslint-enable*/
    await callCreatePdfGeneration(data);
    showNotification(
      "success",
      "Export process done, check exported pdf section for status",
      2000
    );
    this.setState({ showPdfs: true });
    this.getPdfGenerations();
  }

  renderPdfTemplateModal() {
    return (
      <div>
        <div className="modalAlertOverlay pdfTemplateModalOverlay" />
        <div className="modalAlert pdfTemplateAlert">
          <div className="clearfix">
            <div className="pull-left">
              <p style={{ fontSize: 18 }}>Export PDF</p>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <div
                  className={
                    this.state.selectedPdfTemplate !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Select PDF Template</label>
                  <select
                    name="selectedPdfTemplate"
                    value={this.state.selectedPdfTemplate}
                    onChange={(event) => this.handleInputChange(event)}>
                    <option value="" />
                    {this.state.pdfTemplates.map((i) => (
                      <option value={i._id} key={i._id}>
                        {i.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="gap20" />
              </div>
            </div>
            <div className="gap10" />
          </div>
          <div className="gap20" />
          <div className="alertFooterBtns">
            <button
              onClick={this.onClickCancelFromPdfTemplateModal.bind(this)}
              className="btn btn-default dismissThisModal margin-right-10">
              Cancel
            </button>
            <button
              onClick={this.onClickSubmitFromPdfTemplateModel.bind(this)}
              className="btn btn-primary dismissThisModal">
              Submit
            </button>
          </div>
        </div>
      </div>
    );
  }

  onClickCancelFromFormModal() {
    $(".modalAlertOverlay.formModalOverlay,.modalAlert.formAlert").fadeOut(200);
  }

  async onClickSubmitFromFormModel() {
    if (!this.state.selectedForm) {
      showNotification("error", "Select form", 4000);
      return;
    }

    $(".modalAlertOverlay.formModalOverlay,.modalAlert.formAlert").fadeOut(200);

    let form = this.state.taskInfo.form || {};
    form["form_id"] = this.state.selectedForm;
    form["status"] = "pending";

    await callUpdateTask(this.state.taskInfo._id, { form: form });
    showNotification("success", "Form attached", 2000);
    this.getTaskInfo();
  }

  renderSelectFormModal() {
    return (
      <div>
        <div className="modalAlertOverlay formModalOverlay" />
        <div className="modalAlert formAlert">
          <div className="clearfix">
            <div className="pull-left">
              <p style={{ fontSize: 18 }}>Attach Form</p>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <div
                  className={
                    this.state.selectedForm !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Select Form</label>
                  <select
                    name="selectedForm"
                    value={this.state.selectedForm}
                    onChange={(event) => this.handleInputChange(event)}>
                    <option value="" />
                    {this.state.forms.map((i) => (
                      <option value={i._id} key={i._id}>
                        {i.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="gap20" />
              </div>
            </div>
            <div className="gap10" />
          </div>
          <div className="gap20" />
          <div className="alertFooterBtns">
            <button
              onClick={this.onClickCancelFromFormModal.bind(this)}
              className="btn btn-default dismissThisModal margin-right-10">
              Cancel
            </button>
            <button
              onClick={this.onClickSubmitFromFormModel.bind(this)}
              className="btn btn-primary dismissThisModal">
              Submit
            </button>
          </div>
        </div>
      </div>
    );
  }

  onClickForm(formStatus) {
    if (this.state.from === "admin") {
      if (formStatus === "pending") {
        $(".modalAlertOverlay.formModalOverlay,.modalAlert.formAlert").fadeIn(
          200
        );
      } else {
        showNotification(
          "info",
          "Form submitted already so can't change it",
          4000
        );
      }
    }
  }

  onClickTicketNoAlert = () => {
    $(".modalAlertOverlay.lockModal,.modalAlert.lockAlert").fadeOut(200);
  };

  onClickTicketYesAlert = async () => {
    $(".modalAlertOverlay.lockModal,.modalAlert.lockAlert").fadeOut(200);
    let userId = getItem("userId");

    let historyObject = {
      date: new Date(),
      action: "unlock",
      userId: userId,
    };
    /*eslint-disable*/
    await callUpdateTask(this.state.taskInfo._id, {
      lock_by: "ffffffffffffffffffffffff",
      $push: {
        history: historyObject,
      },
    });
    /*eslint-enable*/
    showNotification("success", `${this.state.taskName} unlocked`);
    window.location.reload();
  };

  renderUnlockConfirmationAlert() {
    return (
      <div>
        <div className="modalAlertOverlay lockModal" />
        <div className="modalAlert lockAlert">
          <p>Are you sure you want to unlock this {this.state.taskName} ?</p>
          <div className="alertFooterBtns">
            <button
              className="btn btn-default closeModal-No margin-right-10"
              onClick={this.onClickTicketNoAlert}>
              No
            </button>
            <button
              className="btn btn-primary closeModal-Yes"
              onClick={this.onClickTicketYesAlert}>
              Yes
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderNotes() {
    let taskInfo = this.state.taskInfo;
    let notes = taskInfo.notes || [];
    let notesInfo = taskInfo.notes_info || [];

    let returnVals = [];

    notes.forEach((i) => {
      let userInfo = notesInfo.find((d) => d._id === i.userId);
      let userName = "";
      if (userInfo) {
        userName = userInfo.name;
      } else {
        userName = "System";
      }
      returnVals.push(
        <>
          <div className="gap10" />
          <div
            className={`alert ${i.internal ? "alert-info" : "alert-warning"}`}>
            <div
              dangerouslySetInnerHTML={{
                __html: sanitizer(linkify(i.note)),
              }}
            />
            <div className="gap10" />
            <label style={{ fontSize: 12 }}>
              {moment(i.date).format("MM/DD/YYYY")}
            </label>
            <br />
            <label style={{ fontSize: 12 }}>{userName}</label>
          </div>
        </>
      );
    });

    return returnVals;
  }

  renderDuplicateTasks() {
    if (this.state.duplicateTasks.length > 0 && this.state.from === "admin") {
      return (
        <>
          <div className="gap20" />
          <label>Possible Duplicates</label>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th className="table-3px">{this.state.taskName}</th>
                <th className="table-3px">Field</th>
              </tr>
            </thead>
            <tbody>
              {this.state.duplicateTasks.map((i) => (
                <tr key={i.id}>
                  <td
                    className="link mpCartChange pointer-click table-3px"
                    onClick={() => {
                      let url = `/admin/task-detail?tid=${i.id}`;
                      window.open(url, "_blank");
                    }}>
                    {i.task}
                  </td>
                  <td className="table-3px">{i.field.join(", ")}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      );
    }
  }

  renderTaskFormSubmittedInfo() {
    let taskInfo = this.state.taskInfo;
    let formSubmissionInfo = this.state.formSubmissionInfo;
    let form = taskInfo.form;
    let submittedBy = "";
    let submittedDate = new Date();
    let returnVals = [];
    if (form && form.form_id) {
      if (formSubmissionInfo) {
        if (formSubmissionInfo.member_info) {
          submittedBy = formSubmissionInfo.member_info.name;
        }
        if (formSubmissionInfo.createdAt) {
          submittedDate = formSubmissionInfo.createdAt;
        }
        if (submittedBy && submittedDate) {
          returnVals.push(
            <p>
              &nbsp; &#8226; Form Submitted By {submittedBy} @{" "}
              {moment(submittedDate).format("hh:mm A MMMM Do YYYY")}
            </p>
          );
        } else {
          returnVals.push(<p>Form Submitted</p>);
        }
        if (formSubmissionInfo.form_history) {
          formSubmissionInfo.form_history.forEach((element) => {
            let modifiedBy = "";
            let userInfo = formSubmissionInfo.form_history_member_info.find(
              (d) => d._id === element.member_id
            );

            if (userInfo) {
              modifiedBy = userInfo.name;
            }

            returnVals.push(
              <p>
                &nbsp; &#8226; Form Modified By {modifiedBy} @{" "}
                {moment(element.date).format("hh:mm A MMMM Do YYYY")}
              </p>
            );
          });
        }
      }
    }
    return returnVals;
  }

  render() {
    let taskInfo = this.state.taskInfo;
    let form = taskInfo.form;
    let formId = "";
    let formStatus = "pending";
    if (form && form.form_id) {
      formId = form.form_id;
      formStatus = form.status;
    }
    let locked = false;
    let userId = getItem("userId");
    let lockUserName = "";

    if (taskInfo.lock_by) {
      if (
        taskInfo.lock_by !== userId &&
        taskInfo.lock_by !== "ffffffffffffffffffffffff"
      ) {
        locked = true;
      }
      lockUserName = taskInfo.lock_by_info ? taskInfo.lock_by_info.name : "";
    }

    if (locked) {
      return (
        <div className="container-fluid">
          {this.renderUnlockConfirmationAlert()}
          <div className="row">
            <div className="col-md-12">
              <div className="alert alert-info">
                {lockUserName} is currently working on this{" "}
                {this.state.taskName}. <br />
                <br />
                <button
                  className="btn btn-default btn-sm margin-right-10"
                  onClick={this.onClickUnlockTicket.bind(this)}>
                  Unlock {this.state.taskName}
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="container-fluid">
        {this.renderFieldChangeModal()}
        {this.renderCustomFieldChangeModal()}
        {this.renderPdfTemplateModal()}
        {this.renderSelectFormModal()}
        {this.renderUnlockConfirmationAlert()}
        <TitleBox
          title={`${this.state.taskName} Detail`}
          showBackBtn={true}
          key={this.state.keyTaskName}
        />
        <div className="gap20" />
        {!this.state.loading &&
        taskInfo._id &&
        this.state.staffUsers.length > 0 ? (
          <>
            <TaskDelete
              key={this.state.keyDeleteTask}
              taskId={this.state.taskId}
              onDelete={this.onDeleteTask}
            />
            <TaskEdit
              ref={(ref) => {
                this.editTaskRef = ref;
              }}
              staffUsers={this.state.staffUsers}
              userData={this.state.userData}
              getTasks={() => this.getTaskInfo()}
            />
            {this.renderStatusChangeModal()}
            {this.renderAddInternalNotesModal()}
            <div className="card">
              <div className="container-fluid">
                <div className="gap10" />
                <div className="clearfix">
                  <div className="pull-left">
                    {this.state.from === "admin" && (
                      <button
                        className="btn btn-info btn-sm"
                        onClick={this.onClickEditTask.bind(this)}>
                        <i className="fa fa-cog" /> &nbsp; Edit{" "}
                        {this.state.taskName}
                      </button>
                    )}
                  </div>
                  <div className="pull-right">
                    {this.state.from === "admin" && (
                      <button
                        className="btn btn-default margin-right-10"
                        onClick={this.onClickAddInternalNotes.bind(this)}>
                        Add Internal Note
                      </button>
                    )}
                    {this.state.from === "admin" &&
                      (!taskInfo.lock_by ||
                        taskInfo.lock_by === "ffffffffffffffffffffffff") && (
                        <button
                          className="btn btn-default margin-right-10"
                          onClick={this.onClickLockTicket.bind(this)}>
                          Lock {this.state.taskName} to Myself
                        </button>
                      )}
                    {taskInfo.lock_by &&
                      taskInfo.lock_by !== "ffffffffffffffffffffffff" && (
                        <button
                          className="btn btn-default  margin-right-10"
                          onClick={this.onClickUnlockTicket.bind(this)}>
                          Unlock {this.state.taskName}
                        </button>
                      )}
                    {this.state.pdfTemplates.length > 0 && (
                      <button
                        className="btn btn-default margin-right-10"
                        onClick={this.onClickExportTask.bind(this)}>
                        Export
                      </button>
                    )}
                    {this.state.from === "admin" && (
                      <button
                        className="btn btn-danger margin-right-10"
                        onClick={this.onClickDeleteTask.bind(this)}>
                        <i className="fa fa-trash" />
                      </button>
                    )}
                    {this.state.from === "admin" &&
                      this.state.hideTableFields.indexOf("status") === -1 && (
                        <button
                          className="btn btn-primary"
                          onClick={this.onClickStatus.bind(this)}>
                          Change Status
                        </button>
                      )}
                  </div>
                </div>
                {this.state.taskSocketUsers.length > 0 &&
                  this.state.from === "admin" && (
                    <>
                      <div className="gap20" />
                      <div className="alert alert-info">
                        {this.state.taskSocketUsers.map((i) => (
                          <>
                            <label>
                              {i.userName} currently working on this{" "}
                              {this.state.taskName}.
                            </label>
                            <br />
                          </>
                        ))}
                      </div>
                    </>
                  )}
                {this.renderNotes()}
                {this.renderDuplicateTasks()}
                <TaskBasicInfo
                  hideTableFields={this.state.hideTableFields}
                  from={this.state.from}
                  taskInfo={taskInfo}
                  taskName={this.state.taskName}
                  formInfo={this.state.formInfo}
                  key={this.state.keyCommentView}
                  onClickStatus={this.onClickStatus}
                  fieldCategories={this.state.fieldCategories}
                  customFields={this.state.customFields}
                  onClickEditTaskField={this.onClickEditTaskField.bind(this)}
                  onClickEditCustomField={this.onClickEditCustomField.bind(
                    this
                  )}
                  onClickForm={() => this.onClickForm(formStatus)}
                />
              </div>
            </div>

            <div className="card">
              <div className="container-fluid">
                <TaskImageList
                  images={taskInfo.images}
                  onClickSubmitImage={this.onClickSubmitImage}
                />
              </div>
            </div>

            <TaskCommentList
              currentUserId={this.state.userData._id}
              users={this.state.staffUsers}
              comments={taskInfo.comments}
              key={this.state.keyCommentView}
              onDeleteComment={this.onDeleteComment}
              onClickUpdateComment={this.onClickUpdateComment}
            />

            <div className="card">
              <div className="container-fluid">
                <AddTaskComment
                  type="add"
                  users={this.state.staffUsers}
                  onClickSubmitComment={this.onClickSubmitComment}
                />
              </div>
            </div>

            {formId && (
              <div className="card">
                <div className="container-fluid">
                  <div className="gap20" />
                  {this.renderTaskFormSubmittedInfo()}
                  {formStatus !== "completed" && (
                    <PrePageForm
                      key={formStatus}
                      userData={this.state.userData}
                      from={"task"}
                      formId={formId}
                      taskId={this.state.taskId}
                      showContainer={"no"}
                      formSubmissionId={
                        this.state.formSubmissionInfo
                          ? this.state.formSubmissionInfo._id
                          : ""
                      }
                      onClickSubmit={this.onClickFormSubmit}
                    />
                  )}
                  {formStatus === "completed" && (
                    <div className="list-group list-group-flush">
                      <div
                        className="list-group-item pointer-click"
                        onClick={this.onClickShowForm.bind(this)}>
                        <h5 className="noMargin pull-left firstLetter">Form</h5>

                        <div className="pull-right">
                          <i
                            className={
                              this.state.showForm
                                ? "fa fa-chevron-up"
                                : "fa fa-chevron-down"
                            }
                            aria-hidden="true"
                          />
                        </div>
                      </div>
                      <div className="gap20" />
                      <VelocityTransitionGroup
                        enter={{ animation: "slideDown" }}
                        leave={{ animation: "slideUp" }}>
                        {this.state.showForm && (
                          <PrePageForm
                            key={formStatus}
                            userData={this.state.userData}
                            from={"task"}
                            formId={formId}
                            taskId={this.state.taskId}
                            showContainer={"no"}
                            formSubmissionId={
                              this.state.formSubmissionInfo
                                ? this.state.formSubmissionInfo._id
                                : ""
                            }
                            onClickSubmit={this.onClickFormSubmit}
                          />
                        )}
                      </VelocityTransitionGroup>
                    </div>
                  )}
                </div>
              </div>
            )}

            {this.state.showDbHistory && this.state.from === "admin" && (
              <div className="card">
                <div className="list-group list-group-flush">
                  <div
                    className="list-group-item pointer-click"
                    onClick={this.onClickShowHistory.bind(this)}>
                    <h5 className="noMargin pull-left firstLetter">History</h5>

                    <div className="pull-right">
                      <i
                        className={
                          this.state.showHistory
                            ? "fa fa-chevron-up"
                            : "fa fa-chevron-down"
                        }
                        aria-hidden="true"
                      />
                    </div>
                  </div>
                  <VelocityTransitionGroup
                    enter={{ animation: "slideDown" }}
                    leave={{ animation: "slideUp" }}>
                    {this.state.showHistory === true && (
                      <div className="list-group-item">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th>Action</th>
                              <th>User</th>
                              <th>Date</th>
                              <th>Notes</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.history.map((i) => (
                              <tr key={i.date}>
                                <td className="firstLetter">{i.action}</td>
                                <td>{this.getUserName(i.userId)}</td>
                                <td>
                                  {moment(i.date).format("Do MMM YYYY hh:mm A")}
                                </td>
                                <td>{i.notes || ""}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </VelocityTransitionGroup>
                </div>
              </div>
            )}

            <div className="card">
              <div className="list-group list-group-flush">
                <div className="list-group-item pointer-click">
                  <h5
                    className="noMargin pull-left firstLetter"
                    onClick={this.onClickShowPdfs.bind(this)}>
                    Exported PDFs ({this.state.pdfGenerations.length})
                  </h5>

                  <div className="pull-right">
                    <i
                      style={{ paddingRight: 20 }}
                      className={"fa fa-refresh"}
                      aria-hidden="true"
                      onClick={(event) => {
                        event.preventDefault();
                        this.getPdfGenerations();
                      }}
                    />
                    <i
                      onClick={this.onClickShowPdfs.bind(this)}
                      className={
                        this.state.showPdfs
                          ? "fa fa-chevron-up"
                          : "fa fa-chevron-down"
                      }
                      aria-hidden="true"
                    />
                  </div>
                </div>
                <VelocityTransitionGroup
                  enter={{ animation: "slideDown" }}
                  leave={{ animation: "slideUp" }}>
                  {this.state.showPdfs === true && (
                    <div className="list-group-item">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>Created Date</th>
                            <th>Generated Date</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.pdfGenerations.map((i) => (
                            <tr key={i._id}>
                              <td>
                                {moment(i.createdAt).format(
                                  "Do MMM YYYY hh:mm A"
                                )}
                              </td>
                              <td>
                                {i.generated_date
                                  ? moment(i.generated_date).format(
                                      "Do MMM YYYY hh:mm A"
                                    )
                                  : "-"}
                              </td>
                              <td className="firstLetter">{i.status}</td>
                              <td>
                                {i.download_url && (
                                  <button
                                    className="btn btn-default btn-sm border-0"
                                    onClick={this.onClickDownloadPDF.bind(
                                      this,
                                      i
                                    )}>
                                    <i className="fa fa-download" />
                                  </button>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </VelocityTransitionGroup>
              </div>
            </div>
          </>
        ) : (
          <div id="loader"></div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.authReducer.userData,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TaskDetailPage);
