import React from "react";
import $ from "jquery";
import Scrollbars from "react-custom-scrollbars";
import moment from "moment-timezone";
import PubSub from "pubsub-js";

import RadioTag from "../../../../components/form/radioTag";
// import TypeableSelect from "../../../../components/form/typeableSelect";
import {
  callGetSystemTagUsingName,
  callAddMemberTagL,
  callCreateTasks,
  callUpdateTask,
  callGetGenericTags,
  callCreateGenericTag,
  callUpdateGenericTag,
  uploadImages,
  callExecuteTaskRules,
} from "../../../../services";
import config from "../../../../helpers/config";
import { callGetSettings } from "../../../../services/settingService";
import {
  getLoggedInUser,
  getUniqueId,
  imageUrl,
  showNotification,
} from "../../../../helpers";
import { DraftEditor } from "../../../../packages/draft-editor/index";
import { getItem } from "../../../../helpers/storage";
import DateTimePicker from "react-datetime-picker";
import { VelocityTransitionGroup } from "velocity-react";
import { saveAs } from "file-saver";
import SearchableUserSelect from "../../../../components/form/searchableUserSelect";

class TaskEdit extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      userData: this.props.userData,
      watcherList: [],
      selectedTask: {},
      staffUsers: [],
      action: this.props.action,
      task: "",
      description: "",
      descriptionJson: {},
      priority: "low",
      status: "open",
      assignedTo: "",
      createdBy: "",
      selectedStaffUser: { value: "", label: "" },
      dueDate: new Date(),
      notiTime: 0,
      notiType: "minutes",
      keyNotiType: Math.random(),
      keyRadioSelect: Math.random(),
      keyDueDate: Math.random(),
      keySelectUser: Math.random(),
      statusHistory: [],
      watchers: [],
      defaultWatchers: [],
      customFields: [],
      keyCustomField: Math.random(),
      keyWatcherList: Math.random(),
      taskCreatedTagId: "",
      dueDateOption: "no",
      keyDraftEditor: Math.random(),
      oldDueDate: new Date(),
      category: "",
      keyCategory: Math.random(),
      categories: [],
      showCreateCategory: false,
      categoryName: "",
      files: [],
      fileUrls: [],
      errorMessage: "",
      fieldCategories: [],
      statusList: [],
      hideEditMenuFields: [],
    };
  }

  componentDidMount() {
    this.getData();
  }

  async getData() {
    this.prepareWatcherList();
    await this.callGetTagByName();
    await this.getSettings();
    await this.getCategories();
    await this.getFieldCategories();
  }

  getCategories() {
    callGetGenericTags("task").then((d) => {
      this.setState({ categories: d.data, keyCategory: Math.random() });
      if (d.data.length === 0) {
        this.setState({
          category: "Create New Category",
          keyCategory: Math.random(),
          showCreateCategory: true,
        });
      }
    });
  }

  getFieldCategories() {
    callGetGenericTags("taskfields").then((d) => {
      this.setState({ fieldCategories: d.data });
    });
  }

  prepareWatcherList() {
    let staffUsers = [];
    let watcherList = [];
    let rawStaffUsers = this.props.staffUsers;
    rawStaffUsers.forEach((element) => {
      staffUsers.push({ value: element, label: element.name });
      watcherList.push({ name: element.name, value: element._id });
    });
    this.setState({
      staffUsers,
      watcherList,
      keyWatcherList: Math.random(),
      keySelectUser: Math.random(),
    });
  }

  resetProps() {
    this.setState({
      selectedTask: {},
      task: "",
      description: "",
      descriptionJson: {},
      selectedStaffUser: { label: "", value: "" },
      assignedTo: "",
      createdBy: "",
      priority: "low",
      status: "open",
      dueDate: new Date(),
      keyRadioSelect: Math.random(),
      keyDueDate: Math.random(),
      keySelectUser: Math.random(),
      keyCustomField: Math.random(),
      keyWatcherList: Math.random(),
      keyDraftEditor: Math.random(),
      notiTime: 0,
    });
  }

  async callGetTagByName() {
    let taskCreatedTag = config.tags.taskCreatedTag;
    let tagId = "";
    let d = await callGetSystemTagUsingName(taskCreatedTag);
    if (d.data.length > 0) {
      tagId = d.data[0]._id;
    }
    this.setState({ taskCreatedTagId: tagId });
  }

  async getSettings() {
    let d = await callGetSettings("tasksettings");
    let settings = d.data;
    let taskSettings = settings.task_settings || {};
    let defaultWatchers = taskSettings.default_watchers || [];
    let customFields = taskSettings.custom_fields || [];
    let taskStatusList = taskSettings.task_statuses || [];
    let hideEditMenuFields = taskSettings.hide_table_fields || [];

    let statusList = [];
    taskStatusList.forEach((element) => {
      statusList.push({
        name: element.name[0].toUpperCase() + element.name.slice(1),
        value: element.name,
      });
    });

    this.setState(
      {
        hideEditMenuFields,
        defaultWatchers,
        customFields,
        statusList,
        keyRadioSelect: Math.random(),
      },
      () => {
        this.manageDefaultWatchers(this.state.watchers);
      }
    );
  }

  manageDefaultWatchers(watchers) {
    let userId = getItem("userId");
    watchers.push(userId);
    let defaultWatchers = this.state.defaultWatchers;
    defaultWatchers.forEach((element) => {
      if (watchers.indexOf(element) === -1) {
        watchers.push(element);
      }
    });
    this.setState({ watchers: watchers });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  async executeTaskRules(rule, ruleData) {
    let apiData = {
      action: "execute-task-rule",
      data: {
        taskId: this.state.selectedTask._id,
        rule: rule,
        ruleData: ruleData,
      },
    };
    await callExecuteTaskRules(apiData);
  }

  onClickCancelFromCreateTaskModal() {
    this.setState({
      watchers: this.state.defaultWatchers,
      keyWatcherList: Math.random(),
    });
    this.resetProps();
    $(
      ".modalAlertOverlay.createTaskModalOverlay,.modalAlert.createTaskModal"
    ).fadeOut(200);
  }

  async onClickCreateFromCreateTaskModel() {
    this.setState({ errorMessage: "" });

    if (this.state.task.trim().length === 0) {
      showNotification("error", "Task is required", 4000);
      this.setState({ errorMessage: "Task is required" });
      return;
    }

    // adding comment here

    let assignedTo = "ffffffffffffffffffffffff";

    if (this.state.selectedStaffUser.label.length > 0) {
      assignedTo = this.state.selectedStaffUser.value._id;
    }

    let statusHistory = this.state.statusHistory || [];
    let userId = getItem("userId");

    statusHistory.push({
      status: this.state.status,
      date: new Date(),
      userId: userId,
    });

    let dueDate = this.state.dueDate;
    if (this.state.dueDateOption === "no") {
      dueDate = "";
    }

    let watchers = this.state.watchers
      .filter((item, i, ar) => ar.indexOf(item) === i)
      .filter((d) => d !== null);

    let reminders = [];

    if (dueDate) {
      reminders = [
        {
          id: getUniqueId(),
          date: moment(dueDate).subtract("12", "hours").toDate(),
          status: "pending",
          type: "assigned_to",
        },
      ];
      if (this.state.notiTime > 0) {
        reminders.push({
          id: getUniqueId(),
          date: moment(dueDate)
            .subtract(this.state.notiTime, this.state.notiType)
            .toDate(),
          status: "pending",
          type: "watchers",
        });
      }
    }

    let fileUrls = this.state.fileUrls;

    if (this.state.files.length > 0) {
      let resp = await uploadImages(this.state.files);
      let data = await resp.json();
      if (data.file_names) {
        data.file_names.forEach((element) => {
          fileUrls.push({ file: element.key, name: element.originalname });
        });
      }
    }

    let customFields = this.state.customFields;
    let errorFields = false;

    customFields.forEach((element) => {
      if (element.required) {
        if (!element.selectedOption || !element.selectedOption.id) {
          showNotification("error", `${element.label} is required`);
          errorFields = true;
          this.setState({ errorMessage: `${element.label} is required` });
          return;
        }
      }
    });
    if (errorFields) {
      return;
    }

    $(
      ".modalAlertOverlay.createTaskModalOverlay,.modalAlert.createTaskModal"
    ).fadeOut(200);

    /*eslint-disable*/
    let apiData = {
      assigned_to: assignedTo,
      created_by: userId,
      task: this.state.task,
      description: this.state.description,
      description_json: this.state.descriptionJson,
      priority: this.state.priority,
      status: this.state.status,
      due_date: dueDate,
      due_date_option: this.state.dueDateOption,
      status_history: this.state.statusHistory,
      watchers: watchers,
      custom_fields: customFields,
      reminders: reminders,
      noti_time: this.state.notiTime,
      noti_type: this.state.notiType,
      category: this.state.category,
      files: fileUrls,
    };
    /*eslint-enable*/

    if (this.state.selectedTask._id) {
      let historyObject = {
        date: new Date(),
        action: "update",
        userId: getLoggedInUser(),
      };
      apiData["$push"] = {
        history: historyObject,
      };

      let d = await callUpdateTask(this.state.selectedTask._id, apiData);
      if (d._id) {
        showNotification("success", "Task updated successfully", 4000);

        if (this.state.selectedTask.assigned_to !== assignedTo) {
          await this.executeTaskRules("assignedToUser", {
            userId: assignedTo,
          });
        }

        let ruleFields = [];
        let task = this.state.selectedTask;
        if (task.task !== this.state.task) {
          ruleFields.push("task");
        }
        if (task.description !== this.state.description) {
          ruleFields.push("description");
        }
        if (task.status !== this.state.status) {
          ruleFields.push("status");
        }
        if (task.priority !== this.state.priority) {
          ruleFields.push("priority");
        }
        if (task.due_date !== this.state.dueDate) {
          ruleFields.push("due_date");
        }
        if (task.assigned_to !== this.state.assignedTo) {
          ruleFields.push("assigned_to");
        }
        let taskCustomFields = this.state.selectedTask.custom_fields || [];

        taskCustomFields.forEach((element) => {
          let taskCustomField = customFields.find((d) => d.id === element.id);
          if (taskCustomField) {
            if (
              element.selectedOption.value !==
              taskCustomField.selectedOption.value
            ) {
              if (ruleFields.indexOf(element.id) === -1) {
                ruleFields.push(element.id);
              }
            }
            if (
              element.selectedOptions.length !==
              taskCustomField.selectedOptions.length
            ) {
              if (ruleFields.indexOf(element.id) === -1) {
                ruleFields.push(element.id);
              }
            }
          }
        });

        if (ruleFields.length > 0) {
          await this.executeTaskRules("field", {
            taskId: d._id,
            fields: ruleFields,
          });
        }

        this.resetProps();
        this.props.getTasks();
      } else {
        showNotification("error", "Something went wrong", 4000);
      }
    } else {
      let historyObject = {
        date: new Date(),
        action: "create",
        userId: getLoggedInUser(),
      };
      apiData["history"] = [historyObject];
      let d = await callCreateTasks(apiData);
      if (d._id) {
        showNotification("success", "Task created successfully", 4000);
        await this.executeTaskRules("newTaskCreated", {
          taskId: d._id,
        });
        if (assignedTo !== "ffffffffffffffffffffffff") {
          await this.executeTaskRules("assignedToUser", {
            userId: assignedTo,
            taskId: d._id,
          });
        }
        this.resetProps();
        await this.callAddTagAPI(this.state.watchers, d._id);
        this.props.getTasks();
      } else {
        showNotification("error", "Something went wrong", 4000);
      }
    }
    PubSub.publish("refreshOpenTasks", "");
  }

  async callAddTagAPI(users, taskId) {
    if (this.state.taskCreatedTagId) {
      for (let i = 0; i < users.length; i++) {
        const element = users[i];
        let apiData = {
          action: "add-member-tag",
          data: {
            /*eslint-disable*/
            member_id: element,
            tag_id: this.state.taskCreatedTagId,
            task_id: taskId,
            /*eslint-enable*/
          },
        };
        await callAddMemberTagL(apiData);
      }
    }
  }

  onChangeTaskDate(value) {
    this.setState({
      dueDate: value,
    });
  }

  onSelectStaffUser = (user) => {
    if (user) {
      let watchers = this.state.watchers;
      if (watchers.indexOf(user.value._id) === -1) {
        watchers.push(user.value._id);
      }
      this.setState({
        selectedStaffUser: user,
        watchers,
        keyWatcherList: Math.random(),
      });
    }
  };

  onSelectRadioSelect(item, id) {
    let value = item[Object.keys(item)[0]].value;
    let dueDate = this.state.dueDate;
    if (id === "dueDateOption" && value === "no") {
      dueDate = "";
    }
    this.setState({ [id]: value, dueDate: dueDate });
  }

  onChangeContentValue(value) {
    this.setState({ description: value });
  }

  onSelectWatcher(item, id) {
    let watchers = [];

    Object.keys(item).forEach((obj) => {
      watchers.push(item[obj].value);
    });

    this.setState({
      watchers: watchers,
    });
  }

  manageCustomFields(values, ids) {
    let customFields = this.state.customFields || [];

    customFields.forEach((element) => {
      if (ids.indexOf(element.id) > -1) {
        let selectedOption = element.options.filter((d) => {
          return values.indexOf(d.id) > -1;
        });
        if (selectedOption.length > 0) {
          element["selectedOption"] = selectedOption[0];
        }
        let defaultSelectedOption = element.options.filter((d) => {
          return d.selected === true;
        });
        if (defaultSelectedOption.length > 0 && !element["selectedOption"]) {
          element["selectedOption"] = defaultSelectedOption[0];
        }
      }
      if (ids.length === 0) {
        element["selectedOption"] = {};
      }
    });

    this.setState({
      customFields: customFields,
      keyCustomField: Math.random(),
    });
  }

  manageEditTaskCustomFields(taskCustomFields) {
    let customFields = this.state.customFields || [];

    customFields.forEach((element) => {
      let taskCustomField = taskCustomFields.find((d) => d.id === element.id);
      if (taskCustomField) {
        element.selectedOption = taskCustomField.selectedOption;
        element.selectedOptions = taskCustomField.selectedOptions;
      } else {
        element.selectedOption = { value: "" };
        element.selectedOptions = [];
      }
      let defaultSelectedOption = element.options.find((d) => {
        return d.selected === true;
      });
      if (
        defaultSelectedOption &&
        (!element.selectedOption || !element.selectedOption.id)
      ) {
        element.selectedOption = defaultSelectedOption;
      }
    });

    this.setState({
      customFields: customFields,
      keyCustomField: Math.random(),
    });
  }

  handleTextChange(event, id, label) {
    const target = event.target;
    const value = target.value;

    let customFields = this.state.customFields || [];
    customFields.forEach((element) => {
      if (element.id === id) {
        element["selectedOption"] = { label: label, id: id, value: value };
      }
    });
    this.setState({
      customFields: customFields,
    });
  }

  handleDropdownChange(event, id, label) {
    const target = event.target;
    const value = target.value;

    let customFields = this.state.customFields || [];
    customFields.forEach((element) => {
      if (element.id === id) {
        let option = element.options.find((d) => d.id === value);
        element["selectedOption"] = {
          label: option.label,
          id: id,
          value: value,
        };
      }
    });
    this.setState({
      customFields: customFields,
    });
  }

  handleEditorChange(html, json, id, label) {
    let customFields = this.state.customFields || [];
    customFields.forEach((element) => {
      if (element.id === id) {
        element["selectedOption"] = {
          label: label,
          id: id,
          value: html,
          valueJson: json,
        };
      }
    });
    this.setState({
      customFields: customFields,
    });
  }

  onSelectCustomOption(item, id) {
    this.manageCustomFields([item[Object.keys(item)[0]].value], [id]);
  }

  onSelectCustomOptions(item, id) {
    let customFields = this.state.customFields || [];
    let values = [];

    if (Object.keys(item).length > 0 && item.constructor === Object) {
      Object.keys(item).forEach((key) => {
        values.push(item[key].value);
      });
    }

    customFields.forEach((element) => {
      if (element.id === id) {
        let selectedOptions = element.options.filter((d) => {
          return values.indexOf(d.id) > -1;
        });
        if (selectedOptions.length > 0) {
          element["selectedOptions"] = selectedOptions;
        } else {
          element["selectedOptions"] = [];
        }
      }
    });

    this.setState({
      customFields: customFields,
      keyCustomField: Math.random(),
    });
  }

  renderGroupCustomFields(customFields) {
    let returnVals = [];

    for (let i = 0; i < customFields.length; i++) {
      const element = customFields[i];

      let optionList = [];
      let selectedOption = element.selectedOption || {};
      let selectedOptions = element.selectedOptions || [];

      element.options.forEach((opElem) => {
        if (
          !selectedOption.id &&
          opElem.selected &&
          element.type === "single"
        ) {
          selectedOption = opElem;
        }
        if (
          selectedOptions.length === 0 &&
          opElem.selected &&
          element.type === "multiple"
        ) {
          selectedOptions.push(opElem);
        }
        optionList.push({ name: opElem.label, value: opElem.id });
      });
      selectedOptions = selectedOptions.map((i) => i.id);
      let type = element.type || "single";
      returnVals.push(
        <>
          <div className="row">
            <div className="col-sm-12">
              {type === "single" && (
                <>
                  <label className="firstLetter"> {element.label} </label>
                  <div className="tabsHolder radioTabs">
                    <RadioTag
                      onCheckChange={this.onSelectCustomOption.bind(this)}
                      id={element.id}
                      labelList={optionList}
                      selectType={"value"}
                      key={this.state.keyCustomField}
                      selectedList={[selectedOption && selectedOption.id]}
                    />
                  </div>
                </>
              )}
              {type === "dropdown" && (
                <div
                  className={
                    selectedOption.value !== "" &&
                    selectedOption.value !== undefined
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>{element.label}</label>
                  <select
                    value={selectedOption.value}
                    onChange={(event) =>
                      this.handleDropdownChange(
                        event,
                        element.id,
                        element.label
                      )
                    }>
                    <option value="" />
                    {optionList.map((i) => (
                      <option value={i.value} key={i.value}>
                        {i.name}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              {type === "text" && (
                <div
                  className={
                    selectedOption.value !== "" &&
                    selectedOption.value !== undefined
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>{element.label}</label>
                  <textarea
                    value={selectedOption.value}
                    onChange={(event) =>
                      this.handleTextChange(event, element.id, element.label)
                    }
                  />
                </div>
              )}
              {type === "multiple" && (
                <>
                  <label className="firstLetter"> {element.label} </label>
                  <div className="tabsHolder radioTabs">
                    <RadioTag
                      onCheckChange={this.onSelectCustomOptions.bind(this)}
                      id={element.id}
                      labelList={optionList}
                      selectType={"value"}
                      type={"multiple"}
                      key={this.state.keyCustomField}
                      selectedList={selectedOptions}
                    />
                  </div>
                </>
              )}
              {type === "editor" && (
                <>
                  <label className="firstLetter"> {element.label} </label>
                  <div className="add-task-comment">
                    <DraftEditor
                      cType="task"
                      defaultText=""
                      textAlign={"left"}
                      lineHeight={"inherit"}
                      html={selectedOption.value}
                      json={selectedOption.valueJson}
                      onChange={(html, json) =>
                        this.handleEditorChange(
                          html,
                          json,
                          element.id,
                          element.label
                        )
                      }
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="gap20" />
        </>
      );
    }
    return returnVals;
  }

  renderCustomFields() {
    let customFields = this.state.customFields;
    return this.renderGroupCustomFields(customFields);
  }

  onClickEditTask = (task) => {
    let watchers = task.watchers || [];
    this.manageDefaultWatchers(watchers);

    let taskCustomFields = task.custom_fields || [];
    this.manageEditTaskCustomFields(taskCustomFields);

    let memberInfo = task.member_info;
    let selectedStaffUser = { label: "", value: "" };
    if (memberInfo) {
      selectedStaffUser = {
        label: task.member_info.name,
        value: task.member_info,
      };
    }

    this.setState(
      {
        selectedTask: task,
        task: task.task,
        description: task.description || "",
        descriptionJson: task.description_json || {},
        selectedStaffUser: selectedStaffUser,
        dueDate: task.due_date ? new Date(task.due_date) : "",
        dueDateOption: !task.due_date
          ? task.due_date_option
            ? task.due_date_option
            : "no"
          : "yes",
        priority: task.priority,
        status: task.status,
        statusHistory: task.status_history || [],
        keyRadioSelect: Math.random(),
        keyDueDate: Math.random(),
        keySelectUser: Math.random(),
        keyWatcherList: Math.random(),
        keyDraftEditor: Math.random(),
        notiTime: task.noti_time || 0,
        notiType: task.noti_type || "minutes",
        reminders: task.reminders || [],
        oldDueDate: task.due_date ? new Date(task.due_date) : "",
        category: task.category,
        keyCategory: Math.random(),
        fileUrls: task.files || [],
      },
      () => {
        this.setState({ keyDueDate: Math.random() });
        $(
          ".modalAlertOverlay.createTaskModalOverlay,.modalAlert.createTaskModal"
        ).fadeIn(200);
      }
    );
  };

  onChangeDescription = (html, json) => {
    this.setState({ description: html, descriptionJson: json });
  };

  async onClickSubmitCategory() {
    let categoryName = this.state.categoryName.trim();

    if (categoryName.trim().length === 0) {
      showNotification("error", "Category name is required", 4000);
      return;
    }
    let existingType = this.state.categories.filter((d) => {
      return d.name.toLowerCase() === categoryName.toLowerCase();
    });
    if (existingType.length > 0) {
      showNotification("error", "Category already exists");
      return;
    }

    let data = [{ name: this.state.categoryName, type: "task" }];

    let response = await callCreateGenericTag(data);
    if (response.length > 0) {
      this.setState({ categoryName: "", showCreateCategory: false }, () => {
        this.getCategories();
        this.setState({ category: response[0].id, keyCategory: Math.random() });
      });
    }
  }

  onClickCancelFromUpdateCategoryModal() {
    $(
      ".modalAlertOverlay.updateCategoryModalOverlay,.modalAlert.updateCategoryAlert"
    ).fadeOut(200);
  }

  async onClickUpdateFromUpdateCategoryModel() {
    if (this.state.categoryName.trim().length === 0) {
      showNotification("error", "Cagtegory name is required", 4000);
      return;
    }
    let updatedCategory = await callUpdateGenericTag(this.state.category, {
      name: this.state.categoryName,
    });
    if (updatedCategory._id) {
      this.getCategories();
      $(
        ".modalAlertOverlay.updateCategoryModalOverlay,.modalAlert.updateCategoryAlert"
      ).fadeOut(200);
    }
  }

  onClickEditCategory() {
    let selectedItemType = this.state.categories.find(
      (d) => d._id === this.state.category
    );
    this.setState({ categoryName: selectedItemType.name }, () => {
      $(
        ".modalAlertOverlay.updateCategoryModalOverlay,.modalAlert.updateCategoryAlert"
      ).fadeIn(200);
    });
  }

  renderUpdateCategoryModal() {
    let category = this.state.category;

    if (!category) {
      return null;
    }

    return (
      <div>
        <div className="modalAlertOverlay updateCategoryModalOverlay" />
        <div className="modalAlert updateCategoryAlert">
          <div className="clearfix">
            <div className="pull-left">
              <p>Edit Category</p>
            </div>
          </div>

          <div className="container-fluid">
            <div>
              <div
                className={
                  this.state.categoryName !== ""
                    ? "mdInput mdFocussed"
                    : "mdInput"
                }>
                <label>Category Name</label>
                <input
                  type="text"
                  name="categoryName"
                  value={this.state.categoryName}
                  onChange={(event) => this.handleInputChange(event)}
                />
              </div>
              <div className="gap20" />
            </div>
          </div>
          <div className="gap20" />
          <div className="alertFooterBtns">
            <button
              onClick={this.onClickCancelFromUpdateCategoryModal.bind(this)}
              className="btn btn-default dismissThisModal margin-right-10">
              Cancel
            </button>
            <button
              onClick={this.onClickUpdateFromUpdateCategoryModel.bind(this)}
              className="btn btn-primary dismissThisModal">
              Update
            </button>
          </div>
        </div>
      </div>
    );
  }

  onSelectItem(item, id) {
    this.setState({
      [id]: item[Object.keys(item)[0]].value,
      showCreateCategory:
        item[Object.keys(item)[0]].value === "Create New Category"
          ? true
          : false,
      keyCategory: Math.random(),
    });
  }

  renderCategoryComponent() {
    let categories = this.state.categories;
    let labelList = [];
    categories.forEach((element) => {
      labelList.push({
        name: element.name,
        value: element._id,
      });
    });
    labelList.push({
      name: "Create New Category",
      value: "Create New Category",
    });
    return (
      <>
        <div className="card">
          <div className="container-fluid">
            {labelList.length > 0 && (
              <div className="row" key={this.state.keyCategory}>
                <div className="col-md-12">
                  <div className="gap10" />
                  <label>Select Category</label>
                  <div className="tabsHolder radioTabs">
                    <RadioTag
                      onCheckChange={this.onSelectItem.bind(this)}
                      labelList={labelList}
                      id={"category"}
                      selectedList={[this.state.category]}
                      selectType={"value"}
                    />
                  </div>
                  <div className="gap20" />
                </div>
              </div>
            )}

            <VelocityTransitionGroup
              enter={{ animation: "slideDown" }}
              leave={{ animation: "slideUp" }}>
              {this.state.showCreateCategory && (
                <>
                  <div className="row">
                    <div className="col-sm-4">
                      <div
                        className={
                          this.state.categoryName !== ""
                            ? "mdInput mdFocussed"
                            : "mdInput"
                        }>
                        <label>Category Name</label>
                        <input
                          type="text"
                          name="categoryName"
                          value={this.state.categoryName}
                          onChange={(event) => this.handleInputChange(event)}
                        />
                      </div>
                      <div className="gap20" />
                    </div>
                    <div className="col-sm-4">
                      <div className="gap30" />
                      <div className="gap10" />
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={this.onClickSubmitCategory.bind(this)}>
                        Create Item Category
                      </button>
                    </div>
                  </div>
                </>
              )}
              {this.state.category !== "" && !this.state.showCreateCategory && (
                <button
                  className="btn btn-sm btn-primary"
                  onClick={this.onClickEditCategory.bind(this)}>
                  Edit Selected Category
                </button>
              )}
              <div className="gap10" />
            </VelocityTransitionGroup>
          </div>
        </div>
      </>
    );
  }

  handleFileUpload(event) {
    this.setState({ files: event.target.files });
  }

  onClickFileUrl(element) {
    if (element.file) {
      saveAs(imageUrl(element.file), element.name);
    } else {
      saveAs(imageUrl(element), element);
    }
  }

  renderFiles() {
    let files = this.state.fileUrls || [];
    let returnVals = [];
    for (let i = 0; i < files.length; i++) {
      const element = files[i];
      returnVals.push(
        <label
          className="link pointer-click margin-right-10"
          onClick={this.onClickFileUrl.bind(this, element)}>
          <u>{element.name ? element.name : element}</u>
        </label>
      );
    }
    return returnVals;
  }

  renderSelectFileComponent() {
    return (
      <>
        <label>Files</label>
        <br />
        <input
          ref={(ref) => (this.fileInput = ref)}
          type="file"
          onChange={this.handleFileUpload.bind(this)}
          multiple
        />
        <div className="gap20" />
        {this.renderFiles()}
      </>
    );
  }

  renderCreateTaskModal() {
    let selectedTask = this.state.selectedTask;
    let modalHeight = window.innerHeight - 160;
    let editMode = false;
    if (selectedTask._id) {
      editMode = true;
    }
    let hideEditMenuFields = this.state.hideEditMenuFields;
    return (
      <div>
        <div
          className="modalAlertOverlay createTaskModalOverlay"
          onClick={this.onClickCancelFromCreateTaskModal.bind(this)}
        />
        <div className="modalAlert modalTaskAlert createTaskModal fullScreen">
          <div className="clearfix">
            <div className="pull-left">
              <p style={{ fontSize: 18 }}>
                {editMode === true ? "Update Task" : "Create New Task"}
              </p>
            </div>
          </div>
          <Scrollbars
            autoHide
            autoHideTimeout={1000}
            autoHideDuration={200}
            style={{ height: modalHeight }}>
            <div className="container-fluid">
              <div
                className={
                  this.state.task !== "" ? "mdInput mdFocussed" : "mdInput"
                }>
                <label>Task</label>
                <input
                  type="text"
                  name="task"
                  value={this.state.task}
                  onChange={(event) => this.handleInputChange(event)}
                />
              </div>
              <div className="gap20" />
              <div className="havingHelpText">
                <label>Description</label>
              </div>
              <div className="add-task-comment">
                <DraftEditor
                  key={this.state.keyDraftEditor}
                  cType="task"
                  defaultText="Your Description"
                  textAlign={"left"}
                  lineHeight={"inherit"}
                  html={this.state.description}
                  json={this.state.descriptionJson}
                  onChange={this.onChangeDescription.bind(this)}
                />
              </div>
              <div className="gap20" />
              <div className="row">
                {hideEditMenuFields.indexOf("priority") === -1 && (
                  <div className="col-sm-6">
                    <div>
                      <label>Priority</label>
                      <div className="tabsHolder radioTabs">
                        <RadioTag
                          onCheckChange={this.onSelectRadioSelect.bind(this)}
                          labelList={[
                            { name: "Low", value: "low" },
                            { name: "Medium", value: "medium" },
                            { name: "High", value: "high" },
                          ]}
                          key={this.state.keyRadioSelect}
                          id={"priority"}
                          selectType={"value"}
                          selectedList={[this.state.priority]}
                        />
                      </div>
                      <div className="gap20" />
                    </div>
                  </div>
                )}
                {hideEditMenuFields.indexOf("status") === -1 && (
                  <div className="col-sm-6">
                    <div>
                      <label>Status</label>
                      <div className="tabsHolder radioTabs">
                        <RadioTag
                          onCheckChange={this.onSelectRadioSelect.bind(this)}
                          labelList={this.state.statusList}
                          key={this.state.keyRadioSelect}
                          id={"status"}
                          selectType={"value"}
                          selectedList={[this.state.status]}
                        />
                      </div>
                      <div className="gap20" />
                    </div>
                  </div>
                )}
              </div>
              <div className="gap10" />
              <div>
                <div className="row">
                  {hideEditMenuFields.indexOf("dueDate") === -1 && (
                    <div className="col-sm-6">
                      <label>Select Due Date</label>
                      <div className="tabsHolder radioTabs">
                        <RadioTag
                          onCheckChange={this.onSelectRadioSelect.bind(this)}
                          labelList={[
                            { name: "No Due Date", value: "no" },
                            { name: "Assign Due Date", value: "yes" },
                          ]}
                          key={this.state.keyRadioSelect}
                          id={"dueDateOption"}
                          selectType={"value"}
                          selectedList={[this.state.dueDateOption]}
                        />
                      </div>
                      {this.state.dueDateOption === "yes" && (
                        <div>
                          <div className="gap10" />
                          <DateTimePicker
                            key={this.state.keyDueDate}
                            value={this.state.dueDate}
                            onChange={this.onChangeTaskDate.bind(this)}
                          />
                          <div className="gap20" />
                          <div className="row">
                            <div className="col-4">
                              <div
                                className={
                                  this.state.notiTime !== ""
                                    ? "mdInput mdFocussed"
                                    : "mdInput"
                                }>
                                <label>Send Reminder to watchers before</label>
                                <input
                                  type="text"
                                  name="notiTime"
                                  value={this.state.notiTime}
                                  onChange={(event) =>
                                    this.handleInputChange(event)
                                  }
                                />
                              </div>
                              <div className="gap10" />
                            </div>
                            <div className="col-sm-6">
                              <div>
                                <div className="gap30" />
                                <div className="gap10" />
                                <div className="tabsHolder radioTabs">
                                  <RadioTag
                                    onCheckChange={this.onSelectRadioSelect.bind(
                                      this
                                    )}
                                    labelList={[
                                      { name: "Minutes", value: "minutes" },
                                      { name: "Hours", value: "hours" },
                                      { name: "Days", value: "days" },
                                    ]}
                                    key={this.state.keyNotiType}
                                    id={"notiType"}
                                    selectType={"value"}
                                    selectedList={[this.state.notiType]}
                                  />
                                </div>
                                <div className="gap20" />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  {/* {hideEditMenuFields.indexOf("assigned") === -1 && (
                    <div className="col-sm-6">
                      <TypeableSelect
                        key={this.state.keySelectUser}
                        name={"assignedTo"}
                        placeholder={"Select A User"}
                        selected={this.state.selectedStaffUser}
                        onSelect={this.onSelectStaffUser}
                        options={this.state.staffUsers}
                      />
                    </div>
                  )} */}
                  {hideEditMenuFields.indexOf("assigned") === -1 && (
                    <div className="col-sm-6">
                      <SearchableUserSelect
                        key={this.state.keySelectUser}
                        name={"assignedTo"}
                        placeholder="Select A User"
                        onSelect={this.onSelectStaffUser}
                        selected={
                          this.state.selectedStaffUser
                        }></SearchableUserSelect>
                    </div>
                  )}
                </div>
              </div>
              <div className="gap20" />
              <div className="row">
                <div className="col-sm-12">
                  <label>Watchers</label>
                  <div className="tabsHolder radioTabs">
                    <RadioTag
                      key={this.state.keyWatcherList}
                      onCheckChange={this.onSelectWatcher.bind(this)}
                      labelList={this.state.watcherList}
                      type={"multiple"}
                      id={"watchers"}
                      selectedList={this.state.watchers}
                      selectType={"value"}
                    />
                  </div>
                </div>
              </div>
              <div className="gap20" />
              <div key={this.state.keyCustomField}>
                {this.renderCustomFields()}
              </div>
              <div className="gap20" />
              {hideEditMenuFields.indexOf("category") === -1 &&
                this.renderCategoryComponent()}
              {this.renderSelectFileComponent()}
            </div>
          </Scrollbars>
          <div className="gap20" />
          <div className="alertFooterBtns">
            <label className="text-danger margin-right-10">
              {this.state.errorMessage}
            </label>
            <button
              onClick={this.onClickCancelFromCreateTaskModal.bind(this)}
              className="btn btn-default dismissThisModal margin-right-10">
              Cancel
            </button>
            <button
              onClick={this.onClickCreateFromCreateTaskModel.bind(this)}
              className="btn btn-primary dismissThisModal">
              {editMode === true ? "Update" : "Create"}
            </button>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderUpdateCategoryModal()} {this.renderCreateTaskModal()}
      </div>
    );
  }
}
export default TaskEdit;
