import moment from "moment-timezone";
import React, { Component } from "react";

class SelectDateTime extends Component {
  constructor(props) {
    super(props);

    const dateTime = this.props.date || "";
    const type = this.props.type || "datetime";

    this.state = {
      dateTime: dateTime,
      type: type,
      selectedDay: "",
      selectedMonth: "",
      selectedYear: "",
      selectedTimeType: "",
      selectedHour: "",
      selectedMins: "",
      label: this.props.label || "Date",
      responseType: this.props.responseType || "date",
    };
  }

  componentDidMount() {
    this.setData();
  }

  setData() {
    if (this.state.dateTime && this.state.dateTime !== "") {
      let momentDate = moment(this.state.dateTime);
      if (this.state.type === "time") {
        momentDate = moment(this.state.dateTime, "hh:mm A");
      }
      this.setState({
        selectedDay: momentDate.format("DD"),
        selectedMonth: momentDate.format("MM"),
        selectedYear: momentDate.format("YYYY"),
        selectedTimeType: momentDate.format("A"),
        selectedHour: momentDate.format("hh"),
        selectedMins: momentDate.format("mm"),
      });
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState(
      {
        [name]: value,
      },
      () => {
        this.prepareDate();
      }
    );
  }

  prepareDate() {
    if (this.state.responseType === "date") {
      let newDate = moment();
      newDate = moment(
        `${this.state.selectedYear}/${this.state.selectedMonth}/${this.state.selectedDay}`,
        "YYYY/MM/DD",
        true
      );
      let fromTime = `${this.state.selectedHour}:${this.state.selectedMins} ${this.state.selectedTimeType}`;
      let fromDate = `${newDate.format("YYYY-MM-DD")}T${fromTime}`;
      let fTime = moment(fromDate, "YYYY-MM-DDThh:mm A");
      this.props.onChange(fTime.toDate());
    } else if (this.state.responseType === "time") {
      let fromTime = `${this.state.selectedHour}:${this.state.selectedMins} ${this.state.selectedTimeType}`;
      this.props.onChange(fromTime);
    }
  }

  renderDays() {
    var returnVals = [];
    for (let i = 1; i <= 31; i++) {
      let day = i.toString().length !== 1 ? i : `0${i}`;
      returnVals.push(
        <option value={day} key={day}>
          {day}
        </option>
      );
    }
    return returnVals;
  }

  renderMonths() {
    var returnVals = [];
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    for (let i = 1; i <= 12; i++) {
      let month = i.toString().length !== 1 ? i : `0${i}`;
      returnVals.push(
        <option value={month} key={month}>
          {monthNames[i - 1]}
        </option>
      );
    }
    return returnVals;
  }

  renderYears() {
    let years = ["2021", "2022", "2023", "2024", "2025", "2026", "2027"];
    var returnVals = years.map((i) => (
      <option value={i} key={i}>
        {i}
      </option>
    ));
    return returnVals;
  }

  renderHours() {
    var returnVals = [];
    for (let i = 1; i <= 12; i++) {
      let hour = i.toString().length !== 1 ? i : `0${i}`;
      returnVals.push(
        <option value={hour} key={hour}>
          {hour}
        </option>
      );
    }
    return returnVals;
  }

  renderMins() {
    var returnVals = [];
    for (let i = 0; i <= 60; i = i + 1) {
      let min = i.toString().length !== 1 ? i : `0${i}`;
      returnVals.push(
        <option value={min} key={min}>
          {min}
        </option>
      );
    }
    return returnVals;
  }

  render() {
    let type = this.state.type;
    return (
      <div>
        <label className="inputFakeLabelFocussed">{this.state.label}</label>
        <div className="row">
          {(type === "datetime" || type === "date") && (
            <>
              <div className="col-sm-2">
                <div
                  className={
                    this.state.selectedDay !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Day</label>
                  <select
                    name="selectedDay"
                    value={this.state.selectedDay}
                    onChange={(event) => this.handleInputChange(event)}>
                    <option value="" />
                    {this.renderDays()}
                  </select>
                </div>
                <div className="gap20" />
              </div>
              <div className="col-sm-2">
                <div
                  className={
                    this.state.selectedMonth !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Month</label>
                  <select
                    name="selectedMonth"
                    value={this.state.selectedMonth}
                    onChange={(event) => this.handleInputChange(event)}>
                    <option value="" />
                    {this.renderMonths()}
                  </select>
                </div>
                <div className="gap20" />
              </div>
              <div className="col-sm-2">
                <div
                  className={
                    this.state.selectedYear !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Year</label>
                  <select
                    name="selectedYear"
                    value={this.state.selectedYear}
                    onChange={(event) => this.handleInputChange(event)}>
                    <option value="" />
                    {this.renderYears()}
                  </select>
                </div>
                <div className="gap20" />
              </div>
            </>
          )}
          {(type === "datetime" || type === "time") && (
            <>
              <div className="col-sm-2">
                <div
                  className={
                    this.state.selectedHour !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Hour</label>
                  <select
                    name="selectedHour"
                    value={this.state.selectedHour}
                    onChange={(event) => this.handleInputChange(event)}>
                    <option value="" />
                    {this.renderHours()}
                  </select>
                </div>
                <div className="gap20" />
              </div>
              <div className="col-sm-2">
                <div
                  className={
                    this.state.selectedMins !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Minute</label>
                  <select
                    name="selectedMins"
                    value={this.state.selectedMins}
                    onChange={(event) => this.handleInputChange(event)}>
                    <option value="" />
                    {this.renderMins()}
                  </select>
                </div>
                <div className="gap20" />
              </div>
              <div className="col-sm-2">
                <div
                  className={
                    this.state.selectedTimeType !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Type</label>
                  <select
                    name="selectedTimeType"
                    value={this.state.selectedTimeType}
                    onChange={(event) => this.handleInputChange(event)}>
                    <option value="" />
                    <option value="AM">AM</option>
                    <option value="PM">PM</option>
                  </select>
                </div>
                <div className="gap20" />
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default SelectDateTime;
