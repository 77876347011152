import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment-timezone";

import {
  callGetPageEditorDrafts,
  callGetEditorDraftById,
} from "../../services";
import ReactPaginate from "react-paginate";
import RadioTag from "../form/radioTag";

class EditorDraft extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      drafts: [],
      page: 0,
      pages: 0,
      totalDrafts: 0,
      autoSaveType: "all",
      keyAutoSaveType: Math.random(),
    };
  }

  componentDidMount() {
    this.getDrafts();
  }

  async getDrafts() {
    let limit = 20;

    let query = {
      query: {
        $sort: { createdAt: -1 },
        page: this.state.page,
        limit: limit,
      },
    };

    if (
      this.props.screen === "add-email" ||
      this.props.screen === "add-email-sms" ||
      this.props.screen === "add-challenge-email" ||
      this.props.screen === "add-checkout-item" ||
      this.props.screen === "add-landing-notification-email"
    ) {
      query.query["screen"] = {
        $in: [
          "add-email",
          "add-email-sms",
          "add-challenge-email",
          "add-landing-notification-email",
          "add-checkout-item",
        ],
      };
    } else {
      query.query["screen"] = { $ne: "add-email" };
    }

    query.query["content_type"] = this.props.editorType;

    if (this.state.autoSaveType === "auto") {
      query.query["auto_save"] = true;
    }
    if (this.state.autoSaveType === "save") {
      query.query["auto_save"] = { $ne: true };
    }

    let draftsData = await callGetPageEditorDrafts(query);
    let drafts = draftsData.data;

    this.setState({
      totalDrafts: draftsData.total,
      pages: Math.ceil(draftsData.total / limit),
      drafts: drafts,
    });
  }

  onClickRestoreFromTemplate(element) {
    callGetEditorDraftById(element._id).then((d) => {
      this.props.onRestoreDesign(d);
    });
  }

  getScreenName(screen) {
    let screens = {
      "add-email": "Add Email",
      "add-resource": "Add Resource",
      "add-movement": "Add Movement",
      "add-challenge-item": "Add Challenge Item",
      "add-article": "Add Article",
      "add-marci-circle": "Add Newsletter",
      "add-popup": "Add Popup",
      "add-upsell-item": "Add Upsell Item",
      "add-landing-page": "Add Landing Page",
      "add-process": "Add Process",
      "add-churn-item": "Add Churn Item",
      "add-challenge": "Add Challenge",
      "add-email-template": "Add Email Template",
      "add-onboarding": "Add Onboarding",
      "add-help-item": "Add Help Item",
      "add-registration-page": "Add Registration Page",
      "atq-content": "Settings ATQ Content",
      "member-dashboard": "Settings Member Dashboard",
      "add-subscription-area": "Add Subscription Area",
      "add-help-desk": "Add Article - Help Desk",
      "add-email-sms": "Add Event Email",
      "add-challenge-email": "Add Challenge Email",
      "add-landing-notification-email": "Add Landinge Page Notification",
    };
    return screens[screen];
  }

  formatDate(date) {
    return moment(date).tz(moment.tz.guess()).format("Do MMM YYYY hh:mm A z");
  }

  renderDrafts() {
    var returnVals = [];
    let items = this.state.drafts;
    items.forEach((element) => {
      returnVals.push(
        <tr key={element._id}>
          <td>{this.getScreenName(element.screen)}</td>
          <td>{element.title ? element.title : "-"}</td>
          <td>{this.formatDate(element.createdAt)}</td>
          <td>{element.auto_save ? "Yes" : "No"}</td>
          <td>
            <button
              className="btn btn-primary btn-sm btn-block"
              onClick={this.onClickRestoreFromTemplate.bind(this, element)}>
              Restore
            </button>
          </td>
        </tr>
      );
    });
    return returnVals;
  }

  handlePageClick = (data) => {
    let page = data.selected;
    this.setState({ page: page }, () => {
      this.getDrafts();
    });
  };

  onSelectAutoSaveType(item, id) {
    let value = item[Object.keys(item)[0]].value;
    this.setState({ [id]: value }, () => {
      this.getDrafts();
    });
  }

  render() {
    return (
      <div>
        <div className="container-fluid">
          <h3 className="noMargin">View Drafts</h3>
          <div className="gap20" />
          <div className="card">
            <div className="container-fluid">
              <div className="gap20" />
              <p>
                Total Drafts: <b>{this.state.totalDrafts}</b>
              </p>
              <p>
                Current Page: <b>{this.state.page + 1}</b>
              </p>
              <div id="react-paginate">
                <ReactPaginate
                  previousLabel={<span aria-hidden="true">&laquo;</span>}
                  nextLabel={<span aria-hidden="true">&raquo;</span>}
                  pageCount={this.state.pages}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={10}
                  onPageChange={this.handlePageClick}
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  pageClassName="page-item"
                  previousClassName="page-item"
                  nextClassName="page-item"
                  pageLinkClassName="page-link"
                  previousLinkClassName="page-link"
                  nextLinkClassName="page-link"
                  activeClassName="page-item active"
                  disableInitialCallback={true}
                  initialPage={0}
                />
              </div>
              <div className="gap10" />
              <div className="tabsHolder radioTabs">
                <RadioTag
                  key={this.state.keyAutoSaveType}
                  onCheckChange={this.onSelectAutoSaveType.bind(this)}
                  labelList={[
                    { name: "All", value: "all" },
                    { name: "Save/Preview", value: "save" },
                    { name: "Auto Saved", value: "auto" },
                  ]}
                  id={"autoSaveType"}
                  selectedList={[this.state.autoSaveType]}
                  selectType={"value"}
                />
              </div>
              <div className="gap20" />
            </div>{" "}
          </div>
          <div className="card">
            <div className="list-group list-group-flush">
              <div className="list-group-item">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Screen</th>
                      <th>Title</th>
                      <th>Datetime</th>
                      <th>Auto Save</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>{this.renderDrafts()}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EditorDraft);
