import {
  CREATE_ARTICLE_SUCCESS,
  CREATE_ARTICLE_FAILURE,
  GET_ARTICLE_SUCCESS,
  GET_ARTICLE_CATEGORY_SUCCESS,
  CREATE_CONTENT_TEMPLATE_SUCCESS,
  CREATE_CONTENT_TEMPLATE_FAILURE,
  GET_CONTENT_TEMPLATE_SUCCESS,
  UPDATE_CONTENT_TEMPLATE_SUCCESS,
  UPDATE_CONTENT_TEMPLATE_FAILURE,
  RESET_STATUS,
  UPDATE_ARTICLE_SUCCESS,
  UPDATE_ARTICLE_FAILURE
} from "../types";

import config from "../../helpers/config";

import {
  callCreateArticle,
  callGetArticle,
  callGetArticleCategory,
  callCreateContentTemplate,
  callGetContentTemplate,
  callUpdateContentTemplate,
  callUpdateArticle
} from "../../services/index";

/*
  This method is used to reset the status
  This will reset message and success states
*/
export const resetStatus = data => {
  return async dispatch => {
    dispatch({
      type: RESET_STATUS
    });
  };
};

export const createArticle = data => {
  return async dispatch => {
    return callCreateArticle(data)
      .then(data => {
        dispatch({
          type: CREATE_ARTICLE_SUCCESS,
          message: config.messages.createChlgQueSuccess,
          success: true
        });
      })
      .catch(err => {
        dispatch({
          type: CREATE_ARTICLE_FAILURE,
          message: config.messages.createChlgQueError,
          success: false
        });
      });
  };
};

export const updateArticle = (id, data) => {
  return async dispatch => {
    return callUpdateArticle(id, data)
      .then(data => {
        dispatch({
          type: UPDATE_ARTICLE_SUCCESS,
          message: config.messages.updateMessage,
          success: true
        });
      })
      .catch(err => {
        dispatch({
          type: UPDATE_ARTICLE_FAILURE,
          message: config.messages.createChlgQueError,
          success: false
        });
      });
  };
};

export const getArticle = () => {
  return async dispatch => {
    return callGetArticle().then(data => {
      dispatch({
        type: GET_ARTICLE_SUCCESS,
        data: data
      });
    });
  };
};

export const getArticleCategory = data => {
  return async dispatch => {
    return callGetArticleCategory(data).then(data => {
      dispatch({
        type: GET_ARTICLE_CATEGORY_SUCCESS,
        data: data
      });
    });
  };
};

export const createContentTemplate = data => {
  return async dispatch => {
    return callCreateContentTemplate(data)
      .then(data => {
        dispatch({
          type: CREATE_CONTENT_TEMPLATE_SUCCESS,
          message: config.messages.createChlgQueSuccess,
          success: true
        });
      })
      .catch(err => {
        dispatch({
          type: CREATE_CONTENT_TEMPLATE_FAILURE,
          message: config.messages.createChlgQueError,
          success: false
        });
      });
  };
};

export const updateContentTemplate = (id, data) => {
  return async dispatch => {
    return callUpdateContentTemplate(id, data)
      .then(data => {
        dispatch({
          type: UPDATE_CONTENT_TEMPLATE_SUCCESS,
          message: config.messages.updateMessage,
          success: true
        });
      })
      .catch(err => {
        dispatch({
          type: UPDATE_CONTENT_TEMPLATE_FAILURE,
          message: config.messages.createChlgQueError,
          success: false
        });
      });
  };
};

export const getContentTemplate = data => {
  return async dispatch => {
    return callGetContentTemplate(data).then(data => {
      dispatch({
        type: GET_CONTENT_TEMPLATE_SUCCESS,
        data: data
      });
    });
  };
};
