import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { showNotification } from "../../helpers";
import SearchableUserSelect from "./searchableUserSelect";

class ActionFilter extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    let groups = this.props.groups || [];
    let selectedGroup = "";

    if (groups.length > 0) {
      selectedGroup = groups[0].id;
    }

    let removeGroups = this.props.removeGroups || [];
    let selectedRemoveGroup = "";

    if (removeGroups.length > 0) {
      selectedRemoveGroup = removeGroups[0].id;
    }

    let watchers = this.props.watchers || [];
    let selectedWatcher = "";
    let selectedOwner = "";

    if (watchers.length > 0) {
      selectedWatcher = watchers[0]._id;
      selectedOwner = watchers[0]._id;
    }

    let selectedTaskStatus = "";

    let statusList = this.props.statusList || [];
    if (statusList.length > 0) {
      statusList = statusList.filter((d) => d.value !== "all");
      selectedTaskStatus = statusList[0].value;
    }

    this.state = {
      actions: [],
      selectedAction: "select",
      groups: groups,
      removeGroups: removeGroups,
      selectedGroup: selectedGroup,
      selectedRemoveGroup: selectedRemoveGroup,
      type: this.props.type,
      selectedTaskStatus: selectedTaskStatus,
      watchers: watchers,
      selectedWatcher: selectedWatcher,
      selectedOwner: selectedOwner,
      statusList: statusList,
      keySelectUser: Math.random(),
      selectedStaffUser: {},
    };
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  onPressSubmit() {
    let {
      selectedAction,
      selectedGroup,
      selectedRemoveGroup,
      selectedTaskStatus,
      selectedWatcher,
      selectedStaffUser,
    } = this.state;
    if (
      this.state.type === "viewEmail" ||
      this.state.type === "viewSMS" ||
      this.state.type === "viewEvents"
    ) {
      if (selectedGroup || selectedRemoveGroup) {
        if (selectedAction === "remove-group") {
          this.props.onSubmit(selectedRemoveGroup, "remove");
        } else if (selectedAction === "add-group") {
          this.props.onSubmit(selectedGroup, "add");
        }
      } else {
        showNotification("error", "Please select group");
      }
    } else if (this.state.type === "viewTasks") {
      if (selectedAction === "task-move-to") {
        this.props.onSubmit(selectedTaskStatus, this.state.selectedAction);
      } else if (selectedAction === "task-add-watcher") {
        this.props.onSubmit(selectedWatcher, this.state.selectedAction);
      } else if (selectedAction === "task-assign-owner") {
        this.props.onSubmit(
          selectedStaffUser.value._id,
          this.state.selectedAction
        );
      } else if (
        selectedAction === "pay-invoice" ||
        selectedAction === "create-invoice"
      ) {
        this.props.onSubmit("", this.state.selectedAction);
      }
    }
  }

  onSelectStaffUser = (user) => {
    if (user) {
      this.setState({
        selectedStaffUser: user,
      });
    }
  };

  render() {
    let { type } = this.props;
    let hostName = window.location.hostname;
    let tasksApp =
      hostName.includes("cri") ||
      hostName.includes("testonly") ||
      hostName.includes("local");
    return (
      <div className="row">
        <div className="col-md-2">
          <select
            name="selectedAction"
            value={this.state.selectedAction}
            onChange={(event) => this.handleInputChange(event)}>
            <option value="select">Select an action</option>
            {(type === "viewEmail" ||
              type === "viewSMS" ||
              type === "viewEvents") && (
              <option value="add-group">Put in group</option>
            )}
            {(type === "viewEmail" ||
              type === "viewSMS" ||
              type === "viewEvents") && (
              <option value="remove-group">Remove from group</option>
            )}
            {type === "viewTasks" && (
              <option value="task-move-to">Move to</option>
            )}
            {/* {type === "viewTasks" && (
              <option value="task-add-watcher">Add Watcher</option>
            )}*/}
            {type === "viewTasks" && (
              <option value="task-assign-owner">Assign User</option>
            )}
            {type === "viewTasks" && tasksApp && (
              <option value="pay-invoice">Pay Bill</option>
            )}
            {type === "viewTasks" && tasksApp && (
              <option value="create-invoice">Generate Invoice</option>
            )}
          </select>
        </div>

        <div className="col-md-3">
          {this.state.selectedAction === "add-group" && (
            <select
              name="selectedGroup"
              value={this.state.selectedGroup}
              onChange={(event) => this.handleInputChange(event)}>
              {this.state.groups.map((i) => (
                <option value={i.id} key={i.id}>
                  {i.name}
                </option>
              ))}
            </select>
          )}
          {this.state.selectedAction === "remove-group" && (
            <select
              name="selectedRemoveGroup"
              value={this.state.selectedRemoveGroup}
              onChange={(event) => this.handleInputChange(event)}>
              {this.state.removeGroups.map((i) => (
                <option value={i.id} key={i.id}>
                  {i.name}
                </option>
              ))}
            </select>
          )}
          {this.state.selectedAction === "task-move-to" && (
            <select
              name="selectedTaskStatus"
              value={this.state.selectedTaskStatus}
              onChange={(event) => this.handleInputChange(event)}>
              {this.state.statusList.map((i) => (
                <option value={i.value}>{i.name}</option>
              ))}
            </select>
          )}
          {this.state.selectedAction === "task-add-watcher" && (
            <select
              name="selectedWatcher"
              value={this.state.selectedWatcher}
              onChange={(event) => this.handleInputChange(event)}>
              {this.state.watchers.map((i) => (
                <option value={i._id} key={i._id}>
                  {i.name}
                </option>
              ))}
            </select>
          )}
          {this.state.selectedAction === "task-assign-owner" && (
            <SearchableUserSelect
              key={this.state.keySelectUser}
              name={"assignedTo"}
              placeholder="Select A User"
              onSelect={this.onSelectStaffUser}
              selected={this.state.selectedStaffUser}></SearchableUserSelect>
          )}
        </div>

        <div className="col-md-2">
          <div className="gap5" />
          {(this.state.selectedAction === "add-group" ||
            this.state.selectedAction === "remove-group" ||
            this.state.selectedAction === "task-move-to" ||
            this.state.selectedAction === "task-add-watcher" ||
            this.state.selectedAction === "task-assign-owner" ||
            this.state.selectedAction === "pay-invoice" ||
            this.state.selectedAction === "create-invoice") && (
            <button
              className="btn btn-primary btn-sm"
              onClick={() => this.onPressSubmit()}>
              Submit
            </button>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ActionFilter);
