import React, { Component } from "react";
import Switch from "react-switch";

class StatusSwitch extends Component {
  constructor(props) {
    super(props);

    let label = this.props.isActive ? "Currently Active" : "Currently Inactive";
    this.state = {
      isActive: this.props.isActive,
      label: label
    };
  }

  onChange = check => {
    this.setState(
      {
        isActive: check,
        label: check ? "Currently Active" : "Currently Inactive"
      },
      () => {
        this.props.onChange(check);
      }
    );
  };

  render() {
    return (
      <div className="card">
        <div className="container-fluid">
          <div className="gap10" />
          <Switch
            onChange={this.onChange}
            checked={this.state.isActive}
            onColor={"#26ace2"}
            checkedIcon={false}
            uncheckedIcon={false}
          />
          <br />
          <label
            className={
              this.state.isActive ? "inputFakeLabelFocussed" : "inputFakeLabel"
            }>
            {this.state.label}
          </label>
        </div>
      </div>
    );
  }
}

export default StatusSwitch;
