import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { arrayMove } from "react-sortable-hoc";
import { VelocityTransitionGroup } from "velocity-react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import $ from "jquery";
import PubSub from "pubsub-js";
import DatePicker from "react-date-picker";

import { resetStatus } from "../../../modules/actions/index";

import {
  SortableQuestionList,
  SortableVideoList,
} from "../../../components/form/sortableList";
import PageContent from "../../../components/editor/pageContent";
import Checkbox from "../../../components/form/checkBox";
import TitleBox from "../../../components/general/titleBox";
import AutoSuggest from "../../../components/form/autoSuggest";
import TypeableSelect from "../../../components/form/typeableSelect";
import RadioTag from "../../../components/form/radioTag";
import HelpTextDiv from "../../../components/form/helpTextDiv";
import SelectAddImage from "../../../components/form/selectAddImage";

import {
  showNotification,
  openPagePopupModal,
  getParameterByName,
  isFS,
  getEditorType,
  slugify,
  checkGMSMessage,
} from "../../../helpers";

import PreviewChallenge from "./previewChallenge";
import {
  callGetChlgById,
  callCreateChlg,
  callUpdateChlg,
  callGetSystemTags,
  callCreateSystemTag,
  callGetEmails,
  callGetCheckoutItemByQuery,
  callGetTagFilterUsers,
  callUpdateEmail,
  callGetGenericTags,
  callCreateGenericTag,
  callUpdateGenericTag,
  callUpdateCheckoutItem,
  callGetQuestionsUsingQuery,
  callGetNotificationsUsingQuery,
  callGetChlgsByQuery,
  callGetNotificationById,
  callGetChlgQueById,
  callGetTextMessagesByQuery,
  callGetFormsUsingQuery,
  callGetPDFFormsUsingQuery,
  callUpdateChlgQue,
  callCheckTier,
  callCloneChlgItem,
  callGetPromoCodes,
} from "../../../services";
import PagePopup from "../../../components/popup/pagePopup";
import VideoPlayerNote from "../../../components/general/videoPlayerNote";
import {
  callGetChlgItemById,
  callGetChlgQues,
  callGetChlgItems,
} from "../../../services";
import Onboarding from "../onboarding/onboarding";
import ChallengeOperations from "./challengeOpts";
import { SettingsHelper } from "../../../helpers/settings";
import { getItem } from "../../../helpers/storage";
import SelectDiscussionBoard from "../../../components/form/discussionBoard";
import moment from "moment-timezone";
import DateTimePicker from "react-datetime-picker";
import { processEditorContent } from "../../../components/editor/processEditor";
import { callEmailTemplateData } from "../../../services/settingService";
import config from "../../../helpers/config";
import TagSelect from "../../../components/form/tagSelect";
import Scrollbars from "react-custom-scrollbars";

class AddChallenge extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    let challenge = {
      _id: 0,
      name: "",
      description: "",
      category: "",
      shortName: "",
      activeDays: "60",
      addActiveDays: "1",
      challengeDays: "60",
      previewDays: "",
      startDayOfWeek: "",
      feedback: false,
      isForeverOpen: false,
      rules: [],
      challengeItemList: [],
      videoList: [],
      baseQuestionList: [],
      html: "",
      content: {},
      type: "course",
      durationType: "nd",
      videoSectionTitle: "",
      displayType: "ac", // ac = video display after content and bc = before content
      newChallengeItemCreated: false,
      challengeItemUpdated: false,
      showAdvancedOptionsDetails: false,
      showAdvancedOptionsItems: false,
      screenshot: "",
      priority: "",
      group: "",
    };

    this.previewChlgRef = React.createRef();

    let challengeId = getParameterByName("id");
    let editMode = false;
    if (challengeId) {
      challenge._id = challengeId;
      editMode = true;
    }

    let selectedTab = "details";
    let tab = getParameterByName("tab");
    if (tab) {
      selectedTab = tab;
    }

    let days = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Wednesday",
      "Friday",
      "Saturday",
      "Sunday",
    ];

    const tags = [];

    let tabOptions = [
      { label: "Details", key: "details", selected: selectedTab === "details" },
      { label: "Content", key: "content", selected: selectedTab === "content" },
      { label: "Items", key: "items", selected: selectedTab === "items" },
      {
        label: "Rules & Emails",
        key: "rules",
        selected: selectedTab === "rules",
      },
      { label: "Accountability", key: "atq", selected: selectedTab === "atq" },
      {
        label: "Agreement Contract",
        key: "form",
        selected: selectedTab === "form",
      },
      {
        label: "Checkout",
        key: "checkout",
        selected: selectedTab === "checkout",
      },
      {
        label: "Users",
        key: "users",
        selected: selectedTab === "users",
      },
    ];

    this.socialLables = {
      facebook: "Facebook",
      twitter: "Twitter",
      linkedin: "LinkedIn",
      pintrest: "Pintrest",
      tumblr: "Tumblr",
      email: "Email",
      telegram: "Telegram",
      messenger: "Messenger",
      reddit: "Reddit",
    };

    let socialOptions = {
      facebook: false,
      twitter: false,
      linkedin: false,
      pintrest: false,
      tumblr: false,
      reddit: false,
      messenger: false,
      telegram: false,
      email: false,
    };

    this.state = {
      challengeId: challengeId,
      tabOptions,
      selectedTab: selectedTab,
      challenge: challenge,
      editMode: editMode,
      questions: [],
      items: [],
      videoTitle: "",
      videoUrl: "",
      videoPlayer: "Youtube",
      itemOffsetDays: 0,
      itemOffsetType: "days",
      keyItemOffsetType: Math.random(),
      itemOffsetDate: "",
      isTimeReleaseThisItem: false,
      itemLockDays: "",
      itemPreviewDays: "",
      isHideOnLock: false,
      isHideBeforeRelease: false,
      days: days,
      selectedCategory: "",
      selectedQue: { value: "", label: "" },
      selectedItem: { value: "", label: "" },
      selectedEditItemIndex: -1,
      isFeedback: challenge.feedback,
      tagValue: "",
      tagSelected: {},
      tags: tags,
      tagSuggestions: [],
      rules: [],
      ruleType: "",
      onSpecificDay: "",
      perCompleted: "",
      noActivityDays: "",
      daysNotTracked: "",
      actionType: "",
      keyQuestion: Math.random(),
      keyItem: Math.random(),
      keyFeedback: Math.random(),
      keyHideOnLock: Math.random(),
      keyHideBeforeRelease: Math.random(),
      keyForeverOpen: Math.random(),
      showAddedItems: true,
      isForeverOpen: true,
      isCompleteItem: false,
      keyCompleteItem: Math.random(),
      isVideoCompleteItem: false,
      keyVideoCompleteItem: Math.random(),
      isOpenCompleteItem: false,
      keyOpenCompleteItem: Math.random(),
      completeItemBtn: "Task Completed",
      completeItemPoints: "10",
      html: challenge.html,
      content: challenge.content,
      keyTag: Math.random(),
      popupType: "",
      popupLabel: "",
      popupChallengeItem: "",
      popupKey: Math.random(),
      keyDuration: Math.random(),
      isCharts: false,
      isAtqCharts: false,
      atqCharts: { donut: false, day: false, daily: false },
      keyAtqCharts: Math.random(),
      keyCharts: Math.random(),
      keyHelpDiv: Math.random(),
      isAllowedToLock: false,
      isAllowedToBePreviewed: false,
      keyAllowLock: Math.random(),
      keyAllowPreview: Math.random(),
      keyTimeReleaseThisItem: Math.random(),
      keyChallengeType: Math.random(),
      keyChallengeDispalyType: Math.random(),
      isLeaderBoard: false,
      isShowNavigation: true,
      isHideCategory: true,
      keyHideCategory: Math.random(),
      showVideoProgress: true,
      keyShowVideoProgress: Math.random(),
      keyLeaderBoard: Math.random(),
      keyShowNavigation: Math.random(),
      leaderboardActiveUserCount: 0,
      leaderboardNumberOfUsers: 10,
      videoKey: Math.random(),
      keyImage: Math.random(),
      isSocialShare: false,
      keySocialShare: Math.random(),
      shareUrl: "",
      shareText:
        "If you enjoy our content, we would love so much if you can share it with your friends with the links below too.",
      socialOptions: socialOptions,
      searchItemType: "new",
      keySearchItemType: Math.random(),
      tagType: "existing",
      keyTagType: Math.random(),
      tagName: "",
      emails: [],
      searchQuestionType: "existing",
      keySearchQuestionType: Math.random(),
      checkoutAmount: "",
      checkoutItems: [],
      challengeUsers: [],
      backupChallengeUsers: [],
      newQuestionCreated: false,
      accountKey: "",
      domainList: [],
      pendingUsers: [],
      systemTagId: "",
      processing: false,
      editorType: isFS()
        ? "mp"
        : getItem("de") === "classic"
        ? "unlayer"
        : "mp",
      selectedEmail: {},
      isTagChallenge: false,
      keyTagChallenge: Math.random(),
      keyAddTag: Math.random(),
      addTags: [],
      selectedTags: [],
      priority: challenge.priority,
      group: "",
      groups: [],
      groupName: "",
      groupPriority: "",
      keyGroups: Math.random(),
      showCreateGroup: false,
      discussionBoard: "",
      isDiscussionBoard: false,
      keyDiscussionBoard: Math.random(),
      emailName: "",
      emailContent: {},
      emailHtml: "",
      keyPageContent: Math.random(),
      selectedCheckoutItem: {},
      draftId: "",
      draftImage: "",
      notiTitle: "",
      notiBody: "",
      notiScreen: "",
      sendTimeType: "send",
      selectedHour: "09",
      selectedMins: "00",
      selectedTimeType: "AM",
      keySendTime: Math.random(),
      queTagId: "",
      queTagValue: "",
      queQueId: "",
      questionName: "",
      queTag: {},
      isRuleLoading: false,
      onWeekday: "monday",
      onSpecificDate: new Date(),
      challenges: [],
      challengeItems: [],
      keyChallenge: Math.random(),
      notiChallengeId: "",
      notiChallengeItemId: "",
      challegeInfo: {},
      smsName: "",
      smsMessage: "",
      isGMSMessage: true,
      noOfMessages: 1,
      forms: [],
      keyForm: Math.random(),
      selectedForm: { value: "", label: "" },
      showAdvancedOptionsItems: false,
      showAtqAdvancedOptions: false,
      atqRuleType: "",
      onSpecificAtqDay: "",
      searchQuestionAtqType: "new",
      newQuestionType: "normal",
      atqRules: [],
      selectedAtqRule: {},
      colIntroSection: false,
      keyColIntroSection: Math.random(),
      loadingPage: true,
      userVideos: [],
      editorTemplates: [],
      itemTemplateId: config.editorTemplateIds.defaultMpChallengeItem,
      duplicateItems: [],
      promoCodeAvailable: false,
      keyPromoCodeAvailable: Math.random(),
      selectedPromoCodes: [],
      promoCodes: [],
      keyPromoCode: Math.random(),
      pCodes: [],
      popupSystemTag: {},
      selectedUser: {},
      challengeUserStatus: "all",
      autoSignupTag: false,
    };
  }

  componentDidMount() {
    this.getPromoCodes();
    if (!this.state.editMode) {
      this.checkTier();
    } else {
      this.setState({ loadingPage: false });
    }

    this.getData();

    if (this.state.challengeId) {
      this.getChallengeInfo();
    } else {
      this.getForms();
    }
    PubSub.subscribe("refreshChallenge", (key, run) => {
      let tab = getParameterByName("tab");
      if (tab === "content") {
        this.getChallengeInfo();
      }
    });
  }

  async checkTier() {
    var response = await callCheckTier("num_courses");
    if (!response.status) {
      this.props.history.push("/admin/tier");
    } else {
      this.setState({ loadingPage: false });
    }
  }

  getPromoCodes() {
    callGetPromoCodes().then((d) => {
      let codes = [];
      d.data.forEach((element) => {
        codes.push({ title: element.code });
      });
      this.setState({ promoCodes: codes, keyPromoCode: Math.random() });
    });
  }

  async getForms() {
    let query = {
      query: {
        $select: ["name", "_id"],
      },
    };
    let d = await callGetFormsUsingQuery(query);
    let forms = d.data;

    let fForms = [];

    forms.forEach((element) => {
      fForms.push({ value: element, label: element.name, type: "normal" });
    });

    let t = await callGetPDFFormsUsingQuery(query);
    let pdfForms = t.data;

    pdfForms.forEach((element) => {
      fForms.push({ value: element, label: element.name, type: "pdf" });
    });

    this.setState({
      forms: fForms,
      keyForm: Math.random(),
    });
    return fForms;
  }

  async getData() {
    this.getForms();
    this.getChallengeQuestions();
    this.getChallengeItems();
    this.getSystemTags();
    this.getGroups();
    this.getEditorTemplates();

    let response = await callGetChlgsByQuery({
      query: {
        archive: { $ne: true },
        $select: ["-content", "-html"],
      },
    });

    this.setState({
      challenges: response.data,
      keyChallenge: Math.random(),
      linkUrlDisable: true,
    });
  }

  async getEditorTemplates() {
    let object = await callEmailTemplateData(true);
    this.setState({ editorTemplates: object.data });
  }

  async getChallengeQuestions() {
    let d = await callGetChlgQues();
    let ques = d.data;
    let fQues = [];
    ques.forEach((element) => {
      if (element.status !== "archive") {
        fQues.push({ value: element, label: element.name });
      }
    });

    this.setState({
      questions: fQues,
      keyQuestion: Math.random(),
    });

    if (this.state.newQuestionCreated) {
      let newItems = fQues;
      this.setState(
        {
          selectedQue: {
            value: newItems[newItems.length - 1].value,
            label: newItems[newItems.length - 1].value.name,
          },
          newQuestionCreated: false,
        },
        () => {
          this.onClickAddQuestion();
        }
      );
    }
  }

  async getChallengeItems() {
    let d = await callGetChlgItems();
    let items = d.data;
    let fItems = [];

    items.forEach((element) => {
      fItems.push({ value: element, label: element.name });
    });

    this.setState({
      items: fItems,
      keyItem: Math.random(),
    });

    if (this.state.newChallengeItemCreated) {
      let newItems = fItems;
      this.setState(
        {
          selectedItem: {
            value: newItems[newItems.length - 1].value,
            label: newItems[newItems.length - 1].value.name,
          },
          isCompleteItem: true,
          completeItemPoints: "10",
          newChallengeItemCreated: false,
        },
        () => {
          this.onClickAddItem();
        }
      );
    }
    if (this.state.challengeItemUpdated) {
      let newItems = fItems;
      let index = 0;
      for (var element of newItems) {
        if (!this.state.selectedItem.value._id) {
          break;
        }
        if (element.value._id === this.state.selectedItem.value._id) {
          this.setState({
            selectedItem: {
              value: newItems[index].value,
              label: newItems[index].value.name,
            },
            challengeItemUpdated: false,
          });
          this.getChallengeInfo();
          break;
        }
        index = index + 1;
      }
    }
  }

  async getSystemTags() {
    let d = await callGetSystemTags();
    this.setState({ tags: d.data, keyAddTag: Math.random() });
  }

  async getGroups() {
    let d = await callGetGenericTags("challenge");
    this.setState({ groups: d.data, keyGroups: Math.random() });
    if (d.data.length === 0) {
      this.setState({
        itemType: "Create New Group",
        keyGroups: Math.random(),
        showCreateGroup: true,
      });
    }
  }

  async getChallengeInfo() {
    let challenge = await callGetChlgById(this.state.challengeId);
    if (challenge._id) {
      let tagName = `${slugify(challenge.name)}-auto-signup`;
      let existingTag = await callGetSystemTags({ query: { name: tagName } });
      if (existingTag.data.length > 0) {
        this.setState({ autoSignupTag: true });
      }

      this.prepareChallengeInfo(challenge);
    } else {
      this.setState({ editMode: false });
    }
  }

  async prepareChallengeInfo(challenge) {
    let forms = await this.getForms();

    let selectedForms = forms.filter((d) => d.value._id === challenge.form_id);
    let selectedForm = {};

    if (selectedForms.length > 0) {
      selectedForm = {
        value: selectedForms[0].value,
        label: selectedForms[0].label,
        type: challenge.form_type,
      };
    }

    challenge.shortName = challenge["short_name"];
    delete challenge["short_name"];

    challenge.challengeDays = challenge["challenge_days"].toString();
    delete challenge["challenge_days"];

    challenge.activeDays = challenge["active_days"].toString();
    delete challenge["active_days"];

    challenge.addActiveDays = challenge["add_active_days"] || 1;
    delete challenge["add_active_days"];

    challenge.previewDays = challenge["preview_days"].toString();
    delete challenge["preview_days"];

    challenge.startDayOfWeek = challenge["start_day_of_week"];
    delete challenge["start_day_of_week"];

    challenge.videoList = challenge["video_list"];
    delete challenge["video_list"];

    challenge.baseQuestionList = challenge["base_question_list_info"] || [];

    challenge.content = challenge.content || {};
    challenge.html = challenge.html || "";
    challenge.type = challenge.type || "challenge";
    challenge.durationType = challenge.duration_type || "nd";
    challenge.displayType = challenge.display_type || "ac";
    challenge.priority = challenge.priority || "";
    challenge.group = challenge.group || "";

    let baseQuestionList = [];
    challenge.baseQuestionList.forEach((element) => {
      baseQuestionList.push({
        value: element,
        name: element.name,
        question: element.question,
        description: element.description,
      });
    });

    challenge.baseQuestionList = baseQuestionList;
    this.setState({ isRuleLoading: true });
    challenge.rules = await this.prepareRules(challenge.rules);

    challenge.challengeItemList = challenge["challenge_item_list_info"] || [];
    challenge.videoSectionTitle = challenge["video_section_title"] || "";
    challenge.screenshot = challenge["screenshot"] || "";
    challenge.draftId = challenge["draft_id"] || "";
    challenge.draftImage = challenge["draft_image"] || "";

    challenge.challengeItemList.forEach((obj) => {
      obj["item_info"] = challenge["challenge_item_list"].filter((d) => {
        return d.item_id === obj._id;
      })[0];

      obj["question"] = obj.name;
      obj["value"] = obj;

      obj["offset_days"] = obj.item_info["offset_days"];
      obj["offset_date"] = obj.item_info["offset_date"]
        ? new Date(obj.item_info["offset_date"])
        : "";
      obj["offset_type"] = obj.item_info["offset_type"];
      obj["lock_days"] = obj.item_info["lock_days"];
      obj["preview_days"] = obj.item_info["preview_days"] || "";
      obj["hide_on_lock"] = obj.item_info["hide_on_lock"];
      obj["hide_before_release"] = obj.item_info["hide_before_release"];
      obj["complete_item"] = obj.item_info["complete_item"] || false;
      obj["video_complete_item"] =
        obj.item_info["video_complete_item"] || false;
      obj["open_complete_item"] = obj.item_info["open_complete_item"] || false;
      obj["complete_item_btn"] = obj.item_info["complete_item_btn"] || "";
      obj["complete_item_points"] = obj.item_info["complete_item_points"] || "";
      obj["lock_allowed"] = obj.item_info["lock_allowed"] || false;
      obj["preview_allowed"] = obj.item_info["preview_allowed"] || false;
      obj["delete_confirm"] = false;
    });

    let foreverOpen = challenge.forever_open;
    if (foreverOpen === null || foreverOpen === undefined) {
      foreverOpen = false;
    }
    let isSocialShare = false;
    let shareUrl = "";
    let shareText = this.state.shareText;
    let socialShare = challenge.social_share;
    let socialOptions = this.state.socialOptions;
    let newSocialOptions = {};

    if (socialShare) {
      if (socialShare.share) {
        isSocialShare = true;
      }
      shareUrl = socialShare.url;
      shareText = socialShare.text || shareText;
      for (const key in socialOptions) {
        if (socialOptions.hasOwnProperty(key)) {
          if (socialShare.options[key] !== undefined) {
            newSocialOptions[key] = socialShare.options[key];
          } else {
            newSocialOptions[key] = false;
          }
        }
      }
      socialOptions = newSocialOptions;
    }

    this.getEmailsFromGroup(challenge.email_group_id);
    this.getCheckoutItems(challenge);
    this.getChallengeUsers(challenge);

    let editorType = getEditorType(challenge.html, challenge.content);

    let addTags = challenge.ftags || [];
    let selectedTags = challenge.ftags || [];

    let atqRules = await this.prepareAtqRules(challenge.atq_rules);

    let userVideos = challenge.user_videos || [];

    this.setState({
      challenge,
      userVideos,
      editMode: true,
      isFeedback: challenge.feedback,
      isForeverOpen: foreverOpen,
      keyForeverOpen: Math.random(),
      isCharts: challenge.is_charts || false,
      isAtqCharts: challenge.is_atq_charts,
      atqCharts: challenge.atq_charts || {
        donut: false,
        day: false,
        daily: false,
      },
      keyCharts: Math.random(),
      html: challenge.html,
      content: challenge.content,
      keyDuration: Math.random(),
      keyChallengeType: Math.random(),
      keyChallengeDispalyType: Math.random(),
      isLeaderBoard: challenge.is_leaderboard || false,
      isShowNavigation:
        challenge.is_navigation !== undefined ? challenge.is_navigation : true,
      isHideCategory:
        challenge.hide_category !== undefined ? challenge.hide_category : false,
      isDiscussionBoard: challenge.is_discussion_board || false,
      discussionBoard: challenge.discussion_board || "",
      keyLeaderBoard: Math.random(),
      keyImage: Math.random(),
      leaderboardActiveUserCount: challenge.leaderboard_active_user_count,
      leaderboardNumberOfUsers:
        challenge.leaderboard_number_of_users === undefined
          ? 10
          : challenge.leaderboard_number_of_users,
      isSocialShare: isSocialShare,
      keySocialShare: Math.random(),
      shareUrl: shareUrl,
      shareText: shareText,
      socialOptions: socialOptions,
      editorType: editorType,
      addTags: addTags,
      selectedTags: selectedTags,
      keyAddTag: Math.random(),
      isTagChallenge: challenge.is_tag_challenge,
      keyTagChallenge: Math.random(),
      keyDiscussionBoard: Math.random(),
      keyPageContent: Math.random(),
      draftId: challenge.draftId,
      draftImage: challenge.draftImage,
      isRuleLoading: false,
      keyForm: Math.random(),
      selectedForm: selectedForm,
      atqRules: atqRules,
      colIntroSection: challenge.col_intro,
      keyColIntroSection: Math.random(),
      showVideoProgress: challenge.show_video_progress,
      keyShowVideoProgress: Math.random(),
      itemTemplateId:
        challenge.item_template_id ||
        config.editorTemplateIds.defaultMpChallengeItem,
    });
  }

  async getChallengeUsers(challenge) {
    let challengeRules = challenge.rules;
    let signupTag = challengeRules.find(
      (d) => d.lock && d.type === "at_signup"
    );
    let cancelledTag = challengeRules.find(
      (d) => d.lock && d.type === "on_cancel"
    );
    let completedTag = challengeRules.find(
      (d) => d.lock && d.type === "on_last_day"
    );

    let signupTagId = signupTag ? signupTag.tag_id : "";
    let cancelledTagId = cancelledTag ? cancelledTag.tag_id : "";
    let completedTagId = completedTag ? completedTag.tag_id : "";

    let data = {
      action: "challenge-users",
      data: {
        signupTagId: signupTagId,
        cancelledTagId: cancelledTagId,
        completedTagId: completedTagId,
        challengeId: challenge._id,
      },
    };
    let d = await callGetTagFilterUsers(data);
    this.setState({
      challengeUsers: d.challengeUsers,
      backupChallengeUsers: d.challengeUsers,
      pendingUsers: d.pendingUsers,
    });
  }

  async getCheckoutItems(challenge) {
    let ids = challenge.checkout_ids;
    if (ids.length > 0) {
      let query = {
        query: {
          _id: { $in: ids },
          archive: { $ne: "archive" },
        },
      };
      let items = await callGetCheckoutItemByQuery(query);
      let settingsHelper = new SettingsHelper();
      let accountKey = settingsHelper.getAccountKey();
      let domainList = settingsHelper.getDomainList();

      this.setState({ checkoutItems: items.data, accountKey, domainList });
    }
  }

  async getEmailsFromGroup(groupId) {
    if (!groupId) {
      return;
    }
    /*eslint-disable*/
    let query = {
      query: {
        status: { $ne: "archive" },
        generic_tag_list: { $in: [groupId] },
      },
    };
    /*eslint-enable*/
    let data = await callGetEmails(query);
    this.setState({ emails: data.data });
  }

  onSelectQuestion = (que) => {
    this.setState({ selectedQue: que }, () => {
      this.onClickAddQuestion();
    });
  };

  onSelectAtqQuestion = (que) => {
    this.setState({ selectedQue: que });
  };

  onSelectItem = (item) => {
    this.setState({ selectedItem: item });
  };

  onSelectVideoPlayer(item, id) {
    this.setState({
      [id]: item[Object.keys(item)[0]].value,
      videoKey: Math.random(),
    });
  }

  onChangeCategory = (category) => {
    let challenge = this.state.challenge;
    challenge["category"] = category;
    this.setState({ challenge: challenge });
  };

  onSelectCategory = (data) => {
    let challenge = this.state.challenge;
    challenge["category"] = data.category;
    this.setState({ challenge: challenge });
  };

  onSelectGroup(item, id) {
    let challenge = this.state.challenge;
    challenge[id] = item[Object.keys(item)[0]].value;

    this.setState({
      challenge: challenge,
      groupName: "",
      groupPriority: "",
      showCreateGroup:
        item[Object.keys(item)[0]].value === "Create New Group" ? true : false,
    });
  }

  onSelectDay(item, id) {
    let challenge = this.state.challenge;
    challenge[id] = item[Object.keys(item)[0]].value;
    this.setState({ challenge: challenge });
  }

  onSelectType(item, id) {
    let challenge = this.state.challenge;
    challenge[id] = item[Object.keys(item)[0]].value;
    this.setState({ challenge: challenge });
  }

  onSelectSearchItemType(item, id) {
    this.setState({ [id]: item[Object.keys(item)[0]].value });
  }

  onSelectTagType(item, id) {
    this.setState({ [id]: item[Object.keys(item)[0]].value });
  }

  onSelectDisplayType(item, id) {
    let challenge = this.state.challenge;
    challenge[id] = item[Object.keys(item)[0]].value;
    this.setState({ challenge: challenge });
  }

  onSelectDurationType(item, id) {
    let challenge = this.state.challenge;
    challenge[id] = item[Object.keys(item)[0]].value;
    this.setState({ challenge: challenge });
  }

  resetProps() {
    this.setState({
      name: "",
      description: "",
      shortName: "",
      challengeDays: "",
      activeDays: "60",
      previewDays: "",
      questions: [],
      items: [],
      chlgQueList: [],
      chlgItemList: [],
      videos: [],
      videoTitle: "",
      videoUrl: "",
      videoPlayer: "Youtube",
      itemOffsetDays: 0,
      itemLockDays: "",
      isHideOnLock: false,
      isHideBeforeRelease: false,
      selectedQue: { value: "", label: "" },
      selectedItem: { value: "", label: "" },
      selectedDay: "",
      isFeedback: false,
      tagValue: "",
      tagSelected: {},
      tagSuggestions: [],
      rules: [],
      ruleType: "",
      onSpecificDay: "",
      daysNotTracked: "",
      actionType: "",
      keyQuestion: Math.random(),
      keyItem: Math.random(),
      keyFeedback: Math.random(),
      keyHideOnLock: Math.random(),
      keyHideBeforeRelease: Math.random(),
      isForeverOpen: true,
      keyForeverOpen: Math.random(),
      completeItemBtn: "",
      group: "",
    });
  }

  handleInputChange(event) {
    const target = event.target;
    var value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    if (name === "ruleType") {
      if (value === "on_specific_day") {
        this.setState({
          daysNotTracked: "",
        });
      }
      if (value === "days_not_tracked") {
        this.setState({
          onSpecificDay: "",
        });
      }
      if (value === "at_signup") {
        this.setState({
          onSpecificDay: "",
          daysNotTracked: "",
        });
      }
    }

    if (name === "emailName") {
      this.setState({ emailName: value });
      return;
    }

    if (name === "actionType") {
      if (value === "add-sms") {
        /*eslint-disable*/
        this.setState({
          sendTimeType: "time",
          selectedHour: "09",
          selectedMins: "00",
          selectedTimeType: "AM",
          keySendTime: Math.random(),
        });
        /*eslint-enable*/
      }
      if (value === "add-email") {
        /*eslint-disable*/
        this.setState({
          sendTimeType: "send",
          selectedHour: "01",
          selectedMins: "00",
          selectedTimeType: "AM",
          keySendTime: Math.random(),
        });
        /*eslint-enable*/
      }
      this.setState({ [name]: value });
      return;
    }

    if (name === "tagName") {
      value = slugify(value, false);
    }

    this.setState({
      [name]: value,
    });

    if (name === "notiChallengeId") {
      let challengeItems = this.state.challenges.filter(
        (d) => d._id === value
      )[0].challenge_item_list_info;
      this.setState({
        challengeItems: challengeItems,
        notiChallengeItemId: "",
      });
    }
  }

  handleChallengeInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    let challenge = this.state.challenge;

    if (name === "name") {
      if (value.length > 0) {
        let aValue = value.match(/\b\w/g).join("");
        challenge.shortName = aValue;
      }
    }

    challenge[name] = value;
    this.setState({
      challenge: challenge,
    });
  }

  onClickAddVideo() {
    let videos = this.state.challenge.videoList;

    if (this.state.videoTitle.trim().length === 0) {
      showNotification("error", "Video Title is required", 4000);
      return;
    }
    if (this.state.videoUrl.trim().length === 0) {
      showNotification("error", "Video ID is required", 4000);
      return;
    }
    if (this.state.videoPlayer.trim().length === 0) {
      showNotification("error", "Video Player is required", 4000);
      return;
    }

    videos.push({
      title: this.state.videoTitle,
      url: this.state.videoUrl,
      player: this.state.videoPlayer,
    });

    let challenge = this.state.challenge;
    challenge.videoList = videos;

    this.setState({
      challenge: challenge,
      videoTitle: "",
      videoUrl: "",
      videoPlayer: "Youtube",
    });
  }

  onSortEndVideos = ({ oldIndex, newIndex }) => {
    let challenge = this.state.challenge;
    challenge.videoList = arrayMove(challenge.videoList, oldIndex, newIndex);

    this.setState({
      challenge: challenge,
    });
  };

  onSortEndQues = ({ oldIndex, newIndex }) => {
    let challenge = this.state.challenge;
    challenge.baseQuestionList = arrayMove(
      challenge.baseQuestionList,
      oldIndex,
      newIndex
    );

    this.setState({
      challenge: challenge,
    });
  };

  onSortEndItems = ({ oldIndex, newIndex }) => {
    let challenge = this.state.challenge;
    challenge.challengeItemList = arrayMove(
      challenge.challengeItemList,
      oldIndex,
      newIndex
    );

    this.setState(
      {
        challenge: challenge,
      },
      () => {
        this.onClickAddChallenge();
      }
    );
  };

  onPressRemoveVideo(e) {
    let index = e.target.dataset["value"];
    let videos = this.state.challenge.videoList;
    videos.splice(index, 1);
    this.setState({
      videos: videos,
    });
  }

  onPressRemoveQue(e) {
    let index = e.target.dataset["value"];
    let chlgQueList = this.state.challenge.baseQuestionList;
    chlgQueList.splice(index, 1);
    this.setState(
      {
        chlgQueList: chlgQueList,
      },
      () => {
        this.onClickAddChallenge();
      }
    );
  }

  onPressViewQue(e) {
    let index = e.target.dataset["value"];
    let chlgQueList = this.state.challenge.baseQuestionList;
    let question = chlgQueList[index];
    window.open(`/admin/add-question?id=${question.value.id}`, "_blank");
  }

  onPressRemoveItemUndo(e) {
    let index = e.target.dataset["value"];
    let challengeItemList = this.state.challenge.challengeItemList;
    let item = challengeItemList[index];
    item["delete_confirm"] = false;
    this.setState({
      challengeItemList: challengeItemList,
    });
  }

  onPressRemoveItemConfirm(e) {
    let index = e.target.dataset["value"];
    let challengeItemList = this.state.challenge.challengeItemList;
    challengeItemList.splice(index, 1);

    this.setState(
      {
        challengeItemList: challengeItemList,
      },
      () => {
        this.onClickAddChallenge();
      }
    );
  }

  async onPressDuplicate(e) {
    let index = e.target.dataset["value"];
    let challengeItemList = this.state.challenge.challenge_item_list;
    let duplicateItems = this.state.duplicateItems;

    const item = challengeItemList[index];

    let challengeItem = await callGetChlgItemById(item.item_id);
    let editorContent = processEditorContent(challengeItem.editor_content);

    const response = await callCloneChlgItem(item.item_id, {
      editorContent: editorContent,
    });
    if (response._id) {
      item["item_id"] = response._id;
      duplicateItems.push(item);
    }

    this.setState({ duplicateItems }, () => {
      this.onClickAddChallenge();
    });
  }

  onPressRemoveItem(e) {
    let index = e.target.dataset["value"];
    let challengeItemList = this.state.challenge.challengeItemList;
    let item = challengeItemList[index];
    item["delete_confirm"] = true;
    this.setState({
      challengeItemList: challengeItemList,
    });
  }

  onPressEditItemContent(e) {
    let index = e.target.dataset["value"];
    let challengeItemList = this.state.challenge.challengeItemList;

    let challengeItem = challengeItemList[index];
    if (!challengeItem) {
      return;
    }

    let selectedItem = challengeItem.value;

    callGetChlgItemById(selectedItem._id).then((item) => {
      this.setState(
        {
          popupType: "editChallengeItem",
          popupLabel: "Edit Challenge Item",
          popupKey: Math.random(),
          popupChallengeItem: item,
        },
        () => {
          openPagePopupModal("editChallengeItem");
        }
      );
    });
  }

  onPressEditItem(e) {
    let index = e.target.dataset["value"];
    let challengeItemList = this.state.challenge.challengeItemList;

    let challengeItem = challengeItemList[index];
    if (!challengeItem) {
      return;
    }

    let selectedItem = challengeItem.value;

    this.setState({
      selectedEditItemIndex: index,
      itemLockDays: challengeItem.lock_days,
      itemOffsetDays: challengeItem.offset_days || 0,
      itemPreviewDays: challengeItem.preview_days,
      isHideOnLock: challengeItem.hide_on_lock,
      isHideBeforeRelease: challengeItem.hide_before_release,
      itemOffsetDate: challengeItem.offset_date || "",
      itemOffsetType: challengeItem.offset_type || "days",
      selectedItem: { value: selectedItem, label: selectedItem.name },
      keyItem: Math.random(),
      keyHideOnLock: Math.random(),
      keyHideBeforeRelease: Math.random(),
      showAddedItems: false,
      isCompleteItem: challengeItem.complete_item || false,
      isVideoCompleteItem: challengeItem.video_complete_item || false,
      keyVideoCompleteItem: Math.random(),
      isOpenCompleteItem: challengeItem.open_complete_item || false,
      keyOpenCompleteItem: Math.random(),
      completeItemBtn: challengeItem.complete_item_btn || "Task Completed",
      completeItemPoints: challengeItem.complete_item_points || "10",
      keyCompleteItem: Math.random(),
      isAllowedToLock: challengeItem.lock_allowed || false,
      isAllowedToBePreviewed: challengeItem.preview_allowed || false,
      keyAllowLock: Math.random(),
      keyAllowPreview: Math.random(),
      isTimeReleaseThisItem:
        challengeItem.offset_days !== 0 || challengeItem.offset_type !== "days"
          ? true
          : false,
      searchItemType: "existing",
      keySearchItemType: Math.random(),
      showAdvancedOptionsItems: true,
    });
  }

  toggleFeedbackCheckbox = (check) => {
    this.setState({
      isFeedback: check,
    });
  };

  toggleChartsCheckbox = (check) => {
    this.setState({
      isCharts: check,
    });
  };

  toggleAtqChartsCheckbox = (check) => {
    this.setState({
      isAtqCharts: check,
    });
  };

  toggleAtqChartsValueCheckbox = (value, check) => {
    let atqCharts = this.state.atqCharts;
    atqCharts[value] = check;
    this.setState({
      atqCharts,
    });
  };

  toggleLeaderBoardCheckbox = (check) => {
    this.setState({
      isLeaderBoard: check,
    });
  };

  toggleNavigationCheckbox = (check) => {
    this.setState({
      isShowNavigation: check,
    });
  };

  toggleHideCategoryCheckbox = (check) => {
    this.setState({
      isHideCategory: check,
    });
  };

  toggleShowVideoProgressCheckbox = (check) => {
    this.setState({
      showVideoProgress: check,
    });
  };

  toggleForverOpenCheckbox = (check) => {
    this.setState({
      isForeverOpen: check,
    });
  };

  toggleSocialShareCheckbox = (check) => {
    this.setState({
      isSocialShare: check,
    });
  };

  toggleHideLockCheckbox = (check) => {
    this.setState({
      isHideOnLock: check,
    });
  };

  toggleCompleteItemCheckbox = (check) => {
    this.setState({
      isCompleteItem: check,
    });
  };

  toggleVideoCompleteItemCheckbox = (check) => {
    this.setState({
      isVideoCompleteItem: check,
    });
  };

  toggleOpenCompleteItemCheckbox = (check) => {
    this.setState({
      isOpenCompleteItem: check,
    });
  };

  toggleAllowLockCheckbox = (check) => {
    if (!check) {
      this.setState({
        itemLockDays: 0,
      });
    }

    this.setState({
      isAllowedToLock: check,
    });
  };

  toggleTimeReleaseThisItem = (check) => {
    if (!check) {
      this.setState({
        itemOffsetDays: 0,
        itemOffsetType: "days",
      });
    }

    this.setState({
      isTimeReleaseThisItem: check,
    });
  };

  toggleColIntroSection = (check) => {
    this.setState(
      {
        colIntroSection: check,
      },
      () => {
        this.onClickAddChallenge();
      }
    );
  };

  toggleAllowPreviewCheckbox = (check) => {
    if (!check) {
      this.setState({
        itemPreviewDays: 0,
      });
    }

    this.setState({
      isAllowedToBePreviewed: check,
    });
  };

  toggleHideBeforeReleaseCheckbox = (check) => {
    this.setState({
      isHideBeforeRelease: check,
    });
  };

  toggleTagChallengeCheckbox = (check) => {
    this.setState({
      isTagChallenge: check,
    });
  };

  onChangeContent(html, content, draftId, draftImage) {
    let userVideoIds = [];
    let itemList = content.contentEditor
      ? content.contentEditor.itemList || []
      : [];
    itemList.forEach((element) => {
      element.itemList.forEach((rowElem) => {
        rowElem.itemList.forEach((colElem) => {
          colElem.itemList.forEach((itemElem) => {
            if (itemElem.type === "video") {
              userVideoIds.push(itemElem.attributes.id);
            }
          });
        });
      });
    });
    this.setState(
      {
        content: content,
        html: html,
        draftId: draftId,
        draftImage: draftImage,
        keyPageContent: Math.random(),
        userVideos: userVideoIds,
      },
      () => {
        this.onClickAddChallenge();
      }
    );
  }

  onChangeEmailContent(html, content) {
    this.setState({
      emailContent: content,
      emailHtml: html,
    });
  }

  async onClickAddChallenge() {
    let itemList = [];
    let challenge = this.state.challenge;

    challenge.challengeItemList.forEach((element) => {
      itemList.push({
        /*eslint-disable*/
        item_id: element.value._id,
        offset_days: element.offset_days,
        offset_date: element.offset_date,
        offset_type: element.offset_type,
        preview_days: element.preview_days,
        lock_days: element.lock_days,
        hide_on_lock: element.hide_on_lock,
        hide_before_release: element.hide_before_release,
        complete_item: element.complete_item,
        video_complete_item: element.video_complete_item,
        open_complete_item: element.open_complete_item,
        complete_item_btn: element.complete_item_btn,
        incomplete_item_btn: element.incomplete_item_btn,
        complete_item_points: element.complete_item_points,
        preview_allowed: element.preview_allowed,
        lock_allowed: element.lock_allowed,
        /*eslint-enable*/
      });
    });

    if (this.state.duplicateItems.length > 0) {
      itemList = itemList.concat(this.state.duplicateItems);
    }

    let queList = [];
    challenge.baseQuestionList.forEach((element) => {
      if (element.value) {
        queList.push(element.value._id);
      }
    });

    let rules = challenge.rules;

    if (challenge.name.trim().length === 0) {
      showNotification("error", "Challenge Name is required", 4000);
      return;
    }
    if (challenge.description.trim().length === 0) {
      showNotification("error", "Description is required", 4000);
      return;
    }
    if (challenge.shortName.trim().length === 0) {
      showNotification("error", "Short Name is required", 4000);
      return;
    }
    if (challenge.challengeDays.trim().length === 0) {
      challenge.challengeDays = "0";
    }
    if (
      !challenge.addActiveDays &&
      challenge.addActiveDays.trim().length === 0
    ) {
      challenge.addActiveDays = "1";
    }

    if (challenge.challengeDays) {
      let challengeDays = parseInt(challenge.challengeDays, 10);
      challenge["activeDays"] = challengeDays.toString();
    } else {
      challenge["activeDays"] = "0";
    }

    if (challenge.previewDays.trim().length === 0) {
      challenge.previewDays = "0";
    }

    let tagList = this.state.addTags
      .filter((d) => {
        return d._id !== null;
      })
      .map((i) => i._id);

    let formId = "";
    let selectedForm = this.state.selectedForm;
    let formType = "normal";

    if (selectedForm && selectedForm.value && selectedForm.value._id) {
      formId = selectedForm.value._id;
      formType = selectedForm.type;
    }

    let apiData = {
      challenge: "",
      category: challenge.category,
      status: "active",
      name: challenge.name,
      description: challenge.description,
      /*eslint-disable*/
      short_name: challenge.shortName,
      active_days: challenge.activeDays,
      add_active_days: challenge.addActiveDays,
      challenge_days: challenge.challengeDays,
      preview_days: challenge.previewDays,
      start_day_of_week: challenge.startDayOfWeek,
      feedback: this.state.isFeedback,
      video_list: challenge.videoList,
      base_question_list: queList,
      challenge_item_list: itemList,
      rules: rules,
      forever_open: this.state.isForeverOpen,
      html: this.state.html,
      content: this.state.content,
      type: challenge.type,
      duration_type: challenge.durationType,
      display_type: challenge.displayType,
      is_charts: this.state.isCharts,
      is_atq_charts: this.state.isAtqCharts,
      atq_charts: this.state.atqCharts,
      video_section_title: challenge.videoSectionTitle,
      is_leaderboard: this.state.isLeaderBoard,
      leaderboard_active_user_count: this.state.leaderboardActiveUserCount || 0,
      leaderboard_number_of_users: this.state.leaderboardNumberOfUsers,
      screenshot: challenge.screenshot,
      social_share: {
        share: this.state.isSocialShare,
        text: this.state.shareText,
        url: this.state.shareUrl,
        options: this.state.socialOptions,
      },
      tags: tagList,
      is_tag_challenge: this.state.isTagChallenge,
      priority: challenge.priority,
      group: challenge.group !== "Create New Group" ? challenge.group : "",
      is_discussion_board: this.state.isDiscussionBoard,
      discussion_board: this.state.discussionBoard,
      draft_id: this.state.draftId,
      draft_image: this.state.draftImage,
      form_id: formId,
      form_type: formType,
      form_tag_id: challenge.form_tag_id || "",
      atq_rules: this.state.atqRules,
      is_navigation: this.state.isShowNavigation,
      hide_category: this.state.isHideCategory,
      col_intro: this.state.colIntroSection,
      user_videos: this.state.userVideos,
      item_template_id: this.state.itemTemplateId,
      show_video_progress: this.state.showVideoProgress,
      /*eslint-enable*/
    };

    this.setState({ processing: true });

    if (this.state.editMode) {
      let result = await callUpdateChlg(challenge._id, apiData);

      this.setState({ processing: false, duplicateItems: [] });
      if (result._id) {
        showNotification("success", "Updated Successfully");
      }
      this.getChallengeInfo();
    } else {
      let chlgOp = new ChallengeOperations();
      let { rules, emailGenericTagId, challengeTemplate, formTagId } =
        await chlgOp.prepareChallengeData(
          challenge.name,
          {},
          this.state.editorType
        );

      apiData.rules = rules;
      apiData.content = processEditorContent(challengeTemplate.editor_content, {
        name: apiData.name,
        description: apiData.description,
        type: apiData.type,
      });
      apiData.html = challengeTemplate.html;
      apiData["email_group_id"] = emailGenericTagId;
      // apiData["base_question_list"] = [questionId];
      apiData["form_tag_id"] = formTagId;

      let result = await callCreateChlg(apiData);

      if (result._id) {
        await chlgOp.createAutoSignupSystemTag(challenge.name, result._id);
        showNotification("success", "Created Successfully", 2000);
        this.props.history.push(
          `/admin/edit-challenge?id=${result._id}&tab=content`
        );
        setTimeout(() => {
          window.location.reload();
          this.setState({ processing: false, duplicateItems: [] });
        }, 1000);
      } else {
        this.setState({ processing: false, duplicateItems: [] });
        if (result.tier) {
          showNotification("error", result.message, 5000);
        }
      }
    }
    this.getSystemTags();
    this.getEmailsFromGroup(this.state.challenge.email_group_id);
  }

  onClickAddQuestion() {
    let queList = this.state.challenge.baseQuestionList;
    if (!this.state.selectedQue.value.question) {
      showNotification("error", "Select the question first", 4000);
      return;
    }

    queList.push({
      name: this.state.selectedQue.value.name,
      question: this.state.selectedQue.value.question,
      description: this.state.selectedQue.value.description,
      value: this.state.selectedQue.value,
    });

    this.setState(
      {
        searchQuestionType: "existing",
        keySearchQuestionType: Math.random(),
        chlgQueList: queList,
        selectedQue: { value: "", label: "" },
        keyQuestion: Math.random(),
      },
      () => {
        this.onClickAddChallenge();
      }
    );
  }

  onClickCancelItem() {
    this.setState({
      selectedEditItemIndex: -1,
      itemLockDays: "",
      itemOffsetDays: 0,
      itemPreviewDays: "",
      isHideOnLock: false,
      isHideBeforeRelease: false,
      selectedItem: { value: "", label: "" },
      keyItem: Math.random(),
      keyHideOnLock: Math.random(),
      keyHideBeforeRelease: Math.random(),
      showAddedItems: true,
      isCompleteItem: false,
      isVideoCompleteItem: false,
      keyVideoCompleteItem: Math.random(),
      completeItemBtn: "",
      completeItemPoints: "10",
      isAllowedToBePreviewed: false,
      isAllowedToLock: false,
      keyAllowLock: Math.random(),
      keyAllowPreview: Math.random(),
      isTimeReleaseThisItem: false,
      showAdvancedOptionsItems: false,
    });
  }

  onClickAddItem() {
    let itemList = this.state.challenge.challengeItemList;
    if (this.state.itemOffsetDays.toString().trim().length === 0) {
      showNotification("error", "Item offset days are required", 4000);
      return;
    }
    if (!this.state.selectedItem.value.name) {
      showNotification("error", "Select the item first", 4000);
      return;
    }

    if (this.state.isCompleteItem) {
      if (this.state.completeItemPoints.toString().trim().length === 0) {
        showNotification("error", "Complete Item Points are required ", 4000);
        return;
      }
    }

    let item = {
      question: this.state.selectedItem.value.name,
      description: this.state.selectedItem.value.description,
      name: this.state.selectedItem.value.name,
      value: this.state.selectedItem.value,
      /*eslint-disable*/
      offset_days: this.state.itemOffsetDays,
      offset_date: this.state.itemOffsetDate,
      offset_type: this.state.itemOffsetType,
      lock_days: this.state.itemLockDays,
      preview_days: this.state.itemPreviewDays,
      hide_on_lock: this.state.isHideOnLock,
      hide_before_release: this.state.isHideBeforeRelease,
      complete_item: this.state.isCompleteItem,
      video_complete_item: this.state.isVideoCompleteItem,
      open_complete_item: this.state.isOpenCompleteItem,
      complete_item_btn: this.state.completeItemBtn,
      complete_item_points: this.state.completeItemPoints,
      lock_allowed: this.state.isAllowedToLock,
      preview_allowed: this.state.isAllowedToBePreviewed,
      delete_confirm: false,
      /*eslint-enable*/
    };

    if (this.state.selectedEditItemIndex >= 0) {
      itemList[this.state.selectedEditItemIndex] = item;
    } else {
      itemList.push(item);
    }

    let challenge = this.state.challenge;
    challenge.challengeItemList = itemList;

    this.setState(
      {
        challenge: challenge,
        // chlgItemList: itemList,
        itemLockDays: "",
        itemOffsetDays: 0,
        itemOffsetDate: "",
        itemOffsetType: "days",
        keyItemOffsetType: Math.random(),
        itemPreviewDays: "",
        isHideOnLock: false,
        isHideBeforeRelease: false,
        selectedEditItemIndex: -1,
        selectedItem: { value: "", label: "" },
        keyItem: Math.random(),
        keyHideBeforeRelease: Math.random(),
        keyHideOnLock: Math.random(),
        showAddedItems: true,
        isCompleteItem: false,
        isVideoCompleteItem: false,
        keyVideoCompleteItem: Math.random(),
        isOpenCompleteItem: false,
        keyOpenCompleteItem: Math.random(),
        completeItemBtn: "Task Completed",
        completeItemPoints: "10",
        keyCompleteItem: Math.random(),
        isAllowedToLock: false,
        isAllowedToBePreviewed: false,
        keyAllowLock: Math.random(),
        keyAllowPreview: Math.random(),
        isTimeReleaseThisItem: false,
        searchItemType: "new",
        keySearchItemType: Math.random(),
        showAdvancedOptionsItems: false,
      },
      () => {
        this.onClickAddChallenge();
      }
    );
  }

  renderDays() {
    let returnVals = [];
    let days = this.state.days;
    returnVals.push(<option value={""} key={0} />);
    for (let i = 0; i < days.length; i++) {
      returnVals.push(
        <option value={days[i]} key={i + 1}>
          {days[i]}
        </option>
      );
    }
    return returnVals;
  }

  async checkActionType(tagId) {
    if (
      this.state.actionType !== "add-email" &&
      this.state.actionType !== "add-notification" &&
      this.state.actionType !== "add-atq" &&
      this.state.actionType !== "add-sms"
    ) {
      if (this.state.tagValue.trim().length === 0) {
        showNotification("error", "Tag is required", 4000);
        return;
      }
    }

    let actionType = this.state.actionType;
    let emailId = "";
    let notiId = "";
    let queId = "";
    let smsId = "";
    let tagIdN = "";
    let tagValue = "";

    if (this.state.actionType === "add-email") {
      let dOpe = new ChallengeOperations();
      /*eslint-disable*/
      let response = await dOpe.createChallengeRuleEmail(
        this.state.challenge.name,
        this.state.onSpecificDay,
        this.state.emailName,
        this.state.emailContent,
        this.state.emailHtml,
        {
          send_time_type: this.state.sendTimeType,
          selected_hour: this.state.selectedHour,
          selected_mins: this.state.selectedMins,
          selected_time_type: this.state.selectedTimeType,
          rule: {
            day: this.state.onSpecificDay,
            challengeId: this.state.challengeId,
          },
        }
      );
      /*eslint-enable*/
      tagValue = response.tagValue;
      tagIdN = response.tagId;
      emailId = response.emailId;
    } else if (this.state.actionType === "add-notification") {
      let dOpe = new ChallengeOperations();
      /*eslint-disable*/
      let response = await dOpe.createChallengeRuleNotification(
        this.state.challenge.name,
        this.state.onSpecificDay,
        this.state.notiTitle,
        this.state.notiBody,
        this.state.notiScreen,
        {
          send_time_type: this.state.sendTimeType,
          selected_hour: this.state.selectedHour,
          selected_mins: this.state.selectedMins,
          selected_time_type: this.state.selectedTimeType,
          challenge_id: this.state.notiChallengeId,
          challenge_item_id: this.state.notiChallengeItemId,
        }
      );
      /*eslint-enable*/
      tagValue = response.tagValue;
      tagIdN = response.tagId;
      notiId = response.notiId;
    } else if (this.state.actionType === "add-sms") {
      let dOpe = new ChallengeOperations();
      /*eslint-disable*/
      let response = await dOpe.createChallengeRuleSMS(
        this.state.challenge.name,
        this.state.onSpecificDay,
        this.state.smsName,
        this.state.smsMessage,
        {
          send_time_type: this.state.sendTimeType,
          selected_hour: this.state.selectedHour,
          selected_mins: this.state.selectedMins,
          selected_time_type: this.state.selectedTimeType,
        }
      );
      /*eslint-enable*/
      tagValue = response.tagValue;
      tagIdN = response.tagId;
      smsId = response.smsId;
    } else if (this.state.actionType === "add-atq") {
      tagValue = this.state.queTagValue;
      tagIdN = this.state.queTagId;
      queId = this.state.queQueId;
    } else {
      tagIdN = tagId[0]._id;
      tagValue = this.state.tagValue;
    }

    return { actionType, emailId, notiId, queId, tagIdN, tagValue, smsId };
  }

  async onClickAddRule() {
    let rules = this.state.challenge.rules;
    let tagId = this.state.tags.filter((d) => {
      return d.name === this.state.tagValue;
    });
    if (!tagId) {
      showNotification("error", "Tag not found", 4000);
      return;
    }
    if (this.state.ruleType.trim().length === 0) {
      showNotification("error", "Rule type is required", 4000);
      return;
    }
    if (this.state.ruleType === "on_specific_day") {
      if (this.state.onSpecificDay.trim().length === 0) {
        showNotification("error", "Enter Day is required", 4000);
        return;
      }

      let { actionType, emailId, notiId, queId, tagIdN, tagValue, smsId } =
        await this.checkActionType(tagId);

      rules.push({
        type: "on_specific_day",
        day: this.state.onSpecificDay,
        action: actionType,
        /*eslint-disable*/
        tag_id: tagIdN,
        tag_name: tagValue,
        email_linked: emailId,
        notification_linked: notiId,
        question_linked: queId,
        sms_linked: smsId,
        /*eslint-enable*/
      });
    } else if (this.state.ruleType === "days_not_tracked") {
      if (this.state.daysNotTracked.trim().length === 0) {
        showNotification("error", "Day Not Tracked is required", 4000);
        return;
      }
      let { actionType, emailId, notiId, queId, tagIdN, tagValue, smsId } =
        await this.checkActionType(tagId);

      rules.push({
        type: "has_not_tracked",
        days: this.state.daysNotTracked,
        action: actionType,
        /*eslint-disable*/
        tag_id: tagIdN,
        tag_name: tagValue,
        email_linked: emailId,
        notification_linked: notiId,
        question_linked: queId,
        sms_linked: smsId,
        /*eslint-enable*/
      });
    } else if (this.state.ruleType === "on_specific_date") {
      let { actionType, emailId, notiId, queId, tagIdN, tagValue, smsId } =
        await this.checkActionType(tagId);

      rules.push({
        type: "on_specific_date",
        date: this.state.onSpecificDate,
        action: actionType,
        /*eslint-disable*/
        tag_id: tagIdN,
        tag_name: tagValue,
        email_linked: emailId,
        notification_linked: notiId,
        question_linked: queId,
        sms_linked: smsId,
        /*eslint-enable*/
      });
    } else if (this.state.ruleType === "on_weekday") {
      let { actionType, emailId, notiId, queId, tagIdN, tagValue, smsId } =
        await this.checkActionType(tagId);

      rules.push({
        type: "on_weekday",
        /*eslint-disable*/
        week_day: this.state.onWeekday,
        action: actionType,
        tag_id: tagIdN,
        tag_name: tagValue,
        email_linked: emailId,
        notification_linked: notiId,
        question_linked: queId,
        sms_linked: smsId,
        /*eslint-enable*/
      });
    } else if (
      this.state.ruleType === "at_signup" ||
      this.state.ruleType === "on_cancel" ||
      this.state.ruleType === "on_last_day"
    ) {
      let { actionType, emailId, notiId, queId, tagIdN, tagValue, smsId } =
        await this.checkActionType(tagId);

      rules.push({
        type: this.state.ruleType,
        action: actionType,
        /*eslint-disable*/
        tag_id: tagIdN,
        tag_name: tagValue,
        email_linked: emailId,
        notification_linked: notiId,
        question_linked: queId,
        sms_linked: smsId,
        /*eslint-enable*/
      });
    } else if (this.state.ruleType === "per_completed") {
      let { actionType, emailId, notiId, queId, tagIdN, tagValue, smsId } =
        await this.checkActionType(tagId);

      rules.push({
        type: this.state.ruleType,
        action: actionType,
        /*eslint-disable*/
        tag_id: tagIdN,
        tag_name: tagValue,
        email_linked: emailId,
        notification_linked: notiId,
        question_linked: queId,
        percentage: this.state.perCompleted,
        sms_linked: smsId,
        /*eslint-enable*/
      });
    } else if (this.state.ruleType === "no_activity") {
      let { actionType, emailId, notiId, queId, tagIdN, tagValue, smsId } =
        await this.checkActionType(tagId);

      rules.push({
        type: this.state.ruleType,
        action: actionType,
        /*eslint-disable*/
        tag_id: tagIdN,
        tag_name: tagValue,
        email_linked: emailId,
        notification_linked: notiId,
        question_linked: queId,
        no_activity_days: this.state.noActivityDays,
        sms_linked: smsId,
        /*eslint-enable*/
      });
    }

    let challenge = this.state.challenge;
    let newRules = await this.prepareRules(rules);
    challenge.rules = newRules;
    this.setState(
      {
        tagValue: "",
        challenge: challenge,
        keyTag: Math.random(),
        emailContent: {},
        emailHtml: "",
        emailName: "",
        notiTitle: "",
        notiBody: "",
        notiScreen: "",
        notiChallengeId: "",
        notiChallengeItemId: "",
        smsName: "",
        smsMessage: "",
        noActivityDays: "",
      },
      () => {
        this.onClickAddChallenge();
      }
    );
  }

  onClickDeleteRule(index) {
    let challenge = this.state.challenge;
    let rules = challenge.rules;
    rules.splice(index - 1, 1);
    challenge.rules = rules;

    this.setState({ challenge: challenge }, () => {
      this.onClickAddChallenge();
    });
  }

  onClickDeleteAtqRule(index) {
    let atqRules = this.state.atqRules;
    atqRules.splice(index - 1, 1);
    this.setState({ atqRules }, () => {
      this.onClickAddChallenge();
    });
  }

  onChangeAddTags = (tags) => {
    this.setState({ addTags: tags });
  };

  onClickRuleTag(tag) {
    this.setState(
      {
        popupType: "addEmailFromChallenge",
        popupLabel: "Add New Email",
        systemTagId: tag.tag_id,
        popupKey: Math.random(),
      },
      () => {
        openPagePopupModal("addEmailFromChallenge");
      }
    );
    // let url = `/admin/add-email?tId=${tag.tag_id}&cId=${this.state.challenge._id}`;
    // window.open(url, "_blank");
  }

  onClickEditGroup() {
    let selectedGroup = this.state.groups.find(
      (d) => d._id === this.state.challenge.group
    );
    this.setState(
      {
        groupName: selectedGroup.name,
        groupPriority: selectedGroup.priority || "",
      },
      () => {
        $(
          ".modalAlertOverlay.updateCategoryModalOverlay,.modalAlert.updateCategoryAlert"
        ).fadeIn(200);
      }
    );
  }

  async onClickCreateGroup() {
    let groupName = this.state.groupName.trim();

    if (groupName.trim().length === 0) {
      showNotification("error", "Group Name is required", 4000);
      return;
    }
    let eGroup = this.state.groups.filter((d) => {
      return d.name.toLowerCase() === groupName.toLowerCase();
    });
    if (eGroup.length > 0) {
      showNotification("error", "Group already exists");
      return;
    }

    let data = [
      {
        name: this.state.groupName,
        priority: this.state.groupPriority,
        type: "challenge",
      },
    ];

    let response = await callCreateGenericTag(data);
    if (response.length > 0) {
      this.setState(
        { groupName: "", groupPriority: "", showCreateGroup: false },
        () => {
          this.getGroups();
          this.setState({ groupName: response[0].id });
        }
      );
    }
  }

  onClickCancelFromUpdateGroupModal() {
    $(
      ".modalAlertOverlay.updateCategoryModalOverlay,.modalAlert.updateCategoryAlert"
    ).fadeOut(200);
  }

  async onClickUpdateFromUpdateGroupModel() {
    if (this.state.groupName.trim().length === 0) {
      showNotification("error", "Group name is required", 4000);
      return;
    }
    let updatedGroup = await callUpdateGenericTag(this.state.challenge.group, {
      name: this.state.groupName,
      priority: this.state.groupPriority,
    });
    if (updatedGroup._id) {
      this.getGroups();
      $(
        ".modalAlertOverlay.updateCategoryModalOverlay,.modalAlert.updateCategoryAlert"
      ).fadeOut(200);
    }
  }

  onSelectSendTime(item, id) {
    this.setState({ [id]: item[Object.keys(item)[0]].value });
  }

  renderHours() {
    var returnVals = [];
    for (let i = 1; i <= 12; i++) {
      let hour = i.toString().length !== 1 ? i : `0${i}`;
      returnVals.push(
        <option value={hour} key={hour}>
          {hour}
        </option>
      );
    }
    return returnVals;
  }

  renderMins() {
    var returnVals = [];
    for (let i = 0; i <= 59; i = i + 1) {
      let min = i.toString().length !== 1 ? i : `0${i}`;
      returnVals.push(
        <option value={min} key={min}>
          {min}
        </option>
      );
    }
    return returnVals;
  }

  onSelectScreen(item, id) {
    let type = item[Object.keys(item)[0]].value;
    this.setState({ notiScreen: type });
  }

  onSelectItemOffsetType(item, id) {
    let type = item[Object.keys(item)[0]].value;
    this.setState({ itemOffsetType: type });
  }

  renderScreen() {
    return (
      <>
        <div className="gap20" />
        <div className="radioBHolder">
          <div className="havingHelpText">
            <p>Screen</p>
          </div>
          <div className="tabsHolder radioTabs">
            <RadioTag
              onCheckChange={this.onSelectScreen.bind(this)}
              labelList={[
                { name: "Dashboard", value: "dashboard" },
                { name: "Challenge", value: "challenge" },
                { name: "Messages", value: "messages" },
                { name: "Resources", value: "resources" },
                { name: "Accountability", value: "atq" },
                { name: "Charts", value: "charts" },
                { name: "Profile", value: "profile" },
                { name: "Events", value: "events" },
              ]}
              id={"screen"}
              selectedList={[this.state.notiScreen]}
              selectType={"value"}
            />
          </div>
        </div>

        {this.state.challenges.length > 0 &&
          this.state.notiScreen === "challenge" && (
            <div>
              <div className="gap20" />
              <div
                className={
                  this.state.notiChallengeId !== ""
                    ? "mdInput mdFocussed"
                    : "mdInput"
                }>
                <label>Select Challenge</label>
                <select
                  name="notiChallengeId"
                  value={this.state.notiChallengeId}
                  onChange={(event) => this.handleInputChange(event)}>
                  <option value={""} key={"1"}></option>
                  {this.state.challenges.map((i) => (
                    <option value={i._id} key={i._id}>
                      {i.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          )}
        {this.state.challengeItems.length > 0 &&
          this.state.notiScreen === "challenge" && (
            <div>
              <div
                className={
                  this.state.notiChallengeItemId !== ""
                    ? "mdInput mdFocussed"
                    : "mdInput"
                }>
                <label>Select Challenge Item</label>
                <select
                  name="notiChallengeItemId"
                  value={this.state.notiChallengeItemId}
                  onChange={(event) => this.handleInputChange(event)}>
                  <option value={""} key={"1"}></option>
                  {this.state.challengeItems.map((i) => (
                    <option value={i._id} key={i._id}>
                      {i.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="gap20" />
            </div>
          )}

        <div className="gap10" />
      </>
    );
  }

  renderSendTime() {
    return (
      <>
        <div className="gap10" />
        <div className="radioBHolder">
          <p>Send Time</p>
          <div className="tabsHolder radioTabs">
            <RadioTag
              onCheckChange={this.onSelectSendTime.bind(this)}
              labelList={[
                {
                  name: "Immediately",
                  value: "send",
                },
                { name: "Specified Time", value: "time" },
              ]}
              id={"sendTimeType"}
              selectedList={[this.state.sendTimeType]}
              selectType={"value"}
              key={this.state.keySendTime}
            />
          </div>
        </div>
        {this.state.sendTimeType === "time" && (
          <>
            <div className="row">
              <div className="col-sm-3">
                <div
                  className={
                    this.state.selectedHour !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Select Hour</label>
                  <select
                    name="selectedHour"
                    value={this.state.selectedHour}
                    onChange={(event) => this.handleInputChange(event)}>
                    <option value="" />
                    {this.renderHours()}
                  </select>
                </div>
                <div className="gap20" />
              </div>
              <div className="col-sm-3">
                <div
                  className={
                    this.state.selectedMins !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Select Minute</label>
                  <select
                    name="selectedMins"
                    value={this.state.selectedMins}
                    onChange={(event) => this.handleInputChange(event)}>
                    <option value="" />
                    {this.renderMins()}
                  </select>
                </div>
                <div className="gap20" />
              </div>
              <div className="col-sm-3">
                <div
                  className={
                    this.state.selectedTimeType !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Select Type</label>
                  <select
                    name="selectedTimeType"
                    value={this.state.selectedTimeType}
                    onChange={(event) => this.handleInputChange(event)}>
                    <option value="" />
                    <option value="AM">AM</option>
                    <option value="PM">PM</option>
                  </select>
                </div>
                <div className="gap20" />
              </div>
            </div>
          </>
        )}
      </>
    );
  }

  renderUpdateGroupModal() {
    let group = this.state.challenge.group;

    if (!group) {
      return null;
    }

    return (
      <div>
        <div className="modalAlertOverlay updateCategoryModalOverlay" />
        <div className="modalAlert updateCategoryAlert">
          <div className="clearfix">
            <div className="pull-left">
              <p>Edit Group</p>
            </div>
          </div>

          <div className="container-fluid">
            <div>
              <div
                className={
                  this.state.groupName !== "" ? "mdInput mdFocussed" : "mdInput"
                }>
                <label>Group Name</label>
                <input
                  type="text"
                  name="groupName"
                  value={this.state.groupName}
                  onChange={(event) => this.handleInputChange(event)}
                />
              </div>
              <div className="gap20" />
            </div>

            <div>
              <div
                className={
                  this.state.groupPriority !== ""
                    ? "mdInput mdFocussed"
                    : "mdInput"
                }>
                <label>Group Priority</label>
                <input
                  type="number"
                  min={1}
                  name="groupPriority"
                  value={this.state.groupPriority}
                  onChange={(event) => this.handleInputChange(event)}
                />
              </div>
              <div className="gap20" />
            </div>
          </div>
          <div className="gap20" />
          <div className="alertFooterBtns">
            <button
              onClick={this.onClickCancelFromUpdateGroupModal.bind(this)}
              className="btn btn-default dismissThisModal margin-right-10">
              Cancel
            </button>
            <button
              onClick={this.onClickUpdateFromUpdateGroupModel.bind(this)}
              className="btn btn-primary dismissThisModal">
              Update
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderGroupComponent() {
    let groups = this.state.groups;
    let labelList = [];
    groups.forEach((element) => {
      labelList.push({
        name: element.name,
        value: element._id,
      });
    });
    labelList.push({
      name: "Create New Group",
      value: "Create New Group",
    });
    return (
      <div className="col-sm-12">
        <div className="card">
          <div className="container-fluid">
            {labelList.length > 0 && (
              <div className="row" key={this.state.keyGroups}>
                <div className="col-md-12">
                  <div className="gap10" />
                  <label>Select Group</label>
                  <div className="tabsHolder radioTabs">
                    <RadioTag
                      onCheckChange={this.onSelectGroup.bind(this)}
                      labelList={labelList}
                      id={"group"}
                      selectedList={[this.state.challenge.group]}
                      selectType={"value"}
                    />
                  </div>
                  <div className="gap20" />
                </div>
              </div>
            )}

            <VelocityTransitionGroup
              enter={{ animation: "slideDown" }}
              leave={{ animation: "slideUp" }}>
              {this.state.showCreateGroup && (
                <>
                  <div className="row">
                    <div className="col-sm-4">
                      <div
                        className={
                          this.state.groupName !== ""
                            ? "mdInput mdFocussed"
                            : "mdInput"
                        }>
                        <label>Group Name</label>
                        <input
                          type="text"
                          name="groupName"
                          value={this.state.groupName}
                          onChange={(event) => this.handleInputChange(event)}
                        />
                      </div>
                      <div className="gap20" />
                    </div>
                    <div className="col-sm-4">
                      <div
                        className={
                          this.state.groupPriority !== ""
                            ? "mdInput mdFocussed"
                            : "mdInput"
                        }>
                        <label>Group Priority</label>
                        <input
                          type="number"
                          name="groupPriority"
                          value={this.state.groupPriority}
                          onChange={(event) => this.handleInputChange(event)}
                        />
                      </div>
                      <div className="gap20" />
                    </div>
                    <div className="col-sm-4">
                      <div className="gap30" />
                      <div className="gap10" />
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={this.onClickCreateGroup.bind(this)}>
                        Create Group
                      </button>
                    </div>
                  </div>
                </>
              )}
              {this.state.challenge.group !== "" &&
                !this.state.showCreateGroup && (
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={this.onClickEditGroup.bind(this)}>
                    Edit Selected Group
                  </button>
                )}
              <div className="gap10" />
            </VelocityTransitionGroup>
          </div>
        </div>
      </div>
    );
  }

  async prepareAtqRules(rawRules) {
    let rules = rawRules || [];
    let eRules = [];

    for (let i = 0; i < rules.length; i++) {
      let element = rules[i];

      if (element.question_linked) {
        let response = await callGetQuestionsUsingQuery(
          {
            query: {
              _id: element.question_linked,
              $select: ["_id", "name"],
            },
          },
          false
        );
        if (response.data.length > 0) {
          element.questionName = response.data[0].name;
        }
      }
      eRules.push(element);
    }
    return eRules;
  }

  async prepareRules(rawRules) {
    let rules = [];
    let hasNotTracked = rawRules.filter((d) => d.type === "has_not_tracked");
    let onCancel = rawRules.filter((d) => d.type === "on_cancel");
    let atSignup = rawRules.filter((d) => d.type === "at_signup");
    let onSpecificDay = rawRules.filter((d) => d.type === "on_specific_day");
    let onLastDay = rawRules.filter((d) => d.type === "on_last_day");
    let onWeekDay = rawRules.filter((d) => d.type === "on_weekday");
    let onSpecificDate = rawRules.filter((d) => d.type === "on_specific_date");
    let perCompleted = rawRules.filter((d) => d.type === "per_completed");
    let noActivity = rawRules.filter((d) => d.type === "no_activity");

    onSpecificDay.sort((a, b) => {
      return parseInt(a.day) - parseInt(b.day);
    });

    rules = rules.concat(hasNotTracked);
    rules = rules.concat(onCancel);
    rules = rules.concat(atSignup);
    rules = rules.concat(onSpecificDay);
    rules = rules.concat(onLastDay);
    rules = rules.concat(onWeekDay);
    rules = rules.concat(onSpecificDate);
    rules = rules.concat(perCompleted);
    rules = rules.concat(noActivity);

    let eRules = [];

    for (let i = 0; i < rules.length; i++) {
      let element = rules[i];
      if (element.email_linked) {
        let response = await callGetEmails(
          {
            query: {
              _id: element.email_linked,
            },
          },
          false
        );
        if (response.data.length > 0) {
          element.emailName = response.data[0].name;
        }
      }
      if (element.question_linked) {
        let response = await callGetQuestionsUsingQuery(
          {
            query: {
              _id: element.question_linked,
              $select: ["_id", "name"],
            },
          },
          false
        );
        if (response.data.length > 0) {
          element.questionName = response.data[0].name;
        }
      }
      if (element.notification_linked) {
        let response = await callGetNotificationsUsingQuery(
          {
            query: {
              _id: element.notification_linked,
              $select: ["_id", "title"],
            },
          },
          false
        );
        if (response.data.length > 0) {
          element.notificationTitle = response.data[0].title;
        }
      }
      if (element.sms_linked) {
        let response = await callGetTextMessagesByQuery(
          {
            query: {
              _id: element.sms_linked,
            },
          },
          false
        );
        if (response.data.length > 0) {
          element.smsName = response.data[0].name;
        }
      }
      eRules.push(element);
    }

    return eRules;
  }

  onClickSystemTag(tag) {
    this.setState(
      {
        popupType: "editTag",
        popupLabel: "Edit Tag",
        popupKey: Math.random(),
        popupSystemTag: tag,
      },
      () => {
        openPagePopupModal("editTag");
      }
    );
  }

  getSystemTagName(element) {
    let tagList = this.state.tags.filter((d) => d._id === element.tag_id);
    const tagView = (
      <label
        onClick={() => this.onClickSystemTag(tagList[0])}
        className="link pointer-click">
        (Edit Tag)
      </label>
    );

    if (element.emailName) {
      return (
        <label>
          {element.emailName} {tagView}
        </label>
      );
    }
    if (element.questionName) {
      return (
        <label>
          {element.questionName} {tagView}
        </label>
      );
    }
    if (element.notificationTitle) {
      return (
        <label>
          {element.notificationTitle} {tagView}
        </label>
      );
    }
    if (element.smsName) {
      return (
        <label>
          {element.smsName} {tagView}
        </label>
      );
    }
    if (tagList.length > 0) {
      return (
        <label>
          {tagList[0].name} {tagView}
        </label>
      );
    }
    return "";
  }

  renderRules() {
    let rules = this.state.challenge.rules;
    let returnVals = [];
    let i = 0;
    let isGroupId = this.state.challenge.email_group_id ? true : false;

    const ButtonDelete = (props) => (
      <button
        onClick={this.onClickDeleteRule.bind(this, props.i)}
        className="btn btn-danger btn-sm border-0"
        style={{ float: "right" }}>
        <i className="fa fa-trash" />
      </button>
    );

    const ButtonEdit = (props) => (
      <button
        onClick={props.onClick}
        className="btn btn-default btn-sm border-0"
        style={{ float: "right" }}>
        <i className="fa fa-pencil" />
      </button>
    );

    const ButtonMail = (props) => (
      <button
        onClick={this.onClickRuleTag.bind(this, props.tag)}
        className="btn btn-default btn-sm margin-right-10"
        style={{ float: "right" }}>
        <i className="fa fa-envelope" /> &nbsp;&nbsp; Create Email with This
        Rule
      </button>
    );

    if (rules.length > 0) {
      rules.forEach((element) => {
        i++;
        let labelTag = "";
        let isNotification = false;
        let isSMS = false;
        switch (element.action) {
          case "add-tag":
            labelTag = "Add A Tag";
            break;
          case "add-email":
            labelTag = "Add Email";
            break;
          case "add-sms":
            labelTag = "Add SMS";
            isSMS = true;
            break;
          case "add-notification":
            labelTag = "Add Notification";
            isNotification = true;
            break;
          case "add-atq":
            labelTag = "Add Accountability";
            break;

          default:
            labelTag = "Inactivate A Tag";
            break;
        }
        if (element.type === "has_not_tracked") {
          returnVals.push(
            <div key={i}>
              Has Not Tracked : {element.days} days, {labelTag}:{" "}
              {this.getSystemTagName(element)}
              {element.lock !== true && <ButtonDelete i={i} />}
              {!isSMS &&
                !isNotification &&
                isGroupId &&
                !element.email_linked && <ButtonMail tag={element} />}
            </div>
          );
        } else if (element.type === "on_specific_day") {
          returnVals.push(
            <div key={i}>
              On Day : {element.day}, {labelTag} :{" "}
              {this.getSystemTagName(element)}
              {element.lock !== true && <ButtonDelete i={i} />}
              {element.email_linked && (
                <ButtonEdit
                  onClick={this.onClickEditEmail.bind(this, {
                    _id: element.email_linked,
                  })}
                />
              )}
              {element.notification_linked && (
                <ButtonEdit
                  onClick={this.onClickEditNotification.bind(
                    this,
                    element.notification_linked
                  )}
                />
              )}
              {element.question_linked && (
                <ButtonEdit
                  onClick={this.onClickEditQuestion.bind(
                    this,
                    element.question_linked
                  )}
                />
              )}
              {element.sms_linked && (
                <ButtonEdit
                  onClick={this.onClickEditSMS.bind(this, element.sms_linked)}
                />
              )}
              {!isSMS &&
                !isNotification &&
                isGroupId &&
                !element.email_linked && <ButtonMail tag={element} />}
            </div>
          );
        } else if (element.type === "at_signup") {
          returnVals.push(
            <div key={i}>
              At Signup, {labelTag} : {this.getSystemTagName(element)}
              {element.lock !== true && <ButtonDelete i={i} />}
              {element.email_linked && (
                <ButtonEdit
                  onClick={this.onClickEditEmail.bind(this, {
                    _id: element.email_linked,
                  })}
                />
              )}
              {element.notification_linked && (
                <ButtonEdit
                  onClick={this.onClickEditNotification.bind(
                    this,
                    element.notification_linked
                  )}
                />
              )}
              {element.question_linked && (
                <ButtonEdit
                  onClick={this.onClickEditQuestion.bind(
                    this,
                    element.question_linked
                  )}
                />
              )}
              {element.sms_linked && (
                <ButtonEdit
                  onClick={this.onClickEditSMS.bind(this, element.sms_linked)}
                />
              )}
              {!isSMS &&
                !isNotification &&
                isGroupId &&
                !element.email_linked && <ButtonMail tag={element} />}
            </div>
          );
        } else if (element.type === "on_cancel") {
          returnVals.push(
            <div key={i}>
              On Cancel, {labelTag} : {this.getSystemTagName(element)}
              {element.lock !== true && <ButtonDelete i={i} />}
              {element.email_linked && (
                <ButtonEdit
                  onClick={this.onClickEditEmail.bind(this, {
                    _id: element.email_linked,
                  })}
                />
              )}
              {element.notification_linked && (
                <ButtonEdit
                  onClick={this.onClickEditNotification.bind(
                    this,
                    element.notification_linked
                  )}
                />
              )}
              {element.question_linked && (
                <ButtonEdit
                  onClick={this.onClickEditQuestion.bind(
                    this,
                    element.question_linked
                  )}
                />
              )}
              {element.sms_linked && (
                <ButtonEdit
                  onClick={this.onClickEditSMS.bind(this, element.sms_linked)}
                />
              )}
              {!isSMS &&
                !isNotification &&
                isGroupId &&
                !element.email_linked && <ButtonMail tag={element} />}
            </div>
          );
        } else if (element.type === "on_last_day") {
          returnVals.push(
            <div key={i}>
              On Last Day, {labelTag} : {this.getSystemTagName(element)}
              {element.lock !== true && <ButtonDelete i={i} />}
              {element.email_linked && (
                <ButtonEdit
                  onClick={this.onClickEditEmail.bind(this, {
                    _id: element.email_linked,
                  })}
                />
              )}
              {element.notification_linked && (
                <ButtonEdit
                  onClick={this.onClickEditNotification.bind(
                    this,
                    element.notification_linked
                  )}
                />
              )}
              {element.question_linked && (
                <ButtonEdit
                  onClick={this.onClickEditQuestion.bind(
                    this,
                    element.question_linked
                  )}
                />
              )}
              {element.sms_linked && (
                <ButtonEdit
                  onClick={this.onClickEditSMS.bind(this, element.sms_linked)}
                />
              )}
              {!isSMS &&
                !isNotification &&
                isGroupId &&
                !element.email_linked && <ButtonMail tag={element} />}
            </div>
          );
        } else if (element.type === "on_specific_date") {
          returnVals.push(
            <div key={i}>
              On Date: {moment(element.date).format("Do MMM YYYY")}, {labelTag}{" "}
              : {this.getSystemTagName(element)}
              {element.lock !== true && <ButtonDelete i={i} />}
              {element.email_linked && (
                <ButtonEdit
                  onClick={this.onClickEditEmail.bind(this, {
                    _id: element.email_linked,
                  })}
                />
              )}
              {element.notification_linked && (
                <ButtonEdit
                  onClick={this.onClickEditNotification.bind(
                    this,
                    element.notification_linked
                  )}
                />
              )}
              {element.question_linked && (
                <ButtonEdit
                  onClick={this.onClickEditQuestion.bind(
                    this,
                    element.question_linked
                  )}
                />
              )}
              {element.sms_linked && (
                <ButtonEdit
                  onClick={this.onClickEditSMS.bind(this, element.sms_linked)}
                />
              )}
              {!isSMS &&
                !isNotification &&
                isGroupId &&
                !element.email_linked && <ButtonMail tag={element} />}
            </div>
          );
        } else if (element.type === "on_weekday") {
          returnVals.push(
            <div key={i}>
              On Weekday: {element.week_day}, {labelTag} :{" "}
              {this.getSystemTagName(element)}
              {element.lock !== true && <ButtonDelete i={i} />}
              {element.email_linked && (
                <ButtonEdit
                  onClick={this.onClickEditEmail.bind(this, {
                    _id: element.email_linked,
                  })}
                />
              )}
              {element.notification_linked && (
                <ButtonEdit
                  onClick={this.onClickEditNotification.bind(
                    this,
                    element.notification_linked
                  )}
                />
              )}
              {element.question_linked && (
                <ButtonEdit
                  onClick={this.onClickEditQuestion.bind(
                    this,
                    element.question_linked
                  )}
                />
              )}
              {element.sms_linked && (
                <ButtonEdit
                  onClick={this.onClickEditSMS.bind(this, element.sms_linked)}
                />
              )}
              {!isSMS &&
                !isNotification &&
                isGroupId &&
                !element.email_linked && <ButtonMail tag={element} />}
            </div>
          );
        } else if (element.type === "per_completed") {
          returnVals.push(
            <div key={i}>
              When {element.percentage}% completed, {labelTag} :{" "}
              {this.getSystemTagName(element)}
              {element.lock !== true && <ButtonDelete i={i} />}
            </div>
          );
        } else if (element.type === "no_activity") {
          returnVals.push(
            <div key={i}>
              When no activity for {element.no_activity_days} days, {labelTag} :{" "}
              {this.getSystemTagName(element)}
              {element.lock !== true && <ButtonDelete i={i} />}
            </div>
          );
        }
      });
    }
    return returnVals;
  }

  onClickAtqRule = (atqRule) => {
    this.setState({
      atqRuleType: atqRule.type,
      onSpecificAtqDay: atqRule.day,
      selectedAtqRule: atqRule,
    });
  };

  onClickSaveAtqRule() {
    let rules = this.state.atqRules;
    rules.forEach((element) => {
      if (
        element.question_linked === this.state.selectedAtqRule.question_linked
      ) {
        element.day = this.state.onSpecificAtqDay;
        element.type = this.state.atqRuleType;
      }
    });
    this.setState({ atqRules: rules, selectedAtqRule: {} }, () => {
      this.onClickAddChallenge();
    });
  }

  renderAtqRules() {
    let rules = this.state.atqRules;
    let returnVals = [];
    let i = 0;

    const ButtonDelete = (props) => (
      <button
        onClick={this.onClickDeleteAtqRule.bind(this, props.i)}
        className="btn btn-danger btn-sm border-0"
        style={{ float: "right" }}>
        <i className="fa fa-trash" />
      </button>
    );

    const ButtonEdit = (props) => (
      <button
        onClick={props.onClick}
        className="btn btn-default btn-sm border-0"
        style={{ float: "right" }}>
        <i className="fa fa-pencil" />
      </button>
    );

    const ButtonEditRule = (props) => (
      <button
        onClick={props.onClick}
        className="btn btn-default btn-sm border-0"
        style={{ float: "right" }}>
        <i className="fa fa-cog" />
      </button>
    );

    if (rules.length > 0) {
      rules.forEach((element) => {
        i++;
        let labelTag = "Add Accountability";
        if (element.type === "on_specific_day") {
          returnVals.push(
            <div key={i}>
              On Day : {element.day}, {labelTag} :{" "}
              {this.getSystemTagName(element)}
              <ButtonDelete i={i} />
              {element.question_linked && (
                <ButtonEdit
                  onClick={this.onClickEditQuestion.bind(
                    this,
                    element.question_linked
                  )}
                />
              )}
              <ButtonEditRule
                onClick={this.onClickAtqRule.bind(this, element)}
              />
            </div>
          );
        } else if (element.type === "at_signup") {
          returnVals.push(
            <div key={i}>
              At Signup, {labelTag} : {this.getSystemTagName(element)}
              <ButtonDelete i={i} />
              {element.question_linked && (
                <ButtonEdit
                  onClick={this.onClickEditQuestion.bind(
                    this,
                    element.question_linked
                  )}
                />
              )}
              <ButtonEditRule
                onClick={this.onClickAtqRule.bind(this, element)}
              />
            </div>
          );
        } else if (element.type === "on_cancel") {
          returnVals.push(
            <div key={i}>
              On Cancel, {labelTag} : {this.getSystemTagName(element)}
              <ButtonDelete i={i} />
              {element.question_linked && (
                <ButtonEdit
                  onClick={this.onClickEditQuestion.bind(
                    this,
                    element.question_linked
                  )}
                />
              )}
              <ButtonEditRule
                onClick={this.onClickAtqRule.bind(this, element)}
              />
            </div>
          );
        }
      });
    }
    return returnVals;
  }

  onChangeTagValue(value) {
    this.setState({
      tagValue: value,
    });
  }

  onSelectTagValue(value) {
    this.setState({
      tagSelected: value,
    });
  }

  onClickPreviewChallenge() {
    callGetChlgById(this.state.challenge._id).then((d) => {
      this.previewChlgRef.current.onClickPreviewButton(d);
    });
  }

  async onClickCreateTag() {
    let tagName = this.state.tagName.trim().toLowerCase();
    if (tagName.length === 0) {
      showNotification("error", "Tag name is required", 5000);
      return;
    }
    let existingTags = this.state.tags.filter((d) => {
      return d.name.toLowerCase() === tagName;
    });
    if (existingTags.length > 0) {
      showNotification("error", "Tag already exists", 4000);
      return;
    }
    // call api to create system tag
    /*eslint-disable*/
    let apiData = {
      name: tagName,
      description: tagName,
      rules: [
        {
          limit_tag_per_days: "1",
        },
      ],
      additional_rules: [],
      events: [],
      notifications: [],
      product_list: [],
      generic_tag_list: [],
      zoom_meeting: {
        user_id: "",
        meeting_id: "",
      },
      type: "automated",
      created_from: "challenge",
    };
    /*eslint-enable*/
    let newTag = await callCreateSystemTag(apiData);
    if (newTag._id) {
      // call api get system tags
      let tags = await callGetSystemTags();
      this.setState(
        {
          tagName: "",
          tags: tags.data,
          tagType: "existing",
          keyTag: Math.random(),
          keyTagType: Math.random(),
          tagValue: this.state.tagName,
        },
        () => {
          this.onClickAddRule();
        }
      );
    }
  }

  onCreateNewItem(type, id) {
    if (type === "newForm") {
      this.getForms();
    }
    if (type === "editChallengeItem") {
      this.getChallengeItems();
      this.setState({
        challengeItemUpdated: true,
      });
      this.getChallengeInfo();
    }

    if (type === "challengeItem") {
      this.getChallengeItems();
      this.setState({
        newChallengeItemCreated: true,
      });
    }

    if (type === "editTag") {
      this.getSystemTags();
    }

    if (type === "atqQuestion") {
      this.getChallengeQuestions();
      if (this.state.newQuestionType === "atq") {
        this.setState({ queQueId: id }, () => {
          this.onClickAddQuestionRule();
        });
      } else {
        this.setState({ newQuestionCreated: true, queQueId: id });
      }
    }

    if (type === "addEmailFromChallenge") {
      this.getEmailsFromGroup(this.state.challenge.email_group_id);
    }
  }

  onClickHideAdvancedDetails() {
    this.setState({
      showAdvancedOptionsDetails: false,
    });
  }

  onClickEditChallengeItem() {
    callGetChlgItemById(this.state.selectedItem.value._id).then((item) => {
      this.setState(
        {
          popupType: "editChallengeItem",
          popupLabel: "Edit Challenge Item",
          popupKey: Math.random(),
          popupChallengeItem: item,
        },
        () => {
          openPagePopupModal("editChallengeItem");
        }
      );
    });
  }

  onClickShowAdvancedDetails() {
    this.setState({
      showAdvancedOptionsDetails: true,
    });
  }

  onClickHideAdvancedItems() {
    this.setState({
      showAdvancedOptionsItems: false,
    });
  }

  onClickShowAdvancedItems() {
    this.setState({
      showAdvancedOptionsItems: true,
    });
  }

  onChangeImage = (image) => {
    let challenge = this.state.challenge;
    challenge["screenshot"] = image;
    this.setState({ challenge: challenge });
  };

  toggleSocialOptionCheckbox(key, check) {
    let socialOptions = this.state.socialOptions;
    socialOptions[key] = check;
    this.setState({
      socialOptions: socialOptions,
    });
  }

  renderSocialOptions() {
    let socialOptions = this.state.socialOptions;
    let returnVals = [];

    for (const key in socialOptions) {
      if (socialOptions.hasOwnProperty(key)) {
        const element = socialOptions[key];
        returnVals.push(
          <Checkbox
            label={this.socialLables[key]}
            isChecked={element}
            handleCheckboxChange={this.toggleSocialOptionCheckbox.bind(
              this,
              key
            )}
          />
        );
      }
    }
    return returnVals;
  }

  renderDetails() {
    let isHd = this.state.challenge.durationType === "hd";
    let isShortName = false;
    let isCategory = false;

    return (
      <div className="container-fluid">
        <div className="card">
          <div className="container-fluid">
            <div className="gap20" />
            <div className="text-right">
              <button
                className={"btn btn-info removeFocusDecoration"}
                onClick={() =>
                  this.state.showAdvancedOptionsDetails
                    ? this.onClickHideAdvancedDetails()
                    : this.onClickShowAdvancedDetails()
                }>
                {this.state.showAdvancedOptionsDetails
                  ? "Hide Advanced Options"
                  : "Show Advanced Options"}
              </button>
            </div>
            <HelpTextDiv
              id="idChallengeName"
              label="Learn more about the Challenge Name"
              type="addChallenge"
              isInline="false"
            />
            <div className="radioBHolder">
              <div className="havingHelpText">
                <p>How will you refer to your course?</p>
              </div>

              <HelpTextDiv
                id="idChallengeType"
                label="Learn more about the Challenge Type"
                type="addChallenge"
                isInline="true"
              />

              <div className="tabsHolder radioTabs">
                <RadioTag
                  onCheckChange={this.onSelectType.bind(this)}
                  labelList={[
                    { name: "Course", value: "course" },
                    { name: "Challenge", value: "challenge" },
                    { name: "Program", value: "program" },
                    { name: "Workshop", value: "workshop" },
                    { name: "Masterclass", value: "masterclass" },
                    { name: "Training", value: "training" },
                  ]}
                  id={"type"}
                  selectedList={[this.state.challenge.type]}
                  selectType={"value"}
                  key={this.state.keyChallengeType}
                />
              </div>
              <div className="gap10" />
              <label style={{ color: "gray", fontSize: 14 }}>
                Note: This can be changed at any time and does not change
                anything.
              </label>
            </div>

            <div className="gap20" />
            <div
              className={
                this.state.challenge.name !== ""
                  ? "mdInput mdFocussed"
                  : "mdInput"
              }>
              <label>Challenge Name</label>
              <input
                type="text"
                className="challengeName"
                name="name"
                value={this.state.challenge.name}
                onChange={(event) => this.handleChallengeInputChange(event)}
              />
            </div>
            <div className="gap20" />

            <HelpTextDiv
              id="idChallengeDescription"
              label="Learn more about the Description"
              type="addChallenge"
              isInline="false"
            />

            <div
              className={
                this.state.challenge.description !== ""
                  ? "mdInput mdFocussed"
                  : "mdInput"
              }>
              <label>Description</label>
              <input
                type="text"
                className="challengeDesc"
                name="description"
                value={this.state.challenge.description}
                onChange={(event) => this.handleChallengeInputChange(event)}
              />
            </div>
            <div className="gap20" />
            {isCategory && (
              <>
                <HelpTextDiv
                  id="idChallengeCategory"
                  label="Learn more about the Category"
                  type="addChallenge"
                  isInline="false"
                />

                <div
                  className={
                    this.state.challenge.category !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Challenge Category</label>
                  <AutoSuggest
                    key={"challenge add category"}
                    searchKey="category"
                    value={this.state.challenge.category}
                    data={[
                      { category: "random category" },
                      { category: "random category2" },
                    ]}
                    onChange={this.onChangeCategory.bind(this)}
                    onSelect={this.onSelectCategory.bind(this)}
                  />
                </div>

                <div className="gap20" />
              </>
            )}
            {isShortName && (
              <>
                <HelpTextDiv
                  id="idChallengeShortName"
                  label="Learn more about the Challenge Short Name"
                  type="addChallenge"
                  isInline="false"
                />

                <div
                  className={
                    this.state.challenge.shortName !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Short Name</label>
                  <input
                    type="text"
                    name="shortName"
                    value={this.state.challenge.shortName}
                    onChange={(event) => this.handleChallengeInputChange(event)}
                  />
                </div>
                <div className="gap20" />
              </>
            )}
            {this.state.showAdvancedOptionsDetails && (
              <div className="row">
                <div className="col-sm-12">
                  <div className="radioBHolder">
                    <div className="havingHelpText">
                      <p>Duration Type</p>
                    </div>

                    <HelpTextDiv
                      id="idChallengeDurationType"
                      label="Learn more about the Duration Type"
                      type="addChallenge"
                      isInline="true"
                    />

                    <div className="tabsHolder radioTabs">
                      <RadioTag
                        key={this.state.keyDuration}
                        onCheckChange={this.onSelectDurationType.bind(this)}
                        labelList={[
                          { name: "No Duration", value: "nd" },
                          { name: "Has Duration", value: "hd" },
                        ]}
                        id={"durationType"}
                        selectedList={[this.state.challenge.durationType]}
                        selectType={"value"}
                      />
                    </div>
                    <div className="gap20" />
                    {/* {this.state.challenge.durationType === "nd" && (
                      <p>
                        Note: Accountability is not available if No Duration is
                        selected. Accountability is only available while a
                        challenge is active.
                      </p>
                    )}
                    {this.state.challenge.durationType === "nd" && (
                      <>
                        <Checkbox
                          label={
                            "Allow user to have access to this course by tags."
                          }
                          isChecked={this.state.isTagChallenge}
                          handleCheckboxChange={this.toggleTagChallengeCheckbox}
                          key={this.state.keyTagChallenge}
                        />

                        {this.state.isTagChallenge && (
                          <>
                            <div className="gap10" />
                            <p>
                              Note: If challenge is added by tag then all items
                              will be available.
                            </p>
                            <TagSelect
                              id={"add-challenge-tag"}
                              key={this.state.keyAddTag}
                              selectedValues={this.state.selectedTags}
                              searchKey={"name"}
                              selectKey={"name"}
                              btnText={"Create New Tag"}
                              lblText={"Search Tag"}
                              data={this.state.tags}
                              onChange={this.onChangeAddTags}
                              isClickable={true}
                              reload={() => {
                                this.getSystemTags();
                              }}
                            />
                          </>
                        )}
                        <div className="gap20" />
                      </>
                    )} */}
                  </div>
                </div>
              </div>
            )}

            {this.state.showAdvancedOptionsDetails && (
              <div className="row">
                {isHd && (
                  <div className="col-sm-12">
                    <HelpTextDiv
                      id="idChallengeDays"
                      label="Learn more about the Challenge Days"
                      type="addChallenge"
                      isInline="false"
                    />

                    <div
                      className={
                        this.state.challenge.challengeDays !== ""
                          ? "mdInput mdFocussed"
                          : "mdInput"
                      }>
                      <label>Challenge Days</label>
                      <input
                        type="text"
                        name="challengeDays"
                        value={this.state.challenge.challengeDays}
                        onChange={(event) =>
                          this.handleChallengeInputChange(event)
                        }
                      />
                    </div>
                    <div className="gap20" />
                  </div>
                )}
                {(!isHd || this.state.showAdvancedOptionsDetails) && (
                  <div className="col-sm-12">
                    <HelpTextDiv
                      id="idChallengeActiveDays"
                      label="Learn more about the Challenge Active Days"
                      type="addChallenge"
                      isInline="false"
                    />

                    <div
                      className={
                        this.state.challenge.addActiveDays !== ""
                          ? "mdInput mdFocussed"
                          : "mdInput"
                      }>
                      <label>
                        Keep this challenge open for this many days after the
                        challenge is over
                      </label>
                      <input
                        type="text"
                        name="addActiveDays"
                        value={this.state.challenge.addActiveDays}
                        onChange={(event) =>
                          this.handleChallengeInputChange(event)
                        }
                      />
                    </div>
                    <div className="gap20" />
                  </div>
                )}
                {isHd && this.state.showAdvancedOptionsDetails && (
                  <div className="col-sm-12">
                    <HelpTextDiv
                      id="idChallengePreviewDays"
                      label="Learn more about the Challenge Preview Days"
                      type="addChallenge"
                      isInline="false"
                    />

                    <div
                      className={
                        this.state.challenge.previewDays !== ""
                          ? "mdInput mdFocussed"
                          : "mdInput"
                      }>
                      <label>Preview Days</label>
                      <input
                        type="text"
                        name="previewDays"
                        value={this.state.challenge.previewDays}
                        onChange={(event) =>
                          this.handleChallengeInputChange(event)
                        }
                      />
                    </div>
                    <div className="gap20" />
                    <p>
                      Show the challenge page to a user this many days before it
                      starts (Note this only applies if your challenge starts at
                      a specific date in the future or on a certain day of the
                      week)
                    </p>
                  </div>
                )}
              </div>
            )}

            <div className="row">
              {!isHd && (
                <p style={{ paddingLeft: 15, paddingRight: 15 }}>
                  Note: Challenge rules will process for the number of days the
                  challenge is active. Once the challenge is no longer active
                  the rules will no longer apply
                </p>
              )}
            </div>
            <div className="row">
              {this.state.showAdvancedOptionsDetails && (
                <div className="col-sm-12">
                  <div className="havingHelpText">
                    <Checkbox
                      label={
                        "Keep this challenge open forever (Once the customer gets it they will always have access)"
                      }
                      isChecked={this.state.isForeverOpen}
                      handleCheckboxChange={this.toggleForverOpenCheckbox}
                      key={this.state.keyForeverOpen}
                    />
                  </div>
                  <HelpTextDiv
                    id="idKeepChallengeOpenForever"
                    label="Learn more about keep challenge open forever"
                    type="addChallenge"
                    isInline="true"
                  />
                  <div className="gap20" />
                  <Checkbox
                    label={"Enable Social Media Sharing for this challenge"}
                    isChecked={this.state.isSocialShare}
                    handleCheckboxChange={this.toggleSocialShareCheckbox}
                    key={this.state.keySocialShare}
                  />
                  {this.state.isSocialShare ? (
                    <>
                      <div className="gap20" />
                      <div className="card">
                        <div className="container-fluid">
                          <div
                            className={
                              this.state.shareUrl !== ""
                                ? "mdInput mdFocussed"
                                : "mdInput"
                            }>
                            <label>Share Url</label>
                            <input
                              type="text"
                              name="shareUrl"
                              value={this.state.shareUrl}
                              onChange={(event) =>
                                this.handleInputChange(event)
                              }
                            />
                          </div>
                          <label style={{ color: "#c2c2c2" }}>
                            You can point this to sales page or something public
                            that relates to your challenge when users share it
                          </label>
                          <div className="gap10" />
                          <div
                            className={
                              this.state.shareText !== ""
                                ? "mdInput mdFocussed"
                                : "mdInput"
                            }>
                            <label>Share Text</label>
                            <input
                              type="text"
                              name="shareText"
                              value={this.state.shareText}
                              onChange={(event) =>
                                this.handleInputChange(event)
                              }
                            />
                          </div>
                          <div className="gap10" />
                          {this.renderSocialOptions()}
                        </div>
                        <div className="gap20" />
                      </div>
                    </>
                  ) : (
                    <div className="gap20" />
                  )}
                </div>
              )}
              {/* {this.state.showAdvancedOptionsDetails && (
                <div className="col-sm-12">
                  <div className="havingHelpText">
                    <Checkbox
                      label={
                        "Enable general feedback option on accountability page"
                      }
                      isChecked={this.state.isFeedback}
                      handleCheckboxChange={this.toggleFeedbackCheckbox}
                      key={this.state.keyFeedback}
                    />
                  </div>
                  <HelpTextDiv
                    id="idEnableGeneralFeedback"
                    label="Learn more about the Enable general feedback"
                    type="addChallenge"
                    isInline="true"
                  />
                  <div className="gap20" />
                </div>
              )} */}
              {this.state.showAdvancedOptionsDetails && (
                <div className="col-sm-12">
                  <div className="havingHelpText">
                    <Checkbox
                      label={"Disable Charts"}
                      isChecked={this.state.isCharts}
                      handleCheckboxChange={this.toggleChartsCheckbox}
                      key={this.state.keyCharts}
                    />
                  </div>
                  <div className="gap20" />
                  {!this.state.isCharts && (
                    <>
                      <div className="havingHelpText">
                        <Checkbox
                          label={"Enable Accountability Charts"}
                          isChecked={this.state.isAtqCharts}
                          handleCheckboxChange={this.toggleAtqChartsCheckbox}
                          key={this.state.keyAtqCharts}
                        />
                      </div>
                      <div className="gap20" />
                      {this.state.isAtqCharts && (
                        <>
                          <div className="card">
                            <div className="container-fluid">
                              <div className="gap20" />
                              <div className="row">
                                <div className="col-sm-4">
                                  <Checkbox
                                    label={"Percent Completed Donut"}
                                    isChecked={this.state.atqCharts.donut}
                                    handleCheckboxChange={this.toggleAtqChartsValueCheckbox.bind(
                                      this,
                                      "donut"
                                    )}
                                    key={this.state.keyAtqCharts}
                                  />
                                </div>
                                <div className="col-sm-4">
                                  <Checkbox
                                    label={"7 Day Graph"}
                                    isChecked={this.state.atqCharts.day}
                                    handleCheckboxChange={this.toggleAtqChartsValueCheckbox.bind(
                                      this,
                                      "day"
                                    )}
                                    key={this.state.keyAtqCharts}
                                  />
                                </div>
                                <div className="col-sm-4">
                                  <Checkbox
                                    label={"Daily Tracker"}
                                    isChecked={this.state.atqCharts.daily}
                                    handleCheckboxChange={this.toggleAtqChartsValueCheckbox.bind(
                                      this,
                                      "daily"
                                    )}
                                    key={this.state.keyAtqCharts}
                                  />
                                </div>
                              </div>
                              <div className="gap20" />
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
              )}

              {this.state.showAdvancedOptionsDetails && (
                <div className="col-sm-12">
                  <div className="havingHelpText">
                    <Checkbox
                      label={"Show Leaderboard"}
                      isChecked={this.state.isLeaderBoard}
                      handleCheckboxChange={this.toggleLeaderBoardCheckbox}
                      key={this.state.keyLeaderBoard}
                    />
                  </div>
                  <div className="gap20" />
                </div>
              )}
              {this.state.showAdvancedOptionsDetails && (
                <div className="col-sm-12">
                  <div className="havingHelpText">
                    <Checkbox
                      label={
                        "Show Previous / Next / Complete Buttons inside challenge items"
                      }
                      isChecked={this.state.isShowNavigation}
                      handleCheckboxChange={this.toggleNavigationCheckbox}
                      key={this.state.keyShowNavigation}
                    />
                  </div>
                  <div className="gap20" />
                </div>
              )}
              {this.state.showAdvancedOptionsDetails && (
                <div className="col-sm-12">
                  <div className="havingHelpText">
                    <Checkbox
                      label={"Hide category if all items are disabled"}
                      isChecked={this.state.isHideCategory}
                      handleCheckboxChange={this.toggleHideCategoryCheckbox}
                      key={this.state.keyHideCategory}
                    />
                  </div>
                  <div className="gap20" />
                </div>
              )}
              {this.state.showAdvancedOptionsDetails && (
                <div className="col-sm-12">
                  <div className="havingHelpText">
                    <Checkbox
                      label={"Show Video Progress"}
                      isChecked={this.state.showVideoProgress}
                      handleCheckboxChange={
                        this.toggleShowVideoProgressCheckbox
                      }
                      key={this.state.keyShowVideoProgress}
                    />
                  </div>
                  <div className="gap20" />
                </div>
              )}
              {this.state.showAdvancedOptionsDetails && (
                <div className="col-sm-4">
                  <div
                    className={
                      this.state.challenge.priority !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>Priority</label>
                    <input
                      type="number"
                      className="challengeName"
                      name="priority"
                      min={1}
                      value={this.state.challenge.priority}
                      onChange={(event) =>
                        this.handleChallengeInputChange(event)
                      }
                    />
                  </div>
                  <div className="gap20" />
                </div>
              )}

              {this.state.showAdvancedOptionsDetails && (
                <div className="col-sm-12">
                  <p>
                    Note: The priority is the number in which the challenge will
                    be displayed on the members dashboard. So if you want it to
                    be first you can give it a priority of 1. It is recommended
                    to start with the number like 100 so that in the future you
                    can always give a priority of 95 or 105 so you can still
                    easily put things in front or after that challenge. This
                    field is optional.
                  </p>
                </div>
              )}

              {this.state.showAdvancedOptionsDetails &&
                this.renderGroupComponent()}

              {this.state.showAdvancedOptionsDetails && (
                <SelectDiscussionBoard
                  key={this.state.keyDiscussionBoard}
                  isDiscussionBoard={this.state.isDiscussionBoard}
                  discussionBoard={this.state.discussionBoard}
                  onChange={(isDiscussionBoard, discussionBoard) => {
                    this.setState({
                      isDiscussionBoard: isDiscussionBoard,
                      discussionBoard: discussionBoard,
                    });
                  }}
                />
              )}

              {this.state.showAdvancedOptionsDetails &&
                this.state.isLeaderBoard && (
                  <div className="col-sm-12">
                    <div
                      className={
                        this.state.leaderboardActiveUserCount !== ""
                          ? "mdInput mdFocussed"
                          : "mdInput"
                      }>
                      <label>
                        How many active users required to show Leaderboard?
                      </label>
                      <input
                        type="number"
                        name="leaderboardActiveUserCount"
                        value={this.state.leaderboardActiveUserCount}
                        onChange={(event) => this.handleInputChange(event)}
                      />
                    </div>
                    <div className="gap20" />
                  </div>
                )}

              {this.state.showAdvancedOptionsDetails &&
                this.state.isLeaderBoard && (
                  <div className="col-sm-12">
                    <div
                      className={
                        this.state.leaderboardNumberOfUsers !== ""
                          ? "mdInput mdFocussed"
                          : "mdInput"
                      }>
                      <label>
                        How many top users you want to show in Leaderboard?
                      </label>
                      <input
                        type="number"
                        name="leaderboardNumberOfUsers"
                        value={this.state.leaderboardNumberOfUsers}
                        onChange={(event) => this.handleInputChange(event)}
                      />
                    </div>
                    <div className="gap20" />
                  </div>
                )}
              {this.state.showAdvancedOptionsDetails && (
                <div className="col-sm-12">
                  <SelectAddImage
                    key={this.state.keyImage}
                    label="Screenshot"
                    onChangeImage={this.onChangeImage}
                    image={this.state.challenge.screenshot}
                  />
                  <div className="gap10" />
                </div>
              )}

              {this.state.showAdvancedOptionsDetails && (
                <div className="col-sm-12">
                  <div className="radioBHolder">
                    <div className="havingHelpText">
                      <p>Challenge Start Day</p>
                    </div>

                    <HelpTextDiv
                      id="idChallengeStartDay"
                      label="Learn more about the Challenge Start Day"
                      type="addChallenge"
                      isInline="true"
                    />

                    <div className="tabsHolder radioTabs">
                      <RadioTag
                        onCheckChange={this.onSelectDay.bind(this)}
                        labelList={[
                          { name: "None", value: "" },
                          { name: "Monday", value: "monday" },
                          { name: "Tuesday", value: "tuesday" },
                          { name: "Wednesday", value: "wednesday" },
                          { name: "Thursday", value: "thursday" },
                          { name: "Friday", value: "friday" },
                          { name: "Saturday", value: "saturday" },
                          { name: "Sunday", value: "sunday" },
                        ]}
                        id={"startDayOfWeek"}
                        selectedList={[this.state.challenge.startDayOfWeek]}
                        selectType={"value"}
                      />
                    </div>
                  </div>
                  <div className="gap20" />
                </div>
              )}

              {this.state.showAdvancedOptionsDetails && (
                <div className="col-sm-12">
                  <div
                    className={
                      this.state.itemTemplateId !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>Default Challenge Item Template</label>
                    <select
                      name="itemTemplateId"
                      value={this.state.itemTemplateId}
                      onChange={(event) => this.handleInputChange(event)}>
                      {this.state.editorTemplates.map((i) => (
                        <option value={i._id} key={i._id}>
                          {i.template_name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
            </div>
            <div className="gap20" />
          </div>
        </div>
      </div>
    );
  }

  renderPageContent() {
    return (
      <>
        <div className="container-fluid">
          <Checkbox
            label={
              "Collapse this section if the user has watched all videos (note: this only applies if you have added videos into the section)"
            }
            isChecked={this.state.colIntroSection}
            handleCheckboxChange={this.toggleColIntroSection}
            key={this.state.keyColIntroSection}
          />
          <div className="gap20" />
          <PageContent
            key={this.state.keyPageContent}
            label={"Introduction Content"}
            description={
              "The introduction content will be displayed above the challenge/course material. \
               Typically this is where you will introduce the content of the course and mention anything else you would like."
            }
            title={this.state.challenge.name}
            screen={"add-challenge"}
            previewType="mp-preview"
            html={this.state.html}
            content={this.state.content}
            onChangeData={this.onChangeContent.bind(this)}
            pageName={"addChallenge"}
            draftId={this.state.draftId}
            draftImage={this.state.draftImage}
            editorType={this.state.editorType}
            onClickAlert={this.onClickAddChallenge.bind(this)}
            challengeInfo={{
              name: this.state.challenge.name,
              description: this.state.challenge.description,
              type: this.state.challenge.type,
            }}
          />
        </div>
      </>
    );
  }

  onClickAddNewAtqQuestion(type) {
    this.setState(
      {
        popupType: "atqQuestion",
        popupLabel: "Add New Accountability Question",
        popupKey: Math.random(),
        newQuestionType: type,
      },
      () => {
        openPagePopupModal("atqQuestion");
      }
    );
  }

  onClickAddNewEmail() {
    this.setState(
      {
        popupType: "addEmailFromChallenge",
        popupLabel: "Add New Email",
        popupKey: Math.random(),
      },
      () => {
        openPagePopupModal("addEmailFromChallenge");
      }
    );
  }

  async onClickAddQuestionRule() {
    let questionId = "";
    if (this.state.searchQuestionAtqType === "existing") {
      questionId = this.state.selectedQue.value._id;
    } else {
      questionId = this.state.queQueId;
    }

    let dOpe = new ChallengeOperations();

    let response = await dOpe.createQuestionTag(
      this.state.challenge.name,
      this.state.onSpecificDay
    );
    let tagValue = response.tagValue;
    let tagIdN = response.tagId;

    let atqRules = this.state.atqRules;
    atqRules.push({
      type: this.state.atqRuleType,
      day: this.state.onSpecificAtqDay,
      action: "add-atq",
      /*eslint-disable*/
      tag_id: tagIdN,
      tag_name: tagValue,
      question_linked: questionId,
      /*eslint-enable*/
    });

    atqRules = await this.prepareAtqRules(atqRules);

    /*eslint-disable*/
    let updateApiData = {
      $push: { included_tags: tagIdN },
    };
    /*eslint-enable*/
    let question = await callUpdateChlgQue(questionId, updateApiData);

    let queList = this.state.challenge.baseQuestionList;

    queList.push({
      name: question.name,
      question: question.question,
      description: question.description,
      value: question,
    });

    this.setState(
      { atqRules, chlgQueList: queList, keyQuestion: Math.random() },
      () => {
        this.onClickAddChallenge();
      }
    );
  }

  renderQuestions() {
    let isQuestions = this.state.questions.length > 0;
    let showNote = this.state.challenge.durationType === "nd";
    let showQuestions = this.state.showAtqAdvancedOptions;

    return (
      <>
        <div className="container-fluid">
          <div className="card">
            <div className="container-fluid">
              <div className="gap20" />
              <div className="clearfix">
                <div className="pull-left">
                  <h5 className="noMargin firstLetter">Add Accountability</h5>
                </div>
                <div className="pull-right">
                  <button
                    className={"btn btn-info removeFocusDecoration"}
                    onClick={() =>
                      this.setState({
                        showAtqAdvancedOptions:
                          !this.state.showAtqAdvancedOptions,
                      })
                    }>
                    {this.state.showAtqAdvancedOptions
                      ? "Hide Advanced Options"
                      : "Show Advanced Options"}
                  </button>
                </div>
              </div>
              <p className="text-secondary">
                Adding accountability lets your customers send feedback or
                answer specific questions you give them.
              </p>
              <div className="gap20" />
              <div className="row">
                <div className="col-sm-4">
                  <div
                    className={
                      this.state.atqRuleType !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>Select Rule Type</label>
                    <select
                      className="selectRuleTypeInput"
                      name="atqRuleType"
                      value={this.state.atqRuleType}
                      onChange={(event) => this.handleInputChange(event)}>
                      <option value="" />
                      <option value="on_specific_day">On Day</option>
                      <option value="at_signup">At Signup</option>
                    </select>
                  </div>
                  <div className="gap20" />
                </div>
                {this.state.atqRuleType === "on_specific_day" && (
                  <div className="col-sm-4">
                    <div
                      className={
                        this.state.onSpecificAtqDay !== ""
                          ? "mdInput mdFocussed"
                          : "mdInput"
                      }>
                      <label>Enter Day</label>
                      <input
                        type="text"
                        className="onDayInput"
                        name="onSpecificAtqDay"
                        value={this.state.onSpecificAtqDay}
                        onChange={(event) => this.handleInputChange(event)}
                      />
                    </div>
                    <div className="gap20" />
                  </div>
                )}
              </div>
              {this.state.searchQuestionAtqType === "new" &&
                this.state.atqRuleType !== "" &&
                !this.state.selectedAtqRule.question_linked && (
                  <>
                    <div className="gap10" />
                    <button
                      className="btn btn-default btn-sm"
                      onClick={this.onClickAddNewAtqQuestion.bind(this, "atq")}>
                      Create Accountability Question
                    </button>
                  </>
                )}
              {this.state.selectedAtqRule.question_linked && (
                <>
                  <div className="gap10" />
                  <button
                    className="btn btn-default btn-sm"
                    onClick={this.onClickSaveAtqRule.bind(this)}>
                    Save
                  </button>
                </>
              )}
              <div className="gap20" />
              {this.state.atqRules.length > 0 && (
                <div className="card">
                  <div className="container-fluid">
                    <div className="gap20" />
                    <div className="clearfix">
                      <div className="pull-left">
                        <h5 className="noMargin">Added Rules</h5>
                      </div>
                      <div className="pull-right">
                        <button
                          className="btn btn-sm btn-default"
                          onClick={() =>
                            window.open(
                              `/admin/challenge-atq-rules?id=${this.state.challengeId}`,
                              "_blank"
                            )
                          }>
                          View All Rules
                        </button>
                      </div>
                    </div>

                    <div className="gap20" />

                    <div className="addedRule">{this.renderAtqRules()}</div>

                    <div className="gap20" />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {showQuestions && (
          <>
            <div className="container-fluid">
              <div className="card">
                <div className="container-fluid">
                  <div className="gap20" />
                  <p className="text-secondary">
                    Note: This section is optional and only required if you want
                    accountability to appear while the customer is active inside
                    a challenge.
                  </p>
                  {showNote && (
                    <p className="text-info">
                      Your challenge currently has no duration. To add
                      accountability to a challenge it must have a duration with
                      active days. This can be set inside the Details tab in the
                      advanced options.
                    </p>
                  )}
                  {!showNote && isQuestions && (
                    <>
                      {this.state.searchQuestionType === "existing" && (
                        <div className="addChallengeContainer">
                          <div className="column">
                            <TypeableSelect
                              key={this.state.keyQuestion}
                              name={"question"}
                              placeholder={"Search Question"}
                              selected={this.state.selectedQue}
                              onSelect={this.onSelectQuestion}
                              options={this.state.questions}
                            />
                          </div>
                        </div>
                      )}
                      <div className="gap20" />
                    </>
                  )}
                  {!showNote && !isQuestions && (
                    <>
                      <p>
                        There is not any challenge accountability available.
                      </p>
                      <div className="gap10" />
                      <button
                        className="btn btn-default btn-sm"
                        onClick={this.onClickAddNewAtqQuestion.bind(
                          this,
                          "normal"
                        )}>
                        Create Accountability Question
                      </button>
                      <div className="gap10" />
                    </>
                  )}
                </div>
              </div>
            </div>

            {!showNote && this.state.challenge.baseQuestionList.length > 0 && (
              <div className="container-fluid">
                <div className="card">
                  <div className="container-fluid">
                    <div className="gap20" />
                    <h5 className="noMargin">Added Accountability Questions</h5>
                    <div className="gap20" />
                    <SortableQuestionList
                      items={this.state.challenge.baseQuestionList}
                      onSortEnd={this.onSortEndQues}
                      showName={true}
                      showView={true}
                      onPressView={this.onPressViewQue.bind(this)}
                      onPressRemove={this.onPressRemoveQue.bind(this)}
                    />
                    <div className="gap20" />
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </>
    );
  }

  renderVideos() {
    return (
      <>
        <div className="container-fluid">
          <div className="card">
            <div className="container-fluid">
              <div className="gap20" />
              <div className="radioBHolder">
                <div className="havingHelpText">
                  <h5>Display Video </h5>
                </div>

                <HelpTextDiv
                  id="idVideoDispalyType"
                  label="Learn more about the Challenge Type"
                  type="addChallenge"
                  isInline="true"
                />

                <div className="tabsHolder radioTabs">
                  <RadioTag
                    onCheckChange={this.onSelectDisplayType.bind(this)}
                    labelList={[
                      { name: "Before Content", value: "bc" },
                      { name: "After Content", value: "ac" },
                    ]}
                    id={"displayType"}
                    selectedList={[this.state.challenge.displayType]}
                    selectType={"value"}
                    key={this.state.keyChallengeDispalyType}
                  />
                </div>
              </div>
              <div className="gap20" />
            </div>
          </div>

          <div className="card">
            <div className="container-fluid">
              <HelpTextDiv
                id="idChallengeVideoSectionTitle"
                label="Learn more about the Video Section Title"
                type="addChallenge"
                isInline="false"
                topMargin="10"
              />
              <div
                className={
                  this.state.challenge.videoSectionTitle !== ""
                    ? "mdInput mdFocussed"
                    : "mdInput"
                }>
                <label>Video Section Title</label>
                <input
                  type="text"
                  name="videoSectionTitle"
                  value={this.state.challenge.videoSectionTitle}
                  onChange={(event) => this.handleChallengeInputChange(event)}
                />
              </div>
              <div className="gap20" />
            </div>
          </div>
          <div className="card">
            <div className="container-fluid">
              <div className="gap20" />
              <h5>Add Videos</h5>
              <div className="gap20" />
              <div className="row">
                <div className="col-sm-4">
                  <HelpTextDiv
                    id="idChallengeVideoTitle"
                    label="Learn more about the video title"
                    type="addChallenge"
                    isInline="false"
                  />
                  <div
                    className={
                      this.state.videoTitle !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>Video Title</label>
                    <input
                      type="text"
                      name="videoTitle"
                      value={this.state.videoTitle}
                      onChange={(event) => this.handleInputChange(event)}
                    />
                  </div>
                  <div className="gap20" />
                </div>
                <div className="col-sm-4">
                  <HelpTextDiv
                    id="idChallengeVideoID"
                    label="Learn more about the video Id"
                    type="addChallenge"
                    isInline="false"
                  />
                  <div
                    className={
                      this.state.videoUrl !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>
                      {this.state.videoPlayer === "Vooplayer"
                        ? "Video ID"
                        : "Video ID"}
                    </label>
                    <input
                      type="text"
                      className="videoUrl"
                      name="videoUrl"
                      value={this.state.videoUrl}
                      onChange={(event) => this.handleInputChange(event)}
                    />
                  </div>
                  <div className="gap20" />
                </div>
                <div className="col-sm-4">
                  <div className="havingHelpText">
                    <p>Video Player</p>
                  </div>
                  <HelpTextDiv
                    id="idChallengeVideoPlayer"
                    label="Learn more about the video player"
                    type="addChallenge"
                    isInline="false"
                  />
                  <div className="tabsHolder radioTabs">
                    <RadioTag
                      onCheckChange={this.onSelectVideoPlayer.bind(this)}
                      labelList={[
                        { name: "YouTube", value: "Youtube" },
                        { name: "Vimeo", value: "Vimeo" },
                        { name: "Voo Player", value: "vooplayer" },
                      ]}
                      id={"videoPlayer"}
                      selectedList={[this.state.videoPlayer]}
                      selectType={"value"}
                    />
                  </div>
                  <div className="gap20" />
                </div>
              </div>
              <VideoPlayerNote
                videoPlayer={this.state.videoPlayer}
                key={this.state.videoKey}
              />
              <div
                className="text-right"
                onClick={() => this.onClickAddVideo()}>
                <button className="btn btn-primary addVideoButton">
                  Add Video
                </button>
              </div>
              <div className="gap20" />
            </div>
          </div>
        </div>

        {this.state.challenge.videoList.length > 0 && (
          <div className="container-fluid">
            <div className="card">
              <div className="container-fluid">
                <div className="gap20" />
                <h5>Added Videos</h5>
                <div className="gap20" />
                <div className="gap20" />
                <SortableVideoList
                  items={this.state.challenge.videoList}
                  onSortEnd={this.onSortEndVideos}
                  onPressRemove={this.onPressRemoveVideo.bind(this)}
                />
                <div className="gap20" />
              </div>
            </div>
          </div>
        )}
      </>
    );
  }

  onClickAddNewItem() {
    this.setState(
      {
        popupType: "challengeItem",
        popupLabel: "Add New Challenge Item",
        popupKey: Math.random(),
        challegeInfo: {
          name: this.state.challenge.name,
          description: this.state.description,
          type: this.state.challenge.type,
          itemTemplateId: this.state.itemTemplateId,
        },
      },
      () => {
        openPagePopupModal("challengeItem");
      }
    );
  }

  onClickAddNewForm() {
    this.setState(
      {
        popupType: "newForm",
        popupLabel: "Add New Form",
        popupKey: Math.random(),
      },
      () => {
        openPagePopupModal("newForm");
      }
    );
  }

  onChangeRuleDate(value) {
    this.setState({
      onSpecificDate: value,
    });
  }

  onChangeItemOffsetDate(value) {
    this.setState({
      itemOffsetDate: value,
    });
  }

  renderItems() {
    let isItems = this.state.items.length > 0;

    return (
      <>
        <div className="container-fluid">
          <div className="card">
            <div className="container-fluid">
              <div className="gap20" />

              <div className="clearfix">
                <div className="pull-left">
                  <h5 className="noMargin firstLetter">Add Items</h5>
                </div>
                <div className="pull-right">
                  <button
                    className={"btn btn-info removeFocusDecoration"}
                    onClick={() =>
                      this.state.showAdvancedOptionsItems
                        ? this.onClickHideAdvancedItems()
                        : this.onClickShowAdvancedItems()
                    }>
                    {this.state.showAdvancedOptionsItems
                      ? "Hide Advanced Options"
                      : "Show Advanced Options"}
                  </button>
                </div>
              </div>
              <div className="gap20" />
              <div className="card">
                <div className="container-fluid">
                  <div className="gap10" />
                  <p className="text-secondary">
                    Items are how you will create challenge content. You could
                    think of these are modules or lessons inside of a course.
                  </p>
                  <p className="text-secondary">
                    Click the "Add New Item" to right to create your content
                    now.
                  </p>
                </div>
              </div>

              {isItems && (
                <>
                  {this.state.showAdvancedOptionsItems && (
                    <>
                      <div className="gap10" />
                      <div className="havingHelpText">
                        <Checkbox
                          label={
                            "Time release this item (it is not instantly available)"
                          }
                          isChecked={this.state.isTimeReleaseThisItem}
                          handleCheckboxChange={this.toggleTimeReleaseThisItem}
                          key={this.state.keyAllowLock}
                        />
                      </div>
                      <HelpTextDiv
                        id="idTimeReleaseThisItem"
                        label="Learn more about the time release this item"
                        type="addChallenge"
                        isInline="true"
                      />
                      <div className="gap10" />

                      {this.state.isTimeReleaseThisItem && (
                        <>
                          <div className="tabsHolder radioTabs">
                            <RadioTag
                              key={this.state.itemOffsetType}
                              onCheckChange={this.onSelectItemOffsetType.bind(
                                this
                              )}
                              labelList={[
                                { name: "After Days", value: "days" },
                                { name: "On Specific Date", value: "date" },
                              ]}
                              id={"itemOffsetType"}
                              selectedList={[this.state.itemOffsetType]}
                              selectType={"value"}
                            />
                          </div>
                          {this.state.itemOffsetType === "days" ? (
                            <div className="row">
                              <div className="col-md-6">
                                <HelpTextDiv
                                  id="idChallengeOffsetDays"
                                  label="Learn more about the offset days"
                                  type="addChallenge"
                                  isInline="false"
                                />
                                <div className="mdInput mdFocussed">
                                  <label>Offset Days</label>
                                  <input
                                    type="number"
                                    className="offsetDays"
                                    name="itemOffsetDays"
                                    value={this.state.itemOffsetDays}
                                    onChange={(event) =>
                                      this.handleInputChange(event)
                                    }
                                  />
                                </div>
                                <div className="gap20" />
                              </div>
                            </div>
                          ) : (
                            <div className="row">
                              <div className="col-md-6">
                                <label className="inputFakeLabelFocussed">
                                  Select Date
                                </label>
                                <div>
                                  <DateTimePicker
                                    renderNumbers
                                    use12Hours
                                    onChange={this.onChangeItemOffsetDate.bind(
                                      this
                                    )}
                                    value={this.state.itemOffsetDate}
                                  />
                                </div>

                                <div className="gap20" />
                              </div>
                            </div>
                          )}
                          <div className="row">
                            <div className="col-md-6">
                              <div className="havingHelpText">
                                <Checkbox
                                  label={"Hide Before Release"}
                                  isChecked={this.state.isHideBeforeRelease}
                                  handleCheckboxChange={
                                    this.toggleHideBeforeReleaseCheckbox
                                  }
                                  key={this.state.keyHideBeforeRelease}
                                />
                              </div>
                              <HelpTextDiv
                                id="idHideBeforeRelease"
                                label="Learn more about the hide before release"
                                type="addChallenge"
                                isInline="true"
                              />
                              <div className="gap10" />
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}
                  {this.state.showAdvancedOptionsItems && (
                    <div>
                      <div className="havingHelpText">
                        {" "}
                        <Checkbox
                          label={"Allow Item to Lock"}
                          isChecked={this.state.isAllowedToLock}
                          handleCheckboxChange={this.toggleAllowLockCheckbox}
                          key={this.state.keyAllowLock}
                        />
                      </div>
                      <HelpTextDiv
                        id="idAllowItemlock"
                        label="Learn more about the allow item to lock"
                        type="addChallenge"
                        isInline="true"
                      />
                    </div>
                  )}
                  {this.state.showAdvancedOptionsItems &&
                    this.state.isAllowedToLock && (
                      <>
                        <div className="row">
                          <div className="col-md-6">
                            <HelpTextDiv
                              id="idChallengeLockDays"
                              label="Learn more about the lock days"
                              type="addChallenge"
                              isInline="false"
                            />
                            <div
                              className={
                                this.state.itemLockDays !== ""
                                  ? "mdInput mdFocussed"
                                  : "mdInput"
                              }>
                              <label>Lock Days</label>
                              <input
                                type="text"
                                className="lockDays"
                                name="itemLockDays"
                                value={this.state.itemLockDays}
                                onChange={(event) =>
                                  this.handleInputChange(event)
                                }
                              />
                            </div>
                            <div className="gap20" />
                          </div>
                          <div className="col-md-6">
                            <div className="gap20" />
                            <div className="gap20" />
                            <Checkbox
                              label={"Hide On Lock"}
                              isChecked={this.state.isHideOnLock}
                              handleCheckboxChange={this.toggleHideLockCheckbox}
                              key={this.state.keyHideOnLock}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  {this.state.showAdvancedOptionsItems && (
                    <div>
                      <div className="gap10" />
                      <div className="havingHelpText">
                        <Checkbox
                          label={
                            "Allow Item to be previewed before it is available"
                          }
                          isChecked={this.state.isAllowedToBePreviewed}
                          handleCheckboxChange={this.toggleAllowPreviewCheckbox}
                          key={this.state.keyAllowPreview}
                        />
                      </div>
                      <HelpTextDiv
                        id="idAllowItemPreviewed"
                        label="Learn more about the allow item to be previewed"
                        type="addChallenge"
                        isInline="true"
                      />
                    </div>
                  )}
                  {this.state.showAdvancedOptionsItems &&
                    this.state.isAllowedToBePreviewed && (
                      <>
                        <div className="row">
                          <div className="col-md-6">
                            <HelpTextDiv
                              id="idChallengeItemPreviewDays"
                              label="Learn more about the item preview days"
                              type="addChallenge"
                              isInline="false"
                            />
                            <div
                              className={
                                this.state.itemPreviewDays !== ""
                                  ? "mdInput mdFocussed"
                                  : "mdInput"
                              }>
                              <label>Preview Days</label>
                              <input
                                type="text"
                                className="lockDays"
                                name="itemPreviewDays"
                                value={this.state.itemPreviewDays}
                                onChange={(event) =>
                                  this.handleInputChange(event)
                                }
                              />
                            </div>
                            <div className="gap20" />
                          </div>
                        </div>
                      </>
                    )}
                  <HelpTextDiv
                    id="idChallengeItemSearchItems"
                    label="Learn more about the search item"
                    type="addChallenge"
                    isInline="false"
                  />
                  {this.state.showAdvancedOptionsItems && (
                    <>
                      <div className="gap10" />
                    </>
                  )}
                  <>
                    {this.state.selectedEditItemIndex === -1 && (
                      <div className="tabsHolder radioTabs">
                        <RadioTag
                          onCheckChange={this.onSelectSearchItemType.bind(this)}
                          labelList={[
                            { name: "Create a New Item", value: "new" },
                            { name: "Select Existing Item", value: "existing" },
                          ]}
                          id={"searchItemType"}
                          selectedList={[this.state.searchItemType]}
                          selectType={"value"}
                          key={this.state.keySearchItemType}
                        />
                      </div>
                    )}
                    {this.state.searchItemType === "existing" && (
                      <div className="addChallengeContainer">
                        {this.state.selectedEditItemIndex === -1 && (
                          <div className="column">
                            <TypeableSelect
                              key={this.state.keyItem}
                              name={"item"}
                              placeholder={"Search Item"}
                              selected={this.state.selectedItem}
                              onSelect={this.onSelectItem}
                              options={this.state.items}
                            />
                          </div>
                        )}

                        {/* {this.state.selectedItem.value && (
                          <div className="column">
                            <button
                              className={
                                "btn btn-info removeFocusDecoration float-left"
                              }
                              onClick={() => this.onClickEditChallengeItem()}>
                              Edit Item Content
                            </button>
                          </div>
                        )} */}
                      </div>
                    )}
                  </>

                  {this.state.searchItemType === "new" && (
                    <>
                      <div className="gap10" />
                      <button
                        className="btn btn-default btn-sm"
                        onClick={this.onClickAddNewItem.bind(this)}>
                        Add New Challenge Item
                      </button>
                    </>
                  )}
                  <div className="gap20" />
                  {this.state.showAdvancedOptionsItems && (
                    <>
                      <div className="havingHelpText">
                        <Checkbox
                          label={"Allow user to complete this item"}
                          isChecked={this.state.isCompleteItem}
                          handleCheckboxChange={this.toggleCompleteItemCheckbox}
                          key={this.state.keyCompleteItem}
                        />
                      </div>
                      {this.state.isCompleteItem && (
                        <>
                          <div className="row">
                            <div className="col-md-6">
                              <HelpTextDiv
                                id="idChallengeCompleteItemPoints"
                                label="Learn more about the complete item points"
                                type="addChallenge"
                                isInline="false"
                              />
                              <div
                                className={
                                  this.state.completeItemPoints !== ""
                                    ? "mdInput mdFocussed"
                                    : "mdInput"
                                }>
                                <label>Complete Item Points</label>
                                <input
                                  type="number"
                                  className="lockDays"
                                  name="completeItemPoints"
                                  value={this.state.completeItemPoints}
                                  onChange={(event) =>
                                    this.handleInputChange(event)
                                  }
                                />
                              </div>
                              <div className="gap20" />
                            </div>
                          </div>
                          <div className="havingHelpText">
                            <Checkbox
                              label={
                                "Automatically Mark This Item as Completed When All Videos are Watched"
                              }
                              isChecked={this.state.isVideoCompleteItem}
                              handleCheckboxChange={
                                this.toggleVideoCompleteItemCheckbox
                              }
                              key={this.state.keyVideoCompleteItem}
                            />
                          </div>
                          <div className="gap20" />
                          <div className="havingHelpText">
                            <Checkbox
                              label={
                                "Automatically Mark This Item as Completed When Open"
                              }
                              isChecked={this.state.isOpenCompleteItem}
                              handleCheckboxChange={
                                this.toggleOpenCompleteItemCheckbox
                              }
                              key={this.state.keyOpenCompleteItem}
                            />
                          </div>
                        </>
                      )}
                    </>
                  )}
                  <div className="text-right">
                    {/*eslint-disable*/}
                    {this.state.selectedEditItemIndex >= 0 && (
                      <a
                        className="btn btn-default margin-right-10"
                        onClick={() => this.onClickCancelItem()}>
                        Cancel
                      </a>
                    )}
                    {this.state.selectedItem.value.name && (
                      <a
                        className="btn btn-primary addQuestionButton"
                        onClick={() => this.onClickAddItem()}>
                        {this.state.selectedEditItemIndex >= 0
                          ? "Update Item"
                          : "Add Item"}
                      </a>
                    )}
                    {/*eslint-enable*/}
                  </div>
                  <div className="gap20" />
                </>
              )}

              {!isItems && (
                <>
                  <button
                    className="btn btn-default btn-sm"
                    onClick={this.onClickAddNewItem.bind(this)}>
                    Add New Challenge Item
                  </button>
                  <div className="gap20" />
                </>
              )}
            </div>
          </div>
        </div>

        <VelocityTransitionGroup
          enter={{ animation: "slideDown" }}
          leave={{ animation: "slideUp" }}>
          {this.state.showAddedItems &&
            this.state.challenge.challengeItemList.length > 0 && (
              <div className="container-fluid">
                <div className="card">
                  <div className="container-fluid">
                    <div className="gap20" />
                    <h5 className="noMargin">Added Items</h5>
                    <div className="gap20" />
                    {this.state.selectedItem.value &&
                      this.state.selectedEditItemIndex === -1 && (
                        <div className="card">
                          <div className="container-fluid">
                            <div className="gap10" />
                            <p className="text-danger">
                              You currently have an item selected above. Make
                              sure to click the "Add Item" button to add it to
                              the list below.
                            </p>
                          </div>
                        </div>
                      )}
                    <SortableQuestionList
                      items={this.state.challenge.challengeItemList}
                      onSortEnd={this.onSortEndItems}
                      showEdit={true}
                      showName={false}
                      onPressRemove={this.onPressRemoveItem.bind(this)}
                      onPressRemoveUndo={this.onPressRemoveItemUndo.bind(this)}
                      onPressRemoveConfirm={this.onPressRemoveItemConfirm.bind(
                        this
                      )}
                      onPressDuplicate={this.onPressDuplicate.bind(this)}
                      onPressEdit={this.onPressEditItem.bind(this)}
                      onPressEditContent={this.onPressEditItemContent.bind(
                        this
                      )}
                    />
                    <div className="gap20" />
                  </div>
                </div>
              </div>
            )}
        </VelocityTransitionGroup>
      </>
    );
  }

  onClickEditEmail = (email) => {
    let showBack = "y";
    this.props.history.push(
      `/admin/edit-email?eid=${email._id}&back=${showBack}`
    );
  };

  onClickEditNotification = async (notificationId) => {
    let notification = await callGetNotificationById(notificationId);
    this.props.history.push("/admin/add-notification", {
      notification: notification,
    });
  };

  onClickEditQuestion = async (challengeQuestionId, event) => {
    if (!event.ctrlKey) {
      let challengeQuestion = await callGetChlgQueById(challengeQuestionId);

      this.props.history.push("/admin/add-question", {
        challengeQuestion: challengeQuestion,
      });
    } else {
      window.open(`/admin/add-question?id=${challengeQuestionId}`, "_blank");
    }
  };

  onClickEditSMS = async (smsId) => {
    this.props.history.push(`/admin/add-sms?id=${smsId}`);
  };

  onClickArchiveEmail = (email) => {
    this.setState({ selectedEmail: email }, () => {
      $(
        ".modalAlertOverlay.confirmAlertOverlay,.modalAlert.confirmAlert"
      ).fadeIn(200);
    });
  };

  onClickNoAlert = () => {
    $(
      ".modalAlertOverlay.confirmAlertOverlay,.modalAlert.confirmAlert"
    ).fadeOut(200);
  };

  onClickYesAlert = () => {
    callUpdateEmail(this.state.selectedEmail._id, { status: "archive" }).then(
      (d) => {
        $(
          ".modalAlertOverlay.confirmAlertOverlay,.modalAlert.confirmAlert"
        ).fadeOut(200);
        this.getEmailsFromGroup(this.state.challenge.email_group_id);
      }
    );
  };

  renderEmails() {
    var returnVals = [];
    let emails = this.state.emails || [];
    emails.forEach((element) => {
      returnVals.push(
        <tr key={element._id}>
          <td>{element.name}</td>
          <td>{element.title}</td>
          <td className="min-width-100">
            <button
              title="Edit"
              onClick={this.onClickEditEmail.bind(this, element)}
              className="btn btn-default btn-sm margin-right-10 border-0">
              <i className="fa fa-pencil" />
            </button>
            <button
              title="Archive"
              onClick={this.onClickArchiveEmail.bind(this, element)}
              className="btn btn-danger btn-sm  border-0">
              <i className="fa fa-archive" />
            </button>
          </td>
        </tr>
      );
    });
    return returnVals;
  }

  async onClickCreateCheckouItem() {
    if (this.state.checkoutAmount.toString().trim().length === 0) {
      showNotification("error", "Amount is required", 4000);
      return;
    }
    let chlgOp = new ChallengeOperations();
    let { checkoutIds, checkoutItem } = await chlgOp.createCheckoutItem(
      this.state.challenge,
      this.state.checkoutAmount,
      {
        promoCodeAvailable: this.state.promoCodeAvailable,
        promoCodes: this.state.pCodes,
        promoType: "add",
      }
    );
    let challenge = this.state.challenge;
    challenge["checkout_ids"] = checkoutIds;
    if (checkoutItem._id) {
      this.setState(
        {
          checkoutAmount: "",
          challenge: challenge,
          selectedPromoCodes: [],
          pCodes: [],
          keyPromoCode: Math.random(),
        },
        () => {
          this.getCheckoutItems(challenge);
        }
      );
      showNotification("success", "Checkout created.", 4000);
    }
  }

  onClickEditCheckoutItem(element) {
    this.props.history.push("/admin/add-checkout-item", {
      checkoutItem: element,
      type: "edit",
    });
  }

  getCheckoutUrl(element) {
    let domainList = this.state.domainList;
    let checkoutUrls = domainList.filter((d) => {
      return d.type === "checkout";
    });
    let checkoutUrl = "https://checkout.memberpages.app";
    if (checkoutUrls.length > 0) {
      checkoutUrl = checkoutUrls[0].domain;
      checkoutUrl = `https://${checkoutUrl}`;
    }
    return `${checkoutUrl}?p=${element.checkout_id}&key=${this.state.accountKey}`;
  }

  onClickArchive = (item) => {
    this.setState({ selectedCheckoutItem: item }, () => {
      $(".modalAlertOverlay.archiveOverlay,.modalAlert.archiveAlert").fadeIn(
        200
      );
    });
  };

  onClickNoArchiveAlert = () => {
    $(".modalAlertOverlay.archiveOverlay,.modalAlert.archiveAlert").fadeOut(
      200
    );
  };

  onClickYesArchiveAlert = () => {
    callUpdateCheckoutItem(this.state.selectedCheckoutItem._id, {
      archive: "archive",
      active: false,
    }).then((d) => {
      $(".modalAlertOverlay.archiveOverlay,.modalAlert.archiveAlert").fadeOut(
        200
      );
      this.getCheckoutItems(this.state.challenge);
    });
  };

  renderArchiveAlert() {
    let selectedItem = this.state.selectedCheckoutItem;
    let name = "";
    if (selectedItem.name) {
      name = selectedItem.name;
    }
    return (
      <div>
        <div className="modalAlertOverlay archiveOverlay" />
        <div className="modalAlert archiveAlert">
          <p>{`Are you sure you want to archive "${name}" checkout item?`}</p>
          <div className="alertFooterBtns">
            <button
              className="btn btn-default closeModal-No margin-right-10"
              onClick={this.onClickNoArchiveAlert}>
              No
            </button>
            <button
              className="btn btn-primary closeModal-Yes"
              onClick={this.onClickYesArchiveAlert}>
              Yes
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderCheckoutItems() {
    var returnVals = [];
    let items = this.state.checkoutItems || [];
    items.forEach((element) => {
      returnVals.push(
        <tr key={element._id}>
          <td>{element.name}</td>
          <td>${element.amount}</td>
          <td className="min-width-100">
            <button
              title="Edit"
              onClick={this.onClickEditCheckoutItem.bind(this, element)}
              className="btn btn-default btn-sm margin-right-10 border-0">
              <i className="fa fa-pencil" />
            </button>
            <CopyToClipboard
              title="Copy Checkout Url"
              text={this.getCheckoutUrl(element)}
              onCopy={() =>
                showNotification("success", "Link copied to clipboard", 5000)
              }>
              <button className="btn btn-sm btn-default border-0 margin-right-10">
                <i className="fa fa-clipboard" />
              </button>
            </CopyToClipboard>
            <button
              title="Archive"
              onClick={this.onClickArchive.bind(this, element)}
              className="btn btn-danger btn-sm  border-0">
              <i className="fa fa-archive" />
            </button>
          </td>
        </tr>
      );
    });
    return returnVals;
  }

  togglePromoCodeAvailable = (check) => {
    this.setState({
      promoCodeAvailable: check,
    });
  };

  onChangePromoCode = (codes) => {
    this.setState({ pCodes: codes });
  };

  renderCheckoutSection() {
    return (
      <div className="container-fluid">
        <div className="card">
          <div className="container-fluid">
            <div className="gap20" />
            <h5 className="noMargin">Create Sales Checkout</h5>
            <div className="gap20" />
            <label>How much do you want to sell this for?</label>
            <div className="row">
              <div className="col-md-2">
                <div
                  className={
                    this.state.checkoutAmount !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Amount</label>
                  <input
                    type="number"
                    className="challengeName"
                    name="checkoutAmount"
                    value={this.state.checkoutAmount}
                    onChange={(event) => this.handleInputChange(event)}
                  />
                </div>
              </div>
            </div>
            <div className="gap20" />
            <Checkbox
              label={"Promo Code Available"}
              isChecked={this.state.promoCodeAvailable}
              handleCheckboxChange={this.togglePromoCodeAvailable}
              key={this.state.keyPromoCodeAvailable}
            />
            <div className="gap20" />

            {this.state.promoCodeAvailable && (
              <>
                <TagSelect
                  id={"add-checkout-item-promo-type"}
                  key={this.state.keyPromoCode}
                  selectedValues={this.state.selectedPromoCodes}
                  searchKey={"title"}
                  selectKey={"title"}
                  lblText={"Select Promo Code"}
                  data={this.state.promoCodes}
                  onChange={this.onChangePromoCode}
                  label={"Promo Codes"}
                  showInfo={false}
                  reload={() => {
                    this.getSystemTags();
                  }}
                />
              </>
            )}
            <div className="gap30" />
            <button
              title="Edit"
              onClick={this.onClickCreateCheckouItem.bind(this)}
              className="btn btn-primary btn-sm">
              Create Now
            </button>
            <div className="gap20" />
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Amount</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>{this.renderCheckoutItems()}</tbody>
            </table>
            <div className="gap10" />
          </div>
        </div>
      </div>
    );
  }

  onClickEditUser = (user) => {
    if (user._id) {
      let url = `/admin/edit-user?id=${user._id}`;
      // window.open(url, "_blank");
      this.props.history.push(url);
    }
  };

  onClickInfoChallengeUser = (user) => {
    this.setState({ selectedUser: user }, () => {
      $(".modalAlertOverlay.infoOverlay,.modalAlert.infoAlert").fadeIn(200);
    });
  };

  onClickCloseFromInfoModal() {
    $(".modalAlertOverlay.infoOverlay,.modalAlert.infoAlert").fadeOut(200);
  }

  renderInfoModal() {
    let modalHeight = window.innerHeight - 200;
    let itemInfo = this.state.selectedUser.itemInfo || [];

    return (
      <div>
        <div className="modalAlertOverlay infoOverlay" />
        <div className="modalAlert infoAlert modal-lg big">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title margin-right-10"
                id="exampleModalLabel">
                Challenge Items
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.onClickCloseFromInfoModal.bind(this)}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div
              className="container-fluid modalInnerBody"
              style={{ maxHeight: modalHeight, overflowY: "hidden" }}>
              <Scrollbars
                autoHide
                autoHideTimeout={1000}
                autoHideDuration={200}
                style={{ height: modalHeight }}>
                <div className="gap20" />

                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Item</th>
                      <th>Completed</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {itemInfo.map((i) => (
                      <tr key={i.id}>
                        <td>{i.itemName}</td>
                        <td>{i.completed ? "Yes" : "No"}</td>
                        <td>
                          {i.date ? moment(i.date).format("Do MMM YYYY") : ""}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Scrollbars>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderChallengeUsers() {
    var returnVals = [];
    let items = this.state.challengeUsers || [];
    items.forEach((element) => {
      returnVals.push(
        <tr key={element._id}>
          <td>{element.name}</td>
          <td>{element.email_address}</td>
          <td>
            {element.startDate
              ? moment(element.startDate).format("Do MMM YYYY")
              : "-"}
          </td>
          <td>
            {element.perCompleted || "-"}{" "}
            {element.perCompleted && (
              <i
                className="fa fa-info-circle"
                onClick={this.onClickInfoChallengeUser.bind(this, element)}
              />
            )}
          </td>
          <td className="min-width-100">
            <button
              title="Edit"
              onClick={this.onClickEditUser.bind(this, element)}
              className="btn btn-default btn-sm margin-right-10 border-0">
              <i className="fa fa-pencil" />
            </button>
          </td>
        </tr>
      );
    });
    return returnVals;
  }

  renderPendingUsers() {
    var returnVals = [];
    let items = this.state.pendingUsers || [];
    items.forEach((element) => {
      returnVals.push(
        <tr key={element._id}>
          <td className="firstLetter">{element.name}</td>
          <td>{element.email_address}</td>
        </tr>
      );
    });
    return returnVals;
  }

  onClickAddUserChallenge() {
    this.props.history.push("/admin/add-user", {
      isPlaceOrder: true,
      billing: "n",
      challengeId: this.state.challenge._id,
    });
  }

  onSelectForm = (form) => {
    this.setState({ selectedForm: form });
  };

  renderAccessForm() {
    return (
      <div className="container-fluid">
        <div className="card">
          <div className="container-fluid">
            <div className="gap20" />
            <div className="row">
              <div className="col-md-10">
                <div className="alert alert-info">
                  The agreement contract will be added to any user who has the
                  tag
                  {` ${slugify(this.state.challenge.name)}-agreement-form`}
                </div>
              </div>
              <div className="col-md-2">
                <div className="gap5" />
                <button
                  className="btn btn-default"
                  onClick={this.onClickAddNewForm.bind(this)}>
                  Add New Form
                </button>
              </div>
            </div>

            <TypeableSelect
              key={this.state.keyForm}
              name={"form"}
              placeholder={"Select Form"}
              selected={this.state.selectedForm}
              onSelect={this.onSelectForm}
              options={this.state.forms}
            />
            <div className="gap30" />
            <button
              title="Save"
              onClick={this.onClickAddChallenge.bind(this)}
              className="btn btn-primary btn-sm">
              Save
            </button>
            <div className="gap20" />
          </div>
        </div>
      </div>
    );
  }

  onSelectStatus(item, id) {
    let value = item[Object.keys(item)[0]].value;
    this.setState({ [id]: value });
    if (value === "all") {
      this.setState({ challengeUsers: this.state.backupChallengeUsers });
    }
    if (value === "completed") {
      let users = this.state.backupChallengeUsers.filter((i) => i.completed);
      this.setState({ challengeUsers: users });
    }
    if (value === "active") {
      let users = this.state.backupChallengeUsers.filter((i) => !i.completed);
      this.setState({ challengeUsers: users });
    }
  }

  renderUsersSection() {
    let tagName = "";
    if (this.state.challenge) {
      tagName = `${slugify(this.state.challenge.name)}-auto-signup`;
    }
    return (
      <div className="container-fluid">
        <div className="card">
          <div className="container-fluid">
            <div className="gap20" />
            {this.state.autoSignupTag && (
              <p>
                Note: Giving users the tag <code>{tagName}</code> will
                automatically enroll users in the challenge (This will bypass
                any billing) if needed.
              </p>
            )}
            {this.state.pendingUsers.length > 0 && (
              <>
                <div className="gap20" />
                <p>
                  Challenge being created for following users. Please refresh
                  this page again after 1 minute.
                </p>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                    </tr>
                  </thead>
                  <tbody>{this.renderPendingUsers()}</tbody>
                </table>
              </>
            )}
            <div className="gap20" />
            <div className="tabsHolder radioTabs">
              <RadioTag
                onCheckChange={this.onSelectStatus.bind(this)}
                labelList={[
                  { name: "All", value: "all" },
                  { name: "Active", value: "active" },
                  { name: "Completed", value: "completed" },
                ]}
                id={"challengeUserStatus"}
                selectedList={[this.state.challengeUserStatus]}
                selectType={"value"}
              />
            </div>
            <div className="gap20" />
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Start Date</th>
                  <th>Progress</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>{this.renderChallengeUsers()}</tbody>
            </table>
            <div className="gap10" />
            <button
              title="Add user"
              onClick={this.onClickAddUserChallenge.bind(this)}
              className="btn btn-primary btn-sm">
              Add User
            </button>
            <div className="gap10" />
          </div>
        </div>
      </div>
    );
  }

  handleMessageChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    const isGMSMessage = checkGMSMessage(value);
    const limit = isGMSMessage ? 140 : 67;

    let noOfMessages = Math.ceil(value.length / limit);

    this.setState({
      [name]: value,
      isGMSMessage: isGMSMessage,
      noOfMessages,
    });
  }

  getNoOfMessages() {
    let noOf = this.state.noOfMessages;
    return noOf;
  }

  async onClickAddAtq() {
    let dOpe = new ChallengeOperations();

    let response = await dOpe.createQuestionTag(
      this.state.challenge.name,
      this.state.onSpecificDay
    );
    /*eslint-enable*/
    let tagValue = response.tagValue;
    let tagIdN = response.tagId;
    this.setState(
      {
        popupType: "atqQuestion",
        popupLabel: "Add New Accountability Question",
        popupKey: Math.random(),
        queTagId: tagIdN,
        queTagValue: tagValue,
        queTag: response.tag,
      },
      () => {
        openPagePopupModal("atqQuestion");
      }
    );
  }

  getFirstName() {
    return "{{first_name}}";
  }

  renderRuleSection() {
    return (
      <div className="container-fluid">
        <div className="card">
          <div className="container-fluid">
            <div className="gap10" />
            <p>You must set a duration for certain rules to work.</p>
            <p>
              Rules allow you to send email &amp; more. You can set rules to be
              added on specific days of your challenge.
            </p>

            <p>
              A few rules have automatically been generated for you already: A
              signup rule, a cancellation rule, and a completion rule. These
              rules are tied to emails and will be given at the corresponding
              times.
            </p>
            <p>
              So for example when a new member is registered for your challenge
              they will automatically be given a signup tag (and sent a signup
              email).
            </p>

            <p>
              Further below you can see a list of all emails corresponding to
              your challenge.
            </p>

            <p>
              You can add as many rules and emails as you would like into your
              challenge.
            </p>
          </div>
        </div>
        <div className="card">
          <div className="container-fluid">
            <div className="gap20" />
            <h5 className="noMargin">Add Rules</h5>
            <div className="gap20" />
            <div className="row">
              <div className="col-sm-4">
                <HelpTextDiv
                  id="idChallengeRuleType"
                  label="Learn more about the rule type"
                  type="addChallenge"
                  isInline="false"
                />
                <div
                  className={
                    this.state.ruleType !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Select Rule Type</label>
                  <select
                    className="selectRuleTypeInput"
                    name="ruleType"
                    value={this.state.ruleType}
                    onChange={(event) => this.handleInputChange(event)}>
                    <option value="" />
                    <option value="on_specific_day">On Day</option>
                    <option value="days_not_tracked">Days Not Tracked</option>
                    <option value="at_signup">At Signup</option>
                    <option value="on_last_day">On Last Day</option>
                    <option value="on_cancel">On Cancel</option>
                    <option value="on_specific_date">On Date</option>
                    <option value="on_weekday">On Weekday</option>
                    <option value="per_completed">% Completed</option>
                    <option value="no_activity">No Activity</option>
                  </select>
                </div>
                <div className="gap20" />
              </div>
              {this.state.ruleType === "on_specific_day" && (
                <div className="col-sm-4">
                  <HelpTextDiv
                    id="idChallengeEnterDay"
                    label="Learn more about the enter day"
                    type="addChallenge"
                    isInline="false"
                  />
                  <div
                    className={
                      this.state.onSpecificDay !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>Enter Day</label>
                    <input
                      type="text"
                      className="onDayInput"
                      name="onSpecificDay"
                      value={this.state.onSpecificDay}
                      onChange={(event) => this.handleInputChange(event)}
                    />
                  </div>
                  <div className="gap20" />
                </div>
              )}
              {this.state.ruleType === "per_completed" && (
                <div className="col-sm-4">
                  <div
                    className={
                      this.state.perCompleted !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>Percentage</label>
                    <input
                      type="number"
                      className="onDayInput"
                      name="perCompleted"
                      value={this.state.perCompleted}
                      onChange={(event) => this.handleInputChange(event)}
                    />
                  </div>
                  <div className="gap20" />
                </div>
              )}
              {this.state.ruleType === "no_activity" && (
                <div className="col-sm-4">
                  <div
                    className={
                      this.state.noActivityDays !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>X Days</label>
                    <input
                      type="number"
                      className="onDayInput"
                      name="noActivityDays"
                      value={this.state.noActivityDays}
                      onChange={(event) => this.handleInputChange(event)}
                    />
                  </div>
                  <div className="gap20" />
                </div>
              )}
              {this.state.ruleType === "on_weekday" && (
                <div className="col-sm-4">
                  <div
                    className={
                      this.state.onWeekday !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <select
                      name="onWeekday"
                      value={this.state.onWeekday}
                      onChange={(event) => this.handleInputChange(event)}>
                      <option value="monday">Monday</option>
                      <option value="tuesday">Tuesday</option>
                      <option value="wednesday">Wednesday</option>
                      <option value="thursday">Thursday</option>
                      <option value="friday">Friday</option>
                      <option value="saturday">Saturday</option>
                      <option value="sunday">Sunday</option>
                    </select>
                  </div>
                </div>
              )}
              {this.state.ruleType === "on_specific_date" && (
                <div className="col-sm-4">
                  <HelpTextDiv
                    id="idSystemTagRuleDate"
                    label="Learn more about the Date"
                    type="addSystemTag"
                    isInline="false"
                  />

                  <label className="inputFakeLabelFocussed">Select Date</label>
                  <div>
                    <DatePicker
                      value={this.state.onSpecificDate}
                      onChange={this.onChangeRuleDate.bind(this)}
                    />
                  </div>
                </div>
              )}
              {this.state.ruleType === "days_not_tracked" && (
                <div className="col-sm-4">
                  <HelpTextDiv
                    id="idChallengeDayNotTracked"
                    label="Learn more about the day not tracked"
                    type="addChallenge"
                    isInline="false"
                  />
                  <div
                    className={
                      this.state.daysNotTracked !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>Day Not Tracked</label>
                    <input
                      type="text"
                      className="notTrackedInput"
                      name="daysNotTracked"
                      value={this.state.daysNotTracked}
                      onChange={(event) => this.handleInputChange(event)}
                    />
                  </div>
                  <div className="gap20" />
                </div>
              )}
              {this.state.ruleType !== "" && (
                <div className="col-sm-4">
                  <HelpTextDiv
                    id="idChallengeAction"
                    label="Learn more about the action"
                    type="addChallenge"
                    isInline="false"
                  />

                  <div
                    className={
                      this.state.actionType !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>Select Action</label>
                    <select
                      className="actionSelect"
                      name="actionType"
                      value={this.state.actionType}
                      onChange={(event) => this.handleInputChange(event)}>
                      <option value="" />
                      <option value="add-tag">Add A Tag</option>
                      {this.state.ruleType !== "per_completed" && (
                        <>
                          <option value="inactivate-tag">
                            Inactivate A Tag
                          </option>
                          <option value="add-email">Add an Email</option>
                          <option value="add-sms">Add SMS</option>
                          <option value="add-notification">
                            Add Notification
                          </option>
                          <option value="add-atq">Add Accountability</option>
                        </>
                      )}
                    </select>
                  </div>
                  <div className="gap20" />
                </div>
              )}
            </div>
            <div className="gap20" />
            {this.state.actionType === "add-tag" && (
              <>
                <div className="tabsHolder radioTabs">
                  <RadioTag
                    onCheckChange={this.onSelectTagType.bind(this)}
                    labelList={[
                      { name: "Select Existing Tag", value: "existing" },
                      { name: "Create New Tag", value: "new" },
                    ]}
                    id={"tagType"}
                    selectedList={[this.state.tagType]}
                    selectType={"value"}
                    key={this.state.keyTagType}
                  />
                </div>
                {this.state.tagType === "existing" && (
                  <div
                    className={
                      this.state.tagValue !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>Search Tag</label>
                    <AutoSuggest
                      value={this.state.tagValue}
                      key={this.state.keyTag}
                      searchKey="name"
                      data={this.state.tags}
                      onChange={this.onChangeTagValue.bind(this)}
                      onSelect={this.onSelectTagValue.bind(this)}
                    />
                  </div>
                )}
                {this.state.tagType === "new" && (
                  <div className="row">
                    <div className="col-sm-4">
                      <div
                        className={
                          this.state.tagName !== ""
                            ? "mdInput mdFocussed"
                            : "mdInput"
                        }>
                        <label>Tag Name</label>
                        <input
                          type="text"
                          name="tagName"
                          value={this.state.tagName}
                          onChange={(event) => this.handleInputChange(event)}
                        />
                      </div>
                      <div className="gap20" />
                    </div>
                    <div className="col-sm-4">
                      <div className="gap30" />
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={this.onClickCreateTag.bind(this)}>
                        Create
                      </button>
                    </div>
                  </div>
                )}
                {this.state.ruleType === "per_completed" && (
                  <p style={{ paddingTop: 10 }}>
                    Please make sure any tag that is added here is set to only
                    ever be added one time (you can set the days limit to 9999)
                    this way users will not get tags everytime they click the
                    completed boxes.
                  </p>
                )}
              </>
            )}
            {this.state.actionType === "inactivate-tag" && (
              <div
                className={
                  this.state.tagValue !== "" ? "mdInput mdFocussed" : "mdInput"
                }>
                <label>Search Tag</label>
                <AutoSuggest
                  value={this.state.tagValue}
                  key={this.state.keyTag}
                  searchKey="name"
                  data={this.state.tags}
                  onChange={this.onChangeTagValue.bind(this)}
                  onSelect={this.onSelectTagValue.bind(this)}
                />
              </div>
            )}

            {this.state.actionType === "add-email" && (
              <div>
                <div
                  className={
                    this.state.emailName !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Email Name</label>
                  <input
                    type="text"
                    className="challengeName"
                    name="emailName"
                    value={this.state.emailName}
                    onChange={(event) => this.handleInputChange(event)}
                  />
                </div>
                <div className="gap20" />
                <PageContent
                  key={this.state.keyContent}
                  title={this.state.challenge.name}
                  displayMode={"email"}
                  previewType={"iframe"}
                  screen={"add-challenge-email"}
                  html={this.state.emailHtml}
                  content={this.state.emailContent}
                  editorType={"unlayer"}
                  onChangeData={this.onChangeEmailContent.bind(this)}
                  pageName={"addChallengeEmail"}
                />
                <div className="gap10" />
                {this.renderSendTime()}
              </div>
            )}

            {this.state.actionType === "add-sms" && (
              <div>
                <div className="alert alert-info">
                  When sending automated sms messages as challenge rules please
                  be sure they are useful and specific and preferably something
                  the user will respond back to. For example a good message is
                  "Hey {this.getFirstName()}, thank you for completing XYZ. I
                  would really love to get your feedback on the course. Can you
                  please let me know what you thought about it?"
                  <br />
                  <br />A message that would be better sent as an app
                  notification would be something like: "Hey{" "}
                  {this.getFirstName()} It's day one of your challenge and you
                  can click here to view the content now." The customer is not
                  expected to respond to this message and it is more likely to
                  be viewed as spam via the phone carriers.
                </div>
                <div
                  className={
                    this.state.smsName !== "" ? "mdInput mdFocussed" : "mdInput"
                  }>
                  <label>Name</label>
                  <input
                    type="text"
                    name="smsName"
                    onChange={this.handleInputChange.bind(this)}
                    value={this.state.smsName}
                  />
                </div>
                <div className="gap10" />
                <div
                  className={
                    this.state.smsMessage !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Message</label>
                  <textarea
                    style={{ height: "auto" }}
                    rows={5}
                    type="text"
                    name="smsMessage"
                    onChange={this.handleMessageChange.bind(this)}
                    value={this.state.smsMessage}
                  />
                </div>
                <div className="gap10" />
                <label>
                  {this.state.smsMessage.length} /{" "}
                  {this.state.isGMSMessage ? "140" : "67"}
                </label>
                {!this.state.isGMSMessage && (
                  <>
                    <div className="gap20" />
                    <label>
                      Note: Special characters detected. Message character limit
                      set to 67 characters.
                    </label>
                  </>
                )}
                {this.getNoOfMessages() > 1 && (
                  <>
                    <div className="gap20" />
                    <label className="text-primary">
                      This message has more characters than the limit. You can
                      still send it as a single message but will be charged for{" "}
                      {this.getNoOfMessages()} messages per user. This is due to
                      the network carrier fees etc.
                    </label>
                  </>
                )}
                <div className="gap20" />
                {this.renderSendTime()}
              </div>
            )}

            {this.state.actionType === "add-notification" && (
              <div>
                <div
                  className={
                    this.state.notiTitle !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Title</label>
                  <input
                    type="text"
                    name="notiTitle"
                    onChange={this.handleInputChange.bind(this)}
                    value={this.state.notiTitle}
                  />
                </div>
                <div className="gap10" />

                <div
                  className={
                    this.state.notiBody !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Body</label>
                  <textarea
                    name="notiBody"
                    onChange={this.handleInputChange.bind(this)}
                    value={this.state.notiBody}
                  />
                </div>
                <div className="gap10" />
                {this.renderSendTime()}
                {this.renderScreen()}
              </div>
            )}

            {this.state.actionType === "add-atq" && (
              <div>
                <div
                  className={
                    this.state.questionName !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Question Name</label>
                  <input
                    type="text"
                    name="questionName"
                    onChange={this.handleInputChange.bind(this)}
                    value={this.state.questionName}
                  />
                </div>
                <div className="gap10" />
                {this.state.questionName.length > 0 && (
                  <button
                    className="btn btn-primary addRuleBtn"
                    onClick={() => this.onClickAddAtq()}>
                    Add Accountability Answers
                  </button>
                )}
              </div>
            )}
            <div className="gap20" />
            <div className="text-right">
              <button
                className="btn btn-primary addRuleBtn"
                onClick={() => this.onClickAddRule()}>
                Add Rule
              </button>
            </div>
            <div className="gap20" />
          </div>
        </div>

        {this.state.challenge.rules.length > 0 && (
          <div className="card">
            <div className="container-fluid">
              <div className="gap20" />
              <h5 className="noMargin">Added Rules</h5>
              <div className="gap20" />
              {this.state.isRuleLoading ? (
                <div className="addedRule">Loading ...</div>
              ) : (
                <div className="addedRule">{this.renderRules()}</div>
              )}

              <div className="gap20" />
            </div>
          </div>
        )}

        <div className="card">
          <div className="container-fluid">
            <div className="gap20" />
            <h5 className="noMargin">Emails</h5>
            <div className="gap20" />
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Title</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>{this.renderEmails()}</tbody>
            </table>
            <div className="gap10" />
            <button
              onClick={this.onClickAddNewEmail.bind(this)}
              className="btn btn-primary btn-sm margin-right-10">
              Add New Email
            </button>
            <div className="gap10" />
          </div>
        </div>
      </div>
    );
  }

  insertUrlParam(key, value) {
    if (window.history.pushState) {
      let searchParams = new URLSearchParams(window.location.search);
      searchParams.set(key, value);
      let newurl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        "?" +
        searchParams.toString();
      window.history.pushState({ path: newurl }, "", newurl);
    }
  }

  onClickTab(tab) {
    this.insertUrlParam("tab", tab);
    let tabOptions = this.state.tabOptions;
    tabOptions.forEach((element) => {
      element.selected = element.key === tab;
    });
    this.setState({
      tabOptions,
      selectedTab: tab,
    });
  }

  renderTabOptions() {
    let tabOptions = this.state.tabOptions;
    let returnVals = [];
    for (let i = 0; i < tabOptions.length; i++) {
      const element = tabOptions[i];
      const dataTab = `.for-tab${i + 1}`;
      /*eslint-disable*/
      if (element.key === "form") {
        let createdAt = moment(this.state.challenge.createdAt);
        let sepDate = moment("2022-09-08");
        if (createdAt < sepDate) {
          continue;
        }
      }
      returnVals.push(
        <a
          className={element.selected ? "active" : ""}
          onClick={() => this.onClickTab(element.key)}
          data-for={dataTab}>
          {element.label}
        </a>
      );
      /*eslint-enable*/
    }
    return returnVals;
  }

  renderConfirmationAlert() {
    let selectedEmail = this.state.selectedEmail;
    let name = "";
    if (selectedEmail.name) {
      name = selectedEmail.name;
    }
    return (
      <div>
        <div className="modalAlertOverlay confirmAlertOverlay" />
        <div className="modalAlert confirmAlert">
          <p>{`Are you sure you want to archive "${name}" email?`}</p>
          <div className="alertFooterBtns">
            <button
              className="btn btn-default closeModal-No margin-right-10"
              onClick={this.onClickNoAlert}>
              No
            </button>
            <button
              className="btn btn-primary closeModal-Yes"
              onClick={this.onClickYesAlert}>
              Yes
            </button>
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.loadingPage) {
      return <div id="loader"></div>;
    }
    return (
      <Onboarding history={this.props.history}>
        <div>
          {this.renderConfirmationAlert()}
          {this.renderUpdateGroupModal()}
          {this.renderArchiveAlert()}
          {this.renderInfoModal()}
          <PreviewChallenge
            ref={this.previewChlgRef}
            history={this.props.history}
          />

          <PagePopup
            type={this.state.popupType}
            label={this.state.popupLabel}
            challengeItem={this.state.popupChallengeItem}
            key={this.state.popupKey}
            onCreate={this.onCreateNewItem.bind(this)}
            challengeId={this.state.challenge._id}
            systemTagId={this.state.systemTagId}
            questionTag={this.state.queTag}
            challengeInfo={this.state.challegeInfo}
            questionName={this.state.questionName}
            newQuestionType={this.state.newQuestionType}
            systemTagInfo={this.state.popupSystemTag}
          />

          <div className="container-fluid">
            <TitleBox
              title={
                this.state.editMode ? "Edit Challenge" : "Create A Challenge"
              }
              showBackBtn={this.state.editMode}
            />
            <div className="gap20" />
          </div>

          <div className="container-fluid">
            <div className="card">
              {this.state.editMode && (
                <div className="profileTab">
                  <div className="modalHeaderTabs">
                    {this.renderTabOptions()}
                  </div>
                </div>
              )}
              <div className="gap20" />

              {this.state.selectedTab === "details" && (
                <div
                  className={
                    this.state.selectedTab === "details"
                      ? "active for-tab1"
                      : "for-tab1"
                  }>
                  {this.renderDetails()}
                </div>
              )}

              {this.state.selectedTab === "content" && (
                <div
                  className={
                    this.state.selectedTab === "content"
                      ? "active for-tab2"
                      : "for-tab2"
                  }>
                  {this.renderPageContent()}
                </div>
              )}

              {this.state.selectedTab === "atq" && (
                <div
                  className={
                    this.state.selectedTab === "atq"
                      ? "active for-tab4"
                      : "for-tab4"
                  }>
                  {this.renderQuestions()}
                </div>
              )}

              {this.state.selectedTab === "items" && (
                <div
                  className={
                    this.state.selectedTab === "items"
                      ? "active for-tab3"
                      : "for-tab3"
                  }>
                  {this.renderItems()}
                </div>
              )}

              {this.state.selectedTab === "rules" && (
                <div
                  className={
                    this.state.selectedTab === "rules"
                      ? "active for-tab6"
                      : "for-tab6"
                  }>
                  {this.renderRuleSection()}
                </div>
              )}

              {this.state.selectedTab === "checkout" && (
                <div
                  className={
                    this.state.selectedTab === "checkout"
                      ? "active for-tab7"
                      : "for-tab7"
                  }>
                  {this.renderCheckoutSection()}
                </div>
              )}

              {this.state.selectedTab === "users" && (
                <div
                  className={
                    this.state.selectedTab === "users"
                      ? "active for-tab7"
                      : "for-tab7"
                  }>
                  {this.renderUsersSection()}
                </div>
              )}

              {this.state.selectedTab === "form" && (
                <div
                  className={
                    this.state.selectedTab === "form"
                      ? "active for-tab8"
                      : "for-tab8"
                  }>
                  {this.renderAccessForm()}
                </div>
              )}
            </div>
          </div>

          <div className="container-fluid">
            <div className="text-right">
              {this.state.editMode && (
                <button
                  className="btn btn-default margin-right-10"
                  onClick={() => this.onClickPreviewChallenge()}>
                  Preview
                </button>
              )}
              {this.state.selectedTab === "details" && (
                <>
                  {!this.state.processing ? (
                    <button
                      className="btn btn-primary"
                      onClick={() => this.onClickAddChallenge()}>
                      {this.state.editMode
                        ? "Update Challenge"
                        : "Create Challenge"}
                    </button>
                  ) : (
                    <button className="btn btn-primary">
                      <img
                        src={`https://${window.location.host}/images/loading3.gif`}
                        alt="loading"
                      />
                      &nbsp;&nbsp;{" "}
                      {this.state.editMode
                        ? "Updating Challenge"
                        : "Creating Challenge"}
                    </button>
                  )}
                </>
              )}
            </div>

            <div className="gap20" />
          </div>
        </div>
      </Onboarding>
    );
  }
}

AddChallenge.propTypes = {
  message: PropTypes.string,
  success: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  message: state.challengeReducer.message,
  success: state.challengeReducer.success,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      resetStatus,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(AddChallenge);
