import React from "react";

import RadioTag from "../../../components/form/radioTag";
import Checkbox from "../../../components/form/checkBox";

import { callGetUsersByQuery } from "../../../services";
import {
  SortableTaskFieldList,
  SortableFieldOptionList,
} from "../../../components/form/sortableList";
import { arrayMove } from "react-sortable-hoc/dist/commonjs/utils";
import { showNotification } from "../../../helpers";
import {
  callGetSettings,
  callSaveSettings,
} from "../../../services/settingService";
import TitleBox from "../../../components/general/titleBox";

class InvoiceSettings extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      settingId: "",
      customFields: [],
      fieldLabel: "",
      fieldListName: "",
      fieldType: "single",
      isDisplay: false,
      keyIsDisplay: Math.random(),
      selectedFieldIndex: -1,
      options: [],
      fieldId: "",
      optionLabel: "",
      optionId: "",
      optionSelected: false,
      keyOptionSelected: Math.random(),
      selectedOptionIndex: -1,
      keyRadioSelect: Math.random(),
      hideInvoices: false,
      keyHideInvoices: Math.random(),
    };
  }

  componentDidMount() {
    this.procecssUsers();
    this.getSettings();
  }

  async getSettings() {
    let d = await callGetSettings("invoicesettings");
    let settings = d.data;
    let invoiceSettings = settings.invoice_settings || {};
    let customFields = invoiceSettings.custom_fields || [];

    let hideInvoices = invoiceSettings.hide_invoices;

    this.setState({
      customFields: customFields,
      settingId: settings._id,
      hideInvoices: hideInvoices,
      keyHideInvoices: Math.random(),
    });
  }

  procecssUsers() {
    /*eslint-disable*/
    let query = {
      query: {
        member_type: "staff",
        status: { $ne: "archive" },
        $select: ["_id", "name"],
        populate: "no",
      },
    };
    /*eslint-enable*/

    callGetUsersByQuery(query).then((d) => {
      let fUsers = d.data;
      let watcherList = [];
      fUsers.forEach((element) => {
        watcherList.push({ name: element.name, value: element._id });
      });
      this.setState({
        watcherList: watcherList,
        keyWatcherRadio: Math.random(),
      });
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  onSelectWatcher(item, id) {
    let defaultWatchers = [];

    Object.keys(item).forEach((obj) => {
      defaultWatchers.push(item[obj].value);
    });

    this.setState({
      defaultWatchers: defaultWatchers,
    });
  }

  toggleIsDisplay = (check) => {
    this.setState({ isDisplay: check });
  };

  toggleOptionSelected = (check) => {
    this.setState({ optionSelected: check });
  };

  toggleHideInvoices = (check) => {
    this.setState({ hideInvoices: check });
  };

  onSelectRadioSelect(item, id) {
    let value = item[Object.keys(item)[0]].value;
    this.setState({ [id]: value });
  }

  async onClickSaveInfo() {
    /*eslint-disable*/
    let data = {
      id: this.state.settingId,
      type: "invoice_settings",
      invoice_settings: {
        custom_fields: this.state.customFields,
        hide_invoices: this.state.hideInvoices,
      },
    };
    /*eslint-enable*/
    let updatedData = await callSaveSettings(data);
    if (updatedData.status) {
      showNotification("success", updatedData.message, 3000);
    } else {
      showNotification("error", updatedData.message, 5000);
    }
  }

  onClickAddField() {
    let customFields = this.state.customFields;

    if (this.state.selectedOptionIndex >= 0) {
      showNotification("error", "Please save current option", 4000);
      return;
    }

    if (this.state.fieldLabel.trim().length === 0) {
      showNotification("error", "Label is required", 4000);
      return;
    }

    if (this.state.isDisplay) {
      if (this.state.fieldListName.trim().length === 0) {
        showNotification("error", "Invoice List Name is required", 4000);
        return;
      }
    }

    let id = Math.random().toString(36).substr(2);

    let field = {
      label: this.state.fieldLabel,
      name: this.state.fieldListName,
      options: this.state.options,
      display: this.state.isDisplay,
      type: this.state.fieldType,
      id: id,
    };

    if (this.state.selectedFieldIndex >= 0) {
      field.id = this.state.fieldId;
      customFields[this.state.selectedFieldIndex] = field;
    } else {
      customFields.push(field);
    }

    this.setState({
      customFields: customFields,
      selectedFieldIndex: -1,
      fieldLabel: "",
      fieldListName: "",
      fieldType: "single",
      isDisplay: false,
      keyIsDisplay: Math.random(),
      fieldId: "",
      options: [],
    });
  }

  onSortEndFields = ({ oldIndex, newIndex }) => {
    let customFields = this.state.customFields;
    customFields = arrayMove(customFields, oldIndex, newIndex);

    this.setState({
      customFields: customFields,
    });
  };

  onPressRemoveField(e) {
    let index = e.target.dataset["value"];
    let customFields = this.state.customFields;
    customFields.splice(index, 1);
    this.setState({
      customFields: customFields,
    });
  }

  onPressEditField(e) {
    let index = e.target.dataset["value"];
    let customFields = this.state.customFields;

    let customField = customFields[index];

    this.setState({
      options: customField.options,
      selectedFieldIndex: index,
      fieldLabel: customField.label,
      fieldType: customField.type || "single",
      fieldListName: customField.name || "",
      fieldId: customField.id,
      isDisplay: customField.display,
      keyIsDisplay: Math.random(),
      keyRadioSelect: Math.random(),
    });
  }

  onClickCancelField() {
    this.setState({
      selectedFieldIndex: -1,
      fieldLabel: "",
      options: [],
      fieldId: "",
      isDisplay: false,
      fieldListName: "",
      keyIsDisplay: Math.random(),
      keyRadioSelect: Math.random(),
    });
  }

  renderFieldList() {
    return (
      <>
        <div className="text-right">
          <button
            className="btn btn-primary addQuestionButton"
            onClick={() => this.onClickAddField()}>
            Add Field
          </button>
        </div>
        <div className="gap20" />

        <SortableTaskFieldList
          distance={10}
          task={false}
          items={this.state.customFields}
          onSortEnd={this.onSortEndFields}
          onPressEdit={this.onPressEditField.bind(this)}
          onPressRemove={this.onPressRemoveField.bind(this)}
        />
        <div className="gap20" />
      </>
    );
  }

  onSortEndOptions = ({ oldIndex, newIndex }) => {
    let options = this.state.options;
    options = arrayMove(options, oldIndex, newIndex);

    this.setState({
      options: options,
    });
  };

  onPressRemoveOption(e) {
    let index = e.target.dataset["value"];
    let options = this.state.options;
    options.splice(index, 1);
    this.setState({
      options: options,
    });
  }

  onPressEditOption(e) {
    let index = e.target.dataset["value"];
    let options = this.state.options;

    let option = options[index];

    this.setState({
      selectedOptionIndex: index,
      optionLabel: option.label,
      optionId: option.id,
      optionSelected: option.selected,
      keyOptionSelected: Math.random(),
    });
  }

  onClickCancelFormField() {
    this.setState({
      selectedOptionIndex: -1,
      optionLabel: "",
      optionId: "",
      optionSelected: false,
      keyOptionSelected: Math.random(),
    });
  }

  onClickAddOption() {
    let options = this.state.options;

    if (this.state.optionLabel.trim().length === 0) {
      showNotification("error", "Label is required", 4000);
      return;
    }

    let id = Math.random().toString(36).substr(2);

    let option = {
      label: this.state.optionLabel,
      id: id,
      showEdit: true,
      selected: this.state.optionSelected,
    };

    if (this.state.selectedOptionIndex >= 0) {
      option.id = this.state.optionId;
      options[this.state.selectedOptionIndex] = option;
    } else {
      options.push(option);
    }

    this.setState({
      options: options,
      selectedOptionIndex: -1,
      optionLabel: "",
      optionId: "",
    });
  }

  isRenderSelectedOptionCheck() {
    let check = false;
    let arrTemp = this.state.options.filter((d) => {
      return d.selected === true;
    });
    check = arrTemp.length > 0 ? false : true;

    if (this.state.selectedOptionIndex >= 0) {
      let selectedOption = this.state.options[this.state.selectedOptionIndex];
      if (selectedOption && selectedOption.selected) {
        check = true;
      }
    }

    return check;
  }

  renderAddOptionSection() {
    let type = this.state.fieldType;
    return (
      <>
        {type !== "text" && (
          <div className="card">
            <div className="container-fluid">
              <div className="gap20" />
              <h5 className="noMargin">{this.state.fieldLabel} Options</h5>
              <div className="gap20" />

              <div className="row">
                <div className="col-md-6">
                  <div
                    className={
                      this.state.optionLabel !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>Label</label>
                    <input
                      type="text"
                      className="offsetDays"
                      name="optionLabel"
                      value={this.state.optionLabel}
                      onChange={(event) => this.handleInputChange(event)}
                    />
                  </div>
                  <div className="gap20" />
                </div>
              </div>
              <div className="gap10" />
              {this.isRenderSelectedOptionCheck() && (
                <Checkbox
                  label={"Selected by default"}
                  isChecked={this.state.optionSelected}
                  handleCheckboxChange={this.toggleOptionSelected.bind(this)}
                  key={this.state.keyOptionSelected}
                />
              )}

              <div className="text-right">
                {this.state.selectedFormFieldIndex >= 0 && (
                  <button
                    className="btn btn-default margin-right-10"
                    onClick={() => this.onClickCancelFormField()}>
                    Cancel
                  </button>
                )}
                <button
                  className="btn btn-primary addQuestionButton"
                  onClick={() => this.onClickAddOption()}>
                  {this.state.selectedOptionIndex >= 0
                    ? "Update Option"
                    : "Add Option"}
                </button>
              </div>
              <div className="gap20" />
              <SortableFieldOptionList
                distance={10}
                items={this.state.options}
                onSortEnd={this.onSortEndOptions}
                onPressEdit={this.onPressEditOption.bind(this)}
                onPressRemove={this.onPressRemoveOption.bind(this)}
              />
              <div className="gap20" />
            </div>
          </div>
        )}
        <div className="gap10" />
        <div className="text-right">
          <button
            className="btn btn-default margin-right-10"
            onClick={() => this.onClickCancelField()}>
            Cancel
          </button>
          <button
            className="btn btn-primary addQuestionButton"
            onClick={() => this.onClickAddField()}>
            Save Field
          </button>
        </div>
        <div className="gap20" />
      </>
    );
  }

  render() {
    return (
      <div>
        <div className="container-fluid">
          <TitleBox title="Invoice Settings" />
          <div className="gap20" />
          <div className="card">
            <div className="container-fluid">
              <div className="gap20" />
              <Checkbox
                label={"Hide Invoices created by tasks"}
                isChecked={this.state.hideInvoices}
                handleCheckboxChange={this.toggleHideInvoices}
                key={this.state.keyHideInvoices}
              />
              <div className="gap20" />
              <div className="text-right">
                <button
                  className="btn btn-primary"
                  onClick={this.onClickSaveInfo.bind(this)}>
                  Save
                </button>
                <div className="gap10" />
              </div>
            </div>
          </div>
          <div className="card">
            <div className="container-fluid">
              <div className="gap20" />

              <div className="havingHelpText">
                <h5 className="noMargin">Custom Fields</h5>
              </div>

              <div className="gap20" />

              <div className="row">
                <div className="col-md-6">
                  <div
                    className={
                      this.state.fieldLabel !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>Label</label>
                    <input
                      type="text"
                      className="offsetDays"
                      name="fieldLabel"
                      value={this.state.fieldLabel}
                      onChange={(event) => this.handleInputChange(event)}
                    />
                  </div>
                  <div className="gap20" />
                  <label>Type</label>
                  <div className="tabsHolder radioTabs">
                    <RadioTag
                      onCheckChange={this.onSelectRadioSelect.bind(this)}
                      labelList={[
                        { name: "Single Select", value: "single" },
                        { name: "Multiple Select", value: "multiple" },
                        { name: "Text", value: "text" },
                      ]}
                      key={this.state.keyRadioSelect}
                      id={"fieldType"}
                      selectType={"value"}
                      selectedList={[this.state.fieldType]}
                    />
                  </div>
                  <div className="gap20" />
                  <Checkbox
                    label={"Display inside Invoice List"}
                    isChecked={this.state.isDisplay}
                    handleCheckboxChange={this.toggleIsDisplay}
                    key={this.state.keyIsDisplay}
                  />
                  <div className="gap20" />
                  {this.state.isDisplay && (
                    <div
                      className={
                        this.state.fieldListName !== ""
                          ? "mdInput mdFocussed"
                          : "mdInput"
                      }>
                      <label>Invoice List Name</label>
                      <input
                        type="text"
                        maxLength={30}
                        max={30}
                        className="offsetDays"
                        name="fieldListName"
                        value={this.state.fieldListName}
                        onChange={(event) => this.handleInputChange(event)}
                      />
                    </div>
                  )}
                  <div className="gap20" />
                </div>
              </div>

              {this.state.selectedFieldIndex === -1 && this.renderFieldList()}
              {this.state.selectedFieldIndex > -1 &&
                this.renderAddOptionSection()}

              {this.state.selectedFieldIndex === -1 && (
                <div className="text-right">
                  <button
                    className="btn btn-primary"
                    onClick={this.onClickSaveInfo.bind(this)}>
                    Save Custom Fields
                  </button>
                  <div className="gap10" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default InvoiceSettings;
