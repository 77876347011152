import * as api from "./api";
import { callApi } from "../helpers";

function callCreateSystemUpdate(data) {
  return callApi(api.service("system-update").create(data));
}

function callGetSystemUpdates() {
  return callApi(api.service("system-update").find({}));
}

function callGetSystemUpdatesByQuery(query) {
  return callApi(api.service("system-update").find(query));
}

function callUpdateSystemUpdate(id, data) {
  return callApi(api.service("system-update").patch(id, data));
}

export {
  callCreateSystemUpdate,
  callGetSystemUpdates,
  callGetSystemUpdatesByQuery,
  callUpdateSystemUpdate
};
