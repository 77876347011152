import {
  RESET_STATUS,
  CREATE_STRIPE_PLAN_SUCCESS,
  CREATE_STRIPE_PLAN_FAILURE,
  GET_STRIPE_PLAN,
  CREATE_CUSTOMER_ORDER_SUCCESS,
  CREATE_CUSTOMER_ORDER_FAILURE,
  CREATE_CHECKOUT_ITEM_SUCCESS,
  CREATE_CHECKOUT_ITEM_FAILURE,
  GET_CHECKOUT_ITEM_SUCCESS,
  CREATE_PROMO_CODE_SUCCESS,
  CREATE_PROMO_CODE_FAILURE,
  GET_PROMO_CODE_SUCCESS,
  CREATE_UPSELL_ITEM_SUCCESS,
  CREATE_UPSELL_ITEM_FAILURE,
  GET_UPSELL_ITEM_SUCCESS,
  CREATE_FUNNEL_PATH_SUCCESS,
  CREATE_FUNNEL_PATH_FAILURE,
  GET_FUNNEL_PATH_SUCCESS,
  CREATE_MEMBER_PAYMENT_SUCCESS,
  CREATE_MEMBER_PAYMENT_FAILURE,
  GET_MEMBER_PAYMENT_SUCCESS,
  UPDATE_UPSELL_ITEM_SUCCESS,
  UPDATE_UPSELL_ITEM_FAILURE,
  UPDATE_CHECKOUT_ITEM_SUCCESS,
  UPDATE_CHECKOUT_ITEM_FAILURE,
  UPDATE_FUNNEL_PATH_SUCCESS,
  UPDATE_FUNNEL_PATH_FAILURE
} from "../types";

const initialState = {
  message: "",
  success: false,
  stripePlans: {},
  checkoutItems: {},
  promoCodes: {},
  upsellItems: {},
  funnelPaths: {},
  memberPayments: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RESET_STATUS:
      return {
        ...state,
        message: "",
        success: false
      };
    case CREATE_STRIPE_PLAN_SUCCESS:
      return {
        ...state,
        message: action.message,
        success: action.success
      };
    case CREATE_STRIPE_PLAN_FAILURE:
      return {
        ...state,
        message: action.message,
        success: action.success
      };
    case GET_STRIPE_PLAN:
      return {
        ...state,
        stripePlans: action.data
      };
    case CREATE_CUSTOMER_ORDER_SUCCESS:
      return {
        ...state,
        message: action.message,
        success: action.success
      };
    case CREATE_CUSTOMER_ORDER_FAILURE:
      return {
        ...state,
        message: action.message,
        success: action.success
      };
    case CREATE_CHECKOUT_ITEM_SUCCESS:
      return {
        ...state,
        message: action.message,
        success: action.success
      };
    case CREATE_CHECKOUT_ITEM_FAILURE:
      return {
        ...state,
        message: action.message,
        success: action.success
      };
    case UPDATE_CHECKOUT_ITEM_SUCCESS:
      return {
        ...state,
        message: action.message,
        success: action.success
      };
    case UPDATE_CHECKOUT_ITEM_FAILURE:
      return {
        ...state,
        message: action.message,
        success: action.success
      };
    case GET_CHECKOUT_ITEM_SUCCESS:
      return {
        ...state,
        checkoutItems: action.data
      };
    case CREATE_PROMO_CODE_SUCCESS:
      return {
        ...state,
        message: action.message,
        success: action.success
      };
    case CREATE_PROMO_CODE_FAILURE:
      return {
        ...state,
        message: action.message,
        success: action.success
      };
    case GET_PROMO_CODE_SUCCESS:
      return {
        ...state,
        promoCodes: action.data
      };
    case CREATE_UPSELL_ITEM_SUCCESS:
      return {
        ...state,
        message: action.message,
        success: action.success
      };
    case CREATE_UPSELL_ITEM_FAILURE:
      return {
        ...state,
        message: action.message,
        success: action.success
      };
    case UPDATE_UPSELL_ITEM_SUCCESS:
      return {
        ...state,
        message: action.message,
        success: action.success
      };
    case UPDATE_UPSELL_ITEM_FAILURE:
      return {
        ...state,
        message: action.message,
        success: action.success
      };
    case GET_UPSELL_ITEM_SUCCESS:
      return {
        ...state,
        upsellItems: action.data
      };
    case CREATE_FUNNEL_PATH_SUCCESS:
      return {
        ...state,
        message: action.message,
        success: action.success
      };
    case CREATE_FUNNEL_PATH_FAILURE:
      return {
        ...state,
        message: action.message,
        success: action.success
      };
    case UPDATE_FUNNEL_PATH_SUCCESS:
      return {
        ...state,
        message: action.message,
        success: action.success
      };
    case UPDATE_FUNNEL_PATH_FAILURE:
      return {
        ...state,
        message: action.message,
        success: action.success
      };
    case GET_FUNNEL_PATH_SUCCESS:
      return {
        ...state,
        funnelPaths: action.data
      };
    case CREATE_MEMBER_PAYMENT_SUCCESS:
      return {
        ...state,
        message: action.message,
        success: action.success
      };
    case CREATE_MEMBER_PAYMENT_FAILURE:
      return {
        ...state,
        message: action.message,
        success: action.success
      };
    case GET_MEMBER_PAYMENT_SUCCESS:
      return {
        ...state,
        memberPayments: action.data
      };
    default:
      return state;
  }
};
