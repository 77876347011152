import React, { Component } from "react";
import * as $ from "jquery";
import { Scrollbars } from "react-custom-scrollbars";

import AutoSuggest from "./autoSuggest";
import {
  showNotification,
  openPagePopupModal,
  getUniqueId,
} from "../../helpers";
import PagePopup from "../popup/pagePopup";
import { filterByTags } from "../../helpers/filters";
import FilterList from "../../components/form/filterList";

class TagSelect extends Component {
  constructor(props) {
    super(props);

    let selectedValues = [];
    if (this.props.selectedValues) {
      selectedValues = this.props.selectedValues;
    }
    let tags = [];
    if (this.props.data) {
      tags = this.props.data;
    }

    let key = this.props.searchKey;

    tags = tags.sort((a, b) => {
      if (a[key] < b[key]) return -1;
      if (a[key] > b[key]) return 1;
      return 0;
    });

    this.state = {
      cKey: this.props.id || getUniqueId(),
      key: this.props.searchKey,
      data: tags,
      dataBackup: tags,
      valueSelected: {},
      keyAuto: Math.random(),
      value: "",
      selectedValues: selectedValues,
      popupType: "",
      popupLabel: this.props.lblText,
      popupKey: Math.random(),
      isClickable: this.props.isClickable,
      singleSelection: this.props.singleSelection || false,
      hideInput: this.props.hideInput || false,
      label: this.props.label || "Tags",
      filterList: [],
      showInfo: this.props.showInfo !== undefined ? this.props.showInfo : true,
    };
  }

  onChangeValue = (value) => {
    // value
  };

  onSelectValue = (val) => {
    let name = val.name !== undefined ? val.name : val.title;

    this.setState({
      valueSelected: val,
      value: name,
    });

    this.updateSelectTagModel(name);
  };

  updateSelectTagModel = (value) => {
    const selectKey = this.props.selectKey;
    let selectedValues = this.state.selectedValues;
    selectedValues = selectedValues.filter((n) => n);

    if (this.state.singleSelection) {
      if (selectedValues.length > 0) {
        showNotification("warning", "Only single selection allowed", 4000);
        return;
      }
    }
    const valueSelected = this.state.valueSelected;
    let selectedTag = selectedValues.filter((d) => {
      return d.name === value || d === value;
    });

    if (selectedTag.length > 0) {
      showNotification("warning", `'${value}'is already added`, 4000);
      return;
    }

    if (valueSelected[selectKey]) {
      selectedValues.push(valueSelected);
    } else if (value.length > 0) {
      let tags = this.state.data;
      let fTag = tags.filter((d) => {
        return d.name === value;
      });
      if (fTag.length > 0) {
        selectedValues.push(fTag[0]);
      } else {
        selectedValues.push(value);
      }
    }

    this.setState({
      value: "",
      valueSelected: {},
      selectedValues: selectedValues,
      keyAuto: Math.random(),
    });
    this.onChangeDataValue(selectedValues);
  };

  onPressAddValue = () => {
    this.setState(
      {
        popupType: "addTag",
        popupLabel: this.state.popupLabel,
        popupKey: Math.random(),
      },
      () => {
        openPagePopupModal("addTag");
      }
    );
  };

  onAddNewTag() {
    if (this.props.reload) {
      this.props.reload();
    }
  }

  onChangeDataValue(data) {
    this.props.onChange(data);
  }

  onClickRemoveValue = (i) => {
    let selectedValues = this.state.selectedValues;
    selectedValues.splice(i, 1);
    this.setState({
      selectedValues: selectedValues,
      value: "",
      valueSelected: {},
      keyAuto: Math.random(),
    });
    this.onChangeDataValue(selectedValues);
  };

  onClickInfoTag(i) {
    let selectedValues = this.state.selectedValues;

    let id = selectedValues[i].id;
    if (!id) {
      id = this.state.data.find((d) => d.name === selectedValues[i])._id;
    }
    let url = `/admin/tag-info?id=${id}`;
    window.open(url, "_blank");
  }

  renderSelectedValues() {
    var returnVals = [];
    let selectedValues = this.state.selectedValues;

    for (let i = 0; i < selectedValues.length; i++) {
      let val = selectedValues[i][this.props.selectKey]
        ? selectedValues[i][this.props.selectKey]
        : selectedValues[i];
      if (val !== "") {
        returnVals.push(
          <>
            <span key={i} onClick={this.onClickRemoveValue.bind(this, i)}>
              {val}
            </span>
            {this.state.showInfo && (
              <i
                className="fa fa-info-circle"
                onClick={this.onClickInfoTag.bind(this, i)}
              />
            )}
          </>
        );
      }
    }
    return returnVals;
  }

  onClickCloseFromTagsModal() {
    $(
      `.modalAlertOverlay.viewTagsOverlay${this.state.cKey},.modalAlert.viewTagsAlert${this.state.cKey}`
    ).fadeOut(200);
  }

  onPressViewTags() {
    $(
      `.modalAlertOverlay.viewTagsOverlay${this.state.cKey},.modalAlert.viewTagsAlert${this.state.cKey}`
    ).fadeIn(200);
  }

  onClickTagFromViewTags(tag) {
    this.onSelectValue(tag);
    this.onClickCloseFromTagsModal();
  }

  filterUpdated(updatedFilter, name, type, searchFields) {
    let filterList = this.state.filterList;
    filterList[name] = {
      name: name,
      type: type,
      searchFields: searchFields,
      values: updatedFilter,
    };
    this.setState(
      {
        filterList: filterList,
      },
      () => {
        let data = filterByTags(this.state.dataBackup, filterList);
        this.setState({
          data: data,
        });
      }
    );
  }

  renderTagsModal() {
    let modalHeight = window.innerHeight - 200;
    let data = this.state.data;
    data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

    return (
      <div>
        <div
          className={`modalAlertOverlay viewTagsOverlay${this.state.cKey}`}
          onClick={this.onClickCloseFromTagsModal.bind(this)}
        />
        <div className={`modalAlert viewTagsAlert${this.state.cKey}`}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {this.state.label}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.onClickCloseFromTagsModal.bind(this)}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div
              className="container-fluid modalInnerBody"
              style={{ maxHeight: modalHeight, overflowY: "hidden" }}>
              <Scrollbars
                autoHide
                autoHideTimeout={1000}
                autoHideDuration={200}
                style={{ height: modalHeight }}>
                <FilterList
                  label={`Search ${this.state.label}`}
                  name={"search"}
                  type={"search"}
                  searchFields={["name", "description", "title"]}
                  onFinish={this.filterUpdated.bind(this)}
                />
                <div className="gap20" />
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Description</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((i) => (
                      <tr key={i._id}>
                        <td>{i[this.state.key]}</td>
                        <td>{i.description}</td>
                        <td>
                          <button
                            title="Select"
                            onClick={this.onClickTagFromViewTags.bind(this, i)}
                            className="btn btn-default btn-sm">
                            Select
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Scrollbars>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="row">
        {this.renderTagsModal()}
        <PagePopup
          type={this.state.popupType}
          label={this.state.popupLabel}
          key={this.state.popupKey}
          onCreate={this.onAddNewTag.bind(this)}
        />
        {!this.state.hideInput && (
          <div
            className={
              this.state.isClickable === undefined
                ? "col-md-10 col-sm-10"
                : "col-md-8 col-sm-8"
            }>
            <div
              className={
                this.state.value !== "" ? "mdInput mdFocussed" : "mdInput"
              }>
              <label>{this.props.lblText}</label>
              <AutoSuggest
                key={this.state.keyAuto}
                value={this.state.value}
                searchKey={this.props.searchKey}
                data={this.state.data}
                onChange={this.onChangeValue}
                onSelect={this.onSelectValue}
              />
            </div>
          </div>
        )}
        <div className={this.state.hideInput ? "col-sm-4" : "col-sm-2"}>
          <div className="gap30" />
          <button
            className="btn btn-block btn-default"
            style={{ whiteSpace: "normal" }}
            onClick={this.onPressViewTags.bind(this)}>
            {this.props.lblText ? this.props.lblText : "Select Tag"}
          </button>
        </div>
        {this.state.isClickable && (
          <div className={this.state.hideInput ? "col-sm-2" : "col-sm-2"}>
            <div className="gap30" />
            <button
              className="btn btn-block btn-default"
              onClick={this.onPressAddValue.bind(this)}>
              Create New Tag
            </button>
          </div>
        )}
        <div style={{ marginTop: 20, marginLeft: 10 }} className="tagsHolder">
          {this.renderSelectedValues()}
        </div>
        <div className="gap20" />
      </div>
    );
  }
}

export default TagSelect;
