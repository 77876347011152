import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import $ from "jquery";

import TitleBox from "../../../components/general/titleBox";
import FilterList from "../../../components/form/filterList";

import { filterByTags } from "../../../helpers/filters";
import { callGetPopups, callDeletePopupById } from "../../../services";

class ViewPopup extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      popups: [],
      popupsBackup: [],
      filterList: [],
      selectedPopup: {},
    };
  }

  componentDidMount() {
    this.getPopups();
  }

  getPopups() {
    callGetPopups().then((d) => {
      this.setState({ popups: d.data, popupsBackup: d.data });
    });
  }

  onClickEditPopup = (popup) => {
    this.props.history.push("/admin/add-popup", {
      popup: popup,
    });
  };

  onClickDeletePopup = (popup) => {
    this.setState({ selectedPopup: popup }, () => {
      $(".modalAlertOverlay,.modalAlert").fadeIn(200);
    });
  };

  filterUpdated(updatedFilter, name, type, searchFields) {
    let filterList = this.state.filterList;
    filterList[name] = {
      name: name,
      type: type,
      searchFields: searchFields,
      values: updatedFilter,
    };
    this.setState(
      {
        filterList: filterList,
      },
      () => {
        let data = filterByTags(this.state.popupsBackup, filterList);
        this.setState({
          popups: data,
        });
      }
    );
  }

  renderPopups() {
    var returnVals = [];
    let items = this.state.popups;
    items.forEach((element) => {
      returnVals.push(
        <tr key={element._id}>
          <td>{element.title}</td>
          <td>{element.description}</td>
          <td>{element.page_list.join(", ")}</td>
          <td className="min-width-100">
            <button
              onClick={this.onClickDeletePopup.bind(this, element)}
              className="btn btn-danger btn-sm margin-right-10 border-0">
              <i className="fa fa-trash" />
            </button>
            <button
              onClick={this.onClickEditPopup.bind(this, element)}
              className="btn btn-default btn-sm border-0">
              <i className="fa fa-pencil" />
            </button>
          </td>
        </tr>
      );
    });
    return returnVals;
  }

  onClickNoAlert = () => {
    $(".modalAlertOverlay,.modalAlert").fadeOut(200);
  };

  onClickYesAlert = () => {
    callDeletePopupById(this.state.selectedPopup._id).then((d) => {
      $(".modalAlertOverlay,.modalAlert").fadeOut(200);
      this.getPopups();
    });
  };

  renderConfirmationAlert() {
    let selectedPopup = this.state.selectedPopup;
    let title = "";
    if (selectedPopup.title) {
      title = selectedPopup.title;
    }
    return (
      <div>
        <div className="modalAlertOverlay" />
        <div className="modalAlert">
          <p>{`Are you sure you want to delete "${title}" pre-page content?`}</p>
          <div className="alertFooterBtns">
            <button
              className="btn btn-default closeModal-No margin-right-10"
              onClick={this.onClickNoAlert}>
              No
            </button>
            <button
              className="btn btn-primary closeModal-Yes"
              onClick={this.onClickYesAlert}>
              Yes
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderFilterView() {
    return (
      <div className="list-group-item">
        <FilterList
          label={"Search Pre-Page Content..."}
          name={"search"}
          type={"search"}
          searchFields={["title", "description"]}
          onFinish={this.filterUpdated.bind(this)}
        />
      </div>
    );
  }

  render() {
    return (
      <div className="container-fluid">
        {this.renderConfirmationAlert()}
        <TitleBox title="Pre-Page Content Overview" />
        <div className="gap20" />
        <div className="card">
          <div className="list-group list-group-flush">
            {this.renderFilterView()}
            <div className="list-group-item">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Description</th>
                    <th>Page</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>{this.renderPopups()}</tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ViewPopup);
