import {
  RESET_STATUS,
  GET_EMAIL_SUCCESS,
  CREATE_EMAIL_SUCCESS,
  CREATE_EMAIL_FAILURE,
  UPDATE_EMAIL_SUCCESS,
  UPDATE_EMAIL_FAILURE
} from "../types";

import config from "../../helpers/config";

import {
  callCreateEmail,
  callGetEmails,
  callUpdateEmail
} from "../../services/index";

/*
 This method is used to reset the status
 This will reset message and success states
 */
export const resetStatus = data => {
  return async dispatch => {
    dispatch({
      type: RESET_STATUS
    });
  };
};

export const createEmail = data => {
  return async dispatch => {
    return callCreateEmail(data)
      .then(data => {
        dispatch({
          type: CREATE_EMAIL_SUCCESS,
          message: config.messages.createChlgQueSuccess,
          success: true
        });
      })
      .catch(err => {
        dispatch({
          type: CREATE_EMAIL_FAILURE,
          message: config.messages.createChlgQueError,
          success: false
        });
      });
  };
};

export const updateEmail = (id, data) => {
  return async dispatch => {
    return callUpdateEmail(id, data)
      .then(data => {
        dispatch({
          type: UPDATE_EMAIL_SUCCESS,
          message: config.messages.updateMessage,
          success: true
        });
      })
      .catch(err => {
        dispatch({
          type: UPDATE_EMAIL_FAILURE,
          message: config.messages.createChlgQueError,
          success: false
        });
      });
  };
};

export const getEmails = data => {
  return async dispatch => {
    return callGetEmails(data).then(data => {
      dispatch({
        type: GET_EMAIL_SUCCESS,
        data: data
      });
    });
  };
};
