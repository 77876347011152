import React, { Component } from "react";

class TextAreaAutoResize extends Component {
  constructor(props) {
    super(props);

    const { name, value } = this.props;

    this.state = {
      name: name,
      value: value,
      scrollHeight: ""
    };
  }

  handleTextChange(event) {
    const { onTextChange } = this.props;

    const target = event.target;

    this.setState(
      {
        value: target.value,
        scrollHeight: "auto"
      },
      () => {
        this.setState({
          scrollHeight: target.scrollHeight + "px"
        });
      }
    );

    onTextChange(event);
  }

  render() {
    let textAreaClass = {
      height: this.state.scrollHeight === "" ? "45px" : this.state.scrollHeight,
      overflowY: "hidden"
    };
    return (
      <div className="mdTextArea">
        <div
          className={
            this.state.value !== "" ? "mdInput mdFocussed" : "mdInput"
          }>
          <label>{this.props.label}</label>
          <textarea
            ref={input => {
              this.props.onRef(input);
            }}
            name={this.state.name}
            style={textAreaClass}
            value={this.state.value}
            onKeyDown={this.props.onKeyDown}
            onChange={event => this.handleTextChange(event)}
          />
        </div>
      </div>
    );
  }
}

export default TextAreaAutoResize;
