import * as api from "./api";
import { callApi } from "../helpers";

function callCreateTasks(data) {
  return callApi(api.service("tasks").create(data));
}

function callUpdateTask(id, data) {
  return callApi(api.service("tasks").patch(id, data));
}

function callUpdateTaskByQuery(data, query) {
  return callApi(api.service("tasks").patch(null, data, query));
}

function callDeleteTask(id) {
  return callApi(api.service("tasks").remove(id));
}

function callGetTasks() {
  return callApi(api.service("tasks").find({}));
}

function callGetTaskById(id, params = {}) {
  return callApi(api.service("tasks").get(id, params));
}

function callGetTasksByQuery(query) {
  return callApi(api.service("tasks").find(query));
}

function callCreateInvoice(data) {
  return callApi(api.service("invoice").create(data));
}

function callUpdateInvoice(id, data) {
  return callApi(api.service("invoice").patch(id, data));
}

function callDeleteInvoice(id) {
  return callApi(api.service("invoice").remove(id));
}

function callGetInvoiceById(id) {
  return callApi(api.service("invoice").get(id));
}

function callGetInvoices() {
  return callApi(api.service("invoice").find({}));
}

function callGetInvoicesByQuery(query) {
  return callApi(api.service("invoice").find(query));
}

function callCreatePdfGeneration(data) {
  return callApi(api.service("pdf-generation").create(data));
}

function callUpdatePdfGeneration(id, data) {
  return callApi(api.service("pdf-generation").patch(id, data));
}

function callDeletePdfGeneration(id) {
  return callApi(api.service("pdf-generation").remove(id));
}

function callGetPdfGenerationById(id) {
  return callApi(api.service("pdf-generation").get(id));
}

function callGetPdfGeneration() {
  return callApi(api.service("pdf-generation").find({}));
}

function callGetPdfGenerationByQuery(query) {
  return callApi(api.service("pdf-generation").find(query));
}

function callCreatePdfTemplate(data) {
  return callApi(api.service("pdf-template").create(data));
}

function callGetPdfTemplates() {
  return callApi(api.service("pdf-template").find({}));
}

function callGetPdfTemplateById(id) {
  return callApi(api.service("pdf-template").get(id));
}

function callGetPdfTemplatesUsingQuery(query, isLoading = true) {
  return callApi(api.service("pdf-template").find(query), isLoading);
}

function callUpdatePdfTemplate(id, data) {
  return callApi(api.service("pdf-template").patch(id, data));
}

function callDeletePdfTemplateById(id) {
  return callApi(api.service("pdf-template").remove(id));
}

function callCreateTaskHistoryTemplate(data) {
  return callApi(api.service("task-history-template").create(data));
}

function callUpdateTaskHistoryTemplate(id, data) {
  return callApi(api.service("task-history-template").patch(id, data));
}

function callDeleteTaskHistoryTemplate(id) {
  return callApi(api.service("task-history-template").remove(id));
}

function callGetTaskHistoryTemplateById(id) {
  return callApi(api.service("task-history-template").get(id));
}

function callGetTaskHistoryTemplates() {
  return callApi(api.service("task-history-template").find({}));
}

function callGetTaskHistoryTemplatesByQuery(query) {
  return callApi(api.service("task-history-template").find(query));
}

export {
  callCreatePdfTemplate,
  callGetPdfTemplates,
  callGetPdfTemplatesUsingQuery,
  callUpdatePdfTemplate,
  callDeletePdfTemplateById,
  callCreateTasks,
  callUpdateTask,
  callDeleteTask,
  callGetTasks,
  callGetTasksByQuery,
  callGetTaskById,
  callUpdateTaskByQuery,
  callCreateInvoice,
  callUpdateInvoice,
  callDeleteInvoice,
  callGetInvoices,
  callGetInvoicesByQuery,
  callGetInvoiceById,
  callCreatePdfGeneration,
  callUpdatePdfGeneration,
  callDeletePdfGeneration,
  callGetPdfGenerationById,
  callGetPdfGeneration,
  callGetPdfGenerationByQuery,
  callGetPdfTemplateById,
  callCreateTaskHistoryTemplate,
  callUpdateTaskHistoryTemplate,
  callDeleteTaskHistoryTemplate,
  callGetTaskHistoryTemplateById,
  callGetTaskHistoryTemplates,
  callGetTaskHistoryTemplatesByQuery,
};
