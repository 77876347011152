const defaultState = {
  chats: [],
  oneChat: ""
};

export default function(state = defaultState, action = {}) {
  switch (action.type) {
    case "CHAT_GET_SUCCESS":
      return {
        ...state,
        chats: action.payload
      };
    case "STAFF_REPLAY_SUCCESS":
      const replayChat = action.payload;
      return {
        ...state,
        chats: state.chat.map(
          item => (item._id === replayChat._id ? replayChat : item)
        )
      };
    case "GET_ONE_CHAT":
      return {
        ...state,
        oneChat: action.payload
      };

    default:
      return state;
  }
}
