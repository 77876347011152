import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import SignaturePad from "react-signature-pad-wrapper";

import DatePicker from "react-date-picker";
import moment from "moment-timezone";

import {
  callGetFormById,
  callCreateFormSubmission,
  uploadImage,
  callGetPopupsById,
  callProcessOrderTagInfo,
  callCreateDraftFormSubmission,
  callUpdateDraftFormSubmission,
  callGetDraftFormSubmissionUsingQuery,
  callDeleteDraftFormSubmissionById,
  callGetFormSubmissionById,
  callUpdateFormSubmission,
} from "../../../services";
import {
  getParameterByName,
  showNotification,
  sanitizer,
  imageUrl,
} from "../../../helpers";

import Checkbox from "../../../components/form/checkBox";
import { Wizard, Step } from "../../../components/general/wizard";
import { callCreateFormPDF } from "../../../services/settingService";
import { getItem, setItem } from "../../../helpers/storage";
import SelectDateTime from "../../../components/form/selectDateTime";
import RadioTag from "../../../components/form/radioTag";

class PreviewFormPage extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    let id = getParameterByName("id");
    let pId = "";
    let formType = "normal";

    if (!id) {
      id = this.props.formId;
      formType = this.props.formType || "normal";
      if (this.props.popupId) {
        pId = this.props.popupId;
      }
    }

    this.wizardRef = React.createRef();

    this.state = {
      userData: this.props.userData || {},
      formId: id,
      formType: formType,
      pId: pId,
      steps: [],
      formFieldValues: {},
      keyWizard: Math.random(),
      initialStep: 0,
      popupHtml: "",
      pdfUrl: "",
      createPdf: false,
      draftFormId: "",
      formSubmissionId: this.props.formSubmissionId || "",
      from: this.props.from || "normal",
      signUrl: "",
      updateSign: false,
    };
  }

  async componentDidMount() {
    let popupHtml = "";
    if (this.state.pId) {
      let popupInfo = await callGetPopupsById(this.state.pId);
      popupHtml = popupInfo.html;
      this.setState({ popupHtml: popupHtml });
    }

    if (this.state.formId && this.state.formType === "normal") {
      let response = await callGetFormById(this.state.formId);
      if (response._id) {
        let steps = response.steps;

        let userId = this.state.userData._id;
        if (!userId) {
          userId = getItem("userId");
        }

        let draftFormFieldValues = {};
        let draftFormId = "";

        if (this.state.from === "task") {
          if (this.state.formSubmissionId) {
            let response = await callGetFormSubmissionById(
              this.state.formSubmissionId
            );
            if (response._id) {
              let signatureField = response.form_data.find(
                (d) => d.type === "signature"
              );
              if (response.form_values) {
                draftFormFieldValues = response.form_values || {};
                if (signatureField) {
                  let id = signatureField.id;
                  let signUrl = draftFormFieldValues[id];
                  this.setState({ signUrl });
                }
              }
            }
          } else {
            /*eslint-disable*/
            let queryDrafts = {
              query: {
                member_id: userId,
                form_id: this.state.formId,
                task_id: this.props.taskId,
              },
            };
            /*eslint-enable*/

            let drafts = await callGetDraftFormSubmissionUsingQuery(
              queryDrafts
            );
            if (drafts.data.length > 0) {
              draftFormFieldValues = drafts.data[0].form_field_values || {};
              draftFormId = drafts.data[0]._id;
            }
          }
        } else if (this.state.from === "normal") {
          /*eslint-disable*/
          let queryDrafts = {
            query: {
              member_id: userId,
              form_id: this.state.formId,
            },
          };
          /*eslint-enable*/

          let drafts = await callGetDraftFormSubmissionUsingQuery(queryDrafts);
          if (drafts.data.length > 0) {
            draftFormFieldValues = drafts.data[0].form_field_values || {};
            draftFormId = drafts.data[0]._id;
          }
        }

        let formFieldValues = {};
        let newSteps = [];

        for (var i = 0; i < steps.length; i++) {
          var element = steps[i];
          var newFields = [];

          for (var j = 0; j < element.fields.length; j++) {
            var field = element.fields[j];
            field.signRef = "";

            if (field.type === "paragraph") {
              if (field.value.includes("{{order(")) {
                field.value = await this.callAPIOrderTagInfo(field.value);
              }
            }
            if (field.type === "date") {
              formFieldValues[field.id] = draftFormFieldValues[field.id] || "";
              if (formFieldValues[field.id]) {
                formFieldValues[field.id] = new Date(formFieldValues[field.id]);
              }
            } else if (field.type === "checkbox") {
              formFieldValues[field.id] =
                draftFormFieldValues[field.id] || false;
            } else {
              formFieldValues[field.id] = draftFormFieldValues[field.id] || "";
              formFieldValues[field.id + "text"] =
                draftFormFieldValues[field.id + "text"] || "";
            }

            newFields.push(field);
          }
          element.fields = newFields;
          newSteps.push(element);
        }

        this.setState({
          steps: newSteps,
          draftFormId: draftFormId,
          formFieldValues: formFieldValues,
          keyWizard: Math.random(),
          createPdf: response.create_pdf,
        });
      }
    }

    if (this.state.formId && this.state.formType === "pdf") {
      let key = getItem("sk");
      let pdfAppUrl = "https://pdf.memberpages.app";
      if (window.location.host.includes("localhost")) {
        pdfAppUrl = "http://localhost:3050";
      }
      let userId = this.state.userData._id;
      if (!userId) {
        userId = getItem("userId");
      }
      let pdfUrl = `${pdfAppUrl}/?id=${this.state.formId}&key=${key}&uid=${userId}`;
      this.setState({ pdfUrl });
    }

    window.addEventListener("message", this.handleIframeTask);
  }

  handleIframeTask = (e) => {
    let check = `submitted-page-${this.state.formId}`;
    if (e.data === check) {
      this.props.onClickSubmit();
      return;
    }
  };

  async callAPIOrderTagInfo(htmlContent) {
    let userId = this.state.userData._id;
    if (!userId) {
      userId = getItem("userId");
    }
    let apiData = {
      action: "process-order-tag",
      data: {
        memberId: userId,
        htmlContent: htmlContent,
      },
    };
    /*eslint-enable*/

    let data = await callProcessOrderTagInfo(apiData);
    return data.htmlContent;
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    let formFieldValues = this.state.formFieldValues;
    formFieldValues[name] = value;

    this.setState({ formFieldValues });
  }

  toggleCheckboxChange = (id, check) => {
    let formFieldValues = this.state.formFieldValues;
    formFieldValues[id] = check;

    this.setState({ formFieldValues });
  };

  onChangeDate(id, value) {
    let formFieldValues = this.state.formFieldValues;
    formFieldValues[id] = value;

    this.setState({ formFieldValues });
  }

  onChangeEditor(id, html, json) {
    let formFieldValues = this.state.formFieldValues;
    formFieldValues[id] = html;

    this.setState({ formFieldValues });
  }

  setSignData(id, value) {
    let formFieldValues = this.state.formFieldValues;
    formFieldValues[id] = value;

    this.setState({ formFieldValues });
  }

  async checkFormFields(index) {
    let step = this.state.steps[index];
    let fields = step.fields;
    let fieldsValues = this.state.formFieldValues;

    for (let i = 0; i < fields.length; i++) {
      let element = fields[i];
      const value = fieldsValues[element.id];
      if (element.type === "signature" && element.signRef) {
        if (
          element.required &&
          element.signRef.isEmpty() &&
          !this.state.formSubmissionId
        ) {
          showNotification("error", `${element.label} is required`, 4000);
          return false;
        }
        let imageData = element.signRef.toDataURL();
        let imageBlob = await fetch(imageData).then((res) => res.blob());

        let signImage = await uploadImage(imageBlob);
        signImage = await signImage.json();

        if (signImage.file_name) {
          this.setSignData(element.id, signImage.file_name);
        }
        let svgData = element.signRef.toSVG();
        if (svgData) {
          this.setSignData(`${element.id}-svg`, svgData);
        }
      } else {
        if (element.required) {
          if (element.type === "checkbox") {
            if (value === false) {
              showNotification("error", `${element.label} is required`, 4000);
              return false;
            }
          } else {
            if (
              value.toString().trim().length === 0 &&
              !this.state.formSubmissionId
            ) {
              showNotification("error", `${element.label} is required`, 4000);
              return false;
            }
          }
        }
      }
    }
    return true;
  }

  async callSaveFormValuesAPI() {
    let formValues = this.state.formFieldValues;
    let steps = this.state.steps;

    let formData = [];

    steps.forEach((step) => {
      step.fields.forEach((field) => {
        delete field.showEdit;
        delete field.signRef;
        field["user_value"] = formValues[field.id];
        field["user_value_svg"] = formValues[field.id + "-svg"];
        field["user_value_text"] = formValues[field.id + "text"];
        formData.push(field);
      });
    });

    let userId = this.state.userData._id;
    if (!userId) {
      userId = getItem("userId");
    }

    /*eslint-disable*/
    let apiData = {
      member_id: userId,
      form_id: this.state.formId,
      form_data: formData,
      form_values: formValues,
    };
    /*eslint-enable*/

    let formSubmissionId = this.state.formSubmissionId;
    if (formSubmissionId) {
      /*eslint-disable*/
      let updateData = {
        form_data: formData,
        form_values: formValues,
        $push: { form_history: { member_id: userId, date: new Date() } },
      };
      /*eslint-enable*/
      let response = await callUpdateFormSubmission(
        formSubmissionId,
        updateData
      );
      if (response._id) {
        setItem("formSubmissionId", formSubmissionId);
      }
      this.props.onClickSubmit();
    } else {
      let response = await callCreateFormSubmission(apiData);
      if (response._id) {
        setItem("formSubmissionId", response._id);
        if (this.state.createPdf) {
          this.checkForFormPDF(response._id);
        } else {
          if (this.state.draftFormId) {
            await callDeleteDraftFormSubmissionById(this.state.draftFormId);
          }
          this.props.onClickSubmit();
        }
      }
    }
  }

  async checkForFormPDF(formSubId) {
    let userId = this.state.userData._id;
    if (!userId) {
      userId = getItem("userId");
    }
    /*eslint-disable*/
    let data = {
      form_sub_id: formSubId,
      member_id: userId,
    };
    /*eslint-enable*/
    let response = await callCreateFormPDF(data);
    if (response.status) {
      if (this.state.draftFormId) {
        await callDeleteDraftFormSubmissionById(this.state.draftFormId);
      }
      this.props.onClickSubmit();
    }
  }

  async onClickSaveData(index) {
    if (this.state.formId) {
      let check = await this.checkFormFields(index);
      if (check) {
        this.callSaveFormValuesAPI();
      }
    }
  }

  scrollToElement(selector, offset) {
    const element = document.querySelector(selector);
    if (element) {
      const elementRect = element.getBoundingClientRect();
      const elementTop = window.pageYOffset + elementRect.top - offset;
      window.scrollTo({ top: elementTop, behavior: "smooth" });
    }
  }

  async onClickNextBtn(index) {
    let check = await this.checkFormFields(index);
    if (check) {
      this.scrollToElement("#formView", 60);
      this.callSaveFormFieldValuesAPI();
      this.wizardRef.current.processNavigateBtn("next");
    }
  }

  async callSaveFormFieldValuesAPI() {
    let userId = this.state.userData._id;
    if (!userId) {
      userId = getItem("userId");
    }

    /*eslint-disable*/
    let apiData = {
      member_id: userId,
      form_id: this.state.formId,
      form_field_values: this.state.formFieldValues,
      task_id: this.props.taskId || "",
    };
    /*eslint-enable*/

    let draftFormId = this.state.draftFormId;

    if (draftFormId) {
      await callUpdateDraftFormSubmission(draftFormId, apiData);
    } else {
      let response = await callCreateDraftFormSubmission(apiData);
      if (response._id) {
        this.setState({ draftFormId: response._id });
      }
    }
  }

  onClickClearSignature(element) {
    if (element.signRef) {
      element.signRef.clear();
      this.setSignData(element.id, "");
    }
  }

  checkForCustomAttributes(emailContent, tagList) {
    var reHtml = new RegExp(/\{\{tag\(.*?\)\}\}/gi);

    let htmlContent = emailContent.replace(reHtml, (match) => {
      let tagId = match;

      let re = match.match(/\((.*)\)/);
      if (re.length > 1) {
        tagId = re[1];
      }

      tagId = tagId.replace("-&gt;", "->");

      let snippet = "";
      let tagArray = tagId.split("->");
      if (tagArray.length === 2 && tagList) {
        let fTagList = tagList.filter(
          (d) => d.id.toString() === tagArray[0].toString()
        );
        if (fTagList.length > 0) {
          fTagList.sort((a, b) => new Date(b.created) - new Date(a.created));
          let fTag = fTagList[0];
          if (fTag.status === "active") {
            let attributes = fTag.attributes || [];
            let fAttrs = attributes.filter(
              (d) => d.name.toLowerCase() === tagArray[1].toLowerCase()
            );
            if (fAttrs.length > 0) {
              snippet = fAttrs[0].value;
              if (snippet.includes("{{tag_date}}")) {
                snippet = snippet.replace(
                  "{{tag_date}}",
                  moment(fTag.created).format("MM/DD/YYYY")
                );
              }
            }
          }
        }
      }
      return snippet;
    });

    return htmlContent;
  }

  renderParagraph(value) {
    let html = value;

    if (html.includes("{{customer_name}}")) {
      if (this.state.userData.name) {
        html = html.replace(/{{customer_name}}/gi, this.state.userData.name);
      }
    }

    if (html.includes("{{full_date}}")) {
      let date = moment().format("MMM Do, YYYY");
      html = html.replace(/{{full_date}}/gi, date);
    }

    if (html.includes("{{tag(")) {
      html = this.checkForCustomAttributes(html, this.state.userData.tag_list);
    }

    return html;
  }

  onClickSubmit() {
    if (this.state.draftFormId) {
      callDeleteDraftFormSubmissionById(this.state.draftFormId).then(() => {
        this.props.onClickSubmit();
      });
    } else {
      this.props.onClickSubmit();
    }
  }

  checkForAllowText(options, value) {
    let option = options.find((d) => d.id === value);
    return option ? option.allowText : false;
  }

  onChangeRadioField(item, id) {
    let formFieldValues = this.state.formFieldValues;
    formFieldValues[id] = item[Object.keys(item)[0]].value;

    this.setState({ formFieldValues });
  }

  renderFields(fields) {
    let returnVals = [];

    for (let i = 0; i < fields.length; i++) {
      const element = fields[i];
      let value = this.state.formFieldValues[element.id];
      let radioLabelList = [];
      if (element.type === "radio") {
        element.options.forEach((option) => {
          radioLabelList.push({
            name: option.label,
            value: option.id,
          });
        });
      }
      returnVals.push(
        <div className="row" key={element.id}>
          {element.type === "text" && (
            <div className="col-md-12">
              <div className={value !== "" ? "mdInput mdFocussed" : "mdInput"}>
                <label className={element.required ? "required" : ""}>
                  {element.label}
                </label>
                <input
                  id={element.id}
                  name={element.id}
                  value={value}
                  max={element.limit ? element.limit : 524288}
                  maxLength={element.limit ? element.limit : 524288}
                  className="challangeDesc"
                  onChange={(event) => this.handleInputChange(event)}
                />
              </div>
              <div className="gap20" />
            </div>
          )}
          {element.type === "select" && (
            <>
              <div className="col-md-12">
                <div
                  className={value !== "" ? "mdInput mdFocussed" : "mdInput"}>
                  <label className={element.required ? "required" : ""}>
                    {element.label}
                  </label>
                  <select
                    id={element.id}
                    name={element.id}
                    value={value}
                    onChange={(event) => this.handleInputChange(event)}>
                    <option value=""></option>
                    {element.options.map((i) => (
                      <option value={i.id}>{i.label}</option>
                    ))}
                  </select>
                </div>
                <div className="gap20" />
              </div>
              {this.checkForAllowText(element.options, value) && (
                <div className="col-md-12">
                  <div
                    className={
                      this.state.formFieldValues[element.id + "text"] !== "" &&
                      this.state.formFieldValues[element.id + "text"] !==
                        undefined
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label className={element.required ? "required" : ""}>
                      Enter text
                    </label>
                    <input
                      max={element.limit ? element.limit : 524288}
                      maxLength={element.limit ? element.limit : 524288}
                      id={element.id + "text"}
                      name={element.id + "text"}
                      value={this.state.formFieldValues[element.id + "text"]}
                      className="challangeDesc"
                      onChange={(event) => this.handleInputChange(event)}
                    />
                  </div>
                  <div className="gap20" />
                </div>
              )}
            </>
          )}
          {element.type === "radio" && (
            <>
              <div className="col-md-12">
                <div className="radioBHolder">
                  <label className={element.required ? "required" : ""}>
                    {element.label}
                  </label>
                  <div className="tabsHolder radioTabs">
                    <RadioTag
                      onCheckChange={this.onChangeRadioField.bind(this)}
                      labelList={radioLabelList}
                      id={element.id}
                      name={element.id}
                      selectedList={[value]}
                      selectType={"value"}
                      key={value}
                    />
                  </div>
                </div>
                <div className="gap20" />
              </div>
              {this.checkForAllowText(element.options, value) && (
                <div className="col-md-12">
                  <div
                    className={
                      this.state.formFieldValues[element.id + "text"] !== "" &&
                      this.state.formFieldValues[element.id + "text"] !==
                        undefined
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label className={element.required ? "required" : ""}>
                      Enter text
                    </label>
                    <input
                      max={element.limit ? element.limit : 524288}
                      maxLength={element.limit ? element.limit : 524288}
                      id={element.id + "text"}
                      name={element.id + "text"}
                      value={this.state.formFieldValues[element.id + "text"]}
                      className="challangeDesc"
                      onChange={(event) => this.handleInputChange(event)}
                    />
                  </div>
                  <div className="gap20" />
                </div>
              )}
            </>
          )}
          {element.type === "checkbox" && (
            <div className="col-md-12">
              <Checkbox
                label={element.label}
                isChecked={value}
                handleCheckboxChange={this.toggleCheckboxChange.bind(
                  this,
                  element.id
                )}
              />
              <div className="gap20" />
            </div>
          )}
          {element.type === "time" && (
            <div className="col-md-12">
              <SelectDateTime
                type="time"
                label={element.label}
                date={value}
                responseType="time"
                onChange={(time) => {
                  this.onChangeDate(element.id, time);
                }}
              />
              <div className="gap20" />
            </div>
          )}
          {element.type === "date" && (
            <div className="col-md-3">
              <label
                className={
                  element.required
                    ? "required inputFakeLabelFocussed"
                    : "inputFakeLabelFocussed"
                }>
                {element.label}
              </label>
              <div>
                <DatePicker
                  value={value}
                  onChange={this.onChangeDate.bind(this, element.id)}
                />
              </div>
              <div className="gap20" />
            </div>
          )}
          {element.type === "image" && (
            <div className="col-md-12">
              <div>
                <img src={imageUrl(element.value)} alt="url" />
              </div>
              <div className="gap20" />
            </div>
          )}
          {element.type === "paragraph" && (
            <div className="col-md-12">
              <div className="qlContent">
                <div
                  dangerouslySetInnerHTML={{
                    __html: sanitizer(this.renderParagraph(element.value)),
                  }}
                />
              </div>
              <div className="gap20" />
            </div>
          )}
          {element.type === "signature" && !this.state.formSubmissionId && (
            <div className="col-md-6">
              <div>
                <label className={element.required ? "required" : ""}>
                  {element.label}
                </label>
                <div style={{ border: "1px solid #e8e8e8" }}>
                  <SignaturePad ref={(ref) => (element.signRef = ref)} />
                </div>
                <div className="gap10" />
                <button
                  className="btn btn-sm btn-default"
                  onClick={this.onClickClearSignature.bind(this, element)}>
                  Clear Signature
                </button>
              </div>
              <div className="gap20" />
            </div>
          )}
          {element.type === "signature" && this.state.signUrl && (
            <div className="col-md-6">
              <div>
                <label className={element.required ? "required" : ""}>
                  {element.label}
                </label>
                <br />
                <img
                  alt={"sign"}
                  src={imageUrl(this.state.signUrl)}
                  width={100}
                  height={100}
                />
                <br />
                <button
                  className="btn btn-sm btn-default"
                  onClick={() => {
                    this.setState({ updateSign: !this.state.updateSign });
                  }}>
                  {this.state.updateSign ? "Cancel" : "Update Signature"}
                </button>
                <br />
                {this.state.updateSign && (
                  <>
                    <br />
                    <div style={{ border: "1px solid #e8e8e8" }}>
                      <SignaturePad ref={(ref) => (element.signRef = ref)} />
                    </div>
                    <div className="gap10" />
                    <button
                      className="btn btn-sm btn-default"
                      onClick={this.onClickClearSignature.bind(this, element)}>
                      Clear Signature
                    </button>
                  </>
                )}
              </div>
              <div className="gap20" />
            </div>
          )}

          {element.type === "editor" && (
            <div className="col-md-12">
              <div className={value !== "" ? "mdInput mdFocussed" : "mdInput"}>
                <label className={element.required ? "required" : ""}>
                  {element.label}
                </label>
                <textarea
                  id={element.id}
                  name={element.id}
                  style={{ height: "auto" }}
                  rows={4}
                  value={value}
                  max={element.limit ? element.limit : 524288}
                  maxLength={element.limit ? element.limit : 524288}
                  className="challangeDesc"
                  onChange={(event) => this.handleInputChange(event)}
                />
              </div>
              <div className="gap20" />
            </div>
          )}
        </div>
      );
    }
    return returnVals;
  }

  renderSteps() {
    let steps = this.state.steps;
    let returnVals = [];
    for (let i = 0; i < steps.length; i++) {
      const element = steps[i];
      returnVals.push(
        <Step key={element.id}>{this.renderFields(element.fields)}</Step>
      );
    }
    return returnVals;
  }

  render() {
    let popupHtml = this.state.popupHtml;

    return (
      <div className="container-fluid" id="formView">
        {popupHtml.length > 0 && (
          <div className="card">
            <div className="container-fluid">
              <div className="qlContent">
                <div
                  dangerouslySetInnerHTML={{
                    __html: sanitizer(popupHtml),
                  }}
                />
              </div>
            </div>
          </div>
        )}
        {this.state.formId && this.state.formType === "normal" && (
          <div className="card">
            <div className="container-fluid">
              <div className="gap20" />
              <Wizard
                ref={this.wizardRef}
                from="form"
                key={this.state.keyWizard}
                onSubmit={this.onClickSaveData.bind(this)}
                onClickNext={this.onClickNextBtn.bind(this)}
                initialStep={this.state.initialStep}>
                {this.renderSteps()}
              </Wizard>
            </div>
          </div>
        )}
        {this.state.formId && this.state.formType === "pdf" && (
          <div className="card">
            <div className="container-fluid">
              <iframe
                style={{
                  width: "100%",
                  height: "100vh",
                  top: 0,
                  left: 0,
                }}
                src={this.state.pdfUrl}
                title="pdfForm"
                frameborder="0"></iframe>
            </div>
          </div>
        )}
        {!this.state.formId && (
          <div className="text-right">
            <button
              className="btn btn-primary"
              onClick={this.onClickSubmit.bind(this)}>
              Submit
            </button>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PreviewFormPage);
