import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import TitleBox from "../../../components/general/titleBox";

import {
  getParameterByName,
  showNotification,
  toPromise,
} from "../../../helpers";
import {
  callGetContentEditorSectionById,
  callUpdateContentEditorSection,
} from "../../../services";
import Checkbox from "../../../components/form/checkBox";
import PageContent from "../../../components/editor/pageContent";

class AddCustomSectionPage extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    let sectionId = getParameterByName("id") || 0;

    let section = {
      sectionId: sectionId,
      name: "",
      nonCustomizable: false,
      html: "",
      json: {},
      editorSectionId: "",
      draftId: "",
      draftImage: "",
    };

    this.state = {
      sectionId: section.sectionId,
      name: section.name,
      html: section.html,
      json: section.json,
      nonCustomizable: section.nonCustomizable,
      editorSectionId: section.editorSectionId,
      keyCheckbox: Math.random(),
      draftId: section.draftId,
      draftImage: section.draftImage,
      keyEditor: Math.random(),
    };
  }

  componentDidMount() {
    if (this.state.sectionId !== 0) {
      this.getContentSection();
    }
  }

  async getContentSection() {
    const fData = await callGetContentEditorSectionById(this.state.sectionId);

    let section = {
      sectionId: 0,
      name: "",
      nonCustomizable: false,
      html: "",
      json: {},
      editorSectionId: "",
      draftId: "",
      draftImage: "",
    };

    section.sectionId = fData._id;
    section.name = fData.name;
    section.nonCustomizable = fData.non_customizable;
    section.html = fData.html;
    section.json = fData.json;
    section.editorSectionId = fData.section_id || "";
    section.draftId = fData.draft_id || "";
    section.draftImage = fData.draft_image || "";

    this.setState({
      sectionId: section.sectionId,
      name: section.name,
      html: section.html,
      json: section.json,
      nonCustomizable: section.nonCustomizable,
      editorSectionId: section.editorSectionId,
      keyCheckbox: Math.random(),
      draftId: section.draftId,
      draftImage: section.draftImage,
      keyEditor: Math.random(),
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  toggleCheckbox = (check) => {
    this.setState({
      nonCustomizable: check,
    });
  };

  onChangeContent(html, content, draftId, draftImage) {
    this.setState(
      {
        json: content,
        html: html,
        draftId: draftId,
        draftImage: draftImage,
        keyEditor: Math.random(),
      },
      () => {
        this.onPressSave();
      }
    );
  }

  async onPressSave() {
    if (this.state.name.trim().length === 0) {
      showNotification("error", "Name is required", 4000);
      return;
    }
    /*eslint-disable*/
    let apiData = {
      name: this.state.name,
      json: this.state.json,
      html: this.state.html,
      non_customizable: this.state.nonCustomizable,
      draft_id: this.state.draftId,
      draft_image: this.state.draftImage,
    };

    let [err, data] = await toPromise(
      callUpdateContentEditorSection(this.state.sectionId, apiData)
    );
    /*eslint-enable*/

    if (err) {
      showNotification("error", err.message, 5000);
    } else {
      showNotification("success", "Custom Section updated", 4000);
    }
  }

  render() {
    return (
      <div>
        <div className="container-fluid">
          <TitleBox title={"Edit Custom Section"} showBackBtn={true} />
          <div className="gap20" />
        </div>
        <div className="container-fluid">
          <div className="card">
            <div className="container-fluid">
              <div
                className={
                  this.state.name !== "" ? "mdInput mdFocussed" : "mdInput"
                }>
                <label>Name</label>
                <input
                  type="text"
                  name="name"
                  onChange={this.handleInputChange.bind(this)}
                  value={this.state.name}
                />
              </div>
              <div className="gap20" />
              <Checkbox
                label={"Non Customizable"}
                isChecked={this.state.nonCustomizable}
                handleCheckboxChange={this.toggleCheckbox}
                key={this.state.keyCheckbox}
              />
              <div className="gap20" />
              {this.state.editorSectionId && (
                <PageContent
                  key={this.state.keyEditor}
                  title={this.state.name}
                  screen={"add-custom-section"}
                  html={this.state.html}
                  content={this.state.json}
                  onChangeData={this.onChangeContent.bind(this)}
                  pageName={"addCustomSection"}
                  editorType={"mp"}
                  previewType={"mp-preview"}
                  draftId={this.state.draftId}
                  draftImage={this.state.draftImage}
                />
              )}
            </div>
          </div>
        </div>
        <div className="text-right container-fluid">
          <button
            className="btn btn-primary"
            onClick={() => this.onPressSave()}>
            Submit
          </button>
        </div>
        <div className="gap20" />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddCustomSectionPage);
