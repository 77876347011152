export const getItemAttributeValue = (
  type,
  id,
  isButton,
  selectedItem,
  selectedComponent,
  attributes,
  editorType,
  pageWidth
) => {
  let value = "";
  if (type === "marginTop" || type === "marginBottom") {
    if (selectedComponent === "section" && type === "marginBottom") {
      value = "20";
    } else if (
      (selectedItem.type === "checkbox" ||
        selectedItem.type === "selectbox" ||
        isButton) &&
      type === "marginTop"
    ) {
      value = "10";
      if (editorType === "email") {
        value = "0";
      }
    } else if (selectedItem.type === "input") {
      if (type === "marginTop") {
        value = "0";
      } else if (type === "marginBottom") {
        value = "10";
      }
    } else {
      value = "0";
    }
  } else if (type === "marginRight" || type === "marginLeft") {
    value = "0";
  }
  if (
    ["paddingTop", "paddingBottom", "paddingLeft", "paddingRight"].indexOf(
      type
    ) > -1
  ) {
    let formTypes = [
      "input",
      "checkbox",
      "selectbox",
      "code",
      "navBar",
      "image",
      "productName",
      "productDescription",
      "productPrice",
      "fileInput",
    ];
    if (
      selectedComponent === "item" &&
      formTypes.indexOf(selectedItem.type) === -1
    ) {
      value = "10";
    } else {
      value = "0";
    }
    if (selectedComponent === "section") {
      if (["paddingLeft", "paddingRight"].indexOf(type) > -1) {
        value = "15";
      }
    }
  }
  if (type === "backgroundColor") {
    value = "#ffffff";
    if (isButton) {
      value = "#33aaee";
    }
  }
  if (type === "color") {
    value = "#000000";
    if (isButton) {
      value = "#ffffff";
    }
  }
  if (type === "borderRadius") {
    value = "0";
  }
  if (type === "borderRadius" && selectedItem.type === "button") {
    value = "6";
  }
  if (selectedItem.type === "title") {
    if (type === "customFontColor") {
      value = "#000000";
    }
    if (type === "customFontSize") {
      value = "40";
    }
    if (type === "customFontFamily") {
      value = "Ubuntu";
    }
    if (type === "letterSpacing") {
      value = "0";
    }
  }
  if (selectedItem.type === "subTitle") {
    if (type === "customFontColor") {
      value = "#000000";
    }
    if (type === "customFontSize") {
      value = "30";
    }
    if (type === "customFontFamily") {
      value = "Roboto";
    }
    if (type === "letterSpacing") {
      value = "0";
    }
  }
  if (
    selectedItem.type === "text" ||
    selectedItem.type === "checkbox" ||
    selectedItem.type === "iconText"
  ) {
    if (type === "customFontColor") {
      value = "#000000";
    }
    if (type === "customFontSize") {
      value = "22";
    }
    if (type === "customFontFamily") {
      value = "Roboto";
    }
    if (type === "letterSpacing") {
      value = "0";
    }
  }
  if (selectedItem.type === "button") {
    if (type === "customFontColor") {
      value = "#ffffff";
    }
    if (type === "customFontSize") {
      value = "26";
    }
    if (type === "customFontFamily") {
      value = "Ubuntu";
    }
    if (type === "fullWidth") {
      value = true;
    }
  }
  if (type === "backgroundRepeat") {
    value = false;
  }
  if (type === "backgroundSize") {
    value = false;
  }
  if (type === "backgroundFull") {
    value = true;
  }
  if (type === "buttonIconName") {
    value = "rightTriangle";
  }
  if (type === "buttonIconSize") {
    value = "20";
  }
  if (type === "buttonIconColor") {
    value = "#ffffff";
  }
  if (type === "buttonIconAlignment") {
    value = "left";
  }
  if (type === "buttonCreateCustomer") {
    value = true;
  }
  if (type === "buttonSaveSubmission") {
    value = false;
  }
  if (type === "fontWeight") {
    value = "400";
  }
  if (type === "buttonIconPaddingLeft" || type === "buttonIconPaddingRight") {
    value = "5";
  }
  if (type === "borderWidth") {
    value = "0";
  }
  if (type === "borderColor") {
    value = "#000000";
  }
  if (selectedItem.type === "input") {
    if (type === "borderWidth") {
      value = "1";
    }
    if (type === "borderColor") {
      value = "#e8e8e8";
    }
    if (type === "borderRadius") {
      value = "5";
    }
  }
  if (type === "isMaxWidth") {
    value = false;
  }
  if (type === "reverseColumns") {
    value = false;
  }
  if (type === "isMaxWidth" && selectedItem.type === "video") {
    value = true;
  }
  if (type === "maxWidth") {
    value = "0";
  }
  if (type === "maxWidth" && selectedItem.type === "imageSlider") {
    value = "300";
  }
  if (type === "maxWidth" && selectedItem.type === "video") {
    value = "700";
  }
  if (type === "maxWidth" && selectedComponent === "section") {
    if (editorType === "email") {
      value = pageWidth;
    } else if (editorType === "web" || editorType === "popup") {
      value = pageWidth;
    }
  }
  if (type === "width") {
    value = "100";
  }
  if (type === "height") {
    value = "1";
  }
  if (type === "borderSpacing") {
    value = "2";
  }
  if (type === "textAlign") {
    if (selectedItem.type === "button" || selectedItem.type === "video") {
      value = "center";
    }
    if (
      selectedItem.type === "title" ||
      selectedItem.type === "subTitle" ||
      selectedItem.type === "image"
    ) {
      value = "center";
    }
    if (
      selectedComponent === "section" ||
      selectedItem.type === "text" ||
      selectedItem.type === "checkbox" ||
      selectedItem.type === "iconText" ||
      selectedItem.type === "input"
    ) {
      value = "left";
    }
  }
  if (type === "inputType") {
    value = "name";
  }
  if (type === "inputWidth") {
    value = "100";
  }
  if (type === "inputFontSize") {
    value = 12;
  }
  if (type === "inputColor") {
    value = "#000000";
  }
  if (type === "linkColor") {
    value = "#26ace2";
  }
  if (type === "inputPlaceholder") {
    if (selectedItem.type === "input") {
      value = "Enter your name";
    }
    if (selectedItem.type === "selectbox") {
      value = "Select an Option";
    }
  }
  if (type === "inputName") {
    if (selectedItem.type === "input") {
      value = "customer-name";
    }
    if (selectedItem.type === "fileInput") {
      value = `file-${id}`;
    }
    if (selectedItem.type === "checkbox") {
      value = `checkbox-${id}`;
    }
    if (selectedItem.type === "selectbox") {
      value = `option-${id}`;
    }
  }
  if (type === "inputSaveResponse") {
    value = false;
  }
  if (type === "checkboxPosition") {
    value = "center";
  }
  if (type === "inputSaveResponseType") {
    value = "value";
  }
  if (type === "inputSaveResponseTagAttribute") {
    value = "";
  }
  if (type === "inputSaveResponseTags") {
    value = [];
  }
  if (type === "inputErrorMessage") {
    if (selectedItem.type === "input") {
      value = "Please enter your name";
    } else {
      value = "Field not completed";
    }
  }
  if (type === "inputRequired") {
    value = true;
  }
  if (type === "inputFormName") {
    value = "form0";
  }
  if (type === "buttonFormName") {
    value = "form0";
  }
  if (type === "buttonActionType") {
    value = "url";
  }
  if (type === "buttonSecondLineFontSize") {
    value = "14";
  }
  if (type === "buttonSecondLineLineHeight") {
    value = "1";
  }
  if (type === "inputSelectPlaceholder") {
    value = true;
  }
  if (type === "inputMultipleLines") {
    value = false;
  }
  if (type === "inputHeight") {
    value = "100";
  }
  if (type === "buttonTags") {
    value = [];
  }
  if (type === "buttonNewWindow") {
    value = false;
  }
  if (type === "buttonMarkCompleted") {
    value = false;
  }
  if (type === "imageLinkNewWindow") {
    value = false;
  }
  if (type === "lock") {
    value = false;
  }
  if (type === "iconElemName") {
    value = "rightTriangle";
  }
  if (type === "iconElemSize") {
    value = "20";
  }
  if (type === "iconGap") {
    value = "5";
  }
  if (type === "imageGap") {
    value = "10";
  }
  if (type === "iconPosition") {
    value = "center";
  }
  if (type === "textPosition") {
    value = "middle";
  }
  if (type === "videoType") {
    value = "16-9";
  }
  if (type === "iconElemColor") {
    value = "#000000";
  }
  if (type === "cdTimeFontSize") {
    value = "30";
  }
  if (type === "cdFontSize") {
    value = "16";
  }
  if (type === "fontSize") {
    value = "14";
  }
  if (type === "logoHeight") {
    value = "35";
  }
  if (type === "fontFamily") {
    value = "Ubuntu";
  }
  if (type === "cdOuterBackgroundColor") {
    value = "#333333";
  }
  if (type === "cdInnerBackgroundColor") {
    value = "#555555";
  }
  if (type === "cdFontFamily") {
    value = "Ubuntu";
  }
  if (type === "cdDateTypeOption") {
    value = "date";
  }
  if (type === "cdDateYear") {
    value = new Date().getFullYear();
  }
  if (type === "cdDateMonth") {
    value = "1";
  }
  if (type === "cdDateDay") {
    value = "01";
  }
  if (type === "cdDateHour") {
    value = "00";
  }
  if (type === "cdDateMinute") {
    value = "01";
  }
  if (type === "lineStyle") {
    value = "solid";
  }
  if (type === "linkUrl") {
    value = "";
  }
  if (type === "sliderFullImage") {
    value = false;
  }
  if (type === "inputLimitMax") {
    value = "";
  }
  if (type === "inputLimitIn") {
    value = "";
  }
  if (type === "inputLimit") {
    value = false;
  }
  if (type === "lineHeight") {
    value = "40";

    if (selectedItem.type === "title") {
      value = "40";
    }
    if (selectedItem.type === "subTitle") {
      value = "30";
    }
    if (
      selectedItem.type === "text" ||
      selectedItem.type === "checkbox" ||
      selectedItem.type === "iconText"
    ) {
      value = "22";
    }
    if (
      selectedItem.type === "productName" ||
      selectedItem.type === "productDescription" ||
      selectedItem.type === "productPrice" ||
      selectedItem.type === "iconText"
    ) {
      value = "14";
    }
  }
  if (
    type === "cdShowDays" ||
    type === "cdShowHours" ||
    type === "cdShowMinutes" ||
    type === "cdShowSeconds"
  ) {
    value = true;
  }
  if (
    attributes[id] &&
    attributes[id][type] !== "" &&
    attributes[id][type] !== undefined &&
    attributes[id][type] !== null
  ) {
    value = attributes[id][type];
  }
  return value;
};

export const getPageAttributeValue = (name, type, pageAttr) => {
  let value = "";
  if (type === "width" && name === "page") {
    value = "1600";
  }
  if (type === "fontSize" && name === "title") {
    value = "40";
  }
  if (type === "fontSize" && name === "subTitle") {
    value = "30";
  }
  if (type === "fontSize" && name === "text") {
    value = "22";
  }
  if (type === "lineHeight" && name === "title") {
    value = "40";
  }
  if (type === "lineHeight" && name === "subTitle") {
    value = "30";
  }
  if (type === "lineHeight" && name === "text") {
    value = "22";
  }
  if (type === "font" && name === "title") {
    value = "Ubuntu";
  }
  if (type === "font" && name === "subTitle") {
    value = "Roboto";
  }
  if (type === "font" && name === "text") {
    value = "Roboto";
  }
  if (type === "letterSpacing" && name === "title") {
    value = "0";
  }
  if (type === "letterSpacing" && name === "subTitle") {
    value = "0";
  }
  if (type === "letterSpacing" && name === "text") {
    value = "0";
  }
  if (type === "fontSize" && name === "button") {
    value = "26";
  }
  if (type === "font" && name === "button") {
    value = "Ubuntu";
  }
  if (type === "color" && name === "text") {
    value = "#000000";
  }
  if (type === "color") {
    value = "#000000";
  }
  if (type === "color" && name === "button") {
    value = "#ffffff";
  }
  if (type === "textAlign") {
    value = "center";
  }
  if (type === "backgroundColor" && name === "page") {
    value = "#ffffff";
  }
  if (type === "backgroundRepeat") {
    value = false;
  }
  if (type === "backgroundSize") {
    value = false;
  }
  if (type === "backgroundOpacity") {
    value = 1;
  }
  if (type === "fontWeight") {
    value = 400;
  }
  if (pageAttr[name] && pageAttr[name][type]) {
    value = pageAttr[name][type];
  }

  return value;
};
