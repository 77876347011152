let adminScreens = {
  "/admin": "dashboard",
  "/admin/add-user": "addUser",
  "/admin/users": "users",
  "/admin/view-archived-users": "users",
  "/admin/edit-user": "editUser",
  "/admin/send-message": "editUser",
  "/admin/create-message": "editUser",
  "/admin/add-challenge-question": "addChlgQue",
  "/admin/view-challenge-questions": "viewChlgQue",
  "/admin/add-challenge-item": "addChlgItem",
  "/admin/view-challenge-items": "viewChlgItems",
  "/admin/add-challenge": "addChlg",
  "/admin/challenge-atq-rules": "addChlg",
  "/admin/view-challenges": "viewChlgs",
  "/admin/preview-challenges": "viewChlgs",
  "/admin/add-system-tag": "addSystemTag",
  "/admin/view-system-tags": "viewSystemTags",
  "/admin/view-atq": "accTrackFeedback",
  "/admin/view-messages": "editUser",
  "/admin/view-tasks": "viewTasks",
  "/admin/marcis-circle-overview": "marciCircle",
  "/admin/add-newsletter": "addNewsletter",
  "/admin/view-newsletters": "viewNewsletters",
  "/admin/add-mc-atq-question": "addMcAtqQuestion",
  "/admin/add-spotlight": "addSpotlight",
  "/admin/view-spotlights": "viewSpotlights",
  "/admin/add-checkout-item": "addCheckoutItem",
  "/admin/view-checkout-items": "viewCheckoutItems",
  "/admin/add-promo-code": "addPromoCode",
  "/admin/view-promo-codes": "viewPromoCodes",
  "/admin/add-upsell-item": "addUpsellItem",
  "/admin/view-upsell-items": "viewUpsellItems",
  "/admin/add-funnel-path": "addFunnelPath",
  "/admin/view-funnel-paths": "viewFunnelPaths",
  "/admin/add-member-payment": "addMemberPayment",
  "/admin/view-member-payments": "viewMemberPayments",
  "/admin/add-bank-transfer": "addBankTransfer",
  "/admin/view-payments-error-logs": "viewPaymentsErrorLogs",
  "/admin/add-article": "addArticle",
  "/admin/view-articles": "viewArticles",
  "/admin/add-additional-card": "addAdditionalCard",
  "/admin/marcis-circle-newsletter": "marciCircleNews",
  "/admin/add-email": "addEmail",
  "/admin/add-single-email": "addEmail",
  "/admin/add-automated-email": "addAutomationEmail",
  "/admin/edit-email": "addEmail",
  "/admin/view-emails": "viewEmails",
  "/admin/add-email-sequence": "addEmailSequence",
  "/admin/view-email-sequences": "viewEmailSequences",
  "/admin/email-settings": "emailSettings",
  "/admin/add-meal-plan": "addMealPlans",
  "/admin/view-meal-plans": "viewMealPlans",
  "/admin/add-subscription-plan": "addPlan",
  "/admin/view-subscription-plans": "viewPlans",
  "/admin/reports": "reports",
  "/admin/reports/email-reports": "reports",
  "/admin/reports/email-links": "reports",
  "/admin/reports/marci-circle-reports": "reports",
  "/admin/add-custom-program": "addCustomProgram",
  "/admin/view-custom-programs": "viewCustomPrograms",
  "/admin/edit-custom-program": "viewCustomPrograms",
  "/admin/edit-custom-program-day": "viewCustomPrograms",
  "/admin/add-exercise": "addExercise",
  "/admin/view-exercises": "viewExercises",
  "/admin/add-program-phase": "viewCustomPrograms",
  "/admin/add-system-update": "addSystemUpdate",
  "/admin/view-system-updates": "viewSystemUpdates",
  "/admin/add-challenge-group": "addChlgGroup",
  "/admin/view-challenge-groups": "viewChlgGroup",
  "/admin/tasks": "viewTasks",
  "/admin/task-settings": "taskSettings",
  "/admin/create-task": "createTask",
  "/admin/view-templates": "viewTemplates",
  "/admin/add-company": "addCompany",
  "/admin/add-company-license": "addCompany",
  "/admin/view-companies": "viewCompanies",
  "/admin/add-license": "addLicense",
  "/admin/view-licenses": "viewLicenses",
  "/admin/view-applications": "viewApplications",
  "/admin/chats": "chats",
  "/admin/chats/reply": "chats",
  "/admin/add-movement": "addMovement",
  "/admin/view-movements": "viewMovements",
  "/admin/add-resource": "addResource",
  "/admin/view-resources": "viewResources",
  "/admin/add-certificate": "addCertificate",
  "/admin/view-certificates": "viewCertificates",
  "/admin/import-users": "importUsers",
  "/admin/add-popup": "addPopup",
  "/admin/view-popups": "viewPopups",
  "/admin/add-landing-page": "addLandingPage",
  "/admin/view-landing-pages": "viewLandingPages",
  "/admin/landing-website-menu": "landingWebsiteMenu",
  "/admin/add-custom-section": "viewCustomSections",
  "/admin/view-custom-sections": "viewCustomSections",
  "/admin/add-process": "addProcessPage",
  "/admin/view-processes": "viewProcessesPage",
  "/admin/add-churn-item": "addChurnItem",
  "/admin/view-churn-items": "viewChurnItems",
  "/admin/view-generic-tags": "viewGenericTags",
  "/admin/view-article-categories": "viewArticleCategories",
  "/admin/add-event": "addEvent",
  "/admin/view-events": "viewEvents",
  "/admin/add-event-customer": "addEventCustomer",
  "/admin/view-event-customers": "viewEventCustomers",
  "/admin/view-promotions": "viewPromotions",
  "/admin/add-notification": "addNotification",
  "/admin/view-notifications": "viewNotifications",
  "/admin/settings": "settings",
  "/admin/add-member": "addMember",
  "/admin/add-form": "addForm",
  "/admin/view-forms": "viewForms",
  "/admin/add-pdf-form": "addPDFForm",
  "/admin/view-pdf-forms": "viewPDFForms",
  "/admin/add-external-form": "addExternalForm",
  "/admin/view-external-forms": "viewExternalForms",
  "/admin/add-quiz": "addQuiz",
  "/admin/view-quizzes": "viewQuizzes",
  "/admin/view-payments": "viewPayments",
  "/admin/view-upcoming-payments": "viewUpcomingPayments",
  "/admin/add-subscription-area": "addSubscriptionArea",
  "/admin/view-subscription-area": "viewSubscriptionArea",
  "/admin/add-podcast": "addPodcast",
  "/admin/view-podcasts": "viewPodcasts",
  "/admin/add-registration-page": "addRegistrationPage",
  "/admin/view-registration-pages": "viewRegistrationPages",
  "/admin/enter-registration-mode": "enterRegistrationMode",
  "/admin/staff-registration-codes": "staffRegistrationCodes",
  "/admin/add-question": "addQuestion",
  "/admin/view-questions": "viewQuestions",
  "/admin/add-help-item": "addHelpItem",
  "/admin/view-help-items": "viewHelpItems",
  "/admin/add-video": "addVideo",
  "/admin/view-videos": "viewVideos",
  "/admin/add-onboarding": "addOnboarding",
  "/admin/view-onboarding": "viewOnboarding",
  "/admin/add-help-desk": "addHelpDesk",
  "/admin/view-help-desk": "viewHelpDesk",
  "/admin/settings-help-desk": "viewHelpDeskSettings",
  "/admin/view-dbs": "viewDatabases",
  "/admin/add-classroom": "addClassroom",
  "/admin/view-classrooms": "viewClassrooms",
  "/admin/add-landing-funnel": "addLandingFunnel",
  "/admin/view-landing-funnels": "viewLandingFunnels",
  "/admin/create-demo-account": "createDemoAccount",
  "/admin/add-editor-popup": "addEditorPopup",
  "/admin/view-editor-popups": "viewEditorPopups",
  "/admin/add-sms": "addSMS",
  "/admin/view-sms": "viewSMS",
  "/admin/view-sms-history": "viewSMSHistory",
  "/admin/view-sms-conversation": "viewSMSHistory",
  "/admin/add-incoming-rule": "addIncomingRule",
  "/admin/view-incoming-rules": "viewIncomingRules",
  "/admin/sms-automation": "smsAutomation",
  "/admin/view-submissions": "viewSubmissions",
  "/admin/add-lead": "addLead",
  "/admin/view-leads": "viewLeads",
  "/admin/lead-settings": "leadSettings",
  "/admin/add-release": "addRelease",
  "/admin/view-releases": "viewReleases",
  "/admin/messages/open": "openMessages",
  "/admin/messages/pending": "pendingMessages",
  "/admin/messages/closed": "closedMessages",
  "/admin/coaching-site": "mysite",
  "/admin/add-tier": "addTier",
  "/admin/view-tiers": "viewTiers",
  "/admin/add-product": "addProduct",
  "/admin/view-products": "viewProducts",
  "/admin/add-invoice": "addInvoice",
  "/admin/view-invoices": "viewInvoices",
  "/admin/invoice-settings": "invoiceSettings",
  "/admin/add-pdf-template": "addPdfTemplate",
  "/admin/view-pdf-templates": "viewPdfTemplates",
  "/admin/add-department": "addDepartment",
  "/admin/view-departments": "viewDepartments",
  "/admin/add-ticket": "addTicket",
  "/admin/view-tickets": "viewTickets",
  "/admin/settings-tickets": "viewTicketsSettings",
};

let restRoles = {
  dashboard: ["/admin"],
  messages: [
    "/admin/messages/open",
    "/admin/messages/pending",
    "/admin/messages/closed",
  ],
  tasks: [
    "/admin/tasks",
    "/admin/task-detail",
    "/admin/create-task",
    "/admin/task-settings",
    "/admin/pay-task-history",
  ],
  "atq-responses": ["/admin/view-atq"],
  emails: [
    "/admin/add-email",
    "/admin/view-emails",
    "/admin/edit-email",
    "/admin/email-settings",
    "/admin/add-single-email",
    "/admin/add-automated-email",
    "/admin/add-email-sequence",
    "/admin/view-email-sequences",
  ],
  articles: [
    "/admin/add-article",
    "/admin/view-articles",
    "/admin/view-article-categories",
  ],
  companies: [
    "/admin/add-company",
    "/admin/add-company-license",
    "/admin/view-companies",
    "/admin/add-license",
    "/admin/view-licenses",
  ],
  users: [
    "/admin/add-user",
    "/admin/edit-user",
    "/admin/users",
    "/admin/send-message",
    "/admin/create-message",
    "/admin/view-messages",
    "/admin/import-users",
    "/admin/export-users",
    "/admin/add-member",
    "/admin/view-submissions",
    "/admin/view-archived-users",
  ],
  "system-tags": [
    "/admin/add-system-tag",
    "/admin/view-system-tags",
    "/admin/view-generic-tags",
  ],
  resources: ["/admin/add-resource", "/admin/view-resources"],
  "marci-circle": [
    "/admin/view-spotlights",
    "/admin/add-spotlight",
    "/admin/add-mc-atq-question",
    "/admin/add-movement",
    "/admin/view-movements",
    "/admin/add-education",
    "/admin/view-educations",
  ],
  challenges: [
    "/admin/add-challenge-question",
    "/admin/view-challenge-questions",
    "/admin/add-challenge-item",
    "/admin/view-challenge-items",
    "/admin/add-challenge",
    "/admin/view-challenges",
    "/admin/add-challenge-group",
    "/admin/view-challenge-groups",
  ],
  billing: [
    "/admin/add-subscription-plan",
    "/admin/view-subscription-plans",
    "/admin/add-checkout-item",
    "/admin/view-checkout-items",
    "/admin/add-promo-code",
    "/admin/view-promo-codes",
    "/admin/add-upsell-item",
    "/admin/view-upsell-items",
    "/admin/add-funnel-path",
    "/admin/view-funnel-paths",
    "/admin/view-promotions",
    "/admin/add-product",
    "/admin/view-products",
  ],
  payments: [
    "/admin/add-member-payment",
    "/admin/view-member-payments",
    "/admin/add-bank-transfer",
    "/admin/view-payments",
    "/admin/view-upcoming-payments",
    "/admin/view-payments-error-logs",
  ],
  reports: [
    "/admin/reports",
    "/admin/reports/email-reports",
    "/admin/reports/email-links",
    "/admin/reports/marci-circle-reports",
    "/admin/reports/zoom-meetings",
    "/admin/reports/affiliate-reports",
    "/admin/reports/bounced-emails",
    "/admin/reports/customer-revenue",
    "/admin/reports/upcoming-payments",
    "/admin/reports/payments-history",
  ],
  "custom-programs": [
    "/admin/add-custom-program",
    "/admin/view-custom-programs",
    "/admin/edit-custom-program",
    "/admin/edit-custom-program-day",
    "/admin/add-exercise",
    "/admin/view-exercises",
    "/admin/add-program-phase",
    "/admin/view-templates",
    "/admin/view-applications",
  ],
  "system-updates": ["/admin/add-system-update", "/admin/view-system-updates"],
  "preview-challenges": ["/admin/preview-challenges"],
  chats: ["/admin/chats", "/admin/chats/reply"],
  popups: ["/admin/add-popup", "/admin/view-popups"],
  "editor-popups": ["/admin/add-editor-popup", "/admin/view-editor-popups"],
  "landing-page": [
    "/admin/add-landing-page",
    "/admin/view-landing-pages",
    "/admin/add-landing-funnel",
    "/admin/view-landing-funnels",
    "/admin/add-custom-section",
    "/admin/view-custom-sections",
    "/admin/landing-website-menu",
  ],
  processes: [
    "/admin/add-process",
    "/admin/view-processes",
    "/admin/add-help-item",
    "/admin/view-help-items",
  ],
  churn: ["/admin/add-churn-item", "/admin/view-churn-items"],
  events: [
    "/admin/add-event",
    "/admin/view-events",
    "/admin/add-event-customer",
    "/admin/view-event-customers",
  ],
  notifications: ["/admin/add-notification", "/admin/view-notifications"],
  settings: ["/admin/settings"],
  forms: [
    "/admin/add-form",
    "/admin/view-forms",
    "/admin/add-pdf-form",
    "/admin/view-pdf-forms",
    "/admin/add-external-form",
    "/admin/view-external-forms",
  ],
  quiz: ["/admin/add-quiz", "/admin/view-quizzes"],
  "subscription-area": [
    "/admin/add-subscription-area",
    "/admin/view-subscription-area",
    "/admin/add-newsletter",
    "/admin/view-newsletters",
  ],
  registration: [
    "/admin/add-registration-page",
    "/admin/view-registration-pages",
    "/admin/enter-registration-mode",
    "/admin/staff-registration-codes",
  ],
  accountability: ["/admin/add-question", "/admin/view-questions"],
  onboarding: ["/admin/add-onboarding", "/admin/view-onboarding"],
  helpdesk: [
    "/admin/add-help-desk",
    "/admin/view-help-desk",
    "/admin/settings-help-desk",
  ],
  tickets: [
    "/admin/settings-tickets",
    "/admin/add-department",
    "/admin/view-departments",
    "/admin/add-ticket",
    "/admin/view-tickets",
  ],
  classrooms: ["/admin/add-classroom", "/admin/view-classrooms"],
  "text-messaging": [
    "/admin/add-sms",
    "/admin/view-sms",
    "/admin/view-sms-history",
    "/admin/view-sms-conversation",
    "/admin/add-incoming-rule",
    "/admin/view-incoming-rules",
  ],
  leads: ["/admin/add-lead", "/admin/view-leads"],
  releases: ["/admin/add-release", "/admin/view-releases"],
  mysite: ["/admin/coaching-site"],
  tiers: ["/admin/add-tier", "/admin/view-tiers"],
  invoices: [
    "/admin/add-invoice",
    "/admin/view-invoices",
    "/admin/invoice-settings",
  ],
  "pdf-templates": ["/admin/add-pdf-template", "/admin/view-pdf-templates"],
};

let fullRoles = {
  dashboard: ["/admin"],
  messages: [],
};

let memberScreens = {
  "/member": "dashboard",
  "/member/add-challenge": "dashboard",
  "/member/challenge": "challenge",
  "/member/challenge-item": "challenge",
  "/member/challenge-consent": "challenge",
  "/member/atq": "atq",
  "/member/messages": "messages",
  "/member/tasks": "tasks",
  "/member/view-messages": "messages",
  "/member/view-progress-journal": "viewProgressJournal",
  "/member/add-progress-journal": "addProgressJournal",
  "/member/photo-consent": "addProgressJournal",
  "/member/marcis-circle": "marciCircle",
  "/member/marcis-circle/home": "mcHome",
  "/member/marcis-circle/newsletter": "mcNewsletter",
  "/member/marcis-circle/newsletter-detail": "mcNewsletter",
  "/member/marcis-circle/meal-plans": "mcMealPlans",
  "/member/marcis-circle/trainings": "mcTrainings",
  "/member/marcis-circle/foam-roller": "mcFoamRoller",
  "/member/marcis-circle/courses": "mcCourses",
  "/member/marcis-circle/news": "mcNews",
  "/member/marcis-circle/savings": "mcSavings",
  "/member/marcis-circle/forum": "mcForum",
  "/member/marcis-circle/podcasts": "mcPodcasts",
  "/member/marcis-circle/buy-challenge": "mcBuyChallenge",
  "/member/meal-plan-database": "mealPlanDatabase",
  "/member/awards": "awards",
  "/member/charts": "charts",
  "/member/payment-history": "paymentHistory",
  "/member/btp": "btp",
  "/member/billing": "memberBilling",
  "/member/resources": "resources",
  "/member/movements": "mcMovements",
  "/member/educations": "mcEducations",
  "/member/events": "memberEvents",
  "/member/affiliate": "memberAffiliate",
};

let companyScreens = {
  "/company": "dashboard",
  "/company/detail": "detail",
  "/company/billing": "billing",
  "/company/licenses": "licenses",
  "/company/users": "users",
};

let frontendScreens = {
  "/": "home",
  "/get-started": "getStarted",
  "/login": "login",
  "/checkout": "checkout",
  "/thank-you": "thankYou",
  "/process-order": "processOrder",
  "/reset-password": "forgotPassword",
  "/new-password": "newPassword",
  "/terms-of-service": "termsService",
  "/privacy-policy": "privacyPolicy",
  "/macro-calc": "macroCalculator",
  "/hec": "healthyEatingChallenge",
};

let registrationScreens = {
  "/registration": "registration",
  "/registration/register": "registration",
  "/registration/checkin": "registration",
  "/registration/exit": "exitRegistration",
};

let searchPages = [
  { name: "Your ATQ", url: "/member/atq", id: 1 },
  { name: "Dashboard", url: "/member", id: 2 },
  { name: "Messages", url: "/member/messages", id: 3 },
  { name: "Marci Circle", url: "/member/marcis-circle/home", id: 4 },
  { name: "Newsletter", url: "/member/marcis-circle/newsletter", id: 4 },
  { name: "Meal Plans", url: "/member/marcis-circle/meal-plans", id: 4 },
  { name: "Trainings", url: "/member/marcis-circle/trainings", id: 4 },
  { name: "Foam Rolling", url: "/member/marcis-circle/foam-roller", id: 4 },
  { name: "Progress", url: "/member/view-progress-journal", id: 5 },
  { name: "Journal", url: "/member/view-progress-journal", id: 5 },
  { name: "Profile", url: "/member/profile", id: 6 },
];

let userRoles = [
  {
    name: "Accountability Responses",
    value: "atq-responses",
  },
  { name: "Emails", value: "emails" },
  { name: "Articles", value: "articles" },
  { name: "Companies", value: "companies" },
  { name: "Users", value: "users" },
  {
    name: "System Tags",
    value: "system-tags",
  },
  {
    name: "Resources",
    value: "resources",
  },
  {
    name: "Tasks",
    value: "tasks",
  },
  {
    name: "Marci Circle",
    value: "marci-circle",
  },
  {
    name: "Challenges",
    value: "challenges",
  },
  {
    name: "Preview Challenges",
    value: "preview-challenges",
  },
  { name: "Billing", value: "billing" },
  { name: "Payments", value: "payments" },
  { name: "Reports", value: "reports" },
  {
    name: "Custom Programs",
    value: "custom-programs",
  },
  {
    name: "System Updates",
    value: "system-updates",
  },
  {
    name: "Chats",
    value: "chats",
  },
  {
    name: "Pre-Page Content",
    value: "popups",
  },
  {
    name: "Editor Popups",
    value: "editor-popups",
  },
  {
    name: "Landing Page",
    value: "landing-page",
  },
  {
    name: "Processes",
    value: "processes",
  },
  {
    name: "Churn",
    value: "churn",
  },
  {
    name: "Events",
    value: "events",
  },
  {
    name: "Notifications",
    value: "notifications",
  },
  {
    name: "Settings",
    value: "settings",
  },
  {
    name: "Forms",
    value: "forms",
  },
  {
    name: "Quiz",
    value: "quiz",
  },
  {
    name: "Subscription Area",
    value: "subscription-area",
  },
  {
    name: "Accountability",
    value: "accountability",
  },
  {
    name: "Onboarding",
    value: "onboarding",
  },
  {
    name: "Knowledgebase",
    value: "helpdesk",
  },
  {
    name: "Tickets",
    value: "tickets",
  },
  {
    name: "Classroom",
    value: "classrooms",
  },
  {
    name: "Text Messaging",
    value: "text-messaging",
  },
  {
    name: "Leads",
    value: "leads",
  },
  {
    name: "Messages",
    value: "messages",
  },
  {
    name: "Tini Site",
    value: "mysite",
  },
  {
    name: "Tier Settings",
    value: "tiers",
  },
  {
    name: "Invoices",
    value: "invoices",
  },
  {
    name: "PDF Templates",
    value: "pdf-templates",
  },
];

let reportRoles = [
  {
    name: "Email",
    value: "email",
  },
  { name: "Payment", value: "payment" },
  { name: "Challenge", value: "challenge" },
  { name: "Affiliate", value: "affiliate" },
  { name: "Zoom Meeting", value: "zoom" },
  { name: "Dashboard", value: "dashboard" },
];

let paymentsRoles = [
  {
    name: "Account Balance",
    value: "account-balance",
  },
  { name: "Member Payments", value: "member-payments" },
];

let usersRoles = [
  {
    name: "User Type",
    value: "user-type",
  },
  {
    name: "Import Users",
    value: "import-users",
  },
];

let ticketRoles = [
  {
    name: "View Tickets",
    value: "view-tickets",
  },
  {
    name: "Settings",
    value: "settings",
  },
];

export {
  adminScreens,
  memberScreens,
  frontendScreens,
  companyScreens,
  registrationScreens,
  restRoles,
  fullRoles,
  searchPages,
  userRoles,
  reportRoles,
  paymentsRoles,
  usersRoles,
  ticketRoles,
};
