import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment-timezone";
import $ from "jquery";

import TitleBox from "../../../components/general/titleBox";
import FilterList from "../../../components/form/filterList";
import { filterByTags } from "../../../helpers/filters";
import { callGetLicenses, callDeleteLicense } from "../../../services";

class ViewLicense extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      licenses: [],
      licensesBackup: [],
      filterList: [],
      selectedLicense: {}
    };
  }

  componentDidMount() {
    this.getLicenses();
  }

  getLicenses() {
    callGetLicenses().then(d => {
      this.setState({ licenses: d.data, licensesBackup: d.data });
    });
  }

  filterUpdated(updatedFilter, name, type, searchFields) {
    let filterList = this.state.filterList;
    filterList[name] = {
      name: name,
      type: type,
      searchFields: searchFields,
      values: updatedFilter
    };
    this.setState(
      {
        filterList: filterList
      },
      () => {
        let data = filterByTags(this.state.licensesBackup, filterList);
        this.setState({
          licenses: data
        });
      }
    );
  }

  onClickEditLicense(license) {
    this.props.history.push("/admin/add-license", { license: license });
  }

  onClickDeleteLicense(license) {
    this.setState({ selectedLicense: license }, () => {
      $(".modalAlertOverlay,.modalAlert").fadeIn(200);
    });
  }

  onClickNoAlert = () => {
    $(".modalAlertOverlay,.modalAlert").fadeOut(200);
  };

  onClickYesAlert = () => {
    callDeleteLicense(this.state.selectedLicense._id).then(d => {
      $(".modalAlertOverlay,.modalAlert").fadeOut(200);
      this.getLicenses();
    });
  };

  renderConfirmationAlert() {
    let selectedLicense = this.state.selectedLicense;
    let name = "";
    if (selectedLicense.name) {
      name = selectedLicense.name;
    }
    return (
      <div>
        <div className="modalAlertOverlay" />
        <div className="modalAlert">
          <p>{`Are you sure you want to delete "${name}" license?`}</p>
          <div className="alertFooterBtns">
            <button
              className="btn btn-default closeModal-No margin-right-10"
              onClick={this.onClickNoAlert}>
              No
            </button>
            <button
              className="btn btn-primary closeModal-Yes"
              onClick={this.onClickYesAlert}>
              Yes
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderLicenses() {
    var returnVals = [];
    let items = this.state.licenses;
    items.forEach(element => {
      returnVals.push(
        <tr key={element._id}>
          <td>{element.name}</td>
          <td>{element.description}</td>
          <td>{element.challenge_list.length}</td>
          <td>{element.product_list.length}</td>
          <td>{moment(element.expire_date).format("MMMM Do, YYYY")}</td>
          <td className="min-width-100">
            <button
              onClick={this.onClickDeleteLicense.bind(this, element)}
              className="btn btn-danger btn-sm margin-right-10 border-0">
              <i className="fa fa-trash" />
            </button>
            <button
              onClick={this.onClickEditLicense.bind(this, element)}
              className="btn btn-default btn-sm border-0">
              <i className="fa fa-pencil" />
            </button>
          </td>
        </tr>
      );
    });
    return returnVals;
  }

  renderFilterView() {
    return (
      <div className="list-group-item">
        <FilterList
          label={"Search Licenses"}
          name={"search"}
          type={"search"}
          searchFields={["name", "description"]}
          onFinish={this.filterUpdated.bind(this)}
        />
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderConfirmationAlert()}
        <div className="container-fluid">
          <TitleBox title={"View Licenses"} />
          <div className="gap20" />
          <div className="card">
            <div className="list-group list-group-flush">
              {this.renderFilterView()}
              <div className="list-group-item">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Description</th>
                      <th>No Of Challenges</th>
                      <th>No Of Products</th>
                      <th>Expire Date</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>{this.renderLicenses()}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewLicense);
