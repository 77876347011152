import {
  CREATE_BTP_QUESTIONNAIRE_SUCCESS,
  CREATE_BTP_QUESTIONNAIRE_FAILED
} from "../types";

const initialState = {
  message: "",
  success: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_BTP_QUESTIONNAIRE_SUCCESS:
      return {
        ...state,
        message: action.message,
        success: action.success
      };
    case CREATE_BTP_QUESTIONNAIRE_FAILED:
      return {
        ...state,
        message: action.message,
        success: action.success
      };
    default:
      return state;
  }
};
