import React from "react";
import ContentHtml from "../../../components/general/contentHtml";

class PreviewMPEditor extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      html: "",
      keyEditor: Math.random(),
    };
  }

  componentDidMount() {
    let html = localStorage.getItem("htmlStr");
    this.setState({ html: html, keyEditor: Math.random() });
  }

  render() {
    return (
      <ContentHtml
        key={this.state.keyEditor}
        html={this.state.html}
        type="iframe"
      />
    );
  }
}

export default PreviewMPEditor;
