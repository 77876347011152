import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import EmailEditor from "./reactEmailEditor";
// import $ from "jquery";
import PubSub from "pubsub-js";

import FilterList from "../form/filterList";
import GenericTagSelect from "../form/genericTagSelect";
import config from "../../helpers/config";
import { filterByTags } from "../../helpers/filters";
import {
  closeContentEditor,
  isTemplateSite,
  imageUrl,
  getParameterByName,
  templateImageUrl,
} from "../../helpers/index";
import { showNotification } from "../../helpers";

import {
  resetStatus,
  getContentTemplate,
  getGenericTags,
} from "../../modules/actions/index";

import {
  callEmailTemplateData,
  callEmailTemplateById,
} from "../../services/settingService";

import {
  callGetContentTemplate,
  callGetContentTemplateById,
  callGetEditorDrafts,
  callGetEditorPopups,
  callGetGenericTags,
  callResendEmail,
} from "../../services";

import {
  callCreateGenericTag,
  callCreateContentTemplate,
  callUpdateContentTemplate,
  callCreateEditorDraft,
  callDeleteContentTemplateById,
} from "../../services";

import DefaultEmailData from "./defaultEmailEditorData.json";
import DefaultWebData from "./defaultWebEditorData.json";

import AddEmailLink from "./addLink";
import EditorDraft from "./editorDrafts";
import EditorImages from "./editorImages";
import HtmlSnippets from "./htmlSnippets";
import { getItem, getStorageItem, setStorageItem } from "../../helpers/storage";
import Scrollbars from "react-custom-scrollbars";
import MPContentEditor from "../../packages/mp-content-editor";
import { processEditorContent } from "./processEditor";
import moment from "moment-timezone";
import Checkbox from "../form/checkBox";

declare var $: any;

class ContentEditor extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    let displayMode = this.props.displayMode || "web";

    let categories = [
      { name: "All", value: "all", selected: false },
      {
        name: "Email",
        value: "email",
        selected: displayMode === "email",
      },
      {
        name: "Web Content",
        value: "web",
        selected: displayMode === "web",
      },
      {
        name: "Popup",
        value: "popup",
        selected: displayMode === "popup",
      },
    ];

    let editorType = this.props.editorType || "unlayer";
    let editor = getParameterByName("mp") || "n";
    if (editor === "y" || editorType === "mp") {
      editorType = "mp";
    } else {
      editorType = "unlayer";
    }

    this.html = "";
    this.countDraft = 0;

    this.state = {
      userData: this.props.userData || {},
      templateName: "",
      templateSortPriority: 0,
      selectedTemplate: "0",
      selectedCategory: this.props.displayMode,
      tags: [],
      genericTagss: [],
      contentType: "",
      filterTags: [],
      backupFilterTags: [],
      keyFilterTag: Math.random(),
      fTags: { new: [], existing: [] },
      contentHtml: "",
      contentJson: {},
      contentTemplates: [],
      contentPreTemplates: [],
      contentTemplatesOriginal: [],
      searchQuery: "",
      selectedTags: [],
      filterList: [],
      displayMode: displayMode,
      keyTag: Math.random(),
      selectedTab: "editor",
      editorType: editorType,
      editorChange: false,
      keyRadio: Math.random(),
      editorContent: this.props.editorContent,
      keyEditor: Math.random(),
      selectedDeleteTemplate: {},
      types: [
        { name: "All", value: "all", selected: true },
        {
          name: "Pre-made",
          value: "pre-made",
          selected: false,
        },
        {
          name: "Custom",
          value: "yours",
          selected: false,
        },
      ],
      categories: categories,
      selectedTemplateImage: {},
      imageSelectType: "upload",
      imageUrl: "",
      videoUrl: "",
      imageType: "imageUrl",
      editorPopups: [],
      showSaveAsTemplate: editorType === "mp" ? false : true,
      imageKey: Math.random(),
      isTemplatesLoading: true,
      refreshKey: Math.random(),
      itemId: "",
      hideOtherTabs: false,
      challengeInfo: this.props.challengeInfo || {},
      tags2: [],
      filterCategory: "email",
      templateChallengeContent: false,
      templateCourseItem: false,
      templateSalesPage: false,
      templateOptinPage: false,
      templateThankYouPage: false,
      templateEventsPage: false,
      templateQuizesPage: false,
      templateFormsPage: false,
      templatKnowledgebaseArticle: false,
      keyTemplateOption1: Math.random(),
      keyTemplateOption2: Math.random(),
      keyTemplateOption3: Math.random(),
      keyTemplateOption4: Math.random(),
      keyTemplateOption5: Math.random(),
      keyTemplateOption6: Math.random(),
      keyTemplateOption7: Math.random(),
      keyTemplateOption8: Math.random(),
      keyTemplateOption9: Math.random(),
      defaultTemplates: {
        challengeContent: "",
        courseItem: "",
        salesPage: "",
        optinPage: "",
        thankYouPage: "",
        eventsPage: "",
        quizesPage: "",
        formsPage: "",
      },
    };
  }

  componentDidMount() {
    this.getInitialData();

    this.draftInterval = setInterval(() => {
      if (this.state.editorType === "unlayer") {
        if (this.editor !== undefined) {
          this.editor.exportHtml((data) => {
            const { html } = data;
            this.editor.saveDesign((design) => {
              this.callCreateEditorDraftAPI(
                html,
                design,
                2,
                "unlayer",
                "interval"
              );
            });
          });
        }
      }
      if (this.state.editorType === "mp") {
        PubSub.publish("getMpEditorData", "");
        PubSub.subscribe("mpEditorData", (key, data) => {
          if (this.html !== data.html) {
            this.html = data.html;
            this.callCreateEditorDraftAPI(
              data.html,
              data.json,
              2,
              "mp",
              "interval"
            );
          }
        });
      }
    }, config.draftInterval);
  }

  componentWillUnmount() {
    clearInterval(this.draftInterval);
  }

  async getInitialData() {
    // this.onClickTemplates();
    // this.setState({
    //   selectedTab: "templates",
    //   isTemplatesLoading: false,
    // });

    let editorType = this.state.editorType || "unlayer";
    let editorContent = this.props.editorContent;

    if (editorType === "mp") {
      if (editorContent && editorContent.contentEditor) {
        this.onClickEditor();
      } else {
        this.onClickEditor();
      }
      this.setState({
        keyEditor: Math.random(),
        editorType: editorType,
        keyRadio: Math.random(),
        editorContent: editorContent,
      });
    }

    let object = await callGetGenericTags("content-template");
    let object2 = await callGetGenericTags("content-template-email");

    let d = await callGetEditorPopups();
    let popups = [];
    d.data.forEach((element) => {
      popups.push({
        id: element._id,
        title: element.title,
        json: element.content,
      });
    });
    if (this.props.onRefreshData) {
      this.props.onRefreshData();
    }
    this.setState({
      editorPopups: popups,
      keyEditor: Math.random(),
      tags: object.data,
      tags2: object2.data,
      keyTag: Math.random(),
      contentType: "content-template",
    });
  }

  onLoadFinish = async () => {
    if (!this.state.editorChange) {
      let editorType = this.state.editorType || "unlayer";
      let editorContent = this.props.editorContent || {};

      if (!editorContent.body && this.props.screen !== "add-landing-page") {
        if (this.state.displayMode === "web") {
          if (editorType === "unlayer") {
            editorContent = DefaultWebData;
          }
        } else {
          let emailDefaultTemplateId = config.editorTemplateIds.defaultEmail;
          let editorData = await callEmailTemplateById(emailDefaultTemplateId);
          if (editorData.data._id) {
            editorContent = editorData.data.editor_content;
          } else {
            if (editorType === "unlayer") {
              editorContent = DefaultEmailData;
            }
          }
        }
      }

      if (editorType === "unlayer") {
        if (editorContent && editorContent.body) {
          this.onClickEditor();
          if (this.editor !== undefined && this.editor !== null) {
            this.editor.loadDesign(editorContent);
          } else {
            setTimeout(() => {
              if (this.editor !== undefined && this.editor !== null) {
                this.editor.loadDesign(editorContent);
              }
            }, 4000);
          }
        } else {
          this.onClickTemplates();
        }
      } else {
        editorType = "unlayer";
      }
      this.setState({
        editorType: editorType,
        keyRadio: Math.random(),
        editorContent: editorContent,
      });
    }
  };

  componentWillReceiveProps(newProps) {
    if (this.state.contentType === "content-template") {
      this.setState({
        tags: this.state.tags,
        contentType: "",
        keyTag: Math.random(),
      });
    }

    if (newProps.userData) {
      this.setState({ userData: newProps.userData });
    }
  }

  async getContentTemplates() {
    let contentPreTemplates = [];
    if (!isTemplateSite()) {
      let object = await callEmailTemplateData();
      contentPreTemplates = object.data || [];
    }

    let query = {
      query: {
        $select: ["-editor_content", "-html"],
      },
    };
    let d = await callGetContentTemplate(query);
    this.setState({
      contentTemplates: [...d.data, ...contentPreTemplates],
      contentTemplatesOriginal: [...d.data, ...contentPreTemplates],
      isTemplatesLoading: false,
    });
    this.prepareDataForFilter(this.state.contentTemplatesOriginal);
    this.filterTemplates();
  }

  onClickEditor() {
    this.setState({
      selectedTab: "editor",
    });
  }

  onClickTemplates() {
    this.setState({
      selectedTab: "templates",
    });

    // this.props.getContentTemplate();
    this.getContentTemplates();
  }

  onClickLinks() {
    this.setState({
      selectedTab: "links",
    });
  }

  onClickDrafts() {
    this.setState({
      selectedTab: "drafts",
    });
  }

  onClickImages() {
    this.setState({
      selectedTab: "images",
      imageSelectType: "upload",
    });
  }

  onClickHtmlSnippets() {
    this.setState({
      selectedTab: "snippets",
    });
  }

  onSelectEditorType(item, id) {
    let value = item[Object.keys(item)[0]].value;
    this.setState({ [id]: value, editorChange: true });
  }

  resetProps() {
    this.setState({
      templateName: "",
      selectedTemplate: "0",
      selectedCategory: this.props.displayMode,
    });
  }

  prepareDataForFilter(data) {
    let filterTags = [{ name: "All", value: "all", selected: true }];
    let filterTags2 = [{ name: "All", value: "all", selected: true }];

    let uData = data;

    for (let i = 0; i < uData.length; i++) {
      const element = uData[i];

      let tag = element.ftags || [];
      // eslint-disable-next-line
      tag.map((el, i) => {
        let arrTag = filterTags.filter((d) => {
          return d.name === el.name.toLowerCase();
        });
        if (arrTag.length === 0) {
          if (el.type === "content-template") {
            filterTags.push({
              name: el.name.toLowerCase(),
              value: el.name,
              selected: false,
              type: el.type,
            });
          } else {
            filterTags2.push({
              name: el.name.toLowerCase(),
              value: el.name,
              selected: false,
              type: el.type,
            });
          }
        }
      });
    }
    this.setState({
      filterTags: filterTags,
      backupFilterTags: filterTags2,
      keyFilterTag: Math.random(),
    });
  }

  filterTemplates() {
    let data = filterByTags(
      this.state.contentTemplatesOriginal,
      this.state.filterList
    );
    this.setState({
      contentTemplates: data,
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  renderCloseAlert() {
    return (
      <div>
        <div className="modalAlertOverlay closeEditorModal" />
        <div className="modalAlert closeEditorAlert">
          <p>Are you sure you want to close the editor?</p>
          <div className="alertFooterBtns">
            {/*eslint-disable*/}
            <a
              className="btn btn-default closeModal-No margin-right-10"
              onClick={this.onClickNoAlert}>
              No
            </a>
            <a
              className="btn btn-primary closeModal-Yes"
              onClick={this.onClickYesAlert}>
              Yes
            </a>
            {/*eslint-enable*/}
          </div>
        </div>
      </div>
    );
  }

  onClickNoAlertDeleteTemplate = () => {
    $(
      ".modalAlertOverlay.deleteTemplateModal,.modalAlert.deleteTemplateAlert"
    ).hide();
  };

  onClickYesAlertDeleteTemplate = () => {
    callDeleteContentTemplateById(this.state.selectedDeleteTemplate._id).then(
      (d) => {
        $(
          ".modalAlertOverlay.deleteTemplateModal,.modalAlert.deleteTemplateAlert"
        ).fadeOut(200);
        // this.props.getContentTemplate();
        this.getContentTemplates();
      }
    );
  };

  renderDeleteTemplateConfirmationAlert() {
    let selectedDeleteTemplate = this.state.selectedDeleteTemplate;
    let name = "";
    if (selectedDeleteTemplate.template_name) {
      name = selectedDeleteTemplate.template_name;
    }
    return (
      <div>
        <div className="modalAlertOverlay deleteTemplateModal" />
        <div className="modalAlert deleteTemplateAlert">
          <p>{`Are you sure you want to delete "${name}" template?`}</p>
          <div className="alertFooterBtns">
            <button
              className="btn btn-default closeModal-No margin-right-10"
              onClick={this.onClickNoAlertDeleteTemplate}>
              No
            </button>
            <button
              className="btn btn-primary closeModal-Yes"
              onClick={this.onClickYesAlertDeleteTemplate}>
              Yes
            </button>
          </div>
        </div>
      </div>
    );
  }

  onClickNoAlertEditTemplate = () => {
    $(
      ".modalAlertOverlay.editTemplateModal,.modalAlert.editTemplateAlert"
    ).hide();
  };

  onClickYesAlertEditTemplate = () => {
    /*eslint-disable*/
    const updateData = {
      template_name: this.state.templateName,
      template_sort: this.state.templateSortPriority,
    };
    /*eslint-enable*/
    callUpdateContentTemplate(
      this.state.selectedDeleteTemplate._id,
      updateData
    ).then((d) => {
      $(
        ".modalAlertOverlay.editTemplateModal,.modalAlert.editTemplateAlert"
      ).fadeOut(200);
      showNotification("success", "Template name updated!");
      this.getContentTemplates();
    });
  };

  renderEditTemplateConfirmationAlert() {
    return (
      <div>
        <div className="modalAlertOverlay editTemplateModal" />
        <div className="modalAlert editTemplateAlert">
          <p>Edit Template</p>
          <div className="container-fluid">
            <div
              className={
                this.state.templateName !== ""
                  ? "mdInput mdFocussed"
                  : "mdInput"
              }>
              <label>Template Name</label>
              <input
                type="text"
                name="templateName"
                value={this.state.templateName}
                onChange={(event) => this.handleInputChange(event)}
              />
            </div>
            <div className="gap20"></div>
            <div
              className={
                this.state.templateSortPriority !== ""
                  ? "mdInput mdFocussed"
                  : "mdInput"
              }>
              <label>Sort Priority</label>
              <input
                type="number"
                name="templateSortPriority"
                value={this.state.templateSortPriority}
                onChange={(event) => this.handleInputChange(event)}
              />
            </div>
          </div>
          <div className="alertFooterBtns">
            <button
              className="btn btn-default closeModal-No margin-right-10"
              onClick={this.onClickNoAlertEditTemplate}>
              Cancel
            </button>
            <button
              className="btn btn-primary closeModal-Yes"
              onClick={this.onClickYesAlertEditTemplate}>
              Save
            </button>
          </div>
        </div>
      </div>
    );
  }

  onClickNoAlert = () => {
    $(
      ".modalAlertOverlay.closeEditorModal,.modalAlert.closeEditorAlert"
    ).hide();
  };

  onClickYesAlert = () => {
    $(
      ".modalAlertOverlay.closeEditorModal,.modalAlert.closeEditorAlert"
    ).hide();
    closeContentEditor();
    clearInterval(this.draftInterval);
    this.setState({
      editorChange: false,
      keyRadio: Math.random(),
    });
    this.props.onCloseEditor();
  };

  onClickSave = () => {
    setStorageItem("saveEditor", "no");

    let editorType = this.state.editorType;

    if (editorType === "unlayer") {
      this.editor.exportHtml((data) => {
        const { html } = data;
        this.editor.saveDesign((design) => {
          this.callCreateEditorDraftAPI(html, design, 1, "unlayer", "save");
        });
      });
    }
  };

  async callCreateEditorDraftAPI(
    html,
    design,
    option,
    type,
    from = "interval"
  ) {
    /*eslint-disable*/
    let data = {
      member_id: this.state.userData._id ? this.state.userData._id : "",
      title: this.props.title ? this.props.title : "",
      screen: this.props.screen,
      html: html,
      editor_content: design,
      content_type: type,
      auto_save: from === "interval",
    };
    /*eslint-enable*/

    if (option === 2) {
      /*eslint-disable*/
      let existingDrafts = await callGetEditorDrafts({
        query: {
          title: this.props.title,
          screen: this.props.screen,
          auto_save: true,
        },
      });
      /*eslint-enable*/
      if (existingDrafts.data.length > 0) {
        let data = existingDrafts.data;
        for (let i = 0; i < data.length; i++) {
          const element = data[i];
          let diff = moment(element.createdAt).diff(
            moment().subtract(6, "minutes")
          );
          if (diff > -1) {
            return;
          }
        }
      }
    }

    if (this.countDraft > 0) {
      return;
    }
    this.countDraft++;
    let d = await callCreateEditorDraft(data);
    this.countDraft = 0;
    if (d._id) {
      if (option === 1) {
        clearInterval(this.draftInterval);
        this.setState({
          editorContent: {},
        });

        let draftImage = "";

        this.props.onChangeTemplate(html, design, type, d._id, draftImage);
      }

      if (option === 3) {
        let key = getItem("sk");
        window.open(
          `https://site.memberpages.app/mp-preview?key=${key}&t=${d._id}`,
          "__blank"
        );
      }
    }
  }

  getDataFromEditor() {
    this.setState({
      selectedTab: "saveAs",
    });

    if (this.state.editorType === "unlayer") {
      this.editor.exportHtml((data) => {
        const { html } = data;
        this.setState({ contentHtml: html });
      });

      this.editor.saveDesign((design) => {
        this.setState({ contentJson: design });
      });
    }
  }

  onClickClose = () => {
    if (this.state.selectedTab === "editor") {
      $(
        ".modalAlertOverlay.closeEditorModal,.modalAlert.closeEditorAlert"
      ).show();
    } else {
      this.setState({ selectedTab: "editor" });
    }
  };

  onChangeTags = (tags) => {
    if (tags && tags.length > 0) {
      let fTags = { new: [], existing: [] };
      tags.forEach((element) => {
        if (element.name) {
          fTags.existing.push(element);
        } else {
          fTags.new.push(element);
        }
      });
      this.setState({ fTags: fTags });
    }
  };

  async onPressSaveTemplate() {
    let id = this.state.selectedTemplate;
    if (id === "0") {
      if (this.state.templateName.trim().length === 0) {
        showNotification("error", "Template Name is required", 4000);
        return;
      }
    }

    if (isTemplateSite()) {
      let tags = this.state.fTags;
      if (tags.new.length > 0) {
        let data = [];
        let selectedCategory = this.state.selectedCategory;
        let tagType = "content-template";
        if (selectedCategory === "email") {
          tagType = "content-template-email";
        }
        tags.new.forEach((element) => {
          data.push({ name: element, type: tagType });
        });
        callCreateGenericTag(data).then((resp) => {
          let eTags = this.state.tags;
          this.setState({
            tags: eTags.concat(resp),
          });
          let newTagIds = resp.map((t) => t._id);
          let tagIds = tags.existing.map((t) => t._id);
          let finalTagIds = newTagIds.concat(tagIds);

          this.callAddContentTemplate(finalTagIds);
        });
      } else {
        let tagIds = tags.existing.map((t) => t._id);
        this.callAddContentTemplate(tagIds);
      }
    } else {
      let yourSavedTags = this.state.tags.filter(
        (d) => d.name === "Your Saved Templates"
      );
      let tagId = "";
      if (yourSavedTags.length > 0) {
        tagId = yourSavedTags[0]._id;
      } else {
        let tagInfo = await callCreateGenericTag({
          name: "Your Saved Templates",
          type:
            this.state.selectedCategory === "email"
              ? "content-template-email"
              : "content-template",
        });
        this.setState({
          tags: this.state.tags.concat(tagInfo),
        });
        tagId = tagInfo._id;
      }
      this.callAddContentTemplate([tagId]);
    }
  }

  callAddContentTemplate(tags) {
    let html = this.state.contentHtml;
    let editorContent = this.state.contentJson;

    let apiData = {
      /*eslint-disable */
      category: this.state.selectedCategory,
      html: html,
      editor_content: editorContent,
      content_type: this.state.editorType,
      /*eslint-enable*/
      tags: tags,
      type: isTemplateSite() ? "pre-made" : "yours",
    };
    let id = this.state.selectedTemplate;

    if (id !== "0") {
      callUpdateContentTemplate(id, apiData).then((d) => {
        this.handleAPIResponse(d, 1);
      });
    } else {
      apiData["template_name"] = this.state.templateName;
      callCreateContentTemplate(apiData).then((d) => {
        this.handleAPIResponse(d, 2);
      });
    }
  }

  handleAPIResponse(d, option) {
    if (d._id) {
      showNotification(
        "success",
        option === 2
          ? config.messages.createChlgQueSuccess
          : config.messages.updateMessage,
        5000
      );
      if (this.state.selectedTemplate === "0") {
        this.resetProps();
      }
      if (this.state.editorType === "mp") {
        this.setState({ showSaveAsTemplate: false, hideOtherTabs: false });
      }
      this.onClickTemplates();
    } else {
      showNotification("error", config.messages.createChlgQueError, 5000);
    }
  }

  onPressCancelSaveTemplate() {
    if (this.state.editorType === "mp") {
      this.setState({ showSaveAsTemplate: false, hideOtherTabs: false });
    }
    this.onClickEditor();
  }

  getFormattedTags(tags) {
    if (tags && tags.length > 0) {
      let ftags = tags.map((a) => a.name);
      return ftags.join(", ");
    } else {
      return "";
    }
  }

  loadEditorData(editorContent, editorType) {
    this.setState({ editorType: editorType, keyRadio: Math.random() }, () => {
      if (editorType === "unlayer") {
        if (this.editor !== undefined && this.editor !== null) {
          this.editor.loadDesign(editorContent);
        }
      } else {
        editorContent = processEditorContent(
          editorContent,
          this.state.challengeInfo
        );
        this.setState({
          editorContent: editorContent,
          keyEditor: Math.random(),
        });
      }
    });
  }

  onClickInsertFromTemplate = (template) => {
    this.onClickEditFromTemplate(template);
  };

  onClickSetDefaultTemplate = async (template) => {
    let templateId = template._id;

    let apiData = {
      action: "get-editor-settings",
      data: {
        type: "normal",
      },
    };

    let response = await callResendEmail(apiData);
    let defaultTemplates = response.defaultTemplates;

    let templateChallengeContent =
      defaultTemplates.challengeContent === templateId;
    let templateCourseItem = defaultTemplates.courseItem === templateId;
    let templateSalesPage = defaultTemplates.salesPage === templateId;
    let templateOptinPage = defaultTemplates.optinPage === templateId;
    let templateThankYouPage = defaultTemplates.thankYouPage === templateId;
    let templateEventsPage = defaultTemplates.eventsPage === templateId;
    let templateQuizesPage = defaultTemplates.quizesPage === templateId;
    let templateFormsPage = defaultTemplates.formsPage === templateId;
    let templatKnowledgebaseArticle =
      defaultTemplates.knowledgebaseArticle === templateId;

    this.setState(
      {
        selectedTemplate: template,
        defaultTemplates,
        templateChallengeContent,
        templateCourseItem,
        templateSalesPage,
        templateOptinPage,
        templateThankYouPage,
        templateEventsPage,
        templateQuizesPage,
        templateFormsPage,
        templatKnowledgebaseArticle,
        keyTemplateOption1: Math.random(),
        keyTemplateOption2: Math.random(),
        keyTemplateOption3: Math.random(),
        keyTemplateOption4: Math.random(),
        keyTemplateOption5: Math.random(),
        keyTemplateOption6: Math.random(),
        keyTemplateOption7: Math.random(),
        keyTemplateOption8: Math.random(),
        keyTemplateOption9: Math.random(),
      },
      () => {
        $(
          ".modalAlertOverlay.defaultTemplateModal,.modalAlert.defaultTemplateAlert"
        ).fadeIn(200);
      }
    );
  };

  onClickEditFromTemplate = async (template) => {
    this.setState({
      templateName: template.template_name,
      selectedTemplate: template._id,
      selectedCategory: template.category,
      selectedTags: template.ftags || [],
      keyTag: Math.random(),
    });

    this.onChangeTags(template.ftags);
    // this.onClickCategoryOption(template.category);

    let editorType = template.content_type || "unlayer";
    let templateInfo = template;
    if (!template.html) {
      templateInfo = await callGetContentTemplateById(template._id);
    }
    this.loadEditorData(templateInfo.editor_content, editorType);
    this.onClickEditor();
  };

  async onRestoreDesign(template) {
    let editorType = template.content_type || "unlayer";
    // let templateInfo = await callGetContentTemplateById(template._id);
    this.loadEditorData(template.editor_content, editorType);
    this.onClickEditor();
  }

  renderTemplatesForSelection() {
    var returnVals = [];
    let items = this.state.contentTemplates;
    returnVals.push(
      <option value={"0"} key={"Create New Template"}>
        Create New Template
      </option>
    );

    for (let i = 0; i < items.length; i++) {
      const element = items[i];
      if (element.content_type !== this.state.editorType) {
        continue;
      }
      if (isTemplateSite() && element.type === "pre-made") {
        returnVals.push(
          <option value={element._id} key={i}>
            {element.template_name}
          </option>
        );
      } else if (element.type === "yours") {
        returnVals.push(
          <option value={element._id} key={i}>
            {element.template_name}
          </option>
        );
      }
    }
    return returnVals;
  }

  filterUpdated(updatedFilter, name, type, searchFields) {
    let filterList = this.state.filterList;
    filterList[name] = {
      name: name,
      type: type,
      searchFields: searchFields,
      values: updatedFilter,
    };
    let categories = filterList.categories ? filterList.categories.values : [];
    let fCategory = categories.find((d) => d.selected);
    if (fCategory) {
      this.setState({ filterCategory: fCategory.value });
    }
    this.setState(
      {
        filterList: filterList,
      },
      () => {
        let data = filterByTags(
          this.state.contentTemplatesOriginal,
          filterList
        );
        this.setState({
          contentTemplates: data,
        });
      }
    );
  }

  onClickDeleteTemplate(element) {
    this.setState({ selectedDeleteTemplate: element }, () => {
      $(
        ".modalAlertOverlay.deleteTemplateModal,.modalAlert.deleteTemplateAlert"
      ).fadeIn(200);
    });
  }

  onClickEditTemplate(element) {
    this.setState(
      {
        selectedDeleteTemplate: element,
        templateName: element.template_name,
        templateSortPriority: element.template_sort || 0,
      },
      () => {
        $(
          ".modalAlertOverlay.editTemplateModal,.modalAlert.editTemplateAlert"
        ).fadeIn(200);
      }
    );
  }

  onClickImage(element) {
    if (element.full_image) {
      this.setState({ selectedTemplateImage: element }, () => {
        $(
          ".modalAlertOverlay.fullTemplateModal,.modalAlert.fullTemplateAlert"
        ).fadeIn(200);
      });
    }
  }

  onClickYesFromDefaultTemplateAlert = async () => {
    $(
      ".modalAlertOverlay.defaultTemplateModal,.modalAlert.defaultTemplateAlert"
    ).fadeOut(200);
    let templateId = this.state.selectedTemplate._id;

    let defaultTemplates = this.state.defaultTemplates;

    if (this.state.templateChallengeContent) {
      defaultTemplates.challengeContent = templateId;
    }
    if (this.state.templateCourseItem) {
      defaultTemplates.courseItem = templateId;
    }
    if (this.state.templateSalesPage) {
      defaultTemplates.salesPage = templateId;
    }
    if (this.state.templateOptinPage) {
      defaultTemplates.optinPage = templateId;
    }
    if (this.state.templateThankYouPage) {
      defaultTemplates.thankYouPage = templateId;
    }
    if (this.state.templateEventsPage) {
      defaultTemplates.eventsPage = templateId;
    }
    if (this.state.templateQuizesPage) {
      defaultTemplates.quizesPage = templateId;
    }
    if (this.state.templateFormsPage) {
      defaultTemplates.formsPage = templateId;
    }
    if (this.state.templatKnowledgebaseArticle) {
      defaultTemplates.knowledgebaseArticle = templateId;
    }

    let apiData = {
      action: "save-editor-settings",
      data: {
        defaultTemplates: defaultTemplates,
      },
    };

    let response = await callResendEmail(apiData);
    console.log(response);
    showNotification("success", "Default templates updated");
  };

  onClickNoFromDefaultTemplateAlert = () => {
    $(
      ".modalAlertOverlay.defaultTemplateModal,.modalAlert.defaultTemplateAlert"
    ).fadeOut(200);
  };

  toggleTemplateOption = (id, check) => {
    this.setState({
      [id]: check,
    });
  };

  renderDefaultTemplateAlert() {
    let modalHeight = window.innerHeight - 250;
    return (
      <div>
        <div
          className="modalAlertOverlay defaultTemplateModal"
          onClick={this.onClickNoFromDefaultTemplateAlert}
        />
        <div className="modalAlert defaultTemplateAlert">
          <p>Set as Default Template</p>
          <Scrollbars
            autoHide
            autoHideTimeout={1000}
            autoHideDuration={200}
            style={{ height: modalHeight }}>
            <div className="container-fluid">
              <label>
                Where would you like to use this as the default template?
              </label>
              <br />
              <br />
              <label>Courses: </label>
              <Checkbox
                label={"Challenge Content"}
                isChecked={this.state.templateChallengeContent}
                handleCheckboxChange={this.toggleTemplateOption.bind(
                  this,
                  "templateChallengeContent"
                )}
                key={this.state.keyTemplateOption1}
              />
              <div className="gap10"></div>
              <Checkbox
                label={"Course Item"}
                isChecked={this.state.templateCourseItem}
                handleCheckboxChange={this.toggleTemplateOption.bind(
                  this,
                  "templateCourseItem"
                )}
                key={this.state.keyTemplateOption2}
              />

              <div className="gap20"></div>
              <label>Landing Pages</label>
              <Checkbox
                label={"Sales Page"}
                isChecked={this.state.templateSalesPage}
                handleCheckboxChange={this.toggleTemplateOption.bind(
                  this,
                  "templateSalesPage"
                )}
                key={this.state.keyTemplateOption3}
              />
              <div className="gap10"></div>
              <Checkbox
                label={"Optin Page"}
                isChecked={this.state.templateOptinPage}
                handleCheckboxChange={this.toggleTemplateOption.bind(
                  this,
                  "templateOptinPage"
                )}
                key={this.state.keyTemplateOption4}
              />
              <div className="gap10"></div>
              <Checkbox
                label={"Thank You Page"}
                isChecked={this.state.templateThankYouPage}
                handleCheckboxChange={this.toggleTemplateOption.bind(
                  this,
                  "templateThankYouPage"
                )}
                key={this.state.keyTemplateOption5}
              />
              <div className="gap20"></div>
              <label>Others</label>
              <Checkbox
                label={"Events Page"}
                isChecked={this.state.templateEventsPage}
                handleCheckboxChange={this.toggleTemplateOption.bind(
                  this,
                  "templateEventsPage"
                )}
                key={this.state.keyTemplateOption6}
              />
              <div className="gap10"></div>
              <Checkbox
                label={"Quizes Page"}
                isChecked={this.state.templateQuizesPage}
                handleCheckboxChange={this.toggleTemplateOption.bind(
                  this,
                  "templateQuizesPage"
                )}
                key={this.state.keyTemplateOption7}
              />
              <div className="gap10"></div>
              <Checkbox
                label={"Forms Page"}
                isChecked={this.state.templateFormsPage}
                handleCheckboxChange={this.toggleTemplateOption.bind(
                  this,
                  "templateFormsPage"
                )}
                key={this.state.keyTemplateOption8}
              />
              <div className="gap10"></div>
              <Checkbox
                label={"Knowledgebase Article"}
                isChecked={this.state.templatKnowledgebaseArticle}
                handleCheckboxChange={this.toggleTemplateOption.bind(
                  this,
                  "templatKnowledgebaseArticle"
                )}
                key={this.state.keyTemplateOption9}
              />
              <div className="gap30"></div>
              <label>
                Note: Please note if you select any of the items above, this
                will become the default template used when creating that item.
              </label>
            </div>
          </Scrollbars>
          <div className="alertFooterBtns">
            <button
              className="btn btn-default closeModal-No margin-right-10"
              onClick={this.onClickNoFromDefaultTemplateAlert}>
              Cancel
            </button>
            <button
              className="btn btn-primary closeModal-Yes"
              onClick={this.onClickYesFromDefaultTemplateAlert}>
              Save
            </button>
          </div>
        </div>
      </div>
    );
  }

  onClickYesFromTemplateAlert = () => {
    this.onClickEditFromTemplate(this.state.selectedTemplateImage);
    $(
      ".modalAlertOverlay.fullTemplateModal,.modalAlert.fullTemplateAlert"
    ).fadeOut(200);
  };

  onClickNoFromTemplateAlert = () => {
    $(
      ".modalAlertOverlay.fullTemplateModal,.modalAlert.fullTemplateAlert"
    ).fadeOut(200);
  };

  renderFullTemplateAlert() {
    let template = this.state.selectedTemplateImage;
    let modalHeight = window.innerHeight - 250;
    return (
      <div>
        <div
          className="modalAlertOverlay fullTemplateModal"
          onClick={this.onClickNoFromTemplateAlert}
        />
        <div className="modalAlert fullTemplateAlert big">
          <p>Template Preview</p>
          <Scrollbars
            autoHide
            autoHideTimeout={1000}
            autoHideDuration={200}
            style={{ height: modalHeight }}>
            <div className="container-fluid">
              <div
                style={{ paddingTop: 30, paddingBottom: 30 }}
                className="card">
                <img
                  className="imgResponsive"
                  alt={template.name}
                  src={imageUrl(template.full_image)}
                />
              </div>
            </div>
          </Scrollbars>
          <div className="alertFooterBtns">
            <button
              className="btn btn-default closeModal-No margin-right-10"
              onClick={this.onClickNoFromTemplateAlert}>
              Cancel
            </button>
            <button
              className="btn btn-primary closeModal-Yes"
              onClick={this.onClickYesFromTemplateAlert}>
              Insert
            </button>
          </div>
        </div>
      </div>
    );
  }

  async onClickRefreshTemplate(element) {
    let templateInfo = element;
    if (!element.html) {
      templateInfo = await callGetContentTemplateById(element._id);
    }
    let data = await callUpdateContentTemplate(element.id, {
      category: templateInfo.category,
      html: templateInfo.html,
    });
    if (data._id) {
      showNotification("success", "Refreshed", 4000);
      // this.props.getContentTemplate();
      this.getContentTemplates();
    }
  }

  renderTemplates() {
    var returnVals = [];
    let items = this.state.contentTemplates;
    let fItems = items.filter((d) => d.content_type === this.state.editorType);

    fItems.forEach((element) => {
      if (!element.template_sort) {
        element["template_sort"] = 0;
      }
    });
    fItems.sort((a, b) => b.template_sort - a.template_sort);

    for (let i = 0; i < fItems.length; i++) {
      const element = fItems[i];
      const category =
        element.category === "web"
          ? "Web Content"
          : element.category === "popup"
          ? "Popup"
          : "Email";
      returnVals.push(
        <div className="col-md-3" key={element._id}>
          <div className="card">
            <div className="container-fluid">
              <div className="gap20" />
              <h5>{element.template_name}</h5>
              {isTemplateSite() && (
                <div style={{ float: "right" }}>
                  <button
                    onClick={this.onClickRefreshTemplate.bind(this, element)}
                    className="btn btn-default btn-sm margin-right-10 border-0">
                    <i className="fa fa-refresh" />
                  </button>
                </div>
              )}
              <p>
                {category}{" "}
                {(element.type === "yours" || isTemplateSite()) && (
                  <>
                    <button
                      onClick={this.onClickDeleteTemplate.bind(this, element)}
                      className="btn btn-danger btn-sm margin-right-10 border-0">
                      <i className="fa fa-trash" />
                    </button>
                    <button
                      onClick={this.onClickEditTemplate.bind(this, element)}
                      className="btn btn-default btn-sm margin-right-10 border-0">
                      <i className="fa fa-pencil" />
                    </button>
                  </>
                )}
                {isTemplateSite() && (
                  <label
                    title="Sort Priority"
                    style={{ fontSize: 14, color: "grey" }}>
                    {element.template_sort}
                  </label>
                )}
              </p>

              {element.image && (
                <div onClick={this.onClickImage.bind(this, element)}>
                  <div className="gap10" />
                  <img
                    src={templateImageUrl(
                      element.image,
                      element.type,
                      element.updatedAt
                    )}
                    className="btn-block pointer-click editorGalleryImage"
                    alt="ImageUrl"
                  />
                  <div className="gap10" />
                </div>
              )}
              {element.full_image && (
                <button
                  className="btn btn-warning btn-sm btn-block"
                  onClick={this.onClickImage.bind(this, element)}>
                  Preview
                </button>
              )}
              <button
                className="btn btn-primary btn-sm btn-block"
                onClick={this.onClickEditFromTemplate.bind(this, element)}>
                Insert
              </button>
              <button
                className="btn btn-default btn-sm btn-block"
                onClick={this.onClickSetDefaultTemplate.bind(this, element)}>
                Set as Default Template
              </button>
              <div className="gap20" />
            </div>
          </div>
          <div className="gap20" />
        </div>
      );
    }
    return returnVals;
  }

  onSaveMp(html, json) {
    this.callCreateEditorDraftAPI(html, json, 1, "mp", "save");
  }

  onMpPreview(html, json) {
    this.callCreateEditorDraftAPI(html, json, 3, "mp", "preview");
  }

  onSaveMpTemplate(html, json) {
    let selectedTemplate = this.state.selectedTemplate;

    if (!isTemplateSite()) {
      let templates = this.state.contentTemplates.filter(
        (d) => d._id === selectedTemplate && d.type !== "pre-made"
      );

      if (templates.length === 0) {
        selectedTemplate = "0";
      }
    }

    this.setState({
      selectedTab: "saveAs",
      contentHtml: html,
      contentJson: json,
      showSaveAsTemplate: true,
      hideOtherTabs: true,
      selectedTemplate: selectedTemplate,
      keyTag: Math.random(),
    });
  }

  onClickSelectImage(type) {
    this.setState({
      selectedTab: "images",
      imageSelectType: "select",
      imageType: type,
    });
  }

  onClickSelectVideo(type, itemId = "") {
    this.setState({
      selectedTab: "images",
      imageSelectType: "select",
      imageType: type,
      itemId: itemId,
    });
  }

  onSelectImage(image) {
    this.setState({
      selectedTab: "editor",
      imageUrl: imageUrl(image.key),
      imageType: this.state.imageType,
      imageKey: Math.random(),
    });
  }

  onSelectVideo(video) {
    let key = video.key;
    let type = video.type || "youtube";
    let videoUrl = "";

    if (type === "youtube") {
      videoUrl = `https://d38x6s7j8yvy7v.cloudfront.net/${key}`;
    } else {
      key = `${key}/stream-1080p.m3u8`;
      if (video.s_key) {
        key = `${video.s_key}/stream-master-pl.m3u8`;
      }

      videoUrl = `https://d38x6s7j8yvy7v.cloudfront.net/${key}`;
    }
    this.setState({
      selectedTab: "editor",
      videoUrl: videoUrl,
      imageType: this.state.imageType,
      refreshKey: Math.random(),
    });
  }

  onSelectVideoUrl(videoUrl) {
    this.setState({
      selectedTab: "editor",
      videoUrl: videoUrl,
      imageType: this.state.imageType,
      refreshKey: Math.random(),
    });
  }

  renderMpContentEditor() {
    let eMode = getStorageItem("eMode") || "normal";
    return (
      <MPContentEditor
        key={this.state.keyEditor}
        screen={this.props.screen}
        onSave={this.onSaveMp.bind(this)}
        onSaveTemplate={this.onSaveMpTemplate.bind(this)}
        ref={(editor) => (this.mpEditor = editor)}
        json={this.state.editorContent || {}}
        displayMode={this.state.displayMode}
        onClickSelectImage={this.onClickSelectImage.bind(this)}
        onClickSelectVideo={this.onClickSelectVideo.bind(this)}
        imageUrl={this.state.imageUrl}
        videoUrl={this.state.videoUrl}
        imageType={this.state.imageType}
        editorPopups={this.state.editorPopups}
        onPreview={this.onMpPreview.bind(this)}
        editorMode={eMode}
        imageKey={this.state.imageKey}
        refreshKey={this.state.refreshKey}
        itemId={this.state.itemId}
      />
    );
  }

  renderUnlayerEditor() {
    let id = getItem("sk") || this.state.userData._id;
    let spk = getItem("spk") || "";

    let host = window.location.protocol + "//" + window.location.host;

    return (
      <EmailEditor
        minHeight={"800px"}
        onLoad={this.onLoadFinish}
        ref={(editor) => (this.editor = editor)}
        options={{
          customCSS: [
            host + "/custom-tools/css/nano.min.css",
            host + "/custom-tools/css/shared-styles.css",
            host + "/custom-tools/css/form-styles.css",
            "https://use.fontawesome.com/releases/v5.0.7/css/all.css",
            //host + "/custom-tools/css/all.css",
          ],
          customJS: [
            host + "/custom-tools/js/jquery-3.2.1.min.js",
            host + "/custom-tools/js/pickr.min.js",
            host + "/custom-tools/js/pickr.es5.min.js",
            host + "/custom-tools/form-tool.js",
            host + "/custom-tools/js/form-scripts.js",
            host + "/custom-tools/js/modal-scripts.js",
          ],
          projectId: 314,
          displayMode: this.state.displayMode,
          id: id,
          tools: {
            "custom#form_tool": {
              /*eslint-disable*/
              data: {
                stripe_key: spk,
                mpKey: id,
                tagList: "tag1,tag2,tag3",
                redirectUrl: "https://xyz.com",
                enableCheckout: true,
                checkoutId: "test",
              },
              /*eslint-enable*/
              properties: {
                formStyle: {
                  editor: {
                    data: {
                      options: [
                        { label: "Underline", value: "underline" },
                        { label: "Box", value: "box" },
                      ],
                    },
                  },
                },
                buttonStyle: {
                  editor: {
                    data: {
                      options: [
                        { label: "Style 1", value: "style1" },
                        { label: "Style 2", value: "style2" },
                      ],
                    },
                  },
                },
              },
            },
          },
          mergeTags: [
            { name: "Email Address", value: "{{email_address}}" },
            { name: "First Name", value: "{{first_name}}" },
            { name: "Last Name", value: "{{last_name}}" },
            {
              name: "Initial Password",
              value: "{{initial_password}}",
            },
            {
              name: "Reset Password Link",
              value: "{{reset_password_link}}",
            },
            {
              name: "Unsubscribe Email Link",
              value: "{{unsubscribe_link}}",
            },
            {
              name: "Company Name",
              value: "{{company_name}}",
            },
            {
              name: "Login URL",
              value: "{{login_url}}",
            },
            {
              name: "URL",
              value: "{{url}}",
            },
            {
              name: "Current Year",
              value: "{{current_year}}",
            },
          ],
        }}
      />
    );
  }

  renderTemplatesTab() {
    return (
      <div className="container-fluid">
        <h3 className="noMargin">Templates</h3>
        <div className="gap20" />
        <div className="card">
          <div className="list-group list-group-flush">
            <div className="list-group-item">
              <FilterList
                label={"Search Templates"}
                name={"search"}
                type={"search"}
                searchFields={["template_name"]}
                onFinish={this.filterUpdated.bind(this)}
              />
            </div>
            {!isTemplateSite() && (
              <div className="list-group-item">
                <p>Select A Type</p>
                <FilterList
                  filterList={this.state.types}
                  name={"types"}
                  type={"single"}
                  searchFields={["type"]}
                  onFinish={this.filterUpdated.bind(this)}
                />
              </div>
            )}
            <div className="list-group-item">
              <p>Select A Category</p>
              <FilterList
                filterList={this.state.categories}
                name={"categories"}
                type={"single"}
                searchFields={["category"]}
                onFinish={this.filterUpdated.bind(this)}
              />
            </div>
            <div className="list-group-item">
              <p>Filter by tags</p>
              <FilterList
                key={this.state.keyFilterTag && this.state.filterCategory}
                filterList={
                  this.state.filterCategory === "email"
                    ? this.state.backupFilterTags
                    : this.state.filterTags
                }
                name={"filterTags"}
                type={"single"}
                searchFields={["ftags->name"]}
                onFinish={this.filterUpdated.bind(this)}
              />
            </div>
            <div className="list-group-item mpTemplatesBackground">
              <div className="row">
                {!this.state.isTemplatesLoading ? (
                  this.renderTemplates()
                ) : (
                  <div id="loader"></div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderSaveAsTemplateTab() {
    return (
      <div className="container-fluid">
        <h3 className="noMargin">Save As Template</h3>
        <div className="gap20" />
        <div className="card">
          <div className="list-group list-group-flush">
            <div className="list-group-item">
              <div className="mdInput mdFocussed">
                <label>Select Template</label>
                <select
                  key={this.state.keyTag}
                  name="selectedTemplate"
                  value={this.state.selectedTemplate}
                  onChange={(event) => this.handleInputChange(event)}>
                  {this.renderTemplatesForSelection()}
                </select>
              </div>
              {this.state.selectedTemplate === "0" && (
                <>
                  <div className="gap10" />
                  <div
                    className={
                      this.state.templateName !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>Template Name</label>
                    <input
                      type="text"
                      name="templateName"
                      value={this.state.templateName}
                      onChange={(event) => this.handleInputChange(event)}
                    />
                  </div>
                </>
              )}
              <div className="gap20" />
            </div>
            {isTemplateSite() && (
              <div className="list-group-item">
                <GenericTagSelect
                  id={"content-editor-select-group"}
                  key={this.state.keyTag && this.state.selectedCategory}
                  selectedValues={this.state.selectedTags}
                  searchKey={"name"}
                  selectKey={"name"}
                  lblText={"Select a group"}
                  data={this.state.tags}
                  onChange={this.onChangeTags}
                  addTag={this.addEmailContentTag}
                  isClickable={true}
                  btnText={"Add Group"}
                  popupHeaderText={"Create a new group"}
                  popupGenericType={"content-template"}
                  popupHeaderFFEnableName={true}
                  popupHeaderFFEnableDescription={true}
                  popupHeaderFFEnableTags={true}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  addEmailContentTag = (add) => {
    this.state.tags.push(add);
    this.setState({
      fTags: {
        existing: this.state.tags,
      },
    });
  };

  render() {
    // if (this.state.filterTags.length <= 0) {
    //   return null;
    // }
    let className = "fullModal contentEditor";
    if (this.props.modalType) {
      className = `fullModal contentEditor ${this.props.modalType}`;
    }

    return (
      <div className={className}>
        {this.renderCloseAlert()}
        {this.renderDeleteTemplateConfirmationAlert()}
        {this.renderFullTemplateAlert()}
        {this.renderDefaultTemplateAlert()}
        {this.renderEditTemplateConfirmationAlert()}
        <div className="fmInner">
          <div className="fmHeader">
            <div className="modalHeaderTabs">
              {/*eslint-disable*/}
              {!this.state.hideOtherTabs && (
                <>
                  <a
                    className={
                      this.state.selectedTab === "editor" ? "active" : ""
                    }
                    onClick={() => this.onClickEditor()}
                    data-for=".for-tab1">
                    Editor
                  </a>
                  <a
                    className={
                      this.state.selectedTab === "templates" ? "active" : ""
                    }
                    data-for=".for-tab2"
                    onClick={() => this.onClickTemplates()}>
                    Templates
                  </a>
                </>
              )}
              {this.state.showSaveAsTemplate && (
                <a
                  className={
                    this.state.selectedTab === "saveAs" ? "active" : ""
                  }
                  data-for=".for-tab3"
                  onClick={() => this.getDataFromEditor()}>
                  Save As Template
                </a>
              )}
              {!this.state.hideOtherTabs && (
                <>
                  <a
                    className={
                      this.state.selectedTab === "links" ? "active" : ""
                    }
                    data-for=".for-tab4"
                    onClick={() => this.onClickLinks()}>
                    Links
                  </a>
                  <a
                    className={
                      this.state.selectedTab === "drafts" ? "active" : ""
                    }
                    data-for=".for-tab5"
                    onClick={() => this.onClickDrafts()}>
                    Drafts
                  </a>
                  <a
                    className={
                      this.state.selectedTab === "images" ? "active" : ""
                    }
                    data-for=".for-tab6"
                    onClick={() => this.onClickImages()}>
                    Images & Files
                  </a>
                  {/* <a
                    className={
                      this.state.selectedTab === "snippets" ? "active" : ""
                    }
                    data-for=".for-tab7"
                    onClick={() => this.onClickHtmlSnippets()}>
                    HTML Snippets
                  </a> */}
                </>
              )}
            </div>
            <a className="closeFullModal" onClick={this.onClickClose}>
              {this.state.selectedTab === "editor" ? (
                <span className="icon-cross" />
              ) : (
                <span className="icon-arrow-left" />
              )}
            </a>
            {/*eslint-disable*/}
          </div>
          <div className="fmBody">
            <div className="fmBodyInner">
              <div className="modTabContent">
                <div
                  className={
                    this.state.selectedTab === "editor"
                      ? "active for-tab1 tabHasFooter"
                      : "for-tab1 tabHasFooter"
                  }>
                  {this.state.editorType === "unlayer" &&
                    this.renderUnlayerEditor()}
                  {this.state.editorType === "unlayer" && (
                    <>
                      <div className="gap50" />
                      <div className="gap20" />
                    </>
                  )}
                  {this.state.editorType === "mp" &&
                    this.renderMpContentEditor()}

                  {this.state.editorType === "unlayer" && (
                    <div className="fmFooter">
                      <div className="clearfix">
                        <div className="pull-right">
                          <button
                            className="btn btn-primary"
                            onClick={this.onClickSave}>
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className={
                    this.state.selectedTab === "templates"
                      ? "active for-tab2"
                      : "for-tab2"
                  }>
                  <div className="gap20" />
                  {this.renderTemplatesTab()}
                </div>
                <div
                  className={
                    this.state.selectedTab === "saveAs"
                      ? "active for-tab3 tabHasfooter"
                      : "for-tab3 tabHasFooter"
                  }>
                  <div className="gap20" />
                  {this.renderSaveAsTemplateTab()}
                  <div className="fmFooter">
                    <div className="text-right">
                      <button
                        className=" btn btn-default btn-sm margin-right-10"
                        onClick={() => this.onPressCancelSaveTemplate()}>
                        Cancel
                      </button>
                      <button
                        className="btn btn-primary"
                        onClick={() => this.onPressSaveTemplate()}>
                        Save Template
                      </button>
                    </div>
                  </div>
                </div>
                {this.state.selectedTab === "links" && (
                  <div
                    className={
                      this.state.selectedTab === "links"
                        ? "active for-tab4"
                        : "for-tab4"
                    }>
                    <div className="gap20" />
                    <AddEmailLink />
                  </div>
                )}
                {this.state.selectedTab === "drafts" && (
                  <div
                    className={
                      this.state.selectedTab === "drafts"
                        ? "active for-tab5"
                        : "for-tab5"
                    }>
                    <div className="gap20" />
                    <EditorDraft
                      screen={this.props.screen}
                      editorType={this.state.editorType}
                      onRestoreDesign={this.onRestoreDesign.bind(this)}
                    />
                  </div>
                )}
                {this.state.selectedTab === "images" && (
                  <div
                    className={
                      this.state.selectedTab === "images"
                        ? "active for-tab6"
                        : "for-tab6"
                    }>
                    <div className="gap20" />
                    <EditorImages
                      contentType={
                        this.state.imageType === "imageUrl" ||
                        this.state.imageType === "item" ||
                        this.state.imageType === "thumbUrl" ||
                        this.state.imageType === "page" ||
                        this.state.imageType === "sliderImageUrl"
                          ? "image"
                          : this.state.imageType === "videoUrl"
                          ? "video"
                          : "file"
                      }
                      type={this.state.imageSelectType}
                      onSelectImage={this.onSelectImage.bind(this)}
                      onSelectVideo={this.onSelectVideo.bind(this)}
                      onSelectFile={this.onSelectImage.bind(this)}
                      onSelectVideoUrl={this.onSelectVideoUrl.bind(this)}
                    />
                  </div>
                )}
                {this.state.selectedTab === "snippets" && (
                  <div
                    className={
                      this.state.selectedTab === "snippets"
                        ? "active for-tab7"
                        : "for-tab7"
                    }>
                    <div className="gap20" />
                    <HtmlSnippets />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  genericTags: state.tagReducer.genericTags,
  contentTemplates: state.articleReducer.contentTemplates,
  userData: state.authReducer.userData,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      resetStatus,
      getContentTemplate,
      getGenericTags,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ContentEditor);
