import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import $ from "jquery";

import FilterList from "../../../components/form/filterList";
import TitleBox from "../../../components/general/titleBox";
import TableLoader from "../../../components/loader/table";

import { filterByTags } from "../../../helpers/filters";
import {
  callGetMarciCircleUsingId,
  callDeleteMarciCircleUsingId,
  callGetMarciCircles,
  callCreateMarciCircle,
} from "../../../services";
import { showNotification } from "../../../helpers";
import SortItem from "../../../components/form/sortItem";
import { getUpdatedEditorContent } from "../../../components/editor/processEditor";

class ViewNewsletters extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      marciCircles: [],
      marciCirclesBackup: [],
      filterList: [],
      tags: [],
      dateFilters: [],
      selectedMarciCircle: {},
      newsletterName: "",
      keySort: Math.random(),
      keyFilter: Math.random(),
      showLoader: true,
    };
  }

  componentDidMount() {
    this.getMarciCircles();
  }

  getMarciCircles() {
    /*eslint-disable*/
    let query = {
      query: {
        $sort: { release_date: -1 },
      },
    };
    /*eslint-disable*/
    callGetMarciCircles(query).then((d) => {
      this.setState({
        marciCircles: d.data,
        marciCirclesBackup: d.data,
        showLoader: false,
      });
      this.prepareDataForFilter(d.data);
    });
  }

  componentWillReceiveProps(newProps) {
    if (newProps.marciCircles.data) {
      this.setState({
        marciCircles: newProps.marciCircles.data,
        marciCirclesBackup: newProps.marciCircles.data,
        showLoader: false,
      });
      this.prepareDataForFilter(newProps.marciCircles.data);
    }
  }

  prepareDataForFilter(data) {
    let tags = [{ name: "All", value: "all", selected: true }];

    data.forEach((element) => {
      let tag = element.ftags || [];
      tag.forEach((el) => {
        let arrTag = tags.filter((d) => {
          return d.name.toLowerCase() === el.name.toLowerCase();
        });
        if (arrTag.length === 0) {
          tags.push({
            name: el.name.charAt(0).toUpperCase() + el.name.slice(1),
            value: el.name.toLowerCase(),
            selected: false,
          });
        }
      });
    });

    let dateFilters = [
      {
        name: "All",
        value: "all",
        type: "selected",
        selected: true,
      },
      {
        name: "Last 7 Days",
        value: "-days-7",
        type: "selected",
        selected: false,
      },
      {
        name: "Last 30 Days",
        value: "-days-30",
        type: "selected",
        selected: false,
      },
    ];

    this.setState({
      tags: tags,
      dateFilters: dateFilters,
      keyFilter: Math.random(),
      keySort: Math.random(),
    });
  }

  formatDate(date) {
    if (date === "") {
      return "";
    }
    return moment(date).format("MMMM Do, YYYY");
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  onClickEditNewsletter = (newsLetter) => {
    callGetMarciCircleUsingId(newsLetter._id).then((d) => {
      if (d._id) {
        this.props.history.push("/admin/add-newsletter", {
          newsLetter: d,
        });
      }
    });
  };

  onClickDeleteNewsletter = (newsLetter) => {
    this.setState({ selectedMarciCircle: newsLetter }, () => {
      $(".modalAlertOverlay.deleteModalOverlay,.modalAlert.deleteAlert").fadeIn(
        200
      );
    });
  };

  onClickCloneNewsletter = (newsLetter) => {
    callGetMarciCircleUsingId(newsLetter._id).then((d) => {
      if (d._id) {
        this.setState({ selectedMarciCircle: d }, () => {
          $(
            ".modalAlertOverlay.newsletterModalOverlay,.modalAlert.newsletterModalAlert"
          ).fadeIn(200);
        });
      }
    });
  };

  filterUpdated(updatedFilter, name, type, searchFields) {
    let filterList = this.state.filterList;
    filterList[name] = {
      name: name,
      type: type,
      searchFields: searchFields,
      values: updatedFilter,
    };
    this.setState(
      {
        filterList: filterList,
      },
      () => {
        let data = filterByTags(this.state.marciCirclesBackup, filterList);
        this.setState({
          marciCircles: data,
          keySort: Math.random(),
        });
      }
    );
  }

  getFormattedTags(tags) {
    if (tags.length > 0) {
      let ftags = tags.map((a) => a.name);
      return ftags.join(", ");
    } else {
      return "";
    }
  }

  renderCircles() {
    var returnVals = [];
    let items = this.state.marciCircles;
    items.forEach((element) => {
      returnVals.push(
        <tr key={element._id}>
          <td>{element.name}</td>
          <td>{element.description}</td>
          <td>{this.formatDate(element.release_date)}</td>
          <td>{this.getFormattedTags(element.ftags)}</td>
          <td className="min-width-150">
            <a
              className="btn btn-danger btn-sm margin-right-10 border-0"
              onClick={this.onClickDeleteNewsletter.bind(this, element)}>
              <i className="fa fa-trash" />
            </a>
            <a
              className="btn btn-default btn-sm margin-right-10 border-0"
              onClick={this.onClickEditNewsletter.bind(this, element)}>
              <i className="fa fa-pencil" />
            </a>
            <a
              onClick={this.onClickCloneNewsletter.bind(this, element)}
              className="btn btn-default btn-sm border-0">
              <i className="fa fa-clone" />
            </a>
          </td>
        </tr>
      );
    });
    return returnVals;
  }

  onClickCancelFromNewsletterModal() {
    $(
      ".modalAlertOverlay.newsletterModalOverlay,.modalAlert.newsletterModalAlert"
    ).fadeOut(200);
  }

  onClickProcessFromNewsletterModal() {
    if (this.state.newsletterName.length === 0) {
      showNotification("error", "Please enter newsletter name", 4000);
      return;
    }

    let existingNewsletter = this.state.marciCirclesBackup.filter((d) => {
      return (
        d.name.trim().toLowerCase() ===
        this.state.newsletterName.trim().toLowerCase()
      );
    });

    if (existingNewsletter.length > 0) {
      showNotification("error", "Newsletter already exists", 4000);
      return;
    }

    $(
      ".modalAlertOverlay.newsletterModalOverlay,.modalAlert.newsletterModalAlert"
    ).fadeOut(200);

    let selectedNewsletter = this.state.selectedMarciCircle;

    let apiData = {
      name: this.state.newsletterName,
      description: selectedNewsletter.description,
      editor_content: getUpdatedEditorContent(
        selectedNewsletter.editor_content,
        selectedNewsletter.main_content
      ),
      main_content: selectedNewsletter.main_content,
      release_date: selectedNewsletter.release_date,
      generic_tag_list: selectedNewsletter.generic_tag_list,
      newsletter_date: selectedNewsletter.newsletter_date,
      title: selectedNewsletter.title,
      week_no: selectedNewsletter.week_no,
      author: selectedNewsletter.author,
      highlights: selectedNewsletter.highlights,
      status: "inactive",
    };

    callCreateMarciCircle(apiData).then((d) => {
      if (d._id) {
        this.setState({ selectedMarciCircle: {}, newsletterName: "" }, () => {
          showNotification("success", "Newsletter created successfully", 4000);
          this.getMarciCircles();
        });
      } else {
        showNotification("error", "Something went wrong", 4000);
      }
    });
  }

  renderNewNewletterModal() {
    let name = "";
    if (this.state.selectedMarciCircle.name) {
      name = this.state.selectedMarciCircle.name;
    }
    return (
      <div>
        <div className="modalAlertOverlay newsletterModalOverlay" />
        <div className="modalAlert newsletterModalAlert">
          <div className="clearfix">
            <div className="pull-left">
              <p>Clone Newsletter - {name}</p>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div
                  className={
                    this.state.newsletterName !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Newsletter Name</label>
                  <input
                    type="text"
                    name="newsletterName"
                    value={this.state.newsletterName}
                    onChange={(event) => this.handleInputChange(event)}
                  />
                </div>
                <div className="gap10" />
              </div>
            </div>
          </div>
          <div className="gap10" />
          <div className="container-fluid">
            <label style={{ fontSize: 14 }}>
              Cloned newsletter will automatically be set as "Inactive" and will
              not enable until set to "Active"
            </label>
          </div>

          <div className="gap10" />

          <div className="alertFooterBtns">
            <a
              onClick={this.onClickCancelFromNewsletterModal.bind(this)}
              className="btn btn-default dismissThisModal margin-right-10">
              Cancel
            </a>
            <a
              onClick={this.onClickProcessFromNewsletterModal.bind(this)}
              className="btn btn-primary dismissThisModal">
              Create Newsletter
            </a>
          </div>
        </div>
      </div>
    );
  }

  onClickNoAlert = () => {
    $(".modalAlertOverlay.deleteModalOverlay,.modalAlert.deleteAlert").fadeOut(
      200
    );
  };

  onClickYesAlert = () => {
    callDeleteMarciCircleUsingId(this.state.selectedMarciCircle._id).then(
      (d) => {
        $(
          ".modalAlertOverlay.deleteModalOverlay,.modalAlert.deleteAlert"
        ).fadeOut(200);
        this.getMarciCircles();
      }
    );
  };

  renderConfirmationAlert() {
    let selectedMarciCircle = this.state.selectedMarciCircle;
    let name = "";
    if (selectedMarciCircle.name) {
      name = selectedMarciCircle.name;
    }
    return (
      <div>
        <div className="modalAlertOverlay deleteModalOverlay" />
        <div className="modalAlert deleteAlert">
          <p>{`Are you sure you want to delete "${name}" newsletter?`}</p>
          <div className="alertFooterBtns">
            <a
              className="btn btn-default closeModal-No margin-right-10"
              onClick={this.onClickNoAlert}>
              No
            </a>
            <a
              className="btn btn-primary closeModal-Yes"
              onClick={this.onClickYesAlert}>
              Yes
            </a>
          </div>
        </div>
      </div>
    );
  }

  onChangeItems = (items) => {
    this.setState({
      marciCircles: items,
    });
  };

  renderFilterView() {
    return (
      <>
        <div className="list-group-item">
          <FilterList
            label={"Search Newsletters"}
            name={"search"}
            type={"search"}
            searchFields={["name", "description"]}
            onFinish={this.filterUpdated.bind(this)}
          />
        </div>
        <div className="list-group-item">
          <p>Filter by date</p>
          <FilterList
            key={this.state.keyFilter}
            filterList={this.state.dateFilters}
            name={"releaseDates"}
            type={"date"}
            searchFields={["release_date"]}
            onFinish={this.filterUpdated.bind(this)}
          />
        </div>
        <div className="list-group-item">
          <p>Select a tag</p>
          <FilterList
            key={this.state.keyFilter}
            filterList={this.state.tags}
            name={"tags"}
            type={"single"}
            searchFields={["ftags->name"]}
            onFinish={this.filterUpdated.bind(this)}
          />
        </div>
        <div className="list-group-item">
          <SortItem
            key={this.state.keySort}
            items={this.state.marciCircles}
            columns={[
              { label: "Name", value: "name", type: "string" },
              {
                label: "Description",
                value: "description",
                type: "string",
              },
              {
                label: "Release Date",
                value: "release_date",
                type: "date",
              },
            ]}
            onChangeItems={this.onChangeItems}
          />
        </div>
      </>
    );
  }

  render() {
    return (
      <div>
        {this.renderConfirmationAlert()}
        {this.renderNewNewletterModal()}
        <div className="container-fluid">
          <TitleBox title="Newsletters Overview" />
          <div className="gap20" />
          <div className="card">
            <div className="list-group list-group-flush">
              {this.renderFilterView()}
              {!this.state.showLoader && (
                <div className="list-group-item">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Description</th>
                        <th>Release Date</th>
                        <th>Tags</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>{this.renderCircles()}</tbody>
                  </table>
                </div>
              )}
              {this.state.showLoader && (
                <div className="container-fluid">
                  <TableLoader />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  marciCircles: state.marciCircleReducer.marciCircles,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ViewNewsletters);
