import React, { lazy, Suspense } from "react";
import { Route, Redirect, Switch } from "react-router-dom";

import "react-quill/dist/quill.snow.css";
// import "react-select/dist/react-select.css";
import "react-progress-2/main.css";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import $ from "jquery";
import Progress from "react-progress-2";
import ClearCache from "react-clear-cache";

// helpers

import { checkAuth, getStoreSettings } from "./helpers";
import { frontendScreens } from "./helpers/routes";
import { checkPathForSearchBar } from "./helpers/search";

import AsyncComponent from "./components/core/asyncComponent";

import SSOPage from "./views/general/sso";

// admin pages
import AddChallengeQuestion from "./views/admin/challenge/addChallengeQue";
import ViewChallengeQuestion from "./views/admin/challenge/viewChallengeQue";
import ViewArchivedQuestion from "./views/admin/challenge/viewArchivedQue";
import AddChallengeItem from "./views/admin/challenge/addChallengeItem";
import ViewChallengeItem from "./views/admin/challenge/viewChallengeItem";
import AddChallenge from "./views/admin/challenge/addChallenge";
import ChallengeAtqRules from "./views/admin/challenge/viewAtqRules";
import ViewChallenge from "./views/admin/challenge/viewChallenge";
import AddSystemTag from "./views/admin/tag/addSystemTag";
import ViewSystemTag from "./views/admin/tag/viewSystemTag";
import AddArticle from "./views/admin/article/addArticle";
import ArticleOverview from "./views/admin/article/articleOverview";
import OrderDetails from "./views/admin/user/orderDetails";

import ImportUsers from "./views/admin/user/importUsers";

import AddResource from "./views/admin/resources/addResource";
import ViewResources from "./views/admin/resources/viewResources";

import AddPDFTemplate from "./views/admin/pdf-template/addPdfTemplate";
import ViewPdfTemplates from "./views/admin/pdf-template/viewPdfTemplates";

import AddInvoice from "./views/admin/invoice/addInvoice";
import ViewInvoices from "./views/admin/invoice/viewInvoices";
import PayInvoices from "./views/admin/invoice/payInvoices";
import PayTaskHistory from "./views/admin/invoice/payTaskHistory";
import TaskHistoryTemplates from "./views/admin/invoice/taskHistoryTemplates";
import AddTaskHistoryTemplates from "./views/admin/invoice/addTaskHistoryTemplate";

import InvoiceSettings from "./views/admin/invoice/invoiceSettings";

import AddCertificate from "./views/admin/resources/addCertificate";
import viewCertificates from "./views/admin/resources/viewCertificates";

import AddChallengeGroup from "./views/admin/challenge/addChallengeGroup";
import ViewChallengeGroup from "./views/admin/challenge/viewChallengeGroup";

import ViewMessages from "./views/admin/message/viewMessages";
import SendMessage from "./views/admin/message/sendMessage";
import CreateMessage from "./views/admin/message/createMessage";

import CustomAdminMenuPage from "./views/admin/settings/customAdminMenu";

import CreateSubscriptionPlan from "./views/admin/billing/createSubscriptionPlan";
import ViewSubscriptionPlan from "./views/admin/billing/viewSubscriptionPlan";
import AddMealPlan from "./views/admin/meal-plan/addMealPlan";
import ViewMealPlan from "./views/admin/meal-plan/viewMealPlan";
import ViewEmail from "./views/admin/email/viewEmail";

import AddCheckoutItem from "./views/admin/billing/addCheckoutItem";
import ViewCheckoutItem from "./views/admin/billing/viewCheckoutItems";
import ViewArchivedCheckoutItem from "./views/admin/billing/viewArchivedCheckoutItems";
import AddPromoCode from "./views/admin/billing/addPromoCode";
import ViewPromoCode from "./views/admin/billing/viewPromoCodes";
import AddUpsellItem from "./views/admin/billing/addUpsellItem";
import ViewUpsellItem from "./views/admin/billing/viewUpsellItems";
import AddFunnelPath from "./views/admin/billing/addFunnelPath";
import ViewFunnelPath from "./views/admin/billing/viewFunnelPaths";
import AddProduct from "./views/admin/billing/addProduct";
import ViewProducts from "./views/admin/billing/viewProducts";

import AddExercise from "./views/admin/custom-program/addExercise";
import ViewExercise from "./views/admin/custom-program/viewExercise";
import AddProgramPhase from "./views/admin/custom-program/addPhase";

import AccountabilityTrackingFeedback from "./views/admin/general/accTrackingFeedback";
import AccountabilityResponses from "./views/admin/accountability/responses";
import ViewNewsletters from "./views/admin/subscription-area/viewNewsletters";
import AddNewsletter from "./views/admin/subscription-area/addNewsletter";
import AddSpotlight from "./views/admin/marci-circle/addSpotlight";
import viewSpotlight from "./views/admin/marci-circle/viewSpotlights";
import MCAddAtqQuestion from "./views/admin/marci-circle/addAtqQuestion";

import TestPage from "./views/admin/general/testPage";
import FilterExample from "./views/admin/general/filterExample";
import TierPage from "./views/general/tier";
import AddAdditionalCard from "./views/admin/general/addAdditionalCard";
import AddEmail from "./views/admin/email/addEmail";
import AddSingleEmail from "./views/admin/email/addEmail";
import AddAutomationEmail from "./views/admin/email/addEmail";
import EmailSettings from "./views/admin/email/emailSettings";
import AddEmailSequence from "./views/admin/email/addEmailSequence";
import ViewEmailSequences from "./views/admin/email/viewEmailSequences";
import AddCustomProgram from "./views/admin/custom-program/addProgram";
import ViewCustomPrograms from "./views/admin/custom-program/viewPrograms";
import EditCustomProgram from "./views/admin/custom-program/editProgram";
import EditCustomProgramDay from "./views/admin/custom-program/editProgramDay";
import ViewTemplates from "./views/admin/custom-program/viewTemplates";
import ViewApplications from "./views/admin/custom-program/viewApplications";
import AddSystemUpdate from "./views/admin/system-update/addSystemUpdate";
import ViewSystemUpdate from "./views/admin/system-update/viewSystemUpdate";

import AddMemberPayment from "./views/admin/payments/addMemberPayment";
import ViewMemberPayments from "./views/admin/payments/viewMemberPayments";
import ViewPaymentsErrorLogs from "./views/admin/payments/errorLogs";
import AddBankTransfer from "./views/admin/payments/addBankTransfer";

import AddCompany from "./views/admin/company/addCompany";
import AddCompanyLicense from "./views/admin/company/addCompanyLicense";
import ViewCompany from "./views/admin/company/viewCompanies";
import AddLicense from "./views/admin/company/addLicense";
import ViewLicense from "./views/admin/company/viewLicenses";

import Reports from "./views/admin/reports/index";
import EmailReports from "./views/admin/reports/emails/emailReports";
import AffiliateReports from "./views/admin/reports/affiliates/affiliateReports";
import AffiliateListReport from "./views/admin/reports/affiliates/affiliateList";
import EmailLinks from "./views/admin/reports/emails/emailLinks";
import BouncedEmails from "./views/admin/reports/emails/bouncedEmails";
import CustoemrRevenue from "./views/admin/reports/users/customerRevenue";
import CustomAdminFields from "./views/admin/reports/users/customAdminFields";
import UnsubscribedUsers from "./views/admin/reports/users/unsubscribedUsers";
import AppleOrders from "./views/admin/reports/payments/viewAppleOrders";
import TagReports from "./views/admin/reports/tags/tagReports";
import MarciCircleReports from "./views/admin/reports/marci-circle/mcReports";
import ZoomMeetingReport from "./views/admin/reports/general/zoomMeetings";
import ChallengeProgressReport from "./views/admin/reports/challenge/challengeProgress";
import TagAttributesReport from "./views/admin/reports/tags/tagAttributesReport";

import AdminTasks from "./views/admin/tasks/tasks";
import DeletedTasks from "./views/admin/tasks/deletedTasks";
import CreateTask from "./views/admin/tasks/components/editTask";
import TaskSettings from "./views/admin/tasks/settings/taskSettings";
import TaskReports from "./views/admin/tasks/taskReports";

import TaskDetailPage from "./views/admin/tasks/taskDetails";

import MemberTasks from "./views/admin/tasks/tasks";
import MemberTaskDetailPage from "./views/admin/tasks/taskDetails";

import AddMovement from "./views/admin/marci-circle/addMovement";
import ViewMovements from "./views/admin/marci-circle/viewMovements";

import AddEducation from "./views/admin/marci-circle/addEducation";
import ViewEducations from "./views/admin/marci-circle/viewEducation";

import AddPopup from "./views/admin/popups/addPopup";
import ViewPopups from "./views/admin/popups/viewPopups";

import AddEditorPopup from "./views/admin/editor-popup/addEditorPopup";
import ViewEditorPopups from "./views/admin/editor-popup/viewEditorPopups";

import AddProcessPage from "./views/admin/processes/addProcess";
import ViewProcessesPage from "./views/admin/processes/viewProcesses";

import AddLandingPage from "./views/admin/landing-page/addLandingPage";
import ViewLandingPages from "./views/admin/landing-page/viewLandingPages";
import LandingWebsiteMenuPage from "./views/admin/landing-page/websiteMenu";
import ViewArchivedLandingPages from "./views/admin/landing-page/viewArchivedLandingPages";
import AddLandingFunnel from "./views/admin/landing-page/addLandingFunnel";
import ViewLandingFunnels from "./views/admin/landing-page/viewLandingFunnels";
import AddCustomSectionPage from "./views/admin/landing-page/addCustomSection";
import ViewCustomSectionsPage from "./views/admin/landing-page/viewCustomSections";

import AddLeadPage from "./views/admin/leads/addLead";
import ViewLeadsPage from "./views/admin/leads/viewLeads";
import LeadSettingsPage from "./views/admin/leads/leadSettings";
import MySitePage from "./views/admin/settings/mySite";

import EditorImages from "./components/editor/editorImages";

import NotFound from "./views/general/notFound";
import PreviewMpEditor from "./packages/mp-content-editor/views/preview";
import RadioTagV2Example from "./views/general/radioTagExample";

import {
  migrateStorage,
  setItem,
  getItem,
  removeItem,
} from "./helpers/storage";

// import { initializeFirebase } from "./pushNotification";
import { callRemoveLoggedinCustomer } from "./services";
import ErrorBoundary from "./errorBoundary";

// admin sync
const AsyncPDF = AsyncComponent(() => import("./views/general/pdf"));
const AsyncAdminMessages = AsyncComponent(() =>
  import("./views/admin/message/statusMessages")
);

const AsyncAdminViewMessages = AsyncComponent(() =>
  import("./views/admin/message/viewConversation")
);

const AsyncAdminDashboard = AsyncComponent(() =>
  import("./views/admin/dashboard")
);
const AsyncAddUser = AsyncComponent(() => import("./views/admin/user/addUser"));
const AsyncEditUser = AsyncComponent(() =>
  import("./views/admin/user/editUser")
);
const AsyncUsers = AsyncComponent(() => import("./views/admin/user/viewUsers"));

// company async

const AsyncCompanyDashboard = AsyncComponent(() =>
  import("./views/company/dashboard")
);
const AsyncCompanyDetail = AsyncComponent(() =>
  import("./views/company/detail/detail")
);
const AsyncCompanyBilling = AsyncComponent(() =>
  import("./views/company/billing/billing")
);
const AsyncCompanyLicenses = AsyncComponent(() =>
  import("./views/company/licenses/licenses")
);
const AsyncCompanyUsers = AsyncComponent(() =>
  import("./views/company/users/users")
);

// frontend aync

const AsyncLogin = AsyncComponent(() => import("./views/frontend/user/login"));

const AsyncAffiliateLanding = AsyncComponent(() =>
  import("./views/frontend/affiliateLanding")
);
const AsyncResetPassword = AsyncComponent(() =>
  import("./views/frontend/user/resetPassword")
);
const AsyncNewPassword = AsyncComponent(() =>
  import("./views/frontend/user/newPassword")
);

const AsyncUnsubscribe = AsyncComponent(() =>
  import("./views/frontend/user/unsubscribe")
);
const AsyncAffiliatePage = AsyncComponent(() =>
  import("./views/frontend/affiliate")
);
const AsyncInstallApp = AsyncComponent(() =>
  import("./views/frontend/user/installApp")
);
const AsyncClearApp = AsyncComponent(() => import("./views/general/clear"));

const AsyncLogooutApp = AsyncComponent(() =>
  import("./views/frontend/user/logout")
);

// member async
const AsyncDashboard = AsyncComponent(() => import("./views/member/dashboard"));
const AsyncProfile = AsyncComponent(() => import("./views/member/profile"));

const AsyncPhotoConsentForm = AsyncComponent(() =>
  import("./views/member/progress-journal/photoConsentForm")
);
const AsyncAddProgressJournal = AsyncComponent(() =>
  import("./views/member/progress-journal/addProgressJournal")
);
const AsyncViewProgressJournal = AsyncComponent(() =>
  import("./views/member/progress-journal/viewProgressJournal")
);
const AsyncMemberATQ = AsyncComponent(() => import("./views/member/atq/atq"));
const AsyncLeaderBoard = AsyncComponent(() =>
  import("./views/member/leaderboard/leaderboard")
);
const AsyncMemberChallenge = AsyncComponent(() =>
  import("./views/member/challenge/challenge")
);
const AsyncChallengeConsentForm = AsyncComponent(() =>
  import("./views/member/challenge/challengeConsentForm")
);
const AsyncMemberChallengeItem = AsyncComponent(() =>
  import("./views/member/challenge/challengeItem")
);
const AsyncMessages = AsyncComponent(() =>
  import("./views/member/message/messages")
);
const AsyncCheckoutUpgrade = AsyncComponent(() =>
  import("./views/member/challenge/upgrade")
);
const AsyncViewMessage = AsyncComponent(() =>
  import("./views/member/message/viewMessage")
);

const AsyncMarcisCircle = AsyncComponent(() =>
  import("./views/member/marcis-circle/marcisCircle")
);
const AsyncMCHome = AsyncComponent(() =>
  import("./views/member/marcis-circle/home")
);
const AsyncMCNewsletter = AsyncComponent(() =>
  import("./views/member/marcis-circle/newsletter")
);
const AsyncMCNewsletterDetail = AsyncComponent(() =>
  import("./views/member/marcis-circle/newsletterDetail")
);
const AsyncMCMealPlans = AsyncComponent(() =>
  import("./views/member/marcis-circle/mealPlans")
);
const AsyncMCMealPlanDetail = AsyncComponent(() =>
  import("./views/member/marcis-circle/mealPlanDetail")
);

const AsyncMCTrainings = AsyncComponent(() =>
  import("./views/member/marcis-circle/trainings")
);
const AsyncMCFoamRoller = AsyncComponent(() =>
  import("./views/member/marcis-circle/foamRoller")
);
const AsyncMCCourses = AsyncComponent(() =>
  import("./views/member/marcis-circle/courses")
);
const AsyncMCNews = AsyncComponent(() =>
  import("./views/member/marcis-circle/news")
);
const AsyncMCSavings = AsyncComponent(() =>
  import("./views/member/marcis-circle/savings")
);
const AsyncMCPodcasts = AsyncComponent(() =>
  import("./views/member/content/podcast")
);
const AsyncMCBuyChallenge = AsyncComponent(() =>
  import("./views/member/marcis-circle/buyChallenge")
);

const AsyncMCForum = AsyncComponent(() =>
  import("./views/member/marcis-circle/forum")
);
const AsyncSavePayoutDetails = AsyncComponent(() =>
  import("./views/member/payments/savePayoutDetails")
);
const AsyncPaymentHistory = AsyncComponent(() =>
  import("./views/member/payments/paymentHistory")
);
const AsyncBtpQuestionnaire = AsyncComponent(() =>
  import("./views/member/btp/btpQuestionnaire")
);
const AsyncBTP = AsyncComponent(() => import("./views/member/btp/btp"));
const AsyncMovements = AsyncComponent(() =>
  import("./views/member/marcis-circle/movements")
);
const AsyncMovementDetail = AsyncComponent(() =>
  import("./views/member/marcis-circle/movementDetail")
);
const AsyncEducations = AsyncComponent(() =>
  import("./views/member/marcis-circle/educations")
);
const AsyncEducationDetail = AsyncComponent(() =>
  import("./views/member/marcis-circle/educationDetail")
);

const AsyncResources = AsyncComponent(() =>
  import("./views/member/resources/resources")
);
const AsyncAwards = AsyncComponent(() =>
  import("./views/member/awards/awards")
);
const AsyncCharts = AsyncComponent(() =>
  import("./views/member/charts/charts")
);
const AsyncAddMemberChallenge = AsyncComponent(() =>
  import("./views/member/challenge/addChallenge")
);
const AsyncMemberBilling = AsyncComponent(() =>
  import("./views/member/payments/billing")
);
const AsyncEditBillingDetails = AsyncComponent(() =>
  import("./views/member/payments/editBillingDetails")
);
const AsyncCancelServicePage = AsyncComponent(() =>
  import("./views/member/cancel/cancelService")
);
const AsyncCancelServiceStepsPage = AsyncComponent(() =>
  import("./views/member/cancel/cancelSteps")
);
const AsyncCancelThankYouPage = AsyncComponent(() =>
  import("./views/member/cancel/cancelThankYou")
);
const AsyncMemberEvents = AsyncComponent(() =>
  import("./views/member/events/viewEvents")
);
const AsyncMemberQuiz = AsyncComponent(() =>
  import("./views/member/quiz/quiz")
);
const AsyncMemberQuizThankYou = AsyncComponent(() =>
  import("./views/member/quiz/quizThankYou")
);
const AsyncMemberAffiliate = AsyncComponent(() =>
  import("./views/member/affiliate/affiliate")
);
const AsyncMemberContent = AsyncComponent(() =>
  import("./views/member/content/content")
);
const AsyncMemberContentNewsletterDetail = AsyncComponent(() =>
  import("./views/member/content/newsletterDetail")
);
const AsyncMemberContentMealPlanDetail = AsyncComponent(() =>
  import("./views/member/content/mealPlanDetail")
);
const AsyncMemberUpcomingPayments = AsyncComponent(() =>
  import("./views/member/payments/upcomingPayments")
);

const AsyncMemberAccountability = AsyncComponent(() =>
  import("./views/member/atq/atq2")
);
const AsyncMemberLeads = AsyncComponent(() =>
  import("./views/member/leads/leads")
);
const AsyncTaskPayouts = AsyncComponent(() =>
  import("./views/member/payments/taskPayouts")
);
const AsyncReleasesPage = AsyncComponent(() =>
  import("./views/general/releases")
);
//admin pages

const AsyncAddChurnItemPage = AsyncComponent(() =>
  import("./views/admin/churn/addChurnItem")
);
const AsyncViewChurnItemsPage = AsyncComponent(() =>
  import("./views/admin/churn/viewChurnItems")
);
const AsyncViewGenericTag = AsyncComponent(() =>
  import("./views/admin/tag/viewGenericTag")
);
const AsyncViewArticleCategory = AsyncComponent(() =>
  import("./views/admin/article/viewArticleCategory")
);
const AsyncExportUsersPage = AsyncComponent(() =>
  import("./views/admin/user/exportUsers")
);
const AsyncAddEventPage = AsyncComponent(() =>
  import("./views/admin/events/addEvent")
);
const AsyncAddEventV2Page = AsyncComponent(() =>
  import("./views/admin/events/addEventV2")
);
const AsyncViewEventsPage = AsyncComponent(() =>
  import("./views/admin/events/viewEvents")
);
const AsyncViewEventsV2Page = AsyncComponent(() =>
  import("./views/admin/events/viewEventsV2")
);
const AsyncAddCustomerPage = AsyncComponent(() =>
  import("./views/admin/events/addCustomer")
);
const AsyncViewCustomersPage = AsyncComponent(() =>
  import("./views/admin/events/viewCustomers")
);
const AsyncViewArchiveUsersPage = AsyncComponent(() =>
  import("./views/admin/user/viewArchivedUsers")
);
const AsyncViewArchivedEmailPage = AsyncComponent(() =>
  import("./views/admin/email/viewArchivedEmail")
);
const AsyncViewArchivedChallengesPage = AsyncComponent(() =>
  import("./views/admin/challenge/viewArchivedChallenges")
);
const AsyncViewArchivedChallengeItemsPage = AsyncComponent(() =>
  import("./views/admin/challenge/viewArchivedChallengeItems")
);
const AsyncViewArchivedTagsPage = AsyncComponent(() =>
  import("./views/admin/tag/viewArchivedTags")
);
const AsyncViewPromotionsPage = AsyncComponent(() =>
  import("./views/admin/billing/viewPromotions")
);

const AsyncAddNotificationPage = AsyncComponent(() =>
  import("./views/admin/notification/addNotification")
);
const AsyncViewNotificationsPage = AsyncComponent(() =>
  import("./views/admin/notification/viewNotifications")
);

const AsyncSettingPage = AsyncComponent(() =>
  import("./views/admin/settings/settings")
);

const AsyncAddMemberPage = AsyncComponent(() =>
  import("./views/admin/user/addMember")
);

const AsyncViewMembersTagsPage = AsyncComponent(() =>
  import("./views/admin/user/viewMembersTags")
);

const AsyncAddFormPage = AsyncComponent(() =>
  import("./views/admin/forms/addForm")
);
const AsyncViewFormPage = AsyncComponent(() =>
  import("./views/admin/forms/viewForms")
);
const AsyncPreviewFormPage = AsyncComponent(() =>
  import("./views/admin/forms/previewForm")
);

// const AsyncAddPDFFormPage = AsyncComponent(() =>
//   import("./views/admin/forms/addPdfForm")
// );

const AsyncAddPDFFormPage = lazy(() =>
  import("./views/admin/forms/addPdfForm")
);

const AsyncViewPDFFormPage = AsyncComponent(() =>
  import("./views/admin/forms/viewPdfForms")
);
const AsyncAddExternalFormPage = AsyncComponent(() =>
  import("./views/admin/forms/addExternalForm")
);
const AsyncViewExternalFormsPage = AsyncComponent(() =>
  import("./views/admin/forms/viewExternalForms")
);

const AsyncFormPage = AsyncComponent(() => import("./views/member/form/form"));
const AsyncAddQuizPage = AsyncComponent(() =>
  import("./views/admin/quiz/addQuiz")
);
const AsyncViewQuizPage = AsyncComponent(() =>
  import("./views/admin/quiz/viewQuizzes")
);
const AsyncViewQuizResultPage = AsyncComponent(() =>
  import("./views/admin/quiz/viewQuizResult")
);
const AsyncViewPaymentsPage = AsyncComponent(() =>
  import("./views/admin/payments/viewHistory")
);
const AsyncViewUpcomingPaymentsPage = AsyncComponent(() =>
  import("./views/admin/payments/viewUpcomingPayments")
);
const AsyncViewFailedPaymentsPage = AsyncComponent(() =>
  import("./views/admin/payments/viewFailedPayments")
);
const AsyncViewSubscriptionsReportPage = AsyncComponent(() =>
  import("./views/admin/reports/payments/viewSubscriptionsReport")
);
const AsyncViewCustomerOrderPage = AsyncComponent(() =>
  import("./views/admin/reports/payments/viewCustomerOrders")
);
const AsyncAddTemplatePage = AsyncComponent(() =>
  import("./views/admin/email/addTemplate")
);
const AsyncViewTemplatesPage = AsyncComponent(() =>
  import("./views/admin/email/viewTemplates")
);
const AsyncAddSubscriptionAreaPage = AsyncComponent(() =>
  import("./views/admin/subscription-area/addSubscriptionArea")
);
const AsyncViewSubscriptionAreaPage = AsyncComponent(() =>
  import("./views/admin/subscription-area/viewSubscriptionArea")
);
const AsyncAddPodcastPage = AsyncComponent(() =>
  import("./views/admin/subscription-area/addPodcast")
);
const AsyncViewPodcastsPage = AsyncComponent(() =>
  import("./views/admin/subscription-area/viewPodcasts")
);
const AsyncAddRegistrationPage = AsyncComponent(() =>
  import("./views/admin/registration/addRegistrationPage")
);
const AsyncViewRegistrationPages = AsyncComponent(() =>
  import("./views/admin/registration/viewRegistrationPages")
);
const AsyncEnterRegistrationMode = AsyncComponent(() =>
  import("./views/admin/registration/enterRegistrationMode")
);
const AsyncStaffRegistrationCodes = AsyncComponent(() =>
  import("./views/admin/registration/staffRegistrationCodes")
);

const AsyncAddHelpItemPage = AsyncComponent(() =>
  import("./views/admin/processes/addHelpItem")
);
const AsyncViewHelpItemsPage = AsyncComponent(() =>
  import("./views/admin/processes/viewHelpItems")
);

const AsyncAddVideoPage = AsyncComponent(() =>
  import("./views/admin/subscription-area/addVideo")
);
const AsyncViewVideosPage = AsyncComponent(() =>
  import("./views/admin/subscription-area/viewVideos")
);

const AsyncAddOnboardingPage = AsyncComponent(() =>
  import("./views/admin/onboarding/addOnboarding")
);
const AsyncViewOnboardingPage = AsyncComponent(() =>
  import("./views/admin/onboarding/viewOnboarding")
);

const AsyncAddHelpDeskPage = AsyncComponent(() =>
  import("./views/admin/help-desk/addHelpDesk")
);
const AsyncViewHelpDeskPage = AsyncComponent(() =>
  import("./views/admin/help-desk/viewHelpDesk")
);
const AsyncSortHelpDeskPage = AsyncComponent(() =>
  import("./views/admin/help-desk/sorting")
);
const AsyncSettingsTickets = AsyncComponent(() =>
  import("./views/admin/help-desk/settingTickets")
);
const AsyncAddDepartmentPage = AsyncComponent(() =>
  import("./views/admin/help-desk/addDepartment")
);
const AsyncViewDepartmentsPage = AsyncComponent(() =>
  import("./views/admin/help-desk/viewDepartments")
);

const AsyncAddTicketPage = AsyncComponent(() =>
  import("./views/admin/help-desk/addTicket")
);
const AsyncViewTicketsPage = AsyncComponent(() =>
  import("./views/admin/help-desk/viewTickets")
);
const AsyncViewTicketPage = AsyncComponent(() =>
  import("./views/admin/help-desk/viewTicket")
);

const AsyncViewDatabasePage = AsyncComponent(() =>
  import("./views/admin/user/viewDatabases")
);
const AsyncViewArchivedDatabasePage = AsyncComponent(() =>
  import("./views/admin/user/viewArchivedDatabases")
);
const AsyncCreateDemoAccountPage = AsyncComponent(() =>
  import("./views/admin/user/createDemoAccount")
);

const AsyncAddClassroomPage = AsyncComponent(() =>
  import("./views/admin/classroom/addClassroom")
);
const AsyncViewClassroomPage = AsyncComponent(() =>
  import("./views/admin/classroom/viewClassroom")
);

const AsyncAddReleasePage = AsyncComponent(() =>
  import("./views/admin/release/addRelease")
);
const AsyncViewReleasesPage = AsyncComponent(() =>
  import("./views/admin/release/viewReleases")
);

const AsyncAddTierPage = AsyncComponent(() =>
  import("./views/admin/tier/addTier")
);
const AsyncViewTiersPage = AsyncComponent(() =>
  import("./views/admin/tier/viewTiers")
);

const AsyncAddSMSPage = AsyncComponent(() =>
  import("./views/admin/sms/addSms")
);
const AsyncSMSAutomation = AsyncComponent(() =>
  import("./views/admin/sms/smsAutomation")
);
const AsyncViewSMSPage = AsyncComponent(() =>
  import("./views/admin/sms/viewSms")
);
const AsyncViewArchivedSMSPage = AsyncComponent(() =>
  import("./views/admin/sms/viewArchivedSms")
);
const AsyncViewSMSHistoryPage = AsyncComponent(() =>
  import("./views/admin/sms/viewHistory")
);
const AsyncViewSMSConversationPage = AsyncComponent(() =>
  import("./views/admin/sms/viewConversation")
);
const AsyncAddIncomingRulePage = AsyncComponent(() =>
  import("./views/admin/sms/addIncomingRule")
);
const AsyncViewIncomingRulesPage = AsyncComponent(() =>
  import("./views/admin/sms/viewIncomingRules")
);

const AsyncViewSubmissionsPage = AsyncComponent(() =>
  import("./views/admin/user/viewSubmissions")
);

const AsyncCreateNewAdminMessagePage = AsyncComponent(() =>
  import("./views/admin/message/createAdminMessage")
);

const AsyncTagInfoPage = AsyncComponent(() =>
  import("./views/general/tagInfo")
);

// registration screens

const AsyncRegistrationPage = AsyncComponent(() =>
  import("./views/registration/page")
);
const AsyncExitRegistrationPage = AsyncComponent(() =>
  import("./views/registration/exit")
);
const AsyncRegisterPage = AsyncComponent(() =>
  import("./views/registration/register")
);
const AsyncRegisterCheckInPage = AsyncComponent(() =>
  import("./views/registration/checkin")
);

// menu & search components

const AdminMenu = lazy(() => import("./components/menu/adminMenu"));
const MemberMenu = lazy(() => import("./components/menu/memberMenu"));
const CompanyMenu = lazy(() => import("./components/menu/companyMenu"));
const RegistrationMenu = lazy(() =>
  import("./components/menu/registrationMenu")
);

const AdminSearch = lazy(() => import("./components/search/adminSearch"));
const MemberSearch = lazy(() => import("./components/search/memberSearch"));
const CompanySearch = lazy(() => import("./components/search/companySearch"));

const Popup = lazy(() => import("./components/popup/popup"));

class App extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      showAdminMenu: false,
      showMemberMenu: false,
      showCompanyMenu: false,
      showRegistratioMenu: false,
      currentScreen: "",
      loadPage: false,
    };
  }

  componentWillMount() {
    removeItem("uq");
    removeItem("cq");
    removeItem("lq");
    migrateStorage();
    // initializeFirebase();
    let location = this.props.history.location;
    this.checkPath(location);
    this.props.history.listen((location, action) => {
      this.checkPath(location);
    });
    getStoreSettings(location);
  }

  checkPath(location) {
    let path = location.pathname;
    if (!path.includes("login") && !path.includes("logout")) {
      setItem("lastRoute", `${path}${location.search}`);
    }

    let checkAdmin = path.includes("/admin");
    let checkMember = path.includes("/member");
    let checkCompany = path.includes("/company");
    let checkRegistration = path.includes("/registration");
    let checkNewPassword = path.includes("/new-password");
    let unsubscribePage = path.includes("/unsubscribe");
    let liveInfinitelyPage = path.includes("/live-infinitely");
    let programsPage = path.includes("/programs");
    let contactPage = path.includes("/contact");
    let educationPage = path.includes("/education");
    let blogPage = path.includes("/blog");
    let followPage = path.includes("/come-follow-me");
    let cancelThankYouPage = path.includes("/cancel-thank-you");

    checkPathForSearchBar(path);

    setItem("helpTextOn", false);

    if (checkAdmin || checkMember || checkCompany || checkRegistration) {
      $("body").attr("class", "");
      $("body").removeAttr("class");
      $("body").addClass("admin-body");
    } else {
      if (frontendScreens[path]) {
        $("body").attr("class", "");
        $("body").removeAttr("class");
        $("body").addClass("frontend-body");
      } else if (
        checkNewPassword ||
        unsubscribePage ||
        liveInfinitelyPage ||
        programsPage ||
        contactPage ||
        educationPage ||
        blogPage ||
        followPage ||
        cancelThankYouPage
      ) {
        $("body").attr("class", "");
        $("body").removeAttr("class");
        $("body").addClass("frontend-body");
      } else {
        $("body").attr("class", "");
        $("body").removeAttr("class");
        $("body").addClass("notFound");
      }
    }

    let rMode = getItem("rMode");
    if (rMode === "y") {
      checkAdmin = false;
      checkMember = false;
      checkCompany = false;
    }

    this.setState(
      {
        showAdminMenu: checkAdmin,
        showMemberMenu: checkMember,
        showCompanyMenu: checkCompany,
        showRegistratioMenu: checkRegistration,
        currentScreen: path,
      },
      () => {
        window.scrollTo(0, 0); // Scroll user to the top of the page when they click a link...
        var pbc = getItem("pbc");
        if (pbc && checkMember) {
          $(".admin-body").css("backgroundColor", pbc);
        } else {
          $(".admin-body").css("backgroundColor", "#f2f6fb");
        }
      }
    );
  }

  renderAdminMenu() {
    const { isAdmin, isLoggedIn } = checkAuth();
    if (isAdmin && isLoggedIn) {
      return (
        <Suspense fallback={<div />}>
          <AdminMenu history={this.props.history} />
        </Suspense>
      );
    }
    return null;
  }

  renderCompanyMenu() {
    const { isCompany, isLoggedIn } = checkAuth();
    if (isCompany && isLoggedIn) {
      return (
        <Suspense fallback={<div />}>
          <CompanyMenu history={this.props.history} />
        </Suspense>
      );
    }
    return null;
  }

  renderMemberMenu() {
    const { isMember, isAdmin, isLoggedIn } = checkAuth();
    if ((isAdmin || isMember) && isLoggedIn) {
      return (
        <Suspense fallback={<div />}>
          <MemberMenu history={this.props.history} />
        </Suspense>
      );
    }
    return null;
  }

  renderRegistrationMenu() {
    const { isMember, isAdmin, isLoggedIn } = checkAuth();
    if ((isAdmin || isMember) && isLoggedIn) {
      return (
        <Suspense fallback={<div />}>
          <RegistrationMenu history={this.props.history} />
        </Suspense>
      );
    }
    return null;
  }

  renderAdminSearch() {
    const { isAdmin, isLoggedIn } = checkAuth();
    if (isAdmin && isLoggedIn) {
      return (
        <Suspense fallback={<div />}>
          <AdminSearch history={this.props.history} />
        </Suspense>
      );
    }
    return null;
  }

  renderCompanySearch() {
    const { isCompany, isLoggedIn } = checkAuth();
    if (isCompany && isLoggedIn) {
      return (
        <Suspense fallback={<div />}>
          <CompanySearch history={this.props.history} />
        </Suspense>
      );
    }
    return null;
  }

  renderMemberSearch() {
    const { isMember, isAdmin, isLoggedIn } = checkAuth();
    if ((isAdmin || isMember) && isLoggedIn) {
      return (
        <Suspense fallback={<div />}>
          <MemberSearch history={this.props.history} />
        </Suspense>
      );
    }
    return null;
  }

  getBackgroundStyle() {
    var style = {};
    if (this.state.showMemberMenu) {
      var pbc = getItem("pbc");
      if (pbc) {
        style = { backgroundColor: pbc };
      }
    }
    return style;
  }

  async onClickOkFromAccessAlert() {
    $(
      ".modalAlertOverlay.confirmAccessModal,.modalAlert.confirmAccessAlert"
    ).fadeOut(200);

    let userData = getItem("userData");
    let memberId = "";
    if (userData) {
      memberId = JSON.parse(userData)._id;
    }
    /*eslint-disable*/
    let query = {
      member_id: memberId,
    };
    /*eslint-enable*/
    await callRemoveLoggedinCustomer(query);

    removeItem("ism");
    removeItem("accessToken");
    removeItem("tokenExpiry");
    removeItem("userData");
    removeItem("companyId");

    this.props.history.replace("/login");
  }

  renderAccessAlert() {
    let isShow = getItem("ism");
    if (!isShow) {
      return null;
    }
    return (
      <div>
        <div className="modalAlertOverlay confirmAccessModal" />
        <div className="modalAlert confirmAccessAlert">
          <p>
            {!isShow
              ? ""
              : "We apologize it looks like this account is already logged in. You will need to logout from your other device or wait until it is inactive before you can continue."}
          </p>
          <div className="alertFooterBtns">
            {!isShow ? (
              ""
            ) : (
              <button
                className="btn btn-default closeModal-No margin-right-10"
                onClick={this.onClickOkFromAccessAlert.bind(this)}>
                Ok
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderAccessAlert()}
        <ClearCache>
          {({ isLatestVersion, emptyCacheStorage }) => {
            return <div></div>;
          }}
        </ClearCache>
        <ToastContainer
          enableMultiContainer
          containerId={"topRight"}
          position={toast.POSITION.TOP_RIGHT}
        />
        <ToastContainer
          enableMultiContainer
          containerId={"center"}
          position={toast.POSITION.TOP_CENTER}
        />

        <Progress.Component />
        {this.state.showAdminMenu && this.renderAdminMenu()}
        {/* {this.state.showAdminMenu && this.renderAdminSearch()} */}

        {this.state.showCompanyMenu && this.renderCompanyMenu()}
        {this.state.showCompanyMenu && this.renderCompanySearch()}

        {this.state.showMemberMenu && this.renderMemberMenu()}
        {(this.state.showMemberMenu || this.state.showAdminMenu) &&
          this.renderMemberSearch()}

        {this.state.showRegistratioMenu && this.renderRegistrationMenu()}

        <Suspense fallback={<div />}>
          <Popup history={this.props.history} />
        </Suspense>

        <main>
          <ErrorBoundary>
            <Switch>
              {/**
               * Frontend route
               */}

              <Route exact path="/sso" component={SSOPage} />

              <FrontendRoute exact path="/" component={AsyncLogin} />

              <FrontendRoute
                exact
                path="/install-app"
                component={AsyncInstallApp}
              />
              <FrontendRoute exact path="/clear" component={AsyncClearApp} />

              <FrontendRoute exact path="/logout" component={AsyncLogooutApp} />

              <FrontendRoute
                exact
                path="/reset-password"
                component={AsyncResetPassword}
              />
              <FrontendRoute
                exact
                path="/new-password/:code"
                component={AsyncNewPassword}
              />
              <FrontendRoute exact path="/login" component={AsyncLogin} />

              <FrontendRoute
                exact
                path="/unsubscribe/:memberid"
                component={AsyncUnsubscribe}
              />
              <FrontendRoute
                exact
                path="/page"
                component={AsyncAffiliatePage}
              />

              <FrontendRoute
                exact
                path="/cancel/:churn_id"
                component={AsyncCancelServiceStepsPage}
              />
              <FrontendRoute
                exact
                path="/cancel/:churn_id/:item_id"
                component={AsyncCancelServiceStepsPage}
              />
              <FrontendRoute
                exact
                path="/cancel-thank-you"
                component={AsyncCancelThankYouPage}
              />

              <FrontendRoute
                exact
                path="/a"
                component={AsyncAffiliateLanding}
              />

              {/**
               * Member routes
               */}
              <MemberRoute exact path="/member" component={AsyncDashboard} />
              <MemberRoute
                exact
                path="/member/profile"
                component={AsyncProfile}
              />
              <MemberRoute
                exact
                path="/member/photo-consent"
                component={AsyncPhotoConsentForm}
              />
              <MemberRoute
                exact
                path="/member/add-progress-journal"
                component={AsyncAddProgressJournal}
              />
              <MemberRoute
                exact
                path="/member/view-progress-journal"
                component={AsyncViewProgressJournal}
              />
              <MemberRoute
                exact
                path="/member/messages"
                component={AsyncMessages}
              />
              <MemberRoute
                exact
                path="/member/marcis-circle"
                component={AsyncMarcisCircle}
              />
              <MemberRoute
                exact
                path="/member/marcis-circle/home"
                component={AsyncMCHome}
              />
              <MemberRoute
                exact
                path="/member/marcis-circle/newsletter"
                component={AsyncMCNewsletter}
              />
              <MemberRoute
                exact
                path="/member/marcis-circle/newsletter-detail"
                component={AsyncMCNewsletterDetail}
              />
              <MemberRoute
                exact
                path="/member/marcis-circle/meal-plans"
                component={AsyncMCMealPlans}
              />
              <MemberRoute
                exact
                path="/member/marcis-circle/meal-plan-details"
                component={AsyncMCMealPlanDetail}
              />
              <MemberRoute
                exact
                path="/member/marcis-circle/trainings"
                component={AsyncMCTrainings}
              />
              <MemberRoute
                exact
                path="/member/marcis-circle/foam-roller"
                component={AsyncMCFoamRoller}
              />
              <MemberRoute
                exact
                path="/member/marcis-circle/courses"
                component={AsyncMCCourses}
              />
              <MemberRoute
                exact
                path="/member/marcis-circle/news"
                component={AsyncMCNews}
              />
              <MemberRoute
                exact
                path="/member/marcis-circle/savings"
                component={AsyncMCSavings}
              />
              <MemberRoute
                exact
                path="/member/marcis-circle/buy-challenge"
                component={AsyncMCBuyChallenge}
              />
              <MemberRoute
                exact
                path="/member/marcis-circle/podcasts"
                component={AsyncMCPodcasts}
              />
              <MemberRoute
                exact
                path="/member/marcis-circle/forum"
                component={AsyncMCForum}
              />
              <MemberRoute
                exact
                path="/member/awards"
                component={AsyncAwards}
              />
              <MemberRoute
                exact
                path="/member/charts"
                component={AsyncCharts}
              />
              <MemberRoute
                exact
                path="/member/view-messages"
                component={AsyncViewMessage}
              />
              <MemberRoute
                exact
                path="/member/atq2"
                component={AsyncMemberATQ}
              />
              <MemberRoute
                exact
                path="/member/challenge-consent"
                component={AsyncChallengeConsentForm}
              />
              <MemberRoute
                exact
                path="/member/challenge"
                component={AsyncMemberChallenge}
              />
              <MemberRoute
                exact
                path="/member/leaderboard"
                component={AsyncLeaderBoard}
              />
              <MemberRoute
                exact
                path="/member/challenge-item"
                component={AsyncMemberChallengeItem}
              />
              <MemberRoute
                exact
                path="/member/payout-details"
                component={AsyncSavePayoutDetails}
              />
              <MemberRoute
                exact
                path="/member/payment-history"
                component={AsyncPaymentHistory}
              />
              <MemberRoute
                exact
                path="/member/btp-questionnaire"
                component={AsyncBtpQuestionnaire}
              />
              <MemberRoute exact path="/member/btp" component={AsyncBTP} />
              <MemberRoute
                exact
                path="/member/add-challenge"
                component={AsyncAddMemberChallenge}
              />
              <MemberRoute
                exact
                path="/member/billing"
                component={AsyncMemberBilling}
              />
              <MemberRoute
                exact
                path="/member/movements"
                component={AsyncMovements}
              />
              <MemberRoute
                exact
                path="/member/movement/:url"
                component={AsyncMovementDetail}
              />
              <MemberRoute
                exact
                path="/member/educations"
                component={AsyncEducations}
              />
              <MemberRoute
                exact
                path="/member/education/:url"
                component={AsyncEducationDetail}
              />
              <MemberRoute
                exact
                path="/member/resources"
                component={AsyncResources}
              />
              <MemberRoute
                exact
                path="/member/edit-billing"
                component={AsyncEditBillingDetails}
              />
              <MemberRoute
                exact
                path="/member/cancel-service"
                component={AsyncCancelServicePage}
              />
              <MemberRoute
                exact
                path="/member/events"
                component={AsyncMemberEvents}
              />
              <MemberRoute
                exact
                path="/member/create-message"
                component={CreateMessage}
              />
              <MemberRoute
                exact
                path="/member/quiz"
                component={AsyncMemberQuiz}
              />
              <MemberRoute
                exact
                path="/member/quiz/thank-you"
                component={AsyncMemberQuizThankYou}
              />
              <MemberRoute
                exact
                path="/member/affiliate"
                component={AsyncMemberAffiliate}
              />
              <MemberRoute
                exact
                path="/member/content"
                component={AsyncMemberContent}
              />
              <MemberRoute
                exact
                path="/member/content/newsletter-detail"
                component={AsyncMemberContentNewsletterDetail}
              />
              <MemberRoute
                exact
                path="/member/content/meal-plan-detail"
                component={AsyncMemberContentMealPlanDetail}
              />
              <MemberRoute
                exact
                path="/member/upcoming-payments"
                component={AsyncMemberUpcomingPayments}
              />
              <MemberRoute
                exact
                path="/member/atq"
                component={AsyncMemberAccountability}
              />

              <MemberRoute
                exact
                path="/member/leads"
                component={AsyncMemberLeads}
              />
              <MemberRoute
                exact
                path="/member/task-payouts"
                component={AsyncTaskPayouts}
              />

              <MemberRoute
                exact
                path="/member/add-lead"
                component={AddLeadPage}
              />
              <MemberRoute
                exact
                path="/member/checkout-upgrade"
                component={AsyncCheckoutUpgrade}
              />

              <MemberRoute exact path="/member/tasks" component={MemberTasks} />

              <MemberRoute
                exact
                path="/member/task-detail"
                component={MemberTaskDetailPage}
              />

              <MemberRoute
                exact
                path="/member/form"
                component={AsyncFormPage}
              />

              {/**
               * Admin routes
               */}
              <AdminRoute
                exact
                path="/admin/radio-tag-example"
                component={RadioTagV2Example}
                currentScreen={this.state.currentScreen}
              />
              <AdminRoute
                exact
                path="/admin/pdf"
                component={AsyncPDF}
                currentScreen={this.state.currentScreen}
              />
              <AdminRoute
                exact
                path="/admin"
                component={AsyncAdminDashboard}
                currentScreen={this.state.currentScreen}
              />
              <AdminRoute
                exact
                path="/admin/sso"
                component={SSOPage}
                currentScreen={this.state.currentScreen}
              />
              <AdminRoute
                exact
                path="/admin/add-user"
                component={AsyncAddUser}
                currentScreen={this.state.currentScreen}
              />
              <AdminRoute
                exact
                path="/admin/users"
                component={AsyncUsers}
                currentScreen={this.state.currentScreen}
              />
              <AdminRoute
                exact
                path="/admin/edit-user"
                component={AsyncEditUser}
                currentScreen={this.state.currentScreen}
              />
              <AdminRoute
                exact
                path="/admin/order-details"
                component={OrderDetails}
                currentScreen={this.state.currentScreen}
              />
              <AdminRoute
                exact
                path="/admin/view-messages"
                component={ViewMessages}
                currentScreen={this.state.currentScreen}
              />
              <AdminRoute
                exact
                path="/admin/send-message"
                component={SendMessage}
                currentScreen={this.state.currentScreen}
              />
              <AdminRoute
                exact
                path="/admin/create-message"
                component={CreateMessage}
                currentScreen={this.state.currentScreen}
              />
              <AdminRoute
                exact
                path="/admin/add-question"
                component={AddChallengeQuestion}
                currentScreen={this.state.currentScreen}
              />
              <AdminRoute
                exact
                path="/admin/view-questions"
                component={ViewChallengeQuestion}
                currentScreen={this.state.currentScreen}
              />
              <AdminRoute
                exact
                path="/admin/view-archived-questions"
                component={ViewArchivedQuestion}
                currentScreen={this.state.currentScreen}
              />
              <AdminRoute
                exact
                path="/admin/add-challenge-item"
                component={AddChallengeItem}
                currentScreen={this.state.currentScreen}
              />
              <AdminRoute
                exact
                path="/admin/view-challenge-items"
                component={ViewChallengeItem}
                currentScreen={this.state.currentScreen}
              />
              <AdminRoute
                exact
                path="/admin/add-challenge"
                component={AddChallenge}
                currentScreen={this.state.currentScreen}
              />
              <AdminRoute
                exact
                path="/admin/edit-challenge"
                component={AddChallenge}
                currentScreen={this.state.currentScreen}
              />
              <AdminRoute
                exact
                path="/admin/view-challenges"
                component={ViewChallenge}
                currentScreen={this.state.currentScreen}
              />
              <AdminRoute
                exact
                path="/admin/challenge-atq-rules"
                component={ChallengeAtqRules}
                currentScreen={this.state.currentScreen}
              />
              <AdminRoute
                exact
                path="/admin/view-archived-challenges"
                component={AsyncViewArchivedChallengesPage}
                currentScreen={this.state.currentScreen}
              />
              <AdminRoute
                exact
                path="/admin/view-archived-challenge-items"
                component={AsyncViewArchivedChallengeItemsPage}
                currentScreen={this.state.currentScreen}
              />
              <AdminRoute
                exact
                path="/admin/add-system-tag"
                component={AddSystemTag}
                currentScreen={this.state.currentScreen}
              />
              <AdminRoute
                exact
                path="/admin/view-system-tags"
                component={ViewSystemTag}
                currentScreen={this.state.currentScreen}
              />
              <AdminRoute
                exact
                path="/admin/view-archived-tags"
                component={AsyncViewArchivedTagsPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/view-atq2"
                component={AccountabilityTrackingFeedback}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/view-atq"
                component={AccountabilityResponses}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/add-newsletter"
                component={AddNewsletter}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/view-newsletters"
                component={ViewNewsletters}
                currentScreen={this.state.currentScreen}
              />
              <AdminRoute
                exact
                path="/admin/add-member-payment"
                component={AddMemberPayment}
                currentScreen={this.state.currentScreen}
              />
              <AdminRoute
                exact
                path="/admin/view-member-payments"
                component={ViewMemberPayments}
                currentScreen={this.state.currentScreen}
              />
              <AdminRoute
                exact
                path="/admin/view-payments-error-logs"
                component={ViewPaymentsErrorLogs}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/add-bank-transfer"
                component={AddBankTransfer}
                currentScreen={this.state.currentScreen}
              />
              <AdminRoute
                exact
                path="/admin/add-checkout-item"
                component={AddCheckoutItem}
                currentScreen={this.state.currentScreen}
              />
              <AdminRoute
                exact
                path="/admin/view-checkout-items"
                component={ViewCheckoutItem}
                currentScreen={this.state.currentScreen}
              />
              <AdminRoute
                exact
                path="/admin/add-product"
                component={AddProduct}
                currentScreen={this.state.currentScreen}
              />
              <AdminRoute
                exact
                path="/admin/view-products"
                component={ViewProducts}
                currentScreen={this.state.currentScreen}
              />
              <AdminRoute
                exact
                path="/admin/view-archived-checkout-items"
                component={ViewArchivedCheckoutItem}
                currentScreen={this.state.currentScreen}
              />
              <AdminRoute
                exact
                path="/admin/add-promo-code"
                component={AddPromoCode}
                currentScreen={this.state.currentScreen}
              />
              <AdminRoute
                exact
                path="/admin/add-upsell-item"
                component={AddUpsellItem}
                currentScreen={this.state.currentScreen}
              />
              <AdminRoute
                exact
                path="/admin/view-upsell-items"
                component={ViewUpsellItem}
                currentScreen={this.state.currentScreen}
              />
              <AdminRoute
                exact
                path="/admin/add-funnel-path"
                component={AddFunnelPath}
                currentScreen={this.state.currentScreen}
              />
              <AdminRoute
                exact
                path="/admin/view-funnel-paths"
                component={ViewFunnelPath}
                currentScreen={this.state.currentScreen}
              />
              <AdminRoute
                exact
                path="/admin/view-promo-codes"
                component={ViewPromoCode}
                currentScreen={this.state.currentScreen}
              />
              <AdminRoute
                exact
                path="/admin/add-article"
                component={AddArticle}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/view-articles"
                component={ArticleOverview}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/test-page"
                component={TestPage}
                currentScreen={this.state.currentScreen}
              />
              <AdminRoute
                exact
                path="/admin/filter-example"
                component={FilterExample}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/tier"
                component={TierPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/add-additional-card"
                component={AddAdditionalCard}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/add-email"
                component={AddEmail}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/add-email-sequence"
                component={AddEmailSequence}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/view-email-sequences"
                component={ViewEmailSequences}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/add-single-email"
                component={AddSingleEmail}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/add-automated-email"
                component={AddAutomationEmail}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/edit-email"
                component={AddEmail}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/email-settings"
                component={EmailSettings}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/add-custom-program"
                component={AddCustomProgram}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/view-custom-programs"
                component={ViewCustomPrograms}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/edit-custom-program"
                component={EditCustomProgram}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/edit-custom-program-day"
                component={EditCustomProgramDay}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/view-emails"
                component={ViewEmail}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/view-archived-emails"
                component={AsyncViewArchivedEmailPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/add-meal-plan"
                component={AddMealPlan}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/view-meal-plans"
                component={ViewMealPlan}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/add-system-update"
                component={AddSystemUpdate}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/view-system-updates"
                component={ViewSystemUpdate}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/add-subscription-plan"
                component={CreateSubscriptionPlan}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/view-subscription-plans"
                component={ViewSubscriptionPlan}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/add-exercise"
                component={AddExercise}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/view-exercises"
                component={ViewExercise}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/add-program-phase"
                component={AddProgramPhase}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/add-spotlight"
                component={AddSpotlight}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/view-spotlights"
                component={viewSpotlight}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/reports"
                component={Reports}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/reports/email-reports"
                component={EmailReports}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/reports/affiliate-reports"
                component={AffiliateReports}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/reports/affiliate-list"
                component={AffiliateListReport}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/reports/email-links"
                component={EmailLinks}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/reports/bounced-emails"
                component={BouncedEmails}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/reports/customer-revenue"
                component={CustoemrRevenue}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/reports/custom-admin-fields"
                component={CustomAdminFields}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/reports/unsubscribed-users"
                component={UnsubscribedUsers}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/reports/apple-orders"
                component={AppleOrders}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/reports/view-tags"
                component={TagReports}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/reports/upcoming-payments"
                component={AsyncViewUpcomingPaymentsPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/reports/payments-history"
                component={AsyncViewPaymentsPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/reports/zoom-meetings"
                component={ZoomMeetingReport}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/reports/challenge-users"
                component={ChallengeProgressReport}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/reports/tag-attributes"
                component={TagAttributesReport}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/add-mc-atq-question"
                component={MCAddAtqQuestion}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/reports/marci-circle-reports"
                component={MarciCircleReports}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/add-challenge-group"
                component={AddChallengeGroup}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/view-challenge-groups"
                component={ViewChallengeGroup}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/tag-info"
                component={AsyncTagInfoPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/tasks"
                component={AdminTasks}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/deleted-tasks"
                component={DeletedTasks}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/create-task"
                component={CreateTask}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/task-settings"
                component={TaskSettings}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/task-reports"
                component={TaskReports}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/task-detail"
                component={TaskDetailPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/view-templates"
                component={ViewTemplates}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/add-company"
                component={AddCompany}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/add-company-license"
                component={AddCompanyLicense}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/view-companies"
                component={ViewCompany}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/add-license"
                component={AddLicense}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/view-licenses"
                component={ViewLicense}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/setup-licenses"
                component={AsyncCompanyLicenses}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/preview-challenges"
                component={ViewChallenge}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/view-applications"
                component={ViewApplications}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/add-movement"
                component={AddMovement}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/view-movements"
                component={ViewMovements}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/add-pdf-template"
                component={AddPDFTemplate}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/view-pdf-templates"
                component={ViewPdfTemplates}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/add-education"
                component={AddEducation}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/view-educations"
                component={ViewEducations}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/add-resource"
                component={AddResource}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/view-resources"
                component={ViewResources}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/add-invoice"
                component={AddInvoice}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/view-invoices"
                component={ViewInvoices}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/pay-invoices"
                component={PayInvoices}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/pay-task-history"
                component={PayTaskHistory}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/add-task-history-template"
                component={AddTaskHistoryTemplates}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/task-history-templates"
                component={TaskHistoryTemplates}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/invoice-settings"
                component={InvoiceSettings}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/add-certificate"
                component={AddCertificate}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/view-certificates"
                component={viewCertificates}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/import-users"
                component={ImportUsers}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/add-popup"
                component={AddPopup}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/view-popups"
                component={ViewPopups}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/add-editor-popup"
                component={AddEditorPopup}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/view-editor-popups"
                component={ViewEditorPopups}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/add-landing-page"
                component={AddLandingPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/view-landing-pages"
                component={ViewLandingPages}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/landing-website-menu"
                component={LandingWebsiteMenuPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/add-custom-section"
                component={AddCustomSectionPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/view-custom-sections"
                component={ViewCustomSectionsPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/view-archived-landing-pages"
                component={ViewArchivedLandingPages}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/add-landing-funnel"
                component={AddLandingFunnel}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/view-landing-funnels"
                component={ViewLandingFunnels}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/image-gallery"
                component={EditorImages}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/add-process"
                component={AddProcessPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/view-processes"
                component={ViewProcessesPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/add-lead"
                component={AddLeadPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/view-leads"
                component={ViewLeadsPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/lead-settings"
                component={LeadSettingsPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/coaching-site"
                component={MySitePage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/add-churn-item"
                component={AsyncAddChurnItemPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/view-churn-items"
                component={AsyncViewChurnItemsPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/view-generic-tags"
                component={AsyncViewGenericTag}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/view-article-categories"
                component={AsyncViewArticleCategory}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/export-users"
                component={AsyncExportUsersPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/add-event"
                component={AsyncAddEventV2Page}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/add-event-v1"
                component={AsyncAddEventPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/view-events"
                component={AsyncViewEventsV2Page}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/view-events-v1"
                component={AsyncViewEventsPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/add-event-customer"
                component={AsyncAddCustomerPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/view-event-customers"
                component={AsyncViewCustomersPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/view-archived-users"
                component={AsyncViewArchiveUsersPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/view-promotions"
                component={AsyncViewPromotionsPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/add-notification"
                component={AsyncAddNotificationPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/view-notifications"
                component={AsyncViewNotificationsPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/settings"
                component={AsyncSettingPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/add-member"
                component={AsyncAddMemberPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/view-members-tags"
                component={AsyncViewMembersTagsPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/add-form"
                component={AsyncAddFormPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/view-forms"
                component={AsyncViewFormPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/add-pdf-form"
                component={AsyncAddPDFFormPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/view-pdf-forms"
                component={AsyncViewPDFFormPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/add-external-form"
                component={AsyncAddExternalFormPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/view-external-forms"
                component={AsyncViewExternalFormsPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/preview-form"
                component={AsyncPreviewFormPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/add-quiz"
                component={AsyncAddQuizPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/view-quizzes"
                component={AsyncViewQuizPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/view-quiz-result"
                component={AsyncViewQuizResultPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/view-payments"
                component={AsyncViewPaymentsPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/view-upcoming-payments"
                component={AsyncViewUpcomingPaymentsPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/reports/failed-payments"
                component={AsyncViewFailedPaymentsPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/reports/archived-payments"
                component={AsyncViewFailedPaymentsPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/reports/view-subscriptions"
                component={AsyncViewSubscriptionsReportPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/reports/view-customer-orders"
                component={AsyncViewCustomerOrderPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/add-email-template"
                component={AsyncAddTemplatePage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/view-email-templates"
                component={AsyncViewTemplatesPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/add-subscription-area"
                component={AsyncAddSubscriptionAreaPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/view-subscription-area"
                component={AsyncViewSubscriptionAreaPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/add-podcast"
                component={AsyncAddPodcastPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/view-podcasts"
                component={AsyncViewPodcastsPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/add-registration-page"
                component={AsyncAddRegistrationPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/view-registration-pages"
                component={AsyncViewRegistrationPages}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/enter-registration-mode"
                component={AsyncEnterRegistrationMode}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/staff-registration-codes"
                component={AsyncStaffRegistrationCodes}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/add-help-item"
                component={AsyncAddHelpItemPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/view-help-items"
                component={AsyncViewHelpItemsPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/add-video"
                component={AsyncAddVideoPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/view-videos"
                component={AsyncViewVideosPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/add-onboarding"
                component={AsyncAddOnboardingPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/view-onboarding"
                component={AsyncViewOnboardingPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/user/edit-billing"
                component={AsyncEditBillingDetails}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/add-help-desk"
                component={AsyncAddHelpDeskPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/view-help-desk"
                component={AsyncViewHelpDeskPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/add-department"
                component={AsyncAddDepartmentPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/view-departments"
                component={AsyncViewDepartmentsPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/add-ticket"
                component={AsyncAddTicketPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/view-tickets"
                component={AsyncViewTicketsPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/view-ticket"
                component={AsyncViewTicketPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/settings-help-desk"
                component={AsyncSortHelpDeskPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/settings-tickets"
                component={AsyncSettingsTickets}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/view-dbs"
                component={AsyncViewDatabasePage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/view-archived-dbs"
                component={AsyncViewArchivedDatabasePage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/create-demo-account"
                component={AsyncCreateDemoAccountPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/add-classroom"
                component={AsyncAddClassroomPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/view-classrooms"
                component={AsyncViewClassroomPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/add-sms"
                component={AsyncAddSMSPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/view-sms"
                component={AsyncViewSMSPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/sms-automation"
                component={AsyncSMSAutomation}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/view-archived-sms"
                component={AsyncViewArchivedSMSPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/view-sms-history"
                component={AsyncViewSMSHistoryPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/view-sms-conversation"
                component={AsyncViewSMSConversationPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/add-incoming-rule"
                component={AsyncAddIncomingRulePage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/view-incoming-rules"
                component={AsyncViewIncomingRulesPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/preview-mp-editor"
                component={PreviewMpEditor}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/view-submissions"
                component={AsyncViewSubmissionsPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/add-release"
                component={AsyncAddReleasePage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/view-releases"
                component={AsyncViewReleasesPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/add-tier"
                component={AsyncAddTierPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/view-tiers"
                component={AsyncViewTiersPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/releases"
                component={AsyncReleasesPage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/messages/view"
                component={AsyncAdminViewMessages}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/messages/open"
                component={AsyncAdminMessages}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/messages/closed"
                component={AsyncAdminMessages}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/messages/pending"
                component={AsyncAdminMessages}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/messages/create-message"
                component={AsyncCreateNewAdminMessagePage}
                currentScreen={this.state.currentScreen}
              />

              <AdminRoute
                exact
                path="/admin/custom-admin-menu"
                component={CustomAdminMenuPage}
                currentScreen={this.state.currentScreen}
              />

              {/**
               * Registration routes
               */}

              <RegistrationRoute
                exact
                path="/registration"
                component={AsyncRegistrationPage}
                currentScreen={this.state.currentScreen}
              />

              <RegistrationRoute
                exact
                path="/registration/exit"
                component={AsyncExitRegistrationPage}
                currentScreen={this.state.currentScreen}
              />

              <RegistrationRoute
                exact
                path="/registration/register"
                component={AsyncRegisterPage}
                currentScreen={this.state.currentScreen}
              />

              <RegistrationRoute
                exact
                path="/registration/checkin"
                component={AsyncRegisterCheckInPage}
                currentScreen={this.state.currentScreen}
              />

              {/**
               * Company routes
               */}

              <CompanyRoute
                exact
                path="/company"
                component={AsyncCompanyDashboard}
                currentScreen={this.state.currentScreen}
              />

              <CompanyRoute
                exact
                path="/company/detail"
                component={AsyncCompanyDetail}
                currentScreen={this.state.currentScreen}
              />

              <CompanyRoute
                exact
                path="/company/billing"
                component={AsyncCompanyBilling}
                currentScreen={this.state.currentScreen}
              />

              <CompanyRoute
                exact
                path="/company/licenses"
                component={AsyncCompanyLicenses}
                currentScreen={this.state.currentScreen}
              />

              <CompanyRoute
                exact
                path="/company/users"
                component={AsyncCompanyUsers}
                currentScreen={this.state.currentScreen}
              />

              <Route component={NotFound} />
            </Switch>
          </ErrorBoundary>
        </main>
      </div>
    );
  }
}

const FrontendRoute = function ({ component: Component, ...rest }) {
  const { isAdmin, isMember, isLoggedIn } = checkAuth();
  let path = rest.path;
  let ignorePaths = [
    "/terms-of-service",
    "/privacy-policy",
    "/hec",
    "/contact",
    "/education",
    "/programs",
    "/blog",
    "/come-follow-me",
    "/logout",
    "/clear",
  ];
  let isIgnore = ignorePaths.indexOf(path) > -1;

  if (
    path.includes("/unsubscribe") ||
    path.includes("/process-order") ||
    path.includes("/page") ||
    path.includes("/live-infinitely") ||
    path.includes("/macro-calc") ||
    path.includes("/landing") ||
    path.includes("/cancel") ||
    path.includes("/cancel-thank-you") ||
    path.includes("/a") ||
    path.includes(".")
  ) {
    isIgnore = true;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isIgnore === false ? (
          isLoggedIn === true && isAdmin === true ? (
            <Redirect
              to={{ pathname: "/admin", state: { from: props.location } }}
            />
          ) : isLoggedIn === true && isMember === true ? (
            <Redirect
              to={{ pathname: "/member", state: { from: props.location } }}
            />
          ) : (
            <Component {...props} />
          )
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

const MemberRoute = function ({ component: Component, ...rest }) {
  const { isLoggedIn, isMember, isAdmin, rMode } = checkAuth();
  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn === true && (isMember === true || isAdmin === true) ? (
          rMode ? (
            <Redirect to={{ pathname: "/registration" }} />
          ) : (
            <Component {...props} path={rest.path} />
          )
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
};

const CompanyRoute = function ({ component: Component, ...rest }) {
  const { isLoggedIn, isCompany } = checkAuth();

  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn === true && isCompany === true ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
};

const AdminRoute = function ({ component: Component, ...rest }) {
  const { isAdmin, isMember, isLoggedIn, pageRole, rMode } = checkAuth(
    rest.currentScreen
  );
  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn === true && isAdmin === true ? (
          pageRole === true ? (
            rMode ? (
              <Redirect to={{ pathname: "/registration" }} />
            ) : (
              <Suspense fallback={<div />}>
                <Component {...props} path={rest.path} />
              </Suspense>
            )
          ) : (
            <NotFound code={"401"} />
          )
        ) : isLoggedIn === true && isMember === true ? (
          <Redirect
            to={{ pathname: "/member", state: { from: props.location } }}
          />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
};

const RegistrationRoute = function ({ component: Component, ...rest }) {
  const { isAdmin, isLoggedIn, isMember, rMode } = checkAuth(
    rest.currentScreen
  );
  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn === true && isAdmin === true && rMode === true ? (
          <Component {...props} path={rest.path} />
        ) : isLoggedIn === true && isMember === true ? (
          <Redirect
            to={{ pathname: "/member", state: { from: props.location } }}
          />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
};

export default App;
