import React from "react";
import $ from "jquery";
import { callGetSettings } from "../../../../services/settingService";
import RadioTag from "../../../../components/form/radioTag";

class TaskExport extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      taskId: this.props.taskId,
      fields: [],
      keyFields: Math.random(),
      labelList: [
        { name: "Task", value: "task" },
        { name: "Description", value: "description" },
        { name: "Due Date", value: "due_date" },
        { name: "Priority", value: "priority" },
        { name: "Status", value: "status" },
        { name: "Last Status Change Date", value: "status_date" },
        { name: "Assigned User", value: "assigned_user" },
      ],
    };
  }

  componentDidMount() {
    this.getData();
  }

  async getSettings() {
    let d = await callGetSettings("tasksettings");
    let settings = d.data;
    let taskSettings = settings.task_settings || {};

    let labelList = this.state.labelList;
    let customFields = taskSettings.custom_fields || [];

    customFields.forEach((element) => {
      labelList.push({
        name: element.label,
        value: element.id,
      });
    });

    this.setState({
      labelList: labelList,
      keyFields: Math.random(),
    });
  }

  async getData() {
    await this.getSettings();
  }

  onClickNoAlert = () => {
    $(".modalAlertOverlay.taskExportModal,.modalAlert.taskExportAlert").fadeOut(
      200
    );
  };

  onClickYesAlert = () => {
    $(".modalAlertOverlay.taskExportModal,.modalAlert.taskExportAlert").fadeOut(
      200
    );
    this.props.onSubmit(this.state.fields);
  };

  onClickExport() {
    $(".modalAlertOverlay.taskExportModal,.modalAlert.taskExportAlert").fadeIn(
      200
    );
  }

  onSelectFields(item, id) {
    let fieldList = [];

    Object.keys(item).forEach((obj) => {
      fieldList.push(item[obj].value);
    });

    this.setState({
      fields: fieldList,
    });
  }

  renderExportAlert() {
    return (
      <div>
        <div className="modalAlertOverlay taskExportModal" />
        <div className="modalAlert taskExportAlert">
          <p>Export</p>
          <div className="container-fluid">
            <div className="tabsHolder radioTabs">
              <RadioTag
                onCheckChange={this.onSelectFields.bind(this)}
                labelList={this.state.labelList}
                id={"fields"}
                type={"multiple"}
                key={this.state.keyFields}
                selectedList={this.state.fields}
                selectType={"value"}
              />
            </div>
          </div>
          <div className="gap30" />
          <div className="alertFooterBtns">
            <button
              className="btn btn-default closeModal-No margin-right-10"
              onClick={this.onClickNoAlert}>
              Close
            </button>
            <button
              className="btn btn-primary closeModal-Yes"
              onClick={this.onClickYesAlert}>
              Submit
            </button>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <>
        {this.renderExportAlert()}

        <button
          className="btn btn-default btn-sm margin-right-10"
          onClick={this.onClickExport.bind(this)}>
          Export
        </button>
      </>
    );
  }
}
export default TaskExport;
