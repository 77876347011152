import {
  CREATE_SYSTEM_TAG_SUCCESS,
  CREATE_SYSTEM_TAG_FAILURE,
  GET_SYSTEM_TAG_SUCCESS,
  RESET_STATUS,
  GET_GENERIC_TAG_SUCCESS,
  CREATE_GENERIC_TAG_SUCCESS,
  CREATE_GENERIC_TAG_FAILURE,
  UPDATE_SYSTEM_TAG_SUCCESS,
  UPDATE_SYSTEM_TAG_FAILURE
} from "../types";

const initialState = {
  message: "",
  success: false,
  systemTags: {},
  genericTags: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RESET_STATUS:
      return {
        ...state,
        message: "",
        success: false
      };
    case CREATE_SYSTEM_TAG_SUCCESS:
      return {
        ...state,
        message: action.message,
        success: action.success
      };
    case CREATE_SYSTEM_TAG_FAILURE:
      return {
        ...state,
        message: action.message,
        success: action.success
      };
    case GET_SYSTEM_TAG_SUCCESS:
      return {
        ...state,
        systemTags: action.data
      };
    case UPDATE_SYSTEM_TAG_SUCCESS:
      return {
        ...state,
        message: action.message,
        success: action.success
      };
    case UPDATE_SYSTEM_TAG_FAILURE:
      return {
        ...state,
        message: action.message,
        success: action.success
      };
    case CREATE_GENERIC_TAG_SUCCESS:
      return {
        ...state,
        message: action.message,
        success: action.success
      };
    case CREATE_GENERIC_TAG_FAILURE:
      return {
        ...state,
        message: action.message,
        success: action.success
      };
    case GET_GENERIC_TAG_SUCCESS:
      return {
        ...state,
        genericTags: action.data
      };
    default:
      return state;
  }
};
