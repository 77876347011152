import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import TitleBox from "../../../../components/general/titleBox";

import { callGetDatabases } from "../../../../services/settingService";
import { isDashboardSite } from "../../../../helpers";
import { callGetReports } from "../../../../services";
import moment from "moment-timezone";
import config from "../../../../helpers/config";

class ViewAppleOrdersPage extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      dbs: [],
      items: [],
      itemsBackup: [],
      selectedDb: "",
    };
  }

  componentDidMount() {
    this.getDatabases();
  }

  getDatabases() {
    callGetDatabases().then((d) => {
      this.setState({ dbs: d.data }, () => {
        this.getAppleOrders();
      });
    });
  }

  getDefaultDomain(element) {
    let domainList = element.domain_list;
    let fList = domainList.filter((d) => {
      return d.type === "default";
    });
    if (fList.length > 0) {
      return fList[0].domain;
    } else {
      let fList = domainList.filter((d) => {
        return d.type === "primary";
      });
      if (fList.length > 0) {
        return fList[0].domain;
      }
    }
    return "-";
  }

  renderItems() {
    var returnVals = [];
    let items = this.state.items;
    items.forEach((element) => {
      let appleAmounts = config.appleProducts.filter(
        (e) => e.id === element.apple_amount
      );
      let appleAmount = "";
      if (appleAmounts.length > 0) {
        appleAmount = appleAmounts[0].amount;
      }
      returnVals.push(
        <tr key={element._id}>
          <td>{element.site}</td>
          <td>{element.userName}</td>
          <td>{element.userEmail}</td>
          <td>{element.apple_amount}</td>
          <td>{element.checkoutItemId}</td>
          <td>{appleAmount}</td>
          <td>{moment(element.createdAt).format("Do MMM YYYY")}</td>
          {/* <td></td> */}
        </tr>
      );
    });
    return returnVals;
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState(
      {
        [name]: value,
      },
      () => {
        if (value === "all") {
          this.setState({ items: this.state.itemsBackup });
        } else {
          let items = this.state.itemsBackup.filter((d) => d.db === value);
          this.setState({ items: items });
        }
      }
    );
  }

  async getAppleOrders() {
    this.setState({ showLoader: true });
    let data = {};
    data["type"] = "apple-orders";
    let d = await callGetReports(data);

    this.setState({
      items: d,
      itemsBackup: d,
    });
  }

  render() {
    let isDashboard = isDashboardSite();
    if (!isDashboard) {
      return null;
    }
    return (
      <div className="container-fluid">
        <TitleBox title="Apple Orders" />
        <div className="gap20" />
        <div className="card">
          <div className="list-group list-group-flush">
            <div className="list-group-item">
              <p>Select Site</p>
              <select
                name="selectedDb"
                value={this.state.selectedDb}
                onChange={(event) => this.handleInputChange(event)}>
                <option value={"all"} key={"all"}>
                  All
                </option>
                {this.state.dbs.map((i) => (
                  <option value={i.database_name} key={i.database_name}>
                    {this.getDefaultDomain(i)}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="list-group list-group-flush">
            <div className="list-group-item">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Site</th>
                    <th>Name</th>
                    <th>Email Address</th>
                    <th>Apple Product</th>
                    <th>Checkout Item</th>
                    <th>Amount</th>
                    <th>Date</th>
                    {/* <th>Action</th> */}
                  </tr>
                </thead>
                <tbody>{this.renderItems()}</tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewAppleOrdersPage);
