import * as api from "./api";
import { callApi } from "../helpers";

function callCreateLicense(data) {
  return callApi(api.service("license").create(data));
}

function callUpdateLicense(id, data) {
  return callApi(api.service("license").patch(id, data));
}

function callDeleteLicense(id) {
  return callApi(api.service("license").remove(id));
}

function callGetLicenses() {
  return callApi(api.service("license").find({}));
}

function callGetLicensesByQuery(query) {
  return callApi(api.service("license").find(query));
}

function callCreateCompany(data) {
  return callApi(api.service("company").create(data));
}

function callUpdateCompany(id, data) {
  return callApi(api.service("company").patch(id, data));
}

function callDeleteCompany(id) {
  return callApi(api.service("company").remove(id));
}

function callGetCompanies() {
  return callApi(api.service("company").find({}));
}

function callGetCompanyById(id) {
  return callApi(api.service("company").get(id));
}

function callGetCompaniesByQuery(query) {
  return callApi(api.service("company").find(query));
}

function callCreateCompanyOrder(data) {
  return callApi(api.service("company-order").create(data));
}

function callGetCompanyOrderByQuery(query) {
  return callApi(api.service("company-order").find(query));
}

function callCreateMemberLicense(data) {
  return callApi(api.service("member-license").create(data));
}

function callGetMemberLicensesByQuery(query) {
  return callApi(api.service("member-license").find(query));
}

export {
  callCreateLicense,
  callUpdateLicense,
  callDeleteLicense,
  callGetLicenses,
  callGetLicensesByQuery,
  callCreateCompany,
  callUpdateCompany,
  callDeleteCompany,
  callGetCompanies,
  callGetCompaniesByQuery,
  callCreateCompanyOrder,
  callGetCompanyById,
  callGetCompanyOrderByQuery,
  callCreateMemberLicense,
  callGetMemberLicensesByQuery
};
