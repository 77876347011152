import {
  RESET_STATUS,
  CREATE_EXERCISE_SUCCESS,
  CREATE_EXERCISE_FAILURE,
  UPDATE_EXERCISE_SUCCESS,
  UPDATE_EXERCISE_FAILURE,
  GET_EXERCISE,
  GET_MEMBER_PRODUCT,
  GET_CUSTOM_PROGRAM
} from "../types";

import config from "../../helpers/config";

import {
  callCreateExercise,
  callUpdateExercise,
  callGetExercises,
  callGetMemberProductUsingQuery,
  callGetCustomPrograms
} from "../../services/index";

/*
    This method is used to reset the status
    This will reset message and success states
*/
export const resetStatus = data => {
  return async dispatch => {
    dispatch({
      type: RESET_STATUS
    });
  };
};

export const createExercise = data => {
  return async dispatch => {
    return callCreateExercise(data)
      .then(data => {
        dispatch({
          type: CREATE_EXERCISE_SUCCESS,
          message: config.messages.createChlgQueSuccess,
          success: true
        });
      })
      .catch(err => {
        dispatch({
          type: CREATE_EXERCISE_FAILURE,
          message: config.messages.createChlgQueError,
          success: false
        });
      });
  };
};

export const updateExercise = (id, data) => {
  return async dispatch => {
    return callUpdateExercise(id, data)
      .then(data => {
        dispatch({
          type: UPDATE_EXERCISE_SUCCESS,
          message: config.messages.updateMessage,
          success: true
        });
      })
      .catch(err => {
        dispatch({
          type: UPDATE_EXERCISE_FAILURE,
          message: config.messages.createChlgQueError,
          success: false
        });
      });
  };
};

export const getExercises = () => {
  return async dispatch => {
    return callGetExercises().then(data => {
      dispatch({
        type: GET_EXERCISE,
        data: data
      });
    });
  };
};

export const getMemberProductUsingQuery = query => {
  return async dispatch => {
    return callGetMemberProductUsingQuery(query).then(data => {
      dispatch({
        type: GET_MEMBER_PRODUCT,
        data: data
      });
    });
  };
};

export const getCustomPrograms = () => {
  return async dispatch => {
    return callGetCustomPrograms().then(data => {
      dispatch({
        type: GET_CUSTOM_PROGRAM,
        data: data
      });
    });
  };
};
