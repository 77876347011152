import React from "react";
import { connect } from "react-redux";
import { goBack } from "react-router-redux";
import { bindActionCreators } from "redux";
import * as $ from "jquery";
import PubSub from "pubsub-js";
import { Scrollbars } from "react-custom-scrollbars";
import { VelocityTransitionGroup } from "velocity-react";

import { getItem, removeItem, setItem } from "../../helpers/storage";
import {
  callGetProcessesUsingQuery,
  callGetHelpItemsFromMemberPagesDb,
} from "../../services";
import { loggedInAsUser, sanitizer } from "../../helpers";

import { refreshCurrentUser } from "../../modules/actions/index";

import ContentHtml from "./contentHtml";

class TitleBox extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    let viewUser = getItem("viewUser");

    this.isProcess = false;

    this.state = {
      history: this.props.history,
      title: this.props.title,
      showBackBtn: this.props.showBackBtn || false,
      refreshPage: this.props.refreshPage || false,
      viewUser: viewUser ? true : false,
      processInfo: {},
      showProcessPopup: false,
      helpTextOn: false,
      isAdmin: false,
      itemInfo: {},
      showItemInfo: false,
      showHelpItems:
        this.props.showHelpItems !== undefined
          ? this.props.showHelpItems
          : true,
      titleComponent: this.props.titleComponent,
      showExitLogin:
        this.props.showExitLogin !== undefined
          ? this.props.showExitLogin
          : true,
    };
  }

  componentDidMount() {
    if (this.state.history) {
      this.checkForProcess(this.state.history);
    }

    this.subscribe = PubSub.subscribe("clickHelpItems", (key, run) => {
      this.onClickShowHelpItems();
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.history) {
      this.setState({ history: nextProps.history }, () => {
        this.checkForProcess(nextProps.history);
      });
    }
  }

  async checkForProcess(history) {
    if (!this.isProcess && history.location) {
      this.isProcess = true;
      let path = history.location.pathname;
      if (path.includes("/admin")) {
        /*eslint-disable*/
        let query = {
          query: {
            page_list: { $in: [path] },
            status: true,
          },
        };

        this.setState({ isAdmin: true });

        /*eslint-enable*/

        let processes = await callGetProcessesUsingQuery(query, false);

        if (processes.data.length > 0) {
          let processInfo = processes.data[0];
          this.setState({ processInfo: processInfo });
        }
      }
    }
  }

  async getHelpItem() {
    let path = this.state.history.location.pathname;
    if (path.includes("/admin")) {
      /*eslint-disable*/
      let query = {
        query: {
          page_list: { $in: [path] },
          status: true,
        },
      };

      this.setState({ isAdmin: true });

      /*eslint-enable*/

      let items = await callGetHelpItemsFromMemberPagesDb(query, false);

      if (items.data.length > 0) {
        let itemInfo = items.data[0];
        this.setState({ itemInfo: itemInfo, showItemInfo: true });
      }
    }
  }

  onClickInfo() {
    this.setState({ showProcessPopup: true }, () => {
      this.showPopup();
    });
  }

  showPopup() {
    $(
      ".modalAlertOverlay.viewProcessContent,.modalAlert.viewProcessContentAlert"
    ).fadeIn(200);
  }

  hidePopup() {
    $(
      ".modalAlertOverlay.viewProcessContent,.modalAlert.viewProcessContentAlert"
    ).fadeOut(200);
  }

  onClickCloseFromModal() {
    this.setState({ showProcessPopup: false }, () => {
      this.hidePopup();
    });
  }

  onClickBackBtn() {
    PubSub.publish("refreshChallenge", "");
    this.props.goBack();
    // if (this.state.refreshPage) {
    //   setTimeout(() => {
    //     window.location.reload();
    //   }, 1000);
    // }
  }

  onClickExitViewMode() {
    removeItem("viewUser");
    loggedInAsUser();
  }

  async onClickShowHelpItems() {
    PubSub.publish("showHelpItems", "");
    setItem("helpTextOn", true);
    await this.getHelpItem();
    this.setState({
      helpTextOn: true,
    });
  }

  onClickHideHelpItems() {
    PubSub.publish("hideHelpItems", "");
    setItem("helpTextOn", false);
    this.setState({
      helpTextOn: false,
      showItemInfo: false,
    });
  }

  onClickInfoIcon() {
    PubSub.publish("tutorialRun", "");
  }

  renderProcessContent() {
    if (!this.state.processInfo._id) {
      return null;
    }
    let modalHeight = window.innerHeight - 250;
    return (
      <div>
        <div
          className="modalAlertOverlay viewProcessContent"
          onClick={this.onClickCloseFromModal.bind(this)}
        />
        <div className="modalAlert viewProcessContentAlert autoWidthModal">
          <p className="firstLetter"> {this.state.processInfo.title}</p>
          <div
            className="container-fluid modalInnerBody"
            style={{ maxHeight: modalHeight, overflowY: "hidden" }}>
            <Scrollbars
              autoHide
              autoHideTimeout={1000}
              autoHideDuration={200}
              style={{ height: modalHeight }}>
              <div className="list-group-item">
                <div className="gap10" />
                <p
                  dangerouslySetInnerHTML={{
                    __html: sanitizer(this.state.processInfo.html),
                  }}
                />
              </div>
            </Scrollbars>
          </div>

          <div className="alertFooterBtns">
            {/*eslint-disable-next-line*/}
            <a
              className="btn btn-primary dismissThisModal"
              onClick={this.onClickCloseFromModal.bind(this)}>
              Close
            </a>
          </div>
        </div>
      </div>
    );
  }

  renderHelpLabelButton(havingBackButton) {
    if (!this.state.showHelpItems) {
      return;
    }
    if (!this.state.isAdmin) {
      return null;
    }
    return (
      <button
        className={
          havingBackButton
            ? "btn btn-default removeFocusDecoration margin-right-10"
            : "btn btn-default removeFocusDecoration"
        }
        onClick={() =>
          this.state.helpTextOn
            ? this.onClickHideHelpItems()
            : this.onClickShowHelpItems()
        }>
        {this.state.helpTextOn ? "Hide Help Items" : "Show Help Items"}
      </button>
    );
  }

  render() {
    let isProcess = this.state.processInfo._id ? true : false;
    if (this.props.showInfoIcon === false) {
      isProcess = false;
    }
    let historyLength = this.props.history.length;
    let showTasks = false;
    let location = this.props.history.location;
    if (location) {
      showTasks = location.pathname.indexOf("/admin/task-detail") > -1;
    }

    return (
      <div>
        {this.state.showProcessPopup && this.renderProcessContent()}
        <div className="clearfix">
          <div className="pull-left">
            {this.state.title && (
              <h3 className="noMargin firstLetter">
                {this.state.title}{" "}
                {isProcess && (
                  <i
                    className="fa fa-info-circle pointer-click"
                    onClick={this.onClickInfo.bind(this)}
                  />
                )}
              </h3>
            )}
            {this.state.titleComponent !== undefined && (
              <>{this.state.titleComponent}</>
            )}
          </div>

          {this.state.showBackBtn ? (
            <div
              className={
                this.state.title.length > 17
                  ? "backButton abc"
                  : "backButtonAlt"
              }>
              {this.renderHelpLabelButton(this.state.showBackBtn)}

              {historyLength > 3 && (
                <button
                  className="btn btn-primary"
                  onClick={this.onClickBackBtn.bind(this)}>
                  <i className="fa fa-arrow-left" />
                  &nbsp;&nbsp;Back
                </button>
              )}
              {historyLength <= 2 && showTasks && (
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    this.props.history.push("/admin/tasks");
                  }}>
                  <i className="fa fa-arrow-left" />
                  &nbsp;&nbsp; Tasks
                </button>
              )}
            </div>
          ) : (
            <div className="text-right">
              {this.renderHelpLabelButton(this.state.showBackBtn)}
            </div>
          )}

          {this.state.viewUser && this.state.showExitLogin && (
            <div className={"backButton"} style={{ marginRight: 15 }}>
              <button
                className="btn btn-danger"
                onClick={this.onClickExitViewMode.bind(this)}>
                <i className="fa fa-sign-out" />
                &nbsp;&nbsp;Exit Login
              </button>
            </div>
          )}
        </div>
        <VelocityTransitionGroup
          enter={{ animation: "slideDown" }}
          leave={{ animation: "slideUp" }}>
          {this.state.showItemInfo && (
            <div>
              <div className="gap20" />
              <div className="card">
                <ContentHtml html={this.state.itemInfo.html} type="iframe" />
              </div>
            </div>
          )}
        </VelocityTransitionGroup>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  history: state.authReducer.history,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      refreshCurrentUser,
      goBack: () => goBack(),
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(TitleBox);
