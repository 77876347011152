import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import FilterList from "../../../../components/form/filterList";
import TitleBox from "../../../../components/general/titleBox";
import { filterByTags } from "../../../../helpers/filters";

import { getEmails } from "../../../../modules/actions/index";
import { callGetReports } from "../../../../services";

class EmailReports extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      emails: [],
      emailsBackup: [],
      emailHistories: [],
      emailActions: [],
      filterList: [],
      selectedEmail: {},
      emailCategories: [
        { name: "All", value: "all", selected: true },
        { name: "Automated", value: "automated", selected: false },
        { name: "One Time", value: "onetime", selected: false },
      ],
      emailTypes: [
        { name: "All", value: "all", selected: true },
        { name: "Marketing", value: "marketing", selected: false },
        { name: "Challenge", value: "challenge", selected: false },
        { name: "System", value: "system", selected: false },
      ],
    };
  }

  componentDidMount() {
    this.props.getEmails();
    this.callGetEmailStats();
  }

  callGetEmailStats() {
    let data = {};
    data["type"] = "email-stats";
    data["action"] = "all";
    callGetReports(data).then((d) => {
      this.setState({
        emailHistories: d.email_histories,
        emailActions: d.email_actions,
      });
    });
  }

  componentWillReceiveProps(newProps) {
    if (newProps.emails.data) {
      this.setState({
        emails: newProps.emails.data,
        emailsBackup: newProps.emails.data,
      });
    }
  }

  filterUpdated(updatedFilter, name, type, searchFields) {
    let filterList = this.state.filterList;
    filterList[name] = {
      name: name,
      type: type,
      searchFields: searchFields,
      values: updatedFilter,
    };
    this.setState(
      {
        filterList: filterList,
      },
      () => {
        let data = filterByTags(this.state.emailsBackup, filterList);
        this.setState({
          emails: data,
        });
      }
    );
  }

  getCount(type, emailId) {
    let emailHistories = this.state.emailHistories;
    let emailActions = this.state.emailActions;
    switch (type) {
      case "sent":
        let fEmail = emailHistories.filter((d) => {
          return d.email_id === emailId;
        });
        if (fEmail.length > 0) {
          return fEmail[0].count;
        }
        break;
      case "open":
      case "click":
        let fEmailActions = emailActions.filter((d) => {
          return d.email_id === emailId && d.action === type;
        });
        if (fEmailActions.length > 0) {
          return fEmailActions[0].count;
        }
        break;

      default:
        break;
    }
    return 0;
  }

  onClickViewLinkReport(email) {
    this.props.history.push("/admin/reports/email-links", {
      email: email,
    });
  }

  renderEmails() {
    var returnVals = [];
    let emails = this.state.emails;
    emails.forEach((element) => {
      returnVals.push(
        <tr key={element._id}>
          <td>{element.name}</td>
          <td style={{ width: "20%" }}>{element.description}</td>
          <td>{element.type}</td>
          <td>{element.email_type || ""}</td>
          <td>{this.getCount("sent", element._id)}</td>
          <td>{this.getCount("open", element._id)}</td>
          <td>{this.getCount("click", element._id)}</td>
          <td>
            <button
              className="btn btn-default btn-sm border-0"
              onClick={this.onClickViewLinkReport.bind(this, element)}>
              <i className="fa fa-eye" />
            </button>
          </td>
        </tr>
      );
    });
    return returnVals;
  }

  render() {
    return (
      <div className="container-fluid">
        <TitleBox title="Email Reports" showBackBtn={true} />
        <div className="gap20" />
        <div className="card">
          <div className="list-group list-group-flush">
            <div className="list-group-item">
              <FilterList
                label={"Search Emails"}
                name={"search"}
                type={"search"}
                searchFields={["name", "description", "title", "html"]}
                onFinish={this.filterUpdated.bind(this)}
              />
            </div>
            <div className="list-group-item">
              <p>Select a category</p>
              <FilterList
                filterList={this.state.emailCategories}
                name={"searchCategory"}
                type={"single"}
                searchFields={["type"]}
                onFinish={this.filterUpdated.bind(this)}
              />
            </div>
            <div className="list-group-item">
              <p>Select a type</p>
              <FilterList
                filterList={this.state.emailTypes}
                name={"searchType"}
                type={"single"}
                searchFields={["email_type"]}
                onFinish={this.filterUpdated.bind(this)}
              />
            </div>
            <div className="list-group-item">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Email Name</th>
                    <th>Description</th>
                    <th>Category</th>
                    <th>Type</th>
                    <th>Emails Sent</th>
                    <th>Emails Opened</th>
                    <th>Links Clicked</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>{this.renderEmails()}</tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  emails: state.emailReducer.emails,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getEmails,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(EmailReports);
