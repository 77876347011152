import React from "react";
import RadioTag from "../../../../components/form/radioTag";
import Checkbox from "../../../../components/form/checkBox";
import {
  callCreateGenericTag,
  callGetGenericTags,
  callUpdateGenericTag,
} from "../../../../services";
import { VelocityTransitionGroup } from "velocity-react";
import { showNotification } from "../../../../helpers";
import $ from "jquery";
import {
  SortableFieldOptionList,
  SortableTaskFieldList,
} from "../../../../components/form/sortableList";
import { arrayMove } from "react-sortable-hoc";
import { DraftEditor } from "../../../../packages/draft-editor";

class TaskSettingCustomFields extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      customFields: this.props.customFields,
      fieldLabel: "",
      fieldListName: "",
      fieldType: "single",
      isDisplay: false,
      allowFilter: false,
      filterType: "single",
      isRequired: false,
      isEdit: false,
      isVisible: false,
      searchDuplicates: false,
      keySearchDuplicates: Math.random(),
      keyAllowFilter: Math.random(),
      keyIsRequired: Math.random(),
      keyIsDisplay: Math.random(),
      keyIsEdit: Math.random(),
      keyIsVisible: Math.random(),
      selectedFieldIndex: -1,
      options: [],
      fieldId: "",
      optionLabel: "",
      optionId: "",
      optionSelected: false,
      keyOptionSelected: Math.random(),
      optionCustomerSelect: false,
      keyOptionCustomerSelect: Math.random(),
      optionIsNote: false,
      optionNote: "",
      optionNoteJson: {},
      keyOptionIsNote: Math.random(),
      selectedOptionIndex: -1,
      keyRadioSelect: Math.random(),
      category: "",
      keyCategory: Math.random(),
      categories: [],
      showCreateCategory: false,
      categoryName: "",
    };
  }

  componentDidMount() {
    this.getData();
  }

  async getData() {
    await this.getCategories();
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  getCategories() {
    callGetGenericTags("taskfields").then((d) => {
      this.setState({ categories: d.data, keyCategory: Math.random() });
      if (d.data.length === 0) {
        this.setState({
          category: "Create New Category",
          keyCategory: Math.random(),
          showCreateCategory: true,
        });
      }
    });
  }

  onSelectRadioSelect(item, id) {
    let value = item[Object.keys(item)[0]].value;
    this.setState({ [id]: value });
  }

  toggleIsDisplay = (check) => {
    this.setState({ isDisplay: check });
  };

  toggleAllowFilter = (check) => {
    this.setState({ allowFilter: check });
  };

  toggleSearchDuplicates = (check) => {
    this.setState({ searchDuplicates: check });
  };

  toggleIsRequired = (check) => {
    this.setState({ isRequired: check });
  };

  toggleIsEdit = (check) => {
    this.setState({ isEdit: check });
  };

  toggleIsVisible = (check) => {
    this.setState({ isVisible: check });
  };

  toggleOptionSelected = (check) => {
    this.setState({ optionSelected: check });
  };

  toggleOptionCustomerSelect = (check) => {
    this.setState({ optionCustomerSelect: check });
  };

  toggleOptionIsNote = (check) => {
    this.setState({ optionIsNote: check });
  };

  onSelectItem(item, id) {
    this.setState({
      [id]: item[Object.keys(item)[0]].value,
      showCreateCategory:
        item[Object.keys(item)[0]].value === "Create New Category"
          ? true
          : false,
      keyCategory: Math.random(),
    });
  }

  async onClickSubmitCategory() {
    let categoryName = this.state.categoryName.trim();

    if (categoryName.trim().length === 0) {
      showNotification("error", "Category name is required", 4000);
      return;
    }
    let existingType = this.state.categories.filter((d) => {
      return d.name.toLowerCase() === categoryName.toLowerCase();
    });
    if (existingType.length > 0) {
      showNotification("error", "Category already exists");
      return;
    }

    let data = [{ name: this.state.categoryName, type: "taskfields" }];

    let response = await callCreateGenericTag(data);
    if (response.length > 0) {
      this.setState({ categoryName: "", showCreateCategory: false }, () => {
        this.getCategories();
        this.setState({ category: response[0].id, keyCategory: Math.random() });
      });
    }
  }

  onClickCancelFromUpdateCategoryModal() {
    $(
      ".modalAlertOverlay.updateCategoryModalOverlay,.modalAlert.updateCategoryAlert"
    ).fadeOut(200);
  }

  async onClickUpdateFromUpdateCategoryModel() {
    if (this.state.categoryName.trim().length === 0) {
      showNotification("error", "Cagtegory name is required", 4000);
      return;
    }
    let updatedCategory = await callUpdateGenericTag(this.state.category, {
      name: this.state.categoryName,
    });
    if (updatedCategory._id) {
      this.getCategories();
      $(
        ".modalAlertOverlay.updateCategoryModalOverlay,.modalAlert.updateCategoryAlert"
      ).fadeOut(200);
    }
  }

  onClickEditCategory() {
    let selectedItemType = this.state.categories.find(
      (d) => d._id === this.state.category
    );
    this.setState({ categoryName: selectedItemType.name }, () => {
      $(
        ".modalAlertOverlay.updateCategoryModalOverlay,.modalAlert.updateCategoryAlert"
      ).fadeIn(200);
    });
  }

  renderUpdateCategoryModal() {
    let category = this.state.category;

    if (!category) {
      return null;
    }

    return (
      <div>
        <div className="modalAlertOverlay updateCategoryModalOverlay" />
        <div className="modalAlert updateCategoryAlert">
          <div className="clearfix">
            <div className="pull-left">
              <p>Edit Category</p>
            </div>
          </div>

          <div className="container-fluid">
            <div>
              <div
                className={
                  this.state.categoryName !== ""
                    ? "mdInput mdFocussed"
                    : "mdInput"
                }>
                <label>Category Name</label>
                <input
                  type="text"
                  name="categoryName"
                  value={this.state.categoryName}
                  onChange={(event) => this.handleInputChange(event)}
                />
              </div>
              <div className="gap20" />
            </div>
          </div>
          <div className="gap20" />
          <div className="alertFooterBtns">
            <button
              onClick={this.onClickCancelFromUpdateCategoryModal.bind(this)}
              className="btn btn-default dismissThisModal margin-right-10">
              Cancel
            </button>
            <button
              onClick={this.onClickUpdateFromUpdateCategoryModel.bind(this)}
              className="btn btn-primary dismissThisModal">
              Update
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderCategoryComponent() {
    let categories = this.state.categories;
    let labelList = [];
    categories.forEach((element) => {
      labelList.push({
        name: element.name,
        value: element._id,
      });
    });
    labelList.push({
      name: "Create New Category",
      value: "Create New Category",
    });
    return (
      <>
        <div className="card">
          <div className="container-fluid">
            {labelList.length > 0 && (
              <div className="row" key={this.state.keyCategory}>
                <div className="col-md-12">
                  <div className="gap10" />
                  <label>Select Category</label>
                  <div className="tabsHolder radioTabs">
                    <RadioTag
                      onCheckChange={this.onSelectItem.bind(this)}
                      labelList={labelList}
                      id={"category"}
                      selectedList={[this.state.category]}
                      selectType={"value"}
                    />
                  </div>
                  <div className="gap20" />
                </div>
              </div>
            )}

            <VelocityTransitionGroup
              enter={{ animation: "slideDown" }}
              leave={{ animation: "slideUp" }}>
              {this.state.showCreateCategory && (
                <>
                  <div className="row">
                    <div className="col-sm-4">
                      <div
                        className={
                          this.state.categoryName !== ""
                            ? "mdInput mdFocussed"
                            : "mdInput"
                        }>
                        <label>Category Name</label>
                        <input
                          type="text"
                          name="categoryName"
                          value={this.state.categoryName}
                          onChange={(event) => this.handleInputChange(event)}
                        />
                      </div>
                      <div className="gap20" />
                    </div>
                    <div className="col-sm-4">
                      <div className="gap30" />
                      <div className="gap10" />
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={this.onClickSubmitCategory.bind(this)}>
                        Create Item Category
                      </button>
                    </div>
                  </div>
                </>
              )}
              {this.state.category !== "" && !this.state.showCreateCategory && (
                <button
                  className="btn btn-sm btn-primary"
                  onClick={this.onClickEditCategory.bind(this)}>
                  Edit Selected Category
                </button>
              )}
              <div className="gap10" />
            </VelocityTransitionGroup>
          </div>
        </div>
      </>
    );
  }

  onSortEndFields = ({ oldIndex, newIndex }) => {
    let customFields = this.state.customFields;
    customFields = arrayMove(customFields, oldIndex, newIndex);

    this.setState({
      customFields: customFields,
    });
  };

  onPressRemoveField(e) {
    let index = e.target.dataset["value"];
    let customFields = this.state.customFields;
    customFields.splice(index, 1);
    this.setState({
      customFields: customFields,
    });
  }

  onPressEditField(e) {
    let index = e.target.dataset["value"];
    let customFields = this.state.customFields;

    let customField = customFields[index];

    this.setState({
      options: customField.options,
      selectedFieldIndex: index,
      fieldLabel: customField.label,
      fieldType: customField.type || "single",
      fieldListName: customField.name || "",
      fieldId: customField.id,
      isDisplay: customField.display,
      keyIsDisplay: Math.random(),
      keyRadioSelect: Math.random(),
      isRequired: customField.required,
      keyIsRequired: Math.random(),
      isEdit: customField.edit,
      keyIsEdit: Math.random(),
      isVisible: customField.visible,
      keyIsVisible: Math.random(),
      category: customField.category || "",
      keyCategory: Math.random(),
      allowFilter: customField.filter,
      keyAllowFilter: Math.random(),
      filterType: customField.filterType || "single",
      searchDuplicates: customField.duplicates,
      keySearchDuplicates: Math.random(),
    });
  }

  onClickCancelField() {
    this.setState({
      selectedFieldIndex: -1,
      fieldLabel: "",
      options: [],
      fieldId: "",
      isDisplay: false,
      fieldListName: "",
      isRequired: false,
      isEdit: false,
      isVisible: false,
      keyIsVisible: Math.random(),
      keyIsRequired: Math.random(),
      keyIsDisplay: Math.random(),
      keyRadioSelect: Math.random(),
      keyIsEdit: Math.random(),
      allowFilter: false,
      keyAllowFilter: Math.random(),
      searchDuplicates: false,
      keySearchDuplicates: Math.random(),
    });
  }

  renderFieldList() {
    return (
      <>
        <div className="text-right">
          <button
            className="btn btn-primary addQuestionButton"
            onClick={() => this.onClickAddField()}>
            Add Field
          </button>
        </div>
        <div className="gap20" />

        <SortableTaskFieldList
          distance={10}
          task={true}
          items={this.state.customFields}
          onSortEnd={this.onSortEndFields}
          onPressEdit={this.onPressEditField.bind(this)}
          onPressRemove={this.onPressRemoveField.bind(this)}
        />
        <div className="gap20" />
      </>
    );
  }

  onSortEndOptions = ({ oldIndex, newIndex }) => {
    let options = this.state.options;
    options = arrayMove(options, oldIndex, newIndex);

    this.setState({
      options: options,
    });
  };

  onPressRemoveOption(e) {
    let index = e.target.dataset["value"];
    let options = this.state.options;
    options.splice(index, 1);
    this.setState({
      options: options,
    });
  }

  onPressEditOption(e) {
    let index = e.target.dataset["value"];
    let options = this.state.options;

    let option = options[index];

    this.setState({
      selectedOptionIndex: index,
      optionLabel: option.label,
      optionId: option.id,
      optionSelected: option.selected,
      optionCustomerSelect: option.customerSelect,
      keyOptionCustomerSelect: Math.random(),
      keyOptionSelected: Math.random(),
      keyOptionIsNote: Math.random(),
      optionNote: option.note || "",
      optionNoteJson: option.noteJson || {},
      optionIsNote: option.isNote,
    });
  }

  onClickCancelFormField() {
    this.setState({
      selectedOptionIndex: -1,
      optionLabel: "",
      optionId: "",
      optionSelected: false,
      keyOptionSelected: Math.random(),
      optionCustomerSelect: false,
      keyOptionCustomerSelect: Math.random(),
    });
  }

  onClickAddOption() {
    let options = this.state.options;

    if (this.state.optionLabel.trim().length === 0) {
      showNotification("error", "Label is required", 4000);
      return;
    }

    let id = Math.random().toString(36).substr(2);

    let option = {
      label: this.state.optionLabel,
      id: id,
      showEdit: true,
      selected: this.state.optionSelected,
      customerSelect: this.state.optionCustomerSelect,
      isNote: this.state.optionIsNote,
      note: this.state.optionNote,
      noteJson: this.state.optionNoteJson,
    };

    if (this.state.selectedOptionIndex >= 0) {
      option.id = this.state.optionId;
      options[this.state.selectedOptionIndex] = option;
    } else {
      options.push(option);
    }

    this.setState({
      options: options,
      selectedOptionIndex: -1,
      optionLabel: "",
      optionId: "",
      optionCustomerSelect: false,
      keyOptionCustomerSelect: Math.random(),
      optionIsNote: false,
      optionNote: "",
      optionNoteJson: {},
      keyOptionIsNote: Math.random(),
    });
  }

  isRenderSelectedOptionCheck() {
    let check = false;
    let arrTemp = this.state.options.filter((d) => {
      return d.selected === true;
    });
    check = arrTemp.length > 0 ? false : true;

    if (this.state.selectedOptionIndex >= 0) {
      let selectedOption = this.state.options[this.state.selectedOptionIndex];
      if (selectedOption && selectedOption.selected) {
        check = true;
      }
    }

    return check;
  }

  onClickAddField() {
    let customFields = this.state.customFields;

    if (this.state.selectedOptionIndex >= 0) {
      showNotification("error", "Please save current option", 4000);
      return;
    }

    if (this.state.fieldLabel.trim().length === 0) {
      showNotification("error", "Label is required", 4000);
      return;
    }

    if (this.state.isDisplay) {
      if (this.state.fieldListName.trim().length === 0) {
        showNotification("error", "Task List Name is required", 4000);
        return;
      }
    }

    let id = Math.random().toString(36).substr(2);

    let field = {
      label: this.state.fieldLabel,
      name: this.state.fieldListName,
      options: this.state.options,
      display: this.state.isDisplay,
      required: this.state.isRequired,
      edit: this.state.isEdit,
      visible: this.state.isVisible,
      type: this.state.fieldType,
      id: id,
      category: this.state.category,
      filter: this.state.allowFilter,
      filterType: this.state.filterType,
      duplicates: this.state.searchDuplicates,
    };

    if (this.state.selectedFieldIndex >= 0) {
      field.id = this.state.fieldId;
      customFields[this.state.selectedFieldIndex] = field;
    } else {
      customFields.push(field);
    }

    this.setState({
      customFields: customFields,
      selectedFieldIndex: -1,
      fieldLabel: "",
      fieldListName: "",
      fieldType: "single",
      isDisplay: false,
      keyIsDisplay: Math.random(),
      isRequired: false,
      keyIsRequired: Math.random(),
      isEdit: false,
      keyIsEdit: Math.random(),
      isVisible: false,
      keyIsVisible: Math.random(),
      fieldId: "",
      options: [],
      category: "",
      allowFilter: false,
      filterType: "single",
      keyAllowFilter: Math.random(),
      keyRadioSelect: Math.random(),
      keySearchDuplicates: Math.random(),
      searchDuplicates: false,
    });
  }

  renderAddOptionSection() {
    let type = this.state.fieldType;
    return (
      <>
        {type !== "text" && type !== "editor" && (
          <div className="card">
            <div className="container-fluid">
              <div className="gap20" />
              <h5 className="noMargin">{this.state.fieldLabel} Options</h5>
              <div className="gap20" />

              <div className="row">
                <div className="col-md-6">
                  <div
                    className={
                      this.state.optionLabel !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>Label</label>
                    <input
                      type="text"
                      className="offsetDays"
                      name="optionLabel"
                      value={this.state.optionLabel}
                      onChange={(event) => this.handleInputChange(event)}
                    />
                  </div>
                  <div className="gap20" />
                </div>
              </div>
              <div className="gap10" />
              {this.isRenderSelectedOptionCheck() && (
                <Checkbox
                  label={"Selected by default"}
                  isChecked={this.state.optionSelected}
                  handleCheckboxChange={this.toggleOptionSelected.bind(this)}
                  key={this.state.keyOptionSelected}
                />
              )}
              {this.state.isEdit && (
                <>
                  <div className="gap20" />
                  <Checkbox
                    label={"Allow Customer to select this option"}
                    isChecked={this.state.optionCustomerSelect}
                    handleCheckboxChange={this.toggleOptionCustomerSelect.bind(
                      this
                    )}
                    key={this.state.keyOptionCustomerSelect}
                  />
                </>
              )}
              <div className="gap20" />
              <Checkbox
                label={"Add Custom Note"}
                isChecked={this.state.optionIsNote}
                handleCheckboxChange={this.toggleOptionIsNote.bind(this)}
                key={this.state.keyOptionIsNote}
              />
              {this.state.optionIsNote && (
                <div>
                  <div className="gap30" />
                  <div className="add-task-comment">
                    <DraftEditor
                      cType="task"
                      defaultText=""
                      textAlign={"left"}
                      lineHeight={"inherit"}
                      html={this.state.optionNote}
                      json={this.state.optionNoteJson}
                      onChange={(html, json) =>
                        this.setState({
                          optionNote: html,
                          optionNoteJson: json,
                        })
                      }
                    />
                  </div>
                  <div className="gap20" />
                </div>
              )}
              <div className="text-right">
                {this.state.selectedOptionIndex >= 0 && (
                  <button
                    className="btn btn-default margin-right-10"
                    onClick={() => this.onClickCancelFormField()}>
                    Cancel
                  </button>
                )}
                <button
                  className="btn btn-primary addQuestionButton"
                  onClick={() => this.onClickAddOption()}>
                  {this.state.selectedOptionIndex >= 0
                    ? "Update Option"
                    : "Add Option"}
                </button>
              </div>
              <div className="gap20" />
              <SortableFieldOptionList
                distance={10}
                items={this.state.options}
                onSortEnd={this.onSortEndOptions}
                onPressEdit={this.onPressEditOption.bind(this)}
                onPressRemove={this.onPressRemoveOption.bind(this)}
              />
              <div className="gap20" />
            </div>
          </div>
        )}
        <div className="gap10" />
        <div className="text-right">
          <button
            className="btn btn-default margin-right-10"
            onClick={() => this.onClickCancelField()}>
            Cancel
          </button>
          <button
            className="btn btn-primary addQuestionButton"
            onClick={() => this.onClickAddField()}>
            Save Field
          </button>
        </div>
        <div className="gap20" />
      </>
    );
  }

  onClickSaveInfo() {
    this.props.onClickSaveInfo({
      customFields: this.state.customFields,
    });
  }

  render() {
    return (
      <div className="card">
        <div className="container-fluid">
          <div className="gap20" />

          <div className="havingHelpText">
            <h5 className="noMargin">Custom Fields</h5>
          </div>

          <div className="gap20" />

          <div className="row">
            <div className="col-md-8">
              <div
                className={
                  this.state.fieldLabel !== ""
                    ? "mdInput mdFocussed"
                    : "mdInput"
                }>
                <label>Label</label>
                <input
                  type="text"
                  className="offsetDays"
                  name="fieldLabel"
                  value={this.state.fieldLabel}
                  onChange={(event) => this.handleInputChange(event)}
                />
              </div>
              <div className="gap20" />
              <label>Type</label>
              <div className="tabsHolder radioTabs">
                <RadioTag
                  onCheckChange={this.onSelectRadioSelect.bind(this)}
                  labelList={[
                    { name: "Single Select", value: "single" },
                    { name: "Multiple Select", value: "multiple" },
                    { name: "Text", value: "text" },
                    { name: "Large Text Area", value: "editor" },
                    { name: "Dropdown", value: "dropdown" },
                  ]}
                  key={this.state.keyRadioSelect}
                  id={"fieldType"}
                  selectType={"value"}
                  selectedList={[this.state.fieldType]}
                />
              </div>
              <div className="gap20" />
              <Checkbox
                label={"Visible to Customer"}
                isChecked={this.state.isVisible}
                handleCheckboxChange={this.toggleIsVisible}
                key={this.state.keyIsVisible}
              />
              <div className="gap20" />
              {this.state.isVisible && (
                <>
                  <Checkbox
                    label={"Allow Customer to edit this field"}
                    isChecked={this.state.isEdit}
                    handleCheckboxChange={this.toggleIsEdit}
                    key={this.state.keyIsEdit}
                  />
                  <div className="gap20" />
                </>
              )}
              <Checkbox
                label={"Is Required?"}
                isChecked={this.state.isRequired}
                handleCheckboxChange={this.toggleIsRequired}
                key={this.state.keyIsRequired}
              />
              <div className="gap20" />
              <Checkbox
                label={"Display inside Task List"}
                isChecked={this.state.isDisplay}
                handleCheckboxChange={this.toggleIsDisplay}
                key={this.state.keyIsDisplay}
              />

              {this.state.fieldType === "single" && (
                <>
                  <div className="gap20" />
                  <Checkbox
                    label={"Allow Filter"}
                    isChecked={this.state.allowFilter}
                    handleCheckboxChange={this.toggleAllowFilter}
                    key={this.state.keyAllowFilter}
                  />

                  {this.state.allowFilter && (
                    <>
                      <div className="gap20" />
                      <div className="tabsHolder radioTabs">
                        <RadioTag
                          onCheckChange={this.onSelectRadioSelect.bind(this)}
                          labelList={[
                            { name: "Single", value: "single" },
                            { name: "Multiple", value: "multiple" },
                          ]}
                          key={this.state.keyRadioSelect}
                          id={"filterType"}
                          selectType={"value"}
                          selectedList={[this.state.filterType]}
                        />
                      </div>
                    </>
                  )}
                </>
              )}
              <div className="gap20" />
              <Checkbox
                label={"Search for Duplicates"}
                isChecked={this.state.searchDuplicates}
                handleCheckboxChange={this.toggleSearchDuplicates}
                key={this.state.keySearchDuplicates}
              />
              <div className="gap20" />
              {this.state.isDisplay && (
                <div
                  className={
                    this.state.fieldListName !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Task List Name</label>
                  <input
                    type="text"
                    maxLength={30}
                    max={30}
                    className="offsetDays"
                    name="fieldListName"
                    value={this.state.fieldListName}
                    onChange={(event) => this.handleInputChange(event)}
                  />
                </div>
              )}
              <div className="gap20" />
              {this.renderCategoryComponent()}
            </div>
          </div>

          {this.state.selectedFieldIndex === -1 && this.renderFieldList()}
          {this.state.selectedFieldIndex > -1 && this.renderAddOptionSection()}

          {this.state.selectedFieldIndex === -1 && (
            <div className="text-right">
              <button
                className="btn btn-primary"
                onClick={this.onClickSaveInfo.bind(this)}>
                Save Custom Fields
              </button>
              <div className="gap10" />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default TaskSettingCustomFields;
