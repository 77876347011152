import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import TitleBox from "../../../components/general/titleBox";
import { showNotification, toPromise } from "../../../helpers";
import {
  callCreateLead,
  callGetGoogleCalendarList,
  callGetUserById,
  callGetUsersByQuery,
  callUpdateLead,
} from "../../../services";
import TypeableSelect from "../../../components/form/typeableSelect";
import RadioTag from "../../../components/form/radioTag";

import LeadCustomFields from "./customFields";
import { getItem } from "../../../helpers/storage";
import Checkbox from "../../../components/form/checkBox";
import config from "../../../helpers/config";
import AutoSuggestAPI from "../../../components/form/autoSuggestAPI";
import SelectDateTime from "../../../components/form/selectDateTime";

class AddLeadPage extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    let lead = {
      leadId: 0,
      name: "",
      email: "",
      phone: "",
      status: "follow",
      customFields: [],
      customFieldValues: [],
      statusHistory: [],
      followDate: "",
      notes: "",
      sendEmailNotification: false,
      sendSmsNotification: false,
      notiStatus: "pending",
      notiTime: "0",
      calendarId: "default",
      calendarUser: "",
      calendarDuration: "15",
      timezone: "America/Denver",
    };

    let from = "admin";

    let userId = "";
    if (this.props.history.location.pathname.includes("member")) {
      from = "member";
    }
    if (this.props.history.location.state !== undefined) {
      let lData = this.props.history.location.state.lead;

      lead.leadId = lData._id;
      lead.name = lData.name;
      lead.email = lData.email;
      lead.phone = lData.phone;
      userId = lData.lead_user || "";
      lead.customFieldValues = lData.custom_fields || [];
      lead.customFields = lData.custom_fields || [];
      lead.status = lData.status || "follow";
      lead.statusHistory = lData.status_history || [];
      lead.followDate = lData.follow_date ? new Date(lData.follow_date) : "";
      lead.notes = lData.notes || "";
      lead.sendEmailNotification = lData.send_email_notification;
      lead.sendSmsNotification = lData.send_sms_notification;
      lead.notiStatus = lData.notification_status || "pending";
      lead.notiTime = lData.notification_time || "0";
      lead.calendarId = lData.calendar_id || "default";
      lead.calendarUser = lData.calendar_user || "";
      lead.calendarDuration = lData.calendar_duration || "15";
      lead.timezone = lData.timezone || "America/Denver";
    }

    this.state = {
      leadId: lead.leadId,
      name: lead.name,
      email: lead.email,
      phone: lead.phone,
      keyLeadUser: Math.random(),
      selectedLeadUser: { value: "", label: "" },
      userId: userId,
      leadUsers: [],
      customFields: lead.customFields,
      customFieldValues: lead.customFieldValues,
      status: lead.status,
      keyStatus: Math.random(),
      statusHistory: lead.statusHistory,
      followDate: lead.followDate,
      keyDate: Math.random(),
      notes: lead.notes,
      from: from,
      sendEmailNotification: lead.sendEmailNotification,
      sendSmsNotification: lead.sendSmsNotification,
      keyCheckbox: Math.random(),
      notiStatus: lead.notiStatus,
      notiTime: lead.notiTime,
      calendarUser: lead.calendarUser,
      calendarId: lead.calendarId,
      calendarUsers: [],
      cCalendars: [],
      keyCalendar: Math.random(),
      calendarDuration: lead.calendarDuration,
      timezone: lead.timezone,
      keyName: Math.random(),
    };
  }

  componentDidMount() {
    this.getCalendarUsers();
    this.getAffiliateUsers();
    if (this.state.leadId !== 0) {
      if (this.state.calendarUser) {
        this.callGetUserInfo(this.state.calendarUser);
      }
    }
  }

  async getAffiliateUsers() {
    /*eslint-disable*/
    let query = {
      query: {
        member_type: "staff",
        accepts_leads: true,
        $select: ["_id", "name", "email_address"],
        populate: "no",
      },
    };
    /*eslint-enable*/
    let response = await callGetUsersByQuery(query);
    let fUsers = response.data;
    let leadUsers = [];
    let selectedLeadUser = {};

    fUsers.forEach((element) => {
      leadUsers.push({ value: element, label: element.name });
      if (this.state.userId === element._id) {
        selectedLeadUser = { value: element, label: element.name };
      }
    });
    this.setState({
      leadUsers: leadUsers,
      keyLeadUser: Math.random(),
      selectedLeadUser: selectedLeadUser,
    });
  }

  async getCalendarUsers() {
    let userData = await callGetUsersByQuery({
      query: {
        "google_info.refreshToken": { $exists: true },
        $select: ["_id", "name"],
      },
    });

    let fUsers = userData.data;
    this.setState({ calendarUsers: fUsers });
  }

  async callGetUserInfo(userId) {
    let refreshToken = "";

    var response = await callGetUserById(userId);
    if (response._id) {
      let googleInfo = response.google_info || {};
      if (googleInfo.refreshToken) {
        refreshToken = googleInfo.refreshToken || "";
      }
    }
    this.getCalendarList(refreshToken);
  }

  async getCalendarList(refreshToken) {
    if (refreshToken) {
      let query = {
        refreshToken: refreshToken,
      };

      let d = await callGetGoogleCalendarList(query);
      let cCalendars = [];
      d.items.forEach((element) => {
        cCalendars.push({
          summary: element.summary,
          id: element.id,
        });
      });
      this.setState({ cCalendars, keyCalendar: Math.random() });
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    if (name === "calendarUser") {
      this.callGetUserInfo(value);
      this.setState({
        [name]: value,
      });
    } else {
      this.setState({
        [name]: value,
      });
    }
  }

  resetProps() {
    this.setState({
      leadId: 0,
      name: "",
      email: "",
      phone: "",
      selectedLeadUser: "",
      status: "follow",
      followDate: "",
      keyStatus: Math.random(),
      notes: "",
      keyCheckbox: Math.random(),
      sendEmailNotification: false,
      sendSmsNotification: false,
    });
  }

  onSelectLeadUser = (user) => {
    this.setState({ selectedLeadUser: user });
  };

  onSelectStatus(item, id) {
    this.setState({
      [id]: item[Object.keys(item)[0]].value,
    });
  }

  onChangeFollowDate(name, date) {
    this.setState({
      [name]: date,
    });
  }

  toggleEmailCheckbox = (check) => {
    this.setState({
      sendEmailNotification: check,
    });
  };

  toggleSMSCheckbox = (check) => {
    this.setState({
      sendSmsNotification: check,
    });
  };

  onSelectNameValue(value) {
    this.setState({
      name: value.name,
      email: value.email_address,
      phone: value.phone_number,
      keyEmail: Math.random(),
    });
  }

  onChangeNameValue(value) {
    this.setState({
      name: value,
    });
  }

  renderTimezones() {
    let returnVals = [];
    let timezones = config.timezones;
    for (let i = 0; i < timezones.length; i++) {
      returnVals.push(
        <option value={timezones[i]} key={i + 1}>
          {timezones[i]}
        </option>
      );
    }
    return returnVals;
  }

  async onPressSave() {
    if (this.state.name.trim().length === 0) {
      showNotification("error", "Name is required", 4000);
      return;
    }

    let memberId = "";

    if (this.state.from !== "member") {
      if (
        !this.state.selectedLeadUser.label ||
        this.state.selectedLeadUser.label.trim().length === 0
      ) {
        showNotification("error", "Lead user is required", 4000);
        return;
      }
      memberId = this.state.selectedLeadUser.value._id;
    }

    let userData = getItem("userData");
    let loggedInUser = "";
    if (userData) {
      loggedInUser = JSON.parse(userData)._id;
      if (!memberId) {
        memberId = loggedInUser;
      }
    }

    let statusHistory = this.state.statusHistory;
    statusHistory.push({
      status: this.state.status,
      date: new Date(),
      memberId: loggedInUser,
    });

    /*eslint-disable*/
    let apiData = {
      name: this.state.name,
      email: this.state.email,
      phone: this.state.phone,
      lead_user: memberId,
      custom_fields: this.state.customFields,
      status: this.state.status,
      follow_date: this.state.followDate,
      status_history: statusHistory,
      notes: this.state.notes,
      send_email_notification: this.state.sendEmailNotification,
      send_sms_notification: this.state.sendSmsNotification,
      notification_status: this.state.notiStatus,
      notification_time: this.state.notiTime,
      calendar_id: this.state.calendarId,
      calendar_user: this.state.calendarUser,
      calendar_duration: this.state.calendarDuration,
      timezone: this.state.timezone,
    };

    if (this.state.leadId === 0) {
      let [err, data] = await toPromise(callCreateLead(apiData));

      if (err) {
        showNotification("error", err.message, 5000);
      } else {
        showNotification("success", "Created Successfully", 4000);
        this.resetProps();
      }
    } else {
      apiData["notification_status"] = "pending";
      let [err, data] = await toPromise(
        callUpdateLead(this.state.leadId, apiData)
      );
      if (err) {
        showNotification("error", err.message, 5000);
      } else {
        showNotification("success", "Updated Successfully", 4000);
      }
    }
  }

  render() {
    return (
      <div>
        <div className="container-fluid">
          <TitleBox
            title={this.state.leadId === 0 ? "Add Lead" : "Edit Lead"}
            showBackBtn={
              this.state.leadId !== 0 || this.state.from === "member"
            }
          />

          <div className="gap20" />

          <div className="card">
            <div className="container-fluid">
              <div className="gap20" />
              <div
                className={
                  this.state.name !== "" ? "mdInput mdFocussed" : "mdInput"
                }>
                <label>Name</label>
                {/* <input
                  type=""
                  className="challangeDesc"
                  name="name"
                  value={this.state.name}
                  onChange={(event) => this.handleInputChange(event)}
                /> */}
                <AutoSuggestAPI
                  key={this.state.keyName}
                  searchKey="name"
                  value={this.state.name}
                  onChange={this.onChangeNameValue.bind(this)}
                  onSelect={this.onSelectNameValue.bind(this)}
                />
              </div>
              <div className="gap20" />
              <div
                className={
                  this.state.email !== "" ? "mdInput mdFocussed" : "mdInput"
                }>
                <label>Email Address</label>
                <input
                  type="text"
                  className="challangeDesc"
                  name="email"
                  value={this.state.email}
                  onChange={(event) => this.handleInputChange(event)}
                />
              </div>
              <div className="gap20" />
              <div
                className={
                  this.state.phone !== "" ? "mdInput mdFocussed" : "mdInput"
                }>
                <label>Phone</label>
                <input
                  type="text"
                  className="challangeDesc"
                  name="phone"
                  value={this.state.phone}
                  onChange={(event) => this.handleInputChange(event)}
                />
              </div>
              <div className="gap20" />
              {this.state.from !== "member" && (
                <>
                  <TypeableSelect
                    key={this.state.keyLeadUser}
                    name={"selectedLeadUser"}
                    placeholder={"Lead User"}
                    selected={this.state.selectedLeadUser}
                    onSelect={this.onSelectLeadUser}
                    options={this.state.leadUsers}
                  />
                  <div className="gap20" />
                </>
              )}
              <div
                className={
                  this.state.notes !== "" ? "mdInput mdFocussed" : "mdInput"
                }>
                <label>Notes</label>
                <input
                  type="text"
                  className="challangeDesc"
                  name="notes"
                  value={this.state.notes}
                  onChange={(event) => this.handleInputChange(event)}
                />
              </div>
              <div className="gap20" />
              <LeadCustomFields
                customFieldValues={this.state.customFieldValues}
                onChangeFields={(fields) => {
                  this.setState({ customFields: fields });
                }}
              />
              <label className="inputFakeLabelFocussed">Select Status</label>
              <div className="tabsHolder radioTabs">
                <RadioTag
                  onCheckChange={this.onSelectStatus.bind(this)}
                  labelList={[
                    {
                      name: "Pending",
                      value: "pending",
                    },
                    {
                      name: "On Hold",
                      value: "hold",
                    },
                    {
                      name: "Follow Up",
                      value: "follow",
                    },
                    {
                      name: "Spam",
                      value: "spam",
                    },
                    {
                      name: "Completed",
                      value: "completed",
                    },
                  ]}
                  id={"status"}
                  selectedList={[this.state.status]}
                  selectType={"value"}
                  key={this.state.keyStatus}
                />
              </div>
              <div className="gap20" />
              {this.state.status === "follow" && (
                <>
                  <SelectDateTime
                    date={this.state.followDate}
                    type="datetime"
                    label="Follow Up Date"
                    onChange={(date) => {
                      this.setState({ followDate: date });
                    }}
                  />
                  <div className="gap20" />
                  <div className="row">
                    <div className="col-3">
                      <div className="mdInput mdFocussed">
                        <label>Timezone</label>
                        <select
                          name="timezone"
                          value={this.state.timezone}
                          onChange={(event) => this.handleInputChange(event)}>
                          {this.renderTimezones()}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="gap20" />
                  <Checkbox
                    label={"Send Email Notification"}
                    isChecked={this.state.sendEmailNotification}
                    handleCheckboxChange={this.toggleEmailCheckbox.bind(this)}
                    key={this.state.keyCheckbox}
                  />
                  <div className="gap20" />
                  <Checkbox
                    label={"Send SMS Notification"}
                    isChecked={this.state.sendSmsNotification}
                    handleCheckboxChange={this.toggleSMSCheckbox.bind(this)}
                    key={this.state.keyCheckbox}
                  />
                  <div className="gap20" />
                  {(this.state.sendEmailNotification ||
                    this.state.sendSmsNotification) && (
                    <>
                      <div className="row">
                        <div className="col-3">
                          <div
                            className={
                              this.state.notiTime !== ""
                                ? "mdInput mdFocussed"
                                : "mdInput"
                            }>
                            <label>Send X minutes before follow up date</label>
                            <input
                              type="text"
                              className="challangeDesc"
                              name="notiTime"
                              value={this.state.notiTime}
                              onChange={(event) =>
                                this.handleInputChange(event)
                              }
                            />
                          </div>
                          <div className="gap20" />
                        </div>
                      </div>
                    </>
                  )}
                  <div className="row">
                    <div className="col-4">
                      <div
                        className={
                          this.state.calendarUser !== ""
                            ? "mdInput mdFocussed"
                            : "mdInput"
                        }>
                        <label>Google Calendar User</label>
                        <select
                          name="calendarUser"
                          value={this.state.calendarUser}
                          onChange={(event) => this.handleInputChange(event)}>
                          <option value={""} key={"2"}></option>
                          {this.state.calendarUsers.map((i) => (
                            <option value={i._id} key={i._id}>
                              {i.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="gap20" />
                    </div>
                    <div className="col-4">
                      {this.state.calendarUser.length > 0 && (
                        <>
                          <div
                            className={
                              this.state.calendarId !== ""
                                ? "mdInput mdFocussed"
                                : "mdInput"
                            }>
                            <label>Select Calendar</label>
                            <select
                              key={this.state.keyCalendar}
                              name="calendarId"
                              value={this.state.calendarId}
                              onChange={(event) =>
                                this.handleInputChange(event)
                              }>
                              <option value={"default"} key={"2"}>
                                Default
                              </option>
                              {this.state.cCalendars.map((i) => (
                                <option value={i.id} key={i.id}>
                                  {i.summary}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="gap20" />
                        </>
                      )}
                    </div>
                    {this.state.calendarUser.length > 0 && (
                      <div className="col-4">
                        <div
                          className={
                            this.state.calendarDuration !== ""
                              ? "mdInput mdFocussed"
                              : "mdInput"
                          }>
                          <label>Duration of Event in Minutes</label>
                          <input
                            type="number"
                            className="challangeDesc"
                            name="calendarDuration"
                            placeholder="Minutes"
                            value={this.state.calendarDuration}
                            onChange={(event) => this.handleInputChange(event)}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="text-right">
            <button
              className="btn btn-primary"
              onClick={() => this.onPressSave()}>
              Save
            </button>
          </div>
          <div className="gap20" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddLeadPage);
