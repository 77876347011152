import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import DatePicker from "react-date-picker";
import * as $ from "jquery";
import moment from "moment";
import { Scrollbars } from "react-custom-scrollbars";

import RadioTag from "../../../../components/form/radioTag";
import TitleBox from "../../../../components/general/titleBox";
import {
  callGetReports,
  callGetEmailUsingId,
  callDeleteEmailHistoryByQuery,
  callDeleteEmailActionByQuery,
} from "../../../../services";
import { sanitizer } from "../../../../helpers";

class EmailLinks extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    let emailInfo = this.props.history.location.state.email;

    this.state = {
      emailInfo: emailInfo,
      keyOption: Math.random(),
      keyFilterDate: Math.random(),
      startFilterDate: new Date(),
      endFilterDate: new Date(),
      reportType: "",
      emailContent: "",
      emailSent: 0,
      emailClick: [],
      emailOpenData: [],
      emailOpen: [],
      emailOpenUnique: [],
      emailClickCounts: {},
      emailClickUniqueCounts: {},
      uniqueCounts: 0,
      emailOpenOption: "All",
      dateFilters: [
        { name: "Today", value: "today" },
        { name: "Yesterday", value: "yesterday" },
        { name: "This Week", value: "week" },
        { name: "This Month", value: "month" },
        { name: "This Year", value: "year" },
        { name: "Custom", value: "custom" },
      ],
    };
  }

  componentWillMount() {
    this.callGetEmailStats({ filter: "today" });
  }

  callGetEmailStats(data) {
    data["type"] = "email-stats";
    data["action"] = "links";
    data["email_id"] = this.state.emailInfo._id;

    callGetReports(data).then((response) => {
      let emailSentCount = response.emailHistories.length;
      let reportData = response.emailActions;
      if (reportData.length > 0) {
        let emailOpen = reportData.filter((d) => {
          return d.action === "open";
        });

        let memberGroups = {};
        let emailOpenUnique = [];
        emailOpen.forEach((element) => {
          if (!memberGroups[element.member_id]) {
            emailOpenUnique.push(element);
          }
          memberGroups[element.member_id] = true;
        });

        let emailClick = reportData.filter((d) => {
          return d.action === "click";
        });

        let linkGroups = {};
        emailClick.forEach((element) => {
          if (!linkGroups[element.link_id]) {
            linkGroups[element.link_id] = {};
            linkGroups[element.link_id]["name"] = element.link_info[0].name;
            linkGroups[element.link_id]["url"] = element.link_info[0].url;
            linkGroups[element.link_id]["links"] = [];
          }
          linkGroups[element.link_id]["links"].push(element);
        });

        let linkUniqueGroups = {};
        let count = 0;
        for (const key in linkGroups) {
          if (linkGroups.hasOwnProperty(key)) {
            const element = linkGroups[key];
            linkUniqueGroups[key] = {};
            linkUniqueGroups[key]["name"] = element.name;
            linkUniqueGroups[key]["url"] = element.url;

            let links = [];
            let fEle = {};
            element.links.forEach((element) => {
              if (!fEle[element.member_id]) {
                fEle[element.member_id] = element;
                links.push(element);
              }
            });
            linkUniqueGroups[key]["links"] = links;
            count += links.length;
          }
        }
        this.setState({
          emailSent: emailSentCount,
          emailOpenOption: "All",
          emailOpenData: emailOpenUnique,
          emailOpen: emailOpen,
          emailOpenUnique: emailOpenUnique,
          emailClick: emailClick,
          emailClickCounts: linkGroups,
          emailClickUniqueCounts: linkUniqueGroups,
          uniqueCounts: count,
        });
      } else {
        this.setState({
          emailSent: emailSentCount,
          emailOpen: [],
          emailClick: [],
          emailOpenData: [],
          emailOpenUnique: [],
          uniqueCounts: 0,
          emailClickCounts: 0,
          emailClickUniqueCounts: 0,
          emailOpenOption: "All",
        });
      }
    });
  }

  getReportsData(type, data) {
    switch (type) {
      case "email-stats":
        this.callGetEmailStats(data);
        break;
      default:
        break;
    }
  }

  onClickDateFilterReport(type, selectedList, name) {
    let filter = selectedList[Object.keys(selectedList)[0]].value;
    switch (filter) {
      case "today":
      case "yesterday":
      case "week":
      case "month":
      case "year":
        this.getReportsData(type, { filter: filter });
        break;
      case "custom":
        this.setState({ reportType: type }, () => {
          $(
            ".modalAlertOverlay.filterDateOverlay,.modalAlert.filterDateAlert"
          ).fadeIn(200);
          this.setState({ keyFilterDate: Math.random() });
        });
        break;

      default:
        break;
    }
  }

  renderDateFilter(type) {
    return (
      <div className="tabsHolder radioTabs">
        <RadioTag
          onCheckChange={this.onClickDateFilterReport.bind(this, type)}
          labelList={this.state.dateFilters}
          selectType={"value"}
          selectedList={["today"]}
          key={this.state.keyOption}
        />
      </div>
    );
  }

  onClickCloseFromFilterDateModal() {
    $(
      ".modalAlertOverlay.filterDateOverlay,.modalAlert.filterDateAlert"
    ).fadeOut(200);
  }

  onClickDoneFromFilterDateModal() {
    $(
      ".modalAlertOverlay.filterDateOverlay,.modalAlert.filterDateAlert"
    ).fadeOut(200);
    let customDates =
      moment(this.state.startFilterDate).startOf("day").format("YYYY-MM-DD") +
      "," +
      moment(this.state.endFilterDate).startOf("day").format("YYYY-MM-DD");

    let reportType = this.state.reportType;
    switch (reportType) {
      case "email-stats":
        this.callGetEmailStats({
          filter: "custom",
          customDates: customDates,
        });
        break;
      default:
        break;
    }
  }

  onChangeFilterDate(name, date) {
    this.setState({
      [name]: date,
    });
  }

  onClickViewReport() {
    this.props.history.push("/admin/reports/email-reports");
  }

  onClickEmailName() {
    callGetEmailUsingId(this.state.emailInfo._id).then((d) => {
      if (d._id) {
        this.setState({ emailContent: d.html }, () => {
          $(
            ".modalAlertOverlay.viewEmailContent,.modalAlert.viewEmailContentAlert"
          ).fadeIn(200);
        });
      }
    });
  }

  renderCustomDateModal() {
    return (
      <div>
        <div className="modalAlertOverlay filterDateOverlay" />
        <div className="modalAlert filterDateAlert">
          <p> Filter By Date </p>
          <div className="container-fluid">
            <div className="row">
              <div className="col-6">
                <label className="inputFakeLabelFocussed">
                  Select Start Date
                </label>
                <div>
                  <DatePicker
                    key={this.state.keyTrackingDate}
                    value={this.state.startFilterDate}
                    onChange={this.onChangeFilterDate.bind(
                      this,
                      "startFilterDate"
                    )}
                  />
                </div>
              </div>
              <div className="col-6">
                <label className="inputFakeLabelFocussed">
                  Select End Date
                </label>
                <div>
                  <DatePicker
                    key={this.state.keyTrackingDate}
                    value={this.state.endFilterDate}
                    onChange={this.onChangeFilterDate.bind(
                      this,
                      "endFilterDate"
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="alertFooterBtns">
            {/*eslint-disable*/}
            <a
              className="btn btn-default dismissThisModal margin-right-10"
              onClick={this.onClickCloseFromFilterDateModal.bind(this)}>
              Close
            </a>
            <a
              className="btn btn-primary dismissThisModal"
              onClick={this.onClickDoneFromFilterDateModal.bind(this)}>
              Done
            </a>
            {/*eslint-enable*/}
          </div>
        </div>
      </div>
    );
  }

  renderEmailTotalLinks() {
    var returnVals = [];
    let emailClickCounts = this.state.emailClickCounts;
    for (const key in emailClickCounts) {
      if (emailClickCounts.hasOwnProperty(key)) {
        const element = emailClickCounts[key];
        returnVals.push(
          <div className="listWithRight" key={key}>
            <p className="noMargin firstLetter">{element.url}</p>
            <div className="rightElem">
              <span className="taskStatus taskGreen">
                {element.links.length}
              </span>
            </div>
          </div>
        );
      }
    }
    return returnVals;
  }

  renderEmailUniqueLinks() {
    var returnVals = [];
    let emailClickCounts = this.state.emailClickUniqueCounts;
    for (const key in emailClickCounts) {
      if (emailClickCounts.hasOwnProperty(key)) {
        const element = emailClickCounts[key];
        returnVals.push(
          <div className="listWithRight" key={key}>
            <p className="noMargin firstLetter">{element.url}</p>
            <div className="rightElem">
              <span className="taskStatus taskGreen">
                {element.links.length}
              </span>
            </div>
          </div>
        );
      }
    }
    return returnVals;
  }

  onClickOnEmailOpenOption() {
    let emailOpenOption = this.state.emailOpenOption;
    if (emailOpenOption === "Unique") {
      this.setState({
        emailOpenData: this.state.emailOpenUnique,
        emailOpenOption: "All",
      });
    } else {
      this.setState({
        emailOpenData: this.state.emailOpen,
        emailOpenOption: "Unique",
      });
    }
  }

  onClickViewResetCounts(email) {
    $(".modalAlertOverlay.clearCountModal,.modalAlert.clearCountAlert").fadeIn(
      200
    );
  }

  renderConfirmAlert() {
    let name = "";
    if (this.state.emailInfo.name) {
      name = this.state.emailInfo.name;
    }
    return (
      <div>
        <div className="modalAlertOverlay clearCountModal" />
        <div className="modalAlert clearCountAlert">
          <p>{`Are you sure you want to clear counts for "${name}" email?`}</p>
          <div className="alertFooterBtns">
            {/*eslint-disable*/}
            <a
              className="btn btn-default closeModal-No margin-right-10"
              onClick={this.onClickNoAlert.bind(this)}>
              No
            </a>
            <a
              className="btn btn-primary closeModal-Yes"
              onClick={this.onClickYesAlert.bind(this)}>
              Yes
            </a>
            {/*eslint-enable*/}
          </div>
        </div>
      </div>
    );
  }

  onClickNoAlert() {
    $(".modalAlertOverlay.clearCountModal,.modalAlert.clearCountAlert").fadeOut(
      200
    );
  }

  async onClickYesAlert() {
    $(".modalAlertOverlay.clearCountModal,.modalAlert.clearCountAlert").fadeOut(
      200
    );

    /*eslint-disable*/
    let query = {
      query: {
        email_id: this.state.emailInfo._id,
      },
    };
    /*eslint-enable*/

    await callDeleteEmailHistoryByQuery(query);
    await callDeleteEmailActionByQuery(query);

    this.props.history.goBack();
  }

  onClickCloseEmailContentModel() {
    $(
      ".modalAlertOverlay.viewEmailContent,.modalAlert.viewEmailContentAlert"
    ).fadeOut(200);
  }

  renderEmailContent() {
    let modalHeight = window.innerHeight - 250;
    return (
      <div>
        <div className="modalAlertOverlay viewEmailContent" />
        <div className="modalAlert viewEmailContentAlert bigY">
          <p className="firstLetter"> {this.state.emailInfo.name}</p>
          <div
            className="container-fluid modalInnerBody"
            style={{ maxHeight: modalHeight, overflowY: "hidden" }}>
            <Scrollbars
              autoHide
              autoHideTimeout={1000}
              autoHideDuration={200}
              style={{ height: modalHeight }}>
              <div className="list-group-item">
                <div className="gap10" />
                <p
                  dangerouslySetInnerHTML={{
                    __html: sanitizer(this.state.emailContent),
                  }}
                />
              </div>
            </Scrollbars>
          </div>

          <div className="alertFooterBtns">
            {/*eslint-disable-next-line*/}
            <a
              className="btn btn-primary dismissThisModal"
              onClick={this.onClickCloseEmailContentModel.bind(this)}>
              Close
            </a>
          </div>
        </div>
      </div>
    );
  }

  renderTotalClicksReport() {
    return (
      <div className="card">
        <div className="list-group list-group-flush">
          <div className="list-group-item">
            <h5 className="noMargin pull-left firstLetter">Total Clicks</h5>
            <div className="pull-right taskGreen">
              <b>{this.state.emailClick.length}</b>
            </div>
          </div>
          <div className="list-group-item">
            <div className="gap10" />
            <div className="cardList">{this.renderEmailTotalLinks()}</div>
          </div>
        </div>
      </div>
    );
  }

  renderEmailSentReport() {
    return (
      <div className="card">
        <div className="list-group list-group-flush">
          <div className="list-group-item">
            <h5 className="noMargin pull-left firstLetter">Emails Sent</h5>
            <div className="pull-right taskGreen">
              <b>{this.state.emailSent}</b>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderUniqueClicksReport() {
    return (
      <div className="card">
        <div className="list-group list-group-flush">
          <div className="list-group-item">
            <h5 className="noMargin pull-left firstLetter">Unique Clicks</h5>
            <div className="pull-right taskGreen">
              <b>{this.state.uniqueCounts}</b>
            </div>
          </div>
          <div className="list-group-item">
            <div className="gap10" />
            <div className="cardList">{this.renderEmailUniqueLinks()}</div>
          </div>
        </div>
      </div>
    );
  }

  renderMemberOpenReport() {
    return (
      <div className="card">
        <div className="list-group list-group-flush">
          <div className="list-group-item">
            <h5 className="noMargin pull-left">Member Opened</h5>
            <div className="pull-right">
              <b style={{ marginRight: 15 }} className="taskGreen">
                {this.state.emailOpenData.length}
              </b>
              {/*eslint-disable-next-line*/}
              <a
                className="btn btn-sm btn-default"
                onClick={this.onClickOnEmailOpenOption.bind(this)}>
                {this.state.emailOpenOption}
              </a>
            </div>
          </div>
          <div className="list-group-item">
            <div className="gap10" />
            <div className="cardList">
              {this.state.emailOpenData.map((i) => (
                <div className="listWithRight" key={i._id}>
                  <p className="noMargin firstLetter">
                    {i.member_info[0] ? (
                      i.member_info[0].name ? (
                        i.member_info[0].name
                      ) : (
                        <i>No Name</i>
                      )
                    ) : (
                      <i>No Name</i>
                    )}
                  </p>
                  <div className="rightElem moreWidth">
                    <span className="taskStatus taskGreen moreWidth">
                      {moment(i.createdAt).format("Do MMM YYYY hh:mm A")}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderMemberClickReport() {
    return (
      <div className="card">
        <div className="list-group list-group-flush">
          <div className="list-group-item">
            <h5 className="noMargin">Member Clicked</h5>
          </div>
          <div className="list-group-item">
            <div className="gap10" />
            <div className="cardList">
              {this.state.emailClick.map((i) => (
                <div className="listWithRight" key={i._id}>
                  <p className="noMargin firstLetter">
                    {i.member_info[0] ? (
                      i.member_info[0].name ? (
                        i.member_info[0].name
                      ) : (
                        <i>No Name</i>
                      )
                    ) : (
                      <i>No Name</i>
                    )}
                  </p>
                  <div className="rightElem moreWidth">
                    <span className="taskStatus taskGreen moreWidth">
                      {moment(i.createdAt).format("Do MMM YYYY hh:mm A")}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderDateFilterSection() {
    return (
      <div className="card">
        <div className="list-group list-group-flush">
          <div className="list-group-item">
            <h5 className="noMargin pull-left firstLetter">
              {/*eslint-disable*/}
              <a
                style={{ color: "#26ace2" }}
                onClick={this.onClickEmailName.bind(this)}>
                {this.state.emailInfo.name}
              </a>
            </h5>
            <div className="pull-right">
              <a
                className="btn btn-sm btn-danger"
                onClick={this.onClickViewResetCounts.bind(this)}>
                Reset Email Stats
              </a>
            </div>
            {/*eslint-enable*/}
          </div>
          <div className="list-group-item">
            {this.renderDateFilter("email-stats")}
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderEmailContent()}
        {this.renderConfirmAlert()}
        {this.renderCustomDateModal()}
        <div className="container-fluid">
          <TitleBox title="Email Links" showBackBtn={true} />
          <div className="gap20" />
          <div className="row">
            <div className="col-lg-12">{this.renderDateFilterSection()}</div>
          </div>

          <div className="row">
            <div className="col-lg-6">{this.renderEmailSentReport()}</div>
          </div>

          <div className="row">
            <div className="col-lg-6">{this.renderTotalClicksReport()}</div>
            <div className="col-lg-6">{this.renderUniqueClicksReport()}</div>
          </div>

          <div className="row">
            <div className="col-lg-6">{this.renderMemberOpenReport()}</div>
            <div className="col-lg-6">{this.renderMemberClickReport()}</div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EmailLinks);
