import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import TitleBox from "../../../components/general/titleBox";

import {
  callCreateProduct,
  callGetProductById,
  callGetSystemTags,
  callUpdateProduct,
} from "../../../services";
import {
  getParameterByName,
  getUniqueId,
  showNotification,
  toPromise,
} from "../../../helpers";
import SelectAddImage from "../../../components/form/selectAddImage";
import TagSelect from "../../../components/form/tagSelect";

class AddProductPage extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    let product = {
      productId: 0,
      name: "",
      description: "",
      price: "",
      image: "",
      uniqueId: "0",
    };

    let id = getParameterByName("id");
    if (id) {
      product.productId = id;
    }

    this.state = {
      productId: product.productId,
      name: product.name,
      description: product.description,
      price: product.price,
      image: product.image,
      keyImage: Math.random(),
      uniqueId: product.uniqueId,
      systemTags: [],
      keyAddTag: Math.random(),
      addTags: [],
      selectedTags: [],
    };
  }

  componentDidMount() {
    this.getSystemTags();
    if (this.state.productId !== 0) {
      this.getProductInfo();
    }
  }

  async getSystemTags() {
    let tags = await callGetSystemTags();
    this.setState({ systemTags: tags.data, keyAddTag: Math.random() });
  }

  async getProductInfo() {
    var response = await callGetProductById(this.state.productId);
    if (response._id) {
      this.setState({
        productId: response._id,
        name: response.name,
        description: response.description,
        image: response.image,
        price: response.price,
        keyImage: Math.random(),
        uniqueId: response.product_id || "0",
        addTags: response.ftags || [],
        selectedTags: response.ftags || [],
        keyAddTag: Math.random(),
      });
    }
  }

  resetProps() {
    this.setState({
      productId: "",
      name: "",
      description: "",
      image: "",
      price: "",
      keyImage: Math.random(),
      selectedTags: [],
      keyAddTag: [],
      addTags: [],
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  onChangeImage = (image) => {
    this.setState({ image: image });
  };

  onChangeAddTags = (tags) => {
    this.setState({ addTags: tags });
  };

  async onPressSave() {
    if (this.state.name.trim().length === 0) {
      showNotification("error", "Name is required", 4000);
      return;
    }

    if (this.state.price.toString().trim().length === 0) {
      showNotification("error", "Price is required", 4000);
      return;
    }

    let uniqueId = this.state.uniqueId;
    if (uniqueId === "0") {
      uniqueId = getUniqueId();
    }

    let tagList = this.state.addTags
      .filter((d) => {
        return d._id !== null;
      })
      .map((t) => t._id);

    /*eslint-disable*/
    let apiData = {
      name: this.state.name,
      description: this.state.description,
      price: this.state.price,
      image: this.state.image,
      product_id: uniqueId,
      tags: tagList,
    };

    if (this.state.productId !== 0) {
      let [err, data] = await toPromise(
        callUpdateProduct(this.state.productId, apiData)
      );

      /*eslint-enable*/
      if (err) {
        showNotification("error", err.message, 5000);
      } else {
        showNotification("success", "Updated successfully", 4000);
      }
    } else {
      /*eslint-disable*/
      let [err, data] = await toPromise(callCreateProduct(apiData));
      /*eslint-enable*/
      if (err) {
        showNotification("error", err.message, 5000);
      } else {
        showNotification("success", "Created successfully", 4000);
        this.resetProps();
      }
    }
  }

  render() {
    return (
      <div>
        <div className="container-fluid">
          <TitleBox
            title={this.state.productId === 0 ? "Add Product" : "Edit Product"}
            showBackBtn={this.state.productId !== 0}
          />
          <div className="gap20" />
          <div className="card">
            <div className="container-fluid">
              <div className="gap20" />
              <div
                className={
                  this.state.name !== "" ? "mdInput mdFocussed" : "mdInput"
                }>
                <label>Name</label>
                <input
                  type="text"
                  name="name"
                  onChange={this.handleInputChange.bind(this)}
                  value={this.state.name}
                />
              </div>
              <div className="gap20" />
              <div
                className={
                  this.state.description !== ""
                    ? "mdInput mdFocussed"
                    : "mdInput"
                }>
                <label>Description</label>
                <input
                  type="text"
                  name="description"
                  onChange={this.handleInputChange.bind(this)}
                  value={this.state.description}
                />
              </div>
              <div className="gap20" />
              <div
                className={
                  this.state.price !== "" ? "mdInput mdFocussed" : "mdInput"
                }>
                <label>Price</label>
                <input
                  type="number"
                  name="price"
                  onChange={this.handleInputChange.bind(this)}
                  value={this.state.price}
                />
              </div>
              <div className="gap20" />
              <SelectAddImage
                key={this.state.keyImage}
                type="product-image"
                label="Product Image"
                onChangeImage={this.onChangeImage}
                image={this.state.image}
              />
              <div className="gap20" />
              <TagSelect
                id={"add-product-add-tag"}
                key={this.state.keyAddTag}
                selectedValues={this.state.selectedTags}
                searchKey={"name"}
                selectKey={"name"}
                btnText={"Select Tag"}
                lblText={"Select Tag"}
                data={this.state.systemTags}
                onChange={this.onChangeAddTags}
                isClickable={true}
                reload={() => {
                  this.getSystemTags();
                }}
              />
              <div className="gap10" />
            </div>
          </div>
          <div className="text-right container-fluid">
            <button
              className="btn btn-primary"
              onClick={() => this.onPressSave()}>
              Save
            </button>
          </div>
          <div className="gap20" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddProductPage);
