import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { arrayMove } from "react-sortable-hoc";
import { VelocityTransitionGroup } from "velocity-react";
import $ from "jquery";

import PageContent from "../../../components/editor/pageContent";
import RadioTag from "../../../components/form/radioTag";
import Checkbox from "../../../components/form/checkBox";
import TitleBox from "../../../components/general/titleBox";
import SelectAddImage from "../../../components/form/selectAddImage";
import TagUsers from "../../../components/form/tagUsers";

import { getChlgQues, resetStatus } from "../../../modules/actions/index";
import {
  SortableQuestionList,
  SortableVideoList,
} from "../../../components/form/sortableList";

import TypeableSelect from "../../../components/form/typeableSelect";
import { getEditorType, isFS, showNotification } from "../../../helpers";
import {
  callGetGenericTags,
  callCreateGenericTag,
  callCreateChlgItem,
  callUpdateChlgItem,
  callUpdateGenericTag,
  callGetChlgItemById,
  callGeneralPost,
} from "../../../services";
import HelpTextDiv from "../../../components/form/helpTextDiv";
import VideoPlayerNote from "../../../components/general/videoPlayerNote";
import { callCreateAwsVideo } from "../../../services/awsVideosService";
import { getItem } from "../../../helpers/storage";
import SelectDiscussionBoard from "../../../components/form/discussionBoard";
import { callEmailTemplateById } from "../../../services/settingService";
import config from "../../../helpers/config";
import { processEditorContent } from "../../../components/editor/processEditor";

class AddChallengeItem extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    let challengeItem = {
      _id: 0,
      challengeQuestionList: [],
      content: "",
      editorContent: {},
      description: "",
      itemType: "",
      name: "",
      videoList: [],
      isBonus: false,
      isAtqItem: false,
      isRatingItem: false,
      ratingType: "Helpfulness",
      ratingTypeKey: Math.random(),
      helpfullnessRating: {
        ratingHfDisplayText: "Was this helpful?",
        ratingHfYesText: "Yes",
        ratingHfNoText: "No",
        ratingHfCommentText:
          "Any additional information you would like to add?",
        isRattingHFcommentItem: true,
        keyRatingCommentCommentItem: Math.random(),
      },
      numberRating: {
        ratingNrDisplayText: "how much did you enjoy this content?",
        ratingNrOption1: "1 (Dislike)",
        ratingNrOption2: "2 (:",
        ratingNrOption3: "3 :|",
        ratingNrOption4: "4 :)",
        ratingNrOption5: "5 (Love)",
        ratingNrCommentText: "Add your comment?",
      },
      discussionBoard: "",
      isDiscussionBoard: false,
      keyDiscussionBoard: Math.random(),
      selectedIncludedTags: [],
      selectedExcludedTags: [],
      contentType: "content",
      userVideos: [],
    };

    let editorType = isFS()
      ? "mp"
      : getItem("de") === "classic"
      ? "unlayer"
      : "mp";

    if (
      (this.props.history && this.props.history.location.state !== undefined) ||
      this.props.challengeItem !== undefined
    ) {
      let chlgItem = "";
      if (this.props.challengeItem !== undefined)
        chlgItem = this.props.challengeItem;
      else chlgItem = this.props.history.location.state.challengeItem;
      challengeItem._id = chlgItem._id;
    }

    this.state = {
      itemId: challengeItem._id,
      itemName: challengeItem.name,
      description: challengeItem.description,
      itemType: challengeItem.itemType,
      offsetDays: "",
      lockDays: "",
      content: challengeItem.content,
      editorContent: challengeItem.editorContent,
      questions: [],
      chlgQueList: challengeItem.challengeQuestionList,
      videos: challengeItem.videoList,
      videoTitle: "",
      videoUrl: "",
      videoFullUrl: "",
      videoPlayer: "Youtube",
      ratingType: challengeItem.ratingType,
      helpfullnessRating: challengeItem.helpfullnessRating,
      numberRating: challengeItem.numberRating,
      selectedQue: { value: "", label: "" },
      isDefaultLock: false,
      keyQuestion: Math.random(),
      reloadContentEditor: false,
      keyContent: Math.random(),
      itemTypes: [],
      itemTypeName: "",
      keyTypes: Math.random(),
      showCreateItemType: false,
      keyBonusItem: Math.random(),
      isBonusItem: challengeItem.isBonus,
      isAtqItem: challengeItem.isAtqItem,
      isRatingItem: challengeItem.isRatingItem,
      isOwnVideo: false,
      keyOwnVideo: Math.random(),
      keyRatingItem: Math.random(),
      keyAtqItem: Math.random(),
      videoKey: Math.random(),
      showAdvanced: false,
      categoryName: "",
      selectVideoType: "addVideo",
      keyVideoType: Math.random(),
      awsVideoId: "",
      editorType: editorType,
      discussionBoard: challengeItem.discussionBoard,
      isDiscussionBoard: challengeItem.isDiscussionBoard,
      keyDiscussionBoard: Math.random(),
      addTags: challengeItem.selectedIncludedTags,
      excludeTags: challengeItem.selectedExcludedTags,
      selectedIncludedTags: challengeItem.selectedIncludedTags,
      selectedExcludedTags: challengeItem.selectedExcludedTags,
      keyTags: Math.random(),
      contentType: challengeItem.contentType,
      fileUrl: "",
      externalUrl: "",
      keyFile: Math.random(),
      keyEditor: Math.random(),
      draftId: "",
      draftImage: "",
      isResource: false,
      keyResource: Math.random(),
      userVideos: [],
    };
  }

  componentDidMount() {
    this.props.getChlgQues();
    this.getChlgItemTypes();
    this.getChlgItem();
  }

  async getDefaultTemplate() {
    let challengeInfo = this.props.challengeInfo || {};

    let apiData = {
      action: "get-template-content",
      data: {
        type: "courseItem",
      },
    };

    let response = await callGeneralPost(apiData);
    if (response._id) {
      let editorContent = processEditorContent(
        response.editor_content,
        challengeInfo
      );

      this.setState({
        keyEditor: Math.random(),
        content: response.html,
        editorContent: editorContent,
      });
      return;
    }

    let templateId = config.editorTemplateIds.defaultMpChallengeItem;
    if (challengeInfo.itemTemplateId) {
      templateId = challengeInfo.itemTemplateId;
    }

    let editorData = await callEmailTemplateById(templateId);
    if (editorData && editorData.data && editorData.data._id) {
      let template = editorData.data;

      let editorContent = processEditorContent(
        template.editor_content,
        challengeInfo
      );

      this.setState({
        keyEditor: Math.random(),
        content: template.html,
        editorContent: editorContent,
      });
    }
  }

  async getChlgItem() {
    if (this.state.itemId === 0) {
      this.getDefaultTemplate();
      return;
    }
    let chlgItem = await callGetChlgItemById(this.state.itemId);
    let challengeItem = {
      _id: 0,
      challengeQuestionList: [],
      content: "",
      editorContent: {},
      description: "",
      itemType: "",
      name: "",
      videoList: [],
      isBonus: false,
      isAtqItem: false,
      isRatingItem: false,
      ratingType: "Helpfulness",
      ratingTypeKey: Math.random(),
      helpfullnessRating: {
        ratingHfDisplayText: "Was this helpful?",
        ratingHfYesText: "Yes",
        ratingHfNoText: "No",
        ratingHfCommentText:
          "Any additional information you would like to add?",
        isRattingHFcommentItem: true,
        keyRatingCommentCommentItem: Math.random(),
      },
      numberRating: {
        ratingNrDisplayText: "how much did you enjoy this content?",
        ratingNrOption1: "1 (Dislike)",
        ratingNrOption2: "2 (:",
        ratingNrOption3: "3 :|",
        ratingNrOption4: "4 :)",
        ratingNrOption5: "5 (Love)",
        ratingNrCommentText: "Add your comment?",
      },
      discussionBoard: "",
      isDiscussionBoard: false,
      keyDiscussionBoard: Math.random(),
      selectedIncludedTags: [],
      selectedExcludedTags: [],
      contentType: "content",
      fileUrl: "",
      externalUrl: "",
      draftId: "",
      draftImage: "",
      isResource: false,
      userVideos: [],
    };

    let editorType = isFS()
      ? "mp"
      : getItem("de") === "classic"
      ? "unlayer"
      : "mp";

    challengeItem._id = chlgItem._id;
    challengeItem.name = chlgItem.name;
    challengeItem.item = chlgItem.item;
    challengeItem.description = chlgItem.description;
    challengeItem.content = chlgItem.content;
    challengeItem.editorContent = chlgItem.editor_content || {};
    challengeItem.selectedIncludedTags = chlgItem.fincludedtags || [];
    challengeItem.selectedExcludedTags = chlgItem.fexcludedtags || [];
    challengeItem.isResource =
      chlgItem.resource !== undefined ? chlgItem.resource : false;

    editorType = getEditorType(
      challengeItem.content,
      challengeItem.editorContent
    );

    let queList = [];

    let challengeQuestionList = chlgItem.challenge_question_list_info || [];

    challengeQuestionList.forEach((item) => {
      queList.push({
        name: item.name,
        question: item.question,
        description: item.description,
        value: item,
      });
    });

    challengeItem.challengeQuestionList = queList;
    challengeItem.itemType = chlgItem.item_type;
    challengeItem.videoList = chlgItem.video_list;
    challengeItem.isBonus = chlgItem.is_bonus === true ? true : false;
    challengeItem.discussionBoard = chlgItem.discussion_board || "";
    challengeItem.isDiscussionBoard =
      chlgItem.is_discussion_board !== undefined
        ? chlgItem.is_discussion_board
        : false;

    challengeItem.contentType = chlgItem.content_type || "content";
    challengeItem.fileUrl = chlgItem.file_url || "";
    challengeItem.externalUrl = chlgItem.external_url || "";

    let isAtqItem = true;
    if (chlgItem.is_atq_item !== undefined) {
      isAtqItem = chlgItem.is_atq_item;
    }

    challengeItem.isAtqItem = isAtqItem;

    if (chlgItem.helpfullness_rating !== undefined) {
      challengeItem.helpfullnessRating = chlgItem.helpfullness_rating;
    }

    if (chlgItem.number_rating !== undefined) {
      challengeItem.numberRating = chlgItem.number_rating;
    }

    if (chlgItem.rating_type !== undefined) {
      challengeItem.ratingType = chlgItem.rating_type;
    }

    let isRatingItem = false;
    if (chlgItem.is_rating_item !== undefined) {
      isRatingItem = chlgItem.is_rating_item;
    }

    challengeItem.isRatingItem = isRatingItem;
    challengeItem.draftId = chlgItem.draft_id || "";
    challengeItem.draftImage = chlgItem.draft_image || "";

    challengeItem.userVideos = chlgItem.user_videos || [];

    this.setState({
      itemId: challengeItem._id,
      itemName: challengeItem.name,
      description: challengeItem.description,
      itemType: challengeItem.itemType,
      content: challengeItem.content,
      editorContent: challengeItem.editorContent,
      chlgQueList: challengeItem.challengeQuestionList,
      ratingType: challengeItem.ratingType,
      helpfullnessRating: challengeItem.helpfullnessRating,
      numberRating: challengeItem.numberRating,
      keyQuestion: Math.random(),
      keyContent: Math.random(),
      keyTypes: Math.random(),
      keyBonusItem: Math.random(),
      isBonusItem: challengeItem.isBonus,
      isAtqItem: challengeItem.isAtqItem,
      isRatingItem: challengeItem.isRatingItem,
      keyOwnVideo: Math.random(),
      keyRatingItem: Math.random(),
      keyAtqItem: Math.random(),
      videoKey: Math.random(),
      keyVideoType: Math.random(),
      editorType: editorType,
      discussionBoard: challengeItem.discussionBoard,
      isDiscussionBoard: challengeItem.isDiscussionBoard,
      keyDiscussionBoard: Math.random(),
      addTags: challengeItem.selectedIncludedTags,
      excludeTags: challengeItem.selectedExcludedTags,
      selectedIncludedTags: challengeItem.selectedIncludedTags,
      selectedExcludedTags: challengeItem.selectedExcludedTags,
      keyTags: Math.random(),
      keyEditor: Math.random(),
      contentType: challengeItem.contentType,
      fileUrl: challengeItem.fileUrl,
      externalUrl: challengeItem.externalUrl,
      keyFile: Math.random(),
      draftId: challengeItem.draftId,
      draftImage: challengeItem.draftImage,
      isResource: challengeItem.isResource,
      keyResource: Math.random(),
      userVideos: challengeItem.userVideos,
    });
  }

  getChlgItemTypes() {
    callGetGenericTags("challenge-item").then((d) => {
      this.setState({ itemTypes: d.data, keyTypes: Math.random() });
      if (d.data.length === 0) {
        this.setState({
          itemType: "Create New Category",
          keyTypes: Math.random(),
          showCreateItemType: true,
        });
      }
    });
  }

  onSelectQuestion = (que) => {
    this.setState({ selectedQue: que });
  };

  componentWillReceiveProps(newProps) {
    if (newProps.chlgQuestions.data) {
      let ques = newProps.chlgQuestions.data;
      let fQues = [];
      ques.forEach((element) => {
        fQues.push({ value: element, label: element.question });
      });
      this.setState({
        questions: fQues,
        keyQuestion: Math.random(),
      });
    }
  }

  resetProps() {
    this.setState({
      itemName: "",
      description: "",
      itemType: "",
      offsetDays: "",
      lockDays: "",
      content: "",
      editorContent: {},
      questions: [],
      chlgQueList: [],
      videos: [],
      videoTitle: "",
      videoUrl: "",
      videoFullUrl: "",
      videoPlayer: "Youtube",
      isRatingItem: true,
      ratingType: "Helpfulness",
      ratingTypeKey: Math.random(),
      helpfullnessRating: {
        ratingHfDisplayText: "Was this helpful?",
        ratingHfYesText: "Yes",
        ratingHfNoText: "No",
        ratingHfCommentText:
          "Any additional information you would like to add?",
        isRattingHFcommentItem: true,
        keyRatingCommentCommentItem: Math.random(),
      },
      numberRating: {
        ratingNrDisplayText: "how much did you enjoy this content?",
        ratingNrOption1: "1 (Dislike)",
        ratingNrOption2: "2 (:",
        ratingNrOption3: "3 :|",
        ratingNrOption4: "4 :)",
        ratingNrOption5: "5 (Love)",
        ratingNrCommentText: "Add your comment?",
      },
      selectedQue: { value: "", label: "" },
      isDefaultLock: false,
      contentType: "content",
      fileUrl: "",
      externalUrl: "",
      isResource: false,
      keyResource: Math.random(),
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  handleOnBlurItemName(event) {
    let value = event.target.value;
    let itemName = this.state.itemName.toLowerCase().trim();
    let trimValue = value.toLowerCase().trim();
    if (
      trimValue === itemName ||
      itemName.length === 0 ||
      trimValue.includes(itemName)
    ) {
      this.setState({ itemName: value });
    }
    this.setState({ keyEditor: Math.random() });
  }

  handleNumberInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      ...this.state,
      numberRating: { ...this.state.numberRating, [name]: value },
    });
  }

  handleHelpfullnessInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      ...this.state,
      helpfullnessRating: { ...this.state.helpfullnessRating, [name]: value },
    });
  }

  onSelectItem(item, id) {
    this.setState({
      [id]: item[Object.keys(item)[0]].value,
      showCreateItemType:
        item[Object.keys(item)[0]].value === "Create New Category"
          ? true
          : false,
      videoKey: Math.random(),
      ratingTypeKey: Math.random(),
    });
  }

  onSelectVideoType(item, id) {
    this.setState({
      [id]: item[Object.keys(item)[0]].value,
    });
  }

  onChangeVideo(video) {
    this.setState({
      awsVideoId: video._id,
      videoFullUrl: video.url,
      videoTitle: video.title || "",
    });
  }

  async onClickAddVideo() {
    let videos = this.state.videos;

    if (this.state.videoTitle.trim().length === 0) {
      showNotification("error", "Video Title is required", 4000);
      return;
    }

    if (this.state.videoPlayer === "memberpages") {
      if (this.state.selectVideoType === "addVideo") {
        if (this.state.videoFullUrl.trim().length === 0) {
          showNotification("error", "Video Url is required", 4000);
          return;
        }
        if (!this.state.isOwnVideo) {
          showNotification(
            "error",
            "You must agree to copyright access for your video url",
            4000
          );
          return;
        }
      }
    } else {
      if (this.state.videoUrl.trim().length === 0) {
        showNotification("error", "Video ID is required", 4000);
        return;
      }
    }

    if (this.state.videoPlayer.trim().length === 0) {
      showNotification("error", "Video Player is required", 4000);
      return;
    }

    let awsVideoId = this.state.awsVideoId;

    if (
      this.state.videoPlayer === "memberpages" &&
      this.state.selectVideoType === "addVideo"
    ) {
      let awsVideoData = {
        url: this.state.videoFullUrl,
        title: this.state.videoTitle,
        status: "pending",
      };
      let awsVideo = await callCreateAwsVideo(awsVideoData);
      if (awsVideo._id) {
        awsVideoId = awsVideo._id;
      }
    }

    videos.push({
      title: this.state.videoTitle,
      url: this.state.videoUrl,
      player: this.state.videoPlayer,
      fullUrl: this.state.videoFullUrl,
      ownVideo: this.state.ownVideo,
      awsVideoId: awsVideoId,
    });

    this.setState({
      videos: videos,
      videoTitle: "",
      videoUrl: "",
      videoFullUrl: "",
      ownVideo: false,
      keyOwnVideo: Math.random(),
      videoPlayer: "Youtube",
      videoKey: Math.random(),
      awsVideoId: "",
      selectVideoType: "addVideo",
    });
  }

  onSelectContentType(item, id) {
    this.setState({ [id]: item[Object.keys(item)[0]].value });
  }

  onSortEndVideos = ({ oldIndex, newIndex }) => {
    const { videos } = this.state;

    this.setState({
      videos: arrayMove(videos, oldIndex, newIndex),
    });
  };

  onSortEndQues = ({ oldIndex, newIndex }) => {
    const { chlgQueList } = this.state;

    this.setState({
      chlgQueList: arrayMove(chlgQueList, oldIndex, newIndex),
    });
  };

  onPressRemoveVideo(e) {
    let index = e.target.dataset["value"];
    let videos = this.state.videos;
    videos.splice(index, 1);
    this.setState({
      videos: videos,
    });
  }

  onPressRemoveQue(e) {
    let index = e.target.dataset["value"];
    let chlgQueList = this.state.chlgQueList;
    chlgQueList.splice(index, 1);
    this.setState({
      chlgQueList: chlgQueList,
    });
  }

  async onPressAddItem() {
    //API call
    let queList = [];
    this.state.chlgQueList.forEach((element) => {
      queList.push(element.value._id);
    });

    if (this.state.description.trim().length === 0) {
      showNotification("error", "Item Name is required", 4000);
      return;
    }

    let itemName = this.state.itemName;
    if (!itemName) {
      itemName = this.state.description;
    }

    if (
      this.state.itemType.trim().length === 0 ||
      this.state.itemType.trim() === "Create New Category"
    ) {
      showNotification("error", "Item Category is required", 4000);
      return;
    }

    let addTagIds = this.state.addTags.map((t) => t._id);
    let excludeTagIds = this.state.excludeTags.map((t) => t._id);

    let apiData = {
      name: itemName,
      description: this.state.description,
      /*eslint-disable*/
      item_type: this.state.itemType,
      content: this.state.content,
      editor_content: this.state.editorContent,
      challenge_question_list: queList,
      video_list: this.state.videos,
      is_bonus: this.state.isBonusItem,
      is_atq_item: this.state.isAtqItem,
      is_rating_item: this.state.isRatingItem,
      rating_type: this.state.ratingType,
      helpfullness_rating: this.state.helpfullnessRating,
      number_rating: this.state.numberRating,
      discussion_board: this.state.discussionBoard,
      is_discussion_board: this.state.isDiscussionBoard,
      tag_list_included: addTagIds,
      tag_list_excluded: excludeTagIds,
      content_type: this.state.contentType,
      file_url: this.state.fileUrl,
      external_url: this.state.externalUrl,
      draft_id: this.state.draftId,
      draft_image: this.state.draftImage,
      resource: this.state.isResource,
      user_videos: this.state.userVideos,
      /*eslint-enable*/
    };

    if (this.state.itemId === 0) {
      let result = await callCreateChlgItem(apiData);
      if (result._id) {
        showNotification("success", "Created Successfully", 4000);
        this.resetProps();
        if (this.props.fromPopup) {
          this.props.onCreate();
        }
      }
    } else {
      let result = await callUpdateChlgItem(this.state.itemId, apiData);
      if (result._id) {
        showNotification("success", "Updated Successfully", 4000);
        if (this.props.challengeItem !== undefined) {
          this.resetProps();
          if (this.props.fromPopup) {
            this.props.onCreate();
          }
        }
      }
    }
  }

  onClickAddQuestion() {
    let queList = this.state.chlgQueList;
    if (!this.state.selectedQue.value.question) {
      showNotification("error", "Select the question first", 4000);
      return;
    }
    queList.push({
      name: this.state.selectedQue.value.name,
      question: this.state.selectedQue.value.question,
      description: this.state.selectedQue.value.description,
      value: this.state.selectedQue.value,
    });
    this.setState({
      chlgQueList: queList,
    });
  }

  onChangeContent(html, content, draftId, draftImage) {
    let userVideoIds = [];
    let itemList = content.contentEditor.itemList;
    itemList.forEach((element) => {
      element.itemList.forEach((rowElem) => {
        rowElem.itemList.forEach((colElem) => {
          colElem.itemList.forEach((itemElem) => {
            if (itemElem.type === "video") {
              userVideoIds.push(itemElem.attributes.id);
            }
          });
        });
      });
    });
    this.setState(
      {
        editorContent: content,
        content: html,
        draftId: draftId,
        draftImage: draftImage,
        keyEditor: Math.random(),
        userVideos: userVideoIds,
      },
      () => {
        if (this.state.itemId !== 0) {
          this.onPressAddItem();
        }
      }
    );
  }

  toggleBonusItemCheckbox = (check) => {
    this.setState({ isBonusItem: check });
  };

  toggleOwnVideoCheckbox = (check) => {
    this.setState({ isOwnVideo: check });
  };

  toggleResource = (check) => {
    this.setState({ isResource: check });
  };

  toggleAtqItemCheckbox = (check) => {
    this.setState({ isAtqItem: check });
  };

  toggleRatingItemCheckbox = (check) => {
    this.setState({ isRatingItem: check });
  };

  toggleRatingHFCommentItemCheckbox = (check) => {
    this.setState({
      ...this.state,
      helpfullnessRating: {
        ...this.state.helpfullnessRating,
        isRattingHFcommentItem: check,
      },
    });
  };

  onClickCreateItemType() {
    this.setState({ showCreateItemType: !this.state.showCreateItemType });
  }

  onChangeAddTags = (tags) => {
    this.setState({ addTags: tags });
  };

  onChangeExcludeTags = (tags) => {
    this.setState({ excludeTags: tags });
  };

  onChangeFile = (file) => {
    this.setState({ fileUrl: file });
  };

  async onClickSubmitItemType() {
    let itemTypeName = this.state.itemTypeName.trim();

    if (itemTypeName.trim().length === 0) {
      showNotification("error", "Item category is required", 4000);
      return;
    }
    let existingType = this.state.itemTypes.filter((d) => {
      return d.name.toLowerCase() === itemTypeName.toLowerCase();
    });
    if (existingType.length > 0) {
      showNotification("error", "Item category already exists");
      return;
    }

    let data = [{ name: this.state.itemTypeName, type: "challenge-item" }];

    let response = await callCreateGenericTag(data);
    if (response.length > 0) {
      this.setState({ itemTypeName: "", showCreateItemType: false }, () => {
        this.getChlgItemTypes();
        this.setState({ itemType: response[0].id });
      });
    }
  }

  rednerNumberRatingComponent() {
    return (
      <div>
        <div
          className={
            this.state.numberRating.ratingNrDisplayText !== ""
              ? "mdInput mdFocussed"
              : "mdInput"
          }>
          <label>Display Text</label>
          <input
            type="text"
            name="ratingNrDisplayText"
            value={this.state.numberRating.ratingNrDisplayText}
            onChange={(event) => this.handleNumberInputChange(event)}
          />
        </div>

        <div
          className={
            this.state.numberRating.ratingNrOption1 !== ""
              ? "mdInput mdFocussed"
              : "mdInput"
          }>
          <label>Option 1</label>
          <input
            type="text"
            name="ratingNrOption1"
            value={this.state.numberRating.ratingNrOption1}
            onChange={(event) => this.handleNumberInputChange(event)}
          />
        </div>

        <div
          className={
            this.state.numberRating.ratingNrOption2 !== ""
              ? "mdInput mdFocussed"
              : "mdInput"
          }>
          <label>Option 2</label>
          <input
            type="text"
            name="ratingNrOption2"
            value={this.state.numberRating.ratingNrOption2}
            onChange={(event) => this.handleNumberInputChange(event)}
          />
        </div>

        <div
          className={
            this.state.numberRating.ratingNrOption3 !== ""
              ? "mdInput mdFocussed"
              : "mdInput"
          }>
          <label>Option 3</label>
          <input
            type="text"
            name="ratingNrOption3"
            value={this.state.numberRating.ratingNrOption3}
            onChange={(event) => this.handleNumberInputChange(event)}
          />
        </div>

        <div
          className={
            this.state.numberRating.ratingNrOption4 !== ""
              ? "mdInput mdFocussed"
              : "mdInput"
          }>
          <label>Option 4</label>
          <input
            type="text"
            name="ratingNrOption4"
            value={this.state.numberRating.ratingNrOption4}
            onChange={(event) => this.handleNumberInputChange(event)}
          />
        </div>

        <div
          className={
            this.state.numberRating.ratingNrOption5 !== ""
              ? "mdInput mdFocussed"
              : "mdInput"
          }>
          <label>Option 5</label>
          <input
            type="text"
            name="ratingNrOption5"
            value={this.state.numberRating.ratingNrOption5}
            onChange={(event) => this.handleNumberInputChange(event)}
          />
        </div>

        <div
          className={
            this.state.numberRating.ratingNrCommentText !== ""
              ? "mdInput mdFocussed"
              : "mdInput"
          }>
          <label>Please enter the comment text</label>
          <input
            type="text"
            name="ratingNrCommentText"
            value={this.state.numberRating.ratingNrCommentText}
            onChange={(event) => this.handleNumberInputChange(event)}
          />
        </div>
      </div>
    );
  }

  rednerHelpfulnessComponent() {
    return (
      <div>
        <div
          className={
            this.state.helpfullnessRating.ratingHfDisplayText !== ""
              ? "mdInput mdFocussed"
              : "mdInput"
          }>
          <label>Diplay Text</label>
          <input
            type="text"
            name="ratingHfDisplayText"
            value={this.state.helpfullnessRating.ratingHfDisplayText}
            onChange={(event) => this.handleHelpfullnessInputChange(event)}
          />
        </div>

        <div
          className={
            this.state.helpfullnessRating.ratingHfYesText !== ""
              ? "mdInput mdFocussed"
              : "mdInput"
          }>
          <label>Yes Text</label>
          <input
            type="text"
            name="ratingHfYesText"
            value={this.state.helpfullnessRating.ratingHfYesText}
            onChange={(event) => this.handleHelpfullnessInputChange(event)}
          />
        </div>

        <div
          className={
            this.state.helpfullnessRating.ratingHfNoText !== ""
              ? "mdInput mdFocussed"
              : "mdInput"
          }>
          <label>No Text</label>
          <input
            type="text"
            name="ratingHfNoText"
            value={this.state.helpfullnessRating.ratingHfNoText}
            onChange={(event) => this.handleHelpfullnessInputChange(event)}
          />
        </div>

        <div className="gap20" />
        <div className="havingHelpText">
          <Checkbox
            label={"Enable Comments"}
            isChecked={this.state.helpfullnessRating.isRattingHFcommentItem}
            handleCheckboxChange={this.toggleRatingHFCommentItemCheckbox}
            key={this.state.helpfullnessRating.keyRatingCommentCommentItem}
          />
        </div>
        <HelpTextDiv
          id="idChallengeRatingCommentItem"
          label="Learn more about the add ratting comment item"
          type="addChallengeItem"
          isInline="true"
        />

        {this.state.helpfullnessRating.isRattingHFcommentItem && (
          <div
            className={
              this.state.helpfullnessRating.ratingHfCommentText !== ""
                ? "mdInput mdFocussed"
                : "mdInput"
            }>
            <label>Please enter the comment text</label>
            <input
              type="text"
              name="ratingHfCommentText"
              value={this.state.helpfullnessRating.ratingHfCommentText}
              onChange={(event) => this.handleHelpfullnessInputChange(event)}
            />
          </div>
        )}
      </div>
    );
  }

  onClickCancelFromUpdateCategoryModal() {
    $(
      ".modalAlertOverlay.updateCategoryModalOverlay,.modalAlert.updateCategoryAlert"
    ).fadeOut(200);
  }

  async onClickUpdateFromUpdateCategoryModel() {
    if (this.state.categoryName.trim().length === 0) {
      showNotification("error", "Cagtegory name is required", 4000);
      return;
    }
    let updatedCategory = await callUpdateGenericTag(this.state.itemType, {
      name: this.state.categoryName,
    });
    if (updatedCategory._id) {
      this.getChlgItemTypes();
      $(
        ".modalAlertOverlay.updateCategoryModalOverlay,.modalAlert.updateCategoryAlert"
      ).fadeOut(200);
    }
  }

  onClickEditCategory() {
    let selectedItemType = this.state.itemTypes.find(
      (d) => d._id === this.state.itemType
    );
    this.setState({ categoryName: selectedItemType.name }, () => {
      $(
        ".modalAlertOverlay.updateCategoryModalOverlay,.modalAlert.updateCategoryAlert"
      ).fadeIn(200);
    });
  }

  renderUpdateCategoryModal() {
    let itemType = this.state.itemType;

    if (!itemType) {
      return null;
    }

    return (
      <div>
        <div className="modalAlertOverlay updateCategoryModalOverlay" />
        <div className="modalAlert updateCategoryAlert">
          <div className="clearfix">
            <div className="pull-left">
              <p>Edit Category</p>
            </div>
          </div>

          <div className="container-fluid">
            <div>
              <div
                className={
                  this.state.categoryName !== ""
                    ? "mdInput mdFocussed"
                    : "mdInput"
                }>
                <label>Category Name</label>
                <input
                  type="text"
                  name="categoryName"
                  value={this.state.categoryName}
                  onChange={(event) => this.handleInputChange(event)}
                />
              </div>
              <div className="gap20" />
            </div>
          </div>
          <div className="gap20" />
          <div className="alertFooterBtns">
            <button
              onClick={this.onClickCancelFromUpdateCategoryModal.bind(this)}
              className="btn btn-default dismissThisModal margin-right-10">
              Cancel
            </button>
            <button
              onClick={this.onClickUpdateFromUpdateCategoryModel.bind(this)}
              className="btn btn-primary dismissThisModal">
              Update
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderItemTypeComponent() {
    let itemTypes = this.state.itemTypes;
    let labelList = [];
    itemTypes.forEach((element) => {
      labelList.push({
        name: element.name,
        value: element._id,
      });
    });
    labelList.push({
      name: "Create New Category",
      value: "Create New Category",
    });
    return (
      <>
        <div className="card">
          <div className="container-fluid">
            {labelList.length > 0 && (
              <div className="row" key={this.state.keyTypes}>
                <div className="col-md-12">
                  <div className="gap10" />
                  <label>Select Item Category</label>
                  <p>
                    Note: Challenge items will be displayed inside a section
                    with the category you select here.
                  </p>
                  <div className="tabsHolder radioTabs">
                    <RadioTag
                      onCheckChange={this.onSelectItem.bind(this)}
                      labelList={labelList}
                      id={"itemType"}
                      selectedList={[this.state.itemType]}
                      selectType={"value"}
                    />
                  </div>
                  <div className="gap20" />
                </div>
              </div>
            )}

            <VelocityTransitionGroup
              enter={{ animation: "slideDown" }}
              leave={{ animation: "slideUp" }}>
              {this.state.showCreateItemType && (
                <>
                  <p>
                    The Item category is what section your customers will see
                    your content in (where the red arrow points in the example).
                  </p>
                  <img
                    src="https://memberpages.s3.amazonaws.com/1590727940802-4throixjplh-683x200-irx73zj2m3.png"
                    alt="help"
                  />
                  <div className="row">
                    <div className="col-sm-4">
                      <div
                        className={
                          this.state.itemTypeName !== ""
                            ? "mdInput mdFocussed"
                            : "mdInput"
                        }>
                        <label>Item Category</label>
                        <input
                          type="text"
                          name="itemTypeName"
                          value={this.state.itemTypeName}
                          onChange={(event) => this.handleInputChange(event)}
                        />
                      </div>
                      <div className="gap20" />
                    </div>
                    <div className="col-sm-4">
                      <div className="gap30" />
                      <div className="gap10" />
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={this.onClickSubmitItemType.bind(this)}>
                        Create Item Category
                      </button>
                    </div>
                  </div>
                </>
              )}
              {this.state.itemType !== "" && !this.state.showCreateItemType && (
                <button
                  className="btn btn-sm btn-primary"
                  onClick={this.onClickEditCategory.bind(this)}>
                  Edit Selected Category
                </button>
              )}
              <div className="gap10" />
            </VelocityTransitionGroup>
          </div>
        </div>

        {/* <div className="gap20" />
        <div className="havingHelpText">
          <Checkbox
            label={"This is a bonus item"}
            isChecked={this.state.isBonusItem}
            handleCheckboxChange={this.toggleBonusItemCheckbox}
            key={this.state.keyBonusItem}
          />
        </div> */}

        <HelpTextDiv
          id="idChallengeBonusItem"
          label="Learn more about the Challenge Bonus Item"
          type="addChallengeItem"
          isInline="true"
        />
      </>
    );
  }

  render() {
    let showTitle = true;
    if (this.props.fromPopup === true) {
      showTitle = false;
    }

    return (
      <div>
        {this.renderUpdateCategoryModal()}
        <div className="container-fluid">
          {showTitle && (
            <TitleBox
              title={
                this.state.itemId === 0
                  ? "Create Challenge Item"
                  : "Edit Challenge Item"
              }
              showBackBtn={this.state.itemId !== 0}
            />
          )}
          <div className="gap20" />

          <div className="card">
            <div className="container-fluid">
              <div className="gap20" />
              <div className="pull-right">
                <button
                  className={"btn btn-info removeFocusDecoration"}
                  onClick={() =>
                    this.setState({ showAdvanced: !this.state.showAdvanced })
                  }>
                  {this.state.showAdvanced
                    ? "Hide Advanced Options"
                    : "Show Advanced Options"}
                </button>
              </div>
              <div className="gap10" />
              <HelpTextDiv
                id="idChallengeItemDescription"
                label="Learn more about the Challenge Item Name"
                type="addChallengeItem"
                isInline="false"
              />
              <div
                className={
                  this.state.description !== ""
                    ? "mdInput mdFocussed"
                    : "mdInput"
                }>
                <label>Item Name</label>
                <input
                  type=""
                  className="challangeDesc"
                  name="description"
                  value={this.state.description}
                  onChange={(event) => this.handleInputChange(event)}
                  onBlur={(event) => this.handleOnBlurItemName(event)}
                />
              </div>
              <div className="gap20" />
              <HelpTextDiv
                id="idChallengeItemName"
                label="Learn more about the Challenge Internal Item Name"
                type="addChallengeItem"
                isInline="false"
              />
              {this.state.showAdvanced && (
                <div
                  className={
                    this.state.itemName !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Internal Item Name</label>
                  <input
                    type=""
                    className="challangeName"
                    name="itemName"
                    value={this.state.itemName}
                    onChange={(event) => this.handleInputChange(event)}
                  />
                </div>
              )}
              <div className="gap20" />

              {this.renderItemTypeComponent()}
              <div className="row">
                <SelectDiscussionBoard
                  key={this.state.keyDiscussionBoard}
                  isDiscussionBoard={this.state.isDiscussionBoard}
                  discussionBoard={this.state.discussionBoard}
                  onChange={(isDiscussionBoard, discussionBoard) => {
                    this.setState({
                      isDiscussionBoard: isDiscussionBoard,
                      discussionBoard: discussionBoard,
                    });
                  }}
                />
              </div>

              {this.state.showAdvanced && (
                <>
                  <TagUsers
                    label={"Add / Exclude Tags"}
                    notes={
                      "You can make it so that users only get access to this item if they have (or don't have) certain tags. If you would like to do that please select the tags here. Note: This area is completely optional."
                    }
                    key={this.state.keyTags}
                    type="tags"
                    selectedIncludedTags={this.state.selectedIncludedTags}
                    selectedExcludedTags={this.state.selectedExcludedTags}
                    history={this.props.history}
                    onChangeAddTags={this.onChangeAddTags}
                    onChangeExcludeTags={this.onChangeExcludeTags}
                  />
                  <div className="card">
                    <div className="container-fluid">
                      <div className="gap10" />
                      <div className="havingHelpText">
                        <Checkbox
                          label={"Add Rating"}
                          isChecked={this.state.isRatingItem}
                          handleCheckboxChange={this.toggleRatingItemCheckbox}
                          key={this.state.keyRatingItem}
                        />
                      </div>
                      <div className="gap10" />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        {this.state.isAtqItem && (
          <div className="container-fluid">
            <div className="card">
              <div className="container-fluid">
                <div className="gap20" />
                <h5>Add Questions</h5>

                <div className="gap20" />
                <HelpTextDiv
                  id="idChallengeItemName"
                  label="Learn more about the Challenge Question"
                  type="addChallengeItem"
                  isInline="false"
                />
                <TypeableSelect
                  key={this.state.keyQuestion}
                  name={"question"}
                  placeholder={"Challenge Question"}
                  selected={this.state.selectedQue}
                  onSelect={this.onSelectQuestion}
                  options={this.state.questions}
                />
                <div className="gap20" />
                <div
                  className="text-right"
                  onClick={() => this.onClickAddQuestion()}>
                  {/*eslint-disable-next-line*/}
                  <a className="btn btn-primary addQuestionButton">
                    Add Question
                  </a>
                </div>
                <div className="gap20" />
                <SortableQuestionList
                  items={this.state.chlgQueList}
                  onSortEnd={this.onSortEndQues}
                  showName={true}
                  onPressRemove={this.onPressRemoveQue.bind(this)}
                />
                <div className="gap20" />
              </div>
            </div>
          </div>
        )}

        {this.state.isRatingItem && (
          <div className="container-fluid">
            <div className="card">
              <div className="container-fluid">
                <div className="gap20" />
                <h5>Ratings</h5>
                <div className="tabsHolder radioTabs">
                  <RadioTag
                    onCheckChange={this.onSelectItem.bind(this)}
                    labelList={[
                      { name: "Helpfulness", value: "Helpfulness" },
                      { name: "Number Rating", value: "NumberRating" },
                    ]}
                    id={"ratingType"}
                    selectedList={[this.state.ratingType]}
                    selectType={"value"}
                    key={this.state.ratingTypeKey}
                  />
                </div>
                <div className="gap20" />

                {this.state.ratingType === "Helpfulness"
                  ? this.rednerHelpfulnessComponent()
                  : this.rednerNumberRatingComponent()}
              </div>

              <div className="gap20" />
            </div>
          </div>
        )}

        <div className="container-fluid">
          <div className="card">
            <div className="container-fluid">
              <div className="gap20" />
              <div className="radioBHolder">
                <div className="havingHelpText">
                  <p>Content Type</p>
                </div>

                <div className="tabsHolder radioTabs">
                  <RadioTag
                    onCheckChange={this.onSelectContentType.bind(this)}
                    labelList={[
                      { name: "Content", value: "content" },
                      { name: "File", value: "file" },
                      { name: "External Url", value: "url" },
                    ]}
                    id={"contentType"}
                    selectedList={[this.state.contentType]}
                    selectType={"value"}
                    key={this.state.keyTags}
                  />
                </div>
              </div>
              {(this.state.contentType === "content" ||
                this.state.contentType === "file") && (
                <>
                  <div className="gap20" />
                  <Checkbox
                    label={"Add as resource"}
                    isChecked={this.state.isResource}
                    handleCheckboxChange={this.toggleResource}
                    key={this.state.keyResource}
                  />
                  <div className="gap20" />
                </>
              )}
              {this.state.contentType === "content" && (
                <PageContent
                  key={this.state.keyEditor}
                  title={this.state.description}
                  previewType="mp-preview"
                  screen={"add-challenge-item"}
                  html={this.state.content}
                  content={this.state.editorContent}
                  onChangeData={this.onChangeContent.bind(this)}
                  pageName={"addChallengeItem"}
                  editorType={this.state.editorType}
                  draftId={this.state.draftId}
                  draftImage={this.state.draftImage}
                  onClickAlert={this.onPressAddItem.bind(this)}
                />
              )}
              {this.state.contentType === "file" && (
                <>
                  <SelectAddImage
                    contentType="file"
                    label="Challenge Item File"
                    onChangeFile={this.onChangeFile}
                    file={this.state.fileUrl}
                    key={this.state.keyFile}
                  />
                  <div className="gap20" />
                </>
              )}
              {this.state.contentType === "url" && (
                <div
                  className={
                    this.state.externalUrl !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>URL</label>
                  <input
                    type="text"
                    name="externalUrl"
                    value={this.state.externalUrl}
                    onChange={(event) => this.handleInputChange(event)}
                  />
                </div>
              )}
              <div className="gap20" />
            </div>{" "}
          </div>
        </div>

        {this.state.showAdvanced && (
          <div className="container-fluid">
            <div className="card">
              <div className="container-fluid">
                <div className="gap20" />
                <div className="havingHelpText">
                  <h5>Add Videos</h5>
                </div>
                <HelpTextDiv
                  id="idChallengeItemAddVideos"
                  label="Learn more about the Challenge Item Add Videos"
                  type="addChallengeItem"
                  isInline="true"
                />
                <div className="gap20" />
                <div className="row">
                  <div className="col-sm-4">
                    <div className="havingHelpText">
                      <label>Video Player</label>
                    </div>
                    <HelpTextDiv
                      id="idChallengeItemVideoPlayer"
                      label="Learn more about the Video Player"
                      type="addChallengeItem"
                      isInline="true"
                    />
                    <div className="tabsHolder radioTabs">
                      <RadioTag
                        onCheckChange={this.onSelectItem.bind(this)}
                        labelList={[
                          { name: "YouTube", value: "Youtube" },
                          { name: "Vimeo", value: "Vimeo" },
                          { name: "Voo Player", value: "vooplayer" },
                          { name: "Member Pages", value: "memberpages" },
                        ]}
                        id={"videoPlayer"}
                        selectedList={[this.state.videoPlayer]}
                        selectType={"value"}
                        key={this.state.videoKey}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  {this.state.videoPlayer !== "memberpages" ? (
                    <div className="row">
                      <div className="col-sm-4">
                        <HelpTextDiv
                          id="idChallengeItemVideoTitle"
                          label="Learn more about the Challenge Item Video Title"
                          type="addChallengeItem"
                          isInline="false"
                        />
                        <div
                          className={
                            this.state.videoTitle !== ""
                              ? "mdInput mdFocussed"
                              : "mdInput"
                          }>
                          <label>Video Title</label>
                          <input
                            type="text"
                            name="videoTitle"
                            value={this.state.videoTitle}
                            onChange={(event) => this.handleInputChange(event)}
                          />
                        </div>
                        <div className="gap20" />
                      </div>
                      <div className="col-sm-4">
                        <HelpTextDiv
                          id="idChallengeItemVideoId"
                          label="Learn more about the Challenge Item Video ID"
                          type="addChallengeItem"
                          isInline="false"
                        />
                        <div
                          className={
                            this.state.videoUrl !== ""
                              ? "mdInput mdFocussed"
                              : "mdInput"
                          }>
                          <label>
                            {this.state.videoPlayer === "vooplayer"
                              ? "Video ID"
                              : "Video ID"}
                          </label>
                          <input
                            type="text"
                            className="videoUrl"
                            name="videoUrl"
                            value={this.state.videoUrl}
                            onChange={(event) => this.handleInputChange(event)}
                          />
                        </div>
                        <div className="gap20" />
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="gap10" />
                      <label>Select Type</label>
                      <div className="tabsHolder radioTabs">
                        <RadioTag
                          onCheckChange={this.onSelectVideoType.bind(this)}
                          labelList={[
                            {
                              name: "Add Video from Youtube",
                              value: "addVideo",
                            },
                            { name: "Select Video", value: "selectVideo" },
                          ]}
                          id={"selectVideoType"}
                          selectedList={[this.state.selectVideoType]}
                          selectType={"value"}
                          key={this.state.keyVideoType}
                        />
                      </div>
                      <div className="gap10" />
                      {this.state.selectVideoType === "selectVideo" && (
                        <SelectAddImage
                          contentType="video"
                          label="Video"
                          type="select"
                          onChangeVideo={this.onChangeVideo.bind(this)}
                        />
                      )}

                      <div className="row">
                        <div className="col-sm-4">
                          <HelpTextDiv
                            id="idChallengeItemVideoTitle"
                            label="Learn more about the Challenge Item Video Title"
                            type="addChallengeItem"
                            isInline="false"
                          />
                          <div
                            className={
                              this.state.videoTitle !== ""
                                ? "mdInput mdFocussed"
                                : "mdInput"
                            }>
                            <label>Video Title</label>
                            <input
                              type="text"
                              name="videoTitle"
                              value={this.state.videoTitle}
                              onChange={(event) =>
                                this.handleInputChange(event)
                              }
                            />
                          </div>
                          <div className="gap20" />
                        </div>
                        <div className="col-sm-4">
                          <div
                            className={
                              this.state.videoFullUrl !== ""
                                ? "mdInput mdFocussed"
                                : "mdInput"
                            }>
                            <label>Video URL</label>
                            <input
                              type="text"
                              className="videoFullUrl"
                              name="videoFullUrl"
                              value={this.state.videoFullUrl}
                              onChange={(event) =>
                                this.handleInputChange(event)
                              }
                            />
                          </div>
                          <div className="gap20" />
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <VideoPlayerNote
                  videoPlayer={this.state.videoPlayer}
                  key={this.state.videoKey}
                />
                {this.state.videoPlayer === "memberpages" &&
                  this.state.selectVideoType === "addVideo" && (
                    <>
                      <lable>
                        We can download your video from youtube and host it for
                        you. You must first upload your video to your youtube
                        and paste the url above.
                      </lable>
                      <div className="gap10" />
                      <Checkbox
                        label={
                          "I own or have copyright access to the video above."
                        }
                        isChecked={this.state.isOwnVideo}
                        handleCheckboxChange={this.toggleOwnVideoCheckbox}
                        key={this.state.keyOwnVideo}
                      />
                    </>
                  )}
                <div
                  className="text-right"
                  onClick={() => this.onClickAddVideo()}>
                  {/*eslint-disable-next-line*/}
                  <a className="btn btn-primary addVideoButton">Add Video</a>
                </div>
                <div className="gap20" />
                <SortableVideoList
                  items={this.state.videos}
                  onSortEnd={this.onSortEndVideos}
                  onPressRemove={this.onPressRemoveVideo.bind(this)}
                />
                <div className="gap20" />
              </div>
            </div>
          </div>
        )}
        <div className="container-fluid">
          <div className="text-right">
            <button
              className="btn btn-primary"
              onClick={() => this.onPressAddItem()}>
              {this.state.itemId === 0
                ? "Create Challenge Item"
                : "Update Challenge Item"}
            </button>
          </div>
          <div className="gap20" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  message: state.challengeReducer.message,
  success: state.challengeReducer.success,
  chlgQuestions: state.challengeReducer.chlgQuestions,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getChlgQues,
      resetStatus,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(AddChallengeItem);
