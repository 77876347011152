import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import $ from "jquery";

import FilterList from "../../../components/form/filterList";
import TitleBox from "../../../components/general/titleBox";
import { filterByTags } from "../../../helpers/filters";

import { getPromoCodes } from "../../../modules/actions/index";
import { callDeletePromoCodeById } from "../../../services";

class ViewPromoCode extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      codes: [],
      codesBackup: [],
      selectedCode: {},
      filterList: [],
      codeDurations: [
        { name: "All", value: "all", selected: true },
        { name: "Once", value: "once", selected: false },
        { name: "Multi Month", value: "repeating", selected: false },
        { name: "Forever", value: "forever", selected: false }
      ],
      codeTypes: [
        { name: "All", value: "all", selected: true },
        { name: "Single", value: "single", selected: false },
        { name: "Recurring", value: "recurring", selected: false }
      ]
    };
  }

  componentDidMount() {
    this.props.getPromoCodes();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.promoCodes.data) {
      this.setState({
        codes: newProps.promoCodes.data,
        codesBackup: newProps.promoCodes.data
      });
    }
  }

  filterUpdated(updatedFilter, name, type, searchFields) {
    let filterList = this.state.filterList;
    filterList[name] = {
      name: name,
      type: type,
      searchFields: searchFields,
      values: updatedFilter
    };
    this.setState(
      {
        filterList: filterList
      },
      () => {
        let data = filterByTags(this.state.codesBackup, filterList);
        this.setState({
          codes: data
        });
      }
    );
  }

  onClickDeleteCode = code => {
    this.setState({ selectedCode: code }, () => {
      $(".modalAlertOverlay,.modalAlert").fadeIn(200);
    });
  };

  renderPromoCodes() {
    var returnVals = [];
    let items = this.state.codes;
    items.forEach(element => {
      returnVals.push(
        <tr key={element._id}>
          <td>{element.code}</td>
          <td>{element.percent_off ? element.percent_off : "-"}</td>
          <td>{element.amount_off ? element.amount_off : "-"}</td>
          <td>
            {element.duration_type === "repeating"
              ? `${element.duration_type} (${element.duration_months})`
              : element.duration_type}
          </td>
          <td>{element.max_redemptions || "-"}</td>
          <td>{element.times_used || "0"}</td>
          <td>{element.type}</td>
          <td className="min-width-100">
            <button
              onClick={this.onClickDeleteCode.bind(this, element)}
              className="btn btn-danger btn-sm margin-right-10 border-0">
              <i className="fa fa-trash" />
            </button>
          </td>
        </tr>
      );
    });
    return returnVals;
  }

  onClickNoAlert = () => {
    $(".modalAlertOverlay,.modalAlert").fadeOut(200);
  };

  onClickYesAlert = () => {
    callDeletePromoCodeById(this.state.selectedCode._id).then(d => {
      $(".modalAlertOverlay,.modalAlert").fadeOut(200);
      this.props.getPromoCodes();
    });
  };

  renderConfirmationAlert() {
    let selectedCode = this.state.selectedCode;
    let code = "";
    if (selectedCode.code) {
      code = selectedCode.code;
    }
    return (
      <div>
        <div className="modalAlertOverlay" />
        <div className="modalAlert">
          <p>{`Are you sure you want to delete "${code}" code?`}</p>
          <div className="alertFooterBtns">
            <button
              className="btn btn-default closeModal-No margin-right-10"
              onClick={this.onClickNoAlert}>
              No
            </button>
            <button
              className="btn btn-primary closeModal-Yes"
              onClick={this.onClickYesAlert}>
              Yes
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderFilterView() {
    return (
      <>
        <div className="list-group-item">
          <FilterList
            label={"Search Codes"}
            name={"search"}
            type={"search"}
            searchFields={["code"]}
            onFinish={this.filterUpdated.bind(this)}
          />
        </div>
        <div className="list-group-item">
          <p>Select a duration</p>
          <FilterList
            filterList={this.state.codeDurations}
            name={"searchDuration"}
            type={"single"}
            searchFields={["duration_type"]}
            onFinish={this.filterUpdated.bind(this)}
          />
        </div>
        <div className="list-group-item">
          <p>Select a type</p>
          <FilterList
            filterList={this.state.codeTypes}
            name={"searchType"}
            type={"single"}
            searchFields={["type"]}
            onFinish={this.filterUpdated.bind(this)}
          />
        </div>
      </>
    );
  }

  render() {
    return (
      <div>
        {this.renderConfirmationAlert()}
        <div className="container-fluid">
          <TitleBox title="Promo Code Overview" />
          <div className="gap20" />
          <div className="card">
            <div className="list-group list-group-flush">
              {this.renderFilterView()}
              <div className="list-group-item">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Code</th>
                      <th>Percent Off</th>
                      <th>Amount Off</th>
                      <th>Duration</th>
                      <th>Max Redemptions</th>
                      <th>Redemptions</th>
                      <th>Type</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>{this.renderPromoCodes()}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  promoCodes: state.billingReducer.promoCodes
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ getPromoCodes }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewPromoCode);
