import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import $ from "jquery";

import TitleBox from "../../../components/general/titleBox";
import {
  callGetLicenses,
  callGetStripePlan,
  callCreateStripeCustomer,
  callCreateCompanyOrder,
  callUpdateCompany,
  callGetUsersByQuery,
} from "../../../services";
import TypeableSelect from "../../../components/form/typeableSelect";
import PaymentForm from "../../../components/payment/paymentForm";
import Checkbox from "../../../components/form/checkBox";
import RadioTag from "../../../components/form/radioTag";
import { showNotification } from "../../../helpers";

class AddCompanyLicense extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    let company = {};
    let showAlreadyCard = false;
    let licenses = [];

    if (this.props.history.location.state !== undefined) {
      company = this.props.history.location.state.company;
      showAlreadyCard = company.billing_details.length > 0;
      licenses = company.licenses ? company.licenses.slice(0) : [];
    }
    this.state = {
      userData: this.props.userData || {},
      selectedLicense: { value: "", label: "" },
      selectedLicenses: licenses,
      allLicenses: [],
      licenses: [],
      plans: [],
      count: "",
      keyLicense: Math.random(),
      stripeToken: "",
      isBilling: false,
      keyPlan: Math.random(),
      keyCheckbox: Math.random(),
      billingType: "add",
      paymentData: {
        amount: "",
        selectedDates: [],
        subscriptionStartDate: "",
        selectedPaymentType: "",
        selectedPlan: "",
      },
      companyInfo: company,
      staffUsers: [],
      selectedStaffUser: { value: "", label: "" },
      keySelectUser: Math.random(),
      showAlreadyCard: showAlreadyCard,
      isAlreadyCard: false,
      invoiceDescription: "",
    };
  }

  componentDidMount() {
    callGetLicenses().then((d) => {
      let licenses = [];
      d.data.forEach((element) => {
        licenses.push({ value: element, label: element.name });
      });
      this.setState({
        allLicenses: d.data,
        licenses: licenses,
        keyLicense: Math.random(),
      });
    });

    callGetStripePlan().then((d) => {
      this.setState({ plans: d.data });
    });

    this.procecssUsers();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.userData) {
      this.setState({ userData: newProps.userData });
    }
  }

  procecssUsers() {
    /*eslint-disable*/
    let query = {
      query: {
        member_type: "staff",
      },
    };
    /*eslint-enable*/

    callGetUsersByQuery(query).then((d) => {
      let fUsers = d.data;
      let staffUsers = [];
      let selectedStaffUser = { value: "", label: "" };
      fUsers.forEach((element) => {
        staffUsers.push({ value: element, label: element.name });
        if (element._id === this.state.userData._id) {
          selectedStaffUser = { value: element, label: element.name };
        }
      });
      this.setState({
        staffUsers: staffUsers,
        keySelectUser: Math.random(),
        selectedStaffUser: selectedStaffUser,
      });
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  toggleBillingCheckbox = (check) => {
    this.setState({
      isBilling: check,
    });
  };

  onSelectStaffUser = (user) => {
    this.setState({ selectedStaffUser: user });
  };

  onChangePaymentData(data) {
    this.setState({
      paymentData: {
        selectedDates: data.selectedDates,
        subscriptionStartDate: data.subscriptionStartDate,
        selectedPaymentType: data.selectedPaymentType,
        selectedPlan: data.selectedPlan,
      },
    });
  }

  onSelectBillingType(item, id) {
    let value = item[Object.keys(item)[0]].value;
    this.setState({ [id]: value });
  }

  onSelectLicense = (license) => {
    this.setState({ selectedLicense: license });
  };

  toggleAlreadyCardCheckbox = (check) => {
    this.setState({
      isAlreadyCard: check,
      keyPlan: Math.random(),
    });
  };

  calculateLicenseCode(count) {
    let ids = [];
    for (let i = 0; i < count; i++) {
      let code = Math.random().toString(36).substring(6);
      ids.push({ code: code, active: true });
    }
    return ids;
  }

  onClickAddLicense() {
    if (this.state.selectedLicense.label.trim().length === 0) {
      showNotification("error", "Please select license", 4000);
      return;
    }

    if (this.state.count.trim().length === 0) {
      showNotification("error", "Please number of licenses", 4000);
      return;
    }

    /*eslint-disable*/
    let licenseInfo = {
      id: this.state.selectedLicense.value._id,
      count: this.state.count,
      valid_codes: this.calculateLicenseCode(this.state.count),
      active: true,
    };
    /*eslint-enable*/

    let selectedLicenses = this.state.selectedLicenses;
    selectedLicenses.push(licenseInfo);

    this.setState({
      selectedLicenses: selectedLicenses,
      count: "",
      selectedLicense: { value: "", label: "" },
      keyLicense: Math.random(),
    });
  }

  onClickRemoveLicense(index) {
    let selectedLicenses = this.state.selectedLicenses;
    selectedLicenses.splice(index, 1);

    this.setState({ selectedLicenses: selectedLicenses });
  }

  renderAddedLicenses() {
    let selectedLicenses = this.state.selectedLicenses;
    let returnVals = [];

    for (let i = 0; i < selectedLicenses.length; i++) {
      const element = selectedLicenses[i];
      let license = this.state.allLicenses.filter((d) => {
        return d._id === element.id;
      });

      if (license.length > 0) {
        returnVals.push(
          <div key={i}>
            {license[0].name} (<b>{element.count}</b>)
            <button
              onClick={this.onClickRemoveLicense.bind(this, i)}
              className="btn btn-danger btn-sm"
              style={{ float: "right" }}>
              Remove
            </button>
            <div className="gap5" />
          </div>
        );
      }
    }
    return returnVals;
  }

  onCreateToken(response) {
    if (response.error) {
      showNotification("error", response.error.message, 4000);
    } else if (response.token) {
      this.setState({ stripeToken: response.token.id }, () => {
        if (this.state.companyInfo.billing_details.length === 0) {
          this.callCreateStripeCustomerAPI();
        } else {
          this.callCreateStripeCardAPI();
        }
      });
    }
  }

  callCreateStripeCustomerAPI() {
    // call create stripe customer api
    let companyInfo = this.state.companyInfo;
    let apiData = {
      action: "create-company-customer",
      data: {
        /*eslint-disable*/
        company_id: companyInfo._id,
        is_update_member: 1,
        is_create_card: 1,
        email: companyInfo.email,
        name: companyInfo.name,
        token: this.state.stripeToken,
        /*eslint-enable*/
      },
    };
    callCreateStripeCustomer(apiData).then((data) => {
      if (data.id) {
        this.callCreateCompanyOrderAPI();
      } else {
        showNotification(
          "error",
          "Something went wrong while creating the stripe customer"
        );
      }
    });
  }

  callCreateStripeCardAPI() {
    let apiData = {
      action: "create-card",
      data: {
        /*eslint-disable*/
        token: this.state.stripeToken,
        customer_id: this.state.companyInfo.billing_details[0]
          .stripe_customer_id,
        /*eslint-enable*/
      },
    };
    callCreateStripeCustomer(apiData).then((data) => {
      if (data.id) {
        this.callCreateCompanyOrderAPI();
      } else {
        showNotification("error", data.message);
      }
    });
  }

  callCreateCompanyOrderAPI() {
    // call create company order api
    let companyInfo = this.state.companyInfo;

    let apiData = {};
    var planId = "ffffffffffffffffffffffff";
    var totalCharges = "1";
    var customChargeDates = this.state.paymentData.selectedDates;
    var firstChargeDate = "";
    var amount = "";
    var staffId = this.state.userData._id;

    if (this.state.selectedStaffUser.label.length > 0) {
      staffId = this.state.selectedStaffUser.value._id;
    }

    if (this.state.billingType === "no") {
      /*eslint-disable*/
      apiData = {
        company_id: companyInfo._id,
        staff_id: staffId,
        status: "completed",
        processor: "stripe",
        plan_id: planId,
        charge_amount: "",
        total_charges: "",
        invoice_description: this.state.invoiceDescription,
        first_charge_date: firstChargeDate,
        custom_charge_dates: [],
      };
      /*eslint-enable*/
    } else {
      if (this.state.paymentData.selectedPaymentType === "subscription") {
        planId = this.state.paymentData.selectedPlan;
        totalCharges = "";
        customChargeDates = [];
        amount = "";
        firstChargeDate = this.state.paymentData.subscriptionStartDate;
      } else {
        if (this.state.paymentData.selectedDates.length > 0) {
          firstChargeDate = this.state.paymentData.selectedDates[0].date;
          customChargeDates = this.state.paymentData.selectedDates;
          amount = 0;
          this.state.paymentData.selectedDates.forEach((element) => {
            amount = amount + parseFloat(element.amount);
          });
          totalCharges = this.state.paymentData.selectedDates.length.toString();
        }
      }

      customChargeDates.forEach((element) => {
        element.done = false;
      });

      /*eslint-disable*/
      apiData = {
        company_id: companyInfo._id,
        staff_id: staffId,
        status: "pending",
        processor: "stripe",
        plan_id: planId,
        charge_amount: amount.toString(),
        invoice_description: this.state.invoiceDescription,
        total_charges: totalCharges,
        first_charge_date: firstChargeDate,
        custom_charge_dates: customChargeDates,
      };
      /*eslint-enable*/
    }

    callCreateCompanyOrder(apiData).then((d) => {
      if (d._id) {
        this.callUpdateCompanyAPI();
      } else {
        showNotification("error", "Something went wrong", 4000);
      }
    });
  }

  callUpdateCompanyAPI() {
    /*eslint-disable*/
    let apiData = {
      licenses: this.state.selectedLicenses,
    };
    /*eslint-enable*/
    callUpdateCompany(this.state.companyInfo._id, apiData).then((d) => {
      if (d._id) {
        showNotification("success", "License Added Successfully", 4000);
      } else {
        showNotification("error", "Something went wrong", 4000);
      }
    });
  }

  onClickAddCompanyLicense() {
    if (this.state.selectedLicenses.length === 0) {
      showNotification("error", "Select atleast one license", 4000);
      return;
    }

    if (
      this.state.companyInfo.licenses.length ===
      this.state.selectedLicenses.length
    ) {
      $(".modalAlertOverlay,.modalAlert").fadeIn(200);
    } else {
      this.processBilling();
    }
  }

  processBilling() {
    if (this.state.billingType === "add") {
      if (this.state.isAlreadyCard) {
        this.callCreateCompanyOrderAPI();
      } else {
        this.paymentForm.click();
      }
    } else if (this.state.billingType === "no") {
      this.callCreateCompanyOrderAPI();
    } else {
      this.callCreateCompanyOrderAPI();
    }
  }

  renderConfirmAlert() {
    return (
      <div>
        <div className="modalAlertOverlay" />
        <div className="modalAlert">
          <p>Note: You have not added new licenses</p>
          <div className="alertFooterBtns">
            {/*eslint-disable*/}
            <a
              className="btn btn-default closeModal-No margin-right-10"
              onClick={this.onClickNoAlert}>
              Cancel
            </a>
            <a
              className="btn btn-primary closeModal-Yes"
              onClick={this.onClickYesAlert}>
              Continue
            </a>
            {/*eslint-enable*/}
          </div>
        </div>
      </div>
    );
  }

  onClickNoAlert = () => {
    $(".modalAlertOverlay,.modalAlert").fadeOut(200);
  };

  onClickYesAlert = () => {
    $(".modalAlertOverlay,.modalAlert").fadeOut(200);
    this.processBilling();
  };

  render() {
    if (!this.state.companyInfo._id) {
      return null;
    }
    return (
      <div>
        {this.renderConfirmAlert()}
        <div className="container-fluid">
          <TitleBox
            title={`Add Company License - ${this.state.companyInfo.name}`}
            showBackBtn={true}
          />
          <div className="gap20" />

          <div className="card">
            <div className="container-fluid">
              <div className="gap20" />
              <h5 className="noMargin">Add Licenses</h5>
              <div className="gap20" />
              <div className="row">
                <div className="col-md-6">
                  <TypeableSelect
                    key={this.state.keyLicense}
                    name={"selectedLicense"}
                    placeholder={"Search License"}
                    selected={this.state.selectedLicense}
                    onSelect={this.onSelectLicense}
                    options={this.state.licenses}
                  />
                </div>
                <div className="col-md-6">
                  <div
                    className={
                      this.state.count !== "" ? "mdInput mdFocussed" : "mdInput"
                    }>
                    <label>Count</label>
                    <input
                      type="number"
                      name="count"
                      value={this.state.count}
                      onChange={(event) => this.handleInputChange(event)}
                    />
                  </div>
                  <div className="gap20" />
                </div>
              </div>

              <div className="gap20" />
              <div
                className="text-right"
                onClick={() => this.onClickAddLicense()}>
                <button className="btn btn-primary addQuestionButton">
                  Add License
                </button>
              </div>
              <div className="gap20" />
            </div>
          </div>

          {this.state.selectedLicenses.length > 0 && (
            <div className="card">
              <div className="container-fluid">
                <div className="gap20" />
                <h5 className="noMargin">Added Licenses</h5>
                <div className="gap20" />
                <div className="addedRule">{this.renderAddedLicenses()}</div>
                <div className="gap20" />
              </div>
            </div>
          )}

          <div className="card">
            <div className="container-fluid">
              <div className="gap20" />
              <h5 className="noMargin">Billing Details</h5>
              <div className="gap20" />
              <div className="radioBHolder">
                <div className="tabsHolder radioTabs">
                  <RadioTag
                    onCheckChange={this.onSelectBillingType.bind(this)}
                    labelList={[
                      { name: "Add Billing", value: "add" },
                      { name: "Do Not Add Billing", value: "no" },
                      { name: "Customer Add Billing", value: "customer" },
                    ]}
                    id={"billingType"}
                    selectedList={[this.state.billingType]}
                    selectType={"value"}
                  />
                </div>
              </div>
              <div className="gap20" />

              {this.state.billingType === "add" && (
                <div>
                  {this.state.showAlreadyCard && (
                    <div>
                      <div className="gap10" />
                      <Checkbox
                        label={"Use Card Already On File"}
                        isChecked={this.state.isAlreadyCard}
                        handleCheckboxChange={this.toggleAlreadyCardCheckbox.bind(
                          this
                        )}
                        key={this.state.keyCheckbox}
                      />
                      <div className="gap20" />
                    </div>
                  )}
                  <PaymentForm
                    paymentRef={(ref) => (this.paymentForm = ref)}
                    onCreateToken={this.onCreateToken.bind(this)}
                    showPayment={true}
                    isAlreadyCard={this.state.isAlreadyCard}
                    keyPlan={this.state.keyPlan}
                    plans={this.state.plans}
                    cardData={{
                      name: this.state.companyInfo.name,
                    }}
                    onChangeData={this.onChangePaymentData.bind(this)}
                  />
                </div>
              )}
              {this.state.billingType === "customer" && (
                <PaymentForm
                  paymentRef={(ref) => (this.paymentForm = ref)}
                  onCreateToken={this.onCreateToken.bind(this)}
                  showPayment={true}
                  isAlreadyCard={true}
                  keyPlan={this.state.keyPlan}
                  plans={this.state.plans}
                  cardData={{
                    name: this.state.companyInfo.name,
                  }}
                  onChangeData={this.onChangePaymentData.bind(this)}
                />
              )}
            </div>
          </div>

          <div className="card">
            <div className="container-fluid">
              <div className="gap20" />
              <h5 className="noMargin">Invoice Details</h5>
              <div className="gap20" />
              <div className="row">
                <div className="col-sm-12">
                  <div className="mdTextArea multiLine">
                    <div
                      className={
                        this.state.invoiceDescription !== ""
                          ? "mdInput mdFocussed"
                          : "mdInput"
                      }>
                      <label>Description</label>
                      <textarea
                        name="invoiceDescription"
                        value={this.state.invoiceDescription}
                        onChange={(event) => this.handleInputChange(event)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="gap20" />
            </div>
          </div>

          <div className="card">
            <div className="container-fluid">
              <div className="gap20" />
              <h5 className="noMargin">Sales Rep</h5>
              <div className="gap20" />
              <div className="row">
                <div className="col-sm-6">
                  <TypeableSelect
                    key={this.state.keySelectUser}
                    name={"staffUser"}
                    placeholder={"Select A User"}
                    selected={this.state.selectedStaffUser}
                    onSelect={this.onSelectStaffUser}
                    options={this.state.staffUsers}
                  />
                </div>
              </div>
              <div className="gap20" />
            </div>
          </div>

          <div
            className="text-right"
            onClick={() => this.onClickAddCompanyLicense()}>
            {/*eslint-disable-next-line*/}
            <a className="btn btn-primary">Add Company License</a>
          </div>
          <div className="gap20" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.authReducer.userData,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddCompanyLicense);
