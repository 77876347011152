import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import TitleBox from "../../../../components/general/titleBox";
import TagSelect from "../../../../components/form/tagSelect";
import { callGetSystemTags, callGetTagFilterUsers } from "../../../../services";
import moment from "moment-timezone";
import { exportCSV } from "../../../../helpers/exportCSV";
import DatePicker from "react-date-picker";

class TagAttributesReport extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      keyTag: Math.random(),
      systemTags: [],
      selectedIncludedTags: [],
      addTags: [],
      userInfos: [],
      userCount: 0,
      attributes: [],
      startFilterDate: new Date(),
      endFilterDate: new Date(),
      keyFilterDate: Math.random(),
      showResult: false,
    };
  }

  componentDidMount() {
    this.getSystemTags();
  }

  async getSystemTags() {
    let tags = await callGetSystemTags();
    this.setState({
      systemTags: tags.data,
      keyTag: Math.random(),
      keyFilterDate: Math.random(),
    });
  }

  onChangeAddTags = (tags) => {
    this.setState({ addTags: tags });
  };

  onClickGetUsers() {
    let tags = this.state.addTags;
    let includedTags = tags.map((t) => t._id);
    this.filterUserForTags(includedTags);
  }

  getTagInfo(user, includedTags) {
    let tagList = user.tag_list || [];
    let tags = [];
    includedTags.forEach((element) => {
      let fList = tagList.filter((d) => d.id.toString() === element.toString());
      fList.forEach((e) => {
        tags.push(e);
      });
    });
    tags.sort((a, b) => b.created - a.created);
    return tags[0];
  }

  onClickExport() {
    let rows = [];
    let headers = [
      { id: "name", display: "Name" },
      { id: "emailAddress", display: "Email Address" },
      { id: "tagDate", display: "Tag Date" },
    ];
    this.state.attributes.forEach((element) => {
      headers.push({ id: element, display: element });
    });

    this.state.userInfos.forEach((element) => {
      let object = {
        name: element.name,
        emailAddress: element.email_address,
        tagDate: element.tagDate,
      };
      let attributes = element.tagInfo.attributes || [];
      this.state.attributes.forEach((att) => {
        let fAttr = attributes.filter((d) => d.name === att);
        if (fAttr.length > 0) {
          object[att] = fAttr[0].value || "";
        } else {
          object[att] = "";
        }
      });
      rows.push(object);
    });
    exportCSV(headers, rows, "tag_attributes");
  }

  async filterUserForTags(includedTags) {
    let userCount = 0;

    let selectedUsers = [];
    let userInfos = [];

    if (includedTags.length > 0) {
      let data = {
        action: "tag-filter-users",
        data: {
          includedTags: includedTags,
          excludedTags: [],
          dateFilter: "custom",
          startDate: this.state.startFilterDate,
          endDate: this.state.endFilterDate,
        },
      };
      let d = await callGetTagFilterUsers(data);
      userCount = d.userCount;
      selectedUsers = d.selectedUsers;
      userInfos = d.userInfos;
    } else {
      userCount = 0;
    }

    userInfos.forEach((element) => {
      element.tagInfo = this.getTagInfo(element, includedTags);
      element.tagDate = moment(element.tagInfo.created).format("Do MMM YYYY");
    });

    let attributes = [];
    userInfos.forEach((element) => {
      let attrs = element.tagInfo.attributes || [];
      attrs.forEach((att) => {
        if (attributes.indexOf(att.name) === -1) {
          attributes.push(att.name);
        }
      });
    });

    this.setState({
      userCount: userCount,
      selectedUsers: selectedUsers,
      userInfos: userInfos,
      attributes,
      showResult: true,
    });
  }

  renderUserRow() {
    let returnVals = [];
    let userInfos = this.state.userInfos;
    let attributes = this.state.attributes || [];

    for (let i = 0; i < userInfos.length; i++) {
      const user = userInfos[i];
      const tdList = [];
      attributes.forEach((attr) => {
        if (user.tagInfo.attributes) {
          let fAt = user.tagInfo.attributes.filter((d) => d.name === attr);
          if (fAt.length > 0) {
            tdList.push(<td>{fAt[0].value}</td>);
          }
        }
      });

      returnVals.push(
        <tr key={user._id}>
          <td>{user.name}</td>
          <td>{user.email_address}</td>
          <td>{moment(user.tagInfo.created).format("Do MMM YYYY")}</td>
          {tdList}
        </tr>
      );
    }
    return returnVals;
  }

  onChangeFilterDate(name, date) {
    this.setState({
      [name]: date,
    });
  }

  render() {
    return (
      <div>
        <div className="container-fluid">
          <TitleBox title="Tag Attributes Report" showBackBtn={true} />
          <div className="gap20" />
          <div className="card">
            <div className="container-fluid">
              <TagSelect
                id={"tag-reports-include-tag"}
                key={this.state.keyTag}
                selectedValues={this.state.selectedIncludedTags}
                searchKey={"name"}
                selectKey={"name"}
                btnText={"Add"}
                lblText={"Include Tag"}
                data={this.state.systemTags}
                onChange={this.onChangeAddTags}
              />
              <div className="gap20" />
              <div className="row">
                <div className="col-2">
                  <label className="inputFakeLabelFocussed">
                    Select Start Date
                  </label>
                  <div>
                    <DatePicker
                      key={this.state.keyFilterDate}
                      value={this.state.startFilterDate}
                      onChange={this.onChangeFilterDate.bind(
                        this,
                        "startFilterDate"
                      )}
                    />
                  </div>
                </div>
                <div className="col-2">
                  <label className="inputFakeLabelFocussed">
                    Select End Date
                  </label>
                  <div>
                    <DatePicker
                      key={this.state.keyFilterDate}
                      value={this.state.endFilterDate}
                      onChange={this.onChangeFilterDate.bind(
                        this,
                        "endFilterDate"
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="gap20" />
              <div className="text-right">
                <button
                  className="btn btn btn-primary"
                  onClick={this.onClickGetUsers.bind(this)}>
                  Submit
                </button>
              </div>
              <div className="gap20" />
            </div>
          </div>

          {this.state.showResult && (
            <div className="card">
              <div className="container-fluid">
                <div className="gap20" />

                <div className="clearfix">
                  <div className="pull-left detailed-stats">
                    <h5 className="noMargin"> Users </h5>
                  </div>
                  <div className="pull-right detailed-stats">
                    {this.state.userCount > 0 && (
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={this.onClickExport.bind(this)}>
                        Export
                      </button>
                    )}
                  </div>
                </div>

                <div className="gap20" />
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Tag Date</th>
                      {this.state.attributes.map((i) => (
                        <th>{i}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>{this.renderUserRow()}</tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TagAttributesReport);
