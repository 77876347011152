import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { arrayMove } from "react-sortable-hoc";

import RadioTag from "../../../components/form/radioTag";
import { SortableVideoList } from "../../../components/form/sortableList";
import TitleBox from "../../../components/general/titleBox";
import {
  createExercise,
  updateExercise,
  resetStatus,
} from "../../../modules/actions/index";
import { showNotification } from "../../../helpers";
import HelpTextDiv from "../../../components/form/helpTextDiv";
import VideoPlayerNote from "../../../components/general/videoPlayerNote";

class AddExercise extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    let exerciseItem = {
      _id: 0,
      name: "",
      description: "",
      notes: "",
      videoList: [],
    };

    if (this.props.history.location.state !== undefined) {
      let exerItem = this.props.history.location.state.exerciseItem;

      exerciseItem._id = exerItem._id;
      exerciseItem.name = exerItem.name;
      exerciseItem.description = exerItem.description;
      exerciseItem.notes = exerItem.notes;
      exerciseItem.videoList = exerItem.video_list;
    }

    this.state = {
      exerId: exerciseItem._id,
      name: exerciseItem.name,
      description: exerciseItem.description,
      notes: exerciseItem.notes,
      videos: exerciseItem.videoList,
      videoTitle: "",
      videoUrl: "",
      videoPlayer: "Youtube",
      videoKey: Math.random(),
    };
  }

  componentWillReceiveProps(newProps) {
    if (newProps.message) {
      if (newProps.success) {
        if (this.state.exerId === 0) {
          this.resetProps();
        }
        showNotification("success", newProps.message, 5000);
      } else {
        showNotification("error", newProps.message, 5000);
      }
      this.props.resetStatus();
    }
  }

  resetProps() {
    this.setState({
      name: "",
      description: "",
      notes: "",
      videos: [],
      videoTitle: "",
      videoUrl: "",
      videoPlayer: "Youtube",
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  onSelectItem(item, id) {
    this.setState({
      [id]: item[Object.keys(item)[0]].value,
      videoKey: Math.random(),
    });
  }

  onClickAddVideo() {
    let videos = this.state.videos;
    if (this.state.videoTitle.trim().length === 0) {
      showNotification("error", "Video Title is required", 4000);
      return;
    }
    if (this.state.videoUrl.trim().length === 0) {
      showNotification("error", "Video ID is required", 4000);
      return;
    }
    if (this.state.videoPlayer.trim().length === 0) {
      showNotification("error", "Video Player is required", 4000);
      return;
    }

    videos.push({
      title: this.state.videoTitle,
      url: this.state.videoUrl,
      player: this.state.videoPlayer,
    });

    this.setState({
      videos: videos,
      videoTitle: "",
      videoUrl: "",
      videoPlayer: "Youtube",
    });
  }

  onSortEndVideos = ({ oldIndex, newIndex }) => {
    const { videos } = this.state;

    this.setState({
      videos: arrayMove(videos, oldIndex, newIndex),
    });
  };

  onPressRemoveVideo(e) {
    let index = e.target.dataset["value"];
    let videos = this.state.videos;
    videos.splice(index, 1);
    this.setState({
      videos: videos,
    });
  }

  onPressAddExercise() {
    if (this.state.name.trim().length === 0) {
      showNotification("error", "Name is required", 4000);
      return;
    }

    if (this.state.description.trim().length === 0) {
      showNotification("error", "Description is required", 4000);
      return;
    }

    if (this.state.notes.trim().length === 0) {
      showNotification("error", "Note is required", 4000);
      return;
    }

    let apiData = {
      name: this.state.name,
      description: this.state.description,
      /*eslint-disable*/
      notes: this.state.notes,
      video_list: this.state.videos,
      /*eslint-enable*/
    };

    if (this.state.exerId === 0) {
      this.props.createExercise(apiData);
    } else {
      this.props.updateExercise(this.state.exerId, apiData);
    }
  }

  render() {
    return (
      <div>
        <div className="container-fluid">
          <TitleBox
            title={
              this.state.exerId === 0 ? "Create Exercise" : "Edit Exercise"
            }
            showBackBtn={this.state.exerId !== 0}
          />
          <div className="gap20" />

          <div className="card">
            <div className="container-fluid">
              <div className="gap20" />

              <HelpTextDiv
                id="idExerciseName"
                label="Learn more about the Name"
                type="addExercise"
                isInline="false"
              />
              <div
                className={
                  this.state.name !== "" ? "mdInput mdFocussed" : "mdInput"
                }>
                <label>Name</label>
                <input
                  type=""
                  className="challangeName"
                  name="name"
                  value={this.state.name}
                  onChange={(event) => this.handleInputChange(event)}
                />
              </div>
              <div className="gap20" />

              <HelpTextDiv
                id="idExerciseDescription"
                label="Learn more about the Description"
                type="addExercise"
                isInline="false"
              />
              <div
                className={
                  this.state.description !== ""
                    ? "mdInput mdFocussed"
                    : "mdInput"
                }>
                <label>Description</label>
                <input
                  type=""
                  className="challangeDesc"
                  name="description"
                  value={this.state.description}
                  onChange={(event) => this.handleInputChange(event)}
                />
              </div>
              <div className="gap20" />

              <HelpTextDiv
                id="idExerciseNotes"
                label="Learn more about the Exercise Notes"
                type="addExercise"
                isInline="false"
              />
              <div
                className={
                  this.state.notes !== "" ? "mdInput mdFocussed" : "mdInput"
                }>
                <label>Notes</label>
                <input
                  type=""
                  className="challangeDesc"
                  name="notes"
                  value={this.state.notes}
                  onChange={(event) => this.handleInputChange(event)}
                />
              </div>
              <div className="gap20" />
            </div>
          </div>
          <div className="card">
            <div className="container-fluid">
              <div className="gap20" />
              <div className="card">
                <div className="container-fluid">
                  <div className="gap20" />
                  <h5>Add Videos</h5>
                  <div className="gap20" />
                  <div className="row">
                    <div className="col-sm-4">
                      <HelpTextDiv
                        id="idExerciseVideoTitle"
                        label="Learn more about the Video Title"
                        type="addExercise"
                        isInline="false"
                      />

                      <div
                        className={
                          this.state.videoTitle !== ""
                            ? "mdInput mdFocussed"
                            : "mdInput"
                        }>
                        <label>Video Title</label>
                        <input
                          type="text"
                          className="videoTitle"
                          name="videoTitle"
                          value={this.state.videoTitle}
                          onChange={(event) => this.handleInputChange(event)}
                        />
                      </div>
                      <div className="gap20" />
                    </div>
                    <div className="col-sm-4">
                      <HelpTextDiv
                        id="idExerciseVideoID"
                        label="Learn more about the Video ID"
                        type="addExercise"
                        isInline="false"
                      />

                      <div
                        className={
                          this.state.videoUrl !== ""
                            ? "mdInput mdFocussed"
                            : "mdInput"
                        }>
                        <label>
                          {this.state.videoPlayer === "vooplayer"
                            ? "Video ID"
                            : "Video ID"}
                        </label>
                        <input
                          type="text"
                          className="videoUrl"
                          name="videoUrl"
                          value={this.state.videoUrl}
                          onChange={(event) => this.handleInputChange(event)}
                        />
                      </div>
                      <div className="gap20" />
                    </div>
                    <div className="col-sm-4">
                      <div className="havingHelpText">
                        <p>Video Player</p>
                      </div>
                      <HelpTextDiv
                        id="idExerciseVideoPlayer"
                        label="Learn more about the Video Player"
                        type="addExercise"
                        isInline="true"
                      />
                      <div className="tabsHolder radioTabs">
                        <RadioTag
                          onCheckChange={this.onSelectItem.bind(this)}
                          labelList={[
                            { name: "YouTube", value: "Youtube" },
                            { name: "Vimeo", value: "Vimeo" },
                            { name: "Voo Player", value: "vooplayer" },
                          ]}
                          id={"videoPlayer"}
                          selectedList={[this.state.videoPlayer]}
                          selectType={"value"}
                        />
                      </div>
                      <div className="gap20" />
                      <div className="gap20" />
                    </div>
                  </div>
                  <VideoPlayerNote
                    videoPlayer={this.state.videoPlayer}
                    key={this.state.videoKey}
                  />
                  <div
                    className="text-right"
                    onClick={() => this.onClickAddVideo()}>
                    {/*eslint-disable-next-line*/}
                    <a className="btn btn-primary addVideoButton">Add Video</a>
                  </div>
                  <div className="gap20" />
                  <SortableVideoList
                    items={this.state.videos}
                    onSortEnd={this.onSortEndVideos}
                    onPressRemove={this.onPressRemoveVideo.bind(this)}
                  />
                  <div className="gap20" />
                </div>
              </div>
              <div className="text-right">
                <button
                  className="btn btn-primary"
                  onClick={() => this.onPressAddExercise()}>
                  {this.state.exerId === 0
                    ? "Create Exercise"
                    : "Update Exercise"}
                </button>
              </div>
              <div className="gap20" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  message: state.customProgramReducer.message,
  success: state.customProgramReducer.success,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ createExercise, updateExercise, resetStatus }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddExercise);
