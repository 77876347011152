import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import FilterList from "../../../components/form/filterList";
import TitleBox from "../../../components/general/titleBox";
import { filterByTags } from "../../../helpers/filters";
import { callGetChallengGroupByQuery } from "../../../services";

class ViewChallengeGroup extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      chlgGroups: [],
      chlgGroupsBackup: [],
      filterList: []
    };
  }

  componentDidMount() {
    callGetChallengGroupByQuery({}).then(d => {
      this.setState({ chlgGroups: d.data, chlgGroupsBackup: d.data });
    });
  }

  onClickEditGroup = group => {
    this.props.history.push("/admin/add-challenge-group", {
      challengeGroup: group
    });
  };

  renderGroups() {
    var returnVals = [];
    let groups = this.state.chlgGroups;
    groups.forEach(element => {
      returnVals.push(
        <tr key={element._id}>
          <td>{element.name}</td>
          <td>{element.unique_id}</td>
          <td>
            {element.challenge_list_info
              ? element.challenge_list_info.map(i => i.name).join(", ")
              : "-"}
          </td>
          <td>{element.active ? "Yes" : "No"}</td>
          <td>
            <button
              onClick={this.onClickEditGroup.bind(this, element)}
              className="btn btn-default btn-sm border-0"
            >
              <i className="fa fa-pencil" />
            </button>
          </td>
        </tr>
      );
    });
    return returnVals;
  }

  filterUpdated(updatedFilter, name, type, searchFields) {
    let filterList = this.state.filterList;
    filterList[name] = {
      name: name,
      type: type,
      searchFields: searchFields,
      values: updatedFilter
    };
    this.setState(
      {
        filterList: filterList
      },
      () => {
        let data = filterByTags(this.state.chlgGroupsBackup, filterList);
        this.setState({
          chlgGroups: data
        });
      }
    );
  }

  renderFilterView() {
    return (
      <div className="list-group-item">
        <FilterList
          label={"Search Groups"}
          name={"search"}
          type={"search"}
          searchFields={["name"]}
          onFinish={this.filterUpdated.bind(this)}
        />
      </div>
    );
  }

  render() {
    return (
      <div className="container-fluid">
        <TitleBox title="Group Credit Overview" />
        <div className="gap20" />
        <div className="card">
          <div className="list-group list-group-flush">
            {this.renderFilterView()}
            <div className="list-group-item">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Id</th>
                    <th>Challenges</th>
                    <th>Active</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>{this.renderGroups()}</tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ViewChallengeGroup);
