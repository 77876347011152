import React from "react";
import {
  callCreateDiscussionBoard,
  callGetDiscussionBoards,
  callUpdateDiscussionBoard,
} from "../../services";
import $ from "jquery";

import Checkbox from "../form/checkBox";
import { showNotification } from "../../helpers";
import RadioTag from "../form/radioTag";
import VelocityTransitionGroup from "velocity-react/velocity-transition-group";

class SelectDiscussionBoard extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      discussionBoard: this.props.discussionBoard || "",
      boardTitle: "",
      showCreateBoard: false,
      keyDiscussionBoard: this.props.keyDiscussionBoard || Math.random(),
      discussionBoards: [],
      isDiscussionBoard: this.props.isDiscussionBoard || false,
      keyIsDiscussionBoard: false,
    };
  }

  componentDidMount() {
    this.getDiscussionBoards();
  }

  async getDiscussionBoards() {
    let query = {
      query: {
        $select: ["title"],
      },
    };
    var response = await callGetDiscussionBoards(query);
    if (response.data.length > 0) {
      this.setState({
        discussionBoards: response.data,
        keyDiscussionBoard: Math.random(),
      });
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  toggleDiscussionBoardCheckbox = (check) => {
    this.setState({ isDiscussionBoard: check });
    this.props.onChange(check, this.state.discussionBoard);
  };

  onSelectBoard(item, id) {
    let value = item[Object.keys(item)[0]].value;
    this.props.onChange(this.state.isDiscussionBoard, value);

    this.setState({
      [id]: value,
      boardTitle: "",
      showCreateBoard:
        item[Object.keys(item)[0]].value === "Create New Board" ? true : false,
    });
  }

  async onClickCreateDiscussionBoard() {
    let boardTitle = this.state.boardTitle.trim();

    if (boardTitle.trim().length === 0) {
      showNotification("error", "Board Title is required", 4000);
      return;
    }
    let eBoard = this.state.discussionBoards.filter((d) => {
      return d.title.toLowerCase() === boardTitle.toLowerCase();
    });
    if (eBoard.length > 0) {
      showNotification("error", "Board already exists");
      return;
    }

    let data = {
      title: this.state.boardTitle,
      comments: [],
      status: "active",
    };

    let response = await callCreateDiscussionBoard(data);
    if (response._id) {
      this.setState(
        {
          boardTitle: "",
          discussionBoard: response._id,
          showCreateBoard: false,
          keyDiscussionBoard: Math.random(),
        },
        () => {
          this.getDiscussionBoards();
          this.props.onChange(
            this.state.isDiscussionBoard,
            this.state.discussionBoard
          );
        }
      );
    }
  }

  onClickEditBoard() {
    let selectedBoard = this.state.discussionBoards.find(
      (d) => d._id === this.state.discussionBoard
    );
    this.setState(
      {
        boardTitle: selectedBoard.title,
      },
      () => {
        $(
          ".modalAlertOverlay.updateBoardModalOverlay,.modalAlert.updateBoardAlert"
        ).fadeIn(200);
      }
    );
  }

  onClickCancelFromUpdateBoardModal() {
    $(
      ".modalAlertOverlay.updateBoardModalOverlay,.modalAlert.updateBoardAlert"
    ).fadeOut(200);
  }

  async onClickUpdateFromUpdateBoardModel() {
    if (this.state.boardTitle.trim().length === 0) {
      showNotification("error", "Board Title is required", 4000);
      return;
    }
    let updatedBoard = await callUpdateDiscussionBoard(
      this.state.discussionBoard,
      {
        title: this.state.boardTitle,
      }
    );
    if (updatedBoard._id) {
      this.getDiscussionBoards();
      $(
        ".modalAlertOverlay.updateBoardModalOverlay,.modalAlert.updateBoardAlert"
      ).fadeOut(200);
    }
  }

  renderUpdateBoardModal() {
    let board = this.state.discussionBoard;

    if (!board) {
      return null;
    }

    return (
      <div>
        <div className="modalAlertOverlay updateBoardModalOverlay" />
        <div className="modalAlert updateBoardAlert">
          <div className="clearfix">
            <div className="pull-left">
              <p>Edit Board</p>
            </div>
          </div>

          <div className="container-fluid">
            <div>
              <div
                className={
                  this.state.boardTitle !== ""
                    ? "mdInput mdFocussed"
                    : "mdInput"
                }>
                <label>Board Title</label>
                <input
                  type="text"
                  name="boardTitle"
                  value={this.state.boardTitle}
                  onChange={(event) => this.handleInputChange(event)}
                />
              </div>
              <div className="gap20" />
            </div>
          </div>
          <div className="gap20" />
          <div className="alertFooterBtns">
            <button
              onClick={this.onClickCancelFromUpdateBoardModal.bind(this)}
              className="btn btn-default dismissThisModal margin-right-10">
              Cancel
            </button>
            <button
              onClick={this.onClickUpdateFromUpdateBoardModel.bind(this)}
              className="btn btn-primary dismissThisModal">
              Update
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderDiscussionBoardComponent() {
    let discussionBoards = this.state.discussionBoards;
    let labelList = [];
    discussionBoards.forEach((element) => {
      labelList.push({
        name: element.title,
        value: element._id,
      });
    });
    labelList.push({
      name: "Create New Board",
      value: "Create New Board",
    });
    return (
      <div className="col-sm-12">
        <div className="card">
          <div className="container-fluid">
            <div className="gap10" />
            <Checkbox
              key={this.state.keyIsDiscussionBoard}
              label={"Use Discussion Board"}
              isChecked={this.state.isDiscussionBoard}
              handleCheckboxChange={this.toggleDiscussionBoardCheckbox.bind(
                this
              )}
            />{" "}
            <div className="gap10" />
            {this.state.isDiscussionBoard && (
              <>
                {labelList.length > 0 && (
                  <div className="row" key={this.state.keyDiscussionBoard}>
                    <div className="col-md-12">
                      <div className="gap10" />
                      <label>Select Board</label>
                      <div className="tabsHolder radioTabs">
                        <RadioTag
                          onCheckChange={this.onSelectBoard.bind(this)}
                          labelList={labelList}
                          id={"discussionBoard"}
                          selectedList={[this.state.discussionBoard]}
                          selectType={"value"}
                        />
                      </div>
                      <div className="gap20" />
                    </div>
                  </div>
                )}

                <VelocityTransitionGroup
                  enter={{ animation: "slideDown" }}
                  leave={{ animation: "slideUp" }}>
                  {this.state.showCreateBoard && (
                    <>
                      <div className="row">
                        <div className="col-sm-4">
                          <div
                            className={
                              this.state.boardTitle !== ""
                                ? "mdInput mdFocussed"
                                : "mdInput"
                            }>
                            <label>Board Title</label>
                            <input
                              type="text"
                              name="boardTitle"
                              value={this.state.boardTitle}
                              onChange={(event) =>
                                this.handleInputChange(event)
                              }
                            />
                          </div>
                          <div className="gap20" />
                        </div>
                        <div className="col-sm-4">
                          <div className="gap30" />
                          <div className="gap10" />
                          <button
                            className="btn btn-sm btn-primary"
                            onClick={this.onClickCreateDiscussionBoard.bind(
                              this
                            )}>
                            Create Board
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                  {this.state.discussionBoard !== "" &&
                    !this.state.showCreateBoard && (
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={this.onClickEditBoard.bind(this)}>
                        Edit Selected Board
                      </button>
                    )}
                  <div className="gap10" />
                </VelocityTransitionGroup>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <>
        {this.renderUpdateBoardModal()} {this.renderDiscussionBoardComponent()}{" "}
      </>
    );
  }
}

export default SelectDiscussionBoard;
