import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment-timezone";
import $ from "jquery";

import TitleBox from "../../../components/general/titleBox";
import FilterList from "../../../components/form/filterList";
import { filterByTags } from "../../../helpers/filters";
import { callDeleteInvoice, callGetInvoicesByQuery } from "../../../services";
import { callGetSettings } from "../../../services/settingService";
import { sanitizer } from "../../../helpers";

class ViewInvoices extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      invoices: [],
      invoicesBackup: [],
      filterList: [],
      selectedInvoice: {},
      customFields: [],
      customHeaders: [],
      hideTaskInvoices: false,
    };
  }

  componentDidMount() {
    this.getData();
  }

  async getData() {
    await this.getSettings();
  }

  async getSettings() {
    let d = await callGetSettings("invoicesettings");
    let settings = d.data;
    let invoiceSettings = settings.invoice_settings || {};
    let customFields = invoiceSettings.custom_fields || [];
    let hideTaskInvoices = invoiceSettings.hide_invoices;

    let headers = customFields.filter((d) => d.display);

    this.setState(
      { customFields, customHeaders: headers, hideTaskInvoices },
      () => {
        this.getInvoices();
      }
    );
  }

  getInvoices() {
    /*eslint-disable*/
    let query = {};

    if (this.state.hideTaskInvoices) {
      query = {
        query: {
          created_by: { $ne: "task" },
        },
      };
    }
    /*eslint-enable*/
    callGetInvoicesByQuery(query).then((d) => {
      this.setState({ invoices: d.data, invoicesBackup: d.data });
    });
  }

  filterUpdated(updatedFilter, name, type, searchFields) {
    let filterList = this.state.filterList;
    filterList[name] = {
      name: name,
      type: type,
      searchFields: searchFields,
      values: updatedFilter,
    };
    this.setState(
      {
        filterList: filterList,
      },
      () => {
        let data = filterByTags(this.state.invoicesBackup, filterList);
        this.setState({
          invoices: data,
        });
      }
    );
  }

  onClickEditInvoice(invoice) {
    this.props.history.push(`/admin/add-invoice?id=${invoice._id}`);
  }

  onClickDeleteInvoice(invoice) {
    this.setState({ selectedInvoice: invoice }, () => {
      $(".modalAlertOverlay,.modalAlert").fadeIn(200);
    });
  }

  onClickNoAlert = () => {
    $(".modalAlertOverlay,.modalAlert").fadeOut(200);
  };

  onClickYesAlert = () => {
    callDeleteInvoice(this.state.selectedInvoice._id).then((d) => {
      $(".modalAlertOverlay,.modalAlert").fadeOut(200);
      this.getInvoices();
    });
  };

  renderConfirmationAlert() {
    let selectedInvoice = this.state.selectedInvoice;
    let name = "";
    if (selectedInvoice.description) {
      name = selectedInvoice.description;
    }
    return (
      <div>
        <div className="modalAlertOverlay" />
        <div className="modalAlert">
          <p>{`Are you sure you want to delete "${name}" invoice?`}</p>
          <div className="alertFooterBtns">
            <button
              className="btn btn-default closeModal-No margin-right-10"
              onClick={this.onClickNoAlert}>
              No
            </button>
            <button
              className="btn btn-primary closeModal-Yes"
              onClick={this.onClickYesAlert}>
              Yes
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderInvoices() {
    var returnVals = [];
    let items = this.state.invoices;
    let customHeaders = this.state.customHeaders;

    items.forEach((element) => {
      let customValues = [];
      let customFields = element.custom_fields || [];
      customHeaders.forEach((elHeader) => {
        let fValue = customFields.find((d) => d.id === elHeader.id);
        if (fValue && fValue.selectedOption) {
          let type = fValue.type || "single";
          if (type === "text") {
            customValues.push(fValue.selectedOption.value);
          } else if (type === "single") {
            customValues.push(fValue.selectedOption.label);
          } else if (type === "multiple") {
            if (fValue.selectedOptions && fValue.selectedOptions.length > 0) {
              customValues.push(
                fValue.selectedOptions.map((i) => i.label).join(", ")
              );
            } else {
              customValues.push("-");
            }
          }
        } else {
          customValues.push("-");
        }
      });

      returnVals.push(
        <tr key={element._id}>
          <td>{element.unique_id}</td>
          <td>
            <div
              dangerouslySetInnerHTML={{
                __html: sanitizer(element.description),
              }}
            />
          </td>
          <td>{element.amount}</td>
          <td className="firstLetter">
            {element.status} ({element.paid ? "Paid" : "Unpaid"})
          </td>
          <td>{moment(element.due_date).format("MMMM Do, YYYY")}</td>
          <td className="firstLetter">{element.type || "outgoing"}</td>
          <td className="firstLetter">{element.created_by || "normal"}</td>
          {customValues.map((i) => (
            <td>{i}</td>
          ))}
          <td className="min-width-100">
            <button
              onClick={this.onClickDeleteInvoice.bind(this, element)}
              className="btn btn-danger btn-sm margin-right-10 border-0">
              <i className="fa fa-trash" />
            </button>
            <button
              onClick={this.onClickEditInvoice.bind(this, element)}
              className="btn btn-default btn-sm border-0">
              <i className="fa fa-pencil" />
            </button>
          </td>
        </tr>
      );
    });
    return returnVals;
  }

  renderFilterView() {
    return (
      <div className="list-group-item">
        <FilterList
          label={"Search Invoices"}
          name={"search"}
          type={"search"}
          searchFields={["amount", "description"]}
          onFinish={this.filterUpdated.bind(this)}
        />
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderConfirmationAlert()}
        <div className="container-fluid">
          <TitleBox title={"View Invoices"} />
          <div className="gap20" />
          <div className="card">
            <div className="list-group list-group-flush">
              {this.renderFilterView()}
              <div className="list-group-item">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Description</th>
                      <th>Amount</th>
                      <th>Status</th>
                      <th>Due Date</th>
                      <th>Type</th>
                      <th>Created By</th>
                      {this.state.customHeaders.map((i) => (
                        <th className="firstLetter">
                          {i.name ? i.name : i.label}
                        </th>
                      ))}
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>{this.renderInvoices()}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ViewInvoices);
