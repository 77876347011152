import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import TitleBox from "../../../components/general/titleBox";
import TagSelect from "../../../components/form/tagSelect";
import RadioTag from "../../../components/form/radioTag";
import PageContent from "../../../components/editor/pageContent";
import TypeableSelect from "../../../components/form/typeableSelect";
import HelpTextDiv from "../../../components/form/helpTextDiv";
import StatusSwitch from "../../../components/form/statusSwitch";

import {
  callGetSystemTags,
  callCreatePopup,
  callUpdatePopup,
  callGetFormsUsingQuery,
  callGetChlgsByQuery,
  callGetPDFFormsUsingQuery,
} from "../../../services";
import { memberScreens } from "../../../helpers/routes";
import {
  showNotification,
  toPromise,
  isFS,
  openPagePopupModal,
  getEditorType,
} from "../../../helpers";
import PagePopup from "../../../components/popup/pagePopup";
import Onboarding from "../onboarding/onboarding";
import { getItem } from "../../../helpers/storage";

class AddPopup extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    let mScreens = Object.keys(memberScreens);
    let screenList = [];

    let isFs = isFS();

    mScreens.forEach((element) => {
      if (isFs) {
        screenList.push({
          title: element,
        });
      } else if (
        !element.includes("marcis-circle") &&
        !element.includes("btp") &&
        !element.includes("consent") &&
        !element.includes("add-challenge")
      ) {
        screenList.push({
          title: element,
        });
      }
    });

    let popup = {
      popupId: 0,
      popupInfo: {},
      title: "",
      description: "",
      isActive: true,
      statusLabel: "Currently Active",
      selectedIncludedTags: [],
      selectedExcludedTags: [],
      selectedRules: [],
      tagRules: { close: [], display: [], not_again: [], on_completed: [] }, //eslint-disable-line
      pageList: [],
      selectedPages: [],
      content: {},
      html: "",
      type: "modal",
      selectedForm: { value: "", label: "" },
      selectedChallenge: { value: "", label: "" },
      subPage: "",
      draftId: "",
      draftImage: "",
      editorType: isFS()
        ? "mp"
        : getItem("de") === "classic"
        ? "unlayer"
        : "mp",
    };

    if (this.props.history.location.state !== undefined) {
      let pData = this.props.history.location.state.popup;

      popup.popupInfo = pData;
      popup.popupId = pData._id;
      popup.title = pData.title;
      popup.description = pData.description;
      popup.isActive = pData.status;
      popup.statusLabel = pData.status
        ? "Currently Active"
        : "Currently Inactive";

      let pageList = pData.page_list;
      let selectedPages = [];

      pageList.forEach((element) => {
        selectedPages.push(element);
      });

      popup.selectedPages = selectedPages;
      popup.pageList = selectedPages;

      popup.content = pData.content || {};
      popup.html = pData.html || "";
      popup.type = pData.type || "modal";

      popup.editorType = getEditorType(popup.html, popup.content);

      popup.draftId = pData.draft_id || "";
      popup.draftImage = pData.draft_image || "";

      let form = pData.form_id;
      let formPdf = pData.pdf_form_id;
      let formType = pData.form_type || "normal";

      if (form && pData.form_info && formType === "normal") {
        popup.selectedForm = {
          value: pData.form_info,
          label: pData.form_info.name,
          type: "normal",
        };
      }
      if (formPdf && pData.pdf_form_info && formType === "pdf") {
        popup.selectedForm = {
          value: pData.pdf_form_info,
          label: pData.pdf_form_info.name,
          type: "pdf",
        };
      }

      popup.subPage = pData.sub_page || "";
    }

    this.state = {
      popupInfo: popup.popupInfo,
      popupId: popup.popupId,
      title: popup.title,
      description: popup.description,
      isActive: popup.isActive,
      statusLabel: popup.statusLabel,
      systemTags: [],
      addTags: [],
      excludeTags: [],
      selectedIncludedTags: popup.selectedIncludedTags,
      selectedExcludedTags: popup.selectedExcludedTags,
      keyAddTag: Math.random(),
      keyExcludeTag: Math.random(),
      selectedRules: popup.selectedRules,
      keyRuleTag: Math.random(),
      tagRules: popup.tagRules,
      screenList: screenList,
      keyPageTag: Math.random(),
      pageList: popup.pageList,
      selectedPages: popup.selectedPages,
      keyTagOn: Math.random(),
      content: popup.content,
      html: popup.html,
      type: popup.type,
      keyType: Math.random(),
      keyForm: Math.random(),
      forms: [],
      selectedForm: popup.selectedForm,
      popupType: "",
      popupLabel: "",
      popupChallengeItem: "",
      popupKey: Math.random(),
      keyChallenge: Math.random(),
      selectedChallenge: { value: "", label: "" },
      challenges: [],
      subPage: popup.subPage,
      keySwitch: Math.random(),
      editorType: popup.editorType,
      draftId: popup.draftId,
      keyEditor: Math.random(),
      draftImage: popup.draftImage,
    };
  }

  componentDidMount() {
    this.getSystemTags();
    this.getForms();
    this.getChallenges();
  }

  componentWillMount() {
    if (!this.state.isActive && this.state.popupId !== 0) {
      showNotification("warning", "This popup is currently inactive.", false);
    }
  }

  async getChallenges() {
    let query = {
      query: {
        $select: ["name", "_id"],
      },
    };
    let challenges = await callGetChlgsByQuery(query);
    let fChlgs = [];
    let selectedChallenge = { value: "", label: "" };

    challenges.data.forEach((element) => {
      if (this.state.subPage && this.state.subPage === element._id) {
        selectedChallenge = { value: element._id, label: element.name };
      }
      fChlgs.push({ value: element._id, label: element.name });
    });

    this.setState({
      challenges: fChlgs,
      keyChallenge: Math.random(),
      selectedChallenge,
    });
  }

  async getSystemTags() {
    let d = await callGetSystemTags();
    this.setState(
      {
        systemTags: d.data,
        keyAddTag: Math.random(),
        keyExcludeTag: Math.random(),
        keyRuleTag: Math.random(),
      },
      () => {
        if (this.state.popupId !== 0) {
          this.checkForTags();
        }
      }
    );
  }

  async getForms() {
    let query = {
      query: {
        $select: ["name", "_id"],
      },
    };
    let d = await callGetFormsUsingQuery(query);
    let forms = d.data;

    let fForms = [];

    forms.forEach((element) => {
      fForms.push({ value: element, label: element.name, type: "normal" });
    });

    let t = await callGetPDFFormsUsingQuery(query);
    let pdfForms = t.data;

    pdfForms.forEach((element) => {
      fForms.push({ value: element, label: element.name, type: "pdf" });
    });

    this.setState({
      forms: fForms,
      keyForm: Math.random(),
    });
  }

  getTagsInfo(tags) {
    let systemTags = this.state.systemTags;
    let fTags = [];
    tags.forEach((element) => {
      let tagInfo = systemTags.filter((d) => {
        return d._id === element;
      });
      if (tagInfo.length > 0) {
        fTags.push(tagInfo[0]);
      }
    });
    return fTags;
  }

  checkForTags() {
    let selectedIncludedTags = this.getTagsInfo(
      this.state.popupInfo.included_tags
    );
    let selectedExcludedTags = this.getTagsInfo(
      this.state.popupInfo.excluded_tags
    );

    let tagList = this.state.popupInfo.tag_list;
    let selectedRules = [];
    let tagRules = { close: [], display: [], not_again: [] }; //eslint-disable-line

    for (const key in tagList) {
      if (tagList.hasOwnProperty(key)) {
        const element = tagList[key];
        if (element.length > 0) {
          selectedRules.push(key);
          tagRules[key] = this.getTagsInfo(element);
        }
      }
    }

    this.setState({
      addTags: selectedIncludedTags,
      excludeTags: selectedExcludedTags,
      selectedIncludedTags,
      selectedExcludedTags,
      selectedRules,
      tagRules,
      keyRuleTag: Math.random(),
      keyAddTag: Math.random(),
      keyExcludeTag: Math.random(),
      keyTagOn: Math.random(),
    });
  }

  resetProps() {
    this.setState({
      popupId: 0,
      title: "",
      description: "",
      isActive: true,
      statusLabel: "Currently Active",
      addTags: [],
      excludeTags: [],
      selectedIncludedTags: [],
      selectedExcludedTags: [],
      keyAddTag: Math.random(),
      keyExcludeTag: Math.random(),
      selectedRules: [],
      keyRuleTag: Math.random(),
      tagRules: { close: [], display: [], not_again: [], on_completed: [] }, //eslint-disable-line
      keyPageTag: Math.random(),
      pageList: [],
      selectedPages: [],
      type: "modal",
      keyTagOn: Math.random(),
      keyType: Math.random(),
      keyChallenge: Math.random(),
      selectedChallenge: { value: "", label: "" },
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  toggleCheckbox = (check) => {
    this.setState({
      isActive: check,
      statusLabel: check ? "Currently Active" : "Currently Inactive",
    });
  };

  onChangeAddTags = (tags) => {
    this.setState({ addTags: tags });
  };

  onChangeExcludeTags = (tags) => {
    this.setState({ excludeTags: tags });
  };

  onChangePageList = (pages) => {
    this.setState({ pageList: pages });
  };

  onSelectRules(item, id) {
    let selectedRules = [];

    Object.keys(item).forEach((obj) => {
      selectedRules.push(item[obj].value);
    });

    this.setState({
      selectedRules: selectedRules,
    });
  }

  onChangeTagRules(type, tags) {
    let tagRules = this.state.tagRules;
    tagRules[type] = tags;
    this.setState({ tagRules });
  }

  onChangeContent(html, content, draftId, draftImage) {
    this.setState({
      content: content,
      html: html,
      draftId: draftId,
      draftImage: draftImage,
      keyEditor: Math.random(),
    });
  }

  onSelectForm = (form) => {
    this.setState({ selectedForm: form });
  };

  onSelectType(item, id) {
    let value = item[Object.keys(item)[0]].value;
    this.setState({ [id]: value, keyTagOn: Math.random(), selectedRules: [] });
  }

  onSelectChallenge = (challenge) => {
    this.setState({ selectedChallenge: challenge });
  };

  onClickAddNewForm() {
    this.setState(
      {
        popupType: "newForm",
        popupLabel: "Add New Form",
        popupKey: Math.random(),
        popupChallengeItem: "",
      },
      () => {
        openPagePopupModal("newForm");
      }
    );
  }

  onCreateNewForm(type) {
    if (type === "newForm") {
      this.getForms();
    }
  }

  renderTagSelect() {
    let selectedRules = this.state.selectedRules;
    let tagRules = this.state.tagRules;
    let returnVals = [];

    for (let i = 0; i < selectedRules.length; i++) {
      const element = selectedRules[i];
      returnVals.push(
        <TagSelect
          id={`add-popup-add-tag-dynamic-${element}`}
          key={this.state.keyRuleTag}
          selectedValues={tagRules[element]}
          searchKey={"name"}
          selectKey={"name"}
          btnText={"Create New Tag"}
          lblText={`Select ${element} Tag`}
          data={this.state.systemTags}
          isClickable={true}
          onChange={this.onChangeTagRules.bind(this, element)}
          reload={() => {
            this.getSystemTags();
          }}
        />
      );
    }

    return returnVals;
  }

  async onPressSave() {
    if (this.state.title.trim().length === 0) {
      showNotification("error", "Title is required", 4000);
      return;
    }

    if (this.state.description.trim().length === 0) {
      showNotification("error", "Description is required", 4000);
      return;
    }

    if (this.state.pageList.length === 0) {
      showNotification("error", "Atlease one page is required", 4000);
      return;
    }

    // if (this.state.type === "page") {
    //   if (this.state.selectedForm.label.trim().length === 0) {
    //     showNotification("error", "Form is required", 4000);
    //     return;
    //   }
    // }

    let addTags = this.state.addTags
      .filter((d) => {
        return d._id !== null;
      })
      .map((i) => i._id);

    let excludeTags = this.state.excludeTags
      .filter((d) => {
        return d._id !== null;
      })
      .map((i) => i._id);

    let tagRules = this.state.tagRules;
    let fTagRules = {};

    for (const key in tagRules) {
      if (tagRules.hasOwnProperty(key)) {
        const element = tagRules[key];
        if (this.state.selectedRules.indexOf(key) > -1) {
          fTagRules[key] = element
            .filter((d) => {
              return d._id !== null;
            })
            .map((i) => i._id);
        } else {
          fTagRules[key] = [];
        }
      }
    }

    let formId = "";
    let pdfFormId = "";
    let selectedForm = this.state.selectedForm;
    let formType = "normal";

    if (selectedForm.value._id && this.state.type === "page") {
      if (selectedForm.type === "pdf") {
        pdfFormId = selectedForm.value._id;
        formType = "pdf";
      }
      if (selectedForm.type === "normal") {
        formId = selectedForm.value._id;
        formType = "normal";
      }
    }

    /*eslint-disable*/
    let apiData = {
      title: this.state.title,
      description: this.state.description,
      included_tags: addTags,
      excluded_tags: excludeTags,
      page_list: this.state.pageList,
      sub_page: this.state.selectedChallenge.value,
      tag_list: fTagRules,
      status: this.state.isActive,
      content: this.state.content,
      html: this.state.html,
      type: this.state.type,
      form_id: formId,
      pdf_form_id: pdfFormId,
      form_type: formType,
      draft_id: this.state.draftId,
      draft_image: this.state.draftImage,
    };

    if (this.state.popupId !== 0) {
      let [err, data] = await toPromise(
        callUpdatePopup(this.state.popupId, apiData)
      );

      /*eslint-enable*/
      if (err) {
        showNotification("error", err.message, 5000);
      } else {
        showNotification("success", "Pre-Page updated successfully", 4000);
      }
    } else {
      /*eslint-disable*/
      let [err, data] = await toPromise(callCreatePopup(apiData));
      /*eslint-enable*/
      if (err) {
        showNotification("error", err.message, 5000);
      } else {
        showNotification("success", "Pre-Page created successfully", 4000);
        this.resetProps();
      }
    }
  }

  getTagOnRules() {
    let tagOn = [
      {
        name: "Close",
        value: "close",
      },
      {
        name: "Display",
        value: "display",
      },
      {
        name: "Do Not Show Again",
        value: "not_again",
      },
    ];
    if (this.state.type === "page") {
      tagOn = [
        {
          name: "On Completed",
          value: "on_completed",
        },
      ];
    }
    return tagOn;
  }

  renderChallenges() {
    let pages = this.state.pageList;
    let challengePages = pages.filter((d) => {
      return d === "/member/challenge";
    });
    if (challengePages.length > 0 && pages.length === 1) {
      return (
        <TypeableSelect
          key={this.state.keyChallenge}
          name={"selectedChallenge"}
          selected={this.state.selectedChallenge}
          placeholder={"Select Challenge"}
          onSelect={this.onSelectChallenge}
          options={this.state.challenges}
        />
      );
    }
    return null;
  }

  render() {
    return (
      <Onboarding history={this.props.history}>
        <div>
          <PagePopup
            type={this.state.popupType}
            label={this.state.popupLabel}
            challengeItem={this.state.popupChallengeItem}
            key={this.state.popupKey}
            onCreate={this.onCreateNewForm.bind(this)}
          />
          <div className="container-fluid">
            <TitleBox
              title={
                this.state.popupId === 0
                  ? "Add New Pre-Page Content"
                  : "Edit Pre-Page Content"
              }
              showBackBtn={this.state.popupId !== 0}
            />
            <div className="gap20" />
            <div className="card">
              <div className="container-fluid">
                <div className="gap20" />

                <div className="havingHelpText">
                  <StatusSwitch
                    key={this.state.keySwitch}
                    isActive={this.state.isActive}
                    onChange={this.toggleCheckbox.bind(this)}
                  />
                </div>
                <HelpTextDiv
                  id="idPopupStatus"
                  label="Learn more about the Popup Status"
                  type="addPopup"
                  isInline="true"
                />

                <HelpTextDiv
                  id="idPopupTitle"
                  label="Learn more about the Popup Title"
                  type="addPopup"
                  isInline="false"
                />

                <div
                  className={
                    this.state.title !== "" ? "mdInput mdFocussed" : "mdInput"
                  }>
                  <label>Title</label>
                  <input
                    type="text"
                    name="title"
                    // onChange={this.handleInputChange.bind(this)}
                    // value={this.state.title}
                    defaultValue={this.state.title}
                    onBlur={this.handleInputChange.bind(this)}
                  />
                </div>
                <div className="gap10" />
                <HelpTextDiv
                  id="idPopupDescription"
                  label="Learn more about the Popup Description"
                  type="addPopup"
                  isInline="false"
                />

                <div
                  className={
                    this.state.description !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Description</label>
                  <textarea
                    name="description"
                    onBlur={this.handleInputChange.bind(this)}
                    defaultValue={this.state.description}
                  />
                </div>
                <div className="gap20" />
                <HelpTextDiv
                  id="idPopupIncludeTag"
                  label="Learn more about the Include Tags"
                  type="addPopup"
                  isInline="false"
                />

                <TagSelect
                  id={"add-popup-include-tag"}
                  key={this.state.keyAddTag}
                  selectedValues={this.state.selectedIncludedTags}
                  searchKey={"name"}
                  selectKey={"name"}
                  btnText={"Include Tag"}
                  lblText={"Select Include Tag"}
                  data={this.state.systemTags}
                  onChange={this.onChangeAddTags}
                  isClickable={true}
                  reload={() => {
                    this.getSystemTags();
                  }}
                />
                <div className="gap10" />
                <p>
                  Note: If the user has any of the tags here then it will match
                </p>
                <HelpTextDiv
                  id="idPopupExcludeTag"
                  label="Learn more about the Exclude Tags"
                  type="addPopup"
                  isInline="false"
                />

                <TagSelect
                  id={"add-popup-exclude-tag"}
                  key={this.state.keyExcludeTag}
                  selectedValues={this.state.selectedExcludedTags}
                  searchKey={"name"}
                  selectKey={"name"}
                  btnText={"Exclude Tag"}
                  lblText={"Select Exclude Tag"}
                  data={this.state.systemTags}
                  onChange={this.onChangeExcludeTags}
                  isClickable={true}
                  reload={() => {
                    this.getSystemTags();
                  }}
                />
                <div className="gap10" />
                <HelpTextDiv
                  id="idPopupSelectPage"
                  label="Learn more about the Select Page"
                  type="addPopup"
                  isInline="false"
                />

                <TagSelect
                  id={"add-popup-add-page"}
                  key={this.state.keyPageTag}
                  selectedValues={this.state.selectedPages}
                  searchKey={"title"}
                  selectKey={"title"}
                  btnText={"Add Page"}
                  lblText={"Select Page"}
                  data={this.state.screenList}
                  onChange={this.onChangePageList}
                  singleSelection={true}
                />
                <div className="gap10" />
                <label
                  style={{
                    color: "gray",
                    fontSize: 13,
                  }}>
                  (Note: Start search with "/member")
                </label>
                <div className="gap10" />
                {this.renderChallenges()}
                <div className="gap20" />
                <div className="card">
                  <div className="container-fluid">
                    <div className="gap20" />
                    <div className="radioBHolder">
                      <div className="havingHelpText">
                        <p>Type</p>
                      </div>
                      <HelpTextDiv
                        id="idPopupType"
                        label="Learn more about the Popup Type"
                        type="addPopup"
                        isInline="true"
                      />
                      <div className="tabsHolder radioTabs">
                        <RadioTag
                          key={this.state.keyType}
                          onCheckChange={this.onSelectType.bind(this)}
                          labelList={[
                            { name: "Popup", value: "modal" },
                            { name: "Pre Page", value: "page" },
                          ]}
                          id={"type"}
                          selectedList={[this.state.type]}
                          selectType={"value"}
                        />
                      </div>
                    </div>
                    <div className="gap20" />
                    <div className="radioBHolder">
                      <div className="havingHelpText">
                        <p>Add Tag On</p>
                      </div>
                      <HelpTextDiv
                        id="idPopupTagOn"
                        label="Learn more about the Popup Add Tag On"
                        type="addPopup"
                        isInline="true"
                      />
                      <div className="tabsHolder radioTabs">
                        <RadioTag
                          key={this.state.keyTagOn}
                          onCheckChange={this.onSelectRules.bind(this)}
                          labelList={this.getTagOnRules()}
                          id={"rules"}
                          selectedList={this.state.selectedRules}
                          selectType={"value"}
                          type={"multiple"}
                        />
                      </div>
                    </div>
                    <div className="gap10" />
                    {this.renderTagSelect()}
                    <div className="gap20" />
                  </div>
                </div>

                <PageContent
                  key={this.state.keyEditor}
                  title={this.state.title}
                  screen={"add-popup"}
                  html={this.state.html}
                  content={this.state.content}
                  onChangeData={this.onChangeContent.bind(this)}
                  pageName={"addPopup"}
                  onClickAlert={this.onPressSave.bind(this)}
                  editorType={this.state.editorType}
                  previewType={"mp-preview"}
                  draftId={this.state.draftId}
                  draftImage={this.state.draftImage}
                />

                {this.state.type === "page" && (
                  <div className="card">
                    <div className="container-fluid">
                      <div className="gap20" />
                      <div className="clearfix">
                        <div className="pull-left">
                          <h5 className="noMargin firstLetter">Form</h5>
                        </div>
                        {/* <div className="pull-right" style={{ marginRight: 15 }}>
                          <button
                            className="btn btn-default"
                            onClick={this.onClickAddNewForm.bind(this)}>
                            Add New Form
                          </button>
                        </div> */}
                      </div>
                      <div className="gap10" />
                      <TypeableSelect
                        key={this.state.keyForm}
                        name={"form"}
                        placeholder={"Select Form"}
                        selected={this.state.selectedForm}
                        onSelect={this.onSelectForm}
                        options={this.state.forms}
                      />
                      <div className="gap20" />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="text-right container-fluid">
              <button
                className="btn btn-primary"
                onClick={() => this.onPressSave()}>
                {this.state.popupId === 0 ? "Create" : "Update"}
              </button>
            </div>
            <div className="gap20" />
          </div>
        </div>
      </Onboarding>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddPopup);
