import React, { useEffect, useRef } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  textState,
  editorTypeState,
  jsonState,
  draftJsonState,
  productsState,
} from "../recoil/atoms";
import ContentIcon from "./general/icon";
import { DraftEditor } from "../../draft-editor";
import {
  getTitleDefaultAttr,
  getSubTitleDefaultAttr,
  getButtonDefaultAttr,
  getIconAttributes,
  getTextDefaultAttr,
  getIconElemAttributes,
} from "../helpers/json";
import UploadEditorImage from "../../../components/editor/uploadEditorImage";
import EmbedVideo from "../../../components/general/embedVideo";
import { getParameterByName, imageUrl } from "../../../helpers";
import { getItem } from "../../../helpers/storage";

function ContentColumn(props) {
  const [textValues, setTextValues] = useRecoilState(textState);
  const [draftJsonValues, setDraftJsonValues] = useRecoilState(draftJsonState);
  const editorType = useRecoilValue(editorTypeState);
  const products = useRecoilValue(productsState);
  const [json] = useRecoilState(jsonState);
  // const [imageSliders, setImageSliders] = useState([]);
  const imageRef = useRef();

  let column = props.column;
  let width = (parseFloat(props.rowWidth) * parseFloat(column.widthPer)) / 100;

  const onClickColumn = (event) => {
    event.stopPropagation();
    props.onClickColumn(column.attributes.id);
  };

  const onClickItem = (element, event) => {
    event.stopPropagation();
    props.onClickItem(column.attributes.id, element);
  };

  const onClickAddItem = (event) => {
    event.stopPropagation();
    props.onClickAddItem(column.attributes.id);
  };

  const onChangePValue = (id, html, json) => {
    textValues[id] = html;
    setTextValues(textValues);

    draftJsonValues[id] = json;
    setDraftJsonValues(draftJsonValues);
  };

  const onClickAddHtml = (element, event) => {
    event.stopPropagation();
    props.onClickAddHtml(column.attributes.id, element);
  };

  const onClickAddImage = (id, element, event) => {
    event.stopPropagation();
    props.onClickAddImage(column.attributes.id, element, "");
  };

  const onClickAddVideo = (id, element, event) => {
    event.stopPropagation();
    props.onClickAddVideo(column.attributes.id, element, "");
  };

  const onChangeImageUrl = (element, url) => {
    props.onClickAddImage(column.attributes.id, element, url);
  };

  const renderTitle = (id, element, attributes) => {
    let itemAttr = {};
    if (!element.useDefaultStyle || element.useDefaultStyle === false) {
      itemAttr = attributes;
    } else {
      itemAttr = {
        ...attributes,
        ...getTitleDefaultAttr(json),
      };
    }

    let seoType = element.attributes.seoType || "div";

    let draftEditor = (
      <DraftEditor
        textAlign={attributes.textAlign}
        selected={element.selected}
        defaultText="Your Title"
        html={
          textValues[id]
            ? textValues[id]
            : element.attributes.attributes.content
        }
        json={
          draftJsonValues[id]
            ? draftJsonValues[id]
            : element.attributes.attributes.json
        }
        onChange={onChangePValue.bind(this, id)}
        key={element.attributes.id}
      />
    );

    return (
      <>
        {seoType === "div" && (
          <div style={itemAttr} key={id}>
            {draftEditor}
          </div>
        )}
        {seoType === "h1" && (
          <h1 style={itemAttr} key={id}>
            {draftEditor}
          </h1>
        )}
        {seoType === "h2" && (
          <h2 style={itemAttr} key={id}>
            {draftEditor}
          </h2>
        )}
        {seoType === "h3" && (
          <h3 style={itemAttr} key={id}>
            {draftEditor}
          </h3>
        )}
      </>
    );
  };

  const renderSubTitle = (id, element, attributes) => {
    let itemAttr = {};
    if (!element.useDefaultStyle || element.useDefaultStyle === false) {
      itemAttr = attributes;
    } else {
      itemAttr = {
        ...attributes,
        ...getSubTitleDefaultAttr(json),
      };
    }

    let seoType = element.attributes.seoType || "div";

    let draftEditor = (
      <DraftEditor
        textAlign={attributes.textAlign}
        selected={element.selected}
        defaultText="Your SubTitle"
        html={
          textValues[id]
            ? textValues[id]
            : element.attributes.attributes.content
        }
        json={
          draftJsonValues[id]
            ? draftJsonValues[id]
            : element.attributes.attributes.json
        }
        onChange={onChangePValue.bind(this, id)}
        key={element.attributes.id}
        attributes={itemAttr}
      />
    );

    return (
      <>
        {seoType === "div" && (
          <div style={itemAttr} key={id}>
            {draftEditor}
          </div>
        )}
        {seoType === "h1" && (
          <h1 style={itemAttr} key={id}>
            {draftEditor}
          </h1>
        )}
        {seoType === "h2" && (
          <h2 style={itemAttr} key={id}>
            {draftEditor}
          </h2>
        )}
        {seoType === "h3" && (
          <h3 style={itemAttr} key={id}>
            {draftEditor}
          </h3>
        )}
      </>
    );
  };

  const renderText = (id, element, attributes) => {
    let itemAttr = {};
    if (!element.useDefaultStyle || element.useDefaultStyle === false) {
      itemAttr = attributes;
    } else {
      itemAttr = {
        ...attributes,
        ...getTextDefaultAttr(json),
      };
    }
    let draftEditor = (
      <DraftEditor
        defaultText="Add your paragraph text here..."
        textAlign={attributes.textAlign}
        selected={element.selected}
        html={
          textValues[id]
            ? textValues[id]
            : element.attributes.attributes.content
        }
        json={
          draftJsonValues[id]
            ? draftJsonValues[id]
            : element.attributes.attributes.json
        }
        onChange={onChangePValue.bind(this, id)}
        key={element.attributes.id}
      />
    );

    return (
      <>
        <div style={itemAttr} key={id}>
          {draftEditor}
        </div>
      </>
    );
  };

  const renderSocialMediaLinks = (id, element, attributes) => {
    let socialIconSize = element.attributes["socialIconSize"] || "30px";
    let socialIconSpacing = element.attributes["socialIconSpacing"] || "5px";
    let socialFacebookLink = element.attributes["socialFacebookLink"] || "";
    let socialInstagramLink = element.attributes["socialInstagramLink"] || "";
    let socialLinkedinLink = element.attributes["socialLinkedinLink"] || "";
    let socialPintrestLink = element.attributes["socialPintrestLink"] || "";
    let socialTwitterLink = element.attributes["socialTwitterLink"] || "";
    let socialYoutubeLink = element.attributes["socialYoutubeLink"] || "";
    let itemAttr = attributes;
    return (
      <div style={itemAttr} key={id}>
        <div className="row" style={{ display: "flex", alignItems: "center" }}>
          {socialFacebookLink && (
            <a
              style={{ paddingRight: socialIconSpacing }}
              target="blank"
              href={socialFacebookLink}>
              <img
                style={{ width: socialIconSize }}
                alt="facebook"
                src="https://site.memberpages.app/images/social/fb.svg"
              />
            </a>
          )}
          {socialInstagramLink && (
            <a
              style={{ paddingRight: socialIconSpacing }}
              target="blank"
              href={socialInstagramLink}>
              <img
                style={{ width: socialIconSize }}
                alt="instagram"
                src="https://site.memberpages.app/images/social/ig.svg"
              />
            </a>
          )}
          {socialLinkedinLink && (
            <a
              style={{ paddingRight: socialIconSpacing }}
              target="blank"
              href={socialLinkedinLink}>
              <img
                style={{ width: socialIconSize }}
                alt="linkedin"
                src="https://site.memberpages.app/images/social/li.svg"
              />
            </a>
          )}
          {socialPintrestLink && (
            <a
              style={{ paddingRight: socialIconSpacing }}
              target="blank"
              href={socialPintrestLink}>
              <img
                style={{ width: socialIconSize }}
                alt="pintrest"
                src="https://site.memberpages.app/images/social/pintrest.svg"
              />
            </a>
          )}
          {socialTwitterLink && (
            <a
              style={{ paddingRight: socialIconSpacing }}
              target="blank"
              href={socialTwitterLink}>
              <img
                style={{ width: socialIconSize }}
                alt="x"
                src="https://site.memberpages.app/images/social/x.svg"
              />
            </a>
          )}
          {socialYoutubeLink && (
            <a
              style={{ paddingRight: socialIconSpacing }}
              target="blank"
              href={socialYoutubeLink}>
              <img
                style={{ width: socialIconSize }}
                alt="youtube"
                src="https://site.memberpages.app/images/social/yt.svg"
              />
            </a>
          )}
        </div>
      </div>
    );
  };

  const renderButton = (id, element, attributes, iconAttributes) => {
    let itemAttr = {};
    if (!element.useDefaultStyle || element.useDefaultStyle === false) {
      itemAttr = attributes;
    } else {
      itemAttr = {
        ...attributes,
        ...getButtonDefaultAttr(json),
      };
    }

    let subId = element.attributes.subId;

    let buttonSecondLine = element.attributes.buttonSecondLine;

    let buttonSecondLineFontSize = element.attributes.buttonSecondLineFontSize;
    let buttonSecondLineLineHeight =
      element.attributes.buttonSecondLineLineHeight;

    if (editorType === "email") {
      return (
        <table
          width={itemAttr.width}
          cellSpacing="0"
          cellPadding="0"
          className="mobile"
          style={{
            ...itemAttr,
            paddingRight: 0,
            paddingTop: 0,
            paddingLeft: 0,
            paddingBottom: 0,
            border: 0,
            borderRadius: 0,
            textAlign: itemAttr.textAlign,
            marginTop: attributes.marginTop,
            marginBottom: attributes.marginBottom,
          }}>
          <tbody>
            <tr>
              <td>
                <div
                  style={{
                    borderRadius: itemAttr.borderRadius,
                    border: itemAttr.border,
                  }}>
                  <table
                    cellSpacing="0"
                    cellPadding="0"
                    width="100%"
                    className="mobile">
                    <tbody>
                      <tr>
                        <td>
                          {/*eslint-disable*/}
                          <a>
                            <div
                              style={{
                                paddingTop: attributes.paddingTop,
                                paddingRight: attributes.paddingRight,
                                paddingLeft: attributes.paddingLeft,
                                paddingBottom: attributes.paddingBottom,
                              }}>
                              <table width="100%">
                                <tbody>
                                  <tr>
                                    <td width="100%">
                                      <DraftEditor
                                        selected={element.selected}
                                        defaultText="Click Here"
                                        html={
                                          textValues[id]
                                            ? textValues[id]
                                            : element.attributes.attributes
                                                .title
                                        }
                                        json={
                                          draftJsonValues[id]
                                            ? draftJsonValues[id]
                                            : element.attributes.attributes.json
                                        }
                                        onChange={onChangePValue.bind(this, id)}
                                        key={element.attributes.id}
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </a>
                          {/*eslint-enable*/}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      );
    }
    if (editorType === "web" || editorType === "popup") {
      let divAttributes = {
        margin: itemAttr.margin,
        textAlign: itemAttr.textAlign,
      };
      return (
        <div style={divAttributes}>
          {/*eslint-disable*/}
          <a
            style={{
              ...itemAttr,
              paddingRight: 0,
              paddingTop: 0,
              paddingLeft: 0,
              paddingBottom: 0,
            }}>
            <div
              style={{
                paddingTop: attributes.paddingTop,
                paddingRight: attributes.paddingRight,
                paddingLeft: attributes.paddingLeft,
                paddingBottom: attributes.paddingBottom,
              }}>
              <table style={{ margin: "0 auto" }}>
                <tbody>
                  <tr>
                    {iconAttributes.buttonIconEnable &&
                      iconAttributes.buttonIconAlignment === "left" && (
                        <td
                          style={{
                            paddingLeft: `${iconAttributes.buttonIconPaddingLeft}px`,
                            paddingRight: `${iconAttributes.buttonIconPaddingRight}px`,
                          }}>
                          <ContentIcon
                            name={iconAttributes.buttonIconName}
                            width={iconAttributes.buttonIconSize}
                            color={iconAttributes.buttonIconColor}
                          />
                        </td>
                      )}
                    <td>
                      <DraftEditor
                        selected={element.selected}
                        defaultText="Click Here"
                        html={
                          textValues[id] ? textValues[id] : attributes.title
                        }
                        json={
                          draftJsonValues[id]
                            ? draftJsonValues[id]
                            : attributes.json
                        }
                        onChange={onChangePValue.bind(this, id)}
                        key={element.attributes.id}
                      />
                    </td>
                    {iconAttributes.buttonIconEnable &&
                      iconAttributes.buttonIconAlignment === "right" && (
                        <td
                          style={{
                            paddingLeft: `${iconAttributes.buttonIconPaddingLeft}px`,
                            paddingRight: `${iconAttributes.buttonIconPaddingRight}px`,
                          }}>
                          <ContentIcon
                            name={iconAttributes.buttonIconName}
                            width={iconAttributes.buttonIconSize}
                            color={iconAttributes.buttonIconColor}
                          />
                        </td>
                      )}
                  </tr>
                  {buttonSecondLine && (
                    <tr>
                      <td
                        colSpan="3"
                        style={{
                          fontSize: parseFloat(buttonSecondLineFontSize),
                          lineHeight: parseFloat(buttonSecondLineLineHeight),
                        }}>
                        <DraftEditor
                          selected={element.selected}
                          defaultText="Click Here"
                          html={
                            textValues[subId]
                              ? textValues[subId]
                              : element.attributes.attributes.subTitle
                          }
                          json={
                            draftJsonValues[subId]
                              ? draftJsonValues[subId]
                              : element.attributes.attributes.subJson
                          }
                          onChange={onChangePValue.bind(this, subId)}
                          key={element.attributes.subId}
                        />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </a>
          {/*eslint-enable*/}
        </div>
      );
    }
  };

  const renderImage = (id, element, attributes) => {
    let imageAttr = {
      ...attributes,
      border: 0,
      borderRadius: 0,
      fontSize: 0,
    };

    delete imageAttr.width;
    delete imageAttr.maxWidth;

    let maxWidth = element.attributes.attributes.maxWidth || "100%";

    let imageAttributes = {
      border: attributes.border,
      borderRadius: attributes.borderRadius,
      maxWidth: maxWidth,
    };
    return (
      <div>
        {element.src ? (
          editorType === "email" ? (
            <table style={attributes}>
              <tr>
                <td style={{ padding: 0, lineHeight: 0 }}>
                  <img src={element.src} alt="I" />
                </td>
              </tr>
            </table>
          ) : (
            <div style={imageAttr}>
              <img
                src={element.src}
                alt="I"
                style={imageAttributes}
                responsiveImage
                id={id}
              />
            </div>
          )
        ) : (
          <div className="newImgHolder">
            <div
              className="newImgPlaceholder"
              onClick={onClickAddImage.bind(this, id, element)}>
              <span>+</span>Add Image
              <UploadEditorImage
                key={id}
                imageRef={imageRef}
                onChangeUrl={onChangeImageUrl.bind(this, element)}
              />
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderImageSlider = (id, element, attributes) => {
    let imageAttr = {
      ...attributes,
    };

    // let maxWidth = element.attributes.attributes.maxWidth || "300px";

    // let imageAttributes = {
    //   maxWidth: maxWidth,
    // };

    // let sliderImages = element.attributes.sliderImages || [];
    // let splidId = element.attributes.id;

    // if (imageSliders.indexOf(splidId) === -1) {
    //   var scriptTrack = document.createElement("script");
    //   scriptTrack.text = `
    //     document.addEventListener('DOMContentLoaded', function() {
    //       var splide${splidId} = new Splide('#${splidId}', {
    //         pagination: false,
    //         lazyLoad: 'nearby'
    //       }).mount();
    //     });
    //   `;
    //   document.body.appendChild(scriptTrack);
    //   imageSliders.push(splidId);
    //   setImageSliders(imageSliders);
    // }

    return (
      <div style={imageAttr}>
        {/* {sliderImages.length > 0 ? (
          // <section className="splide" id={splidId} aria-label="Presentation">
          //   <div className="splide__track">
          //     <ul className="splide__list">
          //       {sliderImages.map((i) => (
          //         <li className="splide__slide">
          //           <img alt={i.id} src={i.src} />
          //         </li>
          //       ))}
          //     </ul>
          //   </div>
          // </section>
          // <img alt={"Slider"} src={"https://d38x6s7j8yvy7v.cloudfront.net/jt3smfkqi3e-1679154934366-ru4ppji4z1.jpg"} style={imageAttributes} />
        // ) : (
          // <p>Slider Images</p>
        )} */}
        <img
          alt={"Slider"}
          class={"responsiveImage"}
          src={
            "https://d38x6s7j8yvy7v.cloudfront.net/jt3smfkqi3e-1679154934366-ru4ppji4z1.jpg"
          }
        />
      </div>
    );
  };

  const renderVideo = (id, element, attributes) => {
    let imageAttr = {
      ...attributes,
    };
    var player = "member-pages";
    let url = element.src;
    if (element.src && element.src.includes("vimeo")) {
      player = "vimeoEmbed";
      var parts = element.src.split("/");
      url = parts.pop() || parts.pop();
    }
    if (element.src && element.src.includes("youtube")) {
      player = "youtube";
      url = getParameterByName("v", element.src);
    }
    return (
      <div>
        {element.src ? (
          <div
            style={{
              border: "6px dashed",
              borderColor: "rgb(0 0 0 / 10%)",
              backgroundColor: "rgba(0,0,0,0)",
            }}>
            <div style={imageAttr}>
              <EmbedVideo
                //player={element.player.toLowerCase()}
                player={player}
                key={url}
                url={url}
                thumbUrl={element.srcThumb}
              />
            </div>
          </div>
        ) : (
          <div className="newImgHolder">
            <div
              className="newImgPlaceholder"
              onClick={onClickAddVideo.bind(this, id, element)}>
              <span>+</span>Add Video
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderAudio = (id, element, attributes) => {
    let imageAttr = {
      ...attributes,
    };

    let url = element.src;

    return (
      <div style={imageAttr}>
        <audio controls key={url}>
          <source src={`${url}`} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      </div>
    );
  };

  const renderInput = (id, element, attributes, isSameName) => {
    let inputAttributes = element.attributes.inputAttributes || {};
    let name = inputAttributes.name || "";
    let type = inputAttributes.type || "text";
    let placeholder = inputAttributes.placeholder || "";
    let color = inputAttributes.color || "#000000";
    let backgroundColor = inputAttributes.backgroundColor || "";
    let fontSize = inputAttributes.fontSize || "12";
    let required = inputAttributes.required || false;
    let width = inputAttributes.width || "100";
    width = width + "%";

    let value = inputAttributes.value || "";
    if (type === "hidden") {
      type = "name";
      placeholder = "Hidden Field";
    }

    let itemAttr = {
      ...attributes,
      border: isSameName ? "1px red dotted" : 0,
      borderRadius: 0,
    };

    let height = inputAttributes.inputHeight || "100";

    let multipleLines = inputAttributes.multipleLines;

    let inputAttr = {
      border: attributes.border,
      borderRadius: attributes.borderRadius,
      width: width,
      color: color,
      fontSize: fontSize + "px",
      backgroundColor: backgroundColor,
    };
    if (multipleLines) {
      inputAttr["height"] = `${height}px`;
    }

    if (multipleLines) {
      return (
        <div style={itemAttr}>
          <textarea
            id={id}
            type={type}
            readOnly
            className="mpTextInputStandard"
            name={name}
            placeholder={placeholder}
            required={required}
            style={inputAttr}
            value={value}
          />
        </div>
      );
    }

    return (
      <div style={itemAttr}>
        <input
          id={id}
          type={type}
          readOnly
          className="mpTextInputStandard"
          name={name}
          placeholder={placeholder}
          required={required}
          style={inputAttr}
          value={value}
        />
      </div>
    );
  };

  const renderFileInput = (id, element, attributes, isSameName) => {
    let inputAttributes = element.attributes.inputAttributes || {};
    let name = inputAttributes.name || "";
    let required = inputAttributes.required || false;
    let width = inputAttributes.width || "100";
    width = width + "%";

    let itemAttr = {
      ...attributes,
      border: isSameName ? "1px red dotted" : 0,
      borderRadius: 0,
    };

    let inputAttr = {
      border: attributes.border,
      borderRadius: attributes.borderRadius,
      width: width,
    };

    let disabled =
      inputAttributes.disabled !== undefined ? inputAttributes.disabled : false;

    return (
      <div style={itemAttr}>
        <input
          id={id}
          type={"file"}
          readOnly
          className="mpTextInputStandard"
          name={name}
          required={required}
          style={inputAttr}
          disabled={disabled}
        />
      </div>
    );
  };

  const renderCheckoutForm = (id, element, attributes) => {
    const checkoutId = element.attributes.checkoutId || "";
    let imageAttr = {
      ...attributes,
    };
    const key = getItem("sk");
    const url = `https://order.memberpages.app?p=${checkoutId}&key=${key}&v=iframe`;

    return (
      <div style={imageAttr}>
        {checkoutId ? (
          <iframe
            class="checkoutFrame"
            id={id}
            width="100%"
            src={url}
            allowtransparency="true"
            style={{ border: "none", margin: 0, padding: 0 }}
            title="Iframe"></iframe>
        ) : (
          <label>Select Checkout Item</label>
        )}
      </div>
    );
  };

  const renderCode = (id, element, attributes) => {
    return (
      <div style={attributes}>
        {attributes.content ? (
          <div
            id={id}
            dangerouslySetInnerHTML={{
              __html: attributes.content,
            }}
          />
        ) : (
          /*eslint-disable*/
          <div className="addNewRowHold">
            <a onClick={onClickAddHtml.bind(this, element)}>ADD HTML</a>
          </div>
          /*eslint-enable*/
        )}
      </div>
    );
  };

  const renderProduct = (id, element, attributes, type) => {
    let productId = element.attributes.productId || "";
    let content = type;
    let productInfo = {};
    if (productId) {
      productInfo = products.find((d) => d.product_id === productId);
      if (productInfo) {
        if (type === "productName") {
          content = productInfo.name;
        }
        if (type === "productDescription") {
          content = productInfo.description;
        }
        if (type === "productPrice") {
          content = productInfo.price;
        }
        if (type === "productImage") {
          content = productInfo.image;
        }
      }
    }

    let productView = <p>{content}</p>;
    if (type === "productImage") {
      if (productInfo) {
        productView = productInfo.image ? (
          <img
            src={imageUrl(content)}
            alt="I"
            style={{ width: "100%" }}
            responsiveImage
            id={id}
          />
        ) : (
          <p>productImage</p>
        );
      }
    }

    if (type === "productCart") {
      productView = (
        <table id={id} border="0" cellspacing="0" cellpadding="0">
          <tbody>
            <tr>
              <td>
                <div class="mpCartItem mpCartChange removeProductId">-</div>
              </td>
              <td>
                <div
                  class="mpCartItem mpCartCount"
                  style={{ paddingLeft: 10, paddingRight: 10 }}>
                  0
                </div>
              </td>
              <td>
                <div class="mpCartItem mpCartChange addProductId">+</div>
              </td>
            </tr>
          </tbody>
        </table>
      );
    }

    return <div style={attributes}>{productView}</div>;
  };

  const renderLine = (id, element, attributes) => {
    let lineAttributes = element.attributes.attributes;
    let height = lineAttributes.height || "1";
    let color = lineAttributes.backgroundColor || "#000000";
    let style = lineAttributes.lineStyle || "solid";

    let hrAttributes = {
      border: 0,
      borderTop: `${height}px ${style} ${color}`,
      padding: 5,
    };

    let itemAttr = {
      ...lineAttributes,
      height: 0,
      backgroundColor: "transparent",
    };

    return (
      <div style={itemAttr}>
        <hr style={hrAttributes} />
      </div>
    );
  };

  const renderCountdown = (id, element, attributes) => {
    let counterAttributes = element.attributes.attributes;
    let countdownAttributes = element.attributes.countdownAttributes || {
      textColor: "#ffffff",
      timeFontSize: "30",
      textFontSize: "16",
      outerBackgroundColor: "#333333",
      innerBackgroundColor: "#555555",
      fontFamily: "Ubuntu",
    };

    let showDays = countdownAttributes.showDays;
    let showHours = countdownAttributes.showHours;
    let showMinutes = countdownAttributes.showMinutes;
    let showSeconds = countdownAttributes.showSeconds;

    if (showDays === undefined) {
      showDays = true;
    }
    if (showHours === undefined) {
      showHours = true;
    }
    if (showMinutes === undefined) {
      showMinutes = true;
    }
    if (showSeconds === undefined) {
      showSeconds = true;
    }

    let counterAttr = {
      ...counterAttributes,
    };

    let mainDivAttr = {
      fontFamily: countdownAttributes.fontFamily,
      color: countdownAttributes.textColor,
      display: "inline-block",
      fontWeight: 100,
      textAlign: "center",
      fontSize: `${countdownAttributes.timeFontSize}px`,
    };
    let subDivAttr = {
      padding: "10px",
      marginRight: "0.2em",
      marginBottom: "0.2em",
      borderRadius: "3px",
      background: countdownAttributes.outerBackgroundColor,
      display: "inline-block",
    };
    let subDivSpanAttr = {
      padding: "15px",
      borderRadius: "3px",
      background: countdownAttributes.innerBackgroundColor,
      display: "inline-block",
    };

    let smallTextAttr = {
      paddingTop: "5px",
      fontSize: `${countdownAttributes.textFontSize}px`,
    };

    return (
      <div style={counterAttr}>
        <div id="clockdiv" style={mainDivAttr}>
          {showDays && (
            <div style={subDivAttr}>
              <span style={subDivSpanAttr} className="countdownDays">
                00
              </span>
              <div style={smallTextAttr}>Days</div>
            </div>
          )}
          {showHours && (
            <div style={subDivAttr}>
              <span style={subDivSpanAttr} className="countdownHours">
                00
              </span>
              <div style={smallTextAttr}>Hours</div>
            </div>
          )}
          {showMinutes && (
            <div style={subDivAttr}>
              <span style={subDivSpanAttr} className="countdownMinutes">
                00
              </span>
              <div style={smallTextAttr}>Minutes</div>
            </div>
          )}
          {showSeconds && (
            <div style={subDivAttr}>
              <span style={subDivSpanAttr} className="countdownSeconds">
                00
              </span>
              <div style={smallTextAttr}>Seconds</div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderCheckbox = (id, element, attributes) => {
    let itemAttr = {};
    if (!element.useDefaultStyle || element.useDefaultStyle === false) {
      itemAttr = attributes;
    } else {
      itemAttr = {
        ...attributes,
        ...getTextDefaultAttr(json),
      };
    }

    let checkboxPosition =
      element.attributes.inputAttributes.checkboxPosition || "center";

    return (
      <div style={itemAttr}>
        <div className="mp-row">
          <div className="mp-col">
            <label for={id}>
              <input
                key={checkboxPosition}
                type="checkbox"
                id={id}
                style={{
                  float: "left",
                  margin: 0,
                  height: itemAttr.fontSize,
                  width: itemAttr.fontSize,
                  verticalAlign: checkboxPosition,
                }}
              />
              <div>
                <DraftEditor
                  defaultText="Add your paragraph text here..."
                  textAlign={"left"}
                  selected={element.selected}
                  html={
                    textValues[id]
                      ? textValues[id]
                      : element.attributes.attributes.content
                  }
                  json={
                    draftJsonValues[id]
                      ? draftJsonValues[id]
                      : element.attributes.attributes.json
                  }
                  onChange={onChangePValue.bind(this, id)}
                  key={element.attributes.id}
                />
              </div>
            </label>
          </div>
        </div>
      </div>
    );
  };

  const renderIcon = (id, element, attributes, iconElemAttributes) => {
    let itemAttr = {};

    itemAttr = {
      ...attributes,
      display: "inline-block",
    };

    let divAttributes = {
      margin: itemAttr.margin,
      textAlign: itemAttr.textAlign,
    };

    let iconAttributes = iconElemAttributes;
    return (
      <div>
        <div style={divAttributes}>
          <div style={itemAttr}>
            <ContentIcon
              name={iconAttributes.name}
              width={iconAttributes.size}
              color={iconAttributes.color}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderIconText = (id, element, attributes, iconElemAttributes) => {
    let itemAttr = {};
    itemAttr = attributes;

    if (!element.useDefaultStyle || element.useDefaultStyle === false) {
      itemAttr = attributes;
    } else {
      itemAttr = {
        ...attributes,
        ...getTextDefaultAttr(json),
      };
    }

    let iconAttributes = iconElemAttributes;
    let position = itemAttr.position || "middle";

    return (
      <div style={itemAttr}>
        <table
          style={{
            ...itemAttr,
            display: "flex",
            justifyContent: itemAttr.textAlign,
          }}>
          <tbody>
            <tr>
              <td
                key={iconAttributes.position}
                style={{
                  paddingRight: `${iconAttributes.gap}px`,
                  verticalAlign: `${iconAttributes.position}`,
                }}>
                <ContentIcon
                  name={iconAttributes.name}
                  width={iconAttributes.size}
                  color={iconAttributes.color}
                />
              </td>
              <td style={{ verticalAlign: position }}>
                <DraftEditor
                  defaultText="Add your paragraph text here..."
                  textAlign={"left"}
                  selected={element.selected}
                  html={
                    textValues[id]
                      ? textValues[id]
                      : element.attributes.attributes.content
                  }
                  json={
                    draftJsonValues[id]
                      ? draftJsonValues[id]
                      : element.attributes.attributes.json
                  }
                  onChange={onChangePValue.bind(this, id)}
                  key={element.attributes.id}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  const renderImageText = (id, element, attributes, iconElemAttributes) => {
    let itemAttr = {};
    if (!element.useDefaultStyle || element.useDefaultStyle === false) {
      itemAttr = attributes;
    } else {
      itemAttr = {
        ...attributes,
        ...getTextDefaultAttr(json),
      };
    }

    let maxWidth = itemAttr.maxWidth || "100px";
    let position = itemAttr.position || "middle";
    let gap = itemAttr.gap || "10";

    delete itemAttr.maxWidth;
    delete itemAttr.position;
    delete itemAttr.gap;

    return (
      <div style={itemAttr}>
        <div className="mp-row">
          <div className="mp-col">
            <table style={itemAttr}>
              <tbody>
                <tr>
                  <td style={{ maxWidth: maxWidth, paddingRight: `${gap}px` }}>
                    {element.src && (
                      <img
                        src={element.src}
                        alt="I"
                        style={{ width: "100%" }}
                        responsiveImage
                        id={id}
                      />
                    )}
                  </td>
                  <td style={{ verticalAlign: position }}>
                    <DraftEditor
                      defaultText="Add your paragraph text here..."
                      textAlign={"left"}
                      selected={element.selected}
                      html={
                        textValues[id]
                          ? textValues[id]
                          : element.attributes.attributes.content
                      }
                      json={
                        draftJsonValues[id]
                          ? draftJsonValues[id]
                          : element.attributes.attributes.json
                      }
                      onChange={onChangePValue.bind(this, id)}
                      key={element.attributes.id}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };

  const renderSelectBox = (id, element, attributes) => {
    let inputAttributes = element.attributes.inputAttributes || {};
    let name = inputAttributes.name || "";
    let required = inputAttributes.required || false;
    let width = inputAttributes.width || "100";
    width = width + "%";

    let options = inputAttributes.options || "";
    let arrOptions = options.split("\n");

    let placeholder = inputAttributes.selectPlaceholder;
    if (placeholder === undefined) {
      placeholder = true;
    }
    let placeholderValue = inputAttributes.placeholder || "Select an Option";
    let wOptions = [];
    if (placeholder) {
      wOptions.push(<option value="">{placeholderValue}</option>);
    }
    arrOptions.forEach((element) => {
      if (element) {
        wOptions.push(<option value={element}>{element}</option>);
      }
    });
    return (
      <div style={attributes}>
        <select
          id={id}
          name={name}
          required={required}
          style={{ width: width }}
          className="mpTextInputStandard">
          {wOptions}
        </select>
      </div>
    );
  };

  const renderContent = () => {
    let items = column.itemList;
    let returnVals = [];

    let inputItems = items.filter((d) => d.type === "input");
    let nameInputMap = {};
    inputItems.forEach((element) => {
      var name = element.attributes.inputAttributes.name;
      if (!nameInputMap[name]) {
        nameInputMap[name] = [];
      }
      nameInputMap[name].push(element);
    });

    let isSameName = false;

    let values = Object.values(nameInputMap);
    for (let i = 0; i < values.length; i++) {
      const element = values[i];
      if (element.length > 1) {
        isSameName = true;
        break;
      }
    }

    for (let i = 0; i < items.length; i++) {
      const element = items[i];
      let id = element.attributes.id;
      let attributes = element.attributes.attributes;
      let iconAttributes = getIconAttributes(
        element.attributes.iconAttributes,
        editorType
      );
      let iconElemAttributes = getIconElemAttributes(
        element.attributes.iconElemAttributes,
        editorType
      );
      returnVals.push(
        <div
          id={id}
          className={`insertedElement ${
            element.selected ? "insertedElemActive" : ""
          } `}
          key={element.attributes.id}>
          <div
            className="textElemDiv"
            onClick={onClickItem.bind(this, element)}>
            {element.type === "title" && renderTitle(id, element, attributes)}
            {element.type === "subTitle" &&
              renderSubTitle(id, element, attributes)}
            {element.type === "text" && renderText(id, element, attributes)}
            {element.type === "button" &&
              renderButton(id, element, attributes, iconAttributes)}
            {element.type === "image" && renderImage(id, element, attributes)}
            {element.type === "imageSlider" &&
              renderImageSlider(id, element, attributes)}
            {element.type === "video" && renderVideo(id, element, attributes)}
            {element.type === "audio" && renderAudio(id, element, attributes)}
            {element.type === "input" &&
              renderInput(id, element, attributes, isSameName)}
            {element.type === "fileInput" &&
              renderFileInput(id, element, attributes)}
            {element.type === "code" && renderCode(id, element, attributes)}
            {element.type === "checkbox" &&
              renderCheckbox(id, element, attributes)}
            {element.type === "selectbox" &&
              renderSelectBox(id, element, attributes)}
            {element.type === "checkoutForm" &&
              renderCheckoutForm(id, element, attributes)}
            {element.type === "line" && renderLine(id, element, attributes)}
            {element.type === "countdown" &&
              renderCountdown(id, element, attributes)}
            {element.type === "icon" &&
              renderIcon(id, element, attributes, iconElemAttributes)}
            {element.type === "iconText" &&
              renderIconText(id, element, attributes, iconElemAttributes)}
            {element.type === "imageText" &&
              renderImageText(id, element, attributes, iconElemAttributes)}
            {element.type === "productDescription" &&
              renderProduct(id, element, attributes, element.type)}
            {element.type === "productName" &&
              renderProduct(id, element, attributes, element.type)}
            {element.type === "productPrice" &&
              renderProduct(id, element, attributes, element.type)}
            {element.type === "productImage" &&
              renderProduct(id, element, attributes, element.type)}
            {element.type === "productCart" &&
              renderProduct(id, element, attributes, element.type)}
            {element.type === "socialMediaLinks" &&
              renderSocialMediaLinks(id, element, attributes)}
          </div>
        </div>
      );
    }
    return returnVals;
  };

  useEffect(() => {
    //
  }, [textValues, props.rowWidth]);

  if (editorType === "email") {
    return (
      <td
        onClick={onClickColumn.bind(this)}
        id={column.attributes.id}
        width={width}
        className="mobile"
        style={column.attributes.attributes}>
        <div
          className={`col ${
            column.selected ? "colActiveSelected" : "colActive"
          } `}>
          {column.itemList.length === 0 && (
            <span className="col-label">Column</span>
          )}
          {column.itemList.length === 0 && (
            <div className="addNewItemHold">
              {/*eslint-disable*/}
              <a onClick={onClickAddItem.bind(this)}>ADD ITEM</a>
              {/*eslint-enable*/}
            </div>
          )}
          {column.itemList.length > 0 && renderContent()}
        </div>
      </td>
    );
  } else if (editorType === "web" || editorType === "popup") {
    var attributes = JSON.parse(JSON.stringify(column.attributes.attributes));

    let newAttributes = {
      ...attributes,
    };

    let backgroundImageAttr = {
      backgroundImage: newAttributes.backgroundImage,
      backgroundRepeat: newAttributes.backgroundRepeat,
      backgroundAttachment: newAttributes.backgroundAttachment,
      backgroundPosition: newAttributes.backgroundPosition,
      backgroundSize: newAttributes.backgroundSize,
      backgroundColor: newAttributes.backgroundColor,
    };

    let maxWidth = newAttributes.maxWidth;
    if (newAttributes.maxWidth) {
      delete newAttributes.maxWidth;
    }

    if (newAttributes.backgroundImage) {
      delete newAttributes.backgroundImage;
      delete newAttributes.backgroundRepeat;
      delete newAttributes.backgroundPosition;
      delete newAttributes.backgroundAttachment;
      delete newAttributes.backgroundSize;
      delete newAttributes.backgroundColor;
    }

    let styleCol = { width: `${parseFloat(column.widthPer)}%` };

    let isColSelected = column.selected;

    let backgroundFull = attributes.backgroundFull;
    let divAttr = { width: "100%" };
    if (backgroundFull === false) {
      newAttributes = { ...newAttributes, ...backgroundImageAttr };
    } else {
      divAttr = { ...divAttr, ...backgroundImageAttr, maxWidth };
    }

    return (
      <div
        onClick={onClickColumn.bind(this)}
        style={styleCol}
        className="mp-col">
        <div style={divAttr}>
          <div style={newAttributes} id={column.attributes.id}>
            <div
              className={`col ${
                isColSelected ? "colActiveSelected" : "colActive"
              } `}>
              {column.itemList.length === 0 && (
                <span className="col-label">Column</span>
              )}
              {column.itemList.length === 0 && (
                <div className="addNewItemHold">
                  {/*eslint-disable*/}
                  <a onClick={onClickAddItem.bind(this)}>ADD ITEM</a>
                  {/*eslint-enable*/}
                </div>
              )}
              {column.itemList.length > 0 && renderContent()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContentColumn;
