import React, { Component } from "react";

class RadioTag extends Component {
  constructor(props) {
    super(props);

    const { id, labelList, selectedList, type, selectType } = this.props;

    this.state = {
      id: id || "generic-radio",
      labelList: labelList,
      selectedList: selectedList || [],
      type: type || "single",
      selectType: selectType || "label",
    };
  }

  onClickRadio = (selectedLabel) => {
    const { id, labelList, selectedList, type, selectType } = this.state;

    let newSelectedValues = {};
    let newSelect = [];

    labelList.forEach((item, i) => {
      let selectedItem =
        selectType === "label"
          ? item.name.toLowerCase()
          : item.value.toLowerCase();

      if (type === "single") {
        if (selectedItem === selectedLabel) {
          newSelectedValues[i] = item;
          newSelect.push(selectedItem);
        }
      } else {
        if (selectedLabel === selectedItem) {
          if (!selectedList.includes(item.value)) {
            newSelectedValues[i] = item;
            newSelect.push(selectedItem);
          }
        } else {
          if (selectedList.includes(selectedItem)) {
            newSelectedValues[i] = item;
            newSelect.push(selectedItem);
          }
        }
      }
    });
    this.setState(
      {
        selectedList: newSelect,
      },
      () => {
        this.props.onCheckChange(newSelectedValues, id);
      }
    );
  };

  render() {
    const { labelList, selectedList, selectType } = this.state;
    return labelList.map((label) => {
      let selected = selectedList
        .join("~|}")
        .toLowerCase()
        .split("~|}")
        .includes(
          selectType === "label"
            ? label.name.toLowerCase()
            : label.value.toLowerCase()
        )
        ? true
        : false;

      return (
        <div
          key={label.value}
          onClick={() =>
            this.onClickRadio(
              selectType === "label"
                ? label.name.toLowerCase()
                : label.value.toLowerCase()
            )
          }
          data-call="onlyPhotos"
          className={selected ? "active" : ""}>
          <span className={selected ? "icon-check active" : "icon-check"} />{" "}
          {label.name}
        </div>
      );
    });
  }
}

export default RadioTag;
