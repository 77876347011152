import React from "react";
import AvatarEditor from "react-avatar-editor";
import { Scrollbars } from "react-custom-scrollbars";
import $ from "jquery";
import Checkbox from "../form/checkBox";

class ImageUpload extends React.Component {
  constructor(props) {
    super(props);
    const {
      border,
      width,
      height,
      clearOnSuccess,
      showSaveButton,
      scaleOnEdit,
      type,
      borderRadius,
      preserveRatio,
      maxHeight,
    } = this.props;

    let views = [];
    switch (type || "avatar") {
      case "full":
        views = [
          "rotate",
          "dimensions",
          "zoom",
          "radius",
          "scale",
          "quality",
          "transparency",
        ];
        break;

      case "avatar":
        views = ["rotate", "zoom"];
        break;

      case "progress":
        views = ["rotate"];
        break;

      case "normal":
        views = [];
        break;

      case "thumbnail":
      default:
        views = ["rotate", "zoom"];
        break;
    }

    this.state = {
      file: this.props.file,
      image: {},
      uploadFile: this.props.imageType === "url" ? true : false,
      rotate: 0,
      borderRadius: borderRadius || 0,
      zoom: 1,
      border: border || 1,
      width: width || 250,
      height: height || 250,
      clearOnSuccess: clearOnSuccess || false,
      showSaveButton: showSaveButton || false,
      uploadType: type || "avatar",
      scaleOnEdit: scaleOnEdit === true ? true : false,
      preserveRatio: preserveRatio || false,
      maxHeight: maxHeight || 10000,
      views: views,
      scaleHeight: "",
      saveQuality: ".72",
      transparency: false,
      saveImageLabel: this.props.saveImgLabel || "Save Image",
      imageLoaded: false,
      mimeType: this.props.mimeType || "image/jpeg",
      hideInput: this.props.hideInput || false,
    };
  }

  onImageReady = (e) => {
    if (this.state.imageLoaded) {
      return;
    }
    let imageType = this.props.imageType;
    if (this.editor && imageType === "url") {
      const canvas = this.state.file;
      fetch(canvas)
        .then((res) => res.blob())
        .then((blob) => {
          let reader = new FileReader();
          reader.onloadend = () => {
            var image = new Image();
            image.src = reader.result;
            image.onload = () => {
              this.setState(
                {
                  uploadFile: true,
                  imageLoaded: true,
                  image: image,
                },
                () => {
                  this.setupHeight(image);
                }
              );
            };
          };
          reader.readAsDataURL(blob);
        });
    }
  };

  onImageChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      var image = new Image();
      image.src = reader.result;
      image.onload = () => {
        this.setState(
          {
            file: !this.state.clearOnSuccess ? file : "",
            uploadFile: !this.state.clearOnSuccess ? true : false,
            image: image,
          },
          () => {
            this.props.onChangeImage(image, file, {
              height: parseInt(image.height, 10),
              width: parseInt(image.width, 10),
              mimeType: file.type,
            });
            this.setupHeight(image);
          }
        );
      };
    };
    reader.readAsDataURL(file);
  }

  setupHeight(image) {
    if (this.state.preserveRatio) {
      if (image.height > this.state.maxHeight) {
        this.setState(
          {
            scaleHeight: this.state.maxHeight,
          },
          () => {
            this.onScaleHeightAndWidthChange();
          }
        );
      } else {
        this.setState({
          height: parseInt(image.height, 10),
          width: parseInt(image.width, 10),
        });
      }
    }
  }

  onWidthChange(e) {
    let width = parseInt(e.target.value, 10);
    this.setState({
      width: width,
    });
  }

  onHeightChange(e) {
    let height = parseInt(e.target.value, 10);
    this.setState({
      height: height,
    });
  }

  onBorderRadiusChange = (e) => {
    let borderRadius = parseInt(e.target.value, 10);
    this.setState({
      borderRadius: borderRadius,
    });
  };

  onZoomChange = (e) => {
    let zoom = parseFloat(e.target.value);
    this.setState({
      zoom: zoom,
    });
  };

  rotateImageRight = () => {
    let rotate = this.state.rotate;
    rotate = rotate + 90;

    this.setState({
      rotate: rotate,
    });
    if (rotate === 1800) {
      this.setState({
        rotate: 0,
      });
    }
  };

  rotateImageLeft = () => {
    let rotate = this.state.rotate;
    rotate = rotate + 270;

    this.setState({
      rotate: rotate,
    });
    if (rotate === 2520) {
      this.setState({
        rotate: 0,
      });
    }
  };

  onScaleHeightAndWidthChange() {
    let imgWidth = this.state.image.width;
    let imgHeight = this.state.image.height;

    let newWidth = (imgWidth / imgHeight) * this.state.scaleHeight;
    let newHeight = (imgHeight / imgWidth) * newWidth;

    this.setState({
      height: parseInt(newHeight, 10),
      width: parseInt(newWidth, 10),
    });
  }

  changeScaleHeight(e) {
    this.setState(
      {
        scaleHeight: e.target.value,
      },
      () => this.onScaleHeightAndWidthChange()
    );
  }

  changeImageQuality(e) {
    this.setState({
      saveQuality: e.target.value,
    });
  }

  renderRotateView() {
    var returnVals = [];
    returnVals.push(
      <div className="col-md-12" key={"Rotate Left"}>
        <div className="">
          <div className="alwaysFocused">
            <label name="Rotate">Rotate</label>
          </div>
          <div>
            <button
              className="btn btn-sm btn-default"
              onClick={this.rotateImageLeft.bind(this)}>
              <i className="fa fa-rotate-left" /> Left
            </button>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <button
              className="btn btn-sm btn-default"
              onClick={this.rotateImageRight.bind(this)}>
              <i className="fa fa-rotate-right" /> Right
            </button>
          </div>
          <div className="gap20" />
        </div>
      </div>
    );
    return returnVals;
  }

  renderHeightWidthView() {
    var returnVals = [];
    returnVals.push(
      <div className="col-md-4" key={"Width Field"}>
        <div className="mdInput mdFocussed">
          <label>Width</label>
          <input
            type="text"
            name="width"
            value={this.state.width}
            onChange={(event) => this.onWidthChange(event)}
          />
        </div>
        <div className="gap20" />
      </div>
    );
    returnVals.push(
      <div className="col-md-4" key={"Height Field"}>
        <div className="mdInput mdFocussed">
          <label>Height</label>
          <input
            type="text"
            name="height"
            value={this.state.height}
            onChange={(event) => this.onHeightChange(event)}
          />
        </div>
        <div className="gap20" />
      </div>
    );

    returnVals.push(<div className="col-md-4" key={"Height Field 2"} />);
    return returnVals;
  }

  renderZoomView() {
    return (
      <div key={"zoom"} className="col-md-4">
        <div className="gap20" />
        <div className="alwaysFocused">
          <label name="Zoom">
            Zoom <br />
            <input
              id="slider1"
              type="range"
              value={this.state.zoom}
              min="1"
              max="6"
              onChange={this.onZoomChange.bind(this)}
              step=".2"
            />
          </label>{" "}
        </div>
      </div>
    );
  }

  renderRadiusView() {
    return (
      <div className="col-md-12">
        <div className="gap20" />
        <div className="alwaysFocused">
          <label name="radius">
            Radius <br />
            <input
              id="slider1"
              type="range"
              value={this.state.borderRadius}
              min="0"
              max="500"
              onChange={this.onBorderRadiusChange.bind(this)}
              step="1"
            />
          </label>
        </div>
      </div>
    );
  }

  componentWillReceiveProps(newProps) {
    const { file, clearOnSuccess, showSaveButton } = newProps;

    this.setState({
      file: file || this.state.file,
      clearOnSuccess: clearOnSuccess || this.state.clearOnSuccess,
      showSaveButton: showSaveButton || this.state.showSaveButton,
      uploadFile: showSaveButton || this.state.uploadFile,
    });
  }

  renderScaleView() {
    var returnVals = [];
    returnVals.push(
      <div key="scale" className="col-md-4">
        <div className="gap20" />
        <div className="alwaysFocused">
          <label name="Scale">
            Scale
            <br />
            <input
              id="imageScale"
              type="range"
              min="50"
              max="1000"
              step="5"
              onChange={this.changeScaleHeight.bind(this)}
            />
          </label>
        </div>
      </div>
    );
    returnVals.push(<div className="col-md-4" key={"Scale Field 2"} />);
    return returnVals;
  }

  renderQualityView() {
    return (
      <div key="scale" className="col-md-4">
        <div className="gap20" />
        <div className="alwaysFocused">
          <label name="Quality">
            Image Quality ({this.state.saveQuality})
            <br />
            <input
              id="quality"
              type="range"
              defaultValue={this.state.saveQuality}
              min="0"
              max="1"
              step=".02"
              onChange={this.changeImageQuality.bind(this)}
            />
          </label>
        </div>
      </div>
    );
  }

  toggleTransparency = (check) => {
    this.setState({
      transparency: check,
    });
  };

  toggleCheckbox = (image, check) => {
    let checkboxImages = this.state.checkboxImages;
    checkboxImages[image._id].value = check;
    this.setState({ checkboxImages });
  };

  renderTransparencyView() {
    var returnVals = [];
    returnVals.push(
      <div key="transparency" className="col-md-4">
        <div className="gap40" />
        <div className="alwaysFocused">
          <Checkbox
            label={"Preserve Transparency"}
            isChecked={false}
            handleCheckboxChange={this.toggleTransparency}
            key="preserveTransparency"
          />
        </div>
      </div>
    );

    if (this.state.transparency) {
      returnVals.push(
        <div className="col-md-4" key={"Transparency Field 2"} />
      );
    }
    return returnVals;
  }

  saveEdit() {
    let q = parseFloat(this.state.saveQuality);
    let canvas = this.editor
      .getImageScaledToCanvas()
      .toDataURL(
        this.state.transparency ? "image/png" : this.state.mimeType,
        q
      );
    fetch(canvas)
      .then((res) => res.blob())
      .then((blob) => {
        let reader = new FileReader();
        reader.onloadend = () => {
          var image = new Image();
          image.src = reader.result;
          image.onload = () => {
            this.reset();
            this.setState(
              {
                file: !this.state.clearOnSuccess ? blob : "",
                uploadFile: !this.state.clearOnSuccess ? true : false,
                image: image,
              },
              () => {
                this.props.onSaveEdit(blob, image, {
                  width: this.state.width,
                  height: this.state.height,
                });
              }
            );
          };
        };

        reader.readAsDataURL(blob);
      });
  }

  cancelEdit() {
    this.reset();
  }

  reset() {
    this.setState(
      {
        file: "",
        showSaveButton: false,
        uploadFile: false,
        rotate: 0,
        zoom: 1,
      },
      () => {
        this.props.onCancelEdit();
      }
    );
  }

  renderSaveCancel() {
    return (
      <div className="col-md-4">
        <div className="gap40" />
        <button
          onClick={() => this.saveEdit()}
          className="btn btn-primary pull-right">
          {this.state.saveImageLabel}
        </button>
        <div className="btn  hidden pull-right" />

        <button
          onClick={() => this.cancelEdit()}
          className="btn btn-default pull-right">
          Cancel
        </button>
      </div>
    );
  }

  clickImageUploadBox() {
    $(".imageUpload").trigger("click");
  }

  hideThumbnail() {
    return (
      <div
        className="thumbnailImageHolder"
        onClick={this.clickImageUploadBox.bind(this)}>
        <span>{this.props.thumbnailAlt}</span>
        <label className="border-0">
          <i className="fa fa-camera" />
        </label>
      </div>
    );
  }

  showAvatarEditor() {
    let width = this.state.width || 250;
    let height = this.state.height || 250;
    let w = parseInt(width, 10);
    let h = parseInt(height, 10);

    return (
      <Scrollbars style={{ height: "350px" }}>
        <div className="avatarEditor">
          <AvatarEditor
            crossOrigin="anonymous"
            ref={this.setEditorRef}
            image={this.state.file}
            width={w}
            onImageReady={this.onImageReady}
            height={h}
            border={this.state.border}
            borderRadius={this.state.borderRadius}
            color={[255, 255, 255, 0.6]}
            scale={this.state.zoom}
            rotate={this.state.rotate}
          />
        </div>
      </Scrollbars>
    );
  }

  setEditorRef = (editor) => (this.editor = editor);

  render() {
    const isFile = this.state.uploadFile;
    const views = this.state.views;

    return (
      <section>
        <div>
          <div>
            {isFile ? this.showAvatarEditor() : null}
            <br />
            {/* {!isFile ? this.hideThumbnail() : " "} */}
            {!this.state.showSaveButton ? (
              <div>
                {!this.state.hideInput && (
                  <label className="btn btn-primary btn-upload-avatar">
                    {this.props.thumbnailAlt}
                    <input
                      className="imageUpload"
                      type="file"
                      name="FileUpload"
                      onChange={this.onImageChange.bind(this)}
                    />
                  </label>
                )}
              </div>
            ) : (
              " "
            )}
          </div>
        </div>
        {isFile && views.length > 0 ? (
          <div>
            <div className="row imageStyler">
              {/*eslint-disable-next-line*/}
              {views.map((item) => {
                switch (item) {
                  case "rotate":
                    return this.renderRotateView();
                  case "dimensions":
                    return this.renderHeightWidthView();
                  case "zoom":
                    return this.renderZoomView();
                  // case "radius":
                  //   return this.renderRadiusView();
                  case "scale":
                    return this.renderScaleView();
                  case "transparency":
                    if (views.indexOf("transparency") === -1) {
                      views.push("transparency");
                    }
                    return this.renderTransparencyView();
                  case "quality": {
                    if (!this.state.transparency) {
                      return this.renderQualityView();
                    }
                    break;
                  }
                  default:
                    return <div key="other" />;
                }
              })}

              {this.state.showSaveButton ? this.renderSaveCancel() : ""}
            </div>
          </div>
        ) : null}
      </section>
    );
  }
}
export default ImageUpload;
