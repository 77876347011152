import React, { Component } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { showNotification } from "../../helpers";
import $ from "jquery";
import TagUsers from "../../components/form/tagUsers";
import { callCreateGenericTag } from "../../services/tagService";
import SelectAddImage from "./selectAddImage";

class GenericTagSelect extends Component {
  constructor(props) {
    super(props);

    let selectedValues = [];
    if (this.props.selectedValues) {
      selectedValues = this.props.selectedValues;
    }

    this.state = {
      cKey: this.props.id || "unique-id",
      data: this.props.data,
      valueSelected: {},
      keyAuto: Math.random(),
      value: "",
      selectedValues: selectedValues,
      popupType: "",
      popupLabel: this.props.lblText,
      popupHeaderText: this.props.popupHeaderText,
      popupHeaderFFEnableName: this.props.popupHeaderFFEnableName | true,
      popupHeaderFFEnableDescription:
        this.props.popupHeaderFFEnableDescription | false,
      popupHeaderFFEnableTags: this.props.popupHeaderFFEnableTags | false,
      popupGenericType: this.props.popupGenericType,
      popupKey: Math.random(),
      isClickable: this.props.isClickable,
      selectedTag: {},
      keyTagUsers: Math.random(),
      selectedIncludedTags: [],
      selectedExcludedTags: [],
      addTags: [],
      excludeTags: [],
      popupTitle: this.props.popupTitle || "Tags",
      socialMediaImage: "",
      socialImage: this.props.socialImage || "yes",
    };
  }

  onChangeValue = (value) => {
    // value
  };

  onSelectValue = (val) => {
    let name = val.name !== undefined ? val.name : val.title;

    this.setState({
      valueSelected: val,
      value: name,
    });

    this.updateSelectTagModel(name);
  };

  updateSelectTagModel = (value) => {
    const selectKey = this.props.selectKey;
    let selectedValues = this.state.selectedValues;
    const valueSelected = this.state.valueSelected;
    let selectedTag = selectedValues.filter((d) => {
      return d.name === value || d === value;
    });

    if (selectedTag.length > 0) {
      showNotification("warning", `'${value}'is already added`, 4000);
      return;
    }

    if (valueSelected[selectKey]) {
      selectedValues.push(valueSelected);
    } else if (value.length > 0) {
      let tags = this.state.data;
      let fTag = tags.filter((d) => {
        return d.name === value;
      });
      if (fTag.length > 0) {
        selectedValues.push(fTag[0]);
      } else {
        selectedValues.push(value);
      }
    }

    this.setState({
      value: "",
      valueSelected: {},
      selectedValues: selectedValues,
      keyAuto: Math.random(),
    });
    this.onChangeDataValue(selectedValues);
  };

  onPressAddValue = (tag) => {
    // this.setState(
    //   {
    //     popupType: "addTag",
    //     popupLabel: this.state.popupLabel,
    //     popupKey: Math.random()
    //   },
    //   () => {
    //     openPagePopupModal("addTag");
    //   }
    // );

    // tag = {
    //   _id: "5dfb78b5d2543157183f9b2e",
    //   name: "Vehicale",
    //   type: "challenge-item",
    //   createdAt: "2019-12-19T13:18:45.059Z",
    //   updatedAt: "2020-01-21T13:26:41.232Z",
    //   description: "<p>vehicale	</p>",
    //   tag_list_included: [],
    //   tag_list_excluded: []
    // }
    /*eslint-disable*/

    tag = {
      _id: "1",
      name: "",
      type: this.state.popupGenericType,
      createdAt: "",
      updatedAt: "",
      description: "",
      tag_list_included: [],
      tag_list_excluded: [],
    };
    /*eslint-enable*/

    let includedTags = tag.fincludedtags || [];
    let excludedTags = tag.fexcludedtags || [];
    this.setState(
      {
        selectedTag: tag,
        tagName: tag.name,
        tagDescription: tag.description,
        keyTagDescription: Math.random(),
        selectedIncludedTags: includedTags,
        selectedExcludedTags: excludedTags,
        addTags: includedTags,
        excludeTags: excludedTags,
      },
      () => {
        $(
          ".modalAlertOverlay.updateTagModalOverlay,.modalAlert.updateTagModal"
        ).fadeIn(200);
      }
    );
  };

  onChangeImage = (image) => {
    this.setState({ socialMediaImage: image });
  };

  renderUpdateTagModal() {
    let selectedTag = this.state.selectedTag;

    if (!selectedTag._id) {
      return null;
    }
    return (
      <div>
        <div className="modalAlertOverlay updateTagModalOverlay" />
        <div className="modalAlert updateTagModal bigX">
          <div className="clearfix">
            <div className="pull-left">
              <p>{this.state.popupHeaderText}</p>
            </div>
          </div>

          <div className="container-fluid">
            {Boolean(this.state.popupHeaderFFEnableName) && (
              <div>
                <div
                  className={
                    this.state.tagName !== "" ? "mdInput mdFocussed" : "mdInput"
                  }>
                  <label>Name</label>
                  <input
                    type="text"
                    name="tagName"
                    value={this.state.tagName}
                    onChange={(event) => this.handleInputChange(event)}
                  />
                </div>
                <div className="gap20" />
              </div>
            )}
            {Boolean(this.state.popupHeaderFFEnableDescription) && (
              <div>
                <div
                  className={
                    this.state.tagDescription !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Description</label>

                  <input
                    type="text"
                    name="tagDescription"
                    value={this.state.tagDescription}
                    onChange={(event) => this.handleInputChange(event)}
                  />
                </div>
                <div className="gap10" />
              </div>
            )}

            <div className="gap10" />
            {this.state.socialImage === "yes" && (
              <SelectAddImage
                type="social-media-image"
                label="Social Media Image"
                onChangeImage={this.onChangeImage}
                image={this.state.socialMediaImage}
              />
            )}
            <div className="gap10" />

            {Boolean(this.state.popupHeaderFFEnableTags) && (
              <div>
                <TagUsers
                  key={this.state.keyTagUsers}
                  contentId={selectedTag._id}
                  selectedIncludedTags={this.state.selectedIncludedTags}
                  selectedExcludedTags={this.state.selectedExcludedTags}
                  history={this.props.history}
                  onChangeAddTags={this.onChangeAddTags}
                  onChangeExcludeTags={this.onChangeExcludeTags}
                />
                <div className="gap20" />
              </div>
            )}
          </div>
          <div className="gap20" />
          <div className="alertFooterBtns">
            <button
              onClick={this.onClickCancelFromUpdateTagModal.bind(this)}
              className="btn btn-default dismissThisModal margin-right-10">
              Cancel
            </button>
            <button
              onClick={this.onClickCreateFromUpdateTagModel.bind(this)}
              className="btn btn-primary dismissThisModal">
              Add
            </button>
          </div>
        </div>
      </div>
    );
  }

  onChangeTagDescription(value, text) {
    this.setState({
      tagDescription: value,
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  onChangeAddTags = (tags) => {
    this.setState({ addTags: tags });
  };

  onChangeExcludeTags = (tags) => {
    this.setState({ excludeTags: tags });
  };

  onClickCancelFromUpdateTagModal() {
    $(
      ".modalAlertOverlay.updateTagModalOverlay,.modalAlert.updateTagModal"
    ).fadeOut(200);
  }

  onClickCreateFromUpdateTagModel() {
    if (this.state.tagName.trim().length === 0) {
      showNotification("error", "Please enter tag name", 4000);
      return;
    }

    let addTagIds = this.state.addTags.map((t) => t._id);
    let excludeTagIds = this.state.excludeTags.map((t) => t._id);

    /*eslint-disable*/
    let addData = {
      name: this.state.tagName,
      description: this.state.tagDescription,
      tag_list_included: addTagIds,
      tag_list_excluded: excludeTagIds,
      type: this.state.popupGenericType,
      social_media_image: this.state.socialMediaImage,
    };
    /*eslint-enable*/

    callCreateGenericTag(addData).then((d) => {
      this.props.addTag(d);
      $(
        ".modalAlertOverlay.updateTagModalOverlay,.modalAlert.updateTagModal"
      ).fadeOut(200);
    });
  }

  onAddNewTag() {
    this.props.reload();
  }

  onChangeDataValue(data) {
    this.props.onChange(data);
  }

  onClickRemoveValue = (i) => {
    let selectedValues = this.state.selectedValues;
    selectedValues.splice(i, 1);
    this.setState({
      selectedValues: selectedValues,
      value: "",
      valueSelected: {},
      keyAuto: Math.random(),
    });
    this.onChangeDataValue(selectedValues);
  };

  renderSelectedValues() {
    var returnVals = [];
    let selectedValues = this.state.selectedValues;

    for (let i = 0; i < selectedValues.length; i++) {
      let val = selectedValues[i][this.props.selectKey]
        ? selectedValues[i][this.props.selectKey]
        : selectedValues[i];
      returnVals.push(
        <span key={i} onClick={this.onClickRemoveValue.bind(this, i)}>
          {val}
        </span>
      );
    }
    return returnVals;
  }
  onClickCloseFromTagsModal() {
    $(
      `.modalAlertOverlay.viewTagsOverlay${this.state.cKey},.modalAlert.viewTagsAlert${this.state.cKey}`
    ).fadeOut(200);
  }

  onPressViewTags() {
    $(
      `.modalAlertOverlay.viewTagsOverlay${this.state.cKey},.modalAlert.viewTagsAlert${this.state.cKey}`
    ).fadeIn(200);
  }

  onClickTagFromViewTags(tag) {
    this.onSelectValue(tag);
    this.onClickCloseFromTagsModal();
  }

  renderTagsModal() {
    let modalHeight = window.innerHeight - 200;
    let data = this.state.data;

    if (data.length === 0) {
      return null;
    }

    return (
      <div>
        <div
          className={`modalAlertOverlay viewTagsOverlay${this.state.cKey}`}
          onClick={this.onClickCloseFromTagsModal.bind(this)}
        />
        <div className={`modalAlert viewTagsAlert${this.state.cKey}`}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {this.state.popupTitle}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.onClickCloseFromTagsModal.bind(this)}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div
              className="container-fluid modalInnerBody"
              style={{ maxHeight: modalHeight, overflowY: "hidden" }}>
              <Scrollbars
                autoHide
                autoHideTimeout={1000}
                autoHideDuration={200}
                style={{ height: modalHeight }}>
                <div className="gap20" />
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((i) => (
                      <tr key={i._id}>
                        <td>{i.name}</td>
                        <td>
                          <button
                            title="Report"
                            onClick={this.onClickTagFromViewTags.bind(this, i)}
                            className="btn btn-default btn-sm">
                            Select
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Scrollbars>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <>
        <div className="row">
          {this.renderTagsModal()}
          {this.renderUpdateTagModal()}

          {this.state.isClickable && (
            <div className="col-md-2">
              <div className="gap30" />
              <button
                className="btn btn-block btn-sm btn-default"
                onClick={this.onPressAddValue.bind(this)}>
                {this.props.btnText}
              </button>
            </div>
          )}
          <div className="col-md-2">
            <div className="gap30" />
            <button
              className="btn btn-block btn-sm btn-default"
              onClick={this.onPressViewTags.bind(this)}>
              View {this.state.popupTitle}
            </button>
          </div>
          <div className="gap20" />
        </div>
        <div style={{ marginTop: 20 }} className="tagsHolder">
          {this.renderSelectedValues()}
        </div>
      </>
    );
  }
}

export default GenericTagSelect;
