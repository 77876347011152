import {
  RESET_STATUS,
  CREATE_ARTICLE_SUCCESS,
  CREATE_ARTICLE_FAILURE,
  GET_ARTICLE_SUCCESS,
  GET_ARTICLE_CATEGORY_SUCCESS,
  CREATE_CONTENT_TEMPLATE_SUCCESS,
  CREATE_CONTENT_TEMPLATE_FAILURE,
  GET_CONTENT_TEMPLATE_SUCCESS,
  UPDATE_CONTENT_TEMPLATE_SUCCESS,
  UPDATE_CONTENT_TEMPLATE_FAILURE,
  UPDATE_ARTICLE_SUCCESS,
  UPDATE_ARTICLE_FAILURE
} from "../types";

const initialState = {
  message: "",
  success: false,
  articles: {},
  articleCategories: {},
  contentTemplates: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RESET_STATUS:
      return {
        ...state,
        message: "",
        success: false
      };
    case CREATE_ARTICLE_SUCCESS:
      return {
        ...state,
        message: action.message,
        success: action.success
      };
    case CREATE_ARTICLE_FAILURE:
      return {
        ...state,
        message: action.message,
        success: action.success
      };
    case UPDATE_ARTICLE_SUCCESS:
      return {
        ...state,
        message: action.message,
        success: action.success
      };
    case UPDATE_ARTICLE_FAILURE:
      return {
        ...state,
        message: action.message,
        success: action.success
      };
    case GET_ARTICLE_SUCCESS:
      return {
        ...state,
        articles: action.data
      };
    case GET_ARTICLE_CATEGORY_SUCCESS:
      return {
        ...state,
        articleCategories: action.data
      };
    case CREATE_CONTENT_TEMPLATE_SUCCESS:
      return {
        ...state,
        message: action.message,
        success: action.success
      };
    case CREATE_CONTENT_TEMPLATE_FAILURE:
      return {
        ...state,
        message: action.message,
        success: action.success
      };
    case GET_CONTENT_TEMPLATE_SUCCESS:
      return {
        ...state,
        contentTemplates: action.data
      };
    case UPDATE_CONTENT_TEMPLATE_SUCCESS:
      return {
        ...state,
        message: action.message,
        success: action.success
      };
    case UPDATE_CONTENT_TEMPLATE_FAILURE:
      return {
        ...state,
        message: action.message,
        success: action.success
      };
    default:
      return state;
  }
};
