import React from "react";
import { callGetSystemTags } from "../../../../services";
import TagSelect from "../../../../components/form/tagSelect";

class TagPicker extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      systemTags: [],
      keyTag: Math.random(),
      sTags: this.props.selectedTags || [],
      selectedTags: [],
      addedTags: [],
      reload: false,
    };
  }

  componentDidMount() {
    this.getSystemTags();
  }

  async getSystemTags(reload = false) {
    let tags = await callGetSystemTags();
    this.setState(
      { systemTags: tags.data, keyTag: Math.random(), reload },
      () => {
        let sTags = this.state.sTags;
        if (sTags.length > 0) {
          let tList = [];
          sTags.forEach((element) => {
            let t = tags.data.filter((d) => d._id === element);
            if (t.length > 0) {
              tList.push(t[0]);
            }
          });
          this.setState({ selectedTags: tList, keyTag: Math.random(), reload });
        }
      }
    );
  }

  onChangeTags = (tags) => {
    this.setState({ addedTags: tags });
    this.props.onChangeTags(tags, this.state.reload);
  };

  render() {
    return (
      <div>
        <TagSelect
          id={"modal-tag-picker"}
          key={this.state.keyTag}
          selectedValues={this.state.selectedTags}
          searchKey={"name"}
          selectKey={"name"}
          btnText={"Create New Tag"}
          lblText={"Select Tag"}
          data={this.state.systemTags}
          onChange={this.onChangeTags}
          isClickable={true}
          reload={() => {
            this.getSystemTags(true);
          }}
        />
      </div>
    );
  }
}
export default TagPicker;
