import {
  callGetAllChat,
  callGetChatHistory,
  callStaffReplay
} from "../../services/index";

export const getAllChat = () => {
  return async dispatch => {
    return callGetAllChat()
      .then(chat => {
        dispatch({
          type: "CHAT_GET_SUCCESS",
          payload: chat.data
        });
      })
      .catch(err => {
        dispatch({
          type: "CHAT_ERROR"
        });
      });
  };
};
export const createStaffChat = (chatData, _id) => {
  return async dispatch => {
    if (_id) {
      return callGetChatHistory(_id)
        .then(chatHis => {
          let conversation = chatHis.conversation;
          conversation.push({
            staffId: chatData.conversation.staffId,
            from: chatData.conversation.from,
            message: chatData.conversation.message,
            createdAt: new Date()
          });

          const updateConversation = {
            staffId: chatData.staffId,
            conversation: conversation
          };
          return callStaffReplay(_id, updateConversation)
            .then(data => {
              dispatch({
                type: "STAFF_REPLAY_SUCCESS",
                payload: data
              });
            })
            .catch(err => {
              dispatch({
                type: "CHAT_ERROR"
              });
            });
        })
        .catch(err => {
          dispatch({
            type: "CHAT_ERROR"
          });
        });
    }
  };
};
export const getOneChat = _id => {
  return async dispatch => {
    return callGetChatHistory(_id)
      .then(oneChat => {
        dispatch({
          type: "GET_ONE_CHAT",
          payload: oneChat
        });
      })
      .catch(err => {
        dispatch({
          type: "CHAT_ERROR"
        });
      });
  };
};
