import SecureLS from "secure-ls";

var storage = new SecureLS({ encodingType: "aes", isCompression: true });

function setItem(key, value) {
  storage.set(key, value);
}

function setStorageItem(key, value) {
  storage.set(key, value);
}

function getItem(key) {
  try {
    return storage.get(key);
  } catch (error) {
    removeAll();
  }
}

function getStorageItem(key) {
  try {
    return storage.get(key);
  } catch (error) {
    removeAll();
  }
}

function removeItem(key) {
  return storage.remove(key);
}

function removeAll() {
  return storage.removeAll();
}

function migrateStorage() {
  let migrateDone = getItem("migrateDone");
  if (!migrateDone) {
    let tokenExpiry = window.localStorage.getItem("tokenExpiry");
    if (tokenExpiry) {
      setItem("tokenExpiry", tokenExpiry);
    }

    let accessToken = window.localStorage.getItem("accessToken");
    if (accessToken) {
      setItem("accessToken", accessToken);
    }

    let userData = window.localStorage.getItem("userData");
    if (userData) {
      setItem("userData", userData);
    }

    let placeholder = window.localStorage.getItem("placeholder");
    if (placeholder) {
      setItem("placeholder", placeholder);
    }

    let fsced = window.localStorage.getItem("fsced");
    if (fsced) {
      setItem("fsced", fsced);
    }

    setItem("migrateDone", "true");
  }
}

export {
  setItem,
  getItem,
  removeItem,
  removeAll,
  migrateStorage,
  setStorageItem,
  getStorageItem,
};
