import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import VideoPlayer from "../general/videoPlayer";
import "video.js/dist/video-js.css";
// Fantasy
import "@videojs/themes/dist/fantasy/index.css";

class EmbedVideo extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    const { url, player, fullUrl, thumbUrl, aspectRatio } = this.props;

    this.state = {
      url: url,
      aspectRatio: aspectRatio || "16:9",
      fullUrl: fullUrl,
      player: player,
      thumbUrl: thumbUrl,
    };
  }

  render() {
    const { url, player, fullUrl, thumbUrl } = this.state;

    switch (player) {
      case "vimeo":
        let vimeoUrl = `https://player.vimeo.com/video/${url}`;
        if (fullUrl) {
          vimeoUrl = this.state.fullUrl;
        }
        return (
          <div class="maxVideo">
            <div className="embed-responsive embed-responsive-16by9">
              <iframe
                className="videoIframe"
                src={vimeoUrl}
                frameborder="0"
                allow="autoplay; fullscreen"
                allowfullscreen
                title={vimeoUrl}
              />
            </div>
          </div>
        );

      case "vimeoEmbed":
        let vimeoEmUrl = `https://player.vimeo.com/video/${url}`;
        if (fullUrl) {
          vimeoEmUrl = this.state.fullUrl;
        }
        return (
          <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
            <iframe
              title={vimeoEmUrl}
              src={vimeoEmUrl}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
              frameborder="0"
              allow="autoplay; fullscreen"
              allowfullscreen></iframe>
          </div>
        );
      case "youtube":
        let youtubeUrl = `https://www.youtube.com/embed/${url}`;
        if (fullUrl) {
          youtubeUrl = this.state.fullUrl;
        }
        return (
          <div className="maxVideo">
            <div className="embed-responsive embed-responsive-16by9 video-responsive">
              <iframe
                className="videoIframe"
                title={youtubeUrl}
                src={youtubeUrl}
                frameBorder="0"
                allowFullScreen
              />
            </div>
          </div>
        );

      case "vooplayer":
        const id = url;
        let vooSrc = "https://videos.cdn.vooplayer.com/publish/" + url;
        return (
          <div className="maxVideo">
            <div className="embed-responsive embed-responsive-16by9">
              <iframe
                title={url}
                className="vooplayer"
                data-playerid={id}
                allowtransparency="true"
                style={{ maxWidth: "100%" }}
                name="vooplayerframe"
                allowFullScreen="true"
                src={vooSrc}
                frameBorder="0"
                scrolling="no"
              />
            </div>
          </div>
        );

      case "member-pages":
      default:
        var videoOptions = {
          autoplay: false,
          controls: true,
          responsive: true,
          preload: false,
          html5: { vhs: { smoothQualityChange: false } },
          poster: thumbUrl,
          // playbackRates: [0.5, 1, 1.5, 2], // Let this one be an option they can toggle on or off in the editor.
          sources: [
            {
              src: url,
              type: "application/x-mpegURL", // This one should change if they directly add an mp4... For now though they are not allowed
            },
          ],
          controlBar: {
            fullscreenToggle: true,
          },
          plugins: {
            httpSourceSelector: {
              default: "high",
            },
          },
          hls: {
            overrideNative: false,
          },
        };
        return (
          <VideoPlayer
            {...videoOptions}
            aspectRatio={this.state.aspectRatio}
            onPlayerChange={this.props.onPlayerChange}
          />
        );
      // return (
      //   <video
      //     className="video-js vjs-theme-fantasy vjs-fluid vjs-big-play-centered vjs-show-big-play-button-on-pause"
      //     controls
      //     preload="auto"
      //     controlslist="nodownload"
      //     data-setup="{}">
      //     <source src={url} type="application/x-mpegURL" />
      //   </video>
      // );
    }
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EmbedVideo);
