import React from "react";

import $ from "jquery";
import moment from "moment-timezone";
import Checkbox from "../../../components/form/checkBox";
import {
  callCreateMemberChallenge,
  callMemberChallengeByQuery,
} from "../../../services";
import { getItem } from "../../../helpers/storage";
import { getParameterByName } from "../../../helpers";
// import DatePicker from "react-date-picker";

class PreviewChallenge extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    let preview = getParameterByName("p") || "no";
    this.state = {
      selectedChallenge: {},
      challengeDate: new Date(),
      challengeCurrentDay: 1,
      keyChallengeDate: Math.random(),
      previewForm: false,
      preview: preview,
    };
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  onClickPreviewButton(challenge) {
    this.setState({ selectedChallenge: challenge }, () => {
      this.setState({ keyChallengeDate: Math.random() });
      $(
        ".modalAlertOverlay.previewChallengeModalOverlay,.modalAlert.previewChallengeModal"
      ).fadeIn(200);
    });
  }

  onChangeChallengeDate(value) {
    this.setState({
      challengeDate: value,
    });
  }

  onClickCancelFromPreviewChallengeModal() {
    $(
      ".modalAlertOverlay.previewChallengeModalOverlay,.modalAlert.previewChallengeModal"
    ).fadeOut(200);
  }

  async onClickPreviewFromPreviewChallengeModal() {
    if (this.state.preview === "yes") {
      let memberId = getItem("userId");
      /*eslint-disable*/
      const chlgResponse = await callMemberChallengeByQuery({
        query: {
          member_id: memberId,
          challenge_id: this.state.selectedChallenge._id,
        },
      });
      /*eslint-enable*/
      let mcId = "";
      if (chlgResponse.data.length > 0) {
        mcId = chlgResponse.data[0]._id;
      } else {
        /*eslint-disable*/
        let challengeData = {
          member_id: memberId,
          challenge_id: this.state.selectedChallenge._id,
          customer_order_id: "ffffffffffffffffffffffff",
          name: this.state.selectedChallenge.name,
          start_date: moment().format("MM-DD-YYYY"),
          active_days: this.state.selectedChallenge.active_days,
          add_active_days: 1,
          challenge_days: this.state.selectedChallenge.challenge_days,
          preview_days: 0,
          redeemed_date: "",
          tos_agree_date: "",
          forever_open: true,
          challenge_item_completed: [],
          tag_id: "",
          status: "completed",
          preview: "yes",
        };
        /*eslint-enable*/
        const memberChallenge = await callCreateMemberChallenge(challengeData);
        mcId = memberChallenge._id;
      }
      if (mcId) {
        this.props.history.push(
          `/member/challenge/${mcId}?d=${this.state.challengeCurrentDay}`
        );
      }
    } else {
      // go to challenge page

      /*eslint-disable*/
      let challenge = {
        name: this.state.selectedChallenge.name,
        start_date: moment(this.state.challengeDate).format("MM-DD-YYYY"),
        challenge_item_completed: [],
        chlg_current_day: this.state.challengeCurrentDay,
        challenge_info: [this.state.selectedChallenge],
      };
      /*eslint-enable*/

      let previewForm = this.state.previewForm ? "yes" : "no";

      this.props.history.push(
        `/member/challenge?from=admin&form=${previewForm}`,
        {
          challenge: challenge,
        }
      );
    }
  }

  renderChallengeDays() {
    let returnVals = [];

    let addActiveDays = this.state.selectedChallenge.add_active_days || "0";
    let activeDays = this.state.selectedChallenge.active_days || "0";

    let days = parseInt(addActiveDays) + parseInt(activeDays);
    if (days > 0) {
      for (let i = 1; i <= days; i++) {
        returnVals.push(
          <option value={i} key={i}>
            {i}
          </option>
        );
      }
    } else {
      returnVals.push(
        <option value={0} key={0}>
          0
        </option>
      );
    }

    return returnVals;
  }

  toggleCheckbox(check) {
    this.setState({ previewForm: check });
  }

  renderPreviewChallengeModal() {
    let name = "";
    if (this.state.selectedChallenge.name) {
      name = this.state.selectedChallenge.name;
    }
    return (
      <div>
        <div className="modalAlertOverlay previewChallengeModalOverlay" />
        <div className="modalAlert previewChallengeModal">
          <div className="clearfix">
            <div className="pull-left">
              <p>Preview Challenge - {name}</p>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
              <p>
                Please select the day you would like to preview (as different
                content can be available on different days during a challenge).
              </p>
              {/* <div className="col-sm-6">
                <div
                  className={
                    this.state.challengeDate !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Select Start Date</label>
                  <DatePicker
                    key={this.state.keyChallengeDate}
                    value={this.state.challengeDate}
                    onChange={this.onChangeChallengeDate.bind(this)}
                  />
                </div>
              </div> */}
              <div className="col-sm-6">
                <div className={"mdInput mdFocussed"}>
                  <label>Select Challenge Day</label>
                  <select
                    name="challengeCurrentDay"
                    value={this.state.challengeCurrentDay}
                    onChange={(event) => this.handleInputChange(event)}>
                    {this.renderChallengeDays()}
                  </select>
                </div>
                <div className="gap20" />
                <Checkbox
                  label={"Show Preview Form"}
                  isChecked={this.state.previewForm}
                  handleCheckboxChange={this.toggleCheckbox.bind(this)}
                />
              </div>
            </div>
          </div>
          <div className="gap10" />

          <div className="alertFooterBtns">
            <button
              onClick={this.onClickCancelFromPreviewChallengeModal.bind(this)}
              className="btn btn-default dismissThisModal margin-right-10">
              Cancel
            </button>
            <button
              onClick={this.onClickPreviewFromPreviewChallengeModal.bind(this)}
              className="btn btn-primary dismissThisModal">
              Preview
            </button>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return <div>{this.renderPreviewChallengeModal()}</div>;
  }
}

export default PreviewChallenge;
