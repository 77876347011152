import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import $ from "jquery";
import { Scrollbars } from "react-custom-scrollbars";

import TitleBox from "../../../components/general/titleBox";

import FilterList from "../../../components/form/filterList";
import { showNotification } from "../../../helpers";
import {
  callGetPageCheckoutItemsByQuery,
  callUpdateCheckoutItem,
} from "../../../services";
import { SettingsHelper } from "../../../helpers/settings";
import ScriptPopup from "../../../components/general/scriptPopup";
import ReactPaginate from "react-paginate";

class ViewArchivedCheckoutItem extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      items: [],
      itemsBackup: [],
      filterList: [],
      types: [
        { name: "All", value: "all", selected: true },
        { name: "Single", value: "single", selected: false },
        { name: "Subscription", value: "subscription", selected: false },
      ],
      domainList: [],
      accountKey: "",
      selectedItem: {},
      scriptData: {},
      showScript: false,
      page: 0,
      pages: 0,
      totalCheckoutItems: 0,
      searchQuery: "",
      /*eslint-disable*/
      sQuery: { order_type: "all", search: "" },
      /*eslint-enable*/
    };
  }

  componentDidMount() {
    this.getCheckoutItems();
  }

  async getCheckoutItems() {
    let limit = 10;
    let sQuery = this.state.sQuery;
    let query = {
      query: {
        page: this.state.page,
        limit: limit,
        $sort: { createdAt: -1 },
        archive: "archive",
      },
    };
    if (sQuery.order_type !== "all") {
      query.query["order_type"] = sQuery.order_type;
    }
    if (sQuery.search) {
      let regex = { $regex: sQuery.search, $options: "i" };
      /*eslint-disable*/
      query.query["$or"] = [
        { name: regex },
        { description: regex },
        { checkout_id: regex },
      ];
      /*eslint-enable*/
    }

    let itemsData = await callGetPageCheckoutItemsByQuery(query);
    let items = itemsData.data;

    let settingsHelper = new SettingsHelper();
    let accountKey = settingsHelper.getAccountKey();
    let domainList = settingsHelper.getDomainList();

    this.setState(
      {
        totalCheckoutItems: itemsData.total,
        pages: Math.ceil(itemsData.total / limit),
        showLoader: false,
        items: items,
        itemsBackup: items,
        accountKey,
        domainList,
      },
      () => {
        if ($(window).width() > 780) {
          setTimeout(() => {
            let tooltipFn = $("[data-toggle='tooltip']");
            if (tooltipFn.tooltip) {
              tooltipFn.tooltip({
                html: true,
                animated: "fade",
              });
            }
          }, 2000);
        }
      }
    );
  }

  onClickEditCheckoutItem = (checkoutItem) => {
    this.props.history.push("/admin/add-checkout-item", {
      checkoutItem: checkoutItem,
      type: "edit",
    });
  };

  onClickSetting = (item) => {
    let scriptData = item.script_data || {};
    this.setState(
      { selectedItem: item, scriptData: scriptData, showScript: true },
      () => {
        $(
          ".modalAlertOverlay.scriptSettingModal,.modalAlert.scriptSettingAlert"
        ).fadeIn(200);
      }
    );
  };

  filterUpdated(updatedFilter, name, type, searchFields) {
    let filterList = this.state.filterList;
    filterList[name] = {
      name: name,
      type: type,
      searchFields: searchFields,
      values: updatedFilter,
    };
    let query = this.state.sQuery;

    let searchType = filterList.searchType;
    if (searchType) {
      let values = searchType.values.filter((d) => {
        return d.selected === true;
      });
      if (values.length > 0) {
        query["order_type"] = values[0].value;
      }
    }

    let search = filterList.search;
    if (search) {
      let values = search.values[0];
      query["search"] = values.value;
    }

    this.setState(
      {
        sQuery: query,
        filterList: filterList,
      },
      () => {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.handlePageClick({ selected: 0 });
        }, 300);
      }
    );
  }

  getCheckoutUrl(element) {
    let domainList = this.state.domainList;
    let checkoutUrls = domainList.filter((d) => {
      return d.type === "checkout";
    });
    let checkoutUrl = "https://checkout.memberpages.app";
    if (checkoutUrls.length > 0) {
      checkoutUrl = checkoutUrls[0].domain;
      checkoutUrl = `https://${checkoutUrl}`;
    }
    return `${checkoutUrl}?p=${element.checkout_id}&key=${this.state.accountKey}`;
  }

  getCheckoutCustomUrl(element) {
    let domainList = this.state.domainList;
    let checkoutUrls = domainList.filter((d) => {
      return d.type === "checkout";
    });
    let checkoutUrl = "https://checkout.memberpages.app";
    if (checkoutUrls.length > 0) {
      checkoutUrl = checkoutUrls[0].domain;
      checkoutUrl = `https://${checkoutUrl}`;
    }
    let item = this.state.selectedItem;
    return `${checkoutUrl}?p=${item.checkout_id}&c=${element.id}&key=${this.state.accountKey}`;
  }

  onClickInfoCheckoutUrl = (element) => {
    this.setState({ selectedItem: element }, () => {
      $(".modalAlertOverlay.infoOverlay,.modalAlert.infoAlert").fadeIn(200);
    });
  };

  onClickCloseFromInfoModal() {
    $(".modalAlertOverlay.infoOverlay,.modalAlert.infoAlert").fadeOut(200);
  }

  getAmount(element) {
    let amount = 0;
    if (element.type === "date") {
      amount += element.dates
        .map((i) => parseFloat(i.amount))
        .reduce((a, b) => a + b);
    } else {
      amount += element.days
        .map((i) => parseFloat(i.amount))
        .reduce((a, b) => a + b);
    }
    return amount;
  }

  onClickCloseFromTagInfoModal() {
    $(".modalAlertOverlay.tagInfoOverlay,.modalAlert.tagInfoAlert").fadeOut(
      200
    );
  }

  renderTagInfoModal() {
    let modalHeight = window.innerHeight - 200;
    let iTags = this.state.selectedItem.ftags || [];

    return (
      <div>
        <div className="modalAlertOverlay tagInfoOverlay" />
        <div className="modalAlert tagInfoAlert modal-lg big">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title margin-right-10"
                id="exampleModalLabel">
                Tags
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.onClickCloseFromTagInfoModal.bind(this)}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div
              className="container-fluid modalInnerBody"
              style={{ maxHeight: modalHeight, overflowY: "hidden" }}>
              <Scrollbars
                autoHide
                autoHideTimeout={1000}
                autoHideDuration={200}
                style={{ height: modalHeight }}>
                <div className="gap20" />
                {iTags.length > 0 && (
                  <>
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Name</th>
                        </tr>
                      </thead>
                      <tbody>
                        {iTags.map((i) => (
                          <tr key={i._id}>
                            <td>{i._id}</td>
                            <td>{i.name}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="gap10" />
                  </>
                )}
              </Scrollbars>
            </div>
          </div>
        </div>
      </div>
    );
  }

  getMaxDay(days) {
    let daysValues = days.map((i) => parseInt(i.day, 10));
    return Math.max.apply(Math, daysValues);
  }

  renderInfoModal() {
    let modalHeight = window.innerHeight - 200;
    let options = this.state.selectedItem.payment_options;
    if (!options) {
      return null;
    }

    return (
      <div>
        <div className="modalAlertOverlay infoOverlay" />
        <div className="modalAlert infoAlert modal-lg big">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title margin-right-10"
                id="exampleModalLabel">
                Checkout Links
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.onClickCloseFromInfoModal.bind(this)}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div
              className="container-fluid modalInnerBody"
              style={{ maxHeight: modalHeight, overflowY: "hidden" }}>
              <Scrollbars
                autoHide
                autoHideTimeout={1000}
                autoHideDuration={200}
                style={{ height: modalHeight }}>
                <div className="gap20" />
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Type</th>
                      <th>Amount</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {options.map((i) => (
                      <tr key={i.id}>
                        <td>{i.id}</td>
                        <td>
                          {i.type === "date"
                            ? `${i.dates.length} Payments (Date)`
                            : `${i.days.length} Payments (${this.getMaxDay(
                                i.days
                              )})`}
                        </td>
                        <td>${this.getAmount(i)}</td>
                        <td>
                          <CopyToClipboard
                            title={"Copy Checkout Link"}
                            text={this.getCheckoutCustomUrl(i)}
                            onCopy={() =>
                              showNotification(
                                "success",
                                "Link copied to clipboard",
                                5000
                              )
                            }>
                            <button className="btn btn-sm btn-default margin-right-10 border-0">
                              <i className="fa fa-clipboard" />
                            </button>
                          </CopyToClipboard>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Scrollbars>
            </div>
          </div>
        </div>
      </div>
    );
  }

  handlePageClick = (data) => {
    let page = data.selected;
    this.setState({ page: page }, () => {
      this.getCheckoutItems();
    });
  };

  getTagsTitle(element) {
    let iTags = element.ftags || [];

    let iString = "";
    if (iTags.length > 0) {
      iString = `<label><b>Tags :</b>  ${iTags
        .map((i) => i.name)
        .join(", ")}</label>`;
    }

    return iString;
  }

  onClickTagOverview = (checkoutItem) => {
    this.setState({ selectedItem: checkoutItem }, () => {
      $(".modalAlertOverlay.tagInfoOverlay,.modalAlert.tagInfoAlert").fadeIn(
        200
      );
    });
  };

  renderCheckoutItems() {
    var returnVals = [];
    let items = this.state.items;
    items.forEach((element) => {
      returnVals.push(
        <tr key={element._id}>
          <td className={element.active === false ? "text-danger" : ""}>
            {element.name}
          </td>
          <td>{element.checkout_id}</td>
          <td>{element.order_type ? element.order_type : "single"}</td>
          <td>{element.description}</td>
          <td>
            {element.payment_type === "custom"
              ? "Custom"
              : element.amount
              ? `$${element.amount}`
              : element.stripe_plans
              ? `$${parseFloat(element.stripe_plans[0].amount) / 100}`
              : "-"}
          </td>
          <td>
            <button
              onClick={this.onClickTagOverview.bind(this, element)}
              data-toggle="tooltip"
              title={this.getTagsTitle(element)}
              className="btn btn-default btn-sm margin-right-10 border-0">
              <i className="fa fa-info-circle" />
            </button>
          </td>
          <td>{element.active === false ? "N" : "Y"}</td>
          <td className="min-width-100">
            <button
              onClick={this.onClickEditCheckoutItem.bind(this, element)}
              className="btn btn-default btn-sm margin-right-10 border-0">
              <i className="fa fa-pencil" />
            </button>
            {element.payment_type === "fixed" || !element.payment_type ? (
              <CopyToClipboard
                title="Copy Checkout Url"
                text={this.getCheckoutUrl(element)}
                onCopy={() =>
                  showNotification("success", "Link copied to clipboard", 5000)
                }>
                <button className="btn btn-sm btn-default border-0">
                  <i className="fa fa-link" />
                </button>
              </CopyToClipboard>
            ) : (
              <button
                title="Checkout Links"
                onClick={this.onClickInfoCheckoutUrl.bind(this, element)}
                className="btn btn-default btn-sm border-0">
                <i className="fa fa-link" />
              </button>
            )}
          </td>
        </tr>
      );
    });
    return returnVals;
  }

  renderFilterView() {
    return (
      <>
        <div className="list-group-item">
          <FilterList
            label={"Search Checkout Items"}
            name={"search"}
            type={"search"}
            searchFields={["name", "description", "checkout_id"]}
            onFinish={this.filterUpdated.bind(this)}
          />
        </div>
        <div className="list-group-item">
          <p>Select a type</p>
          <FilterList
            filterList={this.state.types}
            name={"searchType"}
            type={"single"}
            searchFields={["order_type"]}
            onFinish={this.filterUpdated.bind(this)}
          />
        </div>

        <div className="container-fluid">
          <div className="gap20" />
          <p>
            Total Checkout Items : <b>{this.state.totalCheckoutItems}</b>
          </p>
          <p>
            Current Page: <b>{this.state.page + 1}</b>
          </p>
          <div id="react-paginate">
            <ReactPaginate
              previousLabel={<span aria-hidden="true">&laquo;</span>}
              nextLabel={<span aria-hidden="true">&raquo;</span>}
              pageCount={this.state.pages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={10}
              onPageChange={this.handlePageClick}
              breakClassName="page-item"
              breakLinkClassName="page-link"
              pageClassName="page-item"
              previousClassName="page-item"
              nextClassName="page-item"
              pageLinkClassName="page-link"
              previousLinkClassName="page-link"
              nextLinkClassName="page-link"
              activeClassName="page-item active"
              disableInitialCallback={true}
              initialPage={0}
            />
          </div>
        </div>
      </>
    );
  }

  onClickScriptSubmit = (data) => {
    /*eslint-disable*/
    let updateData = {
      script_data: data,
    };
    /*eslint-enable*/
    callUpdateCheckoutItem(this.state.selectedItem._id, updateData).then(
      (d) => {
        this.getCheckoutItems();
      }
    );
  };

  render() {
    return (
      <div>
        {this.renderInfoModal()}
        {this.renderTagInfoModal()}
        {this.state.showScript && (
          <ScriptPopup
            script={this.state.scriptData}
            onSubmit={this.onClickScriptSubmit}
            type="checkout"
          />
        )}
        <div className="container-fluid">
          <TitleBox title="Archived Checkout Item Overview" />
          <div className="gap20" />
          <div className="card">
            <div className="list-group list-group-flush">
              {this.renderFilterView()}
              <div className="list-group-item">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>ID</th>
                      <th>Type</th>
                      <th>Description</th>
                      <th>Price</th>
                      <th>Tags</th>
                      <th>Active</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>{this.renderCheckoutItems()}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  checkoutItems: state.billingReducer.checkoutItems,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewArchivedCheckoutItem);
