import {
  CREATE_STRIPE_PLAN_SUCCESS,
  CREATE_STRIPE_PLAN_FAILURE,
  GET_STRIPE_PLAN,
  RESET_STATUS,
  CREATE_CUSTOMER_ORDER_SUCCESS,
  CREATE_CUSTOMER_ORDER_FAILURE,
  CREATE_CHECKOUT_ITEM_SUCCESS,
  CREATE_CHECKOUT_ITEM_FAILURE,
  GET_CHECKOUT_ITEM_SUCCESS,
  CREATE_PROMO_CODE_SUCCESS,
  CREATE_PROMO_CODE_FAILURE,
  GET_PROMO_CODE_SUCCESS,
  CREATE_UPSELL_ITEM_SUCCESS,
  CREATE_UPSELL_ITEM_FAILURE,
  GET_UPSELL_ITEM_SUCCESS,
  CREATE_FUNNEL_PATH_SUCCESS,
  CREATE_FUNNEL_PATH_FAILURE,
  GET_FUNNEL_PATH_SUCCESS,
  CREATE_MEMBER_PAYMENT_SUCCESS,
  CREATE_MEMBER_PAYMENT_FAILURE,
  GET_MEMBER_PAYMENT_SUCCESS,
  UPDATE_UPSELL_ITEM_SUCCESS,
  UPDATE_UPSELL_ITEM_FAILURE,
  UPDATE_CHECKOUT_ITEM_SUCCESS,
  UPDATE_CHECKOUT_ITEM_FAILURE,
  UPDATE_FUNNEL_PATH_SUCCESS,
  UPDATE_FUNNEL_PATH_FAILURE
} from "../types";

import config from "../../helpers/config";

import {
  callCreateStripePlan,
  callGetStripePlan,
  callCreateCustomerOrder,
  callCreateCheckoutItem,
  callGetCheckoutItems,
  callCreatePromoCode,
  callGetPromoCodes,
  callCreateUpsellItem,
  callGetUpsellItems,
  callCreateFunnelPath,
  callGetFunnelPaths,
  callCreateMemberPayment,
  callGetMemberPayments,
  callUpdateUpsellItem,
  callUpdateCheckoutItem,
  callUpdateFunnelPath
} from "../../services/index";

/*
  This method is used to reset the status
  This will reset message and success states
*/
export const resetStatus = data => {
  return async dispatch => {
    dispatch({
      type: RESET_STATUS
    });
  };
};

export const createStripePlan = data => {
  return async dispatch => {
    return callCreateStripePlan(data)
      .then(data => {
        dispatch({
          type: CREATE_STRIPE_PLAN_SUCCESS,
          message: config.messages.createChlgQueSuccess,
          success: true
        });
      })
      .catch(err => {
        dispatch({
          type: CREATE_STRIPE_PLAN_FAILURE,
          message: config.messages.createChlgQueError,
          success: false
        });
      });
  };
};

export const getStripePlans = () => {
  return async dispatch => {
    return callGetStripePlan().then(data => {
      dispatch({
        type: GET_STRIPE_PLAN,
        data: data
      });
    });
  };
};

export const createCustomerOrder = data => {
  return async dispatch => {
    return callCreateCustomerOrder(data)
      .then(data => {
        dispatch({
          type: CREATE_CUSTOMER_ORDER_SUCCESS,
          message: config.messages.createChlgQueSuccess,
          success: true
        });
      })
      .catch(err => {
        dispatch({
          type: CREATE_CUSTOMER_ORDER_FAILURE,
          message: config.messages.createChlgQueError,
          success: false
        });
      });
  };
};

export const createCheckoutItem = data => {
  return async dispatch => {
    return callCreateCheckoutItem(data)
      .then(data => {
        dispatch({
          type: CREATE_CHECKOUT_ITEM_SUCCESS,
          message: config.messages.createChlgQueSuccess,
          success: true
        });
      })
      .catch(err => {
        dispatch({
          type: CREATE_CHECKOUT_ITEM_FAILURE,
          message: config.messages.createChlgQueError,
          success: false
        });
      });
  };
};

export const updateCheckoutItem = (id, data) => {
  return async dispatch => {
    return callUpdateCheckoutItem(id, data)
      .then(data => {
        dispatch({
          type: UPDATE_CHECKOUT_ITEM_SUCCESS,
          message: config.messages.updateMessage,
          success: true
        });
      })
      .catch(err => {
        dispatch({
          type: UPDATE_CHECKOUT_ITEM_FAILURE,
          message: config.messages.createChlgQueError,
          success: false
        });
      });
  };
};

export const getCheckoutItems = () => {
  return async dispatch => {
    return callGetCheckoutItems().then(data => {
      dispatch({
        type: GET_CHECKOUT_ITEM_SUCCESS,
        data: data
      });
    });
  };
};

export const createPromoCode = data => {
  return async dispatch => {
    return callCreatePromoCode(data)
      .then(data => {
        dispatch({
          type: CREATE_PROMO_CODE_SUCCESS,
          message: config.messages.createChlgQueSuccess,
          success: true
        });
      })
      .catch(err => {
        dispatch({
          type: CREATE_PROMO_CODE_FAILURE,
          message: config.messages.createChlgQueError,
          success: false
        });
      });
  };
};

export const getPromoCodes = () => {
  return async dispatch => {
    return callGetPromoCodes().then(data => {
      dispatch({
        type: GET_PROMO_CODE_SUCCESS,
        data: data
      });
    });
  };
};

export const createUpsellItem = data => {
  return async dispatch => {
    return callCreateUpsellItem(data)
      .then(data => {
        dispatch({
          type: CREATE_UPSELL_ITEM_SUCCESS,
          message: config.messages.createChlgQueSuccess,
          success: true
        });
      })
      .catch(err => {
        dispatch({
          type: CREATE_UPSELL_ITEM_FAILURE,
          message: config.messages.createChlgQueError,
          success: false
        });
      });
  };
};

export const updateUpsellItem = (id, data) => {
  return async dispatch => {
    return callUpdateUpsellItem(id, data)
      .then(data => {
        dispatch({
          type: UPDATE_UPSELL_ITEM_SUCCESS,
          message: config.messages.updateMessage,
          success: true
        });
      })
      .catch(err => {
        dispatch({
          type: UPDATE_UPSELL_ITEM_FAILURE,
          message: config.messages.createChlgQueError,
          success: false
        });
      });
  };
};

export const getUpsellItems = () => {
  return async dispatch => {
    return callGetUpsellItems().then(data => {
      dispatch({
        type: GET_UPSELL_ITEM_SUCCESS,
        data: data
      });
    });
  };
};

export const createFunnelPath = data => {
  return async dispatch => {
    return callCreateFunnelPath(data)
      .then(data => {
        dispatch({
          type: CREATE_FUNNEL_PATH_SUCCESS,
          message: config.messages.createChlgQueSuccess,
          success: true
        });
      })
      .catch(err => {
        dispatch({
          type: CREATE_FUNNEL_PATH_FAILURE,
          message: config.messages.createChlgQueError,
          success: false
        });
      });
  };
};

export const updateFunnelPath = (id, data) => {
  return async dispatch => {
    return callUpdateFunnelPath(id, data)
      .then(data => {
        dispatch({
          type: UPDATE_FUNNEL_PATH_SUCCESS,
          message: config.messages.updateMessage,
          success: true
        });
      })
      .catch(err => {
        dispatch({
          type: UPDATE_FUNNEL_PATH_FAILURE,
          message: config.messages.createCshlgQueError,
          success: false
        });
      });
  };
};

export const getFunnelPaths = () => {
  return async dispatch => {
    return callGetFunnelPaths().then(data => {
      dispatch({
        type: GET_FUNNEL_PATH_SUCCESS,
        data: data
      });
    });
  };
};

export const createMemberPayment = data => {
  return async dispatch => {
    return callCreateMemberPayment(data)
      .then(data => {
        dispatch({
          type: CREATE_MEMBER_PAYMENT_SUCCESS,
          message: config.messages.createChlgQueSuccess,
          success: true
        });
      })
      .catch(err => {
        dispatch({
          type: CREATE_MEMBER_PAYMENT_FAILURE,
          message: config.messages.createChlgQueError,
          success: false
        });
      });
  };
};

export const getMemberPayments = () => {
  return async dispatch => {
    return callGetMemberPayments().then(data => {
      dispatch({
        type: GET_MEMBER_PAYMENT_SUCCESS,
        data: data
      });
    });
  };
};
