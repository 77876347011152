import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  callDeleteCfSubmission,
  callGetPageCfSubmissiobsByQuery,
  callUpdateUser,
} from "../../../services";
import TitleBox from "../../../components/general/titleBox";
import ReactPaginate from "react-paginate";
import moment from "moment";
import $ from "jquery";
import { getItem } from "../../../helpers/storage";
import { getUniqueId, imageUrl, showNotification } from "../../../helpers";
import Scrollbars from "react-custom-scrollbars";
import { saveAs } from "file-saver";

class ViewSubmissionsPage extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      submissions: [],
      /*eslint-disable*/
      sQuery: { tag_list: [] },
      /*eslint-enable*/
      page: 0,
      pages: 0,
      totalSubmissions: 0,
      selectedTag: {},
      selectedUser: {},
      attName: "",
      attValue: "",
      attId: "",
      attrRefresh: Math.random(),
      showUrl: {},
      pageType: this.props.type || "page",
    };
  }

  componentDidMount() {
    this.getSubmissions();
  }

  async getSubmissions() {
    let limit = 10;
    let sQuery = this.state.sQuery;
    let query = {
      query: {
        page: this.state.page,
        limit: limit,
        $sort: { createdAt: -1 },
      },
    };

    if (this.props.landingPageId) {
      query.query["form_data.landingPageId"] = this.props.landingPageId;
    }

    if (sQuery.tag_list !== "all") {
      query.query["tag_list"] = { $in: [sQuery.tag_list] };
    }
    let data = await callGetPageCfSubmissiobsByQuery(query);
    this.setState({
      submissions: data.data,
      totalSubmissions: data.total,
      pages: Math.ceil(data.total / limit),
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    if (name === "notes") {
      let selectedUser = this.state.selectedUser;
      selectedUser.notes = value;
      this.setState({ selectedUser });
    }

    this.setState({
      [name]: value,
    });
  }

  handlePageClick = (data) => {
    let page = data.selected;
    this.setState({ page: page }, () => {
      this.getSubmissions();
    });
  };

  renderFormData(data) {
    let retVals = [];
    for (let key in data) {
      if (
        key === "tags" ||
        key === "saveFormSubmission" ||
        key === "staffUserId" ||
        key === "createCustomer" ||
        key === "mpUserId" ||
        key === "landingPageId" ||
        key === "redirect" ||
        key.indexOf("file") > -1
      ) {
        continue;
      }
      let value = data[key];

      if (value) {
        retVals.push(
          <div>
            <label className="firstLetter">
              <b>{key}</b>
            </label>{" "}
            : <label>{value}</label>
          </div>
        );
      }
    }
    return retVals;
  }

  onClickDeleteCfSubmission = (cfSubmission) => {
    this.setState({ selectedItem: cfSubmission }, () => {
      $(".modalAlertOverlay.deleteOverlay,.modalAlert.deleteAlert").fadeIn(200);
    });
  };

  onClickTagInfoModal(tag, element) {
    if (tag && element.member_info[0]) {
      let tagList = element.member_info[0].tag_list || [];
      let fTags = tagList.filter((d) => d.id === tag._id);
      fTags.sort((a, b) => new Date(b.created) - new Date(a.created));
      if (fTags.length > 0) {
        this.setState(
          { selectedTag: fTags[0], selectedUser: element.member_info[0] },
          () => {
            $(
              ".modalAlertOverlay.tagUpdateOverlay,.modalAlert.tagUpdateAlert"
            ).show();
          }
        );
      }
    }
  }

  onPressCreateCustomValue() {
    let tag = this.state.selectedTag;
    let attributes = tag.attributes || [];
    if (this.state.attName.trim().length === 0) {
      showNotification("error", "Attribute name is required");
      return;
    }
    if (this.state.attValue.trim().length === 0) {
      showNotification("error", "Attribute value is required");
      return;
    }
    if (this.state.attId) {
      attributes.forEach((element) => {
        if (element.id === this.state.attId) {
          element.name = this.state.attName;
          element.value = this.state.attValue;
        }
      });
    } else {
      attributes.push({
        name: this.state.attName,
        value: this.state.attValue,
        id: getUniqueId(),
      });
    }

    tag.attributes = attributes;
    this.setState(
      { selectedTag: tag, attName: "", attValue: "", attId: "" },
      () => {
        this.onClickTagUpdateYesAlert();
      }
    );
  }

  onClickDeleteCustomValue(i) {
    let tag = this.state.selectedTag;
    let attributes = tag.attributes || [];
    attributes = attributes.filter((d) => d.id !== i.id);
    tag.attributes = attributes;
    this.setState({ selectedTag: tag, attrRefresh: Math.random() });
  }

  onClickEditCustomValue(i) {
    let tag = this.state.selectedTag;
    let attributes = tag.attributes || [];
    let attInfo = attributes.filter((d) => d.id === i.id)[0];
    this.setState({
      attName: attInfo.name,
      attValue: attInfo.value,
      attId: attInfo.id,
    });
  }

  renderTagUpdateAlert() {
    let attributes = this.state.selectedTag.attributes || [];
    let modalHeight = window.innerHeight - 250;

    return (
      <div>
        <div className="modalAlertOverlay tagUpdateOverlay" />
        <div className="modalAlert tagUpdateAlert">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Update Tag Attributes - {this.state.selectedTag.title}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.onClickTagUpdateNoAlert.bind(this)}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="container-fluid">
            <Scrollbars
              autoHide
              autoHideTimeout={1000}
              autoHideDuration={200}
              style={{ height: modalHeight }}>
              <div className="gap20" />
              <div className="card">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-6">
                      <div
                        className={
                          this.state.attName !== ""
                            ? "mdInput mdFocussed"
                            : "mdInput"
                        }>
                        <label>Name</label>
                        <input
                          type="text"
                          name="attName"
                          onChange={this.handleInputChange.bind(this)}
                          value={this.state.attName}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div
                        className={
                          this.state.attValue !== ""
                            ? "mdInput mdFocussed"
                            : "mdInput"
                        }>
                        <label>Value</label>
                        <input
                          type="text"
                          name="attValue"
                          onChange={this.handleInputChange.bind(this)}
                          value={this.state.attValue}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="gap20" />
                  <button
                    className="btn btn-primary"
                    onClick={() => this.onPressCreateCustomValue()}>
                    {this.state.attId
                      ? "Update Custom Value"
                      : "Create Custom Value"}
                  </button>
                  <div className="gap10" />
                  {attributes.length > 0 && (
                    <div key={this.state.attrRefresh}>
                      <div className="gap20" />
                      <label>Added Custom Values</label>
                      <div className="gap20" />
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Value</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {attributes.map((i) => (
                            <tr key={i.name}>
                              <td>{i.name}</td>
                              <td>{i.value}</td>

                              <td className="min-width-100">
                                <button
                                  className="btn btn-default btn-sm border-0 margin-right-10"
                                  onClick={this.onClickEditCustomValue.bind(
                                    this,
                                    i
                                  )}>
                                  <i className="fa fa-pencil" />
                                </button>
                                <button
                                  className="btn btn-danger btn-sm border-0"
                                  onClick={this.onClickDeleteCustomValue.bind(
                                    this,
                                    i
                                  )}>
                                  <i className="fa fa-trash" />
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </Scrollbars>
          </div>
        </div>
      </div>
    );
  }

  onClickTagUpdateNoAlert = () => {
    $(".modalAlertOverlay.tagUpdateOverlay,.modalAlert.tagUpdateAlert").hide();
  };

  onClickTagUpdateYesAlert = async () => {
    let tags = this.state.selectedUser.tag_list || [];
    let newTags = [];
    tags.forEach((element) => {
      if (element.member_tag_id === this.state.selectedTag.member_tag_id) {
        if (element.modification === undefined) {
          element = { ...element, modification: [] };
        }

        var object = {
          modifiedBy: this.state.selectedTag.member_tag_id,
          originalDate: element.created,
          created: new Date(),
        };

        element.attributes = this.state.selectedTag.attributes;
        element.modification.push(object);
      }
      newTags.push(element);
    });

    /*eslint-disable*/
    await callUpdateUser(this.state.selectedUser._id, { tag_list: newTags });
    showNotification("success", "Tag Updated Successfully", 4000);
    /*eslint-enable*/
    $(".modalAlertOverlay.tagUpdateOverlay,.modalAlert.tagUpdateAlert").hide();
  };

  renderTags(element) {
    let returnVals = [];
    if (element.ftags) {
      element.ftags.forEach((tag) => {
        returnVals.push(
          <div>
            <label style={{ marginRight: 10 }}>{tag.name}</label>
            <sup className="pointer-click">
              <i
                className="fa fa-book"
                onClick={() => {
                  this.onClickTagInfoModal(tag, element);
                }}
              />
            </sup>
          </div>
        );
      });
    }
    return returnVals;
  }

  onClickFileUrl(element) {
    saveAs(imageUrl(element), element);
  }

  renderFiles(item) {
    let returnVals = [];
    let keys = Object.keys(item.form_data);
    for (let i = 0; i < keys.length; i++) {
      const element = keys[i];
      if (element.indexOf("file") > -1) {
        returnVals.push(
          <label
            className="link pointer-click margin-right-10"
            onClick={this.onClickFileUrl.bind(this, item.form_data[element])}>
            <u>{item.form_data[element]}</u>
          </label>
        );
      }
    }
    return returnVals;
  }

  renderSubmissions() {
    let submissions = this.state.submissions;
    let accountKey = getItem("sk");

    let returnVals = [];
    for (let i = 0; i < submissions.length; i++) {
      const element = submissions[i];

      returnVals.push(
        <div className="card">
          <div className="container-fluid">
            <div className="gap10" />
            <div className="pull-right">
              <button
                onClick={this.onClickDeleteCfSubmission.bind(this, element)}
                className="btn btn-danger btn-sm margin-right-10 border-0">
                <i className="fa fa-trash" />
              </button>
            </div>
            {this.renderFormData(element.form_data)}
            <label>
              <b>Submission Date</b>
            </label>{" "}
            :{" "}
            <label>
              {moment(element.createdAt).format("Do MMM YYYY hh:mm A")}
            </label>
            {element.member_info && element.member_info[0] && (
              <>
                <br />
                <label>
                  <b>Name</b>
                </label>{" "}
                : <label>{element.member_info[0].name}</label>
                <br />
                <label>
                  <b>Email</b>
                </label>{" "}
                : <label>{element.member_info[0].email_address}</label>
              </>
            )}
            {element.landing_page_info && element.landing_page_info[0] && (
              <>
                <br />
                <label>
                  <b>Landing Page</b>
                </label>{" "}
                :{" "}
                <label>
                  <a
                    href={`https://site.memberpages.app/${element.landing_page_info[0].url}?key=${accountKey}`}
                    target="_blank"
                    rel="noopener noreferrer">
                    {element.landing_page_info[0].name}
                  </a>
                </label>
              </>
            )}
            <br></br>
            {element.ftags && element.ftags.length > 0 && (
              <>
                <label>
                  <b>Tags</b>
                </label>{" "}
                : {this.renderTags(element)}
              </>
            )}
            {element.form_data.redirect && (
              <>
                <label>
                  <b>Redirect</b>
                </label>{" "}
                :{"  "}
                {this.state.showUrl[element._id] ? (
                  <label>{element.form_data.redirect}</label>
                ) : (
                  <button
                    className="btn btn-link shadow-none"
                    onClick={() => {
                      let showUrl = this.state.showUrl;
                      showUrl[element._id] = true;
                      this.setState({ showUrl });
                    }}
                    style={{ padding: 0, margin: 0 }}>
                    Show URL
                  </button>
                )}
              </>
            )}
            {this.renderFiles(element)}
          </div>
        </div>
      );
    }
    return returnVals;
  }

  onClickNoAlert = () => {
    $(".modalAlertOverlay.deleteOverlay,.modalAlert.deleteAlert").fadeOut(200);
  };

  onClickYesAlert = () => {
    callDeleteCfSubmission(this.state.selectedItem._id).then((d) => {
      $(".modalAlertOverlay.deleteOverlay,.modalAlert.deleteAlert").fadeOut(
        200
      );
      this.getSubmissions();
    });
  };

  renderConfirmationAlert() {
    return (
      <div>
        <div className="modalAlertOverlay deleteOverlay" />
        <div className="modalAlert deleteAlert">
          <p>Are you sure you want to delete this submission? </p>
          <div className="alertFooterBtns">
            <button
              className="btn btn-default closeModal-No margin-right-10"
              onClick={this.onClickNoAlert}>
              No
            </button>
            <button
              className="btn btn-primary closeModal-Yes"
              onClick={this.onClickYesAlert}>
              Yes
            </button>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        <div className="container-fluid">
          {this.renderConfirmationAlert()}
          {this.renderTagUpdateAlert()}
          {this.state.pageType === "page" && <TitleBox title="Submissions" />}
          <div className="gap20" />
          <div className="card">
            <div className="container-fluid">
              <div className="gap20" />
              <p>
                Total Submissions : <b>{this.state.totalSubmissions}</b>
              </p>
              <p>
                Current Page: <b>{this.state.page + 1}</b>
              </p>
              <div id="react-paginate">
                <ReactPaginate
                  previousLabel={<span aria-hidden="true">&laquo;</span>}
                  nextLabel={<span aria-hidden="true">&raquo;</span>}
                  pageCount={this.state.pages}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={10}
                  onPageChange={this.handlePageClick}
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  pageClassName="page-item"
                  previousClassName="page-item"
                  nextClassName="page-item"
                  pageLinkClassName="page-link"
                  previousLinkClassName="page-link"
                  nextLinkClassName="page-link"
                  activeClassName="page-item active"
                  disableInitialCallback={true}
                  initialPage={0}
                />
              </div>
            </div>
          </div>
          {this.state.totalSubmissions > 0 && (
            <div className="card">
              <div className="container-fluid">
                <div className="gap20" />
                {this.renderSubmissions()}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewSubmissionsPage);
