import React, { Component } from "react";
import PropTypes from "prop-types";
import { EditorState, Modifier } from "draft-js";

class DraftCustomOption extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    editorState: PropTypes.object,
  };

  state = {
    open: false,
  };

  openPlaceholderDropdown = () => this.setState({ open: !this.state.open });

  addPlaceholder = (placeholder) => {
    const { editorState, onChange } = this.props;
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      placeholder,
      editorState.getCurrentInlineStyle()
    );
    onChange(EditorState.push(editorState, contentState, "insert-characters"));
  };

  placeholderOptions = [
    { key: "firstName", value: "{{first_name}}", text: "First Name" },
    { key: "lastName", value: "{{last_name}}", text: "Last Name" },
    { key: "emailAddress", value: "{{email_address}}", text: "Email Address " },
    { key: "password", value: "{{initial_password}}", text: "Password" },
    { key: "loginUrl", value: "{{login_url}}", text: "Login URL" },
    { key: "url", value: "{{url}}", text: "URL" },
    {
      key: "resetPasswordLink",
      value: "{{reset_password_link}}",
      text: "Reset Password Link",
    },
    {
      key: "unsubscribeEmailLink",
      value: "{{unsubscribe_link}}",
      text: "Unsubscribe Email Link",
    },
    {
      key: "currentYear",
      value: "{{current_year}}",
      text: "Current Year",
    },
  ];

  listItem = this.placeholderOptions.map((item) => (
    <li
      onClick={this.addPlaceholder.bind(this, item.value)}
      key={item.key}
      className="rdw-dropdownoption-default placeholder-li">
      {item.text}
    </li>
  ));

  render() {
    return (
      <div
        onClick={this.openPlaceholderDropdown}
        className="rdw-block-wrapper"
        aria-label="rdw-block-control">
        <div
          className="rdw-dropdown-wrapper rdw-block-dropdown"
          aria-label="rdw-dropdown">
          <div className="rdw-dropdown-selectedtext" title="Placeholders">
            <span style={{ fontSize: 12 }}>Custom Fields</span>
            <div
              className={`rdw-dropdown-caretto${
                this.state.open ? "close" : "open"
              }`}></div>
          </div>
          <ul
            className={`rdw-dropdown-optionwrapper ${
              this.state.open ? "" : "placeholder-ul"
            }`}>
            {this.listItem}
          </ul>
        </div>
      </div>
    );
  }
}

export default DraftCustomOption;
