import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import TitleBox from "../../../../components/general/titleBox";
import { callGetSettings } from "../../../../services/settingService";
import { callGetUsersByQuery } from "../../../../services";
import { showNotification } from "../../../../helpers";

class CustomAdminFieldReport extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      fields: [],
      fieldValues: [],
      selectedField: "",
      selectedValue: "",
      users: [],
    };
  }

  componentDidMount() {
    this.getCustomFields();
  }

  getCustomFields() {
    callGetSettings("customfields").then((d) => {
      let fields = d.data.custom_fields || [];

      this.setState({
        fields: fields,
      });
    });
  }

  handleInputChange(event) {
    const target = event.target;
    var value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    let fieldValues = this.state.fieldValues;

    if (name === "selectedField") {
      fieldValues = this.state.fields
        .find((d) => d.id === value)
        .value.split("\n");
    }

    this.setState({
      [name]: value,
      fieldValues: fieldValues,
    });
  }

  async onClickGetUsers() {
    let key = `custom_admin_fields.${this.state.selectedField}`;
    let query = {
      query: {
        [key]: this.state.selectedValue,
        $select: ["name", "email_address"],
      },
    };
    var response = await callGetUsersByQuery(query);
    if (response.data.length > 0) {
      this.setState({ users: response.data });
    } else {
      showNotification("info", "No users found with selected fields");
    }
  }

  renderUsers() {
    var returnVals = [];
    let users = this.state.users;
    users.forEach((element) => {
      returnVals.push(
        <tr key={element._id}>
          <td>{element.name}</td>
          <td>{element.email_address}</td>
        </tr>
      );
    });
    return returnVals;
  }

  render() {
    return (
      <div className="container-fluid">
        <TitleBox title="Custom Admin Fields" showBackBtn={true} />
        <div className="gap20" />
        <div className="card">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-4">
                <div
                  className={
                    this.state.selectedField !== "" &&
                    this.state.selectedField !== null
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Select Field</label>
                  <select
                    name={"selectedField"}
                    value={this.state.selectedField}
                    onChange={(event) => this.handleInputChange(event)}>
                    <option value=""></option>
                    {this.state.fields.map((i) => (
                      <option value={i.id}>{i.name}</option>
                    ))}
                  </select>
                </div>
              </div>
              {this.state.selectedField.length > 0 && (
                <>
                  <div className="col-sm-4">
                    <div
                      className={
                        this.state.selectedValue !== "" &&
                        this.state.selectedValue !== null
                          ? "mdInput mdFocussed"
                          : "mdInput"
                      }>
                      <label>Select Value</label>
                      <select
                        name={"selectedValue"}
                        value={this.state.selectedValue}
                        onChange={(event) => this.handleInputChange(event)}>
                        <option value=""></option>
                        {this.state.fieldValues.map((i) => (
                          <option value={i}>{i}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="gap30"></div>
                    <button
                      className="btn btn-primary"
                      onClick={this.onClickGetUsers.bind(this)}>
                      Submit
                    </button>
                  </div>
                </>
              )}
            </div>
            <div className="gap20" />
          </div>
        </div>

        {this.state.users.length > 0 && (
          <div className="card">
            <div className="container-fluid">
              <div className="gap20" />
              <div class="table-responsive">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                    </tr>
                  </thead>
                  <tbody>{this.renderUsers()}</tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomAdminFieldReport);
