import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import TitleBox from "../../../../components/general/titleBox";
import { callGetUsersByQuery } from "../../../../services";

class AffiliateListReport extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      parentUsers: {},
      allUsers: [],
    };
  }

  componentDidMount() {
    this.getAffiliates();
  }

  async getAffiliates() {
    /*eslint-disable*/
    let usersData = await callGetUsersByQuery({
      query: {
        is_affiliate: true,
        $select: ["_id", "name", "email_address", "affiliate_details"],
      },
    });
    /*eslint-enable*/
    let users = usersData.data;
    let parentUser = { noParents: [] };
    for (let i = 0; i < users.length; i++) {
      let userDetails = users[i];
      let affiliateDetails = userDetails.affiliate_details;
      if (userDetails && affiliateDetails && affiliateDetails.affiliate_code) {
        let parentId = affiliateDetails.parent_affiliate_id;
        let userDetail = {
          name: userDetails.name,
          email: userDetails.email_address,
        };
        if (parentId) {
          if (!parentUser[parentId]) {
            parentUser[parentId] = [];
          }
          parentUser[parentId].push(userDetail);
        } else {
          parentUser["noParents"].push(userDetail);
        }
      }
    }
    this.setState({ parentUsers: parentUser, allUsers: users });
  }

  getParentUserInfo(id) {
    if (id === "noParents") {
      return "No Parents";
    }
    let user = this.state.allUsers.find((d) => d._id === id);
    let userName = "";
    if (user) {
      userName = user.name;
    }
    return userName;
  }

  getChildInfo(name, key, parentUsers) {
    var users = parentUsers[key] || [];
    return (
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>{name}</th>
          </tr>
        </thead>
        <tbody>
          {users.length > 0 && (
            <div className="container-fluid">
              <div className="gap20" />
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email Address</th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((i) => (
                    <tr>
                      <td>{i.name}</td>
                      <td>{i.email}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </tbody>
      </table>
    );
  }

  renderAffliates() {
    let parentUsers = this.state.parentUsers;
    let userIds = Object.keys(parentUsers);

    let returnVals = [];
    for (let i = 0; i < userIds.length; i++) {
      const element = userIds[i];
      if (element === "noParents") {
        continue;
      }
      const name = this.getParentUserInfo(element);
      returnVals.push(this.getChildInfo(name, element, parentUsers));
    }

    returnVals.push(this.getChildInfo("No Parents", "noParents", parentUsers));
    return returnVals;
  }

  render() {
    return (
      <div className="container-fluid">
        <TitleBox title="Affiliate List Report" showBackBtn={true} />
        <div className="gap20" />
        <div className="card">
          <div className="gap20" />
          <div className="container-fluid">{this.renderAffliates()}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AffiliateListReport);
