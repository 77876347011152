import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import DatePicker from "react-date-picker";
import * as $ from "jquery";
import moment from "moment";

import RadioTag from "../../../components/form/radioTag";
import TitleBox from "../../../components/general/titleBox";
import {
  callGetReports,
  callGetPromotions,
  callGetChlgsByQuery,
} from "../../../services";
import { isFS, isDashboardSite } from "../../../helpers";
import Onboarding from "../onboarding/onboarding";
import { getItem } from "../../../helpers/storage";
import { reportRoles } from "../../../helpers/routes";

const ReportRow = (props) => {
  return (
    <div className="listWithRight">
      <p className="noMargin firstLetter">{props.label}</p>
      <div className="rightElem">
        <span className={`taskStatus ${props.class}`}>{props.value}</span>
      </div>
    </div>
  );
};

class Reports extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      chlgs: [],
      keyOption: Math.random(),
      keyPromotionOption: Math.random(),
      challengeStartDate: new Date(),
      keyFilterDate: Math.random(),
      startFilterDate: new Date(),
      endFilterDate: new Date(),
      selectedChallenge: "",
      reportType: "",
      emailStats: {
        sent: 0,
        open: 0,
        click: 0,
      },
      mcUsers: {
        active: 0,
        cancel: 0,
      },
      amountCollected: {
        total: 0,
        refunds: 0,
        totalMinusRefunds: 0,
        customerValue: 0,
      },
      tagActionCounts: [],
      mcUsersTracked: [],
      challengeUsersTracked: [],
      activeChallengeUsers: [],
      dateFilters: [
        { name: "Today", value: "today" },
        { name: "Yesterday", value: "yesterday" },
        { name: "This Week", value: "week" },
        { name: "This Month", value: "month" },
        { name: "This Year", value: "year" },
        { name: "Custom", value: "custom" },
      ],
      selectedDateFilter: "",
      selectedCustomDates: "",
      selectedPromotion: "all",
      promotions: [],
      selectedActiveChallenge: "",
      selectedActiveStartDate: new Date(),
      emailInterval: "3",
      emailCount: "3",
      ignoredEmailUsers: [],
      reportRoles: [],
      smsHistories: [],
    };
  }

  componentWillMount() {
    this.getPromotions();
    this.callGetEmailStats({ filter: "today" });
    this.callGetSMSStats({ filter: "today" });
    this.callGetMCUsersStats();
    this.callGetTagActionCounts({ filter: "today" });
    this.callGetMCUsersTracked({ filter: "today" });
    this.callGetAmountCollectedCounts({ filter: "today", promotionId: "all" });
    this.getChallenges();
    this.callGetIgnoredEmails({ count: "3", interval: "3" });

    var userData = getItem("userData");
    if (userData) {
      let user = JSON.parse(userData);
      let isAdmin = user.master_roles;
      let reRoles = user.report_roles || [];
      if (isAdmin) {
        reRoles = reportRoles.map((i) => i.value);
      }
      this.setState({ reportRoles: reRoles });
    }
  }

  getPromotions() {
    let query = {
      query: { status: "active" },
    };

    callGetPromotions(query).then((d) => {
      let promotions = [];
      promotions.push({
        name: "All",
        value: "all",
      });
      d.data.forEach((element) => {
        promotions.push({
          name: element.name,
          value: element._id,
        });
      });
      this.setState({ promotions, keyPromotionOption: Math.random() });
    });
  }

  callGetEmailStats(data) {
    data["type"] = "email-stats";
    callGetReports(data).then((d) => {
      let emailStats = {
        sent: 0,
        open: 0,
        click: 0,
      };
      if (d.email_histories.length > 0) {
        emailStats.sent = d.email_histories[0].count;
      }
      d.email_actions.forEach((element) => {
        emailStats[element.action] = element.count;
      });
      this.setState({ emailStats: emailStats });
    });
  }

  callGetSMSStats(data) {
    data["type"] = "sms-stats";
    callGetReports(data).then((d) => {
      this.setState({ smsHistories: d.smsHistories });
    });
  }

  callGetMCUsersStats() {
    let data = {};
    data["type"] = "mc-users";
    data["action"] = "count";
    callGetReports(data).then((d) => {
      let mcUsers = {
        active: d.active ? d.active : 0,
        cancel: d.cancelled ? d.cancelled : 0,
      };
      this.setState({ mcUsers: mcUsers });
    });
  }

  callGetTagActionCounts(data) {
    data["type"] = "tag-action-stats";
    data["action"] = "counts";
    callGetReports(data).then((d) => {
      this.setState({ tagActionCounts: d });
    });
  }

  callGetMCUsersTracked(data) {
    data["type"] = "mc-most-tracked";
    callGetReports(data).then((d) => {
      this.setState({ mcUsersTracked: d });
    });
  }

  callGetChallengeUsersTracked(data) {
    data["type"] = "challenge-most-tracked";
    callGetReports(data).then((d) => {
      this.setState({ challengeUsersTracked: d });
    });
  }

  callGetActiveChallengeUsers(data) {
    data["type"] = "chlg-users-start-date";
    callGetReports(data).then((d) => {
      this.setState({ activeChallengeUsers: d });
    });
  }

  callGetIgnoredEmails(data) {
    data["type"] = "ignored-emails";
    callGetReports(data, true).then((d) => {
      this.setState({ ignoredEmailUsers: d });
    });
  }

  callGetAmountCollectedCounts(data) {
    data["type"] = "stripe-charge";
    data["action"] = "counts";
    callGetReports(data).then((d) => {
      let amountCollected = {
        total: 0,
        refunds: 0,
        totalMinusRefunds: 0,
        customerValue: 0,
      };
      if (d.length > 0) {
        d.forEach((element) => {
          let data = element;
          let total = data["totalAmount"]["$numberDecimal"] || "0";
          let refunds = data["totalRefund"]["$numberDecimal"] || "0";
          let totalMinusRefunds =
            data["totalMinusRefund"]["$numberDecimal"] || "0";

          amountCollected.total += total !== "0" ? parseFloat(total) / 100 : 0;
          amountCollected.refunds +=
            refunds !== "0" ? parseFloat(refunds) / 100 : 0;
          amountCollected.totalMinusRefunds +=
            totalMinusRefunds !== "0" ? parseFloat(totalMinusRefunds) / 100 : 0;
        });

        amountCollected.total = amountCollected.total.toFixed(2);
        amountCollected.refunds = amountCollected.refunds.toFixed(2);
        amountCollected.totalMinusRefunds =
          amountCollected.totalMinusRefunds.toFixed(2);

        if (amountCollected.total > 0) {
          amountCollected.customerValue = (
            amountCollected.total / d.length
          ).toFixed(2);
        }
      }
      let filter = data["filter"];
      let promotionId = data["promotionId"];
      let customDates = data["customDates"] || "";
      this.setState({
        selectedPromotion: promotionId,
        amountCollected: amountCollected,
        selectedDateFilter: filter,
        selectedCustomDates: customDates,
      });
    });
  }

  getReportsData(type, data) {
    switch (type) {
      case "email-stats":
        this.callGetEmailStats(data);
        break;
      case "sms-stats":
        this.callGetSMSStats(data);
        break;
      case "tag-action-stats":
        this.callGetTagActionCounts(data);
        break;
      case "mc-most-tracked":
        this.callGetMCUsersTracked(data);
        break;
      case "challenge-most-tracked":
        this.callGetChallengeUsersTracked(data);
        break;
      case "stripe-charge-counts":
        data["promotionId"] = this.state.selectedPromotion;
        this.callGetAmountCollectedCounts(data);
        break;
      default:
        break;
    }
  }

  onClickDateFilterReport(type, selectedList, name) {
    let filter = selectedList[Object.keys(selectedList)[0]].value;
    switch (filter) {
      case "today":
      case "yesterday":
      case "week":
      case "month":
      case "year":
        this.getReportsData(type, { filter: filter });
        break;
      case "custom":
        this.setState({ reportType: type }, () => {
          $(
            ".modalAlertOverlay.filterDateOverlay,.modalAlert.filterDateAlert"
          ).fadeIn(200);
          this.setState({ keyFilterDate: Math.random() });
        });
        break;

      default:
        break;
    }
  }

  onClickPromotionFilterReport(selectedList, name) {
    let filter = selectedList[Object.keys(selectedList)[0]].value;
    this.setState({ selectedPromotion: filter }, () => {
      let data = {};
      data["filter"] = this.state.selectedDateFilter;
      data["customDates"] = this.state.selectedCustomDates;
      data["promotionId"] = filter;
      this.callGetAmountCollectedCounts(data);
    });
  }

  onClickExportIgnoredUsers(tagName) {
    let users = this.state.ignoredEmailUsers;
    if (tagName === "active-users") {
      users = this.state.activeChallengeUsers.map((i) => i.member_info);
    }
    this.props.history.push("/admin/export-users", {
      users: users,
      tagName: tagName,
    });
  }

  renderDateFilter(type) {
    return (
      <div className="tabsHolder radioTabs">
        <RadioTag
          onCheckChange={this.onClickDateFilterReport.bind(this, type)}
          labelList={this.state.dateFilters}
          selectType={"value"}
          selectedList={["today"]}
          key={this.state.keyOption}
        />
      </div>
    );
  }

  renderPromotionFilter() {
    return (
      <div className="tabsHolder radioTabs">
        <RadioTag
          onCheckChange={this.onClickPromotionFilterReport.bind(this)}
          labelList={this.state.promotions}
          selectType={"value"}
          selectedList={["all"]}
          key={this.state.keyPromotionOption}
        />
      </div>
    );
  }

  onClickCloseFromFilterDateModal() {
    $(
      ".modalAlertOverlay.filterDateOverlay,.modalAlert.filterDateAlert"
    ).fadeOut(200);
  }

  onClickDoneFromFilterDateModal() {
    $(
      ".modalAlertOverlay.filterDateOverlay,.modalAlert.filterDateAlert"
    ).fadeOut(200);
    let customDates =
      moment(this.state.startFilterDate).startOf("day").format("YYYY-MM-DD") +
      "," +
      moment(this.state.endFilterDate).startOf("day").format("YYYY-MM-DD");

    let reportType = this.state.reportType;
    switch (reportType) {
      case "email-stats":
        this.callGetEmailStats({
          filter: "custom",
          customDates: customDates,
        });
        break;
      case "sms-stats":
        this.callGetSMSStats({
          filter: "custom",
          customDates: customDates,
        });
        break;
      case "tag-action-stats":
        this.callGetTagActionCounts({
          filter: "custom",
          customDates: customDates,
        });
        break;
      case "stripe-charge-counts":
        this.callGetAmountCollectedCounts({
          filter: "custom",
          customDates: customDates,
        });
        break;
      case "mc-most-tracked":
        this.callGetMCUsersTracked({
          filter: "custom",
          customDates: customDates,
        });
        break;
      default:
        break;
    }
  }

  onChangeFilterDate(name, date) {
    this.setState({
      [name]: date,
    });
  }

  onClickEmailViewReport() {
    this.props.history.push("/admin/reports/email-reports");
  }

  onClickAffiliateViewReport() {
    this.props.history.push("/admin/reports/affiliate-reports");
  }

  onClickAffiliateListReport() {
    this.props.history.push("/admin/reports/affiliate-list");
  }

  onClickBouncedEmails() {
    this.props.history.push("/admin/reports/bounced-emails");
  }

  onClickViewSubscriptions() {
    this.props.history.push("/admin/reports/view-subscriptions");
  }

  onClickViewCustomOrders() {
    this.props.history.push("/admin/reports/view-customer-orders");
  }

  onClickViewTagsReport() {
    this.props.history.push("/admin/reports/view-tags?t=reports");
  }

  onClickCustomAdminFieldReport() {
    this.props.history.push("/admin/reports/custom-admin-fields");
  }

  onClickUnsubscribedUsersReport() {
    this.props.history.push("/admin/reports/unsubscribed-users");
  }

  onClickAppleOrdersReport() {
    this.props.history.push("/admin/reports/apple-orders");
  }

  onClickCustomerRevenue() {
    this.props.history.push("/admin/reports/customer-revenue");
  }

  onClickZoomMeeting() {
    this.props.history.push("/admin/reports/zoom-meetings");
  }

  onClickPaymentHistoryReport() {
    this.props.history.push("/admin/reports/payments-history");
  }

  onClickUpcomingPaymentsReport() {
    this.props.history.push("/admin/reports/upcoming-payments");
  }

  onClickChallengeUsersReport() {
    this.props.history.push("/admin/reports/challenge-users");
  }

  onClickTagAttributesReport() {
    this.props.history.push("/admin/reports/tag-attributes");
  }

  onClickFailedPaymentsReport() {
    this.props.history.push("/admin/reports/failed-payments");
  }

  onClickMCViewReport() {
    this.props.history.push("/admin/reports/marci-circle-reports");
  }

  onClickGetIgnoredEmails() {
    let data = {};

    data.count = this.state.emailCount;
    data.interval = this.state.emailInterval;

    this.callGetIgnoredEmails(data);
  }

  async getChallenges() {
    let query = {
      query: {
        $select: ["_id", "name"],
      },
    };
    let data = await callGetChlgsByQuery(query);
    this.setState({ chlgs: data.data });
  }

  renderChallenges() {
    let returnVals = [];
    let challenges = this.state.chlgs;
    returnVals.push(<option value={""} key={0} />);
    for (let i = 0; i < challenges.length; i++) {
      returnVals.push(
        <option value={challenges[i]._id} key={i + 1}>
          {challenges[i].name}
        </option>
      );
    }
    return returnVals;
  }

  handleTextInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    let data = [];
    data.selectedChallenge = value;
    data.challengeStartDate = moment(this.state.challengeStartDate).format(
      "MM-DD-YYYY"
    );
    this.setState(
      {
        selectedChallenge: value,
      },
      () => {
        this.callGetChallengeUsersTracked(data);
      }
    );
  }

  onChangeChallengeStartDate(value) {
    let data = [];
    data.selectedChallenge = this.state.selectedChallenge;
    data.challengeStartDate = moment(value).format("MM-DD-YYYY");
    this.setState(
      {
        challengeStartDate: value,
      },
      () => {
        this.callGetChallengeUsersTracked(data);
      }
    );
  }

  handleActiveInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    let data = [];
    data.selectedChallenge = value;
    data.challengeStartDate = moment(this.state.selectedActiveStartDate).format(
      "MM-DD-YYYY"
    );
    this.setState(
      {
        selectedActiveChallenge: value,
      },
      () => {
        this.callGetActiveChallengeUsers(data);
      }
    );
  }

  onChangeActiveChallengeStartDate(value) {
    let data = [];
    data.selectedChallenge = this.state.selectedActiveChallenge;
    data.challengeStartDate = moment(value).format("MM-DD-YYYY");
    this.setState(
      {
        selectedActiveStartDate: value,
      },
      () => {
        this.callGetActiveChallengeUsers(data);
      }
    );
  }

  renderCustomDateModal() {
    return (
      <div>
        <div className="modalAlertOverlay filterDateOverlay" />
        <div className="modalAlert filterDateAlert">
          <p> Filter By Date </p>
          <div className="container-fluid">
            <div className="row">
              <div className="col-6">
                <label className="inputFakeLabelFocussed">
                  Select Start Date
                </label>
                <div>
                  <DatePicker
                    key={this.state.keyTrackingDate}
                    value={this.state.startFilterDate}
                    onChange={this.onChangeFilterDate.bind(
                      this,
                      "startFilterDate"
                    )}
                  />
                </div>
              </div>
              <div className="col-6">
                <label className="inputFakeLabelFocussed">
                  Select End Date
                </label>
                <div>
                  <DatePicker
                    key={this.state.keyTrackingDate}
                    value={this.state.endFilterDate}
                    onChange={this.onChangeFilterDate.bind(
                      this,
                      "endFilterDate"
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="alertFooterBtns">
            {/*eslint-disable*/}
            <a
              className="btn btn-default dismissThisModal margin-right-10"
              onClick={this.onClickCloseFromFilterDateModal.bind(this)}>
              Close
            </a>
            <a
              className="btn btn-primary dismissThisModal"
              onClick={this.onClickDoneFromFilterDateModal.bind(this)}>
              Done
            </a>
            {/*eslint-enable*/}
          </div>
        </div>
      </div>
    );
  }

  render() {
    let isFs = isFS();
    var reportRoles = this.state.reportRoles;
    return (
      <Onboarding history={this.props.history}>
        <div>
          {this.renderCustomDateModal()}
          <div className="container-fluid">
            <TitleBox title="Reports" />
            <div className="gap20" />
            {reportRoles.indexOf("email") > -1 && (
              <div className="row">
                <div className="col-lg-6">
                  <div className="card">
                    <div className="list-group list-group-flush">
                      <div className="list-group-item">
                        <h5 className="noMargin pull-left firstLetter">
                          Email
                        </h5>
                        <div className="pull-right">
                          <button
                            className="btn btn-default btn-sm"
                            onClick={this.onClickEmailViewReport.bind(this)}>
                            View Report
                          </button>
                        </div>
                      </div>
                      <div className="list-group-item">
                        {this.renderDateFilter("email-stats")}
                      </div>
                      <div className="list-group-item">
                        <div className="gap10" />
                        <div className="cardList">
                          <ReportRow
                            label="Sent"
                            class="taskGreen"
                            value={this.state.emailStats.sent}
                          />
                          <ReportRow
                            label="Open"
                            class="taskGreen"
                            value={this.state.emailStats.open}
                          />
                          <ReportRow
                            label="Click"
                            class="taskGreen"
                            value={this.state.emailStats.click}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="card">
                    <div className="list-group list-group-flush">
                      <div className="list-group-item">
                        <h5 className="noMargin pull-left firstLetter">
                          Email Tag Action Counts
                        </h5>
                      </div>
                      <div className="list-group-item">
                        {this.renderDateFilter("tag-action-stats")}
                      </div>
                      <div className="list-group-item">
                        <div className="gap10" />
                        <div className="cardList">
                          {this.state.tagActionCounts.map((i) => (
                            <ReportRow
                              label={i.status}
                              class="taskGreen"
                              value={i.count}
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="row">
              {reportRoles.indexOf("email") > -1 && (
                <div className="col-lg-6">
                  <div className="card">
                    <div className="list-group list-group-flush">
                      <div className="list-group-item">
                        <h5 className="noMargin pull-left firstLetter">
                          Ignored Emails
                          <div className="gap10" />
                          <p style={{ fontSize: 13, color: "gray" }}>
                            People who have not opened emails in the specified
                            time
                          </p>
                        </h5>
                      </div>

                      <div className="list-group-item">
                        <div className="gap10" />
                        <div className="row">
                          <div className="col-sm-5">
                            <div
                              className={
                                this.state.emailInterval !== ""
                                  ? "mdInput mdFocussed"
                                  : "mdInput"
                              }>
                              <label>Email Interval (Months)</label>
                              <input
                                type="number"
                                min="1"
                                name="emailInterval"
                                value={this.state.emailInterval}
                                onChange={(event) =>
                                  this.handleTextInputChange(event)
                                }
                              />
                            </div>
                          </div>
                          <div className="col-sm-5">
                            <div
                              className={
                                this.state.emailCount !== ""
                                  ? "mdInput mdFocussed"
                                  : "mdInput"
                              }>
                              <label>Email Count</label>
                              <input
                                type="number"
                                min="1"
                                name="emailCount"
                                value={this.state.emailCount}
                                onChange={(event) =>
                                  this.handleTextInputChange(event)
                                }
                              />
                            </div>
                          </div>
                          <div className="col-sm-2">
                            <div className="gap40" />
                            <button
                              className="btn btn-primary btn-sm"
                              onClick={this.onClickGetIgnoredEmails.bind(this)}>
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="list-group-item">
                        <h6 className="noMargin pull-left firstLetter">
                          Total : {this.state.ignoredEmailUsers.length}
                        </h6>

                        <div className="pull-right">
                          <button
                            className="btn btn-default btn-sm"
                            onClick={this.onClickExportIgnoredUsers.bind(
                              this,
                              "ignored-emails"
                            )}>
                            Export Users
                          </button>
                        </div>
                      </div>
                      <div className="list-group-item">
                        <div className="cardList">
                          <div className="gap10" />
                          {this.state.ignoredEmailUsers.map((i) => (
                            <ReportRow label={i.name} class="" value={""} />
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {reportRoles.indexOf("payment") > -1 && (
                <div className="col-lg-6">
                  <div className="card">
                    <div className="list-group list-group-flush">
                      <div className="list-group-item">
                        <h5 className="noMargin pull-left firstLetter">
                          Payment History
                        </h5>
                        <div className="pull-right">
                          <button
                            className="btn btn-default btn-sm"
                            onClick={this.onClickPaymentHistoryReport.bind(
                              this
                            )}>
                            View Report
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="card">
                  <div className="list-group list-group-flush">
                    <div className="list-group-item">
                      <h5 className="noMargin pull-left firstLetter">SMS</h5>
                    </div>
                    <div className="list-group-item">
                      {this.renderDateFilter("sms-stats")}
                    </div>
                    <div className="list-group-item">
                      <div className="gap10" />
                      <div className="cardList">
                        {this.state.smsHistories.map(
                          (e) =>
                            e._id && (
                              <ReportRow
                                label={e._id}
                                class="taskGreen"
                                value={e.count}
                              />
                            )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="card">
                  <div className="list-group list-group-flush">
                    <div className="list-group-item">
                      <h5 className="noMargin pull-left firstLetter">
                        Challenge Users
                      </h5>
                      <div className="pull-right">
                        <button
                          className="btn btn-default btn-sm"
                          onClick={this.onClickChallengeUsersReport.bind(this)}>
                          View Report
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="card">
                  <div className="list-group list-group-flush">
                    <div className="list-group-item">
                      <h5 className="noMargin pull-left firstLetter">
                        User Tag Attributes
                      </h5>
                      <div className="pull-right">
                        <button
                          className="btn btn-default btn-sm"
                          onClick={this.onClickTagAttributesReport.bind(this)}>
                          View Report
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              {reportRoles.indexOf("payment") > -1 && (
                <div className="col-lg-6">
                  <div className="card">
                    <div className="list-group list-group-flush">
                      <div className="list-group-item">
                        <h5 className="noMargin pull-left firstLetter">
                          Upcoming Payments
                        </h5>
                        <div className="pull-right">
                          <button
                            className="btn btn-default btn-sm"
                            onClick={this.onClickUpcomingPaymentsReport.bind(
                              this
                            )}>
                            View Report
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {reportRoles.indexOf("payment") > -1 && (
                <div className="col-lg-6">
                  <div className="card">
                    <div className="list-group list-group-flush">
                      <div className="list-group-item">
                        <h5 className="noMargin pull-left firstLetter">
                          Failed Payments
                        </h5>
                        <div className="pull-right">
                          <button
                            className="btn btn-default btn-sm"
                            onClick={this.onClickFailedPaymentsReport.bind(
                              this
                            )}>
                            View Report
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="row">
              {reportRoles.indexOf("payment") > -1 && (
                <div className="col-lg-6">
                  <div className="card">
                    <div className="list-group list-group-flush">
                      <div className="list-group-item">
                        <h5 className="noMargin pull-left firstLetter">
                          Customer Revenue
                        </h5>
                        <div className="pull-right">
                          <button
                            className="btn btn-default btn-sm"
                            onClick={this.onClickCustomerRevenue.bind(this)}>
                            View Report
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {reportRoles.indexOf("zoom") > -1 && (
                <div className="col-lg-6">
                  <div className="card">
                    <div className="list-group list-group-flush">
                      <div className="list-group-item">
                        <h5 className="noMargin pull-left firstLetter">
                          Zoom Meetings
                        </h5>
                        <div className="pull-right">
                          <button
                            className="btn btn-default btn-sm"
                            onClick={this.onClickZoomMeeting.bind(this)}>
                            View Report
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="row">
              {reportRoles.indexOf("affiliate") > -1 && (
                <div className="col-lg-6">
                  <div className="card">
                    <div className="list-group list-group-flush">
                      <div className="list-group-item">
                        <h5 className="noMargin pull-left firstLetter">
                          Affiliate Sales Report
                        </h5>
                        <div className="pull-right">
                          <button
                            className="btn btn-default btn-sm"
                            onClick={this.onClickAffiliateViewReport.bind(
                              this
                            )}>
                            View Report
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {reportRoles.indexOf("affiliate") > -1 && (
                <div className="col-lg-6">
                  <div className="card">
                    <div className="list-group list-group-flush">
                      <div className="list-group-item">
                        <h5 className="noMargin pull-left firstLetter">
                          Affiliate List Report
                        </h5>
                        <div className="pull-right">
                          <button
                            className="btn btn-default btn-sm"
                            onClick={this.onClickAffiliateListReport.bind(
                              this
                            )}>
                            View Report
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="row">
              {reportRoles.indexOf("email") > -1 && (
                <div className="col-lg-6">
                  <div className="card">
                    <div className="list-group list-group-flush">
                      <div className="list-group-item">
                        <h5 className="noMargin pull-left firstLetter">
                          Email Bounces and Complaints
                        </h5>
                        <div className="pull-right">
                          <button
                            className="btn btn-default btn-sm"
                            onClick={this.onClickBouncedEmails.bind(this)}>
                            View Report
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {reportRoles.indexOf("payment") > -1 && (
                <div className="col-lg-6">
                  <div className="card">
                    <div className="list-group list-group-flush">
                      <div className="list-group-item">
                        <h5 className="noMargin pull-left firstLetter">
                          View Subscriptions
                        </h5>
                        <div className="pull-right">
                          <button
                            className="btn btn-default btn-sm"
                            onClick={this.onClickViewSubscriptions.bind(this)}>
                            View Report
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="row">
              <div className="col-lg-6">
                <div className="card">
                  <div className="list-group list-group-flush">
                    <div className="list-group-item">
                      <h5 className="noMargin pull-left firstLetter">
                        Customer Orders
                      </h5>
                      <div className="pull-right">
                        <button
                          className="btn btn-default btn-sm"
                          onClick={this.onClickViewCustomOrders.bind(this)}>
                          View Report
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="card">
                  <div className="list-group list-group-flush">
                    <div className="list-group-item">
                      <h5 className="noMargin pull-left firstLetter">
                        Tags Report
                      </h5>
                      <div className="pull-right">
                        <button
                          className="btn btn-default btn-sm"
                          onClick={this.onClickViewTagsReport.bind(this)}>
                          View Report
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6">
                <div className="card">
                  <div className="list-group list-group-flush">
                    <div className="list-group-item">
                      <h5 className="noMargin pull-left firstLetter">
                        Custom Admin Fields
                      </h5>
                      <div className="pull-right">
                        <button
                          className="btn btn-default btn-sm"
                          onClick={this.onClickCustomAdminFieldReport.bind(
                            this
                          )}>
                          View Report
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="card">
                  <div className="list-group list-group-flush">
                    <div className="list-group-item">
                      <h5 className="noMargin pull-left firstLetter">
                        Unsubscribed Users
                      </h5>
                      <div className="pull-right">
                        <button
                          className="btn btn-default btn-sm"
                          onClick={this.onClickUnsubscribedUsersReport.bind(
                            this
                          )}>
                          View Report
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {isDashboardSite() && (
              <div className="row">
                <div className="col-lg-6">
                  <div className="card">
                    <div className="list-group list-group-flush">
                      <div className="list-group-item">
                        <h5 className="noMargin pull-left firstLetter">
                          Apple Orders
                        </h5>
                        <div className="pull-right">
                          <button
                            className="btn btn-default btn-sm"
                            onClick={this.onClickAppleOrdersReport.bind(this)}>
                            View Report
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="row">
              {reportRoles.indexOf("payment") > -1 && (
                <div className="col-lg-6">
                  <div className="card">
                    <div className="list-group list-group-flush">
                      <div className="list-group-item">
                        <h5 className="noMargin pull-left firstLetter">
                          Amount Collected
                        </h5>
                      </div>
                      <div className="list-group-item">
                        {this.renderDateFilter("stripe-charge-counts")}
                      </div>
                      {this.state.promotions.length > 0 && (
                        <div className="list-group-item">
                          {this.renderPromotionFilter()}
                        </div>
                      )}
                      <div className="list-group-item">
                        <div className="gap10" />
                        <div className="cardList">
                          <ReportRow
                            label="Total"
                            class="taskGreen"
                            value={`$${this.state.amountCollected.total}`}
                          />
                          <ReportRow
                            label="Refunds"
                            class="taskRed"
                            value={`$${this.state.amountCollected.refunds}`}
                          />
                          <ReportRow
                            label="Total Minus Refunds"
                            class="taskGreen"
                            value={`$${this.state.amountCollected.totalMinusRefunds}`}
                          />
                          <ReportRow
                            label="Customer Value"
                            class="taskGreen"
                            value={`$${this.state.amountCollected.customerValue}`}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {isFs && (
                <div className="col-lg-6">
                  <div className="card">
                    <div className="list-group list-group-flush">
                      <div className="list-group-item">
                        <h5 className="noMargin pull-left firstLetter">
                          Marci's Circle Users
                        </h5>
                        <div className="pull-right">
                          <button
                            className="btn btn-default btn-sm"
                            onClick={this.onClickMCViewReport.bind(this)}>
                            View Report
                          </button>
                        </div>
                      </div>
                      <div className="list-group-item">
                        <div className="gap10" />
                        <div className="cardList">
                          <ReportRow
                            label="Active"
                            class="taskGreen"
                            value={this.state.mcUsers.active}
                          />
                          <ReportRow
                            label="Cancelled"
                            class="taskGreen"
                            value={this.state.mcUsers.cancel}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {isFs && (
              <div className="row">
                <div className="col-lg-6">
                  <div className="card">
                    <div className="list-group list-group-flush">
                      <div className="list-group-item">
                        <h5 className="noMargin pull-left firstLetter">
                          Marci Circle Users (Most Tracked Points)
                        </h5>
                      </div>
                      <div className="list-group-item">
                        {this.renderDateFilter("mc-most-tracked")}
                      </div>
                      <div className="list-group-item">
                        <div className="gap10" />
                        <div className="cardList">
                          {this.state.mcUsersTracked.map((i) => (
                            <ReportRow
                              label={i.user_name}
                              class="taskGreen"
                              value={i.total}
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {reportRoles.indexOf("challenge") > -1 && (
              <div className="row">
                <div className="col-lg-6">
                  <div className="card">
                    <div className="list-group list-group-flush">
                      <div className="list-group-item">
                        <h5 className="noMargin pull-left firstLetter">
                          Challenge Users (Most Tracked Points)
                        </h5>
                      </div>
                      <div className="list-group-item">
                        <div className="row">
                          <div className="col-lg-6">
                            <div
                              className={
                                this.state.selectedChallenge !== ""
                                  ? "mdInput mdFocussed"
                                  : "mdInput"
                              }>
                              <label>Select Challenge</label>
                              <select
                                name="selectedChallenge"
                                value={this.state.selectedChallenge}
                                onChange={(event) =>
                                  this.handleInputChange(event)
                                }>
                                {this.renderChallenges()}
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <label className="inputFakeLabelFocussed">
                              Challenge Start Date
                            </label>
                            <div>
                              <DatePicker
                                value={this.state.challengeStartDate}
                                onChange={this.onChangeChallengeStartDate.bind(
                                  this
                                )}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="list-group-item">
                        <div className="gap10" />
                        <div className="cardList">
                          {this.state.challengeUsersTracked.map((i) => (
                            <ReportRow
                              label={i.user_name}
                              class="taskGreen"
                              value={i.total}
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="card">
                    <div className="list-group list-group-flush">
                      <div className="list-group-item">
                        <h5 className="noMargin pull-left firstLetter">
                          Active Challenge Users
                        </h5>
                      </div>
                      <div className="list-group-item">
                        <div className="row">
                          <div className="col-lg-6">
                            <div
                              className={
                                this.state.selectedActiveChallenge !== ""
                                  ? "mdInput mdFocussed"
                                  : "mdInput"
                              }>
                              <label>Select Challenge</label>
                              <select
                                name="selectedActiveChallenge"
                                value={this.state.selectedActiveChallenge}
                                onChange={(event) =>
                                  this.handleActiveInputChange(event)
                                }>
                                {this.renderChallenges()}
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <label className="inputFakeLabelFocussed">
                              Challenge Start Date
                            </label>
                            <div>
                              <DatePicker
                                value={this.state.selectedActiveStartDate}
                                onChange={this.onChangeActiveChallengeStartDate.bind(
                                  this
                                )}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="list-group-item">
                        <h6 className="noMargin pull-left firstLetter">
                          Total : {this.state.activeChallengeUsers.length}
                        </h6>

                        <div className="pull-right">
                          <button
                            className="btn btn-default btn-sm"
                            onClick={this.onClickExportIgnoredUsers.bind(
                              this,
                              "active-users"
                            )}>
                            Export Users
                          </button>
                        </div>
                      </div>
                      <div className="list-group-item">
                        <div className="gap10" />
                        <div className="cardList">
                          {this.state.activeChallengeUsers.map((i) => (
                            <ReportRow
                              label={i.member_info.name}
                              class="taskGreen"
                              value={""}
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Onboarding>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
