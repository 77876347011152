import React from "react";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  PostalCodeElement,
  StripeProvider,
  Elements,
  injectStripe,
  StripeProps,
} from "react-stripe-elements";
import { VelocityTransitionGroup } from "velocity-react";
import DateTimePicker from "react-datetime-picker";

import { showNotification } from "../../helpers";
import { SettingsHelper } from "../../helpers/settings";

class PaymentForm extends React.Component<{
  fontSize: string,
  stripe: StripeProps,
}> {
  constructor(props) {
    super(props);

    let paymentTypeClass = {
      payments: "active",
      subscription: "",
    };

    this.state = {
      cardName: "",
      cardEmpty: true,
      expEmpty: true,
      cvcEmpty: true,
      postalEmpty: true,
      cardFocus: false,
      expFocus: false,
      cvcFocus: false,
      postalFocus: false,
      paymentTypeClass: paymentTypeClass,
      selectedPaymentType: "payments",
      selectedPlan: "",
      isAlreadyCard: this.props.isAlreadyCard || false,
      subscriptionStartDate: new Date(),
      selectedDates: [{ date: new Date(), amount: "0" }],
      dates: [0],
      showPlanDate: true,
    };
  }

  componentWillReceiveProps(newProps) {
    if (newProps.isAlreadyCard !== undefined) {
      this.setState({ isAlreadyCard: newProps.isAlreadyCard });
    }
  }

  handleSubmit = (ev) => {
    ev.preventDefault();

    if (this.state.cardName.length === 0) {
      showNotification("error", "Your card name is incomplete", 4000);
      return;
    }

    this.props.stripe
      .createToken({ currency: "usd", name: this.state.cardName })
      .then((response) => {
        this.props.onCreateToken(response);
      });
  };

  handleCardChange = (change) => {
    this.setState({ cardEmpty: change["empty"] });
  };

  handleCvcChange = (change) => {
    this.setState({ cvcEmpty: change["empty"] });
  };

  handleExpChange = (change) => {
    this.setState({ expEmpty: change["empty"] });
  };

  handlePostalChange = (change) => {
    this.setState({ postalEmpty: change["empty"] });
  };

  handleCardFocus = () => {
    this.setState({ cardFocus: true });
  };

  handleCvcFocus = () => {
    this.setState({ cvcFocus: true });
  };

  handleExpFocus = () => {
    this.setState({ expFocus: true });
  };

  handlePostalFocus = () => {
    this.setState({ postalFocus: true });
  };

  handleCardBlur = () => {
    this.setState({ cardFocus: false });
  };

  handleCvcBlur = () => {
    this.setState({ cvcFocus: false });
  };

  handleExpBlur = () => {
    this.setState({ expFocus: false });
  };

  handlePostalBlur = () => {
    this.setState({ postalFocus: false });
  };

  renderPlans() {
    let returnVals = [];
    let plans = this.props.plans;
    returnVals.push(<option value={""} key={0} />);
    for (let i = 0; i < plans.length; i++) {
      returnVals.push(
        /*eslint-disable*/
        <option value={plans[i]._id} key={i + 1}>
          {plans[i].description +
            " ($" +
            parseFloat(plans[i].amount) / 100 +
            "/" +
            plans[i].interval_period +
            ")"}
        </option>
        /*eslint-enable*/
      );
    }
    return returnVals;
  }

  onChangeStartDate(value) {
    this.setState(
      {
        subscriptionStartDate: value,
      },
      () => {
        this.callChangeData();
      }
    );
  }

  onClickPaymentOption(option) {
    let paymentTypeClass = this.state.paymentTypeClass;
    for (const key in paymentTypeClass) {
      if (paymentTypeClass.hasOwnProperty(key)) {
        if (key === option) {
          paymentTypeClass[key] = "active";
        } else {
          paymentTypeClass[key] = "";
        }
      }
    }
    this.setState(
      {
        paymentTypeClass: paymentTypeClass,
        selectedPaymentType: option,
      },
      () => {
        this.callChangeData();
      }
    );
  }

  addNewDate() {
    var newDate = this.state.dates.length;
    this.setState(
      {
        dates: this.state.dates.concat(newDate),
        selectedDates: this.state.selectedDates.concat({
          date: new Date(),
          amount: "0",
        }),
      },
      () => {
        this.callChangeData();
      }
    );
  }

  onChangeDates(index, date) {
    let selectedDates = this.state.selectedDates;
    selectedDates[index].date = date;
    this.setState({ selectedDates: selectedDates }, () => {
      this.callChangeData();
    });
  }

  removeDateFromList = (index) => {
    let dates = this.state.dates;
    let selectedDates = this.state.selectedDates;
    dates.splice(index, 1);
    selectedDates.splice(index, 1);
    this.setState({ dates: dates, selectedDates: selectedDates });
  };

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  handleAmountChange(event, index) {
    const target = event.target;
    const value = target.value;

    let selectedDates = this.state.selectedDates;
    selectedDates[index].amount = value;
    this.setState({ selectedDates: selectedDates }, () => {
      this.callChangeData();
    });
  }

  onChangePlan(event) {
    const target = event.target;
    const value = target.value;

    let plans = this.props.plans || [];
    let fPlans = plans.filter((d) => {
      return d._id === value;
    });
    let showPlanDate = true;
    if (fPlans.length > 0) {
      let fPlan = fPlans[0];
      if (fPlan.trial_days) {
        showPlanDate = false;
      }
    }

    this.setState(
      {
        showPlanDate: showPlanDate,
        selectedPlan: value,
      },
      () => {
        this.callChangeData();
      }
    );
  }

  callChangeData() {
    let data = {
      selectedDates: this.state.selectedDates,
      subscriptionStartDate: this.state.subscriptionStartDate,
      selectedPaymentType: this.state.selectedPaymentType,
      selectedPlan: this.state.selectedPlan,
    };
    this.props.onChangeData(data);
  }

  render() {
    const createOptions = () => {
      return {
        style: {
          base: {
            color: "#111",
            fontSize: "16px",
            fontWeight: "500",
            letterSpacing: "0",
            fontSmoothing: "antialiased",
            fontFamily: "Lato, 'sans-serif'",
            "::placeholder": {
              color: "#aaa",
              fontFamily: "Lato, 'sans-serif'",
              fontSize: "16px",
              fontWeight: "500",
            },
          },
          invalid: {
            color: "#9e2146",
          },
        },
      };
    };

    return (
      <form className="stripePaymentForm">
        <VelocityTransitionGroup
          enter={{ animation: "slideDown" }}
          leave={{ animation: "slideUp" }}>
          {this.state.isAlreadyCard === false && (
            <div>
              <div
                className={
                  this.state.cardName !== "" ? "mdInput mdFocussed" : "mdInput"
                }>
                <label>Name On Card</label>
                <input
                  type="text"
                  name={"cardName"}
                  value={this.state.cardName}
                  onChange={(event) => this.handleInputChange(event)}
                />
              </div>
              <div className="gap10" />
              <div
                className={
                  this.state.cardFocus || !this.state.cardEmpty
                    ? "mdInput mdFocussed"
                    : "mdInput"
                }>
                <label>Card Number</label>
                <CardNumberElement
                  {...createOptions(this.props)}
                  onChange={this.handleCardChange}
                  onFocus={this.handleCardFocus}
                  onBlur={this.handleCardBlur}
                  placeholder={""}
                />
              </div>
              <div className="gap10" />
              <div
                className={
                  this.state.expFocus || !this.state.expEmpty
                    ? "mdInput mdFocussed"
                    : "mdInput"
                }>
                <label>Expiration Date (mm/yy)</label>
                <CardExpiryElement
                  {...createOptions(this.props)}
                  onChange={this.handleExpChange}
                  onFocus={this.handleExpFocus}
                  onBlur={this.handleExpBlur}
                  placeholder={"                          "}
                />
              </div>
              <div className="gap10" />

              <div
                className={
                  this.state.cvcFocus || !this.state.cvcEmpty
                    ? "mdInput mdFocussed"
                    : "mdInput"
                }>
                <label>CVC</label>
                <CardCVCElement
                  {...createOptions(this.props)}
                  onChange={this.handleCvcChange}
                  onFocus={this.handleCvcFocus}
                  onBlur={this.handleCvcBlur}
                  placeholder={"      "}
                />
              </div>
              <div className="gap10" />

              <div
                className={
                  this.state.postalFocus || !this.state.postalEmpty
                    ? "mdInput mdFocussed"
                    : "mdInput"
                }>
                <label>Postal code</label>
                <PostalCodeElement
                  {...createOptions(this.props)}
                  onChange={this.handlePostalChange}
                  onFocus={this.handlePostalFocus}
                  onBlur={this.handlePostalBlur}
                  placeholder={"             "}
                />
              </div>

              <div className="gap30" />
            </div>
          )}
        </VelocityTransitionGroup>

        {this.props.showPayment === true && (
          <div>
            <div className="tabsHolder radioTabs">
              <div
                className={this.state.paymentTypeClass.payments}
                onClick={() => this.onClickPaymentOption("payments")}>
                <span className="icon-check" /> Payments
              </div>
              <div
                className={this.state.paymentTypeClass.subscription}
                onClick={() => this.onClickPaymentOption("subscription")}>
                <span className="icon-check" /> Subscription
              </div>
            </div>

            {this.state.selectedPaymentType === "subscription" && (
              <div>
                <div
                  className={
                    this.state.selectedPlan !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Select plan</label>
                  <select
                    value={this.state.selectedPlan}
                    className="billingDate"
                    onChange={this.onChangePlan.bind(this)}>
                    {this.renderPlans()}
                  </select>
                </div>

                <div className="gap20" />
                {this.state.showPlanDate && (
                  <>
                    <label className="inputFakeLabelFocussed">
                      Select Start Date
                    </label>
                    <div>
                      <DateTimePicker
                        value={this.state.subscriptionStartDate}
                        onChange={this.onChangeStartDate.bind(this)}
                      />
                    </div>
                    <div className="gap20" />
                  </>
                )}
              </div>
            )}

            {this.state.selectedPaymentType === "payments" && (
              <div>
                <div className="gap20" />
                {this.state.dates.map((i, index) => (
                  <div className="row" key={i}>
                    <div className="col-sm-8" key={index}>
                      <label className="inputFakeLabelFocussed">
                        Select Date {index + 1}
                      </label>
                      <div>
                        <DateTimePicker
                          value={this.state.selectedDates[index].date}
                          onChange={this.onChangeDates.bind(this, index)}
                        />
                        {index !== 0 && (
                          <div
                            className="btn"
                            onClick={this.removeDateFromList.bind(this, index)}>
                            <span style={{ color: "red" }}>Remove Date</span>
                          </div>
                        )}
                      </div>
                      <div className="gap20" />
                    </div>
                    <div className="col-sm-4">
                      <div
                        className={
                          this.state.selectedDates[index].amount !== ""
                            ? "mdInput mdFocussed"
                            : "mdInput"
                        }>
                        <label>
                          Billing Amount {index + 1}
                          ($)
                        </label>
                        <input
                          type="number"
                          name={"amount" + index + 1}
                          value={this.state.selectedDates[index].amount}
                          onChange={(event) =>
                            this.handleAmountChange(event, index)
                          }
                        />
                      </div>
                      <div className="gap20" />
                    </div>
                  </div>
                ))}
                <div className="text-right">
                  <div
                    className="btn btn-primary"
                    onClick={() => this.addNewDate()}>
                    Add Another Date
                  </div>
                </div>
                <div className="gap20" />
              </div>
            )}
          </div>
        )}

        <div
          className="text-right"
          style={{ display: "none" }}
          onClick={this.handleSubmit.bind(this)}
          ref={this.props.paymentRef}>
          <button className="btn btn-primary addAnotherDate">
            Call Stripe Token Api
          </button>
        </div>
      </form>
    );
  }
}
const SplitForm = injectStripe(PaymentForm);

class CheckoutPage extends React.Component<{}, {}> {
  render() {
    let settingsHelper = new SettingsHelper();
    let stripeKey = settingsHelper.getStripePrivateKey();

    if (this.props.privateKey) {
      stripeKey = this.props.privateKey;
    }

    if (!stripeKey) {
      return (
        <div>
          <p>Please setup stripe account from settings screen.</p>
        </div>
      );
    }
    return (
      <div>
        <StripeProvider apiKey={stripeKey}>
          <div className="Checkout">
            <Elements
              fonts={[
                {
                  cssSrc:
                    "https://fonts.googleapis.com/css?family=Lato:300,400,500,600",
                },
              ]}>
              <SplitForm
                paymentRef={this.props.paymentRef}
                plans={this.props.plans}
                onChangeData={this.props.onChangeData}
                onCreateToken={this.props.onCreateToken}
                showPayment={this.props.showPayment}
                isAlreadyCard={this.props.isAlreadyCard}
                cardData={this.props.cardData}
              />
            </Elements>
          </div>
        </StripeProvider>
      </div>
    );
  }
}

export default CheckoutPage;
