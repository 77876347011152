import React, { Component } from "react";
import Autosuggest from "react-autosuggest";

class AutoSuggest extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.value || "",
      placeholder: this.props.placeholder || "",
      selectedValue: {},
      data: this.props.data,
      suggestions: [],
      searchKey: this.props.searchKey || "name",
    };
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    });
    this.props.onChange(newValue);
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    return inputLength === 0
      ? []
      : this.state.data.filter(
          (el) =>
            el[this.props.searchKey] &&
            el[this.props.searchKey].toLowerCase().slice(0, inputLength) ===
              inputValue
        );
  };

  getSuggestionValue = (suggestion) => {
    this.props.onSelect(suggestion);
    return suggestion[this.state.searchKey];
  };

  renderSuggestion = (suggestion) => {
    return (
      <div className="customSearchSuggestionItem noBorder">
        <div className="customSearchSuggestionInfo">
          {/* <p><img src={suggestion.image} alt="" /></p> */}
          <p>{suggestion[this.state.searchKey]}</p>
        </div>
      </div>
    );
  };

  render() {
    return (
      <Autosuggest
        suggestions={this.state.suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested.bind(
          this
        )}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested.bind(
          this
        )}
        getSuggestionValue={this.getSuggestionValue.bind(this)}
        renderSuggestion={this.renderSuggestion.bind(this)}
        inputProps={{
          value: this.state.value,
          onChange: this.onChange,
          placeholder: this.state.placeholder,
        }}
      />
    );
  }
}

export default AutoSuggest;
