import * as api from "./api";
import { callApi } from "../helpers";

function callCreateIngredient(data) {
  return callApi(api.service("meal-plan-ingredient").create(data));
}

function callGetIngredients() {
  return callApi(api.service("meal-plan-ingredient").find({}));
}

function callCreateMealPlan(data) {
  return callApi(api.service("meal-plan").create(data));
}

function callUpdateMealPlan(id, data) {
  return callApi(api.service("meal-plan").patch(id, data));
}

function callGetMealPlans() {
  return callApi(api.service("meal-plan").find({}), false);
}

function callGetMealPlansByQuery(query) {
  return callApi(api.service("meal-plan").find(query));
}

function callDeleteMealPlanById(id) {
  return callApi(api.service("meal-plan").remove(id));
}

export {
  callCreateIngredient,
  callGetIngredients,
  callCreateMealPlan,
  callGetMealPlans,
  callGetMealPlansByQuery,
  callUpdateMealPlan,
  callDeleteMealPlanById
};
