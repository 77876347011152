import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DatePicker from "react-date-picker";

import RadioTag from "../../../components/form/radioTag";
import Checkbox from "../../../components/form/checkBox";
import TitleBox from "../../../components/general/titleBox";
import HelpTextDiv from "../../../components/form/helpTextDiv";
import { DraftEditor } from "../../../packages/draft-editor/index";

import {
  createSystemUpdate,
  updateSystemUpdate,
  resetStatus,
} from "../../../modules/actions/index";
import { showNotification, isFS } from "../../../helpers";
import Onboarding from "../onboarding/onboarding";

class AddSystemUpdate extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    let systemUpdateInfo = {
      systemUpdateId: 0,
      updateType: "article",
      title: "",
      content: "",
      contentJson: {},
      url: "",
      openInTab: false,
      keyOpenInTab: Math.random(),
      releaseDate: new Date(),
    };

    if (this.props.history.location.state !== undefined) {
      let systemUpdate = this.props.history.location.state.systemUpdate;
      systemUpdateInfo.systemUpdateId = systemUpdate._id;
      systemUpdateInfo.updateType = systemUpdate.update_type;
      systemUpdateInfo.title = systemUpdate.title;
      systemUpdateInfo.content = systemUpdate.content || "";
      systemUpdateInfo.contentJson = systemUpdate.json || {};
      systemUpdateInfo.url = systemUpdate.action_url;
      systemUpdateInfo.openInTab = systemUpdate.open_new_tab || false;
      systemUpdateInfo.releaseDate = new Date(systemUpdate.release_date);
    }

    this.state = {
      systemUpdateId: systemUpdateInfo.systemUpdateId,
      updateType: systemUpdateInfo.updateType,
      title: systemUpdateInfo.title,
      content: systemUpdateInfo.content,
      contentJson: systemUpdateInfo.contentJson,
      url: systemUpdateInfo.url,
      openInTab: systemUpdateInfo.openInTab,
      keyOpenInTab: systemUpdateInfo.keyOpenInTab,
      releaseDate: systemUpdateInfo.releaseDate,
      keyEditor: Math.random(),
    };
  }

  componentWillReceiveProps(newProps) {
    if (newProps.message) {
      if (newProps.success) {
        if (this.state.systemUpdateId === 0) {
          this.resetProps();
        }
        showNotification("success", newProps.message, 5000);
      } else {
        showNotification("error", newProps.message, 5000);
      }
      this.props.resetStatus();
    }
  }

  resetProps() {
    this.setState({
      updateType: "article",
      title: "",
      content: "",
      contentJson: {},
      url: "",
      openInTab: false,
      keyOpenInTab: Math.random(),
      releaseDate: new Date(),
    });
  }

  onSelectUpdateType(item, id) {
    this.setState({
      [id]: item[Object.keys(item)[0]].value,
    });
  }

  onChangeContentValue = (html, json) => {
    this.setState({ content: html, contentJson: json });
  };

  onChangeReleaseDate(value) {
    this.setState({
      releaseDate: value,
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  toggleOpenInTabCheckbox = (check) => {
    this.setState({
      openInTab: check,
    });
  };

  onClickAddUpdate() {
    if (this.state.title.length === 0) {
      showNotification("error", "Title is required", 4000);
      return;
    }

    if (this.state.content.length === 0) {
      showNotification("error", "Content is required", 4000);
      return;
    }

    let apiData = {
      /*eslint-disable */
      update_type: this.state.updateType,
      title: this.state.title,
      content: this.state.content,
      json: this.state.contentJson,
      action_url: this.state.url,
      release_date: this.state.releaseDate,
      open_new_tab: this.state.openInTab,
      status: "Active",
      is_active: true,
      /*eslint-enable*/
    };

    if (this.state.systemUpdateId === 0) {
      this.props.createSystemUpdate(apiData);
    } else {
      this.props.updateSystemUpdate(this.state.systemUpdateId, apiData);
    }
  }

  render() {
    let updateTypes = [
      { name: "Article", value: "article" },
      { name: "Challenge", value: "challenge" },
      { name: "Other", value: "other" },
    ];
    if (isFS()) {
      updateTypes = [
        { name: "Article", value: "article" },
        { name: "News", value: "news" },
        { name: "Marci's Circle", value: "marci-circle" },
        { name: "Meal Plan", value: "meal-plan" },
        { name: "Challenge", value: "challenge" },
        { name: "Other", value: "other" },
      ];
    }
    return (
      <Onboarding history={this.props.history}>
        <div>
          <div className="container-fluid">
            <TitleBox
              title={
                this.state.systemUpdateId === 0
                  ? "Create New System Update"
                  : "Edit System Update"
              }
              showBackBtn={this.state.systemUpdateId !== 0}
            />
            <div className="gap20" />
            <div className="card">
              <div className="container-fluid">
                <div className="gap20" />
                <div className="radioBHolder">
                  <div className="havingHelpText">
                    <p>Update Type</p>
                  </div>
                  <HelpTextDiv
                    id="idSystemUpdateType"
                    label="Learn more about the System Update Type"
                    type="addSystemUpdate"
                    isInline="true"
                  />
                  <div className="tabsHolder radioTabs">
                    <RadioTag
                      onCheckChange={this.onSelectUpdateType.bind(this)}
                      labelList={updateTypes}
                      id={"updateType"}
                      selectType={"value"}
                      selectedList={[this.state.updateType]}
                    />
                  </div>
                </div>
                <div className="gap10" />

                <HelpTextDiv
                  id="idSystemUpdateTitle"
                  label="Learn more about the System Update Title"
                  type="addSystemUpdate"
                  isInline="false"
                />
                <div
                  className={
                    this.state.title !== "" ? "mdInput mdFocussed" : "mdInput"
                  }>
                  <label>Update Title</label>
                  <input
                    type="text"
                    name="title"
                    value={this.state.title}
                    onChange={(event) => this.handleInputChange(event)}
                  />
                </div>

                <div>
                  <div className="gap20" />

                  <div className="havingHelpText">
                    <p>Content</p>
                  </div>
                  <HelpTextDiv
                    id="idSystemUpdateContent"
                    label="Learn more about the System Update Content"
                    type="addSystemUpdate"
                    isInline="true"
                  />
                  <div className="gap20" />
                  <div className="add-task-comment">
                    <DraftEditor
                      key={this.state.keyEditor}
                      cType="task"
                      defaultText="Content"
                      textAlign={"left"}
                      lineHeight={"inherit"}
                      html={this.state.content}
                      json={this.state.contentJson}
                      onChange={this.onChangeContentValue.bind(this)}
                    />
                  </div>
                  <div className="gap20" />
                </div>

                <HelpTextDiv
                  id="idSystemUpdateActionURL"
                  label="Learn more about the System Update Action URL"
                  type="addSystemUpdate"
                  isInline="false"
                />
                <div
                  className={
                    this.state.url !== "" ? "mdInput mdFocussed" : "mdInput"
                  }>
                  <label>Action URL</label>
                  <input
                    type="text"
                    name="url"
                    value={this.state.url}
                    onChange={(event) => this.handleInputChange(event)}
                  />
                </div>
                <div className="gap20" />

                <div className="havingHelpText">
                  <Checkbox
                    label={"Open in New Tab"}
                    isChecked={this.state.openInTab}
                    handleCheckboxChange={this.toggleOpenInTabCheckbox}
                    key={this.state.keyOpenInTab}
                  />
                </div>
                <HelpTextDiv
                  id="idSystemUpdateOpenInNewTab"
                  label="Learn more about the Open In New Tab"
                  type="addSystemUpdate"
                  isInline="true"
                />
                <div className="gap20" />

                <HelpTextDiv
                  id="idSystemUpdateReleaseDate"
                  label="Learn more about the System Update Release Date"
                  type="addSystemUpdate"
                  isInline="false"
                />
                <label className="inputFakeLabelFocussed">Release Date</label>
                <div>
                  <DatePicker
                    value={this.state.releaseDate}
                    onChange={this.onChangeReleaseDate.bind(this)}
                  />
                </div>
                <div className="gap20" />
                <div className="text-right">
                  <button
                    className="btn btn-primary"
                    onClick={() => this.onClickAddUpdate()}>
                    Save
                  </button>
                </div>
                <div className="gap20" />
              </div>
            </div>
          </div>

          <div className="gap20" />
        </div>
      </Onboarding>
    );
  }
}

const mapStateToProps = (state) => ({
  message: state.systemUpdateReducer.message,
  success: state.systemUpdateReducer.success,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { createSystemUpdate, updateSystemUpdate, resetStatus },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AddSystemUpdate);
