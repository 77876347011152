import {
  RESET_STATUS,
  CREATE_MEAL_PLAN_SUCCESS,
  CREATE_MEAL_PLAN_FAILURE,
  GET_MEAL_PLAN_SUCCESS,
  GET_INGREDIENT_SUCCESS,
  UPDATE_MEAL_PLAN_SUCCESS,
  UPDATE_MEAL_PLAN_FAILURE
} from "../types";

const initialState = {
  message: "",
  success: false,
  ingredients: {},
  mealPlans: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RESET_STATUS:
      return {
        ...state,
        message: "",
        success: false
      };
    case CREATE_MEAL_PLAN_SUCCESS:
      return {
        ...state,
        message: action.message,
        success: action.success
      };
    case CREATE_MEAL_PLAN_FAILURE:
      return {
        ...state,
        message: action.message,
        success: action.success
      };
    case UPDATE_MEAL_PLAN_SUCCESS:
      return {
        ...state,
        message: action.message,
        success: action.success
      };
    case UPDATE_MEAL_PLAN_FAILURE:
      return {
        ...state,
        message: action.message,
        success: action.success
      };
    case GET_MEAL_PLAN_SUCCESS:
      return {
        ...state,
        mealPlans: action.data
      };

    case GET_INGREDIENT_SUCCESS:
      return {
        ...state,
        ingredients: action.data
      };
    default:
      return state;
  }
};
