import * as api from "./api";
import { callApi } from "../helpers";

function callCreateNotification(data) {
  return callApi(api.service("notification").create(data));
}

function callUpdateNotification(id, data) {
  return callApi(api.service("notification").patch(id, data));
}

function callGetNotificationsUsingQuery(query, isLoading = true) {
  return callApi(api.service("notification").find(query), isLoading);
}

function callGetNotificationById(id) {
  return callApi(api.service("notification").get(id));
}

function callDeleteNotificationById(id) {
  return callApi(api.service("notification").remove(id));
}

export {
  callCreateNotification,
  callUpdateNotification,
  callGetNotificationsUsingQuery,
  callGetNotificationById,
  callDeleteNotificationById,
};
