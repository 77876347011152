import React from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import ContentIcon from "../../packages/mp-content-editor/components/general/icon";
import { imageUrl } from "../../helpers";

const SortableVideoItem = SortableElement((props) => {
  return (
    <div className="generatedList">
      <div className="generatedListItem">
        {props.value.data.player !== "memberpages" ? (
          <>
            <p className="generatedListAns">{props.value.data.title}</p>
            <p className="generatedDesc">{props.value.data.url}</p>
          </>
        ) : (
          <>
            <p className="generatedListAns">{props.value.data.fullUrl}</p>
            <p className="generatedDesc">{props.value.data.awsVideoId}</p>
          </>
        )}

        <button
          className="btn btn-danger btn-sm removeGeneratedList"
          onClick={props.onPressRemove}
          data-value={props.value.index}>
          Remove
        </button>
      </div>
    </div>
  );
});

export const SortableVideoList = SortableContainer((props) => {
  return (
    <div>
      {props.items.map((value, index) => (
        <SortableVideoItem
          key={`item-${index}`}
          index={index}
          value={{ data: value, index: index }}
          onPressRemove={props.onPressRemove}
        />
      ))}
    </div>
  );
});

const SortableQuestionItem = SortableElement((props) => {
  let category = props.value.data.item_type_info
    ? props.value.data.item_type_info.name
    : props.value.data.value.item_type_info
    ? props.value.data.value.item_type_info.name
    : "-";
  if (category === "-") {
    category = props.value.data.question;
  }

  let deleteConfirm = props.value.data.delete_confirm;
  return (
    <div className="generatedList">
      <div className="generatedListItem">
        <p className="generatedListAns">{props.value.data.name}</p>
        <p className="generatedDesc">
          <label>{category}</label>
        </p>
        {props.showEdit && (
          <>
            <button
              className="btn btn-info btn-sm editAnswer"
              style={{ marginRight: 220 }}
              onClick={props.onPressDuplicate}
              data-value={props.value.index}>
              Duplicate
            </button>
            <button
              className="btn btn-success btn-sm editAnswer"
              style={{ marginRight: 110 }}
              onClick={props.onPressEditContent}
              data-value={props.value.index}>
              Edit Content
            </button>
            <button
              className="btn btn-info btn-sm editAnswer"
              onClick={props.onPressEdit}
              data-value={props.value.index}>
              Edit Settings
            </button>
          </>
        )}
        {props.showView && (
          <button
            className="btn btn-info btn-sm editAnswer"
            onClick={props.onPressView}
            data-value={props.value.index}>
            View
          </button>
        )}
        {!deleteConfirm ? (
          <button
            className="btn btn-danger btn-sm removeGeneratedList"
            onClick={props.onPressRemove}
            data-value={props.value.index}>
            Remove
          </button>
        ) : (
          <>
            <button
              className="btn btn-success btn-sm removeGeneratedListUndo fa fa-undo"
              onClick={props.onPressRemoveUndo}
              data-value={props.value.index}></button>
            <button
              className="btn btn-danger btn-sm removeGeneratedListConfirm fa fa-close"
              onClick={props.onPressRemoveConfirm}
              data-value={props.value.index}></button>
          </>
        )}
      </div>
    </div>
  );
});

export const SortableQuestionList = SortableContainer((props) => {
  return (
    <div>
      {props.items.map((value, index) => (
        <SortableQuestionItem
          showEdit={props.showEdit || false}
          key={`item-${index}`}
          index={index}
          value={{ data: value, index: index }}
          onPressRemove={props.onPressRemove}
          onPressRemoveUndo={props.onPressRemoveUndo}
          onPressRemoveConfirm={props.onPressRemoveConfirm}
          onPressEdit={props.onPressEdit}
          onPressEditContent={props.onPressEditContent}
          onPressDuplicate={props.onPressDuplicate}
          onPressView={props.onPressView}
          showName={props.showName}
          showView={props.showView}
        />
      ))}
    </div>
  );
});

const SortableAnswerItem = SortableElement((props) => {
  return (
    <div className="answerList">
      <div className="answerListItem">
        <p className="answerListAns">
          <span className="answerListPoint">{props.value.data.points}</span>
          {props.value.data.selected ? (
            <label style={{ color: "#17a2b8" }}>
              {props.value.data.answer}
            </label>
          ) : (
            <label>{props.value.data.answer}</label>
          )}
        </p>
        <p className="answerListDesc">{props.value.data.description}</p>
        {props.showEdit && (
          <button
            className="btn btn-info btn-sm editAnswer"
            onClick={props.onPressEdit}
            data-value={props.value.index}>
            Edit
          </button>
        )}
        <button
          className="btn btn-danger btn-sm removeAnswer"
          onClick={props.onPressRemove}
          data-value={props.value.index}>
          Remove
        </button>
      </div>
    </div>
  );
});

export const SortableAnswerList = SortableContainer((props) => {
  return (
    <div>
      {props.items.map((value, index) => (
        <SortableAnswerItem
          showEdit={props.showEdit || false}
          key={`item-${index}`}
          index={index}
          value={{ data: value, index: index }}
          onPressRemove={props.onPressRemove}
          onPressEdit={props.onPressEdit}
        />
      ))}
    </div>
  );
});

const SortableIngredientItem = SortableElement((props) => {
  return (
    <div className="generatedList">
      <div className="generatedListItem">
        <p className="generatedListAns">
          {props.value.data.amount} {props.value.data.measurement}{" "}
          {props.value.data.name}
        </p>
        <p className="generatedDesc">{props.value.data.comment}</p>
        <button
          className="btn btn-danger btn-sm removeGeneratedList"
          onClick={props.onPressRemove}
          data-value={props.value.index}>
          Remove
        </button>
      </div>
    </div>
  );
});

export const SortableIngredientList = SortableContainer((props) => {
  return (
    <div>
      {props.items.map((value, index) => (
        <SortableIngredientItem
          key={`item-${index}`}
          index={index}
          value={{ data: value, index: index }}
          onPressRemove={props.onPressRemove}
        />
      ))}
    </div>
  );
});

const SortableExerciseItem = SortableElement((props) => {
  return (
    <div className="generatedList">
      <div className="generatedListItem">
        <p className="generatedListAns">{props.value.data.name}</p>
        <p className="generatedDesc">
          {props.value.data.weight
            ? props.value.data.weight + " lbs"
            : "Weight not added"}
        </p>
        {props.showEdit && (
          <button
            className="btn btn-info btn-sm editAnswer"
            onClick={props.onPressEdit}
            data-value={props.value.index}>
            Edit
          </button>
        )}
        <button
          className="btn btn-danger btn-sm removeGeneratedList"
          onClick={props.onPressRemove}
          data-value={props.value.index}>
          Remove
        </button>
      </div>
    </div>
  );
});

export const SortableExerciseList = SortableContainer((props) => {
  return (
    <div>
      {props.items.map((value, index) => (
        <SortableExerciseItem
          showEdit={props.showEdit || false}
          key={`item-${index}`}
          index={index}
          value={{ data: value, index: index }}
          onPressRemove={props.onPressRemove}
          onPressEdit={props.onPressEdit}
        />
      ))}
    </div>
  );
});

const SortablePathItem = SortableElement((props) => {
  return (
    <div className="generatedList">
      <div className="generatedListItem">
        <p className="generatedListAns">{props.value.data.upsell_item_name}</p>
        <p className="generatedDesc">
          {props.value.data.upsell_item_description
            ? props.value.data.upsell_item_description
            : "N/A"}
        </p>
        {props.value.data.has_tags.length > 0 && (
          <p className="generatedDesc">
            <b>Included Tags</b>:{" "}
            {props.value.data.has_tags.map((i) => i.name).join(", ")}
          </p>
        )}
        {props.value.data.not_has_tags.length > 0 && (
          <p className="generatedDesc">
            <b>Excluded Tags</b>:{" "}
            {props.value.data.not_has_tags.map((i) => i.name).join(", ")}
          </p>
        )}
        {props.showEdit && (
          <button
            className="btn btn-info btn-sm editAnswer"
            onClick={props.onPressEdit}
            data-value={props.value.index}>
            Edit
          </button>
        )}
        <button
          className="btn btn-danger btn-sm removeGeneratedList"
          onClick={props.onPressRemove}
          data-value={props.value.index}>
          Remove
        </button>
      </div>
    </div>
  );
});

export const SortablePathList = SortableContainer((props) => {
  return (
    <div>
      {props.items.map((value, index) => (
        <SortablePathItem
          key={`item-${index}`}
          showEdit={props.showEdit || false}
          index={index}
          value={{ data: value, index: index }}
          onPressRemove={props.onPressRemove}
          onPressEdit={props.onPressEdit}
        />
      ))}
    </div>
  );
});

const SortableIssueItem = SortableElement((props) => {
  return (
    <div className="generatedList">
      <div className="generatedListItem">
        <p className="generatedListAns">{props.value.data.title}</p>
        <p className="generatedDesc">{props.value.data.description}</p>
        <button
          className="btn btn-danger btn-sm removeGeneratedList"
          onClick={props.onPressRemove}
          data-value={props.value.index}>
          Remove
        </button>
      </div>
    </div>
  );
});

export const SortableIssueList = SortableContainer((props) => {
  return (
    <div>
      {props.items.map((value, index) => (
        <SortableIssueItem
          key={`item-${index}`}
          index={index}
          value={{ data: value, index: index }}
          onPressRemove={props.onPressRemove}
        />
      ))}
    </div>
  );
});

const SortableProgramSetItem = SortableElement((props) => {
  return (
    <div className="generatedList">
      <div className="generatedListItem">
        <p className="generatedListAns">{props.value.data.name}</p>
        <p className="generatedDesc">
          <b>Added Exercices</b>:{" "}
          {props.value.data.exercises.map((i) => i.name).join(", ")}
        </p>
        {props.showEdit && (
          <button
            className="btn btn-info btn-sm editAnswer"
            onClick={props.onPressEdit}
            data-value={props.value.index}>
            Edit
          </button>
        )}
        <button
          className="btn btn-danger btn-sm removeGeneratedList"
          onClick={props.onPressRemove}
          data-value={props.value.index}>
          Remove
        </button>
      </div>
    </div>
  );
});

export const SortableProgramSetList = SortableContainer((props) => {
  return (
    <div>
      {props.items.map((value, index) => (
        <SortableProgramSetItem
          key={`item-${index}`}
          showEdit={props.showEdit || false}
          index={index}
          value={{ data: value, index: index }}
          onPressRemove={props.onPressRemove}
          onPressEdit={props.onPressEdit}
        />
      ))}
    </div>
  );
});

const SortableTagUserItem = SortableElement((props) => {
  return (
    <div className="answerList">
      <div className="answerListItem">
        <p className="generatedDesc normal">
          <b>Name</b>: {props.value.data.name ? props.value.data.name : "-"}
        </p>
        <p className="generatedDesc normal">
          <b>Users</b>: {props.value.data.userInfo.userCount}
        </p>
        {props.value.data.included_tags.length > 0 && (
          <p className="generatedDesc normal firstLetter">
            <b>Included Tags</b>:{" "}
            {props.value.data.included_tags
              .map((i) => props.value.data.tagInfo[i].name)
              .join(", ")}
          </p>
        )}
        {props.value.data.included_tags.length === 0 && (
          <p className="generatedDesc normal firstLetter">
            <b>Included Tags</b>: -
          </p>
        )}
        {props.value.data.excluded_tags.length > 0 && (
          <p className="generatedDesc normal firstLetter">
            <b>Excluded Tags</b>:{" "}
            {props.value.data.excluded_tags
              .map((i) => props.value.data.tagInfo[i].name)
              .join(", ")}
          </p>
        )}
        {props.value.data.excluded_tags.length === 0 && (
          <p className="generatedDesc normal firstLetter">
            <b>Excluded Tags</b>: -
          </p>
        )}
        {props.value.data.userInfo.userCount > 0 && (
          <button
            className="btn btn-default btn-sm viewAnswer"
            onClick={props.onPressUser}
            data-value={props.value.index}>
            Users
          </button>
        )}
        <button
          className="btn btn-default btn-sm copyUser"
          onClick={props.onPressCopyUser}
          data-value={props.value.index}>
          Copy
        </button>
        <button
          className="btn btn-danger btn-sm removeGeneratedList"
          onClick={props.onPressRemove}
          data-value={props.value.index}>
          Remove
        </button>
      </div>
    </div>
  );
});

export const SortableTagUserList = SortableContainer((props) => {
  return (
    <div>
      {props.items.map((value, index) => (
        <SortableTagUserItem
          key={`item-${index}`}
          index={index}
          value={{ data: value, index: index }}
          onPressRemove={props.onPressRemove}
          onPressUser={props.onPressUser}
          onPressCopyUser={props.onPressCopyUser}
        />
      ))}
    </div>
  );
});

const SortableFileItem = SortableElement((props) => {
  return (
    <div className="generatedList">
      <div className="generatedListItem">
        <p className="generatedListAns">{props.value.data.name}</p>
        <p className="generatedDesc">{props.value.data.file}</p>
        {props.onPressEdit && (
          <button
            className="btn btn-info btn-sm editStep"
            onClick={props.onPressEdit}
            data-value={props.value.index}>
            Edit
          </button>
        )}
        <button
          className="btn btn-danger btn-sm removeGeneratedList"
          onClick={props.onPressRemove}
          data-value={props.value.index}>
          Remove
        </button>
      </div>
    </div>
  );
});

export const SortableFileList = SortableContainer((props) => {
  return (
    <div>
      {props.items.map((value, index) => (
        <SortableFileItem
          key={`item-${index}`}
          index={index}
          value={{ data: value, index: index }}
          onPressEdit={props.onPressEdit}
          onPressRemove={props.onPressRemove}
        />
      ))}
    </div>
  );
});

const SortableFormFieldItem = SortableElement((props) => {
  return (
    <div className="generatedList">
      <div className="generatedListItem">
        <p className="generatedListAns">{props.value.data.label}</p>
        {props.value.data.type && (
          <p className="generatedDesc firstLetter">
            {props.value.data.type === "editor"
              ? "Large Text Area"
              : props.value.data.type}
          </p>
        )}
        {props.value.data.map && (
          <p className="generatedDesc">{props.value.data.map}</p>
        )}
        {props.value.data.required === false && (
          <p className="generatedDesc" style={{ color: "orange" }}>
            Optional
          </p>
        )}
        {props.value.data.required === true && (
          <p className="generatedDesc" style={{ color: "red" }}>
            Required
          </p>
        )}
        {props.value.data.id && (
          <p className="generatedDesc">ID: {props.value.data.id}</p>
        )}

        {props.value.data.showEdit && (
          <button
            className="btn btn-info btn-sm editAnswer"
            onClick={props.onPressEdit}
            data-value={props.value.index}>
            Edit
          </button>
        )}
        {props.value.data.showEdit && (
          <button
            className="btn btn-danger btn-sm removeGeneratedList"
            onClick={props.onPressRemove}
            data-value={props.value.index}>
            Remove
          </button>
        )}
      </div>
    </div>
  );
});

export const SortableFormFieldList = SortableContainer((props) => {
  return (
    <div>
      {props.items.map((value, index) => (
        <SortableFormFieldItem
          key={`item-${index}`}
          index={index}
          value={{ data: value, index: index }}
          onPressEdit={props.onPressEdit}
          onPressRemove={props.onPressRemove}
        />
      ))}
    </div>
  );
});

const SortableStepItem = SortableElement((props) => {
  return (
    <div className="generatedList">
      <div className="generatedListItem">
        <p className="generatedListAns">{props.value.data.title}</p>
        <p className="generatedDesc">{props.value.data.fields.length} Fields</p>
        <button
          className="btn btn-info btn-sm editStep"
          onClick={props.onPressEdit}
          data-value={props.value.index}>
          Add/Edit Fields
        </button>
        <button
          className="btn btn-danger btn-sm removeGeneratedList"
          onClick={props.onPressRemove}
          data-value={props.value.index}>
          Remove
        </button>
      </div>
    </div>
  );
});

export const SortableStepList = SortableContainer((props) => {
  return (
    <div>
      {props.items.map((value, index) => (
        <SortableStepItem
          key={`item-${index}`}
          index={index}
          value={{ data: value, index: index }}
          onPressEdit={props.onPressEdit}
          onPressRemove={props.onPressRemove}
        />
      ))}
    </div>
  );
});

const SortablePageItem = SortableElement((props) => {
  return (
    <div className="generatedList">
      <div className="generatedListItem">
        <p className="generatedListAns">{props.value.data.title}</p>
        <p className="generatedDesc">
          {props.value.data.questions.length} Questions
        </p>
        <button
          className="btn btn-info btn-sm editStep"
          onClick={props.onPressEdit}
          data-value={props.value.index}>
          Add/Edit Questions
        </button>
        <button
          className="btn btn-danger btn-sm removeGeneratedList"
          onClick={props.onPressRemove}
          data-value={props.value.index}>
          Remove
        </button>
      </div>
    </div>
  );
});

export const SortablePageList = SortableContainer((props) => {
  return (
    <div>
      {props.items.map((value, index) => (
        <SortablePageItem
          key={`item-${index}`}
          index={index}
          value={{ data: value, index: index }}
          onPressEdit={props.onPressEdit}
          onPressRemove={props.onPressRemove}
        />
      ))}
    </div>
  );
});

const SortablePageQuestionItem = SortableElement((props) => {
  return (
    <div className="generatedList">
      <div className="generatedListItem">
        <p className="generatedListAns">{props.value.data.question}</p>
        <p className="generatedDesc">{props.value.data.type}</p>
        <p className="generatedDesc">
          {props.value.data.answers.length} Answers
        </p>

        <button
          className="btn btn-info btn-sm editStep"
          onClick={props.onPressEdit}
          data-value={props.value.index}>
          {props.value.data.type === "single" ||
          props.value.data.type === "multiple"
            ? "Add/Edit Answers"
            : "Edit"}
        </button>

        <button
          className="btn btn-danger btn-sm removeGeneratedList"
          onClick={props.onPressRemove}
          data-value={props.value.index}>
          Remove
        </button>
      </div>
    </div>
  );
});

export const SortablePageQuestionList = SortableContainer((props) => {
  return (
    <div>
      {props.items.map((value, index) => (
        <SortablePageQuestionItem
          key={`item-${index}`}
          index={index}
          value={{ data: value, index: index }}
          onPressEdit={props.onPressEdit}
          onPressRemove={props.onPressRemove}
        />
      ))}
    </div>
  );
});

const SortablePageAnswerItem = SortableElement((props) => {
  let answer = props.value.data.answer;
  let points = props.value.data.point;
  if (!answer) {
    answer = props.value.data.text;
    if (!answer) {
      answer = props.value.data.placeholder;
    }
  }
  if (!points) {
    points = props.value.data.points;
  }
  return (
    <div className="generatedList">
      <div className="generatedListItem">
        <p className="generatedListAns">{answer}</p>
        <p className="generatedDesc">{points}</p>
        <button
          className="btn btn-info btn-sm editStep"
          onClick={props.onPressEdit}
          data-value={props.value.index}>
          Edit
        </button>
        <button
          className="btn btn-danger btn-sm removeGeneratedList"
          onClick={props.onPressRemove}
          data-value={props.value.index}>
          Remove
        </button>
      </div>
    </div>
  );
});

export const SortablePageAnswerList = SortableContainer((props) => {
  return (
    <div>
      {props.items.map((value, index) => (
        <SortablePageAnswerItem
          key={`item-${index}`}
          index={index}
          value={{ data: value, index: index }}
          onPressEdit={props.onPressEdit}
          onPressRemove={props.onPressRemove}
        />
      ))}
    </div>
  );
});

const SortableSubMenuItem = SortableElement((props) => {
  return (
    <div className="generatedList">
      <div className="generatedListItem">
        <p className="generatedListAns">{props.value.data.name}</p>
        <p className="generatedDesc">{props.value.data.type}</p>
        <button
          className="btn btn-info btn-sm editStep"
          onClick={props.onPressEdit}
          data-value={props.value.index}>
          Edit
        </button>
        <button
          className="btn btn-danger btn-sm removeGeneratedList"
          onClick={props.onPressRemove}
          data-value={props.value.index}>
          Remove
        </button>
      </div>
    </div>
  );
});

export const SortableSubMenuList = SortableContainer((props) => {
  return (
    <div>
      {props.items.map((value, index) => (
        <SortableSubMenuItem
          key={`item-${index}`}
          index={index}
          value={{ data: value, index: index }}
          onPressEdit={props.onPressEdit}
          onPressRemove={props.onPressRemove}
        />
      ))}
    </div>
  );
});

const SortableRegistrationFormItem = SortableElement((props) => {
  return (
    <div className="generatedList">
      <div className="generatedListItem">
        <p className="generatedListAns">{props.value.data.name}</p>
        <p className="generatedDesc">{props.value.data._id}</p>
        <button
          className="btn btn-danger btn-sm removeGeneratedList"
          onClick={props.onPressRemove}
          data-value={props.value.index}>
          Remove
        </button>
      </div>
    </div>
  );
});

export const SortableRegistrationFormList = SortableContainer((props) => {
  return (
    <div>
      {props.items.map((value, index) => (
        <SortableRegistrationFormItem
          key={`item-${index}`}
          index={index}
          value={{ data: value, index: index }}
          onPressRemove={props.onPressRemove}
        />
      ))}
    </div>
  );
});

const SortableLandingPageItem = SortableElement((props) => {
  return (
    <div className="generatedList">
      <div className="generatedListItem">
        <p className="generatedListAns">{props.value.data.name}</p>
        <p className="generatedDesc">{props.value.data.url}</p>
        <button
          className="btn btn-primary btn-sm viewPreview"
          onClick={props.onPressPreview}
          data-value={props.value.index}>
          Preview
        </button>
        <button
          className="btn btn-info btn-sm copyUser"
          onClick={props.onPressEdit}
          data-value={props.value.index}>
          Edit
        </button>
        <button
          className="btn btn-danger btn-sm removeGeneratedList"
          onClick={props.onPressRemove}
          data-value={props.value.index}>
          Remove
        </button>
      </div>
    </div>
  );
});

export const SortableLandingPageList = SortableContainer((props) => {
  return (
    <div>
      {props.items.map((value, index) => (
        <SortableLandingPageItem
          key={`item-${index}`}
          index={index}
          value={{ data: value, index: index }}
          onPressEdit={props.onPressEdit}
          onPressRemove={props.onPressRemove}
          onPressPreview={props.onPressPreview}
        />
      ))}
    </div>
  );
});

const SortableFieldOptionItem = SortableElement((props) => {
  return (
    <div className="generatedList">
      <div className="generatedListItem">
        <p className="generatedListAns">{props.value.data.label}</p>
        <p className="generatedDesc">
          Selected By Default - {props.value.data.selected ? "Yes" : "No"}
        </p>
        {props.value.data.showEdit && (
          <button
            className="btn btn-info btn-sm editAnswer"
            onClick={props.onPressEdit}
            data-value={props.value.index}>
            Edit
          </button>
        )}
        {props.value.data.showEdit && (
          <button
            className="btn btn-danger btn-sm removeGeneratedList"
            onClick={props.onPressRemove}
            data-value={props.value.index}>
            Remove
          </button>
        )}
      </div>
    </div>
  );
});

export const SortableFieldOptionList = SortableContainer((props) => {
  return (
    <div>
      {props.items.map((value, index) => (
        <SortableFieldOptionItem
          key={`item-${index}`}
          index={index}
          value={{ data: value, index: index }}
          onPressEdit={props.onPressEdit}
          onPressRemove={props.onPressRemove}
        />
      ))}
    </div>
  );
});

const SortableTaskFieldItem = SortableElement((props) => {
  const mapType = {
    single: "Single Select",
    multiple: "Multiple Select",
    text: "Text",
    editor: "Large Text Area",
  };
  return (
    <div className="generatedList">
      <div className="generatedListItem">
        <p className="generatedListAns">{props.value.data.label}</p>
        <p className="generatedDesc">Type - {mapType[props.value.data.type]}</p>
        {props.task && (
          <p className="generatedDesc">
            Display inside Task List - {props.value.data.display ? "Yes" : "No"}
          </p>
        )}
        <p className="generatedDesc">ID - {props.value.data.id}</p>
        <button
          className="btn btn-info btn-sm editStep"
          onClick={props.onPressEdit}
          data-value={props.value.index}>
          Add/Edit Options
        </button>
        <button
          className="btn btn-danger btn-sm removeGeneratedList"
          onClick={props.onPressRemove}
          data-value={props.value.index}>
          Remove
        </button>
      </div>
    </div>
  );
});

export const SortableTaskFieldList = SortableContainer((props) => {
  return (
    <div>
      {props.items.map((value, index) => (
        <SortableTaskFieldItem
          key={`item-${index}`}
          index={index}
          value={{ data: value, index: index }}
          onPressEdit={props.onPressEdit}
          task={props.task}
          onPressRemove={props.onPressRemove}
        />
      ))}
    </div>
  );
});

const SortableLeadFieldItem = SortableElement((props) => {
  return (
    <div className="generatedList">
      <div className="generatedListItem">
        <p className="generatedListAns">{props.value.data.label}</p>
        <p className="generatedDesc">Type: {props.value.data.type || "text"}</p>
        <button
          className="btn btn-info btn-sm editStep"
          onClick={props.onPressEdit}
          data-value={props.value.index}>
          Add/Edit Options
        </button>
        <button
          className="btn btn-danger btn-sm removeGeneratedList"
          onClick={props.onPressRemove}
          data-value={props.value.index}>
          Remove
        </button>
      </div>
    </div>
  );
});

export const SortableLeadFieldList = SortableContainer((props) => {
  return (
    <div>
      {props.items.map((value, index) => (
        <SortableLeadFieldItem
          key={`item-${index}`}
          index={index}
          value={{ data: value, index: index }}
          onPressEdit={props.onPressEdit}
          onPressRemove={props.onPressRemove}
        />
      ))}
    </div>
  );
});

const SortableCustomFieldItem = SortableElement((props) => {
  return (
    <div className="generatedList">
      <div className="generatedListItem">
        <p className="generatedListAns">{props.value.data.name}</p>
        <p className="generatedDesc">
          Type: {props.value.data.type || "dropdown"}
        </p>
        <button
          className="btn btn-info btn-sm editStep"
          onClick={props.onPressEdit}
          data-value={props.value.index}>
          Edit
        </button>
        <button
          className="btn btn-danger btn-sm removeGeneratedList"
          onClick={props.onPressRemove}
          data-value={props.value.index}>
          Remove
        </button>
      </div>
    </div>
  );
});

export const SortableCustomFieldList = SortableContainer((props) => {
  return (
    <div>
      {props.items.map((value, index) => (
        <SortableCustomFieldItem
          key={`item-${index}`}
          index={index}
          value={{ data: value, index: index }}
          onPressEdit={props.onPressEdit}
          onPressRemove={props.onPressRemove}
        />
      ))}
    </div>
  );
});

const SortableSubPageItem = SortableElement((props) => {
  return (
    <div className="generatedList">
      <div className="generatedListItem">
        <p className="generatedListAns">{props.value.data.type}</p>
        <p className="generatedDesc">
          {props.value.data.url ? props.value.data.url : "Sub Page"}
        </p>
        <button
          className="btn btn-danger btn-sm removeGeneratedList"
          onClick={props.onPressRemove}
          data-value={props.value.index}>
          Remove
        </button>
      </div>
    </div>
  );
});

export const SortableSubPageList = SortableContainer((props) => {
  return (
    <div>
      {props.items.map((value, index) => (
        <SortableSubPageItem
          key={`item-${index}`}
          index={index}
          value={{ data: value, index: index }}
          onPressRemove={props.onPressRemove}
        />
      ))}
    </div>
  );
});

const SortableNavLinkItem = SortableElement((props) => {
  return (
    <div className="generatedList">
      <div className="generatedListItem">
        <p className="generatedListAns">{props.value.data.label}</p>
        {props.value.data.url && props.value.data.type === "url" && (
          <p className="generatedDesc">{props.value.data.url}</p>
        )}
        {props.value.data.links && (
          <p className="generatedDesc">
            Links: {props.value.data.links.length}
          </p>
        )}
        {props.value.data.subMenus && (
          <p className="generatedDesc">
            SubMenus: {props.value.data.subMenus.length}
          </p>
        )}
        {props.value.data.icon && (
          <div style={{ paddingTop: 5 }}>
            {props.iconType === "material" ? (
              <i
                className="material-icons"
                title={props.value.data.icon}
                style={{ fontSize: 20 }}>
                {props.value.data.icon}
              </i>
            ) : (
              <ContentIcon
                name={props.value.data.icon}
                width="20"
                alignItems="start"
                justifyContent="start"
                color="black"
                key={props.value.data.icon}
              />
            )}
          </div>
        )}
        {props.value.data.type && (
          <p className="generatedDesc">
            {props.value.data.type === "landingpage"
              ? "Landing Page"
              : props.value.data.type}
          </p>
        )}
        {props.value.data.type === "submenu" && (
          <button
            style={{ marginRight: 90 }}
            className="btn btn-info btn-sm editStep"
            onClick={props.onPressEditSubMenu}
            data-value={props.value.index}>
            Edit Sub Menu
          </button>
        )}
        <button
          className="btn btn-info btn-sm editStep"
          onClick={props.onPressEdit}
          data-value={props.value.index}>
          Edit Menu
        </button>
        <button
          className="btn btn-danger btn-sm removeGeneratedList"
          onClick={props.onPressRemove}
          data-value={props.value.index}>
          Remove
        </button>
      </div>
    </div>
  );
});

export const SortableNavLinkList = SortableContainer((props) => {
  return (
    <div>
      {props.items.map((value, index) => (
        <SortableNavLinkItem
          key={`item-${index}`}
          index={index}
          iconType={props.iconType}
          type={props.type || "link"}
          value={{ data: value, index: index }}
          onPressEdit={props.onPressEdit}
          onPressEditSubMenu={props.onPressEditSubMenu}
          onPressRemove={props.onPressRemove}
        />
      ))}
    </div>
  );
});

const SortableBumpItem = SortableElement((props) => {
  return (
    <div className="generatedList">
      <div className="generatedListItem">
        <p className="generatedListAns">{props.value.data.name}</p>
        {props.value.data.order_type && (
          <p className="generatedDesc">{props.value.data.order_type}</p>
        )}

        <button
          className="btn btn-info btn-sm editAnswer"
          onClick={props.onPressEdit}
          data-value={props.value.index}>
          Edit
        </button>

        <button
          className="btn btn-danger btn-sm removeGeneratedList"
          onClick={props.onPressRemove}
          data-value={props.value.index}>
          Remove
        </button>
      </div>
    </div>
  );
});

export const SortableBumpItemList = SortableContainer((props) => {
  return (
    <div>
      {props.items.map((value, index) => (
        <SortableBumpItem
          key={`item-${index}`}
          index={index}
          value={{ data: value, index: index }}
          onPressEdit={props.onPressEdit}
          onPressRemove={props.onPressRemove}
        />
      ))}
    </div>
  );
});

const SortableCategoryArticle = SortableElement((props) => {
  return (
    <div className="generatedList">
      <div className="generatedListItem">
        <p className="generatedListAns">{props.value.data.name}</p>
        <p className="generatedDesc">{props.value.data.id}</p>
        {props.onPressSort && (
          <button
            className="btn btn-info btn-sm editAnswer"
            onClick={props.onPressSort}
            data-value={props.value.index}>
            Sort Articles
          </button>
        )}
      </div>
    </div>
  );
});

export const SortableCategoryArticleList = SortableContainer((props) => {
  return (
    <div>
      {props.items.map((value, index) => (
        <SortableCategoryArticle
          key={`item-${index}`}
          index={index}
          value={{ data: value, index: index }}
          onPressSort={props.onPressSort}
        />
      ))}
    </div>
  );
});

const SortableMenuItem = SortableElement((props) => {
  return (
    <div className="generatedList">
      <div className="generatedListItem">
        <p className="generatedListAns">{props.value.data.label}</p>
        <p className="generatedDesc">{props.value.index + 1}</p>
      </div>
    </div>
  );
});

export const SortableMenuList = SortableContainer((props) => {
  return (
    <div>
      {props.items.map((value, index) => (
        <SortableMenuItem
          key={`item-${index}`}
          index={index}
          value={{ data: value, index: index }}
        />
      ))}
    </div>
  );
});

const SortableTaskImageItem = SortableElement((props) => {
  return (
    <div className="generatedList">
      <div className="generatedListItem">
        <p className="generatedDesc">
          <img
            src={imageUrl(props.value.data.file)}
            alt=""
            width={100}
            height={100}
            className="articleSideThumb img-responsive"
          />
        </p>
        <div className="gap10"></div>
        <p className="generatedListAns">{props.value.data.description}</p>
        <button
          className="btn btn-danger btn-sm removeGeneratedList"
          onClick={props.onPressRemove}
          data-value={props.value.index}>
          Remove
        </button>
      </div>
    </div>
  );
});

export const SortableTaskImageList = SortableContainer((props) => {
  return (
    <div>
      {props.items.map((value, index) => (
        <SortableTaskImageItem
          key={`item-${index}`}
          index={index}
          value={{ data: value, index: index }}
          onPressRemove={props.onPressRemove}
        />
      ))}
    </div>
  );
});

const SortableTaskStatusItem = SortableElement((props) => {
  return (
    <div className="generatedList">
      <div className="generatedListItem">
        <p className="generatedListAns firstLetter">{props.value.data.name}</p>
        <p className="generatedDesc">
          Allow customer to select: {props.value.data.select ? "Yes" : "No"}
        </p>
        <p className="generatedDesc">
          Default: {props.value.data.default ? "Yes" : "No"}
        </p>
        <button
          className="btn btn-info btn-sm editStep"
          onClick={props.onPressEdit}
          data-value={props.value.index}>
          Edit
        </button>
        <button
          className="btn btn-danger btn-sm removeGeneratedList"
          onClick={props.onPressRemove}
          data-value={props.value.index}>
          Remove
        </button>
      </div>
    </div>
  );
});

export const SortableTaskStatusList = SortableContainer((props) => {
  return (
    <div>
      {props.items.map((value, index) => (
        <SortableTaskStatusItem
          key={`item-${index}`}
          index={index}
          value={{ data: value, index: index }}
          onPressEdit={props.onPressEdit}
          onPressRemove={props.onPressRemove}
        />
      ))}
    </div>
  );
});

const SortableTaskReportFieldItem = SortableElement((props) => {
  return (
    <div className="generatedList">
      <div className="generatedListItem">
        <p className="generatedListAns firstLetter">{props.value.data.name}</p>
        <p className="generatedDesc firstLetter">
          Field : {props.value.data.field}
        </p>
        <button
          className="btn btn-info btn-sm editStep"
          onClick={props.onPressEdit}
          data-value={props.value.index}>
          Edit
        </button>
        <button
          className="btn btn-danger btn-sm removeGeneratedList"
          onClick={props.onPressRemove}
          data-value={props.value.index}>
          Remove
        </button>
      </div>
    </div>
  );
});

export const SortableTaskReportFieldList = SortableContainer((props) => {
  return (
    <div>
      {props.items.map((value, index) => (
        <SortableTaskReportFieldItem
          key={`item-${index}`}
          index={index}
          value={{ data: value, index: index }}
          onPressEdit={props.onPressEdit}
          onPressRemove={props.onPressRemove}
        />
      ))}
    </div>
  );
});

const SortableTaskRuleItem = SortableElement((props) => {
  const data = props.value.data;
  const fieldList = props.value.fieldList || [];
  /*eslint-disable*/
  const typeMap = {
    assigned_to_user: "Assigned To User",
    select_field: "Field",
    new_task_created: "New Task Created",
    form_completed: "Form Completed",
    on_task_comment: "On Task Comment",
    task_opened_assigned_user: "Task Opened By Assigned User",
    unassigned_user: "Unassigned User",
  };
  const actionMap = {
    create_invoice: "Create Invoice",
    send_user_email: "Send User Email",
    send_user_sms: "Send User SMS",
    send_staff_email: "Send Staff Email",
    update_status: "Update Status",
    send_email: "Send Email",
    send_sms: "Send SMS",
  };
  const fromMap = {
    user: "From User",
    staff: "From Staff",
  };
  const fromToMap = {
    assigned_user: "To Assigned User",
  };
  const conditionMap = {
    is_equal: "Is Equal",
    is_not_equal: "Is Not Equal",
    is_changed: "Is Changed",
    instantly: "Instantly",
    if_not_opened: "If Not Opened",
  };
  const thenMap = {
    assign_form: "Assign Form",
    update_status: "Update Status",
    update_incoming_invoice: "Update Bill Field",
    update_outgoing_invoice: "Update Invoice Field",
  };
  /*eslint-enable*/
  let field = "";
  if (data.field) {
    if (data.fieldType === "normal") {
      field = data.field;
    } else {
      let d = fieldList.find((d) => d.value === data.field);
      if (d) {
        field = d.name;
      }
    }
  }

  const arrow = <i className="fa fa-long-arrow-right" />;

  return (
    <div className="generatedList">
      <div className="generatedListItem">
        <p className="generatedListAns firstLetter">
          {typeMap[data.type]}
          {data.from ? arrow : ""} {data.from ? fromMap[data.from] : ""}
          {data.staffUserId ? arrow : ""} {data.staffUserName}
          {data.fromTo ? arrow : ""} {data.fromTo ? fromToMap[data.fromTo] : ""}
          {data.action ? arrow : ""} {data.action ? actionMap[data.action] : ""}{" "}
          {field ? arrow : ""} {field} {data.condition ? arrow : ""}
          {data.condition ? conditionMap[data.condition] : ""}{" "}
          {data.value ? arrow : ""} {data.value ? data.value : ""}{" "}
          {data.then ? arrow : ""} {data.then ? thenMap[data.then] : ""}{" "}
          {data.invoiceField ? arrow : ""}{" "}
          {data.invoiceField ? data.invoiceField : ""}{" "}
          {data.invoiceFieldValue ? arrow : ""}{" "}
          {data.invoiceFieldValue ? data.invoiceFieldValue : ""}{" "}
          {data.status ? arrow : ""} {data.status ? data.status : ""}
        </p>
        <p className="generatedDesc">{data.type}</p>
        <button
          className="btn btn-info btn-sm editStep"
          onClick={props.onPressEdit}
          data-value={props.value.index}>
          Edit
        </button>
        <button
          className="btn btn-danger btn-sm removeGeneratedList"
          onClick={props.onPressRemove}
          data-value={props.value.index}>
          Remove
        </button>
      </div>
    </div>
  );
});

export const SortableTaskRuleList = SortableContainer((props) => {
  return (
    <div>
      {props.items.map((value, index) => (
        <SortableTaskRuleItem
          key={`item-${index}`}
          index={index}
          value={{ data: value, index: index, fieldList: props.fieldList }}
          onPressEdit={props.onPressEdit}
          onPressRemove={props.onPressRemove}
        />
      ))}
    </div>
  );
});
