/* eslint jsx-a11y/anchor-is-valid: 0 */

import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import $ from "jquery";
import CSVReader from "react-csv-reader";
import TitleBox from "../../../components/general/titleBox";
import Checkbox from "../../../components/form/checkBox";
import TagSelect from "../../../components/form/tagSelect";

import {
  callGetSystemTags,
  callCreateImportUser,
  callGetImportUsersUsingQuery,
  callUpdateImportUser,
  callDeleteImportUser,
  callDeleteAllImportUser,
} from "../../../services";
import { validateEmail, showNotification } from "../../../helpers";
import { getUniqueId } from "../../../packages/mp-content-editor/helpers";
import { callGetSettings } from "../../../services/settingService";

class ImportUsers extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      csvUsers: [],
      users: [],
      keyCheckbox: Math.random(),
      ignoreFirstRow: true,
      keyAddTag: Math.random(),
      selectedTags: [],
      systemTags: [],
      pendingUsers: [],
      failedUsers: [],
      existingUsers: 0,
      newUsers: 0,
      selectedUser: {},
      editName: "",
      editEmailAddress: "",
      type: "1",
      keyType: Math.random(),
      headerValues: {},
      fields: [],
    };
  }

  componentDidMount() {
    this.getSystemTags();
    this.getUserStats();
    this.getCustomFields();
  }

  getSystemTags() {
    callGetSystemTags().then((d) => {
      this.setState({ systemTags: d.data, keyAddTag: Math.random() });
    });
  }

  getCustomFields() {
    callGetSettings("customfields").then((d) => {
      let fields = d.data.custom_fields || [];
      this.setState({
        fields: fields,
      });
    });
  }

  getUserStats() {
    let query = {
      query: { status: { $in: ["pending", "failed"] } },
    };

    callGetImportUsersUsingQuery(query).then((d) => {
      let pendingUsers = d.data.filter((e) => {
        return e.status === "pending";
      });

      let failedUsers = d.data.filter((e) => {
        return e.status === "failed";
      });

      this.setState({ failedUsers: failedUsers, pendingUsers: pendingUsers });
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  handleSelectChange(event, i) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;

    let headerValues = this.state.headerValues;
    headerValues[i] = value;
    this.setState({ headerValues });
  }

  handleUsers = (users) => {
    this.setState({ users: users, csvUsers: users }, () => {
      this.checkForUsers();
    });
  };

  async checkForUsers() {
    let check = this.state.ignoreFirstRow;
    let csvUsers = this.state.csvUsers;
    let users = this.state.users;

    if (check === true) {
      if (csvUsers.length > 1) {
        users = csvUsers.slice(1, csvUsers.length);
      }
    } else {
      users = csvUsers;
      users = users.slice(0, users.length - 1);
    }

    this.setState({ users: users });
  }

  toggleCheckbox = (check) => {
    this.setState(
      {
        ignoreFirstRow: check,
      },
      () => {
        this.checkForUsers();
      }
    );
  };

  onChangeAddTags = (tags) => {
    this.setState({ selectedTags: tags });
  };

  onSelectType(item, id) {
    this.setState({ [id]: item[Object.keys(item)[0]].value });
  }

  onClickEditUser(user) {
    this.setState(
      {
        selectedUser: user,
        editName: user.name,
        editEmailAddress: user.email_address,
      },
      () => {
        $(".modalAlertOverlay.editModalOverlay,.modalAlert.editAlert").fadeIn(
          200
        );
      }
    );
  }

  onClickDeleteAllFailedUser = (users) => {
    this.setState({ selectedUser: users }, () => {
      $(
        ".modalAlertOverlay.deleteAllUserModal,.modalAlert.deleteAllUserAlert"
      ).fadeIn(200);
    });
  };

  onClickDeleteFailedUser = (user) => {
    this.setState({ selectedUser: user }, () => {
      $(
        ".modalAlertOverlay.deleteUserModal,.modalAlert.deleteUserAlert"
      ).fadeIn(200);
    });
  };

  onClickCloseFromEditModal() {
    $(".modalAlertOverlay.editModalOverlay,.modalAlert.editAlert").fadeOut(200);
  }

  async onClickSubmitFromEditModal() {
    if (this.state.editName.trim().length === 0) {
      showNotification("error", "Name is required", 4000);
      return;
    }

    if (this.state.editEmailAddress.trim().length === 0) {
      showNotification("error", "Email is required", 4000);
      return;
    }

    if (!validateEmail(this.state.editEmailAddress)) {
      showNotification("error", "Email is not valid", 4000);
      return;
    }

    /*eslint-disable*/
    let data = {
      name: this.state.editName,
      email_address: this.state.editEmailAddress,
      status: "pending",
    };
    /*eslint-enable*/

    let updatedUser = await callUpdateImportUser(
      this.state.selectedUser._id,
      data
    );
    if (updatedUser._id) {
      showNotification("success", "User entry updated successfully");
      this.setState(
        { selectedUser: {}, editName: "", editEmailAddress: "" },
        () => {
          this.getUserStats();
        }
      );
    }
  }

  renderEditUserModal() {
    if (!this.state.selectedUser._id) {
      return null;
    }
    return (
      <div>
        <div className="modalAlertOverlay editModalOverlay" />
        <div className="modalAlert editAlert">
          <div className="clearfix">
            <div className="pull-left">
              <p>Edit User</p>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div
                  className={
                    this.state.editName !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Name</label>
                  <input
                    type="text"
                    name="editName"
                    value={this.state.editName}
                    onChange={(event) => this.handleInputChange(event)}
                  />
                </div>
                <div className="gap10" />
              </div>
              <div className="col-sm-12">
                <div
                  className={
                    this.state.editEmailAddress !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Email Address</label>
                  <input
                    type="text"
                    name="editEmailAddress"
                    value={this.state.editEmailAddress}
                    onChange={(event) => this.handleInputChange(event)}
                  />
                </div>
                <div className="gap10" />
              </div>
            </div>
          </div>
          <div className="gap10" />

          <div className="alertFooterBtns">
            <a
              onClick={this.onClickCloseFromEditModal.bind(this)}
              className="btn btn-default dismissThisModal margin-right-10">
              Close
            </a>

            <a
              onClick={this.onClickSubmitFromEditModal.bind(this)}
              className="btn btn-primary dismissThisModal">
              Submit
            </a>
          </div>
        </div>
      </div>
    );
  }

  onClickNoAlert = () => {
    $(".modalAlertOverlay.deleteUserModal,.modalAlert.deleteUserAlert").fadeOut(
      200
    );
  };

  onClickYesAlert = () => {
    callDeleteImportUser(this.state.selectedUser._id).then((d) => {
      $(
        ".modalAlertOverlay.deleteUserModal,.modalAlert.deleteUserAlert"
      ).fadeOut(200);
      showNotification("success", "User deleted successfully");
      this.getUserStats();
    });
  };

  onClickAllNoAlert = () => {
    $(
      ".modalAlertOverlay.deleteAllUserModal,.modalAlert.deleteAllUserAlert"
    ).fadeOut(200);
  };

  onClickAllYesAlert = () => {
    let ids = [];
    this.state.failedUsers.forEach((id) => {
      ids.push(id._id);
    });

    callDeleteAllImportUser({ id: ids }).then((d) => {
      $(
        ".modalAlertOverlay.deleteAllUserModal,.modalAlert.deleteAllUserAlert"
      ).fadeOut(200);
      showNotification(
        "success",
        "All failed users have been deleted successfully"
      );
      this.getUserStats();
    });
  };

  renderDeleteConfirmationAlert() {
    let selectedUser = this.state.selectedUser;
    let email = "";
    if (selectedUser.email_address) {
      email = selectedUser.email_address;
    }
    return (
      <div>
        <div className="modalAlertOverlay deleteUserModal" />
        <div className="modalAlert deleteUserAlert">
          <p>{`Are you sure you want to delete "${email}" user?`}</p>
          <div className="alertFooterBtns">
            <a
              className="btn btn-default closeModal-No margin-right-10"
              onClick={this.onClickNoAlert}>
              No
            </a>
            <a
              className="btn btn-primary closeModal-Yes"
              onClick={this.onClickYesAlert}>
              Yes
            </a>
          </div>
        </div>
      </div>
    );
  }

  renderDeleteAllConfirmationAlert() {
    return (
      <div>
        <div className="modalAlertOverlay deleteAllUserModal" />
        <div className="modalAlert deleteAllUserAlert">
          <p>{"Are you sure you want to delete all failed users?"}</p>
          <div className="alertFooterBtns">
            <a
              className="btn btn-default closeModal-No margin-right-10"
              onClick={this.onClickAllNoAlert}>
              No
            </a>
            <a
              className="btn btn-primary closeModal-Yes"
              onClick={this.onClickAllYesAlert}>
              Yes
            </a>
          </div>
        </div>
      </div>
    );
  }

  renderFailedUsers() {
    let users = this.state.failedUsers;
    let returnVals = [];
    for (let i = 0; i < users.length; i++) {
      const element = users[i];
      returnVals.push(
        <tr key={element._id}>
          <td>{i + 1}</td>
          <td>{element.name}</td>
          <td>{element.email_address}</td>
          <td>
            <a
              onClick={this.onClickEditUser.bind(this, element)}
              className="btn btn-default btn-sm border-0">
              <i className="fa fa-pencil" />
            </a>
            <a
              onClick={this.onClickDeleteFailedUser.bind(this, element)}
              className="btn btn-danger btn-sm border-0">
              <i className="fa fa-trash" />
            </a>
          </td>
        </tr>
      );
    }
    return returnVals;
  }

  renderCsvUsers() {
    let users = this.state.users;
    let returnVals = [];
    for (let i = 0; i < users.length; i++) {
      const element = users[i];
      returnVals.push(
        <tr key={i}>
          <td>{i + 1}</td>
          {element.map((j) => (
            <td key={getUniqueId()}>{j}</td>
          ))}
        </tr>
      );
    }
    return returnVals;
  }

  onPressImportUsers() {
    let users = this.state.users;
    let apiData = [];
    let headerValues = this.state.headerValues;
    let customFields = this.state.fields;

    for (let i = 0; i < users.length; i++) {
      const element = users[i];
      /*eslint-disable*/
      let obj = { custom_admin_fields: {} };
      /*eslint-enable*/
      for (let j = 0; j < element.length; j++) {
        if (headerValues[j] !== "ignore") {
          obj["status"] = "pending";
          obj[headerValues[j]] = element[j];
          if (obj[headerValues[j]] === "email_address") {
            if (validateEmail(element[j])) {
              obj["status"] = "pending";
            } else {
              obj["status"] = "failed";
            }
          }
          let field = customFields.find((d) => d.id === headerValues[j]);
          if (field) {
            obj["custom_admin_fields"][headerValues[j]] = element[j];
          }
        }
      }
      obj["tag_list"] = this.state.selectedTags.map((i) => i._id);
      apiData.push(obj);
    }

    apiData.forEach((element) => {
      if (element.first_name) {
        element.name = element.first_name;
      }
      if (element.last_name) {
        if (element.name) {
          element.name = element.name + " " + element.last_name;
        } else {
          element.name = element.last_name;
        }
      }
      delete element.first_name;
      delete element.last_name;
    });

    callCreateImportUser(apiData).then((d) => {
      showNotification("info", "Import Users Process Done", 4000);
      this.setState({
        selectedTags: [],
        keyTag: Math.random(),
        users: [],
        csvUsers: [],
        ignoreFirstRow: true,
        keyAddTag: Math.random(),
        keyCheckbox: Math.random(),
      });
      $("#csvReader").val("");
      this.getUserStats();
    });
  }

  renderHeaders() {
    let users = this.state.users;
    let leUsers = 0;
    if (users.length > 0) {
      leUsers = users[0].length;
    }
    let returnVals = [<th>No</th>];
    for (let i = 0; i < leUsers; i++) {
      returnVals.push(
        <th>
          <select
            name={"header" + i}
            value={this.state.headerValues[i]}
            onChange={(event) => this.handleSelectChange(event, i)}>
            <option value="">Select Field</option>
            <option value="name">Name</option>
            <option value="first_name">First Name</option>
            <option value="last_name">Last Name</option>
            <option value="email_address">Email Address</option>
            <option value="phone_number">Phone Number</option>
            <option value="address">Address</option>
            <option value="address2">Address 2</option>
            <option value="city">City</option>
            <option value="state">State</option>
            <option value="zip">Zip</option>
            <option value="quickbook_id">Quickbook ID</option>
            <option value="ignore">Ignore</option>
            {this.state.fields.map((i) => (
              <option value={i.id}>{i.name}</option>
            ))}
          </select>
        </th>
      );
    }
    return returnVals;
  }

  render() {
    let users = this.state.failedUsers;
    return (
      <div>
        {this.renderDeleteConfirmationAlert()}
        {this.renderDeleteAllConfirmationAlert()}
        {this.renderEditUserModal()}
        <div className="container-fluid">
          <TitleBox title="Import Users" />
          <div className="gap20" />

          {(this.state.pendingUsers.length > 0 ||
            this.state.failedUsers.length > 0) && (
            <div className="card">
              <div className="container-fluid">
                <div className="gap20" />
                <div className="alert alert-danger" role="alert">
                  Please note it takes a few minutes for users to import. Wait
                  until all users are imported before sending out any emails or
                  sms messages.
                </div>
                <div className="gap10" />
                <p>
                  Pending Users : <b>{this.state.pendingUsers.length}</b>
                </p>
                <p>
                  Failed Users : <b>{this.state.failedUsers.length}</b>
                  {this.state.failedUsers.length > 0 && (
                    <a
                      onClick={this.onClickDeleteAllFailedUser.bind(
                        this,
                        users
                      )}
                      className="btn btn-danger btn-sm border-0">
                      <i className="fa fa-trash" />
                    </a>
                  )}
                </p>

                {this.state.failedUsers.length > 0 && (
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>{this.renderFailedUsers()}</tbody>
                  </table>
                )}
              </div>
            </div>
          )}

          <div className="card">
            <div className="container-fluid">
              <div className="gap20" />
              <h5 className="noMargin">Select CSV</h5>
              <div className="gap10" />
              <CSVReader
                inputId={"csvReader"}
                cssClass="react-csv-input"
                onFileLoaded={this.handleUsers}
              />
              <div className="gap10" />
              <Checkbox
                label={"Ignore First Row"}
                isChecked={this.state.ignoreFirstRow}
                handleCheckboxChange={this.toggleCheckbox.bind(this)}
                key={this.state.keyCheckbox}
              />
              <div className="gap20" />
              <a
                href={`https://${window.location.hostname}/static/sample${this.state.type}.csv`}>
                Click here to download sample csv file.
              </a>
              <div className="gap20" />
            </div>
          </div>

          {this.state.users.length > 0 && (
            <div>
              <div className="card">
                <div className="container-fluid">
                  <div className="gap20" />
                  <h5 className="noMargin">CSV Users</h5>
                  <div className="gap20" />
                  <p>
                    Total Users : <b>{this.state.users.length}</b>
                  </p>
                  <div className="gap10" />
                  <table className="table table-bordered">
                    <thead>
                      <tr>{this.renderHeaders()}</tr>
                    </thead>
                    <tbody>{this.renderCsvUsers()}</tbody>
                  </table>
                </div>
              </div>

              <div className="card">
                <div className="container-fluid">
                  <div className="gap20" />
                  <h5 className="noMargin">Add Tags</h5>
                  <div className="gap20" />
                  <TagSelect
                    id={"import-users-add-tag"}
                    key={this.state.keyAddTag}
                    selectedValues={this.state.selectedTags}
                    searchKey={"name"}
                    selectKey={"name"}
                    btnText={"Create New Tag"}
                    lblText={"Select Tag"}
                    data={this.state.systemTags}
                    onChange={this.onChangeAddTags}
                    isClickable={true}
                    reload={() => {
                      this.getSystemTags();
                    }}
                  />
                </div>
              </div>

              <div className="text-right container-fluid">
                <button
                  className="btn btn-primary"
                  onClick={() => this.onPressImportUsers()}>
                  Import
                </button>
              </div>
              <div className="gap20" />
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ImportUsers);
