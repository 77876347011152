import Cookies from "js-cookie";
// import config from "./config";

function setCookie(key, value) {
  Cookies.set(
    key,
    value
    //   , {
    //   expires: 7,
    //   path: "",
    //   domain: config.urls.cookieUrl
    // }
  );
}

function getCookie(key) {
  return Cookies.get(key);
}

function removeCookie(key) {
  Cookies.remove(key);
}

export { setCookie, getCookie, removeCookie };
