import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import DatePicker from "react-date-picker";
import moment from "moment";

import { showNotification } from "../../helpers";

class SelectProduct extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    let from = this.props.from || "";

    let products = [{ name: "Marci's Circle", value: "marci-circle" }];

    if (from !== "setup") {
      products.push({ name: "Custom Program", value: "custom-program" });
    }

    this.state = {
      products: products,
      selectedProduct: "",
      productDate: new Date(),
      productStartType: "",
      selectedProducts: this.props.selectedProducts || [],
    };
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  onChangeProductDate(value) {
    this.setState({
      productDate: value,
    });
  }

  renderProducts() {
    let returnVals = [];
    let products = this.state.products;
    returnVals.push(<option value={""} key={0} />);
    for (let i = 0; i < products.length; i++) {
      returnVals.push(
        <option value={products[i].value} key={i + 1}>
          {products[i].name}
        </option>
      );
    }
    return returnVals;
  }

  onClickAddProduct() {
    if (this.state.selectedProduct.trim().length === 0) {
      showNotification("error", "Please select a product", 4000);
      return;
    }

    if (this.state.productStartType.trim().length === 0) {
      showNotification("error", "Please select a product start type", 4000);
      return;
    }

    let currentDay = false;
    let productDate = "";

    if (this.state.productStartType === "current_day") {
      currentDay = true;
      productDate = moment().format("MM-DD-YYYY").toString();
    } else {
      productDate = moment(this.state.productDate)
        .format("MM-DD-YYYY")
        .toString();
    }

    let product = this.state.products.filter((d) => {
      return d.value === this.state.selectedProduct;
    });

    let productInfo = {
      name: product[0].name,
      productDate: productDate,
      currentDay: currentDay,
    };

    let selectedProducts = this.state.selectedProducts;
    selectedProducts.push(productInfo);
    this.setState(
      {
        selectedProduct: "",
        productStartType: "",
        selectedProducts: selectedProducts,
      },
      () => {
        this.props.onChange(this.state.selectedProducts);
      }
    );
  }

  onClickRemoveFromSelectedProducts(i) {
    let selectedProducts = this.state.selectedProducts;
    selectedProducts.splice(i, 1);
    this.setState(
      {
        selectedProducts: selectedProducts,
      },
      () => {
        this.props.onChange(this.state.selectedProducts);
      }
    );
  }

  renderSelectedProducts() {
    var returnVals = [];
    let selectedProducts = this.state.selectedProducts;
    for (let i = 0; i < selectedProducts.length; i++) {
      const element = selectedProducts[i];
      returnVals.push(
        <tr key={i}>
          <td className="col-sm-11">
            {element.name}
            {element.productDate !== "" && element.currentDay === false && (
              <span className="subtext"> ({element.productDate})</span>
            )}
            {element.productDate !== "" && element.currentDay === true && (
              <span className="subtext"> (Current Day)</span>
            )}
          </td>
          <td>
            <button
              className="text-danger"
              onClick={this.onClickRemoveFromSelectedProducts.bind(this, i)}>
              REMOVE
            </button>
          </td>
        </tr>
      );
    }
    return returnVals;
  }

  render() {
    return (
      <div>
        <div className="card">
          <div className="list-group list-group-flush">
            <div className="list-group-item">
              <div className="row">
                <div className="col-lg-4">
                  <div
                    className={
                      this.state.selectedProduct !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>Select Product</label>
                    <select
                      name="selectedProduct"
                      value={this.state.selectedProduct}
                      onChange={(event) => this.handleInputChange(event)}>
                      {this.renderProducts()}
                    </select>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div
                    className={
                      this.state.productStartType !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>Product Start Type</label>
                    <select
                      name="productStartType"
                      value={this.state.productStartType}
                      onChange={(event) => this.handleInputChange(event)}>
                      <option value="" />
                      <option value="current_day">Current day</option>
                      <option value="select_day">Select a day</option>
                    </select>
                  </div>
                </div>
                {this.state.productStartType === "select_day" && (
                  <div className="col-lg-4">
                    <label className="inputFakeLabelFocussed">
                      Select Date
                    </label>
                    <div>
                      <DatePicker
                        value={this.state.productDate}
                        onChange={this.onChangeProductDate.bind(this)}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="gap20" />
              <div className="text-right">
                <button
                  className="btn btn-primary"
                  onClick={this.onClickAddProduct.bind(this)}>
                  Add Product
                </button>
              </div>
              <div className="gap10" />
            </div>
          </div>
        </div>

        {this.state.selectedProducts.length > 0 && (
          <div className="card">
            <div className="list-group list-group-flush">
              <div className="list-group-item">
                <h5 className="noMargin">Added Products</h5>
                <div className="gap20" />
                <table className="table table-bordered">
                  <tbody>{this.renderSelectedProducts()}</tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SelectProduct);
