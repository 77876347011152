import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { arrayMove } from "react-sortable-hoc/dist/commonjs/utils";
import TitleBox from "../../../components/general/titleBox";
import RadioTag from "../../../components/form/radioTag";
import { SortableNavLinkList } from "../../../components/form/sortableList";
import {
  getParameterByName,
  openPagePopupModal,
  showNotification,
  slugify,
} from "../../../helpers";
import {
  callGetSettings,
  callSaveSettings,
} from "../../../services/settingService";
import {
  callGetEventsUsingQuery,
  callGetLandingPagesUsingQuery,
} from "../../../services";
import SelectAddImage from "../../../components/form/selectAddImage";
import PagePopup from "../../../components/popup/pagePopup";
import { VelocityTransitionGroup } from "velocity-react";
import Checkbox from "../../../components/form/checkBox";
import MaterialIcons from "../../../components/general/materialIcons";

class LandingMenuList extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      menuList: this.props.menuList || [],
      menuType: "landingpage",
      linkType: "text",
      subMenuType: "landingpage",
      menuUrl: "",
      subMenuLink: "",
      menuDescription: "",
      menuLabel: "",
      menuIcon: "",
      menuLandingPage: "",
      menuEvent: "",
      subMenus: [],
      subMenuLabel: "",
      subMenuDescription: "",
      subMenuUrl: "",
      sMenuType: "link",
      sSubMenuType: "landingpage",
      sMenuLandingPage: "",
      sMenuEvent: "",
      sSubMenuLink: "",
      landingPages: this.props.landingPages || [],
      events: this.props.events || [],
      settingId: this.props.settingId,
      popupType: "",
      popupLabel: "Edit Landing Page",
      editLandingPage: {},
      popupKey: Math.random(),
      showForm: false,
      theme: this.props.theme,
      selectedIcon: "",
      iconModalOpen: false,
    };
  }

  handleInputChange(event) {
    const target = event.target;
    var value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  onSortEndMenuList = ({ oldIndex, newIndex }) => {
    let menuList = this.state.menuList;
    menuList = arrayMove(menuList, oldIndex, newIndex);

    this.setState({
      menuList: menuList,
    });
    this.props.onChange(menuList, this.state.theme);
  };

  onClickCancelUpdateMenu() {
    this.setState({
      selectedMenuIndex: -1,
      menuLabel: "",
      menuUrl: "",
      menuDescription: "",
      selectedIcon: "",
      selectedMenuId: "",
      subMenus: [],
      menuLandingPage: "",
      showSubMenu: false,
      menuType: "submenu",
      linkType: "text",
      menuEvent: "",
      subMenuType: "landingpage",
      subMenuLink: "",
      keyMenuType: Math.random(),
      showForm: false,
    });
    this.onClickCancelUpdateSubMenu();
  }

  onPressEditMenu(type, e) {
    let index = e.target.dataset["value"];
    let menuList = this.state.menuList;

    let menu = menuList[index];

    this.setState({
      menuLabel: menu.label,
      menuUrl: menu.url || "",
      menuDescription: menu.description || "",
      selectedIcon: menu.icon,
      selectedMenuId: menu.id,
      selectedMenuIndex: index,
      subMenus: menu.subMenus,
      showSubMenu: type === "editSubmenu",
      menuType: menu.type || "submenu",
      linkType: menu.linkType || "text",
      menuLandingPage: menu.landingPage || "",
      menuEvent: menu.event || "",
      subMenuType: menu.subMenuType || "landingpage",
      subMenuLink: menu.landingPageUrl || "",
      keyMenuType: Math.random(),
      showForm: true,
    });
  }

  onPressRemoveMenu(e) {
    let index = e.target.dataset["value"];
    let menuList = this.state.menuList;
    menuList.splice(index, 1);
    this.setState({
      menuList: menuList,
    });
    this.props.onChange(menuList, this.state.theme);
  }

  onClickAddMenu() {
    let menuList = this.state.menuList;

    if (this.state.menuLabel.trim().length === 0) {
      showNotification("error", "Label is required", 4000);
      return;
    }

    let id = "m" + Math.random().toString(36).substr(2);

    let subMenus = this.state.subMenus;
    if (this.state.menuType !== "submenu") {
      subMenus = [];
    }

    let field = {
      label: this.state.menuLabel,
      url: this.state.menuUrl,
      description: this.state.menuDescription,
      id: id,
      icon: this.state.selectedIcon,
      subMenus: subMenus,
      type: this.state.menuType,
      linkType: this.state.linkType,
      landingPage: this.state.menuLandingPage,
      subMenuType: this.state.subMenuType,
      landingPageUrl: this.state.subMenuLink,
      event: this.state.menuEvent,
    };

    if (this.state.selectedMenuIndex >= 0) {
      field.id = this.state.selectedMenuId;
      menuList[this.state.selectedMenuIndex] = field;
    } else {
      menuList.push(field);
    }

    this.setState({
      selectedMenuIndex: -1,
      selectedMenuId: "",
      menuList: menuList,
      menuLabel: "",
      menuUrl: "",
      menuDescription: "",
      selectedIcon: "",
      subMenus: [],
      showSubMenu: false,
      menuLandingPage: "",
      menuType: "submenu",
      subMenuType: "landingpage",
      subMenuLink: "",
      menuEvent: "",
      keyMenuType: Math.random(),
      showForm: false,
    });
    this.props.onChange(menuList, this.state.theme);
  }

  onSortEndSubMenus = ({ oldIndex, newIndex }) => {
    let subMenus = this.state.subMenus;
    subMenus = arrayMove(subMenus, oldIndex, newIndex);

    this.setState({
      subMenus: subMenus,
    });
  };

  onPressRemoveSubMenu(e) {
    let index = e.target.dataset["value"];
    let subMenus = this.state.subMenus;
    subMenus.splice(index, 1);
    this.setState({
      subMenus: subMenus,
    });
  }

  onClickCancelUpdateSubMenu() {
    this.setState({
      subMenuLabel: "",
      subMenuDescription: "",
      subMenuUrl: "",
      selectedSubIcon: "",
      selectedSubMenuIndex: -1,
      selectedSubMenuId: "",
      sMenuType: "link",
      sMenuLandingPage: "",
      sMenuEvent: "",
      sSubMenuType: "landingpage",
      sSubMenuLink: "",
      keySubMenuType: Math.random(),
      showForm: false,
    });
  }

  onPressEditSubMenu(e) {
    let index = e.target.dataset["value"];
    let subMenus = this.state.subMenus;

    let subMenu = subMenus[index];

    this.setState({
      subMenuLabel: subMenu.label,
      subMenuDescription: subMenu.description || "",
      subMenuUrl: subMenu.url,
      selectedSubIcon: subMenu.icon,
      selectedSubMenuId: subMenu.id,
      selectedSubMenuIndex: index,
      sMenuType: subMenu.type || "link",
      sMenuLandingPage: subMenu.landingPage || "",
      sMenuEvent: subMenu.event || "",
      sSubMenuType: subMenu.subMenuType || "landingpage",
      sSubMenuLink: subMenu.landingPageUrl || "",
      keySubMenuType: Math.random(),
      showForm: true,
    });
  }

  onClickAddSubMenu() {
    let subMenus = this.state.subMenus;

    if (this.state.subMenuLabel.trim().length === 0) {
      showNotification("error", "Label is required", 4000);
      return;
    }

    let id = "sm" + Math.random().toString(36).substr(2);

    let field = {
      label: this.state.subMenuLabel,
      description: this.state.subMenuDescription,
      url: this.state.subMenuUrl,
      icon: this.state.selectedSubIcon,
      id: id,
      type: this.state.sMenuType,
      landingPage: this.state.sMenuLandingPage,
      event: this.state.sMenuEvent,
      subMenuType: this.state.sSubMenuType,
      landingPageUrl: this.state.sSubMenuLink,
    };

    if (this.state.selectedSubMenuIndex >= 0) {
      field.id = this.state.selectedSubMenuId;
      subMenus[this.state.selectedSubMenuIndex] = field;
    } else {
      subMenus.push(field);
    }

    this.setState({
      subMenus: subMenus,
      subMenuLabel: "",
      subMenuDescription: "",
      subMenuUrl: "",
      selectedSubIcon: "",
      selectedSubMenuIndex: -1,
      selectedSubMenuId: "",
      sMenuType: "link",
      sMenuLandingPage: "",
      sSubMenuType: "landingpage",
      sSubMenuLink: "",
      sMenuEvent: "",
      keySubMenuType: Math.random(),
      showForm: false,
    });
  }

  onSelectSubMenuType(item, id) {
    this.setState({ [id]: item[Object.keys(item)[0]].value });
  }

  onUpdateLandingPage() {
    // comment
  }

  async openLandingPage() {
    let landingPages = await callGetLandingPagesUsingQuery({
      query: { _id: this.state.menuLandingPage },
    });
    this.setState(
      {
        popupType: "editLandingPage",
        popupLabel: this.state.popupLabel,
        popupKey: Math.random(),
        editLandingPage: landingPages.data[0],
      },
      () => {
        openPagePopupModal("editLandingPage");
      }
    );
  }

  toggleSocialMedia = (check) => {
    let theme = this.state.theme;
    theme.socialMedia = check;
    this.setState({ theme });
    this.props.onChange(this.state.menuList, theme);
  };

  renderMenuListTab() {
    let types = [
      { name: "Custom", value: "landingpage" },
      { name: "Sub Menu", value: "submenu" },
      {
        name: "Direct Link",
        value: "link",
      },
    ];

    let linkTypes = [
      { name: "Text", value: "text" },
      { name: "Button", value: "button" },
    ];

    if (this.props.type === "footer") {
      types = [{ name: "Sub Menu", value: "submenu" }];
    }

    return (
      <>
        <PagePopup
          type={this.state.popupType}
          label={this.state.popupLabel}
          key={this.state.popupKey}
          landingPage={this.state.editLandingPage}
          onCreate={this.onUpdateLandingPage.bind(this)}
        />
        <div className="gap20" />
        <h5>{this.props.title}</h5>
        <div className="gap20" />
        {this.props.setting.socialMedia && (
          <Checkbox
            label={"Display Social Media"}
            isChecked={this.state.theme.socialMedia}
            handleCheckboxChange={this.toggleSocialMedia}
            key={this.state.keyUseTheme}
          />
        )}
        <div className="gap20" />
        <div className="text-left">
          <button
            className="btn btn-primary btn-sm firstLetter"
            onClick={() => {
              this.setState({ showForm: !this.state.showForm });
            }}>
            Create New {this.props.type} Item &nbsp;&nbsp;
            {this.state.showForm ? (
              <i className="fa fa-minus" />
            ) : (
              <i className="fa fa-plus" />
            )}
          </button>
        </div>
        <hr />
        <div className="gap10" />
        <VelocityTransitionGroup
          enter={{ animation: "slideDown" }}
          leave={{ animation: "slideUp" }}>
          {this.state.showForm && (
            <>
              {!this.state.showSubMenu && (
                <>
                  <div className="row">
                    <div className="col-md-6">
                      <div
                        className={
                          this.state.menuLabel !== ""
                            ? "mdInput mdFocussed"
                            : "mdInput"
                        }>
                        <label>Menu Label</label>
                        <input
                          type="text"
                          name="menuLabel"
                          maxLength={50}
                          value={this.state.menuLabel}
                          onChange={(event) => this.handleInputChange(event)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="gap10" />
                  <div className="radioBHolder">
                    <p>Type</p>
                    <div className="tabsHolder radioTabs">
                      <RadioTag
                        key={this.state.keyMenuType}
                        onCheckChange={this.onSelectSubMenuType.bind(this)}
                        labelList={types}
                        id={"menuType"}
                        selectedList={[this.state.menuType]}
                        selectType={"value"}
                      />
                    </div>
                  </div>
                  {this.state.menuType === "link" && (
                    <div className="row">
                      <div className="col-md-6">
                        <div
                          className={
                            this.state.menuUrl !== ""
                              ? "mdInput mdFocussed"
                              : "mdInput"
                          }>
                          <label>Menu Url</label>
                          <input
                            type="text"
                            name="menuUrl"
                            value={this.state.menuUrl}
                            onChange={(event) => this.handleInputChange(event)}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.menuType === "landingpage" && (
                    <>
                      <div className="gap20" />
                      <div className="radioBHolder">
                        <p>Sub Type</p>
                        <div className="tabsHolder radioTabs">
                          <RadioTag
                            onCheckChange={this.onSelectSubMenuType.bind(this)}
                            labelList={[
                              { name: "Landing Page", value: "landingpage" },
                              { name: "Event", value: "event" },
                              { name: "URL", value: "url" },
                            ]}
                            id={"subMenuType"}
                            selectedList={[this.state.subMenuType]}
                            selectType={"value"}
                          />
                        </div>
                      </div>
                      {this.state.subMenuType === "landingpage" && (
                        <div className="row">
                          <div className="col-md-6">
                            <div
                              className={
                                this.state.menuLandingPage !== ""
                                  ? "mdInput mdFocussed"
                                  : "mdInput"
                              }>
                              <label>Select Landing Page</label>
                              <select
                                name="menuLandingPage"
                                value={this.state.menuLandingPage}
                                onChange={(event) =>
                                  this.handleInputChange(event)
                                }>
                                <option value=""></option>
                                {this.state.landingPages.map((i) => (
                                  <option value={i._id} key={i._id}>
                                    {i.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="gap30"></div>
                            <button
                              className="btn btn-default btn-sm"
                              onClick={this.openLandingPage.bind(this)}>
                              Edit Content
                            </button>
                          </div>
                        </div>
                      )}
                      {this.state.subMenuType === "event" && (
                        <div className="row">
                          <div className="col-md-6">
                            <div
                              className={
                                this.state.menuEvent !== ""
                                  ? "mdInput mdFocussed"
                                  : "mdInput"
                              }>
                              <label>Select Event</label>
                              <select
                                name="menuEvent"
                                value={this.state.menuEvent}
                                onChange={(event) =>
                                  this.handleInputChange(event)
                                }>
                                <option value=""></option>
                                {this.state.events.map((i) => (
                                  <option value={i.event_id} key={i._id}>
                                    {i.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      )}
                      {this.state.subMenuType === "url" && (
                        <div className="row">
                          <div className="col-md-6">
                            <div
                              className={
                                this.state.subMenuLink !== ""
                                  ? "mdInput mdFocussed"
                                  : "mdInput"
                              }>
                              <label>Url</label>
                              <input
                                type="text"
                                name="subMenuLink"
                                value={this.state.subMenuLink}
                                onChange={(event) =>
                                  this.handleInputChange(event)
                                }
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                  <div className="gap20" />
                  <MaterialIcons
                    icon={this.state.selectedIcon}
                    onChange={(icon) => {
                      this.setState({ selectedIcon: icon });
                    }}
                  />
                  <div className="gap20" />
                  {this.state.menuType !== "submenu" && (
                    <div className="radioBHolder">
                      <p>Link Type</p>
                      <div className="tabsHolder radioTabs">
                        <RadioTag
                          key={this.state.keyMenuType}
                          onCheckChange={this.onSelectSubMenuType.bind(this)}
                          labelList={linkTypes}
                          id={"linkType"}
                          selectedList={[this.state.linkType]}
                          selectType={"value"}
                        />
                      </div>
                    </div>
                  )}
                </>
              )}

              <div className="gap20" />
              {this.state.showSubMenu && (
                <div className="card">
                  <div className="container-fluid">
                    <div className="gap20" />
                    <h5>Add Sub Menu</h5>
                    <div className="gap10" />
                    <div className="row">
                      <div className="col-md-6">
                        <div
                          className={
                            this.state.subMenuLabel !== ""
                              ? "mdInput mdFocussed"
                              : "mdInput"
                          }>
                          <label>SubMenu Label</label>
                          <input
                            type="text"
                            name="subMenuLabel"
                            maxLength={50}
                            value={this.state.subMenuLabel}
                            onChange={(event) => this.handleInputChange(event)}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="gap20" />
                    <div className="radioBHolder">
                      <p>Type</p>
                      <div className="tabsHolder radioTabs">
                        <RadioTag
                          key={this.state.keySubMenuType}
                          onCheckChange={this.onSelectSubMenuType.bind(this)}
                          labelList={[
                            { name: "Direct Link", value: "link" },
                            { name: "Custom", value: "landingpage" },
                          ]}
                          id={"sMenuType"}
                          selectedList={[this.state.sMenuType]}
                          selectType={"value"}
                        />
                      </div>
                    </div>
                    {this.state.sMenuType === "link" && (
                      <div className="row">
                        <div className="col-md-6">
                          <div
                            className={
                              this.state.subMenuUrl !== ""
                                ? "mdInput mdFocussed"
                                : "mdInput"
                            }>
                            <label>SubMenu Url</label>
                            <input
                              type="text"
                              name="subMenuUrl"
                              value={this.state.subMenuUrl}
                              onChange={(event) =>
                                this.handleInputChange(event)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {this.state.sMenuType === "landingpage" && (
                      <>
                        <div className="gap20" />
                        <div className="radioBHolder">
                          <p>Sub Type</p>
                          <div className="tabsHolder radioTabs">
                            <RadioTag
                              onCheckChange={this.onSelectSubMenuType.bind(
                                this
                              )}
                              labelList={[
                                { name: "Landing Page", value: "landingpage" },
                                { name: "Event", value: "event" },
                                { name: "URL", value: "url" },
                              ]}
                              id={"sSubMenuType"}
                              selectedList={[this.state.sSubMenuType]}
                              selectType={"value"}
                            />
                          </div>
                        </div>
                        {this.state.sSubMenuType === "landingpage" && (
                          <div className="row">
                            <div className="col-md-6">
                              <div
                                className={
                                  this.state.sMenuLandingPage !== ""
                                    ? "mdInput mdFocussed"
                                    : "mdInput"
                                }>
                                <label>Select Landing Page</label>
                                <select
                                  name="sMenuLandingPage"
                                  value={this.state.sMenuLandingPage}
                                  onChange={(event) =>
                                    this.handleInputChange(event)
                                  }>
                                  <option value=""></option>
                                  {this.state.landingPages.map((i) => (
                                    <option value={i._id} key={i._id}>
                                      {i.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                        )}
                        {this.state.sSubMenuType === "event" && (
                          <div className="row">
                            <div className="col-md-6">
                              <div
                                className={
                                  this.state.sMenuEvent !== ""
                                    ? "mdInput mdFocussed"
                                    : "mdInput"
                                }>
                                <label>Select Event</label>
                                <select
                                  name="sMenuEvent"
                                  value={this.state.sMenuEvent}
                                  onChange={(event) =>
                                    this.handleInputChange(event)
                                  }>
                                  <option value=""></option>
                                  {this.state.events.map((i) => (
                                    <option value={i.event_id} key={i._id}>
                                      {i.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                        )}
                        {this.state.sSubMenuType === "url" && (
                          <div className="row">
                            <div className="col-md-6">
                              <div
                                className={
                                  this.state.sSubMenuLink !== ""
                                    ? "mdInput mdFocussed"
                                    : "mdInput"
                                }>
                                <label>Url</label>
                                <input
                                  type="text"
                                  name="sSubMenuLink"
                                  value={this.state.sSubMenuLink}
                                  onChange={(event) =>
                                    this.handleInputChange(event)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                    <div className="gap20" />
                    <div className="text-right">
                      {this.state.selectedSubMenuIndex > -1 && (
                        <button
                          className="btn btn-default margin-right-10"
                          onClick={this.onClickCancelUpdateSubMenu.bind(this)}>
                          Cancel
                        </button>
                      )}
                      <button
                        className="btn btn-primary"
                        onClick={this.onClickAddSubMenu.bind(this)}>
                        {this.state.selectedSubMenuIndex > -1
                          ? "Update Sub Menu"
                          : "Add Sub Menu"}
                      </button>
                      <div className="gap20" />
                    </div>
                    <SortableNavLinkList
                      distance={10}
                      iconType={"material"}
                      items={this.state.subMenus}
                      onSortEnd={this.onSortEndSubMenus}
                      onPressEdit={this.onPressEditSubMenu.bind(this)}
                      onPressRemove={this.onPressRemoveSubMenu.bind(this)}
                    />
                    <div className="gap20" />
                  </div>
                </div>
              )}
              <div className="gap20" />
              <div className="text-right">
                {this.state.selectedMenuIndex > -1 && (
                  <button
                    className="btn btn-default margin-right-10"
                    onClick={this.onClickCancelUpdateMenu.bind(this)}>
                    Cancel
                  </button>
                )}
                <button
                  className="btn btn-primary"
                  onClick={this.onClickAddMenu.bind(this)}>
                  {this.state.selectedMenuIndex > -1
                    ? "Update Menu"
                    : "Add Menu"}
                </button>
                <div className="gap20" />
              </div>
            </>
          )}
        </VelocityTransitionGroup>
        <SortableNavLinkList
          distance={10}
          type={"menu"}
          items={this.state.menuList}
          iconType={"material"}
          onSortEnd={this.onSortEndMenuList}
          onPressEdit={this.onPressEditMenu.bind(this, "edit")}
          onPressEditSubMenu={this.onPressEditMenu.bind(this, "editSubmenu")}
          onPressRemove={this.onPressRemoveMenu.bind(this)}
        />
        <div className="gap20" />
      </>
    );
  }

  render() {
    return (
      <div className="card">
        <div className="container-fluid">{this.renderMenuListTab()}</div>
      </div>
    );
  }
}

class LandingWebsiteMenuPage extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    let selectedTab = "theme";

    let tab = getParameterByName("tab");
    if (tab) {
      selectedTab = tab;
    }

    let tabOptions = [
      { label: "Theme", key: "theme", selected: selectedTab === "theme" },
      {
        label: "Navigation Bar",
        key: "header",
        selected: selectedTab === "header",
      },
      { label: "Footer", key: "footer", selected: selectedTab === "footer" },
      {
        label: "Branding",
        key: "branding",
        selected: selectedTab === "branding",
      },
    ];

    this.state = {
      selectedTab,
      tabOptions: tabOptions,
      logoImage: "",
      keyLogoImage: Math.random(),
      menuList: [],
      footerList: [],
      landingPages: [],
      events: [],
      domainList: [],
      settingId: "",
      keyCmp: Math.random(),
      keyFooter: Math.random(),
      selectedTheme: "simple",
      keySelectedTheme: Math.random(),
      headerTheme: {
        socialMedia: false,
      },
      footerTheme: {
        socialMedia: false,
      },
      twitter: "",
      facebook: "",
      youtube: "",
      instagram: "",
      defaultLandingPage: "",
      themeSetting: {
        header: {
          socialMedia: true,
        },
        footer: {
          socialMedia: true,
        },
      },
      themeBranding: {
        simple: {
          navBarAlwaysShow: true,
          navBarBackColor: "#3e8da8",
          navBarFontColor: "#ffffff",
          footerBackColor: "#24262b",
          footerFontColor: "#ffffff",
          footerCategoryBarColor: "#e91e63",
          navBarButtonBgColor: "#ffffff",
          navBarButtonFontColor: "#ffffff",
          navBarFont: "Poppins",
          footerFont: "Poppins",
        },
        "minimal-nav": {
          navBarFontColor: "#ffffff",
          footerBackColor: "#24262b",
          footerFontColor: "#ffffff",
          footerCategoryBarColor: "#e91e63",
          navBarButtonBgColor: "#ffffff",
          navBarButtonFontColor: "#ffffff",
          navBarFont: "Poppins",
          footerFont: "Poppins",
        },
      },
    };
  }

  componentDidMount() {
    this.getSettings();
  }

  async getSettings() {
    let d = await callGetSettings("");

    /*eslint-disable*/
    let landingPages = await callGetLandingPagesUsingQuery({
      query: {
        $select: ["name", "_id", "url"],
        status: { $ne: "archive" },
        $or: [{ sub_id: "" }, { sub_id: { $exists: false } }],
      },
    });
    /*eslint-enable*/
    this.setState({ landingPages: landingPages.data });

    let events = await callGetEventsUsingQuery({
      query: {
        $select: ["name", "_id", "event_id"],
        status: { $ne: "archive" },
      },
    });
    this.setState({ events: events.data });

    let data = d.data;
    let domainList = data["business_details"][0]["domain_list"];

    if (data) {
      this.setState({
        domainList: domainList,
        settingId: data._id,
      });
      let landingPage = data.landing_page;
      if (landingPage) {
        let branding = landingPage.branding || {};
        let theme = landingPage.theme || {};
        let themeBranding = theme.branding || this.state.themeBranding;
        if (!themeBranding["minimal-nav"]) {
          themeBranding["minimal-nav"] = {
            navBarAlwaysShow: true,
            navBarBackColor: "#3e8da8",
            navBarFontColor: "#ffffff",
            footerBackColor: "#24262b",
            footerFontColor: "#ffffff",
            footerCategoryBarColor: "#e91e63",
          };
        }

        this.setState({
          menuList: landingPage.menu_list,
          footerList: landingPage.footer_list || [],
          keyCmp: Math.random(),
          keyFooter: Math.random(),
          logoImage: branding.logo_image || "",
          twitter: branding.twitter || "",
          facebook: branding.facebook || "",
          instagram: branding.instagram || "",
          youtube: branding.youtube || "",
          keyLogoImage: Math.random(),
          selectedTheme: theme.name || "simple",
          defaultLandingPage: branding.default_landing_page || "",
          headerTheme: theme.header || {
            socialMedia: true,
          },
          footerTheme: theme.footer || {
            socialMedia: false,
          },
          keySelectedTheme: Math.random(),
          themeBranding: themeBranding,
        });
      }
    }
  }

  async onClickSave() {
    /*eslint-disable*/
    let data = {
      id: this.state.settingId,
      type: "landing_page",
      landing_page: {
        menu_list: this.state.menuList,
        footer_list: this.state.footerList,
        branding: {
          logo_image: this.state.logoImage,
          twitter: this.state.twitter,
          facebook: this.state.facebook,
          instagram: this.state.instagram,
          youtube: this.state.youtube,
          default_landing_page: this.state.defaultLandingPage,
        },
        theme: {
          name: this.state.selectedTheme,
          header: this.state.headerTheme,
          footer: this.state.footerTheme,
          branding: this.state.themeBranding,
        },
      },
    };
    /*eslint-enable*/
    let updatedData = await callSaveSettings(data);
    if (updatedData.status) {
      showNotification("success", "Data updated", 3000);
    } else {
      showNotification("error", updatedData.message, 5000);
    }
  }

  toggleAlwaysShowNavBar = (check) => {
    let themeBranding = this.state.themeBranding;
    themeBranding[this.state.selectedTheme]["navBarAlwaysShow"] = check;
    this.setState({
      themeBranding,
    });
  };

  onClickTab(tab) {
    let tabOptions = this.state.tabOptions;
    tabOptions.forEach((element) => {
      element.selected = element.key === tab;
    });
    this.setState({
      tabOptions,
      selectedTab: tab,
    });
  }

  renderTabOptions() {
    let tabOptions = this.state.tabOptions;
    let returnVals = [];
    for (let i = 0; i < tabOptions.length; i++) {
      const element = tabOptions[i];
      const dataTab = `.for-tab${i + 1}`;
      /*eslint-disable*/
      returnVals.push(
        <a
          className={element.selected ? "active" : ""}
          onClick={() => this.onClickTab(element.key)}
          data-for={dataTab}>
          {element.label}
        </a>
      );
      /*eslint-enable*/
    }
    return returnVals;
  }

  onChangeLogoImage = (image) => {
    this.setState({ logoImage: image });
  };

  onSelectType(item, id) {
    let value = item[Object.keys(item)[0]].value;
    this.setState({ [id]: value, keySelectedTheme: Math.random() });
  }

  handleInputChange(event) {
    const target = event.target;
    var value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  handleThemeItemChange(event) {
    const target = event.target;
    var value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    let themeBranding = this.state.themeBranding;
    themeBranding[this.state.selectedTheme][name] = value;
    this.setState({
      themeBranding,
    });
  }

  onResetColor(name) {
    let colors = {
      navBarBackColor: "#3e8da8",
      navBarFontColor: "#ffffff",
      footerBackColor: "#24262b",
      footerFontColor: "#ffffff",
      footerCategoryBarColor: "#e91e63",
      navBarButtonBgColor: "#ffffff",
      navBarButtonFontColor: "#ffffff",
      navBarFont: "Poppins",
      footerFont: "Poppins",
    };
    let themeBranding = this.state.themeBranding;
    themeBranding[this.state.selectedTheme][name] = colors[name];
    this.setState({
      themeBranding,
    });
  }

  onSelectDefaultLandingPage = (event) => {
    this.setState({ defaultLandingPage: event.target.value });
  };

  getPreviewLink() {
    let landingUrl = "";
    let domainList = this.state.domainList;
    let landingUrls = domainList.filter((d) => {
      return d.type === "landing";
    });
    let url = slugify(this.state.defaultLandingPage);

    let defaultDomain = "";
    domainList.forEach((domain) => {
      if (domain.type === "default") {
        defaultDomain = domain.domain;
      }
    });
    let subDomain = defaultDomain.split(".")[0] || "";

    landingUrl = `https://site.memberpages.app/${subDomain}/${url}`;
    if (landingUrls.length > 0) {
      landingUrl = `https://${landingUrls[0].domain}/${url}`;
    }

    return landingUrl;
  }

  onPressPreview() {
    let landingUrl = this.getPreviewLink();
    window.open(landingUrl, "_blank");
  }

  renderBrandingTab() {
    return (
      <div className="container-fluid">
        <div className="card">
          <div className="container-fluid">
            <div className="gap20" />
            <SelectAddImage
              type="site-app-logo"
              backColor={"white"}
              key={this.state.keyLogoImage}
              label="Site Logo"
              onChangeImage={this.onChangeLogoImage}
              image={this.state.logoImage}
            />
            <div className="gap20" />
            <label>Social Media</label>
            <div className="row">
              <div className="col-md-6">
                <div
                  className={
                    this.state.facebook !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Facebook</label>
                  <input
                    type="text"
                    name="facebook"
                    value={this.state.facebook}
                    onChange={(event) => this.handleInputChange(event)}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div
                  className={
                    this.state.twitter !== "" ? "mdInput mdFocussed" : "mdInput"
                  }>
                  <label>Twitter</label>
                  <input
                    type="text"
                    name="twitter"
                    value={this.state.twitter}
                    onChange={(event) => this.handleInputChange(event)}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div
                  className={
                    this.state.instagram !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Instagram</label>
                  <input
                    type="text"
                    name="instagram"
                    value={this.state.instagram}
                    onChange={(event) => this.handleInputChange(event)}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div
                  className={
                    this.state.youtube !== "" ? "mdInput mdFocussed" : "mdInput"
                  }>
                  <label>Youtube</label>
                  <input
                    type="text"
                    name="youtube"
                    value={this.state.youtube}
                    onChange={(event) => this.handleInputChange(event)}
                  />
                </div>
              </div>
            </div>
            <div className="gap20" />
          </div>
        </div>
      </div>
    );
  }

  renderFonts() {
    return (
      <>
        <option value="Actor">Actor</option>
        <option value="Alex Brush">Alex Brush</option>
        <option value="Arizonia">Arizonia</option>
        <option value="Assistant">Assistant</option>
        <option value="Barlow">Barlow</option>
        <option value="Bungee Spice">Bungee Spice</option>
        <option value="Cabin">Cabin</option>
        <option value="Cedarville Cursive">Cedarville Cursive</option>
        <option value="Cinzel">Cinzel</option>
        <option value="DM Sans">DM Sans</option>
        <option value="Epilogue">Epilogue</option>
        <option value="Exo">Exo</option>
        <option value="Faustina">Faustina</option>
        <option value="Fira Sans">Fira Sans</option>
        <option value="Gelasio">Gelasio</option>
        <option value="Glegoo">Glegoo</option>
        <option value="Heebo">Heebo</option>
        <option value="Hind">Hind</option>
        <option value="IBM Plex Sans">IBM Plex Sans</option>
        <option value="Inconsolata">Inconsolata</option>
        <option value="Kanit">Kanit</option>
        <option value="Karla">Karla</option>
        <option value="Kaushan Script">Kaushan Script</option>
        <option value="Lato">Lato</option>
        <option value="Lobster">Lobster</option>
        <option value="Lora">Lora</option>
        <option value="Lustria">Lustria</option>
        <option value="Merriweather">Merriweather</option>
        <option value="Mirza">Mirza</option>
        <option value="Montserrat">Montserrat</option>
        <option value="Mulish">Mulish</option>
        <option value="Nanum Gothic">Nanum Gothic</option>
        <option value="Noto Sans Devanagari">Noto Sans Devanagari</option>
        <option value="Noto Sans NKo">Noto Sans N’Ko</option>
        <option value="Nunito">Nunito</option>
        <option value="Nunito Sans">Nunito Sans</option>
        <option value="Open Sans">Open Sans</option>
        <option value="Outfit">Outfit</option>
        <option value="Overlock">Overlock</option>
        <option value="Overpass">Overpass</option>
        <option value="Ovo">Ovo</option>
        <option value="Oxygen">Oxygen</option>
        <option value="Pacifico">Pacifico</option>
        <option value="Philosopher">Philosopher</option>
        <option value="Play">Play</option>
        <option value="Playfair Display">Playfair Display</option>
        <option value="Poppins">Poppins</option>
        <option value="Quicksand">Quicksand</option>
        <option value="Raleway">Raleway</option>
        <option value="Roboto">Roboto</option>
        <option value="Rubik">Rubik</option>
        <option value="Salsa">Salsa</option>
        <option value="Sofia">Sofia</option>
        <option value="Sofia Sans Condensed">Sofia Sans Condensed</option>
        <option value="Source Sans Pro">Source Sans Pro</option>
        <option value="Suranna">Suranna</option>
        <option value="Tajawal">Tajawal</option>
        <option value="Titillium Web">Titillium Web</option>
        <option value="Ubuntu">Ubuntu</option>
      </>
    );
  }

  render() {
    return (
      <div className="container-fluid">
        <TitleBox title="Website" />
        <div className="gap20" />
        <div className="alert alert-info">
          Your website is created from landing pages. You can use the content
          below to select the themes, colors and pages.{" "}
          <a
            href="https://helpdesk.memberpages.app/?key=h3sf345hf32"
            target="_blank"
            rel="noopener noreferrer">
            Click here to view the helpdesk for additional information.
          </a>
        </div>
        <div className="card">
          <div>
            <div className="profileTab">
              <div className="modalHeaderTabs">{this.renderTabOptions()}</div>
            </div>
            <div className="gap10" />
          </div>
          {this.state.selectedTab === "theme" && (
            <div
              className={
                this.state.selectedTab === "theme"
                  ? "active for-tab1"
                  : "for-tab1"
              }>
              <div className="container-fluid">
                <div className="card">
                  <div className="container-fluid">
                    <div className="gap20" />
                    <div className="radioBHolder">
                      <p>Select Theme</p>
                      <div className="tabsHolder radioTabs">
                        <RadioTag
                          key={this.state.keySelectedTheme}
                          onCheckChange={this.onSelectType.bind(this)}
                          labelList={[
                            { name: "Theme 1", value: "simple" },
                            { name: "Theme 2", value: "minimal-nav" },
                          ]}
                          id={"selectedTheme"}
                          selectedList={[this.state.selectedTheme]}
                          selectType={"value"}
                        />
                      </div>
                    </div>
                    <div className="gap10" />

                    <div className="row">
                      <div className="col-sm-4">
                        <div
                          className={
                            this.state.selectedSplitPage !== ""
                              ? "mdInput mdFocussed"
                              : "mdInput"
                          }>
                          <label>Navigation Bar Font</label>
                          <select
                            className="actionSelect"
                            name="navBarFont"
                            value={
                              this.state.themeBranding[
                                this.state.selectedTheme
                              ]["navBarFont"]
                            }
                            onChange={(event) =>
                              this.handleThemeItemChange(event)
                            }>
                            {this.renderFonts()}
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="gap20" />
                    {this.state.selectedTheme === "simple" && (
                      <>
                        <Checkbox
                          label={"Always Show Navigation Bar "}
                          isChecked={
                            this.state.themeBranding[this.state.selectedTheme][
                              "navBarAlwaysShow"
                            ]
                          }
                          handleCheckboxChange={this.toggleAlwaysShowNavBar}
                          key={this.state.keySelectedTheme}
                        />
                        <div className="gap30" />
                      </>
                    )}
                    {this.state.selectedTheme === "simple" && (
                      <p className="bgSetter">
                        <input
                          type="color"
                          name="navBarBackColor"
                          value={
                            this.state.themeBranding[this.state.selectedTheme][
                              "navBarBackColor"
                            ]
                          }
                          onChange={(event) =>
                            this.handleThemeItemChange(event)
                          }
                        />
                        Navigation Bar Background Color &nbsp;
                        <label
                          className="link"
                          onClick={() => this.onResetColor("navBarBackColor")}>
                          (Reset)
                        </label>
                      </p>
                    )}
                    <p className="bgSetter">
                      <input
                        type="color"
                        name="navBarFontColor"
                        value={
                          this.state.themeBranding[this.state.selectedTheme][
                            "navBarFontColor"
                          ]
                        }
                        onChange={(event) => this.handleThemeItemChange(event)}
                      />
                      Navigation Bar Font Color &nbsp;
                      <label
                        className="link"
                        onClick={() => this.onResetColor("navBarFontColor")}>
                        (Reset)
                      </label>
                    </p>
                    <p className="bgSetter">
                      <input
                        type="color"
                        name="navBarButtonBgColor"
                        value={
                          this.state.themeBranding[this.state.selectedTheme][
                            "navBarButtonBgColor"
                          ]
                        }
                        onChange={(event) => this.handleThemeItemChange(event)}
                      />
                      Navigation Bar Button Background Color &nbsp;
                      <label
                        className="link"
                        onClick={() =>
                          this.onResetColor("navBarButtonBgColor")
                        }>
                        (Reset)
                      </label>
                    </p>
                    <p className="bgSetter">
                      <input
                        type="color"
                        name="navBarButtonFontColor"
                        value={
                          this.state.themeBranding[this.state.selectedTheme][
                            "navBarButtonFontColor"
                          ]
                        }
                        onChange={(event) => this.handleThemeItemChange(event)}
                      />
                      Navigation Bar Button Font Color &nbsp;
                      <label
                        className="link"
                        onClick={() =>
                          this.onResetColor("navBarButtonFontColor")
                        }>
                        (Reset)
                      </label>
                    </p>
                    <div className="row">
                      <div className="col-sm-4">
                        <div
                          className={
                            this.state.selectedSplitPage !== ""
                              ? "mdInput mdFocussed"
                              : "mdInput"
                          }>
                          <label>Footer Font</label>
                          <select
                            className="actionSelect"
                            name="footerFont"
                            value={
                              this.state.themeBranding[
                                this.state.selectedTheme
                              ]["footerFont"]
                            }
                            onChange={(event) =>
                              this.handleThemeItemChange(event)
                            }>
                            {this.renderFonts()}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="gap20" />
                    <p className="bgSetter">
                      <input
                        type="color"
                        name="footerBackColor"
                        value={
                          this.state.themeBranding[this.state.selectedTheme][
                            "footerBackColor"
                          ]
                        }
                        onChange={(event) => this.handleThemeItemChange(event)}
                      />
                      Footer Background Color &nbsp;
                      <label
                        className="link"
                        onClick={() => this.onResetColor("footerBackColor")}>
                        (Reset)
                      </label>
                    </p>
                    <p className="bgSetter">
                      <input
                        type="color"
                        name="footerFontColor"
                        value={
                          this.state.themeBranding[this.state.selectedTheme][
                            "footerFontColor"
                          ]
                        }
                        onChange={(event) => this.handleThemeItemChange(event)}
                      />
                      Footer Font Color &nbsp;
                      <label
                        className="link"
                        onClick={() => this.onResetColor("footerFontColor")}>
                        (Reset)
                      </label>
                    </p>
                    <p className="bgSetter">
                      <input
                        type="color"
                        name="footerCategoryBarColor"
                        value={
                          this.state.themeBranding[this.state.selectedTheme][
                            "footerCategoryBarColor"
                          ]
                        }
                        onChange={(event) => this.handleThemeItemChange(event)}
                      />
                      Footer Category Bar Color &nbsp;
                      <label
                        className="link"
                        onClick={() =>
                          this.onResetColor("footerCategoryBarColor")
                        }>
                        (Reset)
                      </label>
                    </p>
                  </div>
                </div>
                <div className="card">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-6">
                        <div
                          className={
                            this.state.defaultLandingPage !== "" &&
                            this.state.defaultLandingPage !== undefined
                              ? "mdInput mdFocussed"
                              : "mdInput"
                          }>
                          <label>Default Landing Page</label>
                          <select
                            name="defaultLandingPage"
                            value={this.state.defaultLandingPage}
                            onChange={this.onSelectDefaultLandingPage.bind(
                              this
                            )}>
                            <option value=""></option>
                            {this.state.landingPages.map((i) => (
                              <option value={i.url}>{i.name}</option>
                            ))}
                          </select>
                        </div>
                        <div className="gap20" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="gap20" />
              </div>
            </div>
          )}
          {this.state.selectedTab === "branding" && (
            <div
              className={
                this.state.selectedTab === "branding"
                  ? "active for-tab2"
                  : "for-tab2"
              }>
              {this.renderBrandingTab()}
            </div>
          )}

          {this.state.selectedTab === "header" && (
            <div
              className={
                this.state.selectedTab === "header"
                  ? "active for-tab4"
                  : "for-tab4"
              }>
              <div className="container-fluid">
                <LandingMenuList
                  key={this.state.keyCmp}
                  type={"header"}
                  title={"Header Menu List"}
                  menuList={this.state.menuList}
                  landingPages={this.state.landingPages}
                  events={this.state.events}
                  settingId={this.state.settingId}
                  theme={this.state.headerTheme}
                  setting={this.state.themeSetting.header}
                  onChange={(menuList, theme) => {
                    this.setState({ menuList: menuList, headerTheme: theme });
                  }}
                />
              </div>
            </div>
          )}

          {this.state.selectedTab === "footer" && (
            <div
              className={
                this.state.selectedTab === "footer"
                  ? "active for-tab3"
                  : "for-tab3"
              }>
              <div className="container-fluid">
                <LandingMenuList
                  key={this.state.keyFooter}
                  type={"footer"}
                  title={"Footer Menu List"}
                  menuList={this.state.footerList}
                  landingPages={this.state.landingPages}
                  events={this.state.events}
                  settingId={this.state.settingId}
                  theme={this.state.footerTheme}
                  setting={this.state.themeSetting.footer}
                  onChange={(menuList, theme) => {
                    this.setState({ footerList: menuList, footerTheme: theme });
                  }}
                />
              </div>
            </div>
          )}

          <div className="container-fluid">
            <div className="text-right">
              {this.state.defaultLandingPage && (
                <button
                  className="btn btn-default margin-right-10"
                  onClick={() => this.onPressPreview()}>
                  Preview
                </button>
              )}
              <button
                className="btn btn-primary"
                onClick={this.onClickSave.bind(this)}>
                Save
              </button>
              <div className="gap20" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LandingWebsiteMenuPage);
