import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "react-router-redux";
//import * as Sentry from "@sentry/browser";

import * as serviceWorker from "./serviceWorker";

import store, { history } from "./modules/store";
import App from "./app";
import { registerServiceWorker } from "./helpers/pwa";

// const host = window.location.host;
//const isSentry = host.includes("localhost") || host.includes("192.168");

//  if (!isSentry) {
//   Sentry.init({
//     dsn: "https://b5920e0a8f264e729cb242ce22eb7988@sentry.io/1382687",
//   });
//  }

registerServiceWorker(serviceWorker);

const target = document.querySelector("#root");

render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <div>
        <App store={store} history={history} />
      </div>
    </ConnectedRouter>
  </Provider>,
  target
);
