import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import TitleBox from "../../../components/general/titleBox";
import {
  getParameterByName,
  getUniqueId,
  showNotification,
  slugify,
  toPromise,
} from "../../../helpers";
import {
  callAddMemberTag,
  callCreateEmailSequence,
  callCreateSystemTag,
  callGetEmailSequenceUsingId,
  callGetEmails,
  callGetSystemTags,
  callGetUsersByQuery,
  callUpdateEmailSequence,
} from "../../../services";
import PageContent from "../../../components/editor/pageContent";
import AutoSuggest from "../../../components/form/autoSuggest";
import RadioTag from "../../../components/form/radioTag";
import moment from "moment-timezone";
import ChallengeOperations from "../challenge/challengeOpts";
import SearchableUserSelect from "../../../components/form/searchableUserSelect";

class AddEmailSequencePage extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    let item = {
      id: 0,
      name: "",
      rules: [],
    };

    let itemId = getParameterByName("id");
    if (itemId) {
      item.id = itemId;
    }

    let selectedTab = "rules";
    let tab = getParameterByName("tab") || this.props.tab;
    if (tab) {
      selectedTab = tab;
    }

    let tabOptions = [
      { label: "Rules", key: "rules", selected: selectedTab === "rules" },
      { label: "Users", key: "users", selected: selectedTab === "users" },
    ];

    this.state = {
      selectedTab,
      tabOptions: tabOptions,
      id: item.id,
      name: item.name,
      rules: item.rules,
      isRuleLoading: false,
      ruleType: "",
      onSpecificDay: "",
      actionType: "",
      tagType: "existing",
      keyTagType: Math.random(),
      tagValue: "",
      tagSelected: {},
      keyTag: Math.random(),
      tagName: "",
      tags: [],
      emailNotiName: "",
      emailNotiContent: {},
      emailNotiHtml: "",
      keyPageContent: Math.random(),
      keyContent: Math.random(),
      selectedTimeType: "",
      selectedHour: "",
      selectedMins: "",
      selectedTimeIndex: -1,
      selectedDay: "",
      selectedMonth: moment().format("MM"),
      selectedYear: moment().format("YYYY"),
      sendTimeType: "send",
      tagId: "",
      tagAddName: "",
      users: [],
      selectedUser: { value: "", label: "" },
      keySelectUser: Math.random(),
    };
  }

  componentDidMount() {
    this.getSystemTags();
    if (this.state.id) {
      this.getEmailSequence();
    }
  }

  async getEmailSequence() {
    var item = await callGetEmailSequenceUsingId(this.state.id);
    if (item._id) {
      let rules = item.rules || [];
      this.setState({ isRuleLoading: true });

      rules = await this.prepareRules(rules);

      this.setState(
        {
          id: item._id,
          name: item.name,
          tagId: item.tag_id,
          tagAddName: item.tag_name,
          rules: rules,
          isRuleLoading: false,
        },
        () => {
          this.getUsers();
        }
      );
    }
  }

  async getUsers() {
    let query = {
      query: {
        "tag_list.id": this.state.tagId,
        "tag_list.status": "active",
        $select: ["_id", "name", "email_address"],
        populate: "no",
      },
    };
    const response = await callGetUsersByQuery(query);
    this.setState({ users: response.data });
  }

  async getSystemTags() {
    let d = await callGetSystemTags();
    this.setState({ tags: d.data, keyAddTag: Math.random() });
  }

  async prepareRules(rawRules) {
    let rules = [];
    let atSignup = rawRules.filter((d) => d.type === "at_signup");
    let onSpecificDay = rawRules.filter((d) => d.type === "on_specific_day");

    onSpecificDay.sort((a, b) => {
      return parseInt(a.day) - parseInt(b.day);
    });

    rules = rules.concat(atSignup);
    rules = rules.concat(onSpecificDay);

    let eRules = [];

    for (let i = 0; i < rules.length; i++) {
      let element = rules[i];
      if (element.email_linked) {
        let response = await callGetEmails(
          {
            query: {
              _id: element.email_linked,
            },
          },
          false
        );
        if (response.data.length > 0) {
          element.emailName = response.data[0].name;
        }
      }
      eRules.push(element);
    }

    return eRules;
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  onSelectTagType(item, id) {
    this.setState({ [id]: item[Object.keys(item)[0]].value });
  }

  onChangeTagValue(value) {
    this.setState({
      tagValue: value,
    });
  }

  onSelectTagValue(value) {
    this.setState({
      tagSelected: value,
    });
  }

  onChangeNotiEmailContent(html, content) {
    this.setState({
      emailNotiContent: content,
      emailNotiHtml: html,
    });
  }

  resetProps() {
    this.setState({
      id: 0,
      name: "",
      rules: [],
    });
  }

  onSelectSendTime(item, id) {
    this.setState({ [id]: item[Object.keys(item)[0]].value });
  }

  renderDays() {
    var returnVals = [];
    for (let i = 1; i <= 31; i++) {
      let day = i.toString().length !== 1 ? i : `0${i}`;
      returnVals.push(
        <option value={day} key={day}>
          {day}
        </option>
      );
    }
    return returnVals;
  }

  renderMonths() {
    var returnVals = [];
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    for (let i = 1; i <= 12; i++) {
      let month = i.toString().length !== 1 ? i : `0${i}`;
      returnVals.push(
        <option value={month} key={month}>
          {monthNames[i - 1]}
        </option>
      );
    }
    return returnVals;
  }

  renderYears() {
    var returnVals = [
      <option value={"2022"} key={"2022"}>
        {"2022"}
      </option>,
      <option value={"2023"} key={"2023"}>
        {"2023"}
      </option>,
      <option value={"2024"} key={"2024"}>
        {"2024"}
      </option>,
      <option value={"2025"} key={"2025"}>
        {"2025"}
      </option>,
    ];
    return returnVals;
  }

  renderHours() {
    var returnVals = [];
    for (let i = 1; i <= 12; i++) {
      let hour = i.toString().length !== 1 ? i : `0${i}`;
      returnVals.push(
        <option value={hour} key={hour}>
          {hour}
        </option>
      );
    }
    return returnVals;
  }

  renderMins() {
    var returnVals = [];
    for (let i = 0; i <= 45; i = i + 15) {
      let min = i.toString().length !== 1 ? i : `0${i}`;
      returnVals.push(
        <option value={min} key={min}>
          {min}
        </option>
      );
    }
    return returnVals;
  }

  renderSendTime() {
    return (
      <>
        <div className="gap10" />
        <div className="radioBHolder">
          <p>Send Time</p>
          <div className="tabsHolder radioTabs">
            <RadioTag
              onCheckChange={this.onSelectSendTime.bind(this)}
              labelList={[
                {
                  name: "Immediately",
                  value: "send",
                },
                { name: "Specified Time", value: "time" },
              ]}
              id={"sendTimeType"}
              selectedList={[this.state.sendTimeType]}
              selectType={"value"}
              key={this.state.keySendTime}
            />
          </div>
        </div>
        {this.state.sendTimeType === "time" && (
          <>
            <div className="row">
              <div className="col-sm-3">
                <div
                  className={
                    this.state.selectedHour !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Select Hour</label>
                  <select
                    name="selectedHour"
                    value={this.state.selectedHour}
                    onChange={(event) => this.handleInputChange(event)}>
                    <option value="" />
                    {this.renderHours()}
                  </select>
                </div>
                <div className="gap20" />
              </div>
              <div className="col-sm-3">
                <div
                  className={
                    this.state.selectedMins !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Select Minute</label>
                  <select
                    name="selectedMins"
                    value={this.state.selectedMins}
                    onChange={(event) => this.handleInputChange(event)}>
                    <option value="" />
                    {this.renderMins()}
                  </select>
                </div>
                <div className="gap20" />
              </div>
              <div className="col-sm-3">
                <div
                  className={
                    this.state.selectedTimeType !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Select Type</label>
                  <select
                    name="selectedTimeType"
                    value={this.state.selectedTimeType}
                    onChange={(event) => this.handleInputChange(event)}>
                    <option value="" />
                    <option value="AM">AM</option>
                    <option value="PM">PM</option>
                  </select>
                </div>
                <div className="gap20" />
              </div>
            </div>
          </>
        )}
      </>
    );
  }

  async checkActionType(tagId) {
    if (this.state.actionType !== "add-email") {
      if (this.state.tagValue.trim().length === 0) {
        showNotification("error", "Tag is required", 4000);
        return;
      }
    }

    let actionType = this.state.actionType;
    let emailId = "";
    let tagIdN = "";
    let tagValue = "";

    if (this.state.actionType === "add-email") {
      let dOpe = new ChallengeOperations();
      /*eslint-disable*/
      let response = await dOpe.createEmailSequenceRuleEmail(
        this.state.name,
        this.state.onSpecificDay,
        this.state.emailNotiName,
        this.state.emailNotiContent,
        this.state.emailNotiHtml,
        {
          send_time_type: this.state.sendTimeType,
          selected_hour: this.state.selectedHour,
          selected_mins: this.state.selectedMins,
          selected_time_type: this.state.selectedTimeType,
        }
      );
      /*eslint-enable*/
      tagValue = response.tagValue;
      tagIdN = response.tagId;
      emailId = response.emailId;
    } else {
      tagIdN = tagId[0]._id;
      tagValue = this.state.tagValue;
    }

    return { actionType, emailId, tagIdN, tagValue };
  }

  async onClickCreateTag() {
    let tagName = this.state.tagName.trim().toLowerCase();
    if (tagName.length === 0) {
      showNotification("error", "Tag name is required", 5000);
      return;
    }
    let existingTags = this.state.tags.filter((d) => {
      return d.name.toLowerCase() === tagName;
    });
    if (existingTags.length > 0) {
      showNotification("error", "Tag already exists", 4000);
      return;
    }
    // call api to create system tag
    /*eslint-disable*/
    let apiData = {
      name: tagName,
      description: tagName,
      rules: [
        {
          limit_tag_per_days: "1",
        },
      ],
      additional_rules: [],
      events: [],
      notifications: [],
      product_list: [],
      generic_tag_list: [],
      zoom_meeting: {
        user_id: "",
        meeting_id: "",
      },
      type: "automated",
      created_from: "email-sequence",
    };
    /*eslint-enable*/
    let newTag = await callCreateSystemTag(apiData);
    if (newTag._id) {
      // call api get system tags
      let tags = await callGetSystemTags();
      this.setState(
        {
          tagName: "",
          tags: tags.data,
          tagType: "existing",
          keyTag: Math.random(),
          keyTagType: Math.random(),
          tagValue: this.state.tagName,
        },
        () => {
          this.onClickAddRule();
        }
      );
    }
  }

  async onClickAddRule() {
    let rules = this.state.rules;
    let tagId = this.state.tags.filter((d) => {
      return d.name === this.state.tagValue;
    });
    if (!tagId) {
      showNotification("error", "Tag not found", 4000);
      return;
    }
    if (this.state.ruleType.trim().length === 0) {
      showNotification("error", "Rule type is required", 4000);
      return;
    }
    if (this.state.ruleType === "on_specific_day") {
      if (this.state.onSpecificDay.trim().length === 0) {
        showNotification("error", "Enter Day is required", 4000);
        return;
      }

      let { actionType, emailId, tagIdN, tagValue } =
        await this.checkActionType(tagId);

      rules.push({
        type: "on_specific_day",
        day: this.state.onSpecificDay,
        action: actionType,
        /*eslint-disable*/
        tag_id: tagIdN,
        tag_name: tagValue,
        email_linked: emailId,
        /*eslint-enable*/
      });
    } else if (this.state.ruleType === "at_signup") {
      let { actionType, emailId, tagIdN, tagValue } =
        await this.checkActionType(tagId);

      rules.push({
        type: this.state.ruleType,
        action: actionType,
        /*eslint-disable*/
        tag_id: tagIdN,
        tag_name: tagValue,
        email_linked: emailId,
        /*eslint-enable*/
      });
    }

    let newRules = await this.prepareRules(this.state.rules);

    this.setState(
      {
        rules: newRules,
        tagValue: "",
        keyTag: Math.random(),
        emailNotiContent: {},
        emailNotiHtml: "",
        emailNotiName: "",
      },
      () => {
        if (this.state.id !== 0) {
          this.onPressSave();
        }
      }
    );
  }

  async onPressSave() {
    if (this.state.name.trim().length === 0) {
      showNotification("error", "Name is required", 4000);
      return;
    }
    /*eslint-disable*/
    let apiData = {
      name: this.state.name,
      rules: this.state.rules,
    };

    if (this.state.id === 0) {
      let tagName = `email-seq-${slugify(this.state.name)}-${getUniqueId()}`;
      let chlgOpe = new ChallengeOperations();
      let tagId = await chlgOpe.createEmailSequenceSystemTag(tagName);
      apiData["tag_id"] = tagId;
      apiData["tag_name"] = tagName;
      let [err, data] = await toPromise(callCreateEmailSequence(apiData));

      if (err) {
        showNotification("error", err.message, 5000);
      } else {
        showNotification("success", "Created Successfully", 4000);
        this.resetProps();
      }
    } else {
      let [err, data] = await toPromise(
        callUpdateEmailSequence(this.state.id, apiData)
      );
      if (err) {
        showNotification("error", err.message, 5000);
      } else {
        showNotification("success", "Updated Successfully", 4000);
      }
    }
    /*eslint-enable*/
  }

  getSystemTagName(element) {
    if (element.emailName) {
      return element.emailName;
    }
    let tagList = this.state.tags.filter((d) => d._id === element.tag_id);
    if (tagList.length > 0) {
      return tagList[0].name;
    }
    return "";
  }

  onClickEditEmail = (email) => {
    let showBack = "y";
    this.props.history.push(
      `/admin/edit-email?eid=${email._id}&back=${showBack}`
    );
  };

  onClickDeleteRule(index) {
    let rules = this.state.rules;
    rules.splice(index - 1, 1);

    this.setState({ rules: rules }, () => {
      if (this.state.id !== 0) {
        this.onPressSave();
      }
    });
  }

  renderRules() {
    let rules = this.state.rules;
    let returnVals = [];
    let i = 0;

    const ButtonDelete = (props) => (
      <button
        onClick={this.onClickDeleteRule.bind(this, props.i)}
        className="btn btn-danger btn-sm border-0"
        style={{ float: "right" }}>
        <i className="fa fa-trash" />
      </button>
    );

    const ButtonEdit = (props) => (
      <button
        onClick={props.onClick}
        className="btn btn-default btn-sm border-0"
        style={{ float: "right" }}>
        <i className="fa fa-pencil" />
      </button>
    );

    if (rules.length > 0) {
      rules.forEach((element) => {
        i++;
        let labelTag = "";
        switch (element.action) {
          case "add-tag":
            labelTag = "Add A Tag";
            break;
          case "add-email":
            labelTag = "Add Email";
            break;
          default:
            labelTag = "Inactivate A Tag";
            break;
        }
        if (element.type === "on_specific_day") {
          returnVals.push(
            <div key={i}>
              On Day : {element.day}, {labelTag} :{" "}
              {this.getSystemTagName(element)}
              {element.lock !== true && <ButtonDelete i={i} />}
              {element.email_linked && (
                <ButtonEdit
                  onClick={this.onClickEditEmail.bind(this, {
                    _id: element.email_linked,
                  })}
                />
              )}
            </div>
          );
        } else if (element.type === "at_signup") {
          returnVals.push(
            <div key={i}>
              At Signup, {labelTag} : {this.getSystemTagName(element)}
              {element.lock !== true && <ButtonDelete i={i} />}
              {element.email_linked && (
                <ButtonEdit
                  onClick={this.onClickEditEmail.bind(this, {
                    _id: element.email_linked,
                  })}
                />
              )}
            </div>
          );
        }
      });
    }
    return returnVals;
  }

  async onClickAddUser() {
    if (this.state.selectedUser.value._id) {
      /*eslint-disable*/
      let apiData = {
        action: "add-member-tag",
        data: {
          member_id: this.state.selectedUser.value._id,
          tags: [this.state.tagId],
        },
      };
      /*eslint-enable*/
      await callAddMemberTag(apiData);
      this.getUsers();
    }
  }

  onClickEditUser = (user) => {
    if (user._id) {
      let url = `/admin/edit-user?id=${user._id}`;
      // window.open(url, "_blank");
      this.props.history.push(url);
    }
  };

  renderUsers() {
    var returnVals = [];
    let items = this.state.users || [];
    items.forEach((element) => {
      returnVals.push(
        <tr key={element._id}>
          <td>{element.name}</td>
          <td>{element.email_address}</td>
          <td className="min-width-100">
            <button
              title="Edit"
              onClick={this.onClickEditUser.bind(this, element)}
              className="btn btn-default btn-sm margin-right-10 border-0">
              <i className="fa fa-pencil" />
            </button>
          </td>
        </tr>
      );
    });
    return returnVals;
  }

  onSelectUser = (user) => {
    if (user) {
      this.setState({
        selectedUser: user,
      });
    }
  };

  renderUsersCmp() {
    return (
      <div className="container-fluid">
        <div className="gap20" />
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>{this.renderUsers()}</tbody>
        </table>
        <div className="gap10" />
        <div className="row">
          <div className="col-sm-4">
            <SearchableUserSelect
              key={this.state.keySelectUser}
              name={"selectedUser"}
              placeholder="Select A User"
              onSelect={this.onSelectUser}
              selected={this.state.selectedUser}></SearchableUserSelect>
          </div>
          <div className="col-sm-2">
            <div className="gap40" />
            <button
              title="Add user"
              onClick={this.onClickAddUser.bind(this)}
              className="btn btn-primary btn-sm">
              Add User
            </button>
          </div>
        </div>
        <div className="gap10" />
      </div>
    );
  }

  renderRulesCmp() {
    return (
      <>
        <div className="gap20" />
        <div className="card">
          <div className="container-fluid">
            <div className="gap20" />
            <div className="row">
              <div className="col-sm-4">
                <div
                  className={
                    this.state.ruleType !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Select Rule Type</label>
                  <select
                    className="selectRuleTypeInput"
                    name="ruleType"
                    value={this.state.ruleType}
                    onChange={(event) => this.handleInputChange(event)}>
                    <option value="" />
                    <option value="on_specific_day">On Day</option>
                    <option value="at_signup">At Signup</option>
                  </select>
                </div>
                <div className="gap20" />
              </div>
              {this.state.ruleType === "on_specific_day" && (
                <div className="col-sm-4">
                  <div
                    className={
                      this.state.onSpecificDay !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>Enter Day</label>
                    <input
                      type="text"
                      className="onDayInput"
                      name="onSpecificDay"
                      value={this.state.onSpecificDay}
                      onChange={(event) => this.handleInputChange(event)}
                    />
                  </div>
                  <div className="gap20" />
                </div>
              )}
              {this.state.ruleType !== "" && (
                <div className="col-sm-4">
                  <div
                    className={
                      this.state.actionType !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>Select Action</label>
                    <select
                      className="actionSelect"
                      name="actionType"
                      value={this.state.actionType}
                      onChange={(event) => this.handleInputChange(event)}>
                      <option value="" />
                      {/* <option value="add-tag">Add A Tag</option>
                      <option value="inactivate-tag">Inactivate A Tag</option> */}
                      <option value="add-email">Add an Email</option>
                    </select>
                  </div>
                  <div className="gap20" />
                </div>
              )}
            </div>
            <div className="gap20" />
            {this.state.actionType === "add-tag" && (
              <>
                <div className="tabsHolder radioTabs">
                  <RadioTag
                    onCheckChange={this.onSelectTagType.bind(this)}
                    labelList={[
                      { name: "Select Existing Tag", value: "existing" },
                      { name: "Create New Tag", value: "new" },
                    ]}
                    id={"tagType"}
                    selectedList={[this.state.tagType]}
                    selectType={"value"}
                    key={this.state.keyTagType}
                  />
                </div>
                {this.state.tagType === "existing" && (
                  <div
                    className={
                      this.state.tagValue !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>Search Tag</label>
                    <AutoSuggest
                      value={this.state.tagValue}
                      key={this.state.keyTag}
                      searchKey="name"
                      data={this.state.tags}
                      onChange={this.onChangeTagValue.bind(this)}
                      onSelect={this.onSelectTagValue.bind(this)}
                    />
                  </div>
                )}
                {this.state.tagType === "new" && (
                  <div className="row">
                    <div className="col-sm-4">
                      <div
                        className={
                          this.state.tagName !== ""
                            ? "mdInput mdFocussed"
                            : "mdInput"
                        }>
                        <label>Tag Name</label>
                        <input
                          type="text"
                          name="tagName"
                          value={this.state.tagName}
                          onChange={(event) => this.handleInputChange(event)}
                        />
                      </div>
                      <div className="gap20" />
                    </div>
                    <div className="col-sm-4">
                      <div className="gap30" />
                      <button
                        className="btn btn-sm btn-primary"
                        onClick={this.onClickCreateTag.bind(this)}>
                        Create
                      </button>
                    </div>
                  </div>
                )}
                {this.state.ruleType === "per_completed" && (
                  <p style={{ paddingTop: 10 }}>
                    Please make sure any tag that is added here is set to only
                    ever be added one time (you can set the days limit to 9999)
                    this way users will not get tags everytime they click the
                    completed boxes.
                  </p>
                )}
              </>
            )}

            {this.state.actionType === "add-email" && (
              <div>
                <div
                  className={
                    this.state.emailNotiName !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Email Name</label>
                  <input
                    type="text"
                    className="challengeName"
                    name="emailNotiName"
                    value={this.state.emailNotiName}
                    onChange={(event) => this.handleInputChange(event)}
                  />
                </div>
                <div className="gap20" />
                <PageContent
                  key={this.state.keyContent}
                  title={this.state.name}
                  displayMode={"email"}
                  previewType={"iframe"}
                  screen={"add-landing-page-notification-email"}
                  html={this.state.emailNotiHtml}
                  content={this.state.emailNotiContent}
                  editorType={"unlayer"}
                  onChangeData={this.onChangeNotiEmailContent.bind(this)}
                  pageName={"addLandingPageNotiEmail"}
                />
                <div className="gap10" />
                {this.renderSendTime()}
              </div>
            )}
            <div className="gap20" />
            <div className="text-right">
              <button
                className="btn btn-primary addRuleBtn"
                onClick={() => this.onClickAddRule()}>
                Add Rule
              </button>
            </div>
            <div className="gap20" />
          </div>
        </div>
        {this.state.rules.length > 0 && (
          <>
            <h5 className="noMargin">Added Rules</h5>
            <div className="gap20" />
            {this.state.isRuleLoading ? (
              <div className="addedRule">Loading ...</div>
            ) : (
              <div className="addedRule">{this.renderRules()}</div>
            )}

            <div className="gap20" />
          </>
        )}
      </>
    );
  }

  onClickTab(tab) {
    let tabOptions = this.state.tabOptions;
    tabOptions.forEach((element) => {
      element.selected = element.key === tab;
    });
    this.setState({
      tabOptions,
      selectedTab: tab,
    });
  }

  renderTabOptions() {
    let tabOptions = this.state.tabOptions;
    let returnVals = [];
    for (let i = 0; i < tabOptions.length; i++) {
      const element = tabOptions[i];
      const dataTab = `.for-tab${i + 1}`;
      /*eslint-disable*/
      returnVals.push(
        <a
          className={element.selected ? "active" : ""}
          onClick={() => this.onClickTab(element.key)}
          data-for={dataTab}>
          {element.label}
        </a>
      );
      /*eslint-enable*/
    }
    return returnVals;
  }

  render() {
    return (
      <div>
        <div className="container-fluid">
          <TitleBox
            title={
              this.state.id === 0 ? "Add Email Sequence" : "Edit Email Sequence"
            }
            showBackBtn={this.state.id !== 0}
          />

          <div className="gap20" />

          <div className="card">
            <div className="container-fluid">
              <div className="gap20" />
              <div
                className={
                  this.state.name !== "" ? "mdInput mdFocussed" : "mdInput"
                }>
                <label>Name</label>
                <input
                  type=""
                  className="challangeDesc"
                  name="name"
                  value={this.state.name}
                  onChange={(event) => this.handleInputChange(event)}
                />
              </div>
              <div className="gap20" />
            </div>
          </div>

          <div className="card">
            {this.state.id !== 0 && (
              <div className="profileTab">
                <div className="modalHeaderTabs">{this.renderTabOptions()}</div>
              </div>
            )}
            {this.state.selectedTab === "rules" && (
              <div
                className={
                  this.state.selectedTab === "rules"
                    ? "active for-tab1"
                    : "for-tab1"
                }>
                <div className="container-fluid">{this.renderRulesCmp()}</div>
              </div>
            )}
            {this.state.selectedTab === "users" && (
              <div
                className={
                  this.state.selectedTab === "users"
                    ? "active for-tab2"
                    : "for-tab2"
                }>
                <div className="container-fluid">{this.renderUsersCmp()}</div>
              </div>
            )}
          </div>

          {this.state.tagAddName && (
            <div className="card">
              <div className="container-fluid">
                <div className="gap20" />
                Give this tag to a user to receive this email sequence :{" "}
                <label style={{ fontStyle: "italic" }}>
                  {this.state.tagAddName}
                </label>
                <div className="gap10" />
              </div>
            </div>
          )}

          <div className="text-right">
            <button
              className="btn btn-primary"
              onClick={() => this.onPressSave()}>
              Save
            </button>
          </div>
          <div className="gap20" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddEmailSequencePage);
