import React from "react";
import { callDeleteTask } from "../../../../services";
import $ from "jquery";

class TaskDelete extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      taskId: this.props.taskId,
    };
  }

  onClickNoAlert = () => {
    $(".modalAlertOverlay.deleteModal,.modalAlert.deleteAlert").fadeOut(200);
  };

  onClickYesAlert = () => {
    callDeleteTask(this.state.taskId).then((d) => {
      $(".modalAlertOverlay.deleteModal,.modalAlert.deleteAlert").fadeOut(200);
      this.props.onDelete();
    });
  };

  renderDeleteConfirmationAlert() {
    return (
      <div>
        <div className="modalAlertOverlay deleteModal" />
        <div className="modalAlert deleteAlert">
          <p>Are you sure you want to delete this task ?</p>
          <div className="alertFooterBtns">
            <button
              className="btn btn-default closeModal-No margin-right-10"
              onClick={this.onClickNoAlert}>
              No
            </button>
            <button
              className="btn btn-primary closeModal-Yes"
              onClick={this.onClickYesAlert}>
              Yes
            </button>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return <div>{this.renderDeleteConfirmationAlert()}</div>;
  }
}
export default TaskDelete;
