import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import $ from "jquery";
import { VelocityTransitionGroup } from "velocity-react";

import ContentEditor from "./contentEditor";
import HelpTextDiv from "../../components/form/helpTextDiv";
import ContentHtml from "../general/contentHtml";
import {
  openContentEditor,
  closeContentEditor,
  isLocalSite,
  showNotification,
} from "../../helpers";
import { getItem, getStorageItem } from "../../helpers/storage";

class PageContent extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    let showContent = true;
    if ("showContent" in this.props) {
      showContent = this.props.showContent;
    }
    this.state = {
      label: this.props.label || "Page Content",
      description: this.props.description || "",
      title: this.props.title,
      screen: this.props.screen,
      displayMode: this.props.displayMode || "web",
      html: this.props.html || "",
      content: this.props.content,
      previewType: this.props.previewType || "inner",
      keyContent: Math.random(),
      pageName: this.props.pageName,
      showContent: showContent,
      previewNote: this.props.previewNote,
      draftId: this.props.draftId,
      draftImage: this.props.draftImage,
      challengeInfo: this.props.challengeInfo || {},
      showEditor: false,
      loading: false,
      hideButton: this.props.hideButton || "no",
    };
  }

  onClickUpdateBtn() {
    if (this.state.showEditor) {
      openContentEditor();
    } else {
      this.setState({ showEditor: true, loading: true });
    }
  }

  onRefreshData() {
    this.setState({ loading: false }, () => {
      openContentEditor();
    });
  }

  onClickPreviewBtn() {
    this.setState({ showContent: !this.state.showContent });
  }

  onClickRemoveBtn() {
    $(
      ".modalAlertOverlay.deleteContentModal,.modalAlert.deleteContentAlert"
    ).fadeIn(200);
  }

  onCloseEditor() {
    // on close editor event
  }

  onChangeData(draftId = "", draftImage = "") {
    this.setState({ showContent: true }, () => {
      this.props.onChangeData(
        this.state.html,
        this.state.content,
        draftId,
        draftImage
      );
    });
  }

  onChangeContent(template, design, type, draftId = "", draftImage = "") {
    let saveEditor = getStorageItem("saveEditor");
    if (saveEditor === "no") {
      this.setState(
        {
          content: design,
          html: template,
          keyContent: Math.random(),
          showEditor: false,
        },
        () => {
          this.onChangeData(draftId, draftImage);
        }
      );
      closeContentEditor();
    } else {
      showNotification("success", "Draft Saved!");

      this.setState(
        {
          content: design,
          html: template,
          draftId: draftId,
          // showEditor: true,
          // keyContent: Math.random(),
        },
        () => {
          // this.onChangeData(draftId, draftImage);
          // this.props.onChangeData(
          //   this.state.html,
          //   this.state.content,
          //   draftId,
          //   draftImage
          // );
        }
      );
    }
  }

  onClickNoDeleteAlert = () => {
    $(
      ".modalAlertOverlay.deleteContentModal,.modalAlert.deleteContentAlert"
    ).fadeOut(200);
  };

  onClickYesDeleteAlert = () => {
    this.setState({ html: "", content: {}, keyContent: Math.random() }, () => {
      $(
        ".modalAlertOverlay.deleteContentModal,.modalAlert.deleteContentAlert"
      ).fadeOut(200);
      this.onChangeData();
    });
  };

  renderConfirmationDeleteAlert() {
    return (
      <div>
        <div className="modalAlertOverlay deleteContentModal" />
        <div className="modalAlert deleteContentAlert">
          <p>Are you sure you want to clear the content?</p>
          <div className="alertFooterBtns">
            <button
              className="btn btn-default closeModal-No margin-right-10"
              onClick={this.onClickNoDeleteAlert}>
              No
            </button>
            <button
              className="btn btn-primary closeModal-Yes"
              onClick={this.onClickYesDeleteAlert}>
              Yes
            </button>
          </div>
        </div>
      </div>
    );
  }

  loadMpPreview() {
    if (this.state.draftId) {
      let key = getItem("sk");
      let userId = getItem("userId");
      let sitePrefix = "https://site.memberpages.app";
      if (isLocalSite()) {
        sitePrefix = "https://api.memberpages.local";
      }

      let iframeSrc = `${sitePrefix}/inner-content/?type=draft&id=${this.state.draftId}&key=${key}&userId=${userId}`;
      return (
        <div
          style={{ textAlign: "center", maxHeight: "500px", overflow: "auto" }}>
          <ContentHtml type="iframeSrc" src={iframeSrc} />
        </div>
      );
    } else {
      return (
        <ContentHtml
          key={this.state.keyContent}
          html={this.state.html}
          type={this.state.previewType}
        />
      );
    }
  }

  render() {
    return (
      <div>
        {this.renderConfirmationDeleteAlert()}
        <div className="card">
          {this.state.hideButton === "no" && (
            <div className="container-fluid">
              <div className="gap20" />

              <div className="havingHelpText">
                <h5>{this.state.label}</h5>
              </div>

              {this.state.description && (
                <p className="text-secondary">{this.state.description}</p>
              )}
              <HelpTextDiv
                id="pageContent"
                label="Learn more about the Page Content"
                type={this.state.pageName}
                isInline="true"
              />

              <div className="gap10" />
              <div className="clearfix">
                <div className="pull-left">
                  <button
                    className="btn btn-success"
                    onClick={this.onClickUpdateBtn.bind(this)}>
                    <i className="fa fa-edit margin-right-10" />
                    {this.state.loading
                      ? "Loading Editor..."
                      : this.state.html.length === 0
                      ? "Add Page Content"
                      : "Update Page Content"}
                  </button>
                </div>
                {this.state.html.length !== 0 && (
                  <div className="pull-right">
                    <button
                      title="Remove Content"
                      className="btn btn-danger"
                      onClick={this.onClickRemoveBtn.bind(this)}>
                      <i className="fa fa-trash margin-right-10" />
                      Remove Content
                    </button>
                  </div>
                )}
              </div>
              <div className="gap20" />
            </div>
          )}

          <VelocityTransitionGroup
            enter={{ animation: "slideDown" }}
            leave={{ animation: "slideUp" }}>
            {this.state.html.length > 0 && this.state.showContent === true && (
              <div className="list-group-item">
                <div className="gap10" />
                {(this.state.previewType !== "mp-preview" ||
                  this.props.editorType !== "mp") && (
                  <ContentHtml
                    key={this.state.keyContent}
                    html={this.state.html}
                    type={this.state.previewType}
                  />
                )}
                {this.state.previewType === "mp-preview" &&
                  this.props.editorType === "mp" &&
                  this.loadMpPreview()}
              </div>
            )}
          </VelocityTransitionGroup>
        </div>
        {this.state.showEditor && (
          <ContentEditor
            key={this.state.keyContent}
            title={this.state.title}
            screen={this.state.screen}
            challengeInfo={this.state.challengeInfo}
            onChangeTemplate={this.onChangeContent.bind(this)}
            editorContent={this.state.content}
            displayMode={this.state.displayMode}
            onCloseEditor={this.onCloseEditor.bind(this)}
            editorType={this.props.editorType || "unlayer"}
            onRefreshData={this.onRefreshData.bind(this)}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PageContent);
