import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment";
import $ from "jquery";
import { VelocityTransitionGroup } from "velocity-react";
import PubSub from "pubsub-js";

import TitleBox from "../../../components/general/titleBox";

import Checkbox from "../../../components/form/checkBox";
import {
  callGetUsersByQuery,
  callUpdateTaskByQuery,
  callUpdateTask,
  callGetGenericTags,
  callGetFilteredTasks,
  callUpdateUser,
  callDeleteTask,
  callExecuteTaskRules,
} from "../../../services";
import { callGetSettings } from "../../../services/settingService";
import TaskDelete from "./components/deleteTask";
import TaskEdit from "./components/editTask";
import ActionFilter from "../../../components/form/actionFilter";
import {
  getParameterByName,
  imageUrl,
  showNotification,
} from "../../../helpers";

import { saveFilters } from "../../../modules/actions/index";
import { getItem } from "../../../helpers/storage";
import { SettingsHelper } from "../../../helpers/settings";
import DateFilter from "../../../components/general/dateFilter";
import SortItem from "../../../components/form/sortItem";
import RadioTag from "../../../components/form/radioTag";
import SearchableUserSelect from "../../../components/form/searchableUserSelect";
import ReactPaginate from "react-paginate";
import TaskExport from "./components/exportTask";

class AdminTasks extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    let from = "member";

    if (window.location.pathname.includes("/admin/tasks")) {
      from = "admin";
    }

    this.state = {
      from: from,
      userIdFilter: getParameterByName("uId") || "",
      userData: this.props.userData || {},
      staffUsers: [],
      rawStaffUsers: [],
      tasks: [],
      tasksBackup: [],
      selectedTask: {},
      filterList: [],
      customFields: [],
      taskStatusFilters: [],
      taskPriorityFilters: [
        { name: "All", value: "all", selected: true },
        { name: "Low", value: "low", selected: false },
        { name: "Medium", value: "medium", selected: false },
        { name: "High", value: "high", selected: false },
      ],
      taskInvoiceFilters: [
        { name: "All", value: "all", selected: true },
        { name: "Paid", value: "paid", selected: false },
        { name: "Unpaid", value: "unpaid", selected: false },
      ],
      taskBillFilters: [
        { name: "All", value: "all", selected: true },
        { name: "Paid", value: "paid", selected: false },
        { name: "Unpaid", value: "unpaid", selected: false },
      ],
      taskAssignedFilters: [
        { name: "All", value: "all", selected: true },
        { name: "Unassigned", value: "unassigned", selected: false },
        { name: "Select", value: "select", selected: false },
      ],
      userFilters: [],
      categoryFilters: [],
      keyFilter: Math.random(),
      keySort: Math.random(),
      customHeaders: [],
      keyDeleteTask: Math.random(),
      keyEditTask: Math.random(),
      isSelectAll: false,
      keySelectAll: Math.random(),
      taskCheckbox: {},
      keyActionFilter: Math.random(),
      sortColumn: "createdAt",
      taskName: "",
      sortOrder: "-1",
      showFilter: false,
      categories: [],
      pageTitle: "Tasks",
      keyPageTitle: Math.random(),
      hideTableFields: [],
      dateFilter: { filter: "all" },
      assignedTo: "",
      statusUpdatedBy: "",
      defaultStatus: "all",
      selectedSearchFilter: "",
      selectedStatusFilter: "all",
      selectedPriorityFilter: "all",
      selectedCategoryFilter: "all",
      selectedBillFilter: "all",
      selectedInvoiceFilter: "all",
      selectedAssignedFilter: "all",
      filterCustomFields: [],
      keyCustomField: Math.random(),
      selectedStaffUser: {},
      selectedStatusUser: {},
      keySelectUser: Math.random(),
      sortFields: [
        {
          label: "Due Date",
          value: "due_date",
          type: "date",
          field: "normal",
        },
        {
          label: "Created Date",
          value: "createdAt",
          type: "date",
          field: "normal",
        },
        {
          label: "Updated Date",
          value: "updatedAt",
          type: "date",
          field: "normal",
        },
      ],
      initialPage: 0,
      page: 0,
      pages: 0,
      limit: 50,
      keyPagination: Math.random(),
      socket: null,
    };
  }

  componentDidMount() {
    if (this.state.userData._id) {
      this.getData();
    }
  }

  async getData() {
    this.getPageTitle();
    await this.getSettings();
  }

  componentWillUnmount() {
    if (this.state.socket) {
      this.state.socket.disconnect();
    }
  }

  getPageTitle() {
    let settingsHelper = new SettingsHelper();
    let title = settingsHelper.getTasksTitle() || "Tasks";
    let taskName = settingsHelper.getTasksName() || "Task";
    this.setState({
      pageTitle: title,
      taskName: taskName,
      keyPageTitle: Math.random(),
    });
  }

  componentWillReceiveProps(newProps) {
    if (newProps.userData) {
      this.setState({ userData: newProps.userData }, () => {
        this.getData();
      });
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  async getCategories() {
    let d = await callGetGenericTags("task");
    let categoryFilters = [{ name: "All", value: "all", selected: true }];

    d.data.forEach((element) => {
      categoryFilters.push({
        name: element.name,
        value: element._id,
        selected: false,
      });
    });

    this.setState(
      {
        categoryFilters: categoryFilters,
      },
      () => {
        this.getTasks();
      }
    );
  }

  async getSettings() {
    let d = await callGetSettings("tasksettings");
    let settings = d.data;
    let taskSettings = settings.task_settings || {};
    let defaultWatchers = taskSettings.default_watchers || [];
    let customFields = taskSettings.custom_fields || [];
    let sortCustomFields = customFields.filter(
      (d) => d.type === "single" || d.type === "text"
    );

    let sortFields = this.state.sortFields || [];

    sortCustomFields.forEach((element) => {
      sortFields.push({
        label: element.label,
        value: element.id,
        type: "string",
        field: "custom",
      });
    });

    let headers = customFields.filter((d) => d.display);

    let taskStatusList = taskSettings.task_statuses || [];
    let hideTableFields = taskSettings.hide_table_fields || [];

    let defaultList = taskStatusList.filter((d) => d.default === true);

    let defaultStatus = "all";
    if (defaultList.length > 0) {
      defaultStatus = defaultList[0].id;
    }

    let defStatus = "all";

    let taskStatusFilters = [
      { name: "All", value: "all", selected: defaultStatus === "all" },
    ];
    taskStatusList.forEach((element) => {
      taskStatusFilters.push({
        name: element.name[0].toUpperCase() + element.name.slice(1),
        value: element.name,
        selected: defaultStatus === element.id,
      });
      if (defaultStatus === element.id) {
        defStatus = element.name;
      }
    });

    let filterCustomFields = customFields.filter((d) => d.filter);

    filterCustomFields.forEach((element) => {
      element.options.unshift({ label: "All", id: "all" });
    });

    this.setState(
      {
        hideTableFields,
        defaultWatchers,
        taskStatusFilters,
        customFields,
        customHeaders: headers,
        keyFilter: Math.random(),
        defaultStatus: defStatus,
        selectedStatusFilter: defStatus,
        filterCustomFields,
        sortFields,
      },
      () => this.getUserFilters()
    );
  }

  async getUserFilters() {
    let query = {
      query: {
        status: { $ne: "archive" },
        $select: ["_id", "task_filters"],
        populate: "no",
        _id: { $in: [this.state.userData._id] },
      },
    };
    /*eslint-enable*/

    let d = await callGetUsersByQuery(query);
    if (d.data.length > 0) {
      let taskFilters = d.data[0].task_filters || {};
      if (taskFilters.search) {
        this.setState({ selectedSearchFilter: taskFilters.search });
      }
      if (taskFilters.status) {
        this.setState({ selectedStatusFilter: taskFilters.status });
      }
      if (taskFilters.category) {
        this.setState({ selectedCategoryFilter: taskFilters.category });
      }
      if (taskFilters.priority) {
        this.setState({ selectedPriorityFilter: taskFilters.priority });
      }
      if (taskFilters.bill) {
        this.setState({ selectedBillFilter: taskFilters.bill });
      }
      if (taskFilters.invoice) {
        this.setState({ selectedInvoiceFilter: taskFilters.invoice });
      }
      if (taskFilters.assignedToType) {
        this.setState({ selectedAssignedFilter: taskFilters.assignedToType });
        if (taskFilters.assignedTo) {
          let d2 = await callGetUsersByQuery({
            query: {
              status: { $ne: "archive" },
              $select: ["_id", "name"],
              populate: "no",
              _id: { $in: [taskFilters.assignedTo] },
            },
          });
          if (d2.data.length > 0) {
            this.setState({
              assignedTo: taskFilters.assignedTo,
              selectedStaffUser: { label: d2.data[0].name, value: d2.data[0] },
            });
          }
        }
      }

      if (taskFilters.statusUpdatedBy) {
        let d2 = await callGetUsersByQuery({
          query: {
            status: { $ne: "archive" },
            $select: ["_id", "name"],
            populate: "no",
            _id: { $in: [taskFilters.statusUpdatedBy] },
          },
        });
        if (d2.data.length > 0) {
          this.setState({
            statusUpdatedBy: taskFilters.statusUpdatedBy,
            selectedStatusUser: { label: d2.data[0].name, value: d2.data[0] },
          });
        }
      }

      if (taskFilters.sortColumn) {
        this.setState({
          sortColumn: taskFilters.sortColumn,
          sortOrder: taskFilters.sortOrder,
          keySort: Math.random(),
        });
      }
      if (taskFilters.dateFilter) {
        this.setState({ dateFilter: taskFilters.dateFilter });
      }
      if (taskFilters.customFields) {
        let filterCustomFields = this.state.filterCustomFields;
        filterCustomFields.forEach((element) => {
          if (taskFilters.customFields[element.id]) {
            let option = element.options.find(
              (d) => d.id === taskFilters.customFields[element.id]
            );
            if (option) {
              element.selectedOption = option;
            }
          }
        });
        this.setState({ filterCustomFields, keyCustomField: Math.random() });
      }
      this.setState({ keyFilter: Math.random() });
    }
    this.getCategories();
  }

  async getTasks() {
    this.setState({ page: 0, limit: 50 }, () => {
      if (this.state.from === "member") {
        this.setState({ assignedTo: this.state.userData._id }, () => {
          this.filterByAPI();
        });
      } else {
        this.filterByAPI();
      }

      this.prepareTasksCheckbox(false);
    });
  }

  onClickCreateNewTask() {
    this.setState({ keyDueDate: Math.random() });
    $(
      ".modalAlertOverlay.createTaskModalOverlay,.modalAlert.createTaskModal"
    ).fadeIn(200);
  }

  onClickPayHistory() {
    this.props.history.push("/admin/pay-task-history");
  }

  onClickDeletedTasks() {
    this.props.history.push("/admin/deleted-tasks");
  }

  onClickTaskSettings() {
    this.props.history.push("/admin/task-settings");
  }

  onClickTaskReports() {
    this.props.history.push("/admin/task-reports");
  }

  async onClickShowHideFilters() {
    this.setState({ showFilter: !this.state.showFilter });
  }

  onSelectStaffUser = (user) => {
    if (user) {
      this.setState({
        selectedStaffUser: user,
        assignedTo: user.value._id,
      });
    }
  };

  onSelectStatusUser = (user) => {
    if (user) {
      this.setState({
        selectedStatusUser: user,
        statusUpdatedBy: user.value._id,
      });
    }
  };

  onClickDetailTask = (task) => {
    let filters = {};
    filters.tasks = this.state.filterList;
    this.props.saveFilters(filters);
    this.props.history.push(`/${this.state.from}/task-detail?tid=${task._id}`);
  };

  onClickEditTask(task) {
    // this.editTaskRef.onClickEditTask(task);
    this.props.history.push(`/${this.state.from}/task-detail?tid=${task._id}`);
  }

  async onClickStarTask(task) {
    let star = task.star || "#e6e6e6";
    if (star === "#e6e6e6") {
      star = "#ccb512";
    } else if (star === "#ccb512") {
      star = "#a71b1b";
    } else if (star === "#a71b1b") {
      star = "#28b31f";
    } else if (star === "#28b31f") {
      star = "#3488de";
    } else if (star === "#3488de") {
      star = "#e6e6e6";
    }

    let updatedTask = await callUpdateTask(task._id, { star: star });
    if (updatedTask._id) {
      let tasks = this.state.tasks;
      tasks.forEach((element) => {
        if (element._id === updatedTask._id) {
          element.star = star;
        }
      });
      this.setState({ tasks: tasks });
    }
  }

  onClickViewTask(task) {
    let url = `/admin/task-detail?tid=${task._id}`;
    window.open(url, "_blank");
  }

  async onClickCompleteTask(task) {
    let data = {
      status: "completed",
    };

    let d = await callUpdateTask(task._id, data);

    if (d._id) {
      showNotification("success", "Status changed successfully", 4000);
      this.getTasks();
      PubSub.publish("refreshOpenTasks", "");
    }
  }

  toggleSelectAll = (check) => {
    this.setState({
      isSelectAll: check,
      keySelectAll: Math.random(),
    });
    this.prepareTasksCheckbox(check);
  };

  prepareTasksCheckbox(status = false) {
    let tasks = this.state.tasks;
    let taskCheckbox = {};
    tasks.forEach((element) => {
      taskCheckbox[element._id] = status;
    });
    this.setState({ taskCheckbox: taskCheckbox, keySelectAll: Math.random() });
  }

  toggleSelectCheckbox = (id, check) => {
    let taskCheckbox = this.state.taskCheckbox;
    taskCheckbox[id] = check;
    let isSelectAll = Object.values(taskCheckbox).indexOf(false) === -1;
    this.setState({ taskCheckbox, isSelectAll, keySelectAll: Math.random() });
  };

  onDeleteTask = () => {
    this.getTasks();
    PubSub.publish("refreshOpenTasks", "");
  };

  onClickDeleteTask = (task) => {
    this.setState({ selectedTask: task }, () => {
      $(
        ".modalAlertOverlay.deleteTaskOverlay,.modalAlert.deleteTaskAlert"
      ).fadeIn(200);
    });
  };

  onClickNoAlert = () => {
    $(
      ".modalAlertOverlay.deleteTaskOverlay,.modalAlert.deleteTaskAlert"
    ).fadeOut(200);
  };

  onClickYesAlert = () => {
    callDeleteTask(this.state.selectedTask._id).then((d) => {
      $(
        ".modalAlertOverlay.deleteTaskOverlay,.modalAlert.deleteTaskAlert"
      ).fadeOut(200);
      this.getTasks();
    });
  };

  renderConfirmationAlert() {
    let selectedTask = this.state.selectedTask;
    let description = "";
    if (selectedTask.task) {
      description = selectedTask.task;
    }
    return (
      <div>
        <div className="modalAlertOverlay deleteTaskOverlay" />
        <div className="modalAlert deleteTaskAlert">
          <p>{`Are you sure you want to delete "${description}" task?`}</p>
          <div className="alertFooterBtns">
            <button
              className="btn btn-default closeModal-No margin-right-10"
              onClick={this.onClickNoAlert}>
              No
            </button>
            <button
              className="btn btn-primary closeModal-Yes"
              onClick={this.onClickYesAlert}>
              Yes
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderTasks() {
    let tasks = this.state.tasks;
    let returnVals = [];
    let customHeaders = this.state.customHeaders;
    let hideTableFields = this.state.hideTableFields;

    for (let i = 0; i < tasks.length; i++) {
      const element = tasks[i];
      let customValues = [];
      let customFields = element.custom_fields || [];
      customHeaders.forEach((elHeader) => {
        let fValue = customFields.find((d) => d.id === elHeader.id);
        if (fValue && fValue.fieldValue) {
          customValues.push(fValue.fieldValue);
        } else {
          customValues.push("-");
        }
      });
      let star = element.star || "#e6e6e6";
      let color = "";
      if (element.lock_by && element.lock_by !== "ffffffffffffffffffffffff") {
        color = "#fff8e5";
      }
      returnVals.push(
        <tr key={i} style={{ backgroundColor: color }}>
          {this.state.from === "admin" && (
            <td>
              <Checkbox
                isChecked={this.state.taskCheckbox[element._id]}
                handleCheckboxChange={this.toggleSelectCheckbox.bind(
                  this,
                  element._id
                )}
                key={this.state.keySelectAll}
              />
            </td>
          )}
          <td style={{ textAlign: "center" }}>
            <button
              onClick={this.onClickStarTask.bind(this, element)}
              className="btn btn-default border-0"
              style={{ padding: "0px", textAlign: "center" }}>
              <i className="fa fa-star" style={{ color: star }} />
            </button>
          </td>
          <td>
            <label
              key={element._id}
              className="link pointer-click"
              onClick={this.onClickDetailTask.bind(this, element)}>
              {element.task}
            </label>
          </td>
          {hideTableFields.indexOf("status") === -1 && (
            <td className="firstLetter">{element.status}</td>
          )}
          {hideTableFields.indexOf("priority") === -1 && (
            <td className="firstLetter">{element.priority}</td>
          )}
          {hideTableFields.indexOf("assigned") === -1 && (
            <td className="firstLetter">
              {element.member_info[0] ? element.member_info[0].name : "-"}
            </td>
          )}
          {hideTableFields.indexOf("created") === -1 && (
            <td className="firstLetter">
              {moment(element.createdAt).format("MM/DD/YYYY")}
            </td>
          )}
          {hideTableFields.indexOf("updated") === -1 && (
            <td className="firstLetter">
              {element.updatedAt
                ? moment(element.updatedAt).format("MM/DD/YYYY")
                : "-"}
            </td>
          )}
          {hideTableFields.indexOf("dueDate") === -1 && (
            <td>
              {element.due_date
                ? moment(element.due_date).format("MM/DD/YYYY")
                : "-"}
            </td>
          )}
          {customValues.map((i) => (
            <td>{i}</td>
          ))}
          <td style={{ width: "71px" }}>
            {/* <button
              title="Delete"
              onClick={this.onClickDeleteTask.bind(this, element)}
              className="btn btn-danger btn-sm border-0">
              <i className="fa fa-trash" />
            </button> */}
            <button
              onClick={this.onClickEditTask.bind(this, element)}
              className="btn btn-default btn-sm border-0 ">
              <i className="fa fa-eye" />
            </button>
            <button
              onClick={this.onClickViewTask.bind(this, element)}
              className="btn btn-default btn-sm  border-0">
              <i className="fa fa-external-link" />
            </button>
          </td>
        </tr>
      );
    }
    return returnVals;
  }

  onClickFilterTasks() {
    this.setState({ page: 0, limit: 50 }, () => {
      this.filterByAPI(2);
    });
  }

  onClickCancelFilterTasks() {
    this.setState({ showFilter: false });
  }

  handlePageClick = (data) => {
    let page = data.selected;
    this.setState({ page: page, initialPage: page }, () => {
      this.filterByAPI();
    });
  };

  async filterByAPI(option = 1, fields = []) {
    let sortColumn = getItem("taskSortColumn") || "createdAt";
    let sortOrder = getItem("taskSortOrder") || "-1";

    let customFields = {};
    let customFieldTypes = {};
    this.state.filterCustomFields.forEach((element) => {
      if (element.selectedOption) {
        if (element.selectedOption.id !== "all") {
          customFields[element.id] = element.selectedOption.id;
        }
      }
      if (element.selectedOptions) {
        if (!element.selectedOptions.includes("all")) {
          customFields[element.id] = element.selectedOptions;
        }
      }
      customFieldTypes[element.id] = element.filterType || "single";
    });

    let type = this.state.selectedAssignedFilter;
    let assignedTo = this.state.assignedTo;

    if (type === "all" && this.state.from === "admin") {
      assignedTo = "";
    }
    if (type === "unassigned") {
      assignedTo = "ffffffffffffffffffffffff";
    }

    let statusUpdatedBy = this.state.statusUpdatedBy;

    let sortType = "normal";
    let sortCustomField = this.state.sortFields.find(
      (d) => d.value === sortColumn
    );
    if (sortCustomField && sortCustomField.field === "custom") {
      sortType = "custom";
    }

    let filterData = {
      status: this.state.selectedStatusFilter,
      dateFilter: this.state.dateFilter,
      priority: this.state.selectedPriorityFilter,
      category: this.state.selectedCategoryFilter,
      search: this.state.selectedSearchFilter,
      assignedTo: assignedTo,
      sortColumn: sortColumn,
      sortOrder: sortOrder,
      sortType: sortType,
      bill: this.state.selectedBillFilter,
      invoice: this.state.selectedInvoiceFilter,
      customFields: customFields,
      customFieldTypes: customFieldTypes,
      assignedToType: type,
      statusUpdatedBy: statusUpdatedBy,
      page: this.state.page,
      limit: this.state.limit,
      action: option === 3 ? "export" : "filter",
      fields: fields,
    };

    let apiData = {
      action: "get-filtered-tasks",
      data: filterData,
    };
    let tasksResponse = await callGetFilteredTasks(apiData);
    /*eslint-disable*/
    if (option === 2) {
      await callUpdateUser(this.state.userData._id, {
        task_filters: filterData,
      });
    }

    if (option === 3) {
      saveAs(imageUrl(tasksResponse.fileKey), "cases.xlsx");
    } else {
      let metadata = tasksResponse.metaData;
      let pages = 0;

      if (metadata) {
        pages = Math.ceil(metadata.total / filterData.limit);
      }
      /*eslint-enable*/
      this.setState({
        tasks: tasksResponse.tasks,
        showFilter: false,
        sortColumn,
        sortOrder,
        keySort: Math.random(),
        pages: pages,
        keyPagination: Math.random(),
        page: this.state.page,
      });
    }
  }

  onClickExportTask(fields) {
    this.filterByAPI(3, fields);
  }

  onChangeDateFilter(filterData) {
    this.setState({ dateFilter: filterData });
  }

  onChangeItems = (items) => {
    console.log(items);
  };

  async executeTaskRules(taskId, rule, ruleData) {
    let apiData = {
      action: "execute-task-rule",
      data: {
        taskId: taskId,
        rule: rule,
        ruleData: ruleData,
      },
    };
    await callExecuteTaskRules(apiData);
  }

  onSubmitActionFilter = async (filterData, action) => {
    let data = {};
    if (action === "task-move-to") {
      data["status"] = filterData;
    }
    if (action === "task-add-watcher") {
      data = {
        $addToSet: { watchers: filterData },
      };
    }
    if (action === "task-assign-owner") {
      data["assigned_to"] = filterData;
    }

    let taskCheckbox = this.state.taskCheckbox;
    let ids = [];
    for (const key in taskCheckbox) {
      if (taskCheckbox.hasOwnProperty(key)) {
        const element = taskCheckbox[key];
        if (element) {
          ids.push(key);
        }
      }
    }
    if (action === "pay-invoice" || action === "create-invoice") {
      this.props.history.push("/admin/pay-invoices", {
        ids: ids,
        action: action,
      });
      return;
    }
    await callUpdateTaskByQuery(data, {
      query: { _id: { $in: ids } },
    });

    for (let i = 0; i < ids.length; i++) {
      const taskId = ids[i];
      if (action === "task-move-to") {
        await this.executeTaskRules(taskId, "field", {
          fields: ["status"],
        });
      }
      if (action === "task-assign-owner") {
        await this.executeTaskRules(taskId, "assignedToUser", {
          userId: filterData,
        });
      }
    }
    showNotification("success", "Tasks updated successfully", 4000);
    this.getTasks();
  };

  onSelectFilterOption(item, id) {
    let value = item[Object.keys(item)[0]].value;
    this.setState({ [id]: value });
  }

  onSelectCustomOption(item, id) {
    this.manageCustomFields([item[Object.keys(item)[0]].value], [id]);
  }

  onSelectCustomOptions(item, id) {
    let values = [];
    if (Object.keys(item).length > 0 && item.constructor === Object) {
      Object.keys(item).forEach((key) => {
        values.push(item[key].value);
      });
    }

    let customFields = this.state.filterCustomFields || [];

    customFields.forEach((element) => {
      if (element.id === id) {
        element["selectedOptions"] = values;
      }
    });

    this.setState({
      filterCustomFields: customFields,
      keyCustomField: Math.random(),
    });
  }

  manageCustomFields(values, ids) {
    let customFields = this.state.filterCustomFields || [];

    customFields.forEach((element) => {
      if (ids.indexOf(element.id) > -1) {
        let selectedOption = element.options.filter((d) => {
          return values.indexOf(d.id) > -1;
        });
        if (selectedOption.length > 0) {
          element["selectedOption"] = selectedOption[0];
        }
      }
      if (ids.length === 0) {
        element["selectedOption"] = {};
      }
    });

    this.setState({
      filterCustomFields: customFields,
      keyCustomField: Math.random(),
    });
  }

  renderCustomFieldFilters() {
    let customFields = this.state.filterCustomFields;
    let returnVals = [];
    for (let i = 0; i < customFields.length; i++) {
      const element = customFields[i];
      const filterType = element.filterType || "single";

      if (filterType === "single") {
        let optionList = [];
        let selectedOption = element.selectedOption || {};

        element.options.forEach((opElem) => {
          if (!selectedOption.id && opElem.id === "all") {
            selectedOption = opElem;
          }
          optionList.push({
            name: opElem.label,
            value: opElem.id,
            selected: false,
          });
        });
        returnVals.push(
          <div className="list-group-item">
            <>
              <label className="firstLetter"> {element.label} </label>
              <div className="tabsHolder radioTabs">
                <RadioTag
                  onCheckChange={this.onSelectCustomOption.bind(this)}
                  id={element.id}
                  labelList={optionList}
                  selectType={"value"}
                  key={this.state.keyCustomField}
                  selectedList={[selectedOption && selectedOption.id]}
                />
              </div>
            </>
          </div>
        );
      } else {
        let optionList = [];
        let selectedOptions = element.selectedOptions || [];

        element.options.forEach((opElem) => {
          optionList.push({
            name: opElem.label,
            value: opElem.id,
            selected: false,
          });
        });
        returnVals.push(
          <div className="list-group-item">
            <>
              <label className="firstLetter"> {element.label} </label>
              <div className="tabsHolder radioTabs">
                <RadioTag
                  onCheckChange={this.onSelectCustomOptions.bind(this)}
                  id={element.id}
                  labelList={optionList}
                  selectType={"value"}
                  type="multiple"
                  key={this.state.keyCustomField}
                  selectedList={selectedOptions}
                />
              </div>
            </>
          </div>
        );
      }
    }
    return returnVals;
  }

  render() {
    let showActionFilter =
      Object.values(this.state.taskCheckbox).indexOf(true) > -1;
    let hideTableFields = this.state.hideTableFields;
    return (
      <div>
        {this.renderConfirmationAlert()}
        <TaskDelete
          key={this.state.keyDeleteTask}
          taskId={this.state.selectedTask._id}
          onDelete={() => {
            this.getTasks();
            PubSub.publish("refreshOpenTasks", "");
          }}
        />
        <TaskEdit
          ref={(ref) => {
            this.editTaskRef = ref;
          }}
          key={this.state.keyEditTask}
          staffUsers={this.state.rawStaffUsers}
          userData={this.state.userData}
          getTasks={() => this.getTasks()}
        />
        <div className="container-fluid">
          <TitleBox
            title={this.state.pageTitle}
            key={this.state.keyPageTitle}
          />
          <div className="gap20" />
          <div className="card">
            <div className="list-group list-group-flush">
              <div className="list-group-item">
                <button
                  className="btn btn-default btn-sm margin-right-10"
                  onClick={this.onClickShowHideFilters.bind(this)}>
                  {this.state.showFilter ? "Hide" : "Show"} Filters
                </button>
                <div className="pull-right">
                  {this.state.from === "admin" && (
                    <TaskExport
                      onSubmit={(fields) => this.onClickExportTask(fields)}
                    />
                  )}
                  {this.state.from === "admin" && (
                    <>
                      <button
                        className="btn btn-default btn-sm margin-right-10"
                        onClick={this.onClickDeletedTasks.bind(this)}>
                        Deleted {this.state.taskName}
                      </button>
                    </>
                  )}
                  {this.state.from === "admin" && (
                    <>
                      <button
                        className="btn btn-default btn-sm margin-right-10"
                        onClick={this.onClickPayHistory.bind(this)}>
                        Pay History
                      </button>
                    </>
                  )}
                  {this.state.from === "admin" && (
                    <>
                      <button
                        className="btn btn-primary btn-sm"
                        onClick={this.onClickCreateNewTask.bind(this)}>
                        Create New {this.state.taskName}
                      </button>
                    </>
                  )}
                </div>
              </div>
              <VelocityTransitionGroup
                enter={{ animation: "slideDown" }}
                leave={{ animation: "slideUp" }}>
                {this.state.showFilter && (
                  <>
                    <div className="list-group-item">
                      <div
                        className={
                          this.state.selectedSearchFilter !== ""
                            ? "mdInput mdFocussed"
                            : "mdInput"
                        }>
                        <label>Search Keyword</label>
                        <input
                          type="text"
                          name="selectedSearchFilter"
                          value={this.state.selectedSearchFilter}
                          onChange={(event) => this.handleInputChange(event)}
                        />
                      </div>
                    </div>
                    <div className="list-group-item">
                      <p>Select a status</p>
                      <div className="radioBHolder">
                        <div className="tabsHolder radioTabs">
                          <RadioTag
                            key={this.state.keyFilter}
                            onCheckChange={this.onSelectFilterOption.bind(this)}
                            labelList={this.state.taskStatusFilters}
                            id={"selectedStatusFilter"}
                            selectedList={[this.state.selectedStatusFilter]}
                            selectType={"value"}
                          />
                        </div>
                      </div>
                      {this.state.from === "admin" &&
                        this.state.selectedStatusFilter !== "all" && (
                          <>
                            <div className="gap20" />
                            <SearchableUserSelect
                              key={this.state.keySelectUser}
                              name={"statusUpdatedBy"}
                              placeholder="Status Updated By"
                              onSelect={this.onSelectStatusUser}
                              selected={
                                this.state.selectedStatusUser
                              }></SearchableUserSelect>
                            <div className="gap20" />
                            <button
                              className="btn btn-default btn-sm"
                              onClick={() => {
                                this.setState({
                                  selectedStatusUser: {},
                                  statusUpdatedBy: "",
                                });
                              }}>
                              Clear
                            </button>
                          </>
                        )}
                    </div>
                    <div className="list-group-item">
                      <p>Select a priority</p>
                      <div className="radioBHolder">
                        <div className="tabsHolder radioTabs">
                          <RadioTag
                            key={this.state.keyFilter}
                            onCheckChange={this.onSelectFilterOption.bind(this)}
                            labelList={this.state.taskPriorityFilters}
                            id={"selectedPriorityFilter"}
                            selectedList={[this.state.selectedPriorityFilter]}
                            selectType={"value"}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="list-group-item">
                      <p>Select a category</p>
                      <div className="radioBHolder">
                        <div className="tabsHolder radioTabs">
                          <RadioTag
                            key={this.state.keyFilter}
                            onCheckChange={this.onSelectFilterOption.bind(this)}
                            labelList={this.state.categoryFilters}
                            id={"selectedCategoryFilter"}
                            selectedList={[this.state.selectedCategoryFilter]}
                            selectType={"value"}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="list-group-item">
                      <p>Bill</p>
                      <div className="radioBHolder">
                        <div className="tabsHolder radioTabs">
                          <RadioTag
                            key={this.state.keyFilter}
                            onCheckChange={this.onSelectFilterOption.bind(this)}
                            labelList={this.state.taskBillFilters}
                            id={"selectedBillFilter"}
                            selectedList={[this.state.selectedBillFilter]}
                            selectType={"value"}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="list-group-item">
                      <p>Invoice</p>
                      <div className="radioBHolder">
                        <div className="tabsHolder radioTabs">
                          <RadioTag
                            key={this.state.keyFilter}
                            onCheckChange={this.onSelectFilterOption.bind(this)}
                            labelList={this.state.taskInvoiceFilters}
                            id={"selectedInvoiceFilter"}
                            selectedList={[this.state.selectedInvoiceFilter]}
                            selectType={"value"}
                          />
                        </div>
                      </div>
                    </div>
                    {this.state.from === "admin" && (
                      <div className="list-group-item">
                        <p>Assigned To</p>
                        <div className="radioBHolder">
                          <div className="tabsHolder radioTabs">
                            <RadioTag
                              key={this.state.keyFilter}
                              onCheckChange={this.onSelectFilterOption.bind(
                                this
                              )}
                              labelList={this.state.taskAssignedFilters}
                              id={"selectedAssignedFilter"}
                              selectedList={[this.state.selectedAssignedFilter]}
                              selectType={"value"}
                            />
                          </div>
                        </div>

                        {this.state.selectedAssignedFilter === "select" && (
                          <>
                            <div className="gap20" />
                            <SearchableUserSelect
                              key={this.state.keySelectUser}
                              name={"assignedTo"}
                              placeholder="Select A User"
                              onSelect={this.onSelectStaffUser}
                              selected={
                                this.state.selectedStaffUser
                              }></SearchableUserSelect>
                          </>
                        )}
                      </div>
                    )}
                    {this.renderCustomFieldFilters()}
                    <DateFilter
                      selected={this.state.dateFilter.filter}
                      label="Filter by Due Date"
                      onChangeFilter={this.onChangeDateFilter.bind(this)}
                    />
                    <div className="list-group-item">
                      <SortItem
                        ref={(ref) => {
                          this.sortRef = ref;
                        }}
                        key={this.state.keySort}
                        items={this.state.tasks}
                        selectedSortColumn={this.state.sortColumn}
                        selectedSortOrder={this.state.sortOrder}
                        columns={this.state.sortFields}
                        filterType="api"
                        onChangeItems={this.onChangeItems}
                      />
                    </div>
                    <div className="pull-right">
                      <div className="gap20" />
                      <button
                        className="btn btn-default btn-sm margin-right-10"
                        onClick={this.onClickCancelFilterTasks.bind(this)}>
                        Cancel
                      </button>
                      <button
                        className="btn btn-primary btn-sm margin-right-10"
                        onClick={this.onClickFilterTasks.bind(this)}>
                        Filter {this.state.taskName}
                      </button>
                      <div className="gap20" />
                    </div>
                  </>
                )}
              </VelocityTransitionGroup>

              {!this.state.showFilter && this.state.pages > 0 && (
                <div className="container-fluid" style={{ paddingLeft: 20 }}>
                  <div className="gap20" />
                  <div id="react-paginate">
                    <ReactPaginate
                      key={this.state.keyPagination}
                      previousLabel={<span aria-hidden="true">&laquo;</span>}
                      nextLabel={<span aria-hidden="true">&raquo;</span>}
                      pageCount={this.state.pages}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={10}
                      onPageChange={this.handlePageClick}
                      breakClassName="page-item"
                      breakLinkClassName="page-link"
                      pageClassName="page-item"
                      previousClassName="page-item"
                      nextClassName="page-item"
                      pageLinkClassName="page-link"
                      previousLinkClassName="page-link"
                      nextLinkClassName="page-link"
                      activeClassName="page-item active"
                      disableInitialCallback={true}
                      initialPage={this.state.initialPage}
                    />
                  </div>
                </div>
              )}

              {!this.state.showFilter && (
                <div className="list-group-item">
                  <div className="table-responsive">
                    <table className="table table-bordered table-condensed">
                      <thead>
                        <tr>
                          {this.state.from === "admin" && (
                            <th style={{}}>
                              <Checkbox
                                isChecked={this.state.isSelectAll}
                                handleCheckboxChange={this.toggleSelectAll}
                                key={this.state.keySelectAll}
                              />
                            </th>
                          )}
                          <th style={{ textAlign: "center" }}>
                            <button
                              style={{ padding: "0px", textAlign: "center" }}
                              className="btn btn-default border-0">
                              <i
                                className="fa fa-star-o"
                                style={{ color: "black" }}
                              />
                            </button>
                          </th>
                          <th>{this.state.taskName}</th>
                          {hideTableFields.indexOf("status") === -1 && (
                            <th>Status</th>
                          )}
                          {hideTableFields.indexOf("priority") === -1 && (
                            <th>Priority</th>
                          )}
                          {hideTableFields.indexOf("assigned") === -1 && (
                            <th>Assigned</th>
                          )}
                          {hideTableFields.indexOf("created") === -1 && (
                            <th>Created</th>
                          )}
                          {hideTableFields.indexOf("updated") === -1 && (
                            <th>Updated</th>
                          )}
                          {hideTableFields.indexOf("dueDate") === -1 && (
                            <th>Due Date</th>
                          )}
                          {this.state.customHeaders.map((i) => (
                            <th className="firstLetter">
                              {i.name ? i.name : i.label}
                            </th>
                          ))}
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>{this.renderTasks()}</tbody>
                    </table>
                  </div>
                </div>
              )}
              {showActionFilter && (
                <div className="list-group-item">
                  <ActionFilter
                    type={"viewTasks"}
                    key={this.state.keyActionFilter}
                    watchers={this.state.rawStaffUsers}
                    statusList={this.state.taskStatusFilters}
                    onSubmit={this.onSubmitActionFilter}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.authReducer.userData,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveFilters,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AdminTasks);
