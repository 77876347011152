import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import $ from "jquery";

import FilterList from "../../../components/form/filterList";
import TitleBox from "../../../components/general/titleBox";
import { filterByTags } from "../../../helpers/filters";

import config from "../../../helpers/config";

import {
  callGetMovements,
  callDeleteMovementUsingId,
  callGetMovementUsingId
} from "../../../services";

class ViewMovements extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      movements: [],
      movementsBackup: [],
      filterList: [],
      tags: [],
      selectedMovement: {},
      keyFilter: Math.random()
    };
  }

  componentDidMount() {
    this.getMovements();
  }

  getMovements() {
    callGetMovements({}).then(d => {
      this.setState({
        movements: d.data,
        movementsBackup: d.data
      });
      this.prepareDataForFilter(d.data);
    });
  }

  onClickEditMovement = movement => {
    callGetMovementUsingId(movement._id).then(d => {
      if (d._id) {
        this.props.history.push("/admin/add-movement", {
          movement: d
        });
      }
    });
  };

  onClickDeleteMovement = movement => {
    this.setState({ selectedMovement: movement }, () => {
      $(".modalAlertOverlay,.modalAlert").fadeIn(200);
    });
  };

  onClickViewMovement = movement => {
    if (movement.url_name) {
      let mUrl = `${config.urls.siteUrl}/member/movement/${movement.url_name}`;
      window.open(mUrl, "_blank");
    }
  };

  prepareDataForFilter(data) {
    let tags = [];

    data.forEach(element => {
      let tag = element.ftags || [];
      tag.forEach(el => {
        let arrTag = tags.filter(d => {
          return d.name.toLowerCase() === el.name.toLowerCase();
        });
        if (arrTag.length === 0) {
          tags.push({
            name: el.name.charAt(0).toUpperCase() + el.name.slice(1),
            value: el.name.toLowerCase(),
            selected: false
          });
        }
      });
    });

    tags.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

    tags.unshift({ name: "All", value: "all", selected: true });

    this.setState({
      tags: tags,
      keyFilter: Math.random()
    });
  }

  filterUpdated(updatedFilter, name, type, searchFields) {
    let filterList = this.state.filterList;
    filterList[name] = {
      name: name,
      type: type,
      searchFields: searchFields,
      values: updatedFilter
    };
    this.setState(
      {
        filterList: filterList
      },
      () => {
        let data = filterByTags(this.state.movementsBackup, filterList);
        this.setState({
          movements: data
        });
      }
    );
  }

  renderItems() {
    var returnVals = [];
    let items = this.state.movements;
    items.forEach(element => {
      returnVals.push(
        <tr key={element._id}>
          <td>{element.name}</td>
          <td>{element.description}</td>
          <td>{element.url_name}</td>
          <td>{element.video_list.length}</td>
          <td className="min-width-150">
            <button
              className="btn btn-danger btn-sm margin-right-10 border-0"
              onClick={this.onClickDeleteMovement.bind(this, element)}>
              <i className="fa fa-trash" />
            </button>
            <button
              className="btn btn-default btn-sm margin-right-10 border-0"
              onClick={this.onClickEditMovement.bind(this, element)}>
              <i className="fa fa-pencil" />
            </button>
            <button
              onClick={this.onClickViewMovement.bind(this, element)}
              className="btn btn-default btn-sm border-0">
              <i className="fa fa-share-square-o" />
            </button>
          </td>
        </tr>
      );
    });
    return returnVals;
  }

  onClickNoAlert = () => {
    $(".modalAlertOverlay,.modalAlert").fadeOut(200);
  };

  onClickYesAlert = () => {
    callDeleteMovementUsingId(this.state.selectedMovement._id).then(d => {
      $(".modalAlertOverlay,.modalAlert").fadeOut(200);
      this.getMovements();
    });
  };

  renderConfirmationAlert() {
    let selectedMovement = this.state.selectedMovement;
    let name = "";
    if (selectedMovement.name) {
      name = selectedMovement.name;
    }
    return (
      <div>
        <div className="modalAlertOverlay" />
        <div className="modalAlert">
          <p>{`Are you sure you want to delete "${name}" movement?`}</p>
          <div className="alertFooterBtns">
            <button
              className="btn btn-default closeModal-No margin-right-10"
              onClick={this.onClickNoAlert}>
              No
            </button>
            <button
              className="btn btn-primary closeModal-Yes"
              onClick={this.onClickYesAlert}>
              Yes
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderFilterView() {
    return (
      <>
        <div className="list-group-item">
          <FilterList
            label={"Search Movements"}
            name={"search"}
            type={"search"}
            searchFields={["name", "description", "url_name"]}
            onFinish={this.filterUpdated.bind(this)}
          />
        </div>
        <div className="list-group-item">
          <p>Select a tag</p>
          <FilterList
            key={this.state.keyFilter}
            filterList={this.state.tags}
            name={"tags"}
            type={"single"}
            searchFields={["ftags->name"]}
            onFinish={this.filterUpdated.bind(this)}
          />
        </div>
      </>
    );
  }

  render() {
    return (
      <div>
        {this.renderConfirmationAlert()}
        <div className="container-fluid">
          <TitleBox title="Movements Overview" />
          <div className="gap20" />
          <div className="card">
            <div className="list-group list-group-flush">
              {this.renderFilterView()}
              <div className="list-group-item">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Description</th>
                      <th>Url Name</th>
                      <th>Videos</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>{this.renderItems()}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewMovements);
