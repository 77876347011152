import { SAVE_FILTER, STORE_USER_SETTINGS, SAVE_FILTERS } from "../types";

const initialState = {
  filterList: [],
  userSettings: {},
  filters: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SAVE_FILTER:
      return {
        ...state,
        filterList: action.data,
      };
    case SAVE_FILTERS:
      return {
        ...state,
        filters: action.data,
      };
    case STORE_USER_SETTINGS:
      return {
        ...state,
        userSettings: action.data,
      };
    default:
      return state;
  }
};
