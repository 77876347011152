import * as api from "./api";
import { callApi } from "../helpers";

function callCreateForm(data) {
  return callApi(api.service("form").create(data));
}

function callUpdateForm(id, data) {
  return callApi(api.service("form").patch(id, data));
}

function callGetFormsUsingQuery(query) {
  return callApi(api.service("form").find(query));
}

function callGetFormById(id) {
  return callApi(api.service("form").get(id));
}

function callDeleteFormById(id) {
  return callApi(api.service("form").remove(id));
}

function callCreateFormSubmission(data) {
  return callApi(api.service("form-submission").create(data));
}

function callUpdateFormSubmission(id, data) {
  return callApi(api.service("form-submission").patch(id, data));
}

function callGetFormSubmissionById(id) {
  return callApi(api.service("form-submission").get(id));
}

function callGetFormSubmissionUsingQuery(query) {
  return callApi(api.service("form-submission").find(query));
}

function callGetPDFFormSubmissionUsingQuery(query) {
  return callApi(api.service("pdf-form-submission").find(query));
}

function callCreateRegistrationPage(data) {
  return callApi(api.service("registration-page").create(data));
}

function callUpdateRegistrationPage(id, data) {
  return callApi(api.service("registration-page").patch(id, data));
}

function callGetRegistrationPagesUsingQuery(query) {
  return callApi(api.service("registration-page").find(query));
}

function callGetRegistrationPageById(id) {
  return callApi(api.service("registration-page").get(id));
}

function callDeleteRegistrationPageById(id) {
  return callApi(api.service("registration-page").remove(id));
}

function callCreatePDFForm(data) {
  return callApi(api.service("pdf-form").create(data));
}

function callUpdatePDFForm(id, data) {
  return callApi(api.service("pdf-form").patch(id, data));
}

function callGetPDFFormsUsingQuery(query) {
  return callApi(api.service("pdf-form").find(query));
}

function callGetPDFFormById(id) {
  return callApi(api.service("pdf-form").get(id));
}

function callDeletePDFFormById(id) {
  return callApi(api.service("pdf-form").remove(id));
}

function callCreateExternalForm(data) {
  return callApi(api.service("external-form").create(data));
}

function callUpdateExternalForm(id, data) {
  return callApi(api.service("external-form").patch(id, data));
}

function callGetExternalFormsUsingQuery(query) {
  return callApi(api.service("external-form").find(query));
}

function callGetExternalFormById(id) {
  return callApi(api.service("external-form").get(id));
}

function callDeleteExternalFormById(id) {
  return callApi(api.service("external-form").remove(id));
}

function callUpdateExternalFormResult(id, data) {
  return callApi(api.service("external-form-result").patch(id, data));
}

function callCreateDraftFormSubmission(data) {
  return callApi(api.service("draft-form-submission").create(data));
}

function callGetDraftFormSubmissionUsingQuery(query) {
  return callApi(api.service("draft-form-submission").find(query));
}

function callUpdateDraftFormSubmission(id, data) {
  return callApi(api.service("draft-form-submission").patch(id, data));
}

function callDeleteDraftFormSubmissionById(id) {
  return callApi(api.service("draft-form-submission").remove(id));
}

export {
  callCreateDraftFormSubmission,
  callUpdateDraftFormSubmission,
  callDeleteDraftFormSubmissionById,
  callGetDraftFormSubmissionUsingQuery,
  callCreateExternalForm,
  callUpdateExternalForm,
  callGetExternalFormsUsingQuery,
  callGetExternalFormById,
  callDeleteExternalFormById,
  callCreateForm,
  callUpdateForm,
  callGetFormsUsingQuery,
  callGetFormById,
  callDeleteFormById,
  callCreateFormSubmission,
  callUpdateFormSubmission,
  callGetFormSubmissionById,
  callGetFormSubmissionUsingQuery,
  callCreateRegistrationPage,
  callUpdateRegistrationPage,
  callGetRegistrationPagesUsingQuery,
  callGetRegistrationPageById,
  callDeleteRegistrationPageById,
  callCreatePDFForm,
  callUpdatePDFForm,
  callGetPDFFormsUsingQuery,
  callGetPDFFormById,
  callDeletePDFFormById,
  callGetPDFFormSubmissionUsingQuery,
  callUpdateExternalFormResult,
};
