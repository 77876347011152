import React from "react";
import { getUniqueId, imageUrl, showNotification } from "../../../../helpers";
import { callResizeAwsImage, uploadImages } from "../../../../services";
import { arrayMove } from "../../../../packages/mp-content-editor/helpers";
import SortableList, { SortableItem } from "react-easy-sort";
import $ from "jquery";
import Checkbox from "../../../../components/form/checkBox";
import Scrollbars from "react-custom-scrollbars";

class TaskImageList extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      images: this.props.images,
      description: "",
      optimizeImage: true,
      keyOptimizeImage: Math.random(),
      keyHideExport: Math.random(),
      fileData: "",
      selectedImage: {},
      files: [],
      editDescription: false,
      imageIndex: 0,
      exifData: {},
    };
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  handleFileUpload(event) {
    this.setState({ files: event.target.files });
  }

  toggleOptimizeImage = (check) => {
    this.setState({ optimizeImage: check });
  };

  toggleHideExport = (check) => {
    let selectedImage = this.state.selectedImage;

    let images = this.state.images;

    images.forEach((element) => {
      if (element.id === selectedImage.id) {
        element.hideExport = check;
      }
    });
    this.setState({
      images: images,
      description: "",
      editDescription: false,
      keyHideExport: Math.random(),
    });
    this.props.onClickSubmitImage(images);
  };

  async onClickSubmitImage() {
    let fileUrls = [];

    if (this.state.files.length > 0) {
      let resp = await uploadImages(this.state.files);
      let data = await resp.json();
      if (data.file_names) {
        data.file_names.forEach((element) => {
          fileUrls.push(element.key);
        });
      }
    }

    if (this.state.optimizeImage) {
      let data = {
        action: "optimize-aws-image",
        data: {
          keys: fileUrls,
        },
      };
      try {
        await callResizeAwsImage(data);
      } catch (error) {}
    }

    let images = this.state.images;

    fileUrls.forEach((element) => {
      let data = {
        description: this.state.description || "",
        file: element,
        optimize: this.state.optimizeImage,
        id: getUniqueId(),
      };
      images.push(data);
    });

    this.setState({ images });
    this.props.onClickSubmitImage(images);
  }

  onPressRemoveImage() {
    let selectedImage = this.state.selectedImage;
    let files = this.state.images;
    files = files.filter((d) => d.id !== selectedImage.id);
    this.setState({
      images: files,
    });
    this.props.onClickSubmitImage(files);
  }

  onSortEnd = (oldIndex, newIndex) => {
    let fileList = this.state.images;
    fileList = arrayMove(fileList, oldIndex, newIndex);

    this.setState({
      images: fileList,
    });
    this.props.onClickSubmitImage(fileList);
  };

  onClickCancelFromImageModal() {
    $(".modalAlertOverlay.imageModalOverlay,.modalAlert.imageAlert").fadeOut(
      200
    );
  }

  onClickCancelDeleteImageModal() {
    this.onPressRemoveImage();
    $(".modalAlertOverlay.imageModalOverlay,.modalAlert.imageAlert").fadeOut(
      200
    );
  }

  async onClickRotate(rotate) {
    $(".modalAlertOverlay.imageModalOverlay,.modalAlert.imageAlert").fadeOut(
      200
    );
    let selectedImage = this.state.selectedImage;

    if (selectedImage) {
      let key = selectedImage.file;
      let newKey = `${getUniqueId()}-${key}`;
      let data = {
        action: "rotate-aws-image",
        data: {
          key: key,
          rotate: rotate,
          newKey: newKey,
        },
      };
      await callResizeAwsImage(data);

      let images = this.state.images;

      images.forEach((element) => {
        if (element.id === selectedImage.id) {
          element.file = newKey;
        }
      });
      this.setState({
        images: images,
      });
      this.props.onClickSubmitImage(images, false);
      showNotification("success", "Rotate image done!", 4000);
    }
  }

  async onClickGetExifData() {
    if (this.state.selectedImage) {
      let data = {
        action: "get-exif-data-image",
        data: {
          url: imageUrl(this.state.selectedImage.file),
        },
      };
      let response = await callResizeAwsImage(data);
      let isExifData = Object.keys(response).length > 0;

      if (!isExifData) {
        showNotification("error", "No EXIF data", 4000);
      }
      this.setState({ exifData: response });
    }
  }

  onClickShowImage(item, index) {
    this.setState(
      {
        selectedImage: item,
        description: item.description || "",
        imageIndex: index,
        exifData: {},
        keyHideExport: Math.random(),
      },
      () => {
        console.log(item);
        $(".modalAlertOverlay.imageModalOverlay,.modalAlert.imageAlert").fadeIn(
          200
        );
      }
    );
  }

  onClickEditImage() {
    this.setState({ editDescription: !this.state.editDescription });
  }

  onClickSaveDescription() {
    let selectedImage = this.state.selectedImage;

    let images = this.state.images;

    images.forEach((element) => {
      if (element.id === selectedImage.id) {
        element.description = this.state.description;
      }
    });
    this.setState({
      images: images,
      description: "",
      editDescription: false,
    });
    this.props.onClickSubmitImage(images);
  }

  onClickNextImage(option) {
    let images = this.state.images;
    let index = this.state.imageIndex;
    if (option === "next") {
      index += 1;
    } else if (option === "previous") {
      index -= 1;
    }
    let image = images[index];
    if (image) {
      this.setState({
        selectedImage: image,
        description: image.description,
        imageIndex: index,
        keyHideExport: Math.random(),
        exifData: {},
      });
    }
  }

  rendeImageModal() {
    let selectedImage = this.state.selectedImage;

    let exifData = this.state.exifData;
    let isExifData = Object.keys(exifData).length > 0;
    let modalHeight = window.innerHeight - 200;

    return (
      <div>
        <div
          className="modalAlertOverlay imageModalOverlay"
          onClick={() => {
            this.onClickCancelFromImageModal();
          }}
        />
        <div className="modalAlert imageAlert big">
          <div className="container-fluid">
            <div className="gap20" />
            <Scrollbars
              autoHide
              autoHideTimeout={1000}
              autoHideDuration={200}
              style={{ height: modalHeight }}>
              <div class="row">
                <div class="col-md-8">
                  <img
                    draggable={false}
                    src={imageUrl(selectedImage.file)}
                    alt=""
                    width={1200}
                    className="img-responsive"
                  />
                  <div className="gap20" />
                </div>
                <div class="col-md-4">
                  <button
                    onClick={this.onClickCancelDeleteImageModal.bind(this)}
                    className="btn btn-danger btn-sm dismissThisModal">
                    Delete
                  </button>
                  <br />
                  <br />
                  <button
                    onClick={this.onClickRotate.bind(this, 90)}
                    className="btn btn-default btn-sm dismissThisModal ">
                    Rotate Clockwise
                  </button>
                  <br />
                  <br />
                  <button
                    onClick={this.onClickRotate.bind(this, -90)}
                    className="btn btn-default btn-sm dismissThisModal ">
                    Rotate Counter Clockwise
                  </button>
                  <br />
                  <br />
                  <button
                    onClick={this.onClickGetExifData.bind(this)}
                    className="btn btn-default btn-sm dismissThisModal ">
                    View EXIF Data
                  </button>
                  <br />
                  <br />
                  {this.state.editDescription && (
                    <>
                      <div
                        className={
                          this.state.description !== ""
                            ? "mdInput mdFocussed"
                            : "mdInput"
                        }>
                        <label>Description</label>
                        <input
                          type="text"
                          className="offsetDays"
                          name="description"
                          value={this.state.description}
                          onChange={(event) => this.handleInputChange(event)}
                        />
                      </div>
                      <div className="gap10" />
                      <button
                        onClick={this.onClickSaveDescription.bind(this)}
                        className="btn btn-default btn-sm dismissThisModal float-right">
                        Save
                      </button>
                      <div className="gap30" />
                    </>
                  )}
                  {!this.state.editDescription && (
                    <>
                      <label className="margin-right-10">
                        {selectedImage.description || "No Description"}
                      </label>
                      <button
                        className="btn btn-default btn-sm border-0 margin-right-10"
                        onClick={this.onClickEditImage.bind(this)}>
                        <i className="fa fa-pencil" />
                      </button>
                    </>
                  )}
                  <br />
                  <br />
                  <Checkbox
                    label={"Hide On Export"}
                    isChecked={selectedImage.hideExport}
                    handleCheckboxChange={this.toggleHideExport}
                    key={this.state.keyHideExport}
                  />{" "}
                  <br />
                  <br />
                  <button
                    disabled={this.state.imageIndex === 0}
                    onClick={this.onClickNextImage.bind(this, "previous")}
                    className="btn btn-default btn-sm dismissThisModal margin-right-10">
                    <i className="fa fa-arrow-left" />
                  </button>
                  <button
                    disabled={
                      this.state.imageIndex === this.state.images.length - 1
                    }
                    onClick={this.onClickNextImage.bind(this, "next")}
                    className="btn btn-default btn-sm dismissThisModal">
                    <i className="fa fa-arrow-right" />
                  </button>
                  <br />
                  <br />
                </div>
              </div>
              {isExifData && (
                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      {Object.keys(exifData).map((i) => (
                        <div className="col-md-3" style={{ paddingBottom: 5 }}>
                          <b>{i.replace("'", "")}</b> <br />
                          {exifData[i]}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}{" "}
            </Scrollbars>
          </div>
          <div className="gap10" />

          <div className="alertFooterBtns">
            <div className="clearfix">
              <button
                onClick={this.onClickCancelFromImageModal.bind(this)}
                className="btn btn-default btn-sm dismissThisModal float-right">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.rendeImageModal()}
        <div className="gap10" />
        <h5>Images</h5>
        <div className="gap10" />
        <div className="row">
          <div className="col-md-6">
            <div
              className={
                this.state.description !== "" ? "mdInput mdFocussed" : "mdInput"
              }>
              <label>Description</label>
              <input
                type="text"
                className="offsetDays"
                name="description"
                value={this.state.description}
                onChange={(event) => this.handleInputChange(event)}
              />
            </div>
            <div className="gap20" />
            <input
              ref={(ref) => (this.fileInput = ref)}
              type="file"
              multiple
              onChange={this.handleFileUpload.bind(this)}
            />
            <div className="gap20" />
            {/* <Checkbox
              label={"Add GPS Data"}
              isChecked={this.state.optimizeImage}
              handleCheckboxChange={this.toggleOptimizeImage}
              key={this.state.keyOptimizeImage}
            /> */}
          </div>
        </div>
        <div className="pull-right">
          <button
            className="btn btn-primary"
            onClick={this.onClickSubmitImage.bind(this)}>
            Submit
          </button>
          <div className="gap20" />
        </div>
        <div className="gap20" />
        {this.state.images.length > 0 && (
          <>
            <div className="gap20" />
            <h5>Added Images</h5>
            <div className="gap10" />
            <div className="gap10" />
            <SortableList
              onSortEnd={this.onSortEnd}
              style={{
                userSelect: "none",
                display: "grid", // Changed from flex to grid
                gridTemplateColumns: "repeat(auto-fill, minmax(140px, 1fr))", // Adjusted for a responsive grid layout
                gridGap: "16px", // Ensured consistency in the unit
              }}
              draggedItemClassName="dragged">
              {this.state.images.map((item, index) => (
                <SortableItem key={index}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "grab",
                      fontSize: "16px",
                      userSelect: "none",
                    }}>
                    <div style={{ textAlign: "center" }}>
                      <img
                        draggable={false}
                        src={imageUrl(item.file)}
                        alt=""
                        width={140}
                        height={140}
                        className="img-responsive"
                        style={{
                          objectFit: "cover",
                          boxShadow: "0px 6px 6px -3px rgba(0, 0, 0, 0.2)",
                        }}
                      />
                      <div
                        style={{
                          width: "40px",
                          height: "40px",
                          backgroundColor: "#FFF",
                          cursor: "pointer",
                          marginLeft: "100px",
                          marginTop: "-35px",
                          position: "absolute",
                          borderRadius: "50%",
                          border: "1px solid #ccc",
                          fontWeight: "bold",
                          fontSize: "20pt",
                          lineHeight: ".9",
                        }}
                        onClick={() => this.onClickShowImage(item, index)}>
                        ...
                      </div>
                      {item.hideExport && (
                        <div
                          style={{
                            width: "35px",
                            height: "35px",
                            backgroundColor: "#FFF",
                            cursor: "pointer",
                            marginLeft: "0px",
                            marginTop: "-35px",
                            position: "absolute",
                            borderRadius: "50%",
                            border: "1px solid #ccc",
                            fontSize: "13pt",
                            lineHeight: "1.8",
                          }}>
                          <i className="fa fa-eye-slash" />
                        </div>
                      )}
                      <div style={{ clear: "both" }} />
                      <p style={{ marginTop: 5 }}>
                        {item.description || "No Description"}
                      </p>
                    </div>
                  </div>
                </SortableItem>
              ))}
            </SortableList>
            <div className="gap20" />
          </>
        )}
      </div>
    );
  }
}
export default TaskImageList;
