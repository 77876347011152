import * as api from "./api";
import { callApi } from "../helpers";

function callCreateArticle(data) {
  return callApi(api.service("article").create(data));
}

function callUpdateArticle(id, data) {
  return callApi(api.service("article").patch(id, data));
}

function callGetArticle() {
  return callApi(api.service("article").find({}));
}

function callGetArticleByQuery(query) {
  return callApi(api.service("article").find(query));
}

function callGetArticleById(id, isLoading = true) {
  return callApi(api.service("article").get(id), isLoading);
}

function callDeleteArticleById(id) {
  return callApi(api.service("article").remove(id));
}

function callCreateArticleCategory(data) {
  return callApi(api.service("article-category").create(data));
}

function callUpdateArticleCategory(id, data) {
  return callApi(api.service("article-category").patch(id, data));
}

function callGetArticleCategory() {
  return callApi(api.service("article-category").find({}));
}

function callGetArticleCategoryByQuery(query) {
  return callApi(api.service("article-category").find(query));
}

function callDeleteArticleCategoryById(id) {
  return callApi(api.service("article-category").remove(id));
}

function callCreateContentTemplate(data) {
  return callApi(api.service("content-editor-template").create(data));
}

function callUpdateContentTemplate(id, data) {
  return callApi(api.service("content-editor-template").patch(id, data));
}

function callDeleteContentTemplateById(id) {
  return callApi(api.service("content-editor-template").remove(id));
}

function callGetContentTemplateById(id) {
  return callApi(api.service("content-editor-template").get(id));
}

function callGetContentTemplate(query = {}) {
  return callApi(api.service("content-editor-template").find(query));
}

export {
  callCreateArticle,
  callGetArticle,
  callCreateArticleCategory,
  callGetArticleCategory,
  callCreateContentTemplate,
  callUpdateContentTemplate,
  callGetContentTemplate,
  callGetArticleById,
  callUpdateArticle,
  callDeleteArticleById,
  callGetArticleCategoryByQuery,
  callGetArticleByQuery,
  callUpdateArticleCategory,
  callDeleteArticleCategoryById,
  callDeleteContentTemplateById,
  callGetContentTemplateById,
};
