import React, { Component } from "react";
import PropTypes from "prop-types";
import { sanitizer } from "../../helpers";

class Checkbox extends Component {
  constructor(props) {
    super(props);
    let isChecked = this.props.isChecked;
    let disabled = this.props.disabled || false;
    let labelClass = this.props.labelClass;

    let checkBoxClass = "customCheck";
    if (isChecked) {
      checkBoxClass = "customCheck checked";
    }

    let labelHTML = this.props.htmlText || false;

    this.state = {
      isChecked: isChecked,
      checkBoxClass: checkBoxClass,
      labelHTML: labelHTML,
      labelClass: labelClass || "",
      disabled: disabled
    };
  }

  toggleCheckboxChange = () => {
    const { handleCheckboxChange } = this.props;

    this.setState(({ isChecked }) => ({
      isChecked: !isChecked,
      checkBoxClass: isChecked ? "customCheck" : "customCheck checked"
    }));

    handleCheckboxChange(!this.state.isChecked);
  };

  render() {
    const { label, isChecked } = this.props;

    return (
      <div className={this.state.checkBoxClass}>
        <div
          className="customCheckBox"
          onClick={!this.state.disabled ? this.toggleCheckboxChange : ""}
        >
          <span className="icon-check" />
        </div>

        <label className={this.state.labelClass}>
          <input
            style={{ display: "none" }}
            type="checkbox"
            value={label}
            checked={isChecked}
            onChange={this.toggleCheckboxChange}
            disabled={this.state.disabled}
          />
          {this.state.labelHTML ? (
            <div dangerouslySetInnerHTML={{ __html: sanitizer(label) }} />
          ) : (
            label
          )}
        </label>
      </div>
    );
  }
}

Checkbox.propTypes = {
  label: PropTypes.string.isRequired,
  handleCheckboxChange: PropTypes.func.isRequired
};

export default Checkbox;
