import {
  RESET_STATUS,
  CREATE_SYSTEM_UPDATE_SUCCESS,
  CREATE_SYSTEM_UPDATE_FAILURE,
  GET_SYSTEM_UPDATE,
  UPDATE_SYSTEM_UPDATE_FAILURE,
  UPDATE_SYSTEM_UPDATE_SUCCESS
} from "../types";

import config from "../../helpers/config";

import {
  callCreateSystemUpdate,
  callGetSystemUpdates,
  callUpdateSystemUpdate
} from "../../services/index";

/*
  This method is used to reset the status
  This will reset message and success states
*/
export const resetStatus = data => {
  return async dispatch => {
    dispatch({
      type: RESET_STATUS
    });
  };
};

export const createSystemUpdate = data => {
  return async dispatch => {
    return callCreateSystemUpdate(data)
      .then(data => {
        dispatch({
          type: CREATE_SYSTEM_UPDATE_SUCCESS,
          message: config.messages.createChlgQueSuccess,
          success: true
        });
      })
      .catch(err => {
        dispatch({
          type: CREATE_SYSTEM_UPDATE_FAILURE,
          message: config.messages.createChlgQueError,
          success: false
        });
      });
  };
};

export const updateSystemUpdate = (id, data) => {
  return async dispatch => {
    return callUpdateSystemUpdate(id, data)
      .then(data => {
        dispatch({
          type: UPDATE_SYSTEM_UPDATE_SUCCESS,
          message: config.messages.updateMessage,
          success: true
        });
      })
      .catch(err => {
        dispatch({
          type: UPDATE_SYSTEM_UPDATE_FAILURE,
          message: config.messages.createChlgQueError,
          success: false
        });
      });
  };
};

export const getSystemUpdates = data => {
  return async dispatch => {
    return callGetSystemUpdates(data).then(data => {
      dispatch({
        type: GET_SYSTEM_UPDATE,
        data: data
      });
    });
  };
};
