import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import EditorImages from "./editorImages";
import { closeSelectImageModal } from "../../helpers";

class SelectImage extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      type: this.props.type,
      showImages: this.props.showImages,
      contentType: this.props.contentType,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ showImages: nextProps.showImages });
  }

  onClickClose = () => {
    closeSelectImageModal(this.state.type);
  };

  onSelectImage = (image) => {
    this.props.onSelectImage(image);
    this.onClickClose();
  };

  onSelectFile = (file) => {
    if (this.props.onSelectFile) {
      this.props.onSelectFile(file);
    }

    this.onClickClose();
  };

  onSelectVideo = (video) => {
    this.props.onSelectVideo(video);
    this.onClickClose();
  };

  render() {
    let className = `fullModal selectImage ${this.state.type}`;
    let cType = "";
    if (this.state.contentType === "file") {
      cType = "File";
    } else if (this.state.contentType === "image") {
      cType = "Image";
    } else if (this.state.contentType === "video") {
      cType = "Video";
    }
    return (
      <div className={className}>
        <div className="fmInner">
          <div className="fmHeader">
            <div className="modalHeaderTabs">
              {/*eslint-disable*/}
              <a className="active" data-for=".for-tab1">
                Select {cType}
              </a>
            </div>
            {/*eslint-disable*/}
            <a className="closeFullModal" onClick={this.onClickClose}>
              <span className="icon-cross" />
            </a>
            {/*eslint-enable*/}
          </div>
          <div className="fmBody">
            <div className="fmBodyInner">
              <div className="modTabContent">
                <div className="gap20" />
                {this.state.showImages && (
                  <div className="active for-tab1">
                    <EditorImages
                      type="select"
                      contentType={this.state.contentType}
                      onSelectImage={this.onSelectImage}
                      onSelectFile={this.onSelectFile}
                      onSelectVideo={this.onSelectVideo}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SelectImage);
