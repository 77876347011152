import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";

class QuillEditor extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      text: this.props.value || "",
      focused: this.props.focused || false
    };
  }

  componentDidMount() {
    this.handleChange(this.state.text);
  }

  handleChange(value) {
    this.setState({ text: value });
    if (this.reactQuillRef) {
      this.quillRef = this.reactQuillRef.getEditor();
      this.props.onChangeValue(value, this.quillRef.getText());
    }
  }

  handleFocus(r, s, e) {
    this.setState({ focused: true });
  }

  handleBlur(r, s, e) {
    if (this.state.text === "") {
      this.setState({ focused: false });
    }
  }

  render() {
    const { theme, label } = this.props;

    const customStyles = {
      // padding: "12px 0px"
    };

    var className = this.state.focused ? "mdInput mdFocussed" : "mdInput";

    const modules = {
      toolbar: [
        ["align"],
        [{ align: "center" }],
        [{ align: "right" }],
        ["bold", "italic", "underline"],
        ["clean"]
      ]
    };

    // toolbar: [
    //     // [{ header: [1, 2, false] }],
    //     // ['bold', 'italic', 'underline'],
    //     // ['image', 'code-block']
    //
    //     [{ header: "1" }, { header: "2" }],
    //     [{ size: [] }],
    //     ["bold", "italic", "underline", "strike", "blockquote"],
    //     [
    //         { list: "ordered" },
    //         { list: "bullet" },
    //         { indent: "-1" },
    //         { indent: "+1" }
    //     ],
    //     ["link", "image"],
    //     ["align"],
    //     [{ align: "center" }],
    //     [{ align: "right" }],
    //     ["clean"]
    // ]

    // const formats = {
    //   formats: [
    //     "header",
    //     "bold",
    //     "italic",
    //     "underline",
    //     "strike",
    //     "blockquote",
    //     "list",
    //     "bullet",
    //     "indent",
    //     "link",
    //     "image",
    //     "align"
    //   ]
    // };

    return (
      <div className={className}>
        <label>{label}</label>
        <ReactQuill
          ref={el => {
            this.reactQuillRef = el;
          }}
          theme={theme}
          style={customStyles}
          value={this.state.text}
          onChange={this.handleChange.bind(this)}
          onBlur={this.handleBlur.bind(this)}
          onFocus={this.handleFocus.bind(this)}
          modules={modules}
          // formats={formats}
          placeholder=" "
        />
        <div className="gap10" />
      </div>
    );
  }
}

export default QuillEditor;
