import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import $ from "jquery";

import { getMealPlans } from "../../../modules/actions/index";
import { imageUrl } from "../../../helpers";
import TitleBox from "../../../components/general/titleBox";

import FilterList from "../../../components/form/filterList";
import { filterByTags } from "../../../helpers/filters";
import { callDeleteMealPlanById } from "../../../services";

class ViewMealPlan extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      plans: [],
      plansBackup: [],
      filterList: [],
      selectedMealPlan: {}
    };
  }

  componentDidMount() {
    this.props.getMealPlans();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.mealPlans.data) {
      this.setState({
        plans: newProps.mealPlans.data,
        plansBackup: newProps.mealPlans.data
      });
    }
  }

  onClickEditMealPlan = mealPlan => {
    this.props.history.push("/admin/add-meal-plan", {
      mealPlan: mealPlan
    });
  };

  onClickDeleteMealPlan = mealPlan => {
    this.setState({ selectedMealPlan: mealPlan }, () => {
      $(".modalAlertOverlay,.modalAlert").fadeIn(200);
    });
  };

  filterUpdated(updatedFilter, name, type, searchFields) {
    let filterList = this.state.filterList;
    filterList[name] = {
      name: name,
      type: type,
      searchFields: searchFields,
      values: updatedFilter
    };
    this.setState(
      {
        filterList: filterList
      },
      () => {
        let data = filterByTags(this.state.plansBackup, filterList);
        this.setState({
          plans: data
        });
      }
    );
  }

  renderPlans() {
    var returnVals = [];
    let items = this.state.plans;
    items.forEach(element => {
      returnVals.push(
        <tr key={element._id}>
          <td className="mealPlanImage">
            <img src={imageUrl(element.image_url)} alt="mealplan" />
          </td>
          <td>{element.name}</td>
          <td>{element.description}</td>
          <td className="min-width-100">
            <button
              onClick={this.onClickDeleteMealPlan.bind(this, element)}
              className="btn btn-danger btn-sm margin-right-10 border-0">
              <i className="fa fa-trash" />
            </button>
            <button
              onClick={this.onClickEditMealPlan.bind(this, element)}
              className="btn btn-default btn-sm border-0">
              <i className="fa fa-pencil" />
            </button>
          </td>
        </tr>
      );
    });
    return returnVals;
  }

  onClickNoAlert = () => {
    $(".modalAlertOverlay,.modalAlert").fadeOut(200);
  };

  onClickYesAlert = () => {
    callDeleteMealPlanById(this.state.selectedMealPlan._id).then(d => {
      $(".modalAlertOverlay,.modalAlert").fadeOut(200);
      this.props.getMealPlans();
    });
  };

  renderConfirmationAlert() {
    let selectedMealPlan = this.state.selectedMealPlan;
    let name = "";
    if (selectedMealPlan.name) {
      name = selectedMealPlan.name;
    }
    return (
      <div>
        <div className="modalAlertOverlay" />
        <div className="modalAlert">
          <p>{`Are you sure you want to delete "${name}" meal plan?`}</p>
          <div className="alertFooterBtns">
            <button
              className="btn btn-default closeModal-No margin-right-10"
              onClick={this.onClickNoAlert}>
              No
            </button>
            <button
              className="btn btn-primary closeModal-Yes"
              onClick={this.onClickYesAlert}>
              Yes
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderFilterView() {
    return (
      <div className="list-group-item">
        <FilterList
          label={"Search Meal Plans..."}
          name={"search"}
          type={"search"}
          searchFields={["name", "description"]}
          onFinish={this.filterUpdated.bind(this)}
        />
      </div>
    );
  }

  render() {
    return (
      <div className="container-fluid">
        {this.renderConfirmationAlert()}
        <TitleBox title="Meal Plan Overview" />
        <div className="gap20" />
        <div className="card">
          <div className="list-group list-group-flush">
            {this.renderFilterView()}
            <div className="list-group-item">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Thumbnail</th>
                    <th>Name</th>
                    <th>Description</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>{this.renderPlans()}</tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  mealPlans: state.mealPlanReducer.mealPlans
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getMealPlans
    },
    dispatch
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewMealPlan);
