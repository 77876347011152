import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment-timezone";
import { callGetChlgsByQuery, callGetReports } from "../../../../services";
import TitleBox from "../../../../components/general/titleBox";
import DatePicker from "react-date-picker";
import { exportCSV } from "../../../../helpers/exportCSV";

class ChallengeProgressReport extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      selectedActiveChallenge: "",
      activeChallengeUsers: [],
      activeChallengeUsersBackup: [],
      selectedActiveStartDate: new Date(),
      chlgs: [],
      fromDate: moment().subtract(1, "year").toDate(),
      toDate: moment().add(1, "year").toDate(),
      keyDate: Math.random(),
      showFilter: false,
    };
  }

  componentWillMount() {
    this.getChallenges();
  }

  async getChallenges() {
    let query = {
      query: {
        $select: ["_id", "name"],
        archive: { $ne: true },
      },
    };
    let data = await callGetChlgsByQuery(query);
    this.setState({ chlgs: data.data });
  }

  callGetActiveChallengeUsers(data) {
    data["type"] = "chlg-users-start-date";
    this.setState({ showFilter: false });
    callGetReports(data).then((d) => {
      this.setState(
        {
          activeChallengeUsers: d,
          activeChallengeUsersBackup: d,
          showFilter: true,
        },
        () => {
          this.filterDate();
        }
      );
    });
  }

  handleActiveInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    let data = [];
    data.selectedChallenge = value;
    // data.challengeStartDate = moment(this.state.selectedActiveStartDate).format(
    //   "MM-DD-YYYY"
    // );
    this.setState(
      {
        selectedActiveChallenge: value,
      },
      () => {
        // this.callGetActiveChallengeUsers(data);
      }
    );
  }

  onChangeActiveChallengeStartDate(value) {
    let data = [];
    data.selectedChallenge = this.state.selectedActiveChallenge;
    data.challengeStartDate = moment(value).format("MM-DD-YYYY");
    this.setState(
      {
        selectedActiveStartDate: value,
      },
      () => {
        this.callGetActiveChallengeUsers(data);
      }
    );
  }

  renderChallenges() {
    let returnVals = [];
    let challenges = this.state.chlgs;
    returnVals.push(<option value={""} key={0} />);
    for (let i = 0; i < challenges.length; i++) {
      returnVals.push(
        <option value={challenges[i]._id} key={i + 1}>
          {challenges[i].name}
        </option>
      );
    }
    return returnVals;
  }

  onChangeFromDate(date) {
    this.setState(
      {
        fromDate: date,
      },
      () => {
        this.filterDate();
      }
    );
  }

  onChangeToDate(date) {
    this.setState(
      {
        toDate: date,
      },
      () => {
        this.filterDate();
      }
    );
  }

  filterDate() {
    let fromDate = this.state.fromDate;
    let toDate = this.state.toDate;

    let payments = this.state.activeChallengeUsersBackup;
    let fPayments = payments.filter((d) =>
      moment(d.start_date).isBetween(moment(fromDate), moment(toDate))
    );
    this.setState({
      activeChallengeUsers: fPayments,
    });
  }

  onClickExport() {
    let users = this.state.activeChallengeUsers;

    let rows = [];

    let headers = [
      { id: "name", display: "Name" },
      { id: "email", display: "Email Address" },
      { id: "startDate", display: "Start Date" },
      { id: "status", display: "Status" },
      { id: "progress", display: "Percentage Completed" },
    ];

    users.forEach((element) => {
      rows.push({
        name: element.member_info.name,
        email: element.member_info.email_address,
        startDate: moment(element.start_date, "MM-DD-YYYY").format(
          "Do MMM YYYY"
        ),
        progress: this.calculateProgress(element),
        status: element.status,
      });
    });
    exportCSV(headers, rows, "challenge-users");
  }

  calculateProgress(element) {
    let perCompleted = "0%";
    if (element.challenge_info) {
      let challengeItemList = element.challenge_info.challenge_item_list || [];
      let totalPoints = 0;
      challengeItemList.forEach((itemL) => {
        if (itemL.complete_item_points) {
          totalPoints += parseInt(itemL.complete_item_points);
        }
      });

      let challengePoints = 0;
      let itemCompleted = element.item_completed || [];
      itemCompleted.forEach((itemC) => {
        if (itemC.points) {
          challengePoints += parseInt(itemC.points, 10);
        }
      });

      if (totalPoints > 0 && challengePoints > 0) {
        let per = ((challengePoints * 100) / totalPoints).toFixed(0);
        if (per >= 100) {
          per = 100;
        }
        perCompleted = `${per}%`;
      }
    }
    return perCompleted;
  }

  onClickMemberName(id) {
    if (id) {
      let url = `/admin/edit-user?id=${id}`;
      window.open(url, "_blank");
    }
  }

  renderUsers() {
    let users = this.state.activeChallengeUsers;
    let returnVals = [];
    users.forEach((element) => {
      let perCompleted = this.calculateProgress(element);
      returnVals.push(
        <tr key={element._id}>
          <td
            className="pointer-click link"
            onClick={this.onClickMemberName.bind(
              this,
              element.member_info._id
            )}>
            {element.member_info.name}
          </td>
          <td>{element.member_info.email_address}</td>
          <td>
            {moment(element.start_date, "MM-DD-YYYY").format("Do MMM YYYY")}
          </td>
          <td className="firstLetter">{element.status}</td>
          <td>{perCompleted}</td>
        </tr>
      );
    });
    return returnVals;
  }

  render() {
    return (
      <div>
        <div className="container-fluid">
          <TitleBox title="Challenge Users" />
          <div className="gap20" />
          <div className="card">
            <div className="container-fluid">
              <div className="gap20" />
              <div className="row">
                <div className="col-lg-6">
                  <div
                    className={
                      this.state.selectedActiveChallenge !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>Select Challenge</label>
                    <select
                      name="selectedActiveChallenge"
                      value={this.state.selectedActiveChallenge}
                      onChange={(event) => this.handleActiveInputChange(event)}>
                      {this.renderChallenges()}
                    </select>
                  </div>
                </div>
              </div>
              <div className="gap20" />
              <button
                className="btn btn-primary"
                onClick={() => {
                  this.callGetActiveChallengeUsers({
                    selectedChallenge: this.state.selectedActiveChallenge,
                  });
                }}>
                Get Users
              </button>
              <div className="gap20" />

              <div>
                {this.state.showFilter && (
                  <>
                    <div className="row">
                      <div className="col-md-2">
                        <label className="inputFakeLabelFocussed">
                          Select From Date
                        </label>
                        <div>
                          <DatePicker
                            key={this.state.keyDate}
                            value={this.state.fromDate}
                            onChange={this.onChangeFromDate.bind(this)}
                          />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <label className="inputFakeLabelFocussed">
                          Select To Date
                        </label>
                        <div>
                          <DatePicker
                            key={this.state.keyDate}
                            value={this.state.toDate}
                            onChange={this.onChangeToDate.bind(this)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="clearfix">
                      <div className="float-left"></div>
                      <div className="float-right">
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={this.onClickExport.bind(this)}>
                          Export
                        </button>
                      </div>
                    </div>
                  </>
                )}
                <div className="gap20" />
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email Address</th>
                      <th>Start Date</th>
                      <th>Status</th>
                      <th>Percentage Completed</th>
                    </tr>
                  </thead>
                  <tbody>{this.renderUsers()}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChallengeProgressReport);
