import { setItem } from "./storage";

export function registerServiceWorker(serviceWorker) {
  serviceWorker.register({
    onUpdate: (registration) => {
      const waitingServiceWorker = registration.waiting;

      if (waitingServiceWorker) {
        waitingServiceWorker.addEventListener("statechange", (event) => {
          if (event.target.state === "activated") {
            // console.log("New content: Reloading...");
            // window.location.reload(true);
            console.log("New Update Available, Please reload the page...");
            setItem("updateReload", "true");
          }
        });
        waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
      }
    },
  });
}

export function setManifest(data) {
  let siteName = data.site_name || "Member Pages";
  let url = `https://${window.location.hostname}/member`;

  /*eslint-disable*/
  var manifest = {
    short_name: siteName,
    name: siteName,
    icons: [
      {
        src: "https://api.memberpages.app/icons/icon-144x144.png",
        sizes: "144x144",
        type: "image/png",
      },
      {
        src: "https://api.memberpages.app/icons/icon-192x192.png",
        sizes: "192x192",
        type: "image/png",
      },
      {
        src: "https://api.memberpages.app/icons/icon-512x512.png",
        sizes: "512x512",
        type: "image/png",
      },
    ],
    start_url: url,
    display: "standalone",
    theme_color: "#26ace2",
    background_color: "#ffffff",
  };
  /*eslint-enable*/

  const stringManifest = JSON.stringify(manifest);
  const blob = new Blob([stringManifest], { type: "application/json" });
  const manifestURL = URL.createObjectURL(blob);

  document
    .querySelector("#site-manifest-placeholder")
    .setAttribute("href", manifestURL);
}
