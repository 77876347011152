import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import TitleBox from "../../../components/general/titleBox";
import RadioTag from "../../../components/form/radioTag";
import SelectAddImage from "../../../components/form/selectAddImage";
import TagUsers from "../../../components/form/tagUsers";
import DatePicker from "react-date-picker";
import { saveAs } from "file-saver";

import {
  showNotification,
  getUniqueId,
  toPromise,
  imageUrl,
} from "../../../helpers";
import {
  callCreateCertificate,
  callUpdateCertificate,
  callGetCertificateUsingId,
  callResizeAwsImage,
} from "../../../services";

class AddCertificate extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    let certificate = {
      certificateId: 0,
      name: "",
      image: "",
      fields: [],
      uniqueCertiId: getUniqueId(),
      expiryDate: new Date(),
      expiry: "no",
      selectedIncludedTags: [],
      selectedExcludedTags: [],
    };

    if (this.props.history.location.state !== undefined) {
      let cData = this.props.history.location.state.certificate;
      certificate.certificateId = cData._id;
      certificate.name = cData.name || "";
      certificate.image = cData.image || "";
      certificate.fields = cData.fields || [];
      certificate.expiry = cData.expiry ? "yes" : "no";
      certificate.uniqueCertiId = cData.certificate_id || getUniqueId();
      certificate.expiryDate = cData.expiry_date
        ? new Date(cData.expiry_date)
        : new Date();
      certificate.selectedIncludedTags = cData.fincludedtags || [];
      certificate.selectedExcludedTags = cData.fexcludedtags || [];
    }

    this.state = {
      certificate: certificate,
      certificateId: certificate.certificateId,
      name: certificate.name,
      image: certificate.image,
      keyImage: Math.random(),
      keyFieldType: Math.random(),
      fields: certificate.fields,
      fieldType: "name",
      fontSize: 100,
      fontColor: "#000000",
      fontType: "tange",
      initialTextPosition: "center",
      keyInitialTextPosition: Math.random(),
      keyExpire: Math.random(),
      horizontalOffset: 0,
      verticalOffset: 0,
      uniqueCertiId: certificate.uniqueCertiId,
      keyTagUsers: Math.random(),
      selectedFieldIndex: -1,
      expiryDate: certificate.expiryDate,
      expiry: certificate.expiry,
      addTags: certificate.selectedIncludedTags,
      excludeTags: certificate.selectedExcludedTags,
      selectedIncludedTags: certificate.selectedIncludedTags,
      selectedExcludedTags: certificate.selectedExcludedTags,
      userData: this.props.userData || {},
      dateFormat: "dommyyyy",
    };
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  componentWillReceiveProps(newProps) {
    if (newProps.userData) {
      this.setState({
        userData: newProps.userData,
      });
    }
  }

  resetProps() {
    this.setState({
      certificate: {},
      certificateId: 0,
      name: "",
      image: "",
      fields: [],
      keyImage: Math.random(),
      keyFieldType: Math.random(),
      fontSize: 100,
      fontColor: "#000000",
      fontType: "tange",
      initialTextPosition: "center",
      keyInitialTextPosition: Math.random(),
      keyExpire: Math.random(),
      horizontalOffset: 0,
      verticalOffset: 0,
      uniqueCertiId: getUniqueId(),
      keyTagUsers: Math.random(),
      expiryDate: new Date(),
      expiry: "no",
      addTags: [],
      excludeTags: [],
      selectedIncludedTags: [],
      selectedExcludedTags: [],
      dateFormat: "dommyyyy",
    });
  }

  onChangeImage = (image) => {
    this.setState({ image: image });
  };

  onChangeExpiryDate(value) {
    this.setState({
      expiryDate: value,
    });
  }

  async onPressPreview() {
    callGetCertificateUsingId(this.state.certificateId).then((d) => {
      d["member_name"] = this.state.userData.name;
      let data = {
        action: "preview-certificate",
        data: d,
      };

      callResizeAwsImage(data).then((d) => {
        if (d.key) {
          saveAs(imageUrl(d.key), d.key);
        } else {
          showNotification(
            "error",
            "Something went wrong while generating preview",
            4000
          );
        }
      });
    });
  }

  async onPressSave() {
    if (this.state.name.trim().length === 0) {
      showNotification("error", "Name is required", 4000);
      return;
    }

    if (this.state.image.trim().length === 0) {
      showNotification("error", "Image is required", 4000);
      return;
    }

    if (this.state.fields.length === 0) {
      showNotification("error", "Atleast one field is required", 4000);
    }

    let addTagIds = this.state.addTags.map((t) => t._id);
    let excludeTagIds = this.state.excludeTags.map((t) => t._id);

    let apiData = {
      /*eslint-disable*/
      name: this.state.name,
      image: this.state.image,
      fields: this.state.fields,
      certificate_id: this.state.uniqueCertiId,
      expiry: this.state.expiry === "yes" ? true : false,
      expiry_date: this.state.expiryDate,
      tag_list: addTagIds,
      tag_list_excluded: excludeTagIds,
      /*eslint-enable*/
    };

    if (this.state.certificateId === 0) {
      let [err, d] = await toPromise(callCreateCertificate(apiData));
      this.resetProps();
      this.handleResponse(err, d, "added");
    } else {
      let [err, d] = await toPromise(
        callUpdateCertificate(this.state.certificateId, apiData)
      );
      this.handleResponse(err, d, "updated");
    }
  }

  handleResponse(err, data, type) {
    if (err) {
      showNotification("error", err.message, 4000);
    } else {
      showNotification("success", `Certificate ${type}`, 4000);
    }
  }

  onSelectRadioType(item, id) {
    this.setState({ [id]: item[Object.keys(item)[0]].value });
  }

  onChangeAddTags = (tags) => {
    this.setState({ addTags: tags });
  };

  onChangeExcludeTags = (tags) => {
    this.setState({ excludeTags: tags });
  };

  onClickAddField() {
    /*eslint-disable*/
    let field = {
      type: this.state.fieldType,
      font_size: this.state.fontSize,
      font_color: this.state.fontColor,
      font_type: this.state.fontType,
      initial_text_position: this.state.initialTextPosition,
      horizontal_offset: this.state.horizontalOffset,
      vertical_offset: this.state.verticalOffset,
      date_format: this.state.dateFormat,
    };
    /*eslint-enable*/
    let fields = this.state.fields;

    if (this.state.selectedFieldIndex > -1) {
      fields[this.state.selectedFieldIndex] = field;
    } else {
      fields.push(field);
    }

    this.setState({
      fields: fields,
      selectedFieldIndex: -1,
      fieldType: "name",
      fontSize: 100,
      fontColor: "#000000",
      fontType: "tange",
      initialTextPosition: "center",
      horizontalOffset: 0,
      verticalOffset: 0,
      keyFieldType: Math.random(),
      keyInitialTextPosition: Math.random(),
      dateFormat: "dommyyyy",
    });
  }

  onClickCancelField(index) {
    this.setState({
      fieldType: "name",
      fontSize: 100,
      fontColor: "#000000",
      fontType: "tange",
      initialTextPosition: "center",
      horizontalOffset: 0,
      verticalOffset: 0,
      keyFieldType: Math.random(),
      keyInitialTextPosition: Math.random(),
      selectedFieldIndex: -1,
    });
  }

  onClickDeleteField(index) {
    let fields = this.state.fields;
    fields.splice(index, 1);

    this.setState({ fields: fields });
  }

  onClickEditField(index) {
    let field = this.state.fields[index];

    this.setState({
      fieldType: field.type,
      fontSize: field.font_size,
      fontColor: field.font_color,
      fontType: field.font_type,
      initialTextPosition: field.initial_text_position,
      horizontalOffset: field.horizontal_offset,
      verticalOffset: field.vertical_offset,
      selectedFieldIndex: index,
      keyFieldType: Math.random(),
      keyInitialTextPosition: Math.random(),
      dateFormat: field.date_format || "dommyyyy",
    });
  }

  renderFields() {
    let returnVals = [];
    let fields = this.state.fields;

    for (let i = 0; i < fields.length; i++) {
      const element = fields[i];
      const type = element.type === "name" ? "Customer Name" : "Date";

      returnVals.push(
        <div key={i}>
          {type}
          <button
            onClick={this.onClickDeleteField.bind(this, i)}
            className="btn btn-danger btn-sm"
            style={{ float: "right" }}>
            Remove
          </button>
          <button
            onClick={this.onClickEditField.bind(this, i)}
            className="btn btn-default btn-sm margin-right-10"
            style={{ float: "right" }}>
            Edit
          </button>
        </div>
      );
    }
    return returnVals;
  }

  render() {
    return (
      <div>
        <div className="container-fluid">
          <TitleBox
            title={
              this.state.certificateId === 0
                ? "Create New Certificate"
                : "Edit Certificate"
            }
            showBackBtn={this.state.certificateId !== 0}
          />
          <div className="gap20" />
          <div className="card">
            <div className="list-group list-group-flush">
              <div className="list-group-item">
                <div className="row">
                  <div className="col-sm-6">
                    <div
                      className={
                        this.state.name !== ""
                          ? "mdInput mdFocussed"
                          : "mdInput"
                      }>
                      <label>Certificate Name</label>
                      <input
                        className="certificateName"
                        name="name"
                        value={this.state.name}
                        onChange={(event) => this.handleInputChange(event)}
                      />
                    </div>
                  </div>
                </div>
                <div className="gap20" />

                <SelectAddImage
                  type="image"
                  key={this.state.keyImage}
                  label="Image"
                  onChangeImage={this.onChangeImage}
                  image={this.state.image}
                />

                <div className="gap20" />
              </div>
            </div>
          </div>

          <div className="card">
            <div className="container-fluid">
              <div className="gap20" />

              <div className="havingHelpText">
                <h5>Add Fields</h5>
              </div>
              <div className="gap20" />
              <p>Field Type</p>
              <div className="tabsHolder radioTabs">
                <RadioTag
                  key={this.state.keyFieldType}
                  onCheckChange={this.onSelectRadioType.bind(this)}
                  labelList={[
                    { name: "Customer Name", value: "name" },
                    { name: "Date", value: "date" },
                  ]}
                  id={"fieldType"}
                  selectedList={[this.state.fieldType]}
                  selectType={"value"}
                />
              </div>
              {this.state.fieldType === "date" && (
                <>
                  <div className="gap20" />
                  <div className="row">
                    <div className="col-sm-3">
                      <div
                        className={
                          this.state.dateFormat !== ""
                            ? "mdInput mdFocussed"
                            : "mdInput"
                        }>
                        <label>Date Format</label>
                        <select
                          name="dateFormat"
                          value={this.state.dateFormat}
                          onChange={(event) => this.handleInputChange(event)}>
                          <option value="dommyyyy">10th December 2019</option>
                          <option value="mmdoyyyy">December 10th 2019</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div className="gap20" />
              <div className="row">
                <div className="col-sm-3">
                  <div
                    className={
                      this.state.fontSize !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>Font Size</label>
                    <input
                      type="number"
                      name="fontSize"
                      value={this.state.fontSize}
                      onChange={(event) => this.handleInputChange(event)}
                    />
                  </div>
                  <div className="gap10" />
                </div>
                <div className="col-sm-3">
                  <p className="bgSetter mdInput mdFocussed">
                    <input
                      type="color"
                      name="fontColor"
                      value={this.state.fontColor}
                      onChange={(event) => this.handleInputChange(event)}
                    />
                    Font Color
                  </p>
                  <div className="gap20" />
                </div>
                <div className="col-sm-3">
                  <div
                    className={
                      this.state.fontType !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>Font Type</label>
                    <select
                      name="fontType"
                      value={this.state.fontType}
                      onChange={(event) => this.handleInputChange(event)}>
                      <option value="tange">Tangerine</option>
                      <option value="baloo">BalooBhai</option>
                      <option value="itali">Italianno</option>
                      <option value="dancing">Dancing Script</option>
                      <option value="league">League Script</option>
                      <option value="lato">Lato</option>
                      <option value="roboto">Roboto</option>
                      <option value="urbanist">Urbanist</option>
                    </select>
                  </div>
                </div>
              </div>

              <p>Initial Text Position</p>
              <div className="tabsHolder radioTabs">
                <RadioTag
                  key={this.state.keyInitialTextPosition}
                  onCheckChange={this.onSelectRadioType.bind(this)}
                  labelList={[
                    { name: "Center", value: "center" },
                    { name: "Top Left", value: "NorthWest" },
                  ]}
                  id={"initialTextPosition"}
                  selectedList={[this.state.initialTextPosition]}
                  selectType={"value"}
                />
              </div>
              <div className="gap20" />

              <div className="row">
                <div className="col-sm-3">
                  <div
                    className={
                      this.state.horizontalOffset !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>Horizonal Offset (pixels)</label>
                    <input
                      type="number"
                      className="horizontalOffset"
                      name="horizontalOffset"
                      value={this.state.horizontalOffset}
                      onChange={(event) => this.handleInputChange(event)}
                    />
                  </div>
                </div>
                <div className="col-sm-3">
                  <div
                    className={
                      this.state.verticalOffset !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>Vertical Offset (pixels)</label>
                    <input
                      type="number"
                      className="verticalOffset"
                      name="verticalOffset"
                      value={this.state.verticalOffset}
                      onChange={(event) => this.handleInputChange(event)}
                    />
                  </div>
                </div>
              </div>
              <div className="text-right">
                {this.state.selectedFieldIndex > -1 && (
                  <button
                    className="btn btn-default margin-right-10"
                    onClick={this.onClickCancelField.bind(this)}>
                    Cancel
                  </button>
                )}
                <button
                  className="btn btn-primary"
                  onClick={this.onClickAddField.bind(this)}>
                  {this.state.selectedFieldIndex > -1
                    ? "Update Field"
                    : "Add Field"}
                </button>
              </div>
              <div className="gap20" />
              {this.state.fields.length > 0 && (
                <div className="card">
                  <div className="container-fluid">
                    <div className="gap20" />
                    <h5 className="noMargin">Added Fields</h5>
                    <div className="gap20" />
                    <div className="addedRule">{this.renderFields()}</div>
                    <div className="gap20" />
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="card">
            <div className="container-fluid">
              <div className="gap20" />
              <p>Does this certificate expire?</p>
              <div className="tabsHolder radioTabs">
                <RadioTag
                  key={this.state.keyExpire}
                  onCheckChange={this.onSelectRadioType.bind(this)}
                  labelList={[
                    { name: "No", value: "no" },
                    { name: "Yes", value: "yes" },
                  ]}
                  id={"expiry"}
                  selectedList={[this.state.expiry]}
                  selectType={"value"}
                />
              </div>
              <div className="gap20" />
              {this.state.expiry === "yes" && (
                <div className="row">
                  <div className="col-md-3">
                    <label className="inputFakeLabelFocussed">
                      Expiry Date
                    </label>
                    <div>
                      <DatePicker
                        value={this.state.expiryDate}
                        onChange={this.onChangeExpiryDate.bind(this)}
                      />
                    </div>
                    <div className="gap20" />
                  </div>
                </div>
              )}

              <TagUsers
                label={"Add / exclude the tags certificate"}
                key={this.state.keyTagUsers}
                contentId={this.state.certificateId}
                selectedIncludedTags={this.state.selectedIncludedTags}
                selectedExcludedTags={this.state.selectedExcludedTags}
                history={this.props.history}
                onChangeAddTags={this.onChangeAddTags}
                onChangeExcludeTags={this.onChangeExcludeTags}
              />
              <div className="gap20" />
            </div>
          </div>

          <div className="text-right container-fluid">
            {this.state.certificateId !== 0 && (
              <button
                className="btn btn-default margin-right-10"
                onClick={() => this.onPressPreview()}>
                Preview Certificate
              </button>
            )}
            <button
              className="btn btn-primary"
              onClick={() => this.onPressSave()}>
              {this.state.certificateId === 0
                ? "Create Certificate"
                : "Update Certificate"}
            </button>
          </div>
          <div className="gap20" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ userData: state.authReducer.userData });

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddCertificate);
