import * as api from "./api";
import { callApi } from "../helpers";

function callCreateLead(data) {
  return callApi(api.service("lead").create(data));
}

function callUpdateLead(id, data) {
  return callApi(api.service("lead").patch(id, data));
}

function callDeleteLead(id) {
  return callApi(api.service("lead").remove(id));
}

function callGetLeads() {
  return callApi(api.service("lead").find({}));
}

function callGetLeadsByQuery(query) {
  return callApi(api.service("lead").find(query));
}

function callCreateRelease(data) {
  return callApi(api.service("release").create(data));
}

function callUpdateRelease(id, data) {
  return callApi(api.service("release").patch(id, data));
}

function callDeleteRelease(id) {
  return callApi(api.service("release").remove(id));
}

function callGetReleases() {
  return callApi(api.service("release").find({}));
}

function callGetReleasesByQuery(query) {
  return callApi(api.service("release").find(query));
}

function callCreateTier(data) {
  return callApi(api.service("tier").create(data));
}

function callUpdateTier(id, data) {
  return callApi(api.service("tier").patch(id, data));
}

function callDeleteTier(id) {
  return callApi(api.service("tier").remove(id));
}

function callGetTierById(id) {
  return callApi(api.service("tier").get(id));
}

function callGetTiers() {
  return callApi(api.service("tier").find({}));
}

function callGetTiersByQuery(query) {
  return callApi(api.service("tier").find(query));
}

export {
  callCreateLead,
  callUpdateLead,
  callDeleteLead,
  callGetLeads,
  callGetLeadsByQuery,
  callCreateRelease,
  callUpdateRelease,
  callDeleteRelease,
  callGetReleases,
  callGetReleasesByQuery,
  callCreateTier,
  callUpdateTier,
  callDeleteTier,
  callGetTiers,
  callGetTiersByQuery,
  callGetTierById,
};
