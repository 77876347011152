import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import TitleBox from "../../../components/general/titleBox";
import RadioTag from "../../../components/form/radioTag";
import TypeableSelect from "../../../components/form/typeableSelect";
import { arrayMove } from "react-sortable-hoc";
import PagePopup from "../../../components/popup/pagePopup";

import {
  slugify,
  showNotification,
  toPromise,
  openPagePopupModal,
} from "../../../helpers";
import {
  callGetLandingPages,
  callCreateLandingPage,
  callUpdateLandingFunnel,
  callCreateLandingFunnel,
} from "../../../services";
import { SortableLandingPageList } from "../../../components/form/sortableList";
import { SettingsHelper } from "../../../helpers/settings";

class AddLandingFunnelPage extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    let funnel = {
      funnelId: 0,
      name: "",
      selectedLandingPages: [],
      sLandingPages: [],
    };

    if (this.props.history.location.state !== undefined) {
      let fData = this.props.history.location.state.item;

      funnel.funnelId = fData._id;
      funnel.name = fData.name;
      funnel.sLandingPages = fData.landing_pages;
    }

    this.state = {
      funnelId: funnel.funnelId,
      name: funnel.name,
      landingName: "",
      landingUrl: "",
      landingPages: [],
      keySelectOption: Math.random(),
      option: "",
      keySelectLandingPage: Math.random(),
      selectedLandingPages: [],
      sLandingPages: funnel.sLandingPages,
      selectedLandingPage: { value: "", label: "" },
      popupType: "",
      popupLabel: "Edit Landing Page",
      editLandingPage: {},
      popupKey: Math.random(),
      accountKey: "",
      domainList: [],
    };
  }

  componentDidMount() {
    this.getLandingPages();
  }

  async getLandingPages() {
    let response = await callGetLandingPages();
    let items = [];
    response.data.forEach((element) => {
      items.push({
        value: element,
        label: element.name,
      });
    });
    let settingsHelper = new SettingsHelper();
    let accountKey = settingsHelper.getAccountKey();
    let domainList = settingsHelper.getDomainList();

    this.setState(
      {
        landingPages: items,
        keySelectLandingPage: Math.random(),
        accountKey,
        domainList,
      },
      () => {
        if (this.state.funnelId !== 0) {
          this.prepareLandingPageList();
        }
      }
    );
  }

  prepareLandingPageList() {
    let sLandingPages = this.state.sLandingPages;
    let landingPages = [];
    for (let i = 0; i < sLandingPages.length; i++) {
      const element = sLandingPages[i];
      let fLandingPages = this.state.landingPages.filter(
        (d) => d.value._id === element
      );
      if (fLandingPages.length > 0) {
        landingPages.push(fLandingPages[0].value);
      }
    }
    this.setState({
      selectedLandingPages: landingPages,
      keySelectLandingPage: Math.random(),
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  handleOnBlurName(event) {
    const value = event.target.value;
    if (
      value.toLowerCase() === this.state.landingUrl ||
      this.state.landingUrl.length === 0
    ) {
      this.setState({ landingUrl: slugify(value) });
    }
  }

  resetProps() {
    this.setState({
      funnelId: 0,
      name: "",
      landingName: "",
      landingUrl: "",
      keySelectOption: Math.random(),
      option: "",
      keySelectLandingPage: Math.random(),
      selectedLandingPages: [],
      selectedLandingPage: { value: "", label: "" },
    });
  }

  onSelectType(item, id) {
    let value = item[Object.keys(item)[0]].value;
    this.setState({ [id]: value });
  }

  onSelectLandingPage = (item) => {
    let selectedLandingPages = this.state.selectedLandingPages;
    selectedLandingPages.push(item.value);
    this.setState({
      selectedLandingPages,
      selectedLandingPage: { value: "", label: "" },
      keySelectLandingPage: Math.random(),
      landingName: "",
      landingUrl: "",
    });
  };

  onSortEndLandingPageList = ({ oldIndex, newIndex }) => {
    let selectedLandingPages = this.state.selectedLandingPages;
    selectedLandingPages = arrayMove(selectedLandingPages, oldIndex, newIndex);
    this.setState({
      selectedLandingPages,
    });
  };

  onPressRemoveLandingPage(e) {
    let index = e.target.dataset["value"];
    let selectedLandingPages = this.state.selectedLandingPages;
    selectedLandingPages.splice(index, 1);
    this.setState({
      selectedLandingPages,
    });
  }

  getPreviewLink(url) {
    let landingUrl = "";
    if (url.length > 0) {
      let domainList = this.state.domainList;
      let landingUrls = domainList.filter((d) => {
        return d.type === "landing";
      });
      url = slugify(url);

      landingUrl = `https://site.memberpages.app/${url}?key=${this.state.accountKey}`;
      if (landingUrls.length > 0) {
        landingUrl = `https://${landingUrls[0].domain}/${url}`;
      }
    }
    return landingUrl;
  }

  onPressPreview(url) {
    if (url.length > 0) {
      let landingUrl = this.getPreviewLink(url);
      window.open(landingUrl, "_blank");
    }
  }

  onPressPreviewLandingPage(e) {
    let index = e.target.dataset["value"];
    let selectedLandingPages = this.state.selectedLandingPages;
    let landingPage = selectedLandingPages[index];
    this.onPressPreview(landingPage.url);
  }

  onPressEditLandingPage(e) {
    let index = e.target.dataset["value"];
    let selectedLandingPages = this.state.selectedLandingPages;
    let landingPage = selectedLandingPages[index];
    this.setState(
      {
        popupType: "editLandingPage",
        popupLabel: this.state.popupLabel,
        popupKey: Math.random(),
        editLandingPage: landingPage,
      },
      () => {
        openPagePopupModal("editLandingPage");
      }
    );
  }

  onUpdateLandingPage() {
    this.getLandingPages();
  }

  async onPressCreateLandingPage() {
    if (this.state.landingName.trim().length === 0) {
      showNotification("error", "Name is required", 4000);
      return;
    }

    if (this.state.landingUrl.trim().length === 0) {
      showNotification("error", "Url is required", 4000);
      return;
    }

    /*eslint-disable*/
    let apiData = {
      name: this.state.landingName,
      url: slugify(this.state.landingUrl),
    };
    /*eslint-enable*/

    let [err, data] = await toPromise(callCreateLandingPage(apiData));
    /*eslint-enable*/
    if (err) {
      showNotification("error", err.message, 5000);
    } else {
      this.onSelectLandingPage({ value: data });
    }
  }

  async onPressSave() {
    if (this.state.name.trim().length === 0) {
      showNotification("error", "Name is required", 4000);
      return;
    }
    let landingPages = this.state.selectedLandingPages.map((i) => i._id);
    /*eslint-disable*/
    let apiData = {
      name: this.state.name,
      landing_pages: landingPages,
    };
    /*eslint-enable*/

    if (this.state.funnelId !== 0) {
      /*eslint-disable*/
      let [err, data] = await toPromise(
        callUpdateLandingFunnel(this.state.funnelId, apiData)
      );

      if (err) {
        showNotification("error", err.message, 5000);
      } else {
        showNotification("success", "Landing Funnel updated", 4000);
      }
    } else {
      let [err, data] = await toPromise(callCreateLandingFunnel(apiData));
      /*eslint-enable*/
      if (err) {
        showNotification("error", err.message, 5000);
      } else {
        showNotification("success", "Landing Funnel created", 4000);
        this.resetProps();
        this.props.history.push("/admin/add-landing-funnel", {
          item: data,
        });
        window.location.reload();
      }
    }
  }

  renderNewSection() {
    return (
      <div className="card">
        <div className="container-fluid">
          <div
            className={
              this.state.landingName !== "" ? "mdInput mdFocussed" : "mdInput"
            }>
            <label>Name</label>
            <input
              type="text"
              name="landingName"
              onChange={this.handleInputChange.bind(this)}
              value={this.state.landingName}
              onBlur={(event) => this.handleOnBlurName(event)}
            />
          </div>
          <div className="gap20" />
          <div
            className={
              this.state.landingUrl !== "" ? "mdInput mdFocussed" : "mdInput"
            }>
            <label>Url</label>
            <input
              type="text"
              name="landingUrl"
              onChange={this.handleInputChange.bind(this)}
              value={this.state.landingUrl}
            />
          </div>
          <div className="gap10" />
          <div className="text-right">
            <button
              className="btn btn-primary"
              onClick={() => this.onPressCreateLandingPage()}>
              Create
            </button>
          </div>
          <div className="gap10" />
        </div>
      </div>
    );
  }

  renderExistingSection() {
    return (
      <div>
        <TypeableSelect
          key={this.state.keySelectLandingPage}
          name={"selectedLandingPage"}
          placeholder={"Select Landing Page"}
          selected={this.state.selectedLandingPage}
          onSelect={this.onSelectLandingPage}
          options={this.state.landingPages}
        />
        <div className="gap20" />
      </div>
    );
  }

  render() {
    return (
      <div>
        <PagePopup
          type={this.state.popupType}
          label={this.state.popupLabel}
          key={this.state.popupKey}
          landingPage={this.state.editLandingPage}
          onCreate={this.onUpdateLandingPage.bind(this)}
        />
        <div className="container-fluid">
          <TitleBox
            title={
              this.state.funnelId === 0
                ? "Add New Landing Funnel"
                : "Edit Landing Funnel"
            }
            showBackBtn={this.state.funnelId !== 0}
          />
          <div className="gap20" />
        </div>
        <div className="container-fluid">
          <div className="card">
            <div className="container-fluid">
              <div
                className={
                  this.state.name !== "" ? "mdInput mdFocussed" : "mdInput"
                }>
                <label>Name</label>
                <input
                  type="text"
                  name="name"
                  onChange={this.handleInputChange.bind(this)}
                  value={this.state.name}
                />
              </div>
              <div className="gap20" />
              <div className="radioBHolder">
                <div className="tabsHolder radioTabs">
                  <RadioTag
                    key={this.state.keySelectOption}
                    onCheckChange={this.onSelectType.bind(this)}
                    labelList={[
                      { name: "Create New", value: "new" },
                      { name: "Select Existing", value: "existing" },
                    ]}
                    id={"option"}
                    selectedList={[this.state.option]}
                    selectType={"value"}
                  />
                </div>
              </div>
              <div className="gap20" />
              {this.state.option === "new" && this.renderNewSection()}
              {this.state.option === "existing" && this.renderExistingSection()}
            </div>
          </div>
        </div>
        {this.state.selectedLandingPages.length > 0 && (
          <div className="container-fluid">
            <div className="card">
              <div className="container-fluid">
                <div className="gap10" />
                <h5>Added Landing Pages</h5>
                <div className="gap20" />
                <SortableLandingPageList
                  items={this.state.selectedLandingPages}
                  onSortEnd={this.onSortEndLandingPageList}
                  onPressEdit={this.onPressEditLandingPage.bind(this)}
                  onPressRemove={this.onPressRemoveLandingPage.bind(this)}
                  onPressPreview={this.onPressPreviewLandingPage.bind(this)}
                />
                <div className="gap20" />
              </div>
            </div>
          </div>
        )}
        <div className="text-right container-fluid">
          <button
            className="btn btn-primary"
            onClick={() => this.onPressSave()}>
            Submit
          </button>
        </div>
        <div className="gap20" />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddLandingFunnelPage);
