import * as api from "./api";
import { callApi } from "../helpers";

function callCreateTextMessage(data) {
  return callApi(api.service("text-message").create(data));
}

function callUpdateTextMessage(id, data) {
  return callApi(api.service("text-message").patch(id, data));
}

function callUpdateTextMessageByQuery(data, query) {
  return callApi(api.service("text-message").patch(null, data, query));
}

function callDeleteTextMessage(id) {
  return callApi(api.service("text-message").remove(id));
}

function callGetTextMessages() {
  return callApi(api.service("text-message").find({}));
}

function callGetTextMessageById(id) {
  return callApi(api.service("text-message").get(id));
}

function callGetTextMessagesByQuery(query) {
  return callApi(api.service("text-message").find(query));
}

function callGetAvailableTwilioNumbers(query) {
  return callApi(
    api.service("general").get("get-twilio-available-numbers", { query: query })
  );
}

function callGetEventCancelations() {
  return callApi(api.service("general").get("get-event-cancelations"));
}

function callGetTaskPayouts(userId) {
  return callApi(
    api
      .service("general")
      .get("get-task-user-payouts", { query: { userId: userId } })
  );
}

function callCreateTwilioSubaccount(name) {
  return callApi(
    api
      .service("general")
      .get("create-twilio-account", { query: { name: name } })
  );
}

function callSendTwilioSMS(data) {
  return callApi(
    api.service("general").get("send-twilio-sms", { query: data })
  );
}

function callCreateTwilioNumber(phoneNumber, accountSid, token, accountKey) {
  return callApi(
    api.service("general").get("create-twilio-number", {
      query: {
        phoneNumber: phoneNumber,
        accountSid: accountSid,
        token: token,
        accountKey: accountKey,
      },
    })
  );
}

function callCreateSMSConversations(data) {
  return callApi(api.service("sms-conversation").create(data));
}

function callGetSMSConversations(query) {
  return callApi(api.service("sms-conversation").find(query));
}

function callGetPageSMSConversations(query) {
  return callApi(api.service("sms-conversation/page").find(query));
}

function callGetSMSConversationById(id) {
  return callApi(api.service("sms-conversation").get(id));
}

function callUpdateSMSConversation(id, data) {
  return callApi(api.service("sms-conversation").patch(id, data));
}

function callCreateIncomingRule(data) {
  return callApi(api.service("incoming-rule").create(data));
}

function callUpdateIncomingRule(id, data) {
  return callApi(api.service("incoming-rule").patch(id, data));
}

function callDeleteIncomingRule(id) {
  return callApi(api.service("incoming-rule").remove(id));
}

function callGetIncomingRules() {
  return callApi(api.service("incoming-rule").find({}));
}

function callGetIncomingRulesByQuery(query) {
  return callApi(api.service("incoming-rule").find(query));
}

function callCreateTextMessageRule(data) {
  return callApi(api.service("text-message-rule").create(data));
}

function callUpdateTextMessageRule(id, data) {
  return callApi(api.service("text-message-rule").patch(id, data));
}

function callDeleteTextMessageRule(id) {
  return callApi(api.service("text-message-rule").remove(id));
}

function callGetTextMessageRules() {
  return callApi(api.service("text-message-rule").find({}));
}

function callGetTextMessageRulesByQuery(query) {
  return callApi(api.service("text-message-rule").find(query));
}

export {
  callCreateTextMessage,
  callUpdateTextMessage,
  callDeleteTextMessage,
  callGetTextMessages,
  callGetTextMessagesByQuery,
  callGetAvailableTwilioNumbers,
  callCreateTwilioSubaccount,
  callCreateTwilioNumber,
  callGetSMSConversations,
  callUpdateSMSConversation,
  callSendTwilioSMS,
  callGetSMSConversationById,
  callCreateIncomingRule,
  callUpdateIncomingRule,
  callDeleteIncomingRule,
  callGetIncomingRules,
  callGetIncomingRulesByQuery,
  callGetPageSMSConversations,
  callCreateTextMessageRule,
  callUpdateTextMessageRule,
  callDeleteTextMessageRule,
  callGetTextMessageRules,
  callGetTextMessageRulesByQuery,
  callUpdateTextMessageByQuery,
  callGetTextMessageById,
  callCreateSMSConversations,
  callGetTaskPayouts,
  callGetEventCancelations,
};
