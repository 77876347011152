import React from "react";
import ContentRow from "./row";
import AddNewSVG from "../images/addnew.svg";
import { useRecoilValue } from "recoil";
import { editorTypeState, jsonState } from "../recoil/atoms";
import TrashSVG from "../images/trash.svg";
import SettingsSVG from "../images/settings.svg";

function ContentSection(props) {
  let section = props.section;
  const editorType = useRecoilValue(editorTypeState);
  let gson = useRecoilValue(jsonState);
  let pageWidth = gson.contentEditor.width;
  let isCustomSection = section.subId && section.nonCustomizable;

  const onClickAddRow = (sectionId, event) => {
    event.stopPropagation();
    if (!isCustomSection) {
      props.onClickAddRow(sectionId);
    }
  };

  const onClickRow = (rowId) => {
    if (!isCustomSection) {
      props.onClickRow(section.attributes.id, rowId);
    }
  };

  const onClickColumn = (rowId, colId) => {
    if (!isCustomSection) {
      props.onClickColumn(section.attributes.id, rowId, colId);
    }
  };

  const onClickItem = (colId, rowId, item) => {
    if (!isCustomSection) {
      props.onClickItem(section.attributes.id, rowId, colId, item);
    }
  };

  const onClickAddHtml = (colId, rowId, item) => {
    if (!isCustomSection) {
      props.onClickAddHtml(section.attributes.id, rowId, colId, item);
    }
  };

  const onClickAddItem = (colId, rowId) => {
    if (!isCustomSection) {
      props.onClickAddItem(section.attributes.id, rowId, colId);
    }
  };

  const onClickAddImage = (colId, rowId, item, url) => {
    if (!isCustomSection) {
      props.onClickAddImage(section.attributes.id, rowId, colId, item, url);
    }
  };

  const onClickAddVideo = (colId, rowId, item, url) => {
    if (!isCustomSection) {
      props.onClickAddVideo(section.attributes.id, rowId, colId, item, url);
    }
  };

  const onClickSelectGrid = (rowId) => {
    if (!isCustomSection) {
      props.onClickSelectGrid(section.attributes.id, rowId);
    }
  };

  const onDeleteRow = (rowId) => {
    if (!isCustomSection) {
      props.onDeleteRow(section.attributes.id, rowId);
    }
  };

  const onClickSection = (event) => {
    event.stopPropagation();
    props.onClickSection(section.attributes.id);
  };

  const onClickDelete = () => {
    props.onDeleteSection(section.attributes.id);
  };

  const renderRow = () => {
    let rows = section.itemList;
    let width = section.attributes.attributes.maxWidth;
    let usePageWidth = section.usePageWidth;
    let returnVals = [];

    rows.forEach((element) => {
      returnVals.push(
        <ContentRow
          row={element}
          width={width}
          usePageWidth={usePageWidth}
          key={element.attributes.id}
          onClickRow={onClickRow}
          onClickColumn={onClickColumn}
          onClickItem={onClickItem}
          onClickAddHtml={onClickAddHtml}
          onDeleteRow={onDeleteRow}
          onClickAddItem={onClickAddItem}
          onClickSelectGrid={onClickSelectGrid}
          onClickAddImage={onClickAddImage}
          onClickAddVideo={onClickAddVideo}
        />
      );
    });
    return returnVals;
  };

  if (editorType === "email") {
    const alignment = section.attributes.attributes.textAlign || "center";
    return (
      <div
        className={`${
          section.itemList.length > 0
            ? "outerSection outerSectionEmpty"
            : "outerSection"
        }  ${section.selected ? "sectionSelected" : ""} `}
        onClick={onClickSection.bind(this)}>
        <span className="elemSectionLabel">
          Section{" "}
          <img
            src={TrashSVG}
            alt="delete"
            height={10}
            style={{ cursor: "pointer" }}
            onClick={onClickDelete.bind(this)}
          />
        </span>
        <div className="addNewRowHoldLabel">
          {/*eslint-disable*/}
          <a
            className="elemSectionAdd"
            onClick={onClickAddRow.bind(this, section.attributes.id)}>
            <img className="addNewIcon" src={AddNewSVG} />
            &nbsp; Add Row
          </a>
        </div>

        <div className="outerSectionInner">
          <table
            width="100%"
            className="mobile"
            cellPadding="0"
            cellSpacing="0"
            border="0"
            id={section.attributes.id}
            style={section.attributes.attributes}>
            <tbody>
              <tr>
                <td width="100%" valign="top" align={alignment}>
                  {section.itemList.length === 0 && (
                    <div className="addNewRowHold">
                      <a
                        onClick={onClickAddRow.bind(
                          this,
                          section.attributes.id
                        )}>
                        ADD ROW
                      </a>
                    </div>
                  )}
                  {/*eslint-enable*/}
                  {renderRow()}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  } else if (editorType === "web" || editorType === "popup") {
    let sectionAttributes = JSON.parse(
      JSON.stringify(section.attributes.attributes)
    );
    let maxWidth = sectionAttributes.maxWidth || pageWidth + "px";

    if (section.usePageWidth) {
      maxWidth = pageWidth + "px";
    }

    let backgroundFull = sectionAttributes.backgroundFull;
    if (backgroundFull === undefined) {
      backgroundFull = true;
    }

    let backgroundAttributes = {
      backgroundImage: sectionAttributes.backgroundImage,
      backgroundRepeat: sectionAttributes.backgroundRepeat,
      backgroundAttachment: sectionAttributes.backgroundAttachment,
      backgroundPosition: sectionAttributes.backgroundPosition,
      backgroundSize: sectionAttributes.backgroundSize,
      backgroundColor: sectionAttributes.backgroundColor,
      maxWidth: backgroundFull ? "100%" : maxWidth,
      margin: "0 auto",
      outline: `${sectionAttributes.outline}`,
      borderRadius: sectionAttributes.borderRadius,
    };

    delete sectionAttributes.backgroundImage;
    delete sectionAttributes.backgroundRepeat;
    delete sectionAttributes.backgroundPosition;
    delete sectionAttributes.backgroundAttachment;
    delete sectionAttributes.backgroundSize;
    delete sectionAttributes.backgroundColor;
    delete sectionAttributes.outline;
    delete sectionAttributes.border;
    delete sectionAttributes.borderRadius;

    sectionAttributes["maxWidth"] = maxWidth;

    return (
      <div
        className={`${
          section.itemList.length > 0
            ? "mp-section outerSection outerSectionEmpty"
            : "mp-section outerSection"
        }  ${section.selected ? "sectionSelected" : ""} `}
        onClick={onClickSection.bind(this)}>
        <span className="elemSectionLabel">
          {isCustomSection ? "Custom Section" : "Section"}{" "}
          <img
            src={TrashSVG}
            alt="delete"
            height={10}
            style={{ cursor: "pointer" }}
            onClick={onClickDelete.bind(this)}
          />
          {isCustomSection && (
            <img
              src={SettingsSVG}
              alt="edit"
              height={10}
              style={{ cursor: "pointer" }}
              onClick={() => {
                let url = `/admin/add-custom-section?id=${section.dbSubId}`;
                window.open(url, "_blank");
              }}
            />
          )}
        </span>
        <div className="addNewRowHoldLabel">
          {/*eslint-disable*/}
          <a
            className="elemSectionAdd"
            onClick={onClickAddRow.bind(this, section.attributes.id)}>
            <img className="addNewIcon" src={AddNewSVG} />
            &nbsp; Add Row
          </a>
        </div>

        <div className="outerSectionInner">
          <div style={backgroundAttributes}>
            <div
              cellPadding="0"
              cellSpacing="0"
              border="0"
              id={section.attributes.id}
              style={sectionAttributes}
              className="mp-section">
              {section.itemList.length === 0 && (
                <div className="addNewRowHold">
                  <a onClick={onClickAddRow.bind(this, section.attributes.id)}>
                    ADD ROW
                  </a>
                </div>
              )}
              {/*eslint-enable*/}
              {renderRow()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContentSection;
