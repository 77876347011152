import * as api from "./api";
import { callApi } from "../helpers";

function callCreateCalendarEvent(data) {
  return callApi(api.service("calendar-event").create(data));
}

function callUpdateCalendarEvent(id, data) {
  return callApi(api.service("calendar-event").patch(id, data));
}

function callGetCalendarEventsUsingQuery(query) {
  return callApi(api.service("calendar-event").find(query));
}

function callUpdateCalendarEventsUsingQuery(data, query) {
  return callApi(api.service("calendar-event").patch(null, data, query));
}

function callGetCalendarEventById(id) {
  return callApi(api.service("calendar-event").get(id));
}

function callDeleteCalendarEventById(id) {
  return callApi(api.service("calendar-event").remove(id));
}

function callCreateEventCustomer(data) {
  return callApi(api.service("event-registration").create(data));
}

function callUpdateEventCustomer(id, data) {
  return callApi(api.service("event-registration").patch(id, data));
}

function callGetEventCustomersUsingQuery(query) {
  return callApi(api.service("event-registration").find(query));
}

function callGetEventCustomerById(id) {
  return callApi(api.service("event-registration").get(id));
}

function callDeleteEventCustomerById(id) {
  return callApi(api.service("event-registration").remove(id));
}

function callGetZoomUsers() {
  return callApi(api.service("general/zoom/users").find());
}

function callGetZoomMeetings(userId) {
  return callApi(
    api.service("general/zoom/meetings").find({ query: { userId: userId } })
  );
}

function callGetZoomRegistrants(meetingId) {
  return callApi(
    api
      .service("general/zoom/registrants")
      .find({ query: { meetingId: meetingId } })
  );
}

function callCreateEvent(data) {
  return callApi(api.service("event").create(data));
}

function callUpdateEvent(id, data) {
  return callApi(api.service("event").patch(id, data));
}

function callGetEventsUsingQuery(query) {
  return callApi(api.service("event").find(query));
}

function callUpdateEventsUsingQuery(data, query) {
  return callApi(api.service("event").patch(null, data, query));
}

function callGetEventById(id) {
  return callApi(api.service("event").get(id));
}

function callDeleteEventById(id) {
  return callApi(api.service("event").remove(id));
}

function callCreateEventCustomerInfo(data) {
  return callApi(api.service("register-event-customer").create(data));
}

export {
  callCreateEvent,
  callUpdateEvent,
  callGetEventsUsingQuery,
  callUpdateEventsUsingQuery,
  callGetEventById,
  callDeleteEventById,
  callCreateCalendarEvent,
  callUpdateCalendarEvent,
  callGetCalendarEventsUsingQuery,
  callGetCalendarEventById,
  callDeleteCalendarEventById,
  callCreateEventCustomer,
  callUpdateEventCustomer,
  callGetEventCustomersUsingQuery,
  callGetEventCustomerById,
  callDeleteEventCustomerById,
  callGetZoomUsers,
  callGetZoomMeetings,
  callGetZoomRegistrants,
  callUpdateCalendarEventsUsingQuery,
  callCreateEventCustomerInfo,
};
