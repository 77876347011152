import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import DatePicker from "react-date-picker";
import { Pie } from "react-chartjs-2";
import * as $ from "jquery";
import { VelocityTransitionGroup } from "velocity-react";
import { Scrollbars } from "react-custom-scrollbars";

import FilterList from "../../../components/form/filterList";
import { filterByTags } from "../../../helpers/filters";
import RadioTag from "../../../components/form/radioTag";

import images from "../../../helpers/images";
import MessageBox from "../../../components/general/messageBox";
import TitleBox from "../../../components/general/titleBox";
import PredefinedResponse from "../../../components/general/predefinedResponse";
import ReplyAutoResize from "../../../components/form/replyAutoResize";
import TableLoader from "../../../components/loader/table";

import {
  callGetAtqByQuery,
  callUpdateMessage,
  callUpdateUser,
  callGetSystemTagUsingName,
  callAddMemberTag,
  callCreateMessage,
  callGetChlgsByQuery,
  callGetUsersByQuery,
  callGetChartCountData,
  callGetAtqUserIds,
} from "../../../services";
import { showNotification, imageUrl, isFS } from "../../../helpers";
import config from "../../../helpers/config";
import Onboarding from "../onboarding/onboarding";

class AccountabilityTrackingFeedback extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.chartData = [0, 0];
    this.chartLabel = ["Did Not Track", "Tracked"];

    this.state = {
      users: [],
      usersBackup: [],
      userData: this.props.userData || {},
      reply: "",
      selectedUser: {},
      showViewFullConversion: false,
      filterList: [],
      atqResponseTagId: "",
      isCallAPI: true,
      trackingFromDate: new Date(),
      trackingToDate: new Date(),
      filterType: "tracked",
      filterDateType: "yesterday",
      keyFilterType: Math.random(),
      keyTrackingDate: Math.random(),
      keyResponse: Math.random(),
      currentReply: "",
      keyAtqType: Math.random(),
      atqTypes: [],
      cmpUpdate: false,
      showLoader: true,
      pointsData: {},
    };
  }

  componentDidMount() {
    if (this.state.userData._id) {
      this.getUsers();
    }
  }

  getUsers() {
    console.log("Accountability Start", new Date());

    callGetAtqUserIds().then((userData) => {
      /*eslint-disable*/
      let query = {
        _id: { $in: userData.userIds },
      };
      if (userData.staffView) {
        query["assigned_to"] = { $in: [this.state.userData._id] };
      }
      callGetUsersByQuery({
        query: query,
      }).then((d) => {
        /*eslint-enable*/
        console.log("Accountability End", new Date());
        if (d.data.length === 0) {
          this.setState({ showLoader: false });
        } else {
          this.setState({ users: d.data, usersBackup: d.data }, () => {
            let date = new Date();
            date.setDate(date.getDate() - 1);
            this.getMemberQuizResultData(date);
            this.callGetTagByName();
            this.prepareFilterData();
          });
        }
      });
    });
  }

  componentWillReceiveProps(newProps) {
    if (newProps.userData) {
      this.setState({ userData: newProps.userData }, () => {
        this.getUsers();
      });
    }
  }

  shouldComponentUpdate() {
    if (this.state.users.length > 0 || this.state.cmpUpdate) {
      return true;
    }
    return false;
  }

  prepareFilterData() {
    if (this.state.atqTypes.length > 0) {
      return;
    }
    let query = {
      query: {
        $select: ["name"],
      },
    };
    callGetChlgsByQuery(query, false).then((d) => {
      let challengeList = [];
      challengeList.push({ name: "All", value: "all", selected: true });
      if (d.data.length > 0) {
        d.data.forEach((element) => {
          challengeList.push({
            name: element.name,
            value: element.name,
            selected: false,
          });
        });
      }
      if (isFS()) {
        challengeList.push({
          name: "Marci's Circle",
          value: "marci's circle",
          selected: false,
        });
      }
      this.setState({
        atqTypes: challengeList,
        keyAtqType: Math.random(),
      });
    });
  }

  callGetTagByName() {
    let atqResponseTag = config.tags.atqResponseTag;

    callGetSystemTagUsingName(atqResponseTag).then((d) => {
      if (d.data.length > 0) {
        this.setState({ atqResponseTagId: d.data[0]._id });
      }
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    if (name === "notes") {
      let selectedUser = this.state.selectedUser;
      selectedUser.notes = value;
      this.setState({ selectedUser });
    }

    this.setState({
      [name]: value,
    });
  }

  handleKeyPress(user, event) {
    if (event.keyCode === 13 && event.ctrlKey) {
      this.onClickSendReply(user);
    }
  }

  getMemberQuizResultData(date) {
    /*eslint-disable*/
    this.setState({ showLoader: true });
    var fromDate = new Date(date);

    fromDate = moment(fromDate).startOf("day").toDate();

    var toDate = moment(fromDate).startOf("day").add(1, "days").toDate();

    if (this.state.filterDateType === "custom") {
      toDate = moment(new Date(this.state.trackingToDate))
        .startOf("day")
        .add(1, "days")
        .toDate();
    }

    let query = {
      query: {
        $or: [
          {
            tracking_date: {
              $gte: fromDate,
              $lt: toDate,
            },
          },
          {
            createdAt: {
              $gte: fromDate,
              $lt: toDate,
            },
          },
        ],
      },
    };
    callGetAtqByQuery(query).then((data) => {
      this.prepareAtqData(data.data, date);
    });
    /*eslint-enable*/
  }

  prepareAtqData(atqData, date) {
    let users = this.state.usersBackup;
    let inputRef = {};

    users.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });

    users.forEach((element) => {
      let atqResult = atqData.filter((d) => {
        return d.member_id === element._id;
      });
      inputRef[element._id] = React.createRef();

      element["atqData"] = [];
      element["reply"] = "";
      element["showReply"] = true;
      element["atqDate"] = date;

      if (atqResult.length !== 0) {
        element["atqData"] = atqResult;
      }

      element["atqMessages"] = [];
      element["isPendingResponse"] = true;

      var memberChallenges = element.member_challenges;

      memberChallenges.forEach((chlgElement) => {
        chlgElement["currentDay"] = moment()
          .startOf("day")
          .diff(
            moment(chlgElement.start_date, "MM-DD-YYYY", true).startOf("day"),
            "days"
          );

        chlgElement["currentDay"] = chlgElement["currentDay"] + 1;

        // temp condition

        let domain = window.location.hostname;
        if (
          domain.includes("blcf.memberpages.app") ||
          domain.includes("app.benlomondcrossfit.com")
        ) {
          chlgElement["isActive"] = parseInt(chlgElement["currentDay"], 10) > 0;
        } else {
          chlgElement["isActive"] =
            parseInt(chlgElement["currentDay"], 10) <
              parseInt(chlgElement.challenge_days, 10) &&
            chlgElement.status === "active" &&
            parseInt(chlgElement["currentDay"], 10) > 0;
        }

        chlgElement["shortName"] = chlgElement.name.match(/\b\w/g).join("");
      });

      let fMemberChallenges = memberChallenges.filter((d) => {
        return d.isActive === true;
      });
      element["member_challenges"] = fMemberChallenges;

      var memberProducts = element.member_products;

      memberProducts.forEach((productElement) => {
        if (
          productElement.program_info &&
          productElement.program_info[0] &&
          productElement.name === "Custom Program"
        ) {
          let product = productElement.program_info[0];

          productElement["currentDay"] = moment()
            .startOf("day")
            .diff(
              moment(productElement.start_date, "MM-DD-YYYY", true).startOf(
                "day"
              ),
              "days"
            );

          productElement["currentDay"] = productElement["currentDay"] + 1;

          productElement["isActive"] =
            parseInt(productElement["currentDay"], 10) <
              parseInt(product.days, 10) &&
            productElement.status === "active" &&
            parseInt(productElement["currentDay"], 10) > 0;

          productElement["shortName"] = product.name.match(/\b\w/g).join("");
        } else if (productElement.name === "Marci's Circle") {
          productElement["isActive"] = productElement.status === "active";
          productElement["shortName"] = productElement.name
            .match(/\b\w/g)
            .join("");
        }
      });

      let fMemberProducts = memberProducts.filter((d) => {
        return d.isActive === true;
      });
      element["member_products"] = fMemberProducts;

      var messages = element.member_messages;
      if (messages.length > 0) {
        let atqMessages = messages.filter((d) => {
          return d.atq_feedback === "true";
        });
        element["memberAtqMessage"] = atqMessages[0];

        if (atqMessages.length > 0) {
          let messageList = atqMessages[0].message_list;
          messageList.forEach((msg) => {
            msg["userInfo"] = users.filter((u) => {
              return u._id === msg.member_id;
            })[0];
          });
          element["atqMessages"] = messageList;

          let arrUserResponse = messageList.filter((u) => {
            return (
              u.member_id === this.state.userData._id &&
              new Date(u.atq_date).setHours(0, 0, 0, 0) ===
                new Date(date).setHours(0, 0, 0, 0)
            );
          });
          if (arrUserResponse.length > 0) {
            element["isPendingResponse"] = false;
          }
        }
      }
    });

    this.getChartData(users);

    let fUsers = users.filter((d) => {
      return d.atqData.length > 0;
    });

    this.inputRef = inputRef;

    this.setState(
      {
        users: fUsers,
        usersBackup: users,
        filterType: "tracked",
        keyFilterType: Math.random(),
        showLoader: false,
      },
      () => {
        this.filterUsingTags(this.state.usersBackup, this.state.filterList);
      }
    );
  }

  getActiveUsers(users) {
    let activeUsers = [];
    users.forEach((element) => {
      let tempChallengeArr = element.member_challenges.filter((d) => {
        return d.isActive === true;
      });

      let tempProductArr = element.member_products.filter((d) => {
        return d.isActive === true;
      });

      if (tempChallengeArr.length !== 0 || tempProductArr.length !== 0) {
        activeUsers.push(element);
      }
    });
    return activeUsers;
  }

  getChartData(users) {
    let total = 0;
    let tracked = 0;

    users.forEach((element) => {
      let tempChallengeArr = element.member_challenges.filter((d) => {
        return d.isActive === true;
      });

      let tempProductArr = element.member_products.filter((d) => {
        return d.isActive === true;
      });

      if (tempChallengeArr.length !== 0 || tempProductArr.length !== 0) {
        total += 1;
        if (element.atqData.length > 0) {
          tracked += 1;
        }
      }
    });

    this.chartData = [total - tracked, tracked];
    this.chartLabel = [
      `Did Not Track (${total - tracked})`,
      `Tracked (${tracked})`,
    ];
  }

  onClickReplyFromATQ(user) {
    let users = this.state.users;
    users.forEach((element) => {
      if (element._id === user._id) {
        element.showReply = !element.showReply;
      }
    });

    setTimeout(() => {
      let ref = this.inputRef[user._id];
      if (ref) {
        ref.focus();
      }
    }, 100);

    this.setState({ users });
  }

  onClickShowDetailsFromATQ(user) {
    let users = this.state.users;
    users.forEach((element) => {
      if (element._id === user._id) {
        element.isPendingResponse = !element.isPendingResponse;
      }
    });
    this.setState({ users });
  }

  onClickViewConversion(user) {
    this.setState({ selectedUser: user }, () => {
      $(
        ".modalAlertOverlay.conversionOverlay,.modalAlert.conversionAlert"
      ).show();
    });
  }

  onClickViewNotesFromProfile(user) {
    this.setState({ selectedUser: user }, () => {
      $(".modalAlertOverlay.notesOverlay,.modalAlert.notesAlert").show();
    });
  }

  onClickGetPoints(user) {
    /*eslint-disable*/
    let query = {
      member_id: user._id,
    };
    /*eslint-enable*/
    callGetChartCountData(query).then((d) => {
      this.setState({ pointsData: d, selectedUser: user }, () => {
        $(".modalAlertOverlay.pointsOverlay,.modalAlert.pointsAlert").fadeIn(
          200
        );
      });
    });
  }

  onClickInsertResponses(user) {
    this.setState({ selectedUser: user, keyResponse: Math.random() }, () => {
      $(
        ".modalAlertOverlay.responsesOverlay,.modalAlert.responsesAlert"
      ).fadeIn(200);
    });
  }

  onClickCloseFromViewConversionModal() {
    $(
      ".modalAlertOverlay.conversionOverlay,.modalAlert.conversionAlert"
    ).hide();
  }

  onClickCloseFromViewNotesModal() {
    $(".modalAlertOverlay.notesOverlay,.modalAlert.notesAlert").hide();
  }

  onClickCloseFromResponsesModal() {
    $(".modalAlertOverlay.responsesOverlay,.modalAlert.responsesAlert").fadeOut(
      200
    );
  }

  onClickCloseFromPointsModal() {
    $(".modalAlertOverlay.pointsOverlay,.modalAlert.pointsAlert").fadeOut(200);
  }

  onClickSaveFromViewNotesModal() {
    let users = this.state.users;
    users.forEach((element) => {
      if (element._id === this.state.selectedUser._id) {
        element.notes = this.state.selectedUser.notes;
      }
    });
    this.setState({ users });

    /*eslint-disable*/
    callUpdateUser(this.state.selectedUser._id, {
      notes: this.state.selectedUser.notes,
    }).then((d) => {
      if (d._id) {
        showNotification("success", "Note changed", 4000);
      }
    });
    $(".modalAlertOverlay.notesOverlay,.modalAlert.notesAlert").hide();
    /*eslint-enable*/
  }

  onClickSendReply(user) {
    let reply = this.inputRef[user._id].value.trim();
    if (reply.length === 0) {
      showNotification("error", "Please enter reply");
      return;
    }

    /*eslint-disable*/

    let memberAtqMessage = user.memberAtqMessage;

    let message = {
      member_id: this.state.userData._id,
      message: reply,
      status: "sent",
      time: new Date(),
      atq_date: user.atqDate,
    };

    if (memberAtqMessage) {
      // update the already atq message
      callUpdateMessage(memberAtqMessage._id, {
        $push: { message_list: message },
      }).then((updateData) => {
        if (updateData._id) {
          this.updateUserForAtqMessage(user, updateData, message);
        }
      });
    } else {
      // create the new one if no atq message
      let apiData = {
        member_from_id: user._id,
        member_to_id: "",
        assigned_staff_id: "",
        title: "Accountability Tracking Quiz",
        atq_feedback: true,
        message_list: [message],
      };
      callCreateMessage(apiData).then((createData) => {
        if (createData._id) {
          this.updateUserForAtqMessage(user, createData, message);
        }
      });
    }

    /*eslint-enable*/
  }

  updateUserForAtqMessage(user, updateData, message) {
    showNotification("success", "Sent message successfully", 4000);

    let users = this.state.users;

    users.forEach((element) => {
      if (element._id === user._id) {
        element.showReply = !element.showReply;
        element.isPendingResponse = !element.isPendingResponse;
        element.memberAtqMessage = updateData;
        message["userInfo"] = this.state.users.filter((u) => {
          return u._id === message.member_id;
        })[0];
        element.atqMessages.push(message);
      }
    });
    this.setState({
      users: users,
      reply: "",
      isCallAPI: false,
      cmpUpdate: true,
    });
    this.callAtqResponseTagUpdateAPI(user);
  }

  callAtqResponseTagUpdateAPI(user) {
    if (this.state.atqResponseTagId) {
      let apiData = {
        action: "add-member-tag",
        data: {
          /*eslint-disable*/
          member_id: user._id,
          tag_id: this.state.atqResponseTagId,
          /*eslint-enable*/
        },
      };
      callAddMemberTag(apiData);
    }
  }

  renderSendReplyComponent(user) {
    return (
      <div className="responseButtons">
        <div>
          <ReplyAutoResize
            label="Enter your reply"
            onRef={(input) => {
              let inputRef = this.inputRef;
              if (inputRef) {
                inputRef[user._id] = input;
                this.inputRef = inputRef;
              }
            }}
          />
        </div>
        <div className="gap20" />
        <div className="text-left">
          <button
            className="btn btn-primary hideResponseBody margin-right-10"
            onClick={this.onClickSendReply.bind(this, user)}>
            Send
          </button>
          <button
            className="btn btn-primary text-right"
            onClick={this.onClickInsertResponses.bind(this, user)}>
            Insert Predefined Message
          </button>
        </div>
      </div>
    );
  }

  filterUpdated(updatedFilter, name, type, searchFields) {
    let filterList = this.state.filterList;
    filterList[name] = {
      name: name,
      type: type,
      searchFields: searchFields,
      values: updatedFilter,
    };
    this.setState(
      {
        filterList: filterList,
      },
      () => {
        this.filterUsingTags(this.state.usersBackup, filterList);
      }
    );
  }

  filterUsingTags(users, filterList) {
    let data = filterByTags(users, filterList);
    this.getChartData(data);
    this.filterUsingStatus(data);
  }

  filterUsingStatus(users) {
    let fUsers = [];
    let value = this.state.filterType;
    if (value === "tracked") {
      fUsers = users.filter((d) => {
        return d.atqData && d.atqData.length > 0;
      });
    } else {
      fUsers = users.filter((d) => {
        return d.atqData && d.atqData.length === 0;
      });
    }
    this.setState({
      users: fUsers,
      cmpUpdate: true,
    });
  }

  updateByType(type, id) {
    let users = this.state.usersBackup;
    let value = type[Object.keys(type)[0]].value;
    this.setState({ [id]: value }, () => {
      this.filterUsingTags(users, this.state.filterList);
    });
  }

  updateByDate(day) {
    let date = new Date();
    day = day[Object.keys(day)[0]].value;
    this.setState({ filterDateType: day }, () => {
      switch (day) {
        case "yesterday":
          date.setDate(date.getDate() - 1);
          this.getMemberQuizResultData(date);
          break;
        case "today":
          date.setDate(date.getDate());
          this.getMemberQuizResultData(date);
          break;
        case "custom":
          this.setState({ keyTrackingDate: Math.random() });
          $(
            ".modalAlertOverlay.filterDateOverlay,.modalAlert.filterDateAlert"
          ).show();
          break;
        default:
          break;
      }
    });
  }

  renderUserProfileComponent(user) {
    function processHeight(user) {
      if (user.height) {
        let inches = parseFloat(user.height).toFixed(0);
        let feet = Math.floor(inches / 12);
        inches %= 12;
        return (
          <p>
            Height: {feet}' {inches}"
          </p>
        );
      } else {
        return <p>Height: </p>;
      }
    }

    function processChallenges(user) {
      var memberChallenges = user.member_challenges;
      var returnVals = [];
      memberChallenges.forEach((element) => {
        if (element.isActive) {
          if (element.currentDay >= 0) {
            returnVals.push(
              <p key={element._id}>
                Day {element.currentDay} /{element.challenge_days}{" "}
                {element.shortName}
              </p>
            );
          }
        }
      });
      return returnVals;
    }

    function processProducts(user) {
      var memberProducts = user.member_products;
      var returnVals = [];
      memberProducts.forEach((element) => {
        if (
          element.isActive &&
          element.program_info &&
          element.program_info[0]
        ) {
          if (element.currentDay >= 0) {
            returnVals.push(
              <p key={element._id}>
                Day {element.currentDay} /{element.program_info[0].days}{" "}
                {element.shortName}
              </p>
            );
          }
        }
      });
      return returnVals;
    }

    return (
      <div className="col-sm-3 col-md-2">
        {user.profile_image_url !== undefined && (
          <img
            alt="profile"
            src={imageUrl(user.profile_image_url)}
            className="btn-block"
          />
        )}
        {user.profile_image_url === undefined && (
          <img alt="profile" src={images.userImage} className="btn-block" />
        )}

        <div className="gap10" />
        <h5 className="noMargin">{user.name}</h5>
        <div className="userINformation">
          <p>Email: {user.email_address}</p>
        </div>
        {/*eslint-disable-next-line*/}
        <a
          className="text-primary"
          onClick={this.onClickViewNotesFromProfile.bind(this, user)}>
          View Notes
        </a>
        <br />
        <a
          className="text-primary"
          href={`${config.urls.siteUrl}/member/view-progress-journal?mid=${user._id}`}
          rel="noopener noreferrer"
          target="_blank">
          View Progress Journal
        </a>
        <div className="userINformation">
          <div>{processChallenges(user)}</div>
          <div>{processProducts(user)}</div>

          <p>
            Points: {/*eslint-disable-next-line*/}
            <a
              className="text-primary"
              onClick={this.onClickGetPoints.bind(this, user)}>
              View Points
            </a>
          </p>
          {processHeight(user)}
          <p>Weight: {user.weight ? user.weight + "lb" : ""}</p>
          <p>Age: {user.dob ? moment().diff(moment(user.dob), "years") : ""}</p>
          <p>Member Since {moment(user.createdAt).format("MMMM YYYY")}</p>
        </div>
        {/*eslint-disable*/}
        <div>
          <a className="text-primary">Challenge History</a>
        </div>
        <div>
          <a className="text-primary">View Goals</a>
        </div>
        {/*eslint-enable*/}
      </div>
    );
  }

  renderQuestionPoints(user) {
    var atqData = user.atqData;
    var quePoints = [];

    atqData.forEach((atq) => {
      var quizResult = atq.quiz_result_info;
      quizResult.forEach((result) => {
        let fQPoints = quePoints.filter((d) => {
          return d.question === result.name;
        });
        if (fQPoints.length === 0) {
          quePoints.push({
            question: result.name,
            points: atq.result.filter((d) => {
              return d.id === result._id;
            })[0].points,
            selected: atq.result.filter((d) => {
              return d.id === result._id;
            })[0].selected,
          });
        }
      });
    });

    if (quePoints.length > 0) {
      return (
        <tbody>
          <tr>
            {quePoints.map((k) => {
              return <td key={Math.random()}>{k.question}</td>;
            })}
          </tr>
          <tr>
            {quePoints.map((k) => {
              return (
                <td key={Math.random()}>
                  {k.selected ? `${k.selected} (${k.points})` : "-"}
                </td>
              );
            })}
          </tr>
        </tbody>
      );
    }
    return null;
  }

  renderFilterView() {
    return (
      <div className="card">
        <div className="list-group list-group-flush">
          <div className="list-group-item">
            <p>Filter By Challenge</p>
            <FilterList
              key={this.state.keyAtqType}
              filterList={this.state.atqTypes}
              name={"searchType"}
              type={"single"}
              searchFields={[
                "member_challenges->name",
                "member_products->name",
              ]}
              onFinish={this.filterUpdated.bind(this)}
            />
          </div>
          <div className="list-group-item">
            <p>Filter By Type</p>
            <div className="tabsHolder radioTabs">
              <RadioTag
                key={this.state.keyFilterType}
                onCheckChange={this.updateByType.bind(this)}
                labelList={[
                  { name: "Tracked", value: "tracked" },
                  { name: "Did not Track", value: "nottracked" },
                ]}
                selectedList={[this.state.filterType]}
                id={"filterType"}
                selectType={"value"}
              />
            </div>
          </div>

          <div className="list-group-item">
            <p>Filter By Date</p>
            <div className="tabsHolder radioTabs">
              <RadioTag
                onCheckChange={this.updateByDate.bind(this)}
                selectedList={[this.state.filterDateType]}
                selectType={"value"}
                labelList={[
                  { name: "Yesterday", value: "yesterday" },
                  { name: "Today", value: "today" },
                  { name: "Custom", value: "custom" },
                ]}
              />
            </div>
          </div>
          <div className="list-group-item">
            <div className="gap10" />
            <div className="row">
              <div className="col-md-4">
                <Pie
                  legend={{ reverse: true }}
                  data={{
                    labels: this.chartLabel,
                    datasets: [
                      {
                        data: this.chartData,
                        backgroundColor: ["#FF6384", "#36A2EB"],
                        hoverBackgroundColor: ["#FF6384", "#36A2EB"],
                      },
                    ],
                  }}
                />
              </div>
            </div>
            <div className="gap10" />
          </div>
        </div>
      </div>
    );
  }

  renderMessages(user) {
    var messages = user.atqMessages;

    messages.sort((a, b) => {
      return new Date(b.time) - new Date(a.time);
    });

    if (messages.length > 0) {
      let messageList = [];
      let dayDate = moment().startOf("day").subtract(2, "days");
      let last2DayMessages = messages.filter((d) => {
        return moment(d.time).startOf("day").diff(dayDate) >= 2;
      });
      messageList = last2DayMessages;

      if (last2DayMessages.length <= 2) {
        messageList = messages.slice(0, 3);
      }

      if (messages.length === 1) {
        messageList = messages;
      }
      return (
        <MessageBox
          from={"messageList"}
          messages={messageList}
          showTrackingDate={true}
        />
      );
    }

    return null;
  }

  renderChallengeNames(element) {
    let filterChallenges = element.member_challenges
      .filter((d) => {
        return d.status === "active";
      })
      .map((i) => i.name)
      .join(", ");

    let filterProducts = element.member_products.filter((d) => {
      return d.status === "active" && d.name === "Marci's Circle";
    });

    if (filterProducts.length > 0) {
      if (filterChallenges.length > 0) {
        filterChallenges += ", Marci's Circle";
      } else {
        filterChallenges += "Marci's Circle";
      }
    }

    return filterChallenges;
  }

  onChangeTrackingFromDate(date) {
    this.setState({
      trackingFromDate: date,
    });
  }

  onChangeTrackingToDate(date) {
    this.setState({
      trackingToDate: date,
    });
  }

  onClickCloseFromTrackingDateModal() {
    $(
      ".modalAlertOverlay.filterDateOverlay,.modalAlert.filterDateAlert"
    ).hide();
  }

  onClickDoneFromTrackingDateModal() {
    $(
      ".modalAlertOverlay.filterDateOverlay,.modalAlert.filterDateAlert"
    ).hide();
    this.getMemberQuizResultData(this.state.trackingFromDate);
  }

  onClickInsertMessage(data) {
    this.onClickCloseFromResponsesModal();

    let reply = data.message.replace(
      /{{first_name}}/gi,
      this.state.selectedUser.name.split(" ")[0]
    );

    this.inputRef[this.state.selectedUser._id].value += reply;
  }

  renderCustomDateModal() {
    return (
      <div>
        <div className="modalAlertOverlay filterDateOverlay" />
        <div className="modalAlert filterDateAlert">
          <p> Filter By Date </p>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6">
                <label className="inputFakeLabelFocussed">
                  Select From Date
                </label>
                <div>
                  <DatePicker
                    key={this.state.keyTrackingDate}
                    maxDate={new Date()}
                    value={this.state.trackingFromDate}
                    onChange={this.onChangeTrackingFromDate.bind(this)}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <label className="inputFakeLabelFocussed">Select To Date</label>
                <div>
                  <DatePicker
                    key={this.state.keyTrackingDate}
                    maxDate={new Date()}
                    value={this.state.trackingToDate}
                    onChange={this.onChangeTrackingToDate.bind(this)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="alertFooterBtns">
            {/*eslint-disable*/}
            <a
              className="btn btn-default dismissThisModal margin-right-10"
              onClick={this.onClickCloseFromTrackingDateModal.bind(this)}>
              Close
            </a>
            <a
              className="btn btn-primary dismissThisModal"
              onClick={this.onClickDoneFromTrackingDateModal.bind(this)}>
              Done
            </a>
            {/*eslint-enable*/}
          </div>
        </div>
      </div>
    );
  }

  renderAtqFeedback() {
    var returnVals = [];
    let users = this.getActiveUsers(this.state.users);
    for (let i = 0; i < users.length; i++) {
      const element = users[i];

      returnVals.push(
        <div className="container-fluid" key={i}>
          <div className="card">
            <div className="list-group list-group-flush">
              <VelocityTransitionGroup
                enter={{ animation: "slideDown" }}
                leave={{ animation: "slideUp" }}>
                {element.isPendingResponse === true && (
                  <div>
                    <div className="list-group-item">
                      <div className="clearfix">
                        <div className="row">
                          <div className="col-sm-8">
                            <h5 className="noMargin">Pending Response</h5>
                          </div>
                          <div className="col-sm-2">
                            <p className="noMargin">
                              {moment(element.atqDate).format("MMMM Do, YYYY")}
                            </p>
                          </div>
                          <div className="col-sm-2">
                            {/*eslint-disable-next-line*/}
                            <a
                              className="btn btn-sm btn-default btn-block"
                              onClick={this.onClickShowDetailsFromATQ.bind(
                                this,
                                element
                              )}>
                              Hide Details
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="list-group-item responseBody">
                      <div className="container-fluid">
                        <div className="gap20" />
                        <div className="row">
                          {this.renderUserProfileComponent(element)}
                          <div className="col-sm-9 col-md-10">
                            {element.atqData.length === 0 && (
                              <div className="text-center">
                                <h6 className="text-primary">Did Not Track</h6>
                              </div>
                            )}
                            <div className="text-center">
                              <h5>{this.renderChallengeNames(element)}</h5>
                            </div>
                            <div className="gap10" />
                            <div className="ble-responsive">
                              <table className="table table-bordered">
                                {this.renderQuestionPoints(element)}
                              </table>
                            </div>
                            <div className="gap20" />
                            {this.renderMessages(element)}
                            <div className="text-right">
                              {/*eslint-disable*/}
                              {element.atqMessages.length > 0 && (
                                <a
                                  className="btn btn-default btn-sm margin-right-10"
                                  onClick={this.onClickViewConversion.bind(
                                    this,
                                    element
                                  )}>
                                  View Full Conversation
                                </a>
                              )}
                              <a
                                className="btn btn-default btn-sm"
                                onClick={this.onClickReplyFromATQ.bind(
                                  this,
                                  element
                                )}>
                                Reply
                              </a>
                              {/*eslint-enable*/}
                            </div>

                            <VelocityTransitionGroup
                              enter={{ animation: "fadeIn" }}
                              leave={{ animation: "fadeOut" }}>
                              {element.showReply &&
                                this.renderSendReplyComponent(element)}
                            </VelocityTransitionGroup>
                          </div>
                        </div>
                        <div className="gap20" />
                      </div>
                    </div>
                  </div>
                )}
              </VelocityTransitionGroup>

              {element.isPendingResponse === false && (
                <div className="list-group-item">
                  <div className="row">
                    <div className="col-sm-4">
                      <p className="noMargin firstLetter">{element.name}</p>
                    </div>
                    <div className="col-sm-6">
                      <p className="noMargin">
                        <b>{this.renderChallengeNames(element)}</b>
                      </p>
                    </div>
                    <div className="col-sm-2">
                      {/*eslint-disable-next-line*/}
                      <a
                        className="btn btn-sm btn-default btn-block"
                        onClick={this.onClickShowDetailsFromATQ.bind(
                          this,
                          element
                        )}>
                        Show Details
                      </a>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }
    return returnVals;
  }

  renderViewFullConversionModal() {
    let modalHeight = window.innerHeight - 300;
    return (
      <div>
        <div className="modalAlertOverlay conversionOverlay" />
        <div className="modalAlert conversionAlert modal-lg big">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Full Conversion
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.onClickCloseFromViewConversionModal.bind(this)}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="gap10" />
            <div
              className="container-fluid modalInnerBody"
              style={{ maxHeight: modalHeight, overflowY: "hidden" }}>
              <Scrollbars
                autoHide
                autoHideTimeout={1000}
                autoHideDuration={200}
                style={{ height: modalHeight }}>
                <MessageBox
                  messages={
                    this.state.selectedUser.atqMessages
                      ? this.state.selectedUser.atqMessages
                      : []
                  }
                  from={"messageList"}
                  showTrackingDate={true}
                />
              </Scrollbars>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderViewNotesModal() {
    return (
      <div>
        <div className="modalAlertOverlay notesOverlay" />
        <div className="modalAlert notesAlert modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Customer Notes
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.onClickCloseFromViewNotesModal.bind(this)}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="card">
                <div className="container-fluid">
                  <div className="gap10" />
                  <div
                    className={
                      this.state.selectedUser.notes !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>Note</label>
                    <textarea
                      name="notes"
                      value={this.state.selectedUser.notes}
                      onChange={(event) => this.handleInputChange(event)}
                    />
                  </div>
                  <div className="gap10" />
                  <div className="text-right">
                    {/*eslint-disable-next-line*/}
                    <a
                      className="btn btn-sm btn-default"
                      onClick={this.onClickSaveFromViewNotesModal.bind(this)}>
                      SAVE
                    </a>
                  </div>
                  <div className="gap10" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderPointsModal() {
    let selectedUser = this.state.selectedUser;
    let pointsData = this.state.pointsData;
    if (pointsData.totalPoints === undefined) {
      return;
    }

    return (
      <div>
        <div
          className="modalAlertOverlay pointsOverlay"
          onClick={this.onClickCloseFromPointsModal.bind(this)}
        />
        <div className="modalAlert pointsAlert modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {selectedUser.name} - {selectedUser.email_address}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.onClickCloseFromPointsModal.bind(this)}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="card">
                <div className="container-fluid">
                  <div className="gap10" />
                  Total Points : <b>{pointsData.totalPoints}</b>
                  <div className="gap10" />
                  Total Challenges : <b>{pointsData.totalChlgs}</b>
                  <div className="gap10" />
                  Accountability Submitted : <b>{pointsData.atqSubmitted}</b>
                  <div className="gap10" />
                  Marci's Circle Accountability Submitted :{" "}
                  <b>{pointsData.mcSubmitted}</b>
                  <div className="gap10" />
                  Marci's Circle Points : <b>{pointsData.mcPoints}</b>
                  <div className="gap10" />
                  Bonus's Completed : <b>{pointsData.bonusCompleted}</b>
                  <div className="gap10" />
                  <div className="gap10" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderResponsesModal() {
    let modalHeight = window.innerHeight - 200;
    return (
      <div>
        <div className="modalAlertOverlay responsesOverlay" />
        <div className="modalAlert responsesAlert modal-lg big">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Predefined Responses
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.onClickCloseFromResponsesModal.bind(this)}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div
              className="container-fluid modalInnerBody"
              style={{ maxHeight: modalHeight, overflowY: "hidden" }}>
              <Scrollbars
                autoHide
                autoHideTimeout={1000}
                autoHideDuration={200}
                style={{ height: modalHeight }}>
                <PredefinedResponse
                  key={this.state.keyResponse}
                  message={this.state.currentReply}
                  onClickInsertMessage={this.onClickInsertMessage.bind(this)}
                />
              </Scrollbars>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <Onboarding history={this.props.history}>
        <div>
          {this.renderViewFullConversionModal()}
          {this.renderCustomDateModal()}
          {this.renderViewNotesModal()}
          {this.renderResponsesModal()}
          {this.renderPointsModal()}
          <div className="container-fluid">
            <TitleBox title="Accountability Tracking Feedback" />
            <div className="gap20" />
            {this.renderFilterView()}
          </div>
          {!this.state.showLoader && this.renderAtqFeedback()}
          {this.state.showLoader && (
            <div className="container-fluid">
              <div className="card">
                <div className="container-fluid">
                  <TableLoader />
                </div>
              </div>
            </div>
          )}
        </div>
      </Onboarding>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.authReducer.userData,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountabilityTrackingFeedback);
