import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { arrayMove } from "react-sortable-hoc";

import { getChlgQues } from "../../../modules/actions/index";
import TitleBox from "../../../components/general/titleBox";
import RadioTag from "../../../components/form/radioTag";
import TypeableSelect from "../../../components/form/typeableSelect";
import { SortableQuestionList } from "../../../components/form/sortableList";
import { showNotification } from "../../../helpers";
import {
  callCreateMCQuestion,
  callGetMCQuestion,
  callUpdateMCQuestion,
} from "../../../services";

class MCAddAtqQuestion extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      atqId: 0,
      editMode: false,
      trackingDays: [],
      questions: [],
      selectedQuestions: [],
      selectedQue: { value: "", label: "" },
      keyTrackingDays: Math.random(),
      keyQuestion: Math.random(),
    };
  }

  componentDidMount() {
    this.props.getChlgQues();
    callGetMCQuestion().then((d) => {
      if (d.data.length > 0) {
        let mcAtq = d.data[0];
        let selectedQuestions = [];
        mcAtq.base_question_list_info.forEach((element) => {
          selectedQuestions.push({
            value: element,
            name: element.name,
            question: element.question,
            description: element.description,
          });
        });
        this.setState({
          atqId: mcAtq._id,
          editMode: true,
          selectedQuestions: selectedQuestions,
          trackingDays: mcAtq.tracking_days,
          keyTrackingDays: Math.random(),
          keyQuestion: Math.random(),
        });
      }
    });
  }

  componentWillReceiveProps(newProps) {
    if (newProps.chlgQuestions.data) {
      let ques = newProps.chlgQuestions.data;
      let fQues = [];
      ques.forEach((element) => {
        if (element.item_type === "marci-circle") {
          fQues.push({ value: element, label: element.question });
        }
      });

      this.setState({
        questions: fQues,
        keyQuestion: Math.random(),
      });
    }
  }

  onSelectDay(item, id) {
    let days = [];
    if (Object.keys(item).length > 0 && item.constructor === Object) {
      Object.keys(item).forEach((key) => {
        days.push(item[key].value);
      });
    }
    this.setState({ trackingDays: days });
  }

  onSelectQuestion = (que) => {
    this.setState({ selectedQue: que });
  };

  onPressRemoveQue(e) {
    let index = e.target.dataset["value"];
    let selectedQuestions = this.state.selectedQuestions;
    selectedQuestions.splice(index, 1);
    this.setState({
      selectedQuestions: selectedQuestions,
    });
  }

  onSortEndQues = ({ oldIndex, newIndex }) => {
    let selectedQuestions = this.state.selectedQuestions;
    selectedQuestions = arrayMove(selectedQuestions, oldIndex, newIndex);

    this.setState({
      selectedQuestions: selectedQuestions,
    });
  };

  onClickAddQuestion() {
    let selectedQuestions = this.state.selectedQuestions;
    if (!this.state.selectedQue.value.question) {
      showNotification("error", "Select the question first", 4000);
      return;
    }

    selectedQuestions.push({
      name: this.state.selectedQue.value.name,
      question: this.state.selectedQue.value.question,
      description: this.state.selectedQue.value.description,
      value: this.state.selectedQue.value,
    });

    this.setState({
      selectedQuestions: selectedQuestions,
      selectedQue: { value: "", label: "" },
      keyQuestion: Math.random(),
    });
  }

  onClickSave() {
    if (this.state.trackingDays.length === 0) {
      showNotification("error", "Select atleast one day", 4000);
      return;
    }

    if (this.state.selectedQuestions.length === 0) {
      showNotification("error", "Add atleast one question", 4000);
      return;
    }

    let queList = [];
    this.state.selectedQuestions.forEach((element) => {
      queList.push(element.value._id);
    });

    /*eslint-disable*/
    let apiData = {
      type: "marci-circle",
      tracking_days: this.state.trackingDays,
      base_question_list: queList,
    };
    /*eslint-enable*/

    if (this.state.editMode) {
      callUpdateMCQuestion(this.state.atqId, apiData).then((d) => {
        if (d._id) {
          showNotification(
            "success",
            "Marci Circle Questions updated successfully",
            4000
          );
        }
      });
    } else {
      callCreateMCQuestion(apiData).then((d) => {
        if (d._id) {
          showNotification(
            "success",
            "Marci Circle Questions added successfully",
            4000
          );
        }
      });
    }
  }

  render() {
    return (
      <div>
        <div className="container-fluid">
          <TitleBox
            title={"Marci Circle's Accountability Questions"}
            showBackBtn={this.state.editMode}
          />
          <div className="gap20" />
          <div className="card">
            <div className="container-fluid">
              <div className="gap20" />

              <div className="radioBHolder">
                <p>Select Tracking Days</p>
                <div className="tabsHolder radioTabs">
                  <RadioTag
                    onCheckChange={this.onSelectDay.bind(this)}
                    labelList={[
                      { name: "Monday", value: "monday" },
                      { name: "Tuesday", value: "tuesday" },
                      { name: "Wednesday", value: "wednesday" },
                      { name: "Thursday", value: "thursday" },
                      { name: "Friday", value: "friday" },
                      { name: "Saturday", value: "saturday" },
                      { name: "Sunday", value: "sunday" },
                    ]}
                    id={"trackingDays"}
                    type={"multiple"}
                    key={this.state.keyTrackingDays}
                    selectedList={this.state.trackingDays}
                    selectType={"value"}
                  />
                </div>
                <div className="gap20" />
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="card">
            <div className="container-fluid">
              <div className="gap20" />
              <h5 className="noMargin">Add Accountability Questions</h5>
              <div className="gap20" />
              <TypeableSelect
                key={this.state.keyQuestion}
                name={"question"}
                placeholder={"Search Question"}
                selected={this.state.selectedQue}
                onSelect={this.onSelectQuestion}
                options={this.state.questions}
              />
              <div className="gap20" />
              <div
                className="text-right"
                onClick={() => this.onClickAddQuestion()}>
                {/*eslint-disable-next-line*/}
                <a className="btn btn-primary addQs">Add Question</a>
              </div>
              <div className="gap20" />
            </div>
          </div>
        </div>

        {this.state.selectedQuestions.length > 0 && (
          <div className="container-fluid">
            <div className="card">
              <div className="container-fluid">
                <div className="gap20" />
                <h5 className="noMargin">Added Accountability Questions</h5>
                <div className="gap20" />
                <SortableQuestionList
                  items={this.state.selectedQuestions}
                  onSortEnd={this.onSortEndQues}
                  showName={true}
                  onPressRemove={this.onPressRemoveQue.bind(this)}
                />
                <div className="gap20" />
              </div>
            </div>
          </div>
        )}
        <div className="container-fluid">
          <div className="text-right" onClick={() => this.onClickSave()}>
            {/*eslint-disable-next-line*/}
            <a className="btn btn-primary">Save</a>
          </div>
          <div className="gap20" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  chlgQuestions: state.challengeReducer.chlgQuestions,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getChlgQues,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(MCAddAtqQuestion);
