import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  RESET_STATUS,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,
  GET_USER_SUCCESS,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  UPDATE_ALERT_ICON,
  REFRESH_CURRENT_USER,
  REFRESH_NOTIFICATION_UPDATE,
  GET_MESSAGE_SUCCESS,
  RESET_LOGGEDIN_USER,
  IS_MARCI_CIRCLE_AVAILABLE,
  SET_PAGE_HISTORY,
  IS_MARCI_CIRCLE_HOLD_AVAILABLE,
} from "../types";

const initialState = {
  message: "",
  success: false,
  isAdmin: false,
  isMember: false,
  isCompany: false,
  isMarciCircleAvailable: false,
  isMarciCircleHoldAvailable: false,
  updateAlertIcon: false,
  userData: {},
  notificationUpdates: [],
  users: {},
  memberMessages: {},
  history: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        message: action.message,
        isAdmin: action.isAdmin,
        isMember: action.isMember,
        isCompany: action.isCompany,
        userData: action.userData,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        message: action.message,
        isAdmin: action.isAdmin,
        isMember: action.isMember,
        isCompany: action.isCompany,
      };

    case RESET_STATUS:
      return {
        ...state,
        message: "",
        success: false,
        updateAlertIcon: false,
      };
    case IS_MARCI_CIRCLE_AVAILABLE:
      return {
        ...state,
        isMarciCircleAvailable: action.data,
      };
    case IS_MARCI_CIRCLE_HOLD_AVAILABLE:
      return {
        ...state,
        isMarciCircleHoldAvailable: action.data,
      };
    case CREATE_USER_SUCCESS:
      return {
        ...state,
        message: action.message,
        success: action.success,
      };
    case CREATE_USER_FAILURE:
      return {
        ...state,
        message: action.message,
        success: action.success,
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        message: action.message,
        success: action.success,
      };
    case UPDATE_USER_FAILURE:
      return {
        ...state,
        message: action.message,
        success: action.success,
      };
    case GET_USER_SUCCESS:
      return {
        ...state,
        users: action.data,
      };
    case UPDATE_ALERT_ICON:
      return {
        ...state,
        updateAlertIcon: true,
      };
    case REFRESH_CURRENT_USER:
      return {
        ...state,
        userData: action.data,
      };
    case REFRESH_NOTIFICATION_UPDATE:
      return {
        ...state,
        notificationUpdates: action.data,
      };
    case GET_MESSAGE_SUCCESS:
      return {
        ...state,
        memberMessages: action.data,
      };
    case RESET_LOGGEDIN_USER:
      return {
        ...state,
        message: "",
        success: false,
        isAdmin: false,
        isMember: false,
        updateAlertIcon: false,
        userData: {},
        notificationUpdates: [],
        users: {},
        memberMessages: {},
      };
    case SET_PAGE_HISTORY:
      return {
        ...state,
        history: action.history,
      };
    default:
      return state;
  }
};
