import React, { Component } from "react";

class ReplyAutoResize extends Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
  }

  handleTextChange(event) {
    const target = event.target;
    this.inputRef.style.height = target.scrollHeight + "px";
    this.inputRef.style.overflowY = "hidden";
  }

  render() {
    return (
      <div className="mdTextArea">
        <div
          className={
            this.inputRef.value !== "" ? "mdInput mdFocussed" : "mdInput"
          }>
          <label>{this.props.label}</label>
          <textarea
            ref={input => {
              this.inputRef = input;
              this.props.onRef(input);
            }}
            style={{ height: "45px" }}
            onChange={event => this.handleTextChange(event)}
          />
        </div>
      </div>
    );
  }
}

export default ReplyAutoResize;
