import React, { Component } from "react";
import PropTypes from "prop-types";

class DraftCustomColor extends Component {
  static propTypes = {
    expanded: PropTypes.bool,
    onExpandEvent: PropTypes.func,
    onChange: PropTypes.func,
    currentState: PropTypes.object,
  };

  stopPropagation = (event) => {
    event.stopPropagation();
  };

  onChange = (color) => {
    const { onChange } = this.props;
    onChange("color", color.target.value);
  };

  render() {
    const { color } = this.props.currentState;
    return <input type="color" value={color} onChange={this.onChange} />;
  }
}

export default DraftCustomColor;
