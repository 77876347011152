import { getEditorType } from "../../helpers";
import { getUniqueId } from "../../packages/mp-content-editor/helpers";

export function processEditorContent(json, challengeInfo = {}) {
  if (!json || !json.contentEditor) {
    return json;
  }
  let itemList = json.contentEditor.itemList;
  let customStyles = json.contentEditor.customStyles || "";
  let itemStyles = json.contentEditor.itemStyles || {};
  let attributes = json.contentEditor.attributes || {};
  let urlAttributes = json.contentEditor.urlAttributes || {};
  let defaultStyle = json.contentEditor.defaultStyle || {};

  itemList.forEach((element) => {
    let sectionId = getUniqueId();
    customStyles = customStyles.replaceAll(element.attributes.id, sectionId);
    if (attributes[element.attributes.id] !== undefined) {
      attributes[sectionId] = attributes[element.attributes.id];
      delete attributes[element.attributes.id];
    }
    if (defaultStyle[element.attributes.id] !== undefined) {
      defaultStyle[sectionId] = defaultStyle[element.attributes.id];
      delete defaultStyle[element.attributes.id];
    }
    if (urlAttributes[element.attributes.id] !== undefined) {
      urlAttributes[sectionId] = urlAttributes[element.attributes.id];
      delete urlAttributes[element.attributes.id];
    }
    if (itemStyles[element.attributes.id] !== undefined) {
      itemStyles[sectionId] = itemStyles[element.attributes.id];
      delete itemStyles[element.attributes.id];
    }
    element.attributes.id = sectionId;
    element.itemList.forEach((rowElem) => {
      let rowId = getUniqueId();
      customStyles = customStyles.replaceAll(rowElem.attributes.id, rowId);
      if (attributes[rowElem.attributes.id] !== undefined) {
        attributes[rowId] = attributes[rowElem.attributes.id];
        delete attributes[rowElem.attributes.id];
      }
      if (defaultStyle[rowElem.attributes.id] !== undefined) {
        defaultStyle[rowId] = defaultStyle[rowElem.attributes.id];
        delete defaultStyle[rowElem.attributes.id];
      }
      if (urlAttributes[rowElem.attributes.id] !== undefined) {
        urlAttributes[rowId] = urlAttributes[rowElem.attributes.id];
        delete urlAttributes[rowElem.attributes.id];
      }
      if (itemStyles[rowElem.attributes.id] !== undefined) {
        itemStyles[rowId] = itemStyles[rowElem.attributes.id];
        delete itemStyles[rowElem.attributes.id];
      }
      rowElem.attributes.id = rowId;
      rowElem.itemList.forEach((colElem) => {
        let colId = getUniqueId();
        customStyles = customStyles.replaceAll(colElem.attributes.id, colId);
        if (attributes[colElem.attributes.id] !== undefined) {
          attributes[colId] = attributes[colElem.attributes.id];
          delete attributes[colElem.attributes.id];
        }
        if (defaultStyle[colElem.attributes.id] !== undefined) {
          defaultStyle[colId] = defaultStyle[colElem.attributes.id];
          delete defaultStyle[colElem.attributes.id];
        }
        if (urlAttributes[colElem.attributes.id] !== undefined) {
          urlAttributes[colId] = urlAttributes[colElem.attributes.id];
          delete urlAttributes[colElem.attributes.id];
        }
        if (itemStyles[colElem.attributes.id] !== undefined) {
          itemStyles[colId] = itemStyles[colElem.attributes.id];
          delete itemStyles[colElem.attributes.id];
        }
        colElem.attributes.id = colId;
        colElem.itemList.forEach((itemElem) => {
          let itemId = getUniqueId();
          customStyles = customStyles.replaceAll(
            itemElem.attributes.id,
            itemId
          );
          if (attributes[itemElem.attributes.id] !== undefined) {
            attributes[itemId] = attributes[itemElem.attributes.id];
            delete attributes[itemElem.attributes.id];
          }
          if (defaultStyle[itemElem.attributes.id] !== undefined) {
            defaultStyle[itemId] = defaultStyle[itemElem.attributes.id];
            delete defaultStyle[itemElem.attributes.id];
          }
          if (urlAttributes[itemElem.attributes.id] !== undefined) {
            urlAttributes[itemId] = urlAttributes[itemElem.attributes.id];
            delete urlAttributes[itemElem.attributes.id];
          }
          if (itemStyles[itemElem.attributes.id] !== undefined) {
            itemStyles[itemId] = itemStyles[itemElem.attributes.id];
            delete itemStyles[itemElem.attributes.id];
          }

          let textAttributes = JSON.parse(
            JSON.stringify(itemElem.attributes.attributes)
          );
          if (textAttributes.content) {
            if (textAttributes.content.includes("{{--CourseName--}}")) {
              textAttributes.content = textAttributes.content.replaceAll(
                "{{--CourseName--}}",
                challengeInfo.name || "course"
              );
            }
            if (textAttributes.content.includes("{{--CourseDescription--}}")) {
              textAttributes.content = textAttributes.content.replaceAll(
                "{{--CourseDescription--}}",
                challengeInfo.description || ""
              );
            }
            if (textAttributes.content.includes("{{--CourseType--}}")) {
              textAttributes.content = textAttributes.content.replaceAll(
                "{{--CourseType--}}",
                challengeInfo.type || "course"
              );
            }
            let textJson = textAttributes.json || { blocks: [] };
            textJson.blocks.forEach((element) => {
              if (element.text.includes("{{--CourseName--}}")) {
                element.text = element.text.replaceAll(
                  "{{--CourseName--}}",
                  challengeInfo.name || "course"
                );
              }
              if (element.text.includes("{{--CourseDescription--}}")) {
                element.text = element.text.replaceAll(
                  "{{--CourseDescription--}}",
                  challengeInfo.description || ""
                );
              }
              if (element.text.includes("{{--CourseType--}}")) {
                element.text = element.text.replaceAll(
                  "{{--CourseType--}}",
                  challengeInfo.type || "course"
                );
              }
            });
            textAttributes.json = textJson;
          }
          itemElem.attributes.attributes = textAttributes;
          itemElem.attributes.id = itemId;
        });
      });
    });
  });

  let jsonBackup = Object.assign({}, json);
  jsonBackup.contentEditor.itemList = itemList;
  jsonBackup.contentEditor.customStyles = customStyles;
  jsonBackup.contentEditor.itemStyles = itemStyles;
  jsonBackup.contentEditor.attributes = attributes;
  jsonBackup.contentEditor.defaultStyle = defaultStyle;
  jsonBackup.contentEditor.urlAttributes = urlAttributes;
  return jsonBackup;
}

export function changeContentJsonIds(element) {
  let elementJson = element.json;
  if (element.section_id) {
    let iList = element.json.contentEditor.itemList;
    elementJson = {};
    elementJson.itemList = iList;
    elementJson.attributes = element.json.contentEditor.attributes;
  }

  let jsonBackup = JSON.parse(JSON.stringify(elementJson));
  let itemList = jsonBackup.itemList;
  let itemStyles = element.item_styles || {};
  itemStyles = JSON.parse(JSON.stringify(itemStyles));

  let attributes = element.json.contentEditor.attributes || {};
  attributes = JSON.parse(JSON.stringify(attributes));

  itemList.forEach((element) => {
    let sectionId = getUniqueId();
    if (itemStyles[element.attributes.id] !== undefined) {
      itemStyles[sectionId] = itemStyles[element.attributes.id];
      delete itemStyles[element.attributes.id];
    }
    if (attributes[element.attributes.id] !== undefined) {
      attributes[sectionId] = attributes[element.attributes.id];
      delete attributes[element.attributes.id];
    }
    element.attributes.id = sectionId;

    element.itemList.forEach((rowElem) => {
      let rowId = getUniqueId();
      if (itemStyles[rowElem.attributes.id] !== undefined) {
        itemStyles[rowId] = itemStyles[rowElem.attributes.id];
        delete itemStyles[rowElem.attributes.id];
      }
      if (attributes[rowElem.attributes.id] !== undefined) {
        attributes[rowId] = attributes[rowElem.attributes.id];
        delete attributes[rowElem.attributes.id];
      }
      rowElem.attributes.id = rowId;

      rowElem.itemList.forEach((colElem) => {
        let colId = getUniqueId();
        if (itemStyles[colElem.attributes.id] !== undefined) {
          itemStyles[colId] = itemStyles[colElem.attributes.id];
          delete itemStyles[colElem.attributes.id];
        }
        if (attributes[colElem.attributes.id] !== undefined) {
          attributes[colId] = attributes[colElem.attributes.id];
          delete attributes[colElem.attributes.id];
        }
        colElem.attributes.id = colId;
        if (colElem.itemList) {
          colElem.itemList.forEach((itemElem) => {
            let itemId = getUniqueId();
            if (itemStyles[itemElem.attributes.id] !== undefined) {
              itemStyles[itemId] = itemStyles[itemElem.attributes.id];
              delete itemStyles[itemElem.attributes.id];
            }
            if (attributes[itemElem.attributes.id] !== undefined) {
              attributes[itemId] = attributes[itemElem.attributes.id];
              delete attributes[itemElem.attributes.id];
            }
            itemElem.attributes.id = itemId;
          });
        }
      });
    });
  });
  jsonBackup.html = element.html;
  jsonBackup.nonCustomizable = element.non_customizable;
  jsonBackup.dbSubId = element._id;
  jsonBackup.itemList = itemList;
  jsonBackup.attributes.id = getUniqueId();
  return { jsonBackup, itemStyles, attributes };
}

export function getUpdatedEditorContent(json, html) {
  let editorType = getEditorType(html, json);
  if (editorType === "unlayer") {
    return json;
  }
  return processEditorContent(json);
}
