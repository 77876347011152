import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import TitleBox from "../../../components/general/titleBox";
import { isDashboardSite, showNotification } from "../../../helpers";
import Checkbox from "../../../components/form/checkBox";
import { adminMenu } from "../../../components/menu/menu";
import { getItem } from "../../../helpers/storage";
import { callGetUserById, callUpdateUser } from "../../../services";

class CustomAdminMenuPage extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      keyMenu: Math.random(),
      customAdminMenuOptions: adminMenu,
      customAdminMenu: adminMenu
        .filter((d) => d.mode === "custom")
        .map((i) => i.id),
    };
  }

  componentDidMount() {
    this.getCustomAdminMenu();
  }

  async getCustomAdminMenu() {
    let userId = getItem("userId");
    var response = await callGetUserById(userId);
    let customAdminMenu = response.custom_admin_menu || [];
    let customAdminMenuOptions = this.state.customAdminMenuOptions;
    customAdminMenuOptions.forEach((element) => {
      if (customAdminMenu.indexOf(element.id) > -1) {
        element.mode = "custom";
        element.checked = true;
      }
    });

    this.setState({ customAdminMenuOptions, keyMenu: Math.random() });
  }

  toggleCustomAdminMenuCheckBox(menuOption, checked) {
    let customAdminMenuOptions = this.state.customAdminMenuOptions;
    customAdminMenuOptions.forEach((element) => {
      if (element.id === menuOption.id) {
        if (element.mode === "custom") {
          element.mode = "full";
        } else {
          element.mode = "custom";
        }
        element.checked = checked;
      }
    });
    this.setState({ customAdminMenuOptions });
  }

  renderCustomAdminMenu() {
    let customAdminMenuOptions = this.state.customAdminMenuOptions;
    let returnVals = [];

    for (let i = 0; i < customAdminMenuOptions.length; i++) {
      const element = customAdminMenuOptions[i];
      const inactiveMenu = [
        "settings",
        "admin-dashboard",
        "company-dashboard",
        "member-dashboard",
        "onboarding",
        "articles",
        "churn",
      ].concat(this.state.adminInactiveMenu);
      if (inactiveMenu.indexOf(element.id) > -1) {
        continue;
      }
      if (element.id === "releases") {
        if (!isDashboardSite()) {
          continue;
        }
      }

      let label = element.label;
      if (element.id === "preview-challenges") {
        label = "Challenges (Preview)";
      }
      returnVals.push(
        <div key={i} className="clearfix">
          <div className="pull-left">
            <Checkbox
              label={label}
              isChecked={element.checked}
              handleCheckboxChange={this.toggleCustomAdminMenuCheckBox.bind(
                this,
                element
              )}
            />
          </div>
        </div>
      );
    }
    return returnVals;
  }

  async onClickSave() {
    let customAdminMenu = this.state.customAdminMenuOptions
      .filter((d) => d.mode === "custom")
      .map((i) => i.id);
    let userId = getItem("userId");
    /*eslint-disable*/
    var response = await callUpdateUser(userId, {
      custom_admin_menu: customAdminMenu,
      admin_menu_type: "custom",
    });
    /*eslint-enable*/
    if (response._id) {
      showNotification("success", "Updated successfully", 4000);
      window.location.reload();
    }
  }

  render() {
    return (
      <div>
        <div className="container-fluid">
          <TitleBox title={"Custom Admin Menu"} />
          <div className="gap20" />
          <div className="card">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-4">
                  <div className="gap20" />
                  <div className="addedRule" key={this.state.keyMenu}>
                    {this.renderCustomAdminMenu()}
                  </div>
                  <div className="gap20" />
                </div>
              </div>
            </div>
          </div>
          <div className="text-right">
            <button
              className="btn btn-primary"
              onClick={this.onClickSave.bind(this)}>
              Save
            </button>
            <div className="gap20" />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomAdminMenuPage);
