import {
  RESET_STATUS,
  GET_EMAIL_SUCCESS,
  CREATE_EMAIL_SUCCESS,
  CREATE_EMAIL_FAILURE,
  UPDATE_EMAIL_SUCCESS,
  UPDATE_EMAIL_FAILURE
} from "../types";

const initialState = {
  message: "",
  success: false,
  emails: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RESET_STATUS:
      return {
        ...state,
        message: "",
        success: false
      };
    case CREATE_EMAIL_SUCCESS:
      return {
        ...state,
        message: action.message,
        success: action.success
      };
    case CREATE_EMAIL_FAILURE:
      return {
        ...state,
        message: action.message,
        success: action.success
      };
    case UPDATE_EMAIL_SUCCESS:
      return {
        ...state,
        message: action.message,
        success: action.success
      };
    case UPDATE_EMAIL_FAILURE:
      return {
        ...state,
        message: action.message,
        success: action.success
      };
    case GET_EMAIL_SUCCESS:
      return {
        ...state,
        emails: action.data
      };
    default:
      return state;
  }
};
