import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getParameterByName, getCompanyRole } from "../../helpers";
import { setItem } from "../../helpers/storage";
import { callVerifyAuthentication } from "../../services";
import { callGetUserById } from "../../services/authService";

class SSOPage extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    let token = getParameterByName("token");
    this.state = { token: token };
  }

  componentDidMount() {
    let token = this.state.token;
    callVerifyAuthentication(token).then(
      (d) => {
        let currentTime = Math.floor(new Date().getTime() / 1000);
        if (parseInt(d.exp, 0) < currentTime) {
          window.location.assign("/login");
          return;
        }
        setItem("tokenExpiry", d.exp);
        setItem("accessToken", token);

        callGetUserById(d._id, false, false).then((data) => {
          if (data._id) {
            let { companyId } = getCompanyRole(data);
            let isAdmin = data.member_type === "staff";
            let isMember = data.member_type === "customer";

            let userValues = JSON.stringify({
              _id: data._id,
              /*eslint-disable*/
              member_type: data.member_type,
              company_list: data.company_list,
              master_roles: data.master_roles,
              roles: data.roles,
              report_roles: data.report_roles || [],
              /*eslint-enable*/
            });

            setItem("userData", userValues);
            setItem("companyId", companyId);
            if (isAdmin) {
              window.location.assign("/admin");
            } else if (isMember) {
              window.location.assign("/member");
            }
          }
        });
      },
      (error) => {
        window.location.assign("/login");
      }
    );
  }

  render() {
    return <div></div>;
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SSOPage);
