import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { VelocityTransitionGroup } from "velocity-react";
import { arrayMove } from "react-sortable-hoc";
import moment from "moment";

import { getExercises, resetStatus } from "../../../modules/actions/index";
import {
  SortableExerciseList,
  SortableProgramSetList
} from "../../../components/form/sortableList";
import { showNotification } from "../../../helpers";
import {
  callUpdateCustomProgram,
  callUpdateCustomProgramTemplate
} from "../../../services";
import TypeableSelect from "../../../components/form/typeableSelect";
import TitleBox from "../../../components/general/titleBox";

class EditProgramDay extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    let from = this.props.history.location.state.from;

    let customProgram = {};
    let customProgramDay = "";
    let template = {};
    let templateDay = "";
    let sets = [];
    let notes = "";

    if (from === "program") {
      customProgram = this.props.history.location.state.customProgram;
      customProgramDay = moment(customProgram["customProgramDay"]).format(
        "YYYY-MM-DD"
      );
      let events = customProgram["events"];

      let dayEvents = events.filter(d => {
        return d.date === customProgramDay;
      });

      if (dayEvents.length === 1) {
        sets = dayEvents[0].sets;
        notes = dayEvents[0].notes;
      }
    } else {
      template = this.props.history.location.state.template;
      templateDay = template["templateDay"];
      let events = template["events"];

      let dayEvents = events.filter(d => {
        return d.day === templateDay;
      });

      if (dayEvents.length === 1) {
        sets = dayEvents[0].sets;
        notes = dayEvents[0].notes;
      }
    }

    this.state = {
      from: from,
      template: template,
      templateDay: templateDay,
      customProgramDay: customProgramDay,
      customProgram: customProgram,
      exercises: [],
      showAddExercise: false,
      selectedExercise: { value: "", label: "" },
      selectedExercises: [],
      selectedEditExerciseIndex: -1,
      selectedSets: sets,
      selectedEditSetIndex: -1,
      selectedSetId: 1,
      weight: "",
      reps: "",
      time: "",
      tempo: "",
      notes: notes,
      exeNotes: "",
      setSummary: "",
      setName: "",
      keyExercise: Math.random(),
      showAddSet: false
    };
  }

  componentDidMount() {
    this.props.getExercises();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.exercises.data) {
      let exercises = newProps.exercises.data;
      let fExercises = [];
      exercises.forEach(element => {
        fExercises.push({ value: element, label: element.name });
      });

      this.setState({
        exercises: fExercises,
        keyExercise: Math.random()
      });
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  onClickShowExercise() {
    this.setState({ showAddExercise: !this.state.showAddExercise });
  }

  onClickShowSet() {
    this.setState({ showAddSet: !this.state.showAddSet });
  }

  onClickAddExercise() {
    let exercises = this.state.selectedExercises;

    if (this.state.selectedExercise.value.name.trim().length === 0) {
      showNotification("error", "Please select a exercise", 4000);
      return;
    }

    let exercise = this.state.selectedExercise.value;

    /*eslint-disable*/

    let exerciseInfo = {
      weight: this.state.weight,
      reps: this.state.reps,
      time: this.state.time,
      notes: this.state.exeNotes,
      tempo: this.state.tempo,
      name: exercise.name,
      value: exercise,
      _id: exercise._id,
      exercise_id: exercise._id
    };

    /*eslint-enable*/

    if (this.state.selectedEditExerciseIndex >= 0) {
      exercises[this.state.selectedEditExerciseIndex] = exerciseInfo;
    } else {
      exercises.push(exerciseInfo);
    }

    this.setState({
      showAddExercise: !this.state.showAddExercise,
      selectedEditExerciseIndex: -1,
      selectedExercises: exercises,
      weight: "",
      reps: "",
      time: "",
      tempo: "",
      exeNotes: "",
      selectedExercise: { value: "", label: "" },
      keyExercise: Math.random()
    });
  }

  onClickAddSet() {
    if (this.state.setName.trim().length === 0) {
      showNotification("error", "Please add set name", 4000);
      return;
    }

    if (this.state.setSummary.trim().length === 0) {
      showNotification("error", "Please add set summary", 4000);
      return;
    }

    if (this.state.selectedExercises.length === 0) {
      showNotification("error", "Please add atleast one exercise", 4000);
      return;
    }

    let sets = this.state.selectedSets;

    let setInfo = {
      name: this.state.setName,
      summary: this.state.setSummary,
      exercises: this.state.selectedExercises,
      id: this.state.selectedSetId
    };

    if (this.state.selectedEditSetIndex >= 0) {
      sets[this.state.selectedEditSetIndex] = setInfo;
    } else {
      if (sets.length > 0) {
        let lastId = Math.max.apply(
          Math,
          sets.map(o => {
            return o.id;
          })
        );
        lastId += 1;
        setInfo.id = lastId;
      }

      sets.push(setInfo);
    }

    this.setState({
      selectedSets: sets,
      selectedEditSetIndex: -1,
      selectedExercises: [],
      setSummary: "",
      setName: "",
      showAddSet: !this.state.showAddSet
    });
  }

  onClickCancelSet() {
    this.setState({
      selectedEditSetIndex: -1,
      selectedExercises: [],
      setSummary: "",
      setName: "",
      showAddSet: !this.state.showAddSet
    });
  }

  onSortEndExerItems = ({ oldIndex, newIndex }) => {
    let selectedExercises = this.state.selectedExercises;
    selectedExercises = arrayMove(selectedExercises, oldIndex, newIndex);

    this.setState({
      selectedExercises: selectedExercises
    });
  };

  onPressRemoveExerItem(e) {
    let index = e.target.dataset["value"];
    let selectedExercises = this.state.selectedExercises;
    selectedExercises.splice(index, 1);
    this.setState({
      selectedExercises: selectedExercises
    });
  }

  onPressEditExerItem(e) {
    let index = e.target.dataset["value"];
    let exercises = this.state.selectedExercises;

    let exercise = exercises[index];
    let selectedExercise = exercise.value;

    delete selectedExercise.value;

    this.setState({
      showAddExercise: true,
      selectedEditExerciseIndex: index,
      weight: exercise.weight,
      reps: exercise.reps,
      time: exercise.time,
      tempo: exercise.tempo || "",
      exeNotes: exercise.notes || "",
      selectedExercise: {
        value: exercise,
        label: selectedExercise.name
      },
      keyExercise: Math.random()
    });
  }

  onSortEndSetItems = ({ oldIndex, newIndex }) => {
    let selectedSets = this.state.selectedSets;
    selectedSets = arrayMove(selectedSets, oldIndex, newIndex);

    this.setState({
      selectedSets: selectedSets
    });
  };

  onPressRemoveSetItem(e) {
    let index = e.target.dataset["value"];
    let selectedSets = this.state.selectedSets;
    selectedSets.splice(index, 1);
    this.setState({
      selectedSets: selectedSets
    });
  }

  onPressEditSetItem(e) {
    let index = e.target.dataset["value"];
    let sets = this.state.selectedSets;

    let set = sets[index];

    set.exercises.forEach(element => {
      element["value"] = element;
      element["_id"] = element.exercise_id;
    });

    this.setState({
      showAddSet: true,
      selectedEditSetIndex: index,
      selectedExercises: set.exercises,
      setSummary: set.summary,
      setName: set.name,
      selectedSetId: set.id,
      keyExercise: Math.random()
    });
  }

  onClickCancel() {
    this.props.history.goBack();
  }

  onClickSaveProgram() {
    let customProgram = this.state.customProgram;
    var events = customProgram["events"];

    let sets = this.state.selectedSets;

    if (sets.length === 0) {
      showNotification("error", "Please add atleast one set", 4000);
      return;
    }

    sets.forEach(set => {
      let exercises = [];
      set.exercises.forEach(element => {
        exercises.push({
          exercise_id: element.exercise_id, //eslint-disable-line
          name: element.name,
          weight: element.weight,
          reps: element.reps,
          notes: element.notes,
          time: element.time,
          tempo: element.tempo
        });
      });
      set.exercises = exercises;
    });

    if (events.length > 0) {
      let dayEvent = events.filter(d => {
        return d.date === this.state.customProgramDay;
      });
      if (dayEvent.length === 1) {
        events.forEach(element => {
          if (element.date === this.state.customProgramDay) {
            element.sets = sets;
            element.notes = this.state.notes;
          }
        });
      } else {
        events.push({
          date: this.state.customProgramDay,
          notes: this.state.notes,
          sets: sets
        });
      }
    } else {
      events.push({
        date: this.state.customProgramDay,
        notes: this.state.notes,
        sets: sets
      });
    }

    customProgram["events"] = events;

    callUpdateCustomProgram(customProgram._id, {
      events: customProgram["events"]
    }).then(data => {
      if (data._id) {
        showNotification("success", "Custom Program Updated", 3000);
        this.onClickCancel();
      }
    });
  }

  onClickSaveTemplate() {
    let template = this.state.template;
    var events = template["events"];

    let sets = this.state.selectedSets;

    if (sets.length === 0) {
      showNotification("error", "Please add atleast one set", 4000);
      return;
    }

    sets.forEach(set => {
      let exercises = [];
      set.exercises.forEach(element => {
        exercises.push({
          exercise_id: element.exercise_id, //eslint-disable-line
          name: element.name,
          weight: element.weight,
          reps: element.reps,
          notes: element.notes,
          time: element.time,
          tempo: element.tempo
        });
      });
      set.exercises = exercises;
    });

    if (events.length > 0) {
      let dayEvent = events.filter(d => {
        return d.day === this.state.templateDay;
      });
      if (dayEvent.length === 1) {
        events.forEach(element => {
          if (element.day === this.state.templateDay) {
            element.sets = sets;
            element.notes = this.state.notes;
          }
        });
      } else {
        events.push({
          day: this.state.templateDay,
          notes: this.state.notes,
          sets: sets
        });
      }
    } else {
      events.push({
        day: this.state.templateDay,
        notes: this.state.notes,
        sets: sets
      });
    }

    template["events"] = events;

    callUpdateCustomProgramTemplate(template._id, {
      events: template["events"]
    }).then(data => {
      if (data._id) {
        showNotification("success", "Custom Program Template Updated", 3000);
        this.onClickCancel();
      }
    });
  }

  onSelectExercise = exercise => {
    this.setState({ selectedExercise: exercise });
  };

  render() {
    return (
      <div>
        <div className="container-fluid">
          <TitleBox title="Edit Program Day" showBackBtn={true} />
          <div className="gap20" />
        </div>

        <div className="container-fluid">
          {/*eslint-disable-next-line*/}
          <a
            className="btn btn-primary"
            onClick={this.onClickShowSet.bind(this)}>
            Add New Set
          </a>
          <div className="gap20" />
          <VelocityTransitionGroup
            enter={{ animation: "slideDown" }}
            leave={{ animation: "slideUp" }}>
            {this.state.showAddSet && (
              <div className="container-fluid">
                <div className="card">
                  <div className="gap20" />
                  <div className="container-fluid">
                    <div
                      className={
                        this.state.setName !== ""
                          ? "mdInput mdFocussed"
                          : "mdInput"
                      }>
                      <label>Name</label>
                      <input
                        type=""
                        className="challangeDesc"
                        name="setName"
                        value={this.state.setName}
                        onChange={event => this.handleInputChange(event)}
                      />
                    </div>
                    <div className="gap10" />
                    <div
                      className={
                        this.state.setSummary !== ""
                          ? "mdInput mdFocussed"
                          : "mdInput"
                      }>
                      <label>Summary</label>
                      <input
                        type=""
                        className="challangeDesc"
                        name="setSummary"
                        value={this.state.setSummary}
                        onChange={event => this.handleInputChange(event)}
                      />
                    </div>
                    <div className="gap20" />
                    {/*eslint-disable-next-line*/}
                    <a
                      className="btn btn-default"
                      onClick={this.onClickShowExercise.bind(this)}>
                      Add Exercise
                    </a>
                    <div className="gap20" />
                    <VelocityTransitionGroup
                      enter={{ animation: "slideDown" }}
                      leave={{ animation: "slideUp" }}>
                      {this.state.showAddExercise && (
                        <div className="card">
                          <div className="container-fluid">
                            <div className="">
                              <div className="gap20" />
                              <div className="gap10" />
                              <TypeableSelect
                                key={this.state.keyExercise}
                                name={"selectedExercise"}
                                placeholder={"Search Exercise"}
                                selected={this.state.selectedExercise}
                                onSelect={this.onSelectExercise}
                                options={this.state.exercises}
                              />
                              <div className="gap30" />
                              <div className="row">
                                <div className="col-md-4">
                                  <div
                                    className={
                                      this.state.weight !== ""
                                        ? "mdInput mdFocussed"
                                        : "mdInput"
                                    }>
                                    <label>Weight</label>
                                    <input
                                      type="text"
                                      name="weight"
                                      value={this.state.weight}
                                      onChange={event =>
                                        this.handleInputChange(event)
                                      }
                                    />
                                  </div>
                                  <div className="gap20" />
                                </div>
                                <div className="col-md-4">
                                  <div
                                    className={
                                      this.state.reps !== ""
                                        ? "mdInput mdFocussed"
                                        : "mdInput"
                                    }>
                                    <label>Reps/Time</label>
                                    <input
                                      type="text"
                                      name="reps"
                                      value={this.state.reps}
                                      onChange={event =>
                                        this.handleInputChange(event)
                                      }
                                    />
                                  </div>
                                  <div className="gap20" />
                                </div>
                                <div className="col-md-4">
                                  <div
                                    className={
                                      this.state.time !== ""
                                        ? "mdInput mdFocussed"
                                        : "mdInput"
                                    }>
                                    <label>Rest</label>
                                    <input
                                      type="text"
                                      name="time"
                                      value={this.state.time}
                                      onChange={event =>
                                        this.handleInputChange(event)
                                      }
                                    />
                                  </div>
                                  <div className="gap20" />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-4">
                                  <div
                                    className={
                                      this.state.tempo !== ""
                                        ? "mdInput mdFocussed"
                                        : "mdInput"
                                    }>
                                    <label>Tempo</label>
                                    <input
                                      type="text"
                                      name="tempo"
                                      value={this.state.tempo}
                                      onChange={event =>
                                        this.handleInputChange(event)
                                      }
                                    />
                                  </div>
                                  <div className="gap20" />
                                </div>
                              </div>
                              <div
                                className={
                                  this.state.exeNotes !== ""
                                    ? "mdInput mdFocussed"
                                    : "mdInput"
                                }>
                                <label>Notes</label>
                                <input
                                  type=""
                                  className="challangeDesc"
                                  name="exeNotes"
                                  value={this.state.exeNotes}
                                  onChange={event =>
                                    this.handleInputChange(event)
                                  }
                                />
                              </div>
                              <div className="gap10" />
                              <div className="gap20" />
                              <div className="text-right">
                                {/*eslint-disable-next-line*/}
                                <a
                                  className="btn btn-primary"
                                  onClick={this.onClickAddExercise.bind(this)}>
                                  {this.state.selectedEditExerciseIndex >= 0
                                    ? "Update"
                                    : "Save"}
                                </a>
                              </div>
                            </div>

                            <div className="gap20" />
                          </div>
                        </div>
                      )}
                    </VelocityTransitionGroup>
                    {this.state.selectedExercises.length > 0 && (
                      <div className="card">
                        <div className="container-fluid">
                          <div className="gap20" />
                          <h5 className="noMargin">Exercise List</h5>
                          <div className="gap20" />
                          <SortableExerciseList
                            items={this.state.selectedExercises}
                            onSortEnd={this.onSortEndExerItems}
                            showEdit={true}
                            onPressRemove={this.onPressRemoveExerItem.bind(
                              this
                            )}
                            onPressEdit={this.onPressEditExerItem.bind(this)}
                          />
                          <div className="gap20" />
                        </div>
                      </div>
                    )}
                    <div className="text-right">
                      {/*eslint-disable*/}
                      {this.state.selectedEditSetIndex >= 0 && (
                        <a
                          className="btn btn-default margin-right-10"
                          onClick={this.onClickCancelSet.bind(this)}>
                          Cancel
                        </a>
                      )}
                      <a
                        className="btn btn-primary"
                        onClick={this.onClickAddSet.bind(this)}>
                        {this.state.selectedEditSetIndex >= 0
                          ? "Update Set"
                          : "Add Set"}
                      </a>
                      {/*eslint-enable*/}
                    </div>
                    <div className="gap20" />
                  </div>
                </div>
              </div>
            )}
          </VelocityTransitionGroup>
        </div>

        {this.state.selectedSets.length > 0 && (
          <div className="container-fluid">
            <div className="card">
              <div className="container-fluid">
                <div className="gap20" />
                <h5 className="noMargin">Set List</h5>
                <div className="gap20" />
                <SortableProgramSetList
                  items={this.state.selectedSets}
                  onSortEnd={this.onSortEndSetItems}
                  showEdit={true}
                  onPressRemove={this.onPressRemoveSetItem.bind(this)}
                  onPressEdit={this.onPressEditSetItem.bind(this)}
                />
                <div className="gap20" />
              </div>
            </div>
          </div>
        )}

        <div className="container-fluid">
          <div className="card">
            <div className="container-fluid">
              <div className="gap20" />
              <h5>Workout Summary</h5>
              <div className="gap10" />
              <div
                className={
                  this.state.notes !== "" ? "mdInput mdFocussed" : "mdInput"
                }>
                <label>Notes</label>
                <textarea
                  name="notes"
                  value={this.state.notes}
                  onChange={event => this.handleInputChange(event)}
                />
              </div>
              <div className="gap20" />
            </div>
          </div>

          <div className="clearfix">
            {/*eslint-disable*/}
            <div className="pull-left">
              <a
                className="btn btn-default"
                onClick={this.onClickCancel.bind(this)}>
                Cancel
              </a>
            </div>
            <div className="pull-right">
              {this.state.from === "program" && (
                <a
                  className="btn btn-primary"
                  onClick={this.onClickSaveProgram.bind(this)}>
                  Save & Return
                </a>
              )}
              {this.state.from === "template" && (
                <a
                  className="btn btn-primary"
                  onClick={this.onClickSaveTemplate.bind(this)}>
                  Save & Return
                </a>
              )}
            </div>
            {/*eslint-enable*/}
          </div>
          <div className="gap20" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  message: state.customProgramReducer.message,
  success: state.customProgramReducer.success,
  exercises: state.customProgramReducer.exercises
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ getExercises, resetStatus }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditProgramDay);
