import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import TitleBox from "../../../components/general/titleBox";
import {
  callGetSettings,
  callSaveSettings,
} from "../../../services/settingService";
import { getUniqueId, showNotification } from "../../../helpers";

class EmailSettingsPage extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      emailType: "",
      emailTypes: [],
      selectedEmailTypeIndex: -1,
      settingId: "",
      typeId: "",
    };
  }

  componentDidMount() {
    callGetSettings("emailsettings").then((d) => {
      let emailTypes = d.data.email_settings.email_types || [];
      emailTypes = emailTypes.filter((d) => d.status === "active" || !d.status);
      this.setState({
        settingId: d.data._id,
        emailTypes,
      });
    });
  }

  handleInputChange(event) {
    const target = event.target;
    var value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  async onClickAddType() {
    let emailTypes = this.state.emailTypes || [];
    let typeId = getUniqueId();

    let fEmailTypes = emailTypes.filter(
      (d) => d.name.toLowerCase() === this.state.emailType.toLowerCase()
    );
    if (fEmailTypes.length > 0) {
      showNotification("error", "Type already exists", 4000);
      return;
    }

    let emailType = {
      name: this.state.emailType,
      value: typeId,
      type: "user",
      status: "active",
    };
    if (this.state.selectedEmailTypeIndex > -1) {
      emailType.value = this.state.typeId;
      emailTypes[this.state.selectedEmailTypeIndex] = emailType;
    } else {
      emailTypes.push(emailType);
    }
    this.setState({ emailTypes, emailType: "", typeId: "" }, () => {
      this.callSaveEmailTypes(typeId);
    });
  }

  async callSaveEmailTypes(typeId = "") {
    /*eslint-disable*/
    let data = {
      id: this.state.settingId,
      type: "email_settings",
      email_settings: {
        email_types: this.state.emailTypes,
        type_id: typeId,
      },
    };
    /*eslint-enable*/

    let updatedData = await callSaveSettings(data);
    if (updatedData.status) {
      showNotification("success", "Email Settings updated.");
    } else {
      this.setState({ errorMessage: updatedData.message });
    }
  }

  onClickDeleteType(element) {
    let emailTypes = this.state.emailTypes;
    emailTypes.forEach((d) => {
      if (d.value === element.value) {
        d.status = "inactive";
      }
    });

    this.setState({ emailTypes }, () => {
      this.callSaveEmailTypes();
    });
  }

  onClickEditType(index) {
    let emailType = this.state.emailTypes[index];
    this.setState({
      emailType: emailType.name,
      typeId: emailType.value,
      selectedEmailTypeIndex: index,
    });
  }

  renderTypes() {
    let emailTypes = this.state.emailTypes;
    let returnVals = [];
    emailTypes.forEach((i, index) => {
      if (i.status === "active" || !i.status) {
        returnVals.push(
          <div key={i.id}>
            {i.name}
            {i.type === "user" && (
              <>
                <button
                  onClick={this.onClickDeleteType.bind(this, i)}
                  className="btn btn-danger btn-sm border-0"
                  style={{ float: "right" }}>
                  <i className="fa fa-trash" />
                </button>
                <button
                  onClick={this.onClickEditType.bind(this, index)}
                  className="btn btn-default btn-sm border-0"
                  style={{ float: "right" }}>
                  <i className="fa fa-pencil" />
                </button>
              </>
            )}
          </div>
        );
      }
    });
    return returnVals;
  }

  render() {
    return (
      <div>
        <div className="container-fluid">
          <TitleBox title="Email Settings" showBackBtn={false} />

          <div className="gap20" />
          <div className="card">
            <div className="container-fluid">
              <div className="gap10" />
              <p>Add Email Type</p>
              <div className="row">
                <div className="col-md-6">
                  <div
                    className={
                      this.state.emailType !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>Type</label>
                    <input
                      type="text"
                      name="emailType"
                      value={this.state.emailType}
                      onChange={(event) => this.handleInputChange(event)}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="gap30"></div>
                  <button
                    className="btn btn-primary"
                    onClick={this.onClickAddType.bind(this)}>
                    Submit
                  </button>
                </div>
              </div>
              <div className="gap30" />
              <div className="addedRule">{this.renderTypes()}</div>
              <div className="gap20" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EmailSettingsPage);
