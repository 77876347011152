import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import $ from "jquery";
import { Scrollbars } from "react-custom-scrollbars";

import RadioTag from "../form/radioTag";
// import TagSelect from "../form/tagSelect";
import TypeableSelect from "../form/typeableSelect";
import SelectAddImage from "../form/selectAddImage";

import { callGetSystemTags, callGetCheckoutItems } from "../../services";

class ScriptPopup extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    let scriptInfo = this.props.script || {};

    let checkoutItemId = scriptInfo.checkoutItemId || "";

    this.state = {
      fbPixel: scriptInfo.fbPixel || "yes",
      leadPixel: scriptInfo.leadPixel || "no",
      purchasePixel: scriptInfo.purchasePixel || "no",
      headerScript: scriptInfo.headerScript || "",
      footerScript: scriptInfo.footerScript || "",
      pageTitle: scriptInfo.pageTitle || "",
      redirectUrl: scriptInfo.redirectUrl || "",
      googlePixel: scriptInfo.googlePixel || "no",
      googlePurchasePixel: scriptInfo.googlePurchasePixel || "no",
      socialMediaDesc: scriptInfo.socialMediaDesc || "",
      socialMediaImage: scriptInfo.socialMediaImage || "",
      keyRadioSelect: Math.random(),
      keyTag: Math.random(),
      systemTags: [],
      sTags: scriptInfo.tags || [],
      tags: [],
      type: this.props.type || "landing",
      tyLeadPixel: scriptInfo.tyLeadPixel || "no",
      tyPurchasePixel: scriptInfo.tyPurchasePixel || "no",
      cmpType: this.props.cmpType || "popup",
      keyCheckoutItems: Math.random(),
      selectedCheckoutItem: { value: checkoutItemId, label: checkoutItemId },
      checkoutItems: [],
      enableCheckout: scriptInfo.enableCheckout || "no",
      enableAffiliate: scriptInfo.enableAffiliate || "no",
    };
  }

  componentDidMount() {
    this.getSystemTags();
    this.getCheckoutItems();
  }

  async getCheckoutItems() {
    let response = await callGetCheckoutItems();
    let checkoutItems = response.data;
    let items = [];
    checkoutItems.forEach((element) => {
      if (element.checkout_id) {
        items.push({
          value: element.checkout_id,
          label: element.checkout_id,
        });
      }
    });
    this.setState({
      checkoutItems: items,
      keyCheckoutItems: Math.random(),
    });
  }

  async getSystemTags() {
    let tags = await callGetSystemTags();
    let selectedTags = [];
    if (this.state.queId !== 0) {
      let sTags = this.state.sTags;

      sTags.forEach((element) => {
        let fTags = tags.data.filter((d) => {
          return d._id === element;
        });
        if (fTags.length > 0) {
          selectedTags.push(fTags[0]);
        }
      });
    }
    this.setState({
      systemTags: tags.data,
      keyTag: Math.random(),
      tags: selectedTags,
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  onSelectRadioSelect(item, id) {
    let value = item[Object.keys(item)[0]].value;
    this.setState({ [id]: value });
  }

  onChangeTags = (tags) => {
    this.setState({ tags: tags });
  };

  onClickSubmit() {
    $(
      ".modalAlertOverlay.scriptSettingModal,.modalAlert.scriptSettingAlert"
    ).fadeOut(200);

    let tags = this.state.tags
      .filter((d) => {
        return d._id !== null;
      })
      .map((t) => t._id);

    let data = {
      googlePixel: this.state.googlePixel,
      fbPixel: this.state.fbPixel,
      leadPixel: this.state.leadPixel,
      purchasePixel: this.state.purchasePixel,
      googlePurchasePixel: this.state.googlePurchasePixel,
      headerScript: this.state.headerScript,
      footerScript: this.state.footerScript,
      pageTitle: this.state.pageTitle,
      redirectUrl: this.state.redirectUrl,
      tyLeadPixel: this.state.tyLeadPixel,
      tyPurchasePixel: this.state.tyPurchasePixel,
      socialMediaDesc: this.state.socialMediaDesc,
      socialMediaImage: this.state.socialMediaImage,
      tags: tags,
      checkoutItemId:
        this.state.enableCheckout === "yes"
          ? this.state.selectedCheckoutItem.value
          : "",
      enableCheckout: this.state.enableCheckout,
      enableAffiliate: this.state.enableAffiliate,
    };
    this.props.onSubmit(data);
  }

  onClickCancel() {
    $(
      ".modalAlertOverlay.scriptSettingModal,.modalAlert.scriptSettingAlert"
    ).fadeOut(200);
  }

  onSelectCheckoutItem = (item) => {
    this.setState({ selectedCheckoutItem: item });
  };

  onChangeImage = (image) => {
    this.setState({ socialMediaImage: image });
  };

  renderSettings() {
    return (
      <div>
        {this.state.type === "landing" && (
          <>
            <div className="gap20" />
            <div
              className={
                this.state.pageTitle !== "" ? "mdInput mdFocussed" : "mdInput"
              }>
              <label>Page Title</label>
              <input
                type="text"
                name="pageTitle"
                value={this.state.pageTitle}
                onChange={(event) => this.handleInputChange(event)}
              />
            </div>
            {/* <TagSelect
              id={"script-popup-add-tag"}
              key={this.state.keyTag}
              selectedValues={this.state.tags}
              searchKey={"name"}
              selectKey={"name"}
              btnText={"Create New Tag"}
              lblText={"Add Tags on Form Submission"}
              data={this.state.systemTags}
              onChange={this.onChangeTags}
            /> */}
            <div
              className={
                this.state.redirectUrl !== "" ? "mdInput mdFocussed" : "mdInput"
              }>
              <label>Redirect Url</label>
              <input
                type="text"
                name="redirectUrl"
                value={this.state.redirectUrl}
                onChange={(event) => this.handleInputChange(event)}
              />
            </div>
            <div className="gap10" />

            <div
              className={
                this.state.headerScript !== ""
                  ? "mdInput mdFocussed"
                  : "mdInput"
              }>
              <label>Header Script</label>
              <textarea
                name="headerScript"
                onChange={this.handleInputChange.bind(this)}
                value={this.state.headerScript}
              />
            </div>

            <div className="gap10" />
            <label>
              Note: Add any custom header scripts you would like added to your
              page header.
            </label>
            <div
              className={
                this.state.footerScript !== ""
                  ? "mdInput mdFocussed"
                  : "mdInput"
              }>
              <label>Footer Script</label>
              <textarea
                name="footerScript"
                onChange={this.handleInputChange.bind(this)}
                value={this.state.footerScript}
              />
            </div>

            <div className="gap10" />
            <label>
              Note: Add any custom footer scripts you would like added to your
              page footer.
            </label>
            <div className="gap10" />
            <div
              className={
                this.state.socialMediaDesc !== ""
                  ? "mdInput mdFocussed"
                  : "mdInput"
              }>
              <label>Social Media Description</label>
              <input
                type="text"
                name="socialMediaDesc"
                value={this.state.socialMediaDesc}
                onChange={(event) => this.handleInputChange(event)}
              />
            </div>
            <div className="gap10" />
            <SelectAddImage
              type="social-media-image"
              label="Social Media Image (Must be at least 200x200 px)"
              onChangeImage={this.onChangeImage}
              image={this.state.socialMediaImage}
            />
          </>
        )}
        <div className="gap20" />

        <div className="row">
          <div className="col-sm-6">
            <div>
              <label>Add your google tag id to this page</label>
              <div className="tabsHolder radioTabs">
                <RadioTag
                  onCheckChange={this.onSelectRadioSelect.bind(this)}
                  labelList={[
                    { name: "Yes", value: "yes" },
                    { name: "No", value: "no" },
                  ]}
                  key={this.state.keyRadioSelect}
                  id={"googlePixel"}
                  selectType={"value"}
                  selectedList={[this.state.googlePixel]}
                />
              </div>
              <div className="gap10" />
              <label>
                Note: This google tag id is set from your settings page in
                integrations area.
              </label>
              <div className="gap10" />
            </div>
          </div>
        </div>

        {this.state.googlePixel === "yes" && (
          <>
            <div className="row">
              <div className="col-sm-6">
                <div>
                  <label>Track this page as a purchase</label>
                  <div className="tabsHolder radioTabs">
                    <RadioTag
                      onCheckChange={this.onSelectRadioSelect.bind(this)}
                      labelList={[
                        { name: "Yes", value: "yes" },
                        { name: "No", value: "no" },
                      ]}
                      key={this.state.keyRadioSelect}
                      id={"googlePurchasePixel"}
                      selectType={"value"}
                      selectedList={[this.state.googlePurchasePixel]}
                    />
                  </div>
                  <div className="gap20" />
                </div>
              </div>
            </div>
          </>
        )}

        {this.state.type === "landing" && (
          <>
            <div>
              <label>Checkout</label>
              <div className="tabsHolder radioTabs">
                <RadioTag
                  onCheckChange={this.onSelectRadioSelect.bind(this)}
                  labelList={[
                    { name: "Disabled", value: "no" },
                    { name: "Enabled", value: "yes" },
                  ]}
                  key={this.state.keyRadioSelect}
                  id={"enableCheckout"}
                  selectType={"value"}
                  selectedList={[this.state.enableCheckout]}
                />
              </div>
              <div className="gap10" />
            </div>
            {this.state.enableCheckout === "yes" && (
              <>
                <TypeableSelect
                  key={this.state.keyCheckoutItems}
                  name={"checkoutItemId"}
                  placeholder={"Select Checkout Item"}
                  selected={this.state.selectedCheckoutItem}
                  onSelect={this.onSelectCheckoutItem}
                  options={this.state.checkoutItems}
                />
                <div className="gap10" />
              </>
            )}
            <div className="gap10" />
            <div>
              <label>Enable Affiliate</label>
              <div className="tabsHolder radioTabs">
                <RadioTag
                  onCheckChange={this.onSelectRadioSelect.bind(this)}
                  labelList={[
                    { name: "No", value: "no" },
                    { name: "Yes", value: "yes" },
                  ]}
                  key={this.state.keyRadioSelect}
                  id={"enableAffiliate"}
                  selectType={"value"}
                  selectedList={[this.state.enableAffiliate]}
                />
              </div>
              <div className="gap10" />
            </div>
          </>
        )}
      </div>
    );
  }

  renderSettingModal() {
    let modalHeight = window.innerHeight - 250;
    return (
      <div>
        <div className="modalAlertOverlay scriptSettingModal" />
        <div className="modalAlert scriptSettingAlert bigX">
          <div className="clearfix">
            <div className="pull-left">
              <p>Settings</p>
            </div>
          </div>

          <div className="container-fluid">
            <Scrollbars
              autoHide
              autoHideTimeout={1000}
              autoHideDuration={200}
              style={{ height: modalHeight }}>
              {this.renderSettings()}
            </Scrollbars>
          </div>

          <div className="gap20" />
          <div className="alertFooterBtns">
            <button
              onClick={this.onClickCancel.bind(this)}
              className="btn btn-default dismissThisModal margin-right-10">
              Cancel
            </button>
            <button
              onClick={this.onClickSubmit.bind(this)}
              className="btn btn-primary dismissThisModal">
              Save
            </button>
          </div>
        </div>
      </div>
    );
  }

  render() {
    let type = this.state.cmpType;
    if (type === "popup") {
      return <div>{this.renderSettingModal()}</div>;
    } else {
      return (
        <div>
          {this.renderSettings()}
          <div className="gap10" />
          <div className="text-right">
            <button
              className="btn btn-primary"
              onClick={() => this.onClickSubmit()}>
              Save
            </button>
          </div>
          <div className="gap20" />
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ScriptPopup);
