import React from "react";
import moment from "moment";
import { saveAs } from "file-saver";
import $ from "jquery";

import { sanitizer, imageUrl, linkify } from "../../../../helpers";
import images from "../../../../helpers/images";
import AddTaskComment from "./addComment";

class TaskCommentList extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      users: this.props.users,
      comments: this.props.comments,
      currentUserId: this.props.currentUserId,
      commentId: "",
      comment: {},
      keyComment: Math.random(),
    };
  }

  getUserName(id) {
    let users = this.state.users;
    let fUsers = users.filter((d) => d._id === id);
    if (fUsers.length > 0) {
      return [fUsers[0].name, fUsers[0].profile_image_url];
    }
    return "";
  }

  onClickFileUrl(url) {
    saveAs(imageUrl(url));
  }

  onClickDeleteComment(commentId) {
    this.setState({ commentId: commentId }, () => {
      $(
        ".modalAlertOverlay.deleteCommentModal,.modalAlert.deletCommentAlert"
      ).fadeIn(200);
    });
  }

  onClickEditComment(comment) {
    this.setState({ comment: comment, keyComment: Math.random() }, () => {
      $(
        ".modalAlertOverlay.editCommentModal,.modalAlert.editCommentAlert"
      ).fadeIn(200);
    });
  }

  renderMentionUsers(element) {
    let users = element.users;
    let returnVals = [];
    for (let u = 0; u < users.length; u++) {
      const element = users[u];
      const [userName] = this.getUserName(element);
      if (userName) {
        returnVals.push(
          <i className="firstLetter">@ {this.getUserName(element)[0]} </i>
        );
      }
    }
    return returnVals;
  }

  renderCommentList() {
    let comments = this.state.comments;
    let returnVals = [];
    for (let i = 0; i < comments.length; i++) {
      const element = comments[i];
      let [userName, userImage] = this.getUserName(element.userId);
      returnVals.push(
        <div className={"conversationLeft"} key={i}>
          {userImage !== undefined && (
            <div
              className="convImg"
              style={{
                backgroundImage: `url(${imageUrl(userImage)})`,
              }}
            />
          )}
          {userImage === undefined && (
            <div
              className="userInfoImage convImg"
              style={{
                backgroundImage: `url(${images.userImage})`,
              }}
            />
          )}
          <div className="convContent">
            <div className="convText">
              <b className="convAuthor">{userName}</b>
              {/* {element.users && element.users.length > 0 && (
                <p className="convTextContent">
                  {this.renderMentionUsers(element)}
                </p>
              )} */}
              <p className="convTextContent">
                <div
                  dangerouslySetInnerHTML={{
                    __html: sanitizer(linkify(element.comment)),
                  }}
                />
              </p>
            </div>
            <div className="cleafix">
              <div className="pull-left">
                {element.fileUrl && (
                  <label
                    className="link pointer-click margin-right-10"
                    onClick={this.onClickFileUrl.bind(this, element.fileUrl)}>
                    <u>Attachment</u>
                  </label>
                )}
              </div>
              <div className="convTime pull-right">
                {this.state.currentUserId === element.userId && (
                  <>
                    <label
                      className="link pointer-click margin-right-10"
                      onClick={this.onClickEditComment.bind(this, element)}>
                      Edit
                    </label>
                    <label
                      className="link pointer-click margin-right-10"
                      onClick={this.onClickDeleteComment.bind(
                        this,
                        element.id
                      )}>
                      Delete
                    </label>
                  </>
                )}
                {moment(element.date)
                  .tz(moment.tz.guess())
                  .format("Do MMM YYYY hh:mm A z")}
              </div>
            </div>
            <div className="gap30" />
          </div>
        </div>
      );
    }
    return returnVals;
  }

  onClickNoCommentAlert = () => {
    this.setState({ commentId: {} }, () => {
      $(
        ".modalAlertOverlay.deleteCommentModal,.modalAlert.deletCommentAlert"
      ).fadeOut(200);
    });
  };

  onClickYesCommentAlert = () => {
    $(
      ".modalAlertOverlay.deleteCommentModal,.modalAlert.deletCommentAlert"
    ).fadeOut(200);
    this.props.onDeleteComment(this.state.commentId);
  };

  renderDeleteCommentAlert() {
    return (
      <div>
        <div className="modalAlertOverlay deleteCommentModal" />
        <div className="modalAlert deletCommentAlert">
          <p>Are you sure you want to delete this comment ?</p>
          <div className="alertFooterBtns">
            <button
              className="btn btn-default closeModal-No margin-right-10"
              onClick={this.onClickNoCommentAlert}>
              No
            </button>
            <button
              className="btn btn-primary closeModal-Yes"
              onClick={this.onClickYesCommentAlert}>
              Yes
            </button>
          </div>
        </div>
      </div>
    );
  }

  onClickCancelEditCommentAlert = () => {
    this.setState({ commentId: {} }, () => {
      $(
        ".modalAlertOverlay.editCommentModal,.modalAlert.editCommentAlert"
      ).fadeOut(200);
    });
  };

  onClickSubmitEditCommentAlert = () => {
    $(
      ".modalAlertOverlay.editCommentModal,.modalAlert.editCommentAlert"
    ).fadeOut(200);
  };

  renderEditCommentAlert() {
    return (
      <div>
        <div className="modalAlertOverlay editCommentModal" />
        <div className="modalAlert editCommentAlert bigX">
          <div className="clearfix">
            <div className="pull-left">
              <p style={{ fontSize: 18 }}>Edit Comment</p>
            </div>
          </div>
          <div className="container-fluid">
            <AddTaskComment
              users={this.state.users}
              comment={this.state.comment}
              type="edit"
              key={this.state.keyComment}
              onClickUpdateComment={this.props.onClickUpdateComment}
            />
          </div>
          <div className="gap20" />
        </div>
      </div>
    );
  }

  render() {
    let commentsCount = this.state.comments.length;
    if (commentsCount === 0) {
      return null;
    }
    return (
      <div className="card">
        <div className="container-fluid">
          {this.renderDeleteCommentAlert()}
          {this.renderEditCommentAlert()}
          <div className="gap10" />
          <h5>Comments ({commentsCount})</h5>
          <div className="gap20" />
          <div className="conversationHolder">{this.renderCommentList()}</div>
        </div>
      </div>
    );
  }
}
export default TaskCommentList;
