import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment";

import TitleBox from "../../../components/general/titleBox";
import RadioTag from "../../../components/form/radioTag";
import {
  callGetStripeAccountBalance,
  callCreateBankTransfer,
  callGetBankTransfers,
} from "../../../services";
import { showNotification } from "../../../helpers";
import HelpTextDiv from "../../../components/form/helpTextDiv";

class AddBankTransfer extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      userData: this.props.userData || {},
      processor: "stripe",
      amountAvailable: 0,
      amountPending: 0,
      amount: "",
      notes: "",
      bankTransfers: [],
      showBalance: false,
    };
  }

  componentDidMount() {
    if (this.state.processor === "stripe") {
      this.getStripeAccountBalance();
    }
    this.getBankTransfers();
    if (this.state.userData) {
      this.checkShowBalance();
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.userData) {
      this.setState({ userData: newProps.userData }, () => {
        this.checkShowBalance();
      });
    }
  }

  checkShowBalance() {
    let userData = this.state.userData;
    let roles = userData.roles || [];
    let check = true;
    if (roles.indexOf("payments") > -1) {
      let paymentsRoles = userData.payments_roles || [
        "account-balance",
        "member-payments",
      ];
      check = paymentsRoles.indexOf("account-balance") > -1;
    }
    this.setState({ showBalance: check });
  }

  getBankTransfers() {
    let query = {
      query: {
        $sort: { createdAt: -1 },
      },
    };
    callGetBankTransfers(query).then((d) => {
      this.setState({ bankTransfers: d.data });
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  getStripeAccountBalance() {
    callGetStripeAccountBalance().then((d) => {
      if (d.object === "balance") {
        this.setState({
          amountAvailable: (d.available[0].amount / 100).toFixed(2),
          amountPending: (d.pending[0].amount / 100).toFixed(2),
        });
      }
    });
  }

  onSelectProcessor(item, id) {
    let value = item[Object.keys(item)[0]].value;
    this.setState({ [id]: value });
  }

  resetProps() {
    this.setState({
      processor: "stripe",
      amount: "",
      notes: "",
    });
  }

  onClickCreateTransfer() {
    if (this.state.amount.trim().length === 0) {
      showNotification("error", "Amount is required", 4000);
      return;
    }
    if (parseFloat(this.state.amount) <= 0) {
      showNotification("error", "Amount can not be less then 1", 4000);
      return;
    }
    if (parseFloat(this.state.amount) > this.state.amountAvailable) {
      showNotification(
        "error",
        "Amount should be less then balance available",
        4000
      );
      return;
    }
    let apiData = {
      /*eslint-disable*/
      processor: this.state.processor,
      description: this.state.notes,
      amount: this.state.amount,
      member_id: this.state.userData._id,
      /*eslint-enable*/
    };

    callCreateBankTransfer(apiData).then((d) => {
      if (d._id) {
        showNotification("success", "Transfer created successfully", 4000);
        this.resetProps();
        this.getStripeAccountBalance();
        this.getBankTransfers();
      } else {
        showNotification("error", d.message, 4000);
      }
    });
  }

  render() {
    return (
      <div>
        <div className="container-fluid">
          <TitleBox title="Bank Transfer" />
          <div className="gap20" />
          <div className="card">
            <div className="list-group list-group-flush">
              <div className="list-group-item">
                <h5 className="noMargin">Create New Bank Transfer</h5>
              </div>
              <div className="list-group-item">
                <div className="radioBHolder">
                  <div className="havingHelpText">
                    <p>Processor</p>
                  </div>
                  <HelpTextDiv
                    id="idBankTransferProcessor"
                    label="Learn more about the Processor"
                    type="addBankTransfer"
                    isInline="true"
                  />
                  <div className="tabsHolder radioTabs">
                    <RadioTag
                      onCheckChange={this.onSelectProcessor.bind(this)}
                      labelList={[{ name: "Stripe", value: "stripe" }]}
                      id={"processor"}
                      selectedList={[this.state.processor]}
                      selectType={"value"}
                    />
                  </div>
                </div>
                <div className="gap20" />
                {this.state.showBalance && (
                  <div>
                    <div className="havingHelpText">
                      <h5 className="noMargin">
                        Balance Available : &nbsp;
                        <b>${this.state.amountAvailable}</b>
                      </h5>
                    </div>
                    <HelpTextDiv
                      id="idBankTransferBalanceAvailable"
                      label="Learn more about the Balance Available"
                      type="addBankTransfer"
                      isInline="true"
                    />
                    <div className="gap10" />

                    <div className="havingHelpText">
                      <h5 className="noMargin">
                        Balance Pending : &nbsp;
                        <b>${this.state.amountPending}</b>
                      </h5>
                    </div>
                    <HelpTextDiv
                      id="idBankTransferBalancePending"
                      label="Learn more about the Balance Pending"
                      type="addBankTransfer"
                      isInline="true"
                    />
                    <div className="gap10" />
                  </div>
                )}
                <div className="gap10" />
                <div className="card">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-sm-6">
                        <HelpTextDiv
                          id="idBankTransferAmount"
                          label="Learn more about the Amount"
                          type="addBankTransfer"
                          isInline="false"
                          topMargin="10"
                        />
                        <div
                          className={
                            this.state.amount !== ""
                              ? "mdInput mdFocussed"
                              : "mdInput"
                          }>
                          <label>Enter Amount</label>
                          <input
                            type="text"
                            name="amount"
                            value={this.state.amount}
                            onChange={(event) => this.handleInputChange(event)}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <HelpTextDiv
                          id="idBankTransferNotes"
                          label="Learn more about the Notes"
                          type="addBankTransfer"
                          isInline="false"
                          topMargin="10"
                        />
                        <div
                          className={
                            this.state.notes !== ""
                              ? "mdInput mdFocussed"
                              : "mdInput"
                          }>
                          <label>Transfer Notes</label>
                          <input
                            type="text"
                            name="notes"
                            value={this.state.notes}
                            onChange={(event) => this.handleInputChange(event)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="gap20" />
                    <div className="text-right">
                      <button
                        className="btn btn-primary"
                        onClick={this.onClickCreateTransfer.bind(this)}>
                        Create Transfer
                      </button>
                    </div>
                    <div className="gap20" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.state.bankTransfers.length > 0 && (
            <div className="card">
              <div className="list-group list-group-flush">
                <div>
                  <div className="list-group-item">
                    <h5 className="noMargin">Transfer History</h5>
                  </div>
                  <div className="list-group-item">
                    <div className="table-responsive">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Amount</th>
                            <th>Payout Id</th>
                            <th>Processor</th>
                            <th>Notes</th>
                            <th>Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.bankTransfers.map((i) => (
                            <tr key={i._id}>
                              <td className="firstLetter">
                                {i.member_info.name}
                              </td>
                              <td>${parseFloat(i.amount).toFixed(2)}</td>
                              <td>{i.payout_id}</td>
                              <td>{i.processor}</td>
                              <td>{i.description ? i.description : "-"}</td>
                              <td>
                                {moment(i.createdAt).format(
                                  "MMM, Do YYYY hh:mm A"
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.authReducer.userData,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddBankTransfer);
