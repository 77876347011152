import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { showNotification } from "../../helpers";
import { callGetChallengGroupByQuery } from "../../services/challengeService";

class SelectChallengeGroup extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      challengeGroups: [],
      selectedChallengeGroup: "",
      expireDays: "",
      selectedChallengeGroups: this.props.selectedChallengeGroups || []
    };
  }

  componentDidMount() {
    let query = {
      query: {
        active: true
      }
    };
    callGetChallengGroupByQuery(query).then(d => {
      this.setState({ challengeGroups: d.data });
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  renderChallengeGroups() {
    let returnVals = [];
    let groups = this.state.challengeGroups;
    returnVals.push(<option value={""} key={0} />);
    for (let i = 0; i < groups.length; i++) {
      returnVals.push(
        <option value={groups[i]._id} key={i + 1}>
          {groups[i].name}
        </option>
      );
    }
    return returnVals;
  }

  onClickAddChallengeGroup() {
    if (this.state.selectedChallengeGroup.trim().length === 0) {
      showNotification("error", "Please select a group credit", 4000);
      return;
    }

    let challengeGroup = this.state.challengeGroups.filter(d => {
      return d._id === this.state.selectedChallengeGroup;
    });

    let challengeGroupInfo = {
      groupId: challengeGroup[0]._id,
      name: challengeGroup[0].name,
      expireDays: this.state.expireDays
    };

    let selectedChallengeGroups = this.state.selectedChallengeGroups;
    selectedChallengeGroups.push(challengeGroupInfo);
    this.setState(
      {
        selectedChallengeGroup: "",
        expireDays: "",
        selectedChallengeGroups: selectedChallengeGroups
      },
      () => {
        this.props.onChange(this.state.selectedChallengeGroups);
      }
    );
  }

  onClickRemoveFromSelectedChallengeGroup(i) {
    let selectedChallengeGroups = this.state.selectedChallengeGroups;
    selectedChallengeGroups.splice(i, 1);
    this.setState(
      {
        selectedChallengeGroups: selectedChallengeGroups
      },
      () => {
        this.props.onChange(this.state.selectedChallengeGroups);
      }
    );
  }

  renderSelectedChallengeGroups() {
    var returnVals = [];
    let selectedChallengeGroups = this.state.selectedChallengeGroups;
    for (let i = 0; i < selectedChallengeGroups.length; i++) {
      const element = selectedChallengeGroups[i];
      returnVals.push(
        <tr key={i}>
          <td className="col-sm-11">{element.name}</td>
          <td>
            {/*eslint-disable-next-line*/}
            <a
              className="text-danger"
              onClick={this.onClickRemoveFromSelectedChallengeGroup.bind(
                this,
                i
              )}
            >
              Remove
            </a>
          </td>
        </tr>
      );
    }
    return returnVals;
  }

  render() {
    if (this.state.challengeGroups.length === 0) {
      return null;
    }
    return (
      <div>
        <div className="card">
          <div className="list-group list-group-flush">
            <div className="list-group-item">
              <div className="row">
                <div className="col-lg-4">
                  <div
                    className={
                      this.state.selectedChallengeGroup !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }
                  >
                    <label>Select Group Credit</label>
                    <select
                      name="selectedChallengeGroup"
                      value={this.state.selectedChallengeGroup}
                      onChange={event => this.handleInputChange(event)}
                    >
                      {this.renderChallengeGroups()}
                    </select>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div
                    className={
                      this.state.expireDays !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }
                  >
                    <label>Expire Days</label>
                    <input
                      type="text"
                      name="expireDays"
                      value={this.state.expireDays}
                      onChange={event => this.handleInputChange(event)}
                    />
                  </div>
                </div>
              </div>
              <div className="gap20" />
              <div className="text-right">
                <button
                  className="btn btn-primary"
                  onClick={this.onClickAddChallengeGroup.bind(this)}
                >
                  Add Group Credit
                </button>
              </div>
              <div className="gap10" />
            </div>
          </div>
        </div>

        {this.state.selectedChallengeGroups.length > 0 && (
          <div className="card">
            <div className="list-group list-group-flush">
              <div className="list-group-item">
                <h5 className="noMargin">Added Group Credits</h5>
                <div className="gap20" />
                <table className="table table-bordered">
                  <tbody>{this.renderSelectedChallengeGroups()}</tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectChallengeGroup);
