import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";

import authReducer from "./authReducer";
import challengeReducer from "./challengeReducer";
import articleReducer from "./articleReducer";
import tagReducer from "./tagReducer";
import emailReducer from "./emailReducer";
import billingReducer from "./billingReducer";
import mealPlanReducer from "./mealPlanReducer";
import marciCircleReducer from "./marciCircleReducer";
import systemUpdateReducer from "./systemUpdateReducer";
import customProgramReducer from "./customProgramReducer";
import linkReducer from "./linkReducer";
import btpQuestionnaireReducer from "./btpQuestionnaireReducer";
import chatReducer from "./chatReducer";
import generalReducer from "./generalReducer";

export default combineReducers({
  router: routerReducer,
  authReducer,
  challengeReducer,
  articleReducer,
  tagReducer,
  emailReducer,
  billingReducer,
  mealPlanReducer,
  marciCircleReducer,
  systemUpdateReducer,
  customProgramReducer,
  linkReducer,
  btpQuestionnaire: btpQuestionnaireReducer,
  chatStore: chatReducer,
  generalReducer: generalReducer
});
