import * as api from "./api";
import { callApi } from "../helpers";

function callCreateSystemTag(data) {
  return callApi(api.service("system-tag").create(data));
}

function callUpdateSystemTag(id, data) {
  return callApi(api.service("system-tag").patch(id, data));
}

function callGetSystemTags(query = {}) {
  return callApi(api.service("system-tag").find(query));
}

function callGetPageSystemTags(query = {}) {
  return callApi(api.service("system-tag/page").find(query));
}

function callGetSystemTagById(id) {
  return callApi(api.service("system-tag").get(id));
}

function callGetSystemTagUsingName(name, isLoading = true, auth = true) {
  return callApi(
    api.service("system-tag", auth).find({ query: { name: name } }),
    isLoading
  );
}

function callCreateGenericTag(data) {
  return callApi(api.service("tags").create(data));
}

function callUpdateGenericTag(id, data) {
  return callApi(api.service("tags").patch(id, data));
}

function callGetGenericTags(type) {
  return callApi(api.service("tags").find({ query: { type: type } }));
}

function callGetGenericTagUsingId(id) {
  return callApi(api.service("tags").get(id));
}

function callGetGenericTagsUsingQuery(query) {
  return callApi(api.service("tags").find(query));
}

function callCreateTagUsers(data) {
  return callApi(api.service("tag-users").create(data));
}

function callUpdateTagUsers(id, data) {
  return callApi(api.service("tag-users").patch(id, data));
}

function callGetTagUsersByQuery(query) {
  return callApi(api.service("tag-users").find(query));
}

function callCreateResource(data) {
  return callApi(api.service("resources").create(data));
}

function callUpdateResource(id, data) {
  return callApi(api.service("resources").patch(id, data));
}

function callGetResourcesByQuery(query) {
  return callApi(api.service("resources").find(query));
}

function callDeleteResourceById(id) {
  return callApi(api.service("resources").remove(id));
}

export {
  callCreateSystemTag,
  callUpdateSystemTag,
  callGetSystemTags,
  callGetSystemTagUsingName,
  callCreateGenericTag,
  callGetGenericTags,
  callCreateTagUsers,
  callUpdateTagUsers,
  callGetTagUsersByQuery,
  callCreateResource,
  callUpdateResource,
  callGetResourcesByQuery,
  callDeleteResourceById,
  callGetGenericTagsUsingQuery,
  callUpdateGenericTag,
  callGetSystemTagById,
  callGetGenericTagUsingId,
  callGetPageSystemTags,
};
