import React from "react";
import { setItem } from "../../helpers/storage";

class SortItem extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      items: this.props.items,
      columns: this.props.columns,
      selectedSortColumn: this.props.selectedSortColumn || "",
      selectedSortOrder: this.props.selectedSortOrder || "-1",
      filterType: this.props.filterType || "data",
    };
  }

  componentDidMount() {
    if (this.state.filterType === "data") {
      this.sortItems();
    }
  }

  handleSortChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState(
      {
        [name]: value,
      },
      () => {
        setItem("taskSortColumn", this.state.selectedSortColumn);
        setItem("taskSortOrder", this.state.selectedSortOrder);
        if (this.state.filterType === "data") {
          this.sortItems();
        }
      }
    );
  }

  sortItems() {
    let items = this.state.items;
    let column = this.state.selectedSortColumn;
    let order = this.state.selectedSortOrder;

    if (order.length === 0 || column.length === 0) {
      return;
    }

    let orderTypes = this.state.columns.filter((d) => {
      return d.value === column;
    });
    let orderType = "";
    if (orderTypes.length > 0) {
      orderType = orderTypes[0].type;
    }

    let sortA = order === "-1" ? 1 : -1;
    let sortD = order === "-1" ? -1 : 1;

    items.sort((a, b) => {
      let colA = "";
      let colB = "";
      switch (orderType) {
        case "string":
          colA = a[column].toLowerCase();
          colB = b[column].toLowerCase();
          break;
        case "date":
          colA = new Date(a[column]);
          colB = new Date(b[column]);
          break;
        default:
          break;
      }

      if (colA < colB) {
        return sortA;
      }
      if (colA > colB) {
        return sortD;
      }
      return 0;
    });

    this.props.onChangeItems(items);
  }

  renderColumns() {
    let returnVals = [];
    let columns = this.state.columns;
    returnVals.push(<option value="" />);
    for (let i = 0; i < columns.length; i++) {
      const element = columns[i];
      returnVals.push(<option value={element.value}>{element.label}</option>);
    }
    return returnVals;
  }

  render() {
    return (
      <>
        <p>Sort By</p>
        <div className="row">
          <div className="col-sm-4">
            <div
              className={
                this.state.selectedSortColumn !== ""
                  ? "mdInput mdFocussed"
                  : "mdInput"
              }>
              <label>Select Column</label>
              <select
                name="selectedSortColumn"
                value={this.state.selectedSortColumn}
                onChange={(event) => this.handleSortChange(event)}>
                {this.renderColumns()}
              </select>
            </div>
          </div>
          <div className="col-sm-4">
            <div
              className={
                this.state.selectedSortOrder !== ""
                  ? "mdInput mdFocussed"
                  : "mdInput"
              }>
              <label>Select Order</label>
              <select
                name="selectedSortOrder"
                value={this.state.selectedSortOrder}
                onChange={(event) => this.handleSortChange(event)}>
                <option value="" />
                <option value="1">Ascending</option>
                <option value="-1">Descending</option>
              </select>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default SortItem;
