import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { VelocityTransitionGroup } from "velocity-react";

import RadioTag from "../../../components/form/radioTag";
import TitleBox from "../../../components/general/titleBox";
import TagSelect from "../../../components/form/tagSelect";
import SelectChallenge from "../../../components/form/selectChallenge";
import SelectProduct from "../../../components/form/selectProduct";
import PageContent from "../../../components/editor/pageContent";
import HelpTextDiv from "../../../components/form/helpTextDiv";

import {
  getSystemTags,
  getStripePlans,
  getChlgs,
  resetStatus,
  createUpsellItem,
  updateUpsellItem,
} from "../../../modules/actions/index";
import {
  showNotification,
  slugify,
  isFS,
  getEditorType,
  getUniqueId,
} from "../../../helpers";
import { getItem } from "../../../helpers/storage";
import { callGetUpsellItemById } from "../../../services";
import { callEmailTemplateById } from "../../../services/settingService";
import config from "../../../helpers/config";
import { processEditorContent } from "../../../components/editor/processEditor";

const OrderItemButtons = (props) => {
  return (
    <div className="pull-right">
      <div className="gap20" />
      <button
        className="btn btn-default btn-sm margin-right-10"
        onClick={props.onClickEdit}>
        Edit
      </button>
      <button className="btn btn-danger btn-sm" onClick={props.onClickRemove}>
        Remove
      </button>
    </div>
  );
};

class AddUpsellItem extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    let upsellItem = {
      _id: 0,
      name: "",
      description: "",
      urlDisplyName: getUniqueId(),
      orderType: "single",
      amountList: [],
      planList: [],
      productList: {},
      keyChallenges: Math.random(),
      keyProducts: Math.random(),
      selectedChallenges: [],
      selectedProducts: [],
      content: {},
      htmlContent: "",
      addTags: [],
      selectedTags: [],
      keyAddTag: Math.random(),
      editorType: isFS()
        ? "mp"
        : getItem("de") === "classic"
        ? "unlayer"
        : "mp",
      draftId: "",
      draftImage: "",
    };

    if (this.props.history.location.state !== undefined) {
      let ulItem = this.props.history.location.state.upsellItem;

      upsellItem._id = ulItem._id;
    }

    this.state = {
      upsellItemId: upsellItem._id,
      name: upsellItem.name,
      description: upsellItem.description,
      urlDisplyName: upsellItem.urlDisplyName,
      orderAmount: "",
      orderPlan: "",
      orderType: upsellItem.orderType,
      amountList: upsellItem.amountList,
      planList: upsellItem.planList,
      productList: upsellItem.productList,
      plans: [],
      challenges: [],
      keyChallenges: upsellItem.keyChallenges,
      keyProducts: upsellItem.keyProducts,
      selectedChallenges: upsellItem.selectedChallenges,
      selectedProducts: upsellItem.selectedProducts,
      content: upsellItem.content,
      htmlContent: upsellItem.htmlContent,
      systemTags: [],
      addTags: upsellItem.addTags,
      selectedTags: upsellItem.selectedTags,
      keyAddTag: upsellItem.keyAddTag,
      selectedId: "",
      editorType: upsellItem.editorType,
      draftId: upsellItem.draftId,
      keyEditor: Math.random(),
      draftImage: upsellItem.draftImage,
      addChallengeStatus: "add",
      keyAddChallengeStatus: Math.random(),
    };
  }

  componentDidMount() {
    this.getData();
  }

  async getData() {
    this.props.getStripePlans();
    this.props.getChlgs();
    this.props.getSystemTags();

    if (this.state.upsellItemId !== 0) {
      let ulItem = await callGetUpsellItemById(this.state.upsellItemId);
      let upsellItem = {
        _id: 0,
        name: "",
        description: "",
        urlDisplyName: "",
        orderType: "single",
        amountList: [],
        planList: [],
        productList: {},
        keyChallenges: Math.random(),
        keyProducts: Math.random(),
        selectedChallenges: [],
        selectedProducts: [],
        content: {},
        htmlContent: "",
        addTags: [],
        selectedTags: [],
        keyAddTag: Math.random(),
        editorType: isFS()
          ? "mp"
          : getItem("de") === "classic"
          ? "unlayer"
          : "mp",
        draftId: "",
        draftImage: "",
      };

      upsellItem._id = ulItem._id;
      upsellItem.name = ulItem.name;
      upsellItem.description = ulItem.description;
      upsellItem.urlDisplyName = ulItem.url_display_name || getUniqueId();
      upsellItem.orderType = ulItem.order_type;
      upsellItem.amountList = ulItem.amount_list || [];
      upsellItem.planList = ulItem.plan_list || [];
      upsellItem.content = ulItem.editor_content;
      upsellItem.htmlContent = ulItem.main_content;
      upsellItem.addTags = ulItem.ftags || [];
      upsellItem.selectedTags = ulItem.ftags || [];
      upsellItem.productList = ulItem.product_list || {};

      upsellItem.editorType = getEditorType(
        ulItem.main_content,
        ulItem.editor_content
      );

      upsellItem.draftId = ulItem.draft_id || "";
      upsellItem.draftImage = ulItem.draft_image || "";

      this.setState({
        upsellItemId: upsellItem._id,
        name: upsellItem.name,
        description: upsellItem.description,
        urlDisplyName: upsellItem.urlDisplyName,
        orderType: upsellItem.orderType,
        amountList: upsellItem.amountList,
        planList: upsellItem.planList,
        productList: upsellItem.productList,
        keyChallenges: upsellItem.keyChallenges,
        keyProducts: upsellItem.keyProducts,
        selectedChallenges: upsellItem.selectedChallenges,
        selectedProducts: upsellItem.selectedProducts,
        content: upsellItem.content,
        htmlContent: upsellItem.htmlContent,
        addTags: upsellItem.addTags,
        selectedTags: upsellItem.selectedTags,
        keyAddTag: upsellItem.keyAddTag,
        editorType: upsellItem.editorType,
        draftId: upsellItem.draftId,
        keyEditor: Math.random(),
        draftImage: upsellItem.draftImage,
        keyAddChallengeStatus: Math.random(),
      });
    } else {
      this.getDefaultTemplate();
    }
  }

  async getDefaultTemplate() {
    let editorData = await callEmailTemplateById(
      config.editorTemplateIds.defaultMpUpsellItem
    );
    if (editorData && editorData.data && editorData.data._id) {
      let template = editorData.data;

      let editorContent = processEditorContent(template.editor_content);

      this.setState({
        keyEditor: Math.random(),
        htmlContent: template.html,
        content: editorContent,
      });
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.message) {
      if (newProps.success) {
        showNotification("success", newProps.message, 5000);
        if (this.state.upsellItemId === 0) {
          this.resetProps();
        }
      } else {
        showNotification("error", newProps.message, 5000);
      }
      this.props.resetStatus();
    }

    if (newProps.chlgs.data) {
      this.setState({
        challenges: newProps.chlgs.data,
        keyChallenges: Math.random(),
      });
    }

    if (newProps.systemTags.data) {
      this.setState({
        systemTags: newProps.systemTags.data,
        keyAddTag: Math.random(),
      });
    }

    if (newProps.stripePlans.data) {
      this.setState({
        plans: newProps.stripePlans.data,
      });
    }
  }

  resetProps() {
    this.setState({
      name: "",
      description: "",
      orderType: "single",
      urlDisplyName: "",
      amountList: [],
      planList: [],
      keyChallenges: Math.random(),
      keyProducts: Math.random(),
      selectedChallenges: [],
      selectedProducts: [],
      content: {},
      htmlContent: "",
      addTags: [],
      selectedTags: [],
      keyAddTag: Math.random(),
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  onChangePlan(event) {
    const target = event.target;
    const value = target.value;

    this.setState({
      orderPlan: value,
    });
  }

  onSelectOrderType(item, id) {
    let value = item[Object.keys(item)[0]].value;
    this.setState({
      [id]: value,
      orderAmount: "",
      orderPlan: "",
      selectedChallenges: [],
      selectedProducts: [],
      keyProducts: Math.random(),
      keyChallenges: Math.random(),
      addChallengeStatus: "add",
    });
  }

  onChangeChallenges(challenges) {
    this.setState({ selectedChallenges: challenges });
  }

  onChangeProducts(products) {
    this.setState({ selectedProducts: products });
  }

  onChangeContent(html, content, draftId, draftImage) {
    this.setState(
      {
        content: content,
        htmlContent: html,
        draftId: draftId,
        draftImage: draftImage,
        keyEditor: Math.random(),
      },
      () => {
        this.onPressSaveUpsellItem();
      }
    );
  }

  onChangeAddTags = (tags) => {
    this.setState({ addTags: tags });
  };

  getProducts() {
    let productList = [];
    /*eslint-disable*/
    this.state.selectedChallenges.forEach((element) => {
      productList.push({
        challenge_id: element.challenge._id,
        product_id: "",
        name: element.challenge.name,
        start_date: element.challengeDate,
        type: element.challengeType,
        challenge_start_type: element.challengeStartType,
      });
    });

    this.state.selectedProducts.forEach((element) => {
      productList.push({
        challenge_id: "",
        product_id: "Y",
        name: element.name,
        current_day: element.currentDay,
        start_date: element.productDate,
        type: "product",
      });
    });
    return productList;
  }

  onPressAddOrderItem() {
    if (this.state.orderType === "single") {
      if (this.state.orderAmount.trim().length === 0) {
        showNotification("error", "Please add order amount", 4000);
        return;
      }
      if (this.state.addChallengeStatus === "add") {
        if (
          this.state.selectedChallenges.length === 0 &&
          this.state.selectedProducts.length === 0
        ) {
          showNotification("error", "Atleast 1 challenge/product is required");
          return;
        }
      }
      let amountList = this.state.amountList;
      let id = "";

      if (this.state.selectedId.length === 0) {
        id = Math.random().toString(36).substring(3);

        amountList.push({
          amount: this.state.orderAmount,
          id: id,
        });
      } else {
        id = this.state.selectedId;
        amountList.forEach((element) => {
          if (element.id === id) {
            element.amount = this.state.orderAmount;
          }
        });
      }

      let productList = this.state.productList;
      productList[id] = this.getProducts();

      this.setState({
        selectedId: "",
        orderAmount: "",
        amountList,
        productList,
        selectedChallenges: [],
        selectedProducts: [],
        keyChallenges: Math.random(),
        keyProducts: Math.random(),
      });
    }

    if (this.state.orderType === "subscription") {
      if (this.state.orderPlan.trim().length === 0) {
        showNotification("error", "Please select plan", 4000);
        return;
      }

      if (this.state.addChallengeStatus === "add") {
        if (
          this.state.selectedChallenges.length === 0 &&
          this.state.selectedProducts.length === 0
        ) {
          showNotification("error", "Atleast 1 challenge/product is required");
          return;
        }
      }

      let planList = this.state.planList;
      let id = "";

      if (this.state.selectedId.length === 0) {
        id = Math.random().toString(36).substring(3);

        planList.push({
          plan: this.state.orderPlan,
          id: id,
        });
      } else {
        id = this.state.selectedId;
        planList.forEach((element) => {
          if (element.id === id) {
            element.plan = this.state.orderPlan;
          }
        });
      }

      let productList = this.state.productList;
      productList[id] = this.getProducts();

      this.setState({
        selectedId: "",
        orderPlan: "",
        planList,
        productList,
        selectedChallenges: [],
        selectedProducts: [],
        keyChallenges: Math.random(),
        keyProducts: Math.random(),
      });
    }
  }

  onPressSaveUpsellItem() {
    if (this.state.name.trim().length === 0) {
      showNotification("error", "Name is required", 4000);
      return;
    }

    if (this.state.description.trim().length === 0) {
      showNotification("error", "Description is required", 4000);
      return;
    }

    if (this.state.orderType === "single") {
      if (this.state.amountList.length === 0) {
        showNotification("error", "Atleast 1 amount is required", 4000);
        return;
      }
    }

    if (this.state.orderType === "subscription") {
      if (this.state.planList.length === 0) {
        showNotification("error", "Atleast 1 plan is required", 4000);
        return;
      }
    }

    if (this.state.urlDisplyName.trim().length === 0) {
      showNotification("error", "Url display name is required", 4000);
      return;
    }

    if (/\s/g.test(this.state.urlDisplyName.trim())) {
      showNotification(
        "error",
        "Url display name should not contain space",
        5000
      );
      return;
    }

    if (this.state.htmlContent.trim().length === 0) {
      showNotification("error", "Content is required", 4000);
      return;
    }

    let tagList = this.state.addTags
      .filter((d) => {
        return d._id !== null;
      })
      .map((t) => t._id);

    let apiData = {
      name: this.state.name,
      description: this.state.description,
      url_display_name: slugify(this.state.urlDisplyName),
      order_type: this.state.orderType,
      amount_list: this.state.amountList,
      plan_list: this.state.planList,
      payments: "",
      product_list: this.state.productList,
      tag_list: tagList,
      editor_content: this.state.content,
      main_content: this.state.htmlContent,
      draft_id: this.state.draftId,
      draft_image: this.state.draftImage,
    };
    /*eslint-enable*/

    if (this.state.upsellItemId === 0) {
      this.props.createUpsellItem(apiData);
    } else {
      this.props.updateUpsellItem(this.state.upsellItemId, apiData);
    }
  }

  renderPlans() {
    let returnVals = [];
    let plans = this.state.plans;
    returnVals.push(<option value={""} key={0} />);
    for (let i = 0; i < plans.length; i++) {
      returnVals.push(
        /*eslint-disable*/
        <option value={plans[i]._id} key={i + 1}>
          {`${plans[i].description} - ${plans[i].unique_id} ($${
            parseFloat(plans[i].amount) / 100
          }/${plans[i].interval_recur_at} ${plans[i].interval_period})`}
        </option>
        /*eslint-enable*/
      );
    }
    return returnVals;
  }

  getSelectedItems(productList) {
    let selectedChallenges = [];
    let selectedProducts = [];
    productList.forEach((element) => {
      if (element.challenge_id) {
        let challenge = {
          challenge: { name: element.name, _id: element.challenge_id },
          challengeType: element.type,
          challengeDate: element.start_date,
          challengeStartType: element.challenge_start_type || "",
        };
        selectedChallenges.push(challenge);
      } else {
        let product = {
          name: element.name,
          productDate: element.start_date,
          currentDay: element.current_day || false,
        };
        selectedProducts.push(product);
      }
    });
    return { selectedChallenges, selectedProducts };
  }

  onClickEditItem(type, index) {
    if (type === "amount") {
      let amount = this.state.amountList[index];
      let productList = this.state.productList[amount.id];

      let { selectedChallenges, selectedProducts } =
        this.getSelectedItems(productList);

      this.setState({
        selectedId: amount.id,
        orderAmount: amount.amount,
        selectedChallenges,
        selectedProducts,
        keyChallenges: Math.random(),
        keyProducts: Math.random(),
        addChallengeStatus:
          selectedChallenges.length > 0 || selectedProducts.length > 0
            ? "add"
            : "doNot",
        keyAddChallengeStatus: Math.random(),
      });
    }

    if (type === "subscription") {
      let plan = this.state.planList[index];
      let productList = this.state.productList[plan.id];

      let { selectedChallenges, selectedProducts } =
        this.getSelectedItems(productList);

      this.setState({
        selectedId: plan.id,
        orderPlan: plan.plan,
        selectedChallenges,
        selectedProducts,
        keyChallenges: Math.random(),
        keyProducts: Math.random(),
        addChallengeStatus:
          selectedChallenges.length > 0 || selectedProducts.length > 0
            ? "add"
            : "doNot",
        keyAddChallengeStatus: Math.random(),
      });
    }
  }

  onClickRemoveItem(type, index) {
    if (type === "amount") {
      let amountId = this.state.amountList[index].id;
      let productList = this.state.productList;
      delete productList[amountId];
      let amountList = this.state.amountList;
      amountList.splice(index, 1);
      this.setState({ amountList, productList });
    }

    if (type === "subscription") {
      let planId = this.state.planList[index].id;
      let planList = this.state.planList;
      planList.splice(index, 1);
      let productList = this.state.productList;
      delete productList[planId];
      this.setState({ planList, productList });
    }
  }

  onPressCancelOrderItem() {
    if (this.state.orderType === "single") {
      this.setState({
        selectedId: "",
        orderAmount: "",
        selectedChallenges: [],
        selectedProducts: [],
        keyChallenges: Math.random(),
        keyProducts: Math.random(),
      });
    }

    if (this.state.orderType === "subscription") {
      this.setState({
        selectedId: "",
        orderPlan: "",
        selectedChallenges: [],
        selectedProducts: [],
        keyChallenges: Math.random(),
        keyProducts: Math.random(),
      });
    }
  }

  renderOrderItems() {
    let orderType = this.state.orderType;
    let returnVals = [];

    if (orderType === "single") {
      let amountList = this.state.amountList;
      for (let i = 0; i < amountList.length; i++) {
        const element = amountList[i];
        returnVals.push(
          <div key={i} className="clearfix">
            <div className="pull-left">
              <p>
                <b>Id</b> : {element.id}
              </p>
              <p>
                <b>Amount</b> : ${element.amount}
              </p>
              <p>
                <b>Upsell Tag</b> : {`{{UpsellYes-${element.id}}}`}
              </p>
            </div>
            <OrderItemButtons
              onClickEdit={this.onClickEditItem.bind(this, "amount", i)}
              onClickRemove={this.onClickRemoveItem.bind(this, "amount", i)}
            />
          </div>
        );
      }
    }

    if (orderType === "subscription") {
      let planList = this.state.planList;
      for (let i = 0; i < planList.length; i++) {
        const element = planList[i];
        let plan = this.state.plans.filter((d) => {
          return d._id === element.plan;
        });
        returnVals.push(
          <div key={i} className="clearfix">
            <div className="pull-left">
              <p>
                <b>Id</b> : {element.id}
              </p>
              <p>
                <b>Plan</b> :{" "}
                {`${plan[0].description} ($ ${
                  parseFloat(plan[0].amount) / 100
                } / ${plan[0].interval_period})`}
              </p>
              <p>
                <b>Tags</b> : {`{{UpsellYes-${element.id}}}`}
              </p>
            </div>
            <OrderItemButtons
              onClickEdit={this.onClickEditItem.bind(this, "subscription", i)}
              onClickRemove={this.onClickRemoveItem.bind(
                this,
                "subscription",
                i
              )}
            />
          </div>
        );
      }
    }

    return returnVals;
  }

  onSelectAddChallengeStatus(item, id) {
    let value = item[Object.keys(item)[0]].value;
    this.setState({ [id]: value });
  }

  renderChallengeSelection() {
    return (
      <div>
        <div className="gap20" />

        <div className="radioBHolder">
          <div className="tabsHolder radioTabs">
            <RadioTag
              key={this.state.keyAddChallengeStatus}
              onCheckChange={this.onSelectAddChallengeStatus.bind(this)}
              labelList={[
                { name: "Add Challenges", value: "add" },
                {
                  name: "Do Not Add Any Challenges",
                  value: "doNot",
                },
              ]}
              id={"addChallengeStatus"}
              selectedList={[this.state.addChallengeStatus]}
              selectType={"value"}
            />
          </div>
        </div>
        <div className="gap20" />

        {this.state.addChallengeStatus === "add" && (
          <>
            <SelectChallenge
              selectedChallenges={this.state.selectedChallenges}
              challenges={this.state.challenges}
              key={this.state.keyChallenges}
              onChange={this.onChangeChallenges.bind(this)}
            />
            {isFS() && (
              <SelectProduct
                selectedProducts={this.state.selectedProducts}
                key={this.state.keyProducts}
                onChange={this.onChangeProducts.bind(this)}
              />
            )}
            <div className="gap10" />
          </>
        )}

        <div className="text-right">
          {/*eslint-disable*/}
          {this.state.selectedId.length > 0 && (
            <a
              className="btn btn-default margin-right-10"
              onClick={() => this.onPressCancelOrderItem()}>
              Cancel
            </a>
          )}
          <a
            className="btn btn-primary addQuestionButton"
            onClick={() => this.onPressAddOrderItem()}>
            {this.state.selectedId.length > 0
              ? "Update Order Item"
              : "Add Order Item"}
          </a>
          {/*eslint-enable*/}
        </div>
        <div className="gap20" />
      </div>
    );
  }

  renderSingleType() {
    return (
      <div className="card">
        <div className="container-fluid">
          <HelpTextDiv
            id="idUpsellItemOrderAmount"
            label="Learn more about the Order Amount"
            type="addUpsellItem"
            isInline="false"
            topMargin="10"
          />

          <div
            className={
              this.state.orderAmount !== "" ? "mdInput mdFocussed" : "mdInput"
            }>
            <label>Order Amount</label>
            <input
              type="text"
              name="orderAmount"
              value={this.state.orderAmount}
              onChange={(event) => this.handleInputChange(event)}
            />
          </div>
          {this.renderChallengeSelection()}
        </div>
      </div>
    );
  }

  renderSubscriptionType() {
    return (
      <div className="card">
        <div className="container-fluid">
          <HelpTextDiv
            id="idUpsellItemPlan"
            label="Learn more about the Plan"
            type="addUpsellItem"
            isInline="false"
            topMargin="10"
          />

          <div
            className={
              this.state.orderPlan !== "" ? "mdInput mdFocussed" : "mdInput"
            }>
            <label>Select Plan</label>
            <select
              value={this.state.orderPlan}
              className="billingDate"
              onChange={this.onChangePlan.bind(this)}>
              {this.renderPlans()}
            </select>
          </div>
          {this.renderChallengeSelection()}
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        <div className="container-fluid">
          <TitleBox
            title={
              this.state.upsellItemId === 0
                ? "Add New Upsell Item"
                : "Edit Upsell Item"
            }
            showBackBtn={this.state.upsellItemId !== 0}
          />
          <div className="gap20" />
          <div className="card">
            <div className="container-fluid">
              <div className="gap10" />

              <HelpTextDiv
                id="idUpsellItemName"
                label="Learn more about the Name"
                type="addUpsellItem"
                isInline="false"
              />

              <div
                className={
                  this.state.name !== "" ? "mdInput mdFocussed" : "mdInput"
                }>
                <label>Name</label>
                <input
                  type="text"
                  name="name"
                  value={this.state.name}
                  onChange={(event) => this.handleInputChange(event)}
                />
              </div>
              <div className="gap20" />

              <HelpTextDiv
                id="idUpsellItemDescription"
                label="Learn more about the Description"
                type="addUpsellItem"
                isInline="false"
              />

              <div
                className={
                  this.state.description !== ""
                    ? "mdInput mdFocussed"
                    : "mdInput"
                }>
                <label>Description</label>
                <input
                  type="text"
                  name="description"
                  value={this.state.description}
                  onChange={(event) => this.handleInputChange(event)}
                />
              </div>
              <div className="gap20" />

              {false && (
                <div
                  className={
                    this.state.urlDisplyName !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Url Display Name</label>
                  <input
                    type="text"
                    name="urlDisplyName"
                    value={this.state.urlDisplyName}
                    onChange={(event) => this.handleInputChange(event)}
                  />
                </div>
              )}

              <div className="gap20" />
              <div className="radioBHolder">
                <div className="havingHelpText">
                  <p>Order Type</p>
                </div>
                <HelpTextDiv
                  id="idUpsellItemOrderType"
                  label="Learn more about the Order Type"
                  type="addUpsellItem"
                  isInline="true"
                />
                <div className="tabsHolder radioTabs">
                  <RadioTag
                    onCheckChange={this.onSelectOrderType.bind(this)}
                    labelList={[
                      { name: "Single", value: "single" },
                      { name: "Subscription", value: "subscription" },
                      { name: "None", value: "none" },
                    ]}
                    id={"orderType"}
                    selectedList={[this.state.orderType]}
                    selectType={"value"}
                  />
                </div>
              </div>
              <div className="gap10" />
              <VelocityTransitionGroup
                enter={{ animation: "slideDown" }}
                leave={{ animation: "slideUp" }}>
                {this.state.orderType === "single" && this.renderSingleType()}
                <div className="gap10" />
              </VelocityTransitionGroup>
              <VelocityTransitionGroup
                enter={{ animation: "slideDown" }}
                leave={{ animation: "slideUp" }}>
                {this.state.orderType === "subscription" &&
                  this.renderSubscriptionType()}
                <div className="gap10" />
              </VelocityTransitionGroup>
              {(this.state.amountList.length > 0 ||
                this.state.planList.length > 0) &&
                this.state.plans.length > 0 &&
                this.state.orderType !== "none" && (
                  <div className="card">
                    <div className="container-fluid">
                      <div className="gap20" />
                      <h5 className="noMargin">Added Order Items</h5>
                      <div className="gap20" />
                      <div className="addedRule">{this.renderOrderItems()}</div>
                      <div className="gap20" />
                    </div>
                  </div>
                )}
              <div>
                <HelpTextDiv
                  id="idUpsellItemTag"
                  label="Learn more about the Tag"
                  type="addUpsellItem"
                  isInline="false"
                />

                <TagSelect
                  id={"add-upsell-item-add-tag"}
                  key={this.state.keyAddTag}
                  selectedValues={this.state.selectedTags}
                  searchKey={"name"}
                  selectKey={"name"}
                  btnText={"Select Tag"}
                  lblText={"Select Tag"}
                  data={this.state.systemTags}
                  onChange={this.onChangeAddTags}
                  isClickable={true}
                  reload={() => {
                    this.props.getSystemTags();
                  }}
                />
                <div className="gap10" />
              </div>
              <PageContent
                key={this.state.keyEditor}
                title={this.state.name}
                screen={"add-upsell-item"}
                html={this.state.htmlContent}
                content={this.state.content}
                onChangeData={this.onChangeContent.bind(this)}
                pageName={"addUpsellItem"}
                onClickAlert={this.onPressSaveUpsellItem.bind(this)}
                editorType={this.state.editorType}
                previewType={"mp-preview"}
                draftId={this.state.draftId}
                draftImage={this.state.draftImage}
              />
              {this.state.htmlContent.length > 0 && <div className="gap20" />}
            </div>
          </div>
        </div>
        <div className="text-right container-fluid">
          <button
            className="btn btn-primary"
            onClick={() => this.onPressSaveUpsellItem()}>
            Save
          </button>
        </div>
        <div className="gap20" />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  message: state.billingReducer.message,
  success: state.billingReducer.success,
  stripePlans: state.billingReducer.stripePlans,
  chlgs: state.challengeReducer.chlgs,
  systemTags: state.tagReducer.systemTags,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getChlgs,
      createUpsellItem,
      updateUpsellItem,
      getStripePlans,
      getSystemTags,
      resetStatus,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AddUpsellItem);
