import {
  RESET_STATUS,
  CREATE_LINK_SUCCESS,
  CREATE_LINK_FAILURE,
  GET_LINK_SUCCESS
} from "../types";

import config from "../../helpers/config";

import { callAddLink, callGetLinks } from "../../services/index";

/*
   This method is used to reset the status
   This will reset message and success states
   */
export const resetStatus = data => {
  return async dispatch => {
    dispatch({
      type: RESET_STATUS
    });
  };
};

export const createLink = data => {
  return async dispatch => {
    return callAddLink(data)
      .then(data => {
        dispatch({
          type: CREATE_LINK_SUCCESS,
          message: config.messages.createChlgQueSuccess,
          success: true
        });
      })
      .catch(err => {
        dispatch({
          type: CREATE_LINK_FAILURE,
          message: config.messages.createChlgQueError,
          success: false
        });
      });
  };
};

export const getLinks = () => {
  return async dispatch => {
    return callGetLinks().then(data => {
      dispatch({
        type: GET_LINK_SUCCESS,
        data: data
      });
    });
  };
};
