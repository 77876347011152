import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DatePicker from "react-date-picker";
import moment from "moment-timezone";
import * as $ from "jquery";

import RadioTag from "../../../components/form/radioTag";
import TitleBox from "../../../components/general/titleBox";
import TagSelect from "../../../components/form/tagSelect";
import GenericTagSelect from "../../../components/form/genericTagSelect";
import HelpTextDiv from "../../../components/form/helpTextDiv";
import Checkbox from "../../../components/form/checkBox";

import {
  createSystemTag,
  updateSystemTag,
  resetStatus,
  getSystemTags,
} from "../../../modules/actions/index";
import {
  showNotification,
  isTemplateSite,
  slugify,
  getUniqueId,
} from "../../../helpers";
import {
  callGetDemioEvents,
  callGetGenericTags,
  callCreateGenericTag,
  callGetUsersByQuery,
  callGetChlgsByQuery,
  callGetZoomUsers,
  callGetZoomMeetings,
  callManageRules,
  callGetSystemTags,
  callGetEmails,
  callGetStripePlan,
  callCreateSystemTag,
  callUpdateSystemTag,
  callGetTagFilterUsers,
} from "../../../services";
import SelectChallenge from "../../../components/form/selectChallenge";
import Onboarding from "../onboarding/onboarding";
import { callGetSettings } from "../../../services/settingService";
import CopyToClipboard from "react-copy-to-clipboard";
import Scrollbars from "react-custom-scrollbars";

class AddSystemTag extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    let tagTypeClass = {
      automated: "active",
      system: "",
    };

    /*eslint-disable*/
    let systemTag = {
      _id: 0,
      name: "",
      description: "",
      type: "automated",
      rules: [
        {
          limit_tag_per_days: "1",
        },
      ],
      additional_rules: [],
      events: [],
      notifications: [],
      zoomMeeting: { userId: "", meetingId: "" },
    };
    /*eslint-enable*/
    let rulesRetroActive = false;

    let editMode = false;
    let selectedChallenges = [];
    let systemEditMode = true;
    let attributes = [];
    let defaultAttributes = false;

    if (
      this.props.history !== undefined &&
      this.props.history.location.state !== undefined
    ) {
      systemEditMode = this.props.history.location.state.systemEdit;
      systemTag = this.props.history.location.state.systemTag;
    }

    if (this.props.tagInfo) {
      systemTag = this.props.tagInfo;
    }

    if (systemTag._id !== 0) {
      if (!systemTag.additional_rules) {
        systemTag["additional_rules"] = [];
      }
      if (!systemTag.events) {
        systemTag["events"] = [];
      }
      if (!systemTag.notifications) {
        systemTag["notifications"] = [];
      }
      /*eslint-disable*/
      let zoomMeeting = systemTag.zoom_meeting || {
        user_id: "",
        meeting_id: "",
      };
      /*eslint-enable*/

      systemTag.zoomMeeting = {
        userId: zoomMeeting.user_id,
        meetingId: zoomMeeting.meeting_id,
      };
      let productList = systemTag.product_list || [];
      productList.forEach((element) => {
        let challenge = {
          challenge: { name: element.name, _id: element.challenge_id },
          challengeType: element.type,
          challengeDate: element.start_date,
          challengeStartType: element.challenge_start_type || "",
        };
        selectedChallenges.push(challenge);
      });
      rulesRetroActive = systemTag["rules_retroactive"] || false;
      editMode = true;

      attributes = systemTag.attributes || [];
      defaultAttributes = systemTag.default_attributes;
    }

    let selectedRules = [];
    systemTag.rules.forEach((obj) => {
      Object.keys(obj).forEach((o) => {
        selectedRules.push(o);
      });
    });

    if (isTemplateSite()) {
      systemTag.name = systemTag.name.replace("system-", "");
    }

    this.state = {
      editMode: editMode,
      systemTag: systemTag,
      tagTypeClass: tagTypeClass,
      selectedRules: selectedRules,
      selectedEmailBody: ["name"],
      ruleType: "",
      eventType: "",
      onSpecificDay: "",
      tagPriority: 10,
      onWeekday: "monday",
      actionType: "",
      tagWhen: "",
      cancelChallengeId: "",
      notiType: "",
      emailSubject: "",
      tags: [],
      tagValue: "",
      tagWhenValue: "",
      tagSelected: "",
      selectedWebinar: "",
      demioEvents: [],
      keyGenericTag: Math.random(),
      genericTags: [],
      selectedGenericTags: systemTag.ftags || [],
      fTags: { new: [], existing: systemTag.ftags || [] },
      onSpecificDate: new Date(),
      staffUsers: [],
      keySelectUser: Math.random(),
      selectedUsers: [],
      selectedEmails: "",
      challenges: [],
      selectedChallenges: selectedChallenges,
      keyChallenges: Math.random(),
      zoomUsers: [],
      zoomMeetings: [],
      selectedZoomMeeting: systemTag.zoomMeeting.meetingId,
      selectedZoomUser: systemTag.zoomMeeting.userId,
      isZoom: false,
      rulesRetroActive: rulesRetroActive,
      keyRulesRetroActive: Math.random(),
      systemEditMode: systemEditMode,
      emails: [],
      keyNotiTag: Math.random(),
      selectedRuleIndex: -1,
      onDay: "1",
      onDayWeek: "monday",
      stripePlans: [],
      selectedPlan: "",
      attName: "",
      attValue: "",
      attId: "",
      attributes: attributes,
      defaultAttributes: defaultAttributes,
      keyDefaultAttributes: Math.random(),
      showAdvancedOptionsDetails:
        editMode && !this.props.fromPopup ? true : false,
      attrRefresh: Math.random(),
      selectedNotification: -1,
      tagUsers: [],
      tagUserCount: 0,
      userInfos: [],
    };
  }

  componentDidMount() {
    // this.props.getSystemTags();
    this.getSystemTags();
    this.getDemioEvents();
    this.getGenericTags();
    this.getStaffUsers();
    this.getChallenges();
    this.getEmails();
    this.getStripePlans();

    callGetSettings("intezoom").then((d) => {
      let settings = d.data;
      let integrations = settings.integration || {};
      if (
        integrations &&
        integrations.zoom_api_key &&
        integrations.zoom_api_secret
      ) {
        this.setState({ isZoom: true });
        this.getZoomUsers();
      }
    });
  }

  async getEmails() {
    if (!this.state.editMode) {
      return;
    }

    let tagId = this.state.systemTag._id;

    /*eslint-disable*/

    let query = {
      query: {
        status: { $ne: "archive" },
        $or: [
          { tag_list: { $in: [tagId] } },
          { tag_list_excluded: { $in: [tagId] } },
        ],
      },
    };
    /*eslint-enable*/
    let data = await callGetEmails(query);
    this.setState({ emails: data.data });
  }

  async getStripePlans() {
    let response = await callGetStripePlan();
    this.setState({ stripePlans: response.data });
  }

  async getZoomUsers() {
    let users = await callGetZoomUsers();
    if (users.status) {
      this.setState({ zoomUsers: users.data || [] }, () => {
        if (this.state.selectedZoomUser) {
          this.getZoomMeetings(this.state.selectedZoomUser);
        }
      });
    }
  }

  async getZoomMeetings(userId) {
    if (userId) {
      let response = await callGetZoomMeetings(userId);
      if (response.status) {
        let selectedZoomMeeting = "";
        let meetings = response.data;
        if (meetings.length > 0) {
          selectedZoomMeeting = meetings[0].id;
          if (this.state.selectedZoomMeeting) {
            selectedZoomMeeting = this.state.selectedZoomMeeting;
          }
        }
        this.setState({
          zoomMeetings: meetings,
          selectedZoomMeeting: selectedZoomMeeting,
        });
      }
    } else {
      this.setState({ zoomMeetings: [] });
    }
  }

  async getChallenges() {
    let query = {
      query: {
        archive: { $ne: true },
        $select: ["_id", "name"],
      },
    };
    let data = await callGetChlgsByQuery(query);
    this.setState({ challenges: data.data, keyChallenges: Math.random() });
  }

  componentWillReceiveProps(newProps) {
    if (newProps.message) {
      if (newProps.success) {
        showNotification("success", newProps.message, 5000);
      } else {
        showNotification("error", newProps.message, 5000);
      }
      this.props.resetStatus();
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  async getSystemTags() {
    let tags = await callGetSystemTags();
    this.setState({ tags: tags.data, keyNotiTag: Math.random() });
  }

  getGenericTags() {
    callGetGenericTags("systemtag").then((d) => {
      this.setState({ genericTags: d.data, keyGenericTag: Math.random() });
    });
  }

  getStaffUsers() {
    /*eslint-disable*/
    let query = {
      query: {
        member_type: "staff",
        $select: ["_id", "name", "email_address"],
        populate: "no",
      },
    };
    /*eslint-enable*/

    callGetUsersByQuery(query).then((d) => {
      this.setState({
        staffUsers: d.data,
        keySelectUser: Math.random(),
      });
    });
  }

  getDemioEvents() {
    callGetDemioEvents().then((d) => {
      this.setState({ demioEvents: d });
    });
  }

  resetProps() {
    /*eslint-disable*/
    let systemTag = {
      _id: 0,
      name: "",
      description: "",
      type: "automated",
      rules: [
        {
          limit_tag_per_days: "1",
        },
      ],
      additional_rules: [],
      events: [],
      notifications: [],
    };
    /*eslint-enable*/

    this.setState({
      systemTag: systemTag,
      ruleType: "",
      eventType: "",
      onSpecificDay: "",
      actionType: "",
      tagValue: "",
      tagSelected: "",
      keyGenericTag: Math.random(),
      selectedGenericTags: [],
      fTags: { new: [], existing: [] },
      onSpecificDate: new Date(),
    });
  }

  handleTextChange(event) {
    const target = event.target;
    var value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    if (
      name === "ruleType" ||
      name === "eventType" ||
      name === "selectedWebinar" ||
      name === "onSpecificDay" ||
      name === "onWeekday" ||
      name === "actionType" ||
      name === "tagWhen" ||
      name === "tagPriority" ||
      name === "notiType" ||
      name === "emailSubject" ||
      name === "selectedZoomMeeting" ||
      name === "onDay" ||
      name === "onDayWeek" ||
      name === "cancelChallengeId"
    ) {
      this.setState({
        [name]: value,
      });
      return;
    } else if (name === "selectedZoomUser") {
      this.setState(
        {
          [name]: value,
        },
        () => {
          this.getZoomMeetings(value);
          return;
        }
      );
    }

    let systemTag = this.state.systemTag;
    if (name === "name") {
      value = slugify(value, false);
    }
    systemTag[name] = value;

    this.setState({
      systemTag: systemTag,
    });
  }

  handleRulesTextChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    let systemTag = this.state.systemTag;
    systemTag.rules[name] = value;

    systemTag.rules.forEach((obj) => {
      if (obj.hasOwnProperty(name)) {
        obj[name] = value;
      }
    });

    this.setState({
      systemTag: systemTag,
    });
  }

  onChangeTagValue(value) {
    this.setState({
      tagValue: value,
    });
  }

  onSelectTagValue(value) {
    this.setState({
      tagSelected: value,
    });
  }

  onChangeRuleDate(value) {
    this.setState({
      onSpecificDate: value,
    });
  }

  onChangeTags = (tags) => {
    if (tags.length > 0) {
      let fTags = { new: [], existing: [] };
      tags.forEach((element) => {
        if (element.name) {
          fTags.existing.push(element);
        } else {
          fTags.new.push(element);
        }
      });
      this.setState({ fTags: fTags });
    }
  };

  onChangeUsers = (users) => {
    this.setState({ selectedUsers: users });
  };

  onClickAddRule() {
    let systemTag = this.state.systemTag;
    let index = this.state.selectedRuleIndex;
    let rules = systemTag.additional_rules;
    let tagId = this.state.tags.filter((d) => {
      return d.name === this.state.tagValue;
    });

    let tagWhenId = "";
    if (this.state.tagWhenValue) {
      let tagWhenIds = this.state.tags.filter((d) => {
        return d.name === this.state.tagWhenValue;
      });
      if (tagWhenIds.length > 0) {
        tagWhenId = tagWhenIds[0]._id;
      }
    }
    if (this.state.ruleType.trim().length === 0) {
      showNotification("error", "Rule type is required", 4000);
      return;
    }
    if (this.state.ruleType === "on_specific_day") {
      if (this.state.onSpecificDay.trim().length === 0) {
        showNotification("error", "Enter Day is required", 4000);
        return;
      }
      if (this.state.tagValue.trim().length === 0) {
        showNotification("error", "Tag is required", 4000);
        return;
      }

      let object = {
        type: "on_specific_day",
        day: this.state.onSpecificDay,
        action: this.state.actionType,
        /*eslint-disable*/
        tag_id: tagId[0]._id,
        tag_name: this.state.tagValue,
        tag_when: this.state.tagWhen,
        tag_when_id: tagWhenId,
        tag_when_name: this.state.tagWhenValue,
        priority: this.state.tagPriority,
        attributes: this.state.attributes,
        cancel_challenge_id: this.state.cancelChallengeId,
        /*eslint-enable*/
      };
      if (index > -1) {
        rules[index] = object;
      } else {
        rules.push(object);
      }
    }

    if (this.state.ruleType === "on_specific_date") {
      if (this.state.tagValue.trim().length === 0) {
        showNotification("error", "Tag is required", 4000);
        return;
      }
      let object = {
        type: "on_specific_date",
        date: this.state.onSpecificDate,
        action: this.state.actionType,
        /*eslint-disable*/
        tag_id: tagId[0]._id,
        tag_name: this.state.tagValue,
        tag_when: this.state.tagWhen,
        tag_when_id: tagWhenId,
        tag_when_name: this.state.tagWhenValue,
        priority: this.state.tagPriority,
        attributes: this.state.attributes,
        cancel_challenge_id: this.state.cancelChallengeId,
        /*eslint-enable*/
      };

      if (index > -1) {
        rules[index] = object;
      } else {
        rules.push(object);
      }
    }

    if (this.state.ruleType === "on_weekday") {
      if (this.state.tagValue.trim().length === 0) {
        showNotification("error", "Tag is required", 4000);
        return;
      }
      let object = {
        type: "on_weekday",
        /*eslint-disable*/
        week_day: this.state.onWeekday,
        action: this.state.actionType,
        tag_id: tagId[0]._id,
        tag_name: this.state.tagValue,
        tag_when: this.state.tagWhen,
        tag_when_id: tagWhenId,
        tag_when_name: this.state.tagWhenValue,
        priority: this.state.tagPriority,
        attributes: this.state.attributes,
        cancel_challenge_id: this.state.cancelChallengeId,
        /*eslint-enable*/
      };

      if (index > -1) {
        rules[index] = object;
      } else {
        rules.push(object);
      }
    }

    if (this.state.ruleType === "on_day_month") {
      if (this.state.tagValue.trim().length === 0) {
        showNotification("error", "Tag is required", 4000);
        return;
      }
      let object = {
        type: "on_day_month",
        /*eslint-disable*/
        week_day: this.state.onDayWeek,
        day_month: this.state.onDay,
        action: this.state.actionType,
        tag_id: tagId[0]._id,
        tag_name: this.state.tagValue,
        tag_when: this.state.tagWhen,
        tag_when_id: tagWhenId,
        tag_when_name: this.state.tagWhenValue,
        priority: this.state.tagPriority,
        attributes: this.state.attributes,
        cancel_challenge_id: this.state.cancelChallengeId,
        /*eslint-enable*/
      };

      if (index > -1) {
        rules[index] = object;
      } else {
        rules.push(object);
      }
    }

    if (this.state.ruleType === "instant") {
      if (this.state.actionType !== "cancel-challenge") {
        if (this.state.tagValue.trim().length === 0) {
          showNotification("error", "Tag is required", 4000);
          return;
        }
      }
      let tagInfo = "";
      if (tagId[0]) {
        tagInfo = tagId[0]._id;
      }
      let object = {
        type: "instant",
        /*eslint-disable*/
        action: this.state.actionType,
        tag_id: tagInfo,
        tag_name: this.state.tagValue,
        tag_when: this.state.tagWhen,
        tag_when_id: tagWhenId,
        tag_when_name: this.state.tagWhenValue,
        priority: this.state.tagPriority,
        attributes: this.state.attributes,
        cancel_challenge_id: this.state.cancelChallengeId,
        /*eslint-enable*/
      };

      if (index > -1) {
        rules[index] = object;
      } else {
        rules.push(object);
      }
    }

    systemTag["additional_rules"] = rules;

    this.setState({
      systemTag: systemTag,
      ruleType: "",
      actionType: "",
      onSpecificDay: "",
      tagValue: "",
      tagWhenValue: "",
      tagWhen: "",
      onDay: "1",
      onDayWeek: "monday",
      tagPriority: 10,
      tagSelected: {},
      selectedRuleIndex: -1,
      cancelChallengeId: "",
    });
  }

  onClickAddEvent() {
    let systemTag = this.state.systemTag;
    let events = systemTag.events;

    if (this.state.eventType === "add_to_demio") {
      if (this.state.selectedWebinar.trim().length === 0) {
        showNotification("error", "Webinar is required", 4000);
        return;
      }

      let webinar = this.state.demioEvents.filter((d) => {
        return d.id.toString() === this.state.selectedWebinar.toString();
      });

      events.push({
        type: "add_to_demio",
        id: this.state.selectedWebinar,
        name: webinar[0].name,
      });
    }

    systemTag["events"] = events;

    this.setState({
      systemTag: systemTag,
      eventType: "",
    });
  }

  onClickAddNotification() {
    let systemTag = this.state.systemTag;
    let notifications = systemTag.notifications;

    if (this.state.notiType === "send_mail") {
      if (this.state.emailSubject.trim().length === 0) {
        showNotification("error", "Subject is required", 4000);
        return;
      }

      if (this.state.selectedEmailBody.length === 0) {
        showNotification("error", "Select atleast one email body field", 4000);
        return;
      }

      if (
        this.state.selectedUsers.length === 0 &&
        this.state.selectedEmails.length === 0
      ) {
        showNotification("error", "Add atleast one email address / user", 4000);
        return;
      }

      /*eslint-disable*/
      let noti = {
        type: "send_mail",
        email_subject: this.state.emailSubject,
        email_body: this.state.selectedEmailBody,
        users: this.state.selectedUsers.map((i) => i.email_address),
        emails: this.state.selectedEmails,
      };
      /*eslint-enable*/

      if (this.state.selectedNotification === -1) {
        notifications.push(noti);
      } else {
        notifications[this.state.selectedNotification] = noti;
      }
    }

    systemTag["notifications"] = notifications;

    this.setState({
      systemTag: systemTag,
      notiType: "",
      emailSubject: "",
      selectedEmailBody: [],
      selectedUsers: [],
      keySelectUser: Math.random(),
    });
  }

  onSelectType(item, id) {
    let systemTag = this.state.systemTag;
    systemTag[id] = item[Object.keys(item)[0]].value;
    this.setState({ systemTag: systemTag });
  }

  onSelectRules(item, id) {
    let systemTag = this.state.systemTag;
    let newRules = [];
    let selectedRules = [];

    Object.keys(item).forEach((obj) => {
      selectedRules.push(item[obj].value);
      if (systemTag.rules.hasOwnProperty(obj)) {
        let previousVal =
          systemTag.rules[obj][item[obj].value] === undefined
            ? ""
            : systemTag.rules[obj][item[obj].value];
        newRules.push({ [item[obj].value]: previousVal });
      } else {
        newRules.push({ [item[obj].value]: "" });
      }
    });

    systemTag[id] = newRules;
    this.setState({
      systemTag: systemTag,
      selectedRules: selectedRules,
    });
  }

  onSelectEmailBody(item, id) {
    let selectedEmailBody = [];

    Object.keys(item).forEach((obj) => {
      selectedEmailBody.push(item[obj].value);
    });

    this.setState({
      selectedEmailBody: selectedEmailBody,
    });
  }

  onPressAddTag() {
    if (isTemplateSite()) {
      let newSystemTag = this.state.systemTag;
      newSystemTag.name = slugify(newSystemTag.name.toLowerCase());
      newSystemTag.name = "system-" + newSystemTag.name;
      this.setState({
        systemTag: newSystemTag,
      });
    }
    if (this.state.systemTag.name.trim().length === 0) {
      showNotification("error", "Tag Name is required", 4000);
      return;
    }
    if (this.state.systemTag._id === 0) {
      let existingTag = this.state.tags.filter((d) => {
        return d.name.trim() === this.state.systemTag.name.trim();
      });
      if (existingTag.length > 0) {
        showNotification("error", "Tag already exists", 4000);
        return;
      }
    }

    this.state.systemTag.rules.forEach((obj) => {
      if (obj.hasOwnProperty("limit_tag_per_days")) {
        if (
          isNaN(obj["limit_tag_per_days"]) ||
          obj["limit_tag_per_days"].length === 0
        ) {
          showNotification("error", "Must Add Days Tag Limit Days", 4000);
          return;
        }
      }
      if (obj.hasOwnProperty("tag_expire_days")) {
        if (
          isNaN(obj["tag_expire_days"]) ||
          obj["tag_expire_days"].length === 0
        ) {
          showNotification("error", "Must Add Expiration Days", 4000);
          return;
        }
      }
    });

    let tags = this.state.fTags;
    if (tags.new.length > 0) {
      let data = [];
      tags.new.forEach((element) => {
        data.push({ name: element, type: "systemtag" });
      });
      callCreateGenericTag(data).then((resp) => {
        let eTags = this.state.tags;
        this.setState({
          tags: eTags.concat(resp),
        });
        let newTagIds = resp.map((t) => t._id);
        let tagIds = tags.existing.map((t) => t._id);
        let finalTagIds = newTagIds.concat(tagIds);
        this.callAddSystemTagAPI(finalTagIds);
      });
    } else {
      let tagIds = tags.existing.map((t) => t._id);
      this.callAddSystemTagAPI(tagIds);
    }
  }

  async callAddSystemTagAPI(genericTags) {
    let productList = [];
    this.state.selectedChallenges.forEach((element) => {
      /*eslint-disable*/
      productList.push({
        challenge_id: element.challenge._id,
        product_id: "",
        name: element.challenge.name,
        start_date: element.challengeDate,
        type: element.challengeType,
        challenge_start_type: element.challengeStartType,
        if_tag_available: element.ifTagAvailable,
      });
      /*eslint-enable*/
    });

    /*eslint-disable*/
    let apiData = {
      name: slugify(this.state.systemTag.name),
      description: this.state.systemTag.description,
      // type: this.state.systemTag.type,
      rules: this.state.systemTag.rules,
      additional_rules: this.state.systemTag.additional_rules,
      events: this.state.systemTag.events,
      notifications: this.state.systemTag.notifications,
      product_list: productList,
      generic_tag_list: genericTags,
      zoom_meeting: {
        user_id: this.state.selectedZoomUser,
        meeting_id: this.state.selectedZoomMeeting,
      },
      rules_retroactive: this.state.rulesRetroActive,
      stripe_plan_id: this.state.selectedPlan,
      attributes: this.state.attributes,
      default_attributes: this.state.defaultAttributes,
    };
    /*eslint-enable*/

    if (this.state.editMode) {
      if (!this.state.systemEditMode) {
        apiData = { notifications: apiData.notifications, type: "system" };
      }
      if (isTemplateSite()) {
        apiData["type"] = "system";
      }
      await callUpdateSystemTag(this.state.systemTag._id, apiData);
      if (this.state.rulesRetroActive) {
        /*eslint-disable*/
        let tagData = {
          action: "update-additional-rule",
          data: {
            system_tag_id: this.state.systemTag._id,
          },
        };
        /*eslint-enable*/

        await callManageRules(tagData);
      }
      showNotification("success", "Tag updated successfully");
    } else {
      if (isTemplateSite()) {
        apiData["type"] = "system";
      }
      await callCreateSystemTag(apiData);
      showNotification("success", "Tag added successfully");
      this.resetProps();
    }
    if (this.props.onCreate) {
      this.props.onCreate();
    }
    this.props.getSystemTags();
  }

  onClickDeleteRule(index) {
    let systemTag = this.state.systemTag;
    let rules = systemTag.additional_rules;
    rules.splice(index - 1, 1);
    systemTag["additional_rules"] = rules;

    this.setState({ systemTag: systemTag });
  }

  onClickEditRule(element, index) {
    this.setState({
      ruleType: element.type,
      onSpecificDay: element.day,
      onWeekday: element.week_day,
      onSpecificDate: element.date,
      tagPriority: element.priority,
      onDay: element.day_month,
      onDayWeek: element.week_day,
      actionType: element.action,
      selectedRuleIndex: index - 1,
      tagValue: element.tag_name,
      tagWhenValue: element.tag_when_name,
      cancelChallengeId: element.cancel_challenge_id,
    });
  }

  getTagName(id) {
    let tags = this.state.tags;
    let tagName = "Not Found";
    let fTags = tags.filter((d) => d._id === id);
    if (fTags.length > 0) {
      tagName = fTags[0].name;
    }
    return tagName;
  }

  getChallengeName(id) {
    let challenges = this.state.challenges;
    let challengeName = "Not Found";
    let fChallenges = challenges.filter((d) => d._id === id);
    if (fChallenges.length > 0) {
      challengeName = fChallenges[0].name;
    }
    return challengeName;
  }

  renderRules() {
    let systemTag = this.state.systemTag;
    let rules = systemTag.additional_rules;
    let returnVals = [];
    let i = 0;
    let when = {
      always: "Always",
      tag: "User has the tag",
      "no-tag": "User does not have the tag",
    };
    let actionTypeMap = {
      "add-tag": "Add A Tag",
      "cancel-challenge": "Cancel A Challenge",
      "inactivate-tag": "Inactivate A Tag",
    };
    if (rules.length > 0) {
      rules.forEach((element) => {
        i++;
        let actionType = actionTypeMap[element.action];

        let tagWhen = element.tag_when || "always";
        let priority = element.priority || 10;
        if (element.type === "on_specific_day") {
          returnVals.push(
            <div key={i}>
              On Day : <b>{element.day}</b>, {actionType} :{" "}
              <b>{this.getTagName(element.tag_id)}</b>, When:{" "}
              <b>
                {when[tagWhen]}{" "}
                {element.tag_when_id &&
                  `(${this.getTagName(element.tag_when_id)})`}
              </b>
              Priority : <b>{priority}</b>
              <button
                onClick={this.onClickDeleteRule.bind(this, i)}
                className="btn btn-danger btn-sm"
                style={{ float: "right" }}>
                Remove
              </button>
              <button
                onClick={this.onClickEditRule.bind(this, element, i)}
                className="btn btn-default btn-sm margin-right-10"
                style={{ float: "right" }}>
                Edit
              </button>
            </div>
          );
        } else if (element.type === "on_specific_date") {
          returnVals.push(
            <div key={i}>
              On Date : <b>{moment(element.date).format("Do MMM YYYY")}</b>,{" "}
              {actionType} : <b>{this.getTagName(element.tag_id)}</b>, When:{" "}
              <b>
                {when[tagWhen]}{" "}
                {element.tag_when_id &&
                  `(${this.getTagName(element.tag_when_id)})`}
              </b>{" "}
              Priority : <b>{priority}</b>
              <button
                onClick={this.onClickDeleteRule.bind(this, i)}
                className="btn btn-danger btn-sm"
                style={{ float: "right" }}>
                Remove
              </button>
              <button
                onClick={this.onClickEditRule.bind(this, element, i)}
                className="btn btn-default btn-sm margin-right-10"
                style={{ float: "right" }}>
                Edit
              </button>
            </div>
          );
        } else if (element.type === "on_weekday") {
          returnVals.push(
            <div key={i}>
              On Weekday : <b>{element.week_day}</b>, {actionType} :{" "}
              <b>{this.getTagName(element.tag_id)}</b>, When:{" "}
              <b>
                {when[tagWhen]}{" "}
                {element.tag_when_id &&
                  `(${this.getTagName(element.tag_when_id)})`}
              </b>{" "}
              Priority : <b>{priority}</b>
              <button
                onClick={this.onClickDeleteRule.bind(this, i)}
                className="btn btn-danger btn-sm"
                style={{ float: "right" }}>
                Remove
              </button>
              <button
                onClick={this.onClickEditRule.bind(this, element, i)}
                className="btn btn-default btn-sm margin-right-10"
                style={{ float: "right" }}>
                Edit
              </button>
            </div>
          );
        } else if (element.type === "on_day_month") {
          let dayMap = {
            1: "1st",
            2: "2nd",
            3: "3rd",
            4: "4th",
            5: "5th",
          };
          returnVals.push(
            <div key={i}>
              On <b>{dayMap[element.day_month]}</b> <b>{element.week_day}</b> Of
              Month , {actionType} : <b>{this.getTagName(element.tag_id)}</b>,
              When:{" "}
              <b>
                {when[tagWhen]}{" "}
                {element.tag_when_id &&
                  `(${this.getTagName(element.tag_when_id)})`}
              </b>{" "}
              Priority : <b>{priority}</b>
              <button
                onClick={this.onClickDeleteRule.bind(this, i)}
                className="btn btn-danger btn-sm"
                style={{ float: "right" }}>
                Remove
              </button>
              <button
                onClick={this.onClickEditRule.bind(this, element, i)}
                className="btn btn-default btn-sm margin-right-10"
                style={{ float: "right" }}>
                Edit
              </button>
            </div>
          );
        } else if (element.type === "instant") {
          returnVals.push(
            <div key={i}>
              {element.action === "cancel-challenge" ? (
                <>
                  Instantly : {actionType} :{" "}
                  <b>{this.getChallengeName(element.cancel_challenge_id)}</b>
                </>
              ) : (
                <>
                  Instantly : {actionType} :{" "}
                  <b>{this.getTagName(element.tag_id)}</b>, When:{" "}
                  <b>
                    {when[tagWhen]}{" "}
                    {element.tag_when_id &&
                      `(${this.getTagName(element.tag_when_id)})`}
                  </b>{" "}
                  Priority : <b>{priority}</b>
                </>
              )}
              <button
                onClick={this.onClickDeleteRule.bind(this, i)}
                className="btn btn-danger btn-sm"
                style={{ float: "right" }}>
                Remove
              </button>
              <button
                onClick={this.onClickEditRule.bind(this, element, i)}
                className="btn btn-default btn-sm margin-right-10"
                style={{ float: "right" }}>
                Edit
              </button>
            </div>
          );
        }
      });
    }
    return returnVals;
  }

  onClickDeleteEvent(index) {
    let systemTag = this.state.systemTag;
    let events = systemTag.events;
    events.splice(index - 1, 1);
    systemTag["events"] = events;

    this.setState({ systemTag: systemTag });
  }

  onClickDeleteNotification(index) {
    let systemTag = this.state.systemTag;
    let notifications = systemTag.notifications;
    notifications.splice(index - 1, 1);
    systemTag["notifications"] = notifications;

    this.setState({ systemTag: systemTag });
  }

  onClickEditNotification(index) {
    let systemTag = this.state.systemTag;
    let notifications = systemTag.notifications;

    let notification = notifications[index - 1];

    let selectedUsers = [];
    notification.users.forEach((element) => {
      let user = this.state.staffUsers.find((d) => d.email_address === element);
      if (user) {
        selectedUsers.push(user);
      }
    });
    console.log(notification);

    this.setState({
      selectedNotification: index - 1,
      notiType: notification.type,
      emailSubject: notification.email_subject,
      selectedEmailBody: notification.email_body,
      selectedUsers: selectedUsers,
      selectedEmails: notification.emails || "",
    });
  }

  renderEvents() {
    let systemTag = this.state.systemTag;
    let events = systemTag.events;
    let returnVals = [];
    let i = 0;
    if (events.length > 0) {
      events.forEach((element) => {
        i++;
        if (element.type === "add_to_demio") {
          returnVals.push(
            <div key={i}>
              Add To Demio: <b>{element.name}</b> ({element.id})
              <button
                onClick={this.onClickDeleteEvent.bind(this, i)}
                className="btn btn-danger btn-sm"
                style={{ float: "right" }}>
                Remove
              </button>
            </div>
          );
        }
      });
    }
    return returnVals;
  }

  renderNotifications() {
    let systemTag = this.state.systemTag;
    let notifications = systemTag.notifications;
    let returnVals = [];
    let i = 0;
    if (notifications.length > 0) {
      notifications.forEach((element) => {
        i++;
        if (element.type === "send_mail") {
          returnVals.push(
            <div key={i}>
              ({i}) Send Mail: <b>{element.email_subject}</b>(
              {element.users.map((i) => i).join(", ")}){" "}
              {element.emails && <br />} {element.emails}
              <button
                onClick={this.onClickEditNotification.bind(this, i)}
                className="btn btn-default btn-sm"
                style={{ float: "right" }}>
                Edit
              </button>
              <button
                onClick={this.onClickDeleteNotification.bind(this, i)}
                className="btn btn-danger btn-sm margin-right-10"
                style={{ float: "right" }}>
                Remove
              </button>
            </div>
          );
        }
      });
    }
    return returnVals;
  }

  toggleRules = (check) => {
    this.setState({
      rulesRetroActive: check,
    });
  };

  toggleDefaultAttributes = (check) => {
    this.setState({
      defaultAttributes: check,
    });
  };

  onChangeAddTags = (tags) => {
    let tagValue = "";
    if (tags.length > 0) {
      tagValue = tags[0].name;
    }
    this.setState({ tagValue: tagValue });
  };

  onChangeAddWhenTags = (tags) => {
    let tagValue = "";
    if (tags.length > 0) {
      tagValue = tags[0].name;
    }
    this.setState({ tagWhenValue: tagValue });
  };

  renderTagRulesSection() {
    return (
      <div className="container-fluid">
        <div className="card">
          <div className="container-fluid">
            <div className="gap20" />
            <h5 className="noMargin">Additional Tag Rules</h5>
            <div className="gap20" />
            <div className="row">
              <div className="col-sm-4">
                <HelpTextDiv
                  id="idSystemTagRuleType"
                  label="Learn more about the Rule Type"
                  type="addSystemTag"
                  isInline="false"
                />
                <div
                  className={
                    this.state.ruleType !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Select Rule Type</label>
                  <select
                    className="selectRuleTypeInput"
                    name="ruleType"
                    value={this.state.ruleType}
                    onChange={(event) => this.handleTextChange(event)}>
                    <option value="" />
                    <option value="instant">Instantly</option>
                    <option value="on_specific_day">On Day</option>
                    <option value="on_specific_date">On Date</option>
                    <option value="on_weekday">On Weekday</option>
                    <option value="on_day_month">On Weekday Of Month</option>
                  </select>
                </div>
                <div className="gap20" />
              </div>
              {this.state.ruleType === "on_specific_day" && (
                <div className="col-sm-4">
                  <HelpTextDiv
                    id="idSystemTagEnterDay"
                    label="Learn more about the Enter Day"
                    type="addSystemTag"
                    isInline="false"
                  />

                  <div
                    className={
                      this.state.onSpecificDay !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>Enter Day</label>
                    <input
                      type="text"
                      className="onDayInput"
                      name="onSpecificDay"
                      value={this.state.onSpecificDay}
                      onChange={(event) => this.handleTextChange(event)}
                    />
                  </div>
                  <div className="gap20" />
                </div>
              )}
              {this.state.ruleType === "on_weekday" && (
                <div className="col-sm-4">
                  <div
                    className={
                      this.state.onWeekday !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <select
                      name="onWeekday"
                      value={this.state.onWeekday}
                      onChange={(event) => this.handleTextChange(event)}>
                      <option value="monday">Monday</option>
                      <option value="tuesday">Tuesday</option>
                      <option value="wednesday">Wednesday</option>
                      <option value="thursday">Thursday</option>
                      <option value="friday">Friday</option>
                      <option value="saturday">Saturday</option>
                      <option value="sunday">Sunday</option>
                    </select>
                  </div>
                </div>
              )}
              {this.state.ruleType === "on_day_month" && (
                <>
                  <div className="col-sm-2">
                    <div
                      className={
                        this.state.onDay !== ""
                          ? "mdInput mdFocussed"
                          : "mdInput"
                      }>
                      <select
                        name="onDay"
                        value={this.state.onDay}
                        onChange={(event) => this.handleTextChange(event)}>
                        <option value="1">1st</option>
                        <option value="2">2nd</option>
                        <option value="3">3rd</option>
                        <option value="4">4th</option>
                        <option value="5">5th</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div
                      className={
                        this.state.onDayWeek !== ""
                          ? "mdInput mdFocussed"
                          : "mdInput"
                      }>
                      <select
                        name="onDayWeek"
                        value={this.state.onDayWeek}
                        onChange={(event) => this.handleTextChange(event)}>
                        <option value="monday">Monday</option>
                        <option value="tuesday">Tuesday</option>
                        <option value="wednesday">Wednesday</option>
                        <option value="thursday">Thursday</option>
                        <option value="friday">Friday</option>
                        <option value="saturday">Saturday</option>
                        <option value="sunday">Sunday</option>
                      </select>
                    </div>
                  </div>
                </>
              )}
              {this.state.ruleType === "on_specific_date" && (
                <div className="col-sm-4">
                  <HelpTextDiv
                    id="idSystemTagRuleDate"
                    label="Learn more about the Date"
                    type="addSystemTag"
                    isInline="false"
                  />

                  <label className="inputFakeLabelFocussed">Select Date</label>
                  <div>
                    <DatePicker
                      value={this.state.onSpecificDate}
                      onChange={this.onChangeRuleDate.bind(this)}
                    />
                  </div>
                </div>
              )}
              <div className="col-sm-4">
                <div
                  className={
                    this.state.tagPriority !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Priority</label>
                  <input
                    type="number"
                    className="onDayInput"
                    name="tagPriority"
                    value={this.state.tagPriority}
                    onChange={(event) => this.handleTextChange(event)}
                  />
                </div>
                <div className="gap20" />
              </div>
            </div>
            <div className="gap20" />
            {this.state.ruleType !== "" && (
              <div className="row">
                <div className="col-sm-6">
                  <div className="card">
                    <div className="container-fluid">
                      <div className="col-sm-12">
                        <div
                          className={
                            this.state.actionType !== ""
                              ? "mdInput mdFocussed"
                              : "mdInput"
                          }>
                          <label>Select Action</label>
                          <select
                            className="actionSelect"
                            name="actionType"
                            value={this.state.actionType}
                            onChange={(event) => this.handleTextChange(event)}>
                            <option value="" />
                            <option value="add-tag">Add A Tag</option>
                            <option value="inactivate-tag">
                              Inactivate A Tag
                            </option>
                            {this.state.ruleType === "instant" && (
                              <option value="cancel-challenge">
                                Cancel Challenge
                              </option>
                            )}
                          </select>
                        </div>
                        <div className="gap20" />
                        {(this.state.actionType === "add-tag" ||
                          this.state.actionType === "inactivate-tag") && (
                          <TagSelect
                            id={"add-system-add-tag"}
                            key={this.state.keyNotiTag}
                            searchKey={"name"}
                            singleSelection={true}
                            hideInput={true}
                            selectKey={"name"}
                            btnText={"Create New Tag"}
                            lblText={"Select Tag"}
                            data={this.state.tags}
                            onChange={this.onChangeAddTags}
                            isClickable={true}
                            selectedValues={[this.state.tagValue]}
                            reload={() => {
                              this.getSystemTags();
                            }}
                          />
                        )}
                        <div className="gap20" />
                      </div>
                    </div>
                  </div>
                </div>
                {(this.state.actionType === "add-tag" ||
                  this.state.actionType === "inactivate-tag") && (
                  <div className="col-sm-6">
                    <div className="card">
                      <div className="container-fluid">
                        <div className="col-sm-12">
                          <div
                            className={
                              this.state.tagWhen !== ""
                                ? "mdInput mdFocussed"
                                : "mdInput"
                            }>
                            <label>When</label>
                            <select
                              className="actionSelect"
                              name="tagWhen"
                              value={this.state.tagWhen}
                              onChange={(event) =>
                                this.handleTextChange(event)
                              }>
                              <option value="" />
                              <option value="always">Always</option>
                              <option value="tag">User has the tag</option>

                              <option value="no-tag">
                                User does not have the tag
                              </option>
                            </select>
                          </div>
                          <div className="gap20" />
                          {(this.state.tagWhen === "tag" ||
                            this.state.tagWhen === "no-tag") && (
                            <TagSelect
                              id={"add-system-add-when-tag"}
                              key={this.state.keyNotiTag}
                              searchKey={"name"}
                              singleSelection={true}
                              hideInput={true}
                              selectKey={"name"}
                              btnText={"Create New Tag"}
                              lblText={"Select Tag"}
                              data={this.state.tags}
                              onChange={this.onChangeAddWhenTags}
                              selectedValues={[this.state.tagWhenValue]}
                              isClickable={true}
                              reload={() => {
                                this.getSystemTags();
                              }}
                            />
                          )}
                          <div className="gap20" />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {this.state.actionType === "cancel-challenge" && (
                  <div className="col-sm-6">
                    <div className="card">
                      <div className="container-fluid">
                        <div
                          className={
                            this.state.cancelChallengeId !== ""
                              ? "mdInput mdFocussed"
                              : "mdInput"
                          }>
                          <label>Select Challenge</label>
                          <select
                            className="selectRuleTypeInput"
                            name="cancelChallengeId"
                            value={this.state.cancelChallengeId}
                            onChange={(event) => this.handleTextChange(event)}>
                            <option value="" />
                            {this.state.challenges.map((i) => (
                              <option value={i._id}>{i.name}</option>
                            ))}
                          </select>
                        </div>
                        <div className="gap20" />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}

            <div className="gap20" />
            {this.state.editMode && (
              <Checkbox
                label={"Add these rules retroactively"}
                isChecked={this.state.rulesRetroActive}
                handleCheckboxChange={this.toggleRules}
                key={this.state.keyRulesRetroActive}
              />
            )}
            <div className="text-right">
              <button
                className="btn btn-primary addRuleBtn"
                onClick={() => this.onClickAddRule()}>
                {this.state.selectedRuleIndex !== -1 ? "Save Rule" : "Add Rule"}
              </button>
            </div>
            <div className="gap20" />
          </div>
        </div>

        {this.state.systemTag.additional_rules.length > 0 && (
          <div className="card">
            <div className="container-fluid">
              <div className="gap20" />
              <h5 className="noMargin">Added Additional Rules</h5>
              <div className="gap20" />
              <div className="addedRule">{this.renderRules()}</div>
              <div className="gap20" />
            </div>
          </div>
        )}
      </div>
    );
  }

  renderZoomEvents() {
    return (
      <div className="container-fluid">
        <div className="card">
          <div className="container-fluid">
            <div className="gap20" />
            <h5 className="noMargin">Zoom Meeting</h5>
            <div className="gap20" />
            <div className="row">
              <div className="col-sm-3">
                <div
                  className={
                    this.state.selectedZoomUser !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Select User</label>
                  <select
                    className="selectRuleTypeInput"
                    name="selectedZoomUser"
                    value={this.state.selectedZoomUser}
                    onChange={(event) => this.handleTextChange(event)}>
                    <option value="" />
                    {this.state.zoomUsers.map((i) => (
                      <option value={i.id}>
                        {i.first_name} {i.last_name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="gap20" />
              </div>
              <div className="col-sm-9">
                <div
                  className={
                    this.state.selectedZoomMeeting !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Select Meeting</label>
                  <select
                    className="actionSelect"
                    name="selectedZoomMeeting"
                    value={this.state.selectedZoomMeeting}
                    onChange={(event) => this.handleTextChange(event)}>
                    <option value="" />
                    {this.state.zoomMeetings.map((i) => (
                      <option value={i.id}>{i.topic}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="gap20" />
          </div>
        </div>
      </div>
    );
  }

  renderEventsSection() {
    return (
      <div className="container-fluid">
        <div className="card">
          <div className="container-fluid">
            <div className="gap20" />
            <h5 className="noMargin">Events</h5>
            <div className="gap20" />
            <div className="row">
              <div className="col-sm-3">
                <div
                  className={
                    this.state.eventType !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Select Event</label>
                  <select
                    className="selectRuleTypeInput"
                    name="eventType"
                    value={this.state.eventType}
                    onChange={(event) => this.handleTextChange(event)}>
                    <option value="" />
                    <option value="add_to_demio">Add To Demio</option>
                  </select>
                </div>
                <div className="gap20" />
              </div>
              {this.state.eventType === "add_to_demio" && (
                <div className="col-sm-9">
                  <div
                    className={
                      this.state.selectedWebinar !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>Select Webinar</label>
                    <select
                      className="actionSelect"
                      name="selectedWebinar"
                      value={this.state.selectedWebinar}
                      onChange={(event) => this.handleTextChange(event)}>
                      <option value="" />
                      {this.state.demioEvents.map((i) => (
                        <option value={i.id}>
                          {i.name} [{i.status}]
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
            </div>
            <div className="gap20" />
            <div className="text-right">
              <button
                className="btn btn-primary addRuleBtn"
                onClick={() => this.onClickAddEvent()}>
                Add Event
              </button>
            </div>
            <div className="gap20" />
          </div>
        </div>

        {this.state.systemTag.events.length > 0 && (
          <div className="card">
            <div className="container-fluid">
              <div className="gap20" />
              <h5 className="noMargin">Added Events</h5>
              <div className="gap20" />
              <div className="addedRule">{this.renderEvents()}</div>
              <div className="gap20" />
            </div>
          </div>
        )}
      </div>
    );
  }

  renderNotificationsSection() {
    return (
      <div className="container-fluid">
        <div className="card">
          <div className="container-fluid">
            <div className="gap20" />
            <h5 className="noMargin">Notifications</h5>
            <div className="gap20" />
            <div className="row">
              <div className="col-sm-3">
                <HelpTextDiv
                  id="idSystemTagNotification"
                  label="Learn more about the Email From"
                  type="addSystemTag"
                  isInline="false"
                />
                <div
                  className={
                    this.state.notiType !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Select Notification</label>
                  <select
                    className="selectRuleTypeInput"
                    name="notiType"
                    value={this.state.notiType}
                    onChange={(noti) => this.handleTextChange(noti)}>
                    <option value="" />
                    <option value="send_mail">Send Mail</option>
                  </select>
                </div>
                <div className="gap20" />
              </div>
            </div>
            {this.state.notiType === "send_mail" && (
              <div>
                <div className="row">
                  <div className="col-sm">
                    <HelpTextDiv
                      id="idSystemTagSubject"
                      label="Learn more about the subject"
                      type="addSystemTag"
                      isInline="false"
                    />

                    <div
                      className={
                        this.state.emailSubject !== ""
                          ? "mdInput mdFocussed"
                          : "mdInput"
                      }>
                      <label>Subject</label>
                      <input
                        type="text"
                        className="onDayInput"
                        name="emailSubject"
                        value={this.state.emailSubject}
                        onChange={(event) => this.handleTextChange(event)}
                      />
                    </div>
                    <div className="gap20" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm">
                    <div className="radioBHolder">
                      <p>Email Body </p>
                      <div className="tabsHolder radioTabs">
                        <RadioTag
                          onCheckChange={this.onSelectEmailBody.bind(this)}
                          labelList={[
                            {
                              name: "Name",
                              value: "name",
                            },
                            {
                              name: "Email",
                              value: "email_address",
                            },
                            {
                              name: "Phone",
                              value: "phone_number",
                            },
                            {
                              name: "Address",
                              value: "address",
                            },
                            {
                              name: "Country",
                              value: "country",
                            },
                            {
                              name: "Form Submission",
                              value: "form_submission",
                            },
                          ]}
                          id={"emailBody"}
                          selectedList={this.state.selectedEmailBody}
                          selectType={"value"}
                          type={"multiple"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm">
                    <TagSelect
                      id={"add-system-tag-add-user"}
                      key={this.state.keySelectUser}
                      selectedValues={this.state.selectedUsers}
                      searchKey={"name"}
                      selectKey={"email_address"}
                      btnText={"Add User"}
                      lblText={"Search User"}
                      data={this.state.staffUsers}
                      onChange={this.onChangeUsers}
                    />
                    <div
                      className={
                        this.state.selectedEmails !== ""
                          ? "mdInput mdFocussed"
                          : "mdInput"
                      }>
                      <label>Email Addresses</label>
                      <textarea
                        name="selectedEmails"
                        value={this.state.selectedEmails}
                        onChange={(event) => this.handleInputChange(event)}
                      />
                    </div>
                    <div className="gap10" />
                    <label style={{ color: "grey" }}>
                      Note: Comma(,) seperated email addresses
                    </label>
                  </div>
                </div>
              </div>
            )}
            <div className="gap20" />
            <div className="text-right">
              <button
                className="btn btn-primary addRuleBtn"
                onClick={() => this.onClickAddNotification()}>
                {this.state.selectedNotification !== -1
                  ? "Update Notification"
                  : "Add Notification"}
              </button>
            </div>
            <div className="gap20" />
          </div>
        </div>

        {this.state.systemTag.notifications.length > 0 && (
          <div className="card">
            <div className="container-fluid">
              <div className="gap20" />
              <h5 className="noMargin">Added Notifications</h5>
              <div className="gap20" />
              <div className="addedRule">{this.renderNotifications()}</div>
              <div className="gap20" />
            </div>
          </div>
        )}
      </div>
    );
  }

  onChangeChallenges(challenges) {
    this.setState({ selectedChallenges: challenges });
  }

  renderChallengesSection() {
    return (
      <div className="container-fluid">
        <div className="card">
          <div className="container-fluid">
            <div className="gap20" />
            <h5 className="noMargin">Challenges</h5>
            <div className="gap20" />
            <SelectChallenge
              from="add-tag"
              selectedChallenges={this.state.selectedChallenges}
              challenges={this.state.challenges}
              key={this.state.keyChallenges}
              onChange={this.onChangeChallenges.bind(this)}
            />
          </div>
        </div>
      </div>
    );
  }

  renderPlans() {
    let returnVals = [];
    let plans = this.state.stripePlans;
    returnVals.push(<option value={""} key={0} />);
    for (let i = 0; i < plans.length; i++) {
      returnVals.push(
        /*eslint-disable*/
        <option value={plans[i]._id} key={i + 1}>
          {plans[i].description +
            " ($" +
            parseFloat(plans[i].amount) / 100 +
            "/" +
            plans[i].interval_period +
            ")"}
        </option>
        /*eslint-enable*/
      );
    }
    return returnVals;
  }

  onChangePlan(event) {
    const target = event.target;
    const value = target.value;

    this.setState({
      selectedPlan: value,
    });
  }

  renderSubscriptionSection() {
    return (
      <div className="container-fluid">
        <div className="card">
          <div className="container-fluid">
            <div className="gap20" />
            <h5 className="noMargin">Add Subscription</h5>
            <div className="gap20" />
            <div
              className={
                this.state.selectedPlan !== ""
                  ? "mdInput mdFocussed"
                  : "mdInput"
              }>
              <label>Select plan</label>
              <select
                value={this.state.selectedPlan}
                className="billingDate"
                onChange={this.onChangePlan.bind(this)}>
                {this.renderPlans()}
              </select>
            </div>
            <div className="gap20" />
          </div>
        </div>
      </div>
    );
  }

  renderGenericTagSection() {
    return (
      <div className="container-fluid">
        <div className="card">
          <div className="container-fluid">
            <div className="gap20" />
            <h5 className="noMargin">Add Group</h5>
            <div className="gap20" />

            <HelpTextDiv
              id="idSystemTagSearchTags"
              label="Learn more about the Search Tags"
              type="addSystemTag"
              isInline="false"
            />

            <GenericTagSelect
              id={"add-system-tag-search-tag"}
              key={this.state.keyGenericTag}
              selectedValues={this.state.selectedGenericTags}
              searchKey={"name"}
              selectKey={"name"}
              lblText={"Search Group"}
              data={this.state.genericTags}
              onChange={this.onChangeTags}
              addTag={this.addSystemTag}
              isClickable={true}
              btnText={"Add Group"}
              popupHeaderText={"Create a group"}
              popupGenericType={"systemtag"}
              popupHeaderFFEnableName={true}
              popupHeaderFFEnableDescription={false}
              popupHeaderFFEnableTags={false}
              popupTitle="Groups"
            />
          </div>
        </div>
      </div>
    );
  }

  onClickEditEmail = (email) => {
    let showBack = "y";
    this.props.history.push(
      `/admin/edit-email?eid=${email._id}&back=${showBack}`
    );
  };

  renderEmails() {
    var returnVals = [];
    let emails = this.state.emails || [];
    let tagId = this.state.systemTag._id;
    emails.forEach((element) => {
      let color = "black";
      if (element.tag_list_excluded.indexOf(tagId) > -1) {
        color = "red";
      }
      returnVals.push(
        <tr key={element._id}>
          <td style={{ color: color }}>{element.name}</td>
          <td>{element.title}</td>
          <td className="min-width-100">
            <button
              title="Edit"
              onClick={this.onClickEditEmail.bind(this, element)}
              className="btn btn-default btn-sm margin-right-10 border-0">
              <i className="fa fa-pencil" />
            </button>
          </td>
        </tr>
      );
    });
    return returnVals;
  }

  renderEmailSection() {
    return (
      <div className="container-fluid">
        <div className="card">
          <div className="container-fluid">
            <div className="gap20" />
            <h5 className="noMargin">Emails Associated</h5>
            <div className="gap20" />
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Title</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>{this.renderEmails()}</tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }

  onPressCreateCustomValue() {
    let attributes = this.state.attributes || [];
    if (this.state.attName.trim().length === 0) {
      showNotification("error", "Attribute name is required");
      return;
    }
    if (this.state.attValue.trim().length === 0) {
      showNotification("error", "Attribute value is required");
      return;
    }
    if (this.state.attId) {
      attributes.forEach((element) => {
        if (element.id === this.state.attId) {
          element.name = this.state.attName;
          element.value = this.state.attValue;
        }
      });
    } else {
      attributes.push({
        name: this.state.attName,
        value: this.state.attValue,
        id: getUniqueId(),
      });
    }
    this.setState({ attributes, attName: "", attValue: "", attId: "" });
  }

  onClickDeleteCustomValue(i) {
    let attributes = this.state.attributes || [];
    attributes = attributes.filter((d) => d.id !== i.id);
    this.setState({ attributes, attrRefresh: Math.random() });
  }

  onClickEditCustomValue(i) {
    let attributes = this.state.attributes || [];
    let attInfo = attributes.filter((d) => d.id === i.id)[0];
    this.setState({
      attName: attInfo.name,
      attValue: attInfo.value,
      attId: attInfo.id,
    });
  }

  renderCustomAttributesSection() {
    let attributes = this.state.attributes;
    let title = this.state.systemTag._id;
    return (
      <div className="container-fluid">
        <div className="card">
          <div className="container-fluid">
            <div className="gap20" />
            <Checkbox
              label={"Add Default Tag Attributes"}
              isChecked={this.state.defaultAttributes}
              handleCheckboxChange={this.toggleDefaultAttributes}
              key={this.state.keyDefaultAttributes}
            />{" "}
            <div className="gap20" />
            {this.state.defaultAttributes && (
              <>
                <div className="row">
                  <div className="col-sm-6">
                    <div
                      className={
                        this.state.attName !== ""
                          ? "mdInput mdFocussed"
                          : "mdInput"
                      }>
                      <label>Name</label>
                      <input
                        type="text"
                        name="attName"
                        onChange={this.handleInputChange.bind(this)}
                        value={this.state.attName}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div
                      className={
                        this.state.attValue !== ""
                          ? "mdInput mdFocussed"
                          : "mdInput"
                      }>
                      <label>Value</label>
                      <input
                        type="text"
                        name="attValue"
                        onChange={this.handleInputChange.bind(this)}
                        value={this.state.attValue}
                      />
                    </div>
                  </div>
                </div>
                <div className="gap20" />
                <button
                  className="btn btn-primary"
                  onClick={() => this.onPressCreateCustomValue()}>
                  {this.state.attId
                    ? "Update Custom Value"
                    : "Create Custom Value"}
                </button>
                <div className="gap10" />
                <div key={this.state.attrRefresh}>
                  {attributes.length > 0 && (
                    <>
                      <div className="gap20" />
                      <label>Added Custom Values</label>
                      <div className="gap20" />
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Value</th>
                            <th>Tag</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {attributes.map((i) => (
                            <tr key={i.name}>
                              <td>{i.name}</td>
                              <td>{i.value}</td>
                              <td>
                                {title ? `{{tag(${title}->${i.name})}}` : ""}
                              </td>
                              <td className="min-width-100">
                                {title !== "" && (
                                  <CopyToClipboard
                                    text={`{{tag(${title}->${i.name})}}`}
                                    onCopy={() =>
                                      showNotification(
                                        "success",
                                        "Tag copied to clipboard",
                                        5000
                                      )
                                    }>
                                    <button className="btn btn-sm btn-default border-0 margin-right-10">
                                      <i className="fa fa-copy" />
                                    </button>
                                  </CopyToClipboard>
                                )}
                                <button
                                  className="btn btn-default btn-sm border-0 margin-right-10"
                                  onClick={this.onClickEditCustomValue.bind(
                                    this,
                                    i
                                  )}>
                                  <i className="fa fa-pencil" />
                                </button>
                                <button
                                  className="btn btn-danger btn-sm border-0"
                                  onClick={this.onClickDeleteCustomValue.bind(
                                    this,
                                    i
                                  )}>
                                  <i className="fa fa-trash" />
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }

  onChangeDate(value) {
    let systemTag = this.state.systemTag;
    systemTag.rules["tag_expire_date"] = value;

    systemTag.rules.forEach((obj) => {
      if (obj.hasOwnProperty("tag_expire_date")) {
        obj["tag_expire_date"] = value;
      }
    });

    this.setState({
      systemTag: systemTag,
    });
  }

  addSystemTag = (add) => {
    this.state.genericTags.push(add);
    this.setState({
      fTags: {
        existing: this.state.genericTags,
      },
    });
  };

  async onClickViewUsers() {
    let userCount = 0;

    let selectedUsers = [];
    let userInfos = [];

    let data = {
      action: "tag-filter-users",
      data: {
        includedTags: [this.state.systemTag._id],
        excludedTags: [],
      },
    };
    let d = await callGetTagFilterUsers(data);
    userCount = d.userCount;
    selectedUsers = d.selectedUsers;
    userInfos = d.userInfos;
    this.setState(
      { tagUsers: selectedUsers, userInfos, tagUserCount: userCount },
      () => {
        $(".modalAlertOverlay.usersOverlay,.modalAlert.usersAlert").fadeIn(200);
      }
    );
  }

  getTagDate(user) {
    let tagList = user.tag_list || [];
    let dates = [];
    let fList = tagList.filter(
      (d) => d.id.toString() === this.state.systemTag._id.toString()
    );
    fList.forEach((e) => {
      dates.push(new Date(e.created));
    });
    dates.sort((a, b) => b - a);
    return dates[0];
  }

  onClickMemberName(element) {
    if (element._id) {
      let url = `/admin/edit-user?id=${element._id}`;
      window.open(url, "_blank");
    }
  }

  onClickModalOverlay() {
    $(".modalAlertOverlay.usersOverlay,.modalAlert.usersAlert").fadeOut(200);
  }

  onClickCloseFromUsersModal() {
    $(".modalAlertOverlay.usersOverlay,.modalAlert.usersAlert").fadeOut(200);
  }

  onClickExportUsers() {
    let users = [];
    let allUsers = this.state.userInfos;
    let selectedUsers = this.state.tagUsers;

    for (let i = 0; i < selectedUsers.length; i++) {
      const element = selectedUsers[i];

      let user = allUsers.filter((d) => {
        return d._id === element;
      });
      let userInfo = user[0];
      let tagDate = this.getTagDate(userInfo);
      userInfo.tagDate = moment(tagDate).format("Do MMM YYYY");
      users.push(user[0]);
    }

    this.props.history.push("/admin/export-users", {
      users: users,
      tagName: this.state.systemTag.name,
    });
  }

  renderUsersModal() {
    let modalHeight = window.innerHeight - 200;
    let allUsers = this.state.userInfos;
    let selectedUsers = this.state.tagUsers;

    if (selectedUsers.length === 0) {
      return null;
    }

    let self = this;

    function renderUserRow() {
      let returnVals = [];
      for (let i = 0; i < selectedUsers.length; i++) {
        const element = selectedUsers[i];

        let user = allUsers.filter((d) => {
          return d._id === element;
        });
        if (user.length > 0) {
          returnVals.push(
            <tr key={user[0]._id}>
              <td>{user[0].name}</td>
              <td>{user[0].email_address}</td>
              <td>{moment(self.getTagDate(user[0])).format("Do MMM YYYY")}</td>
              <td>
                <button
                  className="btn btn-default btn-sm"
                  onClick={self.onClickMemberName.bind(self, user[0])}>
                  View
                </button>
              </td>
            </tr>
          );
        }
      }
      return returnVals;
    }

    return (
      <div>
        <div
          className="modalAlertOverlay usersOverlay"
          onClick={this.onClickModalOverlay.bind(this)}
        />
        <div className="modalAlert usersAlert modal-lg big">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title margin-right-10"
                id="exampleModalLabel">
                Users
              </h5>
              <div onClick={() => this.onClickExportUsers()}>
                <button className="btn btn-sm btn-primary">Export</button>
              </div>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.onClickCloseFromUsersModal.bind(this)}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div
              className="container-fluid modalInnerBody"
              style={{ maxHeight: modalHeight, overflowY: "hidden" }}>
              <Scrollbars
                autoHide
                autoHideTimeout={1000}
                autoHideDuration={200}
                style={{ height: modalHeight }}>
                <div className="gap20" />
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Tag Date</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>{renderUserRow()}</tbody>
                </table>
              </Scrollbars>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderAddSytemTag() {
    let hasTagDayLimit = false;
    let hasTagExpireDays = false;
    let hasTagExpireDate = false;
    let dayLimit = "";
    let expireDays = "";
    let expireDate = new Date();

    this.state.systemTag.rules.forEach((obj) => {
      if (obj.hasOwnProperty("limit_tag_per_days")) {
        hasTagDayLimit = true;
        dayLimit = obj["limit_tag_per_days"];
      }
      if (obj.hasOwnProperty("tag_expire_days")) {
        hasTagExpireDays = true;
        expireDays = obj["tag_expire_days"];
      }
      if (obj.hasOwnProperty("tag_expire_date")) {
        hasTagExpireDate = true;
        expireDate = obj["tag_expire_date"]
          ? new Date(obj["tag_expire_date"])
          : new Date();
      }
    });

    let fromPopup = this.props.fromPopup;

    return (
      <div>
        {this.renderUsersModal()}
        <div className="container-fluid">
          {!fromPopup ? (
            <TitleBox
              title={this.state.editMode ? "Edit Tag" : "Create New Tag"}
              showBackBtn={this.state.editMode}
            />
          ) : null}
          <div className="gap20" />
          <div className="card">
            <div className="container-fluid">
              <div className="gap20" />
              {(!this.state.editMode || fromPopup) && (
                <button
                  className={"btn btn-info float-right"}
                  onClick={() =>
                    this.setState({
                      showAdvancedOptionsDetails:
                        !this.state.showAdvancedOptionsDetails,
                    })
                  }>
                  {this.state.showAdvancedOptionsDetails
                    ? "Hide Advanced Options"
                    : "Show Advanced Options"}
                </button>
              )}

              {this.state.editMode && !fromPopup && (
                <button
                  className={"btn btn-default float-right"}
                  onClick={() => this.onClickViewUsers()}>
                  View Users
                </button>
              )}

              {this.state.editMode && (
                <>
                  <label className="inputFakeLabelFocussed">Tag ID</label>
                  <br />
                  <label>{this.state.systemTag._id}</label>
                </>
              )}

              <div className="row">
                <div className="col-md-12">
                  <HelpTextDiv
                    id="idSystemTagName"
                    label="Learn more about the Tag Name"
                    type="addSystemTag"
                    isInline="false"
                  />

                  <div
                    className={
                      this.state.systemTag.name !== "" || isTemplateSite()
                        ? "mdInput mdFocussed input-group"
                        : "mdInput input-group"
                    }>
                    <label>Tag Name</label>
                    {isTemplateSite() && (
                      <div className="input-group-append">
                        <span className="input-group-text" id="basic-addon2">
                          system-
                        </span>
                      </div>
                    )}
                    <input
                      type="text"
                      className="TagName"
                      name="name"
                      value={this.state.systemTag.name}
                      onChange={(event) => this.handleTextChange(event)}
                    />
                  </div>
                </div>
              </div>
              <div className="gap20" />

              {this.state.showAdvancedOptionsDetails && (
                <div
                  className={
                    this.state.systemTag.description !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Tag Description</label>
                  <input
                    type="text"
                    className="TagDesc"
                    name="description"
                    value={this.state.systemTag.description}
                    onBlur={(event) => {
                      const target = event.target;
                      var value = target.value;
                      let systemTag = this.state.systemTag;
                      if (!systemTag["name"]) {
                        systemTag["name"] = slugify(value);
                      }
                      this.setState({ systemTag: systemTag });
                    }}
                    onChange={(event) => this.handleTextChange(event)}
                  />
                </div>
              )}

              {/* <div className="gap20" />
        <div className="radioBHolder">
          <div className="havingHelpText">
            <p>Tag Type</p>
          </div>
          <HelpTextDiv
            id="idSystemTagType"
            label="Learn more about the Tag Type"
            type="addSystemTag"
            isInline="true"
          />
          <div className="tabsHolder radioTabs">
            <RadioTag
              onCheckChange={this.onSelectType.bind(this)}
              labelList={[
                { name: "Automated", value: "automated" },
                { name: "System", value: "system" }
              ]}
              id={"type"}
              selectedList={[this.state.systemTag.type]}
              selectType={"value"}
            />
          </div>
        </div> */}
              <div className="gap20" />
            </div>
            {this.state.showAdvancedOptionsDetails && (
              <>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="radioBHolder">
                        <div className="havingHelpText">
                          <p>Tag Rules</p>
                        </div>
                        <HelpTextDiv
                          id="idSystemTagRules"
                          label="Learn more about the Tag Rules"
                          type="addSystemTag"
                          isInline="true"
                        />
                        <div className="tabsHolder radioTabs">
                          <RadioTag
                            onCheckChange={this.onSelectRules.bind(this)}
                            labelList={[
                              {
                                name: "Add Tag Day Limit",
                                value: "limit_tag_per_days",
                              },
                              {
                                name: "Tag Expires After X Days",
                                value: "tag_expire_days",
                              },
                              {
                                name: "Tag Expires On Date",
                                value: "tag_expire_date",
                              },
                            ]}
                            id={"rules"}
                            selectedList={this.state.selectedRules}
                            selectType={"value"}
                            type={"multiple"}
                          />
                        </div>
                      </div>
                      <div className="gap20" />
                    </div>
                    {hasTagDayLimit && (
                      <div className="col-sm-6 addTagAgeLimit">
                        <HelpTextDiv
                          id="idSystemTagFixDays"
                          label="Learn more about the minimum Days"
                          type="addSystemTag"
                          isInline="false"
                        />

                        <div
                          className={
                            dayLimit !== "" ? "mdInput mdFocussed" : "mdInput"
                          }>
                          <label>
                            This tag can only be added once every X amount of
                            days
                          </label>
                          <input
                            type="text"
                            name="limit_tag_per_days"
                            value={dayLimit}
                            onChange={(event) =>
                              this.handleRulesTextChange(event)
                            }
                          />
                        </div>
                        <div className="gap20" />
                      </div>
                    )}
                    {hasTagExpireDays && (
                      <div className="col-sm-4 tagExpiresAfterXDays">
                        <HelpTextDiv
                          id="idSystemTagAfterExpires"
                          label="Learn more about the Tags after X days"
                          type="addSystemTag"
                          isInline="false"
                        />

                        <div
                          className={
                            expireDays !== "" ? "mdInput mdFocussed" : "mdInput"
                          }>
                          <label>Tag expires after X amount of days</label>
                          <input
                            type="text"
                            name="tag_expire_days"
                            value={expireDays}
                            onChange={(event) =>
                              this.handleRulesTextChange(event)
                            }
                          />
                        </div>
                        <div className="gap20" />
                      </div>
                    )}
                    {hasTagExpireDate && (
                      <div className="col-sm-4">
                        <label className="inputFakeLabelFocussed">
                          Tag Expiry Date
                        </label>
                        <br />

                        <DatePicker
                          minDate={new Date()}
                          value={expireDate}
                          onChange={this.onChangeDate.bind(this)}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="gap20" />

                {this.renderTagRulesSection()}
                {/* {isFS() && this.renderEventsSection()} */}
                {this.state.isZoom && this.renderZoomEvents()}
                {this.renderNotificationsSection()}
                {this.renderChallengesSection()}
                {this.renderSubscriptionSection()}
                {this.renderGenericTagSection()}
                {this.state.editMode && this.renderEmailSection()}
                {this.renderCustomAttributesSection()}
              </>
            )}
          </div>
        </div>

        <div className="text-right container-fluid">
          {!this.state.editMode && (
            <button
              className="btn btn-default margin-right-10"
              onClick={() => this.resetProps()}>
              Clear
            </button>
          )}
          <button
            className="btn btn-primary"
            onClick={() => this.onPressAddTag()}>
            {this.state.editMode ? "Update Tag" : "Create Tag"}
          </button>
        </div>
        <div className="gap20" />
      </div>
    );
  }

  render() {
    let fromPopup = this.props.fromPopup;
    if (fromPopup) {
      return this.renderAddSytemTag();
    } else {
      return (
        <Onboarding history={this.props.history}>
          {this.renderAddSytemTag()}
        </Onboarding>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  message: state.tagReducer.message,
  success: state.tagReducer.success,
  systemTags: state.challengeReducer.systemTags,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { createSystemTag, updateSystemTag, resetStatus, getSystemTags },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AddSystemTag);
