import Modal from "react-modal";
import React from "react";
import {
  callGetPopularMaterialIcons,
  callSearchMaterialIcons,
} from "../../services";

class MaterialIcons extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      search: "",
      iconModalOpen: false,
      selectedIcon: this.props.icon || "",
      icons: [],
      backupIcons: [],
    };
  }

  componentDidMount() {
    this.getData();
  }

  async getData() {
    const response = await callGetPopularMaterialIcons();
    this.setState({ icons: response, backupIcons: response });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
    if (value.length >= 2) {
      this.searchIcons(value);
    }
    if (!value) {
      this.setState({ icons: this.state.backupIcons });
    }
  }

  async searchIcons(query) {
    let response = await callSearchMaterialIcons({ search: query });
    this.setState({ icons: response });
  }

  render() {
    const customStyles = {
      overlay: { zIndex: 20000 },
      content: {
        top: "10%",
        left: "35%",
        right: "35%",
        bottom: "20%",
      },
    };

    return (
      <>
        <Modal
          isOpen={this.state.iconModalOpen}
          contentLabel="Material Icons"
          style={customStyles}
          shouldCloseOnOverlayClick={true}
          onRequestClose={() => {
            this.setState({ iconModalOpen: false });
          }}>
          <>
            <div
              className={
                this.state.search !== "" ? "mdInput mdFocussed" : "mdInput"
              }>
              <label>Search Icons</label>
              <input
                type="text"
                name="search"
                value={this.state.search}
                onChange={(event) => this.handleInputChange(event)}
              />
            </div>
            <div className="gap20" />
            <div className="alt-row">
              {this.state.icons.map((i) => (
                <div
                  className="alt-col-2"
                  key={i}
                  onClick={() => {
                    this.setState({ selectedIcon: i, iconModalOpen: false });
                    this.props.onChange(i);
                  }}
                  style={{ padding: 10, cursor: "pointer" }}>
                  <i
                    className="material-icons"
                    title={i}
                    style={{ fontSize: 30 }}>
                    {i}
                  </i>
                </div>
              ))}
            </div>
          </>
        </Modal>
        <button
          className="btn btn-default"
          onClick={() => {
            this.setState({
              iconModalOpen: true,
            });
          }}>
          {this.state.selectedIcon && (
            <>
              <i
                className="material-icons"
                title={this.state.selectedIcon}
                style={{ fontSize: 30 }}>
                {this.state.selectedIcon}
              </i>
              <br />
            </>
          )}
          Select Icon
        </button>
      </>
    );
  }
}

export default MaterialIcons;
