/* eslint jsx-a11y/anchor-is-valid: 0 */

import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment";
import $ from "jquery";
import { VelocityTransitionGroup } from "velocity-react";
import DatePicker from "react-date-picker";

import TitleBox from "../../../components/general/titleBox";
import RadioTag from "../../../components/form/radioTag";
import Checkbox from "../../../components/form/checkBox";
import {
  callGetStripePayments,
  callCreateStripeCustomer,
  callUpdateStripeCharge,
  callUpdateCustomerOrder,
  callGetStripeSubscriptions,
  callUpdateStripeSubscription,
  callUpdateStripePayment,
  callGetCustomerOrderUsingId,
  callProcessUpcomingPayment,
  callGetStripeSubscription,
  callGetUserById,
  callGetUsersByQuery,
  callCreateMemberPayment,
} from "../../../services";
import { showNotification } from "../../../helpers";
import TypeableSelect from "../../../components/form/typeableSelect";
import DateTimePicker from "react-datetime-picker";
import { getItem } from "../../../helpers/storage";
import { callGetSettings } from "../../../services/settingService";

class OrderDetails extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    let order = undefined;
    let notes = "";

    if (this.props.history.location.state !== undefined) {
      order = this.props.history.location.state.order;
      notes = this.props.history.location.state.notes;
    }
    let isSubscription = false;
    if (order && !order.total_charges) {
      isSubscription = true;
    }
    this.state = {
      userData: this.props.userData || {},
      order: order,
      charges: [],
      selectedCharge: {},
      refundAmount: 0,
      notes: "requested_by_customer",
      internalNotes: "",
      isSubscription: isSubscription,
      stripeSubscription: {},
      subscriptionStatus: "",
      isCancelSubscription: false,
      processingRefund: false,
      refundAction: "no",
      keyCheckbox: Math.random(),
      keyRefundType: Math.random(),
      stripePayment: {},
      deleteOption: "single",
      selectedPaymentIndex: "",
      selectedPayment: {},
      paymentDate: new Date(),
      paymentAmount: "",
      keyPaymentDate: Math.random(),
      orderUserData: {},
      affiliateUserInfo: {},
      customerNotes: notes,
      affiliateUsers: [],
      keyAffiliateUsers: Math.random(),
      selectedAffiliateUser: {},
      affiliateOption: "yes",
      commissionAmount: "",
      affAdding: true,
      stripeSubscriptionStatus: "",
      noOfSubscriptions: 0,
      stripeAccounts: [],
    };
  }

  componentDidMount() {
    this.getOrderDetails();
    this.getAffiliateUsers();
    this.getSettings();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.userData) {
      this.setState({ userData: newProps.userData });
    }
  }

  async getSettings() {
    let d = await callGetSettings("stripeaccounts");
    if (d.status) {
      let accounts = d.data.stripe_accounts || [];
      let newAccounts = [{ id: "default", name: "Default" }];
      newAccounts = newAccounts.concat(accounts);
      this.setState({ stripeAccounts: newAccounts });
    }
  }

  async getOrderDetails() {
    if (this.state.order._id) {
      let d = await callGetCustomerOrderUsingId(this.state.order._id);

      if (d.affiliate_id) {
        let aUserInfo = await callGetUserById(d.affiliate_id);
        this.setState({ affiliateUserInfo: aUserInfo, affAdding: false });
      } else {
        this.setState({ affAdding: false });
      }
      let userInfo = await callGetUserById(d.member_id);
      this.setState({ orderUserData: userInfo });

      this.setState({ order: d }, () => {
        this.getCharges();
      });
    }
  }

  async getAffiliateUsers() {
    /*eslint-disable*/
    let query = {
      query: {
        is_affiliate: true,
        $select: ["_id", "name", "email_address", "affiliate_details"],
      },
    };
    /*eslint-enable*/
    let response = await callGetUsersByQuery(query);
    let fUsers = response.data;
    let affiliateUsers = [];

    fUsers.forEach((element) => {
      affiliateUsers.push({ value: element, label: element.name });
    });
    this.setState({
      affiliateUsers: affiliateUsers,
      keyAffiliateUsers: Math.random(),
    });
  }

  getCharges() {
    let order = this.state.order;
    if (order) {
      if (order.processor === "stripe" && !this.state.isSubscription) {
        /*eslint-disable*/
        let query = {
          query: { customer_order_id: order._id },
        };
        /*eslint-enable*/

        callGetStripePayments(query).then((d) => {
          if (d.data.length > 0) {
            let payments = d.data;
            let charges = [];
            for (let i = 0; i < payments.length; i++) {
              const element = payments[i];
              let stripeCharges = element.stripe_charges;
              let stCharges = [];
              if (stripeCharges) {
                stripeCharges.forEach((element) => {
                  if (element._id) {
                    stCharges.push(element);
                  }
                });
              }
              charges = charges.concat(stCharges);
              charges = charges.sort((a, b) => {
                return new Date(b["createdAt"]) - new Date(a["createdAt"]);
              });
            }
            let stripePaymentObject = payments[0];
            this.setState({
              charges: charges,
              stripePayment: stripePaymentObject,
            });
          }
        });
      } else if (order.processor === "stripe" && this.state.isSubscription) {
        /*eslint-disable*/
        let query = {
          query: { customer_order_id: order._id },
        };
        /*eslint-enable*/

        callGetStripeSubscriptions(query).then((d) => {
          if (d.data.length > 0) {
            let payments = d.data || [];
            let stripeSubscription = {};
            let subscriptionStatus = "";
            if (payments.length > 0) {
              stripeSubscription = payments[0];
              subscriptionStatus =
                stripeSubscription.status === "pending" ||
                stripeSubscription.status === "active"
                  ? "Active"
                  : "Cancelled";
            }
            let charges = [];
            for (let i = 0; i < payments.length; i++) {
              const element = payments[i];
              let stripeCharges = element.stripe_charges;
              let stCharges = [];
              if (stripeCharges) {
                stripeCharges.forEach((stCharge) => {
                  if (stCharge._id) {
                    stCharges.push(stCharge);
                  }
                });
              }
              charges = charges.concat(stCharges);
            }
            this.setState(
              {
                charges: charges,
                subscriptionStatus: subscriptionStatus,
                stripeSubscription: stripeSubscription,
                noOfSubscriptions: payments.length,
              },
              () => {
                this.onClickRefreshSubscription(2);
              }
            );
          }
        });
      }
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  formatDate(date) {
    return moment(date).format("MMM Do, YYYY @ hh:mm A");
  }

  onSelectOrderTypeRadio(item, id) {
    let value = item[Object.keys(item)[0]].value;
    let order = this.state.order;

    order["order_type"] = value;

    this.setState({ order: order }, () => {
      /*eslint-disable*/
      let data = {
        order_type: value,
      };
      /*eslint-enable*/
      callUpdateCustomerOrder(order._id, data).then((d) => {
        // update done
      });
    });
  }

  onSelectRefundAction(item, id) {
    let value = item[Object.keys(item)[0]].value;
    this.setState({ refundAction: value });
  }

  onSelectAffiliateOption(item, id) {
    let value = item[Object.keys(item)[0]].value;
    this.setState({ affiliateOption: value });
  }

  onClickRefund(charge) {
    let amtCollected = parseFloat(charge.amount);
    let amtRefunded = charge.refund_amount
      ? parseFloat(charge.refund_amount)
      : 0;
    let maxRefundAmt = amtCollected - amtRefunded;
    charge.amtCollected = amtCollected;
    charge.amtRefunded = amtRefunded;
    charge.maxRefundAmt = maxRefundAmt;

    let refundAmount = (amtCollected / 100).toFixed(2);

    this.setState(
      { selectedCharge: charge, refundAmount: refundAmount },
      () => {
        $(
          ".modalAlertOverlay.refundModalOverlay,.modalAlert.refundModal"
        ).fadeIn(200);
      }
    );
  }

  onClickCancelFromRefundModal() {
    $(".modalAlertOverlay.refundModalOverlay,.modalAlert.refundModal").fadeOut(
      200
    );
  }

  onClickProcessFromRefundModel() {
    if (this.state.isCancelSubscription && this.state.refundAction === "no") {
      this.callCancelSubscriptionAPI(1);
      return;
    }

    if (this.state.notes.trim().length === 0) {
      showNotification("error", "Please add notes", 4000);
      return;
    }

    //call stripe refund api

    let refundAmount = parseFloat(this.state.refundAmount) * 100;

    if (refundAmount <= 0) {
      showNotification("error", "Please add valid refund amount", 4000);
      return;
    }

    this.setState({ processingRefund: true });

    let updateData = {
      action: "create-refund",
      data: {
        charge: this.state.selectedCharge.stripe_charge_id,
        amount: Math.round(refundAmount),
        reason: this.state.notes,
      },
    };
    callCreateStripeCustomer(updateData).then((data) => {
      if (data.id) {
        /*eslint-disable*/
        let refundData = {
          date: new Date(),
          amount: refundAmount,
          notes: this.state.notes,
          internal_notes: this.state.internalNotes,
          stripe_refund_id: data.id,
        };
        /*eslint-enable*/
        let refundHistory = this.state.selectedCharge.refund_history || [];
        refundHistory.push(refundData);
        let refAmount = refundHistory
          .map((i) => i.amount)
          .reduce((a, b) => {
            return a + b;
          }, 0);

        /*eslint-disable*/
        let updateData = {
          refund_amount: refAmount,
          refund_history: refundHistory,
        };
        /*eslint-enable*/
        callUpdateStripeCharge(this.state.selectedCharge._id, updateData).then(
          (d) => {
            if (this.state.isCancelSubscription) {
              this.callCancelSubscriptionAPI(2);
            } else {
              this.setState({
                refundAmount: 0,
                notes: "requested_by_customer",
                processingRefund: false,
              });
              this.onClickCancelFromRefundModal();
              this.getCharges();
              showNotification("success", "Refund processed.", 6000);
            }
          }
        );
      } else {
        this.setState({ processingRefund: false });
        this.onClickCancelFromRefundModal();
        showNotification("error", data.raw.message, 6000);
      }
    });
  }

  callCancelSubscriptionAPI(option) {
    if (option === 1) {
      this.setState({ processingRefund: true });
    }

    let updateData = {
      action: "cancel-subscription",
      data: {
        subscriptionId: this.state.stripeSubscription.stripe_subscription_id,
      },
    };
    callCreateStripeCustomer(updateData).then((data) => {
      let subId = this.state.stripeSubscription._id;
      /*eslint-disable*/
      let cancelData = {
        status: "cancelled",
        cancel_member_id: this.state.userData._id,
        cancel_date: new Date(),
      };
      /*eslint-enable*/

      callUpdateStripeSubscription(subId, cancelData).then((d) => {
        this.setState({
          refundAmount: 0,
          notes: "requested_by_customer",
          processingRefund: false,
        });
        this.onClickCancelFromRefundModal();
        this.getCharges();
        showNotification("success", "Subscription successfully canceled", 6000);
      });
    });
  }

  toggleCheckbox = (check) => {
    let refundAction = this.state.refundAction;
    if (!check) {
      refundAction = "no";
    }
    this.setState({
      isCancelSubscription: check,
      refundAction: refundAction,
    });
  };

  renderRefundModal() {
    let amtCollected = 0;
    let amtRefunded = 0;
    let maxRefundAmt = 0;
    if (this.state.selectedCharge.amtCollected) {
      let charge = this.state.selectedCharge;
      amtCollected = (charge.amtCollected / 100).toFixed(2);
      amtRefunded = (charge.amtRefunded / 100).toFixed(2);
      maxRefundAmt = ((charge.amtCollected - charge.amtRefunded) / 100).toFixed(
        2
      );
    }
    return (
      <div>
        <div className="modalAlertOverlay refundModalOverlay" />
        <div className="modalAlert refundModal bigX">
          <div className="clearfix">
            <div className="pull-left">
              <p>Refund Charge</p>
            </div>
            <div className="pull-right">
              <small className="smallDate">
                {this.formatDate(this.state.selectedCharge.createdAt)}
              </small>
            </div>
          </div>

          <div className="container-fluid">
            <table className="table table-bordered orderDetailsTable">
              <tr>
                <td>Amount Collected</td>
                <td>
                  <span className="text-success">${amtCollected}</span>
                </td>
              </tr>
              <tr>
                <td>Amount Refunded</td>
                <td>
                  <span className="text-danger">${amtRefunded}</span>
                </td>
              </tr>
              <tr>
                <td>Max Refund Available</td>
                <td>
                  <span className="text-danger">${maxRefundAmt}</span>
                </td>
              </tr>
            </table>

            <div className="gap10" />

            <div>
              <label>Process Refund?</label>
              <div className="tabsHolder radioTabs">
                <RadioTag
                  onCheckChange={this.onSelectRefundAction.bind(this)}
                  labelList={[
                    { name: "Yes", value: "yes" },
                    { name: "No", value: "no" },
                  ]}
                  key={this.state.keyRefundType}
                  id={"refundAction"}
                  selectType={"value"}
                  selectedList={[this.state.refundAction]}
                />
              </div>
              <div className="gap20" />
            </div>

            <VelocityTransitionGroup
              enter={{ animation: "slideDown" }}
              leave={{ animation: "slideUp" }}>
              {this.state.refundAction === "yes" && (
                <div>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className={"mdInput mdFocussed"}>
                        <label>Refund Amount</label>
                        <input
                          type="number"
                          name="refundAmount"
                          value={this.state.refundAmount}
                          onChange={(event) => this.handleInputChange(event)}
                        />
                      </div>
                      <div className="gap10" />
                    </div>
                    <div className="col-sm-6">
                      <div
                        className={
                          this.state.notes !== ""
                            ? "mdInput mdFocussed"
                            : "mdInput"
                        }>
                        <label>Reason</label>
                        <select
                          name="notes"
                          value={this.state.notes}
                          onChange={(event) => this.handleInputChange(event)}>
                          <option value="requested_by_customer">
                            Requested By Customer
                          </option>
                          <option value="duplicate">Duplicate</option>
                          <option value="fraudulent">Fradulent</option>
                        </select>
                      </div>
                      <div className="gap10" />
                    </div>
                  </div>
                  <div
                    className={
                      this.state.internalNotes !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>Notes</label>
                    <input
                      type="text"
                      name="internalNotes"
                      value={this.state.internalNotes}
                      onChange={(event) => this.handleInputChange(event)}
                    />
                  </div>
                </div>
              )}
            </VelocityTransitionGroup>

            <div className="gap10" />
            {this.state.isSubscription &&
              this.state.subscriptionStatus === "Active" && (
                <Checkbox
                  label={"Cancel Subscription"}
                  isChecked={this.state.isCancelSubscription}
                  handleCheckboxChange={this.toggleCheckbox.bind(this)}
                  key={this.state.keyCheckbox}
                />
              )}
          </div>
          <div className="gap10" />

          <div className="alertFooterBtns">
            <a
              onClick={this.onClickCancelFromRefundModal.bind(this)}
              className="btn btn-default dismissThisModal margin-right-10">
              Cancel
            </a>
            {(this.state.refundAction === "yes" ||
              this.state.isSubscription) && (
              <>
                {this.state.processingRefund === false && (
                  <a
                    onClick={this.onClickProcessFromRefundModel.bind(this)}
                    className="btn btn-primary dismissThisModal">
                    {this.state.refundAction === "yes"
                      ? "Process Refund"
                      : "Cancel Subscription"}
                  </a>
                )}
                {this.state.processingRefund === true && (
                  <a className="btn btn-primary dismissThisModal">
                    <img
                      src={`https://${window.location.host}/images/loading3.gif`}
                      alt="loading"
                    />
                    &nbsp;&nbsp; Processing
                  </a>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    );
  }

  onClickRefundHistory(charge) {
    this.setState({ selectedCharge: charge }, () => {
      $(
        ".modalAlertOverlay.refundHistoryModalOverlay,.modalAlert.refundHistoryModal"
      ).fadeIn(200);
    });
  }

  onClickOkFromRefundHistoryModal() {
    $(
      ".modalAlertOverlay.refundHistoryModalOverlay,.modalAlert.refundHistoryModal"
    ).fadeOut(200);
  }

  renderRefundHistoryModal() {
    if (!this.state.selectedCharge.refund_history) {
      return null;
    }
    return (
      <div>
        <div className="modalAlertOverlay refundHistoryModalOverlay" />
        <div className="modalAlert refundHistoryModal">
          <div className="clearfix">
            <div className="pull-left">
              <p>Refund History</p>
            </div>
          </div>

          <div className="container-fluid">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Amount</th>
                  <th>Notes</th>
                </tr>
              </thead>
              <tbody>
                {this.state.selectedCharge.refund_history.map((i) => (
                  <tr key={Math.random()}>
                    <td>{this.formatDate(i.date)}</td>
                    <td>${(parseFloat(i.amount) / 100).toFixed(2)}</td>
                    <td>{i.internal_notes ? i.internal_notes : "-"}</td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="gap10" />

            <div className="alertFooterBtns">
              <a
                onClick={this.onClickOkFromRefundHistoryModal.bind(this)}
                className="btn btn-default dismissThisModal">
                Ok
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderShippingDetailSection() {
    let shippingDetails = this.state.order.shipping_details || {};

    var count = 0;
    for (const key in shippingDetails) {
      if (Object.hasOwnProperty.call(shippingDetails, key)) {
        const element = shippingDetails[key];
        if (element) {
          count++;
        }
      }
    }
    if (Object.keys(shippingDetails).length === 0 || count === 0) {
      return <div></div>;
    }
    return (
      <div>
        <TitleBox
          title="Shipping Details"
          showInfoIcon={false}
          showHelpItems={false}
          showExitLogin={false}
        />
        <div className="gap20" />
        <div className="card">
          <div className="container-fluid">
            <div className="gap10" />
            {shippingDetails.name} <br />
            {shippingDetails.address}
            <br />
            {shippingDetails.city} {shippingDetails.state ? ", " : ""}
            {shippingDetails.state} {shippingDetails.zip}
            <br />
            {shippingDetails.country}
            <br />
            <div className="gap10" />
          </div>
        </div>
      </div>
    );
  }

  renderCustomFieldsSection() {
    let customFields = this.state.order.custom_fields || {};
    if (Object.keys(customFields).length === 0) {
      return <div></div>;
    }

    let retVals = [];
    for (let key in customFields) {
      retVals.push(
        <div>
          <label className="firstLetter">
            <b>{key.replace("-", " ")}</b>
          </label>{" "}
          : <label>{customFields[key]}</label>
        </div>
      );
    }

    return (
      <div>
        <TitleBox
          title="Custom Fields"
          showInfoIcon={false}
          showHelpItems={false}
          showExitLogin={false}
        />
        <div className="gap20" />
        <div className="card">
          <div className="container-fluid">
            <div className="gap10" />
            {retVals}
            <div className="gap10" />
          </div>
        </div>
      </div>
    );
  }

  renderOrderTypeSection() {
    return (
      <div>
        <TitleBox
          title="Order Type"
          showInfoIcon={false}
          showHelpItems={false}
          showExitLogin={false}
        />
        <div className="gap20" />
        <div className="card">
          <div className="container-fluid">
            <div className="gap20" />
            <div className="tabsHolder radioTabs">
              <RadioTag
                onCheckChange={this.onSelectOrderTypeRadio.bind(this)}
                labelList={[
                  { name: "Regular", value: "regular" },
                  {
                    name: "Duplicate",
                    value: "duplicate",
                  },
                  {
                    name: "Bump",
                    value: "bump",
                  },
                  {
                    name: "Upsell",
                    value: "upsell",
                  },
                  {
                    name: "Test",
                    value: "test",
                  },
                ]}
                id={"orderType"}
                selectedList={[this.state.order.order_type]}
                selectType={"value"}
                key={this.state.keyOrderType}
              />
            </div>
            <div className="gap20" />
          </div>
        </div>
      </div>
    );
  }

  renderCustomerNotes() {
    return (
      <div>
        <TitleBox
          title="Customer Notes"
          showInfoIcon={false}
          showHelpItems={false}
          showExitLogin={false}
        />
        <div className="gap20" />
        <div className="card">
          <div className="container-fluid">
            <div className="gap20" />
            <p>{this.state.customerNotes}</p>
            <div className="gap10" />
          </div>
        </div>
      </div>
    );
  }

  renderErrorList() {
    let stripePayment = this.state.stripePayment;
    let errorList = stripePayment.error_list || [];

    if (this.state.isSubscription) {
      errorList = this.state.stripeSubscription.error_list || [];
    }

    if (errorList.length === 0) {
      return;
    }
    return (
      <div>
        <TitleBox
          title="Charge Log"
          showInfoIcon={false}
          showHelpItems={false}
          showExitLogin={false}
        />
        <div className="gap20" />
        <div className="card">
          <div className="container-fluid">
            <div className="gap20" />
            <table className="table table-bordered orderDetailsTable">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Message</th>
                </tr>
              </thead>
              {errorList.map((i) => (
                <tr>
                  <td>
                    <b>{this.formatDate(i.date)}</b>
                  </td>
                  <td>{i.message}</td>
                </tr>
              ))}
            </table>
          </div>
        </div>
      </div>
    );
  }

  renderChargesSection() {
    return (
      <div>
        <TitleBox
          title="Charges and Refunds"
          showInfoIcon={false}
          showHelpItems={false}
          showExitLogin={false}
        />
        <div className="gap20" />
        <div className="card">
          <div className="container-fluid">
            <div className="gap20" />
            <table className="table table-bordered orderDetailsTable">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Amount</th>
                  <th>Refund</th>
                  <th>Action</th>
                  <th>History</th>
                </tr>
              </thead>
              {this.state.charges.map((i) => (
                <tr>
                  <td>
                    <b>{i.createdAt ? this.formatDate(i.createdAt) : "-"}</b>
                  </td>
                  <td>
                    <b className="text-success">
                      ${(parseFloat(i.amount) / 100).toFixed(2)}
                    </b>
                  </td>
                  <td>
                    {i.refund_history && i.refund_history.length > 0 && (
                      <b className="text-danger">
                        $
                        {i.refund_history
                          .map((i) => parseFloat(i.amount) / 100)
                          .reduce((a, b) => {
                            return a + b;
                          }, 0)
                          .toFixed(2)}
                      </b>
                    )}
                  </td>
                  <td>
                    <b>
                      <a
                        onClick={this.onClickRefund.bind(this, i)}
                        className="link">
                        Refund / Cancel
                      </a>
                    </b>
                  </td>
                  <td>
                    <b>
                      {i.refund_history && i.refund_history.length > 0 && (
                        <a
                          onClick={this.onClickRefundHistory.bind(this, i)}
                          className="link">
                          Refund History
                        </a>
                      )}
                    </b>
                  </td>
                </tr>
              ))}
            </table>
          </div>
        </div>
      </div>
    );
  }

  renderCartProductsSection() {
    let cartProducts = this.state.order.cart_products || [];
    if (cartProducts.length === 0) {
      return null;
    }
    return (
      <div>
        <TitleBox
          title="Cart Products"
          showInfoIcon={false}
          showHelpItems={false}
          showExitLogin={false}
        />
        <div className="gap20" />
        <div className="card">
          <div className="container-fluid">
            <div className="gap20" />
            <table className="table table-bordered orderDetailsTable">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Price</th>
                  <th>Quantity</th>
                </tr>
              </thead>
              {cartProducts.map((i) => (
                <tr>
                  <td>{i.name}</td>
                  <td>${parseFloat(i.price).toFixed(2)}</td>
                  <td>{i.qty}</td>
                </tr>
              ))}
            </table>
          </div>
        </div>
      </div>
    );
  }

  onClickDeletePayment(payment, index) {
    this.setState(
      { deleteOption: "single", selectedPaymentIndex: index },
      () => {
        $(
          ".modalAlertOverlay.cancelPaymentOverlay,.modalAlert.cancelPaymentAlert"
        ).fadeIn(200);
      }
    );
  }

  onClickEditPayment(payment, index) {
    this.setState(
      {
        selectedPayment: payment,
        paymentDate: new Date(payment.date),
        paymentAmount: payment.amount,
        selectedPaymentIndex: index,
      },
      () => {
        $(
          ".modalAlertOverlay.editPaymentOverlay,.modalAlert.editPaymentAlert"
        ).fadeIn(200);
        this.setState({ keyPaymentDate: Math.random() });
      }
    );
  }

  onClickCollectPayment(payment, index) {
    this.setState(
      {
        selectedPayment: payment,
        selectedPaymentIndex: index,
      },
      () => {
        $(
          ".modalAlertOverlay.collectPaymentOverlay,.modalAlert.collectPaymentAlert"
        ).fadeIn(200);
      }
    );
  }

  onClickCancelAllPayments() {
    this.setState({ deleteOption: "multiple" }, () => {
      $(
        ".modalAlertOverlay.cancelPaymentOverlay,.modalAlert.cancelPaymentAlert"
      ).fadeIn(200);
    });
  }

  checkPaymentDate(payment) {
    let done = payment.done !== undefined ? payment.done : false;
    return (
      moment().startOf("day").diff(moment(payment.date).startOf("day")) < 0 &&
      !done
    );
  }

  onClickMarkAsPaid() {
    $(
      ".modalAlertOverlay.markAsPaidOverlay,.modalAlert.markAsPaidAlert"
    ).fadeIn(200);
  }

  renderPaymentsSection() {
    let order = this.state.order;
    let dates = order.custom_charge_dates;
    return (
      <div>
        <TitleBox
          title="Edit/Cancel Payments"
          showInfoIcon={false}
          showHelpItems={false}
          showExitLogin={false}
        />
        <div className="gap20" />
        <div className="card">
          <>
            <div className="gap20" />
            <div className="pull-left">
              <div className="row">
                {this.state.stripePayment.status !== "completed" && (
                  <div style={{ marginLeft: 20 }}>
                    <button
                      className="btn btn-primary margin-left-10 pull-left"
                      onClick={this.onClickMarkAsPaid.bind(this)}>
                      Mark As Paid
                    </button>
                  </div>
                )}
                {this.state.stripePayment.status === "retry" && (
                  <button
                    className="btn btn-primary margin-left-10 pull-left"
                    onClick={this.onClickMarkAsActive.bind(this)}>
                    Set As Active
                  </button>
                )}
              </div>
            </div>
          </>

          <div className="container-fluid">
            <div className="gap20" />
            {dates.length > 0 && (
              <table className="table table-bordered orderDetailsTable">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Amount</th>
                    <th>Action</th>
                  </tr>
                </thead>
                {dates.map((element, index) => (
                  <tr>
                    <td>
                      <b>{this.formatDate(element.date)}</b>
                    </td>
                    <td>
                      <b>${element.amount}</b>
                    </td>
                    <td>
                      {this.state.stripePayment.status === "pending" &&
                        this.checkPaymentDate(element) && (
                          <>
                            <button
                              onClick={this.onClickEditPayment.bind(
                                this,
                                element,
                                index
                              )}
                              className="btn btn-default btn-sm border-0 margin-right-10">
                              <i className="fa fa-pencil" />
                            </button>
                            <button
                              onClick={this.onClickDeletePayment.bind(
                                this,
                                element,
                                index
                              )}
                              className="btn btn-danger btn-sm border-0 margin-right-10">
                              <i className="fa fa-trash" />
                            </button>
                          </>
                        )}
                      {(this.state.stripePayment.status === "failed" ||
                        this.state.stripePayment.status === "retry") &&
                        element.done !== true && (
                          <>
                            <button
                              onClick={this.onClickEditPayment.bind(
                                this,
                                element,
                                index
                              )}
                              className="btn btn-default btn-sm border-0 margin-right-10">
                              <i className="fa fa-pencil" />
                            </button>
                            <button
                              onClick={this.onClickDeletePayment.bind(
                                this,
                                element,
                                index
                              )}
                              className="btn btn-danger btn-sm border-0 margin-right-10">
                              <i className="fa fa-trash" />
                            </button>
                          </>
                        )}
                      {element.done !== true &&
                        this.state.stripePayment.status !== "cancelled" && (
                          <button
                            onClick={this.onClickCollectPayment.bind(
                              this,
                              element,
                              index
                            )}
                            className="btn btn-default btn-sm">
                            Collect Payment
                          </button>
                        )}
                    </td>
                  </tr>
                ))}
              </table>
            )}
            <div className="gap20" />
            {/* {this.state.stripePayment.status === "pending" && (
              <div className="text-right">
                <button
                  className="btn btn-danger btn-sm"
                  onClick={this.onClickCancelAllPayments.bind(this)}
                >
                  Cancel All Payments
                </button>
              </div>
            )} */}
            {this.state.stripePayment.status === "pending" && (
              <div className="text-right">
                <button
                  className="btn btn-default btn-sm"
                  onClick={this.onClickAddNewPayment.bind(this)}>
                  Add Another Date
                </button>
              </div>
            )}
            <div className="gap20" />
          </div>
        </div>
      </div>
    );
  }

  async onClickRefreshSubscription(option = 1) {
    let subscription = this.state.stripeSubscription;
    let stripeSubscriptionId = subscription.stripe_subscription_id;
    let data = await callGetStripeSubscription({ subId: stripeSubscriptionId });
    if (data.id) {
      let status = data.status;
      this.setState({ stripeSubscriptionStatus: status });
      if (option === 1) {
        if (status === "canceled") {
          let updatedInfo = await callUpdateStripeSubscription(
            subscription._id,
            {
              status: "cancelled",
            }
          );
          if (updatedInfo._id) {
            this.getCharges();
          }
        }
      }
    }
    if (option === 1) {
      showNotification("success", "Refreshed Successfully");
    }
  }

  onClickCancelSubscription() {
    $(
      ".modalAlertOverlay.cancelSubscriptionOverlay,.modalAlert.cancelSubscriptionAlert"
    ).fadeIn(200);
  }

  async callCancelSubscriptionFromCancelPopup() {
    let updateData = {
      action: "cancel-subscription",
      data: {
        subscriptionId: this.state.stripeSubscription.stripe_subscription_id,
      },
    };
    callCreateStripeCustomer(updateData).then((data) => {
      let subId = this.state.stripeSubscription._id;
      /*eslint-disable*/
      let cancelData = {
        status: "cancelled",
        cancel_member_id: this.state.userData._id,
        cancel_date: new Date(),
      };
      /*eslint-enable*/

      callUpdateStripeSubscription(subId, cancelData).then((d) => {
        this.getCharges();
        showNotification("success", "Cancel subscription done!");
      });
    });
  }

  onClickNoCancelPaymentAlert = () => {
    $(
      ".modalAlertOverlay.cancelPaymentOverlay,.modalAlert.cancelPaymentAlert"
    ).fadeOut(200);
  };

  onClickYesCancelPaymentAlert = () => {
    $(
      ".modalAlertOverlay.cancelPaymentOverlay,.modalAlert.cancelPaymentAlert"
    ).fadeOut(200);
    if (this.state.deleteOption === "single") {
      this.processCancelSinglePayment();
    } else {
      this.processCancelAllPayments();
    }
  };

  onClickNoCancelSubscriptionAlert = () => {
    $(
      ".modalAlertOverlay.cancelSubscriptionOverlay,.modalAlert.cancelSubscriptionAlert"
    ).fadeOut(200);
  };

  onClickYesCancelSubscriptionAlert = () => {
    $(
      ".modalAlertOverlay.cancelSubscriptionOverlay,.modalAlert.cancelSubscriptionAlert"
    ).fadeOut(200);
    this.callCancelSubscriptionFromCancelPopup();
  };

  async processCancelAllPayments() {
    let payment = await callUpdateStripePayment(this.state.stripePayment._id, {
      status: "cancelled",
    });
    if (payment._id) {
      let stripePayment = this.state.stripePayment;
      stripePayment.status = "cancelled";
      this.setState({ stripePayment });
    }
  }

  async onClickResumePayment() {
    let payment = await callUpdateStripePayment(this.state.stripePayment._id, {
      status: "pending",
    });
    if (payment._id) {
      let stripePayment = this.state.stripePayment;
      stripePayment.status = "pending";
      this.setState({ stripePayment });
    }
  }

  async processCancelSinglePayment() {
    let order = this.state.order;
    let dates = order.custom_charge_dates;
    dates.splice(this.state.selectedPaymentIndex, 1);

    let dateObj = this.getCustomChargeDate(dates);

    /*eslint-disable*/
    let data = {
      custom_charge_dates: dateObj[0],
      next_charge_date: dateObj[1],
      total_charges: dates.length,
    };
    /*eslint-enable*/
    let orderInfo = await callUpdateCustomerOrder(order._id, data);

    let chargesLeft = parseInt(this.state.stripePayment.charges_left, 10);
    if (chargesLeft > 0) {
      chargesLeft -= 1;
    }

    let fDates = dateObj[0].filter((d) => {
      return d.done === false;
    });
    if (fDates.length === 0) {
      data["status"] = "completed";
    }

    data["charges_left"] = chargesLeft.toString();

    let paymentInfo = await callUpdateStripePayment(
      this.state.stripePayment._id,
      data
    );

    if (orderInfo._id && paymentInfo._id) {
      order["custom_charge_dates"] = dateObj[0];
      this.setState({ order });
    }
  }

  getCustomChargeDate(dates) {
    let sortedDates = dates.sort((a, b) => {
      return new Date(a.date) - new Date(b.date);
    });

    sortedDates.forEach((chElem) => {
      if (chElem["done"] === undefined) {
        if (new Date(chElem.date).getTime() < new Date().getTime()) {
          chElem["done"] = true;
        } else {
          chElem["done"] = false;
        }
      }
    });

    let fChargeDates = sortedDates.filter((d) => {
      return d.done === false;
    });

    let nextChargeDate = new Date();
    if (fChargeDates.length > 0) {
      nextChargeDate = fChargeDates[0].date;
    }
    return [sortedDates, nextChargeDate];
  }

  async onClickSubmitFromAddPaymentModal() {
    let order = this.state.order;
    let dates = order.custom_charge_dates;

    if (this.state.paymentAmount.trim().length === 0) {
      showNotification("error", "Payment amount is required", 4000);
      return;
    }

    let selectedDate = {};
    selectedDate["date"] = this.state.paymentDate;
    selectedDate["amount"] = this.state.paymentAmount;
    selectedDate["done"] = false;
    dates.push(selectedDate);

    let dateObj = this.getCustomChargeDate(dates);

    /*eslint-disable*/
    let data = {
      custom_charge_dates: dateObj[0],
      next_charge_date: dateObj[1],
    };
    /*eslint-enable*/
    let orderInfo = await callUpdateCustomerOrder(order._id, data);

    let paymentInfo = await callUpdateStripePayment(
      this.state.stripePayment._id,
      data
    );

    if (orderInfo._id && paymentInfo._id) {
      order["custom_charge_dates"] = dateObj[0];
      this.setState({ order }, () => {
        $(
          ".modalAlertOverlay.addPaymentOverlay,.modalAlert.addPaymentAlert"
        ).fadeOut(200);
      });
    }
  }

  onClickCancelFromAddPaymentModal() {
    $(
      ".modalAlertOverlay.addPaymentOverlay,.modalAlert.addPaymentAlert"
    ).fadeOut(200);
  }

  onClickAddNewPayment() {
    $(
      ".modalAlertOverlay.addPaymentOverlay,.modalAlert.addPaymentAlert"
    ).fadeIn(200);
  }

  renderAddPaymentDateModal() {
    if (this.state.order === undefined) {
      return null;
    }

    return (
      <div>
        <div className="modalAlertOverlay addPaymentOverlay" />
        <div className="modalAlert addPaymentAlert">
          <div className="clearfix">
            <div className="pull-left">
              <p>Add Payment</p>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <label className="inputFakeLabelFocussed">Payment Date</label>
                <div>
                  <DatePicker
                    key={this.state.keyPaymentDate}
                    value={this.state.paymentDate}
                    onChange={this.onChangePaymentDate.bind(this)}
                  />
                </div>
              </div>
            </div>
            <div className="gap10" />
            <div className="row">
              <div className="col-sm-6">
                <div
                  className={
                    this.state.paymentAmount !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Payment Amount</label>
                  <input
                    type="number"
                    name="paymentAmount"
                    value={this.state.paymentAmount}
                    onChange={(event) => this.handleInputChange(event)}
                  />
                </div>
              </div>
            </div>

            <div className="gap10" />
            <div className="alertFooterBtns">
              <button
                onClick={this.onClickCancelFromAddPaymentModal.bind(this)}
                className="btn btn-default dismissThisModal margin-right-10">
                Cancel
              </button>
              <button
                onClick={this.onClickSubmitFromAddPaymentModal.bind(this)}
                className="btn btn-primary dismissThisModal">
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  async onClickSubmitFromEditPaymentModal() {
    let index = this.state.selectedPaymentIndex;
    let order = this.state.order;
    let dates = order.custom_charge_dates;

    let selectedDate = dates[index];
    selectedDate["date"] = this.state.paymentDate;
    selectedDate["amount"] = this.state.paymentAmount;
    dates[index] = selectedDate;

    let dateObj = this.getCustomChargeDate(dates);

    /*eslint-disable*/
    let data = {
      custom_charge_dates: dateObj[0],
      next_charge_date: dateObj[1],
    };
    /*eslint-enable*/
    let orderInfo = await callUpdateCustomerOrder(order._id, data);

    let paymentInfo = await callUpdateStripePayment(
      this.state.stripePayment._id,
      data
    );

    if (orderInfo._id && paymentInfo._id) {
      order["custom_charge_dates"] = dateObj[0];
      this.setState({ order }, () => {
        $(
          ".modalAlertOverlay.editPaymentOverlay,.modalAlert.editPaymentAlert"
        ).fadeOut(200);
      });
    }
  }

  onClickCancelFromEditPaymentModal() {
    $(
      ".modalAlertOverlay.editPaymentOverlay,.modalAlert.editPaymentAlert"
    ).fadeOut(200);
  }

  onChangePaymentDate(value) {
    this.setState({
      paymentDate: value,
    });
  }

  onClickAffiliate() {
    this.props.history.push(
      `/admin/edit-user?id=${this.state.affiliateUserInfo._id}`
    );
  }

  onClickAddAffiliate() {
    $(
      ".modalAlertOverlay.addAffiliateOverlay,.modalAlert.addAffiliateAlert"
    ).fadeIn(200);
  }

  async onClickSaveFromAddAffiliateModal() {
    if (!this.state.selectedAffiliateUser.value._id) {
      showNotification("error", "Select affiliate", 4000);
      return;
    }

    if (this.state.affiliateOption === "yes") {
      if (this.state.commissionAmount.trim().length === 0) {
        showNotification("error", "Enter commission amount", 4000);
        return;
      }
    }

    $(
      ".modalAlertOverlay.addAffiliateOverlay,.modalAlert.addAffiliateAlert"
    ).fadeOut(200);

    this.setState({ affAdding: true });
    /*eslint-disable*/
    let updateData = {
      affiliate_id: this.state.selectedAffiliateUser.value._id,
      affiliate_code:
        this.state.selectedAffiliateUser.value.affiliate_details.affiliate_code,
      affiliate_note: `Manually added by ${this.state.userData.name}`,
      affiliate_details: [
        {
          memberId: this.state.selectedAffiliateUser.value._id,
          type: "fixed",
          amount: this.state.commissionAmount,
          level: "level1",
          recurring: true,
          checkoutId: this.state.order.checkout_item_id,
          orderAmount: this.state.order.total_amount,
        },
      ],
    };
    /*eslint-enable*/

    await callUpdateCustomerOrder(this.state.order._id, updateData);

    if (this.state.affiliateOption === "yes") {
      let releaseDate = moment().add(35, "days").toDate();

      /*eslint-disable*/
      let paymentDetails = {
        member_id: this.state.selectedAffiliateUser.value._id,
        amount: this.state.commissionAmount,
        notes: "Affiliate Payment",
        status: "pending",
        processor: "stripe",
        release_date: releaseDate,
        customer_order_id: this.state.order._id,
        affiliate_info: {
          userName: this.state.userData.name,
          checkoutId: this.state.order.checkout_item_id,
          type: "level1",
          orderAmount: this.state.order.total_amount,
        },
      };
      /*eslint-enable*/
      await callCreateMemberPayment(paymentDetails);
    }

    this.getOrderDetails();
    // if commission amount then add to payment with notes
    // refresh get customer order details
  }

  onClickCancelFromAddAffiliateModal() {
    $(
      ".modalAlertOverlay.addAffiliateOverlay,.modalAlert.addAffiliateAlert"
    ).fadeOut(200);
  }

  onSelectAffiliateUser = (user) => {
    this.setState({ selectedAffiliateUser: user });
  };

  renderAddAffiliateModal() {
    return (
      <div>
        <div className="modalAlertOverlay addAffiliateOverlay" />
        <div className="modalAlert addAffiliateAlert">
          <div className="clearfix">
            <div className="pull-left">
              <p>Add Affiliate</p>
            </div>
          </div>
          <div className="container-fluid">
            <TypeableSelect
              key={this.state.keyAffiliateUsers}
              name={"selectedAffiliateUser"}
              placeholder={"Select Affiliate"}
              selected={this.state.selectedAffiliateUser}
              onSelect={this.onSelectAffiliateUser}
              options={this.state.affiliateUsers}
            />
            <div className="gap20" />
            <div>
              <div className="tabsHolder radioTabs">
                <RadioTag
                  onCheckChange={this.onSelectAffiliateOption.bind(this)}
                  labelList={[
                    { name: "Add Affiliate Commission", value: "yes" },
                    { name: "Do Not Add Affiliate Commission", value: "no" },
                  ]}
                  id={"affiliateOption"}
                  selectType={"value"}
                  selectedList={[this.state.affiliateOption]}
                />
              </div>
              <div className="gap20" />
            </div>

            {this.state.affiliateOption === "yes" && (
              <div className="row">
                <div className="col-sm-6">
                  <div
                    className={
                      this.state.commissionAmount !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>Commission Amount</label>
                    <input
                      type="number"
                      name="commissionAmount"
                      value={this.state.commissionAmount}
                      onChange={(event) => this.handleInputChange(event)}
                    />
                  </div>
                </div>
              </div>
            )}
            {this.state.affiliateOption === "no" && (
              <label>No commissions will be added (Now or in the future)</label>
            )}
            <div className="gap20" />

            <div className="alertFooterBtns">
              <button
                onClick={this.onClickCancelFromAddAffiliateModal.bind(this)}
                className="btn btn-default dismissThisModal margin-right-10">
                Cancel
              </button>
              <button
                onClick={this.onClickSaveFromAddAffiliateModal.bind(this)}
                className="btn btn-primary dismissThisModal">
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderEditPaymentModal() {
    if (!this.state.selectedPayment.date) {
      return null;
    }

    if (this.state.order === undefined) {
      return null;
    }

    return (
      <div>
        <div className="modalAlertOverlay editPaymentOverlay" />
        <div className="modalAlert editPaymentAlert">
          <div className="clearfix">
            <div className="pull-left">
              <p>Edit Payment</p>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <label className="inputFakeLabelFocussed">Payment Date</label>
                <div>
                  <DateTimePicker
                    key={this.state.keyPaymentDate}
                    value={this.state.paymentDate}
                    onChange={this.onChangePaymentDate.bind(this)}
                  />
                </div>
              </div>
            </div>
            <div className="gap10" />
            <div className="row">
              <div className="col-sm-6">
                <div
                  className={
                    this.state.paymentAmount !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Payment Amount</label>
                  <input
                    type="number"
                    name="paymentAmount"
                    value={this.state.paymentAmount}
                    onChange={(event) => this.handleInputChange(event)}
                  />
                </div>
              </div>
            </div>

            <div className="gap10" />
            <div className="alertFooterBtns">
              <button
                onClick={this.onClickCancelFromEditPaymentModal.bind(this)}
                className="btn btn-default dismissThisModal margin-right-10">
                Cancel
              </button>
              <button
                onClick={this.onClickSubmitFromEditPaymentModal.bind(this)}
                className="btn btn-primary dismissThisModal">
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderConfirmationAlert() {
    let title = "";
    if (this.state.deleteOption === "single") {
      title = "Are you sure you want to cancel single payment ?";
    } else {
      title = "Are you sure you want to cancel all payments?";
    }
    return (
      <div>
        <div className="modalAlertOverlay cancelPaymentOverlay" />
        <div className="modalAlert cancelPaymentAlert">
          <p>{title}</p>
          <div className="alertFooterBtns">
            <button
              className="btn btn-default closeModal-No margin-right-10"
              onClick={this.onClickNoCancelPaymentAlert}>
              No
            </button>
            <button
              className="btn btn-primary closeModal-Yes"
              onClick={this.onClickYesCancelPaymentAlert}>
              Yes
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderCancelSubscriptionAlert() {
    return (
      <div>
        <div className="modalAlertOverlay cancelSubscriptionOverlay" />
        <div className="modalAlert cancelSubscriptionAlert">
          <p>Are you sure you want to cancel this subscription?</p>
          <div className="alertFooterBtns">
            <button
              className="btn btn-default closeModal-No margin-right-10"
              onClick={this.onClickNoCancelSubscriptionAlert}>
              No
            </button>
            <button
              className="btn btn-primary closeModal-Yes"
              onClick={this.onClickYesCancelSubscriptionAlert}>
              Yes
            </button>
          </div>
        </div>
      </div>
    );
  }

  onClickNoCollectPaymentAlert = () => {
    $(
      ".modalAlertOverlay.collectPaymentOverlay,.modalAlert.collectPaymentAlert"
    ).fadeOut(200);
  };

  onClickYesCollectPaymentAlert = async () => {
    let payment = this.state.selectedPayment;
    let userId = getItem("userId");
    /*eslint-disable*/
    let data = {
      action: "process-upcoming-payment",
      data: {
        date: payment.date,
        payment_id: this.state.stripePayment._id,
        action_user_id: userId,
      },
    };
    /*eslint-enable*/

    $(
      ".modalAlertOverlay.collectPaymentOverlay,.modalAlert.collectPaymentAlert"
    ).fadeOut(200);
    showNotification(
      "info",
      "We will attempt this charge. Please check back in a couple minutes.",
      3000
    );

    let payData = await callProcessUpcomingPayment(data);
    if (payData.status === 0) {
      showNotification("error", payData.message, 5000);
      return;
    } else {
      showNotification("success", "Collect payment process done.", 5000);
      this.getOrderDetails();
    }
  };

  renderCollectPaymentAlert() {
    let title = "Are you sure you would like to collect this payment now?";
    return (
      <div>
        <div className="modalAlertOverlay collectPaymentOverlay" />
        <div className="modalAlert collectPaymentAlert">
          <p>{title}</p>
          <div className="alertFooterBtns">
            <button
              className="btn btn-default closeModal-No margin-right-10"
              onClick={this.onClickNoCollectPaymentAlert}>
              No
            </button>
            <button
              className="btn btn-primary closeModal-Yes"
              onClick={this.onClickYesCollectPaymentAlert}>
              Yes
            </button>
          </div>
        </div>
      </div>
    );
  }

  onClickNoMarkAsPaidAlert = () => {
    $(
      ".modalAlertOverlay.markAsPaidOverlay,.modalAlert.markAsPaidAlert"
    ).fadeOut(200);
  };

  onClickYesMarkAsPaidAlert = async () => {
    await callUpdateStripePayment(this.state.stripePayment._id, {
      status: "completed",
    });
    $(
      ".modalAlertOverlay.markAsPaidOverlay,.modalAlert.markAsPaidAlert"
    ).fadeOut(200);
    this.getOrderDetails();
  };

  onClickMarkAsActive = async () => {
    await callUpdateStripePayment(this.state.stripePayment._id, {
      status: "pending",
    });
    this.getOrderDetails();
  };

  renderMarkAsPaidAlert() {
    let title =
      "The status will be changed to completed and nothing else will be billed on this order?";
    return (
      <div>
        <div className="modalAlertOverlay markAsPaidOverlay" />
        <div className="modalAlert markAsPaidAlert">
          <p>{title}</p>
          <div className="alertFooterBtns">
            <button
              className="btn btn-default closeModal-No margin-right-10"
              onClick={this.onClickNoMarkAsPaidAlert}>
              Cancel
            </button>
            <button
              className="btn btn-primary closeModal-Yes"
              onClick={this.onClickYesMarkAsPaidAlert}>
              Yes
            </button>
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (!this.state.order) {
      return null;
    }
    let order = this.state.order;

    let total = "";
    let chargeAmount = "";
    let intervalType = "";
    let customDates = [];

    if (this.state.isSubscription) {
      if (order.stripe_plans && order.stripe_plans[0]) {
        total = (parseFloat(order.stripe_plans[0].amount) / 100).toFixed(2);
        intervalType = order.stripe_plans[0]["interval_period"];
        chargeAmount = (parseFloat(order.stripe_plans[0].amount) / 100).toFixed(
          2
        );
      }
    } else {
      if (order.total_amount) {
        total = parseFloat(order.total_amount).toFixed(2);
      }
      if (order.charge_amount) {
        chargeAmount = parseFloat(order.charge_amount).toFixed(2);
      }
      if (order.custom_charge_dates) {
        order.custom_charge_dates.forEach((element) => {
          customDates.push(
            <div>
              <label>
                {this.formatDate(element.date)} - ${element.amount}
              </label>
            </div>
          );
        });
      }
    }

    let showAffiliate = getItem("sk") !== "ooo5s4y1dy"; // dont show for ptw

    // enable for only two users Mikelle and Brookes
    if (
      this.state.userData._id === "5d0317bf00aa9d51d8f0b384" ||
      this.state.userData._id === "5d49f8cceb3ec051fb76f56a"
    ) {
      showAffiliate = true;
    }

    let stripeAccount = order.stripe_account || "default";

    let stripeAccounts = this.state.stripeAccounts;
    let fStripeAccount = stripeAccounts.find((d) => d.id === stripeAccount);

    return (
      <div>
        {this.renderRefundModal()}
        {this.renderRefundHistoryModal()}
        {this.renderConfirmationAlert()}
        {this.renderEditPaymentModal()}
        {this.renderAddPaymentDateModal()}
        {this.renderCollectPaymentAlert()}
        {this.renderMarkAsPaidAlert()}
        {this.renderCancelSubscriptionAlert()}
        {this.renderAddAffiliateModal()}
        <div className="container-fluid">
          <TitleBox title="Order Details" showBackBtn={true} />
          <div className="gap20" />
          <div className="card">
            <div className="container-fluid">
              <div className="gap20" />
              {this.state.noOfSubscriptions > 1 && (
                <>
                  <div className="alert alert-danger" role="alert">
                    This order contains two subscriptions. Please contact
                    support for more information.
                  </div>
                </>
              )}
              <table className="table table-bordered orderDetailsTable">
                <tr>
                  <td>Name</td>
                  <td>
                    <b className="firstLetter">
                      {this.state.orderUserData.name}
                    </b>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>Email Address</td>
                  <td>
                    <b>{this.state.orderUserData.email_address}</b>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>Products</td>
                  <td>
                    {order.product_list.map((i) => (
                      <div>
                        <b>{i.name}</b>
                      </div>
                    ))}
                  </td>
                  <td>
                    {order.product_list.map((i) => (
                      <div>
                        <b>
                          {i.start_date ? this.formatDate(i.start_date) : ""}
                        </b>
                      </div>
                    ))}
                  </td>
                </tr>
                {!this.state.isSubscription && (
                  <tr>
                    <td>Payment Status</td>
                    <td>
                      {this.state.stripePayment.status !== "cancelled" &&
                        (this.state.stripePayment.status === "retry" ? (
                          <span className="text-danger firstLetter">Retry</span>
                        ) : (
                          <span className="text-success firstLetter">
                            {this.state.stripePayment.status === "pending"
                              ? "Active"
                              : this.state.stripePayment.status}
                          </span>
                        ))}
                      {this.state.stripePayment.status === "cancelled" && (
                        <span className="text-danger firstLetter">
                          {this.state.stripePayment.status}
                        </span>
                      )}
                    </td>
                    <td>
                      {this.state.stripePayment.status === "failed" && (
                        <button
                          className="btn btn-default btn-sm margin-right-10"
                          onClick={() => this.onClickResumePayment()}>
                          Resume
                        </button>
                      )}
                    </td>
                  </tr>
                )}
                {this.state.stripePayment.status === "retry" && (
                  <tr>
                    <td className="text-danger">Next Retry Date</td>
                    <td className="text-danger">
                      <b>
                        {this.state.stripePayment.next_retry_date
                          ? this.formatDate(
                              this.state.stripePayment.next_retry_date
                            )
                          : "-"}
                      </b>
                    </td>
                    <td />
                  </tr>
                )}
                <tr>
                  <td>Processor</td>
                  <td>
                    <b className="firstLetter">{order.processor}</b>
                  </td>
                  <td />
                </tr>
                <tr>
                  <td>Total</td>
                  <td>
                    <b>{total ? `$${total}` : "-"}</b>
                  </td>
                  <td />
                </tr>
                <tr>
                  <td>Total Charges</td>
                  <td>
                    <b>
                      {order.total_charges
                        ? chargeAmount
                          ? order.total_charges
                          : "-"
                        : "Subscription"}
                    </b>
                  </td>
                  <td>
                    <div>
                      <b className="firstLetter">
                        {intervalType
                          ? intervalType
                          : customDates.length > 0
                          ? customDates
                          : ""}
                      </b>
                    </div>
                  </td>
                </tr>
                {this.state.isSubscription && (
                  <>
                    <tr>
                      <td>Subscription Status</td>
                      <td>
                        <b>
                          {this.state.subscriptionStatus}
                          {this.state.stripeSubscriptionStatus !== "" && (
                            <>
                              {" "}
                              (
                              <label
                                className={
                                  this.state.stripeSubscriptionStatus ===
                                  "canceled"
                                    ? "text-danger"
                                    : "text-success"
                                }>
                                {this.state.stripeSubscriptionStatus} in Stripe
                              </label>
                              )
                            </>
                          )}
                        </b>
                        {(this.state.subscriptionStatus === "Active" ||
                          this.state.stripeSubscriptionStatus === "active") && (
                          <button
                            className="btn btn-default btn-sm margin-right-10 border-0"
                            onClick={() => this.onClickRefreshSubscription()}>
                            <i className="fa fa-refresh" />
                          </button>
                        )}
                      </td>
                      <td>
                        {(this.state.subscriptionStatus !== "Cancelled" ||
                          this.state.stripeSubscriptionStatus !==
                            "canceled") && (
                          <button
                            className="btn btn-danger btn-sm margin-right-10"
                            onClick={() => this.onClickCancelSubscription()}>
                            Cancel
                          </button>
                        )}
                      </td>
                    </tr>
                  </>
                )}
                <tr>
                  <td>Charge Amount</td>
                  <td>
                    <b>{chargeAmount ? `$${chargeAmount}` : "-"}</b>
                  </td>
                  <td />
                </tr>
                <tr>
                  <td>First Charge Date</td>
                  <td>
                    <b>
                      {order.first_charge_date
                        ? this.formatDate(order.first_charge_date)
                        : "-"}
                    </b>
                  </td>
                  <td />
                </tr>
                <tr>
                  <td>Referrer</td>
                  <td>
                    <b>{order.referrer ? order.referrer : "-"}</b>
                  </td>
                  <td />
                </tr>

                <>
                  {this.state.affAdding ? (
                    <tr>
                      <td>Affiliate</td>
                      <td>
                        <b className="firstLetter link pointer-click">
                          Loading...
                        </b>
                      </td>
                      <td></td>
                    </tr>
                  ) : this.state.affiliateUserInfo._id ? (
                    <tr>
                      <td>Affiliate</td>
                      <td onClick={this.onClickAffiliate.bind(this)}>
                        <b className="firstLetter link pointer-click">
                          {this.state.affiliateUserInfo.name}
                        </b>
                      </td>
                      <td>
                        {order.affiliate_code}{" "}
                        {order.affiliate_note
                          ? `(${order.affiliate_note})`
                          : ""}
                      </td>
                    </tr>
                  ) : (
                    showAffiliate && (
                      <tr>
                        <td>Affiliate</td>
                        <td onClick={this.onClickAddAffiliate.bind(this)}>
                          <b className="firstLetter link pointer-click">
                            Add Affiliate
                          </b>
                        </td>
                        <td></td>
                      </tr>
                    )
                  )}
                </>

                <tr>
                  <td>Notes</td>
                  <td>
                    <b>{order.notes ? order.notes : "-"}</b>
                  </td>
                  <td />
                </tr>
                <tr>
                  <td>Stripe Account</td>
                  <td>
                    <b>{fStripeAccount ? fStripeAccount.name : "-"}</b>
                  </td>
                  <td />
                </tr>
              </table>
            </div>
          </div>
          {this.renderShippingDetailSection()}
          {this.renderCustomFieldsSection()}
          {this.renderCartProductsSection()}
          {this.renderCustomerNotes()}
          {this.renderOrderTypeSection()}
          {this.renderErrorList()}

          {this.state.charges.length > 0 && this.renderChargesSection()}
          {this.state.isSubscription === false && this.renderPaymentsSection()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ userData: state.authReducer.userData });

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetails);
