import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import DatePicker from "react-date-picker";
import moment from "moment";
import Checkbox from "./checkBox";

import { showNotification, calculateChlgStartDate } from "../../helpers";

class SelectChallenge extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      from: this.props.from || "",
      challenges: this.props.challenges,
      selectedChallenges: this.props.selectedChallenges || [],
      selectedChallenge: "",
      challengeStartType: "",
      challengeDate: new Date(),
      challengeDays: "",
      ifTagAvailable: false,
      keyIfTagAvailable: Math.random(),
    };
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  onChangeChallengeDate(value) {
    this.setState({
      challengeDate: value,
    });
  }

  onClickRemoveFromSelectedChallenges(i) {
    let selectedChallenges = this.state.selectedChallenges;
    selectedChallenges.splice(i, 1);
    this.setState(
      {
        selectedChallenges: selectedChallenges,
      },
      () => {
        this.props.onChange(this.state.selectedChallenges);
      }
    );
  }

  onClickAddChallenge() {
    if (this.state.selectedChallenge.trim().length === 0) {
      showNotification("error", "Please select a challenge", 4000);
      return;
    }

    if (this.state.challengeStartType.trim().length === 0) {
      showNotification("error", "Please select a challenge start type", 4000);
      return;
    }

    if (this.state.challengeStartType === "days_after") {
      if (this.state.challengeDays.trim().length === 0) {
        showNotification("error", "Please add days after order", 4000);
        return;
      }
    }

    let challenge = this.state.challenges.filter((d) => {
      return d._id === this.state.selectedChallenge;
    });

    let challengeType = "credit";
    let challengeStartType = "Group Credit";
    let challengeDate = "";

    if (this.state.challengeStartType === "select_day") {
      challengeType = "challenge";
      let fDate = moment(this.state.challengeDate)
        .format("MM-DD-YYYY")
        .toString();
      challengeDate = fDate;
      challengeStartType = "";
    } else if (this.state.challengeStartType === "days_after") {
      challengeType = "challenge";
      challengeDate = this.state.challengeDays;
      challengeStartType = "Days After Order";
    } else if (this.state.challengeStartType === "default_day") {
      challengeType = "challenge";
      challengeStartType = "Default Day";
      challengeDate = calculateChlgStartDate(challenge[0].start_day_of_week);
    }

    let challengeInfo = {
      challenge: challenge[0],
      challengeType: challengeType,
      challengeDate: challengeDate,
      challengeStartType: challengeStartType,
      ifTagAvailable: this.state.ifTagAvailable,
    };

    let selectedChallenges = this.state.selectedChallenges;
    selectedChallenges.push(challengeInfo);
    this.setState(
      {
        selectedChallenge: "",
        challengeStartType: "",
        ifTagAvailable: false,
        keyIfTagAvailable: Math.random(),
        selectedChallenges: selectedChallenges,
      },
      () => {
        this.props.onChange(this.state.selectedChallenges);
      }
    );
  }

  renderChallenges() {
    let returnVals = [];
    let challenges = this.state.challenges;
    returnVals.push(<option value={""} key={0} />);
    for (let i = 0; i < challenges.length; i++) {
      returnVals.push(
        <option value={challenges[i]._id} key={i + 1}>
          {challenges[i].name}
        </option>
      );
    }
    return returnVals;
  }

  renderSelectedChallenges() {
    var returnVals = [];
    let selectedChallenges = this.state.selectedChallenges;
    for (let i = 0; i < selectedChallenges.length; i++) {
      const element = selectedChallenges[i];
      returnVals.push(
        <tr key={i}>
          <td className="col-sm-11">
            {element.challenge.name}
            {element.challengeDate !== "" && (
              <span className="subtext"> ({element.challengeDate})</span>
            )}{" "}
            {element.challengeStartType !== "" && (
              <span className="subtext"> - {element.challengeStartType}</span>
            )}
          </td>
          <td>
            <button
              className="text-danger"
              onClick={this.onClickRemoveFromSelectedChallenges.bind(this, i)}>
              REMOVE
            </button>
          </td>
        </tr>
      );
    }
    return returnVals;
  }

  toggleIfTagAvailable = (check) => {
    this.setState({
      ifTagAvailable: check,
    });
  };

  render() {
    return (
      <div>
        <div className="card">
          <div className="list-group list-group-flush">
            <div className="list-group-item">
              <div className="row">
                <div className="col-lg-4">
                  <div
                    className={
                      this.state.selectedChallenge !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>Select Challenge</label>
                    <select
                      name="selectedChallenge"
                      value={this.state.selectedChallenge}
                      onChange={(event) => this.handleInputChange(event)}>
                      {this.renderChallenges()}
                    </select>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div
                    className={
                      this.state.challengeStartType !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>Challenge Start Type</label>
                    <select
                      name="challengeStartType"
                      value={this.state.challengeStartType}
                      onChange={(event) => this.handleInputChange(event)}>
                      <option value="" />
                      <option value="default_day">Default day</option>
                      <option value="select_day">Select a day</option>
                      {this.state.from !== "setup" && (
                        <option value="days_after">Days after Order</option>
                      )}
                      {this.state.from !== "setup" && (
                        <option value="give_credit">Give a Group Credit</option>
                      )}
                    </select>
                  </div>
                </div>
                {this.state.challengeStartType === "select_day" && (
                  <div className="col-lg-4">
                    <label className="inputFakeLabelFocussed">
                      Select Date
                    </label>
                    <div>
                      <DatePicker
                        value={this.state.challengeDate}
                        onChange={this.onChangeChallengeDate.bind(this)}
                      />
                    </div>
                  </div>
                )}
                {this.state.challengeStartType === "days_after" && (
                  <div className="col-lg-4">
                    <div
                      className={
                        this.state.challengeDays !== ""
                          ? "mdInput mdFocussed"
                          : "mdInput"
                      }>
                      <label>Days</label>
                      <input
                        type="text"
                        name="challengeDays"
                        value={this.state.challengeDays}
                        onChange={(event) => this.handleInputChange(event)}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="gap20" />
              {this.state.from === "add-tag" &&
                this.state.selectedChallenge !== "" && (
                  <>
                    <Checkbox
                      label={
                        "Only allow user to have access to this challenge if they have this tag."
                      }
                      isChecked={this.state.ifTagAvailable}
                      handleCheckboxChange={this.toggleIfTagAvailable}
                      key={this.state.keyIfTagAvailable}
                    />
                    <label>
                      (If you check this box then if the user loses the tag they
                      will lose access to the challenge)
                    </label>
                  </>
                )}
              <div className="gap20" />
              <div className="text-right">
                <button
                  className="btn btn-primary"
                  onClick={this.onClickAddChallenge.bind(this)}>
                  Add Challenge
                </button>
              </div>
              <div className="gap10" />
            </div>
          </div>
        </div>
        {this.state.selectedChallenges.length > 0 && (
          <div className="card">
            <div className="list-group list-group-flush">
              <div className="list-group-item">
                <h5 className="noMargin">Added Challenges</h5>
                <div className="gap20" />
                <table className="table table-bordered">
                  <tbody>{this.renderSelectedChallenges()}</tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SelectChallenge);
