import React from "react";
import { VelocityTransitionGroup } from "velocity-react";

require("velocity-animate");
require("velocity-animate/velocity.ui");

class Wizard extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    let children = this.props.children;
    let arrChild = [];
    React.Children.map(children, (child, i) => {
      arrChild.push(child);
    });

    this.state = {
      from: this.props.from || "internal",
      currentChild: this.props.initialStep,
      totalChild: arrChild.length,
      arrChild: arrChild,
    };
  }

  processNavigateBtn(event) {
    let currentChild = this.state.currentChild;
    if (event === "next") {
      currentChild += 1;
    } else {
      currentChild -= 1;
    }

    this.setState({ currentChild: currentChild });
  }

  onClickNavigateBtn(event) {
    if (event === "next" && this.state.from === "form") {
      this.props.onClickNext(this.state.currentChild);
    } else {
      this.processNavigateBtn(event);
    }
  }

  onClickSubmit() {
    this.props.onSubmit(this.state.currentChild);
  }

  render() {
    return (
      <div>
        <VelocityTransitionGroup
          enter={{ animation: "slideDown" }}
          leave={{ animation: "slideUp" }}>
          {React.Children.map(this.props.children, (child, i) => {
            if (this.state.currentChild === i) {
              return child;
            }
          })}
        </VelocityTransitionGroup>
        <div className="gap30" />
        <div className="row">
          <div className="col-6">
            {this.state.currentChild !== 0 && (
              <div className="text-left">
                <button
                  className="btn btn-default"
                  onClick={this.onClickNavigateBtn.bind(this, "back")}>
                  Back
                </button>
              </div>
            )}
          </div>
          <div className="col-6">
            {this.state.currentChild === this.state.totalChild - 1 && (
              <div className="text-right">
                <button
                  className="btn btn-primary"
                  onClick={this.onClickSubmit.bind(this)}>
                  Submit
                </button>
              </div>
            )}
            {this.state.currentChild < this.state.totalChild - 1 && (
              <div className="text-right">
                <button
                  className="btn btn-primary"
                  onClick={this.onClickNavigateBtn.bind(this, "next")}>
                  Next
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="gap20" />
      </div>
    );
  }
}

class Step extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {};
  }

  render() {
    return <div>{this.props.children}</div>;
  }
}

export { Wizard, Step };
