import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import TitleBox from "../../../components/general/titleBox";
import Checkbox from "../../../components/form/checkBox";
import TagSelect from "../../../components/form/tagSelect";
import PageContent from "../../../components/editor/pageContent";

import { callUpdateProcess, callCreateProcess } from "../../../services";
import { adminScreens } from "../../../helpers/routes";
import { showNotification, toPromise, isFS } from "../../../helpers";
import HelpTextDiv from "../../../components/form/helpTextDiv";

class AddProcessPage extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    let aScreens = Object.keys(adminScreens);
    let screenList = [];

    let isFs = isFS();

    aScreens.forEach((element) => {
      if (isFs) {
        screenList.push({
          title: element,
        });
      } else if (
        !element.includes("marci-circle") &&
        !element.includes("custom-program")
      ) {
        screenList.push({
          title: element,
        });
      }
    });

    let process = {
      processId: 0,
      processInfo: {},
      title: "",
      isActive: true,
      statusLabel: "Currently Active",
      pageList: [],
      selectedPages: [],
      content: {},
      html: "",
    };

    if (this.props.history.location.state !== undefined) {
      let pData = this.props.history.location.state.process;

      process.processInfo = pData;
      process.processId = pData._id;
      process.title = pData.title;
      process.isActive = pData.status;
      process.statusLabel = pData.status
        ? "Currently Active"
        : "Currently Inactive";

      let pageList = pData.page_list;
      let selectedPages = [];

      pageList.forEach((element) => {
        selectedPages.push({ title: element });
      });

      process.selectedPages = selectedPages;
      process.pageList = selectedPages;

      process.content = pData.content || {};
      process.html = pData.html || "";
    }

    this.state = {
      processInfo: process.popupInfo,
      processId: process.processId,
      title: process.title,
      isActive: process.isActive,
      statusLabel: process.statusLabel,
      screenList: screenList,
      keyPageTag: Math.random(),
      pageList: process.pageList,
      selectedPages: process.selectedPages,
      content: process.content,
      html: process.html,
    };
  }

  componentWillMount() {
    if (!this.state.isActive && this.state.processId !== 0) {
      showNotification("warning", "This process is currently inactive.", false);
    }
  }

  resetProps() {
    this.setState({
      processId: 0,
      title: "",
      isActive: true,
      statusLabel: "Currently Active",
      keyPageTag: Math.random(),
      pageList: [],
      selectedPages: [],
      content: {},
      html: "",
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  toggleCheckbox = (check) => {
    this.setState({
      isActive: check,
      statusLabel: check ? "Currently Active" : "Currently Inactive",
    });
  };

  onChangePageList = (pages) => {
    this.setState({ pageList: pages });
  };

  onChangeContent(html, content) {
    this.setState({
      content: content,
      html: html,
    });
  }

  async onPressSave() {
    if (this.state.title.trim().length === 0) {
      showNotification("error", "Title is required", 4000);
      return;
    }

    if (this.state.selectedPages.length === 0) {
      showNotification("error", "Atlease one page is required", 4000);
      return;
    }

    /*eslint-disable*/
    let apiData = {
      title: this.state.title,
      page_list: this.state.pageList,
      status: this.state.isActive,
      content: this.state.content,
      html: this.state.html,
    };

    if (this.state.processId !== 0) {
      let [err, data] = await toPromise(
        callUpdateProcess(this.state.processId, apiData)
      );

      /*eslint-enable*/
      if (err) {
        showNotification("error", err.message, 5000);
      } else {
        showNotification("success", "Process updated successfully", 4000);
      }
    } else {
      /*eslint-disable*/
      let [err, data] = await toPromise(callCreateProcess(apiData));
      /*eslint-enable*/
      if (err) {
        showNotification("error", err.message, 5000);
      } else {
        showNotification("success", "Process created successfully", 4000);
        this.resetProps();
      }
    }
  }

  render() {
    return (
      <div>
        <div className="container-fluid">
          <TitleBox
            title={this.state.processId === 0 ? "Add Process" : "Edit Process"}
            showBackBtn={this.state.processId !== 0}
          />
          <div className="gap20" />
          <div className="card">
            <div className="container-fluid">
              <div className="gap20" />

              <div className="havingHelpText">
                <Checkbox
                  label={this.state.statusLabel}
                  isChecked={this.state.isActive}
                  handleCheckboxChange={this.toggleCheckbox.bind(this)}
                  key={"Process Status"}
                />
              </div>
              <HelpTextDiv
                id="idProcessStatus"
                label="Learn more about the Process Status"
                type="addProcess"
                isInline="true"
              />

              <HelpTextDiv
                id="idProcessTitle"
                label="Learn more about the Title"
                type="addProcess"
                isInline="false"
              />
              <div
                className={
                  this.state.title !== "" ? "mdInput mdFocussed" : "mdInput"
                }>
                <label>Title</label>
                <input
                  type="text"
                  name="title"
                  onChange={this.handleInputChange.bind(this)}
                  value={this.state.title}
                />
              </div>
              <div className="gap10" />

              <HelpTextDiv
                id="idProcessAdminPage"
                label="Learn more about the Admin Page"
                type="addProcess"
                isInline="false"
              />
              <TagSelect
                id={"add-process-add-page"}
                key={this.state.keyPageTag}
                selectedValues={this.state.selectedPages}
                searchKey={"title"}
                selectKey={"title"}
                btnText={"Add Page"}
                lblText={"Select Admin Page"}
                data={this.state.screenList}
                onChange={this.onChangePageList}
              />
              <label
                style={{
                  color: "gray",
                  fontSize: 13,
                }}>
                (Note: Start search with "/admin")
              </label>
              <div className="gap20" />
              <PageContent
                title={this.state.title}
                screen={"add-process"}
                html={this.state.html}
                content={this.state.content}
                onChangeData={this.onChangeContent.bind(this)}
                pageName={"addProcess"}
                onClickAlert={this.onPressSave.bind(this)}
              />
            </div>
          </div>
          <div className="text-right container-fluid">
            <button
              className="btn btn-primary"
              onClick={() => this.onPressSave()}>
              {this.state.processId === 0 ? "Create Process" : "Update Process"}
            </button>
          </div>
          <div className="gap20" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddProcessPage);
