import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import images from "../../helpers/images";
import { imageUrl, showNotification, sanitizer } from "../../helpers";
import {
  callAddMemberTag,
  callGetSystemTagUsingName,
  callGetUsersByQuery,
  callProcessTextMessageRule,
  callUpdateMessage,
  uploadImages,
} from "../../services";
import { saveAs } from "file-saver";
import { VelocityTransitionGroup } from "velocity-react";
import TextAreaAutoResize from "../form/textAreaAutoResize";
import PubSub from "pubsub-js";
import { callGetSettings } from "../../services/settingService";
import config from "../../helpers/config";

class MessageBox extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      showReply: this.props.showReply || false,
      reply: "",
      messageId: this.props.messageId || "",
      from: this.props.from || "",
      fromUser: this.props.fromUser || "",
      userData: this.props.userData || {},
      showTrackingDate: this.props.showTrackingDate || false,
      files: [],
      addAttachment: false,
      memberId: this.props.memberId || "",
      staffUsers: [],
      userList: [],
      personalMessageTagId: "",
      responseReceivedTagId: "",
    };
  }

  componentDidMount() {
    this.getData();
  }

  async getData() {
    await this.getSettings();
    this.getTrainerUsers();
    this.callGetTagByName();
  }

  async getSettings() {
    let d = await callGetSettings("messages");
    let settings = d.data;
    let messages = settings.messages || {};
    let staffUsers = messages.staff_users || [];
    this.setState({ staffUsers });
  }

  async getTrainerUsers() {
    /*eslint-disable*/
    let query = {
      query: {
        member_type: "staff",
        $select: ["_id", "name", "email_address"],
      },
    };
    /*eslint-enable*/
    let users = await callGetUsersByQuery(query);
    let userList = [];
    let staffUsers = this.state.staffUsers;
    users.data.forEach((element) => {
      if (
        element._id !== this.state.userData._id &&
        staffUsers.indexOf(element._id) > -1
      ) {
        userList.push({ name: element.name, value: element._id });
      }
    });
    this.setState({ userList });
  }

  callGetTagByName() {
    let personalMessageTag = config.tags.personalMessageTag;

    callGetSystemTagUsingName(personalMessageTag).then((d) => {
      if (d.data.length > 0) {
        this.setState({ personalMessageTagId: d.data[0]._id });
      }
    });

    let responseReceivedTag = config.tags.responseReceivedTag;

    callGetSystemTagUsingName(responseReceivedTag).then((d) => {
      if (d.data.length > 0) {
        this.setState({ responseReceivedTagId: d.data[0]._id });
      }
    });
  }

  componentWillReceiveProps(newProps) {
    if (newProps.userData) {
      this.setState({ userData: newProps.userData });
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  onClickFileUrl(element) {
    if (element.file) {
      saveAs(imageUrl(element.file), element.name);
    } else {
      saveAs(imageUrl(element), element);
    }
  }

  renderAttachments(element) {
    let files = element.files || [];
    let returnVals = [];
    for (let i = 0; i < files.length; i++) {
      const element = files[i];
      returnVals.push(
        <label
          className="link pointer-click margin-right-10"
          onClick={this.onClickFileUrl.bind(this, element)}>
          <u>{element.name ? element.name : element}</u>
        </label>
      );
    }
    return returnVals;
  }

  renderMessages() {
    var returnVals = [];
    let messages = this.props.messages;

    if (this.state.from === "messageList") {
      messages.sort((a, b) => {
        return new Date(b.time) - new Date(a.time);
      });
    }

    for (let i = 0; i < messages.length; i++) {
      const element = messages[i];
      let className =
        element.member_id === this.state.userData._id
          ? "conversationLeft"
          : "conversationRight";

      if (!element.userInfo) {
        continue;
      }

      returnVals.push(
        <div className={className} key={i}>
          {element.userInfo.profile_image_url !== undefined && (
            <div
              className="convImg"
              style={{
                backgroundImage: `url(${imageUrl(
                  element.userInfo.profile_image_url
                )})`,
              }}
            />
          )}
          {element.userInfo.profile_image_url === undefined && (
            <div
              className="userInfoImage"
              style={{
                backgroundImage: `url(${images.userImage})`,
              }}
            />
          )}
          <div className="convContent">
            <div className="convText">
              <b className="convAuthor">{element.userInfo.name}</b>
              <p className="convTextContent">
                <div
                  dangerouslySetInnerHTML={{
                    __html: sanitizer(element.message),
                  }}
                />
              </p>
            </div>

            <div className="cleafix">
              <div className="pull-left">{this.renderAttachments(element)}</div>
              <div className="convTime">
                {moment(element.time).format("MMMM Do, YYYY HH:mm")}
              </div>
              {element.atq_date &&
                this.state.showTrackingDate &&
                className === "conversationRight" && (
                  <div className="convTime">
                    <b>Accountability Date: </b>
                    {moment(element.atq_date).format("MMMM Do, YYYY")}
                  </div>
                )}
            </div>
          </div>
        </div>
      );
    }
    return returnVals;
  }

  handleFileUpload(event) {
    this.setState({ files: event.target.files });
  }

  async onClickSendReply() {
    // send reply

    if (this.state.reply.trim().length === 0) {
      showNotification("error", "Please enter reply", 3000);
      return;
    }

    /*eslint-disable*/

    let fileUrls = [];

    if (this.state.files.length > 0) {
      let resp = await uploadImages(this.state.files);
      let data = await resp.json();
      if (data.file_names) {
        data.file_names.forEach((element) => {
          fileUrls.push({ file: element.key, name: element.originalname });
        });
      }
    }

    let message = {
      member_id: this.state.userData._id,
      message: this.state.reply,
      status: "sent",
      time: new Date(),
      atq_date: new Date(),
      files: fileUrls,
    };

    let dataUpdate = {
      $push: { message_list: message },
    };
    if (this.state.fromUser === "member") {
      dataUpdate["status"] = "open";
      await this.processTextMessageRule();
      await this.callAddStaffTagAPI();
    } else if (this.state.fromUser === "staff") {
      dataUpdate["status"] = "pending";
      if (this.state.memberId) {
        await this.callAddMemberTagAPI(this.state.memberId);
      }
    }

    PubSub.publish("refreshOpenMessages", "");

    await callUpdateMessage(this.state.messageId, dataUpdate);

    showNotification("success", "Message sent successfully", 4000);

    this.props.history.goBack();
    /*eslint-enable*/
  }

  async callAddStaffTagAPI() {
    if (this.state.responseReceivedTagId) {
      for (let i = 0; i < this.state.userList.length; i++) {
        const element = this.state.userList[i];
        let apiData = {
          action: "add-member-tag",
          data: {
            /*eslint-disable*/
            member_id: element.value,
            tag_id: this.state.responseReceivedTagId,
            /*eslint-enable*/
          },
        };
        await callAddMemberTag(apiData);
      }
    }
    this.props.history.goBack();
  }

  callAddMemberTagAPI(memberId) {
    if (this.state.personalMessageTagId) {
      let apiData = {
        action: "add-member-tag",
        data: {
          /*eslint-disable*/
          member_id: memberId,
          tag_id: this.state.personalMessageTagId,
          action: {
            type: "message-box",
            id: getItem("userId"),
            id_type: "user",
            option: "personal-message-tag",
          },
          /*eslint-enable*/
        },
      };
      callAddMemberTag(apiData);
    }
    this.props.history.goBack();
  }

  async processTextMessageRule() {
    let data = {
      action: "process-text-message-rule",
      data: {
        userId: this.state.userData._id,
        message: this.state.reply,
      },
    };

    await callProcessTextMessageRule(data);
  }

  onClickAddAttachment() {
    this.setState({ addAttachment: !this.state.addAttachment });
  }

  renderReplyComponent() {
    return (
      <div className="card">
        <div className="list-group-item responseButtons">
          <div className="mdInput">
            <TextAreaAutoResize
              onRef={(input) => {
                this.iRef = input;
              }}
              label={"Enter your reply"}
              name={"reply"}
              value={this.state.reply}
              onTextChange={this.handleInputChange.bind(this)}
            />
          </div>
          <div className="gap20" />

          <label
            className="link pointer-click margin-right-10"
            onClick={this.onClickAddAttachment.bind(this)}>
            <u>Add Attachments</u>
          </label>
          <br />
          <VelocityTransitionGroup
            enter={{ animation: "slideDown" }}
            leave={{ animation: "slideUp" }}>
            {this.state.addAttachment && (
              <input
                ref={(ref) => (this.fileInput = ref)}
                type="file"
                onChange={this.handleFileUpload.bind(this)}
                multiple
              />
            )}
          </VelocityTransitionGroup>
          <div className="gap20" />
          <div className="text-right">
            <button
              className="btn btn-primary hideResponseBody margin-right-10"
              onClick={this.onClickSendReply.bind(this)}>
              Send
            </button>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.state.showReply && this.renderReplyComponent()}
        <div className="conversationHolder">{this.renderMessages()}</div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.authReducer.userData,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MessageBox);
