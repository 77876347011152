import images from "../../helpers/images";

let classInActive = "button";

let memberMenu = [
  {
    id: "dashboard",
    label: "Dashboard",
    class: classInActive,
    classIdentifier: "dashboard",
    icon: images.admin.home,
    available: true,
    type: "normal",
    screen: "/member",
    subMenu: false,
    menuOptions: [],
  },
  {
    id: "admin-dashboard",
    label: "Admin Dashboard",
    class: classInActive,
    classIdentifier: "",
    icon: images.member.dashboard,
    available: true,
    type: "normal",
    screen: "/admin",
    subMenu: false,
    menuOptions: [],
  },
  {
    id: "company-dashboard",
    label: "Company Dashboard",
    class: classInActive,
    classIdentifier: "",
    icon: images.member.dashboard,
    available: true,
    type: "normal",
    screen: "/company",
    subMenu: false,
    menuOptions: [],
  },
  {
    id: "your-atq",
    label: "Accountability",
    class: classInActive,
    classIdentifier: "atq",
    icon: images.admin.articles,
    available: true,
    type: "normal",
    screen: "/member/atq",
    subMenu: false,
    menuOptions: [],
  },
  {
    id: "messages",
    label: "Messages",
    class: classInActive,
    classIdentifier: "messages",
    icon: images.member.messages,
    available: true,
    type: "normal",
    screen: "/member/messages",
    subMenu: false,
    menuOptions: [],
  },
  {
    id: "resources",
    label: "Resources",
    class: classInActive,
    classIdentifier: "resources",
    icon: images.member.resources,
    available: true,
    type: "normal",
    screen: "/member/resources",
    subMenu: false,
    menuOptions: [],
  },
  {
    id: "tasks",
    label: "Tasks",
    class: "button",
    classIdentifier: "memberTasks",
    icon: images.admin.tasks,
    available: true,
    type: "normal",
    screen: "/member/tasks",
    subMenu: false,
    mode: "custom",
    menuOptions: [],
  },
  {
    id: "mc",
    label: "Marci's Circle & Meals",
    class: classInActive,
    classIdentifier: "marciCircle",
    icon: images.admin.marciCircle,
    available: true,
    type: "dropdown",
    subMenu: false,
    menuOptions: [
      {
        id: "mc-home",
        label: "Home",
        class: classInActive,
        classIdentifier: "mcHome",
        icon: images.admin.home,
        available: true,
        type: "normal",
        screen: "/member/marcis-circle/home",
      },
      {
        id: "mc-home",
        label: "Newsletter",
        class: classInActive,
        classIdentifier: "mcNewsletter",
        icon: images.member.newsletter,
        available: true,
        type: "normal",
        screen: "/member/marcis-circle/newsletter",
      },
      {
        id: "mc-meal-plans",
        label: "Meal Plans",
        class: classInActive,
        classIdentifier: "mcMealPlans",
        icon: images.admin.mealPlans,
        available: true,
        type: "normal",
        screen: "/member/marcis-circle/meal-plans",
      },
      {
        id: "mc-movements",
        label: "Movements",
        class: classInActive,
        classIdentifier: "mcMovements",
        icon: images.member.movements,
        available: true,
        type: "normal",
        screen: "/member/movements",
      },
      {
        id: "mc-educations",
        label: "Education",
        class: classInActive,
        classIdentifier: "mcEducations",
        icon: images.member.movements,
        available: true,
        type: "normal",
        screen: "/member/educations",
      },
      {
        id: "mc-trainings",
        label: "Trainings",
        class: classInActive,
        classIdentifier: "mcTrainings",
        icon: images.member.trainings,
        available: true,
        type: "normal",
        screen: "/member/marcis-circle/trainings",
      },
      {
        id: "mc-foam-rolling",
        label: "Foam Rolling",
        class: classInActive,
        classIdentifier: "mcFoamRoller",
        icon: images.member.foamRolling,
        available: true,
        type: "normal",
        screen: "/member/marcis-circle/foam-roller",
      },
      {
        id: "mc-client-splotlights",
        label: "Client Spotlights",
        class: classInActive,
        classIdentifier: "mcNews",
        icon: images.member.spotlight,
        available: true,
        type: "normal",
        screen: "/member/marcis-circle/news",
      },
      {
        id: "mc-savings",
        label: "Savings",
        class: classInActive,
        classIdentifier: "mcSavings",
        icon: images.member.savings,
        available: true,
        type: "normal",
        screen: "/member/marcis-circle/savings",
      },
      {
        id: "mc-podcasts",
        label: "Podcasts",
        class: classInActive,
        classIdentifier: "mcPodcasts",
        icon: images.member.dashboard,
        available: true,
        type: "normal",
        screen: "/member/marcis-circle/podcasts",
      },
      {
        id: "mc-buy-challenge",
        label: "Buy a Challenge",
        class: classInActive,
        classIdentifier: "mcBuyChallenge",
        icon: images.member.dashboard,
        available: true,
        type: "normal",
        screen: "/member/marcis-circle/buy-challenge",
      },
    ],
  },
  {
    id: "mc-hold",
    label: "Marci's Circle",
    class: classInActive,
    classIdentifier: "marciCircle",
    icon: images.admin.marciCircle,
    available: true,
    type: "dropdown",
    subMenu: false,
    menuOptions: [
      {
        id: "mc-home",
        label: "Newsletter",
        class: classInActive,
        classIdentifier: "mcNewsletter",
        icon: images.member.newsletter,
        available: true,
        type: "normal",
        screen: "/member/marcis-circle/newsletter",
      },
    ],
  },
  {
    id: "btp",
    label: "BTP",
    class: classInActive,
    classIdentifier: "btp",
    icon: images.admin.customPrograms,
    available: true,
    type: "normal",
    screen: "/member/btp",
    subMenu: false,
    menuOptions: [],
  },
  {
    id: "charts",
    label: "Charts",
    class: classInActive,
    classIdentifier: "charts",
    icon: images.member.charts,
    available: true,
    type: "normal",
    screen: "/member/charts",
    subMenu: false,
    menuOptions: [],
  },
  {
    id: "progress-journal",
    label: "Progress Journal",
    class: classInActive,
    classIdentifier: "progressJournal",
    icon: images.member.progressJournal,
    available: true,
    type: "dropdown",
    subMenu: false,
    menuOptions: [
      {
        id: "add-progress",
        label: "Add Progress",
        class: classInActive,
        classIdentifier: "addProgressJournal",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/member/add-progress-journal",
      },
      {
        id: "view-progress",
        label: "View Your Journal",
        class: classInActive,
        classIdentifier: "viewProgressJournal",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/member/view-progress-journal",
      },
    ],
  },
  {
    id: "payment-history",
    label: "Payment History",
    class: classInActive,
    classIdentifier: "paymentHistory",
    icon: images.member.paymentHistory,
    available: true,
    type: "normal",
    screen: "/member/payment-history",
    subMenu: false,
    menuOptions: [],
  },
  {
    id: "member-events",
    label: "Events",
    class: classInActive,
    classIdentifier: "memberEvents",
    icon: images.admin.events,
    available: true,
    type: "normal",
    screen: "/member/events",
    subMenu: false,
    menuOptions: [],
  },
  {
    id: "task-payouts",
    label: "Task Payouts",
    class: classInActive,
    classIdentifier: "taskPayouts",
    icon: images.admin.payments,
    available: true,
    type: "normal",
    screen: "/member/task-payouts",
    subMenu: false,
    menuOptions: [],
  },
  {
    id: "member-affiliate",
    label: "Affiliate Details",
    class: classInActive,
    classIdentifier: "memberAffiliate",
    icon: images.admin.users,
    available: true,
    type: "normal",
    screen: "/member/affiliate",
    subMenu: false,
    menuOptions: [],
  },
  {
    id: "member-leads",
    label: "Leads",
    class: classInActive,
    classIdentifier: "leads",
    icon: images.admin.users,
    available: true,
    type: "normal",
    screen: "/member/leads",
    subMenu: false,
    menuOptions: [],
  },
  {
    id: "helpdesk",
    label: "Helpdesk",
    class: classInActive,
    classIdentifier: "helpdesk",
    icon: images.admin.helpdesk,
    available: true,
    type: "normal",
    screen: "",
    subMenu: false,
    menuOptions: [],
  },
  {
    id: "member-billing",
    label: "Billing Information",
    class: classInActive,
    classIdentifier: "memberBilling",
    icon: images.admin.users,
    available: true,
    type: "normal",
    screen: "/member/billing",
    subMenu: false,
    menuOptions: [],
  },
];

let adminMenu = [
  {
    id: "admin-dashboard",
    label: "Dashboard",
    class: classInActive,
    classIdentifier: "dashboard",
    icon: images.admin.home,
    available: true,
    type: "normal",
    screen: "/admin",
    subMenu: false,
    mode: "custom",
    menuOptions: [],
  },
  {
    id: "company-dashboard",
    label: "Company Dashboard",
    class: classInActive,
    classIdentifier: "companyDashboard",
    icon: images.member.dashboard,
    available: true,
    type: "normal",
    screen: "/company",
    subMenu: false,
    mode: "advanced",
    menuOptions: [],
  },
  {
    id: "member-dashboard",
    label: "Member Dashboard",
    class: classInActive,
    classIdentifier: "memberDashboard",
    icon: images.member.dashboard,
    available: true,
    type: "normal",
    screen: "/member",
    subMenu: false,
    mode: "custom",
    menuOptions: [],
  },
  {
    id: "atq-responses",
    label: "Accountability Responses",
    class: classInActive,
    classIdentifier: "accTrackFeedback",
    icon: images.admin.atqResponses,
    available: true,
    type: "normal",
    screen: "/admin/view-atq",
    subMenu: false,
    mode: "custom",
    menuOptions: [],
  },
  {
    id: "tickets",
    label: "Tickets",
    class: classInActive,
    classIdentifier: "",
    icon: images.admin.helpdesk,
    available: true,
    type: "dropdown",
    mode: "advanced",
    subMenu: false,
    menuOptions: [
      {
        id: "add-department",
        label: "Add Department",
        class: classInActive,
        classIdentifier: "addDepartment",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/add-department",
      },
      {
        id: "view-departments",
        label: "View Departments",
        class: classInActive,
        classIdentifier: "viewDepartments",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/view-departments",
      },
      {
        id: "add-ticket",
        label: "Add Ticket",
        class: classInActive,
        classIdentifier: "addTicket",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/add-ticket",
      },
      {
        id: "view-tickets",
        label: "View Tickets",
        class: classInActive,
        classIdentifier: "viewTickets",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/view-tickets",
      },
      {
        id: "view-tickets-settings",
        label: "Settings",
        class: classInActive,
        classIdentifier: "viewTicketsSettings",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/settings-tickets",
      },
    ],
  },
  {
    id: "tasks",
    label: "Tasks",
    class: classInActive,
    classIdentifier: "",
    icon: images.admin.tasks,
    available: true,
    type: "dropdown",
    subMenu: false,
    mode: "advanced",
    menuOptions: [
      {
        id: "view-tasks",
        label: "View Tasks",
        class: classInActive,
        classIdentifier: "viewTasks",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/tasks",
      },
      {
        id: "task-settings",
        label: "Task Settings",
        class: classInActive,
        classIdentifier: "taskSettings",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/task-settings",
      },
    ],
  },
  {
    id: "reports",
    label: "Reports",
    class: classInActive,
    classIdentifier: "reports",
    icon: images.admin.reports,
    available: true,
    type: "normal",
    screen: "/admin/reports",
    subMenu: false,
    mode: "custom",
    menuOptions: [],
  },
  {
    id: "settings",
    label: "Settings",
    class: classInActive,
    classIdentifier: "settings",
    icon: images.admin.settings,
    available: true,
    type: "normal",
    screen: "/admin/settings",
    mode: "custom",
    subMenu: false,
    menuOptions: [],
  },
  {
    id: "mysite",
    label: "Tini Site",
    class: classInActive,
    classIdentifier: "mysite",
    icon: images.admin.tiniSite,
    available: true,
    type: "normal",
    screen: "/admin/coaching-site",
    mode: "custom",
    subMenu: false,
    menuOptions: [],
  },
  {
    id: "tiers",
    label: "Tier Settings",
    class: classInActive,
    classIdentifier: "",
    icon: images.admin.systemUpdates,
    available: true,
    type: "dropdown",
    subMenu: false,
    mode: "advanced",
    menuOptions: [
      {
        id: "add-tier",
        label: "Add Tier",
        class: classInActive,
        classIdentifier: "addTier",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/add-tier",
      },
      {
        id: "view-tiers",
        label: "View Tiers",
        class: classInActive,
        classIdentifier: "viewTiers",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/view-tiers",
      },
    ],
  },
  {
    id: "messages",
    label: "Messages",
    class: classInActive,
    classIdentifier: "",
    icon: images.member.messages,
    available: true,
    type: "dropdown",
    mode: "custom",
    subMenu: false,
    menuOptions: [
      {
        id: "open-messages",
        label: "Open",
        class: classInActive,
        classIdentifier: "openMessages",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/messages/open",
      },
      {
        id: "pending-messages",
        label: "Pending",
        class: classInActive,
        classIdentifier: "pendingMessages",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/messages/pending",
      },
      {
        id: "closed-messages",
        label: "Closed",
        class: classInActive,
        classIdentifier: "closedMessages",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/messages/closed",
      },
    ],
  },
  {
    id: "preview-challenges",
    label: "Challenges",
    class: classInActive,
    classIdentifier: "viewChlgs",
    icon: images.admin.challenges,
    available: true,
    type: "normal",
    mode: "custom",
    screen: "/admin/preview-challenges",
    subMenu: false,
    menuOptions: [],
  },
  {
    id: "registration",
    label: "Registration",
    class: classInActive,
    classIdentifier: "",
    icon: images.admin.companies,
    available: true,
    mode: "advanced",
    type: "dropdown",
    subMenu: false,
    menuOptions: [
      {
        id: "add-registration-page",
        label: "Add Registration Page",
        class: classInActive,
        classIdentifier: "addRegistrationPage",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/add-registration-page",
      },
      {
        id: "view-registration-pages",
        label: "View Registration Pages",
        class: classInActive,
        classIdentifier: "viewRegistrationPages",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/view-registration-pages",
      },
      {
        id: "enter-registration-mode",
        label: "Enter Registration Mode",
        class: classInActive,
        classIdentifier: "enterRegistrationMode",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/enter-registration-mode",
      },
      {
        id: "staff-registration-codes",
        label: "Staff Registration Codes",
        class: classInActive,
        classIdentifier: "staffRegistrationCodes",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/staff-registration-codes",
      },
    ],
  },
  {
    id: "companies",
    label: "Companies",
    class: classInActive,
    classIdentifier: "",
    icon: images.admin.companies,
    available: true,
    type: "dropdown",
    mode: "advanced",
    subMenu: false,
    menuOptions: [
      {
        id: "add-company",
        label: "Add Company",
        class: classInActive,
        classIdentifier: "addCompany",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/add-company",
      },
      {
        id: "view-company",
        label: "View Companies",
        class: classInActive,
        classIdentifier: "viewCompanies",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/view-companies",
      },
      {
        id: "add-license",
        label: "Add License",
        class: classInActive,
        classIdentifier: "addLicense",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/add-license",
      },
      {
        id: "view-license",
        label: "View Licenses",
        class: classInActive,
        classIdentifier: "viewLicenses",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/view-licenses",
      },
    ],
  },

  {
    id: "users",
    label: "Users",
    class: classInActive,
    classIdentifier: "",
    icon: images.admin.users,
    available: true,
    type: "dropdown",
    mode: "custom",
    subMenu: false,
    menuOptions: [
      {
        id: "search-users",
        label: "Search Users",
        class: classInActive,
        classIdentifier: "users",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/users",
      },
      {
        id: "add-new-user",
        label: "Add New User",
        class: classInActive,
        classIdentifier: "addUser",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/add-user",
      },
      {
        id: "add-new-member",
        label: "Add New Member",
        class: classInActive,
        classIdentifier: "addMember",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/add-member",
      },
      {
        id: "import-users",
        label: "Import Users",
        class: classInActive,
        classIdentifier: "importUsers",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/import-users",
      },
      {
        id: "view-dbs",
        label: "Databases",
        class: classInActive,
        classIdentifier: "viewDatabases",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/view-dbs",
      },
      {
        id: "create-demo-account",
        label: "Create Demo Account",
        class: classInActive,
        classIdentifier: "createDemoAccount",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/create-demo-account",
      },
      {
        id: "view-submissions",
        label: "Submissions",
        class: classInActive,
        classIdentifier: "viewSubmissions",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/view-submissions",
      },
    ],
  },
  {
    id: "events",
    label: "Events",
    class: classInActive,
    classIdentifier: "",
    icon: images.admin.events,
    available: true,
    type: "dropdown",
    mode: "advanced",
    subMenu: false,
    menuOptions: [
      {
        id: "add-event",
        label: "Add Event",
        class: classInActive,
        classIdentifier: "addEvent",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/add-event",
      },
      {
        id: "view-events",
        label: "View Events",
        class: classInActive,
        classIdentifier: "viewEvents",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/view-events",
      },
    ],
  },
  {
    id: "leads",
    label: "Leads",
    class: classInActive,
    classIdentifier: "",
    icon: images.admin.leads,
    available: true,
    type: "dropdown",
    mode: "advanced",
    subMenu: false,
    menuOptions: [
      {
        id: "add-lead",
        label: "Add Lead",
        class: classInActive,
        classIdentifier: "addLead",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/add-lead",
      },
      {
        id: "view-leads",
        label: "View Leads",
        class: classInActive,
        classIdentifier: "viewLeads",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/view-leads",
      },
      {
        id: "lead-settings",
        label: "Lead Settings",
        class: classInActive,
        classIdentifier: "leadSettings",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/lead-settings",
      },
    ],
  },
  {
    id: "classrooms",
    label: "Classrooms",
    class: classInActive,
    classIdentifier: "",
    icon: images.admin.events,
    available: false,
    type: "dropdown",
    subMenu: false,
    menuOptions: [
      {
        id: "add-classroom",
        label: "Add Classroom",
        class: classInActive,
        classIdentifier: "addClassroom",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/add-classroom",
      },
      {
        id: "view-classrooms",
        label: "View Classrooms",
        class: classInActive,
        classIdentifier: "viewClassrooms",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/view-classrooms",
      },
    ],
  },
  {
    id: "system-tags",
    label: "Tags",
    class: classInActive,
    classIdentifier: "",
    icon: images.admin.systemTag,
    available: true,
    type: "dropdown",
    mode: "custom",
    subMenu: false,
    menuOptions: [
      {
        id: "add-system-tag",
        label: "Add Tag",
        class: classInActive,
        classIdentifier: "addSystemTag",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/add-system-tag",
      },
      {
        id: "view-system-tags",
        label: "View Tags",
        class: classInActive,
        classIdentifier: "viewSystemTags",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/view-system-tags",
      },
      {
        id: "view-generic-tags",
        label: "View Generic Tags",
        class: classInActive,
        classIdentifier: "viewGenericTags",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/view-generic-tags",
      },
    ],
  },
  {
    id: "invoices",
    label: "Invoices",
    class: classInActive,
    classIdentifier: "",
    icon: images.admin.users,
    available: true,
    type: "dropdown",
    mode: "advanced",
    subMenu: false,
    menuOptions: [
      {
        id: "add-invoice",
        label: "Add Invoice",
        class: classInActive,
        classIdentifier: "addInvoice",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/add-invoice",
      },
      {
        id: "view-invoices",
        label: "View Invoices",
        class: classInActive,
        classIdentifier: "viewInvoices",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/view-invoices",
      },
      {
        id: "invoice-settings",
        label: "Invoice Settings",
        class: classInActive,
        classIdentifier: "invoiceSettings",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/invoice-settings",
      },
    ],
  },
  {
    id: "resources",
    label: "Resources",
    class: classInActive,
    classIdentifier: "",
    icon: images.member.resources,
    available: true,
    type: "dropdown",
    mode: "advanced",
    subMenu: false,
    menuOptions: [
      {
        id: "add-resource",
        label: "Add Resource",
        class: classInActive,
        classIdentifier: "addResource",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/add-resource",
      },
      {
        id: "view-resources",
        label: "View Resources",
        class: classInActive,
        classIdentifier: "viewResources",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/view-resources",
      },
      {
        id: "add-certificate",
        label: "Add Certificate",
        class: classInActive,
        classIdentifier: "addCertificate",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/add-certificate",
      },
      {
        id: "view-certificates",
        label: "View Certificates",
        class: classInActive,
        classIdentifier: "viewCertificates",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/view-certificates",
      },
    ],
  },
  {
    id: "subscription-area",
    label: "Subscription Area",
    class: classInActive,
    classIdentifier: "",
    icon: images.admin.subscriptionArea,
    available: true,
    type: "dropdown",
    mode: "advanced",
    subMenu: false,
    menuOptions: [
      {
        id: "add-subscription-area",
        label: "Add Subscription Area",
        class: classInActive,
        classIdentifier: "addSubscriptionArea",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/add-subscription-area",
      },
      {
        id: "view-subscription-area",
        label: "View Subscription Area",
        class: classInActive,
        classIdentifier: "viewSubscriptionArea",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/view-subscription-area",
      },
      {
        id: "add-newsletter",
        label: "Add Newsletter",
        class: classInActive,
        classIdentifier: "addNewsletter",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/add-newsletter",
      },
      {
        id: "view-newsletters",
        label: "View Newsletters",
        class: classInActive,
        classIdentifier: "viewNewsletters",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/view-newsletters",
      },
      {
        id: "add-meal-plan",
        label: "Add Meal Plan",
        class: classInActive,
        classIdentifier: "addMealPlans",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/add-meal-plan",
      },
      {
        id: "view-meal-plans",
        label: "View Meal Plans",
        class: classInActive,
        classIdentifier: "viewMealPlans",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/view-meal-plans",
      },
      {
        id: "add-podcast",
        label: "Add Podcast",
        class: classInActive,
        classIdentifier: "addPodcast",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/add-podcast",
      },
      {
        id: "view-podcasts",
        label: "View Podcasts",
        class: classInActive,
        classIdentifier: "viewPodcasts",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/view-podcasts",
      },
      {
        id: "add-video",
        label: "Add Video",
        class: classInActive,
        classIdentifier: "addVideo",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/add-video",
      },
      {
        id: "view-videos",
        label: "View Videos",
        class: classInActive,
        classIdentifier: "viewVideos",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/view-videos",
      },
    ],
  },
  {
    id: "accountability",
    label: "Accountability",
    class: classInActive,
    classIdentifier: "",
    icon: images.admin.accountability,
    available: true,
    type: "dropdown",
    mode: "custom",
    subMenu: false,
    menuOptions: [
      {
        id: "add-question",
        label: "Add Question",
        class: classInActive,
        classIdentifier: "addQuestion",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/add-question",
      },
      {
        id: "view-questions",
        label: "View Questions",
        class: classInActive,
        classIdentifier: "viewQuestions",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/view-questions",
      },
    ],
  },
  {
    id: "churn",
    label: "Churn",
    class: classInActive,
    classIdentifier: "",
    icon: images.admin.churn,
    available: true,
    type: "dropdown",
    mode: "advanced",
    subMenu: false,
    menuOptions: [
      {
        id: "add-churn-item",
        label: "Add Churn Item",
        class: classInActive,
        classIdentifier: "addChurnItem",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/add-churn-item",
      },
      {
        id: "view-churn-items",
        label: "View Churn Items",
        class: classInActive,
        classIdentifier: "viewChurnItems",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/view-churn-items",
      },
    ],
  },
  {
    id: "marci-circle",
    label: "Marci's Circle",
    class: classInActive,
    classIdentifier: "",
    icon: images.admin.marciCircle,
    available: true,
    type: "dropdown",
    mode: "advanced",
    subMenu: false,
    menuOptions: [
      {
        id: "add-spotlight",
        label: "Add Spotlight",
        class: classInActive,
        classIdentifier: "addSpotlight",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/add-spotlight",
      },
      {
        id: "view-spotlights",
        label: "View Spotlights",
        class: classInActive,
        classIdentifier: "viewSpotlights",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/view-spotlights",
      },
      {
        id: "atq-tracking",
        label: "Accountability Tracking",
        class: classInActive,
        classIdentifier: "addMcAtqQuestion",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/add-mc-atq-question",
      },
      {
        id: "add-movement",
        label: "Add Movement",
        class: classInActive,
        classIdentifier: "addMovement",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/add-movement",
      },
      {
        id: "view-movements",
        label: "View Movements",
        class: classInActive,
        classIdentifier: "viewMovements",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/view-movements",
      },
      {
        id: "add-education",
        label: "Add Education",
        class: classInActive,
        classIdentifier: "addEducation",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/add-education",
      },
      {
        id: "view-educations",
        label: "View Education",
        class: classInActive,
        classIdentifier: "viewEducations",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/view-educations",
      },
    ],
  },
  {
    id: "challenges",
    label: "Courses / Challenges",
    class: classInActive,
    classIdentifier: "",
    icon: images.admin.course,
    available: true,
    type: "dropdown",
    mode: "custom",
    subMenu: false,
    menuOptions: [
      {
        id: "view-challenges",
        label: "View Challenges",
        class: classInActive,
        classIdentifier: "viewChlgs",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/view-challenges",
      },
      {
        id: "add-challenge",
        label: "Add Challenge",
        class: classInActive,
        classIdentifier: "addChlg",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/add-challenge",
      },
      // {
      //   id: "view-challenges-item",
      //   label: "View Challenge Items",
      //   class: classInActive,
      //   classIdentifier: "viewChlgItems",
      //   icon: images.smIcon5,
      //   available: true,
      //   type: "normal",
      //   screen: "/admin/view-challenge-items",
      // },
      // {
      //   id: "add-challenge-item",
      //   label: "Add Challenge Item",
      //   class: classInActive,
      //   classIdentifier: "addChlgItem",
      //   icon: images.smIcon5,
      //   available: true,
      //   type: "normal",
      //   screen: "/admin/add-challenge-item",
      // },
      {
        id: "view-challenge-group",
        label: "View Group Credits",
        class: classInActive,
        classIdentifier: "viewChlgGroup",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/view-challenge-groups",
      },
      {
        id: "add-challenge-group",
        label: "Add Group Credit",
        class: classInActive,
        classIdentifier: "addChlgGroup",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/add-challenge-group",
      },
    ],
  },
  {
    id: "landing-page",
    label: "Website & Landing Pages",
    class: classInActive,
    classIdentifier: "",
    icon: images.admin.landingPage,
    available: true,
    type: "dropdown",
    subMenu: false,
    mode: "custom",
    menuOptions: [
      {
        id: "add-landing-page",
        label: "Add Landing Page",
        class: classInActive,
        classIdentifier: "addLandingPage",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/add-landing-page",
      },
      {
        id: "view-landing-page",
        label: "View Landing Pages",
        class: classInActive,
        classIdentifier: "viewLandingPages",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/view-landing-pages",
      },
      {
        id: "add-editor-popup",
        label: "Add Popup",
        class: classInActive,
        classIdentifier: "addEditorPopup",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/add-editor-popup",
      },
      {
        id: "view-editor-popups",
        label: "View Popups",
        class: classInActive,
        classIdentifier: "viewEditorPopups",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/view-editor-popups",
      },
      {
        id: "view-custom-sections",
        label: "Custom Sections",
        class: classInActive,
        classIdentifier: "viewCustomSections",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/view-custom-sections",
      },
      {
        id: "landing-website-menu",
        label: "Website",
        class: classInActive,
        classIdentifier: "landingWebsiteMenu",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/landing-website-menu",
      },
    ],
  },
  {
    id: "emails",
    label: "Emails",
    class: classInActive,
    classIdentifier: "",
    icon: images.admin.emails,
    available: true,
    type: "dropdown",
    subMenu: false,
    mode: "custom",
    menuOptions: [
      {
        id: "add-email",
        label: "Add Email",
        class: classInActive,
        classIdentifier: "addEmail",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/add-single-email",
      },
      {
        id: "add-automation-email",
        label: "Add Automation Email",
        class: classInActive,
        classIdentifier: "addAutomationEmail",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/add-automated-email",
      },
      {
        id: "view-emails",
        label: "View Emails",
        class: classInActive,
        classIdentifier: "viewEmails",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/view-emails",
      },
      // {
      //   id: "add-email-template",
      //   label: "Add Template",
      //   class: classInActive,
      //   classIdentifier: "addTemplate",
      //   icon: images.smIcon5,
      //   available: true,
      //   type: "normal",
      //   screen: "/admin/add-email-template",
      // },
      // {
      //   id: "view-email-templates",
      //   label: "View Templates",
      //   class: classInActive,
      //   classIdentifier: "viewEmailTemplates",
      //   icon: images.smIcon5,
      //   available: true,
      //   type: "normal",
      //   screen: "/admin/view-email-templates",
      // },
      {
        id: "email-settings",
        label: "Email Settings",
        class: classInActive,
        classIdentifier: "emailSettings",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/email-settings",
      },
      {
        id: "add-email-sequence",
        label: "Add Email Sequence",
        class: classInActive,
        classIdentifier: "addEmailSequence",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/add-email-sequence",
      },
      {
        id: "view-email-sequences",
        label: "View Email Sequences",
        class: classInActive,
        classIdentifier: "viewEmailSequences",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/view-email-sequences",
      },
    ],
  },
  {
    id: "text-messaging",
    label: "Text Messaging",
    class: classInActive,
    classIdentifier: "",
    icon: images.admin.sms,
    available: true,
    type: "dropdown",
    subMenu: false,
    mode: "advanced",
    menuOptions: [
      {
        id: "add-sms",
        label: "Add SMS",
        class: classInActive,
        classIdentifier: "addSMS",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/add-sms",
      },
      {
        id: "view-sms",
        label: "View SMS",
        class: classInActive,
        classIdentifier: "viewSMS",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/view-sms",
      },
      {
        id: "view-sms-history",
        label: "View History",
        class: classInActive,
        classIdentifier: "viewSMSHistory",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/view-sms-history",
      },
      {
        id: "sms-automation",
        label: "SMS Automation",
        class: classInActive,
        classIdentifier: "smsAutomation",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/sms-automation",
      },
    ],
  },
  {
    id: "notifications",
    label: "Notifications",
    class: classInActive,
    classIdentifier: "",
    icon: images.admin.notification,
    available: true,
    type: "dropdown",
    mode: "advanced",
    subMenu: false,
    menuOptions: [
      {
        id: "add-notification",
        label: "Create New",
        class: classInActive,
        classIdentifier: "addNotification",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/add-notification",
      },
      {
        id: "view-notifications",
        label: "View All",
        class: classInActive,
        classIdentifier: "viewNotifications",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/view-notifications",
      },
    ],
  },
  {
    id: "pdf-templates",
    label: "PDF Templates",
    class: classInActive,
    classIdentifier: "",
    icon: images.admin.pdfTemplate,
    available: true,
    type: "dropdown",
    mode: "advanced",
    subMenu: false,
    menuOptions: [
      {
        id: "add-pdf-template",
        label: "Add New",
        class: classInActive,
        classIdentifier: "addPdfTemplate",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/add-pdf-template",
      },
      {
        id: "view-pdf-templates",
        label: "View All",
        class: classInActive,
        classIdentifier: "viewPdfTemplates",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/view-pdf-templates",
      },
    ],
  },
  {
    id: "popups",
    label: "Pre-Page Content",
    class: classInActive,
    classIdentifier: "",
    icon: images.admin.prepage,
    available: true,
    type: "dropdown",
    mode: "advanced",
    subMenu: false,
    menuOptions: [
      {
        id: "add-popup",
        label: "Add New",
        class: classInActive,
        classIdentifier: "addPopup",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/add-popup",
      },
      {
        id: "view-popups",
        label: "View All",
        class: classInActive,
        classIdentifier: "viewPopups",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/view-popups",
      },
    ],
  },
  // {
  //   id: "editor-popups",
  //   label: "Popups",
  //   class: classInActive,
  //   classIdentifier: "",
  //   icon: images.admin.popup,
  //   available: true,
  //   type: "dropdown",
  //   subMenu: false,
  //   menuOptions: [
  //     {
  //       id: "add-editor-popup",
  //       label: "Add New",
  //       class: classInActive,
  //       classIdentifier: "addEditorPopup",
  //       icon: images.smIcon5,
  //       available: true,
  //       type: "normal",
  //       screen: "/admin/add-editor-popup",
  //     },
  //     {
  //       id: "view-editor-popups",
  //       label: "View All",
  //       class: classInActive,
  //       classIdentifier: "viewEditorPopups",
  //       icon: images.smIcon5,
  //       available: true,
  //       type: "normal",
  //       screen: "/admin/view-editor-popups",
  //     },
  //   ],
  // },
  {
    id: "helpdesk",
    label: "Knowledge Base",
    class: classInActive,
    classIdentifier: "",
    icon: images.admin.helpdesk,
    available: true,
    type: "dropdown",
    mode: "advanced",
    subMenu: false,
    menuOptions: [
      {
        id: "add-help-desk",
        label: "Add Article",
        class: classInActive,
        classIdentifier: "addHelpDesk",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/add-help-desk",
      },
      {
        id: "view-help-desk",
        label: "View Articles",
        class: classInActive,
        classIdentifier: "viewHelpDesk",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/view-help-desk",
      },
      {
        id: "view-help-desk-settings",
        label: "Settings",
        class: classInActive,
        classIdentifier: "viewHelpDeskSettings",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/settings-help-desk",
      },
    ],
  },

  {
    id: "onboarding",
    label: "Onboarding",
    class: classInActive,
    classIdentifier: "",
    icon: images.admin.popup,
    available: true,
    type: "dropdown",
    mode: "advanced",
    subMenu: false,
    menuOptions: [
      {
        id: "add-onboarding",
        label: "Add Onboarding",
        class: classInActive,
        classIdentifier: "addOnboarding",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/add-onboarding",
      },
      {
        id: "view-onboarding",
        label: "View Onboarding",
        class: classInActive,
        classIdentifier: "viewOnboarding",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/view-onboarding",
      },
    ],
  },
  {
    id: "forms",
    label: "Forms",
    class: classInActive,
    classIdentifier: "",
    icon: images.admin.forms,
    available: true,
    type: "dropdown",
    mode: "advanced",
    subMenu: false,
    menuOptions: [
      {
        id: "add-form",
        label: "Add Form",
        class: classInActive,
        classIdentifier: "addForm",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/add-form",
      },
      {
        id: "view-forms",
        label: "View Forms",
        class: classInActive,
        classIdentifier: "viewForms",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/view-forms",
      },
      {
        id: "add-pdf-form",
        label: "Add PDF Form",
        class: classInActive,
        classIdentifier: "addPDFForm",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/add-pdf-form",
      },
      {
        id: "view-pdf-forms",
        label: "View PDF Forms",
        class: classInActive,
        classIdentifier: "viewPDFForms",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/view-pdf-forms",
      },
      {
        id: "add-external-form",
        label: "Add Interactive Form",
        class: classInActive,
        classIdentifier: "addExternalForm",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/add-external-form",
      },
      {
        id: "view-external-forms",
        label: "View Interactive Forms",
        class: classInActive,
        classIdentifier: "viewExternalForms",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/view-external-forms",
      },
    ],
  },
  {
    id: "quiz",
    label: "Quizzes",
    class: classInActive,
    classIdentifier: "",
    icon: images.admin.quiz,
    available: true,
    type: "dropdown",
    subMenu: false,
    mode: "advanced",
    menuOptions: [
      {
        id: "add-quiz",
        label: "Add Quiz",
        class: classInActive,
        classIdentifier: "addQuiz",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/add-quiz",
      },
      {
        id: "view-quizzes",
        label: "View Quizzes",
        class: classInActive,
        classIdentifier: "viewQuizzes",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/view-quizzes",
      },
    ],
  },
  {
    id: "articles",
    label: "Articles",
    class: classInActive,
    classIdentifier: "",
    icon: images.admin.articles,
    available: true,
    type: "dropdown",
    subMenu: false,
    mode: "advanced",
    menuOptions: [
      {
        id: "add-articles",
        label: "Add Articles",
        class: classInActive,
        classIdentifier: "addArticle",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/add-article",
      },
      {
        id: "view-articles",
        label: "View Articles",
        class: classInActive,
        classIdentifier: "viewArticles",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/view-articles",
      },
      {
        id: "view-categories",
        label: "View Categories",
        class: classInActive,
        classIdentifier: "viewArticleCategories",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/view-article-categories",
      },
    ],
  },
  {
    id: "billing",
    label: "Checkout",
    class: classInActive,
    classIdentifier: "",
    icon: images.admin.billing,
    available: true,
    type: "dropdown",
    mode: "custom",
    subMenu: false,
    menuOptions: [
      {
        id: "add-checkout-item",
        label: "Add Checkout Item",
        class: classInActive,
        classIdentifier: "addCheckoutItem",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/add-checkout-item",
      },
      {
        id: "view-checout-items",
        label: "View Checkout Items",
        class: classInActive,
        classIdentifier: "viewCheckoutItems",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/view-checkout-items",
      },
      {
        id: "add-funnel-path",
        label: "Add Funnel Path",
        class: classInActive,
        classIdentifier: "addFunnelPath",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/add-funnel-path",
      },
      {
        id: "view-funnel-paths",
        label: "View Funnel Paths",
        class: classInActive,
        classIdentifier: "viewFunnelPaths",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/view-funnel-paths",
      },
      {
        id: "add-promo-code",
        label: "Add Promo Code",
        class: classInActive,
        classIdentifier: "addPromoCode",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/add-promo-code",
      },
      {
        id: "view-promo-codes",
        label: "View Promo Codes",
        class: classInActive,
        classIdentifier: "viewPromoCodes",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/view-promo-codes",
      },
      {
        id: "add-subscription-plan",
        label: "Add Subscription Plan",
        class: classInActive,
        classIdentifier: "addPlan",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/add-subscription-plan",
      },
      {
        id: "view-subscription-plans",
        label: "View Subscription Plans",
        class: classInActive,
        classIdentifier: "viewPlans",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/view-subscription-plans",
      },
      {
        id: "add-upsell-item",
        label: "Add Upsell Item",
        class: classInActive,
        classIdentifier: "addUpsellItem",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/add-upsell-item",
      },
      {
        id: "view-upsell-items",
        label: "View Upsell Items",
        class: classInActive,
        classIdentifier: "viewUpsellItems",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/view-upsell-items",
      },
      {
        id: "view-promotions",
        label: "View Promotions",
        class: classInActive,
        classIdentifier: "viewPromotions",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/view-promotions",
      },
      {
        id: "add-product",
        label: "Add Product",
        class: classInActive,
        classIdentifier: "addProduct",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/add-product",
      },
      {
        id: "view-upsell-items",
        label: "View Products",
        class: classInActive,
        classIdentifier: "viewProducts",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/view-products",
      },
    ],
  },
  {
    id: "payments",
    label: "Payments",
    class: classInActive,
    classIdentifier: "",
    icon: images.admin.payments,
    available: true,
    type: "dropdown",
    mode: "custom",
    subMenu: false,
    menuOptions: [
      {
        id: "add-bank-transfer",
        label: "Add Bank Transfer",
        class: classInActive,
        classIdentifier: "addBankTransfer",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/add-bank-transfer",
      },
      {
        id: "add-member-payment",
        label: "Add Member Payment",
        class: classInActive,
        classIdentifier: "addMemberPayment",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/add-member-payment",
      },
      {
        id: "view-member-payments",
        label: "View Member Payments",
        class: classInActive,
        classIdentifier: "viewMemberPayments",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/view-member-payments",
      },
      {
        id: "payments-error-logs",
        label: "Error Logs",
        class: classInActive,
        classIdentifier: "viewPaymentsErrorLogs",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/view-payments-error-logs",
      },
    ],
  },
  {
    id: "custom-programs",
    label: "Custom Programs",
    class: classInActive,
    classIdentifier: "",
    icon: images.admin.customPrograms,
    available: true,
    type: "dropdown",
    mode: "advanced",
    subMenu: false,
    menuOptions: [
      {
        id: "view-custom-programs",
        label: "View Custom Programs",
        class: classInActive,
        classIdentifier: "viewCustomPrograms",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/view-custom-programs",
      },
      {
        id: "add-new-program",
        label: "Add New Program",
        class: classInActive,
        classIdentifier: "addCustomProgram",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/add-custom-program",
      },
      {
        id: "view-exercices",
        label: "View Exercises",
        class: classInActive,
        classIdentifier: "viewExercises",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/view-exercises",
      },
      {
        id: "add-exercice",
        label: "Add Exercise",
        class: classInActive,
        classIdentifier: "addExercise",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/add-exercise",
      },
      {
        id: "view-templates",
        label: "View Templates",
        class: classInActive,
        classIdentifier: "viewTemplates",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/view-templates",
      },
      {
        id: "submitted-applications",
        label: "Submitted Applications",
        class: classInActive,
        classIdentifier: "viewApplications",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/view-applications",
      },
    ],
  },
  {
    id: "system-updates",
    label: "System Updates",
    class: classInActive,
    classIdentifier: "",
    icon: images.admin.systemUpdates,
    available: true,
    type: "dropdown",
    mode: "advanced",
    subMenu: false,
    menuOptions: [
      {
        id: "add-system-update",
        label: "Add System Update",
        class: classInActive,
        classIdentifier: "addSystemUpdate",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/add-system-update",
      },
      {
        id: "view-system-updates",
        label: "View System Updates",
        class: classInActive,
        classIdentifier: "viewSystemUpdates",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/view-system-updates",
      },
    ],
  },
  {
    id: "releases",
    label: "Releases",
    class: classInActive,
    classIdentifier: "",
    icon: images.admin.systemUpdates,
    available: true,
    type: "dropdown",
    subMenu: false,
    mode: "advanced",
    menuOptions: [
      {
        id: "add-release",
        label: "Add Release",
        class: classInActive,
        classIdentifier: "addRelease",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/add-release",
      },
      {
        id: "view-releases",
        label: "View Releases",
        class: classInActive,
        classIdentifier: "viewReleases",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/view-releases",
      },
    ],
  },
  {
    id: "processes",
    label: "Processes",
    class: classInActive,
    classIdentifier: "",
    icon: images.admin.process,
    available: true,
    type: "dropdown",
    mode: "advanced",
    subMenu: false,
    menuOptions: [
      {
        id: "add-process",
        label: "Add Process",
        class: classInActive,
        classIdentifier: "addProcess",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/add-process",
      },
      {
        id: "view-processes",
        label: "View Processes",
        class: classInActive,
        classIdentifier: "viewProcesses",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/view-processes",
      },
      {
        id: "add-help-item",
        label: "Add Help Item",
        class: classInActive,
        classIdentifier: "addHelpItem",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/add-help-item",
      },
      {
        id: "view-help-items",
        label: "View Help Items",
        class: classInActive,
        classIdentifier: "viewHelpItems",
        icon: images.smIcon5,
        available: true,
        type: "normal",
        screen: "/admin/view-help-items",
      },
    ],
  },
];

let companyMenu = [
  {
    id: "dashboard",
    label: "Dashboard",
    class: classInActive,
    classIdentifier: "dashboard",
    icon: images.admin.home,
    available: true,
    type: "normal",
    screen: "/company",
    subMenu: false,
    menuOptions: [],
  },
  {
    id: "admin-dashboard",
    label: "Admin Dashboard",
    class: classInActive,
    classIdentifier: "adminDashboard",
    icon: images.member.dashboard,
    available: true,
    type: "normal",
    screen: "/admin",
    subMenu: false,
    menuOptions: [],
  },
  {
    id: "member-dashboard",
    label: "Member Dashboard",
    class: classInActive,
    classIdentifier: "memberDashboard",
    icon: images.member.dashboard,
    available: true,
    type: "normal",
    screen: "/member",
    subMenu: false,
    menuOptions: [],
  },
  {
    id: "detail",
    label: "Detail",
    class: classInActive,
    classIdentifier: "detail",
    icon: images.member.dashboard,
    available: false,
    type: "normal",
    screen: "/company/detail",
    subMenu: false,
    menuOptions: [],
  },
  {
    id: "billing-invoices",
    label: "Billing & Invoices",
    class: classInActive,
    classIdentifier: "billing",
    icon: images.company.companyBilling,
    available: true,
    type: "normal",
    screen: "/company/billing",
    subMenu: false,
    menuOptions: [],
  },
  {
    id: "licenses",
    label: "Licenses",
    class: classInActive,
    classIdentifier: "licenses",
    icon: images.member.dashboard,
    available: false,
    type: "normal",
    screen: "/company/licenses",
    subMenu: false,
    menuOptions: [],
  },
  {
    id: "users",
    label: "Users",
    class: classInActive,
    classIdentifier: "users",
    icon: images.admin.users,
    available: true,
    type: "normal",
    screen: "/company/users",
    subMenu: false,
    menuOptions: [],
  },
];

let registrationMenu = [
  {
    id: "registration",
    label: "Registration",
    class: classInActive,
    classIdentifier: "registration",
    icon: images.admin.home,
    available: true,
    type: "normal",
    screen: "/registration",
    subMenu: false,
    menuOptions: [],
  },
  {
    id: "exit-registration",
    label: "Exit Registration",
    class: classInActive,
    classIdentifier: "exitRegistration",
    icon: images.admin.home,
    available: true,
    type: "normal",
    screen: "/registration/exit",
    subMenu: false,
    menuOptions: [],
  },
];

export { memberMenu, adminMenu, companyMenu, registrationMenu };
