import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { imageUrl, openSelectImageModal } from "../../helpers";

import SelectImage from "../editor/selectImage";

class SelectAddImage extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      class: this.props.class || "addMealPlanPhoto",
      label: this.props.label,
      image: this.props.image || "",
      backColor: this.props.backColor || "",
      showImages: false,
      key: this.props.key,
      type: this.props.type || "type",
      categoryType: this.props.categoryType || "",
      contentType: this.props.contentType || "image",
      file: this.props.file || "",
    };
  }

  removePhoto() {
    this.setState({
      image: "",
    });
    this.props.onChangeImage("");
  }

  removeFile() {
    this.setState({
      file: "",
    });
    this.props.onChangeFile("");
  }

  onPressSelectImage() {
    this.setState({ showImages: true }, () => {
      openSelectImageModal(this.state.type);
    });
  }

  onSelectImage = (img) => {
    if (this.props.onChangeImage) {
      this.setState({ image: img.key }, () => {
        this.props.onChangeImage(img.key);
      });
    }
  };

  onSelectFile = (file) => {
    if (this.props.onChangeFile) {
      this.setState({ file: file.key }, () => {
        this.props.onChangeFile(file.key);
      });
    }
  };

  onSelectVideo = (video) => {
    if (this.props.onChangeVideo) {
      this.props.onChangeVideo(video);
    }
  };

  checkExtension() {
    let file = this.state.file;
    let files = file.split(".");
    let ext = files[files.length - 1];
    return ext;
  }

  render() {
    let contentType = this.state.contentType;
    return (
      <div>
        {contentType === "image" ? (
          <>
            {this.state.image === "" && this.state.categoryType === "" && (
              <div>
                <button
                  className="btn btn-primary"
                  onClick={() => this.onPressSelectImage()}>
                  Select/Add {this.state.label}
                </button>
              </div>
            )}

            {this.state.image !== "" && this.state.categoryType === "" && (
              <div>
                <div className="inputFakeLabel">{this.state.label}</div>
                <div className="row">
                  <div
                    className="col-sm-3"
                    style={{ backgroundColor: this.state.backColor }}>
                    <div className="text-right">
                      {/*eslint-disable-next-line*/}
                      <a onClick={(e) => this.removePhoto()} className="">
                        X
                      </a>
                    </div>
                    <div
                      className={this.state.class}
                      style={{
                        backgroundImage:
                          "url(" + imageUrl(this.state.image) + ")",
                      }}
                    />
                  </div>
                </div>
              </div>
            )}

            {this.state.image !== "" && this.state.categoryType === "branding" && (
              <div>
                <div
                  className="bgSet"
                  style={{ backgroundColor: this.state.backColor }}>
                  <div
                    className="bgSetIn"
                    style={{
                      backgroundImage:
                        "url(" + imageUrl(this.state.image) + ")",
                    }}></div>
                </div>

                {/*eslint-disable-next-line*/}
                <a onClick={(e) => this.removePhoto()} className="removeBgSet">
                  REMOVE
                </a>
              </div>
            )}

            {this.state.image === "" && this.state.categoryType === "branding" && (
              <div>
                <button
                  className="btn btn-primary"
                  onClick={() => this.onPressSelectImage()}>
                  Add Image
                </button>
              </div>
            )}

            {this.state.image !== "" &&
              this.state.categoryType === "userImage" && (
                <div>
                  <div
                    className="userImageHolder"
                    style={{
                      backgroundImage: `url(${imageUrl(this.state.image)})`,
                    }}
                  />

                  {/*eslint-disable-next-line*/}
                  <a
                    onClick={(e) => this.removePhoto()}
                    className="removeBgSet">
                    REMOVE USER IMAGE
                  </a>
                </div>
              )}

            {this.state.image === "" &&
              this.state.categoryType === "userImage" && (
                <div>
                  <button
                    className="btn btn-primary"
                    onClick={() => this.onPressSelectImage()}>
                    Add User Image
                  </button>
                </div>
              )}
          </>
        ) : (
          <div>
            {this.state.file === "" && (
              <div>
                <button
                  className="btn btn-primary"
                  onClick={() => this.onPressSelectImage()}>
                  Select/Add {this.state.label}
                </button>
              </div>
            )}
            {this.state.file !== "" && (
              <div>
                <div className="inputFakeLabel">{this.state.label}:</div>
                <p>{this.state.file}</p>{" "}
                {this.checkExtension() === "mp3" && (
                  <audio controls>
                    <source src={imageUrl(this.state.file)} />
                    Your browser does not support the audio element.
                  </audio>
                )}
                <div className="gap10" />
                <div className="">
                  <button
                    onClick={(e) => this.removeFile()}
                    className="btn btn-default btn-sm">
                    Remove File
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
        <SelectImage
          contentType={this.state.contentType}
          type={this.state.type}
          key={this.state.key}
          showImages={this.state.showImages}
          onSelectImage={this.onSelectImage}
          onSelectFile={this.onSelectFile}
          onSelectVideo={this.onSelectVideo}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SelectAddImage);
