import React from "react";
import $ from "jquery";

import { showNotification } from "../../../../helpers";
import { uploadImage } from "../../../../services";

import { DraftEditor } from "../../../../packages/draft-editor/index";
import { SettingsHelper } from "../../../../helpers/settings";

class AddTaskComment extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    let comment = "";
    let commentJson = {};
    let users = [];
    let id = "";
    let type = this.props.type;
    if (type === "edit") {
      comment = this.props.comment.comment || "";
      commentJson = this.props.comment.json || {};
      users = this.props.comment.users || [];
      id = this.props.comment.id || "";
    }
    this.state = {
      id: id,
      type: type,
      comment: comment,
      commentJson: commentJson,
      fileData: "",
      keyUserList: Math.random(),
      users: users,
      userList: [],
      suggestions: [],
      keyEditor: Math.random(),
      addComment: "Add Comment",
    };
  }

  componentDidMount() {
    let userList = [];
    let staffUsers = this.props.users;
    let suggestions = [];
    staffUsers.forEach((element) => {
      userList.push({ name: element.name, value: element._id });
      suggestions.push({
        text: element.name,
        value: element.name.toLowerCase(),
        url: element._id,
      });
    });
    this.setState({
      userList,
      suggestions,
      keyUserList: Math.random(),
      keyEditor: Math.random(),
    });
    this.getAddCommentName();
  }

  getAddCommentName() {
    let settingsHelper = new SettingsHelper();
    let addComment = settingsHelper.getAddCommentName() || "Add Comment";
    this.setState({
      addComment: addComment,
    });
  }

  onChangeCommentValue(value) {
    this.setState({ comment: value });
  }

  handleFileUpload(event) {
    this.setState({ fileData: event.target.files[0] });
  }

  onSelectUser(item, id) {
    let users = [];

    Object.keys(item).forEach((obj) => {
      users.push(item[obj].value);
    });

    this.setState({
      users: users,
    });
  }

  onClickCancelComment() {
    $(
      ".modalAlertOverlay.editCommentModal,.modalAlert.editCommentAlert"
    ).fadeOut(200);
  }

  async onClickSubmitComment() {
    let comment = this.state.comment;
    if (comment.trim().length === 0) {
      showNotification("error", "Comment is required", 4000);
      return;
    }
    let fileUrl = "";
    if (this.state.fileData) {
      let resp = await uploadImage(this.state.fileData);
      let data = await resp.json();
      if (data.file_name) {
        fileUrl = data.file_name;
      }
    }

    let entityMap = this.state.commentJson.entityMap || {};
    let users = [];
    for (var id in entityMap) {
      if (entityMap.hasOwnProperty(id)) {
        let map = entityMap[id];
        if (map.type === "MENTION") {
          users.push(map.data.url);
        }
      }
    }

    if (this.state.type === "add") {
      this.props.onClickSubmitComment(
        comment,
        this.state.commentJson,
        fileUrl,
        users
      );
    } else {
      this.props.onClickUpdateComment(
        this.state.id,
        comment,
        this.state.commentJson,
        users
      );
    }

    if (this.fileInput) {
      this.fileInput.value = "";
    }
    this.setState({
      comment: "",
      commentJson: {},
      fileData: "",
      users: [],
      keyUserList: Math.random(),
      keyEditor: Math.random(),
    });
  }

  onChangeComment = (html, json) => {
    this.setState({ comment: html, commentJson: json });
  };

  render() {
    return (
      <div>
        {this.state.type === "add" && (
          <>
            <div className="gap10" />
            <h5>{this.state.addComment}</h5>
            <div className="gap10" />
          </>
        )}
        <div className="add-task-comment">
          <DraftEditor
            key={this.state.keyEditor}
            cType="task"
            defaultText="Your Comment"
            textAlign={"left"}
            lineHeight={"inherit"}
            html={this.state.comment}
            json={this.state.commentJson}
            onChange={this.onChangeComment.bind(this)}
            suggestions={this.state.suggestions}
          />
        </div>
        <br />
        {/* <div className="row">
          <div className="col-sm-12">
            <label>Mention User</label>
            <div className="tabsHolder radioTabs">
              <RadioTag
                key={this.state.keyUserList}
                onCheckChange={this.onSelectUser.bind(this)}
                labelList={this.state.userList}
                type={"multiple"}
                id={"watchers"}
                selectedList={this.state.users}
                selectType={"value"}
              />
            </div>
          </div>
        </div> */}
        <div className="gap20" />
        {this.state.type === "add" && (
          <input
            ref={(ref) => (this.fileInput = ref)}
            type="file"
            onChange={this.handleFileUpload.bind(this)}
          />
        )}
        <div className="pull-right">
          {this.state.type === "edit" && (
            <button
              className="btn btn-default margin-right-10"
              onClick={this.onClickCancelComment.bind(this)}>
              Cancel
            </button>
          )}
          <button
            className="btn btn-primary"
            onClick={this.onClickSubmitComment.bind(this)}>
            Submit
          </button>
        </div>
        <div className="gap20" />
        <div className="gap20" />
      </div>
    );
  }
}

export default AddTaskComment;
