import * as api from "./api";
import { callApi } from "../helpers";

function callCreateVideo(data) {
  return callApi(api.service("video").create(data));
}

function callUpdateVideo(id, data) {
  return callApi(api.service("video").patch(id, data));
}

function callGetVideoUsingQuery(query) {
  return callApi(api.service("video").find(query));
}

function callGetVideoById(id) {
  return callApi(api.service("video").get(id));
}

function callDeleteVideoById(id) {
  return callApi(api.service("video").remove(id));
}

export {
  callCreateVideo,
  callUpdateVideo,
  callGetVideoUsingQuery,
  callGetVideoById,
  callDeleteVideoById
};
