import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment";
import Switch from "react-switch";

import FilterList from "../../../components/form/filterList";
import TitleBox from "../../../components/general/titleBox";
import { filterByTags } from "../../../helpers/filters";
import { callGetSpotlights, callUpdateSpotlight } from "../../../services";

class ViewSpotlight extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      spotlights: [],
      spotlightsBackup: [],
      filterList: [],
      dateFilters: [],
      typeFilters: [],
      keyFilter: Math.random()
    };
  }

  componentDidMount() {
    this.callGetSpotlightsAPI();
  }

  callGetSpotlightsAPI() {
    callGetSpotlights().then(d => {
      d.data.forEach(element => {
        element["user_name"] = element.member_info.name;
      });
      this.setState({ spotlights: d.data, spotlightsBackup: d.data });
      this.prepareDataForFilter(d.data);
    });
  }

  prepareDataForFilter(data) {
    let dateFilters = [
      {
        name: "All",
        value: "all",
        type: "selected",
        selected: true
      },
      {
        name: "Last 7 Days",
        value: "-days-7",
        type: "selected",
        selected: false
      },
      {
        name: "Last 30 Days",
        value: "-days-30",
        type: "selected",
        selected: false
      }
    ];

    let types = [
      { name: "All", value: "all", selected: true },
      { name: "Pending", value: "pending", selected: false },
      { name: "Approved", value: "approved", selected: false },
      { name: "Rejected", value: "rejected", selected: false }
    ];

    this.setState({
      typeFilters: types,
      dateFilters: dateFilters,
      keyFilter: Math.random()
    });
  }

  onClickStatus(element, isActive) {
    /*eslint-disable*/
    let data = {
      active: isActive
    };
    /*eslint-enable*/
    callUpdateSpotlight(element._id, data).then(d => {
      this.callGetSpotlightsAPI();
    });
  }

  onClickEditSpotlight = spotlight => {
    this.props.history.push("/admin/add-spotlight", {
      spotlight: spotlight
    });
  };

  filterUpdated(updatedFilter, name, type, searchFields) {
    let filterList = this.state.filterList;
    filterList[name] = {
      name: name,
      type: type,
      searchFields: searchFields,
      values: updatedFilter
    };
    this.setState(
      {
        filterList: filterList
      },
      () => {
        let data = filterByTags(this.state.spotlightsBackup, filterList);
        this.setState({
          spotlights: data
        });
      }
    );
  }

  renderSpotlights() {
    var returnVals = [];
    let items = this.state.spotlights;
    items.forEach(element => {
      returnVals.push(
        <tr key={element._id}>
          <td className="firstLetter">{element.member_info.name}</td>
          <td>
            {moment(element.member_info.createdAt).format("MMMM Do, YYYY")}
          </td>
          <td>{moment(element.release_date).format("MMMM Do, YYYY")}</td>
          <td className="firstLetter">{element.status || ""}</td>
          <td>
            <button
              onClick={this.onClickEditSpotlight.bind(this, element)}
              className="btn btn-default btn-sm border-0">
              <i className="fa fa-pencil" />
            </button>
          </td>
          <td>
            <Switch
              onChange={this.onClickStatus.bind(this, element)}
              checked={element.active}
              onColor={"#26ace2"}
              checkedIcon={false}
              uncheckedIcon={false}
            />
          </td>
        </tr>
      );
    });
    return returnVals;
  }

  renderFilterView() {
    return (
      <>
        <div className="list-group-item">
          <FilterList
            label={"Search Spotlights"}
            name={"search"}
            type={"search"}
            searchFields={["user_name"]}
            onFinish={this.filterUpdated.bind(this)}
          />
        </div>
        <div className="list-group-item">
          <p>Filter by date</p>
          <FilterList
            key={this.state.keyFilter}
            filterList={this.state.dateFilters}
            name={"releaseDates"}
            type={"date"}
            searchFields={["release_date"]}
            onFinish={this.filterUpdated.bind(this)}
          />
        </div>
        <div className="list-group-item">
          <p>Select a status</p>
          <FilterList
            key={this.state.keyFilter}
            filterList={this.state.typeFilters}
            name={"searchStatus"}
            type={"single"}
            searchFields={["status"]}
            onFinish={this.filterUpdated.bind(this)}
          />
        </div>
      </>
    );
  }

  render() {
    return (
      <div>
        <div className="container-fluid">
          <TitleBox title={"Spotlight Overview"} />
          <div className="gap20" />
          <div className="card">
            <div className="list-group list-group-flush">
              {this.renderFilterView()}
              <div className="list-group-item">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Member</th>
                      <th>Member Since</th>
                      <th>Release Date</th>
                      <th>Status</th>
                      <th>Details</th>
                      <th>Active</th>
                    </tr>
                  </thead>
                  <tbody>{this.renderSpotlights()}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewSpotlight);
