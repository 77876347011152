import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

class VideoPlayerNote extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      videoPlayer: this.props.videoPlayer,
    };
  }

  render() {
    let videoPlayer = this.state.videoPlayer;
    let note = "";
    switch (videoPlayer) {
      case "Youtube":
        note =
          "For example in this video https://www.youtube.com/watch?v=LXb3EKWsLnQ the id is LXb3EKWsLnQ";
        break;
      case "vooplayer":
        note =
          "For example in this video https://videos.cdn.vooplayer.com/publish/NTQwMTA= the id is NTQwMTA=";
        break;
      case "Vimeo":
        note =
          "For example in this video https://player.vimeo.com/video/378311326 the id is 378311326";
        break;
      default:
        break;
    }
    return (
      <div className="row">
        <div className="col-sm-6">
          {videoPlayer !== "memberpages" && (
            <p>Note: The video id can be found from the url.</p>
          )}
          <p>{note}</p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(VideoPlayerNote);
