import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { arrayMove } from "react-sortable-hoc";

import TitleBox from "../../../components/general/titleBox";
import TypeableSelect from "../../../components/form/typeableSelect";
import TagSelect from "../../../components/form/tagSelect";
import HelpTextDiv from "../../../components/form/helpTextDiv";

import {
  getCheckoutItems,
  getUpsellItems,
  getSystemTags,
  createFunnelPath,
  updateFunnelPath,
  resetStatus,
} from "../../../modules/actions/index";
import { showNotification } from "../../../helpers";
import { SortablePathList } from "../../../components/form/sortableList";

class AddFunnelPath extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    let path = {
      _id: 0,
      name: "",
      description: "",
      selectedCheckoutItem: { value: "", label: "" },
      paths: [],
      selectedUpsellItem: { value: "", label: "" },
      selectedIncludedTags: [],
      selectedExcludedTags: [],
      includedTags: [],
      excludedTags: [],
      keyCheckoutItems: Math.random(),
      keyUpsellItems: Math.random(),
      keyIncludeTag: Math.random(),
      keyExcludeTag: Math.random(),
    };

    let type = "";

    if (this.props.history.location.state !== undefined) {
      let fPath = this.props.history.location.state.funnelPath;
      let systemTags = this.props.history.location.state.systemTags;
      type = this.props.history.location.state.type;

      path._id = fPath._id;
      path.name = fPath.name;
      path.description = fPath.description;

      if (type === "clone") {
        path.selectedCheckoutItem = { value: "", label: "" };
      } else {
        path.selectedCheckoutItem = {
          value: fPath.checkout_item,
          label: `${fPath.checkout_item.name} (${fPath.checkout_item.checkout_id})`,
        };
      }

      let paths = [];

      for (let i = 0; i < fPath.paths.length; i++) {
        const element = fPath.paths[i];
        let hasTags = [];
        let hasNotTags = [];
        element.has_tags.forEach((element) => {
          let fTag = systemTags.filter((d) => {
            return d._id === element;
          });
          if (fTag.length > 0) {
            hasTags.push(fTag[0]);
          }
        });
        element.not_has_tags.forEach((element) => {
          let fTag = systemTags.filter((d) => {
            return d._id === element;
          });
          if (fTag.length > 0) {
            hasNotTags.push(fTag[0]);
          }
        });
        /*eslint-disable*/
        element.has_tags = hasTags;
        element.not_has_tags = hasNotTags;
        /*eslint-enable*/
        paths.push(element);
      }

      path.paths = paths;
    }

    this.state = {
      type: type,
      pathId: path._id,
      name: path.name,
      description: path.description,
      selectedCheckoutItem: path.selectedCheckoutItem,
      checkoutItems: [],
      upsellItems: [],
      paths: path.paths,
      systemTags: [],
      selectedUpsellItem: path.selectedUpsellItem,
      selectedIncludedTags: path.selectedIncludedTags,
      selectedExcludedTags: path.selectedExcludedTags,
      includedTags: path.includedTags,
      excludedTags: path.excludedTags,
      keyCheckoutItems: path.keyCheckoutItems,
      keyUpsellItems: path.keyUpsellItems,
      keyIncludeTag: path.keyIncludeTag,
      keyExcludeTag: path.keyExcludeTag,
      selectedEditPathIndex: -1,
    };
  }

  componentDidMount() {
    this.props.getCheckoutItems();
    this.props.getUpsellItems();
    this.props.getSystemTags();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.message) {
      if (newProps.success) {
        if (this.state.pathId === 0) {
          this.resetProps();
        }

        showNotification("success", newProps.message, 5000);
      } else {
        showNotification("error", newProps.message, 5000);
      }
      this.props.resetStatus();
    }

    if (newProps.checkoutItems.data) {
      let checkoutItems = newProps.checkoutItems.data;
      let items = [];
      checkoutItems.forEach((element) => {
        items.push({
          value: element,
          label: `${element.name} (${element.checkout_id})`,
        });
      });
      this.setState({
        checkoutItems: items,
        keyCheckoutItems: Math.random(),
      });
    }

    if (newProps.upsellItems.data) {
      let upsellItems = newProps.upsellItems.data;
      let items = [];
      upsellItems.forEach((element) => {
        items.push({ value: element, label: element.name });
      });
      this.setState({
        upsellItems: items,
        keyUpsellItems: Math.random(),
      });
    }

    if (newProps.systemTags.data) {
      this.setState({
        systemTags: newProps.systemTags.data,
        keyIncludeTag: Math.random(),
        keyExcludeTag: Math.random(),
      });
    }
  }

  resetProps() {
    this.setState({
      name: "",
      description: "",
      selectedCheckoutItem: { value: "", label: "" },
      paths: [],
      selectedUpsellItem: { value: "", label: "" },
      selectedIncludedTags: [],
      selectedExcludedTags: [],
      includedTags: [],
      excludedTags: [],
      keyCheckoutItems: Math.random(),
      keyUpsellItems: Math.random(),
      keyIncludeTag: Math.random(),
      keyExcludeTag: Math.random(),
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  onSelectCheckoutItem = (item) => {
    this.setState({ selectedCheckoutItem: item });
  };

  onSelectUpsellItem = (item) => {
    this.setState({ selectedUpsellItem: item });
  };

  onSelectPreviousUpsell(item, id) {
    this.setState({ [id]: item[Object.keys(item)[0]].value });
  }

  onChangeIncludedTags = (tags) => {
    this.setState({ includedTags: tags });
  };

  onChangeExcludedTags = (tags) => {
    this.setState({ excludedTags: tags });
  };

  onSortPathEnd = ({ oldIndex, newIndex }) => {
    let paths = this.state.paths;
    paths = arrayMove(paths, oldIndex, newIndex);

    this.setState({
      paths: paths,
    });
  };

  onPressRemovePath(e) {
    let index = e.target.dataset["value"];
    let paths = this.state.paths;
    paths.splice(index, 1);
    this.setState({
      paths: paths,
    });
  }

  onPressEditPath(e) {
    let index = e.target.dataset["value"];
    let paths = this.state.paths;

    let path = paths[index];

    let selectedUpsellItem = this.state.upsellItems.filter((d) => {
      return d.label === path.upsell_item_name;
    })[0];

    this.setState({
      includedTags: path.has_tags,
      excludedTags: path.not_has_tags,
      selectedIncludedTags: path.has_tags,
      selectedExcludedTags: path.not_has_tags,
      selectedUpsellItem: selectedUpsellItem,
      keyUpsellItems: Math.random(),
      keyIncludeTag: Math.random(),
      keyExcludeTag: Math.random(),
      selectedEditPathIndex: index,
    });
  }

  onClickAddPath() {
    if (this.state.selectedUpsellItem.label.trim().length === 0) {
      showNotification("error", "Select a upsell item", 4000);
      return;
    }

    /*eslint-disable*/

    let path = {
      has_tags: this.state.includedTags,
      not_has_tags: this.state.excludedTags,
      upsell_item_id: this.state.selectedUpsellItem.value._id,
      upsell_item_name: this.state.selectedUpsellItem.value.name,
      upsell_item_description: this.state.selectedUpsellItem.value.description,
    };
    /*eslint-enable*/

    let paths = this.state.paths;
    if (this.state.selectedEditPathIndex >= 0) {
      paths[this.state.selectedEditPathIndex] = path;
    } else {
      paths.push(path);
    }

    this.setState({
      paths: paths,
      includedTags: [],
      selectedExcludedTags: [],
      selectedIncludedTags: [],
      excludedTags: [],
      keyIncludeTag: Math.random(),
      keyExcludeTag: Math.random(),
      keyUpsellItems: Math.random(),
      selectedUpsellItem: { value: "", label: "" },
      selectedEditPathIndex: -1,
    });
  }

  onPressSaveFunnelPath() {
    if (this.state.name.trim().length === 0) {
      showNotification("error", "Name is required", 4000);
      return;
    }

    if (this.state.description.trim().length === 0) {
      showNotification("error", "Description is required", 4000);
      return;
    }

    if (this.state.selectedCheckoutItem.label.trim().length === 0) {
      showNotification("error", "Select a checkout item", 4000);
      return;
    }

    if (this.state.paths.length === 0) {
      showNotification("error", "Add atleast one path", 4000);
      return;
    }

    /*eslint-disable*/

    let paths = [];
    let allPaths = this.state.paths;

    for (let i = 0; i < allPaths.length; i++) {
      const element = allPaths[i];
      const newElement = {};
      newElement.upsell_item_id = element.upsell_item_id;
      newElement.upsell_item_name = element.upsell_item_name;
      newElement.upsell_item_description = element.upsell_item_description;
      newElement.has_tags = element.has_tags.map((i) => i._id);
      newElement.not_has_tags = element.not_has_tags.map((i) => i._id);
      paths.push(newElement);
    }

    let apiData = {
      name: this.state.name,
      description: this.state.description,
      checkout_item_id: this.state.selectedCheckoutItem.value.checkout_id,
      paths: paths,
    };
    /*eslint-enable*/

    if (this.state.pathId === 0) {
      this.props.createFunnelPath(apiData);
    } else {
      if (this.state.type === "clone") {
        this.props.createFunnelPath(apiData);
      } else {
        this.props.updateFunnelPath(this.state.pathId, apiData);
      }
    }
  }

  render() {
    return (
      <div>
        <div className="container-fluid">
          <TitleBox
            title={
              this.state.pathId === 0
                ? "Add New Funnel Path"
                : this.state.type === "clone"
                ? "Add New Funnel Path"
                : "Edit Funnel Path"
            }
            showBackBtn={this.state.pathId !== 0}
          />
          <div className="gap20" />
          <div className="card">
            <div className="container-fluid">
              <div className="gap10" />

              <HelpTextDiv
                id="idFunnelPathName"
                label="Learn more about the Name"
                type="addFunnelPath"
                isInline="false"
              />

              <div
                className={
                  this.state.name !== "" ? "mdInput mdFocussed" : "mdInput"
                }>
                <label>Name</label>
                <input
                  type="text"
                  name="name"
                  value={this.state.name}
                  onChange={(event) => this.handleInputChange(event)}
                />
              </div>
              <div className="gap20" />

              <HelpTextDiv
                id="idFunnelPathDescription"
                label="Learn more about the Description"
                type="addFunnelPath"
                isInline="false"
              />

              <div
                className={
                  this.state.description !== ""
                    ? "mdInput mdFocussed"
                    : "mdInput"
                }>
                <label>Description</label>
                <input
                  type="text"
                  name="description"
                  value={this.state.description}
                  onChange={(event) => this.handleInputChange(event)}
                />
              </div>
              <div className="gap20" />

              <HelpTextDiv
                id="idFunnelPathCheckoutItems"
                label="Learn more about the Checkout Items"
                type="addFunnelPath"
                isInline="false"
              />

              <TypeableSelect
                key={this.state.keyCheckoutItems}
                name={"checkoutItemId"}
                placeholder={"Select Checkout Item"}
                selected={this.state.selectedCheckoutItem}
                onSelect={this.onSelectCheckoutItem}
                options={this.state.checkoutItems}
              />
              <div className="gap20" />
              <div className="card">
                <div className="container-fluid">
                  <div className="gap20" />

                  <HelpTextDiv
                    id="idFunnelPathIncludedTags"
                    label="Learn more about the Included Tags"
                    type="addFunnelPath"
                    isInline="false"
                  />

                  <TagSelect
                    id={"add-funnel-path-include-tag"}
                    key={this.state.keyIncludeTag}
                    selectedValues={this.state.selectedIncludedTags}
                    searchKey={"name"}
                    selectKey={"name"}
                    btnText={"Add"}
                    lblText={"Included Tags"}
                    data={this.state.systemTags}
                    onChange={this.onChangeIncludedTags}
                  />
                  <div className="gap20" />

                  <HelpTextDiv
                    id="idFunnelPathExcludedTags"
                    label="Learn more about the Excluded Tags"
                    type="addFunnelPath"
                    isInline="false"
                  />

                  <TagSelect
                    id={"add-funnel-path-excluded-tags"}
                    key={this.state.keyExcludeTag}
                    selectedValues={this.state.selectedExcludedTags}
                    searchKey={"name"}
                    selectKey={"name"}
                    btnText={"Add"}
                    lblText={"Excluded Tags"}
                    data={this.state.systemTags}
                    onChange={this.onChangeExcludedTags}
                  />
                  <div className="gap20" />

                  <HelpTextDiv
                    id="idFunnelPathUpsellItem"
                    label="Learn more about the Upsell Item"
                    type="addFunnelPath"
                    isInline="false"
                  />

                  <TypeableSelect
                    key={this.state.keyUpsellItems}
                    name={"upsellItemId"}
                    placeholder={"Select Upsell Item"}
                    selected={this.state.selectedUpsellItem}
                    onSelect={this.onSelectUpsellItem}
                    options={this.state.upsellItems}
                  />
                  <div className="gap20" />
                  <div
                    className="text-right"
                    onClick={() => this.onClickAddPath()}>
                    {/*eslint-disable-next-line*/}
                    <a className="btn btn-primary addVideoButton">
                      {this.state.selectedEditPathIndex >= 0
                        ? "Update Path"
                        : "Add Path"}
                    </a>
                  </div>
                  <div className="gap20" />
                </div>
                {this.state.paths.length > 0 && (
                  <div className="container-fluid">
                    <div className="card">
                      <div className="container-fluid">
                        <div className="gap20" />
                        <h5 className="noMargin">Added Paths</h5>
                        <div className="gap20" />
                        <SortablePathList
                          items={this.state.paths}
                          onSortEnd={this.onSortPathEnd}
                          showEdit={true}
                          onPressRemove={this.onPressRemovePath.bind(this)}
                          onPressEdit={this.onPressEditPath.bind(this)}
                        />
                        <div className="gap20" />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="text-right container-fluid">
          <button
            className="btn btn-primary"
            onClick={() => this.onPressSaveFunnelPath()}>
            Save Funnel Path
          </button>
        </div>
        <div className="gap20" />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  message: state.billingReducer.message,
  success: state.billingReducer.success,
  checkoutItems: state.billingReducer.checkoutItems,
  upsellItems: state.billingReducer.upsellItems,
  systemTags: state.tagReducer.systemTags,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getCheckoutItems,
      getUpsellItems,
      getSystemTags,
      createFunnelPath,
      updateFunnelPath,
      resetStatus,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AddFunnelPath);
