import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import $ from "jquery";

import TitleBox from "../../../components/general/titleBox";
import FilterList from "../../../components/form/filterList";

import { filterByTags } from "../../../helpers/filters";
import { callGetProcesses, callDeleteProcessById } from "../../../services";

class ViewProcessesPage extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      processes: [],
      processesBackup: [],
      filterList: [],
      selectedProcess: {}
    };
  }

  componentDidMount() {
    this.getProcesses();
  }

  getProcesses() {
    callGetProcesses().then(d => {
      this.setState({ processes: d.data, processesBackup: d.data });
    });
  }

  onClickEditProcess = process => {
    this.props.history.push("/admin/add-process", {
      process: process
    });
  };

  onClickDeleteProcess = process => {
    this.setState({ selectedProcess: process }, () => {
      $(".modalAlertOverlay,.modalAlert").fadeIn(200);
    });
  };

  filterUpdated(updatedFilter, name, type, searchFields) {
    let filterList = this.state.filterList;
    filterList[name] = {
      name: name,
      type: type,
      searchFields: searchFields,
      values: updatedFilter
    };
    this.setState(
      {
        filterList: filterList
      },
      () => {
        let data = filterByTags(this.state.processesBackup, filterList);
        this.setState({
          processes: data
        });
      }
    );
  }

  renderProcesses() {
    var returnVals = [];
    let items = this.state.processes;
    items.forEach(element => {
      returnVals.push(
        <tr key={element._id}>
          <td>{element.title}</td>
          <td>{element.page_list.join(", ")}</td>
          <td className="min-width-100">
            {/*eslint-disable*/}
            <a
              onClick={this.onClickDeleteProcess.bind(this, element)}
              className="btn btn-danger btn-sm margin-right-10 border-0">
              <i className="fa fa-trash" />
            </a>
            <a
              onClick={this.onClickEditProcess.bind(this, element)}
              className="btn btn-default btn-sm border-0">
              <i className="fa fa-pencil" />
            </a>
            {/*eslint-enable*/}
          </td>
        </tr>
      );
    });
    return returnVals;
  }

  onClickNoAlert = () => {
    $(".modalAlertOverlay,.modalAlert").fadeOut(200);
  };

  onClickYesAlert = () => {
    callDeleteProcessById(this.state.selectedProcess._id).then(d => {
      $(".modalAlertOverlay,.modalAlert").fadeOut(200);
      this.getProcesses();
    });
  };

  renderConfirmationAlert() {
    let selectedProcess = this.state.selectedProcess;
    let title = "";
    if (selectedProcess.title) {
      title = selectedProcess.title;
    }
    return (
      <div>
        <div className="modalAlertOverlay" />
        <div className="modalAlert">
          <p>{`Are you sure you want to delete "${title}" process?`}</p>
          <div className="alertFooterBtns">
            {/*eslint-disable*/}
            <a
              className="btn btn-default closeModal-No margin-right-10"
              onClick={this.onClickNoAlert}>
              No
            </a>
            <a
              className="btn btn-primary closeModal-Yes"
              onClick={this.onClickYesAlert}>
              Yes
            </a>
            {/*eslint-enable*/}
          </div>
        </div>
      </div>
    );
  }

  renderFilterView() {
    return (
      <div className="list-group-item">
        <FilterList
          label={"Search Processes..."}
          name={"search"}
          type={"search"}
          searchFields={["title", "description"]}
          onFinish={this.filterUpdated.bind(this)}
        />
      </div>
    );
  }

  render() {
    return (
      <div className="container-fluid">
        {this.renderConfirmationAlert()}
        <TitleBox title="Processes Overview" />
        <div className="gap20" />
        <div className="card">
          <div className="list-group list-group-flush">
            {this.renderFilterView()}
            <div className="list-group-item">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Page</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>{this.renderProcesses()}</tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewProcessesPage);
