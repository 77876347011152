import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import BarChartHorizontal from "../../../components/chart/barChartHorizontal";

class TestPage extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {};
  }

  render() {
    return (
      <div>
        <BarChartHorizontal
          // key={this.state.keyChart}
          // data={this.state.monthPayments}
          // labels={this.state.months}
          key={this.state.keyChart}
          data={[12, 19, 3, 5, 2, 3, 3]}
          labels={[
            "July 1st 2020",
            "July 2nd 2020",
            "July 3rd 2020",
            "July 4th 2020",
            "July 5th 2020",
            "July 6th 2020",
            "July 7th 2020",
          ]}
          label={"300 Users"}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TestPage);
