import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { arrayMove } from "react-sortable-hoc";

import AutoSuggest from "../../../components/form/autoSuggest";
import TitleBox from "../../../components/general/titleBox";
import HelpTextDiv from "../../../components/form/helpTextDiv";

import {
  resetStatus,
  getGenericTags,
  getIngredients,
  createMealPlan,
  updateMealPlan,
} from "../../../modules/actions/index";
import { showNotification, isFS } from "../../../helpers";
import { SortableIngredientList } from "../../../components/form/sortableList";
import {
  callCreateGenericTag,
  callCreateIngredient,
  callGetSubscriptionAreaUsingQuery,
} from "../../../services/index";
import SelectAddImage from "../../../components/form/selectAddImage";
import GenericTagSelect from "../../../components/form/genericTagSelect";

class CreateMealPlan extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    let mealPlan = {
      _id: 0,
      name: "",
      description: "",
      serves: "",
      servingType: "",
      imgUrl: "",
      additionalInformation: "",
      directions: "",
      preparationTime: "",
      fat: "",
      carbs: "",
      protein: "",
      calories: "",
      sugar: "",
      videoUrl: "",
      tags: [],
      selectedTags: [],
      fTags: { new: [], existing: [] },
      ingredientList: [],
      ingredients: [],
      ingredient: "",
      amount: "",
      measurement: "",
      comment: "",
      keyName: Math.random(),
      keyTag: Math.random(),
      subAreaId: "",
    };

    if (this.props.history.location.state !== undefined) {
      let mPlan = this.props.history.location.state.mealPlan;

      mealPlan._id = mPlan._id;
      mealPlan.name = mPlan.name;
      mealPlan.description = mPlan.description;
      mealPlan.serves = mPlan.serves;
      mealPlan.servingType = mPlan.serving_type;
      mealPlan.additionalInformation = mPlan.ingredients_optional;
      mealPlan.directions = mPlan.directions;
      mealPlan.preparationTime = mPlan.prep_time;
      mealPlan.fat = mPlan.fat;
      mealPlan.carbs = mPlan.carbs;
      mealPlan.protein = mPlan.protein;
      mealPlan.calories = mPlan.calories;
      mealPlan.sugar = mPlan.sugar;
      mealPlan.videoUrl = mPlan.video_url;
      mealPlan.selectedTags = mPlan.ftags || [];

      let ingredientList = [];
      mPlan.ingredient_info_list.forEach((element) => {
        ingredientList.push({
          name: element.name || "",
          amount: element.amount || "",
          measurement: element.measurement || "",
          comment: element.comment || "",
        });
      });
      mealPlan.ingredientList = ingredientList;
      mealPlan.imgUrl = mPlan.image_url;
      mealPlan.subAreaId = mPlan.subscription_area || "";
    }

    this.state = {
      mealPlanId: mealPlan._id,
      name: mealPlan.name,
      description: mealPlan.description,
      serves: mealPlan.serves,
      servingType: mealPlan.servingType,
      imgUrl: mealPlan.imgUrl,
      additionalInformation: mealPlan.additionalInformation,
      directions: mealPlan.directions,
      preparationTime: mealPlan.preparationTime,
      fat: mealPlan.fat,
      carbs: mealPlan.carbs,
      protein: mealPlan.protein,
      calories: mealPlan.calories,
      sugar: mealPlan.sugar,
      videoUrl: mealPlan.videoUrl,
      tags: [],
      selectedTags: mealPlan.selectedTags,
      fTags: { new: [], existing: mealPlan.selectedTags },
      ingredientList: mealPlan.ingredientList,
      ingredients: [],
      ingreMes: [],
      ingredient: "",
      amount: "",
      measurement: "",
      comment: "",
      keyName: Math.random(),
      keyMeasurement: Math.random(),
      keyTag: Math.random(),
      showImages: false,
      subscriptionAreas: [],
      isLoading: true,
      selectedSubscriptionArea: mealPlan.subAreaId,
    };
  }

  componentDidMount() {
    this.props.getIngredients();
    this.props.getGenericTags("meal-plan");
    this.getSubscriptionAreas();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.message) {
      if (newProps.success) {
        if (this.state.mealPlanId === 0) {
          this.resetProps();
        }

        showNotification("success", newProps.message, 5000);
      } else {
        showNotification("error", newProps.message, 5000);
      }
      this.props.resetStatus();
    }

    if (newProps.genericTags.data) {
      this.setState({
        tags: newProps.genericTags.data,
        keyTag: Math.random(),
      });
    }

    if (newProps.ingredients.data) {
      let inData = newProps.ingredients.data;
      var flags = [],
        flagsm = [],
        ingredients = [],
        ingreMes = [];
      for (var i = 0; i < inData.length; i++) {
        if (flags[inData[i].name.toLowerCase()]) continue;
        flags[inData[i].name.toLowerCase()] = true;
        ingredients.push(inData[i]);
      }
      for (var j = 0; j < inData.length; j++) {
        if (flagsm[inData[j].measurement.toLowerCase()]) continue;
        flagsm[inData[j].measurement.toLowerCase()] = true;
        ingreMes.push(inData[j]);
      }
      this.setState({
        ingredients: ingredients,
        ingreMes: ingreMes,
        keyName: Math.random(),
        keyMeasurement: Math.random(),
      });
    }
  }

  resetProps() {
    this.setState({
      name: "",
      description: "",
      serves: "",
      servingType: "",
      imgUrl: "",
      additionalInformation: "",
      directions: "",
      preparationTime: "",
      fat: "",
      carbs: "",
      protein: "",
      calories: "",
      sugar: "",
      videoUrl: "",
      ingredientList: [],
      fTags: { new: [], existing: [] },
      ingredient: "",
      amount: "",
      measurement: "",
      comment: "",
      keyName: Math.random(),
      keyTag: Math.random(),
      selectedSubscriptionArea: "",
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  getSubscriptionAreas() {
    callGetSubscriptionAreaUsingQuery().then((d) => {
      this.setState({ subscriptionAreas: d.data, isLoading: false });
    });
  }

  onChangeMealPlanImage(image, file) {
    this.setState({ mealPlanImage: file });
  }

  onClickAddIntegredient() {
    let ingredientList = this.state.ingredientList;

    if (this.state.ingredient.trim().length === 0) {
      showNotification("error", "Ingredient name is required", 4000);
      return;
    }

    ingredientList.push({
      name: this.state.ingredient,
      amount: this.state.amount,
      measurement: this.state.measurement,
      comment: this.state.comment,
    });

    this.setState({
      ingredientList: ingredientList,
      ingredient: "",
      amount: "",
      measurement: "",
      keyMeasurement: Math.random(),
      comment: "",
      keyName: Math.random(),
    });
  }

  onSelectIngredientValue(value) {
    this.setState({
      ingredient: value.name,
    });
  }

  onChangeIngredientValue(value) {
    this.setState({
      ingredient: value,
    });
  }

  onSortEndIngredients = ({ oldIndex, newIndex }) => {
    const { ingredientList } = this.state;

    this.setState({
      ingredientList: arrayMove(ingredientList, oldIndex, newIndex),
    });
  };

  onPressRemoveIngredient(e) {
    let index = e.target.dataset["value"];
    let ingredientList = this.state.ingredientList;
    ingredientList.splice(index, 1);
    this.setState({
      ingredientList: ingredientList,
    });
  }

  onChangeTags = (tags) => {
    if (tags.length > 0) {
      let fTags = { new: [], existing: [] };
      tags.forEach((element) => {
        if (element.name) {
          fTags.existing.push(element);
        } else {
          fTags.new.push(element);
        }
      });
      this.setState({ fTags: fTags });
    }
  };

  removePhoto() {
    this.setState({
      imgUrl: "",
    });
  }

  onPressAddMealPlan() {
    if (this.state.name.trim().length === 0) {
      showNotification("error", "Meal Plan Name is required", 4000);
      return;
    }

    if (this.state.description.trim().length === 0) {
      showNotification("error", "Description is required", 4000);
      return;
    }

    if (this.state.ingredientList.length === 0) {
      showNotification("error", "Atleast one ingredient is required", 4000);
      return;
    }

    if (this.state.directions.trim().length === 0) {
      showNotification("error", "Directions is required", 4000);
      return;
    }

    if (this.state.fat.trim().length === 0) {
      showNotification("error", "Fat is required", 4000);
      return;
    }

    if (this.state.carbs.trim().length === 0) {
      showNotification("error", "Carbs is required", 4000);
      return;
    }

    if (this.state.protein.trim().length === 0) {
      showNotification("error", "Protein is required", 4000);
      return;
    }

    if (this.state.calories.trim().length === 0) {
      showNotification("error", "Calories is required", 4000);
      return;
    }
    this.callCreateTagAPI();
  }

  callCreateTagAPI() {
    let tags = this.state.fTags;
    if (tags.new.length > 0) {
      let data = [];
      tags.new.forEach((element) => {
        data.push({ name: element, type: "meal-plan" });
      });
      callCreateGenericTag(data).then((resp) => {
        let eTags = this.state.tags;
        this.setState({
          tags: eTags.concat(resp),
        });
        let newTagIds = resp.map((t) => t._id);
        let tagIds = tags.existing.map((t) => t._id);
        let finalTagIds = newTagIds.concat(tagIds);

        this.callCreateIngredientAPI(finalTagIds);
      });
    } else {
      let tagIds = tags.existing.map((t) => t._id);
      this.callCreateIngredientAPI(tagIds);
    }
  }

  callCreateIngredientAPI(tagIds) {
    let ingredientList = this.state.ingredientList;
    if (ingredientList.length > 0) {
      callCreateIngredient(ingredientList).then((resp) => {
        let ingIds = resp.map((t) => t._id);
        let ingres = this.state.ingredients;
        this.setState({ ingredients: this.state.ingredients.concat(ingres) });
        this.callCreateMealPlanAPI(tagIds, ingIds);
      });
    } else {
      this.callCreateMealPlanAPI(tagIds, []);
    }
  }

  callCreateMealPlanAPI(tagIds, ingredientIds) {
    let apiData = {
      /*eslint-disable */
      name: this.state.name,
      description: this.state.description,
      image_url: this.state.imgUrl,
      directions: this.state.directions,
      ingredient_list: ingredientIds,
      ingredients_optional: this.state.additionalInformation,
      generic_tag_list: tagIds,
      fat: this.state.fat,
      carbs: this.state.carbs,
      protein: this.state.protein,
      calories: this.state.calories,
      sugar: this.state.sugar,
      serves: this.state.serves,
      serving_type: this.state.servingType,
      prep_time: this.state.preparationTime,
      video_url: this.state.videoUrl,
      subscription_area: this.state.selectedSubscriptionArea,
      /*eslint-enable*/
    };

    if (this.state.mealPlanId === 0) {
      this.props.createMealPlan(apiData);
    } else {
      this.props.updateMealPlan(this.state.mealPlanId, apiData);
    }
  }

  onChangeImage = (image) => {
    this.setState({ imgUrl: image });
  };

  onPressAddSubscriptionArea() {
    this.props.history.push("/admin/add-subscription-area");
  }

  renderSubscriptionAreas() {
    let returnVals = [];
    let items = this.state.subscriptionAreas;
    returnVals.push(<option value={""} key={0} />);
    for (let i = 0; i < items.length; i++) {
      returnVals.push(
        <option value={items[i]._id} key={i + 1}>
          {items[i].name}
        </option>
      );
    }
    return returnVals;
  }

  addMealPlanTag = (add) => {
    this.state.tags.push(add);
    this.setState({
      fTags: {
        existing: this.state.tags,
      },
    });
  };

  render() {
    let isLoading = this.state.isLoading;
    let isAreas = this.state.subscriptionAreas.length > 0;

    if (isFS()) {
      isLoading = false;
      isAreas = true;
    }

    return (
      <div>
        <div className="container-fluid">
          <TitleBox
            title={
              this.state.mealPlanId === 0
                ? "Add A New Meal Plan"
                : "Edit Meal Plan"
            }
            showBackBtn={this.state.mealPlanId !== 0}
          />
        </div>

        {!isLoading && isAreas && (
          <div className="container-fluid">
            <div className="gap20" />
            <div className="card">
              <div className="container-fluid">
                <div className="gap20" />

                <HelpTextDiv
                  id="idMealPlanName"
                  label="Learn more about the Name"
                  type="addMealPlan"
                  isInline="false"
                />

                <div
                  className={
                    this.state.name !== "" ? "mdInput mdFocussed" : "mdInput"
                  }>
                  <label>Meal Plan Name</label>
                  <input
                    type="text"
                    name="name"
                    value={this.state.name}
                    onChange={(event) => this.handleInputChange(event)}
                  />
                </div>
                <div className="gap20" />

                <HelpTextDiv
                  id="idMealPlanDescription"
                  label="Learn more about the Description"
                  type="addMealPlan"
                  isInline="false"
                />

                <div
                  className={
                    this.state.description !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Meal Plan Description</label>
                  <textarea
                    name="description"
                    value={this.state.description}
                    onChange={(event) => this.handleInputChange(event)}
                  />
                </div>
                <div className="gap30" />

                <SelectAddImage
                  label="Meal Plan Image"
                  onChangeImage={this.onChangeImage}
                  image={this.state.imgUrl}
                />

                <div className="gap20" />

                <div className="row">
                  <div className="col-md-6">
                    <HelpTextDiv
                      id="idMealPlanServers"
                      label="Learn more about the Serves"
                      type="addMealPlan"
                      isInline="false"
                    />

                    <div
                      className={
                        this.state.serves !== ""
                          ? "mdInput mdFocussed"
                          : "mdInput"
                      }>
                      <label>Serves</label>
                      <input
                        type="text"
                        name="serves"
                        value={this.state.serves}
                        onChange={(event) => this.handleInputChange(event)}
                      />
                    </div>
                    <div className="gap20" />
                  </div>
                  <div className="col-md-6">
                    <HelpTextDiv
                      id="idMealPlanServingType"
                      label="Learn more about the Serving Type"
                      type="addMealPlan"
                      isInline="false"
                    />

                    <div
                      className={
                        this.state.servingType !== ""
                          ? "mdInput mdFocussed"
                          : "mdInput"
                      }>
                      <label>Serving Type</label>
                      <input
                        type="text"
                        name="servingType"
                        value={this.state.servingType}
                        onChange={(event) => this.handleInputChange(event)}
                      />
                    </div>
                    <div className="gap20" />
                  </div>
                </div>

                <div className="container-fluid">
                  <div className="card">
                    <div className="container-fluid">
                      <div className="gap20" />
                      <h5>Add Ingredients</h5>
                      <div className="gap20" />
                      <div className="row">
                        <div className="col-sm-6">
                          <HelpTextDiv
                            id="idMealPlanIngrdient"
                            label="Learn more about the Ingredient"
                            type="addMealPlan"
                            isInline="false"
                          />

                          <div
                            className={
                              this.state.ingredient !== ""
                                ? "mdInput mdFocussed"
                                : "mdInput"
                            }>
                            <label>Ingredient</label>
                            <AutoSuggest
                              key={this.state.keyName}
                              searchKey="name"
                              data={this.state.ingredients}
                              value={this.state.ingredient}
                              onChange={this.onChangeIngredientValue.bind(this)}
                              onSelect={this.onSelectIngredientValue.bind(this)}
                            />
                          </div>

                          <div className="gap20" />
                        </div>
                        <div className="col-sm-6">
                          <HelpTextDiv
                            id="idMealPlanAmountPerQuantity"
                            label="Learn more about the Amount per Quantity"
                            type="addMealPlan"
                            isInline="false"
                          />

                          <div
                            className={
                              this.state.amount !== ""
                                ? "mdInput mdFocussed"
                                : "mdInput"
                            }>
                            <label>Amount/Quantity</label>
                            <input
                              type="text"
                              className="amount"
                              name="amount"
                              value={this.state.amount}
                              onChange={(event) =>
                                this.handleInputChange(event)
                              }
                            />
                          </div>
                          <div className="gap20" />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-6">
                          <HelpTextDiv
                            id="idMealPlanMeasurement"
                            label="Learn more about the Measurement"
                            type="addMealPlan"
                            isInline="false"
                          />

                          <div
                            className={
                              this.state.measurement !== ""
                                ? "mdInput mdFocussed"
                                : "mdInput"
                            }>
                            <label>Measurement</label>
                            <AutoSuggest
                              key={this.state.keyMeasurement}
                              searchKey="measurement"
                              data={this.state.ingreMes}
                              value={this.state.measurement}
                              onChange={(value) =>
                                this.setState({ measurement: value })
                              }
                              onSelect={(value) =>
                                this.setState({
                                  measurement: value.measurement,
                                })
                              }
                            />
                          </div>
                          <div className="gap20" />
                        </div>
                        <div className="col-sm-6">
                          <HelpTextDiv
                            id="idMealPlanComment"
                            label="Learn more about the Comment"
                            type="addMealPlan"
                            isInline="false"
                          />

                          <div
                            className={
                              this.state.comment !== ""
                                ? "mdInput mdFocussed"
                                : "mdInput"
                            }>
                            <label>Comment</label>
                            <input
                              type="text"
                              className="comment"
                              name="comment"
                              value={this.state.comment}
                              onChange={(event) =>
                                this.handleInputChange(event)
                              }
                            />
                          </div>
                          <div className="gap20" />
                        </div>
                      </div>
                      <div
                        className="text-right"
                        onClick={() => this.onClickAddIntegredient()}>
                        {/*eslint-disable-next-line*/}
                        <a className="btn btn-primary addVideoButton">
                          ADD INGREDIENT
                        </a>
                      </div>

                      <div className="gap20" />
                      <SortableIngredientList
                        items={this.state.ingredientList}
                        onSortEnd={this.onSortEndIngredients}
                        onPressRemove={this.onPressRemoveIngredient.bind(this)}
                      />
                      <div className="gap20" />
                    </div>
                  </div>
                </div>

                <HelpTextDiv
                  id="idMealPlanAdditionalIngredientInformation"
                  label="Learn more about the Additional Ingredient Information"
                  type="addMealPlan"
                  isInline="false"
                />

                <div
                  className={
                    this.state.additionalInformation !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Additional Ingredient Information</label>
                  <textarea
                    name="additionalInformation"
                    value={this.state.additionalInformation}
                    onChange={(event) => this.handleInputChange(event)}
                  />
                </div>
                <div className="gap20" />

                <HelpTextDiv
                  id="idMealPlanDirections"
                  label="Learn more about the Directions"
                  type="addMealPlan"
                  isInline="false"
                />

                <div
                  className={
                    this.state.directions !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Directions (Separated by line)</label>
                  <textarea
                    name="directions"
                    value={this.state.directions}
                    onChange={(event) => this.handleInputChange(event)}
                  />
                </div>
                <div className="gap20" />

                <HelpTextDiv
                  id="idMealPlanPreparationTime"
                  label="Learn more about the Preparation Time"
                  type="addMealPlan"
                  isInline="false"
                />

                <div
                  className={
                    this.state.preparationTime !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Preparation Time (In minutes)</label>
                  <input
                    type="text"
                    name="preparationTime"
                    value={this.state.preparationTime}
                    onChange={(event) => this.handleInputChange(event)}
                  />
                </div>
                <div className="gap20" />

                <HelpTextDiv
                  id="idMealPlanFat"
                  label="Learn more about the Fat"
                  type="addMealPlan"
                  isInline="false"
                />

                <div
                  className={
                    this.state.fat !== "" ? "mdInput mdFocussed" : "mdInput"
                  }>
                  <label>Fat</label>
                  <input
                    type="text"
                    name="fat"
                    value={this.state.fat}
                    onChange={(event) => this.handleInputChange(event)}
                  />
                </div>
                <div className="gap20" />

                <HelpTextDiv
                  id="idMealPlanCarbs"
                  label="Learn more about the Carbs"
                  type="addMealPlan"
                  isInline="false"
                />

                <div
                  className={
                    this.state.carbs !== "" ? "mdInput mdFocussed" : "mdInput"
                  }>
                  <label>Carbs</label>
                  <input
                    type="text"
                    name="carbs"
                    value={this.state.carbs}
                    onChange={(event) => this.handleInputChange(event)}
                  />
                </div>
                <div className="gap20" />

                <HelpTextDiv
                  id="idMealPlanProtein"
                  label="Learn more about the Protein"
                  type="addMealPlan"
                  isInline="false"
                />

                <div
                  className={
                    this.state.protein !== "" ? "mdInput mdFocussed" : "mdInput"
                  }>
                  <label>Protein</label>
                  <input
                    type="text"
                    name="protein"
                    value={this.state.protein}
                    onChange={(event) => this.handleInputChange(event)}
                  />
                </div>
                <div className="gap20" />

                <HelpTextDiv
                  id="idMealPlanCalories"
                  label="Learn more about the Calories"
                  type="addMealPlan"
                  isInline="false"
                />

                <div
                  className={
                    this.state.calories !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Calories</label>
                  <input
                    type="text"
                    name="calories"
                    value={this.state.calories}
                    onChange={(event) => this.handleInputChange(event)}
                  />
                </div>
                <div className="gap20" />

                <HelpTextDiv
                  id="idMealPlanSugar"
                  label="Learn more about the Sugar"
                  type="addMealPlan"
                  isInline="false"
                />

                <div
                  className={
                    this.state.sugar !== "" ? "mdInput mdFocussed" : "mdInput"
                  }>
                  <label>Sugar</label>
                  <input
                    type="text"
                    name="sugar"
                    value={this.state.sugar}
                    onChange={(event) => this.handleInputChange(event)}
                  />
                </div>
                <div className="gap20" />

                <HelpTextDiv
                  id="idMealPlanVideoID"
                  label="Learn more about the Video ID"
                  type="addMealPlan"
                  isInline="false"
                />

                <div
                  className={
                    this.state.videoUrl !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Video ID</label>
                  <input
                    type="text"
                    name="videoUrl"
                    value={this.state.videoUrl}
                    onChange={(event) => this.handleInputChange(event)}
                  />
                </div>

                <div className="gap20" />

                <HelpTextDiv
                  id="idMealPlanTags"
                  label="Learn more about the Tags"
                  type="addMealPlan"
                  isInline="false"
                />

                <GenericTagSelect
                  id={"add-meal-plan-type-tag"}
                  key={this.state.keyTag}
                  selectedValues={this.state.selectedTags}
                  searchKey={"name"}
                  selectKey={"name"}
                  btnText={"Create New Tag"}
                  lblText={"Type a Tag"}
                  data={this.state.tags}
                  onChange={this.onChangeTags}
                  addTag={this.addMealPlanTag}
                  isClickable={true}
                  popupHeaderText={"Add Meal Plan Tag"}
                  popupGenericType={"meal-plan"}
                  popupHeaderFFEnableName={true}
                  popupHeaderFFEnableDescription={true}
                />
                <div
                  className={
                    this.state.selectedSubscriptionArea !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Select Subscription Area</label>
                  <select
                    name="selectedSubscriptionArea"
                    value={this.state.selectedSubscriptionArea}
                    onChange={(event) => this.handleInputChange(event)}>
                    {this.renderSubscriptionAreas()}
                  </select>
                </div>
                <div className="gap20" />

                <div className="text-right">
                  <button
                    className="btn btn-primary"
                    onClick={() => this.onPressAddMealPlan()}>
                    Save Meal Plan
                  </button>
                </div>

                <div className="gap20" />
              </div>
            </div>
          </div>
        )}

        {!isAreas && (
          <div className="container-fluid">
            <div className="gap10" />
            <div className="card">
              <div className="container-fluid">
                <div className="gap20" />
                <div className="row">
                  <div className="col-md-3">
                    {isLoading && <p>Loading ...</p>}
                    {!isLoading && (
                      <>
                        <p>Please add subscription area.</p>
                        <button
                          className="btn btn-primary"
                          onClick={() => this.onPressAddSubscriptionArea()}>
                          Add Subscription Area
                        </button>
                        <div className="gap20" />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  message: state.mealPlanReducer.message,
  success: state.mealPlanReducer.success,
  genericTags: state.tagReducer.genericTags,
  ingredients: state.mealPlanReducer.ingredients,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      resetStatus,
      getGenericTags,
      getIngredients,
      createMealPlan,
      updateMealPlan,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CreateMealPlan);
