import React, { Component } from "react";
import { callGetChlgsByQuery } from "../../services";
import RadioTag from "./radioTag";

class SelectScreen extends Component {
  constructor(props) {
    super(props);

    const { screen, challengeId, challengeItemId, label } = this.props;

    this.state = {
      label: label,
      screen: screen,
      challenges: [],
      challengeItems: [],
      keyChallenge: Math.random(),
      challengeId: challengeId || "",
      challengeItemId: challengeItemId || "",
    };
  }

  componentDidMount() {
    this.getData();
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });

    if (name === "challengeId") {
      let challengeItems = this.state.challenges.filter(
        (d) => d._id === value
      )[0].challenge_item_list_info;
      this.setState({ challengeItems: challengeItems, challengeItemId: "" });
      this.props.onChange({
        screen: this.state.screen,
        challengeId: value,
        challengeItemId: this.state.challengeItemId,
      });
    }
    if (name === "challengeItemId") {
      this.props.onChange({
        screen: this.state.screen,
        challengeId: this.state.challengeId,
        challengeItemId: value,
      });
    }
  }

  async getData() {
    let response = await callGetChlgsByQuery({
      query: {
        archive: { $ne: true },
        $select: ["-content", "-html"],
      },
    });

    this.setState(
      {
        challenges: response.data,
        keyChallenge: Math.random(),
      },
      () => {
        if (this.state.challengeId) {
          let challengeItems = this.state.challenges.filter(
            (d) => d._id === this.state.challengeId
          )[0].challenge_item_list_info;
          this.setState({
            challengeItems: challengeItems,
          });
        }
      }
    );
  }

  onSelectScreen(item, id) {
    let type = item[Object.keys(item)[0]].value;
    this.setState({ screen: type });
    this.props.onChange({
      screen: type,
      challengeId: this.state.challengeId,
      challengeItemId: this.state.challengeItemId,
    });
  }

  render() {
    return (
      <div>
        <div className="radioBHolder">
          <div className="havingHelpText">
            <p>{this.state.label}</p>
          </div>
          <div className="tabsHolder radioTabs">
            <RadioTag
              onCheckChange={this.onSelectScreen.bind(this)}
              labelList={[
                { name: "Dashboard", value: "dashboard" },
                { name: "Challenge", value: "challenge" },
                { name: "Messages", value: "messages" },
                { name: "Resources", value: "resources" },
                { name: "Accountability", value: "atq" },
                { name: "Charts", value: "charts" },
                { name: "Profile", value: "profile" },
                { name: "Events", value: "events" },
              ]}
              id={"screen"}
              selectedList={[this.state.screen]}
              selectType={"value"}
            />
          </div>
        </div>

        {this.state.challenges.length > 0 && this.state.screen === "challenge" && (
          <div>
            <div className="gap20" />
            <div
              className={
                this.state.challengeId !== "" ? "mdInput mdFocussed" : "mdInput"
              }>
              <label>Select Challenge</label>
              <select
                name="challengeId"
                value={this.state.challengeId}
                onChange={(event) => this.handleInputChange(event)}>
                <option value={""} key={"1"}></option>
                {this.state.challenges.map((i) => (
                  <option value={i._id} key={i._id}>
                    {i.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}
        {this.state.challengeItems.length > 0 &&
          this.state.screen === "challenge" && (
            <div>
              <div
                className={
                  this.state.challengeItemId !== ""
                    ? "mdInput mdFocussed"
                    : "mdInput"
                }>
                <label>Select Challenge Item</label>
                <select
                  name="challengeItemId"
                  value={this.state.challengeItemId}
                  onChange={(event) => this.handleInputChange(event)}>
                  <option value={""} key={"1"}></option>
                  {this.state.challengeItems.map((i) => (
                    <option value={i._id} key={i._id}>
                      {i.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="gap20" />
            </div>
          )}
      </div>
    );
  }
}

export default SelectScreen;
