import {
  RESET_STATUS,
  CREATE_MEAL_PLAN_SUCCESS,
  CREATE_MEAL_PLAN_FAILURE,
  GET_MEAL_PLAN_SUCCESS,
  GET_INGREDIENT_SUCCESS,
  UPDATE_MEAL_PLAN_SUCCESS,
  UPDATE_MEAL_PLAN_FAILURE
} from "../types";

import config from "../../helpers/config";

import {
  callCreateMealPlan,
  callGetMealPlans,
  callGetIngredients,
  callUpdateMealPlan
} from "../../services/index";

/*
   This method is used to reset the status
   This will reset message and success states
   */
export const resetStatus = data => {
  return async dispatch => {
    dispatch({
      type: RESET_STATUS
    });
  };
};

export const createMealPlan = data => {
  return async dispatch => {
    return callCreateMealPlan(data)
      .then(data => {
        dispatch({
          type: CREATE_MEAL_PLAN_SUCCESS,
          message: config.messages.createChlgQueSuccess,
          success: true
        });
      })
      .catch(err => {
        dispatch({
          type: CREATE_MEAL_PLAN_FAILURE,
          message: config.messages.createChlgQueError,
          success: false
        });
      });
  };
};

export const updateMealPlan = (id, data) => {
  return async dispatch => {
    return callUpdateMealPlan(id, data)
      .then(data => {
        dispatch({
          type: UPDATE_MEAL_PLAN_SUCCESS,
          message: config.messages.updateMessage,
          success: true
        });
      })
      .catch(err => {
        dispatch({
          type: UPDATE_MEAL_PLAN_FAILURE,
          message: config.messages.createChlgQueError,
          success: false
        });
      });
  };
};

export const getMealPlans = data => {
  return async dispatch => {
    return callGetMealPlans(data).then(data => {
      dispatch({
        type: GET_MEAL_PLAN_SUCCESS,
        data: data
      });
    });
  };
};

export const getIngredients = data => {
  return async dispatch => {
    return callGetIngredients(data).then(data => {
      dispatch({
        type: GET_INGREDIENT_SUCCESS,
        data: data
      });
    });
  };
};
