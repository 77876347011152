/*eslint-disable*/
import moment from "moment";
import cloneDeep from "lodash/cloneDeep";

export const filterByTags = (originalTable, filterList) => {
  var newTable = cloneDeep(originalTable);
  var table = originalTable;
  var tableRow, tableField, tableInfo, tableVal, found, row, val;
  var tempRemoveList = [],
    saveList = [],
    removeList = [];

  var searchFields = [];

  var filterType;
  var filterValues;

  var dateInfo, startDate, endDate, compareTime;

  for (var filterName in filterList) {
    filterValues = filterList[filterName];

    filterType = filterValues.type.toLowerCase();
    searchFields = filterValues.searchFields;

    filterValues.values.map((filterOption) => {
      if (filterOption.selected) {
        searchFields.map((searchField) => {
          table.map((row, index) => {
            if (searchField.indexOf("->") !== -1) {
              tableInfo = searchField.split("->");
              tableRow = row[tableInfo[0]];
              tableField = tableInfo[1];
            } else {
              //tableRow = typeof row === "object" ? row : Array(row);
              tableRow = Array(row);
              tableField = searchField;
            }

            found = false;

            if (
              filterType === "search" ||
              filterOption.value.toLowerCase() !== "all"
            ) {
              tableRow.map((r) => {
                if (saveList.indexOf(index) <= -1) {
                  if (!found) {
                    if (
                      r &&
                      r[tableField] !== undefined &&
                      r[tableField] !== null
                    ) {
                      tableVal =
                        r[tableField].constructor === Array
                          ? r[tableField]
                          : Array(r);

                      tableVal.map((v) => {
                        val = typeof v === "object" ? v[tableField] : v;
                        switch (filterType) {
                          case "search":
                            if (
                              val
                                .toLowerCase()
                                .indexOf(filterOption.value.toLowerCase()) >= 0
                            ) {
                              found = true;
                            }
                            break;
                          case "single":
                          case "multiple":
                            if (
                              filterOption.value.toLowerCase() ===
                              val.toLowerCase()
                            ) {
                              found = true;
                            }
                            break;

                          case "date":
                            if (filterOption.value.indexOf("->") >= 0) {
                              dateInfo = filterOption.value.split("->");
                              startDate = moment(new Date(dateInfo[0])).format(
                                "MM-DD-YYYY"
                              );
                              endDate = moment(new Date(dateInfo[1])).format(
                                "MM-DD-YYYY"
                              );

                              compareTime = moment(val).format("MM-DD-YYYY");

                              if (
                                compareTime >= startDate &&
                                compareTime <= endDate
                              ) {
                                found = true;
                              }
                            }
                            break;
                          case "tags":
                            if (
                              filterOption.value.toLowerCase() ===
                                val.toLowerCase() &&
                              v.status === "active"
                            ) {
                              found = true;
                            }
                            break;

                          default:
                            break;
                        }
                      });
                    }
                  }
                }
              });
            } else {
              found = true;
            }
            if (filterOption.value === "" && filterOption.name === "") {
              found = true;
            }
            if (found) {
              saveList.push(index);
              if (tempRemoveList.indexOf(index) >= -1)
                delete tempRemoveList[index];
            } else {
              if (tempRemoveList.indexOf(index) >= -1)
                tempRemoveList.push(index);
            }
          });
        });
      }
    });

    //This switches it from being or/and
    tempRemoveList.map((i, ind) => {
      if (saveList.indexOf(i) > -1) {
        delete tempRemoveList[ind];
      }
    });
    removeList = removeList.concat(tempRemoveList.splice(0));
    saveList = [];
  }
  for (row in removeList) {
    delete newTable[removeList[row]];
  }

  removeList = removeList.filter((n) => {
    return n !== undefined;
  });

  for (row in removeList) {
    delete newTable[removeList[row]];
  }
  newTable = newTable.filter((n) => {
    return n !== undefined;
  });

  return newTable;
};
