import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment";
import $ from "jquery";
import { VelocityTransitionGroup } from "velocity-react";

import TitleBox from "../../../components/general/titleBox";
import TypeableSelect from "../../../components/form/typeableSelect";
import { showNotification } from "../../../helpers";
import {
  callCreateMemberPayment,
  callGetMemberPaymentsByQuery,
  callUpdateMemberPayment,
} from "../../../services/billingService";
import {
  callGetStripeAccountBalance,
  callAddMemberPayment,
  callGetStripePayments,
} from "../../../services/generalService";
import {
  callGetCustomerOrderUsingId,
  callGetUsersByQuery,
} from "../../../services";
import HelpTextDiv from "../../../components/form/helpTextDiv";

class AddMemberPayment extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      userData: this.props.userData || {},
      payUsers: [],
      selectedUser: { value: "", label: "" },
      keyUser: Math.random(),
      amount: "",
      notes: "",
      failedPayments: [],
      pendingPayments: [],
      unreleasedPayments: [],
      payoutPayments: [],
      amountAvailable: 0,
      amountOwed: 0,
      amountPending: 0,
      editAmount: "",
      editNotes: "",
      editType: "",
      selectedPayout: {},
      showFailedPayments: false,
      failedPayout: false,
      showBalance: false,
      refundAmount: 0,
      instant: false,
    };
  }

  componentDidMount() {
    this.procecssUsers();
    this.getPendingPayments();
    this.getFailedPayments();
    this.getAccountBalance();
    if (this.state.userData) {
      this.checkShowBalance();
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.userData) {
      this.setState({ userData: newProps.userData }, () => {
        this.checkShowBalance();
      });
    }
  }

  checkShowBalance() {
    let userData = this.state.userData;
    let roles = userData.roles || [];
    let check = true;
    if (roles.indexOf("payments") > -1) {
      let paymentsRoles = userData.payments_roles || [
        "account-balance",
        "member-payments",
      ];
      check = paymentsRoles.indexOf("account-balance") > -1;
    }
    this.setState({ showBalance: check });
  }

  getAccountBalance() {
    callGetStripeAccountBalance().then((d) => {
      if (d.object === "balance") {
        this.setState({
          amountAvailable: (d.available[0].amount / 100).toFixed(2),
          amountPending: (d.pending[0].amount / 100).toFixed(2),
        });
      }
    });
  }

  getFailedPayments() {
    let query = {
      query: {
        status: "failed",
        $sort: { createdAt: -1 },
      },
    };
    callGetMemberPaymentsByQuery(query).then((d) => {
      this.setState({
        failedPayments: d.data,
      });
    });
  }

  getPendingPayments() {
    let query = {
      query: {
        status: "pending",
        $sort: { createdAt: -1 },
      },
    };
    callGetMemberPaymentsByQuery(query).then((d) => {
      let amountOwed = 0;
      d.data.forEach((element) => {
        amountOwed += parseFloat(element.amount);
      });

      let payments = d.data;

      let payoutPayments = payments.filter((p) => {
        return p.member_info.payout_details.length === 0;
      });

      let unreleasedPayments = payments.filter((p) => {
        return p.release_date && new Date(p.release_date) >= new Date();
      });

      let pendingPayments = payments.filter((p) => {
        return (
          !p.release_date ||
          (p.release_date && new Date(p.release_date) <= new Date())
        );
      });

      this.setState({
        pendingPayments: pendingPayments,
        unreleasedPayments: unreleasedPayments,
        payoutPayments: payoutPayments,
        amountOwed: amountOwed.toFixed(2),
      });
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  onSelectUser = (user) => {
    this.setState({ selectedUser: user });
  };

  async procecssUsers() {
    /*eslint-disable*/
    let userData = await callGetUsersByQuery({
      query: {
        "payout_details.0": { $exists: true },
        $select: ["_id", "name", "email_address", "payout_details"],
      },
    });
    /*eslint-enable*/

    let fUsers = userData.data;

    let payUsers = [];
    fUsers.forEach((element) => {
      payUsers.push({ value: element, label: element.name });
    });
    this.setState({ payUsers: payUsers, keyUser: Math.random() });
  }

  async onClickSendPayments(payout, failed = false, instant = false) {
    if (payout) {
      /*eslint-disable*/
      let query = {
        query: {
          customer_order_id: payout.customer_order_id,
        },
      };
      /*eslint-enable*/
      let d = await callGetStripePayments(query);
      let refundAmount = 0;
      if (d.data.length > 0) {
        let stripePayment = d.data[0];
        let stripeCharges = stripePayment.stripe_charges || [];
        stripeCharges.forEach((element) => {
          if (element.refund_amount) {
            refundAmount += parseFloat(element.refund_amount);
          }
        });
      }

      this.setState(
        {
          selectedPayout: payout,
          refundAmount: refundAmount,
          failedPayout: failed,
          instant: instant,
        },
        () => {
          $(
            ".modalAlertOverlay.sendPaymentOverlay,.modalAlert.sendPaymentAlert"
          ).fadeIn(200);
        }
      );
    } else {
      $(
        ".modalAlertOverlay.sendPaymentOverlay,.modalAlert.sendPaymentAlert"
      ).fadeIn(200);
    }
  }

  onClickNoSendPaymentAlert = () => {
    $(
      ".modalAlertOverlay.sendPaymentOverlay,.modalAlert.sendPaymentAlert"
    ).fadeOut(200);
  };

  onClickYesSendPaymentAlert = () => {
    // call send payments api
    $(
      ".modalAlertOverlay.sendPaymentOverlay,.modalAlert.sendPaymentAlert"
    ).fadeOut(200);

    let apiData = {
      action: "create-member-payment",
      data: {},
    };

    if (this.state.selectedPayout.member_id) {
      apiData.data["id"] = this.state.selectedPayout._id;
      apiData.data["memberId"] = this.state.selectedPayout.member_id;
      apiData.data["type"] = "single";
      apiData.data["failed"] = this.state.failedPayout;
      apiData.data["instant"] = false;
    }

    callAddMemberPayment(apiData).then((d) => {
      this.setState({ selectedPayout: {}, failedPayout: false }, () => {
        this.getPendingPayments();
        this.getFailedPayments();
        this.getAccountBalance();
      });
    });
  };

  renderConfirmationAlert() {
    return (
      <div>
        <div className="modalAlertOverlay sendPaymentOverlay" />
        <div className="modalAlert sendPaymentAlert">
          <p>Are you sure want to send payments?</p>
          <div className="alertFooterBtns">
            <button
              className="btn btn-default closeModal-No margin-right-10"
              onClick={this.onClickNoSendPaymentAlert}>
              No
            </button>
            <button
              className="btn btn-primary closeModal-Yes"
              onClick={this.onClickYesSendPaymentAlert}>
              Yes
            </button>
          </div>
        </div>
      </div>
    );
  }

  onClickMarkAsPaid(payout) {
    if (payout) {
      this.setState({ selectedPayout: payout }, () => {
        $(
          ".modalAlertOverlay.paidPaymentOverlay,.modalAlert.paidPaymentAlert"
        ).fadeIn(200);
      });
    }
  }

  onClickNoPaidPaymentAlert = () => {
    $(
      ".modalAlertOverlay.paidPaymentOverlay,.modalAlert.paidPaymentAlert"
    ).fadeOut(200);
  };

  onClickYesPaidPaymentAlert = () => {
    let data = {
      /*eslint-disable*/
      status: "completed",
      $push: {
        notes_history: {
          memberId: this.state.userData._id,
          notes: this.state.editNotes,
          date: new Date(),
        },
      },
      /*eslint-enable*/
    };
    callUpdateMemberPayment(this.state.selectedPayout._id, data).then((d) => {
      if (d._id) {
        this.setState({ selectedPayout: {}, editNotes: "" }, () => {
          showNotification("success", "Payout updated successfully", 4000);
          this.onClickNoPaidPaymentAlert();
          this.getPendingPayments();
          this.getFailedPayments();
        });
      }
    });
  };

  renderPaidConfirmationAlert() {
    return (
      <div>
        <div className="modalAlertOverlay paidPaymentOverlay" />
        <div className="modalAlert paidPaymentAlert">
          <div className="clearfix">
            <div className="pull-left">
              <p>Are you sure you want to mark this payment as paid?</p>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div
                  className={
                    this.state.editNotes !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Notes</label>
                  <input
                    type="text"
                    name="editNotes"
                    value={this.state.editNotes}
                    onChange={(event) => this.handleInputChange(event)}
                  />
                </div>
                <div className="gap10" />
              </div>
            </div>
          </div>
          <div className="gap10" />

          <div className="alertFooterBtns">
            {/*eslint-disable*/}
            <a
              onClick={this.onClickNoPaidPaymentAlert.bind(this)}
              className="btn btn-default dismissThisModal margin-right-10">
              No
            </a>

            <a
              onClick={this.onClickYesPaidPaymentAlert.bind(this)}
              className="btn btn-primary dismissThisModal">
              Yes
            </a>

            {/*eslint-enable*/}
          </div>
        </div>
      </div>
    );
  }

  onClickAddPayment() {
    if (this.state.selectedUser.label.trim().length === 0) {
      showNotification("error", "User is required", 4000);
      return;
    }
    if (this.state.amount.trim().length === 0) {
      showNotification("error", "Amount is required", 4000);
      return;
    }

    /*eslint-disable*/
    let apiData = {
      member_id: this.state.selectedUser.value._id,
      amount: this.state.amount,
      notes: this.state.notes,
      status: "pending",
      processor: "stripe",
      transfer_id: "",
      error_list: [],
    };
    /*eslint-enable*/
    callCreateMemberPayment(apiData).then((d) => {
      if (d._id) {
        this.setState(
          {
            notes: "",
            amount: "",
            selectedUser: { value: "", label: "" },
            keyUser: Math.random(),
          },
          () => {
            this.getPendingPayments();
            this.getFailedPayments();
          }
        );
      }
    });
  }

  onClickViewDetailsFromOrder(orderId) {
    callGetCustomerOrderUsingId(orderId).then((order) => {
      this.props.history.push("/admin/order-details", {
        order: order,
      });
    });
  }

  onClickEditPaymentFromOrder(payout, type) {
    this.setState(
      {
        selectedPayout: payout,
        editAmount: payout.amount,
        editNotes: payout.notes,
        editType: type,
      },
      () => {
        $(".modalAlertOverlay.editModalOverlay,.modalAlert.editAlert").fadeIn(
          200
        );
      }
    );
  }

  onClickCloseFromEditModal() {
    $(".modalAlertOverlay.editModalOverlay,.modalAlert.editAlert").fadeOut(200);
  }

  onClickSubmitFromEditModal(payout) {
    let data = {
      amount: this.state.editAmount.toString(),
      notes: this.state.editNotes,
      /*eslint-disable*/
      $push: {
        notes_history: {
          memberId: this.state.userData._id,
          notes: this.state.editNotes,
          previous_amount: this.state.selectedPayout.amount.toString(),
          new_amount: this.state.editAmount.toString(),
          date: new Date(),
        },
      },
      /*eslint-enable*/
    };
    this.callUpdatePayoutAPI(this.state.selectedPayout._id, data);
  }

  onClickCancelFromEditModal(payout) {
    /*eslint-disable*/
    let data = {
      cancel_details: {
        cancel_by: this.state.userData._id,
        cancel_notes: this.state.editNotes,
      },
      status: "cancelled",
    };
    /*eslint-enable*/
    this.callUpdatePayoutAPI(this.state.selectedPayout._id, data);
  }

  callUpdatePayoutAPI(id, data) {
    callUpdateMemberPayment(id, data).then((d) => {
      if (d._id) {
        this.setState(
          { selectedPayout: {}, editNotes: "", editAmount: "", editType: "" },
          () => {
            showNotification("success", "Payout updated successfully", 4000);
            this.onClickCloseFromEditModal();
            this.getPendingPayments();
            this.getFailedPayments();
          }
        );
      }
    });
  }

  renderEditPaymentModal() {
    if (this.state.editType.length === 0) {
      return null;
    }
    return (
      <div>
        <div className="modalAlertOverlay editModalOverlay" />
        <div className="modalAlert editAlert">
          <div className="clearfix">
            <div className="pull-left">
              <p>{this.state.editType} Payment</p>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div
                  className={
                    this.state.editAmount !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Amount</label>
                  <input
                    disabled={this.state.editType === "Cancel"}
                    type="number"
                    name="editAmount"
                    value={this.state.editAmount}
                    onChange={(event) => this.handleInputChange(event)}
                  />
                </div>
                <div className="gap10" />
              </div>
              <div className="col-sm-12">
                <div
                  className={
                    this.state.editNotes !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Notes</label>
                  <input
                    type="text"
                    name="editNotes"
                    value={this.state.editNotes}
                    onChange={(event) => this.handleInputChange(event)}
                  />
                </div>
                <div className="gap10" />
              </div>
            </div>
          </div>
          <div className="gap10" />

          <div className="alertFooterBtns">
            {/*eslint-disable*/}
            <a
              onClick={this.onClickCloseFromEditModal.bind(this)}
              className="btn btn-default dismissThisModal margin-right-10">
              Close
            </a>
            {this.state.editType === "Edit" && (
              <a
                onClick={this.onClickSubmitFromEditModal.bind(this)}
                className="btn btn-primary dismissThisModal">
                Submit
              </a>
            )}
            {this.state.editType === "Cancel" && (
              <a
                onClick={this.onClickCancelFromEditModal.bind(this)}
                className="btn btn-primary dismissThisModal">
                Delete Payment
              </a>
            )}
            {/*eslint-enable*/}
          </div>
        </div>
      </div>
    );
  }

  renderNotes(element) {
    let notes = element.notes;
    if (element.affiliate_info) {
      notes = `${notes} Added By ${element.affiliate_info.userName} (${element.affiliate_info.checkoutId})`;
    }
    return notes;
  }

  renderPayments(payments, option, failed = false) {
    return (
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Name</th>
            <th>Amount</th>
            <th>Notes</th>
            <th>Created</th>
            <th>Release Date</th>
            <th>Action</th>
            {option && <th>Pay</th>}
          </tr>
        </thead>
        <tbody>
          {payments.map((i) => (
            <>
              <tr key={i._id}>
                <td>{i.member_info.name}</td>
                <td>${parseFloat(i.amount).toFixed(2)}</td>
                <td>{this.renderNotes(i)}</td>
                <td>
                  {i.createdAt
                    ? moment(i.createdAt).format("MMM, Do YYYY hh:mm A")
                    : "-"}
                </td>
                <td>
                  {i.release_date
                    ? moment(i.release_date).format("MMM, Do YYYY hh:mm A")
                    : "-"}
                </td>
                <td>
                  {/*eslint-disable*/}
                  {i.customer_order_id && (
                    <a
                      onClick={this.onClickViewDetailsFromOrder.bind(
                        this,
                        i.customer_order_id
                      )}
                      className="btn btn-default btn-sm margin-right-10 border-0">
                      <i className="fa fa-eye" />
                    </a>
                  )}
                  <a
                    onClick={this.onClickEditPaymentFromOrder.bind(
                      this,
                      i,
                      "Edit"
                    )}
                    className="btn btn-default btn-sm margin-right-10 border-0">
                    <i className="fa fa-pencil" />
                  </a>
                  <a
                    onClick={this.onClickEditPaymentFromOrder.bind(
                      this,
                      i,
                      "Cancel"
                    )}
                    className="btn btn-danger btn-sm border-0">
                    <i className="fa fa-trash" />
                  </a>
                  {/*eslint-enable*/}
                </td>
                <td>
                  {option && (
                    <>
                      <button
                        className="btn btn-default margin-right-10"
                        onClick={this.onClickSendPayments.bind(
                          this,
                          i,
                          failed
                        )}>
                        Pay Now
                      </button>
                      <button
                        className="btn btn-default margin-right-10"
                        onClick={this.onClickSendPayments.bind(
                          this,
                          i,
                          failed,
                          true
                        )}>
                        Pay Instant
                      </button>
                      <button
                        className="btn btn-default margin-right-10"
                        onClick={this.onClickMarkAsPaid.bind(this, i)}>
                        Mark As Paid
                      </button>
                    </>
                  )}
                </td>
              </tr>
              {failed && i.error_list.length > 0 && (
                <tr>
                  <td colSpan="7">
                    <label style={{ color: "#a9a9a9" }}>
                      {i.error_list.map((i) => (
                        <p>
                          {moment(i.date).format("Do MMM YYYY")} -{" "}
                          {i.error_message}
                        </p>
                      ))}
                    </label>
                  </td>
                </tr>
              )}
            </>
          ))}
        </tbody>
      </table>
    );
  }

  onClickShowFailedPayments() {
    this.setState({ showFailedPayments: !this.state.showFailedPayments });
  }

  render() {
    return (
      <div>
        {this.renderConfirmationAlert()}
        {this.renderEditPaymentModal()}
        {this.renderPaidConfirmationAlert()}
        <div className="container-fluid">
          <TitleBox title="Add / Process Member Payment" />
          <div className="gap20" />
          <div className="card">
            {this.state.failedPayments.length > 0 && (
              <div className="list-group list-group-flush">
                <div
                  className="list-group-item pointer-click"
                  onClick={this.onClickShowFailedPayments.bind(this)}>
                  <h5 className="noMargin pull-left text-danger">
                    You have failed payments. Click here to view them
                  </h5>

                  <div className="pull-right">
                    <i
                      className={
                        this.state.showFailedPayments
                          ? "fa fa-chevron-up"
                          : "fa fa-chevron-down"
                      }
                      aria-hidden="true"
                    />
                  </div>
                </div>
                <VelocityTransitionGroup
                  enter={{ animation: "slideDown" }}
                  leave={{ animation: "slideUp" }}>
                  {this.state.showFailedPayments === true && (
                    <div className="list-group-item">
                      <div className="table-responsive">
                        {this.renderPayments(
                          this.state.failedPayments,
                          true,
                          true
                        )}
                      </div>
                    </div>
                  )}
                </VelocityTransitionGroup>
              </div>
            )}
            <div className="list-group list-group-flush">
              {this.state.pendingPayments.length > 0 && (
                <div>
                  <div className="list-group-item">
                    <h5 className="noMargin">Pending Payments</h5>
                  </div>
                  <div className="list-group-item">
                    <div className="table-responsive">
                      {this.renderPayments(this.state.pendingPayments, true)}
                      <div className="text-right">
                        {/*eslint-disable-next-line*/}
                        <a
                          className="btn btn-primary"
                          onClick={this.onClickSendPayments.bind(this)}>
                          Send All Payments
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {this.state.unreleasedPayments.length > 0 && (
                <div>
                  <div className="list-group-item">
                    <h5 className="noMargin">Unreleased Payments</h5>
                  </div>
                  <div className="list-group-item">
                    <div className="table-responsive">
                      {this.renderPayments(this.state.unreleasedPayments, true)}
                    </div>
                  </div>
                </div>
              )}

              {this.state.payoutPayments.length > 0 && (
                <div>
                  <div className="list-group-item">
                    <h5 className="noMargin">
                      Payments Without Payout Details
                    </h5>
                  </div>
                  <div className="list-group-item">
                    <div className="table-responsive">
                      {this.renderPayments(this.state.payoutPayments, false)}
                    </div>
                  </div>
                </div>
              )}
              {this.state.showBalance && (
                <>
                  <div className="list-group-item">
                    <h5 className="noMargin">Account Balance</h5>
                  </div>
                  <div className="container-fluid">
                    <div className="gap20" />
                    <div className="card">
                      <div className="container-fluid">
                        <div className="gap10" />

                        <div className="havingHelpText">
                          {" "}
                          <h5 className="noMargin">
                            Owed : &nbsp;
                            <b>${this.state.amountOwed}</b>
                          </h5>
                        </div>
                        <HelpTextDiv
                          id="idPaymentOwnedAmount"
                          label="Learn more about the Owned Amount"
                          type="addMemberPayment"
                          isInline="true"
                        />
                        <div className="gap10" />

                        <div className="havingHelpText">
                          {" "}
                          <h5 className="noMargin">
                            Available : &nbsp;
                            <b>${this.state.amountAvailable}</b>
                          </h5>
                        </div>
                        <HelpTextDiv
                          id="idPaymentAvailableAmount"
                          label="Learn more about the Available Amount"
                          type="addMemberPayment"
                          isInline="true"
                        />
                        <div className="gap10" />

                        <div className="havingHelpText">
                          <h5 className="noMargin">
                            Pending : &nbsp;
                            <b>${this.state.amountPending}</b>
                          </h5>
                        </div>
                        <HelpTextDiv
                          id="idPaymentPendingAmount"
                          label="Learn more about the Pending Amount"
                          type="addMemberPayment"
                          isInline="true"
                        />
                        <div className="gap10" />
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div className="list-group-item">
                <h5 className="noMargin">New Payment</h5>
              </div>
              <div className="container-fluid">
                <div className="gap20" />
                <div className="card">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-sm-4">
                        <HelpTextDiv
                          id="idPaymentUser"
                          label="Learn more about the User"
                          type="addMemberPayment"
                          isInline="false"
                          topMargin="10"
                        />
                        <TypeableSelect
                          key={this.state.keyUser}
                          name={"selectedUser"}
                          placeholder={"Select A User"}
                          selected={this.state.selectedUser}
                          onSelect={this.onSelectUser}
                          options={this.state.payUsers}
                        />
                      </div>
                      <div className="col-sm-4">
                        <HelpTextDiv
                          id="idPaymentAmount"
                          label="Learn more about the Amount"
                          type="addMemberPayment"
                          isInline="false"
                          topMargin="10"
                        />
                        <div
                          className={
                            this.state.amount !== ""
                              ? "mdInput mdFocussed"
                              : "mdInput"
                          }>
                          <label>Enter Amount</label>
                          <input
                            type="text"
                            name="amount"
                            value={this.state.amount}
                            onChange={(event) => this.handleInputChange(event)}
                          />
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <HelpTextDiv
                          id="idPaymentNotes"
                          label="Learn more about the Notes"
                          type="addMemberPayment"
                          isInline="false"
                          topMargin="10"
                        />
                        <div
                          className={
                            this.state.notes !== ""
                              ? "mdInput mdFocussed"
                              : "mdInput"
                          }>
                          <label>Payment Notes</label>
                          <input
                            type="text"
                            name="notes"
                            value={this.state.notes}
                            onChange={(event) => this.handleInputChange(event)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="gap20" />
                    <div className="text-right">
                      {/*eslint-disable-next-line*/}
                      <a
                        className="btn btn-primary"
                        onClick={this.onClickAddPayment.bind(this)}>
                        Add
                      </a>
                    </div>
                    <div className="gap20" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.authReducer.users,
  userData: state.authReducer.userData,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddMemberPayment);
