import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { showNotification } from "../../../helpers";
import {
  callCreateMessage,
  callGetSystemTagUsingName,
  callAddMemberTag,
  callGetUsersByQuery,
  callProcessTextMessageRule,
} from "../../../services";
import config from "../../../helpers/config";
import TitleBox from "../../../components/general/titleBox";
import RadioTag from "../../../components/form/radioTag";
import { callGetSettings } from "../../../services/settingService";

class CreateMessage extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    let locationState = this.props.history.location.state;

    let userInfo = locationState.userInfo;
    let from = locationState.from || "admin";

    this.state = {
      from: from,
      userData: this.props.userData || {},
      userInfo: userInfo,
      users: [],
      title: "",
      message: "",
      personalMessageTagId: "",
      messageReceivedTagId: "",
      memberToId: "",
      userList: [],
      keyUser: Math.random(),
      isCompanyMessage: true,
      staffUsers: [],
    };
  }

  componentDidMount() {
    this.getData();
  }

  async getData() {
    await this.getSettings();
    this.getTrainerUsers();
    this.callGetTagByName();
  }

  async getSettings() {
    let d = await callGetSettings("messages");
    let settings = d.data;
    let messages = settings.messages || {};
    let isCompanyMessage = this.state.isCompanyMessage;
    if (messages.send_message_to === "individual") {
      isCompanyMessage = false;
    }
    let staffUsers = messages.staff_users || [];
    this.setState({ isCompanyMessage: isCompanyMessage, staffUsers });
  }

  componentWillReceiveProps(newProps) {
    if (newProps.userData) {
      this.setState({ userData: newProps.userData });
      this.getData();
    }
  }

  async getTrainerUsers() {
    /*eslint-disable*/
    let query = {
      query: {
        member_type: "staff",
        $select: ["_id", "name", "email_address"],
      },
    };
    /*eslint-enable*/
    let users = await callGetUsersByQuery(query);
    let userList = [];
    let staffUsers = this.state.staffUsers;
    users.data.forEach((element) => {
      if (
        element._id !== this.state.userData._id &&
        staffUsers.indexOf(element._id) > -1
      ) {
        userList.push({ name: element.name, value: element._id });
      }
    });
    this.setState({ userList, keyUser: Math.random() });
  }

  callGetTagByName() {
    let personalMessageTag = config.tags.personalMessageTag;

    callGetSystemTagUsingName(personalMessageTag).then((d) => {
      if (d.data.length > 0) {
        this.setState({ personalMessageTagId: d.data[0]._id });
      }
    });

    let messageReceivedTag = config.tags.messageReceivedTag;

    callGetSystemTagUsingName(messageReceivedTag).then((d) => {
      if (d.data.length > 0) {
        this.setState({ messageReceivedTagId: d.data[0]._id });
      }
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  onSelectUser(item, id) {
    this.setState({
      [id]: item[Object.keys(item)[0]].value,
    });
  }

  onClickSendReply() {
    // send reply

    if (this.state.title.trim().length === 0) {
      showNotification("error", "Please enter title", 3000);
      return;
    }

    if (this.state.message.trim().length === 0) {
      showNotification("error", "Please enter message", 3000);
      return;
    }

    if (this.state.from === "member" && !this.state.isCompanyMessage) {
      if (this.state.memberToId.trim().length === 0) {
        showNotification("error", "Please select message to", 3000);
        return;
      }
    }

    /*eslint-disable*/

    let message = {
      member_id: this.state.userData._id,
      message: this.state.message,
      status: "sent",
      time: new Date(),
    };

    let memberToId = this.state.memberToId;
    if (this.state.isCompanyMessage) {
      memberToId = "ffffffffffffffffffffffff";
    }

    // create the new one if no atq message
    let apiData = {
      member_from_id: this.state.userInfo._id,
      member_to_id: memberToId,
      assigned_staff_id: "",
      title: this.state.title,
      atq_feedback: false,
      message_list: [message],
      status: "open",
    };
    callCreateMessage(apiData).then((createData) => {
      if (createData._id) {
        let memberId = this.state.userInfo._id;
        if (this.state.from === "member") {
          this.processTextMessageRule();
        }
        this.callAddStaffTagAPI();
        if (this.state.from === "admin") {
          this.callAddMemberTagAPI(memberId);
        }
      }
    });

    /*eslint-enable*/
  }

  async processTextMessageRule() {
    let data = {
      action: "process-text-message-rule",
      data: {
        userId: this.state.userData._id,
        message: this.state.message,
      },
    };

    await callProcessTextMessageRule(data);
  }

  async callAddStaffTagAPI() {
    if (this.state.messageReceivedTagId) {
      for (let i = 0; i < this.state.userList.length; i++) {
        const element = this.state.userList[i];
        let apiData = {
          action: "add-member-tag",
          data: {
            /*eslint-disable*/
            member_id: element.value,
            tag_id: this.state.messageReceivedTagId,
            /*eslint-enable*/
          },
        };
        await callAddMemberTag(apiData);
      }
    }
    this.props.history.goBack();
  }

  callAddMemberTagAPI(memberId) {
    if (this.state.personalMessageTagId) {
      let apiData = {
        action: "add-member-tag",
        data: {
          /*eslint-disable*/
          member_id: memberId,
          tag_id: this.state.personalMessageTagId,
          /*eslint-enable*/
        },
      };
      callAddMemberTag(apiData);
    }
    this.props.history.goBack();
  }

  render() {
    let title = `Create New Message For ${this.state.userInfo.name}`;
    let from = this.state.from;
    if (from === "member") {
      title = "Create New Message";
    }
    return (
      <div className="container-fluid">
        <TitleBox title={title} showBackBtn={true} />
        <div className="gap20" />

        <div className="card">
          <div className="list-group-item responseButtons">
            <div className="mdInput">
              <label>Title</label>
              <textarea
                name="title"
                value={this.state.title}
                onChange={(event) => this.handleInputChange(event)}
              />
            </div>
            <div className="gap20" />
            <div className="mdInput">
              <label>Enter your message</label>
              <textarea
                name="message"
                value={this.state.message}
                onChange={(event) => this.handleInputChange(event)}
              />
            </div>
            <div className="gap20" />
            {from === "member" &&
              this.state.userList.length > 0 &&
              !this.state.isCompanyMessage && (
                <>
                  <div className="tabsHolder radioTabs">
                    <p>Send Message To</p>
                    <RadioTag
                      key={this.state.keyUser}
                      onCheckChange={this.onSelectUser.bind(this)}
                      labelList={this.state.userList}
                      id={"memberToId"}
                      selectType={"value"}
                      selectedList={[this.state.memberToId]}
                    />
                  </div>
                  <div className="gap20" />
                </>
              )}
            <div className="text-left">
              <button
                className="btn btn-primary hideResponseBody"
                onClick={this.onClickSendReply.bind(this)}>
                Create
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.authReducer.userData,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(CreateMessage);
