import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import $ from "jquery";
import { Scrollbars } from "react-custom-scrollbars";

import TitleBox from "../../../components/general/titleBox";
import FilterList from "../../../components/form/filterList";
import { filterByTags } from "../../../helpers/filters";
import { callGetCompanies, callDeleteCompany } from "../../../services";

class ViewCompany extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      companies: [],
      companiesBackup: [],
      filterList: [],
      selectedCompany: {}
    };
  }

  componentDidMount() {
    this.getCompanies();
  }

  getCompanies() {
    callGetCompanies().then(d => {
      this.setState({ companies: d.data, companiesBackup: d.data });
    });
  }

  filterUpdated(updatedFilter, name, type, searchFields) {
    let filterList = this.state.filterList;
    filterList[name] = {
      name: name,
      type: type,
      searchFields: searchFields,
      values: updatedFilter
    };
    this.setState(
      {
        filterList: filterList
      },
      () => {
        let data = filterByTags(this.state.companiesBackup, filterList);
        this.setState({
          companies: data
        });
      }
    );
  }

  onClickEditCompany(company) {
    this.props.history.push("/admin/add-company", { company: company });
  }

  onClickAddCompanyLicense(company) {
    this.props.history.push("/admin/add-company-license", { company: company });
  }

  onClickDeleteCompany(company) {
    this.setState({ selectedCompany: company }, () => {
      $(".modalAlertOverlay.deleteAlertOverlay,.modalAlert.deleteAlert").fadeIn(
        200
      );
    });
  }

  onClickViewCompanyLicense(company) {
    this.setState({ selectedCompany: company }, () => {
      $(
        ".modalAlertOverlay.licenseAlertOverlay,.modalAlert.licenseAlert"
      ).fadeIn(200);
    });
  }

  onClickSetupLicense(company) {
    this.props.history.push("/admin/setup-licenses", {
      companyId: company._id
    });
  }

  onClickNoAlert = () => {
    $(".modalAlertOverlay.deleteAlertOverlay,.modalAlert.deleteAlert").fadeOut(
      200
    );
  };

  onClickYesAlert = () => {
    callDeleteCompany(this.state.selectedCompany._id).then(d => {
      $(
        ".modalAlertOverlay.deleteAlertOverlay,.modalAlert.deleteAlert"
      ).fadeOut(200);
      this.getCompanies();
    });
  };

  renderConfirmationAlert() {
    let selectedCompany = this.state.selectedCompany;
    let name = "";
    if (selectedCompany.name) {
      name = selectedCompany.name;
    }
    return (
      <div>
        <div className="modalAlertOverlay deleteAlertOverlay" />
        <div className="modalAlert deleteAlert">
          <p>{`Are you sure you want to delete "${name}" Company?`}</p>
          <div className="alertFooterBtns">
            {/*eslint-disable*/}
            <a
              className="btn btn-default closeModal-No margin-right-10"
              onClick={this.onClickNoAlert}>
              No
            </a>
            <a
              className="btn btn-primary closeModal-Yes"
              onClick={this.onClickYesAlert}>
              Yes
            </a>
            {/*eslint-enable*/}
          </div>
        </div>
      </div>
    );
  }

  onClickCloseFromLicensesModal() {
    $(
      ".modalAlertOverlay.licenseAlertOverlay,.modalAlert.licenseAlert"
    ).fadeOut(200);
  }

  renderLicensesModal() {
    let modalHeight = window.innerHeight - 200;
    if (!this.state.selectedCompany._id) {
      return null;
    }

    let licensesInfo = this.state.selectedCompany.licenses_info;

    let licenses = this.state.selectedCompany.licenses;
    licenses.forEach(element => {
      let licenseD = licensesInfo.filter(d => {
        return d._id === element.id;
      });
      element["name"] = licenseD[0] ? licenseD[0].name : "";
    });

    return (
      <div>
        <div className="modalAlertOverlay licenseAlertOverlay" />
        <div className="modalAlert licenseAlert modal-lg big">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Licenses & Codes
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.onClickCloseFromLicensesModal.bind(this)}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div
              className="container-fluid modalInnerBody"
              style={{ maxHeight: modalHeight, overflowY: "hidden" }}>
              <Scrollbars
                autoHide
                autoHideTimeout={1000}
                autoHideDuration={200}
                style={{ height: modalHeight }}>
                <div className="gap20" />
                {licenses.map(license => (
                  <div className="card" key={license.name}>
                    <div className="list-group list-group-flush">
                      <div className="list-group-item">
                        <h5 className="noMargin pull-left firstLetter">
                          {license.name}
                        </h5>

                        <div className="pull-right">
                          <p>{license.count}</p>
                        </div>
                      </div>
                      <div className="list-group-item">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th>Code</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {license.valid_codes.map(code => (
                              <tr key={code.code}>
                                <td>{code.code}</td>
                                <td>
                                  {code.active ? (
                                    <p className="text-success">Active</p>
                                  ) : (
                                    <p className="text-danger">Inactive</p>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                ))}
              </Scrollbars>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderCompanies() {
    var returnVals = [];
    let items = this.state.companies;
    for (let i = 0; i < items.length; i++) {
      const element = items[i];

      let setup = "Incomplete";
      let fLic = element.licenses.filter(d => {
        return d.setup_completed === true;
      });

      if (fLic.length === element.licenses.length) {
        setup = "Complete";
      }

      returnVals.push(
        <tr key={element._id}>
          <td className="firstLetter">{element.name}</td>
          <td>{element.description}</td>
          <td>{element.email}</td>
          <td>{element.licenses.length}</td>
          {/*eslint-disable*/}
          <td>
            <a
              className={setup === "Complete" ? "text-success" : "text-danger"}
              onClick={this.onClickSetupLicense.bind(this, element)}>
              {setup}
            </a>
          </td>
          <td className="min-width-200">
            <a
              onClick={this.onClickDeleteCompany.bind(this, element)}
              className="btn btn-danger btn-sm margin-right-10 border-0">
              <i className="fa fa-trash" />
            </a>
            <a
              onClick={this.onClickAddCompanyLicense.bind(this, element)}
              className="btn btn-default btn-sm margin-right-10 border-0">
              <i className="fa fa-address-book" />
            </a>
            <a
              onClick={this.onClickViewCompanyLicense.bind(this, element)}
              className="btn btn-default btn-sm margin-right-10 border-0">
              <i className="fa fa-eye" />
            </a>
            <a
              onClick={this.onClickEditCompany.bind(this, element)}
              className="btn btn-default btn-sm border-0">
              <i className="fa fa-pencil" />
            </a>
          </td>
          {/*eslint-enable*/}
        </tr>
      );
    }
    return returnVals;
  }

  renderFilterView() {
    return (
      <div className="list-group-item">
        <FilterList
          label={"Search Companies"}
          name={"search"}
          type={"search"}
          searchFields={["name", "description"]}
          onFinish={this.filterUpdated.bind(this)}
        />
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderLicensesModal()}
        {this.renderConfirmationAlert()}
        <div className="container-fluid">
          <TitleBox title={"View Companies"} />
          <div className="gap20" />
          <div className="card">
            <div className="list-group list-group-flush">
              {this.renderFilterView()}
              <div className="list-group-item">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Description</th>
                      <th>Email</th>
                      <th>Licenses</th>
                      <th>Setup</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>{this.renderCompanies()}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewCompany);
