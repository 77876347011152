import * as api from "./api";
import { callApi } from "../helpers";

function callCreateExercise(data) {
  return callApi(api.service("exercise").create(data));
}

function callUpdateExercise(id, data) {
  return callApi(api.service("exercise").patch(id, data));
}

function callGetExercises() {
  return callApi(api.service("exercise").find({}));
}

function callGetExerciseById(id) {
  return callApi(api.service("exercise").get(id));
}

function callGetMemberProductUsingQuery(query) {
  return callApi(api.service("member-product").find(query));
}

function callCreateCustomProgram(data) {
  return callApi(api.service("custom-program").create(data));
}

function callUpdateCustomProgram(id, data) {
  return callApi(api.service("custom-program").patch(id, data));
}

function callGetCustomProgram(id) {
  return callApi(api.service("custom-program").get(id));
}

function callUpdateMemberProduct(id, data) {
  return callApi(api.service("member-product").patch(id, data));
}

function callGetCustomPrograms(query) {
  return callApi(api.service("custom-program").find(query));
}

function callCreateCustomProgramTemplate(data) {
  return callApi(api.service("custom-program-template").create(data));
}

function callGetCustomProgramTemplates(query) {
  return callApi(api.service("custom-program-template").find(query));
}

function callUpdateCustomProgramTemplate(id, data) {
  return callApi(api.service("custom-program-template").patch(id, data));
}

export {
  callCreateExercise,
  callUpdateExercise,
  callGetExercises,
  callGetMemberProductUsingQuery,
  callCreateCustomProgram,
  callUpdateCustomProgram,
  callUpdateMemberProduct,
  callGetCustomPrograms,
  callGetCustomProgram,
  callGetExerciseById,
  callCreateCustomProgramTemplate,
  callGetCustomProgramTemplates,
  callUpdateCustomProgramTemplate
};
