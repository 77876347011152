import {
  RESET_STATUS,
  GET_CERTIFICATE_SUCCESS,
  CREATE_CERTIFICATE_SUCCESS,
  CREATE_CERTIFICATE_FAILURE,
  UPDATE_CERTIFICATE_SUCCESS,
  UPDATE_CERTIFICATE_FAILURE
} from "../types";

import config from "../../helpers/config";

import {
  callCreateCertificate,
  callUpdateCertificate,
  callGetCertificates
} from "../../services/index";

/*
   This method is used to reset the status
   This will reset message and success states
   */
export const resetStatus = data => {
  return async dispatch => {
    dispatch({
      type: RESET_STATUS
    });
  };
};

export const createCertificate = data => {
  return async dispatch => {
    return callCreateCertificate(data)
      .then(data => {
        dispatch({
          type: CREATE_CERTIFICATE_SUCCESS,
          message: config.messages.createChlgQueSuccess,
          success: true
        });
      })
      .catch(err => {
        dispatch({
          type: CREATE_CERTIFICATE_FAILURE,
          message: config.messages.createChlgQueError,
          success: false
        });
      });
  };
};

export const updateCertificate = (id, data) => {
  return async dispatch => {
    return callUpdateCertificate(id, data)
      .then(data => {
        dispatch({
          type: UPDATE_CERTIFICATE_SUCCESS,
          message: config.messages.updateMessage,
          success: true
        });
      })
      .catch(err => {
        dispatch({
          type: UPDATE_CERTIFICATE_FAILURE,
          message: config.messages.createChlgQueError,
          success: false
        });
      });
  };
};

export const getCertificates = data => {
  return async dispatch => {
    return callGetCertificates(data).then(data => {
      dispatch({
        type: GET_CERTIFICATE_SUCCESS,
        data: data
      });
    });
  };
};
