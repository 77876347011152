import React, { useState, forwardRef, useImperativeHandle } from "react";
import "./RadioTag.css";

const RadioTagV2 = forwardRef(({ groups }, ref) => {
  const [selectedValues, setSelectedValues] = useState({});
  const [additionalContent, setAdditionalContent] = useState({});

  useImperativeHandle(ref, () => ({
    getSelectedValues(group) {
      const values = selectedValues[group];
      const groupType = groups.find((g) => g.group === group)?.type;
      return groupType === "single" ? values?.[0] || null : values || [];
    },
  }));

  const toggleCheck = (
    id,
    additionalInfo,
    groupKey,
    type,
    additionalInfoType
  ) => {
    const groupSelectedValues = selectedValues[groupKey] || [];
    if (type === "single") {
      if (groupSelectedValues.includes(id)) {
        setSelectedValues((prev) => ({ ...prev, [groupKey]: [] }));
        setAdditionalContent((prev) => ({ ...prev, [groupKey]: null }));
      } else {
        setSelectedValues((prev) => ({ ...prev, [groupKey]: [id] }));
        if (additionalInfoType === "below") {
          setAdditionalContent((prev) => ({
            ...prev,
            [groupKey]: additionalInfo,
          }));
        }
      }
    } else {
      setSelectedValues((prev) => ({
        ...prev,
        [groupKey]: groupSelectedValues.includes(id)
          ? groupSelectedValues.filter((value) => value !== id)
          : [...groupSelectedValues, id],
      }));
      if (additionalInfoType === "below") {
        if (groupSelectedValues.includes(id)) {
          setAdditionalContent((prev) => ({ ...prev, [groupKey]: null }));
        } else {
          setAdditionalContent((prev) => ({
            ...prev,
            [groupKey]: additionalInfo,
          }));
        }
      }
    }
  };

  const isChecked = (id, groupKey) => {
    return selectedValues[groupKey]?.includes(id) || false;
  };

  const renderCard = (option, group) => {
    const isCheckedOption = isChecked(option.id, group.group);
    const additionalInfo =
      group.additionalInfoType === "below" ? null : option.additionalInfo;
    return (
      <div
        className="mpRadio-card"
        onClick={() =>
          toggleCheck(
            option.id,
            option.additionalInfo,
            group.group,
            group.type,
            group.additionalInfoType
          )
        }
        key={option.id}
        style={{
          minWidth: group.minWidth || "200px",
          maxWidth: group.maxWidth || "calc(33.333% - 10px)",
          flex: group.maxWidth
            ? `1 1 ${group.maxWidth}`
            : "1 1 calc(33.333% - 10px)",
        }}>
        <div className="mpRadio-content-wrapper">
          <div
            className={`mpRadio-icon ${isCheckedOption ? "checked" : ""}`}
            id={option.id}></div>
          <div className="mpRadio-content">
            <p className="mpRadio-title">{option.title}</p>
            <p className="mpRadio-subtitle">{option.subtitle}</p>
          </div>
        </div>
        {option.image && (
          <img
            src={option.image}
            alt={option.title}
            className="mpRadio-image"
          />
        )}
        {isCheckedOption && additionalInfo && (
          <div
            className="mpRadio-additional-info additionalInfoContent"
            dangerouslySetInnerHTML={{ __html: additionalInfo }}
          />
        )}
      </div>
    );
  };

  return (
    <div className="mpRadio-set">
      {groups.map((group) => (
        <div key={group.group}>
          <div className="mpRadio-container">
            {group.options.map((option) => renderCard(option, group))}
          </div>
          {group.additionalInfoType === "below" && (
            <div
              className="mpRadio-additional-info additionalInfoContent"
              dangerouslySetInnerHTML={{
                __html: additionalContent[group.group] || "",
              }}
            />
          )}
        </div>
      ))}
    </div>
  );
});

export default RadioTagV2;
