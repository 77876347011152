import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { saveAs } from "file-saver";

import TitleBox from "../../../components/general/titleBox";

import {
  callUpdatePdfTemplate,
  callCreatePdfTemplate,
  uploadImage,
  callGetPdfTemplateById,
} from "../../../services";
import {
  getParameterByName,
  imageUrl,
  showNotification,
  slugify,
  toPromise,
} from "../../../helpers";
import RadioTag from "../../../components/form/radioTag";
import { arrayMove } from "../../../packages/mp-content-editor/helpers";
import { SortableTaskReportFieldList } from "../../../components/form/sortableList";
import Checkbox from "../../../components/form/checkBox";

class AddPDFTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    let pdfId = getParameterByName("id") || 0;

    this.state = {
      pdfId: pdfId,
      name: "",
      type: "task",
      pages: [],
      keyType: Math.random(),
      pageName: "",
      svgFile: "",
      svgFileName: "",
      jsonFile: "",
      jsonFileName: "",
      field: "none",
      pageType: "svg",
      keyField: Math.random(),
      selectedPageIndex: -1,
      enabledCustomer: false,
      enabledStaff: true,
      keyEnabledCustomer: Math.random(),
      keyEnabledStaff: Math.random(),
    };
  }

  componentDidMount() {
    if (this.state.pdfId !== 0) {
      this.getPdfTemplateInfo();
    }
  }

  async getPdfTemplateInfo() {
    const response = await callGetPdfTemplateById(this.state.pdfId);
    if (response._id) {
      this.setState({
        name: response.name,
        type: response.type,
        keyType: Math.random(),
        pages: response.pages,
        enabledCustomer: response.customer,
        enabledStaff: response.staff,
        keyEnabledCustomer: Math.random(),
        keyEnabledStaff: Math.random(),
      });
    }
  }

  resetProps() {
    this.setState({
      name: "",
      type: "task",
      pages: [],
      keyType: Math.random(),
      enabledCustomer: false,
      enabledStaff: true,
      keyEnabledCustomer: Math.random(),
      keyEnabledStaff: Math.random(),
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  onSelectRadio(item, id) {
    let value = item[Object.keys(item)[0]].value;
    this.setState({ [id]: value });
  }

  onSelectRadioType(item, id) {
    this.setState({ [id]: item[Object.keys(item)[0]].value });
  }

  async onClickAddPage() {
    /*eslint-disable*/

    if (!this.state.pageName) {
      showNotification("error", "Page Name is required", 4000);
      return;
    }

    let svgFileName = this.state.svgFileName;

    if (this.state.svgFile) {
      let fileName = slugify(this.state.pageName);
      let resp = await uploadImage(this.state.svgFile, fileName);
      let data = await resp.json();
      svgFileName = data.file_name;
    }

    let jsonFileName = this.state.jsonFileName;

    if (this.state.jsonFile) {
      let fileName = `${slugify(this.state.pageName)}-json`;
      let resp = await uploadImage(this.state.jsonFile, fileName);
      let data = await resp.json();
      jsonFileName = data.file_name;
    }

    let pages = this.state.pages;

    let page = {
      name: this.state.pageName,
      svg_file: svgFileName,
      field: this.state.field,
      json_file: jsonFileName,
      type: this.state.pageType,
    };
    /*eslint-enable*/

    if (this.state.selectedPageIndex > -1) {
      pages[this.state.selectedPageIndex] = page;
    } else {
      pages.push(page);
    }

    console.log(jsonFileName);

    this.setState({
      pages: pages,
      selectedPageIndex: -1,
      pageName: "",
      field: "none",
      svgFile: "",
      jsonFile: "",
      pageType: "svg",
      svgFileName: "",
      keyField: Math.random(),
    });
  }

  onClickCancelPage(index) {
    this.setState({
      pageName: "",
      selectedPageIndex: -1,
      field: "none",
      svgFile: "",
      svgFileName: "",
      keyField: Math.random(),
    });
  }

  onFileChange(e) {
    e.preventDefault();
    let file = e.target.files[0];
    this.setState({ svgFile: file });
  }

  onJSONFileChange(e) {
    e.preventDefault();
    let file = e.target.files[0];
    this.setState({ jsonFile: file });
  }

  onSortEndPages = ({ oldIndex, newIndex }) => {
    let pages = this.state.pages;
    pages = arrayMove(pages, oldIndex, newIndex);

    this.setState({
      pages: pages,
    });
  };

  onPressRemovePage(e) {
    let index = e.target.dataset["value"];
    let pages = this.state.pages;
    pages.splice(index, 1);
    this.setState({
      pages: pages,
    });
  }

  onPressEditPage(e) {
    let index = e.target.dataset["value"];
    let pages = this.state.pages;

    let page = pages[index];

    this.setState({
      pageName: page.name,
      field: page.field,
      svgFileName: page.svg_file,
      selectedPageIndex: index,
      pageType: page.type,
      jsonFileName: page.json_file,
      keyField: Math.random(),
    });
  }

  toggleEnabledCustomer = (check) => {
    this.setState({ enabledCustomer: check });
  };

  toggleEnabledStaff = (check) => {
    this.setState({ enabledStaff: check });
  };

  async onPressSave() {
    if (this.state.name.trim().length === 0) {
      showNotification("error", "Name is required", 4000);
      return;
    }

    if (this.state.pages.length === 0) {
      showNotification("error", "Atlease one page is required", 4000);
      return;
    }

    /*eslint-disable*/
    let apiData = {
      name: this.state.name,
      type: this.state.type,
      pages: this.state.pages,
      customer: this.state.enabledCustomer,
      staff: this.state.enabledStaff,
    };

    if (this.state.pdfId !== 0) {
      let [err, data] = await toPromise(
        callUpdatePdfTemplate(this.state.pdfId, apiData)
      );

      /*eslint-enable*/
      if (err) {
        showNotification("error", err.message, 5000);
      } else {
        showNotification("success", "PDF Template updated successfully", 4000);
      }
    } else {
      /*eslint-disable*/
      let [err, data] = await toPromise(callCreatePdfTemplate(apiData));
      /*eslint-enable*/
      if (err) {
        showNotification("error", err.message, 5000);
      } else {
        showNotification("success", "PDF Template created successfully", 4000);
        this.resetProps();
      }
    }
  }

  render() {
    return (
      <div>
        <div className="container-fluid">
          <TitleBox
            title={
              this.state.pdfId === 0 ? "Add PDF Template" : "Edit PDF Template"
            }
            showBackBtn={this.state.pdfId !== 0}
          />
          <div className="gap20" />
          <div className="card">
            <div className="container-fluid">
              <div className="gap20" />

              <div
                className={
                  this.state.name !== "" ? "mdInput mdFocussed" : "mdInput"
                }>
                <label>Name</label>
                <input
                  type="text"
                  name="name"
                  onChange={this.handleInputChange.bind(this)}
                  value={this.state.name}
                />
              </div>
              <div className="gap20" />
              <div className="radioBHolder">
                <div className="tabsHolder radioTabs">
                  <p>Type</p>
                  <RadioTag
                    key={this.state.keyType}
                    onCheckChange={this.onSelectRadio.bind(this)}
                    labelList={[
                      { name: "Task", value: "task" },
                      { name: "Invoice", value: "invoice" },
                    ]}
                    id={"type"}
                    selectedList={[this.state.type]}
                    selectType={"value"}
                  />
                </div>
              </div>
              <div className="gap20" />
              <Checkbox
                key={this.state.keyEnabledCustomer}
                label={"Enabled for Customer"}
                isChecked={this.state.enabledCustomer}
                handleCheckboxChange={this.toggleEnabledCustomer.bind(this)}
              />
              <div className="gap20" />
              <Checkbox
                key={this.state.keyEnabledStaff}
                label={"Enabled for Staff"}
                isChecked={this.state.enabledStaff}
                handleCheckboxChange={this.toggleEnabledStaff.bind(this)}
              />
              <div className="gap30" />
              <div className="havingHelpText">
                <h5>Pages</h5>
              </div>
              <div className="gap20" />
              <div className="card">
                <div className="container-fluid">
                  <div className="gap20" />
                  <div className="row">
                    <div className="col-sm-4">
                      <div
                        className={
                          this.state.pageName !== ""
                            ? "mdInput mdFocussed"
                            : "mdInput"
                        }>
                        <label>Page Name</label>
                        <input
                          type="text"
                          name="pageName"
                          value={this.state.pageName}
                          onChange={(event) => this.handleInputChange(event)}
                        />
                      </div>
                      <div className="gap10" />
                    </div>
                  </div>
                  <div className="gap20" />
                  <div className="row">
                    <div className="col-sm-6">
                      <p>Select Type</p>
                      <div className="tabsHolder radioTabs">
                        <RadioTag
                          key={this.state.keyField}
                          onCheckChange={this.onSelectRadioType.bind(this)}
                          labelList={[
                            { name: "SVG", value: "svg" },
                            { name: "PDF", value: "pdf" },
                          ]}
                          id={"pageType"}
                          selectedList={[this.state.pageType]}
                          selectType={"value"}
                        />
                      </div>
                    </div>
                  </div>
                  {(this.state.field !== "images" ||
                    this.state.pageType === "svg") && (
                    <>
                      {this.state.pageType === "pdf" && (
                        <>
                          <div className="gap20" />
                          <p>Select JSON File</p>
                          <input
                            type="file"
                            name="FileUpload"
                            onChange={this.onJSONFileChange.bind(this)}
                          />
                        </>
                      )}
                      <div className="gap20" />
                      {this.state.jsonFileName && (
                        <>
                          <button
                            className="btn btn-default btn-sm"
                            onClick={() => {
                              saveAs(
                                imageUrl(this.state.jsonFileName),
                                this.state.jsonFileName
                              );
                            }}>
                            Download File
                          </button>
                          <div className="gap20" />
                        </>
                      )}

                      <p>Select File</p>
                      <input
                        type="file"
                        name="FileUpload"
                        onChange={this.onFileChange.bind(this)}
                      />
                      <div className="gap20" />

                      {this.state.svgFileName && (
                        <>
                          <button
                            className="btn btn-default btn-sm"
                            onClick={() => {
                              saveAs(
                                imageUrl(this.state.svgFileName),
                                this.state.svgFileName
                              );
                            }}>
                            Download File
                          </button>
                          <div className="gap20" />
                        </>
                      )}
                    </>
                  )}
                  <div className="gap20" />
                  <div className="row">
                    <div className="col-sm-6">
                      <p>Select Field</p>
                      <div className="tabsHolder radioTabs">
                        <RadioTag
                          key={this.state.keyField}
                          onCheckChange={this.onSelectRadioType.bind(this)}
                          labelList={[
                            { name: "None", value: "none" },
                            { name: "Task Images", value: "images" },
                          ]}
                          id={"field"}
                          selectedList={[this.state.field]}
                          selectType={"value"}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="text-right">
                    {this.state.selectedPageIndex > -1 && (
                      <button
                        className="btn btn-default margin-right-10"
                        onClick={this.onClickCancelPage.bind(this)}>
                        Cancel
                      </button>
                    )}
                    <button
                      className="btn btn-primary"
                      onClick={this.onClickAddPage.bind(this)}>
                      {this.state.selectedPageIndex > -1
                        ? "Update Page"
                        : "Add Page"}
                    </button>
                    <div className="gap20" />
                  </div>
                </div>
              </div>
              <div className="gap20" />
              {this.state.pages.length > 0 && (
                <div className="card">
                  <div className="container-fluid">
                    <div className="gap20" />
                    <h5 className="noMargin">Added Pages</h5>
                    <div className="gap20" />
                    <SortableTaskReportFieldList
                      distance={10}
                      task={true}
                      items={this.state.pages}
                      onSortEnd={this.onSortEndPages}
                      onPressEdit={this.onPressEditPage.bind(this)}
                      onPressRemove={this.onPressRemovePage.bind(this)}
                    />
                    <div className="gap20" />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="text-right container-fluid">
            <button
              className="btn btn-primary"
              onClick={() => this.onPressSave()}>
              Submit
            </button>
          </div>
          <div className="gap20" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddPDFTemplate);
