export const LOGIN_SUCCESS = "auth/LOGIN_SUCCESS";
export const LOGIN_FAILURE = "auth/LOGIN_FAILURE";

export const CREATE_USER_SUCCESS = "auth/CREATE_USER_SUCCESS";
export const CREATE_USER_FAILURE = "auth/CREATE_USER_FAILURE";
export const GET_USER_SUCCESS = "auth/GET_USER_SUCCESS";

export const UPDATE_USER_SUCCESS = "auth/UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "auth/UPDATE_USER_FAILURE";

export const RESET_STATUS = "core/RESET_STATUS";
export const RESET_LOGGEDIN_USER = "core/RESET_LOGGEDIN_USER";

export const CREATE_CHLG_QUE_SUCCESS = "challenge/CREATE_CHLG_QUE_SUCCESS";
export const CREATE_CHLG_QUE_FAILURE = "challenge/CREATE_CHLG_QUE_FAILURE";

export const UPDATE_CHLG_QUE_SUCCESS = "challenge/UPDATE_CHLG_QUE_SUCCESS";
export const UPDATE_CHLG_QUE_FAILURE = "challenge/UPDATE_CHLG_QUE_FAILURE";

export const CREATE_CHLG_ITEM_SUCCESS = "challenge/CREATE_CHLG_ITEM_SUCCESS";
export const CREATE_CHLG_ITEM_FAILURE = "challenge/CREATE_CHLG_ITEM_FAILURE";

export const UPDATE_CHLG_ITEM_SUCCESS = "challenge/UPDATE_CHLG_ITEM_SUCCESS";
export const UPDATE_CHLG_ITEM_FAILURE = "challenge/UPDATE_CHLG_ITEM_FAILURE";

export const CREATE_CHLG_SUCCESS = "challenge/CREATE_CHLG_SUCCESS";
export const CREATE_CHLG_FAILURE = "challenge/CREATE_CHLG_FAILURE";

export const UPDATE_CHLG_SUCCESS = "challenge/UPDATE_CHLG_SUCCESS";
export const UPDATE_CHLG_FAILURE = "challenge/UPDATE_CHLG_FAILURE";

export const CREATE_ARTICLE_SUCCESS = "article/CREATE_ARTICLE_SUCCESS";
export const CREATE_ARTICLE_FAILURE = "article/CREATE_ARTICLE_FAILURE";

export const UPDATE_ARTICLE_SUCCESS = "article/UPDATE_ARTICLE_SUCCESS";
export const UPDATE_ARTICLE_FAILURE = "article/UPDATE_ARTICLE_FAILURE";

export const GET_ARTICLE_SUCCESS = "article/GET_ARTICLE_SUCCESS";
export const GET_ARTICLE_CATEGORY_SUCCESS =
  "article/GET_ARTICLE_CATEGORY_SUCCESS";

export const GET_CHLG_QUE_SUCCESS = "challenge/GET_CHLG_QUE_SUCCESS";
export const GET_CHLG_ITEM_SUCCESS = "challenge/GET_CHLG_ITEM_SUCCESS";
export const GET_CHLG_SUCCESS = "challenge/GET_CHLG_SUCCESS";

export const GET_SYSTEM_TAG_SUCCESS = "challenge/GET_SYSTEM_TAG_SUCCESS";
export const CREATE_SYSTEM_TAG_SUCCESS = "challenge/CREATE_SYSTEM_TAG_SUCCESS";
export const CREATE_SYSTEM_TAG_FAILURE = "challenge/CREATE_SYSTEM_TAG_FAILURE";

export const UPDATE_SYSTEM_TAG_SUCCESS = "challenge/UPDATE_SYSTEM_TAG_SUCCESS";
export const UPDATE_SYSTEM_TAG_FAILURE = "challenge/UPDATE_SYSTEM_TAG_FAILURE";

export const GET_GENERIC_TAG_SUCCESS = "challenge/GET_GENERIC_TAG_SUCCESS";
export const CREATE_GENERIC_TAG_SUCCESS =
  "challenge/CREATE_GENERIC_TAG_SUCCESS";
export const CREATE_GENERIC_TAG_FAILURE =
  "challenge/CREATE_GENERIC_TAG_FAILURE";

export const CREATE_CONTENT_TEMPLATE_SUCCESS =
  "article/CREATE_CONTENT_TEMPLATE_SUCCESS";
export const CREATE_CONTENT_TEMPLATE_FAILURE =
  "article/CREATE_CONTENT_TEMPLATE_FAILURE";
export const GET_CONTENT_TEMPLATE_SUCCESS =
  "article/GET_CONTENT_TEMPLATE_SUCCESS";

export const UPDATE_CONTENT_TEMPLATE_SUCCESS =
  "article/UPDATE_CONTENT_TEMPLATE_SUCCESS";
export const UPDATE_CONTENT_TEMPLATE_FAILURE =
  "article/UPDATE_CONTENT_TEMPLATE_FAILURE";

export const GET_EMAIL_SUCCESS = "email/GET_EMAIL_SUCCESS";
export const CREATE_EMAIL_SUCCESS = "email/CREATE_EMAIL_SUCCESS";
export const CREATE_EMAIL_FAILURE = "email/CREATE_EMAIL_FAILURE";

export const UPDATE_EMAIL_SUCCESS = "email/UPDATE_EMAIL_SUCCESS";
export const UPDATE_EMAIL_FAILURE = "email/UPDATE_EMAIL_FAILURE";

export const GET_CERTIFICATE_SUCCESS = "certificate/GET_CERTIFICATE_SUCCESS";
export const CREATE_CERTIFICATE_SUCCESS =
  "certificate/CREATE_CERTIFICATE_SUCCESS";
export const CREATE_CERTIFICATE_FAILURE =
  "certificate/CREATE_CERTIFICATE_FAILURE";

export const UPDATE_CERTIFICATE_SUCCESS =
  "certificate/UPDATE_CERTIFICATE_SUCCESS";
export const UPDATE_CERTIFICATE_FAILURE =
  "certificate/UPDATE_CERTIFICATE_FAILURE";

export const CREATE_CUSTOMER_ORDER_SUCCESS =
  "customer/CREATE_CUSTOMER_ORDER_SUCCESS";
export const CREATE_CUSTOMER_ORDER_FAILURE =
  "customer/CREATE_CUSTOMER_ORDER_FAILURE";

export const CREATE_STRIPE_PLAN_SUCCESS = "CREATE_STRIPE_PLAN_SUCCESS";
export const CREATE_STRIPE_PLAN_FAILURE = "CREATE_STRIPE_PLAN_FAILURE";

export const GET_STRIPE_PLAN = "GET_STRIPE_PLAN";

export const CREATE_MEAL_PLAN_SUCCESS = "CREATE_MEAL_PLAN_SUCCESS";
export const CREATE_MEAL_PLAN_FAILURE = "CREATE_MEAL_PLAN_FAILURE";

export const UPDATE_MEAL_PLAN_SUCCESS = "UPDATE_MEAL_PLAN_SUCCESS";
export const UPDATE_MEAL_PLAN_FAILURE = "UPDATE_MEAL_PLAN_FAILURE";

export const GET_INGREDIENT_SUCCESS = "GET_INGREDIENT";

export const GET_MEAL_PLAN_SUCCESS = "GET_MEAL_PLAN";

export const CREATE_MARCI_CRICLE_SUCCESS = "CREATE_MARCI_CRICLE_SUCCESS";
export const CREATE_MARCI_CRICLE_FAILURE = "CREATE_MARCI_CRICLE_FAILURE";

export const UPDATE_MARCI_CRICLE_SUCCESS = "UPDATE_MARCI_CRICLE_SUCCESS";
export const UPDATE_MARCI_CRICLE_FAILURE = "UPDATE_MARCI_CRICLE_FAILURE";

export const GET_MARCI_CRICLE = "GET_MARCI_CRICLE";

export const CREATE_SPOTLIGHT_SUCCESS = "CREATE_SPOTLIGHT_SUCCESS";
export const CREATE_SPOTLIGHT_FAILURE = "CREATE_SPOTLIGHT_FAILURE";

export const UPDATE_SPOTLIGHT_SUCCESS = "UPDATE_SPOTLIGHT_SUCCESS";
export const UPDATE_SPOTLIGHT_FAILURE = "UPDATE_SPOTLIGHT_FAILURE";

export const GET_SPOTLIGHT = "GET_SPOTLIGHT";

export const CREATE_SYSTEM_UPDATE_SUCCESS = "CREATE_SYSTEM_UPDATE_SUCCESS";
export const CREATE_SYSTEM_UPDATE_FAILURE = "CREATE_SYSTEM_UPDATE_FAILURE";

export const UPDATE_SYSTEM_UPDATE_SUCCESS = "UPDATE_SYSTEM_UPDATE_SUCCESS";
export const UPDATE_SYSTEM_UPDATE_FAILURE = "UPDATE_SYSTEM_UPDATE_FAILURE";

export const GET_SYSTEM_UPDATE = "GET_SYSTEM_UPDATE";

export const UPDATE_ALERT_ICON = "UPDATE_ALERT_ICON";

export const REFRESH_CURRENT_USER = "REFRESH_CURRENT_USER";

export const REFRESH_NOTIFICATION_UPDATE = "REFRESH_NOTIFICATION_UPDATE";

export const CREATE_ATQ_SUCCESS = "challenge/CREATE_ATQ_SUCCESS";
export const CREATE_ATQ_FAILURE = "challenge/CREATE_ATQ_FAILURE";

export const CREATE_EXERCISE_SUCCESS = "customProgram/CREATE_EXERCISE_SUCCESS";
export const CREATE_EXERCISE_FAILURE = "customProgram/CREATE_EXERCISE_FAILURE";

export const UPDATE_EXERCISE_SUCCESS = "customProgram/UPDATE_EXERCISE_SUCCESS";
export const UPDATE_EXERCISE_FAILURE = "customProgram/UPDATE_EXERCISE_FAILURE";

export const GET_EXERCISE = "customProgram/GET_EXERCISE";
export const GET_MEMBER_PRODUCT = "customProgram/GET_MEMBER_PRODUCT";

export const CREATE_CUSTOM_PROGRAM_SUCCESS =
  "customProgram/CREATE_CUSTOM_PROGRAM_SUCCESS";
export const CREATE_CUSTOM_PROGRAM_FAILURE =
  "customProgram/CREATE_CUSTOM_PROGRAM_FAILURE";

export const GET_CUSTOM_PROGRAM = "customProgram/GET_CUSTOM_PROGRAM";

export const GET_MESSAGE_SUCCESS = "challenge/GET_MESSAGE_SUCCESS";

export const CREATE_PROGRESS_JOURNAL_SUCCESS =
  "challenge/CREATE_PROGRESS_JOURNAL_SUCCESS";
export const CREATE_PROGRESS_JOURNAL_FAILURE =
  "challenge/CREATE_PROGRESS_JOURNAL_FAILURE";

export const UPDATE_PROGRESS_JOURNAL_SUCCESS =
  "challenge/UPDATE_PROGRESS_JOURNAL_SUCCESS";
export const UPDATE_PROGRESS_JOURNAL_FAILURE =
  "challenge/UPDATE_PROGRESS_JOURNAL_FAILURE";

export const GET_LINK_SUCCESS = "link/GET_LINK_SUCCESS";
export const CREATE_LINK_SUCCESS = "link/CREATE_LINK_SUCCESS";
export const CREATE_LINK_FAILURE = "link/CREATE_LINK_FAILURE";

export const CREATE_CHECKOUT_ITEM_SUCCESS =
  "billing/CREATE_CHECKOUT_ITEM_SUCCESS";
export const CREATE_CHECKOUT_ITEM_FAILURE =
  "billing/CREATE_CHECKOUT_ITEM_FAILURE";

export const UPDATE_CHECKOUT_ITEM_SUCCESS =
  "billing/UPDATE_CHECKOUT_ITEM_SUCCESS";
export const UPDATE_CHECKOUT_ITEM_FAILURE =
  "billing/UPDATE_CHECKOUT_ITEM_FAILURE";

export const GET_CHECKOUT_ITEM_SUCCESS = "billing/GET_CHECKOUT_ITEM_SUCCESS";

export const CREATE_PROMO_CODE_SUCCESS = "billing/CREATE_PROMO_CODE_SUCCESS";
export const CREATE_PROMO_CODE_FAILURE = "billing/CREATE_PROMO_CODE_FAILURE";

export const GET_PROMO_CODE_SUCCESS = "billing/GET_PROMO_CODE_SUCCESS";

export const CREATE_UPSELL_ITEM_SUCCESS = "billing/CREATE_UPSELL_ITEM_SUCCESS";
export const CREATE_UPSELL_ITEM_FAILURE = "billing/CREATE_UPSELL_ITEM_FAILURE";

export const UPDATE_UPSELL_ITEM_SUCCESS = "billing/UPDATE_UPSELL_ITEM_SUCCESS";
export const UPDATE_UPSELL_ITEM_FAILURE = "billing/UPDATE_UPSELL_ITEM_FAILURE";

export const GET_UPSELL_ITEM_SUCCESS = "billing/GET_UPSELL_ITEM_SUCCESS";

export const CREATE_FUNNEL_PATH_SUCCESS = "billing/CREATE_FUNNEL_PATH_SUCCESS";
export const CREATE_FUNNEL_PATH_FAILURE = "billing/CREATE_FUNNEL_PATH_FAILURE";

export const UPDATE_FUNNEL_PATH_SUCCESS = "billing/UPDATE_FUNNEL_PATH_SUCCESS";
export const UPDATE_FUNNEL_PATH_FAILURE = "billing/UPDATE_FUNNEL_PATH_FAILURE";

export const GET_FUNNEL_PATH_SUCCESS = "billing/GET_FUNNEL_PATH_SUCCESS";

export const CREATE_MEMBER_PAYMENT_SUCCESS =
  "billing/CREATE_MEMBER_PAYMENT_SUCCESS";
export const CREATE_MEMBER_PAYMENT_FAILURE =
  "billing/CREATE_MEMBER_PAYMENT_FAILURE";

export const GET_MEMBER_PAYMENT_SUCCESS = "billing/GET_MEMBER_PAYMENT_SUCCESS";

export const CREATE_BTP_QUESTIONNAIRE_SUCCESS =
  "btp-questionnaire/CREATE_BTP_QUESTIONNAIRE_SUCCESS";
export const CREATE_BTP_QUESTIONNAIRE_FAILED =
  "btp-questionnaire/CREATE_BTP_QUESTIONNAIRE_FAILED";

export const IS_MARCI_CIRCLE_AVAILABLE = "auth/IS_MARCI_CIRCLE_AVAILABLE";
export const IS_MARCI_CIRCLE_HOLD_AVAILABLE =
  "auth/IS_MARCI_CIRCLE_HOLD_AVAILABLE";

export const SET_PAGE_HISTORY = "auth/SET_PAGE_HISTORY";

export const SAVE_FILTER = "general/SAVE_FILTER";
export const SAVE_FILTERS = "general/SAVE_FILTERS";

export const STORE_USER_SETTINGS = "general/STORE_USER_SETTINGS";
