import { getUniqueId, slugify } from "../../../helpers";
import {
  callCreateEmail,
  callCreateSystemTag,
  callUpdateEmail,
} from "../../../services";

class CheckoutItemOptions {
  _getSystemTagObject(tagName) {
    /*eslint-disable*/
    let apiData = {
      name: tagName,
      description: tagName,
      rules: [
        {
          limit_tag_per_days: "1",
        },
      ],
      additional_rules: [],
      events: [],
      notifications: [],
      product_list: [],
      generic_tag_list: [],
      zoom_meeting: {
        user_id: "",
        meeting_id: "",
      },
      type: "automated",
      created_from: "checkout-item",
    };
    /*eslint-enable*/
    return apiData;
  }

  async _createSystemTag(tagName) {
    let id = "";

    let newTag = await callCreateSystemTag(this._getSystemTagObject(tagName));
    id = newTag._id;
    return id;
  }

  async createEmail(checkoutItemName, emailInfo) {
    /*eslint-disable*/

    let includedTagName = `${slugify(checkoutItemName)}-${getUniqueId()}`;

    let includedTagId = await this._createSystemTag(includedTagName);

    let apiData = {
      email_type: "1",
      email_from: "",
      tag_list: [includedTagId],
      tag_list_excluded: [],
      generic_tag_list: [],
      name: checkoutItemName,
      description: checkoutItemName,
      status: "active",
      system: "false",
      type: "automated",
      send_date: "",
      timezone: "",
      delay: "",
      title: emailInfo.title,
      content: emailInfo.content,
      html: emailInfo.html,
      created_from: "checkout-item",
    };
    /*eslint-enable*/
    let email = await callCreateEmail(apiData);
    return { emailId: email._id, tagId: includedTagId };
  }

  async updateEmail(emailId, emailInfo) {
    let email = await callUpdateEmail(emailId, {
      content: emailInfo.content,
      html: emailInfo.html,
      title: emailInfo.title,
    });
    return email._id;
  }
}

export default CheckoutItemOptions;
