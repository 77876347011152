var uuid = require("uuid");

export function getUniqueId() {
  let id = uuid.v4();
  let strChars = id.substr(id.length - 12);

  return `mp${strChars}`;
}

export function arrayMove(arr, fromIndex, toIndex) {
  var element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
  return arr;
}

export function daysInMonth(month, year) {
  return new Date(year, month, 0).getDate();
}
