import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DatePicker from "react-date-picker";
import { Helmet } from "react-helmet";

import QuillEditor from "../../../components/form/reactQuill";
import AutoSuggest from "../../../components/form/autoSuggest";
import PageContent from "../../../components/editor/pageContent";
import TagSelect from "../../../components/form/tagSelect";
import TitleBox from "../../../components/general/titleBox";
import TypeableSelect from "../../../components/form/typeableSelect";
import HelpTextDiv from "../../../components/form/helpTextDiv";
import StatusSwitch from "../../../components/form/statusSwitch";

import {
  getEditorType,
  isFS,
  showNotification,
  slugify,
} from "../../../helpers";

import {
  callCreateArticleCategory,
  callCreateGenericTag,
  callGetUsersByQuery,
  callGetArticleById,
} from "../../../services";

import {
  createArticle,
  updateArticle,
  getArticleCategory,
  getGenericTags,
  resetStatus,
} from "../../../modules/actions/index";
import config from "../../../helpers/config";
import SelectAddImage from "../../../components/form/selectAddImage";
import { getItem } from "../../../helpers/storage";

class AddArticle extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    let articleInfo = {
      articleId: 0,
      articleTitle: "",
      articleSlug: "",
      pageContent: "",
      editorContent: {},
      shortVer: "",
      releaseDate: new Date(),
      videoUrl: "",
      categoryValue: "",
      categorySelected: {},
      subCategoryValue: "",
      subCategorySelected: {},
      fTags: { new: [], existing: [] },
      selectedTags: [],
      articleCategory: {},
      articleSubCategory: {},
      isActive: true,
      articleStatusLabel: "Currently Active",
      imgUrl: "",
      selectedStaffUser: { value: "", label: "" },
      editorType: isFS()
        ? "mp"
        : getItem("de") === "classic"
        ? "unlayer"
        : "mp",
      draftId: "",
      draftImage: "",
    };

    if (this.props.history.location.state !== undefined) {
      let article = this.props.history.location.state.article;

      articleInfo.articleId = article._id;
      articleInfo.articleTitle = article.title;
      articleInfo.articleSlug = article.article_slug || "";
      articleInfo.shortVer = article.short_version;
      articleInfo.pageContent = article.main_content;
      articleInfo.editorContent = article.editor_content;

      articleInfo.editorType = getEditorType(
        articleInfo.pageContent,
        articleInfo.editorContent
      );
      articleInfo.releaseDate = new Date(article.release_date);
      articleInfo.isActive = article.status === "active" ? true : false;
      articleInfo.articleStatusLabel = articleInfo.isActive
        ? "Currently Active"
        : "Currently Inactive";
      articleInfo.videoUrl = article.video_url;

      let tags = article.ftags || [];

      articleInfo.fTags = { new: [], existing: tags };
      articleInfo.selectedTags = tags || [];

      let category = article.category ? article.category[0] : { name: "" };
      let subCategory = article.sub_category
        ? article.sub_category[0]
        : { name: "" };

      articleInfo.articleCategory = category;
      articleInfo.articleSubCategory = subCategory;
      articleInfo.categoryValue = category.name;
      articleInfo.subCategoryValue = subCategory.name;
      articleInfo.categorySelected = category;
      articleInfo.subCategorySelected = subCategory;

      articleInfo.imgUrl = article.image;

      if (article.member_info) {
        articleInfo.selectedStaffUser = {
          value: article.member_info,
          label: article.member_info.name,
        };
      }

      articleInfo.draftId = article.draft_id || "";
      articleInfo.draftImage = article.draft_image || "";
    }

    this.state = {
      articleId: articleInfo.articleId,
      articleTitle: articleInfo.articleTitle,
      articleSlug: articleInfo.articleSlug,
      pageContent: articleInfo.pageContent,
      editorContent: articleInfo.editorContent,
      shortVer: articleInfo.shortVer,
      releaseDate: articleInfo.releaseDate,
      videoUrl: articleInfo.videoUrl,
      categoryValue: articleInfo.categoryValue,
      categorySelected: articleInfo.categorySelected,
      subCategoryValue: articleInfo.subCategoryValue,
      subCategorySelected: articleInfo.subCategorySelected,
      allCategories: [],
      tags: [],
      fTags: articleInfo.fTags,
      selectedTags: articleInfo.selectedTags,
      categories: [],
      subCategories: [],
      articleCategory: articleInfo.articleCategory,
      articleSubCategory: articleInfo.articleSubCategory,
      keyCategory: Math.random(),
      keySubcategory: Math.random(),
      keyTag: Math.random(),
      isActive: articleInfo.isActive,
      articleStatusLabel: articleInfo.articleStatusLabel,
      imgUrl: articleInfo.imgUrl,
      selectedStaffUser: articleInfo.selectedStaffUser,
      keyStaffUser: Math.random(),
      staffUsers: [],
      userData: this.props.userData || {},
      keySwitch: Math.random(),
      editorType: articleInfo.editorType,
      draftId: articleInfo.draftId,
      keyEditor: Math.random(),
      draftImage: articleInfo.draftImage,
      showAdvancedOptionsDetails: false,
    };
  }

  onChange = (event, { newValue }) => {
    this.setState({
      name: newValue,
    });
  };

  componentDidMount() {
    this.props.getArticleCategory();
    this.props.getGenericTags("article");
    this.getStaffUsers();
  }

  componentWillMount() {
    if (!this.state.isActive && this.state.articleId !== 0) {
      showNotification("warning", "This article is currently inactive.", 2000);
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.message) {
      if (newProps.success) {
        if (this.state.articleId === 0) {
          this.resetProps();
        }
        showNotification("success", newProps.message, 5000);
      } else {
        showNotification("error", newProps.message, 5000);
      }
      this.props.resetStatus();
    }

    if (newProps.articleCategories.data) {
      let data = newProps.articleCategories.data;
      let categories = data.filter((el) => {
        if (!el.parent_id) {
          return true;
        } else {
          return false;
        }
      });
      this.setState({
        allCategories: data,
        categories: categories,
        keyCategory: Math.random(),
      });
    }

    if (newProps.genericTags.data) {
      this.setState({
        tags: newProps.genericTags.data,
        keyTag: Math.random(),
      });
    }

    if (newProps.userData) {
      this.setState({ userData: newProps.userData });
    }
  }

  resetProps() {
    this.setState({
      articleTitle: "",
      articleSlug: "",
      pageContent: "",
      shortVer: "",
      releaseDate: new Date(),
      videoUrl: "",
      categoryValue: "",
      categorySelected: {},
      subCategoryValue: "",
      subCategorySelected: {},
      selectedTags: [],
      tags: [],
      fTags: { new: [], existing: [] },
      keyCategory: Math.random(),
      keySubcategory: Math.random(),
      keyTag: Math.random(),
      imgUrl: "",
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    if (name === "articleTitle") {
      let slug = slugify(value);
      this.setState({
        articleTitle: value,
      });
      if (!this.state.articleSlug) {
        this.setState({
          articleSlug: slug,
        });
      }
    } else {
      this.setState({
        [name]: value,
      });
    }
  }

  getStaffUsers() {
    /*eslint-disable*/
    let query = {
      query: {
        member_type: "staff",
        $select: ["_id", "email_address", "name"],
        populate: "no",
      },
    };
    /*eslint-enable*/

    callGetUsersByQuery(query).then((d) => {
      let fUsers = d.data;
      let staffUsers = [];
      let selectedStaffUser = this.state.selectedStaffUser;

      fUsers.forEach((element) => {
        staffUsers.push({ value: element, label: element.name });
        if (
          this.state.selectedStaffUser.label.length === 0 &&
          this.state.userData._id
        ) {
          if (element._id.toString() === this.state.userData._id.toString()) {
            selectedStaffUser = { value: element, label: element.name };
          }
        }
      });
      this.setState({
        staffUsers: staffUsers,
        keyStaffUser: Math.random(),
        selectedStaffUser: selectedStaffUser,
      });
    });
  }

  toggleActiveCheckbox = (check) => {
    this.setState({
      isActive: check,
      articleStatusLabel: check ? "Currently Active" : "Currently Inactive",
    });
  };

  checkAddCategory() {
    let categoryValue = this.state.categoryValue;
    let categorySelected = this.state.categorySelected;

    if (categorySelected._id) {
      this.setState({ articleCategory: categorySelected });
      return Promise.resolve(categorySelected);
    } else {
      let data = { name: slugify(categoryValue) };
      return callCreateArticleCategory(data);
    }
  }

  checkAddSubCategory(categorySelected) {
    let subCategoryValue = this.state.subCategoryValue;
    let subCategorySelected = this.state.subCategorySelected;

    if (subCategorySelected._id) {
      this.setState({ articleSubCategory: subCategorySelected });
      return Promise.resolve(subCategorySelected);
    } else {
      let data = {
        name: slugify(subCategoryValue),
        /* eslint-disable */
        parent_id: categorySelected._id,
        /* eslint-enable */
      };
      return callCreateArticleCategory(data).then((resp) => {
        this.setState({ articleSubCategory: resp });
        return resp;
      });
    }
  }

  checkForCategory() {
    return this.checkAddCategory().then((resp) => {
      this.setState({ articleCategory: resp });
      return this.checkAddSubCategory(resp);
    });
  }

  onPressViewArticle() {
    callGetArticleById(this.state.articleId).then((article) => {
      if (article.category && article.category.length > 0) {
        let category = article.category[0].name;
        let articleSlug = article.article_slug;
        let articleUrl = `${config.urls.siteUrl}/${category}/${articleSlug}`;
        window.open(articleUrl, "_blank");
      }
    });
  }

  onPressNewArticle() {
    if (this.state.articleTitle.trim().length === 0) {
      showNotification("error", "Artile Title is required", 4000);
      return;
    }
    if (this.state.categoryValue.length === 0) {
      showNotification("error", "Article Category is required", 4000);
      return;
    }
    if (this.state.subCategoryValue.length === 0) {
      showNotification("error", "Article Sub Category is required", 4000);
      return;
    }
    if (this.state.pageContent.trim().length === 0) {
      showNotification("error", "Full Article is required", 4000);
      return;
    }
    if (this.state.shortVer.trim().length === 0) {
      showNotification("error", "Short Version of article is required", 4000);
      return;
    }
    this.checkForCategory().then(() => {
      this.setupAPIcall();
    });
  }

  setupAPIcall() {
    let tags = this.state.fTags;
    if (tags.new.length > 0) {
      let data = [];
      tags.new.forEach((element) => {
        data.push({ name: element, type: "article" });
      });
      callCreateGenericTag(data).then((resp) => {
        let eTags = this.state.tags;
        this.setState({
          tags: eTags.concat(resp),
        });
        let newTagIds = resp.map((t) => t._id);
        let tagIds = tags.existing.map((t) => t._id);
        let finalTagIds = newTagIds.concat(tagIds);

        this.callAddArticleAPI(finalTagIds);
      });
    } else {
      let tagIds = tags.existing.map((t) => t._id);
      this.callAddArticleAPI(tagIds);
    }
  }

  callAddArticleAPI(tags) {
    let apiData = {
      title: this.state.articleTitle,
      /*eslint-disable */
      article_slug: this.state.articleSlug,
      article_category_id: this.state.articleCategory._id,
      article_subcategory_id: this.state.articleSubCategory._id,
      editor_content: this.state.editorContent,
      main_content: this.state.pageContent,
      short_version: this.state.shortVer,
      status: this.state.isActive ? "active" : "inactive",
      release_date: this.state.releaseDate,
      image: this.state.imgUrl,
      thumbnail: this.state.imgUrl,
      video_url: this.state.videoUrl,
      draft_id: this.state.draftId,
      draft_image: this.state.draftImage,
      /*eslint-enable*/
      tags: tags,
      author: this.state.selectedStaffUser.value._id,
    };

    if (this.state.articleId === 0) {
      this.props.createArticle(apiData);
    } else {
      this.props.updateArticle(this.state.articleId, apiData);
    }
  }

  onChangeCategoryValue(value) {
    this.setState({
      categoryValue: value,
    });
  }

  onSelectCategoryValue(value) {
    let subCategories = this.state.allCategories.filter((el) => {
      return el.parent_id === value._id;
    });
    this.setState({
      subCategories: subCategories,
      keySubcategory: Math.random(),
      categorySelected: value,
    });
  }

  onChangeSubCategoryValue(value) {
    this.setState({
      subCategoryValue: value,
    });
  }

  onSelectSubCategoryValue(value) {
    this.setState({
      subCategorySelected: value,
    });
  }

  onChangeShortVersion(value, text) {
    this.setState({
      shortVer: value,
    });
  }

  onChangeReleaseDate(value) {
    this.setState({
      releaseDate: value,
    });
  }

  removePhoto() {
    this.setState({
      existingImage: "",
    });
  }

  onSelectStaffUser = (user) => {
    this.setState({ selectedStaffUser: user });
  };

  onChangeTags = (tags) => {
    if (tags.length > 0) {
      let fTags = { new: [], existing: [] };
      tags.forEach((element) => {
        if (element.name) {
          fTags.existing.push(element);
        } else {
          fTags.new.push(element);
        }
      });
      this.setState({ fTags: fTags });
    }
  };

  onChangeImage = (image) => {
    this.setState({ imgUrl: image });
  };

  onChangeContent(html, content, draftId, draftImage) {
    this.setState({
      editorContent: content,
      pageContent: html,
      draftId: draftId,
      draftImage: draftImage,
      keyEditor: Math.random(),
    });
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>Add New Article</title>
        </Helmet>

        <div className="container-fluid">
          <TitleBox
            title={
              this.state.articleId === 0 ? "Create New Article" : "Edit Article"
            }
            showBackBtn={this.state.articleId !== 0}
          />
          <div className="gap20" />
          <div className="card">
            <div className="list-group list-group-flush">
              <div className="list-group-item">
                <div className="container-fluid">
                  <div className="gap20" />
                  <div className="text-right">
                    <button
                      className={"btn btn-info"}
                      onClick={() =>
                        this.setState({
                          showAdvancedOptionsDetails:
                            !this.state.showAdvancedOptionsDetails,
                        })
                      }>
                      {this.state.showAdvancedOptionsDetails
                        ? "Hide Advanced Options"
                        : "Show Advanced Options"}
                    </button>
                  </div>

                  <div className="havingHelpText">
                    <StatusSwitch
                      key={this.state.keySwitch}
                      isActive={this.state.isActive}
                      onChange={this.toggleActiveCheckbox.bind(this)}
                    />
                  </div>
                  <HelpTextDiv
                    id="idArticleStatus"
                    label="Learn more about the Article status"
                    type="addArticle"
                    isInline="true"
                  />
                  <div className="gap10" />

                  <HelpTextDiv
                    id="idArticleTitle"
                    label="Learn more about the Article Title"
                    type="addArticle"
                    isInline="false"
                  />
                  <div
                    className={
                      this.state.articleTitle !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>Article Title</label>
                    <input
                      type="text"
                      name="articleTitle"
                      value={this.state.articleTitle}
                      onChange={(event) => this.handleInputChange(event)}
                    />
                  </div>
                  <div className="gap20" />

                  <HelpTextDiv
                    id="idArticleUrlSlug"
                    label="Learn more about the Article Url/Slug"
                    type="addArticle"
                    isInline="false"
                  />
                  {this.state.showAdvancedOptionsDetails && (
                    <div
                      className={
                        this.state.articleSlug !== ""
                          ? "mdInput mdFocussed"
                          : "mdInput"
                      }>
                      <label>URL Name</label>
                      <input
                        type="text"
                        name="articleSlug"
                        value={this.state.articleSlug}
                        onChange={(event) => this.handleInputChange(event)}
                      />
                    </div>
                  )}
                  <div className="gap20" />

                  <HelpTextDiv
                    id="idArticleCategory"
                    label="Learn more about the Article Category"
                    type="addArticle"
                    isInline="false"
                  />
                  <div
                    className={
                      this.state.categoryValue !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>Article Category</label>
                    <AutoSuggest
                      key={this.state.keyCategory}
                      searchKey="name"
                      value={this.state.categoryValue}
                      data={this.state.categories}
                      onChange={this.onChangeCategoryValue.bind(this)}
                      onSelect={this.onSelectCategoryValue.bind(this)}
                    />
                  </div>
                  <div className="gap20" />

                  <HelpTextDiv
                    id="idArticleSubCategory"
                    label="Learn more about the Article Sub Category"
                    type="addArticle"
                    isInline="false"
                  />
                  <div
                    className={
                      this.state.subCategoryValue !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>Article Sub Category</label>
                    <AutoSuggest
                      key={this.state.keySubcategory}
                      searchKey="name"
                      value={this.state.subCategoryValue}
                      data={this.state.subCategories}
                      onChange={this.onChangeSubCategoryValue.bind(this)}
                      onSelect={this.onSelectSubCategoryValue.bind(this)}
                    />
                  </div>
                  <div className="gap40" />
                  <PageContent
                    key={this.state.keyEditor}
                    title={this.state.articleTitle}
                    screen={"add-article"}
                    html={this.state.pageContent}
                    content={this.state.editorContent}
                    onChangeData={this.onChangeContent.bind(this)}
                    pageName={"addArticle"}
                    onClickAlert={this.onPressNewArticle.bind(this)}
                    editorType={this.state.editorType}
                    previewType={"mp-preview"}
                    draftId={this.state.draftId}
                    draftImage={this.state.draftImage}
                  />
                  <div className="gap20" />

                  <HelpTextDiv
                    id="idArticleShortVersion"
                    label="Learn more about the Article Short Version"
                    type="addArticle"
                    isInline="false"
                  />
                  <QuillEditor
                    value={this.state.shortVer}
                    theme="bubble"
                    label="Short Version"
                    focused={this.state.shortVer.length > 0 ? true : false}
                    type="mdInput"
                    onChangeValue={this.onChangeShortVersion.bind(this)}
                  />
                  <div className="gap20" />

                  <HelpTextDiv
                    id="idArticleTag"
                    label="Learn more about the Article Tag"
                    type="addArticle"
                    isInline="false"
                  />
                  <TagSelect
                    id={"add-article-tag"}
                    key={this.state.keyTag}
                    selectedValues={this.state.selectedTags}
                    searchKey={"name"}
                    selectKey={"name"}
                    btnText={"Create New Tag"}
                    lblText={"Type a Tag"}
                    data={this.state.tags}
                    onChange={this.onChangeTags}
                    reload={() => {
                      this.props.getGenericTags("article");
                    }}
                  />
                  <div className="gap20" />
                  <SelectAddImage
                    type="article-image"
                    label="Article Image"
                    onChangeImage={this.onChangeImage}
                    image={this.state.imgUrl}
                  />
                  <div className="gap20" />
                  <div className="row">
                    <div className="col-md-6">
                      <HelpTextDiv
                        id="idArticleReleaseDate"
                        label="Learn more about the Article Release Date"
                        type="addArticle"
                        isInline="false"
                      />
                      <label className="inputFakeLabelFocussed">
                        Release Date
                      </label>
                      <div>
                        <DatePicker
                          value={this.state.releaseDate}
                          onChange={this.onChangeReleaseDate.bind(this)}
                        />
                      </div>
                      <div className="gap20" />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-6">
                      <HelpTextDiv
                        id="idArticleAuthor"
                        label="Learn more about the Article Author"
                        type="addArticle"
                        isInline="false"
                      />
                      <TypeableSelect
                        key={this.state.keyStaffUser}
                        name={"selectedUser"}
                        placeholder={"Author"}
                        selected={this.state.selectedStaffUser}
                        onSelect={this.onSelectStaffUser}
                        options={this.state.staffUsers}
                      />
                      <div className="gap10" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-right">
            {this.state.articleId !== 0 && (
              <button
                className="btn btn-default margin-right-10"
                onClick={() => this.onPressViewArticle()}>
                Preview
              </button>
            )}
            <button
              className="btn btn-primary"
              onClick={() => this.onPressNewArticle()}>
              Save
            </button>
          </div>
          <div className="gap40" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  message: state.articleReducer.message,
  success: state.articleReducer.success,
  articleCategories: state.articleReducer.articleCategories,
  genericTags: state.tagReducer.genericTags,
  userData: state.authReducer.userData,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      resetStatus,
      createArticle,
      updateArticle,
      getGenericTags,
      getArticleCategory,
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(AddArticle);
