import { SAVE_FILTER, SAVE_FILTERS } from "../types";

export const saveFilter = (data) => {
  return async (dispatch) => {
    dispatch({
      type: SAVE_FILTER,
      data: data,
    });
  };
};

export const saveFilters = (data) => {
  return async (dispatch) => {
    dispatch({
      type: SAVE_FILTERS,
      data: data,
    });
  };
};
