import { atom } from "recoil";

export const jsonState = atom({
  key: "jsonState",
  dangerouslyAllowMutability: true,
  default: {
    contentEditor: {
      id: "",
      contentId: "",
      editorId: 0,
      type: "email",
      pageTitle: "Content",
      width: "1600",
      bodyAttributes: {
        alignment: "center",
        paddingLeft: "0px",
        paddingRight: "0px",
        paddingTop: "0px",
        paddingBottom: "0px",
        backgroundColor: "#FFF",
        backgroundImage: "",
        marginTop: "0px",
        marginBottom: "0px",
      },
      itemList: [],
    },
  },
});

export const textState = atom({
  key: "textState",
  dangerouslyAllowMutability: true,
  default: {},
});

export const draftJsonState = atom({
  key: "draftJsonState",
  dangerouslyAllowMutability: true,
  default: {},
});

export const editorTypeState = atom({
  key: "editorTypeState",
  dangerouslyAllowMutability: true,
  default: "email",
});

export const productsState = atom({
  key: "productsState",
  dangerouslyAllowMutability: true,
  default: [],
});
