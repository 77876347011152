import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

class AddAdditionalCard extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {};
  }

  render() {
    return (
      <div>
        <div className="container-fluid">
          <h3 className="noMargin">Add Additional Card</h3>
          <div className="gap20" />

          <div className="card">
            <div className="list-group list-group-flush">
              <div className="list-group-item">
                <div className="billinfInfo">
                  <div className="gap10" />
                  <div className="customCheck checked">
                    <div className="customCheckBox">
                      <span className="icon-check" />
                    </div>
                    Set as Primary Card
                  </div>
                  <div className="gap20" />
                  <div className="row">
                    <div className="col-md-6">
                      <div className="cardDetHolder">
                        <div className="mdInput">
                          <label>Card Number</label>
                          <input
                            type="text"
                            name="card_number"
                            id="card_number"
                          />
                          <div className="gap20" />
                        </div>
                        <div className="row">
                          <div className="col-sm-6">
                            <div className="mdInput">
                              <label>Exp. Month</label>
                              <select>
                                <option />
                                <option>01 (Jan)</option>
                                <option>02 (Feb)</option>
                                <option>03 (Mar)</option>
                                <option>04 (Apr)</option>
                                <option>05 (May)</option>
                                <option>06 (Jun)</option>
                                <option>07 (Jul)</option>
                                <option>08 (Aug)</option>
                                <option>09 (Sep)</option>
                                <option>10 (Oct)</option>
                                <option>11 (Nov)</option>
                                <option>12 (Dec)</option>
                              </select>
                            </div>
                            <div className="gap20" />
                          </div>
                          <div className="col-sm-6">
                            <div className="mdInput">
                              <label>Exp. Year</label>
                              <select>
                                <option />
                                <option>2017</option>
                                <option>2017</option>
                                <option>2017</option>
                                <option>2017</option>
                                <option>2017</option>
                                <option>2017</option>
                                <option>2017</option>
                              </select>
                            </div>
                            <div className="gap20" />
                          </div>
                        </div>
                        <div className="mdInput">
                          <label>CVV Code</label>
                          <input type="text" />
                        </div>
                        <div className="gap20" />

                        <div className="selectMultipleDatesHolder">
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="mdInput">
                                <label>Select Date</label>
                                <input
                                  type="text"
                                  className="multiDatepicker"
                                />
                              </div>
                              <div className="gap20" />
                            </div>
                          </div>
                          <div className="text-right">
                            <button className="btn btn-primary addAnotherDate">
                              Add Another Date
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="gap20" />
                </div>
              </div>
            </div>
          </div>

          <div className="text-right">
            <button className="btn btn-primary">ADD CARD</button>
          </div>
          <div className="gap40" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddAdditionalCard);
