import {
  RESET_STATUS,
  CREATE_MARCI_CRICLE_SUCCESS,
  CREATE_MARCI_CRICLE_FAILURE,
  GET_MARCI_CRICLE,
  UPDATE_MARCI_CRICLE_SUCCESS,
  UPDATE_MARCI_CRICLE_FAILURE
} from "../types";

import config from "../../helpers/config";

import {
  callCreateMarciCircle,
  callGetMarciCircles,
  callUpdateMarciCircle
} from "../../services/index";

/*
     This method is used to reset the status
     This will reset message and success states
     */
export const resetStatus = data => {
  return async dispatch => {
    dispatch({
      type: RESET_STATUS
    });
  };
};

export const createMarciCircle = data => {
  return async dispatch => {
    return callCreateMarciCircle(data)
      .then(data => {
        dispatch({
          type: CREATE_MARCI_CRICLE_SUCCESS,
          message: config.messages.createChlgQueSuccess,
          success: true
        });
      })
      .catch(err => {
        dispatch({
          type: CREATE_MARCI_CRICLE_FAILURE,
          message: config.messages.createChlgQueError,
          success: false
        });
      });
  };
};

export const updateMarciCircle = (id, data) => {
  return async dispatch => {
    return callUpdateMarciCircle(id, data)
      .then(data => {
        dispatch({
          type: UPDATE_MARCI_CRICLE_SUCCESS,
          message: config.messages.updateMessage,
          success: true
        });
      })
      .catch(err => {
        dispatch({
          type: UPDATE_MARCI_CRICLE_FAILURE,
          message: config.messages.createChlgQueError,
          success: false
        });
      });
  };
};

export const getMarciCircles = data => {
  return async dispatch => {
    return callGetMarciCircles(data).then(data => {
      dispatch({
        type: GET_MARCI_CRICLE,
        data: data
      });
    });
  };
};
