import React from "react";

import AsyncSelect from "react-select/async";
import { callGetPageUsersByQuery } from "../../services";

const promiseOptions = (inputValue) =>
  new Promise((resolve) => {
    let query = {
      query: {
        limit: 5,
        status: { $ne: "archive" },
        $select: [
          "_id",
          "name",
          "email_address",
          "phone_number",
          "createdAt",
          "last_activity_date",
          "tag_list",
          "member_type",
          "site_status",
        ],
      },
    };
    let regex = { $regex: inputValue, $options: "i" };
    /*eslint-disable*/
    query.query["$or"] = [
      { email_address: regex },
      { name: regex },
      { phone_number: regex },
    ];
    /*eslint-enable*/
    callGetPageUsersByQuery(query, true, false).then((d) => {
      let users = [];
      d.data.forEach((element) => {
        users.push({ label: element.name, value: element });
      });
      resolve(users);
    });
  });

export default (props) => (
  <>
    <label>{props.placeholder}</label>
    <AsyncSelect
      name={props.name}
      placeholder={props.placeholder}
      onChange={(value) => props.onSelect(value)}
      cacheOptions
      value={props.selected}
      defaultOptions
      loadOptions={promiseOptions}
      styles={{
        control: (base) => ({
          ...base,
          border: 0,
          borderRadius: 0,
          borderBottom: "1px solid grey",
          ":hover": {},
        }),
        indicatorSeparator: (base) => ({ ...base, display: "none" }),
        valueContainer: (base) => ({ ...base, padding: 0 }),
        container: (base) => ({ ...base }),
        input: (base) => ({
          ...base,
        }),
        placeholder: (base) => ({
          ...base,
          fontWeight: 400,
        }),
      }}
    />
  </>
);
