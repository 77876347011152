import $ from "jquery";

import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,
  GET_USER_SUCCESS,
  RESET_STATUS,
  UPDATE_USER_SUCCESS,
  UPDATE_ALERT_ICON,
  REFRESH_CURRENT_USER,
  REFRESH_NOTIFICATION_UPDATE,
  GET_MESSAGE_SUCCESS,
  RESET_LOGGEDIN_USER,
  IS_MARCI_CIRCLE_AVAILABLE,
  SET_PAGE_HISTORY,
  IS_MARCI_CIRCLE_HOLD_AVAILABLE,
} from "../types";

import {
  callAuthenticate,
  callCreateUser,
  callGetUsers,
  callUpdateUser,
  callGetMessageByQuery,
  callGetUserById,
} from "../../services/index";
import config from "../../helpers/config";
import { removeItem, setItem } from "../../helpers/storage";
import { getCompanyRole } from "../../helpers";

/*
  This method is used to reset the status
  This will reset message and success states
*/
export const resetStatus = (data) => {
  return async (dispatch) => {
    dispatch({
      type: RESET_STATUS,
    });
  };
};

export const resetLoggedInUser = () => {
  return async (dispatch) => {
    dispatch({
      type: RESET_LOGGEDIN_USER,
    });
  };
};

export const updateMarciCircleStatus = (data) => {
  return async (dispatch) => {
    dispatch({
      type: IS_MARCI_CIRCLE_AVAILABLE,
      data: data,
    });
  };
};

export const updateMarciCircleHoldStatus = (data) => {
  return async (dispatch) => {
    dispatch({
      type: IS_MARCI_CIRCLE_HOLD_AVAILABLE,
      data: data,
    });
  };
};

export const loginUser = (email, password) => {
  return async (dispatch) => {
    removeItem("accessToken");
    removeItem("tokenExpiry");
    removeItem("userData");
    dispatch({
      type: LOGIN_FAILURE,
      message: "",
    });

    return callAuthenticate(email, password)
      .then((tokenData) => {
        if (tokenData.token) {
          setItem("accessToken", tokenData.token);
          setItem("tokenExpiry", tokenData.exp);

          callGetUserById(tokenData.data._id, false, false)
            .then((data) => {
              let isAdmin = data.member_type === "staff";
              let isMember = data.member_type === "customer";
              let { isCompany, companyId } = getCompanyRole(data);

              let userValues = JSON.stringify({
                _id: data._id,
                /*eslint-disable*/
                member_type: data.member_type,
                company_list: data.company_list,
                master_roles: data.master_roles,
                roles: data.roles,
                report_roles: data.report_roles || [],
                /*eslint-enable*/
              });

              setItem("userData", userValues);
              setItem("userId", data._id);
              setItem("companyId", companyId);

              dispatch({
                type: LOGIN_SUCCESS,
                message: config.messages.loginSuccess,
                isAdmin: isAdmin,
                isMember: isMember,
                isCompany: isCompany,
                userData: data,
              });
            })
            .catch((err) => {
              if (err.status === 403) {
                setItem("ism", "true");
                setTimeout(() => {
                  $(
                    ".modalAlertOverlay.confirmAccessModal,.modalAlert.confirmAccessAlert"
                  ).fadeIn(200);
                }, 200);
              }
              dispatch({
                type: LOGIN_FAILURE,
                message: config.messages.loginFailure,
              });
            });
        } else {
          dispatch({
            type: LOGIN_FAILURE,
            message: config.messages.loginFailure,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: LOGIN_FAILURE,
          message: config.messages.loginFailure,
        });
      });
  };
};

export const createUser = (data) => {
  return async (dispatch) => {
    return callCreateUser(data)
      .then((data) => {
        dispatch({
          type: CREATE_USER_SUCCESS,
          message: config.messages.createUserSuccess,
          success: true,
        });
      })
      .catch((err) => {
        dispatch({
          type: CREATE_USER_FAILURE,
          message: config.messages.errorMessage,
          success: false,
        });
      });
  };
};

export const updateUser = (id, data) => {
  return async (dispatch) => {
    let userInfo = data;

    delete userInfo.password;
    delete userInfo.initial_password;
    delete userInfo.tag_list_ids;
    delete userInfo.member_challenges;
    delete userInfo.updatedAt;
    delete userInfo.createdAt;

    return callUpdateUser(id, userInfo)
      .then((data) => {
        dispatch({
          type: UPDATE_USER_SUCCESS,
          message: config.messages.updateMessage,
          success: true,
        });
      })
      .catch((err) => {
        dispatch({
          type: CREATE_USER_FAILURE,
          message: config.messages.errorMessage,
          success: false,
        });
      });
  };
};

export const getUsers = (isLoading = true) => {
  return async (dispatch) => {
    return callGetUsers(isLoading).then((data) => {
      dispatch({
        type: GET_USER_SUCCESS,
        data: data,
      });
    });
  };
};

export const updateAlertIcon = () => {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_ALERT_ICON,
    });
  };
};

export const refreshCurrentUser = (data) => {
  return async (dispatch) => {
    dispatch({
      type: REFRESH_CURRENT_USER,
      data: data,
    });
  };
};

export const refreshNotificationUpdates = (data) => {
  return async (dispatch) => {
    dispatch({
      type: REFRESH_NOTIFICATION_UPDATE,
      data: data,
    });
  };
};

export const getMessages = (query) => {
  return async (dispatch) => {
    return callGetMessageByQuery(query).then((data) => {
      dispatch({
        type: GET_MESSAGE_SUCCESS,
        data: data,
      });
    });
  };
};

export const setPageHistory = (history) => {
  return async (dispatch) => {
    dispatch({
      type: SET_PAGE_HISTORY,
      history: history,
    });
  };
};
