import React from "react";

import WebBuilder from "./views/builder";
import Frame from "react-frame-component";
import { RecoilRoot } from "recoil";

export default class MPContentEditor extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      css: "",
      linkCss: "",
      itemCss: "",
      fontCss:
        "https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Lato:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Mirza:wght@400;700&family=Raleway:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Rubik:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap&family=Pacifico:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900",
    };
  }

  onChangeCss(css) {
    this.setState({ css });
  }

  onChangeItemCss(css) {
    let styleCss = "";
    if (css) {
      Object.keys(css).forEach((d) => {
        styleCss += `#${d}{${css[d]}}`;
      });
    }
    this.setState({ itemCss: styleCss });
  }

  onChangeLinkCss(css) {
    let linkCss = this.state.linkCss;
    linkCss = `
      ${linkCss} 
      ${css}
    `;
    this.setState({ linkCss: linkCss });
  }

  onChangeFontCss(font) {
    let fontCss = this.state.fontCss;
    let fontPl = `&family=${font}:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900`;

    if (fontCss.indexOf(font) === -1) {
      fontCss = `${fontCss}${fontPl}`;
    }
    this.setState({ fontCss: fontCss });
  }

  onChangeFontsCss(fonts) {
    let fontCss = this.state.fontCss;
    fonts.forEach((font) => {
      let fontPl = `&family=${font}:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900`;
      if (fontCss.indexOf(font) === -1) {
        fontCss = `${fontCss}${fontPl}`;
      }
    });

    this.setState({ fontCss: fontCss });
  }

  render() {
    return (
      <RecoilRoot>
        <Frame
          head={
            <>
              <link
                type="text/css"
                rel="stylesheet"
                href="/static/css/builder.css"
              />
              <link
                type="text/css"
                rel="stylesheet"
                href="/static/css/react-draft-wysiwyg.css"
              />
              <link
                href="https://fonts.googleapis.com/icon?family=Material+Icons"
                rel="stylesheet"></link>
              <link
                href={this.state.fontCss}
                rel="stylesheet"
                type="text/css"></link>
              <style>{this.state.css}</style>
              <style>{this.state.itemCss}</style>
              <style>{this.state.linkCss}</style>
              <link
                href="https://site.memberpages.app/css/videojs/video-js-7.10.2.css"
                rel="stylesheet"
              />
              <link
                href="https://site.memberpages.app/css/videojs/vjs-fantasy-theme.css"
                rel="stylesheet"></link>
              <script src="https://site.memberpages.app/scripts/videojs-ie8.min-1.1.2.js"></script>
              <script src="https://site.memberpages.app/scripts/videojs-7.10.2.js"></script>
              <link
                rel="stylesheet"
                href="https://site.memberpages.app/scripts/splide-4.1.3/css/splide.min.css"
              />
              <link
                href="https://site.memberpages.app/scripts/lightbox2/css/lightbox.min.css"
                rel="stylesheet"
              />
              <script src="https://site.memberpages.app/scripts/lightbox2/js/lightbox.min.js"></script>
              <script
                type="text/javascript"
                src="https://site.memberpages.app/scripts/splide-4.1.3/js/splide.min.js"></script>
            </>
          }
          style={{
            width: "calc(100vw - 17px)",
            height: "calc(100vh - 76px)",
          }}
          frameBorder="0"
          scrolling="none">
          <WebBuilder
            {...this.props}
            onChangeItemCss={this.onChangeItemCss.bind(this)}
            onChangeCss={this.onChangeCss.bind(this)}
            onChangeLinkCss={this.onChangeLinkCss.bind(this)}
            onChangeFontCss={this.onChangeFontCss.bind(this)}
            onChangeFontsCss={this.onChangeFontsCss.bind(this)}
          />
        </Frame>
      </RecoilRoot>
    );
  }
}
