import * as api from "./api";

function callGetAllChat() {
  return api.service("chat").find();
}
function callGetChatHistory(_id) {
  return api.service("chat").get(_id);
}
function callStaffReplay(_id, updataData) {
  return api.service("chat").patch(_id, updataData);
}

export { callGetAllChat, callGetChatHistory, callStaffReplay };
