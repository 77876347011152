import React, { useState, useEffect, useMemo, useRef } from "react";
import ContentSection from "../components/section";
import { useRecoilState } from "recoil";
import {
  jsonState,
  textState,
  editorTypeState,
  draftJsonState,
  productsState,
} from "../recoil/atoms";
import { Scrollbars } from "react-custom-scrollbars";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { saveAs } from "file-saver";
import prettify from "html-prettify";
import moment from "moment";
import Toggle from "react-toggle";
import PubSub from "pubsub-js";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import Modal from "react-modal";
import { CopyToClipboard } from "react-copy-to-clipboard";

import NavigateUpArrow from "../images/navigate-up-arrow.svg";
import NavigateDownArrow from "../images/navigate-down-arrow.svg";
import TitleSVG from "../images/title.svg";
import SubTitleSVG from "../images/subtitle.svg";
import ParagraphSVG from "../images/paragraph.svg";
import ImageSVG from "../images/image.svg";
import ButtonSVG from "../images/button.svg";
import InputSVG from "../images/inputfield.svg";
import LineHRSVG from "../images/linehr.svg";
import IconTextSelectSVG from "../images/icon-text-select.svg";
import IconSelectSVG from "../images/icon-select.svg";
import CheckBoxSVG from "../images/check-box.svg";
import CheckoutFormSVG from "../images/checkout-form.svg";
import SelectBoxSVG from "../images/select-box.svg";
import CodeSVG from "../images/code.svg";
import SelectUpSVG from "../images/select-parent.svg";
import SelectImageSVG from "../images/cloud-search.svg";
import MoveAreaSVG from "../images/movearea.svg";
import MoveUpSVG from "../images/moveup.svg";
import MoveDownSVG from "../images/movedown.svg";
import CopySVG from "../images/copy.svg";
import TrashSVG from "../images/trash.svg";
import AlertSVG from "../images/alert.svg";
import SaveSVG from "../images/save.svg";
import LeftAlignmentSVG from "../images/left-alignment.svg";
import RightAlignmentSVG from "../images/right-alignment.svg";
import CenterAlignmentSVG from "../images/center-alignment.svg";
import SettingSVG from "../images/settings.svg";
import CountdownSVG from "../images/countdown.svg";
import AudioSVG from "../images/audio.svg";
import VideoSVG from "../images/video.svg";
import SectionSVG from "../images/section.svg";
import RedoSVG from "../images/redo.svg";
import UndoSVG from "../images/undo.svg";
import MenuSVG from "../images/menu.svg";

import {
  prepareSection,
  prepareRow,
  deleteSection,
  deleteRow,
  selectRow,
  selectColumn,
  selectGrid,
  addTextElement,
  saveHtml,
  selectAllColumn,
  selectItem,
  changeRowWidth,
  changeUrlAttr,
  moveRow,
  changeItemAttributes,
  selectSection,
  moveSection,
  moveItem,
  deleteItem,
  resetContent,
  generateHtml,
  changePageAttributes,
  selectParent,
  transferItem,
  copyItem,
  changeDefaultStyle,
  changeCodeHtml,
  changeCustomStyles,
  changeButtonFullWidth,
  changeUsePageWidth,
  getFormNames,
  changeLockElem,
  getColItems,
  changeHideOnMobileElem,
  changeHideOnDesktopElem,
  changeItemCustomStyles,
  getEditorFonts,
} from "../helpers/json";
import {
  defaultArticleHtml,
  defaultCoreHtml,
  defaultCustomSectionHtml,
  defaultHtml,
} from "../data";
import ColorPicker from "../components/general/colorPicker";
import ContentIcon from "../components/general/icon";
import { icons } from "../components/general/icons";
import { getItemAttributeValue, getPageAttributeValue } from "../helpers/value";
import TagPicker from "../components/general/tagPicker";
import { isTemplateSite, isValidUrl, showNotification } from "../../../helpers";
import { getUniqueId, daysInMonth } from "../helpers";
import {
  getStorageItem,
  removeItem,
  setStorageItem,
} from "../../../helpers/storage";
import {
  callCreateContentEditorSection,
  callDeleteContentEditorSectionById,
  callGetCheckoutItemByQuery,
  callGetContentEditorSections,
  callGetEditorPopups,
  callGetProducts,
  callGetSystemTags,
  callGetUsersByQuery,
  // callGetUsersByQuery,
  callUpdateContentEditorSection,
} from "../../../services";
import { changeContentJsonIds } from "../../../components/editor/processEditor";

Modal.setAppElement("#root");

function WebBuilder(props) {
  const propsJson = props.json;

  const propsContentEditor = propsJson ? propsJson.contentEditor || {} : {};
  const [gson, setGson] = useRecoilState(jsonState);
  const [textValues, setTextValues] = useRecoilState(textState);
  const [draftJsonValues, setDraftJsonValues] = useRecoilState(draftJsonState);
  const [editorType, setEditorType] = useRecoilState(editorTypeState);
  /*eslint-disable*/
  const [productStore, setProductStore] = useRecoilState(productsState);
  /*eslint-enable*/
  const [showAttrView, setShowAttrView] = useState(false);
  const [showGridView, setShowGridView] = useState(false);
  const [showMoveRow, setShowMoveRow] = useState(false);
  const [showAddElement, setShowAddElement] = useState(false); //eslint-disable-line
  const [selectedRowId, setRowId] = useState("");
  const [selectedColumnId, setColumnId] = useState("");
  const [selectedSectionId, setSectionId] = useState("");
  const [textElementValues, setTextElementValues] = useState({});
  const [elementType, setElementType] = useState("");
  const [selectedItem, setItem] = useState({});
  const [customWidth, setCustomWidth] = useState(
    propsContentEditor.customWidth || {}
  );
  const [widthError, setWidthError] = useState(false);
  const [urlAttr, setUrlAttr] = useState(
    propsContentEditor.urlAttributes || {}
  );
  const [defaultStyle, setDefaultStyle] = useState(
    propsContentEditor.defaultStyle || {}
  );
  const [lockElem, setLockElem] = useState(propsContentEditor.lockElem || {});
  const [hideOnMobile, setHideOnMobile] = useState(
    propsContentEditor.hideOnMobile || {}
  );
  const [hideOnDesktop, setHideOnDesktop] = useState(
    propsContentEditor.hideOnDesktop || {}
  );
  const [sectionWidth, setSectionWidth] = useState(
    propsContentEditor.sectionWidth || {}
  );
  const [btnFullWidth, setBtnFullWidth] = useState(
    propsContentEditor.buttonFullWidth || {}
  );
  const [attributes, setAttributes] = useState(
    propsContentEditor.attributes || {}
  );
  const [pageAttr, setPageAttr] = useState(
    propsContentEditor.pageAttributes || {}
  );
  const [selectedComponent, setSelectedComponent] = useState("page");
  const [moveArea, setMoveArea] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalColItemOpen, setColItemOpen] = React.useState(false);
  const [modalStylesIsOpen, setStylesIsOpen] = React.useState(false);
  const [modalAddTagsIsOpen, setAddTagsIsOpen] = React.useState(false);
  const [modalAddInputTagsIsOpen, setAddInputTagsIsOpen] =
    React.useState(false);
  const [modalAddOptionTagsIsOpen, setAddOptionTagsIsOpen] =
    React.useState(false);
  const [gridModalIsOpen, setGridModalIsOpen] = React.useState(false);
  const [addElementModalIsOpen, setAddElementModalIsOpen] =
    React.useState(false);
  const [addItemType, setAddItemType] = useState("page");

  const [codeHtml, setCodeHtml] = useState(propsContentEditor.codeHtml || {});
  const [cStyles, setCustomStyles] = useState(
    propsContentEditor.customStyles || ""
  );
  const [modalItemStylesIsOpen, setItemStylesIsOpen] = React.useState(false);
  const [itemStyles, setItemStyles] = useState(
    propsContentEditor.itemStyles || {}
  );
  const [buttonModalIsOpen, setButtonModalIsOpen] = React.useState(false);
  const [iconModalIsOpen, setIconModalIsOpen] = React.useState(false);
  const [buttonIcon, setButtonIcon] = useState({});
  const [showSetting, setShowSetting] = useState(false);
  var [popups, setPopups] = useState(props.editorPopups || []);
  var [systemTags, setSystemTags] = useState([]);
  var [selectedSelectOption, setSelectedSelectOption] = useState("");
  const [reloadTags, setReloadTags] = useState(false);
  var [staffUsers, setStaffUsers] = useState([]);
  var [checkoutItems, setCheckoutItems] = useState([]);
  const [products, setProducts] = useState([]);

  const scrollRef = useRef();

  const isTextType = useMemo(
    () =>
      selectedComponent === "item"
        ? selectedItem
          ? selectedItem.type === "text" ||
            selectedItem.type === "title" ||
            selectedItem.type === "subTitle" ||
            selectedItem.type === "checkbox" ||
            selectedItem.type === "selectbox" ||
            selectedItem.type === "imageText"
          : false
        : false,
    [selectedItem, selectedComponent]
  );

  const isButton = useMemo(
    () =>
      selectedComponent === "item"
        ? selectedItem
          ? selectedItem.type === "button"
          : false
        : false,
    [selectedItem, selectedComponent]
  );

  const isDefaultStyle = useMemo(
    () =>
      selectedComponent === "item"
        ? selectedItem
          ? defaultStyle[selectedItem.attributes.id] !== undefined
            ? defaultStyle[selectedItem.attributes.id]
            : false
          : false
        : false,
    [selectedItem, selectedComponent, defaultStyle]
  );

  const isLockElem = useMemo(() => {
    let id = "";
    if (selectedComponent === "row") {
      id = selectedRowId;
    } else if (selectedComponent === "column") {
      id = selectedColumnId;
    } else if (selectedComponent === "item") {
      id = selectedItem.attributes.id;
    } else if (selectedComponent === "section") {
      id = selectedSectionId;
    }
    return lockElem[id] !== undefined ? lockElem[id] : false;
  }, [
    selectedItem,
    selectedComponent,
    lockElem,
    selectedRowId,
    selectedColumnId,
    selectedSectionId,
  ]);

  const isHideOnMobile = useMemo(() => {
    let id = "";
    if (selectedComponent === "row") {
      id = selectedRowId;
    } else if (selectedComponent === "column") {
      id = selectedColumnId;
    } else if (selectedComponent === "item") {
      id = selectedItem.attributes.id;
    } else if (selectedComponent === "section") {
      id = selectedSectionId;
    }
    return hideOnMobile[id] !== undefined ? hideOnMobile[id] : false;
  }, [
    selectedItem,
    selectedComponent,
    hideOnMobile,
    selectedRowId,
    selectedColumnId,
    selectedSectionId,
  ]);

  const isHideOnDesktop = useMemo(() => {
    let id = "";
    if (selectedComponent === "row") {
      id = selectedRowId;
    } else if (selectedComponent === "column") {
      id = selectedColumnId;
    } else if (selectedComponent === "item") {
      id = selectedItem.attributes.id;
    } else if (selectedComponent === "section") {
      id = selectedSectionId;
    }
    return hideOnDesktop[id] !== undefined ? hideOnDesktop[id] : false;
  }, [
    selectedItem,
    selectedComponent,
    hideOnDesktop,
    selectedRowId,
    selectedColumnId,
    selectedSectionId,
  ]);

  const usePageWidth = useMemo(
    () =>
      selectedComponent === "section"
        ? sectionWidth[selectedSectionId] !== undefined
          ? sectionWidth[selectedSectionId]
          : true
        : true,
    [sectionWidth, selectedComponent, selectedSectionId]
  );

  const isButtonFullWidth = useMemo(
    () =>
      selectedComponent === "item"
        ? btnFullWidth[selectedItem.attributes.id] !== undefined
          ? btnFullWidth[selectedItem.attributes.id]
          : false
        : false,
    [selectedItem, selectedComponent, btnFullWidth]
  );

  const isButtonIcon = useMemo(
    () =>
      selectedComponent === "item"
        ? buttonIcon[selectedItem.attributes.id] !== undefined
          ? buttonIcon[selectedItem.attributes.id]
          : true
        : true,
    [selectedItem, selectedComponent, buttonIcon]
  );

  const isPlaceholder = useMemo(() => {
    if (selectedItem.type === "selectbox") {
      return attributes[selectedItem.attributes.id] !== undefined
        ? attributes[selectedItem.attributes.id]["inputSelectPlaceholder"] !==
          undefined
          ? attributes[selectedItem.attributes.id]["inputSelectPlaceholder"]
          : true
        : true;
    }
    return false;
  }, [selectedItem, attributes]);

  const [bpJson, setBpJson] = useState({});
  const [bpPageAttr, setBpPageAttr] = useState({}); //eslint-disable-line
  const [eMode, setEmode] = useState(props.editorMode || "normal");
  const [popupId, setPopupId] = useState("");
  const [seId, setSeId] = useState(""); //eslint-disable-line
  const [seItem, setSeItem] = useState({}); //eslint-disable-line
  /*eslint-disable*/
  const [seValues, setSeValues] = useState({
    //eslint-disable-line
    sectionId: "",
    rowId: "",
    colId: "",
  });
  /*eslint-enable*/

  const [sectionName, setSectionName] = useState("");
  const [sectionNonCustomizable, setSectionNonCustomizable] = useState(false);
  const [sectionNameIsOpen, setSectionNameIsOpen] = useState(false);
  const [sections, setSections] = useState([]);
  const [reloadSections, setReloadSections] = useState(false);
  const [sectionModalIsOpen, setSectionModalIsOpen] = useState(false);
  const [productModalIsOpen, setProductModalIsOpen] = useState(false);
  const [productListModalIsOpen, setProductListModalIsOpen] = useState(false);
  const [isEditSection, setIsEditSection] = useState(false);
  const [editSectionId, setEditSectionId] = useState("");
  const [contentMenuClass, setContentMenuClass] =
    useState("builderContentMenu");

  const [json, setJson] = useState(
    props.json.contentEditor
      ? props.json
      : {
          contentEditor: {
            id: "",
            contentId: "",
            type: "email",
            pageTitle: "MY NEW PAGE",
            width: "1600",
            bodyAttributes: {
              alignment: "center",
              paddingLeft: "0px",
              paddingRight: "0px",
              paddingTop: "0px",
              paddingBottom: "0px",
              backgroundColor: "#FFF",
              backgroundImage: "",
              marginTop: "0px",
              marginBottom: "0px",
            },
            itemList: [],
          },
        }
  );

  let defaultJson = props.json.contentEditor
    ? props.json
    : {
        contentEditor: {
          id: "",
          contentId: "",
          type: "email",
          pageTitle: "MY NEW PAGE",
          width: "1600",
          bodyAttributes: {
            alignment: "center",
            paddingLeft: "0px",
            paddingRight: "0px",
            paddingTop: "0px",
            paddingBottom: "0px",
            backgroundColor: "#FFF",
            backgroundImage: "",
            marginTop: "0px",
            marginBottom: "0px",
          },
          itemList: [],
        },
      };

  // eslint-disable-next-line no-unused-vars
  const [history, setHistory] = useState([defaultJson]);
  const [redoHistory, setRedoHistory] = useState([]);

  // const onClickCreatePopup = () => {
  //   let defaultValue = {
  //     contentEditor: {
  //       id: "",
  //       contentId: "",
  //       type: "popup",
  //       pageTitle: "MY NEW PAGE",
  //       width: "1600",
  //       bodyAttributes: {
  //         alignment: "center",
  //         paddingLeft: "0px",
  //         paddingRight: "0px",
  //         paddingTop: "0px",
  //         paddingBottom: "0px",
  //         backgroundColor: "#FFF",
  //         backgroundImage: "",
  //         marginTop: "0px",
  //         marginBottom: "0px",
  //       },
  //       itemList: [],
  //     },
  //   };
  //   setJson(defaultValue);
  //   setGson(defaultValue);
  //   setBpJson(json);
  //   setPageAttr({});
  //   setBpPageAttr(pageAttr);
  //   setEmode("popup");
  //   setShowAttrView(false);
  //   setShowAddElement(true);
  //   setSelectedComponent("page");
  //   setSeId(getSelectedComponentId());
  //   setSeItem(selectedItem);
  //   setSeValues({
  //     sectionId: selectedSectionId,
  //     rowId: selectedRowId,
  //     colId: selectedColumnId,
  //   });
  //   setStorageItem("eMode", "popup");
  //   setStorageItem("bpJson", json);
  //   setStorageItem("bpPageAttr", pageAttr);
  // };

  const onClickGoCreatePopup = () => {
    let url = "/admin/add-editor-popup";
    window.open(url, "_blank");
  };

  const onClickViewPopups = () => {
    let url = "/admin/view-editor-popups";
    window.open(url, "_blank");
  };

  const onClickRefreshPopups = () => {
    callGetEditorPopups().then((d) => {
      let popups = [];
      d.data.forEach((element) => {
        popups.push({
          id: element._id,
          title: element.title,
          json: element.content,
        });
      });
      setPopups(popups);
      showNotification("success", "Popups refreshed");
    });
  };

  // const onClickEditPopup = () => {
  //   let id = getAttributeValue("buttonPopupId");
  //   setPopupId(id);

  //   let popup = popups.filter((d) => d.id === id);
  //   if (popup.length > 0) {
  //     let pageAttributes = popup[0].json.contentEditor.pageAttributes;
  //     setJson(popup[0].json);
  //     setGson(popup[0].json);
  //     setPageAttr(pageAttributes ? pageAttributes : {});
  //     setBpJson(json);
  //     setEmode("popup");
  //     setShowAttrView(false);
  //     setShowAddElement(true);
  //     setSeId(getSelectedComponentId());
  //     setSeItem(selectedItem);
  //     setSeValues({
  //       sectionId: selectedSectionId,
  //       rowId: selectedRowId,
  //       colId: selectedColumnId,
  //     });
  //     setSelectedComponent("page");
  //     setStorageItem("eMode", "popup");
  //     setStorageItem("bpJson", json);
  //     setStorageItem("bpPageAttr", pageAttr);
  //   }
  // };

  // const onClickDeletePopup = () => {
  //   let id = getAttributeValue("buttonPopupId");
  //   let newPopups = popups.filter((d) => d.id !== id);
  //   setPopups(newPopups);
  // };

  const handlePageInputChange = (type, event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    changePageValue(name, type, value);

    if (
      type === "customFontFamily" ||
      type === "font" ||
      type === "fontFamily"
    ) {
      props.onChangeFontCss(value);
    }
  };

  const handleUrlAttrChange = (type, event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    let cW = Object.assign({}, urlAttr);
    cW[name] = value;
    setUrlAttr(cW);

    let jsonBackup = changeUrlAttr(
      json,
      selectedSectionId,
      selectedRowId,
      selectedColumnId,
      selectedItem,
      cW,
      type
    );
    setJson(jsonBackup);
    setGson(jsonBackup);
  };

  const handleDefaultStyleChange = (event) => {
    const target = event.target;
    const value = target.checked;
    const name = target.name;
    let cW = Object.assign({}, defaultStyle);
    cW[name] = value;
    setDefaultStyle(cW);

    let jsonBackup = changeDefaultStyle(
      json,
      selectedSectionId,
      selectedRowId,
      selectedColumnId,
      selectedItem,
      cW
    );
    setJson(jsonBackup);
    setGson(jsonBackup);
  };

  const handleSectionWidthChange = (event) => {
    const target = event.target;
    const value = target.checked;
    const name = target.name;
    let cW = Object.assign({}, sectionWidth);
    cW[name] = value;
    setSectionWidth(cW);

    event.stopPropagation();
    let jsonBackup1 = changeUsePageWidth(json, selectedSectionId, cW);
    let jsonBackup = changeItemAttributes(jsonBackup1, attributes, editorType);
    setJson(jsonBackup);
    setGson(jsonBackup);
  };

  const handleLockElemChange = (event) => {
    const target = event.target;
    const value = target.checked;
    const name = target.name;
    let cW = Object.assign({}, lockElem);
    cW[name] = value;
    setLockElem(cW);

    event.stopPropagation();
    let jsonBackup = changeLockElem(json, name, cW);
    setJson(jsonBackup);
    setGson(jsonBackup);
    handleValueBlur();
  };

  const handleHideOnMobileChange = (event) => {
    const target = event.target;
    const value = target.checked;
    const name = target.name;
    let cW = Object.assign({}, hideOnMobile);
    cW[name] = value;
    setHideOnMobile(cW);

    event.stopPropagation();
    let jsonBackup = changeHideOnMobileElem(json, name, cW);
    setJson(jsonBackup);
    setGson(jsonBackup);
    handleValueBlur();
  };

  const handleHideOnDesktopChange = (event) => {
    const target = event.target;
    const value = target.checked;
    const name = target.name;
    let cW = Object.assign({}, hideOnDesktop);
    cW[name] = value;
    setHideOnDesktop(cW);

    event.stopPropagation();
    let jsonBackup = changeHideOnDesktopElem(json, name, cW);
    setJson(jsonBackup);
    setGson(jsonBackup);
    handleValueBlur();
  };

  const handleButtonFullWidthChange = (event) => {
    const target = event.target;
    const value = target.checked;
    const name = target.name;
    let cW = Object.assign({}, btnFullWidth);
    cW[name] = value;
    setBtnFullWidth(cW);

    let jsonBackup = changeButtonFullWidth(
      json,
      selectedSectionId,
      selectedRowId,
      selectedColumnId,
      selectedItem,
      cW
    );
    setJson(jsonBackup);
    setGson(jsonBackup);
    handleValueBlur();
  };

  const handleButtonIconChange = (event) => {
    const target = event.target;
    const value = target.checked;
    const name = target.name;
    let cW = Object.assign({}, buttonIcon);
    cW[name] = value;
    setButtonIcon(cW);
    changeAttributeValue(getSelectedComponentId(), "buttonIconEnable", value);
  };

  const handleToggleChange = (type, event) => {
    const target = event.target;
    const value = target.checked;
    const name = target.name;
    changeAttributeValue(name, type, value);
  };

  const handlePageToggleChange = (type, event) => {
    const target = event.target;
    const value = target.checked;
    const name = target.name;
    changePageValue(name, type, value);
  };

  const handleValueChange = (type, event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    if (isNaN(value)) {
      return;
    }
    changeAttributeValue(name, type, value);
  };

  const handleInputChange = (type, event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    changeAttributeValue(name, type, value);

    if (
      type === "customFontFamily" ||
      type === "font" ||
      type === "fontFamily"
    ) {
      props.onChangeFontCss(value);
    }
  };

  const handleInputTypeChange = (type, event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    let cW = Object.assign({}, attributes);
    if (!cW[name]) {
      cW[name] = {};
    }
    cW[name][type] = value;
    if (value !== "custom") {
      cW[name]["inputName"] = `customer-${value}`;
      cW[name]["inputPlaceholder"] = `Enter your ${value}`;
      cW[name]["inputErrorMessage"] = `Please enter your ${value}`;
    } else {
      cW[name]["inputName"] = "custom-field";
      cW[name]["inputPlaceholder"] = "Enter custom value";
      cW[name]["inputErrorMessage"] = "Field not completed";
    }

    if (value !== "hidden") {
      cW[name]["inputValue"] = "";
    }

    setAttributes(cW);

    let jsonBackup = changeItemAttributes(json, cW, editorType);
    setJson(jsonBackup);
    setGson(jsonBackup);
  };

  const changeAttributeValue = (name, type, value) => {
    let cW = Object.assign({}, attributes);
    if (!cW[name]) {
      cW[name] = {};
    }
    cW[name][type] = value;

    if (type === "customFontSize") {
      cW[name]["lineHeight"] = (
        parseFloat(cW[name]["customFontSize"]) + 2
      ).toString();
    }
    if (type === "inputSaveResponseTagAttribute") {
      value = value.replace(/[']/g, "");
      value = value.replace(/["]/g, "");
      cW[name][type] = value;
    }
    setAttributes(cW);

    let linkCss = "";

    if (type === "linkColor" || type === "linkColorHover") {
      let linkStyle = "";
      if (cW[name]["linkColor"]) {
        linkStyle = `
        #${name} a {
          color: ${cW[name]["linkColor"]};
        }
      `;
      }

      let linkHoverStyle = "";
      if (cW[name]["linkColorHover"]) {
        linkHoverStyle = `
        #${name} a:hover {
          color: ${cW[name]["linkColorHover"]};
        }
      `;
      }
      linkCss = `
      ${linkStyle}
      ${linkHoverStyle}
      `;
      props.onChangeLinkCss(linkCss);
    }

    let jsonBackup = changeItemAttributes(json, cW, editorType, linkCss);
    setJson(jsonBackup);
    setGson(jsonBackup);
  };

  const handlePageValueChange = (type, event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    if (isNaN(value)) {
      return;
    }
    changePageValue(name, type, value);
  };

  const onChangeColor = (name, type, color) => {
    changePageValue(name, type, color);
  };

  const onChangeAttrColor = (name, type, color) => {
    changeAttributeValue(name, type, color);
  };

  const changePageValue = (name, type, value) => {
    let cW = Object.assign({}, pageAttr);
    if (!cW[name]) {
      cW[name] = {};
    }
    cW[name][type] = value;
    setPageAttr(cW);
    let jsonBackup = changePageAttributes(json, cW);
    setJson(jsonBackup);
    setGson(jsonBackup);
  };

  const handleValueBlur = () => {
    let jsonBackup = changeItemAttributes(json, attributes, editorType);
    setJson(jsonBackup);
    setGson(jsonBackup);
  };

  const handleVideoUrlValueBlur = (id) => {
    let value = urlAttr[id];
    let valid = isValidUrl(value);
    if (!valid) {
      showNotification("error", "Video url is not valid");
      let cW = Object.assign({}, urlAttr);
      cW[id] = "";
      setUrlAttr(cW);
    }
  };

  const handlePageValueBlur = () => {
    let jsonBackup = changePageAttributes(json, pageAttr);
    setJson(jsonBackup);
    setGson(jsonBackup);
  };

  const onBlurButtonIconToggle = () => {
    changeAttributeValue(
      getSelectedComponentId(),
      "buttonIconEnable",
      isButtonIcon
    );
    handleValueBlur();
  };

  const handlePerChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    let cW = Object.assign({}, customWidth);
    cW[name] = value;
    setCustomWidth(cW);
  };

  const onClickShowMobileMenu = (event) => {
    let className = "builderContentMenuShow";
    if (contentMenuClass === "builderContentMenu") {
      className = "";
    } else {
      className = "builderContentMenu";
    }
    setContentMenuClass(className);
  };

  const onClickAddSection = (event) => {
    event.stopPropagation();
    history.push(JSON.parse(JSON.stringify(json)));
    setRedoHistory([]);
    let response = prepareSection(json);
    setJson(response.jsonBackup);
    setGson(response.jsonBackup);
    onClickSection(response.sectionId);
  };

  const onClickAddRow = (sectionId) => {
    history.push(JSON.parse(JSON.stringify(json)));
    setRedoHistory([]);
    let response = prepareRow(json, sectionId);
    setJson(response.jsonBackup);
    setGson(response.jsonBackup);
    prepareSelectRow(response.jsonBackup, sectionId, response.rowId);
  };

  const onClickAddTextElement = (sectionId, rowId, colId, type) => {
    history.push(JSON.parse(JSON.stringify(json)));
    setRedoHistory([]);
    let { jsonBackup, id, item } = addTextElement(
      json,
      colId,
      rowId,
      sectionId,
      type,
      editorType,
      selectedItem
    );

    let textElementTypes = ["text", "title", "subTitle", "code"];

    if (textElementTypes.indexOf(type) > -1) {
      textElementValues[id] = "";
      draftJsonValues[id] = null;
      setTextElementValues(textElementValues);
      setTextValues(textElementValues);
      setDraftJsonValues(draftJsonValues);
      let linkStyle = `
        #${id} a {
          color: #26ace2;
        }
      `;
      props.onChangeLinkCss(linkStyle);
    }
    prepareOnClickItem(sectionId, rowId, colId, item);
    setJson(jsonBackup);
    setGson(jsonBackup);
  };

  const onClickAddElement = (type, event) => {
    event.stopPropagation();
    if (type === "customSections") {
      setSectionModalIsOpen(true);
      setAddElementModalIsOpen(false);
      return;
    }
    if (type === "product") {
      setProductModalIsOpen(true);
      setAddElementModalIsOpen(false);
      return;
    }
    if (type === elementType) {
      setElementType("");
      let jsonBackup = resetContent(json);
      setJson(jsonBackup);
      setGson(jsonBackup);
      setShowAttrView(false);
      setShowAddElement(true);
      setSelectedComponent("page");
    } else {
      if (addItemType === "page") {
        let jsonBackup = selectAllColumn(json);
        setJson(jsonBackup);
        setGson(jsonBackup);
        setElementType(type);
        setMoveArea(false);
      } else {
        onClickAddTextElement(
          selectedSectionId,
          selectedRowId,
          selectedColumnId,
          type
        );
        setElementType("");
        setAddItemType("page");
      }
    }

    setAddElementModalIsOpen(false);
  };

  const onClickDeleteSection = () => {
    history.push(JSON.parse(JSON.stringify(json)));
    setRedoHistory([]);

    let jsonBackup = deleteSection(json, selectedSectionId);
    setJson(jsonBackup);
    setGson(jsonBackup);
  };

  const onClickDeleteRow = () => {
    history.push(JSON.parse(JSON.stringify(json)));
    setRedoHistory([]);

    let jsonBackup = deleteRow(json, selectedSectionId, selectedRowId);
    setJson(jsonBackup);
    setGson(jsonBackup);
  };

  const onClickDeleteItem = () => {
    history.push(JSON.parse(JSON.stringify(json)));
    setRedoHistory([]);

    let jsonBackup = deleteItem(
      json,
      selectedSectionId,
      selectedRowId,
      selectedColumnId,
      selectedItem.attributes.id
    );
    setJson(jsonBackup);
    setGson(jsonBackup);
  };

  const onClickDelete = () => {
    // let item = selectedComponent[0].toUpperCase() + selectedComponent.slice(1);
    // confirmAlert({
    //   title: `Delete ${item}`,
    //   message: "Are you sure you want to delete?",
    //   buttons: [
    //     {
    //       label: "Yes",
    //       onClick: () => processDelete(),
    //     },
    //     {
    //       label: "No",
    //       onClick: () => {
    //         //
    //       },
    //     },
    //   ],
    // });
    processDelete();
  };

  const onDeleteSection = (sectionId) => {
    let jsonBackup = deleteSection(json, sectionId);
    setJson(jsonBackup);
    setGson(jsonBackup);
  };

  const onDeleteRow = (sectionId, rowId) => {
    let jsonBackup = deleteRow(json, sectionId, rowId);
    setJson(jsonBackup);
    setGson(jsonBackup);
  };

  const onClickColumnItems = () => {
    setColItemOpen(true);
  };

  const onClickSaveSectionIcon = () => {
    setSectionNameIsOpen(true);
  };

  const onClickSetting = () => {
    setShowSetting(!showSetting);
  };

  const processDelete = () => {
    if (selectedComponent === "row") {
      onClickDeleteRow();
    } else if (selectedComponent === "section") {
      onClickDeleteSection();
    } else if (selectedComponent === "item") {
      onClickDeleteItem();
    }
    onClickContent();
  };

  const onClickCopy = () => {
    let response = copyItem(
      json,
      selectedSectionId,
      selectedRowId,
      selectedColumnId,
      selectedItem,
      selectedComponent,
      attributes,
      defaultStyle,
      textValues,
      draftJsonValues,
      sectionWidth,
      codeHtml,
      urlAttr,
      customWidth,
      itemStyles
    );
    setJson(response.jsonBackup);
    setGson(response.jsonBackup);
    setAttributes(response.newAttributes);
    setDefaultStyle(response.newDefaultStyle);
    setTextValues(response.newTextValues);
    setTextElementValues(response.newTextValues);
    setDraftJsonValues(response.newDraftJsonValues);
    setSectionWidth(response.newSectionWidth);
    setCodeHtml(response.newCodeHtml);
    setUrlAttr(response.newUrlAttr);
    setCustomWidth(response.newCustomWidth);
    setItemStyles(response.newItemStyles);
    props.onChangeItemCss(response.newItemStyles);

    if (selectedComponent === "section") {
      onClickSection(response.newId);
    }
    if (selectedComponent === "row") {
      onClickRow(selectedSectionId, response.newId);
    }
    if (selectedComponent === "item") {
      onClickItem(
        selectedSectionId,
        selectedRowId,
        selectedColumnId,
        response.newItem
      );
    }
  };

  const onClickRow = (sectionId, rowId) => {
    prepareSelectRow(json, sectionId, rowId);
  };

  const prepareSelectRow = (jsonData, sectionId, rowId) => {
    var jsonBackup1 = resetContent(jsonData);
    let jsonBackup = selectRow(jsonBackup1, sectionId, rowId);
    setJson(jsonBackup);
    setGson(jsonBackup);
    setShowGridView(true);
    setSectionId(sectionId);
    setRowId(rowId);
    setShowMoveRow(true);
    setShowAttrView(true);
    setShowAddElement(true);
    setMoveArea(false);
    setSelectedComponent("row");
    scrollRef.current.scrollTop(0);
  };

  const onClickColumn = (sectionId, rowId, colId) => {
    var jsonBackup1 = resetContent(json);
    let jsonBackup = selectColumn(jsonBackup1, sectionId, rowId, colId);
    setJson(jsonBackup);
    setGson(jsonBackup);
    setSectionId(sectionId);
    setColumnId(colId);
    setRowId(rowId);
    setShowAttrView(true);
    setShowAddElement(true);
    setSelectedComponent("column");
    setItem({});
    prepareAddElement(sectionId, rowId, colId);
    scrollRef.current.scrollTop(0);
  };

  const prepareAddElement = (sectionId, rowId, colId) => {
    let types = [
      "title",
      "subTitle",
      "text",
      "button",
      "image",
      "video",
      "audio",
      "input",
      "code",
      "selectbox",
      "checkbox",
      "checkoutForm",
      "line",
      "countdown",
      "icon",
      "iconText",
      "imageText",
      "navBar",
      "productName",
      "productDescription",
      "productPrice",
      "productCart",
      "productImage",
      "imageSlider",
      "socialMediaLinks",
      "fileInput",
    ];
    if (types.indexOf(elementType) > -1) {
      onClickAddTextElement(sectionId, rowId, colId, elementType);
      setElementType("");
    }

    if (moveArea) {
      prepareMoveItem(sectionId, rowId, colId);
      setMoveArea(false);
    }
  };

  const prepareMoveItem = (sectionId, rowId, colId) => {
    let response = transferItem(
      json,
      { sectionId, rowId, colId },
      {
        sectionId: selectedSectionId,
        rowId: selectedRowId,
        colId: selectedColumnId,
      },
      selectedItem
    );
    prepareOnClickItem(sectionId, rowId, colId, selectedItem);
    setJson(response.jsonBackup);
    setGson(response.jsonBackup);
  };

  const onClickItem = (sectionId, rowId, colId, item) => {
    prepareOnClickItem(sectionId, rowId, colId, item);
    prepareAddElement(sectionId, rowId, colId);
  };

  const onClickAddHtml = (sectionId, rowId, colId, item) => {
    prepareOnClickItem(sectionId, rowId, colId, item);
    setIsOpen(true);
  };

  const onClickAddItem = (sectionId, rowId, colId) => {
    if (elementType) {
      onClickAddTextElement(sectionId, rowId, colId, elementType);
      setElementType("");
      setAddItemType("page");
    } else {
      setAddItemType("item");
      setSectionId(sectionId);
      setRowId(rowId);
      setColumnId(colId);
      setAddElementModalIsOpen(true);
    }
  };

  const onClickAddImage = (sectionId, rowId, colId, item, url) => {
    setSectionId(sectionId);
    setRowId(rowId);
    setColumnId(colId);
    setItem(item);
    props.onClickSelectImage("imageUrl");
    if (url) {
      let cW = Object.assign({}, urlAttr);
      cW[item.attributes.id] = url;
      setUrlAttr(cW);

      let jsonBackup = changeUrlAttr(
        json,
        selectedSectionId,
        selectedRowId,
        selectedColumnId,
        selectedItem,
        cW,
        "imageUrl"
      );
      setJson(jsonBackup);
      setGson(jsonBackup);
    }
  };

  const onClickAddVideo = (sectionId, rowId, colId, item, url) => {
    setSectionId(sectionId);
    setRowId(rowId);
    setColumnId(colId);
    setItem(item);
    props.onClickSelectVideo("videoUrl", item.attributes.id);
    if (url) {
      let cW = Object.assign({}, urlAttr);
      cW[item.attributes.id] = url;
      setUrlAttr(cW);

      let jsonBackup = changeUrlAttr(
        json,
        selectedSectionId,
        selectedRowId,
        selectedColumnId,
        selectedItem,
        cW,
        "videoUrl"
      );
      setJson(jsonBackup);
      setGson(jsonBackup);
    }
  };

  const onClickAddAudio = (sectionId, rowId, colId, item, url) => {
    props.onClickSelectAudio("audioUrl");
    if (url) {
      setSectionId(sectionId);
      setRowId(rowId);
      setColumnId(colId);
      setItem(item);

      let cW = Object.assign({}, urlAttr);
      cW[item.attributes.id] = url;
      setUrlAttr(cW);

      let jsonBackup = changeUrlAttr(
        json,
        selectedSectionId,
        selectedRowId,
        selectedColumnId,
        selectedItem,
        cW,
        "audioUrl"
      );
      setJson(jsonBackup);
      setGson(jsonBackup);
    }
  };

  const onClickSelectGrid = (sectionId, rowId) => {
    setSectionId(sectionId);
    setRowId(rowId);
    // prepareGrid(2);
    setGridModalIsOpen(true);
  };

  const prepareOnClickItem = (sectionId, rowId, colId, item) => {
    var jsonBackup1 = resetContent(json);
    let jsonBackup = selectItem(jsonBackup1, sectionId, rowId, colId, item);
    setJson(jsonBackup);
    setGson(jsonBackup);
    setSectionId(sectionId);
    setRowId(rowId);
    setItem(item);
    setColumnId(colId);
    setShowAttrView(true);
    setShowMoveRow(true);
    setShowAddElement(true);
    setSelectedComponent("item");
    scrollRef.current.scrollTop(0);
  };

  const onClickSection = (sectionId) => {
    var jsonBackup1 = resetContent(json);
    let jsonBackup = selectSection(jsonBackup1, sectionId);
    setJson(jsonBackup);
    setGson(jsonBackup);
    setSectionId(sectionId);
    setShowMoveRow(true);
    setShowAttrView(true);
    setMoveArea(false);
    setItem({});

    let selectedSection = jsonBackup.contentEditor.itemList.filter(
      (d) => d.attributes && d.attributes.id === sectionId
    )[0];
    if (
      selectedSection &&
      selectedSection.subId &&
      selectedSection.nonCustomizable
    ) {
      setSelectedComponent("custom-section");
    } else {
      setSelectedComponent("section");
    }
    scrollRef.current.scrollTop(0);
  };

  const onClickGrid = (type, event) => {
    event.stopPropagation();

    let itemList = json.contentEditor.itemList;
    let showAlert = true;
    itemList.forEach((secElem) => {
      let rowElem = secElem.itemList.filter(
        (d) => d.attributes.id === selectedRowId
      );
      if (rowElem.length > 0) {
        if (rowElem[0].itemList.length === 0) {
          showAlert = false;
        }
      }
    });
    if (!showAlert) {
      prepareGrid(type);
      return;
    }

    confirmAlert({
      title: "Change Row",
      message:
        "If you are removing columns data in the last columns will be lost.",
      buttons: [
        {
          label: "Yes",
          onClick: () => prepareGrid(type),
        },
        {
          label: "No",
          onClick: () => {
            //
          },
        },
      ],
    });
  };

  const prepareGrid = (type) => {
    let jsonBackup = selectGrid(json, type, selectedSectionId, selectedRowId);
    setJson(jsonBackup);
    setGson(jsonBackup);
    setShowAddElement(true);
    setGridModalIsOpen(false);
  };

  const onClickMoveRowUp = (event) => {
    event.stopPropagation();

    history.push(JSON.parse(JSON.stringify(json)));
    setRedoHistory([]);

    if (selectedComponent === "row") {
      let response = moveRow(json, selectedRowId, selectedSectionId, "up");
      setSectionId(response.newSectionId);
      setJson(response.jsonBackup);
      setGson(response.jsonBackup);
    }

    if (selectedComponent === "section") {
      let jsonBackup = moveSection(json, selectedSectionId, "up");
      setJson(jsonBackup);
      setGson(jsonBackup);
    }

    if (selectedComponent === "item") {
      let response = moveItem(
        json,
        selectedSectionId,
        selectedRowId,
        selectedColumnId,
        selectedItem,
        "up"
      );
      setSectionId(response.newSectionId);
      setRowId(response.newRowId);
      setColumnId(response.newColId);
      setJson(response.jsonBackup);
      setGson(response.jsonBackup);
      let itemView = document.getElementById(selectedItem.attributes.id);
      if (itemView) {
        itemView.scrollIntoView();
      }
    }
  };

  const onClickMoveArea = (event) => {
    event.stopPropagation();
    let jsonBackup = selectAllColumn(json);
    setJson(jsonBackup);
    setGson(jsonBackup);
    setMoveArea(true);
  };

  const onClickSelectParent = (event) => {
    event.stopPropagation();
    let response = selectParent(
      json,
      selectedSectionId,
      selectedRowId,
      selectedColumnId,
      selectedItem,
      selectedComponent
    );
    setSelectedComponent(response.selectedType);
    setItem({});
    if (response.selectedType === "row") {
      setShowGridView(true);
    }
    setJson(response.jsonBackup);
    setGson(response.jsonBackup);
  };

  const onClickNavSelect = (type) => {
    let sComponent = "";

    if (type === "section") {
      sComponent = "row";
    }
    if (type === "row") {
      sComponent = "column";
    }
    if (type === "column") {
      sComponent = "item";
    }

    let response = selectParent(
      json,
      selectedSectionId,
      selectedRowId,
      selectedColumnId,
      selectedItem,
      sComponent
    );
    setSelectedComponent(response.selectedType);
    setItem({});
    if (response.selectedType === "row") {
      setShowGridView(true);
    }
    setJson(response.jsonBackup);
    setGson(response.jsonBackup);
  };

  const onClickMoveRowDown = (event) => {
    event.stopPropagation();

    history.push(JSON.parse(JSON.stringify(json)));
    setRedoHistory([]);

    if (selectedComponent === "row") {
      let response = moveRow(json, selectedRowId, selectedSectionId, "down");
      setSectionId(response.newSectionId);
      setJson(response.jsonBackup);
      setGson(response.jsonBackup);
    }
    if (selectedComponent === "section") {
      let jsonBackup = moveSection(json, selectedSectionId, "down");
      setJson(jsonBackup);
      setGson(jsonBackup);
    }
    if (selectedComponent === "item") {
      let response = moveItem(
        json,
        selectedSectionId,
        selectedRowId,
        selectedColumnId,
        selectedItem,
        "down"
      );
      setSectionId(response.newSectionId);
      setColumnId(response.newColId);
      setRowId(response.newRowId);
      setJson(response.jsonBackup);
      setGson(response.jsonBackup);
      let itemView = document.getElementById(selectedItem.attributes.id);
      if (itemView) {
        itemView.scrollIntoView();
      }
    }
  };

  const onClickContent = (event) => {
    if (event) {
      event.stopPropagation();
    }

    let jsonBackup = resetContent(json);
    setJson(jsonBackup);
    setGson(jsonBackup);
    setShowAttrView(false);
    setShowAddElement(true);
    setSelectedComponent("page");
  };

  const getDefaultHtml = () => {
    if (props.screen === "add-article") {
      return defaultArticleHtml;
    }
    if (props.screen === "add-custom-section") {
      return defaultCustomSectionHtml;
    }
    return defaultHtml;
  };

  const onClickSave = (option = 1) => {
    removeItem("eMode");
    removeItem("bpJson");
    removeItem("bpPageAttr");
    if (option === 1) {
      setStorageItem("saveEditor", "yes");
    } else {
      setStorageItem("saveEditor", "no");
    }

    if (eMode === "popup") {
      const jsonData = saveHtml(gson, textValues, draftJsonValues);
      jsonData.contentEditor.type = editorType;
      if (!popups) {
        popups = [];
      }
      let pId = getUniqueId();
      if (popupId) {
        popups.forEach((element) => {
          if (element.id === popupId) {
            element.json = jsonData;
          }
        });
        pId = popupId;
      } else {
        popups.push({ id: pId, json: jsonData });
      }
      bpJson.contentEditor.popups = popups;

      setPageAttr(bpPageAttr);
      setEmode("normal");
      setPopupId("");

      let jsonBackup = selectItem(
        bpJson,
        seValues.sectionId,
        seValues.rowId,
        seValues.colId,
        seItem
      );

      setPopups(popups);
      setJson(jsonBackup);
      setGson(jsonBackup);

      setSectionId(seValues.sectionId);
      setRowId(seValues.rowId);
      setItem(seItem);
      setColumnId(seValues.colId);
      setShowAttrView(true);
      setShowMoveRow(true);
      setShowAddElement(true);
      setSelectedComponent("item");
      scrollRef.current.scrollTop(0);
    } else {
      if (props.onSave) {
        const jsonData = saveHtml(gson, textValues, draftJsonValues);
        const editorPopups = props.editorPopups || [];
        const html = generateHtml(
          getDefaultHtml(),
          jsonData,
          editorType,
          editorPopups,
          popups,
          attributes
        );
        jsonData.contentEditor.attributes = attributes;
        jsonData.contentEditor.type = editorType;
        jsonData.contentEditor.itemStyles = itemStyles;
        jsonData.contentEditor.codeHtml = codeHtml;
        jsonData.contentEditor.customWidth = customWidth;
        // jsonData.contentEditor.popups = popups;

        if (props.screen === "add-help-desk") {
          const coretml = generateHtml(
            defaultCoreHtml,
            jsonData,
            editorType,
            editorPopups,
            popups,
            attributes
          );
          PubSub.publish("helpDeskCoreHtml", coretml);
        }
        // console.log(html);
        props.onSave(html, jsonData);
      }
    }
  };

  const getGeneratedHtmlJson = () => {
    const jsonData = saveHtml(json, textValues, draftJsonValues);
    const editorPopups = props.editorPopups || [];
    const html = generateHtml(
      getDefaultHtml(),
      jsonData,
      editorType,
      editorPopups,
      popups
    );

    jsonData.contentEditor.attributes = attributes;
    jsonData.contentEditor.type = editorType;
    jsonData.contentEditor.itemStyles = itemStyles;
    // jsonData.contentEditor.popups = popups;

    return { html: html, json: jsonData };
  };

  const onClickCancelPopup = () => {
    let bpJsonD = getStorageItem("bpJson");
    let bpPageAttr = getStorageItem("bpPageAttr");

    setJson(bpJsonD);
    setGson(bpJsonD);
    setPageAttr(bpPageAttr);
    setEmode("normal");
    setPopupId("");
  };

  const onClickSelectImage = (type) => {
    props.onClickSelectImage(type);
  };

  const onClickSaveTemplate = () => {
    const jsonData = saveHtml(gson, textValues, draftJsonValues);
    const editorPopups = props.editorPopups || [];
    const html = generateHtml(
      getDefaultHtml(),
      jsonData,
      editorType,
      editorPopups,
      popups
    );
    // jsonData.contentEditor.popups = popups;
    jsonData.contentEditor.itemStyles = itemStyles;
    jsonData.contentEditor.type = editorType;
    if (props.onSaveTemplate) {
      props.onSaveTemplate(html, jsonData);
    }
  };

  const onClickExport = (event) => {
    event.stopPropagation();
    let jsonData = saveHtml(gson, textValues, draftJsonValues);
    const editorPopups = props.editorPopups || [];
    const html = generateHtml(
      getDefaultHtml(),
      jsonData,
      editorType,
      editorPopups,
      popups,
      attributes
    );
    if (!event.ctrlKey) {
      // localStorage.setItem("htmlStr", html);
      // jsonData.contentEditor.popups = popups;
      props.onPreview(html, jsonData);
      // window.open("/preview-mp-editor");
    } else {
      var file = new File([prettify(html)], "content.html", {
        type: "text/plain",
      });
      saveAs(file);
    }
  };

  const onClickUndo = (event) => {
    event.stopPropagation();
    if (history.length > 1) {
      const jsonBackup = history.pop();
      if (jsonBackup) {
        redoHistory.push(JSON.parse(JSON.stringify(json)));
        setJson(jsonBackup);
        setGson(jsonBackup);
      }
    }
  };

  const onClickRedo = (event) => {
    event.stopPropagation();
    if (redoHistory.length > 0) {
      const jsonBackup = redoHistory.pop();
      if (jsonBackup) {
        history.push(JSON.parse(JSON.stringify(json)));
        setJson(jsonBackup);
        setGson(jsonBackup);
      }
    }
  };

  const onClickButtonFullWidthToggle = (event) => {
    event.stopPropagation();
    let jsonBackup = changeButtonFullWidth(
      json,
      selectedSectionId,
      selectedRowId,
      selectedColumnId,
      selectedItem,
      btnFullWidth
    );
    setJson(jsonBackup);
    setGson(jsonBackup);
    handleValueBlur();
  };

  const onClickSetWidth = (event) => {
    event.stopPropagation();
    setWidthError(false);

    let colValue = checkSelectedGrid();
    let count = colValue.count;
    let cols = colValue.cols;
    let total = 0;

    cols.forEach((element, index) => {
      let value =
        customWidth[element.attributes.id] ||
        Math.floor(100 / count).toFixed(0);
      total += parseFloat(value.toString());
      customWidth[element.attributes.id] = value;
    });

    if (!total || total > 100) {
      setWidthError(true);
    } else {
      setCustomWidth(customWidth);
      let jsonBackup = changeRowWidth(
        json,
        customWidth,
        selectedSectionId,
        selectedRowId
      );
      setJson(jsonBackup);
      setGson(jsonBackup);
    }
  };

  const renderSection = () => {
    let editor = json.contentEditor;
    let sections = editor.itemList.filter((d) => d.type === "section");
    let returnVals = [];

    sections.forEach((element) => {
      returnVals.push(
        <ContentSection
          section={element}
          key={element.attributes.id}
          onClickAddRow={onClickAddRow}
          onClickSection={onClickSection}
          onDeleteSection={onDeleteSection}
          onClickRow={onClickRow}
          onDeleteRow={onDeleteRow}
          onClickColumn={onClickColumn}
          onClickItem={onClickItem}
          onClickAddHtml={onClickAddHtml}
          onClickAddItem={onClickAddItem}
          onClickSelectGrid={onClickSelectGrid}
          onClickAddImage={onClickAddImage}
          onClickAddVideo={onClickAddVideo}
          onClickAddAudio={onClickAddAudio}
        />
      );
    });
    return returnVals;
  };

  const onClickCustomStyles = () => {
    setStylesIsOpen(true);
  };

  const onClickItemStyles = () => {
    setItemStylesIsOpen(true);
  };

  const onClickAddColumnStep = () => {
    console.log(selectedItem);
  };

  const onClickAddTags = () => {
    setAddTagsIsOpen(true);
  };

  const onClickAddInputTags = () => {
    setAddInputTagsIsOpen(true);
  };

  const onClickAddOptionTags = () => {
    setAddOptionTagsIsOpen(true);
  };

  const renderTags = () => {
    let tags = getAttributeValue("buttonTags");
    let tagString = "";
    tags.forEach((element) => {
      let fTags = systemTags.filter((d) => d._id === element);
      if (fTags.length > 0) {
        if (tagString === "") {
          tagString = `${fTags[0].name}`;
        } else {
          tagString = `${tagString}, ${fTags[0].name}`;
        }
      }
    });

    return <label className="bConHeader">{tagString}</label>;
  };

  const onClickDownImageOption = (index, event) => {
    event.stopPropagation();
    let menus = getAttributeValue("sliderImages") || [];
    if (index < menus.length) {
      const to = index + 1;
      var f = menus.splice(index, 1)[0];
      menus.splice(to, 0, f);
    }
    changeAttributeValue(getSelectedComponentId(), "sliderImages", menus);
  };

  const onClickUpImageOption = (index, event) => {
    event.stopPropagation();
    let menus = getAttributeValue("sliderImages") || [];
    const to = index - 1;
    if (index > 0) {
      var f = menus.splice(index, 1)[0];
      menus.splice(to, 0, f);
    }
    changeAttributeValue(getSelectedComponentId(), "sliderImages", menus);
  };

  const renderSliderImages = () => {
    let sliderImages = getAttributeValue("sliderImages") || [];
    let imageHtml = [];
    for (let i = 0; i < sliderImages.length; i++) {
      const element = sliderImages[i];
      imageHtml.push(
        <div>
          <div className="alt-row">
            <div className="alt-col-6">
              <div className="bConHeader">
                <img src={element.src} alt={"Trash"} height={35} width={35} />
              </div>
            </div>

            <div className="alt-col-2">
              <div
                style={{ cursor: "pointer" }}
                onClick={onClickUpImageOption.bind(this, i)}>
                <img src={NavigateUpArrow} alt={"Up"} height={10} width={10} />
              </div>
            </div>

            <div className="alt-col-2">
              <div
                style={{ cursor: "pointer" }}
                className=""
                onClick={onClickDownImageOption.bind(this, i)}>
                <img
                  src={NavigateDownArrow}
                  alt={"Down"}
                  height={10}
                  width={10}
                />
              </div>
            </div>
            <div className="alt-col-2">
              <div
                style={{ cursor: "pointer" }}
                onClick={onClickDeleteImageOption.bind(this, element)}>
                <img src={TrashSVG} alt={"Trash"} height={13} width={13} />
              </div>
            </div>
          </div>
        </div>
      );
    }
    return imageHtml;
  };

  const renderInputTags = () => {
    let tags = getAttributeValue("inputSaveResponseTags");
    let tagString = "";
    tags.forEach((element) => {
      let fTags = systemTags.filter((d) => d._id === element);
      if (fTags.length > 0) {
        if (tagString === "") {
          tagString = `${fTags[0].name}`;
        } else {
          tagString = `${tagString}, ${fTags[0].name}`;
        }
      }
    });

    return <label className="bConHeader">{tagString}</label>;
  };

  const renderSelectedProduct = () => {
    let productId = getAttributeValue("productId");
    let productString = "";
    if (productId) {
      let productInfo = products.find((d) => d.product_id === productId);
      if (productInfo) {
        productString = productInfo.name;
      }
    }
    return <label className="bConHeader">{productString}</label>;
  };

  const renderOptionTags = () => {
    let tagsMap = getAttributeValue("optionTags") || {};
    let tags = [];
    if (tagsMap[selectedSelectOption]) {
      tags = tagsMap[selectedSelectOption];
    }
    let tagString = "";
    tags.forEach((element) => {
      let fTags = systemTags.filter((d) => d._id === element);
      if (fTags.length > 0) {
        if (tagString === "") {
          tagString = `${fTags[0].name}`;
        } else {
          tagString = `${tagString}, ${fTags[0].name}`;
        }
      }
    });

    return <label className="bConHeader">{tagString}</label>;
  };

  const checkSelectedGrid = () => {
    let itemList = gson.contentEditor ? gson.contentEditor.itemList : [];
    let count = 0;
    let cols = [];
    itemList.forEach((secElem) => {
      if (secElem.itemList && selectedRowId) {
        let selectedRowElem = secElem.itemList.find(
          (d) => d && d.attributes.id === selectedRowId
        );
        if (selectedRowElem) {
          count = selectedRowElem.itemList.length;
          cols = selectedRowElem.itemList;
        }
      }
    });
    return { count, cols };
  };

  const renderCustomWidthView = () => {
    let returnVals = [];
    let colValue = checkSelectedGrid();
    let count = colValue.count;
    let length = colValue.cols.length;
    for (let o = 0; o < length; o++) {
      const col = colValue.cols[o];
      let value =
        customWidth[col.attributes.id] || Math.floor(100 / count).toFixed(0);

      returnVals.push(
        <td key={col.attributes.id}>
          <input
            type="text"
            value={value}
            name={col.attributes.id}
            onChange={handlePerChange}
          />
        </td>
      );
    }
    return returnVals;
  };

  const onClickUpAttribute = (type, event) => {
    event.stopPropagation();
    let value = getAttributeValue(type);
    if (!value) {
      value = "0";
    }
    value = parseFloat(value) + 1;
    changeAttributeValue(getSelectedComponentId(), type, value.toString());
  };

  const onClickDownAttribute = (type, event) => {
    event.stopPropagation();
    let value = getAttributeValue(type);
    if (!value) {
      value = "0";
    }
    value = parseFloat(value) - 1;
    changeAttributeValue(getSelectedComponentId(), type, value.toString());
  };

  const onClickUpPageAttr = (name, type, event) => {
    event.stopPropagation();
    let value = getPageAttrValue(name, type);
    if (!value) {
      value = "0";
    }
    value = parseFloat(value) + 1;
    changePageValue(name, type, value.toString());
  };

  const onClickDownPageAttr = (name, type, event) => {
    event.stopPropagation();
    let value = getPageAttrValue(name, type);
    if (!value) {
      value = "0";
    }
    value = parseFloat(value) - 1;
    changePageValue(name, type, value.toString());
  };

  const getSelectedComponentId = () => {
    let id = "";
    if (selectedComponent === "row") {
      id = selectedRowId;
    } else if (selectedComponent === "column") {
      id = selectedColumnId;
    } else if (selectedComponent === "item") {
      id = selectedItem.attributes.id;
    } else if (selectedComponent === "section") {
      id = selectedSectionId;
    }
    return id;
  };

  const getAttributeValue = (type) => {
    let id = getSelectedComponentId();
    let value = "";
    let pageWidth = gson.contentEditor.width;

    value = getItemAttributeValue(
      type,
      id,
      isButton,
      selectedItem,
      selectedComponent,
      attributes,
      editorType,
      pageWidth
    );
    return value;
  };

  const getPageAttrValue = (name, type) => {
    let value = "";
    value = getPageAttributeValue(name, type, pageAttr);
    return value;
  };

  const renderSelectedComponentType = () => {
    if (selectedComponent === "item") {
      let type = selectedItem.type;
      if (type === "input") {
        return "Input Field";
      }
      if (type === "fileInput") {
        return "File Attachment";
      }
      if (type === "subTitle") {
        return "Sub Title";
      }
      if (type === "text") {
        return "Paragraph";
      }
      if (type === "checkoutForm") {
        return "Checkout";
      }
      if (type === "productName") {
        return "Product Name";
      }
      if (type === "productDescription") {
        return "Product Description";
      }
      if (type === "productPrice") {
        return "Product Price";
      }
      if (type === "productImage") {
        return "Product Image";
      }
      if (type === "productCart") {
        return "Product Cart";
      }
      if (type === "iconText") {
        return "Icon Text";
      }
      if (type === "imageSlider") {
        return "Image Slider";
      }
      if (type === "socialMediaLinks") {
        return "Social Media Links";
      }
      return type;
    }
    return selectedComponent;
  };

  const renderFonts = () => {
    if (editorType === "email") {
      return (
        <>
          <option value="Arial">Arial</option>
          <option value="Courier">Courier</option>
          <option value="Georgia">Georgia</option>
          <option value="Helvetica">Helvetica</option>
          <option value="Tahoma">Tahoma</option>
          <option value="Times New Roman">Times New Roman</option>
          <option value="Trebuchet">Trebuchet</option>
          <option value="Verdana">Verdana</option>
        </>
      );
    }
    return (
      <>
        <option value="Actor">Actor</option>
        <option value="Alex Brush">Alex Brush</option>
        <option value="Arizonia">Arizonia</option>
        <option value="Assistant">Assistant</option>
        <option value="Barlow">Barlow</option>
        <option value="Bungee Spice">Bungee Spice</option>
        <option value="Cabin">Cabin</option>
        <option value="Cedarville Cursive">Cedarville Cursive</option>
        <option value="Cinzel">Cinzel</option>
        <option value="DM Sans">DM Sans</option>
        <option value="Epilogue">Epilogue</option>
        <option value="Exo">Exo</option>
        <option value="Faustina">Faustina</option>
        <option value="Fira Sans">Fira Sans</option>
        <option value="Gelasio">Gelasio</option>
        <option value="Glegoo">Glegoo</option>
        <option value="Heebo">Heebo</option>
        <option value="Hind">Hind</option>
        <option value="IBM Plex Sans">IBM Plex Sans</option>
        <option value="Inconsolata">Inconsolata</option>
        <option value="Kanit">Kanit</option>
        <option value="Karla">Karla</option>
        <option value="Kaushan Script">Kaushan Script</option>
        <option value="Lato">Lato</option>
        <option value="Lobster">Lobster</option>
        <option value="Lora">Lora</option>
        <option value="Lustria">Lustria</option>
        <option value="Merriweather">Merriweather</option>
        <option value="Mirza">Mirza</option>
        <option value="Montserrat">Montserrat</option>
        <option value="Mulish">Mulish</option>
        <option value="Nanum Gothic">Nanum Gothic</option>
        <option value="Noto Sans Devanagari">Noto Sans Devanagari</option>
        <option value="Noto Sans NKo">Noto Sans N’Ko</option>
        <option value="Nunito">Nunito</option>
        <option value="Nunito Sans">Nunito Sans</option>
        <option value="Open Sans">Open Sans</option>
        <option value="Outfit">Outfit</option>
        <option value="Overlock">Overlock</option>
        <option value="Overpass">Overpass</option>
        <option value="Ovo">Ovo</option>
        <option value="Oxygen">Oxygen</option>
        <option value="Pacifico">Pacifico</option>
        <option value="Philosopher">Philosopher</option>
        <option value="Play">Play</option>
        <option value="Playfair Display">Playfair Display</option>
        <option value="Poppins">Poppins</option>
        <option value="Quicksand">Quicksand</option>
        <option value="Raleway">Raleway</option>
        <option value="Roboto">Roboto</option>
        <option value="Rubik">Rubik</option>
        <option value="Salsa">Salsa</option>
        <option value="Sofia">Sofia</option>
        <option value="Sofia Sans Condensed">Sofia Sans Condensed</option>
        <option value="Source Sans Pro">Source Sans Pro</option>
        <option value="Suranna">Suranna</option>
        <option value="Tajawal">Tajawal</option>
        <option value="Titillium Web">Titillium Web</option>
        <option value="Ubuntu">Ubuntu</option>
      </>
    );
  };

  const renderInputTypes = () => {
    return (
      <>
        <option value="custom">Custom Field</option>
        <option value="name">Name</option>
        <option value="email">Email Address</option>
        <option value="phone">Phone</option>
        <option value="address1">Address</option>
        <option value="address2">Address 2</option>
        <option value="city">City</option>
        <option value="state">State</option>
        <option value="zip">Zip</option>
        <option value="country">Country</option>
        <option value="websiteUrl">Website Url</option>
        <option value="hidden">Hidden</option>
      </>
    );
  };

  const renderFormNames = () => {
    let formNames = getFormNames(json);
    return (
      <>
        <option value={""}>Select Form</option>
        {formNames.map((i) => (
          <option value={i}>{i}</option>
        ))}
      </>
    );
  };

  const renderPopups = () => {
    let editorPopups = popups || [];
    return (
      <>
        {editorPopups.map((i, index) => (
          <option value={i.id}>
            {i.title ? i.title : `Popup - ${index + 1}`}
          </option>
        ))}
      </>
    );
  };

  const onClickSelectIcon = (event) => {
    event.stopPropagation();
    setButtonModalIsOpen(true);
  };

  const onClickSelectIconElem = (event) => {
    event.stopPropagation();
    setIconModalIsOpen(true);
  };

  const onSelectIcon = (name) => {
    setButtonModalIsOpen(false);
    changeAttributeValue(getSelectedComponentId(), "buttonIconName", name);
    handleValueBlur();
  };

  const onSelectIconElem = (name) => {
    setIconModalIsOpen(false);
    changeAttributeValue(getSelectedComponentId(), "iconElemName", name);
    handleValueBlur();
  };

  const onClickAlignButtonIcon = (alignment, event) => {
    event.stopPropagation();
    changeAttributeValue(
      getSelectedComponentId(),
      "buttonIconAlignment",
      alignment
    );
    handleValueBlur();
  };

  const onClickTextAlign = (align, event) => {
    event.stopPropagation();
    changeAttributeValue(getSelectedComponentId(), "textAlign", align);
    handleValueBlur();
  };

  const onClickPageTextAlign = (align, event) => {
    event.stopPropagation();
    changePageValue("page", "textAlign", align);
  };

  const closeCodeModal = () => {
    setIsOpen(false);
    let jsonBackup = changeCodeHtml(
      json,
      selectedSectionId,
      selectedRowId,
      selectedColumnId,
      selectedItem.attributes.id,
      codeHtml
    );
    setJson(jsonBackup);
    setGson(jsonBackup);
  };

  const closeStylesModal = () => {
    setStylesIsOpen(false);
    let jsonBackup = changeCustomStyles(json, cStyles);
    setJson(jsonBackup);
    setGson(jsonBackup);
  };

  const closeItemStylesModal = () => {
    setItemStylesIsOpen(false);
    let jsonBackup = changeItemCustomStyles(json, itemStyles);
    setJson(jsonBackup);
    setGson(jsonBackup);
  };

  const closeAddTagsModal = () => {
    setAddTagsIsOpen(false);
  };

  const onClickEditHtml = (event) => {
    event.stopPropagation();
    setIsOpen(true);
  };

  const onClickDeleteImageOption = (element, event) => {
    event.stopPropagation();
    let menus = getAttributeValue("sliderImages") || [];
    menus = menus.filter((d) => d.id !== element.id);
    changeAttributeValue(getSelectedComponentId(), "sliderImages", menus);
  };

  const onChangeCodeChange = (id, event) => {
    event.stopPropagation();
    let value = event.target.value;
    codeHtml[id] = value;
    setCodeHtml(codeHtml);
  };

  const onChangeCustomStyles = (event) => {
    event.stopPropagation();
    let value = event.target.value;
    setCustomStyles(value);
    props.onChangeCss(value);
  };

  const onChangeItemStyles = (event) => {
    event.stopPropagation();
    let value = event.target.value;
    itemStyles[getSelectedComponentId()] = value;
    setItemStyles(itemStyles);
    props.onChangeItemCss(itemStyles);
  };

  const getSelectedItemId = () => {
    let id = "";
    if (selectedComponent === "section") {
      id = selectedSectionId;
    }
    if (selectedComponent === "row") {
      id = selectedRowId;
    }
    if (selectedComponent === "column") {
      id = selectedColumnId;
    }
    if (selectedComponent === "item") {
      id = selectedItem.attributes.id;
    }

    return id;
  };

  const renderButtonIconModal = () => {
    const customStyles = {
      overlay: { zIndex: 20000 },
      content: {
        top: "10%",
        left: "35%",
        right: "35%",
        bottom: "10%",
      },
    };
    return (
      <Modal
        isOpen={buttonModalIsOpen}
        contentLabel="Button Modal"
        style={customStyles}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => {
          setButtonModalIsOpen(false);
        }}>
        <div className="alt-row">
          {Object.keys(icons).map((i) => (
            <div
              className="alt-col-2"
              key={i}
              onClick={onSelectIcon.bind(this, i)}
              style={{ padding: 10, cursor: "pointer" }}>
              <ContentIcon name={i} width="50" color="black" key={i} />
            </div>
          ))}
        </div>
      </Modal>
    );
  };

  const callSaveSection = async () => {
    const selectedId = getSelectedComponentId();

    var itemList = json.contentEditor.itemList;
    itemList = itemList.filter((d) => d.attributes.id === selectedId);

    const sectionId = getUniqueId();
    let sectionData = itemList[0];
    sectionData.subId = sectionId;

    let jsonData = {
      contentEditor: {
        id: getUniqueId(),
        contentId: getUniqueId(),
        type: "web",
        subType: "contentSection",
        pageTitle: "Content Section",
        attributes: attributes,
        bodyAttributes: {
          alignment: "center",
          paddingLeft: "0px",
          paddingRight: "0px",
          paddingTop: "0px",
          paddingBottom: "0px",
          backgroundColor: "#EDEDED",
          backgroundImage: "",
          marginTop: "0px",
          marginBottom: "0px",
        },
        itemList: [sectionData],
      },
    };

    const jsonUpdatedData = saveHtml(jsonData, textValues, draftJsonValues);
    const editorPopups = props.editorPopups || [];
    const htmlData = generateHtml(
      getDefaultHtml(),
      jsonUpdatedData,
      editorType,
      editorPopups,
      popups
    );

    /*eslint-disable*/
    let data = {
      name: sectionName,
      json: jsonUpdatedData,
      html: htmlData,
      section_id: sectionId,
      non_customizable: sectionNonCustomizable,
      item_styles: itemStyles,
      custom_styles: itemStyles[itemList[0].attributes.id],
    };
    /*eslint-enable*/
    await callCreateContentEditorSection(data);
    showNotification("success", "Section saved");
    setSectionNameIsOpen(false);
    setReloadSections(!reloadSections);
  };

  const callSaveEditSection = async () => {
    /*eslint-disable*/
    await callUpdateContentEditorSection(editSectionId, {
      name: sectionName,
      non_customizable: sectionNonCustomizable,
    });
    /*eslint-enable*/
    showNotification("success", "Section saved");
    setSectionNameIsOpen(false);
    setReloadSections(!reloadSections);
  };

  const renderSectionNameModal = () => {
    const customStyles = {
      overlay: { zIndex: 30000 },
      content: {
        top: "auto",
        left: "35%",
        right: "35%",
        bottom: "50%",
      },
    };
    return (
      <Modal
        isOpen={sectionNameIsOpen}
        contentLabel="Save Section"
        style={customStyles}
        shouldCloseOnOverlayClick={false}
        onRequestClose={() => {
          setSectionNameIsOpen(false);
          setIsEditSection(false);
        }}>
        <p>Save Section</p>
        <div className="row">
          <div className="col-12">
            <input
              type="text"
              value={sectionName}
              name={"sectionName"}
              placeholder={"Enter section name"}
              onChange={(event) => {
                setSectionName(event.target.value);
              }}
            />
          </div>
        </div>
        <div style={{ height: 30 }}></div>
        {/* <div className="row">
          <div className="col-12">
            <input
              type="checkbox"
              checked={sectionNonCustomizable}
              onChange={(event) => {
                setSectionNonCustomizable(event.target.checked);
              }}
            />{" "}
            Non Customizable
          </div>
        </div> */}

        <div style={{ height: 20 }}></div>
        {isEditSection ? (
          <button
            className="btn btn-sm btn-primary margin-right-10"
            onClick={callSaveEditSection}>
            Save
          </button>
        ) : (
          <button
            className="btn btn-sm btn-primary margin-right-10"
            onClick={callSaveSection}>
            Submit
          </button>
        )}
        <button
          className="btn btn-sm btn-default"
          onClick={() => {
            setSectionNameIsOpen(false);
            setIsEditSection(false);
          }}>
          Close
        </button>
      </Modal>
    );
  };

  const onClickDeleteCustomSection = async (element) => {
    await callDeleteContentEditorSectionById(element._id);
    setReloadSections(!reloadSections);
  };

  const onClickInsertCustomSection = (element) => {
    let itemList = json.contentEditor.itemList;
    let iStyles = itemStyles || json.contentEditor.itemStyles;
    let attributeList = attributes || json.contentEditor.attributes;

    let newJson = changeContentJsonIds(element);
    itemList = itemList.concat(newJson.jsonBackup.itemList);
    if (newJson.jsonBackup.attributes.id) {
      iStyles[newJson.jsonBackup.attributes.id] = element.custom_styles || {};
    }

    let itStyles = { ...iStyles, ...newJson.itemStyles };
    let attri = {
      ...newJson.attributes,
      ...attributeList,
    };

    let jsonBackup = Object.assign({}, json);
    jsonBackup.contentEditor.itemList = itemList;
    jsonBackup.contentEditor.itemStyles = itStyles;
    jsonBackup.contentEditor.attributes = attri;

    setAttributes(attri);
    setItemStyles(itStyles);
    setJson(jsonBackup);
    setGson(jsonBackup);
    setSectionModalIsOpen(false);
  };

  const onClickEditCustomSection = (element) => {
    setSectionName(element.name);
    setSectionNonCustomizable(element.non_customizable);
    setIsEditSection(true);
    setEditSectionId(element._id);
    setSectionNameIsOpen(true);
  };

  const onClickInsertProduct = (element) => {
    if (addItemType === "page") {
      let jsonBackup = selectAllColumn(json);
      setJson(jsonBackup);
      setGson(jsonBackup);
      setElementType(`product${element}`);
      setMoveArea(false);
    } else {
      onClickAddTextElement(
        selectedSectionId,
        selectedRowId,
        selectedColumnId,
        `product${element}`
      );
      setElementType("");
      setAddItemType("page");
    }
    setProductModalIsOpen(false);
  };

  const onClickSelectProduct = (element) => {
    changeAttributeValue(
      getSelectedComponentId(),
      "productId",
      element.product_id
    );
    setProductListModalIsOpen(false);
  };

  const renderSectionsModal = () => {
    const customStyles = {
      overlay: { zIndex: 20000 },
      content: {
        top: "10%",
        left: "30%",
        right: "30%",
        bottom: "10%",
      },
    };
    return (
      <Modal
        isOpen={sectionModalIsOpen}
        contentLabel="Save Section"
        style={customStyles}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => {
          setSectionModalIsOpen(false);
        }}>
        <p>Sections</p>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Name</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {sections.map((element) => (
              <tr>
                <td>{element.name}</td>
                <td>
                  <button
                    onClick={onClickInsertCustomSection.bind(this, element)}
                    className="btn btn-default btn-sm margin-right-10">
                    Insert
                  </button>
                  {(element.from !== "templates" || isTemplateSite()) && (
                    <button
                      onClick={onClickEditCustomSection.bind(this, element)}
                      className="btn btn-default btn-sm margin-right-10">
                      <i className="fa fa-pencil" />
                    </button>
                  )}
                  {(element.from !== "templates" || isTemplateSite()) && (
                    <button
                      onClick={onClickDeleteCustomSection.bind(this, element)}
                      className="btn btn-danger btn-sm margin-right-10">
                      <i className="fa fa-trash" />
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <br />
        <div style={{ float: "right" }}>
          <button
            className="btn btn-sm btn-default"
            onClick={() => setSectionModalIsOpen(false)}>
            Close
          </button>
        </div>
      </Modal>
    );
  };

  const renderProductFieldsModal = () => {
    const customStyles = {
      overlay: { zIndex: 20000 },
      content: {
        top: "auto",
        left: "35%",
        right: "35%",
        bottom: "50%",
      },
    };
    return (
      <Modal
        isOpen={productModalIsOpen}
        contentLabel="Product Fields"
        style={customStyles}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => {
          setProductModalIsOpen(false);
        }}>
        <div className="bControlSection controlToolAddElements">
          <div className="bConHeader">Product Fields</div>
          <div className="addElemHold">
            {["Name", "Description", "Price", "Image", "Cart"].map(
              (element) => (
                <div>
                  <div
                    className="addElemItem addTitleElement"
                    title={element}
                    onClick={onClickInsertProduct.bind(this, element)}>
                    <img src={ButtonSVG} alt={element} />
                    <span>{element}</span>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </Modal>
    );
  };

  const renderProductListModal = () => {
    const customStyles = {
      overlay: { zIndex: 20000 },
      content: {
        top: "10%",
        left: "30%",
        right: "30%",
        bottom: "10%",
      },
    };
    return (
      <Modal
        isOpen={productListModalIsOpen}
        contentLabel="Products"
        style={customStyles}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => {
          setProductListModalIsOpen(false);
        }}>
        <p>Products</p>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Name</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {products.map((element) => (
              <tr>
                <td className="firstLetter">{element.name}</td>
                <td>
                  <button
                    onClick={onClickSelectProduct.bind(this, element)}
                    className="btn btn-default btn-sm margin-right-10">
                    Select
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <br />
        <div style={{ float: "right" }}>
          <button
            className="btn btn-sm btn-default"
            onClick={() => setProductListModalIsOpen(false)}>
            Close
          </button>
        </div>
      </Modal>
    );
  };

  const renderIconElemModal = () => {
    const customStyles = {
      overlay: { zIndex: 20000 },
      content: {
        top: "10%",
        left: "35%",
        right: "35%",
        bottom: "10%",
      },
    };
    return (
      <Modal
        isOpen={iconModalIsOpen}
        contentLabel="Icon Elem Modal"
        style={customStyles}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => {
          setIconModalIsOpen(false);
        }}>
        <div className="alt-row">
          {Object.keys(icons).map((i) => (
            <div
              className="alt-col-2"
              key={i}
              onClick={onSelectIconElem.bind(this, i)}
              style={{ padding: 10, cursor: "pointer" }}>
              <ContentIcon name={i} width="50" color="black" key={i} />
            </div>
          ))}
        </div>
      </Modal>
    );
  };

  const renderCodeModal = () => {
    const customStyles = {
      overlay: { zIndex: 20000 },
      content: {
        top: "10%",
        left: "35%",
        right: "35%",
        bottom: "20%",
      },
    };

    return (
      <Modal
        isOpen={modalIsOpen}
        contentLabel="Code Modal"
        style={customStyles}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => {
          setIsOpen(false);
        }}>
        <textarea
          style={{ width: "100%", minHeight: "200px", maxHeight: "40vh" }}
          type="text"
          className="mpTextInputStandard"
          defaultValue={codeHtml[getSelectedComponentId()]}
          onChange={onChangeCodeChange.bind(this, getSelectedComponentId())}
        />
        <br />
        <div style={{ float: "right" }}>
          <button
            className="btn btn-sm btn-danger"
            style={{ marginRight: "10px" }}
            onClick={() => setIsOpen(false)}>
            Cancel
          </button>
          <button className="btn btn-sm btn-default" onClick={closeCodeModal}>
            Save
          </button>
        </div>
      </Modal>
    );
  };

  const onClickSelectItem = (item) => {
    onClickItem(selectedSectionId, selectedRowId, selectedColumnId, item);
    setColItemOpen(false);
  };

  const renderColItems = () => {
    const colItems = getColItems(
      json,
      selectedSectionId,
      selectedRowId,
      selectedColumnId
    );

    var returnVals = [];
    for (let i = 0; i < colItems.length; i++) {
      const element = colItems[i];

      returnVals.push(
        <tr>
          <td>{element.attributes.id}</td>
          <td>{element.type}</td>
          <td>
            <button
              onClick={onClickSelectItem.bind(this, element)}
              className="btn btn-default btn-sm margin-right-10">
              Select
            </button>
          </td>
        </tr>
      );
    }
    return returnVals;
  };

  const renderColumnItemsModal = () => {
    const customStyles = {
      overlay: { zIndex: 20000 },
      content: {
        top: "10%",
        left: "25%",
        right: "25%",
        bottom: "10%",
      },
    };
    return (
      <Modal
        isOpen={modalColItemOpen}
        contentLabel="Column Items Modal"
        style={customStyles}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => {
          setIsOpen(false);
        }}>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>ID</th>
              <th>Type</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>{renderColItems()}</tbody>
        </table>
        <br />
        <div style={{ float: "right" }}>
          <button
            className="btn btn-sm btn-default"
            style={{ marginRight: "10px" }}
            onClick={() => setColItemOpen(false)}>
            Close
          </button>
        </div>
      </Modal>
    );
  };

  const renderCustomStylesModal = () => {
    const customStylesDiv = {
      overlay: { zIndex: 20000 },
      content: {
        top: "auto",
        left: "35%",
        right: "35%",
        bottom: "50%",
      },
    };

    var value = cStyles || json.contentEditor.customStyles;

    return (
      <Modal
        isOpen={modalStylesIsOpen}
        contentLabel="Custom Styles Modal"
        style={customStylesDiv}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => {
          setStylesIsOpen(false);
        }}>
        <textarea
          style={{ width: "100%", minHeight: "200px", maxHeight: "40vh" }}
          type="text"
          className="mpTextInputStandard"
          defaultValue={value}
          onChange={onChangeCustomStyles.bind(this)}
        />
        <br />
        <div style={{ float: "right" }}>
          {/* <button
            className="btn btn-sm btn-danger"
            style={{ marginRight: "10px" }}
            onClick={() => setStylesIsOpen(false)}>
            Cancel
          </button> */}
          <button className="btn btn-sm btn-default" onClick={closeStylesModal}>
            Close
          </button>
        </div>
      </Modal>
    );
  };

  const renderItemStylesModal = () => {
    const customStylesDiv = {
      overlay: { zIndex: 20000 },
      content: {
        top: "auto",
        left: "35%",
        right: "35%",
        bottom: "50%",
      },
    };

    var cStyle = itemStyles || json.contentEditor.itemStyles || {};

    var value = cStyle[getSelectedComponentId()];

    return (
      <Modal
        isOpen={modalItemStylesIsOpen}
        contentLabel="Item Styles Modal"
        style={customStylesDiv}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => {
          setItemStylesIsOpen(false);
        }}>
        <textarea
          style={{ width: "100%", minHeight: "200px", maxHeight: "40vh" }}
          type="text"
          className="mpTextInputStandard"
          defaultValue={value}
          placeholder="Type your css here like: background-color:pink; border:7px solid !important;"
          onChange={onChangeItemStyles.bind(this)}
        />
        <br />
        <div style={{ float: "right" }}>
          {/* <button
            className="btn btn-sm btn-danger"
            style={{ marginRight: "10px" }}
            onClick={() => setItemStylesIsOpen(false)}>
            Cancel
          </button> */}
          <button
            className="btn btn-sm btn-default"
            onClick={closeItemStylesModal}>
            Close
          </button>
        </div>
      </Modal>
    );
  };

  const renderSelectGridModal = () => {
    const customStyles = {
      overlay: { zIndex: 20000 },
      content: {
        top: "auto",
        left: "35%",
        right: "35%",
        bottom: "50%",
      },
    };

    return (
      <Modal
        isOpen={gridModalIsOpen}
        contentLabel="Grid Modal"
        style={customStyles}
        onRequestClose={() => {
          setGridModalIsOpen(false);
        }}
        shouldCloseOnOverlayClick={true}>
        <div className="contentHolder mpPageBuilder">
          <div className="builderControlHolder">
            <div className="bControlsInner">{renderSelectGrid()}</div>
          </div>
        </div>
      </Modal>
    );
  };

  const renderAddElementModal = () => {
    const customStyles = {
      overlay: { zIndex: 20000 },
      content: {
        top: "auto",
        left: "35%",
        right: "35%",
        bottom: "50%",
      },
    };

    return (
      <Modal
        isOpen={addElementModalIsOpen}
        contentLabel="Add Element Modal"
        style={customStyles}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => {
          setAddElementModalIsOpen(false);
        }}>
        <div>
          <div className="contentHolder mpPageBuilder">
            <div className="builderControlHolder">
              <div className="bControlsInner">{renderAddElement()}</div>
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  const renderAddTagsModal = () => {
    const addTagsDiv = {
      overlay: { zIndex: 20000 },
      content: {
        top: "auto",
        left: "25%",
        right: "25%",
        bottom: "50%",
      },
    };

    let tags = getAttributeValue("buttonTags") || [];

    return (
      <Modal
        isOpen={modalAddTagsIsOpen}
        contentLabel="Add Tags Modal"
        style={addTagsDiv}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => {
          setStylesIsOpen(false);
        }}>
        <TagPicker
          selectedTags={tags}
          onChangeTags={(tagList, reload) => {
            setReloadTags(reload);
            let tList = tagList.map((i) => i._id);
            changeAttributeValue(getSelectedComponentId(), "buttonTags", tList);
          }}
        />
        <br />
        <button className="btn btn-default" onClick={closeAddTagsModal}>
          Close
        </button>
      </Modal>
    );
  };

  const renderAddInputTagsModal = () => {
    const addTagsDiv = {
      overlay: { zIndex: 20000 },
      content: {
        top: "auto",
        left: "25%",
        right: "25%",
        bottom: "50%",
      },
    };

    let tags = getAttributeValue("inputSaveResponseTags") || [];

    return (
      <Modal
        isOpen={modalAddInputTagsIsOpen}
        contentLabel="Add Input Tags Modal"
        style={addTagsDiv}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => {
          setAddInputTagsIsOpen(false);
        }}>
        <TagPicker
          selectedTags={tags}
          onChangeTags={(tagList, reload) => {
            setReloadTags(reload);
            let tList = tagList.map((i) => i._id);
            changeAttributeValue(
              getSelectedComponentId(),
              "inputSaveResponseTags",
              tList
            );
          }}
        />
        <br />
        <button
          className="btn btn-default"
          onClick={() => setAddInputTagsIsOpen(false)}>
          Close
        </button>
      </Modal>
    );
  };

  const renderAddOptionTagsModal = () => {
    const addTagsDiv = {
      overlay: { zIndex: 20000 },
      content: {
        top: "auto",
        left: "25%",
        right: "25%",
        bottom: "50%",
      },
    };

    let tagsMap = getAttributeValue("optionTags") || {};
    let tags = [];
    if (tagsMap[selectedSelectOption]) {
      tags = tagsMap[selectedSelectOption];
    }

    return (
      <Modal
        isOpen={modalAddOptionTagsIsOpen}
        contentLabel="Add Option Tags Modal"
        style={addTagsDiv}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => {
          setAddOptionTagsIsOpen(false);
        }}>
        <TagPicker
          selectedTags={tags}
          onChangeTags={(tagList, reload) => {
            setReloadTags(reload);

            let tList = tagList.map((i) => i._id);
            tagsMap[selectedSelectOption] = tList;

            changeAttributeValue(
              getSelectedComponentId(),
              "optionTags",
              tagsMap
            );
          }}
        />
        <br />
        <button
          className="btn btn-default"
          onClick={() => setAddOptionTagsIsOpen(false)}>
          Close
        </button>
      </Modal>
    );
  };

  useEffect(() => {
    window.onmessage = (e) => {
      if (e.data.hasOwnProperty("frameHeight")) {
        document.getElementById("iframe_src").style.height = `${
          e.data.frameHeight + 30
        }px`;
      } else if (e.data.hasOwnProperty("checkoutFrameHeight")) {
        document.getElementsByClassName("checkoutFrame")[0].style.height = `${
          e.data.checkoutFrameHeight + 30
        }px`;
      } else if (e.data.hasOwnProperty("mpRedirectUrl")) {
        window.location.assign(e.data.mpRedirectUrl);
        return false;
      }
    };
  }, []);

  useEffect(() => {
    PubSub.subscribe("getMpEditorData", (key) => {
      let data = getGeneratedHtmlJson();
      PubSub.publish("mpEditorData", data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function fetchData() {
      const response = await callGetContentEditorSections();
      setSections(response.data);

      const resp = await callGetProducts();
      setProducts(resp.data);
      setProductStore(resp.data);
    }
    fetchData();
  }, [reloadSections, setProductStore]);

  useEffect(() => {
    async function fetchData() {
      const response = await callGetSystemTags({
        query: { $select: ["name", "_id"] },
      });
      setSystemTags(response.data);
    }
    fetchData();
  }, [reloadTags]);

  useEffect(() => {
    async function fetchData() {
      /*eslint-disable*/
      let query = {
        query: {
          member_type: "staff",
          $select: ["_id", "name"],
          populate: "no",
        },
      };
      /*eslint-enable*/
      let d = await callGetUsersByQuery(query);
      let users = d.data;
      setStaffUsers(users);

      /*eslint-disable*/
      let cQuery = {
        query: {
          $select: ["_id", "checkout_id", "name"],
          archive: { $ne: "archive" },
        },
      };
      /*eslint-enable*/
      let cd = await callGetCheckoutItemByQuery(cQuery);
      let items = cd.data;
      setCheckoutItems(items);
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (props.imageUrl) {
      var imageUrl = props.imageUrl;

      if (props.imageType === "imageUrl") {
        let cW = Object.assign({}, urlAttr);
        cW[selectedItem.attributes.id] = imageUrl;
        setUrlAttr(cW);

        let jsonBackup = changeUrlAttr(
          json,
          selectedSectionId,
          selectedRowId,
          selectedColumnId,
          selectedItem,
          cW,
          "imageUrl"
        );
        setJson(jsonBackup);
        setGson(jsonBackup);
      } else if (props.imageType === "thumbUrl") {
        let cW = Object.assign({}, urlAttr);
        cW[selectedItem.attributes.id + "-thumb"] = imageUrl;
        setUrlAttr(cW);

        let jsonBackup = changeUrlAttr(
          json,
          selectedSectionId,
          selectedRowId,
          selectedColumnId,
          selectedItem,
          cW,
          "thumbUrl"
        );
        setJson(jsonBackup);
        setGson(jsonBackup);
      } else if (props.imageType === "page") {
        changePageValue("page", "backgroundImage", imageUrl);
      } else if (props.imageType === "item") {
        changeAttributeValue(
          getSelectedComponentId(),
          "backgroundImage",
          imageUrl
        );
      } else if (props.imageType === "audioUrl") {
        let cW = Object.assign({}, urlAttr);
        cW[selectedItem.attributes.id] = imageUrl;
        setUrlAttr(cW);

        let jsonBackup = changeUrlAttr(
          json,
          selectedSectionId,
          selectedRowId,
          selectedColumnId,
          selectedItem,
          cW,
          "audioUrl"
        );
        setJson(jsonBackup);
        setGson(jsonBackup);
      } else if (props.imageType === "sliderImageUrl") {
        let sliderImages = getAttributeValue("sliderImages") || [];
        sliderImages.push({ id: getUniqueId(), src: imageUrl });

        changeAttributeValue(
          getSelectedComponentId(),
          "sliderImages",
          sliderImages
        );
      }
    }
    if (props.videoUrl) {
      var videoUrl = props.videoUrl;

      if (props.imageType === "videoUrl") {
        let cW = Object.assign({}, urlAttr);
        let selectedItemId = props.itemId || getSelectedComponentId();
        cW[selectedItemId] = videoUrl;
        setUrlAttr(cW);

        let jsonBackup = changeUrlAttr(
          json,
          selectedSectionId,
          selectedRowId,
          selectedColumnId,
          selectedItem,
          cW,
          "videoUrl"
        );
        setJson(jsonBackup);
        setGson(jsonBackup);
      }
    }
    /*eslint-disable*/
  }, [
    props.imageUrl,
    props.videoUrl,
    props.refreshKey,
    props.itemId,
    props.imageKey,
  ]);
  /*eslint-enable*/

  useEffect(() => {
    // setJson(json);
    if (eMode === "normal") {
      if (props.json.contentEditor && props.editorMode === "normal") {
        setJson({ contentEditor: { itemList: [] } });
        setGson({ contentEditor: { itemList: [] } });

        setJson(props.json);
        setGson(props.json);
      }
      if (props.json.contentEditor) {
        let fonts = getEditorFonts(props.json);
        if (fonts.length > 0) {
          props.onChangeFontsCss(fonts);
        }

        props.onChangeCss(props.json.contentEditor.customStyles);
        props.onChangeItemCss(props.json.contentEditor.itemStyles);
        props.onChangeLinkCss(props.json.contentEditor.linkStyles);
      }
      if (props.displayMode) {
        setEditorType(props.displayMode);
        setStorageItem("et", props.displayMode);
      }
    } else if (eMode === "popup") {
      let bpJsonD = getStorageItem("bpJson");
      setBpJson(bpJsonD);
      if (props.json.contentEditor && props.editorMode === "popup") {
        setJson(props.json);
        setGson(props.json);
        setEmode("popup");
      }
      if (props.json.contentEditor) {
        props.onChangeCss(props.json.contentEditor.customStyles);
        props.onChangeItemCss(props.json.contentEditor.itemStyles);
        props.onChangeLinkCss(props.json.contentEditor.linkStyles);
      }
      if (props.displayMode) {
        setEditorType(props.displayMode);
        setStorageItem("et", props.displayMode);
      }
    }
    /*eslint-disable*/
  }, [eMode, setEmode, props.displayMode, setEditorType]);
  /*eslint-enable*/

  const renderAddElement = () => {
    return (
      <div className="bControlSection controlToolAddElements">
        <div className="bConHeader">Add New Item</div>
        <div className="addElemHold">
          <div>
            <div
              className={`addElemItem addTitleElement ${
                elementType === "title" ? " addElemItemActive" : ""
              }`}
              title="Title"
              onClick={onClickAddElement.bind(this, "title")}>
              <img src={TitleSVG} alt="Title" />
              <span>Title</span>
            </div>
          </div>
          <div>
            <div
              className={`addElemItem addSubTitleElement ${
                elementType === "subTitle" ? " addElemItemActive" : ""
              }`}
              title="Subtitle"
              onClick={onClickAddElement.bind(this, "subTitle")}>
              <img src={SubTitleSVG} alt="SubTitle" />
              <span>Subtitle</span>
            </div>
          </div>
          <div>
            <div
              className={`addElemItem addTextElement ${
                elementType === "text" ? " addElemItemActive" : ""
              }`}
              title="Text"
              onClick={onClickAddElement.bind(this, "text")}>
              <img src={ParagraphSVG} alt="Paragraph" />
              <span>Paragraph</span>
            </div>
          </div>
          <div>
            <div
              className={`addElemItem addTextElement ${
                elementType === "image" ? " addElemItemActive" : ""
              }`}
              title="Image"
              onClick={onClickAddElement.bind(this, "image")}>
              <img src={ImageSVG} alt="Img" />
              <span>Image</span>
            </div>
          </div>
          <div>
            <div
              onClick={onClickAddElement.bind(this, "button")}
              className={`addElemItem addTextElement ${
                elementType === "button" ? " addElemItemActive" : ""
              }`}
              title="Button">
              <img src={ButtonSVG} alt="Button" />
              <span>Button</span>
            </div>
          </div>
          {(editorType === "web" || editorType === "popup") && (
            <>
              <div>
                <div
                  className={`addElemItem addTextElement ${
                    elementType === "video" ? " addElemItemActive" : ""
                  }`}
                  title="Video"
                  onClick={onClickAddElement.bind(this, "video")}>
                  <img src={VideoSVG} alt="Img" />
                  <span>Video</span>
                </div>
              </div>
              <div>
                <div
                  className={`addElemItem addTextElement ${
                    elementType === "audio" ? " addElemItemActive" : ""
                  }`}
                  title="Audio"
                  onClick={onClickAddElement.bind(this, "audio")}>
                  <img src={AudioSVG} alt="Img" />
                  <span>Audio</span>
                </div>
              </div>
              <div>
                <div
                  onClick={onClickAddElement.bind(this, "input")}
                  className={`addElemItem addTextElement ${
                    elementType === "input" ? " addElemItemActive" : ""
                  }`}
                  title="Input">
                  <img src={InputSVG} alt="Input" />
                  <span>Input Box</span>
                </div>
              </div>
              <div>
                <div
                  onClick={onClickAddElement.bind(this, "fileInput")}
                  className={`addElemItem addTextElement ${
                    elementType === "fileInput" ? " addElemItemActive" : ""
                  }`}
                  title="File Attachment">
                  <img src={InputSVG} alt="fileInput" />
                  <span>File Attachment</span>
                </div>
              </div>
              <div>
                <div
                  onClick={onClickAddElement.bind(this, "checkoutForm")}
                  className={`addElemItem addTextElement ${
                    elementType === "checkoutForm" ? " addElemItemActive" : ""
                  }`}
                  title="Checkout Form">
                  <img src={CheckoutFormSVG} alt="CheckoutForm" />
                  <span>Checkout Form</span>
                </div>
              </div>
              <div>
                <div
                  onClick={onClickAddElement.bind(this, "checkbox")}
                  className={`addElemItem addTextElement ${
                    elementType === "checkbox" ? " addElemItemActive" : ""
                  }`}
                  title="Checkbox">
                  <img src={CheckBoxSVG} alt="Checkbox" />
                  <span>Check Box</span>
                </div>
              </div>
              <div>
                <div
                  onClick={onClickAddElement.bind(this, "selectbox")}
                  className={`addElemItem addTextElement ${
                    elementType === "selectbox" ? " addElemItemActive" : ""
                  }`}
                  title="SelectBox">
                  <img src={SelectBoxSVG} alt="SelectBox" />
                  <span>Select Box</span>
                </div>
              </div>
              <div>
                <div
                  onClick={onClickAddElement.bind(this, "line")}
                  className={`addElemItem addTextElement ${
                    elementType === "line" ? " addElemItemActive" : ""
                  }`}
                  title="Line">
                  <img src={LineHRSVG} alt="Line" />
                  <span>Line</span>
                </div>
              </div>
              <div>
                <div
                  onClick={onClickAddElement.bind(this, "countdown")}
                  className={`addElemItem addTextElement ${
                    elementType === "countdown" ? " addElemItemActive" : ""
                  }`}
                  title="Timer">
                  <img src={CountdownSVG} alt="Countdown Timer" />
                  <span>Countdown Timer</span>
                </div>
              </div>
              <div>
                <div
                  onClick={onClickAddElement.bind(this, "icon")}
                  className={`addElemItem addTextElement ${
                    elementType === "icon" ? " addElemItemActive" : ""
                  }`}
                  title="Icon">
                  <img src={IconSelectSVG} alt="Icon" />
                  <span>Icon</span>
                </div>
              </div>
              <div>
                <div
                  onClick={onClickAddElement.bind(this, "iconText")}
                  className={`addElemItem addTextElement ${
                    elementType === "iconText" ? " addElemItemActive" : ""
                  }`}
                  title="Icon Text">
                  <img src={IconTextSelectSVG} alt="Icon Text" />
                  <span>Icon Text</span>
                </div>
              </div>
              <div>
                <div
                  onClick={onClickAddElement.bind(this, "imageText")}
                  className={`addElemItem addTextElement ${
                    elementType === "imageText" ? " addElemItemActive" : ""
                  }`}
                  title="Image Text">
                  <img src={IconTextSelectSVG} alt="Images Text" />
                  <span>Image Text</span>
                </div>
              </div>
            </>
          )}
          <div>
            <div
              onClick={onClickAddElement.bind(this, "code")}
              className={`addElemItem addTextElement ${
                elementType === "code" ? " addElemItemActive" : ""
              }`}
              title="Custom HTML">
              <img src={CodeSVG} alt="Code" />
              <span>Code</span>
            </div>
          </div>
          <div>
            <div
              onClick={onClickAddElement.bind(this, "customSections")}
              className={`addElemItem addTextElement ${
                elementType === "code" ? " addElemItemActive" : ""
              }`}
              title="Custom Section">
              <img src={SectionSVG} alt="Code" />
              <span>Custom Section</span>
            </div>
          </div>
          <div
            onClick={onClickAddElement.bind(this, "product")}
            className={`addElemItem addTextElement ${
              elementType === "product" ? " addElemItemActive" : ""
            }`}
            title="Product">
            <img src={SectionSVG} alt="Product" />
            <span>Product</span>
          </div>
          <div
            onClick={onClickAddElement.bind(this, "imageSlider")}
            className={`addElemItem addTextElement ${
              elementType === "imageSlider" ? " addElemItemActive" : ""
            }`}
            title="Image Slider">
            <img src={ImageSVG} alt="ImageSlider" />
            <span>Image Slider</span>
          </div>
          <div
            onClick={onClickAddElement.bind(this, "socialMediaLinks")}
            className={`addElemItem addTextElement ${
              elementType === "socialMediaLinks" ? " addElemItemActive" : ""
            }`}
            title="Social Media Links">
            <img src={ImageSVG} alt="socialMediaLinks" />
            <span>Social Media Links</span>
          </div>
        </div>
      </div>
    );
  };

  const renderSelectGrid = () => {
    return (
      <>
        <div className="bControlSection controlToolColumns">
          <div className="bConHeader">Select Grid</div>
          <div
            className={`gridItem ${
              checkSelectedGrid().count === 1 ? "gridItemActive" : ""
            }`}
            data-col-count="1"
            onClick={onClickGrid.bind(this, 1)}>
            <span>1</span>
            <div className="gridSecHold">
              <div className="gridSecRow">
                <div className="gridSec"></div>
              </div>
            </div>
          </div>
          <div
            className={`gridItem ${
              checkSelectedGrid().count === 2 ? "gridItemActive" : ""
            }`}
            data-col-count="2"
            onClick={onClickGrid.bind(this, 2)}>
            <span>2</span>
            <div className="gridSecHold">
              <div className="gridSecRow">
                {[1, 2].map((i) => (
                  <div className="gridSec"></div>
                ))}
              </div>
            </div>
          </div>
          <div
            className={`gridItem ${
              checkSelectedGrid().count === 3 ? "gridItemActive" : ""
            }`}
            data-col-count="3"
            onClick={onClickGrid.bind(this, 3)}>
            <span>3</span>
            <div className="gridSecHold">
              <div className="gridSecRow">
                {[1, 2, 3].map((i) => (
                  <div className="gridSec"></div>
                ))}
              </div>
            </div>
          </div>
          <div
            className={`gridItem ${
              checkSelectedGrid().count === 4 ? "gridItemActive" : ""
            }`}
            data-col-count="4"
            onClick={onClickGrid.bind(this, 4)}>
            <span>4</span>
            <div className="gridSecHold">
              <div className="gridSecRow">
                {[1, 2, 3, 4].map((i) => (
                  <div className="gridSec"></div>
                ))}
              </div>
            </div>
          </div>
          <div
            className={`gridItem ${
              checkSelectedGrid().count === 5 ? "gridItemActive" : ""
            }`}
            data-col-count="5"
            onClick={onClickGrid.bind(this, 5)}>
            <span>5</span>
            <div className="gridSecHold">
              <div className="gridSecRow">
                {[1, 2, 3, 4, 5].map((i) => (
                  <div className="gridSec"></div>
                ))}
              </div>
            </div>
          </div>
          <div
            className={`gridItem ${
              checkSelectedGrid().count === 6 ? "gridItemActive" : ""
            }`}
            data-col-count="6"
            onClick={onClickGrid.bind(this, 6)}>
            <span>6</span>
            <div className="gridSecHold">
              <div className="gridSecRow">
                {[1, 2, 3, 4, 5, 6].map((i) => (
                  <div className="gridSec"></div>
                ))}
              </div>
            </div>
          </div>
        </div>
        {checkSelectedGrid().count >= 1 && (
          <div className="bControlSection controlToolColumns">
            <div className="bConHeader">Set custom width (%)</div>
            <div className="customGridItem">
              <div className="customGridWd">
                <table className="customWdTable">
                  <tbody>
                    <tr>{renderCustomWidthView()}</tr>
                  </tbody>
                </table>
                <div className="text-center">
                  {/*eslint-disable*/}
                  <a
                    className="toolButton"
                    onClick={onClickSetWidth.bind(this)}>
                    SET
                  </a>
                  {/*eslint-enable*/}
                  {widthError && (
                    <div className="custWdAlert">
                      Total width cannot be more than 100%
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  const renderDays = () => {
    let returnVals = [];
    let year = getAttributeValue("cdDateYear") || new Date().getFullYear();
    let month = getAttributeValue("cdDateMonth") || "1";
    let noOfDays = daysInMonth(month, year);

    for (let i = 1; i <= noOfDays; i++) {
      let value = i;
      if (i < 10) {
        value = `0${i}`;
      }
      returnVals.push(<option value={value}>{value}</option>);
    }
    return returnVals;
  };

  const renderHours = () => {
    let returnVals = [];
    for (let i = 0; i < 24; i++) {
      let value = i;
      if (i < 10) {
        value = `0${i}`;
      }
      returnVals.push(<option value={value}>{value}</option>);
    }
    return returnVals;
  };

  const renderMinues = () => {
    let returnVals = [];
    for (let i = 1; i <= 60; i++) {
      let value = i;
      if (i < 10) {
        value = `0${i}`;
      }
      returnVals.push(<option value={value}>{value}</option>);
    }
    return returnVals;
  };

  const renderOptionSelection = () => {
    let options = getAttributeValue("inputOptions");
    if (options.length === 0) {
      return <div />;
    }
    let arrOptions = options.split("\n");
    let wOptions = [<option value={""}>Select option</option>];
    arrOptions.forEach((element) => {
      if (element) {
        wOptions.push(<option value={element}>{element}</option>);
      }
    });
    return (
      <div className="alt-row">
        <div className="alt-col-12">
          <div>
            <div className="bConHeader">Option Tags</div>
            <select
              className="cSelectText"
              name="option"
              value={selectedSelectOption}
              onChange={(event) => {
                setSelectedSelectOption(event.target.value);
              }}>
              {wOptions}
            </select>
          </div>
        </div>
      </div>
    );
  };

  const getBackgroundImageStyle = () => {
    let backRepeat = getPageAttrValue("page", "backgroundRepeat");
    let bImage = getPageAttrValue("page", "backgroundImage");
    let bCimage = "";
    if (bImage) {
      bCimage = `url(${bImage})`;
    }
    return {
      backgroundColor: getPageAttrValue("page", "backgroundColor"),
      backgroundImage: bCimage,
      backgroundRepeat: backRepeat ? "repeat" : "no-repeat",
      backgroundSize: !backRepeat
        ? getPageAttrValue("page", "backgroundSize")
          ? "contain"
          : "cover"
        : "unset",
      backgroundAttachment: !backRepeat
        ? getPageAttrValue("page", "backgroundAttachment") || "scroll"
        : "unset",
      backgroundPosition:
        getPageAttrValue("page", "backgroundPosition") || "top",
      opacity: getPageAttrValue("page", "backgroundOpacity") || 1.0,
      width: "calc(100vw - 310px)",
      maxWidth: json.contentEditor.width + "px",
    };
  };

  const subType = json.contentEditor.subType || "normal";

  return (
    <div>
      {renderCodeModal()}
      {renderButtonIconModal()}
      {renderIconElemModal()}
      {renderSelectGridModal()}
      {renderAddElementModal()}
      {renderCustomStylesModal()}
      {renderItemStylesModal()}
      {renderAddTagsModal()}
      {renderAddOptionTagsModal()}
      {renderAddInputTagsModal()}
      {renderColumnItemsModal()}
      {renderSectionNameModal()}
      {renderSectionsModal()}
      {renderProductFieldsModal()}
      {renderProductListModal()}
      <div className="contentHolder mpPageBuilder">
        <div
          className="builderContent"
          onClick={onClickContent.bind(this)}
          style={getBackgroundImageStyle()}>
          {(history.length > 0 || redoHistory.length > 0) && (
            <div class="undo-redo-buttons">
              {history.length > 0 && (
                <div class="undo-redo-button" onClick={onClickUndo.bind(this)}>
                  <img src={UndoSVG} style={{ maxHeight: "15px" }} alt="undo" />
                </div>
              )}
              {redoHistory.length > 0 && (
                <div
                  class="undo-redo-button button"
                  onClick={onClickRedo.bind(this)}>
                  <img src={RedoSVG} style={{ maxHeight: "15px" }} alt="redo" />
                </div>
              )}
            </div>
          )}
          <div className="builderContentInner">{renderSection()}</div>
          {/*eslint-disable*/}
          {subType === "normal" && (
            <div className="addSectionButton">
              <a onClick={onClickAddSection.bind(this)}>ADD SECTION</a>
            </div>
          )}
        </div>
        <div className="builderContentMenuShow">
          <div class="showBuilderControls">
            <a onClick={onClickShowMobileMenu.bind(this)}>
              <img
                src={MenuSVG}
                style={{
                  maxHeight: "18px",
                  paddingLeft: "6px",
                  paddingTop: "6px",
                }}
                alt="Menu"
              />
            </a>
          </div>
        </div>
        <div className={contentMenuClass}>
          <Scrollbars
            ref={scrollRef}
            autoHide
            autoHideTimeout={1000}
            autoHideDuration={1000}
            style={{
              height: "calc(100vh - 130px)",
              width: "230px",
              position: "fixed",
              right: "15px",
              marginBottom: "20px",
              background: "rgba(255, 255, 255, 1)",
              borderRadius: "15px",
              boxShadow: "0 0 20px rgba(0, 0, 0, 0.2)",
              opacity: "1",
              minHeight: "85px",
              marginTop: "20px",
              zIndex: "1",
            }}>
            {selectedComponent !== "section" &&
              selectedComponent !== "page" && (
                <div class="alt-row" style={{ backgroundColor: "#f1f1f1" }}>
                  {(selectedComponent === "column" ||
                    selectedComponent === "row" ||
                    selectedComponent === "item") && (
                    <div
                      class="alt-col-3"
                      style={{
                        fontSize: "12px",
                        cursor: "pointer",
                        textAlign: "center",
                      }}
                      onClick={() => {
                        onClickNavSelect("section");
                      }}>
                      <div
                        class="bConTitle"
                        style={{
                          fontSize: "11px",
                          color: "#818181",
                          textAlign: "center",
                        }}>
                        Section
                      </div>
                    </div>
                  )}
                  {(selectedComponent === "column" ||
                    selectedComponent === "item") && (
                    <div
                      class="alt-col-1"
                      style={{ fontSize: "12px", textAlign: "center" }}>
                      <div
                        class="bConTitle"
                        style={{ fontSize: "12px", textAlign: "center" }}>
                        {">"}
                      </div>
                    </div>
                  )}
                  {(selectedComponent === "column" ||
                    selectedComponent === "item") && (
                    <div
                      class="alt-col-3"
                      style={{
                        fontSize: "12px",
                        cursor: "pointer",
                        textAlign: "center",
                      }}
                      onClick={() => {
                        onClickNavSelect("row");
                      }}>
                      <div
                        class="bConTitle"
                        style={{
                          fontSize: "11px",
                          color: "#818181",
                          textAlign: "center",
                        }}>
                        Row
                      </div>
                    </div>
                  )}
                  {selectedComponent === "item" && (
                    <div
                      class="alt-col-1"
                      style={{ fontSize: "12px", textAlign: "center" }}>
                      <div
                        class="bConTitle"
                        style={{ fontSize: "12px", textAlign: "center" }}>
                        {">"}
                      </div>
                    </div>
                  )}
                  {selectedComponent === "item" && (
                    <div
                      class="alt-col-3"
                      style={{
                        fontSize: "12px",
                        cursor: "pointer",
                        textAlign: "center",
                      }}
                      onClick={() => {
                        onClickNavSelect("column");
                      }}>
                      <div
                        class="bConTitle"
                        style={{
                          fontSize: "11px",
                          color: "#818181",
                          textAlign: "center",
                        }}>
                        Column
                      </div>
                    </div>
                  )}
                </div>
              )}
            <div className="builderControlHolder">
              {showAttrView && selectedComponent !== "page" && (
                <>
                  <div className="bControlSection controlToolFontSize bConSettingsArea">
                    <div className="alt-row">
                      <div className="alt-col-8">
                        <div className="bConTitle">
                          {renderSelectedComponentType()}
                        </div>
                      </div>
                      <div className="alt-col-2">
                        <div
                          className="bConDelete moveRowDown"
                          key={getSelectedComponentId()}
                          style={{
                            fontSize: 10,
                            marginTop: 5,
                            color: itemStyles[getSelectedComponentId()]
                              ? "red"
                              : "black",
                          }}
                          onClick={onClickItemStyles}>
                          CSS
                        </div>
                      </div>
                      {selectedComponent !== "column" && (
                        <>
                          <div className="alt-col-2">
                            <div
                              className="bConDelete moveRowDown"
                              onClick={onClickCopy.bind(this)}>
                              <img src={CopySVG} />
                            </div>
                          </div>
                          <div className="alt-col-2">
                            <div
                              className="bConDelete moveRowDown"
                              onClick={onClickDelete.bind(this)}>
                              <img src={TrashSVG} />
                            </div>
                          </div>
                        </>
                      )}

                      {showMoveRow && selectedComponent !== "column" && (
                        <>
                          {selectedComponent === "item" && (
                            <div className="alt-col-2">
                              <img
                                src={MoveAreaSVG}
                                className="moveRowUp"
                                onClick={onClickMoveArea}
                              />
                            </div>
                          )}

                          <div className="alt-col-2">
                            <img
                              src={MoveUpSVG}
                              className="moveRowUp"
                              onClick={onClickMoveRowUp}
                            />
                          </div>
                          <div className="alt-col-2">
                            <img
                              src={MoveDownSVG}
                              className="moveRowDown"
                              onClick={onClickMoveRowDown}
                            />
                          </div>
                        </>
                      )}

                      <CopyToClipboard
                        text={getSelectedItemId()}
                        onCopy={() =>
                          showNotification(
                            "success",
                            "ID copied to clipboard",
                            5000
                          )
                        }>
                        <div className="alt-col-2">
                          <div
                            className="bConDelete moveRowDown"
                            title={getSelectedItemId()}>
                            <img src={AlertSVG} />
                          </div>
                        </div>
                      </CopyToClipboard>
                      {selectedComponent === "column" && (
                        <div className="alt-col-2">
                          <img
                            src={AlertSVG}
                            className="bConDelete moveRowDown"
                            onClick={onClickColumnItems.bind(this)}
                          />
                        </div>
                      )}
                      {selectedComponent === "section" && (
                        <div className="alt-col-2">
                          <img
                            src={SaveSVG}
                            className="bConDelete moveRowDown"
                            onClick={onClickSaveSectionIcon.bind(this)}
                          />
                        </div>
                      )}
                      <div className="alt-col-2">
                        {selectedComponent !== "section" && (
                          <img
                            src={SelectUpSVG}
                            className="moveRowUp"
                            onClick={onClickSelectParent}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div className="bControlsInner">
                <>
                  {selectedComponent !== "page" &&
                    showAttrView &&
                    isTemplateSite() && (
                      <div className="bControlSection controlToolFontSize">
                        <div className="alt-row">
                          <div className="alt-col-9">
                            <div className="bConHeader">Lock</div>
                          </div>
                          <div className="alt-col-3">
                            <div className="customSpinner">
                              <Toggle
                                id={getSelectedComponentId()}
                                name={getSelectedComponentId()}
                                checked={isLockElem}
                                onChange={handleLockElemChange.bind(this)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                </>

                {selectedComponent === "page" && (
                  <>
                    {!showAttrView && (
                      <div className="bControlSection controlToolPadding">
                        <div className="alt-row">
                          <div className="alt-col-10">
                            <div className="bConHeader">
                              {editorType === "popup" ? "Popup" : "Page"}{" "}
                              Details
                            </div>
                          </div>
                          <div className="alt-col-2">
                            <div
                              onClick={onClickSetting.bind(this)}
                              className="bConDelete"
                              title={getSelectedItemId()}>
                              <img src={SettingSVG} width={19} />
                            </div>
                          </div>
                        </div>
                        <div className="alt-row">
                          <div className="alt-col-12">
                            <div className="bConHeaderSec">Width</div>
                            <div className="customSpinner">
                              <a
                                className="cSpinBtn spinUp"
                                onClick={onClickUpPageAttr.bind(
                                  this,
                                  "page",
                                  "width"
                                )}>
                                <img src={NavigateUpArrow} />
                              </a>
                              <input
                                type="text"
                                className="cSpinTextLong"
                                value={getPageAttrValue("page", "width")}
                                name={"page"}
                                onChange={handlePageValueChange.bind(
                                  this,
                                  "width"
                                )}
                                onBlur={handlePageValueBlur.bind(this)}
                              />
                              <a
                                className="cSpinBtn spinDown"
                                onClick={onClickDownPageAttr.bind(
                                  this,
                                  "page",
                                  "width"
                                )}>
                                <img src={NavigateDownArrow} />
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="gap10" />
                        <div className="alt-row">
                          <div className="bConHeader">Alignment</div>
                          <div className="fontEditRow fontEditRowIconsAlignment">
                            <div className="alt-col-2 alt-no-margin">
                              <a
                                onClick={onClickPageTextAlign.bind(
                                  this,
                                  "left"
                                )}
                                data-text-effect="textLeft"
                                className={`${
                                  getPageAttrValue("page", "textAlign") ===
                                  "left"
                                    ? "activeAlignment"
                                    : ""
                                } btntextLeft`}>
                                <img src={LeftAlignmentSVG} />
                              </a>
                            </div>
                            <div className="alt-col-2 alt-no-margin">
                              <a
                                onClick={onClickPageTextAlign.bind(
                                  this,
                                  "center"
                                )}
                                data-text-effect="textCenter"
                                className={`${
                                  getPageAttrValue("page", "textAlign") ===
                                  "center"
                                    ? "activeAlignment"
                                    : ""
                                } btntextCenter`}>
                                <img src={CenterAlignmentSVG} />
                              </a>
                            </div>
                            <div className="alt-col-2 alt-no-margin">
                              <a
                                onClick={onClickPageTextAlign.bind(
                                  this,
                                  "right"
                                )}
                                data-text-effect="textRight"
                                className={`${
                                  getPageAttrValue("page", "textAlign") ===
                                  "right"
                                    ? "activeAlignment"
                                    : ""
                                } btntextRight`}>
                                <img src={RightAlignmentSVG} />
                              </a>
                            </div>
                          </div>
                        </div>

                        <div className="gap10" />

                        {/* For Section, Row, & Column */}
                        <div className="alt-row">
                          <div className="alt-col-12 alt-no-margin">
                            <div className="bConHeaderColor">
                              Background Image
                            </div>
                          </div>
                        </div>

                        <div className="alt-row">
                          <div className="alt-col-10">
                            <div className="customSpinner">
                              <input
                                type="text"
                                className="cInputText"
                                name={"page"}
                                value={getPageAttrValue(
                                  "page",
                                  "backgroundImage"
                                )}
                                onChange={handlePageInputChange.bind(
                                  this,
                                  "backgroundImage"
                                )}
                                onBlur={handlePageValueBlur.bind(this)}
                              />
                            </div>
                          </div>
                          <div className="alt-col-2">
                            <img
                              src={SelectImageSVG}
                              onClick={onClickSelectImage.bind(this, "page")}
                              className="selectImage"
                            />
                          </div>
                        </div>

                        {getPageAttrValue("page", "backgroundImage").trim()
                          .length > 0 && (
                          <>
                            <div className="alt-row">
                              <div className="alt-col-8">
                                <div className="bConHeaderColor">
                                  Repeat Background
                                </div>
                              </div>
                              <div className="alt-col-4">
                                <div className="text-right">
                                  <Toggle
                                    id={"pageBackgroundRepeat"}
                                    name={"page"}
                                    defaultChecked={getPageAttrValue(
                                      "page",
                                      "backgroundRepeat"
                                    )}
                                    checked={getPageAttrValue(
                                      "page",
                                      "backgroundRepeat"
                                    )}
                                    onChange={handlePageToggleChange.bind(
                                      this,
                                      "backgroundRepeat"
                                    )}
                                    onBlur={handlePageValueBlur.bind(this)}
                                  />
                                </div>
                              </div>
                            </div>
                            {getPageAttrValue("page", "backgroundRepeat") ===
                              false && (
                              <>
                                <div className="alt-row">
                                  <div className="alt-col-6">
                                    <div className="bConHeaderColor">
                                      Background Type
                                    </div>
                                  </div>
                                  <div className="alt-col-6">
                                    <div className="text-right">
                                      <select
                                        type="text"
                                        className="cSelectText"
                                        name={"page"}
                                        onBlur={handlePageValueBlur.bind(this)}
                                        value={getPageAttrValue(
                                          "page",
                                          "backgroundAttachment"
                                        )}
                                        onChange={handlePageInputChange.bind(
                                          this,
                                          "backgroundAttachment"
                                        )}>
                                        <option value="scroll">
                                          Scrolling
                                        </option>
                                        <option value="fixed">Fixed</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div className="alt-row">
                                  <div className="alt-col-6">
                                    <div className="bConHeaderColor">
                                      Background Position
                                    </div>
                                  </div>
                                  <div className="alt-col-6">
                                    <div className="text-right">
                                      <select
                                        type="text"
                                        className="cSelectText"
                                        name={"page"}
                                        onBlur={handlePageValueBlur.bind(this)}
                                        value={getPageAttrValue(
                                          "page",
                                          "backgroundPosition"
                                        )}
                                        onChange={handlePageInputChange.bind(
                                          this,
                                          "backgroundPosition"
                                        )}>
                                        <option value="top">Top</option>
                                        <option value="bottom">Bottom</option>
                                        <option value="center">Center</option>
                                        <option value="left">Left</option>
                                        <option value="right">Right</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>

                                <div className="alt-row">
                                  <div className="alt-col-6">
                                    <div className="bConHeaderColor">
                                      Contain Image
                                    </div>
                                  </div>
                                  <div className="alt-col-6">
                                    <div className="text-right">
                                      <Toggle
                                        id={"pageBackgroundSize"}
                                        name={"page"}
                                        defaultChecked={getPageAttrValue(
                                          "page",
                                          "backgroundSize"
                                        )}
                                        checked={getPageAttrValue(
                                          "page",
                                          "backgroundSize"
                                        )}
                                        onChange={handlePageToggleChange.bind(
                                          this,
                                          "backgroundSize"
                                        )}
                                        onBlur={handlePageValueBlur.bind(this)}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="alt-row">
                                  <div className="alt-col-6">
                                    <div className="bConHeaderColor">
                                      Opacity
                                    </div>
                                  </div>
                                </div>
                                <div className="alt-row">
                                  <div className="alt-col-12">
                                    <input
                                      type="range"
                                      min="0"
                                      max="1"
                                      step="0.1"
                                      color="#2ae"
                                      name={"page"}
                                      value={getPageAttrValue(
                                        "page",
                                        "backgroundOpacity"
                                      )}
                                      onChange={handlePageInputChange.bind(
                                        this,
                                        "backgroundOpacity"
                                      )}
                                    />
                                  </div>
                                </div>
                                <div className="alt-row">
                                  <div className="alt-col-6">
                                    <label className="opacitylabel">
                                      Opacity{" "}
                                      {getPageAttrValue(
                                        "page",
                                        "backgroundOpacity"
                                      ) * 100}
                                      %
                                    </label>
                                  </div>
                                </div>
                              </>
                            )}
                          </>
                        )}

                        <div className="gap10" />
                        {/* For Section, Row, & Column */}
                        <div className="alt-row">
                          <div className="alt-col-8">
                            <div className="bConHeaderColor">
                              Background Color
                            </div>
                          </div>
                        </div>

                        <ColorPicker
                          key="pageBackgroundColor"
                          color={getPageAttrValue("page", "backgroundColor")}
                          onClose={handlePageValueBlur.bind(this)}
                          onChange={onChangeColor.bind(
                            this,
                            "page",
                            "backgroundColor"
                          )}
                        />
                        <div className="gap20" />

                        {showSetting && (
                          <>
                            <div className="bConHeader">
                              Default Title Details
                            </div>
                            <div className="alt-row">
                              <div className="alt-col-6">
                                <div className="bConHeader">Font Size</div>
                                <div className="customSpinner">
                                  <a
                                    className="cSpinBtn spinUp"
                                    onClick={onClickUpPageAttr.bind(
                                      this,
                                      "title",
                                      "fontSize"
                                    )}>
                                    <img src={NavigateUpArrow} />
                                  </a>
                                  <input
                                    type="text"
                                    className="cSpinText"
                                    value={getPageAttrValue(
                                      "title",
                                      "fontSize"
                                    )}
                                    name={"title"}
                                    onChange={handlePageValueChange.bind(
                                      this,
                                      "fontSize"
                                    )}
                                    onBlur={handlePageValueBlur.bind(this)}
                                  />
                                  <a
                                    className="cSpinBtn spinDown"
                                    onClick={onClickDownPageAttr.bind(
                                      this,
                                      "title",
                                      "fontSize"
                                    )}>
                                    <img src={NavigateDownArrow} />
                                  </a>
                                </div>
                              </div>
                              <div className="alt-col-6">
                                <div className="bConHeader">Line Height</div>
                                <div className="customSpinner">
                                  <a
                                    className="cSpinBtn spinUp"
                                    onClick={onClickUpPageAttr.bind(
                                      this,
                                      "title",
                                      "lineHeight"
                                    )}>
                                    <img src={NavigateUpArrow} />
                                  </a>
                                  <input
                                    type="text"
                                    className="cSpinText"
                                    value={getPageAttrValue(
                                      "title",
                                      "lineHeight"
                                    )}
                                    name={"title"}
                                    onChange={handlePageValueChange.bind(
                                      this,
                                      "lineHeight"
                                    )}
                                    onBlur={handlePageValueBlur.bind(this)}
                                  />
                                  <a
                                    className="cSpinBtn spinDown"
                                    onClick={onClickDownPageAttr.bind(
                                      this,
                                      "title",
                                      "lineHeight"
                                    )}>
                                    <img src={NavigateDownArrow} />
                                  </a>
                                </div>
                              </div>
                            </div>
                            <ColorPicker
                              key="titleColor"
                              color={getPageAttrValue("title", "color")}
                              onClose={handlePageValueBlur.bind(this)}
                              onChange={onChangeColor.bind(
                                this,
                                "title",
                                "color"
                              )}
                            />
                            <div className="alt-row">
                              <div className="alt-col-12 alt-no-margin">
                                <select
                                  type="text"
                                  className="cSelectText"
                                  name="title"
                                  onBlur={handlePageValueBlur.bind(this)}
                                  value={getPageAttrValue("title", "font")}
                                  onChange={handlePageInputChange.bind(
                                    this,
                                    "font"
                                  )}>
                                  {renderFonts()}
                                </select>
                              </div>
                            </div>

                            <div className="gap20" />

                            <div className="bConHeader">
                              Default Subtitle Details
                            </div>

                            <div className="alt-row">
                              <div className="alt-col-6">
                                <div className="bConHeader">Font Size</div>
                                <div className="customSpinner">
                                  <a
                                    className="cSpinBtn spinUp"
                                    onClick={onClickUpPageAttr.bind(
                                      this,
                                      "subTitle",
                                      "fontSize"
                                    )}>
                                    <img src={NavigateUpArrow} />
                                  </a>
                                  <input
                                    type="text"
                                    className="cSpinText"
                                    value={getPageAttrValue(
                                      "subTitle",
                                      "fontSize"
                                    )}
                                    name={"subTitle"}
                                    onChange={handlePageValueChange.bind(
                                      this,
                                      "fontSize"
                                    )}
                                    onBlur={handlePageValueBlur.bind(this)}
                                  />
                                  <a
                                    className="cSpinBtn spinDown"
                                    onClick={onClickDownPageAttr.bind(
                                      this,
                                      "subTitle",
                                      "fontSize"
                                    )}>
                                    <img src={NavigateDownArrow} />
                                  </a>
                                </div>
                              </div>
                              <div className="alt-col-6">
                                <div className="bConHeader">Line Height</div>
                                <div className="customSpinner">
                                  <a
                                    className="cSpinBtn spinUp"
                                    onClick={onClickUpPageAttr.bind(
                                      this,
                                      "subTitle",
                                      "lineHeight"
                                    )}>
                                    <img src={NavigateUpArrow} />
                                  </a>
                                  <input
                                    type="text"
                                    className="cSpinText"
                                    value={getPageAttrValue(
                                      "subTitle",
                                      "lineHeight"
                                    )}
                                    name={"subTitle"}
                                    onChange={handlePageValueChange.bind(
                                      this,
                                      "lineHeight"
                                    )}
                                    onBlur={handlePageValueBlur.bind(this)}
                                  />
                                  <a
                                    className="cSpinBtn spinDown"
                                    onClick={onClickDownPageAttr.bind(
                                      this,
                                      "subTitle",
                                      "lineHeight"
                                    )}>
                                    <img src={NavigateDownArrow} />
                                  </a>
                                </div>
                              </div>
                            </div>
                            <ColorPicker
                              key="subTitleColor"
                              color={getPageAttrValue("subTitle", "color")}
                              onClose={handlePageValueBlur.bind(this)}
                              onChange={onChangeColor.bind(
                                this,
                                "subTitle",
                                "color"
                              )}
                            />
                            <div className="alt-row">
                              <div className="alt-col-12 alt-no-margin">
                                <select
                                  type="text"
                                  className="cSelectText"
                                  value={getPageAttrValue("subTitle", "font")}
                                  onBlur={handlePageValueBlur.bind(this)}
                                  name="subTitle"
                                  onChange={handlePageInputChange.bind(
                                    this,
                                    "font"
                                  )}>
                                  {renderFonts()}
                                </select>
                              </div>
                            </div>

                            <div className="gap20" />

                            <div className="bConHeader">
                              Default Paragraph Details
                            </div>
                            <div className="alt-row">
                              <div className="alt-col-6">
                                <div className="bConHeader">Font Size</div>
                                <div className="customSpinner">
                                  <a
                                    className="cSpinBtn spinUp"
                                    onClick={onClickUpPageAttr.bind(
                                      this,
                                      "text",
                                      "fontSize"
                                    )}>
                                    <img src={NavigateUpArrow} />
                                  </a>
                                  <input
                                    type="text"
                                    className="cSpinText"
                                    value={getPageAttrValue("text", "fontSize")}
                                    name={"text"}
                                    onChange={handlePageValueChange.bind(
                                      this,
                                      "fontSize"
                                    )}
                                    onBlur={handlePageValueBlur.bind(this)}
                                  />
                                  <a
                                    className="cSpinBtn spinDown"
                                    onClick={onClickDownPageAttr.bind(
                                      this,
                                      "text",
                                      "fontSize"
                                    )}>
                                    <img src={NavigateDownArrow} />
                                  </a>
                                </div>
                              </div>
                              <div className="alt-col-6">
                                <div className="bConHeader">Line Height</div>
                                <div className="customSpinner">
                                  <a
                                    className="cSpinBtn spinUp"
                                    onClick={onClickUpPageAttr.bind(
                                      this,
                                      "text",
                                      "lineHeight"
                                    )}>
                                    <img src={NavigateUpArrow} />
                                  </a>
                                  <input
                                    type="text"
                                    className="cSpinText"
                                    value={getPageAttrValue(
                                      "text",
                                      "lineHeight"
                                    )}
                                    name={"text"}
                                    onChange={handlePageValueChange.bind(
                                      this,
                                      "lineHeight"
                                    )}
                                    onBlur={handlePageValueBlur.bind(this)}
                                  />
                                  <a
                                    className="cSpinBtn spinDown"
                                    onClick={onClickDownPageAttr.bind(
                                      this,
                                      "text",
                                      "lineHeight"
                                    )}>
                                    <img src={NavigateDownArrow} />
                                  </a>
                                </div>
                              </div>
                            </div>
                            <ColorPicker
                              key="textColor"
                              color={getPageAttrValue("text", "color")}
                              onClose={handlePageValueBlur.bind(this)}
                              onChange={onChangeColor.bind(
                                this,
                                "text",
                                "color"
                              )}
                            />
                            <div className="alt-row">
                              <div className="alt-col-12 alt-no-margin">
                                <select
                                  type="text"
                                  className="cSelectText"
                                  value={getPageAttrValue("text", "font")}
                                  onBlur={handlePageValueBlur.bind(this)}
                                  name="text"
                                  onChange={handlePageInputChange.bind(
                                    this,
                                    "font"
                                  )}>
                                  {renderFonts()}
                                </select>
                              </div>
                            </div>

                            <div className="gap20" />

                            <div className="bConHeader">
                              Default Button Details
                            </div>
                            <div className="alt-row">
                              <div className="alt-col-6">
                                <div className="bConHeader">Font Size</div>
                                <div className="customSpinner">
                                  <a
                                    className="cSpinBtn spinUp"
                                    onClick={onClickUpPageAttr.bind(
                                      this,
                                      "button",
                                      "fontSize"
                                    )}>
                                    <img src={NavigateUpArrow} />
                                  </a>
                                  <input
                                    type="text"
                                    className="cSpinText"
                                    value={getPageAttrValue(
                                      "button",
                                      "fontSize"
                                    )}
                                    name={"button"}
                                    onChange={handlePageValueChange.bind(
                                      this,
                                      "fontSize"
                                    )}
                                    onBlur={handlePageValueBlur.bind(this)}
                                  />
                                  <a
                                    className="cSpinBtn spinDown"
                                    onClick={onClickDownPageAttr.bind(
                                      this,
                                      "button",
                                      "fontSize"
                                    )}>
                                    <img src={NavigateDownArrow} />
                                  </a>
                                </div>
                              </div>
                            </div>
                            <ColorPicker
                              key="defaultButtonColor"
                              color={getPageAttrValue("button", "color")}
                              onClose={handlePageValueBlur.bind(this)}
                              onChange={onChangeColor.bind(
                                this,
                                "button",
                                "color"
                              )}
                            />

                            <div className="alt-row">
                              <div className="alt-col-12 alt-no-margin">
                                <select
                                  type="text"
                                  className="cSelectText"
                                  value={getPageAttrValue("button", "font")}
                                  onBlur={handlePageValueBlur.bind(this)}
                                  name="button"
                                  onChange={handlePageInputChange.bind(
                                    this,
                                    "font"
                                  )}>
                                  {renderFonts()}
                                </select>
                              </div>
                            </div>
                            <div className="gap20"></div>
                            <div
                              style={{ cursor: "pointer", color: "#26ace2" }}
                              className="bConHeader"
                              onClick={onClickCustomStyles}>
                              Page Styles
                            </div>
                          </>
                        )}
                      </div>
                    )}
                  </>
                )}
                {selectedItem.type === "checkoutForm" && (
                  <div className="bControlSection controlToolFontSize">
                    <div className="gap10" />
                    <div className="bConHeader">Select Checkout Item</div>
                    <div className="alt-row">
                      <div className="alt-col-12">
                        <select
                          type="text"
                          className="cSelectText"
                          name={getSelectedComponentId()}
                          onBlur={handleValueBlur.bind(this)}
                          value={getAttributeValue("checkoutId")}
                          onChange={handleInputChange.bind(this, "checkoutId")}>
                          <option value=""></option>
                          {checkoutItems.map((i) => (
                            <option value={i.checkout_id}>{i.name}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="gap10" />
                  </div>
                )}

                {(isTemplateSite() || !isLockElem) && (
                  <>
                    {selectedComponent === "section" && (
                      <div className="bControlSection controlToolFontSize">
                        <div className="alt-row">
                          <div className="alt-col-9">
                            <div className="bConHeader">Use Page Width</div>
                          </div>
                          <div className="alt-col-3">
                            <div className="customSpinner">
                              <Toggle
                                key={getSelectedComponentId()}
                                id={getSelectedComponentId()}
                                name={getSelectedComponentId()}
                                defaultChecked={usePageWidth}
                                onChange={handleSectionWidthChange.bind(this)}
                              />
                            </div>
                          </div>
                        </div>
                        {!usePageWidth && (
                          <div className="alt-row">
                            <div className="alt-col-12">
                              <div className="bConHeaderSec">Width</div>
                              <div className="customSpinner">
                                <a
                                  className="cSpinBtn spinUp"
                                  onClick={onClickUpAttribute.bind(
                                    this,
                                    "maxWidth"
                                  )}>
                                  <img src={NavigateUpArrow} />
                                </a>
                                <input
                                  type="text"
                                  className="cSpinText"
                                  value={getAttributeValue("maxWidth")}
                                  name={getSelectedComponentId()}
                                  onChange={handleValueChange.bind(
                                    this,
                                    "maxWidth"
                                  )}
                                  onBlur={handleValueBlur.bind(this)}
                                />
                                <a
                                  className="cSpinBtn spinDown"
                                  onClick={onClickDownAttribute.bind(
                                    this,
                                    "maxWidth"
                                  )}>
                                  <img src={NavigateDownArrow} />
                                </a>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}

                    {selectedComponent === "row" &&
                      showGridView &&
                      renderSelectGrid()}

                    {selectedComponent === "row" && (
                      <>
                        <div className="bControlSection controlToolColumns">
                          <div className="alt-row">
                            <div className="alt-col-9">
                              <div className="bConHeader">
                                Reverse Columns on Mobile
                              </div>
                            </div>
                            <div className="alt-col-3">
                              <div className="customSpinner">
                                <Toggle
                                  key={getSelectedComponentId()}
                                  id={getSelectedComponentId()}
                                  name={getSelectedComponentId()}
                                  defaultChecked={getAttributeValue(
                                    "reverseColumns"
                                  )}
                                  value={getAttributeValue("reverseColumns")}
                                  onChange={handleToggleChange.bind(
                                    this,
                                    "reverseColumns"
                                  )}
                                  onBlur={handleValueBlur.bind(this)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    {selectedComponent === "item" &&
                      selectedItem.type === "countdown" && (
                        <div className="bControlSection controlToolFontSize">
                          <div className="alt-row">
                            <div className="bConHeader">Date Type</div>
                            <select
                              type="text"
                              className="cSelectText"
                              name={getSelectedComponentId()}
                              onBlur={handleValueBlur.bind(this)}
                              value={getAttributeValue("cdDateTypeOption")}
                              onChange={handleInputChange.bind(
                                this,
                                "cdDateTypeOption"
                              )}>
                              <option value="date">Select Date</option>
                              <option value="time">After Specified Time</option>
                            </select>
                            <div className="gap10" />
                            {getAttributeValue("cdDateTypeOption") ===
                              "date" && (
                              <>
                                <div className="alt-row">
                                  <div className="alt-col-3">
                                    <div className="bConHeader bConHeaderV">
                                      Year
                                    </div>
                                  </div>
                                  <div className="alt-col-9">
                                    <select
                                      type="text"
                                      className="cSelectText"
                                      name={getSelectedComponentId()}
                                      onBlur={handleValueBlur.bind(this)}
                                      value={getAttributeValue("cdDateYear")}
                                      onChange={handleInputChange.bind(
                                        this,
                                        "cdDateYear"
                                      )}>
                                      <option value={moment().format("YYYY")}>
                                        {moment().format("YYYY")}
                                      </option>
                                      <option
                                        value={moment()
                                          .add("1", "year")
                                          .format("YYYY")}>
                                        {moment()
                                          .add("1", "year")
                                          .format("YYYY")}
                                      </option>
                                      <option
                                        value={moment()
                                          .add("2", "year")
                                          .format("YYYY")}>
                                        {moment()
                                          .add("2", "year")
                                          .format("YYYY")}
                                      </option>
                                    </select>
                                  </div>
                                </div>
                                <div className="alt-row">
                                  <div className="alt-col-3">
                                    <div className="bConHeader bConHeaderV">
                                      Month
                                    </div>
                                  </div>
                                  <div className="alt-col-9">
                                    <select
                                      type="text"
                                      className="cSelectText"
                                      name={getSelectedComponentId()}
                                      onBlur={handleValueBlur.bind(this)}
                                      value={getAttributeValue("cdDateMonth")}
                                      onChange={handleInputChange.bind(
                                        this,
                                        "cdDateMonth"
                                      )}>
                                      <option value="1">January</option>
                                      <option value="2">February</option>
                                      <option value="3">March</option>
                                      <option value="4">April</option>
                                      <option value="5">May</option>
                                      <option value="6">June</option>
                                      <option value="7">July</option>
                                      <option value="8">August</option>
                                      <option value="9">September</option>
                                      <option value="10">October</option>
                                      <option value="11">November</option>
                                      <option value="12">December</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="alt-row">
                                  <div className="alt-col-3">
                                    <div className="bConHeader bConHeaderV">
                                      Day
                                    </div>
                                  </div>
                                  <div className="alt-col-9">
                                    <select
                                      type="text"
                                      className="cSelectText"
                                      name={getSelectedComponentId()}
                                      onBlur={handleValueBlur.bind(this)}
                                      value={getAttributeValue("cdDateDay")}
                                      onChange={handleInputChange.bind(
                                        this,
                                        "cdDateDay"
                                      )}>
                                      {renderDays()}
                                    </select>
                                  </div>
                                </div>
                                <div className="alt-row">
                                  <div className="alt-col-3">
                                    <div className="bConHeader bConHeaderV">
                                      Hour
                                    </div>
                                  </div>
                                  <div className="alt-col-9">
                                    <select
                                      type="text"
                                      className="cSelectText"
                                      name={getSelectedComponentId()}
                                      onBlur={handleValueBlur.bind(this)}
                                      value={getAttributeValue("cdDateHour")}
                                      onChange={handleInputChange.bind(
                                        this,
                                        "cdDateHour"
                                      )}>
                                      {renderHours()}
                                    </select>
                                  </div>
                                </div>
                                <div className="alt-row">
                                  <div className="alt-col-3">
                                    <div className="bConHeader bConHeaderV">
                                      Minute
                                    </div>
                                  </div>
                                  <div className="alt-col-9">
                                    <select
                                      type="text"
                                      className="cSelectText"
                                      name={getSelectedComponentId()}
                                      onBlur={handleValueBlur.bind(this)}
                                      value={getAttributeValue("cdDateMinute")}
                                      onChange={handleInputChange.bind(
                                        this,
                                        "cdDateMinute"
                                      )}>
                                      {renderMinues()}
                                    </select>
                                  </div>
                                </div>
                              </>
                            )}
                            {getAttributeValue("cdDateTypeOption") ===
                              "time" && (
                              <div className="alt-row">
                                <div className="alt-col-3">
                                  <div className="bConHeader bConHeaderV">
                                    Minute
                                  </div>
                                </div>
                                <div className="alt-col-9">
                                  <input
                                    type="number"
                                    className="cSpinTextLong"
                                    value={getAttributeValue("cdDateMinute")}
                                    name={getSelectedComponentId()}
                                    onChange={handleValueChange.bind(
                                      this,
                                      "cdDateMinute"
                                    )}
                                    onBlur={handleValueBlur.bind(this)}
                                  />
                                </div>
                              </div>
                            )}
                            <div className="gap10" />
                            <div className="bConHeader">Font Family</div>
                            <select
                              type="text"
                              className="cSelectText"
                              value={getAttributeValue("cdFontFamily")}
                              onBlur={handleValueBlur.bind(this)}
                              name={getSelectedComponentId()}
                              onChange={handleInputChange.bind(
                                this,
                                "cdFontFamily"
                              )}>
                              {renderFonts()}
                            </select>
                            <div className="gap10" />
                            <div className="bConHeader">Text Color</div>
                            <ColorPicker
                              key={getSelectedComponentId()}
                              color={getAttributeValue("cdTextColor")}
                              onClose={handleValueBlur.bind(this)}
                              onChange={onChangeAttrColor.bind(
                                this,
                                getSelectedComponentId(),
                                "cdTextColor"
                              )}
                              range={getAttributeValue("cdTextColorRange")}
                              onChangeRange={onChangeAttrColor.bind(
                                this,
                                getSelectedComponentId(),
                                "cdTextColorRange"
                              )}
                            />
                            <div className="gap10" />
                            <div className="bConHeader">Time Font Size</div>
                            <div className="alt-col-12">
                              <div className="customSpinner">
                                <a
                                  className="cSpinBtn spinUp"
                                  onClick={onClickUpAttribute.bind(
                                    this,
                                    "cdTimeFontSize"
                                  )}>
                                  <img src={NavigateUpArrow} />
                                </a>
                                <input
                                  type="text"
                                  className="cSpinText"
                                  value={getAttributeValue("cdTimeFontSize")}
                                  name={getSelectedComponentId()}
                                  onChange={handleValueChange.bind(
                                    this,
                                    "cdTimeFontSize"
                                  )}
                                  onBlur={handleValueBlur.bind(this)}
                                />
                                <a
                                  className="cSpinBtn spinDown"
                                  onClick={onClickDownAttribute.bind(
                                    this,
                                    "cdTimeFontSize"
                                  )}>
                                  <img src={NavigateDownArrow} />
                                </a>
                              </div>
                            </div>

                            <div className="gap10" />
                            <div className="bConHeader">Text Font Size</div>
                            <div className="alt-col-6">
                              <div className="customSpinner">
                                <a
                                  className="cSpinBtn spinUp"
                                  onClick={onClickUpAttribute.bind(
                                    this,
                                    "cdFontSize"
                                  )}>
                                  <img src={NavigateUpArrow} />
                                </a>
                                <input
                                  type="text"
                                  className="cSpinText"
                                  value={getAttributeValue("cdFontSize")}
                                  name={getSelectedComponentId()}
                                  onChange={handleValueChange.bind(
                                    this,
                                    "cdFontSize"
                                  )}
                                  onBlur={handleValueBlur.bind(this)}
                                />
                                <a
                                  className="cSpinBtn spinDown"
                                  onClick={onClickDownAttribute.bind(
                                    this,
                                    "cdFontSize"
                                  )}>
                                  <img src={NavigateDownArrow} />
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="gap10" />
                          <div className="bConHeader">
                            Outer Background Color
                          </div>
                          <ColorPicker
                            key={getSelectedComponentId()}
                            color={getAttributeValue("cdOuterBackgroundColor")}
                            onClose={handleValueBlur.bind(this)}
                            onChange={onChangeAttrColor.bind(
                              this,
                              getSelectedComponentId(),
                              "cdOuterBackgroundColor"
                            )}
                            range={getAttributeValue(
                              "cdOuterBackgroundColorRange"
                            )}
                            onChangeRange={onChangeAttrColor.bind(
                              this,
                              getSelectedComponentId(),
                              "cdOuterBackgroundColorRange"
                            )}
                          />
                          <div className="gap10" />
                          <div className="bConHeader">
                            Inner Background Color
                          </div>
                          <ColorPicker
                            key={getSelectedComponentId()}
                            color={getAttributeValue("cdInnerBackgroundColor")}
                            onClose={handleValueBlur.bind(this)}
                            onChange={onChangeAttrColor.bind(
                              this,
                              getSelectedComponentId(),
                              "cdInnerBackgroundColor"
                            )}
                            range={getAttributeValue(
                              "cdInnerBackgroundColorRange"
                            )}
                            onChangeRange={onChangeAttrColor.bind(
                              this,
                              getSelectedComponentId(),
                              "cdInnerBackgroundColorRange"
                            )}
                          />
                          <div className="gap20" />
                          <div className="alt-row">
                            <div className="alt-col-9">
                              <div className="bConHeader">Show Days</div>
                            </div>
                            <div className="alt-col-3">
                              <div className="customSpinner">
                                <Toggle
                                  id={getSelectedComponentId()}
                                  name={getSelectedComponentId()}
                                  defaultChecked={getAttributeValue(
                                    "cdShowDays"
                                  )}
                                  onChange={handleToggleChange.bind(
                                    this,
                                    "cdShowDays"
                                  )}
                                  onBlur={handleValueBlur.bind(this)}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="alt-row">
                            <div className="alt-col-9">
                              <div className="bConHeader">Show Hours</div>
                            </div>
                            <div className="alt-col-3">
                              <div className="customSpinner">
                                <Toggle
                                  id={getSelectedComponentId()}
                                  name={getSelectedComponentId()}
                                  defaultChecked={getAttributeValue(
                                    "cdShowHours"
                                  )}
                                  onChange={handleToggleChange.bind(
                                    this,
                                    "cdShowHours"
                                  )}
                                  onBlur={handleValueBlur.bind(this)}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="alt-row">
                            <div className="alt-col-9">
                              <div className="bConHeader">Show Minutes</div>
                            </div>
                            <div className="alt-col-3">
                              <div className="customSpinner">
                                <Toggle
                                  id={getSelectedComponentId()}
                                  name={getSelectedComponentId()}
                                  defaultChecked={getAttributeValue(
                                    "cdShowMinutes"
                                  )}
                                  onChange={handleToggleChange.bind(
                                    this,
                                    "cdShowMinutes"
                                  )}
                                  onBlur={handleValueBlur.bind(this)}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="alt-row">
                            <div className="alt-col-9">
                              <div className="bConHeader">Show Seconds</div>
                            </div>
                            <div className="alt-col-3">
                              <div className="customSpinner">
                                <Toggle
                                  id={getSelectedComponentId()}
                                  name={getSelectedComponentId()}
                                  defaultChecked={getAttributeValue(
                                    "cdShowSeconds"
                                  )}
                                  onChange={handleToggleChange.bind(
                                    this,
                                    "cdShowSeconds"
                                  )}
                                  onBlur={handleValueBlur.bind(this)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                    {selectedComponent === "item" &&
                      selectedItem.type === "line" && (
                        <div className="bControlSection controlToolFontSize">
                          <div className="alt-row">
                            <div className="alt-col-12">
                              <div className="bConHeader">Height</div>
                              <div className="customSpinner">
                                <a
                                  className="cSpinBtn spinUp"
                                  onClick={onClickUpAttribute.bind(
                                    this,
                                    "height"
                                  )}>
                                  <img src={NavigateUpArrow} />
                                </a>
                                <input
                                  type="text"
                                  className="cSpinText"
                                  value={getAttributeValue("height")}
                                  name={getSelectedComponentId()}
                                  onChange={handleValueChange.bind(
                                    this,
                                    "height"
                                  )}
                                />
                                <a
                                  className="cSpinBtn spinDown"
                                  onClick={onClickDownAttribute.bind(
                                    this,
                                    "height"
                                  )}>
                                  <img src={NavigateDownArrow} />
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="gap10" />
                          <div className="alt-row">
                            <div className="alt-col-12">
                              <div className="bConHeader">Width</div>
                              <div className="customSlider">
                                <div className="alt-row">
                                  <div className="alt-col-9">
                                    <Slider
                                      key={getSelectedComponentId()}
                                      min={25}
                                      defaultValue={getAttributeValue("width")}
                                      marks={{
                                        25: "25%",
                                        50: "50%",
                                        75: "75%",
                                        100: "100%",
                                      }}
                                      step={1}
                                      onChange={(value) => {
                                        changeAttributeValue(
                                          getSelectedComponentId(),
                                          "width",
                                          value
                                        );
                                      }}
                                      onBlur={handleValueBlur.bind(this)}
                                    />
                                  </div>
                                  <div className="alt-col-3">
                                    <div className="sliderPer">
                                      {getAttributeValue("width")}%
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="alt-row">
                            <div className="alt-col-12">
                              <div className="bConHeader">Style</div>
                              <select
                                type="text"
                                className="cSelectText"
                                name={getSelectedComponentId()}
                                onBlur={handleValueBlur.bind(this)}
                                value={getAttributeValue("lineStyle")}
                                onChange={handleInputChange.bind(
                                  this,
                                  "lineStyle"
                                )}>
                                <option value="solid">Solid</option>
                                <option value="dotted">Dotted</option>
                                <option value="dashed">Dashed</option>
                              </select>
                            </div>
                          </div>
                          <div className="gap20" />
                          <div className="alt-row">
                            <div className="alt-col-8">
                              <div className="bConHeader">Color</div>
                            </div>
                          </div>
                          <ColorPicker
                            key={getSelectedComponentId()}
                            color={getAttributeValue("backgroundColor")}
                            onChange={onChangeAttrColor.bind(
                              this,
                              getSelectedComponentId(),
                              "backgroundColor"
                            )}
                            range={getAttributeValue("backgroundColorRange")}
                            onChangeRange={onChangeAttrColor.bind(
                              this,
                              getSelectedComponentId(),
                              "backgroundColorRange"
                            )}
                          />
                        </div>
                      )}

                    {(selectedItem.type === "icon" ||
                      selectedItem.type === "iconText") && (
                      <div className="bControlSection controlToolFontSize">
                        <div className="alt-row">
                          <div className="bConHeader">Icon Color</div>
                          <ColorPicker
                            key={getSelectedComponentId()}
                            color={getAttributeValue("iconElemColor")}
                            onClose={handleValueBlur.bind(this)}
                            onChange={onChangeAttrColor.bind(
                              this,
                              getSelectedComponentId(),
                              "iconElemColor"
                            )}
                            range={getAttributeValue("iconElemColorRange")}
                            onChangeRange={onChangeAttrColor.bind(
                              this,
                              getSelectedComponentId(),
                              "iconElemColorRange"
                            )}
                          />
                          {selectedItem.type === "iconText" && (
                            <>
                              <div className="gap10" />
                              <div className="bConHeader">Gap</div>
                              <div className="alt-col-12">
                                <div className="customSpinner">
                                  <a
                                    className="cSpinBtn spinUp"
                                    onClick={onClickUpAttribute.bind(
                                      this,
                                      "iconGap"
                                    )}>
                                    <img src={NavigateUpArrow} />
                                  </a>
                                  <input
                                    type="text"
                                    className="cSpinText"
                                    value={getAttributeValue("iconGap")}
                                    name={getSelectedComponentId()}
                                    onChange={handleValueChange.bind(
                                      this,
                                      "iconGap"
                                    )}
                                    onBlur={handleValueBlur.bind(this)}
                                  />
                                  <a
                                    className="cSpinBtn spinDown"
                                    onClick={onClickDownAttribute.bind(
                                      this,
                                      "iconGap"
                                    )}>
                                    <img src={NavigateDownArrow} />
                                  </a>
                                </div>
                              </div>
                            </>
                          )}
                          <div className="gap30" />
                          <div className="bConHeader">Icon Size</div>
                          <div className="alt-col-6">
                            <div className="customSpinner">
                              <a
                                className="cSpinBtn spinUp"
                                onClick={onClickUpAttribute.bind(
                                  this,
                                  "iconElemSize"
                                )}>
                                <img src={NavigateUpArrow} />
                              </a>
                              <input
                                type="text"
                                className="cSpinText"
                                value={getAttributeValue("iconElemSize")}
                                name={getSelectedComponentId()}
                                onChange={handleValueChange.bind(
                                  this,
                                  "iconElemSize"
                                )}
                                onBlur={handleValueBlur.bind(this)}
                              />
                              <a
                                className="cSpinBtn spinDown"
                                onClick={onClickDownAttribute.bind(
                                  this,
                                  "iconElemSize"
                                )}>
                                <img src={NavigateDownArrow} />
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="gap20" />
                        <div className="alt-col-12 alt-no-margin">
                          <div className="addNewRowHold alt-no-margin">
                            <a
                              style={{ background: "#efefef" }}
                              className="noBottomPadding"
                              onClick={onClickSelectIconElem.bind(this)}>
                              <div className="alt-row alt-no-margin">
                                {getAttributeValue("iconElemName") && (
                                  <div className="alt-col-12 alt-no-margin">
                                    <ContentIcon
                                      name={getAttributeValue("iconElemName")}
                                      width={getAttributeValue("iconElemSize")}
                                      color={getAttributeValue("iconElemColor")}
                                    />
                                  </div>
                                )}
                                <div className="alt-col-12 alt-no-margin">
                                  Select Icon
                                </div>
                              </div>
                              <br />
                            </a>
                          </div>
                        </div>
                        <div className="gap20" />
                        <div className="bConHeader">Icon Position</div>
                        <div className="alt-row">
                          <div className="alt-col-12">
                            <select
                              type="text"
                              className="cSelectText"
                              name={getSelectedComponentId()}
                              onBlur={handleValueBlur.bind(this)}
                              value={getAttributeValue("iconPosition")}
                              onChange={handleInputChange.bind(
                                this,
                                "iconPosition"
                              )}>
                              <option value="center">Center</option>
                              <option value="top">Top</option>
                            </select>
                          </div>
                        </div>
                        <div className="gap20" />
                        <div className="bConHeader">Background Color</div>
                        <ColorPicker
                          key={getSelectedComponentId()}
                          color={getAttributeValue("backgroundColor")}
                          onClose={handleValueBlur.bind(this)}
                          onChange={onChangeAttrColor.bind(
                            this,
                            getSelectedComponentId(),
                            "backgroundColor"
                          )}
                          range={getAttributeValue("backgroundColorRange")}
                          onChangeRange={onChangeAttrColor.bind(
                            this,
                            getSelectedComponentId(),
                            "backgroundColorRange"
                          )}
                        />
                      </div>
                    )}

                    {showAttrView &&
                      selectedItem.type === "code" &&
                      selectedComponent === "item" && (
                        <div className="bControlSection controlToolFontSize">
                          <div className="addNewRowHold">
                            <a onClick={onClickEditHtml}>Edit Html</a>
                          </div>
                        </div>
                      )}

                    {showAttrView &&
                      (selectedItem.type === "productName" ||
                        selectedItem.type === "productDescription" ||
                        selectedItem.type === "productPrice" ||
                        selectedItem.type === "productImage" ||
                        selectedItem.type === "productCart") &&
                      selectedComponent === "item" && (
                        <div className="bControlSection controlToolFontSize">
                          <div
                            style={{
                              cursor: "pointer",
                              color: "#26ace2",
                            }}
                            className="bConHeader"
                            onClick={() => setProductListModalIsOpen(true)}>
                            Select Product
                          </div>
                          <div className="gap10" />
                          <div>{renderSelectedProduct()}</div>
                        </div>
                      )}

                    {selectedItem.type === "socialMediaLinks" && (
                      <>
                        <div className="bControlSection controlToolFontSize">
                          {[
                            "Facebook",
                            "Linkedin",
                            "Youtube",
                            "Instagram",
                            "Pintrest",
                            "Twitter",
                          ].map((i) => (
                            <>
                              <div className="bConHeader">{i}</div>
                              <div className="alt-row">
                                <div className="alt-col-12">
                                  <div className="customSpinner">
                                    <input
                                      type="text"
                                      className="cInputText"
                                      value={getAttributeValue(
                                        `social${i}Link`
                                      )}
                                      name={getSelectedComponentId()}
                                      onChange={handleInputChange.bind(
                                        this,
                                        `social${i}Link`
                                      )}
                                      onBlur={handleValueBlur.bind(this)}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="gap10"></div>
                            </>
                          ))}
                        </div>
                      </>
                    )}

                    {showAttrView &&
                      selectedItem.type === "imageSlider" &&
                      selectedComponent === "item" && (
                        <div className="bControlSection controlToolFontSize">
                          <div className="bConHeader">Select Image</div>
                          <div className="alt-row">
                            <div className="alt-col-10">
                              <div className="customSpinner">
                                <input
                                  key={getSelectedComponentId()}
                                  type="text"
                                  className="cInputText"
                                  name={selectedItem.attributes.id}
                                  value={
                                    urlAttr[selectedItem.attributes.id] ||
                                    selectedItem.src
                                  }
                                  onChange={handleUrlAttrChange.bind(
                                    this,
                                    "sliderImageUrl"
                                  )}
                                />
                              </div>
                            </div>
                            <div className="alt-col-2">
                              <img
                                src={SelectImageSVG}
                                onClick={onClickSelectImage.bind(
                                  this,
                                  "sliderImageUrl"
                                )}
                                className="selectImage"
                              />
                            </div>
                          </div>
                          <div className="gap10" />
                          {renderSliderImages()}
                          <div className="gap10" />
                          <div className="alt-row">
                            <div className="alt-col-9">
                              <div className="bConHeader">
                                Enable Full Screen Images
                              </div>
                            </div>
                            <div className="alt-col-3">
                              <div className="customSpinner">
                                <Toggle
                                  key={selectedItem.attributes.id}
                                  id={getSelectedComponentId()}
                                  name={getSelectedComponentId()}
                                  defaultChecked={getAttributeValue(
                                    "sliderFullImage"
                                  )}
                                  value={getAttributeValue("sliderFullImage")}
                                  onChange={handleToggleChange.bind(
                                    this,
                                    "sliderFullImage"
                                  )}
                                  onBlur={handleValueBlur.bind(this)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                    {showAttrView &&
                      (selectedItem.type === "image" ||
                        selectedItem.type === "imageText") &&
                      selectedComponent === "item" && (
                        <div className="bControlSection controlToolFontSize">
                          <div className="bConHeader">Image URL</div>
                          <div className="alt-row">
                            <div className="alt-col-10">
                              <div className="customSpinner">
                                <input
                                  key={getSelectedComponentId()}
                                  type="text"
                                  className="cInputText"
                                  name={selectedItem.attributes.id}
                                  value={
                                    urlAttr[selectedItem.attributes.id] ||
                                    selectedItem.src
                                  }
                                  onChange={handleUrlAttrChange.bind(
                                    this,
                                    "imageUrl"
                                  )}
                                />
                              </div>
                            </div>
                            <div className="alt-col-2">
                              <img
                                src={SelectImageSVG}
                                onClick={onClickSelectImage.bind(
                                  this,
                                  "imageUrl"
                                )}
                                className="selectImage"
                              />
                            </div>
                          </div>
                          <div className="gap10" />
                          <div className="bConHeader">Link URL</div>
                          <div className="alt-row">
                            <div className="alt-col-12">
                              <div className="customSpinner">
                                <input
                                  key={getSelectedComponentId()}
                                  type="text"
                                  className="cInputText"
                                  name={selectedItem.attributes.id}
                                  value={getAttributeValue("linkUrl")}
                                  onChange={handleInputChange.bind(
                                    this,
                                    "linkUrl"
                                  )}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="alt-row">
                            <div className="alt-col-9">
                              <div className="bConHeader">
                                Open in New Window
                              </div>
                            </div>
                            <div className="alt-col-3">
                              <div className="customSpinner">
                                <Toggle
                                  key={selectedItem.attributes.id}
                                  id={getSelectedComponentId()}
                                  name={getSelectedComponentId()}
                                  defaultChecked={getAttributeValue(
                                    "imageLinkNewWindow"
                                  )}
                                  value={getAttributeValue(
                                    "imageLinkNewWindow"
                                  )}
                                  onChange={handleToggleChange.bind(
                                    this,
                                    "imageLinkNewWindow"
                                  )}
                                  onBlur={handleValueBlur.bind(this)}
                                />
                              </div>
                            </div>
                          </div>
                          {selectedItem.type === "imageText" && (
                            <>
                              <div className="gap10" />
                              <div className="bConHeader">Image Width</div>
                              <input
                                key={getSelectedComponentId()}
                                type="text"
                                className="cInputText"
                                name={selectedItem.attributes.id}
                                value={getAttributeValue("maxWidth")}
                                onChange={handleInputChange.bind(
                                  this,
                                  "maxWidth"
                                )}
                              />
                              <div className="gap20" />
                              <div className="bConHeader">Text Position</div>

                              <select
                                type="text"
                                className="cSelectText"
                                name={getSelectedComponentId()}
                                onBlur={handleValueBlur.bind(this)}
                                value={getAttributeValue("textPosition")}
                                onChange={handleInputChange.bind(
                                  this,
                                  "textPosition"
                                )}>
                                <option value="middle">Middle</option>
                                <option value="top">Top</option>
                                <option value="bottom">Bottom</option>
                              </select>

                              <div className="gap20" />
                              <div className="bConHeader">Gap</div>
                              <div className="alt-col-12">
                                <div className="customSpinner">
                                  <a
                                    className="cSpinBtn spinUp"
                                    onClick={onClickUpAttribute.bind(
                                      this,
                                      "imageGap"
                                    )}>
                                    <img src={NavigateUpArrow} />
                                  </a>
                                  <input
                                    type="text"
                                    className="cSpinText"
                                    value={getAttributeValue("imageGap")}
                                    name={getSelectedComponentId()}
                                    onChange={handleValueChange.bind(
                                      this,
                                      "imageGap"
                                    )}
                                    onBlur={handleValueBlur.bind(this)}
                                  />
                                  <a
                                    className="cSpinBtn spinDown"
                                    onClick={onClickDownAttribute.bind(
                                      this,
                                      "imageGap"
                                    )}>
                                    <img src={NavigateDownArrow} />
                                  </a>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      )}

                    {showAttrView &&
                      selectedItem.type === "video" &&
                      selectedComponent === "item" && (
                        <div className="bControlSection controlToolFontSize">
                          <div className="bConHeader">Video URL</div>
                          <div className="alt-row">
                            <div className="alt-col-10">
                              <div className="customSpinner">
                                <input
                                  key={getSelectedComponentId()}
                                  type="text"
                                  className="cInputText"
                                  name={selectedItem.attributes.id}
                                  value={urlAttr[selectedItem.attributes.id]}
                                  onChange={handleUrlAttrChange.bind(
                                    this,
                                    "videoUrl"
                                  )}
                                  onBlur={handleVideoUrlValueBlur.bind(
                                    this,
                                    selectedItem.attributes.id
                                  )}
                                />
                              </div>
                            </div>
                            <div className="alt-col-2">
                              <img
                                src={SelectImageSVG}
                                onClick={onClickSelectImage.bind(
                                  this,
                                  "videoUrl"
                                )}
                                className="selectImage"
                              />
                            </div>
                          </div>
                          <div className="gap20" />
                          <div className="bConHeader">Thumbnail URL</div>
                          <div className="alt-row">
                            <div className="alt-col-10">
                              <div className="customSpinner">
                                <input
                                  key={getSelectedComponentId()}
                                  type="text"
                                  className="cInputText"
                                  name={selectedItem.attributes.id + "-thumb"}
                                  value={
                                    urlAttr[
                                      selectedItem.attributes.id + "-thumb"
                                    ] || selectedItem.srcThumb
                                  }
                                  onChange={handleUrlAttrChange.bind(
                                    this,
                                    "thumbUrl"
                                  )}
                                />
                              </div>
                            </div>
                            <div className="alt-col-2">
                              <img
                                src={SelectImageSVG}
                                onClick={onClickSelectImage.bind(
                                  this,
                                  "thumbUrl"
                                )}
                                className="selectImage"
                              />
                            </div>
                          </div>
                          <div className="gap10" />
                          <div className="bConHeader">Aspect Ratio</div>
                          <div className="gap10" />
                          <select
                            type="text"
                            style={{ marginLeft: 0 }}
                            className="cSelectText"
                            name={getSelectedComponentId()}
                            onBlur={handleValueBlur.bind(this)}
                            value={getAttributeValue("videoType")}
                            onChange={handleInputChange.bind(
                              this,
                              "videoType"
                            )}>
                            <option value="16-9">16:9(landscape)</option>
                            <option value="9-16">9:16(portrait)</option>
                            <option value="4-3">4:3</option>
                            <option value="fluid">Full</option>
                          </select>

                          {/* <div className="gap20" />
                        <div className="bConHeader">Video Width</div>
                        <div className="alt-row">
                          <div className="alt-col-12">
                            <input
                              key={getSelectedComponentId()}
                              type="text"
                              className="cInputText"
                              name={selectedItem.attributes.id}
                              value={getAttributeValue("maxWidth")}
                              onChange={handleInputChange.bind(
                                this,
                                "maxWidth"
                              )}
                            />
                          </div>
                        </div> */}
                        </div>
                      )}

                    {showAttrView &&
                      selectedItem.type === "audio" &&
                      selectedComponent === "item" && (
                        <div className="bControlSection controlToolFontSize">
                          <div className="bConHeader">Audio URL</div>
                          <div className="alt-row">
                            <div className="alt-col-10">
                              <div className="customSpinner">
                                <input
                                  key={getSelectedComponentId()}
                                  type="text"
                                  className="cInputText"
                                  name={selectedItem.attributes.id}
                                  value={urlAttr[selectedItem.attributes.id]}
                                  onChange={handleUrlAttrChange.bind(
                                    this,
                                    "audioUrl"
                                  )}
                                />
                              </div>
                            </div>
                            <div className="alt-col-2">
                              <img
                                src={SelectImageSVG}
                                onClick={onClickSelectImage.bind(
                                  this,
                                  "audioUrl"
                                )}
                                className="selectImage"
                              />
                            </div>
                          </div>
                          <div className="gap10" />
                        </div>
                      )}

                    {showAttrView &&
                      selectedComponent === "item" &&
                      !isTextType && (
                        <div
                          className="bControlSection controlToolFontSize"
                          key={getSelectedComponentId()}>
                          {selectedItem.type === "input" && (
                            <>
                              <div className="bConHeader">Type</div>
                              <div className="alt-row">
                                <div className="alt-col-12">
                                  <select
                                    type="text"
                                    className="cSelectText"
                                    name={getSelectedComponentId()}
                                    onBlur={handleValueBlur.bind(this)}
                                    value={getAttributeValue("inputType")}
                                    onChange={handleInputTypeChange.bind(
                                      this,
                                      "inputType"
                                    )}>
                                    {renderInputTypes()}
                                  </select>
                                </div>
                              </div>
                            </>
                          )}
                          {(selectedItem.type === "input" ||
                            selectedItem.type === "checkbox" ||
                            selectedItem.type === "fileInput" ||
                            selectedItem.type === "selectbox") && (
                            <>
                              <div className="gap20" />
                              <div className="bConHeader">Name</div>
                              <div className="alt-row">
                                <div className="alt-col-12">
                                  <div className="customSpinner">
                                    <input
                                      type="text"
                                      className="cInputText"
                                      value={getAttributeValue("inputName")}
                                      name={getSelectedComponentId()}
                                      onChange={handleInputChange.bind(
                                        this,
                                        "inputName"
                                      )}
                                      onBlur={handleValueBlur.bind(this)}
                                    />
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                          {selectedItem.type === "selectbox" && (
                            <>
                              <div className="gap20" />
                              <div className="bConHeader">Options</div>
                              <div className="alt-row">
                                <div className="alt-col-12">
                                  <div className="customSpinner">
                                    <textarea
                                      type="text"
                                      className="cInputText"
                                      value={getAttributeValue("inputOptions")}
                                      name={getSelectedComponentId()}
                                      onChange={handleInputChange.bind(
                                        this,
                                        "inputOptions"
                                      )}
                                      onBlur={handleValueBlur.bind(this)}
                                    />
                                  </div>
                                </div>
                              </div>
                              {renderOptionSelection()}
                              <div className="gap10" />
                              {selectedSelectOption && (
                                <>
                                  <div
                                    style={{
                                      cursor: "pointer",
                                      color: "#26ace2",
                                    }}
                                    className="bConHeader"
                                    onClick={onClickAddOptionTags}>
                                    View/Add Tags
                                  </div>
                                  <div className="gap10" />
                                  <div>{renderOptionTags()}</div>
                                </>
                              )}
                            </>
                          )}
                          {selectedItem.type === "selectbox" && (
                            <>
                              <div className="gap20" />
                              <div className="alt-row">
                                <div className="alt-col-8">
                                  <div className="bConHeader">
                                    Enable Placeholder
                                  </div>
                                </div>
                                <div className="alt-col-4">
                                  <div className="text-right">
                                    <Toggle
                                      id={getSelectedComponentId()}
                                      name={getSelectedComponentId()}
                                      defaultChecked={getAttributeValue(
                                        "inputSelectPlaceholder"
                                      )}
                                      onChange={handleToggleChange.bind(
                                        this,
                                        "inputSelectPlaceholder"
                                      )}
                                      onBlur={handleValueBlur.bind(this)}
                                    />
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                          {(selectedItem.type === "input" ||
                            (selectedItem.type === "selectbox" &&
                              isPlaceholder)) &&
                            getAttributeValue("inputType") !== "hidden" && (
                              <>
                                <div className="gap20" />
                                <div className="bConHeader">Placeholder</div>
                                <div className="alt-row">
                                  <div className="alt-col-12">
                                    <div className="customSpinner">
                                      <input
                                        type="text"
                                        className="cInputText"
                                        value={getAttributeValue(
                                          "inputPlaceholder"
                                        )}
                                        name={getSelectedComponentId()}
                                        onChange={handleInputChange.bind(
                                          this,
                                          "inputPlaceholder"
                                        )}
                                        onBlur={handleValueBlur.bind(this)}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          {selectedItem.type === "input" &&
                            getAttributeValue("inputType") === "hidden" && (
                              <>
                                <div className="gap20" />
                                <div className="bConHeader">Value</div>
                                <div className="alt-row">
                                  <div className="alt-col-12">
                                    <div className="customSpinner">
                                      <input
                                        type="text"
                                        className="cInputText"
                                        value={getAttributeValue("inputValue")}
                                        name={getSelectedComponentId()}
                                        onChange={handleInputChange.bind(
                                          this,
                                          "inputValue"
                                        )}
                                        onBlur={handleValueBlur.bind(this)}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          {/* {(selectedItem.type === "input" ||
                        selectedItem.type === "selectbox") && (
                        <>
                          <div className="gap20" />
                          <div className="bConHeader">Width</div>
                          <div className="customSlider">
                            <div className="alt-row">
                              <div className="alt-col-9">
                                <Slider
                                  key={getSelectedComponentId()}
                                  min={25}
                                  defaultValue={getAttributeValue("inputWidth")}
                                  marks={{
                                    25: "25%",
                                    50: "50%",
                                    75: "75%",
                                    100: "100%",
                                  }}
                                  step={1}
                                  onChange={(value) => {
                                    changeAttributeValue(
                                      getSelectedComponentId(),
                                      "inputWidth",
                                      value
                                    );
                                    handleValueBlur();
                                  }}
                                  onBlur={handleValueBlur.bind(this)}
                                />
                              </div>
                              <div className="alt-col-3">
                                <div className="sliderPer">
                                  {getAttributeValue("inputWidth")}%
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )} */}
                          {selectedItem.type === "input" && (
                            <>
                              <div className="gap20" />
                              <div className="alt-row">
                                <div className="alt-col-8">
                                  <div className="bConHeader">
                                    Multiple Lines
                                  </div>
                                </div>
                                <div className="alt-col-4">
                                  <div className="text-right">
                                    <Toggle
                                      key={getSelectedComponentId()}
                                      id={getSelectedComponentId()}
                                      name={getSelectedComponentId()}
                                      defaultChecked={getAttributeValue(
                                        "inputMultipleLines"
                                      )}
                                      onChange={handleToggleChange.bind(
                                        this,
                                        "inputMultipleLines"
                                      )}
                                      onBlur={handleValueBlur.bind(this)}
                                    />
                                  </div>
                                </div>
                              </div>
                              {getAttributeValue("inputMultipleLines") && (
                                <div style={{ paddingLeft: 5 }}>
                                  <div className="bConHeader">Input Height</div>
                                  <div className="alt-row">
                                    <div className="alt-col-12">
                                      <input
                                        key={getSelectedComponentId()}
                                        type="number"
                                        className="cInputText"
                                        name={selectedItem.attributes.id}
                                        value={getAttributeValue("inputHeight")}
                                        onChange={handleInputChange.bind(
                                          this,
                                          "inputHeight"
                                        )}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                          {selectedItem.type === "fileInput" && (
                            <>
                              <div className="gap10" />
                              <div className="alt-row">
                                <div className="alt-col-8">
                                  <div className="bConHeader">Disabled</div>
                                </div>
                                <div className="alt-col-4">
                                  <div className="text-right">
                                    <Toggle
                                      id={getSelectedComponentId()}
                                      name={getSelectedComponentId()}
                                      defaultChecked={getAttributeValue(
                                        "inputDisabled"
                                      )}
                                      onChange={handleToggleChange.bind(
                                        this,
                                        "inputDisabled"
                                      )}
                                      onBlur={handleValueBlur.bind(this)}
                                    />
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                          {(selectedItem.type === "input" ||
                            selectedItem.type === "checkbox" ||
                            selectedItem.type === "selectbox") && (
                            <>
                              <div className="gap10" />
                              <div className="alt-row">
                                <div className="alt-col-8">
                                  <div className="bConHeader">Is Required?</div>
                                </div>
                                <div className="alt-col-4">
                                  <div className="text-right">
                                    <Toggle
                                      id={getSelectedComponentId()}
                                      name={getSelectedComponentId()}
                                      defaultChecked={getAttributeValue(
                                        "inputRequired"
                                      )}
                                      onChange={handleToggleChange.bind(
                                        this,
                                        "inputRequired"
                                      )}
                                      onBlur={handleValueBlur.bind(this)}
                                    />
                                  </div>
                                </div>
                              </div>
                              {getAttributeValue("inputRequired") === true && (
                                <>
                                  <div className="gap20" />
                                  <div className="bConHeader">
                                    Error Message
                                  </div>
                                  <div className="alt-row">
                                    <div className="alt-col-12">
                                      <div className="customSpinner">
                                        <input
                                          type="text"
                                          className="cInputText"
                                          value={getAttributeValue(
                                            "inputErrorMessage"
                                          )}
                                          name={getSelectedComponentId()}
                                          onChange={handleInputChange.bind(
                                            this,
                                            "inputErrorMessage"
                                          )}
                                          onBlur={handleValueBlur.bind(this)}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                              <div className="gap10" />
                              {selectedItem.type === "input" && (
                                <>
                                  <div className="alt-row">
                                    <div className="alt-col-8">
                                      <div className="bConHeader">
                                        Limit Characters
                                      </div>
                                    </div>
                                    <div className="alt-col-4">
                                      <div className="text-right">
                                        <Toggle
                                          id={getSelectedComponentId()}
                                          name={getSelectedComponentId()}
                                          defaultChecked={getAttributeValue(
                                            "inputLimit"
                                          )}
                                          onChange={handleToggleChange.bind(
                                            this,
                                            "inputLimit"
                                          )}
                                          onBlur={handleValueBlur.bind(this)}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  {getAttributeValue("inputLimit") === true && (
                                    <div className="alt-row">
                                      <div className="alt-col-6">
                                        <div className="bConHeaderSec">Max</div>
                                        <div className="customSpinner">
                                          <a
                                            className="cSpinBtn spinUp"
                                            onClick={onClickUpAttribute.bind(
                                              this,
                                              "inputLimitMax"
                                            )}>
                                            <img src={NavigateUpArrow} />
                                          </a>
                                          <input
                                            type="text"
                                            className="cSpinText"
                                            value={getAttributeValue(
                                              "inputLimitMax"
                                            )}
                                            name={getSelectedComponentId()}
                                            onChange={handleValueChange.bind(
                                              this,
                                              "inputLimitMax"
                                            )}
                                            onBlur={handleValueBlur.bind(this)}
                                          />
                                          <a
                                            className="cSpinBtn spinDown"
                                            onClick={onClickDownAttribute.bind(
                                              this,
                                              "inputLimitMax"
                                            )}>
                                            <img src={NavigateDownArrow} />
                                          </a>
                                        </div>
                                      </div>

                                      <div className="alt-col-6">
                                        <div className="bConHeaderSec">Min</div>
                                        <div className="customSpinner">
                                          <a
                                            className="cSpinBtn spinUp"
                                            onClick={onClickUpAttribute.bind(
                                              this,
                                              "inputLimitMin"
                                            )}>
                                            <img src={NavigateUpArrow} />
                                          </a>
                                          <input
                                            type="text"
                                            className="cSpinText"
                                            value={getAttributeValue(
                                              "inputLimitMin"
                                            )}
                                            name={getSelectedComponentId()}
                                            onChange={handleValueChange.bind(
                                              this,
                                              "inputLimitMin"
                                            )}
                                            onBlur={handleValueBlur.bind(this)}
                                          />
                                          <a
                                            className="cSpinBtn spinDown"
                                            onClick={onClickDownAttribute.bind(
                                              this,
                                              "inputLimitMin"
                                            )}>
                                            <img src={NavigateDownArrow} />
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </>
                              )}
                            </>
                          )}
                          {(selectedItem.type === "input" ||
                            selectedItem.type === "checkbox" ||
                            selectedItem.type === "selectbox") && (
                            <>
                              <div className="gap10" />
                              <div className="alt-row">
                                <div className="alt-col-9">
                                  <div className="bConHeader">
                                    Save Value to Tag
                                  </div>
                                </div>
                                <div className="alt-col-3">
                                  <div className="customSpinner">
                                    <Toggle
                                      key={selectedItem.attributes.id}
                                      id={getSelectedComponentId()}
                                      name={getSelectedComponentId()}
                                      defaultChecked={getAttributeValue(
                                        "inputSaveResponse"
                                      )}
                                      value={getAttributeValue(
                                        "inputSaveResponse"
                                      )}
                                      onChange={handleToggleChange.bind(
                                        this,
                                        "inputSaveResponse"
                                      )}
                                      onBlur={handleValueBlur.bind(this)}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="gap10" />
                              {getAttributeValue("inputSaveResponse") && (
                                <>
                                  <div className="gap20" />
                                  <div className="bConHeader">
                                    What would you like to save?
                                  </div>
                                  <div className="alt-row">
                                    <div className="alt-col-12">
                                      <select
                                        type="text"
                                        className="cSelectText"
                                        name={getSelectedComponentId()}
                                        onBlur={handleValueBlur.bind(this)}
                                        value={getAttributeValue(
                                          "inputSaveResponseType"
                                        )}
                                        onChange={handleInputChange.bind(
                                          this,
                                          "inputSaveResponseType"
                                        )}>
                                        <option value="value">Value</option>
                                        <option value="current-date">
                                          Current Date
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="gap20" />
                                  <div className="bConHeader">
                                    Where would you like to save this
                                    information?
                                  </div>
                                  <div className="gap10" />
                                  <div
                                    style={{
                                      cursor: "pointer",
                                      color: "#26ace2",
                                    }}
                                    className="bConHeader"
                                    onClick={onClickAddInputTags}>
                                    Select Tag
                                  </div>
                                  <div className="gap10" />
                                  <div>{renderInputTags()}</div>
                                  <div className="gap20" />
                                  <div className="bConHeader">
                                    Tag Attribute
                                  </div>
                                  <div className="alt-row">
                                    <div className="alt-col-12">
                                      <div className="customSpinner">
                                        <input
                                          type="text"
                                          className="cInputText"
                                          value={getAttributeValue(
                                            "inputSaveResponseTagAttribute"
                                          )}
                                          name={getSelectedComponentId()}
                                          onChange={handleInputChange.bind(
                                            this,
                                            "inputSaveResponseTagAttribute"
                                          )}
                                          onBlur={handleValueBlur.bind(this)}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}

                              {selectedItem.type === "checkbox" && (
                                <>
                                  <div className="gap20" />
                                  <div className="bConHeader">Position</div>
                                  <div className="alt-row">
                                    <div className="alt-col-12">
                                      <select
                                        type="text"
                                        className="cSelectText"
                                        name={getSelectedComponentId()}
                                        onBlur={handleValueBlur.bind(this)}
                                        value={getAttributeValue(
                                          "checkboxPosition"
                                        )}
                                        onChange={handleInputChange.bind(
                                          this,
                                          "checkboxPosition"
                                        )}>
                                        <option value="center">Center</option>
                                        <option value="top">Top</option>
                                      </select>
                                    </div>
                                  </div>
                                </>
                              )}
                            </>
                          )}
                          {selectedItem.type === "input" && (
                            <>
                              <div className="bConHeader">Font Size</div>
                              <div className="alt-row">
                                <div className="alt-col-8">
                                  <div className="customSpinner">
                                    <a
                                      className="cSpinBtn spinUp"
                                      onClick={onClickUpAttribute.bind(
                                        this,
                                        "inputFontSize"
                                      )}>
                                      <img src={NavigateUpArrow} />
                                    </a>
                                    <input
                                      type="text"
                                      className="cSpinText"
                                      value={getAttributeValue("inputFontSize")}
                                      name={getSelectedComponentId()}
                                      onChange={handleValueChange.bind(
                                        this,
                                        "inputFontSize"
                                      )}
                                      onBlur={handleValueBlur.bind(this)}
                                    />
                                    <a
                                      className="cSpinBtn spinDown"
                                      onClick={onClickDownAttribute.bind(
                                        this,
                                        "inputFontSize"
                                      )}>
                                      <img src={NavigateDownArrow} />
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div className="gap20" />
                              <div className="bConHeader">Color</div>
                              <ColorPicker
                                key={getSelectedComponentId()}
                                color={getAttributeValue("inputColor")}
                                onClose={handleValueBlur.bind(this)}
                                onChange={onChangeAttrColor.bind(
                                  this,
                                  getSelectedComponentId(),
                                  "inputColor"
                                )}
                                range={getAttributeValue("inputColorRange")}
                                onChangeRange={onChangeAttrColor.bind(
                                  this,
                                  getSelectedComponentId(),
                                  "inputColorRange"
                                )}
                              />
                              <div className="gap20" />
                              <div className="bConHeader">Background Color</div>
                              <ColorPicker
                                key={getSelectedComponentId()}
                                color={getAttributeValue(
                                  "inputBackgroundColor"
                                )}
                                onClose={handleValueBlur.bind(this)}
                                onChange={onChangeAttrColor.bind(
                                  this,
                                  getSelectedComponentId(),
                                  "inputBackgroundColor"
                                )}
                                range={getAttributeValue(
                                  "inputBackgroundColorRange"
                                )}
                                onChangeRange={onChangeAttrColor.bind(
                                  this,
                                  getSelectedComponentId(),
                                  "inputBackgroundColorRange"
                                )}
                              />
                            </>
                          )}
                        </div>
                      )}

                    {showAttrView && isButton && (
                      <div className="bControlSection controlToolFontSize">
                        {(editorType === "web" || editorType === "popup") && (
                          <>
                            <div className="alt-row">
                              <div className="alt-col-9">
                                <div className="bConHeader">
                                  Expand Full Width
                                </div>
                              </div>
                              <div className="alt-col-3">
                                <div className="customSpinner">
                                  <Toggle
                                    id={selectedItem.attributes.id}
                                    name={selectedItem.attributes.id}
                                    defaultChecked={isButtonFullWidth}
                                    onChange={handleButtonFullWidthChange.bind(
                                      this
                                    )}
                                    onBlur={onClickButtonFullWidthToggle.bind(
                                      this
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="gap10" />
                            <div className="alt-row">
                              <div className="alt-col-9">
                                <div className="bConHeader">
                                  Add Second Line
                                </div>
                              </div>
                              <div className="alt-col-3">
                                <div className="customSpinner">
                                  <Toggle
                                    id={getSelectedComponentId()}
                                    name={getSelectedComponentId()}
                                    defaultChecked={getAttributeValue(
                                      "buttonSecondLine"
                                    )}
                                    onChange={handleToggleChange.bind(
                                      this,
                                      "buttonSecondLine"
                                    )}
                                    onBlur={handleValueBlur.bind(this)}
                                  />
                                </div>
                              </div>
                            </div>
                            {getAttributeValue("buttonSecondLine") && (
                              <div className="alt-row">
                                <div className="alt-col-6">
                                  <div className="bConHeader">Font Size</div>
                                  <div className="customSpinner">
                                    <a
                                      className="cSpinBtn spinUp"
                                      onClick={onClickUpAttribute.bind(
                                        this,
                                        "buttonSecondLineFontSize"
                                      )}>
                                      <img src={NavigateUpArrow} />
                                    </a>
                                    <input
                                      type="text"
                                      className="cSpinText"
                                      value={getAttributeValue(
                                        "buttonSecondLineFontSize"
                                      )}
                                      name={getSelectedComponentId()}
                                      onChange={handleValueChange.bind(
                                        this,
                                        "buttonSecondLineFontSize"
                                      )}
                                      onBlur={handleValueBlur.bind(this)}
                                    />
                                    <a
                                      className="cSpinBtn spinDown"
                                      onClick={onClickDownAttribute.bind(
                                        this,
                                        "buttonSecondLineFontSize"
                                      )}>
                                      <img src={NavigateDownArrow} />
                                    </a>
                                  </div>
                                </div>
                                <div className="alt-col-6">
                                  <div className="bConHeader">Line Height</div>
                                  <div className="customSpinner">
                                    <a
                                      className="cSpinBtn spinUp"
                                      onClick={onClickUpAttribute.bind(
                                        this,
                                        "buttonSecondLineLineHeight"
                                      )}>
                                      <img src={NavigateUpArrow} />
                                    </a>
                                    <input
                                      type="text"
                                      className="cSpinText"
                                      value={getAttributeValue(
                                        "buttonSecondLineLineHeight"
                                      )}
                                      name={getSelectedComponentId()}
                                      onChange={handleValueChange.bind(
                                        this,
                                        "buttonSecondLineLineHeight"
                                      )}
                                    />
                                    <a
                                      className="cSpinBtn spinDown"
                                      onClick={onClickDownAttribute.bind(
                                        this,
                                        "buttonSecondLineLineHeight"
                                      )}>
                                      <img src={NavigateDownArrow} />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            )}
                          </>
                        )}
                        {(editorType === "web" || editorType === "popup") && (
                          <>
                            <div className="gap20" />
                            <div className="bConHeader">Action Type</div>
                            <div className="alt-row">
                              <div className="alt-col-12">
                                <select
                                  type="text"
                                  className="cSelectText"
                                  name={getSelectedComponentId()}
                                  onBlur={handleValueBlur.bind(this)}
                                  value={getAttributeValue("buttonActionType")}
                                  onChange={handleInputChange.bind(
                                    this,
                                    "buttonActionType"
                                  )}>
                                  <option value="url">URL</option>
                                  <option value="form">Form Submission</option>
                                  <option value="checkout">
                                    Go to Checkout
                                  </option>
                                  <option value="popup">Popup</option>
                                  <option value="next-landing">
                                    Next Landing Page
                                  </option>
                                  {/* <option value="next-page">Next Page</option>
                                <option value="previous-page">
                                  Previous Page
                                </option> */}
                                </select>
                              </div>
                            </div>
                          </>
                        )}
                        {(getAttributeValue("buttonActionType") ===
                          "next-page" ||
                          getAttributeValue("buttonActionType") ===
                            "previous-page") && (
                          <>
                            <div className="gap10" />
                            <div className="alt-row">
                              <div className="alt-col-9">
                                <div className="bConHeader">
                                  Mark as completed
                                </div>
                              </div>
                              <div className="alt-col-3">
                                <div className="customSpinner">
                                  <Toggle
                                    id={getSelectedComponentId()}
                                    name={getSelectedComponentId()}
                                    defaultChecked={getAttributeValue(
                                      "buttonMarkCompleted"
                                    )}
                                    value={getAttributeValue(
                                      "buttonMarkCompleted"
                                    )}
                                    onChange={handleToggleChange.bind(
                                      this,
                                      "buttonMarkCompleted"
                                    )}
                                    onBlur={handleValueBlur.bind(this)}
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        )}

                        {getAttributeValue("buttonActionType") === "url" && (
                          <>
                            <div className="gap20" />
                            <div className="bConHeader">URL</div>
                            <div className="customSpinner">
                              <input
                                key={getSelectedComponentId()}
                                type="text"
                                className="cInputText"
                                name={selectedItem.attributes.id}
                                value={
                                  urlAttr[selectedItem.attributes.id] ||
                                  "{{NextPage}}"
                                }
                                onChange={handleUrlAttrChange.bind(
                                  this,
                                  "buttonUrl"
                                )}
                              />
                            </div>
                            <div className="gap10" />
                            <div className="alt-row">
                              <div className="alt-col-9">
                                <div className="bConHeader">
                                  Open in New Window
                                </div>
                              </div>
                              <div className="alt-col-3">
                                <div className="customSpinner">
                                  <Toggle
                                    key={selectedItem.attributes.id}
                                    id={getSelectedComponentId()}
                                    name={getSelectedComponentId()}
                                    defaultChecked={getAttributeValue(
                                      "buttonNewWindow"
                                    )}
                                    value={getAttributeValue("buttonNewWindow")}
                                    onChange={handleToggleChange.bind(
                                      this,
                                      "buttonNewWindow"
                                    )}
                                    onBlur={handleValueBlur.bind(this)}
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                        {getAttributeValue("buttonActionType") === "form" && (
                          <>
                            <div className="gap20" />
                            <div className="bConHeader">Form Name</div>
                            <div className="alt-row">
                              <div className="alt-col-12">
                                <select
                                  type="text"
                                  className="cSelectText"
                                  name={getSelectedComponentId()}
                                  onBlur={handleValueBlur.bind(this)}
                                  value={getAttributeValue("buttonFormName")}
                                  onChange={handleInputChange.bind(
                                    this,
                                    "buttonFormName"
                                  )}>
                                  {renderFormNames()}
                                </select>
                              </div>
                            </div>
                            <div className="gap10" />
                            <div className="bConHeader">Thank You URL</div>
                            <div className="alt-row">
                              <div className="alt-col-12">
                                <input
                                  type="text"
                                  className="cInputText"
                                  name={getSelectedComponentId()}
                                  value={getAttributeValue("buttonThankYouUrl")}
                                  onChange={handleInputChange.bind(
                                    this,
                                    "buttonThankYouUrl"
                                  )}
                                />
                              </div>
                            </div>
                            <div className="gap10" />
                            <div className="alt-row">
                              <div className="alt-col-9">
                                <div className="bConHeader">
                                  Save Form Submission
                                </div>
                              </div>
                              <div className="alt-col-3">
                                <div className="customSpinner">
                                  <Toggle
                                    id={getSelectedComponentId()}
                                    name={getSelectedComponentId()}
                                    defaultChecked={getAttributeValue(
                                      "buttonSaveSubmission"
                                    )}
                                    value={getAttributeValue(
                                      "buttonSaveSubmission"
                                    )}
                                    onChange={handleToggleChange.bind(
                                      this,
                                      "buttonSaveSubmission"
                                    )}
                                    onBlur={handleValueBlur.bind(this)}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="gap10" />
                            {getAttributeValue("buttonSaveSubmission") ===
                              true && (
                              <div className="alt-row">
                                <div className="alt-col-9">
                                  <div className="bConHeader">
                                    Create Customer
                                  </div>
                                </div>
                                <div className="alt-col-3">
                                  <div className="customSpinner">
                                    <Toggle
                                      id={getSelectedComponentId()}
                                      name={getSelectedComponentId()}
                                      defaultChecked={getAttributeValue(
                                        "buttonCreateCustomer"
                                      )}
                                      value={getAttributeValue(
                                        "buttonCreateCustomer"
                                      )}
                                      onChange={handleToggleChange.bind(
                                        this,
                                        "buttonCreateCustomer"
                                      )}
                                      onBlur={handleValueBlur.bind(this)}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                            <div className="alt-row">
                              <div className="alt-col-9">
                                <div className="bConHeader">Save As Lead</div>
                              </div>
                              <div className="alt-col-3">
                                <div className="customSpinner">
                                  <Toggle
                                    id={getSelectedComponentId()}
                                    name={getSelectedComponentId()}
                                    defaultChecked={getAttributeValue(
                                      "buttonSaveLead"
                                    )}
                                    value={getAttributeValue("buttonSaveLead")}
                                    onChange={handleToggleChange.bind(
                                      this,
                                      "buttonSaveLead"
                                    )}
                                    onBlur={handleValueBlur.bind(this)}
                                  />
                                </div>
                              </div>
                            </div>
                            {getAttributeValue("buttonSaveLead") === true && (
                              <>
                                <div className="gap10" />
                                <div className="bConHeader">
                                  Select Staff User
                                </div>
                                <div className="alt-row">
                                  <div className="alt-col-12">
                                    <select
                                      type="text"
                                      className="cSelectText"
                                      name={getSelectedComponentId()}
                                      onBlur={handleValueBlur.bind(this)}
                                      value={getAttributeValue(
                                        "buttonLeadStaffUser"
                                      )}
                                      onChange={handleInputChange.bind(
                                        this,
                                        "buttonLeadStaffUser"
                                      )}>
                                      <option value=""></option>
                                      {staffUsers.map((i) => (
                                        <option value={i._id}>{i.name}</option>
                                      ))}
                                    </select>
                                  </div>
                                </div>
                                <div className="gap10" />
                              </>
                            )}
                            <div className="gap10" />
                            <div
                              style={{ cursor: "pointer", color: "#26ace2" }}
                              className="bConHeader"
                              onClick={onClickAddTags}>
                              View/Add Tags
                            </div>
                            <div className="gap10" />
                            <div>{renderTags()}</div>
                          </>
                        )}
                        {getAttributeValue("buttonActionType") === "popup" && (
                          <>
                            <div className="gap20" />
                            <div className="bConHeader">Select Popup</div>
                            <div className="alt-row">
                              <div className="alt-col-12">
                                <select
                                  type="text"
                                  className="cSelectText"
                                  name={getSelectedComponentId()}
                                  onBlur={handleValueBlur.bind(this)}
                                  value={getAttributeValue("buttonPopupId")}
                                  onChange={handleInputChange.bind(
                                    this,
                                    "buttonPopupId"
                                  )}>
                                  <option value=""></option>
                                  {renderPopups()}
                                </select>
                              </div>
                            </div>
                            <div
                              onClick={onClickGoCreatePopup}
                              style={{ cursor: "pointer", color: "#26ace2" }}
                              className="bConHeader link pointer-click">
                              Create Popup
                            </div>
                            <div
                              onClick={onClickRefreshPopups}
                              style={{ cursor: "pointer", color: "#26ace2" }}
                              className="bConHeader link pointer-click">
                              Refresh Popups
                            </div>
                            <div
                              onClick={onClickViewPopups}
                              style={{ cursor: "pointer", color: "#26ace2" }}
                              className="bConHeader link pointer-click">
                              View Popups
                            </div>
                            {/* <div
                            onClick={onClickCreatePopup}
                            style={{ cursor: "pointer", color: "#26ace2" }}
                            className="bConHeader link pointer-click">
                            Create Popup
                          </div>
                          {getAttributeValue("buttonPopupId") && (
                            <div
                              onClick={onClickEditPopup}
                              style={{ cursor: "pointer", color: "#26ace2" }}
                              className="bConHeader link pointer-click">
                              Edit Popup
                            </div>
                          )}
                          {getAttributeValue("buttonPopupId") && (
                            <div
                              onClick={onClickDeletePopup}
                              style={{ cursor: "pointer", color: "red" }}
                              className="bConHeader link pointer-click">
                              Delete Popup
                            </div>
                          )} */}
                          </>
                        )}

                        {getAttributeValue("buttonActionType") ===
                          "checkout" && (
                          <>
                            <div className="gap10" />
                            <div className="bConHeader">
                              Select Checkout Item
                            </div>
                            <div className="alt-row">
                              <div className="alt-col-12">
                                <select
                                  type="text"
                                  className="cSelectText"
                                  name={getSelectedComponentId()}
                                  onBlur={handleValueBlur.bind(this)}
                                  value={getAttributeValue("buttonCheckoutId")}
                                  onChange={handleInputChange.bind(
                                    this,
                                    "buttonCheckoutId"
                                  )}>
                                  <option value=""></option>
                                  {checkoutItems.map((i) => (
                                    <option value={i.checkout_id}>
                                      {i.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    )}

                    {selectedComponent === "column" && (
                      <div className="bControlSection controlToolFontSize">
                        <div className="alt-row">
                          {/* <div className="bConHeader">Alignment</div>
                        <div className="fontEditRow fontEditRowIconsAlignment">
                          <div className="alt-col-2 alt-no-margin">
                            <a
                              onClick={onClickTextAlign.bind(this, "left")}
                              data-text-effect="textLeft"
                              className={`${
                                getAttributeValue("textAlign") === "left"
                                  ? "activeAlignment"
                                  : ""
                              } btntextLeft`}>
                              <img src={LeftAlignmentSVG} />
                            </a>
                          </div>
                        </div> */}
                          <div
                            style={{ cursor: "pointer", color: "#26ace2" }}
                            className="bConHeader"
                            onClick={onClickAddColumnStep}>
                            Add Column Step
                          </div>
                        </div>
                      </div>
                    )}

                    {showAttrView &&
                      (selectedItem.type === "productName" ||
                        selectedItem.type === "productDescription" ||
                        selectedItem.type === "productPrice" ||
                        selectedItem.type === "productCart" ||
                        selectedItem.type === "iconText") && (
                        <>
                          <div className="bControlSection controlToolFontSize">
                            <div className="alt-row">
                              <div className="alt-col-6">
                                <div className="bConHeader">Font Size</div>
                                <div className="customSpinner">
                                  <a
                                    className="cSpinBtn spinUp"
                                    onClick={onClickUpAttribute.bind(
                                      this,
                                      "fontSize"
                                    )}>
                                    <img src={NavigateUpArrow} />
                                  </a>
                                  <input
                                    type="text"
                                    className="cSpinText"
                                    value={getAttributeValue("fontSize")}
                                    name={getSelectedComponentId()}
                                    onChange={handleValueChange.bind(
                                      this,
                                      "fontSize"
                                    )}
                                    onBlur={handleValueBlur.bind(this)}
                                  />
                                  <a
                                    className="cSpinBtn spinDown"
                                    onClick={onClickDownAttribute.bind(
                                      this,
                                      "fontSize"
                                    )}>
                                    <img src={NavigateDownArrow} />
                                  </a>
                                </div>
                              </div>
                              <div className="alt-col-6">
                                <div className="bConHeader">Line Height</div>
                                <div className="customSpinner">
                                  <a
                                    className="cSpinBtn spinUp"
                                    onClick={onClickUpAttribute.bind(
                                      this,
                                      "lineHeight"
                                    )}>
                                    <img src={NavigateUpArrow} />
                                  </a>
                                  <input
                                    type="text"
                                    className="cSpinText"
                                    value={getAttributeValue("lineHeight")}
                                    name={getSelectedComponentId()}
                                    onChange={handleValueChange.bind(
                                      this,
                                      "lineHeight"
                                    )}
                                  />
                                  <a
                                    className="cSpinBtn spinDown"
                                    onClick={onClickDownAttribute.bind(
                                      this,
                                      "lineHeight"
                                    )}>
                                    <img src={NavigateDownArrow} />
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="gap10" />
                            <div className="alt-row">
                              <div className="alt-col-8">
                                <div className="bConHeader">Font Color</div>
                              </div>
                            </div>
                            <ColorPicker
                              key={getSelectedComponentId()}
                              color={getAttributeValue("color")}
                              onClose={handleValueBlur.bind(this)}
                              onChange={onChangeAttrColor.bind(
                                this,
                                getSelectedComponentId(),
                                "color"
                              )}
                              range={getAttributeValue("colorRange")}
                              onChangeRange={onChangeAttrColor.bind(
                                this,
                                getSelectedComponentId(),
                                "colorRange"
                              )}
                            />{" "}
                            <div className="gap20" />
                            <div className="alt-row">
                              <div className="alt-col-12 alt-no-margin">
                                <div className="bConHeader">Font Family</div>
                                <select
                                  type="text"
                                  className="cSelectText"
                                  value={getAttributeValue("fontFamily")}
                                  onBlur={handleValueBlur.bind(this)}
                                  name={getSelectedComponentId()}
                                  onChange={handleInputChange.bind(
                                    this,
                                    "fontFamily"
                                  )}>
                                  {renderFonts()}
                                </select>
                              </div>
                            </div>
                            <div className="gap10" />
                            <div className="alt-row">
                              <div className="alt-col-12 alt-no-margin">
                                <div className="bConHeader">Font Weight</div>
                                <select
                                  type="text"
                                  className="cSelectText"
                                  value={getAttributeValue("fontWeight")}
                                  onBlur={handleValueBlur.bind(this)}
                                  name={getSelectedComponentId()}
                                  onChange={handleInputChange.bind(
                                    this,
                                    "fontWeight"
                                  )}>
                                  <option value="200">Extra Thin</option>
                                  <option value="300">Thin</option>
                                  <option value="400">Normal</option>
                                  <option value="700">Bold</option>
                                  <option value="900">Extra Bold</option>
                                </select>
                              </div>
                            </div>
                            <div className="gap10" />
                            <div className="alt-row">
                              <div className="alt-col-6">
                                <div className="bConHeader">Letter Spacing</div>
                                <div className="customSpinner">
                                  <a
                                    className="cSpinBtn spinUp"
                                    onClick={onClickUpAttribute.bind(
                                      this,
                                      "letterSpacing"
                                    )}>
                                    <img src={NavigateUpArrow} />
                                  </a>
                                  <input
                                    type="text"
                                    className="cSpinText"
                                    value={getAttributeValue("letterSpacing")}
                                    name={getSelectedComponentId()}
                                    onChange={handleValueChange.bind(
                                      this,
                                      "letterSpacing"
                                    )}
                                    onBlur={handleValueBlur.bind(this)}
                                  />
                                  <a
                                    className="cSpinBtn spinDown"
                                    onClick={onClickDownAttribute.bind(
                                      this,
                                      "letterSpacing"
                                    )}>
                                    <img src={NavigateDownArrow} />
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}

                    {showAttrView && (isTextType || isButton) && (
                      <>
                        <div className="bControlSection controlToolFontSize">
                          <div className="alt-row">
                            <div className="alt-col-9">
                              <div className="bConHeader">
                                Use Default Style
                              </div>
                            </div>
                            <div className="alt-col-3">
                              <div className="customSpinner">
                                <Toggle
                                  id={selectedItem.attributes.id}
                                  name={selectedItem.attributes.id}
                                  checked={isDefaultStyle}
                                  defaultChecked={isDefaultStyle}
                                  onChange={handleDefaultStyleChange.bind(this)}
                                />
                              </div>
                            </div>
                          </div>
                          {!isDefaultStyle && (
                            <>
                              <div className="alt-row">
                                <div className="alt-col-6">
                                  <div className="bConHeader">Font Size</div>
                                  <div className="customSpinner">
                                    <a
                                      className="cSpinBtn spinUp"
                                      onClick={onClickUpAttribute.bind(
                                        this,
                                        "customFontSize"
                                      )}>
                                      <img src={NavigateUpArrow} />
                                    </a>
                                    <input
                                      type="text"
                                      className="cSpinText"
                                      value={getAttributeValue(
                                        "customFontSize"
                                      )}
                                      name={getSelectedComponentId()}
                                      onChange={handleValueChange.bind(
                                        this,
                                        "customFontSize"
                                      )}
                                      onBlur={handleValueBlur.bind(this)}
                                    />
                                    <a
                                      className="cSpinBtn spinDown"
                                      onClick={onClickDownAttribute.bind(
                                        this,
                                        "customFontSize"
                                      )}>
                                      <img src={NavigateDownArrow} />
                                    </a>
                                  </div>
                                </div>
                                <div className="alt-col-6">
                                  <div className="bConHeader">Line Height</div>
                                  <div className="customSpinner">
                                    <a
                                      className="cSpinBtn spinUp"
                                      onClick={onClickUpAttribute.bind(
                                        this,
                                        "lineHeight"
                                      )}>
                                      <img src={NavigateUpArrow} />
                                    </a>
                                    <input
                                      type="text"
                                      className="cSpinText"
                                      value={getAttributeValue("lineHeight")}
                                      name={getSelectedComponentId()}
                                      onChange={handleValueChange.bind(
                                        this,
                                        "lineHeight"
                                      )}
                                    />
                                    <a
                                      className="cSpinBtn spinDown"
                                      onClick={onClickDownAttribute.bind(
                                        this,
                                        "lineHeight"
                                      )}>
                                      <img src={NavigateDownArrow} />
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div className="gap10" />
                              <div className="alt-row">
                                <div className="alt-col-8">
                                  <div className="bConHeader">Font Color</div>
                                </div>
                              </div>
                              <ColorPicker
                                key={getSelectedComponentId()}
                                color={getAttributeValue("customFontColor")}
                                onClose={handleValueBlur.bind(this)}
                                onChange={onChangeAttrColor.bind(
                                  this,
                                  getSelectedComponentId(),
                                  "customFontColor"
                                )}
                                range={getAttributeValue(
                                  "customFontColorRange"
                                )}
                                onChangeRange={onChangeAttrColor.bind(
                                  this,
                                  getSelectedComponentId(),
                                  "customFontColorRange"
                                )}
                              />{" "}
                              <div className="gap20" />
                              <div className="alt-row">
                                <div className="alt-col-12 alt-no-margin">
                                  <div className="bConHeader">Font Family</div>
                                  <select
                                    type="text"
                                    className="cSelectText"
                                    value={getAttributeValue(
                                      "customFontFamily"
                                    )}
                                    onBlur={handleValueBlur.bind(this)}
                                    name={getSelectedComponentId()}
                                    onChange={handleInputChange.bind(
                                      this,
                                      "customFontFamily"
                                    )}>
                                    {renderFonts()}
                                  </select>
                                </div>
                              </div>
                              <div className="gap10" />
                              <div className="alt-row">
                                <div className="alt-col-12 alt-no-margin">
                                  <div className="bConHeader">Font Weight</div>
                                  <select
                                    type="text"
                                    className="cSelectText"
                                    value={getAttributeValue("fontWeight")}
                                    onBlur={handleValueBlur.bind(this)}
                                    name={getSelectedComponentId()}
                                    onChange={handleInputChange.bind(
                                      this,
                                      "fontWeight"
                                    )}>
                                    <option value="200">Extra Thin</option>
                                    <option value="300">Thin</option>
                                    <option value="400">Normal</option>
                                    <option value="700">Bold</option>
                                    <option value="900">Extra Bold</option>
                                  </select>
                                </div>
                              </div>
                              <div className="gap10" />
                              <div className="alt-row">
                                <div className="alt-col-6">
                                  <div className="bConHeader">
                                    Letter Spacing
                                  </div>
                                  <div className="customSpinner">
                                    <a
                                      className="cSpinBtn spinUp"
                                      onClick={onClickUpAttribute.bind(
                                        this,
                                        "letterSpacing"
                                      )}>
                                      <img src={NavigateUpArrow} />
                                    </a>
                                    <input
                                      type="text"
                                      className="cSpinText"
                                      value={getAttributeValue("letterSpacing")}
                                      name={getSelectedComponentId()}
                                      onChange={handleValueChange.bind(
                                        this,
                                        "letterSpacing"
                                      )}
                                      onBlur={handleValueBlur.bind(this)}
                                    />
                                    <a
                                      className="cSpinBtn spinDown"
                                      onClick={onClickDownAttribute.bind(
                                        this,
                                        "letterSpacing"
                                      )}>
                                      <img src={NavigateDownArrow} />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </>
                    )}

                    {(editorType === "web" || editorType === "popup") &&
                      showAttrView &&
                      isButton && (
                        <>
                          <div className="bControlSection controlToolPadding">
                            <div className="bConHeader">Icon Details</div>
                            <div className="alt-row">
                              <div className="alt-col-9">
                                <div className="bConHeader">
                                  Enable Button Icon
                                </div>
                              </div>
                              <div className="alt-col-3">
                                <div className="customSpinner">
                                  <Toggle
                                    id={selectedItem.attributes.id}
                                    name={selectedItem.attributes.id}
                                    defaultChecked={isButtonIcon}
                                    onChange={handleButtonIconChange.bind(this)}
                                    onBlur={onBlurButtonIconToggle.bind(this)}
                                  />
                                </div>
                              </div>
                            </div>

                            {isButtonIcon && (
                              <>
                                <div className="alt-row">
                                  <div className="alt-row">
                                    <div className="alt-col-8">
                                      <div className="bConHeaderColor">
                                        Icon Color
                                      </div>
                                    </div>
                                  </div>
                                  <ColorPicker
                                    key={getSelectedComponentId()}
                                    color={getAttributeValue("buttonIconColor")}
                                    onClose={handleValueBlur.bind(this)}
                                    onChange={onChangeAttrColor.bind(
                                      this,
                                      getSelectedComponentId(),
                                      "buttonIconColor"
                                    )}
                                    range={getAttributeValue(
                                      "buttonIconColorRange"
                                    )}
                                    onChangeRange={onChangeAttrColor.bind(
                                      this,
                                      getSelectedComponentId(),
                                      "buttonIconColorRange"
                                    )}
                                  />
                                  <div className="gap10" />

                                  <div className="alt-row">
                                    <div className="alt-col-6">
                                      <div className="bConHeaderSec">
                                        Padding Right
                                      </div>
                                      <div className="customSpinner">
                                        <a
                                          className="cSpinBtn spinUp"
                                          onClick={onClickUpAttribute.bind(
                                            this,
                                            "buttonIconPaddingRight"
                                          )}>
                                          <img src={NavigateUpArrow} />
                                        </a>
                                        <input
                                          type="text"
                                          className="cSpinText"
                                          value={getAttributeValue(
                                            "buttonIconPaddingRight"
                                          )}
                                          name={getSelectedComponentId()}
                                          onChange={handleValueChange.bind(
                                            this,
                                            "buttonIconPaddingRight"
                                          )}
                                          onBlur={handleValueBlur.bind(this)}
                                        />
                                        <a
                                          className="cSpinBtn spinDown"
                                          onClick={onClickDownAttribute.bind(
                                            this,
                                            "buttonIconPaddingRight"
                                          )}>
                                          <img src={NavigateDownArrow} />
                                        </a>
                                      </div>
                                    </div>

                                    <div className="alt-col-6">
                                      <div className="bConHeaderSec">
                                        Padding Left
                                      </div>
                                      <div className="customSpinner">
                                        <a
                                          className="cSpinBtn spinUp"
                                          onClick={onClickUpAttribute.bind(
                                            this,
                                            "buttonIconPaddingLeft"
                                          )}>
                                          <img src={NavigateUpArrow} />
                                        </a>
                                        <input
                                          type="text"
                                          className="cSpinText"
                                          value={getAttributeValue(
                                            "buttonIconPaddingLeft"
                                          )}
                                          name={getSelectedComponentId()}
                                          onChange={handleValueChange.bind(
                                            this,
                                            "buttonIconPaddingLeft"
                                          )}
                                          onBlur={handleValueBlur.bind(this)}
                                        />
                                        <a
                                          className="cSpinBtn spinDown"
                                          onClick={onClickDownAttribute.bind(
                                            this,
                                            "buttonIconPaddingLeft"
                                          )}>
                                          <img src={NavigateDownArrow} />
                                        </a>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="alt-col-6">
                                    <div className="bConHeaderSec">Size</div>
                                    <div className="customSpinner">
                                      <a
                                        className="cSpinBtn spinUp"
                                        onClick={onClickUpAttribute.bind(
                                          this,
                                          "buttonIconSize"
                                        )}>
                                        <img src={NavigateUpArrow} />
                                      </a>
                                      <input
                                        type="text"
                                        className="cSpinText"
                                        value={getAttributeValue(
                                          "buttonIconSize"
                                        )}
                                        name={getSelectedComponentId()}
                                        onChange={handleValueChange.bind(
                                          this,
                                          "buttonIconSize"
                                        )}
                                        onBlur={handleValueBlur.bind(this)}
                                      />
                                      <a
                                        className="cSpinBtn spinDown"
                                        onClick={onClickDownAttribute.bind(
                                          this,
                                          "buttonIconSize"
                                        )}>
                                        <img src={NavigateDownArrow} />
                                      </a>
                                    </div>
                                  </div>

                                  <div className="alt-col-4">
                                    <div className="bConHeaderSec">
                                      Alignment
                                    </div>
                                    <div className="fontEditRow fontEditRowIconsAlignment">
                                      <div className="alt-row alt-no-margin">
                                        <div className="alt-col-6 alt-no-margin">
                                          <a
                                            onClick={onClickAlignButtonIcon.bind(
                                              this,
                                              "left"
                                            )}
                                            data-text-effect="textLeft"
                                            className={`${
                                              getAttributeValue(
                                                "buttonIconAlignment"
                                              ) === "left"
                                                ? "activeAlignment"
                                                : ""
                                            } btntextLeft`}>
                                            <img src={LeftAlignmentSVG} />
                                          </a>
                                        </div>
                                        <div className="alt-col-4 alt-no-margin">
                                          <a
                                            onClick={onClickAlignButtonIcon.bind(
                                              this,
                                              "right"
                                            )}
                                            data-text-effect="textRight"
                                            className={`${
                                              getAttributeValue(
                                                "buttonIconAlignment"
                                              ) === "right"
                                                ? "activeAlignment"
                                                : ""
                                            } btntextRight`}>
                                            <img src={RightAlignmentSVG} />
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="alt-col-12 alt-no-margin">
                                  <div className="addNewRowHold alt-no-margin">
                                    <a
                                      className="noBottomPadding"
                                      onClick={onClickSelectIcon.bind(this)}>
                                      <div className="alt-row alt-no-margin">
                                        {getAttributeValue(
                                          "buttonIconName"
                                        ) && (
                                          <div className="alt-col-12 alt-no-margin">
                                            <ContentIcon
                                              name={getAttributeValue(
                                                "buttonIconName"
                                              )}
                                              width={getAttributeValue(
                                                "buttonIconSize"
                                              )}
                                              color={getAttributeValue(
                                                "buttonIconColor"
                                              )}
                                            />
                                          </div>
                                        )}

                                        <div className="alt-col-12 alt-no-margin">
                                          Select Icon
                                        </div>
                                      </div>
                                      <br />
                                    </a>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </>
                      )}

                    {(selectedComponent === "item" ||
                      selectedComponent === "column") &&
                      selectedItem.type !== "checkoutForm" &&
                      selectedItem.type !== "fileInput" && (
                        <div className="bControlSection">
                          <div className="alt-row">
                            <div className="alt-col-9">
                              <div className="bConHeader">Enable Max Width</div>
                            </div>
                            <div className="alt-col-3">
                              <div className="customSpinner">
                                <Toggle
                                  key={getSelectedComponentId()}
                                  id={getSelectedComponentId()}
                                  name={getSelectedComponentId()}
                                  defaultChecked={getAttributeValue(
                                    "isMaxWidth"
                                  )}
                                  value={getAttributeValue("isMaxWidth")}
                                  onChange={handleToggleChange.bind(
                                    this,
                                    "isMaxWidth"
                                  )}
                                  onBlur={handleValueBlur.bind(this)}
                                />
                              </div>
                            </div>
                          </div>
                          {getAttributeValue("isMaxWidth") === true && (
                            <div className="alt-row">
                              <div className="alt-col-12">
                                <div className="bConHeaderSec">Max Width</div>
                                <div className="customSpinner">
                                  <a
                                    className="cSpinBtn spinUp"
                                    onClick={onClickUpAttribute.bind(
                                      this,
                                      "maxWidth"
                                    )}>
                                    <img src={NavigateUpArrow} />
                                  </a>
                                  <input
                                    type="text"
                                    className="cSpinText"
                                    value={getAttributeValue("maxWidth")}
                                    name={getSelectedComponentId()}
                                    onChange={handleValueChange.bind(
                                      this,
                                      "maxWidth"
                                    )}
                                    onBlur={handleValueBlur.bind(this)}
                                  />
                                  <a
                                    className="cSpinBtn spinDown"
                                    onClick={onClickDownAttribute.bind(
                                      this,
                                      "maxWidth"
                                    )}>
                                    <img src={NavigateDownArrow} />
                                  </a>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      )}

                    {showAttrView && selectedComponent !== "custom-section" && (
                      <div className="bControlSection controlToolPadding">
                        <div className="bConHeader">Spacing & Alignment</div>
                        <div className="alt-row">
                          <div className="alt-col-6">
                            <div className="bConHeaderSec">Top</div>
                            <div className="customSpinner">
                              <a
                                className="cSpinBtn spinUp"
                                onClick={onClickUpAttribute.bind(
                                  this,
                                  "marginTop"
                                )}>
                                <img src={NavigateUpArrow} />
                              </a>
                              <input
                                type="text"
                                className="cSpinText"
                                value={getAttributeValue("marginTop")}
                                name={getSelectedComponentId()}
                                onChange={handleValueChange.bind(
                                  this,
                                  "marginTop"
                                )}
                                onBlur={handleValueBlur.bind(this)}
                              />
                              <a
                                className="cSpinBtn spinDown"
                                onClick={onClickDownAttribute.bind(
                                  this,
                                  "marginTop"
                                )}>
                                <img src={NavigateDownArrow} />
                              </a>
                            </div>
                          </div>

                          <div className="alt-col-6">
                            <div className="bConHeaderSec">Bottom</div>
                            <div className="customSpinner">
                              <a
                                className="cSpinBtn spinUp"
                                onClick={onClickUpAttribute.bind(
                                  this,
                                  "marginBottom"
                                )}>
                                <img src={NavigateUpArrow} />
                              </a>
                              <input
                                type="text"
                                className="cSpinText"
                                value={getAttributeValue("marginBottom")}
                                name={getSelectedComponentId()}
                                onChange={handleValueChange.bind(
                                  this,
                                  "marginBottom"
                                )}
                                onBlur={handleValueBlur.bind(this)}
                              />
                              <a
                                className="cSpinBtn spinDown"
                                onClick={onClickDownAttribute.bind(
                                  this,
                                  "marginBottom"
                                )}>
                                <img src={NavigateDownArrow} />
                              </a>
                            </div>
                          </div>
                        </div>

                        {(selectedComponent === "column" ||
                          selectedComponent === "section" ||
                          selectedItem.type === "button") && (
                          <div className="alt-row">
                            <div className="alt-col-6">
                              <div className="bConHeaderSec">Left</div>
                              <div className="customSpinner">
                                <a
                                  className="cSpinBtn spinUp"
                                  onClick={onClickUpAttribute.bind(
                                    this,
                                    "marginLeft"
                                  )}>
                                  <img src={NavigateUpArrow} />
                                </a>
                                <input
                                  type="text"
                                  className="cSpinText"
                                  value={getAttributeValue("marginLeft")}
                                  name={getSelectedComponentId()}
                                  onChange={handleValueChange.bind(
                                    this,
                                    "marginLeft"
                                  )}
                                  onBlur={handleValueBlur.bind(this)}
                                />
                                <a
                                  className="cSpinBtn spinDown"
                                  onClick={onClickDownAttribute.bind(
                                    this,
                                    "marginLeft"
                                  )}>
                                  <img src={NavigateDownArrow} />
                                </a>
                              </div>
                            </div>
                            <div className="alt-col-6">
                              <div className="bConHeaderSec">Right</div>
                              <div className="customSpinner">
                                <a
                                  className="cSpinBtn spinUp"
                                  onClick={onClickUpAttribute.bind(
                                    this,
                                    "marginRight"
                                  )}>
                                  <img src={NavigateUpArrow} />
                                </a>
                                <input
                                  type="text"
                                  className="cSpinText"
                                  value={getAttributeValue("marginRight")}
                                  name={getSelectedComponentId()}
                                  onChange={handleValueChange.bind(
                                    this,
                                    "marginRight"
                                  )}
                                  onBlur={handleValueBlur.bind(this)}
                                />
                                <a
                                  className="cSpinBtn spinDown"
                                  onClick={onClickDownAttribute.bind(
                                    this,
                                    "marginRight"
                                  )}>
                                  <img src={NavigateDownArrow} />
                                </a>
                              </div>
                            </div>
                          </div>
                        )}
                        {selectedComponent === "row" && (
                          <div className="alt-row">
                            <div className="alt-col-6">
                              <div className="bConHeaderSec">
                                Column Spacing
                              </div>
                              <div className="customSpinner">
                                <a
                                  className="cSpinBtn spinUp"
                                  onClick={onClickUpAttribute.bind(
                                    this,
                                    "borderSpacing"
                                  )}>
                                  <img src={NavigateUpArrow} />
                                </a>
                                <input
                                  type="text"
                                  className="cSpinText"
                                  value={getAttributeValue("borderSpacing")}
                                  name={getSelectedComponentId()}
                                  onChange={handleValueChange.bind(
                                    this,
                                    "borderSpacing"
                                  )}
                                  onBlur={handleValueBlur.bind(this)}
                                />
                                <a
                                  className="cSpinBtn spinDown"
                                  onClick={onClickDownAttribute.bind(
                                    this,
                                    "borderSpacing"
                                  )}>
                                  <img src={NavigateDownArrow} />
                                </a>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}

                    {showAttrView &&
                      selectedItem.type !== "navBar" &&
                      (isTextType ||
                        selectedItem.type === "image" ||
                        isButton ||
                        selectedItem.type !== undefined ||
                        selectedComponent === "section") && (
                        <div className="bControlSection controlToolFontSize">
                          <div className="alt-row">
                            <div className="bConHeader">Alignment</div>
                            <div className="fontEditRow fontEditRowIconsAlignment">
                              <div className="alt-col-2 alt-no-margin">
                                <a
                                  onClick={onClickTextAlign.bind(this, "left")}
                                  data-text-effect="textLeft"
                                  className={`${
                                    getAttributeValue("textAlign") === "left"
                                      ? "activeAlignment"
                                      : ""
                                  } btntextLeft`}>
                                  <img src={LeftAlignmentSVG} />
                                </a>
                              </div>
                              <div className="alt-col-2 alt-no-margin">
                                <a
                                  onClick={onClickTextAlign.bind(
                                    this,
                                    "center"
                                  )}
                                  data-text-effect="textCenter"
                                  className={`${
                                    getAttributeValue("textAlign") === "center"
                                      ? "activeAlignment"
                                      : ""
                                  } btntextCenter`}>
                                  <img src={CenterAlignmentSVG} />
                                </a>
                              </div>
                              <div className="alt-col-2 alt-no-margin">
                                <a
                                  onClick={onClickTextAlign.bind(this, "right")}
                                  data-text-effect="textRight"
                                  className={`${
                                    getAttributeValue("textAlign") === "right"
                                      ? "activeAlignment"
                                      : ""
                                  } btntextRight`}>
                                  <img src={RightAlignmentSVG} />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    {isTextType && (
                      <div className="bControlSection controlToolPadding">
                        <div className="alt-row">
                          <div className="alt-col-8">
                            <div className="bConHeaderColor">Link Color</div>
                          </div>
                        </div>
                        <ColorPicker
                          key={getSelectedComponentId()}
                          color={getAttributeValue("linkColor")}
                          onClose={handleValueBlur.bind(this)}
                          showOpacity={false}
                          onChange={onChangeAttrColor.bind(
                            this,
                            getSelectedComponentId(),
                            "linkColor"
                          )}
                          range={getAttributeValue("linkColorRange")}
                          onChangeRange={onChangeAttrColor.bind(
                            this,
                            getSelectedComponentId(),
                            "linkColorRange"
                          )}
                        />

                        <div className="alt-row">
                          <div className="alt-col-8">
                            <div className="bConHeaderColor">
                              Link Color on Hover
                            </div>
                          </div>
                        </div>
                        <ColorPicker
                          key={getSelectedComponentId() + Math.random()}
                          color={getAttributeValue("linkColorHover")}
                          onClose={handleValueBlur.bind(this)}
                          showOpacity={false}
                          onChange={onChangeAttrColor.bind(
                            this,
                            getSelectedComponentId(),
                            "linkColorHover"
                          )}
                          range={getAttributeValue("linkColorHoverRange")}
                          onChangeRange={onChangeAttrColor.bind(
                            this,
                            getSelectedComponentId(),
                            "linkColorHoverRange"
                          )}
                        />
                      </div>
                    )}
                    {showAttrView && isTextType && (
                      <>
                        <div className="bControlSection controlToolPadding">
                          <div className="alt-row">
                            <div className="alt-col-8">
                              <div className="bConHeaderColor">
                                Background Color
                              </div>
                            </div>
                          </div>
                          <ColorPicker
                            key={getSelectedComponentId()}
                            color={getAttributeValue("backgroundColor")}
                            onClose={handleValueBlur.bind(this)}
                            onChange={onChangeAttrColor.bind(
                              this,
                              getSelectedComponentId(),
                              "backgroundColor"
                            )}
                            range={getAttributeValue("backgroundColorRange")}
                            onChangeRange={onChangeAttrColor.bind(
                              this,
                              getSelectedComponentId(),
                              "backgroundColorRange"
                            )}
                          />
                        </div>
                      </>
                    )}

                    {showAttrView &&
                      !isTextType &&
                      selectedItem.type !== "video" &&
                      selectedItem.type !== "audio" &&
                      selectedItem.type !== "image" &&
                      selectedItem.type !== "checkoutForm" &&
                      selectedItem.type !== "line" &&
                      selectedItem.type !== "countdown" &&
                      selectedItem.type !== "input" &&
                      selectedItem.type !== "fileInput" &&
                      selectedItem.type !== "checkbox" &&
                      selectedItem.type !== "icon" &&
                      selectedItem.type !== "navBar" &&
                      selectedItem.type !== "productDescription" &&
                      selectedItem.type !== "productName" &&
                      selectedItem.type !== "productPrice" &&
                      selectedItem.type !== "productImage" &&
                      selectedItem.type !== "productCart" &&
                      selectedItem.type !== "imageSlider" &&
                      selectedItem.type !== "socialMediaLinks" &&
                      selectedComponent !== "custom-section" && (
                        <div className="bControlSection controlToolPadding">
                          <div className="bConHeader">Colors & Background</div>

                          {/* For Section, Row, & Column */}
                          {!isButton && (
                            <>
                              {(selectedComponent === "section" ||
                                selectedComponent === "column") && (
                                <div className="alt-row">
                                  <div className="alt-col-9">
                                    <div className="bConHeaderColor">
                                      Use Full Width
                                    </div>
                                  </div>
                                  <div className="alt-col-3">
                                    <div className="customSpinner">
                                      <Toggle
                                        key={getSelectedComponentId()}
                                        id={getSelectedComponentId()}
                                        name={getSelectedComponentId()}
                                        defaultChecked={getAttributeValue(
                                          "backgroundFull"
                                        )}
                                        onChange={handleToggleChange.bind(
                                          this,
                                          "backgroundFull"
                                        )}
                                        onBlur={handleValueBlur.bind(this)}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                              <div className="alt-row">
                                <div className="alt-col-12 alt-no-margin">
                                  <div className="bConHeaderColor">
                                    Background Image
                                  </div>
                                </div>
                                <div className="alt-col-12 alt-no-margin">
                                  <div className="alt-col-10">
                                    <input
                                      type="text"
                                      className="cInputText"
                                      name={getSelectedComponentId()}
                                      value={getAttributeValue(
                                        "backgroundImage"
                                      )}
                                      onChange={handleInputChange.bind(
                                        this,
                                        "backgroundImage"
                                      )}
                                      onBlur={handleValueBlur.bind(this)}
                                    />
                                  </div>
                                  <div className="alt-col-2">
                                    <img
                                      src={SelectImageSVG}
                                      onClick={onClickSelectImage.bind(
                                        this,
                                        "item"
                                      )}
                                      className="selectImage"
                                    />
                                  </div>
                                </div>
                              </div>
                              {getAttributeValue("backgroundImage").trim()
                                .length > 0 && (
                                <>
                                  <div className="alt-row">
                                    <div className="alt-col-8">
                                      <div className="bConHeaderColor">
                                        Repeat Background
                                      </div>
                                    </div>
                                    <div className="alt-col-4">
                                      <div className="text-right">
                                        <Toggle
                                          id={getSelectedComponentId()}
                                          name={getSelectedComponentId()}
                                          defaultChecked={getAttributeValue(
                                            "backgroundRepeat"
                                          )}
                                          checked={getAttributeValue(
                                            "backgroundRepeat"
                                          )}
                                          onChange={handleToggleChange.bind(
                                            this,
                                            "backgroundRepeat"
                                          )}
                                          onBlur={handleValueBlur.bind(this)}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  {getAttributeValue("backgroundRepeat") ===
                                    false && (
                                    <>
                                      <div className="alt-row">
                                        <div className="alt-col-6">
                                          <div className="bConHeaderColor">
                                            Background Type
                                          </div>
                                        </div>
                                        <div className="alt-col-6">
                                          <div className="text-right">
                                            <select
                                              type="text"
                                              className="cSelectText"
                                              name={getSelectedComponentId()}
                                              onBlur={handleValueBlur.bind(
                                                this
                                              )}
                                              value={getAttributeValue(
                                                "backgroundAttachment"
                                              )}
                                              onChange={handleInputChange.bind(
                                                this,
                                                "backgroundAttachment"
                                              )}>
                                              <option value="scroll">
                                                Scrolling
                                              </option>
                                              <option value="fixed">
                                                Fixed
                                              </option>
                                            </select>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="alt-row">
                                        <div className="alt-col-6">
                                          <div className="bConHeaderColor">
                                            Background Position
                                          </div>
                                        </div>
                                        <div className="alt-col-6">
                                          <div className="text-right">
                                            <select
                                              type="text"
                                              className="cSelectText"
                                              name={getSelectedComponentId()}
                                              onBlur={handleValueBlur.bind(
                                                this
                                              )}
                                              value={getAttributeValue(
                                                "backgroundPosition"
                                              )}
                                              onChange={handleInputChange.bind(
                                                this,
                                                "backgroundPosition"
                                              )}>
                                              <option value="top">Top</option>
                                              <option value="bottom">
                                                Bottom
                                              </option>
                                              <option value="center">
                                                Center
                                              </option>
                                              <option value="left">Left</option>
                                              <option value="right">
                                                Right
                                              </option>
                                            </select>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="alt-row">
                                        <div className="alt-col-6">
                                          <div className="bConHeaderColor">
                                            Contain Image
                                          </div>
                                        </div>
                                        <div className="alt-col-6">
                                          <div className="text-right">
                                            <Toggle
                                              id={getSelectedComponentId()}
                                              name={getSelectedComponentId()}
                                              defaultChecked={getAttributeValue(
                                                "backgroundSize"
                                              )}
                                              checked={getAttributeValue(
                                                "backgroundSize"
                                              )}
                                              onChange={handleToggleChange.bind(
                                                this,
                                                "backgroundSize"
                                              )}
                                              onBlur={handleValueBlur.bind(
                                                this
                                              )}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}

                          {showAttrView && (
                            <>
                              <div className="alt-row">
                                <div className="alt-col-8">
                                  <div className="bConHeaderColor">
                                    Background Color
                                  </div>
                                </div>
                              </div>
                              <ColorPicker
                                key={getSelectedComponentId()}
                                color={getAttributeValue("backgroundColor")}
                                onClose={handleValueBlur.bind(this)}
                                onChange={onChangeAttrColor.bind(
                                  this,
                                  getSelectedComponentId(),
                                  "backgroundColor"
                                )}
                                range={getAttributeValue(
                                  "backgroundColorRange"
                                )}
                                onChangeRange={onChangeAttrColor.bind(
                                  this,
                                  getSelectedComponentId(),
                                  "backgroundColorRange"
                                )}
                              />
                            </>
                          )}
                          {showAttrView && selectedComponent === "item" && (
                            <>
                              <div className="alt-row">
                                <div className="alt-col-8">
                                  <div className="bConHeaderColor">
                                    Background Color on Hover
                                  </div>
                                </div>
                              </div>
                              <ColorPicker
                                key={getSelectedComponentId()}
                                color={getAttributeValue(
                                  "backgroundColorHover"
                                )}
                                onClose={handleValueBlur.bind(this)}
                                onChange={onChangeAttrColor.bind(
                                  this,
                                  getSelectedComponentId(),
                                  "backgroundColorHover"
                                )}
                                range={getAttributeValue(
                                  "backgroundColorHoverRange"
                                )}
                                onChangeRange={onChangeAttrColor.bind(
                                  this,
                                  getSelectedComponentId(),
                                  "backgroundColorHoverRange"
                                )}
                              />
                            </>
                          )}

                          {showAttrView && selectedComponent === "item" && (
                            <>
                              <div className="alt-row">
                                <div className="alt-col-8">
                                  <div className="bConHeaderColor">
                                    Text Color on Hover
                                  </div>
                                </div>
                              </div>
                              <ColorPicker
                                key={getSelectedComponentId()}
                                color={getAttributeValue("colorHover")}
                                onClose={handleValueBlur.bind(this)}
                                onChange={onChangeAttrColor.bind(
                                  this,
                                  getSelectedComponentId(),
                                  "colorHover"
                                )}
                                range={getAttributeValue("colorHoverRange")}
                                onChangeRange={onChangeAttrColor.bind(
                                  this,
                                  getSelectedComponentId(),
                                  "colorHoverRange"
                                )}
                              />
                            </>
                          )}

                          {/* For Specific Items */}
                          {showAttrView &&
                            selectedComponent === "item" &&
                            !isButton &&
                            !isTextType && (
                              <>
                                <div className="alt-row">
                                  <div className="alt-col-8">
                                    <div className="bConHeaderColor">
                                      Bold Color
                                    </div>
                                  </div>
                                  <div className="alt-col-4">
                                    <div
                                      style={{
                                        background: "rgb(220, 231, 117)",
                                        height: "22px",
                                        width: "22px",
                                        cursor: "pointer",
                                        borderRadius: "4px",
                                        marginLeft: "auto",
                                      }}></div>
                                    {/* <ChromePicker
                        // color={this.state.background}
                        // onChangeComplete={ this.handleChangeComplete }
                        /> */}
                                  </div>
                                </div>
                              </>
                            )}
                        </div>
                      )}

                    {showAttrView &&
                      (isButton ||
                        isTextType ||
                        selectedComponent === "section" ||
                        selectedComponent === "column" ||
                        selectedComponent === "row" ||
                        selectedItem.type === "image" ||
                        selectedItem.type === "video" ||
                        selectedItem.type === "audio" ||
                        selectedItem.type === "input" ||
                        selectedItem.type === "icon") && (
                        <div className="bControlSection controlToolPadding">
                          <div className="bConHeader">Borders & Edges</div>

                          <div className="alt-row">
                            <div className="alt-col-8">
                              <div className="bConHeaderSec">Border Width</div>
                              <div className="customSpinner">
                                <a
                                  className="cSpinBtn spinUp"
                                  onClick={onClickUpAttribute.bind(
                                    this,
                                    "borderWidth"
                                  )}>
                                  <img src={NavigateUpArrow} />
                                </a>
                                <input
                                  type="text"
                                  className="cSpinText"
                                  value={getAttributeValue("borderWidth")}
                                  name={getSelectedComponentId()}
                                  onChange={handleValueChange.bind(
                                    this,
                                    "borderWidth"
                                  )}
                                  onBlur={handleValueBlur.bind(this)}
                                />
                                <a
                                  className="cSpinBtn spinDown"
                                  onClick={onClickDownAttribute.bind(
                                    this,
                                    "borderWidth"
                                  )}>
                                  <img src={NavigateDownArrow} />
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="alt-row">
                            <div className="alt-col-8">
                              <div className="bConHeader">Border Style</div>
                              <select
                                type="text"
                                className="cSelectText"
                                name={getSelectedComponentId()}
                                onBlur={handleValueBlur.bind(this)}
                                value={getAttributeValue("lineStyle")}
                                onChange={handleInputChange.bind(
                                  this,
                                  "lineStyle"
                                )}>
                                <option value="solid">Solid</option>
                                <option value="dotted">Dotted</option>
                                <option value="dashed">Dashed</option>
                              </select>
                            </div>
                          </div>
                          <ColorPicker
                            key={getSelectedComponentId()}
                            color={getAttributeValue("borderColor")}
                            onClose={handleValueBlur.bind(this)}
                            onChange={onChangeAttrColor.bind(
                              this,
                              getSelectedComponentId(),
                              "borderColor"
                            )}
                            range={getAttributeValue("borderColorRange")}
                            onChangeRange={onChangeAttrColor.bind(
                              this,
                              getSelectedComponentId(),
                              "borderColorRange"
                            )}
                          />
                          <div className="alt-row">
                            <div className="alt-col-6">
                              <div className="bConHeaderSec">Roundness</div>
                              <div className="customSpinner">
                                <a
                                  className="cSpinBtn spinUp"
                                  onClick={onClickUpAttribute.bind(
                                    this,
                                    "borderRadius"
                                  )}>
                                  <img src={NavigateUpArrow} />
                                </a>
                                <input
                                  type="text"
                                  className="cSpinText"
                                  value={getAttributeValue("borderRadius")}
                                  name={getSelectedComponentId()}
                                  onChange={handleValueChange.bind(
                                    this,
                                    "borderRadius"
                                  )}
                                  onBlur={handleValueBlur.bind(this)}
                                />
                                <a
                                  className="cSpinBtn spinDown"
                                  onClick={onClickDownAttribute.bind(
                                    this,
                                    "borderRadius"
                                  )}>
                                  <img src={NavigateDownArrow} />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                    {showAttrView &&
                      selectedItem.type !== "line" &&
                      selectedItem.type !== "row" &&
                      selectedComponent !== "custom-section" && (
                        <div style={{ clear: "both" }}>
                          <div className="bControlSection controlToolPadding">
                            <div className="bConHeader">Padding</div>
                            <div className="alt-row">
                              <div className="alt-col-6">
                                <div className="bConHeaderSec">Top</div>
                                <div className="customSpinner">
                                  <a
                                    className="cSpinBtn spinUp"
                                    onClick={onClickUpAttribute.bind(
                                      this,
                                      "paddingTop"
                                    )}>
                                    <img src={NavigateUpArrow} />
                                  </a>
                                  <input
                                    type="text"
                                    className="cSpinText"
                                    value={getAttributeValue("paddingTop")}
                                    name={getSelectedComponentId()}
                                    onChange={handleValueChange.bind(
                                      this,
                                      "paddingTop"
                                    )}
                                    onBlur={handleValueBlur.bind(this)}
                                  />
                                  <a
                                    className="cSpinBtn spinDown"
                                    onClick={onClickDownAttribute.bind(
                                      this,
                                      "paddingTop"
                                    )}>
                                    <img src={NavigateDownArrow} />
                                  </a>
                                </div>
                              </div>

                              <div className="alt-col-6">
                                <div className="bConHeaderSec">Bottom</div>
                                <div className="customSpinner">
                                  <a
                                    className="cSpinBtn spinUp"
                                    onClick={onClickUpAttribute.bind(
                                      this,
                                      "paddingBottom"
                                    )}>
                                    <img src={NavigateUpArrow} />
                                  </a>
                                  <input
                                    type="text"
                                    className="cSpinText"
                                    value={getAttributeValue("paddingBottom")}
                                    name={getSelectedComponentId()}
                                    onChange={handleValueChange.bind(
                                      this,
                                      "paddingBottom"
                                    )}
                                    onBlur={handleValueBlur.bind(this)}
                                  />
                                  <a
                                    className="cSpinBtn spinDown"
                                    onClick={onClickDownAttribute.bind(
                                      this,
                                      "paddingBottom"
                                    )}>
                                    <img src={NavigateDownArrow} />
                                  </a>
                                </div>
                              </div>

                              <div className="alt-col-6">
                                <div className="bConHeaderSec">Left</div>
                                <div className="customSpinner">
                                  <a
                                    className="cSpinBtn spinUp"
                                    onClick={onClickUpAttribute.bind(
                                      this,
                                      "paddingLeft"
                                    )}>
                                    <img src={NavigateUpArrow} />
                                  </a>
                                  <input
                                    type="text"
                                    className="cSpinText"
                                    value={getAttributeValue("paddingLeft")}
                                    name={getSelectedComponentId()}
                                    onChange={handleValueChange.bind(
                                      this,
                                      "paddingLeft"
                                    )}
                                    onBlur={handleValueBlur.bind(this)}
                                  />
                                  <a
                                    className="cSpinBtn spinDown"
                                    onClick={onClickDownAttribute.bind(
                                      this,
                                      "paddingLeft"
                                    )}>
                                    <img src={NavigateDownArrow} />
                                  </a>
                                </div>
                              </div>

                              <div className="alt-col-6">
                                <div className="bConHeaderSec">Right</div>
                                <div className="customSpinner">
                                  <a
                                    className="cSpinBtn spinUp"
                                    onClick={onClickUpAttribute.bind(
                                      this,
                                      "paddingRight"
                                    )}>
                                    <img src={NavigateUpArrow} />
                                  </a>
                                  <input
                                    type="text"
                                    className="cSpinText"
                                    value={getAttributeValue("paddingRight")}
                                    name={getSelectedComponentId()}
                                    onChange={handleValueChange.bind(
                                      this,
                                      "paddingRight"
                                    )}
                                    onBlur={handleValueBlur.bind(this)}
                                  />
                                  <a
                                    className="cSpinBtn spinDown"
                                    onClick={onClickDownAttribute.bind(
                                      this,
                                      "paddingRight"
                                    )}>
                                    <img src={NavigateDownArrow} />
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                    {showAttrView &&
                      (selectedItem.type === "input" ||
                        selectedItem.type === "fileInput" ||
                        selectedItem.type === "checkbox" ||
                        selectedItem.type === "selectbox") &&
                      selectedComponent === "item" && (
                        <div className="bControlSection controlToolFontSize">
                          <div className="bConHeader">Form Name</div>
                          <div className="alt-row">
                            <div className="alt-col-12">
                              <div className="customSpinner">
                                <input
                                  type="text"
                                  className="cInputText"
                                  value={getAttributeValue("inputFormName")}
                                  name={getSelectedComponentId()}
                                  onChange={handleInputChange.bind(
                                    this,
                                    "inputFormName"
                                  )}
                                  onBlur={handleValueBlur.bind(this)}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="gap10" />
                        </div>
                      )}

                    {showAttrView && selectedComponent !== "custom-section" && (
                      <div className="bControlSection">
                        {(selectedComponent === "section" ||
                          selectedComponent === "column" ||
                          selectedComponent === "row" ||
                          selectedComponent === "item") && (
                          <div className="alt-row">
                            <div className="alt-col-12">
                              <div className="bConHeader">Animations</div>
                              <select
                                type="text"
                                className="cSelectText"
                                name={getSelectedComponentId()}
                                onBlur={handleValueBlur.bind(this)}
                                value={getAttributeValue("animation")}
                                onChange={handleInputChange.bind(
                                  this,
                                  "animation"
                                )}>
                                <option value="">None</option>
                                <option value="slide-right">Slide Right</option>
                                <option value="slide-left">Slide Left</option>
                                <option value="scale-in">Scan In</option>
                                <option value="fade-in">Fade In</option>
                                {selectedComponent === "item" && (
                                  <option value="Typewriter">Typewriter</option>
                                )}
                              </select>
                            </div>
                          </div>
                        )}

                        <div className="alt-row">
                          <div className="alt-col-9">
                            <div className="bConHeader">Hide on mobile</div>
                          </div>
                          <div className="alt-col-3">
                            <div className="customSpinner">
                              <Toggle
                                id={getSelectedComponentId()}
                                name={getSelectedComponentId()}
                                checked={isHideOnMobile}
                                onChange={handleHideOnMobileChange.bind(this)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="alt-row">
                          <div className="alt-col-9">
                            <div className="bConHeader">Hide on desktop</div>
                          </div>
                          <div className="alt-col-3">
                            <div className="customSpinner">
                              <Toggle
                                id={getSelectedComponentId()}
                                name={getSelectedComponentId()}
                                checked={isHideOnDesktop}
                                onChange={handleHideOnDesktopChange.bind(this)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </Scrollbars>
        </div>
        <div className="builderControlItems">{renderAddElement()}</div>
        <div className="builderControlSave">
          <div className="bControlSection noPadding">
            <div className="bConRow">
              <div className="bConTr bCon2Col bConTopBtns colHasBorder">
                {eMode === "normal" ? (
                  <div
                    className="bConPreview"
                    style={{ float: "left", width: "20%" }}
                    onClick={onClickExport.bind(this)}>
                    <div className="bConColIn">
                      <a>
                        {" "}
                        <i
                          className="material-icons"
                          title="Preview"
                          style={{ fontSize: 15 }}>
                          visibility
                        </i>
                      </a>
                    </div>
                  </div>
                ) : (
                  <div
                    className="bConPreview"
                    style={{ float: "left", width: "20%" }}
                    onClick={onClickCancelPopup.bind(this)}>
                    <div className="bConColIn">
                      <a>
                        <i
                          className="material-icons"
                          title="Cancel"
                          style={{ fontSize: 15 }}>
                          cancel
                        </i>
                      </a>
                    </div>
                  </div>
                )}
                <div
                  className="bConSave"
                  style={{
                    float: "left",
                    width: "20%",
                  }}
                  onClick={onClickSave.bind(this, 1)}>
                  <div
                    className="bConColIn"
                    style={{ borderLeft: "1px solid #ccc" }}>
                    <a>
                      <i
                        className="material-icons"
                        title="Save"
                        style={{ fontSize: 15 }}>
                        save
                      </i>
                    </a>
                  </div>
                </div>
                <div
                  className="bConSave"
                  style={{
                    float: "left",
                    width: "45%",
                  }}
                  onClick={onClickSave.bind(this, 2)}>
                  <div
                    className="bConColIn"
                    style={{ borderLeft: "1px solid #ccc", paddingTop: 4 }}>
                    <a>SAVE & CLOSE</a>
                  </div>
                </div>
                <div
                  className="bConSaveTemplate"
                  style={{
                    float: "left",
                    width: "15%",
                  }}
                  onClick={onClickSaveTemplate}>
                  <div
                    className="bConColIn"
                    style={{ borderLeft: "1px solid #ccc" }}>
                    <a>
                      <i
                        className="material-icons"
                        title="Save"
                        style={{ fontSize: 15 }}>
                        chevron_right
                      </i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*eslint-enable*/}
      </div>
    </div>
  );
}

export default WebBuilder;
