import { toast } from "react-toastify";

import $ from "jquery";
import Push from "push.js";
import Progress from "react-progress-2";
import moment from "moment";
import dompurify from "dompurify";

import images from "./images";
import { fullRoles, restRoles } from "./routes";
import { getItem, setItem, removeItem, removeAll } from "./storage";
import { removeCookie } from "./cookie";

import store from "../modules/store";
import { callGetSettings } from "../services/settingService";
import { STORE_USER_SETTINGS } from "../modules/types";
import { SettingsHelper } from "./settings";
import { setManifest } from "./pwa";

var uuid = require("uuid");

export const showNotification = (
  type,
  message,
  duration = 4000,
  containerId = "center"
) => {
  switch (type) {
    case "info":
      toast.info(message, {
        autoClose: duration,
        containerId: containerId,
      });
      break;
    case "success":
      toast.success(message, {
        autoClose: duration,
        containerId: containerId,
      });
      break;
    case "warning":
      toast.warn(message, {
        autoClose: duration,
        containerId: containerId,
      });
      break;
    case "error":
      toast.error(message, {
        autoClose: duration,
        containerId: containerId,
      });
      break;
    case "default":
      toast(message, {
        autoClose: duration,
        containerId: containerId,
      });
      break;
    default:
      toast(message, {
        autoClose: duration,
        containerId: containerId,
      });
      break;
  }
};

export const showOnClickNotification = (message, duration = 4000, callback) => {
  toast.info(message, {
    autoClose: duration,
    onClose: callback,
  });
};

export const getLoggedInUser = () => {
  let userData = getItem("userData");
  let userId = "";
  if (userData) {
    const user = JSON.parse(userData);
    userId = user._id;
  }
  return userId;
};

export const checkAuth = (currentScreen = "") => {
  let isLoggedIn = false;
  let isAdmin = false;
  let isMember = false;
  let isCompany = false;
  let pageRole = false;
  let rMode = false;

  let userRoles = Object.assign({}, restRoles);

  let tokenExpiry = getItem("tokenExpiry");
  console.log("TE: ", new Date(tokenExpiry * 1000));
  let userData = getItem("userData");
  rMode = getItem("rMode") === "y";

  if (tokenExpiry) {
    let currentTime = Math.floor(new Date().getTime() / 1000);
    if (parseInt(tokenExpiry, 0) > currentTime) {
      isLoggedIn = true;
    }
  }

  if (isLoggedIn) {
    let user = {};
    if (userData) {
      try {
        user = JSON.parse(userData);
        if (user) {
          isAdmin = user.member_type === "staff";
          isMember = user.member_type === "customer";
          let { isCompany: aCompany } = getCompanyRole(user);
          isCompany = aCompany;
        }
      } catch (error) {
        removeItem("userData");
        removeItem("accessToken");
        window.location.reload();
      }
    }

    Object.keys(userRoles).forEach((key) => {
      userRoles[key] = false;
      if (fullRoles.hasOwnProperty(key)) {
        userRoles[key] = true;
      }
    });

    if (isAdmin) {
      if (user.roles && user.roles.length > 0 && !user.master_roles) {
        user.roles.forEach((element) => {
          userRoles[element] = true;
        });
        if (currentScreen) {
          for (const key in restRoles) {
            if (restRoles.hasOwnProperty(key)) {
              const element = restRoles[key];
              if (userRoles[key] && element.indexOf(currentScreen) > -1) {
                pageRole = true;
              }
            }
          }
        }
      } else {
        Object.keys(userRoles).forEach((key) => {
          userRoles[key] = true;
        });
        pageRole = true;
      }
    }
  } else {
    removeItem("userData");
    removeCookie("userData");
    removeItem("accessToken");
    console.log("logout user: route token check");
  }

  return {
    isAdmin: isAdmin,
    isCompany: isCompany,
    isLoggedIn: isLoggedIn,
    isMember: isMember,
    userRoles: userRoles,
    pageRole: pageRole,
    rMode: rMode,
  };
};

export const openContentEditor = (type = "") => {
  if (type) {
    $(`.fullModal.contentEditor.${type}`).fadeIn(200).addClass("showFullModal");
  } else {
    $(".fullModal.contentEditor").fadeIn(200).addClass("showFullModal");
  }
};

export const closeContentEditor = (type = "") => {
  if (type) {
    $(`.fullModal.contentEditor.${type}`)
      .fadeOut(200)
      .removeClass("showFullModal");
  } else {
    $(".fullModal.contentEditor").fadeOut(200).removeClass("showFullModal");
  }

  // showNotification("info", "Don't forget to save the page.", 2000);
};

export const openSelectImageModal = (type) => {
  $(`.fullModal.selectImage.${type}`).fadeIn(200).addClass("showFullModal");
};

export const closeSelectImageModal = (type) => {
  $(`.fullModal.selectImage.${type}`).fadeOut(200).removeClass("showFullModal");
};

export const openPagePopupModal = (type) => {
  $(`.fullModal.pagePopup.${type}`).fadeIn(200).addClass("showFullModal");
};

export const closePagePopupModal = (type) => {
  $(`.fullModal.pagePopup.${type}`).fadeOut(200).removeClass("showFullModal");
};

export const openModal = (name) => {
  $(".fullModal").fadeIn(200).addClass("showFullModal");
};

export const closeModal = (name) => {
  $("." + name).modal("hide");
};

export const openMenu = () => {
  $(".globalMenu").addClass("globalMenuShow");
  $(".globalMenuOverlay").fadeIn(200);

  $(".globalMenuOverlay").click(() => {
    $(".globalMenu").removeClass("globalMenuShow");
    $(".globalMenuOverlay").fadeOut(200);
  });
};

export const closeMenu = () => {
  $(".globalMenu").removeClass("globalMenuShow");
  $(".globalMenuOverlay").fadeOut(200);
};

export const sendPushNotification = (data) => {
  Push.create(data.title, {
    body: data.content,
    link: data.link,
    icon: images.logoSm,
    timeout: data.timeout,
  });
};

export const imageUrl = (url, type = 1) => {
  let settingsHelper = new SettingsHelper();
  let bucket = settingsHelper.getAwsBucketName();
  if (isFS() && url && url.indexOf("template-") > -1) {
    bucket = "memberpages";
  }
  if (bucket === "memberpages") {
    return `https://d38x6s7j8yvy7v.cloudfront.net/${url}`;
  }
  return `https://${bucket}.s3.amazonaws.com/${url}`;
};

export const templateImageUrl = (url, type, date) => {
  let settingsHelper = new SettingsHelper();
  let bucket = settingsHelper.getAwsBucketName();
  if (isFS() && url && type === "pre-made") {
    bucket = "memberpages";
  }
  let iUrl = "";
  if (bucket === "memberpages") {
    iUrl = `https://d38x6s7j8yvy7v.cloudfront.net/${url}`;
  }
  iUrl = `https://${bucket}.s3.amazonaws.com/${url}`;
  if (date) {
    let diff = moment().diff(moment(date), "days");
    let rtime = moment().toDate().getTime();
    if (diff < 7) {
      iUrl = `${iUrl}?rtime=${rtime}`;
    }
  }
  return iUrl;
};

export const fileUrl = (url, type = 1) => {
  let settingsHelper = new SettingsHelper();
  let bucket = settingsHelper.getAwsBucketName();
  if (bucket === "memberpages") {
    return `https://d38x6s7j8yvy7v.cloudfront.net/${url}`;
  }
  return `https://${bucket}.s3.amazonaws.com/${url}`;
};

export const showLoading = () => {
  try {
    if (Progress !== undefined) {
      Progress.show();
    }
  } catch (error) {}
};

export const hideLoading = () => {
  try {
    if (Progress !== undefined) {
      Progress.hide();
    }
  } catch (error) {}
};

export function callApi(api, isLoading = true) {
  if (isLoading) {
    showLoading();
  }

  return api
    .then((data) => {
      if (isLoading) {
        hideLoading();
      }
      return Promise.resolve(data);
    })
    .catch((error) => {
      if (isLoading) {
        hideLoading();
      }
      throw error;
    });
}

export function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  /*eslint-disable*/
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  /*eslint-enable*/
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export function toTitleCase(str) {
  if (!str) {
    return str;
  }
  return str.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export function getAffiliateId() {
  let partnerId = "";
  let affiliateExpriedDate = getItem("aed");
  if (affiliateExpriedDate) {
    let expiredDate = new Date(affiliateExpriedDate);
    if (expiredDate > new Date()) {
      partnerId = getItem("aid");
    }
  }
  return partnerId;
}

export function getReferMemberId() {
  let memberId = "";
  let referExpriedDate = getItem("rend");
  if (referExpriedDate) {
    let expiredDate = new Date(referExpriedDate);
    if (expiredDate > new Date()) {
      memberId = getItem("rid");
    }
  }
  return memberId;
}

export function calculateChlgStartDate(dayWeek, days = "") {
  let challengeDate = "";

  let cDate = moment().startOf("day");

  if (days) {
    cDate = moment().add(days, "days");
  }

  if (dayWeek) {
    let days = {
      monday: 1,
      tuesday: 2,
      wednesday: 3,
      thursday: 4,
      friday: 5,
      saturday: 6,
      sunday: 7,
    };
    let weekDay = moment().weekday();
    let challengeDay = days[dayWeek];
    let date = cDate
      .day(challengeDay)
      .startOf("day")
      .format("MM-DD-YYYY")
      .toString();
    if (weekDay > challengeDay) {
      date = cDate
        .day(challengeDay + 7)
        .startOf("day")
        .format("MM-DD-YYYY")
        .toString();
    }
    challengeDate = date;
  } else {
    challengeDate = cDate.startOf("day").format("MM-DD-YYYY").toString();
  }
  return challengeDate;
}

export function loggedInAsUser(data) {
  if (data) {
    setItem(
      "userData",
      JSON.stringify({
        _id: data._id,
        /*eslint-disable*/
        member_type: data.member_type,
        master_roles: data.master_roles,
        roles: data.roles,
        /*eslint-enable*/
      })
    );
  }
  setTimeout(() => {
    window.location.reload();
  }, 100);
}

export function slugify(text, trim = true) {
  /*eslint-disable*/
  if (trim) {
    return text
      .toString()
      .toLowerCase()
      .trim()
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(/&/g, "-and-") // Replace & with 'and'
      .replace(/[^\w\-]+/g, "") // Remove all non-word chars
      .replace(/\-\-+/g, "-"); // Replace multiple - with single -
  } else {
    return text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(/&/g, "-and-") // Replace & with 'and'
      .replace(/[^\w\-]+/g, "") // Remove all non-word chars
      .replace(/\-\-+/g, "-")
      .trim(); // Replace multiple - with single -
  }
  /*eslint-enable*/
}

export function cleanString(text) {
  return text.replace(/[&\\/\\#,+()$~%.'":*?<>{}]/g, "");
}

export function getCompanyRole(userData) {
  let companies = [];

  if (userData.company_list) {
    companies = userData.company_list.filter((d) => {
      return d.user_role === "admin" || d.user_role === "manager";
    });
  }

  let companyId = "";
  if (companies.length > 0) {
    companyId = companies[0].id;
  }

  let isCompany = companies.length > 0 ? true : false;
  let isMultipleCompany = companies.length > 1 ? true : false;

  return {
    isCompany: isCompany,
    companyId: companyId,
    isMultipleCompany: isMultipleCompany,
  };
}

export function validateEmail(email) {
  /*eslint-disable*/
  var re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  /*eslint-enable*/
  return re.test(String(email).toLowerCase());
}

export function toPromise(promise) {
  return promise
    .then((data) => {
      return [null, data];
    })
    .catch((err) => [err]);
}

export function bytesToSize(bytes) {
  var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "0 Byte";
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
}

export function nth(n) {
  n = parseInt(n, 10);
  return ["st", "nd", "rd"][((((n + 90) % 100) - 10) % 10) - 1] || "th";
}

export function genrateUniqueKey() {
  return (
    Math.random().toString(36).substring(2) + new Date().getTime().toString(36)
  );
}

export function getStoreSettings(location) {
  callGetSettings("initwebapp").then((d) => {
    if (d.status) {
      store.dispatch({
        type: STORE_USER_SETTINGS,
        data: d.data,
      });
      setManifest(d.data);
      setTitleFavicon(d.data);
      // setGoogleSiteVerificationCode(d.data);
      // setGoogleTagCodeId(d.data);
      // setFacebookPixelId(d.data);
      redirectToDefault(d.data, location);

      let settings = d.data;

      if (settings["business_details"] && settings["business_details"][0]) {
        let id = settings["business_details"][0]["unique_id"];
        if (id) {
          setItem("sk", id);
        }
      }
      setItem("pbc", d.data.page_back_color);
      setItem("bbbc", d.data.blue_button_back_color);
      setItem("bbfc", d.data.blue_button_font_color);
      setItem("obbc", d.data.orange_button_back_color);
      setItem("obfc", d.data.orange_button_font_color);

      if (d.data.page_back_color && location.pathname.includes("/member")) {
        $(".admin-body").css("backgroundColor", d.data.page_back_color);
      }

      setItem("de", d.data.default_editor);

      if (d.data.site_status === "disable") {
        removeAll();
        if (!location.pathname.includes("/login")) {
          window.location.assign("/login");
        }
      }
    }
  });
}

export function getStoreSettingsLogin() {
  callGetSettings("postitapp").then((d) => {
    if (d.status) {
      store.dispatch({
        type: STORE_USER_SETTINGS,
        data: d.data,
      });
      let settings = d.data;
      if (settings["stripe"]) {
        let id = settings["stripe"]["private_key"];
        if (id) {
          setItem("spk", id);
        }
      }
    }
  });
}

function redirectToDefault(settings, location) {
  let hostname = window.location.hostname;
  if (
    hostname.includes("localhost") ||
    hostname.includes("127.0.0.1") ||
    hostname.includes("192")
  ) {
    return;
  }
  if (settings["business_details"] && settings["business_details"][0]) {
    let domains = settings["business_details"][0]["domain_list"];
    let defaultDomains = domains.filter((d) => d.type === "default");

    let path = location.pathname;

    if (defaultDomains.length > 0 && path.includes("admin")) {
      let defaultDomain = defaultDomains[0].domain;
      if (defaultDomain !== window.location.hostname) {
        let dd = `https://${defaultDomain}/admin`;
        setItem("dd", dd);
        setItem("ddf", defaultDomain);
        window.location.assign(dd);
      }
    }
  }
}

function setTitleFavicon(settings) {
  if ("page_title" in settings) {
    document.title = settings["page_title"];
  }

  if ("page_fav_icon" in settings) {
    var link =
      document.querySelector("link[rel*='icon']") ||
      document.createElement("link");
    link.type = "image/x-icon";
    link.rel = "shortcut icon";
    link.href = imageUrl(settings["page_fav_icon"]);
    document.getElementsByTagName("head")[0].appendChild(link);
  }
}

// function setGoogleSiteVerificationCode(settings) {
//   if (settings.integration) {
//     if (settings.integration.google_site_verification_code) {
//       var meta =
//         document.querySelector("meta[name*='google-site-verification']") ||
//         document.createElement("meta");
//       meta.content = settings.integration
//         ? settings.integration.google_site_verification_code
//         : "lhn8HOTKrU7dX7IISMyaTeRxDWPI2krMyNg10j2a6fU";
//       document.getElementsByTagName("head")[0].appendChild(meta);
//     }
//   }
// }

// function setGoogleTagCodeId(settings) {
//   if (settings.integration) {
//     if (settings.integration.google_tag_id) {
//       var scriptGTId = document.createElement("script");
//       scriptGTId.async = true;
//       scriptGTId.src =
//         "https://www.googletagmanager.com/gtag/js?id=" +
//         (settings.integration
//           ? settings.integration.google_tag_id
//           : "AW-779375570");
//       document.getElementsByTagName("head")[0].appendChild(scriptGTId);
//     }
//   }

//   if (settings.integration) {
//     if (
//       settings.integration.google_tag_id ||
//       settings.integration.google_tag_code
//     ) {
//       var scriptGTCode = document.createElement("script");

//       let scriptText =
//         "window.dataLayer = window.dataLayer || [];\n" +
//         "function gtag() {\n" +
//         "dataLayer.push(arguments);\n" +
//         "}\n" +
//         "gtag('js', new Date());\n";

//       if (settings.integration.google_tag_id) {
//         scriptText +=
//           "gtag('config', '" + settings.integration.google_tag_id + "');\n";
//       }
//       if (settings.integration.google_tag_code) {
//         scriptText +=
//           "gtag('config', '" + settings.integration.google_tag_code + "');\n";
//       }

//       scriptGTCode.text = scriptText;
//       document.getElementsByTagName("head")[0].appendChild(scriptGTCode);
//     }
//   }
// }

export function setFacebookPixelId(settings) {
  if (settings.integration) {
    if (settings.integration.facebook_pixel_code) {
      var scriptFbPixelId = document.createElement("script");
      scriptFbPixelId.text =
        "!(function (f, b, e, v, n, t, s) {\n" +
        "if (f.fbq) return;\n" +
        "n = f.fbq = function () {\n" +
        "n.callMethod ?\n" +
        " n.callMethod.apply(n, arguments) :\n" +
        "n.queue.push(arguments);\n" +
        "};\n" +
        "if (!f._fbq) f._fbq = n;\n" +
        "n.push = n;\n" +
        "n.loaded = !0;\n" +
        "n.version = '2.0';\n" +
        "n.queue = [];\n" +
        "t = b.createElement(e);\n" +
        "t.async = !0;\n" +
        "t.src = v;\n" +
        "s = b.getElementsByTagName(e)[0];\n" +
        "s.parentNode.insertBefore(t, s);\n" +
        "})(\n" +
        "window,\n" +
        "document,\n" +
        "'script',\n" +
        "'https://connect.facebook.net/en_US/fbevents.js'\n" +
        ");\n" +
        "fbq('init', '" +
        (settings.integration
          ? settings.integration.facebook_pixel_code
          : "656534494697566") +
        "');\n" +
        "fbq('track', 'PageView');";
      document.getElementsByTagName("head")[0].appendChild(scriptFbPixelId);
    }
  }

  if (settings.integration) {
    if (settings.integration.facebook_pixel_code) {
      var noscriptFbPixelId = document.createElement("noscript");
      var noscriptImg = document.createElement("img");
      noscriptImg.height = 1;
      noscriptImg.width = 1;
      noscriptImg.src =
        "https://www.facebook.com/tr?id=" +
        (settings.integration
          ? settings.integration.facebook_pixel_code
          : "656534494697566") +
        "&ev=PageView&noscript=1";
      noscriptFbPixelId.appendChild(noscriptImg);
      document.getElementsByTagName("head")[0].appendChild(noscriptFbPixelId);
    }
  }
}

export function extractHostname(url) {
  var hostname;
  //find & remove protocol (http, ftp, etc.) and get hostname

  if (url.indexOf("//") > -1) {
    hostname = url.split("/")[2];
  } else {
    hostname = url.split("/")[0];
  }

  //find & remove port number
  hostname = hostname.split(":")[0];
  //find & remove "?"
  hostname = hostname.split("?")[0];

  return hostname;
}

export function isFS() {
  let hostname = window.location.hostname;
  return (
    hostname.includes("app.funandsustainable.com") ||
    hostname.includes("fs.memberpages.app") ||
    hostname.includes("localhost")
  );
}

export function isTemplateSite() {
  return (
    window.location.hostname.includes("templates.memberpages") ||
    window.location.hostname.includes("127.0.0.1") ||
    window.location.hostname.includes("templates.memberpages.local")
  );
}

export function isLocalSite() {
  return (
    window.location.hostname.includes("localhost") ||
    window.location.hostname.includes("app.memberpages.local")
  );
}

export function isDashboardSite() {
  let hostname = window.location.hostname;
  return (
    hostname.includes("dashboard.memberpages") ||
    hostname.includes("127.0.0.1") ||
    window.location.hostname.includes("dashboard.memberpages.local")
  );
}

export function getHeaderLogo() {
  let settingsHelper = new SettingsHelper();
  let branding = settingsHelper.getBranding();

  let image = images.defaultLogo;
  let type = "";
  if (branding.headerImage !== "") {
    image = imageUrl(branding.headerImage);
    type = "user";
  } else {
    image = images.defaultLogo;
    type = "default";
  }
  return { image: image, color: branding.loginHeaderBackColor, type: type };
}

export function getFooterLogo() {
  let settingsHelper = new SettingsHelper();
  let branding = settingsHelper.getBranding();

  let image = images.defaultLogo;
  let defaultLogo = true;

  if (branding.footerImage !== "") {
    image = imageUrl(branding.footerImage);
    defaultLogo = false;
  } else {
    image = images.defaultLogo;
  }
  return {
    image: image,
    color: branding.loginFooterBackColor,
    defaultLogo: defaultLogo,
  };
}

export function getLoginBackImage() {
  let settingsHelper = new SettingsHelper();
  let branding = settingsHelper.getBranding();

  let loginBackImage = "";

  if (branding.loginBackImage !== "") {
    loginBackImage = imageUrl(branding.loginBackImage);
  }
  return { loginBackImage };
}

export function getSiteName() {
  let settingsHelper = new SettingsHelper();
  let branding = settingsHelper.getBranding();

  let siteName = "";

  if (branding.siteName !== "") {
    siteName = branding.siteName;
  }
  return { siteName };
}

export function sanitizer(text) {
  const sanitizerFunc = dompurify.sanitize;
  return sanitizerFunc(text, {
    FORBID_TAGS: ["style", "scripts"],
    ADD_TAGS: ["iframe"],
  });
}

export function sanitizerTicket(text) {
  const sanitizerFunc = dompurify.sanitize;
  return sanitizerFunc(text, {
    FORBID_TAGS: ["scripts"],
    ADD_TAGS: ["iframe"],
  });
}

export function getUniqueId() {
  let id = uuid.v4();

  return id.substr(id.length - 12);
}

export function convertDataURIToBinary(dataURI) {
  var BASE64_MARKER = ";base64,";

  var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
  var base64 = dataURI.substring(base64Index);
  var raw = window.atob(base64);
  var rawLength = raw.length;
  var array = new Uint8Array(new ArrayBuffer(rawLength));

  for (var i = 0; i < rawLength; i++) {
    array[i] = raw.charCodeAt(i);
  }
  return array;
}

export function getEditorType(html, contentObject = {}) {
  let editorType = getItem("de") === "classic" ? "unlayer" : "mp";
  if (html !== "" && !contentObject.body) {
    editorType = "mp";
  } else if (html === "" && isFS()) {
    editorType = "mp";
  } else if (contentObject.body) {
    editorType = "unlayer";
  }
  return editorType;
}

export function getFormattedNumber(phoneNumber) {
  if (phoneNumber) {
    let newPhoneNumber = phoneNumber.replace(/\D/g, "");
    newPhoneNumber = newPhoneNumber.toString();

    if (newPhoneNumber.length > 0) {
      if (newPhoneNumber.charAt(0) === "+") {
        newPhoneNumber = newPhoneNumber.substring(1);
      }

      if (newPhoneNumber.charAt(0) === "1") {
        newPhoneNumber = newPhoneNumber.substring(1);
      }
    }
    return newPhoneNumber;
  }
  return phoneNumber;
}

export function checkGMSMessage(message) {
  const gsmCodePoints = new Set([
    0x000a, 0x000c, 0x000d, 0x0020, 0x0021, 0x0022, 0x0023, 0x0024, 0x0025,
    0x0026, 0x0027, 0x0028, 0x0029, 0x002a, 0x002b, 0x002c, 0x002d, 0x002e,
    0x002f, 0x0030, 0x0031, 0x0032, 0x0033, 0x0034, 0x0035, 0x0036, 0x0037,
    0x0038, 0x0039, 0x003a, 0x003b, 0x003c, 0x003d, 0x003e, 0x003f, 0x0040,
    0x0041, 0x0042, 0x0043, 0x0044, 0x0045, 0x0046, 0x0047, 0x0048, 0x0049,
    0x004a, 0x004b, 0x004c, 0x004d, 0x004e, 0x004f, 0x0050, 0x0051, 0x0052,
    0x0053, 0x0054, 0x0055, 0x0056, 0x0057, 0x0058, 0x0059, 0x005a, 0x005b,
    0x005c, 0x005d, 0x005e, 0x005f, 0x0061, 0x0062, 0x0063, 0x0064, 0x0065,
    0x0066, 0x0067, 0x0068, 0x0069, 0x006a, 0x006b, 0x006c, 0x006d, 0x006e,
    0x006f, 0x0070, 0x0071, 0x0072, 0x0073, 0x0074, 0x0075, 0x0076, 0x0077,
    0x0078, 0x0079, 0x007a, 0x007b, 0x007c, 0x007d, 0x007e, 0x00a1, 0x00a3,
    0x00a4, 0x00a5, 0x00a7, 0x00bf, 0x00c4, 0x00c5, 0x00c6, 0x00c7, 0x00c9,
    0x00d1, 0x00d6, 0x00d8, 0x00dc, 0x00df, 0x00e0, 0x00e4, 0x00e5, 0x00e6,
    0x00e8, 0x00e9, 0x00ec, 0x00f1, 0x00f2, 0x00f6, 0x00f8, 0x00f9, 0x00fc,
    0x0393, 0x0394, 0x0398, 0x039b, 0x039e, 0x03a0, 0x03a3, 0x03a6, 0x03a8,
    0x03a9, 0x20ac,
  ]);

  for (const s of message) {
    const codePoint = s.codePointAt(0);
    if (codePoint && !gsmCodePoints.has(codePoint)) {
      return false;
    }
  }
  return true;
}

export function getOrangeButtonStyle() {
  let style = { backgroundColor: "orange", color: "white" };
  let obbc = getItem("obbc");
  if (obbc) {
    style["backgroundColor"] = obbc;
  }

  let obfc = getItem("obfc");
  if (obfc) {
    style["color"] = obfc;
  }
  return style;
}

export function getBlueButtonStyle() {
  let style = { backgroundColor: "#26ace2", color: "white" };
  let bbbc = getItem("bbbc");
  if (bbbc) {
    style["backgroundColor"] = bbbc;
  }

  let bbfc = getItem("bbfc");
  if (bbfc) {
    style["color"] = bbfc;
  }
  return style;
}

export function cleanAmount(amount) {
  if (amount) {
    amount = amount.replace("$", "");
    amount = amount.replace(",", "");
    amount = amount.trim();
  }
  return amount;
}

export function linkify(text) {
  /*eslint-disable*/
  return text.replaceAll(
    /((http|https|ftp):\/\/[\w?=&.\/-;#~%-]+(?![\w\s?&.\/;#~%"=-]*>))/g,
    "<a href='$1'>$1</a> "
  );
  /*eslint-enable*/
}

export function isValidUrl(url) {
  // Regular expression to match URL starting with http:// or https://
  var urlPattern = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
  return urlPattern.test(url);
}
