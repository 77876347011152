import * as api from "./api";
import { callApi } from "../helpers";

function callCreatePodcast(data) {
  return callApi(api.service("podcast").create(data));
}

function callUpdatePodcast(id, data) {
  return callApi(api.service("podcast").patch(id, data));
}

function callGetPodcastUsingQuery(query) {
  return callApi(api.service("podcast").find(query));
}

function callGetPodcastById(id) {
  return callApi(api.service("podcast").get(id));
}

function callDeletePodcastById(id) {
  return callApi(api.service("podcast").remove(id));
}

export {
  callCreatePodcast,
  callUpdatePodcast,
  callGetPodcastUsingQuery,
  callGetPodcastById,
  callDeletePodcastById
};
