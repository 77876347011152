import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { VelocityTransitionGroup } from "velocity-react";
import DateTimePicker from "react-datetime-picker";
import DatePicker from "react-date-picker";
import { CopyToClipboard } from "react-copy-to-clipboard";

import TitleBox from "../../../components/general/titleBox";
import Checkbox from "../../../components/form/checkBox";
import SelectChallenge from "../../../components/form/selectChallenge";
// import SelectProduct from "../../../components/form/selectProduct";
import TypeableSelect from "../../../components/form/typeableSelect";
import SelectChallengeGroup from "../../../components/form/selectChallengeGroup";
import RadioTag from "../../../components/form/radioTag";
import TagSelect from "../../../components/form/tagSelect";
import HelpTextDiv from "../../../components/form/helpTextDiv";
import PageContent from "../../../components/editor/pageContent";
import StatusSwitch from "../../../components/form/statusSwitch";
import QuillEditor from "../../../components/form/reactQuill";
import { DraftEditor } from "../../../packages/draft-editor/index";

import {
  getChlgs,
  getStripePlans,
  createCheckoutItem,
  updateCheckoutItem,
  resetStatus,
  getSystemTags,
} from "../../../modules/actions/index";
import {
  showNotification,
  isFS,
  getUniqueId,
  getEditorType,
  isDashboardSite,
  cleanAmount,
  toPromise,
  slugify,
} from "../../../helpers";
import {
  callCheckTier,
  callCreateCheckoutItem,
  callGetCheckoutItems,
  callGetEmailUsingId,
  callGetPromoCodes,
  callGetSystemTags,
  callGetUsersByQuery,
} from "../../../services";
import Onboarding from "../onboarding/onboarding";
import { getItem } from "../../../helpers/storage";
import { arrayMove } from "react-sortable-hoc";
import {
  SortableFormFieldList,
  SortableBumpItemList,
} from "../../../components/form/sortableList";
import { getUpdatedEditorContent } from "../../../components/editor/processEditor";
import config from "../../../helpers/config";
import SelectAddImage from "../../../components/form/selectAddImage";
import CheckoutItemOptions from "./checkoutItemOptions";
import { callGetSettings } from "../../../services/settingService";

class AddCheckoutItem extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    let checkoutItem = {
      _id: 0,
      productName: "",
      productDescription: "",
      descriptionJson: {},
      checkoutId: "",
      orderAmount: "",
      regularCost: "",
      orderType: "single",
      paymentType: "fixed",
      orderPlan: "",
      allowPaymentOptions: false,
      promoCodeAvailable: false,
      addOrderBumpItem: false,
      maxPayments: "",
      paidDays: "",
      bumpItemName: "",
      bumpItemDescription: "",
      bumpItemOrder: "",
      bumpAddTags: [],
      bumpSelectedTags: [],
      bumpOrderType: "single",
      bumpItemPlan: "",
      keyAllowPaymentOptions: Math.random(),
      keyPromoCodeAvailable: Math.random(),
      keyAddOrderBumpItem: Math.random(),
      keyChallenges: Math.random(),
      keyProducts: Math.random(),
      selectedMainChallenges: [],
      selectedMainProducts: [],
      selectedChallengeGroups: [],
      selectedBumpChallenges: [],
      selectedBumpProducts: [],
      addTags: [],
      selectedTags: [],
      keyAddTag: Math.random(),
      isActive: true,
      statusLabel: "Currently Active",
      isValidTillDate: false,
      validTillDate: new Date(),
      paymentOptions: [],
      thankYouHtml: "",
      thankYouObject: {},
      thankYouUrl: "",
      thankYouType: "page",
      isThankYouCustom: false,
      affiliateType: "",
      affiliateValue: "",
      affiliateType2: "",
      affiliateValue2: "",
      affiliateDescription: "",
      isAffiliateCommissions: false,
      isAffiliateCommissionsOverride: false,
      customText: "",
      editorType: isFS()
        ? "mp"
        : getItem("de") === "classic"
        ? "unlayer"
        : "mp",
      draftId: "",
      draftImage: "",
      showShipping: false,
      hideAffiliateLink: false,
      promoType: "all",
      pCodes: [],
      selectedPromoCodes: [],
      registrationFields: [],
      customCommissions: [],
      isUpgrade: false,
      keyUpgrade: Math.random(),
      appleAmount: "",
      agreeTerms: "",
      agreeTermsObject: {},
      isAgreeTerms: false,
      orderBumps: [],
      createMemberPagesAccount: false,
      customTextJson: {},
      doNotCollectBilling: false,
      groupBumps: {},
      imgUrl: "",
      allowCart: false,
      cardTypes: ["credit"],
      isSendEmail: false,
      emailId: "",
      emailTagId: "",
      isCustomAmount: false,
      customAmountNotes: "",
      stripeAccount: "default",
    };

    let type = "";
    let cItem = {};

    if (this.props.history.location.state !== undefined) {
      cItem = this.props.history.location.state.checkoutItem;

      type = this.props.history.location.state.type;

      checkoutItem._id = cItem._id;
      checkoutItem.productName = cItem.name;
      checkoutItem.productDescription = cItem.description || "";
      checkoutItem.descriptionJson = cItem.description_json || {};
      checkoutItem.checkoutId =
        type === "clone" ? getUniqueId() : cItem.checkout_id;
      checkoutItem.orderAmount = cItem.amount;
      checkoutItem.regularCost = cItem.regular_cost || "";
      checkoutItem.orderType = cItem.order_type || "single";
      checkoutItem.paymentType = cItem.payment_type || "fixed";
      checkoutItem.paymentOptions = cItem.payment_options || [];
      checkoutItem.orderPlan = cItem.order_plan || "";
      checkoutItem.allowPaymentOptions = cItem.payments ? true : false;
      checkoutItem.promoCodeAvailable =
        cItem.coupon_code === "Y" ? true : false;
      checkoutItem.allowCart = cItem.allow_cart;
      checkoutItem.maxPayments = cItem.payments;
      checkoutItem.paidDays = cItem.payment_max_days;
      checkoutItem.addOrderBumpItem = cItem.order_bump.name ? true : false;
      if (cItem.order_bumps) {
        if (cItem.order_bumps.length > 0) {
          checkoutItem.addOrderBumpItem = true;
        }
      }

      checkoutItem.isSendEmail = cItem.send_email;

      checkoutItem.doNotCollectBilling = cItem.do_not_collect_billing;

      checkoutItem.addTags = cItem.ftags || [];
      checkoutItem.selectedTags = cItem.ftags || [];
      checkoutItem.cardTypes = cItem.card_types || ["credit"];

      checkoutItem.customText = cItem.custom_text || "";
      checkoutItem.customTextJson = cItem.custom_text_json || {};
      checkoutItem.showShipping = cItem.show_shipping;
      checkoutItem.hideAffiliateLink = cItem.hide_affiliate_link;
      checkoutItem.promoType = cItem.promo_type || "all";

      let promoCodes = cItem.promo_codes || [];
      checkoutItem.selectedPromoCodes = promoCodes;
      checkoutItem.pCodes = promoCodes;

      checkoutItem.isActive = true;
      checkoutItem.appleAmount = cItem.apple_amount || "";

      checkoutItem.isValidTillDate = cItem.valid_till_date
        ? cItem.valid_till_date.length > 0
        : false;
      checkoutItem.validTillDate = checkoutItem.isValidTillDate
        ? new Date(cItem.valid_till_date)
        : new Date();

      if (cItem.hasOwnProperty("active")) {
        checkoutItem.isActive = cItem.active;
      }

      let challengeList = cItem.product_list.filter((d) => {
        return d.challenge_id.length > 0;
      });

      checkoutItem.statusLabel = checkoutItem.isActive
        ? "Currently Active"
        : "Currently Inactive";

      checkoutItem.thankYouHtml = cItem.thank_you_html || "";
      checkoutItem.thankYouObject = getUpdatedEditorContent(
        cItem.thank_you_object || {},
        cItem.thank_you_html
      );
      checkoutItem.thankYouUrl = cItem.thank_you_url || "";
      checkoutItem.thankYouType = cItem.thank_you_type || "page";
      if (checkoutItem.thankYouHtml || checkoutItem.thankYouUrl) {
        checkoutItem.isThankYouCustom = true;
      }

      checkoutItem.editorType = getEditorType(
        checkoutItem.thankYouHtml,
        checkoutItem.thankYouObject
      );

      checkoutItem.draftId = cItem.draft_id || "";
      checkoutItem.draftImage = cItem.draft_image || "";
      checkoutItem.isUpgrade = cItem.upgrade_product || false;
      checkoutItem.agreeTerms = cItem.agree_terms || "";
      checkoutItem.agreeTermsObject = cItem.agree_terms_object;
      checkoutItem.isAgreeTerms = checkoutItem.agreeTerms.length > 0;
      checkoutItem.createMemberPagesAccount = cItem.create_mp_account;

      checkoutItem.affiliateCommissions = cItem.affiliate_commissions || {};
      checkoutItem.affiliateDescription = cItem.affiliate_description || "";
      checkoutItem.customCommissions =
        checkoutItem.affiliateCommissions.custom_commissions || [];
      if (
        checkoutItem.affiliateCommissions &&
        (checkoutItem.affiliateCommissions.type ||
          checkoutItem.affiliateCommissions.type2)
      ) {
        checkoutItem.affiliateType =
          checkoutItem.affiliateCommissions.type || "";
        checkoutItem.affiliateValue =
          checkoutItem.affiliateCommissions.value || "";
        checkoutItem.affiliateType2 =
          checkoutItem.affiliateCommissions.type2 || "";
        checkoutItem.affiliateValue2 =
          checkoutItem.affiliateCommissions.value2 || "";
        checkoutItem.isAffiliateCommissions =
          checkoutItem.affiliateCommissions.enable;
        checkoutItem.isAffiliateCommissionsOverride =
          checkoutItem.affiliateCommissions.override || false;
      }

      checkoutItem.registrationFields = cItem.custom_fields || [];

      checkoutItem.selectedMainChallenges = [];
      challengeList.forEach((element) => {
        let challenge = {
          challenge: { name: element.name, _id: element.challenge_id },
          challengeType: element.type,
          challengeDate: element.start_date,
          challengeStartType: element.challenge_start_type || "",
        };
        checkoutItem.selectedMainChallenges.push(challenge);
      });

      checkoutItem.selectedChallengeGroups = [];
      if (cItem.challenge_group_list) {
        cItem.challenge_group_list.forEach((element) => {
          let group = {
            groupId: element.group_id,
            expireDays: element.expire_days,
            name: element.name,
            type: "universal-credit",
          };
          checkoutItem.selectedChallengeGroups.push(group);
        });
      }

      let productList = cItem.product_list.filter((d) => {
        return d.product_id.length > 0;
      });

      checkoutItem.selectedMainProducts = [];
      productList.forEach((element) => {
        let product = {
          name: element.name,
          productDate: element.start_date,
          currentDay: element.current_day || false,
        };
        checkoutItem.selectedMainProducts.push(product);
      });

      checkoutItem.orderBumps = cItem.order_bumps || [];

      if (checkoutItem.addOrderBumpItem) {
        if (checkoutItem.orderBumps.length === 0) {
          if (cItem.order_bump.name) {
            cItem.order_bump.id = getUniqueId();
            checkoutItem.orderBumps.push(cItem.order_bump);
          }
        }
      }

      checkoutItem.groupBumps = cItem.group_bumps || {
        isGroupBump: false,
        dropdownAboveText: "",
        dropdownDefaultText: "",
        groupBumps: {},
      };
    }

    let customTextJson = checkoutItem.customTextJson || {};
    let isCustomText =
      checkoutItem.customText !== "<p><br></p>" &&
      checkoutItem.customText.trim() !== "";
    let isCustomTextJson = Object.keys(customTextJson).length > 0;

    checkoutItem.imgUrl = cItem.image || "";

    checkoutItem.emailId = cItem.email_id || "";
    checkoutItem.emailTagId = cItem.email_tag_id || "";
    checkoutItem.isCustomAmount = cItem.custom_amount;
    checkoutItem.customAmountNotes = cItem.custom_amount_notes || "";
    checkoutItem.stripeAccount = cItem.stripe_account || "default";

    this.state = {
      isCustomText,
      isCustomTextJson,
      cItem: cItem,
      type: type,
      checkoutItemId: checkoutItem._id,
      productName: checkoutItem.productName,
      productDescription: checkoutItem.productDescription,
      descriptionJson: checkoutItem.descriptionJson,
      checkoutId: checkoutItem.checkoutId,
      orderAmount: checkoutItem.orderAmount,
      regularCost: checkoutItem.regularCost,
      orderType: checkoutItem.orderType,
      paymentType: checkoutItem.paymentType,
      paymentOptions: checkoutItem.paymentOptions,
      orderPlan: checkoutItem.orderPlan,
      allowPaymentOptions: checkoutItem.allowPaymentOptions,
      promoCodeAvailable: checkoutItem.promoCodeAvailable,
      addOrderBumpItem: checkoutItem.addOrderBumpItem,
      maxPayments: checkoutItem.maxPayments,
      paidDays: checkoutItem.paidDays,
      bumpItemName: checkoutItem.bumpItemName,
      bumpItemDescription: checkoutItem.bumpItemDescription,
      bumpItemOrder: checkoutItem.bumpItemOrder,
      bumpItemImage: "",
      bumpItemAllowQty: false,
      keyBumpItemAllowQty: Math.random(),
      bumpAddTags: checkoutItem.bumpAddTags,
      bumpSelectedTags: checkoutItem.bumpSelectedTags,
      bumpItemPlan: checkoutItem.bumpItemPlan,
      bumpOrderType: checkoutItem.bumpOrderType,
      keyAllowPaymentOptions: Math.random(),
      keyPromoCodeAvailable: Math.random(),
      keyAddOrderBumpItem: Math.random(),
      keyChallenges: Math.random(),
      keyProducts: Math.random(),
      keyChallengeGroups: Math.random(),
      challenges: [],
      plans: [],
      selectedMainChallenges: checkoutItem.selectedMainChallenges,
      selectedMainProducts: checkoutItem.selectedMainProducts,
      selectedChallengeGroups: checkoutItem.selectedChallengeGroups,
      selectedBumpChallenges: checkoutItem.selectedBumpChallenges,
      selectedBumpProducts: checkoutItem.selectedBumpProducts,
      showPaymentOptions: checkoutItem.orderType === "single" ? true : false,
      systemTags: [],
      addTags: checkoutItem.addTags,
      selectedTags: checkoutItem.selectedTags,
      keyAddTag: checkoutItem.keyAddTag,
      isActive: checkoutItem.isActive,
      statusLabel: checkoutItem.statusLabel,
      isValidTillDate: checkoutItem.isValidTillDate,
      validTillDate: checkoutItem.validTillDate,
      keyValidTillDate: Math.random(),
      checkoutItems: [],
      selectedCheckoutItem: { value: "", label: "" },
      keyCheckoutItems: Math.random(),
      showAddPaymentOption: false,
      optionType: "days",
      optionRegularCost: "",
      optionFallback: false,
      addChallengeStatus:
        checkoutItem._id !== 0
          ? checkoutItem.selectedMainChallenges.length !== 0 ||
            checkoutItem.selectedMainProducts.length !== 0
            ? "addChallenges"
            : "doNotAddChallenges"
          : "addChallenges",
      addBumpChallengeStatus:
        checkoutItem._id !== 0
          ? checkoutItem.selectedBumpChallenges.length !== 0 ||
            checkoutItem.selectedBumpProducts.length !== 0
            ? "addBumpChallenges"
            : "doNotAddBumpChallenges"
          : "addBumpChallenges",
      selectedDates: [{ date: new Date(), amount: "0" }],
      dates: [0],
      selectedDays: [{ day: "0", amount: "0" }],
      days: [0],
      selectedOptionIndex: -1,
      keyOptionType: Math.random(),
      showAddedOptions: true,
      optionId: "",
      thankYouHtml: checkoutItem.thankYouHtml,
      thankYouObject: checkoutItem.thankYouObject,
      thankYouUrl: checkoutItem.thankYouUrl,
      thankYouType: checkoutItem.thankYouType,
      isThankYouCustom: checkoutItem.isThankYouCustom,
      isAffiliateCommissions: checkoutItem.isAffiliateCommissions,
      isAffiliateCommissionsOverride:
        checkoutItem.isAffiliateCommissionsOverride,
      affiliateType: checkoutItem.affiliateType,
      affiliateValue: checkoutItem.affiliateValue,
      affiliateType2: checkoutItem.affiliateType2,
      affiliateValue2: checkoutItem.affiliateValue2,
      affiliateDescription: checkoutItem.affiliateDescription,
      keyAffiliateCommissions: Math.random(),
      keyThankYouItem: Math.random(),
      keyThankYouContent: Math.random(),
      keySwitch: Math.random(),
      customText: checkoutItem.customText,
      customTextJson: checkoutItem.customTextJson,
      editorType: checkoutItem.editorType,
      draftId: checkoutItem.draftId,
      draftImage: checkoutItem.draftImage,
      showShipping: checkoutItem.showShipping,
      keyShowShipping: Math.random(),
      hideAffiliateLink: checkoutItem.hideAffiliateLink,
      keyHideAffiliateLink: Math.random(),
      promoType: checkoutItem.promoType,
      keyPromoCode: Math.random(),
      selectedPromoCodes: checkoutItem.selectedPromoCodes,
      promoCodes: [],
      pCodes: checkoutItem.selectedPromoCodes,
      registrationFields: checkoutItem.registrationFields,
      selectedRegiFieldIndex: -1,
      regiFieldLabel: "",
      isRegiFieldRequired: false,
      regiFieldDropdownValue: "",
      regiFieldType: "text",
      keyRegiFieldRequired: Math.random(),
      keyAffiliateUser: Math.random(),
      selectedAffiliateUser: { value: "", label: "" },
      affiliateUsers: [],
      affiliatecType: "",
      affiliatecValue: "",
      affiliatecType2: "",
      affiliatecValue2: "",
      customCommissions: checkoutItem.customCommissions,
      isUpgrade: checkoutItem.isUpgrade,
      keyDraftEditor: Math.random(),
      appleAmount: checkoutItem.appleAmount,
      agreeTerms: checkoutItem.agreeTerms,
      agreeTermsObject: checkoutItem.agreeTermsObject,
      isAgreeTerms: checkoutItem.isAgreeTerms,
      keyAgreeTerms: Math.random(),
      doNotCollectBilling: checkoutItem.doNotCollectBilling,
      keyDoNotCollectBilling: Math.random(),
      orderBumps: checkoutItem.orderBumps,
      selectedBumpItemIndex: -1,
      keyAddBmpChallengeStatus: Math.random(),
      createMemberPagesAccount: checkoutItem.createMemberPagesAccount,
      keyMemberPagesAccount: Math.random(),
      keyCustomText: Math.random(),
      isGroupBumps: checkoutItem.groupBumps.isGroupBump,
      keyIsGroupBumps: Math.random(),
      groupBumps: checkoutItem.groupBumps.groupBumps || {},
      dropdownAboveText: checkoutItem.groupBumps.dropdownAboveText || "",
      dropdownDefaultText: checkoutItem.groupBumps.dropdownDefaultText || "",
      keySelectGroupBump: Math.random(),
      imgUrl: checkoutItem.imgUrl,
      showAdvancedOptionsDetails: false,
      loadingPage: true,
      allowCart: checkoutItem.allowCart,
      keyAllowCart: Math.random(),
      cardTypes: checkoutItem.cardTypes,
      keyEditor: Math.random(),
      keyInput: Math.random(),
      isSendEmail: checkoutItem.isSendEmail,
      keySendEmail: Math.random(),
      sendEmailHtml: "",
      sendEmailContent: {},
      sendEmailSubject: "",
      keySendEmailContent: Math.random(),
      emailId: checkoutItem.emailId,
      emailTagId: checkoutItem.emailTagId,
      isCustomAmount: checkoutItem.isCustomAmount,
      keyCustomAmount: Math.random(),
      customAmountNotes: checkoutItem.customAmountNotes,
      keyBumpItemImage: Math.random(),
      stripeAccounts: [],
      stripeAccount: checkoutItem.stripeAccount,
    };
  }

  componentDidMount() {
    if (this.state.checkoutItemId === 0) {
      this.checkTier();
    } else {
      this.setState({ loadingPage: false });
    }
    this.props.getChlgs();
    this.props.getStripePlans();
    this.getCheckoutItems();
    this.getPromoCodes();
    this.getSystemTags();
    this.getAffiliateUsers();
    this.getEmailInfo();
    this.getSettings();
  }

  async checkTier() {
    var response = await callCheckTier("num_checkout_pages");
    if (!response.status) {
      this.props.history.push("/admin/tier");
    } else {
      this.setState({ loadingPage: false });
    }
  }

  async getSettings() {
    let d = await callGetSettings("stripeaccounts");
    if (d.status) {
      let accounts = d.data.stripe_accounts || [];
      let newAccounts = [{ id: "default", name: "Default" }];
      newAccounts = newAccounts.concat(accounts);
      this.setState({ stripeAccounts: newAccounts });
    }
  }

  async getEmailInfo() {
    if (this.state.checkoutItemId && this.state.emailId) {
      const emailInfo = await callGetEmailUsingId(this.state.emailId);
      this.setState({
        emailId: this.state.type === "clone" ? "" : this.state.emailId,
        sendEmailContent: emailInfo.content,
        sendEmailHtml: emailInfo.html,
        sendEmailSubject: emailInfo.title,
        keySendEmailContent: Math.random(),
      });
    }
  }

  componentWillMount() {
    if (!this.state.isActive && this.state.checkoutId !== 0) {
      showNotification(
        "warning",
        "This checkout item is currently inactive.",
        false
      );
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.chlgs.data) {
      let challengeList = newProps.chlgs.data.filter((d) => {
        return d.archive === false || !d.archive;
      });
      if (newProps.chlgs.data) {
        this.setState({
          challenges: challengeList || [],
          keyChallenges: Math.random(),
        });
      }
    }

    if (newProps.message) {
      if (newProps.success) {
        if (this.state.checkoutItemId === 0) {
          this.resetProps();
        }

        showNotification("success", newProps.message, 5000);
      } else {
        showNotification("error", newProps.message, 5000);
      }
      this.props.resetStatus();
    }

    if (newProps.stripePlans.data) {
      this.setState({
        plans: newProps.stripePlans.data,
      });
    }
  }

  async getSystemTags() {
    let tags = await callGetSystemTags();
    this.setState({ systemTags: tags.data, keyAddTag: Math.random() });
  }

  resetProps() {
    this.setState({
      productName: "",
      productDescription: "",
      descriptionJson: {},
      checkoutId: "",
      orderAmount: "",
      regularCost: "",
      orderType: "single",
      paymentType: "fixed",
      orderPlan: "",
      allowPaymentOptions: false,
      promoCodeAvailable: false,
      addOrderBumpItem: false,
      maxPayments: "",
      paidDays: "",
      bumpItemName: "",
      bumpItemDescription: "",
      bumpItemOrder: "",
      bumpAddTags: [],
      bumpSelectedTags: [],
      bumpOrderType: "single",
      bumpItemPlan: "",
      keyAllowPaymentOptions: Math.random(),
      keyPromoCodeAvailable: Math.random(),
      keyAddOrderBumpItem: Math.random(),
      keyChallenges: Math.random(),
      keyProducts: Math.random(),
      selectedMainChallenges: [],
      selectedMainProducts: [],
      selectedChallengeGroups: [],
      selectedBumpChallenges: [],
      selectedBumpProducts: [],
      addTags: [],
      selectedTags: [],
      keyAddTag: Math.random(),
      isActive: true,
      statusLabel: "Currently Active",
      isValidTillDate: false,
      validTillDate: new Date(),
      paymentOptions: [],
      thankYouHtml: "",
      thankYouUrl: "",
      thankYouObject: {},
      isThankYouCustom: false,
      affiliateType: "",
      affiliateValue: "",
      affiliateType2: "",
      affiliateValue2: "",
      affiliateDescription: "",
      isAffiliateCommissions: false,
      isAffiliateCommissionsOverride: false,
      customText: "",
      draftId: "",
      draftImage: "",
      showShipping: false,
      hideAffiliateLink: false,
      promoType: "all",
      pCodes: [],
      selectedPromoCodes: [],
      registrationFields: [],
      customCommissions: [],
      isUpgrade: false,
      keyUpgrade: Math.random(),
      appleAmount: "",
      agreeTerms: "",
      agreeTermsObject: {},
      isAgreeTerms: false,
      isSendEmail: false,
      keySendEmail: Math.random(),
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  getCheckoutItems() {
    callGetCheckoutItems().then((d) => {
      let checkoutItems = d.data;
      let items = [];
      let selectedCheckoutItem = {};

      checkoutItems.forEach((element) => {
        if (element.checkout_id === this.state.cItem.redirect_item) {
          selectedCheckoutItem = {
            value: element.checkout_id,
            label: `${element.name} (${element.checkout_id})`,
          };
        }
        if (element.checkout_id !== this.state.checkoutId) {
          items.push({
            value: element.checkout_id,
            label: `${element.name} (${element.checkout_id})`,
          });
        }
      });
      this.setState({
        selectedCheckoutItem,
        checkoutItems: items,
        keyCheckoutItems: Math.random(),
      });
    });
  }

  getAffiliateUsers() {
    /*eslint-disable*/
    let query = {
      query: {
        is_affiliate: true,
        $select: ["_id", "name", "email_address"],
        populate: "no",
      },
    };
    /*eslint-enable*/

    callGetUsersByQuery(query).then((d) => {
      let fUsers = d.data;
      let affiliateUsers = [];

      fUsers.forEach((element) => {
        affiliateUsers.push({ value: element._id, label: element.name });
      });
      // let selectedUsers = [];

      // if (this.state.eventInfo.id === 0) {
      //   let staffUser = affiliateUsers.filter((d) => {
      //     return d.value === this.state.userData._id;
      //   });
      //   if (staffUser.length > 0) {
      //     selectedUsers.push(this.state.userData._id);
      //   }
      // } else {
      //   selectedUsers = this.state.eventInfo.selectedUsers;
      // }
      this.setState({
        affiliateUsers: affiliateUsers,
        keyAffiliateUser: Math.random(),
      });
    });
  }

  getPromoCodes() {
    callGetPromoCodes().then((d) => {
      let codes = [];
      d.data.forEach((element) => {
        codes.push({ title: element.code });
      });
      this.setState({ promoCodes: codes, keyPromoCode: Math.random() });
    });
  }

  onChangePlan(event) {
    const target = event.target;
    const value = target.value;

    this.setState({
      orderPlan: value,
    });
  }

  onChangeAppleAmount(event) {
    const target = event.target;
    const value = target.value;

    this.setState({
      appleAmount: value,
    });
  }

  onChangeBumpItemPlan(event) {
    const target = event.target;
    const value = target.value;

    this.setState({
      bumpItemPlan: value,
    });
  }

  onChangeValidTillDate(value) {
    this.setState({
      validTillDate: value,
    });
  }

  onSelectCheckoutItem = (item) => {
    this.setState({ selectedCheckoutItem: item });
  };

  toggleCheckbox = (check) => {
    this.setState({
      isActive: check,
      statusLabel: check ? "Currently Active" : "Currently Inactive",
    });
  };

  toggleAllowPaymentOptions = (check) => {
    this.setState({
      allowPaymentOptions: check,
      maxPayments: !check ? "" : this.state.maxPayments,
      paidDays: !check ? "" : this.state.paidDays,
    });
  };

  togglePromoCodeAvailable = (check) => {
    this.setState({
      promoCodeAvailable: check,
    });
  };

  toggleCustomAmount = (check) => {
    this.setState({
      isCustomAmount: check,
    });
  };

  toggleAllowCart = (check) => {
    this.setState({ allowCart: check });
  };

  toggleOrderBumpItem = (check) => {
    this.setState({
      addOrderBumpItem: check,
    });
  };

  toggleShowShipping = (check) => {
    this.setState({
      showShipping: check,
    });
  };

  toggleHideAffiliateLink = (check) => {
    this.setState({
      hideAffiliateLink: check,
    });
  };

  toggleThankYouItem = (check) => {
    this.setState({
      isThankYouCustom: check,
    });
  };

  toggleAffilicateCommissions = (check) => {
    this.setState({
      isAffiliateCommissions: check,
    });
  };

  toggleUpgrade = (check) => {
    this.setState({
      isUpgrade: check,
    });
  };

  toggleTermsAgree = (check) => {
    this.setState({
      isAgreeTerms: check,
    });
  };

  toggleSendEmail = (check) => {
    this.setState({
      isSendEmail: check,
    });
  };

  toggleDoNotCollectBilling = (check) => {
    this.setState({
      doNotCollectBilling: check,
    });
  };

  toggleMemberPagesAccount = (check) => {
    this.setState({
      createMemberPagesAccount: check,
    });
  };

  toggleOverrideAffilicateCommissions = (check) => {
    this.setState({
      isAffiliateCommissionsOverride: check,
    });
  };

  toggleValidTillDate = (check) => {
    this.setState({
      isValidTillDate: check,
    });
  };

  onChangeContent(html, content, draftId, draftImage) {
    this.setState({
      thankYouObject: content,
      thankYouHtml: html,
      keyThankYouContent: Math.random(),
      draftId: draftId,
      draftImage: draftImage,
    });
  }

  onChangeEmailContent(html, content) {
    this.setState({
      sendEmailContent: content,
      sendEmailHtml: html,
      keySendEmailContent: Math.random(),
    });
  }

  onChangeCustomText(value, text) {
    this.setState({
      customText: value,
    });
  }

  onChangeAddTags = (tags) => {
    this.setState({ addTags: tags });
  };

  onChangeBumpAddTags = (tags) => {
    this.setState({ bumpAddTags: tags });
  };

  onChangeMainChallenges(challenges) {
    this.setState({ selectedMainChallenges: challenges });
  }

  onChangeMainProducts(products) {
    this.setState({ selectedMainProducts: products });
  }

  onChangeChallengeGroups(challengeGroups) {
    this.setState({ selectedChallengeGroups: challengeGroups });
  }

  onChangeBumpChallenges(challenges) {
    this.setState({ selectedBumpChallenges: challenges });
  }

  onChangeBumpProducts(products) {
    this.setState({ selectedBumpProducts: products });
  }

  onSelectOrderType(item, id) {
    let value = item[Object.keys(item)[0]].value;
    if (value === "subscription") {
      this.setState({ showPaymentOptions: false, allowPaymentOptions: false });
    } else {
      this.setState({ showPaymentOptions: true });
    }
    this.setState({ [id]: value });
  }

  onSelectBumpOrderType(item, id) {
    let value = item[Object.keys(item)[0]].value;
    this.setState({ [id]: value });
  }

  onSelectPaymentType(item, id) {
    let value = item[Object.keys(item)[0]].value;

    if (value === "custom") {
      this.setState({ showPaymentOptions: false, allowPaymentOptions: false });
    } else {
      this.setState({ showPaymentOptions: true });
    }

    this.setState({ [id]: value });
  }

  onSelectPromoType(item, id) {
    let value = item[Object.keys(item)[0]].value;
    this.setState({ [id]: value, keyPromoCode: Math.random() });
  }

  onSelectOptionType(item, id) {
    let value = item[Object.keys(item)[0]].value;
    this.setState({ [id]: value });
  }

  onSelectAddChallengeStatus(item, id) {
    let value = item[Object.keys(item)[0]].value;

    this.setState({ [id]: value });
  }

  onSelectAddBumpChallengeStatus(item, id) {
    let value = item[Object.keys(item)[0]].value;

    this.setState({ [id]: value });
  }

  onChangeDescription = (html, json) => {
    this.setState({ productDescription: html, descriptionJson: json });
  };

  onPressPreview() {
    let checkoutUrl = "https://checkout.memberpages.app";
    let key = getItem("sk");
    let url = `${checkoutUrl}?p=${this.state.checkoutId}&key=${key}`;
    window.open(url, "_blank");
  }

  async onClickCreateCheckoutItem() {
    if (this.state.productName.trim().length === 0) {
      showNotification("error", "Product name is required", 4000);
      return;
    }

    if (this.state.productDescription.trim().length === 0) {
      showNotification("error", "Product description is required", 4000);
      return;
    }

    let orderAmount = this.state.orderAmount;

    if (
      this.state.orderType === "single" &&
      this.state.paymentType === "fixed" &&
      orderAmount.trim().length === 0
    ) {
      if (this.state.allowCart) {
        orderAmount = "0";
      } else {
        showNotification("error", "Order amount is required", 4000);
        return;
      }
    }

    if (
      this.state.orderType === "subscription" &&
      this.state.orderPlan.trim().length === 0
    ) {
      showNotification("error", "Order plan is required", 4000);
      return;
    }

    if (this.state.allowPaymentOptions) {
      if (this.state.maxPayments.trim().length === 0) {
        showNotification("error", "Maximum payment is required", 4000);
        return;
      }
      if (this.state.paidDays.trim().length === 0) {
        showNotification("error", "Paid days is required", 4000);
        return;
      }
    }

    if (this.state.isAgreeTerms) {
      if (this.state.agreeTerms.trim().length === 0) {
        showNotification("error", "Agree text is required", 4000);
        return;
      }
    }

    let productList = [];
    let challengeGroupList = [];
    if (this.state.addChallengeStatus === "addChallenges") {
      if (
        this.state.selectedMainChallenges.length === 0 &&
        this.state.selectedMainProducts.length === 0
      ) {
        showNotification(
          "error",
          "Please add a challenge or select 'Do Not Add Any Challenges'",
          4000
        );
        return;
      }

      /*eslint-disable*/
      this.state.selectedMainChallenges.forEach((element) => {
        productList.push({
          challenge_id: element.challenge._id,
          product_id: "",
          name: element.challenge.name,
          start_date: element.challengeDate,
          type: element.challengeType,
          challenge_start_type: element.challengeStartType,
        });
      });

      this.state.selectedMainProducts.forEach((element) => {
        productList.push({
          challenge_id: "",
          product_id: "Y",
          name: element.name,
          current_day: element.currentDay,
          start_date: element.productDate,
          type: "product",
        });
      });

      this.state.selectedChallengeGroups.forEach((element) => {
        challengeGroupList.push({
          group_id: element.groupId,
          expire_days: element.expireDays,
          name: element.name,
          type: "universal-credit",
        });
      });
    }
    /*eslint-enable*/

    if (this.state.isGroupBumps) {
      if (this.state.dropdownAboveText.trim().length === 0) {
        showNotification("error", "Dropdown text is required", 4000);
        return;
      }
      if (this.state.dropdownDefaultText.trim().length === 0) {
        showNotification("error", "Dropdown default text is required", 4000);
        return;
      }
      if (Object.keys(this.state.groupBumps).length === 0) {
        showNotification(
          "error",
          "Select atleast one order bump in dropdown",
          4000
        );
        return;
      }
    }

    let tagList = this.state.addTags
      .filter((d) => {
        return d._id !== null;
      })
      .map((i) => i._id);

    let checkoutId = this.state.checkoutId;

    if (!checkoutId) {
      checkoutId = getUniqueId();
    }

    checkoutId = slugify(checkoutId);
    let emailId = this.state.emailId;
    let emailTagId = this.state.emailTagId;

    if (this.state.isSendEmail) {
      if (this.state.sendEmailHtml) {
        const itemOptions = new CheckoutItemOptions();
        if (!emailId) {
          const emailData = await itemOptions.createEmail(
            this.state.productName,
            {
              html: this.state.sendEmailHtml,
              content: this.state.sendEmailContent,
              title: this.state.sendEmailSubject,
            }
          );
          emailId = emailData.emailId;
          emailTagId = emailData.tagId;
        } else {
          await itemOptions.updateEmail(emailId, {
            html: this.state.sendEmailHtml,
            content: this.state.sendEmailContent,
            title: this.state.sendEmailSubject,
          });
        }
      }
    }

    /*eslint-disable*/
    let apiData = {
      name: this.state.productName,
      description: this.state.productDescription,
      description_json: this.state.descriptionJson,
      checkout_id: checkoutId,
      status: "pending",
      amount: cleanAmount(orderAmount),
      regular_cost: cleanAmount(this.state.regularCost),
      order_type: this.state.orderType,
      order_plan: this.state.orderPlan,
      payments:
        this.state.paymentType === "fixed" ? this.state.maxPayments : "",
      payment_max_days:
        this.state.paymentType === "fixed" ? this.state.paidDays : "",
      coupon_code: this.state.promoCodeAvailable ? "Y" : "N",
      product_list: productList,
      challenge_group_list: challengeGroupList,
      tag_list: tagList,
      active: this.state.isActive,
      payment_type: this.state.paymentType,
      payment_options: this.state.paymentOptions,
      thank_you_html: this.state.thankYouHtml,
      thank_you_object: this.state.thankYouObject,
      thank_you_url: this.state.thankYouUrl,
      thank_you_type: this.state.thankYouType,
      draft_id: this.state.draftId,
      draft_image: this.state.draftImage,
      affiliate_commissions: {
        enable: this.state.isAffiliateCommissions,
        override: this.state.isAffiliateCommissionsOverride,
        type: this.state.affiliateType,
        value: this.state.affiliateValue,
        type2: this.state.affiliateType2,
        value2: this.state.affiliateValue2,
        custom_commissions: this.state.customCommissions,
      },
      affiliate_description: this.state.affiliateDescription,
      custom_text: this.state.customText,
      custom_text_json: this.state.customTextJson,
      show_shipping: this.state.showShipping,
      promo_type: this.state.promoType,
      promo_codes: this.state.pCodes,
      custom_fields: this.state.registrationFields,
      hide_affiliate_link: this.state.hideAffiliateLink,
      upgrade_product: this.state.isUpgrade,
      apple_amount: this.state.appleAmount,
      agree_terms: this.state.isAgreeTerms ? this.state.agreeTerms : "",
      agree_terms_object: this.state.agreeTermsObject,
      create_mp_account: this.state.createMemberPagesAccount,
      do_not_collect_billing: this.state.doNotCollectBilling,
      group_bumps: {
        isGroupBump: this.state.isGroupBumps,
        dropdownAboveText: this.state.dropdownAboveText,
        dropdownDefaultText: this.state.dropdownDefaultText,
        groupBumps: this.state.groupBumps,
      },
      image: this.state.imgUrl,
      allow_cart: this.state.allowCart,
      card_types: this.state.cardTypes,
      send_email: this.state.isSendEmail,
      email_id: emailId,
      email_tag_id: emailTagId,
      custom_amount: this.state.isCustomAmount,
      custom_amount_notes: this.state.customAmountNotes,
      stripe_account: this.state.stripeAccount || "default",
    };
    /*eslint-enable*/

    if (this.state.isValidTillDate) {
      apiData["valid_till_date"] = this.state.validTillDate;
      apiData["redirect_item"] = this.state.selectedCheckoutItem.value;
    } else {
      apiData["valid_till_date"] = "";
      apiData["redirect_item"] = "";
    }

    if (this.state.addOrderBumpItem) {
      apiData["order_bumps"] = this.state.orderBumps;
    } else {
      apiData["order_bumps"] = [];
    }
    /*eslint-enable*/

    if (this.state.checkoutItemId === 0) {
      let [err, data] = await toPromise(callCreateCheckoutItem(apiData));
      if (err) {
        showNotification("error", err.message, 5000);
      } else {
        if (data._id) {
          showNotification(
            "success",
            "Checkout Item created successfully",
            5000
          );
        }
      }
    } else {
      if (this.state.type === "clone") {
        let [err, data] = await toPromise(callCreateCheckoutItem(apiData));
        if (err) {
          showNotification("error", err.message, 5000);
        } else {
          if (data._id) {
            showNotification(
              "success",
              "Checkout Item created successfully",
              5000
            );
          }
        }
      } else {
        this.props.updateCheckoutItem(this.state.checkoutItemId, apiData);
      }
    }
  }

  renderPlans() {
    let returnVals = [];
    let plans = this.state.plans;
    plans.forEach((d) => {
      if (!d.stripe_account) {
        /*eslint-disable*/
        d.stripe_account = "default";
        /*eslint-enable*/
      }
    });
    plans = plans.filter((d) => d.stripe_account === this.state.stripeAccount);
    returnVals.push(<option value={""} key={0} />);
    for (let i = 0; i < plans.length; i++) {
      returnVals.push(
        /*eslint-disable*/
        <option value={plans[i]._id} key={i + 1}>
          {plans[i].description +
            " ($" +
            parseFloat(plans[i].amount) / 100 +
            "/" +
            plans[i].interval_period +
            ")"}
        </option>
        /*eslint-enable*/
      );
    }
    return returnVals;
  }

  renderAppleAmounts() {
    let returnVals = [];
    let amounts = config.appleProducts;
    returnVals.push(<option value={""} key={0} />);
    for (let i = 0; i < amounts.length; i++) {
      returnVals.push(
        <option value={amounts[i].id} key={i + 1}>
          {amounts[i].amount}
        </option>
      );
    }
    return returnVals;
  }

  onClickShowCustomPaymentOption() {
    this.setState({ showAddPaymentOption: !this.state.showAddPaymentOption });
  }

  handleAmountChange(event, index) {
    const target = event.target;
    const value = target.value;

    let selectedDates = this.state.selectedDates;
    selectedDates[index].amount = value;
    this.setState({ selectedDates: selectedDates });
  }

  handleDaysDayChange(event, index) {
    const target = event.target;
    const value = target.value;

    let selectedDays = this.state.selectedDays;
    selectedDays[index].day = value;
    this.setState({ selectedDays: selectedDays });
  }

  handleDaysAmountChange(event, index) {
    const target = event.target;
    const value = target.value;

    let selectedDays = this.state.selectedDays;
    selectedDays[index].amount = value;
    this.setState({ selectedDays: selectedDays });
  }

  onChangeDates(index, date) {
    let selectedDates = this.state.selectedDates;
    selectedDates[index].date = date;
    this.setState({ selectedDates: selectedDates });
  }

  removeDateFromList = (index) => {
    let dates = this.state.dates;
    let selectedDates = this.state.selectedDates;
    dates.splice(index, 1);
    selectedDates.splice(index, 1);
    this.setState({ dates: dates, selectedDates: selectedDates });
  };

  removeDayFromList = (index) => {
    let days = this.state.days;
    let selectedDays = this.state.selectedDays;
    days.splice(index, 1);
    selectedDays.splice(index, 1);
    this.setState({ days: days, selectedDays: selectedDays });
  };

  onClickAddAnotherDate() {
    var newDate = this.state.dates.length;
    this.setState({
      dates: this.state.dates.concat(newDate),
      selectedDates: this.state.selectedDates.concat({
        date: new Date(),
        amount: "0",
      }),
    });
  }

  onClickAddAnotherDay() {
    var newDay = this.state.days.length;
    this.setState({
      days: this.state.days.concat(newDay),
      selectedDays: this.state.selectedDays.concat({
        day: "0",
        amount: "0",
      }),
    });
  }

  onSelectCardType(item, id) {
    let types = [];
    if (Object.keys(item).length > 0 && item.constructor === Object) {
      Object.keys(item).forEach((key) => {
        types.push(item[key].value);
      });
    }
    this.setState({ cardTypes: types });
  }

  getTotalAmount() {
    let type = this.state.optionType;
    let amount = 0;
    if (type === "date") {
      amount += this.state.selectedDates
        .map((i) => parseFloat(i.amount))
        .reduce((a, b) => a + b);
    } else {
      amount += this.state.selectedDays
        .map((i) => parseFloat(i.amount))
        .reduce((a, b) => a + b);
    }
    return amount.toFixed(2);
  }

  onClickAddOption() {
    let option = {
      type: this.state.optionType,
      dates: this.state.selectedDates,
      days: this.state.selectedDays,
      regularCost: this.state.optionRegularCost,
      fallback: this.state.optionFallback,
    };
    let options = this.state.paymentOptions;

    let index = this.state.selectedOptionIndex;
    if (index > -1) {
      option.id = this.state.optionId;
      options[index] = option;
    } else {
      option.id = getUniqueId();
      options.push(option);
    }

    this.setState({
      paymentOptions: options,
      showAddPaymentOption: false,
      selectedDates: [{ date: new Date(), amount: "0" }],
      dates: [0],
      selectedDays: [{ day: "0", amount: "0" }],
      days: [0],
      optionId: "",
      showAddedOptions: true,
      selectedOptionIndex: -1,
      optionRegularCost: "",
      keyOptionType: Math.random(),
    });
  }

  onClickCancelOption(index) {
    this.setState({
      showAddPaymentOption: false,
      selectedDates: [{ date: new Date(), amount: "0" }],
      dates: [0],
      selectedDays: [{ day: "0", amount: "0" }],
      days: [0],
      optionId: "",
      showAddedOptions: true,
      selectedOptionIndex: -1,
      optionRegularCost: "",
      keyOptionType: Math.random(),
    });
  }

  onClickDeleteOption(index) {
    let options = this.state.paymentOptions;
    options.splice(index, 1);

    this.setState({ paymentOptions: options });
  }

  onClickEditOption(index) {
    let option = this.state.paymentOptions[index];
    let dates = Array.from(Array(option.dates.length).keys());
    let days = Array.from(Array(option.days.length).keys());

    this.setState({
      optionId: option.id,
      showAddPaymentOption: true,
      selectedOptionIndex: index,
      optionType: option.type,
      keyOptionType: Math.random(),
      selectedDates: option.dates,
      dates: dates,
      selectedDays: option.days,
      optionRegularCost: option.regularCost || "",
      days: days,
      showAddedOptions: false,
      optionFallback: option.fallback,
    });
  }

  onClickSetAsFallback(index) {
    let paymentOptions = this.state.paymentOptions;
    let option = paymentOptions[index];

    paymentOptions.forEach((element) => {
      element.fallback = element.id === option.id;
    });

    this.setState({ paymentOptions });
  }

  onChangePromoCode = (codes) => {
    this.setState({ pCodes: codes });
  };

  onSelectAffiliateUser = (user) => {
    this.setState({ selectedAffiliateUser: user });
  };

  onClickAddCustomSubmission() {
    let selectedAffiliateUser = this.state.selectedAffiliateUser.value;
    if (!selectedAffiliateUser) {
      showNotification("error", "Select affiliate user");
      return;
    }
    let affiliateCommissions = this.state.customCommissions;
    affiliateCommissions.push({
      userId: selectedAffiliateUser,
      type: this.state.affiliatecType,
      value: this.state.affiliatecValue,
      type2: this.state.affiliatecType2,
      value2: this.state.affiliatecValue2,
    });

    this.setState({
      customCommissions: affiliateCommissions,
      selectedAffiliateUser: {},
      affiliatecType: "",
      affiliatecValue: "",
      affiliatecType2: "",
      affiliatecValue2: "",
    });
  }

  onClickDeleteAffiliateOption(index) {
    let options = this.state.customCommissions;
    options.splice(index, 1);

    this.setState({ customCommissions: options });
  }

  renderCustomCommissions() {
    let returnVals = [];
    let options = this.state.customCommissions;

    for (let i = 0; i < options.length; i++) {
      const element = options[i];
      let user = this.state.affiliateUsers.filter(
        (d) => d.value === element.userId
      );
      let name = "";
      if (user.length > 0) {
        name = user[0].label;
      }
      returnVals.push(
        <div key={i}>
          {name} <br />
          level1 - {element.value} {element.type === "percentage" ? "%" : "$"}{" "}
          <br />
          level2 - {element.value2} {element.type2 === "percentage" ? "%" : "$"}
          <button
            onClick={this.onClickDeleteAffiliateOption.bind(this, i)}
            className="btn btn-danger btn-sm"
            style={{ float: "right" }}>
            Remove
          </button>
        </div>
      );
    }
    return returnVals;
  }

  renderPaymentOptions() {
    let returnVals = [];
    let options = this.state.paymentOptions;

    for (let i = 0; i < options.length; i++) {
      const element = options[i];
      const type =
        element.type === "date" ? "Payments By Date" : "Payments By Day";
      let amount = 0;
      let length = 0;
      if (element.type === "date") {
        length = element.dates.length;
        amount += element.dates
          .map((i) => parseFloat(i.amount))
          .reduce((a, b) => a + b);
      } else {
        length = element.days.length;
        amount += element.days
          .map((i) => parseFloat(i.amount))
          .reduce((a, b) => a + b);
      }
      amount = amount.toFixed(2);
      returnVals.push(
        <div key={i}>
          {length} {type} - [<b>${amount}</b>]
          <button
            onClick={this.onClickDeleteOption.bind(this, i)}
            className="btn btn-danger btn-sm"
            style={{ float: "right" }}>
            Remove
          </button>
          <button
            onClick={this.onClickEditOption.bind(this, i)}
            className="btn btn-default btn-sm margin-right-10"
            style={{ float: "right" }}>
            Edit
          </button>
          <button
            onClick={this.onClickSetAsFallback.bind(this, i)}
            className="btn btn-default btn-sm margin-right-10"
            style={{ float: "right" }}>
            {element.fallback ? "Fallback" : "Set as Fallback"}
          </button>
        </div>
      );
    }
    return returnVals;
  }

  onSortEndBumpItems = ({ oldIndex, newIndex }) => {
    let orderBumps = this.state.orderBumps;
    orderBumps = arrayMove(orderBumps, oldIndex, newIndex);

    this.setState({
      orderBumps: orderBumps,
    });
  };

  onPressRemoveBumpItem(e) {
    let index = e.target.dataset["value"];
    let orderBumps = this.state.orderBumps;
    orderBumps.splice(index, 1);
    this.setState({
      orderBumps: orderBumps,
    });
  }

  onPressEditBumpItem(e) {
    let index = e.target.dataset["value"];
    let orderBumps = this.state.orderBumps;

    let bumpItem = orderBumps[index];

    let bumpItemName = bumpItem.name;
    let bumpItemDescription = bumpItem.description;
    let bumpItemOrder = bumpItem.amount;
    let bumpOrderType = bumpItem.order_type || "single";
    let bumpItemPlan = bumpItem.order_plan || "";
    let tags = bumpItem.tag_list || [];

    let fTags = [];
    tags.forEach((element) => {
      let ffTags = this.state.systemTags.filter((d) => d._id === element);
      if (ffTags.length > 0) {
        fTags.push(ffTags[0]);
      }
    });

    let bumpAddTags = fTags;
    let bumpSelectedTags = fTags;

    let challengeList = bumpItem.product_list.filter((d) => {
      return d.challenge_id.length > 0;
    });

    let selectedBumpChallenges = [];
    challengeList.forEach((element) => {
      let challenge = {
        challenge: { name: element.name, _id: element.challenge_id },
        challengeType: element.type,
        challengeDate: element.start_date,
        challengeStartType: element.challenge_start_type || "",
      };
      selectedBumpChallenges.push(challenge);
    });

    let productList = bumpItem.product_list.filter((d) => {
      return d.product_id.length > 0;
    });

    let selectedBumpProducts = [];
    productList.forEach((element) => {
      let product = {
        name: element.name,
        productDate: element.start_date,
        currentDay: element.current_day || false,
      };
      selectedBumpProducts.push(product);
    });

    let addBumpChallengeStatus =
      selectedBumpChallenges.length !== 0 || selectedBumpProducts.length !== 0
        ? "addBumpChallenges"
        : "doNotAddBumpChallenges";

    this.setState({
      selectedBumpItemIndex: index,
      bumpItemName: bumpItemName,
      bumpItemDescription: bumpItemDescription,
      bumpItemOrder: bumpItemOrder,
      bumpAddTags: bumpAddTags,
      bumpSelectedTags: bumpSelectedTags,
      bumpItemPlan: bumpItemPlan,
      bumpOrderType: bumpOrderType,
      addBumpChallengeStatus: addBumpChallengeStatus,
      selectedBumpChallenges: selectedBumpChallenges,
      selectedBumpProducts: selectedBumpProducts,
      keyAddOrderBumpItem: Math.random(),
      keyAddTag: Math.random(),
      keyAddBmpChallengeStatus: Math.random(),
      bumpItemImage: bumpItem.image || "",
      keyBumpItemImage: Math.random(),
      bumpItemAllowQty: bumpItem.allow_qty,
      keyBumpItemAllowQty: Math.random(),
    });
  }

  onSelectNotiType(item, id) {
    let value = item[Object.keys(item)[0]].value;
    this.setState({ [id]: value });
  }

  toggleRegiFieldRequired = (check) => {
    this.setState({ isRegiFieldRequired: check });
  };

  toggleBumpItemAllowQty = (check) => {
    this.setState({ bumpItemAllowQty: check });
  };

  toggleGroupBumps = (check) => {
    this.setState({ isGroupBumps: check });
  };

  toggleSelectGroupBump = (id, check) => {
    const groupBumps = this.state.groupBumps;
    groupBumps[id] = check;
    this.setState({ groupBumps });
  };

  onSortEndFields = ({ oldIndex, newIndex }) => {
    let registrationFields = this.state.registrationFields;
    registrationFields = arrayMove(registrationFields, oldIndex, newIndex);

    this.setState({
      registrationFields: registrationFields,
    });
  };

  onPressRemoveField(e) {
    let index = e.target.dataset["value"];
    let registrationFields = this.state.registrationFields;
    registrationFields.splice(index, 1);
    this.setState({
      registrationFields: registrationFields,
    });
  }

  onPressEditField(e) {
    let index = e.target.dataset["value"];
    let registrationFields = this.state.registrationFields;

    let field = registrationFields[index];

    this.setState({
      selectedRegiFieldIndex: index,
      regiFieldLabel: field.label,
      isRegiFieldRequired: field.required,
      keyRegiFieldRequired: Math.random(),
    });
  }

  onClickCancelRegiField() {
    this.setState({
      selectedRegiFieldIndex: -1,
      regiFieldLabel: "",
      isRegiFieldRequired: false,
      keyRegiFieldRequired: Math.random(),
    });
  }

  onClickAddRegiField() {
    let registrationFields = this.state.registrationFields;
    if (this.state.regiFieldLabel.trim().length === 0) {
      showNotification("error", "Label is required", 4000);
      return;
    }

    let field = {
      label: this.state.regiFieldLabel,
      required: this.state.isRegiFieldRequired,
      type: this.state.regiFieldType,
      value: this.state.regiFieldDropdownValue,
      showEdit: true,
    };

    if (this.state.selectedRegiFieldIndex >= 0) {
      registrationFields[this.state.selectedRegiFieldIndex] = field;
    } else {
      registrationFields.push(field);
    }

    this.setState({
      registrationFields: registrationFields,
      selectedRegiFieldIndex: -1,
      regiFieldLabel: "",
      isRegiFieldRequired: false,
      regiFieldDropdownValue: "",
      regiFieldType: "text",
      keyRegiFieldRequired: Math.random(),
      keyInput: Math.random(),
    });
  }

  onChangeImage = (image) => {
    this.setState({ imgUrl: image });
  };

  onChangeBumpImage = (image) => {
    this.setState({ bumpItemImage: image });
  };

  renderCustomFieldSection() {
    return (
      <div className="card">
        <div className="container-fluid">
          <div className="gap20" />
          <h5 className="noMargin">Custom Fields</h5>
          <div className="gap20" />
          <div className="tabsHolder radioTabs">
            <RadioTag
              onCheckChange={this.onSelectNotiType.bind(this)}
              labelList={[
                { name: "Text", value: "text" },
                { name: "Dropdown", value: "dropdown" },
              ]}
              id={"regiFieldType"}
              selectedList={[this.state.regiFieldType]}
              selectType={"value"}
            />
          </div>
          <div className="row">
            <div className="col-md-6">
              <HelpTextDiv
                id="idEventLabel"
                label="Learn more about the Event Label"
                type="addEvent"
                isInline="false"
              />
              <div
                className={
                  this.state.regiFieldLabel !== undefined &&
                  this.state.regiFieldLabel !== ""
                    ? "mdInput mdFocussed"
                    : "mdInput"
                }>
                <label>Label</label>
                <input
                  key={this.state.keyInput}
                  type="text"
                  className="offsetDays"
                  name="regiFieldLabel"
                  defaultValue={this.state.regiFieldLabel}
                  onChange={(event) => this.handleInputChange(event)}
                />
              </div>
              <div className="gap20" />
            </div>
            <div className="col-md-3">
              <div className="gap20" />
              <div className="gap20" />
              <Checkbox
                label={"Required"}
                isChecked={this.state.isRegiFieldRequired}
                handleCheckboxChange={this.toggleRegiFieldRequired}
                key={this.state.keyRegiFieldRequired}
              />
            </div>
          </div>

          {this.state.regiFieldType === "dropdown" && (
            <div className="row">
              <div className="col-md-6">
                <HelpTextDiv
                  id="idEventLabel"
                  label="Learn more about the Event Label"
                  type="addEvent"
                  isInline="false"
                />
                <div
                  className={
                    this.state.regiFieldDropdownValue !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Dropdown Values</label>
                  <textarea
                    type="text"
                    className="offsetDays"
                    name="regiFieldDropdownValue"
                    defaultValue={this.state.regiFieldDropdownValue}
                    onBlur={(event) => this.handleInputChange(event)}
                  />
                </div>
                <div className="gap20" />
              </div>
            </div>
          )}

          <div className="text-right">
            {this.state.selectedRegiFieldIndex >= 0 && (
              <button
                className="btn btn-default margin-right-10"
                onClick={() => this.onClickCancelRegiField()}>
                Cancel
              </button>
            )}
            <button
              className="btn btn-primary addQuestionButton"
              onClick={() => this.onClickAddRegiField()}>
              {this.state.selectedRegiFieldIndex >= 0
                ? "Update Field"
                : "Add Field"}
            </button>
          </div>
          <div className="gap20" />
          <SortableFormFieldList
            distance={10}
            items={this.state.registrationFields}
            onSortEnd={this.onSortEndFields}
            onPressEdit={this.onPressEditField.bind(this)}
            onPressRemove={this.onPressRemoveField.bind(this)}
          />
          <div className="gap20" />
        </div>
      </div>
    );
  }

  onClickAddBumpItem() {
    if (this.state.bumpItemName.trim().length === 0) {
      showNotification("error", "Bump Product name is required", 4000);
      return;
    }

    if (this.state.bumpItemDescription.trim().length === 0) {
      showNotification("error", "Bump Product description is required", 4000);
      return;
    }

    if (this.state.bumpOrderType === "single") {
      if (this.state.bumpItemOrder.trim().length === 0) {
        showNotification("error", "Bump Order amount is required", 4000);
        return;
      }
    }

    if (this.state.bumpOrderType === "subscription") {
      if (this.state.bumpItemPlan.trim().length === 0) {
        showNotification("error", "Bump Order Plan is required", 4000);
        return;
      }
    }

    let productList = [];

    let bumpTagList = this.state.bumpAddTags
      .filter((d) => {
        return d._id !== null;
      })
      .map((i) => i._id);

    if (this.state.addBumpChallengeStatus === "addBumpChallenges") {
      if (
        this.state.selectedBumpChallenges.length === 0 &&
        this.state.selectedBumpProducts.length === 0
      ) {
        showNotification(
          "error",
          "Please add a challenge or select 'Do Not Add Any Challenges'",
          4000
        );
        return;
      }

      /*eslint-disable*/
      this.state.selectedBumpChallenges.forEach((element) => {
        productList.push({
          challenge_id: element.challenge._id,
          product_id: "",
          name: element.challenge.name,
          start_date: element.challengeDate,
          type: element.challengeType,
          challenge_start_type: element.challengeStartType,
        });
      });

      this.state.selectedBumpProducts.forEach((element) => {
        productList.push({
          challenge_id: "",
          product_id: "Y",
          name: element.name,
          current_day: element.currentDay,
          start_date: element.productDate,
          type: "product",
        });
      });
    }

    let orderBump = {
      id: getUniqueId(),
      name: this.state.bumpItemName,
      description: this.state.bumpItemDescription,
      amount: this.state.bumpItemOrder,
      order_type: this.state.bumpOrderType,
      order_plan: this.state.bumpItemPlan,
      product_list: productList,
      tag_list: bumpTagList,
      image: this.state.bumpItemImage,
      allow_qty: this.state.bumpItemAllowQty,
    };
    let orderBumps = this.state.orderBumps;

    if (this.state.selectedBumpItemIndex > -1) {
      orderBumps[this.state.selectedBumpItemIndex] = orderBump;
    } else {
      orderBumps.push(orderBump);
    }

    this.setState({ orderBumps, selectedBumpItemIndex: -1 });
  }

  onChangeAgreeValue = (html, json) => {
    this.setState({ agreeTerms: html, agreeTermsObject: json });
  };

  renderCustomPaymentOption() {
    return (
      <div>
        <div className="gap10" />
        <p>
          Note: Custom Billing is only available with Stripe. Customers can not
          use paypal to checkout with a checkout page using custom.
        </p>
        <button
          className="btn btn-default btn-sm"
          onClick={this.onClickShowCustomPaymentOption.bind(this)}>
          Add Payment Option
        </button>

        {this.state.showAddPaymentOption && (
          <>
            <div className="gap20" />
            <div className="card">
              <div className="list-group list-group-flush">
                <div className="list-group-item">
                  <div className="radioBHolder">
                    <div className="havingHelpText">
                      <p>Option Type</p>
                    </div>
                    <div className="tabsHolder radioTabs">
                      <RadioTag
                        onCheckChange={this.onSelectOptionType.bind(this)}
                        labelList={[
                          { name: "Payment By Days", value: "days" },
                          { name: "Payment By Date", value: "date" },
                        ]}
                        id={"optionType"}
                        selectedList={[this.state.optionType]}
                        selectType={"value"}
                        key={this.state.keyOptionType}
                      />
                    </div>
                  </div>
                  {this.state.optionType === "date" ? (
                    <div>
                      <div className="gap20" />
                      {this.state.dates.map((i, index) => (
                        <div className="row" key={i}>
                          <div className="col-sm-4" key={index}>
                            <label className="inputFakeLabelFocussed">
                              Select Date {index + 1}
                            </label>
                            <div>
                              <DatePicker
                                minDate={new Date()}
                                value={this.state.selectedDates[index].date}
                                onChange={this.onChangeDates.bind(this, index)}
                              />
                            </div>
                            <div className="gap20" />
                          </div>
                          <div className="col-sm-4">
                            <div
                              className={
                                this.state.selectedDates[index].amount !== ""
                                  ? "mdInput mdFocussed"
                                  : "mdInput"
                              }>
                              <label>
                                Billing Amount {index + 1}
                                ($)
                              </label>
                              <input
                                type="number"
                                name={"amount" + index + 1}
                                value={this.state.selectedDates[index].amount}
                                onChange={(event) =>
                                  this.handleAmountChange(event, index)
                                }
                              />
                            </div>
                            <div className="gap20" />
                          </div>
                          <div className="col-sm-4">
                            <div className="gap30" />
                            {index !== 0 && (
                              <div
                                className="btn"
                                onClick={this.removeDateFromList.bind(
                                  this,
                                  index
                                )}>
                                <span style={{ color: "red" }}>
                                  Remove Date
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                      <div className="text-left">
                        <div
                          className="btn btn-default btn-sm"
                          onClick={() => this.onClickAddAnotherDate()}>
                          Add Another Date
                        </div>
                      </div>
                      <div className="gap20" />
                      <div className="text-left">
                        <p>Total Amount: ${this.getTotalAmount()}</p>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className="gap20" />
                      {this.state.days.map((i, index) => (
                        <div className="row" key={i}>
                          <div className="col-sm-4" key={index}>
                            <div
                              className={
                                this.state.selectedDays[index].date !== ""
                                  ? "mdInput mdFocussed"
                                  : "mdInput"
                              }>
                              <label>Days From Purchase {index + 1}</label>{" "}
                              <input
                                type="number"
                                name={"amount" + index + 1}
                                value={this.state.selectedDays[index].day}
                                onChange={(event) =>
                                  this.handleDaysDayChange(event, index)
                                }
                              />
                            </div>
                            <div className="gap20" />
                          </div>
                          <div className="col-sm-4">
                            <div
                              className={
                                this.state.selectedDays[index].amount !== ""
                                  ? "mdInput mdFocussed"
                                  : "mdInput"
                              }>
                              <label>
                                Billing Amount {index + 1}
                                ($)
                              </label>
                              <input
                                type="number"
                                name={"amount" + index + 1}
                                value={this.state.selectedDays[index].amount}
                                onChange={(event) =>
                                  this.handleDaysAmountChange(event, index)
                                }
                              />
                            </div>
                            <div className="gap20" />
                          </div>
                          <div className="col-sm-4">
                            <div className="gap30" />
                            {index !== 0 && (
                              <div
                                className="btn"
                                onClick={this.removeDayFromList.bind(
                                  this,
                                  index
                                )}>
                                <span style={{ color: "red" }}>Remove Day</span>
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                      <div className="text-left">
                        <div
                          className="btn btn-default btn-sm"
                          onClick={() => this.onClickAddAnotherDay()}>
                          Add Another Day
                        </div>
                      </div>
                      <div className="gap20" />
                      <div className="text-left">
                        <p>Total Amount: ${this.getTotalAmount()}</p>
                      </div>
                    </div>
                  )}
                  <div className="row">
                    <div className="col-sm-4">
                      <div
                        className={
                          this.state.optionRegularCost !== ""
                            ? "mdInput mdFocussed"
                            : "mdInput"
                        }>
                        <label>Regular Cost</label>
                        <input
                          type="text"
                          name="optionRegularCost"
                          value={this.state.optionRegularCost}
                          onChange={(event) => this.handleInputChange(event)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="gap20" />

                  <div className="text-right">
                    {this.state.selectedOptionIndex > -1 && (
                      <button
                        className="btn btn-default margin-right-10"
                        onClick={this.onClickCancelOption.bind(this)}>
                        Cancel
                      </button>
                    )}
                    <button
                      className="btn btn-primary"
                      onClick={this.onClickAddOption.bind(this)}>
                      {this.state.selectedOptionIndex > -1
                        ? "Update Option"
                        : "Add Option"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {this.state.paymentOptions.length > 0 &&
          this.state.showAddedOptions && (
            <>
              <div className="gap20" />
              <div className="card">
                <div className="container-fluid">
                  <div className="gap20" />
                  <h5 className="noMargin">Added Options</h5>
                  <div className="gap20" />
                  <div className="addedRule">{this.renderPaymentOptions()}</div>
                  <div className="gap20" />
                </div>
              </div>
            </>
          )}
      </div>
    );
  }

  onChangeParagaphValue = (html, json, text) => {
    if (text.trim().length === 0) {
      html = "";
      json = {};
    }
    this.setState({ customText: html, customTextJson: json });
  };

  renderCustomText() {
    let { isCustomText, isCustomTextJson } = this.state;

    if (isCustomText && !isCustomTextJson) {
      return (
        <QuillEditor
          value={this.state.customText}
          theme="bubble"
          label="Additional Information"
          focused={this.state.customText.length > 0 ? true : false}
          type="mdInput"
          onChangeValue={this.onChangeCustomText.bind(this)}
        />
      );
    } else if ((!isCustomText && !isCustomTextJson) || isCustomTextJson) {
      return (
        <div className="add-task-comment">
          <DraftEditor
            key={this.state.keyCustomText}
            cType="task"
            defaultText=""
            textAlign={"left"}
            lineHeight={"inherit"}
            html={this.state.customText}
            json={this.state.customTextJson}
            onChange={this.onChangeParagaphValue.bind(this)}
          />
        </div>
      );
    }
  }

  render() {
    if (this.state.loadingPage) {
      return <div id="loader"></div>;
    }

    return (
      <Onboarding history={this.props.history}>
        <div>
          <div className="container-fluid">
            <TitleBox
              title={
                this.state.checkoutItemId === 0
                  ? "Add New Checkout Item"
                  : this.state.type === "clone"
                  ? "Add New Checkout Item"
                  : "Edit Checkout Item"
              }
              showBackBtn={this.state.checkoutItemId !== 0}
            />
            <div className="gap20" />
            <div className="card">
              <div className="container-fluid">
                <div className="gap20" />
                <div className="text-right">
                  <button
                    className={"btn btn-info removeFocusDecoration"}
                    onClick={() =>
                      this.setState({
                        showAdvancedOptionsDetails:
                          !this.state.showAdvancedOptionsDetails,
                      })
                    }>
                    {this.state.showAdvancedOptionsDetails
                      ? "Hide Advanced Options"
                      : "Show Advanced Options"}
                  </button>
                </div>

                <div className="havingHelpText">
                  <StatusSwitch
                    key={this.state.keySwitch}
                    isActive={this.state.isActive}
                    onChange={this.toggleCheckbox.bind(this)}
                  />
                </div>
                <HelpTextDiv
                  id="idCheckoutItemStatus"
                  label="Learn more about the Status"
                  type="addCheckoutItem"
                  isInline="true"
                />
                <div className="gap10" />

                <HelpTextDiv
                  id="idCheckoutItemName"
                  label="Learn more about the Name"
                  type="addCheckoutItem"
                  isInline="false"
                />
                <div
                  className={
                    this.state.productName !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Name</label>
                  <input
                    type="text"
                    name="productName"
                    // value={this.state.productName}
                    defaultValue={this.state.productName}
                    // onChange={(event) => this.handleInputChange(event)}
                    onBlur={(event) => this.handleInputChange(event)}
                  />
                </div>
                <div className="gap20" />

                {/* <HelpTextDiv
                  id="idCheckoutItemDescription"
                  label="Learn more about the Description"
                  type="addCheckoutItem"
                  isInline="false"
                />
                <div
                  className={
                    this.state.productDescription !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Description</label>
                  <input
                    type="text"
                    name="productDescription"
                    // value={this.state.productDescription}
                    defaultValue={this.state.productDescription}
                    // onChange={(event) => this.handleInputChange(event)}
                    onBlur={(event) => this.handleInputChange(event)}
                  />
                </div> */}

                <div className="havingHelpText">
                  <label>Description</label>
                </div>
                <div className="add-task-comment">
                  <DraftEditor
                    key={this.state.keyDraftEditor}
                    cType="task"
                    defaultText="Description"
                    textAlign={"left"}
                    lineHeight={"inherit"}
                    html={this.state.productDescription}
                    json={this.state.descriptionJson}
                    onChange={this.onChangeDescription.bind(this)}
                  />
                </div>
                <div className="gap20" />

                <SelectAddImage
                  type="checkout-image"
                  label="Checkout Image"
                  onChangeImage={this.onChangeImage}
                  image={this.state.imgUrl}
                />
                <div className="gap20" />

                <HelpTextDiv
                  id="idCheckoutItemCheckoutId"
                  label="Learn more about the Check Out ID"
                  type="addCheckoutItem"
                  isInline="false"
                />
                {this.state.showAdvancedOptionsDetails && (
                  <>
                    <div
                      className={
                        this.state.checkoutId !== ""
                          ? "mdInput mdFocussed"
                          : "mdInput"
                      }>
                      <label>Checkout Id</label>
                      <input
                        type="text"
                        name="checkoutId"
                        value={this.state.checkoutId}
                        onChange={(event) => this.handleInputChange(event)}
                      />
                    </div>
                    <div className="gap20" />
                  </>
                )}
                <HelpTextDiv
                  id="idCheckoutItemTag"
                  label="Learn more about the Tag"
                  type="addCheckoutItem"
                  isInline="false"
                />
                <TagSelect
                  id={"add-checkout-item-add-tag-id"}
                  key={this.state.keyAddTag}
                  selectedValues={this.state.selectedTags}
                  searchKey={"name"}
                  selectKey={"name"}
                  btnText={"Select Tag"}
                  lblText={"Select Tag"}
                  data={this.state.systemTags}
                  onChange={this.onChangeAddTags}
                  isClickable={true}
                  reload={() => {
                    this.getSystemTags();
                  }}
                />

                <div className="gap20" />

                <div className="havingHelpText">
                  <Checkbox
                    label={"Only Valid Until Specific Date"}
                    isChecked={this.state.isValidTillDate}
                    handleCheckboxChange={this.toggleValidTillDate}
                    key={this.state.keyValidTillDate}
                  />
                </div>
                <HelpTextDiv
                  id="idCheckoutItemValidUntilSpecificDate"
                  label="Learn more about the Valid Until Specific Date"
                  type="addCheckoutItem"
                  isInline="true"
                />
                <div className="gap10" />
                <VelocityTransitionGroup
                  enter={{ animation: "slideDown" }}
                  leave={{ animation: "slideUp" }}>
                  {this.state.isValidTillDate && (
                    <div className="card">
                      <div className="container-fluid">
                        <div className="gap20" />
                        <div className="radioBHolder autEmailDateTime">
                          <div className="row">
                            <div className="col-sm-3">
                              <HelpTextDiv
                                id="idCheckoutItemDate"
                                label="Learn more about the Date"
                                type="addCheckoutItem"
                                isInline="false"
                              />
                              <label className="inputFakeLabelFocussed">
                                Select Date
                              </label>
                              <div>
                                <DateTimePicker
                                  renderNumbers
                                  use12Hours
                                  onChange={this.onChangeValidTillDate.bind(
                                    this
                                  )}
                                  value={this.state.validTillDate}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="gap10" />

                        <HelpTextDiv
                          id="idCheckoutItemFallback"
                          label="Learn more about the Fallback Item"
                          type="addCheckoutItem"
                          isInline="false"
                        />
                        <TypeableSelect
                          key={this.state.keyCheckoutItems}
                          name={"checkoutItemId"}
                          placeholder={"Select Fallback Item"}
                          selected={this.state.selectedCheckoutItem}
                          onSelect={this.onSelectCheckoutItem}
                          options={this.state.checkoutItems}
                        />
                        <div className="gap20" />
                      </div>
                    </div>
                  )}
                </VelocityTransitionGroup>
                <div className="gap10" />
                {this.state.stripeAccounts.length > 0 && (
                  <>
                    <div className="radioBHolder">
                      <div className="havingHelpText">
                        <p>Stripe Account</p>
                      </div>
                      <div className="tabsHolder radioTabs">
                        <RadioTag
                          onCheckChange={this.onSelectAddChallengeStatus.bind(
                            this
                          )}
                          labelList={this.state.stripeAccounts.map((i) => {
                            return { name: i.name, value: i.id };
                          })}
                          id={"stripeAccount"}
                          selectedList={[this.state.stripeAccount]}
                          selectType={"value"}
                        />
                      </div>
                    </div>
                    <div className="gap20" />
                  </>
                )}

                <div className="radioBHolder">
                  <div className="havingHelpText">
                    <p>Order Type</p>
                  </div>
                  <HelpTextDiv
                    id="idCheckoutItemOrderType"
                    label="Learn more about the Order Type"
                    type="addCheckoutItem"
                    isInline="true"
                  />
                  <div className="tabsHolder radioTabs">
                    <RadioTag
                      key={this.state.keyAddOrderBumpItem}
                      onCheckChange={this.onSelectOrderType.bind(this)}
                      labelList={[
                        { name: "Single", value: "single" },
                        { name: "Subscription", value: "subscription" },
                      ]}
                      id={"orderType"}
                      selectedList={[this.state.orderType]}
                      selectType={"value"}
                    />
                  </div>
                </div>
                <div className="gap10" />
                <VelocityTransitionGroup
                  enter={{ animation: "slideDown" }}
                  leave={{ animation: "slideUp" }}>
                  {this.state.orderType === "single" && (
                    <div>
                      <div className="gap10" />
                      <div className="radioBHolder">
                        <div className="havingHelpText">
                          <p>Payment Type</p>
                        </div>
                        <div className="tabsHolder radioTabs">
                          <RadioTag
                            onCheckChange={this.onSelectPaymentType.bind(this)}
                            labelList={[
                              { name: "Fixed", value: "fixed" },
                              { name: "Custom", value: "custom" },
                            ]}
                            id={"paymentType"}
                            selectedList={[this.state.paymentType]}
                            selectType={"value"}
                          />
                        </div>
                      </div>
                      {this.state.paymentType === "fixed" ? (
                        <>
                          <div className="gap20" />
                          <Checkbox
                            label={"Allow Products from Shopping Cart"}
                            isChecked={this.state.allowCart}
                            handleCheckboxChange={this.toggleAllowCart}
                            key={this.state.keyAllowCart}
                          />
                          <div className="gap20" />

                          <div
                            className={
                              this.state.orderAmount !== ""
                                ? "mdInput mdFocussed"
                                : "mdInput"
                            }>
                            <label>Order Amount</label>
                            <input
                              type="text"
                              name="orderAmount"
                              value={this.state.orderAmount}
                              onChange={(event) =>
                                this.handleInputChange(event)
                              }
                            />
                          </div>

                          <HelpTextDiv
                            id="idCheckoutItemRegularCost"
                            label="Learn more about the Regular Cost"
                            type="addCheckoutItem"
                            isInline="false"
                            topMargin="15"
                          />
                          <div
                            className={
                              this.state.regularCost !== ""
                                ? "mdInput mdFocussed"
                                : "mdInput"
                            }>
                            <label>Regular Cost</label>
                            <input
                              type="text"
                              name="regularCost"
                              value={this.state.regularCost}
                              onChange={(event) =>
                                this.handleInputChange(event)
                              }
                            />
                          </div>
                          <div className="gap20" />
                          <Checkbox
                            label={"Enable Custom Amount"}
                            isChecked={this.state.isCustomAmount}
                            handleCheckboxChange={this.toggleCustomAmount}
                            key={this.state.keyCustomAmount}
                          />
                          {this.state.isCustomAmount && (
                            <>
                              <div className="gap20" />
                              <div
                                className={
                                  this.state.customAmountNotes !== ""
                                    ? "mdInput mdFocussed"
                                    : "mdInput"
                                }>
                                <label>Custom Amount Notes</label>
                                <input
                                  type="text"
                                  name="customAmountNotes"
                                  value={this.state.customAmountNotes}
                                  onChange={(event) =>
                                    this.handleInputChange(event)
                                  }
                                />
                              </div>
                            </>
                          )}

                          <div className="gap20" />
                          <label>
                            What is the cost of this for Apple in app purchase
                            (note there will be a 50% charge associated with
                            this)
                          </label>
                          <div
                            className={
                              this.state.appleAmount !== ""
                                ? "mdInput mdFocussed"
                                : "mdInput"
                            }>
                            <label>Select Apple Amount</label>
                            <select
                              value={this.state.appleAmount}
                              className="billingDate"
                              onChange={this.onChangeAppleAmount.bind(this)}>
                              {this.renderAppleAmounts()}
                            </select>
                          </div>
                        </>
                      ) : (
                        this.renderCustomPaymentOption()
                      )}
                    </div>
                  )}
                </VelocityTransitionGroup>
                <VelocityTransitionGroup
                  enter={{ animation: "slideDown" }}
                  leave={{ animation: "slideUp" }}>
                  {this.state.orderType === "subscription" && (
                    <HelpTextDiv
                      id="idCheckoutItemSubscriptionPlan"
                      label="Learn more about the Subscription Plan"
                      type="addCheckoutItem"
                      isInline="false"
                    />
                  )}
                  {this.state.orderType === "subscription" && (
                    <div
                      className={
                        this.state.orderPlan !== ""
                          ? "mdInput mdFocussed"
                          : "mdInput"
                      }>
                      <label>Select Plan</label>
                      <select
                        value={this.state.orderPlan}
                        className="billingDate"
                        onChange={this.onChangePlan.bind(this)}>
                        {this.renderPlans()}
                      </select>
                    </div>
                  )}
                  <div className="gap10" />
                </VelocityTransitionGroup>
                <div className="gap10" />

                <Checkbox
                  label={"Promo Code Available"}
                  isChecked={this.state.promoCodeAvailable}
                  handleCheckboxChange={this.togglePromoCodeAvailable}
                  key={this.state.keyPromoCodeAvailable}
                />

                <div className="gap20" />

                {this.state.promoCodeAvailable && (
                  <>
                    <div className="tabsHolder radioTabs">
                      <RadioTag
                        onCheckChange={this.onSelectPromoType.bind(this)}
                        labelList={[
                          { name: "Accept All Promo Codes", value: "all" },
                          { name: "Add Promo Codes", value: "add" },
                        ]}
                        id={"promoType"}
                        selectedList={[this.state.promoType]}
                        selectType={"value"}
                      />
                    </div>
                    <div className="gap10" />
                    {this.state.promoType === "add" && (
                      <TagSelect
                        id={"add-checkout-item-promo-type"}
                        key={this.state.keyPromoCode}
                        selectedValues={this.state.selectedPromoCodes}
                        searchKey={"title"}
                        selectKey={"title"}
                        lblText={"Select Promo Code"}
                        data={this.state.promoCodes}
                        onChange={this.onChangePromoCode}
                        label={"Promo Codes"}
                        showInfo={false}
                        reload={() => {
                          this.getSystemTags();
                        }}
                      />
                    )}
                  </>
                )}

                <div className="gap10" />
                <div className="radioBHolder">
                  <div className="havingHelpText">
                    <p>Payment Types</p>
                  </div>
                  <div className="tabsHolder radioTabs">
                    <RadioTag
                      onCheckChange={this.onSelectCardType.bind(this)}
                      labelList={[
                        { name: "Credit", value: "credit" },
                        { name: "ACH", value: "ach" },
                        { name: "Affirm", value: "affirm" },
                        { name: "Klarna", value: "klarna" },
                      ]}
                      id={"cardTypes"}
                      selectedList={this.state.cardTypes}
                      selectType={"value"}
                      type={"multiple"}
                    />
                  </div>
                </div>

                <div className="gap20" />

                {this.state.showPaymentOptions === true && (
                  <div>
                    <div className="havingHelpText">
                      <Checkbox
                        label={"Allow Payment Options"}
                        isChecked={this.state.allowPaymentOptions}
                        handleCheckboxChange={this.toggleAllowPaymentOptions}
                        key={this.state.keyAllowPaymentOptions}
                      />
                    </div>
                    <HelpTextDiv
                      id="idCheckoutItemAllowPaymentOptions"
                      label="Learn more about the Allow Payment Options"
                      type="addCheckoutItem"
                      isInline="true"
                    />
                    <div className="gap20" />
                  </div>
                )}

                <VelocityTransitionGroup
                  enter={{ animation: "slideDown" }}
                  leave={{ animation: "slideUp" }}>
                  {this.state.allowPaymentOptions && (
                    <div className="card">
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-sm-4">
                            <HelpTextDiv
                              id="idCheckoutItemMaxNumOfPayments"
                              label="Learn more about the Max # of Payments"
                              type="addCheckoutItem"
                              isInline="false"
                              topMargin="10"
                            />
                            <div
                              className={
                                this.state.maxPayments !== ""
                                  ? "mdInput mdFocussed"
                                  : "mdInput"
                              }>
                              <label>Maximum # of payments</label>
                              <input
                                type="text"
                                name="maxPayments"
                                value={this.state.maxPayments}
                                onChange={(event) =>
                                  this.handleInputChange(event)
                                }
                              />
                            </div>
                            <div className="gap20" />
                          </div>
                          <div className="col-sm-4">
                            <HelpTextDiv
                              id="idCheckoutItemPaymentPeriod"
                              label="Learn more about the Max Days Between Payment"
                              type="addCheckoutItem"
                              isInline="false"
                              topMargin="10"
                            />
                            <div
                              className={
                                this.state.paidDays !== ""
                                  ? "mdInput mdFocussed"
                                  : "mdInput"
                              }>
                              <label>
                                Max days between payments(from original order)
                              </label>
                              <input
                                type="text"
                                name="paidDays"
                                value={this.state.paidDays}
                                onChange={(event) =>
                                  this.handleInputChange(event)
                                }
                              />
                            </div>
                            <div className="gap20" />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </VelocityTransitionGroup>

                <div className="gap10" />
                <div className="radioBHolder">
                  <div className="tabsHolder radioTabs">
                    <RadioTag
                      onCheckChange={this.onSelectAddChallengeStatus.bind(this)}
                      labelList={[
                        { name: "Add Challenges", value: "addChallenges" },
                        {
                          name: "Do Not Add Any Challenges",
                          value: "doNotAddChallenges",
                        },
                      ]}
                      id={"addChallengeStatus"}
                      selectedList={[this.state.addChallengeStatus]}
                      selectType={"value"}
                    />
                  </div>
                </div>
                <div className="gap10" />
                <VelocityTransitionGroup
                  enter={{ animation: "slideDown" }}
                  leave={{ animation: "slideUp" }}>
                  {this.state.addChallengeStatus === "addChallenges" && (
                    <div>
                      <SelectChallenge
                        selectedChallenges={this.state.selectedMainChallenges}
                        challenges={this.state.challenges}
                        key={this.state.keyChallenges}
                        onChange={this.onChangeMainChallenges.bind(this)}
                      />
                      {/* {isFS() && (
                        <SelectProduct
                          selectedProducts={this.state.selectedMainProducts}
                          key={this.state.keyProducts}
                          onChange={this.onChangeMainProducts.bind(this)}
                        />
                      )} */}

                      <SelectChallengeGroup
                        key={this.state.keyChallengeGroups}
                        selectedChallengeGroups={
                          this.state.selectedChallengeGroups
                        }
                        onChange={this.onChangeChallengeGroups.bind(this)}
                      />
                    </div>
                  )}
                </VelocityTransitionGroup>

                <Checkbox
                  label={"Collect Shipping Details"}
                  isChecked={this.state.showShipping}
                  handleCheckboxChange={this.toggleShowShipping}
                  key={this.state.keyShowShipping}
                />
                <div className="gap20" />
                <div className="havingHelpText">
                  <Checkbox
                    label={"Add Order Bump Item"}
                    isChecked={this.state.addOrderBumpItem}
                    handleCheckboxChange={this.toggleOrderBumpItem}
                    key={this.state.keyAddOrderBumpItem}
                  />
                </div>
                <HelpTextDiv
                  id="idCheckoutItemOrderBumpItem"
                  label="Learn more about the Order Bump Item"
                  type="addCheckoutItem"
                  isInline="true"
                />
                <div className="gap20" />
                <Checkbox
                  label={"Customize Thank You Page"}
                  isChecked={this.state.isThankYouCustom}
                  handleCheckboxChange={this.toggleThankYouItem}
                  key={this.state.keyThankYouItem}
                />
                <div className="gap20" />
                {this.state.isThankYouCustom && (
                  <>
                    <div className="tabsHolder radioTabs">
                      <RadioTag
                        onCheckChange={this.onSelectNotiType.bind(this)}
                        labelList={[
                          { name: "Customize Page", value: "page" },
                          { name: "Send to Custom Url", value: "url" },
                        ]}
                        id={"thankYouType"}
                        selectedList={[this.state.thankYouType]}
                        selectType={"value"}
                      />
                    </div>
                    <div className="gap10" />
                    {this.state.thankYouType === "url" && (
                      <div className="row">
                        <div className="col-sm-12">
                          <div
                            className={
                              this.state.thankYouUrl !== ""
                                ? "mdInput mdFocussed"
                                : "mdInput"
                            }>
                            <label>Thank You Page URL</label>
                            <input
                              type="text"
                              name="thankYouUrl"
                              value={this.state.thankYouUrl}
                              onChange={(event) =>
                                this.handleInputChange(event)
                              }
                            />
                          </div>
                          <div className="gap20" />
                        </div>
                      </div>
                    )}
                    {this.state.thankYouType === "page" && (
                      <PageContent
                        key={this.state.keyThankYouContent}
                        displayMode={"web"}
                        title={this.state.checkoutId}
                        screen={"add-checkout-item"}
                        html={this.state.thankYouHtml}
                        content={this.state.thankYouObject}
                        onChangeData={this.onChangeContent.bind(this)}
                        pageName={"addCheckoutItem"}
                        onClickAlert={this.onClickCreateCheckoutItem.bind(this)}
                        editorType={this.state.editorType}
                        previewType={"mp-preview"}
                        draftId={this.state.draftId}
                        draftImage={this.state.draftImage}
                      />
                    )}
                  </>
                )}
                <Checkbox
                  label={"Enable Affiliate Commissions"}
                  isChecked={this.state.isAffiliateCommissions}
                  handleCheckboxChange={this.toggleAffilicateCommissions}
                  key={this.state.keyAffiliateCommissions}
                />
                {this.state.isAffiliateCommissions && (
                  <>
                    <div className="gap20" />
                    <Checkbox
                      label={
                        "Do not show this product inside members affiliate links"
                      }
                      isChecked={this.state.hideAffiliateLink}
                      handleCheckboxChange={this.toggleHideAffiliateLink}
                      key={this.state.keyHideAffiliateLink}
                    />
                  </>
                )}
                {((this.state.orderType === "single" &&
                  this.state.paymentType === "fixed") ||
                  this.state.orderType === "subscription") && (
                  <>
                    <div className="gap20" />
                    <Checkbox
                      label={"Allow active user to upgrade to this product"}
                      isChecked={this.state.isUpgrade}
                      handleCheckboxChange={this.toggleUpgrade}
                      key={this.state.keyUpgrade}
                    />
                    <div className="gap20" />
                  </>
                )}
                <Checkbox
                  label={"Do not collect billing details"}
                  isChecked={this.state.doNotCollectBilling}
                  handleCheckboxChange={this.toggleDoNotCollectBilling}
                  key={this.state.keyDoNotCollectBilling}
                />
                <div className="gap20" />
                <Checkbox
                  label={
                    "Add checkbox that user must agree to prior to being able to purchase"
                  }
                  isChecked={this.state.isAgreeTerms}
                  handleCheckboxChange={this.toggleTermsAgree}
                  key={this.state.keyAgreeTerms}
                />
                {this.state.isAgreeTerms && (
                  <>
                    <div className="gap10" />
                    <div className="inputFakeLabelFocussed">
                      <p>Enter agree terms text</p>
                    </div>

                    <div className="add-task-comment">
                      <DraftEditor
                        key={this.state.keyEditor}
                        cType="task"
                        defaultText={this.state.agreeTerms}
                        lineHeight={"inherit"}
                        textAlign={"left"}
                        html={this.state.agreeTerms}
                        json={this.state.agreeTermsObject}
                        onChange={this.onChangeAgreeValue.bind(this)}
                      />
                    </div>
                  </>
                )}

                {this.state.checkoutItemId !== 0 && this.state.isUpgrade && (
                  <div>
                    <div className="gap10" />

                    <CopyToClipboard
                      title="Copy Checkout Url"
                      text={`https://${window.location.hostname}/member/checkout-upgrade?c=${this.state.checkoutItemId}`}
                      onCopy={() =>
                        showNotification(
                          "success",
                          "Link copied to clipboard",
                          5000
                        )
                      }>
                      <p className="link-share">
                        {`https://${window.location.hostname}/member/checkout-upgrade?c=${this.state.checkoutItemId}`}
                      </p>
                    </CopyToClipboard>
                  </div>
                )}
                <div className="gap20" />
                {isDashboardSite() && (
                  <>
                    <Checkbox
                      label={"Create member pages account"}
                      isChecked={this.state.createMemberPagesAccount}
                      handleCheckboxChange={this.toggleMemberPagesAccount}
                      key={this.state.keyMemberPagesAccount}
                    />
                    <div className="gap20" />
                  </>
                )}

                {this.state.isAffiliateCommissions && (
                  <div className="card">
                    <div className="gap20" />
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-md-6">
                          <div
                            className={
                              this.state.affiliateDescription !== ""
                                ? "mdInput mdFocussed"
                                : "mdInput"
                            }>
                            <label>Description</label>
                            <input
                              type="text"
                              name="affiliateDescription"
                              value={this.state.affiliateDescription}
                              onChange={(event) =>
                                this.handleInputChange(event)
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="gap20" />
                      <div className="gap20" />
                      <div className="row">
                        <div className="col-md-6">
                          <div className="card">
                            <div className="container-fluid">
                              <div className="gap10" />
                              <p>Level 1 Commission</p>
                              <div className="radioBHolder">
                                <div className="havingHelpText">
                                  <p>Type</p>
                                </div>
                                <div className="tabsHolder radioTabs">
                                  <RadioTag
                                    onCheckChange={this.onSelectPaymentType.bind(
                                      this
                                    )}
                                    labelList={[
                                      {
                                        name: "Fixed Amount",
                                        value: "fixed",
                                      },
                                      {
                                        name: "Percentage",
                                        value: "percentage",
                                      },
                                    ]}
                                    id={"affiliateType"}
                                    selectedList={[this.state.affiliateType]}
                                    selectType={"value"}
                                  />
                                </div>
                              </div>
                              {this.state.affiliateType === "fixed" && (
                                <>
                                  <div className="gap10" />
                                  <p>
                                    Note : If Fixed Amount type is selected then
                                    exact amount will always be assigned to the
                                    affiliate even if a promo code is used
                                  </p>
                                </>
                              )}
                              <div
                                className={
                                  this.state.affiliateValue !== ""
                                    ? "mdInput mdFocussed"
                                    : "mdInput"
                                }>
                                <label>Value</label>
                                <input
                                  type="number"
                                  name="affiliateValue"
                                  value={this.state.affiliateValue}
                                  onChange={(event) =>
                                    this.handleInputChange(event)
                                  }
                                />
                              </div>
                              <div className="gap10" />
                            </div>{" "}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="card">
                            <div className="container-fluid">
                              <div className="gap10" />
                              <p>Level 2 Commission</p>
                              <div className="radioBHolder">
                                <div className="havingHelpText">
                                  <p>Type</p>
                                </div>
                                <div className="tabsHolder radioTabs">
                                  <RadioTag
                                    onCheckChange={this.onSelectPaymentType.bind(
                                      this
                                    )}
                                    labelList={[
                                      {
                                        name: "Fixed Amount",
                                        value: "fixed",
                                      },
                                      {
                                        name: "Percentage",
                                        value: "percentage",
                                      },
                                    ]}
                                    id={"affiliateType2"}
                                    selectedList={[this.state.affiliateType2]}
                                    selectType={"value"}
                                  />
                                </div>
                                {this.state.affiliateType2 === "fixed" && (
                                  <>
                                    <div className="gap10" />
                                    <p>
                                      Note : If Fixed Amount type is selected
                                      then exact amount will always be assigned
                                      to the affiliate even if a promo code is
                                      used
                                    </p>
                                  </>
                                )}
                              </div>
                              <div
                                className={
                                  this.state.affiliateValue2 !== ""
                                    ? "mdInput mdFocussed"
                                    : "mdInput"
                                }>
                                <label>Value</label>
                                <input
                                  type="number"
                                  name="affiliateValue2"
                                  value={this.state.affiliateValue2}
                                  onChange={(event) =>
                                    this.handleInputChange(event)
                                  }
                                />
                              </div>
                              <div className="gap10" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="card">
                            <div className="container-fluid">
                              <div className="gap20" />
                              <h5 className="noMargin">Custom Commission</h5>
                              <div className="gap20" />
                              <div className="row">
                                <div className="col-md-6">
                                  <TypeableSelect
                                    key={this.state.keyAffiliateUser}
                                    name={"affiliateUser"}
                                    placeholder={"Select Affiliate User"}
                                    selected={this.state.selectedAffiliateUser}
                                    onSelect={this.onSelectAffiliateUser}
                                    options={this.state.affiliateUsers}
                                  />{" "}
                                </div>
                              </div>
                              <div className="gap20" />
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="card">
                                    <div className="container-fluid">
                                      <div className="gap10" />
                                      <p>Level 1 Commission</p>
                                      <div className="radioBHolder">
                                        <div className="havingHelpText">
                                          <p>Type</p>
                                        </div>
                                        <div className="tabsHolder radioTabs">
                                          <RadioTag
                                            onCheckChange={this.onSelectPaymentType.bind(
                                              this
                                            )}
                                            labelList={[
                                              {
                                                name: "Fixed Amount",
                                                value: "fixed",
                                              },
                                              {
                                                name: "Percentage",
                                                value: "percentage",
                                              },
                                            ]}
                                            id={"affiliatecType"}
                                            selectedList={[
                                              this.state.affiliatecType,
                                            ]}
                                            selectType={"value"}
                                          />
                                        </div>
                                        {this.state.affiliatecType ===
                                          "fixed" && (
                                          <>
                                            <div className="gap10" />
                                            <p>
                                              Note : If Fixed Amount type is
                                              selected then exact amount will
                                              always be assigned to the
                                              affiliate even if a promo code is
                                              used
                                            </p>
                                          </>
                                        )}
                                      </div>
                                      <div
                                        className={
                                          this.state.affiliatecValue !== ""
                                            ? "mdInput mdFocussed"
                                            : "mdInput"
                                        }>
                                        <label>Value</label>
                                        <input
                                          type="number"
                                          name="affiliatecValue"
                                          value={this.state.affiliatecValue}
                                          onChange={(event) =>
                                            this.handleInputChange(event)
                                          }
                                        />
                                      </div>
                                      <div className="gap10" />
                                    </div>{" "}
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="card">
                                    <div className="container-fluid">
                                      <div className="gap10" />
                                      <p>Level 2 Commission</p>
                                      <div className="radioBHolder">
                                        <div className="havingHelpText">
                                          <p>Type</p>
                                        </div>
                                        <div className="tabsHolder radioTabs">
                                          <RadioTag
                                            onCheckChange={this.onSelectPaymentType.bind(
                                              this
                                            )}
                                            labelList={[
                                              {
                                                name: "Fixed Amount",
                                                value: "fixed",
                                              },
                                              {
                                                name: "Percentage",
                                                value: "percentage",
                                              },
                                            ]}
                                            id={"affiliatecType2"}
                                            selectedList={[
                                              this.state.affiliatecType2,
                                            ]}
                                            selectType={"value"}
                                          />
                                        </div>
                                        {this.state.affiliatecType2 ===
                                          "fixed" && (
                                          <>
                                            <div className="gap10" />
                                            <p>
                                              Note : If Fixed Amount type is
                                              selected then exact amount will
                                              always be assigned to the
                                              affiliate even if a promo code is
                                              used
                                            </p>
                                          </>
                                        )}
                                      </div>
                                      <div
                                        className={
                                          this.state.affiliatecValue2 !== ""
                                            ? "mdInput mdFocussed"
                                            : "mdInput"
                                        }>
                                        <label>Value</label>
                                        <input
                                          type="number"
                                          name="affiliatecValue2"
                                          value={this.state.affiliatecValue2}
                                          onChange={(event) =>
                                            this.handleInputChange(event)
                                          }
                                        />
                                      </div>
                                      <div className="gap10" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="clearfix">
                                <div className="pull-right">
                                  <button
                                    className="btn btn-primary"
                                    onClick={this.onClickAddCustomSubmission.bind(
                                      this
                                    )}>
                                    Add Custom Commission
                                  </button>
                                </div>
                              </div>
                              <div className="gap20" />

                              {this.state.customCommissions.length > 0 && (
                                <>
                                  <div className="gap20" />
                                  <div className="card">
                                    <div className="container-fluid">
                                      <div className="gap20" />
                                      <h5 className="noMargin">
                                        Added Custom Commissions
                                      </h5>
                                      <div className="gap20" />
                                      <div className="addedRule">
                                        {this.renderCustomCommissions()}
                                      </div>
                                      <div className="gap20" />
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="gap10" />
                    </div>
                  </div>
                )}
                <p>
                  Any additional text you would like to add to be displayed near
                  the bottom of the checkout page:
                </p>
                {this.renderCustomText()}
                <div className="gap20" />

                <Checkbox
                  label={"Send Confirmation Email"}
                  isChecked={this.state.isSendEmail}
                  handleCheckboxChange={this.toggleSendEmail}
                  key={this.state.keySendEmail}
                />
                <div className="gap20" />
                {this.state.isSendEmail && (
                  <>
                    <div
                      className={
                        this.state.sendEmailSubject !== ""
                          ? "mdInput mdFocussed"
                          : "mdInput"
                      }>
                      <label>Subject</label>
                      <input
                        type="text"
                        name="sendEmailSubject"
                        value={this.state.sendEmailSubject}
                        onChange={(event) => this.handleInputChange(event)}
                      />
                    </div>
                    <div className="gap20" />
                    <PageContent
                      key={this.state.keySendEmailContent}
                      displayMode={"email"}
                      label="Email Content"
                      title={this.state.productName}
                      screen={"add-checkout-item"}
                      html={this.state.sendEmailHtml}
                      content={this.state.sendEmailContent}
                      onChangeData={this.onChangeEmailContent.bind(this)}
                      pageName={"addCheckoutItem"}
                      editorType={"unlayer"}
                      previewType={"iframe"}
                    />
                  </>
                )}
              </div>
            </div>

            {this.renderCustomFieldSection()}

            <VelocityTransitionGroup
              enter={{ animation: "slideDown" }}
              leave={{ animation: "slideUp" }}>
              {this.state.addOrderBumpItem && (
                <div className="card">
                  <div className="list-group list-group-flush">
                    <div className="list-group-item">
                      <h5 className="noMargin">Order Bump Item</h5>
                      <div className="gap10" />

                      <div className="card">
                        <div className="container-fluid">
                          <div className="gap20" />
                          <div
                            className={
                              this.state.bumpItemName !== ""
                                ? "mdInput mdFocussed"
                                : "mdInput"
                            }>
                            <label>Name</label>
                            <input
                              type="text"
                              name="bumpItemName"
                              value={this.state.bumpItemName}
                              onChange={(event) =>
                                this.handleInputChange(event)
                              }
                            />
                          </div>
                          <div className="gap20" />

                          <div
                            className={
                              this.state.bumpItemDescription !== ""
                                ? "mdInput mdFocussed"
                                : "mdInput"
                            }>
                            <label>Description</label>
                            <input
                              type="text"
                              name="bumpItemDescription"
                              value={this.state.bumpItemDescription}
                              onChange={(event) =>
                                this.handleInputChange(event)
                              }
                            />
                          </div>
                          <div className="gap20" />

                          <SelectAddImage
                            key={this.state.keyBumpItemImage}
                            type="bump-item-image"
                            label="Bump Item Image"
                            onChangeImage={this.onChangeBumpImage}
                            image={this.state.bumpItemImage}
                          />
                          <div className="gap20" />

                          <div className="radioBHolder">
                            <div className="havingHelpText">
                              <p>Order Type</p>
                            </div>
                            <div className="tabsHolder radioTabs">
                              <RadioTag
                                onCheckChange={this.onSelectBumpOrderType.bind(
                                  this
                                )}
                                labelList={[
                                  { name: "Single", value: "single" },
                                  {
                                    name: "Subscription",
                                    value: "subscription",
                                  },
                                ]}
                                id={"bumpOrderType"}
                                selectedList={[this.state.bumpOrderType]}
                                selectType={"value"}
                              />
                            </div>
                          </div>
                          {this.state.bumpOrderType === "single" && (
                            <>
                              <HelpTextDiv
                                id="idCheckoutItemBumpItemAmount"
                                label="Learn more about the Amount"
                                type="addCheckoutItem"
                                isInline="false"
                              />
                              <div
                                className={
                                  this.state.bumpItemOrder !== ""
                                    ? "mdInput mdFocussed"
                                    : "mdInput"
                                }>
                                <label>Amount</label>
                                <input
                                  type="number"
                                  name="bumpItemOrder"
                                  value={this.state.bumpItemOrder}
                                  onChange={(event) =>
                                    this.handleInputChange(event)
                                  }
                                />
                              </div>
                            </>
                          )}
                          {this.state.bumpOrderType === "subscription" && (
                            <div
                              className={
                                this.state.bumpItemPlan !== ""
                                  ? "mdInput mdFocussed"
                                  : "mdInput"
                              }>
                              <label>Select Plan</label>
                              <select
                                value={this.state.bumpItemPlan}
                                className="billingDate"
                                onChange={this.onChangeBumpItemPlan.bind(this)}>
                                {this.renderPlans()}
                              </select>
                            </div>
                          )}
                          <div className="gap10" />
                          <TagSelect
                            id={"add-checkout-item-add-bump-tag-id"}
                            key={this.state.keyAddTag}
                            selectedValues={this.state.bumpSelectedTags}
                            searchKey={"name"}
                            selectKey={"name"}
                            btnText={"Select Tag"}
                            lblText={"Select Tag"}
                            data={this.state.systemTags}
                            onChange={this.onChangeBumpAddTags}
                            isClickable={true}
                            reload={() => {
                              this.getSystemTags();
                            }}
                          />
                          <div className="radioBHolder">
                            <div className="tabsHolder radioTabs">
                              <RadioTag
                                onCheckChange={this.onSelectAddBumpChallengeStatus.bind(
                                  this
                                )}
                                labelList={[
                                  {
                                    name: "Add Challenges",
                                    value: "addBumpChallenges",
                                  },
                                  {
                                    name: "Do Not Add Any Challenges",
                                    value: "doNotAddBumpChallenges",
                                  },
                                ]}
                                key={this.state.keyAddBmpChallengeStatus}
                                id={"addBumpChallengeStatus"}
                                selectedList={[
                                  this.state.addBumpChallengeStatus,
                                ]}
                                selectType={"value"}
                              />
                            </div>
                          </div>
                          <div className="gap10" />
                          <div className="gap20" />
                          <VelocityTransitionGroup
                            enter={{ animation: "slideDown" }}
                            leave={{ animation: "slideUp" }}>
                            {this.state.addBumpChallengeStatus ===
                              "addBumpChallenges" && (
                              <div>
                                <SelectChallenge
                                  selectedChallenges={
                                    this.state.selectedBumpChallenges
                                  }
                                  challenges={this.state.challenges}
                                  key={this.state.keyChallenges}
                                  onChange={this.onChangeBumpChallenges.bind(
                                    this
                                  )}
                                />
                                {/* <SelectProduct
                                  selectedProducts={
                                    this.state.selectedBumpProducts
                                  }
                                  key={this.state.keyProducts}
                                  onChange={this.onChangeBumpProducts.bind(
                                    this
                                  )}
                                /> */}
                              </div>
                            )}
                          </VelocityTransitionGroup>

                          <Checkbox
                            label={"Allow user to select quantity"}
                            isChecked={this.state.bumpItemAllowQty}
                            handleCheckboxChange={this.toggleBumpItemAllowQty}
                            key={this.state.keyBumpItemAllowQty}
                          />
                          <div className="gap20" />
                        </div>
                      </div>
                      <div className="clearfix">
                        <div className="pull-right">
                          <button
                            className="btn btn-primary"
                            onClick={this.onClickAddBumpItem.bind(this)}>
                            {this.state.selectedBumpItemIndex > -1
                              ? "Update Order Bump Item"
                              : " Add Order Bump Item"}
                          </button>
                        </div>
                      </div>
                      <div className="gap20" />
                      <SortableBumpItemList
                        items={this.state.orderBumps}
                        onSortEnd={this.onSortEndBumpItems}
                        onPressEdit={this.onPressEditBumpItem.bind(this)}
                        onPressRemove={this.onPressRemoveBumpItem.bind(this)}
                      />
                      <div className="gap20" />
                      {this.state.orderBumps.length > 1 && (
                        <>
                          <Checkbox
                            label={"Group Order Bumps"}
                            isChecked={this.state.isGroupBumps}
                            handleCheckboxChange={this.toggleGroupBumps}
                            key={this.state.keyIsGroupBumps}
                          />
                          <div className="gap20" />
                          {this.state.isGroupBumps && (
                            <div className="card">
                              <div className="container-fluid">
                                <div className="gap10" />
                                <div className="row">
                                  <div className="col-sm-6">
                                    <div
                                      className={
                                        this.state.dropdownAboveText !== ""
                                          ? "mdInput mdFocussed"
                                          : "mdInput"
                                      }>
                                      <label>Text above drop down menu</label>
                                      <input
                                        type="text"
                                        name="dropdownAboveText"
                                        value={this.state.dropdownAboveText}
                                        onChange={(event) =>
                                          this.handleInputChange(event)
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-sm-6">
                                    <div
                                      className={
                                        this.state.dropdownDefaultText !== ""
                                          ? "mdInput mdFocussed"
                                          : "mdInput"
                                      }>
                                      <label>
                                        Default text for drop down menu
                                      </label>
                                      <input
                                        type="text"
                                        name="dropdownDefaultText"
                                        value={this.state.dropdownDefaultText}
                                        onChange={(event) =>
                                          this.handleInputChange(event)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="gap20" />
                                <label className="inputFakeLabelFocussed">
                                  Select order bump to be added to drop down
                                  menu
                                </label>
                                {this.state.orderBumps.map((i) => (
                                  <>
                                    <Checkbox
                                      label={i.name}
                                      isChecked={this.state.groupBumps[i.id]}
                                      handleCheckboxChange={this.toggleSelectGroupBump.bind(
                                        this,
                                        i.id
                                      )}
                                      key={this.state.keySelectGroupBump}
                                    />
                                    <div className="gap5" />
                                  </>
                                ))}
                                <div className="gap20" />
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </VelocityTransitionGroup>
            <div className="clearfix">
              <div className="pull-right">
                {this.state.checkoutItemId !== 0 && (
                  <button
                    className="btn btn-default margin-right-10"
                    onClick={() => this.onPressPreview()}>
                    Preview
                  </button>
                )}
                <button
                  className="btn btn-primary"
                  onClick={this.onClickCreateCheckoutItem.bind(this)}>
                  {this.state.checkoutItemId === 0
                    ? "Create Checkout Item"
                    : this.state.type === "clone"
                    ? "Create Checkout Item"
                    : "Update Checkout Item"}
                </button>
              </div>
            </div>
          </div>
          <div className="gap20" />
        </div>
      </Onboarding>
    );
  }
}

const mapStateToProps = (state) => ({
  message: state.billingReducer.message,
  success: state.billingReducer.success,
  chlgs: state.challengeReducer.chlgs,
  stripePlans: state.billingReducer.stripePlans,
  systemTags: state.tagReducer.systemTags,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getChlgs,
      resetStatus,
      createCheckoutItem,
      updateCheckoutItem,
      getStripePlans,
      getSystemTags,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AddCheckoutItem);
