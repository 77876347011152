import React from "react";
import { HorizontalBar } from "react-chartjs-2";

class BarChartHorizontal extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      lables: this.props.labels,
      label: this.props.label,
      data: this.props.data,
    };
  }

  render() {
    let { label, lables, data } = this.state;
    const chartData = {
      labels: lables,
      datasets: [
        {
          label: label,
          backgroundColor: "#26ACE2",
          borderColor: "#26ACE2",
          borderWidth: 1,
          hoverBackgroundColor: "#26ACE2",
          hoverBorderColor: "#26ACE2",
          data: data,
        },
      ],
    };
    const options = {
      maintainAspectRatio: false,
      tooltips: {
        callbacks: {
          title: function (tooltipItem, data) {
            return data.labels[tooltipItem[0].index];
          },
          label: function (tooltipItem, data) {
            return `${
              data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
            }`;
          },
        },
      },
      scales: {
        xAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
    };

    let height = 150;

    if (lables.length > 0) {
      height = 150 + 20 * lables.length;
    }

    return (
      <div>
        <HorizontalBar data={chartData} options={options} height={height} />
      </div>
    );
  }
}

export default BarChartHorizontal;
