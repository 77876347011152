import config from "../helpers/config";
import FetchService from "./fetch";

const query = require("qs");

function toError(error) {
  throw error;
}

class ApiService {
  constructor(name, auth) {
    this.name = name;
    this.auth = auth;
    this.base = `${config.urls.apiUrl}/${this.name}`;
  }

  makeUrl(params, id) {
    params = params || {};
    let url = this.base;

    if (typeof id !== "undefined" && id !== null) {
      url += `/${encodeURIComponent(id)}`;
    }

    if (Object.keys(params).length !== 0) {
      const queryString = query.stringify(params);

      url += `?${queryString}`;
    }

    return url;
  }

  request(options, params) {
    let fetch = new FetchService();
    return fetch.request(options, params, this.auth);
  }

  find(params = {}) {
    return this.request(
      {
        url: this.makeUrl(params.query),
        method: "GET",
        headers: Object.assign({}, params.headers),
      },
      params
    ).catch(toError);
  }

  get(id, params = {}) {
    if (typeof id === "undefined") {
      return Promise.reject(new Error(""));
    }

    return this.request(
      {
        url: this.makeUrl(params.query, id),
        method: "GET",
        headers: Object.assign({}, params.headers),
      },
      params
    ).catch(toError);
  }

  create(body, params = {}) {
    return this.request(
      {
        url: this.makeUrl(params.query),
        body,
        method: "POST",
        headers: Object.assign(
          { "Content-Type": "application/json" },
          params.headers
        ),
      },
      params
    ).catch(toError);
  }

  update(id, body, params = {}) {
    if (typeof id === "undefined") {
      return Promise.reject(new Error(""));
    }

    return this.request(
      {
        url: this.makeUrl(params.query, id),
        body,
        method: "PUT",
        headers: Object.assign(
          { "Content-Type": "application/json" },
          params.headers
        ),
      },
      params
    ).catch(toError);
  }

  patch(id, body, params = {}) {
    if (typeof id === "undefined") {
      return Promise.reject(new Error(""));
    }

    return this.request(
      {
        url: this.makeUrl(params.query, id),
        body,
        method: "PATCH",
        headers: Object.assign(
          { "Content-Type": "application/json" },
          params.headers
        ),
      },
      params
    ).catch(toError);
  }

  remove(id, params = {}) {
    if (typeof id === "undefined") {
      return Promise.reject(new Error(""));
    }

    return this.request(
      {
        url: this.makeUrl(params.query, id),
        method: "DELETE",
        headers: Object.assign({}, params.headers),
      },
      params
    ).catch(toError);
  }

  removeAll(body, params = {}) {
    if (typeof body === "undefined") {
      return Promise.reject(new Error(""));
    }

    return this.request(
      {
        url: this.makeUrl(params.query) + "/delete/all",
        body,
        method: "DELETE",
        headers: Object.assign(
          { "Content-Type": "application/json" },
          params.headers
        ),
      },
      params
    ).catch(toError);
  }
}

export function service(name, auth = true) {
  return new ApiService(name, auth);
}

export function logout() {
  return true;
}
