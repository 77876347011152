import * as api from "./api";
import { callApi } from "../helpers";

function callCreateStripeCustomer(data, isLoading = true) {
  return callApi(api.service("stripe-customer").create(data), isLoading);
}

function callCreateStripePlan(data) {
  return callApi(api.service("stripe-plan").create(data));
}

function callUpdateStripePlan(id, data) {
  return callApi(api.service("stripe-plan").patch(id, data));
}

function callGetStripePlan() {
  return callApi(api.service("stripe-plan").find({}));
}

function callGetStripePlansUsingQuery(query) {
  return callApi(api.service("stripe-plan").find(query));
}

function callGetStripePlanById(id) {
  return callApi(api.service("stripe-plan").get(id));
}

function callCreateCheckoutItem(data) {
  return callApi(api.service("checkout-item").create(data));
}

function callCreateCheckoutItemById(id) {
  return callApi(api.service("checkout-item").get(id));
}

function callUpdateCheckoutItem(id, data) {
  return callApi(api.service("checkout-item").patch(id, data));
}

function callGetCheckoutItems() {
  return callApi(api.service("checkout-item").find({}));
}

function callGetPageCheckoutItemsByQuery(query) {
  return callApi(api.service("checkout-item/page").find(query), false);
}

function callGetCheckoutItemByQuery(query, isLoading = true) {
  return callApi(api.service("checkout-item").find(query), isLoading);
}

function callCreatePromoCode(data) {
  return callApi(api.service("promo-code").create(data));
}

function callDeletePromoCodeById(id) {
  return callApi(api.service("promo-code").remove(id));
}

function callGetPromoCodes() {
  return callApi(api.service("promo-code").find({}));
}

function callGetPromoCodesByQuery(query) {
  return callApi(api.service("promo-code").find(query));
}

function callUpdatePromoCode(id, data) {
  return callApi(api.service("promo-code").patch(id, data));
}

function callCreateUpsellItem(data) {
  return callApi(api.service("upsell-item").create(data));
}

function callUpdateUpsellItem(id, data) {
  return callApi(api.service("upsell-item").patch(id, data));
}

function callGetUpsellItems() {
  return callApi(api.service("upsell-item").find({}));
}

function callCreateFunnelPath(data) {
  return callApi(api.service("funnel-path").create(data));
}

function callUpdateFunnelPath(id, data) {
  return callApi(api.service("funnel-path").patch(id, data));
}

function callGetFunnelPaths() {
  return callApi(api.service("funnel-path").find({}));
}

function callDeleteFunnelPathById(id) {
  return callApi(api.service("funnel-path").remove(id));
}

function callGetFunnelPathsByQuery(query) {
  return callApi(api.service("funnel-path").find(query));
}

function callGetUpsellItemById(id) {
  return callApi(api.service("upsell-item").get(id));
}

function callCreateMemberPayment(data) {
  return callApi(api.service("member-payout").create(data));
}

function callUpdateMemberPayment(id, data) {
  return callApi(api.service("member-payout").patch(id, data));
}

function callGetMemberPayments() {
  return callApi(api.service("member-payout").find({}));
}

function callGetMemberPaymentsByQuery(query, isLoading = true) {
  return callApi(api.service("member-payout").find(query), isLoading);
}

function callCreateConnectAccount(data) {
  return callApi(api.service("stripe-customer").create(data));
}

function callCreateBankTransfer(data) {
  return callApi(api.service("bank-transfer").create(data));
}

function callGetBankTransfers(query) {
  return callApi(api.service("bank-transfer").find(query));
}

function callCreatePromotion(data) {
  return callApi(api.service("promotion").create(data));
}

function callUpdatePromotion(id, data) {
  return callApi(api.service("promotion").patch(id, data));
}

function callGetPromotions(query) {
  return callApi(api.service("promotion").find(query));
}

function callCreateProduct(data) {
  return callApi(api.service("product").create(data));
}

function callUpdateProduct(id, data) {
  return callApi(api.service("product").patch(id, data));
}

function callGetProducts() {
  return callApi(api.service("product").find({}));
}

function callGetProductById(id) {
  return callApi(api.service("product").get(id));
}

function callDeleteProductById(id) {
  return callApi(api.service("product").remove(id));
}

function callGetProductsByQuery(query) {
  return callApi(api.service("product").find(query));
}

export {
  callCreateProduct,
  callUpdateProduct,
  callGetProductById,
  callGetProducts,
  callDeleteProductById,
  callGetProductsByQuery,
  callCreateStripeCustomer,
  callCreateStripePlan,
  callGetStripePlan,
  callCreateCheckoutItem,
  callGetCheckoutItems,
  callGetCheckoutItemByQuery,
  callCreatePromoCode,
  callGetPromoCodes,
  callGetPromoCodesByQuery,
  callUpdatePromoCode,
  callCreateUpsellItem,
  callGetUpsellItems,
  callCreateFunnelPath,
  callGetFunnelPaths,
  callGetFunnelPathsByQuery,
  callGetUpsellItemById,
  callCreateMemberPayment,
  callGetMemberPayments,
  callGetMemberPaymentsByQuery,
  callCreateConnectAccount,
  callCreateBankTransfer,
  callGetBankTransfers,
  callUpdateUpsellItem,
  callUpdateCheckoutItem,
  callUpdateFunnelPath,
  callDeletePromoCodeById,
  callUpdateMemberPayment,
  callCreatePromotion,
  callUpdatePromotion,
  callGetPromotions,
  callUpdateStripePlan,
  callGetStripePlansUsingQuery,
  callGetPageCheckoutItemsByQuery,
  callCreateCheckoutItemById,
  callGetStripePlanById,
  callDeleteFunnelPathById,
};
