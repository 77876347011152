import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import ContentHtml from "../../../components/general/contentHtml";
import { refreshCurrentUser } from "../../../modules/actions/authAction";

import { callUpdateUser } from "../../../services";
import { callGetMemberPagesData } from "../../../services/settingService";

class Onboarding extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      userData: this.props.userData || {},
      showComponent: false,
      showPrePage: false,
      onboardingInfo: {},
      location: {},
      keyContent: Math.random(),
    };
  }

  componentDidMount() {
    if (this.state.userData._id) {
      this.checkForLocation();
    }
  }

  checkForLocation() {
    if (this.props.history) {
      let location = this.props.history.location;
      this.setState({ location }, () => {
        this.checkOnboarding();
      });

      this.props.history.listen((location, action) => {
        this.setState({ location: location }, () => {
          this.checkOnboarding();
        });
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.userData) {
      this.setState({ userData: nextProps.userData }, () => {
        this.checkForLocation();
      });
    }
  }

  checkForPage(path) {
    let userData = this.state.userData;
    let onboardingPages = userData.onboarding_pages || [];
    let fPages = onboardingPages.filter((d) => {
      return d === path;
    });
    return fPages.length === 0;
  }

  async checkOnboarding() {
    if (this.state.showComponent || !this.state.userData.onboarding_pages) {
      return;
    }
    let path = this.state.location.pathname;

    /*eslint-disable*/

    let data = {
      query: { page_list: { $in: [path] } },
      action: "onboardings",
    };
    /*eslint-enable*/

    let items = await callGetMemberPagesData(data);
    if (items.data.length > 0) {
      let itemData = {};

      itemData = items.data[0];

      let status = itemData.status || "active";
      if (status === "inactive") {
        this.showComponent();
        return;
      }

      if (!itemData._id) {
        this.showComponent();
        return;
      }

      let check = this.checkForPage(path);
      if (check) {
        this.setState({
          showPrePage: true,
          showComponent: false,
          onboardingInfo: itemData,
          keyContent: Math.random(),
        });
      } else {
        this.showComponent();
      }
    } else {
      this.showComponent();
    }
  }

  onClickBtn() {
    let userData = this.state.userData;
    let onboardingPages = userData.onboarding_pages || [];
    onboardingPages.push(this.state.location.pathname);
    /*eslint-disable*/
    let data = { onboarding_pages: onboardingPages };
    /*eslint-enable*/
    callUpdateUser(userData._id, data).then((d) => {
      this.props.refreshCurrentUser(d);
      window.location.reload();
    });
  }

  showComponent() {
    this.setState({ showComponent: true, showPrePage: false });
  }

  renderOnboarding() {
    let onboarding = this.state.onboardingInfo;
    let btnText = onboarding.button_text || "Continue";

    return (
      <div>
        <div className="container-fluid">
          <div className="card">
            <ContentHtml key={this.state.keyContent} html={onboarding.html} />
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-6">
              <center>
                <button
                  className="btn btn-primary btn-onboarding"
                  onClick={this.onClickBtn.bind(this)}>
                  {btnText}
                </button>
              </center>
              <div className="gap30" />
            </div>
            <div className="col-md-3"></div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <>
        {this.state.showPrePage && this.renderOnboarding()}
        {this.state.showComponent && this.props.children}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.authReducer.userData,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ refreshCurrentUser }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Onboarding);
