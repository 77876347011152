import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";

import { getUsers } from "../../../modules/actions";
import { callGetUserById } from "../../../services";
import TitleBox from "../../../components/general/titleBox";

class ViewMessages extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    let userInfo = this.props.history.location.state.userInfo;

    this.state = {
      userInfo: userInfo || {},
      users: this.props.users.data || [],
    };
  }

  componentDidMount() {
    callGetUserById(this.state.userInfo._id).then((userData) => {
      this.setState({ userInfo: userData });
    });
  }

  componentWillReceiveProps(newProps) {
    // if (newProps.users.data) {
    //   this.setState({
    //     users: newProps.users.data,
    //   });
    // }
  }

  onClickViewMessage(message) {
    this.props.history.push("/admin/send-message", {
      message: message,
      userInfo: this.state.userInfo,
    });
  }

  onClickNewMessage() {
    this.props.history.push("/admin/create-message", {
      userInfo: this.state.userInfo,
      from: "admin",
    });
  }

  getUserName(memberId) {
    let users = this.state.userInfo.userInfos;
    let user = users.filter((d) => {
      return d._id === memberId;
    });
    if (user.length > 0) {
      return user[0].name;
    } else {
      return "";
    }
  }

  renderMessages() {
    var returnVals = [];
    let messages = [];
    let fromMessages = this.state.userInfo.member_messages || [];
    let toMessages = this.state.userInfo.member_messages_to || [];
    messages = fromMessages.concat(toMessages);

    messages = messages.reverse();

    for (let i = 0; i < messages.length; i++) {
      const element = messages[i];
      let lastMessage = element.message_list[element.message_list.length - 1];

      element.message_list.forEach((msg) => {
        msg["userInfo"] = this.state.userInfo.userInfos.filter((u) => {
          return u._id === msg.member_id;
        })[0];
      });
      returnVals.push(
        <tr key={i}>
          <td>{element.title}</td>
          <td className="messageContentInTable">
            <div>{lastMessage.message}</div>
          </td>
          <td>{this.getUserName(lastMessage.member_id)}</td>
          <td>{moment(lastMessage.time).format("MMM, DD YYYY")}</td>
          <td>
            <button
              className="btn btn-primary btn-sm btn-block"
              onClick={this.onClickViewMessage.bind(this, element)}>
              Reply
            </button>
          </td>
        </tr>
      );
    }
    return returnVals;
  }

  render() {
    return (
      <div className="container-fluid">
        <TitleBox
          title={`${this.state.userInfo.name}'s Messages`}
          showBackBtn={true}
        />
        <div className="gap20" />
        <div className="card">
          <div className="list-group-item responseButtons">
            <div className="text-left">
              {/*eslint-disable-next-line*/}
              <a
                className="btn btn-primary hideResponseBody"
                onClick={this.onClickNewMessage.bind(this)}>
                Create New Message
              </a>
            </div>
          </div>
        </div>
        {this.state.userInfo.member_messages.length > 0 && (
          <div className="card">
            <div className="list-group list-group-flush">
              <div className="list-group-item">
                <table className="table table-bordered table-reponsive">
                  <thead>
                    <tr>
                      <td>Title</td>
                      <td>Message</td>
                      <td>Last Reply From</td>
                      <td>Date</td>
                      <td>Details</td>
                    </tr>
                  </thead>
                  <tbody>{this.renderMessages()}</tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.authReducer.users,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getUsers }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ViewMessages);
