import React from "react";
import { imageUrl } from "../../../helpers";
import images from "../../../helpers/images";
import moment from "moment-timezone";
import { Scrollbars } from "react-custom-scrollbars";

import MessageBox from "../../../components/general/messageBox";

export class UserProfileComponent extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  processHeight(user) {
    if (user.height) {
      let inches = parseFloat(user.height).toFixed(0);
      let feet = Math.floor(inches / 12);
      inches %= 12;
      return (
        <p>
          Height: {feet}' {inches}"
        </p>
      );
    } else {
      return "";
    }
  }

  processChallenges(user) {
    var memberChallenges = user.member_challenges;
    var returnVals = [];
    memberChallenges.forEach((element) => {
      if (element.isActive) {
        if (element.currentDay >= 0) {
          returnVals.push(<p key={element._id}>Day {element.currentDay}</p>);
        }
      }
    });
    return returnVals;
  }

  processProducts(user) {
    var memberProducts = user.member_products;
    var returnVals = [];
    memberProducts.forEach((element) => {
      if (element.isActive && element.program_info && element.program_info[0]) {
        if (element.currentDay >= 0) {
          returnVals.push(
            <p key={element._id}>
              Day {element.currentDay} /{element.program_info[0].days}
            </p>
          );
        }
      }
    });
    return returnVals;
  }
  render() {
    let user = this.props.user;

    return (
      <div className="col-sm-3 col-md-2">
        {user.profile_image_url !== undefined && (
          <img
            alt="profile"
            src={imageUrl(user.profile_image_url)}
            className="btn-block"
          />
        )}
        {user.profile_image_url === undefined && (
          <img alt="profile" src={images.userImage} className="btn-block" />
        )}

        <div className="gap10" />
        <h5 className="noMargin">{user.name}</h5>
        <div className="userINformation">
          <p>Email: {user.email_address}</p>
        </div>
        <div className="userINformation">
          <div>{this.processChallenges(user)}</div>
          <div>{this.processProducts(user)}</div>
          {this.processHeight(user)}
          {user.weight && (
            <p>Weight: {user.weight ? user.weight + "lb" : ""}</p>
          )}
          {user.dob && (
            <p>
              Age: {user.dob ? moment().diff(moment(user.dob), "years") : ""}
            </p>
          )}
          <p>Member Since {moment(user.createdAt).format("MMMM YYYY")}</p>
          <a
            className="text-primary"
            href={`/member/view-progress-journal?mid=${user._id}`}
            rel="noopener noreferrer"
            target="_blank">
            View Progress Journal
          </a>

          <br />
          {/*eslint-disable*/}
          <a
            className="text-primary"
            onClick={() => this.props.onClickViewNotesFromProfile(user)}>
            View Notes
          </a>
          <br />
          <a
            className="text-primary"
            onClick={() => this.props.onClickGetPoints(user)}>
            View Points
          </a>
          {/*eslint-enable*/}
        </div>
      </div>
    );
  }
}

export const QuestionPoints = (props) => {
  var atqData = props.user.atqData;
  var quePoints = [];

  atqData.forEach((atq) => {
    if (atq.question_info.answer_type !== "text") {
      let subItemLabel = "";
      let dontShow = false;
      let answerInfo = atq.question_info.answer_list.filter(
        (d) => d.answer === atq.answer
      );
      if (answerInfo.length > 0) {
        subItemLabel = answerInfo[0].sub_item_label || "";
        dontShow = answerInfo[0].dont_show;
      }

      if (!dontShow) {
        quePoints.push({
          question: atq.question_info.question,
          points: atq.points,
          selected: atq.answer,
          date: atq.tracking_date,
          info: atq,
          isSubItem: atq.is_sub_item,
          subItemValue: atq.sub_item_value,
          subItemLabel,
        });
      }
    }
  });

  quePoints = quePoints.sort((a, b) => {
    return new Date(b.date) - new Date(a.date);
  });

  if (quePoints.length > 0) {
    let recentTrackingDate = quePoints[0].date;
    let fQuePoints = quePoints.filter((d) => {
      return (
        moment(d.date).format("DD-MM-YYYY") ===
        moment(recentTrackingDate).format("DD-MM-YYYY")
      );
    });

    return (
      <tbody>
        <tr>
          <td colSpan={fQuePoints.length}>
            {moment(recentTrackingDate).format("MMM Do, YYYY")}
          </td>
        </tr>
        <tr>
          {fQuePoints.map((k) => {
            return (
              <td key={Math.random()}>
                {k.question}{" "}
                {k.info.question_type === "tag" && (
                  <sup className="float-right pointer-click">
                    <i
                      className="fa fa-book"
                      onClick={() => props.onClickTag(k)}
                    />
                  </sup>
                )}
              </td>
            );
          })}
        </tr>
        <tr>
          {fQuePoints.map((k) => {
            return (
              <td key={Math.random()}>
                {k.selected ? `${k.selected} (${k.points})` : "-"}
                <br />
                {k.isSubItem && (
                  <label>
                    {k.subItemLabel} : {k.subItemValue}
                  </label>
                )}
              </td>
            );
          })}
        </tr>
      </tbody>
    );
  }
  return null;
};

export const ChallengeNames = (props) => {
  let filterChallenges = props.user.member_challenges
    .filter((d) => {
      return d.status === "active";
    })
    .map((i) => i.name)
    .join(", ");

  // let filterProducts = props.user.member_products.filter((d) => {
  //   return d.status === "active" && d.name === "Marci's Circle";
  // });

  // if (filterProducts.length > 0) {
  //   if (filterChallenges.length > 0) {
  //     filterChallenges += ", Marci's Circle";
  //   } else {
  //     filterChallenges += "Marci's Circle";
  //   }
  // }

  return filterChallenges;
};

export const MessagesList = (props) => {
  var messages = props.user.atqMessages;

  messages.sort((a, b) => {
    return new Date(b.time) - new Date(a.time);
  });

  if (messages.length > 0) {
    let messageList = [];
    let dayDate = moment().startOf("day").subtract(2, "days");
    let last2DayMessages = messages.filter((d) => {
      return moment(d.time).startOf("day").diff(dayDate) >= 2;
    });
    messageList = last2DayMessages;

    if (last2DayMessages.length <= 2) {
      messageList = messages.slice(0, 3);
    }

    if (messages.length === 1) {
      messageList = messages;
    }
    return (
      <MessageBox
        from={"messageList"}
        messages={messageList}
        showTrackingDate={true}
      />
    );
  }

  return null;
};

export const PointsModal = (props) => {
  let selectedUser = props.selectedUser;
  let pointsData = props.pointsData;
  return (
    <div>
      <div
        className="modalAlertOverlay pointsOverlay"
        onClick={props.onClickCloseFromPointsModal}
      />
      <div className="modalAlert pointsAlert modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {selectedUser.name} - {selectedUser.email_address}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={props.onClickCloseFromPointsModal}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="card">
              <div className="container-fluid">
                <div className="gap10" />
                Total Points : <b>{pointsData.totalPoints}</b>
                <div className="gap10" />
                Total Challenges : <b>{pointsData.totalChlgs}</b>
                <div className="gap10" />
                Accountability Submitted : <b>{pointsData.atqSubmitted}</b>
                <div className="gap10" />
                Marci's Circle Accountability Submitted :{" "}
                <b>{pointsData.mcSubmitted}</b>
                <div className="gap10" />
                Marci's Circle Points : <b>{pointsData.mcPoints}</b>
                <div className="gap10" />
                Bonus's Completed : <b>{pointsData.bonusCompleted}</b>
                <div className="gap10" />
                <div className="gap10" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ViewFullConversionModal = (props) => {
  let modalHeight = window.innerHeight - 300;
  return (
    <div>
      <div className="modalAlertOverlay conversionOverlay" />
      <div className="modalAlert conversionAlert modal-lg big">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Full Conversion
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={props.onClickCloseFromViewConversionModal}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="gap10" />
          <div
            className="container-fluid modalInnerBody"
            style={{ maxHeight: modalHeight, overflowY: "hidden" }}>
            <Scrollbars
              autoHide
              autoHideTimeout={1000}
              autoHideDuration={200}
              style={{ height: modalHeight }}>
              <MessageBox
                messages={
                  props.selectedUser.atqMessages
                    ? props.selectedUser.atqMessages
                    : []
                }
                from={"messageList"}
                showTrackingDate={true}
              />
            </Scrollbars>
          </div>
        </div>
      </div>
    </div>
  );
};
