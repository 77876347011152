import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import $ from "jquery";
import { CopyToClipboard } from "react-copy-to-clipboard";

import {
  callRemoveHtmlSnippet,
  callGetHtmlSnippets,
  callCreateHtmlSnippet,
  callUpdateHtmlSnippet,
} from "../../services";
import { showNotification } from "../../helpers";

class HtmlSnippets extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      name: "",
      tag: "",
      editTag: "",
      html: "",
      snippets: [],
      selectedSnippet: {},
      editMode: false,
    };
  }

  componentDidMount() {
    this.getSnippets();
  }

  getSnippets() {
    callGetHtmlSnippets().then((d) => {
      this.setState({ snippets: d.data });
    });
  }

  handleTextChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  onPressAddNewSnippet() {
    if (this.state.name.trim().length === 0) {
      showNotification("error", "Name is required", 4000);
      return;
    }

    if (this.state.tag.trim().length === 0) {
      showNotification("error", "Tag is required", 4000);
      return;
    }

    if (this.state.editTag.trim() !== this.state.tag.trim()) {
      let existingTag = this.state.snippets.filter((d) => {
        return d.tag === this.state.tag.trim();
      });

      if (existingTag.length > 0) {
        showNotification("error", "Tag with snippet is already exists", 4000);
        return;
      }
    }

    if (this.state.html.trim().length === 0) {
      showNotification("error", "Html is required", 4000);
      return;
    }

    let apiData = {
      name: this.state.name,
      tag: this.state.tag,
      html: this.state.html,
    };

    if (this.state.editMode) {
      callUpdateHtmlSnippet(this.state.selectedSnippet._id, apiData).then(
        (d) => {
          if (d._id) {
            showNotification("success", "Snippet updated successfully");
            this.setState(
              {
                name: "",
                html: "",
                tag: "",
                editTag: "",
                selectedSnippet: {},
                editMode: false,
              },
              () => {
                this.getSnippets();
              }
            );
          }
        }
      );
    } else {
      callCreateHtmlSnippet(apiData).then((d) => {
        if (d._id) {
          showNotification("success", "Snippet created successfully");
          this.setState(
            {
              name: "",
              tag: "",
              editTag: "",
              html: "",
            },
            () => {
              this.getSnippets();
            }
          );
        }
      });
    }
  }

  onPressCancelSnippet() {
    this.setState({
      name: "",
      html: "",
      selectedSnippet: {},
      editMode: false,
    });
  }

  onClickDeleteSnippet(snippet) {
    this.setState({ selectedSnippet: snippet }, () => {
      $(".modalAlertOverlay.confirmModal,.modalAlert.confirmAlert").fadeIn(200);
    });
  }

  onClickEditSnippet(snippet) {
    this.setState({
      selectedSnippet: snippet,
      name: snippet.name,
      html: snippet.html,
      tag: snippet.tag || "",
      editTag: snippet.tag || "",
      editMode: true,
    });
  }

  renderSnippets() {
    var returnVals = [];
    let items = this.state.snippets;
    items.forEach((element) => {
      returnVals.push(
        <tr key={element._id}>
          <td style={{ width: "40%" }}>{element.name}</td>
          <td style={{ width: "40%" }}>
            {element.tag ? `{{html(${element.tag})}}` : "-"}
          </td>
          <td style={{ width: "20%" }}>
            <CopyToClipboard
              text={element.html}
              onCopy={() =>
                showNotification("success", "Html copied to clipboard", 5000)
              }>
              <button className="btn btn-sm btn-default margin-right-10 border-0">
                <i className="fa fa-clipboard" />
              </button>
            </CopyToClipboard>
            <button
              onClick={this.onClickEditSnippet.bind(this, element)}
              className="btn btn-default btn-sm margin-right-10 border-0">
              <i className="fa fa-pencil" />
            </button>
            <button
              onClick={this.onClickDeleteSnippet.bind(this, element)}
              className="btn btn-danger btn-sm border-0">
              <i className="fa fa-trash" />
            </button>
          </td>
        </tr>
      );
    });
    return returnVals;
  }

  onClickNoAlert = () => {
    $(".modalAlertOverlay.confirmModal,.modalAlert.confirmAlert").fadeOut(200);
  };

  onClickYesAlert = () => {
    callRemoveHtmlSnippet(this.state.selectedSnippet._id).then((d) => {
      $(".modalAlertOverlay.confirmModal,.modalAlert.confirmAlert").fadeOut(
        200
      );
      this.getSnippets();
    });
  };

  renderConfirmationAlert() {
    let selectedSnippet = this.state.selectedSnippet;
    let name = "";
    if (selectedSnippet.name) {
      name = selectedSnippet.name;
    }
    return (
      <div>
        <div className="modalAlertOverlay confirmModal" />
        <div className="modalAlert confirmAlert">
          <p>{`Are you sure you want to delete "${name}" snippet?`}</p>
          <div className="alertFooterBtns">
            {/*eslint-disable*/}
            <a
              className="btn btn-default closeModal-No margin-right-10"
              onClick={this.onClickNoAlert}>
              No
            </a>
            <a
              className="btn btn-primary closeModal-Yes"
              onClick={this.onClickYesAlert}>
              Yes
            </a>
            {/*eslint-enable*/}
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderConfirmationAlert()}
        <div className="container-fluid">
          <h3 className="noMargin">Add HTML Snippet</h3>
          <div className="gap20" />
          <div className="card">
            <div className="container-fluid">
              <div className="gap20" />
              <div
                className={
                  this.state.name !== "" ? "mdInput mdFocussed" : "mdInput"
                }>
                <label>Name</label>
                <input
                  type="text"
                  name="name"
                  value={this.state.name}
                  onChange={(event) => this.handleTextChange(event)}
                />
              </div>
              <div className="gap20" />
              <div
                className={
                  this.state.tag !== "" ? "mdInput mdFocussed" : "mdInput"
                }>
                <label>Tag</label>
                <input
                  type="text"
                  name="tag"
                  value={this.state.tag}
                  onChange={(event) => this.handleTextChange(event)}
                />
              </div>
              <div className="gap20" />
              <div className="mdTextArea multiLine">
                <div
                  className={
                    this.state.html !== "" ? "mdInput mdFocussed" : "mdInput"
                  }>
                  <label>Html</label>
                  <textarea
                    name="html"
                    value={this.state.html}
                    onChange={(event) => this.handleTextChange(event)}
                  />
                </div>
              </div>
              <div className="gap20" />
            </div>
          </div>
        </div>

        <div className="text-right container-fluid">
          {this.state.editMode && (
            <button
              className="btn btn-default margin-right-10"
              onClick={() => this.onPressCancelSnippet()}>
              Cancel
            </button>
          )}
          <button
            className="btn btn-primary"
            onClick={() => this.onPressAddNewSnippet()}>
            {this.state.editMode ? "Update" : "Create"}
          </button>
        </div>
        <div className="gap20" />

        {this.state.snippets.length > 0 && (
          <div className="container-fluid">
            <h3 className="noMargin">View HTML Snippets</h3>
            <div className="gap20" />
            <div className="card">
              <div className="list-group list-group-flush">
                <div className="list-group-item">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Tag</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>{this.renderSnippets()}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HtmlSnippets);
