import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import $ from "jquery";

import RadioTag from "../form/radioTag";
import TypeableSelect from "../form/typeableSelect";
import {
  callGetArticle,
  callGetMarciCircles,
  callRemoveLink,
  callGetLinks,
  callGetChlgsByQuery,
} from "../../services";
import { showNotification, slugify } from "../../helpers";
import { createLink, resetStatus } from "../../modules/actions";

class AddEmailLink extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.dashboardPrefix = "/member";
    this.challengePrefix = "/member?";
    this.messagePrefix = "/member/messages";
    this.newsletterPrefix = "/member/marcis-circle/newsletter?";

    this.state = {
      name: "",
      url: "",
      tag: "",
      type: "",
      challenges: [],
      challengeItems: [],
      articles: [],
      newsletters: [],
      linkUrlDisable: true,
      selectedChallenge: { value: "", label: "" },
      selectedChallengeItem: { value: "", label: "" },
      selectedArticle: { value: "", label: "" },
      selectedNewsletter: { value: "", label: "" },
      keyChallenge: Math.random(),
      keyChallengeItem: Math.random(),
      keyArticle: Math.random(),
      keyNewsletter: Math.random(),
      keySelectOption: Math.random(),
      links: [],
      selectedLink: {},
    };
  }

  componentDidMount() {
    this.callGetLinksAPI();
  }

  callGetLinksAPI() {
    let query = {
      query: { $sort: { createdAt: -1 }, auto: { $ne: true } },
    };
    callGetLinks(query).then((d) => {
      this.setState({ links: d.data });
    });
  }

  componentWillReceiveProps(newProps) {
    if (newProps.message) {
      if (newProps.success) {
        this.resetProps();
        this.callGetLinksAPI();
        showNotification("success", newProps.message, 5000);
      } else {
        showNotification("error", newProps.message, 5000);
      }
      this.props.resetStatus();
    }
  }

  resetProps() {
    this.setState({
      name: "",
      url: "",
      type: "",
      tag: "",
      selectedChallenge: { value: "", label: "" },
      selectedChallengeItem: { value: "", label: "" },
      selectedArticle: { value: "", label: "" },
      linkUrlDisable: true,
      keySelectOption: Math.random(),
    });
  }

  handleTextChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  onSelectType(item, id) {
    let value = item[Object.keys(item)[0]].value;
    this.setState({ [id]: value });

    switch (value) {
      case "challenge":
        if (this.state.challenges.length === 0) {
          callGetChlgsByQuery({
            query: {
              archive: { $ne: true },
              $select: ["-content", "-html"],
            },
          }).then((d) => {
            let fChlgs = [];
            d.data.forEach((element) => {
              fChlgs.push({ value: element, label: element.name });
            });

            this.setState({
              challenges: fChlgs,
              keyChallenge: Math.random(),
              linkUrlDisable: true,
              url: "",
            });
          });
        } else {
          this.setState({ linkUrlDisable: true, url: "" });
        }
        break;
      case "article":
        if (this.state.articles.length === 0) {
          callGetArticle().then((d) => {
            let fArticles = [];
            d.data.forEach((element) => {
              fArticles.push({ value: element, label: element.title });
            });

            this.setState({
              articles: fArticles,
              keyArticle: Math.random(),
              linkUrlDisable: true,
              url: "",
              selectedChallenge: { value: "", label: "" },
              challengeItems: [],
            });
          });
        } else {
          this.setState({
            linkUrlDisable: true,
            url: "",
            selectedChallenge: { value: "", label: "" },
            challengeItems: [],
          });
        }
        break;
      case "newsletter":
        if (this.state.newsletters.length === 0) {
          callGetMarciCircles().then((d) => {
            let fNewsletter = [];
            d.data.forEach((element) => {
              fNewsletter.push({ value: element, label: element.title });
            });

            this.setState({
              newsletters: fNewsletter,
              keyNewsletter: Math.random(),
              linkUrlDisable: true,
              url: "",
              selectedNewsletter: { value: "", label: "" },
            });
          });
        } else {
          this.setState({
            linkUrlDisable: true,
            url: "",
            selectedNewsletter: { value: "", label: "" },
          });
        }
        break;
      case "other":
        this.setState({
          url: "",
          linkUrlDisable: false,
          selectedChallenge: { value: "", label: "" },
          challengeItems: [],
        });
        break;
      case "message":
        this.setState({
          url: this.messagePrefix,
          linkUrlDisable: true,
          selectedChallenge: { value: "", label: "" },
          challengeItems: [],
        });
        break;
      case "dashboard":
        this.setState({
          url: this.dashboardPrefix,
          linkUrlDisable: true,
          selectedChallenge: { value: "", label: "" },
          challengeItems: [],
        });
        break;

      default:
        break;
    }
  }

  onSelectChallenge = (challenge) => {
    let selectedChallenge = challenge.value;
    if (selectedChallenge._id) {
      let url = `${this.challengePrefix}chlgid=${selectedChallenge._id}`;

      let challengeItems = selectedChallenge.challenge_item_list_info;

      let fItems = [];
      challengeItems.forEach((element) => {
        fItems.push({ value: element, label: element.name });
      });

      this.setState({
        selectedChallenge: challenge,
        challengeItems: fItems,
        keyChallengeItem: Math.random(),
        url: url,
        linkUrlDisable: true,
      });
    } else {
      this.setState({
        selectedChallenge: { value: "", label: "" },
        challengeItems: [],
        keyChallengeItem: Math.random(),
        url: "",
        linkUrlDisable: true,
      });
    }
  };

  onSelectChallengeItem = (challengeItem) => {
    let selectedChallengeItem = challengeItem.value;
    let selectedChallenge = this.state.selectedChallenge.value;
    let url = `${this.challengePrefix}chlgid=${selectedChallenge._id}`;

    if (selectedChallengeItem._id) {
      url += `&chlgitemid=${selectedChallengeItem._id}`;
    }
    this.setState({
      selectedChallengeItem: selectedChallengeItem,
      url: url,
      linkUrlDisable: true,
    });
  };

  onSelectArticle = (article) => {
    let selectedArticle = article.value;

    let url = "";

    if (selectedArticle._id && selectedArticle.category) {
      url += `/${selectedArticle.category[0].name}/${selectedArticle.article_slug}`;
    }
    this.setState({
      selectedArticle: selectedArticle,
      url: url,
      linkUrlDisable: true,
    });
  };

  onSelectNewsletter = (newsletter) => {
    let selectedNewsletter = newsletter.value;

    let url = "";

    if (selectedNewsletter._id) {
      url += `${this.newsletterPrefix}nid=${selectedNewsletter._id}`;
    }
    this.setState({
      selectedNewsletter: selectedNewsletter,
      url: url,
      linkUrlDisable: true,
    });
  };

  onPressAddLink() {
    // call api to save new link
    if (this.state.name.trim().length === 0) {
      showNotification("error", "Link Name is required", 4000);
      return;
    }

    if (this.state.tag.trim().length === 0) {
      showNotification("error", "Tag Name is required", 4000);
      return;
    }

    let existingTag = this.state.links.filter((d) => {
      return d.tag === this.state.tag;
    });

    if (existingTag.length > 0) {
      showNotification("error", "Tag Name already exists", 4000);
      return;
    }

    if (this.state.type.trim().length === 0) {
      showNotification("error", "Please select a type", 4000);
      return;
    }

    if (this.state.url.trim().length === 0) {
      showNotification("error", "Url is required", 4000);
      return;
    }

    let tag = this.state.tag;
    let subTag = tag.substring(0, 2);
    if (subTag === "{{") {
      let re = tag.match(/\((.*)\)/);
      if (re.length > 1) {
        tag = re[1];
      }
    }

    let apiData = {
      name: this.state.name,
      url: this.state.url,
      type: this.state.type,
      tag: slugify(this.state.tag),
    };

    this.props.createLink(apiData);
  }

  onClickDeleteLink = (link) => {
    this.setState({ selectedLink: link }, () => {
      $(
        ".modalAlertOverlay.confirmDeleteLinkModal,.modalAlert.confirmDeleteLinkAlert"
      ).fadeIn(200);
    });
  };

  renderLinks() {
    var returnVals = [];
    let items = this.state.links;
    items.forEach((element) => {
      returnVals.push(
        <tr key={element._id}>
          <td>{element.name}</td>
          <td>{element.type}</td>
          <td>{`{{link(${element.tag})}}`}</td>
          <td>{element.url}</td>
          <td>
            <button
              onClick={this.onClickDeleteLink.bind(this, element)}
              className="btn btn-danger btn-sm border-0">
              <i className="fa fa-trash" />
            </button>
          </td>
        </tr>
      );
    });
    return returnVals;
  }

  onClickNoAlert = () => {
    $(
      ".modalAlertOverlay.confirmDeleteLinkModal,.modalAlert.confirmDeleteLinkAlert"
    ).fadeOut(200);
  };

  onClickYesAlert = () => {
    callRemoveLink(this.state.selectedLink._id).then((d) => {
      $(
        ".modalAlertOverlay.confirmDeleteLinkModal,.modalAlert.confirmDeleteLinkAlert"
      ).fadeOut(200);
      this.callGetLinksAPI();
    });
  };

  renderConfirmationAlert() {
    let selectedLink = this.state.selectedLink;
    let name = "";
    if (selectedLink.name) {
      name = selectedLink.name;
    }
    return (
      <div>
        <div className="modalAlertOverlay confirmDeleteLinkModal" />
        <div className="modalAlert confirmDeleteLinkAlert">
          <p>{`Are you sure you want to delete "${name}" link?`}</p>
          <div className="alertFooterBtns">
            {/*eslint-disable*/}
            <a
              className="btn btn-default closeModal-No margin-right-10"
              onClick={this.onClickNoAlert}>
              No
            </a>
            <a
              className="btn btn-primary closeModal-Yes"
              onClick={this.onClickYesAlert}>
              Yes
            </a>
            {/*eslint-enable*/}
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderConfirmationAlert()}
        <div className="container-fluid">
          <h3 className="noMargin">Add Link</h3>
          <div className="gap20" />
          <div className="card">
            <div className="container-fluid">
              <div className="gap20" />
              <div
                className={
                  this.state.name !== "" ? "mdInput mdFocussed" : "mdInput"
                }>
                <label>Link Name</label>
                <input
                  type="text"
                  name="name"
                  value={this.state.name}
                  onChange={(event) => this.handleTextChange(event)}
                />
              </div>
              <div className="gap20" />
              <div
                className={
                  this.state.tag !== "" ? "mdInput mdFocussed" : "mdInput"
                }>
                <label>Tag Name</label>
                <input
                  type="text"
                  name="tag"
                  value={this.state.tag}
                  onChange={(event) => this.handleTextChange(event)}
                />
              </div>
              <div className="gap20" />
              <div className="radioBHolder">
                <p>Link Type</p>
                <div className="tabsHolder radioTabs">
                  <RadioTag
                    key={this.state.keySelectOption}
                    onCheckChange={this.onSelectType.bind(this)}
                    labelList={[
                      { name: "Dashboard", value: "dashboard" },
                      { name: "Challenge", value: "challenge" },
                      { name: "Message", value: "message" },
                      { name: "Article", value: "article" },
                      { name: "Newsletter", value: "newsletter" },
                      { name: "Other", value: "other" },
                    ]}
                    id={"type"}
                    selectedList={[this.state.type]}
                    selectType={"value"}
                  />
                </div>
              </div>
              <div className="gap20" />
              {this.state.challenges.length > 0 &&
                this.state.type === "challenge" && (
                  <div>
                    <TypeableSelect
                      key={this.state.keyChallenge}
                      name={"selectedChallenge"}
                      selected={this.state.selectedChallenge}
                      placeholder={"Select Challenge"}
                      onSelect={this.onSelectChallenge}
                      options={this.state.challenges}
                    />
                    <div className="gap20" />
                  </div>
                )}
              {this.state.challengeItems.length > 0 &&
                this.state.type === "challenge" && (
                  <div>
                    <TypeableSelect
                      key={this.state.keyChallengeItem}
                      name={"selectedChallengeItem"}
                      selected={this.state.selectedChallengeItem}
                      placeholder={"Select Challenge Item"}
                      onSelect={this.onSelectChallengeItem}
                      options={this.state.challengeItems}
                    />
                    <div className="gap20" />
                  </div>
                )}
              {this.state.articles.length > 0 && this.state.type === "article" && (
                <div>
                  <TypeableSelect
                    key={this.state.keyArticle}
                    name={"selectedArticle"}
                    selected={this.state.selectedArticle}
                    placeholder={"Select Article"}
                    onSelect={this.onSelectArticle}
                    options={this.state.articles}
                  />
                  <div className="gap20" />
                </div>
              )}
              {this.state.newsletters.length > 0 &&
                this.state.type === "newsletter" && (
                  <div>
                    <TypeableSelect
                      key={this.state.keyNewsletter}
                      name={"selectedNewsletter"}
                      selected={this.state.selectedNewsletter}
                      placeholder={"Select Newsletter"}
                      onSelect={this.onSelectNewsletter}
                      options={this.state.newsletters}
                    />
                    <div className="gap20" />
                  </div>
                )}
              {this.state.type.length > 0 && this.state.type === "other" && (
                <div
                  className={
                    this.state.url !== "" ? "mdInput mdFocussed" : "mdInput"
                  }>
                  <label>Link Url</label>
                  <input
                    type="text"
                    name="url"
                    disabled={this.state.linkUrlDisable}
                    value={this.state.url}
                    onChange={(event) => this.handleTextChange(event)}
                  />
                </div>
              )}
              <div className="gap20" />
            </div>
          </div>
        </div>

        <div className="text-right container-fluid">
          <button
            className="btn btn-primary"
            onClick={() => this.onPressAddLink()}>
            Create New Link
          </button>
        </div>
        <div className="gap20" />

        {this.state.links.length > 0 && (
          <div className="container-fluid">
            <h3 className="noMargin">View Links</h3>
            <div className="gap20" />
            <div className="card">
              <div className="list-group list-group-flush">
                <div className="list-group-item">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Type</th>
                        <th>Link</th>
                        <th>Url</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>{this.renderLinks()}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  message: state.linkReducer.message,
  success: state.linkReducer.success,
  links: state.linkReducer.links,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      resetStatus,
      createLink,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AddEmailLink);
