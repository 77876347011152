import React from "react";
import PageContent from "../../../../components/editor/pageContent";
import moment from "moment-timezone";
import $ from "jquery";

import RadioTag from "../../../../components/form/radioTag";
import { checkGMSMessage } from "../../../../helpers";
import { callGetSettings } from "../../../../services/settingService";
import {
  callGetEmailUsingId,
  callGetFormsUsingQuery,
  callGetInvoices,
  callGetTextMessageById,
  callGetUsersByQuery,
  callUpdateEmail,
  callUpdateTextMessage,
} from "../../../../services";
import ChallengeOperations from "../../challenge/challengeOpts";
import { SortableTaskRuleList } from "../../../../components/form/sortableList";
import { arrayMove } from "../../../../packages/mp-content-editor/helpers";

class TaskSettingRuleList extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      ruleType: "",
      ruleAction: "",
      ruleCondition: "",
      ruleField: "",
      ruleValue: "",
      ruleThen: "",
      ruleForm: "",
      ruleWhenStatus: "",
      ruleWhenStatusValue: "",
      ruleStatus: "",
      ruleInvoice: "",
      ruleInvoiceField: "",
      ruleInvoiceFieldValue: "",
      ruleInvoiceFields: [],
      ruleInvoiceType: "",
      ruleFrom: "",
      ruleStaffUserId: "",
      ruleFromTo: "assigned_user",
      emailNotiName: "",
      emailNotiContent: {},
      emailNotiHtml: "",
      keyPageContent: Math.random(),
      sendTimeType: "send",
      smsName: "",
      smsMessage: "",
      isGMSMessage: true,
      noOfMessages: 1,
      keyContent: Math.random(),
      selectedTimeType: "",
      selectedHour: "",
      selectedMins: "",
      selectedTimeIndex: -1,
      selectedDay: "",
      selectedMonth: moment().format("MM"),
      selectedYear: moment().format("YYYY"),
      fieldList: [
        { name: "Task Name", value: "task", type: "normal" },
        { name: "Description", value: "description", type: "normal" },
        { name: "Priority", value: "priority", type: "normal" },
        { name: "Status", value: "status", type: "normal" },
        { name: "Due Date", value: "due_date", type: "normal" },
        { name: "Assigned To", value: "assigned_to", type: "normal" },
      ],
      invoiceField: "",
      invoiceFieldValue: "",
      forms: [],
      statusList: [],
      taskRules: this.props.taskRules,
      invoices: [],
      selectedRuleIndex: -1,
      emailId: "",
      smsId: "",
      tagId: "",
      staffUsers: [],
    };
  }

  componentDidMount() {
    this.getData();
  }

  async getData() {
    await this.getSettings();
    await this.getForms();
    await this.getInvoices();
    await this.getStaffUsers();
  }

  async getForms() {
    let d = await callGetFormsUsingQuery();
    this.setState({
      forms: d.data,
    });
  }

  async getInvoices() {
    let d = await callGetInvoices();
    this.setState({
      invoices: d.data,
    });
  }

  async getStaffUsers() {
    /*eslint-disable*/
    let query = {
      query: {
        member_type: "staff",
        $select: ["_id", "name", "email_address"],
        populate: "no",
      },
    };
    /*eslint-enable*/
    let d = await callGetUsersByQuery(query);
    let staffUsers = d.data;

    let taskRules = this.state.taskRules;
    taskRules.forEach((element) => {
      if (element.staffUserId) {
        let fUser = staffUsers.find((d) => d._id === element.staffUserId);
        if (fUser._id) {
          element.staffUserName = fUser.name;
        }
      }
    });

    this.setState({
      taskRules: taskRules,
      staffUsers: staffUsers,
    });
  }

  async getSettings() {
    let d = await callGetSettings("tasksettings");
    let settings = d.data;
    let taskSettings = settings.task_settings || {};
    let customFields = taskSettings.custom_fields || [];
    let fieldList = this.state.fieldList;
    let statusList = taskSettings.task_statuses || [];

    customFields.forEach((element) => {
      fieldList.push({
        name: element.label,
        value: element.id,
        type: "customField",
      });
    });

    this.setState({
      fieldList,
      statusList,
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  onChangeNotiEmailContent(html, content) {
    this.setState({
      emailNotiContent: content,
      emailNotiHtml: html,
    });
  }

  renderDays() {
    var returnVals = [];
    for (let i = 1; i <= 31; i++) {
      let day = i.toString().length !== 1 ? i : `0${i}`;
      returnVals.push(
        <option value={day} key={day}>
          {day}
        </option>
      );
    }
    return returnVals;
  }

  renderMonths() {
    var returnVals = [];
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    for (let i = 1; i <= 12; i++) {
      let month = i.toString().length !== 1 ? i : `0${i}`;
      returnVals.push(
        <option value={month} key={month}>
          {monthNames[i - 1]}
        </option>
      );
    }
    return returnVals;
  }

  renderYears() {
    var returnVals = [
      <option value={"2022"} key={"2022"}>
        {"2022"}
      </option>,
      <option value={"2023"} key={"2023"}>
        {"2023"}
      </option>,
      <option value={"2024"} key={"2024"}>
        {"2024"}
      </option>,
      <option value={"2025"} key={"2025"}>
        {"2025"}
      </option>,
    ];
    return returnVals;
  }

  renderHours() {
    var returnVals = [];
    for (let i = 1; i <= 12; i++) {
      let hour = i.toString().length !== 1 ? i : `0${i}`;
      returnVals.push(
        <option value={hour} key={hour}>
          {hour}
        </option>
      );
    }
    return returnVals;
  }

  renderMins() {
    var returnVals = [];
    for (let i = 0; i <= 45; i = i + 15) {
      let min = i.toString().length !== 1 ? i : `0${i}`;
      returnVals.push(
        <option value={min} key={min}>
          {min}
        </option>
      );
    }
    return returnVals;
  }

  onSelectSendTime(item, id) {
    this.setState({ [id]: item[Object.keys(item)[0]].value });
  }

  renderSendTime() {
    return (
      <>
        <div className="gap10" />
        <div className="radioBHolder">
          <p>Send Time</p>
          <div className="tabsHolder radioTabs">
            <RadioTag
              onCheckChange={this.onSelectSendTime.bind(this)}
              labelList={[
                {
                  name: "Immediately",
                  value: "send",
                },
                { name: "Specified Time", value: "time" },
              ]}
              id={"sendTimeType"}
              selectedList={[this.state.sendTimeType]}
              selectType={"value"}
              key={this.state.keySendTime}
            />
          </div>
        </div>
        {this.state.sendTimeType === "time" && (
          <>
            <div className="row">
              <div className="col-sm-3">
                <div
                  className={
                    this.state.selectedHour !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Select Hour</label>
                  <select
                    name="selectedHour"
                    value={this.state.selectedHour}
                    onChange={(event) => this.handleInputChange(event)}>
                    <option value="" />
                    {this.renderHours()}
                  </select>
                </div>
                <div className="gap20" />
              </div>
              <div className="col-sm-3">
                <div
                  className={
                    this.state.selectedMins !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Select Minute</label>
                  <select
                    name="selectedMins"
                    value={this.state.selectedMins}
                    onChange={(event) => this.handleInputChange(event)}>
                    <option value="" />
                    {this.renderMins()}
                  </select>
                </div>
                <div className="gap20" />
              </div>
              <div className="col-sm-3">
                <div
                  className={
                    this.state.selectedTimeType !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Select Type</label>
                  <select
                    name="selectedTimeType"
                    value={this.state.selectedTimeType}
                    onChange={(event) => this.handleInputChange(event)}>
                    <option value="" />
                    <option value="AM">AM</option>
                    <option value="PM">PM</option>
                  </select>
                </div>
                <div className="gap20" />
              </div>
            </div>
          </>
        )}
      </>
    );
  }

  getFirstName() {
    return "{{first_name}}";
  }

  getNoOfMessages() {
    let noOf = this.state.noOfMessages;
    return noOf;
  }

  handleMessageChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    const isGMSMessage = checkGMSMessage(value);
    const limit = isGMSMessage ? 140 : 67;

    let noOfMessages = Math.ceil(value.length / limit);

    this.setState({
      [name]: value,
      isGMSMessage: isGMSMessage,
      noOfMessages,
    });
  }

  onClickAddMapField() {
    let ruleInvoiceFields = this.state.ruleInvoiceFields;
    let field = this.state.fieldList.find(
      (d) => d.value === this.state.invoiceFieldValue
    );

    let map = {
      field: this.state.invoiceField,
      value: this.state.invoiceFieldValue,
      type: field.type,
    };
    ruleInvoiceFields.push(map);
    this.setState({
      ruleInvoiceFields,
      invoiceField: "",
      invoiceFieldValue: "",
    });
  }

  onClickDeleteMapField(index) {
    let fields = this.state.ruleInvoiceFields;
    fields.splice(index, 1);

    this.setState({ ruleInvoiceFields: fields });
  }

  renderMapFields() {
    let fields = this.state.ruleInvoiceFields;
    let returnVals = [];
    let fieldList = this.state.fieldList;
    const arrow = <i className="fa fa-long-arrow-right" />;

    for (let i = 0; i < fields.length; i++) {
      const element = fields[i];
      let value = element.value;
      let field = fieldList.find((d) => d.value === element.value);
      if (field) {
        value = field.name;
      }
      returnVals.push(
        <div key={i}>
          <label className="firstLetter">{element.field}</label> {arrow} {value}
          <button
            onClick={this.onClickDeleteMapField.bind(this, i)}
            className="btn btn-danger btn-sm border-0"
            style={{ float: "right" }}>
            <i className="fa fa-trash" />
          </button>
        </div>
      );
    }
    return returnVals;
  }

  onClickCancelRule() {
    this.setState({
      ruleType: "",
      selectedRuleIndex: -1,
    });
  }

  async onClickAddRule() {
    let taskRules = this.state.taskRules;
    let fieldType = "";

    if (this.state.ruleField) {
      let field = this.state.fieldList.find(
        (d) => d.value === this.state.ruleField
      );
      fieldType = field.type;
    }

    let rule = {
      type: this.state.ruleType,
      action: this.state.ruleAction,
      field: this.state.ruleField,
      fieldType: fieldType,
      condition: this.state.ruleCondition,
      value: this.state.ruleValue,
      then: this.state.ruleThen,
      form: this.state.ruleForm,
      status: this.state.ruleStatus,
      statusWhen: this.state.ruleWhenStatus,
      statusWhenValue: this.state.ruleWhenStatusValue,
      invoiceId: this.state.ruleInvoice,
      invoiceFields: this.state.ruleInvoiceFields,
      invoiceType: this.state.ruleInvoiceType,
      invoiceField: this.state.ruleInvoiceField,
      invoiceFieldValue: this.state.ruleInvoiceFieldValue,
      emailLinked: "",
      smsLinked: "",
      tagId: "",
      from: this.state.ruleFrom,
      fromTo: this.state.ruleFrom === "staff" ? this.state.ruleFromTo : "",
      staffUserId: this.state.ruleStaffUserId,
    };

    if (
      this.state.ruleAction === "send_user_email" ||
      this.state.ruleAction === "send_staff_email" ||
      this.state.ruleAction === "send_email"
    ) {
      if (this.state.selectedRuleIndex > -1) {
        /*eslint-disable*/
        await callUpdateEmail(this.state.emailId, {
          title: this.state.emailNotiName,
          content: this.state.emailNotiContent,
          html: this.state.emailNotiHtml,
          send_time_type: this.state.sendTimeType,
          selected_hour: this.state.selectedHour,
          selected_mins: this.state.selectedMins,
          selected_time_type: this.state.selectedTimeType,
        });
        /*eslint-enable*/
        rule.emailLinked = this.state.emailId;
        rule.tagId = this.state.tagId;
      } else {
        let dOpe = new ChallengeOperations();

        /*eslint-disable*/
        let response = await dOpe.createTaskRuleEmail(
          this.state.ruleType,
          this.state.emailNotiName,
          this.state.emailNotiContent,
          this.state.emailNotiHtml,
          {
            send_time_type: this.state.sendTimeType,
            selected_hour: this.state.selectedHour,
            selected_mins: this.state.selectedMins,
            selected_time_type: this.state.selectedTimeType,
          }
        );
        rule.emailLinked = response.emailId;
        rule.tagId = response.tagId;
        /*eslint-enable*/
      }
    }
    if (
      this.state.ruleAction === "send_user_sms" ||
      this.state.ruleAction === "send_sms"
    ) {
      if (this.state.selectedRuleIndex > -1) {
        /*eslint-disable*/
        await callUpdateTextMessage(this.state.smsId, {
          name: this.state.smsName,
          message: this.state.smsMessage,
          send_time_type: this.state.sendTimeType,
          selected_hour: this.state.selectedHour,
          selected_mins: this.state.selectedMins,
          selected_time_type: this.state.selectedTimeType,
        });
        /*eslint-enable*/
        rule.smsLinked = this.state.smsId;
        rule.tagId = this.state.tagId;
      } else {
        let dOpe = new ChallengeOperations();
        /*eslint-disable*/
        let response = await dOpe.createTaskRuleSMS(
          this.state.ruleType,
          this.state.smsName,
          this.state.smsMessage,
          {
            send_time_type: this.state.sendTimeType,
            selected_hour: this.state.selectedHour,
            selected_mins: this.state.selectedMins,
            selected_time_type: this.state.selectedTimeType,
          }
        );
        /*eslint-enable*/
        rule.smsLinked = response.smsId;
        rule.tagId = response.tagId;
      }
    }

    if (this.state.selectedRuleIndex > -1) {
      taskRules[this.state.selectedRuleIndex] = rule;
    } else {
      taskRules.push(rule);
    }

    this.setState({
      taskRules,
      selectedRuleIndex: -1,
      ruleType: "",
      ruleAction: "",
      ruleCondition: "",
      ruleField: "",
      ruleValue: "",
      ruleThen: "",
      ruleForm: "",
      ruleWhenStatus: "",
      ruleWhenStatusValue: "",
      ruleStatus: "",
      ruleInvoice: "",
      ruleInvoiceField: "",
      ruleInvoiceFieldValue: "",
      ruleInvoiceFields: [],
      ruleInvoiceType: "",
      emailNotiName: "",
      emailNotiContent: {},
      emailNotiHtml: "",
      keyPageContent: Math.random(),
      sendTimeType: "send",
      smsName: "",
      smsMessage: "",
      isGMSMessage: true,
      noOfMessages: 1,
      keyContent: Math.random(),
      selectedTimeType: "",
      selectedHour: "",
      selectedMins: "",
      selectedTimeIndex: -1,
      selectedDay: "",
      selectedMonth: moment().format("MM"),
      selectedYear: moment().format("YYYY"),
      invoiceField: "",
      invoiceFieldValue: "",
      emailId: "",
      smsId: "",
      tagId: "",
      ruleFromTo: "assigned_user",
      ruleFrom: "",
      ruleStaffUserId: "",
    });

    this.props.onClickSaveInfo({ taskRules: taskRules });
  }

  onSortEndRules = ({ oldIndex, newIndex }) => {
    let taskRules = this.state.taskRules;
    taskRules = arrayMove(taskRules, oldIndex, newIndex);

    this.setState({
      taskRules: taskRules,
    });
    this.props.onClickSaveInfo({ taskRules: taskRules });
  };

  onClickDeleteRule(e) {
    let index = e.target.dataset["value"];
    this.setState({ selectedRuleIndex: index }, () => {
      $(
        ".modalAlertOverlay.confirmDeleteRuleModal,.modalAlert.confirmDeleteRuleAlert"
      ).fadeIn(200);
    });
  }

  async onPressEditRule(e) {
    let index = e.target.dataset["value"];
    let rules = this.state.taskRules;

    let rule = rules[index];

    let emailNotiName = "";
    let emailNotiContent = {};
    let emailNotiHtml = "";
    let smsName = "";
    let smsMessage = "";
    let sendTimeType = "";
    let selectedHour = "";
    let selectedMins = "";
    let selectedTimeType = "";

    if (rule.action === "send_user_sms") {
      const response = await callGetTextMessageById(rule.smsLinked);
      if (response._id) {
        smsName = response.name;
        smsMessage = response.message;
        sendTimeType = response.send_time_type;
        selectedHour = response.selected_hour;
        selectedMins = response.selected_mins;
        selectedTimeType = response.selected_time_type;
      }
    }
    if (
      rule.action === "send_staff_email" ||
      rule.action === "send_user_email"
    ) {
      const response = await callGetEmailUsingId(rule.emailLinked);
      if (response._id) {
        emailNotiName = response.title;
        emailNotiContent = response.content;
        emailNotiHtml = response.html;
        sendTimeType = response.send_time_type;
        selectedHour = response.selected_hour;
        selectedMins = response.selected_mins;
        selectedTimeType = response.selected_time_type;
      }
    }

    this.setState({
      ruleType: rule.type,
      ruleAction: rule.action,
      ruleField: rule.field,
      fieldType: rule.fieldType,
      ruleCondition: rule.condition,
      ruleValue: rule.value,
      ruleThen: rule.then,
      ruleForm: rule.form,
      ruleStatus: rule.status,
      ruleWhenStatus: rule.statusWhen,
      ruleWhenStatusValue: rule.statusWhenValue,
      ruleInvoice: rule.invoiceId,
      ruleInvoiceFields: rule.invoiceFields,
      ruleInvoiceType: rule.invoiceType,
      ruleInvoiceField: rule.invoiceField,
      ruleInvoiceFieldValue: rule.invoiceFieldValue,
      selectedRuleIndex: index,
      emailId: rule.emailLinked,
      smsId: rule.smsLinked,
      tagId: rule.tagId,
      emailNotiName: emailNotiName,
      emailNotiContent: emailNotiContent,
      emailNotiHtml: emailNotiHtml,
      smsName: smsName,
      smsMessage: smsMessage,
      sendTimeType: sendTimeType,
      selectedHour: selectedHour,
      selectedMins: selectedMins,
      selectedTimeType: selectedTimeType,
      keyContent: Math.random(),
    });
  }

  onClickNoAlert = () => {
    $(
      ".modalAlertOverlay.confirmDeleteRuleModal,.modalAlert.confirmDeleteRuleAlert"
    ).fadeOut(200);
    this.setState({ selectedRuleIndex: -1 });
  };

  onClickYesAlert = () => {
    let rules = this.state.taskRules;
    rules.splice(this.state.selectedRuleIndex, 1);

    this.setState({ taskRules: rules, selectedRuleIndex: -1 });
    this.props.onClickSaveInfo({ taskRules: rules });
    $(
      ".modalAlertOverlay.confirmDeleteRuleModal,.modalAlert.confirmDeleteRuleAlert"
    ).fadeOut(200);
  };

  renderConfirmationAlert() {
    let selectedRuleIndex = this.state.selectedRuleIndex;
    if (selectedRuleIndex === -1) {
      return;
    }
    return (
      <div>
        <div className="modalAlertOverlay confirmDeleteRuleModal" />
        <div className="modalAlert confirmDeleteRuleAlert">
          <p>Are you sure you want to delete this rule?</p>
          <div className="alertFooterBtns">
            <button
              className="btn btn-default closeModal-No margin-right-10"
              onClick={this.onClickNoAlert}>
              No
            </button>
            <button
              className="btn btn-primary closeModal-Yes"
              onClick={this.onClickYesAlert}>
              Yes
            </button>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="card">
        {this.renderConfirmationAlert()}
        <div className="container-fluid">
          <div className="gap20" />

          <div className="havingHelpText">
            <h5 className="noMargin">Task Rules</h5>
          </div>

          <div className="row">
            <div className="col-md-4">
              <div
                className={
                  this.state.ruleType !== "" ? "mdInput mdFocussed" : "mdInput"
                }>
                <label>Rule Type</label>
                <select
                  name="ruleType"
                  value={this.state.ruleType}
                  onChange={(event) => this.handleInputChange(event)}>
                  <option value="" />
                  <option value="assigned_to_user">Assigned To User</option>
                  <option value="unassigned_user">Unassigned User</option>
                  <option value="select_field">Field</option>
                  <option value="new_task_created">New Task Created</option>
                  <option value="form_completed">Form Completed</option>
                  <option value="on_task_comment">On Task Comment</option>
                  <option value="task_opened_assigned_user">
                    Task Opened By Assigned User
                  </option>
                </select>
              </div>
            </div>
            {this.state.ruleType === "assigned_to_user" && (
              <div className="col-md-4">
                <div
                  className={
                    this.state.ruleCondition !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Select Condition</label>
                  <select
                    name="ruleCondition"
                    value={this.state.ruleCondition}
                    onChange={(event) => this.handleInputChange(event)}>
                    <option value="" />
                    <option value="instantly">Instantly</option>
                    <option value="if_not_opened">If Not Opened</option>
                  </select>
                </div>
              </div>
            )}
            {this.state.ruleType === "assigned_to_user" &&
              this.state.ruleCondition !== "" &&
              this.state.ruleCondition === "if_not_opened" && (
                <div className="col-md-4">
                  <div
                    className={
                      this.state.ruleValue !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>Days</label>
                    <input
                      type="text"
                      name="ruleValue"
                      onChange={this.handleInputChange.bind(this)}
                      value={this.state.ruleValue}
                    />
                  </div>
                </div>
              )}
            {this.state.ruleType === "assigned_to_user" &&
              this.state.ruleCondition !== "" && (
                <div className="col-md-4">
                  <div
                    className={
                      this.state.ruleAction !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>Select Action</label>
                    <select
                      name="ruleAction"
                      value={this.state.ruleAction}
                      onChange={(event) => this.handleInputChange(event)}>
                      <option value="" />
                      <option value="send_user_email">Send User Email</option>
                      <option value="send_user_sms">Send User SMS</option>
                    </select>
                  </div>
                </div>
              )}
            {(this.state.ruleType === "task_opened_assigned_user" ||
              this.state.ruleType === "unassigned_user") && (
              <div className="col-md-4">
                <div
                  className={
                    this.state.ruleAction !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Select Action</label>
                  <select
                    name="ruleAction"
                    value={this.state.ruleAction}
                    onChange={(event) => this.handleInputChange(event)}>
                    <option value="" />
                    <option value="update_status">Update Status</option>
                  </select>
                </div>
              </div>
            )}
            {this.state.ruleType === "on_task_comment" && (
              <div className="col-md-4">
                <div
                  className={
                    this.state.ruleFrom !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Select From</label>
                  <select
                    name="ruleFrom"
                    value={this.state.ruleFrom}
                    onChange={(event) => this.handleInputChange(event)}>
                    <option value="" />
                    <option value="user">From User</option>
                    <option value="staff">From Staff</option>
                  </select>
                </div>
              </div>
            )}
            {this.state.ruleFrom === "user" && (
              <div className="col-md-4">
                <div
                  className={
                    this.state.ruleStaffUserId !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Select Staff User</label>
                  <select
                    className="selectRuleTypeInput"
                    name="ruleStaffUserId"
                    value={this.state.ruleStaffUserId}
                    onChange={(event) => this.handleInputChange(event)}>
                    <option value="" />
                    {this.state.staffUsers.map((i) => (
                      <option value={i._id}>{i.name}</option>
                    ))}
                  </select>
                </div>
              </div>
            )}
            {this.state.ruleFrom === "staff" && (
              <div className="col-md-4">
                <div
                  className={
                    this.state.ruleFromTo !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Select To</label>
                  <select
                    name="ruleFromTo"
                    value={this.state.ruleFromTo}
                    onChange={(event) => this.handleInputChange(event)}>
                    <option value="assigned_user">To Assigned User</option>
                  </select>
                </div>
              </div>
            )}
            {this.state.ruleType === "on_task_comment" &&
              this.state.ruleFrom !== "" && (
                <div className="col-md-4">
                  <div
                    className={
                      this.state.ruleAction !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>Select Action</label>
                    <select
                      name="ruleAction"
                      value={this.state.ruleAction}
                      onChange={(event) => this.handleInputChange(event)}>
                      <option value="" />
                      <option value="send_email">Send Email</option>
                      <option value="send_sms">Send SMS</option>
                    </select>
                  </div>
                </div>
              )}
            {this.state.ruleType === "select_field" && (
              <div className="col-md-4">
                <div
                  className={
                    this.state.ruleField !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Select Field</label>
                  <select
                    name="ruleField"
                    value={this.state.ruleField}
                    onChange={(event) => this.handleInputChange(event)}>
                    <option value="" />
                    {this.state.fieldList.map((i) => (
                      <option value={i.value}>{i.name}</option>
                    ))}
                  </select>
                </div>
              </div>
            )}
            {this.state.ruleType === "new_task_created" && (
              <div className="col-md-4">
                <div
                  className={
                    this.state.ruleAction !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Select Action</label>
                  <select
                    name="ruleAction"
                    value={this.state.ruleAction}
                    onChange={(event) => this.handleInputChange(event)}>
                    <option value="" />
                    <option value="create_invoice">Create Invoice</option>
                  </select>
                </div>
              </div>
            )}
            {this.state.ruleType === "new_task_created" &&
              this.state.ruleAction === "create_invoice" && (
                <div className="col-md-4">
                  <div
                    className={
                      this.state.ruleInvoiceType !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>Select Invoice Type</label>
                    <select
                      name="ruleInvoiceType"
                      value={this.state.ruleInvoiceType}
                      onChange={(event) => this.handleInputChange(event)}>
                      <option value="" />
                      <option value="outgoing">Outgoing</option>
                      <option value="incoming">Incoming</option>
                    </select>
                  </div>
                </div>
              )}
            {this.state.ruleType === "form_completed" && (
              <div className="col-md-4">
                <div
                  className={
                    this.state.ruleAction !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Select Action</label>
                  <select
                    name="ruleAction"
                    value={this.state.ruleAction}
                    onChange={(event) => this.handleInputChange(event)}>
                    <option value="" />
                    <option value="send_staff_email">Send Staff Email</option>
                    <option value="update_status">Update Status</option>
                  </select>
                </div>
              </div>
            )}
            {this.state.ruleType === "select_field" && (
              <div className="col-md-4">
                <div
                  className={
                    this.state.ruleCondition !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Select Condition</label>
                  <select
                    name="ruleCondition"
                    value={this.state.ruleCondition}
                    onChange={(event) => this.handleInputChange(event)}>
                    <option value="" />
                    <option value="is_equal">Is Equal To</option>
                    <option value="is_not_equal">Is Not Equal To</option>
                    <option value="is_changed">Is Changed</option>
                  </select>
                </div>
              </div>
            )}
            {this.state.ruleType === "select_field" &&
              this.state.ruleCondition !== "" &&
              this.state.ruleCondition !== "is_changed" && (
                <div className="col-md-4">
                  <div
                    className={
                      this.state.ruleValue !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>Rule Value</label>
                    <input
                      type="text"
                      name="ruleValue"
                      onChange={this.handleInputChange.bind(this)}
                      value={this.state.ruleValue}
                    />
                  </div>
                </div>
              )}
            {this.state.ruleType === "select_field" &&
              this.state.ruleCondition !== "" && (
                <div className="col-md-4">
                  <div
                    className={
                      this.state.ruleThen !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>Select Then</label>
                    <select
                      name="ruleThen"
                      value={this.state.ruleThen}
                      onChange={(event) => this.handleInputChange(event)}>
                      <option value="" />
                      <option value="assign_form">Assign Form</option>
                      <option value="update_status">Update Status</option>
                      <option value="update_incoming_invoice">
                        Update Bill Field
                      </option>
                      <option value="update_outgoing_invoice">
                        Update Invoice Field
                      </option>
                    </select>
                  </div>
                </div>
              )}
            {this.state.ruleType === "select_field" &&
              this.state.ruleThen === "assign_form" && (
                <div className="col-md-4">
                  <div
                    className={
                      this.state.ruleForm !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>Select Form</label>
                    <select
                      name="ruleForm"
                      value={this.state.ruleForm}
                      onChange={(event) => this.handleInputChange(event)}>
                      <option value="" />
                      {this.state.forms.map((i) => (
                        <option value={i._id}>{i.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
            {this.state.ruleType === "select_field" &&
              (this.state.ruleThen === "update_incoming_invoice" ||
                this.state.ruleThen === "update_outgoing_invoice") && (
                <div className="col-md-4">
                  <div
                    className={
                      this.state.ruleInvoiceField !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>Select Invoice Field</label>
                    <select
                      name="ruleInvoiceField"
                      value={this.state.ruleInvoiceField}
                      onChange={(event) => this.handleInputChange(event)}>
                      <option value="" />
                      <option value="unique_id">Invoice ID</option>
                      <option value="amount">Amount</option>
                      <option value="description">Invoice Description</option>
                    </select>
                  </div>
                </div>
              )}
            {this.state.ruleType === "select_field" &&
              (this.state.ruleThen === "update_incoming_invoice" ||
                this.state.ruleThen === "update_outgoing_invoice") &&
              this.state.ruleInvoiceField !== "" && (
                <div className="col-md-4">
                  <div
                    className={
                      this.state.ruleInvoiceFieldValue !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>Invoice Value</label>
                    <input
                      type="text"
                      name="ruleInvoiceFieldValue"
                      onChange={this.handleInputChange.bind(this)}
                      value={this.state.ruleInvoiceFieldValue}
                    />
                  </div>
                </div>
              )}
            {(this.state.ruleType === "select_field" ||
              this.state.ruleType === "form_completed" ||
              this.state.ruleType === "task_opened_assigned_user" ||
              this.state.ruleType === "unassigned_user") &&
              (this.state.ruleThen === "update_status" ||
                this.state.ruleAction === "update_status") && (
                <div className="col-md-4">
                  <div
                    className={
                      this.state.ruleStatus !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>Select Status</label>
                    <select
                      name="ruleStatus"
                      value={this.state.ruleStatus}
                      onChange={(event) => this.handleInputChange(event)}>
                      <option value="" />
                      {this.state.statusList.map((i) => (
                        <option value={i.name}>{i.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
            {(this.state.ruleType === "select_field" ||
              this.state.ruleType === "task_opened_assigned_user" ||
              this.state.ruleType === "unassigned_user") &&
              (this.state.ruleThen === "update_status" ||
                this.state.ruleAction === "update_status") && (
                <div className="col-md-4">
                  <div
                    className={
                      this.state.ruleWhenStatus !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>Select When</label>
                    <select
                      name="ruleWhenStatus"
                      value={this.state.ruleWhenStatus}
                      onChange={(event) => this.handleInputChange(event)}>
                      <option value="" />
                      <option value="always">Always</option>
                      <option value="if_status">If Task Status is</option>
                    </select>
                  </div>
                </div>
              )}
            {(this.state.ruleType === "select_field" ||
              this.state.ruleType === "task_opened_assigned_user" ||
              this.state.ruleType === "unassigned_user") &&
              (this.state.ruleThen === "update_status" ||
                this.state.ruleAction === "update_status") &&
              this.state.ruleWhenStatus === "if_status" && (
                <div className="col-md-4">
                  <div
                    className={
                      this.state.ruleWhenStatusValue !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>Status</label>
                    <select
                      name="ruleWhenStatusValue"
                      value={this.state.ruleWhenStatusValue}
                      onChange={(event) => this.handleInputChange(event)}>
                      <option value="" />
                      {this.state.statusList.map((i) => (
                        <option value={i.name}>{i.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
          </div>
          <div className="gap20" />
          {(this.state.ruleType === "assigned_to_user" ||
            this.state.ruleType === "form_completed" ||
            this.state.ruleType === "on_task_comment") &&
            (this.state.ruleAction === "send_user_email" ||
              this.state.ruleAction === "send_staff_email" ||
              this.state.ruleAction === "send_email") && (
              <div>
                <div
                  className={
                    this.state.emailNotiName !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Email Name</label>
                  <input
                    type="text"
                    className="challengeName"
                    name="emailNotiName"
                    value={this.state.emailNotiName}
                    onChange={(event) => this.handleInputChange(event)}
                  />
                </div>
                <div className="gap20" />
                <PageContent
                  key={this.state.keyContent}
                  title={this.state.emailNotiName}
                  displayMode={"email"}
                  previewType={"iframe"}
                  screen={"add-task-rule-user-email"}
                  html={this.state.emailNotiHtml}
                  content={this.state.emailNotiContent}
                  editorType={"unlayer"}
                  onChangeData={this.onChangeNotiEmailContent.bind(this)}
                  pageName={"addTaskRuleUserEmail"}
                />
                <div className="gap10" />
                {this.renderSendTime()}
              </div>
            )}

          {(this.state.ruleType === "assigned_to_user" ||
            this.state.ruleType === "on_task_comment") &&
            (this.state.ruleAction === "send_user_sms" ||
              this.state.ruleAction === "send_sms") && (
              <div>
                <div className="alert alert-info">
                  When sending automated sms messages as challenge rules please
                  be sure they are useful and specific and preferably something
                  the user will respond back to. For example a good message is
                  "Hey {this.getFirstName()}, thank you for completing XYZ. I
                  would really love to get your feedback on the course. Can you
                  please let me know what you thought about it?"
                  <br />
                  <br />A message that would be better sent as an app
                  notification would be something like: "Hey{" "}
                  {this.getFirstName()} It's day one of your challenge and you
                  can click here to view the content now." The customer is not
                  expected to respond to this message and it is more likely to
                  be viewed as spam via the phone carriers.
                </div>
                <div
                  className={
                    this.state.smsName !== "" ? "mdInput mdFocussed" : "mdInput"
                  }>
                  <label>Name</label>
                  <input
                    type="text"
                    name="smsName"
                    onChange={this.handleInputChange.bind(this)}
                    value={this.state.smsName}
                  />
                </div>
                <div className="gap10" />
                <div
                  className={
                    this.state.smsMessage !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Message</label>
                  <textarea
                    style={{ height: "auto" }}
                    rows={5}
                    type="text"
                    name="smsMessage"
                    onChange={this.handleMessageChange.bind(this)}
                    value={this.state.smsMessage}
                  />
                </div>
                <div className="gap10" />
                <label>
                  {this.state.smsMessage.length} /{" "}
                  {this.state.isGMSMessage ? "140" : "67"}
                </label>
                {!this.state.isGMSMessage && (
                  <>
                    <div className="gap20" />
                    <label>
                      Note: Special characters detected. Message character limit
                      set to 67 characters.
                    </label>
                  </>
                )}
                {this.getNoOfMessages() > 1 && (
                  <>
                    <div className="gap20" />
                    <label className="text-primary">
                      This message has more characters than the limit. You can
                      still send it as a single message but will be charged for{" "}
                      {this.getNoOfMessages()} messages per user. This is due to
                      the network carrier fees etc.
                    </label>
                  </>
                )}
                <div className="gap20" />
                {this.renderSendTime()}
              </div>
            )}

          {this.state.ruleType === "new_task_created" &&
            this.state.ruleAction === "create_invoice" && (
              <div className="card">
                <div className="container-fluid">
                  <div className="gap10"></div>
                  <p>Map Invoice Fields</p>
                  <div className="row">
                    <div className="col-sm-4">
                      <div
                        className={
                          this.state.invoiceField !== ""
                            ? "mdInput mdFocussed"
                            : "mdInput"
                        }>
                        <label>Select Field</label>
                        <select
                          className="selectRuleTypeInput"
                          name="invoiceField"
                          value={this.state.invoiceField}
                          onChange={(event) => this.handleInputChange(event)}>
                          <option value="" />
                          <option value="id">Invoice ID</option>
                          <option value="amount">Amount</option>
                          <option value="description">
                            Invoice Description
                          </option>
                        </select>
                      </div>
                      <div className="gap20" />
                    </div>
                    <div className="col-sm-4">
                      <div
                        className={
                          this.state.invoiceFieldValue !== ""
                            ? "mdInput mdFocussed"
                            : "mdInput"
                        }>
                        <label>Select Value</label>
                        <select
                          className="selectRuleTypeInput"
                          name="invoiceFieldValue"
                          value={this.state.invoiceFieldValue}
                          onChange={(event) => this.handleInputChange(event)}>
                          <option value="" />
                          {this.state.fieldList.map((i) => (
                            <option value={i.value}>{i.name}</option>
                          ))}
                        </select>
                      </div>
                      <div className="gap20" />
                    </div>
                  </div>
                  <div className="text-right">
                    <button
                      className="btn btn-primary addRuleBtn"
                      onClick={() => this.onClickAddMapField()}>
                      Add
                    </button>
                  </div>
                  <div className="gap20" />
                  {this.state.ruleInvoiceFields.length > 0 && (
                    <>
                      <h5 className="noMargin">Added Invoice Fields</h5>
                      <div className="gap20" />

                      <div className="addedRule">{this.renderMapFields()}</div>
                      <div className="gap20" />
                    </>
                  )}
                </div>
              </div>
            )}

          <div className="gap20" />
          <div className="text-right">
            {this.state.selectedRuleIndex > -1 && (
              <button
                className="btn btn-default margin-right-10"
                onClick={this.onClickCancelRule.bind(this)}>
                Cancel
              </button>
            )}
            <button
              className="btn btn-primary addRuleBtn"
              onClick={() => this.onClickAddRule()}>
              {this.state.selectedRuleIndex > -1 ? "Update Rule" : "Add Rule"}
            </button>
          </div>
          <div className="gap20" />
          {this.state.taskRules.length > 0 && (
            <>
              <h5 className="noMargin">Added Task Rules</h5>
              <div className="gap20" />
              <SortableTaskRuleList
                distance={10}
                task={true}
                fieldList={this.state.fieldList}
                items={this.state.taskRules}
                onSortEnd={this.onSortEndRules}
                onPressEdit={this.onPressEditRule.bind(this)}
                onPressRemove={this.onClickDeleteRule.bind(this)}
              />
              <div className="gap20" />
            </>
          )}
        </div>
      </div>
    );
  }
}

export default TaskSettingRuleList;
