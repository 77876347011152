import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import DatePicker from "react-date-picker";

import TitleBox from "../../../components/general/titleBox";
import {
  callCreateInvoice,
  callGetInvoiceById,
  callUpdateInvoice,
} from "../../../services";
import {
  getParameterByName,
  getUniqueId,
  showNotification,
} from "../../../helpers";
import RadioTag from "../../../components/form/radioTag";
import { callGetSettings } from "../../../services/settingService";

class AddInvoice extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    let id = getParameterByName("id") || 0;

    let invoiceInfo = {
      id: id,
      uniqueId: getUniqueId(),
      description: "",
      dueDate: new Date(),
      amount: "",
      status: "open",
      type: "outgoing",
    };

    this.state = {
      invoiceId: invoiceInfo.id,
      amount: invoiceInfo.amount,
      unqiueId: invoiceInfo.uniqueId,
      description: invoiceInfo.description,
      dueDate: invoiceInfo.dueDate,
      status: invoiceInfo.status,
      type: invoiceInfo.type,
      keyDate: Math.random(),
      customFields: [],
      keyCustomField: Math.random(),
      keyRadio: Math.random(),
    };
  }

  componentDidMount() {
    this.getData();
  }

  async getData() {
    await this.getSettings();
    if (this.state.invoiceId !== 0) {
      this.calllInvoiceById();
    }
  }

  async getSettings() {
    let d = await callGetSettings("invoicesettings");
    let settings = d.data;
    let invoiceSettings = settings.invoice_settings || {};
    let customFields = invoiceSettings.custom_fields || [];
    this.setState({ customFields });
  }

  async calllInvoiceById() {
    const invoiceInfo = await callGetInvoiceById(this.state.invoiceId);

    if (invoiceInfo._id) {
      let invoiceCustomFields = invoiceInfo.custom_fields || [];
      let values = invoiceCustomFields.map(
        (i) => i.selectedOption && i.selectedOption.id
      );
      let ids = invoiceCustomFields.map((i) => i.id);
      this.manageCustomFields(values, ids);

      let textCustomFields = invoiceCustomFields.filter(
        (d) => d.type === "text"
      );
      this.manageTextCustomFields(textCustomFields);

      let multipleCustomFields = invoiceCustomFields.filter(
        (d) => d.type === "multiple"
      );
      this.manageMultipleCustomFields(multipleCustomFields);

      this.setState({
        invoiceId: invoiceInfo._id,
        amount: invoiceInfo.amount,
        unqiueId: invoiceInfo.unique_id,
        description: invoiceInfo.description,
        dueDate: invoiceInfo.due_date,
        status: invoiceInfo.status,
        type: invoiceInfo.type || "outgoing",
        keyDate: Math.random(),
        keyRadio: Math.random(),
      });
    }
  }

  resetProps() {
    this.setState({
      uniqueId: getUniqueId(),
      description: "",
      dueDate: new Date(),
      status: "open",
      amount: "",
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  onChangeDueDate(date) {
    this.setState({ dueDate: date });
  }

  onSelectStatus(item, id) {
    let value = item[Object.keys(item)[0]].value;
    this.setState({ [id]: value });
  }

  onClickAddInvoice() {
    if (this.state.description.length === 0) {
      showNotification("error", "Please add description", 4000);
      return;
    }

    if (this.state.amount.length === 0) {
      showNotification("error", "Please add amount", 4000);
      return;
    }

    /*eslint-disable*/
    let apiData = {
      unique_id: this.state.unqiueId,
      description: this.state.description,
      amount: this.state.amount,
      due_date: this.state.dueDate,
      status: this.state.status,
      type: this.state.type,
      custom_fields: this.state.customFields,
      created_by: "normal",
    };
    /*eslint-enable*/

    if (this.state.invoiceId === 0) {
      callCreateInvoice(apiData).then((d) => {
        if (d._id) {
          this.resetProps();
          showNotification("success", "Invoice Created Successfully", 4000);
        } else {
          showNotification("error", "Something went wrong", 4000);
        }
      });
    } else {
      callUpdateInvoice(this.state.invoiceId, apiData).then((d) => {
        if (d._id) {
          showNotification("success", "Invoice Updated Successfully", 4000);
        } else {
          showNotification("error", "Something went wrong", 4000);
        }
      });
    }
  }

  manageCustomFields(values, ids) {
    let customFields = this.state.customFields || [];

    customFields.forEach((element) => {
      if (ids.indexOf(element.id) > -1) {
        let selectedOption = element.options.filter((d) => {
          return values.indexOf(d.id) > -1;
        });
        if (selectedOption.length > 0) {
          element["selectedOption"] = selectedOption[0];
        }
        let defaultSelectedOption = element.options.filter((d) => {
          return d.selected === true;
        });
        if (
          defaultSelectedOption.length > 0 &&
          !element["selectedOption"].value
        ) {
          element["selectedOption"] = defaultSelectedOption[0];
        }
      }
      if (ids.length === 0) {
        element["selectedOption"] = {};
      }
    });

    this.setState({
      customFields: customFields,
      keyCustomField: Math.random(),
    });
  }

  manageMultipleCustomFields(multipleCustomFields) {
    let customFields = this.state.customFields || [];

    customFields.forEach((element) => {
      let selectedOptions = multipleCustomFields.filter(
        (d) => d.id === element.id
      );
      if (selectedOptions.length > 0) {
        element.selectedOptions = selectedOptions[0].selectedOptions;
      } else {
        element.selectedOptions = [];
      }
    });

    this.setState({
      customFields: customFields,
      keyCustomField: Math.random(),
    });
  }

  manageTextCustomFields(textCustomFields) {
    let customFields = this.state.customFields || [];

    customFields.forEach((element) => {
      let selectedOptions = textCustomFields.filter((d) => d.id === element.id);
      if (selectedOptions.length > 0) {
        element.selectedOption = selectedOptions[0].selectedOption;
      } else {
        element.selectedOption = { value: "" };
      }
    });

    this.setState({
      customFields: customFields,
      keyCustomField: Math.random(),
    });
  }

  handleTextChange(event, id, label) {
    const target = event.target;
    const value = target.value;

    let customFields = this.state.customFields || [];
    customFields.forEach((element) => {
      if (element.id === id) {
        element["selectedOption"] = { label: label, id: id, value: value };
      }
    });
    this.setState({
      customFields: customFields,
    });
  }

  onSelectCustomOption(item, id) {
    this.manageCustomFields([item[Object.keys(item)[0]].value], [id]);
  }

  onSelectCustomOptions(item, id) {
    let customFields = this.state.customFields || [];
    let values = [];

    if (Object.keys(item).length > 0 && item.constructor === Object) {
      Object.keys(item).forEach((key) => {
        values.push(item[key].value);
      });
    }

    customFields.forEach((element) => {
      if (element.id === id) {
        let selectedOptions = element.options.filter((d) => {
          return values.indexOf(d.id) > -1;
        });
        if (selectedOptions.length > 0) {
          element["selectedOptions"] = selectedOptions;
        } else {
          element["selectedOptions"] = [];
        }
      }
    });

    this.setState({
      customFields: customFields,
      keyCustomField: Math.random(),
    });
  }

  renderCustomFields() {
    let customFields = this.state.customFields;
    let returnVals = [];

    for (let i = 0; i < customFields.length; i++) {
      const element = customFields[i];

      let optionList = [];
      let selectedOption = element.selectedOption || {};
      let selectedOptions = element.selectedOptions || [];
      element.options.forEach((opElem) => {
        if (!selectedOption.id && opElem.selected && opElem.type === "single") {
          selectedOption = opElem;
        }
        if (
          selectedOptions.length === 0 &&
          opElem.selected &&
          opElem.type === "multiple"
        ) {
          selectedOptions.push(opElem);
        }
        optionList.push({ name: opElem.label, value: opElem.id });
      });
      selectedOptions = selectedOptions.map((i) => i.id);
      let type = element.type || "single";
      returnVals.push(
        <>
          <div className="row">
            <div className="col-sm-12">
              {type === "single" && (
                <>
                  <label className="firstLetter"> {element.label} </label>
                  <div className="tabsHolder radioTabs">
                    <RadioTag
                      onCheckChange={this.onSelectCustomOption.bind(this)}
                      id={element.id}
                      labelList={optionList}
                      selectType={"value"}
                      key={this.state.keyCustomField}
                      selectedList={[selectedOption && selectedOption.id]}
                    />
                  </div>
                </>
              )}
              {type === "text" && (
                <div
                  className={
                    selectedOption.value !== "" &&
                    selectedOption.value !== undefined
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>{element.label}</label>
                  <textarea
                    value={selectedOption.value}
                    onChange={(event) =>
                      this.handleTextChange(event, element.id, element.label)
                    }
                  />
                </div>
              )}
              {type === "multiple" && (
                <>
                  <label className="firstLetter"> {element.label} </label>
                  <div className="tabsHolder radioTabs">
                    <RadioTag
                      onCheckChange={this.onSelectCustomOptions.bind(this)}
                      id={element.id}
                      labelList={optionList}
                      selectType={"value"}
                      type={"multiple"}
                      key={this.state.keyCustomField}
                      selectedList={selectedOptions}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="gap20" />
        </>
      );
    }
    return returnVals;
  }

  render() {
    return (
      <div>
        <div className="container-fluid">
          <TitleBox
            title={
              this.state.invoiceId !== 0 ? "Update Invoice" : "Add Invoice"
            }
            showBackBtn={this.state.invoiceId !== 0}
          />
          <div className="gap20" />
          <div className="card">
            <div className="container-fluid">
              <div className="gap20" />
              <div
                className={
                  this.state.unqiueId !== "" ? "mdInput mdFocussed" : "mdInput"
                }>
                <label>Invoice ID</label>
                <input
                  type="text"
                  name="unqiueId"
                  value={this.state.unqiueId}
                  onChange={(event) => this.handleInputChange(event)}
                />
              </div>
              <div className="gap20" />
              <div
                className={
                  this.state.description !== ""
                    ? "mdInput mdFocussed"
                    : "mdInput"
                }>
                <label>Description</label>
                <input
                  type="text"
                  name="description"
                  value={this.state.description}
                  onChange={(event) => this.handleInputChange(event)}
                />
              </div>
              <div className="gap20" />
              <div
                className={
                  this.state.amount !== "" ? "mdInput mdFocussed" : "mdInput"
                }>
                <label>Amount</label>
                <input
                  type="number"
                  name="amount"
                  value={this.state.amount}
                  onChange={(event) => this.handleInputChange(event)}
                />
              </div>
              <div className="gap20" />
              <div className="tabsHolder radioTabs">
                <label className="fakeLabel">Status</label>
                <br />
                <RadioTag
                  key={this.state.keyRadio}
                  onCheckChange={this.onSelectStatus.bind(this)}
                  labelList={[
                    { name: "Open", value: "open" },
                    { name: "Pending", value: "pending" },
                    { name: "Closed", value: "closed" },
                  ]}
                  id={"status"}
                  selectedList={[this.state.status]}
                  selectType={"value"}
                />
              </div>
              <div className="gap20" />
              <div className="tabsHolder radioTabs">
                <label className="fakeLabel">Type</label>
                <br />
                <RadioTag
                  key={this.state.keyRadio}
                  onCheckChange={this.onSelectStatus.bind(this)}
                  labelList={[
                    { name: "Outgoing", value: "outgoing" },
                    { name: "Incoming", value: "incoming" },
                  ]}
                  id={"type"}
                  selectedList={[this.state.type]}
                  selectType={"value"}
                />
              </div>
              <div className="gap20" />
              <label className="inputFakeLabelFocussed">Due Date</label>
              <div>
                <DatePicker
                  key={this.state.keyDate}
                  value={this.state.dueDate}
                  onChange={this.onChangeDueDate.bind(this)}
                />
              </div>
              <div className="gap20" />
              <div key={this.state.keyCustomField}>
                {this.renderCustomFields()}
              </div>
              <div className="gap20" />
            </div>
          </div>

          <div className="text-right">
            <button
              className="btn btn-primary"
              onClick={() => this.onClickAddInvoice()}>
              {this.state.invoiceId !== 0 ? "Update Invoice" : "Add Invoice"}
            </button>
          </div>
          <div className="gap20" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddInvoice);
