import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { closePagePopupModal } from "../../helpers";

import AddChallengeItem from "../../views/admin/challenge/addChallengeItem";
import AddChallengeQue from "../../views/admin/challenge/addChallengeQue";
import AddSystemTag from "../../views/admin/tag/addSystemTag";

import AddForm from "../../views/admin/forms/addForm";
import AddEmail from "../../views/admin/email/addEmail";
import AddLandingPage from "../../views/admin/landing-page/addLandingPage";

class PagePopup extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      label: this.props.label,
      type: this.props.type,
      challengeItem: this.props.challengeItem,
      challengeId: this.props.challengeId,
      systemTagId: this.props.systemTagId,
      landingPage: this.props.landingPage,
      challengeInfo: this.props.challengeInfo || {},
      questionTag: this.props.questionTag || {},
      questionName: this.props.questionName || "",
      newQuestionType: this.props.newQuestionType || "",
      systemTagInfo: this.props.systemTagInfo || {},
    };
  }

  onClickClose = () => {
    closePagePopupModal(this.state.type);
  };

  onCreateItem = (id = "") => {
    this.onClickClose();
    this.props.onCreate(this.state.type, id);
  };

  getComponent() {
    let type = this.state.type;
    switch (type) {
      case "challengeItem":
        return (
          <AddChallengeItem
            fromPopup={true}
            onCreate={this.onCreateItem}
            challengeInfo={this.state.challengeInfo}
          />
        );
      case "editChallengeItem":
        return (
          <AddChallengeItem
            fromPopup={true}
            onCreate={this.onCreateItem}
            challengeItem={this.state.challengeItem}
            challengeInfo={this.state.challengeInfo}
          />
        );
      case "addTag":
        return <AddSystemTag fromPopup={true} onCreate={this.onCreateItem} />;
      case "editTag":
        return (
          <AddSystemTag
            fromPopup={true}
            onCreate={this.onCreateItem}
            tagInfo={this.state.systemTagInfo}
          />
        );
      case "atqQuestion":
        return (
          <AddChallengeQue
            fromPopup={true}
            onCreate={this.onCreateItem}
            questionTag={this.state.questionTag}
            questionName={this.state.questionName}
            newQuestionType={this.state.newQuestionType}
          />
        );
      case "newForm":
        return <AddForm fromPopup={true} onCreate={this.onCreateItem} />;
      case "addEmailFromChallenge":
        return (
          <AddEmail
            fromPopup={true}
            onCreate={this.onCreateItem}
            challengeId={this.state.challengeId}
            tagId={this.state.systemTagId}
          />
        );
      case "editLandingPage":
        return (
          <AddLandingPage
            fromPopup={true}
            onCreate={this.onCreateItem}
            id={this.state.landingPage._id}
            tab="content"
          />
        );
      default:
        break;
    }
  }

  render() {
    let className = `fullModal pagePopup ${this.state.type}`;
    return (
      <div className={className}>
        <div className="fmInner">
          <div className="fmHeader">
            <div className="modalHeaderTabs">
              {/*eslint-disable*/}
              <a className="active" data-for=".for-tab1">
                {this.state.label}
              </a>
            </div>
            {/*eslint-disable*/}
            <a className="closeFullModal" onClick={this.onClickClose}>
              <span className="icon-cross" />
            </a>
            {/*eslint-enable*/}
          </div>
          <div className="fmBody">
            <div className="fmBodyInner">
              <div className="modTabContent">
                <div className="gap20" />
                <div className="active for-tab1">{this.getComponent()}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PagePopup);
