import PubSub from "pubsub-js";
import { getItem, setItem } from "./storage";

let searchData = {
  newsLetters: {
    pages: [
      "/member/marcis-circle/newsletter",
      "/member/marcis-circle/newsletter-detail"
    ],
    placeholder: "Search Newsletters"
  },
  mealPlans: {
    pages: [
      "/member/marcis-circle/meal-plans",
      "/member/marcis-circle/meal-plan-details"
    ],
    placeholder: "Search Mealplans"
  }
};

export function checkPathForSearchBar(path) {
  let placeholder = "Search";

  let lastPlaceholder = getItem("placeholder");

  for (const key in searchData) {
    if (searchData.hasOwnProperty(key)) {
      const element = searchData[key];
      for (let i = 0; i < element.pages.length; i++) {
        const page = element.pages[i];
        if (path.includes(page)) {
          placeholder = element.placeholder;
          break;
        }
      }
    }
  }

  setItem("placeholder", placeholder);

  let refresh = "false";
  if (lastPlaceholder === placeholder) {
    refresh = "true";
  }

  setTimeout(() => {
    PubSub.publish("searchPlaceHolder", placeholder);
    PubSub.publish("refreshSearchQuery", refresh);
  }, 100);
}
