import React, { Component } from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as $ from "jquery";
import { Scrollbars } from "react-custom-scrollbars";

import {
  callGetSystemTags,
  callGetTagFilterUsers,
  callGetPageUsersByQuery,
  callCreateSystemTag,
} from "../../services";
import ReactPaginate from "react-paginate";
import { showNotification, slugify } from "../../helpers";
import FilterList from "../../components/form/filterList";
import RadioTag from "../../components/form/radioTag";
import Checkbox from "../../components/form/checkBox";

import { filterByTags } from "../../helpers/filters";

class TagUsers extends Component {
  constructor(props) {
    super(props);

    let includedTags = this.props.selectedIncludedTags || [];
    let excludedTags = this.props.selectedExcludedTags || [];
    let selectedValues = [];

    includedTags.forEach((element) => {
      element.tagType = "included";
      selectedValues.push(element);
    });

    excludedTags.forEach((element) => {
      element.tagType = "excluded";
      selectedValues.push(element);
    });

    let selectedUsers = this.props.selectedUsers || [];

    this.state = {
      label: this.props.label || "Title",
      notes: this.props.notes || "",
      selectedIncludedTags: includedTags,
      selectedExcludedTags: excludedTags,
      keyAddTag: Math.random(),
      keyExcludeTag: Math.random(),
      addTags: includedTags,
      excludeTags: excludedTags,
      systemTags: [],
      systemTagsBackup: [],
      userCount: selectedUsers.length,
      selectedUsers: selectedUsers,
      contentId: this.props.contentId || 0,
      page: 0,
      pages: 0,
      allUsers: [],
      cKey: "included",
      searchKey: "name",
      selectedValues: selectedValues,
      tagName: "",
      filterList: [],
      uFilterList: [],
      userType: this.props.tagUserType || "tag",
      keyUserType: Math.random(),
      users: [],
      sQuery: { search: "" },
      keyCheckbox: Math.random(),
      screen: this.props.screen || "all",
      phoneNumberCount: 0,
      type: this.props.type || "users",
      unsubscribeCounts: {
        subscriptionCount: 0,
        challengeCount: 0,
        updatesCount: 0,
      },
      systemMemberTag: {},
    };
  }

  componentDidMount() {
    this.loadTagData();
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: slugify(value, false),
    });
  }

  filterUserForTags() {
    let includedTags = this.state.addTags.map((t) => t._id);
    let excludedTags = this.state.excludeTags.map((t) => t._id);

    if (includedTags.length > 0 || excludedTags.length > 0) {
      let data = {
        action: "tag-filter-users",
        data: {
          includedTags: includedTags,
          excludedTags: excludedTags,
          tagUserType: this.state.userType,
        },
      };
      callGetTagFilterUsers(data).then((d) => {
        this.setState({
          userCount: d.userCount,
          phoneNumberCount: d.phoneNumberCount,
          selectedUsers: d.selectedUsers,
          unsubscribeCounts: d.unsubscribeCounts,
        });
        if (this.props.onCountUsers) {
          this.props.onCountUsers(d.userCount);
        }
      });
    } else {
      this.setState({
        userCount: 0,
        unsubscribeCounts: { challengeCount: 0 },
        selectedUsers: [],
      });
      if (this.props.onCountUsers) {
        this.props.onCountUsers(0);
      }
    }
  }

  loadTagData = () => {
    callGetSystemTags().then((d) => {
      let tags = d.data;
      tags.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });

      tags = tags.filter((d) => d.status !== "archive");
      let systemMemberTag = {};
      let filterTags = tags.filter((d) => d.name === "system-member");
      if (filterTags.length > 0) {
        systemMemberTag = filterTags[0];
      }
      this.setState(
        {
          systemTags: tags,
          systemTagsBackup: tags,
          keyAddTag: Math.random(),
          keyExcludeTag: Math.random(),
          systemMemberTag: systemMemberTag,
        },
        () => {
          if (
            this.state.userType === "tag" ||
            this.state.userType === "anytag"
          ) {
            this.filterUserForTags();
          } else if (this.state.userType === "everyone") {
            this.setState({ addTags: [systemMemberTag] }, () => {
              this.props.onChangeAddTags(this.state.addTags);
              this.filterUserForTags();
            });
          }
        }
      );
    });
  };

  async getUsers(show) {
    if (this.state.selectedUsers.length === 0) {
      return;
    }
    let limit = 20;
    let query = {
      query: {
        page: this.state.page,
        limit: limit,
        _id: { $in: this.state.selectedUsers },
        $select: [
          "_id",
          "name",
          "email_address",
          "phone_number",
          "country_code",
          "email_subscription",
          "unsubscribed_email_types",
        ],
      },
    };

    let usersData = await callGetPageUsersByQuery(query);
    let users = usersData.data;
    let phoneNumberUsers = users.filter((d) => d.phone_number);
    this.setState(
      {
        pages: Math.ceil(usersData.total / limit),
        allUsers: users,
        phoneNumberCount: phoneNumberUsers.length,
      },
      () => {
        if (show) {
          $(".modalAlertOverlay.usersOverlay,.modalAlert.usersAlert").fadeIn(
            200
          );
        }
      }
    );
  }

  async onClickViewUsers() {
    if (this.state.userCount < 100) {
      await this.getUsers(true);
    }
  }

  onClickCloseFromUsersModal() {
    $(".modalAlertOverlay.usersOverlay,.modalAlert.usersAlert").fadeOut(200);
  }

  onClickMemberName(element) {
    // $(".modalAlertOverlay.usersOverlay,.modalAlert.usersAlert").fadeOut(200);
    window.open(`/admin/edit-user?id=${element._id}`, "_blank");
  }

  renderUsersModal() {
    let modalHeight = window.innerHeight - 200;
    let allUsers = this.state.allUsers;

    if (allUsers.length === 0) {
      return null;
    }

    var self = this;

    let challengeCount = this.state.unsubscribeCounts.challengeCount;
    if (this.state.screen !== "add-email") {
      challengeCount = 0;
    }

    function renderUserRow() {
      let returnVals = [];
      for (let i = 0; i < allUsers.length; i++) {
        const user = allUsers[i];

        let phoneNumber = user.phone_number;
        if (user.country_code) {
          phoneNumber = `+${user.country_code}${phoneNumber}`;
        }

        returnVals.push(
          <tr key={user._id}>
            <td>{user.name}</td>
            <td>{user.email_address}</td>
            <td>{phoneNumber}</td>
            <td>
              <button
                className="btn btn-default btn-sm border-0"
                onClick={self.onClickMemberName.bind(self, user)}>
                <i className="fa fa-eye" />
              </button>
            </td>
          </tr>
        );
      }
      return returnVals;
    }

    return (
      <div>
        <div className="modalAlertOverlay usersOverlay" />
        <div className="modalAlert usersAlert modal-lg big">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Users
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.onClickCloseFromUsersModal.bind(this)}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div
              className="container-fluid modalInnerBody"
              style={{ maxHeight: modalHeight, overflowY: "hidden" }}>
              <Scrollbars
                autoHide
                autoHideTimeout={1000}
                autoHideDuration={200}
                style={{ height: modalHeight }}>
                <div className="gap20" />
                <div className="card">
                  <div className="container-fluid">
                    <div className="gap20" />
                    <p>
                      Total Users :{" "}
                      <b>{this.state.userCount - challengeCount}</b>
                    </p>
                    <p>
                      Current Page: <b>{this.state.page + 1}</b>
                    </p>
                    <div id="react-paginate">
                      <ReactPaginate
                        previousLabel={<span aria-hidden="true">&laquo;</span>}
                        nextLabel={<span aria-hidden="true">&raquo;</span>}
                        pageCount={this.state.pages}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={10}
                        onPageChange={this.handlePageClick}
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        pageClassName="page-item"
                        previousClassName="page-item"
                        nextClassName="page-item"
                        pageLinkClassName="page-link"
                        previousLinkClassName="page-link"
                        nextLinkClassName="page-link"
                        activeClassName="page-item active"
                        disableInitialCallback={true}
                        initialPage={0}
                      />
                    </div>
                  </div>
                </div>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>{renderUserRow()}</tbody>
                </table>
              </Scrollbars>
            </div>
          </div>
        </div>
      </div>
    );
  }

  handlePageClick = (data) => {
    let page = data.selected;
    this.setState({ page: page }, () => {
      this.getUsers(false);
    });
  };

  onClickRemoveValue = (id, tagType) => {
    let selectedValues = this.state.selectedValues.filter((d) => d._id !== id);
    let addTags = this.state.addTags;
    let excludedTags = this.state.excludeTags;

    if (tagType === "included") {
      addTags = addTags.filter((d) => d._id !== id);
      this.props.onChangeAddTags(addTags);
    } else {
      excludedTags = excludedTags.filter((d) => d._id !== id);
      this.props.onChangeExcludeTags(excludedTags);
    }

    this.setState(
      {
        addTags,
        excludedTags,
        selectedValues: selectedValues,
      },
      () => {
        this.filterUserForTags();
      }
    );
  };

  updateSelectTagModel = (value) => {
    let selectedValues = this.state.selectedValues;

    let selectedTag = selectedValues.filter((d) => {
      return d.name === value;
    });

    if (selectedTag.length > 0) {
      showNotification("warning", `'${value}'is already added`, 4000);
      return;
    }
    let addTags = this.state.addTags;
    let excludedTags = this.state.excludeTags;

    if (value.length > 0) {
      let tags = this.state.systemTags;
      let fTag = tags.filter((d) => {
        return d.name === value;
      });
      if (fTag.length > 0) {
        let tag = fTag[0];
        tag.tagType = this.state.cKey;
        selectedValues.push(tag);
        if (this.state.cKey === "included") {
          addTags.push(tag);
          this.props.onChangeAddTags(addTags);
        } else {
          excludedTags.push(tag);
          this.props.onChangeExcludeTags(excludedTags);
        }
      }
    }

    this.setState(
      {
        addTags: addTags,
        excludedTags: excludedTags,
        selectedValues: selectedValues,
      },
      () => {
        this.filterUserForTags();
      }
    );
  };

  onClickInfoTag(i) {
    let selectedValues = this.state.selectedValues;
    let url = `/admin/tag-info?id=${selectedValues[i].id}`;
    window.open(url, "_blank");
  }

  renderSelectedValues() {
    var returnVals = [];
    let selectedValues = this.state.selectedValues;

    for (let i = 0; i < selectedValues.length; i++) {
      let val = selectedValues[i];
      let name = val["name"];
      let backgroundColor = val.tagType === "included" ? "white" : "#ff3a05";
      let textColor = val.tagType === "included" ? "black" : "white";
      returnVals.push(
        <>
          <span
            key={i}
            onClick={this.onClickRemoveValue.bind(this, val._id, val.tagType)}
            style={{
              backgroundColor: backgroundColor,
              color: textColor,
            }}>
            {name}
          </span>
          <i
            className="fa fa-info-circle"
            onClick={this.onClickInfoTag.bind(this, i)}
          />
        </>
      );
    }
    return returnVals;
  }

  onClickCloseFromTagsModal() {
    $(
      `.modalAlertOverlay.viewTagsOverlay${this.state.cKey},.modalAlert.viewTagsAlert${this.state.cKey}`
    ).fadeOut(200);
  }

  onPressViewTags(cKey) {
    this.setState({ cKey: cKey }, () => {
      $(
        `.modalAlertOverlay.viewTagsOverlay${this.state.cKey},.modalAlert.viewTagsAlert${this.state.cKey}`
      ).fadeIn(200);
    });
  }

  onSelectType(item, id) {
    this.setState({ [id]: item[Object.keys(item)[0]].value }, () => {
      this.props.onChangeSelectedUsers(
        this.state.selectedUsers,
        this.state.userType
      );
      if (this.state.userType === "tag" || this.state.userType === "anytag") {
        this.filterUserForTags();
      } else if (this.state.userType === "everyone") {
        this.setState({ addTags: [this.state.systemMemberTag] }, () => {
          this.props.onChangeAddTags(this.state.addTags);
          this.filterUserForTags();
        });
      }
    });
  }

  onClickTagFromViewTags(tag) {
    this.updateSelectTagModel(tag.name);
    this.onClickCloseFromTagsModal();
  }

  async getFilterUsers() {
    let limit = 50;
    let sQuery = this.state.sQuery;
    let query = {
      query: {
        limit: limit,
        status: { $ne: "archive" },
        $select: [
          "_id",
          "name",
          "email_address",
          "phone_number",
          "createdAt",
          "last_activity_date",
          "tag_list",
          "member_type",
          "site_status",
          "unsubscribed_email_types",
        ],
      },
    };
    if (sQuery.search) {
      let regex = { $regex: sQuery.search, $options: "i" };
      /*eslint-disable*/
      query.query["$or"] = [
        { email_address: regex },
        { name: regex },
        { phone_number: regex },
      ];
      /*eslint-enable*/
      let usersData = await callGetPageUsersByQuery(query);
      let users = usersData.data;
      let eUsers = this.state.users;
      users.forEach((element) => {
        let existingUsers = eUsers.filter(
          (d) => d._id === element._id && d.isSelected
        );
        if (existingUsers.length > 0) {
          element.isSelected = true;
        }
      });

      this.setState({
        users: users,
      });
    }
  }

  filterUpdated(updatedFilter, name, type, searchFields) {
    let filterList = this.state.filterList;
    filterList[name] = {
      name: name,
      type: type,
      searchFields: searchFields,
      values: updatedFilter,
    };
    this.setState(
      {
        filterList: filterList,
      },
      () => {
        let data = filterByTags(this.state.systemTagsBackup, filterList);
        this.setState({
          systemTags: data,
        });
      }
    );
  }

  filterUserUpdated(updatedFilter, name, type, searchFields) {
    let filterList = this.state.uFilterList;
    filterList[name] = {
      name: name,
      type: type,
      searchFields: searchFields,
      values: updatedFilter,
    };
    let query = this.state.sQuery;

    let searchType = filterList.search;
    if (searchType) {
      let values = searchType.values[0];
      query["search"] = values.value;
    }

    this.setState(
      {
        sQuery: query,
        uFilterList: filterList,
      },
      () => {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.getFilterUsers();
        }, 300);
      }
    );
  }

  renderTagsModal() {
    let modalHeight = window.innerHeight - 200;
    let data = this.state.systemTags;

    return (
      <div>
        <div
          className={`modalAlertOverlay viewTagsOverlay${this.state.cKey}`}
          onClick={this.onClickCloseFromTagsModal.bind(this)}
        />
        <div className={`modalAlert viewTagsAlert${this.state.cKey} big`}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Tags
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.onClickCloseFromTagsModal.bind(this)}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div
              className="container-fluid modalInnerBody"
              style={{ maxHeight: modalHeight, overflowY: "hidden" }}>
              <Scrollbars
                autoHide
                autoHideTimeout={1000}
                autoHideDuration={200}
                style={{ height: modalHeight }}>
                <FilterList
                  label={"Search Tags"}
                  name={"search"}
                  type={"search"}
                  searchFields={["name", "description"]}
                  onFinish={this.filterUpdated.bind(this)}
                />
                <div className="gap20" />
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Description</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((i) => (
                      <tr key={i._id}>
                        <td>{i[this.state.searchKey]}</td>
                        <td>{i.description}</td>
                        <td>
                          <button
                            title="Report"
                            onClick={this.onClickTagFromViewTags.bind(this, i)}
                            className="btn btn-default btn-sm">
                            Select
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Scrollbars>
            </div>
          </div>
        </div>
      </div>
    );
  }

  onClickCloseFromCreateTagModal() {
    $(".modalAlertOverlay.createTagOverlay,.modalAlert.createTagAlert").fadeOut(
      200
    );
  }

  onClickCreateNewTag() {
    $(".modalAlertOverlay.createTagOverlay,.modalAlert.createTagAlert").fadeIn(
      200
    );
  }

  onPressSelectUsers() {
    $(
      ".modalAlertOverlay.selectUsersOverlay,.modalAlert.selectUsersAlert"
    ).fadeIn(200);
  }

  onClickCloseFromSelectUsersModal() {
    $(
      ".modalAlertOverlay.selectUsersOverlay,.modalAlert.selectUsersAlert"
    ).fadeOut(200);
    let selectedUsers = this.state.selectedUsers;
    this.setState(
      {
        selectedUsers: selectedUsers,
        userCount: selectedUsers.length,
      },
      () => {
        this.getUsers(false);
      }
    );

    if (this.props.onChangeSelectedUsers) {
      this.props.onChangeSelectedUsers(selectedUsers, this.state.userType);
    }
  }

  onClickClearAll() {
    this.setState({
      selectedUsers: [],
      userCount: 0,
      phoneNumberCount: 0,
      keyCheckbox: Math.random(),
    });
    if (this.props.onChangeSelectedUsers) {
      this.props.onChangeSelectedUsers([], this.state.userType);
    }
  }

  toggleCheckbox = (user, check) => {
    let users = this.state.selectedUsers;
    if (check) {
      users.push(user._id);
    } else {
      users = users.filter((d) => d !== user._id);
    }
    this.setState({
      selectedUsers: users,
    });
  };

  renderSelectUsersModal() {
    let modalHeight = window.innerHeight - 200;
    return (
      <div>
        <div
          className={"modalAlertOverlay selectUsersOverlay"}
          onClick={this.onClickCloseFromSelectUsersModal.bind(this)}
        />
        <div className={"modalAlert selectUsersAlert big"}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Select Users
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.onClickCloseFromSelectUsersModal.bind(this)}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div
              className="container-fluid modalInnerBody"
              style={{ maxHeight: modalHeight, overflowY: "hidden" }}>
              <Scrollbars
                autoHide
                autoHideTimeout={1000}
                autoHideDuration={200}
                style={{ height: modalHeight }}>
                <div className="gap20" />
                <FilterList
                  label={"Search Users"}
                  name={"search"}
                  type={"search"}
                  searchFields={[
                    "email_address",
                    "name",
                    "first_name",
                    "last_name",
                    "_id",
                    "phone_number",
                  ]}
                  onFinish={this.filterUserUpdated.bind(this)}
                />
                <div className="gap20" />
                {this.state.users.length > 0 && (
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th></th>
                        <th>Name</th>
                        <th>Email Address</th>
                        <th>Phone Number</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.users.map((i) => (
                        <tr key={i._id}>
                          <td>
                            {this.state.screen === "add-sms" &&
                            !i.phone_number ? (
                              <div></div>
                            ) : (
                              <Checkbox
                                key={this.state.keyCheckbox}
                                isChecked={
                                  this.state.selectedUsers.indexOf(i._id) !== -1
                                }
                                handleCheckboxChange={this.toggleCheckbox.bind(
                                  this,
                                  i
                                )}
                              />
                            )}
                          </td>
                          <td>{i.name}</td>
                          <td>{i.email_address}</td>
                          <td>{i.phone_number}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </Scrollbars>
            </div>
            <div className="alertFooterBtns">
              <button
                className="btn btn-default dismissThisModal margin-right-10"
                onClick={this.onClickClearAll.bind(this)}>
                Clear All
              </button>
              <button
                className="btn btn-primary dismissThisModal"
                onClick={this.onClickCloseFromSelectUsersModal.bind(this)}>
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  async onClickCreateTag(type) {
    let tagName = this.state.tagName.trim().toLowerCase();
    if (tagName.length === 0) {
      showNotification("error", "Tag name is required", 5000);
      return;
    }
    let existingTags = this.state.systemTags.filter((d) => {
      return d.name.toLowerCase() === tagName;
    });
    if (existingTags.length > 0) {
      showNotification("error", "Tag already exists", 4000);
      return;
    }
    // call api to create system tag
    /*eslint-disable*/
    let apiData = {
      name: tagName,
      description: tagName,
      rules: [
        {
          limit_tag_per_days: "1",
        },
      ],
      additional_rules: [],
      events: [],
      notifications: [],
      product_list: [],
      generic_tag_list: [],
      zoom_meeting: {
        user_id: "",
        meeting_id: "",
      },
      type: "automated",
    };
    /*eslint-enable*/
    let newTag = await callCreateSystemTag(apiData);
    if (newTag._id) {
      let systemTags = this.state.systemTags;
      systemTags.push(newTag);
      this.setState({ tagName: "", cKey: type, systemTags: systemTags }, () => {
        this.onClickTagFromViewTags(newTag);
        this.onClickCloseFromCreateTagModal();
      });
    }
  }

  renderCreateTagModal() {
    return (
      <div>
        <div className="modalAlertOverlay createTagOverlay" />
        <div className="modalAlert createTagAlert big">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Create Tag
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.onClickCloseFromCreateTagModal.bind(this)}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="container-fluid modalInnerBody">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-sm-12">
                    <div
                      className={
                        this.state.tagName !== ""
                          ? "mdInput mdFocussed"
                          : "mdInput"
                      }>
                      <label>Tag Name</label>
                      <input
                        type="text"
                        name="tagName"
                        value={this.state.tagName}
                        onChange={(event) => this.handleInputChange(event)}
                      />
                    </div>
                    <div className="gap20" />
                  </div>
                </div>

                <div className="text-right">
                  <button
                    className="btn btn-default btn-sm margin-right-10"
                    onClick={this.onClickCloseFromCreateTagModal.bind(this)}>
                    Cancel
                  </button>
                  <button
                    className="btn btn-primary btn-sm margin-right-10"
                    onClick={this.onClickCreateTag.bind(this, "excluded")}>
                    Add as Exclude Tag
                  </button>

                  <button
                    className="btn btn-primary btn-sm"
                    onClick={this.onClickCreateTag.bind(this, "included")}>
                    Add as Include Tag
                  </button>
                </div>
                <div className="gap20" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    let type = this.state.type;
    let challengeCount = this.state.unsubscribeCounts.challengeCount;
    if (this.state.screen !== "add-email") {
      challengeCount = 0;
    }
    return (
      <>
        {this.renderUsersModal()}
        {this.renderTagsModal()}
        {this.renderCreateTagModal()}
        {this.renderSelectUsersModal()}
        <div className="card">
          <div className="container-fluid">
            <div className="gap20" />
            {type === "users" && (
              <>
                <div className="radioBHolder">
                  <p>Select Users</p>
                  <div className="tabsHolder radioTabs">
                    <RadioTag
                      onCheckChange={this.onSelectType.bind(this)}
                      labelList={[
                        {
                          name: "Users With All Tags",
                          value: "tag",
                        },
                        {
                          name: "Users With Any Tags",
                          value: "anytag",
                        },
                        { name: "Select Users", value: "users" },
                        { name: "Everyone", value: "everyone" },
                      ]}
                      id={"userType"}
                      selectedList={[this.state.userType]}
                      selectType={"value"}
                      key={this.state.keyUserType}
                    />
                  </div>
                </div>
                <div className="gap20" />
              </>
            )}

            {this.state.userType === "tag" ||
            this.state.userType === "anytag" ? (
              <>
                <p>{this.state.label}</p>
                {this.state.notes ? <p>{this.state.notes}</p> : ""}
                <div className="row">
                  <div className="col-sm-2">
                    <button
                      className="btn btn-block btn-default btn-sm"
                      onClick={this.onPressViewTags.bind(this, "included")}>
                      Include Tags
                    </button>
                  </div>
                  <div className="col-sm-2">
                    <button
                      className="btn btn-block btn-default btn-sm"
                      onClick={this.onPressViewTags.bind(this, "excluded")}>
                      Exclude Tags
                    </button>
                  </div>
                  <div className="col-sm-2">
                    <button
                      className="btn btn-block btn-default btn-sm"
                      onClick={this.onClickCreateNewTag.bind(this)}>
                      Create New Tag
                    </button>
                  </div>
                </div>
                <div style={{ marginTop: 20 }} className="tagsHolder">
                  {this.renderSelectedValues()}
                </div>
              </>
            ) : this.state.userType === "users" ? (
              <div className="row">
                <div className="col-sm-2">
                  <button
                    className="btn btn-block btn-default btn-sm"
                    onClick={this.onPressSelectUsers.bind(this, "included")}>
                    Select Users
                  </button>
                </div>
              </div>
            ) : (
              <p>
                All users with the <b>system-member</b> tag will get an email
              </p>
            )}
            <div className="gap10" />
            {(type === "users" || type === "tags") && (
              <>
                <p className="text-success">
                  {/*eslint-disable-next-line*/}
                  <a onClick={this.onClickViewUsers.bind(this)}>
                    {this.state.userCount - challengeCount} User(s) Selected
                  </a>
                </p>
                {this.state.userCount > 0 &&
                  this.state.screen === "add-email" &&
                  this.state.userType !== "users" && (
                    <p>{challengeCount} User(s) Unsubscribed</p>
                  )}
              </>
            )}
            {this.state.screen === "add-sms" && (
              <p className="text-primary">
                {/*eslint-disable-next-line*/}
                <a onClick={this.onClickViewUsers.bind(this)}>
                  {this.state.phoneNumberCount} User(s) with Phone Numbers
                </a>
              </p>
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TagUsers);
