import React from "react";
import moment from "moment";
import { imageUrl, linkify, sanitizer } from "../../../../helpers";
import { saveAs } from "file-saver";
import $ from "jquery";

const InfoView = (props) => {
  return (
    <>
      <label style={{ color: "#a0a0a0" }} className="firstLetter">
        {props.label}
      </label>
      <br />
      <label
        className={`firstLetter link ${props.onClick ? "pointer-click" : ""}`}
        onClick={props.onClick ? props.onClick : null}>
        {props.value}{" "}
        {props.label === "Status"
          ? props.statusUpdatedBy
            ? `(${props.statusUpdatedBy})`
            : ""
          : ""}
      </label>
      {props.label === "Assigned" && props.memberInfo && (
        <i
          className="fa fa-info-circle"
          onClick={props.onClickInfo}
          style={{ paddingLeft: 10, cursor: "pointer" }}
        />
      )}
    </>
  );
};

export class TaskBasicInfo extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  getCustomFieldValue(cField) {
    var taskInfo = this.props.taskInfo;
    let customFields = taskInfo.custom_fields || [];

    let customField = customFields.find((d) => d.id === cField.id);

    if (!customField) {
      return "-";
    }

    let type = customField.type || "single";
    var selectedOption = customField.selectedOption || {};
    if (type === "single" || type === "dropdown") {
      return selectedOption.label ? selectedOption.label : "-";
    } else if (type === "text") {
      return selectedOption.value ? selectedOption.value : "-";
    } else if (type === "editor") {
      return selectedOption.value ? (
        <span
          style={{ wordWrap: "break-word" }}
          dangerouslySetInnerHTML={{
            __html: sanitizer(selectedOption.value),
          }}
        />
      ) : (
        "-"
      );
    } else if (type === "multiple") {
      var selectedOptions = customField.selectedOptions || [];
      return selectedOptions.map((i) => i.label).join(", ");
    }
  }

  onClickFileUrl(element) {
    if (element.file) {
      saveAs(imageUrl(element.file), element.name);
    } else {
      saveAs(imageUrl(element), element);
    }
  }

  renderFiles(task) {
    let files = task.files || [];
    let returnVals = [];
    for (let i = 0; i < files.length; i++) {
      const element = files[i];
      returnVals.push(
        <label
          className="link pointer-click margin-right-10"
          onClick={this.onClickFileUrl.bind(this, element)}>
          <u>{element.name ? element.name : element}</u>
        </label>
      );
    }
    return returnVals;
  }

  getSingleFieldValueOption(cField) {
    var taskInfo = this.props.taskInfo;
    let customFields = taskInfo.custom_fields || [];

    let customField = customFields.find((d) => d.id === cField.id);
    if (customField) {
      var selectedOption = customField.selectedOption || {};

      return selectedOption.isNote ? selectedOption.note : "";
    }
    return "";
  }

  renderGroupCustomFields(customFields) {
    let returnVals = [];

    for (let i = 0; i < customFields.length; i++) {
      const element = customFields[i];
      if (element.visible || this.props.from === "admin") {
        let className = "col-sm-2";
        let note = "";
        if (element.type === "single") {
          note = this.getSingleFieldValueOption(element);
          if (note) {
            className = "col-sm-12";
          }
        }
        returnVals.push(
          <div className={className}>
            <InfoView
              label={element.label}
              value={this.getCustomFieldValue(element)}
              onClick={() => this.props.onClickEditCustomField(element)}
            />
            {note && (
              <p
                style={{ wordWrap: "break-word" }}
                dangerouslySetInnerHTML={{
                  __html: sanitizer(note),
                }}></p>
            )}
          </div>
        );
      }
    }
    return returnVals;
  }

  renderCustomFields(customFields) {
    let returnVals = [];

    let categoryMap = {};
    customFields.forEach((element) => {
      if (!element.category) {
        element.category = "no";
      }
      if (!categoryMap[element.category]) {
        categoryMap[element.category] = [];
      }
      categoryMap[element.category].push(element);
    });

    for (const key in categoryMap) {
      if (Object.hasOwnProperty.call(categoryMap, key)) {
        const element = categoryMap[key];
        if (key !== "no") {
          let category = this.props.fieldCategories.find((d) => d._id === key);
          let visibleElement = element.filter((e) => e.visible);

          if (visibleElement.length > 0 || this.props.from === "admin") {
            returnVals.push(
              <fieldset className="border p-3">
                <legend
                  className="float-none p-2 firstLetter"
                  style={{ fontSize: 16, width: "auto" }}>
                  {category ? category.name : "NA"}
                </legend>
                <div className="row">
                  {this.renderGroupCustomFields(element)}
                </div>
              </fieldset>
            );
          }
        }
      }
    }
    if (Object.hasOwnProperty.call(categoryMap, "no")) {
      returnVals.push(
        <>
          <div className="row pt-3">
            {this.renderGroupCustomFields(categoryMap["no"])}{" "}
          </div>
        </>
      );
    }
    return returnVals;
  }

  onClickCancelFromPdfTemplateModal() {
    $(
      ".modalAlertOverlay.assignedInfoModalOverlay,.modalAlert.assignedInfoAlert"
    ).fadeOut(200);
  }

  onClickAssignedInfo() {
    $(
      ".modalAlertOverlay.assignedInfoModalOverlay,.modalAlert.assignedInfoAlert"
    ).fadeIn(200);
  }

  renderAssignedInfoModal() {
    let taskInfo = this.props.taskInfo;
    return (
      <div>
        <div className="modalAlertOverlay assignedInfoModalOverlay" />
        <div className="modalAlert assignedInfoAlert">
          <div className="clearfix">
            <div className="pull-left">
              <p style={{ fontSize: 18 }}>Assigned Information</p>
            </div>
          </div>
          <div className="container-fluid">
            <label>
              Name : {taskInfo.member_info ? taskInfo.member_info.name : "-"}
            </label>
            <br />
            <label>
              Email Address :{" "}
              {taskInfo.member_info ? taskInfo.member_info.email_address : "-"}
            </label>
            <br />
            <label>
              Phone Number :{" "}
              {taskInfo.member_info ? taskInfo.member_info.phone_number : "-"}
            </label>
            <div className="gap10" />
          </div>
          <div className="gap20" />
          <div className="alertFooterBtns">
            <button
              onClick={this.onClickCancelFromPdfTemplateModal.bind(this)}
              className="btn btn-default dismissThisModal margin-right-10">
              Close
            </button>
          </div>
        </div>
      </div>
    );
  }

  render() {
    var taskInfo = this.props.taskInfo;
    var props = this.props;
    var customFields = this.props.customFields || [];
    /*eslint-disable*/
    let description = linkify(taskInfo.description);
    /*eslint-enable*/
    let hideTableFields = this.props.hideTableFields;

    return (
      <div className="task-info">
        {this.renderAssignedInfoModal()}
        <div className="gap10" />
        <div className="row">
          <div className="col-sm-12">
            <label style={{ color: "#a0a0a0" }}>{this.props.taskName}</label>
            <br />
            <label
              onClick={() => props.onClickEditTaskField("task")}
              className="firstLetter link pointer-click">
              {taskInfo.task}
            </label>
          </div>
        </div>
        <div className="gap10" />

        <div className="row">
          <div className="col-sm-12">
            <label style={{ color: "#a0a0a0" }}>Description</label>
            <br />
            <div
              onClick={() => props.onClickEditTaskField("description")}
              className="link pointer-click"
              dangerouslySetInnerHTML={{
                __html: sanitizer(description || "-"),
              }}
            />
          </div>
        </div>
        <div className="gap20" />

        <div className="row">
          {hideTableFields.indexOf("status") === -1 && (
            <div className="col-sm-2">
              <InfoView
                label="Status"
                value={taskInfo.status}
                statusUpdatedBy={taskInfo.statusUpdatedBy}
                onClick={props.onClickStatus}
              />
            </div>
          )}
          {hideTableFields.indexOf("priority") === -1 && (
            <div className="col-sm-2">
              <InfoView
                label="Priority"
                value={taskInfo.priority}
                onClick={() => props.onClickEditTaskField("priority")}
              />
            </div>
          )}
          {hideTableFields.indexOf("due_date") === -1 && (
            <div className="col-sm-2">
              <InfoView
                label="Due Date"
                value={
                  props.taskInfo.due_date
                    ? moment(props.taskInfo.due_date).format("MM/DD/YYYY")
                    : "-"
                }
                onClick={() => props.onClickEditTaskField("dueDate")}
              />
            </div>
          )}
          {hideTableFields.indexOf("created") === -1 && (
            <div className="col-sm-2">
              <InfoView
                label="Created"
                value={
                  taskInfo.createdAt
                    ? moment(taskInfo.createdAt).format("MM/DD/YYYY")
                    : "-"
                }
              />
            </div>
          )}
          {hideTableFields.indexOf("updated") === -1 && (
            <div className="col-sm-2">
              <InfoView
                label="Updated"
                value={
                  taskInfo.updatedAt
                    ? moment(taskInfo.updatedAt).format("MM/DD/YYYY")
                    : "-"
                }
              />
            </div>
          )}
          {hideTableFields.indexOf("assigned") === -1 && (
            <div className="col-sm-2">
              <InfoView
                label="Assigned"
                value={
                  taskInfo.member_info
                    ? taskInfo.member_info.name
                    : "Unassigned"
                }
                memberInfo={taskInfo.member_info}
                onClickInfo={() => this.onClickAssignedInfo()}
                onClick={() => props.onClickEditTaskField("assigned")}
              />
            </div>
          )}
          {hideTableFields.indexOf("incoming_invoice") === -1 && (
            <div className="col-sm-2">
              <InfoView
                label={`Bill ${
                  taskInfo.invoice
                    ? taskInfo.invoice.incomingPaid
                      ? "(Paid)"
                      : "(Unpaid)"
                    : ""
                }`}
                value={taskInfo.invoice ? "View" : "-"}
                onClick={() => {
                  if (taskInfo.invoice.incoming) {
                    let url = `/admin/add-invoice?id=${taskInfo.invoice.incoming}`;
                    window.open(url, "_blank");
                  }
                }}
              />
            </div>
          )}
          {hideTableFields.indexOf("outcoming_invoice") === -1 && (
            <div className="col-sm-2">
              <InfoView
                label={`Invoice ${
                  taskInfo.invoice
                    ? taskInfo.invoice.outgoingPaid
                      ? "(Paid)"
                      : "(Unpaid)"
                    : ""
                }`}
                value={taskInfo.invoice ? "View" : "-"}
                onClick={() => {
                  if (taskInfo.invoice.outgoing) {
                    let url = `/admin/add-invoice?id=${taskInfo.invoice.outgoing}`;
                    window.open(url, "_blank");
                  }
                }}
              />
            </div>
          )}
          <div className="col-sm-2">
            <InfoView label="Created By" value={taskInfo.created_by_name} />
          </div>

          {this.props.from === "admin" && (
            <div className="col-sm-2">
              <InfoView
                label="Attached Form"
                value={this.props.formInfo ? this.props.formInfo.name : "-"}
                onClick={props.onClickForm}
              />
            </div>
          )}
        </div>

        {this.renderCustomFields(customFields)}

        <div className="gap10" />
        {taskInfo.files.length > 0 && (
          <>
            <label style={{ color: "#a0a0a0" }}>Files</label>
            <br />
            {this.renderFiles(taskInfo)}
          </>
        )}
      </div>
    );
  }
}
