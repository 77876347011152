/*eslint-disable*/
import React, { Component } from "react";
import moment from "moment";

class FilterList extends Component {
  constructor(props) {
    super(props);
    const { type, name, searchFields, label, searchQuery } = this.props;

    let { filterList } = this.props;

    let i, days;

    if (type.toLowerCase() === "date") {
      for (i in filterList) {
        if (filterList[i].value.indexOf("-days-") >= 0) {
          days = filterList[i].value.split("-days-")[1];
          filterList[i].value =
            moment()
              .subtract(days, "d")
              .format("MM-DD-YYYY") +
            "->" +
            moment().format("MM-DD-YYYY");
        } else if (filterList[i].value.indexOf("-days+") >= 0) {
          days = filterList[i].value.split("-days+")[1];
          filterList[i].value =
            moment()
              .add(1, "d")
              .format("MM-DD-YYYY") +
            "->" +
            moment()
              .add(days, "d")
              .format("MM-DD-YYYY");
        }
      }
    }

    this.state = {
      filterList: filterList,
      type: type,
      name: name,
      label: label || "",
      searchQuery: searchQuery || "",
      searchFields: searchFields
    };
  }

  componentWillMount() {
    if (this.state.searchQuery.length > 0) {
      this.handleSearchChange({
        target: { value: this.state.searchQuery }
      });
    } else {
      let filterList = this.state.filterList;
      if (filterList !== undefined) {
        if (filterList.length > 0) {
          for (var i in filterList) {
            if (i.name === "search") {
              if (filterList[i]["selected"]) {
                this.handleSearchChange({
                  target: { value: filterList[i]["value"] }
                });
              }
            } else {
              this.filterUpdated(filterList);
            }
          }
        }
      } else {
        this.handleSearchChange({
          target: { value: "" }
        });
      }
    }
  }

  onClickFilterOption(value, key) {
    let newFilterList = this.state.filterList;
    let filterOption = newFilterList[key];

    if (
      this.state.type.toLowerCase() === "single" ||
      this.state.type.toLowerCase() === "date" ||
      this.state.type.toLowerCase() === "tags"
    ) {
      newFilterList.map(filterItem => {
        filterItem.selected = false;
      });
    }

    filterOption.selected = !filterOption.selected;
    newFilterList[key] = filterOption;

    this.setState({
      filterList: newFilterList
    });

    this.filterUpdated(newFilterList);
  }

  filterUpdated(newFilterList) {
    this.props.onFinish(
      newFilterList,
      this.state.name,
      this.state.type,
      this.state.searchFields
    );
  }

  renderDate(row, i) {
    switch (row.type) {
      case "selected":
        return (
          <div
            key={i}
            className={row.selected ? "active" : ""}
            onClick={() => this.onClickFilterOption(row.value, i)}
          >
            <span className="icon-check" /> {row.name}
          </div>
        );
      case "selectSingle":
        return (
          <div
            key={i}
            className={row.selected ? "active" : ""}
            onClick={() => this.onClickFilterOption(row.value, i)}
          >
            <span className="icon-check" /> {row.name}
          </div>
        );
      case "selectMulti":
        return (
          <div
            key={i}
            className={
              row.selected ? "setCustomDateOuter active" : "setCustomDateOuter"
            }
            onClick={() => this.onClickFilterOption(row.value, i)}
          >
            <span className="icon-check" /> {row.name}
            <input type="text" className="setCustomDate" />
          </div>
        );
      default:
        return;
    }
  }

  handleSearchChange(event) {
    const value = event.target.value;

    let newFilterList = [{ name: value, value: value, selected: true }];

    this.setState(
      {
        searchQuery: value
      },
      function() {
        this.filterUpdated(newFilterList);
      }
    );
  }

  render() {
    switch (this.state.type) {
      case "single":
      case "multiple":
      case "tags":
        return (
          <div className="tabsHolder radioTabs">
            {this.state.filterList.map((row, i) => (
              <div
                key={i}
                className={row.selected ? "active" : ""}
                onClick={() => this.onClickFilterOption(row.value, i)}
              >
                <span className="icon-check" /> {row.name}
              </div>
            ))}
          </div>
        );

      case "search":
        return (
          <div
            className={
              this.state.searchQuery !== "" ? "mdInput mdFocussed" : "mdInput"
            }
          >
            <label>{this.state.label}</label>
            <input
              type="text"
              value={this.state.searchQuery}
              onChange={event => this.handleSearchChange(event)}
            />
          </div>
        );

      case "date":
        return (
          <div className="tabsHolder radioTabs">
            {this.state.filterList.map((row, i) => this.renderDate(row, i))}
          </div>
        );
      default:
        return;
    }
  }
}

export default FilterList;
