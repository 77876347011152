import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { VelocityTransitionGroup } from "velocity-react";
import DatePicker from "react-date-picker";

import RadioTag from "../../../components/form/radioTag";
import Checkbox from "../../../components/form/checkBox";
import TitleBox from "../../../components/general/titleBox";
import { showNotification, slugify } from "../../../helpers";

import { createPromoCode, resetStatus } from "../../../modules/actions/index";
import HelpTextDiv from "../../../components/form/helpTextDiv";
import { callGetSettings } from "../../../services/settingService";

class AddPromoCode extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      code: "",
      percentOff: "",
      amountOff: "",
      duration: "once",
      type: "single",
      durationMonths: "",
      maxRedemptions: "",
      isRedeemBy: false,
      redeemBy: new Date(),
      keyCheckbox: Math.random(),
      offType: "percent",
      stripeAccount: "default",
      stripeAccounts: [],
    };
  }

  componentDidMount() {
    this.getSettings();
  }

  async getSettings() {
    let d = await callGetSettings("stripeaccounts");
    if (d.status) {
      let accounts = d.data.stripe_accounts || [];
      let newAccounts = [{ id: "default", name: "Default" }];
      newAccounts = newAccounts.concat(accounts);
      this.setState({ stripeAccounts: newAccounts });
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.message) {
      if (newProps.success) {
        this.resetProps();
        showNotification("success", newProps.message, 5000);
      } else {
        showNotification("error", newProps.message, 5000);
      }
      this.props.resetStatus();
    }
  }

  resetProps() {
    this.setState({
      code: "",
      percentOff: "",
      amountOff: "",
      duration: "once",
      type: "single",
      durationMonths: "",
      maxRedemptions: "",
      redeemBy: new Date(),
    });
  }

  handleTextChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  onSelectDuration(item, id) {
    this.setState({ [id]: item[Object.keys(item)[0]].value });
  }

  onSelectOffType(item, id) {
    let value = item[Object.keys(item)[0]].value;
    if (value === "amount") {
      this.setState({ [id]: value, percentOff: "" });
    }
    if (value === "percent") {
      this.setState({ [id]: value, amountOff: "" });
    }
  }

  onChangeRedeemBy(value) {
    this.setState({
      redeemBy: value,
    });
  }

  toggleRedeemByCheckbox = (check) => {
    this.setState({
      isRedeemBy: check,
    });
  };

  onSelectChangeRadioTag(item, id) {
    let value = item[Object.keys(item)[0]].value;

    this.setState({ [id]: value });
  }

  onPressCreatePromoCode() {
    // call api to add promo code

    if (this.state.code.trim().length === 0) {
      showNotification("error", "Code is required", 4000);
      return;
    }

    if (
      this.state.percentOff.trim().length === 0 &&
      this.state.amountOff.trim().length === 0
    ) {
      showNotification("error", "Percent off or amount off is required", 4000);
      return;
    }

    if (
      this.state.percentOff.trim().length > 0 &&
      this.state.amountOff.trim().length > 0
    ) {
      showNotification("error", "Either add percent off or amount off", 4000);
      return;
    }

    if (this.state.duration === "repeating") {
      if (this.state.durationMonths.trim().length === 0) {
        showNotification("error", "Duration in months is required", 4000);
        return;
      }
    }

    /*eslint-disable*/
    let apiData = {
      percent_off: this.state.percentOff,
      amount_off: this.state.amountOff,
      duration_type: this.state.duration,
      duration_months: this.state.durationMonths,
      code: slugify(this.state.code),
      max_redemptions: this.state.maxRedemptions,
      type: this.state.type,
      redeem_by: this.state.isRedeemBy ? this.state.redeemBy : "",
      times_used: "0",
      stripe_account: this.state.stripeAccount,
    };
    /*eslint-enable*/

    this.props.createPromoCode(apiData);
  }

  render() {
    return (
      <div>
        <div className="container-fluid">
          <TitleBox title={"Create New Promo Code"} showBackBtn={false} />
          <div className="gap20" />
          <div className="card">
            <div className="container-fluid">
              <div className="gap20" />

              <HelpTextDiv
                id="idPromoCode"
                label="Learn more about the Code"
                type="addPromoCode"
                isInline="false"
              />
              <div
                className={
                  this.state.code !== "" ? "mdInput mdFocussed" : "mdInput"
                }>
                <label>Code</label>
                <input
                  type="text"
                  name="code"
                  value={this.state.code}
                  onChange={(event) => this.handleTextChange(event)}
                />
              </div>
              <div className="gap20" />
              <div className="radioBHolder">
                <div className="havingHelpText">
                  <p>Discount in</p>
                </div>
                <div className="tabsHolder radioTabs">
                  <RadioTag
                    onCheckChange={this.onSelectOffType.bind(this)}
                    labelList={[
                      { name: "Percent", value: "percent" },
                      { name: "Amount", value: "amount" },
                    ]}
                    id={"offType"}
                    selectedList={[this.state.offType]}
                    selectType={"value"}
                  />
                </div>
              </div>

              <HelpTextDiv
                id="idPromoPercentOff"
                label="Learn more about the Percent Off"
                type="addPromoCode"
                isInline="false"
              />
              {this.state.offType === "percent" && (
                <div
                  className={
                    this.state.percentOff !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Percent Off</label>
                  <input
                    type="text"
                    name="percentOff"
                    value={this.state.percentOff}
                    onChange={(event) => this.handleTextChange(event)}
                  />
                </div>
              )}

              <HelpTextDiv
                id="idPromoAmountOff"
                label="Learn more about the Amount Off"
                type="addPromoCode"
                isInline="false"
              />
              {this.state.offType === "amount" && (
                <div
                  className={
                    this.state.amountOff !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Amount Off</label>
                  <input
                    type="text"
                    name="amountOff"
                    value={this.state.amountOff}
                    onChange={(event) => this.handleTextChange(event)}
                  />
                </div>
              )}
              <div className="gap20" />

              <HelpTextDiv
                id="idPromoMaxRedemption"
                label="Learn more about the Max Redemptions"
                type="addPromoCode"
                isInline="false"
              />
              <div
                className={
                  this.state.maxRedemptions !== ""
                    ? "mdInput mdFocussed"
                    : "mdInput"
                }>
                <label>Max Redemptions</label>
                <input
                  type="text"
                  name="maxRedemptions"
                  value={this.state.maxRedemptions}
                  onChange={(event) => this.handleTextChange(event)}
                />
              </div>
              <div className="gap20" />

              <div className="havingHelpText">
                <Checkbox
                  label={"Must be redeemed by specific date"}
                  isChecked={this.state.isRedeemBy}
                  handleCheckboxChange={this.toggleRedeemByCheckbox.bind(this)}
                  key={this.state.keyCheckbox}
                />
              </div>
              <HelpTextDiv
                id="idPromoRedeemDuration"
                label="Learn more about the Redeem Condition"
                type="addPromoCode"
                isInline="true"
              />
              <div className="gap10" />
              <VelocityTransitionGroup
                enter={{ animation: "slideDown" }}
                leave={{ animation: "slideUp" }}>
                {this.state.isRedeemBy && (
                  <HelpTextDiv
                    id="idPromoRedeemBy"
                    label="Learn more about the Redeem By"
                    type="addPromoCode"
                    isInline="false"
                  />
                )}
                {this.state.isRedeemBy && (
                  <>
                    <label>
                      Note: The code will no longer be valid on the day you
                      enter below.
                    </label>
                    <div className="gap10" />
                    <label className="inputFakeLabelFocussed">Redeem By</label>
                    <div>
                      <DatePicker
                        minDate={new Date()}
                        value={this.state.redeemBy}
                        onChange={this.onChangeRedeemBy.bind(this)}
                      />
                    </div>
                  </>
                )}
                <div className="gap20" />
              </VelocityTransitionGroup>
              <div className="radioBHolder">
                <div className="havingHelpText">
                  <p>Duration</p>
                </div>
                <HelpTextDiv
                  id="idPromoDuration"
                  label="Learn more about the Duration"
                  type="addPromoCode"
                  isInline="true"
                />
                <div className="tabsHolder radioTabs">
                  <RadioTag
                    onCheckChange={this.onSelectDuration.bind(this)}
                    labelList={[
                      { name: "Once", value: "once" },
                      { name: "Multi Month", value: "repeating" },
                      { name: "Forever", value: "forever" },
                    ]}
                    id={"duration"}
                    selectedList={[this.state.duration]}
                    selectType={"value"}
                  />
                </div>
              </div>
              <div className="gap20" />
              <VelocityTransitionGroup
                enter={{ animation: "slideDown" }}
                leave={{ animation: "slideUp" }}>
                {this.state.duration === "repeating" && (
                  <HelpTextDiv
                    id="idPromoDurationPeriod"
                    label="Learn more about the Duration Period"
                    type="addPromoCode"
                    isInline="false"
                  />
                )}
                {this.state.duration === "repeating" && (
                  <div
                    className={
                      this.state.durationMonths !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>Duration In Months</label>
                    <input
                      type="text"
                      name="durationMonths"
                      value={this.state.durationMonths}
                      onChange={(event) => this.handleTextChange(event)}
                    />
                  </div>
                )}
                <div className="gap10" />
              </VelocityTransitionGroup>
              <div className="radioBHolder">
                <div className="havingHelpText">
                  <p>Type</p>
                </div>
                <HelpTextDiv
                  id="idPromoType"
                  label="Learn more about the Type"
                  type="addPromoCode"
                  isInline="true"
                />
                <div className="tabsHolder radioTabs">
                  <RadioTag
                    onCheckChange={this.onSelectDuration.bind(this)}
                    labelList={[
                      { name: "Single", value: "single" },
                      { name: "Recurring", value: "recurring" },
                    ]}
                    id={"type"}
                    selectedList={[this.state.type]}
                    selectType={"value"}
                  />
                </div>
              </div>
              <div className="gap20" />
              {this.state.stripeAccounts.length > 0 && (
                <>
                  <div className="radioBHolder">
                    <div className="havingHelpText">
                      <p>Stripe Account</p>
                    </div>
                    <div className="tabsHolder radioTabs">
                      <RadioTag
                        onCheckChange={this.onSelectChangeRadioTag.bind(this)}
                        labelList={this.state.stripeAccounts.map((i) => {
                          return { name: i.name, value: i.id };
                        })}
                        id={"stripeAccount"}
                        selectedList={[this.state.stripeAccount]}
                        selectType={"value"}
                      />
                    </div>
                  </div>
                  <div className="gap20" />
                </>
              )}
            </div>
          </div>
        </div>
        <div className="text-right container-fluid">
          <button
            className="btn btn-primary"
            onClick={() => this.onPressCreatePromoCode()}>
            Create Promo Code
          </button>
        </div>
        <div className="gap20" />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  message: state.billingReducer.message,
  success: state.billingReducer.success,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createPromoCode,
      resetStatus,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AddPromoCode);
