import React from "react";

import {
  callGetAtqUserIds,
  callGetSystemTagUsingName,
  callGetChlgsByQuery,
  callGetAccountabilityResponseByQuery,
} from "../../../services";
import config from "../../../helpers/config";
// import { isFS } from "../../../helpers";
import moment from "moment-timezone";

class ResponsesLogic {
  async getUsers(userId) {
    let userData = await callGetAtqUserIds(userId);
    return userData.users;
  }

  async callGetTagByName() {
    let atqResponseTag = config.tags.atqResponseTag;
    let tagId = "";
    let d = await callGetSystemTagUsingName(atqResponseTag);
    if (d.data.length > 0) {
      tagId = d.data[0]._id;
    }
    return tagId;
  }

  async getChallenges() {
    let query = {
      query: {
        $select: ["name"],
      },
    };
    let d = await callGetChlgsByQuery(query, false);
    let challengeList = [];
    challengeList.push({ name: "All", value: "all", selected: true });
    if (d.data.length > 0) {
      d.data.forEach((element) => {
        challengeList.push({
          name: element.name,
          value: element.name,
          selected: false,
        });
      });
    }
    // if (isFS()) {
    //   challengeList.push({
    //     name: "Marci's Circle",
    //     value: "marci's circle",
    //     selected: false,
    //   });
    // }
    return challengeList;
  }

  async getAccountabilityResponsesByDate(date, filterDateType, trackingToDate) {
    var fromDate = new Date(date);
    fromDate = moment(fromDate).startOf("day").toDate();

    var toDate = moment(fromDate).startOf("day").add(1, "days").toDate();

    if (filterDateType === "custom" || filterDateType === "last-7-days") {
      toDate = moment(new Date(trackingToDate))
        .startOf("day")
        .add(1, "days")
        .toDate();
    }

    /*eslint-disable*/
    let query = {
      query: {
        tracking_date: {
          $gte: fromDate,
          $lt: toDate,
        },
      },
    };
    /*eslint-enable*/
    let data = await callGetAccountabilityResponseByQuery(query);
    return { data: data.data, atqDate: date };
  }

  async prepareAtqData(userData, usersBackup, atqData, date) {
    let users = usersBackup;
    let inputRef = {};

    /*eslint-disable*/
    users.sort((a, b) => {
      if (a.name && b.name) {
        return a.name.localeCompare(b.name);
      }
    });
    /*eslint-enable*/

    users.forEach((element) => {
      let atqResult = atqData.filter((d) => {
        return d.member_id.toString() === element._id.toString();
      });
      inputRef[element._id] = React.createRef();

      element["atqData"] = [];
      element["reply"] = "";
      element["showReply"] = true;
      element["atqDate"] = date;
      element["isTagAtq"] =
        atqResult.filter((d) => d.question_type === "tag").length > 0;

      if (atqResult.length !== 0) {
        element["atqData"] = atqResult;
      }

      element["atqMessages"] = [];
      element["responseStatus"] = "unresponded";
      element["isPendingResponse"] = true;

      let unresponded = atqResult.filter(
        (d) => d.response_status === "pending"
      );
      let responded = atqResult.filter(
        (d) => d.response_status === "responded"
      );
      let ignored = atqResult.filter((d) => d.response_status === "ignored");

      element["responseStatus"] =
        unresponded.length > 0
          ? "unresponded"
          : responded.length > 0
          ? "responded"
          : ignored.length > 0
          ? "ignored"
          : "unresponded";

      var memberChallenges = element.member_challenges;

      memberChallenges.forEach((chlgElement) => {
        chlgElement["currentDay"] = moment()
          .startOf("day")
          .diff(
            moment(chlgElement.start_date, "MM-DD-YYYY", true).startOf("day"),
            "days"
          );

        chlgElement["currentDay"] = chlgElement["currentDay"] + 1;

        chlgElement["isActive"] =
          parseInt(chlgElement["currentDay"], 10) <
            parseInt(chlgElement.challenge_days, 10) &&
          chlgElement.status === "active" &&
          parseInt(chlgElement["currentDay"], 10) > 0;

        chlgElement["shortName"] = chlgElement.name.match(/\b\w/g).join("");
      });

      let fMemberChallenges = memberChallenges.filter((d) => {
        return d.isActive === true;
      });
      element["member_challenges"] = fMemberChallenges;

      var memberProducts = element.member_products;

      memberProducts.forEach((productElement) => {
        if (
          productElement.program_info &&
          productElement.program_info[0] &&
          productElement.name === "Custom Program"
        ) {
          let product = productElement.program_info[0];

          productElement["currentDay"] = moment()
            .startOf("day")
            .diff(
              moment(productElement.start_date, "MM-DD-YYYY", true).startOf(
                "day"
              ),
              "days"
            );

          productElement["currentDay"] = productElement["currentDay"] + 1;

          productElement["isActive"] =
            parseInt(productElement["currentDay"], 10) <
              parseInt(product.days, 10) &&
            productElement.status === "active" &&
            parseInt(productElement["currentDay"], 10) > 0;

          productElement["shortName"] = product.name.match(/\b\w/g).join("");
        }
        //  else if (productElement.name === "Marci's Circle") {
        //   productElement["isActive"] = productElement.status === "active";
        //   productElement["shortName"] = productElement.name
        //     .match(/\b\w/g)
        //     .join("");
        // }
      });

      let fMemberProducts = memberProducts.filter((d) => {
        return d.isActive === true;
      });
      element["member_products"] = fMemberProducts;

      var messages = element.member_messages;
      if (messages.length > 0) {
        let atqMessages = messages.filter((d) => {
          return d.atq_feedback === "true";
        });
        element["memberAtqMessage"] = atqMessages[0];

        if (atqMessages.length > 0) {
          let messageList = atqMessages[0].message_list;
          messageList.forEach((msg) => {
            msg["userInfo"] = users.filter((u) => {
              return u._id === msg.member_id;
            })[0];
          });
          element["atqMessages"] = messageList;
        }
      }
    });

    let fUsers = users.filter((d) => {
      return d.atqData.length > 0;
    });

    return { users: users, fUsers: fUsers, inputRef: inputRef };
  }

  getChartData(users) {
    let total = 0;
    let tracked = 0;

    users.forEach((element) => {
      let tempChallengeArr = element.member_challenges.filter((d) => {
        return d.isActive === true;
      });

      let tempProductArr = element.member_products.filter((d) => {
        return d.isActive === true;
      });

      if (tempChallengeArr.length !== 0 || tempProductArr.length !== 0) {
        total += 1;
        if (element.atqData.length > 0) {
          tracked += 1;
        }
      }
    });

    return {
      chartData: [total - tracked, tracked],
      chartLabel: [
        `Did Not Track (${total - tracked})`,
        `Tracked (${tracked})`,
      ],
    };
  }

  getActiveUsers(users) {
    let activeUsers = [];
    users.forEach((element) => {
      let tempChallengeArr = element.member_challenges.filter((d) => {
        return d.isActive === true;
      });

      let tempProductArr = element.member_products.filter((d) => {
        return d.isActive === true;
      });

      if (
        tempChallengeArr.length !== 0 ||
        tempProductArr.length !== 0 ||
        element.isTagAtq
      ) {
        activeUsers.push(element);
      }
    });
    return activeUsers;
  }
}

export default ResponsesLogic;
