import { callApi } from "../helpers";
import * as api from "./api";

function callCreateEditorPopup(data) {
  return callApi(api.service("editor-popup").create(data));
}

function callGetEditorPopups() {
  return callApi(api.service("editor-popup").find({}));
}

function callGetEditorPopupsUsingQuery(query, isLoading = true) {
  return callApi(api.service("editor-popup").find(query), isLoading);
}

function callGetEditorPopupsById(id, isLoading = true) {
  return callApi(api.service("editor-popup").get(id), isLoading);
}

function callUpdateEditorPopup(id, data) {
  return callApi(api.service("editor-popup").patch(id, data));
}

function callDeleteEditorPopupById(id) {
  return callApi(api.service("editor-popup").remove(id));
}

function callSyncEditorPopup(id) {
  return callApi(api.service("editor-popup/sync").patch(id, {}));
}

export {
  callCreateEditorPopup,
  callGetEditorPopups,
  callGetEditorPopupsUsingQuery,
  callGetEditorPopupsById,
  callUpdateEditorPopup,
  callDeleteEditorPopupById,
  callSyncEditorPopup,
};
