import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getStripePlans } from "../../../modules/actions/index";

import FilterList from "../../../components/form/filterList";
import TitleBox from "../../../components/general/titleBox";
import { filterByTags } from "../../../helpers/filters";

class ViewSubscriptionPlan extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      plans: [],
      plansBackup: [],
      periodList: [],
      filterList: [],
      keyFilter: Math.random(),
    };
  }

  componentDidMount() {
    this.props.getStripePlans();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.stripePlans.data) {
      this.setState({
        plans: newProps.stripePlans.data,
        plansBackup: newProps.stripePlans.data,
      });

      this.prepareDataForFilter(newProps.stripePlans.data);
    }
  }

  onClickEditPlan(element) {
    this.props.history.push("/admin/add-subscription-plan", { plan: element });
  }

  renderPlans() {
    var returnVals = [];
    let plans = this.state.plans;
    plans.forEach((element) => {
      returnVals.push(
        <tr key={element._id}>
          <td>{element.description}</td>
          <td>{element.stripe_plan_id}</td>
          <td>{parseFloat(element.amount) / 100}</td>
          <td>{element.interval_period}</td>
          <td>{element.trial_days}</td>
          <td>{element.trial_amount}</td>
          <td>
            <button
              onClick={this.onClickEditPlan.bind(this, element)}
              className="btn btn-default btn-sm border-0 margin-right-10">
              <i className="fa fa-pencil" />
            </button>
          </td>
        </tr>
      );
    });
    return returnVals;
  }

  prepareDataForFilter(data) {
    let periodList = [{ name: "All", value: "all", selected: true }];
    let period;

    data.forEach((element) => {
      period = element.interval_period;

      let arrPeriod = periodList.filter((d) => {
        return d.name === period.toLowerCase();
      });
      if (arrPeriod.length === 0) {
        periodList.push({
          name: period.toLowerCase(),
          value: period,
          selected: false,
        });
      }
    });

    this.setState({
      periodList: periodList,
      keyFilter: Math.random(),
    });
  }

  filterUpdated(updatedFilter, name, type, searchFields) {
    let filterList = this.state.filterList;
    filterList[name] = {
      name: name,
      type: type,
      searchFields: searchFields,
      values: updatedFilter,
    };
    this.setState(
      {
        filterList: filterList,
      },
      () => {
        let data = filterByTags(this.state.plansBackup, filterList);
        this.setState({
          plans: data,
        });
      }
    );
  }

  renderFilterView() {
    return (
      <>
        <div className="list-group-item">
          <FilterList
            label={"Search Plans"}
            name={"search"}
            type={"search"}
            searchFields={["description", "stripe_plan_id"]}
            onFinish={this.filterUpdated.bind(this)}
          />
        </div>
        <div className="list-group-item">
          <p>Select A Period</p>
          <FilterList
            key={this.state.keyFilter}
            filterList={this.state.periodList}
            name={"period"}
            type={"single"}
            searchFields={["interval_period"]}
            onFinish={this.filterUpdated.bind(this)}
          />
        </div>
      </>
    );
  }

  render() {
    return (
      <div className="container-fluid">
        <TitleBox title="View System Plans" />
        <div className="gap20" />
        <div className="card">
          <div className="list-group list-group-flush">
            {this.renderFilterView()}
            <div className="list-group-item">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Description</th>
                    <th>ID </th>
                    <th>Amount</th>
                    <th>Period</th>
                    <th>Trial</th>
                    <th>Trial Amount</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>{this.renderPlans()}</tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  stripePlans: state.billingReducer.stripePlans,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getStripePlans,
    },
    dispatch
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewSubscriptionPlan);
