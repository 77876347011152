import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import $ from "jquery";
import TitleBox from "../../../components/general/titleBox";

import {
  callGetQuestionsUsingQuery,
  callUpdateChlgQue,
  callCreateChlgQue,
} from "../../../services";
import { showNotification } from "../../../helpers";
import ExportContent from "../../../helpers/exportContent";

class ViewChallengeQuestion extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    let filterClass = {
      all: "active",
      focus: "",
      bonus: "",
      chartable: "",
    };
    this.state = {
      questions: [],
      queBackup: [],
      filterClass: filterClass,
      searchQuery: "",
      selectedFilter: "all",
      selectedQuestion: {},
      questionName: "",
      keyExport: Math.random(),
    };
  }

  componentDidMount() {
    this.getQuestions();
  }

  async getQuestions() {
    let query = {
      query: {
        status: { $ne: "archive" },
      },
    };
    let d = await callGetQuestionsUsingQuery(query);
    this.setState({
      questions: d.data,
      queBackup: d.data,
      keyExport: Math.random(),
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  onClickEditQuestion = (challengeQuestion) => {
    this.props.history.push("/admin/add-question", {
      challengeQuestion: challengeQuestion,
    });
  };

  onClickCloneQuestion = (question) => {
    this.setState({ selectedQuestion: question }, () => {
      $(".modalAlertOverlay.cloneOverlay,.modalAlert.cloneAlert").fadeIn(200);
    });
  };

  onClickArchiveQuestion = (question) => {
    this.setState({ selectedQuestion: question }, () => {
      $(".modalAlertOverlay.archiveOverlay,.modalAlert.archiveAlert").fadeIn(
        200
      );
    });
  };

  renderQuestions() {
    var returnVals = [];
    let questions = this.state.questions;
    questions.forEach((element) => {
      returnVals.push(
        <tr key={element._id}>
          <td>{element.name}</td>
          <td>{element.question}</td>
          <td className="firstLetter">
            {element.group_info
              ? element.group_info.name
                ? element.group_info.name
                : ""
              : ""}
          </td>
          <td className="firstLetter">{element.answer_type}</td>
          <td>{element.answer_list.length}</td>
          <td>
            <button
              onClick={this.onClickEditQuestion.bind(this, element)}
              className="btn btn-default btn-sm border-0 margin-right-10">
              <i className="fa fa-pencil" />
            </button>
            <button
              title="Clone"
              onClick={this.onClickCloneQuestion.bind(this, element)}
              className="btn btn-default btn-sm border-0 margin-right-10">
              <i className="fa fa-clone" />
            </button>
            <button
              title="Archive"
              onClick={this.onClickArchiveQuestion.bind(this, element)}
              className="btn btn-danger btn-sm  border-0">
              <i className="fa fa-archive" />
            </button>
          </td>
        </tr>
      );
    });
    return returnVals;
  }

  onClickFilterOption(option, searchQuery) {
    let filterClass = this.state.filterClass;
    for (const key in filterClass) {
      if (filterClass.hasOwnProperty(key)) {
        if (key === option) {
          filterClass[key] = "active";
        } else {
          filterClass[key] = "";
        }
      }
    }
    let questions = this.state.queBackup;
    let filterQue = [];

    if (option === "focus" || option === "bonus") {
      filterQue = questions.filter((el) => {
        return el.item_type === option;
      });
    } else if (option === "all") {
      filterQue = this.state.queBackup;
    } else if (option === "chartable") {
      filterQue = questions.filter((el) => {
        return el.show_chart === true;
      });
    }
    if (searchQuery.length > 0) {
      filterQue = filterQue.filter((el) => {
        return (
          el.name.indexOf(searchQuery) > -1 ||
          el.description.indexOf(searchQuery) > -1
        );
      });
    }

    this.setState({
      filterClass: filterClass,
      selectedFilter: option,
      questions: filterQue,
    });
  }

  handleSearchChange(event) {
    const value = event.target.value;
    if (value.length > 0) {
      let questions = this.state.queBackup;
      let filterQue = questions.filter((el) => {
        return (
          el.name.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
          el.description.toLowerCase().indexOf(value.toLowerCase()) > -1
        );
      });
      this.setState({
        searchQuery: value,
        questions: filterQue,
      });
    } else {
      this.setState({
        searchQuery: value,
      });
      this.onClickFilterOption(this.state.selectedFilter, value);
    }
  }

  onClickNoArchiveAlert = () => {
    $(".modalAlertOverlay.archiveOverlay,.modalAlert.archiveAlert").fadeOut(
      200
    );
  };

  onClickYesArchiveAlert = () => {
    callUpdateChlgQue(this.state.selectedQuestion._id, {
      status: "archive",
    }).then((d) => {
      $(".modalAlertOverlay.archiveOverlay,.modalAlert.archiveAlert").fadeOut(
        200
      );
      this.getQuestions();
    });
  };

  renderArchiveAlert() {
    let selectedQuestion = this.state.selectedQuestion;
    let name = "";
    if (selectedQuestion.name) {
      name = selectedQuestion.name;
    }
    return (
      <div>
        <div className="modalAlertOverlay archiveOverlay" />
        <div className="modalAlert archiveAlert">
          <p>{`Are you sure you want to archive "${name}" question?`}</p>
          <div className="alertFooterBtns">
            <button
              className="btn btn-default closeModal-No margin-right-10"
              onClick={this.onClickNoArchiveAlert}>
              No
            </button>
            <button
              className="btn btn-primary closeModal-Yes"
              onClick={this.onClickYesArchiveAlert}>
              Yes
            </button>
          </div>
        </div>
      </div>
    );
  }

  onClickCancelCloneModal() {
    $(".modalAlertOverlay.cloneOverlay,.modalAlert.cloneAlert").fadeOut(200);
  }

  onClickProcessCloneModal() {
    if (this.state.questionName.length === 0) {
      showNotification("error", "Please enter question name", 4000);
      return;
    }

    $(".modalAlertOverlay.cloneOverlay,.modalAlert.cloneAlert").fadeOut(200);

    let question = this.state.selectedQuestion;

    question.name = this.state.questionName;
    question.question = this.state.questionName;
    delete question._id;

    callCreateChlgQue(question).then((d) => {
      if (d._id) {
        this.setState(
          {
            selectedQuestion: {},
            questionName: "",
          },
          () => {
            showNotification("success", "Question created successfully", 3000);
            this.onClickEditQuestion(d);
          }
        );
      } else {
        showNotification("error", "Something went wrong", 4000);
      }
    });
  }

  onClickViewArchivedQuestions() {
    this.props.history.push("/admin/view-archived-questions");
  }

  renderCloneQuestionModal() {
    let name = "";
    if (this.state.selectedQuestion.name) {
      name = this.state.selectedQuestion.name;
    }
    return (
      <div>
        <div className="modalAlertOverlay cloneOverlay" />
        <div className="modalAlert cloneAlert">
          <div className="clearfix">
            <div className="pull-left">
              <p>Clone Question - {name}</p>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div
                  className={
                    this.state.questionName !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Question Name</label>
                  <input
                    type="text"
                    name="questionName"
                    value={this.state.questionName}
                    onChange={(event) => this.handleInputChange(event)}
                  />
                </div>
                <div className="gap10" />
              </div>
            </div>
          </div>
          <div className="gap10" />

          <div className="alertFooterBtns">
            <button
              onClick={this.onClickCancelCloneModal.bind(this)}
              className="btn btn-default dismissThisModal margin-right-10">
              Cancel
            </button>
            <button
              onClick={this.onClickProcessCloneModal.bind(this)}
              className="btn btn-primary dismissThisModal">
              Create Question
            </button>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="container-fluid">
        {this.renderArchiveAlert()}
        {this.renderCloneQuestionModal()}
        <TitleBox title="Question Overview" />
        <div className="gap20" />
        <div className="card">
          <div className="list-group list-group-flush">
            <div className="list-group-item">
              <ExportContent
                key={this.state.keyExport}
                data={this.state.questions}
                keys={[
                  "name",
                  "question",
                  "item_type",
                  "createdAt",
                  "description",
                  "answer_type",
                  "show_chart",
                  "enable_accountability",
                ]}
                screen={"challenge-questions"}
              />
            </div>
            <div className="list-group-item">
              <div className="mdInput">
                <label>Search Questions</label>
                <input
                  type="text"
                  value={this.state.searchQuery}
                  onChange={(event) => this.handleSearchChange(event)}
                />
              </div>
            </div>
            <div className="list-group-item">
              <button
                className="btn btn-default btn-sm"
                onClick={this.onClickViewArchivedQuestions.bind(this)}>
                View Archived Questions
              </button>
            </div>

            <div className="list-group-item">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Question Name</th>
                    <th>Question</th>
                    <th>Group</th>
                    <th>Type</th>
                    <th>Answers</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>{this.renderQuestions()}</tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewChallengeQuestion);
