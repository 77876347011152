import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import $ from "jquery";

import TitleBox from "../../../components/general/titleBox";
import FilterList from "../../../components/form/filterList";

import { filterByTags } from "../../../helpers/filters";
import {
  callDeleteEditorPopupById,
  callGetEditorPopups,
  callSyncEditorPopup,
} from "../../../services/popupService";
import { isTemplateSite, showNotification } from "../../../helpers";

class ViewEditorPopupsPage extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      popups: [],
      popupsBackup: [],
      filterList: [],
      selectedPopup: {},
    };
  }

  componentDidMount() {
    this.getPopups();
  }

  getPopups() {
    callGetEditorPopups().then((d) => {
      this.setState({ popups: d.data, popupsBackup: d.data });
    });
  }

  onClickEditPopup = (popup) => {
    this.props.history.push("/admin/add-editor-popup", {
      popup: popup,
    });
  };

  onClickSyncPopup = async (popup) => {
    await callSyncEditorPopup(popup._id);
    showNotification("success", "Popup synced successfully");
  };

  onClickDeletePopup = (popup) => {
    this.setState({ selectedPopup: popup }, () => {
      $(".modalAlertOverlay,.modalAlert").fadeIn(200);
    });
  };

  filterUpdated(updatedFilter, name, type, searchFields) {
    let filterList = this.state.filterList;
    filterList[name] = {
      name: name,
      type: type,
      searchFields: searchFields,
      values: updatedFilter,
    };
    this.setState(
      {
        filterList: filterList,
      },
      () => {
        let data = filterByTags(this.state.popupsBackup, filterList);
        this.setState({
          popups: data,
        });
      }
    );
  }

  renderPopups() {
    var returnVals = [];
    let items = this.state.popups;
    items.forEach((element) => {
      returnVals.push(
        <tr key={element._id}>
          <td>{element.title}</td>
          <td className="min-width-100">
            <button
              onClick={this.onClickEditPopup.bind(this, element)}
              className="btn btn-default btn-sm border-0 margin-right-10">
              <i className="fa fa-pencil" />
            </button>
            {isTemplateSite() && (
              <button
                onClick={this.onClickSyncPopup.bind(this, element)}
                className="btn btn-default btn-sm border-0">
                <i className="fa fa-refresh" />
              </button>
            )}
            {!element.from_template && (
              <button
                onClick={this.onClickDeletePopup.bind(this, element)}
                className="btn btn-danger btn-sm margin-right-10 border-0">
                <i className="fa fa-trash" />
              </button>
            )}
          </td>
        </tr>
      );
    });
    return returnVals;
  }

  onClickNoAlert = () => {
    $(".modalAlertOverlay,.modalAlert").fadeOut(200);
  };

  onClickYesAlert = () => {
    callDeleteEditorPopupById(this.state.selectedPopup._id).then((d) => {
      $(".modalAlertOverlay,.modalAlert").fadeOut(200);
      this.getPopups();
    });
  };

  renderConfirmationAlert() {
    let selectedPopup = this.state.selectedPopup;
    let title = "";
    if (selectedPopup.title) {
      title = selectedPopup.title;
    }
    return (
      <div>
        <div className="modalAlertOverlay" />
        <div className="modalAlert">
          <p>{`Are you sure you want to delete "${title}" popup?`}</p>
          <div className="alertFooterBtns">
            <button
              className="btn btn-default closeModal-No margin-right-10"
              onClick={this.onClickNoAlert}>
              No
            </button>
            <button
              className="btn btn-primary closeModal-Yes"
              onClick={this.onClickYesAlert}>
              Yes
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderFilterView() {
    return (
      <div className="list-group-item">
        <FilterList
          label={"Search Popups..."}
          name={"search"}
          type={"search"}
          searchFields={["title"]}
          onFinish={this.filterUpdated.bind(this)}
        />
      </div>
    );
  }

  render() {
    return (
      <div className="container-fluid">
        {this.renderConfirmationAlert()}
        <TitleBox title="Popups Overview" />
        <div className="gap20" />
        <div className="card">
          <div className="list-group list-group-flush">
            {this.renderFilterView()}
            <div className="list-group-item">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>{this.renderPopups()}</tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewEditorPopupsPage);
