import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import DatePicker from "react-date-picker";

import TitleBox from "../../../components/general/titleBox";
import {
  callGetChlgs,
  callCreateLicense,
  callUpdateLicense,
} from "../../../services";
import { showNotification } from "../../../helpers";
import HelpTextDiv from "../../../components/form/helpTextDiv";

class AddLicense extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    let licenseInfo = {
      id: 0,
      name: "",
      description: "",
      expireDate: new Date(),
      selectedChallenge: "",
      selectedProduct: "",
      selectedChallenges: [],
      selectedProducts: [],
    };

    if (this.props.history.location.state !== undefined) {
      let license = this.props.history.location.state.license;

      licenseInfo.id = license._id;
      licenseInfo.name = license.name;
      licenseInfo.description = license.description;
      licenseInfo.expireDate = new Date(license.expire_date);
      licenseInfo.selectedChallenges = license.challenge_list;
      licenseInfo.selectedProducts = license.product_list;
    }

    this.state = {
      licenseId: licenseInfo.id,
      name: licenseInfo.name,
      description: licenseInfo.description,
      expireDate: licenseInfo.expireDate,
      selectedChallenge: licenseInfo.selectedChallenge,
      selectedProduct: licenseInfo.selectedProduct,
      selectedChallenges: licenseInfo.selectedChallenges,
      selectedProducts: licenseInfo.selectedProducts,
      challenges: [],
      products: [{ _id: "marci-cirle", name: "Marci's Circle" }],
    };
  }

  componentDidMount() {
    callGetChlgs().then((d) => {
      this.setState({ challenges: d.data });
    });
  }

  resetProps() {
    this.setState({
      name: "",
      description: "",
      expireDate: new Date(),
      selectedChallenge: "",
      selectedProduct: "",
      selectedChallenges: [],
      selectedProducts: [],
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  onChangeExpireDate(date) {
    this.setState({ expireDate: date });
  }

  renderChallenges() {
    let returnVals = [];
    let challenges = this.state.challenges;
    returnVals.push(<option value={""} key={0} />);
    for (let i = 0; i < challenges.length; i++) {
      returnVals.push(
        <option value={challenges[i]._id} key={i + 1}>
          {challenges[i].name}
        </option>
      );
    }
    return returnVals;
  }

  renderAddedChallenges() {
    let selectedChallenges = this.state.selectedChallenges;
    let returnVals = [];

    for (let i = 0; i < selectedChallenges.length; i++) {
      const element = selectedChallenges[i];
      let challenge = this.state.challenges.filter((d) => {
        return d._id === element;
      });

      if (challenge.length > 0) {
        returnVals.push(
          <div key={i}>
            {challenge[0].name}
            <button
              onClick={this.onClickRemoveChallenge.bind(this, i)}
              className="btn btn-danger btn-sm"
              style={{ float: "right" }}>
              Remove
            </button>
            <div className="gap5" />
          </div>
        );
      }
    }
    return returnVals;
  }

  onClickRemoveChallenge(index) {
    let selectedChallenges = this.state.selectedChallenges;
    selectedChallenges.splice(index, 1);

    this.setState({ selectedChallenges: selectedChallenges });
  }

  onClickAddChallenge() {
    if (this.state.selectedChallenge.length === 0) {
      showNotification("error", "Please select challenge", 4000);
      return;
    }

    let selectedChallenges = this.state.selectedChallenges;
    selectedChallenges.push(this.state.selectedChallenge);

    this.setState({
      selectedChallenge: "",
      selectedChallenges: selectedChallenges,
    });
  }

  renderProducts() {
    let returnVals = [];
    let products = this.state.products;
    returnVals.push(<option value={""} key={0} />);
    for (let i = 0; i < products.length; i++) {
      returnVals.push(
        <option value={products[i]._id} key={i + 1}>
          {products[i].name}
        </option>
      );
    }
    return returnVals;
  }

  renderAddedProducts() {
    let selectedProducts = this.state.selectedProducts;
    let returnVals = [];

    for (let i = 0; i < selectedProducts.length; i++) {
      const element = selectedProducts[i];
      let product = this.state.products.filter((d) => {
        return d._id === element;
      });

      if (product.length > 0) {
        returnVals.push(
          <div key={i}>
            {product[0].name}
            <button
              onClick={this.onClickRemoveProduct.bind(this, i)}
              className="btn btn-danger btn-sm"
              style={{ float: "right" }}>
              Remove
            </button>
            <div className="gap5" />
          </div>
        );
      }
    }
    return returnVals;
  }

  onClickRemoveProduct(index) {
    let selectedProducts = this.state.selectedProducts;
    selectedProducts.splice(index, 1);

    this.setState({ selectedProducts: selectedProducts });
  }

  onClickAddProduct() {
    if (this.state.selectedProduct.length === 0) {
      showNotification("error", "Please select product", 4000);
      return;
    }

    let selectedProducts = this.state.selectedProducts;
    selectedProducts.push(this.state.selectedProduct);

    this.setState({
      selectedProduct: "",
      selectedProducts: selectedProducts,
    });
  }

  onClickAddLicense() {
    if (this.state.name.length === 0) {
      showNotification("error", "Please add name", 4000);
      return;
    }

    if (this.state.description.length === 0) {
      showNotification("error", "Please add description", 4000);
      return;
    }

    /*eslint-disable*/
    let apiData = {
      name: this.state.name,
      description: this.state.description,
      product_list: this.state.selectedProducts,
      challenge_list: this.state.selectedChallenges,
      expire_date: this.state.expireDate,
    };
    /*eslint-enable*/

    if (this.state.licenseId === 0) {
      callCreateLicense(apiData).then((d) => {
        if (d._id) {
          this.resetProps();
          showNotification("success", "License Created Successfully", 4000);
        } else {
          showNotification("error", "Something went wrong", 4000);
        }
      });
    } else {
      callUpdateLicense(this.state.licenseId, apiData).then((d) => {
        if (d._id) {
          showNotification("success", "License Updated Successfully", 4000);
        } else {
          showNotification("error", "Something went wrong", 4000);
        }
      });
    }
  }

  render() {
    return (
      <div>
        <div className="container-fluid">
          <TitleBox
            title={
              this.state.licenseId !== 0 ? "Update License" : "Add License"
            }
            showBackBtn={this.state.licenseId !== 0}
          />
          <div className="gap20" />
          <div className="card">
            <div className="container-fluid">
              <div className="gap20" />
              <HelpTextDiv
                id="idLicenseName"
                label="Learn more about the License Name"
                type="addLicense"
                isInline="false"
              />
              <div
                className={
                  this.state.name !== "" ? "mdInput mdFocussed" : "mdInput"
                }>
                <label>Name</label>
                <input
                  type="text"
                  name="name"
                  value={this.state.name}
                  onChange={(event) => this.handleInputChange(event)}
                />
              </div>
              <div className="gap20" />
              <HelpTextDiv
                id="idLicenseDescription"
                label="Learn more about the License Description"
                type="addLicense"
                isInline="false"
              />
              <div
                className={
                  this.state.description !== ""
                    ? "mdInput mdFocussed"
                    : "mdInput"
                }>
                <label>Description</label>
                <input
                  type="text"
                  name="description"
                  value={this.state.description}
                  onChange={(event) => this.handleInputChange(event)}
                />
              </div>
              <div className="gap20" />
              <HelpTextDiv
                id="idLicenseExpiryDate"
                label="Learn more about the License Expiry Date"
                type="addLicense"
                isInline="false"
              />
              <label className="inputFakeLabelFocussed">Expire Date</label>
              <div>
                <DatePicker
                  value={this.state.expireDate}
                  onChange={this.onChangeExpireDate.bind(this)}
                />
              </div>
              <div className="gap20" />
            </div>
          </div>

          <div className="card">
            <div className="container-fluid">
              <div className="gap20" />
              <h5 className="noMargin">Add Challenges</h5>
              <div className="gap20" />
              <div className="row">
                <div className="col-lg-4">
                  <HelpTextDiv
                    id="idLicenseChallenge"
                    label="Learn more about the License Challenge"
                    type="addLicense"
                    isInline="false"
                  />
                  <div
                    className={
                      this.state.selectedChallenge !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>Select Challenge</label>
                    <select
                      name="selectedChallenge"
                      value={this.state.selectedChallenge}
                      onChange={(event) => this.handleInputChange(event)}>
                      {this.renderChallenges()}
                    </select>
                  </div>
                </div>
              </div>
              <div className="gap20" />
              <div
                className="text-right"
                onClick={() => this.onClickAddChallenge()}>
                {/*eslint-disable-next-line*/}
                <a className="btn btn-primary addRuleBtn">Add Challenge</a>
              </div>
              <div className="gap20" />
            </div>
          </div>

          {this.state.selectedChallenges.length > 0 && (
            <div className="card">
              <div className="container-fluid">
                <div className="gap20" />
                <h5 className="noMargin">Added Challenges</h5>
                <div className="gap20" />
                <div className="addedRule">{this.renderAddedChallenges()}</div>
                <div className="gap20" />
              </div>
            </div>
          )}

          <div className="card">
            <div className="container-fluid">
              <div className="gap20" />
              <h5 className="noMargin">Add Products</h5>
              <div className="gap20" />
              <div className="row">
                <div className="col-lg-4">
                  <HelpTextDiv
                    id="idLicenseProduct"
                    label="Learn more about the License Product"
                    type="addLicense"
                    isInline="false"
                  />
                  <div
                    className={
                      this.state.selectedProduct !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>Select Product</label>
                    <select
                      name="selectedProduct"
                      value={this.state.selectedProduct}
                      onChange={(event) => this.handleInputChange(event)}>
                      {this.renderProducts()}
                    </select>
                  </div>
                </div>
              </div>
              <div className="gap20" />
              <div className="text-right">
                <button
                  className="btn btn-primary addRuleBtn"
                  onClick={() => this.onClickAddProduct()}>
                  Add Product
                </button>
              </div>
              <div className="gap20" />
            </div>
          </div>

          {this.state.selectedProducts.length > 0 && (
            <div className="card">
              <div className="container-fluid">
                <div className="gap20" />
                <h5 className="noMargin">Added Products</h5>
                <div className="gap20" />
                <div className="addedRule">{this.renderAddedProducts()}</div>
                <div className="gap20" />
              </div>
            </div>
          )}

          <div className="text-right">
            <button
              className="btn btn-primary"
              onClick={() => this.onClickAddLicense()}>
              {this.state.licenseId !== 0 ? "Update License" : "Add License"}
            </button>
          </div>
          <div className="gap20" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddLicense);
