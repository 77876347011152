import React, { Component } from "react";
import Select from "react-select";

class TypeableSelect extends Component {
  constructor(props) {
    super(props);

    const { name, placeholder } = this.props;

    this.state = {
      name: name,
      placeholder: placeholder || "",
      selected: this.props.selected || { value: "", label: "" },
      options: this.props.options,
    };
  }

  onChangeSelectValue = (selectedValue) => {
    this.setState({ selected: selectedValue });
    this.props.onSelect(selectedValue);
  };

  render() {
    let value = this.state.selected;
    if (value) {
      value = value.value;
    }

    return (
      // <div className={value ? "mdInput mdFocussed" : "mdInput"}>
      //   <label>{this.state.placeholder}</label>
      <Select
        name={this.state.name}
        className={""}
        placeholder={this.state.placeholder}
        resetValue={{ value: "", label: "" }}
        value={this.state.selected}
        options={this.state.options}
        onChange={this.onChangeSelectValue}
      />
      // </div>
    );
  }
}

export default TypeableSelect;
