import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import $ from "jquery";

import FilterList from "../../../components/form/filterList";
import TitleBox from "../../../components/general/titleBox";
import { filterByTags } from "../../../helpers/filters";

import {
  callCreateChlgItem,
  callGetChlgItemById,
  callGetChlgItemsByQuery,
  callUpdateChlgItem,
} from "../../../services";
import { showNotification } from "../../../helpers";
import { getUpdatedEditorContent } from "../../../components/editor/processEditor";
import ExportContent from "../../../helpers/exportContent";

class ViewChallengeItem extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      items: [],
      itemsBackup: [],
      searchQuery: "",
      filterList: [],
      itemTypes: [],
      keyFilter: Math.random(),
      itemName: "",
      selectedItem: {},
      keyExport: Math.random(),
    };
  }

  componentDidMount() {
    this.getChlgItems();
  }

  async getChlgItems() {
    let query = {
      query: {
        archive: { $ne: true },
        $select: ["-content", "-editor_content"],
      },
    };
    let response = await callGetChlgItemsByQuery(query);
    this.setState(
      {
        items: response.data,
        itemsBackup: response.data,
        keyExport: Math.random(),
      },
      () => {
        this.prepareDataForFilter(response.data);
      }
    );
  }

  prepareDataForFilter(data) {
    let itemTypes = [{ name: "All", value: "all", selected: true }];

    data.forEach((element) => {
      let itemType = element.item_type_info
        ? element.item_type_info.name
          ? element.item_type_info.name
          : ""
        : "";
      if (itemType) {
        let arrType = itemTypes.filter((d) => {
          return d.name.toLowerCase() === itemType.toLowerCase();
        });
        if (arrType.length === 0) {
          itemTypes.push({
            name: itemType.charAt(0).toUpperCase() + itemType.slice(1),
            value: itemType.toLowerCase(),
            selected: false,
          });
        }
        element["item_type_name"] = itemType;
      }
    });

    this.setState({
      items: data,
      itemsBackup: data,
      itemTypes: itemTypes,
      keyFilter: Math.random(),
    });
  }

  onClickEditItem = (challengeItem) => {
    callGetChlgItemById(challengeItem._id).then((d) => {
      this.props.history.push("/admin/add-challenge-item", {
        challengeItem: d,
      });
    });
  };

  filterUpdated(updatedFilter, name, type, searchFields) {
    let filterList = this.state.filterList;
    filterList[name] = {
      name: name,
      type: type,
      searchFields: searchFields,
      values: updatedFilter,
    };
    this.setState(
      {
        filterList: filterList,
      },
      () => {
        let data = filterByTags(this.state.itemsBackup, filterList);
        this.setState({
          items: data,
        });
      }
    );
  }

  onClickCloneItem = (item) => {
    this.setState({ selectedItem: item }, () => {
      $(".modalAlertOverlay.cloneModalOverlay,.modalAlert.cloneAlert").fadeIn(
        200
      );
    });
  };

  onClickArchiveChallengeItem = (item) => {
    this.setState({ selectedItem: item }, () => {
      $(".modalAlertOverlay.archiveOverlay,.modalAlert.archiveAlert").fadeIn(
        200
      );
    });
  };

  renderItems() {
    var returnVals = [];
    let items = this.state.items;
    items.forEach((element) => {
      returnVals.push(
        <tr key={element._id}>
          <td>{element.name}</td>
          {/* <td>{element.description}</td> */}
          <td className="firstLetter">
            {element.item_type_info
              ? element.item_type_info.name
                ? element.item_type_info.name
                : ""
              : ""}
          </td>
          <td>{element.default_hide_on_lock ? "Yes" : "No"}</td>
          <td>{element.video_list.length}</td>
          <td>
            <button
              onClick={this.onClickCloneItem.bind(this, element)}
              className="btn btn-default btn-sm margin-right-10 border-0">
              <i className="fa fa-clone" />
            </button>
            <button
              onClick={this.onClickEditItem.bind(this, element)}
              className="btn btn-default btn-sm border-0 margin-right-10">
              <i className="fa fa-pencil" />
            </button>
            <button
              title="Archive"
              onClick={this.onClickArchiveChallengeItem.bind(this, element)}
              className="btn btn-danger btn-sm  border-0">
              <i className="fa fa-archive" />
            </button>
          </td>
        </tr>
      );
    });
    return returnVals;
  }

  handleSearchChange(event) {
    const value = event.target.value;
    if (value.length > 0) {
      this.setState({
        searchQuery: value,
      });
    } else {
      this.setState({
        searchQuery: value,
      });
    }
  }

  onClickCancelFromCloneModal() {
    $(".modalAlertOverlay.cloneModalOverlay,.modalAlert.cloneAlert").fadeOut(
      200
    );
  }

  async onClickProcessFromCloneModal() {
    if (this.state.itemName.length === 0) {
      showNotification("error", "Please enter challenge item name", 4000);
      return;
    }

    $(".modalAlertOverlay.cloneModalOverlay,.modalAlert.cloneAlert").fadeOut(
      200
    );

    let sItem = this.state.selectedItem;

    let selectedItem = await callGetChlgItemById(sItem._id);

    let apiData = {
      name: this.state.itemName,
      description: selectedItem.description,
      /*eslint-disable*/
      item_type: selectedItem.item_type,
      content: selectedItem.content,
      editor_content: getUpdatedEditorContent(
        selectedItem.editor_content,
        selectedItem.content
      ),
      challenge_question_list: selectedItem.challenge_question_list,
      video_list: selectedItem.video_list,
      is_bonus: selectedItem.is_bonus,
      is_atq_item: selectedItem.is_atq_item,
      is_rating_item: selectedItem.is_rating_item,
      rating_type: selectedItem.rating_type,
      helpfullness_rating: selectedItem.helpfullness_rating,
      number_rating: selectedItem.number_rating,
      /*eslint-enable*/
    };

    callCreateChlgItem(apiData).then((d) => {
      if (d._id) {
        this.setState({ selectedItem: {}, itemName: "" }, () => {
          showNotification(
            "success",
            "Challenge Item created successfully",
            4000
          );
          this.props.getChlgItems();
        });
      } else {
        showNotification("error", "Something went wrong", 4000);
      }
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  renderCloneModal() {
    let name = "";
    if (this.state.selectedItem.name) {
      name = this.state.selectedItem.name;
    }
    return (
      <div>
        <div className="modalAlertOverlay cloneModalOverlay" />
        <div className="modalAlert cloneAlert">
          <div className="clearfix">
            <div className="pull-left">
              <p>Clone Challenge Item - {name}</p>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div
                  className={
                    this.state.itemName !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Challenge Item Name</label>
                  <input
                    type="text"
                    name="itemName"
                    value={this.state.itemName}
                    onChange={(event) => this.handleInputChange(event)}
                  />
                </div>
                <div className="gap10" />
              </div>
            </div>
          </div>
          <div className="gap10" />

          <div className="alertFooterBtns">
            <button
              onClick={this.onClickCancelFromCloneModal.bind(this)}
              className="btn btn-default dismissThisModal margin-right-10">
              Cancel
            </button>
            <button
              onClick={this.onClickProcessFromCloneModal.bind(this)}
              className="btn btn-primary dismissThisModal">
              Create Challenge Item
            </button>
          </div>
        </div>
      </div>
    );
  }

  onClickNoArchiveAlert = () => {
    $(".modalAlertOverlay.archiveOverlay,.modalAlert.archiveAlert").fadeOut(
      200
    );
  };

  onClickYesArchiveAlert = () => {
    callUpdateChlgItem(this.state.selectedItem._id, { archive: true }).then(
      (d) => {
        $(".modalAlertOverlay.archiveOverlay,.modalAlert.archiveAlert").fadeOut(
          200
        );
        this.getChlgItems();
      }
    );
  };

  renderArchiveConfirmationAlert() {
    let selectedItem = this.state.selectedItem;
    let name = "";
    if (selectedItem.name) {
      name = selectedItem.name;
    }
    return (
      <div>
        <div className="modalAlertOverlay archiveOverlay" />
        <div className="modalAlert archiveAlert">
          <p>{`Are you sure you want to archive "${name}" challenge item?`}</p>
          <div className="alertFooterBtns">
            <button
              className="btn btn-default closeModal-No margin-right-10"
              onClick={this.onClickNoArchiveAlert}>
              No
            </button>
            <button
              className="btn btn-primary closeModal-Yes"
              onClick={this.onClickYesArchiveAlert}>
              Yes
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderFilterView() {
    return (
      <>
        <div className="list-group-item">
          <FilterList
            label={"Search Challenge Items"}
            name={"search"}
            type={"search"}
            searchFields={["name", "content", "description"]}
            onFinish={this.filterUpdated.bind(this)}
          />
        </div>
        <div className="list-group-item">
          <p>Select Item Type</p>
          <FilterList
            key={this.state.keyFilter}
            filterList={this.state.itemTypes}
            name={"searchType"}
            type={"single"}
            searchFields={["item_type_name"]}
            onFinish={this.filterUpdated.bind(this)}
          />
        </div>
      </>
    );
  }

  onClickViewArchivedChallengeItems() {
    this.props.history.push("/admin/view-archived-challenge-items");
  }

  render() {
    return (
      <div className="container-fluid">
        {this.renderCloneModal()}
        {this.renderArchiveConfirmationAlert()}
        <TitleBox title="Challenge Item Overview" />
        <div className="gap20" />
        <div className="card">
          <div className="list-group list-group-flush">
            {this.renderFilterView()}
            <div className="list-group-item">
              <button
                className="btn btn-default btn-sm"
                onClick={this.onClickViewArchivedChallengeItems.bind(this)}>
                View Archived Challenge Items
              </button>
            </div>
            <div className="list-group-item">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Challenge Item Name </th>
                    {/* <th>Description</th> */}
                    <th>Item Category</th>
                    <th>Locks Up</th>
                    <th>Videos</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>{this.renderItems()}</tbody>
              </table>
            </div>
            <div className="list-group-item">
              <ExportContent
                key={this.state.keyExport}
                data={this.state.items}
                keys={["name", "description", "createdAt", "item_type_name"]}
                screen={"challenge-items"}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  chlgItems: state.challengeReducer.chlgItems,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ViewChallengeItem);
