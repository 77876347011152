const styleHtml = `      
      <style type="text/css">{{--REPLACE-CUSTOM-STYLE--}}</style>
      {{--REPLACE-FONT-STYLE--}}
      <style type="text/css">
        table td { border: 0;}
        table tr { border: 0;}
        table { border: 0; }
        img { border: 0; }      
        p { margin-block-start: 0; margin-block-end: 0; margin: 0; } 
        a { margin-block-start: 0; margin-block-end: 0; margin: 0; text-decoration:none;} 
        td { margin-block-start: 0; margin-block-end: 0; margin: 0; } 
        body {-webkit-font-smoothing:antialiased;font-family:Arial,Times,serif;margin-block-start: 0 !important;margin-block-end: 0!important; margin: 0 !important; -webkit-font-smoothing: antialiased;}
        table{border-collapse:collapse !important}*[class*="mobileOn"]{display:none !important;max-height:none !important}
        h1,h2,h3,h4,h5,h6 {margin-block-start: 0; margin-block-end: 0; margin: 0; padding:0; font-size:inherit; font-weight:inherit; } 
        body, table, td, a { -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; }
        table, td { mso-table-lspace: 0pt; mso-table-rspace: 0pt; } // EMAIL CLIENTS
        img { -ms-interpolation-mode: bicubic; }
        a[x-apple-data-detectors] { color: inherit !important; text-decoration: none !important; }
        // END EMAIL CLIENTS
        .ReadMsgBody{width:100%} 
        .ExternalClass{width:100%;}
        .mpTextInputStandard {
          padding: 10px;
          border-radius: 4px;
          border: 1px solid #e8e8e8;
          font-size: 14px;
          box-sizing: border-box !important;
        }
        .video-responsive {
          overflow: hidden;
          padding-bottom: 56.25%;
          position: relative;
          height: 0;
        }
        .video-responsive iframe {
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          position: absolute;
        }
        
        .modifySpeedButton {
          cursor:pointer;
        }
        .video-js .vjsSpeedModify {
        width: 40px;
        }
        
        @keyframes playButtonColor {
          from, to { color: orange; }
          0%      { color: orange; }
          50%      { color: white; }
          100%      { color: orange; }
        }
        
        .vjs-big-play-button > .vjs-icon-placeholder {
          color: orange;
          animation: playButtonColor 3s 2;
        }
        

      </style>
      <style type="text/css">
      @media only screen and (max-width: 639px){.wrapper{width:100%;padding:0 !important}}
      @media screen and (max-width:480px)
        {
          .centerClass{margin:0 auto !important}
          .container{padding:0 !important}
          .mobile{
            width:100%;
            max-width:100%;
            float:left;
          }
          .mobileCol{
            max-width:100%
          }
        }
      </style>
      <style type="text/css">
      @media screen and (max-width:480px)
        {
          .mobile50{width:300px;padding:0 !important;text-align:center}
          *[class="mobileOff"]{width:0px !important;display:none !important}*[class*="mobileOn"]{display:block !important;max-height:none !important}} 
          a {text-decoration:none;}
        }
      </style>
      <style type="text/css">
      /* ==== EXPORTED GRID SYSTEM ==== */

      .mp-section {
        margin-left: auto;
        margin-right: auto;
      }
      
      .mp-row {
        display: flex;
        flex-wrap: wrap; /* Ensures that content wraps if there isn't enough space */
      }

      
      .mp-no-margin {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
      }
      
      /* Make .mp-col a flex item */
      .mp-col {
        flex: 0 0 auto; /* Prevent columns from shrinking or growing */
        width: auto; /* Default to auto width */
        max-width: 100%; /* Ensure columns do not exceed their row's width */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0;
        margin: 0;
        box-sizing: border-box;
      }
      
      @media only screen and (max-width: 550px) {
        /* 540px */
        .mp-col {
          width: 100% !important;
        }
        .reverseFlexColumns
        {
          flex-direction: column-reverse;
        }
      }

      :focus{outline: none;}
      
      input[type="text"]{font: 15px/24px 'Muli', sans-serif; color: #333; width: 100%; box-sizing: border-box; letter-spacing: 1px;}

      :focus{outline: none;}

      .stripeLoading {
          display:none;
      }

      .hiddenCode{
          display:none;
      }

      .checkout-text-container{float: left; width: 100%; position: relative; margin-top:30px;} /* necessary to give position: relative to parent. */

      
      /* ==== END EXPORTED GRID SYSTEM ==== */

      /* AUDIO PLAYER */

      #audioPlayerProgress {
        width: 100%;
        background-color: #d9d9f2;
        cursor: pointer;
        border-radius: 10px;
      }

      #audioPlayerBar {
        width: 0%;
        height: 5px;
        background-color: #ffc266;
        border-radius: 10px;
      }

      .ap-btn-action {
        cursor: pointer;
        padding-top: 10px;
        width: 30px;
      }

      .ap-btn-ctn,
      .ap-infos-ctn {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .ap-infos-ctn {
        padding-top: 0px;
        font-size: 14px;
      }

      .ap-btn-ctn > div {
        padding: 5px;
        margin-top: 18px;
        margin-bottom: 0px;
      }

      .ap-infos-ctn > div {
        margin-bottom: 8px;
        color: #ffc266;
      }

      .ap-first-btn {
        margin-left: 3px;
      }

      .ap-duration {
        margin-left: 10px;
      }

      .ap-title {
        margin-left: 10px;
        width: 210px;
        text-align: center;
      }

      .player-ctn {
        border-radius: 15px;
        width: 100%;
        background-color: #373737;
        margin: auto;
      }

      .player-pad {
        padding: 15px;
      }

      .playlist-track-ctn {
        /*display: flex;*/
        display: none;
        background-color: #464646;
        margin-top: 3px;
        border-radius: 5px;
        cursor: pointer;
      }
      .playlist-track-ctn:last-child {
        /*border: 1px solid #ffc266; */
      }

      .playlist-track-ctn > div {
        margin: 10px;
      }
      .playlist-info-track {
        width: 80%;
      }
      .playlist-info-track,
      .playlist-duration {
        padding-top: 7px;
        padding-bottom: 7px;
        color: #e9cc95;
        font-size: 14px;
        pointer-events: none;
      }
      .playlist-ctn {
        /*padding-bottom: 20px;*/
      }
      .active-track {
        background: #4d4d4d;
        color: #ffc266 !important;
        font-weight: bold;
      }

      .active-track > .playlist-info-track,
      .active-track > .playlist-duration,
      .active-track > .playlist-btn-play {
        color: #ffc266 !important;
      }

      .playlist-btn-play {
        pointer-events: none;
        padding-top: 5px;
        padding-bottom: 5px;
      }
      .fas {
        color: #ffc266;
        font-size: 20px;
      }

      /* END AUDIO PLAYER */

      .mpCartItem {
        padding-top:2px;
        padding-bottom:2px;
        padding-left:10px;
        padding-right:10px;
        border: 1px solid #000;
        color:#000;
        border-radius:0px;
      }
      
      .mpCartChange {
          cursor:pointer;
      }
      
      .mpCartCount {
          margin-left:5px; margin-right:5px;
      }

      .mpOrderedListItem
      {
        padding-top:9px;
        padding-bottom:9px;
      }
      .mpUnorderedListItem
      {
        padding-top:9px;
        padding-bottom:9px;
      }

      </style>
      <style type="text/css">
        @-ms-viewport{width:device-width}
        }
      </style>
      <style type="text/css">{{--REPLACE-STYLE--}}</style>
      <style type="text/css">{{--REPLACE-WEB-STYLE--}}</style>

`;

const scriptHtml = `     
      <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>     

      <script>
        $(document).ready(function() {
          videoContextInterval = setInterval(function(){
            $(".video-js").on("contextmenu", function (e) {
              return false;
            });
          },200);
          setTimeout(function(){
            clearInterval(videoContextInterval);
            videoContextInterval = null;
          }, 10000);
        });
      </script>

    <script>
        function getTimeRemaining(endtime) {
            const total = Date.parse(endtime) - Date.parse(new Date());
            const seconds = Math.floor((total / 1000) % 60);
            const minutes = Math.floor((total / 1000 / 60) % 60);
            const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
            const days = Math.floor(total / (1000 * 60 * 60 * 24));

            return {
                total,
                days,
                hours,
                minutes,
                seconds
            };
        }

        function ctSetCookie(name,value,days) {
          var expires = "";
          if (days) {
              var date = new Date();
              date.setTime(date.getTime() + (days*24*60*60*1000));
              expires = "; expires=" + date.toUTCString();
          }
          document.cookie = name + "=" + (value || "")  + expires + "; path=/";
        }

        function ctGetCookie(name) {
            var nameEQ = name + "=";
            var ca = document.cookie.split(';');
            for(var i=0;i < ca.length;i++) {
                var c = ca[i];
                while (c.charAt(0)==' ') c = c.substring(1,c.length);
                if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
            }
            return null;
        }

        function initializeClock(id, endtime) {
            const clock = document.getElementById(id);
            const daysSpan = clock.querySelector('.countdownDays');
            const hoursSpan = clock.querySelector('.countdownHours');
            const minutesSpan = clock.querySelector('.countdownMinutes');
            const secondsSpan = clock.querySelector('.countdownSeconds');

            function updateClock() {
                const t = getTimeRemaining(endtime);
                ctSetCookie(id, endtime, 2);
                if ( daysSpan !== null )
                {
                    daysSpan.innerHTML = t.days;
                    if(t.days < 0)
                    {
                      daysSpan.innerHTML = 0;
                    }
                }
                if ( hoursSpan !== null )
                {
                    hoursSpan.innerHTML = ('0' + t.hours).slice(-2);
                    if(t.hours < 0)
                    {
                      hoursSpan.innerHTML = 0;
                    }
                }
                if ( minutesSpan !== null )
                {
                    minutesSpan.innerHTML = ('0' + t.minutes).slice(-2);
                    if(t.minutes < 0)
                    {
                      minutesSpan.innerHTML = 0;
                    }
                }
                if ( secondsSpan !== null )
                {
                    secondsSpan.innerHTML = ('0' + t.seconds).slice(-2);
                    if(t.seconds < 0)
                    {
                      secondsSpan.innerHTML = 0;
                    }
                }

                if (t.total <= 0) {
                    clearInterval(timeinterval);
                }
            }

            updateClock();
            const timeinterval = setInterval(updateClock, 1000);
        }

    </script>
`;

const metaHtml = `
    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0" />
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
    <!--[if !mso]><!-->
      <meta http-equiv="X-UA-Compatible" content="IE=edge" /> 
    <!--<![endif]-->  
`;

const titleHtml = `
    <title>{{--REPLACE-TITLE--}}</title>
`;

/*eslint-disable*/
export const defaultHtml = `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional //EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
  <html>
    <head>
      ${titleHtml}
      ${metaHtml}
      ${styleHtml}
      ${scriptHtml}
    </head>
    {{--REPLACE-BODY--}}
    {{--REPLACE-MODAL--}}
  </html>`;

export const defaultArticleHtml = `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional //EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
  <html>
    <head>
      ${titleHtml}
      ${metaHtml}
      ${styleHtml}
      ${scriptHtml}
    </head>
    {{--REPLACE-BODY--}}
    {{--REPLACE-MODAL--}}
  </html>`;

export const defaultCustomSectionHtml = `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional //EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
  <html>    
    {{--REPLACE-BODY--}}
    {{--REPLACE-MODAL--}}
  </html>`;

export const defaultCoreHtml = `{{--REPLACE-BODY--}}`;

/*eslint-disable*/
export const defaultPopupHtml = `
    <style type="text/css">{{--REPLACE-STYLE--}}</style>
    <style type="text/css">{{--REPLACE-WEB-STYLE--}}</style>
    <style type="text/css">{{--REPLACE-CUSTOM-STYLE--}}</style>    
    {{--REPLACE-BODY--}}
    {{--REPLACE-MODAL--}}
`;
