import * as api from "./api";
import { callApi } from "../helpers";
import { getItem } from "../helpers/storage";

function callAuthenticate(email, password) {
  /*eslint-disable*/
  const loginData = {
    email_address: email.trim().toLowerCase(),
    password: password,
  };
  /*eslint-enable*/
  return callApi(api.service("users/login").create(loginData));
}

function callVerifyAuthentication(token) {
  return callApi(api.service("users/login/verify").create({ token: token }));
}

function callCreateUser(data) {
  return callApi(api.service("users").create(data));
}

function callUpdateUser(id, data, isLoading = true, auth = true) {
  return callApi(api.service("users", auth).patch(id, data), isLoading);
}

function callGetUsers(isLoading = true) {
  return callApi(
    api
      .service("users")
      .find({ query: { status: { $ne: "archive" }, populate: "no" } }),
    false
  );
}

function callGetUsersUsingQuery(query, isLoading = true) {
  return callApi(api.service("users").find(query), isLoading);
}

function callGetUsersByQuery(query, auth = true) {
  return callApi(api.service("users", auth).find(query), false);
}

function callGetPageUsersByQuery(query, auth = true, loading = true) {
  return callApi(api.service("users/page", auth).find(query), loading);
}

function callGetUserById(id, isLoading = true, auth = true) {
  return callApi(api.service("users", auth).get(id), isLoading);
}

function callGetUserById1(id, isLoading = true, auth = true) {
  return callApi(
    api.service("users/no", auth).find({ query: { _id: id } }),
    isLoading
  );
}

function callDeleteUserById(id) {
  return callApi(api.service("users").remove(id));
}

function callCreateCustomerOrder(data) {
  return callApi(api.service("customer-order").create(data));
}

function callUpdateCustomerOrder(id, data) {
  return callApi(api.service("customer-order").patch(id, data));
}

function callGetCustomerOrderUsingId(id) {
  return callApi(api.service("customer-order").get(id));
}

function callGetCustomerOrderUsingQuery(query, isLoading = true) {
  return callApi(api.service("customer-order").find(query), isLoading);
}

function callAddMemberTag(data, auth = true) {
  return callApi(api.service("general", auth).create(data));
}

function callAddMemberTagL(data, auth = true) {
  return callApi(api.service("general", auth).create(data), false);
}

function callInactiveMemberTag(data) {
  return callApi(api.service("general").create(data));
}

function callGetAtqUserIds(userId) {
  return callApi(
    api.service("general").get("get-atq-users", { query: { userId: userId } })
  );
}

function callCheckTier(type) {
  return callApi(
    api.service("general").get("check-tier", { query: { type: type } })
  );
}

function callGetResetPasswordData(query, auth = true) {
  return callApi(api.service("reset-password", auth).find(query));
}

function callUpdateResetPasswordData(id, data, auth = true) {
  return callApi(api.service("reset-password", auth).patch(id, data));
}

function callCreateImportUser(data) {
  return callApi(api.service("import-users").create(data));
}

function callGetImportUsersUsingQuery(query) {
  return callApi(api.service("import-users").find(query));
}

function callUpdateImportUser(id, data) {
  return callApi(api.service("import-users").patch(id, data));
}

function callDeleteImportUser(id) {
  return callApi(api.service("import-users").remove(id));
}

function callDeleteAllImportUser(ids) {
  return callApi(api.service("import-users").removeAll(ids));
}

function callGetCfSubmissionsByQuery(query, auth = true) {
  return callApi(api.service("cf-submission", auth).find(query), false);
}

function callGetPageCfSubmissiobsByQuery(query, auth = true) {
  return callApi(api.service("cf-submission/page", auth).find(query), false);
}

function callDeleteCfSubmission(id) {
  return callApi(api.service("cf-submission").remove(id));
}

function callCreateUserNote(data) {
  return callApi(api.service("user-notes").create(data));
}

function callUpdateUserNote(id, data) {
  return callApi(api.service("user-notes").patch(id, data));
}

function callGetUserNotesUsingQuery(query) {
  return callApi(api.service("user-notes").find(query));
}

async function callUpdateUserForPushId(data, option) {
  let pushData = {};
  let user = {};
  let userData = getItem("userData");

  try {
    if (option === 1) {
      pushData = JSON.parse(data);
    } else {
      pushData = data;
    }
    user = JSON.parse(userData);
  } catch (error) {}

  if (user._id && pushData.push_id) {
    let userInfo = await callGetUserById(user._id);
    if (userInfo._id) {
      let firebasePushIds = userInfo.firebase_push_id_list || [];
      let existIds = firebasePushIds.filter((d) => {
        return d.push_id === pushData.push_id;
      });
      if (existIds.length === 0) {
        firebasePushIds.push(pushData);
      }
      /*eslint-disable*/
      let updatedUser = await callUpdateUser(userInfo._id, {
        firebase_push_id_list: firebasePushIds,
      });
      /*eslint-enable*/
    }
  }
}

export {
  callAuthenticate,
  callVerifyAuthentication,
  callCreateUser,
  callUpdateUser,
  callGetUsers,
  callGetUsersByQuery,
  callGetUserById,
  callCreateCustomerOrder,
  callAddMemberTag,
  callGetResetPasswordData,
  callUpdateResetPasswordData,
  callUpdateCustomerOrder,
  callInactiveMemberTag,
  callGetCustomerOrderUsingQuery,
  callDeleteUserById,
  callGetCustomerOrderUsingId,
  callCreateImportUser,
  callGetImportUsersUsingQuery,
  callUpdateImportUser,
  callDeleteImportUser,
  callDeleteAllImportUser,
  callGetUsersUsingQuery,
  callUpdateUserForPushId,
  callGetAtqUserIds,
  callGetPageUsersByQuery,
  callAddMemberTagL,
  callGetCfSubmissionsByQuery,
  callGetPageCfSubmissiobsByQuery,
  callDeleteCfSubmission,
  callGetUserById1,
  callCreateUserNote,
  callGetUserNotesUsingQuery,
  callUpdateUserNote,
  callCheckTier,
};
