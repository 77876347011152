import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import $ from "jquery";

import TitleBox from "../../../components/general/titleBox";
import FilterList from "../../../components/form/filterList";

import { filterByTags } from "../../../helpers/filters";
import {
  callDeletePdfTemplateById,
  callGetPdfTemplates,
} from "../../../services";

class ViewPdfTemplates extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      items: [],
      itemsBackup: [],
      filterList: [],
      selectedItem: {},
    };
  }

  componentDidMount() {
    this.getPdfTemplates();
  }

  getPdfTemplates() {
    callGetPdfTemplates().then((d) => {
      this.setState({ items: d.data, itemsBackup: d.data });
    });
  }

  onClickEditPdfTemplate = (item) => {
    this.props.history.push(`/admin/add-pdf-template?id=${item._id}`);
  };

  onClickDeletePdfTemplate = (item) => {
    this.setState({ selectedItem: item }, () => {
      $(".modalAlertOverlay,.modalAlert").fadeIn(200);
    });
  };

  filterUpdated(updatedFilter, name, type, searchFields) {
    let filterList = this.state.filterList;
    filterList[name] = {
      name: name,
      type: type,
      searchFields: searchFields,
      values: updatedFilter,
    };
    this.setState(
      {
        filterList: filterList,
      },
      () => {
        let data = filterByTags(this.state.itemsBackup, filterList);
        this.setState({
          items: data,
        });
      }
    );
  }

  renderPdfTemplates() {
    var returnVals = [];
    let items = this.state.items;
    items.forEach((element) => {
      returnVals.push(
        <tr key={element._id}>
          <td>{element.name}</td>
          <td className="firstLetter">{element.type}</td>
          <td>{element.pages.length}</td>
          <td>{element.customer ? "Yes" : "No"}</td>
          <td>{element.staff ? "Yes" : "No"}</td>
          <td className="min-width-100">
            {/*eslint-disable*/}
            <a
              onClick={this.onClickDeletePdfTemplate.bind(this, element)}
              className="btn btn-danger btn-sm margin-right-10 border-0">
              <i className="fa fa-trash" />
            </a>
            <a
              onClick={this.onClickEditPdfTemplate.bind(this, element)}
              className="btn btn-default btn-sm border-0">
              <i className="fa fa-pencil" />
            </a>
            {/*eslint-enable*/}
          </td>
        </tr>
      );
    });
    return returnVals;
  }

  onClickNoAlert = () => {
    $(".modalAlertOverlay,.modalAlert").fadeOut(200);
  };

  onClickYesAlert = () => {
    callDeletePdfTemplateById(this.state.selectedItem._id).then((d) => {
      $(".modalAlertOverlay,.modalAlert").fadeOut(200);
      this.getPdfTemplates();
    });
  };

  renderConfirmationAlert() {
    let selectedItem = this.state.selectedItem;
    let name = "";
    if (selectedItem.name) {
      name = selectedItem.name;
    }
    return (
      <div>
        <div className="modalAlertOverlay" />
        <div className="modalAlert">
          <p>{`Are you sure you want to delete "${name}" pdf template?`}</p>
          <div className="alertFooterBtns">
            {/*eslint-disable*/}
            <a
              className="btn btn-default closeModal-No margin-right-10"
              onClick={this.onClickNoAlert}>
              No
            </a>
            <a
              className="btn btn-primary closeModal-Yes"
              onClick={this.onClickYesAlert}>
              Yes
            </a>
            {/*eslint-enable*/}
          </div>
        </div>
      </div>
    );
  }

  renderFilterView() {
    return (
      <div className="list-group-item">
        <FilterList
          label={"Search PDF Templates..."}
          name={"search"}
          type={"search"}
          searchFields={["name", "type"]}
          onFinish={this.filterUpdated.bind(this)}
        />
      </div>
    );
  }

  render() {
    return (
      <div className="container-fluid">
        {this.renderConfirmationAlert()}
        <TitleBox title="PDF Templates Overview" />
        <div className="gap20" />
        <div className="card">
          <div className="list-group list-group-flush">
            {this.renderFilterView()}
            <div className="list-group-item">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Pages</th>
                    <th>Customer</th>
                    <th>Staff</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>{this.renderPdfTemplates()}</tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ViewPdfTemplates);
