import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import $ from "jquery";

import TitleBox from "../../../components/general/titleBox";
import { getUpsellItems } from "../../../modules/actions/index";
import FilterList from "../../../components/form/filterList";
import { filterByTags } from "../../../helpers/filters";
import { showNotification } from "../../../helpers";
import { callCreateUpsellItem } from "../../../services";
import { getUpdatedEditorContent } from "../../../components/editor/processEditor";

class ViewUpsellItem extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      items: [],
      itemsBackup: [],
      filterList: [],
      types: [
        { name: "All", value: "all", selected: true },
        { name: "Single", value: "single", selected: false },
        { name: "Subscription", value: "subscription", selected: false },
        { name: "None", value: "none", selected: false },
      ],
      itemName: "",
      selectedItem: {},
    };
  }

  componentDidMount() {
    this.props.getUpsellItems();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.upsellItems.data) {
      this.setState({
        items: newProps.upsellItems.data,
        itemsBackup: newProps.upsellItems.data,
      });
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  onClickEditUpsellItem = (upsellItem) => {
    this.props.history.push("/admin/add-upsell-item", {
      upsellItem: upsellItem,
    });
  };

  onClickCloneUpsellItem = (upsellItem) => {
    this.setState({ selectedItem: upsellItem }, () => {
      $(".modalAlertOverlay.itemModalOverlay,.modalAlert.itemAlert").fadeIn(
        200
      );
    });
  };

  filterUpdated(updatedFilter, name, type, searchFields) {
    let filterList = this.state.filterList;
    filterList[name] = {
      name: name,
      type: type,
      searchFields: searchFields,
      values: updatedFilter,
    };
    this.setState(
      {
        filterList: filterList,
      },
      () => {
        let data = filterByTags(this.state.itemsBackup, filterList);
        this.setState({
          items: data,
        });
      }
    );
  }

  renderItems() {
    var returnVals = [];
    let items = this.state.items;
    items.forEach((element) => {
      returnVals.push(
        <tr key={element._id}>
          <td>{element.name}</td>
          <td>{element.description}</td>
          <td>{element.order_type}</td>
          <td className="min-width-100">
            <button
              onClick={this.onClickEditUpsellItem.bind(this, element)}
              className="btn btn-default btn-sm margin-right-10 border-0">
              <i className="fa fa-pencil" />
            </button>
            <button
              onClick={this.onClickCloneUpsellItem.bind(this, element)}
              className="btn btn-default btn-sm border-0">
              <i className="fa fa-clone" />
            </button>
          </td>
        </tr>
      );
    });
    return returnVals;
  }

  onClickCancelFromItemModal() {
    $(".modalAlertOverlay.itemModalOverlay,.modalAlert.itemAlert").fadeOut(200);
  }

  onClickProcessFromItemModal() {
    let itemName = this.state.itemName.trim();

    if (itemName.length === 0) {
      showNotification("error", "Please enter item name", 4000);
      return;
    }

    let existingItem = this.state.itemsBackup.filter((d) => {
      return d.name === itemName;
    });

    if (existingItem.length > 0) {
      showNotification("error", "Item already exists", 4000);
      return;
    }

    $(".modalAlertOverlay.itemModalOverlay,.modalAlert.itemAlert").fadeOut(200);

    let selectedItem = this.state.selectedItem;

    /*eslint-disable*/
    let apiData = {
      name: this.state.itemName,
      description: selectedItem.description,
      url_display_name: "",
      order_type: selectedItem.order_type,
      amount_list: selectedItem.amount_list,
      plan_list: selectedItem.plan_list,
      payments: "",
      product_list: selectedItem.product_list,
      tag_list: selectedItem.tag_list,
      editor_content: getUpdatedEditorContent(
        selectedItem.editor_content,
        selectedItem.main_content
      ),
      main_content: selectedItem.main_content,
    };
    /*eslint-enable*/

    callCreateUpsellItem(apiData).then((d) => {
      if (d._id) {
        this.setState({ selectedItem: {}, itemName: "" }, () => {
          showNotification("success", "Upsell Item created successfully", 4000);
          this.props.getUpsellItems();
        });
      } else {
        showNotification("error", "Something went wrong", 4000);
      }
    });
  }

  renderNewItemModal() {
    let name = "";
    if (this.state.selectedItem.name) {
      name = this.state.selectedItem.name;
    }
    return (
      <div>
        <div className="modalAlertOverlay itemModalOverlay" />
        <div className="modalAlert itemAlert">
          <div className="clearfix">
            <div className="pull-left">
              <p>Clone Upsell Item - {name}</p>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div
                  className={
                    this.state.itemName !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Item Name</label>
                  <input
                    type="text"
                    name="itemName"
                    value={this.state.itemName}
                    onChange={(event) => this.handleInputChange(event)}
                  />
                </div>
                <div className="gap10" />
              </div>
            </div>
          </div>
          <div className="gap10" />
          <div className="alertFooterBtns">
            <button
              onClick={this.onClickCancelFromItemModal.bind(this)}
              className="btn btn-default dismissThisModal margin-right-10">
              Cancel
            </button>
            <button
              onClick={this.onClickProcessFromItemModal.bind(this)}
              className="btn btn-primary dismissThisModal">
              Create Upsell Item
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderFilterView() {
    return (
      <>
        <div className="list-group-item">
          <FilterList
            label={"Search Upsell Items"}
            name={"search"}
            type={"search"}
            searchFields={["name", "description"]}
            onFinish={this.filterUpdated.bind(this)}
          />
        </div>
        <div className="list-group-item">
          <p>Select a type</p>
          <FilterList
            filterList={this.state.types}
            name={"searchType"}
            type={"single"}
            searchFields={["order_type"]}
            onFinish={this.filterUpdated.bind(this)}
          />
        </div>
      </>
    );
  }

  render() {
    return (
      <div>
        {this.renderNewItemModal()}
        <div className="container-fluid">
          <TitleBox title="Upsell Item Overview" />
          <div className="gap20" />
          <div className="card">
            <div className="list-group list-group-flush">
              {this.renderFilterView()}
              <div className="list-group-item">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Description</th>
                      <th>Type</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>{this.renderItems()}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  upsellItems: state.billingReducer.upsellItems,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUpsellItems,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ViewUpsellItem);
