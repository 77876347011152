import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import DatePicker from "react-date-picker";

import TitleBox from "../../../components/general/titleBox";
import TypeableSelect from "../../../components/form/typeableSelect";
import RadioTag from "../../../components/form/radioTag";
import { showNotification } from "../../../helpers";
import {
  callCreateSpotlight,
  callGetUsersByQuery,
  callUpdateSpotlight,
  callUpdateUser,
} from "../../../services";
import SelectAddImage from "../../../components/form/selectAddImage";
import HelpTextDiv from "../../../components/form/helpTextDiv";
import StatusSwitch from "../../../components/form/statusSwitch";

class AddSpotlight extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    let date = new Date();
    date.setDate(date.getDate() + 7);

    let spotlightInfo = {
      spotlightId: 0,
      isActiveLabel: "Currently Active",
      isActive: true,
      status: "pending",
      releaseDate: date,
      memberId: "",
      favHobbies: "",
      successThing: "",
      favQuote: "",
      favFood: "",
      funFacts: "",
      occupation: "",
      favPartFast: "",
      favLineFast: "",
      selectedUser: { value: "", label: "" },
      imgUrl: "",
    };

    if (this.props.history.location.state !== undefined) {
      let spotlight = this.props.history.location.state.spotlight;

      spotlightInfo.spotlightId = spotlight._id;
      spotlightInfo.status = spotlight.status || "pending";
      spotlightInfo.isActive = spotlight.active;
      spotlightInfo.isActiveLabel = spotlightInfo.isActive
        ? "Currently Active"
        : "Currently Inactive";
      spotlightInfo.releaseDate = new Date(spotlight.release_date);
      spotlightInfo.memberId = spotlight.member_info._id;
      spotlightInfo.favHobbies = spotlight.fav_hobbies;
      spotlightInfo.successThing = spotlight.success_thing || "";
      spotlightInfo.favQuote = spotlight.fav_quote;
      spotlightInfo.favFood = spotlight.fav_food;
      spotlightInfo.funFacts = spotlight.fun_facts;
      spotlightInfo.occupation = spotlight.occupation;
      spotlightInfo.favPartFast = spotlight.fav_part_fast;
      spotlightInfo.favLineFast = spotlight.fav_line_fast;
      spotlightInfo.imgUrl = spotlight.image_url;
      spotlightInfo.selectedUser = {
        value: spotlight.member_info,
        label: spotlight.member_info.name,
      };
    }

    this.state = {
      spotlightId: spotlightInfo.spotlightId,
      status: spotlightInfo.status,
      isActiveLabel: spotlightInfo.isActiveLabel,
      isActive: spotlightInfo.isActive,
      releaseDate: spotlightInfo.releaseDate,
      memberId: spotlightInfo.memberId,
      favHobbies: spotlightInfo.favHobbies,
      successThing: spotlightInfo.successThing,
      favQuote: spotlightInfo.favQuote,
      favFood: spotlightInfo.favFood,
      funFacts: spotlightInfo.funFacts,
      occupation: spotlightInfo.occupation,
      favPartFast: spotlightInfo.favPartFast,
      favLineFast: spotlightInfo.favLineFast,
      keyUser: Math.random(),
      selectedUser: spotlightInfo.selectedUser,
      users: [],
      imgUrl: spotlightInfo.imgUrl,
      keySwitch: Math.random(),
    };
  }

  componentWillMount() {
    if (!this.state.isActive && this.state.spotlightId !== 0) {
      showNotification(
        "warning",
        "This spotlight is currently inactive.",
        2000
      );
    }
    this.getStaffUsers();
  }

  async getStaffUsers() {
    /*eslint-disable*/
    let query = {
      query: {
        member_type: "staff",
        status: { $ne: "archive" },
        $select: ["_id", "name", "email_address"],
      },
    };
    /*eslint-enable*/

    let d = await callGetUsersByQuery(query);
    let fUsers = d.data;
    let staffUsers = [];

    fUsers.forEach((element) => {
      staffUsers.push({ value: element, label: element.name });
    });

    this.setState({
      users: staffUsers,
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  toggleActiveCheckbox = (check) => {
    this.setState({
      isActive: check,
      isActiveLabel: check ? "Currently Active" : "Currently Inactive",
    });
  };

  onChangeReleaseDate(value) {
    this.setState({
      releaseDate: value,
    });
  }

  onSelectUser = (user) => {
    this.setState({ selectedUser: user });
  };

  onSelectStatus(item, id) {
    this.setState({ [id]: item[Object.keys(item)[0]].value });
  }

  onClickAddSpotlight() {
    if (this.state.selectedUser.label.trim().length === 0) {
      showNotification("error", "Please select a user", 4000);
      return;
    }

    if (this.state.favHobbies.trim().length === 0) {
      showNotification("error", "'Favorite Hobbies' field is required", 4000);
      return;
    }

    if (this.state.successThing.trim().length === 0) {
      showNotification(
        "error",
        "'What is something cool you've been successful at lately?' field is required",
        4000
      );
      return;
    }

    if (this.state.favQuote.trim().length === 0) {
      showNotification(
        "error",
        "'Favorite Quote and Why' field is required",
        4000
      );
      return;
    }

    if (this.state.favFood.trim().length === 0) {
      showNotification("error", "'Favorite Food' field is required", 4000);
      return;
    }

    if (this.state.funFacts.trim().length === 0) {
      showNotification("error", "'Fun Facts' field is required", 4000);
      return;
    }

    if (this.state.occupation.trim().length === 0) {
      showNotification("error", "'Occupation' field is required", 4000);
      return;
    }

    if (this.state.favPartFast.trim().length === 0) {
      showNotification(
        "error",
        "'Favorite thing about being a part of FAST' field is required",
        4000
      );
      return;
    }

    if (this.state.favLineFast.trim().length === 0) {
      showNotification(
        "error",
        "'Favorite line from our FAST mission statement and why' field is required",
        4000
      );
      return;
    }
    this.callCreateSpotlightAPI();
  }

  resetProps() {
    this.setState({
      isActiveLabel: "Currently Inactive",
      isActive: false,
      status: "pending",
      memberId: "",
      favHobbies: "",
      successThing: "",
      favQuote: "",
      favFood: "",
      funFacts: "",
      occupation: "",
      favPartFast: "",
      favLineFast: "",
      keyUser: Math.random(),
      selectedUser: { value: "", label: "" },
      imgUrl: "",
    });
  }

  callCreateSpotlightAPI() {
    let apiData = {
      /*eslint-disable */
      member_id: this.state.selectedUser.value._id,
      image_url: this.state.imgUrl,
      status: this.state.status,
      active: this.state.isActive,
      release_date: this.state.releaseDate,
      fav_hobbies: this.state.favHobbies,
      success_thing: this.state.successThing,
      fav_quote: this.state.favQuote,
      fav_food: this.state.favFood,
      fun_facts: this.state.funFacts,
      occupation: this.state.occupation,
      fav_part_fast: this.state.favPartFast,
      fav_line_fast: this.state.favLineFast,
      /*eslint-enable*/
    };

    if (this.state.spotlightId === 0) {
      callCreateSpotlight(apiData).then((d) => {
        if (d._id) {
          this.callUpdateUserAPI(1);
        } else {
          showNotification("error", "Something went wrong", 4000);
        }
      });
    } else {
      callUpdateSpotlight(this.state.spotlightId, apiData).then((d) => {
        if (d._id) {
          this.callUpdateUserAPI(2);
        } else {
          showNotification("error", "Something went wrong", 4000);
        }
      });
    }
  }

  callUpdateUserAPI(option) {
    /*eslint-disable*/
    let apiUserData = {
      spotlight_status: this.state.status,
    };
    /*eslint-enable*/
    callUpdateUser(this.state.selectedUser.value._id, apiUserData).then((d) => {
      if (d._id) {
        if (option === 1) {
          this.resetProps();
          showNotification("success", "Spotlight added successfully", 4000);
        } else {
          showNotification("success", "Spotlight updated successfully", 4000);
        }
      }
    });
  }

  onChangeImage = (image) => {
    this.setState({ imgUrl: image });
  };

  render() {
    return (
      <div>
        <div className="container-fluid">
          <TitleBox
            title={
              this.state.spotlightId === 0
                ? "Create New Spotlight"
                : "Edit Spotlight"
            }
            showBackBtn={this.state.spotlightId !== 0}
          />
          <div className="gap20" />
          <div className="card">
            <div className="list-group list-group-flush">
              <div className="list-group-item">
                <div className="gap20" />

                <div className="havingHelpText">
                  <StatusSwitch
                    key={this.state.keySwitch}
                    isActive={this.state.isActive}
                    onChange={this.toggleActiveCheckbox.bind(this)}
                  />
                </div>
                <HelpTextDiv
                  id="idSpotLightActiveStatus"
                  label="Learn more about the Active Status"
                  type="addSpotlight"
                  isInline="true"
                />
                <div className="gap20" />
                <div className="">
                  <div className="row">
                    <div className="col-sm-6">
                      <HelpTextDiv
                        id="idSpotLightUser"
                        label="Learn more about the User"
                        type="addSpotlight"
                        isInline="false"
                      />

                      <TypeableSelect
                        key={this.state.keyUser}
                        name={"selectedUser"}
                        placeholder={"Select A User"}
                        selected={this.state.selectedUser}
                        onSelect={this.onSelectUser}
                        options={this.state.users}
                      />
                    </div>

                    <div className="col-sm-6">
                      <HelpTextDiv
                        id="idSpotLightReleaseDate"
                        label="Learn more about the Release Date"
                        type="addSpotlight"
                        isInline="false"
                      />

                      <label className="inputFakeLabelFocussed">
                        Release Date
                      </label>
                      <div>
                        <DatePicker
                          value={this.state.releaseDate}
                          onChange={this.onChangeReleaseDate.bind(this)}
                        />
                      </div>
                      <div className="gap20" />
                    </div>
                  </div>
                  <div>
                    <div className="havingHelpText">
                      <p>Status</p>
                    </div>
                    <HelpTextDiv
                      id="idSpotLightStatus"
                      label="Learn more about the Status"
                      type="addSpotlight"
                      isInline="true"
                    />
                    <div className="tabsHolder radioTabs">
                      <RadioTag
                        onCheckChange={this.onSelectStatus.bind(this)}
                        labelList={[
                          { name: "Pending", value: "pending" },
                          { name: "Approved", value: "approved" },
                          { name: "Rejected", value: "rejected" },
                        ]}
                        id={"status"}
                        selectedList={[this.state.status]}
                        selectType={"value"}
                      />
                    </div>
                    <div className="gap20" />
                  </div>

                  <HelpTextDiv
                    id="idSpotLightFavoriteHobbies"
                    label="Learn more about the Favorite Hobbies"
                    type="addSpotlight"
                    isInline="false"
                  />

                  <div
                    className={
                      this.state.favHobbies !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>Favorite Hobbies</label>
                    <textarea
                      name="favHobbies"
                      value={this.state.favHobbies}
                      onChange={(event) => this.handleInputChange(event)}
                    />
                  </div>
                  <div className="gap20" />

                  <HelpTextDiv
                    id="idSpotLightSuccessful"
                    label="Learn more about the you have been successful at lately"
                    type="addSpotlight"
                    isInline="false"
                  />

                  <div
                    className={
                      this.state.successThing !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>
                      What is something cool you've been successful at lately?
                    </label>
                    <textarea
                      name="successThing"
                      value={this.state.successThing}
                      onChange={(event) => this.handleInputChange(event)}
                    />
                  </div>
                  <div className="gap20" />

                  <HelpTextDiv
                    id="idSpotLightFavQuote"
                    label="Learn more about the Favourite Quote and the Reason behind it"
                    type="addSpotlight"
                    isInline="false"
                  />

                  <div
                    className={
                      this.state.favQuote !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>Favorite Quote and Why</label>
                    <textarea
                      name="favQuote"
                      value={this.state.favQuote}
                      onChange={(event) => this.handleInputChange(event)}
                    />
                  </div>
                  <div className="gap20" />

                  <HelpTextDiv
                    id="idSpotLightFavoriteFood"
                    label="Learn more about the Favorite Food"
                    type="addSpotlight"
                    isInline="false"
                  />

                  <div
                    className={
                      this.state.favFood !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>Favorite Food</label>
                    <textarea
                      name="favFood"
                      value={this.state.favFood}
                      onChange={(event) => this.handleInputChange(event)}
                    />
                  </div>
                  <div className="gap20" />

                  <HelpTextDiv
                    id="idSpotLightFunFacts"
                    label="Learn more about the Fun Facts"
                    type="addSpotlight"
                    isInline="false"
                  />

                  <div
                    className={
                      this.state.funFacts !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>Fun Facts</label>
                    <textarea
                      name="funFacts"
                      value={this.state.funFacts}
                      onChange={(event) => this.handleInputChange(event)}
                    />
                  </div>
                  <div className="gap20" />

                  <HelpTextDiv
                    id="idSpotLightOccupation"
                    label="Learn more about the Occupation"
                    type="addSpotlight"
                    isInline="false"
                  />

                  <div
                    className={
                      this.state.occupation !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>Occupation</label>
                    <textarea
                      name="occupation"
                      value={this.state.occupation}
                      onChange={(event) => this.handleInputChange(event)}
                    />
                  </div>
                  <div className="gap20" />

                  <HelpTextDiv
                    id="idSpotLightPartOfFast"
                    label="Learn more about being a part of FAST"
                    type="addSpotlight"
                    isInline="false"
                  />

                  <div
                    className={
                      this.state.favPartFast !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>Favorite thing about being a part of FAST</label>
                    <textarea
                      name="favPartFast"
                      value={this.state.favPartFast}
                      onChange={(event) => this.handleInputChange(event)}
                    />
                  </div>
                  <div className="gap20" />

                  <HelpTextDiv
                    id="idSpotLightFastMission"
                    label="Learn more about the Fast Mission"
                    type="addSpotlight"
                    isInline="false"
                  />

                  <div
                    className={
                      this.state.favLineFast !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>
                      Favorite line from our FAST mission statement and why
                    </label>
                    <textarea
                      name="favLineFast"
                      value={this.state.favLineFast}
                      onChange={(event) => this.handleInputChange(event)}
                    />
                  </div>
                  <div className="gap30" />
                  <SelectAddImage
                    label="Spotlight Image"
                    onChangeImage={this.onChangeImage}
                    image={this.state.imgUrl}
                  />
                  <div className="gap20" />
                </div>
              </div>
            </div>
          </div>
          <div className="text-right">
            {/*eslint-disable-next-line*/}
            <a
              className="btn btn-primary"
              onClick={this.onClickAddSpotlight.bind(this)}>
              Save
            </a>
          </div>
          <div className="gap20" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ users: state.authReducer.users });

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddSpotlight);
