import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import $ from "jquery";

import TitleBox from "../../../components/general/titleBox";
import {
  callGetTasksByQuery,
  callPayTaskInvoices,
  callUpdateInvoice,
  callUpdateTask,
} from "../../../services";
import moment from "moment-timezone";
import { showNotification } from "../../../helpers";
import { getItem } from "../../../helpers/storage";

class PayTaskInvoicesPage extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    let ids = [];
    let action = "";

    if (this.props.history.location.state !== undefined) {
      ids = this.props.history.location.state.ids || [];
      action = this.props.history.location.state.action || "";
    }
    this.state = {
      ids,
      action,
      tasks: [],
      amount: "",
      invoiceId: "",
      bAmount: "",
      taskId: "",
      quickBookUsers: [],
      quickBookIds: {},
    };
  }

  componentDidMount() {
    if (this.state.ids.length > 0) {
      this.getTasksInfo();
    }
  }

  async getTasksInfo() {
    let query = {
      query: { _id: { $in: this.state.ids } },
    };
    let tasks = await callGetTasksByQuery(query);
    this.setState({ tasks: tasks.data });

    let apiSendData = {
      action: "get-quickbook-customers",
      data: {},
    };
    let response = await callPayTaskInvoices(apiSendData);
    if (response) {
      let quickBookIds = {};
      tasks.data.forEach((element) => {
        quickBookIds[element._id] = "";
      });
      this.setState({ quickBookIds, quickBookUsers: response });
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  renderInvoices() {
    var returnVals = [];
    let items = this.state.tasks;
    let action = this.state.action;

    for (let i = 0; i < items.length; i++) {
      const element = items[i];
      let amount = "";
      let dueDate = "";
      let invoice = {};
      if (action === "pay-invoice") {
        invoice = element.incoming_invoice || {};
      }
      if (action === "create-invoice") {
        invoice = element.outgoing_invoice || {};
      }

      amount = invoice.amount || "";
      dueDate = invoice.due_date || "";

      returnVals.push(
        <tr key={element._id}>
          <td>{element.member_info ? element.member_info.name : "-"}</td>
          <td
            className="link pointer-click"
            onClick={() => {
              let url = `/admin/task-detail?tid=${element._id}`;
              window.open(url, "_blank");
            }}>
            {element.task}
          </td>
          <td
            className={amount ? "link pointer-click" : "-"}
            onClick={() => {
              if (invoice._id) {
                this.setState(
                  {
                    amount: amount || "",
                    bAmount: amount || "",
                    invoiceId: invoice._id,
                    taskId: element._id,
                  },
                  () => {
                    $(
                      ".modalAlertOverlay.amountModalOverlay,.modalAlert.amountAlert"
                    ).fadeIn(200);
                  }
                );
              }
            }}>
            {amount ? `$${amount}` : "-"}
          </td>
          <td>{dueDate ? moment(dueDate).format("MMMM Do, YYYY") : "-"}</td>
          <td>{invoice.paid ? "Paid" : "Unpaid"}</td>
          <td
            className="link pointer-click"
            onClick={() => {
              if (invoice._id) {
                let url = `/admin/add-invoice?id=${invoice._id}`;
                window.open(url, "_blank");
              }
            }}>
            {invoice.unique_id || "-"}
          </td>
          <td>
            <select
              name={element._id}
              value={this.state.quickBookIds[element._id]}
              onChange={(event) => {
                let ids = this.state.quickBookIds;
                ids[element._id] = event.target.value;
                this.setState({ quickBookIds: ids });
              }}>
              <option value="">Select User</option>
              {this.state.quickBookUsers.map((i) => (
                <option value={i.id} key={i.id}>
                  {i.name}
                </option>
              ))}
            </select>
          </td>
        </tr>
      );
    }
    return returnVals;
  }

  async onPressPay() {
    let items = this.state.tasks;
    let action = this.state.action;
    let apiData = [];
    for (let i = 0; i < items.length; i++) {
      const element = items[i];
      let invoice = {};
      if (action === "pay-invoice") {
        invoice = element.incoming_invoice || {};
      }
      if (action === "create-invoice") {
        invoice = element.outgoing_invoice || {};
      }
      if (invoice.paid) {
        continue;
      }
      if (invoice._id) {
        apiData.push({
          taskId: element._id,
          invoiceId: invoice._id,
          type: invoice.type,
          payBy: getItem("userId"),
          quickBookUserId: this.state.quickBookIds[element._id] || "",
        });
      }
    }
    if (apiData.length > 0) {
      let apiSendData = {
        action: "pay-task-invoice",
        type: this.state.action === "pay-invoice" ? "pay" : "generate",
        data: apiData,
      };
      await callPayTaskInvoices(apiSendData);
      showNotification(
        "success",
        this.state.action === "pay-invoice"
          ? "Pay process done!"
          : "Generate process done!",
        4000
      );
      this.getTasksInfo();
    } else {
      showNotification(
        "error",
        this.state.action === "pay-invoice"
          ? "Nothing to pay!"
          : "Nothing to generate!",
        4000
      );
    }
  }

  onClickCancelFromAmountModal() {
    $(".modalAlertOverlay.amountModalOverlay,.modalAlert.amountAlert").fadeOut(
      200
    );
  }

  async onClickSubmitFromAmountModel() {
    $(".modalAlertOverlay.amountModalOverlay,.modalAlert.amountAlert").fadeOut(
      200
    );
    await callUpdateInvoice(this.state.invoiceId, {
      amount: this.state.amount,
    });
    let historyObject = {
      date: new Date(),
      action: "amount change",
      userId: getItem("userId"),
      notes: `Updated bill amount from $${this.state.bAmount} to $${this.state.amount}`,
    };
    await callUpdateTask(this.state.taskId, {
      $push: { history: historyObject },
    });
    this.getTasksInfo();
  }

  renderAmountModal() {
    return (
      <div>
        <div className="modalAlertOverlay amountModalOverlay" />
        <div className="modalAlert amountAlert">
          <div className="clearfix">
            <div className="pull-left">
              <p style={{ fontSize: 18 }}>Invoice Amount</p>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                <div
                  className={
                    this.state.amount !== "" ? "mdInput mdFocussed" : "mdInput"
                  }>
                  <label>Amount</label>
                  <input
                    type="number"
                    name="amount"
                    value={this.state.amount}
                    onChange={(event) => this.handleInputChange(event)}
                  />
                </div>
                <div className="gap20" />
              </div>
            </div>
            <div className="gap10" />
          </div>
          <div className="gap20" />
          <div className="alertFooterBtns">
            <button
              onClick={this.onClickCancelFromAmountModal.bind(this)}
              className="btn btn-default dismissThisModal margin-right-10">
              Cancel
            </button>
            <button
              onClick={this.onClickSubmitFromAmountModel.bind(this)}
              className="btn btn-primary dismissThisModal">
              Submit
            </button>
          </div>
        </div>
      </div>
    );
  }

  render() {
    let action = this.state.action;
    return (
      <div className="container-fluid">
        {this.renderAmountModal()}
        <TitleBox
          title={action === "pay-invoice" ? "Pay Bills" : "Generate Invoices"}
          showBackBtn={true}
        />
        <div className="gap20" />
        <div className="card">
          <div className="list-group list-group-flush">
            <div className="list-group-item">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>User Name</th>
                    <th>Task Name</th>
                    <th>Amount</th>
                    <th>Due Date</th>
                    <th>Status</th>
                    <th>Invoice ID</th>
                    <th>Quickbook User</th>
                  </tr>
                </thead>
                <tbody>{this.renderInvoices()}</tbody>
              </table>

              <div className="text-right">
                <button
                  className="btn btn-primary"
                  onClick={() => this.onPressPay()}>
                  {action === "pay-invoice" ? "Pay Bills" : "Generate Invoices"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PayTaskInvoicesPage);
