import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import DatePicker from "react-date-picker";
import moment from "moment";
import $ from "jquery";
import { Scrollbars } from "react-custom-scrollbars";

import TitleBox from "../../../components/general/titleBox";
import TypeableSelect from "../../../components/form/typeableSelect";
import FullCalendar from "../../../components/general/fullCalendar";
import {
  callGetCustomProgramTemplates,
  callUpdateCustomProgramTemplate
} from "../../../services";
import { showNotification } from "../../../helpers";

class ViewTemplates extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      keyTemplate: Math.random(),
      selectedTemplate: { value: "", label: "" },
      templates: [],
      startDate: new Date(),
      events: [],
      calEvents: [],
      dayDateMap: {},
      keyCalendar: Math.random(),
      selectedTemplateDay: "",
      daySets: [],
      dayNotes: ""
    };
  }

  componentDidMount() {
    this.getTemplates();
  }

  getTemplates() {
    callGetCustomProgramTemplates().then(d => {
      let data = d.data;
      let templates = [];
      data.forEach(element => {
        templates.push({
          value: element,
          label: element.name
        });
      });
      this.setState({ templates: templates, keyTemplate: Math.random() });
    });
  }

  onChangeStartDate(value) {
    this.setState(
      {
        startDate: value
      },
      () => {
        this.processTemplate();
      }
    );
  }

  onSelectTemplate = template => {
    this.setState({ selectedTemplate: template }, () => {
      this.processTemplate();
    });
  };

  getFormattedEvents(cuEvents) {
    let events = [];
    cuEvents.forEach(element => {
      let date = element.date;
      element.sets.forEach(el => {
        events.push({
          date: date,
          event: el.name
        });
      });
    });
    return events;
  }

  getEventsFromTemplate() {
    let template = this.state.selectedTemplate.value;
    let events = template.events;

    if (events.length === 0) {
      return [];
    }

    events.sort((a, b) => {
      return a.day > b.day;
    });

    let lastEvent = events[events.length - 1];

    let startDate = moment(this.state.startDate)
      .subtract(1, "days")
      .startOf("day");

    let endDate = moment(this.state.startDate)
      .add(parseInt(lastEvent.day, 10), "days")
      .startOf("day");

    let day = 1;
    let filteredEvents = [];
    let dayDateMap = {};

    while (startDate.add(1, "days").diff(endDate) < 0) {
      let cloneDate = startDate.clone().startOf("day");
      let eventDate = cloneDate.format("YYYY-MM-DD");

      let eventDay = day;

      let event = events.filter(d => {
        return d.day === eventDay;
      });

      if (event.length === 1) {
        let eventInfo = {
          date: eventDate,
          sets: event[0].sets,
          notes: event[0].notes
        };
        filteredEvents.push(eventInfo);
      }
      dayDateMap[eventDate] = eventDay;
      day++;
    }

    this.setState({ dayDateMap: dayDateMap });

    return filteredEvents;
  }

  processTemplate() {
    if (this.state.selectedTemplate.value) {
      let events = this.getEventsFromTemplate();
      let fEvents = this.getFormattedEvents(events);
      this.setState({
        calEvents: fEvents,
        events: events,
        keyCalendar: Math.random()
      });
    }
  }

  onPasteEvents(copyDate, newDate) {
    // save custom program template with newDay

    let copyDay = this.state.dayDateMap[moment(copyDate).format("YYYY-MM-DD")];
    let newDay = this.state.dayDateMap[moment(newDate).format("YYYY-MM-DD")];

    let template = this.state.selectedTemplate.value;
    var events = template["events"];

    let copyDayEvent = events.filter(d => {
      return d.day === copyDay;
    });

    let newDayEvent = events.filter(d => {
      return d.day === newDay;
    });
    if (newDayEvent.length === 1) {
      events.forEach(element => {
        if (element.day === newDay) {
          let sets = element.sets;
          element.sets = sets.concat(copyDayEvent[0].sets);
        }
      });
    } else {
      events.push({
        day: newDay,
        notes: copyDayEvent[0].notes,
        sets: copyDayEvent[0].sets
      });
    }

    template["events"] = events;

    callUpdateCustomProgramTemplate(template._id, {
      events: template["events"]
    }).then(data => {
      if (data._id) {
        showNotification("success", "Template Updated", 3000);
      }
    });
  }

  onViewClick = day => {
    if (!this.state.selectedTemplate.label) {
      return;
    }

    let template = this.state.selectedTemplate.value;
    let templateDay = this.state.dayDateMap[moment(day).format("YYYY-MM-DD")];
    let events = template["events"];

    let dayEvents = events.filter(d => {
      return d.day === templateDay;
    });

    let sets = [];
    let notes = "";

    if (dayEvents.length === 1) {
      sets = dayEvents[0].sets;
      notes = dayEvents[0].notes;
    }

    this.setState(
      {
        daySets: sets,
        dayNotes: notes,
        selectedTemplateDay: templateDay
      },
      () => {
        $(
          ".modalAlertOverlay.viewDailyOverview,.modalAlert.viewDailyOverviewAlert"
        ).show();
      }
    );
  };

  onDayClick = day => {
    if (!this.state.selectedTemplate.label) {
      return;
    }
    let template = this.state.selectedTemplate.value;
    let templateDay = this.state.dayDateMap[moment(day).format("YYYY-MM-DD")];

    template["templateDay"] = templateDay;

    this.props.history.push("/admin/edit-custom-program-day", {
      template: template,
      from: "template"
    });
  };

  renderExercisesInDailyOverview() {
    let sets = this.state.daySets;
    let returnVals = [];
    for (let i = 0; i < sets.length; i++) {
      const element = sets[i];
      returnVals.push(
        <div className="card" key={i}>
          <div className="list-group list-group-flush">
            <div className="list-group-item">
              <div className="clearfix">
                <div className="pull-left">
                  <p>
                    <b>{element.name}</b>
                  </p>
                </div>
              </div>
              <div className="gap10" />
              {element.exercises.map(i => (
                <div className="card" key={i.name}>
                  <div className="list-group-item">
                    <div className="clearfix">
                      <div className="pull-left">
                        <p>
                          <b>{i.name}</b>
                        </p>
                      </div>
                      <div className="pull-right">
                        {/*eslint-disable-next-line*/}
                        <a className="btn btn-primary btn-sm">View Exercise</a>
                      </div>
                    </div>
                  </div>
                  <div className="list-group-item">
                    <p>
                      {i.reps ? i.reps + " / " : ""}{" "}
                      {i.weight ? i.weight + "" : ""}
                    </p>
                    {i.time !== "" && <p>Rest {i.time}</p>}
                    <p>{i.notes}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      );
    }
    return returnVals;
  }

  onClickCloseDailyOverviewModel() {
    $(
      ".modalAlertOverlay.viewDailyOverview,.modalAlert.viewDailyOverviewAlert"
    ).hide();
  }

  onClickDeleteDailyOverviewModel() {
    this.onClickCloseDailyOverviewModel();
    setTimeout(() => {
      $(
        ".modalAlertOverlay.viewConfirmOverlay,.modalAlert.viewConfirmAlert"
      ).show();
    }, 300);
  }

  renderDailyOverview() {
    let modalHeight = window.innerHeight - 300;
    return (
      <div>
        <div className="modalAlertOverlay viewDailyOverview" />
        <div className="modalAlert viewDailyOverviewAlert">
          <p>Daily Overview</p>

          <div
            className="container-fluid modalInnerBody"
            style={{ maxHeight: modalHeight, overflowY: "hidden" }}>
            <Scrollbars
              autoHide
              autoHideTimeout={1000}
              autoHideDuration={200}
              style={{ height: modalHeight }}>
              {this.renderExercisesInDailyOverview()}
              <div className="card">
                <div className="list-group list-group-flush">
                  <div className="list-group-item">
                    <p>
                      <b>Overview</b>
                    </p>
                  </div>
                  <div className="list-group-item">
                    <p>{this.state.dayNotes}</p>
                  </div>
                </div>
              </div>
            </Scrollbars>
          </div>

          <div className="alertFooterBtns">
            {/*eslint-disable*/}
            <a
              className="btn btn-danger dismissThisModal margin-right-10"
              onClick={this.onClickDeleteDailyOverviewModel.bind(this)}>
              Delete
            </a>
            <a
              className="btn btn-primary dismissThisModal"
              onClick={this.onClickCloseDailyOverviewModel.bind(this)}>
              Close
            </a>
            {/*eslint-enable*/}
          </div>
        </div>
      </div>
    );
  }

  renderConfirmAlert() {
    return (
      <div>
        <div className="modalAlertOverlay viewConfirmOverlay" />
        <div className="modalAlert viewConfirmAlert">
          <p>Are you sure want to delete/clear this day ?</p>
          <div className="alertFooterBtns">
            {/*eslint-disable*/}
            <a
              className="btn btn-default closeModal-No margin-right-10"
              onClick={this.onClickNoConfirmAlert}>
              No
            </a>
            <a
              className="btn btn-primary closeModal-Yes"
              onClick={this.onClickYesConfirmAlert}>
              Yes
            </a>
            {/*eslint-enable*/}
          </div>
        </div>
      </div>
    );
  }

  onClickNoConfirmAlert = () => {
    $(
      ".modalAlertOverlay.viewConfirmOverlay,.modalAlert.viewConfirmAlert"
    ).hide();
  };

  onClickYesConfirmAlert = () => {
    $(
      ".modalAlertOverlay.viewConfirmOverlay,.modalAlert.viewConfirmAlert"
    ).hide();

    let template = this.state.selectedTemplate.value;
    var events = template["events"];

    const filteredEvents = events.filter(
      item => item.day !== this.state.selectedTemplateDay
    );

    template["events"] = filteredEvents;

    callUpdateCustomProgramTemplate(template._id, {
      events: template["events"]
    }).then(data => {
      if (data._id) {
        let selectedTemplate = this.state.selectedTemplate;
        selectedTemplate.value = data;

        this.setState(
          {
            selectedTemplate: selectedTemplate,
            selectedTemplateDay: ""
          },
          () => {
            this.processTemplate();
            showNotification("success", "Custom Program Updated", 3000);
          }
        );
      }
    });
  };

  render() {
    return (
      <div>
        {this.renderDailyOverview()}
        {this.renderConfirmAlert()}
        <div className="container-fluid">
          <TitleBox title={"View Templates"} />
          <div className="gap20" />
          <div className="card">
            <div className="container-fluid">
              <div className="gap20" />
              <div className="row">
                <div className="col-lg-6">
                  <TypeableSelect
                    key={this.state.keyTemplate}
                    name={"selectedTemplate"}
                    placeholder={"Select A Template"}
                    selected={this.state.selectedTemplate}
                    onSelect={this.onSelectTemplate}
                    options={this.state.templates}
                  />
                </div>
                <div className="col-lg-6">
                  <label className="inputFakeLabelFocussed">
                    Select Start Date
                  </label>
                  <div>
                    <DatePicker
                      value={this.state.startDate}
                      onChange={this.onChangeStartDate.bind(this)}
                    />
                  </div>
                </div>
              </div>
              <div className="gap20" />
            </div>
          </div>

          <div className="card">
            <div className="list-group list-group-flush">
              <div className="list-group-item">
                <h5 className="noMargin pull-left firstLetter">
                  Sets & Exercises
                </h5>
              </div>
              <div className="gap20" />
              <FullCalendar
                ref={ref => (this.calenderRef = ref)}
                changeYear={false}
                changeMonth={true}
                onDayClick={day => this.onDayClick(day)}
                onViewClick={day => this.onViewClick(day)}
                onPasteEvents={this.onPasteEvents.bind(this)}
                events={this.state.calEvents}
                key={this.state.keyCalendar}
              />
              <div className="gap20" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ViewTemplates);
