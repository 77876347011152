import React from "react";
import moment from "moment";
import { sanitizer } from "../../helpers";
/*eslint-disable*/

class FullCalendar extends React.Component {
  constructor(props) {
    super(props);

    const { changeMonth, changeYear, events } = this.props;

    this.state = {
      month: moment().format("MM"),
      year: moment().format("Y"),
      monthText: moment().format("MMMM"),
      yearText: moment().format("Y"),
      events: events,
      copyDay: "",
      eventDays: [],
      changeMonth: changeMonth || true,
      changeYear: changeYear || true,
      isCopy: this.props.isCopy === false ? false : true,
      isView: this.props.isView === false ? false : true,
    };

    setTimeout(() => {
      this.updateEventDays(events);
    }, 10);
  }

  componentWillReceiveProps(newProps) {
    // this.setState({
    //   width: 400,
    // });
  }

  updateEventDays(events) {
    let eventDays = [];
    events.map((event) => {
      eventDays.push(moment(event.date).startOf("day").format("Y-MM-DD")); // Make it so this filters to only unique values...
    });

    this.setState({
      eventDays: eventDays,
    });
  }

  nextMonth() {
    const newDate = moment(this.state.year + "-" + this.state.month).add(
      1,
      "months"
    );
    this.setState({
      month: newDate.format("MM"),
      year: newDate.format("Y"),
      monthText: newDate.format("MMMM"),
      yearText: newDate.format("Y"),
    });
  }

  previousMonth() {
    const newDate = moment(this.state.year + "-" + this.state.month).subtract(
      1,
      "months"
    );
    this.setState({
      month: newDate.format("MM"),
      year: newDate.format("Y"),
      monthText: newDate.format("MMMM"),
      yearText: newDate.format("Y"),
    });
  }

  nextYear() {
    const newDate = moment(this.state.year + "-" + this.state.month).add(
      1,
      "years"
    );
    this.setState({
      month: newDate.format("MM"),
      year: newDate.format("Y"),
      monthText: newDate.format("MMMM"),
      yearText: newDate.format("Y"),
    });
  }

  previousYear() {
    const newDate = moment(this.state.year + "-" + this.state.month).subtract(
      1,
      "years"
    );
    this.setState({
      month: newDate.format("MM"),
      year: newDate.format("Y"),
      monthText: newDate.format("MMMM"),
      yearText: newDate.format("Y"),
    });
  }

  viewDate(e, day) {
    e.stopPropagation();
    this.props.onViewClick(day);
  }

  copyDate(e, day) {
    e.stopPropagation();
    this.setState({
      copyDay: day,
    });
  }

  cancelDate(e, day) {
    e.stopPropagation();
    this.setState({
      copyDay: "",
    });
  }

  pasteDate(e, newDay) {
    e.stopPropagation();
    //copy the event info....
    let events = this.state.events;

    events.map((event) => {
      if (event.date === this.state.copyDay) {
        events.push({
          date: newDay,
          event: event.event,
        });
      }
    });

    let copyDay = this.state.copyDay;

    this.setState(
      {
        copyDay: "",
      },
      () => {
        this.updateEventDays(events);
        this.props.onPasteEvents(copyDay, newDay);
      }
    );
  }

  renderYearChange() {
    return this.state.changeYear ? (
      <div className="pull-left">
        <div onClick={this.previousYear.bind(this)}> {"<<"} </div>
        <div onClick={this.nextYear.bind(this)}> {">>"} </div>
      </div>
    ) : (
      ""
    );
  }

  renderMonthChange() {
    return this.state.changeMonth ? (
      <div className="pull-right">
        <div onClick={this.previousMonth.bind(this)}> {"<"}</div>
        <div onClick={this.nextMonth.bind(this)}> {">"} </div>
      </div>
    ) : (
      ""
    );
  }

  dayClicked(day) {
    this.props.onDayClick(day);
  }

  render() {
    const monthYear = this.state.year + "-" + this.state.month;

    const startWeek = moment(monthYear).startOf("month").week();
    const endWeek = moment(monthYear).endOf("month").week();

    let weeks = [];
    for (var week = startWeek; week <= startWeek + 5; week++) {
      //Now we need to do some calculations in case it's the last and first week, etc...

      let weekAdd = week - 1;

      weeks.push({
        sunday: moment(this.state.year, "Y")
          .add(weekAdd, "weeks")
          .day("Sunday"),
        monday: moment(this.state.year, "Y")
          .add(weekAdd, "weeks")
          .day("Monday"),
        tuesday: moment(this.state.year, "Y")
          .add(weekAdd, "weeks")
          .day("Tuesday"),
        wednesday: moment(this.state.year, "Y")
          .add(weekAdd, "weeks")
          .day("Wednesday"),
        thursday: moment(this.state.year, "Y")
          .add(weekAdd, "weeks")
          .day("Thursday"),
        friday: moment(this.state.year, "Y")
          .add(weekAdd, "weeks")
          .day("Friday"),
        saturday: moment(this.state.year, "Y")
          .add(weekAdd, "weeks")
          .day("Saturday"),
      });
    }
    return (
      <section className="calendarComponent">
        {/*<div>Current Month: {this.state.monthText}</div>
								<div>Current Year: {this.state.yearText}</div>*/}

        <div className="calendarHeading">
          {this.state.monthText}, {this.state.yearText}
        </div>
        <div className="timeNavigator">
          <div className="clearfix">
            {this.renderYearChange()}
            {this.renderMonthChange()}
          </div>
        </div>

        <div className="container">
          <div
            className={
              this.state.copyDay !== ""
                ? "calendarHolder eventCopied"
                : "calendarHolder"
            }>
            <div className="calendarHeader">
              <div>Sun</div>
              <div>Mon</div>
              <div>Tue</div>
              <div>Wed</div>
              <div>Thu</div>
              <div>Fri</div>
              <div>Sat</div>
            </div>
            {weeks.map((week, i) => {
              if (i > 1 && week.sunday.format("MM") !== this.state.month) {
                return "";
              }

              {
                this.state.eventDays.includes(
                  week.monday.startOf("day").format("Y-MM-DD")
                ) ? (
                  <div key={i} className={"hasEvents"}>
                    {this.state.events.map((event, j) => {
                      if (
                        moment(event.date)
                          .startOf("day")
                          .isSame(week.monday.startOf("day"))
                      )
                        return (
                          <p key={event.event + Math.random().toString()}>
                            {event.event}
                          </p>
                        );
                    })}
                  </div>
                ) : (
                  ""
                );
              }
              return (
                <div key={i}>
                  {Object.keys(week).map((k, v) => {
                    let hasEvents = this.state.eventDays.includes(
                      week[k].startOf("day").format("Y-MM-DD")
                    )
                      ? "hasEvents"
                      : "";
                    let otherMonth =
                      this.state.month === week[k].format("MM")
                        ? ""
                        : "otherMonth";

                    let dayClasses = hasEvents + " " + otherMonth;

                    return (
                      <div
                        key={week[k].format("Y-MM-DD")}
                        onClick={() =>
                          this.dayClicked(week[k].format("Y-MM-DDTHH:mm:ss"))
                        }
                        className={dayClasses}>
                        {week[k].format("Do")}

                        {this.state.isCopy && (
                          <button
                            onClick={(e) =>
                              this.copyDate(e, week[k].format("Y-MM-DD"))
                            }
                            className={"calendarCopyBtn"}>
                            Copy
                          </button>
                        )}
                        {this.state.isView && (
                          <button
                            onClick={(e) =>
                              this.viewDate(e, week[k].format("Y-MM-DD"))
                            }
                            className={"calendarViewBtn"}>
                            View
                          </button>
                        )}

                        {this.state.copyDay !== week[k].format("Y-MM-DD") && (
                          <button
                            onClick={(e) =>
                              this.pasteDate(e, week[k].format("Y-MM-DD"))
                            }
                            className={"calendarPasteBtn"}>
                            Paste
                          </button>
                        )}

                        {this.state.copyDay === week[k].format("Y-MM-DD") && (
                          <button
                            onClick={(e) =>
                              this.cancelDate(e, week[k].format("Y-MM-DD"))
                            }
                            className={"calendarPasteBtn"}>
                            Cancel
                          </button>
                        )}

                        <div>
                          {this.state.events.map((event) => {
                            if (
                              moment(event.date)
                                .startOf("day")
                                .isSame(week[k].startOf("day"))
                            )
                              return (
                                <p
                                  key={event.event + Math.random().toString()}
                                  dangerouslySetInnerHTML={{
                                    __html: sanitizer(event.event),
                                  }}></p>
                              );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </section>
    );
  }
}
/*eslint-enable*/

export default FullCalendar;
