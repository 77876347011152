import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars";
import { VelocityTransitionGroup } from "velocity-react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import copy from "copy-to-clipboard";
import { DokaModal } from "../../packages/react-doka";
import PubSub from "pubsub-js";

import {
  callGetAwsImages,
  callGetRefreshAwsImages,
  callDeleteAwsImage,
  callGetGenericTags,
  callCreateGenericTag,
  callUpdateAwsImage,
  callCreateAwsImage,
  callResizeAwsImage,
  callImportAwsImage,
  callUpdateMultiAwsImages,
  uploadImage,
  callCreateAwsFile,
  callGetAwsFiles,
  callDeleteAwsFile,
  uploadImages,
} from "../../services";
import {
  showNotification,
  bytesToSize,
  imageUrl,
  slugify,
  getUniqueId,
} from "../../helpers";
import TagSelect from "../form/tagSelect";
import GenericTagSelect from "../form/genericTagSelect";
import RadioTag from "../form/radioTag";
import ImageUpload from "../form/imageUpload";
import Checkbox from "../form/checkBox";
import config from "../../helpers/config";
import { SettingsHelper } from "../../helpers/settings";

import store from "../../modules/store";
import {
  callCreateAwsVideo,
  callDeleteAwsVideo,
  callGetAwsVideosByQuery,
  callUpdateAwsVideo,
} from "../../services/awsVideosService";
import { getItem, setItem } from "../../helpers/storage";
import EmbedVideo from "../general/embedVideo";
import SearchImages from "./searchImages";

declare var $: any;

class EditorImages extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    let contentType = this.props.contentType || "image";
    let fileType = "image";
    let fileTypes = "images";

    if (contentType === "image") {
      fileType = "image";
      fileTypes = "images";
    } else if (contentType === "file") {
      fileType = "file";
      fileTypes = "files";
    } else if (contentType === "video") {
      fileType = "video";
      fileTypes = "videos";
    }

    this.state = {
      images: [],
      total: 0,
      limit: 30,
      hasMore: true,
      selectedImage: {},
      selectedSubImage: {},
      tags: [],
      fTags: { new: [], existing: [] },
      fImportImageTags: { new: [], existing: [] },
      fMultiTags: { new: [], existing: [] },
      tagList: [],
      keyTagFilter: Math.random(),
      selectedTagId: "all",
      keyRadioSelect: Math.random(),
      resizeOption: "width",
      resizeValue: "",
      image: "",
      imageUpload: "",
      keyUpload: Math.random(),
      keyImportImageTag: Math.random(),
      processingImport: false,
      tempImageCount: 0,
      showImageUpload: false,
      checkboxImages: {},
      keyAddMultiTag: Math.random(),
      bucketName: "",
      type: this.props.type || "upload",
      dokaEnabled: false,
      fileType: fileType,
      keyFileType: Math.random(),
      fileTypes: fileTypes,
      keyFileTypes: Math.random(),
      fileName: "",
      selectedFile: "",
      awsFiles: [],
      awsVideos: [],
      isImageUploading: false,
      keyImageUpload: Math.random(),
      selectedVideo: {},
      videoFullUrl: "",
      videoTitle: "",
      selectVideoType: "manual",
      keyVideoType: Math.random(),
      isOwnVideo: false,
      keyOwnVideo: Math.random(),
      keyVideosType: Math.random(),
      videosType: "upload",
      videosUrl: "",
      selectedVideoFile: "",
      editVideoTitle: "",
      keyEditVideoQuality: Math.random(),
      editVideoQuality: ["1080p", "720p"],
      fileProgress: "",
      showFileCmp: false,
      showVideoCmp: false,
      selectedImages: [],
      videoIndices: [1],
      videoInfo: {},
      markAsPending: false,
      videoStatus: "",
      tagAddList: [],
      imageAddTags: [],
    };
  }

  componentDidMount() {
    this.setBucketName();
    // checking

    store.subscribe(() => {
      let state = store.getState();
      if (state.generalReducer.userSettings._id) {
        this.setBucketName();
      }
    });

    PubSub.subscribe("fileProgress", (message, data) => {
      this.setState({ fileProgress: data });
    });
  }

  setBucketName() {
    let settingsHelper = new SettingsHelper();
    let bucketName = settingsHelper.getAwsBucketName();
    setItem("bucketName", bucketName);

    this.setState({ bucketName: bucketName }, () => {
      this.getAwsImages(this.state.limit);
      this.getAwsFiles();
      this.getAwsVideos();
      this.getGenericTags();
      if ($(window).width() > 780) {
        setTimeout(() => {
          let tooltipFn = $("[data-toggle='tooltip']");
          if (typeof tooltipFn.tooltip == "function") {
            tooltipFn.tooltip({
              html: true,
              animated: "fade",
            });
          }
        }, 2000);
      }
    });
  }

  componentDidUpdate() {
    if ($(window).width() > 780) {
      setTimeout(() => {
        let tooltipFn = $("[data-toggle='tooltip']");
        if (typeof tooltipFn.tooltip == "function") {
          tooltipFn.tooltip({
            html: true,
            animated: "fade",
          });
        }
      }, 2000);
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  getGenericTags() {
    callGetGenericTags("aws-saved-image").then((d) => {
      let tagList = [];
      tagList.push({ name: "All", value: "all" });
      let tagAddList = [];
      d.data.forEach((element) => {
        tagList.push({ name: element.name.toLowerCase(), value: element._id });
        tagAddList.push({
          name: element.name.toLowerCase(),
          value: element._id,
        });
      });
      this.setState({
        tags: d.data,
        tagList: tagList,
        tagAddList: tagAddList,
        keyTagFilter: Math.random(),
      });
    });
  }

  getAwsImages(limit) {
    /*eslint-disable*/
    let query = {
      query: {
        $sort: { date_modified: -1 },
        bucket: this.state.bucketName,
        $limit: limit,
      },
    };

    if (this.state.selectedTagId !== "all") {
      query.query["generic_tag_list"] = { $in: [this.state.selectedTagId] };
    } else {
      this.setState({ hasMore: true });
    }

    /*eslint-enable*/
    callGetAwsImages(query, false).then((d) => {
      let checkboxImages = {};
      let images = [];
      d.data.forEach((element) => {
        checkboxImages[element._id] = {};
        checkboxImages[element._id].value = false;
        checkboxImages[element._id].key = Math.random();

        let fVariations = element.variations.filter((d) => {
          return d.locked === true;
        });
        element["imageLocked"] = fVariations.length > 0;
        images.push(element);
      });
      this.setState({
        images: images,
        checkboxImages,
        total: d.total,
        selectedImages: [],
      });
    });
  }

  getAwsFiles() {
    callGetAwsFiles({}, false).then((d) => {
      this.setState({ awsFiles: d.data });
    });
  }

  getAwsVideos() {
    callGetAwsVideosByQuery({}, false).then((d) => {
      this.setState({
        awsVideos: d.data,
        editVideoTitle: "",
        markAsPending: false,
        keyOwnVideo: Math.random(),
      });
    });
  }

  fetchMoreData = () => {
    let limit = this.state.limit + 30;
    if (this.state.total === this.state.images.length) {
      this.setState({ hasMore: false });
      return;
    }
    this.setState({ limit: limit }, () => {
      this.getAwsImages(limit);
    });
  };

  getImageUrl(key) {
    try {
      return imageUrl(key);
    } catch (error) {
      return "";
    }
  }

  getVarImageUrl(key) {
    try {
      return imageUrl(key);
    } catch (error) {
      return "";
    }
  }

  getVideoUrl(element, urlType = "select") {
    let key = element.key;
    let type = element.type || "youtube";

    if (type === "youtube") {
      return `https://d38x6s7j8yvy7v.cloudfront.net/${key}`;
    } else {
      if (urlType === "select") {
        key = `${key}/stream-1080p.m3u8`;
        if (element.s_key) {
          key = `${element.s_key}/stream-master-pl.m3u8`;
        }
      }
      return `https://d38x6s7j8yvy7v.cloudfront.net/${key}`;
    }
  }

  onChangeImage(image, file, metaData) {
    this.setState(
      {
        image: image.src,
        imageUpload: file,
      },
      () => {
        this.uploadAwsImage(metaData);
      }
    );
  }

  onChangeReplaceImage(image, file, metaData) {
    this.setState(
      {
        image: image.src,
        imageUpload: file,
      },
      () => {
        this.replaceAwsImage(metaData);
      }
    );
  }

  onSelectType(item, id) {
    let value = item[Object.keys(item)[0]].value;
    this.setState({ [id]: value, videosType: "upload" }, () => {
      if (value === "images") {
        this.getAwsImages(this.state.limit);
      } else if (value === "files") {
        this.getAwsFiles();
      } else if (value === "videos") {
        this.getAwsVideos();
      }
    });
  }

  onSelectVideosType(item, id) {
    let value = item[Object.keys(item)[0]].value;
    this.setState({ [id]: value });
  }

  replaceAwsImage(metaData) {
    $(
      ".modalAlertOverlay.replaceImageOverlay,.modalAlert.replaceImageAlert"
    ).fadeOut(200);
    let fileName = this.state.selectedImage.key;
    this.setState({ isImageUploading: true, keyImageUpload: Math.random() });
    uploadImage(this.state.imageUpload, fileName, "replace").then((resp) => {
      resp.json().then((data) => {
        /*eslint-disable*/
        if (data.file_name) {
          let variantResize = `${metaData.width}x${metaData.height}`;
          let apiData = {
            date_modified: new Date(),
            mime_type: metaData.mimeType,
            size: data.size,
            variant: variantResize,
          };
          /*eslint-enable*/
          callUpdateAwsImage(this.state.selectedImage._id, apiData).then(
            (d) => {
              if (d._id) {
                this.prepareThumbImage(this.state.selectedImage, 2);
              }
            }
          );
        }
      });
    });
  }

  uploadAwsImage(metaData) {
    let fileName = new Date().getTime();
    if (config.isDev === "true") {
      fileName = `dev-${fileName}`;
    }
    this.setState({ isImageUploading: true, keyImageUpload: Math.random() });
    uploadImage(this.state.imageUpload, fileName).then((resp) => {
      resp.json().then((data) => {
        /*eslint-disable*/
        if (data.file_name) {
          let key = data.file_name;
          let fileName = key.substr(0, key.lastIndexOf("."));
          let extName = key.substr(key.lastIndexOf(".") + 1);
          let thumbKey = `${fileName}-thumbnail.${extName}`;

          let variantResize = `${metaData.width}x${metaData.height}`;

          if (extName.includes("svg")) {
            thumbKey = key;
          }

          let apiData = {
            key: key,
            file: fileName,
            thumbnail_key: thumbKey,
            date_modified: new Date(),
            bucket: this.state.bucketName,
            vendor: "aws",
            mime_type: metaData.mimeType,
            size: data.size,
            variant: variantResize,
            generic_tag_list: [],
            variations: [],
          };
          /*eslint-enable*/
          callCreateAwsImage(apiData).then((d) => {
            if (d._id) {
              if (extName.includes("svg")) {
                showNotification("success", "Image uploaded");
                this.getAwsImages(this.state.limit);
                this.onClickShowImageUpload();
              } else {
                this.prepareThumbImage(d, 1);
              }
            }
          });
        }
      });
    });
  }

  async uploadAwsImages() {
    /*eslint-disable*/

    let fileUrls = [];

    this.setState({ isImageUploading: true, keyImageUpload: Math.random() });

    let resp = await uploadImages(this.state.selectedImages);
    let data = await resp.json();
    if (data.file_names) {
      for (let u = 0; u < data.file_names.length; u++) {
        const element = data.file_names[u];
        let key = element.key;
        let fileName = key.substr(0, key.lastIndexOf("."));
        let extName = key.substr(key.lastIndexOf(".") + 1);
        let thumbKey = `${fileName}-thumbnail.${extName}`;

        if (extName.includes("svg")) {
          thumbKey = key;
        }

        fileUrls.push({
          key: key,
          file: fileName,
          thumbnail_key: thumbKey,
          date_modified: new Date(),
          bucket: this.state.bucketName,
          vendor: "aws",
          mime_type: element.mimetype,
          size: element.size,
          variant: "",
          generic_tag_list: this.state.imageAddTags || [],
          variations: [],
          variant_status: "pending",
          extName: extName,
        });
      }
      await callCreateAwsImage(fileUrls);

      for (let o = 0; o < fileUrls.length; o++) {
        const element = fileUrls[o];
        if (!element.extName.includes("svg")) {
          await this.prepareThumbImages(element);
        }
      }

      showNotification("success", "Image uploaded");
      this.setState({ isImageUploading: false, keyImageUpload: Math.random() });
      this.getAwsImages(this.state.limit);
    }
    /*eslint-enable*/
  }

  async prepareThumbImages(image) {
    let resize = "x200";
    let data = {
      action: "resize-aws-image",
      data: {
        resize: resize,
        value: 200,
        url: imageUrl(image.key),
        key: image.key,
        thumbkey: image.thumbnail_key,
      },
    };
    await callResizeAwsImage(data);
  }

  prepareThumbImage(image, option = 1) {
    let resize = "x200";
    let data = {
      action: "resize-aws-image",
      data: {
        resize: resize,
        value: 200,
        url: this.getImageUrl(image.key),
        key: image.key,
        thumbkey: image.thumbnail_key,
      },
    };
    callResizeAwsImage(data).then((d) => {
      $(".modalAlertOverlay.settingsOverlay,.modalAlert.settingsAlert").fadeOut(
        200
      );
      if (option === 1) {
        showNotification("success", "Image uploaded");
        this.getAwsImages(this.state.limit);
        this.onClickShowImageUpload();
      } else {
        $(
          ".modalAlertOverlay.replaceImageOverlay,.modalAlert.replaceImageAlert"
        ).fadeOut(200);
        showNotification("success", "Image replaced");
        this.getAwsImages(this.state.limit);
        if (this.props.onSelectImage) {
          this.props.onSelectImage(image);
        }
      }
      this.setState({ isImageUploading: false, keyImageUpload: Math.random() });
    });
  }

  variantImageUpload(output) {
    let imageData = output.data;
    let file = output.file;

    let variantResize = `${imageData.image.width}x${imageData.image.height}`;
    let key = this.state.selectedImage.key;
    let keyName = key.substr(0, key.lastIndexOf("."));
    let fileName = `${keyName}-${variantResize}`;

    uploadImage(file, fileName).then((resp) => {
      resp.json().then((data) => {
        if (data.file_name) {
          let key = data.file_name;
          let fileName = key.substr(0, key.lastIndexOf("."));
          let extName = key.substr(key.lastIndexOf(".") + 1);
          let thumbKey = `${fileName}-thumbnail.${extName}`;
          /*eslint-disable*/
          let apiData = {
            key: key,
            file: fileName,
            thumbnail_key: thumbKey,
            date_modified: new Date(),
            bucket: this.state.bucketName,
            vendor: "aws",
            mime_type: "",
            size: data.size,
            variant: variantResize,
            generic_tag_list: [],
            variations: [],
          };
          /*eslint-enable*/
          callCreateAwsImage(apiData).then((d) => {
            if (d._id) {
              this.prepareThumbImage(d);
            }
          });
        }
      });
    });
  }

  variantImageEdited(output) {
    let imageData = output.data;
    let file = output.file;

    let variantResize = `${imageData.image.width}x${imageData.image.height}`;
    let key = this.state.selectedImage.key;
    let keyName = key.substr(0, key.lastIndexOf("."));
    let fileName = `${keyName}-${variantResize}`;

    let variations = this.state.selectedImage.variations;
    let exVariants = variations.filter((d) => {
      return d.key.includes(fileName);
    });
    let index = 0;

    if (exVariants.length > 0) {
      index = exVariants[exVariants.length - 1].index || 0;
      fileName += `-${index + 1}`;
    }

    uploadImage(file, fileName).then((resp) => {
      resp.json().then((data) => {
        /*eslint-disable*/
        if (data.file_name) {
          let key = data.file_name;
          let variant = {
            key: key,
            variant: variantResize,
            size: data.size,
            index: index + 1,
            locked: true,
          };
          let updateApiData = {
            $push: { variations: variant },
          };
          callUpdateAwsImage(this.state.selectedImage._id, updateApiData).then(
            (d) => {
              if (d._id) {
                copy(this.getVarImageUrl(key));
                this.getAwsImages(this.state.limit);
              }
            }
          );
        }
      });
    });
  }

  onClickImportImages() {
    this.setState({ keyImportImageTag: Math.random() });
    $(
      ".modalAlertOverlay.importImageOverlay,.modalAlert.importImageAlert"
    ).fadeIn(200);
  }

  onClickTagFilter(selectedList, name) {
    let tagId = selectedList[Object.keys(selectedList)[0]].value;
    this.setState({ selectedTagId: tagId }, () => {
      this.setState({ hasMore: true }, () => {
        this.getAwsImages(this.state.limit);
      });
    });
  }

  onClickTagImage(item) {
    let values = [];
    if (Object.keys(item).length > 0 && item.constructor === Object) {
      Object.keys(item).forEach((key) => {
        values.push(item[key].value);
      });
    }
    this.setState({ imageAddTags: values });
  }

  onClickRefresh() {
    callGetRefreshAwsImages().then((d) => {
      showNotification("info", "Refresh Process Done", 4000);
      if (d.status) {
        this.getAwsImages(this.state.limit);
      }
    });
  }

  onClickDeleteImage(image) {
    this.setState({ selectedImage: image }, () => {
      if (image.imageLocked) {
        showNotification(
          "warning",
          "One or more variant images are locked. Please unlock to delete this image",
          5000
        );
        return;
      }
      $(
        ".modalDeleteAlertOverlay.deleteImageOverlay,.modalDeleteAlert.deleteImageAlert"
      ).fadeIn(200);
    });
  }

  onClickReplaceImage(image) {
    this.setState({ selectedImage: image }, () => {
      $(
        ".modalAlertOverlay.replaceImageOverlay,.modalAlert.replaceImageAlert"
      ).fadeIn(200);
    });
  }

  onClickDeleteFile(file) {
    this.setState({ selectedFile: file }, () => {
      $(
        ".modalDeleteAlertOverlay.deleteFileOverlay,.modalDeleteAlert.deleteFileAlert"
      ).fadeIn(200);
    });
  }

  onClickDeleteVideo(video) {
    this.setState({ selectedVideo: video }, () => {
      $(
        ".modalDeleteAlertOverlay.deleteFileOverlay,.modalDeleteAlert.deleteFileAlert"
      ).fadeIn(200);
    });
  }

  onClickEditVideo(video) {
    this.setState(
      {
        selectedVideo: video,
        editVideoTitle: video.title,
        editVideoQuality:
          video.qualities.length > 0 ? video.qualities : ["1080p", "720p"],
        keyEditVideoQuality: Math.random(),
      },
      () => {
        $(
          ".modalAlertOverlay.updateVideoModal,.modalAlert.updateVideoAlert"
        ).fadeIn(200);
      }
    );
  }

  onClickDownloadVideo(video) {
    let videoUrl = this.getVideoUrl(video, "download");
    window.open(videoUrl, "new");
  }

  onClickThumbVideo(video) {
    this.setState({ selectedVideo: video }, () => {
      $(
        ".modalAlertOverlay.thumbVideoModal,.modalAlert.thumbVideoAlert"
      ).fadeIn();
    });
  }

  onClickDeleteSubImage(image) {
    this.setState({ selectedSubImage: image }, () => {
      if (image.locked === true) {
        showNotification("warning", "Image is locked, unlock to delete it.");
        return;
      }
      $(
        ".modalDeleteAlertOverlay.deleteImageOverlay,.modalDeleteAlert.deleteImageAlert"
      ).fadeIn(200);
    });
  }

  onClickLockSubImage(image) {
    let selectedImage = this.state.selectedImage;
    let selectedSubImage = image;
    let variations = selectedImage.variations;

    variations.forEach((element) => {
      if (element.key === selectedSubImage.key) {
        element["locked"] = selectedSubImage.locked
          ? !selectedSubImage.locked
          : true;
      }
    });

    let updateApiData = {
      variations: variations,
    };
    callUpdateAwsImage(selectedImage._id, updateApiData).then((d) => {
      if (d._id) {
        this.setState(
          { selectedImage: selectedImage, selectedSubImage: {} },
          () => {
            this.getAwsImages(this.state.limit);
          }
        );
      }
    });
  }

  onClickCopyImageUrl(image) {
    let sImage = image;
    let eImages = sImage.variations.filter((d) => d.key === image.key);
    if (eImages.length === 0) {
      sImage.variations.unshift({
        key: image.key,
        variant: image.variant,
        size: image.size,
        main: true,
      });
    }
    this.setState({ selectedImage: sImage }, () => {
      $(".modalAlertOverlay.settingsOverlay,.modalAlert.settingsAlert").fadeIn(
        200
      );
      this.setState({ keyUpload: Math.random() });
    });
  }

  onClickSettingImage(image) {
    this.setState({ selectedImage: image, dokaEnabled: true });
  }

  onClickAddMultiTags() {
    this.setState({ keyAddMultiTag: Math.random() });
    $(".modalAlertOverlay.addTagsOverlay,.modalAlert.addTagsAlert").fadeIn(200);
  }

  onClickDeleteMultiImages() {
    $(
      ".modalDeleteAlertOverlay.deleteMultiImageOverlay,.modalDeleteAlert.deleteMultiImageAlert"
    ).fadeIn(200);
  }

  onClickShowImageUpload() {
    this.setState({ showImageUpload: !this.state.showImageUpload });
  }

  onClickNoAlert = () => {
    this.setState({ selectedSubImage: {} }, () => {
      $(
        ".modalDeleteAlertOverlay.deleteImageOverlay,.modalDeleteAlert.deleteImageAlert"
      ).fadeOut(200);
    });
  };

  onClickYesAlert = () => {
    if (this.state.selectedSubImage.key) {
      let data = {
        action: "delete-aws-image",
        data: {
          key: this.state.selectedSubImage.key,
          bucket: this.state.bucketName,
        },
      };
      callDeleteAwsImage(data).then((d) => {
        $(
          ".modalDeleteAlertOverlay.deleteImageOverlay,.modalDeleteAlert.deleteImageAlert"
        ).fadeOut(200);
        let selectedImage = this.state.selectedImage;
        let variations = selectedImage.variations.filter((d) => {
          return d.key !== this.state.selectedSubImage.key;
        });

        selectedImage.variations = variations;

        let updateApiData = {
          variations: variations,
        };
        callUpdateAwsImage(selectedImage._id, updateApiData).then((d) => {
          if (d._id) {
            this.setState(
              { selectedImage: selectedImage, selectedSubImage: {} },
              () => {
                this.getAwsImages(this.state.limit);
              }
            );
          }
        });
      });
    } else {
      let data = {
        action: "delete-aws-image",
        data: {
          key: this.state.selectedImage.key,
          id: this.state.selectedImage._id,
          bucket: this.state.bucketName,
          thumbkey: this.state.selectedImage.thumbnail_key,
          variations: this.state.selectedImage.variations,
        },
      };
      callDeleteAwsImage(data).then((d) => {
        $(
          ".modalDeleteAlertOverlay.deleteImageOverlay,.modalDeleteAlert.deleteImageAlert"
        ).fadeOut(200);
        this.getAwsImages(this.state.limit);
      });
    }
  };

  onClickNoMultiAlert = () => {
    $(
      ".modalDeleteAlertOverlay.deleteMultiImageOverlay,.modalDeleteAlert.deleteMultiImageAlert"
    ).fadeOut(200);
  };

  onClickYesMultiAlert = () => {
    let ids = [];
    let checkboxImages = this.state.checkboxImages;

    Object.keys(checkboxImages).forEach((element) => {
      if (checkboxImages[element].value) {
        let image = this.state.images.filter((d) => {
          return d._id === element;
        });
        ids.push({
          key: image[0].key,
          id: element,
          variations: image[0].variations,
          thumbkey: image[0].thumbnail_key,
        });
      }
    });

    let data = {
      action: "delete-multi-aws-images",
      data: {
        ids: ids,
        bucket: this.state.bucketName,
      },
    };

    callUpdateMultiAwsImages(data).then((d) => {
      this.getAwsImages(this.state.limit);
      $(
        ".modalDeleteAlertOverlay.deleteMultiImageOverlay,.modalDeleteAlert.deleteMultiImageAlert"
      ).fadeOut(200);
    });
  };

  renderConfirmationAlert() {
    return (
      <div>
        <div className="modalDeleteAlertOverlay deleteImageOverlay" />
        <div className="modalDeleteAlert deleteImageAlert">
          <p>Are you sure you want to delete this image?</p>
          <div className="alertFooterBtns">
            <a
              className="btn btn-default closeModal-No margin-right-10"
              onClick={this.onClickNoAlert}>
              No
            </a>
            <a
              className="btn btn-primary closeModal-Yes"
              onClick={this.onClickYesAlert}>
              Yes
            </a>
          </div>
        </div>
      </div>
    );
  }

  onClickNoFileAlert = () => {
    this.setState({ selectedFile: {} }, () => {
      $(
        ".modalDeleteAlertOverlay.deleteFileOverlay,.modalDeleteAlert.deleteFileAlert"
      ).fadeOut(200);
    });
  };

  onClickYesFileAlert = async () => {
    if (this.state.selectedFile) {
      var response = await callDeleteAwsFile(this.state.selectedFile._id);
      if (response._id) {
        this.setState({ selectedFile: {} }, () => {
          $(
            ".modalDeleteAlertOverlay.deleteFileOverlay,.modalDeleteAlert.deleteFileAlert"
          ).fadeOut(200);
          this.getAwsFiles();
        });
      }
    } else if (this.state.selectedVideo) {
      var response = await callDeleteAwsVideo(this.state.selectedVideo._id);
      if (response._id) {
        this.setState({ selectedVideo: {} }, () => {
          $(
            ".modalDeleteAlertOverlay.deleteFileOverlay,.modalDeleteAlert.deleteFileAlert"
          ).fadeOut(200);
          this.getAwsVideos();
        });
      }
    }
  };

  renderDeleteFileConfirmationAlert() {
    return (
      <div>
        <div className="modalDeleteAlertOverlay deleteFileOverlay" />
        <div className="modalDeleteAlert deleteFileAlert">
          <p>Are you sure you want to delete this file?</p>
          <div className="alertFooterBtns">
            <a
              className="btn btn-default closeModal-No margin-right-10"
              onClick={this.onClickNoFileAlert}>
              No
            </a>
            <a
              className="btn btn-primary closeModal-Yes"
              onClick={this.onClickYesFileAlert}>
              Yes
            </a>
          </div>
        </div>
      </div>
    );
  }

  onClickNoVideoAlert = () => {
    this.setState({ selectedVideo: {} }, () => {
      $(
        ".modalDeleteAlertOverlay.deleteVideoOverlay,.modalDeleteAlert.deleteVideoAlert"
      ).fadeOut(200);
    });
  };

  onClickYesVideoAlert = async () => {
    var response = await callDeleteAwsVideo(this.state.selectedVideo._id);
    if (response._id) {
      this.setState({ selectedVideo: {} }, () => {
        $(
          ".modalDeleteAlertOverlay.deleteVideoOverlay,.modalDeleteAlert.deleteVideoAlert"
        ).fadeOut(200);
        this.getAwsVideos();
      });
    }
  };

  renderDeleteVideoConfirmationAlert() {
    return (
      <div>
        <div className="modalDeleteAlertOverlay deleteVideoOverlay" />
        <div className="modalDeleteAlert deleteVideoAlert">
          <p>Are you sure you want to delete this video?</p>
          <div className="alertFooterBtns">
            <a
              className="btn btn-default closeModal-No margin-right-10"
              onClick={this.onClickNoVideoAlert}>
              No
            </a>
            <a
              className="btn btn-primary closeModal-Yes"
              onClick={this.onClickYesVideoAlert}>
              Yes
            </a>
          </div>
        </div>
      </div>
    );
  }

  renderMultiImageConfirmationAlert() {
    return (
      <div>
        <div className="modalDeleteAlertOverlay deleteMultiImageOverlay" />
        <div className="modalDeleteAlert deleteMultiImageAlert">
          <p>Are you sure you want to delete selected images?</p>
          <div className="alertFooterBtns">
            <a
              className="btn btn-default closeModal-No margin-right-10"
              onClick={this.onClickNoMultiAlert}>
              No
            </a>
            <a
              className="btn btn-primary closeModal-Yes"
              onClick={this.onClickYesMultiAlert}>
              Yes
            </a>
          </div>
        </div>
      </div>
    );
  }

  onClickAddTag(image) {
    let tags = image.ftags || [];
    let fTags = { new: [], existing: Object.assign({}, tags) };
    this.setState({ selectedImage: image, fTags: fTags }, () => {
      $(".modalAlertOverlay.addTagOverlay,.modalAlert.addTagAlert").fadeIn(200);
    });
  }

  onChangeTags = (tags) => {
    if (tags.length > 0) {
      let fTags = { new: [], existing: [] };
      tags.forEach((element) => {
        if (element.name) {
          fTags.existing.push(element);
        } else {
          fTags.new.push(element);
        }
      });
      this.setState({ fTags: fTags });
    } else {
      this.setState({ fTags: { new: [], existing: [] } });
    }
  };

  onChangeMultiTags = (tags) => {
    if (tags.length > 0) {
      let fTags = { new: [], existing: [] };
      tags.forEach((element) => {
        if (element.name) {
          fTags.existing.push(element);
        } else {
          fTags.new.push(element);
        }
      });
      this.setState({ fMultiTags: fTags });
    }
  };

  onChangeImportImageTags = (tags) => {
    if (tags.length > 0) {
      let fTags = { new: [], existing: [] };
      tags.forEach((element) => {
        if (element.name) {
          fTags.existing.push(element);
        } else {
          fTags.new.push(element);
        }
      });
      this.setState({ fImportImageTags: fTags });
    }
  };

  onClickSubmitAddTagModal = () => {
    let tags = this.state.fTags;

    if (tags.new.length > 0) {
      let data = [];
      tags.new.forEach((element) => {
        data.push({ name: element, type: "aws-saved-image" });
      });
      callCreateGenericTag(data).then((resp) => {
        let eTags = this.state.tags;
        this.setState({
          tags: eTags.concat(resp),
        });
        let newTagIds = resp.map((t) => t._id);
        let tagIds = tags.existing.map((t) => t._id);
        let finalTagIds = newTagIds.concat(tagIds);

        this.callUpdateAwsImageAPI(finalTagIds);
      });
    } else {
      let tagIds = tags.existing.map((t) => t._id);
      this.callUpdateAwsImageAPI(tagIds);
    }
  };

  callUpdateAwsImageAPI(tags) {
    /*eslint-disable*/
    let apiData = {
      generic_tag_list: tags,
    };
    /*eslint-enable*/

    callUpdateAwsImage(this.state.selectedImage._id, apiData).then((d) => {
      if (d._id) {
        this.setState(
          { selectedImage: {}, fTags: { new: [], existing: [] } },
          () => {
            this.onClickCancelAddTagModal();
            this.getAwsImages(this.state.limit);
          }
        );
      }
    });
  }

  renderImportImageModal() {
    let imageCount = this.state.tempImageCount;
    let currentCount = 20;
    if (imageCount < 20) {
      currentCount = imageCount;
    }
    return (
      <div>
        <div className="modalAlertOverlay importImageOverlay" />
        <div className="modalAlert importImageAlert big">
          <div className="clearfix">
            <div className="pull-left">
              <p>Import Images</p>
            </div>
          </div>

          <div className="container-fluid">
            <label>
              Import {currentCount} out of {imageCount} Image(s)
            </label>
            id={"editor-images-add-tag-2"}
            <TagSelect
              key={this.state.keyImportImageTag}
              searchKey={"name"}
              selectKey={"name"}
              btnText={"Create New Tag"}
              lblText={"Type a Tag"}
              data={this.state.tags}
              onChange={this.onChangeImportImageTags}
            />
          </div>
          <div className="gap10" />
          <div className="alertFooterBtns">
            {/*eslint-disable*/}
            {this.state.processingImport === false && (
              <a
                className="btn btn-default closeModal-No margin-right-10"
                onClick={this.onClickCancelImportImageModal}>
                Cancel
              </a>
            )}
            {this.state.processingImport === false && (
              <a
                onClick={this.onClickSubmitImportImageModal.bind(this)}
                className="btn btn-primary dismissThisModal">
                Submit
              </a>
            )}
            {this.state.processingImport === true && (
              <a className="btn btn-primary dismissThisModal">
                <img
                  src={`https://${window.location.host}/images/loading3.gif`}
                  alt="loading"
                />
                &nbsp;&nbsp; Importing
              </a>
            )}
            {/*eslint-enable*/}
          </div>
        </div>
      </div>
    );
  }

  onClickSubmitImportImageModal = () => {
    this.setState({ processingImport: true });
    let tags = this.state.fImportImageTags;

    if (tags.new.length > 0) {
      let data = [];
      tags.new.forEach((element) => {
        data.push({ name: element, type: "aws-saved-image" });
      });
      callCreateGenericTag(data).then((resp) => {
        let eTags = this.state.tags;
        this.setState({
          tags: eTags.concat(resp),
        });
        let newTagIds = resp.map((t) => t._id);
        let tagIds = tags.existing.map((t) => t._id);
        let finalTagIds = newTagIds.concat(tagIds);

        this.callImportImageAPI(finalTagIds);
      });
    } else {
      let tagIds = tags.existing.map((t) => t._id);
      this.callImportImageAPI(tagIds);
    }
  };

  callImportImageAPI(tagIds) {
    /*eslint-disable*/
    let data = {
      action: "import-aws-images",
      data: {
        generic_tag_list: tagIds,
      },
    };
    /*eslint-enable*/
    callImportAwsImage(data).then((d) => {
      this.setState({ processingImport: false }, () => {
        $(
          ".modalAlertOverlay.importImageOverlay,.modalAlert.importImageAlert"
        ).fadeOut(200);
        this.getAwsImages(this.state.limit);
      });
    });
  }

  onClickCancelImportImageModal = () => {
    $(
      ".modalAlertOverlay.importImageOverlay,.modalAlert.importImageAlert"
    ).fadeOut(200);
  };

  onClickCancelAddTagModal = () => {
    $(".modalAlertOverlay.addTagOverlay,.modalAlert.addTagAlert").fadeOut(200);
  };

  addEventTag = (add) => {
    this.state.tags.push(add);
    let selectedImage = this.state.selectedImage;
    selectedImage.ftags.existing = this.state.tags;
    this.setState({
      fTags: {
        existing: this.state.tags,
      },
      selectedImage: selectedImage,
    });
  };

  renderAddTagModal() {
    if (!this.state.selectedImage._id) {
      return null;
    }

    let selectedImage = this.state.selectedImage;
    let fTags = Object.assign([], selectedImage.ftags);

    return (
      <div>
        <div className="modalAlertOverlay addTagOverlay" />
        <div className="modalAlert addTagAlert big">
          <div className="clearfix">
            <div className="pull-left">
              <p>Add/Edit Tag</p>
            </div>
          </div>
          <div className="container-fluid">
            {/* <TagSelect
              key={selectedImage._id}
              selectedValues={fTags}
              searchKey={"name"}
              selectKey={"name"}
              btnText={"Create New Tag"}
              lblText={"Type a Tag"}
              data={this.state.tags}
              onChange={this.onChangeTags}
            /> */}
            <GenericTagSelect
              id={"editor-images-type-tag"}
              key={selectedImage._id}
              selectedValues={fTags}
              searchKey={"name"}
              selectKey={"name"}
              lblText={"Type a Tag"}
              data={this.state.tags}
              onChange={this.onChangeTags}
              addTag={this.addEventTag}
              btnText={"Create New Tag"}
              isClickable={true}
              popupHeaderText={"Add Tag"}
              popupGenericType={"aws-saved-image"}
              popupHeaderFFEnableName={true}
              popupHeaderFFEnableDescription={true}
            />
          </div>
          <div className="gap10" />
          <div className="alertFooterBtns">
            {/*eslint-disable*/}
            <a
              className="btn btn-default closeModal-No margin-right-10"
              onClick={this.onClickCancelAddTagModal}>
              Cancel
            </a>
            <a
              className="btn btn-primary closeModal-Yes"
              onClick={this.onClickSubmitAddTagModal}>
              Submit
            </a>
            {/*eslint-enable*/}
          </div>
        </div>
      </div>
    );
  }

  onClickCancelAddMultiTagsModal = () => {
    $(".modalAlertOverlay.addTagsOverlay,.modalAlert.addTagsAlert").fadeOut(
      200
    );
  };

  onClickSubmitAddMultiTagsModal = () => {
    let tags = this.state.fMultiTags;

    if (tags.new.length > 0) {
      let data = [];
      tags.new.forEach((element) => {
        data.push({ name: element, type: "aws-saved-image" });
      });
      callCreateGenericTag(data).then((resp) => {
        let eTags = this.state.tags;
        this.setState({
          tags: eTags.concat(resp),
        });
        let newTagIds = resp.map((t) => t._id);
        let tagIds = tags.existing.map((t) => t._id);
        let finalTagIds = newTagIds.concat(tagIds);

        this.callUpdateAwsImagesAPI(finalTagIds);
      });
    } else {
      let tagIds = tags.existing.map((t) => t._id);
      this.callUpdateAwsImagesAPI(tagIds);
    }
  };

  callUpdateAwsImagesAPI(tagIds) {
    let ids = [];
    let checkboxImages = this.state.checkboxImages;

    Object.keys(checkboxImages).forEach((element) => {
      if (checkboxImages[element].value) {
        ids.push(element);
      }
    });

    let data = {
      action: "update-multi-aws-images",
      data: {
        ids: ids,
        tags: tagIds,
      },
    };

    callUpdateMultiAwsImages(data).then((d) => {
      $(".modalAlertOverlay.addTagsOverlay,.modalAlert.addTagsAlert").fadeOut(
        200
      );
      this.getAwsImages(this.state.limit);
      this.getGenericTags();
    });
  }

  renderAddMultipleTagsModal() {
    return (
      <div>
        <div className="modalAlertOverlay addTagsOverlay" />
        <div className="modalAlert addTagsAlert big">
          <div className="clearfix">
            <div className="pull-left">
              <p>Add Multiple Tags</p>
            </div>
          </div>
          <div className="container-fluid">
            <TagSelect
              id={"editor-images-add-tag"}
              key={this.state.keyAddMultiTag}
              searchKey={"name"}
              selectKey={"name"}
              btnText={"Create New Tag"}
              lblText={"Select a Tag"}
              data={this.state.tags}
              onChange={this.onChangeMultiTags}
            />
          </div>
          <div className="gap10" />
          <div className="alertFooterBtns">
            {/*eslint-disable*/}
            <a
              className="btn btn-default closeModal-No margin-right-10"
              onClick={this.onClickCancelAddMultiTagsModal}>
              Cancel
            </a>
            <a
              className="btn btn-primary closeModal-Yes"
              onClick={this.onClickSubmitAddMultiTagsModal}>
              Submit
            </a>
            {/*eslint-enable*/}
          </div>
        </div>
      </div>
    );
  }

  onClickCancelReplaceImageModal = () => {
    $(
      ".modalAlertOverlay.replaceImageOverlay,.modalAlert.replaceImageAlert"
    ).fadeOut(200);
  };

  onClickSubmitReplaceImageModal = () => {
    $(
      ".modalAlertOverlay.replaceImageOverlay,.modalAlert.replaceImageAlert"
    ).fadeOut(200);
  };

  renderReplaceImageModal() {
    return (
      <div>
        <div className="modalAlertOverlay replaceImageOverlay" />
        <div className="modalAlert replaceImageAlert">
          <div className="clearfix">
            <div className="pull-left">
              <p>Replace Image</p>
            </div>
          </div>
          <div className="container-fluid">
            <div className="list-group list-group-flush">
              <div className="list-group-item">
                <label>
                  Note: This will directly replace the previous image. Make sure
                  to upload an image of the same filetype (ex: .jpg, .png,
                  etc...)
                </label>
                <ImageUpload
                  key={this.state.keyImageUpload}
                  thumbnailAlt={"Upload Image"}
                  onChangeImage={this.onChangeReplaceImage.bind(this)}
                  type={"normal"}
                  preserveRatio={true}
                  height={260}
                  file={this.state.image}
                  hideInput={this.state.isImageUploading}
                />
              </div>
            </div>
          </div>
          <div className="gap10" />
          <div className="alertFooterBtns">
            <button
              className="btn btn-default closeModal-No margin-right-10"
              onClick={this.onClickCancelReplaceImageModal}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    );
  }

  onClickCancelSettingsModal = () => {
    this.setState({ selectedImage: {} }, () => {
      $(".modalAlertOverlay.settingsOverlay,.modalAlert.settingsAlert").fadeOut(
        200
      );
    });
  };

  renderSettingsModal() {
    if (!this.state.selectedImage._id) {
      return null;
    }

    let modalHeight = window.innerHeight - 250;
    let selectedImage = this.state.selectedImage;

    return (
      <div>
        <div
          className="modalAlertOverlay settingsOverlay"
          onClick={this.onClickCancelSettingsModal.bind(this)}
        />
        <div className="modalAlert settingsAlert bigY">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Variations
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.onClickCancelSettingsModal.bind(this)}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="gap10" />
          <div className="container-fluid">
            <Scrollbars
              autoHide
              autoHideTimeout={1000}
              autoHideDuration={200}
              style={{ height: modalHeight }}>
              {selectedImage.variations.length > 0 && (
                <div>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Variant</th>
                        <th>Size</th>
                        <th>Key</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedImage.variations.map((i) => (
                        <tr key={i.key}>
                          <td>{i.variant}</td>
                          <td>{i.size ? bytesToSize(i.size) : "-"}</td>
                          <td>{i.key}</td>
                          <td className="min-width-100">
                            {this.state.type !== "select" ? (
                              <>
                                {!i.main && (
                                  <button
                                    className="btn btn-sm btn-default border-0 margin-right-10"
                                    onClick={this.onClickLockSubImage.bind(
                                      this,
                                      i
                                    )}>
                                    {i.locked ? (
                                      <i className="fa fa-lock" />
                                    ) : (
                                      <i className="fa fa-unlock" />
                                    )}
                                  </button>
                                )}
                                <CopyToClipboard
                                  text={this.getVarImageUrl(i.key)}
                                  onCopy={() =>
                                    showNotification(
                                      "success",
                                      "Image url copied to clipboard",
                                      5000
                                    )
                                  }>
                                  <button
                                    className="btn btn-sm btn-default border-0 margin-right-10"
                                    onClick={this.onClickCancelSettingsModal.bind(
                                      this
                                    )}>
                                    <i className="fa fa-copy" />
                                  </button>
                                </CopyToClipboard>
                                {!i.main && (
                                  <button
                                    className="btn btn-sm btn-danger border-0"
                                    onClick={this.onClickDeleteSubImage.bind(
                                      this,
                                      i
                                    )}>
                                    <i className="fa fa-trash" />
                                  </button>
                                )}
                              </>
                            ) : (
                              <button
                                className="btn btn-default btn-sm"
                                onClick={this.onClickSelectImage.bind(this, i)}>
                                Select
                              </button>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="gap10" />
                </div>
              )}
            </Scrollbars>
          </div>
          <div className="gap10" />
        </div>
      </div>
    );
  }

  toggleCheckbox = (image, check) => {
    let checkboxImages = this.state.checkboxImages;
    checkboxImages[image._id].value = check;
    this.setState({ checkboxImages });
  };

  onClickSelectImage = (image) => {
    if (this.props.onSelectImage) {
      this.props.onSelectImage(image);
    }
  };

  onClickSelectFile = (file) => {
    if (this.props.onSelectFile) {
      this.props.onSelectFile(file);
    }
  };

  onClickSelectVideo = (video) => {
    this.props.onSelectVideo(video);
  };

  handleDokaConfirm(output) {
    this.setState(
      {
        dokaEnabled: false,
      },
      () => {
        // this.variantImageEdited(output);
        this.variantImageUpload(output);
      }
    );
  }

  handleDokaCancel() {
    this.setState({
      dokaEnabled: false,
    });
  }

  onFileChange(e) {
    e.preventDefault();
    let file = e.target.files[0];
    let fileName = file.name || "";
    if (fileName) {
      fileName = fileName.split(".");
      if (fileName.length > 1) {
        this.setState({ fileName: fileName[0] });
      }
    }
    this.setState({ selectedFile: file, showFileCmp: true });
  }

  onVideoFileChange(index, e) {
    e.preventDefault();
    let file = e.target.files[0];
    let fileName = file.name || "";
    let videoInfo = this.state.videoInfo;
    if (fileName) {
      fileName = fileName.split(".");
      if (fileName.length > 1) {
        fileName = fileName[0];
      }
    }
    videoInfo[index] = { ...videoInfo[index], file: file, title: fileName };
    this.setState({ videoInfo, showVideoCmp: true });
  }

  onClickAddNewVideo() {
    let videoIndices = this.state.videoIndices;
    let newIndex = videoIndices.length + 1;
    videoIndices.push(newIndex);
    let videoInfo = this.state.videoInfo;
    videoInfo[newIndex] = { ...videoInfo[newIndex], title: "" };
    this.setState({ videoIndices });
  }

  onClickAddDeleteVideo(index) {
    let videoIndices = this.state.videoIndices;
    videoIndices = videoIndices.filter((d) => d !== index);
    let videoInfo = this.state.videoInfo;
    delete videoInfo[index];
    this.setState({ videoIndices, videoInfo });
  }

  handleVideoTitleChange(event, index) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;

    let videoInfo = this.state.videoInfo;
    videoInfo[index] = { ...videoInfo[index], title: value };
    this.setState({ videoInfo });
  }

  onClickAddVideo() {
    this.props.onSelectVideoUrl(this.state.videosUrl);
  }

  onClickUploadFile() {
    if (this.state.fileName.trim().length === 0) {
      showNotification("error", "File Name is required", 4000);
      return;
    }

    if (!this.state.selectedFile) {
      showNotification("error", "File is required", 4000);
      return;
    }

    this.setState({ isImageUploading: true });

    let fileName = slugify(this.state.fileName);

    uploadImage(this.state.selectedFile, fileName, "file").then((data) => {
      // resp.json().then((data) => {
      /*eslint-disable*/
      if (data.file_name) {
        let key = data.file_name;

        let apiData = {
          key: key,
          file_name: fileName,
          date_modified: new Date(),
          bucket: this.state.bucketName,
          vendor: "aws",
          size: data.size,
        };
        /*eslint-enable*/
        callCreateAwsFile(apiData).then((d) => {
          if (d._id) {
            this.setState(
              {
                fileName: "",
                selectedFile: "",
                showImageUpload: false,
                isImageUploading: false,
                showFileCmp: false,
              },
              () => {
                this.getAwsFiles();
                if (this.props.onSelectFile) {
                  this.props.onSelectFile(d);
                }
              }
            );
          }
        });
      }
      // });
    });
  }

  async onClickUploadVideo() {
    if (this.state.selectVideoType === "youtube") {
      if (this.state.videoTitle.trim().length === 0) {
        showNotification("error", "Video Title is required", 4000);
        return;
      }

      if (this.state.videoFullUrl.trim().length === 0) {
        showNotification("error", "Video URL is required", 4000);
        return;
      }

      if (!this.state.isOwnVideo) {
        showNotification(
          "error",
          "You must agree to copyright access for your video url",
          4000
        );
        return;
      }

      let awsVideoData = {
        url: this.state.videoFullUrl,
        title: this.state.videoTitle,
        status: "pending",
        type: this.state.selectVideoType,
        qualities: ["1080p", "720p"],
      };
      let awsVideo = await callCreateAwsVideo(awsVideoData);
      if (awsVideo._id) {
        showNotification("success", "Video Uploaded Successfully", 4000);
        this.setState({
          videoTitle: "",
          videoFullUrl: "",
          isOwnVideo: false,
          keyOwnVideo: Math.random(),
          selectVideoType: "manual",
          keyVideoType: Math.random(),
        });
        this.getAwsVideos();
        this.onClickShowImageUpload();
      }
    } else {
      let sk = getItem("sk");

      this.setState({ isImageUploading: true });
      let videoInfo = this.state.videoInfo;
      let files = Object.values(videoInfo).map((i) => i.file);

      let data = await uploadImages(files, "file");
      /*eslint-disable*/

      if (data.file_names) {
        let videoUrls = [];

        for (let u = 0; u < data.file_names.length; u++) {
          const element = data.file_names[u];
          let key = element.key;
          let fileName = key.substr(0, key.lastIndexOf("."));

          videoUrls.push({
            key: key,
            s_key: `${sk}-${getUniqueId()}`,
            file_name: fileName,
            title: this.state.videoInfo[u + 1].title,
            date_modified: new Date(),
            bucket: "memberpages",
            status: "pending",
            vendor: "aws",
            size: element.size,
            type: this.state.selectVideoType,
            qualities: ["1080p", "720p"],
          });
        }
        await callCreateAwsVideo(videoUrls);
        showNotification("success", "Video uploaded");
        this.setState({
          selectVideoType: "manual",
          keyVideoType: Math.random(),
          isImageUploading: false,
          showVideoCmp: false,
          videoInfo: {},
          videoIndices: [1],
        });
        this.getAwsVideos();
      }
    }
  }

  fmtMSS(sec) {
    var hours = Math.floor(sec / 3600);
    hours >= 1 ? (sec = sec - hours * 3600) : (hours = "00");
    var min = Math.floor(sec / 60);
    min >= 1 ? (sec = sec - min * 60) : (min = "00");
    sec < 1 ? (sec = "00") : void 0;
    sec = Math.round(sec).toFixed(0);

    min.toString().length === 1 ? (min = "0" + min) : void 0;
    sec.toString().length === 1 ? (sec = "0" + sec) : void 0;

    return hours + ":" + min + ":" + sec;
  }

  renderVideos() {
    var returnVals = [];
    let items = this.state.awsVideos;
    items.forEach((element) => {
      returnVals.push(
        <tr key={element._id}>
          <td>{element.title}</td>
          <td>{element.duration ? this.fmtMSS(element.duration) : "-"}</td>
          <td className="firstLetter">{element.type}</td>
          <td className="firstLetter">{element.status}</td>
          <td>
            <button
              className="btn btn-default btn-sm border-0 margin-right-10"
              onClick={this.onClickDownloadVideo.bind(this, element)}>
              <i className="fa fa-download" />
            </button>
            <button
              className="btn btn-default btn-sm border-0 margin-right-10"
              onClick={this.onClickThumbVideo.bind(this, element)}>
              <i className="fa fa-video-camera" />
            </button>
            {this.state.type === "select" && (
              <button
                className="btn btn-default btn-sm"
                onClick={this.onClickSelectVideo.bind(this, element)}>
                Select
              </button>
            )}
            <CopyToClipboard
              text={this.getVideoUrl(element)}
              onCopy={() =>
                showNotification(
                  "success",
                  "Video url copied to clipboard",
                  5000
                )
              }>
              <button className="btn btn-sm btn-default border-0 margin-right-10">
                <i className="fa fa-copy" />
              </button>
            </CopyToClipboard>
            <button
              className="btn btn-default btn-sm border-0 margin-right-10"
              onClick={this.onClickEditVideo.bind(this, element)}>
              <i className="fa fa-pencil" />
            </button>
            <button
              className="btn btn-danger btn-sm border-0"
              onClick={this.onClickDeleteVideo.bind(this, element)}>
              <i className="fa fa-trash" />
            </button>
          </td>
        </tr>
      );
    });
    return returnVals;
  }

  renderFiles() {
    var returnVals = [];
    let items = this.state.awsFiles;
    items.forEach((element) => {
      returnVals.push(
        <tr key={element._id}>
          <td>{this.getVarImageUrl(element.key)}</td>
          <td>{element.file_name}</td>
          <td>{element.size ? bytesToSize(element.size) : ""}</td>
          <td>
            <CopyToClipboard
              text={this.getVarImageUrl(element.key)}
              onCopy={() =>
                showNotification(
                  "success",
                  "File url copied to clipboard",
                  5000
                )
              }>
              <button className="btn btn-sm btn-default border-0 margin-right-10">
                <i className="fa fa-copy" />
              </button>
            </CopyToClipboard>
            {this.state.type === "select" && (
              <button
                className="btn btn-default btn-sm"
                onClick={this.onClickSelectFile.bind(this, element)}>
                Select
              </button>
            )}
            <button
              className="btn btn-danger btn-sm border-0"
              onClick={this.onClickDeleteFile.bind(this, element)}>
              <i className="fa fa-trash" />
            </button>
          </td>
        </tr>
      );
    });
    return returnVals;
  }

  onImageChange(e) {
    e.preventDefault();
    let files = e.target.files;
    this.setState({
      selectedImages: files,
    });
  }

  onClickUploadImage() {
    this.uploadAwsImages();
  }

  renderSelectImage() {
    return (
      <div className="list-group-item">
        <div className="gap20" />
        <div>
          <input
            type="file"
            name="FileUpload"
            multiple
            onChange={this.onImageChange.bind(this)}
          />
        </div>
        <div className="gap30" />

        <p>Tags</p>
        <div className="tabsHolder radioTabs callProgressTimeline">
          <RadioTag
            onCheckChange={this.onClickTagImage.bind(this)}
            labelList={this.state.tagAddList}
            selectType={"value"}
            selectedList={[]}
            type="multiple"
            key={this.state.keyTagFilter}
          />
        </div>

        {this.state.selectedImages.length > 0 && (
          <div className="pull-right">
            {this.state.isImageUploading ? (
              <button className="btn btn-primary">
                <img
                  src={`https://${window.location.host}/images/loading3.gif`}
                  alt="loading"
                />
                &nbsp;&nbsp; Uploading Image....
              </button>
            ) : (
              <button
                className="btn btn-primary"
                onClick={this.onClickUploadImage.bind(this)}>
                Upload Image
              </button>
            )}
            <div className="gap20" />
          </div>
        )}
      </div>
    );
  }

  renderSelectFile() {
    return (
      <div className="list-group-item">
        {this.state.showFileCmp && (
          <div
            className={
              this.state.fileName !== "" ? "mdInput mdFocussed" : "mdInput"
            }>
            <label>File Name</label>
            <input
              type="text"
              name="fileName"
              value={this.state.fileName}
              onChange={(event) => this.handleInputChange(event)}
            />
          </div>
        )}
        <div className="gap20" />
        <div>
          <input
            type="file"
            name="FileUpload"
            onChange={this.onFileChange.bind(this)}
          />
        </div>
        <div className="gap20" />
        {this.state.showFileCmp && (
          <>
            {this.state.fileProgress && (
              <label>
                Uploading File...&nbsp;&nbsp; {this.state.fileProgress}
              </label>
            )}
            <div className="pull-right">
              {this.state.isImageUploading ? (
                <button className="btn btn-primary">
                  <img
                    src={`https://${window.location.host}/images/loading3.gif`}
                    alt="loading"
                  />
                  &nbsp;&nbsp; Uploading File....
                </button>
              ) : (
                <button
                  className="btn btn-primary"
                  onClick={this.onClickUploadFile.bind(this)}>
                  Upload File
                </button>
              )}
              <div className="gap20" />
            </div>
          </>
        )}
      </div>
    );
  }

  onSelectVideoType(item, id) {
    this.setState({
      [id]: item[Object.keys(item)[0]].value,
    });
  }

  toggleOwnVideoCheckbox = (check) => {
    this.setState({ isOwnVideo: check });
  };

  toggleMarkPending = (check) => {
    this.setState({ markAsPending: check });
  };

  onClickCancelFromVideoTitleModel() {
    $(
      ".modalAlertOverlay.updateVideoModal,.modalAlert.updateVideoAlert"
    ).hide();
  }

  async onClickSubmitFromVideoTitleModel() {
    if (this.state.editVideoTitle.trim().length === 0) {
      showNotification("error", "Video title is required", 4000);
      return;
    }

    let updateData = {
      title: this.state.editVideoTitle,
      qualities: this.state.editVideoQuality,
    };
    if (this.state.markAsPending) {
      updateData["status"] = "pending";
    }

    let response = await callUpdateAwsVideo(
      this.state.selectedVideo._id,
      updateData
    );
    $(
      ".modalAlertOverlay.updateVideoModal,.modalAlert.updateVideoAlert"
    ).hide();
    if (response._id) {
      let video = this.state.selectedVideo;
      let url = `https://videoprocessing.memberpages.app/update-settings.php?skey=${video.s_key}&key=${video.key}`;
      try {
        var request = new Request(url, {
          method: "POST",
          body: JSON.stringify({ qualities: this.state.editVideoQuality }),
        });
        await fetch(request);
      } catch (error) {
        console.log(error);
      }
      this.getAwsVideos();
    }
  }

  onSelectVideoQuality(item, id) {
    let fields = [];

    Object.keys(item).forEach((obj) => {
      fields.push(item[obj].value);
    });

    this.setState({
      editVideoQuality: fields,
    });
  }

  renderUpdateVideoModel() {
    return (
      <div>
        <div className="modalAlertOverlay updateVideoModal" />
        <div className="modalAlert updateVideoAlert">
          <p>Update Video</p>
          <div className="container-fluid">
            <div
              className={
                this.state.editVideoTitle !== ""
                  ? "mdInput mdFocussed"
                  : "mdInput"
              }>
              <label>Video Title</label>
              <input
                type="text"
                name="editVideoTitle"
                value={this.state.editVideoTitle}
                onChange={(event) => this.handleInputChange(event)}
              />
            </div>
            <div className="gap20"></div>
            <div className="tabsHolder radioTabs">
              <label className="fakeLabel">Video Quality</label>
              <br />
              <RadioTag
                key={this.state.keyEditVideoQuality}
                onCheckChange={this.onSelectVideoQuality.bind(this)}
                labelList={[
                  { name: "1080p", value: "1080p" },
                  { name: "720p", value: "720p" },
                  { name: "320p", value: "320p" },
                ]}
                id={"editVideoQuality"}
                selectedList={this.state.editVideoQuality}
                selectType={"value"}
                type={"multiple"}
              />
            </div>
            <div className="gap20"></div>
            {this.state.videoStatus !== "pending" && (
              <>
                <Checkbox
                  label={"Process this video again"}
                  isChecked={this.state.markAsPending}
                  handleCheckboxChange={this.toggleMarkPending}
                  key={this.state.keyOwnVideo}
                />
                <div className="gap10" />
                <label>
                  Note: Processing this video again will cause the video to stop
                  working while we reprocess it and is not recommended unless
                  you had an issue with the video.
                </label>
                <div className="gap20" />
              </>
            )}
          </div>

          <div className="alertFooterBtns">
            <button
              className="btn btn-default dismissThisModal margin-right-10"
              onClick={this.onClickCancelFromVideoTitleModel.bind(this)}>
              Cancel
            </button>
            <button
              className="btn btn-primary dismissThisModal"
              onClick={this.onClickSubmitFromVideoTitleModel.bind(this)}>
              Submit
            </button>
          </div>
        </div>
      </div>
    );
  }

  onClickCancelFromThumbVideoModel() {
    this.setState({ selectedVideo: {} }, () => {
      $(
        ".modalAlertOverlay.thumbVideoModal,.modalAlert.thumbVideoAlert"
      ).hide();
    });
  }

  async onClickScreenshotFromThumbVideoModel() {
    let video = this.state.selectedVideo;
    let time = this.player.currentTime();
    let url = `https://videoprocessing.memberpages.app/generate-video-thumb.php?skey=${video.s_key}&key=${video.key}&duration=${time}`;
    $(".modalAlertOverlay.thumbVideoModal,.modalAlert.thumbVideoAlert").hide();
    try {
      var response = await fetch(url);
      showNotification("success", "Screenshot done!");
    } catch (error) {
      console.log(error);
    }
  }

  renderThumbVideoModel() {
    let video = this.state.selectedVideo;
    if (!video._id) {
      return;
    }
    let videoUrl = this.getVideoUrl(video, "select");
    let modalHeight = window.innerHeight - 450;

    return (
      <div>
        <div className="modalAlertOverlay thumbVideoModal" />
        <div className="modalAlert thumbVideoAlert">
          <p>Screenshot Video</p>
          <div className="container-fluid">
            <Scrollbars
              autoHide
              autoHideTimeout={1000}
              autoHideDuration={200}
              style={{ height: modalHeight }}>
              <EmbedVideo
                aspectRatio="16:9"
                player={"member-pages"}
                url={videoUrl}
                onPlayerChange={(player) => {
                  this.player = player;
                }}
              />
            </Scrollbars>
          </div>

          <div className="alertFooterBtns">
            <button
              className="btn btn-default dismissThisModal margin-right-10"
              onClick={this.onClickCancelFromThumbVideoModel.bind(this)}>
              Close
            </button>
            <button
              className="btn btn-primary dismissThisModal margin-right-10"
              onClick={this.onClickScreenshotFromThumbVideoModel.bind(this)}>
              Use this Screenshot
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderSelectVideo() {
    return (
      <div className="list-group-item">
        {this.state.showVideoCmp && false && (
          <>
            <label>Select Type</label>
            <div className="tabsHolder radioTabs">
              <RadioTag
                onCheckChange={this.onSelectVideoType.bind(this)}
                labelList={[
                  {
                    name: "Manual",
                    value: "manual",
                  },
                ]}
                id={"selectVideoType"}
                selectedList={[this.state.selectVideoType]}
                selectType={"value"}
                key={this.state.keyVideoType}
              />
            </div>
          </>
        )}
        {this.state.selectVideoType === "youtube" && (
          <>
            <div className="row">
              <div className="col-sm-5">
                <div
                  className={
                    this.state.videoTitle !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Video Title</label>
                  <input
                    type="text"
                    name="videoTitle"
                    value={this.state.videoTitle}
                    onChange={(event) => this.handleInputChange(event)}
                  />
                </div>
                <div className="gap20" />
              </div>
              <div className="col-sm-5">
                <div
                  className={
                    this.state.videoFullUrl !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Video URL</label>
                  <input
                    type="text"
                    className="videoFullUrl"
                    name="videoFullUrl"
                    value={this.state.videoFullUrl}
                    onChange={(event) => this.handleInputChange(event)}
                  />
                </div>
                <div className="gap20" />
              </div>
            </div>
            <>
              <lable>
                We can download your video from youtube and host it for you. You
                must first upload your video to your youtube and paste the url
                above.
              </lable>
              <div className="gap10" />
              <Checkbox
                label={"I own or have copyright access to the video above."}
                isChecked={this.state.isOwnVideo}
                handleCheckboxChange={this.toggleOwnVideoCheckbox}
                key={this.state.keyOwnVideo}
              />
            </>
          </>
        )}
        {this.state.selectVideoType === "manual" && (
          <>
            <div>
              {this.state.videoIndices.map((i) => (
                <div className="row">
                  {this.state.showVideoCmp && (
                    <div className="col-sm-5">
                      <div
                        className={
                          this.state.videoInfo[i].title !== ""
                            ? "mdInput mdFocussed"
                            : "mdInput"
                        }>
                        <label>Video Title</label>
                        <input
                          type="text"
                          name="videoTitle"
                          value={this.state.videoInfo[i].title}
                          onChange={(event) =>
                            this.handleVideoTitleChange(event, i)
                          }
                        />
                      </div>
                      <div className="gap20" />
                    </div>
                  )}
                  <div className="col-sm-5">
                    <div className="gap20" />
                    <div className="gap20" />
                    <input
                      type="file"
                      name="FileUpload"
                      onChange={this.onVideoFileChange.bind(this, i)}
                    />
                  </div>
                  {i !== 1 && (
                    <div className="col-sm-2">
                      <div className="gap20" />
                      <div className="gap20" />
                      <button
                        className="btn btn-danger btn-sm"
                        onClick={this.onClickAddDeleteVideo.bind(this, i)}>
                        <i className="fa fa-close"></i>
                      </button>
                    </div>
                  )}
                </div>
              ))}
              {this.state.showVideoCmp && (
                <>
                  <button
                    className="btn btn-default btn-sm"
                    onClick={this.onClickAddNewVideo.bind(this)}>
                    Add Video
                  </button>
                  <br />
                  <br />
                </>
              )}
              {this.state.showVideoCmp && (
                <>
                  <label>Max Filesize Limit : 3GB</label>
                  <br />
                  {this.state.fileProgress && (
                    <label>
                      Uploading Video...&nbsp;&nbsp; {this.state.fileProgress}
                    </label>
                  )}
                </>
              )}
            </div>
          </>
        )}
        {this.state.showVideoCmp && (
          <div className="pull-right">
            {this.state.isImageUploading ? (
              <button className="btn btn-primary">
                <img
                  src={`https://${window.location.host}/images/loading3.gif`}
                  alt="loading"
                />
                &nbsp;&nbsp; Uploading Video....
              </button>
            ) : (
              <button
                className="btn btn-primary"
                onClick={this.onClickUploadVideo.bind(this)}>
                Upload Video
              </button>
            )}

            <div className="gap20" />
          </div>
        )}
      </div>
    );
  }

  render() {
    let checkboxImages = this.state.checkboxImages;
    let showMassActions = 0;
    Object.values(checkboxImages).forEach((value) => {
      if (value.value === true) {
        showMassActions += 1;
      }
    });

    return (
      <div>
        {this.renderSettingsModal()}
        {this.renderAddTagModal()}
        {this.renderAddMultipleTagsModal()}
        {this.renderImportImageModal()}
        {this.renderConfirmationAlert()}
        {this.renderReplaceImageModal()}
        {this.renderUpdateVideoModel()}
        {this.renderThumbVideoModel()}
        {this.renderMultiImageConfirmationAlert()}
        {this.renderDeleteFileConfirmationAlert()}
        {this.renderDeleteVideoConfirmationAlert()}
        {this.state.dokaEnabled && (
          <DokaModal
            utils={["crop", "filter", "color", "markup", "resize"]}
            outputData={true}
            src={imageUrl(this.state.selectedImage.key)}
            labelButtonConfirm={"Save Image"}
            onConfirm={this.handleDokaConfirm.bind(this)}
            onCancel={this.handleDokaCancel.bind(this)}
          />
        )}
        <div className="container-fluid">
          {this.state.type !== "select" && (
            <div className="clearfix">
              <div className="pull-left">
                <h3 className="noMargin firstLetter">Images / Files</h3>
              </div>
              {this.state.tempImageCount > 0 && (
                <div className="pull-right" style={{ marginRight: 15 }}>
                  <button
                    className="btn btn-primary"
                    onClick={this.onClickImportImages.bind(this)}>
                    Import
                  </button>
                </div>
              )}
            </div>
          )}
          <div className="gap20" />
          <div className="card">
            <div className="list-group list-group-flush">
              <div className="list-group-item">
                <div className="radioBHolder">
                  <div className="tabsHolder radioTabs">
                    <RadioTag
                      key={this.state.keyFileTypes}
                      onCheckChange={this.onSelectType.bind(this)}
                      labelList={[
                        { name: "Images", value: "images" },
                        { name: "Files", value: "files" },
                        { name: "Videos", value: "videos" },
                      ]}
                      id={"fileTypes"}
                      selectedList={[this.state.fileTypes]}
                      selectType={"value"}
                    />
                  </div>
                </div>
              </div>

              {this.state.fileTypes === "videos" &&
                this.state.type === "select" && (
                  <div className="list-group-item">
                    <div className="radioBHolder">
                      <div className="tabsHolder radioTabs">
                        <RadioTag
                          key={this.state.keyVideosType}
                          onCheckChange={this.onSelectVideosType.bind(this)}
                          labelList={[
                            { name: "Youtube", value: "youtube" },
                            { name: "Vimeo", value: "vimeo" },
                            { name: "Upload Video", value: "upload" },
                          ]}
                          id={"videosType"}
                          selectedList={[this.state.videosType]}
                          selectType={"value"}
                        />
                      </div>
                    </div>
                  </div>
                )}

              {this.state.videosType !== "upload" &&
                this.state.fileTypes === "videos" && (
                  <div className="list-group-item">
                    <div className="row">
                      <div className="col-sm-5">
                        <div
                          className={
                            this.state.videosUrl !== ""
                              ? "mdInput mdFocussed"
                              : "mdInput"
                          }>
                          <label>Video URL</label>
                          <input
                            type="text"
                            className="videosUrl"
                            name="videosUrl"
                            value={this.state.videosUrl}
                            onChange={(event) => this.handleInputChange(event)}
                          />
                        </div>
                        <div className="gap20" />
                      </div>
                      <div className="col-sm-5">
                        <div className="">
                          <div className="gap20" />
                          <button
                            className="btn btn-primary"
                            onClick={this.onClickAddVideo.bind(this)}>
                            Add
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

              {(this.state.fileTypes === "images" ||
                this.state.fileTypes === "files") && (
                <div
                  className="list-group-item pointer-click"
                  onClick={this.onClickShowImageUpload.bind(this)}>
                  <p className="noMargin pull-left firstLetter">
                    Upload{" "}
                    {this.state.fileTypes === "images" ? "Image" : "File"}
                    &nbsp;&nbsp;
                    <i
                      className={"fa fa-upload"}
                      style={{ color: "#26ace2" }}
                      aria-hidden="true"
                    />
                  </p>
                </div>
              )}

              {this.state.fileTypes === "videos" &&
                this.state.videosType === "upload" && (
                  <div
                    className="list-group-item pointer-click"
                    onClick={this.onClickShowImageUpload.bind(this)}>
                    <p className="noMargin pull-left firstLetter">
                      Upload Video &nbsp;&nbsp;
                      <i
                        className={"fa fa-upload"}
                        style={{ color: "#26ace2" }}
                        aria-hidden="true"
                      />
                    </p>
                  </div>
                )}

              <div className="list-group list-group-flush">
                {this.state.fileTypes === "images" &&
                  // <div className="list-group-item">
                  //   <ImageUpload
                  //     key={this.state.keyImageUpload}
                  //     thumbnailAlt={"Upload Image"}
                  //     onChangeImage={this.onChangeImage.bind(this)}
                  //     type={"normal"}
                  //     preserveRatio={true}
                  //     height={260}
                  //     file={this.state.image}
                  //     hideInput={this.state.isImageUploading}
                  //   />
                  // </div>
                  this.renderSelectImage()}
                {this.state.fileTypes === "files" && this.renderSelectFile()}
                {this.state.fileTypes === "videos" &&
                  this.state.videosType === "upload" &&
                  this.renderSelectVideo()}
              </div>
              <div className="gap20" />
            </div>
          </div>
          {this.state.fileTypes === "images" && (
            <SearchImages
              refreshImages={() => this.getAwsImages(this.state.limit)}
            />
          )}
          <div className="card">
            <div className="list-group list-group-flush">
              {this.state.fileTypes === "images" && (
                <div className="list-group-item">
                  <p>Filter by tag</p>
                  <div className="tabsHolder radioTabs callProgressTimeline">
                    <RadioTag
                      onCheckChange={this.onClickTagFilter.bind(this)}
                      labelList={this.state.tagList}
                      selectType={"value"}
                      selectedList={["All"]}
                      key={this.state.keyTagFilter}
                    />
                  </div>
                </div>
              )}
              <VelocityTransitionGroup
                enter={{ animation: "slideDown" }}
                leave={{ animation: "slideUp" }}>
                {showMassActions > 0 && (
                  <div className="list-group-item">
                    <p>Mass Actions</p>
                    <button
                      className="btn btn-sm btn-default margin-right-10"
                      onClick={this.onClickAddMultiTags.bind(this)}>
                      Add Tags
                    </button>
                    <button
                      className="btn btn-sm btn-danger"
                      onClick={this.onClickDeleteMultiImages.bind(this)}>
                      Delete Images
                    </button>
                  </div>
                )}
              </VelocityTransitionGroup>
              {this.state.images.length > 0 &&
                this.state.fileTypes === "images" && (
                  <div className="list-group-item">
                    <div className="row">
                      {this.state.images.map((i) => (
                        <div className="col-sm-3" key={i.key}>
                          <div className="row">
                            <div className="col-sm-8">
                              <img
                                onClick={this.onClickSettingImage.bind(this, i)}
                                src={this.getImageUrl(
                                  i.thumbnail_key ? i.thumbnail_key : i.key
                                )}
                                className="btn-block pointer-click editorGalleryImage"
                                alt="ImageUrl"
                                data-toggle="tooltip"
                                title={
                                  "<img style='max-width:600px; max-height:400px; object-fit: contain;' src='" +
                                  this.getImageUrl(i.key ? i.key : i.key) +
                                  "' />"
                                }
                              />
                              <div className="gap10" />
                              {this.state.type !== "select" ? (
                                <>
                                  <div className="float-left">
                                    <Checkbox
                                      label={
                                        (i.variant ? i.variant : "") +
                                        " - " +
                                        i.size
                                          ? bytesToSize(i.size)
                                          : ""
                                      }
                                      isChecked={
                                        this.state.checkboxImages[i._id].value
                                      }
                                      handleCheckboxChange={this.toggleCheckbox.bind(
                                        this,
                                        i
                                      )}
                                      key={this.state.checkboxImages[i._id].key}
                                    />
                                  </div>
                                  <br />

                                  <div className="gap10" />
                                  <div
                                    onClick={this.onClickAddTag.bind(this, i)}
                                    className="pointer-click float-left">
                                    <span
                                      className="badge badge-pill badge-primary border-0"
                                      style={{ marginRight: 5 }}>
                                      <i className="fa fa-plus" />
                                    </span>
                                    {i.ftags &&
                                      i.ftags.map((j) => (
                                        <span
                                          key={Math.random().toString()}
                                          className="badge badge-pill badge-secondary"
                                          style={{ marginRight: 5 }}>
                                          {j.name.toLowerCase()}
                                        </span>
                                      ))}
                                  </div>
                                  <br />
                                  <div className="gap10" />
                                  <div>
                                    <div className="margin-right-10 float-left">
                                      {i.variations.length > 0 ? (
                                        <button
                                          className="btn btn-sm btn-default border-0"
                                          onClick={this.onClickCopyImageUrl.bind(
                                            this,
                                            i
                                          )}>
                                          <i className="fa fa-copy" />
                                        </button>
                                      ) : (
                                        <CopyToClipboard
                                          text={this.getVarImageUrl(i.key)}
                                          onCopy={() =>
                                            showNotification(
                                              "success",
                                              "Image url copied to clipboard",
                                              5000
                                            )
                                          }>
                                          <button className="btn btn-sm btn-default border-0 margin-right-10">
                                            <i className="fa fa-copy" />
                                          </button>
                                        </CopyToClipboard>
                                      )}
                                    </div>
                                    {i.imageLocked && (
                                      <div className="margin-right-10 float-left">
                                        {/*eslint-disable-next-line*/}
                                        <a className="btn btn-sm btn-default border-0">
                                          <i className="fa fa-lock" />
                                        </a>
                                      </div>
                                    )}
                                    <div className="margin-right-10 float-left">
                                      {/*eslint-disable-next-line*/}
                                      <a
                                        className="btn btn-sm btn-danger border-0"
                                        onClick={this.onClickDeleteImage.bind(
                                          this,
                                          i
                                        )}>
                                        <i className="fa fa-trash" />
                                      </a>
                                    </div>
                                    {i.variations.length === 0 && (
                                      <div className="margin-right-10 float-left">
                                        {/*eslint-disable-next-line*/}
                                        <a
                                          className="btn btn-sm btn-default border-0"
                                          onClick={this.onClickReplaceImage.bind(
                                            this,
                                            i
                                          )}>
                                          <i className="fa fa-upload" />
                                        </a>
                                      </div>
                                    )}
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="">
                                    {(i.variant ? i.variant : "") +
                                    " - " +
                                    i.size
                                      ? bytesToSize(i.size)
                                      : ""}
                                  </div>
                                  <div className="gap10" />
                                  <button
                                    className="btn btn-default btn-sm"
                                    onClick={this.onClickSelectImage.bind(
                                      this,
                                      i
                                    )}>
                                    Select
                                  </button>
                                </>
                              )}
                            </div>
                          </div>
                          <div className="gap30" />
                        </div>
                      ))}
                    </div>
                    {this.state.hasMore && this.state.images.length >= 30 && (
                      <div className="text-center">
                        <button
                          className="btn btn-primary"
                          onClick={this.fetchMoreData}>
                          Load More
                        </button>
                      </div>
                    )}
                  </div>
                )}
              {this.state.awsFiles.length > 0 &&
                this.state.fileTypes === "files" && (
                  <div className="list-group list-group-flush">
                    <div className="list-group-item">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>URL</th>
                            <th>File Name</th>
                            <th>Size</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>{this.renderFiles()}</tbody>
                      </table>
                    </div>
                  </div>
                )}
              {this.state.awsVideos.length > 0 &&
                this.state.fileTypes === "videos" && (
                  <div className="list-group list-group-flush">
                    <div className="list-group-item">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>Title</th>
                            <th>Duration</th>
                            <th>Type</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>{this.renderVideos()}</tbody>
                      </table>
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EditorImages);
