import { getItem } from "../helpers/storage";

class FetchService {
  request(options, params, auth) {
    let fetchOptions = Object.assign({}, options);

    let token = getItem("accessToken");
    if (token) {
      fetchOptions.headers["Authorization"] = `Bearer ${token}`;
    }
    let path = window.location.pathname;
    if (path.includes("member")) {
      fetchOptions.headers["mtype"] = "m";
    }

    if (auth === false) {
      fetchOptions.headers["auth"] = "false";
    }

    fetchOptions.headers = Object.assign(
      {
        Accept: "application/json",
        domainid: window.location.hostname,
      },
      fetchOptions.headers
    );

    if (options.body) {
      fetchOptions.body = JSON.stringify(options.body);
    }

    return fetch(options.url, fetchOptions)
      .then(this.checkStatus)
      .then((response) => {
        if (response.status === 204) {
          return null;
        }
        return response.json();
      });
  }

  checkStatus(response) {
    if (response.ok) {
      return response;
    }
    return response.json().then((error) => {
      error.response = response;
      throw error;
    });
  }
}

export default FetchService;
