import {
  CREATE_BTP_QUESTIONNAIRE_SUCCESS,
  CREATE_BTP_QUESTIONNAIRE_FAILED
} from "../types";

import config from "../../helpers/config";

import { callCreateBtpQuestionnaire } from "../../services/index";

export const createBtpQuestionnaire = questionnaireData => {
  return async dispatch => {
    return callCreateBtpQuestionnaire(questionnaireData)
      .then(data => {
        dispatch({
          type: CREATE_BTP_QUESTIONNAIRE_SUCCESS,
          message: config.messages.createChlgQueSuccess,
          success: true
        });
      })
      .catch(err => {
        dispatch({
          type: CREATE_BTP_QUESTIONNAIRE_FAILED,
          message: config.messages.createChlgQueError,
          success: false
        });
      });
  };
};
