import React, { Component } from "react";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { convertToHTML } from "draft-convert";

import DraftCustomOption from "./customOption";

import DraftCustomColor from "./draftCustomColor";
import { getUniqueId } from "../mp-content-editor/helpers";

export class DraftEditor extends Component {
  constructor(props) {
    super(props);
    this.props = props;

    const html = this.props.html;
    var json = this.props.json || null;
    const textAlign = this.props.textAlign || "center";

    let minHeight = this.props.lineHeight
      ? this.props.lineHeight || "16px"
      : "16px";

    if (minHeight === "inherit") {
      minHeight = "16px";
    }

    var lineHeight = "";

    if (this.props.lineHeight) {
      lineHeight = this.props.lineHeight || "inherit";
    }

    let defaultText = this.props.defaultText ? this.props.defaultText : "";

    var editorState = EditorState.createEmpty();

    if (json) {
      if (!json.hasOwnProperty("entityMap")) {
        json["entityMap"] = {};
      }

      let blocks = json.blocks || [];
      blocks.forEach((element) => {
        element.key = getUniqueId();
      });
      json.blocks = blocks;
    }

    let jsonContent = json
      ? json
      : {
          blocks: [
            {
              key: getUniqueId(),
              text: defaultText,
              type: "unstyled",
              depth: 0,
              entityRanges: [],
              data: { textAlign: textAlign },
            },
          ],
          entityMap: {},
        };

    const contentState = convertFromRaw(jsonContent);
    editorState = EditorState.createWithContent(contentState);

    this.state = {
      selected: this.props.selected,
      editorState: editorState,
      html: html,
      minHeight: minHeight,
      lineHeight: lineHeight,
      cType: this.props.cType || "editor",
      textAlign: textAlign,
      suggestions: this.props.suggestions || [],
      key: this.props.key || Math.random(),
    };
  }

  componentDidMount() {
    // this.onEditorStateChange(this.state.editorState);
  }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });

    const html = convertToHTML({
      styleToHTML: (style) => {
        if (style.indexOf("STRIKETHROUGH") !== -1) {
          return <span style={{ textDecoration: "line-through" }} />;
        }
        if (style.indexOf("fontsize") !== -1) {
          let fontSize = style.replace("fontsize-", "");
          return (
            <span
              style={{
                fontSize: fontSize + "px",
                display: "inline-block",
                minHeight: fontSize + "px",
              }}
            />
          );
        }
        if (style.indexOf("color-rgb") !== -1) {
          let color = style.replace("color-rgb", "rgb");
          return <span style={{ color }} />;
        } else if (style.indexOf("color-") !== -1) {
          let color = style.replace("color-", "");
          return <span style={{ color }} />;
        }
      },
      blockToHTML: (block) => {
        // Make it so text aligns remains for non-content editor items but remove it for the content editor so it works smoothly
        if (this.state.cType !== "task") {
          delete block.data["text-align"]; // We are going to remove any text align that could have been set from within the editor
        }

        const newBlock = {
          ...block.data,
          minHeight: this.state.minHeight,
        };
        if (this.state.lineHeight) {
          newBlock.lineHeight = this.state.lineHeight;
        }
        if (block.type === "unstyled" || block.type === "PARAGRAPH") {
          return <div style={newBlock} />;
        }
        if (block.type === "header-one") {
          /*eslint-disable*/
          return <h1 style={newBlock} />;
          /*eslint-enable*/
        }
        if (block.type === "header-three") {
          /*eslint-disable*/
          return <td style={newBlock} />;
          /*eslint-enable*/
        }
        // Function to convert style object to inline style string
        const styleObjectToString = (styleObject) => {
          return Object.entries(styleObject)
            .map(([key, value]) => {
              const kebabKey = key.replace(/([A-Z])/g, "-$1").toLowerCase();
              return `${kebabKey}: ${value};`;
            })
            .join(" ");
        };
        const inlineStyleString = styleObjectToString(newBlock);

        if (block.type === "ordered-list-item") {
          /*eslint-disable*/
          return {
            start: `<li class="mpOrderedListItem" style="${inlineStyleString}">`,
            end: "</li>",
            nest: '<ol class="mpOrderedList">',
            nestStart: "<ol>",
            nestEnd: "</ol>",
          };
          /*eslint-enable*/
        }
        if (block.type === "unordered-list-item") {
          /*eslint-disable*/
          return {
            start: `<li class="mpUnorderedListItem" style="${inlineStyleString}">`,
            end: "</li>",
            nest: '<ol class="mpUnorderedList">',
            nestStart: "<ol>",
            nestEnd: "</ol>",
          };
          /*eslint-enable*/
        }
      },
      entityToHTML: (entity, originalText) => {
        if (entity.type === "LINK") {
          // return <a href={entity.data.url}>{originalText}</a>;
          let targetOption = entity.data.targetOption;
          if (targetOption === "_self") {
            targetOption = "_parent";
          }

          return (
            <a target={targetOption} href={entity.data.url}>
              {originalText}
            </a>
          );
        }
        return originalText;
      },
    })(editorState.getCurrentContent());

    let json = JSON.parse(
      JSON.stringify(convertToRaw(editorState.getCurrentContent()))
    );
    let text = editorState.getCurrentContent().getPlainText();
    this.props.onChange(html, json, text);
  };

  render() {
    const { editorState, contentState } = this.state;

    let customOptions = [];
    if (this.state.cType === "editor") {
      customOptions = [<DraftCustomOption />];
    }
    let mention = {};
    let options = [
      "inline",
      "list",
      "blockType",
      "fontSize",
      "colorPicker",
      "link",
      "emoji",
      "remove",
      "history",
    ];
    if (this.state.cType === "task") {
      options = [
        "inline",
        "list",
        "blockType",
        "fontSize",
        "textAlign",
        "colorPicker",
        "link",
        "emoji",
        "remove",
        "history",
      ];
      mention = {
        separator: " ",
        trigger: "@",
        suggestions: this.state.suggestions,
      };
    }
    return (
      <div>
        <Editor
          key={this.state.key}
          toolbarOnFocus
          // defaultContentState={editorState}
          editorState={editorState}
          // contentState={contentState}
          initialContentState={contentState}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          onEditorStateChange={this.onEditorStateChange}
          stripPastedStyles={true}
          toolbarCustomButtons={customOptions}
          mention={mention}
          toolbar={{
            options: options,
            blockType: {
              inDropdown: true,
              options: ["Normal", "H1", "H2", "H3"],
              className: undefined,
              component: undefined,
              dropdownClassName: undefined,
            },
            fontSize: {
              options: [
                8, 9, 10, 11, 12, 14, 16, 18, 22, 24, 26, 28, 30, 32, 34, 36,
                48, 60, 72, 96,
              ],
            },
            colorPicker: { component: DraftCustomColor },
            inline: {
              inDropdown: false,
              options: ["bold", "italic", "underline", "strikethrough"],
            },
            list: { inDropdown: false },
            textAlign: { inDropdown: false },
            link: { inDropdown: false },
            history: { inDropdown: false },
          }}
        />
      </div>
    );
  }
}
