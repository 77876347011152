import React, { Component } from "react";
import Autosuggest from "react-autosuggest";
import { callGetPageUsersByQuery } from "../../services";

class AutoSuggestAPI extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.value || "",
      placeholder: this.props.placeholder || "",
      selectedValue: {},
      data: this.props.data,
      suggestions: [],
      searchKey: this.props.searchKey || "name",
    };
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    });
    this.props.onChange(newValue);
  };

  onSuggestionsFetchRequested = async ({ value }) => {
    let data = await this.getSuggestions(value);
    this.setState({
      suggestions: data,
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  async getUsers(value) {
    let limit = 50;

    let query = {
      query: {
        page: 0,
        limit: limit,
        status: { $ne: "archive" },
      },
    };
    let regex = { $regex: value, $options: "i" };
    /*eslint-disable*/
    query.query["$or"] = [{ [this.state.searchKey]: regex }];
    /*eslint-enable*/

    let usersData = await callGetPageUsersByQuery(query, true, false);
    let users = usersData.data;
    return users;
  }

  getSuggestions = async (value) => {
    const inputValue = value.trim().toLowerCase();
    return await this.getUsers(inputValue);
  };

  getSuggestionValue = (suggestion) => {
    this.props.onSelect(suggestion);
    return suggestion[this.state.searchKey];
  };

  renderSuggestion = (suggestion) => {
    return (
      <div className="customSearchSuggestionItem noBorder">
        <div className="customSearchSuggestionInfo">
          {/* <p><img src={suggestion.image} alt="" /></p> */}
          <p>{suggestion[this.state.searchKey]}</p>
        </div>
      </div>
    );
  };

  render() {
    return (
      <Autosuggest
        suggestions={this.state.suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested.bind(
          this
        )}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested.bind(
          this
        )}
        getSuggestionValue={this.getSuggestionValue.bind(this)}
        renderSuggestion={this.renderSuggestion.bind(this)}
        inputProps={{
          value: this.state.value,
          onChange: this.onChange,
          placeholder: this.state.placeholder,
        }}
      />
    );
  }
}

export default AutoSuggestAPI;
