import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import $ from "jquery";
import moment from "moment-timezone";

import TitleBox from "../../../components/general/titleBox";
import FilterList from "../../../components/form/filterList";

import { filterByTags } from "../../../helpers/filters";
import {
  callDeleteLandingPageById,
  callUpdateLandingPage,
  callGetLandingPagesUsingQuery,
  callCreateLandingPage,
  callGetLandingPageById,
} from "../../../services";
import { SettingsHelper } from "../../../helpers/settings";
import ScriptPopup from "../../../components/general/scriptPopup";
import { getUniqueId, showNotification, slugify } from "../../../helpers";
import { getUpdatedEditorContent } from "../../../components/editor/processEditor";
import ExportContent from "../../../helpers/exportContent";
import { getItem, removeItem, setItem } from "../../../helpers/storage";

class ViewLandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      pages: [],
      pagesBackup: [],
      filterList: [],
      selectedPage: {},
      accountKey: "",
      domainList: [],
      scriptData: {},
      showScript: false,
      pageName: "",
      keyScript: Math.random(),
      keyExport: Math.random(),
      logQuery: false,
      searchQuery: "",
    };
  }

  componentDidMount() {
    this.getPages();
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  getPages() {
    let query = {
      query: {
        status: { $ne: "archive" },
        type: { $ne: "eventThankYou" },
        $select: ["-content_html", "-content_object"],
      },
    };
    callGetLandingPagesUsingQuery(query).then((d) => {
      let settingsHelper = new SettingsHelper();
      let accountKey = settingsHelper.getAccountKey();
      let domainList = settingsHelper.getDomainList();

      let pageList = d.data;
      pageList = pageList.filter((d) => !d.sub_id);

      this.setState(
        {
          pages: pageList,
          pagesBackup: pageList,
          keyExport: Math.random(),
          accountKey,
          domainList,
        },
        () => {
          let lq = getItem("lq");
          if (lq) {
            let query = JSON.parse(lq);
            let isDate = moment().isAfter(
              moment(query.date).add("5", "minutes"),
              "minutes"
            );
            if (!isDate) {
              let search = query.filterList.search;
              this.filterUpdated(
                search.values,
                "search",
                "search",
                search.searchFields
              );
              this.setState({
                keyFilter: Math.random(),
                searchQuery: query.searchQuery,
                filterList: query.filterList,
              });
            } else {
              removeItem("lq");
            }
          }
        }
      );
    });
  }

  onClickEditPage = (page) => {
    this.props.history.push(
      `/admin/add-landing-page?id=${page._id}&tab=content`
    );
  };

  onClickCloneLandingPage = (page) => {
    callGetLandingPageById(page._id).then((pageInfo) => {
      this.setState({ selectedPage: pageInfo }, () => {
        $(".modalAlertOverlay.pageModalOverlay,.modalAlert.pageModal").fadeIn(
          200
        );
      });
    });
  };

  onClickPreviewLandingPage = (page) => {
    if (page.url.length > 0) {
      let domainList = this.state.domainList;
      let landingUrls = domainList.filter((d) => {
        return d.type === "landing";
      });
      let defaultDomain = "";
      domainList.forEach((domain) => {
        if (domain.type === "default") {
          defaultDomain = domain.domain;
        }
      });
      let subDomain = defaultDomain.split(".")[0] || "";

      let landingUrl = `https://site.memberpages.app/${subDomain}/${page.url}`;
      if (landingUrls.length > 0) {
        landingUrl = `https://${landingUrls[0].domain}/${page.url}`;
      }
      window.open(landingUrl, "_blank");
    }
  };

  onClickSetting = (page) => {
    let scriptData = page.script_data || {};
    this.setState(
      {
        selectedPage: page,
        scriptData: scriptData,
        showScript: true,
        keyScript: Math.random(),
      },
      () => {
        $(
          ".modalAlertOverlay.scriptSettingModal,.modalAlert.scriptSettingAlert"
        ).fadeIn(200);
      }
    );
  };

  onClickDeletePage = (page) => {
    this.setState({ selectedPage: page }, () => {
      $(".modalAlertOverlay.deleteOverlay,.modalAlert.deleteAlert").fadeIn(200);
    });
  };

  filterUpdated(updatedFilter, name, type, searchFields) {
    let filterList = this.state.filterList;
    filterList[name] = {
      name: name,
      type: type,
      searchFields: searchFields,
      values: updatedFilter,
    };

    let searchType = filterList.search;
    let searchQuery = "";
    if (searchType) {
      let values = searchType.values[0];
      searchQuery = values.value;
    }

    this.setState(
      {
        filterList: filterList,
      },
      () => {
        let data = filterByTags(this.state.pagesBackup, filterList);
        this.setState({
          pages: data,
          logQuery: true,
        });
        if (this.state.logQuery) {
          setItem(
            "lq",
            JSON.stringify({
              date: new Date(),
              filterList: Object.assign({}, filterList),
              searchQuery: searchQuery,
            })
          );
        }
      }
    );
  }

  onClickArchivePage = (page) => {
    this.setState({ selectedPage: page }, () => {
      $(
        ".modalAlertOverlay.confirmArchiveOverlay,.modalAlert.confirmArchive"
      ).fadeIn(200);
    });
  };

  onClickUnarchivePage = async (page) => {
    await callUpdateLandingPage(page._id, {
      status: "unarchived",
    });
    this.getPages();
  };

  onClickNoArchiveAlert = () => {
    $(
      ".modalAlertOverlay.confirmArchiveOverlay,.modalAlert.confirmArchive"
    ).fadeOut(200);
  };

  onClickYesArchiveAlert = () => {
    callUpdateLandingPage(this.state.selectedPage._id, {
      status: "archive",
    }).then((d) => {
      $(
        ".modalAlertOverlay.confirmArchiveOverlay,.modalAlert.confirmArchive"
      ).fadeOut(200);
      this.getPages();
    });
  };

  renderArchiveAlert() {
    let selectedPage = this.state.selectedPage;
    let name = "";
    if (selectedPage.name) {
      name = selectedPage.name;
    }
    return (
      <div>
        <div className="modalAlertOverlay confirmArchiveOverlay" />
        <div className="modalAlert confirmArchive">
          <p>{`Are you sure you want to archive "${name}" landing page?`}</p>
          <div className="alertFooterBtns">
            <button
              className="btn btn-default closeModal-No margin-right-10"
              onClick={this.onClickNoArchiveAlert}>
              No
            </button>
            <button
              className="btn btn-primary closeModal-Yes"
              onClick={this.onClickYesArchiveAlert}>
              Yes
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderPages() {
    var returnVals = [];
    let items = this.state.pages;
    items.forEach((element) => {
      returnVals.push(
        <tr key={element._id}>
          <td>{element.name}</td>
          {/* <td>{element.description}</td> */}
          <td>
            <label
              className="link-share"
              onClick={this.onClickPreviewLandingPage.bind(this, element)}>
              {element.url ? "/" : ""}
              {element.url}
            </label>
          </td>
          <td>{moment(element.updatedAt).format("Do MMM YYYY hh:mm A")}</td>
          <td className="min-width-100">
            <button
              title="Edit"
              onClick={this.onClickEditPage.bind(this, element)}
              className="btn btn-default btn-sm border-0 margin-right-10">
              <i className="fa fa-pencil" />
            </button>
            <button
              title="Preview"
              onClick={this.onClickPreviewLandingPage.bind(this, element)}
              className="btn btn-default btn-sm border-0 margin-right-10">
              <i className="fa fa-share-square-o" />
            </button>
            <button
              title="Clone"
              onClick={this.onClickCloneLandingPage.bind(this, element)}
              className="btn btn-default btn-sm border-0">
              <i className="fa fa-clone" />
            </button>
            <button
              title="Setting"
              onClick={this.onClickSetting.bind(this, element)}
              className="btn btn-default btn-sm border-0 margin-right-10">
              <i className="fa fa-cog" />
            </button>

            <button
              title="Archive"
              onClick={this.onClickArchivePage.bind(this, element)}
              className="btn btn-danger btn-sm  border-0">
              <i className="fa fa-archive" />
            </button>
          </td>
        </tr>
      );
    });
    return returnVals;
  }

  onClickNoAlert = () => {
    $(".modalAlertOverlay.deleteOverlay,.modalAlert.deleteAlert").fadeOut(200);
  };

  onClickYesAlert = () => {
    callDeleteLandingPageById(this.state.selectedPage._id).then((d) => {
      $(".modalAlertOverlay.deleteOverlay,.modalAlert.deleteAlert").fadeOut(
        200
      );
      this.getPages();
    });
  };

  renderConfirmationAlert() {
    let selectedPage = this.state.selectedPage;
    let name = "";
    if (selectedPage.name) {
      name = selectedPage.name;
    }
    return (
      <div>
        <div className="modalAlertOverlay deleteOverlay" />
        <div className="modalAlert deleteAlert">
          <p>{`Are you sure you want to delete "${name}" landing page?`}</p>
          <div className="alertFooterBtns">
            <button
              className="btn btn-default closeModal-No margin-right-10"
              onClick={this.onClickNoAlert}>
              No
            </button>
            <button
              className="btn btn-primary closeModal-Yes"
              onClick={this.onClickYesAlert}>
              Yes
            </button>
          </div>
        </div>
      </div>
    );
  }

  onClickCancelFromPageModal() {
    $(".modalAlertOverlay.pageModalOverlay,.modalAlert.pageModal").fadeOut(200);
  }

  async onClickProcessFromPageModal() {
    if (this.state.pageName.length === 0) {
      showNotification("error", "Please enter page name", 4000);
      return;
    }

    $(".modalAlertOverlay.pageModalOverlay,.modalAlert.pageModal").fadeOut(200);

    let selectedPage = this.state.selectedPage;

    let subPages = selectedPage.sub_pages || [];
    let newSubPages = [];
    let newSubObject = {};
    let subPagesInfo = [];
    if (subPages.length > 0) {
      subPages.forEach((element) => {
        let object = {
          id: getUniqueId(),
          type: element.type,
        };
        newSubPages.push(object);
        newSubObject[element.id] = object;
      });

      /*eslint-disable*/
      let subPagesInfoData = await callGetLandingPagesUsingQuery({
        query: { sub_id: selectedPage._id },
      });
      subPagesInfo = subPagesInfoData.data || [];
      /*eslint-enable*/
    }

    /*eslint-disable*/
    let apiData = {
      name: this.state.pageName,
      description: selectedPage.description,
      url: slugify(this.state.pageName) + "-" + getUniqueId(),
      content_html: selectedPage.content_html,
      content_object: getUpdatedEditorContent(
        selectedPage.content_object,
        selectedPage.content_html
      ),
      content_type: selectedPage.content_type,
      script_data: selectedPage.script_data,
      sub_pages: newSubPages,
      theme: selectedPage.theme,
      rules: selectedPage.rules,
    };
    /*eslint-enable*/

    let d = await callCreateLandingPage(apiData);
    if (d._id) {
      if (subPagesInfo.length > 0) {
        subPagesInfo.forEach((element) => {
          delete element._id;
          element["sub_id"] = d._id;
          element["unique_id"] = newSubObject[element.unique_id].id;
          element.name = this.state.pageName;
          element.url = slugify(this.state.pageName) + "-" + getUniqueId();
        });
        await callCreateLandingPage(subPagesInfo);
      }
      this.setState(
        {
          selectedPage: {},
          pageName: "",
        },
        () => {
          showNotification(
            "success",
            "Landing Page created successfully",
            2000
          );
          this.getPages();
        }
      );
    } else {
      showNotification("error", "Something went wrong", 4000);
    }
  }

  renderNewLandingPageModal() {
    let name = "";
    if (this.state.selectedPage.name) {
      name = this.state.selectedPage.name;
    }
    return (
      <div>
        <div className="modalAlertOverlay pageModalOverlay" />
        <div className="modalAlert pageModal">
          <div className="clearfix">
            <div className="pull-left">
              <p>Clone Landing Page - {name}</p>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div
                  className={
                    this.state.pageName !== ""
                      ? "mdInput mdFocussed"
                      : "mdInput"
                  }>
                  <label>Page Name</label>
                  <input
                    type="text"
                    name="pageName"
                    value={this.state.pageName}
                    onChange={(event) => this.handleInputChange(event)}
                  />
                </div>
                <div className="gap10" />
              </div>
            </div>
          </div>
          <div className="gap10" />

          <div className="alertFooterBtns">
            {/*eslint-disable*/}
            <a
              onClick={this.onClickCancelFromPageModal.bind(this)}
              className="btn btn-default dismissThisModal margin-right-10">
              Cancel
            </a>
            <a
              onClick={this.onClickProcessFromPageModal.bind(this)}
              className="btn btn-primary dismissThisModal">
              Create Landing Page
            </a>
            {/*eslint-enable*/}
          </div>
        </div>
      </div>
    );
  }

  renderFilterView() {
    return (
      <>
        <div className="list-group-item">
          <FilterList
            key={this.state.keyFilter}
            label={"Search Pages..."}
            name={"search"}
            type={"search"}
            searchQuery={this.state.searchQuery}
            searchFields={["name", "description"]}
            onFinish={this.filterUpdated.bind(this)}
          />
        </div>
      </>
    );
  }

  onClickViewArchivedLandingPages() {
    this.props.history.push("/admin/view-archived-landing-pages");
  }

  onClickScriptSubmit = (data) => {
    /*eslint-disable*/
    let updateData = {
      script_data: data,
    };
    /*eslint-enable*/
    callUpdateLandingPage(this.state.selectedPage._id, updateData).then((d) => {
      this.getPages();
    });
  };

  render() {
    return (
      <div className="container-fluid">
        {this.state.showScript && (
          <ScriptPopup
            key={this.state.keyScript}
            script={this.state.scriptData}
            onSubmit={this.onClickScriptSubmit}
          />
        )}
        {this.renderConfirmationAlert()}
        {this.renderArchiveAlert()}
        {this.renderNewLandingPageModal()}
        <TitleBox title="Landing Pages Overview" />
        <div className="gap20" />
        <div className="card">
          <div className="list-group list-group-flush">
            {this.renderFilterView()}
            <div className="list-group-item">
              <button
                className="btn btn-default btn-sm"
                onClick={this.onClickViewArchivedLandingPages.bind(this)}>
                View Archived Landing Pages
              </button>
            </div>
            <div className="list-group-item">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Name</th>
                    {/* <th>Description</th> */}
                    <th>Url</th>
                    <th>Last Updated</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>{this.renderPages()}</tbody>
              </table>
            </div>
            <div className="list-group-item">
              <ExportContent
                key={this.state.keyExport}
                data={this.state.pages}
                keys={[
                  "name",
                  "url",
                  "description",
                  "createdAt",
                  "script_data.fbPixel",
                  "script_data.googlePixel",
                  "script_data.purchasePixel",
                  "script_data.pageTitle",
                  "script_data.socialMediaDesc",
                  "script_data.socialMediaImage",
                  "script_data.leadPixel",
                ]}
                screen={"landing-pages"}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ViewLandingPage);
