import React from "react";
import config from "../../helpers/config";
import {
  uploadImage,
  callCreateAwsImage,
  callResizeAwsImage,
} from "../../services";
import store from "../../modules/store";
import { SettingsHelper } from "../../helpers/settings";
import { imageUrl } from "../../helpers";

class UploadEditorImage extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      bucketName: "",
    };
  }

  componentDidMount() {
    this.setBucketName();

    store.subscribe(() => {
      let state = store.getState();
      if (state.generalReducer.userSettings._id) {
        this.setBucketName();
      }
    });
  }

  setBucketName() {
    let settingsHelper = new SettingsHelper();
    let bucketName = settingsHelper.getAwsBucketName();

    this.setState({ bucketName: bucketName });
  }

  uploadAwsImage(image, file, metaData) {
    let fileName = new Date().getTime();
    if (config.isDev === "true") {
      fileName = `dev-${fileName}`;
    }
    uploadImage(file, fileName).then((resp) => {
      resp.json().then((data) => {
        /*eslint-disable*/
        if (data.file_name) {
          let key = data.file_name;
          let fileName = key.substr(0, key.lastIndexOf("."));
          let extName = key.substr(key.lastIndexOf(".") + 1);
          let thumbKey = `${fileName}-thumbnail.${extName}`;

          let variantResize = `${metaData.width}x${metaData.height}`;

          let apiData = {
            key: key,
            file: fileName,
            thumbnail_key: thumbKey,
            date_modified: new Date(),
            bucket: this.state.bucketName,
            vendor: "aws",
            mime_type: metaData.mimeType,
            size: data.size,
            variant: variantResize,
            generic_tag_list: [],
            variations: [],
          };
          /*eslint-enable*/
          callCreateAwsImage(apiData).then((d) => {
            if (d._id) {
              //   this.prepareThumbImage(d);
              this.props.onChangeUrl(imageUrl(key));
            }
          });
        }
      });
    });
  }

  prepareThumbImage(image) {
    let resize = "x200";
    let data = {
      action: "resize-aws-image",
      data: {
        resize: resize,
        value: 200,
        url: imageUrl(image.key),
        key: image.key,
        thumbkey: image.thumbnail_key,
      },
    };
    callResizeAwsImage(data).then((d) => {
      this.props.onChangeUrl(imageUrl(image.key));
    });
  }

  onChangeFile(event) {
    event.stopPropagation();
    event.preventDefault();
    let reader = new FileReader();
    let file = event.target.files[0];
    reader.onloadend = () => {
      var image = new Image();
      image.src = reader.result;
      image.onload = () => {
        this.uploadAwsImage(image, file, {
          height: parseInt(image.height, 10),
          width: parseInt(image.width, 10),
          mimeType: file.type,
        });
      };
    };
    reader.readAsDataURL(file);
  }

  render() {
    return (
      <div>
        <input
          className="imageUpload"
          type="file"
          name="FileUpload"
          ref={this.props.imageRef}
          style={{ display: "none" }}
          onChange={this.onChangeFile.bind(this)}
        />
      </div>
    );
  }
}

export default UploadEditorImage;
