import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { arrayMove } from "react-sortable-hoc";
import DateTimePicker from "react-datetime-picker";

import TitleBox from "../../../components/general/titleBox";
import RadioTag from "../../../components/form/radioTag";
import PageContent from "../../../components/editor/pageContent";
import HelpTextDiv from "../../../components/form/helpTextDiv";
import TagSelect from "../../../components/form/tagSelect";
import SelectAddImage from "../../../components/form/selectAddImage";

import {
  callGetSystemTags,
  callCreateResource,
  callUpdateResource,
} from "../../../services";
import {
  getEditorType,
  isFS,
  showNotification,
  toPromise,
} from "../../../helpers";
import {
  SortableFileList,
  SortableVideoList,
} from "../../../components/form/sortableList";
import VideoPlayerNote from "../../../components/general/videoPlayerNote";
import Onboarding from "../onboarding/onboarding";
import { getItem } from "../../../helpers/storage";

class AddResource extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    let resource = {
      resourceId: 0,
      name: "",
      description: "",
      selectedTag: "",
      fileList: [],
      videoList: [],
      content: "",
      editorContent: {},
      selectedRules: [],
      releaseDate: new Date(),
      expiryDate: new Date(),
      editorType: isFS()
        ? "mp"
        : getItem("de") === "classic"
        ? "unlayer"
        : "mp",
      draftId: "",
      draftImage: "",
    };

    if (this.props.history.location.state !== undefined) {
      let rData = this.props.history.location.state.resource;

      resource.resourceId = rData._id;
      resource.name = rData.name;
      resource.description = rData.description;
      resource.fileList = rData.file_list;
      resource.videoList = rData.video_list;
      resource.content = rData.html || "";
      resource.editorContent = rData.content || {};

      resource.editorType = getEditorType(rData.html, rData.content);

      resource.draftId = rData.draft_id || "";
      resource.draftImage = rData.draft_image || "";

      if (rData.tag_info) {
        resource.selectedTag = rData.tag_info.name;
      }

      if (rData.release_date) {
        resource.releaseDate = new Date(rData.release_date);
        resource.selectedRules.push("release_date");
      }

      if (rData.expiry_date) {
        resource.expiryDate = new Date(rData.expiry_date);
        resource.selectedRules.push("expiry_date");
      }
    }

    this.state = {
      resourceId: resource.resourceId,
      tags: [],
      keyTag: Math.random(),
      name: resource.name,
      description: resource.description,
      selectedTag: resource.selectedTag,
      fileName: "",
      fileData: "",
      fileList: resource.fileList,
      videoList: resource.videoList,
      videoTitle: "",
      videoUrl: "",
      videoPlayer: "Youtube",
      content: resource.content,
      editorContent: resource.editorContent,
      selectedRules: resource.selectedRules,
      releaseDate: resource.releaseDate,
      expiryDate: resource.expiryDate,
      editorType: resource.editorType,
      videoKey: Math.random(),
      draftId: resource.draftId,
      keyEditor: Math.random(),
      draftImage: resource.draftImage,
      keyFile: Math.random(),
    };
  }

  componentDidMount() {
    this.getSystemTags();
  }

  async getSystemTags() {
    let d = await callGetSystemTags();

    this.setState({ tags: d.data, keyTag: Math.random() });
  }

  resetProps() {
    this.setState({
      keyTag: Math.random(),
      name: "",
      description: "",
      selectedTag: "",
      fileName: "",
      fileData: "",
      fileList: [],
      videoList: [],
      videoTitle: "",
      videoUrl: "",
      videoPlayer: "Youtube",
      content: "",
      editorContent: {},
      selectedRules: [],
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  handleFileUpload(event) {
    this.setState({ fileData: event.target.files[0] });
  }

  onSelectTag = (tag) => {
    this.setState({ selectedTag: tag });
  };

  onSelectVideoPlayer(item, id) {
    this.setState({
      [id]: item[Object.keys(item)[0]].value,
      videoKey: Math.random(),
    });
  }

  onSortEndFiles = ({ oldIndex, newIndex }) => {
    let fileList = this.state.fileList;
    fileList = arrayMove(fileList, oldIndex, newIndex);

    this.setState({
      fileList: fileList,
    });
  };

  onPressRemoveFile(e) {
    let index = e.target.dataset["value"];
    let files = this.state.fileList;
    files.splice(index, 1);
    this.setState({
      fileList: files,
    });
  }

  async onClickAddFile() {
    if (this.state.fileName.trim().length === 0) {
      showNotification("error", "File name is required", 4000);
      return;
    }
    if (this.state.fileData.length === 0) {
      showNotification("error", "Select a file", 4000);
      return;
    }

    let fileInfo = {
      name: this.state.fileName,
      file: this.state.fileData,
    };

    let fileList = this.state.fileList;
    fileList.push(fileInfo);

    this.setState({ fileList: fileList, fileName: "", fileData: "" });
  }

  onSortEndVideos = ({ oldIndex, newIndex }) => {
    const { videoList } = this.state;

    this.setState({
      videoList: arrayMove(videoList, oldIndex, newIndex),
    });
  };

  onPressRemoveVideo(e) {
    let index = e.target.dataset["value"];
    let videos = this.state.videoList;
    videos.splice(index, 1);
    this.setState({
      videoList: videos,
    });
  }

  onClickAddVideo() {
    let videos = this.state.videoList;
    if (this.state.videoTitle.trim().length === 0) {
      showNotification("error", "Video Title is required", 4000);
      return;
    }
    if (this.state.videoUrl.trim().length === 0) {
      showNotification("error", "Video ID is required", 4000);
      return;
    }
    if (this.state.videoPlayer.trim().length === 0) {
      showNotification("error", "Video Player is required", 4000);
      return;
    }

    videos.push({
      title: this.state.videoTitle,
      url: this.state.videoUrl,
      player: this.state.videoPlayer,
    });

    this.setState({
      videoList: videos,
      videoTitle: "",
      videoUrl: "",
      videoPlayer: "Youtube",
    });
  }

  onSelectRules(item, id) {
    let selectedRules = [];

    Object.keys(item).forEach((obj) => {
      selectedRules.push(item[obj].value);
    });

    this.setState({
      selectedRules: selectedRules,
    });
  }

  onChangeReleaseDate(value) {
    this.setState({
      releaseDate: value,
    });
  }

  onChangeExpiryDate(value) {
    this.setState({
      expiryDate: value,
    });
  }

  onChangeTag = (tags) => {
    let selectedTag = "";
    if (tags.length > 0) {
      selectedTag = tags[0].name;
    }
    this.setState({ selectedTag: selectedTag });
  };

  async onPressSave() {
    if (this.state.selectedTag.trim().length === 0) {
      showNotification("error", "Tag selection is required", 4000);
      return;
    }
    if (this.state.name.trim().length === 0) {
      showNotification("error", "Name is required", 4000);
      return;
    }

    if (this.state.description.trim().length === 0) {
      showNotification("error", "Description is required", 4000);
      return;
    }

    let tagId = this.state.tags.filter((d) => {
      return d.name === this.state.selectedTag;
    });

    /*eslint-disable*/

    let apiData = {
      tag_id: tagId[0]._id,
      name: this.state.name,
      description: this.state.description,
      file_list: this.state.fileList,
      video_list: this.state.videoList,
      content: this.state.editorContent,
      html: this.state.content,
      draft_id: this.state.draftId,
      draft_image: this.state.draftImage,
    };
    /*eslint-enable*/

    if (this.state.selectedRules.length > 0) {
      if (this.state.selectedRules.length === 1) {
        if (this.state.selectedRules[0] === "release_date") {
          apiData["release_date"] = this.state.releaseDate;
          apiData["expiry_date"] = null;
        }
        if (this.state.selectedRules[0] === "expiry_date") {
          apiData["release_date"] = null;
          apiData["expiry_date"] = this.state.expiryDate;
        }
      } else {
        this.state.selectedRules.forEach((element) => {
          if (element === "release_date") {
            apiData["release_date"] = this.state.releaseDate;
          }
          if (element === "expiry_date") {
            apiData["expiry_date"] = this.state.expiryDate;
          }
        });
      }
    } else {
      apiData["release_date"] = null;
      apiData["expiry_date"] = null;
    }

    if (this.state.resourceId === 0) {
      let [err, resp] = await toPromise(callCreateResource(apiData));
      if (err) {
        showNotification("error", err.message, 4000);
      } else {
        if (resp._id) {
          showNotification("success", "Resource added successfully", 4000);
          this.resetProps();
        }
      }
    } else {
      let [err, resp] = await toPromise(
        callUpdateResource(this.state.resourceId, apiData)
      );
      if (err) {
        showNotification("error", err.message, 4000);
      } else {
        if (resp._id) {
          showNotification("success", "Resource updated successfully", 4000);
        }
      }
    }
  }

  onChangeFile = (file) => {
    this.setState({ fileData: file });
  };

  renderFilesSection() {
    return (
      <div className="card">
        <div className="container-fluid">
          <div className="gap20" />
          <h5 className="noMargin">Files</h5>
          <div className="gap10" />
          <div className="row">
            <div className="col-sm-4">
              <HelpTextDiv
                id="idResourceFileName"
                label="Learn more about the File Name"
                type="addResource"
                isInline="false"
              />

              <div
                className={
                  this.state.fileName !== "" ? "mdInput mdFocussed" : "mdInput"
                }>
                <label>File Name</label>
                <input
                  type="text"
                  className=""
                  name="fileName"
                  value={this.state.fileName}
                  onChange={(event) => this.handleInputChange(event)}
                />
              </div>
              <div className="gap20" />
            </div>
          </div>
          <SelectAddImage
            type="resource-file"
            contentType="file"
            label="Resource File"
            onChangeFile={this.onChangeFile}
            file={this.state.fileData}
            key={this.state.keyFile}
          />
          <div className="text-right">
            <button
              className="btn btn-primary addVideoButton"
              onClick={() => this.onClickAddFile()}>
              Add File
            </button>
          </div>
          <div className="gap20" />
        </div>

        {this.state.fileList.length > 0 && (
          <div className="container-fluid">
            <div className="card">
              <div className="container-fluid">
                <div className="gap10" />
                <h5>Added Files</h5>
                <div className="gap10" />
                <div className="gap10" />
                <SortableFileList
                  items={this.state.fileList}
                  onSortEnd={this.onSortEndFiles}
                  onPressRemove={this.onPressRemoveFile.bind(this)}
                />
                <div className="gap20" />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  renderVideosSection() {
    const hideAddVideo = true;
    return (
      <>
        {!hideAddVideo && (
          <>
            <div className="gap20" />

            <div className="card">
              <div className="container-fluid">
                <div className="gap20" />

                <h5 className="noMargin">Videos</h5>
                <div className="gap10" />

                <div className="row">
                  <div className="col-sm-4">
                    <HelpTextDiv
                      id="idResourceVideoTitle"
                      label="Learn more about the Video Title"
                      type="addResource"
                      isInline="false"
                    />

                    <div
                      className={
                        this.state.videoTitle !== ""
                          ? "mdInput mdFocussed"
                          : "mdInput"
                      }>
                      <label>Video Title</label>
                      <input
                        type="text"
                        name="videoTitle"
                        value={this.state.videoTitle}
                        onChange={(event) => this.handleInputChange(event)}
                      />
                    </div>
                    <div className="gap20" />
                  </div>
                  <div className="col-sm-4">
                    <HelpTextDiv
                      id="idResourceVideoId"
                      label="Learn more about the Video ID"
                      type="addResource"
                      isInline="false"
                    />

                    <div
                      className={
                        this.state.videoUrl !== ""
                          ? "mdInput mdFocussed"
                          : "mdInput"
                      }>
                      <label>
                        {this.state.videoPlayer === "vooplayer"
                          ? "Video ID"
                          : "Video ID"}
                      </label>
                      <input
                        type="text"
                        className="videoUrl"
                        name="videoUrl"
                        value={this.state.videoUrl}
                        onChange={(event) => this.handleInputChange(event)}
                      />
                    </div>
                    <div className="gap20" />
                  </div>
                  <div className="col-sm-4">
                    <div className="havingHelpText">
                      <label>Video Player</label>
                    </div>
                    <HelpTextDiv
                      id="idResourceVideoPlayer"
                      label="Learn more about the Video Player"
                      type="addResource"
                      isInline="true"
                    />
                    <div className="tabsHolder radioTabs">
                      <RadioTag
                        onCheckChange={this.onSelectVideoPlayer.bind(this)}
                        labelList={[
                          { name: "YouTube", value: "Youtube" },
                          { name: "Vimeo", value: "Vimeo" },
                          { name: "Voo Player", value: "vooplayer" },
                        ]}
                        id={"videoPlayer"}
                        selectedList={[this.state.videoPlayer]}
                        selectType={"value"}
                      />
                    </div>
                    <div className="gap20" />
                    <div className="gap20" />
                  </div>
                </div>
                <VideoPlayerNote
                  videoPlayer={this.state.videoPlayer}
                  key={this.state.videoKey}
                />

                <div className="text-right">
                  <button
                    className="btn btn-primary addVideoButton"
                    onClick={() => this.onClickAddVideo()}>
                    Add Video
                  </button>
                </div>
                <div className="gap20" />
              </div>
            </div>
          </>
        )}
        {this.state.videoList.length > 0 && (
          <>
            <div className="card">
              <div className="container-fluid">
                <div className="gap10" />
                <h5>Added Videos</h5>
                <div className="gap10" />
                <div className="gap10" />
                <SortableVideoList
                  items={this.state.videoList}
                  onSortEnd={this.onSortEndVideos}
                  onPressRemove={this.onPressRemoveVideo.bind(this)}
                />
                <div className="gap20" />
              </div>
            </div>

            <div className="gap20" />
          </>
        )}
      </>
    );
  }

  onChangeContent(html, content, draftId, draftImage) {
    this.setState({
      editorContent: content,
      content: html,
      draftId: draftId,
      draftImage: draftImage,
      keyEditor: Math.random(),
    });
  }

  renderMainContentSection() {
    return (
      <PageContent
        key={this.state.keyEditor}
        title={this.state.name}
        screen={"add-resource"}
        html={this.state.content}
        content={this.state.editorContent}
        onChangeData={this.onChangeContent.bind(this)}
        pageName={"addResource"}
        onClickAlert={this.onPressSave.bind(this)}
        editorType={this.state.editorType}
        previewType={"mp-preview"}
        draftId={this.state.draftId}
        draftImage={this.state.draftImage}
      />
    );
  }

  render() {
    let isReleaseDate = false;
    let isExpiryDate = false;

    this.state.selectedRules.forEach((element) => {
      if (element === "release_date") {
        isReleaseDate = true;
      }
      if (element === "expiry_date") {
        isExpiryDate = true;
      }
    });

    return (
      <Onboarding history={this.props.history}>
        <div>
          <div className="container-fluid">
            <TitleBox
              title={
                this.state.resourceId === 0
                  ? "Create New Resource"
                  : "Edit Resource"
              }
              showBackBtn={this.state.resourceId !== 0}
            />
            <div className="gap20" />
            <div className="card">
              <div className="list-group list-group-flush">
                <div className="list-group-item">
                  <div className="gap10" />
                  <div className="row">
                    <div className="col-sm-9">
                      <HelpTextDiv
                        id="idResourceTag"
                        label="Learn more about the Tag"
                        type="addResource"
                        isInline="false"
                      />
                      <TagSelect
                        id={"add-resource-tag"}
                        key={this.state.keyTag}
                        searchKey={"name"}
                        singleSelection={true}
                        hideInput={true}
                        selectKey={"name"}
                        btnText={"Create New Tag"}
                        lblText={"Select Tag"}
                        data={this.state.tags}
                        onChange={this.onChangeTag}
                        isClickable={true}
                        selectedValues={[this.state.selectedTag]}
                        reload={() => {
                          this.getSystemTags();
                        }}
                      />
                    </div>
                  </div>
                  <div className="gap20" />

                  <HelpTextDiv
                    id="idResourceName"
                    label="Learn more about the Name"
                    type="addResource"
                    isInline="false"
                  />

                  <div
                    className={
                      this.state.name !== "" ? "mdInput mdFocussed" : "mdInput"
                    }>
                    <label>Name</label>
                    <input
                      className="challangeName"
                      name="name"
                      value={this.state.name}
                      onChange={(event) => this.handleInputChange(event)}
                    />
                  </div>
                  <div className="gap20" />

                  <HelpTextDiv
                    id="idResourceDescription"
                    label="Learn more about the Description"
                    type="addResource"
                    isInline="false"
                  />

                  <div
                    className={
                      this.state.description !== ""
                        ? "mdInput mdFocussed"
                        : "mdInput"
                    }>
                    <label>Description</label>
                    <input
                      className="challangeDesc"
                      name="description"
                      value={this.state.description}
                      onChange={(event) => this.handleInputChange(event)}
                    />
                  </div>
                  <div className="gap20" />

                  <div className="row">
                    <div className="col-sm-12">
                      <div className="radioBHolder">
                        <div className="havingHelpText">
                          {" "}
                          <p>Date Rules</p>
                        </div>
                        <HelpTextDiv
                          id="idResourceDateRules"
                          label="Learn more about the Date Rules"
                          type="addResource"
                          isInline="true"
                        />
                        <div className="tabsHolder radioTabs">
                          <RadioTag
                            onCheckChange={this.onSelectRules.bind(this)}
                            labelList={[
                              {
                                name: "Release Date",
                                value: "release_date",
                              },
                              {
                                name: "Expiry Date",
                                value: "expiry_date",
                              },
                            ]}
                            id={"rules"}
                            selectedList={this.state.selectedRules}
                            selectType={"value"}
                            type={"multiple"}
                          />
                        </div>
                      </div>
                    </div>
                    {isReleaseDate && (
                      <div className="col-sm-6 addTagAgeLimit">
                        <HelpTextDiv
                          id="idResourceReleaseDate"
                          label="Learn more about the Release Date"
                          type="addResource"
                          isInline="false"
                        />

                        <label className="inputFakeLabelFocussed">
                          Select Release Date
                        </label>
                        <div>
                          <DateTimePicker
                            renderNumbers
                            onChange={this.onChangeReleaseDate.bind(this)}
                            value={this.state.releaseDate}
                          />
                        </div>
                        <div className="gap20" />
                      </div>
                    )}
                    {isExpiryDate && (
                      <div className="col-sm-6 tagExpiresAfterXDays">
                        <HelpTextDiv
                          id="idResourceExpiryDate"
                          label="Learn more about the Expiry Date"
                          type="addResource"
                          isInline="false"
                        />
                        <label className="inputFakeLabelFocussed">
                          Select Expiry Date
                        </label>
                        <div>
                          <DateTimePicker
                            renderNumbers
                            onChange={this.onChangeExpiryDate.bind(this)}
                            value={this.state.expiryDate}
                          />
                        </div>
                        <div className="gap20" />
                      </div>
                    )}
                  </div>
                  <div className="gap20" />
                </div>
              </div>
            </div>

            <TitleBox title={"Resources"} showInfoIcon={false} />
            <div className="gap20" />
            {this.renderFilesSection()}
            {this.renderVideosSection()}
            {this.renderMainContentSection()}

            <div className="text-right container-fluid">
              <button
                className="btn btn-primary"
                onClick={() => this.onPressSave()}>
                {this.state.resourceId === 0
                  ? "Create Resource"
                  : "Update Resource"}
              </button>
            </div>
            <div className="gap20" />
          </div>
        </div>
      </Onboarding>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddResource);
