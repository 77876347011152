import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DatePicker from "react-date-picker";
import { arrayMove } from "react-sortable-hoc";

import { showNotification, isFS, getEditorType } from "../../../helpers/index";

import PageContent from "../../../components/editor/pageContent";
import Checkbox from "../../../components/form/checkBox";
import TitleBox from "../../../components/general/titleBox";
import StatusSwitch from "../../../components/form/statusSwitch";

import {
  resetStatus,
  getGenericTags,
  createMarciCircle,
  updateMarciCircle,
} from "../../../modules/actions/index";
import {
  callCreateGenericTag,
  callGetSubscriptionAreaUsingQuery,
  callGetMarciCircleUsingId,
} from "../../../services/index";
import SelectAddImage from "../../../components/form/selectAddImage";
import HelpTextDiv from "../../../components/form/helpTextDiv";
import GenericTagSelect from "../../../components/form/genericTagSelect";
import { getItem } from "../../../helpers/storage";

class AddNewsletter extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    let newsLetter = {
      _id: 0,
      name: "",
      description: "",
      releaseDate: new Date(),
      title: "",
      newsLetterDate: new Date(),
      weekNo: "",
      editorContent: {},
      pageContent: "",
      author: "",
      issueTitle: "",
      issueDescription: "",
      highlights: [],
      selectedTags: [],
      tags: [],
      fTags: { new: [], existing: [] },
      keyTag: Math.random(),
      isActive: true,
      marciCircleStatusLabel: "Currently Active",
      imgUrl: "",
      subAreaId: "",
      pdfUrl: "",
      isPdfOnly: false,
      editorType: isFS()
        ? "mp"
        : getItem("de") === "classic"
        ? "unlayer"
        : "mp",
      draftId: "",
      draftImage: "",
      preventDownloadPdf: false,
    };

    if (this.props.history.location.state !== undefined) {
      let nData = this.props.history.location.state.newsLetter;

      newsLetter._id = nData._id;
    }

    this.state = {
      newsLetterId: newsLetter._id,
      name: newsLetter.name,
      description: newsLetter.description,
      releaseDate: newsLetter.releaseDate,
      title: newsLetter.title,
      newsLetterDate: newsLetter.newsLetterDate,
      weekNo: newsLetter.weekNo,
      editorContent: newsLetter.editorContent,
      pageContent: newsLetter.pageContent,
      author: newsLetter.author,
      issueTitle: "",
      issueDescription: "",
      highlights: newsLetter.highlights,
      selectedTags: newsLetter.selectedTags,
      tags: [],
      fTags: { new: [], existing: newsLetter.selectedTags },
      keyTag: newsLetter.keyTag,
      isActive: newsLetter.isActive,
      marciCircleStatusLabel: newsLetter.marciCircleStatusLabel,
      imgUrl: newsLetter.imgUrl,
      subscriptionAreas: [],
      isLoading: true,
      selectedSubscriptionArea: newsLetter.subAreaId,
      pdfUrl: newsLetter.pdfUrl,
      keyPdfUrl: Math.random(),
      isPdfOnly: newsLetter.isPdfOnly,
      keyPdfOnly: Math.random(),
      keySwitch: Math.random(),
      editorType: newsLetter.editorType,
      draftId: newsLetter.draftId,
      keyEditor: Math.random(),
      draftImage: newsLetter.draftImage,
      preventDownloadPdf: newsLetter.preventDownloadPdf,
      keyPreventDownloadPdf: Math.random(),
    };
  }

  componentDidMount() {
    this.getData();
    this.props.getGenericTags("marci-circle");
    this.getSubscriptionAreas();
  }

  async getData() {
    if (this.state.newsLetterId !== 0) {
      let nData = await callGetMarciCircleUsingId(this.state.newsLetterId);
      let newsLetter = {
        _id: 0,
        name: "",
        description: "",
        releaseDate: new Date(),
        title: "",
        newsLetterDate: new Date(),
        weekNo: "",
        editorContent: {},
        pageContent: "",
        author: "",
        issueTitle: "",
        issueDescription: "",
        highlights: [],
        selectedTags: [],
        tags: [],
        fTags: { new: [], existing: [] },
        keyTag: Math.random(),
        isActive: true,
        marciCircleStatusLabel: "Currently Active",
        imgUrl: "",
        subAreaId: "",
        pdfUrl: "",
        isPdfOnly: false,
        editorType: isFS()
          ? "mp"
          : getItem("de") === "classic"
          ? "unlayer"
          : "mp",
        draftId: "",
        draftImage: "",
        preventDownloadPdf: false,
      };

      newsLetter._id = nData._id;
      newsLetter.name = nData.name;
      newsLetter.description = nData.description;
      newsLetter.releaseDate = new Date(nData.release_date);
      newsLetter.newsLetterDate = new Date(nData.newsletter_date);
      newsLetter.title = nData.title;
      newsLetter.weekNo = nData.week_no;
      newsLetter.author = nData.author;
      newsLetter.editorContent = nData.editor_content;
      newsLetter.pageContent = nData.main_content;
      newsLetter.editorType = getEditorType(
        newsLetter.pageContent,
        newsLetter.editorContent
      );
      newsLetter.selectedTags = nData.ftags || [];
      newsLetter.highlights = nData.highlights;
      newsLetter.pdfUrl = nData.pdf_url || "";
      if (newsLetter.pdfUrl) {
        newsLetter.isPdfOnly = true;
      }

      newsLetter.imgUrl = nData.image || "";
      newsLetter.subAreaId = nData.subscription_area || "";

      newsLetter.isActive = nData.status
        ? nData.status === "active"
          ? true
          : false
        : true;
      if (!newsLetter.isActive) {
        newsLetter.marciCircleStatusLabel = "Currently Inactive";
      }

      newsLetter.draftId = nData.draft_id || "";
      newsLetter.draftImage = nData.draft_image || "";
      newsLetter.preventDownloadPdf =
        nData.prevent_download_pdf !== undefined
          ? nData.prevent_download_pdf
          : false;

      this.setState({
        newsLetterId: newsLetter._id,
        name: newsLetter.name,
        description: newsLetter.description,
        releaseDate: newsLetter.releaseDate,
        title: newsLetter.title,
        newsLetterDate: newsLetter.newsLetterDate,
        weekNo: newsLetter.weekNo,
        editorContent: newsLetter.editorContent,
        pageContent: newsLetter.pageContent,
        author: newsLetter.author,
        highlights: newsLetter.highlights,
        selectedTags: newsLetter.selectedTags,
        fTags: { new: [], existing: newsLetter.selectedTags },
        keyTag: newsLetter.keyTag,
        isActive: newsLetter.isActive,
        marciCircleStatusLabel: newsLetter.marciCircleStatusLabel,
        imgUrl: newsLetter.imgUrl,
        selectedSubscriptionArea: newsLetter.subAreaId,
        pdfUrl: newsLetter.pdfUrl,
        keyPdfUrl: Math.random(),
        isPdfOnly: newsLetter.isPdfOnly,
        keyPdfOnly: Math.random(),
        keySwitch: Math.random(),
        editorType: newsLetter.editorType,
        draftId: newsLetter.draftId,
        keyEditor: Math.random(),
        draftImage: newsLetter.draftImage,
        preventDownloadPdf: newsLetter.preventDownloadPdf,
        keyPreventDownloadPdf: Math.random(),
      });
    }
  }

  componentWillMount() {
    if (!this.state.isActive && this.state.newsLetterId !== 0) {
      showNotification(
        "warning",
        "This newsletter is currently inactive.",
        false
      );
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.message) {
      if (newProps.success) {
        if (this.state.newsLetterId === 0) {
          this.resetProps();
        }

        showNotification("success", newProps.message, 5000);
      } else {
        showNotification("error", newProps.message, 5000);
      }
      this.props.resetStatus();
    }

    if (newProps.genericTags.data) {
      this.setState({
        tags: newProps.genericTags.data,
        keyTag: Math.random(),
      });
    }
  }

  resetProps() {
    this.setState({
      name: "",
      description: "",
      releaseDate: new Date(),
      editorContent: {},
      pageContent: "",
      title: "",
      weekNo: "",
      author: "",
      imgUrl: "",
      highlights: [],
      selectedSubscriptionArea: "",
      pdfUrl: "",
      isPdfOnly: false,
      keyPdfUrl: Math.random(),
      keyPdfOnly: Math.random(),
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  getSubscriptionAreas() {
    callGetSubscriptionAreaUsingQuery().then((d) => {
      this.setState({ subscriptionAreas: d.data, isLoading: false });
    });
  }

  toggleActiveCheckbox = (check) => {
    this.setState({
      isActive: check,
      marciCircleStatusLabel: check ? "Currently Active" : "Currently Inactive",
    });
  };

  togglePDFOnlyCheckbox = (check) => {
    this.setState({
      isPdfOnly: check,
      pdfUrl: check ? this.state.pdfUrl : "",
    });
  };

  togglePreventPDFCheckbox = (check) => {
    this.setState({
      preventDownloadPdf: check,
    });
  };

  onChangeContent(html, content, draftId, draftImage) {
    this.setState({
      editorContent: content,
      pageContent: html,
      draftId: draftId,
      draftImage: draftImage,
      keyEditor: Math.random(),
    });
  }

  onChangePDFFile = (file) => {
    this.setState({ pdfUrl: file });
  };

  onChangeReleaseDate(value) {
    this.setState({
      releaseDate: value,
    });
  }

  onChangeNewsLetterDate(value) {
    this.setState({
      newsLetterDate: value,
    });
  }

  onChangeTags = (tags) => {
    if (tags && tags.length > 0) {
      let fTags = { new: [], existing: [] };
      tags.forEach((element) => {
        if (element.name) {
          fTags.existing.push(element);
        } else {
          fTags.new.push(element);
        }
      });
      this.setState({ fTags: fTags });
    }
  };

  onPressSaveMarciCircle() {
    if (this.state.name.trim().length === 0) {
      showNotification("error", "Name is required", 4000);
      return;
    }
    if (this.state.description.trim().length === 0) {
      showNotification("error", "Description is required", 4000);
      return;
    }

    if (this.state.isPdfOnly) {
      if (this.state.pdfUrl.trim().length === 0) {
        showNotification("error", "PDF File is required", 4000);
        return;
      }
    } else {
      if (this.state.pageContent.trim().length === 0) {
        showNotification("error", "Content is required", 4000);
        return;
      }
    }

    if (this.state.title.trim().length === 0) {
      showNotification("error", "Title is required", 4000);
      return;
    }
    if (this.state.author.trim().length === 0) {
      showNotification("error", "Author is required", 4000);
      return;
    }
    this.setupAPICall();
  }

  setupAPICall() {
    let tags = this.state.fTags;
    if (tags.new.length > 0) {
      let data = [];
      tags.new.forEach((element) => {
        data.push({ name: element, type: "marci-circle" });
      });
      callCreateGenericTag(data).then((resp) => {
        let eTags = this.state.tags || [];
        this.setState({
          tags: eTags.concat(resp),
        });
        let newTagIds = resp.map((t) => t._id);
        let tagIds = tags.existing.map((t) => t._id);
        let finalTagIds = newTagIds.concat(tagIds);

        this.callAddMarciCircleAPI(finalTagIds);
      });
    } else {
      let tagIds = tags.existing.map((t) => t._id);
      this.callAddMarciCircleAPI(tagIds);
    }
  }

  onSortItemEnd = ({ oldIndex, newIndex }) => {
    let items = this.state.highlights;
    items = arrayMove(items, oldIndex, newIndex);

    this.setState({
      highlights: items,
    });
  };

  onPressRemoveItem(e) {
    let index = e.target.dataset["value"];
    let items = this.state.highlights;
    items.splice(index, 1);
    this.setState({
      highlights: items,
    });
  }

  onClickAddItem() {
    if (this.state.issueTitle.trim().length === 0) {
      showNotification("error", "Issue Title is required", 4000);
      return;
    }
    if (this.state.issueDescription.trim().length === 0) {
      showNotification("error", "Issue Description is required", 4000);
      return;
    }

    let highlight = {
      title: this.state.issueTitle,
      description: this.state.issueDescription,
    };

    let highlights = this.state.highlights;
    highlights.push(highlight);

    this.setState({
      highlights: highlights,
      issueTitle: "",
      issueDescription: "",
    });
  }

  callAddMarciCircleAPI(tags) {
    let apiData = {
      /*eslint-disable */
      name: this.state.name,
      description: this.state.description,
      editor_content: this.state.editorContent,
      main_content: this.state.pageContent,
      release_date: this.state.releaseDate,
      generic_tag_list: tags,
      newsletter_date: this.state.newsLetterDate,
      title: this.state.title,
      week_no: this.state.weekNo,
      author: this.state.author,
      highlights: this.state.highlights,
      status: this.state.isActive ? "active" : "inactive",
      image: this.state.imgUrl,
      subscription_area: this.state.selectedSubscriptionArea,
      pdf_url: this.state.pdfUrl,
      draft_id: this.state.draftId,
      draft_image: this.state.draftImage,
      prevent_download_pdf: this.state.preventDownloadPdf,
      /*eslint-enable*/
    };

    if (this.state.newsLetterId === 0) {
      this.props.createMarciCircle(apiData);
      this.resetProps();
    } else {
      this.props.updateMarciCircle(this.state.newsLetterId, apiData);
    }
  }

  onChangeImage = (image) => {
    this.setState({ imgUrl: image });
  };

  renderSubscriptionAreas() {
    let returnVals = [];
    let items = this.state.subscriptionAreas;
    returnVals.push(<option value={""} key={0} />);
    for (let i = 0; i < items.length; i++) {
      returnVals.push(
        <option value={items[i]._id} key={i + 1}>
          {items[i].name}
        </option>
      );
    }
    return returnVals;
  }

  onPressAddSubscriptionArea() {
    this.props.history.push("/admin/add-subscription-area");
  }

  addNewsletterTag = (add) => {
    this.state.tags.push(add);
    this.setState({
      fTags: {
        existing: this.state.tags,
      },
    });
  };

  render() {
    let isLoading = this.state.isLoading;
    let isAreas = this.state.subscriptionAreas.length > 0;

    if (isFS()) {
      isLoading = false;
      isAreas = true;
    }
    return (
      <div>
        <div className="container-fluid">
          <TitleBox
            title={
              this.state.newsLetterId === 0
                ? "Add Newsletter"
                : "Edit Newsletter"
            }
            showBackBtn={this.state.newsLetterId !== 0}
          />
        </div>
        {!isLoading && isAreas && (
          <div className="container-fluid">
            <div className="gap20" />
            <div className="card">
              <div className="list-group list-group-flush">
                <div className="list-group-item">
                  <div className="gap20" />

                  <div className="havingHelpText">
                    <StatusSwitch
                      key={this.state.keySwitch}
                      isActive={this.state.isActive}
                      onChange={this.toggleActiveCheckbox.bind(this)}
                    />
                  </div>
                  <HelpTextDiv
                    id="idMarciCircleStatus"
                    label="Learn more about the status"
                    type="addMarciCircle"
                    isInline="true"
                  />
                  <div className="">
                    <HelpTextDiv
                      id="idMarciCircleName"
                      label="Learn more about the Name"
                      type="addMarciCircle"
                      isInline="false"
                    />
                    <div
                      className={
                        this.state.name !== ""
                          ? "mdInput mdFocussed"
                          : "mdInput"
                      }>
                      <label>Name</label>
                      <input
                        type="text"
                        name="name"
                        value={this.state.name}
                        onChange={(event) => this.handleInputChange(event)}
                      />
                    </div>
                    <div className="gap20" />

                    <HelpTextDiv
                      id="idMarciCircleDescription"
                      label="Learn more about the Description"
                      type="addMarciCircle"
                      isInline="false"
                    />
                    <div
                      className={
                        this.state.description !== ""
                          ? "mdInput mdFocussed"
                          : "mdInput"
                      }>
                      <label>Description</label>
                      <textarea
                        name="description"
                        value={this.state.description}
                        onChange={(event) => this.handleInputChange(event)}
                      />
                    </div>
                    <div className="gap20" />

                    <HelpTextDiv
                      id="idMarciCircleReleaseDate"
                      label="Learn more about the Release Date"
                      type="addMarciCircle"
                      isInline="false"
                    />
                    <label className="inputFakeLabelFocussed">
                      Release Date
                    </label>
                    <div>
                      <DatePicker
                        value={this.state.releaseDate}
                        onChange={this.onChangeReleaseDate.bind(this)}
                      />
                    </div>
                    <div className="gap20" />

                    <HelpTextDiv
                      id="idMarciCircleTag"
                      label="Learn more about the Tag"
                      type="addMarciCircle"
                      isInline="false"
                    />
                    <GenericTagSelect
                      id={"add-newsletter-type-tag"}
                      key={this.state.keyTag}
                      selectedValues={this.state.selectedTags}
                      searchKey={"name"}
                      selectKey={"name"}
                      btnText={"Create New Tag"}
                      lblText={"Type a Tag"}
                      data={this.state.tags}
                      onChange={this.onChangeTags}
                      addTag={this.addNewsletterTag}
                      isClickable={true}
                      popupHeaderText={"Add Newsletter Tag"}
                      popupGenericType={"marci-circle"}
                      popupHeaderFFEnableName={true}
                      popupHeaderFFEnableDescription={true}
                    />

                    <HelpTextDiv
                      id="idMarciCircleTitle"
                      label="Learn more about the Title"
                      type="addMarciCircle"
                      isInline="false"
                    />
                    <div
                      className={
                        this.state.title !== ""
                          ? "mdInput mdFocussed"
                          : "mdInput"
                      }>
                      <label>Title</label>
                      <input
                        type="text"
                        name="title"
                        value={this.state.title}
                        onChange={(event) => this.handleInputChange(event)}
                      />
                    </div>
                    <div className="gap20" />

                    <Checkbox
                      label={"PDF Only"}
                      isChecked={this.state.isPdfOnly}
                      handleCheckboxChange={this.togglePDFOnlyCheckbox.bind(
                        this
                      )}
                      key={"pdf-only"}
                    />
                    <div className="gap20" />
                    {this.state.isPdfOnly ? (
                      <>
                        <SelectAddImage
                          type="newsletter-pdf-file"
                          contentType="file"
                          label="PDF File"
                          onChangeFile={this.onChangePDFFile}
                          file={this.state.pdfUrl}
                          key={this.state.keyPdfUrl}
                        />
                        <div className="gap20" />
                        <Checkbox
                          label={
                            "Prevent downloading the pdf - only allow access in mobile"
                          }
                          isChecked={this.state.preventDownloadPdf}
                          handleCheckboxChange={this.togglePreventPDFCheckbox.bind(
                            this
                          )}
                          key={"pdf-only"}
                        />
                      </>
                    ) : (
                      <>
                        <PageContent
                          key={this.state.keyEditor}
                          title={this.state.name}
                          screen={"add-marci-circle"}
                          html={this.state.pageContent}
                          content={this.state.editorContent}
                          onChangeData={this.onChangeContent.bind(this)}
                          pageName={"addMarciCircle"}
                          onClickAlert={this.onPressSaveMarciCircle.bind(this)}
                          editorType={this.state.editorType}
                          previewType={"mp-preview"}
                          draftId={this.state.draftId}
                          draftImage={this.state.draftImage}
                        />
                      </>
                    )}

                    <HelpTextDiv
                      id="idMarciCircleAuthor"
                      label="Learn more about the Author"
                      type="addMarciCircle"
                      isInline="false"
                    />
                    <div
                      className={
                        this.state.author !== ""
                          ? "mdInput mdFocussed"
                          : "mdInput"
                      }>
                      <label>Author</label>
                      <input
                        type="text"
                        name="author"
                        value={this.state.author}
                        onChange={(event) => this.handleInputChange(event)}
                      />
                    </div>
                    <div className="gap20" />
                    <div
                      className={
                        this.state.selectedSubscriptionArea !== ""
                          ? "mdInput mdFocussed"
                          : "mdInput"
                      }>
                      <label>Select Subscription Area</label>
                      <select
                        name="selectedSubscriptionArea"
                        value={this.state.selectedSubscriptionArea}
                        onChange={(event) => this.handleInputChange(event)}>
                        {this.renderSubscriptionAreas()}
                      </select>
                    </div>
                    <div className="gap20" />
                    <SelectAddImage
                      type={"add-newsletter-image"}
                      contentType="image"
                      label="Newsletter Image"
                      onChangeImage={this.onChangeImage}
                      image={this.state.imgUrl}
                    />
                    <div className="gap20" />
                  </div>
                </div>
              </div>
            </div>
            <div className="text-right">
              <button
                className="btn btn-primary"
                onClick={() => this.onPressSaveMarciCircle()}>
                Save
              </button>
            </div>
            <div className="gap20" />
          </div>
        )}
        {!isAreas && (
          <div className="container-fluid">
            <div className="gap10" />
            <div className="card">
              <div className="container-fluid">
                <div className="gap20" />
                <div className="row">
                  <div className="col-md-3">
                    {isLoading && <p>Loading ...</p>}
                    {!isLoading && (
                      <>
                        <p>Please add subscription area.</p>
                        <button
                          className="btn btn-primary"
                          onClick={() => this.onPressAddSubscriptionArea()}>
                          Add Subscription Area
                        </button>
                        <div className="gap20" />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  message: state.marciCircleReducer.message,
  success: state.marciCircleReducer.success,
  genericTags: state.tagReducer.genericTags,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getGenericTags,
      resetStatus,
      createMarciCircle,
      updateMarciCircle,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AddNewsletter);
