import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import $ from "jquery";

import TitleBox from "../../../components/general/titleBox";

import FilterList from "../../../components/form/filterList";

import { showNotification, isTemplateSite } from "../../../helpers";
import {
  callCreateSystemTag,
  callUpdateSystemTag,
  callGetPageSystemTags,
  callGetSystemTags,
} from "../../../services";
import SortItem from "../../../components/form/sortItem";
import ReactPaginate from "react-paginate";
import ExportContent from "../../../helpers/exportContent";
import moment from "moment";

class ViewSystemTag extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      tags: [],
      tagsBackup: [],
      filterList: [],
      tagTypes: [
        { name: "All", value: "all", selected: false },
        { name: "Automated", value: "automated", selected: true },
        { name: "System", value: "system", selected: false },
      ],
      genericTags: [],
      keyTag: Math.random(),
      selectedSystemTag: {},
      tagName: "",
      keySort: Math.random(),
      page: 0,
      pages: 0,
      totalTags: 0,
      searchQuery: "",
      /*eslint-disable*/
      sQuery: { type: "all", search: "", generic_tag_list: [] },
      /*eslint-enable*/
      keyExport: Math.random(),
      allTags: [],
    };
  }

  componentDidMount() {
    this.getSystemTags();
    this.getAllSystemTags();
  }

  async getAllSystemTags() {
    let tagsData = await callGetSystemTags({});
    let tags = tagsData.data;

    this.setState({
      keyExport: Math.random(),
      allTags: tags,
    });
  }

  async getSystemTags() {
    let limit = 50;
    let sQuery = this.state.sQuery;
    /*eslint-disable*/
    let query = {
      query: {
        page: this.state.page,
        limit: limit,
        status: { $ne: "archive" },
        $sort: { createdAt: -1 },
        created_from: { $nin: ["challenge-rule", "event", "checkout-item"] },
      },
    };
    /*eslint-enable*/
    if (sQuery.type !== "all") {
      query.query["type"] = sQuery.type;
    }
    if (sQuery.search) {
      let regex = { $regex: sQuery.search, $options: "i" };
      /*eslint-disable*/
      query.query["$or"] = [{ name: regex }, { description: regex }];
      /*eslint-enable*/
    }
    if (sQuery.generic_tag_list !== "all") {
      query.query["generic_tag_list"] = { $in: [sQuery.generic_tag_list] };
    }

    let tagsData = await callGetPageSystemTags(query);

    let tags = tagsData.data;
    // this.prepareDataForFilter(tags);
    this.setState({
      totalTags: tagsData.total,
      pages: Math.ceil(tagsData.total / limit),
      keyExport: Math.random(),
      tags: tags,
      tagsBackup: tags,
      keySort: Math.random(),
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  prepareDataForFilter(data) {
    let tags = [{ name: "All", value: "all", selected: true }];

    data.forEach((element) => {
      let tag = element.ftags || [];
      tag.forEach((el) => {
        let arrTag = tags.filter((d) => {
          return d.name.toLowerCase() === el.name.toLowerCase();
        });
        if (arrTag.length === 0) {
          tags.push({
            name: el.name.charAt(0).toUpperCase() + el.name.slice(1),
            value: el._id,
            selected: false,
          });
        }
      });
    });

    this.setState({
      genericTags: tags,
      keyTag: Math.random(),
      keySort: Math.random(),
    });
  }

  onClickEditSystemTag = (systemTag, editMode) => {
    this.props.history.push("/admin/add-system-tag", {
      systemTag: systemTag,
      systemEdit: editMode,
    });
  };

  onClickViewTagInfo = (systemTag) => {
    let url = `/admin/tag-info?id=${systemTag._id}`;
    window.open(url, "_blank");
  };

  onClickCloneSystemTag = (systemTag) => {
    this.setState({ selectedSystemTag: systemTag }, () => {
      $(
        ".modalAlertOverlay.systemTagModalOverlay,.modalAlert.systemTagAlert"
      ).fadeIn(200);
    });
  };

  getFormattedTags(tags) {
    if (tags && tags.length > 0) {
      let ftags = tags.map((a) => a.name);
      return ftags.join(", ");
    } else {
      return "-";
    }
  }

  onClickArchiveTag = (systemTag) => {
    this.setState({ selectedSystemTag: systemTag }, () => {
      $(
        ".modalAlertOverlay.confirmAlertOverlay,.modalAlert.confirmAlert"
      ).fadeIn(200);
    });
  };

  onClickNoAlert = () => {
    $(
      ".modalAlertOverlay.confirmAlertOverlay,.modalAlert.confirmAlert"
    ).fadeOut(200);
  };

  onClickYesAlert = () => {
    let selectedSystemTag = this.state.selectedSystemTag;
    callUpdateSystemTag(selectedSystemTag._id, {
      status: "archive",
    }).then((d) => {
      $(
        ".modalAlertOverlay.confirmAlertOverlay,.modalAlert.confirmAlert"
      ).fadeOut(200);
    });
    this.getSystemTags();
  };

  renderConfirmationAlert() {
    let selectedSystemTag = this.state.selectedSystemTag;
    let name = "";
    if (selectedSystemTag.name) {
      name = selectedSystemTag.name;
    }
    return (
      <div>
        <div className="modalAlertOverlay confirmAlertOverlay" />
        <div className="modalAlert confirmAlert">
          <p>{`Are you sure you want to archive "${name}" tag?`}</p>
          <div className="alertFooterBtns">
            <button
              className="btn btn-default closeModal-No margin-right-10"
              onClick={this.onClickNoAlert}>
              No
            </button>
            <button
              className="btn btn-primary closeModal-Yes"
              onClick={this.onClickYesAlert}>
              Yes
            </button>
          </div>
        </div>
      </div>
    );
  }

  onClickRules(element) {
    this.setState(
      {
        selectedSystemTag: element,
      },
      () => {
        $(".modalAlertOverlay.rulesOverlay,.modalAlert.rulesAlert").fadeIn(200);
      }
    );
  }

  onClickNoRulesAlert = () => {
    $(".modalAlertOverlay.rulesOverlay,.modalAlert.rulesAlert").fadeOut(200);
  };

  getTagName(id) {
    let tags = this.state.tags;
    let tagName = "Not Found";
    let fTags = tags.filter((d) => d._id === id);
    if (fTags.length > 0) {
      tagName = fTags[0].name;
    }
    return tagName;
  }

  renderRulesAlert() {
    let systemTag = this.state.selectedSystemTag;
    let rules = systemTag.additional_rules || [];
    let returnVals = [];
    let i = 0;
    let when = {
      always: "Always",
      tag: "User has the tag",
      "no-tag": "User does not have the tag",
    };
    if (rules.length > 0) {
      rules.forEach((element) => {
        i++;
        let actionType =
          element.action === "add-tag" ? "Add A Tag" : "Inactivate A Tag";
        let tagWhen = element.tag_when || "always";
        let priority = element.priority || 10;
        if (element.type === "on_specific_day") {
          returnVals.push(
            <div key={i}>
              On Day : <b>{element.day}</b>, {actionType} :{" "}
              <b>{this.getTagName(element.tag_id)}</b>, When:{" "}
              <b>
                {when[tagWhen]}{" "}
                {element.tag_when_id &&
                  `(${this.getTagName(element.tag_when_id)})`}
              </b>
              Priority : <b>{priority}</b>
            </div>
          );
        } else if (element.type === "on_specific_date") {
          returnVals.push(
            <div key={i}>
              On Date : <b>{moment(element.date).format("Do MMM YYYY")}</b>,{" "}
              {actionType} : <b>{this.getTagName(element.tag_id)}</b>, When:{" "}
              <b>
                {when[tagWhen]}{" "}
                {element.tag_when_id &&
                  `(${this.getTagName(element.tag_when_id)})`}
              </b>{" "}
              Priority : <b>{priority}</b>
            </div>
          );
        } else if (element.type === "on_weekday") {
          returnVals.push(
            <div key={i}>
              On Weekday : <b>{element.week_day}</b>, {actionType} :{" "}
              <b>{this.getTagName(element.tag_id)}</b>, When:{" "}
              <b>
                {when[tagWhen]}{" "}
                {element.tag_when_id &&
                  `(${this.getTagName(element.tag_when_id)})`}
              </b>{" "}
              Priority : <b>{priority}</b>
            </div>
          );
        } else if (element.type === "instant") {
          returnVals.push(
            <div key={i}>
              Instantly : {actionType} :{" "}
              <b>{this.getTagName(element.tag_id)}</b>, When:{" "}
              <b>
                {when[tagWhen]}{" "}
                {element.tag_when_id &&
                  `(${this.getTagName(element.tag_when_id)})`}
              </b>{" "}
              Priority : <b>{priority}</b>
            </div>
          );
        }
      });
    }
    return (
      <div>
        <div
          className="modalAlertOverlay rulesOverlay"
          onClick={this.onClickNoRulesAlert}
        />
        <div className="modalAlert rulesAlert bigX">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Rules
            </h5>
          </div>
          <div className="gap10" />
          <div className="container-fluid">
            <div className="gap20" />
            {returnVals.map((e) => {
              return <div style={{ marginBottom: 20 }}>{e}</div>;
            })}
            <div className="alertFooterBtns">
              <button
                className="btn btn-default closeModal-No btn-sm"
                onClick={this.onClickNoRulesAlert}>
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderTags() {
    var returnVals = [];
    let tags = this.state.tags;
    let showEdit = isTemplateSite();

    tags.forEach((element) => {
      if (element.type !== "system") {
        showEdit = true;
      }
      returnVals.push(
        <tr key={element._id}>
          <td>{element.name}</td>
          <td>{element.description}</td>
          <td className="firstLetter">{element.type ? element.type : "-"}</td>
          <td className="firstLetter">
            {element.created_from ? element.created_from : "-"}
          </td>
          <td>
            <button
              data-toggle="tooltip"
              onClick={this.onClickRules.bind(this, element)}
              className="btn btn-default btn-sm margin-right-10 border-0">
              <i className="fa fa-info-circle" />
            </button>
          </td>
          <td className="min-width-100">
            <button
              onClick={this.onClickViewTagInfo.bind(this, element)}
              className="btn btn-default btn-sm margin-right-10 border-0">
              <i className="fa fa-list" />
            </button>
            <button
              onClick={this.onClickEditSystemTag.bind(
                this,
                element,
                !element.from_template && showEdit
              )}
              className="btn btn-default btn-sm margin-right-10 border-0">
              <i className="fa fa-pencil" />
            </button>
            {!element.from_template && showEdit && (
              <>
                <button
                  onClick={this.onClickCloneSystemTag.bind(this, element)}
                  className="btn btn-default btn-sm margin-right-10 border-0">
                  <i className="fa fa-clone" />
                </button>
                <button
                  title="Archive"
                  onClick={this.onClickArchiveTag.bind(this, element)}
                  className="btn btn-danger btn-sm  border-0">
                  <i className="fa fa-archive" />
                </button>
              </>
            )}
          </td>
        </tr>
      );
    });
    return returnVals;
  }

  filterUpdated(updatedFilter, name, type, searchFields) {
    let filterList = this.state.filterList;
    filterList[name] = {
      name: name,
      type: type,
      searchFields: searchFields,
      values: updatedFilter,
    };

    let query = this.state.sQuery;

    let searchType = filterList.searchType;
    if (searchType) {
      let values = searchType.values.filter((d) => {
        return d.selected === true;
      });
      if (values.length > 0) {
        query["type"] = values[0].value;
      }
    }
    let search = filterList.search;
    if (search) {
      let values = search.values[0];
      query["search"] = values.value;
    }
    if (filterList.tags) {
      let values = filterList.tags.values.filter((d) => {
        return d.selected === true;
      });
      if (values.length > 0) {
        query["generic_tag_list"] = values[0].value;
      }
    }

    this.setState(
      {
        sQuery: query,
        filterList: filterList,
      },
      () => {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          // this.getSystemTags();
          this.handlePageClick({ selected: 0 });
        }, 300);
      }
    );
  }

  onClickViewArchivedTags() {
    this.props.history.push("/admin/view-archived-tags");
  }

  onClickCancelFromTagModal() {
    $(
      ".modalAlertOverlay.systemTagModalOverlay,.modalAlert.systemTagAlert"
    ).fadeOut(200);
  }

  onClickProcessFromTagModal() {
    let tagName = this.state.tagName.trim().toLowerCase();

    if (tagName.length === 0) {
      showNotification("error", "Please enter tag name", 4000);
      return;
    }

    let existingTag = this.state.tagsBackup.filter((d) => {
      return d.name.toLowerCase() === tagName;
    });

    if (existingTag.length > 0) {
      showNotification("error", "Tag already exists", 4000);
      return;
    }

    $(
      ".modalAlertOverlay.systemTagModalOverlay,.modalAlert.systemTagAlert"
    ).fadeOut(200);

    let selectedSystemTag = this.state.selectedSystemTag;

    /*eslint-disable*/
    let apiData = {
      name: tagName,
      description: selectedSystemTag.description,
      type: selectedSystemTag.type,
      rules: selectedSystemTag.rules,
      additional_rules: selectedSystemTag.additional_rules,
      events: selectedSystemTag.events,
      generic_tag_list: selectedSystemTag.generic_tag_list,
    };

    /*eslint-enable*/

    callCreateSystemTag(apiData).then((d) => {
      if (d._id) {
        this.setState({ selectedSystemTag: {}, tagName: "" }, () => {
          showNotification("success", "Tag created successfully", 4000);
          this.getSystemTags();
        });
      } else {
        showNotification("error", "Something went wrong", 4000);
      }
    });
  }

  renderNewSystemTagModal() {
    let name = "";
    if (this.state.selectedSystemTag.name) {
      name = this.state.selectedSystemTag.name;
    }
    return (
      <div>
        <div className="modalAlertOverlay systemTagModalOverlay" />
        <div className="modalAlert systemTagAlert">
          <div className="clearfix">
            <div className="pull-left">
              <p>Clone Tag - {name}</p>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div
                  className={
                    this.state.tagName !== "" ? "mdInput mdFocussed" : "mdInput"
                  }>
                  <label>Tag Name</label>
                  <input
                    type="text"
                    name="tagName"
                    value={this.state.tagName}
                    onChange={(event) => this.handleInputChange(event)}
                  />
                </div>
                <div className="gap10" />
              </div>
            </div>
          </div>
          <div className="alertFooterBtns">
            <button
              onClick={this.onClickCancelFromTagModal.bind(this)}
              className="btn btn-default dismissThisModal margin-right-10">
              Cancel
            </button>
            <button
              onClick={this.onClickProcessFromTagModal.bind(this)}
              className="btn btn-primary dismissThisModal">
              Create Tag
            </button>
          </div>
        </div>
      </div>
    );
  }

  onChangeItems = (items) => {
    this.setState({
      tags: items,
    });
  };

  handlePageClick = (data) => {
    let page = data.selected;
    this.setState({ page: page }, () => {
      this.getSystemTags();
    });
  };

  renderFilterView() {
    return (
      <>
        <div className="list-group-item">
          <FilterList
            label={"Search Tags"}
            name={"search"}
            type={"search"}
            searchFields={["name", "description"]}
            onFinish={this.filterUpdated.bind(this)}
          />
        </div>
        <div className="list-group-item">
          <p>Select a type</p>
          <FilterList
            filterList={this.state.tagTypes}
            name={"searchType"}
            type={"single"}
            searchFields={["type"]}
            onFinish={this.filterUpdated.bind(this)}
          />
        </div>
        {/* <div className="list-group-item">
          <p>Select a tag</p>
          <FilterList
            key={this.state.keyTag}
            filterList={this.state.genericTags}
            name={"tags"}
            type={"single"}
            searchFields={["ftags->_id"]}
            onFinish={this.filterUpdated.bind(this)}
          />
        </div> */}
        <div className="list-group-item">
          <SortItem
            key={this.state.keySort}
            items={this.state.tags}
            columns={[
              { label: "Name", value: "name", type: "string" },
              { label: "Type", value: "type", type: "string" },
            ]}
            onChangeItems={this.onChangeItems}
          />
        </div>
        <div className="container-fluid">
          <div className="gap20" />
          <p>
            Total Tags : <b>{this.state.totalTags}</b>
          </p>
          <p>
            Current Page: <b>{this.state.page + 1}</b>
          </p>
          <div id="react-paginate">
            <ReactPaginate
              previousLabel={<span aria-hidden="true">&laquo;</span>}
              nextLabel={<span aria-hidden="true">&raquo;</span>}
              pageCount={this.state.pages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={10}
              onPageChange={this.handlePageClick}
              breakClassName="page-item"
              breakLinkClassName="page-link"
              pageClassName="page-item"
              previousClassName="page-item"
              nextClassName="page-item"
              pageLinkClassName="page-link"
              previousLinkClassName="page-link"
              nextLinkClassName="page-link"
              activeClassName="page-item active"
              disableInitialCallback={true}
              initialPage={0}
            />
          </div>
        </div>
      </>
    );
  }

  render() {
    return (
      <div className="container-fluid">
        {this.renderConfirmationAlert()}
        {this.renderNewSystemTagModal()}
        {this.renderRulesAlert()}
        <TitleBox title="Tags Overview" />
        <div className="gap20" />
        <div className="card">
          <div className="list-group list-group-flush">
            {this.renderFilterView()}
            <div className="list-group-item">
              <button
                className="btn btn-default btn-sm"
                onClick={this.onClickViewArchivedTags.bind(this)}>
                View Archived Tags
              </button>
            </div>
            <div className="list-group-item">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Name </th>
                    <th>Description</th>
                    <th>Type</th>
                    <th>From</th>
                    <th>Rules</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>{this.renderTags()}</tbody>
              </table>
            </div>
            <div className="list-group-item">
              <ExportContent
                key={this.state.keyExport}
                data={this.state.allTags}
                keys={[
                  "name",
                  "description",
                  "createdAt",
                  "type",
                  "rules",
                  "additional_rules",
                  "notifications",
                ]}
                screen={"system-tags"}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  systemTags: state.tagReducer.systemTags,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ViewSystemTag);
