import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import TitleBox from "../../../components/general/titleBox";
import DateFilter from "../../../components/general/dateFilter";
import { callGetReports } from "../../../services";
import TableLoader from "../../../components/loader/table";
import moment from "moment";

class PaymentErrorLogsPage extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      errorLogs: [],
      showLoader: true,
    };
  }

  async componentDidMount() {
    this.getPayments({ filter: "today" });
  }

  async getPayments(data) {
    data["type"] = "stripe-payment";
    data["action"] = "error-logs";
    let d = await callGetReports(data);

    this.setState({
      errorLogs: d,
      showLoader: false,
    });
  }

  onChangeDateFilter(filterData) {
    this.getPayments(filterData);
  }

  onClickViewUser = (user) => {
    if (user && user._id) {
      let url = `/admin/edit-user?id=${user._id}`;
      window.open(url, "_blank");
    }
  };

  formatDate(date) {
    return moment(new Date(date))
      .tz(moment.tz.guess())
      .format("ddd, DD MMM YYYY hh:mm A z");
  }

  renderLogs() {
    var returnVals = [];
    let errorLogs = this.state.errorLogs;
    for (let i = 0; i < errorLogs.length; i++) {
      const element = errorLogs[i];
      returnVals.push(
        <tr key={element._id}>
          <td className="firstLetter link">
            {/*eslint-disable*/}
            <a
              onClick={this.onClickViewUser.bind(this, element.member_info[0])}>
              {element.member_info[0] ? element.member_info[0].name : "-"}
            </a>
            {/*eslint-enable*/}
          </td>
          <td>
            {element.member_info[0]
              ? element.member_info[0].email_address
              : "-"}
          </td>
          <td>{element.error_list.message}</td>
          <td>{this.formatDate(element.error_list.date)}</td>
        </tr>
      );
    }
    return returnVals;
  }

  render() {
    return (
      <div>
        <div className="container-fluid">
          <TitleBox title="Error Logs" showBackBtn={true} />
        </div>

        <div className="container-fluid">
          <div className="gap20" />
          <DateFilter
            onChangeFilter={this.onChangeDateFilter.bind(this)}
            customOption={false}
            selected={"today"}
          />
          {!this.state.showLoader && (
            <div className="card">
              <div className="container-fluid">
                <div className="gap20" />
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Error</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>{this.renderLogs()}</tbody>
                </table>
              </div>
            </div>
          )}
          {this.state.showLoader && (
            <div className="card">
              <div className="container-fluid">
                <TableLoader />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentErrorLogsPage);
