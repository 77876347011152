import React from "react";
import Checkbox from "../../../../components/form/checkBox";
import { SortableTaskStatusList } from "../../../../components/form/sortableList";
import { getUniqueId, showNotification } from "../../../../helpers";
import { arrayMove } from "react-sortable-hoc";

class TaskSettingStatusList extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      taskStatuses: this.props.taskStatuses,
      statusName: "",
      statusSelect: false,
      statusDefault: false,
      keyStatusDefault: Math.random(),
      statusId: "",
      selectedStatusIndex: -1,
      keyStatusSelect: Math.random(),
    };
  }

  toggleStatusSelect = (check) => {
    this.setState({ statusSelect: check });
  };

  toggleStatusDefault = (check) => {
    this.setState({ statusDefault: check });
  };

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  onClickSubmitStatus() {
    let statusList = this.state.taskStatuses;
    let statusName = this.state.statusName;
    if (!statusName) {
      showNotification("error", "Status is required", 4000);
      return;
    }
    let fList = statusList.filter(
      (d) =>
        d.name.toLowerCase() === statusName.trim().toLowerCase() &&
        d.id !== this.state.statusId
    );
    if (fList.length > 0) {
      showNotification("error", "Status already exists", 4000);
      return;
    }

    let status = {
      id: getUniqueId(),
      name: statusName.trim(),
      select: this.state.statusSelect,
      default: this.state.statusDefault,
    };

    if (this.state.selectedStatusIndex >= 0) {
      status.id = this.state.statusId;
      statusList[this.state.selectedStatusIndex] = status;
    } else {
      statusList.push(status);
    }

    this.setState(
      {
        taskStatuses: statusList,
        statusName: "",
        statusSelect: false,
        keyStatusSelect: Math.random(),
        statusDefault: false,
        keyStatusDefault: Math.random(),
        selectedStatusIndex: -1,
      },
      () => {
        this.props.onClickSaveInfo({
          taskStatuses: statusList,
        });
      }
    );
  }

  onSortEndStatuses = ({ oldIndex, newIndex }) => {
    let taskStatuses = this.state.taskStatuses;
    taskStatuses = arrayMove(taskStatuses, oldIndex, newIndex);

    this.setState({
      taskStatuses: taskStatuses,
    });
  };

  onPressRemoveStatus(e) {
    let index = e.target.dataset["value"];
    let taskStatuses = this.state.taskStatuses;
    taskStatuses.splice(index, 1);
    this.setState({
      taskStatuses: taskStatuses,
    });
  }

  onPressEditStatus(e) {
    let index = e.target.dataset["value"];
    let taskStatuses = this.state.taskStatuses;

    let status = taskStatuses[index];

    this.setState({
      statusId: status.id,
      statusName: status.name,
      selectedStatusIndex: index,
      statusSelect: status.select,
      statusDefault: status.default,
      keyStatusDefault: Math.random(),
      keyStatusSelect: Math.random(),
    });
  }

  render() {
    return (
      <div className="card">
        <div className="container-fluid">
          <div className="gap20" />

          <div className="havingHelpText">
            <h5 className="noMargin">Task Statuses</h5>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div
                className={
                  this.state.statusName !== ""
                    ? "mdInput mdFocussed"
                    : "mdInput"
                }>
                <label>Status</label>
                <input
                  type="text"
                  name="statusName"
                  value={this.state.statusName}
                  onChange={(event) => this.handleInputChange(event)}
                />
              </div>
            </div>
          </div>
          <div className="gap20" />
          <Checkbox
            label={"Allow Customer to select this option"}
            isChecked={this.state.statusSelect}
            handleCheckboxChange={this.toggleStatusSelect.bind(this)}
            key={this.state.keyStatusSelect}
          />
          <div className="gap20" />
          <Checkbox
            label={"Default Status"}
            isChecked={this.state.statusDefault}
            handleCheckboxChange={this.toggleStatusDefault.bind(this)}
            key={this.state.keyStatusDefault}
          />
          <div className="text-right">
            <div className="gap30" />
            <button
              className="btn btn-primary"
              onClick={this.onClickSubmitStatus.bind(this)}>
              Save
            </button>
          </div>
          <div className="gap20" />
          <SortableTaskStatusList
            distance={10}
            task={true}
            items={this.state.taskStatuses}
            onSortEnd={this.onSortEndStatuses}
            onPressEdit={this.onPressEditStatus.bind(this)}
            onPressRemove={this.onPressRemoveStatus.bind(this)}
          />
          <div className="gap20" />
        </div>
      </div>
    );
  }
}

export default TaskSettingStatusList;
