import {
  RESET_STATUS,
  GET_SYSTEM_UPDATE,
  CREATE_SYSTEM_UPDATE_SUCCESS,
  CREATE_SYSTEM_UPDATE_FAILURE,
  UPDATE_SYSTEM_UPDATE_SUCCESS,
  UPDATE_SYSTEM_UPDATE_FAILURE
} from "../types";

const initialState = {
  message: "",
  success: false,
  systemUpdates: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RESET_STATUS:
      return {
        ...state,
        message: "",
        success: false
      };
    case CREATE_SYSTEM_UPDATE_SUCCESS:
      return {
        ...state,
        message: action.message,
        success: action.success
      };
    case CREATE_SYSTEM_UPDATE_FAILURE:
      return {
        ...state,
        message: action.message,
        success: action.success
      };
    case UPDATE_SYSTEM_UPDATE_SUCCESS:
      return {
        ...state,
        message: action.message,
        success: action.success
      };
    case UPDATE_SYSTEM_UPDATE_FAILURE:
      return {
        ...state,
        message: action.message,
        success: action.success
      };
    case GET_SYSTEM_UPDATE:
      return {
        ...state,
        systemUpdates: action.data
      };
    default:
      return state;
  }
};
