import store from "../modules/store";

class SettingsHelper {
  constructor() {
    let userSettings = store.getState().generalReducer.userSettings;

    if (userSettings.aws === undefined || userSettings.aws.bucket === "") {
      userSettings["aws"] = {};
      userSettings["aws"]["bucket"] = "memberpages";
    }
    if (
      userSettings.stripe === undefined ||
      userSettings.stripe.private_key === ""
    ) {
      userSettings["stripe"] = {};
      userSettings["stripe"]["private_key"] = "";
    }
    this.userSettings = userSettings;
  }

  getAtqHtml() {
    return this.userSettings["atq"] ? this.userSettings["atq"]["html"] : "";
  }

  getAtqQuestion() {
    return this.userSettings["atq"] ? this.userSettings["atq"]["question"] : "";
  }

  getAtqPageTitle() {
    return this.userSettings["atq"]
      ? this.userSettings["atq"]["page_title"]
      : "Accountability";
  }

  getTasksTitle() {
    return this.userSettings["task_settings"]
      ? this.userSettings["task_settings"]["page_title"]
      : "Tasks";
  }

  getTasksName() {
    return this.userSettings["task_settings"]
      ? this.userSettings["task_settings"]["task_name"]
      : "Task";
  }

  getAddCommentName() {
    return this.userSettings["task_settings"]
      ? this.userSettings["task_settings"]["add_comment"]
      : "Task";
  }

  getMemberDashboardHtml() {
    return this.userSettings["member_dashboard"]
      ? this.userSettings["member_dashboard"]["html"]
      : "";
  }

  getMemberDashboardCollapseChallenge() {
    return this.userSettings["member_dashboard"]
      ? this.userSettings["member_dashboard"]["collapase_challenges"]
      : false;
  }

  getFromEmail() {
    return this.userSettings["from_email"] || "";
  }

  getShowAppDownloadLinks() {
    return this.userSettings["member_dashboard"]
      ? this.userSettings["member_dashboard"]["show_app_download_links"] !==
        undefined
        ? this.userSettings["member_dashboard"]["show_app_download_links"]
        : true
      : true;
  }

  getFromEmailList() {
    return this.userSettings["from_email_list"] || "";
  }

  getAccountKey() {
    if (!this.userSettings.business_details) {
      return "";
    }
    return this.userSettings["business_details"][0]["unique_id"] || "";
  }

  getDomainList() {
    if (!this.userSettings.business_details) {
      return [];
    }
    return this.userSettings["business_details"][0]["domain_list"] || [];
  }

  getAwsBucketName() {
    if (!this.userSettings.aws) {
      return "memberpages";
    }
    return this.userSettings["aws"]["bucket"] || "memberpages";
  }

  getStripePrivateKey() {
    if (!this.userSettings.stripe) {
      return "";
    }
    return this.userSettings["stripe"]["private_key"] || "";
  }

  getLogo() {
    let logo = {
      image: this.userSettings["logo_image"],
      color: this.userSettings["logo_back_color"],
    };
    return logo;
  }

  getAdminMenuOptions() {
    let data = { adminMenuOptions: {}, inactiveMenu: [], customAdminMenu: [] };
    if ("admin_menu_options" in this.userSettings) {
      data["adminMenuOptions"] = this.userSettings["admin_menu_options"];
    }
    if ("inactive_menu" in this.userSettings) {
      data["inactiveMenu"] = this.userSettings["inactive_menu"]["admin"];
    }
    data["customAdminMenu"] = this.userSettings["custom_admin_menu"] || [];
    return data;
  }

  getMemberMenuOptions() {
    let data = { memberMenuOptions: {}, inactiveMenu: [], memberMenuSort: {} };
    if ("member_menu_options" in this.userSettings) {
      data["memberMenuOptions"] = this.userSettings["member_menu_options"];
    }
    if ("inactive_menu" in this.userSettings) {
      data["inactiveMenu"] = this.userSettings["inactive_menu"]["member"];
    }

    if ("member_menu_sort" in this.userSettings) {
      data["memberMenuSort"] = this.userSettings["member_menu_sort"];
    }
    return data;
  }

  getRoles() {
    return this.userSettings["roles"] || {};
  }

  getBranding() {
    let data = {
      headerImage: "",
      footerImage: "",
      privacyUrl: "",
      termsUrl: "",
      siteName: "",
      loginHeaderBackColor: "",
      loginFooterBackColor: "",
      loginFooterFontColor: "",
      checkoutHeaderBackColor: "",
      eventHeaderBackColor: "",
      loginBackImage: "",
    };
    data["headerImage"] = this.userSettings["header_image"] || "";
    data["footerImage"] = this.userSettings["footer_image"] || "";
    data["loginBackImage"] = this.userSettings["login_back_image"] || "";
    data["privacyUrl"] = this.userSettings["privacy_url"] || "";
    data["termsUrl"] = this.userSettings["terms_url"] || "";
    data["siteName"] = this.userSettings["site_name"] || "";
    data["loginHeaderBackColor"] =
      this.userSettings["login_header_back_color"] || "#0984b5";
    data["loginFooterBackColor"] =
      this.userSettings["login_footer_back_color"] || "#0984b5";
    data["loginFooterFontColor"] =
      this.userSettings["login_footer_font_color"] || "#ffffff";
    data["checkoutHeaderBackColor"] =
      this.userSettings["checkout_header_back_color"] || "#0984b5";
    data["eventHeaderBackColor"] =
      this.userSettings["event_header_back_color"] || "#f8f9fa";
    return data;
  }

  getProfileFormFields() {
    if (this.userSettings["profile"]) {
      return this.userSettings["profile"]["form_fields"] || [];
    } else {
      return [];
    }
  }

  getAllowMessages() {
    return this.userSettings["member_dashboard"]
      ? this.userSettings["member_dashboard"]["allow_messages"]
      : true;
  }

  getSystemEmailLogoImage() {
    return this.userSettings["system_email_logo_image"] || "";
  }
}

export { SettingsHelper };
