import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";

import { getCustomPrograms } from "../../../modules/actions/index";
import TitleBox from "../../../components/general/titleBox";

import FilterList from "../../../components/form/filterList";
import { filterByTags } from "../../../helpers/filters";

class ViewCustomProgram extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      customPrograms: [],
      customProgramsBackup: [],
      filterList: []
    };
  }

  componentDidMount() {
    this.props.getCustomPrograms();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.customPrograms.data) {
      this.setState({
        customPrograms: newProps.customPrograms.data,
        customProgramsBackup: newProps.customPrograms.data
      });
    }
  }

  onClickExercise = customProgramItem => {
    this.props.history.push("/admin/edit-custom-program", {
      customProgramItem: customProgramItem
    });
  };

  renderCustomPrograms() {
    var returnVals = [];
    let items = this.state.customPrograms;
    items.forEach(element => {
      returnVals.push(
        <tr key={element._id}>
          <td>{element.name}</td>
          <td>{element.description}</td>
          <td className="firstLetter">{element.member_info.name}</td>
          <td>{moment(element.start_date).format("MMM Do, YYYY")}</td>
          <td>{element.days}</td>
          <td>{element.events.length}</td>
          <td>{element.phases ? element.phases.length : 0}</td>
          <td>
            {/*eslint-disable-next-line*/}
            <a
              onClick={this.onClickExercise.bind(this, element)}
              className="btn btn-default btn-sm border-0">
              <i className="fa fa-pencil" />
            </a>
          </td>
        </tr>
      );
    });
    return returnVals;
  }

  filterUpdated(updatedFilter, name, type, searchFields) {
    let filterList = this.state.filterList;
    filterList[name] = {
      name: name,
      type: type,
      searchFields: searchFields,
      values: updatedFilter
    };
    this.setState(
      {
        filterList: filterList
      },
      () => {
        let data = filterByTags(this.state.customProgramsBackup, filterList);
        this.setState({
          customPrograms: data
        });
      }
    );
  }

  render() {
    return (
      <div className="container-fluid">
        <TitleBox title="Custom Program Overview" />
        <div className="gap20" />
        <div className="card">
          <div className="list-group list-group-flush">
            <div className="list-group-item">
              <FilterList
                label={"Search Custom Programs"}
                name={"search"}
                type={"search"}
                searchFields={["name", "description"]}
                onFinish={this.filterUpdated.bind(this)}
              />
            </div>
            <div className="list-group-item">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Name </th>
                    <th>Description</th>
                    <th>User</th>
                    <th>Start Date</th>
                    <th>Days</th>
                    <th>Events</th>
                    <th>Phases</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>{this.renderCustomPrograms()}</tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  customPrograms: state.customProgramReducer.customPrograms
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCustomPrograms
    },
    dispatch
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewCustomProgram);
