import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import $ from "jquery";

import TitleBox from "../../../components/general/titleBox";
import FilterList from "../../../components/form/filterList";

import { filterByTags } from "../../../helpers/filters";
import {
  callGetResourcesByQuery,
  callDeleteResourceById,
  callCreateResource,
} from "../../../services";
import { showNotification } from "../../../helpers";

class ViewResources extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      resources: [],
      resourcesBackup: [],
      filterList: [],
      selectedResource: {},
      name: "",
    };
  }

  componentDidMount() {
    this.getResources();
  }

  getResources() {
    callGetResourcesByQuery().then((d) => {
      this.setState({ resources: d.data, resourcesBackup: d.data });
    });
  }

  onClickEditResource = (resource) => {
    this.props.history.push("/admin/add-resource", {
      resource: resource,
    });
  };

  onClickDeleteResource = (resource) => {
    this.setState({ selectedResource: resource }, () => {
      $(".modalAlertOverlay.deleteOverlay,.modalAlert.deleteAlert").fadeIn(200);
    });
  };

  filterUpdated(updatedFilter, name, type, searchFields) {
    let filterList = this.state.filterList;
    filterList[name] = {
      name: name,
      type: type,
      searchFields: searchFields,
      values: updatedFilter,
    };
    this.setState(
      {
        filterList: filterList,
      },
      () => {
        let data = filterByTags(this.state.resourcesBackup, filterList);
        this.setState({
          resources: data,
        });
      }
    );
  }

  onClickCloneResource = (element) => {
    this.setState({ selectedResource: element }, () => {
      $(".modalAlertOverlay.cloneModalOverlay,.modalAlert.cloneModal").fadeIn(
        200
      );
    });
  };

  renderResources() {
    var returnVals = [];
    let items = this.state.resources;
    items.forEach((element) => {
      returnVals.push(
        <tr key={element._id}>
          <td>{element.name}</td>
          <td>{element.tag_info.name ? element.tag_info.name : ""}</td>
          <td>{element.file_list.length}</td>
          <td>{element.video_list.length}</td>
          <td className="min-width-100">
            <button
              onClick={this.onClickDeleteResource.bind(this, element)}
              className="btn btn-danger btn-sm margin-right-10 border-0">
              <i className="fa fa-trash" />
            </button>
            <button
              onClick={this.onClickEditResource.bind(this, element)}
              className="btn btn-default btn-sm border-0 margin-right-10">
              <i className="fa fa-pencil" />
            </button>
            <button
              title="Clone"
              onClick={this.onClickCloneResource.bind(this, element)}
              className="btn btn-default btn-sm border-0">
              <i className="fa fa-clone" />
            </button>
          </td>
        </tr>
      );
    });
    return returnVals;
  }

  onClickCloneForm = (element) => {
    this.setState({ selectedResource: element }, () => {
      $(".modalAlertOverlay.cloneModalOverlay,.modalAlert.cloneModal").fadeIn(
        200
      );
    });
  };

  onClickCancelFromCloneModal() {
    $(".modalAlertOverlay.cloneModalOverlay,.modalAlert.cloneModal").fadeOut(
      200
    );
  }

  onClickProcessFromCloneModal() {
    if (this.state.name.length === 0) {
      showNotification("error", "Name is required", 4000);
      return;
    }

    $(".modalAlertOverlay.cloneModalOverlay,.modalAlert.cloneModal").fadeOut(
      200
    );

    let selectedResource = this.state.selectedResource;

    /*eslint-disable*/
    let apiData = {
      tag_id: selectedResource.tag_id,
      name: this.state.name,
      description: selectedResource.description,
      file_list: selectedResource.file_list,
      video_list: selectedResource.video_list,
      content: selectedResource.content,
      html: selectedResource.html,
      draft_id: selectedResource.draft_id,
      draft_image: selectedResource.draft_image,
      release_date: selectedResource.release_date,
      expiry_date: selectedResource.expiry_date,
    };
    /*eslint-enable*/

    callCreateResource(apiData).then((d) => {
      if (d._id) {
        this.setState({ selectedResource: {}, name: "" }, () => {
          showNotification("success", "Resource created successfully", 4000);
          this.getResources();
        });
      } else {
        showNotification("error", "Something went wrong", 4000);
      }
    });
  }

  onClickNoAlert = () => {
    $(".modalAlertOverlay.deleteOverlay,.modalAlert.deleteAlert").fadeOut(200);
  };

  onClickYesAlert = () => {
    callDeleteResourceById(this.state.selectedResource._id).then((d) => {
      $(".modalAlertOverlay.deleteOverlay,.modalAlert.deleteAlert").fadeOut(
        200
      );
      this.getResources();
    });
  };

  renderConfirmationAlert() {
    let selectedResource = this.state.selectedResource;
    let name = "";
    if (selectedResource.name) {
      name = selectedResource.name;
    }
    return (
      <div>
        <div className="modalAlertOverlay deleteOverlay" />
        <div className="modalAlert deleteAlert">
          <p>{`Are you sure you want to delete "${name}" resource?`}</p>
          <div className="alertFooterBtns">
            <button
              className="btn btn-default closeModal-No margin-right-10"
              onClick={this.onClickNoAlert}>
              No
            </button>
            <button
              className="btn btn-primary closeModal-Yes"
              onClick={this.onClickYesAlert}>
              Yes
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderFilterView() {
    return (
      <div className="list-group-item">
        <FilterList
          label={"Search Resources..."}
          name={"search"}
          type={"search"}
          searchFields={["name", "description"]}
          onFinish={this.filterUpdated.bind(this)}
        />
      </div>
    );
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  renderNewResourceModal() {
    let title = "";
    if (this.state.selectedResource.name) {
      title = this.state.selectedResource.name;
    }
    return (
      <div>
        <div className="modalAlertOverlay cloneModalOverlay" />
        <div className="modalAlert cloneModal">
          <div className="clearfix">
            <div className="pull-left">
              <p>Clone Resource - {title}</p>
            </div>
          </div>

          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div
                  className={
                    this.state.name !== "" ? "mdInput mdFocussed" : "mdInput"
                  }>
                  <label>Name</label>
                  <input
                    type="text"
                    name="name"
                    value={this.state.name}
                    onChange={(event) => this.handleInputChange(event)}
                  />
                </div>
                <div className="gap10" />
              </div>
            </div>
          </div>
          <div className="gap10" />

          <div className="alertFooterBtns">
            <button
              onClick={this.onClickCancelFromCloneModal.bind(this)}
              className="btn btn-default dismissThisModal margin-right-10">
              Cancel
            </button>
            <button
              onClick={this.onClickProcessFromCloneModal.bind(this)}
              className="btn btn-primary dismissThisModal">
              Create Resource
            </button>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="container-fluid">
        {this.renderConfirmationAlert()}
        {this.renderNewResourceModal()}
        <TitleBox title="Resources Overview" />
        <div className="gap20" />
        <div className="card">
          <div className="list-group list-group-flush">
            {this.renderFilterView()}
            <div className="list-group-item">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Tag</th>
                    <th>Files</th>
                    <th>Videos</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>{this.renderResources()}</tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ViewResources);
