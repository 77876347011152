import React, { useEffect, useState, useRef } from "react";

import videojs from "video.js";
import "video.js/dist/video-js.css";
// Fantasy
import "@videojs/themes/dist/fantasy/index.css";

import "videojs-http-source-selector-2";
import "videojs-contrib-quality-levels";
import "videojs-http-source-selector-2/dist/videojs-http-source-selector.css";

export default function VideoPlayer(props) {
  /*eslint-disable*/
  const { videoOptions } = props;
  const playerRef = useRef();
  const [currentTime, setCurrentTime] = useState(null);

  useEffect(() => {
    const player = videojs(playerRef.current, props, () => {});
    player.httpSourceSelector();
    player.on("contextmenu", function (e) {
      e.preventDefault();
    });

    if (props.onPlayerChange) {
      props.onPlayerChange(player);
    }

    return () => {
      player.dispose();
    };
  }, []);

  //todo we need to get and record the time and all video events..
  //ETC we need watch time, current video time, if the video completed,
  //and if the video starts/stops playing

  return (
    <div>
      <div data-vjs-player>
        <video
          ref={playerRef}
          className={`video-js vjs-${props.aspectRatio} vjs-theme-fantasy vjs-fluid vjs-big-play-centered vjs-show-big-play-button-on-pause`}
          playsInline
        />
      </div>
    </div>
  );
}
